// @flow

import { put, call, fork, takeEvery, select } from 'redux-saga/effects'
import { pickBy, identity } from 'lodash-es'

import api from '../../core/api'
import { serverError } from '../../components/Layout/Layout.actions'
import * as actions from './CompanyView.actionTypes'

function* watchSave() {
  yield takeEvery(actions.COMPANY_VIEW_ADDRESS_SAVE, save)
}

function* watchCreate() {
  yield takeEvery(actions.COMPANY_VIEW_ADDRESS_CREATE, create)
}

function* watchRemove() {
  yield takeEvery(actions.COMPANY_VIEW_ADDRESS_REMOVE, remove)
}

function* save() {
  try {
    const {
      value,
      lattitude,
      longitude,
      widget_zoom: zoom,
      widget_lattitude: wLatitude,
      widget_longitude: wLongitude,
      widget_lattitude_str: wLatitudeStr,
      widget_longitude_str: wLongitudeStr,
      officeId,
    } = yield select(state =>
      state.companyView.addresses.find(address => address.editMode)
    )

    const addressData = {
      value,
      lattitude,
      longitude,
      widget_zoom: zoom,
      widget_lattitude: wLatitude,
      widget_longitude: wLongitude,
      widget_lattitude_str: wLatitudeStr,
      widget_longitude_str: wLongitudeStr,
    }

    const params = pickBy(addressData, identity)

    const { id: addressId } = yield call(api.address.add, params)
    const { address } = yield call(api.office.updateAddress, officeId, {
      address: addressId,
    })
    yield put({ type: actions.COMPANY_VIEW_ADDRESS_SAVED, address, officeId })
  } catch (error) {
    yield put(serverError(error))
  }
}

function* create({ uk, setPrimary }) {
  try {
    const {
      value,
      lattitude,
      longitude,
      widget_zoom: zoom,
      widget_lattitude: wLatitude,
      widget_longitude: wLongitude,
      widget_lattitude_str: wLatitudeStr,
      widget_longitude_str: wLongitudeStr,
    } = yield select(state => state.companyView.creatingAddress)

    const addressData = {
      value,
      lattitude,
      longitude,
      widget_zoom: zoom,
      widget_lattitude: wLatitude,
      widget_longitude: wLongitude,
      widget_lattitude_str: wLatitudeStr,
      widget_longitude_str: wLongitudeStr,
    }

    const params = pickBy(addressData, identity)

    const { id: addressId } = yield call(api.address.add, params)
    const officeResp = yield call(api.office.createAddress, {
      address: addressId,
      uk,
    })

    if (setPrimary) {
      officeResp.primary = true

      yield call(api.office.setPrimary, { id: officeResp.id })
    }

    yield put({ type: actions.COMPANY_VIEW_ADDRESS_CREATED, res: officeResp })
  } catch (error) {
    yield put(serverError(error))
  }
}

function* remove() {
  try {
    const id = yield select(
      state =>
        state.companyView.addresses.find(address => address.editMode).officeId
    )

    yield call(api.office.removeAddress, id)
    yield put({ type: actions.COMPANY_VIEW_ADDRESS_REMOVED, officeId: id })
  } catch (error) {
    yield put({ type: actions.COMPANY_VIEW_ADDRESS_REMOVE_ERROR })
    yield put(serverError(error))
  }
}

export default function* watch() {
  yield fork(watchSave)
  yield fork(watchCreate)
  yield fork(watchRemove)
}
