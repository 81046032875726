// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer, graphql } from 'react-relay'
import { useSelector } from 'react-redux'

import { getFullDateFormat } from '../../../../utils/commonSelectors'
import DateTime from '../../../../components/DateTime'
import { Cell } from '../../../../components/Block'

import styles from './OrderProfileCreationDate.module.scss'

const OrderProfileCreationDate = props => {
  const { t } = useTranslation('Orders')
  const {
    data: { createdAt },
  } = props

  const dateFormat = useSelector(state => getFullDateFormat(state))

  return (
    <Cell title={t('createdAt')}>
      <div className={styles['company-value']}>
        {<DateTime dateTime={createdAt} dateFormat={dateFormat} />}
      </div>
    </Cell>
  )
}

export default createFragmentContainer(OrderProfileCreationDate, {
  data: graphql`
    fragment OrderProfileCreationDate_data on CustomerOrderNode {
      createdAt
    }
  `,
})
