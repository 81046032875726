// @flow

import React, { useState, useEffect, useRef } from 'react'
import api from '../core/api'
import NotFoundPage from '../containers/NotFoundPage'

const DownloadDoc = ({ match }) => {
  const [notFound, setNotFound] = useState(false)
  const timerRef = useRef()
  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current)
    }
  }, [])

  const fileId = match.params?.fileId

  if (!fileId) {
    return <NotFoundPage />
  }

  const INTERVAL = 500

  const getFile = () => {
    api.file
      .getById(fileId)
      .then(res => {
        if (res.checking) {
          timerRef.current = setTimeout(getFile, INTERVAL)
        } else {
          location.replace(res)
        }
      })
      .catch(() => {
        setNotFound(true)
      })
  }
  getFile()

  return notFound ? <NotFoundPage /> : null
}

export default DownloadDoc
