// @flow

import React from 'react'
import classnames from 'classnames'
import type { Node } from 'react'

import styles from './SaveList.module.scss'

export type Props = {
  children?: Node,
  className?: string,
  disabled?: boolean,
  onClick?: (e?: { ... }) => void,
  style?: Object,
}

const SaveList = (props: Props): Node => {
  const { className, disabled, children, style, onClick } = props

  const btnClass = classnames(styles.button, className)

  return (
    <button
      type='button'
      className={btnClass}
      disabled={disabled}
      style={style}
      onClick={onClick}
    >
      {children && <span className={styles.text}>{children}</span>}
    </button>
  )
}

export default SaveList
