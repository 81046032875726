// @flow

import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer, graphql } from 'react-relay'

import { MARKETPLACE_ROUTES } from '../../../../constants'
import { Cell } from '../../../../components/Block'

import styles from './OrderProfileOffer.module.scss'

const OrderProfileOffer = props => {
  const { t } = useTranslation('Orders')

  const {
    data: { offer: offerJSON },
  } = props

  const offerData = JSON.parse(offerJSON)

  const offerText = offerData.name

  return (
    <Cell title={t('offer')}>
      {offerData.archived ? (
        <span className={styles.offerValue}>{offerText}</span>
      ) : (
        <Link
          className={styles.offerValue}
          to={MARKETPLACE_ROUTES['marketplaceOffer'](offerData['__relay_id__'])}
        >
          {offerText}
        </Link>
      )}
    </Cell>
  )
}

export default createFragmentContainer(OrderProfileOffer, {
  data: graphql`
    fragment OrderProfileOffer_data on CustomerOrderNode {
      offer
    }
  `,
})
