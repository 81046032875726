// @flow

import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDrag, useDrop } from 'react-dnd'
import type { Node } from 'react'

import Icon from '../../../../Icon'
import Label from '../../../../../containers/RequestView/RequestActivity/PhaseList/PhaseItem/PhaseBody/ActivityList/ActivityItem/ActivityItemBody/ItemLabels/Label'
import { getDeadlineTemplate } from '../../../../Activities/ActivityModal/ActivityModal.utils'
import ActionIcon from '../../../../../containers/RequestView/RequestActivity/PhaseList/PhaseItem/PhaseBody/ActivityList/ActivityItem/ActivityItemHead/Actions/ActionIcon'
import SelectCustom from '../../../../Select/SelectCustom'
import Row from './Row'
import Notes from './Notes'
import { updateActivityTemplate } from '../../../../../core/api/api.pipeline'
import Type from './Type'

import styles from './ActivityListItem.module.scss'

type Props = {
  activity: Object,
  onRemove: Function,
  reload: Function,
  setActivity: Function,
}

const PREVIEW_AUTOMATIONS_COUNT = 2

const ActivityListItem = (props: Props): Node => {
  const {
    activity: {
      title,
      automations,
      type: { icon, name },
      position,
      priority,
      deadline_days,
      deadline_hours,
      deadline_minutes,
      visible_to_tenant,
      id,
      notes,
    },
    activity,
  } = props

  const ref = useRef(null)

  const { t } = useTranslation('Pipeline')

  const handleSetActivity = () => props.setActivity(activity)

  const handleUpdateAutomation = () => props.setAutomation(activity)

  const [, drag] = useDrag({
    item: {
      id,
      type: 'activity',
      title,
      position,
    },
    canDrag: () => true,
    collect: monitor => {
      return {
        isDragging: monitor.isDragging(),
      }
    },
  })

  const [, drop] = useDrop({
    accept: 'activity',
    canDrop: (dropProps, monitor) => {
      const { id: targetId } = monitor.getItem()

      return targetId !== id
    },
    drop: (dropProps, monitor) => {
      const { id: targetId } = monitor.getItem()

      if (id === targetId) {
        return
      }

      updateActivityTemplate(targetId, { position: position }).finally(() => {
        props.reload()
      })
    },
    collect: monitor => ({
      isDropTarget: monitor.canDrop() && monitor.isOver({ shallow: true }),
    }),
  })

  drag(drop(ref))

  const options = [
    {
      value: 'editActivity',
      label: t('EditActivity'),
      onClick: handleSetActivity,
      icon: 'pencil',
    },
    {
      value: 'coverActivity',
      label: t(visible_to_tenant ? 'UnCover' : 'Cover'),
      onClick: () => {
        updateActivityTemplate(id, {
          visible_to_tenant: !visible_to_tenant,
        }).finally(() => {
          props.reload(true)
        })
      },
      icon: visible_to_tenant ? 'hideEye' : 'showEye',
    },
    {
      value: 'connectAutomation',
      label: t(
        automations.length > 0
          ? 'EditConnectedAutomations'
          : 'ConnectAutomation'
      ),
      onClick: () => handleUpdateAutomation(),
      icon: 'automate',
    },
    {
      value: 'deleteActivity',
      label: t('DeleteActivity'),
      onClick: () => props.setRemovalId(id),
      icon: 'bin',
    },
  ]

  const restAutomations = automations.length - PREVIEW_AUTOMATIONS_COUNT

  return (
    <li className={styles['list-item']} ref={ref}>
      <Row>
        <Type
          title={title}
          icon={icon}
          name={name}
          priority={priority}
          onChange={handleSetActivity}
        />
        <div className={styles.actions}>
          <ActionIcon>
            <Icon id={visible_to_tenant ? 'showEye' : 'superIcon'} />
          </ActionIcon>
          <SelectCustom options={options} onChange={o => o.onClick()} />
        </div>
      </Row>
      {(deadline_days || deadline_hours || deadline_minutes) && (
        <Row>
          <Label
            text={getDeadlineTemplate(
              deadline_days,
              deadline_hours,
              deadline_minutes
            )}
            iconId='deadline'
          />
        </Row>
      )}
      {automations.length > 0 && (
        <Row>
          {automations
            .slice(0, PREVIEW_AUTOMATIONS_COUNT)
            .map(({ id, name }) => (
              <Label automation key={id} text={name} iconId='automate' />
            ))}
          {restAutomations > 0 && (
            <div className={styles.restAutomations}>+{restAutomations}</div>
          )}
        </Row>
      )}
      {notes && (
        <Row>
          <Notes notes={notes} />
        </Row>
      )}
    </li>
  )
}

export default ActivityListItem
