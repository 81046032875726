// @flow

import type { Node } from 'react'
import type { IObjectType } from '../BuildingInformation'
import React from 'react'
import setFormikError from '../../../../utils/setFormikError'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

import { mapValues, trim } from 'lodash'

import FieldRow from '../../../FieldRow'

import styles from './BuildingInformationForms.module.scss'

import Button from '../../../Button'
import {
  ACCOUNTANT,
  ASSET_MANAGER,
  BROKER,
  IDENTICAL_ADDRESS,
  NUMBER_OWNER,
  TAX_CONSULTANT,
  WARRANTY,
  MAX_LENGTH,
} from './OragInfo.constants'
import * as Yup from 'yup'

type Props = {
  active: boolean,
  handleEditClose: () => void,
  initialValues: IObjectType,
  isOpen: boolean,
  updateObjectInfo: (data: {
    object_info: IObjectType,
  }) => Promise<IObjectType>,
}

const OragInfoForm = ({
  isOpen,
  initialValues,
  active,
  handleEditClose,
  updateOragDetails,
}: Props): Node => {
  const { t } = useTranslation('Building')
  const onSubmit = values => {
    const orag_cluster: IObjectType = {
      ...values,
    }

    setValues({ ...mapValues(orag_cluster, trim) })

    updateOragDetails({ orag_cluster: { ...mapValues(orag_cluster) } })
      .then(() => handleEditClose())
      .catch(error => setFormikError(error, setFieldError))
      .finally(() => setSubmitting(false))
  }
  const onClosed = () => {
    handleEditClose()
    resetForm()
  }

  const {
    values,
    errors,
    dirty,
    resetForm,
    isSubmitting,
    setSubmitting,
    setFieldError,
    handleSubmit,
    setValues,
    handleChange,
  } = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      [ACCOUNTANT]: Yup.string()
        .nullable()
        .max(
          MAX_LENGTH,
          t('MaxInputLength', {
            length: MAX_LENGTH,
          })
        ),
      [ASSET_MANAGER]: Yup.string()
        .nullable()
        .max(
          MAX_LENGTH,
          t('MaxInputLength', {
            length: MAX_LENGTH,
          })
        ),
      [BROKER]: Yup.string()
        .nullable()
        .max(
          MAX_LENGTH,
          t('MaxInputLength', {
            length: MAX_LENGTH,
          })
        ),
      [IDENTICAL_ADDRESS]: Yup.string()
        .nullable()
        .max(
          MAX_LENGTH,
          t('MaxInputLength', {
            length: MAX_LENGTH,
          })
        ),
      [NUMBER_OWNER]: Yup.string()
        .nullable()
        .max(
          MAX_LENGTH,
          t('MaxInputLength', {
            length: MAX_LENGTH,
          })
        ),
      [TAX_CONSULTANT]: Yup.string()
        .nullable()
        .max(
          MAX_LENGTH,
          t('MaxInputLength', {
            length: MAX_LENGTH,
          })
        ),
      [WARRANTY]: Yup.string()
        .nullable()
        .max(
          MAX_LENGTH,
          t('MaxInputLength', {
            length: MAX_LENGTH,
          })
        ),
    }),
    onSubmit,
  })

  return (
    <form name='orag_info' onSubmit={handleSubmit} onChange={handleChange}>
      {
        <>
          <FieldRow
            name={IDENTICAL_ADDRESS}
            active={active}
            label={'IdenticalAddress'}
            error={errors[IDENTICAL_ADDRESS]}
            value={values[IDENTICAL_ADDRESS]}
            placeholder={'EnterIdenticalAddress'}
            handleChange={handleChange}
          />
          <FieldRow
            name={ASSET_MANAGER}
            active={active}
            label={'AssetManager'}
            error={errors[ASSET_MANAGER]}
            value={values[ASSET_MANAGER]}
            placeholder={'EnterAssetManager'}
            handleChange={handleChange}
          />
          <FieldRow
            name={ACCOUNTANT}
            active={active}
            label={'Accountant'}
            error={errors[ACCOUNTANT]}
            value={values[ACCOUNTANT]}
            placeholder={'EnterAccountant'}
            handleChange={handleChange}
          />
          {(values[BROKER] || isOpen) && (
            <FieldRow
              name={BROKER}
              active={active}
              label={'Broker'}
              error={errors[BROKER]}
              value={values[BROKER]}
              placeholder={'EnterBroker'}
              handleChange={handleChange}
            />
          )}
          <FieldRow
            name={TAX_CONSULTANT}
            active={active}
            label={'TaxConsultant'}
            error={errors[TAX_CONSULTANT]}
            value={values[TAX_CONSULTANT]}
            placeholder={'EnterTaxConsultant'}
            handleChange={handleChange}
          />

          {(values[WARRANTY] || isOpen) && (
            <FieldRow
              name={WARRANTY}
              active={active}
              label={'Warranty'}
              error={errors[WARRANTY]}
              value={values[WARRANTY]}
              placeholder={'EnterWarranty'}
              handleChange={handleChange}
            />
          )}
          {(values[NUMBER_OWNER] || isOpen) && (
            <FieldRow
              name={NUMBER_OWNER}
              active={active}
              label={'NumberOwner'}
              error={errors[NUMBER_OWNER]}
              value={values[NUMBER_OWNER]}
              placeholder={'EnterNumberOwner'}
              handleChange={handleChange}
            />
          )}
        </>
      }
      {active && (
        <div className={styles.buttonWrapper}>
          <Button.Save disabled={!dirty || isSubmitting} type='submit'>
            {t('Common:Save')}
          </Button.Save>
          <Button.Cancel onClick={onClosed}>{t('Common:Cancel')}</Button.Cancel>
        </div>
      )}
    </form>
  )
}

export default OragInfoForm
