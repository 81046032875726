// @flow

import React from 'react'
import ReactModal from 'react-modal'
import classnames from 'classnames'
import type { Node } from 'react'

import styles from './Modal.module.scss'

ReactModal.setAppElement('#app')

type Props = {
  children?: ?Node,
  className?: string,
  contentLabel: string,
  isOpen: boolean,
  onRequestClose?: () => void,
  overlayClassName?: string,
  style?: Object,
}

const Modal = (props: Props): Node => {
  const { className, contentLabel, isOpen, style, children, overlayClassName } =
    props

  const contentClass = classnames(styles.modal, className)
  const overlayClass = classnames(styles.overlay, overlayClassName)

  const modalProps = Object.assign(
    {},
    props.onRequestClose ? { onRequestClose: props.onRequestClose } : undefined,
    style ? { style } : undefined
  )

  if (!children) {
    return null
  }

  return (
    <ReactModal
      overlayClassName={overlayClass}
      isOpen={isOpen}
      className={contentClass}
      contentLabel={contentLabel}
      {...modalProps}
    >
      {children}
    </ReactModal>
  )
}

Modal.defaultProps = {
  contentLabel: '',
  isOpen: false,
}

export default Modal
