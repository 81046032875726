// @flow

import React, { Suspense, lazy } from 'react'

const FlatClassifier = lazy(() => import('./FlatClassifier'))

const FlatClassifierLazy = props => (
  <Suspense fallback={null}>
    <FlatClassifier {...props} />
  </Suspense>
)

export default FlatClassifierLazy
