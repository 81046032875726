// @flow

import { put, fork, takeLatest, select, call } from 'redux-saga/effects'
import { isEmpty } from 'lodash-es'

import * as actions from './RequestFeedback.actions'
import { feedbackWasLoaded } from '../../../containers/Feedback/Feedback.actions'
import api from '../../../core/api'

const getRequestId = state => state.requestView.data.id

function* watchLoadFeedbackList() {
  yield takeLatest(actions.LOAD_FEEDBACK_LIST, loadFeedbackList)
}

function* loadFeedbackList() {
  try {
    const requestId = yield select(getRequestId)
    const data = yield call(api.feedback.getFeedbackList, {
      requests: requestId,
    })

    if (!isEmpty(data.results.objects)) {
      yield put(feedbackWasLoaded(data.results.objects[0]))
    }

    yield put(actions.feedbackListWasLoaded(data))
  } catch (error) {
    yield put(actions.feedbackListWasNotLoaded())
  }
}

export default function* () {
  yield fork(watchLoadFeedbackList)
}
