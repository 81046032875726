import React from 'react'

import { newLineToBr } from '../../../../utils/text'

const HideableMessagePart = ({ text }) => {
  const msgText = { __html: newLineToBr(text) }

  return (
    <span dangerouslySetInnerHTML={msgText} />
  )
}

export default HideableMessagePart
