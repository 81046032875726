// @flow

import React from 'react'
import classnames from 'classnames'

import { isVideoFile } from '../../../utils/file'
import Icon from '../../Icon'

import { getPreviewSrc, needFitContainPreview } from './DocumentsView.utils'
import styles from './DocumentsView.module.scss'

type Props = {
  data: Object,
  onClick: () => void,
  thumbnailSize: number
}

const DocumentGreatPreview = (props: Props) => {
  const { data, thumbnailSize } = props

  const src = getPreviewSrc(data, thumbnailSize)
  const fitContain = needFitContainPreview(data)
  const isVideo = isVideoFile(data)

  const greatClass = classnames(styles.great, {
    [styles.video]: isVideo && !src
  })

  return (
    <div
      className={greatClass}
      onClick={props.onClick}
    >
      {src && (
        <img
          alt={data.filename}
          className={fitContain ? styles.contain : null}
          src={src}
        />
      )}
      {isVideo && (
        <Icon
          className={styles.play}
          id='play'
        />
      )}
    </div>
  )
}

export default DocumentGreatPreview
