// @flow

import React from 'react'
import classnames from 'classnames'
import type { Node } from 'react'

import styles from './ModalCell.module.scss'

type Props = {
  children: Node,
  gridNumber: number,
}

const ModalCell = (props: Props): Node => {
  const { gridNumber, children } = props

  const cellClass = classnames(styles.cell, styles[`cell_${gridNumber}`])

  return <div className={cellClass}>{children}</div>
}

ModalCell.defaultProps = {
  gridNumber: 1,
}

export default ModalCell
