// @flow

import React from 'react'
import type { Node } from 'react'

import Icon from '../../static/icons/user-entity.svg'

const Avatar = (): Node => (
  <div className='avatar-default'>
    <Icon />
  </div>
)

export default Avatar
