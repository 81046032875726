// @flow

import React, { Component } from 'react'
import type { Node } from 'react'
import { Scrollbars } from 'react-custom-scrollbars'

type Props = {
  dropdown?: boolean,
  height?: number,
  items: Array<Object>,
  listClassName: string,
  listElements: Array<Object>,
  pageSize: number,
  position?: number,
}
type State = {
  height: number,
  position: number,
}

class Scrollbar extends Component<Props, State> {
  static defaultProps = {
    dropdown: true,
    pageSize: 5,
  }

  state = {
    height: this.props.height,
    position: 0,
  }

  updateHeight = (elem: Node) => {
    if (!elem) {
      return
    }

    const { height, pageSize } = this.props
    const { children } = elem

    // Hack for files yet scan for viruses
    let counter = 0

    children.forEach(item => {
      if (item.offsetHeight > 0) {
        counter++
      }
    })

    if (!height && counter > pageSize) {
      this.setState({
        height: pageSize * children[0].offsetHeight,
      })
    }
  }

  render() {
    const {
      pageSize,
      items,
      listElements,
      listClassName,
      dropdown,
    } = this.props

    const { height } = this.state

    const scrollbarsProps = {
      renderTrackHorizontal: () => <div className='scrollbars-horizontal' />,
      renderTrackVertical: props => (
        <div {...props} className='scrollbar__track' />
      ),
      renderThumbVertical: props => (
        <div {...props} className='scrollbar__thumb' />
      ),
    }
    const className = dropdown
      ? 'dropdown__wrapper select__scrollbar-wrapper'
      : ''

    // Hack for files yet scan for viruses
    const filteredItems = items.filter(
      item =>
        !(item.message && item.message === 'File scan for viruses in progress')
    )

    return (
      <div className={className}>
        {filteredItems.length > pageSize ? (
          <Scrollbars
            {...scrollbarsProps}
            ref={scrollBar => {
              if (scrollBar) {
                this.scrollBar = scrollBar
              }
            }}
            onUpdate={() => {
              const { position } = this.props

              if (position !== 0 && this.state.position === 0) {
                this.scrollBar.scrollTop(position)
                this.setState({ position })
              }
            }}
          >
            <ul
              style={{ height: `${height}px` }}
              className={listClassName}
              ref={this.updateHeight}
            >
              {listElements}
            </ul>
          </Scrollbars>
        ) : (
          <ul className={listClassName}>{listElements}</ul>
        )}
      </div>
    )
  }
}

export default Scrollbar
