// @flow

import React from 'react'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import { useSelector } from 'react-redux'

import { getUser } from '../../../../utils/commonSelectors'
import { isAdminUser } from '../../../../utils/utils'
import NewTableBody from '../../../../components/NewTable/NewTableBody'

import AllProvidersTableBodyRow from './AllProvidersTableBodyRow'

const AllProvidersTableBody = props => {
  const { providers, selectedItems } = props

  const dispatch = useDispatch()

  const user = useSelector(getUser)

  const isAdmin = isAdminUser(user)

  const handleOpen = id => dispatch(push(`/marketplace/provider/${id}`))

  return (
    <NewTableBody>
      {providers.map(provider => (
        <AllProvidersTableBodyRow
          isAdmin={isAdmin}
          removeProviders={props.removeProviders}
          selectedItems={selectedItems}
          handleChange={props.toggleProvider(provider.global_id)}
          key={provider.global_id}
          provider={provider}
          setBuildingModal={props.setBuildingModal}
          setCategoryModal={props.setCategoryModal}
          updateProviders={props.updateProviders}
          onOpen={handleOpen}
        />
      ))}
    </NewTableBody>
  )
}

export default AllProvidersTableBody
