// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import ConfirmationPopup from '../../modals/ConfirmationPopup'
import Modal from '../../Modal'

type Props = {
  handleRemove: Function,
  hideModal: Function,
  isMassRemoval?: boolean,
}

const PipelineRemovalModal = (props: Props): Node => {
  const { isMassRemoval } = props

  const { t } = useTranslation('Pipeline')

  let confirm = 'PipelineRemoveButton'
  let title = 'PipelineRemoveTitle'
  let text = 'ConfirmPipelineRemoving'

  if (isMassRemoval) {
    confirm = 'PipelinesRemoveButton'
    title = 'PipelinesRemoveTitle'
    text = 'ConfirmPipelinesRemoving'
  }

  return (
    <Modal isOpen onRequestClose={props.hideModal}>
      <ConfirmationPopup
        confirm={t(confirm)}
        title={t(title)}
        text={t(text)}
        onClose={props.hideModal}
        onOk={props.handleRemove}
      />
    </Modal>
  )
}

export default PipelineRemovalModal
