// @flow

import React, { useEffect } from 'react'
import type { Node } from 'react'

import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'

import * as Yup from 'yup'
import { partial } from 'lodash-es'

import InputField from '../../../InputField'
import Button from '../../../Button'
import Modal from '../../../Modal'
import ModalBody from '../../../Modal/ModalBody'
import ModalButtons from '../../../Modal/ModalButtons'
import ModalCloseButton from '../../../Modal/ModalCloseButton'
import ModalHead from '../../../Modal/ModalHead'
import ModalRow from '../../../Modal/ModalRow'
import ModalCell from '../../../Modal/ModalCell'
import ModalLabel from '../../../Modal/ModalLabel'
import NewSelectSimple from '../../../NewSelectSimple'
import { get } from 'lodash-es'
import {
  createSalutation,
  updateSalutation,
} from '../../../../core/api/api.pipeline'

import { useOverflow } from '../../../../hooks/useOverflow'

import styles from '../SalutationModal.module.scss'
import ModalError from '../../../Modal/ModalError'

type Props = {
  editingSalutation: Object,
  load: () => void,
  onClose: () => void,
  setEditingSalutation: (Salutation: Object) => void,
}

const SalutationModalChange = ({
  onClose,
  load,
  editingSalutation,
  setEditingSalutation,
}: Props): Node => {
  const { t } = useTranslation('Salutation')
  const { salutation_object_type_enum: objectType } = useSelector(
    state => state.init
  )
  const { id, salutation, object_type } = editingSalutation || {}

  useOverflow()
  useEffect(() => {
    return function cleanup() {
      setEditingSalutation(null)
    }
  }, [])

  const handleClose = () => {
    handleReset()
    onClose()
  }

  const {
    values,
    errors,
    dirty,
    setFieldValue,
    handleReset,
    handleSubmit,
    handleChange,
    setErrors,
  } = useFormik({
    validateOnChange: false,
    initialValues: {
      salutation: salutation || '',
      object_type: object_type || '',
    },
    validationSchema: Yup.object({
      salutation: Yup.string()
        .max(128)
        .test(
          'empty-check',
          'not empty',
          salutation => salutation?.trim() !== ''
        )
        .required(),
      object_type: Yup.string().required(),
    }),
    onSubmit: submitValues => {
      const api = id ? partial(updateSalutation, id) : createSalutation
      api(submitValues)
        .then(() => {
          onClose()
          load()
        })
        .catch(error => {
          setErrors({
            salutation: get(error, ['message', 'response', 'data', 'errors']),
          })
        })
    },
  })

  const objectTypeOptions = objectType.map(type => ({
    value: type,
    label: t(type),
  }))
  const objectTypeOption = objectTypeOptions.find(
    typeOption => typeOption.value === values.object_type
  )

  const handleChangeObjectType = option =>
    setFieldValue('object_type', option.value)

  return (
    <Modal isOpen className={styles.modal} onRequestClose={onClose}>
      <ModalHead title={t('AddSalutation')} />
      <ModalCloseButton onClose={onClose} />
      <form onSubmit={handleSubmit}>
        <ModalBody>
          <ModalRow className={styles.row}>
            <ModalCell>
              <ModalLabel className={styles.label} htmlFor='salutation'>
                {t('SalutationName')}
              </ModalLabel>
              <InputField
                autoFocus
                value={values.salutation}
                name='salutation'
                placeholder={t('SalutationNamePlaceholder')}
                error={!!errors['salutation']}
                showErrorText={false}
                onChange={handleChange}
              />
              {errors['salutation'] && (
                <ModalError
                  style={{ marginTop: '12px' }}
                  error={t(errors['salutation'])}
                />
              )}
            </ModalCell>
          </ModalRow>
          <ModalRow className={styles.row}>
            <ModalCell>
              <ModalLabel className={styles.label} htmlFor='object_type'>
                {t('ProfileObjectType')}
              </ModalLabel>
              <NewSelectSimple
                className={styles.select}
                name='object_type'
                options={objectTypeOptions}
                value={objectTypeOption}
                placeholder={t('ProfileObjectTypePlaceholder')}
                error={!!errors['object_type']}
                onChange={handleChangeObjectType}
              />
            </ModalCell>
          </ModalRow>
        </ModalBody>
        <ModalButtons>
          <Button.Save type='submit' disabled={!dirty}>
            {t('Common:Add')}
          </Button.Save>
          <Button.Cancel disabled={!dirty} onClick={handleClose}>
            {t('Common:Cancel')}
          </Button.Cancel>
        </ModalButtons>
      </form>
    </Modal>
  )
}

export default SalutationModalChange
