//@flow

import React, { useState, useEffect, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import classnames from 'classnames'
import { every } from 'lodash-es'

import Block, { Header, CellsBar, Cell } from '../../../components/Block'
import Button from '../../../components/Button'
import Modal from '../../../components/Modal'
import ConfirmationPopup from '../../../components/modals/ConfirmationPopup'
import HeaderIcon from '../../../../static/icons/google-drive.svg'
import Settings from '../../../components/Settings'

import Icon from '../../../components/Icon'
import IconFolderError from '../../../../static/icons/icon-folder-error.svg'
import IconFileText from '../../../../static/icons/file-text.svg'

import Loader from '../../../components/Loader'

import { formatDateTime } from '../../../utils/utils'

import {
  createGoogleConnection,
  updateGoogleConnection,
  getGoogleConnection,
  refreshGoogleTemplates,
} from '../../../core/api/api.docTemplates'
import { modalError } from '../../../components/Layout/Layout.actions'

import styles from '../DocumentTemplates.module.scss'

const CONNECTION_STATUS = {
  OK: 'ok',
  FAIL: 'fail',
  IN_PROGRESS: 'in_progress',
  EMPTY: 'empty',
}

const ERRORS = {
  CONNECTION: 'Google connection check failed',
}

const FOLDER_STATUS = {
  OK: 'ok',
  EMPTY: 'empty',
  NOT_FOUND: 'not_found',
  ERROR: 'error',
  EXCEEDED: 'file_size_exceeded',
  ALL_EXCEEDED: 'all_files_size_exceeded',
  UNKNOWN: 'unknown',
}

const FOLDERS_COUNT = 3

const HIDDEN_KEY = '**********************************'

const POLLING_INTERVAL = 1000

const GoogleDrive = () => {
  const { t } = useTranslation('DocumentTemplates')
  const dispatch = useDispatch()

  const [collapsed, setCollapsed] = useState(false)
  const [working, setWorking] = useState(false)
  const [init, setInit] = useState(false)
  const [initialFields, setInitialFields] = useState({
    secret_key: '',
    private_storage_link: '',
    request_storage_link: '',
    mass_emailing_storage_link: '',
  })
  const [fields, setFields] = useState(initialFields)
  const [lastSync, setLastSync] = useState(null)
  const [status, setStatus] = useState(null)
  const [foldersStatus, setFoldersStatus] = useState([])
  const [modal, setModal] = useState(null)
  const [hint, setHint] = useState(false)

  const handleMouseEnter = () => setHint(true)
  const handleMouseLeave = () => setHint(false)

  useEffect(() => {
    if (!init && !collapsed && !working) {
      getConnection()
    }
  }, [collapsed])

  useEffect(() => {
    setFields(initialFields)
  }, [initialFields])

  const onClickTitle = () => {
    setCollapsed(!collapsed)
  }

  const getRowsClassName = () =>
    classnames({
      'working-overlay': working,
    })

  const showModal = (title, text, val = null) => {
    setModal(
      <ConfirmationPopup
        title={t(title)}
        text={t(text, { fields: val })}
        cancel={t('OkButton')}
        showOk={false}
        onClose={hideModal}
      />
    )
  }

  const hideModal = () => setModal(null)

  const renderStatus = () => {
    const statusText =
      Object.values(CONNECTION_STATUS).indexOf(status) !== -1
        ? `Status.${status}`
        : 'Status.empty'

    const className = classnames('bar__cell-value', {
      'value-progress': status === CONNECTION_STATUS.IN_PROGRESS,
      'value-done': status === CONNECTION_STATUS.OK,
    })

    return (
      <Cell title={t('Status')} valueClassName={className}>
        {t(statusText)}
      </Cell>
    )
  }

  const getFolderStatus = folder_type => {
    const [folder] = foldersStatus.filter(f => f.folder_type === folder_type)

    return folder?.status
  }

  const getInputSettingsProps = (field, folder_type = null) => {
    let icon = null,
      note = null

    if (folder_type) {
      const folder_status = getFolderStatus(folder_type)

      if (
        [FOLDER_STATUS.NOT_FOUND, FOLDER_STATUS.ERROR].includes(folder_status)
      ) {
        icon = <IconFolderError />
        note = t(`FolderStatus.${folder_status}.Note`)
      }

      if (
        [
          FOLDER_STATUS.EMPTY,
          FOLDER_STATUS.EXCEEDED,
          FOLDER_STATUS.ALL_EXCEEDED,
        ].includes(folder_status)
      ) {
        icon = <IconFileText />
        note = t(`FolderStatus.${folder_status}.Note`)
      }
    }

    return {
      field,
      label: t(field),
      icon,
      note,
      withNotes: true,
    }
  }

  const updateField = (key, value) => {
    setFields({ ...fields, [key]: value })
  }

  const updateInput = ({ target: { name, value } }: Object) => {
    updateField(name, value)
  }

  const checkHiddenKey = ({ target: { name, value } }: Object) => {
    if (value === HIDDEN_KEY) {
      updateField(name, '')
    }
  }

  const checkApiKey = secret_key => {
    if (secret_key === HIDDEN_KEY) {
      return true
    }

    try {
      JSON.parse(secret_key)

      return true
    } catch {
      return false
    }
  }

  const getConnection = (creating = false) => {
    setWorking(true)
    getGoogleConnection()
      .then(data => {
        if (data.errors) {
          setWorking(false)

          return
        }

        const {
          last_connected,
          private_storage_link,
          request_storage_link,
          mass_emailing_storage_link,
          folders,
        } = data
        setStatus(data.status || CONNECTION_STATUS.EMPTY)
        setFoldersStatus(data.folders || [])
        setLastSync(last_connected)
        setInitialFields({
          secret_key: last_connected ? HIDDEN_KEY : '',
          private_storage_link,
          request_storage_link,
          mass_emailing_storage_link,
        })
        switch (data.status) {
          case CONNECTION_STATUS.OK:
            if (creating) {
              checkFoldersStatus(folders)
            } else {
              setWorking(false)
            }

            break
          case CONNECTION_STATUS.IN_PROGRESS:
            setTimeout(() => {
              getConnection(creating)
            }, POLLING_INTERVAL)
            break
          case CONNECTION_STATUS.FAIL:
            if (creating && data.error === ERRORS.CONNECTION) {
              showModal('ErrorConnectionTitle', 'ErrorConnectionText')
            }

            setWorking(false)
            break
        }
      })
      .catch(() => {
        if (creating) {
          showModal('ErrorConnectionTitle', 'ErrorConnectionText')
        }

        setWorking(false)
      })
      .finally(() => {
        setInit(true)
      })
  }

  const checkFoldersStatus = folders => {
    let status_folders = {}
    folders.forEach(f => {
      if (!status_folders[f.status]) {
        status_folders[f.status] = [f.folder_type]
      } else {
        status_folders[f.status].push(f.folder_type)
      }
    })

    if (status_folders[FOLDER_STATUS.OK]?.length === FOLDERS_COUNT) {
      showModal('SuccessTitle', 'SuccessText')
      setWorking(false)
    } else if (status_folders[FOLDER_STATUS.UNKNOWN]?.length) {
      setTimeout(() => {
        getConnection(true)
      }, POLLING_INTERVAL)

      return
    } else {
      for (let status in FOLDER_STATUS) {
        if (
          ![FOLDER_STATUS.OK, FOLDER_STATUS.UNKNOWN].includes(
            FOLDER_STATUS[status]
          ) &&
          status_folders[FOLDER_STATUS[status]]
        ) {
          showModal(
            `FolderStatus.${FOLDER_STATUS[status]}.Title`,
            `FolderStatus.${FOLDER_STATUS[status]}.Text`,
            status_folders[FOLDER_STATUS[status]]
              .map(f => t(`${f}_storage_link`))
              .join(', ')
          )
          setWorking(false)

          return
        }
      }
    }
  }

  const save = () => {
    if (!checkApiKey(fields.secret_key)) {
      showModal('ErrorTitle', 'ErrorKeyFormat')

      return
    }

    setWorking(true)

    const {
      secret_key,
      private_storage_link,
      request_storage_link,
      mass_emailing_storage_link,
    } = fields

    let params = {
      private_storage_link,
      request_storage_link,
      mass_emailing_storage_link,
    }

    if (secret_key !== HIDDEN_KEY) {
      const secretKeyObj = JSON.parse(secret_key)
      params.secret_key = secretKeyObj
    }

    const api =
      secret_key === HIDDEN_KEY
        ? updateGoogleConnection
        : createGoogleConnection

    api(params)
      .then(() => {
        getConnection(true)
      })
      .catch(error => {
        if (error.message?.response?.status === 400) {
          dispatch(
            modalError(
              error.message?.response?.data || t('ErrorConnectionTitle')
            )
          )
        }

        setWorking(false)
      })
  }

  const cancel = () => {
    setFields(initialFields)
  }

  const update = () => {
    setWorking(true)
    refreshGoogleTemplates().then(() => {
      getConnection(true)
    })
  }

  const renderFields = () => {
    return (
      <Fragment>
        <div className={getRowsClassName()}>
          <Settings.Row {...getInputSettingsProps('secret_key')}>
            <input
              autoComplete='off'
              autoCorrect='off'
              name='secret_key'
              value={fields.secret_key}
              spellCheck={false}
              disabled={working}
              onChange={updateInput}
              onFocus={checkHiddenKey}
            />
          </Settings.Row>

          <Settings.Row
            {...getInputSettingsProps('private_storage_link', 'private')}
          >
            <input
              autoComplete='off'
              autoCorrect='off'
              name='private_storage_link'
              value={fields.private_storage_link}
              spellCheck={false}
              disabled={working}
              onChange={updateInput}
            />
          </Settings.Row>

          <Settings.Row
            {...getInputSettingsProps('request_storage_link', 'request')}
          >
            <input
              autoComplete='off'
              autoCorrect='off'
              name='request_storage_link'
              value={fields.request_storage_link}
              spellCheck={false}
              disabled={working}
              onChange={updateInput}
            />
          </Settings.Row>

          <Settings.Row
            {...getInputSettingsProps(
              'mass_emailing_storage_link',
              'mass_emailing'
            )}
          >
            <input
              autoComplete='off'
              autoCorrect='off'
              name='mass_emailing_storage_link'
              value={fields.mass_emailing_storage_link}
              spellCheck={false}
              onChange={updateInput}
            />
          </Settings.Row>
        </div>
      </Fragment>
    )
  }

  const renderActions = () => {
    const sameFields = every(fields, (val, key) => initialFields[key] === val)

    return (
      <Settings.Actions
        withChanges={!sameFields}
        additional={
          <Button.Regular
            working={working}
            disabled={!status || status === CONNECTION_STATUS.EMPTY}
            onClick={update}
          >
            {t('UpdateTemplates')}
          </Button.Regular>
        }
        working={working}
        onCancel={cancel}
        onSave={save}
      />
    )
  }

  const renderContent = () => {
    if (collapsed) {
      return null
    }

    if (!init && working) {
      return <Loader text={false} type='medium' />
    }

    return (
      <Fragment>
        <CellsBar>
          <Cell title={t('LastSync')}>
            {lastSync ? formatDateTime(lastSync) : '-'}
          </Cell>
          {renderStatus()}
        </CellsBar>

        <div className='settings-personal__form'>{renderFields()}</div>

        <div className='bar__delimiter' />
        {renderActions()}
      </Fragment>
    )
  }

  return (
    <Fragment>
      <Block
        working={working}
        collapsed={collapsed}
        onClickHeader={onClickTitle}
      >
        <Header header>
          <span icon='true' className={styles.headerIcon}>
            <HeaderIcon />
          </span>
          {t('GoogleDrive')}
          {status === CONNECTION_STATUS.FAIL && (
            <span
              extra='true'
              className={styles.noteIcon}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <Icon id='danger' />
              {hint && (
                <div className={styles.tooltip}>
                  <div />
                  <span>{t('ErrorConnectionNote')}</span>
                </div>
              )}
            </span>
          )}
        </Header>
        {renderContent()}
      </Block>
      <Modal
        contentLabel=''
        className='Modal__Bootstrap modal-dialog'
        isOpen={!!modal}
        onRequestClose={hideModal}
      >
        {modal}
      </Modal>
    </Fragment>
  )
}

export default GoogleDrive
