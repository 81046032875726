// @flow

import React from 'react'
import type { Node } from 'react'
import { withTranslation } from 'react-i18next'
import Select from 'react-select'
import classnames from 'classnames'
import { clone, filter, findIndex, isArray } from 'lodash-es'

import Button from '../../Button'
import Loader from '../../Loader'
import Menu from './Menu'
import ValueItem from '../ValueItem'
import * as actions from './SelectAsync.actionTypes'

export type Props = {
  apiFn: string,
  apiFnParams?: Object,
  btnPlaceholder?: string,
  className?: string,
  cleanable?: boolean,
  data: Array<Object>,
  disabled?: boolean,
  dispatch: Object => void,
  EmptyMenu?: Node,
  explicitLoading?: boolean,
  filterAfter?: number,
  filterKey?: string,
  fullWidth?: boolean,
  hideMultiValues?: boolean,
  id: string,
  ignoredOptions?: Array<Object>,
  initialValueId?: ?any,
  inputError?: boolean,
  isUserTable?: boolean,
  labelKey: string,
  lang: string,
  margin?: boolean,
  modal?: boolean,
  monitor?: number | string,
  multi?: boolean,
  onBlurResetsInput?: boolean,
  onChange: (?any) => void,
  onClickAll?: boolean => void,
  onInputBtnChange?: string => void,
  order?: boolean,
  pageSize: number,
  params?: Object,
  placeholder: string,
  profile?: boolean,
  round?: boolean,
  searchRequest?: boolean,
  showStatus?: boolean,
  t: string => string,
  valueKey?: string,
  volatileOptions?: boolean,
  withDisableOnLoad?: boolean,
  withFilter?: boolean,
}

class SelectAsync extends Select {
  static defaultProps = {
    ...Select.defaultProps,
    backspaceRemoves: false,
    cleanable: false,
    filterAfter: 3,
    ignoredOptions: [],
    onBlurResetsInput: true,
    openOnFocus: true,
    pageSize: 5,
    params: {},
    placeholder: 'Placeholder',
    valueKey: 'id',
  }

  componentDidMount() {
    const { multi, disabled, initialValueId } = this.props

    if (this.props.onClickAll && !multi) {
      throw new Error(
        'You can define onClickAll function only with multi prop!'
      )
    }

    if (initialValueId === 'all' && !this.props.onClickAll) {
      throw new Error(
        'You can set "all" as initial value only if onClickAll is defined!'
      )
    }

    if (!disabled && initialValueId === 'all') {
      this.setState({ allSelected: true })
    }

    if (!this.props.disabled) {
      this.init()
    }
  }

  componentDidUpdate(pProps: Props, pState) {
    const { monitor, volatileOptions, dispatch, id } = this.props
    const { valueInitiated } = this.getData(pProps)

    const { modal, multi, isUserTable } = pProps

    if (!valueInitiated && this.getData(this.props).valueInitiated) {
      // TODO Check isUserTable
      if (modal && !isUserTable) {
        this.setValue(multi ? [] : null)
      }
    }

    if (pProps.disabled && !this.props.disabled) {
      this.init()

      return
    }

    if (monitor !== pProps.monitor) {
      this.setState({ inputValue: '' })
      this.complexReset()
      this.init()

      return
    }

    const { isOpen, isFocused, inputValue } = this.state

    if (pState.isOpen && !isOpen) {
      if (volatileOptions) {
        this.resetOptions()
      }

      if (isFocused) {
        this.input.blur()
      }

      return
    }

    if (!pState.isOpen && isOpen) {
      this.focus()

      if (volatileOptions) {
        this.loadOptions()
      }

      return
    }

    if (pState.inputValue !== inputValue) {
      const { filterAfter } = this.props
      const backspaced = pState.inputValue.length > inputValue.length
      const removed = !inputValue && pState.inputValue.length > 1

      if (inputValue && inputValue.length > filterAfter - 1) {
        dispatch({
          type: actions.SELECT_ASYNC_CHANGE_FILTER,
          key: id,
        })
        this.loadOptions()
      } else if (
        removed ||
        (backspaced && inputValue.length === filterAfter - 1)
      ) {
        this.loadOptions(false, true)
      }
    }
  }

  componentWillUnmount() {
    this.reset()
  }

  onInputBlur = e => {
    if (document.activeElement === this.input) {
      return
    }

    const { inputValue } = this.state
    const { filterAfter } = this.props

    if (inputValue && inputValue.length > filterAfter - 1) {
      this.loadOptions(false, true)
    }

    this.handleInputBlur(e)
  }

  onInputBtnChange = e => {
    const { onInputBtnChange } = this.props
    const { value } = this.getData()

    if (value) {
      this.removeValue()
    }

    onInputBtnChange(e.target.value)

    this.handleInputChange(e)
  }

  getData = props => (props || this.props).data[this.props.id] || {}

  getLabel = opt => {
    if (opt.all) {
      return opt.label
    }

    const { searchRequest, labelKey } = this.props

    if (searchRequest) {
      return this.getRequestText(opt)
    }

    if (labelKey === 'address') {
      return opt.address || opt.address_obj.value
    }

    return opt[labelKey]
  }

  getRequestText = (opt: Object) => {
    const { lang, labelKey, t } = this.props
    const title = lang === 'ru' ? `${t('Request')} ` : ''
    const num = `${title}${t('Common:NumberSymbol')}${opt.request_no}: `

    return `${num}${opt[labelKey]}`
  }

  getValueText = (value?: Object) => {
    if (!value) {
      return ''
    }

    if (value.request_no) {
      return this.getRequestText(value)
    }

    return this.getLabel(value)
  }

  getBtnValueText = () => {
    const { btnPlaceholder, placeholder, t } = this.props
    const { value } = this.getData()

    const isArr = isArray(value)

    if (!isArr && value) {
      return this.getValueText(value)
    }

    if (this.state.allSelected) {
      return t('all')
    }

    return t(btnPlaceholder || placeholder)
  }

  getFilterValue = () => {
    const { inputValue: val } = this.state
    const str = val.substring(0, 1) === '№' ? val.substring(1) : val

    return str || null
  }

  setValue = value => {
    const { id, onChange } = this.props
    this.props.dispatch({
      type: actions.SELECT_ASYNC_SET_VALUE,
      value,
      key: id,
    })
    onChange(value)
  }

  isSelected = (value, opt) => {
    const { inputValue, allSelected } = this.state

    if (allSelected) {
      return true
    }

    const { valueKey, labelKey, onInputBtnChange } = this.props

    if (onInputBtnChange && inputValue) {
      return inputValue === opt[labelKey].toString()
    }

    if (isArray(value)) {
      return findIndex(value, o => o[valueKey] === opt[valueKey]) > -1
    }

    return value ? value[valueKey] === opt[valueKey] : false
  }

  props: Props

  init = () => {
    const {
      initialValueId,
      multi,
      id,
      cleanable,
      apiFn,
      apiFnParams,
      initAll,
      params,
      volatileOptions,
      withDisableOnLoad,
    } = this.props

    this.props.dispatch({
      type: actions.SELECT_ASYNC_INITIATING,
      apiFn,
      valueId: initialValueId !== 'all' ? initialValueId : null,
      params,
      cleanable,
      apiFnParams,
      multi,
      key: id,
      initOptions: !volatileOptions,
      withDisableOnLoad,
      all: initAll ? initAll : null,
    })
  }

  reset = (props = {}) => {
    const { id } = this.props
    this.props.dispatch({ type: actions.SELECT_ASYNC_RESET, key: id, ...props })
  }

  complexReset = () => {
    const { withDisableOnLoad } = this.props
    this.reset({ withDisableOnLoad })
  }

  resetOptions = () => {
    const { id, dispatch } = this.props
    dispatch({ type: actions.SELECT_ASYNC_RESET_OPTIONS, key: id })
  }

  loadOptions = (scrolling = false, reset = false) => {
    const {
      meta: { curr_page: page, page_count: count },
    } = this.getData()
    const { filterAfter } = this.props

    if (scrolling && page === count) {
      return
    }

    const { filterKey, labelKey, apiFn, id, apiFnParams, params } = this.props
    const { inputValue } = this.state

    this.props.dispatch({
      type: actions.SELECT_ASYNC_LOADING,
      params: {
        ...params,
        page: scrolling ? page + 1 : 1,
        [filterKey || labelKey]:
          reset || inputValue.length < filterAfter
            ? null
            : this.getFilterValue(),
      },
      apiFn,
      key: id,
      apiFnParams,
    })
  }

  toggleAll = (blur = true) => {
    const { onClickAll } = this.props
    const { allSelected } = this.state

    if (!onClickAll) {
      throw new Error(
        "It's impossible to select all values while onClickAll prop is undefined!"
      )
    }

    this.setState(
      {
        allSelected: !allSelected,
        isOpen: !blur,
        inputValue: '',
      },
      () => {
        this.setValue([])
        onClickAll(!allSelected)
      }
    )
  }

  prepare = arr => {
    if (Array.isArray(arr)) {
      const { order, multi, valueKey, initialValueId } = this.props

      if (multi && order && Array.isArray(initialValueId)) {
        return arr.sort((a, b) =>
          initialValueId.indexOf(a[valueKey]) >
            initialValueId.indexOf(b[valueKey]) ||
          !~initialValueId.indexOf(a[valueKey])
            ? 1
            : -1
        )
      } else {
        return arr
      }
    } else {
      return arr
    }
  }

  selectValue = opt => {
    const { multi, valueKey } = this.props

    if (opt.all) {
      this.toggleAll()

      return
    }

    const { value } = this.getData()
    const needReset = !!this.state.inputValue
    const resetOptions = () => {
      if (needReset) {
        this.loadOptions()
      }
    }

    if (multi) {
      if (this.state.allSelected) {
        this.toggleAll(false)
      }

      const singleSelected = filter(value, { single: true }).length
      const selectSingle = opt.single && value && value.length
      this.setState(
        {
          isOpen: !selectSingle && value,
          inputValue: '',
          focusedIndex: null,
        },
        () => {
          const unselect =
            findIndex(value, o => o[valueKey] === opt[valueKey]) > -1

          if (unselect) {
            this.removeValue(opt)
          } else if (selectSingle || singleSelected) {
            this.setValue([opt])
          } else {
            this.addValue(opt)
          }

          resetOptions()
        }
      )
    } else {
      this.setState(
        state => ({
          isOpen: false,
          inputValue: '',
          isPseudoFocused: state.isFocused,
        }),
        () => {
          this.setValue(opt)
          resetOptions()
        }
      )
    }
  }

  addValue = opt => {
    const { value } = this.getData()
    this.setValue(value ? value.concat(opt) : [opt])
  }

  removeValue = opt => {
    const { valueKey, multi, cleanable } = this.props
    const { value } = this.getData()

    if (multi) {
      if (!cleanable && value.length === 1) {
        return
      }

      this.setValue(filter(value, o => o[valueKey] !== opt[valueKey]))
    } else {
      this.setValue(null)
    }
  }

  handleMenuScroll = e => {
    const { scrollHeight, offsetHeight, scrollTop } = e.target
    const { id } = this.props
    const { loading, menuLoader } = this.getData()

    const scrolled = offsetHeight + scrollTop

    if (!loading && scrollHeight / 2 <= scrolled) {
      this.loadOptions(true)
    }

    const allScrolled = scrollHeight <= scrolled

    if (!menuLoader && loading && allScrolled) {
      this.props.dispatch({
        type: actions.SELECT_ASYNC_SHOW_MENU_LOADER,
        key: id,
      })
    }
  }

  renderLoader = () => {
    const { modal } = this.props

    return (
      <Loader
        className='select-async-loader'
        text={!modal}
        type={modal ? 'medium' : 'small'}
      />
    )
  }

  renderValue = value => {
    const { cleanable, round, profile, valueKey } = this.props

    return (
      <div key={`value-${value[valueKey]}`}>
        <ValueItem
          avatar={value.avatar}
          cleanable={cleanable}
          profile={profile}
          round={round}
          text={value.fullname}
          onRemove={() => this.removeValue(value)}
        />
      </div>
    )
  }

  renderInputBtn = () => {
    const { inputError } = this.props
    const { inputValue, isFocused } = this.state
    const { value } = this.getData()
    const className = classnames({
      'input-with-placeholder': !isFocused && !inputValue && !value,
      'input--error': inputError,
    })

    return (
      <Button.SelectInput
        {...this.props}
        className={className}
        text={inputValue || this.getValueText(value)}
        onRef={ref => {
          this.input = ref
        }}
        onBlur={this.onInputBlur}
        onChange={this.onInputBtnChange}
        onFocus={this.handleInputFocus}
      />
    )
  }

  renderButton = () => (
    <Button.Select
      {...this.props}
      text={this.getBtnValueText()}
      value={this.getData().value}
      onRef={ref => {
        this.input = ref
      }}
      onBlur={this.onInputBlur}
      onClick={this.handleInputFocus}
      onRemove={this.removeValue}
    />
  )

  renderMenu = () => {
    const {
      valueKey,
      filterAfter,
      modal,
      onInputBtnChange,
      onClickAll,
      showStatus,
    } = this.props

    const {
      loading,
      value,
      options: items,
      menuLoader,
      valueInitiated,
    } = this.getData()

    const { inputValue } = this.state

    let options = clone(items)

    if (options && inputValue.length < filterAfter && value) {
      options = options.filter(o => {
        if (isArray(value)) {
          return !value.find(v => o[valueKey] === v[valueKey])
        }

        return o[valueKey] !== value[valueKey]
      })

      if (isArray(value)) {
        options = [...value, ...options]
      } else {
        options.unshift(value)
      }
    }

    // TODO undefined has normal type in normal browsers
    // const ignoreFilter = withFilter === 'undefined'
    //   ? !!onInputBtnChange
    //   : !withFilter

    return (
      <Menu
        {...this.props}
        showStatus={showStatus}
        disabled={!valueInitiated}
        fetching={(menuLoader && !modal) || loading}
        filterText={inputValue}
        getLabel={this.getLabel}
        ignoreFilter={!!onInputBtnChange}
        isSelected={opt => this.isSelected(value, opt)}
        options={options}
        renderLoader={this.renderLoader}
        onClick={this.handleMouseDownOnMenu}
        onClickAll={inputValue ? null : onClickAll}
        onInputBlur={this.onInputBlur}
        onInputChange={this.handleInputChange}
        onInputFocus={this.handleInputFocus}
        onInputRef={ref => {
          this.input = ref
        }}
        onRef={ref => {
          this.menu = ref
        }}
        onSelect={this.selectValue}
        onScroll={this.handleMenuScroll}
      />
    )
  }

  render() {
    const {
      modal,
      multi,
      margin,
      className,
      onInputBtnChange,
      explicitLoading,
      disabled,
      hideMultiValues,
      withDisableOnLoad,
    } = this.props
    const { valueInitiated, value, loading, meta } = this.getData()

    if (explicitLoading && !valueInitiated) {
      return this.renderLoader()
    }

    const valueArray = this.getValueArray(this.prepare(value))
    const selectClass = classnames('select-user', className, {
      'select-user-multi': multi,
      'select-user--disabled': disabled || (withDisableOnLoad && loading),
      'select-user--margin': margin,
    })
    const { isOpen, inputValue } = this.state
    const ignoreMenu =
      !loading &&
      !inputValue &&
      !multi &&
      !!value &&
      value.length &&
      value.length === meta.count

    return (
      <div className={selectClass}>
        {!hideMultiValues &&
          !modal &&
          multi &&
          !!valueArray.length &&
          valueArray.map(this.renderValue)}

        {!ignoreMenu &&
          (modal ? (
            this.renderMenu()
          ) : (
            <div>
              <div onMouseDown={this.handleMouseDown}>
                {onInputBtnChange ? this.renderInputBtn() : this.renderButton()}
              </div>
              {isOpen && this.renderMenu()}
            </div>
          ))}
      </div>
    )
  }
}

export default withTranslation('Select')(SelectAsync)
