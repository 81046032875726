// @flow

import React from 'react'
import type { Node } from 'react'

type Props = {
  field: string,
  getText: string => Array<string>,
}

const SettingsError = ({ field, getText }: Props): Node => {
  const text = getText(field).join('<br/>')

  return (
    <div className='input__msg' dangerouslySetInnerHTML={{ __html: text }} />
  )
}

export default SettingsError
