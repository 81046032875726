// @flow

export const KEYS_DICT = {
  owner: 'owner',
  building: 'building',
  flat: 'flat',
  dueDate: 'due_date',
  statusId: 'status',
  requestType: 'request_type',
}

export const VALUES_GETTERS = {
  request_type: 'id',
  building: 'id',
  flat: 'id',
  owner: 'id',
}
