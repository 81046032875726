// @flow

import {
  USER_LIST_TOGGLE,
  USER_LIST_UPDATE_ITEM,
} from '../../../../../User/UserList/UserList.actionTypes'

export function toggle(owner) {
  return {
    type: USER_LIST_TOGGLE,
    owner,
  }
}

export function updateUser(id, params, tiny) {
  return {
    type: USER_LIST_UPDATE_ITEM,
    id,
    params,
    tiny,
  }
}
