// @flow

import React from 'react'
import type { Node } from 'react'

import ActivityList from './ActivityList'

import styles from './PhaseBody.module.scss'

const PhaseBody = (): Node => {
  return (
    <div className={styles['phase-body']}>
      <ActivityList />
    </div>
  )
}

export default PhaseBody
