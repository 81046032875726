// @flow

import React from 'react'
import type { Node } from 'react'
import { compose } from 'redux'
import { DragSource, DropTarget } from 'react-dnd'
import classnames from 'classnames'

type Props = {
  active: boolean,
  connectDragSource: Node => Node,
  connectDropTarget: Node => Node,
  group: number,
  id: number,
  index: number,
  isDragging: boolean,
  onClick: Object => void,
  onEndDrag: Object => void,
  onMove: (Object, number) => void,
  onStartDrag: () => void,
  title: string,
}

const DragItemType = 'groupBlock'
export { DragItemType }

const dragSource = {
  beginDrag(props: Props) {
    const { group, id, title, onStartDrag } = props
    onStartDrag()

    return { group, id, title }
  },
  endDrag(props: Props, monitor) {
    const { onEndDrag } = props
    onEndDrag(monitor.getItem())
  },
}
const dropTarget = {
  hover(props: Props, monitor) {
    const { id } = monitor.getItem()

    if (id !== props.id) {
      const { onMove, index } = props
      onMove(monitor.getItem(), index)
    }
  },
}

const RequestConstructorGroupBlock = (props: Props) => {
  const {
    isDragging,
    connectDragSource,
    connectDropTarget,
    active,
    onClick,
    title,
    id,
    group,
    index,
  } = props

  const blockClass = classnames('request-constructor__group__blocks__item', {
    'request-constructor__group__blocks__item--active': active,
  })
  const style = { opacity: isDragging ? 0.4 : 1 }

  const data = { id, group, position: index + 1 }

  return connectDragSource(
    connectDropTarget(
      <div
        className={blockClass}
        style={style}
        title={title}
        onClick={() => onClick(data)}
      >
        {title}
      </div>
    )
  )
}

export default compose(
  DragSource(DragItemType, dragSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  })),
  DropTarget(DragItemType, dropTarget, connect => ({
    connectDropTarget: connect.dropTarget(),
  }))
)(RequestConstructorGroupBlock)
