// @flow

import React from 'react'
import type { Node } from 'react'

import Header from './NewPostItem/Header'
import Info from './NewPostItem/Info'
import Content from './NewPostItem/Content'
import Footer from './NewPostItem/Footer'
import Title from './NewPostItem/Title'
import Text from './NewPostItem/Text'

import styles from './WelcomePost.module.scss'

type Props = {
  data: Object,
}

const WelcomePost = (props: Props): Node => {
  const {
    data: { title, text, created, buttons },
  } = props

  return (
    <div className={styles.postItem}>
      <Header>
        <Info isWelcome created={created} />
      </Header>
      <Content>
        <Title title={title} />
        <Text text={text} />
      </Content>
      <Footer>{buttons}</Footer>
    </div>
  )
}

export default WelcomePost
