// @flow

export const GALLERY_ITEM_INDENT = 6 // TODO maybe we don't need this
export const UK_CONTACTS_MODEL = 0
export const NUMBER_OF_POSTS_PER_REQUEST = 20
export const INF_SCROLL_PX_THRESHOLD = 250
export const PAGE_SIZE = 20
export const ALL_PAGES = 1000000 // to get whole list
export const DEFAULT_LANGUAGE = 'de'
export const PREVIEW_GREAT_SIZE = 340
export const MANAGERS_LIMIT = 40

export * from './requests'
export * from './users'
export * from './feedback'
export * from './contacts'
export * from './marketplace'
export * from './pipeline'
export * from './rating'
export * from './price'
export * from './email'
export * from './validation'
export * from './file'
export * from './links'
