// @flow

import React from 'react'
import type { Node } from 'react'

import MessageChunk from './MessageChunk'

import type { Chunk } from './MessageChunks.types'

type Props = { chunks: Array<Chunk> }

const MessageChunks = (props: Props): Node => {
  const { chunks } = props

  return (
    <div className='ql-editor message-text'>
      {chunks.map((chunk, index) => (
        <MessageChunk chunk={chunk} key={index} />
      ))}
    </div>
  )
}

export default MessageChunks
