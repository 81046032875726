// @flow

import React, { useEffect, useState } from 'react'
import type { Node } from 'react'

import { useTranslation } from 'react-i18next'
import { useOverflow } from '../../../../hooks/useOverflow'
import { useSelected } from '../../../../hooks'

import {
  getExportRequestFields,
  exportRequests,
} from '../../../../core/api/api.request'

import Button from '../../../Button'
import Checkbox from '../../../Checkbox'
import Modal from '../../../Modal'
import ModalBody from '../../../Modal/ModalBody'
import ModalRow from '../../../Modal/ModalRow/ModalRow'
import ModalCloseButton from '../../../Modal/ModalCloseButton'
import ModalButtons from '../../../Modal/ModalButtons'
import ModalHead from '../../../Modal/ModalHead'

import ConfirmationPopup from '../../../modals/ConfirmationPopup'

import styles from './ExportRequestModal.module.scss'

type Props = {
  filters: Object,
  onClose: () => void,
  onError: () => void,
  onSuccess: () => void,
}

const ExportRequestModal = (props: Props): Node => {
  const { filters, onClose, onSuccess, onError } = props

  useOverflow()
  const { t } = useTranslation('Request')

  const [working, setWorking] = useState(false)
  const [fields, setFields] = useState([])
  const [showConfirm, setShowConfirm] = useState(false)

  const [selectedItems, changeSelected, setSelected, isAllSelected] =
    useSelected()

  useEffect(() => {
    setWorking(true)
    getExportRequestFields().then(data => {
      setFields(data)
      setWorking(false)
    })
  }, [])

  useEffect(() => {
    if (fields.length > 0) {
      toggleAll()
    }
  }, [fields])

  const startExport = () => {
    exportRequests({
      request_fields: selectedItems,
      request_filters: filters,
    })
      .then(() => {
        onSuccess()
      })
      .catch(() => {
        onError()
      })
      .finally(() => onClose())
  }

  const toggleAll = () => setSelected(fields.map(f => f.field_id))

  const renderFields = () =>
    fields.map((f, i) => (
      <ModalRow key={i}>
        <Checkbox
          className={styles.checkBox}
          checked={selectedItems.includes(f.field_id)}
          onChange={() => changeSelected(f.field_id)}
        />
        <span className={styles.field}>{f.caption}</span>
      </ModalRow>
    ))

  return (
    <>
      <ModalCloseButton onClose={onClose} />
      <ModalHead title={t('SelectColumns')} />
      <ModalBody className={styles.body}>
        <ModalRow>
          <Checkbox
            outer
            checked={isAllSelected(fields)}
            onChange={toggleAll}
          />
          <span className={styles.selectAll}>{t('SelectAll')}</span>
        </ModalRow>
        <div className={styles.fields}>{renderFields()}</div>
      </ModalBody>
      <ModalButtons>
        <Button.Save working={working} onClick={() => setShowConfirm(true)}>
          {t('CreateExport')}
        </Button.Save>
        <Button.Cancel disabled={working} onClick={onClose}>
          {t('Cancel')}
        </Button.Cancel>
      </ModalButtons>
      <Modal isOpen={showConfirm} onRequestClose={() => setShowConfirm(false)}>
        <ConfirmationPopup
          title={t('Attention')}
          text={t('RequestReportGeneratingText')}
          confirm={t('Start')}
          cancel={t('Cancel')}
          onClose={() => setShowConfirm(false)}
          onOk={startExport}
        />
      </Modal>
    </>
  )
}

export default ExportRequestModal
