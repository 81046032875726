// @flow

import React from 'react'
import type { Node } from 'react'

type Props = {
  children: Node,
}

const CellsBar = ({ children }: Props) => (
  <div className='bar__cols bar__row'>{children}</div>
)

export default CellsBar
