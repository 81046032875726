// @flow

import {
  POST_DATA_LOAD,
  POST_DATA_RESET,
  POST_FILES_UPDATE,
} from './Post.actionTypes'

import type { PostAction, PostData } from './Post.types'

const initialState = {
  allBuildings: false,
  buildings: [],
  text: '',
  files: [],
  loaded: false,
  new_audience: [],
  title: '',
  isGroupsPost: false,
}

export default (
  state: PostData = initialState,
  action: PostAction
): PostData => {
  switch (action.type) {
    case POST_DATA_LOAD:
      return { ...action.data, loaded: true }
    case POST_DATA_RESET:
      return initialState
    case POST_FILES_UPDATE:
      return { ...state, files: action.files }
    default:
      return state
  }
}
