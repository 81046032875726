// @flow

import update from 'immutability-helper'

import * as actions from './RequestConstructorCards.actionTypes'

const findIndex = (state, id) => state.items.findIndex(i => i.id === id)

const initialState = {
  initiated: false,
  items: [],
  variables: [],
  working: false,
  updatingId: null,
  removingId: null,
  error: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.REQUEST_CONSTRUCTOR_RESET_CARDS:
    case actions.REQUEST_CONSTRUCTOR_INITIATE_CARDS:
      return initialState
    case actions.REQUEST_CONSTRUCTOR_CARDS_WERE_INITIATED:
      return {
        ...state,
        items: action.items,
        variables: action.variables,
        initiated: true,
      }
    case actions.REQUEST_CONSTRUCTOR_ADD_CARD:
    case actions.REQUEST_CONSTRUCTOR_MOVE_CARD:
    case actions.REQUEST_CONSTRUCTOR_CARD_ADD_LINK:
    case actions.REQUEST_CONSTRUCTOR_CARD_UPDATE_LINK:
    case actions.REQUEST_CONSTRUCTOR_CARD_REMOVE_LINK:
      return {
        ...state,
        working: true,
        error: null,
      }
    case actions.REQUEST_CONSTRUCTOR_REMOVE_CARD:
      return {
        ...state,
        removingId: action.card.id,
        error: null,
      }
    case actions.REQUEST_CONSTRUCTOR_UPDATE_CARD:
      return {
        ...state,
        updatingId: action.card.id,
        error: null,
      }
    case actions.REQUEST_CONSTRUCTOR_CARD_WAS_ADDED: {
      const position = action.position || state.items.length + 1

      return update(state, {
        items: { $splice: [[position - 1, 0, action.item]] },
        working: { $set: false },
      })
    }
    case actions.REQUEST_CONSTRUCTOR_CARD_WAS_REMOVED: {
      const index = findIndex(state, action.id)

      return update(state, {
        items: { $splice: [[index, 1]] },
        variables: { $set: action.variables || state.variables },
        removingId: { $set: null },
      })
    }
    case actions.REQUEST_CONSTRUCTOR_CARD_WAS_MOVED: {
      const item = state.items.find(c => c.id === action.id)
      const updated = update(state, {
        items: { $set: state.items.filter(c => c.id !== action.id) },
      })

      return update(updated, {
        items: { $splice: [[action.position - 1, 0, item]] },
        working: { $set: false },
      })
    }
    case actions.REQUEST_CONSTRUCTOR_CARD_WAS_UPDATED: {
      const index = findIndex(state, action.data.id)

      return update(state, {
        items: {
          [index]: { $set: action.data },
        },
        variables: { $set: action.variables || state.variables },
        updatingId: { $set: null },
      })
    }
    case actions.REQUEST_CONSTRUCTOR_CARD_LINK_WAS_ADDED: {
      const index = findIndex(state, action.data.card)

      return update(state, {
        items: {
          [index]: { links: { $push: [action.data] } },
        },
        working: { $set: false },
      })
    }
    case actions.REQUEST_CONSTRUCTOR_CARD_LINK_WAS_UPDATED: {
      const index = findIndex(state, action.data.card)

      if (index >= 0) {
        const { links } = state.items[index]
        const linkIndex = links.findIndex(i => i.id === action.data.id)

        return update(state, {
          items: {
            [index]: {
              links: { [linkIndex]: { $set: action.data } },
            },
          },
          working: { $set: false },
        })
      }

      return state
    }
    case actions.REQUEST_CONSTRUCTOR_CARD_LINK_WAS_REMOVED: {
      const index = findIndex(state, action.id)
      const { links } = state.items[index]
      const linkIndex = links.findIndex(i => i.id === action.link.id)

      return update(state, {
        items: {
          [index]: {
            links: { $splice: [[linkIndex, 1]] },
          },
        },
        working: { $set: false },
      })
    }
    case actions.REQUEST_CONSTRUCTOR_CARDS_ERROR:
      return {
        ...state,
        working: false,
        removingId: null,
        updatingId: null,
        error: action.error,
      }
    default:
      return state
  }
}
