// @flow

import React from 'react'
import { ErrorMessage, Form, Formik } from 'formik'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import { globalModalError } from '../../Layout/Layout.actions'

import { Cell, CellsBar, Row, SimpleBlock, Header } from '../../Block'

import DateTime from '../../DateTime'
import { EntityIcon } from '../../Icon'
import Button from '../../Button'
import Input from './Input'
import ValidationText from '../../ValidationText'

import { createContractor } from '../../../core/api/api.contractor'

import {
  PHONE_REG_EXP,
  MAX_EMAIL_LENGTH,
  MAX_WORKING_HOURS_LENGTH,
  MAX_DESCRIPTION_LENGTH,
  MAX_NAME_LENGTH,
  MAX_ADDRESS_LENGTH,
} from '../Contractors.constants'

const FIELDS = [
  {
    name: 'name',
    type: 'text',
  },
  {
    name: 'address',
    type: 'text',
  },
  {
    name: 'email',
    type: 'email',
  },
  {
    name: 'phone',
    type: 'tel',
  },
  {
    name: 'working_hours',
    type: 'text',
  },
  {
    name: 'description',
    type: 'textarea',
  },
]

const CreateContractorPage = () => {
  const { t } = useTranslation('Contractors')
  const dispatch = useDispatch()
  let initialValues = FIELDS.reduce((acc, item) => {
    acc[item.name] = ''

    return acc
  }, {})

  initialValues.sendInvite = false

  const getValidationSchema = () => {
    const getErrorText = name => t('EmptyInput', { key: t(name) })
    const stringValidation = name =>
      Yup.string().trim(getErrorText(name)).required(getErrorText(name))

    return Yup.object().shape({
      name: stringValidation('name').max(
        MAX_NAME_LENGTH,
        t('MaxInputLength', { max: MAX_NAME_LENGTH })
      ),
      address: Yup.string()
        .nullable()
        .max(
          MAX_ADDRESS_LENGTH,
          t('MaxInputLength', { max: MAX_ADDRESS_LENGTH })
        ),
      email: Yup.string()
        .email(t('InvalidEmail'))
        .required(getErrorText('email'))
        .max(MAX_EMAIL_LENGTH, t('MaxInputLength', { max: MAX_EMAIL_LENGTH })),
      phone: Yup.string().nullable().matches(PHONE_REG_EXP, t('InvalidPhone')),
      description: Yup.string()
        .nullable()
        .max(
          MAX_DESCRIPTION_LENGTH,
          t('MaxInputLength', { max: MAX_DESCRIPTION_LENGTH })
        ),
      working_hours: Yup.string()
        .nullable()
        .max(
          MAX_WORKING_HOURS_LENGTH,
          t('MaxInputLength', { max: MAX_WORKING_HOURS_LENGTH })
        ),
    })
  }

  const onSubmit = (values, { setSubmitting }) => {
    const params = {
      ...values,
      phone: values.phone === '+' ? '' : values.phone,
    }
    setSubmitting(true)
    createContractor(params)
      .then(() => {
        dispatch(push('/contractors'))
      })
      .catch(err => {
        dispatch(
          globalModalError(
            err.message?.response?.data?.errors || 'Common:Error',
            '',
            true
          )
        )
      })
      .finally(() => setSubmitting(false))
  }

  const renderRow = formikProps => contact =>
    (
      <Row title={t(contact.name)} key={contact.name}>
        <Input
          autoFocus={contact.name === 'name'}
          contact={contact}
          formikProps={formikProps}
        />
        <ErrorMessage name={contact.name} render={renderError} />
      </Row>
    )

  const renderError = text => <ValidationText active text={text} />

  const goBack = e => {
    e.preventDefault()
    dispatch(push('/contractors'))
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={getValidationSchema()}
      onSubmit={onSubmit}
    >
      {formikProps => (
        <Form>
          <SimpleBlock working={formikProps.isSubmitting}>
            <Header header goBack={goBack}>
              <EntityIcon icon small id='user' />
              {t('CreateContractorTitle')}
            </Header>
            <CellsBar>
              <Cell title={t('Created')}>
                <DateTime dateTime={new Date()} />
              </Cell>
            </CellsBar>
            {FIELDS.map(renderRow(formikProps))}
            <div style={{ padding: '15px' }}>
              <Button.Regular
                type='button'
                disabled={formikProps.isSubmitting}
                onClick={formikProps.handleSubmit}
              >
                {t('Submit')}
              </Button.Regular>
              <Button.Cancel onClick={goBack}>{t('Cancel')}</Button.Cancel>
            </div>
          </SimpleBlock>
        </Form>
      )}
    </Formik>
  )
}

export default CreateContractorPage
