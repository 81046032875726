// @flow

import React, { useState, useEffect, useRef } from 'react'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { isEqual } from 'lodash-es'
import type { Node } from 'react'

import Button from '../../Button'
import Checkbox from '../../Checkbox'
import { ALL_PAGES } from '../../../constants'

import ModalCloseButton from '../../Modal/ModalCloseButton'
import ModalHead from '../../Modal/ModalHead'
import ModalBody from '../../Modal/ModalBody'
import ModalButtons from '../../Modal/ModalButtons'
import ModalRow from '../../Modal/ModalRow'
import ModalText from '../../Modal/ModalText'

import NewSelectAsync from '../../NewSelectAsync'
import { Scrollbars } from 'react-custom-scrollbars'
import List from '../../List'
import ListItem from '../../ListItem'

import { useOverflow, useSelected } from '../../../hooks'

import { getBuildingGroupFilter } from '../../../core/api/api.building'

import styles from './BuildingGroupPopup.module.scss'

type Props = {
  buildingId: number,
  onClose: () => void,
  onOk?: (Array<Object>) => void,
  onUpdate: (Array<Object>, boolean) => void,
}

const BuildingGroupPopup = (props: Props): Node => {
  const { buildingId: building } = props
  const [isLoading, setLoading] = useState(true)
  const [initialItems, setInitialItems] = useState(null)
  const [isAllLoading, setAllLoading] = useState(false)
  const [editManagers, setEditManagers] = useState(false)
  const [metaCount, setMetaCount] = useState(null)

  const { t } = useTranslation('BuildingPopup')
  const [selectedItems, changeSelected, setSelected] = useSelected()
  useOverflow()
  const ref = useRef(null)

  useEffect(() => {
    getBuildingGroupFilter({ building, page_size: ALL_PAGES }).then(data => {
      setSelected(data.results.objects)
      setInitialItems(data.results.objects)
      setLoading(false)
    })
  }, [])

  const bodyClass = classnames({
    'working-overlay': isLoading || isAllLoading,
  })

  const handleSubmit = () => {
    setLoading(true)
    const ids = selectedItems.map(({ value }) => value)
    props.onUpdate(ids, editManagers)
  }

  const isDisabled = () => isEqual(initialItems, selectedItems)

  const getLabel = group => group.label

  const checkAllSelected = selected => {
    return metaCount && metaCount === selected.length
  }

  return (
    <div className={styles.modal}>
      <ModalHead title={t('Building:EditBuildingGroups')} />
      <ModalCloseButton onClose={props.onClose} />
      <ModalBody className={bodyClass}>
        <ModalRow>
          <ModalText text={t('Building:GroupListTitle')} />
        </ModalRow>
        <ModalRow>
          <NewSelectAsync
            isMulti
            idField='value'
            placeholder={t('Select:selectAddress')}
            api={getBuildingGroupFilter}
            pageSize={10}
            view='dropdown'
            searchKey='search'
            selectedItems={selectedItems}
            getLabel={getLabel}
            getSelectedLabel={getLabel}
            permanentParams={{ manageable_only: 1 }}
            className={styles.select}
            setSelectedItems={setSelected}
            isAllSelected={checkAllSelected}
            setMetaCount={setMetaCount}
            onClick={changeSelected}
            onClickAll={setAllLoading}
          />
        </ModalRow>
        <ModalRow>
          <Scrollbars className={styles.scrollbar} ref={ref}>
            <List embedded>
              {selectedItems.map(item => (
                <ListItem
                  key={item.value}
                  primaryText={getLabel(item)}
                  title={getLabel(item)}
                  meta={
                    <Button.Cross
                      disabled={isLoading}
                      onClick={() => changeSelected(item)}
                    />
                  }
                />
              ))}
            </List>
          </Scrollbars>
        </ModalRow>
        <ModalRow>
          <Checkbox
            checked={editManagers}
            text={t('CheckboxGroupManagers')}
            onChange={() => setEditManagers(!editManagers)}
          />
        </ModalRow>
      </ModalBody>
      <ModalButtons>
        <Button.Save
          disabled={isLoading || selectedItems.length === 0 || isDisabled()}
          onClick={handleSubmit}
        >
          {t('Save')}
        </Button.Save>
        <Button.Cancel disabled={isLoading} onClick={props.onClose}>
          {t('Cancel')}
        </Button.Cancel>
      </ModalButtons>
    </div>
  )
}

export default BuildingGroupPopup
