//@flow

import React from 'react'
import type { Node } from 'react'
import { useTranslation } from 'react-i18next'

import classnames from 'classnames'
import styles from './ManagersListItem.module.scss'

import Checkbox from '../../../Checkbox'

type Props = {
  item: Object,
  selected: Array<number>,
  toggle: (value: string) => void,
}

const ManagersListItem = (props: Props): Node => {
  const { selected, item } = props

  const { t } = useTranslation('Mail')

  const profile = item.profile

  const checked = selected.includes(profile.id)

  const itemClass = classnames(styles.item, {
    [styles.itemSelected]: checked,
  })

  const toggle = e => {
    props.toggle(e.target.value)
  }

  return (
    <div className={itemClass} key={profile.id}>
      <div className={classnames(styles.cell, styles.cell01)}>
        <Checkbox value={profile.id} checked={checked} onChange={toggle} />
      </div>
      <div className={classnames(styles.cell, styles.cell02)}>
        <span>{profile.fullname}</span>
        {!profile.email && <span className={styles.note}>{t('NoEmail')}</span>}
      </div>
      <div className={classnames(styles.cell, styles.cell02)}>
        <span>{profile.email}</span>
        {item.blacklist && (
          <span className={styles.note}>{t('BlacklistEmail')}</span>
        )}
      </div>
    </div>
  )
}

export default ManagersListItem
