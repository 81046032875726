// @flow

import React from 'react'
import type { Node } from 'react'

import Progress from './Progress'
import Info from './Info'

import styles from './PhaseHeader.module.scss'

type Props = {
  onClick: Function,
}

const PhaseHeader = (props: Props): Node => {
  return (
    <div className={styles['phase-header']} onClick={props.onClick}>
      <Progress />
      <Info />
    </div>
  )
}

export default PhaseHeader
