// @flow

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'
import { get, isEqual } from 'lodash-es'
import { connect } from 'react-redux'
import { replace } from 'connected-react-router'
import { parse } from 'qs'
import { compose } from 'redux'
import type { Location, Match } from 'react-router-dom'

import Flats from '../../components/Flats'
import Loader from '../../components/Loader'
import RouterTabs from '../../components/Tabs/RouterTabs'
import Pan from '../../components/Tabs/Pan'
import ProfileContacts from '../../components/Profile/ProfileContacts'
import ProfileDocuments from '../../components/Profile/ProfileDocuments'
import ProfileData from '../../components/Profile/ProfileData'
import ProfileInfo from '../../components/Profile/ProfileInfo'
import RequestList from '../../components/Request/RequestList'
import NewsList from '../NewsList'
import { isAdminUser, isStaffUser, isDwellerUser } from '../../utils/utils'
import { newsListReset } from '../NewsList/NewsList.actions'
import { getPosts } from '../NewsList/NewsList.selectors'
import * as actions from './Profile.actions'
import { getCurrentFilters, getBoolean } from '../../utils/routing'
import { getUkConfig, getUser } from '../../utils/commonSelectors'
import type { User } from '../../components/Auth/Login/Login.reducer'
import ProfileAttrs from '../../components/ProfileAttrs'
import BuildingGallery from '../../components/BuildingGallery'

type Props = {
  backupFields: Array<Object>,
  blockPostInitiating: () => void,
  blockUserInitiating: () => void,
  currentUser: User,
  data: Object,
  hasMore: boolean,
  initiate: (number, boolean) => void,
  initiated: boolean,
  initiateProfileContacts: Object => void,
  loadMore: Object => void,
  location: Location,
  match: Match,
  modelId: number,
  params: {},
  posts: Array<Object>,
  profileUpdate: Object => void,
  ready: {
    buildings: boolean,
    contacts: boolean,
    info: boolean,
  },
  replace: string => void,
  reset: () => void,
  resetNewsList: () => void,
  sectionReady: number => void,
  t: string => string,
  updateContacts: (Array<Object>) => void,
  updateFiles: Object => void,
  working: boolean,
}

class Profile extends Component<Props> {
  state = {
    external: false,
  }
  componentDidMount() {
    const { location, currentUser: user } = this.props
    const filters = getCurrentFilters(location)
    const archived = getBoolean(filters, 'archived')

    let external = this.state.external

    if (!location.pathname.includes('profile')) {
      external = true
      this.setState({ external: true })
    }

    this.props.initiate(this.getId(), archived, external, isAdminUser(user))
  }

  componentDidUpdate(prev) {
    const { location, initiated, match, currentUser: user } = this.props
    const filters = getCurrentFilters(location)
    const archived = getBoolean(filters, 'archived')

    if (this.props.initiated && !prev.initiated) {
      this.props.initiateProfileContacts({
        profileId: this.props.match.params.profileId,
        modelId: this.props.modelId,
      })
      this.onSectionInit('contacts')
      this.onSectionInit('buildings')

      return
    }

    if (
      !this.props.ready.contacts &&
      this.props.profileInfo &&
      this.props.profileInfo.permissions &&
      !this.props.profileInfo.permissions.can_view_contacts
    ) {
      this.onSectionInit('contacts')
    }

    if (!isEqual(prev.backupFields, this.props.backupFields)) {
      this.props.updateContacts(this.props.backupFields)
    }

    if (match.params.profileId !== prev.match.params.profileId) {
      const external = !location.pathname.includes('profile')
      this.props.initiate(
        match.params.profileId,
        archived,
        external,
        isAdminUser(user)
      )
      this.setState({
        external: external,
      })

      return
    }

    if (!prev.initiated && initiated && !this.canRenderBuildings()) {
      this.onSectionInit('buildings')
    }
  }

  componentWillUnmount() {
    this.props.reset()
    this.props.resetNewsList()
  }

  onSectionInit = key => this.props.sectionReady(key)

  onInvite = () => {
    const { location, currentUser: user } = this.props
    const filters = getCurrentFilters(location)
    const archived = getBoolean(filters, 'archived')

    let external = this.state.external

    if (!location.pathname.includes('profile')) {
      external = true
      this.setState({ external: true })
    }

    this.props.initiate(this.getId(), archived, external, isAdminUser(user))
  }

  getId = () => parseInt(this.props.match.params.profileId, 10)

  load = params => this.props.loadMore(params)

  canRenderBuildings = () => this.props.data.group === 'manager'

  gallerySave = files => {
    const { data } = this.props
    this.props.updateFiles({ id: data.owner, files })
  }

  handleAddressSave = params => {
    this.props.profileUpdate({
      id: this.getId(),
      params: {
        flat_number: params.number,
      },
      external: this.state.external,
    })
  }

  renderTabs = () => {
    const {
      data,
      posts,
      working,
      modelId,
      currentUser: user,
      t,
      uk_config,
    } = this.props
    const { external } = this.state
    const id = this.getId()

    const attributes = get(data, 'attributes')

    const tabs = []

    const isStaff = isStaffUser(user)
    const isDweller = isDwellerUser(user)

    if (data.permissions.can_view_contacts || external) {
      tabs.push(
        <Pan key='contacts' label={t('Contacts')} linkHash='#contacts'>
          <ProfileContacts
            tabView
            noMargin
            modelId={modelId}
            profileId={id}
            isExternal={external}
          />
        </Pan>
      )
    }

    if (data.permissions.can_view_info || external) {
      tabs.push(
        <Pan key='information' label={t('Information')} linkHash='#information'>
          <ProfileData
            tabView
            noMargin
            userId={data.owner || data.id}
            oragShow={uk_config?.display_orag_cluster}
          />
        </Pan>
      )
    }

    const mine = user.owner === data.owner
    const canViewRequests =
      user.group === 'admin' ||
      (user.group === 'manager' && data.group === 'dweller') ||
      mine

    const canViewFlats =
      !isStaffUser(data) &&
      (isStaffUser(user) || mine) &&
      !data.soft_archived &&
      !this.state.external

    if (canViewFlats) {
      tabs.push(
        <Pan
          key='flats'
          label={t('Flats')}
          linkHash='#flats'
          notAllowed={!user.validated}
        >
          <Flats />
        </Pan>
      )
    }

    if (canViewRequests && !external) {
      tabs.push(
        <Pan key='requests' label={t('Requests')} linkHash='#requests'>
          <RequestList
            params={{ owner: data.owner }}
            init={{ user: data.owner }}
          />
        </Pan>
      )
    }

    if (
      (data.group === 'manager' && isStaffUser(user)) ||
      (isAdminUser(user) && isStaffUser(data))
    ) {
      tabs.push(
        <Pan key='buildings' label={t('Homes')} linkHash='#homes'>
          <BuildingGallery shortView noMargin owner={data.owner} />
        </Pan>
      )
    }

    if (data.permissions.can_view_files || external) {
      tabs.push(
        <Pan key='files' label={t('Documents')} linkHash='#documents'>
          <ProfileDocuments
            noMargin
            isDweller={isDweller}
            profile={data}
            saving={working}
            onSave={this.gallerySave}
          />
        </Pan>
      )
    }

    if (attributes && isStaff) {
      tabs.push(
        <Pan key='attrs' label={t('AttributesTabTitle')} linkHash='#attrs'>
          <ProfileAttrs attributes={attributes} title={t('AttributesTitle')} />
        </Pan>
      )
    }

    if (posts.length > 0) {
      tabs.push(
        <Pan key='posts' label={t('Common')} linkHash='#common'>
          <NewsList
            dontReset
            emptyBoxTitle={t('Common')}
            additionalQueryParams={{ owner: id }}
          />
        </Pan>
      )
    }

    return tabs
  }

  render() {
    const {
      currentUser: user,
      initiated,
      location: { search },
      data,
    } = this.props

    const query = parse(search, { ignoreQueryPrefix: true })

    if (!initiated) {
      return <Loader text={false} type='big' />
    }

    const id = this.getId()
    const contentClass = classnames('content__col')
    const noEditFio = user.group === 'dweller'

    return (
      <div className={contentClass}>
        <div className='center-main'>
          <ProfileInfo
            external={this.state.external}
            instaValidate={query.validate}
            currentUser={user}
            profileData={data}
            profileId={id}
            noEditFio={noEditFio}
            onInit={() => this.onSectionInit('info')}
            onInvite={this.onInvite}
          />
          <RouterTabs className='tabs tabs-style-transparent tabs-style-transparent-nopadding'>
            {this.renderTabs()}
          </RouterTabs>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state.profile,
  modelId: state.init.models_with_contacts.UserProfile,
  currentUser: getUser(state),
  posts: getPosts(state),
  uk_config: getUkConfig(state),
})

export default compose(
  withTranslation('Profile'),
  connect(mapStateToProps, {
    ...actions,
    replace,
    resetNewsList: newsListReset,
  })
)(Profile)
