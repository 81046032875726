// @flow

import React, { Component } from 'react'
import type { Node } from 'react'
import { CSSTransition } from 'react-transition-group'
import classnames from 'classnames'

import { DocumentPreview } from '../../Documents'
import { FilesList } from '../../../containers/FilesUpload'
import NewFilesUpload from '../../NewFilesUpload'

import styles from './WidgetAddEntities.module.scss'

const INDENT = 5
const IN_DURATION = 300
const OUT_DURATION = 800
const UPLOAD_KEY = 'widget-files'

type Files = Array<Object>

type Props = {
  children: Node,
  onFinishUpload: Files => void,
  onStartUpload: () => void,
  outbound?: string,
}

type State = {
  files: Files,
  uploading: boolean,
}

class FileMenuItem extends Component<Props, State> {
  state = {
    files: [],
    uploading: false,
  }

  renderFile = (file: Object, key: string) => (
    <DocumentPreview file={file} key={key} />
  )

  startUpload = () => {
    this.setState({ uploading: true })
    this.props.onStartUpload()
  }

  finishUpload = (files: Files) => {
    this.setState({
      files,
      uploading: false,
    })
  }

  exit = () => {
    const { files } = this.state
    this.props.onFinishUpload(files)
  }

  render() {
    const { children } = this.props
    const { uploading } = this.state

    const animClassNames = {
      enter: styles.filesEnter,
      enterActive: styles.filesEnterActive,
      exit: styles.filesExit,
      exitActive: styles.filesExitActive,
    }

    const animTimeout = {
      enter: IN_DURATION,
      exit: OUT_DURATION,
    }

    const filesStyle = {
      '--inDuration': `${IN_DURATION}ms`,
      '--outDuration': `${OUT_DURATION}ms`,
    }

    const filesClassName = classnames(styles.files, {
      [styles.filesUploading]: uploading,
    })

    return (
      <div className={styles.filesBox}>
        <NewFilesUpload
          multiple
          finishTimeout={OUT_DURATION}
          uploadKey={UPLOAD_KEY}
          outbound={this.props.outbound}
          onStartUpload={this.startUpload}
          onFinishUpload={this.finishUpload}
        >
          {children}
        </NewFilesUpload>
        <CSSTransition
          className={filesClassName}
          classNames={animClassNames}
          in={uploading}
          timeout={animTimeout}
          onExited={this.exit}
        >
          <div style={filesStyle}>
            <FilesList
              itemIndent={INDENT}
              renderItem={this.renderFile}
              rowLength={INDENT}
              uploadKey={UPLOAD_KEY}
            />
          </div>
        </CSSTransition>
      </div>
    )
  }
}

export default FileMenuItem
