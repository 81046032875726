// @flow

import React, { Component } from 'react'
import classnames from 'classnames'
import type { Node } from 'react'

import Loader from '../../Loader'
import Scrollbar from '../../Scrollbar'
import { USER_STATUS } from '../../../constants'
import type { Props } from './SelectAsync'

import styles from './Menu.module.scss'

type MenuProps = {
  fetching: boolean,
  filterText?: string,
  getLabel: Object => string,
  ignoreFilter: boolean,
  isSelected: Object => boolean,
  onClick: Object => void,
  onInputBlur: () => void,
  onInputChange: Object => void,
  onInputFocus: Object => void,
  onInputRef: Object => void,
  onRef: Object => void,
  onScroll: Object => void,
  onSelect: Object => void,
  options?: Array<Object>,
  renderLoader: () => Node,
}

const COLORS = ['warning', 'success', 'warning', 'danger', 'warning']

class Menu extends Component {
  constructor(props) {
    super(props)
    this.listRef = React.createRef()
  }

  getClassName = () => {
    const { modal } = this.props

    return modal
      ? ''
      : 'dropdown dropdown--default -dropdown--up-right dropdown--open'
  }

  getDropdownClassName = () => {
    const { modal } = this.props

    return modal ? 'modal-adduser__dropdown dropdown--default' : ''
  }

  props: Props & MenuProps

  renderNoResults = () => {
    const { EmptyMenu, filterText, onInputBlur, t } = this.props

    if (!filterText && EmptyMenu) {
      return (
        <div className={this.getClassName()}>
          <div className={this.getDropdownClassName()}>
            <EmptyMenu onBlur={onInputBlur} />
          </div>
        </div>
      )
    }

    return (
      <div className={this.getClassName()}>
        {this.renderFilter()}
        <div className='Select-noresults'>
          <div className={this.getDropdownClassName()}>
            <span>{t('NoResults')}</span>
          </div>
        </div>
      </div>
    )
  }

  renderFilter = () => {
    const {
      disabled,
      modal,
      onInputBlur,
      onInputFocus,
      onInputRef,
      onInputChange,
      placeholder,
      filterText,
      ignoreFilter,
    } = this.props

    if (ignoreFilter) {
      return null
    }

    const props = {
      disabled,
      type: 'text',
      value: filterText,
      placeholder: `${this.props.t(placeholder)}...`,
      onBlur: onInputBlur,
      onFocus: onInputFocus,
      onChange: onInputChange,
      ref: onInputRef,
    }

    if (modal) {
      return (
        <div className='modal-adduser__input input input--default input--block input--xlarge input--search'>
          <input {...props} />
          <div className='input__icon' />
        </div>
      )
    }

    const className = classnames('dropdown__search-input', {
      'dropdown__search-input--disabled': disabled,
    })

    return (
      <div className='dropdown__search'>
        <div className={className}>
          <input {...props} />
        </div>
      </div>
    )
  }

  renderOption = (opt: Object) => {
    const {
      index,
      valueKey,
      profile,
      getLabel,
      isSelected,
      onSelect,
      showStatus,
    } = this.props

    const label = getLabel(opt)

    let status = null

    if (showStatus && profile && opt) {
      status = this.props.t(`Common:${USER_STATUS[opt.status]}`)
    }

    const className = classnames('dropdown__list-item', {
      'dropdown__list-item--checked': isSelected(opt),
      [styles.wide]: status,
    })

    const labelClass = classnames('dropdown__list-item-text', {
      [styles.label]: status,
    })

    const statusClass = classnames(styles.status, {
      [styles[COLORS[opt.status]]]: status,
    })

    return (
      <li
        tabIndex={index}
        className={className}
        key={`option-${opt[valueKey] || 'all'}`}
        onClick={() => onSelect(opt)}
      >
        {profile && <img src={opt.avatar} alt='userpic' />}
        <span className={labelClass} title={label}>
          {label}
        </span>
        {status && <span className={statusClass}>{status}</span>}
      </li>
    )
  }

  render() {
    const {
      options,
      onRef,
      onScroll,
      onClick,
      renderLoader,
      pageSize,
      fetching,
    } = this.props

    if (!options || (!options.length && fetching)) {
      return (
        <div className={this.getClassName()}>
          {this.renderFilter()}
          {renderLoader()}
        </div>
      )
    }

    if (options.length) {
      const { ignoredOptions, onClickAll, t } = this.props
      let opts = options.filter(o => ignoredOptions.indexOf(o.id) === -1)

      if (onClickAll) {
        opts = [
          {
            all: true,
            label: t('all'),
          },
        ].concat(opts)
      }

      return (
        <div
          className={this.getClassName()}
          ref={onRef}
          onScroll={onScroll}
          onMouseDown={onClick}
        >
          {this.renderFilter()}
          <div className={this.getDropdownClassName()}>
            <Scrollbar
              items={opts}
              className='g-scroll--smooth'
              listElements={opts.map(this.renderOption)}
              listClassName='dropdown__list dropdown__list--default dropdown__list--checklist'
              pageSize={pageSize}
            />
            {fetching && <Loader text />}
          </div>
        </div>
      )
    }

    return this.renderNoResults()
  }
}

export default Menu
