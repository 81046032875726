// @flow

import React, { PureComponent, createRef } from 'react'

import requestAnimationFrame from '../../../utils/requestAnimationFrame'
import Separator from '../Separator'

const STEP = 3 // 3px
const BETWEEN_BANDS = 20 // 100px

type Props = {
  isMove: boolean,
  news: Array<{
    text: string,
    title: string,
    uuid: string,
  }>,
}

type State = {
  display: string,
  left: string,
}

export default class Band extends PureComponent<Props, State> {
  constructor(props) {
    super(props)

    this.state = {
      display: 'none',
      left: '100%',
    }

    this.band = createRef()
  }

  startAnimation = () => {
    this.setState(
      {
        left: this.getBlockWidth(),
        display: 'block',
      },
      this.animateBand
    )
  }

  getParentElement = () => {
    if (this.band.current) {
      return this.band.current.parentElement
    }

    return null
  }

  getBlockWidth = () => {
    const parentElement = this.getParentElement()

    if (!parentElement) {
      return '100%'
    }

    return parentElement.offsetWidth + 'px'
  }

  componentDidMount() {
    this.startAnimation()
  }

  animateBand = () => {
    const { left: currentLeft } = this.state

    const parentElement = this.getParentElement()

    if (!parentElement) {
      return
    }

    const left = parseFloat(currentLeft) - STEP
    const parentOffsetWidth = parentElement.offsetWidth
    const clientWidth = this.band.current.clientWidth || parentOffsetWidth

    if (left + clientWidth < -BETWEEN_BANDS) {
      // if right side out of screen
      this.setState({
        left: this.getBlockWidth(),
      })
    } else {
      this.setState({ left: left + 'px' })
    }

    requestAnimationFrame(this.animateBand)
  }

  render() {
    const { news } = this.props

    return (
      <div ref={this.band} style={{ ...this.state }}>
        {news.map((el, index) => (
          <span key={`${el.uuid}`}>
            {el.title} : {el.text}
            {index !== news.length - 1 && <Separator />}
          </span>
        ))}
      </div>
    )
  }
}
