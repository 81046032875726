export const STATUSES_COLORS = {
  default: '#b0b0b0',
  danger: '#e44440',
  warning: '#d3b801',
  success: '#9ec221',
  deny: '#81361b',
  blue: 'blue',
  red: 'red',
  green: 'green',
  orange: 'orange',
}
