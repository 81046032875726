// @flow

import React from 'react'
import type { Node } from 'react'
import type { Match } from 'react-router-dom'

import BuildingList from './Building/BuildingList'
import Content from './Content'
import { usePathname } from '../hooks'

type Props = {
  buildingGroup?: number,
  canChange?: boolean,
  contractorId?: number,
  match?: Match,
  noHeader?: boolean,
  noMargin?: boolean,
  owner?: Object,
  setCount?: number => void,
  shortView?: boolean,
}

const BuildingGallery = (props: Props): Node => {
  const {
    match,
    noMargin,
    noHeader,
    shortView,
    owner,
    setCount,
    buildingGroup,
    contractorId,
    canChange,
  } = props

  const isLocked = usePathname()
  const search = match?.params?.search

  if (isLocked) {
    return null
  }

  return (
    <Content>
      <BuildingList
        search={search}
        noMargin={noMargin}
        noHeader={noHeader}
        shortView={shortView}
        owner={owner}
        buildingGroup={buildingGroup}
        contractorId={contractorId}
        canChange={canChange}
        setCount={setCount}
      />
    </Content>
  )
}

export default BuildingGallery
