// @flow

import React, { PureComponent } from 'react'
import classnames from 'classnames'
import { compose } from 'redux'
import { connect } from 'react-redux'
import type { Node } from 'react'

import Button from '../Button'
import { getKeySorting } from '../../utils/routing'
import { getLocation } from '../../utils/commonSelectors'
import addTableFilterHandler from '../../hocs/addTableFilterHandler'

import styles from './Table.module.scss'

type Props = {
  children?: ?Node,
  className?: String,
  handleSort: string => void,
  title: string,
}

class HeaderCell extends PureComponent<Props> {
  handleSort = key => () => {
    this.props.handleSort(key)
  }

  render() {
    const {
      children,
      working,
      location,
      title,
      sortKey,
      style,
      className: classNameProp,
    } = this.props
    const isSortingCell = Boolean(sortKey)
    const className = classnames(
      styles.cell,
      'table__cell',
      'table__caption',
      { 'table__caption-clickable': isSortingCell },
      { [classNameProp]: Boolean(classNameProp) }
    )
    const titleStyle = classnames('table__caption-text', styles.title)

    return (
      <div
        style={style}
        className={className}
        onClick={isSortingCell ? this.handleSort(sortKey) : null}
      >
        <div className='table__cell-wrapper' style={{ height: '100%' }}>
          <span className={titleStyle}>{title}</span>
          {children}
          {isSortingCell && (
            <Button.Sort
              sorting={getKeySorting(sortKey, location)}
              labelKey={sortKey}
              working={working}
              onClick={this.handleSort(sortKey)}
            />
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  location: getLocation(state),
})

export default compose(
  connect(mapStateToProps),
  addTableFilterHandler
)(HeaderCell)
