// @flow

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { v1 as generateId } from 'uuid'
import { connect } from 'react-redux'
import { get, unset } from 'lodash-es'
import update from 'immutability-helper'
import { compose } from 'redux'
import classnames from 'classnames'
import type { Node } from 'react'
import type { Dispatch } from 'redux'

import MailSearch from '../../components/MailSearch/MailSearch'
import SendMessageForm from '../SendMessageForm'
import { getUser } from '../../utils/commonSelectors'
import { MAIL_SEARCH_RESET } from '../MailSearch/MailSearch.actionTypes'
import ConfirmationPopup from '../modals/ConfirmationPopup'
import Modal from '../Modal'
import NewSelectSimple from '../NewSelectSimple'
import Radio from '../Radio'

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import styles from './MailReplyForm.module.scss'

type Props = {
  accounts: Array<Object>,
  automation?: boolean,
  automationRequest?: boolean,
  creating?: boolean,
  dispatch: Dispatch<Object>,
  externalFiles: Array<Object>,
  filesHeaderTitle?: string,
  isCCShown?: boolean,
  isRequestThread: boolean,
  isToHided: ?boolean,
  noFileLimit?: boolean,
  onClose?: Object => void,
  onFilesUploading?: Function,
  onFinishedFilesUploading?: Function,
  onGetForwardValues?: string => Object,
  onGetReplyAllValues?: string => Object,
  onGetReplyValues?: string => Object,
  onlyManualFileRemove?: boolean,
  onSave?: Object => void,
  onUpdate?: Object => void,
  removeExternalFile: number => void,
  renderAdditional?: Node,
  replyCallback?: () => void,
  requestId: number,
  resetForm?: () => void,
  showAddButton: boolean,
  t: string => string,
  threadTitle: string,
  uploadFiles?: boolean,
  user: Object,
  uuid: string,
  values: Object,
  withProviders: boolean,
  working: boolean,
}

type State = {
  account: ?Object,
  files: Array<Object>,
  isBCCShown: boolean,
  isCCShown: boolean,
  isLoading: boolean,
  isReplyActionBarShown: boolean,
  isToHided: boolean,
  modal: ?Node,
  replyCallback: ?(string) => Object,
  saveOriginFileIds: Array<number>,
  values: Object,
}

function getUsersEmails(users) {
  const getUserIdOrEmail = user => {
    if (user.profile) {
      return (
        user.profile.owner ||
        user.profile.id ||
        user.profile.user ||
        user.profile.email
      )
    }

    return user.name
  }

  return users ? users.map(getUserIdOrEmail) : []
}

class MailReplyForm extends Component<Props, State> {
  static defaultProps = {
    externalFiles: [],
    values: {
      bcc: [],
      cc: [],
      files: [],
      message: '',
      title: '',
      files_as_links: false,
    },
  }

  state = {
    modal: null,
    files: this.getInitialFiles(),
    isCCShown: Boolean(this.props.isCCShown),
    isBCCShown: Boolean(this.props.isBCCShown),
    isToHided: Boolean(this.props.isToHided),
    isReplyActionBarShown: true,
    replyCallback: null,
    reactQuillRef: null,
    saveOriginFileIds: this.getInitialFiles().map(f => f.id),
    values: this.getInitialValues(),
    account: null,
    replyTo: null,
    isLoading: true,
    isFocus: false,
    attach_option: this.props.values.attach_option || 'no_attach',
    audience: this.props.values.audience || 'providers',
    to_options: this.props.values.to_options || [],
    cc_options: this.props.values.cc_options || [],
    bcc_options: this.props.values.bcc_options || [],
    files_options: this.props.values.files_options || [],
  }

  componentDidMount() {
    this.setState({ isLoading: true })

    if (this.props.accounts?.length) {
      let defaultAccount = null

      const corporateAccounts = this.props.accounts.filter(a => a.corporate)

      if (
        corporateAccounts.length > 0 &&
        !this.props.values?.send_by_system_credentials
      ) {
        defaultAccount = corporateAccounts[0]
      } else {
        const systemAccount = this.props.accounts.find(a => a.is_info)

        if (systemAccount) {
          defaultAccount = systemAccount
        }
      }

      let replyToAccount = null

      if (this.props.accounts.filter(a => !a.is_info).length > 0) {
        replyToAccount = this.props.accounts.filter(a => !a.is_info)[0]
      }

      if (this.props.values?.reply_to_mail_account_id) {
        const privateReplyAccount = this.props.accounts.find(
          a => a.id === this.props.values.reply_to_mail_account_id
        )

        if (privateReplyAccount) {
          replyToAccount = privateReplyAccount
        }
      }

      if (this.props.values?.send_by_mail_account_id) {
        const privateAccount = this.props.accounts.find(
          a => a.id === this.props.values.send_by_mail_account_id
        )

        if (privateAccount) {
          defaultAccount = privateAccount
        }
      }

      let attach_option = this.props.values?.attach_option

      if (defaultAccount.is_info || attach_option === null) {
        attach_option = 'no_attach'
      } else if (attach_option === undefined) {
        attach_option = 'request_and_activity'
      }

      this.setState(
        () => ({
          isLoading: false,
          account: {
            value: defaultAccount.id,
            label: defaultAccount.email_from,
            ...defaultAccount,
          },
          replyTo: replyToAccount
            ? {
                value: replyToAccount.id,
                label: replyToAccount.email_from,
                ...replyToAccount,
              }
            : null,
          attach_option,
          audience: this.props.values?.audience || 'providers',
        }),
        this.update
      )
    }
  }

  componentDidUpdate(prevProps, prevState: State) {
    const prevCcLength = get(prevState, ['values', 'cc', 'length'], 0)
    const curCcLength = get(this.state, ['values', 'cc', 'length'], 0)

    if (curCcLength && !prevCcLength && curCcLength !== prevCcLength) {
      this.setState({ isCCShown: true })
    }

    if (
      this.props.replyCallback !== null &&
      prevProps.replyCallback !== this.props.replyCallback
    ) {
      this.setState({ replyCallback: this.props.replyCallback })
    }

    if (
      prevState.replyCallback !== this.state.replyCallback &&
      this.state.replyCallback !== null &&
      this.state.replyCallback !== undefined
    ) {
      this.focusQuill()
    }

    if (
      prevState.reactQuillRef !== this.state.reactQuillRef &&
      prevState.reactQuillRef === null
    ) {
      if (this.props.replyCallback) {
        this.setState({ replyCallback: this.props.replyCallback })
      } else if (this.state.account && this.isCreating()) {
        this.focusQuill()
      }
    }

    if (prevState.account && prevState.account !== this.state.account) {
      this.focusQuill()
    }
  }

  hideModal = () => {
    this.setState({ modal: null })

    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  getInitialFiles() {
    const { files } = this.props.values

    return files || []
  }

  getInitialValues() {
    const { values, threadTitle } = this.props

    return {
      bcc: values.bcc || [],
      cc: values.cc || [],
      files_as_links: values.files_as_links,
      message: values.message || '',
      title: values.title || threadTitle || '',
      to: values.to || [],
    }
  }

  onFocusEditor = () => {
    const { isReplyActionBarShown, replyCallback } = this.state

    if (this.props.onWysiwygFocus) {
      this.props.onWysiwygFocus()
    }

    if (this.isCreating()) {
      return
    }

    if (isReplyActionBarShown || replyCallback) {
      this.reply()
    }

    this.setState({
      isToHided: false,
      isReplyActionBarShown: false,
    })
  }

  isCreating = () => this.props.creating || this.props.uuid === 'create'

  handleReplyClick = callback => () => {
    this.setState({ replyCallback: callback })
  }

  update = () => {
    if (this.props.onUpdate) {
      const { files, values } = this.state
      this.props.onUpdate({
        ...values,
        files,
        send_by_mail_account_id: this.state.account
          ? this.state.account.is_info
            ? null
            : this.state.account.value
          : undefined,
        send_by_system_credentials: this.state.account
          ? this.state.account.is_info
          : undefined,
        reply_to_mail_account_id: this.state.account
          ? this.state.account.is_info
            ? this.state.replyTo?.value
            : null
          : undefined,
        attach_option:
          this.state.attach_option === 'no_attach' || this.state.account.is_info
            ? null
            : this.state.attach_option,
        audience:
          this.state.attach_option === 'no_attach' || this.state.account.is_info
            ? null
            : this.state.audience,
        to_options: this.state.to_options,
        cc_options: this.state.cc_options,
        bcc_options: this.state.bcc_options,
        files_options: this.state.files_options,
      })
    }
  }

  updateValue = (key: string) => value => {
    this.setState(
      state => ({
        values: { ...state.values, [key]: value },
      }),
      this.update
    )
  }

  showModal = () => {
    this.setState({
      modal: (
        <ConfirmationPopup
          confirm={this.props.t('ClearButton')}
          cancel={this.props.t('CancelButton')}
          title={this.props.t('ResetMailTitle')}
          text={this.props.t('ConfirmMailReset')}
          onClose={this.hideModal}
          onOk={this.resetValues}
        />
      ),
    })
  }

  resetValues = () => {
    const { uuid } = this.props

    if (this.props.resetForm) {
      this.props.resetForm()
    }

    this.props.dispatch({ type: MAIL_SEARCH_RESET, key: `${uuid}to` })
    this.props.dispatch({ type: MAIL_SEARCH_RESET, key: `${uuid}bcc` })
    this.props.dispatch({ type: MAIL_SEARCH_RESET, key: `${uuid}cc` })
    this.setState(
      {
        values: this.getInitialValues(),
        isToHided: this.props.resetForm ? true : false,
        isBCCShown: false,
        isCCShown: false,
        isReplyActionBarShown: true,
      },
      this.update
    )
    this.hideModal()
  }

  updateTitle = ({ target }) => {
    this.setState(
      state => ({
        values: { ...state.values, title: target.value },
      }),
      this.update
    )
  }

  updateFiles = (files: Array<Object>) => {
    this.props.onFinishedFilesUploading && this.props.onFinishedFilesUploading()
    this.setState({ files }, this.update)
  }

  updateFilesAsLinks = v => {
    if (
      this.props.values?.files_as_links !== undefined &&
      v !== this.props.values?.files_as_links
    ) {
      this.updateValue('files_as_links')(v)
    }
  }

  focusQuill = () => {
    const { reactQuillRef, account } = this.state

    if (!reactQuillRef) {
      return
    }

    if (account) {
      const { signature, insert_signature_before_quoted_text } = account

      if (signature) {
        const editor = reactQuillRef.getEditor()

        let position = 0

        setTimeout(() => {
          if (!insert_signature_before_quoted_text) {
            position = editor.getLength()
          }

          editor.pasteHTML(position, '<br>' + signature)
          editor.setSelection(0)
          reactQuillRef.focus()
        }, 10)
      }
    }

    reactQuillRef.focus()
  }

  save = () => {
    if (this.props.onSave) {
      const { values, files, account } = this.state

      const regExp = /(<p+)(.*?)(style=")(.*?)(;")(.*?)(>?)/g

      const params = {
        title: values.title,
        text: values.message.replace(regExp, '$1$2$3$4; margin: 0px$5$6$7'),
        files: files.map(f => f.id),
        to: getUsersEmails(values.to),
        cc: getUsersEmails(values.cc),
        bcc: getUsersEmails(values.bcc),
        credentials: undefined,
      }

      if (account) {
        params.credentials = account.value
      }

      this.props.onSave(params)
    }
  }

  validate = () => {
    const { to, title, message } = this.state.values
    const validatedTitle = this.isCreating() ? title.trim() !== '' : true
    const messageRegex = /(<([^>]+)>)/gi

    return !(
      message.replace(messageRegex, '').trim() !== '' &&
      to.length !== 0 &&
      validatedTitle
    )
  }

  reply = () => {
    const { uuid, owner_obj, to_objs, threadTitle, created } = this.props
    const { replyCallback } = this.state

    const callback = replyCallback || this.props.onGetReplyValues

    if (!callback) {
      return
    }

    const values = callback(uuid, owner_obj, to_objs, threadTitle, created)

    if (this.state.values.message) {
      delete values.message
    }

    if (values.files) {
      this.setState({
        files: values.files,
        saveOriginFileIds: values.files.map(f => f.id),
      })
      unset(values, 'files')
    } else {
      this.setState({ saveOriginFileIds: [] })
    }

    this.setState(
      state => ({
        values: {
          ...state.values,
          ...values,
        },
        replyCallback: null,
      }),
      this.update
    )
  }

  renderFooter = () => {
    const { isReplyActionBarShown } = this.state

    if (this.isCreating() || !isReplyActionBarShown) {
      return null
    }

    return (
      <div className='reply-bar-wrapper' onClick={this.focusQuill}>
        <div>
          {this.props.t('ClickTo')}&nbsp;
          <span
            className='reply-bar-action'
            onMouseDown={this.handleReplyClick(this.props.onGetReplyValues)}
          >
            {this.props.t('ReplyButton')}
          </span>
          ,&nbsp;
          <span
            className='reply-bar-action'
            onMouseDown={this.handleReplyClick(this.props.onGetReplyAllValues)}
          >
            {this.props.t('ReplyAllButton')}
          </span>
          ,&nbsp;
          <span
            className='reply-bar-action'
            onMouseDown={this.handleReplyClick(this.props.onGetForwardValues)}
          >
            {this.props.t('ForwardButton')}
          </span>
        </div>
      </div>
    )
  }

  handleChange = option => {
    const { account } = this.state

    if (account && option && account.value === option.value) {
      return
    }

    this.setState(
      () => ({
        account: option,
      }),
      this.update
    )
  }

  handleChangeReplyTo = option => {
    const { replyTo } = this.state

    if (replyTo && option && replyTo.value === option.value) {
      return
    }

    this.setState(
      () => ({
        replyTo: option,
      }),
      this.update
    )
  }

  handleChangeAttachOption = option => {
    const { attach_option } = this.state

    if (attach_option === option.value) {
      return
    }

    this.setState(
      () => ({
        attach_option: option.value,
      }),
      this.update
    )
  }

  handleChangeAudience = e => {
    const { audience } = this.state
    const newAudience = e.currentTarget.value

    if (audience === newAudience) {
      return
    }

    this.setState(
      () => ({
        audience: newAudience,
      }),
      this.update
    )
  }

  getRecipientVariables = () =>
    ['request_contractors', 'activity_manager', 'request_owner'].map(value => ({
      label: this.props.t(value),
      value,
    }))

  displayVariables = variables =>
    variables.map(variable => ({ name: this.props.t(variable), variable }))

  handleChangeRecipientVariables = (field, value) => {
    let selected = [...this.state[field]]

    if (selected.includes(value)) {
      selected = selected.filter(o => o !== value)
    } else {
      selected.push(value)
    }

    this.setState(
      () => ({
        [field]: selected,
      }),
      this.update
    )
  }

  updateRecipientVariables = (field, variables) => {
    this.setState(
      () => ({
        [field]: variables.map(v => v.name),
      }),
      this.update
    )
  }

  endDragBlock = recipient => {
    if (recipient.toGroup) {
      this.moveBlock(recipient)
    }
  }

  moveBlock = ({ name, variable, group, toGroup }) => {
    const { values } = this.state
    const fromGroupArr = variable
      ? this.state[`${group}_options`]
      : values[group]
    const toGroupArr = variable
      ? this.state[`${toGroup}_options`]
      : values[toGroup]

    if (
      toGroupArr.some(item =>
        variable ? item === variable : item.name === name
      )
    ) {
      return
    }

    let recipient = fromGroupArr.reduce(
      (profile, curr, currentIndex) => {
        if ((variable && curr === variable) || curr.name === name) {
          profile = {
            index: currentIndex,
            data: curr,
          }
        }

        return profile
      },
      { index: null, data: null }
    )

    if (variable) {
      const newOptions = {
        [`${toGroup}_options`]: [...toGroupArr, recipient.data],
        [`${group}_options`]: fromGroupArr.filter(v => v !== recipient.data),
      }

      this.setState(
        () => ({
          ...newOptions,
        }),
        this.update
      )
    } else {
      const newValues = update(values, {
        [toGroup]: {
          $push: [recipient.data],
        },
        [group]: {
          $splice: [[recipient.index, 1]],
        },
      })

      this.setState(
        prevState => ({
          values: { ...prevState.values, ...newValues },
        }),
        this.update
      )
    }
  }

  handleChangeFilesOptions = option => {
    let selected = [...this.state.files_options]

    if (selected.includes(option)) {
      selected = selected.filter(o => o !== option)
    } else {
      selected.push(option)
    }

    this.setState(
      () => ({
        files_options: selected,
      }),
      this.update
    )
  }

  renderHeader = () => {
    const { uuid, user, accounts, withProviders, automation } = this.props
    const {
      isCCShown,
      isBCCShown,
      isToHided,
      values,
      to_options,
      cc_options,
      bcc_options,
    } = this.state
    const { to, cc, bcc, title } = values

    const options =
      accounts &&
      accounts.length &&
      accounts.map(account => ({
        label: account.email_from,
        value: account.id,
        ...account,
      }))

    const headerInputClass = classnames('reply-headers__input', {
      'reply-headers__input--noborder': !isToHided,
    })

    return (
      <div className='reply-headers'>
        {!isToHided && accounts && !!accounts.length && (
          <div className='reply-headers__input reply-headers__input--from'>
            <label>{this.props.t('MessageFrom')}</label>
            <div
              style={{
                paddingLeft: '30px',
                paddingRight: '8px',
                width: '100%',
                zIndex: 4,
              }}
            >
              <NewSelectSimple
                options={options.filter(a => a.is_info || a.corporate)}
                clearable={false}
                value={this.state.account}
                onChange={this.handleChange}
              />
            </div>
          </div>
        )}
        <DndProvider backend={HTML5Backend}>
          {!isToHided && (
            <div className='reply-headers__input'>
              <label>{this.props.t('MessageTo')}</label>
              <div
                className='reply-headers__search'
                style={
                  automation
                    ? { marginRight: '40px', maxWidth: 'calc(100% - 256px)' }
                    : {}
                }
              >
                <MailSearch
                  withProviders={withProviders}
                  id={`${uuid}to`}
                  uuid={uuid}
                  className='resend-popup-choose-usesdr'
                  currentUserId={user.owner}
                  params={{ group: ['admin', 'manager'] }}
                  style={{ flex: 4 }}
                  managers={to}
                  variables={this.displayVariables(to_options)}
                  group='to'
                  onChange={this.updateValue('to')}
                  onChangeVariables={variables =>
                    this.updateRecipientVariables('to_options', variables)
                  }
                  onValidate={() => {}}
                  onEndDragBlock={this.endDragBlock}
                />
              </div>
              <div
                className='reply-headers__edit'
                style={automation ? { transform: 'translateX(-40px)' } : {}}
              >
                {!isCCShown && (
                  <div onClick={() => this.setState({ isCCShown: true })}>
                    CC
                  </div>
                )}
                {!isBCCShown && (
                  <div onClick={() => this.setState({ isBCCShown: true })}>
                    BCC
                  </div>
                )}
              </div>
              {automation && (
                <NewSelectSimple
                  isMulti
                  useSearch
                  className={styles.variablesSelect}
                  openClass={styles.openVariablesSelect}
                  options={this.getRecipientVariables()}
                  selectedItems={to_options}
                  setSelectedItems={option =>
                    this.handleChangeRecipientVariables(
                      'to_options',
                      option.value
                    )
                  }
                  noResultsText={this.props.t('SearchVariablesNotFound')}
                  searchPlaceholder={this.props.t('SearchVariables')}
                  onChange={() => {}}
                />
              )}
            </div>
          )}
          {isCCShown && (
            <div className={headerInputClass}>
              <label>{this.props.t('CC')}</label>
              <div className='reply-headers__search'>
                <MailSearch
                  withProviders={withProviders}
                  id={`${uuid}cc`}
                  className='resend-popup-choose-usesdr'
                  currentUserId={user.owner}
                  params={{ group: ['admin', 'manager'] }}
                  managers={cc}
                  variables={this.displayVariables(cc_options)}
                  group='cc'
                  onChange={this.updateValue('cc')}
                  onChangeVariables={variables =>
                    this.updateRecipientVariables('cc_options', variables)
                  }
                  onValidate={() => {}}
                  onEndDragBlock={this.endDragBlock}
                />
              </div>
              {automation && (
                <NewSelectSimple
                  isMulti
                  useSearch
                  className={styles.variablesSelect}
                  openClass={styles.openVariablesSelect}
                  options={this.getRecipientVariables()}
                  selectedItems={cc_options}
                  setSelectedItems={option =>
                    this.handleChangeRecipientVariables(
                      'cc_options',
                      option.value
                    )
                  }
                  noResultsText={this.props.t('SearchVariablesNotFound')}
                  searchPlaceholder={this.props.t('SearchVariables')}
                  onChange={() => {}}
                />
              )}
            </div>
          )}
          {isBCCShown && (
            <div className='reply-headers__input reply-headers__input--noborder'>
              <label>{this.props.t('BCC')}</label>
              <div className='reply-headers__search'>
                <MailSearch
                  withProviders={withProviders}
                  id={`${uuid}bcc`}
                  managers={bcc}
                  className='resend-popup-choose-usesdr'
                  currentUserId={user.owner}
                  params={{ group: ['admin', 'manager'] }}
                  variables={this.displayVariables(bcc_options)}
                  group='bcc'
                  onChange={this.updateValue('bcc')}
                  onChangeVariables={variables =>
                    this.updateRecipientVariables('bcc_options', variables)
                  }
                  onValidate={() => {}}
                  onEndDragBlock={this.endDragBlock}
                />
              </div>
              {automation && (
                <NewSelectSimple
                  isMulti
                  useSearch
                  className={styles.variablesSelect}
                  openClass={styles.openVariablesSelect}
                  options={this.getRecipientVariables()}
                  selectedItems={bcc_options}
                  setSelectedItems={option =>
                    this.handleChangeRecipientVariables(
                      'bcc_options',
                      option.value
                    )
                  }
                  noResultsText={this.props.t('SearchVariablesNotFound')}
                  searchPlaceholder={this.props.t('SearchVariables')}
                  onChange={() => {}}
                />
              )}
            </div>
          )}
        </DndProvider>
        {accounts &&
          !!accounts.filter(a => !a.is_info).length &&
          this.state.account?.is_info && (
            <div className='reply-headers__input'>
              <label>{this.props.t('ReplyTo')}</label>
              <div
                style={{
                  paddingLeft: '30px',
                  paddingRight: '8px',
                  width: '100%',
                  zIndex: 3,
                }}
              >
                <NewSelectSimple
                  isBottom
                  options={options.filter(a => !a.is_info)}
                  clearable={false}
                  value={this.state.replyTo}
                  onChange={this.handleChangeReplyTo}
                />
              </div>
            </div>
          )}
        <div className='reply-headers__input reply-headers__input--subject'>
          <label>{this.props.t('Subject')}</label>
          <input
            className='textarea-2__input'
            type='text'
            maxLength={255}
            style={{ fontSize: '15px' }}
            value={title}
            onChange={this.updateTitle}
          />
        </div>
      </div>
    )
  }

  render() {
    const {
      uuid,
      onlyManualFileRemove,
      working,
      uploadFiles,
      isRequestThread,
      requestId,
      externalFiles,
      filesHeaderTitle,
      showAddButton,
      automation,
      automationRequest,
      noFileLimit,
    } = this.props

    const { files, saveOriginFileIds, modal, account, files_options } =
      this.state

    const quillProps = {
      value: this.state.values.message,
      onChange: this.updateValue('message'),
      onFocus: this.onFocusEditor,
      setFocus: elem => {
        this.setState({ reactQuillRef: elem })
      },
    }

    const attachOptions = [
      {
        value: 'request_and_activity',
        label: this.props.t('Attach.request_and_activity'),
      },
      { value: 'request', label: this.props.t('Attach.request') },
      { value: 'no_attach', label: this.props.t('Attach.no_attach') },
    ]

    const filesOptions = [
      { value: 'activity_files', label: this.props.t('Automate:FromActivity') },
      {
        value: 'request_files',
        label: this.props.t('Automate:FromServiceRequestProfile'),
      },
    ]

    const rbId = generateId()

    return (
      <>
        <div className='reply-container'>
          <SendMessageForm
            isEmail
            isConstructor
            hasVariables
            isAutomation={automation || automationRequest}
            account={account}
            showAddButton={showAddButton}
            requestId={requestId}
            isRequestThread={isRequestThread}
            filesHeaderTitle={filesHeaderTitle}
            noFileLimit={noFileLimit}
            uploadFiles={uploadFiles}
            canSubmit={!this.validate()}
            files={files.concat(externalFiles)}
            filesAsLinks={this.props.values?.files_as_links}
            removeExternalFile={this.props.removeExternalFile}
            quillProps={quillProps}
            resetValues={this.showModal}
            onlyManualFileRemove={onlyManualFileRemove}
            renderAdditional={this.props.renderAdditional}
            renderHeader={this.renderHeader}
            renderFooter={this.renderFooter}
            saveOriginFileIds={saveOriginFileIds}
            toolbarId={`toolbar-${uuid}`}
            isToHided={Boolean(this.props.isToHided)}
            working={working}
            fileListClass={styles.files}
            uuid={uuid}
            setActiveIndex={this.props.setFileIndex}
            values={this.props.values}
            onSubmit={this.props.onSave ? this.save : null}
            onUpdateFiles={this.updateFiles}
            onUpdateTitle={this.updateValue('title')}
            onFilesUploading={this.props.onFilesUploading}
            onUpdateFilesAsLinks={this.updateFilesAsLinks}
          />
          <Modal isOpen={!!modal} onRequestClose={this.hideModal}>
            {modal}
          </Modal>
        </div>
        {automation && (
          <div className={styles.attachContainer}>
            <label>{this.props.t('Automate:AddFiles')}</label>
            <div>
              <NewSelectSimple
                isMulti
                options={filesOptions}
                placeholder={this.props.t('Automate:NoAttach')}
                selectedItems={files_options}
                getSelectedLabel={option =>
                  filesOptions.find(o => o.value === option)?.label
                }
                setSelectedItems={option =>
                  this.handleChangeFilesOptions(option.value)
                }
                onChange={() => {}}
              />
            </div>
            <label>{this.props.t('AttachEmail')}</label>
            <div>
              <NewSelectSimple
                isBottom
                disabled={this.state.account?.is_info}
                options={attachOptions}
                clearable={false}
                value={attachOptions.find(
                  o => o.value === this.state.attach_option
                )}
                onChange={this.handleChangeAttachOption}
              />
            </div>
            {this.state.attach_option !== 'no_attach' && (
              <div className={styles.audienceButtons}>
                <Radio
                  id={`audience_providers_${rbId}`}
                  disabled={this.state.account?.is_info}
                  name={'audience'}
                  value={'providers'}
                  checked={this.state.audience === 'providers'}
                  label={this.props.t('Audience.providers')}
                  onChange={this.handleChangeAudience}
                />
                <Radio
                  id={`audience_dwellers_${rbId}`}
                  disabled={this.state.account?.is_info}
                  name={'audience'}
                  value={'dwellers'}
                  checked={this.state.audience === 'dwellers'}
                  label={this.props.t('Audience.dwellers')}
                  onChange={this.handleChangeAudience}
                />
                <Radio
                  id={`audience_managers_${rbId}`}
                  disabled={this.state.account?.is_info}
                  name={'audience'}
                  value={'managers'}
                  checked={this.state.audience === 'managers'}
                  label={this.props.t('Audience.managers')}
                  onChange={this.handleChangeAudience}
                />
              </div>
            )}
          </div>
        )}
      </>
    )
  }
}

const mapStateToProps = state => ({
  user: getUser(state),
})

export default compose(
  withTranslation('Mail'),
  connect(mapStateToProps)
)(MailReplyForm)
