//@flow

import { FORMIK_EMPTY_VALUE } from '../../../constants'

export const ADDRESS = 'address'
export const STREET = 'street'
export const POST_CODE = 'postcode'
export const CITY = 'city'
export const COUNTRY = 'country'
export const ADDRESS_2 = 'address_2'

export const MAX_POSTAL_ADDRESS_LENGTH = 500
export const MAX_STREET_LENGTH = 245
export const MAX_CITY_LENGTH = 245
export const MAX_COUNTRY_LENGTH = 245
export const MAX_POST_CODE = 10

export const KEYS = [ADDRESS, STREET, POST_CODE, CITY]

export const DEFAULT_VALUES: Object = {
  [ADDRESS]: FORMIK_EMPTY_VALUE,
  [STREET]: FORMIK_EMPTY_VALUE,
  [POST_CODE]: FORMIK_EMPTY_VALUE,
  [CITY]: FORMIK_EMPTY_VALUE,
}
