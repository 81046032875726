// @flow

import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import NewSelectSimple from '../NewSelectSimple'

import styles from './NewVariableDropdown.module.scss'

const NewVariableDropdown = props => {
  const { t } = useTranslation('RequestConstructor')

  const [isOpen, setOpen] = useState(false)

  const variables = useSelector(
    state => state.requestConstructorCards.variables
  )

  const getVariables = () =>
    variables.map(variable => ({ value: variable.id, label: variable.name }))

  const selectClass = classnames(styles.select, { [styles.open]: isOpen })

  return (
    <>
      <span>{t('Variables')}</span>
      <NewSelectSimple
        useSearch
        options={getVariables()}
        className={selectClass}
        placeholder={t('ChooseVariable')}
        searchPlaceholder={t('VariableSearchPlaceholder')}
        noResultsText={t('VariablesNotFoundInContext')}
        toggleMenuCb={setOpen}
        onChange={props.handlePasteVariable}
      />
    </>
  )
}

export default NewVariableDropdown
