// @flow

import { getApi, postApi } from './api.core'

const BASE_PATHNAME = 'api/system/'

export const init = () => getApi(`${BASE_PATHNAME}init/`, { by_value: 1 })
export const confirmEmail = (hash: string) =>
  postApi(`${BASE_PATHNAME}confirm-email/${hash}/`)
export const confirmInvite = (hash: string) =>
  postApi(`${BASE_PATHNAME}confirm-invite/${hash}/`)
export const confirmRecovery = (hash: string) =>
  postApi(`${BASE_PATHNAME}confirm-recover/${hash}/`)
export const inviteSetupPass = (hash: string, params: { password: string }) =>
  postApi(`${BASE_PATHNAME}invite-setup-pass/${hash}/`, params)
export const recoverSetupPass = (hash: string, params: { password: string }) =>
  postApi(`${BASE_PATHNAME}recover-setup-pass/${hash}/`, params)
export const validatePhone = (params: { number: string }) =>
  postApi(`${BASE_PATHNAME}validate-phone/`, params)
export const validateCode = (params: { key: string, number: string }) =>
  postApi(`${BASE_PATHNAME}validate-phone/confirm/`, params)
export const resendValidationCode = (params: { number: string }) =>
  postApi(`${BASE_PATHNAME}validate-phone/resend/`, params)
export const getDictOptions = (path: string) =>
  getApi(`${BASE_PATHNAME}dict/${path}/`)
export const deleteDemoData = () =>
  postApi(`${BASE_PATHNAME}rm_demo_content/`, {})
export const getMpSession = () => getApi('api/marketplace/session/')
