// @flow

import { cloneDeep, filter, findIndex } from 'lodash-es'

import * as actions from './FriendsRequests.actionTypes'

const initialState = {
  initiated: false,
  working: false,
  items: [],
  meta: {},
}

export default (state = initialState, action) => {
  let items
  switch (action.type) {
    case actions.FRIENDS_REQUESTS_INITIATING:
      return initialState
    case actions.FRIENDS_REQUESTS_INITIATED:
      // remove after backend fix
      items = filter(action.results.objects, null)

      return {
        ...state,
        initiated: true,
        items,
        meta: action.meta,
      }
    case actions.FRIENDS_REQUESTS_PAGE:
      return {
        ...state,
        working: true,
      }
    case actions.FRIENDS_REQUESTS_HANDLE: {
      items = cloneDeep(state.items)
      const index = findIndex(items, { id: action.user })
      items[index][action.accept ? 'accept' : 'decline'] = true

      return {
        ...state,
        working: true,
        items,
      }
    }
    case actions.FRIENDS_REQUEST_LOADED:
      // remove after backend fix
      items = filter(action.results.objects, null)

      return {
        ...state,
        working: false,
        items,
        meta: action.meta,
      }
    default:
      return state
  }
}
