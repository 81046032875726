// @flow

import React from 'react'
import type { Node } from 'react'

type Props = {
  counter: number,
  text: string,
}

const UserInfoCell = (props: Props): Node => {
  const { counter, text } = props

  return (
    <div className='bar__cell'>
      <span className='bar__cell-title'>{text}</span>
      <span className='bar__cell-value'>{counter}</span>
    </div>
  )
}

export default UserInfoCell
