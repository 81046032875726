// @flow

import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'

import Editor from '../Editor'
import Button from '../../../components/Button'
import {
  editQuestion,
  editAnswer,
  saveAnswer,
  saveQuestion,
  createQuestion,
  showModal,
} from '../Faq.actions'
import * as selectors from '../Faq.selectors'
import { REMOVE_QUESTION, DEFAULT_AVATAR } from '../Faq.constants'

type Props = {
  adminAvatar: string,
  answer: string,
  avatar: string,
  groupId: number,
  id: number,
  isAnswerWorking: boolean,
  isQuestionWorking: boolean,
  permissions: Object,
  position?: number,
  t: string => string,
  title: string,
}
type State = {
  isCollapsed: boolean,
}

class Question extends PureComponent<Props, State> {
  state = {
    isCollapsed: true,
  }

  componentDidUpdate(prevProps) {
    const { id, editingAnswer } = this.props

    if (prevProps.editingAnswer !== editingAnswer && editingAnswer === id) {
      this.setState({
        isCollapsed: false,
      })
    }
  }

  handleClick = () => {
    if (!this.isQuestionEditing() && !this.isAnswerEditing()) {
      this.setState(state => ({
        isCollapsed: !state.isCollapsed,
      }))
    }
  }

  isQuestionEditing = (props = this.props) => {
    const { editingQuestion, id } = props

    return editingQuestion === id
  }

  isAnswerEditing = (props = this.props) => {
    const { editingAnswer, id } = props

    return editingAnswer === id || this.isNew()
  }

  handleQuestionEdit = () => {
    const { id } = this.props
    this.props.editQuestion(id)
  }

  handleAnswerEdit = () => {
    const { id } = this.props
    this.props.editAnswer(id)
  }

  handleSaveQuestion = value => {
    const { groupId, position } = this.props

    this.isNew()
      ? this.props.createQuestion(value, groupId)
      : this.props.saveQuestion(value, groupId, position)
  }

  handleRemoveQuestion = () => {
    const { groupId, position } = this.props
    this.props.showModal(REMOVE_QUESTION, { group: groupId, position })
  }

  handleSaveAnswer = answer => {
    const { groupId, position } = this.props
    this.props.saveAnswer(answer, groupId, position)
  }

  isNew = () => this.props.id.toString().includes('new_')

  render() {
    const {
      title,
      answer,
      isQuestionWorking,
      t,
      avatar = DEFAULT_AVATAR,
      id,
      isAnswerWorking,
      adminAvatar,
      permissions,
      editingQuestion,
      editingAnswer,
    } = this.props
    const { isCollapsed } = this.state
    const isAnswerEditing = this.isAnswerEditing()
    const isQuestionEditing = this.isQuestionEditing()
    const isNew = this.isNew()
    const className = classnames('faq__question', {
      'faq__question-extend': !isCollapsed || isQuestionEditing,
      'faq__question-answer': answer,
    })
    const classAnswer = classnames('faq__answer', {
      'faq__answer-no-edit': !permissions.can_edit,
    })
    const classAnswerBody = classnames('faq__answer-wide', {
      'faq__answer-body': !isAnswerEditing,
    })

    return (
      <div className={className}>
        <div className='faq__question-wrapper'>
          <div className='faq__question-title' onClick={this.handleClick}>
            <div className='faq__question-icon'>?</div>
            <Editor
              value={title}
              isWorking={isQuestionWorking}
              maxLength={300}
              placeholder={t('add_question')}
              isInitialEditing={isNew}
              canEdit={permissions.can_edit}
              editing={editingQuestion}
              id={id}
              onRemove={
                isNew ? this.handleQuestionEdit : this.handleRemoveQuestion
              }
              onSave={this.handleSaveQuestion}
              onToggleEdit={this.handleQuestionEdit}
            />
            {!answer &&
              !isAnswerEditing &&
              !isQuestionEditing &&
              permissions.can_edit && (
                <Button.Regular
                  className='button--success faq__answer-add'
                  onClick={this.handleAnswerEdit}
                >
                  {t('add_answer')}
                </Button.Regular>
              )}
            {answer && !isQuestionEditing && (
              <Button.Minimize
                className='faq__minimize'
                isOpen={!isCollapsed}
              />
            )}
          </div>
          {!isCollapsed && (answer || isAnswerEditing) && (
            <div className={classAnswer}>
              <i className='post-header__userpic'>
                <img
                  className='post-header__userpic-img'
                  src={isAnswerEditing ? adminAvatar : avatar}
                  alt='author'
                />
              </i>
              <div className={classAnswerBody}>
                <Editor
                  isAnswer
                  className='faq__answer-edit'
                  maxLength={4000}
                  isInitialEditing={!answer}
                  placeholder={t('add_answer')}
                  canEdit={permissions.can_edit}
                  isWorking={isAnswerWorking}
                  editing={editingAnswer}
                  id={id}
                  value={answer || ''}
                  onCancel={this.handleAnswerEdit}
                  onSave={this.handleSaveAnswer}
                  onToggleEdit={this.handleAnswerEdit}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  editingQuestion: selectors.getEditingQuestion(state),
  editingAnswer: selectors.getEditingAnswer(state),
  isQuestionWorking: selectors.isQuestionWorking(state),
  isAnswerWorking: selectors.isAnswerWorking(state),
  adminAvatar: selectors.getAdminAvatar(state),
  permissions: selectors.getPermissions(state),
})
const mapDispatchToProps = {
  editQuestion,
  editAnswer,
  saveAnswer,
  saveQuestion,
  createQuestion,
  showModal,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('FAQ')
)(Question)
