// @flow

import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { replace } from 'connected-react-router'

import Info from './Info'
import FeedbackPost from './FeedbackPost'
import Chat from './Chat'
import Loader, { LOADER_TYPE } from '../../components/Loader/Loader'

import {
  loadFeedback,
  feedbackWasLoaded,
  loadRating,
  loadRequest,
} from './Feedback.actions'
import { widgetFilesWereLoaded } from '../../components/widgets/WidgetFiles/WidgetFiles.actionTypes'
import { widgetChatParticipantsListInitiated } from '../../components/widgets/WidgetParticipantsList/WidgetParticipantsList.actionTypes'
import { getUser } from '../../utils/commonSelectors'
import * as selectors from './Feedback.selectors'

export const NEW_FEEDBACK_ID = 'create'

type Props = {
  isLoading: boolean,
  loadFeedback: () => void,
  t: string => string,
  toggleRatingModal: () => void,
}

class Feedback extends PureComponent<Props> {
  componentDidMount() {
    const { match, currentUser } = this.props
    const { id, requestID } = match.params

    if (id === NEW_FEEDBACK_ID) {
      this.props.loadRequest(requestID)
    } else if (['chats', 'emails', 'activity'].includes(id)) {
      return this.props.replace(`/request/${requestID}/${id}/feedback/`)
    } else {
      this.props.loadFeedback(id)
    }

    this.props.widgetFilesWereLoaded([])
    this.props.widgetChatParticipantsListInitiated([
      {
        ...currentUser,
        active: true,
        id: currentUser.owner,
      },
    ])
  }

  render() {
    const { isLoading } = this.props

    if (isLoading) {
      return <Loader text={false} type={LOADER_TYPE.medium} />
    }

    return (
      <>
        <Info />
        <FeedbackPost />
        <Chat />
      </>
    )
  }
}

const mapStateToProps = state => ({
  isLoading: selectors.isLoading(state),
  requestId: selectors.getRequestId(state),
  currentUser: getUser(state),
})
const mapDispatchToProps = {
  loadFeedback,
  feedbackWasLoaded,
  loadRating,
  replace,
  widgetFilesWereLoaded,
  widgetChatParticipantsListInitiated,
  loadRequest,
}

export default compose(
  withTranslation('Feedback'),
  connect(mapStateToProps, mapDispatchToProps)
)(Feedback)
