// @flow

import React from 'react'
import { useSelector } from 'react-redux'
import classnames from 'classnames'
import type { Node } from 'react'

import { getThemeColor } from '../../../utils/commonSelectors'

import styles from './Arrow.module.scss'

import Icon from '../../../../static/icons/arrow.svg'

type Props = {
  className?: string,
  disabled?: boolean,
  forward?: boolean,
  hidden?: boolean,
  onClick: (SyntheticEvent<HTMLButtonElement>) => void,
  style?: Object,
}

const Arrow = (props: Props): Node => {
  const { forward, hidden, disabled, className, style } = props

  const themeColor: string = useSelector(getThemeColor)

  const btnClass = classnames(styles.arrow, className, {
    [styles.forward]: forward,
    [styles.hidden]: hidden,
    [styles.inactive]: disabled,
  })

  return (
    <button
      type='button'
      className={btnClass}
      disabled={disabled || hidden}
      style={{ '--themeColor': themeColor, ...style }}
      onClick={props.onClick}
    >
      <Icon />
    </button>
  )
}

export default Arrow
