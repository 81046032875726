import { all, put, call, fork, takeEvery } from 'redux-saga/effects'
import api from '../../../core/api'
import { serverError } from '../../../components/Layout/Layout.actions'
import * as actions from './LabelsPopup.actionTypes'

function * init ({ parentEntity }) {
  try {
    const response = yield call(api.label.getLabels)
    const { meta, permissions, results } = response
    const parentEntityLabelIds = parentEntity.label_objs.map((l) => l.id)
    const labels = results.objects.map((obj) => ({
      ...obj,
      checked: parentEntityLabelIds.indexOf(obj.id) !== -1
    }))
    yield put({ type: actions.LABELS_MODAL_INITED, labels, meta, permissions })
  } catch (error) {
    console.log(error)
    yield put(serverError(error))
  }
}

function * remove ({ id, cb }) {
  try {
    yield call(api.label.deleteLabel, id)
    yield put({ type: actions.LABELS_MODAL_LABEL_DELETED })
    if (cb) {
      cb()
    }
  } catch (error) {
    yield put(serverError(error))
  }
}

function * create ({ name, color, cb }) {
  try {
    const label = yield call(api.label.createLabel, { name, color })
    label.checked = true
    yield put({ type: actions.LABELS_MODAL_LABEL_CREATED, label })
    if (cb) {
      cb(label)
    }
  } catch (error) {
    yield put(serverError(error))
  }
}

function * update ({ name, color, id, cb }) {
  try {
    const label = yield call(api.label.patchLabel, id, { name, color })
    yield put({ type: actions.LABELS_MODAL_LABEL_UPDATED, label })
    if (cb) {
      cb()
    }
  } catch (error) {
    yield put(serverError(error))
  }
}

function * watchInit () {
  yield takeEvery(actions.LABELS_MODAL_INIT, init)
}

function * watchCreateLabel () {
  yield takeEvery(actions.LABELS_MODAL_LABEL_CREATE, create)
}

function * watchUpdateLabel () {
  yield takeEvery(actions.LABELS_MODAL_LABEL_UPDATE, update)
}

function * watchDeleteLabel () {
  yield takeEvery(actions.LABELS_MODAL_LABEL_DELETE, remove)
}

export default function * watch () {
  yield all([
    fork(watchInit),
    fork(watchCreateLabel),
    fork(watchUpdateLabel),
    fork(watchDeleteLabel)
  ])
}
