// @flow

import React from 'react'
import { connect } from 'react-redux'
import type { Match } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { get } from 'lodash-es'

import type { User } from '../../components/Auth/Login/Login.reducer'
import ProfileFilling from '../../components/widgets/ProfileFilling/'
import DefaultWidgets from './DefaultWidgets'
import { getUser } from '../../utils/commonSelectors'

type Props = {
  match: Match,
  showFillWidget: boolean,
  user: User,
}

const showFillWidget = (user, match) => {
  const owner = get(user, 'owner')
  const profileId = get(match, ['params', 'profileId'])

  return owner && profileId && parseInt(owner, 10) === parseInt(profileId, 10)
}

const ProfileWidgets = (props: Props) => {
  const { user, match } = props

  return (
    <DefaultWidgets>
      {showFillWidget(user, match) && <ProfileFilling />}
    </DefaultWidgets>
  )
}

const mapStateToProps = state => ({
  user: getUser(state),
})

export default compose(withRouter, connect(mapStateToProps))(ProfileWidgets)
