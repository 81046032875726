// @flow

import React from 'react'
import linkifyHtml from 'linkifyjs/html'
import type { Node } from 'react'
import { getMessageWithLinks } from './Message.utils'

type Props = { text: string }

const MessageText = (props: Props): Node => {
  const { text } = props

  return (
    <div
      className='ql-editor message-text'
      dangerouslySetInnerHTML={{
        __html: linkifyHtml(getMessageWithLinks(text)),
      }}
    />
  )
}

export default MessageText
