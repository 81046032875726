import { all, put, call, fork, select, takeLatest } from 'redux-saga/effects'
import { get, isString } from 'lodash-es'

import { getWorkflowId } from './RequestConstructorCards.selectors'
import api from '../../core/api'
import * as actions from './RequestConstructorCards.actionTypes'
import {
  globalModalError,
  serverError,
} from '../../components/Layout/Layout.actions'
import { WORKFLOW_PUBLISHED } from '../RequestConstructor/RequestConstructor.actionTypes'
import { getWorkflow } from '../../core/api/api.requestConstructor'

function* handleError(error) {
  const { data } = error.message.response
  yield put({
    type: actions.REQUEST_CONSTRUCTOR_CARDS_ERROR,
    error: data && data.errors ? data.errors : true,
  })

  const errors = get(data, ['errors'], {})

  let errorText

  if (isString(errors)) {
    errorText = errors
  } else {
    errorText = Object.values(get(data, ['errors'], {}))?.[0]
  }

  if (errorText) {
    yield put(globalModalError(errorText, '', true))
    yield put(serverError(error))
  }
}

function* watchInitiate() {
  yield takeLatest(actions.REQUEST_CONSTRUCTOR_INITIATE_CARDS, initiate)
}

function* watchAdd() {
  yield takeLatest(actions.REQUEST_CONSTRUCTOR_ADD_CARD, add)
}

function* watchUpdate() {
  yield takeLatest(actions.REQUEST_CONSTRUCTOR_UPDATE_CARD, update)
}

function* watchRemove() {
  yield takeLatest(actions.REQUEST_CONSTRUCTOR_REMOVE_CARD, remove)
}

function* watchMove() {
  yield takeLatest(actions.REQUEST_CONSTRUCTOR_MOVE_CARD, move)
}

function* watchAddLink() {
  yield takeLatest(actions.REQUEST_CONSTRUCTOR_CARD_ADD_LINK, addLink)
}

function* watchUpdateLink() {
  yield takeLatest(actions.REQUEST_CONSTRUCTOR_CARD_UPDATE_LINK, updateLink)
}

function* watchRemoveLink() {
  yield takeLatest(actions.REQUEST_CONSTRUCTOR_CARD_REMOVE_LINK, removeLink)
}

function* watchChanged() {
  yield takeLatest(actions.REQUEST_CONSTRUCTOR_CARD_CHANGED, changed)
}

function* changed({ workflowId }) {
  try {
    const workflowData = yield call(getWorkflow, workflowId)

    yield put({
      type: WORKFLOW_PUBLISHED,
      row_is_changed: workflowData.row_is_changed,
    })
  } catch (error) {
    yield* handleError(error)
  }
}

function* initiate({ block }) {
  try {
    const workflow = yield select(getWorkflowId)
    const variables = yield call(api.requestConstructor.getVariables, workflow)

    const items = yield call(api.requestConstructor.getCards, block)
    yield put({
      type: actions.REQUEST_CONSTRUCTOR_CARDS_WERE_INITIATED,
      items,
      variables,
    })
  } catch (error) {
    yield* handleError(error)
  }
}

function* add({ card, workflowId }) {
  try {
    const item = yield call(api.requestConstructor.addCard, card)
    yield put({
      type: actions.REQUEST_CONSTRUCTOR_CARD_WAS_ADDED,
      item,
      position: card.position,
    })

    yield put({ type: actions.REQUEST_CONSTRUCTOR_CARD_CHANGED, workflowId })
  } catch (error) {
    yield* handleError(error)
  }
}

function* update(action) {
  try {
    const card = action.card

    if (card.file) {
      const params = {
        origin: card.file,
        name: card.file.name,
        type: 0,
      }
      const file = yield call(api.file.createFile, params)
      card.file = file.id
    }

    let newCard = { ...card }

    const regExp = /(<p+)(.*?)(style=")(.*?)(;")(.*?)(>?)/g

    if (newCard.marketplace_text) {
      newCard.marketplace_text = newCard.marketplace_text.replace(/\n/g, '')
      newCard.marketplace_text = newCard.marketplace_text.replace(
        regExp,
        '$1$2$3$4; margin: 0px$5$6$7'
      )
    }

    const data = yield call(api.requestConstructor.updateCard, newCard)

    let variables = null

    if (card.variables) {
      const workflow = yield select(getWorkflowId)
      variables = yield call(api.requestConstructor.getVariables, workflow)
    }

    yield put({
      type: actions.REQUEST_CONSTRUCTOR_CARD_WAS_UPDATED,
      data,
      variables,
    })

    yield put({
      type: actions.REQUEST_CONSTRUCTOR_CARD_CHANGED,
      workflowId: action.workflowId,
    })
  } catch (error) {
    yield* handleError(error)
  }
}

function* remove({ card, workflowId }) {
  try {
    yield call(api.requestConstructor.removeCard, card.id)

    let variables = null

    if (card.variables) {
      const workflow = yield select(getWorkflowId)
      variables = yield call(api.requestConstructor.getVariables, workflow)
    }

    yield put({
      type: actions.REQUEST_CONSTRUCTOR_CARD_WAS_REMOVED,
      id: card.id,
      variables,
    })

    yield put({ type: actions.REQUEST_CONSTRUCTOR_CARD_CHANGED, workflowId })
  } catch (error) {
    yield* handleError(error)
  }
}

function* move({ id, position, workflowId }) {
  try {
    yield call(api.requestConstructor.updateCard, { id, position })

    yield put({
      type: actions.REQUEST_CONSTRUCTOR_CARD_WAS_MOVED,
      id,
      position,
    })

    yield put({ type: actions.REQUEST_CONSTRUCTOR_CARD_CHANGED, workflowId })
  } catch (error) {
    yield* handleError(error)
  }
}

function* addLink({ id, link, workflowId }) {
  try {
    const data = yield call(api.requestConstructor.addCardLink, id, link)
    yield put({ type: actions.REQUEST_CONSTRUCTOR_CARD_LINK_WAS_ADDED, data })
    yield put({ type: actions.REQUEST_CONSTRUCTOR_CARD_CHANGED, workflowId })
  } catch (error) {
    yield* handleError(error)
  }
}

function* updateLink({ id, link, workflowId }) {
  try {
    const data = yield call(api.requestConstructor.updateCardLink, id, link)
    yield put({ type: actions.REQUEST_CONSTRUCTOR_CARD_LINK_WAS_UPDATED, data })
    yield put({ type: actions.REQUEST_CONSTRUCTOR_CARD_CHANGED, workflowId })
  } catch (error) {
    yield* handleError(error)
  }
}

function* removeLink({ id, link, workflowId }) {
  try {
    yield call(api.requestConstructor.removeCardLink, id, link)
    yield put({
      type: actions.REQUEST_CONSTRUCTOR_CARD_LINK_WAS_REMOVED,
      id,
      link,
    })
    yield put({ type: actions.REQUEST_CONSTRUCTOR_CARD_CHANGED, workflowId })
  } catch (error) {
    yield* handleError(error)
  }
}

export default function* watch() {
  yield all([
    fork(watchInitiate),
    fork(watchAdd),
    fork(watchUpdate),
    fork(watchRemove),
    fork(watchMove),
    fork(watchAddLink),
    fork(watchUpdateLink),
    fork(watchRemoveLink),
    fork(watchChanged),
  ])
}
