// @flow

import React from 'react'
import classnames from 'classnames'
import type { Node } from 'react'

type Props = {
  address: string,
  dwellersCount?: number,
  flatsCount: any,
  t: string => string,
}

const BuildingBalloon = (props: Props): Node => {
  const { dwellersCount, flatsCount, address } = props

  const modalTitleClass = classnames('modal__title map-balloon__title', {
    wrapped: address.length > 55,
  })

  return (
    <div className='map-balloon__body'>
      <h2 className={modalTitleClass}>{address}</h2>
      <div className='bar__cols bar__row'>
        <div className='bar__cell'>
          <span className='bar__cell-title'>{props.t('DwellersBalloon')}</span>
          <span className='bar__cell-value'>{dwellersCount || 0}</span>
        </div>
        {flatsCount === null ? null : (
          <div className='bar__cell'>
            <span className='bar__cell-title'>{props.t('InfoFlats')}</span>
            <span className='bar__cell-value'>{flatsCount || 0}</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default BuildingBalloon
