// @flow

import { get, pick } from 'lodash-es'
import * as Yup from 'yup'
import moment from 'moment'

import { getUserId, getUserName, isManagerUser } from '../../../utils/utils'
import {
  BUILDING,
  COMMIT_TYPE,
  DEADLINE,
  DEADLINE_DATE,
  DEADLINE_DAYS,
  DEADLINE_HOURS,
  DEADLINE_MINUTES,
  DEADLINE_REGEXP,
  DEADLINE_TEMPLATE,
  DEADLINE_TIME,
  DEFAULT_COMMIT_TYPE,
  DONE_COMMIT_TYPE,
  MANAGER,
  MAX_NOTE_LENGTH,
  MAX_TITLE_LENGTH,
  NOTES,
  PHASE,
  PHASE_TEMPLATE,
  POSITION,
  PRIORITY,
  REQUEST,
  TITLE,
  TYPE,
  YESNO_COMMIT_TYPE,
} from './ActivityModal.constants'
import { FORMIK_EMPTY_VALUE } from '../../../constants/validation'

export function isTemplateText(
  value: string,
  origin: string,
  activityTypes: Array<{ icon: { origin: string }, name: string }>
): boolean {
  return !!activityTypes.find(
    activityType =>
      activityType.name === value && activityType.icon.origin === origin
  )
}

export function getDeadlineTemplate(
  days?: ?number,
  hours?: ?number,
  minutes?: ?number
): string {
  const d = days ? `${days}d` : ''
  const h = hours ? `${days ? ' ' : ''}${hours}h` : ''
  const m = minutes ? `${days || hours ? ' ' : ''}${minutes}m` : ''

  return `${d}${h}${m}`
}

export function getDeadlineObject(deadline?: ?string): Object {
  if (deadline === FORMIK_EMPTY_VALUE) {
    return {
      [DEADLINE_DAYS]: null,
      [DEADLINE_HOURS]: null,
      [DEADLINE_MINUTES]: null,
    }
  }

  if (!deadline) {
    return {}
  }

  const matched = deadline.match(DEADLINE_REGEXP)

  if (!matched) {
    return {}
  }

  const deadlineDays = matched[1]
  const deadlineHours = matched[2]
  const deadlineMinutes = matched[3]

  let obj = {}

  obj[DEADLINE_DAYS] = deadlineDays
    ? +deadlineDays.trim().replace(/[dD]/, '')
    : null

  obj[DEADLINE_HOURS] = deadlineHours
    ? +deadlineHours.trim().replace(/[hH]/, '')
    : null

  obj[DEADLINE_MINUTES] = deadlineMinutes
    ? +deadlineMinutes.trim().replace(/[mM]/, '')
    : null

  return obj
}

export function getPriorityValue(priority?: ?Object): Object | '' {
  if (!priority) {
    return ''
  }

  const value = get(priority, 'id')
  const label = get(priority, 'value')

  if (!value || !label) {
    return ''
  }

  return { value, label }
}

export function getTitleValue(
  title?: ?string,
  activityTypes: Array<Object>
): string {
  return title || get(activityTypes, ['0', 'name'], '')
}

export function getRequestValue(initialRequest?: ?Object): Object | '' {
  if (!initialRequest) {
    return ''
  }

  const id = get(initialRequest, 'id')
  const title = get(initialRequest, 'title')
  const request_no = get(initialRequest, 'request_no')

  if (!id || !title || !request_no) {
    return ''
  }

  return { id, title, request_no }
}

export function getTypeValue(type?: ?Object, activityTypes: Array<Object>) {
  if (!type && (!activityTypes || !activityTypes.length)) {
    return ''
  }

  const typeValue = type || activityTypes[0]

  const id = get(typeValue, 'id')
  const name = get(typeValue, 'name')
  const icon = get(typeValue, 'icon')
  const enabled = get(typeValue, 'enabled', true)

  if (!id || !name || !icon) {
    return ''
  }

  return { id, name, icon, enabled }
}

export function getPhaseValue(phase?: ?Object): Object | '' {
  if (!phase) {
    return ''
  }

  const value = get(phase, 'id')
  const label = get(phase, 'name')

  if (!value || !label) {
    return ''
  }

  return { value, label }
}

export function getManagerValue(
  manager?: ?Object,
  user?: ?Object
): Object | '' {
  const currentManager = user && isManagerUser(user) ? user : null

  if (!manager && !currentManager) {
    return ''
  }

  const managerValue = manager || currentManager

  const id = getUserId(managerValue)
  const name = getUserName(managerValue)

  if (!id || !name) {
    return ''
  }

  return { id, name }
}

export function getDeadlineTimeValue(deadline?: ?string): number | '' {
  if (!deadline) {
    return ''
  }

  return (
    moment(deadline).get('hour') * 60 +
    Math.ceil(moment(deadline).get('minute') / 15) * 15
  )
}

export function getDeadlineValue(
  deadlineDate: string,
  deadlineTime: number
): ?string {
  if (!deadlineDate) {
    return undefined
  }

  return moment(deadlineDate)
    .set('hour', Math.floor(deadlineTime / 60))
    .set('minute', deadlineTime % 60)
    .format('YYYY-MM-DD HH:mm')
}

export function getInitialValues(
  activity?: ?Object,
  request?: ?Object,
  building?: ?Object,
  activityTypes: Array<Object>,
  user: Object
): Object {
  const initialData = activity || {}

  const initialRequest = initialData[REQUEST] || request
  const initialBuilding = initialData[BUILDING] || building
  const currentDate = moment(new Date())

  return {
    [TITLE]: getTitleValue(initialData[TITLE], activityTypes),
    [DEADLINE_TEMPLATE]: getDeadlineTemplate(
      initialData[DEADLINE_DAYS],
      initialData[DEADLINE_HOURS],
      initialData[DEADLINE_MINUTES]
    ),
    [COMMIT_TYPE]: initialData[COMMIT_TYPE] || DEFAULT_COMMIT_TYPE,
    [REQUEST]: getRequestValue(initialRequest),
    [MANAGER]: getManagerValue(initialData[MANAGER], user),
    [NOTES]: initialData[NOTES] || '',
    [TYPE]: getTypeValue(initialData[TYPE], activityTypes),
    [PRIORITY]: getPriorityValue(initialData[PRIORITY]),
    [DEADLINE_DATE]: activity
      ? initialData.deadline || ''
      : currentDate.format('YYYY-MM-DD'),
    [DEADLINE_TIME]: activity
      ? getDeadlineTimeValue(initialData.deadline)
      : getDeadlineTimeValue(currentDate),
    [BUILDING]: initialBuilding ? initialBuilding.address : '-',
    [PHASE]: getPhaseValue(initialData[PHASE]),
  }
}

export const timeChanged = (values: Object, init: Object) => {
  if (
    Object.keys(values).includes(DEADLINE_TIME) &&
    !Object.keys(values).includes(DEADLINE_DATE)
  ) {
    return { ...values, [DEADLINE_DATE]: init[DEADLINE_DATE] }
  } else if (
    Object.keys(values).includes(DEADLINE_DATE) &&
    !Object.keys(values).includes(DEADLINE_TIME)
  ) {
    return { ...values, [DEADLINE_TIME]: init[DEADLINE_TIME] }
  }

  return values
}

export function getValidationSchema(
  isTemplate?: boolean,
  t: Function,
  phaseList: ?Array<Object>
): Object {
  return Yup.object(
    Object.assign(
      {},
      {
        [COMMIT_TYPE]: Yup.mixed()
          .oneOf([DONE_COMMIT_TYPE, YESNO_COMMIT_TYPE])
          .required(),
        [TITLE]: Yup.string()
          .max(
            MAX_TITLE_LENGTH,
            t('MaxTitleLength', { maxLength: MAX_TITLE_LENGTH })
          )
          .required(t('Common:FieldIsRequired')),
        [NOTES]: Yup.string().max(
          MAX_NOTE_LENGTH,
          t('MaxNotesLength', { maxLength: MAX_NOTE_LENGTH })
        ),
        [PRIORITY]: Yup.object().shape({
          value: Yup.number(),
          label: Yup.string(),
        }),
        [TYPE]: Yup.object()
          .shape({
            id: Yup.number().required(),
            name: Yup.string().required(),
            icon: Yup.object().shape({
              origin: Yup.string().required(),
            }),
            enabled: Yup.boolean().required(),
          })
          .required(t('Common:FieldIsRequired')),
      },
      !isTemplate
        ? {
            [DEADLINE_DATE]: Yup.string(),
            [DEADLINE_TIME]: Yup.number().when(DEADLINE_DATE, {
              is: value => !!value,
              then: s => s.required(t('Common:FieldIsRequired')),
            }),
            [MANAGER]: Yup.object()
              .shape({
                id: Yup.number().required(),
                name: Yup.string().required(),
              })
              .required(t('Common:FieldIsRequired')),
            [BUILDING]: Yup.string(),
            [REQUEST]: Yup.object()
              .shape({
                id: Yup.number().required(),
                title: Yup.string().required(),
                request_no: Yup.number().required(),
              })
              .required(t('Common:FieldIsRequired')),
            [PHASE]: Yup.object().when(REQUEST, (value, schema) => {
              if (value && phaseList) {
                return schema
                  .shape({
                    value: Yup.number().required(),
                    label: Yup.string().required(),
                  })
                  .required(t('Common:FieldIsRequired'))
              }

              return schema.shape({
                value: Yup.number(),
                label: Yup.string(),
              })
            }),
          }
        : {
            [DEADLINE_TEMPLATE]: Yup.string().matches(DEADLINE_REGEXP, {
              message: t('WrongDeadlineFormat'),
            }),
          }
    )
  )
}

export function getNormalizedValues(
  values: Object,
  activity?: ?Object,
  isTemplate?: boolean,
  phaseTemplateId?: number
): Object {
  const commonValues = pick(values, [TITLE, TYPE, PRIORITY, COMMIT_TYPE, NOTES])

  const additionalValues = Object.assign(
    {},
    isTemplate
      ? {
          ...pick(values, [DEADLINE_TEMPLATE]),
        }
      : {
          ...pick(values, [
            REQUEST,
            PHASE,
            MANAGER,
            DEADLINE_DATE,
            DEADLINE_TIME,
          ]),
        },
    isTemplate && !activity
      ? {
          [POSITION]: 0,
          [PHASE_TEMPLATE]: phaseTemplateId,
        }
      : undefined
  )

  const mergedValues = { ...commonValues, ...additionalValues }

  return Object.assign(
    {},
    {
      [TITLE]: mergedValues[TITLE],
      [POSITION]: mergedValues[POSITION],
      [PHASE_TEMPLATE]: mergedValues[PHASE_TEMPLATE],
      [NOTES]:
        mergedValues[NOTES] === FORMIK_EMPTY_VALUE ? null : mergedValues[NOTES],
      [DEADLINE]:
        mergedValues[DEADLINE_DATE] === FORMIK_EMPTY_VALUE
          ? null
          : getDeadlineValue(
              mergedValues[DEADLINE_DATE],
              mergedValues[DEADLINE_TIME]
            ),
      [COMMIT_TYPE]: mergedValues[COMMIT_TYPE],
      [TYPE]: mergedValues[TYPE] && mergedValues[TYPE].id,
      [PRIORITY]:
        mergedValues[PRIORITY] === FORMIK_EMPTY_VALUE
          ? null
          : mergedValues[PRIORITY] && mergedValues[PRIORITY].value,
      [REQUEST]: mergedValues[PHASE]
        ? undefined
        : mergedValues[REQUEST] && mergedValues[REQUEST].id,
      [MANAGER]: mergedValues[MANAGER] && mergedValues[MANAGER].id,
      [PHASE]: mergedValues[PHASE] && mergedValues[PHASE].value,
      ...getDeadlineObject(mergedValues[DEADLINE_TEMPLATE]),
    }
  )
}
