// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  withGoogleMap,
  GoogleMap,
  Marker,
  withScriptjs,
} from 'react-google-maps'
import { InfoBox } from 'react-google-maps/lib/components/addons/InfoBox'
import { set } from 'lodash-es'

import Balloon from './Balloon'
import { getUk } from '../../utils/commonSelectors'

const __API_KEY__ = 'AIzaSyAlEKOXU776FD89YnZn3f8y2JSNo0kKjIY'
const gmapBaseUrl = 'https://maps.googleapis.com/maps/api/js'
let gmapQueryObject = {
  v: '3.exp',
  key: __API_KEY__,
  libraries: 'places',
}

type Props = {
  buildingMarker: Object,
  center: Object,
  editMode: boolean,
  onChangeAddress: Object => void,
  onMapClick: Object => void,
  options: Object,
  uk: Object,
  zoom: number,
}
type State = {
  buildingMarker: ?Object,
  keyForReload: string,
  zoom: number,
}

const AsyncGoogleMap = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap {...props} ref={props.onMapLoad} onClick={props.onMapClick}>
      {props.markers.map(marker => {
        const { balloon, showInfo } = marker

        const options = {
          closeBoxURL: '',
          boxClass: 'google-infobox',
          enableEventPropagation: true,
          alignBottom: true,
          boxStyle: {
            overflow: 'visible',
            left: '-50%',
            bottom: '50px',
          },
          maxWidth: 0,
        }

        return (
          <Marker
            key={marker.name}
            {...marker}
            onClick={() => props.onMarkerClick(marker)}
            onDrag={event => props.onMarkerDrag(marker, event)}
            onDragEnd={props.onMarkerDragEnd}
          >
            {showInfo && (
              <InfoBox defaultPosition={marker.position} options={options}>
                <Balloon onClose={() => props.onMarkerClose(marker)}>
                  {balloon}
                </Balloon>
              </InfoBox>
            )}
          </Marker>
        )
      })}
    </GoogleMap>
  ))
)

class GoogleMapWrapper extends Component<Props, State> {
  state = {
    buildingMarker: null,
    keyForReload: '1',
    zoom: this.props.zoom || 17,
  }

  map = null

  geocoder = null

  componentDidUpdate(prevProps) {
    const { buildingMarker } = this.props

    if (prevProps.buildingMarker !== buildingMarker) {
      if (
        prevProps.buildingMarkers === undefined &&
        buildingMarker === undefined
      ) {
        this.reInitMap()
      }

      this.setState({ buildingMarker })
    }
  }

  onMapLoad = (map: Object) => {
    this.map = map
    this.geocoder = new window.google.maps.Geocoder()
  }

  onMarkerDragEnd = () => {
    this.reInitMap()
  }

  onMarkerDrag = (marker: Object, e: Object) => {
    this.updateAddress(e)
  }

  onBoundsChanged = () => {
    if (!this.map || !this.props.editMode) {
      return
    }

    const zoom = this.map.getZoom()
    // $FlowFixMe
    const center = this.map.getCenter()
    this.props.onChangeAddress({
      widget_lattitude: center.lat(),
      widget_longitude: center.lng(),
      widget_lattitude_str: `${center.lat()}`,
      widget_longitude_str: `${center.lng()}`,
      widget_zoom: zoom,
    })
  }

  updateAddress = (event: Object) => {
    if (!this.geocoder) {
      return
    }

    this.geocoder.geocode({ location: event.latLng }, (results, status) => {
      const changedData = {
        lattitude: event.latLng.lat(),
        longitude: event.latLng.lng(),
      }

      if (status === 'OK') {
        if (results[0]) {
          set(changedData, 'value', results[0].formatted_address)
        }
      }

      this.props.onChangeAddress(changedData)
    })
  }

  handleMarkerClick = (marker: Object) => {
    if (this.props.editMode || !marker.balloon) {
      return
    }

    this.setState({
      buildingMarker: {
        ...marker,
        showInfo: true,
      },
    })
  }

  handleMarkerClose = (marker: Object) => {
    this.setState({
      buildingMarker: {
        ...marker,
        showInfo: false,
      },
    })
  }

  handleMapClick = (e: Object) => {
    if (!this.props.editMode) {
      return
    }

    this.reInitMap()

    this.props.onMapClick(e.latLng)
    this.updateAddress(e)
  }

  reInitMap = () => {
    if (this.map) {
      this.setState({
        keyForReload: this.getUniqueKeyForReload(),
        zoom: this.map.getZoom(),
      })
    }
  }

  getUniqueKeyForReload = () => {
    return `${new Date().getTime()}`
  }

  render() {
    const {
      center,
      options,
      uk: { language_obj },
    } = this.props
    const languageCode = language_obj?.code.split('_').shift()

    if (languageCode) {
      gmapQueryObject.language = languageCode
    }

    const markers = []

    if (this.state.buildingMarker) {
      markers.push(this.state.buildingMarker)
    }

    const defaultOptions = {
      disableDefaultUI: true,
      zoomControl: true,
    }
    const props = {
      googleMapURL: `${gmapBaseUrl}?${new URLSearchParams(
        gmapQueryObject
      ).toString()}`,
      options: {
        ...defaultOptions,
        ...options,
      },
      zoom: this.state.zoom,
      center: center || {
        lat: 48.210033,
        lng: 16.363449,
      },
    }

    return (
      <AsyncGoogleMap
        {...props}
        loadingElement={
          <div style={{ height: '100%', backgroundColor: '#ffffff' }} />
        }
        containerElement={<div style={{ height: '100%' }} />}
        mapElement={<div style={{ height: '100%' }} />}
        markers={markers}
        key={this.state.keyForReload}
        onMapLoad={this.onMapLoad}
        onBoundsChange={this.onBoundsChanged}
        onMarkerClick={this.handleMarkerClick}
        onMarkerClose={this.handleMarkerClose}
        onMarkerDrag={this.onMarkerDrag}
        onMarkerDragEnd={this.onMarkerDragEnd}
        onDragEnd={this.onBoundsChanged}
        onMapClick={this.handleMapClick}
        onZoomChanged={this.onBoundsChanged}
      />
    )
  }
}

const mapStateToProps = state => ({
  uk: getUk(state),
})

export default connect(mapStateToProps)(GoogleMapWrapper)
