// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import TopPagination from '../Pagination/TopPagination'
import Button from '../Button'
import Toolbox from '../Toolbox'

type Props = { handleClick: Function, meta: Object }

const WorkflowListToolbox = (props: Props): Node => {
  const { meta } = props

  const { t } = useTranslation('Workflow')

  return (
    <Toolbox>
      <div className='toolbox__cell'>
        <Button.Save onClick={props.handleClick}>{t('ButtonAdd')}</Button.Save>
      </div>
      <div className='toolbox__cell toolbox__cell--right'>
        <TopPagination
          classes='pager pager--large pager--default-2'
          meta={meta}
        />
      </div>
    </Toolbox>
  )
}

export default WorkflowListToolbox
