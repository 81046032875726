// @flow

import React from 'react'
import { createFragmentContainer, graphql } from 'react-relay'
import { useTranslation } from 'react-i18next'

import { Cell } from '../../../../components/Block'

import styles from './OrderProfileComment.module.scss'

const OrderProfileComment = props => {
  const {
    data: { comment },
  } = props

  const { t } = useTranslation('Orders')

  const text = comment ? comment : t('NoComment')

  return (
    <Cell className={styles.cell} valueClassName={styles.value}>
      {text}
    </Cell>
  )
}

export default createFragmentContainer(OrderProfileComment, {
  data: graphql`
    fragment OrderProfileComment_data on CustomerOrderNode {
      comment
    }
  `,
})
