// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import NewTableHeader from '../../components/NewTable/NewTableHeader'
import NewTableHeaderCell from '../../components/NewTable/NewTableHeaderCell'
import NewTableHeaderRow from '../../components/NewTable/NewTableHeaderRow'

const OrdersTableHeader = (props): Node => {
  const { isAdmin } = props

  const { t } = useTranslation('Orders')

  return (
    <NewTableHeader>
      <NewTableHeaderRow>
        <NewTableHeaderCell title={t('provider')} style={{ width: '20%' }} />
        {isAdmin && (
          <NewTableHeaderCell title={t('owner')} style={{ width: '20%' }} />
        )}
        <NewTableHeaderCell title={t('order')} style={{ width: '35%' }} />
        <NewTableHeaderCell title={t('price')} style={{ width: '15%' }} />
        <NewTableHeaderCell title={t('status')} style={{ width: '15%' }} />
        <NewTableHeaderCell title={t('createdAt')} style={{ width: '15%' }} />
      </NewTableHeaderRow>
    </NewTableHeader>
  )
}

export default OrdersTableHeader
