// @flow

import React from 'react'
import { useSelector } from 'react-redux'
import type { Node } from 'react'

import { getUk } from '../../../../utils/commonSelectors'

import styles from './Logo.module.scss'

type ukType = {
  logo_obj: { origin: string },
  name: string,
}

const Logo = (): Node => {
  const uk: ukType = useSelector(state => getUk(state))

  const {
    logo_obj: { origin },
    name,
  } = uk

  return (
    <div className={styles.logo}>
      <img src={origin} alt={name} />
    </div>
  )
}

export default Logo
