// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import { EntityIcon } from '../../../Icon'

import styles from './NumberCell.module.scss'
import { Link } from 'react-router-dom'

type Props = {
  canToggle?: boolean,
  checked: ?boolean,
  flat: Object,
  toggle: () => void,
}

const NumberCell = (props: Props) => {
  const { checked, flat, canToggle } = props
  const { id, number } = flat
  const { t } = useTranslation('Building')

  const text = `${t('flat_number_title')} ${number}`
  const cellClass = classnames('ti', styles['number-cell'])

  return (
    <div className='table__cell' style={{ width: '100%' }}>
      <div className='table__cell-wrapper'>
        <div className={cellClass}>
          {canToggle && (
            <label
              className='checkbox checkbox--medium checkbox--default checkbox--notext ti-checkbox'
              htmlFor={`flat-${id}`}
              onClick={e => e.stopPropagation()}
            >
              <input
                type='checkbox'
                id={`flat-${id}`}
                value={id}
                checked={checked}
                onChange={props.toggle}
              />
              <span />
            </label>
          )}
          <Link to={`/flat/${id}/`}>
            <EntityIcon id='apartment' />
            <span title={text}>{text}</span>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default NumberCell
