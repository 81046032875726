// @flow

import React from 'react'
import type { Node } from 'react'

type Props = {
  children?: Node,
  label: string,
  linkHash?: string,
  notAllowed?: boolean,
  onClick?: () => void,
  style?: Object,
}

const Pan = (props: Props): Node => (
  <div className='tab-pane active' style={props.style}>
    {props.children}
  </div>
)

export default Pan
