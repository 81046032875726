// @flow

export const RESEND_INVITE_POP_UP_INITIATE = 'RESEND_INVITE_POP_UP_INITIATE'
export const RESEND_INVITE_POP_UP_RESET = 'RESEND_INVITE_POP_UP_RESET'
export const RESEND_INVITE_POP_UP_SET_BUILDING =
  'RESEND_INVITE_POP_UP_SET_BUILDING'
export const RESEND_INVITE_POP_UP_LOAD_FLATS = 'RESEND_INVITE_POP_UP_LOAD_FLATS'
export const RESEND_INVITE_POP_UP_UPDATE_FLATS =
  'RESEND_INVITE_POP_UP_UPDATE_FLATS'
export const RESEND_INVITE_POP_UP_ADD_MANAGER =
  'RESEND_INVITE_POP_UP_ADD_MANAGER'
export const RESEND_INVITE_POP_UP_UPDATE_USER =
  'RESEND_INVITE_POP_UP_UPDATE_USER'
export const RESEND_INVITE_POP_UP_SAVE = 'RESEND_INVITE_POP_UP_SAVE'
export const RESEND_INVITE_POP_UP_SAVED = 'RESEND_INVITE_POP_UP_SAVED'
export const RESEND_INVITE_POP_UP_SET_MESSAGE =
  'RESEND_INVITE_POP_UP_SET_MESSAGE'
export const RESEND_INVITE_POP_UP_DELETE_USER_POPUP =
  'RESEND_INVITE_POP_UP_DELETE_USER_POPUP'
export const RESEND_INVITE_POP_UP_UPDATE_ROLE =
  'RESEND_INVITE_POP_UP_UPDATE_ROLE'
export const RESEND_INVITE_POP_UP_ERROR = 'RESEND_INVITE_POP_UP_ERROR'
