import React, { useMemo } from 'react'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import styles from './ContactFormSelect.module.scss'
import { useLoad } from '../../../hooks'
import { COMPANY_SELECT_GROUP, SELECT_GROUP } from '../ContactForm.constants'

import NewSelectSimple from '../../NewSelectSimple'
import api from '../../../core/api'
import { useSelector } from 'react-redux'
import { omit } from 'lodash-es'

const ContactFormSelect = ({
  index,
  onGetField,
  onChangeField,
  resetForm,
  excludeFields,
  isExternal = false,
}) => {
  const { t } = useTranslation('ContactForm')
  const {
    status: { isCompany },
  } = useFormikContext()

  const [typesFields, isLoading] = useLoad(api.contactForm.getTypes)

  const CURRENT_SELECT_GROUP = isCompany ? COMPANY_SELECT_GROUP : SELECT_GROUP

  const { user_profile_contact_type: contactTypes } = useSelector(
    state => state.init
  )

  const options = useMemo(() => {
    return isExternal
      ? Object.keys(omit(contactTypes, excludeFields)).map(id => {
          return { value: parseInt(id), label: t(contactTypes[id]) }
        })
      : typesFields?.results?.objects
          ?.filter(({ id }) => CURRENT_SELECT_GROUP.includes(id))
          .map(({ id, name }) => {
            return {
              value: id,
              label: t(name),
            }
          })
  }, [index, isExternal, typesFields])

  const currentField = onGetField(index)

  const value = options?.find(
    option => option.value === currentField.contact_type
  )

  const onChange = ({ value }) => {
    resetForm()
    onChangeField(index, {
      ...currentField,
      contact_type: value,
      values: '',
      value: '',
      errors: {},
      touched: {},
    })
  }

  return (
    <NewSelectSimple
      loading={isLoading}
      className={styles.selectField}
      options={options}
      value={value}
      onChange={onChange}
    />
  )
}

export default ContactFormSelect
