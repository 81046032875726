// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import classnames from 'classnames'

import Loader from '../../components/Loader'
import * as actions from './ConfirmEmail.actionTypes'
import styles from './ConfirmEmail.module.scss'
import { getUk } from '../../utils/commonSelectors'

type Props = {
  email: string,
  error: Object,
  match: Object,
  t: string => string,
  uk: Object,
  working: boolean,
}

class ConfirmEmail extends Component<Props> {
  componentDidMount() {
    const {
      match: {
        params: { hash },
      },
    } = this.props
    this.props.dispatch({ type: actions.CONFIRM_EMAIL_SEND, hash })
  }

  showLogo = () => {
    if (!this.props.uk.logo) return null

    const {
      uk: {
        logo: { origin },
        name,
      },
    } = this.props

    return (
      <div className='header_logo_login'>
        <img src={origin} alt={name} />
      </div>
    )
  }

  renderMessage = () => {
    const { email, error } = this.props

    if (error) {
      return (
        <div className='wronglink_wrap'>
          <div className='b_main_title'>
            <div className='wronglink_ico' />
            <h1>{this.props.t('ErrorTitle')}</h1>
          </div>
        </div>
      )
    }

    const linkClassName = classnames(
      'response__back button button--default button-404',
      styles.link
    )

    return (
      <div className='newpass_wrap'>
        <div className='b_main_title'>
          <div className='newpass_ico' />
          <h1>
            {this.props.t('SuccessTitleFirst')}
            <br />
            {this.props.t('SuccessTitleSecond', { email })}
          </h1>
        </div>

        <div className={styles.linkContainer}>
          <Link className={linkClassName} to='/'>
            {this.props.t('LoginLink')}
          </Link>
        </div>
      </div>
    )
  }

  render() {
    const { working, uk } = this.props
    const headerClassName = classnames('site_header_left', styles.header)

    return (
      <div className='site_wrap'>
        <header className='site_header'>
          <div className='site_header_helper' />
          <div className='site_header_content'>
            <div className='b_center'>
              <div className='b_table'>
                <div className='b_table__cell' style={{ width: '100%' }}>
                  <div className={headerClassName}>
                    <div className='site_header_text'>
                      <span className='sht__full'>{uk.name || ''}</span>
                      <span className='sht__short'>{uk.name || ''}</span>
                    </div>
                  </div>
                  <div className='clear_both' />
                </div>
              </div>
              {this.showLogo()}
            </div>
          </div>
        </header>
        <main className='site_workarea'>
          {working ? <Loader /> : this.renderMessage()}
        </main>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state.confirmEmail,
  uk: getUk(state),
})

export default compose(
  withTranslation('ConfirmEmail'),
  connect(mapStateToProps)
)(ConfirmEmail)
