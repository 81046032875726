// @flow

import { put, call, fork, takeEvery } from 'redux-saga/effects'
import { replace, push } from 'connected-react-router'
import type { Saga } from 'redux-saga'

import api from '../../core/api'
import BrowserStorage from '../../utils/browserStorage'
import * as actions from './SharedRequest.actionTypes'
import { serverError } from '../../components/Layout/Layout.actions'
import { INIT_LOADED } from '../../components/init/init.actionTypes'
import { LOGIN_SUCCESS } from '../../components/Auth/Login/Login.actionTypes'

function* watchUpdateInit() {
  yield takeEvery(actions.UPDATE_INIT_DATA, updateInitData)
}

function* updateInitData({ hash }) {
  try {
    const response = yield call(api.request.getSharedRequest, hash)
    BrowserStorage.set('token', response.user_token)
    const user = yield call(api.profile.getMyProfile)
    const initData = yield call(api.system.init)

    yield put({ type: INIT_LOADED, data: initData })
    yield put({
      type: LOGIN_SUCCESS,
      response: { key: response.user_token, ...user },
    })
    yield put({ type: actions.SHARED_REQUEST_SET_ID, id: response.request.id })
    yield put(push(`/request/${response.request.id}`))
  } catch (error) {
    yield put(replace('/404'))
    yield put(serverError(error))
  }
}

export default function* watch(): Saga<void> {
  yield fork(watchUpdateInit)
}
