// @flow

import React from 'react'

import styles from './FallbackIcon.module.scss'

const FallbackIcon = (): React$Element<'div'> => {
  return <div className={styles.fallback} />
}

export default FallbackIcon
