// @flow

import { useState, useEffect } from 'react'

export const usePermissions = (getApi, patchApi) => {
  const [permissions, setPermissions] = useState(null)

  useEffect(() => {
    getApi().then((data: Object) => setPermissions(data))
  }, [])

  const handleClick = (permission: { [string]: boolean }) => {
    patchApi(permission).then((data: Object) => setPermissions(data))
  }

  return [permissions, handleClick]
}
