// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import RequestTypeFields from './RequestTypeFields'
import Pan from './Tabs/Pan'
import RouterTabs from './Tabs/RouterTabs'
import Content from './Content'
import ActivityType from './ActivityType'
import ActivityPriority from './ActivityPriority'
import InvoiceType from './InvoiceType'
import PaymentType from './PaymentType'
import Salutation from './Salutation'
import RoomType from './RoomType'
import LivingType from './LivingType'
import InsuranceType from './InsuranceType'
import Status from './Status'
import PreferredCommunication from './PreferredCommunication'
import FlatClassifierLazy from './FlatClassifier'

import styles from './ObjectsAndFields.module.scss'

const ObjectsAndFields = (): Node => {
  const { t } = useTranslation('ObjectsAndFields')

  return (
    <Content style={{ marginTop: '20px' }}>
      <section className='unit unit--default'>
        <RouterTabs>
          <Pan
            id='objects_and_fields_requests_tab'
            label={t('Requests')}
            linkHash='#requests-fields'
            count={1}
          >
            <RequestTypeFields />
          </Pan>
          <Pan
            id='objects_and_fields_activity_type_tab'
            label={t('ActivityType')}
            linkHash='#activity-types'
          >
            <ActivityType />
          </Pan>
          <Pan
            id='objects_and_fields_activity_priority_tab'
            label={t('ActivityPriority')}
            linkHash='#activity-priorities'
          >
            <ActivityPriority />
          </Pan>

          <Pan id='BudgetPlan' label={t('BudgetPlan')} linkHash='#budget-plan'>
            <div className={styles.wrap}>
              <InvoiceType />
            </div>
            <div className={styles.wrap}>
              <PaymentType />
            </div>
          </Pan>
          <Pan
            id='objects_and_fields_salutation_tab'
            label={t('Salutation')}
            linkHash='#salutation'
          >
            <Salutation />
          </Pan>
          <Pan id='technical' label={t('Technical')} linkHash='#technical'>
            <div className={styles.wrap}>
              <RoomType />
            </div>
            <div className={styles.wrap}>
              <LivingType />
            </div>
            <div className={styles.wrap}>
              <InsuranceType />
            </div>
            <div className={styles.wrap}>
              <Status />
            </div>
            <div className={styles.wrap}>
              <PreferredCommunication />
            </div>
            <div className={styles.wrap}>
              <FlatClassifierLazy type='condition' />
            </div>
            <div className={styles.wrap}>
              <FlatClassifierLazy type='category' />
            </div>
            <div className={styles.wrap}>
              <FlatClassifierLazy type='contract' />
            </div>
            <div className={styles.wrap}>
              <FlatClassifierLazy type='specifics' />
            </div>
          </Pan>
        </RouterTabs>
      </section>
    </Content>
  )
}

export default ObjectsAndFields
