// @flow

import React from 'react'
import { Link } from 'react-router-dom'

import FlatAddressInfo from './FlatAddressInfo'

const FlatAddressView = props => {
  const {
    address: { value },
    building,
  } = props

  return (
    <div>
      {value && building ? (
        <h3 className='apartment-profile__title-2 u-a20__heading '>
          <Link className='' to={`/building/${building}`}>
            <span className='u-a20__heading-text-big'>{value}</span>
          </Link>
        </h3>
      ) : (
        <h3 className='apartment-profile__title-2'>
          <span>{value}</span>
        </h3>
      )}
      <FlatAddressInfo />
    </div>
  )
}

export default FlatAddressView
