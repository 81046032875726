// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import type { Node } from 'react'

import TopPagination from '../Pagination/TopPagination'
import Button from '../Button'
import Toolbox from '../Toolbox'
import ToolboxCell from '../Users/UserList/Toolbox/ToolboxCell'

type Props = {
  checked: boolean,
  isLoading: boolean,
  meta: Object,
  openRemovalModal?: Function,
  selected: boolean,
  selectedItems?: ?Array<any>,
  setRemovingItems: Function,
  setSelected: Function,
  templates: Array<Object>,
}

const PipelinesToolbox = (props: Props): Node => {
  const { meta, templates, selectedItems, selected, checked, isLoading } = props

  const { t } = useTranslation('Pipeline')

  return (
    <Toolbox
      showSelectAllCheckbox
      checked={checked}
      setSelected={props.setSelected}
      selected={selected}
      items={templates}
      itemValueKey='id'
    >
      {(!selectedItems || selectedItems.length === 0) && (
        <ToolboxCell>
          <Link to='/settings/pipeline/create'>
            <Button.Upload icon='add' disabled={isLoading}>
              {t('AddPipelineButton')}
            </Button.Upload>
          </Link>
        </ToolboxCell>
      )}
      {selectedItems && selectedItems.length !== 0 && (
        <ToolboxCell>
          <Button.Remove
            disabled={!selectedItems.length || isLoading}
            onClick={() => props.setRemovingItems(selectedItems)}
          >
            {t('RemovePipelines')}
          </Button.Remove>
        </ToolboxCell>
      )}
      <ToolboxCell isRight>
        <TopPagination
          classes='pager pager--large pager--default-2'
          meta={meta}
        />
      </ToolboxCell>
    </Toolbox>
  )
}

export default PipelinesToolbox
