// @flow

import React from 'react'
import type { Node } from 'react'

import styles from './Title.module.scss'

type Props = { t: string => string }

const Title = (props: Props): Node => (
  <div className={styles.title}>
    <h2 className={styles.header}>{props.t('CompanyRegistrationTitle')}</h2>
  </div>
)

export default Title
