// @flow

import React, { useState, useEffect } from 'react'
import type { Node } from 'react'

import NewSelectSimple from '../../../NewSelectSimple'

import styles from './TemplateVariables.module.scss'

type Props = {
  api: Object => Promise<Object>,
  handleSelect: string => void,
  isShowSelected?: Boolean,
  name?: string,
  name_exclude?: string,
  ns?: string,
  t: string => string,
  tags: Array<string>,
}

export const TemplateVariables = (props: Props): Node => {
  const { tags, api, ns, isShowSelected, name_exclude, name } = props

  const [variables, setVariables] = useState([])
  const [loading, setLoading] = useState(true)
  const [selectedVariable, setSelectedVariable] = useState({})

  const [val, setVal] = useState(0)

  useEffect(() => {
    const params = Object.assign(
      {},
      tags && tags.length ? { tags: tags.join(',') } : undefined,
      { name_exclude }
    )

    api(params)
      .then(data =>
        setVariables(
          data.results.objects.map(variable => ({
            ...variable,
            label: props.t(variable.description, ns && { ns }),
            value: variable.name,
            name: variable.label,
          }))
        )
      )
      .finally(() => setLoading(false))
  }, [tags])

  useEffect(() => {
    if (name && variables.length > 0) {
      const option = variables.find(v => v.name === name)

      if (option) {
        handleChange(option)
      }
    }
  }, [variables])

  const handleChange = option => {
    if (option) {
      setVal(val + 1)
      props.handleSelect(option.name)
      setSelectedVariable(option)
    }
  }

  return (
    <NewSelectSimple
      useSearch
      key={val}
      value={isShowSelected && selectedVariable}
      getListTitle={opt => opt.label}
      loading={loading}
      placeholder={props.t('VariableSelect', ns && { ns })}
      searchPlaceholder={props.t('VariableNameSearchPlaceholder', ns && { ns })}
      options={variables}
      className={styles.select}
      openClass={styles.open}
      buttonIcon='variable'
      onChange={handleChange}
    />
  )
}

export default TemplateVariables
