// @flow

import Address from './Address'
import Created from './Created'
import Email from './Email'
import Role from './Role'
import Updated from './Updated'
import Source from './Source'
import Phone from './Phone'
import Category from './Category'

export type Props = {
  user: Object,
}

export default {
  Address,
  Created,
  Email,
  Role,
  Updated,
  Source,
  Phone,
  Category,
}
