// @flow

import React, { useState } from 'react'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { debounce } from 'lodash-es'

import Icon from '../../../components/Icon'

import styles from './Search.module.scss'

const Search = props => {
  const { t } = useTranslation('Settings')
  const { name, placeholderKey, className, placeHolder } = props

  const [focused, setFocused] = useState(false)

  const handleFocus = () => setFocused(true)
  const handleBlur = () => setFocused(false)

  const inputClass = classnames(
    'input input--large input--block input--default',
    className || styles.title
  )

  const handleChange = debounce(value => {
    props.setSearchValue(value.toLowerCase())
  }, 200)

  return (
    <div className={inputClass}>
      <input
        autoFocus
        autoComplete='off'
        autoCorrect='off'
        name={name}
        placeholder={placeHolder || t(placeholderKey)}
        spellCheck={false}
        onChange={e => handleChange(e.target.value)}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      <Icon className='input__icon' id='magnifier' colored={focused} />
    </div>
  )
}

export default Search
