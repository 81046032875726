// @flow

import React, { Component, Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import MediaQuery from 'react-responsive'
import { take } from 'lodash-es'
import { compose } from 'redux'

import Widget from '../../../containers/Widget'
import { getActiveWidget } from '../../../containers/Widget/Widget.selectors'
import { DocumentPreview } from '../../Documents'
import EmptyWidget from '../EmptyWidget'
import Loader from '../../Loader'
import TileList from '../../TileList'
import { WIDGET_TYPES } from '../../../containers/widgets/widgets'
import * as actions from './WidgetFiles.actionTypes'
import {
  MAX_COUNT,
  MEDIA_MIN_WIDTHS,
  ITEM_INDENT,
  ROW_LENGTHS,
  ROWS_COUNT,
  THUMBNAIL_SIZE,
} from './WidgetFiles.constants'
import { getUser } from '../../../utils/commonSelectors'

type Props = {
  dispatch: Object => void,
  files: Array<Object>,
  initiated: boolean,
  isMail?: boolean,
  maxCount: number,
  t: string => string,
  working: boolean,
}

class WidgetFiles extends Component<Props> {
  static defaultProps = {
    maxCount: MAX_COUNT,
  }

  componentDidUpdate(prevProps) {
    const { activeWidget, thread, chatroom, email } = this.props

    const prevOpen = prevProps.activeWidget === WIDGET_TYPES.files
    const nextOpen = activeWidget === WIDGET_TYPES.files

    let entity = null

    if (chatroom) {
      entity = { chatroom }
    } else if (thread) {
      entity = { thread }
    } else if (email) {
      entity = { email }
    }

    if (!prevOpen && nextOpen && entity) {
      this.props.dispatch({ type: actions.WIDGET_FILES_LOAD, ...entity })
    }
  }

  componentWillUnmount() {
    this.reset()
  }

  reset = () => {
    this.props.dispatch({
      type: actions.WIDGET_FILES_RESET,
    })
  }

  view = (lightboxIndex: number) => () => {
    this.props.setFileIndex(lightboxIndex)
  }

  renderItem = (file: Object, index: number) => (
    <DocumentPreview
      canHover
      key={file.id}
      file={file}
      thumbnailSize={THUMBNAIL_SIZE}
      onClick={this.view(index)}
    />
  )

  renderList = (rowLength: number) => {
    const { files, maxCount } = this.props
    const count =
      files.length === maxCount
        ? Math.floor(files.length / rowLength) * rowLength
        : maxCount

    return (
      <TileList
        boxIndent={ITEM_INDENT}
        itemIndent={ITEM_INDENT}
        maxRows={ROWS_COUNT}
        rowLength={rowLength}
      >
        {take(files, count).map(this.renderItem)}
      </TileList>
    )
  }

  renderItems = () => {
    const { files } = this.props

    if (files.length) {
      return (
        <Fragment>
          <MediaQuery maxWidth={MEDIA_MIN_WIDTHS.medium - 1}>
            {this.renderList(ROW_LENGTHS.small)}
          </MediaQuery>
          <MediaQuery
            minWidth={MEDIA_MIN_WIDTHS.medium}
            maxWidth={MEDIA_MIN_WIDTHS.large - 1}
          >
            {this.renderList(ROW_LENGTHS.medium)}
          </MediaQuery>
          <MediaQuery minWidth={MEDIA_MIN_WIDTHS.large}>
            {this.renderList(ROW_LENGTHS.large)}
          </MediaQuery>
        </Fragment>
      )
    }

    return (
      <EmptyWidget icon='file' title={this.props.t('ThereAreNoFilesYet')} />
    )
  }

  render() {
    const { initiated, activeWidget } = this.props

    const open = activeWidget === WIDGET_TYPES.files

    if (!initiated && open) {
      return <Loader text={false} type='medium' />
    }

    const listStyle = {
      height: open ? 'inherit' : 0, // For IE11
    }

    return (
      <Widget name={WIDGET_TYPES.files} title={this.props.t('Common:AllFiles')}>
        <div style={listStyle}>{open && this.renderItems()}</div>
      </Widget>
    )
  }
}

const mapStateToProps = state => ({
  ...state.WidgetFiles,
  activeWidget: getActiveWidget(state),
  user: getUser(state),
})

export default compose(
  withTranslation('WidgetFiles'),
  connect(mapStateToProps)
)(WidgetFiles)
