// @flow

import React from 'react'

import NewTableBody from '../NewTable/NewTableBody'
import EmailsTableBodyRow from './EmailsTableBodyRow'

const EmailsTableBody = props => {
  const { threads, requestId, scope, object } = props

  return (
    <>
      {threads.map(thread => (
        <NewTableBody hovered key={thread.uuid}>
          <EmailsTableBodyRow
            scope={scope}
            object={object}
            selectedItems={props.selectedItems}
            handleChange={props.handleChange}
            thread={thread}
            setLoading={props.setLoading}
            openAudienceModal={props.openAudienceModal}
            openActivitiesModal={props.openActivitiesModal}
            openMoveModal={props.openMoveModal}
            openNotesModal={props.openNotesModal}
            requestId={requestId}
            setUuid={props.setUuid}
            setAudience={props.setAudience}
            setSubject={props.setSubject}
            setActionModal={props.setActionModal}
            openRemoveModal={props.openRemoveModal}
            setActivity={props.setActivity}
          />
        </NewTableBody>
      ))}
    </>
  )
}

export default EmailsTableBody
