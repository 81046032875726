// @flow

import React, { useEffect, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import type { Node } from 'react'

import Button from '../Button'
import Input from '../Input/Input'
import {
  RECOVERY,
  RECOVERY_ERROR,
  RECOVERY_SUCCESS,
  RECOVERY_RESET,
} from './Recovery.actionTypes'
import LoginLink from '../LoginLink'
import recoveryReducer from './Recovery.reducer.js'
import { recover } from '../../../core/api/api.login'
import { EMAIL } from './Recovery.constants'

const RecoveryForm = (): Node => {
  const { t } = useTranslation('LoginForm')

  const [{ isWorking, isSuccess, error, status }, dispatch] = useReducer(
    recoveryReducer,
    {
      error: null,
      status: null,
      isWorking: false,
      isSuccess: false,
    }
  )

  useEffect(() => {
    dispatch({
      type: RECOVERY_RESET,
    })
  }, [])

  useEffect(() => {
    if (isWorking) {
      recover({ email: formik.values[EMAIL] })
        .then(() => dispatch({ type: RECOVERY_SUCCESS }))
        .catch(error => {
          dispatch({
            type: RECOVERY_ERROR,
            error: error.message.response.data.errors,
            status: error.message.response.data.status_code,
          })
        })
    }
  }, [isWorking])

  const formik = useFormik({
    validateOnChange: true,
    enableReinitialize: true,
    initialValues: { [EMAIL]: '' },
    validationSchema: Yup.object({
      [EMAIL]: Yup.string().required(t('Common:FieldIsRequired')),
    }),
    onSubmit: () => {
      dispatch({
        type: RECOVERY,
      })
    },
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      {isSuccess ? (
        <div className='login_anno_text' style={{ textAlign: 'left' }}>
          {t('Recover_Sended')}
        </div>
      ) : (
        <>
          <Input
            type='text'
            name={EMAIL}
            placeholder={t('EnterEmail')}
            value={formik.values[EMAIL]}
            errors={formik.errors[EMAIL] ? [formik.errors[EMAIL]] : []}
            onChange={formik.handleChange}
          />
          <Button text={t('RecoverPassword')} type='submit' />
        </>
      )}
      {error && (
        <div className='error' style={{ marginBottom: '24px' }}>
          {t(status === 429 ? 'EnterEmailAttemptsExceeded' : error)}
        </div>
      )}
      <LoginLink linkText={t('Back')} />
    </form>
  )
}

export default RecoveryForm
