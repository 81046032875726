// @flow

import type { Location } from 'react-router-dom'

import { UUID_KEY, OUTBOUND_UUID_KEY } from './NewWizard.constants'

export function saveWizardUuid(uuid: string, outbound?: string): void {
  localStorage.setItem(outbound ? OUTBOUND_UUID_KEY : UUID_KEY, uuid)
}

export function clearWizardUuid(outbound?: string): void {
  localStorage.removeItem(outbound ? OUTBOUND_UUID_KEY : UUID_KEY)
}

export function getWizardPrevUuid(outbound?: string): ?string {
  return localStorage.getItem(outbound ? OUTBOUND_UUID_KEY : UUID_KEY)
}

export function getNewPath({ pathname }: Location): string {
  if (pathname.includes('search')) {
    return '/requests/search/:search'
  }

  if (pathname.includes('flat')) {
    return '/flat/:flatId/'
  }

  if (pathname.includes('building')) {
    return '/building/:buildingId/'
  }

  if (pathname.includes('profile')) {
    return '/profile/:profileId/:tab'
  }

  return ''
}
