// @flow

import React from 'react'
import type { Node } from 'react'

import ListItem from '../ListItem'
import { getUserName } from '../../utils/utils'
import { useTranslation } from 'react-i18next'

type Props = {
  suggestion: {
    avatar?: string,
    email: string,
    fullname?: string,
    name?: string,
    role?: string,
    secondName?: string,
  },
}

const TRANSLATION_DICT = {
  admin: 'AdminRole',
  dweller: 'DwellerRole',
  worker: 'WorkerRole',
  landlord: 'LandlordRole',
  manager: 'ManagerRole',
  external_contact: 'ExternalContactRole',
}

const MailSearchSuggestion = (props: Props): Node => {
  const { suggestion } = props

  const { t } = useTranslation('Mail')

  const { email, avatar, role } = suggestion

  const userName: string = getUserName(suggestion)

  const primaryText = `${userName || email}${
    role ? ' | ' + t(TRANSLATION_DICT[role]) : ''
  }`

  const secondaryText = userName !== email ? email : undefined

  return (
    <ListItem
      avatar={avatar}
      primaryText={primaryText}
      secondaryText={secondaryText}
    />
  )
}

export default MailSearchSuggestion
