// @flow

export const RETAINED_AMOUNT_TYPE = 'retained_amount_type'
export const INCLUDE_TAX = 'include_tax'
export const TAX = 'tax'
export const TOTAL_ESTIMATED_AMOUNT = 'total_estimated_amount'
export const RETAINED_AMOUNT_IN_CURRENCY = 'retained_amount_in_currency'
export const RETAINED_AMOUNT_IN_PERCENT = 'retained_amount_in_percent'
export const MAX_RETAINED_AMOUNT = 'max_retained_amount'
export const MIN_RETAINED_AMOUNT = 'min_retained_amount'

export const CURRENCY_TYPE = 'currency'
export const PERCENT_TYPE = 'percent'

export const DEFAULT_AMOUNT_TYPE = CURRENCY_TYPE
