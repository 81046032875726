// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

type Props = {|
  creating?: boolean,
  isReplyActionBarShown: boolean,
  onGetForwardValues: (string, Object, Array<Object>, string, string) => Object,
  onGetReplyAllValues: (
    string,
    Object,
    Array<Object>,
    string,
    string
  ) => Object,
  onGetReplyValues: (string, Object, Array<Object>, string, string) => Object,
  onSetReplyCb: Function => void,
  uuid: string,
|}

const ReplyFooter = (props: Props): Node => {
  const { creating, uuid, isReplyActionBarShown } = props

  const { t } = useTranslation('Mail')

  if (creating || uuid === 'create' || !isReplyActionBarShown) {
    return null
  }

  const handleReply = e => {
    e.stopPropagation()

    props.onSetReplyCb(() => props.onGetReplyValues)
  }

  const handleReplyAll = e => {
    e.stopPropagation()

    props.onSetReplyCb(() => props.onGetReplyAllValues)
  }

  const handleReplyForward = e => {
    e.stopPropagation()

    props.onSetReplyCb(() => props.onGetForwardValues)
  }

  return (
    <div className='reply-bar-wrapper' onClick={handleReply}>
      <div>
        {t('ClickTo')}&nbsp;
        <span className='reply-bar-action' onClick={handleReply}>
          {t('ReplyButton')}
        </span>
        ,&nbsp;
        <span className='reply-bar-action' onClick={handleReplyAll}>
          {t('ReplyAllButton')}
        </span>
        ,&nbsp;
        <span className='reply-bar-action' onClick={handleReplyForward}>
          {t('ForwardButton')}
        </span>
      </div>
    </div>
  )
}

export default ReplyFooter
