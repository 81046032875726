// @flow

import React from 'react'
import { withTranslation } from 'react-i18next'

import Icon from './CardIcon'

type Props = {
  automate?: boolean,
  cardType: string,
  t: string => string,
}

const CardHeader = (props: Props) => {
  const { cardType, automate } = props

  return (
    <div className='request-constructor__card--title'>
      <Icon automate={automate} type={cardType} />
      <div>{props.t(cardType)}</div>
    </div>
  )
}

export default withTranslation('RequestConstructor')(CardHeader)
