// @flow

import { getApi, postRaw, deleteApi, patchRaw } from './api.core'

export const getPreferredCommunication = params =>
  getApi(`api/preferred-communication/`, params)
export const updatePreferredCommunication = (id, params) =>
  patchRaw(`api/preferred-communication/${id}/`, params)
export const createPreferredCommunication = params =>
  postRaw(`api/preferred-communication/batch/`, params)
export const deletePreferredCommunication = id =>
  deleteApi(`api/preferred-communication/${id}/`)
