// @flow

import React, { Component } from 'react'

import Scrollbar from '../../../Scrollbar'

type Props = {
  items: Array<string>,
  onSelect: string => void,
  style?: Object,
}

class VariablesDropdown extends Component {
  props: Props

  renderItem = ({ name }: Object) => {
    const { onSelect } = this.props

    return (
      <li
        className='dropdown__list-item'
        key={`option-${name}`}
        onClick={() => onSelect(name)}
      >
        <span className='dropdown__list-item-text' title={name}>
          {name}
        </span>
      </li>
    )
  }

  render() {
    const { items, style } = this.props

    return (
      <div
        className='dropdown dropdown--default -dropdown--up-right dropdown--open'
        style={style}
      >
        <Scrollbar
          items={items}
          listElements={items.map(this.renderItem)}
          listClassName='dropdown__list dropdown__list--default dropdown__list--checklist'
          pageSize={5}
        />
      </div>
    )
  }
}

export default VariablesDropdown
