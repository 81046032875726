// @flow

import React from 'react'
import type { Node } from 'react'

const Separator = (): Node => (
  <span className='public-building-page__separator' />
)

export default Separator
