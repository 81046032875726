// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import { Row } from '../Block'
import NewSelectSimple from '../NewSelectSimple/NewSelectSimple'

type Props = {
  handleChangeType: Object => void,
  postTypeOption: Object,
  postTypeOptions: Array<Object>,
}

const PostType = (props: Props): Node => {
  const { t } = useTranslation('Post')

  const { postTypeOption, postTypeOptions } = props

  return (
    <Row title={t('PostType')}>
      <div style={{ width: '267px' }}>
        <NewSelectSimple
          name='post-type'
          value={postTypeOption}
          options={postTypeOptions}
          onChange={props.handleChangeType}
        />
      </div>
    </Row>
  )
}

export default PostType
