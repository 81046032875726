// @flow

export const FLAT_VIEW_INITIATING = 'FLAT_VIEW_INITIATING'
export const FLAT_VIEW_STATE_RESET = 'FLAT_VIEW_STATE_RESET'
export const FLAT_VIEW_INITIATED = 'FLAT_VIEW_INITIATED'
export const FLAT_VIEW_UPDATE = 'FLAT_VIEW_UPDATE'
export const FLAT_VIEW_UPDATED = 'FLAT_VIEW_UPDATED'
export const FLAT_VIEW_UPDATE_GALLERY = 'FLAT_VIEW_UPDATE_GALLERY'
export const FLAT_VIEW_ERROR = 'FLAT_VIEW_ERROR'
export const FLAT_VIEW_CLEAR_ERROR = 'FLAT_VIEW_CLEAR_ERROR'
