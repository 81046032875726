export const IMPORT_FILES_TABLE_INITIATING = 'IMPORT_FILES_TABLE_INITIATING'
export const IMPORT_FILES_TABLE_INITIATED = 'IMPORT_FILES_TABLE_INITIATED'
export const IMPORT_FILES_TABLE_ERROR = 'IMPORT_FILES_TABLE_ERROR'
export const IMPORT_FILES_GET_IMPORT_PK = 'IMPORT_FILES_GET_IMPORT_PK'
export const IMPORT_FILES_SET_IMPORT_PK = 'IMPORT_FILES_SET_IMPORT_PK'
export const IMPORT_FILES_TABLE_START = 'IMPORT_FILES_TABLE_START'
export const IMPORT_FILES_TABLE_STARTED = 'IMPORT_FILES_TABLE_STARTED'
export const IMPORT_FILES_TABLE_TOGGLE = 'IMPORT_FILES_TABLE_TOGGLE'
export const IMPORT_FILES_TABLE_TOGGLE_ALL = 'IMPORT_FILES_TABLE_TOGGLE_ALL'
export const IMPORT_FILES_TABLE_CANCEL = 'IMPORT_FILES_TABLE_CANCEL'
export const IMPORT_FILES_TABLE_CANCELED = 'IMPORT_FILES_TABLE_CANCELED'
export const IMPORT_FILES_TABLE_REMOVE = 'IMPORT_FILES_TABLE_REMOVE'
export const IMPORT_FILES_TABLE_REMOVED = 'IMPORT_FILES_TABLE_REMOVEED'
export const IMPORT_FILES_TABLE_MODAL = 'IMPORT_FILES_TABLE_MODAL'
export const IMPORT_FILES_TABLE_LAST = 'IMPORT_FILES_TABLE_LAST'
export const IMPORT_FILES_TABLE_LAST_STATUS = 'IMPORT_FILES_TABLE_LAST_STATUS'
export const IMPORT_FILES_TABLE_SET_PAGE = 'IMPORT_FILES_TABLE_SET_PAGE'
export const IMPORT_FILES_TABLE_PAGE = 'IMPORT_FILES_TABLE_PAGE'
export const IMPORT_FORCE_LAST_STATUS = 'IMPORT_FORCE_LAST_STATUS'
