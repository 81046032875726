// @flow

import React, { useEffect } from 'react'

import { get } from 'lodash-es'
import { getUserId } from '../utils/utils'
import { getListTiny } from '../core/api/api.profile'

const addRedirectToProfile = WrappedComponent => {
  return props => {
    useEffect(() => {
      const {
        history: { replace },
        match: { params },
      } = props

      const fetchProfile = async () => {
        try {
          const response = await getListTiny(params)
          const profileId = getUserId(
            get(response, ['results', 'objects', '0'])
          )

          if (profileId) {
            replace(`/profile/${profileId}`)
          }
        } catch (error) {
          console.error(error)
        }
      }

      fetchProfile()
    }, [props])

    return <WrappedComponent {...props} to={'/users/'} />
  }
}

export default addRedirectToProfile
