// @flow

import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import message from 'antd/es/message'
import type { Node } from 'react'

import RenameWorkflowPopup from './RenameWorkflowPopup'
import Button from '../../components/Button'
import Icon from '../../../static/icons/request-constructor.svg'
import { formatDate } from '../../utils/utils'

import styles from './RequestConstructorHeader.module.scss'

type Props = {
  active: boolean,
  changed?: boolean,
  date: string,
  disabledActions?: boolean,
  onStartPublish: () => void,
  onStartTest: () => void,
  onToggleActive: () => void,
  t: string => string,
  title: string,
  workflowId: string,
  workflowType: string,
  working?: boolean,
}

const RequestConstructorHeader = (props: Props): Node => {
  const {
    date,
    active,
    changed,
    title,
    onToggleActive,
    working,
    onStartPublish,
    disabledActions,
    workflowType,
    workflowId,
  } = props

  const { t } = useTranslation('RequestConstructor')

  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  useEffect(() => {
    if (!changed && mounted) {
      message.success(t('WorkflowHasBeenPublished'))
    }
  }, [changed])

  const [isOpen, setOpen] = useState(false)

  const sectionClass = classnames(
    'unit unit--default request-constructor-header',
    {
      'working-overlay': working,
    }
  )

  const showModal = () => setOpen(true)
  const hideModal = () => setOpen(false)

  const statusClass = classnames('bar__cell-value', {
    'bar__cell-value--danger': !active,
    'bar__cell-value--success': active,
  })

  return (
    <section className={sectionClass}>
      <div className='bar__title bar__title-indented bar__row'>
        <Icon className='bar__title-symbol-container' />
        <span className='bar__title-text'>{title}</span>
        <Button.Edit onClick={showModal} />
      </div>
      <div className='bar__cols bar__row'>
        <div className='bar__cell'>
          <span className='bar__cell-title'>{t('status')}</span>
          <span className={statusClass}>
            {t(active ? 'active' : 'inactive')}
          </span>
        </div>
        <div className='bar__cell'>
          <span className='bar__cell-title'>{t('updateDate')}</span>
          <span className='bar__cell-value'>{formatDate(date)}</span>
        </div>
        <div className='bar__cell'>
          <span className='bar__cell-title'>{t('WorkflowType')}</span>
          <span className='bar__cell-value'>{t(workflowType)}</span>
        </div>
      </div>
      <div className='bar__cols bar__row'>
        <div className={styles.requestHeader}>
          <Button.Save
            disabled={disabledActions || !changed}
            onClick={onStartPublish}
          >
            {t('Publish')}
          </Button.Save>
          <Button.Save disabled={disabledActions} onClick={props.onStartTest}>
            {t('startTest')}
          </Button.Save>
          <Button.Toggle
            checked={active}
            disabled={disabledActions}
            onChange={onToggleActive}
          />
          <span className={styles.requestSwitcherText}>
            {t(active ? 'botIsActive' : 'botIsInactive')}
          </span>
        </div>
      </div>
      <RenameWorkflowPopup
        hideModal={hideModal}
        isOpen={isOpen}
        title={title}
        workflowId={workflowId}
      />
    </section>
  )
}

export default RequestConstructorHeader
