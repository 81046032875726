// @flow

import React, { Component, Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import { set } from 'lodash-es'
import { connect } from 'react-redux'
import { compose } from 'redux'

import { Header, SimpleBlock } from '../../Block'
import Nbsp from '../../NBSP'
import BottomPagination from '../../Pagination/BottomPagination'
import EmptyList from '../../EmptyList'
import Loader from '../../Loader'
import Notifications from '../../Notification/NotificationList'
import UserItem from '../../UserItem'
import TileList from '../../TileList'
import { PREVIEW_GREAT_SIZE } from '../../../constants'
import { getCurrentPage } from '../../../utils/routing'
import { getLocation } from '../../../utils/commonSelectors'
import addRedirectToFirstPage from '../../../hocs/addRedirectToFirstPage'
import { getUserId } from '../../../utils/utils'
import * as actions from './ResidentList.actionTypes'

type Props = {
  activeTagsIds: Array<number>,
  dispatch: Object => void,
  initiated: boolean,
  items: Array<Object>,
  location: Object,
  meta: Object,
  notifications: Array<Object>,
  onInit: () => void,
  permissions: Object,
  rowLength: number,
  t: string => string,
  working: boolean,
}

const ROWS_COUNT = 4

class ResidentList extends Component<Props> {
  static defaultProps = {
    rowLength: 5,
  }

  componentDidMount() {
    this.props.dispatch({
      type: actions.RESIDENT_LIST_INITIATING,
      params: this.getParams(),
    })
  }

  componentDidUpdate(prev: Props) {
    const { initiated, location } = this.props

    if (initiated && !prev.initiated) {
      this.props.onInit()
    }

    if (location.search !== prev.location.search) {
      const page = getCurrentPage(location)
      const prevPage = getCurrentPage(prev.location)

      if (prevPage && page !== prevPage) {
        this.load()
      }
    }
  }

  getPageSize = () => this.props.rowLength * ROWS_COUNT

  getParams = () => {
    const { location } = this.props

    return {
      page: getCurrentPage(location),
      page_size: this.getPageSize(),
      preview_size: PREVIEW_GREAT_SIZE,
    }
  }

  load = () => {
    const { meta } = this.props

    const params = this.getParams()

    if (!params.page) {
      set(params, 'page', meta.curr_page)
    }

    this.props.dispatch({ type: actions.RESIDENT_LIST_LOAD, params })
  }

  subscribe = user => {
    const { owner } = user
    this.props.dispatch({ type: actions.RESIDENT_LIST_SUBSCRIBE, owner })
  }

  resend = user => {
    const { owner } = user
    this.props.dispatch({ type: actions.RESIDENT_LIST_SUBSCRIBE, owner })
  }

  unsubscribe = user => {
    const { owner } = user
    this.props.dispatch({ type: actions.RESIDENT_LIST_UNSUBSCRIBE, owner })
  }

  renderHeader = () => {
    const {
      working,
      meta: { count },
    } = this.props

    return (
      <Header header>
        {this.props.t('DwellersRelated')}
        <Nbsp />
        {!working && !!count && (
          <span className='unit__title-num'>{count}</span>
        )}
      </Header>
    )
  }

  render() {
    const { notifications, working, items, meta, rowLength } = this.props

    if (working) {
      return (
        <section className='unit unit--default'>
          {this.renderHeader()}
          <div className='working-overlay' />
          <Loader text={false} type='medium' />
        </section>
      )
    }

    return (
      <Fragment>
        <Notifications
          style={{ marginTop: '1.5rem' }}
          notifications={notifications}
        />
        <SimpleBlock>
          {this.renderHeader()}
          {!items.length ? (
            <EmptyList icon='user' title={this.props.t('NoDwellers')} />
          ) : (
            <TileList rowLength={rowLength}>
              {items.map(item => (
                <UserItem
                  data={item}
                  key={`resident-${getUserId(item)}`}
                  resubscribe={this.resend}
                  subscribe={this.subscribe}
                  unsubscribe={this.unsubscribe}
                />
              ))}
            </TileList>
          )}
          <BottomPagination
            classes='paginator-2'
            basePage='residents'
            meta={meta}
            dispatch={this.props.dispatch}
          />
        </SimpleBlock>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  ...state.residentList,
  location: getLocation(state),
})

export default compose(
  withTranslation('User'),
  connect(mapStateToProps),
  addRedirectToFirstPage
)(ResidentList)
