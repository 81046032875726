// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import Button from '../Button'
import { BUILDINGS, TO_ALL_BUILDINGS, TO_BUILDING } from './Post.constants'

type Props = {
  allBuildings: boolean,
  highlighted: boolean,
  scope: Array<Object>,
  showModal: Function,
}

const PostScopeButton = (props: Props): Node => {
  const { highlighted, scope } = props

  const { t } = useTranslation('Post')

  const getLabel = (item: Object) => {
    if (item !== BUILDINGS) {
      return t(item)
    }

    const { allBuildings } = props

    const label = allBuildings ? TO_ALL_BUILDINGS : TO_BUILDING

    return t(label)
  }

  const getButtonTitle = () =>
    !scope.length ? t('addScope') : scope.map(getLabel).join(', ')

  const error = !scope.length && highlighted

  const btnText = getButtonTitle()

  return (
    <Button.Regular
      icon='planet'
      error={error}
      view='desaturated'
      style={{ maxWidth: '250px' }}
      onClick={props.showModal}
    >
      {scope.length > 0 && (
        <span className='select-address'>{t('Common:send')}: </span>
      )}
      <span className='post-scope-decor-value'>{btnText}</span>
    </Button.Regular>
  )
}

export default PostScopeButton
