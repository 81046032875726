// @flow

export const FAQ_LOAD_GROUPS = 'FAQ_LOAD_GROUPS'
export const FAQ_GROUPS_WAS_LOADED = 'FAQ_GROUPS_WAS_LOADED'
export const FAQ_SHOW_MODAL = 'FAQ_SHOW_MODAL'
export const FAQ_CLOSE_MODAL = 'FAQ_CLOSE_MODAL'
export const FAQ_REMOVE_GROUPS = 'FAQ_REMOVE_GROUPS'
export const FAQ_GROUPS_WAS_REMOVED = 'FAQ_GROUPS_WAS_REMOVED'
export const FAQ_CREATE_GROUP = 'FAQ_CREATE_GROUP'
export const FAQ_TOGGLE_ALL_GROUPS = 'FAQ_TOGGLE_ALL_GROUPS'
export const FAQ_TOGGLE_GROUP = 'FAQ_TOGGLE_GROUP'
export const FAQ_SAVE_GROUP_NAME = 'FAQ_SAVE_GROUP_NAME'
export const FAQ_GROUP_NAME_WAS_SAVED = 'FAQ_NEW_GROUP_NAME_WAS_SAVED'
export const FAQ_EDIT_GROUP = 'FAQ_EDIT_GROUP'
export const FAQ_EDIT_QUESTION = 'FAQ_EDIT_QUESTION'
export const FAQ_SAVE_QUESTION = 'FAQ_SAVE_QUESTION'
export const FAQ_QUESTION_WAS_SAVED = 'FAQ_QUESTION_WAS_SAVED'
export const FAQ_EDIT_ANSWER = 'FAQ_EDIT_ANSWER'
export const FAQ_SAVE_ANSWER = 'FAQ_SAVE_ANSWER'
export const FAQ_ANSWER_WAS_SAVED = 'FAQ_ANSWER_WAS_SAVED'
export const FAQ_CREATE_QUESTION = 'FAQ_CREATE_QUESTION'
export const FAQ_QUESTION_WAS_CREATED = 'FAQ_QUESTION_WAS_CREATED'
export const FAQ_REMOVE_QUESTION = 'FAQ_REMOVE_QUESTION'
export const FAQ_QUESTION_WAS_REMOVED = 'FAQ_QUESTION_WAS_REMOVED'
export const FAQ_CLEAR_STATE = 'FAQ_CLEAR_STATE'
export const FAQ_QUESTION_CHANGE_POSITION = 'FAQ_QUESTION_CHANGE_POSITION'
export const FAQ_QUESTION_POSITION_WAS_CHANGED =
  'FAQ_QUESTION_POSITION_WAS_CHANGED'
export const FAQ_CLEAR_EDIT_QUESTION = 'FAQ_CLEAR_EDIT_QUESTION'

export const loadGroups = () => ({ type: FAQ_LOAD_GROUPS })
export const groupsWasLoaded = (groups, permissions) => ({
  type: FAQ_GROUPS_WAS_LOADED,
  groups,
  permissions,
})
export const clearState = () => ({ type: FAQ_CLEAR_STATE })

export const showModal = (modal, params) => ({
  type: FAQ_SHOW_MODAL,
  modal,
  params,
})
export const closeModal = () => ({ type: FAQ_CLOSE_MODAL })

export const toggleAllGroups = () => ({ type: FAQ_TOGGLE_ALL_GROUPS })
export const removeGroups = () => ({ type: FAQ_REMOVE_GROUPS })
export const groupsWasRemoved = () => ({ type: FAQ_GROUPS_WAS_REMOVED })
export const createGroup = name => ({ type: FAQ_CREATE_GROUP, name })
export const toggleGroup = (id, isSelected) => ({
  type: FAQ_TOGGLE_GROUP,
  id,
  isSelected,
})
export const saveGroupName = name => ({ type: FAQ_SAVE_GROUP_NAME, name })
export const groupNameWasSaved = () => ({ type: FAQ_GROUP_NAME_WAS_SAVED })
export const editGroup = id => ({ type: FAQ_EDIT_GROUP, id })

export const editQuestion = id => ({ type: FAQ_EDIT_QUESTION, id })
export const saveQuestion = (title, group, position) => ({
  type: FAQ_SAVE_QUESTION,
  title,
  group,
  position,
})
export const questionWasSaved = () => ({ type: FAQ_QUESTION_WAS_SAVED })
export const createQuestion = (title, group) => ({
  type: FAQ_CREATE_QUESTION,
  title,
  group,
})
export const questionWasCreated = () => ({ type: FAQ_QUESTION_WAS_CREATED })
export const removeQuestion = (group, position) => ({
  type: FAQ_REMOVE_QUESTION,
  group,
  position,
})
export const questionWasRemoved = () => ({ type: FAQ_QUESTION_WAS_REMOVED })
export const questionChangePosition = params => ({
  type: FAQ_QUESTION_CHANGE_POSITION,
  params,
})
export const questionPositionWasChanged = () => ({
  type: FAQ_QUESTION_POSITION_WAS_CHANGED,
})
export const clearEditQuestion = () => ({ type: FAQ_CLEAR_EDIT_QUESTION })

export const editAnswer = id => ({ type: FAQ_EDIT_ANSWER, id })
export const saveAnswer = (answer, group, position) => ({
  type: FAQ_SAVE_ANSWER,
  answer,
  group,
  position,
})
export const answerWasSaved = () => ({ type: FAQ_ANSWER_WAS_SAVED })
