// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import NewTableHeader from '../../NewTable/NewTableHeader'
import NewTableHeaderCell from '../../NewTable/NewTableHeaderCell'
import NewTableHeaderRow from '../../NewTable/NewTableHeaderRow'

type Props = { isPrivate?: boolean }

const EmailIntegrationTableHeader = (props: Props): Node => {
  const { isPrivate } = props

  const translationKey = isPrivate
    ? 'EmailAccountSharing'
    : 'EmailAccountMarkedAsRead'

  const { t } = useTranslation('EmailIntegration')

  return (
    <NewTableHeader>
      <NewTableHeaderRow>
        <NewTableHeaderCell
          title={t('EmailNameSort')}
          style={{ width: '36%' }}
        />
        <NewTableHeaderCell
          title={t('EmailStatusSort')}
          style={{ width: '23%' }}
        />
        <NewTableHeaderCell
          title={t(translationKey)}
          style={{ width: '23%' }}
        />
        <NewTableHeaderCell
          title={t('EmailUpdatedSort')}
          style={{ width: '18%' }}
        />
      </NewTableHeaderRow>
    </NewTableHeader>
  )
}

export default EmailIntegrationTableHeader
