import { all, put, call, fork, takeEvery, cancel } from 'redux-saga/effects'

import api from '../../../core/api'
import * as actions from './WidgetParticipantsList.actionTypes'
import { serverError } from '../../Layout/Layout.actions'

function* watchClear(task) {
  yield takeEvery(actions.CLEAR_WIDGET_PARTICIPANTS_LIST, clear, task)
}
function* watchLoad() {
  yield takeEvery(actions.WIDGET_PARTICIPANTS_LIST_INITIATING, load)
}

function* watchLoadRequestMembers() {
  yield takeEvery(
    actions.WIDGET_REQUEST_PARTICIPANTS_LIST_INITIATING,
    loadRequestMembers
  )
}

function* watchLoadMailMembers() {
  yield takeEvery(
    actions.WIDGET_MAIL_PARTICIPANTS_LIST_INITIATING,
    loadMailMembers
  )
}

function* clear(task) {
  yield cancel(task)
}

function* requestMailMemebers({ uuid, isSingle }) {
  try {
    const getApi = isSingle ? api.mail.getMessageMembers : api.mail.getMembers

    const data = yield call(getApi, uuid)

    yield put({ type: actions.WIDGET_CHAT_PARTICIPANTS_LIST_INITIATED, data })
  } catch (error) {
    if (error.message.response.status !== 404) {
      yield put(serverError(error))
      yield put({ type: actions.WIDGET_PARTICIPANTS_LIST_ERROR, error })
    }
  }
}

function* loadMailMembers({ uuid, isSingle }) {
  const task = yield fork(requestMailMemebers, { uuid, isSingle })
  yield fork(watchClear, task)
}

function* loadRequestMembers({ requestId }) {
  try {
    const {
      results: { objects: requestMemebers },
    } = yield api.request.getMembers(requestId)
    yield put({
      type: actions.WIDGET_CHAT_PARTICIPANTS_LIST_INITIATED,
      data: requestMemebers,
    })
  } catch (error) {
    if (error.message.response.status !== 404) {
      yield put(serverError(error))
      yield put({ type: actions.WIDGET_PARTICIPANTS_LIST_ERROR, error })
    }
  }
}

function* load({ chatroomUuid }) {
  try {
    const data = yield call(api.messages.getMembers, chatroomUuid)
    yield put({ type: actions.WIDGET_CHAT_PARTICIPANTS_LIST_INITIATED, data })
  } catch (error) {
    if (error.message.response.status !== 404) {
      yield put(serverError(error))
      yield put({ type: actions.WIDGET_PARTICIPANTS_LIST_ERROR, error })
    }
  }
}

export default function* watch() {
  yield all([
    fork(watchLoad),
    fork(watchLoadRequestMembers),
    fork(watchLoadMailMembers),
  ])
}
