// @flow

import React, { useEffect } from 'react'
import { compose } from 'redux'
import { connect, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import AddUserPopup from '../../../modals/AddUserPopup/AddUserPopup'
import ConfirmationPopup from '../../../modals/ConfirmationPopup'
import ResendInvitePopUp from '../../../modals/ResendInvitePopUp'
import ChangeRolePopup from '../../../modals/ChangeRolePopup'
import TopPagination from '../../../Pagination/TopPagination'
import Button from '../../../Button'
import Icon from '../../../Icon'
import SelectCustom from '../../../Select/SelectCustom'

import {
  approve,
  remove,
  restore,
  toggleAll,
  update,
  allSelectedUsers,
} from './Toolbox.actions'

import { USER_GROUPS } from '../../../../constants'
import {
  formatPathname,
  getCurrentFilters,
  getCurrentPage,
  getNewQueryString,
} from '../../../../utils/routing'
import { getLocation, getUser } from '../../../../utils/commonSelectors'
import { load } from '../../Users.actions'
import Checkbox from '../../../Checkbox'
import { omit } from 'lodash-es'
import { usePrevious } from '../../../../hooks/usePrevious'
import { push } from 'connected-react-router'

const Toolbox = props => {
  const {
    users,
    all: allUsers,
    meta,
    selected,
    location,
    permissions: {
      can_create: canCreate,
      can_delete: canDelete,
      can_restore: canRestore,
    },
    user: { id, group },
    working,
  } = props

  useEffect(() => {
    toggleAll({ empty: true })
  }, [])

  const dispatch = useDispatch()
  const isManager = group === USER_GROUPS.manager
  const { groupId: buildingGroup } = useParams()

  const addUser = () => {
    props.setModal(
      <AddUserPopup
        owner={isManager ? id : undefined}
        onHide={() => props.setModal(null)}
        onSave={() => {
          props.load(omit(getParams(), ['counters', 'group']))
        }}
      />
    )
  }

  const preventOpen = e => {
    e.preventDefault()
    e.stopPropagation()
  }

  const getParams = () => {
    const { location } = props
    const page = getCurrentPage(location)

    return {
      page,
      counters: 1,
      group: USER_GROUPS.dweller,
      ...getCurrentFilters(location),
    }
  }

  const remove = (owner = null) => {
    const { selected, all: allUsers, meta } = props
    const idsToDelete = owner ? [owner] : selected
    const values = idsToDelete.filter(id => {
      const user = allUsers.find(u => u.id === id)

      return user && user.permissions && user.permissions.can_delete
    })

    props.remove(values, getParams(), meta)
  }

  const restore = (owner = null) => {
    const { selected, all: allUsers, meta } = props

    const idsToRestore = owner ? [owner] : selected
    const values = idsToRestore.filter(id => {
      const user = allUsers.find(u => u.id === id)

      return user && user.permissions && user.permissions.can_restore
    })

    props.restore(values, getParams(), meta)
  }

  const showRemoveModal = () => {
    props.setModal(
      <ConfirmationPopup
        title={props.t('DeleteTitle')}
        text={props.t('Common:confirmDwellerRemoving')}
        confirm={props.t('Delete')}
        cancel={props.t('Cancel')}
        onClose={props.hideModal}
        onOk={() => remove()}
      />
    )
  }

  const showRestoreModal = () => {
    props.setModal(
      <ConfirmationPopup
        title={props.t('RestoreTitle')}
        text={props.t('Common:confirmDwellerRestoring')}
        confirm={props.t('Common:Restore')}
        cancel={props.t('Cancel')}
        onClose={props.hideModal}
        onOk={() => restore()}
      />
    )
  }

  const confirmReinviting = (user = null) => {
    const { selected, all: allUsers } = props
    const resendUsers = user
      ? [user]
      : allUsers.filter(u => u.status !== 1 && selected.includes(u.id))

    props.setModal(
      <ResendInvitePopUp
        reinvite
        single={resendUsers.length <= 1}
        group={USER_GROUPS.dweller}
        user={user}
        usersResend={resendUsers.map(u => u.id)}
        onHide={props.hideModal}
        onSave={() => {
          props.load({ ...getParams(), page: 1 })
        }}
      />
    )
  }

  const update = patch => {
    props.update(
      selected,
      patch,
      {
        ...getParams(),
        building_id: getParams().building,
      },
      props.meta
    )
  }

  const confirmRoleUpdating = user => {
    const onOk = val => {
      update({ group: val })
    }

    props.setModal(
      <ChangeRolePopup
        confirm={props.t('Save')}
        massAction={!user}
        onClose={props.hideModal}
        onOk={onOk}
      />
    )
  }

  const approve = users => {
    props.approve(users, getParams())
  }

  const confirmApproving = () => {
    const approveUsers = allUsers.filter(
      u => selected.includes(u.id) && !u.validated
    )
    const selectedUsers = allUsers.filter(u => selected.includes(u.id))

    const text =
      selectedUsers.length > 1
        ? props.t('ApproveModalTextMulti', { count: selectedUsers.length })
        : props.t('ApproveModalText', { name: selectedUsers[0].name })

    props.setModal(
      <ConfirmationPopup
        title={props.t('ApproveModalTitle')}
        text={text}
        confirm={props.t('ApproveButtonTitle')}
        cancel={props.t('Cancel')}
        onClose={props.hideModal}
        onOk={() => approve(approveUsers)}
      />
    )
  }

  const getMassActionOptions = () => {
    const options = [
      {
        value: 'changeRole',
        label: props.t('ChangeRole'),
        handler: () => confirmRoleUpdating(),
        icon: 'pencil',
      },
      {
        value: 'sentInvitation',
        label: props.t('ResendInviteToMany'),
        handler: () => confirmReinviting(),
        icon: 'forward',
      },
      {
        value: 'approveSignup',
        label: props.t('ApproveButtonTitle'),
        handler: () => confirmApproving(),
        icon: 'check',
      },
    ]

    return options
  }

  const toggleAll = prev => {
    prev && props.toggleAll({ empty: true })
    props.toggleAll({ empty: prev, curr: true, pages: [...users] })
  }

  const massActionOptions = getMassActionOptions()

  useEffect(() => {
    if (!working) {
      props.hideModal()
    }
  }, [working])

  const prevSelected = usePrevious(selected)
  const prevAll = usePrevious(allUsers)
  const prevMeta = usePrevious(meta)

  useEffect(() => {
    if (
      (prevSelected || prevAll) &&
      prevMeta?.curr_page &&
      prevMeta?.curr_page !== meta.curr_page &&
      prevMeta?.curr_page != location.query.page
    ) {
      props.allSelectedUsers(prevSelected, prevAll)
    }
  }, [meta.curr_page])

  useEffect(() => {
    const page = getCurrentPage(location)
    const filters = getCurrentFilters(location)
    const pathname = formatPathname(location.pathname)

    if ((users.length < 1 || meta.count < meta.limit) && page !== 1) {
      const search = getNewQueryString({
        ...filters,
        page: 1,
      })
      dispatch(push(`/${pathname}/?${search}`))
    } else if (meta.curr_page < page) {
      const search = getNewQueryString({
        ...filters,
        page: meta.curr_page,
      })
      dispatch(push(`/${pathname}/?${search}`))
    }
  }, [users])

  return (
    <div className='toolbox'>
      {(canDelete || canRestore) && (
        <div className='toolbox__cell'>
          <Checkbox
            outer
            checked={
              selected.length > 0 &&
              users.filter(u => selected.includes(u.id)).length === users.length
            }
            disabled={working}
            onChange={() =>
              toggleAll(
                selected.length > 0 &&
                  users.filter(u => selected.includes(u.id)).length ===
                    users.length
              )
            }
          />
        </div>
      )}
      {!buildingGroup && canCreate && selected.length === 0 && (
        <div className='toolbox__cell'>
          <button
            className='button button--success button--large'
            type='button'
            onClick={addUser}
          >
            <Icon id='user' className='button__icon' />
            {props.t('AddUser')}
          </button>
        </div>
      )}
      {!buildingGroup && canDelete && !!selected.length && (
        <div className='toolbox__cell'>
          <SelectCustom
            style={{ marginRight: '-10px' }}
            options={massActionOptions}
            disabled={working}
            onClick={preventOpen}
            onChange={opt => opt.handler()}
          >
            {props.t('Actions')}
          </SelectCustom>
        </div>
      )}
      {!buildingGroup && canDelete && !!selected.length && (
        <div className='toolbox__cell'>
          <button
            type='button'
            className='button button--danger-3 button--large'
            style={{ marginRight: '0' }}
            disabled={working}
            onClick={showRemoveModal}
          >
            <Icon id='bin' className='button__icon' />
            {props.t('Delete')}
          </button>
        </div>
      )}
      {!buildingGroup && canRestore && (
        <div className='toolbox__cell'>
          <button
            type='button'
            className='button button--danger-3 button--large'
            disabled={working}
            onClick={showRestoreModal}
          >
            <Icon id='restore' className='button__icon' />
            {props.t('Common:Restore')}
          </button>
        </div>
      )}
      {!!selected.length && (
        <div className='toolbox__cell'>
          <Button.CreateEmail
            disabled={working}
            selectedUsers={allUsers.filter(u => selected.includes(u.id))}
          />
        </div>
      )}
      {!!meta && (
        <div className='toolbox__cell toolbox__cell--right'>
          <TopPagination
            classes='pager pager--large pager--default-2'
            meta={meta}
          />
        </div>
      )}
    </div>
  )
}

const mapStateToProps = state => ({
  ...state.userList,
  location: getLocation(state),
  user: getUser(state),
})

const mapDispatchToProps = {
  approve,
  remove,
  restore,
  toggleAll,
  update,
  load,
  allSelectedUsers,
}

export default compose(
  withTranslation('User'),
  connect(mapStateToProps, mapDispatchToProps)
)(Toolbox)
