// @flow

import React from 'react'
import { connect } from 'react-redux'
import seapig, { OPTIONAL } from 'seapig'
import classNames from 'classnames'
import type { Node } from 'react'

import Button from '../../components/Button'
import Icon from '../../components/Icon'
import { toggleWidget } from './Widget.actions'
import { getActiveWidget } from './Widget.selectors'

type Props = {
  activeWidget?: string,
  children: Node,
  headerClassName?: string,
  icon?: string,
  name: string,
  notCollapse?: boolean,
  title?: any,
  working: boolean,
}

export const Widget = (props: Props) => {
  const { activeWidget, name, children, title, working, notCollapse, icon } =
    props

  const isOpen = activeWidget === name

  const listStyle = {
    height: isOpen || notCollapse ? 'inherit' : 0, // For IE11
  }

  const toggleWidget = () => {
    !notCollapse && props.toggleWidget(name)
  }

  const { titleChildren, rest } = seapig(children, { title: OPTIONAL })

  const headerClassName = classNames('widget__header', props.headerClassName, {
    'widget__header--no-icon': !icon,
    'widget__header-clickable': !notCollapse,
  })

  return (
    <aside className='widget' style={{ marginBottom: '20px' }}>
      <header className={headerClassName} onClick={toggleWidget}>
        {typeof icon === 'string' ? <Icon id={icon} /> : icon}
        {titleChildren.length ? (
          titleChildren
        ) : (
          <span className='widget__header-text'>{title}</span>
        )}
        {!notCollapse && (
          <Button.Minimize isOpen={isOpen} className='button-drop--widget' />
        )}
      </header>

      {(isOpen || notCollapse) && (
        <div className={working ? 'working-overlay' : ''} style={listStyle}>
          {rest}
        </div>
      )}
    </aside>
  )
}

Widget.defaultProps = {
  notCollapse: false,
  icon: null,
  headerClassName: '',
}

const mapStateToProps = state => ({
  activeWidget: getActiveWidget(state),
})

const mapDispatchToProps = {
  toggleWidget,
}

export default connect(mapStateToProps, mapDispatchToProps)(Widget)
