// @flow

import * as address from './api.address'
import * as automate from './api.automate'
import * as building from './api.building'
import * as contractor from './api.contractor'
import * as checklist from './api.checklist'
import * as contactForm from './api.contactForm'
import * as core from './api.core'
import * as login from './api.login'
import * as register from './api.register'
import * as profile from './api.profile'
import * as publicBuildingPage from './api.publicBuildingPage'
import * as request from './api.request'
import * as file from './api.file'
import * as friends from './api.friends'
import * as newsband from './api.newsband'
import * as uk from './api.uk'
import * as flat from './api.flat'
import * as person from './api.person'
import * as massAction from './api.massAction'
import * as like from './api.like'
import * as translation from './api.translation'
import * as system from './api.system'
import * as userImport from './api.import'
import * as permission from './api.permission'
import * as messages from './api.messages'
import * as label from './api.label'
import * as ukBuildings from './api.ukBuildings'
import * as ukFlats from './api.ukFlats'
import * as mail from './api.mail'
import * as mailsFiles from './api.mailsFiles'
import * as integrations from './api.integrations'
import * as requestCategory from './api.requestCategory'
import * as requestConstructor from './api.requestConstructor'
import * as weather from './api.weather'
import * as faq from './api.faq'
import * as office from './api.office'
import * as printer from './api.printer'
import * as rating from './api.rating'
import * as feedback from './api.feedback'
import * as marketplace from './api.marketplace'
import * as documentMapping from './api.documentMapping'
import * as template from './api.template'
import * as postTemplate from './api.postTemplate'
import * as docTemplates from './api.docTemplates'
import * as roomType from './api.room'
import * as flatCategory from './api.flatCategory'
import * as flatCondition from './api.flatCondition'
import * as flatContract from './api.flatContract'
import * as flatSpecifics from './api.flatSpecifics'
import * as idwell2idwell from './api.idwell2idwell'

export default {
  core,
  login,
  register,
  building,
  contractor,
  profile,
  contactForm,
  request,
  file,
  friends,
  newsband,
  uk,
  flat,
  person,
  publicBuildingPage,
  massAction,
  like,
  address,
  translation,
  system,
  userImport,
  permission,
  messages,
  checklist,
  label,
  ukBuildings,
  ukFlats,
  mail,
  integrations,
  requestCategory,
  requestConstructor,
  weather,
  faq,
  office,
  printer,
  rating,
  feedback,
  marketplace,
  mailsFiles,
  documentMapping,
  template,
  postTemplate,
  docTemplates,
  roomType,
  flatCategory,
  flatCondition,
  flatContract,
  flatSpecifics,
  automate,
  idwell2idwell,
}
