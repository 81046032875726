// @flow

import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { push } from 'connected-react-router'
import classnames from 'classnames'

import SelectCustom from '../../../Select/SelectCustom'
import Cell from './Cell'
import StatusValue from './StatusValue'

import {
  BLOCKED_STATUS,
  USER_STATUS,
  INVITED_STATUS,
} from '../../../../constants'
import { isAdminUser, isManagerUser } from '../../../../utils/utils'

import type Node from 'react'
import type { ItemProps } from './index'

const StaffItem = (props: ItemProps): Node => {
  const {
    archived,
    user,
    selected,
    canDelete,
    canRestore,
    canUpdateRole,
    statusOptions,
    onReinvite,
    onUpdateRole,
    onUpdateStatus,
    onUpdateBuilding,
    onUpdateCategory,
    onToggle,
    isArchived,
  } = props
  const { id, fullname, avatar, status } = user

  const dispatch = useDispatch()
  const { t } = useTranslation('User')

  const open = () => {
    const params = archived ? '?archived=true' : ''
    dispatch(push(`/profile/${user.id}${params}`))
  }

  const preventOpen = e => {
    e.stopPropagation()
  }

  const reinvite = () => {
    onReinvite(user)

    return false
  }

  const updateStatus = status => {
    onUpdateStatus(user, status)
  }

  const getOptions = () => {
    let options = [
      {
        value: 'checkProfile',
        label: t('CheckProfile'),
        handler: open,
        icon: 'eye',
      },
    ]

    if (!isArchived && (isManagerUser(user) || isAdminUser(user))) {
      options.push({
        value: 'assignToBuildings',
        label: t('AssignToBuildings'),
        handler: () => onUpdateBuilding(user),
        icon: 'pencil',
      })

      options.push({
        value: 'assignToCategories',
        label: t('AssignToCategories'),
        handler: () => onUpdateCategory(user),
        icon: 'pencil',
      })
    }

    if (USER_STATUS[status] === INVITED_STATUS) {
      options.push({
        value: 'inviteAgain',
        label: t('InviteAgain'),
        handler: reinvite,
        icon: 'forward',
      })
    }

    if (
      USER_STATUS[status] === BLOCKED_STATUS &&
      user.permissions.can_unblock_user
    ) {
      options.push({
        value: 'unblock',
        label: t('unblock'),
        handler: () => updateStatus(false),
        icon: 'lock',
      })
    }

    if (
      USER_STATUS[status] !== BLOCKED_STATUS &&
      user.permissions.can_block_user
    ) {
      options.push({
        value: 'block',
        label: t('block'),
        handler: () => updateStatus(true),
        icon: 'lock',
      })
    }

    return options
  }

  const renderExtra = () => {
    return (
      <div className='table-extra table-extra--settings'>
        <SelectCustom
          options={getOptions()}
          onClick={preventOpen}
          onChange={opt => opt.handler()}
        />
      </div>
    )
  }

  const preventDelete = user.permissions && !user.permissions.can_delete
  const preventRestore = user.permissions && !user.permissions.can_restore

  const userClass = classnames('table__row', {
    'table__row--attention': selected,
  })

  return (
    <div className={userClass} key={`user-${id}`}>
      <div className='table__cell'>
        <div className='table__cell-wrapper' onClick={() => open(id)}>
          <div className='table-item table-item__user'>
            {(canDelete && !preventDelete) ||
            (canRestore && !preventRestore) ? (
              <label
                className='checkbox checkbox--medium checkbox--default checkbox--notext table-item__checkbox'
                htmlFor={`check-${id}`}
                onClick={preventOpen}
              >
                <input
                  id={`check-${id}`}
                  type='checkbox'
                  value={id}
                  checked={selected}
                  onChange={onToggle}
                />
                <span />
              </label>
            ) : (
              <label className='checkbox checkbox--medium checkbox--default checkbox--notext table-item__checkbox' />
            )}
            <div className='table-item__user-info'>
              <i className='table-item__user-pic' onClick={open}>
                <img src={avatar} alt='user' />
              </i>
              <div>
                <span className='table-item__user-name' title={fullname}>
                  {fullname}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Cell.Role canUpdate={canUpdateRole} user={user} onClick={onUpdateRole} />
      <Cell.Address
        user={user}
        archived={isArchived}
        onClick={onUpdateBuilding}
      />
      <Cell.Category
        user={user}
        archived={isArchived}
        onClick={onUpdateCategory}
      />
      <Cell.Email user={user} />
      <div className='table__cell' onClick={open}>
        <div className='table__cell-wrapper'>
          <StatusValue options={statusOptions} value={status} />
        </div>
      </div>
      <Cell.Updated extra={renderExtra()} user={user} />
    </div>
  )
}

export default StaffItem
