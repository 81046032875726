// @flow

import React from 'react'
import AutoResizeTextarea from 'react-textarea-autosize'
import classnames from 'classnames'
import type { Node } from 'react'

import styles from './Textarea.module.scss'

type Props = {
  className?: string,
  disabled: boolean,
  error: string,
  name: string,
  onChange: (SyntheticEvent<HTMLTextAreaElement>) => void,
  placeholder?: string,
  style?: Object,
  textareaRef?: { current?: ?HTMLTextAreaElement },
  value: string,
}

const Textarea = (props: Props): Node => {
  const {
    className,
    style,
    placeholder,
    name,
    value,
    error,
    disabled,
    textareaRef,
    minRows,
    maxLength,
  } = props

  const textareaClass = classnames(className, styles.textarea, {
    [styles.textareaError]: error,
  })

  const textareaProps = Object.assign(
    {},
    minRows ? { minRows } : undefined,
    maxLength ? { maxLength } : undefined,
    disabled ? { disabled } : undefined,
    { className: textareaClass, name, value, onChange: props.onChange },
    style ? { style } : undefined,
    placeholder ? { placeholder } : undefined,
    textareaRef ? { ref: textareaRef } : undefined
  )

  return <AutoResizeTextarea {...textareaProps} />
}

export default Textarea
