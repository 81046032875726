// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import { entries } from 'lodash-es'
import type { Node } from 'react'

import Attr from './Attr'

type Props = { attrs: Object, isSub?: boolean }

const List = (props: Props): Node => {
  const { attrs, isSub } = props

  const { t } = useTranslation('backend')

  const list = entries(attrs).map(([label, value]) => ({
    value,
    label,
  }))

  return (
    <>
      {list.map((attr, index) => (
        <Attr
          isSub={isSub}
          isLast={index + 1 === list.length}
          attr={attr}
          key={attr.label}
          t={t}
        />
      ))}
    </>
  )
}

export default List
