// @flow

import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { DragDropContext } from 'react-beautiful-dnd'

import Group from './Group'
import { getGroups } from '../Faq.selectors'
import { questionChangePosition } from '../Faq.actions'

type Props = {
  groups: Array<{
    id: number,
    questions: Array<Object>,
    title: string,
  }>,
  questionChangePosition: () => void,
  selected: Array<number>,
  t: string => string,
}

class GroupsList extends PureComponent<Props> {
  handleDragEnd = ({ draggableId, destination, source }) => {
    if (!destination) {
      return
    }

    const position = destination.index

    this.props.questionChangePosition({
      position,
      group: destination.droppableId,
      id: draggableId,
      source,
      destination,
    })
  }

  render() {
    const { groups } = this.props

    return (
      <DragDropContext onDragEnd={this.handleDragEnd}>
        {groups.map(g => (
          <Group key={g.id} id={g.id} title={g.title} questions={g.questions} />
        ))}
      </DragDropContext>
    )
  }
}

const mapStateToProps = state => ({
  groups: getGroups(state),
})
const mapDispatchToProps = {
  questionChangePosition,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(GroupsList)
