// @flow

import React from 'react'
import classnames from 'classnames'
import type { Node } from 'react'

import styles from './NewTable.module.scss'

type Props = {
  children: Node,
  hovered?: boolean,
  onClick?: () => void,
}

const NewTableBody = (props: Props): Node => {
  const { children, hovered } = props

  const tbodyClass = classnames({
    [styles.tableBody]: hovered,
  })

  return (
    <tbody className={tbodyClass} onClick={props.onClick}>
      {children}
    </tbody>
  )
}

export default NewTableBody
