// @flow

import React from 'react'
import type { Node } from 'react'

import Button from '../../../../Button'
import ListItem from '../../../../ListItem'
import { getItemText } from './BuildingsListItem.utils'

type Props = {
  changeSelected: Object => void,
  isDwellerGroup: boolean,
  isloading: boolean,
  item: Object,
  t: string => string,
}

const BuildingsListItem = (props: Props): Node => {
  const { item, isDwellerGroup, isloading } = props

  const text = getItemText(item, isDwellerGroup, props.t)

  const handleRemove = () => props.changeSelected(item)

  return (
    <ListItem
      primaryText={text}
      title={text}
      meta={<Button.Cross disabled={isloading} onClick={handleRemove} />}
    />
  )
}

export default BuildingsListItem
