// @flow

import React from 'react'
import type { Node } from 'react'

import Item from './Item'

type Props = { files?: Array<Object>, onRemove: number => void }

const FileList = (props: Props): Node => {
  const { files } = props

  if (!files || !files.length) {
    return null
  }

  return (
    <>
      {files.map(file => (
        <Item key={file.id} file={file} onRemove={props.onRemove} />
      ))}
    </>
  )
}

export default FileList
