// @flow

import React, { useEffect, useState } from 'react'
import { partial } from 'lodash-es'
import type { Node } from 'react'

import Button from '../../Button'
import Checkbox from '../../Checkbox'
import { updateActivity } from '../../../core/api/api.pipeline'

import styles from './TableCellDone.module.scss'

type Props = {
  commit_type: 'yesno' | 'done',
  id: number,
  isDone?: boolean,
  isYesNo?: ?'yes' | 'no',
  setDone: Function,
  setYesNo: Function,
}

const TableCellDone = (props: Props): Node => {
  const { commit_type, id, isDone, isYesNo } = props

  const [isMounted, setMounted] = useState(false)

  const handleChange = e => props.setDone(e.currentTarget.checked)

  const handleClcik = yesno => () =>
    props.setYesNo(isYesNo === yesno ? null : yesno)

  const update = partial(updateActivity, id)

  useEffect(() => {
    if (!isMounted) {
      setMounted(true)
    }
  }, [])

  useEffect(() => {
    if (isMounted) {
      update({ done: isDone })
    }
  }, [isDone])

  useEffect(() => {
    if (isMounted) {
      update({ yesno: isYesNo })
    }
  }, [isYesNo])

  return (
    <>
      {commit_type === 'done' ? (
        <Checkbox
          size='large'
          className={styles.checkbox}
          checked={isDone}
          onChange={handleChange}
        />
      ) : (
        <div className={styles.buttons}>
          <Button.Square
            active={isYesNo === 'yes'}
            onClick={handleClcik('yes')}
          >
            yes
          </Button.Square>
          <Button.Square active={isYesNo === 'no'} onClick={handleClcik('no')}>
            no
          </Button.Square>
        </div>
      )}
    </>
  )
}

export default TableCellDone
