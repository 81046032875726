// @flow

import React from 'react'
import type { Node } from 'react'

import { EntityIcon } from '../../../../../Icon'

import styles from './Info.module.scss'

const ProviderLink = (): Node => {
  const handleClick = e => {
    e.stopPropagation()
  }

  return (
    <div className={styles.iconBounding} onClick={handleClick}>
      <EntityIcon id='requestProvider' className={styles.entityIcon} />
    </div>
  )
}

export default ProviderLink
