// @flow

export const CONTACT_TYPES_SVG = {
  email: 'email',
  phone: 'phone',
  contact_email: 'email',
  contact_phone: 'phone',
  order_phone: 'phone',
  order_email: 'email',
  website: 'website',
  address: 'address',
  working_hours: 'deadline',
}

export const CONTACT_TYPES = {
  MAIL: 1,
  PHONE: 2,
  WEB: 3,
  AUTH_EMAIL: 4,
  AUTH_PHONE: 5,
  EMERGENCY_PHONE: 6,
}

export const EXTERNAL_CONTACT_TYPES = {
  PHONE_EXTERNAL: 100,
  WEB_EXTERNAL: 500,
  EMAIL_EXTERNAL: 300,
  ADDRESS_EXTERNAL: 400,
  EMERGENCY_PHONE_EXTERNAL: 200,
  WORKING_HOURS_EXTERNAL: 600,
  DESCRIPTION_EXTERNAL: 700,
}
