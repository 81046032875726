// @flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Header, SimpleBlock } from '../../Block'
import Button from '../../Button'
import CompanyInfoForm from './CompanyInfoForm'
import { useSelector } from 'react-redux'
import { getUk } from '../../../utils/commonSelectors'
import CompanyInfoText from './CompanyInfoText'

export const CompanyInfo = () => {
  const { t } = useTranslation('Company')

  const uk = useSelector(getUk)

  const { description, name, id, permissions } = uk

  const [working, setWorking] = useState(false)
  const [editing, setEditing] = useState(false)

  const handleEdit = () => setEditing(true)

  return (
    <SimpleBlock working={working}>
      <Header header>
        {t('InfoTitle')}
        {permissions.can_edit && !working && !editing && (
          <Button.Edit onClick={handleEdit} />
        )}
      </Header>
      {editing ? (
        <CompanyInfoForm
          description={description}
          name={name}
          id={id}
          setEditing={setEditing}
          setWorking={setWorking}
        />
      ) : (
        <CompanyInfoText
          name={name}
          description={description}
          permissions={permissions}
          editing={editing}
          handleEdit={handleEdit}
        />
      )}
    </SimpleBlock>
  )
}

export default CompanyInfo
