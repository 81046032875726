// @flow

import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import type Node from 'react'

import Modal from '../../../Modal'
import ModalBody from '../../../Modal/ModalBody'
import ModalCloseButton from '../../../Modal/ModalCloseButton'
import ModalHead from '../../../Modal/ModalHead'
import ModalButtons from '../../../Modal/ModalButtons'

import NewSelectSimple from '../../../NewSelectSimple'
import Button from '../../../Button'

import { useOverflow } from '../../../../hooks'
import { useThemeColor } from '../../../../hooks'

import styles from './PrinterModal.module.scss'

type Props = {
  isOpen: boolean,
  mode: 'init' | 'error',
  onClose: () => void,
  onSelectPrinter: Object => void,
  printers: Array<Object>,
}

const PrinterModal = (props: Props): Node => {
  const { isOpen, onClose, printers, onSelectPrinter, mode } = props

  const { t } = useTranslation('Mail')
  useOverflow()
  const themeColor = useThemeColor()

  const [printer, setPrinter] = useState(null)
  const [options, setOptions] = useState([])

  useEffect(() => {
    setOptions(
      printers.map(({ id: value, name, email }) => ({
        value,
        label: `${name} (${email})`,
      }))
    )
  }, [])

  useEffect(() => {
    if (options.length > 0) {
      setPrinter(options[0])
    }
  }, [options])

  const handleSave = () => {
    const [selected] = printers.filter(p => p.id === printer.value)
    onSelectPrinter(selected)
    onClose()
  }

  return (
    <>
      <Modal isOpen={isOpen} onRequestClose={onClose}>
        <ModalHead
          title={
            mode === 'error'
              ? t('PrinterUnavailableTitle')
              : t('SelectPrinterTitle')
          }
        />
        <ModalCloseButton onClose={onClose} />
        <ModalBody
          style={{
            '--themeColor': themeColor,
            width: '500px',
          }}
        >
          <div className={styles.text}>
            {mode === 'error'
              ? printers.length > 0
                ? t('SelectPrinterErrorText')
                : t('PrinterUnavailableText')
              : t('SelectPrinterText')}
          </div>
          {printers.length > 0 && (
            <NewSelectSimple
              className={styles.select}
              options={options}
              value={printer}
              onChange={setPrinter}
            />
          )}
          <ModalButtons>
            {printers.length > 0 ? (
              <Button.Save onClick={handleSave}>{t('Save')}</Button.Save>
            ) : (
              <Button.Save onClick={handleSave}>{t('OkButton')}</Button.Save>
            )}
            {mode !== 'error' && (
              <Button.Cancel onClick={onClose}>{t('Cancel')}</Button.Cancel>
            )}
          </ModalButtons>
        </ModalBody>
      </Modal>
    </>
  )
}

export default PrinterModal
