// @flow

import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import Button from '../../../Button'
import SelectCustom from '../../../Select/SelectCustom'
import {
  newsListBlockPostInitiating,
  newsListBlockUserInitiating,
  newsListPinPost,
} from '../../../../containers/NewsList/NewsList.actions'
import { BLOCKED_STATUS, USER_STATUS } from '../../../../constants'
import { getUserId } from '../../../../utils/utils'

import styles from './Actions.module.scss'

import { push } from 'connected-react-router'

type Props = {|
  canBlock: boolean,
  canBlockOwner: boolean,
  canPinPost: boolean,
  canUnblockOwner: boolean,
  canUnpinPost: boolean,
  ownerObj: Object,
  pinned: boolean,
  pinning: boolean,
  spam: boolean,
  uuid: string,
|}

const actions = {
  POST_BLOCK: 0,
  USER_BLOCK: 1,
  EDITING: 2,
}

const Actions = (props: Props): Node => {
  const {
    pinned,
    canPinPost,
    canUnpinPost,
    uuid,
    pinning,
    spam,
    canBlock,
    canBlockOwner,
    canUnblockOwner,
    canEdit,
    ownerObj,
    ownerObj: { status },
  } = props

  const dispatch = useDispatch()
  const { t } = useTranslation('PostItem')

  const handlePin = () => dispatch(newsListPinPost(uuid, pinned))

  const blockPostOrUser = ({ value }) => {
    switch (value) {
      case actions.POST_BLOCK:
        dispatch(
          newsListBlockPostInitiating({
            postUuid: uuid,
            isUnblock: spam,
          })
        )
        break
      case actions.USER_BLOCK:
        dispatch(
          newsListBlockUserInitiating({
            postUuid: uuid,
            userId: getUserId(ownerObj),
            isUnblock: USER_STATUS[status] === BLOCKED_STATUS,
          })
        )
        break
      case actions.EDITING:
        dispatch(push(`/post/${uuid}`))
        break
      default:
    }
  }

  const blockOptions = []

  if (canBlock) {
    blockOptions.push({
      value: 0,
      label: t(spam ? 'UnblockPost' : 'BlockPost'),
    })
  }

  if (canBlockOwner || canUnblockOwner) {
    blockOptions.push({
      value: 1,
      label: t(
        USER_STATUS[status] === BLOCKED_STATUS ? 'UnblockUser' : 'BlockUser'
      ),
    })
  }

  if (canEdit) {
    blockOptions.push({
      value: 2,
      label: t('EditPost'),
    })
  }

  const isVisiblePinButton = canPinPost || pinned || canUnpinPost
  const isVisibleActionSelect =
    canBlock || canUnblockOwner || canBlockOwner || canEdit

  return (
    <div className={styles.actions}>
      {isVisiblePinButton && (
        <Button.Pin
          disabled={!canPinPost && !canUnpinPost}
          active={pinned}
          working={pinning}
          onClick={handlePin}
        />
      )}
      {isVisibleActionSelect && (
        <SelectCustom blank options={blockOptions} onChange={blockPostOrUser} />
      )}
    </div>
  )
}

export default Actions
