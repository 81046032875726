// @flow

import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import classnames from 'classnames'
import type { Node } from 'react'

import { getThemeHeaderColor } from '../../utils/commonSelectors'
import HeaderSearch from '../HeaderSearch/HeaderSearch'
import FriendsNotifications from '../FriendsNotifications'
import HeaderProfile from './HeaderProfile'
import HeaderBurger from './HeaderBurger'
import type { User } from '../../components/Auth/Login/Login.reducer'

type Props = {
  uk: { name: string },
  user: User,
}

const Header = (props: Props): Node => {
  const { user } = props

  const theme = useSelector(getThemeHeaderColor)

  if (!user) {
    return null
  }

  const {
    uk: { name },
  } = props

  const linkClass = classnames('header__company--name', {
    'header__company--gray': theme === '#ffffff',
  })

  return (
    <header className='header'>
      <div className='container'>
        <HeaderBurger theme={theme} />
        <Link title={name} className={linkClass} to={'/'}>
          {name}
        </Link>
        <HeaderSearch theme={theme} />
        <FriendsNotifications theme={theme} />
        <HeaderProfile user={user} theme={theme} />
      </div>
    </header>
  )
}

export default Header
