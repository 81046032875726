// @flow

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'

import RouterTabs from '../../components/Tabs/RouterTabs'
import Pan from '../../components/Tabs/Pan'
import Loader from '../../components/Loader'
import FlatAddress from '../../components/Flat/FlatAddress'
import FlatDescription from '../../components/Flat/FlatDescription'
import FlatInfo from '../../components/Flat/FlatInfo'
import FlatDocuments from '../../components/Flat/FlatDocuments'
import RequestList from '../../components/Request/RequestList'
import * as actions from './FlatView.actionTypes'
import { getUser } from '../../utils/commonSelectors'
import Profiles from '../../components/Profiles'
import { isStaffUser, isDwellerUser } from '../../utils/utils'
import ProfileAttrs from '../../components/ProfileAttrs'
import FlatInformation from '../../components/Flat/FlatInformation'

type Props = {
  data: Object,
  dispatch: Object => void,
  initiated: boolean,
  location: Object,
  match: Object,
  params: Object,
  t: string => string,
  user: Object,
  working: boolean,
}

class FlatView extends Component<Props> {
  componentDidMount() {
    this.props.dispatch({
      type: actions.FLAT_VIEW_INITIATING,
      id: this.getId(),
    })
  }

  componentWillUnmount() {
    this.props.dispatch({
      type: actions.FLAT_VIEW_STATE_RESET,
    })
  }

  componentDidUpdate() {
    const { match, data } = this.props
    const id = data?.id

    if (id && +match.params.flatId !== id) {
      this.props.dispatch({
        type: actions.FLAT_VIEW_INITIATING,
        id: this.getId(),
      })
    }
  }

  getId = () => this.props.match.params.flatId

  update = params => {
    this.props.dispatch({
      type: actions.FLAT_VIEW_UPDATE,
      id: this.getId(),
      params,
    })
  }

  updateFiles = files => {
    this.props.dispatch({
      type: actions.FLAT_VIEW_UPDATE_GALLERY,
      files,
      id: this.getId(),
    })
  }

  render() {
    const { data, initiated, t, user, working, location, history, error } =
      this.props

    if (!initiated) {
      return <Loader text={false} type='big' />
    }

    const { permissions, attributes } = data

    const id = this.getId()
    const {
      permissions: { can_change_dweller_role: canChangeDwellerRole },
    } = user

    const isStaff = isStaffUser(user)
    const isDweller = isDwellerUser(user)

    return (
      <div className='content__col'>
        <FlatInfo
          {...data}
          flatId={id}
          location={location}
          history={history}
          languageCode={user.language_obj.code}
        />
        <RouterTabs className='tabs tabs-style-transparent'>
          {permissions.can_view_info && (
            <Pan
              id='flat-tab-information'
              label={t('InformationTab')}
              linkHash='#information'
            >
              <FlatInformation flatId={id} />
            </Pan>
          )}
          <Pan id='flat-tab-main' label={t('MainInfoTab')} linkHash='#main'>
            <FlatDescription
              noMargin
              flat={data}
              working={working}
              onSave={this.update}
            />
            {permissions.can_view_dwellers && (
              <Profiles
                canChangeDwellerRole={canChangeDwellerRole}
                flat={data}
              />
            )}
          </Pan>
          <Pan id='flat-tab-address' label={t('Address')} linkHash='#address'>
            <FlatAddress
              address={data.address_obj}
              building={data.building}
              flat={data}
              working={working}
              error={error}
              onSave={this.update}
            />
          </Pan>
          {permissions.can_view_requests && (
            <Pan
              id='flat-tab-requests'
              label={t('Requests')}
              linkHash='#requests'
            >
              <RequestList params={{ flat: id }} init={{ flat: id }} />
            </Pan>
          )}
          <Pan
            id='flat-tab-documents'
            label={t('Documents')}
            linkHash='#documents'
          >
            <FlatDocuments
              flat={data}
              permissions={permissions}
              isDweller={isDweller}
            />
          </Pan>
          {attributes && isStaff && (
            <Pan
              id='flat-tab-attrs'
              label={t('AttributesTabTitle')}
              linkHash='#attrs'
            >
              <ProfileAttrs
                attributes={attributes}
                title={t('AttributesTitle')}
              />
            </Pan>
          )}
        </RouterTabs>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state.flatView,
  user: getUser(state),
})

export default compose(
  withTranslation('Flat'),
  connect(mapStateToProps)
)(FlatView)
