// @flow

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'
import { useDispatch } from 'react-redux'

import { isEqual } from 'lodash-es'

import { SimpleBlock, Header } from '../Block'

import Loader from '../Loader'
import ManagersBar from '../Building/ManagersBar'
import Radio from '../Radio'

import { globalModalError } from '../Layout/Layout.actions'

import Settings from '../Settings'

import {
  getAuthorizedUsers,
  updateAuthorizedUsers,
} from '../../core/api/api.mail'

import styles from './EmailIntegrationNew.module.scss'

import { MANAGERS_LIMIT } from '../../constants'

type Props = { accountId: number }

const AuthorizedUsers = (props: Props): Node => {
  const { accountId } = props

  const [isLoading, setLoading] = useState(true)
  const [isSaving, setSaving] = useState(false)
  const [isAll, setAll] = useState(true)
  const [initialUsers, setInitialUsers] = useState([])
  const [users, setUsers] = useState([])
  const [permissions, setPermissions] = useState({})
  const [editingManagers, setEditingManagers] = useState(false)

  const { t } = useTranslation('EmailIntegrationNylas')
  const dispatch = useDispatch()

  useEffect(() => {
    getAuthorizedUsers(accountId, { page_size: MANAGERS_LIMIT }).then(data => {
      setInitialUsers(data.results?.objects || [])
      setUsers(data.results?.objects || [])
      setPermissions(data.permissions || {})
      setEditingManagers(
        data.permissions?.can_create || data.permissions?.can_delete
      )
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    setAll(users.length === 0)
  }, [users])

  const handleChangeRadio = e => setAll(JSON.parse(e.target.value))

  const handleUpdateUsers = u => {
    if (u.length > MANAGERS_LIMIT) {
      dispatch(
        globalModalError(
          t('AuthorizedUsersLimitText', { limit: MANAGERS_LIMIT }),
          t('AuthorizedUsersLimitTitle'),
          true
        )
      )
      setEditingManagers(false)
      setTimeout(() => setEditingManagers(true), 0)
    } else {
      setUsers(u)
    }
  }

  const handleSave = () => {
    setSaving(true)
    updateAuthorizedUsers(accountId, {
      user_ids: isAll ? [] : users.map(user => user.id),
    }).then(data => {
      setInitialUsers(data.results.objects)
      setUsers(data.results.objects)
      setPermissions(data.permissions)
      setSaving(false)
    })
  }

  const changed = !isEqual(users, initialUsers) || (isAll && users.length > 0)

  return (
    <SimpleBlock>
      <Header header>{t('AuthorizedUsers')}</Header>
      {isLoading ? (
        <Loader text={false} type='medium' />
      ) : (
        <>
          <div className={styles.authUsersRow}>
            <Radio
              id='all-users'
              name='auth-users'
              value={true}
              checked={isAll}
              label={t('AllUsers')}
              onChange={handleChangeRadio}
            />
          </div>
          <div className={styles.authUsersRow}>
            <Radio
              id='stricted-users'
              name='auth-users'
              value={false}
              checked={!isAll}
              label={t('SelectedUsers')}
              onChange={handleChangeRadio}
            />
          </div>
          <div className={styles.authUsersRow}>
            {!isAll && (
              <ManagersBar
                margin
                max={MANAGERS_LIMIT}
                data={{
                  owner_objs: users,
                  permissions: {
                    can_edit_manager:
                      permissions.can_create || permissions.can_delete,
                  },
                }}
                title={t('AuthorizedUsersBar')}
                working={isLoading}
                editingManagers={editingManagers}
                editManagers={() => setEditingManagers(true)}
                updateManagers={handleUpdateUsers}
              />
            )}
          </div>
          <Settings.Actions
            withChanges={changed}
            isValid={true}
            working={isSaving}
            onSave={handleSave}
            onCancel={() => {
              setUsers(initialUsers)
              setAll(users.length === 0)
              setEditingManagers(false)
              setTimeout(() => setEditingManagers(true), 0)
            }}
          />
        </>
      )}
    </SimpleBlock>
  )
}

export default AuthorizedUsers
