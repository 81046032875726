/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type OrderProfileCreationDate_data$ref: FragmentReference;
declare export opaque type OrderProfileCreationDate_data$fragmentType: OrderProfileCreationDate_data$ref;
export type OrderProfileCreationDate_data = {|
  +createdAt: any,
  +$refType: OrderProfileCreationDate_data$ref,
|};
export type OrderProfileCreationDate_data$data = OrderProfileCreationDate_data;
export type OrderProfileCreationDate_data$key = {
  +$data?: OrderProfileCreationDate_data$data,
  +$fragmentRefs: OrderProfileCreationDate_data$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "OrderProfileCreationDate_data",
  "type": "CustomerOrderNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "createdAt",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '5198ba98a212d54117f6a9e8417da025';
module.exports = node;
