// @flow

import React from 'react'
import type { Node } from 'react'

import Content from './Content'
import BuildingGroupList from './Building/BuildingGroupList/BuildingGroupList'

type Props = { setGroupCount: number => void }

const BuildingGroups = (props: Props): Node => {
  return (
    <Content>
      <BuildingGroupList setGroupCount={props.setGroupCount} />
    </Content>
  )
}

export default BuildingGroups
