// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'connected-react-router'
import { withTranslation } from 'react-i18next'
import { Scrollbars } from 'react-custom-scrollbars'
import OutsideClick from 'react-onclickout'
import isNil from 'lodash-es/isNil'
import isEmpty from 'lodash-es/isEmpty'
import classnames from 'classnames'
import { compose } from 'redux'

import Button from './Button'
import Loader from '../Loader'
import Icon from '../Icon'
import * as actions from './FriendsNotifications.actionTypes'
import { getUserName, isNoProfile } from '../../utils/utils'

import styles from './FriendsNotifications.module.scss'

const PAGE_SIZE = 4

type Props = {
  count: number,
  dispatch: Object => void,
  items: Array<Object>,
  meta: Object,
  opened: boolean,
  t: string => string,
  working: boolean,
}

class FriendsNotifications extends Component<Props> {
  componentDidMount() {
    this.props.dispatch({ type: actions.FRIEND_NOTIFICATION_INITIATE })
  }

  onScroll = e => {
    const { scrollHeight, offsetHeight, scrollTop } = e.target
    const {
      working,
      meta: { curr_page: currentPage, page_count: pageCount },
    } = this.props

    if (working || pageCount === currentPage) {
      return
    }

    const scrolled = offsetHeight + scrollTop

    if (scrollHeight * 0.7 <= scrolled) {
      this.props.dispatch({
        type: actions.FRIEND_NOTIFICATION_LOAD,
        page: currentPage + 1,
      })
    }
  }

  getSafePage = () => {
    const {
      items,
      meta: { curr_page: currentPage },
    } = this.props

    if (items.length > 1) {
      return currentPage
    }

    if (currentPage > 1) {
      return currentPage - 1
    }

    return null
  }

  toggle = () => {
    const { opened } = this.props

    if (opened) {
      this.close()
    } else {
      this.open()
    }
  }

  open = () => {
    this.props.dispatch({ type: actions.FRIEND_NOTIFICATION_OPEN })
    this.props.dispatch({ type: actions.FRIEND_NOTIFICATION_LOAD })
  }

  close = () => {
    if (this.props.opened) {
      this.props.dispatch({ type: actions.FRIEND_NOTIFICATION_CLOSE })
    }
  }

  handle = (user, accept) => {
    this.props.dispatch({
      type: actions.FRIEND_NOTIFICATION_HANDLE,
      page: this.getSafePage(),
      accept,
      user,
    })
  }

  openProfile = (id, isClickable) => {
    if (isClickable) {
      this.close()
      this.props.dispatch(push(`/profile/${id}`))
    }
  }

  renderItem = user => {
    const { id, avatar } = user
    const isClickable = !isNoProfile(user)

    const userName = getUserName(user)

    return (
      <div className={styles.offer} key={`notifier-${id}`}>
        <a onClick={() => this.openProfile(id, isClickable)}>
          <img className='notifier-b1__img' src={avatar} alt='userpic' />
          <div className='notifier-b1__text' title={userName}>
            {userName}
          </div>
        </a>
        <div className={styles.buttons}>
          <Button accept id={id} onClick={this.handle} />
          <Button id={id} onClick={this.handle} />
        </div>
      </div>
    )
  }

  renderDropdown = () => {
    const { working, meta, count, items, t } = this.props

    if (isEmpty(meta)) {
      return (
        <div className='notifier notifier-empty header__notifier-position-absolute'>
          <div className='notifier-nav'>
            <Loader />
          </div>
        </div>
      )
    }

    const listClass = classnames('notifier__wrapper', {
      'working-overlay': working && !!items.length,
    })
    const scrollbarsProps = {
      renderTrackHorizontal: () => <div className='scrollbars-horizontal' />,
      renderTrackVertical: props => (
        <div {...props} className='scrollbar__track' />
      ),
      renderThumbVertical: props => (
        <div {...props} className='scrollbar__thumb' />
      ),
    }

    return (
      <div className='notifier header__notifier-position-absolute'>
        <div className='notifier-nav'>
          {!!count && (
            <a className='notifier-nav__item notifier-nav__item_active'>
              {t('UnhandledRequests')}
              &nbsp;(
              {count})
            </a>
          )}
          {!!count && (
            <Link
              to='/friends'
              className='notifier-nav__item'
              onClick={this.close}
            >
              {t('AllRequests')}
            </Link>
          )}
          {!count && (
            <a className='notifier-nav__item notifier-nav__item_active'>
              {t('EmptyRequests')}
            </a>
          )}
        </div>
        {!!count && (
          <div className={listClass} onScroll={this.onScroll}>
            {items.length > PAGE_SIZE ? (
              <Scrollbars {...scrollbarsProps}>
                <div className='notifier-list notifier-list-scrolled'>
                  {items.map(this.renderItem)}
                </div>
              </Scrollbars>
            ) : (
              <div className='notifier-list'>{items.map(this.renderItem)}</div>
            )}
          </div>
        )}
        {working && <Loader />}
      </div>
    )
  }

  render() {
    const { opened, count, theme } = this.props
    const isBadgeShown = !isNil(count) && count !== 0
    const headerFriendsClass = classnames('header__nav-item header-friends', {
      'header-friends__opened': opened,
    })

    const iconClass = classnames('header__notifier', {
      [styles.gray]: theme === '#ffffff',
    })

    const badgeClass = classnames(
      'badge badge_xs badge_success header__notifier-position-absolute',
      { [styles.gray]: theme === '#ffffff' }
    )

    return (
      <OutsideClick onClickOut={this.close}>
        <div className={headerFriendsClass}>
          <Icon id='bell' className={iconClass} onClick={this.toggle} />
          {isBadgeShown && (
            <i className={badgeClass} onClick={this.toggle}>
              {count}
            </i>
          )}
          {opened && this.renderDropdown()}
        </div>
      </OutsideClick>
    )
  }
}

const mapStateToProps = state => ({
  ...state.friendsNotifications,
  count: state.counters.friend_invites,
})

export default compose(
  withTranslation('Friends'),
  connect(mapStateToProps)
)(FriendsNotifications)
