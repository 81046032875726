/* istanbul ignore next */
/**
 * Default values
 */
export default {
  fixtures: [],
  initialValue: '',
  placeholder: 'Введите адрес',
  disabled: false,
  location: null,
  radius: null,
  bounds: null,
  country: null,
  types: null,
  queryDelay: 250,
  googleMaps: null,
  onActivateSuggest: () => {
  },
  onSuggestSelect: () => {
  },
  onSuggestNoResults: () => {
  },
  onFocus: () => {
  },
  onBlur: () => {
  },
  onChange: () => {
  },
  skipSuggest: () => {
  },
  getSuggestLabel: (suggest) => suggest.description,
  autoActivateFirstSuggest: false,
  ignoreTab: false
}
