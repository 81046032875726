// @flow

import React from 'react'
import type { Node } from 'react'

import AuthPage from '../AuthPage'
import RecoveryForm from './RecoveryForm'

const Recovery = (): Node => (
  <AuthPage>
    <RecoveryForm />
  </AuthPage>
)

export default Recovery
