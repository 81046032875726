// @flow

import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'
import Joi from 'joi-browser'
import validation from 'react-validation-mixin'
import validationStrategy from 'joi-browser-validation-strategy'
import { every } from 'lodash-es'
import { compose } from 'redux'

import { getById } from '../../../core/api/api.file'

import Block, { Header, CellsBar, Cell } from '../../../components/Block'
import Button from '../../../components/Button'
import Icon from '../../../../static/icons/real-data.svg'
import InputPassword from '../../../components/InputPassword'
import Loader from '../../../components/Loader'
import MatchingRequests from '../../../components/modals/MatchingIgelRequests'
import Modal from '../../../components/Modal'
import Notifications from '../../../components/Notification/NotificationList'
import Select from '../../../components/Select/SelectSimple'
import SelectUser from '../../../components/Select/SelectUser'
import Settings from '../../../components/Settings'
import { formatDateTime } from '../../../utils/utils'
import { SECRET_PASSWORD } from '../Integration.constants'
import * as actions from './Igel.actionTypes'
import * as constants from './Igel.constants'
import BrowserStorage from '../../../utils/browserStorage'

import styles from '../Integrations.module.scss'

type Callback = boolean => void
type Props = {
  config: Object,
  copyingRequestTypes: boolean,
  dispatch: Object => void,
  errors: Array<Object>,
  exist: boolean,
  getValidationMessages: string => Array<string>,
  initiated: boolean,
  isValid: string => boolean,
  matchingRequests: boolean,
  popupError: ?string,
  requests: Object,
  requestsWereCopied: boolean,
  status: Object,
  t: string => string,
  validate: Callback => void,
  working: boolean,
}
type Config = {
  import_igel_request: boolean,
  overwrite_building: boolean,
  overwrite_profile: boolean,
  overwrite_request: boolean,
  password: string,
  reporter: ?number,
  restore_data: boolean,
  update_frequency: number,
  url: string,
  username: string,
}
type State = {
  collapsed: boolean,
  config: Config,
  needInitReporter: boolean,
  openPopup: boolean,
}

class Igel extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    const { t } = this.props
    const options = {
      language: {
        any: { empty: `!! ${t('Common:FieldIsRequired')}` },
        number: { base: `!! ${t('igel.emptyReporter')}` },
        string: { uri: `!! ${t('Common:incorrectUrl')}` },
      },
    }

    this.validatorTypes = {
      username: Joi.string().required().options(options),
      reporter: Joi.number().integer().required().options(options),
      url: Joi.string().uri().required().options(options),
    }
  }

  state = {
    config: {
      overwrite_building: true,
      overwrite_profile: true,
      overwrite_request: true,
      import_igel_request: true,
      password: '',
      reporter: null,
      restore_data: false,
      update_frequency: 0,
      url: '',
      username: '',
    },
    needInitReporter: false,
    openPopup: false,
    collapsed: true,
  }

  validatorTypes = {}

  componentDidMount() {
    const collapsed = JSON.parse(
      BrowserStorage.get(constants.IGEL_LOCALE_STORAGE_KEY)
    )

    const { initiated, config } = this.props

    if (collapsed === null) {
      BrowserStorage.set(constants.IGEL_LOCALE_STORAGE_KEY, true)
      this.setState({ collapsed: true })
    } else if (collapsed === false && !initiated) {
      this.setState({ collapsed: false })
      this.props.dispatch({ type: actions.IGEL_INITIATE })
    } else if (initiated) {
      this.setState({ collapsed, config: { ...config } })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.config !== this.props.config && !this.props.config.username) {
      this.validatorTypes.password = this.validatorTypes.username
    }

    if (!prevProps.initiated && this.props.initiated) {
      if (this.props.exist) {
        this.setState({ config: this.props.config })
      } else {
        const { config } = this.state

        this.props.dispatch({
          type: actions.IGEL_CONFIG_WAS_APPLIED,
          config,
        })
      }
    }
  }

  onClickTitle = () => {
    const { collapsed } = this.state
    const { initiated } = this.props

    const update = () => {
      BrowserStorage.set(constants.IGEL_LOCALE_STORAGE_KEY, !collapsed)

      if (!initiated) {
        this.props.dispatch({ type: actions.IGEL_INITIATE })
      }
    }

    this.setState({ collapsed: !collapsed }, update)
  }

  getValidatorData = () => this.state.config

  getInputSettingsProps = (field: string) => {
    const { isValid, getValidationMessages, t } = this.props

    return {
      isValid,
      getError: getValidationMessages,
      field,
      label: t(`igel.${field}`),
    }
  }

  getRowsClassName = () =>
    classnames({
      'working-overlay':
        this.props.status.is_sync_in_progress || this.props.working,
    })

  overwriteOptions = [
    { value: true, label: this.props.t('Common:yes') },
    { value: false, label: this.props.t('Common:no') },
  ]

  syncOptions = [
    { value: 1, label: this.props.t('Common:yes') },
    { value: 0, label: this.props.t('Common:no') },
  ]

  openMatchingRequests = () => {
    this.setState({ openPopup: true })
  }

  hidePopup = () => {
    this.setState({ openPopup: false })
    this.props.dispatch({ type: actions.IGEL_RESET_POPUP_ERROR })
  }

  updateField = (key: string, value: ?string) => {
    const { config } = this.state
    this.setState({
      config: {
        ...config,
        [key]: value,
      },
    })
  }

  updateInput = ({ target: { name, value } }: Object) => {
    this.updateField(name, value)
  }

  updateReporter = (val: ?Object) => {
    this.updateField('reporter', val ? val.owner : null)
  }

  cancel = () => {
    const { config } = this.props
    const { needInitReporter } = this.state
    this.setState({
      config,
      needInitReporter: !needInitReporter,
    })
  }

  sync = () => {
    this.props.dispatch({ type: actions.IGEL_SYNCHRONIZE })
  }

  checkStatus = () => {
    this.props.dispatch({ type: actions.IGEL_CHECK_STATUS })
  }

  copyRequestTypes = (items: Array<Object>) => {
    this.props.dispatch({
      type: actions.IGEL_COPY_REQUEST_TYPES,
      items: JSON.stringify(items),
    })
  }

  matchRequests = (items: Array<Object>) => {
    this.props.dispatch({
      type: actions.IGEL_MATCH_REQUESTS,
      items: JSON.stringify(items),
    })
  }

  downloadFile = fileId => {
    getById(fileId).then(res => {
      if (res.checking) {
        setTimeout(() => this.downloadFile(fileId), 500)
      } else {
        window.open(res)
      }
    })
  }

  save = () => {
    const { validate, working, exist, dispatch } = this.props

    if (working) {
      return
    }

    validate(err => {
      if (!err) {
        const { config } = this.state
        const type = exist ? actions.IGEL_APPLY_CONFIG : actions.IGEL_CREATE
        dispatch({ type, config })
      }
    })
  }

  renderStatus = () => {
    const {
      status: {
        is_sync_in_progress: inProgress,
        last_sync_timestamp: lastSync,
      },
      t,
    } = this.props

    let text = 'igel.empty'

    if (inProgress) {
      text = 'igel.inProgress'
    } else if (lastSync) {
      text = 'igel.done'
    }

    const className = classnames('bar__cell-value', {
      'value-progress': inProgress,
      'value-done': lastSync,
    })

    return (
      <Cell title={t('igel.status')} valueClassName={className}>
        {t(text)}
      </Cell>
    )
  }

  renderSelect = (key: string) => {
    const { config } = this.state
    const options =
      key === 'update_frequency' ? this.syncOptions : this.overwriteOptions
    const value = options.find(o => o.value === config[key])

    return (
      <Select
        noClearButton
        disabled={key === 'update_frequency'}
        options={options}
        placeholder={this.props.t('realData.select_option')}
        value={value}
        onChange={o => this.updateField(key, o.value)}
      />
    )
  }

  renderActions = () => {
    const {
      working,
      status: {
        last_sync_timestamp: lastSync,
        is_sync_in_progress: inProgress,
        report_file,
      },
      exist,
      t,
    } = this.props
    const { config } = this.state
    const sameFields = every(
      config,
      (val, key) => this.props.config[key] === val
    )

    const text = (!!lastSync || !!report_file) && (
      <>
        {!!lastSync && (
          <div className='submit--additional'>
            <span className='settings-personal__form-label'>
              {t('igel.wasSynced')}:{' '}
            </span>
            <span className='input'>{formatDateTime(lastSync)}</span>
          </div>
        )}
        {!!report_file && (
          <div className='submit--additional submit--additional--report'>
            <a
              download={report_file.filename}
              target='_blank'
              href={report_file.origin}
              rel='noopener noreferrer'
              onClick={e => {
                e.preventDefault()
                this.downloadFile(report_file.id)
              }}
            >
              {t('igel.downloadReport')}
            </a>
          </div>
        )}
      </>
    )

    if (inProgress) {
      return (
        <div className='settings-personal__submit'>
          <Button.Save working={working} onClick={this.checkStatus}>
            {t('igel.checkSync')}
          </Button.Save>
          {text}
        </div>
      )
    }

    if (exist && sameFields) {
      return (
        <div className='settings-personal__submit'>
          <Button.Save working={working} onClick={this.sync}>
            {t('igel.startSync')}
          </Button.Save>
          {text}
        </div>
      )
    }

    return (
      <Settings.Actions
        additional={text}
        withChanges={!sameFields}
        working={working}
        onCancel={this.cancel}
        onSave={this.save}
      />
    )
  }

  renderMatching = () => {
    const {
      requests: { unmapped },
      exist,
      t,
    } = this.props

    const labelClassName = exist
      ? classnames(
          { 'map-tag-cat__map': !unmapped.length },
          { 'map-tag-cat__not-map': unmapped.length }
        )
      : ''

    return (
      <div className='map-tag-cat'>
        <div className={labelClassName}>
          {unmapped.length
            ? t('igel.typesWereNotMatched')
            : t('igel.typesWereMatched')}
        </div>
        <Button.Regular
          className={unmapped.length ? 'button--success' : 'button--success-2'}
          disabled={!exist}
          style={{ marginLeft: '10px' }}
          onClick={this.openMatchingRequests}
        >
          {unmapped.length ? t('igel.matchTypes') : t('Common:edit')}
        </Button.Regular>
      </div>
    )
  }

  renderModal = () => {
    const {
      requests,
      requestsWereCopied,
      matchingRequests,
      copyingRequestTypes,
      popupError,
    } = this.props
    const { openPopup } = this.state

    return (
      <Modal isOpen={openPopup} onRequestClose={this.hidePopup}>
        <MatchingRequests
          copying={copyingRequestTypes}
          error={popupError}
          items={requests}
          itemsWereCopied={requestsWereCopied}
          working={matchingRequests}
          onClose={this.hidePopup}
          onCopyTypes={this.copyRequestTypes}
          onSave={this.matchRequests}
        />
      </Modal>
    )
  }

  renderBuildings = () => {
    const { t } = this.props

    return (
      <Fragment>
        <div className='bar__title bar__title-indented'>
          <span className='bar__title-text'>{t('igel.buildingsTitle')}</span>
        </div>
        <div className={this.getRowsClassName()}>
          <Settings.Hint text={t('igel.buildingsHint')} />
          <Settings.Row label={t('igel.buildings')}>
            {this.renderSelect('overwrite_building')}
          </Settings.Row>

          <Settings.Row label={t('igel.profiles')}>
            {this.renderSelect('overwrite_profile')}
          </Settings.Row>
        </div>
      </Fragment>
    )
  }

  renderRequests = () => {
    const { t } = this.props
    const {
      config: { reporter },
      needInitReporter,
    } = this.state

    return (
      <Fragment>
        <div className='bar__title bar__title-indented'>
          <span className='bar__title-text'>{t('igel.requestsTitle')}</span>
        </div>

        <div className={this.getRowsClassName()}>
          <Settings.Row
            {...this.getInputSettingsProps('reporter')}
            label={t('igel.reporter')}
          >
            <SelectUser
              fullWidth
              btnPlaceholder='adminButton'
              id='igel-reporter'
              monitor={needInitReporter}
              params={{ group: 'admin' }}
              initialValueId={reporter}
              onChange={this.updateReporter}
            />
          </Settings.Row>

          <Settings.Row label={t('igel.requests')}>
            {this.renderSelect('overwrite_request')}
          </Settings.Row>

          <Settings.Row label={t('igel.importIgelRequests')}>
            {this.renderSelect('import_igel_request')}
          </Settings.Row>

          <Settings.Row label={t('igel.matchingTypes')}>
            {this.renderMatching()}
          </Settings.Row>
        </div>
      </Fragment>
    )
  }

  renderCommon = () => {
    const { t, config } = this.props
    const { username, password, url } = this.state.config
    const defaultPassword = config.username ? SECRET_PASSWORD : ''

    return (
      <Fragment>
        <div className='bar__title bar__title-indented'>
          <span className='bar__title-text'>{t('igel.common')}</span>
        </div>

        <div className={this.getRowsClassName()}>
          <Settings.Row {...this.getInputSettingsProps('username')}>
            <input
              autoComplete='off'
              autoCorrect='off'
              name='username'
              value={username}
              spellCheck={false}
              onChange={this.updateInput}
            />
          </Settings.Row>

          <Settings.Row {...this.getInputSettingsProps('password')}>
            <InputPassword
              alwaysSecure
              autoComplete='off'
              name='password'
              value={password || defaultPassword}
              onChange={this.updateInput}
            />
          </Settings.Row>

          <Settings.Row {...this.getInputSettingsProps('url')}>
            <input
              autoComplete='off'
              autoCorrect='off'
              name='url'
              value={url}
              spellCheck={false}
              onChange={this.updateInput}
            />
          </Settings.Row>

          <Settings.Row label={t('igel.autoSync')}>
            {this.renderSelect('update_frequency')}
          </Settings.Row>

          <Settings.Hint text={t('igel.restoreHint')} />
          <Settings.Row label={t('igel.restore')}>
            {this.renderSelect('restore_data')}
          </Settings.Row>
        </div>
      </Fragment>
    )
  }

  renderContent = () => {
    const { collapsed } = this.state

    const {
      initiated,
      status: { last_sync_timestamp: lastSync, synced_obj_count: objects },
      t,
    } = this.props

    if (collapsed) {
      return null
    }

    if (!initiated) {
      return <Loader text={false} type='medium' />
    }

    return (
      <Fragment>
        <CellsBar>
          <Cell title={t('igel.lastSync')}>
            {lastSync ? formatDateTime(lastSync) : '-'}
          </Cell>
          {this.renderStatus()}
          <Cell title={t('igel.handled')}>{lastSync ? objects : '-'}</Cell>
        </CellsBar>

        <div className='settings-personal__form'>
          {this.renderCommon()}
          <div className='bar__delimiter' />
          {this.renderBuildings()}
          <div className='bar__delimiter' />
          {this.renderRequests()}
        </div>

        {this.renderModal()}

        <div className='bar__delimiter' />
        {this.renderActions()}
      </Fragment>
    )
  }

  render() {
    const { errors, working } = this.props
    const { collapsed } = this.state

    return (
      <Fragment>
        <Notifications
          indented
          notifications={errors}
          parent='settings-igel'
          removeTimeoutTime={15000}
        />
        <Block
          working={working}
          collapsed={collapsed}
          onClickHeader={this.onClickTitle}
        >
          <Header header>
            <span icon='true' className={styles.headerIcon}>
              <Icon />
            </span>
            {this.props.t('igel.title')}
          </Header>

          {this.renderContent()}
        </Block>
      </Fragment>
    )
  }
}

export default compose(
  withTranslation('Integrations'),
  connect(state => state.igel),
  validation(validationStrategy)
)(Igel)
