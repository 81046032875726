// @flow

import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import {
  deletePipelineTemplate,
  getPipelineTemplates,
  copyPipelineTemplate,
} from '../../core/api/api.pipeline'
import { useLoad, usePathname } from '../../hooks'
import Table from './Table'
import Content from '../Content'
import { Header, SimpleBlock } from '../Block'
import Loader from '../Loader'
import BottomPagination from '../Pagination/BottomPagination'
import EmptyList from '../EmptyList'
import PipelinesToolbox from './PipelinesToolbox'
import { useSelected } from '../../hooks'
import PipelineRemovalModal from './PipelineRemovalModal'
import PipelineCopyModal from './PipelineCopyModal'
import addRedirectToFirstPage from '../../hocs/addRedirectToFirstPage'

const Pipelines = (): Node => {
  const isLocked = usePathname()
  const [data, isLoading, isError, load] = useLoad(
    getPipelineTemplates,
    null,
    isLocked
  )

  const dispatch = useDispatch()

  const [selectedItems, changeSelected, setSelected, isAllSelected] =
    useSelected()

  const { t } = useTranslation('Pipeline')

  const [removingItems, setRemovingItems] = useState(null)
  const [modal, setModal] = useState(null)

  useEffect(() => {
    if (isLoading) {
      setSelected([])
      setRemovingItems(null)
    }
  }, [isLoading])

  useEffect(() => {
    if (removingItems && removingItems.length) {
      setModal(
        <PipelineRemovalModal
          hideModal={hideModal}
          handleRemove={handleMassRemove}
          isMassRemoval={!!(removingItems && removingItems.length > 1)}
        />
      )
    }
  }, [removingItems])

  // TODO Need error component
  if (isError) {
    return null
  }

  if (!data && !isLoading) {
    return null
  }

  const hideModal = () => setModal(null)

  const removePipelines = async () => {
    return Promise.all(
      removingItems.map(item => {
        return deletePipelineTemplate(item)
          .then(data => data)
          .catch(error => error)
      })
    )
  }

  const handleMassRemove = async () => {
    hideModal()

    if (!removingItems || !removingItems.length) {
      return
    }

    await removePipelines()

    load()
  }

  const openCopyModal = (id, name) => {
    setModal(
      <PipelineCopyModal
        handleCopy={handleCopyItem}
        itemId={id}
        itemName={name}
        onClose={hideModal}
      />
    )
  }

  const handleCopyItem = (id, params) => {
    copyPipelineTemplate(id, params).then(data => {
      dispatch(push(`/settings/pipeline/${data.id}/`))
    })
  }

  return (
    <Content>
      <SimpleBlock>
        <Header header>
          {t('PipelinesTitle')}&nbsp;
          {data && <span className='unit__title-num'>{data.meta.count}</span>}
        </Header>
        {data ? (
          <>
            <PipelinesToolbox
              selectedItems={selectedItems}
              setSelected={setSelected}
              checked={isAllSelected(data.results.objects)}
              itemValueKey='id'
              templates={data.results.objects}
              meta={data.meta}
              setRemovingItems={setRemovingItems}
              isLoading={isLoading}
              selected={
                !!selectedItems.length && !isAllSelected(data.results.objects)
              }
            />
            <Table
              isLoading={isLoading}
              templates={data.results.objects}
              selectedItems={selectedItems}
              setRemovingItems={setRemovingItems}
              onCopyItem={openCopyModal}
              onSelect={changeSelected}
            />
            {!data.results.objects.length && !isLoading && (
              <EmptyList
                embedded
                canAdd
                icon='request'
                title={t('PipelineNotFound')}
              />
            )}
            <BottomPagination meta={data.meta} classes='paginator-2' />
          </>
        ) : (
          <Loader type='big' text={false} />
        )}
      </SimpleBlock>
      {modal}
    </Content>
  )
}

export default addRedirectToFirstPage(Pipelines)
