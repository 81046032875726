/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type OrderProfilePrice_data$ref: FragmentReference;
declare export opaque type OrderProfilePrice_data$fragmentType: OrderProfilePrice_data$ref;
export type OrderProfilePrice_data = {|
  +grandTotal: ?string,
  +vatDetail: ?$ReadOnlyArray<?{|
    +vat: string,
    +total: string,
  |}>,
  +$refType: OrderProfilePrice_data$ref,
|};
export type OrderProfilePrice_data$data = OrderProfilePrice_data;
export type OrderProfilePrice_data$key = {
  +$data?: OrderProfilePrice_data$data,
  +$fragmentRefs: OrderProfilePrice_data$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "OrderProfilePrice_data",
  "type": "CustomerOrderNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "grandTotal",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "vatDetail",
      "storageKey": null,
      "args": null,
      "concreteType": "VATDetailNode",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "vat",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "total",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'bb820538cf89a0469e49aeaad0a0a59d';
module.exports = node;
