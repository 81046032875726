import React from 'react'
import CalendarHeader from 'rc-calendar/lib/calendar/CalendarHeader'

import MonthPanel from './MonthPanel'
import YearPanel from './YearPanel'

class CalendarHeaderNew extends CalendarHeader {
  onSelect = value => {
    this.props.onValueChange(value)
  }

  isMinDate = (value, minYear) =>
    value && value.year() === minYear && value.month() === 0

  isMaxDate = (value, maxYear) =>
    value && value.year() === maxYear && value.month() === 11

  render() {
    const { prefixCls, locale, value, minYear, maxYear, onlyMonth, className } =
      this.props
    const showPrev = !this.isMinDate(value, minYear || 1900)
    const showNext = !this.isMaxDate(
      value,
      maxYear || new Date().getFullYear() + 1
    )

    return (
      <div className={className}>
        <MonthPanel
          locale={locale}
          defaultValue={value}
          value={value}
          rootPrefixCls={prefixCls}
          onlyMonth={onlyMonth}
          showPrev={showPrev}
          showNext={showNext}
          onSelect={this.onSelect}
        />
        {onlyMonth ? null : (
          <YearPanel
            locale={locale}
            value={value}
            minYear={minYear}
            maxYear={maxYear}
            defaultValue={value}
            rootPrefixCls={prefixCls}
            onSelect={this.onSelect}
          />
        )}
      </div>
    )
  }
}

export default CalendarHeaderNew
