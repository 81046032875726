// @flow

import React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Textarea from 'react-textarea-autosize'
import { stringify } from 'qs'
import message from 'antd/es/message'
import type { Node } from 'react'

import { SimpleBlock, Header } from '../Block'
import Settings from '../Settings'
import Button from '../Button'
import Checkbox from '../Checkbox'

import styles from './CompanyLoginForm.module.scss'

type Props = { url: string, working: boolean }

const DEFAULT_WIDTH = 640
const DEFAULT_HEIGHT = 480

const CompanyLoginForm = (props: Props): Node => {
  const { working, url } = props

  const [enabled, setEnabled] = useState(true)
  const { t } = useTranslation('Company')

  if (working) {
    return null
  }

  const handleChange = e => setEnabled(e.currentTarget.checked)

  const domain = url.split('.').reverse()[0]

  const staticUrl = `https://files.idwell.${domain}/external-login-form/public/index.html`

  const params = Object.assign(
    {},
    { host: url },
    enabled ? { background: true } : undefined
  )

  const value = `<iframe width="${DEFAULT_WIDTH}" height="${DEFAULT_HEIGHT}" src="${staticUrl}?${stringify(
    params
  )}" />`

  const handleClick = () => {
    navigator.clipboard
      .writeText(value)
      .then(() => message.success(t('CopyToClipboardSuccessed')))
      .catch(() => message.error(t('CopyToClipboardError')))
  }

  const backgroundStatusText = enabled
    ? 'BackgroundIsEnabled'
    : 'BackgroundIsDisabled'

  return (
    <SimpleBlock>
      <Header header>{t('LoginFormSettingTitle')}</Header>
      <Settings.Row label={t('LoginFormData')}>
        <Textarea disabled className={styles.textarea} value={value} />
      </Settings.Row>
      <Settings.Row label={t('BackgroundStatus')}>
        <Checkbox
          text={t(backgroundStatusText)}
          checked={enabled}
          onChange={handleChange}
        />
      </Settings.Row>
      <div className='settings-personal__submit'>
        <Button.Save onClick={handleClick}>{t('CopyToClipboard')}</Button.Save>
      </div>
    </SimpleBlock>
  )
}

export default CompanyLoginForm
