// @flow

import React from 'react'
import type { Node } from 'react'

type Props = {
  length: number,
  message: string,
  messageClassname: string,
}

const StrengthMeter = (props: Props): Node => {
  const { length, message, messageClassname } = props

  return (
    <div>
      <div className='input__icon' />
      {length > 0 ? <div className={messageClassname}>{message}</div> : null}
    </div>
  )
}

export default StrengthMeter
