// @flow

import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import PhaseItemContext from '../../../PhaseItem.context'

import styles from './Title.module.scss'

const Title = (): Node => {
  const { index } = useContext(PhaseItemContext)

  const { t } = useTranslation('Activities')

  return (
    <span className={styles.title}>{`${t('PhaseIndexTitle')} ${
      index + 1
    }`}</span>
  )
}

export default Title
