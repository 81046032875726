// @flow

import React from 'react'

import Icon from '../../../static/icons/danger.svg'

type Props = {
  text: string,
}

const ErrorItem = (props: Props) => {
  const { text } = props

  return (
    <div className='u-a14__alert u-a14__alert_danger'>
      <Icon className='u-a14__alert-icon' />
      <span className='u-a14__alert-text'>{text}</span>
    </div>
  )
}

export default ErrorItem
