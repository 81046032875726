// @flow

import React, { Suspense } from 'react'
import { omitBy, isNil } from 'lodash-es'
import classnames from 'classnames'

import { useThumbnail } from '../../../hooks'
import * as Icons from './FileIcons'
import {
  DOC_TYPE,
  PDF_TYPE,
  IMAGE_TYPE,
  PPT_TYPE,
  XLS_TYPE,
  VIDEO_TYPE,
  getFileType,
} from '../../../utils/file'

type Props = {
  className?: string,
  file: Object,
  iconClass?: string,
  onClick?: () => void,
  size: string,
  style?: Object,
}

const FileIcon = (props: Props) => {
  const { className, file, style, size, iconClass } = props

  const thumbnail = useThumbnail(file, size, getFileType(file) === VIDEO_TYPE)

  let Icon = Icons.otherWhite

  const fileType = getFileType(file)

  switch (fileType) {
    case DOC_TYPE:
      Icon = Icons.docWhite
      break
    case PDF_TYPE:
      Icon = Icons.pdfWhite
      break
    case PPT_TYPE:
      Icon = Icons.pptWhite
      break
    case XLS_TYPE:
      Icon = Icons.xlsWhite
      break
    case IMAGE_TYPE:
    case VIDEO_TYPE: {
      const iconClassName = classnames('table-item__user-pic', iconClass)

      if (thumbnail) {
        return (
          <i className={iconClassName} style={{ marginLeft: '7px' }}>
            <img src={thumbnail} />
          </i>
        )
      } else {
        Icon = fileType === IMAGE_TYPE ? Icons.image : Icons.video
      }
    }
  }

  const iconProps = omitBy(
    {
      className,
      style,
      onClick: props.onClick,
    },
    isNil
  )

  return (
    <Suspense fallback={null}>
      <Icon {...iconProps} />
    </Suspense>
  )
}

FileIcon.defaultProps = {
  size: '170X170',
}

export default FileIcon
