// @flow

import React from 'react'
import type { Node } from 'react'

import styles from './Currency.module.scss'

const Currency = (): Node => {
  return <span className={styles.currency}>EUR</span>
}

export default Currency
