// @flow

import React from 'react'
import { useDragLayer } from 'react-dnd'
import { useTranslation } from 'react-i18next'

import styles from './FilesDragLayer.module.scss'

const layerStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
}

type Props = { selected: Array<number> }

const FilesDragLayer = (props: Props) => {
  const { selected } = props

  const { t } = useTranslation('Files')

  const collectedProps = useDragLayer(monitor => {
    return {
      isDragging: monitor.isDragging(),
      draggedItem: monitor.getItem(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
    }
  })

  const { isDragging, currentOffset, initialOffset, draggedItem } =
    collectedProps

  const getItemStyles = () => {
    if (!initialOffset || !currentOffset) {
      return {
        display: 'none',
      }
    }

    const { x, y } = currentOffset

    const transform = `translate(${x}px, ${y}px)`

    return {
      transform,
      WebkitTransform: transform,
    }
  }

  if (!isDragging) {
    return null
  }

  const { id, name, is_persistent } = draggedItem

  const length =
    selected.length > 1 && selected.includes(id) ? selected.length : 1

  const text = is_persistent ? t(name) : name

  return (
    <div style={layerStyles}>
      <div style={getItemStyles()} className={styles.dragScope}>
        <span className={styles.text}>{text}</span>
        {length > 1 && <span className={styles.count}>{length}</span>}
      </div>
    </div>
  )
}

export default FilesDragLayer
