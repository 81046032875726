// @flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import { useOverflow } from '../../../hooks'

import Button from '../../Button'
import Modal from '../../Modal'
import ModalBody from '../../Modal/ModalBody'
import ModalButtons from '../../Modal/ModalButtons'
import ModalCloseButton from '../../Modal/ModalCloseButton'
import ModalHead from '../../Modal/ModalHead'
import ModalRow from '../../Modal/ModalRow'
import ModalCell from '../../Modal/ModalCell'
import ModalLabel from '../../Modal/ModalLabel'
import InputField from '../../InputField'

import { MAX_NAME_LENGTH } from '../Contractors.constants'

type Props = {
  name: string,
  onClose: Function,
  updateName: Function,
}

const NameModal = (props: Props): Node => {
  const { t } = useTranslation('Contractors')
  const [name, setName] = useState(props.name)

  useOverflow()

  return (
    <Modal isOpen>
      <ModalCloseButton onClose={props.onClose} />
      <ModalHead title={t('NameEditTitle')} />
      <ModalBody>
        <ModalRow>
          <ModalCell>
            <ModalLabel>{t('Name')}</ModalLabel>
          </ModalCell>
          <ModalCell gridNumber={2}>
            <InputField
              autoFocus
              name='name'
              value={name}
              maxLength={MAX_NAME_LENGTH}
              placeholder={t('NamePlaceholder')}
              onChange={e => setName(e.target.value)}
            />
          </ModalCell>
        </ModalRow>
      </ModalBody>
      <ModalButtons>
        <Button.Save
          disabled={!name.length}
          onClick={() => props.updateName(name)}
        >
          {t('Common:Save')}
        </Button.Save>
        <Button.Cancel onClick={props.onClose}>
          {t('Common:Cancel')}
        </Button.Cancel>
      </ModalButtons>
    </Modal>
  )
}

export default NameModal
