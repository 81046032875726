// @flow

import React, { PureComponent } from 'react'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'

import { Header } from '../Block'
import Button from '../Button'
import { EntityIcon } from '../Icon'
import Loader from '../Loader'
import Modal from '../Modal'
import RenameChatroomPopUp from '../modals/RenameChatroomPopUp'
import { getEntityGoBackCallback } from '../../utils/routing'
import TextClipped from '../TextClipped'
import styles from './ChatroomHeader.module.scss'

type Props = {
  avatar: ?string,
  clearCreatedStatus: Function,
  dispatch: Function,
  history: Object,
  isCreated: boolean,
  loading: boolean,
  membersCount: number,
  minimized: boolean,
  onRenameChatroom: string => void,
  onToggle: () => void,
  permissions: Object,
  renaming: boolean,
  title: string,
}
type State = {
  modal: boolean,
}

class ChatroomHeader extends PureComponent<Props, State> {
  state = {
    modal: false,
  }

  componentDidUpdate(prevProps: Props) {
    const { renaming } = this.props

    if (prevProps.renaming && !renaming) {
      this.hideRenameChatroomPopUp()
    }
  }

  showRenameChatroomPopUp = e => {
    e.preventDefault()
    e.stopPropagation()
    const { permissions } = this.props

    if (permissions.can_edit_title) {
      this.setState({ modal: true })
    }
  }

  hideRenameChatroomPopUp = () => {
    this.setState({ modal: false })
  }

  renameChatroom = newTitle => {
    this.props.onRenameChatroom(newTitle)
    this.hideRenameChatroomPopUp()
  }

  backToList = () => {
    const { history, isCreated } = this.props
    const pathname = 'messages'

    if (isCreated) {
      return () => {
        this.props.clearCreatedStatus()
        this.props.dispatch(push(`/${pathname}`))
      }
    }

    return getEntityGoBackCallback(history, pathname)
  }

  render() {
    const {
      title,
      membersCount,
      avatar,
      loading,
      renaming,
      minimized,
      permissions: { can_edit_title: editableTitle },
    } = this.props

    if (loading) {
      return (
        <div className='messages__header messages__header--loader'>
          <Loader text={false} />
        </div>
      )
    }

    const titleClassName = classnames('messages__header-title', {
      'messages__header-title--editable': editableTitle,
    })

    return (
      <>
        <Header
          chat
          collapsed={minimized}
          goBack={this.backToList()}
          onClickHeader={this.props.onToggle}
        >
          <div icon='true'>
            {membersCount === 2 ? (
              <i className='messages__header-title-image'>
                <img src={avatar} alt='avatar' />
              </i>
            ) : (
              <EntityIcon large id='chat' />
            )}
          </div>

          <div
            className={titleClassName}
            onClick={this.showRenameChatroomPopUp}
          >
            <TextClipped className={styles.title} fontSize={27}>
              {title}
            </TextClipped>
            {editableTitle && <Button.Edit />}
          </div>
        </Header>
        <div className='messages__header-title-drop'>
          <Modal
            contentLabel=''
            className='Modal__Bootstrap modal-dialog'
            isOpen={this.state.modal}
            onRequestClose={this.hideRenameChatroomPopUp}
          >
            <RenameChatroomPopUp
              oldTitle={title}
              saving={renaming}
              onHide={this.hideRenameChatroomPopUp}
              onSave={this.renameChatroom}
            />
          </Modal>
        </div>
      </>
    )
  }
}

export default compose(withRouter, connect())(ChatroomHeader)
