// @flow

import { replace } from 'connected-react-router'
import { all, put, call, fork, takeEvery } from 'redux-saga/effects'

import api from '../../core/api'
import { globalModalError, serverError } from '../Layout/Layout.actions'
import { SERVER_404_ERROR } from '../Layout/Layout.constants'
import * as actions from './PostView.actionTypes'

const getFilesIds = files => files.map(f => f.id)

function* watchInit() {
  yield takeEvery(actions.POST_VIEW_INITIATING, init)
}

function* watchUpdateBody() {
  yield takeEvery(actions.POST_VIEW_UPDATE_BODY, updateBody)
}

function* watchUpdateHead() {
  yield takeEvery(actions.POST_VIEW_UPDATE_HEAD, updateHead)
}

function* watchUpdateGallery() {
  yield takeEvery(actions.POST_VIEW_UPDATE_FILES, updateGallery)
}

function* init(action) {
  try {
    const { id } = action
    const data = yield call(api.newsband.get, id)

    yield put({ type: actions.POST_VIEW_INITIATED, data })
  } catch (error) {
    const action = serverError(error)

    if (action.type === SERVER_404_ERROR) {
      yield put(replace('/404'))
    } else {
      yield put(action)
      yield put({ type: actions.POST_VIEW_ERROR, error })
    }
  }
}

function* updateBody(action) {
  try {
    const { title, text, id } = action
    const params = { title, text }

    const data = yield call(api.newsband.editPost, id, params)
    yield put({ type: actions.POST_VIEW_UPDATED, data })
  } catch (error) {
    yield put(serverError(error))
    yield put({ type: actions.POST_VIEW_ERROR, error })
  }
}

function* updateHead(action) {
  try {
    const {
      buildings,
      building_groups,
      comment,
      dateFrom,
      dateTo,
      id,
      audience,
    } = action

    const params = {
      buildings: buildings ? buildings.map(b => b.id) : undefined,
      building_groups: building_groups
        ? building_groups.map(b => b.id || b.value)
        : undefined,
      comments_forbidden: comment,
      date_from: dateFrom,
      date_to: dateTo,
      new_audience: audience,
    }

    const data = yield call(api.newsband.editPost, id, params)
    yield put({ type: actions.POST_VIEW_UPDATED, data })
  } catch (error) {
    yield put(
      globalModalError(error.message.response.data.errors.non_field_errors)
    )
    yield put({ type: actions.POST_VIEW_ERROR, error })
  }
}

function* updateGallery(action) {
  try {
    const { files, id } = action

    const ids = getFilesIds(files)
    const params = { files: ids }

    const data = yield call(api.newsband.editPost, id, params)
    yield put({ type: actions.POST_VIEW_UPDATED, data })
  } catch (error) {
    yield put(serverError(error))
    yield put({ type: actions.POST_VIEW_ERROR, error })
  }
}

export default function* watch() {
  yield all([
    fork(watchInit),
    fork(watchUpdateBody),
    fork(watchUpdateHead),
    fork(watchUpdateGallery),
  ])
}
