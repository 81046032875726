// @flow

import {
  WIDGET_MAY_KNOW_INITIATING,
  WIDGET_MAY_KNOW_INITIATED,
  WIDGET_MAY_KNOW_ERROR,
} from './WidgetMayKnow.actionTypes'

const initialState = {
  initiated: false,
  dwellers: [],
  working: false,
  error: null,
}

export default function widgetMayKnowReducer(state = initialState, action) {
  switch (action.type) {
    case WIDGET_MAY_KNOW_INITIATING:
      return {
        ...state,
        initiated: false,
        working: true,
      }
    case WIDGET_MAY_KNOW_INITIATED:
      return {
        ...state,
        initiated: true,
        dwellers: action.dwellers,
        working: false,
      }
    case WIDGET_MAY_KNOW_ERROR:
      return {
        ...state,
        initiated: true,
        working: false,
        error: action.error,
      }

    default:
      return state
  }
}
