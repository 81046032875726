// @flow

import { deleteRaw, getApi, patchRaw, postRaw } from './api.core'

export const getBudgetPlan = planId => getApi(`api/budget-plan/${planId}/`)
export const updateBudgetPlan = (planId, params) =>
  patchRaw(`api/budget-plan/${planId}/`, params)
export const createBudgetPlan = params => postRaw('api/budget-plan/', params)

export const createInvoice = params =>
  postRaw('api/budget-plan/invoice/', params)
export const updateInvoice = (id, params) =>
  patchRaw(`api/budget-plan/invoice/${id}/`, params)
export const deleteInvoice = id => deleteRaw(`api/budget-plan/invoice/${id}/`)

export const getInvoiceTypes = params =>
  getApi('api/budget-plan/invoice-type/', params)
export const createInvoiceType = params =>
  postRaw('api/budget-plan/invoice-type/batch/', params)

export const getPaymentTypes = params =>
  getApi('api/budget-plan/payment-type/', params)
export const getInvoicesSystemTypes = () =>
  getApi('api/budget-plan/export/invoices-system-types/')
export const getInvoiceFields = requestId =>
  getApi(`api/budget-plan/invoice-fields/${requestId}/`)
export const createPaymentType = params =>
  postRaw('api/budget-plan/payment-type/batch/', params)

export const getSuppliers = params =>
  getApi('api/budget-plan/suppliers/', params)

export const createReport = params => postRaw(`api/budget-plan/report/`, params)

export const loadRequestsInvoices = () => postRaw('api/budget-plan/export/')

export const getBudgetPlanInfo = () =>
  getApi('api/budget-plan/export/payment-info/')
export const createBudgetPlanInfo = params =>
  postRaw('api/budget-plan/export/payment-info/', params)
export const getDomondaSettings = () =>
  getApi('api/budget-plan/domonda/settings/')
export const updateDomondaSettings = params =>
  postRaw('api/budget-plan/domonda/settings/', params)
export const domondaParseInvoice = params =>
  postRaw('api/budget-plan/domonda/parse/', params)
export const domondaCheckParse = eventId =>
  getApi(`api/budget-plan/domonda/result/${eventId}/`)
