// @flow

import { getApi, postRaw, deleteApi, patchRaw } from './api.core'

export const getInfoStatus = params => getApi(`api/user-info-status/`, params)
export const updateInfoStatus = (id, params) =>
  patchRaw(`api/user-info-status/${id}/`, params)
export const createInfoStatus = params =>
  postRaw(`api/user-info-status/batch/`, params)
export const deletedInfoStatus = id => deleteApi(`api/user-info-status/${id}/`)
