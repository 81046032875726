// @flow

import { all, put, call, fork, takeEvery } from 'redux-saga/effects'

import api from '../../../core/api'
import { serverError, globalModalError } from '../../Layout/Layout.actions'
import * as actions from './BuildingDwellers.actionTypes'

function* watchInit() {
  yield takeEvery(actions.BUILDING_DWELLERS_INITIATING, init)
}

function* watchRemove() {
  yield takeEvery(actions.BUILDING_DWELLERS_REMOVE, remove)
}

function* watchLoad() {
  yield takeEvery(actions.BUILDING_DWELLERS_LOAD, load)
}

function* watchLoadOne() {
  yield takeEvery(actions.BUILDING_DWELLERS_LOAD_ONE, loadOne)
}

function* watchReload() {
  yield takeEvery(actions.BUILDING_DWELLERS_RELOAD, reload)
}

function* watchUpdateRole() {
  yield takeEvery(actions.BUILDING_DWELLERS_UPDATE_ROLE, updateRole)
}

function* init({ params }) {
  try {
    const data = yield call(api.flat.getAllProfileList, params)
    yield put({
      type: actions.BUILDING_DWELLERS_INITIATED,
      ...data,
      page: params.page,
    })
  } catch (error) {
    yield put(serverError(error))
    yield put({ type: actions.BUILDING_DWELLERS_ERROR, error })
  }
}

function* remove({ model_pks: pks, selected, params }) {
  try {
    yield call(api.massAction.updateRequest, {
      model: 'Profile',
      model_pks: pks,
      patch: {
        rm_user_from_flat: selected,
      },
    })
    const data = yield call(api.flat.getAllProfileList, params)
    yield put({
      type: actions.BUILDING_DWELLERS_UPDATE,
      ...data,
      page: params.page,
    })
  } catch (error) {
    yield put(serverError(error))

    if (error.message.response.status === 400) {
      yield put(globalModalError(error.message.response.data.detail))
    }

    yield put({ type: actions.BUILDING_DWELLERS_ERROR, error })
  }
}

function* load({ params }) {
  try {
    const data = yield call(api.flat.getAllProfileList, params)
    yield put({
      type: actions.BUILDING_DWELLERS_UPDATE,
      ...data,
      page: params.page,
    })
  } catch (error) {
    yield put(serverError(error))
    yield put({ type: actions.BUILDING_DWELLERS_ERROR, error })
  }
}

function* loadOne({ params }) {
  try {
    const data = yield call(api.flat.getAllProfileList, params)
    yield put({ type: actions.BUILDING_DWELLERS_UPDATE, ...data })
  } catch (error) {
    yield put(serverError(error))
    yield put({ type: actions.BUILDING_DWELLERS_ERROR, error })
  }
}

function* reload({ params }) {
  try {
    const data = yield call(api.flat.getAllProfileList, params)
    yield put({
      type: actions.BUILDING_DWELLERS_RELOAD_END,
      ...data,
      page: params.page,
    })
  } catch (error) {
    yield put(serverError(error))
    yield put({ type: actions.BUILDING_DWELLERS_ERROR, error })
  }
}

function* updateRole(action) {
  try {
    const { owner, group, params } = action
    yield call(api.profile.update, owner, { group })

    const data = yield call(api.flat.getAllProfileList, params)
    yield put({
      type: actions.BUILDING_DWELLERS_UPDATE,
      ...data,
      page: params.page,
    })
  } catch (error) {
    yield put(serverError(error))
    yield put({ type: actions.BUILDING_DWELLERS_ERROR, error })
  }
}

export default function* watch() {
  yield all([
    fork(watchInit),
    fork(watchRemove),
    fork(watchLoad),
    fork(watchReload),
    fork(watchLoadOne),
    fork(watchUpdateRole),
  ])
}
