// @flow

import { clone, pull, map } from 'lodash-es'

import {
  MAIL_LIST_RESET,
  MAIL_LIST_INITIATING,
  MAIL_LIST_INITIATED,
  MAIL_GET_FILES_INITIATING,
  MAIL_GET_FILES_INITIATED,
  TOGGLE_ALL,
  TOGGLE,
  MODAL,
  DELETE,
  RELOAD,
  RESTORE,
  CHANGE_NOTES,
  SET_FOLDER,
  THREADS_ERROR,
} from './ThreadsList.actionTypes'

import * as attachActions from '../../components/modals/AttachToRequestPopup/AttachToRequestPopup.actionTypes'

const initialState = {
  mailList: [],
  selected: [],
  meta: {},
  fileIdToBeShown: null,
  files: [],
  metaFiles: {},
  working: true,
  reload: false,
  activeTab: null,
  permissions: {},
  currentFolder: {},
}

export default function MailReducer(state = initialState, action) {
  let selected = []
  switch (action.type) {
    case MAIL_LIST_RESET:
      return initialState
    case MAIL_LIST_INITIATING:
      return {
        ...initialState,
        currentFolder: state.currentFolder,
        working: true,
        activeTab: action.folder,
      }
    case MAIL_LIST_INITIATED:
      return {
        ...state,
        mailList: action.mailList,
        meta: action.meta,
        permissions: action.permissions,
        working: false,
        reload: false,
        cantCreateMessage: action.cantCreateMessage,
      }
    case MODAL:
      return {
        ...state,
        modal: action.modal,
      }
    case RELOAD:
      return {
        ...state,
        reload: true,
      }
    case DELETE:
      return {
        ...state,
        working: true,
      }
    case RESTORE:
      return {
        ...state,
        working: true,
      }
    case attachActions.ATTACHED:
      return {
        ...state,
        modal: null,
      }
    case MAIL_GET_FILES_INITIATING:
      return {
        ...state,
      }
    case TOGGLE_ALL: {
      const { mailList } = state

      selected = []

      if (state.selected.length !== mailList.length) {
        selected = map(mailList, mail => mail.uuid)
      }

      return {
        ...state,
        selected,
      }
    }
    case TOGGLE: {
      const { id } = action

      selected = clone(state.selected)

      if (selected.includes(id)) {
        selected = pull(selected, id)
      } else {
        selected.push(id)
      }

      return {
        ...state,
        selected,
      }
    }
    case MAIL_GET_FILES_INITIATED:
      return {
        ...state,
        fileIdToBeShown: action.fileIdToBeShown,
        files: action.files,
        filesMeta: action.meta,
        working: false,
      }
    case CHANGE_NOTES: {
      const newMailList = state.mailList.map(mail => {
        if (mail.uuid === action.uuid) {
          return { ...mail, notes: action.notes }
        }

        return mail
      })

      return {
        ...state,
        mailList: newMailList,
      }
    }
    case SET_FOLDER:
      return {
        ...state,
        work_as_email_client: action.work_as_email_client,
        currentFolder: { ...action.currentFolder, accountId: action.accountId },
      }
    case THREADS_ERROR:
      return {
        ...state,
        working: false,
      }
    default:
      return state
  }
}
