// @flow

import type { Node } from 'react'
import React from 'react'
import { useSelector } from 'react-redux'
import setFormikError from '../../../utils/setFormikError'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

import FieldRow from '../../FieldRow'

import styles from './ProfileInformation.module.scss'

import Button from '../../Button'
import {
  SALUTATION,
  FIRST_NAME,
  SECOND_NAME,
  FULL_NAME,
  MIDDLE_NAME,
  MAX_NAME_LENGTH,
  MAX_FULL_NAME_LENGTH,
  STATUS,
  PHONE,
  EMAIL,
  TITLE,
} from './ProfileInformationPartner.constants'
import * as Yup from 'yup'
import NewSelectAsync from '../../NewSelectAsync'
import PhoneInput from '../../forms/PhoneInput'
import { getSalutation } from '../../../core/api/api.pipeline'

import type { IPartner } from '../ProfileData'

type Props = {
  active: boolean,
  handleEditClose: () => void,
  initialValues: IPartner,
  isOpen: boolean,
  updateProfileInfo: (data: { partner: IPartner }) => Promise<IPartner>,
}

const PartnerForm = ({
  isOpen,
  initialValues,
  active,
  handleEditClose,
  updateProfileInfo,
}: Props): Node => {
  const { t } = useTranslation('Profile')
  const countryCode: string = useSelector(
    state => state.init.uk.country_obj.code
  )
  const onSubmit = values => {
    const partner: IPartner = {
      ...values,
      salutation: values.salutation?.id || null,
    }
    updateProfileInfo({ partner })
      .then(() => handleEditClose())
      .catch(error => setFormikError(error, setFieldError))
  }

  const onClosed = () => {
    handleEditClose()
    resetForm()
  }

  const {
    values,
    errors,
    dirty,
    isSubmitting,
    handleSubmit,
    handleChange,
    setFieldError,
    setFieldValue,
    resetForm,
  } = useFormik({
    validateOnChange: false,
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      [SALUTATION]: Yup.object({
        id: Yup.number().nullable(),
        value: Yup.string().nullable(),
      }).nullable(),
      [STATUS]: Yup.string().nullable(),
      [TITLE]: Yup.string().nullable(),
      [FIRST_NAME]: Yup.string().max(MAX_NAME_LENGTH, t('MaxInputLength')),
      [SECOND_NAME]: Yup.string().max(MAX_NAME_LENGTH, t('MaxInputLength')),
      [MIDDLE_NAME]: Yup.string().max(MAX_NAME_LENGTH, t('MaxInputLength')),
      [FULL_NAME]: Yup.string().max(MAX_FULL_NAME_LENGTH, t('MaxInputLength')),
      [EMAIL]: Yup.string().email(t('ValidEmail')),
    }),
    onSubmit,
  })

  const handleSelectSalutation = option => {
    setFieldValue(SALUTATION, option)
  }

  const handleSalutationClear = () => {
    setFieldValue(SALUTATION, null)
  }

  const handleChangePhone = value => {
    setFieldValue(PHONE, value !== '+' ? value : '')
  }

  return (
    <form
      name='partner_information'
      onSubmit={handleSubmit}
      onChange={handleChange}
    >
      <FieldRow
        value={values[SALUTATION]?.salutation}
        label={'Salutation'}
        error={errors[SALUTATION]}
        active={active}
        type='select'
        handleClear={handleSalutationClear}
      >
        <NewSelectAsync
          pageSize={10}
          permanentParams={{ object_type: 'user' }}
          name={SALUTATION}
          api={getSalutation}
          searchKey='search'
          placeholder={t('SelectSalutation')}
          view='dropdown'
          getLabel={a => a?.salutation}
          getSelectedLabel={a => a?.salutation}
          selectedItems={
            values[SALUTATION]?.salutation ? [values[SALUTATION]] : []
          }
          className={styles.select}
          onClick={handleSelectSalutation}
        />
      </FieldRow>
      {(values[TITLE] || isOpen) && (
        <FieldRow
          name={TITLE}
          active={active}
          label={'PartnerTitle'}
          error={errors[TITLE]}
          value={values[TITLE]}
          placeholder={t('EnterPartnerTitle')}
          handleChange={handleChange}
        />
      )}
      {(values[FIRST_NAME] || isOpen) && (
        <FieldRow
          name={FIRST_NAME}
          active={active}
          label={'FirstName'}
          error={errors[FIRST_NAME]}
          value={values[FIRST_NAME]}
          placeholder={t('EnterFirstName')}
          handleChange={handleChange}
        />
      )}
      {(values[MIDDLE_NAME] || isOpen) && (
        <FieldRow
          name={MIDDLE_NAME}
          active={active}
          label={'MiddleName'}
          error={errors[MIDDLE_NAME]}
          value={values[MIDDLE_NAME]}
          placeholder={t('EnterMiddleName')}
          handleChange={handleChange}
        />
      )}
      {(values[SECOND_NAME] || isOpen) && (
        <FieldRow
          name={SECOND_NAME}
          active={active}
          label={'SecondName'}
          error={errors[SECOND_NAME]}
          value={values[SECOND_NAME]}
          placeholder={t('EnterSecondName')}
          handleChange={handleChange}
        />
      )}
      {(values[FULL_NAME] || isOpen) && (
        <FieldRow
          name={FULL_NAME}
          active={active}
          label={'FullName'}
          error={errors[FULL_NAME]}
          value={values[FULL_NAME]}
          placeholder={t('EnterFullName')}
          handleChange={handleChange}
        />
      )}
      {(values[EMAIL] || isOpen) && (
        <FieldRow
          name={EMAIL}
          active={active}
          label={'Email'}
          error={errors[EMAIL]}
          value={values[EMAIL]}
          placeholder={t('EnterEmail')}
          handleChange={handleChange}
        />
      )}
      {(values[PHONE] || isOpen) && (
        <FieldRow
          value={values[PHONE]}
          label={'Phone'}
          error={errors[PHONE]}
          active={active}
        >
          <div className='input input--large input--block input--default'>
            <PhoneInput
              countryCode={countryCode}
              name={PHONE}
              error={errors[PHONE]}
              value={values[PHONE]}
              onChange={handleChangePhone}
            />
          </div>
        </FieldRow>
      )}
      {active && (
        <div className={styles.buttonWrapper}>
          <Button.Save disabled={!dirty || isSubmitting} type='submit'>
            {t('Common:Save')}
          </Button.Save>
          <Button.Cancel onClick={onClosed}>{t('Common:Cancel')}</Button.Cancel>
        </div>
      )}
    </form>
  )
}

export default PartnerForm
