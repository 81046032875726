// @flow

import React from 'react'
import parse, { domToReact, attributesToProps } from 'html-react-parser'
import type { Node } from 'react'

import MessageBlockquote from './MessageBlockquote'
import { BLOCKQUOTE_TYPE, TEXT_TYPE } from './Message.constants'
import { newLineToBr } from '../../../../utils/text'

export function getValidHTML(text: string): string {
  return text.substr(0, 5) === '<html' ? text : `<html>${text}</html>`
}

export function getHTMLDom(text: string): Object {
  const parser = new DOMParser()

  return parser.parseFromString(getValidHTML(text), 'text/html')
}

export function checkSiblings(blockQuotes: Object): boolean {
  const firstBlockquote = blockQuotes[0]

  let isSibling = true

  blockQuotes.forEach((blockquote, index) => {
    if (
      index !== 0 &&
      firstBlockquote.parentElement !== blockquote.parentElement
    ) {
      isSibling = false
    }
  })

  return isSibling
}

export function getFirstElement(firstBlockquote: Object): Object {
  let parentElement = firstBlockquote.parentElement

  if (
    parentElement.classList.contains('gmail_quote') ||
    parentElement.classList.contains('protonmail_quote')
  ) {
    parentElement = parentElement.parentElement
  }

  return parentElement.firstElementChild
}

export function hasOpenTag(
  index: number,
  currentElementType: ?string,
  prevElementType: ?string
): boolean {
  return (
    (index === 0 && prevElementType === BLOCKQUOTE_TYPE) ||
    (prevElementType === TEXT_TYPE && currentElementType === BLOCKQUOTE_TYPE)
  )
}

export function hasCloseTag(index: number, prevElementType: ?string): boolean {
  return index > 0 && prevElementType === BLOCKQUOTE_TYPE
}

export function getChunks(
  firstBlockquote: Object,
  parentNode: Object
): Array<Object> {
  let index = 0
  let chunks = []
  let prevElementType = null

  function addChunk(element) {
    let currentElementType = TEXT_TYPE

    if (
      element.nodeName === 'BLOCKQUOTE' ||
      element.classList.contains('gmail_quote') ||
      element.classList.contains('protonmail_quote')
    ) {
      currentElementType = BLOCKQUOTE_TYPE
    }

    if (!prevElementType) {
      prevElementType = currentElementType
    }

    if (currentElementType !== prevElementType) {
      ++index
    }

    let blocksQuote = element.querySelectorAll('blockquote')
    let isContainsQuote = blocksQuote && blocksQuote.length

    if (chunks[index]) {
      const content =
        currentElementType === TEXT_TYPE
          ? element.outerHTML
          : `<br>${element.innerHTML}`

      if (!isContainsQuote) {
        chunks[index].content += content
      } else {
        chunks[index].content += `<br>`
      }
    } else {
      let content = ''

      if (hasCloseTag(index, prevElementType)) {
        chunks[index - 1].content += '</blockquote>'
      }

      if (hasOpenTag(index, currentElementType, prevElementType)) {
        content = `<blockquote>${element.innerHTML}`
      } else {
        content = element.outerHTML
      }

      chunks[index] = { type: currentElementType, content }
    }

    if (isContainsQuote) {
      addChunk(element.firstElementChild)
    }

    if (element.nextElementSibling) {
      prevElementType = currentElementType

      addChunk(element.nextElementSibling)
    } else if (currentElementType === BLOCKQUOTE_TYPE) {
      chunks[index].content += '</blockquote>'
    }
  }

  if (
    parentNode.firstElementChild &&
    parentNode.firstElementChild?.nodeName === 'DIV' &&
    !parentNode.firstElementChild.nextElementSibling &&
    parentNode.firstElementChild.firstElementChild
  ) {
    addChunk(parentNode.firstElementChild.firstElementChild)
  } else {
    addChunk(
      parentNode.firstElementChild ? parentNode.firstElementChild : parentNode
    )
  }

  return chunks
}

export function isEmptyText(domNode: Object): boolean {
  const regexp = /^\s*\n\s*$/

  return domNode.type === 'text' && regexp.test(domNode.data)
}

export function getReactComponentsTree(htmlDom: Object): Node {
  let innerHtml = ''
  const body = htmlDom.querySelector('body')

  if (body) {
    innerHtml = body.innerHTML
  } else {
    innerHtml = htmlDom.innerHTML
  }

  const options = {
    replace: domNode => {
      if (isEmptyText(domNode)) {
        return <></>
      }

      if (domNode.name === 'blockquote') {
        const props = attributesToProps(domNode.attribs)

        return (
          <MessageBlockquote>
            <blockquote {...props}>
              {domToReact(domNode.children, options)}
            </blockquote>
          </MessageBlockquote>
        )
      }
    },
  }

  return parse(
    `<div class='ql-editor message-text'>${innerHtml}</div>`,
    options
  )
}

export function getMessageText(
  text?: string
): Node | string | Array<Object> | void {
  if (!text) {
    return text
  }

  const formattedText = newLineToBr(text)

  const htmlDom = getHTMLDom(formattedText)
  const blockQuotes = htmlDom.querySelectorAll('blockquote')
  const allLinks = htmlDom.querySelectorAll('a')

  for (const link of allLinks) {
    link.setAttribute('target', '_blank')
  }

  if (!blockQuotes || !blockQuotes.length) {
    return formattedText
  }

  const isOnlySiblings = checkSiblings(blockQuotes)

  if (isOnlySiblings && blockQuotes.length > 1) {
    return getChunks(
      blockQuotes[0],
      htmlDom.querySelector('body').firstElementChild?.nodeName === 'DIV'
        ? htmlDom.querySelector('body').firstElementChild
        : htmlDom.querySelector('body')
    )
  } else {
    return getReactComponentsTree(htmlDom)
  }
}

export function getMessageWithLinks(text: string): string {
  const parser = new DOMParser()
  const textParse = parser.parseFromString(text, 'text/html')
  const allLinks = textParse.querySelectorAll('a')

  for (const link of allLinks) {
    link.setAttribute('target', '_blank')
    link.style.color = '#0000EE'
  }

  return textParse.documentElement?.innerHTML || text
}
