// @flow

import React, { Component } from 'react'
import { push } from 'connected-react-router'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'
import classnames from 'classnames'
import { omitBy, isNil, toString } from 'lodash-es'

import Checkbox from '../../Checkbox'
import { Header, SimpleBlock } from '../../Block'
import AddFlatPopup from '../../modals/AddFlatPopup'
import Button from '../../Button'
import EmptyList from '../../EmptyList'
import Loader from '../../Loader'
import Nbsp from '../../NBSP'
import FlatUsers from '../../TableCellUsers'
import TopPagination from '../../Pagination/TopPagination'
import BottomPagination from '../../Pagination/BottomPagination'
import ConfirmationPopup from '../../modals/ConfirmationPopup'
import Modal from '../../Modal'
import NumberCell from './NumberCell'
import Table from '../../Table'
import * as actions from './FlatList.actionTypes'
import addRedirectToFirstPage from '../../../hocs/addRedirectToFirstPage'
import { getLocation } from '../../../utils/commonSelectors'
import { getFormattedDate, cutText } from '../../../utils/utils'
import { getCurrentFilters, getCurrentPage } from '../../../utils/routing'

type Props = {
  dispatch: Object => void,
  flats: Array<Object>,
  handleSort: () => void,
  loaded: boolean,
  loading: boolean,
  location: Object,
  meta: Object,
  modal: any,
  noMargin: boolean,
  owner: number,
  permissions: Object,
  selected: Array<number>,
  t: string => string,
}

class FlatList extends Component<Props> {
  componentDidMount() {
    const { owner, location, buildingGroup, match } = this.props

    const page = getCurrentPage(location)
    const filters = getCurrentFilters(location)
    const search = match?.params?.search

    if (filters.page) {
      this.props.dispatch(
        omitBy(
          {
            type: actions.FLAT_LIST_LOADING,
            params: {
              owner,
              buildingGroup,
              page,
              search: search ? decodeURIComponent(search) : undefined,
              ...filters,
            },
          },
          isNil
        )
      )
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { location, match } = this.props
    const search = match?.params?.search
    const prevSearch = prevProps.match?.params?.search

    if (
      location.search !== prevProps.location.search ||
      (search && search !== prevSearch)
    ) {
      const page = getCurrentPage(location)

      this.load(page, this.props)
    }
  }

  componentWillUnmount() {
    this.props.dispatch({ type: actions.FLAT_LIST_RESET })
  }

  open = id => {
    this.props.dispatch(push(`/flat/${id}/`))
  }

  load = (page = null, props) => {
    const {
      meta: { curr_page: currPage },
      owner,
      buildingGroup,
      match,
    } = props
    const search = match?.params?.search
    const params = {
      owner,
      buildingGroup,
      search: search ? decodeURIComponent(search) : undefined,
      page: page || currPage,
      ...getCurrentFilters(props.location),
    }

    this.props.dispatch({
      type: actions.FLAT_LIST_LOADING,
      params: omitBy(params, isNil),
    })
  }

  toggle = e => {
    this.props.dispatch({
      type: actions.FLAT_LIST_TOGGLE,
      id: parseInt(e.target.value, 10),
    })
  }

  toggleAll = () => {
    this.props.dispatch({ type: actions.FLAT_LIST_TOGGLE_ALL })
  }

  remove = () => {
    const {
      selected,
      meta,
      meta: { curr_page: page },
      owner,
      location,
    } = this.props

    const params = {
      owner,
      page,
      ...getCurrentFilters(location),
    }

    this.props.dispatch({
      type: actions.FLAT_LIST_REMOVING,
      ids: selected,
      meta,
      params,
    })
  }

  restore = () => {
    const {
      selected,
      meta,
      meta: { curr_page: page },
      owner,
      location,
    } = this.props

    const params = {
      owner,
      page,
      ...getCurrentFilters(location),
    }

    this.props.dispatch({
      type: actions.FLAT_LIST_RESTORE,
      ids: selected,
      params,
      meta,
    })
  }

  showRemoveModal = () => {
    const { selected } = this.props

    if (selected.length) {
      this.props.dispatch({
        type: actions.FLAT_LIST_SHOW_MODAL,
        modal: (
          <ConfirmationPopup
            title={this.props.t('FlatDeleteTitle')}
            text={this.props.t('Common:delete_flat_warning_text')}
            confirm={this.props.t('Common:Delete')}
            onClose={this.hideModal}
            onOk={this.remove}
          />
        ),
      })
    }
  }

  showRestoreModal = () => {
    const { selected } = this.props

    if (selected.length) {
      this.props.dispatch({
        type: actions.FLAT_LIST_SHOW_MODAL,
        modal: (
          <ConfirmationPopup
            title={this.props.t('FlatRestoreTitle')}
            text={this.props.t('RestoreWarningText')}
            confirm={this.props.t('Common:Restore')}
            onClose={this.hideModal}
            onOk={this.restore}
          />
        ),
      })
    }
  }

  showCreateModal = () => {
    this.props.dispatch({
      type: actions.FLAT_LIST_SHOW_MODAL,
      modal: (
        <AddFlatPopup
          params={getCurrentFilters(this.props.location)}
          onHide={this.hideModal}
        />
      ),
    })
  }

  hideModal = () => {
    this.props.dispatch({ type: actions.FLAT_LIST_HIDE_MODAL })
  }

  showToolbox = () => {
    const {
      permissions: {
        can_create: canCreate,
        can_delete: canDelete,
        can_restore: canRestore,
      },
      meta,
    } = this.props

    return canCreate || canDelete || canRestore || meta.count > meta.limit
  }

  renderTable = () => {
    const { flats, loading, owner, buildingGroup, match } = this.props
    const search = match?.params?.search
    const tableClass = classnames('table__wrapper table--flats__list', {
      'table--flats__list-short': owner,
    })

    return (
      <div className='table table--default'>
        <div className='table__outer-wrapper'>
          <div className={tableClass}>
            <div className='table__head'>
              <Table.HeaderCell
                title={this.props.t('FlatNumber')}
                sortKey={search ? null : 'number'}
                working={loading}
              />
              {!buildingGroup && (
                <Table.HeaderCell
                  title={this.props.t('FlatSquare')}
                  sortKey={search ? null : 'area'}
                  working={loading}
                />
              )}
              {!owner && (
                <>
                  <Table.HeaderCell
                    title={this.props.t('FlatOwners')}
                    working={loading}
                    style={{ width: '20%' }}
                  />
                  <Table.HeaderCell
                    title={this.props.t('FlatDwellers')}
                    working={loading}
                    style={{ width: '20%' }}
                  />
                </>
              )}
              <Table.HeaderCell
                title={
                  owner
                    ? this.props.t('FlatAddress')
                    : this.props.t('FlatBuilding')
                }
                sortKey={search ? null : 'building__address__value'}
                working={loading}
                style={{ width: '25%' }}
              />
              <Table.HeaderCell
                title={this.props.t('Updated')}
                sortKey={search ? null : 'updated'}
                working={loading}
              />
            </div>
            {!loading && flats.map(this.renderFlat)}
          </div>
        </div>
        {loading && <Loader type='medium' text={false} />}
      </div>
    )
  }

  renderFilterNoResults = () => {
    const { match } = this.props
    const search = match?.params?.search
    const title = search ? 'NoFlatsFound' : 'ThereAreNoFlatsThatMatchTheFilter'

    return <EmptyList icon='apartment' title={this.props.t(title)} />
  }

  renderFlat = flat => {
    const {
      selected,
      owner,
      buildingGroup,
      permissions: { can_delete: canDelete, can_restore: canRestore },
    } = this.props

    const { id, area } = flat

    const checked = selected.includes(id)
    const itemClass = classnames('table__row table__row-homes', {
      'table__row--attention': checked,
    })

    const formattedArea = area ? toString(area).replace('.', ',') : ''

    return (
      <div className={itemClass} key={`flat-${id}`}>
        <NumberCell
          canToggle={!buildingGroup && !owner && (canDelete || canRestore)}
          checked={checked}
          flat={flat}
          toggle={this.toggle}
        />
        {!buildingGroup && (
          <div className='table__cell' onClick={() => this.open(id)}>
            <div className='table__cell-wrapper'>
              <div
                className='table-item table-item__text'
                title={formattedArea}
              >
                <span>{cutText(formattedArea, 9)}</span>
              </div>
            </div>
          </div>
        )}
        {!owner && (
          <>
            <div className='table__cell' onClick={() => this.open(id)}>
              <div className='table__cell-wrapper'>
                <FlatUsers
                  count={flat.landlords?.length}
                  items={flat.landlords}
                />
              </div>
            </div>
            <div className='table__cell' onClick={() => this.open(id)}>
              <div className='table__cell-wrapper'>
                <FlatUsers
                  count={flat.dwellers?.length}
                  items={flat.dwellers}
                />
              </div>
            </div>
          </>
        )}
        <div className='table__cell' onClick={() => this.open(id)}>
          <div className='table__cell-wrapper'>
            <div
              className='table-item table-item__text'
              title={flat.address_obj.value}
              style={owner ? { overflow: 'hidden' } : {}}
            >
              <span>{flat.address_obj.value}</span>
            </div>
          </div>
        </div>
        <div
          className='table__cell table__cell--text-right'
          onClick={() => this.open(id)}
        >
          <div className='table__cell-wrapper'>
            <div className='table-item table-item__text'>
              <span>{getFormattedDate(flat.updated)}</span>
            </div>
            <div
              className='table-extra'
              style={{
                height: '100%',
                background: 'none',
              }}
            >
              <Button.Save icon='eye'>{this.props.t('View')}</Button.Save>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderEmpty = () => {
    const { permissions, match } = this.props
    const search = match?.params?.search
    const title = search ? 'NoFlatsFound' : 'FlatsEmptyLabel'
    const canAdd = !search && permissions.can_create

    return (
      <EmptyList
        btnText={this.props.t(search ? '' : 'FlatsEmptyButton')}
        canAdd={canAdd}
        icon='apartment'
        title={this.props.t(title)}
        onClick={this.showCreateModal}
      />
    )
  }

  renderFlatsTable() {
    const {
      permissions: {
        can_delete: canDelete,
        can_create: canCreate,
        can_restore: canRestore,
      },
      flats,
      meta,
      selected,
      loading,
      owner,
      buildingGroup,
      location,
    } = this.props
    const filters = getCurrentFilters(location)
    const isFilterApplied = Object.keys(filters).length > 0

    return (
      <div>
        {this.showToolbox() && !buildingGroup && !owner && (
          <div className='toolbox'>
            {(canDelete || canRestore) && (
              <div className='toolbox__cell'>
                <Checkbox
                  outer
                  checked={!!flats.length && selected.length === flats.length}
                  onChange={this.toggleAll}
                />
              </div>
            )}
            {canCreate && selected.length === 0 && (
              <div className='toolbox__cell'>
                <Button.Save icon='apartment' onClick={this.showCreateModal}>
                  {this.props.t('AddBuildingFlats')}
                </Button.Save>
              </div>
            )}
            {canDelete && selected.length > 0 && (
              <div className='toolbox__cell'>
                <Button.Remove onClick={this.showRemoveModal} />
              </div>
            )}
            {canRestore && (
              <div className='toolbox__cell'>
                <Button.Cancel
                  disabled={!selected.length}
                  icon='restore'
                  onClick={this.showRestoreModal}
                >
                  {this.props.t('Common:Restore')}
                </Button.Cancel>
              </div>
            )}
            {!loading && (
              <div className='toolbox__cell toolbox__cell--right'>
                <TopPagination
                  classes='pager pager--large pager--default-2'
                  meta={meta}
                />
              </div>
            )}
          </div>
        )}
        {this.renderTable()}
        {isFilterApplied && flats.length === 0 && this.renderFilterNoResults()}
        {!loading && (
          <BottomPagination classes='paginator paginator--middle' meta={meta} />
        )}
      </div>
    )
  }

  render() {
    const { loading, loaded } = this.props

    if (loading) {
      return <Loader text={false} type='big' />
    }

    if (!loaded) {
      return null
    }

    const { flats, meta, modal, noMargin, location, match } = this.props
    const sectionClass = classnames('unit unit--default', {
      'unit--nomargin': noMargin,
    })
    const filters = getCurrentFilters(location)
    const search = match?.params?.search

    return (
      <div className='content__col'>
        <SimpleBlock className={sectionClass}>
          <Header header>
            {this.props.t(search ? 'SearchResults' : 'Flats')}
            {search ? `: ${decodeURIComponent(search)}` : ''}
            <span className='unit__title-num'>
              <Nbsp />
              {!!meta.count && meta.count}
            </span>
          </Header>

          {flats.length || Object.keys(filters).length
            ? this.renderFlatsTable()
            : this.renderEmpty()}
          <Modal
            contentLabel=''
            className='Modal__Bootstrap modal-dialog'
            isOpen={!!modal}
            onRequestClose={this.hideModal}
          >
            {modal}
          </Modal>
        </SimpleBlock>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state.flatList,
  location: getLocation(state),
})

export default compose(
  withTranslation('Building'),
  connect(mapStateToProps),
  addRedirectToFirstPage
)(FlatList)
