// @flow

import React from 'react'
import { useSelector } from 'react-redux'

import { getThemeColor } from '../../utils/commonSelectors'

import styles from './ShowAllButton.module.scss'

type Props = {
  count: number,
  onClick: (SyntheticEvent<HTMLButtonElement>) => void,
  text: string,
}

const ShowAllButton = (props: Props) => {
  const { count, text } = props

  const themeColor = useSelector(getThemeColor)

  return (
    <button
      type='button'
      className={styles.button}
      style={{ '--themeColor': themeColor }}
      onClick={props.onClick}
    >
      <span>{`${text} (${count})`}</span>
    </button>
  )
}

export default ShowAllButton
