// @flow

import React, { useState, useEffect } from 'react'
import type { Node } from 'react'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useSelected } from '../../hooks'

import { getAutomationFields } from '../../core/api/api.automate'
import {
  getActivityTypes,
  getActivityPriorities,
  getAllPhaseTemplates,
} from '../../core/api/api.pipeline'
import { getAdminAndManagerOptionList } from '../../core/api/api.profile'
import { getTypes } from '../../core/api/api.request'
import { getLabels } from '../../core/api/api.label'
import { getBuildingOptions, getLivingType } from '../../core/api/api.building'

import { getUserName, getFetchedItems } from '../../utils/utils'
import { getPriorityValue } from '../Activities/ActivityModal/ActivityModal.utils'

import NewSelectSimple from '../NewSelectSimple'
import NewSelectAsync from '../NewSelectAsync'
import InputField from '../InputField'
import Button from '../Button'

import ActivityModalPriority from '../Activities/ActivityModal/ActivityModalPriority'

import styles from './AutomateInfo.module.scss'

import Warning from '../Warning'

import { STATUSES_COLORS } from '../../ui/MainColors.constants'

type Props = {
  canEdit: boolean,
  condition: Object,
  onChange: Function,
  onRemove: number => void,
  showWarning: boolean,
}

const ConditionField = (props: Props): Node => {
  const { condition, showWarning, canEdit } = props
  const { t } = useTranslation('Automate')
  const [fields, setFields] = useState([])
  const [valueOption, setValueOption] = useState(null)
  const [activityPriorities, setActivityPriorities] = useState([])
  const [priorityLoading, setPriorityLoading] = useState(false)
  const requestStatuses = useSelector(state => state.init.request_status)
  const statusOptions = requestStatuses.map(status => ({
    value: status.id,
    label: t(`Request:${status.title}`),
    style: { color: STATUSES_COLORS[status.css] },
  }))

  const [selectedLabels, changeSelected, setSelected] = useSelected([])
  const [labelCount, setLabelCount] = useState(null)

  useEffect(() => {
    getAutomationFields().then(data => setFields(data.fields))

    let optionsApi = null

    switch (condition.field) {
      case 'activity_type':
        optionsApi = getActivityTypes
        break
      case 'activity_manager':
        optionsApi = getAdminAndManagerOptionList
        break
      case 'activity_phase_template':
        optionsApi = getAllPhaseTemplates
        break
      case 'request_category':
        optionsApi = getTypes
        break
      case 'request_building':
        optionsApi = getBuildingOptions
        break
      case 'building_living_type':
        optionsApi = getLivingType
    }

    if (
      [
        'activity_type',
        'activity_manager',
        'activity_phase_template',
        'request_category',
        'request_building',
        'building_living_type',
      ].includes(condition.field) &&
      condition.operator !== 'contains'
    ) {
      optionsApi({ id: condition.value }).then(data =>
        setValueOption(data.results.objects[0])
      )
    }

    if (condition.field === 'request_status') {
      setValueOption(statusOptions.find(s => s.value === condition.value))
    }

    if (condition.field === 'request_labels') {
      getLabels({ page_size: 100500 }).then(data => {
        const labels = data.results.objects
        setSelected(labels.filter(l => condition.value.includes(l.id)))
      })
    }
  }, [])

  useEffect(() => {
    if (condition.field === 'request_labels') {
      props.onChange(
        'value',
        selectedLabels.map(l => l.id)
      )
    }
  }, [selectedLabels])

  const getFieldsOptions = () =>
    fields.map(field => ({
      value: field.name,
      label: t(field.name),
    }))

  const getOperatorOptions = () => {
    const fieldObj = fields.find(f => f.name === condition.field)

    if (fieldObj) {
      return fieldObj.operators.map(o => ({
        value: o.name,
        label: t(o.name),
      }))
    }

    return []
  }

  const renderField = () => {
    let operator = null
    const fieldObj = fields.find(f => f.name === condition.field)

    if (fieldObj) {
      operator = fieldObj.operators.find(o => o.name === condition.operator)
    }

    let fieldComponent = null

    switch (operator?.input_type) {
      case 'str':
        fieldComponent = (
          <InputField
            name={'field_value'}
            value={condition.value}
            placeholder={t('Value')}
            maxLength={operator.validation?.max}
            disabled={!canEdit}
            onChange={e => props.onChange('value', e.target.value)}
          />
        )
        break
      case 'select': {
        const options = operator.options.map(({ value, label }) => ({
          value,
          label: t(label),
        }))
        fieldComponent = (
          <NewSelectSimple
            className={styles.selectValue}
            placeholder={t('SelectField')}
            options={options}
            value={options.find(o => o.value === condition.value)}
            disabled={!canEdit}
            onChange={option => props.onChange('value', option.value)}
          />
        )
        break
      }
      case 'activity_type':
        fieldComponent = (
          <NewSelectAsync
            pageSize={10}
            className={styles.selectValue}
            api={getActivityTypes}
            searchKey='name'
            placeholder={t('Value')}
            getLabel={a => a.name}
            getSelectedLabel={a => a.name}
            view='dropdown'
            selectedItems={valueOption ? [valueOption] : []}
            disabled={!canEdit}
            onClick={option => {
              props.onChange('value', option.id)
              setValueOption(option)
            }}
          />
        )
        break
      case 'staff_member':
        fieldComponent = (
          <NewSelectAsync
            pageSize={10}
            className={styles.selectValue}
            api={getAdminAndManagerOptionList}
            searchKey='fullname'
            placeholder={t('Value')}
            getLabel={getUserName}
            getSelectedLabel={getUserName}
            view='dropdown'
            selectedItems={valueOption ? [valueOption] : []}
            disabled={!canEdit}
            onClick={option => {
              props.onChange('value', option.id)
              setValueOption(option)
            }}
          />
        )
        break
      case 'activity_priority':
        {
          if (activityPriorities.length === 0 && !priorityLoading) {
            setPriorityLoading(true)
            getActivityPriorities().then(res => {
              const priorities = getFetchedItems(res)
              setActivityPriorities(priorities)

              if (condition.value) {
                setValueOption(
                  getPriorityValue(
                    priorities.find(p => p.id === condition.value)
                  )
                )
              }

              setPriorityLoading(false)
            })
          }

          fieldComponent = (
            <ActivityModalPriority
              placeholder={t('PriorityPlaceholder')}
              className={styles.selectValue}
              options={activityPriorities.map(getPriorityValue)}
              value={valueOption}
              disabled={!canEdit || !valueOption}
              onToggle={() => {}}
              onChange={option => {
                props.onChange('value', option.value)
                setValueOption(option)
              }}
              onClear={() => {
                props.onChange('value', null)
                setValueOption(null)
              }}
            />
          )
        }
        break
      case 'phase_template':
        fieldComponent = (
          <NewSelectAsync
            pageSize={10}
            className={styles.selectValue}
            api={getAllPhaseTemplates}
            searchKey='search'
            placeholder={t('Value')}
            getLabel={p => p.name}
            getSelectedLabel={p => p.name}
            getDescription={p => p.pipeline_template?.name || ''}
            view='dropdown'
            selectedItems={valueOption ? [valueOption] : []}
            disabled={!canEdit}
            onClick={option => {
              props.onChange('value', option.id)
              setValueOption(option)
            }}
          />
        )
        break
      case 'request_category':
        fieldComponent = (
          <NewSelectAsync
            pageSize={10}
            className={styles.selectValue}
            api={getTypes}
            searchKey='name'
            placeholder={t('Value')}
            getLabel={c => c.name}
            getSelectedLabel={c => c.name}
            view='dropdown'
            selectedItems={valueOption ? [valueOption] : []}
            disabled={!canEdit}
            onClick={option => {
              props.onChange('value', option.id)
              setValueOption(option)
            }}
          />
        )
        break
      case 'request_status':
        fieldComponent = (
          <NewSelectSimple
            className={styles.selectValue}
            options={statusOptions}
            value={valueOption}
            selectedItems={valueOption ? [valueOption] : []}
            onChange={status => {
              props.onChange('value', status.value)
              setValueOption(status)
            }}
          />
        )
        break
      case 'labels':
        fieldComponent = (
          <NewSelectAsync
            isMulti
            noAll
            view='dropdown'
            pageSize={10}
            api={getLabels}
            placeholder={t('Value')}
            getLabel={l => l.name}
            getSelectedLabel={l => l.name}
            selectedItems={selectedLabels}
            setSelectedItems={setSelected}
            isAllSelected={selected => labelCount === selected.length}
            setMetaCount={setLabelCount}
            searchKey='search'
            className={styles.selectValue}
            minSearchLength={1}
            onClick={item => changeSelected(item)}
          />
        )
        break
      case 'building':
        fieldComponent = (
          <NewSelectAsync
            pageSize={10}
            className={styles.selectValue}
            api={getBuildingOptions}
            searchKey='search'
            placeholder={t('Value')}
            getLabel={c => c.address}
            getSelectedLabel={c => c.address}
            view='dropdown'
            selectedItems={valueOption ? [valueOption] : []}
            disabled={!canEdit}
            onClick={option => {
              props.onChange('value', option.id)
              setValueOption(option)
            }}
          />
        )
        break
      case 'living_type':
        fieldComponent = (
          <NewSelectAsync
            pageSize={10}
            className={styles.selectValue}
            api={getLivingType}
            searchKey='search'
            placeholder={t('Value')}
            getLabel={c => c.value}
            getSelectedLabel={c => c.value}
            view='dropdown'
            selectedItems={valueOption ? [valueOption] : []}
            disabled={!canEdit}
            onClick={option => {
              props.onChange('value', option.id)
              setValueOption(option)
            }}
          />
        )
        break
      default:
        fieldComponent = null
    }

    return fieldComponent
  }

  return (
    <>
      {showWarning && (
        <Warning
          noArrow
          text={t('EmptyConditionWarning')}
          boxClassName={styles.warningBoxCondition}
          className={styles.warning}
        />
      )}
      <div>{t('Field')}</div>
      <div className={styles.conditionSelects}>
        <NewSelectSimple
          className={styles.selectField}
          placeholder={t('SelectField')}
          options={getFieldsOptions()}
          value={getFieldsOptions().find(f => f.value === condition.field)}
          disabled={!canEdit}
          onChange={option => {
            props.onChange('field', option.value)
            setValueOption(null)
            setSelected([])
          }}
        />
        <NewSelectSimple
          className={styles.selectOperator}
          placeholder={t('SelectConditionOperator')}
          options={getOperatorOptions()}
          value={getOperatorOptions().find(o => o.value === condition.operator)}
          onChange={option => props.onChange('operator', option.value)}
        />
        <Button.Delete
          disabled={!canEdit || showWarning}
          onClick={() => props.onRemove()}
        />
      </div>
      {condition.field && condition.operator && renderField()}
    </>
  )
}

export default ConditionField
