// @flow

import React from 'react'
import RangeCalendar from 'rc-calendar/lib/RangeCalendar'
import 'rc-calendar/assets/index.css'
import moment from 'moment'
import { isUndefined } from 'lodash-es'
import classnames from 'classnames'

import CalendarPart from './CalendarPart'

function onValueChange(direction, current) {
  this.fireValueChange(current, direction)
}

function getNow() {
  return moment()
}

function onInputSelect(direction, value) {
  if (!value) {
    return
  }

  const originalValue = this.state.selectedValue
  const selectedValue = originalValue.concat()
  const index = direction === 'left' ? 0 : 1
  selectedValue[index] = value

  if (
    selectedValue[0] &&
    this.compare(selectedValue[0], selectedValue[1]) > 0
  ) {
    selectedValue[1 - index] = this.state.showTimePicker
      ? selectedValue[index]
      : undefined
  }

  this.fireSelectValueChange(selectedValue)
}

function isEmptyArray(arr) {
  return Array.isArray(arr) && (arr.length === 0 || arr.every(i => !i))
}

function getValueFromSelectedValue(selectedValue) {
  const [start, end] = selectedValue
  const newEnd =
    end && end.isSame(start, 'month') ? end.clone().add(1, 'month') : end

  return [start, newEnd]
}

function normalizeAnchor(props, init) {
  const selectedValue =
    props.selectedValue || (init && props.defaultSelectedValue)
  const value = props.value || (init && props.defaultValue)
  const normalizedValue = value || getValueFromSelectedValue(selectedValue)

  return !isEmptyArray(normalizedValue)
    ? normalizedValue
    : init && [getNow(), getNow().add(1, 'months')]
}

class ReactCalendar extends RangeCalendar {
  componentDidMount() {
    this.checkAndSet()
  }

  componentDidUpdate(prevProps) {
    const newState = {}

    if ('value' in this.props && this.props.value !== prevProps.value) {
      if (this.props.value) {
        newState.value = this.props.value
      } else {
        newState.value = normalizeAnchor(this.props, 0)
      }

      if (prevProps.single) {
        newState.left = this.props.value
        newState.selectedValue = [this.props.value]
        this.setState(newState)
      } else {
        this.setState(newState)
      }
    }

    if (
      'selectedValue' in this.props &&
      this.props.selectedValue !== prevProps.selectedValue
    ) {
      newState.selectedValue = this.props.selectedValue
      this.setState(newState)
    }
  }

  root = React.createRef()

  checkAndSet = () => {
    if (this.props.single) {
      this.setState({
        left: moment(this.props.value),
        selectedValue: [moment(this.props.value)],
      })
    } else if (
      !isUndefined(this.props.filter[0]) ||
      !isUndefined(this.props.filter[1])
    ) {
      this.setState({
        selectedValue: [
          moment.utc(this.props.filter[0]).local(),
          moment.utc(this.props.filter[1]).local(),
        ],
      })
    }
  }

  getStartValue = () => (this.state.left ? this.state.left : moment())

  getEndValue = () =>
    this.state.right ? this.state.right : moment().add(1, 'months')

  onEndValueChange = (...oargs) => {
    const args = ['right'].concat(oargs)

    return onValueChange.apply(this, args)
  }

  onStartValueChange = (...oargs) => {
    const args = ['left'].concat(oargs)

    return onValueChange.apply(this, args)
  }

  fireValueChange = (value, direction) => {
    const props = this.props

    if (!('value' in props)) {
      if (direction === 'right') {
        this.setState({
          right: value,
        })
      } else {
        this.setState({
          left: value,
        })
      }
    }

    props.onValueChange(value)
  }

  onStartInputSelect = (...oargs) => {
    const args = ['left'].concat(oargs)

    return onInputSelect.apply(this, args)
  }

  render() {
    const props = this.props
    const state = this.state
    const { dateInputPlaceholder, type, single } = props
    const { hoverValue, selectedValue } = state
    const newProps = {
      selectedValue: state.selectedValue,
      onSelect: this.onSelect,
      onDayHover:
        (type === 'start' && selectedValue[1]) ||
        (type === 'end' && selectedValue[0]) ||
        !!hoverValue.length
          ? this.onDayHover
          : undefined,
    }

    let placeholder1
    let placeholder2

    if (dateInputPlaceholder) {
      if (Array.isArray(dateInputPlaceholder)) {
        ;[placeholder1, placeholder2] = dateInputPlaceholder
      } else {
        placeholder1 = dateInputPlaceholder
        placeholder2 = dateInputPlaceholder
      }
    }

    const startValue = this.getStartValue()
    const endValue = this.getEndValue()
    const css = classnames({
      'calendar--multiple': !single,
      [`${props.styleName}`]: !!props.styleName,
    })

    return (
      <div className={css} ref={this.root} style={props.style} tabIndex='0'>
        <div
          className='calendar calendar--default'
          onMouseLeave={type !== 'both' ? this.onDatePanelLeave : undefined}
          onMouseEnter={type !== 'both' ? this.onDatePanelEnter : undefined}
        >
          <CalendarPart
            {...props}
            {...newProps}
            hoverValue={hoverValue}
            direction='left'
            minYear={this.props.minYear}
            maxYear={this.props.maxYear}
            format={this.getFormat()}
            value={startValue}
            placeholder={placeholder1}
            onInputSelect={this.onStartInputSelect}
            onValueChange={this.onStartValueChange}
          />
          {single ? (
            ''
          ) : (
            <CalendarPart
              {...props}
              {...newProps}
              hoverValue={hoverValue}
              direction='right'
              format={this.getFormat()}
              timePickerDisabledTime={this.getEndDisableTime()}
              placeholder={placeholder2}
              value={endValue}
              onInputSelect={this.onEndInputSelect}
              onValueChange={this.onEndValueChange}
            />
          )}
        </div>
      </div>
    )
  }
}

export default ReactCalendar
