// @flow

import React, { Component } from 'react'
import OutsideClick from 'react-onclickout'
import { withTranslation } from 'react-i18next'
import { isEmpty, pickBy } from 'lodash-es'

import { getSnippetVariables } from '../../../core/api/api.requestConstructor'

import SelectBlock from '../SelectBlock'
import SelectVariable from '../SelectVariable'
import TemplateVariables from '../../Templates/TemplatePopup/TemplateVariables'
import Radio from '../../Radio'

import {
  EQUAL,
  CONTAIN,
  NOT_CONTAIN,
  NOT_EMPTY,
  VariableTypes,
} from '../constants'
import NewSelectSimple from '../../NewSelectSimple'

type State = {
  condition: number,
  destination: ?number,
  validation: Object,
  value: ?string,
  variable: ?number,
}
type Props = {
  active: boolean,
  canSaveOnUnmount: () => boolean,
  data: Object,
  error: boolean,
  onClickOut: () => void,
  onUpdate: Object => void,
  onWasRemoved: number => void,
  removingId: ?number,
  t: string => string,
  updatingId: ?number,
  variables: Array<Object>,
}

class Filter extends Component<Props, State> {
  state = {
    condition: this.props.data.condition,
    destination: this.props.data.destination,
    validation: {
      variable: true,
      condition: true,
      destination: true,
    },
    value: this.props.data.value,
    typeVariable: this.props.data.text_variable ? 'template' : 'system',
    variable: this.props.data.variable,
    text_variable: this.props.data.text_variable,
  }

  tags = ['request_comment', 'chat']

  componentDidUpdate(prevProps: Props) {
    const { active, updatingId, removingId } = prevProps

    if (active && !this.props.active) {
      this.update()

      return
    }

    if (this.props.error) {
      return
    }

    if (
      (updatingId && !this.props.updatingId) ||
      (removingId && !this.props.removingId)
    ) {
      const { variable } = this.state

      if (variable && !this.props.variables.find(v => v.name === variable)) {
        const { data } = prevProps

        this.props.onWasRemoved(data.id)
      }
    }
  }

  componentWillUnmount() {
    if (this.props.canSaveOnUnmount()) {
      this.update()
    }
  }

  isValid = key =>
    this.state.validation[key] ||
    this.props.updatingId === this.props.data.id ||
    this.props.removingId === this.props.data.id

  compareOptions = [
    { value: EQUAL, label: this.props.t('equal') },
    { value: CONTAIN, label: this.props.t('contain') },
    { value: NOT_CONTAIN, label: this.props.t('notContain') },
    { value: NOT_EMPTY, label: this.props.t('notEmpty') },
  ]

  handleChangeOption = key => option => {
    this.setState(state => ({
      [key]: option.value ? option.value : option,
      validation: { ...state.validation, [key]: true },
    }))
  }

  handleChangeValue = ({ target: { value } }) => {
    this.setState({ value })
  }

  handleChangeDestination = ({ id }) => {
    this.setState(state => ({
      destination: id,
      validation: { ...state.validation, destination: true },
    }))
  }

  validate = () => {
    const {
      variable,
      condition,
      value,
      destination,
      typeVariable,
      text_variable,
    } = this.state

    if (typeVariable === 'template') {
      return !isEmpty(text_variable)
    }

    const isFieldValid = field =>
      variable || condition || value ? !!field : true
    const validation = {
      destination: !!destination,
      variable: isFieldValid(variable),
      condition: isFieldValid(condition),
    }
    this.setState({ validation })

    return isEmpty(pickBy(validation, f => !f))
  }

  update = () => {
    const { data, onUpdate } = this.props
    const { variable, condition, value, destination, text_variable } =
      this.state

    if (this.validate()) {
      const old = JSON.stringify({
        variable: data.variable,
        condition: data.condition,
        value: data.value,
        destination: data.destination,
      })
      const updated = {
        variable,
        condition,
        value,
        destination,
        text_variable,
      }

      if (old !== JSON.stringify(updated)) {
        onUpdate({ id: data.id, ...updated })
      }
    }
  }

  toggleVariables = e => {
    const typeVariable = e.target.name

    this.setState({ typeVariable }, () => {
      this.reset()
    })
  }

  reset = () => {
    this.setState({
      condition: null,
      validation: {
        variable: true,
        condition: true,
        destination: true,
      },
      value: null,
      variable: null,
      text_variable: null,
    })
  }

  render() {
    const { data, onClickOut, t } = this.props
    const {
      condition,
      variable,
      value,
      destination,
      typeVariable,
      text_variable,
    } = this.state

    const selectedCondition = this.compareOptions.find(
      o => o.value === condition
    )

    return (
      <OutsideClick onClickOut={() => onClickOut()}>
        <div className='request-constructor__card--body request-constructor__card--redirect'>
          <div className='request-constructor__card--redirect-title'>
            <span>{t('autoRedirect.description')}</span>
          </div>
          <div>
            <div style={{ marginBottom: '15px' }}>
              <Radio
                name='system'
                id='system'
                value={true}
                checked={typeVariable === 'system'}
                label={t('Common:WorkflowBotVariables')}
                onChange={this.toggleVariables}
              />
            </div>
            <div>
              <Radio
                name='template'
                id='template'
                value={false}
                checked={typeVariable === 'template'}
                label={t('Common:TextTemplatesVariables')}
                onChange={this.toggleVariables}
              />
            </div>
          </div>
          <p>{t('autoRedirect.if')}</p>

          <div className='request-constructor__card--redirect-filters'>
            {typeVariable === 'system' ? (
              <SelectVariable
                error={!this.isValid('variable')}
                exclude={[VariableTypes.file]}
                placeholder={t('autoRedirect.variablePlaceholder')}
                value={variable}
                onChange={this.handleChangeOption('variable')}
              />
            ) : (
              <TemplateVariables
                isShowSelected
                t={t}
                ns='Templates'
                api={getSnippetVariables}
                tags={this.tags}
                name={text_variable}
                name_exclude='recipient'
                handleSelect={this.handleChangeOption('text_variable')}
              />
            )}

            <NewSelectSimple
              value={selectedCondition}
              options={this.compareOptions}
              placeholder={t('selectConditionType')}
              error={!this.isValid('condition')}
              onChange={this.handleChangeOption('condition')}
            />
            <div className='input input--large input--block input--default'>
              <input
                placeholder={t('autoRedirect.valuePlaceholder')}
                value={value || ''}
                onChange={this.handleChangeValue}
              />
            </div>
          </div>
          <div className='request-constructor__card--redirect-block'>
            <p>{t('autoRedirect.then')}</p>
            <SelectBlock
              ignoreBlock={data.block}
              inputError={!this.isValid('destination')}
              value={destination}
              onChange={this.handleChangeDestination}
            />
          </div>
        </div>
      </OutsideClick>
    )
  }
}

export default withTranslation(['RequestConstructor', 'Templates'])(Filter)
