// @flow

import { connect } from 'react-redux'

import SelectCustom from './SelectCustom'

const mapStateToProps = state => ({
  ...state.SelectCustom,
})

export default connect(mapStateToProps)(SelectCustom)
