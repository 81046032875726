// @flow

import React from 'react'
import classnames from 'classnames'
import type { Node } from 'react'

import styles from './Label.module.scss'

type Props = {
  className?: string,
  color: string,
  style?: Object,
  text: string,
  type: string,
}

const Label = (props: Props): Node => {
  const { text, style, type, color, className } = props

  const labelClass = classnames(
    styles.label,
    styles[type],
    styles[color],
    className
  )

  return (
    <div className={labelClass} style={style} title={text}>
      {text}
    </div>
  )
}

Label.defaultProps = {
  type: 'contained',
  color: 'danger',
}

export default Label
