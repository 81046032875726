// @flow

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useParams } from 'react-router-dom'
import { get, partial } from 'lodash-es'
import classnames from 'classnames'
import { replace } from 'connected-react-router'
import type { Node } from 'react'

import { Row, SimpleBlock, Header } from '../Block'
import Content from '../Content'
import NewSelectAsync from '../NewSelectAsync'
import Button from '../Button'
import {
  getPipelineTemplate,
  getRequestTypes,
} from '../../core/api/api.pipeline'
import {
  createPipelineTemplate,
  updatePipelineTemplate,
} from '../../core/api/api.pipeline'
import InputField from '../InputField'
import Phase from './Phase'

import { redirectTo404 } from '../../utils/routing'
import { MAX_PIPELINE_NAME_LENGTH } from '../../constants'

import styles from './Pipeline.module.scss'

const Pipeline = (): Node => {
  const { pipelineId } = useParams()

  const dispatch = useDispatch()

  const [isLoading, setLoading] = useState(!!pipelineId)
  const [initialPipeline, setInitialPipeline] = useState({})

  useEffect(() => {
    if (pipelineId) {
      getPipelineTemplate(pipelineId)
        .then(data => setInitialPipeline(data))
        .catch(error => redirectTo404(error))
        .finally(() => {
          setLoading(false)
        })
    }
  }, [pipelineId])

  const { t } = useTranslation('Pipeline')

  const getLabel = type => type.name

  const formik = useFormik({
    validateOnChange: true,
    enableReinitialize: true,
    initialValues: {
      name: initialPipeline.name || 'Pipeline',
      requestType: initialPipeline.request_category || '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required(),
      requestType: Yup.object()
        .shape({
          id: Yup.number().required(),
          name: Yup.string().required(),
          position: Yup.number().nullable(),
        })
        .required(),
    }),
    onSubmit: submitValues => {
      setLoading(true)

      const values = {
        name: submitValues.name,
        request_category: submitValues.requestType.id,
      }

      const api = pipelineId
        ? partial(updatePipelineTemplate, pipelineId)
        : createPipelineTemplate

      api(values).then(data => {
        if (pipelineId) {
          setInitialPipeline(data)
          setLoading(false)
        } else {
          dispatch(replace(`/settings/pipeline/${data.id}/`))
          setLoading(false)
        }
      })
    },
  })

  const handleChangeRequestType = requestType =>
    formik.setFieldValue('requestType', requestType)

  const buttonText = pipelineId ? 'SavePipelineButton' : 'CreatePipelineButton'
  const titleText = pipelineId ? 'EditPipelineTitle' : 'CreatePipelineTitle'

  const blockClass = classnames({ 'working-overlay': isLoading })

  const initialCategoryId = get(initialPipeline, ['request_category', 'id'])

  const permanentParams = Object.assign(
    {},
    initialCategoryId ? { including: initialCategoryId } : undefined
  )

  return (
    <Content>
      <form onSubmit={formik.handleSubmit}>
        <SimpleBlock className={blockClass}>
          <Header header goBack>
            {t(titleText)}
          </Header>
          <Row title={t('PipelineNameLabel')}>
            <InputField
              className={styles.field}
              showErrorText={false}
              value={formik.values.name}
              maxLength={MAX_PIPELINE_NAME_LENGTH}
              name='name'
              error={formik.errors.name}
              onChange={formik.handleChange}
            />
          </Row>
          <Row title={t('RequestTypeLabel')}>
            {(!pipelineId || (pipelineId && !isLoading)) && (
              <NewSelectAsync
                className={styles.field}
                pageSize={10}
                selectedItems={
                  formik.values.requestType ? [formik.values.requestType] : []
                }
                getLabel={getLabel}
                getSelectedLabel={getLabel}
                searchKey='name'
                view='dropdown'
                api={getRequestTypes}
                error={formik.errors.requestType}
                placeholder={t('RequestTypePLaceholder')}
                permanentParams={permanentParams}
                onClick={handleChangeRequestType}
              />
            )}
          </Row>
          <Row>
            <div className={styles.buttons}>
              <Button.Regular
                disabled={!formik.dirty || isLoading}
                type='submit'
              >
                {t(buttonText)}
              </Button.Regular>
              <Button.Cancel
                disabled={!formik.dirty || isLoading}
                onClick={formik.handleReset}
              >
                {t('Common:Cancel')}
              </Button.Cancel>
            </div>
          </Row>
        </SimpleBlock>
      </form>
      {pipelineId && <Phase pipelineId={pipelineId} />}
    </Content>
  )
}

export default Pipeline
