// @flow

import { getApi, postRaw, patchRaw } from './api.core'

const basePath = 'api/doc-templates'

export const createGoogleConnection = (params: {
  mass_emailing_storage_link: string,
  private_storage_link: string,
  request_storage_link: string,
  secret_key: Object,
}) => postRaw(`${basePath}/google-connection/`, params)
export const updateGoogleConnection = (params: {
  mass_emailing_storage_link?: string,
  private_storage_link?: string,
  request_storage_link?: string,
}) => patchRaw(`${basePath}/google-connection/`, params)
export const getGoogleConnection = () =>
  getApi(`${basePath}/google-connection/`)
export const refreshGoogleTemplates = () =>
  postRaw(`${basePath}/google-templates/refresh/`)
export const getGoogleTemplates = search =>
  getApi(`${basePath}/google-templates/`, search ? { search } : {})
export const analyzeGoogleTemplateVariables = (params: Object) =>
  postRaw(`${basePath}/google-templates/analyze-variables/`, params)
export const googleTemplatePreview = (params: {
  dependencies: Object,
  recipient: number,
  template: number,
}) => postRaw(`${basePath}/google-templates/preview/`, params)
export const getExternalVars = (id: number) =>
  getApi(`${basePath}/google-templates/${id}/get-external-vars/`)
