// @flow

import React from 'react'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import styles from './Status.module.scss'

type Props = { status: Object }

const Status = (props: Props): Node => {
  const {
    status: { css, title },
  } = props

  const { t } = useTranslation('Request')

  const statusClass = classnames(`bar__cell-value--${css}`, styles.status)

  return <span className={statusClass}>{t(title)}</span>
}

export default Status
