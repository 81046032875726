// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'
import type { Dispatch } from 'redux'
import type { Match } from 'react-router-dom'

import Loader from '../Loader'
import * as actions from './SharedRequest.actionTypes'

type Props = {
  dispatch: Dispatch,
  match: Match,
}

class SharedRequest extends Component<Props> {
  componentDidMount() {
    this.props.dispatch({
      type: actions.UPDATE_INIT_DATA,
      hash: this.props.match.params.hash,
    })
  }

  render() {
    return (
      <div>
        <Loader text={false} type='big' />
      </div>
    )
  }
}

export default connect()(SharedRequest)
