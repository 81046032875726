// @flow

import React from 'react'
import type { Node } from 'react'
import { DropTarget } from 'react-dnd'

import Item, { ItemTypes } from './DraggableItem'

type Props = {
  connectDropTarget: (Node) => Node,
  items: Array<string>,
  onAdd: (string) => void,
  renderActions: () => Node,
}

const dropTarget = {
  drop (props: Props, monitor) {
    const { name, category } = monitor.getItem()
    props.onAdd(name, category)
  }
}

const UnmatchedBox = (props: Props) => {
  const { items, connectDropTarget, renderActions } = props

  return connectDropTarget(
    <div className='modal__mapping-categories--table-col modal__mapping-categories--table-col-1'>
      {items.map((item) => (
        <Item
          dndType={ItemTypes.unmatched}
          key={item}
          name={item}
        />
      ))}
      {renderActions()}
    </div>
  )
}

export default DropTarget(
  ItemTypes.category,
  dropTarget,
  (connect) => ({
    connectDropTarget: connect.dropTarget()
  })
)(UnmatchedBox)
