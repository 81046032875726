// @flow

import React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ModalText from '../../Modal/ModalText'
import ModalRow from '../../Modal/ModalRow'
import NewSelectAsync from '../../NewSelectAsync'
import api from '../../../core/api'

import styles from './CategoryPopup.module.scss'

function CategoryPicker(props) {
  const { selected } = props

  const { t } = useTranslation('CategoryPopup')

  const [metaCount, setMetaCount] = useState(null)

  const getCategoryLabel = category => category.name

  const handleMetaCount = count => {
    if (!metaCount) {
      setMetaCount(count)
    }
  }

  const checkAllSelected = selectedItems => {
    if (metaCount && metaCount === selectedItems.length) {
      return true
    }

    return false
  }

  const handleClickAll = isLoading => {
    props.setAllLoading(isLoading)
  }

  return (
    <>
      <ModalRow>
        <ModalText text={t('RequestCategoryLabel')} />
      </ModalRow>
      <ModalRow>
        <NewSelectAsync
          isMulti
          placeholder={t('Select:SelectCategory')}
          api={api.requestCategory.get}
          pageSize={10}
          view='dropdown'
          searchKey='search'
          selectedItems={selected}
          getLabel={getCategoryLabel}
          getSelectedLabel={getCategoryLabel}
          className={styles.select}
          setSelectedItems={props.setSelected}
          isAllSelected={checkAllSelected}
          setMetaCount={handleMetaCount}
          onClick={props.changeSelected}
          onClickAll={handleClickAll}
        />
      </ModalRow>
    </>
  )
}

export default CategoryPicker
