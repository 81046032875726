// @flow

import React from 'react'
import type { Node } from 'react'

import Chip from '../Chip'
import NewAvatar from '../NewAvatar'

import styles from './ActiveBuildings.module.scss'

type Props = { buildings: Array<Object>, isGroup?: boolean }

const ActiveBuildings = (props: Props): Node => {
  const { buildings, isGroup } = props

  return (
    <div className={styles.buildings}>
      {buildings.map(building => (
        <Chip
          text={
            isGroup
              ? building.name || building.label
              : building.address_obj?.value || building.address
          }
          key={building.id || building.value}
          className={styles.chip}
          onClose={() => {
            props.changeSelected(building)
          }}
        >
          <NewAvatar
            isBordered
            src={building.avatar_obj?.preview || building.photo_obj.preview}
            size='small'
            className={styles.avatar}
          />
        </Chip>
      ))}
    </div>
  )
}

export default ActiveBuildings
