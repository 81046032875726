/* eslint-disable react/display-name */
import { ErrorMessage, Formik, Form } from 'formik'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { camelCase, isEqual } from 'lodash-es'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'
import classnames from 'classnames'

import { TYPES } from './ProviderProfile.const'
import Button from '../../components/Button'
import Input from '../../components/Input'
import PhoneInput from '../../components/forms/PhoneInput'
import Icon from '../../components/Icon'
import ValidationText from '../../components/ValidationText'
import ButtonsBar from './ButtonsBar'
import { getHref } from '../../utils/utils'
import { CONTACT_TYPES_SVG } from '../../constants'

import styles from './ProviderProfile.module.scss'

const EditContactForm = props => {
  const { editable, contacts, workTime } = props
  const { t } = useTranslation('MarketplaceWidgets')
  const countryCode = useSelector(state => state.init.uk.country_obj.code)
  const [editMode, setEditMode] = useState(false)

  const nonEmptyContactTypes = contacts.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.type]: curr.value,
    }),
    {}
  )
  let contactsData = Object.keys(TYPES.contactTypes).map(contactKey => ({
    ...TYPES.contactTypes[contactKey],
    value: nonEmptyContactTypes[contactKey] || '',
    type: contactKey,
  }))

  const workTimeInput = {
    inputType: 'text',
    name: TYPES.workTime,
    verboseName: 'Worktime',
    value: workTime,
    type: TYPES.workTime,
  }

  contactsData = [...contactsData, workTimeInput]
  let initialValues = contactsData.reduce((acc, item) => {
    acc[item.type] = item.value

    return acc
  }, {})

  initialValues = {
    ...initialValues,
    workTime,
  }

  const renderContact = (contact, i) => {
    const { value } = contact

    if (!value) {
      return null
    }

    const { type, verboseName } = contact
    const contactLabel = t(`${camelCase(verboseName)}`)
    const hrefValue = getHref(type, value)

    return (
      <div className={styles['contact']} key={`contact-${i}`}>
        <div className={styles['contact__header']} title={contactLabel}>
          {type !== TYPES.workTime && (
            <Icon
              className={styles['contact__icon']}
              id={CONTACT_TYPES_SVG[type]}
            />
          )}
          <span className={styles['contact__label']}>{contactLabel}</span>
        </div>
        <div className={styles['contact__value-container']}>
          {type !== TYPES.workTime ? (
            <a
              title={value}
              className={styles['contact__value']}
              href={hrefValue}
              target='_blank'
              rel='noopener noreferrer'
            >
              {value}
            </a>
          ) : (
            <span className={styles['contact__value']}>{value}</span>
          )}
        </div>
      </div>
    )
  }

  const cancelEditMode = () => {
    setEditMode(false)
  }

  const renderInput = formikProps => contact => {
    const { setFieldValue, values, touched, errors } = formikProps
    const { type, verboseName, inputType } = contact
    const title = t(camelCase(verboseName))
    const isPhone = type === 'contact_phone' || type === 'order_phone'
    const handleChange = value => setFieldValue(type, value)
    const errorClassName = {
      [styles.inputError]: errors[type] && touched[type],
    }
    const inputClassName = classnames(errorClassName)
    const phoneClassName = classnames(
      'input input--default input--block input--large input--multiple',
      errorClassName
    )

    return (
      <div key={type} className={styles['contact__row']}>
        <div className={styles['contact__header']} title={title}>
          {type !== TYPES.workTime && (
            <Icon
              className={styles['contact__icon']}
              id={CONTACT_TYPES_SVG[type]}
            />
          )}
          <span className={styles['contact__label']}>{title}</span>
        </div>
        <div style={{ width: '100%' }}>
          {isPhone ? (
            <PhoneInput
              type='text'
              value={values[type]}
              name={type}
              className={phoneClassName}
              countryCode={countryCode}
              onChange={handleChange}
            />
          ) : (
            <div className='input input--large input--block input--default input--success'>
              <Input.Regular
                name={type}
                type={inputType}
                className={inputClassName}
                value={values[type]}
                placeholder={t(camelCase(verboseName))}
                onChange={setFieldValue}
              />
            </div>
          )}
          <ErrorMessage name={type} render={renderError} />
        </div>
      </div>
    )
  }

  const renderError = text => <ValidationText active text={text} />

  const getValidationSchema = () => {
    const getErrorText = name => t('emptyInput', { key: t(name) })
    const stringValidation = name => Yup.string().required(getErrorText(name))

    return Yup.object().shape({
      website: Yup.string(),
      contact_email: Yup.string()
        .email('Invalid email')
        .required(getErrorText('contact_email')),
      contact_phone: stringValidation('contact_phone'),
      order_email: Yup.string().email('Invalid email'),
      order_phone: Yup.string(),
      workTime: Yup.string().required(getErrorText('worktime')),
    })
  }

  const onSubmit = (values, { setSubmitting }) => {
    props.onSubmit(
      {
        contacts: contactsData
          .filter(({ type }) => type !== TYPES.workTime)
          .map(({ type }) => ({
            type,
            value: values[type],
          })),
        workTime: values.workTime,
      },
      () => {
        setSubmitting(false)
        cancelEditMode()
      },
      () => {
        setSubmitting(false)
      }
    )
  }

  const disabled = ({ isSubmitting, values }) =>
    isSubmitting || isEqual(values, initialValues)

  const renderEditForm = () => (
    <Formik
      initialValues={initialValues}
      validationSchema={getValidationSchema()}
      onSubmit={onSubmit}
    >
      {formikProps => (
        <Form>
          {contactsData.map(renderInput(formikProps))}

          <ButtonsBar
            disabled={disabled(formikProps)}
            cancelDisabled={formikProps.isSubmitting}
            onCancel={cancelEditMode}
            onSubmit={formikProps.handleSubmit}
          />
        </Form>
      )}
    </Formik>
  )

  return (
    <div className={styles.description}>
      <div className={styles['contacts-header']}>
        <span>{t('contacts')}</span>
        {editable && !editMode && (
          <Button.Edit
            onClick={() => {
              setEditMode(true)
            }}
          />
        )}
      </div>
      <div className={styles.contacts}>
        {editMode
          ? renderEditForm()
          : [...contacts, workTimeInput].map(renderContact)}
      </div>
    </div>
  )
}

export default EditContactForm
