// @flow

import React, { Children, Component } from 'react'
import type { Node } from 'react'
import ReactResizeDetector from 'react-resize-detector'

import styles from './TileList.module.scss'

export const ITEM_INDENT = 25

type Props = {
  children: Node,
  index: number,
  itemIndent: number,
  length: number,
  onUpdateHeight?: (number, number) => void,
}

class TileRow extends Component<Props> {
  static defaultProps = {
    index: -1,
    itemIndent: ITEM_INDENT,
  }

  getItems = () => {
    const { children, length } = this.props
    const items = Children.toArray(children)

    if (items.length < length) {
      const extended = Array(length - items.length)
        .fill()
        .map((item, i) => item || { fake: true, id: `fake-${i + 1}` })

      return items.concat(extended)
    }

    return items
  }

  updateHeight = (width: number, height: number) => {
    const { index } = this.props

    if (this.props.onUpdateHeight && index >= 0) {
      this.props.onUpdateHeight(index, height)
    }
  }

  renderItem = (item: Object) => {
    if (item.fake) {
      return <div className={styles.fake} key={item.id} />
    }

    const { length } = this.props
    const maxWidth = `${100 / length}%`

    return (
      <div className={styles.item} key={item.key} style={{ maxWidth }}>
        {item}
      </div>
    )
  }

  render() {
    const { itemIndent } = this.props

    return (
      <div className={styles.row} style={{ '--itemIndent': `${itemIndent}px` }}>
        <ReactResizeDetector
          handleHeight={Boolean(this.props.onUpdateHeight)}
          onResize={this.updateHeight}
        >
          {this.getItems().map(this.renderItem)}
        </ReactResizeDetector>
      </div>
    )
  }
}

export default TileRow
