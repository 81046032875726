/* eslint-disable flowtype/no-dupe-keys */
// @flow

import React, { useState } from 'react'
import type { Node } from 'react'
import { partial } from 'lodash-es'

import ProfilePersonal from './ProfilePersonal'
import ProfilePostal from './ProfilePostal'
import ProfileFinancial from './ProfileFinancial'
import ProfilePartner from './ProfilePartner'
import ORAGCluster from './ORAGCluster'

import NotesPopup from '../modals/NotesPopup'

import { useLoad } from '../../hooks/useLoad/useLoad'
import { getProfileInfo, updateProfileInfo } from '../../core/api/api.profile'

export type IPartner = {
  email: string,
  first_name: string,
  full_name: string,
  last_name: string,
  middle_name: string,
  notes: string,
  phone: string,
  salutation: number,
  title: string,
}

export type IFinancial = {
  bank_name: string,
  bic: string,
  deposit: {
    amount: number,
    currency: string,
  },
  iban: string,
  notes: string,
  open_amount: {
    amount: number,
    currency: string,
  },
  payment_type: string,
}

export type IPersonal = {
  block_name: string,
  first_name: string,
  full_name: string,
  last_name: string,
  notes: string,
  salutation: string | null,
}

export type IAddress = {
  address: string,
  city: string,
  notes: string,
  notes: string,
  postcode: string,
  street: string,
}

type IProfileData = {
  address: IAddress,
  financial: IFinancial,
  partner: IPartner,
  personal: IPersonal,
}

export type IModalName = 'personal' | 'address' | 'financial' | 'partner' | ''

type Props = {
  oragShow: boolean,
  userId: number,
}

const ProfileData = ({ userId, oragShow }: Props): Node => {
  const [currentModalNotice, setCurrentModalNotice] = useState<IModalName>('')

  const [profileData, , , load]: [IProfileData, boolean, boolean, Function] =
    useLoad(getProfileInfo, {
      id: userId,
    })
  const { personal, address, financial, partner, orag_cluster }: IProfileData =
    profileData || {}

  const asyncUpdateProfileInfo = async (id: number, params: Object) => {
    await updateProfileInfo(id, params)
    load()
  }
  const handleUpdateProfile = partial(asyncUpdateProfileInfo, userId)
  const handleCloseModalNotice = () => setCurrentModalNotice('')
  const updateProfileInfoNotice = notes => {
    const noticeData = { [(currentModalNotice: string)]: { notes } }
    handleUpdateProfile(noticeData).then(load)
  }

  const list = { personal, address, financial, partner, orag_cluster }

  return (
    <>
      {profileData && (
        <>
          <ProfilePersonal
            personal={personal}
            updateProfileInfo={handleUpdateProfile}
            setCurrentNoticeModal={setCurrentModalNotice}
          />
          <ProfilePostal
            address={address}
            updateProfilePostal={handleUpdateProfile}
            setCurrentNoticeModal={setCurrentModalNotice}
          />
          <ProfileFinancial
            financial={financial}
            updateProfileFinancial={handleUpdateProfile}
            setCurrentNoticeModal={setCurrentModalNotice}
          />
          <ProfilePartner
            partner={partner}
            updateProfileInfo={handleUpdateProfile}
            setCurrentNoticeModal={setCurrentModalNotice}
          />
          {oragShow && (
            <ORAGCluster
              cluster={orag_cluster}
              updateProfileInfo={handleUpdateProfile}
              setCurrentNoticeModal={setCurrentModalNotice}
            />
          )}
        </>
      )}
      {currentModalNotice && (
        <NotesPopup
          updateNotice={updateProfileInfoNotice}
          notes={list[currentModalNotice]?.notes}
          onClose={handleCloseModalNotice}
        />
      )}
    </>
  )
}

export default ProfileData
