// @flow

import { intersection } from 'lodash-es'

import { BUILDINGS_SCOPE } from '../Post/Post.constants'

export function isBuildingsDisabled(scope: Array<string>): boolean {
  return intersection(scope, BUILDINGS_SCOPE).length < 1
}

export function isSubmitDisabled(
  scope: Array<string>,
  selectedItems: Array<Object>,
  requestBuilding: boolean
): boolean {
  if (!scope.length) {
    return true
  }

  if (
    !isBuildingsDisabled(scope) &&
    selectedItems.length === 0 &&
    !requestBuilding
  ) {
    return true
  }

  return false
}
