// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { StickyContainer } from 'react-sticky'
import { isNull } from 'lodash-es'
import { compose } from 'redux'

import Card from '../../components/RequestConstructor/Card'
import CardArrow from '../../components/RequestConstructor/CardArrow'
import ControlPanel from '../../components/RequestConstructor/ControlPanel'

import {
  ButtonTypes,
  LinkButtonTypes,
} from '../../components/RequestConstructor/constants'
import * as actions from './RequestConstructorCards.actionTypes'

type Props = {
  block: number,
  dispatch: Object => void,
  error: ?Object,
  initiated: boolean,
  items: Array<Object>,
  removingId: ?number,
  updatingId: ?number,
  variables: Array<Object>,
  working: boolean,
}
type State = {
  activeCard: ?number,
}

class requestConstructorCards extends Component<Props, State> {
  state = {
    activeCard: null,
  }

  componentDidMount() {
    const { block } = this.props
    this.props.dispatch({
      type: actions.REQUEST_CONSTRUCTOR_INITIATE_CARDS,
      block,
    })
  }

  componentDidUpdate(prev: Props) {
    const { removingId, error } = this.props

    if (!error && prev.removingId && !removingId) {
      const { activeCard } = this.state

      if (!isNull(activeCard) && prev.items[activeCard].id === removingId) {
        this.setState({ activeCard: null })
      }
    }
  }

  componentWillUnmount() {
    this.props.dispatch({
      type: actions.REQUEST_CONSTRUCTOR_RESET_CARDS,
    })
  }

  onWasRemoved = (id: number) => {
    this.props.dispatch({
      type: actions.REQUEST_CONSTRUCTOR_CARD_WAS_REMOVED,
      id,
    })
  }

  focusCard = index => () => {
    this.setState({ activeCard: index })
  }

  blurCard = id => () => {
    const { items, working } = this.props
    const { activeCard } = this.state

    if (!isNull(activeCard) && id === items[activeCard].id && !working) {
      this.setState({ activeCard: null })
    }
  }

  moveCard = i => id => {
    const workflowId = this.props.workflowId
    this.props.dispatch({
      type: actions.REQUEST_CONSTRUCTOR_MOVE_CARD,
      id,
      position: i + 1,
      workflowId,
    })
    this.setState({ activeCard: null })
  }

  add = (data: Object) => {
    const { activeCard } = this.state
    const card = { ...data }
    const workflowId = this.props.workflowId

    if (!isNull(activeCard)) {
      card.position = activeCard + 2
    }

    this.props.dispatch({
      type: actions.REQUEST_CONSTRUCTOR_ADD_CARD,
      card,
      workflowId,
    })
  }

  remove = (card: Object) => {
    const workflowId = this.props.workflowId

    this.props.dispatch({
      type: actions.REQUEST_CONSTRUCTOR_REMOVE_CARD,
      card,
      workflowId,
    })
  }

  update = (card: Object) => {
    const workflowId = this.props.workflowId
    this.props.dispatch({
      type: actions.REQUEST_CONSTRUCTOR_UPDATE_CARD,
      card,
      workflowId,
    })
  }

  addLink = (id: number, link: Object) => {
    const workflowId = this.props.workflowId

    this.props.dispatch({
      type: actions.REQUEST_CONSTRUCTOR_CARD_ADD_LINK,
      id,
      link,
      workflowId,
    })
  }

  updateLink = (id: number, link: Object) => {
    const workflowId = this.props.workflowId

    this.props.dispatch({
      type: actions.REQUEST_CONSTRUCTOR_CARD_UPDATE_LINK,
      id,
      link,
      workflowId,
    })
  }

  removeLink = (id: number, link: Object) => {
    const workflowId = this.props.workflowId

    this.props.dispatch({
      type: actions.REQUEST_CONSTRUCTOR_CARD_REMOVE_LINK,
      id,
      link,
      workflowId,
    })
  }

  renderArrow = (index, firstRed) => {
    const { items } = this.props

    if (index === items.length - 1) {
      return null
    }

    const isRed = firstRed > -1 && index >= firstRed

    return <CardArrow isRed={isRed} />
  }

  renderCard = firstRed => (card, i) => {
    const {
      variables,
      updatingId,
      removingId,
      working,
      error,
      setFiles,
      setFileIndex,
    } = this.props
    const { activeCard } = this.state

    const props = {
      active: i === activeCard,
      data: card,
      error,
      key: card.id,
      updatingId,
      removingId,
      working,
      onBlur: this.blurCard(card.id),
      onFocus: this.focusCard(i),
      onMove: this.moveCard(i),
      onRemove: this.remove,
      onUpdate: this.update,
      renderArrow: () => this.renderArrow(i, firstRed),
      setFiles,
      setFileIndex,
    }
    const linkProps = {
      onLinkAdd: this.addLink,
      onLinkRemove: this.removeLink,
      onLinkUpdate: this.updateLink,
    }
    switch (card.type) {
      case ButtonTypes.text:
        return <Card.Text {...props} {...linkProps} />
      case ButtonTypes.image:
        return <Card.Image {...props} {...linkProps} />
      case ButtonTypes.variable:
        return <Card.Variable {...props} variables={variables} />
      case ButtonTypes.filter:
        return (
          <Card.Filter
            {...props}
            variables={variables}
            onWasRemoved={this.onWasRemoved}
          />
        )
      case ButtonTypes.email:
        return <Card.Email {...props} />
      case ButtonTypes.request:
        return <Card.Request {...props} />
      default:
        return null
    }
  }

  render() {
    const { initiated, items, block } = this.props

    if (!initiated) {
      return null
    }

    const firstRedIndex = items.findIndex(i => {
      const hasLinks = LinkButtonTypes.includes(i.type) && i.links.length
      const hasAutoDestination =
        i.type === 'auto-redirect' && // If unconditional auto redirect
        i.destination &&
        !i.condition &&
        !i.variable

      return hasLinks || hasAutoDestination
    })

    return (
      <StickyContainer>
        <ControlPanel block={block} onClick={this.add} />
        <div className='request-constructor__card-container'>
          {items.map(this.renderCard(firstRedIndex))}
        </div>
      </StickyContainer>
    )
  }
}

const mapStateToProps = state => ({
  ...state.requestConstructorCards,
  workflowId: state.requestConstructor.data.id,
})

export default compose(
  withTranslation('RequestConstructor'),
  connect(mapStateToProps)
)(requestConstructorCards)
