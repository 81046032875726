// @flow

import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash-es'
import type { Node } from 'react'

import NotAvailable from './NotAvailable'
import Button from '../Button'
import Input from '../Input/Input'
import Confirm from './Confirm'
import { getUk } from '../../../utils/commonSelectors'
import { freeRegistration } from '../../../core/api/api.profile'
import { serverError } from '../../Layout/Layout.actions'
import LoginLink from '../LoginLink'
import Terms from '../Terms'
import NewSelectAsync from '../../NewSelectAsync'
import api from '../../../core/api'

type Props = {
  setWorking: boolean => void,
  working: boolean,
}

const MAX_NAME_LENGTH = 50
const MAX_SECOND_NAME_LENGTH = 50

const RegistrationForm = (props: Props): Node => {
  const { working } = props

  const isFreeRegistrationEnabled = useSelector(
    state => state.init.uk_config.free_registration
  )

  const isSimpleRegistration = useSelector(
    state => state.init.uk_config.simple_registration
  )

  const [confirmed, setConfirmed] = useState(false)
  const [error, setError] = useState(false)
  const [address, setAddress] = useState({})

  const dispatch = useDispatch()
  const { t } = useTranslation('Registration')
  const uk = useSelector(state => getUk(state))

  const updateBuilding = e => {
    if (!e) {
      setAddress('')
      formik.setFieldValue('buildingId', '')
      formik.setFieldValue('flatNumber', '')
    } else {
      const id = get(e, 'id')
      setAddress(e)

      if (id) {
        formik.setFieldValue('buildingId', id)
        formik.setFieldValue('flatNumber', '')
      }
    }
  }

  const updateFlat = e => {
    formik.setFieldValue('flatNumber', e)
  }

  const initialValues = Object.assign(
    {},
    { name: '', secondName: '', email: '' },
    !isSimpleRegistration ? { buildingId: '', flatNumber: '' } : undefined
  )

  const validationSchema = Object.assign(
    {},
    {
      name: Yup.string()
        .required(t('Common:FieldIsRequired'))
        .max(MAX_NAME_LENGTH, t('MaxNameLength', { length: MAX_NAME_LENGTH })),
      secondName: Yup.string()
        .required(t('Common:FieldIsRequired'))
        .max(
          MAX_SECOND_NAME_LENGTH,
          t('MaxSecondNameLength', { length: MAX_SECOND_NAME_LENGTH })
        ),
      email: Yup.string()
        .required(t('Common:FieldIsRequired'))
        .email(t('Common:EnterValidEmailErrorText')),
    },
    !isSimpleRegistration
      ? {
          buildingId: Yup.number().required(t('Common:FieldIsRequired')),
          flatNumber: Yup.string().required(t('Common:FieldIsRequired')),
        }
      : undefined
  )

  const formik = useFormik({
    validateOnChange: false,
    initialValues,
    validationSchema: Yup.object(validationSchema),
    onSubmit: values => {
      props.setWorking(true)

      const { name, secondName, email, buildingId, flatNumber } = values

      const params = Object.assign(
        {},
        {
          name,
          second_name: secondName,
          email,
        },
        !isSimpleRegistration
          ? {
              building: buildingId,
              flat: flatNumber,
            }
          : undefined
      )

      freeRegistration(params)
        .then(() => setConfirmed(true))
        .catch(err => {
          const status = get(err, ['message', 'response', 'status'])

          if (status === 400) {
            const error = get(err, ['message', 'response', 'data'])

            return setError(error)
          }

          dispatch(serverError(error))
        })
        .finally(() => props.setWorking(false))
    },
  })

  const handleCloseFlatSelect = val => {
    if (val) {
      formik.setFieldValue('flatNumber', val)
    }
  }

  if (!isFreeRegistrationEnabled) {
    return (
      <NotAvailable
        descriptionText={t('NotAvailableText')}
        headerText={t('FreeRegistrationTitle')}
        t={t}
      />
    )
  }

  if (confirmed) {
    return (
      <Confirm
        email={formik.values.email}
        name={formik.values.name}
        secondName={formik.values.secondName}
        t={t}
      />
    )
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <Input
        value={formik.values.name}
        name='name'
        placeholder={t('name')}
        errors={[formik.errors.name]}
        onChange={formik.handleChange}
      />
      <Input
        value={formik.values.secondName}
        name='secondName'
        placeholder={t('surname')}
        errors={[formik.errors.secondName]}
        onChange={formik.handleChange}
      />
      <Input
        value={formik.values.email}
        name='email'
        placeholder={t('email')}
        errors={[formik.errors.email]}
        onChange={formik.handleChange}
      />
      {!isSimpleRegistration && (
        <>
          <NewSelectAsync
            delay={700}
            pageSize={10}
            view='dropdown'
            searchKey='address'
            name='buildingId'
            api={api.ukBuildings.get({ ukId: uk.id })}
            placeholder={t('Select:FindAddress')}
            getLabel={option => option.address}
            getSelectedLabel={option => option.address}
            className='auth__select-address'
            minSearchLength={1}
            selectedItems={address.id ? [address] : []}
            onClick={updateBuilding}
          />
          <div className='error'>{formik.errors.buildingId}</div>
        </>
      )}
      {!isSimpleRegistration && (
        <>
          <NewSelectAsync
            delay={700}
            key={formik.values.buildingId}
            pageSize={10}
            view='dropdown'
            searchKey='number'
            name='flatNumber'
            disabled={!formik.values.buildingId}
            api={api.ukFlats.get({
              ukId: uk.id,
              buildingId: formik.values.buildingId,
            })}
            placeholder={t('Select:FlatNumberShort')}
            getLabel={option => option.number}
            getSelectedLabel={option => option.number}
            className='auth__select-address'
            minSearchLength={1}
            selectedItems={
              formik.values.flatNumber
                ? [{ number: formik.values.flatNumber }]
                : []
            }
            onClick={v => updateFlat(v ? v.number : null)}
            onClose={handleCloseFlatSelect}
          />
          <div className='error'>{formik.errors.flatNumber}</div>
        </>
      )}
      <Button text={t('Confirm')} type='submit' />
      {!working && !!error && (
        <div className='error' style={{ marginBottom: '24px' }}>
          {error ? t(error.errors) : null}
        </div>
      )}
      <LoginLink infoText={t('AlreadyRegistered')} linkText={t('Login')} />
      <Terms />
    </form>
  )
}

export default RegistrationForm
