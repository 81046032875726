// @flow

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { replace } from 'connected-react-router'

import { Header, SimpleBlock } from '../../components/Block'
import ThreadsList from '../Mail/ThreadsList'
import RouterTabs from '../../components/Tabs/RouterTabs'
import Pan from '../../components/Tabs/Pan'
import { getLocation, getUser } from '../../utils/commonSelectors'
import BrowserStorage from '../../utils/browserStorage'
import { getUserId } from '../../utils/utils'
import {
  MAIL_PATH_KEY,
  SUBMENU_DELETED_INBOX_EMAILS_KEY,
  SUBMENU_DELETED_SENT_EMAILS_KEY,
} from '../../components/Menu/Menu.constants'

type Props = {
  inboxCounter: Array<Object>,
  location: Object,
  match: Object,
  replace: string => void,
  sentCounter: Array<Object>,
  t: string => string,
}

class MailSection extends Component<Props> {
  componentWillUnmount = () => {
    const userId = getUserId(this.props.user)
    let inbox = BrowserStorage.get(`${userId}_/${MAIL_PATH_KEY}/inbox/`)
    let sent = BrowserStorage.get(`${userId}_/${MAIL_PATH_KEY}/sent/`)

    if (inbox?.includes(SUBMENU_DELETED_INBOX_EMAILS_KEY)) {
      BrowserStorage.remove(`${userId}_/${MAIL_PATH_KEY}/inbox/`)
    }

    if (sent?.includes(SUBMENU_DELETED_SENT_EMAILS_KEY)) {
      BrowserStorage.remove(`${userId}_/${MAIL_PATH_KEY}/sent/`)
    }
  }

  renderTabs = () => (
    <RouterTabs>
      <Pan
        id='inbox_tab'
        label={this.props.t('InboxTabTitle')}
        linkHash='#inbox'
      >
        <ThreadsList folder='inbox' key='inbox' />
      </Pan>
      <Pan id='sent_tab' label={this.props.t('SentTabTitle')} linkHash='#sent'>
        <ThreadsList folder='sent' key='sent' />
      </Pan>
    </RouterTabs>
  )

  render() {
    const {
      match: {
        params: { search },
      },
      t,
    } = this.props

    return (
      <div className='content__col'>
        <SimpleBlock>
          <Header header className='mail-section__title'>
            {t(search ? 'SearchResults' : 'MailSectionTitle')}
          </Header>
          {search ? <ThreadsList isSearch query={search} /> : this.renderTabs()}
        </SimpleBlock>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  inboxCounter: state.counters.inbox_emails,
  sentCounter: state.counters.sent_emails,
  location: getLocation(state),
  user: getUser(state),
})

export default compose(
  withTranslation('Mail'),
  connect(mapStateToProps, { replace })
)(MailSection)
