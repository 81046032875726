// @flow

import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'

import NewSelectSimple from '../NewSelectSimple'

type Props = {
  error?: ?boolean,
  exclude?: Array<string>,
  include?: Array<string>,
  items: Array<Object>,
  onBlur?: () => void,
  onChange: Object => void,
  onInputChange?: string => void,
  placeholder: string,
  t: string => string,
  value: ?string,
}

const SelectVariable = (props: Props) => {
  const {
    error,
    exclude,
    include,
    placeholder,
    onBlur,
    onChange,
    onInputChange,
    items,
    value,
    t,
    clearable,
  } = props

  let options = items

  if (exclude) {
    options = options.filter(o => !exclude.includes(o.type))
  }

  if (include) {
    options = options.filter(o => include.includes(o.type))
  }

  options = options.map(opt => ({ value: opt.name, label: opt.name }))

  if (clearable) {
    options = [
      {
        value: null,
        label: t('UnbindVariable'),
      },
    ].concat(options)
  }

  const selected = options.find(opt => opt.value === value) || null

  return (
    <NewSelectSimple
      disabled={!options.length}
      filtering={!!onInputChange}
      inputError={error}
      options={options}
      placeholder={options.length ? placeholder : t('noVariables')}
      value={selected}
      onBlur={onBlur}
      onBlurResetsInput={false}
      onChange={onChange}
      onInputChange={onInputChange}
    />
  )
}

export default compose(
  withTranslation('RequestConstructor'),
  connect(state => ({
    items: state.requestConstructorCards.variables,
  }))
)(SelectVariable)
