// @flow

import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import type { Node } from 'react'

import { useOverflow } from '../../../hooks/useOverflow'
import Button from '../../Button'
import Modal from '../../Modal'
import ModalBody from '../../Modal/ModalBody'
import ModalButtons from '../../Modal/ModalButtons'
import ModalCloseButton from '../../Modal/ModalCloseButton'
import ModalHead from '../../Modal/ModalHead'
import ModalRow from '../../Modal/ModalRow/ModalRow'
import InputField from '../../InputField/InputField'
import { useTranslation } from 'react-i18next'
import ModalCell from '../../Modal/ModalCell'
import ModalLabel from '../../Modal/ModalLabel'

type Props = {
  initialValues?: Object,
  onClose: Function,
  titleLabel: string,
  titleModal: string,
  translationSection: string,
  updateContract: Function,
}

const ContractPopup = (props: Props): Node => {
  const {
    initialValues,
    translationSection,
    updateContract,
    titleModal,
    titleLabel,
    onClose,
  } = props

  const { t } = useTranslation(translationSection)

  const [working, setWorking] = useState(false)

  useOverflow()

  const handleUpdate = async contract => {
    setWorking(true)

    await updateContract(contract)

    onClose()
  }

  const {
    values: { contract_id, duration, purchase_contract_number },
    dirty,
    handleReset,
    handleSubmit,
    handleChange,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({
      contract_id: Yup.string().max(256, t('MaxContractIdLength')),
      duration: Yup.number().nullable(),
    }),
    onSubmit: values => {
      if (!duration && duration !== undefined) {
        values.duration = null
      }

      handleUpdate(values)
    },
  })

  const handleClose = e => {
    if (dirty) {
      handleReset(e)
    } else {
      onClose(e)
    }
  }

  return (
    <Modal isOpen contentLabel=''>
      <ModalCloseButton onClose={props.onClose} />
      <ModalHead title={titleModal} />
      <form onSubmit={handleSubmit}>
        <ModalBody>
          <ModalRow>
            <ModalCell>
              <ModalLabel>{titleLabel}</ModalLabel>
            </ModalCell>
            <ModalCell gridNumber={2}>
              {contract_id !== undefined && (
                <InputField
                  autoFocus
                  name='contract_id'
                  value={contract_id}
                  type='text'
                  onChange={handleChange}
                />
              )}

              {purchase_contract_number !== undefined && (
                <InputField
                  autoFocus
                  name='purchase_contract_number'
                  value={purchase_contract_number}
                  type='text'
                  onChange={handleChange}
                />
              )}

              {duration !== undefined && (
                <InputField
                  autoFocus
                  name='duration'
                  value={duration}
                  type='number'
                  onChange={e => {
                    if (e.target.value.length === 5) {
                      return false
                    }

                    handleChange(e)
                  }}
                />
              )}
            </ModalCell>
          </ModalRow>
        </ModalBody>
        <ModalButtons>
          <Button.Save disabled={working || !dirty} type='submit'>
            {t('Common:Save')}
          </Button.Save>
          <Button.Cancel disabled={working} onClick={handleClose}>
            {t('Common:Cancel')}
          </Button.Cancel>
        </ModalButtons>
      </form>
    </Modal>
  )
}

export default ContractPopup
