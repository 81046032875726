// @flow

import { replace } from 'connected-react-router'
import { all, put, call, fork, takeEvery } from 'redux-saga/effects'

import { SERVER_404_ERROR } from '../../components/Layout/Layout.constants'
import api from '../../core/api'
import * as actions from './FlatView.actionTypes'
import { serverError, modalError } from '../../components/Layout/Layout.actions'

const getFilesIds = files => files.map(f => f.id)

function* load(action) {
  try {
    const data = yield call(api.flat.getFlat, {
      id: action.id,
    })
    yield put({ type: actions.FLAT_VIEW_INITIATED, data })
  } catch (error) {
    const action = serverError(error)

    if (action.type === SERVER_404_ERROR) {
      yield put(replace('/404'))
    } else {
      yield put(action)
    }
  }
}

function* update(action) {
  try {
    const { id, params } = action
    const data = yield call(api.flat.updateFlat, id, params)
    yield put({ type: actions.FLAT_VIEW_UPDATED, data })
  } catch (error) {
    yield put({ type: actions.FLAT_VIEW_ERROR, error })

    if (error.message.response.status === 400) {
      yield put(modalError(error.message.response.data))

      return
    }

    const action = serverError(error)

    if (action.type === SERVER_404_ERROR) {
      yield put(replace('/404'))
    } else {
      yield put(action)
    }
  }
}

function* updateGallery(action) {
  try {
    const { files, id } = action

    const ids = getFilesIds(files)
    const params = { files: JSON.stringify(ids) }
    const data = yield call(api.flat.updateFlat, id, params)
    yield put({ type: actions.FLAT_VIEW_UPDATED, data })
  } catch (error) {
    if (error.message.response.status === 400) {
      yield put(modalError(error.message.response.data))

      return
    }

    yield put(serverError(error))
  }
}

function* watchLoad() {
  yield takeEvery(actions.FLAT_VIEW_INITIATING, load)
}

function* watchUpdate() {
  yield takeEvery(actions.FLAT_VIEW_UPDATE, update)
}

function* watchUpdateGallery() {
  yield takeEvery(actions.FLAT_VIEW_UPDATE_GALLERY, updateGallery)
}

export default function* watch() {
  yield all([fork(watchLoad), fork(watchUpdate), fork(watchUpdateGallery)])
}
