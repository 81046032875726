// @flow

import React from 'react'
import type { Node } from 'react'

import UserExternalOutgoingEmail from './OutgoingEmail'
import UserExternalIncomingEmail from './IncomingEmail'

type Props = { isPrivate?: boolean }

const EmailIntegrationCreate = (props: Props): Node => {
  const { isPrivate } = props

  return (
    <>
      <UserExternalIncomingEmail isPrivate={isPrivate} />
      <UserExternalOutgoingEmail isPrivate={isPrivate} />
    </>
  )
}

export default EmailIntegrationCreate
