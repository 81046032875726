// @flow

import React, { Component } from 'react'
import type { Node } from 'react'
import { DropTarget } from 'react-dnd'
import { withTranslation } from 'react-i18next'
import { last } from 'lodash-es'
import { compose } from 'redux'

import Block, { DragItemType } from './GroupBlock'
import IconAdd from '../../../static/icons/add-thin.svg'

type Props = {
  active: ?Object,
  connectDropTarget: Node => Node,
  group: number,
  isMoving: boolean,
  items: Array<Object>,
  onCreate: number => void,
  onEndDrag: Object => void,
  onMove: (Object, number) => void,
  onSelect: Object => void,
  onStartDrag: () => void,
  t: string => string,
}

const dropTarget = {
  hover(props: Props, monitor) {
    const { onMove } = props
    onMove(monitor.getItem(), 0)
  },
}

class RequestConstructorGroupBlocks extends Component<Props> {
  componentDidUpdate(prevProps) {
    const { isMoving, items, group } = prevProps

    if (!isMoving && this.props.items.length > items.length) {
      const { id } = last(this.props.items)

      this.props.onSelect({
        id,
        group,
        position: this.props.items.length,
        justCreated: true,
      })
    }
  }

  renderBlock = ({ id, title }, index) => {
    const { active, onSelect, onMove, onStartDrag, onEndDrag, group, t } =
      this.props

    return (
      <Block
        active={active && active.id === id}
        group={group}
        id={id}
        index={index}
        key={id}
        title={title || t('emptyBlockTitle')}
        onClick={onSelect}
        onEndDrag={onEndDrag}
        onMove={onMove}
        onStartDrag={onStartDrag}
      />
    )
  }

  renderAdd = () => {
    const { group, onCreate } = this.props

    return (
      <button className='button' type='button' onClick={() => onCreate(group)}>
        <IconAdd />
      </button>
    )
  }

  render() {
    const { items, connectDropTarget } = this.props

    if (items.length) {
      return (
        <div className='request-constructor__group__blocks'>
          {items.map(this.renderBlock)}
          {this.renderAdd()}
        </div>
      )
    }

    return connectDropTarget(
      <div className='request-constructor__group__blocks'>
        {this.renderAdd()}
      </div>
    )
  }
}

export default compose(
  withTranslation('RequestConstructor'),
  DropTarget(DragItemType, dropTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isMoving: monitor.canDrop(),
  }))
)(RequestConstructorGroupBlocks)
