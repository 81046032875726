import React from 'react'
import PropTypes from 'prop-types'

import { FileIcon } from '../../Icon'

import {
  isMediaFile,
  isImageFile,
  isVideoFile,
  getFileType,
  VIDEO_TYPE,
} from '../../../utils/file'
import { useThumbnail } from '../../../hooks'

const TINY_THUMBNAIL_WIDTH = 182
const SMALL_THUMBNAIL_WIDTH = 340

const propTypes = {
  file: PropTypes.object,
  isInboxAttachment: PropTypes.bool,
  onClick: PropTypes.func,
}

const MessagesFileAttachment = props => {
  const { file, size } = props

  let fileThumbnail
  const thumbnail = useThumbnail(file, size, getFileType(file) === VIDEO_TYPE)

  const thumbnailWidth = TINY_THUMBNAIL_WIDTH

  if (file.thumbnails[thumbnailWidth] && isMediaFile(file)) {
    fileThumbnail = (
      <img
        className='inbox__attach'
        src={file.thumbnails[thumbnailWidth] || file.origin}
        alt={file.filename}
        onClick={props.onClick}
      />
    )
  } else {
    fileThumbnail = (
      <FileIcon className='inbox__attach' file={file} onClick={props.onClick} />
    )

    // TODO remove later when websockets are ready
    if (isImageFile(file)) {
      fileThumbnail = (
        <img
          className='inbox__attach'
          src={file.origin}
          alt={file.filename}
          onClick={props.onClick}
        />
      )
    }

    if (isVideoFile(file) && !file.thumbnails[SMALL_THUMBNAIL_WIDTH]) {
      fileThumbnail = (
        <img
          className='inbox__attach'
          src={thumbnail}
          alt={file.filename}
          onClick={props.onClick}
        />
      )
    }
  }

  return fileThumbnail
}

MessagesFileAttachment.propTypes = propTypes

export default MessagesFileAttachment
