// @flow

import type { Node } from 'react'

import {
  MAIL_LIST_RESET,
  MAIL_LIST_INITIATING,
  MAIL_LIST_INITIATED,
  MAIL_LIST_ERROR,
  MAIL_GET_FILES_INITIATING,
  MAIL_GET_FILES_INITIATED,
  MAIL_GET_FILES_ERROR,
  TOGGLE_ALL,
  TOGGLE,
  MODAL,
  DELETE,
  RESTORE,
  MARK_AS_READ,
  MARK_AS_UNREAD,
  CHANGE_NOTES,
  ADD_STAR,
  REMOVE_STAR,
} from './ThreadsList.actionTypes'

type Mail = {
  title: ?string,
  users: Array<number>,
  uuid: string,
}
type MailListResetAction = { type: string }
type MailListInitiatingAction = { type: string }
type MailListInitiatedAction = { mailList: Array<Mail>, type: string }
type MailListErrorAction = { error: string, type: string }

type Meta = {
  count: number,
  curr_page: number,
  limit: number,
  offset: number,
  page_count: number,
}

export function mailListReset(): MailListResetAction {
  return {
    type: MAIL_LIST_RESET,
  }
}

export function mailListInitiating(params: Object): MailListInitiatingAction {
  return {
    type: MAIL_LIST_INITIATING,
    params,
  }
}

export function mailListInitiated(
  mailList: Array<Mail>,
  meta: Meta,
  cantCreateMessage: boolean,
  folder: 'inbox' | 'sent',
  permissions: { canCreate: boolean, canDelete: boolean, canRestore: boolean }
): MailListInitiatedAction {
  return {
    type: MAIL_LIST_INITIATED,
    mailList,
    meta,
    cantCreateMessage,
    folder,
    permissions,
  }
}

export function mailListError(error: string): MailListErrorAction {
  return {
    type: MAIL_LIST_ERROR,
    error,
  }
}

// ============ Get files from gallery associated with chatroom ==============

type ChatroomsGetFilesInitiatingAction = {
  fileIdToBeShown: number,
  galleryId: number,
  type: string,
}

export function chatroomsGetFilesInitiating(
  fileIdToBeShown,
  uuid
): ChatroomsGetFilesInitiatingAction {
  return {
    type: MAIL_GET_FILES_INITIATING,
    fileIdToBeShown,
    uuid,
  }
}

type File = {
  created: string,
  filename: ?string,
  id: number,
  mime_type: string,
  origin: string,
  owner: number,
  thumbnail: ?string,
  thumbnails: ?{
    130: string,
    170: string,
    698: string,
  },
}
type ChatroomsGetFilesInitiatedAction = {
  fileIdToBeShown: number,
  files: Array<File>,
  meta: Meta,
  type: string,
}

export function chatroomsGetFilesInitiated(
  fileIdToBeShown: number,
  files: Array<File>,
  meta: Meta
): ChatroomsGetFilesInitiatedAction {
  return {
    type: MAIL_GET_FILES_INITIATED,
    fileIdToBeShown,
    files,
    meta,
  }
}

type ChatroomsGetFilesErrorAction = { error: string, type: string }

export function chatroomsGetFilesError(
  chatrooms: Array<Mail>,
  error: string
): ChatroomsGetFilesErrorAction {
  return {
    type: MAIL_GET_FILES_ERROR,
    error,
  }
}

export function showModal(modal: ?Node) {
  return {
    type: MODAL,
    modal,
  }
}

export function toggleAll() {
  return {
    type: TOGGLE_ALL,
  }
}

export function toggle(id) {
  return {
    type: TOGGLE,
    id,
  }
}

export function deleteThreads(ids, params) {
  return {
    type: DELETE,
    ids,
    params,
    patch: { soft_archived: true },
  }
}

export function restoreThreads(ids, params) {
  return {
    type: RESTORE,
    ids,
    params,
    patch: { soft_archived: false },
  }
}

export function markAsRead(ids, params) {
  return {
    type: MARK_AS_READ,
    ids,
    params,
    patch: { read: true },
  }
}

export function markAsUnread(ids, params) {
  return {
    type: MARK_AS_UNREAD,
    ids,
    params,
    patch: { unread: true },
  }
}

export function addStar(ids, params) {
  return {
    type: ADD_STAR,
    ids,
    params,
    patch: { starred: true },
  }
}

export function removeStar(ids, params) {
  return {
    type: REMOVE_STAR,
    ids,
    params,
    patch: { starred: false },
  }
}

export function changeNotesAction(uuid, notes) {
  return {
    type: CHANGE_NOTES,
    uuid,
    notes,
  }
}
