import { omitBy } from 'lodash-es'
import * as actions from './Like.actionTypes'

const initialState = {
  data: {},
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.LIKE_WAS_UPDATED:
      return {
        ...state,
        data: {
          ...state.data,
          [action.uuid]: action.active
        }
      }
    case actions.LIKE_RESET:
      return {
        ...state,
        data: omitBy(state.data, (v, k) => k === action.uuid)
      }
    case actions.LIKE_ERROR:
      return {
        ...state,
        error: action.error.message
      }
    default:
      return state
  }
}
