// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'
import Stickyfill from 'stickyfilljs'
import { get } from 'lodash-es'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'

import WidgetAddEntities from '../../components/widgets/WidgetAddEntities'
import * as actions from '../../containers/NewsList/NewsList.actions'
import { isMenuCollapsed } from '../../utils/commonSelectors'
import Widgets from './widgets'
import { USER_STATUS, BLOCKED_STATUS } from '../../constants'

type Props = {
  blocking: boolean,
  collapsed: boolean,
  newsListBlockPostInitiating: Object => Object,
  newsListBlockUserInitiating: Object => Object,
  post: {
    owner_obj: {
      id: number,
      status: number,
    },
    spam: boolean,
    uuid: string,
  },
}

class PostWidgets extends Component<Props> {
  componentDidMount() {
    const elements = document.querySelectorAll('.widget__container--content')
    Stickyfill.add(elements)
  }

  blockPost = () => {
    const {
      post: { spam: isUnblock, uuid: postUuid },
    } = this.props

    this.props.newsListBlockPostInitiating({
      postUuid,
      isUnblock,
    })
  }

  blockUser = () => {
    const {
      post: {
        uuid: postUuid,
        owner_obj: { id: userId, status },
      },
      blocking,
    } = this.props

    if (blocking) {
      return
    }

    this.props.newsListBlockUserInitiating({
      postUuid,
      userId,
      isUnblock: USER_STATUS[status] === BLOCKED_STATUS,
    })
  }

  render() {
    const { post, collapsed } = this.props

    const mapping = {
      BlockPost: 'can_block',
      UnblockPost: 'can_block',
      BlockOwner: 'can_block_owner',
      UnblockOwner: 'can_unblock_owner',
    }

    const permissions = get(post, 'permissions') || {}
    const menuItems = [
      {
        icon: 'bin',
        key: post.spam ? 'UnblockPost' : 'BlockPost',
        callback: this.blockPost,
      },
      {
        icon: 'lock',
        key: 'BlockOwner',
        callback: this.blockUser,
      },
      {
        icon: 'lock',
        key: 'UnblockOwner',
        callback: this.blockUser,
      },
    ].filter(item => permissions[mapping[item.key]])

    return (
      <Widgets collapsed={collapsed}>
        {!!menuItems.length && (
          <WidgetAddEntities
            menuItems={menuItems}
            title={this.props.t('Actions')}
          />
        )}
      </Widgets>
    )
  }
}

const mapStateToProps = state => ({
  post: state.postView.postData,
  blocking: state.postView.blocking,
  collapsed: isMenuCollapsed(state),
})

const mapDispatchToProps = {
  newsListBlockPostInitiating: actions.newsListBlockPostInitiating,
  newsListBlockUserInitiating: actions.newsListBlockUserInitiating,
}

export default compose(
  withTranslation('Request'),
  connect(mapStateToProps, mapDispatchToProps)
)(PostWidgets)
