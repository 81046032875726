import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { captureException } from '@sentry/browser'

const MarketplaceError = ({ error }) => {
  const { t } = useTranslation('Errors')

  useEffect(() => {
    captureException(JSON.stringify(error))
  }, [])

  // TODO fix according to design (ha-ha)
  return <h1>{t('Marketplace')}</h1>
}

export default MarketplaceError
