// @flow

import React, { useState, useEffect } from 'react'
import OutsideClick from 'react-onclickout'
import { useTranslation } from 'react-i18next'

import { getTemplates } from '../core/api/api.template'
import { getTemplates as getPostTemplates } from '../core/api/api.postTemplate'
import Loader from './Loader'
import Scrollbar from './Scrollbar'
import TemplateDropdownItem from './TemplateDropdownItem'

const TemplateDropdown = props => {
  const {
    isRequest,
    isPost,
    isFeedback,
    isProvider,
    isEmail,
    customStyle,
    isManager,
    isPostTemplate,
    isRequestThread,
    permanentParams,
  } = props

  const [templates, setTemplates] = useState([])
  const [loading, setLoading] = useState(true)

  const { t } = useTranslation('Templates')

  const params = { ordering: 'name', hidden: false, page_size: 1000000 }

  if (isRequestThread) {
    params.tag = 'requestthread_email'
  } else if (isPost) {
    params.tag = 'post_comment'
  } else if (isFeedback) {
    params.tag = 'request_feedback'
  } else if (isProvider) {
    params.tag = 'provider_email'
  } else if (isManager) {
    params.tag = 'manager_email'
  } else if (isRequest) {
    params.tag = 'request_comment'
  } else if (isEmail) {
    params.tag = 'email'
  } else {
    if (!isPostTemplate) {
      params.tag = 'chat'
    }
  }

  const style = customStyle || {
    right: '0',
    top: '2.5rem',
  }

  let api = getTemplates

  if (isPostTemplate) {
    api = getPostTemplates
  }

  useEffect(() => {
    api({ ...params, ...(permanentParams || {}) })
      .then(data => setTemplates(data.objects))
      .finally(() => setLoading(false))
  }, [])

  const hasPrivate = !!(templates.length && templates[0].private)

  return (
    <OutsideClick onClickOut={props.closeDropdown}>
      <div
        className='dropdown dropdown--default -dropdown--up-right dropdown--open'
        style={style}
      >
        {loading ? (
          <Loader text={false} type='small' />
        ) : templates.length ? (
          <Scrollbar
            items={templates}
            listElements={templates.map(template => (
              <TemplateDropdownItem
                hasPrivate={hasPrivate}
                key={template.id}
                template={template}
                handleSelect={props.handleSelect}
              />
            ))}
            listClassName='dropdown__list dropdown__list--default dropdown__list--checklist'
            pageSize={3}
          />
        ) : (
          <li
            className='dropdown__list-item'
            style={{ borderTop: '1px solid #d0d0d0' }}
          >
            <span className='dropdown__list-item-text'>
              {t('TemplatesNotFoundInContext')}
            </span>
          </li>
        )}
      </div>
    </OutsideClick>
  )
}

export default TemplateDropdown
