// @flow

import { put, call, fork, takeEvery } from 'redux-saga/effects'

import api from '../../core/api'
import { serverError } from '../../components/Layout/Layout.actions'
import * as actions from './ConfirmEmail.actionTypes'

function* watchConfirm() {
  yield takeEvery(actions.CONFIRM_EMAIL_SEND, confirm)
}

function* confirm({ hash }) {
  try {
    const result = yield call(api.system.confirmEmail, hash)

    if (result.error) {
      yield put(serverError(result.error))

      yield put({ type: actions.CONFIRM_EMAIL_ERROR, ...result })
    } else {
      yield put({ type: actions.CONFIRM_EMAIL_UPDATE, ...result })
    }
  } catch (error) {
    yield put(serverError(error))

    yield put({ type: actions.CONFIRM_EMAIL_ERROR, detail: error.message })
  }
}

export default function* watch() {
  yield fork(watchConfirm)
}
