// @flow

import React, { useContext } from 'react'
import type { Node } from 'react'

import InputField from '../../../../InputField'
import ModalLabel from '../../../../Modal/ModalLabel'
import ModalRow from '../../../../Modal/ModalRow'
import CardStageContext from '../../CardStage.context'
import ModalError from '../../../../Modal/ModalError'
import type { CardType } from '../../../NewWizard.types'

type Props = {| card: CardType |}

const Input = (props: Props): Node => {
  const {
    card: { label, name },
  } = props

  const { errors, values, handleChange } = useContext(CardStageContext)

  return (
    <>
      <ModalRow>
        <ModalLabel>{label}</ModalLabel>
      </ModalRow>
      <ModalRow>
        <InputField
          showErrorText={false}
          name={name}
          value={values[name]}
          error={errors[name]}
          onChange={handleChange}
        />
      </ModalRow>
      {errors[name] && <ModalError error={errors[name]} />}
    </>
  )
}

export default Input
