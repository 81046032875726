// @flow

import React, { useState, useEffect } from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import classnames from 'classnames'
import { compose } from 'redux'
import { connect } from 'react-redux'

import Button from '../Button'
import Option from './Option'
import { getOption, isDefaultOption } from './ArchivedSettings.utils'
import { SETTINGS_CORPORATE_SAVE_CONFIG } from '../SettingsCorporate/SettingsCorporate.actionTypes'
import NewSelectSimple from '../NewSelectSimple'

const ARCHIVED_OPTION_BUILDING = ' displayArchivedUsersInBuilding'
const ARCHIVED_OPTION_FLAT = 'displayArchivedUsersInFlat'

const ArchivedSettings = props => {
  const {
    ukConfig: {
      display_archived_users_in_building: displayArchivedUsersInBuilding,
      display_archived_users_in_flat: displayArchivedUsersInFlat,
    },
  } = props

  const { t } = useTranslation('Settings')

  const options = [
    { value: false, label: t('DisableFreeRegistration') },
    { value: true, label: t('EnableFreeRegistration') },
  ]

  const defaultArchivedBuildingOption = getOption(
    options,
    displayArchivedUsersInBuilding
  )
  const defaultArchivedFlatOption = getOption(
    options,
    displayArchivedUsersInFlat
  )

  const [archivedBuildingOption, setArchivedBuildingOption] = useState(
    defaultArchivedBuildingOption
  )
  const [archivedFlatOption, setArchivedFlatOption] = useState(
    defaultArchivedFlatOption
  )

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const { value: prevArchivedBuilding } = archivedBuildingOption
    const { value: prevArchivedFlat } = archivedFlatOption

    if (displayArchivedUsersInBuilding !== prevArchivedBuilding) {
      setArchivedBuildingOption(
        getOption(options, displayArchivedUsersInBuilding)
      )
    }

    if (displayArchivedUsersInFlat !== prevArchivedFlat) {
      setArchivedFlatOption(getOption(options, displayArchivedUsersInFlat))
    }

    setLoading(false)
  }, [displayArchivedUsersInBuilding, displayArchivedUsersInFlat])

  const handleChange = label => option => {
    switch (label) {
      case ARCHIVED_OPTION_BUILDING:
        setArchivedBuildingOption(option)
        break
      case ARCHIVED_OPTION_FLAT:
        setArchivedFlatOption(option)
        break
      default:
        return null
    }
  }

  const handleSave = () => {
    setLoading(true)

    props.dispatch({
      type: SETTINGS_CORPORATE_SAVE_CONFIG,
      params: {
        display_archived_users_in_building: archivedBuildingOption.value,
        display_archived_users_in_flat: archivedFlatOption.value,
      },
    })
  }

  const handleCancel = () => {
    setArchivedBuildingOption(defaultArchivedBuildingOption)
    setArchivedFlatOption(defaultArchivedFlatOption)
  }

  const sectionClass = classnames(
    'settings-personal unit unit--default settings-regional',
    {
      'working-overlay': loading,
    }
  )

  const submitSectionClass = classnames('settings-personal__submit', {
    'working-overlay': loading,
  })

  const isDefaultOptions = () =>
    isDefaultOption(defaultArchivedBuildingOption, archivedBuildingOption) &&
    isDefaultOption(defaultArchivedFlatOption, archivedFlatOption)

  return (
    <div>
      <section className={sectionClass}>
        <h2 className='unit__title'>{t('ArchivedSectionTitle')}</h2>

        <Option label={t('DisplayArchivedUsersBuilding')}>
          <NewSelectSimple
            name='display-archived-building'
            value={archivedBuildingOption}
            options={options}
            onChange={handleChange(ARCHIVED_OPTION_BUILDING)}
          />
        </Option>

        <Option label={t('DisplayArchivedUsersFlat')}>
          <NewSelectSimple
            name='display-archived-flat'
            value={archivedFlatOption}
            options={options}
            onChange={handleChange(ARCHIVED_OPTION_FLAT)}
          />
        </Option>

        <div className={submitSectionClass}>
          <Button.Save
            type='button'
            disabled={!loading && isDefaultOptions()}
            onClick={handleSave}
          >
            {props.t('Common:Save')}
          </Button.Save>
          <Button.Cancel
            type='button'
            disabled={loading || isDefaultOptions()}
            onClick={handleCancel}
          >
            {props.t('Common:Cancel')}
          </Button.Cancel>
        </div>
      </section>
    </div>
  )
}

export default compose(connect(), withTranslation('Settings'))(ArchivedSettings)
