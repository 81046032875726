// @flow

import DocumentPreview from '../MappedDocument/DocumentPreview'
import { PDF_TYPE } from '../../utils/file'
import React, { useEffect, useState, useRef } from 'react'
import type Node from 'react'

import api from '../../core/api'
import Loader from '../Loader'
import styles from './LightboxGallery.module.scss'
type Props = {
  fileType?: string,
  placeholder: string,
}

const POLLING_INTERVAL = 500

const DocFilePreview = (props: Props): Node => {
  const { fileId, pdf } = props

  const [pdfFile, setPdfFile] = useState(null)
  const [loader, setLoader] = useState(true)

  const [height, setHeight] = useState(0)

  const timerRef = useRef()

  useEffect(() => {
    setHeight(Math.floor(document.body.offsetHeight * 0.8))
  }, [])

  useEffect(() => {
    getFile()

    return () => {
      clearTimeout(timerRef.current)
    }
  }, [])

  const getFile = () => {
    setLoader(true)
    api.file.getById(fileId).then(data => {
      if (data.checking) {
        timerRef.current = setTimeout(getFile, POLLING_INTERVAL)
      } else {
        setPdfFile(data)
        setLoader(false)
      }
    })
  }
  const srcString = `https://docs.google.com/gview?url=${encodeURIComponent(
    pdfFile
  )}&embedded=true`

  return (
    <>
      {loader ? (
        <Loader className={styles.loader} text={false} type='big' />
      ) : pdf ? (
        <DocumentPreview
          fileOrigin={pdfFile}
          fileType={PDF_TYPE}
          height={height}
          outerWidth={750}
          width={350}
        />
      ) : (
        <iframe
          src={srcString}
          width='750px'
          height='550px'
          frameBorder='0'
        ></iframe>
      )}
    </>
  )
}

export default DocFilePreview
