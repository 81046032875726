// @flow

import React from 'react'
import classnames from 'classnames'
import type { Node } from 'react'

import { LIST_PADDING } from './List.constants'

import styles from './List.module.scss'

type Props = {
  children: Node,
  className?: string,
  embedded: boolean,
  style?: Object,
  styleType: string,
}

const List = (props: Props): Node => {
  const {
    children,
    forwardRef,
    className,
    styleType,
    embedded,
    onMouseMove,
    style,
  } = props

  const listClass = classnames(styles.list, className, styles[styleType], {
    [styles.embedded]: embedded,
  })

  const listStyle = Object.assign(
    {},
    { padding: `${LIST_PADDING}px 0` },
    style ? { ...style } : undefined
  )

  return (
    <ul
      ref={forwardRef}
      className={listClass}
      style={listStyle}
      onMouseMove={onMouseMove}
    >
      {children}
    </ul>
  )
}

List.defaultProps = { styleType: 'shaded', embedded: false }

export default React.forwardRef((props, ref) => (
  <List {...props} forwardRef={ref} />
))
