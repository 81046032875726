// @flow

import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { createFragmentContainer, graphql } from 'react-relay'
import { useTranslation } from 'react-i18next'
import { push } from 'connected-react-router'
import { map } from 'lodash-es'
import type { Node } from 'react'

import Checkbox from '../../components/Checkbox'
import { getCurrentPage } from '../../utils/routing'
import BottomPagination from '../../components/Pagination/BottomPagination'
import EmptyList from '../../components/EmptyList'
import { getUser } from '../../utils/commonSelectors'
import Content from '../../components/Content'
import NewTable from '../../components/NewTable'
import NewTableBody from '../../components/NewTable/NewTableBody'
import OrdersTableBodyRow from './OrdersTableBodyRow'
import OrdersTableHeader from './OrdersTableHeader'
import { MARKETPLACE_ROUTES } from '../../constants'
import OrdersStatusModal from './OrdersStatusModal'
import { isStaffUser, isAdminUser } from '../../utils/utils'
import { useSelected } from '../../hooks'
import Button from '../../components/Button'

const OrdersTable = (props): Node => {
  const {
    query: {
      allCustomerOrders: { edges: list, totalCount },
    },
  } = props

  const location = useLocation()
  const dispatch = useDispatch()

  const { t } = useTranslation('Orders')

  const [selected, changeSelected, setSelected, isAllSelected] = useSelected()
  const [singleSelected, setSingleSelected] = useState(null)
  const [isModalOpen, setModalOpen] = useState(false)

  const user = useSelector(getUser)

  useEffect(() => {
    if (singleSelected) {
      setModalOpen(true)
    }
  }, [singleSelected])

  useEffect(() => {
    props.setCount && props.setCount(totalCount)
  }, [totalCount])

  const {
    language_obj: { code },
  } = user

  const isStaff = isStaffUser(user)
  const isAdmin = isAdminUser(user)

  const page = getCurrentPage(location)

  const handleClick = id => () => {
    dispatch(push(MARKETPLACE_ROUTES['marketplaceOrder'](id)))
  }

  const handleChangeStatus = order => e => {
    e.stopPropagation()

    setSingleSelected(order)
  }

  const handleCloseModal = needReset => {
    setModalOpen(false)

    if (selected.length) {
      setSelected([])
    }

    if (singleSelected) {
      setSingleSelected(null)
    }

    if (needReset) {
      dispatch(push(`${MARKETPLACE_ROUTES['marketplaceOrders']}/?page=1`))

      props.setKey()
    }
  }

  const toggleAll = () => {
    setSelected(map(list, ({ node }) => ({ id: node.id, status: node.status })))
  }

  const openMassRemoveModal = () => setModalOpen(true)

  const buttonText =
    selected.length > 1 ? 'ChangeOrdersStatuses' : 'ChangeOrderStatus'

  return (
    <Content>
      {isStaff && (
        <div className='toolbox'>
          <div className='toolbox__cell'>
            <Checkbox
              outer
              disabled={!list.length}
              checked={isAllSelected(
                map(list, ({ node }) => ({
                  id: node.id,
                  status: node.status,
                }))
              )}
              onChange={toggleAll}
            />
          </div>
          <div className='toolbox__cell'>
            <Button.Remove
              icon='status'
              disabled={!selected.length}
              onClick={openMassRemoveModal}
            >
              {t(buttonText)}
            </Button.Remove>
          </div>
        </div>
      )}
      <NewTable>
        <OrdersTableHeader isAdmin={isAdmin} />
        <NewTableBody>
          {list.map(({ node }) => (
            <OrdersTableBodyRow
              order={node}
              isAdmin={isAdmin}
              isStaff={isStaff}
              key={node.id}
              code={code}
              checked={selected.find(s => s.id === node.id)}
              changeSelected={changeSelected}
              onClick={handleClick(node.id)}
              onChangeStatus={handleChangeStatus({
                id: node.id,
                status: node.status,
              })}
            />
          ))}
        </NewTableBody>
      </NewTable>
      {!totalCount && <EmptyList icon='order' title={t('noOrdersFound')} />}
      <BottomPagination
        classes='paginator-2'
        meta={{
          curr_page: parseInt(page),
          page_count: Math.ceil(totalCount / 20),
        }}
      />
      {isModalOpen && (
        <OrdersStatusModal
          selectedOrders={singleSelected ? [singleSelected] : selected}
          onClose={handleCloseModal}
        />
      )}
    </Content>
  )
}

export default createFragmentContainer(OrdersTable, {
  query: graphql`
    fragment OrdersTable_query on Query
    @argumentDefinitions(
      pageSize: { type: Float }
      page: { type: Float }
      first: { type: Int }
      orderNo: { type: String }
      status: { type: String }
      providerName: { type: String }
      categoryId: { type: String }
      all: { type: Boolean }
    ) {
      allCustomerOrders(
        pageSize: $pageSize
        page: $page
        first: $first
        orderNo: $orderNo
        status: $status
        providerName: $providerName
        categoryId: $categoryId
        all: $all
      ) {
        edges {
          node {
            id
            clientInfo
            orderNo
            createdAt
            grandTotal
            status
            offer
            provider {
              name
              image {
                origin
              }
            }
          }
        }
        totalCount
      }
    }
  `,
})
