// @flow

import { flow, get, partialRight, reduce, sortBy, unionBy } from 'lodash-es'

import { RATING_TYPES } from '../../constants'
import { NEW_FEEDBACK_ID } from './Feedback'

export const getInitialState = props => {
  const extraRatings = getExtraRatings(props.data)

  return {
    text: props.data.text,
    general: get(props.generalRating, 'value'),
    uk: get(extraRatings, ['uk']),
    provider: get(extraRatings, ['provider']),
    files: props.data.file_objs || [],
    filesUploading: false,
    isChanged: false,
    isEditing: props.match.params.id === NEW_FEEDBACK_ID,
  }
}

export const getExtraRatings = data =>
  flow([
    partialRight(get, 'extra_ratings', []),
    partialRight(sortBy, 'id'),
    partialRight(unionBy, 'rating_type'),
    partialRight(
      reduce,
      (result, { rating_type: ratingType, value }) => ({
        ...result,
        ...(ratingType === RATING_TYPES['provider'] ? { provider: value } : {}),
        ...(ratingType === RATING_TYPES['uk'] ? { uk: value } : {}),
      }),
      {}
    ),
  ])(data)

export const getUpdatedExtraRating = (list, newRating) =>
  list.map(i => {
    if (i.rating_type === newRating.rating_type) {
      return newRating
    }

    return i
  })
