// @flow

import React from 'react'

import Icon, { FileIcon } from '../Icon'
import { FOLDER_TYPE } from './Files.constants'

type Props = {
  file: Object,
}

const FilesIcon = (props: Props) => {
  const {
    file,
    file: { type },
  } = props

  if (type === FOLDER_TYPE)
    return (
      <Icon
        id='folderWhite'
        style={{
          fill: '#ffd867',
          minWidth: '32px',
          width: '32px',
          height: '32px',
          marginLeft: '7px',
        }}
      />
    )

  return (
    <FileIcon
      file={file}
      style={{
        fill: '#000',
        minWidth: '32px',
        width: '32px',
        height: '32px',
        marginLeft: '7px',
      }}
    />
  )
}

export default FilesIcon
