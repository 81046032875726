// @flow

import React, { Component } from 'react'
import { push } from 'connected-react-router'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { map, difference } from 'lodash-es'
import type { Element } from 'react'
import { compose } from 'redux'

import { Header, SimpleBlock } from '../../components/Block'
import Button from '../../components/Button'
import EmptyList from '../../components/EmptyList'
import Lightbox from '../../components/LightboxGallery'
import Nbsp from '../../components/NBSP'
import TopPagination from '../../components/Pagination/TopPagination'
import BottomPagination from '../../components/Pagination/BottomPagination'
import Loader from '../../components/Loader'
import * as actions from './Messages.actions'
import { getLocation, getUser } from '../../utils/commonSelectors'
import { getCurrentFilters } from '../../utils/routing'
import addRedirectToFirstPage from '../../hocs/addRedirectToFirstPage'
import Message from '../../components/Message'
import Checkbox from '../../components/Checkbox'
import SelectCustom from '../../components/Select/SelectCustom'
import ConfirmationPopup from '../../components/modals/ConfirmationPopup'
import Modal from '../../components/Modal'

const INITIAL_ITEMS_COUNT = 20

type Props = {
  chatrooms: Array<Object>,
  fileIdToBeShown: number,
  files: Array<Object>,
  initiated: boolean,
  meta: Object,
  metaFiles: Object,
  push: string => void,
  startInitiatingChatrooms: () => void,
  startInitiatingGetFiles: () => void,
  startResetInitialState: () => void,
  t: string => string,
  user: Object,
  working: boolean,
}

type State = {
  lightboxIndex: ?number,
  modal: ?Element<'div'>,
  selected: Array<?string>,
}

class Messages extends Component<Props, State> {
  state = {
    lightboxIndex: null,
    modal: null,
    selected: [],
  }

  componentDidMount() {
    const { location } = this.props
    const filters = getCurrentFilters(location)

    if (filters.page) {
      this.props.startInitiatingChatrooms(filters)
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.state.lightboxIndex === null &&
      prevProps.files !== this.props.files
    ) {
      const fileIndex = this.props.files.findIndex(
        element => element.id === this.props.fileIdToBeShown
      )
      this.setState({
        lightboxIndex: fileIndex,
      })
    }

    if (prevProps.location.search !== this.props.location.search) {
      const { location } = this.props
      const filters = getCurrentFilters(location)

      this.props.startInitiatingChatrooms(filters)
    }

    if (prevProps.initiated !== this.props.initiated) {
      this.setState({ selected: [] })
    }
  }

  componentWillUnmount() {
    this.props.startResetInitialState()
  }

  create = () => {
    this.props.push('/chatroom/create')
  }

  hidePopup = () => {
    this.setState({ modal: null })
  }

  showModal = modal => {
    this.setState({ modal })
  }

  toggle = uuid => {
    const { selected } = this.state

    if (selected.includes(uuid)) {
      this.setState({ selected: difference(selected, [uuid]) })
    } else {
      this.setState({ selected: selected.concat([uuid]) })
    }
  }

  updateReadStatus = markAction => {
    const { location } = this.props
    const { selected } = this.state
    const filters = getCurrentFilters(location)

    markAction(selected, filters)
    this.setState({ modal: null })
  }

  markAsRead = () => {
    this.updateReadStatus(this.props.markAsRead)
  }

  markAsUnread = () => {
    this.updateReadStatus(this.props.markAsUnread)
  }

  handleToggleAll = () => {
    const { selected } = this.state
    const { chatrooms } = this.props

    if (selected.length !== chatrooms.length) {
      this.setState({ selected: map(chatrooms, chatroom => chatroom.uuid) })
    } else {
      this.setState({ selected: [] })
    }
  }

  showMarkAsReadModal = () => {
    const modal = (
      <ConfirmationPopup
        title={this.props.t('MarkAsRead')}
        confirm={this.props.t('MarkAsRead')}
        cancel={this.props.t('Cancel')}
        onClose={this.hidePopup}
        onOk={this.markAsRead}
      />
    )

    this.showModal(modal)
  }

  showMarkAsUnreadModal = () => {
    const modal = (
      <ConfirmationPopup
        title={this.props.t('MarkAsUnread')}
        confirm={this.props.t('MarkAsUnread')}
        cancel={this.props.t('Cancel')}
        onClose={this.hidePopup}
        onOk={this.markAsUnread}
      />
    )

    this.showModal(modal)
  }

  getMassActionOptions = () => {
    return [
      {
        value: 'selectWorker',
        label: this.props.t('MarkAsRead'),
        handler: () => this.showMarkAsReadModal(),
        icon: 'messageRead',
      },
      {
        value: 'selectStatus',
        label: this.props.t('MarkAsUnread'),
        handler: () => this.showMarkAsUnreadModal(),
        icon: 'messageUnread',
      },
    ]
  }

  render() {
    const { chatrooms, meta, files, working, initiated, user, permissions } =
      this.props

    const { modal, selected } = this.state
    const isEmpty = !working && !chatrooms.length
    const canCheck = user.group !== 'dweller'
    const { can_create: canCreate } = permissions

    return (
      <div className='content__col'>
        <SimpleBlock>
          <Header header>
            {this.props.t('Messages')}
            <Nbsp />
            <span className='inbox__02'>
              {chatrooms && chatrooms.length > 0 && meta.count}
            </span>
          </Header>

          {isEmpty ? (
            <EmptyList
              canAdd={canCreate}
              btnText={this.props.t('WriteAMessage')}
              icon='chat'
              title={this.props.t('NoChatroomsItems')}
              onClick={this.create}
            />
          ) : (
            <div>
              <div className='toolbox toolbox--chats'>
                {canCheck && initiated && (
                  <div className='toolbox__cell'>
                    <Checkbox
                      outer
                      checked={
                        selected.length === chatrooms.length && initiated
                      }
                      onChange={this.handleToggleAll}
                    />
                  </div>
                )}
                {canCheck && !!selected.length && (
                  <div className='toolbox__cell'>
                    <SelectCustom
                      style={{ marginRight: '-10px' }}
                      options={this.getMassActionOptions()}
                      onChange={opt => opt.handler()}
                    >
                      {this.props.t('Actions')}
                    </SelectCustom>
                  </div>
                )}
                {initiated && (
                  <div className='toolbox__cell'>
                    <a onClick={() => this.props.push('/chatroom/create')}>
                      <Button.Save icon='chat'>
                        {this.props.t('WriteAMessage')}
                      </Button.Save>
                    </a>
                  </div>
                )}
                {meta && meta.count > INITIAL_ITEMS_COUNT && (
                  <div className='toolbox__cell toolbox__cell--right'>
                    <TopPagination
                      classes='pager pager--large pager--default-2'
                      meta={meta}
                      basePage='messages'
                    />
                  </div>
                )}
              </div>

              <div className='inbox__container'>
                {chatrooms && !working ? (
                  chatrooms.map(chat => (
                    <Message
                      canCheck={canCheck}
                      chat={chat}
                      key={chat.uuid}
                      selected={selected}
                      toggle={this.toggle}
                      viewFile={this.props.startInitiatingGetFiles}
                    />
                  ))
                ) : (
                  <Loader text={false} type='big' />
                )}
              </div>

              {!working && meta && meta.count > INITIAL_ITEMS_COUNT && (
                <BottomPagination
                  classes='paginator paginator--middle'
                  meta={meta}
                  basePage='messages'
                />
              )}

              <Lightbox
                newGallery
                activeIndex={this.state.lightboxIndex}
                images={files}
                scope='chat'
                onClose={() => this.setState({ lightboxIndex: null })}
              />
            </div>
          )}
          <Modal
            className='Modal__Bootstrap modal-dialog'
            isOpen={!!modal}
            onRequestClose={this.hidePopup}
          >
            {modal}
          </Modal>
        </SimpleBlock>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state.messages,
  user: getUser(state),
  location: getLocation(state),
})
const mapDispatchToProps = {
  startResetInitialState: actions.chatroomsResetInitialState,
  startInitiatingChatrooms: actions.chatroomsInitiating,
  startInitiatingGetFiles: actions.chatroomsGetFilesInitiating,
  markAsRead: actions.markAsRead,
  markAsUnread: actions.markAsUnread,
  push,
}

export default compose(
  withTranslation('Messages'),
  connect(mapStateToProps, mapDispatchToProps),
  addRedirectToFirstPage
)(Messages)
