// @flow

import { uniqBy } from 'lodash-es'

import * as actions from './WidgetFiles.actionTypes'

const initialState = {
  initiated: false,
  working: false,
  files: [],
  error: null,
  thread: null,
  chatroom: null,
  email: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.WIDGET_FILES_INIT: {
      const { chatroom = null, thread = null, email = null } = action

      return {
        ...state,
        chatroom,
        thread,
        email,
        working: true,
      }
    }
    case actions.WIDGET_FILES_LOAD:
      return {
        ...state,
        working: true,
      }
    case actions.WIDGET_FILES_WERE_LOADED:
      return {
        ...state,
        initiated: true,
        working: false,
        files: uniqBy(state.files.concat(action.files), 'id'), // Because files can be loaded from several sources.
      }
    case actions.WIDGET_FILES_WERE_DELETED:
      return {
        ...state,
        files: state.files.filter(f => !action.files.includes(f.id)),
      }
    case actions.WIDGET_FILES_ERROR:
      return {
        ...state,
        initiated: true,
        working: false,
        error: action.error,
      }
    case actions.WIDGET_FILES_RESET:
      return initialState
    default:
      return state
  }
}
