// @flow

import React from 'react'
import { Link } from 'react-router-dom'
import type { Node } from 'react'

import styles from './Title.module.scss'

type Props = { title: string, uuid?: string }

const Title = (props: Props): Node => {
  const { title, uuid } = props

  return (
    <>
      {uuid ? (
        <Link to={`/post/${uuid}`} className={styles.link}>
          <h2 className={styles.title}>{title}</h2>
        </Link>
      ) : (
        <h2
          className={styles.title}
          dangerouslySetInnerHTML={{ __html: title }}
        />
      )}
    </>
  )
}

export default Title
