// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import NewTableHeaderCell from '../NewTable/NewTableHeaderCell'
import NewTableHeaderRow from '../NewTable/NewTableHeaderRow'
import NewTableHeader from '../NewTable/NewTableHeader'

const PostTemplatesTableHeader = (): Node => {
  const { t } = useTranslation('PostTemplates')

  return (
    <NewTableHeader>
      <NewTableHeaderRow>
        <NewTableHeaderCell
          title={t('TemplateNameSort')}
          sortKey='name'
          style={{ width: '20%' }}
        />
        <NewTableHeaderCell
          title={t('TemplatePostTitleSort')}
          sortKey='post_title'
          style={{ width: '15%' }}
        />
        <NewTableHeaderCell
          title={t('TemplateTextSort')}
          sortKey='text'
          style={{ width: '30%' }}
        />
        <NewTableHeaderCell
          title={t('TemplateHiddenSort')}
          sortKey='hidden'
          style={{ width: '10%' }}
        />
        <NewTableHeaderCell
          title={t('TemplateCreatedBySort')}
          sortKey='created_by'
          style={{ width: '15%' }}
        />
        <NewTableHeaderCell
          title={t('TemplateUpdatedSort')}
          sortKey='updated'
          style={{ width: '10%' }}
        />
      </NewTableHeaderRow>
    </NewTableHeader>
  )
}

export default PostTemplatesTableHeader
