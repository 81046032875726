// @flow

import * as actions from './MarketplaceSettings.actionTypes'

type State = {
  id: ?number,
  is_active: boolean,
  show_secondary_mappings: boolean,
  updated: ?string,
  updating: boolean,
  working: boolean,
}

type ActionType =
  | 'MARKETPLACE_SETTINGS_INITIATE'
  | 'MARKETPLACE_SETTINGS_WERE_INITIATED'
  | 'MARKETPLACE_SETTINGS_UPDATE'
  | 'MARKETPLACE_SETTINGS_WERE_UPDATED'

type ActionData = {|
  id: number,
  is_active: boolean,
  show_secondary_mappings: boolean,
  updated: string,
|}

type Action = {
  data: ActionData,
  type: ActionType,
}

const initialState = {
  is_active: false,
  id: null,
  show_secondary_mappings: false,
  updated: null,
  working: false,
  updating: false,
}

export default (state: State = initialState, action: Action) => {
  switch (action.type) {
    case actions.MARKETPLACE_SETTINGS_INITIATE:
      return {
        ...state,
        working: true,
      }
    case actions.MARKETPLACE_SETTINGS_WERE_INITIATED:
      return {
        ...state,
        ...action.data,
        working: false,
      }
    case actions.MARKETPLACE_SETTINGS_UPDATE:
      return {
        ...state,
        updating: true,
      }
    case actions.MARKETPLACE_SETTINGS_WERE_UPDATED:
      return {
        ...state,
        ...action.data,
        updating: false,
      }
    default:
      return state
  }
}
