// @flow

import {
  BLOCKED_STATUS,
  IMPORTED_STATUS,
  USER_STATUS,
} from '../../../constants'

export function getOptions({ permissions, status }, t) {
  const {
    can_block_user: canBlock,
    can_delete: canDelete,
    canChangeDwellerRole,
    canChangeManagerRole,
    can_edit_external_id: canEditExternalId,
    canReinvite,
    canChangeAddress,
  } = permissions

  const blockUnblockLabel = status === 3 ? t('unblock') : t('block')
  const options = []

  // TODO rewrite using switch without break
  if (canBlock) {
    options.push({ value: 'blockUnblock', label: blockUnblockLabel })
  }

  if (canDelete) {
    options.push({ value: 'removeDweller', label: t('delete') })
  }

  if (canChangeDwellerRole) {
    options.push({ value: 'changeDwellerRole', label: t('ChangeRoleButton') })
  }

  if (canChangeManagerRole) {
    options.push({ value: 'changeManagerRole', label: t('ChangeRoleButton') })
  }

  if (canEditExternalId) {
    options.push({ value: 'editExternalId', label: t('EditExternalIdButton') })
  }

  if (canReinvite) {
    options.push({ value: 'sentInvitation', label: t('SentInvitation') })
  }

  if (canChangeAddress) {
    options.push({ value: 'moveToOtherFlat', label: t('MoveToOtherFlat') })
  }

  return options
}

export function getExternalOptions(t) {
  const options = [
    { value: 'editExternalId', label: t('EditExternalIdButton') },
  ]

  return options
}
export function getShowButtonStatus(profileData) {
  const {
    permissions: { can_block_user: canBlock, can_delete: canDelete },
    status,
  } = profileData

  return function (buttonType) {
    switch (buttonType) {
      case 'approve':
        return status === 4
      case 'invitationReminder':
        return status === 0
      case 'addToFriends':
        return status === 1
      case 'unblock':
        return status === 3
      case 'more':
        return canBlock || canDelete || canReinvite(profileData)
      default:
        return false
    }
  }
}

export function canReinvite(user: User) {
  const { status } = user

  const userStatus = USER_STATUS[status]

  return userStatus === IMPORTED_STATUS || userStatus === BLOCKED_STATUS
}
