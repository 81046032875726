// @flow

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { forEach, sortBy } from 'lodash-es'
import moment from 'moment'

import EmptyList from '../../EmptyList'
import Loader from '../../Loader'
import { getUk, getUkMobileLogo } from '../../../utils/commonSelectors'
import { getUrlForAvatar, cutText, getUserName } from '../../../utils/utils'
import DateTime from '../../DateTime'
import { getLogs } from '../../../core/api/api.request'

const UPDATES_PAGE_SIZE = 5
const SYSTEM_TYPE = 'system'

type Props = {
  logs: Array<Object>,
  requestId: number,
  setLogs: (Array<Object>) => void,
  t: string => string,
  uk: Object,
  ukMobileLogo: Object,
}

type State = {
  areUpdatesVisible: boolean,
  loading: boolean,
}

class RequestActivities extends Component<Props, State> {
  state = {
    areUpdatesVisible: false,
    loading: true,
  }

  showHiddenUpdates = () => {
    this.setState({ areUpdatesVisible: true })
  }

  componentDidMount() {
    const { requestId } = this.props

    getLogs(requestId, {
      page_size: UPDATES_PAGE_SIZE,
    })
      .then((data: Array<Object>) => this.props.setLogs(data))
      .catch(error => console.error(error))
      .finally(() => this.setState({ loading: false }))
  }

  getItems = () => {
    const { logs } = this.props

    const updates = sortBy(logs, i =>
      moment(i.created || i.timestamp).valueOf()
    )

    return updates.reverse()
  }

  renderLog = (log, index) => {
    const { uk, ukMobileLogo } = this.props
    const { uuid, id, owner_obj: owner, timestamp } = log
    const isSystemLog = owner.name === SYSTEM_TYPE
    const fullName = isSystemLog ? uk.name : getUserName(owner)
    const linkContent = cutText(fullName, 60)
    const linkPath = isSystemLog ? '/uk' : `/profile/${owner.id}`
    const avatarSrc = isSystemLog ? ukMobileLogo.origin : getUrlForAvatar(owner)

    return (
      <div className='comments__reply' key={`log-${index}-${uuid || id}`}>
        <a className='comments__reply-image'>
          <img src={avatarSrc} alt='userpic' />
        </a>
        <div className='comments__reply-content'>
          <span className='comments__reply-date'>
            <DateTime dateTime={timestamp} />
          </span>
          <Link className='comments__reply-username' to={linkPath}>
            {linkContent}
          </Link>
          <div className='comments__reply-event comments__reply-event--status comments__reply-text'>
            {this.translate(log)}
          </div>
        </div>
      </div>
    )
  }

  translate = log => {
    const { owner_obj: owner, text } = log
    const user = getUserName(owner)

    let tValues = text.replace(/\r?\n/g, ' ')
    const tValuesReg = /[^{]{([^{]*?)}/gi
    forEach(text.match(tValuesReg), () => {
      const match = tValuesReg.exec(text)
      tValues = tValues.replace(match[1], `{${this.props.t(match[1])}}`)
    })

    const values = {}
    let tText = tValues
    const tTextReg = /{{([\s\S]*?)}}/gi
    forEach(tValues.match(tTextReg), (m, i) => {
      const match = tTextReg.exec(tValues)
      const valueKey = `value${i + 1}`
      values[valueKey] = match[1]
      tText = tText.replace(`{{${match[1]}}}`, `{{${valueKey}}}`)
    })

    if (log.type === 'checkitem' || log.type === 'checklist') {
      values.user = user
      tText = `{{user}} ${tText}`
    }

    return this.props.t(tText, values)
  }

  render() {
    const { loading } = this.state

    if (loading) {
      return <Loader type='medium' text={false} />
    }

    const { areUpdatesVisible } = this.state
    const NUMBER_OF_VISIBLE_UPDATES = UPDATES_PAGE_SIZE
    const items = this.getItems()
    const visibleCount = areUpdatesVisible
      ? items.length
      : NUMBER_OF_VISIBLE_UPDATES

    return (
      <div className='comments'>
        <div className='comments__list'>
          {items.length ? (
            items.slice(0, visibleCount).map(this.renderLog)
          ) : (
            <EmptyList
              embedded
              icon='updates'
              title={this.props.t('ThereAreNoUpdatesYet')}
            />
          )}
          {!areUpdatesVisible && items.length > NUMBER_OF_VISIBLE_UPDATES && (
            <div className='comments__showmore -hidden'>
              <button
                type='button'
                className='comments__showmore-button'
                onClick={this.showHiddenUpdates}
              >
                {this.props.t('ShowAll')}
                &nbsp;(
                {items.length - NUMBER_OF_VISIBLE_UPDATES})
              </button>
            </div>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ukMobileLogo: getUkMobileLogo(state),
  uk: getUk(state),
})

export default compose(
  withTranslation('Request'),
  connect(mapStateToProps)
)(RequestActivities)
