// @flow

import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Header, SimpleBlock } from '../../../Block'

import type { Node } from 'react'
import type { IModalName, IInsurance } from '../BuildingInformation'

import Icon from '../../../Icon'
import SelectCustom from '../../../Select/SelectCustom'
import InsuranceForm from './InsuranceForm'
import Notes from '../../../Notes'

import styles from './BuildingInformationForms.module.scss'

type Props = {
  insurance: IInsurance,
  setCurrentNoticeModal: (modalName: IModalName) => void,
  updateInsurance: (data: { insurance: IInsurance }) => Promise<IInsurance>,
}

const Insurance = ({
  insurance,
  insurance: { notes },
  updateInsurance,
  setCurrentNoticeModal,
}: Props): Node => {
  const { t } = useTranslation('Building')

  const [isEdit, setIsEdit] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (!isOpen) {
      setIsEdit(false)
    }
  }, [isOpen])

  const handleEditOpen = () => {
    setIsEdit(true)
    setIsOpen(true)
  }
  const handleEditClose = () => setIsEdit(false)

  const options = [
    {
      value: 'addNotice',
      label: t('AddNotice'),
      onClick: () => setCurrentNoticeModal('insurance'),
      icon: 'pencil',
    },
  ]

  return (
    <SimpleBlock style={{ marginTop: 0 }}>
      <Header overflowVisible header>
        <div className={styles.profileWrap}>
          <div>
            {t('Insurance')}
            {!isEdit && (
              <Icon
                colorOnHover
                style={{ width: 16, height: 16, paddingLeft: '8px' }}
                id='pencil'
                onClick={handleEditOpen}
              />
            )}
          </div>
          <div className={styles.profileOpenIcon}>
            <SelectCustom options={options} onChange={opt => opt.onClick()} />
          </div>
        </div>
      </Header>
      <InsuranceForm
        isOpen={isOpen}
        initialValues={insurance}
        active={isEdit}
        handleEditClose={handleEditClose}
        updateInsurance={updateInsurance}
      />
      <Notes notes={notes && notes.trim()} />
    </SimpleBlock>
  )
}

export default Insurance
