// @flow

import { cloneDeep } from 'lodash-es'

import { RATING_TYPES } from '../../constants'
import * as actions from './RequestView.constants'
import { CHATROOM_WAS_CREATED } from '../Chatroom/Chatroom.actionTypes'
import { RATING_WAS_UPDATED } from '../Feedback/Feedback.actions'

const initialState = {
  data: null,
  initiated: false,
  working: false,
  modal: null,
  error: false,
  checklists: [],
  isChecklistsPopUpOpen: false,
  isRemovePopUpOpen: false,
  editFromWidget: false,
  providerId: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CHATROOM_WAS_CREATED:
      if (action.request === (state.data && state.data.id)) {
        const { isPublic } = action

        return {
          ...state,
          data: {
            ...state.data,
            public_chatroom: isPublic
              ? action.uuid
              : state.data.public_chatroom,
            private_chatroom: isPublic
              ? state.data.private_chatroom
              : action.uuid,
          },
        }
      }

      return state
    case actions.REQUEST_VIEW_INITIATING:
      return { ...state, working: true }
    case actions.RESET_INITIAL_STATE:
      return initialState
    case actions.REQUEST_VIEW_INITIATED:
      return state.working
        ? {
            ...state,
            initiated: true,
            working: false,
            data: action.data,
            checklists: action.checklists,
          }
        : state
    case actions.REQUEST_VIEW_UPDATE_INFO:
    case actions.REQUEST_VIEW_UPDATE_BODY:
    case actions.REQUEST_VIEW_UPDATE_FILES:
      return {
        ...state,
        working: true,
      }
    case actions.REQUEST_VIEW_UPDATED: {
      return state.working
        ? {
            ...state,
            working: false,
            data: action.data,
          }
        : state
    }
    case actions.REQUEST_VIEW_ERROR:
      return {
        ...state,
        working: false,
        error: action.error,
      }
    case actions.CREATE_CHECKLIST_SUCCESS: {
      return {
        ...state,
        checklists: [...state.checklists, action.checklist],
      }
    }
    case actions.CANCEL_CHECKLIST: {
      return {
        ...state,
        checklists: state.checklists.map(elem => ({
          ...elem,
          justAdded: false,
        })),
      }
    }
    case actions.REQUEST_VIEW_SHOW_MODAL: {
      return {
        ...state,
        modal: action.modal,
      }
    }
    case actions.DELETE_CHECKLIST_INIT: {
      const index = state.checklists.findIndex(
        ({ id }) => id === action.checklistId
      )
      const modifiedChecklist = cloneDeep(state.checklists[index])
      modifiedChecklist.archived = true
      const newState = cloneDeep(state)
      const checklists = newState.checklists
      checklists.splice(index, 1, modifiedChecklist)

      return {
        ...state,
        checklists,
      }
    }
    case actions.REQUEST_VIEW_LABEL_UPDATED: {
      return {
        ...state,
        data: action.request,
      }
    }
    case actions.UPDATE_DUE_DATE_INIT: {
      return {
        ...state,
        working: true,
        data: {
          ...state.data,
          due_date: action.dueDate,
          due_date_backup: state.data.due_date,
        },
      }
    }
    case actions.UPDATE_DUE_DATE_SUCCESS: {
      return {
        ...state,
        working: false,
      }
    }
    case actions.UPDATE_DUE_DATE_ERROR: {
      return {
        ...state,
        working: false,
        data: { ...state.data, due_date: state.data.due_date_backup },
      }
    }
    case actions.REQUEST_VIEW_SHOW_MODAL_CHECKLISTS: {
      return {
        ...state,
        isChecklistsPopUpOpen: true,
      }
    }
    case actions.REQUEST_VIEW_SHOW_MODAL_REMOVE: {
      return {
        ...state,
        isRemovePopUpOpen: true,
      }
    }
    case actions.REQUEST_VIEW_HIDE_MODAL: {
      return {
        ...state,
        modal: null,
        isChecklistsPopUpOpen: false,
      }
    }
    case RATING_WAS_UPDATED: {
      if (action.rating.rating_type === RATING_TYPES.general) {
        return {
          ...state,
          data: {
            ...state.data,
            rating: action.rating.value,
          },
        }
      } else {
        return state
      }
    }
    case actions.EDIT_FROM_WIDGET:
      return { ...state, editFromWidget: true, contractor: action.contractor }
    case actions.CLEAR_EDIT_FROM_WIDGET:
      return { ...state, editFromWidget: false, providerId: null }
    case actions.REQUEST_VIEW_UPDATE_THREAD_COUNTERS: {
      if (!state.data) {
        return state
      }

      return { ...state, data: { ...state.data, ...action.counters } }
    }
    default:
      return state
  }
}
