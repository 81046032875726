// @flow

import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import classnames from 'classnames'
import type { Node } from 'react'

import Modal from '../../Modal'
import { useSelector, useDispatch } from 'react-redux'
import { PROFILE_INFO_EXTERNAL_ID_ERROR_CLEANUP } from './ProfileInfo.actionTypes'
import { useOverflow } from '../../../hooks'

type Props = {
  buttonTitle: string,
  externalId?: string,
  isOpen: boolean,
  onClose: () => void,
  onOk: string => void,
  parent: number,
  setLoading: boolean => void,
  title: string,
}

const EXTERNAL_ID_MAX_LENGTH = 1000

const ExternalIdEditingPopup = (props: Props): Node => {
  const { isOpen, title, buttonTitle } = props

  const externalIdError = useSelector(
    state => state.profileInfo.externalIdError
  )

  const dispatch = useDispatch()

  useOverflow()

  const { t } = useTranslation('User')

  const formik = useFormik({
    validateOnChange: false,
    initialValues: {
      externalId: props.externalId || '',
    },
    validationSchema: Yup.object({
      externalId: Yup.string().max(
        EXTERNAL_ID_MAX_LENGTH,
        t('MaxExternalIdLength', { length: EXTERNAL_ID_MAX_LENGTH })
      ),
    }),
    onSubmit: values => {
      props.onOk(values.externalId)
    },
  })

  useEffect(() => {
    if (externalIdError) {
      dispatch({ type: PROFILE_INFO_EXTERNAL_ID_ERROR_CLEANUP })
    }
  }, [formik.values.externalId])

  const handleClose = e => {
    e.preventDefault()
    formik.setSubmitting(false)
    formik.resetForm()
    props.onClose()
  }

  const blockClassName = classnames(
    'input input--medium input--block input--default',
    { 'input--error': formik.errors.externalId || externalIdError }
  )

  return (
    <Modal isOpen={isOpen}>
      <form onSubmit={formik.handleSubmit}>
        <div className='modal__title'>{title}</div>
        <button className='modal__close' type='button' onClick={handleClose} />
        <div className='modal__body'>
          <div className='m-a10__row'>
            <div className='m-a10__cell m-a10__cell_1'>
              <span className='m-a10__label'>{t('ExternalId')}</span>
            </div>
            <div className='m-a10__cell m-a10__cell_2'>
              <div className={blockClassName}>
                <input
                  autoFocus
                  type='text'
                  name='externalId'
                  placeholder={t('ExternalIdPlaceholder')}
                  value={formik.values.externalId}
                  onChange={formik.handleChange}
                />
                {formik.errors.externalId && (
                  <span className='input__msg'>{formik.errors.externalId}</span>
                )}
                {!formik.errors.externalId && externalIdError && (
                  <span className='input__msg'>
                    {t('ExternalIdAreadyExists')}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='modal__submit'>
          <button
            className='button button--large button--success'
            type='submit'
          >
            {buttonTitle}
          </button>
          <button
            className='button button--large button--danger-3'
            type='button'
            onClick={handleClose}
          >
            {t('Cancel')}
          </button>
        </div>
      </form>
    </Modal>
  )
}

export default ExternalIdEditingPopup
