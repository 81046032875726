// @flow

import React from 'react'
import type { Node } from 'react'

import styles from './Error.module.scss'

type Props = { error: string, t: string => string }

const Error = (props: Props): Node => {
  const { error } = props

  return (
    <div className={styles.error}>
      <div className={styles.title}>
        {props.t('CompanyRegistrationErrorTitle')}
      </div>
      <div className={styles.text}>
        <p>{error}</p>
      </div>
    </div>
  )
}

export default Error
