// @flow

import { getApi, postRaw, patchRaw } from './api.core'

const BASE_PATHNAME = 'api/v2/newsband/'

export const createPost = credentials => postRaw(BASE_PATHNAME, credentials)
export const readPost = uuid => postRaw(`${BASE_PATHNAME}${uuid}/read/`)
export const unreadPost = uuid => postRaw(`${BASE_PATHNAME}${uuid}/unread/`)
export const createTicker = credentials =>
  postRaw('api/ticker-private/', credentials)
export const editPost = (uuid, credentials) =>
  patchRaw(`${BASE_PATHNAME}${uuid}/`, credentials)
export const get = uid => getApi(`${BASE_PATHNAME}${uid}/`)
export const getListTiny = credentials =>
  getApi('api/v2/newsband-tiny/', credentials)
export const pinPost = (uuid, params) =>
  patchRaw(`${BASE_PATHNAME}${uuid}/`, params)
export const getNews = ({
  limit,
  lastPostUuid,
  owner,
  buildings,
  building_group,
  request,
  search,
  post_type,
}) =>
  getApi(
    BASE_PATHNAME,
    {
      from_uuid: lastPostUuid,
      page_size: limit,
      ordering: '-created',
      owner,
      post_type,
      buildings,
      building_group,
      request,
      search,
    },
    null,
    true
  )
export const getAudience = () => getApi('api/v2/newsband-audience/')
