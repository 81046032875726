// @flow

import { deleteApi, getApi, patchRaw, postRaw } from './api.core'

const basePath = 'api/text-module/message-snippet'

export const getTemplates = (params: Object) => getApi(`${basePath}/`, params)
export const createTemplate = (params: {
  name: string,
  tags?: Array<string>,
  text: string,
}) => postRaw(`${basePath}/`, params)
export const cloneTemplate = (id: number, params?: { name: string }) =>
  postRaw(`${basePath}/${id}/clone/`, params)
export const getVariables = (params?: { tags: string }) =>
  getApi(`${basePath}/variables/`, params)
export const deleteTemplate = (id: number) => deleteApi(`${basePath}/${id}/`)
export const getTags = params => getApi(`${basePath}/tags/`, params)
export const getAuthors = params => getApi(`${basePath}/authors/`, params)
export const patchTemplate = (
  id: number,
  params?: {
    hidden?: boolean,
    name?: string,
    tags?: Array<string>,
    text?: string,
  }
): Promise<Object> => patchRaw(`${basePath}/${id}/`, params)
export const renderTemplate = (id: number, params?: Object) =>
  postRaw(`${basePath}/${id}/render/`, params)
export const renderPreview = (id: number, params?: { text: string }) =>
  postRaw(`${basePath}/${id}/render-preview/`, params)
