// @flow

import React, { useEffect } from 'react'
import type { Node } from 'react'

import styles from './Input.module.scss'

type Props = {
  errors: Array<string>,
  name: string,
  onChange: (SyntheticEvent<HTMLInputElement>) => void,
  placeholder: string,
  suggestion?: string,
  type: string,
  value: string,
}

const Input = (props: Props): Node => {
  const {
    errors = [],
    suggestion = '',
    name,
    type,
    value,
    placeholder,
    forwardRef,
  } = props

  useEffect(() => {
    if (forwardRef && forwardRef.current) {
      forwardRef.current.focus()
    }
  }, [])

  const isErrors = errors.length !== 0

  return (
    <>
      <input
        ref={forwardRef}
        name={name}
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={props.onChange}
      />
      {isErrors &&
        errors.map((error, index) => (
          <div className={styles.error} key={index}>
            {error}
          </div>
        ))}
      {!isErrors && suggestion && (
        <div className={styles.suggest}>{suggestion}</div>
      )}
    </>
  )
}

Input.defaultProps = { type: 'text', errors: [] }

export default React.forwardRef((props, ref) => (
  <Input {...props} forwardRef={ref} />
))
