// @flow

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import OutsideClick from 'react-onclickout'
import classnames from 'classnames'

import ConfirmationPopup from '../../modals/ConfirmationPopup'
import Modal from '../../Modal'
import Pan from '../../Tabs/Pan'
import Tabs from '../../Tabs/Tabs'
import Select from '../SelectBlock'

type Props = {
  data: ?Object,
  ignoreBlock?: number,
  linkBottomIndex: ?number,
  onClickOut: (?boolean) => void,
  onEdit: Object => void,
  onRemove: () => void,
  onUpdate: Object => void,
  t: (string, ?Object) => string,
}

type State = {
  block: ?number,
  error: boolean,
  modal: ?boolean,
  name: string,
  updating: boolean,
}

class BlockLink extends Component<Props, State> {
  state = {
    modal: false,
    block: this.props.data ? this.props.data.block : null,
    error: false,
    name: this.props.data ? this.props.data.text : '',
    updating: false,
  }

  setName = ({ target }) => {
    this.setState({ name: target.value, error: false })
    this.props.onEdit({ text: target.value })
  }

  setBlock = opt => {
    this.setState({ block: opt.id, error: false })
    this.props.onEdit({ block: opt.title })
  }

  handleKeyPress = e => {
    if (e.key === 'Enter') {
      this.handleClickOut()
    }
  }

  handleClickOut = () => {
    const { block, name, modal } = this.state

    if (modal) {
      return
    }

    if (!block && !name) {
      const { data } = this.props

      this.props.onClickOut()

      if (!data) {
        this.props.onRemove()
      }

      return
    }

    if (block && name) {
      this.setState({ updating: true }, this.update)
    } else {
      this.setState({ error: true })
    }
  }

  cancelRemoving = () => {
    this.setState({ modal: false })
  }

  confirmRemoving = () => {
    const { data } = this.props

    if (data) {
      this.setState({ modal: true })
    } else {
      this.props.onRemove()
    }
  }

  update = () => {
    const { data } = this.props
    const { block, name } = this.state

    if (data && data.block === block && data.text === name) {
      this.props.onClickOut()

      return
    }

    const link = { block, text: name }

    if (data) {
      link.id = data.id
    }

    this.props.onUpdate(link)
  }

  handleremove = () => {
    this.props.onRemove()
  }

  renderConfirmation = () => {
    const { data } = this.props

    return (
      <Modal
        isOpen
        className='Modal__Bootstrap modal-dialog'
        onRequestClose={this.cancelRemoving}
      >
        <ConfirmationPopup
          title={this.props.t('removeLinkTitle')}
          text={this.props.t('removeLinkText', { name: data.text })}
          confirm={this.props.t('Common:Delete')}
          cancel={this.props.t('Common:Cancel')}
          onClose={this.cancelRemoving}
          onOk={this.handleremove}
        />
      </Modal>
    )
  }

  render() {
    const { ignoreBlock, linkBottomIndex } = this.props
    const { name, block, error, modal } = this.state

    const inputClass = classnames('input modal__wizard-input', {
      'input--error': error && !name,
    })
    const bottom = linkBottomIndex >= 0 ? linkBottomIndex * 63 : 0

    return (
      <OutsideClick onClickOut={this.handleClickOut}>
        <div
          className='request-constructor__link'
          style={{ bottom: `${bottom}px` }}
        >
          <button
            className='modal__close'
            type='button'
            onClick={this.confirmRemoving}
          />
          <div className='request-constructor__link--header'>
            <input
              type='text'
              className={inputClass}
              placeholder={this.props.t('buttonName')}
              value={name}
              onKeyPress={this.handleKeyPress}
              onChange={this.setName}
            />
          </div>
          <section className='request-constructor__link--tab'>
            <Tabs selected={0}>
              <Pan
                id='workflow_constructor_link_tab'
                label={this.props.t('blocks')}
              >
                <div className='request-constructor__link--select'>
                  <Select
                    ignoreBlock={ignoreBlock}
                    inputError={error && !block}
                    value={block}
                    onChange={this.setBlock}
                  />
                </div>
              </Pan>
            </Tabs>
          </section>
        </div>
        {modal && this.renderConfirmation()}
      </OutsideClick>
    )
  }
}

export default withTranslation('RequestConstructor')(BlockLink)
