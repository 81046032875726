// @flow

import React from 'react'
import type { Node } from 'react'

import Menu from '../Menu'
import Container from '../Container'
import Layout from './Layout'

type Props = {
  children: Node,
}

const TwoColumnLayout = (props: Props) => {
  const { children } = props

  return (
    <Layout>
      <div className='content content--2-col'>
        <div className='container container_two'>
          <Menu />
          <Container>{children}</Container>
        </div>
      </div>
    </Layout>
  )
}

export default TwoColumnLayout
