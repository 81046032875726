// @flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import { intersection, difference, union } from 'lodash-es'

import AudienceList from './AudienceList'
import {
  GROUPED_AUDIENCE,
  GROUPED_AUDIENCE_DICT,
  SIGNUP_AUDIENCE,
  NOT_SIGNUP_AUDIENCE,
  BUILDINGS_SCOPE,
  TO_BUILDING,
  TO_ALL_BUILDINGS,
} from '../Post/Post.constants'
import NewSelectAsync from '../NewSelectAsync'
import {
  getBuildingOptions,
  getBuildingGroupFilter,
} from '../../core/api/api.building'
import ModalHead from '../Modal/ModalHead/ModalHead'
import ModalCloseButton from '../Modal/ModalCloseButton/ModalCloseButton'
import ModalRow from '../Modal/ModalRow/ModalRow'
import ModalBody from '../Modal/ModalBody/ModalBody'
import { useSelected } from '../../hooks/useSelected'
import { useOverflow } from '../../hooks/useOverflow'
import ModalButtons from '../Modal/ModalButtons'
import Button from '../Button'
import { isBuildingsDisabled, isSubmitDisabled } from './SetPostScope.utils'
import ActiveBuildings from './ActiveBuildings'
import ModalText from '../Modal/ModalText'
import ModalLabel from '../Modal/ModalLabel'
import { isAdminUser, isDwellerUser } from '../../utils/utils'
import { useUser } from '../../hooks'
import Radio from '../Radio'
import Checkbox from '../Checkbox'

import styles from './SetPostScope.module.scss'

const SetPostScope = props => {
  const {
    scope: initialScope,
    postType,
    buildings,
    allBuildings,
    automate,
    requestBuilding,
  } = props

  const user = useUser()

  const [scope, setScope] = useState(initialScope)
  const [isOpen, setOpen] = useState(false)
  const [isGroupsOpen, setGroupsOpen] = useState(false)
  const [isAllLoading, setAllLoading] = useState(false)
  const [isAllBuildings, setAllBuildings] = useState(false)
  const [metaCount, setMetaCount] = useState(null)
  const [isGroupsPost, setGroupsPost] = useState(props.isGroupsPost || false)
  const [isRequestBuilding, setRequestBuilding] = useState(
    requestBuilding || false
  )
  const [groupCount, setGroupCount] = useState(null)

  const { t } = useTranslation('Post')

  useOverflow()

  const [selectedItems, changeSelected, setSelected] = useSelected(
    !isGroupsPost ? buildings : []
  )
  const [selectedGroups, changeSelectedGroups, setSelectedGroups] = useSelected(
    isGroupsPost ? buildings : []
  )

  const getLabel = building => building.address_obj?.value || building.address
  const getGroupLabel = group => group.label || group.name

  const toggleOption = (id, checked) => {
    if (GROUPED_AUDIENCE.includes(id)) {
      const items = GROUPED_AUDIENCE_DICT[id]
      const func = checked ? union : difference

      setScope(() => func(scope, items))

      return
    }

    if (SIGNUP_AUDIENCE.includes(id)) {
      const newScope = union(difference(scope, SIGNUP_AUDIENCE), [id])

      setScope(newScope)

      return
    }

    if (NOT_SIGNUP_AUDIENCE.includes(id)) {
      const newScope = union(difference(scope, NOT_SIGNUP_AUDIENCE), [id])

      setScope(newScope)

      return
    }

    const included = scope.includes(id)
    const func = included ? difference : union

    setScope(() => func(scope, [id]))
  }

  const toggleRequestBuilding = () => setRequestBuilding(!isRequestBuilding)

  const handleOpen = () => setOpen(!isOpen)
  const handleGroupsOpen = () => setGroupsOpen(!isGroupsOpen)

  const handleSubmit = () => {
    const buildingRelation = intersection(scope, BUILDINGS_SCOPE).length > 0

    const newBuildings = buildingRelation
      ? isGroupsPost
        ? selectedGroups
        : isAllBuildings && isAdminUser(user)
        ? []
        : selectedItems
      : []

    const newScope = buildingRelation
      ? scope
      : difference(scope, [TO_BUILDING, TO_ALL_BUILDINGS])

    props.onOk(
      newScope,
      newBuildings,
      isAllBuildings && !isGroupsPost,
      isGroupsPost,
      isRequestBuilding
    )
  }

  const hideBuildingList = classnames(
    'modal__label--drop button-drop button-drop--small',
    { 'modal__label--drop--open': isOpen }
  )

  const bodyClass = classnames(styles.body, { 'working-overlay': isAllLoading })

  const handleClickAll = isLoading => {
    setAllLoading(isLoading)
  }

  const handleCheck = isAll => {
    setAllBuildings(isAll)
  }

  const checkAllSelected = selectedItems => {
    if (metaCount && metaCount === selectedItems.length) {
      return true
    }

    return false
  }

  const handleMetaCount = count => {
    if (!metaCount) {
      setMetaCount(count)
    }
  }

  const handleChangeRadio = e => setGroupsPost(JSON.parse(e.target.value))

  return (
    <>
      <ModalHead title={t('UpdatePostScopeTitle')} />
      <ModalCloseButton onClose={props.onClose} />
      <ModalBody className={bodyClass}>
        <ModalRow>
          <ModalText text={t('ChooseTheScope')} />
        </ModalRow>
        <AudienceList
          scope={scope}
          toggleOption={toggleOption}
          postType={postType}
        />
        <ModalRow>
          <ModalText
            text={t(isDwellerUser(user) ? 'AddAddress' : 'AddAddressOrGroup')}
          />
        </ModalRow>
        {!isDwellerUser(user) && (
          <>
            <ModalRow>
              <Radio
                id='group'
                name='building_or_group'
                value={false}
                checked={!isGroupsPost && 'checked'}
                label={t('PostForBuildings')}
                onChange={handleChangeRadio}
              />
            </ModalRow>
            <ModalRow>
              <Radio
                id='building'
                name='building_or_group'
                value={true}
                checked={isGroupsPost && 'checked'}
                label={t('PostForGroups')}
                onChange={handleChangeRadio}
              />
            </ModalRow>
          </>
        )}
        {(!isGroupsPost || isDwellerUser(user)) && (
          <ModalRow>
            <NewSelectAsync
              isMulti
              view='dropdown'
              preloadAll={allBuildings && isAdminUser(user)}
              disabled={isBuildingsDisabled(scope)}
              pageSize={10}
              api={getBuildingOptions}
              placeholder={t('Select:selectAddress')}
              permanentParams={{ is_promo: 0, with_avatar: 1 }}
              getLabel={getLabel}
              getSelectedLabel={getLabel}
              selectedItems={selectedItems}
              isAllSelected={checkAllSelected}
              setSelectedItems={setSelected}
              searchKey='search'
              className={styles.select}
              minSearchLength={1}
              checkAllSelected={handleCheck}
              setMetaCount={handleMetaCount}
              onClick={changeSelected}
              onClickAll={handleClickAll}
            />
          </ModalRow>
        )}
        {isGroupsPost && (
          <ModalRow>
            <NewSelectAsync
              isMulti
              noAll
              idField='value'
              className={styles.select}
              placeholder={t('SelectGroupPlaceholder')}
              disabled={isBuildingsDisabled(scope)}
              api={getBuildingGroupFilter}
              permanentParams={{
                with_avatar: 1,
                manageable_only: 1,
              }}
              pageSize={10}
              view='dropdown'
              searchKey='search'
              selectedItems={selectedGroups}
              getLabel={getGroupLabel}
              getSelectedLabel={getGroupLabel}
              setSelectedItems={setSelectedGroups}
              isAllSelected={selected => groupCount === selected.length}
              setMetaCount={setGroupCount}
              onClick={changeSelectedGroups}
            />
          </ModalRow>
        )}
        {!isGroupsPost && !!selectedItems.length && (
          <ModalRow onClick={handleOpen}>
            <ModalText className={styles.text} text={t('BuildingsList')} />
            <ModalLabel className={styles.label}>
              {selectedItems.length}
            </ModalLabel>
            <button
              type='button'
              className={hideBuildingList}
              style={{ marginTop: '1px' }}
            />
          </ModalRow>
        )}
        {!isGroupsPost && isOpen && !!selectedItems.length && (
          <ActiveBuildings
            buildings={selectedItems}
            changeSelected={changeSelected}
          />
        )}
        {isGroupsPost && !!selectedGroups.length && (
          <ModalRow onClick={handleGroupsOpen}>
            <ModalText className={styles.text} text={t('GroupsList')} />
            <ModalLabel className={styles.label}>
              {selectedGroups.length}
            </ModalLabel>
            <button
              type='button'
              className={hideBuildingList}
              style={{ marginTop: '1px' }}
            />
          </ModalRow>
        )}
        {isGroupsPost && isGroupsOpen && !!selectedGroups.length && (
          <ActiveBuildings
            isGroup
            buildings={selectedGroups}
            changeSelected={changeSelectedGroups}
          />
        )}
        {automate && (
          <ModalRow>
            <Checkbox
              checked={isRequestBuilding}
              disabled={isBuildingsDisabled(scope)}
              text={t(
                isGroupsPost
                  ? 'RequestBuildingGroupCheckBox'
                  : 'RequestBuildingCheckBox'
              )}
              onChange={toggleRequestBuilding}
            />
          </ModalRow>
        )}
      </ModalBody>
      <ModalButtons>
        <Button.Save
          disabled={isSubmitDisabled(
            scope,
            isGroupsPost ? selectedGroups : selectedItems,
            isRequestBuilding
          )}
          onClick={handleSubmit}
        >
          {t('SetAudience')}
        </Button.Save>
        <Button.Cancel onClick={props.onClose}>{t('Cancel')}</Button.Cancel>
      </ModalButtons>
    </>
  )
}

export default SetPostScope
