// @flow

import { commitMutation, graphql } from 'react-relay'

const mutation = graphql`
  mutation AllProvidersListMutation(
    $input: AddFavoriteProvidersMutationInput!
  ) {
    addFavoriteProviders(input: $input) {
      addedIds
    }
  }
`

const commit = (environment, providerData, callback) => {
  // TODO view для ошибок
  return commitMutation(environment, {
    mutation,
    variables: {
      input: providerData,
    },
    onCompleted: callback,
    onError: callback,
  })
}

export default { commit }
