// @flow

import React, { useState } from 'react'
import type { Node } from 'react'

import { getInfoStatus } from '../../core/api/api.status'
import { useLoad } from '../../hooks'
import StatusModal from './StatusTypeModal'
import StatusTypeTable from './StatusTypeTable'

const Status = (): Node => {
  const [isModalOpen, setModalOpen] = useState(false)
  const [editingStatus, setEditingStatus] = useState(null)
  const [status, isLoading, , load] = useLoad(getInfoStatus)

  const handleClose = () => setModalOpen(false)
  const handleCreate = () => setModalOpen(true)

  return (
    <>
      {status && (
        <>
          <StatusTypeTable isLoading={isLoading} onEdit={handleCreate} />
          {isModalOpen && (
            <StatusModal
              load={load}
              editingStatus={editingStatus}
              setEditingStatus={setEditingStatus}
              onClose={handleClose}
            />
          )}
        </>
      )}
    </>
  )
}

export default Status
