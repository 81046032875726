// @flow

import React from 'react'

import ImportFilesTable from './ImportFilesTable'

const ImportFiles = () => (
  <div className='content__col'>
    <ImportFilesTable />
  </div>
)

export default ImportFiles
