// @flow

import React from 'react'

import NewTable from '../../NewTable'
import ContractorsTableHeader from './ContractorsTableHeader'
import ContractorsTableBody from './ContractorsTableBody'

const ContractorsTable = props => {
  const { loading, contractors, selectedItems, fromBuildings } = props

  return (
    <NewTable loading={loading}>
      <ContractorsTableHeader />
      <ContractorsTableBody
        contractors={contractors}
        selectedItems={selectedItems}
        toggleContractor={props.toggleContractor}
        setBuildingModal={props.setBuildingModal}
        setCategoryModal={props.setCategoryModal}
        fromBuildings={fromBuildings}
        onRemove={props.onRemove}
        onRestore={props.onRestore}
      />
    </NewTable>
  )
}

export default ContractorsTable
