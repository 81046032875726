import React, { useMemo } from 'react'
import {
  allSelectedUsers,
  onUpdate,
  error,
  toggleAll,
} from './Toolbox/Toolbox.actions'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  getLocation,
  getThemeColor,
  getUser,
} from '../../../utils/commonSelectors'
import {
  getAllArchivedDwellerList,
  getAllDwellerList,
} from '../../../core/api/api.profile'
import { getGroupDwellers } from '../../../core/api/api.building'
import { map } from 'lodash-es'
import { getCurrentFilters, getCurrentPage } from '../../../utils/routing'
import { ALL_PAGES, USER_GROUPS } from '../../../constants'
import SelectPopUp from './SelectPopUp'

const actionType = {
  SELECTED_ALL_PAGES: 'SELECTED_ALL_PAGES',
  SELECTED_SOME: 'SELECTED_SOME',
  SELECTED_CURR_PAGE: 'SELECTED_CURR_PAGE',
}

const SelectPopUpUsers = ({
  selected,
  users,
  location,
  working,
  meta,
  toggleAll,
  themeColor,
  error,
  onUpdate,
  t,
  parentWidth,
  allSelectedUsers,
}) => {
  const { groupId: buildingGroup } = useParams()

  const action = useMemo(() => {
    if (selected.length === meta.count || selected.length > meta.count) {
      return actionType.SELECTED_ALL_PAGES
    } else if (
      users.filter(u => !selected.includes(u.id)).length === 0 &&
      selected.length === users.length
    ) {
      return actionType.SELECTED_CURR_PAGE
    } else {
      return actionType.SELECTED_SOME
    }
  }, [selected])

  if (!selected.length) {
    return null
  }

  const getParams = () => {
    const page = getCurrentPage(location)

    return {
      page,
      counters: 1,
      group: USER_GROUPS.dweller,
      ...getCurrentFilters(location),
    }
  }

  const getAllPagesUsers = () => {
    let params = getParams()
    delete params.page

    const getApi = location.query.soft_archived
      ? getAllArchivedDwellerList
      : buildingGroup
      ? params =>
          getGroupDwellers(buildingGroup, { ...params, page_size: ALL_PAGES })
      : getAllDwellerList

    onUpdate()

    getApi({ ...params })
      .then(data => {
        const users = buildingGroup ? data.results.objects : data.results
        allSelectedUsers([...map(users, item => item.id)], [...users])
      })
      .catch(err => error({ error: err }))
  }

  const selectAll = empty => {
    selected.length > 0 && toggleAll({ empty: true })
    toggleAll({ empty })

    if (meta.count > meta.limit && !empty) {
      getAllPagesUsers()
    }
  }

  const onClick = {
    [actionType.SELECTED_ALL_PAGES]: () => selectAll(true),
    [actionType.SELECTED_CURR_PAGE]: () => getAllPagesUsers(),
    [actionType.SELECTED_SOME]: () => selectAll(false),
  }

  const getMessages = {
    [actionType.SELECTED_ALL_PAGES]: t('SelectedAll', {
      count: meta.count,
    }),
    [actionType.SELECTED_CURR_PAGE]: t('SelectedCurrPage', {
      count: selected.length,
    }),
    [actionType.SELECTED_SOME]: t('SelectedSome', {
      count: selected.length,
    }),
  }

  const getActionMessage = {
    [actionType.SELECTED_ALL_PAGES]: t('unSelectAll', {
      count: meta.count,
    }),
    [actionType.SELECTED_CURR_PAGE]: t('SelectAll', {
      count: meta.count,
    }),
    [actionType.SELECTED_SOME]: t('SelectAll', {
      count: meta.count,
    }),
  }

  return (
    <SelectPopUp
      getInfoMessages={getMessages}
      getActionMessage={getActionMessage}
      working={working}
      action={action}
      clearAll={() => selectAll(true)}
      themeColor={themeColor}
      parentWidth={parentWidth}
      onClick={onClick}
    />
  )
}

const mapStateToProps = state => ({
  ...state.userList,
  location: getLocation(state),
  user: getUser(state),
  themeColor: getThemeColor(state),
})

const mapDispatchToProps = {
  toggleAll,
  allSelectedUsers,
  error,
  onUpdate,
}

export default compose(
  withTranslation('User'),
  connect(mapStateToProps, mapDispatchToProps)
)(SelectPopUpUsers)
