// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import { push } from 'connected-react-router'
import validation from 'react-validation-mixin'
import strategy from 'joi-browser-validation-strategy'
import Joi from 'joi-browser'
import classnames from 'classnames'

import Block, { Header, Row, CellsBar } from '../../Block'
import AvatarEditable from '../../AvatarEditable'
import Button from '../../Button'
import ChangeRolePopup from '../../modals/ChangeRolePopup'
import ConfirmationPopup from '../../modals/ConfirmationPopup'
import Label from '../../Label'
import Modal from '../../Modal'
import Input from '../../Input/Regular'
import ProfileAvatar from './ProfileAvatar'
import ProfileName from './ProfileName'
import ProfileType from './ProfileType'
import ProfileExternalId from './ProfileExternalId'
import ProfileDate from './ProfileDate'
import ProfileStatus from './ProfileStatus'
import ResendInvitePopUp from '../../modals/ResendInvitePopUp'
import SelectCustom from '../../Select/SelectCustom'

import {
  getOptions,
  getShowButtonStatus,
  canReinvite,
  getExternalOptions,
} from './helpers'

import { isStaffUser } from '../../../utils/utils'

import { BUILDING_LIST_INITIATING } from '../../Building/BuildingList/BuildingList.actionTypes'
import { ADD_MEMBER_TO_CHAT } from '../../Select/SelectChatMember/SelectChatMember.actionTypes'
import * as actions from './ProfileInfo.actionTypes'
import styles from './ProfileInfo.module.scss'
import ExternalIdEditingPopup from './ExternalIdEditingPopup'
import BuildingPopup from '../../modals/BuildingPopup'
import { NOT_CONFIRMED_STATUS, USER_STATUS } from '../../../constants'
import ProfileCreatedOn from './ProfileCreatedOn'
import ProfileRole from './ProfileRole'

type Props = {
  backup: Object,
  clearValidations: () => void,
  companyName: string,
  currentUser: Object,
  data: Object,
  dispatch: Object => void,
  editMode: boolean,
  error: boolean,
  getValidationMessages: () => void,
  handleValidation: () => void,
  initiated: boolean,
  instaValidate: boolean,
  isValid: () => void,
  modal: Object,
  noEditFio: boolean,
  onInit?: () => void,
  profileData: Object,
  profileId: string | number,
  redirect: string,
  relationships: Object,
  subscribing: boolean,
  t: string => string,
  validate: ((boolean) => void) => void,
  working: boolean,
}
type State = {
  avatar: ?Object,
  editingAvatar: boolean,
  hover: boolean,
  tempDate: string,
}

class ProfileInfo extends Component<Props, State> {
  state = {
    avatar: null,
    editingAvatar: false,
    hover: false,
    tempDate: moment(),
  }

  validationMsg = {
    language: {
      any: {
        empty: `!! ${this.props.t('emptyErrorProfileInfo')}`,
      },
      string: {
        max: `!! ${this.props.t('maxLengthError')}`,
      },
    },
  }

  validatorTypes = {
    name: Joi.string()
      .required()
      .label(this.props.t('name'))
      .options(this.validationMsg)
      .trim()
      .max(50),
    surname: Joi.string()
      .required()
      .label(this.props.t('second_name'))
      .options(this.validationMsg)
      .trim()
      .max(50),
    post: Joi.string()
      .required()
      .label(this.props.t('company_post_name'))
      .options(this.validationMsg)
      .trim()
      .max(50),
  }

  componentDidMount() {
    const { profileData, instaValidate } = this.props
    this.props.dispatch({
      type: actions.PROFILE_INFO_INITIATE,
      data: profileData,
    })

    if (instaValidate) {
      this.approveDweller()
    }
  }

  componentDidUpdate(prev: Props) {
    const {
      initiated,
      working,
      editMode,
      currentUser: { group },
      redirect,
    } = this.props

    if (redirect) {
      if (group === 'admin') {
        this.props.dispatch(push(redirect))
      } else {
        this.props.dispatch(push('/'))
      }

      return
    }

    if (this.props.onInit && initiated && !prev.initiated) {
      this.props.onInit()
    }

    const isUpdated = prev.editMode && !editMode && prev.working && !working

    if (isUpdated) {
      // So new avatar and new name for Building list updated instantly
      this.props.dispatch({
        type: BUILDING_LIST_INITIATING,
        pagination: false,
      })
    }
  }

  componentWillUnmount() {
    this.props.dispatch({ type: actions.PROFILE_INFO_RESET })
  }

  onFieldUpdate = (name, value) => {
    this.props.dispatch({
      type: actions.PROFILE_INFO_UPDATE_FIELD,
      name,
      value,
    })
  }

  startAvatarEdit = () => {
    this.setState({ editingAvatar: true })
  }

  finishAvatarEdit = (avatar?: string) => {
    this.setState(state => ({
      avatar: avatar || state.avatar,
      editingAvatar: false,
    }))
  }

  onSubmit = e => {
    e.preventDefault()

    if (this.props.working) {
      return
    }

    this.validateAndSave()
  }

  onSubscribe = () => {
    const { profileId } = this.props
    this.props.dispatch({
      type: actions.PROFILE_INFO_SUBSCRIBE,
      owner: profileId,
    })
    this.setState({ hover: false })
  }

  onUnsubscribe = () => {
    const { profileId } = this.props
    this.props.dispatch({
      type: actions.PROFILE_INFO_UNSUBSCRIBE,
      owner: profileId,
    })
    this.setState({ hover: false })
  }

  onAccept = () => {
    const { profileId } = this.props
    this.props.dispatch({ type: actions.PROFILE_INFO_ACCEPT, user: profileId })
  }

  onReject = () => {
    const { profileId } = this.props
    this.props.dispatch({ type: actions.PROFILE_INFO_REJECT, user: profileId })
  }

  getValidatorData() {
    return this.props.data
  }

  validateAndSave() {
    const { profileId, data, noEditFio, external } = this.props

    if (this.isDweller() || external) {
      this.validatorTypes = {
        name: Joi.string()
          .required()
          .label(this.props.t('name'))
          .options(this.validationMsg)
          .trim()
          .max(50),
        surname: Joi.string()
          .required()
          .label(this.props.t('second_name'))
          .options(this.validationMsg)
          .trim()
          .max(50),
      }
    }

    this.props.validate(error => {
      if (error && !noEditFio) {
        return
      }

      const { avatar } = this.state
      const params = {
        name: data.name,
        second_name: data.surname,
        ...(avatar ? { avatar } : {}),
        ...(this.isDweller()
          ? { birthday: moment(data.birthday).format('YYYY-MM-DD') }
          : external
          ? {}
          : { company_post_name: data.post }),
      }

      this.props.dispatch({
        type: actions.PROFILE_INFO_UPDATE,
        id: profileId,
        params,
        external: external,
      })
    })
  }

  isDweller = () => {
    const {
      profileData: { group },
    } = this.props

    return group === 'dweller'
  }

  edit = e => {
    e.preventDefault()
    e.stopPropagation()

    this.props.dispatch({ type: actions.PROFILE_INFO_EDIT })
  }

  cancel = () => {
    this.props.dispatch({
      type: actions.PROFILE_INFO_CANCEL,
    })
    this.props.clearValidations()

    this.setState({ avatar: null })
  }

  toggle = status => {
    this.setState({ hover: status })
  }

  approveDweller = () => {
    const { profileId, working } = this.props

    if (working) {
      return
    }

    this.props.dispatch({ type: actions.PROFILE_INFO_APPROVE, id: profileId })
  }

  renderRelationships = () => {
    const { relationships, subscribing } = this.props

    if (!relationships) {
      return null
    }

    if (subscribing) {
      return (
        <button
          disabled
          className='button button--success button--large'
          type='button'
        >
          <span className='button__spinner' />
          {this.props.t('Wait')}
        </button>
      )
    }

    const {
      is_friend: isFriend,
      is_friend_request: isFriendRequest,
      is_friend_request_received: isFriendRequestReceived,
      is_friend_request_rejected: isFriendRequestRejected,
    } = relationships

    if (isFriendRequestRejected) {
      return null
    }

    const { hover } = this.state
    let buttonText = this.props.t('Subscribe')
    let showIcon = false
    let clickHandler = this.onSubscribe

    if (isFriend) {
      if (hover) {
        buttonText = this.props.t('Unsubscribe')
        clickHandler = this.onUnsubscribe
      } else {
        buttonText = this.props.t('Friend')
        showIcon = true
      }
    }

    if (isFriendRequest) {
      if (hover) {
        buttonText = this.props.t('ResendSubscribe')
      } else {
        buttonText = this.props.t('SubscribeSent')
        showIcon = true
      }
    }

    if (isFriendRequestReceived) {
      buttonText = this.props.t('AcceptInvite')
      clickHandler = this.onAccept
      showIcon = true
    }

    return (
      <>
        <Button.Save
          icon={showIcon ? 'check' : null}
          onClick={clickHandler}
          onMouseEnter={() => this.toggle(true)}
          onMouseLeave={() => this.toggle(false)}
        >
          {buttonText}
        </Button.Save>
        {isFriendRequestReceived && (
          <Button.Cancel
            icon='cross'
            onClick={this.onReject}
            onMouseEnter={() => this.toggle(true)}
            onMouseLeave={() => this.toggle(false)}
          >
            {this.props.t('RejectInvite')}
          </Button.Cancel>
        )}
      </>
    )
  }

  renderField = (name, label, placeholder, large = true, disabled = false) => {
    const { data, handleValidation, getValidationMessages, isValid } =
      this.props

    const labelClass = classnames(
      'mprofile-edit-user__label',
      'mprofile-edit-user__label--required',
      {
        'mprofile-edit-user__label--large': large,
        'mprofile-edit-user__label--middle': !large,
      }
    )
    const inputClass = classnames(
      'input',
      'input--default',
      'input--active',
      'input--large',
      styles.input,
      {
        'input--font-xlarge': large,
        'input--xlarge': large,
        'input--error': !isValid(name) && !disabled,
      }
    )

    return (
      <div className='mprofile-edit-user__field'>
        <div className={labelClass}>{this.props.t(label)}</div>
        <div className={inputClass}>
          <Input
            type='text'
            placeholder={this.props.t(placeholder)}
            name={name}
            value={data[name] || ''}
            disabled={disabled}
            onChange={this.onFieldUpdate}
            onBlur={handleValidation(name)}
          />
          {!disabled && this.renderFieldError(getValidationMessages(name))}
        </div>
      </div>
    )
  }

  renderEditFrom = () => {
    const { noEditFio, error, external } = this.props

    return (
      <div className='mprofile-edit-user__cell mprofile-edit-user__form'>
        {this.renderField('name', 'name', 'InputName', true, noEditFio)}
        {this.renderField(
          'surname',
          'Surname',
          'InputSurname',
          true,
          noEditFio
        )}
        {this.isDweller() || external ? null : (
          <div>
            {this.renderField('post', 'Position', 'InputPosition', false)}
          </div>
        )}
        {!!error && (
          <div className='input--large input--error'>
            {Object.values(error.errors).map(errorText =>
              this.renderFieldError(this.props.t(errorText))
            )}
          </div>
        )}
      </div>
    )
  }

  renderFieldError = error => (
    <div className='input__msg' key={error}>
      {error}
    </div>
  )

  invitationSent = () => {
    this.hideModal()
    this.props.onInvite()
  }

  hideModal = () => {
    this.props.dispatch({ type: actions.PROFILE_INFO_CLOSE_MODAL })
  }

  sendInvitationReminder = () => {
    const { profileId, data: user } = this.props

    this.props.dispatch({
      type: actions.PROFILE_INFO_OPEN_MODAL,
      modal: (
        <ResendInvitePopUp
          single
          reinvite
          group={user.group}
          user={user}
          usersResend={[profileId]}
          onHide={this.hideModal}
          onSave={this.invitationSent}
        />
      ),
    })
  }

  sendMessage = () => {
    const { profileId } = this.props

    this.props.dispatch({ type: ADD_MEMBER_TO_CHAT, profileId })
    this.props.dispatch(push('/chatroom/create'))
  }

  changeUserStatus = (profileId, status) => () => {
    const newStatus = status === 3 ? 2 : 3

    this.props.dispatch({
      type: actions.PROFILE_INFO_CHANGE_USER_STATUS,
      data: { profileId, newStatus },
    })
  }

  getPopupData = () => {
    const {
      data: { status },
    } = this.props
    const verb = status === 3 ? 'unblock' : 'block'

    return {
      title: this.props.t(`User ${verb}ing`),
      text: this.props.t('ConfirmActionText'),
      confirm: this.props.t(verb),
      cansel: this.props.t('Cancel'),
    }
  }

  unblockDweller = () => {
    const {
      data: { status },
      profileId,
    } = this.props
    const popupData = this.getPopupData()

    this.props.dispatch({
      type: actions.PROFILE_INFO_OPEN_MODAL,
      modal: (
        <ConfirmationPopup
          title={popupData.title}
          text={popupData.text}
          confirm={popupData.confirm}
          cancel={popupData.cancel}
          onClose={this.hideModal}
          onOk={this.changeUserStatus(profileId, status)}
        />
      ),
    })
  }

  removeDweller = () => {
    const { profileId, profileData } = this.props

    this.props.dispatch({
      type: actions.PROFILE_INFO_REMOVE,
      profileId,
      profileData,
    })
  }

  deleteExternal = () => {
    const { profileId, profileData, external } = this.props
    this.props.dispatch({
      type: actions.PROFILE_INFO_REMOVE,
      profileId,
      profileData,
      external,
    })

    this.props.dispatch(push(`/settings/users/external-contacts/`))
  }

  restoreDweller = () => {
    const { profileId, profileData } = this.props

    this.props.dispatch({
      type: actions.PROFILE_INFO_RESTORE,
      profileId,
      profileData,
    })
  }

  showRestoringModal = () => {
    this.props.dispatch({
      type: actions.PROFILE_INFO_OPEN_MODAL,
      modal: (
        <ConfirmationPopup
          title={this.props.t('RestoreTitle')}
          text={this.props.t('RestoreText')}
          confirm={this.props.t('Common:Restore')}
          cancel={this.props.t('Cancel')}
          onClose={this.hideModal}
          onOk={this.restoreDweller}
        />
      ),
    })
  }

  changeDwellerRole = option => {
    const { profileId } = this.props
    this.props.dispatch({
      type: actions.PROFILE_INFO_CHANGE_DWELLER_ROLE,
      profileId,
      group: option,
    })
  }

  changeManagerRole = option => {
    const { profileId } = this.props
    this.props.dispatch({
      type: actions.PROFILE_INFO_CHANGE_MANAGER_ROLE,
      profileId,
      group: option,
    })
  }

  changeExternalId = externalId => {
    const { profileId } = this.props

    this.props.dispatch({
      type: actions.PROFILE_INFO_CHANGE_EXTERNAL_ID,
      profileId,
      externalId,
      external: this.props.external,
    })
  }

  updateDwellerFlats = flats => {
    const { profileId, profileData, data } = this.props

    if (flats.length || !data.permissions?.can_manage_all_user_flats) {
      this.props.dispatch({
        type: actions.PROFILE_INFO_UPDATE,
        id: profileId,
        params: { flats },
        tiny: true,
      })
    } else {
      this.props.dispatch({
        type: actions.PROFILE_INFO_REMOVE,
        profileId,
        profileData,
      })
    }
  }

  moreOptions = ({ value }) => {
    const {
      data: { status, is_landlord: isLandlord, external_id: externalId },
      profileId,
      data,
      profileData: { group },
      externalIdError,
    } = this.props

    const popupData = this.getPopupData()

    switch (value) {
      case 'blockUnblock':
        this.props.dispatch({
          type: actions.PROFILE_INFO_OPEN_MODAL,
          modal: (
            <ConfirmationPopup
              title={popupData.title}
              text={popupData.text}
              confirm={popupData.confirm}
              cancel={popupData.cancel}
              onClose={this.hideModal}
              onOk={this.changeUserStatus(profileId, status)}
            />
          ),
        })
        break
      case 'removeDweller':
        this.props.dispatch({
          type: actions.PROFILE_INFO_OPEN_MODAL,
          modal: (
            <ConfirmationPopup
              title={this.props.t('DeleteTitle')}
              text={this.props.t('DeleteText')}
              confirm={this.props.t('Delete')}
              cancel={this.props.t('Cancel')}
              onClose={this.hideModal}
              onOk={() => this.removeDweller()}
            />
          ),
        })
        break
      case 'changeDwellerRole':
        this.props.dispatch({
          type: actions.PROFILE_INFO_OPEN_MODAL,
          modal: (
            <ChangeRolePopup
              text={this.props.t('DwellerRole')}
              confirm={this.props.t('Save')}
              isLandlord={isLandlord}
              onClose={this.hideModal}
              onOk={this.changeDwellerRole}
            />
          ),
        })
        break
      case 'changeManagerRole':
        this.props.dispatch({
          type: actions.PROFILE_INFO_OPEN_MODAL,
          modal: (
            <ChangeRolePopup
              text={this.props.t('ManagerRole')}
              confirm={this.props.t('Save')}
              managerGroup={group}
              onClose={this.hideModal}
              onOk={this.changeManagerRole}
            />
          ),
        })
        break
      case 'editExternalId':
        this.props.dispatch({
          type: actions.PROFILE_INFO_OPEN_MODAL,
          modal: (
            <ExternalIdEditingPopup
              isOpen
              title={this.props.t('UserExternalId')}
              buttonTitle={this.props.t('Save')}
              externalId={externalId}
              externalIdError={externalIdError}
              onClose={this.hideModal}
              onOk={this.changeExternalId}
            />
          ),
        })
        break
      case 'sentInvitation':
        this.sendInvitationReminder()
        break
      case 'moveToOtherFlat':
        this.props.dispatch({
          type: actions.PROFILE_INFO_OPEN_MODAL,
          modal: (
            <BuildingPopup
              user={data}
              onClose={() => this.hideModal()}
              onOk={ids => this.updateDwellerFlats(ids)}
            />
          ),
        })
        break
      case 'deleteDweller':
        this.props.dispatch({
          type: actions.PROFILE_INFO_OPEN_MODAL,
          modal: (
            <ConfirmationPopup
              title={this.props.t('DeleteExternalTitle')}
              text={this.props.t('DeleteExternalText')}
              confirm={this.props.t('deleteExternal')}
              cancel={this.props.t('Cancel')}
              onClose={this.hideModal}
              onOk={() => this.deleteExternal()}
            />
          ),
        })
        break
      default:
    }
  }

  render() {
    if (!this.props.initiated) {
      return null
    }

    const {
      editMode,
      working,
      profileData,
      data,
      data: {
        fullname,
        avatar,
        is_landlord: isLandlord,
        permissions,
        status,
        post,
        external_id: externalId,
        permissions: { can_change_dweller_role: landlordHasOneFlat },
        soft_archived: softArchived,
      },
      modal,
      currentUser,
      currentUser: { permissions: userPermissions },
    } = this.props
    const { editingAvatar } = this.state

    const showButton = getShowButtonStatus(data)

    const sectionClass = classnames(styles.profileInfo, styles.block, {
      'working-overlay': working || editingAvatar,
    })
    const image = avatar.preview || avatar.origin || avatar

    if (editMode) {
      const avatarClass = classnames('mprofile-edit-user__cell', styles.avatar)

      return (
        <section className={sectionClass}>
          <div className='mprofile-edit-user__row'>
            <div className={avatarClass}>
              <AvatarEditable
                alt={fullname}
                image={this.state.avatar || avatar}
                title={this.props.t('AvatarLoad')}
                onFinishEdit={this.finishAvatarEdit}
                onStartEdit={this.startAvatarEdit}
              />
            </div>
            {this.renderEditFrom()}
          </div>
          <div className='mprofile-edit-user__submit'>
            <Button.Regular working={working} onClick={this.onSubmit}>
              {this.props.t('Save')}
            </Button.Regular>
            <Button.Cancel onClick={this.cancel}>
              {this.props.t('Cancel')}
            </Button.Cancel>
          </div>
          <span className='mprofile-edit-user__hint'>
            <strong>*</strong>
            &nbsp;
            {this.props.t('Obligatory')}
          </span>
          <span className='mprofile-edit-user__hint'>
            <strong>*</strong> {this.props.t('Obligatory')}
          </span>
        </section>
      )
    }

    const canChangeDwellerRole =
      userPermissions.can_change_dweller_role &&
      landlordHasOneFlat &&
      profileData.group === 'dweller'
    const canChangeManagerRole =
      userPermissions.can_change_manager_role && profileData.group !== 'dweller'

    const canChangeAddress =
      !isStaffUser(profileData) &&
      USER_STATUS[status] !== NOT_CONFIRMED_STATUS &&
      !softArchived

    let { group: profileGroup, super: isSuper } = profileData

    if (profileGroup === 'dweller' && isLandlord) {
      profileGroup = 'landlord'
    }

    if (this.props.external) {
      return (
        <Block working={working} className={styles.block}>
          <Header header>
            <ProfileAvatar icon fullname={fullname} image={image} />
            <Button.Edit extra onClick={this.edit} />
            <ProfileName fullname={fullname} />
          </Header>

          <CellsBar>
            <ProfileCreatedOn created={profileData.created} />
            <ProfileRole />
            <ProfileExternalId externalId={externalId} />
          </CellsBar>

          {post && (
            <Row title={this.props.t('PostTitle')}>
              <span className={styles.post}>{post}</span>
            </Row>
          )}

          {currentUser.owner !== profileData.owner && (
            <Row>
              <div className={styles.rowValue}>
                {showButton('more') && (
                  <SelectCustom
                    alignLeft
                    buttonClass='button button--large button--dropdown button--showmore '
                    options={getExternalOptions(this.props.t)}
                    onChange={this.moreOptions}
                  >
                    {this.props.t('More')}
                  </SelectCustom>
                )}
              </div>
            </Row>
          )}

          <Modal
            contentLabel=''
            className='Modal__Bootstrap modal-dialog'
            isOpen={!!modal}
            onRequestClose={this.hideModal}
          >
            {modal}
          </Modal>
        </Block>
      )
    }

    return (
      <Block working={working} className={styles.block}>
        <Header header>
          <ProfileAvatar icon fullname={fullname} image={image} />
          {permissions.can_edit && <Button.Edit extra onClick={this.edit} />}
          {<ProfileName fullname={fullname} />}
          {softArchived && (
            <Label
              text={this.props.t('Archived')}
              style={{ marginLeft: '8px' }}
              type='outlined'
            />
          )}
        </Header>

        <CellsBar>
          <ProfileDate
            joined={
              status === 0
                ? profileData.last_invited_at
                : profileData.accepted_user_agreement
            }
            title={this.props.t(
              status === 0 ? 'InvitationDate' : 'RegistrationDateTitle'
            )}
          />
          <ProfileStatus status={status} />
          <ProfileType group={isSuper ? 'SuperAdmin' : profileGroup} />
          {permissions.can_view_external_id && (
            <ProfileExternalId externalId={externalId} />
          )}
        </CellsBar>

        {post && (
          <Row title={this.props.t('PostTitle')}>
            <span className={styles.post}>{post}</span>
          </Row>
        )}

        {currentUser.owner !== profileData.owner && (
          <Row>
            <div className={styles.rowValue}>
              {showButton('approve') && (
                <Button.Save onClick={this.approveDweller}>
                  <span className='button2__text'>
                    {this.props.t('ApproveDweller')}
                  </span>
                </Button.Save>
              )}

              {showButton('invitationReminder') &&
                !softArchived &&
                !this.props.external && (
                  <Button.Save onClick={this.sendInvitationReminder}>
                    <span className='button2__text'>
                      {this.props.t('InviteAgain')}
                    </span>
                  </Button.Save>
                )}

              {
                showButton('addToFriends') && this.renderRelationships() // TODO change to Button component
              }

              {permissions.can_unblock_user && !this.props.external && (
                <Button.Save onClick={this.unblockDweller}>
                  <span className='button2__text'>
                    {this.props.t('unblock')}
                  </span>
                </Button.Save>
              )}

              {permissions.can_start_new_chat && (
                <Button.Save onClick={this.sendMessage}>
                  <span className='button2__text'>
                    {this.props.t('WriteAMessage')}
                  </span>
                </Button.Save>
              )}

              {showButton('more') && (
                <SelectCustom
                  alignLeft
                  buttonClass='button button--large button--dropdown button--showmore '
                  options={getOptions(
                    {
                      permissions: {
                        ...permissions,
                        canChangeDwellerRole,
                        canChangeManagerRole,
                        canReinvite: canReinvite(data),
                        canChangeAddress,
                      },
                      status,
                    },
                    this.props.t
                  )}
                  onChange={this.moreOptions}
                >
                  {this.props.t('More')}
                </SelectCustom>
              )}
              {permissions.can_restore && (
                <Button.Save
                  className='button button--success button--large'
                  onClick={this.showRestoringModal}
                >
                  <span className='button2__text'>
                    {this.props.t('Common:Restore')}
                  </span>
                </Button.Save>
              )}
            </div>
          </Row>
        )}

        <Modal
          contentLabel=''
          className='Modal__Bootstrap modal-dialog'
          isOpen={!!modal}
          onRequestClose={this.hideModal}
        >
          {modal}
        </Modal>
      </Block>
    )
  }
}

const mapStateToProps = state => ({
  ...state.profileInfo,
  companyName: state.init.uk.name,
})

export default compose(
  withTranslation('Profile'),
  connect(mapStateToProps),
  validation(strategy)
)(ProfileInfo)
