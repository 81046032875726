// @flow

export const DEFAULT_UPLOAD_KEY = 'documents'

export const MULTIPLIER = 1024 * 1024

export const FILE_ACCEPT_TYPES = {
  images: 'image/*',
  excel: '.xlsx,.xls',
  rasterImages: 'image/jpeg,image/png',
  pdf: 'application/pdf',
}

export const FILE_ORIGIN_TYPES = {
  background: 5,
  default: 0,
  public: 6,
  import: 1,
}
