// @flow

import React from 'react'

import styles from './ScrollbarWrapper.module.scss'

const Wrapper = props => {
  const { elementRef, ...restProps } = props

  return <div {...restProps} ref={elementRef} className={styles.wrapper} />
}

export default Wrapper
