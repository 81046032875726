// @flow

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import { getPhaseTemplates } from '../../../core/api/api.pipeline'
import PhaseList from './PhaseList'
import ActivityList from './ActivityList'
import { useSingleLoad } from '../../../hooks/useSingleLoad/useSingleLoad'
import Loader from '../../Loader'
import { getFetchedItems } from '../../../utils/utils'

import styles from './Phase.module.scss'

type Props = { pipelineId: string }

const Phase = (props: Props): Node => {
  const { pipelineId } = props

  const { t } = useTranslation('Pipeline')

  const [activePhaseId, setActivePhaseId] = useState(null)

  const [data, isLoading, , reload] = useSingleLoad(
    getPhaseTemplates,
    pipelineId
  )

  useEffect(() => {
    if (data) {
      const phaseList = getFetchedItems(data)

      if (phaseList && phaseList.length) {
        setActivePhaseId(phaseList[0].id)
      } else {
        setActivePhaseId(null)
      }
    }
  }, [data])

  if (!data && !isLoading) {
    return null
  }

  return (
    <div className={styles.phase}>
      {data ? (
        <div className={styles.container}>
          <PhaseList
            pipelineId={pipelineId}
            data={data}
            reload={reload}
            setActivePhaseId={setActivePhaseId}
            activePhaseId={activePhaseId}
          />
          {activePhaseId ? (
            <ActivityList activePhaseId={activePhaseId} />
          ) : (
            <div className={styles.empty}>
              <span>{t('CreatePhaseBefore')}</span>
            </div>
          )}
        </div>
      ) : (
        <Loader text={false} type='medium' />
      )}
    </div>
  )
}

export default Phase
