// @flow

import React from 'react'
import CalendarPart from 'rc-calendar/lib/range-calendar/CalendarPart'

import Button from '../../../Button'
import CalendarHeader from './CalendarHeader'
import DateTable from './DateTable'

class CalendarPartNew extends CalendarPart {
  render() {
    const {
      value,
      prefixCls,
      locale,
      selectedValue,
      disabledDate,
      single,
      showTimePicker,
      hoverValue,
      onlyMonth,
      minYear,
      maxYear,
      onValueChange,
      dateRender,
      showWeekNumber,
      onSelect,
      onDayHover,
      clearText,
    } = this.props

    const newProps = {
      locale,
      single,
      value,
      prefixCls,
      showTimePicker,
      onlyMonth,
    }

    return (
      <div className='calendar__wrap'>
        <div className='calendar__select'>
          <CalendarHeader
            {...newProps}
            minYear={minYear}
            maxYear={maxYear}
            onlyMonth={onlyMonth}
            className='calendar__header'
            onValueChange={onValueChange}
          />
        </div>
        <DateTable
          {...newProps}
          hoverValue={hoverValue}
          selectedValue={selectedValue}
          dateRender={dateRender}
          disabledDate={disabledDate}
          showWeekNumber={showWeekNumber}
          onSelect={onSelect}
          onDayHover={onDayHover}
        />
        {this.props.onClear && clearText && (
          <div style={{ paddingBottom: '5px' }}>
            <Button.Cancel type='button' onClick={this.props.onClear}>
              {clearText}
            </Button.Cancel>
          </div>
        )}
      </div>
    )
  }
}

export default CalendarPartNew
