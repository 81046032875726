// @flow

import { getApi, patchApi, patchRaw } from './api.core'

export const get = () => getApi('api/marketplace/show/')
export const update = params => patchApi('api/marketplace/edit/', params)
export const getBuildingsProviders = params =>
  getApi('api/marketplace/building-providers/', params)
export const updateBuildingProviders = payload =>
  patchRaw('api/marketplace/building-providers/edit/', payload)
export const getBuildingProviders = buildingId =>
  getApi(`api/marketplace/building-providers/${buildingId}/`)
export const getCategoryProviders = categoryId =>
  getApi(`api/marketplace/category-providers/${categoryId}/`)

export const getCategoriesOffers = params =>
  getApi('api/marketplace/category-offers/', params)
export const updateCategoryOffers = payload =>
  patchRaw('api/marketplace/category-offers/edit/', payload)
export const getCategoryOffers = categoryId =>
  getApi(`api/marketplace/category-offers/${categoryId}/`)

export const getCategoriesProviders = params =>
  getApi('api/marketplace/category-providers/', params)
export const updateCategoryProviders = payload =>
  patchRaw('api/marketplace/category-providers/edit/', payload)

// TODO uncomment params after backend add provider_name filter
export const getRequestProviders = (buildingId, categoryId) =>
  getApi(
    `api/marketplace/smartmapping/building/${buildingId}/category/${categoryId}/providers/`
    // params
  )
