// @flow

import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { get } from 'lodash-es'

import { serverError, modalError } from '../Layout/Layout.actions'

export const useError = (): [(string) => void] => {
  const [error, setError] = useState(null)

  const dispatch = useDispatch()

  useEffect(() => {
    if (error) {
      const status = get(error, ['message', 'response', 'status'])
      const data = get(error, ['message', 'response', 'data'])

      if (status === 400 || status === 504) {
        dispatch(modalError(data))
      } else {
        dispatch(serverError(error))
      }
    }
  }, [error])

  return [setError]
}
