// @flow

export const POST_VIEW_INITIATING = 'POST_VIEW_INITIATING'
export const POST_VIEW_INITIATED = 'POST_VIEW_INITIATED'
export const POST_VIEW_RESET = 'POST_VIEW_RESET'
export const POST_VIEW_UPDATE_BODY = 'POST_VIEW_UPDATE_BODY'
export const POST_VIEW_UPDATE_HEAD = 'POST_VIEW_UPDATE_HEAD'
export const POST_VIEW_UPDATE_FILES = 'POST_VIEW_UPDATE_FILES'
export const POST_VIEW_UPDATED = 'POST_VIEW_UPDATED'
export const POST_VIEW_ERROR = 'POST_VIEW_ERROR'
