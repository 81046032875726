// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'
import type { Location, Match } from 'react-router-dom'

import PostInfo from '../Post/PostInfo'
import PostBody from '../Post/PostBody'
import Loader from '../Loader'
import Notifications from '../Notification/NotificationList'
import * as actions from './PostView.actionTypes'
import { widgetFilesReset } from '../widgets/WidgetFiles/WidgetFiles.actionTypes'
import { BUILDING_LIST_LOAD } from '../Building/BuildingList/BuildingList.actionTypes'
import { PAGE_SIZE } from '../../constants'
import { getUser } from '../../utils/commonSelectors'

const EDIT_MODE = 'edit'

type Props = {
  buildingList: Array<Object>,
  dispatch: Object => void,
  initiated: boolean,
  location: Location,
  match: Match,
  notifications: Array<Object>,
  params: Object,
  postData: Object,
  user: Object,
  working: boolean,
}

class PostView extends Component<Props> {
  componentDidMount() {
    this.props.dispatch({
      type: actions.POST_VIEW_INITIATING,
      id: this.getId(),
    })

    this.props.dispatch({
      type: BUILDING_LIST_LOAD,
      params: { page_size: PAGE_SIZE, is_promo: 0 },
    })
  }

  componentDidUpdate(prevProps) {
    const prevId = prevProps.match.params.id
    const curId = this.getId()

    if (curId !== prevId) {
      this.props.dispatch({
        type: actions.POST_VIEW_INITIATING,
        id: curId,
      })
    }
  }

  componentWillUnmount() {
    this.props.dispatch({ type: actions.POST_VIEW_RESET })
    this.props.dispatch(widgetFilesReset())
  }

  getId = () => this.props.match.params.id

  updateBody = data => {
    this.props.dispatch({
      type: actions.POST_VIEW_UPDATE_BODY,
      id: this.getId(),
      ...data,
    })
  }

  updateInfo = data => {
    this.props.dispatch({
      type: actions.POST_VIEW_UPDATE_HEAD,
      id: this.getId(),
      ...data,
    })
  }

  updateGallery = files => {
    this.props.dispatch({
      type: actions.POST_VIEW_UPDATE_FILES,
      files,
      id: this.getId(),
    })
  }

  render() {
    const { initiated } = this.props

    if (!initiated) {
      return <Loader text={false} type='big' />
    }

    const {
      working,
      notifications,
      match: {
        params: { mode },
      },
      postData,
      user,
      location,
      buildingList,
    } = this.props

    return (
      <div>
        <Notifications
          style={{ marginTop: '1.5rem' }}
          notifications={notifications}
        />
        <PostInfo
          buildingList={buildingList}
          languageCode={user.language_obj.code}
          location={location}
          notifications={notifications}
          postData={postData}
          onSave={this.updateInfo}
        />
        <PostBody
          mode={EDIT_MODE === mode}
          postData={postData}
          working={working}
          onGallerySave={this.updateGallery}
          onSave={this.updateBody}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state.postView,
  user: getUser(state),
  buildingList: state.buildingList.buildings,
})

export default connect(mapStateToProps)(PostView)
