// @flow

import React, { useEffect, useState, useRef } from 'react'
import moment from 'moment'
import OutsideClick from 'react-onclickout'
import classNames from 'classnames'
import type { Node } from 'react'

import ReactCalendar from '../../../filters/CalendarFilter/ReactCalendar'
import Button from '../../../Button'
import NewSelectSimple from '../../../NewSelectSimple'
import { getShortDateFormat } from '../../../../utils/utils'

import styles from './ActivityModalDeadline.module.scss'

type Props = {
  deadlineDate: string,
  deadlineTime: string,
  error?: string,
  onChangeDate: string => void,
  onChangeTime: Object => void,
  placeholder: string,
  toggleMenuCb?: boolean => void,
}

const ActivityModalDeadline = (props: Props): Node => {
  const { error, placeholder, deadlineDate, deadlineTime } = props

  const [menuIsOpen, setMenuOpen] = useState(false)
  const [position, setPosition] = useState('bottom')

  const selectRef = useRef()

  const getTime = () => {
    return [...new Array(95).keys()].reduce(
      acc => {
        const currentObj = acc[acc.length - 1]
        const currentMinute = currentObj.minute
        const nextMinute = currentMinute + 15
        const currentHour = currentObj.hour

        if (nextMinute % 60 === 0) {
          acc.push({ hour: currentHour + 1, minute: 0 })
        } else {
          acc.push({ hour: currentHour, minute: nextMinute })
        }

        return acc
      },
      [{ hour: 0, minute: 0 }]
    )
  }

  const getOptions = () =>
    getTime().map(time => ({
      value: time.hour * 60 + time.minute,
      label: `${time.hour < 10 ? '0' : ''}${time.hour} : ${
        time.minute < 10 ? '0' : ''
      }${time.minute}`,
    }))

  useEffect(() => {
    if (props.toggleMenuCb) {
      props.toggleMenuCb(menuIsOpen)
    }
  }, [menuIsOpen])

  const handleToggle = () => {
    if (!menuIsOpen) {
      const isBottom =
        selectRef.current &&
        window.innerHeight - selectRef.current.getBoundingClientRect().bottom >
          380

      setPosition(isBottom ? 'bottom' : 'top')
    }

    setMenuOpen(!menuIsOpen)
  }

  const handleUpdateDate = date =>
    props.onChangeDate(moment(date).format('YYYY-MM-DD'))

  const handleClickOut = () => setMenuOpen(false)

  const calendarClass = classNames(styles.calendar, styles[position])

  const options = getOptions()

  const value = options.find(option => option.value === deadlineTime)

  const handleClear = () => {
    props.onChangeTime({ value: '' })
    props.onChangeDate('')
  }

  return (
    <OutsideClick onClickOut={handleClickOut}>
      <div className={styles.deadline} ref={selectRef}>
        <div className={styles.date}>
          <Button.NewSelect
            placeholder={placeholder}
            selectedItems={deadlineDate ? [deadlineDate] : []}
            getSelectedLabel={value => getShortDateFormat(value)}
            menuIsOpen={menuIsOpen}
            onClick={handleToggle}
          />
          {menuIsOpen && (
            <div className={calendarClass}>
              <ReactCalendar
                single
                minYear={1900}
                maxYear={moment().get('year') + 5}
                onValueChange={handleUpdateDate}
                onChange={date => {
                  handleUpdateDate(date[0])
                  setMenuOpen(false)
                }}
              />
            </div>
          )}
        </div>
        <div className={styles.time}>
          <NewSelectSimple
            toggleMenuCb={props.toggleMenuCb}
            error={!!error}
            disabled={!deadlineDate}
            options={options}
            value={value}
            onChange={props.onChangeTime}
          />
        </div>
        <Button.Delete
          disabled={!deadlineDate}
          className={styles.clear}
          onClick={handleClear}
        />
      </div>
    </OutsideClick>
  )
}

export default ActivityModalDeadline
