// @flow

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'
import Cropper from 'react-cropper'

import Button from '../Button'
import { FILE_ACCEPT_TYPES } from '../../containers/FilesUpload'
import { MAX_SIZE } from '../CompanyImage/CompanyImage.constants'
import Modal from '../Modal'
import { removeFile } from '../../utils/file'
import NewFilesUpload from '../NewFilesUpload'

import 'cropperjs/dist/cropper.css'

export const IMAGE_TYPES = {
  avatar: 'avatar',
  background: 'background',
  mobile: 'mobile',
  web: 'web',
}

const CROP_PROPS = {
  [IMAGE_TYPES.avatar]: {
    initialAspectRatio: 170 / 170,
    aspectRatio: 1 / 1,
    minCropBoxWidth: 170,
  },
  [IMAGE_TYPES.background]: {
    initialAspectRatio: 170 / 170,
    minCropBoxWidth: 170,
  },
  [IMAGE_TYPES.web]: {
    minCropBoxWidth: 182,
    minCropBoxHeight: 135,
  },
  [IMAGE_TYPES.mobile]: {
    initialAspectRatio: 256 / 256,
    minCropBoxWidth: 256,
  },
}

type ImageType = 'avatar' | 'background' | 'mobile' | 'web'

type Props = {
  height: number,
  image: Object,
  onCancel: () => void,
  onSave: Object => void,
  t: string => string,
  titleText: string,
  type: ImageType,
  width: number,
}
type State = {
  canCrop: boolean,
  image: Object,
  uploading: boolean,
}

class LoadImagePopup extends Component<Props, State> {
  constructor(props) {
    super(props)

    this.state = {
      canCrop: false,
      image: this.props.image,
      uploading: false,
    }

    this.cropperRef = React.createRef()
  }

  componentDidMount = () => {
    if (document.body) {
      document.body.style.overflowY = 'hidden'
    }
  }

  componentWillUnmount = () => {
    if (document.body) {
      document.body.style.overflowY = 'scroll'
    }
  }

  onCompleteCrop = () => {
    if (this.cropperRef.current) {
      this.setState({ canCrop: true })
    }
  }

  startUpload = () => {
    this.setState({ uploading: true })
  }

  finishUpload = (images: Array<Object>) => {
    if (images.length) {
      this.setState({ image: images[0] })
    }

    this.setState({ uploading: false })
  }

  cancel = () => {
    const { image } = this.state
    removeFile(image.id)

    this.props.onCancel()
  }

  save = () => {
    const { image } = this.state

    const dataUrl = this.cropperRef.current
      .getCroppedCanvas()
      .toDataURL(image.mime_type, 1.0)

    this.props.onSave({
      dataUrl,
      filename: image.filename,
    })

    if (image.id) {
      removeFile(image.id)
    }
  }

  handleInitialize = instance => {
    this.cropperRef.current = instance
  }

  render() {
    const { height, width, titleText, type } = this.props
    const { canCrop, image, uploading } = this.state

    const modalClass = classnames({
      'working-overlay': uploading,
      'cropper-wrap-box-black': image.mime_type !== 'image/png',
    })
    // 400 is max due to laptop size restrictions
    const cropBoxHeight = 400
    const cropBoxWidth = 400

    return (
      <Modal isOpen>
        <div className={modalClass}>
          <button
            className='modal__close'
            type='button'
            onClick={this.cancel}
          />
          <div className='m-a04'>
            <section className='photobooth'>
              <div className='photobooth__title'>{titleText}</div>
              <div className='photobooth__spec'>
                {this.props.t('LoadImageSize')} {width}x{height} px
              </div>
              <div className='photobooth__wrapper'>
                <div className='photobooth__viewer'>
                  <div
                    className={`photobooth__frame photobooth__frame--${type}`}
                  >
                    <Cropper
                      src={image.origin}
                      className='cropper-wrapper'
                      guides={false}
                      crop={this.onCompleteCrop}
                      style={{ height: cropBoxHeight, width: cropBoxWidth }}
                      crossDomain='use-credentials'
                      onInitialized={this.handleInitialize}
                      {...CROP_PROPS[type]}
                    />
                  </div>
                </div>
              </div>
              <div className='photobooth__controls'>
                <Button.Save
                  disabled={!canCrop}
                  type='button'
                  onClick={this.save}
                >
                  {this.props.t('Save')}
                </Button.Save>
                <Button.Cancel type='button' onClick={this.cancel}>
                  {this.props.t('Cancel')}
                </Button.Cancel>
                <NewFilesUpload
                  useBase64
                  accept={FILE_ACCEPT_TYPES.rasterImages}
                  maxSize={MAX_SIZE[type]}
                  className='photobooth__01 button button--large button--danger-3'
                  onFinishUpload={this.finishUpload}
                  onStartUpload={this.startUpload}
                >
                  {uploading && <span className='button__spinner' />}
                  {this.props.t('LoadImageAnother')}
                </NewFilesUpload>
              </div>
            </section>
          </div>
        </div>
      </Modal>
    )
  }
}

export default withTranslation('Modal')(LoadImagePopup)
