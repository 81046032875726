// @flow

import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import { getFont, patchFont } from '../../../core/api/api.mail'
import { getEmailFonts } from '../../../utils/commonSelectors'
import { SimpleBlock, Header } from '../../Block'
import NewSelectSimple from '../../NewSelectSimple'
import Loader from '../../Loader'
import Settings from '../../Settings'
import { FAMILY, SIZE } from './FontSetting.constants'

import styles from './FontSetting.module.scss'

type Props = { accountId: number }

const FontSetting = (props: Props): Node => {
  const { accountId } = props

  const [isLoading, setLoading] = useState(true)
  const [fontData, setFontData] = useState(null)

  const { t } = useTranslation('SettingsExternalEmail')

  const { fonts, sizes } = useSelector(getEmailFonts)

  const getInitialValues = () => {
    if (!fontData) {
      return { [FAMILY]: null, [SIZE]: null }
    }

    const { font: initialFamily, size: initialSize } = fontData

    const family = { value: initialFamily.id, label: initialFamily.name }
    const size = { value: initialSize.id, label: `${initialSize.size}px` }

    return { [FAMILY]: family, [SIZE]: size }
  }

  useEffect(() => {
    getFont(accountId)
      .then(setFontData)
      .finally(() => setLoading(false))
  }, [])

  const formik = useFormik({
    validateOnChange: true,
    enableReinitialize: true,
    initialValues: {
      ...getInitialValues(),
    },
    validationSchema: Yup.object({
      [FAMILY]: Yup.object().shape({
        value: Yup.number(),
        label: Yup.string(),
      }),
      [SIZE]: Yup.object().shape({
        value: Yup.number(),
        label: Yup.string(),
      }),
    }),
    onSubmit: ({ [FAMILY]: family, [SIZE]: size }) => {
      patchFont(accountId, { font: family.value, size: size.value }).then(
        setFontData
      )
    },
  })

  const familyOptions = fonts.map(font => ({
    value: font[0],
    label: font[1],
  }))

  const sizeOptions = sizes.map(size => ({
    value: size[0],
    label: `${size[1]}px`,
  }))

  const handleChangeFamily = option => formik.setFieldValue(FAMILY, option)
  const handleChangeSize = option => formik.setFieldValue(SIZE, option)

  return (
    <SimpleBlock>
      <Header header>{t('FontSettingHeader')}</Header>
      {isLoading ? (
        <Loader text={false} type='medium' />
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <Settings.Row label={t('FontFamilyLabel')}>
            <NewSelectSimple
              disabled={formik.isSubmitting}
              options={familyOptions}
              value={formik.values[FAMILY]}
              className={styles.select}
              onChange={handleChangeFamily}
            />
          </Settings.Row>
          <Settings.Row label={t('FontSizeLabel')}>
            <NewSelectSimple
              className={styles.select}
              disabled={formik.isSubmitting}
              options={sizeOptions}
              value={formik.values[SIZE]}
              onChange={handleChangeSize}
            />
          </Settings.Row>
          <Settings.Actions
            withChanges={formik.dirty}
            isValid={formik.isValid}
            working={isLoading || formik.isSubmitting}
            onCancel={formik.handleReset}
          />
        </form>
      )}
    </SimpleBlock>
  )
}

export default FontSetting
