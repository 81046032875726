/**
 * @flow
 * @relayHash 50efad51e81db113afe08c272f0f3838
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type OneClickOrderMutationInput = {|
  offerId?: ?string,
  comment?: ?string,
  clientMutationId?: ?string,
|};
export type OfferProfileMutationVariables = {|
  input: OneClickOrderMutationInput
|};
export type OfferProfileMutationResponse = {|
  +oneClickOrder: ?{|
    +order: ?{|
      +id: string,
      +orderNo: ?string,
      +cost: number,
      +pmc: string,
      +offer: ?any,
    |}
  |}
|};
export type OfferProfileMutation = {|
  variables: OfferProfileMutationVariables,
  response: OfferProfileMutationResponse,
|};
*/


/*
mutation OfferProfileMutation(
  $input: OneClickOrderMutationInput!
) {
  oneClickOrder(input: $input) {
    order {
      id
      orderNo
      cost
      pmc
      offer
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "OneClickOrderMutationInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "oneClickOrder",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "OneClickOrderMutationPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "order",
        "storageKey": null,
        "args": null,
        "concreteType": "CustomerOrderNode",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "orderNo",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "cost",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "pmc",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "offer",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "OfferProfileMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "OfferProfileMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "OfferProfileMutation",
    "id": null,
    "text": "mutation OfferProfileMutation(\n  $input: OneClickOrderMutationInput!\n) {\n  oneClickOrder(input: $input) {\n    order {\n      id\n      orderNo\n      cost\n      pmc\n      offer\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5c0640441760d322c1bfe1d28b0f822f';
module.exports = node;
