// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import ModalBody from '../Modal/ModalBody'
import ModalRow from '../Modal/ModalRow'
import ModalButtons from '../Modal/ModalButtons'
import Button from '../Button'
import ModalText from '../Modal/ModalText'

type Props = {|
  onClose: () => void,
  onReject: () => void,
|}

const CancelModal = (props: Props): Node => {
  const { t } = useTranslation('WizardPopup')

  return (
    <>
      <ModalBody>
        <ModalRow>
          <ModalText text={t('CloseConfirmationText')} />
        </ModalRow>
      </ModalBody>
      <ModalButtons>
        <Button.Save onClick={props.onClose}>{t('Common:yes')}</Button.Save>
        <Button.Cancel onClick={props.onReject}>
          {t('CancelRequest')}
        </Button.Cancel>
      </ModalButtons>
    </>
  )
}

export default CancelModal
