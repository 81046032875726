// @flow

import React, { useState, useEffect } from 'react'
import styles from '../MailReplyForm.module.scss'
import type { Node } from 'react'
import { useTranslation } from 'react-i18next'
import Checkbox from '../../Checkbox'
import ConfirmationPopup from '../../modals/ConfirmationPopup'
import { TEMPLATE_TYPE } from '../../../constants'
import { getUserId } from '../../../utils/utils'
import { useUser } from '../../../hooks'
import DocumentTemplatesModal from './DocumentTemplatesModal'
import Modal from '../../Modal'
import Icon from '../../Icon'
import OutsideClick from 'react-onclickout'
import NewFilesUpload from '../../NewFilesUpload'
import ExpandArrow from '../../ExpandArrow'
import classnames from 'classnames'

const LIMIT_USERS_COUNT = 50
const { DOCS } = TEMPLATE_TYPE

type Props = {
  isCreate: boolean,
  isMass: boolean,
  isRequestThread: boolean,
  onAnalyzeDocs?: Object => void,
  onRemoveDocs?: () => void,
  onShowUpload?: (Array<Object>) => void,
  onToggleMass: () => void,
  onUpdateFiles?: (Array<Object>) => void,
  proceedExt?: boolean,
}

const ExtraTools = (props: Props): Node => {
  const {
    isCreate,
    values: { to },
    isRequestThread,
    onToggleMass,
    isMass,
    files,
    onOpenFiles,
  } = props

  const { t } = useTranslation('Mail')
  const [docTemplatesModalOpen, setDocTemplatesModalOpen] = useState(false)
  const [docTemplates, setDocTemplates] = useState([])
  const [docTemplateId, setDocTemplateId] = useState(0)
  const [extVars, setExtVars] = useState({})
  const [modal, setModal] = useState(null)
  const [isOpen, setOpen] = useState(false)

  const user = useUser()
  const userId = getUserId(user)

  useEffect(() => {
    if (props.proceedExt) {
      showDocTemplatesModal(docTemplates[0].id)
    }
  }, [props.proceedExt])

  useEffect(() => {
    setDocTemplates(
      docTemplates.filter(d => files.map(f => f.id).includes(d.id))
    )
  }, [files])

  useEffect(() => {
    if (docTemplates.length === 0) {
      setExtVars({})
      setDocTemplateId(0)
      props.onRemoveDocs()
    }
  }, [docTemplates])

  const showDocTemplatesModal = id => {
    setDocTemplateId(id || 0)
    setDocTemplatesModalOpen(true)
  }

  const hideModal = () => {
    setModal(null)
  }

  const handleChangeMass = () => {
    if (isMass && to.length > LIMIT_USERS_COUNT) {
      setModal(
        <ConfirmationPopup
          title={t('Attention')}
          text={t('SwitchWarning', { limit: LIMIT_USERS_COUNT })}
          cancel={t('OkButton')}
          showOk={false}
          onClose={hideModal}
        />
      )
    } else {
      onToggleMass(!isMass)
    }
  }

  const hideDocTemplatesModal = () => setDocTemplatesModalOpen(false)

  const onAddDocTemplate = (template, externalVars) => {
    /* TEMPORARILy AVAILABLE ONLY ONE DOC TEMPLATE */

    setDocTemplates([template])

    const [currentTemplate] = docTemplates

    props.onUpdateFiles([
      ...files.filter(f => f.id !== currentTemplate?.id),
      {
        id: template.id,
        name: `${template.name}.pdf`,
        size: template.external_size,
        doc: true,
      },
    ])

    setExtVars(externalVars)

    props.onAnalyzeDocs({
      author_id: userId,
      id: template.id,
      templateType: DOCS,
      externalVars,
    })
  }

  const onOpenAttachMenu = () => setOpen(!isOpen)

  const handleClose = () => setOpen(false)
  const direction = isOpen ? 'bottom' : 'top'

  const itemClass = classnames('dropdown__list-item', styles.attachItem)

  const onUpload = (uploaded, replace) => {
    props.onUpload(uploaded, replace)
    handleClose()
  }

  const actionClass = classnames(styles.action, {
    [styles['action-first']]: !isCreate,
  })

  return (
    <>
      <div className={styles.tools}>
        {isCreate && (
          <Checkbox
            text={t('MassMailing')}
            checked={isMass}
            onChange={handleChangeMass}
          />
        )}
        <div className={actionClass}>
          {isRequestThread ? (
            <OutsideClick onClickOut={handleClose}>
              <div className={styles.attach} onClick={() => onOpenAttachMenu()}>
                <Icon colorOnHover className={styles.icon} id='attachFile' />
                <span className={styles.title}>{t('Attach')}</span>
                <ExpandArrow className={styles.arrow} direction={direction} />
              </div>
              {isOpen && (
                <div className={styles.attachDropdown}>
                  <NewFilesUpload
                    multiple
                    className={classnames(styles.attach, itemClass)}
                    onShowUpload={props.onShowUpload}
                    onFinishUpload={onUpload}
                  >
                    <span>{t('FromDesktop')}</span>
                  </NewFilesUpload>
                  <div className={itemClass} onClick={onOpenFiles}>
                    <span>{t('FromServiceRequest')}</span>
                  </div>
                </div>
              )}
            </OutsideClick>
          ) : (
            <NewFilesUpload
              multiple
              noDragEventsBubbling
              className={styles.attach}
              onFinishUpload={props.onUpload}
              onShowUpload={props.onShowUpload}
            >
              <Icon colorOnHover className={styles.icon} id='attachFile' />
              <span className={styles.title}>{t('Attach')}</span>
            </NewFilesUpload>
          )}
        </div>
        <div className={styles.action} onClick={() => showDocTemplatesModal()}>
          <Icon
            colorOnHover
            className={classnames(styles.google, styles.icon)}
            id='googleDrive'
          />
          <span className={styles.title}>{t('GoogleTemplates')}</span>
        </div>
      </div>
      <Modal
        contentLabel=''
        className='Modal__Bootstrap modal-dialog'
        isOpen={!!modal}
        onRequestClose={hideModal}
      >
        {modal}
      </Modal>
      {docTemplatesModalOpen && (
        <DocumentTemplatesModal
          isOpen={docTemplatesModalOpen}
          templateId={docTemplateId}
          extVars={extVars}
          folderType={
            isMass ? 'mass_emailing' : isRequestThread ? 'request' : 'private'
          }
          onClose={hideDocTemplatesModal}
          onAddTemplate={onAddDocTemplate}
        />
      )}
    </>
  )
}

export default ExtraTools
