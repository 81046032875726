// @flow

import React, { PureComponent } from 'react'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'

import { CellsBar, Cell, Row } from '../Block'
import { EntityIcon } from '../Icon'
import DateTime from '../DateTime'
import SelectMember from '../Select/SelectChatMember'
import Member from '../Select/ValueItem'
import LabelsRow from '../Labels/LabelsRow'
import { getUrlForAvatar, getUserId, getUserName } from '../../utils/utils'
import { ACTIVE_STATUS, USER_GROUPS } from '../../constants/users'

type Props = {
  chatCounter: number,
  chatroomInfo: Object,
  chatroomUuid: string,
  created: string,
  isGerman: boolean,
  isHidden: boolean,
  labels: Array<Object>,
  members: Array<Object>,
  newChatroomMembers: Array<Object>,
  onEditLabels: () => void,
  onEditMembers: () => void,
  onSaveLabels: () => void,
  onUpdate: () => void,
  permissions: Object,
  showStatusMessage: boolean,
  signedInUser: Object,
  t: string => string,
}

class ChatroomInfo extends PureComponent<Props, State> {
  renderMember = user => {
    const { id, owner, group, status, available_to_manager } = user

    return (
      <Member
        margin
        inline
        profile
        round
        isLink={status === 1 && available_to_manager}
        avatar={getUrlForAvatar(user)}
        key={id || owner}
        profileId={id}
        group={group}
        text={getUserName(user)}
      />
    )
  }

  renderChatroomMembers = () => {
    const {
      signedInUser,
      chatroomInfo,
      chatroomUuid,
      onUpdate,
      newChatroomMembers,
      selectChatMembersValue,
    } = this.props

    let { members } = this.props

    let chatroomOwnerId

    if (chatroomUuid === 'create') {
      members = newChatroomMembers
      chatroomOwnerId = getUserId(signedInUser)
    } else {
      chatroomOwnerId = chatroomInfo && getUserId(chatroomInfo)
    }

    const memberIds = selectChatMembersValue
      ? selectChatMembersValue.map(getUserId)
      : []

    if (chatroomUuid === 'create') {
      return (
        <SelectMember
          addChatMembers // TODO prop for chatroom owner - you must set id of chat creator here
          owner={chatroomOwnerId} // TODO prop for controlling if it possible to remove members
          canRemove={getUserId(signedInUser) === chatroomOwnerId}
          valueId={memberIds}
          params={{
            all: signedInUser.group !== USER_GROUPS.dweller,
            group: [
              USER_GROUPS.admin,
              USER_GROUPS.manager,
              USER_GROUPS.dweller,
            ],
            status: ACTIVE_STATUS,
          }}
          onChange={onUpdate}
        />
      )
    }

    return members.map(this.renderMember)
  }

  render() {
    const {
      t,
      members,
      created,
      isHidden,
      labels,
      permissions,
      chatroomInfo,
      onEditMembers,
      chatroomUuid,
    } = this.props

    const flattenLabels = labels.map(obj => {
      if (typeof obj.color === 'object') {
        obj.color = obj.color.value // eslint-disable-line

        return obj
      }

      return obj
    })
    const parent = chatroomInfo && chatroomInfo.parent
    const onEditParticipants =
      permissions.can_edit_members && chatroomUuid !== 'create'
        ? onEditMembers
        : null

    return (
      <section
        className='bar bar--default bar--chatroom'
        style={{
          display: isHidden ? 'none' : 'block',
        }}
      >
        <CellsBar>
          <Cell title={t('Common:CreationDate')}>
            <DateTime dateTime={created} />
          </Cell>
          <Cell title={t('NumberOfParticipants')}>
            {members ? members.length : ''}
          </Cell>
        </CellsBar>
        {parent && (
          <Row title={t('RequestTitle')}>
            <span className='request-icon--wrapper'>
              <EntityIcon id='request' />
            </span>
            <Link className='request-title' to={`/request/${parent.id}`}>
              {t('request_number_title')} {parent.request_no}
            </Link>{' '}
            {parent.title}
          </Row>
        )}
        <Row title={t('Common:Participants')} onEdit={onEditParticipants}>
          <div className='chat-members-list'>
            {this.renderChatroomMembers()}
          </div>
        </Row>
        {permissions.can_view_labels && (
          <LabelsRow
            infoData={chatroomInfo}
            permissions={permissions}
            value={flattenLabels}
            onEdit={this.props.onEdit}
            onChange={this.props.onSaveLabels}
          />
        )}
      </section>
    )
  }
}

const mapStateToProps = state => ({
  selectChatMembersValue: state.selectChatMember.value,
})

export default compose(
  withTranslation('Chatroom'),
  connect(mapStateToProps)
)(ChatroomInfo)
