// @flow

import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { isEqual } from 'lodash-es'

import { ALL_PAGES } from '../../../constants'
import Button from '../../../components/Button'
import ModalCloseButton from '../../Modal/ModalCloseButton'
import ModalHead from '../../Modal/ModalHead'
import ModalBody from '../../Modal/ModalBody'
import ModalButtons from '../../Modal/ModalButtons'
import { useOverflow, useSelected } from '../../../hooks'
import api from '../../../core/api'
import ConfirmationPopup from '../ConfirmationPopup'
import CategoryPicker from './CategoryPicker'
import CategoryList from './CategoryList'
import { getFetchedItems } from '../../../utils/utils'

import styles from './CategoryPopup.module.scss'

const CategoryPopup = props => {
  const { user, userIds } = props

  const [isLoading, setLoading] = useState(true)
  const [isConfirmed, setConfirmed] = useState(false)
  const [initialItems, setInitialItems] = useState(null)
  const [isAllLoading, setAllLoading] = useState(false)

  const { t } = useTranslation('CategoryPopup')

  const [selectedItems, changeSelected, setSelected] = useSelected()

  useOverflow()

  useEffect(() => {
    if (userIds) {
      setInitialItems([])
      setLoading(false)

      return
    }

    api.requestCategory
      .get({ page_size: ALL_PAGES, manager: user.id })
      .then(response => {
        const items = getFetchedItems(response)

        setSelected(items)
        setInitialItems(items)

        setLoading(false)
      })
  }, [])

  const bodyClass = classnames({
    'working-overlay': isLoading || isAllLoading,
  })

  const handleCloseConfirm = () => {
    setConfirmed(false)
  }

  const handleSubmit = () => {
    setLoading(true)

    const ids = selectedItems.map(({ id }) => id)

    props.onOk(ids)
  }

  const handleSave = () => {
    if (userIds) {
      handleSubmit()

      return
    }

    if (selectedItems.length === 0) {
      setConfirmed(true)
    } else {
      handleSubmit()
    }
  }

  const isDisabled = () => {
    return isEqual(initialItems, selectedItems)
  }

  if (isConfirmed) {
    return (
      <ConfirmationPopup
        title={t('ConfirmTitle')}
        text={t('ConfirmText')}
        confirm={t('Ok')}
        cancel={t('Cancel')}
        onClose={handleCloseConfirm}
        onOk={handleSubmit}
      />
    )
  }

  return (
    <div className={styles.modal}>
      <ModalHead title={t('ModalTitle')} />
      <ModalCloseButton onClose={props.onClose} />
      <ModalBody className={bodyClass}>
        <CategoryPicker
          selected={selectedItems}
          changeSelected={changeSelected}
          setSelected={setSelected}
          setAllLoading={setAllLoading}
        />
        <CategoryList
          changeSelected={changeSelected}
          isloading={isLoading}
          selected={selectedItems}
        />
      </ModalBody>
      <ModalButtons>
        <Button.Save disabled={isLoading || isDisabled()} onClick={handleSave}>
          {t('Save')}
        </Button.Save>
        <Button.Cancel disabled={isLoading} onClick={props.onClose}>
          {t('Cancel')}
        </Button.Cancel>
      </ModalButtons>
    </div>
  )
}

export default CategoryPopup
