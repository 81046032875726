// @flow

import React from 'react'
import type { Node } from 'react'

import TableHead from './TableHead'
import TableBody from './TableBody'
import NewTable from '../../NewTable'

type Props = {
  loading: boolean,
  setCloningWorkflowId: number => void,
  workflows: Array<Object>,
}

const WorkflowListTable = (props: Props): Node => {
  const { loading, workflows, setModal, load } = props

  return (
    <NewTable loading={loading}>
      <TableHead />
      <TableBody
        load={load}
        setModal={setModal}
        workflows={workflows}
        loading={loading}
        setCloningWorkflowId={props.setCloningWorkflowId}
      />
    </NewTable>
  )
}

export default WorkflowListTable
