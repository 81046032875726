import React, { PureComponent } from 'react'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'

import Button from '../Button'
import styles from './LabelItem.module.scss'
import Icon from '../../../static/icons/check.svg'

class LabelItem extends PureComponent {
  onClickLabel = () => {
    this.props.onSelect()
  }

  render() {
    const {
      label,
      label: { name, id, checked },
    } = this.props
    const color = label.color.value
    const labelClassName = classnames('label-item__name', styles.label)

    return (
      <div
        key={id}
        className='label-item'
        style={{ backgroundColor: color }}
        onClick={this.onClickLabel}
      >
        {checked && <Icon />}
        <span className={labelClassName} title={name}>
          {name}
        </span>
        <Button.Edit onClick={this.props.onEdit} />
      </div>
    )
  }
}

export default withTranslation('Labels')(LabelItem)
