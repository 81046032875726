// @flow

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'
import { parseInt } from 'lodash-es'
import { compose } from 'redux'
import { connect } from 'react-redux'

import RouterTabs from '../Tabs/RouterTabs'
import BuildingDocuments from './BuildingDocuments'
import Pan from '../Tabs/Pan'
import Modal from '../Modal'
import Loader from '../Loader'
import BuildingInfo from '../Building/BuildingInfo'
import BuildingFlats from '../Building/BuildingFlats'
import BuildingDwellers from '../Building/BuildingDwellers'
import BuildingGroupList from '../Building/BuildingGroupList/BuildingGroupList'
import Contractors from '../Contractors/Contractors'
import EmptyList from '../EmptyList'
import AddFlatPopup from '../modals/AddFlatPopup'
import AddUserPopup from '../modals/AddUserPopup/AddUserPopup'
import ViewTab from '../Building/ViewTab'
import NewsList from '../../containers/NewsList'
import { getCurrentFilters } from '../../utils/routing'
import * as actions from './BuildingView.actionTypes'
import * as flatActions from '../../components/Building/BuildingFlats/BuildingFlats.actionTypes'
import * as dwellerActions from '../../components/Building/BuildingDwellers/BuildingDwellers.actionTypes'
import { getContractors } from '../../core/api/api.contractor'
import { S_ADDRESS, S_DWELLERS, S_FLATS } from './BuildingView.constants'
import { getUser } from '../../utils/commonSelectors'
import ProfileAttrs from '../../components/ProfileAttrs'
import BuildingInformation from '../Building/BuildingInformation'

type Props = {
  collapse: Object,
  data: Object,
  dispatch: Object => void,
  error: ?Object,
  flat: Object,
  initiated: boolean,
  location: Object,
  match: Object,
  modal: any,
  reloadFlat: boolean,
  t: string => string,
  user: Object,
  working: boolean,
}
type State = {
  flatsCount: number,
  groupsCount: number,
}

class BuildingView extends Component<Props, State> {
  state = {
    flatsCount: 0,
    groupsCount: 0,
    contractorsCount: 0,
  }

  componentDidMount() {
    const { user: signedInUser } = this.props

    this.props.dispatch({
      type: actions.BUILDING_VIEW_INITIATING,
      id: this.getId(),
      signedInUser,
    })
    this.checkContractorsCount()
  }

  componentDidUpdate(prevProps: Props) {
    const {
      match,
      data,
      data: { id },
    } = this.props

    const { user } = prevProps

    if (data && id && +match.params.buildingId !== id) {
      this.props.dispatch({
        type: actions.BUILDING_VIEW_INITIATING,
        id: this.getId(),
        signedInUser: user,
      })
    }

    if (user && user.flat_obj) {
      const {
        user: {
          flat_obj: { building },
        },
        match: {
          params: { buildingId: currentId },
        },
      } = prevProps

      const {
        match: {
          params: { buildingId: nextId },
        },
      } = this.props

      if (nextId === building.toString() && nextId !== currentId) {
        this.props.dispatch({
          type: actions.BUILDING_VIEW_INITIATING,
          id: nextId,
          signedInUser: user,
        })
      }
    }
  }

  getFilters = () => {
    const { location } = this.props

    return getCurrentFilters(location)
  }

  checkContractorsCount = () => {
    getContractors({ building: this.getId() }).then(data => {
      if (data.results?.objects?.length) {
        this.setState({ contractorsCount: data.results.objects.length })
      }
    })
  }

  componentWillUnmount() {
    this.props.dispatch({
      type: dwellerActions.BUILDING_DWELLERS_RESET,
    })
    this.props.dispatch({
      type: actions.BUILDING_VIEW_STATE_RESET,
    })
  }

  onSuccessAddUser = () => {
    this.props.dispatch({
      type: flatActions.BUILDING_FLATS_INITIATING,
      id: this.getId(),
    })

    this.props.dispatch({
      type: dwellerActions.BUILDING_DWELLERS_INITIATING,
      params: {
        building: this.getId(),
        ...this.getFilters(),
      },
    })
    this.props.dispatch({ type: actions.BUILDING_VIEW_RELOAD })
  }

  getId = () => parseInt(this.props.match.params.buildingId)

  getFlatId = () => parseInt(this.props.flat.id)

  getHeaderClass = section => {
    const { collapse } = this.props

    return classnames('unit__title', {
      'unit__title-collapse-open': !collapse[section],
    })
  }

  showModal = modal => {
    this.props.dispatch({ type: actions.BUILDING_VIEW_SHOW_MODAL, modal })
  }

  hideModal = () => {
    this.props.dispatch({ type: actions.BUILDING_VIEW_HIDE_MODAL })
  }

  showFlatPopup = () => {
    const { data } = this.props
    this.showModal(
      <AddFlatPopup
        address={data.address_obj.value}
        buildingId={data.id}
        onHide={this.hideModal}
      />
    )
  }

  showUserPopup = () => {
    const {
      data: {
        address_obj: { value },
      },
    } = this.props
    this.showModal(
      <AddUserPopup
        buildingId={this.getId()}
        buildingAddress={value}
        onHide={this.hideModal}
        onSave={this.onSuccessAddUser}
      />
    )
  }

  toggleSection = section => {
    this.props.dispatch({
      type: actions.BUILDING_VIEW_TOGGLE_COLLAPSE,
      section,
    })
  }

  updateFlatsCount = count => {
    this.setState({ flatsCount: count })
  }

  update = params => {
    this.props.dispatch({
      type: actions.BUILDING_VIEW_NEED_UPDATE,
      id: this.getId(),
      params,
    })
  }

  updateVirtualFlat = params => {
    this.props.dispatch({
      type: actions.BUILDING_VIEW_VIRTUAL_FLAT_NEED_UPDATE,
      id: this.getFlatId(),
      params,
    })
  }

  isAdminOrManager = () => {
    const { user } = this.props

    return ['admin', 'manager'].includes(user.group)
  }

  isDweller = () => {
    const { user } = this.props

    return ['dweller', 'landlord'].includes(user.group)
  }

  renderAddress = () => {
    const { data, initiated, working, collapse } = this.props

    return (
      <ViewTab.Address
        noMargin
        collapsed={collapse.address}
        data={data}
        initiated={initiated}
        working={working}
        onCollapse={() => this.toggleSection(S_ADDRESS)}
        onUpdate={val => this.update({ address: val })}
      />
    )
  }

  renderDocuments = () => {
    const {
      data: { id: buildingID, permissions, directory },
    } = this.props

    if (!directory) {
      return null
    }

    if (!permissions.can_view_documents) {
      return null
    }

    return (
      <Pan
        id='documents'
        label={this.props.t('Documents')}
        linkHash='#documents'
      >
        <BuildingDocuments
          buildinId={buildingID}
          directoryId={directory.id}
          isDweller={this.isDweller()}
          buildingPermissions={this.isAdminOrManager() ? permissions : null}
        />
      </Pan>
    )
  }

  render() {
    if (!this.props.initiated) {
      return <Loader type='big' text={false} />
    }

    const {
      collapse,
      data,
      data: {
        address_obj: address,
        flat_count: flatCount,
        permissions,
        attributes,
      },
      modal,
      reloadFlat,
      t,
      user,
      working,
      location,
      history,
      error,
    } = this.props
    const { flatsCount, groupsCount } = this.state
    const {
      permissions: { can_change_dweller_role: canChangeDwellerRole },
    } = user

    const adminOrManager = this.isAdminOrManager()
    const buildingId = this.getId()
    let buildingContent
    const tabsClass = classnames('tabs', 'tabs-style-transparent', {
      'tabs-style-scroll':
        permissions.can_view_flats && this.state.contractorsCount > 0,
    })

    if (adminOrManager) {
      buildingContent = (
        <RouterTabs className={tabsClass} openFirst='address'>
          {permissions.can_view_info && (
            <Pan
              id={'building-information-tab'}
              label={t('Information')}
              linkHash='#information'
            >
              <BuildingInformation buildingId={buildingId} />
            </Pan>
          )}
          {permissions.can_view_flats && (
            <Pan
              id='house-main-info-tab'
              label={t('MainInfoTab')}
              linkHash='#main'
            >
              <section className='unit unit--default unit--collapsed unit--nomargin'>
                <h2
                  className={this.getHeaderClass(S_FLATS)}
                  onClick={() => this.toggleSection(S_FLATS)}
                >
                  {t('Flats')}&nbsp;
                  {!!flatsCount && (
                    <span className='unit__title-num'>{flatsCount}</span>
                  )}
                </h2>
                {!collapse.flats &&
                  (flatsCount || flatCount || reloadFlat ? (
                    <BuildingFlats
                      address={address.value}
                      id={this.getId()}
                      reload={reloadFlat}
                      onCountUpdate={this.updateFlatsCount}
                    />
                  ) : (
                    <EmptyList
                      embedded
                      btnText={this.props.t('FlatsEmptyButton')}
                      canAdd={permissions.can_edit}
                      icon='apartment'
                      title={this.props.t('FlatsEmptyLabel')}
                      onClick={this.showFlatPopup}
                    />
                  ))}
              </section>
            </Pan>
          )}
          <Pan
            id='building-address-tab'
            label={t('Address')}
            linkHash='#address'
          >
            {this.renderAddress()}
          </Pan>
          {permissions.can_view_building_groups && (
            <Pan
              key='building-groups'
              label={t('GroupListTitle')}
              linkHash='#building-groups'
            >
              <section className='unit unit--default unit--margin'>
                <h2 className={this.getHeaderClass(S_FLATS)}>
                  {t('GroupListTitle')}&nbsp;
                  {!!groupsCount && (
                    <span className='unit__title-num'>{groupsCount}</span>
                  )}
                </h2>
                <BuildingGroupList
                  buildingId={buildingId}
                  setGroupCount={groupsCount => this.setState({ groupsCount })}
                />
              </section>
            </Pan>
          )}
          {permissions.can_view_dwellers && (
            <Pan
              id='house-dwellers-tab'
              label={t('DwellersAndLandlords')}
              linkHash='#dwellers'
            >
              <section className='unit unit--default unit--nomargin'>
                <BuildingDwellers
                  headerClass={this.getHeaderClass(S_DWELLERS)}
                  buildingPermissions={permissions}
                  buildingID={this.getId()}
                  buildingAddress={this.props.data.address_obj.value}
                  canChangeRole={canChangeDwellerRole}
                  emptyComponent={
                    <EmptyList
                      embedded
                      btnText={t('DwellersEmptyButton')}
                      canAdd={permissions.can_edit}
                      icon='user'
                      title={t('DwellersEmptyLabel')}
                      onClick={this.showUserPopup}
                    />
                  }
                  onSuccessAddUser={this.onSuccessAddUser}
                />
              </section>
            </Pan>
          )}
          {permissions.can_view_flats && this.state.contractorsCount > 0 && (
            <Pan
              id='building-contractors-tab'
              label={t('Contractors')}
              linkHash='#contractors'
            >
              <Contractors building={this.getId()} />
            </Pan>
          )}
          {permissions.can_view_requests && (
            <Pan
              id='building-requests-tab'
              label={t('Requests')}
              linkHash='#requests'
            >
              <ViewTab.Requests data={data} />
            </Pan>
          )}
          {this.renderDocuments()}
          {permissions.can_view_posts && (
            <Pan key='posts' label={t('Posts')} linkHash='#posts'>
              <NewsList
                additionalQueryParams={{ buildings: this.getId() }}
                emptyBoxTitle={t('Posts')}
              />
            </Pan>
          )}
          {attributes && (
            <Pan
              id='building-tab-attrs'
              label={t('AttributesTabTitle')}
              linkHash='#attrs'
            >
              <ProfileAttrs
                attributes={attributes}
                title={t('AttributesTitle')}
              />
            </Pan>
          )}
        </RouterTabs>
      )
    } else {
      buildingContent = (
        <RouterTabs className='tabs tabs-style-transparent' openFirst='address'>
          {permissions.can_view_flats && (
            <Pan
              id='house-main-info-tab'
              label={t('MainInfoTab')}
              linkHash='#main'
            >
              <section className='unit unit--default unit--collapsed unit--nomargin'>
                <h2
                  className={this.getHeaderClass(S_FLATS)}
                  onClick={() => this.toggleSection(S_FLATS)}
                >
                  {t('Flats')}&nbsp;
                  {!!flatsCount && (
                    <span className='unit__title-num'>{flatsCount}</span>
                  )}
                </h2>
                {!collapse.flats &&
                  (flatsCount || flatCount || reloadFlat ? (
                    <BuildingFlats
                      address={address.value}
                      id={this.getId()}
                      reload={reloadFlat}
                      onCountUpdate={this.updateFlatsCount}
                    />
                  ) : (
                    <EmptyList
                      embedded
                      btnText={this.props.t('FlatsEmptyButton')}
                      canAdd={permissions.can_edit}
                      icon='apartment'
                      title={this.props.t('FlatsEmptyLabel')}
                      onClick={this.showFlatPopup}
                    />
                  ))}
              </section>
            </Pan>
          )}
          <Pan
            id='building-address-tab'
            label={t('Address')}
            linkHash='#address'
          >
            {this.renderAddress()}
          </Pan>
          {this.renderDocuments()}
        </RouterTabs>
      )
    }

    return (
      <div className='content__col'>
        <BuildingInfo
          data={data}
          error={error}
          working={working}
          location={location}
          history={history}
          user={user}
          onSave={this.update}
        />

        {buildingContent}

        <Modal
          contentLabel=''
          className='Modal__Bootstrap modal-dialog'
          isOpen={!!modal}
          onRequestClose={this.hideModal}
        >
          {modal}
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state.buildingView,
  user: getUser(state),
})

export default compose(
  withTranslation('Building'),
  connect(mapStateToProps)
)(BuildingView)
