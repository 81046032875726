// @flow

import { commitMutation, graphql } from 'react-relay'

import environment from '../../../../createRelayEnvironment'

const mutation = graphql`
  mutation OrderProfileStatusMutation(
    $input: CustomerOrderStatusMutationInput!
  ) {
    updateCustomerOrderStatus(input: $input) {
      ids
      status
    }
  }
`

const commit = (orderData, callback, errorCallback) => {
  return commitMutation(environment, {
    mutation,
    variables: {
      input: orderData,
    },
    onCompleted: callback,
    onError: errorCallback,
  })
}

export default { commit }
