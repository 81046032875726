// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'

import { SimpleBlock, Header } from '../../Block'
import NewSelectSimple from '../../NewSelectSimple'
import Settings from '../../Settings'

type Props = {
  onCancel: () => void,
  onSave: () => void,
  onUpdate: () => void,
  profileConfig: Object,
  t: string => string,
  user: Object,
  working: boolean,
}

class CompanyRegistration extends Component<Props> {
  fregOptions = [ // eslint-disable-line
    { value: false, label: this.props.t('DisableFreeRegistration') },
    { value: true, label: this.props.t('EnableFreeRegistration') },
  ]

  render() {
    const {
      working,
      t,
      onUpdate,
      config: { free_registration: freeRegistation },
    } = this.props

    const freeRegistationValue = this.fregOptions.find(
      opt => opt.value === freeRegistation
    )

    return (
      <SimpleBlock working={working}>
        <Header header>{t('RegistationSectionTitle')}</Header>

        <Settings.Row label={t('FreeRegistrationOptionTitle')}>
          <div className='input input--large input--default input--block'>
            <NewSelectSimple
              name='free-registration-settings-select'
              value={freeRegistationValue}
              options={this.fregOptions}
              onChange={option => onUpdate({ free_registration: option.value })}
            />
          </div>
        </Settings.Row>

        <Settings.Actions
          withChanges={this.props.saveButtonActive}
          working={working}
          onSave={this.props.onSave}
          onCancel={this.props.onCancel}
        />
      </SimpleBlock>
    )
  }
}

const mapStateToProps = state => ({
  saveButtonActive: state.settingsCorporate.saveButtonActiveConfig,
})

export default compose(
  connect(mapStateToProps),
  withTranslation('Settings')
)(CompanyRegistration)
