// @flow

import React from 'react'
import { useEffect, useReducer, useState, useRef } from 'react'
import { Editor } from '@tinymce/tinymce-react'
import type { Node } from 'react'
import { useTranslation } from 'react-i18next'
import { getFont, getMailAccountMenuList } from '../core/api/api.mail'
import TrimButton from '../components/Button/Trim'
import styles from './SendMessageForm/SendMessageForm.module.scss'
import {
  NYLAS,
  NYLAS_V3,
} from './Menu/filters/MailsCredentialFilter/MailsCredentialFilter.constants'

type Props = {|
  account?: ?Object,
  addLinks?: boolean,
  autoFocus?: boolean,
  autoResizeOnInit: boolean,
  disabled?: boolean,
  isOnDrag?: boolean,
  maxHeight?: number,
  onBlur?: () => void,
  onChange: string => void,
  onFocus?: () => void,
  onInit?: (Object, Object) => void,
  placeholder?: string,
  resize: boolean,
  setEditorRef?: Object => void,
  showToolbar: boolean,
  textareaName?: string,
  uuid?: string,
  value: string,
  working?: boolean,
|}

const WysiwygEditor = (props: Props): Node => {
  const {
    value,
    placeholder,
    disabled,
    resize,
    autoResizeOnInit,
    maxHeight,
    textareaName,
    showToolbar,
    account,
    working,
    isOnDrag,
    addLinks,
    t,
    uuid,
    autoFocus,
  } = props

  const {
    i18n: { language },
  } = useTranslation()
  const editorRef = useRef(null)
  const [defaultFont, setDefaultFont] = useState({
    size: 14,
    family: 'sans-serif',
  })
  const [isTrimmed, setIsTrimmed] = useState(false)
  const [isNeedToggleThread, setIsNeedToggleThread] = useState(false)
  const [isLoading, setLoading] = useState(true)
  const [accountId, setAccountId] = useState(null)

  const [selectKey, forceUpdate] = useReducer(x => x + 1, 0) // hack

  useEffect(() => {
    if (!!value && uuid && uuid !== 'create') {
      const { allBlockquoteArray } = getParserAndBlockquote(value)

      if (allBlockquoteArray.length > 1) {
        if (!isNeedToggleThread) {
          setIsNeedToggleThread(true)
          forceUpdate()
        }
      }
    }
  }, [uuid, value])

  useEffect(() => {
    if (isNeedToggleThread) {
      const editor = editorRef?.current?.editor

      if (editor) {
        editor.focus()

        toggleTrim(editor, isTrimmed)
      }
    }
  }, [isNeedToggleThread, isTrimmed])

  const toolbar =
    showToolbar &&
    `fontselect fontsizeselect | bold italic underline forecolor | alignroup | numlist bullist ${
      addLinks ? '| link ' : ''
    } | outdent indent | blockquote removeformat`

  const handleBlur = () => {
    if (props.onBlur) {
      props.onBlur()
    }
  }

  const handleFocus = () => {
    if (props.onFocus) {
      props.onFocus()
    }
  }

  const handleInit = (e, editor) => {
    if (props.setEditorRef) {
      props.setEditorRef(editor)
    }

    if (props.onInit) {
      props.onInit(e, editor)
    }
  }

  const toggleTrim = (editor, isTrim) => {
    const text = editor.getContent()
    const { textParser, allBlockquoteArray } = getParserAndBlockquote(text)
    const display = isTrim ? 'block' : 'none'

    allBlockquoteArray.shift()

    for (const entry of allBlockquoteArray) {
      entry.style.display = display
    }

    editor.setContent(textParser.body.innerHTML)
  }

  const getParserAndBlockquote = (text: string) => {
    const textParser = new DOMParser().parseFromString(text, 'text/html')
    const allBlockquote = textParser.querySelectorAll('blockquote')
    const allBlockquoteArray = Array.from(allBlockquote)

    return { textParser, allBlockquoteArray }
  }

  useEffect(() => {
    if (working) {
      return
    }

    if (account?.id && account.id !== accountId) {
      setAccountId(account.id)
      getMailAccountMenuList({ page_size: 100500, count_unread: 0 })
        .then(data => {
          return [...data.own, ...data.corporate, ...data.shared].filter(
            item =>
              (item.outgoing && item.outgoing.id === account.id) ||
              ((item.account_type === NYLAS ||
                item.account_type === NYLAS_V3) &&
                item.id === account.id)
          )
        })
        .then(data =>
          getFont(data[0].id).then(data => {
            setDefaultFont({ size: data.size.size, family: data.font.family })
            setLoading(false)
            forceUpdate()
          })
        )
    } else {
      setLoading(false)
    }
  }, [account, working])

  if (isLoading) {
    return null
  }

  let plugins = ['autoresize', 'lists', 'advlist', 'paste']

  if (addLinks) {
    plugins = [...plugins, 'link']
  }

  return (
    <>
      {props.isOnDrag && (
        <div className={styles.editorDrop}>
          <span>{t('dropFilesHere')}</span>
        </div>
      )}

      {isNeedToggleThread && !!value && (
        <TrimButton
          title={t(isTrimmed ? 'HideQuote' : 'ShowQuote')}
          isTrimmed={isTrimmed}
          showTrimmed={() => setIsTrimmed(prevValue => !prevValue)}
        />
      )}

      <Editor
        key={selectKey}
        value={value}
        disabled={disabled}
        ref={editorRef}
        tinymceScriptSrc='https://files.idwell.com/js/tinymce/5.10.8/tinymce.min.js'
        init={{
          auto_focus: autoFocus,
          menubar: false,
          elementpath: false,
          placeholder,
          entity_encoding: 'raw',
          min_height: 200,
          max_height: maxHeight,
          resize,
          object_resizing: false,
          autoresize_bottom_margin: 0,
          autoresize_on_init: autoResizeOnInit,
          fontsize_formats: '8px 9px 10px 11px 12px 14px 16px',
          textareaName,
          toolbar_groups: {
            alignroup: {
              icon: 'align-left',
              items: 'alignleft aligncenter alignright alignjustify',
            },
          },
          content_style:
            `body {font-family: ${defaultFont.family}; font-size: ${defaultFont.size}px; }` +
            `${
              isNeedToggleThread
                ? 'blockquote blockquote { display: none; }'
                : ''
            }` +
            'p { line-height: 16px; margin: 0; }' +
            'p > img { max-width: 100%; }' +
            'a { color: #0000EE; }',
          font_formats:
            'Arial=Arial,sans-serif; Calibri=calibri,sans-serif; Cambria=Cambria; Monospace=monospace; Plus Jakarta Sans=Plus Jakarta Sans,sans-serif; Sans Serif=sans-serif; Serif=serif; Times New Roman=Times New Roman,sans-serif;  Verdana=Verdana,sans-serif',
          plugins,
          toolbar,
          file_picker_types: 'file image media',
          paste_data_images: false,
          block_unsupported_drop: true,
          automatic_uploads: false,
          link_title: false,
          link_context_toolbar: true,
          target_list: false,
          contextmenu: false,
          language,
        }}
        dragOver={!isOnDrag}
        onBlur={handleBlur}
        onInit={handleInit}
        onFocus={handleFocus}
        onEditorChange={props.onChange}
        onFocusIn={props.setFocused}
      />
    </>
  )
}

WysiwygEditor.defaultProps = {
  autoResizeOnInit: false,
  showToolbar: true,
  resize: true,
}

export default WysiwygEditor
