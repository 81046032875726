// @flow

import { all, put, call, fork, takeLatest } from 'redux-saga/effects'
import { replace } from 'connected-react-router'
import type { Saga } from 'redux-saga'

import api from '../../core/api'
import { newsListInitiated } from '../NewsList/NewsList.actions'
import * as actions from './Profile.actionTypes'
import { serverError, modalError } from '../../components/Layout/Layout.actions'
import { isNil } from 'lodash-es'

const getFilesIds = files => files.map(f => f.id)

function* watchInit() {
  yield takeLatest(actions.PROFILE_INITIATING, init)
}

function* watchUpdateFiles() {
  yield takeLatest(actions.PROFILE_UPDATE_FILES, updateFiles)
}

function* watchUpdate() {
  yield takeLatest(actions.PROFILE_UPDATE, updateProfile)
}

function* init({ id, archived, external, isAdmin }) {
  try {
    if (!isAdmin && !isNil(isAdmin) && external) {
      yield put(replace('/404'))
    } else {
      const getApi = external
        ? api.profile.getExternalContact
        : archived
        ? api.profile.getArchivedProfile
        : api.profile.getProfile

      const data = yield call(getApi, id)

      const posts = yield call(api.newsband.getNews, { owner: id })
      yield put(newsListInitiated(posts))
      yield put({
        type: actions.PROFILE_INITIATED,
        data,
      })
    }
  } catch (error) {
    if (error.message.response.status === 404) {
      yield put(replace('/404'))
    } else {
      yield put(serverError(error))
      yield put({ type: actions.PROFILE_ERROR, error })
    }
  }
}

function* updateFiles({ id, files }) {
  try {
    const ids = getFilesIds(files)
    const params = { files: JSON.stringify(ids) }
    const data = yield call(api.profile.update, id, params)
    yield put({ type: actions.PROFILE_UPDATED, data })
  } catch (error) {
    if (error.message.response.status === 400) {
      yield put(modalError(error.message.response.data))

      return
    }

    yield put(serverError(error))
  }
}

function* updateProfile({ id, params, external }) {
  try {
    const profile = yield call(
      external ? api.profile.externalUpdate : api.profile.update,
      id,
      params
    )
    yield put({ type: actions.PROFILE_UPDATED, data: profile })
  } catch (error) {
    if (error.message.response.status === 400) {
      yield put(modalError(error.message.response.data))

      return
    }

    yield put(serverError(error))
  }
}

export default function* watch(): Saga<void> {
  yield all([fork(watchInit), fork(watchUpdateFiles), fork(watchUpdate)])
}
