// @flow

import React from 'react'

type Props = {
  count?: number,
  displayCount?: number,
  items: ?Array<Object>,
}

const MAX_TITLE_COUNT = 10

const TableCellUsers = (props: Props) => {
  const { items, displayCount = 1 } = props

  if (!items || !items.length) {
    return null
  }

  const count = props.count || items.length
  const getFullName = user =>
    user.fullname || `${[user.name, user.second_name].filter(v => v).join(' ')}`

  const getAvatar = user => user.avatar_obj?.preview || user.avatar
  const title = `${items
    .map(user => getFullName(user))
    .slice(0, MAX_TITLE_COUNT)
    .join(', ')}${items.length > MAX_TITLE_COUNT ? '...' : ''}`
  const displayItems = items.slice(0, displayCount)

  return (
    <div className='table__cell-users' title={title}>
      {displayItems.map(user => (
        <div key={user.id} className='table-item table-item__user'>
          <i className='table-item__user-pic'>
            <img src={getAvatar(user)} alt='user' />
          </i>
          <span className='table-item__user-name'>{getFullName(user)}</span>
        </div>
      ))}
      {count > displayCount && <span>+{count - displayCount}</span>}
    </div>
  )
}

export default TableCellUsers
