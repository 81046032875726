//@flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import type Node from 'react'

import Modal from '../../Modal'
import ModalBody from '../../Modal/ModalBody'
import ModalCloseButton from '../../Modal/ModalCloseButton'
import ModalHead from '../../Modal/ModalHead'
import Radio from '../../Radio'
import Button from '../../Button'

import { useOverflow } from '../../../hooks'
import { useThemeColor } from '../../../hooks'

import styles from './SelectTemplateModal.module.scss'

type Props = {
  isOpen: boolean,
  onClose: () => void,
  onInsertText: () => void,
  onReplaceText: () => void,
}

const SelectTemplateModal = (props: Props): Node => {
  const { isOpen, onClose, onReplaceText, onInsertText } = props
  const { t } = useTranslation('Mail')
  useOverflow()
  const themeColor = useThemeColor()
  const [replace, setReplace] = useState(false)
  const handleChangeReplace = e => setReplace(JSON.parse(e.target.value))
  const onApply = () => (replace ? onReplaceText() : onInsertText())

  return (
    <>
      <Modal isOpen={isOpen} onRequestClose={onClose}>
        <ModalHead title={t('SelectTemplateTitle')} />
        <ModalCloseButton onClose={onClose} />
        <ModalBody
          className={styles.selectTemplate}
          style={{
            '--themeColor': themeColor,
          }}
        >
          <p>{t('SelectTemplateText1')}</p>
          <p>{t('SelectTemplateText2')}</p>
          <div className={styles.actions}>
            <Radio
              id='insert'
              name='update_text'
              value={false}
              checked={!replace}
              label={t('SelectTemplateInsertText')}
              onChange={handleChangeReplace}
            />
            <Radio
              id='replace'
              name='update_text'
              value={true}
              checked={replace}
              label={t('SelectTemplateReplaceText')}
              onChange={handleChangeReplace}
            />
          </div>
        </ModalBody>
        <div className={styles.buttons}>
          <Button.Save onClick={onApply}>{t('ApplyButton')}</Button.Save>
          <Button.Cancel onClick={onClose}>{t('CancelButton')}</Button.Cancel>
        </div>
      </Modal>
    </>
  )
}

export default SelectTemplateModal
