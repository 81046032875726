// @flow

import * as actions from './MailSearch.actionTypes'

const getUpdatedData = (stateData, key, data, append = false) => ({
  ...stateData,
  [key]: {
    ...stateData[key],
    ...(append
      ? {
          suggestions: [...stateData[key].suggestions, ...data.suggestions],
          isLoading: false,
          page_count: data.page_count,
        }
      : data),
  },
})

const initialState = {
  data: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.MAIL_SEARCH_SELECT_USERS:
      return {
        ...state,
        selectedUsers: action.selectedUsers,
      }
    case actions.MAIL_SEARCH_SELECT_CUSTOMERS:
      return {
        ...state,
        selectedCustomers: action.selectedCustomers,
      }
    case actions.MAIL_SEARCH_SELECT_PROVIDERS:
      return {
        ...state,
        selectedProviders: action.selectedProviders,
      }
    case actions.MAIL_SEARCH_INIT:
      return {
        ...initialState,
        selectedProviders: state.selectedProviders,
        selectedCustomers: state.selectedCustomers,
        data: getUpdatedData(state.data, action.key, {
          value: '',
          suggestions: [],
          isLoading: false,
        }),
      }
    case actions.MAIL_SEARCH_LOAD:
      return {
        ...state,
        data: getUpdatedData(state.data, action.key, {
          isLoading: true,
        }),
      }
    case actions.MAIL_SEARCH_LOADED:
      if (action.value !== state.data[action.key].value) {
        return {
          ...state,
          data: getUpdatedData(state.data, action.key, {
            isLoading: false,
          }),
        }
      }

      return {
        ...state,
        data: getUpdatedData(
          state.data,
          action.key,
          {
            suggestions: action.suggestions,
            page_count: action.page_count,
            isLoading: false,
          },
          action.append
        ),
      }
    case actions.MAIL_SEARCH_UPDATE_VALUE:
      return {
        ...state,
        data: getUpdatedData(state.data, action.key, {
          value: action.value,
        }),
      }
    case actions.MAIL_SEARCH_CLEAR:
      return {
        ...state,
        data: getUpdatedData(state.data, action.key, {
          suggestions: [],
        }),
      }
    case actions.MAIL_SEARCH_RESET:
      return {
        ...state,
        data: getUpdatedData(state.data, action.key, {
          value: '',
          suggestions: [],
          isLoading: false,
        }),
      }
    default:
      return state
  }
}
