// @flow

import * as actions from './RequestFeedback.actions'

const initialState = {
  loading: true,
  data: {
    results: {
      objects: [],
    },
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.LOAD_FEEDBACK_LIST:
      return {
        ...state,
        loading: true,
      }
    case actions.FEEDBACK_LIST_WAS_LOADED:
      return {
        ...state,
        loading: false,
        data: action.payload,
      }
    case actions.FEEDBACK_LIST_WAS_NOT_LOADED:
      return {
        ...state,
        loading: false,
      }

    default:
      return state
  }
}
