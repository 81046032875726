// @flow

import React from 'react'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { get, partial } from 'lodash-es'
import { replace } from 'connected-react-router'
import NumberFormat from 'react-number-format'
import classnames from 'classnames'
import type { Node } from 'react'

import InputField from '../../InputField'
import NewSelectSimple from '../../NewSelectSimple'
import Radio from '../../Radio'
import {
  getInitialValues,
  getAmountTypeOptions,
  normalizeSubmittedvalues,
} from './BudgetPlanForm.utils'
import {
  CURRENCY_TYPE,
  INCLUDE_TAX,
  MAX_RETAINED_AMOUNT,
  MIN_RETAINED_AMOUNT,
  PERCENT_TYPE,
  RETAINED_AMOUNT_IN_CURRENCY,
  RETAINED_AMOUNT_IN_PERCENT,
  RETAINED_AMOUNT_TYPE,
  TAX,
  TOTAL_ESTIMATED_AMOUNT,
} from './BudgetPlanForm.constants'
import Field from './Field'
import Button from '../../Button'
import {
  updateBudgetPlan,
  createBudgetPlan,
} from '../../../core/api/api.budgetPlan'
import Info from '../Info'
import Cell from '../Info/Cell'
import { getUkCountry } from '../../../utils/commonSelectors'

import styles from './BudgetPlanForm.module.scss'

type Props = {
  plan?: ?Object,
  reload: Function,
  requestId?: string,
}

const BudgetPlanForm = (props: Props): Node => {
  const { plan, requestId } = props

  const { t } = useTranslation('BudgetPlan')

  const dispatch = useDispatch()

  const amountTypeOptions = getAmountTypeOptions()

  const country = useSelector(getUkCountry)

  const code = get(country, 'code')

  const formik = useFormik({
    validateOnChange: true,
    enableReinitialize: true,
    initialValues: getInitialValues(plan || {}, plan ? null : code),
    validationSchema: Yup.object({
      [INCLUDE_TAX]: Yup.boolean().required(),
      [TAX]: Yup.number()
        .min(0, t('TaxMinValueInPercent'))
        .max(100, t('TaxMaxValueInPercent'))
        .when([INCLUDE_TAX], { is: true, then: s => s.required() }),
      [RETAINED_AMOUNT_TYPE]: Yup.mixed()
        .oneOf([CURRENCY_TYPE, PERCENT_TYPE])
        .required(),
      [TOTAL_ESTIMATED_AMOUNT]: Yup.string().max(17),
      [RETAINED_AMOUNT_IN_CURRENCY]: Yup.string()
        .max(17)
        .when([RETAINED_AMOUNT_TYPE], {
          is: CURRENCY_TYPE,
          then: s => s.required(t('RetainedAmountRequired')),
        }),
      [RETAINED_AMOUNT_IN_PERCENT]: Yup.number().when([RETAINED_AMOUNT_TYPE], {
        is: PERCENT_TYPE,
        then: s =>
          s
            .min(0, t('RetainedAmountMinValueInPercent'))
            .max(100, t('RetainedAmountMaxValueInPercent'))
            .required(t('RetainedAmountRequired')),
      }),
      [MAX_RETAINED_AMOUNT]: Yup.string().test(
        'is-max',
        t('LessThenMin'),
        (value, context) => {
          if (!value || !context?.parent[MIN_RETAINED_AMOUNT]) {
            return true
          }

          return !!(
            value &&
            context?.parent &&
            parseFloat(value) >= parseFloat(context.parent[MIN_RETAINED_AMOUNT])
          )
        }
      ),
      [MIN_RETAINED_AMOUNT]: Yup.string()
        .when(
          [RETAINED_AMOUNT_TYPE, MAX_RETAINED_AMOUNT],
          (value1, value2, schema) => {
            if (value1 === PERCENT_TYPE && value2) {
              return schema.required(t('MinRetainedAmountRequired'))
            }

            return schema
          }
        )
        .test('is-min', t('MoreThenMax'), (value, context) => {
          if (!value || !context?.parent[MAX_RETAINED_AMOUNT]) {
            return true
          }

          return !!(
            value &&
            context?.parent &&
            parseFloat(value) <= parseFloat(context.parent[MAX_RETAINED_AMOUNT])
          )
        }),
    }),
    onSubmit: submitValues => {
      const normalizedValues = normalizeSubmittedvalues(
        formik.initialValues,
        submitValues
      )

      const api =
        plan && plan.id ? partial(updateBudgetPlan, plan.id) : createBudgetPlan

      const values = Object.assign(
        {},
        { ...normalizedValues },
        requestId && !plan
          ? {
              request: parseInt(requestId),
              [INCLUDE_TAX]: submitValues[INCLUDE_TAX],
              [RETAINED_AMOUNT_TYPE]: submitValues[RETAINED_AMOUNT_TYPE],
            }
          : undefined,
        submitValues[RETAINED_AMOUNT_TYPE] === PERCENT_TYPE
          ? { [RETAINED_AMOUNT_IN_CURRENCY]: undefined }
          : {
              [RETAINED_AMOUNT_IN_PERCENT]: undefined,
              [MAX_RETAINED_AMOUNT]: undefined,
              [MIN_RETAINED_AMOUNT]: undefined,
            }
      )

      api(values).then(data => {
        if (!plan && requestId) {
          dispatch(replace(`/budget-plan/${data.id}/`))
        } else {
          props.reload()
        }
      })
    },
  })

  const formClass = classnames(styles.form, {
    'working-overlay': formik.isSubmitting,
  })

  const handleChangeTaxType = e =>
    formik.setFieldValue(INCLUDE_TAX, JSON.parse(e.currentTarget.value))

  const handleChangeAmountType = option =>
    formik.setFieldValue(RETAINED_AMOUNT_TYPE, option.value)

  const amountTypeOption = amountTypeOptions.find(
    option => option.value === formik.values[RETAINED_AMOUNT_TYPE]
  )

  const handleChangeTotalEstimatedAmount = values =>
    formik.setFieldValue(TOTAL_ESTIMATED_AMOUNT, values.value)

  const handleChangeMaxRetainedAmount = values =>
    formik.setFieldValue(MAX_RETAINED_AMOUNT, values.value)

  const handleChangeMinRetainedAmount = values =>
    formik.setFieldValue(MIN_RETAINED_AMOUNT, values.value)

  const handleChangeTax = values => formik.setFieldValue(TAX, values.value)

  const handleChangeRetainedAmount = values =>
    formik.setFieldValue(RETAINED_AMOUNT_IN_CURRENCY, values.value)

  const handleChangeRetainedAmountInPercent = values =>
    formik.setFieldValue(RETAINED_AMOUNT_IN_PERCENT, values.value)

  return (
    <form className={formClass} onSubmit={formik.handleSubmit}>
      <div className={styles.fields}>
        <div className={styles.radio}>
          <Field withLabel>
            <label>{t('IncludeOrExludeTax')}</label>
            <Radio
              id='include'
              name={INCLUDE_TAX}
              value={true}
              checked={formik.values[INCLUDE_TAX]}
              label={t('IncludeTax')}
              onChange={handleChangeTaxType}
            />
          </Field>
          <Field>
            <Radio
              id='exclude'
              name={INCLUDE_TAX}
              value={false}
              checked={!formik.values[INCLUDE_TAX]}
              label={t('ExcludeTax')}
              onChange={handleChangeTaxType}
            />
          </Field>
        </div>
        <div className={styles.inputs}>
          <Field withLabel>
            <label style={{ width: '128px' }}>{t('Tax')}</label>
            <NumberFormat
              fixedDecimalScale
              isNumericString
              placeholder='0,00'
              allowNegative={false}
              customInput={InputField}
              decimalSeparator=','
              thousandSeparator='.'
              decimalScale={2}
              showErrorText={false}
              value={formik.values[TAX]}
              name={TAX}
              className={styles.tax}
              error={formik.errors[TAX]}
              onValueChange={handleChangeTax}
            />
          </Field>
          <Field withLabel>
            <label style={{ width: '256px' }}>{t('RetainedAmount')}</label>
            <div>
              <NewSelectSimple
                className={styles['amount-type']}
                buttonClass={styles['amount-type-button']}
                name={RETAINED_AMOUNT_TYPE}
                options={amountTypeOptions}
                value={amountTypeOption}
                onChange={handleChangeAmountType}
              />
              {formik.values[RETAINED_AMOUNT_TYPE] === CURRENCY_TYPE ? (
                <NumberFormat
                  fixedDecimalScale
                  isNumericString
                  placeholder='0,00'
                  allowNegative={false}
                  customInput={InputField}
                  decimalSeparator=','
                  thousandSeparator='.'
                  decimalScale={2}
                  value={formik.values[RETAINED_AMOUNT_IN_CURRENCY]}
                  name={RETAINED_AMOUNT_IN_CURRENCY}
                  error={formik.errors[RETAINED_AMOUNT_IN_CURRENCY]}
                  showErrorText={false}
                  className={styles['retained-amount']}
                  onValueChange={handleChangeRetainedAmount}
                />
              ) : (
                <NumberFormat
                  fixedDecimalScale
                  isNumericString
                  placeholder='0,00'
                  allowNegative={false}
                  customInput={InputField}
                  decimalSeparator=','
                  thousandSeparator='.'
                  decimalScale={2}
                  value={formik.values[RETAINED_AMOUNT_IN_PERCENT]}
                  name={RETAINED_AMOUNT_IN_PERCENT}
                  className={styles['retained-amount']}
                  error={formik.errors[RETAINED_AMOUNT_IN_PERCENT]}
                  showErrorText={false}
                  onValueChange={handleChangeRetainedAmountInPercent}
                />
              )}
            </div>
          </Field>
          {formik.values[RETAINED_AMOUNT_TYPE] === PERCENT_TYPE && (
            <>
              <Field withLabel>
                <label style={{ width: '128px' }}>
                  {t('MinRetainedAmount')}
                </label>
                <NumberFormat
                  fixedDecimalScale
                  isNumericString
                  placeholder='0,00'
                  customInput={InputField}
                  decimalSeparator=','
                  thousandSeparator='.'
                  decimalScale={2}
                  showErrorText={false}
                  value={formik.values[MIN_RETAINED_AMOUNT]}
                  name={MIN_RETAINED_AMOUNT}
                  className={styles['min-retained-amount']}
                  error={formik.errors[MIN_RETAINED_AMOUNT]}
                  onValueChange={handleChangeMinRetainedAmount}
                />
              </Field>
              <Field withLabel>
                <label style={{ width: '128px' }}>
                  {t('MaxRetainedAmount')}
                </label>
                <NumberFormat
                  fixedDecimalScale
                  isNumericString
                  placeholder='0,00'
                  customInput={InputField}
                  decimalSeparator=','
                  thousandSeparator='.'
                  decimalScale={2}
                  value={formik.values[MAX_RETAINED_AMOUNT]}
                  name={MAX_RETAINED_AMOUNT}
                  showErrorText={false}
                  className={styles['max-retained-amount']}
                  error={formik.errors[MAX_RETAINED_AMOUNT]}
                  onValueChange={handleChangeMaxRetainedAmount}
                />
              </Field>
            </>
          )}
        </div>
      </div>
      <Info plan={plan}>
        <Cell>
          <label style={{ fontWeight: '500', color: '#000' }}>
            {t('TotalEstimatedAmount')}
          </label>
        </Cell>
        <Cell>
          <NumberFormat
            fixedDecimalScale
            isNumericString
            placeholder='0,00'
            customInput={InputField}
            decimalSeparator=','
            thousandSeparator='.'
            decimalScale={2}
            value={formik.values[TOTAL_ESTIMATED_AMOUNT]}
            name={TOTAL_ESTIMATED_AMOUNT}
            error={formik.errors[TOTAL_ESTIMATED_AMOUNT]}
            showErrorText={false}
            onValueChange={handleChangeTotalEstimatedAmount}
          />
        </Cell>
      </Info>
      <div className={styles.buttons}>
        <Button.Save
          disabled={!formik.dirty || formik.isSubmitting}
          type='submit'
        >
          {t('SaveBudgetPlan')}
        </Button.Save>
        <Button.Cancel
          disabled={!formik.dirty || formik.isSubmitting}
          onClick={formik.handleReset}
        >
          {t('Common:Cancel')}
        </Button.Cancel>
      </div>
    </form>
  )
}

export default BudgetPlanForm
