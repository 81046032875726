// @flow

import React from 'react'
import type Node from 'react'
import { withTranslation } from 'react-i18next'

import { Cell } from '../../Block'

import styles from './ProfileInfo.module.scss'

type Props = {
  group: string,
  t: string => string,
}

const ProfileExternalId = (props: Props): Node => {
  const dash = <>&mdash;</>

  return (
    <Cell
      title={props.t('ExternalIdTitle')}
      valueClassName={styles.externalIdValue}
      className={styles.externalIdCell}
    >
      {props.externalId || dash}
    </Cell>
  )
}

export default withTranslation('User')(ProfileExternalId)
