// @flow

import React from 'react'
import { useEffect, useState } from 'react'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { isEqual } from 'lodash-es'
import type { Node } from 'react'

import Button from '../../Button'
import Modal from '../../Modal'
import ModalBody from '../../Modal/ModalBody'
import ModalCloseButton from '../../Modal/ModalCloseButton'
import ModalHead from '../../Modal/ModalHead'
import ModalButtons from '../../Modal/ModalButtons'
import { useOverflow, useSelected } from '../../../hooks'
import {
  getRequestPipeline,
  getPhaseActivities,
} from '../../../core/api/api.pipeline'
import NewTable from '../../NewTable'
import NewTableHeader from '../../NewTable/NewTableHeader'
import NewTableHeaderRow from '../../NewTable/NewTableHeaderRow'
import NewTableHeaderCell from '../../NewTable/NewTableHeaderCell'
import NewTableBody from '../../NewTable/NewTableBody'
import NewTableBodyRow from '../../NewTable/NewTableBodyRow'
import NewTableBodyCell from '../../NewTable/NewTableBodyCell'
import Checkbox from '../../Checkbox'
import { patchThread } from '../../../core/api/api.request'
import Text from '../../NewTable/NewTableHeaderCell/Text'
import EmptyList from '../../EmptyList'

import styles from './ActivitiesChangeModal.module.scss'

import Loader from '../../Loader'

type Props = {
  onClose: () => void,
  requestId: number,
  thread: { activities: Array<Object>, threadUuid: string },
}

const ActivitiesChangeModal = (props: Props): Node => {
  const {
    requestId,
    thread: { threadUuid, activities: initialActivities },
  } = props

  const initilaIds = initialActivities.map(activity => activity.id)

  const [activities, setActivities] = useState([])
  const [phaseActivities, setPhaseActivities] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [init, setInit] = useState(false)

  const [selectedItems, changeSelected, setSelected, isAllSelected] =
    useSelected(initilaIds)

  useEffect(() => {
    setInit(true)
    getRequestPipeline(requestId).then(data => {
      if (Array.isArray(data)) {
        getPhase(data, 0, data.length)
      }
    })
  }, [])

  const getPhase = (phases, i, count) => {
    getPhaseActivities(phases[i].id).then(data => {
      if (Array.isArray(data)) {
        setPhaseActivities(data)

        if (i < count - 1) {
          getPhase(phases, ++i, count)
        } else {
          setInit(false)
        }
      }
    })
  }

  useEffect(() => {
    if (phaseActivities.length) {
      setActivities([...activities, ...phaseActivities])
    }
  }, [phaseActivities])

  const handleSave = () => {
    setLoading(true)
  }

  useEffect(() => {
    if (isLoading) {
      patchThread(requestId, threadUuid, {
        activities: selectedItems,
      }).then(() => {
        props.onClose()
        props.setLoading(true)
      })
    }
  }, [isLoading])

  useOverflow()

  const { t } = useTranslation('Request')

  if (!activities) {
    return null
  }

  const handleChange = e => {
    changeSelected(parseInt(e.currentTarget.value))
  }

  const handleChangeAll = () => {
    if (isAllSelected(activities)) {
      setSelected([])
    } else {
      setSelected(activities.map(activity => activity.id))
    }
  }

  const handleCancel = () => {
    if (isEqual(selectedItems, initilaIds)) {
      props.onClose()
    } else {
      setSelected(initilaIds)
    }
  }

  const tableClass = classnames({
    'working-overlay': isLoading,
  })

  return (
    <Modal
      isOpen
      style={{
        content: { minWidth: '661px', maxWidth: '661px' },
      }}
      onRequestClose={props.onClose}
    >
      <ModalHead title={t('ActivitiesAttachingTitle')} />
      <ModalCloseButton onClose={props.onClose} />
      <ModalBody style={{ maxHeight: '604px', overflow: 'auto' }}>
        {init ? (
          <Loader type='medium' text={false} />
        ) : (
          <NewTable className={tableClass}>
            <NewTableHeader>
              <NewTableHeaderRow>
                <NewTableHeaderCell style={{ width: '50%' }}>
                  <Checkbox
                    outer
                    disabled={!activities.length || isLoading}
                    checked={isAllSelected(activities)}
                    selected={
                      !!selectedItems.length && !isAllSelected(activities)
                    }
                    onChange={handleChangeAll}
                  />
                  <Text title={t('PhaseName')} />
                </NewTableHeaderCell>
                <NewTableHeaderCell
                  title={t('ActivityName')}
                  style={{ width: '50%' }}
                />
              </NewTableHeaderRow>
            </NewTableHeader>
            <NewTableBody>
              {activities.map(activity => (
                <NewTableBodyRow key={activity.id}>
                  <NewTableBodyCell title={activity.phase.name}>
                    <Checkbox
                      value={activity.id}
                      checked={selectedItems.includes(activity.id)}
                      className={styles.checkbox}
                      onChange={handleChange}
                    />
                  </NewTableBodyCell>
                  <NewTableBodyCell title={activity.title} />
                </NewTableBodyRow>
              ))}
            </NewTableBody>
          </NewTable>
        )}
        {!activities.length && !isLoading && !init && (
          <EmptyList
            noShowAddIcon
            icon='activity'
            title={t('Activities:ActivitiesNotFound')}
          />
        )}
      </ModalBody>
      <ModalButtons>
        <Button.Save working={isLoading} onClick={handleSave}>
          {t('Common:Save')}
        </Button.Save>
        <Button.Cancel working={isLoading} onClick={handleCancel}>
          {t('Common:Cancel')}
        </Button.Cancel>
      </ModalButtons>
    </Modal>
  )
}

export default ActivitiesChangeModal
