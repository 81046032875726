// @flow

const basePath = 'marketplace'
const ERROR_KEY = '404'

export const THUMBNAIL_WIDTH = 340

export const MARKETPLACE_ROUTES: { [string]: Function | string } = {
  [ERROR_KEY]: '/404',
  marketplaceAllProviders: `/${basePath}/all-providers`,
  marketplaceOffer: (id: string): string => `/${basePath}/offer/${id}`,
  marketplaceOffers: `/${basePath}/offers`,
  marketplaceOrder: (id: string): string => `/${basePath}/order/${id}`,
  marketplaceOrders: `/${basePath}/orders`,
  marketplaceProvider: (id: string): string => `/${basePath}/provider/${id}`,
  marketplaceProviders: `/${basePath}/providers`,
  marketplaceCreateProvider: `/${basePath}/provider/create`,
  marketplaceCreateOrder: `/${basePath}/order/create`,
}
