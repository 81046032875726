// @flow

import {
  put,
  call,
  fork,
  takeLatest,
  cancel,
  takeEvery,
} from 'redux-saga/effects'
import type { Saga } from 'redux-saga'
import { partial, pick, get } from 'lodash-es'

import api from '../../../core/api'
import * as actions from './WidgetFiles.actionTypes'
import { serverError } from '../../Layout/Layout.actions'

function* watchClear(task) {
  yield takeEvery(actions.WIDGET_FILES_RESET, clear, task)
}

function* watchLoad() {
  yield takeLatest(actions.WIDGET_FILES_LOAD, load)
}

function* clear(task) {
  yield cancel(task)
}

function* loadRquest(action) {
  try {
    const {
      chatroom: chatroomId,
      thread: threadId,
      email: emailId,
    } = pick(action, ['chatroom', 'thread', 'email'])

    let fileApi = null
    let files = []

    if (chatroomId) {
      fileApi = partial(api.messages.getChatroomFiles, chatroomId)
    } else if (threadId) {
      fileApi = partial(api.mail.getThreadFiles, threadId)
    } else if (emailId) {
      fileApi = partial(api.mail.getEmailFiles, emailId)
    }

    if (fileApi) {
      const result = yield call(fileApi)

      files = get(result, ['results', 'objects'], [])
    }

    yield put({
      type: actions.WIDGET_FILES_WERE_LOADED,
      files: files.map(f => {
        return {
          ...f,
          scope: chatroomId ? 'chat' : 'mail',
        }
      }),
    })
  } catch (error) {
    if (error.message.response.status !== 404) {
      yield put(serverError(error))
      yield put({ type: actions.WIDGET_FILES_ERROR, error })
    }
  }
}

function* load(action) {
  const task = yield fork(loadRquest, action)
  yield fork(watchClear, task)
}

export default function* watch(): Saga<void> {
  yield fork(watchLoad)
}
