// @flow

import React, { PureComponent } from 'react'
import classnames from 'classnames'
import { withTranslation } from 'react-i18next'

import CheckitemEditable from '../../components/CheckitemEditable'

type Props = {
  canEdit: boolean,
  checked: boolean,
  checkitemId: number,
  onCheckboxClick: (number, boolean) => void,
  onDelete: number => void,
  onTextSave: (number, string) => void,
  text: string,
}

type State = { isEditMode: boolean, text: string }

class CheckItem extends PureComponent<Props, State> {
  state = {
    isEditMode: false,
    text: this.props.text,
  }

  save = text => {
    const { checkitemId } = this.props

    this.setState({ isEditMode: false })

    this.props.onTextSave(checkitemId, text)
  }

  cancel = () => {
    this.setState({ isEditMode: false })
  }

  deleteCheckitem = () => {
    const { checkitemId } = this.props

    this.props.onDelete(checkitemId)
  }

  toggle = () => {
    const { checkitemId, checked, canEdit } = this.props

    if (!canEdit) {
      return
    }

    this.props.onCheckboxClick(checkitemId, !checked)
  }

  startEdit = () => {
    const { canEdit } = this.props

    if (!canEdit) {
      return
    }

    this.setState({ isEditMode: true })
  }

  render() {
    const { text, checked, checkitemId, canEdit } = this.props
    const { isEditMode } = this.state

    const checkboxClassName = classnames(
      'checklist__item-checkbox ',
      'checkbox',
      'checkbox--medium',
      'checkbox--default',
      {
        'checklist__item-checkbox--checked': checked,
        'checklist__item-checkbox--not-editable': !canEdit,
      }
    )
    const checkitemTextClassName = classnames('checklist__item-details-text', {
      'checklist__item-details-text--checked': checked,
      'checklist__item-details-text--not-editable': !canEdit,
    })

    return (
      <div className='checklist__item'>
        <div className='checklist__item-details'>
          {isEditMode ? (
            <CheckitemEditable
              isEdit
              checkitemText={text}
              onSave={this.save}
              onCancel={this.cancel}
              onDelete={this.deleteCheckitem}
            />
          ) : (
            <div className='checklist__item-details-view-container'>
              <label
                className={checkboxClassName}
                htmlFor={`check-${checkitemId}`}
              >
                <input
                  type='checkbox'
                  id={`check-${checkitemId}`}
                  value={checkitemId}
                  checked={checked}
                  onChange={this.toggle}
                />
                <span />
              </label>
              <div className={checkitemTextClassName} onClick={this.startEdit}>
                {text}
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default withTranslation('Checklist')(CheckItem)
