// @flow

import { all, put, call, fork, takeEvery } from 'redux-saga/effects'
import { replace } from 'connected-react-router'

import { SERVER_404_ERROR } from '../../components/Layout/Layout.constants'
import api from '../../core/api'
import {
  serverError,
  updateCountersAction,
} from '../../components/Layout/Layout.actions'
import * as actionTypes from './Messages.constants'
import {
  chatroomsInitiated,
  chatroomsGetFilesInitiated,
} from './Messages.actions'

function* init({ params }) {
  try {
    const {
      results: { objects: chatrooms },
      meta,
      permissions,
    } = yield call(api.messages.getChatrooms, params)
    yield put(updateCountersAction())
    yield put(chatroomsInitiated(chatrooms, meta, permissions))
  } catch (error) {
    const action = serverError(error)

    if (action.type === SERVER_404_ERROR) {
      yield put(replace('/404'))
    } else {
      yield put(action)
      yield put({ type: actionTypes.CHATROOMS_ERROR, error })
    }
  }
}

function* getFiles({ fileIdToBeShown, uuid }) {
  try {
    const {
      results: { objects: files },
      meta,
    } = yield call(api.messages.getChatroomFiles, uuid)
    yield put(chatroomsGetFilesInitiated(fileIdToBeShown, files, meta))
  } catch (error) {
    yield put(serverError(error))
    yield put({ type: actionTypes.CHATROOMS_GET_FILES_ERROR, error })
  }
}

function* markAsRead({ ids, params }) {
  try {
    const patchParams = {
      model: 'chat',
      model_pks: JSON.stringify(ids),
      patch: { read: true },
    }

    yield call(api.massAction.updateRequest, patchParams)

    yield put({
      type: actionTypes.CHATROOMS_RESET_INITIAL_STATE,
    })

    yield put({
      type: actionTypes.CHATROOMS_INITIATING,
      params,
    })
  } catch (error) {
    yield put(serverError(error))
    yield put({ type: actionTypes.CHATROOMS_ERROR, error })
  }
}

function* markAsUnread({ ids, params }) {
  try {
    const patchParams = {
      model: 'chat',
      model_pks: JSON.stringify(ids),
      patch: { unread: true },
    }

    yield call(api.massAction.updateRequest, patchParams)

    yield put({
      type: actionTypes.CHATROOMS_RESET_INITIAL_STATE,
    })

    yield put({
      type: actionTypes.CHATROOMS_INITIATING,
      params,
    })
  } catch (error) {
    yield put(serverError(error))
    yield put({ type: actionTypes.CHATROOMS_ERROR, error })
  }
}

function* watchInit() {
  yield takeEvery(actionTypes.CHATROOMS_INITIATING, init)
}

function* watchGetFiles() {
  yield takeEvery(actionTypes.CHATROOMS_GET_FILES_INITIATING, getFiles)
}

function* watchMarkAsRead() {
  yield takeEvery(actionTypes.CHATROOMS_MARK_AS_READ, markAsRead)
}

function* watchMarkAsUnread() {
  yield takeEvery(actionTypes.CHATROOMS_MARK_AS_UNREAD, markAsUnread)
}

export default function* watch() {
  yield all([
    fork(watchInit),
    fork(watchGetFiles),
    fork(watchMarkAsRead),
    fork(watchMarkAsUnread),
  ])
}
