// @flow

import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Icon from '../../components/Icon'
import {
  deleteThreads,
  restoreThreads,
  showModal,
  toggleAll,
  markAsRead,
  markAsUnread,
  addStar,
  removeStar,
} from './ThreadsList.actions'
import SelectCustom from '../../components/Select/SelectCustom'
import Button from '../../components/Button'
import TopPagination from '../../components/Pagination/TopPagination'
import ConfirmationPopup from '../../components/modals/ConfirmationPopup'
import { INITIAL_ITEMS_COUNT } from './ThreadsList.constants'
import Checkbox from '../../components/Checkbox'
import { getLocation } from '../../utils/commonSelectors'
import { getCurrentFilters } from '../../utils/routing'

const MailToolbox = props => {
  const { t } = useTranslation('Mail')

  const {
    mailList,
    meta,
    pathname,
    permissions: {
      can_create: canCreate,
      can_delete: canDelete,
      can_restore: canRestore,
      can_update_starred: canUpdateStarred,
    },
    selected,
    isSearch,
    folder,
  } = props

  const handleToggleAll = () => {
    props.toggleAll()
  }

  const handleHideModal = () => {
    props.showModal(null)
  }

  const showDeleteModal = () => {
    const modal = (
      <ConfirmationPopup
        title={t('DeleteThreadTitle')}
        text={t('ConfirmActionDeleteText')}
        confirm={t('Delete')}
        cancel={t('Cancel')}
        onClose={handleHideModal}
        onOk={removeThreads}
      />
    )

    props.showModal(modal)
  }

  const showRestoreModal = () => {
    const modal = (
      <ConfirmationPopup
        title={t('RestoreThreadTitle')}
        text={t('ConfirmActionRestoreText')}
        confirm={t('Restore')}
        cancel={t('Cancel')}
        onClose={handleHideModal}
        onOk={restoreThreads}
      />
    )

    props.showModal(modal)
  }

  const showMarkAsReadModal = () => {
    const modal = (
      <ConfirmationPopup
        title={t('MarkAsReadTitle')}
        confirm={t('MarkAsRead')}
        cancel={t('Cancel')}
        onClose={handleHideModal}
        onOk={markAsRead}
      />
    )

    props.showModal(modal)
  }

  const showMarkAsUnreadModal = () => {
    const modal = (
      <ConfirmationPopup
        title={t('MarkAsUnreadTitle')}
        confirm={t('MarkAsUnread')}
        cancel={t('Cancel')}
        onClose={handleHideModal}
        onOk={markAsUnread}
      />
    )

    props.showModal(modal)
  }

  const patch = action => {
    const { location, folder, isSearch, query } = props
    const filters = getCurrentFilters(location)

    action(selected, { folder, ...filters, isSearch, query })
    props.showModal(null)
  }

  const removeThreads = () => {
    patch(props.deleteThreads)
  }

  const restoreThreads = () => {
    patch(props.restoreThreads)
  }

  const markAsRead = () => {
    patch(props.markAsRead)
  }

  const markAsUnread = () => {
    patch(props.markAsUnread)
  }

  const addStar = () => {
    patch(props.addStar)
  }

  const removeStar = () => {
    patch(props.removeStar)
  }

  const getMassActionOptions = () => {
    let options = []

    if (folder === 'inbox') {
      options = options.concat([
        {
          value: 'markAsRead',
          label: t('MarkAsRead'),
          handler: () => showMarkAsReadModal(),
          icon: 'emailRead',
        },
        {
          value: 'markAsUnread',
          label: t('MarkAsUnread'),
          handler: () => showMarkAsUnreadModal(),
          icon: 'emailUnread',
        },
      ])
    }

    if (canUpdateStarred) {
      options = options.concat([
        {
          value: 'addStar',
          label: t('AddStar'),
          handler: () => addStar(),
          icon: 'starFilled',
        },
        {
          value: 'removeStar',
          label: t('RemoveStar'),
          handler: () => removeStar(),
          icon: 'newStar',
        },
      ])
    }

    return options
  }

  return (
    <div className='toolbox'>
      {!isSearch && (
        <div className='toolbox__cell'>
          <Checkbox
            outer
            checked={selected.length === mailList.length}
            onChange={handleToggleAll}
          />
        </div>
      )}
      {!selected.length && canCreate && (
        <div className='toolbox__cell'>
          <Link
            className='button button--success button--large button--content-centered'
            to={'/mail/create'}
          >
            <Icon id='email' className='button__icon' />
            {t('WriteAMessage')}
          </Link>
        </div>
      )}
      {!!selected.length && (
        <>
          <div className='toolbox__cell'>
            <SelectCustom
              style={{ marginRight: '-10px' }}
              options={getMassActionOptions()}
              onChange={opt => opt.handler()}
            >
              {t('Actions')}
            </SelectCustom>
          </div>
          <div className='toolbox__cell'>
            {(canDelete || isSearch) && (
              <Button.Remove onClick={showDeleteModal} />
            )}
            {canRestore && <Button.Restore onClick={showRestoreModal} />}
          </div>
        </>
      )}
      {meta && meta.count > INITIAL_ITEMS_COUNT && (
        <div className='toolbox__cell toolbox__cell--right'>
          <TopPagination
            classes='pager pager--large pager--default-2'
            meta={meta}
            basePage={pathname}
          />
        </div>
      )}
    </div>
  )
}

const mapStateToProps = state => ({
  location: getLocation(state),
})

const mapDispatchToProps = {
  deleteThreads,
  restoreThreads,
  showModal,
  toggleAll,
  markAsRead,
  markAsUnread,
  addStar,
  removeStar,
}

export default connect(mapStateToProps, mapDispatchToProps)(MailToolbox)
