import * as actions from './FlatDescription.actionTypes'

const initialState = {
  data: {},
  edit: false,
  saving: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.FLAT_DESCRIPTION_INIT:
      return {
        ...initialState,
        data: action.data
      }
    case actions.FLAT_DESCRIPTION_EDIT_ON:
      return {
        ...state,
        edit: true
      }
    case actions.FLAT_DESCRIPTION_EDIT_OFF:
      return {
        ...state,
        edit: false,
        data: action.data
      }
    case actions.FLAT_DESCRIPTION_SAVE:
      return {
        ...state,
        saving: true
      }
    case actions.FLAT_DESCRIPTION_SAVED:
      return {
        ...state,
        saving: false,
        edit: false
      }
    case actions.FLAT_DESCRIPTION_SET_VALUE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.name]: action.value
        }
      }
    default:
      return state
  }
}
