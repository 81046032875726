// @flow

import * as actions from './UserSuggest.actionTypes'

const initialState = {
  value: '',
  suggestions: [],
  managers: [],
  isLoading: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.USERSUGGEST_INIT:
      return {
        ...initialState,
        value: action.value,
      }
    case actions.MANAGER_CHANGE:
      return {
        ...state,
        managers: action.managers,
        value: '',
      }
    case actions.UPDATE_INPUT_VALUE:
      return {
        ...state,
        value: action.value,
      }

    case actions.CLEAR_SUGGESTIONS:
      return {
        ...state,
        suggestions: [],
      }

    case actions.USERSUGGEST_LOAD:
    case actions.PROVIDERSUGGEST_LOAD:
      return {
        ...state,
        isLoading: true,
      }

    case actions.USERSUGGEST_LOADED:
      // Ignore suggestions if input value changed
      if (action.value !== state.value) {
        return {
          ...state,
          isLoading: false,
        }
      }

      return {
        ...state,
        suggestions: action.suggestions,
        isLoading: false,
      }

    case actions.PROVIDERSUGGEST_LOADED:
      if (action.value !== state.value) {
        return {
          ...state,
          isLoading: false,
        }
      }

      return {
        ...state,
        suggestions: action.suggestions,
        isLoading: false,
      }

    default:
      return state
  }
}
