// @flow

import React from 'react'
import classnames from 'classnames'
import styles from './Trim.module.scss'

type IProps = {
  isTrimmed: boolean,
  showTrimmed: () => void,
  title: string,
}

const Trim = ({
  showTrimmed,
  isTrimmed,
  title,
}: IProps): React$Element<'div'> => {
  const buttonClass = classnames(styles.button, {
    [styles.trimmed]: isTrimmed,
  })

  return (
    <div className={buttonClass} title={title} onClick={showTrimmed}>
      <svg
        width='30'
        height='10'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <rect width='30' height='10' rx='3' fill='#DDD'></rect>
        <circle cx='8' cy='5' r='2' fill='#757575'></circle>
        <circle cx='15' cy='5' r='2' fill='#757575'></circle>
        <circle cx='22' cy='5' r='2' fill='#757575'></circle>
      </svg>
    </div>
  )
}

export default Trim
