// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'

import SimpleLike from './SimpleLike'
import * as actions from './Like.actionTypes'

type Props = {
  active: boolean,
  count: number,
  data: Object,
  disabled?: boolean,
  dispatch: Object => void,
  model: string,
  uuid: string,
}
type State = {
  count: number,
}

class Like extends Component<Props, State> {
  state = {
    count: this.props.count,
  }

  componentDidMount() {
    const { active, uuid } = this.props
    this.props.dispatch({
      type: actions.LIKE_WAS_UPDATED,
      uuid,
      active,
    })
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { data, uuid } = this.props
    const prevLiked = prevProps.data[uuid]

    if (uuid in prevProps.data && prevLiked !== data[uuid]) {
      const { count } = this.state

      this.setState({
        count: count + (prevLiked && prevState.count ? -1 : 1),
      })
    }
  }

  componentWillUnmount() {
    const { uuid } = this.props
    this.props.dispatch({
      type: actions.LIKE_RESET,
      uuid,
    })
  }

  props: Props

  vote = () => {
    const { disabled, data, uuid, model } = this.props

    if (!disabled) {
      this.props.dispatch({
        type: actions.LIKE_UPDATE,
        uuid,
        model,
        active: data[uuid],
      })
    }
  }

  render() {
    const { uuid, data, disabled } = this.props

    if (!(uuid in data)) {
      return null
    }

    const { count } = this.state

    return (
      <SimpleLike
        count={count}
        disabled={disabled}
        marked={data[uuid]}
        onClick={this.vote}
      />
    )
  }
}

const mapStateToProps = state => state.like

export default compose(withTranslation('Like'), connect(mapStateToProps))(Like)
