// @flow

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Textarea from 'react-textarea-autosize'
import update from 'immutability-helper'
import { filter, isEqual } from 'lodash-es'
import type { Node } from 'react'

import Button from '../../Button'
import Modal from '../../Modal'
import ModalBody from '../../Modal/ModalBody'
import ModalButtons from '../../Modal/ModalButtons'
import ModalCloseButton from '../../Modal/ModalCloseButton'
import ModalHead from '../../Modal/ModalHead'
import ModalRow from '../../Modal/ModalRow'
import { useOverflow } from '../../../hooks/useOverflow'
import { createInfoStatus, getInfoStatus } from '../../../core/api/api.status'
import StatusListListItem from './StatusList/StatusListItem'
import StatusList from './StatusList'

import styles from './StatusModal.module.scss'

type Props = { onClose: Function }

const StatusModal = (props: Props): Node => {
  const { t } = useTranslation('Profile')

  const [status, setStatus] = useState(null)
  const [initialStatus, setInitialStatus] = useState(null)
  const [value, setValue] = useState('')
  const [editingStatus, setEditingStatus] = useState(null)

  useEffect(() => {
    getInfoStatus().then(data => {
      setStatus(data.results.objects)
      setInitialStatus(data.results.objects)
    })
  }, [])

  useOverflow()

  const handleChangeText = e => setValue(e.target.value)

  const handleReplace = (id, targetIndex) => {
    const from = status.findIndex(i => i.id === id)

    if (from === targetIndex) {
      return
    }

    const proirity = status[from]

    const updated = update(status, {
      $splice: [
        [from, 1],
        [targetIndex, 0, proirity],
      ],
    })

    setStatus(updated.map((u, i) => ({ ...u, position: i + 1 })))
  }

  if (!status || !initialStatus) {
    return null
  }

  const hasChanges = () => {
    if (initialStatus.length !== status.length || value) {
      return true
    }

    let changed = filter(
      status,
      (status, index) => !isEqual(status, initialStatus[index])
    )

    return !!changed.length
  }

  const handleClose = () => {
    if (hasChanges()) {
      setStatus(initialStatus)
      setValue('')
    } else {
      props.onClose()
    }
  }

  const handleSubmit = () => {
    const create = value
      ? value
          .split('\n')
          .map(s => s.trim())
          .filter(Boolean)
      : []

    createInfoStatus({ create, update: status }).finally(() => props.onClose())
  }

  const handleRemove = id => {
    setStatus(status.filter(status => status.id !== id))
    setEditingStatus(null)
  }

  const handleSave = (item, index) => {
    setStatus(update(status, { [index]: { $set: item } }))
    setEditingStatus(null)
  }

  return (
    <Modal isOpen className={styles.modal} onRequestClose={props.onClose}>
      <ModalHead title={t('Status')} />
      <ModalCloseButton onClose={props.onClose} />
      <ModalBody>
        {!status && (
          <ModalRow>
            <div className={styles.form}>
              <label className={styles.title}>{t('StatusName')}</label>
              <input disabled value={t('Status')} />
            </div>
          </ModalRow>
        )}
        {status && !!status.length && (
          <ModalRow>
            <div className={styles.form}>
              <span className={styles.title}>{t('StatusList')}</span>
              <StatusList>
                {status.map((status, index) => (
                  <StatusListListItem
                    index={index}
                    isEditing={editingStatus === status.id}
                    payment={status}
                    key={status.id}
                    onRemove={handleRemove}
                    onReplace={handleReplace}
                    onSave={handleSave}
                    onEdit={setEditingStatus}
                  />
                ))}
              </StatusList>
            </div>
          </ModalRow>
        )}
        <ModalRow>
          <div className={styles.form}>
            <span className={styles.title}>{t('AddStatus')}</span>
            <Textarea
              autoFocus
              maxRows={5}
              className={styles.textarea}
              value={value}
              placeholder={t('AddStatusPlaceholder')}
              maxLength={128}
              onChange={handleChangeText}
            />
          </div>
        </ModalRow>
      </ModalBody>
      <ModalButtons>
        <Button.Save disabled={!hasChanges()} onClick={handleSubmit}>
          {t('Common:Save')}
        </Button.Save>
        <Button.Cancel onClick={handleClose}>
          {t('Common:Cancel')}
        </Button.Cancel>
      </ModalButtons>
    </Modal>
  )
}

export default StatusModal
