// @flow

import { all, put, call, fork, takeEvery, takeLatest } from 'redux-saga/effects'
import { replace } from 'connected-react-router'
import { omit } from 'lodash-es'

import api from '../../../core/api'
import { serverError, globalModalError } from '../../Layout/Layout.actions'
import { SERVER_404_ERROR } from '../../Layout/Layout.constants'
import * as actions from './FlatList.actionTypes'
import * as flatPopupActions from '../../modals/AddFlatPopup/AddFlatPopup.actionTypes'

const DWELLERS_COUNT = 10
const LANDLORD_COUNT = 10

function* watchLoad() {
  yield takeLatest(actions.FLAT_LIST_LOADING, load)
}

function* watchRemove() {
  yield takeEvery(actions.FLAT_LIST_REMOVING, remove)
}

function* watchRestore() {
  yield takeEvery(actions.FLAT_LIST_RESTORE, restore)
}

function* watchAdd() {
  yield takeEvery(flatPopupActions.FLAT_POPUP_ADDED_FLAT, addFlat)
}

function* load({ params }) {
  try {
    const getApi = params?.soft_archived
      ? api.flat.getArchivedListTiny
      : params?.buildingGroup
      ? params => api.building.getGroupFlats(params.buildingGroup, params)
      : params?.search
      ? api.flat.getListSearch
      : api.flat.getListTiny
    const data = yield call(getApi, {
      ...omit(params, ['sub', 'soft_archived']),
      get_dwellers: DWELLERS_COUNT,
      get_landlords: LANDLORD_COUNT,
    })

    yield put({ type: actions.FLAT_LIST_LOADED, ...data })
  } catch (error) {
    const action = serverError(error)

    if (action.type === SERVER_404_ERROR) {
      yield put(replace('/404'))
    } else {
      yield put(action)
      yield put({ type: actions.FLAT_LIST_ERROR, error })
    }
  }
}

function* remove({ ids, params, meta }) {
  try {
    const { count, curr_page, limit, page_count } = meta

    let newPage = curr_page

    const removedFlats = yield call(api.massAction.updateRequest, {
      model: 'Flat',
      model_pks: JSON.stringify(ids),
      patch: { soft_archived: true },
    })

    if (
      curr_page === page_count &&
      page_count > 1 &&
      removedFlats &&
      (removedFlats.length === count % limit || removedFlats.length === limit)
    ) {
      newPage = curr_page - 1
    }

    const data = yield call(api.flat.getListTiny, {
      ...omit(params, ['sub', 'soft_archived', 'page']),
      page: newPage,
      get_dwellers: DWELLERS_COUNT,
      get_landlords: LANDLORD_COUNT,
    })

    yield put({ type: actions.FLAT_LIST_LOADED, ...data })
  } catch (error) {
    yield put(serverError(error))

    if (error.message.response.status === 400) {
      yield put(globalModalError(error.message.response.data.detail))
    }

    yield put({ type: actions.FLAT_LIST_ERROR, error })
  }
}

function* restore({ ids, params, meta }) {
  try {
    const { count, curr_page, limit, page_count } = meta

    let newPage = curr_page

    const restoredFlats = yield call(api.massAction.updateRequest, {
      model: 'Flat',
      model_pks: JSON.stringify(ids),
      patch: { soft_archived: false },
    })

    if (
      curr_page === page_count &&
      page_count > 1 &&
      restoredFlats &&
      (restoredFlats.length === count % limit || restoredFlats.length === limit)
    ) {
      newPage = curr_page - 1
    }

    const data = yield call(api.flat.getArchivedListTiny, {
      ...omit(params, ['sub', 'soft_archived', 'page']),
      page: newPage,
      get_dwellers: DWELLERS_COUNT,
      get_landlords: LANDLORD_COUNT,
    })

    yield put({ type: actions.FLAT_LIST_LOADED, ...data })
  } catch (error) {
    yield put(serverError(error))

    if (error.message.response.status === 400) {
      yield put(globalModalError(error.message.response.data.detail))
    }

    yield put({ type: actions.FLAT_LIST_ERROR, error })
  }
}

function* addFlat({ params }) {
  try {
    yield put({ type: actions.FLAT_LIST_LOADING, params })
  } catch (e) {
    console.warn(e.message)
  }
}

export default function* watch() {
  yield all([
    fork(watchLoad),
    fork(watchRemove),
    fork(watchAdd),
    fork(watchRestore),
  ])
}
