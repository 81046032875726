// @flow

import { get } from 'lodash-es'

import {
  INIT_LOAD,
  INIT_LOADED,
  INIT_ERROR,
  TRANSLATIONS_UPDATE,
  EMAIL_ACCOUNT_FONT_UPDATE,
} from './init.actionTypes'
import { COMPANY_UPDATE } from '../CompanyLogo/CompanyLogo.actionTypes' // TODO refactor
import { COMPANY_INFO_UPDATE } from '../Company/CompanyInfo/CompanyInfo.actionTypes'
import { COMPANY_CONTACTS_UPDATE_PHONES } from '../Company/CompanyContacts/CompanyContacts.actionTypes'
import {
  SETTINGS_CORPORATE_UPDATE,
  SETTINGS_CORPORATE_SAVED_CONFIG,
} from '../SettingsCorporate/SettingsCorporate.actionTypes'
import { WIZARD_STATUS_WAS_UPDATED } from '../../containers/RequestConstructor/RequestConstructor.actionTypes'
import {
  COMPANY_VIEW_ADDRESS_REMOVED,
  COMPANY_VIEW_ADDRESS_CREATED,
  COMPANY_VIEW_ADDRESS_SAVED,
  COMPANY_VIEW_PRIMARY_SAVED,
  COMPANY_VIEW_ADDRESS_UPDATE,
} from '../CompanyView/CompanyView.actionTypes'

const initialState = {
  uk: {},
  system: {
    models_with_contacts: {},
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case INIT_LOAD:
      return initialState
    case INIT_LOADED:
      return {
        ...state,
        ...action.data,
      }
    case TRANSLATIONS_UPDATE:
      return {
        ...state,
        translations: action.translations,
      }
    case COMPANY_UPDATE:
      return {
        ...state,
        uk: action.uk,
      }
    case COMPANY_INFO_UPDATE:
      return {
        ...state,
        uk: {
          ...state.uk,
          name: action.name,
          description: action.description,
        },
      }
    case COMPANY_CONTACTS_UPDATE_PHONES:
      return {
        ...state,
        uk: {
          ...state.uk,
          phone: action.phones.length ? action.phones[0].value : null,
        },
      }
    case SETTINGS_CORPORATE_UPDATE:
      return {
        ...state,
        uk: {
          ...state.uk,
          url: action.url,
          city_obj: action.city_obj,
          country_obj: action.country_obj,
          language_obj: action.language_obj,
        },
      }
    case SETTINGS_CORPORATE_SAVED_CONFIG:
      return {
        ...state,
        uk: {
          ...state.uk,
          config: action.config,
        },
        uk_config: action.config,
      }
    case INIT_ERROR:
      return {
        ...state,
        error: action.error,
      }
    case WIZARD_STATUS_WAS_UPDATED:
      return {
        ...state,
        is_wizard_enabled: action.data.is_active,
      }
    case COMPANY_VIEW_ADDRESS_SAVED:
      return {
        ...state,
        uk: {
          ...state.uk,
          address_obj: action.address,
          offices: state.uk.offices.map(office =>
            office.id === action.officeId
              ? { ...office, address: action.address }
              : { ...office }
          ),
        },
      }
    case COMPANY_VIEW_ADDRESS_UPDATE:
      return {
        ...state,
        uk: {
          ...state.uk,
          address_obj: action.addressObj,
        },
      }
    case COMPANY_VIEW_PRIMARY_SAVED:
      return {
        ...state,
        uk: {
          ...state.uk,
          offices: state.uk.offices
            .map(office => ({
              ...office,
              primary: false,
            }))
            .map(office => {
              if (office.id === action.officeId) {
                return { ...office, primary: true }
              }

              return office
            }),
        },
      }
    case COMPANY_VIEW_ADDRESS_CREATED: {
      let offices = state.uk.offices
      let address_obj = state.uk.address_obj

      if (action.res.primary) {
        offices = offices.map(office => ({ ...office, primary: false }))
        address_obj = action.res.address
      }

      return {
        ...state,
        uk: {
          ...state.uk,
          address_obj,
          offices: [
            ...offices,
            {
              id: action.res.id,
              address: action.res.address,
              uk: action.res.uk,
              primary: action.res.primary,
            },
          ],
        },
      }
    }
    case COMPANY_VIEW_ADDRESS_REMOVED: {
      const office = state.uk.offices.filter(
        office => office.id === action.officeId
      )

      const isPrimaryOffice = get(office, [0, 'primary'])

      return {
        ...state,
        uk: {
          ...state.uk,
          address_obj: isPrimaryOffice ? null : state.uk.address_obj,
          offices: state.uk.offices.filter(
            office => office.id !== action.officeId
          ),
        },
      }
    }
    case EMAIL_ACCOUNT_FONT_UPDATE:
      return { ...state, email_fonts: action.emailFonts }

    default:
      return state
  }
}
