// @flow

import React from 'react'
import classnames from 'classnames'
import { Link } from 'react-router-dom'

import FlatAddressValue from './FlatAddressValue'
import FlatAddressContext from './FlatAddress.context'

import styles from './FlatAddress.module.scss'

const FlatAddressInfo = () => {
  return (
    <FlatAddressContext.Consumer>
      {({ number, entrance, floor, flat, flatId }) => {
        if (!flat) {
          return null
        }
        const canOpenFlat =
          flat.permissions && flat.permissions.can_view && flatId !== null
        const infoClassName = classnames(
          'apartment-profile__row apartment-profile__row--b',
          styles.flat
        )

        const Info = (
          <div className={infoClassName}>
            <FlatAddressValue
              name='number'
              title='NumberLabel'
              max={25}
              value={number}
            />
            {number && entrance ? ', ' : ''}
            <FlatAddressValue
              name='entrance'
              title='Entrance'
              max={10}
              value={entrance}
            />
            {floor ? ', ' : ''}
            <FlatAddressValue
              name='floor'
              title='Floor'
              max={10}
              value={floor}
            />
          </div>
        )

        return canOpenFlat ? <Link to={`/flat/${flat.id}`}>{Info}</Link> : Info
      }}
    </FlatAddressContext.Consumer>
  )
}

export default FlatAddressInfo
