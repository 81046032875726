// @flow

import React, { useState } from 'react'

import type { Node } from 'react'

import { getPreferredCommunication } from '../../core/api/api.preferredCommunication'
import { useLoad } from '../../hooks'
import PreferredCommunicationModal from './PreferredCommunicationModal'
import PreferredCommunicationTable from './PreferredCommunicationTable'

const PreferredCommunication = (): Node => {
  const [isModalOpen, setModalOpen] = useState(false)
  const [editingPreferredCommunication, setEditingPreferredCommunication] =
    useState(null)
  const [referredCommunication, isLoading, , load] = useLoad(
    getPreferredCommunication
  )

  const handleClose = () => setModalOpen(false)
  const handleCreate = () => setModalOpen(true)

  return (
    <>
      {referredCommunication && (
        <>
          <PreferredCommunicationTable
            isLoading={isLoading}
            onEdit={handleCreate}
          />
          {isModalOpen && (
            <PreferredCommunicationModal
              load={load}
              editingPreferredCommunication={editingPreferredCommunication}
              setEditingPreferredCommunication={
                setEditingPreferredCommunication
              }
              onClose={handleClose}
            />
          )}
        </>
      )}
    </>
  )
}

export default PreferredCommunication
