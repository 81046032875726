// @flow

import React from 'react'
import type { Node } from 'react'

import styles from './Notes.module.scss'

type Props = { notes: string }

const Notes = (props: Props): Node => {
  const { notes } = props

  return (
    <div className={styles.notes}>
      <span>{notes}</span>
    </div>
  )
}

export default Notes
