/**
 * @flow
 * @relayHash ad92bf5c67446c90631fb3b7d0792c02
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RemoveFavoriteProvidersMutationInput = {|
  ids: $ReadOnlyArray<?string>,
  clientMutationId?: ?string,
|};
export type FavoriteProvidersMutationVariables = {|
  input: RemoveFavoriteProvidersMutationInput
|};
export type FavoriteProvidersMutationResponse = {|
  +removeFavoriteProviders: ?{|
    +removedIds: $ReadOnlyArray<?string>
  |}
|};
export type FavoriteProvidersMutation = {|
  variables: FavoriteProvidersMutationVariables,
  response: FavoriteProvidersMutationResponse,
|};
*/


/*
mutation FavoriteProvidersMutation(
  $input: RemoveFavoriteProvidersMutationInput!
) {
  removeFavoriteProviders(input: $input) {
    removedIds
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RemoveFavoriteProvidersMutationInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "removeFavoriteProviders",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RemoveFavoriteProvidersMutationPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "removedIds",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "FavoriteProvidersMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "FavoriteProvidersMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "FavoriteProvidersMutation",
    "id": null,
    "text": "mutation FavoriteProvidersMutation(\n  $input: RemoveFavoriteProvidersMutationInput!\n) {\n  removeFavoriteProviders(input: $input) {\n    removedIds\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd054d57d810b3d5a1d773317e4573e59';
module.exports = node;
