// @flow

import React from 'react'
import { useRouteMatch, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import RouterTabs from '../Tabs/RouterTabs'
import Pan from '../Tabs/Pan'
import Files from '../Files/Files'
import DocumentMapping from '../DocumentMapping'

const FilesTab = () => {
  const match = useRouteMatch()
  const location = useLocation()
  const { t } = useTranslation('Files')

  return (
    <div className='content__col' style={{ paddingTop: '20px' }}>
      <section className='settings-rights unit unit--default'>
        <RouterTabs>
          <Pan label={t('FileListTitle')} linkHash='#dir'>
            <Files match={match} location={location} />
          </Pan>
          <Pan
            label={t('DocumentMapping:DocumentMappingTitle')}
            linkHash='#map'
          >
            <DocumentMapping match={match} location={location} />
          </Pan>
        </RouterTabs>
      </section>
    </div>
  )
}

export default FilesTab
