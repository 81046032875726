// @flow

import React from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import type { Node } from 'react'

import List from '../../../List'

import styles from './InvoiceList.module.scss'

type Props = { children: Node }

const InvoiceList = (props: Props): Node => {
  const { children } = props

  return (
    <DndProvider backend={HTML5Backend}>
      <List styleType='bordered' className={styles.list}>
        {children}
      </List>
    </DndProvider>
  )
}

export default InvoiceList
