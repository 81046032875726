// @flow

export const INVITED_STATUS = 'invited'
export const ACTIVE_STATUS = 'active'
export const IMPORTED_STATUS = 'imported'
export const BLOCKED_STATUS = 'blocked'
export const NOT_CONFIRMED_STATUS = 'not_confirmed'

export const USER_STATUS = [
  INVITED_STATUS,
  ACTIVE_STATUS,
  IMPORTED_STATUS,
  BLOCKED_STATUS,
  NOT_CONFIRMED_STATUS,
]

export const DEFAULT_AVATAR =
  'https://files.idwell.com/sys/thumbnail/170_default_avatar.png'

export const USER_GROUPS = {
  admin: 'admin',
  dweller: 'dweller',
  landlord: 'landlord',
  manager: 'manager',
  worker: 'worker',
  externalContact: 'external_contact',
}

export const STATUS_COLORS = [
  'warning',
  'success',
  'warning',
  'danger',
  'warning',
]
