// @flow

import React, { useState, Fragment } from 'react'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer, graphql } from 'react-relay'
import { Link } from 'react-router-dom'
import { get, has, isEmpty, isInteger } from 'lodash-es'
import { captureException } from '@sentry/browser'
import { useSelector } from 'react-redux'
import linkifyHtml from 'linkifyjs/html'

import { MARKETPLACE_ROUTES } from '../../constants'
import environment from '../../createRelayEnvironment'
import { DocumentsView } from '../../components/Documents'
import { getAvatar } from '../../utils/utils'
import { getThumbnails } from './OfferProfile.utils'
import { EntityIcon } from '../../components/Icon'
import Modal from '../../components/Modal'
import Tabs from '../../components/MarketplaceTabs'
import OfferProfileMutation from './OfferProfile.mutation'
import OfferProfileModal from './OfferProfile.modal'
import Button from '../../components/Button'
import { getUser } from '../../utils/commonSelectors'
import { parseMpPrice } from '../../utils/utils'

import styles from './OfferProfile.module.scss'

const OfferProfile = props => {
  const { offer } = props
  const [loading, setLoading] = useState(false)
  const [orderInfo, setOrderInfo] = useState(null)
  const [isConfirmModal, setConfirmModal] = useState(false)
  const [comment, setComment] = useState('')
  const [isOrderModal, setOrderModal] = useState(false)
  const { t } = useTranslation('Offers')

  const {
    id,
    name,
    provider: {
      name: providerName,
      contacts,
      id: providerId,
      image: providerImage,
    },
    gallery,
    description,
    enabled,
    appliedAttributes,
  } = offer

  const attributes = JSON.parse(appliedAttributes)

  const makeAnOrder = () => {
    setLoading(true)

    const orderInput = Object.assign({}, comment ? { comment } : undefined)

    OfferProfileMutation.commit(
      environment,
      { offerId: id, ...orderInput },
      (info, errors) => {
        if (errors && errors.length > 0) {
          const errorData = JSON.stringify(errors)
          captureException(errorData)
          setLoading(false)

          return
        }

        const orderPhone = contacts.find(
          contact => contact.type === 'order_phone'
        )
        const contactPhone = contacts.find(
          contact => contact.type === 'contact_phone'
        ).value
        setOrderInfo({
          ...info,
          phone: (orderPhone && orderPhone.value) || contactPhone,
        })
        setConfirmModal(false)
        setOrderModal(true)
        setLoading(false)
      },
      () => {
        setLoading(false)
      }
    )
  }

  const renderCurrentTab = function (id) {
    switch (id) {
      case 'attributes':
        return (
          <div className={styles['tab-content']}>
            <div className={styles['attributes-header']}>{t('attributes')}</div>
            <div className={styles['attributes-items']}>
              {attributes
                .filter(({ value }) => !isEmpty(value) || isInteger(value))
                .map((attribute, i) => (
                  <div
                    className={styles['attributes-item']}
                    key={`attribute-${i}`}
                  >
                    <div className={styles['attributes-name']}>
                      {attribute.label}
                    </div>
                    <div className={styles['attributes-value']}>
                      {Array.isArray(attribute.value)
                        ? attribute.value.join(' / ')
                        : has(attribute, 'type') && attribute.type === 'ADDRESS'
                        ? attribute.value.value
                        : attribute.value}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )
      case 'gallery': {
        let galleryContent = null

        if (gallery.length !== 0) {
          const filteredGallery = [
            gallery.find(galleryItem => galleryItem.default),
            ...gallery.filter(galleryItem => !galleryItem.default),
          ]

          const galleryItems = filteredGallery.map(item => ({
            src: item.origin,
            id: item.id,
            thumbnails: getThumbnails(item.thumbnails),
            alt: item.alt,
            preview: item.origin,
            origin: item.origin,
            mime_type: 'image/png',
            type: 'image/png',
          }))

          galleryContent = (
            <div
              className={classnames(
                styles['tab-content'],
                styles['tab-gallery']
              )}
            >
              <div className={styles['attributes-header']}>{t('gallery')}</div>
              <div>
                <DocumentsView
                  takeOrigin
                  showGreatPreview
                  withoutBlanks
                  withoutDownload
                  files={galleryItems}
                  minRowLength={3}
                />
              </div>
            </div>
          )
        }

        return (
          <Fragment>
            <div
              className={classnames(
                styles['tab-content'],
                styles['tab-description']
              )}
            >
              <div className={styles['description-header']}>
                {t('description')}
              </div>
              <div
                className={styles['description-text']}
                dangerouslySetInnerHTML={{
                  __html: linkifyHtml(description),
                }}
              />
            </div>
            {galleryContent}
          </Fragment>
        )
      }
      default:
        return null
    }
  }

  const tabs = [
    {
      id: 'gallery',
      text: t('mainInfo'),
      content: renderCurrentTab('gallery'),
    },
  ]

  if (attributes && attributes.length) {
    tabs.push({
      id: 'attributes',
      text: t('attributes'),
      content: renderCurrentTab('attributes'),
    })
  }

  const {
    language_obj: { code },
  } = useSelector(getUser)

  const isNullPrice = parseFloat(offer.finalPrice) === 0

  const priceType = get(offer, ['priceType', 'name'], '')

  const finalPrice = isNullPrice
    ? t('CostWilBeSpecified')
    : t('price', {
        price: parseMpPrice(offer.finalPrice, code),
        priceType,
      })

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <Modal
          contentLabel=''
          className='Modal__Bootstrap modal-dialog'
          isOpen={isConfirmModal || isOrderModal}
        >
          {isConfirmModal && (
            <OfferProfileModal.Confirm
              loading={loading}
              comment={comment}
              setComment={setComment}
              onClose={() => {
                setConfirmModal(false)
              }}
              onOrder={makeAnOrder}
            />
          )}
          {isOrderModal && (
            <OfferProfileModal.CreateOrder
              info={orderInfo}
              onClose={() => {
                setOrderModal(false)
              }}
            />
          )}
        </Modal>
        <div className={styles['header-container']}>
          <div className={styles.header}>
            <EntityIcon large id='offer' />
            <div className={styles['header-name']}>{name}</div>
          </div>
          <div className={styles.info}>
            <div className={styles.price}>{finalPrice}</div>
          </div>
          <div className={styles.company}>
            <div className={styles['company-header']}>{t('provider')}</div>
            <Link
              to={MARKETPLACE_ROUTES['marketplaceProvider'](providerId)}
              className={styles['company-value']}
            >
              <img
                className={styles['company-image']}
                src={getAvatar(providerImage)}
              />
              <span>{providerName}</span>
            </Link>
          </div>
          <div className={styles.company}>
            <Button.Save
              type='button'
              disabled={!enabled}
              style={{ width: 200, justifyContent: 'center' }}
              onClick={() => {
                setConfirmModal(true)
              }}
            >
              {t('orderOffer')}
            </Button.Save>
          </div>
        </div>
        <Tabs items={tabs} />
      </div>
    </div>
  )
}

export default createFragmentContainer(OfferProfile, {
  offer: graphql`
    fragment OfferProfile_offer on OfferNode
    @argumentDefinitions(offerID: { type: ID, defaultValue: 0 }) {
      id
      name
      description
      finalPrice
      priceType {
        name
      }
      gallery {
        id
        origin
        default
        alt
        thumbnails {
          size
          url
        }
      }
      provider {
        id
        name
        contacts {
          type
          value
          verboseName
        }
        image {
          origin
          alt
          thumbnails {
            size
            url
          }
        }
      }
      appliedAttributes
      enabled
    }
  `,
})
