// @flow

import React from 'react'
import type { Node } from 'react'

type Props = {
  children: Node,
}

const HeaderRow = ({ children }: Props) => (
  <div className='table__head'>{children}</div>
)

export default HeaderRow
