// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import NewTableHeader from '../../NewTable/NewTableHeader'
import NewTableHeaderCell from '../../NewTable/NewTableHeaderCell'
import NewTableHeaderRow from '../../NewTable/NewTableHeaderRow'

const TableHead = (): Node => {
  const { t } = useTranslation('Pipeline')

  return (
    <NewTableHeader>
      <NewTableHeaderRow>
        <NewTableHeaderCell title={t('PipelinesNameSort')} />
        <NewTableHeaderCell title={t('PipelinesReqyestTypeSort')} />
      </NewTableHeaderRow>
    </NewTableHeader>
  )
}

export default TableHead
