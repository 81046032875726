// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import type { Node } from 'react'

import Modal from '../Modal'
import ModalBody from '../Modal/ModalBody'
import ModalCloseButton from '../Modal/ModalCloseButton'
import ModalHead from '../Modal/ModalHead'
import { useOverflow } from '../../hooks/useOverflow'
import NewSelectSimple from '../NewSelectSimple/NewSelectSimple'
import Button from '../Button'
import ModalButtons from '../Modal/ModalButtons'
import { patchThread } from '../../core/api/api.request'
import {
  DWELLER_AUDIENCE,
  MANAGER_AUDIENCE,
  PROVIDER_AUDIENCE,
} from '../../constants'

type Props = {
  audience?: string,
  isOpen: boolean,
  onClose: () => void,
  reloadThreads: true => void,
  requestId: number,
  thread: Object,
}

const AudienceChangeModal = (props: Props): Node => {
  const { isOpen, requestId, thread } = props

  const { t } = useTranslation('Request')

  useOverflow()

  const options = [
    { label: t('ProviderAudience'), value: PROVIDER_AUDIENCE },
    { label: t('DwellerAudience'), value: DWELLER_AUDIENCE },
    { label: t('ManagerAudience'), value: MANAGER_AUDIENCE },
  ].filter(option => option.value !== thread.audience)

  const inititalAudience = options[0]

  const formik = useFormik({
    validateOnChange: true,
    enableReinitialize: true,
    initialValues: {
      audience: inititalAudience,
    },
    validationSchema: Yup.object({
      audience: Yup.object()
        .shape({ value: Yup.string(), label: Yup.string() })
        .required(),
    }),
    onSubmit: submitValues => {
      patchThread(requestId, thread.threadUuid, {
        audience: submitValues.audience.value,
      }).finally(() => {
        props.onClose()
        props.reloadThreads(true)
      })
    },
  })

  const handleChange = option => formik.setFieldValue('audience', option)

  return (
    <Modal
      isOpen={isOpen}
      style={{ content: { minWidth: '348px' } }}
      onRequestClose={props.onClose}
    >
      <ModalHead title={t('ChangeAudienceTitle')} />
      <ModalCloseButton onClose={props.onClose} />
      <ModalBody style={{ marginBottom: 0 }}>
        <form onSubmit={formik.handleSubmit}>
          <NewSelectSimple
            options={options}
            name='audience'
            value={formik.values.audience}
            onChange={handleChange}
          />
          <ModalButtons>
            <Button.Save type='submit'>{t('ChangeAudienceSubmit')}</Button.Save>
            <Button.Cancel onClick={formik.handleReset}>
              {t('Common:Cancel')}
            </Button.Cancel>
          </ModalButtons>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default AudienceChangeModal
