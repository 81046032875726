// @flow

import { getApi, patchApi, patchRaw } from './api.core'

export const getUks = () => getApi('api/uk/')
export const getUk = id => getApi(`api/uk/${id}/`)
export const update = (id, params) => patchApi(`api/uk/${id}/`, params)
export const getConfig = () => getApi(`api/uk/config/`)
export const updateConfig = params => patchRaw(`api/uk/config/`, params)
export const getCurrency = () => getApi('api/uk/currency/')
