// @flow

import React, { useEffect, useState } from 'react'
import type { Node } from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import MailReplyForm from '../../Mail/MailReplyForm'

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { getMailAccountSimpleList } from '../../../core/api/api.mail'
import { getAccounts } from '../../Mail/NewMailReplyForm/NewMailReplyForm.utils'
import { hasEmail } from '../../../utils/utils'

import Loader from '../../../components/Loader'

const MAX_FILES_SIZE = 9 * 1024 * 1024

type Props = {
  data: Object,
  onUpdate: Object => void,
  setWorking: boolean => void,
}

const Email = (props: Props): Node => {
  const { data } = props

  const { t } = useTranslation('RequestConstructor')

  const [accounts, setAccounts] = useState([])
  const [inited, setInited] = useState(false)
  const [, setFiles] = useState([])
  const [, setFileIndex] = useState(0)

  const formatRecipients = recipients =>
    recipients.map(r => r.profile?.email || r.name)

  const getInitialValues = () => {
    const getList = list => list.map(i => ({ name: i }))

    return {
      audience: data.audience || null,
      attach_option: data.attach_option,
      bcc: getList(data.bcc || []),
      cc: getList(data.cc || []),
      files: data.files || [],
      files_as_links: data.files_as_links || false,
      files_options: data.files_options || [],
      message: data.text || '',
      title: data.subject || '',
      to: getList(data.to || []),
      send_by_mail_account_id: data.send_by_mail_account_id || null,
      send_by_system_credentials: data.send_by_system_credentials || false,
      reply_to_mail_account_id: data.reply_to_mail_account_id || null,
      to_options: data.to_options || [],
      cc_options: data.cc_options || [],
      bcc_options: data.bcc_options || [],
    }
  }

  const [values, setValues] = useState(getInitialValues())

  const updateValues = values => {
    setValues(values)
    props.onUpdate({
      audience: values.audience,
      attach_option: values.attach_option,
      bcc: formatRecipients(values.bcc?.filter(hasEmail) || []),
      cc: formatRecipients(values.cc?.filter(hasEmail) || []),
      files_as_links: values.files_as_links,
      files_options: values.files_options,
      file_ids: values.files.map(f => f.id),
      text: values.message.replace(/\n/g, ''),
      subject: values.title,
      to: formatRecipients(values.to?.filter(hasEmail) || []),
      send_by_mail_account_id: values.send_by_mail_account_id,
      send_by_system_credentials: values.send_by_system_credentials,
      reply_to_mail_account_id: values.reply_to_mail_account_id,
      to_options: values.to_options,
      cc_options: values.cc_options,
      bcc_options: values.bcc_options,
    })
  }

  useEffect(() => {
    if (accounts.length === 0) {
      getMailAccountSimpleList({
        is_active: true,
        info: true,
      }).then(data => {
        setAccounts([...getAccounts(data)])
        setInited(true)
      })
    }
  }, [])

  useEffect(() => {
    const filesSize = values.files.reduce((acc, item) => {
      return acc + item.size
    }, 0)
    const bodySize = values.message.length

    if (filesSize + bodySize > MAX_FILES_SIZE && !values.files_as_links) {
      props.setWorking(true)
    } else {
      props.setWorking(false)
    }
  }, [values.files, values.message, values.files_as_links])

  const setViewFiles = ind => {
    setFileIndex(ind)
    setFiles([...values.files])
  }

  const cardClass = classnames(
    'request-constructor__card--body request-constructor__card--email'
  )

  return (
    <div className={cardClass}>
      {inited ? (
        <>
          <div>{t('emailDescription')}</div>
          <DndProvider backend={HTML5Backend}>
            <MailReplyForm
              automation
              creating
              showAddButton
              onlyManualFileRemove
              withProviders
              accounts={accounts}
              uuid={`card-${data.id}`}
              values={values}
              setFileIndex={setViewFiles}
              isCCShown={values.cc?.length > 0 || values.cc_options?.length > 0}
              isBCCShown={
                values.bcc?.length > 0 || values.bcc_options?.length > 0
              }
              onUpdate={updateValues}
              onFilesUploading={() => props.setWorking(true)}
              onFinishedFilesUploading={() => props.setWorking(false)}
            />
          </DndProvider>
        </>
      ) : (
        <Loader type='big' text={false} />
      )}
    </div>
  )
}

export default Email
