// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { push } from 'connected-react-router'
import { isNull, isEmpty, take, omitBy, isNil, isBoolean } from 'lodash-es'
import { compose } from 'redux'
import classnames from 'classnames'
import type { Node } from 'react'
import type { Location } from 'react-router-dom'

import { Header, SimpleBlock } from '../../Block'
import Nbsp from '../../NBSP'
import BuildingPopup from '../../modals/BuildingPopup'
import BuildingMappingModal from '../../../containers/ProvidersPage/AllProviders/BuildingMappingModal'
import BottomPagination from '../../Pagination/BottomPagination'
import Button from '../../Button'
import ConfirmationPopup from '../../modals/ConfirmationPopup'
import EmptyList from '../../EmptyList'
import Loader from '../../Loader'
import MessagePopup from '../../modals/Message'
import Modal from '../../Modal'
import TableList from '../TableList'
import TileList from '../TileList'
import ListViewToggleButton from '../../ListViewToggleButton'
import TopPagination from '../../Pagination/TopPagination'
import { getCurrentPage, getCurrentFilters } from '../../../utils/routing'
import { getLocation } from '../../../utils/commonSelectors'
import {
  SHORT_TABLE_ITEMS_COUNT,
  SHORT_TILE_ROW_ITEMS_COUNT,
} from './BuildingList.constants'
import { ALL_PAGES } from '../../../constants'
import {
  updateImages,
  init,
  load,
  remove,
  removeFromGroup,
  reset,
  restore,
  updateGroupBuildings,
} from './BuildingList.actions'
import { getContractorBuildingOptions } from '../../../core/api/api.contractor'
import ShowAllButton from '../../ShowAllButton'
import Checkbox from '../../Checkbox'

type Props = {
  buildingGroup?: number,
  buildings: Array<Object>,
  group: string,
  handleSort: string => void,
  init: Object => Object,
  initiated: boolean,
  load: Object => Object,
  location: Location,
  meta: Object,
  onInit?: () => void,
  owner?: number,
  permissions: Object,
  push: string => Object,
  remove: (Object, Array<string>) => Object,
  removeFromGroup: (Object, Array<number>) => Object,
  reset: () => Object,
  restore: (Object, Array<string>) => Object,
  search?: ?string,
  setCount?: number => void,
  shortView?: boolean,
  t: string => string,
  working: boolean,
}
type State = {
  allSelected: boolean,
  buildingMappingLoading: boolean,
  modal: ?Node,
  selectedIds: Array<number>,
  shortView: boolean,
  tileView: boolean,
}

class BuildingList extends Component<Props, State> {
  state = {
    allSelected: false,
    modal: null,
    selectedIds: [],
    shortView: !!this.props.shortView,
    tileView: false,
    buildingMappingLoading: false,
  }

  nodeToScroll = null

  componentDidMount() {
    const { location } = this.props
    const tileView = localStorage.getItem('tileView')
    const page = getCurrentPage(location)
    const filters = getCurrentFilters(location)

    if (tileView !== null) {
      this.setState({ tileView: tileView === 'true' })
    } else {
      localStorage.setItem('tileView', 'false')
    }

    if (filters.page) {
      this.props.init(this.getParams({ page, ...filters }))
    }
  }

  componentDidUpdate(prev: Props, prevState) {
    const { initiated, location, search, buildings, meta, setCount } =
      this.props
    const { tileView } = this.state

    if (search !== prev.search) {
      this.load()

      return
    }

    if (this.props.onInit && initiated && !prev.initiated) {
      this.props.onInit()
    }

    if (location.search !== prev.location.search && !prev.working) {
      const page = getCurrentPage(location)
      const filters = getCurrentFilters(location)

      this.setPage(page, this.nodeToScroll, filters)
    }

    if (
      isBoolean(prevState.tileView) &&
      isBoolean(tileView) &&
      prevState.tileView !== tileView &&
      buildings.length
    ) {
      const imgIds = buildings
        .map(building =>
          building.photo_obj && building.photo_obj.id
            ? building.photo_obj.id
            : null
        )
        .filter(id => !isNull(id))

      this.props.updateImages(imgIds)
    }

    if (meta.count && setCount) {
      setCount(meta.count)
    }
  }

  componentWillUnmount() {
    this.props.reset()
  }

  getShortViewItemsCount = () => {
    const { owner } = this.props
    const { tileView } = this.state

    return tileView && owner
      ? SHORT_TILE_ROW_ITEMS_COUNT
      : SHORT_TABLE_ITEMS_COUNT
  }

  getItems = () => {
    const { buildings } = this.props
    const { shortView } = this.state

    return shortView
      ? take(buildings, this.getShortViewItemsCount())
      : buildings
  }

  getTitle = () => {
    const { search } = this.props

    return search
      ? `${this.props.t('SearchResults')}: ${decodeURIComponent(search)}`
      : this.props.t('ListTitle')
  }

  getParams = (params: Object = {}) => {
    const { owner, search, buildingGroup, contractorId } = this.props

    return omitBy(
      {
        owner,
        buildingGroup,
        contractorId,
        search: search ? decodeURIComponent(search) : search,
        all: true,
        ...params,
      },
      isNil
    )
  }

  setPage = (page: number, nodeToScroll, filters: Object) => {
    this.props.load(this.getParams({ page, ...filters }))
    this.setState({ selectedIds: [], allSelected: false })

    if (nodeToScroll) {
      nodeToScroll.scrollIntoView()
    }
  }

  hidePopup = () => {
    this.setState({ modal: null })
  }

  create = () => {
    this.props.push(`/building/create/`)
  }

  editGroupBuildings = () => {
    this.setState({
      modal: (
        <BuildingPopup
          editGroup={this.props.buildingGroup}
          onClose={this.hidePopup}
          onUpdateGroup={(buildings, update_responsible_users) =>
            this.updateGroupBuildings(buildings, update_responsible_users)
          }
        />
      ),
    })
  }

  matchBuildings = () => {
    const { contractorId: global_id } = this.props
    this.setState({ buildingMappingLoading: true })
    getContractorBuildingOptions({
      contractor: global_id,
      page_size: ALL_PAGES,
    }).then(data => {
      this.setState({
        buildingMappingLoading: false,
        modal: (
          <BuildingMappingModal
            isContractor
            buildingModal={{
              global_id,
              buildings: data.results.objects,
            }}
            setLoading={() => this.load()}
            onClose={this.hidePopup}
          />
        ),
      })
    })
  }

  updateGroupBuildings = (buildings, update_responsible_users) => {
    this.props.updateGroupBuildings(
      this.getParams(),
      buildings,
      update_responsible_users
    )
    this.setState({ selectedIds: [], allSelected: false })
    this.hidePopup()
  }

  toggleView = () => {
    this.setState(
      state => ({ tileView: !state.tileView }),
      () => {
        localStorage.setItem('tileView', this.state.tileView.toString())
      }
    )
  }

  load = () => {
    const { location } = this.props
    const filters = getCurrentFilters(location)

    this.props.load(this.getParams({ ...filters }))
  }

  handleSort = (key: string) => () => {
    this.props.handleSort(key)
  }

  confirmRemove = () => {
    const { buildings, buildingGroup } = this.props
    const { selectedIds } = this.state
    const hasDwellers = id =>
      Boolean(buildings.find(b => b.id === id).dweller_count)
    const hasFlats = id => Boolean(buildings.find(b => b.id === id).flat_count)

    if (selectedIds.length === 1 && hasDwellers(selectedIds[0])) {
      this.setState({
        modal: (
          <MessagePopup
            title={'BuildingDeleteTitle'}
            t={this.props.t}
            text={'you cannot delete a building if dwellers count more 1'}
            onOk={this.hidePopup}
          />
        ),
      })
    } else if (selectedIds.length === 1 && hasFlats(selectedIds[0])) {
      this.setState({
        modal: (
          <MessagePopup
            title={'BuildingDeleteTitle'}
            t={this.props.t}
            text={'you cannot delete a building if flats count more 1'}
            onOk={this.hidePopup}
          />
        ),
      })
    } else {
      this.setState({
        modal: (
          <ConfirmationPopup
            title={this.props.t('BuildingDeleteTitle')}
            text={this.props.t('BuildingDeleteText')}
            confirm={this.props.t('Delete')}
            cancel={this.props.t('Cancel')}
            isAsync={buildingGroup ? false : true}
            onClose={this.hidePopup}
            onOk={() =>
              buildingGroup ? this.confirmRemoveManagers() : this.remove()
            }
          />
        ),
      })
    }
  }

  confirmGroupRemove = () => {
    this.setState({
      modal: (
        <ConfirmationPopup
          title={this.props.t('BuildingDeleteFromGroupTitle')}
          text={this.props.t('BuildingDeleteFromGroupText')}
          confirm={this.props.t('Delete')}
          cancel={this.props.t('Cancel')}
          isAsync={false}
          onClose={this.hidePopup}
          onOk={() => this.confirmRemoveManagers(true)}
        />
      ),
    })
  }

  confirmRemoveManagers = (from_group = false) => {
    this.setState({
      modal: (
        <ConfirmationPopup
          title={this.props.t('RemoveManagersTitle')}
          text={this.props.t('RemoveManagersBuildingText')}
          confirm={this.props.t('No')}
          cancel={this.props.t('RemoveManagersButton')}
          onClose={this.hidePopup}
          onCancel={() =>
            from_group ? this.removeFromGroup(true) : this.remove(true)
          }
          onOk={() =>
            from_group ? this.removeFromGroup(false) : this.remove(false)
          }
        />
      ),
    })
  }

  confirmRestore = () => {
    this.setState({
      modal: (
        <ConfirmationPopup
          title={this.props.t('BuildingRestoreTitle')}
          text={this.props.t('BuildingRestoreText')}
          confirm={this.props.t('Common:Restore')}
          cancel={this.props.t('Cancel')}
          onClose={this.hidePopup}
          onOk={this.restore}
        />
      ),
    })
  }

  toggleAllSelected = () => {
    const { allSelected } = this.state
    this.setState({
      allSelected: !allSelected,
      selectedIds: [],
    })
  }

  remove = (update_responsible_users = false) => {
    const { selectedIds, allSelected } = this.state

    this.props.remove(
      this.getParams(),
      allSelected ? this.getItems().map(item => item.id) : selectedIds.slice(),
      update_responsible_users
    )
    this.setState({ selectedIds: [], allSelected: false })
    this.hidePopup()
  }

  removeFromGroup = (update_responsible_users = false) => {
    const { selectedIds, allSelected } = this.state

    this.props.removeFromGroup(
      this.getParams(),
      allSelected ? this.getItems().map(item => item.id) : selectedIds.slice(),
      update_responsible_users
    )
    this.setState({ selectedIds: [], allSelected: false })
    this.hidePopup()
  }

  restore = () => {
    const { selectedIds, allSelected } = this.state

    this.props.restore(
      this.getParams(),
      allSelected ? this.getItems().map(item => item.id) : selectedIds.slice()
    )
    this.setState({ selectedIds: [], allSelected: false })
    this.hidePopup()
  }

  toggleItemSelect = (id: number) => {
    const { buildings } = this.props
    const { selectedIds, allSelected } = this.state

    if (allSelected) {
      const ids = buildings.map(b => b.id)
      this.setState({
        selectedIds: ids.filter(item => item !== id),
        allSelected: false,
      })

      return
    }

    if (selectedIds.includes(id)) {
      this.setState({
        selectedIds: selectedIds.filter(item => item !== id),
        allSelected: false,
      })
    } else {
      const all = selectedIds.length === buildings.length - 1
      this.setState({
        selectedIds: all ? [] : [...selectedIds, id],
        allSelected: all,
      })
    }
  }

  renderPopup = () => {
    const { modal } = this.state

    return (
      <Modal
        className='Modal__Bootstrap modal-dialog'
        isOpen={!!modal}
        onRequestClose={this.hidePopup}
      >
        {modal}
      </Modal>
    )
  }

  handleShowAll = () => {
    this.setState({ shortView: false })
  }

  handleDeleteFromGroupOption = id => {
    this.setState({ selectedIds: [id] })
    this.confirmGroupRemove()
  }

  renderShowMore() {
    const { meta } = this.props
    const { shortView } = this.state

    if (shortView && meta.count > this.getShortViewItemsCount()) {
      return (
        <ShowAllButton
          count={meta.count}
          text={this.props.t('ShowAll')}
          onClick={this.handleShowAll}
        />
      )
    }

    return null
  }

  renderEmpty = () => {
    const {
      permissions: { can_create: canAdd },
      search,
      canChange,
      contractorId,
    } = this.props

    const addText = canAdd && !search ? ` ${this.props.t('AddFirst')}` : ''
    const title = `${this.props.t(
      search ? 'NoBuildingsFound' : 'NoBuildings'
    )}${addText}`

    return (
      <EmptyList
        btnText={this.props.t('Add')}
        canAdd={!contractorId && (canAdd || canChange)}
        icon='building'
        title={title}
        onClick={this.create}
      />
    )
  }

  render() {
    if (!this.props.initiated) {
      return <Loader text={false} type='big' />
    }

    const {
      meta,
      working,
      owner,
      group,
      permissions: {
        can_delete: canDelete,
        can_create: canAdd,
        can_restore: canRestore,
        can_manage_buildings: canManageBuildings,
      },
      buildings,
      location,
      noMargin,
      noHeader,
      buildingGroup,
      contractorId,
      canChange,
    } = this.props

    const filters = getCurrentFilters(location)

    if (!working && meta.count === 0 && isEmpty(filters)) {
      return (
        <section className='unit unit--flat'>
          <h2 className='unit__title'>{this.getTitle()}</h2>
          {this.renderEmpty()}
          {this.renderPopup()}
        </section>
      )
    }

    const {
      allSelected,
      selectedIds,
      tileView,
      shortView,
      buildingMappingLoading,
    } = this.state
    const items = this.getItems()
    const toolboxStyle = classnames('toolbox', {
      'toolbox--short': shortView,
      'toolbox--no-border': !shortView,
    })

    const simpleBlockStyle = Object.assign(
      {},
      noMargin ? { marginTop: 0, marginBottom: 0 } : undefined,
      noHeader ? { border: 'none' } : undefined
    )

    return (
      <SimpleBlock style={simpleBlockStyle}>
        {noHeader ? (
          <div header style={{ display: 'none' }}></div>
        ) : (
          <Header header>
            {this.getTitle()}
            {!!meta.count && (
              <span className='unit__title-num'>
                <Nbsp />
                {meta.count}
              </span>
            )}
          </Header>
        )}
        <div className={toolboxStyle}>
          {!contractorId && (canDelete || canRestore) && !!meta.count && (
            <div className='toolbox__cell'>
              <Checkbox
                outer
                checked={allSelected}
                onChange={this.toggleAllSelected}
              />
            </div>
          )}
          {contractorId && canChange && (
            <div className='toolbox__cell'>
              <Button.Save
                working={buildingMappingLoading}
                disabled={working}
                icon='building'
                onClick={this.matchBuildings}
              >
                {this.props.t('Contractors:SetBuildings')}
              </Button.Save>
            </div>
          )}
          {canAdd && selectedIds.length === 0 && !allSelected && (
            <div className='toolbox__cell'>
              <Button.Save
                disabled={working}
                icon='building'
                onClick={this.create}
              >
                {this.props.t('AddBuilding')}
              </Button.Save>
            </div>
          )}
          {canManageBuildings && selectedIds.length === 0 && !allSelected && (
            <div className='toolbox__cell'>
              <Button.Save
                disabled={working}
                icon='building'
                onClick={this.editGroupBuildings}
              >
                {this.props.t('EditGroupBuildings')}
              </Button.Save>
            </div>
          )}
          {canDelete && (allSelected || selectedIds.length > 0) && (
            <>
              <div className='toolbox__cell'>
                <Button.Remove
                  disabled={working}
                  onClick={this.confirmRemove}
                />
              </div>
              {buildingGroup && (
                <div className='toolbox__cell'>
                  <Button.Cancel
                    disabled={
                      working ||
                      (!allSelected && !selectedIds.length) ||
                      !buildings.length
                    }
                    icon='building'
                    onClick={this.confirmGroupRemove}
                  >
                    {this.props.t('DeleteFromGroup')}
                  </Button.Cancel>
                </div>
              )}
            </>
          )}
          {canRestore && (
            <div className='toolbox__cell'>
              <Button.Cancel
                disabled={
                  working ||
                  (!allSelected && !selectedIds.length) ||
                  !buildings.length
                }
                icon='restore'
                onClick={this.confirmRestore}
              >
                {this.props.t('Common:Restore')}
              </Button.Cancel>
            </div>
          )}
          {!shortView && (
            <div className='toolbox__cell toolbox__cell--right'>
              <TopPagination
                classes='pager pager--large pager--default-2'
                meta={meta}
              />
            </div>
          )}
          <ListViewToggleButton tileView={tileView} onClick={this.toggleView} />
        </div>

        {!tileView ? (
          <TableList
            searching={!!this.props.search}
            permissions={this.props.permissions}
            allSelected={allSelected}
            EmptyList={this.renderEmpty}
            filter={filters}
            items={items}
            sortManagers={!owner && !buildingGroup && !contractorId}
            showExtra={!!buildingGroup && canDelete}
            group={group}
            selectedIds={selectedIds}
            working={working}
            location={location}
            onDeleteFromGroup={this.handleDeleteFromGroupOption}
            onSort={this.handleSort}
            onToggleItem={!contractorId && this.toggleItemSelect}
          />
        ) : (
          <TileList
            searching={!!this.props.search}
            allSelected={allSelected}
            EmptyList={this.renderEmpty}
            items={items}
            selectedIds={selectedIds}
            shortRow={!!owner || buildingGroup || contractorId}
            working={working}
            onToggleItem={!contractorId && this.toggleItemSelect}
          />
        )}
        {!shortView && (
          <BottomPagination
            classes='paginator paginator--middle-flat'
            meta={meta}
          />
        )}
        {this.renderShowMore()}
        {this.renderPopup()}
      </SimpleBlock>
    )
  }
}

const mapStateToProps = state => ({
  ...state.buildingList,
  group: state.login.user.group,
  location: getLocation(state),
})

const mapDispatchToProps = {
  init,
  load,
  push,
  remove,
  removeFromGroup,
  reset,
  restore,
  updateImages,
  updateGroupBuildings,
}

export default compose(
  withTranslation('Building'),
  connect(mapStateToProps, mapDispatchToProps)
)(BuildingList)
