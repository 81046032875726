// @flow

import React, { Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'

import PostFiles from '../../../components/Post/PostFiles'
import { DocumentsView } from '../../../components/Documents'
import { getFeedbackData, isWorking } from '../Feedback.selectors'
import { updateFeedbackFiles } from '../Feedback.actions'

type Props = {
  data: Object,
  isWorking: boolean,
  match: Object,
  updateFeedbackFiles: () => void,
}

const Documents = (props: Props) => {
  const { data, match, isWorking } = props
  const isFeedbackPage = match.path === '/request/:requestID/feedback/:id'
  const files = data.file_objs || []

  return (
    <Fragment>
      {isFeedbackPage ? (
        <PostFiles
          files={files}
          permissions={data.permissions}
          saving={isWorking}
          onSave={props.updateFeedbackFiles}
        />
      ) : (
        <DocumentsView
          showGreatPreview
          withoutBlanks
          files={files}
          minRowLength={3}
          scope='request'
        />
      )}
    </Fragment>
  )
}

const mapStateToProps = state => ({
  data: getFeedbackData(state),
  isWorking: isWorking(state),
})
const mapDispatchToProps = {
  updateFeedbackFiles,
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Documents)
