import React from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'

const ButtonsBar = ({ disabled, onSubmit, cancelDisabled, onCancel }) => {
  const { t } = useTranslation('Providers')

  return (
    <div style={{ marginTop: '10px' }}>
      <Button.Regular type='submit' disabled={disabled} onClick={onSubmit}>
        {t('submit')}
      </Button.Regular>
      <Button.Cancel
        disabled={cancelDisabled === undefined ? disabled : cancelDisabled}
        onClick={onCancel}
      >
        {t('cancel')}
      </Button.Cancel>
    </div>
  )
}

export default ButtonsBar
