// @flow

import React, { Suspense } from 'react'
import classnames from 'classnames'

import * as Icons from './EmptyListIcons'
import type { EmptyListIconId } from './EmptyListIcon.types'

import styles from './EmptyListIcon.module.scss'

import Plus from '../../../../static/icons/add.svg'

type Props = {
  canAdd?: boolean,
  className?: string,
  id: EmptyListIconId,
}

const EmptyListIcon = ({ id, canAdd, className }: Props) => {
  const Svg = Icons[id]

  return (
    <Suspense fallback={null}>
      <div className={styles.box}>
        <Svg className={classnames(styles.icon, className)} />
        {canAdd && <Plus className={styles.plus} />}
      </div>
    </Suspense>
  )
}

export default EmptyListIcon
