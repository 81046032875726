import { USER_GROUPS } from '../../../constants'

export const isInitiated = (state) => (
  state.profileInfo.initiated && state.profileContacts.initiated
)

export const getSteps = (state) => {
  const { login: { user }, profileInfo: { backup }, profileContacts } = state
  const firstSteps = [
    { key: 'name', value: backup.name },
    { key: 'avatar', value: backup.avatar && backup.avatar.filename !== 'default_avatar.png' }
  ]
  const lastStep = { key: 'contacts', value: !!profileContacts.data.length }

  return user.group !== USER_GROUPS.dweller
    ? [
      ...firstSteps,
      { key: 'position', value: backup.post },
      lastStep
    ] : [
      ...firstSteps,
      lastStep
    ]
}
