// @flow

export const NUMBER = 'number'
export const TYPE = 'type'
export const PAYMENT_TYPE = 'payment_type'
export const TAX = 'tax'
export const AMOUNT_DUE = 'amount_due'
export const PAYMENT_DATE = 'payment_date'
export const SUPPLIER = 'supplier'
export const RECIPIENT = 'recipient'
export const BIC = 'bic'
export const IBAN = 'iban'
export const DEMAND = 'demand'
export const NOTES = 'notes'
export const INVOICE_FILE = 'invoice_file'
