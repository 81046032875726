import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import InputField from '../../InputField'
import styles from './Card.module.scss'
export const DEADLINE_REGEXP =
  /^ *(\d{1,3}[dD] *)*?(\d{1,2}[hH] *)*?(\d{1,2}[mM])*? *$/

export const getDeadline = val => {
  if (!val) {
    return ''
  }

  let split = val.split(' ')

  if (split.length === 1) {
    if (split[0].length > 2) {
      split[1] = split[0]
      split[0] = '00'
    } else {
      split[1] = '00:00:00'
    }
  }

  const days = split[0]
  const time = split[1].split(':')
  const hours = time[0]
  const minutes = time[1]

  const d = days ? `${days}d` : ''
  const h = hours ? `${days ? ' ' : ''}${hours}h` : ''
  const m = minutes ? `${days || hours ? ' ' : ''}${minutes}m` : ''

  return `${d}${h}${m}`
}

const DueDate = ({ dueDate, onChange }) => {
  const [error, setError] = useState('')
  const [stateValue, setValue] = useState(getDeadline(dueDate))
  const { t } = useTranslation('RequestConstructor')

  const onChangeField = e => {
    const { value } = e.target
    setValue(value)

    if (!DEADLINE_REGEXP.test(value)) {
      setError(t('WrongDeadlineFormat'))
    } else {
      setError('')
      const matched = value.match(DEADLINE_REGEXP)

      const deadlineDays = matched[1]
      const deadlineHours = matched[2]
      const deadlineMinutes = matched[3]

      let obj = {}
      obj.deadlineDays = deadlineDays
        ? deadlineDays.trim().replace(/[dD]/, '')
        : '00'

      obj.deadlineHours = deadlineHours
        ? deadlineHours.trim().replace(/[hH]/, '')
        : '00'

      obj.deadlineMinutes = deadlineMinutes
        ? deadlineMinutes.trim().replace(/[mM]/, '')
        : '00'

      const reqValue = `${obj.deadlineDays} ${obj.deadlineHours}:${obj.deadlineMinutes}:00`

      onChange(reqValue)
    }
  }

  return (
    <div className={styles.input}>
      <InputField
        error={error}
        value={stateValue}
        placeholder='4d 6h 45m'
        onChange={onChangeField}
      />
    </div>
  )
}

export default DueDate
