// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import MenuFilterItem from '../MenuFilterItem'
import MenuDivider from '../MenuDivider'
import MenuResetButton from '../MenuResetButton'

import styles from './MenuFilters.module.scss'

type Props = {
  filterItems: Array<Object>,
  hasSub: boolean,
  menuName: string,
}

const MenuFilters = (props: Props): Node => {
  const { filterItems, menuName, hasSub } = props

  const { t } = useTranslation('Menu')

  return (
    <>
      {hasSub && <MenuDivider />}
      <h3 className={styles.header}>{t('FiltersTitle')}</h3>
      <MenuResetButton menuName={menuName} />
      <ul className={styles.list}>
        {filterItems.map(item => (
          <MenuFilterItem item={item} key={item.name} />
        ))}
      </ul>
    </>
  )
}

export default MenuFilters
