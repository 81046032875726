// @flow

import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import Loader from '../../Loader'
import EmptyList from '../../EmptyList/EmptyList'
import ToolboxCell from '../../Users/UserList/Toolbox/ToolboxCell'
import Button from '../../Button'
import { Header, SimpleBlock } from '../../Block'
import InvoicesTable from './InvoicesTable'
import InvoicesModal from './InvoicesModal/InvoicesModal'
import Toolbox from '../../Toolbox'
import { createReport, deleteInvoice } from '../../../core/api/api.budgetPlan'
import { useSelected } from '../../../hooks'
import ConfirmationPopup from '../../modals/ConfirmationPopup'
import Modal from '../../Modal'
import { getById } from '../../../core/api/api.file'

type Props = { isLoading: boolean, plan: Object, reload: Function }

const Invoices = (props: Props): Node => {
  const { plan, isLoading } = props

  const [isModalOpen, setModalOpen] = useState(false)
  const [removeModal, setRemoveModal] = useState(false)
  const [editingInvoice, setEditingInvoice] = useState(false)
  const [isLoadingReport, setLoadingReport] = useState(false)
  const [reportId, setReportId] = useState(null)

  const [selectedItems, changeSelected, setSelected, isAllSelected] =
    useSelected()
  const timerRef = useRef()

  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current)
    }
  }, [])

  const handleSelect = item => changeSelected(item)

  const getFile = () => {
    if (!reportId) {
      setLoadingReport(false)

      return
    }

    getById(reportId)
      .then(res => {
        if (res.checking) {
          timerRef.current = setTimeout(getFile, 1000)
        } else {
          setLoadingReport(false)
          window.open(res)
        }
      })
      .catch(() => {
        setLoadingReport(false)
      })
  }

  useEffect(() => {
    if (reportId) {
      getFile()
    }
  }, [reportId])

  useEffect(() => {
    if (editingInvoice) {
      setModalOpen(true)
    }
  }, [editingInvoice])

  useEffect(() => {
    if (isLoading) {
      setSelected([])
    }
  }, [isLoading])

  const { t } = useTranslation('BudgetPlan')

  const handleOpenModal = () => setModalOpen(true)
  const handleCloseModal = () => setModalOpen(false)

  const removeInvoices = async () => {
    return Promise.all(
      selectedItems.map(item => {
        return deleteInvoice(item).then(data => data)
      })
    )
  }

  const handleRemove = id => {
    deleteInvoice(id).finally(() => {
      hideModal()
      props.reload()
    })
  }

  const handleMassRemove = async () => {
    hideModal()

    await removeInvoices()

    props.reload()
  }

  const openMassModal = () => {
    setRemoveModal(
      <ConfirmationPopup
        confirm={t('InvoicesRemoveButton')}
        title={t('InvoicesRemoveTitle')}
        text={t('ConfirmInvoicesRemoving')}
        onClose={hideModal}
        onOk={handleMassRemove}
      />
    )
  }

  const openModal = id => {
    setRemoveModal(
      <ConfirmationPopup
        confirm={t('InvoiceRemoveButton')}
        title={t('InvoiceRemoveTitle')}
        text={t('ConfirmInvoiceRemoving')}
        onClose={hideModal}
        onOk={() => handleRemove(id)}
      />
    )
  }

  const openMassRemoveModal = () => {
    openMassModal()
  }

  const openRemoveModal = id => {
    openModal(id)
  }

  const hideModal = () => setRemoveModal(null)

  const handleCreateReport = () => {
    setLoadingReport(true)

    createReport({ budget_plan_id: plan.id, invoice_ids: selectedItems }).then(
      data => {
        setReportId(data.file_id)
      }
    )
  }

  return (
    <SimpleBlock>
      <Header header>{t('InvoicesTitle')}</Header>
      {!isLoading ? (
        <>
          <Toolbox
            showSelectAllCheckbox
            itemValueKey='id'
            checked={isAllSelected(plan.invoices)}
            setSelected={setSelected}
            selected={!!selectedItems.length && !isAllSelected(plan.invoices)}
            items={plan.invoices}
            isDisabled={isLoadingReport}
          >
            {selectedItems.length === 0 && (
              <ToolboxCell>
                <Button.Upload icon='add' onClick={handleOpenModal}>
                  {t('AddInvoice')}
                </Button.Upload>
              </ToolboxCell>
            )}
            {selectedItems.length !== 0 && (
              <>
                <ToolboxCell>
                  <Button.Save
                    working={isLoadingReport}
                    disabled={!selectedItems.length}
                    onClick={handleCreateReport}
                  >
                    {t('CreateReport')}
                  </Button.Save>
                </ToolboxCell>
                <ToolboxCell>
                  <Button.Remove
                    disabled={!selectedItems.length || isLoadingReport}
                    onClick={openMassRemoveModal}
                  >
                    {t('RemoveInvoices')}
                  </Button.Remove>
                </ToolboxCell>
              </>
            )}
          </Toolbox>
          <InvoicesTable
            selectedItems={selectedItems}
            fields={plan.invoice_fields
              .sort((a, b) =>
                a.position_in_table > b.position_in_table ? 1 : -1
              )
              .filter(f => f.show_in_table)}
            invoices={plan.invoices}
            setEditingInvoice={setEditingInvoice}
            openRemoveModal={openRemoveModal}
            isLoadingReport={isLoadingReport}
            onClick={setEditingInvoice}
            onSelect={handleSelect}
          />
        </>
      ) : (
        <Loader type='big' text={false} />
      )}
      {!isLoading && !plan.invoices.length && (
        <EmptyList
          canAdd
          icon='folder'
          title={t('InvoicesNotFound')}
          btnText={t('AddInvoice')}
          onClick={handleOpenModal}
        />
      )}
      {isModalOpen && (
        <InvoicesModal
          planId={plan.id}
          fields={plan.invoice_fields}
          editingInvoice={editingInvoice}
          setEditingInvoice={setEditingInvoice}
          reload={props.reload}
          onClose={handleCloseModal}
        />
      )}
      {!!removeModal && (
        <Modal isOpen={!!removeModal} onRequestClose={hideModal}>
          {removeModal}
        </Modal>
      )}
    </SimpleBlock>
  )
}

export default Invoices
