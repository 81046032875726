// @flow

import { chunk, reduce } from 'lodash-es'

export const getRowsHeight = (heights: Object) => (
  reduce(heights, (acc, h) => acc + h)
)

export const prepareRows = (rows: Array<Object>, length: number) => (
  chunk(rows, length)
    .map((items, i) => ({
      id: `chunk-${i + 1}`,
      items
    }))
)
