import React, { Suspense, useState } from 'react'
import styles from './FilesGallery.module.scss'
import Icon from '../Icon'
import FileDownloader from '../FileDownloader'
import {
  DOC_TYPE,
  getFileType,
  PDF_TYPE,
  PPT_TYPE,
  XLS_TYPE,
  getFileScopeParams,
} from '../../utils/file'

import * as Icons from '../Icon/FileIcon/FileIcons'
import api from '../../core/api'

const FilesGalleryHeader = props => {
  const { onClose, file, withoutDownload, scope, object, outbound, subject } =
    props

  const [working, setWorking] = useState(false)

  const fileType = file.doc ? PDF_TYPE : getFileType(file) || 'none'
  const fileId = file.doc ? file.docId : file.id

  const PrintIcon = Icons.print
  let FileIcon = Icons.other

  switch (fileType) {
    case DOC_TYPE:
      FileIcon = Icons.doc
      break
    case PDF_TYPE:
      FileIcon = Icons.pdf
      break
    case PPT_TYPE:
      FileIcon = Icons.ppt
      break
    case XLS_TYPE:
      FileIcon = Icons.xls
      break
    default:
      FileIcon = Icons.other
      break
  }

  const params = getFileScopeParams(props)

  return (
    <div className={styles.header}>
      <div className={styles.leftTools}>
        <div className={styles.fileName}>
          <Suspense fallback={null}>
            <FileIcon className={styles.fileIcon} />
          </Suspense>
          <span className={styles.title}>{file.filename || file.name}</span>
        </div>
      </div>
      <div className={styles.rightTools}>
        <div className={styles.icon}>
          {!withoutDownload && (
            <>
              {fileType === PDF_TYPE && (
                <span
                  onClick={() => {
                    api.file
                      .getById(fileId, { outbound, ...params })
                      .then(res => {
                        window.open(res)
                      })
                  }}
                >
                  <Suspense fallback={null}>
                    <PrintIcon className={styles.printIcon} />
                  </Suspense>
                </span>
              )}
              <FileDownloader
                newGallery
                id={fileId}
                scope={scope}
                setWorking={setWorking}
                object={object}
                outbound={outbound}
                subject={subject}
              >
                <Icon id={working ? 'loader' : 'download'} />
              </FileDownloader>
            </>
          )}
        </div>
        <div className={styles.icon}>
          <Icon
            id={'closeBig'}
            style={{ marginLeft: '30px' }}
            onClick={() => onClose()}
          />
        </div>
      </div>
    </div>
  )
}

export default FilesGalleryHeader
