// @flow

import React from 'react'
import type { Node } from 'react'

import { getMessageText } from './Message.utils'
import MessageText from './MessageText'
import MessageChunks from './MessageChunks'

type Props = { text: string }

const Message = (props: Props): Node => {
  const { text } = props

  const formattedMessage = getMessageText(text)

  if (!formattedMessage) {
    return null
  }

  if (Array.isArray(formattedMessage)) {
    return <MessageChunks chunks={formattedMessage} />
  }

  if (typeof formattedMessage === 'string') {
    return <MessageText text={formattedMessage} />
  }

  return <>{formattedMessage}</>
}

export default Message
