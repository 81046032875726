// @flow

export const TO_SIGNEDUP_DWELLERS = 'ToSignedUpDwellers'
export const TO_NOT_SIGNEDUP_DWELLERS = 'ToNotSignedUpDwellers'
export const TO_DIGITAL_BOARD = 'ToDigitalBoard'
export const TO_SIGNEDUP_LANDLORDS = 'ToSignedUpLandlords'
export const TO_NOT_SIGNEDUP_LANDLORDS = 'ToNotSignedUpLandlords'
export const TO_ALL_FRIENDS = 'ToAllFriends'
export const TO_ALL_COLLEAGUES = 'ToAllColleagues'

export const TO_ALL_SIGNEDUP = 'ToAllSignedUp'
export const TO_ALL_NOT_SIGNEDUP = 'ToAllNotSignedUp'
export const All = 'All'

export const BUILDINGS = 'buildings'

export const TO_ALL_BUILDINGS = 'ToAllBuildings'
export const TO_BUILDING = 'ToBuilding'
export const TO_ALL_DWELLERS = 'ToAllDwellers'

export const REQUEST_BUILDING = 'RequestBuilding'
export const REQUEST_BUILDING_GROUP = 'RequestBuildingGroup'

export const POST_TYPE = 'post'
export const TICKER_TYPE = 'ticker'
export const FEEDBACK_TYPE = 'feedback'
export const SYSTEM_TYPE = 'system_post'

export const BUILDING_TYPE = 'building'
export const AUDIENCE_TYPE = 'audience'

export const ALIASES = {
  [TO_ALL_SIGNEDUP]: All,
  [TO_ALL_NOT_SIGNEDUP]: All,
}

export const BUILDINGS_SCOPE = [
  TO_SIGNEDUP_DWELLERS,
  TO_NOT_SIGNEDUP_DWELLERS,
  TO_DIGITAL_BOARD,
  TO_SIGNEDUP_LANDLORDS,
  TO_NOT_SIGNEDUP_LANDLORDS,
  TO_BUILDING,
  TO_ALL_BUILDINGS,
]

export const AUDIENCE_ICONS = {
  [TO_ALL_FRIENDS]: 'friends',
  [TO_ALL_COLLEAGUES]: 'user',
  [TO_SIGNEDUP_DWELLERS]: 'user',
  [TO_NOT_SIGNEDUP_DWELLERS]: 'user',
  [TO_SIGNEDUP_LANDLORDS]: 'user',
  [TO_NOT_SIGNEDUP_LANDLORDS]: 'user',
  [TO_DIGITAL_BOARD]: 'realData',
  [TO_ALL_DWELLERS]: 'user',
  [TO_ALL_BUILDINGS]: 'user',
  [TO_BUILDING]: 'user',
  [REQUEST_BUILDING]: 'building',
  [REQUEST_BUILDING_GROUP]: 'building',
  [BUILDING_TYPE]: 'building',
}

export const GROUPED_AUDIENCE = [
  TO_ALL_SIGNEDUP,
  TO_ALL_NOT_SIGNEDUP,
  TO_BUILDING,
]
export const SIGNUP_AUDIENCE = [TO_SIGNEDUP_DWELLERS, TO_SIGNEDUP_LANDLORDS]
export const NOT_SIGNUP_AUDIENCE = [
  TO_NOT_SIGNEDUP_DWELLERS,
  TO_NOT_SIGNEDUP_LANDLORDS,
]
export const GROUPED_AUDIENCE_DICT = {
  [TO_ALL_SIGNEDUP]: SIGNUP_AUDIENCE,
  [TO_BUILDING]: SIGNUP_AUDIENCE,
  [TO_ALL_NOT_SIGNEDUP]: NOT_SIGNUP_AUDIENCE,
}
export const AUDIENCE_LIST = [
  TO_ALL_FRIENDS,
  TO_ALL_COLLEAGUES,
  TO_DIGITAL_BOARD,
  TO_ALL_SIGNEDUP,
  TO_ALL_NOT_SIGNEDUP,
]
export const GROUPED_OPTIONS = {
  [TO_BUILDING]: [],
  [TO_ALL_SIGNEDUP]: [
    TO_ALL_SIGNEDUP,
    TO_SIGNEDUP_DWELLERS,
    TO_SIGNEDUP_LANDLORDS,
  ],
  [TO_ALL_NOT_SIGNEDUP]: [
    TO_ALL_NOT_SIGNEDUP,
    TO_NOT_SIGNEDUP_DWELLERS,
    TO_NOT_SIGNEDUP_LANDLORDS,
  ],
}

export const TICKER_MAX_TITLE_LENGTH = 100
export const TICKER_MAX_DESCRIPTION_LENGTH = 120

export const MAX_AUDIENCE_LENGTH = 5

export const FILES_ROW_LENGTH = 4

export const POST_TYPES_INT = {
  [POST_TYPE]: 1,
  [TICKER_TYPE]: 7,
}
