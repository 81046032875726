// @flow

import React from 'react'
import type Node from 'react'

import EditLabelPopup from '../modals/Labels/EditLabelPopup'
import LabelsPopUp from '../modals/Labels/LabelsPopup'

type Props = {
  infoData: Object,
  onChange: (Array<any>) => void,
  onEdit: (Node | null) => void,
}

const LabelsPopup = ({ infoData, ...props }: Props): Node => {
  const handleChange = labels => {
    props.onChange(labels)
    hideModal()
  }

  const hideModal = () => {
    props.onEdit(null)
  }

  const openEditLabelsModal = params => {
    props.onEdit(
      <EditLabelPopup
        {...params}
        onHide={hideModal}
        onCreate={newLabel => renderLabelsPopUp({ newLabel })}
        onDelete={renderLabelsPopUp}
      />
    )
  }

  const renderLabelsPopUp = params => {
    const labels = [...infoData.label_objs]

    // Check new label, weird logic here
    if (params && params.newLabel) {
      labels.push(params.newLabel)
    }

    props.onEdit(getLabelsPopUp(labels))
  }

  const getLabelsPopUp = labels => (
    <LabelsPopUp
      parentEntity={infoData}
      labels={labels}
      showEditLabelModal={openEditLabelsModal}
      onCreate={openEditLabelsModal}
      onHide={hideModal}
      onSave={handleChange}
    />
  )

  return getLabelsPopUp(infoData.label_objs)
}

export default LabelsPopup
