import React from 'react'

const SomethingWentWrong = () => (
  <div className='site_wrap error-page'>
    <main className='site_workarea'>
      <div className='error-page__message'>
        <h1>We apologize for inconveniences!</h1>
        <div className='error-page__separator'>
          <div className='error-page__separator-start' />
          <div className='error-page__separator-line' />
        </div>
        <p>The system is being updated now.<br />We will come back soon online!</p>
      </div>
    </main>
  </div>
)

export default SomethingWentWrong
