// @flow

import {
  AUDIENCE_LIST_LOADED,
  AUDIENCE_LIST_LOADING,
  AUDIENCE_LIST_ERROR,
  AUDIENCE_LIST_RESET
} from './AudienceList.actionTypes'

export const audienceListLoading = () => ({
  type: AUDIENCE_LIST_LOADING
})

export const audienceListLoaded = data => ({
  type: AUDIENCE_LIST_LOADED,
  data
})

export const audienceListError = error => ({
  type: AUDIENCE_LIST_ERROR,
  error
})

export const audienceListReset = () => ({
  type: AUDIENCE_LIST_RESET
})
