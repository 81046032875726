// @flow

import React, { memo, useContext } from 'react'
import type { Node } from 'react'

import ListItemContext from './ListItem.context'

import styles from './ListItem.module.scss'

type Props = {
  children: Node,
}

const Cell = memo<Props>(({ children }) => {
  const { labelsHeight, titleHeight } = useContext(ListItemContext)

  const boxStyle = labelsHeight ? { paddingTop: `${labelsHeight}px` } : null
  const style = titleHeight ? { height: `${titleHeight}px` } : null

  return (
    <div className={styles['cell-box']} style={boxStyle}>
      <div className={styles.cell} style={style}>
        {children}
      </div>
    </div>
  )
})

export default Cell
