// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'

import RouterTabs from '../Tabs/RouterTabs'
import Pan from '../Tabs/Pan'
import DwellerPermissions from './DwellerPermissions'
import ManagerPermissions from './ManagerPermissions'
import UnvalidatedDwellerPermissions from './UnvalidatedDwellerPermissions'

const SettingsPermissions = () => {
  const { t } = useTranslation('Settings')

  return (
    <div className='content__col' style={{ paddingTop: '20px' }}>
      <section className='settings-rights unit unit--default'>
        <RouterTabs>
          <Pan label={t('DwellerTabTitle')} linkHash='#dwellers'>
            <DwellerPermissions key='dweller-permissions' />
          </Pan>
          <Pan
            label={t('UnvalidatedDwellerTabTitle')}
            linkHash='#unvalidated-dwellers'
          >
            <UnvalidatedDwellerPermissions key='unvalidated-dweller-permissions' />
          </Pan>
          <Pan label={t('ManagerTabTitle')} linkHash='#managers'>
            <ManagerPermissions key='manager-permissions' />
          </Pan>
        </RouterTabs>
      </section>
    </div>
  )
}

export default SettingsPermissions
