// @flow

import {
  POST_DATA_LOAD,
  POST_DATA_RESET,
  POST_FILES_UPDATE,
} from './Post.actionTypes'

import type { PostAction, PostData } from './Post.types'

export function postDataLoad(data: PostData): PostAction {
  return {
    type: POST_DATA_LOAD,
    data,
  }
}

export function postDataReset(): PostAction {
  return {
    type: POST_DATA_RESET,
  }
}

export function postFilesUpdate(files: Array<string>): PostAction {
  return {
    type: POST_FILES_UPDATE,
    files,
  }
}
