// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'

const FlatAddressValue = props => {
  const { lang, name, title, max, value } = props

  const { t } = useTranslation('Flat')

  if (!value) {
    return null
  }

  return (
    <span title={value.toString().length > max ? value : null}>
      {lang !== 'ru' || name === 'number'
        ? `${t(title)} ${value}`
        : `${value} ${t(title)}`}
    </span>
  )
}

export default FlatAddressValue
