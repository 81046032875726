// @flow

import React from 'react'
import classNames from 'classnames'
import type { Node } from 'react'

import styles from './Field.module.scss'

type Props = { children: Node, withLabel?: boolean, withMargin?: boolean }

const Field = (props: Props): Node => {
  const { children, withLabel, withMargin } = props

  const filedClass = classNames(styles.field, {
    [styles['with-label']]: withLabel,
    [styles['with-margin']]: withMargin,
  })

  return <div className={filedClass}>{children}</div>
}

Field.defaultProps = {
  withMargin: true,
}

export default Field
