// @flow

import React, { Component } from 'react'

type Props = {
  autoFocus?: boolean,
  className: string,
  disabled: boolean,
  length?: number,
  maxLength?: number,
  name: string,
  onBlur?: (SyntheticEvent<HTMLInputElement>) => void,
  onChange: (string, string | number) => void,
  placeholder: string,
  type: 'text' | 'number',
  value: string | number,
}

class Regular extends Component<Props> {
  static defaultProps = {
    type: 'text',
    disabled: false,
  }

  onChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const { length, name, type } = this.props

    let value = e.currentTarget.value

    if (length && type === 'text') {
      const reg = new RegExp(`^(.{1,${length}}).*$`, 'i')

      value = value.replace(reg, '$1')
    }

    this.props.onChange(name, value)
  }

  render() {
    const {
      type,
      name,
      value,
      placeholder,
      className,
      disabled,
      onBlur,
      autoFocus,
      maxLength,
    } = this.props

    return (
      <input
        className={className}
        type={type}
        placeholder={placeholder}
        name={name}
        value={value}
        disabled={disabled}
        autoFocus={autoFocus}
        maxLength={maxLength}
        onChange={this.onChange}
        onBlur={onBlur}
      />
    )
  }
}

export default Regular
