// @flow

import React, { useState } from 'react'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import styles from './Pin.module.scss'

import Icon from '../../../../static/icons/pin.svg'
import IconFilled from '../../../../static/icons/pin-filled.svg'

type Props = {
  active: boolean,
  className?: string,
  disabled: boolean,
  onClick?: (SyntheticEvent<HTMLButtonElement>) => void,
  style?: Object,
  working: boolean,
}

const Pin = (props: Props): Node => {
  const { className, active, disabled, working, style } = props
  const [isHover, setHover] = useState(false)

  const handleMouseEnter = () => setHover(true)
  const handleMouseLeave = () => setHover(false)

  const { t } = useTranslation('PostFeed')

  const btnClass = classnames(styles.root, className, {
    [styles.active]: active,
  })

  const handleClick = e => {
    e.stopPropagation()

    if (!disabled && !working && props.onClick) {
      props.onClick(e)
    }
  }

  const buttonTitle = t(active ? 'Unpin' : 'Pin')

  const buttonProps = Object.assign(
    {},
    style ? { style } : undefined,
    disabled ? undefined : { title: buttonTitle }
  )

  return (
    <button
      type='button'
      disabled={disabled}
      className={btnClass}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...buttonProps}
    >
      {isHover || active ? (
        <IconFilled className={styles.icon} />
      ) : (
        <Icon className={styles.icon} />
      )}
    </button>
  )
}

Pin.defaultProps = {
  active: false,
  disabled: false,
  working: false,
}

export default Pin
