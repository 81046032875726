// @flow

import React from 'react'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import Icon from '../../../../../../Icon'

import styles from './AdminInfo.module.scss'

const RelatedRequests = (props): Node => {
  const { requestCount, count } = props

  const { t } = useTranslation('Request')

  const fileAttachClassName = classnames(styles['file-attach'], {
    [styles.multi]: count > 1,
  })

  return (
    <>
      <div
        className={fileAttachClassName}
        title={`${requestCount} ${t('ConnectedRequestsCount')}`}
      >
        <Icon
          id='relatedSr'
          title={`${requestCount} ${t('ConnectedRequestsCount')}`}
        />
        <span>{requestCount}</span>
      </div>
      {count === 1 && (
        <span
          style={{ marginLeft: '7px' }}
          className={styles.singleText}
        >{`${requestCount} ${t('ConnectedRequestsCount')}`}</span>
      )}
    </>
  )
}

export default RelatedRequests
