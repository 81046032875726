// @flow

import * as actions from './ResendInvitePopUp.actionTypes'

const initialState = {
  reinvite: false,
  working: false,
  saving: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.RESEND_INVITE_POP_UP_INITIATE:
      return {
        ...state,
        reinvite: true,
      }
    case actions.RESEND_INVITE_POP_UP_RESET:
    case actions.RESEND_INVITE_POP_UP_SAVED:
      return initialState
    case actions.RESEND_INVITE_POP_UP_SAVE:
      return {
        ...state,
        saving: true,
      }
    default:
      return state
  }
}
