// @flow

import React, { useEffect, useState } from 'react'
import { get } from 'lodash-es'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'
import type { Match } from 'react-router-dom'

import Button from '../Button'
import { formatDate } from '../../utils/utils'
import { EntityIcon } from '../Icon'
import { SimpleBlock, Header, CellsBar, Cell } from '../Block'
import AccountSettings from './AccountSettings'
import AuthorizedUsers from './AuthorizedUsers'
import {
  getMailIntegrationAccount,
  toggleAccountIntegrationShared,
  patchAccountSettingsMail,
  syncEmails,
  deleteAsyncTack,
} from '../../core/api/api.mail'
import Loader from '../Loader'
import FontSetting from './FontSetting'
import type { Account } from '../EmailIntegration/EmailIntegration.types'

import { redirectTo404 } from '../../utils/routing'

import styles from './EmailIntegrationNew.module.scss'
import { MARK_AS_READ_ALL } from './AccountSettings/AccountSettings.constants'
import FolderSyncSettings from './FolderSyncSettings/FolderSyncSettings'

type Props = { match: Match }

const EmailIntegrationNewEdit = (props: Props): Node => {
  const { match } = props
  const accountId = get(match, ['params', 'id'])
  const [isLoading, setLoading] = useState(true)
  const [isShared, setShared] = useState(false)
  const [account, setAccount] = useState(null)
  const [isMarked, setMarked] = useState(false)
  const [sync, setSync] = useState(false)
  const [syncError, setSyncError] = useState(null)

  const { t } = useTranslation('EmailIntegrationNylas')

  useEffect(() => {
    if (!isLoading) {
      return
    }

    getMailIntegrationAccount(accountId)
      .then((data: Account) => {
        setAccount(data)
        setShared(data.shared)
        setMarked(data.mark_read_by_all)
      })
      .catch(error => redirectTo404(error))
      .finally(() => setLoading(false))
  }, [isLoading])

  const setSignature = signature => {
    if (account) {
      setAccount({ ...account, signature })
    }
  }

  if (!account) {
    return <Loader type='big' text={false} />
  }

  const { corporate, status, created, last_sync_at, need_reconnect } = account

  const toggleShared = () =>
    toggleAccountIntegrationShared(accountId).then(data =>
      setShared(data.shared)
    )

  const toggleMarked = () =>
    patchAccountSettingsMail(accountId, { [MARK_AS_READ_ALL]: !isMarked }).then(
      data => setMarked(data[MARK_AS_READ_ALL])
    )

  const accountSharedText = isShared
    ? t('accountIsShared')
    : t('accountIsNotShared')
  const accountMarkedText = isMarked
    ? t('accountIsMarkedAsRead')
    : t('accountIsNotMarkedAsRead')
  const formattedDateActivated = created ? formatDate(created) : '-'

  const handleSyncEmails = () => {
    setSync(true)
    setSyncError(null)
    syncEmails(accountId)
      .then(data => {
        if (data.task_id) {
          getTaskStatus(data.task_id)
        }
      })
      .catch(error => {
        setSync(false)
        setSyncError(error.message?.response?.data?.errors)
      })
  }

  const getTaskStatus = task_id => {
    deleteAsyncTack(task_id).then(res => {
      if (!res.ready) {
        setTimeout(() => getTaskStatus(task_id), 500)
      } else {
        setSync(false)
      }
    })
  }

  return (
    <>
      <SimpleBlock>
        <Header header>
          <EntityIcon icon large id='email' />
          {t('EmailAccountTitle')}
        </Header>
        <CellsBar>
          <Cell title={t('Activated')}>{formattedDateActivated}</Cell>
          <Cell title={t('EmailStatus')}>{t(`status_${status}`)}</Cell>
          <Cell title={t('LastSync')}>{formatDate(last_sync_at) || '-'}</Cell>
          {!corporate ? (
            <Cell
              title={t('accountIsSharedInternally')}
              className={styles.lastCell}
            >
              <Button.Toggle
                checked={isShared}
                disabled={isLoading}
                id='shared'
                onChange={toggleShared}
              />
              <span className={styles['header-cell-right-value']}>
                {accountSharedText}
              </span>
            </Cell>
          ) : (
            <Cell title={t('EmailAccountMarkedAsRead')}>
              <Button.Toggle
                checked={isMarked}
                disabled={isLoading}
                id='marked'
                onChange={toggleMarked}
              />
              <span className={styles['header-cell-right-value']}>
                {accountMarkedText}
              </span>
            </Cell>
          )}
        </CellsBar>
        <CellsBar>
          <Cell>
            <Button.Save
              working={sync}
              disabled={need_reconnect}
              onClick={handleSyncEmails}
            >
              {t('SyncEmails')}
            </Button.Save>
            {syncError && (
              <span style={{ color: 'red', marginLeft: '20px' }}>
                {t(syncError.replace('.', ':'))}
              </span>
            )}
          </Cell>
        </CellsBar>
      </SimpleBlock>
      {corporate && <AuthorizedUsers accountId={accountId} />}
      <FontSetting accountId={accountId} />
      <AccountSettings
        accountId={accountId}
        initialParams={{
          ...account,
        }}
        setSignature={setSignature}
        setLoading={setLoading}
        needReconnect={need_reconnect}
        corporate={corporate}
      />
      {!need_reconnect && <FolderSyncSettings accountId={accountId} />}
    </>
  )
}

export default EmailIntegrationNewEdit
