// @flow

import { deleteApi, getApi, patchRaw, postRaw } from './api.core'

const basePath = 'api/text-module/post-snippet'

export const getTemplates = (params: Object): Promise<Object> =>
  getApi(`${basePath}/`, params)
export const createTemplate = (params: Object): Promise<Object> =>
  postRaw(`${basePath}/`, params)
export const patchTemplate = (id: number, params?: Object): Promise<Object> =>
  patchRaw(`${basePath}/${id}/`, params)
export const deleteTemplate = (id: number): Promise<Object> =>
  deleteApi(`${basePath}/${id}/`)
export const cloneTemplate = (
  id: number,
  params: { name: string, private: boolean }
): Promise<Object> => postRaw(`${basePath}/${id}/clone/`, params)
export const renderTemplate = (id: number, params?: Object): Promise<Object> =>
  postRaw(`${basePath}/${id}/render/`, params)
export const getAuthors = (params: Object): Promise<Object> =>
  getApi(`${basePath}/authors/`, params)
export const getVariables = (params?: { tags: string }): Promise<Object> =>
  getApi(`${basePath}/variables/`, params)
