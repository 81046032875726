// @flow

import { clone, pull, map } from 'lodash-es'
import update from 'immutability-helper'

import * as actions from './UserList.actionTypes'

const initialState = {
  initiated: true,
  working: false,
  users: [],
  all: [],
  meta: {},
  selected: [],
  permissions: {},
  error: false,
  reload: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.USER_LIST_INITIATING:
      return { ...state, working: true }
    case actions.USER_LIST_INITIATED:
      return {
        ...state,
        users: action.results.objects,
        all: action.results.objects,
        meta: action.meta,
        permissions: action.permissions,
        initiated: true,
        working: false,
        reload: false,
      }
    case actions.USER_LIST_TOGGLE: {
      const { owner } = action

      let selected = clone(state.selected)

      if (selected.includes(owner)) {
        selected = pull(selected, owner)
      } else {
        selected.push(owner)
      }

      return {
        ...state,
        selected,
      }
    }
    case actions.USER_LIST_TOGGLE_ALL: {
      let selected = []
      let allUsers = []
      const { params } = action

      if (
        params?.curr &&
        !params?.empty &&
        (!state.selected[0] ||
          state.selected.filter(u => state.users.map(s => s.id).includes(u))
            .length === state.selected.length)
      ) {
        selected = map(state.users, item => item.id)
        allUsers = [...state.users]
      } else if (
        state.selected.length < state.users.length &&
        !params?.empty &&
        !params?.curr
      ) {
        selected = map(state.users, item => item.id)
        allUsers = [...state.users]
      } else if (params?.pages && !params?.empty) {
        selected = [
          ...state.selected.filter(
            u => !params.pages.map(s => s.id).includes(u)
          ),
          ...map(params.pages, item => item.id),
        ]
        allUsers = [
          ...state.all.filter(u => !params.pages.map(s => s.id).includes(u.id)),
          ...params.pages,
        ]
      } else if (
        state.selected.length === state.users.length &&
        params?.empty == false
      ) {
        selected = map(state.users, item => item.id)
        allUsers = [...state.users]
      }

      return {
        ...state,
        selected,
        working: false,
        all: [...allUsers],
      }
    }
    case actions.USER_LIST_SELECTED: {
      let usersAll = []

      if (
        action.all.length === state.meta.count ||
        action.all.length > state.meta.count
      ) {
        usersAll = [...action.all]
      } else if (state.all.length < state.meta.count) {
        usersAll = [...state.users, ...action.all]
      }

      return {
        ...state,
        working: false,
        all: [...usersAll],
        selected: [...action.selected],
      }
    }
    case actions.USER_LIST_REMOVE:
    case actions.USER_LIST_RESTORE:
      return {
        ...state,
        working: true,
        selected: [],
      }
    case actions.USER_LIST_DELETE:
      return {
        ...state,
        working: true,
        selected: [],
      }
    case actions.USER_LIST_WAS_UPDATED:
      return state.working
        ? {
            ...state,
            working: false,
            users: action.results.objects,
            all: action.results.objects,
            permissions: action.permissions,
            meta: action.meta,
            selected: [],
          }
        : state
    case actions.USER_LIST_ERROR:
      return {
        ...state,
        working: false,
        error: action.error,
      }
    case actions.USER_LIST_BEFORE_LOAD:
    case actions.USER_LIST_APROVE_USERS:
    case actions.USER_LIST_LOAD:
    case actions.USER_LIST_LOAD_ONE:
    case actions.USER_LIST_SET_MANAGER_BUILDINGS:
    case actions.USER_LIST_SET_MANAGER_CATEGORIES:
    case actions.USER_LIST_MASS_SET_MANAGER_CATEGORIES:
    case actions.USER_LIST_UPDATE_ITEM:
    case actions.USER_LIST_UPDATE:
      return {
        ...state,
        reload: false,
        working: true,
      }
    case actions.USER_LIST_ITEM_WAS_UPDATED: {
      const index = state.all.findIndex(u => u.id === action.id)

      return update(state, {
        users: {
          [index]: { $set: { ...state.users[index], ...action.user } },
        },
        working: { $set: false },
      })
    }
    case actions.USER_LIST_CLEANUP:
      return initialState
    case actions.USER_LIST_RELOAD:
      return { ...initialState, reload: true }
    default:
      return state
  }
}
