//@flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import Modal from '../Modal'
import ModalBody from '../Modal/ModalBody'
import ModalCloseButton from '../Modal/ModalCloseButton'
import ModalHead from '../Modal/ModalHead'
import { useOverflow } from '../../hooks/useOverflow'
import Button from '../Button'
import ModalButtons from '../Modal/ModalButtons'

import Checkbox from '../Checkbox'

type Props = {
  isMass: boolean,
  isOpen: boolean,
  onClose: () => void,
  onOk: boolean => void,
}

const SentSuccessModal = (props: Props): Node => {
  const { isOpen, isMass } = props

  const [hide, setHide] = useState(false)

  const { t } = useTranslation('Mail')

  useOverflow()

  const handleChange = () => {
    setHide(!hide)
  }

  const handleOk = () => {
    props.onOk(hide)
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={props.onClose}>
      <ModalHead title={isMass ? t('SuccessMass') : t('SuccessSent')} />
      <ModalCloseButton onClose={props.onClose} />
      <ModalBody>
        <Checkbox
          checked={hide}
          text={t('DoNotShow')}
          onChange={handleChange}
        />
        <ModalButtons>
          <Button.Save onClick={handleOk}>{t('OkButton')}</Button.Save>
        </ModalButtons>
      </ModalBody>
    </Modal>
  )
}

export default SentSuccessModal
