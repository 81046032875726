// @flow

import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import NewSelectSimple from '../NewSelectSimple'
import { SimpleBlock, Header } from '../Block'
import Settings from '../Settings'
import {
  createBudgetPlanInfo,
  getBudgetPlanInfo,
  getPaymentTypes,
  getInvoicesSystemTypes,
} from '../../core/api/api.budgetPlan'
import { getChangedValues, getFetchedItems } from '../../utils/utils'
import {
  DEBITOR,
  DEBITOR_BIC,
  DEBITOR_IBAN,
  DEBITOR_MAX_LENGTH,
  DEBITOR_IBAN_MAX_LENGTH,
  INVOICES_SYSTEM_TYPE,
  PAYMENT_TYPE_AFTER_EXPORT,
  PAYMENT_TYPE_AFTER_EXPORT_ID,
  PAYMENT_TYPE_FOR_EXPORT,
  PAYMENT_TYPE_FOR_EXPORT_ID,
} from './BudgetPlanSettings.constants'
import InputField from '../InputField'
import { omit } from 'lodash-es'

const BudgetPlanSettings = (): Node => {
  const { t } = useTranslation('BudgetPlan')

  const [isLoading, setLoading] = useState(true)
  const [budgetPlan, setBudgetPlan] = useState(null)
  const [paymentTypes, setPaymentTypes] = useState(null)
  const [invoiceSystemTypes, setInvoiceSystemTypes] = useState(null)

  useEffect(() => {
    if (isLoading) {
      Promise.all([
        getBudgetPlanInfo(),
        getPaymentTypes(),
        getInvoicesSystemTypes(),
      ])
        .then(([plan, types, systemTypes]) => {
          setBudgetPlan(plan)
          setPaymentTypes(getFetchedItems(types))
          setInvoiceSystemTypes(systemTypes.map(t => ({ label: t, value: t })))
        })
        .finally(() => setLoading(false))
    }
  }, [isLoading])

  const formik = useFormik({
    validateOnChange: true,
    enableReinitialize: true,
    initialValues: {
      [PAYMENT_TYPE_FOR_EXPORT]:
        budgetPlan && budgetPlan[PAYMENT_TYPE_FOR_EXPORT]
          ? {
              value: budgetPlan[PAYMENT_TYPE_FOR_EXPORT].id,
              label: budgetPlan[PAYMENT_TYPE_FOR_EXPORT].value,
            }
          : '',
      [PAYMENT_TYPE_AFTER_EXPORT]:
        budgetPlan && budgetPlan[PAYMENT_TYPE_AFTER_EXPORT]
          ? {
              value: budgetPlan[PAYMENT_TYPE_AFTER_EXPORT].id,
              label: budgetPlan[PAYMENT_TYPE_AFTER_EXPORT].value,
            }
          : '',
      [INVOICES_SYSTEM_TYPE]:
        budgetPlan && budgetPlan[INVOICES_SYSTEM_TYPE]
          ? {
              value: budgetPlan[INVOICES_SYSTEM_TYPE],
              label: budgetPlan[INVOICES_SYSTEM_TYPE],
            }
          : '',
      [DEBITOR]: (budgetPlan && budgetPlan[DEBITOR]) || '',
      [DEBITOR_BIC]: (budgetPlan && budgetPlan[DEBITOR_BIC]) || '',
      [DEBITOR_IBAN]: (budgetPlan && budgetPlan[DEBITOR_IBAN]) || '',
    },
    validationSchema: Yup.object({
      [PAYMENT_TYPE_FOR_EXPORT]: Yup.object().shape({
        value: Yup.number(),
        label: Yup.string(),
      }),
      [PAYMENT_TYPE_AFTER_EXPORT]: Yup.object().shape({
        value: Yup.number(),
        label: Yup.string(),
      }),
      [DEBITOR]: Yup.string().max(
        DEBITOR_MAX_LENGTH,
        t('DebitorMaxLength', { maxLength: DEBITOR_MAX_LENGTH })
      ),
      [DEBITOR_BIC]: Yup.string().matches(
        /^.{8}(.{3})?$/,
        t('BicAllowedLength')
      ),
      [DEBITOR_IBAN]: Yup.string().length(
        DEBITOR_IBAN_MAX_LENGTH,
        t('IbanAllowedLength')
      ),
    }),
    onSubmit: submitValues => {
      const changedValues = getChangedValues(formik.initialValues, submitValues)

      const normalizedValues = Object.assign(
        {},
        {
          ...omit(changedValues, [
            PAYMENT_TYPE_FOR_EXPORT,
            PAYMENT_TYPE_AFTER_EXPORT,
            INVOICES_SYSTEM_TYPE,
          ]),
        },
        changedValues[PAYMENT_TYPE_FOR_EXPORT]
          ? {
              [PAYMENT_TYPE_FOR_EXPORT_ID]:
                changedValues[PAYMENT_TYPE_FOR_EXPORT].value,
            }
          : undefined,
        changedValues[PAYMENT_TYPE_AFTER_EXPORT]
          ? {
              [PAYMENT_TYPE_AFTER_EXPORT_ID]:
                changedValues[PAYMENT_TYPE_AFTER_EXPORT].value,
            }
          : undefined,
        changedValues[INVOICES_SYSTEM_TYPE]
          ? {
              [INVOICES_SYSTEM_TYPE]: changedValues[INVOICES_SYSTEM_TYPE].value,
            }
          : undefined
      )

      createBudgetPlanInfo(normalizedValues).then(data => setBudgetPlan(data))
    },
  })

  const paymentTypeOptions = paymentTypes
    ? paymentTypes.map(paymentType => ({
        value: paymentType.id,
        label: paymentType.value,
      }))
    : []

  const handleChangePaymentTypeFor = option =>
    formik.setFieldValue(PAYMENT_TYPE_FOR_EXPORT, option)

  const handleChangePaymentTypeAfter = option =>
    formik.setFieldValue(PAYMENT_TYPE_AFTER_EXPORT, option)

  const handleChangeInvoiceSystemType = option =>
    formik.setFieldValue(INVOICES_SYSTEM_TYPE, option)

  return (
    <SimpleBlock working={isLoading}>
      <Header header>{t('BudgetPlanExportTitle')}</Header>
      <form onSubmit={formik.handleSubmit}>
        <Settings.Row label={t('InvoiceSystemType')}>
          <div className='input input--large input--default input--block'>
            <NewSelectSimple
              disabled={isLoading}
              loading={isLoading}
              value={formik.values[INVOICES_SYSTEM_TYPE]}
              options={invoiceSystemTypes}
              placeholder={t('InvoiceSystemTypePlaceholder')}
              onChange={handleChangeInvoiceSystemType}
            />
          </div>
        </Settings.Row>
        <Settings.Row label={t('PaymentTypeForExportLabel')}>
          <div className='input input--large input--default input--block'>
            <NewSelectSimple
              disabled={isLoading}
              loading={isLoading}
              value={formik.values[PAYMENT_TYPE_FOR_EXPORT]}
              options={paymentTypeOptions}
              placeholder={t('PaymentTypeForPlaceholder')}
              onChange={handleChangePaymentTypeFor}
            />
          </div>
        </Settings.Row>
        <Settings.Row label={t('PaymentTypeAfterExportLabel')}>
          <div className='input input--large input--default input--block'>
            <NewSelectSimple
              disabled={isLoading}
              loading={isLoading}
              value={formik.values[PAYMENT_TYPE_AFTER_EXPORT]}
              options={paymentTypeOptions}
              placeholder={t('PaymentTypeAfterPlaceholder')}
              onChange={handleChangePaymentTypeAfter}
            />
          </div>
        </Settings.Row>
        <Settings.Row label={t('DebitorLabel')}>
          <div className='input input--large input--default input--block'>
            <InputField
              value={formik.values[DEBITOR]}
              error={formik.errors[DEBITOR]}
              name={DEBITOR}
              placeholder={t('DebitorPlaceholder')}
              onChange={formik.handleChange}
            />
          </div>
        </Settings.Row>
        <Settings.Row label={t('DebitorBicLabel')}>
          <div className='input input--large input--default input--block'>
            <InputField
              value={formik.values[DEBITOR_BIC]}
              error={formik.errors[DEBITOR_BIC]}
              name={DEBITOR_BIC}
              placeholder={t('DebitorBicPlaceholder')}
              onChange={formik.handleChange}
            />
          </div>
        </Settings.Row>
        <Settings.Row label={t('DebitorIbanLabel')}>
          <div className='input input--large input--default input--block'>
            <InputField
              value={formik.values[DEBITOR_IBAN]}
              error={formik.errors[DEBITOR_IBAN]}
              name={DEBITOR_IBAN}
              placeholder={t('DebitorIbanPlaceholder')}
              onChange={formik.handleChange}
            />
          </div>
        </Settings.Row>
        <Settings.Actions
          withChanges={formik.dirty}
          isValid={formik.isValid}
          working={isLoading}
          onCancel={formik.handleReset}
        />
      </form>
    </SimpleBlock>
  )
}

export default BudgetPlanSettings
