// @flow

export const WIDGET_FILES_LOAD = 'WIDGET_FILES_LOAD'
export const WIDGET_FILES_WERE_LOADED = 'WIDGET_FILES_WERE_LOADED'
export const WIDGET_FILES_ERROR = 'WIDGET_FILES_ERROR'
export const WIDGET_FILES_RESET = 'WIDGET_FILES_RESET'
export const WIDGET_FILES_WERE_DELETED = 'WIDGET_FILES_WERE_DELETED'
export const WIDGET_FILES_INIT = 'WIDGET_FILES_INIT'

export const widgetFilesWereLoaded = files => ({
  type: WIDGET_FILES_WERE_LOADED,
  files,
})

export const widgetFilesWereDeleted = files => ({
  type: WIDGET_FILES_WERE_DELETED,
  files,
})

export const widgetFilesReset = () => ({ type: WIDGET_FILES_RESET })

export const widgetFilesInit = ({ thread, chatroom, email }) => ({
  type: WIDGET_FILES_INIT,
  thread,
  chatroom,
  email,
})
