/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type OfferProfile_offer$ref: FragmentReference;
declare export opaque type OfferProfile_offer$fragmentType: OfferProfile_offer$ref;
export type OfferProfile_offer = {|
  +id: string,
  +name: string,
  +description: ?string,
  +finalPrice: ?string,
  +priceType: ?{|
    +name: ?string
  |},
  +gallery: ?$ReadOnlyArray<?{|
    +id: ?string,
    +origin: ?string,
    +default: ?boolean,
    +alt: ?string,
    +thumbnails: ?$ReadOnlyArray<?{|
      +size: ?$ReadOnlyArray<?number>,
      +url: ?string,
    |}>,
  |}>,
  +provider: {|
    +id: string,
    +name: string,
    +contacts: ?$ReadOnlyArray<?{|
      +type: ?string,
      +value: ?string,
      +verboseName: ?string,
    |}>,
    +image: ?{|
      +origin: ?string,
      +alt: ?string,
      +thumbnails: ?$ReadOnlyArray<?{|
        +size: ?$ReadOnlyArray<?number>,
        +url: ?string,
      |}>,
    |},
  |},
  +appliedAttributes: ?any,
  +enabled: boolean,
  +$refType: OfferProfile_offer$ref,
|};
export type OfferProfile_offer$data = OfferProfile_offer;
export type OfferProfile_offer$key = {
  +$data?: OfferProfile_offer$data,
  +$fragmentRefs: OfferProfile_offer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "origin",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "alt",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "thumbnails",
  "storageKey": null,
  "args": null,
  "concreteType": "Thumbnail",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "size",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "url",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Fragment",
  "name": "OfferProfile_offer",
  "type": "OfferNode",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "offerID",
      "type": "ID",
      "defaultValue": 0
    }
  ],
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "description",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "finalPrice",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "priceType",
      "storageKey": null,
      "args": null,
      "concreteType": "PriceTypeNode",
      "plural": false,
      "selections": [
        (v1/*: any*/)
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "gallery",
      "storageKey": null,
      "args": null,
      "concreteType": "GalleryImageNode",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "default",
          "args": null,
          "storageKey": null
        },
        (v3/*: any*/),
        (v4/*: any*/)
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "provider",
      "storageKey": null,
      "args": null,
      "concreteType": "ProviderNode",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "contacts",
          "storageKey": null,
          "args": null,
          "concreteType": "ContactObjectType",
          "plural": true,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "type",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "value",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "verboseName",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "image",
          "storageKey": null,
          "args": null,
          "concreteType": "ProviderImageNode",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/)
          ]
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "appliedAttributes",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "enabled",
      "args": null,
      "storageKey": null
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '407292dd4bf74841b32de8ead6af140f';
module.exports = node;
