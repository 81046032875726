// @flow

import React, { useEffect } from 'react'
import type { Node } from 'react'

import { useTranslation } from 'react-i18next'
import { deleteSalutation } from '../../../../core/api/api.pipeline'

import Button from '../../../Button'
import Modal from '../../../Modal'
import ModalBody from '../../../Modal/ModalBody'
import ModalButtons from '../../../Modal/ModalButtons'
import ModalHead from '../../../Modal/ModalHead'

import styles from '../SalutationModal.module.scss'

type Props = {
  editingSalutation: Object,
  load: () => void,
  onClose: () => void,
  setEditingSalutation: (Salutation: Object) => void,
}

const SalutationModalDelete = ({
  editingSalutation: { id },
  setEditingSalutation,
  onClose,
  load,
}: Props): Node => {
  const { t } = useTranslation('Salutation')

  useEffect(() => {
    return function cleanup() {
      setEditingSalutation(null)
    }
  }, [])

  const handleDelete = () => {
    deleteSalutation(id)
    load()
    onClose()
  }

  return (
    <Modal isOpen className={styles.modal} onRequestClose={onClose}>
      <ModalHead title={t('DeleteSalutation')} />
      <ModalBody>Do you really want to delete salutation?</ModalBody>
      <ModalButtons>
        <Button.Save onClick={handleDelete}>{t('Common:Delete')}</Button.Save>
        <Button.Cancel onClick={onClose}>{t('Common:Cancel')}</Button.Cancel>
      </ModalButtons>
    </Modal>
  )
}

export default SalutationModalDelete
