// @flow

import React, { useState, useEffect, useRef } from 'react'
import { withTranslation } from 'react-i18next'
import type { Node } from 'react'

import api from '../core/api'
import Button from './Button'

import { getFileScopeParams } from '../utils/file'

const INTERVAL = 350

type Props = {
  id: string,
  label?: string,
  outbound?: string,
  t: string => string,
}

const FileDownloader = ({
  id,
  label,
  newGallery,
  outbound,
  ...props
}: Props): Node => {
  const [working, toggleWorking] = useState(false)
  const timerRef = useRef()

  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current)
    }
  }, [])

  useEffect(() => {
    props.setWorking && props.setWorking(working)
  }, [working])

  const getFile = () => {
    if (working) {
      return
    }

    const params = getFileScopeParams(props)
    toggleWorking(true)
    api.file
      .getById(id, { outbound, ...params })
      .then(res => {
        if (res.checking) {
          timerRef.current = setTimeout(getFile, INTERVAL)
        } else {
          toggleWorking(false)
          window.open(res)
        }
      })
      .catch(() => {
        toggleWorking(false)
      })
  }

  if (newGallery) {
    return <div onClick={getFile}>{props.children}</div>
  }

  return (
    <Button.Save style={{ margin: '15px' }} working={working} onClick={getFile}>
      {working ? props.t('ScanningViruses') : label || props.t('Download')}
    </Button.Save>
  )
}

export default withTranslation('FileDownloader')(FileDownloader)
