// @flow

import React from 'react'
import type Node from 'react'

type Props = {
  children: Node,
  label: string,
}

const Option = ({ label, children }: Props): Node => (
  <div className='settings-personal__form'>
    <div className='settings-personal__form-row language__form-row'>
      <div className='settings-personal__form-cell'>
        <label className='settings-personal__form-label'>{label}</label>
      </div>
      <div className='settings-personal__form-cell'>
        <div className='input input--large input--default input--block'>
          {children}
        </div>
      </div>
    </div>
  </div>
)

export default Option
