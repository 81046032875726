// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import NewSelectSimple from '../../../NewSelectSimple'
import {
  getGroupedOptions,
  getSelectValue,
  isGroupedAudience,
} from '../../../Post/Post.utils'
import Checkbox from '../../../Checkbox'
import ModalRow from '../../../Modal/ModalRow'

import styles from './AudienceListItem.module.scss'

type Props = {
  cb?: boolean => void,
  checked: boolean,
  item: string,
  scope: Array<string>,
  toggleOption: (string, boolean) => void,
}

const AudienceListItem = (props: Props): Node => {
  const { item, checked, scope } = props

  const { t } = useTranslation('Post')

  const isGrouped =
    isGroupedAudience(item) && !!getGroupedOptions(item, t).length

  const handleChange = () => {
    props.toggleOption(item, !checked)

    if (props.cb) {
      props.cb(!checked)
    }
  }

  const selectHandleChange = option => {
    props.toggleOption(option.value, checked)
  }

  return (
    <>
      <ModalRow>
        <Checkbox checked={checked} text={t(item)} onChange={handleChange} />
      </ModalRow>
      {isGrouped && (
        <ModalRow className={styles.select}>
          <NewSelectSimple
            options={getGroupedOptions(item, t)}
            value={getSelectValue(item, scope, t)}
            disabled={!checked}
            onChange={selectHandleChange}
          />
        </ModalRow>
      )}
    </>
  )
}

export default AudienceListItem
