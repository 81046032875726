// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import NewTable from '../../NewTable'
import NewTableBody from '../../NewTable/NewTableBody'
import RoomTypeTableRow from './RoomTypeTableRow'

type Props = { onEdit: Function }

const RoomTypeTable = (props: Props): Node => {
  const { t } = useTranslation('Flat')

  const options = [
    {
      value: 'editRoomType',
      label: t('EditRoomType'),
      onClick: props.onEdit,
      icon: 'pencil',
    },
  ]

  return (
    <NewTable>
      <NewTableBody>
        <RoomTypeTableRow options={options} onEdit={props.onEdit} />
      </NewTableBody>
    </NewTable>
  )
}

export default RoomTypeTable
