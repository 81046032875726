// @flow

import { LOAD_ERROR, LOAD_INIT, LOAD_SUCCESS } from './useLoad.constants'

export default function dataLoadReducer(state, action) {
  switch (action.type) {
    case LOAD_INIT:
      return { ...state, isLoading: true, isError: false }
    case LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload,
      }
    case LOAD_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      }
    default:
      throw new Error()
  }
}
