// @flow

import React from 'react'
import type { Node } from 'react'
import classnames from 'classnames'

import Button from '../Button'
import { EmptyListIcon } from '../Icon'
import type { EmptyListIconId } from '../Icon'

import styles from './EmptyList.module.scss'

type Props = {
  additionalButton?: Node,
  btnIcon?: string,
  btnText?: string,
  canAdd?: boolean,
  children?: Node,
  className?: string,
  embedded?: boolean,
  icon: EmptyListIconId,
  noShowAddIcon: boolean,
  onClick?: () => void,
  title: string,
}

const EmptyList = (props: Props) => {
  const {
    children,
    embedded,
    icon,
    title,
    additionalButton,
    btnText,
    canAdd,
    btnIcon,
    noShowAddIcon,
    className,
  } = props

  const hasBtn = Boolean(props.onClick) && Boolean(btnText) && canAdd
  const showAddSymbol = (hasBtn || Boolean(children)) && !noShowAddIcon
  const boxClass = classnames(styles.container, className, {
    [styles.embedded]: embedded,
    [styles.interactive]: showAddSymbol,
  })

  return (
    <div className={boxClass}>
      <EmptyListIcon canAdd={showAddSymbol} id={icon} />
      <h2 className={styles.title}>{title}</h2>
      {Boolean(children) && children}
      {hasBtn && (
        <Button.Save icon={btnIcon} onClick={props.onClick}>
          {btnText}
        </Button.Save>
      )}
      {additionalButton}
    </div>
  )
}

EmptyList.defaultProps = {
  noShowAddIcon: false,
}

export default EmptyList
