// @flow

import type { Node } from 'react'
import React from 'react'
import setFormikError from '../../../utils/setFormikError'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

import FieldRow from '../../FieldRow'

import styles from './ProfileInformation.module.scss'

import Button from '../../Button'
import {
  SALUTATION,
  FULL_NAME,
  MIDDLE_NAME,
  NAME_INFO,
  MAX_NAME_LENGTH,
  MAX_FULL_NAME_LENGTH,
  GDPR_INFO,
  STATUS,
  PREFERRED_COMMUNICATION,
  TITLE,
} from './ProfileInformationPersonal.constants'
import * as Yup from 'yup'

import NewSelectAsync from '../../NewSelectAsync'

import { getSalutation } from '../../../core/api/api.pipeline'
import { getInfoStatus } from '../../../core/api/api.status'
import { getPreferredCommunication } from '../../../core/api/api.preferredCommunication'

import type { IPersonal } from '../ProfileData'

type Props = {
  active: boolean,
  handleEditClose: () => void,
  initialValues: IPersonal,
  isOpen: boolean,
  updateProfileInfo: (data: { personal: IPersonal }) => Promise<IPersonal>,
}

const PersonalForm = ({
  isOpen,
  initialValues,
  active,
  handleEditClose,
  updateProfileInfo,
}: Props): Node => {
  const { t } = useTranslation('Profile')

  const onSubmit = values => {
    const personal: IPersonal = {
      ...values,
      status: values.status?.id || null,
      salutation: values.salutation?.id || null,
      preferred_communication: values.preferred_communication?.id || null,
    }
    updateProfileInfo({ personal })
      .then(() => handleEditClose())
      .catch(error => setFormikError(error, setFieldError))
  }

  const onClosed = () => {
    handleEditClose()
    resetForm()
  }

  const {
    values,
    errors,
    dirty,
    setFieldError,
    isSubmitting,
    handleSubmit,
    handleChange,
    setFieldValue,
    resetForm,
  } = useFormik({
    validateOnChange: false,
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      [PREFERRED_COMMUNICATION]: Yup.object({
        id: Yup.number().nullable(),
        value: Yup.string().nullable(),
      }).nullable(),
      [GDPR_INFO]: Yup.string().nullable(),
      [SALUTATION]: Yup.object({
        id: Yup.number().nullable(),
        salutation: Yup.string().nullable(),
      }).nullable(),
      [TITLE]: Yup.string().nullable(),
      [STATUS]: Yup.object({
        id: Yup.number().nullable(),
        value: Yup.string().nullable(),
      }).nullable(),
      [NAME_INFO]: Yup.string().max(MAX_NAME_LENGTH, t('MaxInputLength')),
      [MIDDLE_NAME]: Yup.string().max(MAX_NAME_LENGTH, t('MaxInputLength')),
      [FULL_NAME]: Yup.string().max(MAX_FULL_NAME_LENGTH, t('MaxInputLength')),
    }),
    onSubmit,
  })

  const handleSelectSalutation = option => {
    setFieldValue(SALUTATION, option)
  }

  const handleSalutationClear = () => {
    setFieldValue(SALUTATION, null)
  }

  const handleSelectStatus = option => {
    setFieldValue(STATUS, option)
  }

  const handleStatusClear = () => {
    setFieldValue(STATUS, null)
  }

  const handleSelectPreferredCommunication = option => {
    setFieldValue(PREFERRED_COMMUNICATION, option)
  }

  const handlePreferredCommunicationClear = () => {
    setFieldValue(PREFERRED_COMMUNICATION, null)
  }

  return (
    <form name='profile_information' onSubmit={handleSubmit}>
      <FieldRow
        value={values[SALUTATION]?.salutation}
        label={'Salutation'}
        error={errors[SALUTATION]}
        active={active}
        type='select'
        handleClear={handleSalutationClear}
      >
        <NewSelectAsync
          pageSize={10}
          permanentParams={{ object_type: 'user' }}
          name={SALUTATION}
          api={getSalutation}
          searchKey='search'
          placeholder={t('SelectSalutation')}
          view='dropdown'
          getLabel={a => a?.salutation}
          getSelectedLabel={a => a?.salutation}
          selectedItems={
            values[SALUTATION]?.salutation ? [values[SALUTATION]] : []
          }
          className={styles.select}
          onClick={handleSelectSalutation}
        />
      </FieldRow>
      {(values[TITLE] || isOpen) && (
        <FieldRow
          name={TITLE}
          active={active}
          label={'Title'}
          error={errors[TITLE]}
          value={values[TITLE]}
          placeholder={t('EnterTitle')}
          handleChange={handleChange}
          maxLength={MAX_NAME_LENGTH}
        />
      )}
      {(values[MIDDLE_NAME] || isOpen) && (
        <FieldRow
          name={MIDDLE_NAME}
          active={active}
          label={'MiddleName'}
          error={errors[MIDDLE_NAME]}
          value={values[MIDDLE_NAME]}
          placeholder={t('EnterMiddleName')}
          handleChange={handleChange}
          maxLength={MAX_NAME_LENGTH}
        />
      )}
      {(values[FULL_NAME] || isOpen) && (
        <FieldRow
          name={FULL_NAME}
          active={active}
          label={'FullName'}
          error={errors[FULL_NAME]}
          value={values[FULL_NAME]}
          placeholder={t('EnterFullName')}
          handleChange={handleChange}
          maxLength={MAX_FULL_NAME_LENGTH}
        />
      )}
      {(values[NAME_INFO] || isOpen) && (
        <FieldRow
          name={NAME_INFO}
          active={active}
          label={'NameInfo'}
          error={errors[NAME_INFO]}
          value={values[NAME_INFO]}
          placeholder={t('EnterNameInfo')}
          handleChange={handleChange}
          maxLength={MAX_NAME_LENGTH}
        />
      )}
      {(values[STATUS] || isOpen) && (
        <FieldRow
          value={values[STATUS]?.value}
          label={'Status'}
          error={errors[STATUS]}
          active={active}
          type='select'
          handleClear={handleStatusClear}
        >
          <NewSelectAsync
            pageSize={10}
            name={STATUS}
            api={getInfoStatus}
            searchKey='search'
            placeholder={t('SelectStatus')}
            view='dropdown'
            getLabel={a => a?.value}
            getSelectedLabel={a => a?.value}
            selectedItems={values[STATUS]?.value ? [values[STATUS]] : []}
            className={styles.select}
            onClick={handleSelectStatus}
          />
        </FieldRow>
      )}
      {(values[GDPR_INFO] || isOpen) && (
        <FieldRow
          name={GDPR_INFO}
          active={active}
          label={'GDPRInfo'}
          error={errors[GDPR_INFO]}
          value={values[GDPR_INFO]}
          placeholder={t('EnterGDPRInfo')}
          handleChange={handleChange}
        />
      )}
      {(values[PREFERRED_COMMUNICATION] || isOpen) && (
        <FieldRow
          value={values[PREFERRED_COMMUNICATION]?.value}
          label={'PreferredCommunication'}
          error={errors[PREFERRED_COMMUNICATION]}
          active={active}
          type='select'
          handleClear={handlePreferredCommunicationClear}
        >
          <NewSelectAsync
            pageSize={10}
            name={PREFERRED_COMMUNICATION}
            api={getPreferredCommunication}
            searchKey='search'
            placeholder={t('SelectPreferredCommunication')}
            view='dropdown'
            getLabel={a => a?.value}
            getSelectedLabel={a => a?.value}
            selectedItems={
              values[PREFERRED_COMMUNICATION]
                ? [values[PREFERRED_COMMUNICATION]]
                : []
            }
            className={styles.select}
            onClick={handleSelectPreferredCommunication}
          />
        </FieldRow>
      )}
      {active && (
        <div className={styles.buttonWrapper}>
          <Button.Save disabled={!dirty || isSubmitting} type='submit'>
            {t('Common:Save')}
          </Button.Save>
          <Button.Cancel onClick={onClosed}>{t('Common:Cancel')}</Button.Cancel>
        </div>
      )}
    </form>
  )
}

export default PersonalForm
