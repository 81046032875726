// @flow

import styles from './EmailIntegrationModal.module.scss'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ModalButtons from '../../Modal/ModalButtons'
import Button from '../../Button'
import ModalRow from '../../Modal/ModalRow'
import ModalBody from '../../Modal/ModalBody'
import type { Node } from 'react'
import { SELECT_PROVIDER_STAGE } from './EmailIntegrationModal.constant'
import ModalHead from '../../Modal/ModalHead'
import Icon from '../../Icon'

type Provider = {
  icon: string,
  logIntTranslationKey: string,
  stage: string,
  translationKey: string,
}

type Props = {|
  onClose: () => void,
  provider: Provider,
  // setErrorRequest: () => void,
  setStage: () => void,
|}

const OfficeModal = (props: Props): Node => {
  const { t } = useTranslation('EmailIntegrationNylas')
  const { onClose, setStage, provider } = props
  const [loading, setLoading] = useState(false)

  const handleSubmit = () => {
    setLoading(true)
    setLoading(false)
  }

  return (
    <>
      <ModalHead title={t('AddEmailAccount')} />
      <ModalBody>
        <ModalRow>
          <Icon id={provider.icon} className={styles.icon} />
          <span className={styles.microsoftTitle}>
            {t(provider.logIntTranslationKey)}
          </span>
        </ModalRow>
      </ModalBody>
      <ModalButtons>
        <Button.Save disabled={loading} onClick={() => handleSubmit()}>
          {t('Continue')}
        </Button.Save>
        <Button.Cancel onClick={onClose}>{t('Common:Cancel')}</Button.Cancel>
        <Button.Regular
          disabled={loading}
          view='desaturated'
          className={styles.anotherBtn}
          onClick={() => setStage(SELECT_PROVIDER_STAGE)}
        >
          {t('ChooseAnother')}
        </Button.Regular>
      </ModalButtons>
    </>
  )
}

export default OfficeModal
