// @flow

import React from 'react'
import type { Node } from 'react'

import styles from '../NewTable.module.scss'

type Props = { title: string }

const Text = (props: Props): Node => {
  const { title } = props

  return (
    <span className={styles.headerText} title={title}>
      {title}
    </span>
  )
}

export default Text
