// @flow

import {
  omitBy,
  isEqual,
  flow,
  get,
  isNil,
  partialRight,
  pick,
  defaults,
} from 'lodash-es'

import type { Flat, State } from './FlatAddress'
import { NOT_CONFIRMED_STATUS, USER_STATUS } from '../../../constants'

type Props = {
  flat: Flat,
}

export const isNotConfirmedUser = (status: ?string): boolean =>
  USER_STATUS[status] === NOT_CONFIRMED_STATUS

export const getInitialValues = (props: Props): Flat =>
  flow([
    partialRight(get, 'flat'),
    partialRight(pick, ['entrance', 'floor', 'number']),
    partialRight(omitBy, isNil),
    partialRight(defaults, { entrance: '', floor: '', number: '' }),
  ])(props)

export const isChanged = (state: State, props: Props) =>
  !flow([
    partialRight(pick, ['entrance', 'floor', 'number']),
    partialRight(isEqual, getInitialValues(props)),
  ])(state)

export const getPreparedValues = (state: State): Flat =>
  pick(state, ['entrance', 'floor', 'number'])

export const canEditFlat = (flat: Flat): boolean =>
  !!get(flat, ['permissions', 'can_edit'])
