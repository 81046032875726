// @flow

import React from 'react'
import { Document } from 'react-pdf'

import Loader from '../Loader'

import styles from './MappedDocument.module.scss'

type Props = {
  children: React$Element<any>,
  fileOrigin: string,
  onLoadProgress: Object => void,
  onLoadSuccess: Object => void,
  width: number,
}

const DocumentContainer = (props: Props) => {
  const { fileOrigin, width, children } = props

  return (
    <Document
      file={fileOrigin}
      className={styles.document}
      loading={
        <div style={{ width: `${width}px` }}>
          <Loader text={false} type='big' />
        </div>
      }
      onLoadProgress={props.onLoadProgress}
      onLoadSuccess={props.onLoadSuccess}
    >
      {children}
    </Document>
  )
}

export default DocumentContainer
