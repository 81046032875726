// @flow

import { put, call, fork, takeEvery, all } from 'redux-saga/effects'

import { serverError, modalError } from '../../Layout/Layout.actions'
import api from '../../../core/api'
import * as actions from './ConverToRequestPopup.actionTypes'

function* watchCreate() {
  yield takeEvery(actions.REQUEST_CREATE, create)
}

function* create(action) {
  try {
    const { params } = action
    const request = yield call(api.request.createRequest, params)
    yield put({ type: actions.REQUEST_CREATED, request })
    window.open(`/request/${request.id}`)
  } catch (error) {
    yield put({ type: actions.ERROR, error })

    if (error.message.response.status === 400) {
      yield put(modalError(error.message.response.data))

      return
    }

    yield put(serverError(error))
  }
}

export default function* () {
  yield all([fork(watchCreate)])
}
