// @flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import { useOverflow } from '../../hooks'

import Button from '../Button'
import Modal from '../Modal'
import ModalBody from '../Modal/ModalBody'
import ModalButtons from '../Modal/ModalButtons'
import ModalCloseButton from '../Modal/ModalCloseButton'
import ModalHead from '../Modal/ModalHead'
import ModalRow from '../Modal/ModalRow'
import ModalCell from '../Modal/ModalCell'
import ModalLabel from '../Modal/ModalLabel'
import InputField from '../InputField'

type Props = {
  name: string,
  onClose: Function,
  updateName: Function,
}

const GroupNameModal = (props: Props): Node => {
  const { name } = props

  const { t } = useTranslation('Building')
  const [groupName, setGroupName] = useState(name)

  useOverflow()

  return (
    <Modal isOpen>
      <ModalCloseButton onClose={props.onClose} />
      <ModalHead title={t('GroupNameEditTitle')} />
      <ModalBody>
        <ModalRow>
          <ModalCell>
            <ModalLabel>{t('GroupName')}</ModalLabel>
          </ModalCell>
          <ModalCell gridNumber={2}>
            <InputField
              autoFocus
              name='groupName'
              value={groupName}
              maxLength={256}
              placeholder={t('GroupNamePlaceholder')}
              onChange={e => setGroupName(e.target.value)}
            />
          </ModalCell>
        </ModalRow>
      </ModalBody>
      <ModalButtons>
        <Button.Save
          disabled={!groupName.length}
          onClick={() => props.updateName(groupName)}
        >
          {t('Common:Save')}
        </Button.Save>
        <Button.Cancel onClick={props.onClose}>
          {t('Common:Cancel')}
        </Button.Cancel>
      </ModalButtons>
    </Modal>
  )
}

export default GroupNameModal
