//@flow

import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import Modal from '../../../Modal'
import ModalBody from '../../../Modal/ModalBody'
import ModalRow from '../../../Modal/ModalRow'
import ModalLabel from '../../../Modal/ModalLabel'
import ModalCloseButton from '../../../Modal/ModalCloseButton'
import ModalButtons from '../../../Modal/ModalButtons'
import ModalHead from '../../../Modal/ModalHead'
import { useOverflow } from '../../../../hooks/useOverflow'
import NewSelectSimple from '../../../NewSelectSimple'
import NewSelectAsync from '../../../NewSelectAsync'
import Button from '../../../Button'
import api from '../../../../core/api'
import {
  DWELLER_ROLE_VALUE,
  LANDLORD_ROLE_VALUE,
  STAFF_VALUE,
  TENANT_VALUE,
} from './FilterModal.constants'
import {
  filterUsers,
  getBuildingLabel,
  getFlatLabel,
  getParams,
} from './FilterModal.utils'
import { getFetchedItems } from '../../../../utils/utils'
import { getBuilding } from '../../../../core/api/api.building'
import { getFlat } from '../../../../core/api/api.flat'

import styles from './FilterModal.module.scss'

function FilterModal(props): Node {
  const { isOpen, requestBuildingId, requestFlatId, values, modalEntity } =
    props

  const { t } = useTranslation('Mail')

  const entityOptions = [
    { value: TENANT_VALUE, label: t('TenantFilter') },
    { value: STAFF_VALUE, label: t('StaffFilter') },
  ]

  const roleOptions = [
    { value: DWELLER_ROLE_VALUE, label: t('DwellersOnly') },
    { value: LANDLORD_ROLE_VALUE, label: t('LandlordsOnly') },
  ]

  const [entityOption, setEntityOption] = useState(entityOptions[0])
  const [role, setRole] = useState(null)
  const [building, setBuilding] = useState(null)
  const [flat, setFlat] = useState(null)
  const [isLoading, setLoading] = useState(null)

  useOverflow()

  useEffect(() => {
    if (!modalEntity) {
      return
    }

    if (!requestBuildingId && !requestFlatId) {
      setLoading(false)

      return
    }

    let apiList = []

    if (requestBuildingId) {
      apiList[0] = getBuilding(requestBuildingId)
    }

    if (requestBuildingId && requestFlatId) {
      apiList[1] = getFlat({ id: requestFlatId })
    }

    Promise.all(apiList)
      .then(([building, flat]) => {
        if (building) {
          setBuilding(building)
        }

        if (flat) {
          setFlat(flat)
        }
      })
      .finally(() => setLoading(false))
  }, [requestBuildingId, requestFlatId, modalEntity])

  const handleChangeBuilding = option => {
    setBuilding(option)

    if (flat) {
      setFlat(null)
    }
  }

  const handleClearBuilding = () => {
    setBuilding(null)

    if (flat) {
      setFlat(null)
    }
  }

  const handleClearFlat = () => {
    setFlat(null)
  }

  const handleClearRole = () => {
    setRole(null)
  }

  const handleApply = () => {
    setLoading(true)

    const params = getParams(building, flat, role)

    api.profile.getDwellerOptionList(params).then(response => {
      const nextUsers = filterUsers(
        values[modalEntity],
        getFetchedItems(response)
      )

      props.setValues({
        ...values,
        [modalEntity]: nextUsers,
      })

      setLoading(false)

      props.onToggleMass?.(true)

      props.onClose()
    })
  }

  return (
    <Modal
      isOpen={isOpen}
      className={styles.modal}
      onRequestClose={props.onClose}
    >
      <ModalHead title={t('FilterTitle')} />
      <ModalCloseButton onClose={props.onClose} />
      <ModalBody>
        <ModalRow>
          <ModalLabel>{t('EntityLabel')}</ModalLabel>
        </ModalRow>
        <ModalRow>
          <NewSelectSimple
            disabled
            className={styles.select}
            options={entityOptions}
            value={entityOption}
            onChange={setEntityOption}
          />
        </ModalRow>
        <ModalRow>
          <ModalLabel>{t('BuildingLabel')}</ModalLabel>
        </ModalRow>
        <ModalRow>
          <NewSelectAsync
            disabled={isLoading}
            pageSize={10}
            className={styles.select}
            api={api.building.getListTiny}
            searchKey='address'
            placeholder={t('BuildingPlaceholder')}
            getLabel={getBuildingLabel}
            getSelectedLabel={getBuildingLabel}
            view='dropdown'
            selectedItems={building ? [building] : []}
            permanentParams={{
              with_dwellers: true,
            }}
            onClick={handleChangeBuilding}
            onClear={handleClearBuilding}
          />
        </ModalRow>
        <ModalRow>
          <ModalLabel>{t('FlatLabel')}</ModalLabel>
        </ModalRow>
        <ModalRow>
          <NewSelectAsync
            disabled={!building || isLoading}
            pageSize={10}
            className={styles.select}
            api={api.flat.getListTiny}
            searchKey='number_icontains'
            placeholder={t('FlatPlaceholder')}
            getLabel={getFlatLabel}
            getSelectedLabel={getFlatLabel}
            view='dropdown'
            selectedItems={flat ? [flat] : []}
            dynamicParams={{
              building: building?.id,
            }}
            onClick={setFlat}
            onClear={handleClearFlat}
          />
        </ModalRow>
        <ModalRow>
          <ModalLabel>{t('RoleLabel')}</ModalLabel>
        </ModalRow>
        <ModalRow>
          <NewSelectSimple
            disabled={isLoading}
            className={styles.select}
            options={roleOptions}
            value={role}
            placeholder={t('RolePlaceholder')}
            onChange={setRole}
          />
          {role && (
            <Button.Delete
              disabled={isLoading}
              className={styles.clear}
              onClick={handleClearRole}
            />
          )}
        </ModalRow>
      </ModalBody>
      <ModalButtons className={styles.buttons}>
        <Button.Save
          disabled={isLoading || (!building && !flat && !role)}
          onClick={handleApply}
        >
          {t('ApplyFilter')}
        </Button.Save>
        <Button.Cancel disabled={isLoading} onClick={props.onClose}>
          {t('Common:Cancel')}
        </Button.Cancel>
      </ModalButtons>
    </Modal>
  )
}

export default FilterModal
