// @flow

import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { has } from 'lodash-es'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'
import type { Node } from 'react'

import Widget from '../../../containers/Widget'
// import Map, { MAP_WITHOUT_CONTROLS } from '../../Map/Map'
import FlatAddress from '../../Flat/FlatAddress'
import Content from '../../modals/Content'
import { WIDGET_TYPES } from '../../../containers/widgets/widgets'
import {
  openModal,
  hideModal,
} from '../../../containers/RequestView/RequestView.actions'
import StaticMap from '../../StaticMap'

import styles from './AddressWidget.module.scss'

type Props = {
  hideModal: () => void,
  openModal: Node => void,
  t: string => string,
}

const AddressWidget = (props: Props) => {
  const { address, building, flatId, flat, notCollapse } = props

  if (!address) {
    return null
  }

  const renderAddress = () => {
    const addressClassName = classnames('apartment-profile__map')
    const hasFlatNumber = flatId && has(flat, 'number')
    // const {
    //   lattitude,
    //   longitude,
    //   widget_lattitude_str: widgetLatitudeStr,
    //   widget_longitude_str: widgetLongitudeStr,
    //   widget_zoom: widgetZoom,
    // } = address
    // const center =
    //   widgetLatitudeStr && widgetLongitudeStr
    //     ? {
    //         lat: parseFloat(widgetLatitudeStr),
    //         lng: parseFloat(widgetLongitudeStr),
    //       }
    //     : null
    // const marker =
    //   lattitude && longitude
    //     ? {
    //         name: 'buildingMarker',
    //         position: {
    //           lat: parseFloat(lattitude),
    //           lng: parseFloat(longitude),
    //         },
    //         showBalloon: false,
    //       }
    //     : null
    const handleClick = e => {
      e.stopPropagation()

      const handler = props.setAddressModal || props.openModal

      const closeHandler = props.setAddressModal
        ? () => props.setAddressModal(null)
        : props.hideModal

      if (handler) {
        handler(
          <Content className={styles.modal} onClose={closeHandler}>
            <FlatAddress
              address={address}
              building={building}
              flatId={flatId}
              flat={flat}
            />
          </Content>
        )
      }
    }

    return (
      <div className={addressClassName} onClick={handleClick}>
        <div className={styles.valueContainer}>
          <div className={styles.value} title={address.value}>
            {address.value}
          </div>
          <div className={styles.value}>
            {hasFlatNumber
              ? `${props.t('flat_number_title')} ${flat.number}`
              : null}
          </div>
        </div>
        <StaticMap
          size={hasFlatNumber ? '388x310' : '388x345'}
          address={address}
        />
        {/* <Map
          height={hasFlatNumber ? '310px' : '345px'}
          buildingMarker={marker}
          center={center}
          zoom={widgetZoom}
          options={MAP_WITHOUT_CONTROLS}
        /> */}
      </div>
    )
  }

  return (
    <Widget
      notCollapse={notCollapse}
      name={WIDGET_TYPES.address}
      title={props.t('Common:Address')}
    >
      {renderAddress()}
    </Widget>
  )
}

const mapDispatchToProps = {
  openModal,
  hideModal,
}

export default compose(
  withTranslation('Request'),
  connect(null, mapDispatchToProps)
)(AddressWidget)
