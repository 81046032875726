// @flow

import React from 'react'
import { withTranslation } from 'react-i18next'

import Button from '../../Button'
import { MAX_LINKS_COUNT } from '../constants'

type Props = {
  adding: ?Object,
  items: ?Array<Object>,
  onEdit: (?Object, number) => void,
  t: string => string,
}

const CardLinks = (props: Props) => {
  const { adding, items, onEdit, t } = props

  return (
    <div className='request-constructor__card--footer'>
      {items &&
        items.map((link, i) => (
          <div
            key={`link-${link.id}`}
            className='request-constructor__card--footer-link'
            onClick={() => onEdit(link, i)}
          >
            <div>{link.text}</div>
            <div>{link.block_title || t('emptyBlockTitle')}</div>
          </div>
        ))}
      {!!adding && (
        <div className='request-constructor__card--footer-link'>
          <div>{adding.text || t('emptyLinkTitle')}</div>
          <div>{adding.block || t('emptyLinkBlock')}</div>
        </div>
      )}
      {items.length < MAX_LINKS_COUNT && (
        <Button.Add
          disabled={!!adding}
          title={t('addButton')}
          onClick={() => onEdit(null, items.length)}
        />
      )}
    </div>
  )
}

export default withTranslation('RequestConstructor')(CardLinks)
