// @flow

import {
  split,
  map,
  flatten,
  find,
  filter,
  flow,
  invokeMap,
  partialRight,
  tail,
  has,
} from 'lodash-es'

import * as constants from './Menu.constants'
import type { MenuType } from './Menu'

type ProcessedFilter = {
  component: Object,
  name: string,
  text: string,
}

type ProcessedMenuItem = {
  counter: string,
  exact: Boolean,
  iconPath: string,
  linkTo: string,
  name: string,
  text: string,
}

export function getCounterKey(name: string): string {
  return constants.PAGES_PARAMS[name].unviewedCounterKey
}

export function getFilterComponent(name: string): string | void {
  return constants.FILTERS_PARAMS[name].component
}

export function getExpandedFilterComponent(name: string): string | void {
  return constants.EXPANDED_FILTERS_PARAMS[name].component
}

export function getFilterKey(name: string): string | void {
  return constants.FILTERS_PARAMS[name].filterKey
}

export function getAvatarKey(name: string): string | void {
  return constants.FILTERS_PARAMS[name].avatarKey
}

export function getSearchKey(name: string): string | void {
  return constants.FILTERS_PARAMS[name].searchKey
}

export function getValueKey(name: string): string | void {
  return constants.FILTERS_PARAMS[name].valueKey
}

export function getLabelKey(name: string): string | void {
  return constants.FILTERS_PARAMS[name].labelKey
}

export function getDescriptionKey(name: string): string | void {
  return constants.FILTERS_PARAMS[name].descriptionKey
}

export function isHidden(name: string): boolean {
  return constants.FILTERS_PARAMS[name].hidden || false
}

export function getExpandedFilterKey(name: string): string | void {
  return constants.EXPANDED_FILTERS_PARAMS[name].filterKey
}

export function getFilterTranslation(name: string, t: Function) {
  return t(constants.FILTERS_PARAMS[name].translationKey)
}

export function getExpandedFilterTranslation(name: string, t: Function) {
  return t(constants.EXPANDED_FILTERS_PARAMS[name].translationKey)
}

export function getIconPath(name: string): string {
  return constants.PAGES_PARAMS[name].icon
}

export function getMenuItemTranslation(name: string, t: Function): string {
  return t(constants.PAGES_PARAMS[name].translationKey)
}

export function getSubMenuItemTranslation(name: string, t: Function): string {
  return t(constants.SUBMENU_TRANSLATIONS[name])
}

export function getUrl(name: string): string {
  return `/${constants.PAGES_PARAMS[name].url}`
}

export function isExact(name: string): boolean {
  return !!constants.PAGES_PARAMS[name].exact
}

export function getMenuItemList(
  items: MenuType,
  t: Function
): Array<ProcessedMenuItem> {
  return invokeMap(items, 'map', ({ name }) => ({
    linkTo: getUrl(name),
    iconPath: getIconPath(name),
    text: getMenuItemTranslation(name, t),
    exact: isExact(name),
    counterKey: getCounterKey(name),
    name,
  }))
}

export function getFilterType(name) {
  return constants.FILTERS_PARAMS[name].filterType
}

export function checkSub(name) {
  return constants.FILTERS_PARAMS[name].checkSub
}

export function checkGql(name) {
  return constants.FILTERS_PARAMS[name].useGqlId
}

export function getFilterList(
  items: Array<Object>,
  t: Function
): Array<ProcessedFilter> {
  return items.map(({ name }) => ({
    component: getFilterComponent(name),
    text: getFilterTranslation(name, t),
    filterKey: getFilterKey(name),
    avatarKey: getAvatarKey(name),
    searchKey: getSearchKey(name),
    labelKey: getLabelKey(name),
    valueKey: getValueKey(name),
    descriptionKey: getDescriptionKey(name),
    name,
    filterType: getFilterType(name),
    checkSub: checkSub(name),
    useGqlId: checkGql(name),
    hidden: isHidden(name),
  }))
}

export function getExpandedFilterList(
  items: Array<Object>,
  t: Function
): Array<ProcessedFilter> {
  return items.map(({ name }) => ({
    component: getExpandedFilterComponent(name),
    text: getExpandedFilterTranslation(name, t),
    filterKey: getExpandedFilterKey(name),
    name,
  }))
}

export function getSubMenuItemList(items, currentTab, t) {
  if (!items) {
    return
  }

  return flow([
    partialRight(
      filter,
      item => !has(item, 'tab') || (has(item, 'tab') && item.tab === currentTab)
    ),
    partialRight(map, item => ({
      ...item,
      text: getSubMenuItemTranslation(item.name, t) || item.name,
    })),
  ])(items)
}

export function isActive(menuItemName: string, mainPathName: string): Function {
  return (match: ?Object): Boolean =>
    constants.PAGES_ALIASES[mainPathName] === menuItemName || match
}

export function getMainPathName(match: Object): string {
  const { path } = match

  const mainPathName = flow([partialRight(split, '/', 2), tail])(path)[0]

  return mainPathName || constants.MAIN_PATH_KEY
}

export function getCurrentItem(menu, itemName) {
  return flow([
    flatten,
    partialRight(map, item => {
      if (constants.FLAT_PATH_ALIASES.includes(item.name)) {
        return { ...item, name: 'flats' }
      }

      return item
    }),
    partialRight(find, { name: itemName }),
  ])(menu)
}
