// @flow

import { put, call, fork, takeEvery } from 'redux-saga/effects'

import api from '../../../core/api'
import * as actions from './CompanyBackground.actionTypes'

function* watchSave() {
  yield takeEvery(actions.COMPANY_BACKGROUND_SAVE, save)
}

function* save({ uk, data }) {
  try {
    const result = yield call(api.uk.update, uk, data)

    yield put({ type: actions.COMPANY_UPDATE, uk: result })
    yield put({ type: actions.COMPANY_BACKGROUND_WAS_SAVED })
  } catch (error) {
    yield put({
      type: actions.COMPANY_BACKGROUND_ERROR,
      error,
    })
  }
}

export default function* () {
  yield fork(watchSave)
}
