export const TYPES = {
  workTime: 'workTime',
  contactTypes: {
    contact_email: {
      name: 'contact_email',
      inputType: 'email',
      verboseName: 'Contact email',
    },
    contact_phone: {
      name: 'contact_phone',
      inputType: 'text',
      verboseName: 'Contact phone',
    },
    website: {
      inputType: 'url',
      verboseName: 'Website',
    },
    order_email: {
      name: 'order_email',
      inputType: 'email',
      verboseName: 'Order email',
    },
    order_phone: {
      name: 'order_phone',
      inputType: 'text',
      verboseName: 'Order phone',
    },
  },
}
