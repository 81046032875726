// @flow

import React, { useContext } from 'react'
import type { Node } from 'react'

import ActivityItemContext from '../../ActivityItem.context'

import styles from './ItemNotes.module.scss'

const ItemNotes = (): Node => {
  const {
    activity: { notes },
  } = useContext(ActivityItemContext)

  if (!notes) {
    return null
  }

  return (
    <div className={styles['item-notes']}>
      <span>{notes}</span>
    </div>
  )
}

export default ItemNotes
