// @flow

import { put, call, fork, takeEvery } from 'redux-saga/effects'
import type { Saga } from 'redux-saga'

import api from '../../../core/api'
import {
  WIDGET_MAY_KNOW_INITIATING,
  WIDGET_MAY_KNOW_INITIATED,
  WIDGET_MAY_KNOW_ERROR,
} from './WidgetMayKnow.actionTypes'
import { serverError } from '../../Layout/Layout.actions'

function* watchLoad() {
  yield takeEvery(WIDGET_MAY_KNOW_INITIATING, load)
}

function* load() {
  try {
    const dwellers = yield call(api.profile.getNeighbors)

    yield put({
      type: WIDGET_MAY_KNOW_INITIATED,
      dwellers: dwellers.slice(0, 2),
    })
  } catch (error) {
    yield put(serverError(error))
    yield put({ type: WIDGET_MAY_KNOW_ERROR, error })
  }
}

export default function* widgetMayKnowSaga(): Saga<void> {
  yield fork(watchLoad)
}
