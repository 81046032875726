export const IGEL_INITIATE = 'IGEL_INITIATE'
export const IGEL_WAS_INITIATED = 'IGEL_WAS_INITIATED'
export const IGEL_CHECK_STATUS = 'IGEL_CHECK_STATUS'
export const IGEL_STATUS_WAS_CHECKED = 'IGEL_STATUS_WAS_CHECKED'
export const IGEL_CREATE = 'IGEL_CREATE'
export const IGEL_WAS_CREATED = 'IGEL_WAS_CREATED'
export const IGEL_APPLY_CONFIG = 'IGEL_APPLY_CONFIG'
export const IGEL_CONFIG_WAS_APPLIED = 'IGEL_CONFIG_WAS_APPLIED'
export const IGEL_SYNCHRONIZE = 'IGEL_SYNCHRONIZE'
export const IGEL_MATCH_REQUESTS = 'IGEL_MATCH_REQUESTS'
export const IGEL_REQUESTS_WERE_MATCHED = 'IGEL_REQUESTS_WERE_MATCHED'
export const IGEL_COPY_REQUEST_TYPES = 'IGEL_COPY_REQUEST_TYPES'
export const IGEL_REQUEST_TYPES_WERE_COPIED = 'IGEL_REQUEST_TYPES_WERE_COPIED'
export const IGEL_POPUP_ERROR = 'IGEL_POPUP_ERROR'
export const IGEL_RESET_POPUP_ERROR = 'IGEL_RESET_POPUP_ERROR'
export const IGEL_ERROR = 'IGEL_ERROR'
