// @flow

import type { Node } from 'react'
import React from 'react'
import setFormikError from '../../../utils/setFormikError'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

import FieldRow from '../../FieldRow'

import Button from '../../Button'
import styles from './ProfileInformation.module.scss'
import {
  ADDRESS,
  POST_CODE,
  CITY,
  COUNTRY,
  ADDRESS_2,
  STREET,
  MAX_POSTAL_ADDRESS_LENGTH,
  MAX_STREET_LENGTH,
  MAX_CITY_LENGTH,
  MAX_POST_CODE,
  MAX_COUNTRY_LENGTH,
} from './ProfileInformationPostal.constants'
import * as Yup from 'yup'
import type { IAddress } from '../ProfileData'

type Props = {
  active: boolean,
  handleEditClose: () => void,
  initialValues: IAddress,
  isOpen: boolean,
  updateProfilePostal: (data: { address: IAddress }) => Promise<IAddress>,
}

const PostalForm = ({
  isOpen,
  initialValues,
  active,
  handleEditClose,
  updateProfilePostal,
}: Props): Node => {
  const { t } = useTranslation('Profile')

  const onSubmit = values => {
    const address: IAddress = { ...values }
    updateProfilePostal({ address })
      .then(() => handleEditClose())
      .catch(error => setFormikError(error, setFieldError))
  }

  const onClosed = () => {
    handleEditClose()
    resetForm()
  }

  const {
    values,
    errors,
    dirty,
    isSubmitting,
    handleSubmit,
    handleChange,
    setFieldError,
    resetForm,
  } = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      [COUNTRY]: Yup.string().max(
        MAX_POSTAL_ADDRESS_LENGTH,
        t('MaxInputLength')
      ),
      [ADDRESS]: Yup.string().max(
        MAX_POSTAL_ADDRESS_LENGTH,
        t('MaxInputLength')
      ),
      [ADDRESS_2]: Yup.string().max(
        MAX_POSTAL_ADDRESS_LENGTH,
        t('MaxInputLength')
      ),
      [STREET]: Yup.string().max(MAX_STREET_LENGTH, t('MaxInputLength')),
      [POST_CODE]: Yup.string().max(MAX_POST_CODE, t('MaxInputLength')),
      [CITY]: Yup.string().max(MAX_CITY_LENGTH, t('MaxInputLength')),
    }),
    onSubmit,
  })

  return (
    <form
      name='profile_information'
      onSubmit={handleSubmit}
      onChange={handleChange}
    >
      <FieldRow
        name={ADDRESS}
        label={'PostalAddress'}
        value={values[ADDRESS]}
        error={errors[ADDRESS]}
        active={active}
        placeholder={t('EnterPostalAddress')}
        maxLength={MAX_POSTAL_ADDRESS_LENGTH}
        handleChange={handleChange}
      />
      {(values[STREET] || isOpen) && (
        <FieldRow
          name={STREET}
          active={active}
          label={'Street'}
          error={errors[STREET]}
          value={values[STREET]}
          placeholder={t('EnterStreet')}
          maxLength={MAX_STREET_LENGTH}
          handleChange={handleChange}
        />
      )}
      {(values[ADDRESS_2] || isOpen) && (
        <FieldRow
          name={ADDRESS_2}
          active={active}
          label={'Address2'}
          error={errors[ADDRESS_2]}
          value={values[ADDRESS_2]}
          placeholder={t('EnterAddress2')}
          handleChange={handleChange}
        />
      )}
      {(values[POST_CODE] || isOpen) && (
        <FieldRow
          name={POST_CODE}
          active={active}
          label={'PostCode'}
          error={errors[POST_CODE]}
          value={values[POST_CODE]}
          placeholder={t('EnterPostCode')}
          maxLength={MAX_POST_CODE}
          handleChange={handleChange}
        />
      )}
      {(values[CITY] || isOpen) && (
        <FieldRow
          name={CITY}
          active={active}
          label={'City'}
          error={errors[CITY]}
          value={values[CITY]}
          placeholder={t('EnterCity')}
          MaxLength={MAX_CITY_LENGTH}
          handleChange={handleChange}
        />
      )}
      {(values[COUNTRY] || isOpen) && (
        <FieldRow
          name={COUNTRY}
          active={active}
          label={'Country'}
          error={errors[COUNTRY]}
          value={values[COUNTRY]}
          placeholder={t('EnterCountry')}
          maxLength={MAX_COUNTRY_LENGTH}
          handleChange={handleChange}
        />
      )}
      {active && (
        <div className={styles.buttonWrapper}>
          <Button.Save disabled={!dirty || isSubmitting} type='submit'>
            {t('Common:Save')}
          </Button.Save>
          <Button.Cancel onClick={onClosed}>{t('Common:Cancel')}</Button.Cancel>
        </div>
      )}
    </form>
  )
}

export default PostalForm
