// @flow

import React from 'react'

import styles from './PrimaryText.module.scss'

type Props = {
  text: string,
}

const PrimaryText = (props: Props): React$Element<'span'> => {
  const { text } = props

  return (
    <span className={styles.text} onClick={props.onClick}>
      {text}
    </span>
  )
}

export default PrimaryText
