import React, { useEffect, useState } from 'react'
import { stringify } from 'qs'
import { isEmpty } from 'lodash-es'
import { useSelector } from 'react-redux'

import { useUser } from '../../hooks/useUser'
import { getUkCity } from '../../utils/commonSelectors'

export function StaticMap(props) {
  const { address, size } = props

  const [url, setUrl] = useState(null)

  const user = useUser()
  const city = useSelector(getUkCity)

  const language = user?.language_obj?.code || 'en'

  useEffect(() => {
    if (isEmpty(address)) {
      return
    }

    const { widget_lattitude_str, widget_longitude_str, lattitude, longitude } =
      address

    const defaultLattitude = city?.lattitude || 48.210033
    const defaultLongitude = city?.longitude || 16.363449

    let data = {
      center: `${widget_lattitude_str || defaultLattitude}, ${
        widget_longitude_str || defaultLongitude
      }`,
      size,
      zoom: 17,
      key: 'AIzaSyAlEKOXU776FD89YnZn3f8y2JSNo0kKjIY',
      language,
      scale: 2,
    }

    if (lattitude && longitude) {
      data.markers = `${lattitude},${longitude}`
    }

    setUrl(`https://maps.googleapis.com/maps/api/staticmap?${stringify(data)}`)
  }, [address])

  if (!url) {
    return null
  }

  return <img src={url} />
}

StaticMap.defaultProps = {
  size: '388x310',
}

export default StaticMap
