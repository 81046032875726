//@flow

import { FORMIK_EMPTY_VALUE } from '../../../../constants'

export const TYPE_OF_INSURANCE = 'insurance_type'
export const CONTRACT_NUMBER = 'contract_number'
export const SUPPLIER = 'supplier'

export const MAX_CONTACT_NUMBER_LENGTH = 50

export const KEYS = [
  TYPE_OF_INSURANCE,
  CONTRACT_NUMBER,
  // ,SUPPLIER
]

export const DEFAULT_VALUES: Object = {
  [TYPE_OF_INSURANCE]: FORMIK_EMPTY_VALUE,
  [CONTRACT_NUMBER]: FORMIK_EMPTY_VALUE,
  // [SUPPLIER]: FORMIK_EMPTY_VALUE,
}
