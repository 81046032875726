// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import { camelCase } from 'lodash-es'

import Icon from '../../../components/Icon'
import Widgets from '../widgets'
import AddressWidget from '../../../components/widgets/AddressWidget'
import { getHref } from '../../../utils/utils'
import { CONTACT_TYPES_SVG } from '../../../constants'

import styles from './MarketplaceContactsWidget.module.scss'

const MarketplaceContactsWidget = props => {
  const { contacts, address } = props

  const { t } = useTranslation('MarketplaceWidgets')

  const renderContact = (contact, i) => {
    const { value } = contact

    if (!value) {
      return null
    }

    const { type, verboseName } = contact
    const contactLabel = t(`${camelCase(verboseName)}`)
    const hrefValue = getHref(type, value)

    return (
      <div className={styles.item} key={`contact-${i}`}>
        <div className={styles['contact-header']} title={contactLabel}>
          <Icon className={styles.svg} id={CONTACT_TYPES_SVG[type]} />
          <span className={styles['contact-label']}>{contactLabel}</span>
        </div>
        <a
          title={value}
          className={styles.href}
          href={hrefValue}
          target='_blank'
          rel='noopener noreferrer'
        >
          {value}
        </a>
      </div>
    )
  }

  return (
    <Widgets collapsed={false}>
      <aside className='widget'>
        <header className='widget__header'>
          <Icon className={styles['header-icon']} id='user' />
          <span className='widget__header-text'>{t('contacts')}</span>
        </header>
        {contacts.map(renderContact)}
      </aside>
      {address && (
        <AddressWidget
          notCollapse
          setAddressModal={props.setAddressModal}
          address={{
            lattitude: address.value.lat,
            longitude: address.value.lon,
            value: address.value.value,
            widget_lattitude: address.value.lat,
            widget_lattitude_str: `${address.value.lat}`,
            widget_longitude: `${address.value.lon}`,
            widget_longitude_str: address.value.lon,
            widget_zoom: null,
          }}
        />
      )}
    </Widgets>
  )
}

export default MarketplaceContactsWidget
