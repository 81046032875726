// @flow

import React from 'react'
import type { Node } from 'react'

import { useThumbnail } from '../../../../hooks'
import NewAvatar from '../../../NewAvatar'

type Props = { file: Object }

const OwnerIcon = (props: Props): Node => {
  const { file } = props

  const thumbnail = useThumbnail(file)

  if (!thumbnail) {
    return null
  }

  return <NewAvatar src={thumbnail} />
}

export default OwnerIcon
