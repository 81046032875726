// @flow

export const REQUEST_CONSTRUCTOR_INITIATE_BLOCK = 'REQUEST_CONSTRUCTOR_INITIATE_BLOCK'
export const REQUEST_CONSTRUCTOR_BLOCK_WAS_INITIATED = 'REQUEST_CONSTRUCTOR_BLOCK_WAS_INITIATED'
export const REQUEST_CONSTRUCTOR_RENAME_BLOCK = 'REQUEST_CONSTRUCTOR_RENAME_BLOCK'
export const REQUEST_CONSTRUCTOR_BLOCK_WAS_RENAMED = 'REQUEST_CONSTRUCTOR_BLOCK_WAS_RENAMED'
export const REQUEST_CONSTRUCTOR_REMOVE_BLOCK = 'REQUEST_CONSTRUCTOR_REMOVE_BLOCK'
export const REQUEST_CONSTRUCTOR_BLOCK_WAS_REMOVED = 'REQUEST_CONSTRUCTOR_BLOCK_WAS_REMOVED'

export const REQUEST_CONSTRUCTOR_BLOCK_RESET = 'REQUEST_CONSTRUCTOR_BLOCK_RESET'
export const REQUEST_CONSTRUCTOR_BLOCK_ERROR = 'REQUEST_CONSTRUCTOR_BLOCK_ERROR'
