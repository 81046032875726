// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import { parseMpPrice } from '../../../utils/utils'
import Row from './Row'
import Cell from './Cell'
import Currency from './Currency'

import styles from './Info.module.scss'

type Props = {
  children: Node,
  plan?: Object,
}

const Info = (props: Props): Node => {
  const { plan, children } = props

  const {
    total_invoices_amount,
    open_estimated_amount,
    covered_amount,
    not_covered_amount,
  } = plan || {}

  const { t } = useTranslation('BudgetPlan')

  return (
    <div className={styles.info}>
      <Row>
        {children}
        <Cell>
          <label style={{ fontWeight: '500', color: '#000' }}>
            {t('TotalAmount')}
          </label>
        </Cell>
        <Cell>
          <span className={styles.value}>
            {total_invoices_amount && <Currency />}
            {total_invoices_amount
              ? parseMpPrice(total_invoices_amount, 'de')
              : t('NoMoney')}
          </span>
        </Cell>
      </Row>
      <Row>
        <Cell>
          <label>{t('OpenEstimatedAmount')}</label>
        </Cell>
        <Cell>
          <span className={styles.value}>
            {open_estimated_amount && <Currency />}
            {open_estimated_amount
              ? parseMpPrice(open_estimated_amount, 'de')
              : t('NoMoney')}
          </span>
        </Cell>
        <Cell>
          <label>{t('NotCoveredAmount')}</label>
        </Cell>
        <Cell>
          <span className={styles.value}>
            {not_covered_amount && <Currency />}
            {not_covered_amount ? (
              <span style={{ color: '#e44440' }}>
                {parseMpPrice(not_covered_amount, 'de')}
              </span>
            ) : (
              t('NoMoney')
            )}
          </span>
        </Cell>
      </Row>
      <Row>
        <Cell>
          <label>{t('CoveredAmount')}</label>
        </Cell>
        <Cell>
          <span className={styles.value}>
            {covered_amount && <Currency />}
            {covered_amount ? (
              <span style={{ color: '#9ec221' }}>
                {parseMpPrice(covered_amount, 'de')}
              </span>
            ) : (
              t('NoMoney')
            )}
          </span>
        </Cell>
      </Row>
    </div>
  )
}

export default Info
