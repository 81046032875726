// @flow

import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { QueryRenderer, createRefetchContainer, graphql } from 'react-relay'

import { MARKETPLACE_ROUTES } from '../../constants'
import Loader from '../../components/Loader'
import environment from '../../createRelayEnvironment'
import ProviderProfile from './ProviderProfile'
import ThreeColumnLayout from '../../components/Layout/ThreeColumnLayout'
import MarketplaceContactsWidget from '../widgets/MarketplaceContactsWidget'
import ProviderProfileOffers from './ProviderProfileOffers'
import ErrorMessage from '../../ui/ErrorMessage'

const { MarketplaceError } = ErrorMessage

const ProviderProfileView = props => {
  const {
    query: { provider },
    relay: { refetch },
  } = props

  if (!provider) {
    return <Redirect to={MARKETPLACE_ROUTES['404']} />
  }

  return (
    <ThreeColumnLayout
      contacts={props.query.provider.contacts}
      widgets={MarketplaceContactsWidget}
      {...props}
    >
      <ProviderProfile
        {...props}
        provider={props.query.provider}
        refetch={refetch}
      >
        <ProviderProfileOffers
          {...props}
          provider={props.query.provider}
          refetch={props.relay.refetch}
        />
      </ProviderProfile>
    </ThreeColumnLayout>
  )
}

const RefetchContainer = createRefetchContainer(
  ProviderProfileView,
  {
    query: graphql`
      fragment ProviderView_query on Query
      @argumentDefinitions(
        providerID: { type: "ID!" }
        providerGlobalId: { type: "String" }
        page: { type: "Float" }
      ) {
        provider(id: $providerID) {
          id
          contacts {
            type
            value
            verboseName
          }
          image {
            origin
            alt
          }
          name
          ...ProviderProfile_provider
        }
        ...ProviderProfileOffers_query
          @arguments(providerGlobalId: $providerGlobalId, page: $page)
      }
    `,
  },
  graphql`
    query ProviderViewRefetchQuery(
      $providerID: ID!
      $providerGlobalId: String
      $page: Float
    ) {
      ...ProviderView_query
        @arguments(
          providerID: $providerID
          providerGlobalId: $providerGlobalId
          page: $page
        )
    }
  `
)

function render({ error, props }) {
  if (error) {
    return <MarketplaceError error={error} />
  } else if (props) {
    return <RefetchContainer query={props} />
  } else {
    return <Loader text={false} type='big' />
  }
}

const ProviderView = props => {
  const {
    match: {
      params: { id },
    },
  } = props

  return (
    <div>
      <QueryRenderer
        variables={{
          providerID: id,
          providerGlobalId: id,
        }}
        environment={environment}
        query={graphql`
          query ProviderViewQuery(
            $providerID: ID!
            $providerGlobalId: String
            $page: Float
          ) {
            ...ProviderView_query
              @arguments(
                providerID: $providerID
                providerGlobalId: $providerGlobalId
                page: $page
              )
          }
        `}
        render={render}
      />
    </div>
  )
}

const mapStateToProps = state => ({
  ...state.login,
  ...state.init,
})

export default connect(mapStateToProps)(ProviderView)
