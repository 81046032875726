// @flow

import React, { useContext, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { isEmpty } from 'lodash-es'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import classNames from 'classnames'
import type { Node } from 'react'

import Nbsp from '../../NBSP'
import { getVariables, isSubmitDisabled } from './InitStage.utils'
import {
  getUrlForAvatar,
  getUserId,
  getUserName,
  isStaffUser,
} from '../../../utils/utils'
import api from '../../../core/api'
import ModalBody from '../../Modal/ModalBody'
import ModalLabel from '../../Modal/ModalLabel'
import ModalRow from '../../Modal/ModalRow'
import NewSelectAsync from '../../NewSelectAsync'
import ModalButtons from '../../Modal/ModalButtons'
import { useUser } from '../../../hooks'
import { FORMIK_EMPTY_VALUE } from '../../../constants'
import { BUILDING, FLAT, USER } from './InitStage.constants.js'
import NewWizardContext from '../NewWizard.context'
import Button from '../../Button'
import UserStatus from './UserStatus'

import ModalText from '../../Modal/ModalText/ModalText'
import Loader from '../../Loader'
import { getProfile } from '../../../core/api/api.profile'

import styles from './InitStage.module.scss'

type Props = {|
  chatMessageId?: string,
  initObj: Object,
  isCancel: boolean,
  messageUuid?: string,
  onCancel: () => void,
  outbound?: string,
|}

const InitStage = (props: Props): Node => {
  const {
    isCancel,
    messageUuid,
    chatMessageId,
    inbound_user,
    initObj,
    outbound,
  } = props

  const [isLoading, setLoading] = useState(true)
  const [currentUser, setCurrentUser] = useState(null)

  const { t } = useTranslation('WizardPopup')

  const user = useUser()

  const isStaff = isStaffUser(user)

  const userId = outbound ? inbound_user : getUserId(user)

  useEffect(() => {
    if (isStaff && !outbound) {
      setCurrentUser(user)
    } else if (outbound && initObj.user) {
      setCurrentUser(initObj.user)
    } else {
      getProfile(userId, { outbound })
        .then(data => {
          setUserFields(data)

          return data
        })
        .then(data => setCurrentUser(data))
    }
  }, [user])

  useEffect(() => {
    if (currentUser) {
      setLoading(false)
    }
  }, [currentUser])

  useEffect(() => {
    if (!isEmpty(initObj)) {
      const { user, ...otherFields } = initObj

      if (user) {
        setUserFields(user)
      } else {
        setFields(otherFields)
      }
    }
  }, [initObj])

  const { onSubmit, serverError } = useContext(NewWizardContext)

  const formik = useFormik({
    validateOnChange: true,
    enableReinitialize: true,
    initialStatus: {
      isSingleFlat: false,
    },
    initialValues: {
      [USER]: FORMIK_EMPTY_VALUE,
      [BUILDING]: FORMIK_EMPTY_VALUE,
      [FLAT]: FORMIK_EMPTY_VALUE,
    },
    validationSchema: Yup.object({
      [BUILDING]: Yup.object(),
      [FLAT]: Yup.object(),
      [USER]: Yup.object(),
    }),
    onSubmit: submitValues => {
      const variables = getVariables(
        submitValues[USER],
        outbound ? currentUser : user,
        !formik.status.isSingleFlat && submitValues[BUILDING].id
          ? submitValues[BUILDING]
          : null,
        !formik.status.isSingleFlat &&
          submitValues[FLAT].id &&
          submitValues[BUILDING].id
          ? submitValues[FLAT]
          : null,
        messageUuid,
        chatMessageId
      )

      onSubmit({
        variables: JSON.stringify(variables),
      })
    },
  })

  useEffect(() => {
    if (serverError && formik.isSubmitting) {
      formik.resetForm()
    }
  }, [serverError, formik.isSubmitting])

  const setFields = fields => {
    if (!isEmpty(fields)) {
      Object.keys(fields).forEach(field =>
        formik.setFieldValue(field, fields[field])
      )
    }
  }

  const setUserFields = user => {
    if (user) {
      formik.setFieldValue(USER, user)

      if (user?.flat_obj) {
        const {
          flat_obj: { id, number, building },
          flat_obj,
          address_obj,
          flats_count,
          buildings_count,
        } = user

        if (!(flats_count > 1 || buildings_count > 1)) {
          formik.setFieldValue(BUILDING, {
            id: building,
            address_obj,
          })

          formik.setFieldValue(FLAT, {
            id,
            number,
            flat_obj,
          })

          formik.setStatus({
            isSingleFlat: true,
          })
        }
      }
    }
  }

  const handleClear = () => {
    if (formik.dirty) {
      formik.handleReset()
    } else {
      props.onCancel()
    }
  }

  const handleChangeUser = option => {
    if (option.id) {
      getProfile(option.id, { outbound })
        .then(setUserFields)
        .catch(e => {
          console.error(e)
        })
    }
  }

  const handleChangeBuilding = option => {
    formik.setFieldValue(FLAT, FORMIK_EMPTY_VALUE)
    formik.setFieldValue(BUILDING, option)
  }

  const handleChangeFlat = option => {
    formik.setFieldValue(FLAT, option)
  }

  const handleClearUser = e => {
    e.stopPropagation()

    formik.setFieldValue(USER, FORMIK_EMPTY_VALUE)
    formik.setStatus({
      isSingleFlat: false,
    })
  }

  const handleClearFlat = e => {
    e.stopPropagation()

    formik.setFieldValue(FLAT, FORMIK_EMPTY_VALUE)
  }

  const handleClearBuilding = e => {
    e.stopPropagation()

    formik.setFieldValue(BUILDING, FORMIK_EMPTY_VALUE)
    formik.setFieldValue(FLAT, FORMIK_EMPTY_VALUE)
  }

  const formClass = classNames({
    [styles.hidden]: isCancel,
  })

  const getMeta = (option, isSelected) => {
    if (!option) {
      return null
    }

    const status = option.status

    return <UserStatus status={status} isSelected={isSelected} />
  }

  if (isLoading) {
    return <Loader type='medium' text={false} />
  }

  const isFormFilled = Object.values(formik.values).every(field => !!field)

  return (
    <form className={formClass} onSubmit={formik.handleSubmit}>
      <ModalBody>
        <ModalRow>
          <ModalLabel>{`${t('DwellerOrLandlord')}:`}</ModalLabel>
        </ModalRow>
        <ModalRow>
          {isStaffUser(user) && !outbound ? (
            <NewSelectAsync
              searchKey='fullname_or_email'
              buttonName={USER}
              disabled={formik.isSubmitting}
              api={params =>
                api.profile.getDwellerOptionList({ outbound, ...params })
              }
              buttonClass={styles['select-button']}
              className={styles.select}
              getLabel={getUserName}
              getMeta={getMeta}
              getSelectedLabel={getUserName}
              pageSize={10}
              view='dropdown'
              placeholder={t('Select:SelectDwellerOrLandlord')}
              selectedItems={formik.values[USER] ? [formik.values[USER]] : []}
              dynamicParams={{
                building: formik.values[BUILDING].id,
                flat: formik.values[FLAT].id,
              }}
              permanentParams={{ page: 1 }}
              onClear={handleClearUser}
              onClick={handleChangeUser}
            />
          ) : (
            <span className='bar__userbar bar__userbar-round'>
              <img
                alt='assignee'
                className='bar__userbar-image'
                src={getUrlForAvatar(outbound ? currentUser : user)}
              />
              <Nbsp />
              <span className='bar__userbar-text'>
                <Nbsp />
                {getUserName(outbound ? currentUser : user)}
              </span>
            </span>
          )}
        </ModalRow>
        <ModalRow>
          <ModalLabel>{`${t('BuildingAddress')}:`}</ModalLabel>
        </ModalRow>
        <ModalRow>
          {isFormFilled ? (
            <ModalText
              text={formik.values[BUILDING].address_obj.value}
              className={styles.text}
            />
          ) : (
            <NewSelectAsync
              buttonName={BUILDING}
              disabled={formik.isSubmitting}
              api={params => api.building.getListTiny({ outbound, ...params })}
              getLabel={building => building.address_obj.value}
              getSelectedLabel={building => building.address_obj.value}
              buttonClass={styles['select-button']}
              className={styles.select}
              pageSize={10}
              searchKey='address'
              view='dropdown'
              placeholder={t('Select:selectAddress')}
              selectedItems={
                formik.values[BUILDING] ? [formik.values[BUILDING]] : []
              }
              dynamicParams={{
                dweller: formik.values[USER].id || formik.values[USER].owner,
              }}
              permanentParams={{
                page: 1,
                is_promo: 0,
                with_flats: 1,
              }}
              onClear={handleClearBuilding}
              onClick={handleChangeBuilding}
            />
          )}
        </ModalRow>
        <ModalRow>
          <ModalLabel>{`${t('FlatNumber')}:`}</ModalLabel>
        </ModalRow>
        <ModalRow>
          {isFormFilled ? (
            <ModalText
              text={formik.values[FLAT].number}
              className={styles.text}
            />
          ) : (
            <NewSelectAsync
              minSearchLength={1}
              searchKey='number_icontains'
              buttonName={FLAT}
              disabled={!formik.values[BUILDING] || formik.isSubmitting}
              api={params => api.flat.getListTiny({ outbound, ...params })}
              getLabel={flat => flat.number}
              getSelectedLabel={flat => flat.number}
              buttonClass={styles['select-button']}
              className={styles.select}
              pageSize={10}
              view='dropdown'
              placeholder={t('Select:selectFlat')}
              selectedItems={formik.values[FLAT] ? [formik.values[FLAT]] : []}
              permanentParams={{ page: 1 }}
              dynamicParams={{
                building: formik.values[BUILDING].id,
                owner: formik.values[USER].id || formik.values[USER].owner,
              }}
              onClear={handleClearFlat}
              onClick={handleChangeFlat}
            />
          )}
        </ModalRow>
      </ModalBody>
      <ModalButtons>
        <Button.Save
          type='submit'
          disabled={
            formik.isSubmitting ||
            isSubmitDisabled(
              formik.values[USER],
              !!formik.values[FLAT],
              user,
              outbound
            )
          }
        >
          {t('Common:Continue')}
        </Button.Save>
        <Button.Cancel disabled={formik.isSubmitting} onClick={handleClear}>
          {t('Common:Cancel')}
        </Button.Cancel>
      </ModalButtons>
    </form>
  )
}

export default InitStage
