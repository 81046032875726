// @flow

import {
  getApi,
  fileUpload,
  deleteApi,
  postRaw,
  patchRaw,
  deleteRaw,
} from './api.core'

export const getById = (fileId: number, { outbound, ...params } = {}) =>
  getApi(
    `api/${
      outbound ? `id2id/${outbound}/` : ''
    }v2/document/${fileId}/download/`,
    params
  )
export const getList = ({ outbound, ...params }: Object) =>
  getApi(`api/${outbound ? `id2id/${outbound}/` : ''}v2/file/`, params)
export const getPermissions = (directoryId: number) =>
  getApi(`api/budget-plan/invoice-file-marking/?directory=${directoryId}`)
export const getFile = (fileId: number, { outbound, ...params } = {}) =>
  getApi(
    `api/${outbound ? `id2id/${outbound}/` : ''}v2/document/${fileId}/`,
    params
  )
export const removeFile = (fileId: number, outbound = null) =>
  deleteApi(`api/${outbound ? `id2id/${outbound}/` : ''}v2/document/${fileId}/`)
export const renameFile = (fileId: number, { outbound, ...params }: Object) =>
  patchRaw(
    `api/${outbound ? `id2id/${outbound}/` : ''}v2/document/${fileId}/`,
    params
  )
export const createFile = ({ outbound, ...params }: Object) =>
  fileUpload(`api/${outbound ? `id2id/${outbound}/` : ''}v2/document/`, params)
export const createDirectory = ({ outbound, ...params }: Object) =>
  postRaw(`api/${outbound ? `id2id/${outbound}/` : ''}v2/directory/`, params)
export const removeDirectory = (directoryId: number, outbound = null) =>
  deleteRaw(
    `api/${outbound ? `id2id/${outbound}/` : ''}v2/directory/${directoryId}/`
  )
export const changeDirectory = (
  directoryId: number,
  { outbound, ...params }: Object
) =>
  patchRaw(
    `api/${outbound ? `id2id/${outbound}/` : ''}v2/directory/${directoryId}/`,
    params
  )
export const getFileById = params => getApi('api/file/scoped/', params)

export const getFileScope = (id, { outbound, ...params }: Object) =>
  getApi(`api/${outbound ? `id2id/${outbound}/` : ''}v2/file/${id}/`, params)
