//@flow

import { FORMIK_EMPTY_VALUE } from '../../../constants'
import {
  POP_VALUE,
  COMMON_USE_SSL_FIELD,
  COMMON_USE_TLS_FIELD,
} from '../EmailIntegration.constants'

export const HOST = 'host'
export const PORT = 'port'
export const USERNAME = 'username'
export const PASSWORD = 'password'
export const EMAIL_FROM = 'email_from'
export const USE_SSL = COMMON_USE_SSL_FIELD
export const USE_TLS = COMMON_USE_TLS_FIELD
export const PROTOCOL = 'protocol'
export const MARK_AS_READ = 'mark_as_read'

export const KEYS = [
  HOST,
  PORT,
  USERNAME,
  PASSWORD,
  EMAIL_FROM,
  USE_SSL,
  USE_TLS,
  PROTOCOL,
  MARK_AS_READ,
]

export const DEFAULT_VALUES: Object = {
  [HOST]: FORMIK_EMPTY_VALUE,
  [PORT]: FORMIK_EMPTY_VALUE,
  [USERNAME]: FORMIK_EMPTY_VALUE,
  [PASSWORD]: FORMIK_EMPTY_VALUE,
  [EMAIL_FROM]: FORMIK_EMPTY_VALUE,
  [USE_SSL]: false,
  [USE_TLS]: false,
  [PROTOCOL]: POP_VALUE,
  [MARK_AS_READ]: false,
}
