// @flow

export const REQUEST_LIST_INITIATE = 'REQUEST_LIST_INITIATE'
export const REQUEST_LIST_INITIATED = 'REQUEST_LIST_INITIATED'
export const REQUEST_LIST_TOGGLE = 'REQUEST_LIST_TOGGLE'
export const REQUEST_LIST_TOGGLE_ALL = 'REQUEST_LIST_TOGGLE_ALL'
export const REQUEST_LIST_SHOW_MODAL = 'REQUEST_LIST_SHOW_MODAL'
export const REQUEST_LIST_HIDE_MODAL = 'REQUEST_LIST_HIDE_MODAL'
export const REQUEST_LIST_MASS_UPDATE = 'REQUEST_LIST_MASS_UPDATE'
export const REQUEST_LIST_LOAD = 'REQUEST_LIST_LOAD'
export const REQUEST_LIST_UPDATED = 'REQUEST_LIST_UPDATED'
export const REQUEST_LIST_ERROR = 'REQUEST_LIST_ERROR'
export const REQUEST_LIST_RESET_INITIAL_STATE =
  'REQUEST_LIST_RESET_INITIAL_STATE'
export const REQUEST_LIST_REMOVING = 'REQUEST_LIST_REMOVING'
export const REQUEST_LIST_RESTORING = 'REQUEST_LIST_RESTORING'
export const REQUEST_LIST_MARK_AS_READ = 'REQUEST_LIST_MARK_AS_READ'
export const REQUEST_LIST_MARK_AS_UNREAD = 'REQUEST_LIST_MARK_AS_UNREAD'
export const REQUEST_LIST_EXTRA_INITIATED = 'REQUEST_LIST_EXTRA_INITIATED'
export const REQUEST_LIST_META_INITIATED = 'REQUEST_LIST_META_INITIATED'
export const REQUEST_LIST_EXTRA_UPDATED = 'REQUEST_LIST_EXTRA_UPDATED'
export const REQUEST_LIST_META_UPDATED = 'REQUEST_LIST_META_UPDATED'
