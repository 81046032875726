// @flow

export const CAN_CHANGE_ROLE_PERMISSION_KEY = 'can_change_dweller_role'

export const CHECK_PROFILE_OPTION = 'checkProfile'
export const SENT_INVITATION_OPTION = 'sentInvitation'
export const CHANGE_ROLE_OPTION = 'changeRole'
export const REMOVE_OPTION = 'remove'
export const RESTORE_OPTION = 'restore'
export const BLOCK_OPTION = 'block'
export const UNBLOCK_OPTION = 'unblock'
export const APPROVE_SIGNUP_OPTION = 'approveSignup'

export const OPTIONS_PARAMS = {
  [CHECK_PROFILE_OPTION]: {
    icon: 'eye',
    translationKey: 'CheckProfile',
  },
  [SENT_INVITATION_OPTION]: {
    icon: 'forward',
    translationKey: 'SentInvitation',
  },
  [CHANGE_ROLE_OPTION]: {
    icon: 'pencil',
    translationKey: 'ChangeRole',
  },
  [REMOVE_OPTION]: {
    icon: 'bin',
    translationKey: 'Remove',
  },
  [RESTORE_OPTION]: {
    icon: 'restore',
    translationKey: 'Restore',
  },
  [BLOCK_OPTION]: {
    icon: 'permissions',
    translationKey: 'block',
  },
  [UNBLOCK_OPTION]: {
    icon: 'permissions',
    translationKey: 'unblock',
  },
  [APPROVE_SIGNUP_OPTION]: {
    icon: 'check',
    translationKey: 'ApproveSignup',
  },
}
