// @flow

import React, { useState, useEffect } from 'react'
import type { Node } from 'react'
import { useTranslation } from 'react-i18next'
import { Header, SimpleBlock } from '../Block'
import FinancialForm from './ProfileInformationForms/FinancialForm'
import SelectCustom from '../Select/SelectCustom'
import Icon from '../Icon'
import type { IFinancial, IModalName } from './ProfileData'

import styles from './Profile.module.scss'
import Notes from '../Notes'

type Props = {
  financial: IFinancial,
  setCurrentNoticeModal: (type: IModalName) => void,
  updateProfileFinancial: (data: {
    financial: IFinancial,
  }) => Promise<IFinancial>,
}

const ProfileFinancial = ({
  financial: { notes },
  financial,
  updateProfileFinancial,
  setCurrentNoticeModal,
}: Props): Node => {
  const { t } = useTranslation('Profile')
  const [isEdit, setIsEdit] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (!isOpen) {
      setIsEdit(false)
    }
  }, [isOpen])

  const handleEditOpen = () => {
    setIsEdit(true)
    setIsOpen(true)
  }
  const handleEditClose = () => setIsEdit(false)

  const options = [
    {
      value: 'addNotice',
      label: t('AddNotice'),
      onClick: () => setCurrentNoticeModal('financial'),
      icon: 'pencil',
    },
  ]

  return (
    <SimpleBlock>
      <Header overflowVisible header>
        <div className={styles.profileWrap}>
          <div>
            {t('ProfileFinancial')}
            {!isEdit && (
              <Icon
                colorOnHover
                style={{ width: 16, height: 16, paddingLeft: '8px' }}
                id='pencil'
                onClick={handleEditOpen}
              />
            )}
          </div>
          <div className={styles.profileOpenIcon}>
            <Icon
              style={{ fill: 'transparent' }}
              id={isOpen ? 'openSection' : 'closedSection'}
              onClick={() => setIsOpen(isOpen => !isOpen)}
            />
            <SelectCustom options={options} onChange={opt => opt.onClick()} />
          </div>
        </div>
      </Header>
      <FinancialForm
        isOpen={isOpen}
        initialValues={financial}
        active={isEdit}
        handleEditClose={handleEditClose}
        updateProfileFinancial={updateProfileFinancial}
      />
      <Notes notes={notes && notes.trim()} />
    </SimpleBlock>
  )
}
export default ProfileFinancial
