// @flow

import React from 'react'
import classnames from 'classnames'
import type { Node } from 'react'

import styles from './Close.module.scss'

import Icon from '../../../../static/icons/cross.svg'

type Props = {
  className?: string,
  large?: boolean,
  onClick: (SyntheticEvent<HTMLButtonElement>) => void,
  title?: string,
  working?: boolean,
}

const Close = (props: Props): Node => {
  const { className, working, title } = props

  const btnClass = classnames(
    styles.close,
    { [styles.large]: props.large },
    className
  )

  const buttonProps = Object.assign(
    {},
    !working && props.onClick ? { onClick: props.onClick } : undefined,
    title ? { title } : undefined
  )

  return (
    <button type='button' className={btnClass} {...buttonProps}>
      <Icon />
    </button>
  )
}

export default Close
