// @flow

import { getApi, postApi, deleteApi, patchApi } from './api.core'

export const getColors = params => getApi('api/colors/', params) // unused
export const getLabels = params =>
  getApi('api/label/', { ...params, page_size: 1000000 })
export const createLabel = params => postApi('api/label/', params)
export const patchLabel = (id, params) => patchApi(`api/label/${id}/`, params)
export const deleteLabel = id => deleteApi(`api/label/${id}/`)
