// @flow

import { v1 as uuid } from 'uuid'
import { concat } from 'lodash-es'

import { isImageFile, isMediaFile } from '../../../utils/file'
import { GREAT_PREVIEW_SIZE, MAX_ASPECT_RATIO } from './DocumentsView.constants'

const getItemsWithoutPreview = (
  files: Array<Object>,
  previewIndex: number
): Array<Object> => {
  const items = [...files]

  if (previewIndex >= 0) {
    items.splice(previewIndex, 1)
  }

  return items
}

const getRowsCounts = (length: number, min: number, max: number) => {
  const main = Math.floor(length / max)
  const rest = length % max

  const counts = concat(
    rest > 0 ? [rest] : [],
    Array.from({ length: main }, () => max)
  )

  if (counts.length > 1) {
    while (counts[0] < min) {
      const index = counts.findIndex(c => c > min)

      if (index >= 0) {
        ++counts[0]
        --counts[index]
      } else {
        break
      }
    }

    if (counts[0] < min) {
      counts.push(counts[0])
      counts.splice(0, 1)
    }
  }

  return counts
}

export const needFitContainPreview = (file: Object) => {
  const { width: w, height: h } = file

  return !w || !h || w / h < MAX_ASPECT_RATIO || w <= GREAT_PREVIEW_SIZE
}

export const getPreviewIndex = (files: Array<Object>) => {
  const media = files
    .filter(isMediaFile)
    .sort((a, b) => (isImageFile(a) && !isImageFile(b) ? -1 : 0))

  if (media.length) {
    const fitCover = media.find(f => !needFitContainPreview(f))
    const preview = fitCover || media[0]

    return files.findIndex(f => f.id === preview.id)
  }

  return -1
}

export const getRows = (
  files: Array<Object>,
  previewIndex: number,
  minLength: number,
  maxLength: number
) => {
  const items = getItemsWithoutPreview(files, previewIndex)

  const rows = []
  getRowsCounts(items.length, minLength, maxLength).reduce((value, count) => {
    rows.push({
      items: items.slice(value, value + count),
      key: uuid(),
    })

    return count + value
  }, 0)

  return rows
}

export const getPreviewSrc = (file: Object, thumbnailSize: number) => {
  const { width, origin, thumbnails } = file

  if (isImageFile(file)) {
    return width && width < GREAT_PREVIEW_SIZE
      ? origin
      : thumbnails[thumbnailSize] || origin
  }

  return thumbnails[thumbnailSize]
}
