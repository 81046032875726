// flow

export const USER = 'user'
export const BUILDING = 'building'
export const FLAT = 'flat'

export const PROFILE_VARIABLE_NAME = 'profile_id'
export const BUILDING_VARIABLE_NAME = 'building_id'
export const FLAT_VARIABLE_NAME = 'flat_id'
export const EMAIL_MESSAGE = 'email_message'
export const CHAT_MESSAGE = 'chat_message'
