// @flow

import React, { Component } from 'react'
import Stickyfill from 'stickyfilljs'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import type { Location, Match } from 'react-router-dom'
import { withRouter } from 'react-router-dom'

import WidgetParticipantsList from '../../components/widgets/WidgetParticipantsList/WidgetParticipantsList'
import WidgetFiles from '../../components/widgets/WidgetFiles/WidgetFiles'
import * as actions from '../../containers/Chatroom/Chatroom.actions'
import { getCurrentFilters } from '../../utils/routing'
import { isMenuCollapsed } from '../../utils/commonSelectors'
import { setWidget } from '../../containers/Widget/Widget.actions'
import Widgets, { WIDGET_TYPES } from './widgets'
import Lightbox from '../../components/LightboxGallery'
import { getActiveWidget } from '../Widget/Widget.selectors'

type Props = {
  collapsed: boolean,
  location: Location,
  match: Match,
  setWidget: string => void,
  t: string => string,
}

class MailWidgets extends Component<Props> {
  state = {
    lightboxIndex: null,
  }

  componentDidMount() {
    const elements = document.querySelectorAll('.widget__container--content')
    Stickyfill.add(elements)
  }

  componentWillUnmount() {
    this.props.setWidget(null)
  }

  isNew = () => {
    const {
      match: { params },
    } = this.props

    return params.uuid === 'create'
  }

  isArchived = () => {
    const { location } = this.props

    const filters = getCurrentFilters(location)

    return !!(filters.archived && JSON.parse(filters.archived))
  }

  setFileIndex = ind => {
    this.setState({ lightboxIndex: ind })
  }

  closeLightbox = () => {
    this.setState({ lightboxIndex: null })
  }

  render() {
    const {
      match: { params },
      collapsed,
      files,
      isSingle,
      activeWidget,
    } = this.props

    if (this.isNew()) {
      return <Widgets collapsed={collapsed}></Widgets>
    }

    const { lightboxIndex } = this.state

    return (
      <>
        <Widgets collapsed={collapsed}>
          <WidgetParticipantsList
            isMail
            isSingle={isSingle}
            params={params}
            archived={this.isArchived()}
            isActive={activeWidget === WIDGET_TYPES.participants}
          />
          <WidgetFiles setFileIndex={this.setFileIndex} />
        </Widgets>
        <Lightbox
          newGallery
          activeIndex={lightboxIndex}
          images={files}
          scope={'mail'}
          onClose={this.closeLightbox}
        />
      </>
    )
  }
}

const mapStateToProps = state => {
  const { chatroomInfo } = state.chatroom
  const permissions = chatroomInfo ? chatroomInfo.permissions : {}

  return {
    permissions,
    ...state.WidgetFiles,
    isEmailChat: chatroomInfo && chatroomInfo.from_email,
    collapsed: isMenuCollapsed(state),
    activeWidget: getActiveWidget(state),
  }
}

const mapDispatchToProps = {
  openAddMembersPopUp: actions.openAddMembersPopUp,
  openLabelsPopUp: actions.openLabelsPopUp,
  openConvertToRequestPopUp: actions.openConvertToRequestPopUp,
  setWidget,
}

export default compose(
  withTranslation('WidgetsGeneral'),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(MailWidgets)
