// @flow

import { get, findKey, map, partial, isEqual } from 'lodash-es'

import {
  BLOCKED_STATUS,
  IMPORTED_STATUS,
  INVITED_STATUS,
  NOT_CONFIRMED_STATUS,
  USER_STATUS,
} from '../../../../../../constants'
import { CAN_CHANGE_ROLE_PERMISSION_KEY } from './UserListItem.constants'

type User = {
  permissions: { [string]: boolean },
  status: boolean,
}

export function getUserStatusKey(statusValue) {
  return parseInt(findKey(USER_STATUS, partial(isEqual, statusValue)))
}

export function canReinvite(user: User) {
  const { status } = user
  const userStatus = USER_STATUS[status]

  return (
    userStatus === IMPORTED_STATUS ||
    userStatus === INVITED_STATUS ||
    userStatus === BLOCKED_STATUS
  )
}

export function canChangeRole(user: User, loggedUser: User) {
  return (
    get(user, ['permissions', CAN_CHANGE_ROLE_PERMISSION_KEY]) &&
    get(loggedUser, ['permissions', CAN_CHANGE_ROLE_PERMISSION_KEY])
  )
}

export function canRemove(user: User) {
  return get(user, ['permissions', 'can_delete'])
}

export function canBlock(user: User) {
  const { status } = user

  return (
    get(user, ['permissions', 'can_block_user']) &&
    USER_STATUS[status] !== BLOCKED_STATUS
  )
}

export function canUnblock(user: User) {
  const { status } = user

  return (
    get(user, ['permissions', 'can_unblock_user']) &&
    USER_STATUS[status] === BLOCKED_STATUS
  )
}

export function canEditStatus(user: User) {
  return canBlock(user) || canUnblock(user)
}

export function canApprove(user: User) {
  const { status } = user

  return (
    get(user, ['permissions', 'can_edit']) &&
    USER_STATUS[status] === NOT_CONFIRMED_STATUS
  )
}

export function getStatusOptions(statuses, t) {
  return map(statuses, (val, label) => ({
    code: val,
    value: label,
    label: t(`Common:${label}`),
  }))
}
