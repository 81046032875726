// @flow

import { uniqBy } from 'lodash-es'

import * as actions from './WidgetParticipantsList.actionTypes'

const initialState = {
  working: false,
  error: null,
  data: [],
}

export default function widgetParticipantsListReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case actions.WIDGET_PARTICIPANTS_LIST_INITIATING:
    case actions.WIDGET_REQUEST_PARTICIPANTS_LIST_INITIATING:
    case actions.WIDGET_MAIL_PARTICIPANTS_LIST_INITIATING:
      return {
        ...state,
        working: true,
      }
    case actions.WIDGET_CHAT_PARTICIPANTS_LIST_INITIATED:
      return {
        ...state,
        data: uniqBy(action.data.concat(state.data), 'id'),
        working: false,
      }
    case actions.WIDGET_PARTICIPANTS_LIST_ERROR:
      return {
        ...state,
        working: false,
        error: action.error,
      }
    case actions.UPDATE_PARTICIPANTS_LIST:
      return {
        ...state,
        data: uniqBy(action.data.concat(state.data), 'id'),
      }

    case actions.CLEAR_WIDGET_PARTICIPANTS_LIST:
      return initialState
    default:
      return state
  }
}
