// @flow

import {
  CHATROOMS_RESET_INITIAL_STATE,
  CHATROOMS_INITIATING,
  CHATROOMS_INITIATED,
  CHATROOMS_ERROR,
  CHATROOMS_GET_FILES_INITIATING,
  CHATROOMS_GET_FILES_INITIATED,
  CHATROOMS_GET_FILES_ERROR,
  CHATROOMS_MARK_AS_READ,
  CHATROOMS_MARK_AS_UNREAD,
} from './Messages.constants'

type ChatroomsResetInitialStateAction = { type: string }

export function chatroomsResetInitialState(): ChatroomsResetInitialStateAction {
  return {
    type: CHATROOMS_RESET_INITIAL_STATE,
  }
}

type ChatroomsInitiatingAction = { page: number, type: string }

export function chatroomsInitiating(params: Object): ChatroomsInitiatingAction {
  return {
    type: CHATROOMS_INITIATING,
    params,
  }
}

type Chatrooms = Array<{
  title: string | null,
  users: Array<number>,
  uuid: string,
}>

type Meta = {
  count: number,
  curr_page: number,
  limit: number,
  offset: number,
  page_count: number,
}
type Permissions = {
  can_create: boolean,
  can_delete: boolean,
}
type ChatroomsInitiatedAction = { chatrooms: Chatrooms, type: string }

export function chatroomsInitiated(
  chatrooms: Chatrooms,
  meta: Meta,
  permissions: Permissions
): ChatroomsInitiatedAction {
  return {
    type: CHATROOMS_INITIATED,
    chatrooms,
    meta,
    permissions,
  }
}

type ChatroomsErrorAction = { error: string, type: string }

export function chatroomsError(error: string): ChatroomsErrorAction {
  return {
    type: CHATROOMS_ERROR,
    error,
  }
}

// ============ Get files from gallery associated with chatroom ==============

type ChatroomsGetFilesInitiatingAction = {
  fileIdToBeShown: number,
  type: string,
  uuid: string,
}

export function chatroomsGetFilesInitiating(
  fileIdToBeShown,
  uuid: string,
  params
): ChatroomsGetFilesInitiatingAction {
  return {
    type: CHATROOMS_GET_FILES_INITIATING,
    fileIdToBeShown,
    uuid,
    params,
  }
}

type File = {
  created: string,
  filename: ?string,
  id: number,
  mime_type: string,
  origin: string,
  owner: number,
  thumbnail: ?string,
  thumbnails: ?{
    '130': string,
    '170': string,
    '698': string,
  },
}
type ChatroomsGetFilesInitiatedAction = {
  fileIdToBeShown: number,
  files: Array<File>,
  meta: Meta,
  type: string,
}

export function chatroomsGetFilesInitiated(
  fileIdToBeShown: number,
  files: Array<File>,
  meta: Meta
): ChatroomsGetFilesInitiatedAction {
  return {
    type: CHATROOMS_GET_FILES_INITIATED,
    fileIdToBeShown,
    files,
    meta,
  }
}

type ChatroomsGetFilesErrorAction = { error: string, type: string }

export function chatroomsGetFilesError(
  chatrooms: Chatrooms,
  error: string
): ChatroomsGetFilesErrorAction {
  return {
    type: CHATROOMS_GET_FILES_ERROR,
    error,
  }
}

export function markAsRead(ids, params) {
  return {
    type: CHATROOMS_MARK_AS_READ,
    ids,
    params,
  }
}

export function markAsUnread(ids, params) {
  return {
    type: CHATROOMS_MARK_AS_UNREAD,
    ids,
    params,
  }
}
