// @flow

import React, { useState } from 'react'
import type { Node } from 'react'

import { getInsuranceType } from '../../core/api/api.insurance'
import { useLoad } from '../../hooks'
import InsuranceTypeModal from './InsuranceTypeModal'
import InsuranceTypeTable from './InsuranceTypeTable'

const InsuranceType = (): Node => {
  const [isModalOpen, setModalOpen] = useState(false)
  const [editingInsuranceType, setEditingInsuranceType] = useState(null)
  const [insuranceTypes, isLoading, , load] = useLoad(getInsuranceType)

  const handleClose = () => setModalOpen(false)
  const handleCreate = () => setModalOpen(true)

  return (
    <>
      {insuranceTypes && (
        <>
          <InsuranceTypeTable isLoading={isLoading} onEdit={handleCreate} />
          {isModalOpen && (
            <InsuranceTypeModal
              load={load}
              editingInsuranceType={editingInsuranceType}
              setEditingInsuranceType={setEditingInsuranceType}
              onClose={handleClose}
            />
          )}
        </>
      )}
    </>
  )
}

export default InsuranceType
