// @flow

import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import styles from './RemoveProfileConfirm.module.scss'

import AuthPage from '../AuthPage'
import Button from '../../Button'
import Modal from '../../Modal'
import Loader from '../../Loader'
import ConfirmationPopup from '../../modals/ConfirmationPopup'

import {
  removeProfile,
  removeProfileTokenCheck,
} from '../../../core/api/api.profile'

const RemoveProfileConfirm = () => {
  const { token } = useParams()
  const [working, setWorking] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [modal, setModal] = useState(null)
  const { t } = useTranslation('ConfirmRemove')

  useEffect(() => {
    if (token) {
      setWorking(true)
      removeProfileTokenCheck({ token })
        .then(() => {})
        .catch(() => setError(true))
        .finally(() => setWorking(false))
    }
  }, [token])

  const confirmRemove = () => {
    setWorking(true)
    removeProfile({ token })
      .then(data => {
        if (data.detail) {
          setSuccess(true)
        }
      })
      .catch(() => showModal())
      .finally(() => setWorking(false))
  }

  const showModal = () => {
    setModal(
      <ConfirmationPopup
        title={t('ErrorTitle')}
        text={t('Error')}
        showOk={false}
        onClose={hideModal}
      />
    )
  }

  const hideModal = () => setModal(null)

  const renderContent = () => {
    if (working) {
      return <Loader text={false} type='big' />
    }

    if (!token || error) {
      return <div className={styles.title}>{t('NoToken')}</div>
    }

    if (success) {
      return <div className={styles.title}>{t('Success')}</div>
    }

    return (
      <>
        <div className={styles.title}>{t('ConfirmTitle')}</div>
        <div className={styles.text}>{t('ConfirmText')}</div>
        <Button.Save className='btn' disabled={working} onClick={confirmRemove}>
          {t('Remove')}
        </Button.Save>
      </>
    )
  }

  return (
    <AuthPage working={working}>
      <div className={styles.content}>{renderContent()}</div>
      {!!modal && (
        <Modal
          contentLabel=''
          className='Modal__Bootstrap modal-dialog'
          isOpen={!!modal}
          onRequestClose={hideModal}
        >
          {modal}
        </Modal>
      )}
    </AuthPage>
  )
}

export default RemoveProfileConfirm
