// @flow

import React, { useState, useEffect } from 'react'
import { Page } from 'react-pdf'
import { get } from 'lodash-es'

import usePanZoom from 'use-pan-and-zoom'

import Arrow from '../Button/Arrow'

import styles from './MappedDocument.module.scss'
import classnames from 'classnames'

const PageContainer = props => {
  const { pageCount, width, height, scalable = false, showGoto } = props
  const [page, setPage] = useState(1)
  const [scale, setScale] = useState(1)
  const [gotoInput, setGotoInput] = useState(1)

  const { transform, panZoomHandlers, setContainer, setPan } = usePanZoom({
    panOnDrag: false,
    requireCtrlToZoom: true,
    minZoom: 1,
    maxZoom: 1,
    maxX: 0,
    maxY: 100,
    minX: -0,
    minY: -100,
  })

  const handleClickForward = () => setPage(page + 1)
  const handleClickBack = () => setPage(page - 1)

  useEffect(() => {
    if (props.page) {
      setPage(props.page)
    }
  }, [props.page])

  useEffect(() => {
    if (props.setPage) {
      props.setPage(page)
    }

    setGotoInput(page)

    if (scalable) {
      resetZoom()
    }
  }, [page])

  const handleLoadSuccess = e => {
    if (props.setOriginWidth) {
      const width = get(e, ['_pageInfo', 'view', 2], 595)

      props.setOriginWidth(width)
    }
  }

  const handleChangeGoto = e => {
    let val = e.target.value
    val = val.replace(/[^0-9]/g, '')

    if (val == '0') {
      val = 1
    } else if (+val > pageCount) {
      val = pageCount
    }

    setGotoInput(val)
  }

  const gotoPage = e => {
    if (e.which === 13) {
      setPage(+e.target.value || 1)
    }
  }

  const toggleZoom = () => {
    if (scale === 1) {
      setScale(1.5)
    } else {
      resetZoom()
    }
  }

  const resetZoom = () => {
    setScale(1)
    setPan({ x: 0, y: 0 })
  }

  const isDefaultScale = scale === 1

  let zoomStyle = {}

  if (scalable && !isDefaultScale) {
    zoomStyle = { transform }
  }

  return (
    <>
      <div onClick={scalable && toggleZoom}>
        <div ref={el => setContainer(el)} {...panZoomHandlers}>
          <div style={zoomStyle}>
            <Page
              pageNumber={page}
              width={width}
              scale={scale}
              className={classnames({
                [styles.scalable]: scalable,
                [styles.zoom]: !isDefaultScale,
              })}
              onLoadSuccess={handleLoadSuccess}
            />
          </div>
        </div>
      </div>
      {isDefaultScale && page > 1 && (
        <Arrow style={{ top: 'calc(50% - 20px)' }} onClick={handleClickBack} />
      )}
      {isDefaultScale && pageCount > page && (
        <Arrow
          forward
          style={{ top: 'calc(50% - 20px)' }}
          onClick={handleClickForward}
        />
      )}
      {isDefaultScale && pageCount > 1 && showGoto && (
        <div className={styles.goto}>
          <input
            type='text'
            value={gotoInput}
            style={{
              width: `${height / 30}px`,
              height: `${height / 40}px`,
              fontSize: `${height / 50}px`,
              textAlign: 'right',
              border: '1px #8e8e8f solid',
              marginRight: '1px',
              backgroundColor: 'white',
            }}
            onKeyDown={gotoPage}
            onChange={handleChangeGoto}
          />
          <span
            style={{
              height: `${height / 40}px`,
              fontSize: `${height / 50}px`,
              color: '#8e8e8f',
            }}
          >
            /{pageCount}
          </span>
        </div>
      )}
    </>
  )
}

export default PageContainer
