//@flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import type Node from 'react'

import Modal from '../../Modal'
import ModalBody from '../../Modal/ModalBody'
import ModalCloseButton from '../../Modal/ModalCloseButton'
import ModalHead from '../../Modal/ModalHead'
import Button from '../../Button'

import { useOverflow } from '../../../hooks'
import { useThemeColor } from '../../../hooks'

import styles from '../SelectTemplateModal/SelectTemplateModal.module.scss'

type Props = {
  isOpen: boolean,
  onAnalyzeDoc: () => void,
  onAnalyzeEmail: () => void,
  onClose: () => void,
}

const SelectAnalyzeTemplateModal = (props: Props): Node => {
  const { isOpen, onClose, onAnalyzeEmail, onAnalyzeDoc } = props
  const { t } = useTranslation('Mail')
  useOverflow()
  const themeColor = useThemeColor()

  return (
    <>
      <Modal isOpen={isOpen} onRequestClose={onClose}>
        <ModalHead title={t('SelectAnalyzeTemplateTitle')} />
        <ModalCloseButton onClose={onClose} />
        <ModalBody
          className={styles.selectTemplate}
          style={{
            '--themeColor': themeColor,
          }}
        >
          <p>{t('SelectAnalyzeTemplateText')}</p>
          <div className={styles.actions}>
            <div onClick={onAnalyzeEmail}>{t('AnalyzeEmailTemplate')}</div>
            <div onClick={onAnalyzeDoc}>{t('AnalyzeDocTemplate')}</div>
          </div>
        </ModalBody>
        <Button.Cancel className={styles.button} onClick={onClose}>
          {t('CancelButton')}
        </Button.Cancel>
      </Modal>
    </>
  )
}

export default SelectAnalyzeTemplateModal
