// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import NewTableBody from '../../NewTable/NewTableBody'
import NewTableHeader from '../../NewTable/NewTableHeader'
import NewTableHeaderRow from '../../NewTable/NewTableHeaderRow'
import NewTableHeaderCell from '../../NewTable/NewTableHeaderCell'
import NewTable from '../../NewTable'
import ActivitiesTableRow from './ActivitiesTableRow'

type Props = { activities: Array<Object>, onClick: Object => void }

const ActivitiesTable = (props: Props): Node => {
  const { activities } = props

  const { t } = useTranslation('Activities')

  return (
    <NewTable>
      <NewTableHeader>
        <NewTableHeaderRow>
          <NewTableHeaderCell
            title={t('ActivitiesDone')}
            style={{ width: '10%' }}
          />
          <NewTableHeaderCell
            sortKey={'title'}
            title={t('ActivitiesTitle')}
            style={{ width: '20%' }}
          />
          <NewTableHeaderCell
            sortKey={'manager'}
            title={t('ActivitiesManager')}
            style={{ width: '15%' }}
          />
          <NewTableHeaderCell
            sortKey={'request'}
            title={t('ActivitiesRequest')}
            style={{ width: '15%' }}
          />
          <NewTableHeaderCell
            sortKey={'priority'}
            title={t('ActivitiesPriority')}
            style={{ width: '15%' }}
          />
          <NewTableHeaderCell
            sortKey={'deadline'}
            title={t('ActivitiesDeadline')}
            style={{ width: '10%' }}
          />
          <NewTableHeaderCell
            sortKey={'building'}
            title={t('ActivitiesBuilidng')}
            style={{ width: '15%' }}
          />
        </NewTableHeaderRow>
      </NewTableHeader>
      <NewTableBody>
        {activities.map(activity => {
          return (
            <ActivitiesTableRow
              activity={activity}
              key={activity.id}
              onClick={props.onClick}
            />
          )
        })}
      </NewTableBody>
    </NewTable>
  )
}

export default ActivitiesTable
