// @flow

import React from 'react'

import styles from './TextInput.module.scss'

type Props = {
  disabled: boolean,
  onClick: () => {},
  placeholder: ?string,
  value: any,
}

const TextInput = (props: Props) => {
  const { placeholder, value, disabled } = props

  return (
    <input
      readOnly
      disabled={disabled}
      className={styles.input}
      type='text'
      placeholder={placeholder}
      value={value}
      onClick={props.onClick}
    />
  )
}

TextInput.defaultProps = {
  disabled: false,
}

export default TextInput
