// @flow

import React, { Component } from 'react'
import { range } from 'lodash-es'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import ClassNames from 'classnames'
import { Link } from 'react-router-dom'
import { push } from 'connected-react-router'

import { getLocation } from '../../../utils/commonSelectors'
import {
  getCurrentFilters,
  getNewQueryString,
  formatPathname,
  getCurrentPage,
} from '../../../utils/routing'

type Props = {
  classes: string,
  meta: Object,
  nodeToScroll: any,
  setPage: () => void,
  t: string => string,
}
type State = {
  inval: number,
}

function getItems(count, current) {
  let items = range(1, count + 1)

  if (count > 7) {
    let startArr = current - 1
    let endArr = current + 2
    let startClose = false
    let endClose = false

    if (startArr <= 3) {
      startArr = 3
      startClose = true
    }

    if (endArr > count - 2) {
      endArr = count - 1
      endClose = true
    }

    const middleArr = range(startArr, endArr)
    items = [1, 2]

    if (startClose) {
      items = items.concat(middleArr)
    } else {
      items = items.concat(['..']).concat(middleArr)
    }

    if (endClose) {
      items = items.concat([count - 1, count])
    } else {
      items = items.concat(['..']).concat([count - 1, count])
    }
  }

  return items
}

class BottomPagination extends Component<Props, State> {
  state = {
    inval: 1,
  }

  componentDidUpdate(prevProps: Props): void {
    const prevPage = getCurrentPage(prevProps.location)
    const page = getCurrentPage(this.props.location)

    if (prevPage !== page && page === '1') {
      this.setState({ inval: 1 })
    }
  }

  onInputChange(e) {
    let nval = parseInt(e.target.value)

    if (nval === 0 || isNaN(nval)) {
      nval = ''
    }

    this.setState({ inval: nval })
  }

  moveToPage() {
    const { setPage, nodeToScroll } = this.props
    let { inval } = this.state

    if (inval > this.props.meta.page_count) {
      inval = this.props.meta.page_count
    }

    if (inval < 1) {
      inval = 1
    }

    if (setPage) {
      setPage(inval, nodeToScroll)
    } else {
      this.props.dispatch(push(this.getNextLink(inval)))
    }
  }

  renderItem = (item, key) => {
    const {
      setPage,
      meta: { curr_page: page },
      nodeToScroll,
    } = this.props

    if (item === '..') {
      return (
        <span key={key} className='paginator-2__item'>
          ...
        </span>
      )
    }

    const itemClass = ClassNames('paginator-2__item', {
      'paginator-2__item--current': item === page,
    })

    if (setPage) {
      return (
        <a
          className={itemClass}
          key={key}
          onClick={() => setPage(item, nodeToScroll)}
        >
          {item}
        </a>
      )
    }

    return (
      <Link className={itemClass} key={key} to={this.getNextLink(item)}>
        {item}
      </Link>
    )
  }

  getNextLink = page => {
    const { location } = this.props

    const { pathname } = location
    const filters = getCurrentFilters(location)
    const search = getNewQueryString({ ...filters, page })

    return `/${formatPathname(pathname)}/?${search}`
  }

  render() {
    const { meta, classes, t } = this.props

    if (!meta) return null

    const { curr_page: page, page_count: count } = meta

    if (count <= 1) {
      return null
    }

    const items = getItems(count, page)

    return (
      <nav className={classes}>
        {items.map((item, key) => this.renderItem(item, key))}
        <div className='paginator-2__extra'>
          <span className='paginator-2__01'>{t('MoveToPage')}</span>
          <div className='paginator-2__02 input input--medium input--default input--center'>
            <input
              type='text'
              title={t('PageNumber')}
              value={this.state.inval}
              onChange={e => this.onInputChange(e)}
            />
          </div>
          <button
            type='button'
            className='paginator-2__03 button button--medium button--success-2'
            onClick={() => this.moveToPage()}
          >
            {t('Move')}
          </button>
        </div>
      </nav>
    )
  }
}

const mapStateToProps = state => ({
  location: getLocation(state),
})

export default compose(
  withTranslation('BottomPagination'),
  connect(mapStateToProps)
)(BottomPagination)
