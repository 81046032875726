// @flow

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { compose } from 'redux'

import ConfirmationPopup from '../modals/ConfirmationPopup'
import Modal from '../Modal'
import * as actions from './WarningStripe.actionTypes'

class WarningStripe extends Component {
  showConfirmModal = () => {
    const { t, dispatch } = this.props
    dispatch({
      type: actions.SHOW_MODAL,
      modal: (
        <ConfirmationPopup
          title={t('PopupTitle')}
          text={t('PopupText')}
          onHide={this.hideModal}
          onClose={this.hideModal}
          onOk={this.deleteDemoData}
        />
      ),
    })
  }

  hideModal = () => {
    this.props.dispatch({ type: actions.SHOW_MODAL, modal: null })
  }

  deleteDemoData = () => {
    if (this.props.working) {
      return
    }

    this.props.dispatch({ type: actions.CONFIRM_DELETE })
    this.props.dispatch(push('/'))
  }

  render() {
    const { modal, t, working, completed, alreadyDeleted } = this.props

    if (completed || alreadyDeleted) {
      return null
    }

    return (
      <div className='warning-stripe warning-stripe__bottom'>
        <div
          className='action-text'
          dangerouslySetInnerHTML={{ __html: t('ClearDemoDataText') }}
        />
        <div className='action-button'>
          <button
            type='button'
            className='button button--success button--block button--large'
            onClick={this.showConfirmModal}
          >
            {working && <span className='button__spinner' />}{' '}
            {t('Common:Delete')}
          </button>
        </div>
        <Modal
          contentLabel=''
          className='Modal__Bootstrap modal-dialog'
          isOpen={!!modal}
          onRequestClose={this.hideModal}
        >
          {modal}
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state.demoDataStripe,
  alreadyDeleted: state.init.uk.is_demo_data_deleted,
})

export default compose(
  withTranslation('WarningStripe'),
  connect(mapStateToProps)
)(WarningStripe)
