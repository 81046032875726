// @flow

import React from 'react'
import classnames from 'classnames'
import type { Node } from 'react'

type Props = {
  children: Node,
  isRight: boolean,
}

const ToolboxCell = (props: Props): Node => {
  const { children, isRight } = props

  const cellClass = classnames('toolbox__cell', {
    'toolbox__cell--right': isRight,
  })

  return <div className={cellClass}>{children}</div>
}

ToolboxCell.defaultProps = {
  isRight: false,
}

export default ToolboxCell
