// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import type { Node } from 'react'

import { getMessageText } from '../ThreadsList.utils'

import styles from './ThreadsListItemSubject.module.scss'

type Props = {
  bold: boolean,
  lastMessage?: ?Object,
  title: string,
  totalMsgCount: number,
}

const ThreadsListItemSubject = (props: Props): Node => {
  const { lastMessage, title, bold, totalMsgCount } = props

  const { t } = useTranslation('Mail')

  const lastMessageText = getMessageText(lastMessage)

  const subjectClass = classnames(styles.subject, { [styles.bold]: bold })

  return (
    <div className={styles.container}>
      <span className={subjectClass}>{title}</span>
      <span className={styles.divider}>&ndash;</span>
      <span className={styles.text}>{lastMessageText || t('NoMail')}</span>
      {!!totalMsgCount && (
        <span className={styles.count}>({totalMsgCount})</span>
      )}
    </div>
  )
}

export default ThreadsListItemSubject
