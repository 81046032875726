// @flow

import { postApi, getApi, patchApi, deleteApi, patchRaw } from './api.core'

export const getContacts = (profileId, modelId) =>
  getApi(`api/contact/list/?model=${modelId}&fk=${profileId}`)
export const getTypes = () => getApi('api/contact-type/')
export const deleteContact = contactId =>
  deleteApi(`api/contact/${contactId}/`, { id: contactId })
export const createContact = credentials => postApi('api/contact/', credentials)
export const updateContact = (contactId, credentials) =>
  patchApi(`api/contact/${contactId}/`, credentials)

export const getExternalContacts = profileId =>
  getApi(`api/profile/${profileId}/profile-contact/`)
export const deleteExternalContact = contactId =>
  deleteApi(`api/profile-contact/${contactId}/`)
export const createExternalContact = (profileId, params) =>
  postApi(`api/profile/${profileId}/profile-contact/`, params)
export const updateExternalContact = (contactId, params) =>
  patchRaw(`api/profile-contact/${contactId}/`, params)
