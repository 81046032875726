// @flow

import React from 'react'

import classnames from 'classnames'

import Button from '../../../Button'

type Props = {
  collapse: () => void,
  edit: () => void,
  isShowPencil: boolean,
  open: boolean,
  title: string,
}

const AddressSectionHead = ({
  title,
  open,
  isShowPencil,
  collapse,
  edit,
}: Props): React.Element => {
  const headerClass = classnames('unit__title', {
    'unit__title-collapse-open': open,
  })

  return (
    <h2 className={headerClass} onClick={collapse}>
      {title}
      {isShowPencil && <Button.Edit onClick={edit} />}
    </h2>
  )
}

export default AddressSectionHead
