//@flow

import { all, put, call, fork, takeEvery } from 'redux-saga/effects'

import api from '../../../core/api'
import { serverError } from '../../Layout/Layout.actions'

import {
  FLAT_DWELLERS_INITIATING,
  FLAT_DWELLERS_INITIATED,
  FLAT_DWELLERS_ERROR,
  FLAT_DWELLERS_UPDATE_ROLE,
  FLAT_DWELLERS_UPDATED_ROLE,
  FLAT_DWELLERS_REMOVE,
  FLAT_DWELLERS_LOAD_ONE,
  FLAT_DWELLERS_UPDATE,
  FLAT_DWELLERS_LOAD,
} from './FlatDwellers.actionTypes'

function* watchLoad() {
  yield takeEvery(FLAT_DWELLERS_INITIATING, loadTeam)
}

function* watchFilterLoad() {
  yield takeEvery(FLAT_DWELLERS_LOAD, loadTeam)
}

function* watchUpdateRole() {
  yield takeEvery(FLAT_DWELLERS_UPDATE_ROLE, updateRole)
}

function* watchMassRemove() {
  yield takeEvery(FLAT_DWELLERS_REMOVE, removeUsers)
}

function* watchLoadOne() {
  yield takeEvery(FLAT_DWELLERS_LOAD_ONE, loadOne)
}

function* loadTeam({ params }) {
  try {
    const data = yield call(api.profile.getListTiny, params)
    yield put({ ...data, type: FLAT_DWELLERS_INITIATED })
  } catch (error) {
    yield put(serverError(error))
    yield put({ type: FLAT_DWELLERS_ERROR, error })
  }
}

function* updateRole(action) {
  const { owner, group } = action
  const result = yield call(api.profile.update, owner, { group })
  yield put({ type: FLAT_DWELLERS_UPDATED_ROLE, result })
}

function* removeUsers({ massParams, listParams }) {
  try {
    yield call(api.massAction.updateRequest, {
      model: 'Profile',
      ...massParams,
    })
    const data = yield call(api.profile.getListTiny, listParams)
    yield put({ ...data, type: FLAT_DWELLERS_INITIATED })
  } catch (error) {
    yield put(serverError(error))
    yield put({ type: FLAT_DWELLERS_ERROR, error })
  }
}

function* loadOne({ id }) {
  try {
    const user = yield call(api.profile.getProfileTiny, id, {
      get_permissions: 1,
    })
    yield put({
      type: FLAT_DWELLERS_UPDATE,
      results: { objects: [user] },
      meta: {
        count: 1,
        curr_page: 1,
        limit: 20,
        offset: 0,
        page_count: 1,
      },
    })
  } catch (error) {
    yield put(serverError(error))
    yield put({ type: FLAT_DWELLERS_ERROR, error })
  }
}

export default function* watch() {
  yield all([
    fork(watchLoad),
    fork(watchFilterLoad),
    fork(watchUpdateRole),
    fork(watchMassRemove),
    fork(watchLoadOne),
  ])
}
