// @flow

import React, { useState } from 'react'
import type { Node } from 'react'
import styles from './EmailIntegrationModal.module.scss'
import Modal from '../../Modal'
import {
  INITIAL_STAGE,
  ERROR_REQUEST_STAGE,
  SELECT_PROVIDER_STAGE,
  MICROSOFT_OUTLOOK_STAGE,
  MICROSOFT_EXCHANGE_STAGE,
  MICROSOFT_OFFICE_STAGE,
  OTHER_STAGE,
} from './EmailIntegrationModal.constant'
import {
  providers,
  MICROSOFT_OUTLOOK,
  MICROSOFT_EXCHANGE,
  MICROSOFT_OFFICE,
  OTHER,
} from './Providers/Providers.constant'
import ModalCloseButton from '../../Modal/ModalCloseButton'
import CheckEmailModal from './CheckEmailModal'
import SelectProviderModal from './SelectProviderModal'
import ErrorModal from './ErrorModal'
import MicrosoftModal from './MicrosoftModal'
import OfficeModal from './OfficeModal'
import OtherAccountModal from './OtherAccountModal'

type Props = {
  handleCancel: Function,
  isPrivate?: boolean,
}

const EmailIntegrationModal = (props: Props): Node => {
  const { handleCancel, isPrivate, error } = props
  const [errorRequest, setErrorRequest] = useState(error)
  const [stage, setStage] = useState(
    error ? ERROR_REQUEST_STAGE : INITIAL_STAGE
  )

  return (
    <Modal isOpen className={styles.modalWrapper}>
      <ModalCloseButton onClose={handleCancel} />
      {stage === INITIAL_STAGE && (
        <CheckEmailModal
          isPrivate={isPrivate}
          setStage={setStage}
          setErrorRequest={setErrorRequest}
          onClose={handleCancel}
        />
      )}
      {stage === ERROR_REQUEST_STAGE && (
        <ErrorModal error={errorRequest} onClose={handleCancel} />
      )}
      {stage === SELECT_PROVIDER_STAGE && (
        <SelectProviderModal setStage={setStage} onClose={handleCancel} />
      )}
      {stage === MICROSOFT_OUTLOOK_STAGE && (
        <MicrosoftModal
          setStage={setStage}
          provider={providers[MICROSOFT_OUTLOOK]}
          onClose={handleCancel}
        />
      )}
      {stage === MICROSOFT_EXCHANGE_STAGE && (
        <MicrosoftModal
          setStage={setStage}
          provider={providers[MICROSOFT_EXCHANGE]}
          onClose={handleCancel}
        />
      )}
      {stage === MICROSOFT_OFFICE_STAGE && (
        <OfficeModal
          setStage={setStage}
          provider={providers[MICROSOFT_OFFICE]}
          onClose={handleCancel}
        />
      )}
      {stage === OTHER_STAGE && (
        <OtherAccountModal
          setStage={setStage}
          provider={providers[OTHER]}
          onClose={handleCancel}
        />
      )}
    </Modal>
  )
}

export default EmailIntegrationModal
