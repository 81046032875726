// @flow

import { get, dropRight, dropWhile, partial } from 'lodash-es'
import type { Match } from 'react-router-dom'

type Props = {
  match: Match,
}

const KB_SIZE_SUFFIX = 'K'
const MB_SIZE_SUFFIX = 'M'
const DENOMINATOR = 1024
const SIGN_QUANTITY = 2

export function getHumanFileSize(fileSize?: number) {
  if (!fileSize) {
    return ''
  }

  if (fileSize <= DENOMINATOR) {
    return `1${KB_SIZE_SUFFIX}`
  }

  const kbSize = fileSize / DENOMINATOR

  if (kbSize <= DENOMINATOR) {
    const fixedSize = Math.min(kbSize.toString().length - 1, SIGN_QUANTITY)

    return `${kbSize.toFixed(fixedSize)}${KB_SIZE_SUFFIX}`
  }

  const mbSize = kbSize / DENOMINATOR

  const fixedSize = Math.min(mbSize.toString().length - 1, SIGN_QUANTITY)

  return `${mbSize.toFixed(fixedSize)}${MB_SIZE_SUFFIX}`
}

function getUrl(props) {
  return get(props, ['match', 'url'])
}

export function getSlash(props: Props) {
  const url = getUrl(props)

  return url[url.length - 1] === '/' ? '' : '/'
}

function getUrlArray(props) {
  const url = getUrl(props)

  return dropRight(url.split('/'), 2)
}

export function getUrlByFolderId(id: number, props: Props) {
  const slash = getSlash(props)
  const urlArray = getUrlArray(props)

  return `${urlArray.join('/')}/${slash}${id}/?page=1`
}

function isNotCurrentDirectory(id, directoryId) {
  return id !== parseInt(directoryId)
}

export function getRelatedPathList(
  path: Array<Array<number>>,
  directoryId?: string
) {
  if (!directoryId) {
    return path
  }

  const checkDirectory = partial(isNotCurrentDirectory, directoryId)

  return dropWhile(path, checkDirectory)
}
