// @flow

import React, { Component } from 'react'
import type { Node } from 'react'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'
import Row from './TableItem'

import Table from '../Table'

type Props = {
  allSelected?: boolean,
  EmptyList: Node,
  filter: Object,
  items: Array<Object>,
  onDeleteFromGroup?: number => void,
  onSort: Object => void,
  onToggleItem: number => void,
  selectedIds: Array<number>,
  showExtra?: boolean,
  sortManagers?: boolean,
  working: boolean,
}

class TableList extends Component {
  props: Props

  renderItem = item => {
    const {
      allSelected,
      selectedIds,
      onToggleItem,
      archived,
      permissions,
      showExtra,
      onDeleteFromGroup,
      searching,
    } = this.props

    return (
      <Row
        permissions={permissions}
        archived={archived}
        data={item}
        key={item.id}
        canTogglePage={!searching}
        selected={allSelected || selectedIds.includes(item.id)}
        showExtra={showExtra}
        onToggle={onToggleItem}
        onDeleteFromGroup={onDeleteFromGroup}
      />
    )
  }

  render() {
    const {
      items,
      working,
      EmptyList,
      sortManagers,
      t,
      permissions = {},
      searching,
    } = this.props

    const { can_view_property_owners: canViewOwner } = permissions

    const className = classnames('table table--default', {
      'working-overlay': working,
    })

    return (
      <div className={className}>
        <div className='table__outer-wrapper'>
          <div className='table__wrapper table--homes__list'>
            <div className='table__head'>
              <Table.HeaderCell
                title={t('MainInfo')}
                sortKey={searching ? null : 'address__value'}
                working={working}
              />
              {sortManagers ? (
                <Table.HeaderCell
                  title={t('Managers')}
                  sortKey={searching ? null : 'owners_count'}
                  working={working}
                />
              ) : (
                <Table.HeaderCell title={t('Managers')} />
              )}
              {canViewOwner && (
                <Table.HeaderCell
                  title={t('PropertyOwner')}
                  working={working}
                />
              )}
              <Table.HeaderCell
                title={t('InfoFlats')}
                sortKey={searching ? null : 'flat_count'}
                working={working}
              />
              <Table.HeaderCell
                title={t('DwellersBalloon')}
                sortKey={searching ? null : 'dweller_count'}
                working={working}
              />
              <Table.HeaderCell
                title={t('Updated')}
                sortKey={searching ? null : 'updated'}
                working={working}
              />
            </div>
            {items.map(this.renderItem)}
          </div>
        </div>

        {!items.length && <EmptyList />}
      </div>
    )
  }
}

export default withTranslation('Building')(TableList)
