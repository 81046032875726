//@flow

import { FORMIK_EMPTY_VALUE } from '../../../../constants'

export const IBAN = 'iban'
export const BIC = 'bic'
export const BANK_NAME = 'bank_name'
export const BANK_OWNER = 'bank_account_owner'

export const MAX_BANK_NAME_LENGTH = 256
export const MAX_BANK_OWNER_LENGTH = 256

export const KEYS = [IBAN, BIC, BANK_NAME, BANK_OWNER]

export const DEFAULT_VALUES: Object = {
  [IBAN]: FORMIK_EMPTY_VALUE,
  [BIC]: FORMIK_EMPTY_VALUE,
  [BANK_NAME]: FORMIK_EMPTY_VALUE,
  [BANK_OWNER]: FORMIK_EMPTY_VALUE,
}
