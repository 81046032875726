// @flow

export const PHONE_REG_EXP = /^(\+|\+[0-9]{7,15})$/
export const WEBSITE_REG_EXP = /^(http|https):\/\/[^ "]+$/
export const MAX_EMAIL_LENGTH = 254
export const MAX_NAME_LENGTH = 150
export const MAX_ADDRESS_LENGTH = 255
export const MAX_WEBSITE_LENGTH = 255
export const MAX_WORKING_HOURS_LENGTH = 255
export const MAX_DESCRIPTION_LENGTH = 2048
