// @flow

import React from 'react'
import { get } from 'lodash'
import type { Node } from 'react'

import { useSingleLoad } from '../../hooks/useSingleLoad/useSingleLoad'
import { getBudgetPlan } from '../../core/api/api.budgetPlan'
import { SimpleBlock } from '../Block'
import BudgetPlanHeader from './BudgetPlanHeader'
import BudgetPlanToolbox from './BudgetPlanToolbox'
import Invoices from './Invoices'
import Loader from '../Loader'

type Props = { id: string }

const UpdateBudgetPlan = (props: Props): Node => {
  const { id } = props

  const [data, isLoading, isError, reload] = useSingleLoad(getBudgetPlan, id)

  // TODO Need error component
  if (isError) {
    return null
  }

  if (!data && !isLoading) {
    return null
  }

  const requestNo = get(data, 'request_no')

  return (
    <>
      <SimpleBlock>
        <BudgetPlanHeader header isLoading={isLoading} requestNo={requestNo} />
        {data ? (
          <>
            <BudgetPlanToolbox plan={data} reload={reload} />
          </>
        ) : (
          <Loader text={false} type='big' />
        )}
      </SimpleBlock>
      <Invoices isLoading={isLoading} plan={data} reload={reload} />
    </>
  )
}

export default UpdateBudgetPlan
