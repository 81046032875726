// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { reject } from 'lodash-es'

import Button from '../../Button'
import NewSelectSimple from '../../NewSelectSimple'
import * as actions from './RequestTranslate.actionTypes'

import styles from './RequestTranslate.module.scss'

type Props = {
  disabled?: boolean,
  dispatch: Object => void,
  initiated: boolean,
  languages: Array<Object>,
  onWasTranslated: Object => void,
  saving: boolean,
  t: string => string,
  text: string,
  title: string,
  translations: ?Object,
  ukLang: Object,
  userLang: Object,
  working: boolean,
}
type State = {
  from: ?Object,
  to: ?Object,
}

class RequestTranslate extends Component<Props, State> {
  state = {
    from: null,
    to: null,
  }

  componentDidMount() {
    const { initiated, userLang } = this.props

    if (initiated) {
      this.setInitialValues()
    } else {
      this.props.dispatch({
        type: actions.REQUEST_TRANSLATOR_LOAD_LANGUAGES,
        locale: userLang.code,
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { initiated, saving, translations } = prevProps

    if (!initiated && this.props.initiated) {
      this.setInitialValues(this.props.languages)

      return
    }

    if (saving && !this.props.saving) {
      this.setInitialValues()

      return
    }

    if (!translations && this.props.translations) {
      this.setState({ from: prevState.to, to: null })
      this.props.onWasTranslated(this.props.translations)
      this.props.dispatch({ type: actions.REQUEST_TRANSLATIONS_RESET })
    }
  }

  setInitialValues = (languages: ?Array<Object>) => {
    const { ukLang, userLang } = this.props
    this.setState({
      from: this.findLang(ukLang, languages),
      to: this.findLang(userLang, languages),
    })
  }

  findLang = (lang, items) => {
    const { languages } = this.props

    return (items || languages).find(o => o.value === lang.code)
  }

  translate = () => {
    const { dispatch, title, text } = this.props
    const { from, to } = this.state
    dispatch({
      type: actions.REQUEST_TRANSLATE,
      from: from.value,
      to: to.value,
      title,
      text,
    })
  }

  updateLang = key => lang => {
    this.setState({ [key]: lang })
  }

  render() {
    const { t, working, disabled, initiated, languages } = this.props
    const { from, to } = this.state
    const languagesTo = from
      ? reject(languages, ['value', from.value])
      : languages

    return (
      <div className='translation'>
        <div className='translation__fromtitle'>{t('TranslateFromTitle')}</div>
        <div className='translation__from'>
          <NewSelectSimple
            useSearch
            searchClass={styles.search}
            className='translation__from-select'
            disabled={!initiated}
            value={from}
            options={languages}
            onChange={this.updateLang('from')}
          />
        </div>
        <div className='translation__totitle'>{t('TranslateToTitle')}</div>
        <div className='translation__to'>
          <NewSelectSimple
            useSearch
            searchClass={styles.search}
            className='translation__from-select'
            disabled={!initiated}
            value={to}
            options={languagesTo}
            onChange={this.updateLang('to')}
          />
        </div>
        <div className='translation__submit'>
          <Button.Save
            disabled={!initiated || !from || !to}
            working={working}
            onClick={this.translate}
          >
            {t('TranslateButtonTitle')}
          </Button.Save>
        </div>
        {(working || disabled) && <div className='translation__overlay' />}
      </div>
    )
  }
}

export default compose(
  withTranslation('TranslateForm'),
  connect(state => ({
    ...state.requestTranslate,
    ukLang: state.init.uk.language_obj,
    userLang: state.login.user.language_obj,
  }))
)(RequestTranslate)
