// @flow

import { compact, intersection, map, pull, concat } from 'lodash-es'

type Tab = { label: string, value: string }

type Tabs = Array<Tab>

export function getFlatTabNames(tabs: Tabs): Array<string> {
  return map(tabs, 'value')
}

export function getTreeValues(tab: Object): Array<string> {
  return concat(tab.value, tab.children ? getTreeTabs(tab.children) : [])
}

export function getTreeTabs(tabs: Array<Object>): Array<string> {
  return tabs.reduce((acc, tab) => acc.concat(getTreeValues(tab)), [])
}

export function getUrlTabList(tabPath?: ?string): Array<string> {
  if (!tabPath) {
    return []
  }

  return compact(tabPath.split('/'))
}

export function getInitialTab(
  urlTabList: Array<string>,
  tabs: Array<Object>,
  initialSelectedTab?: string
): string {
  const tabNames = getTreeTabs(tabs)

  const intersectTab = intersection(tabNames, urlTabList)

  if (intersectTab.length) {
    return intersectTab[0]
  }

  return initialSelectedTab || tabNames[0]
}

export function checkTabInUrl(
  selectedTab: string,
  urlTabList: Array<string>
): boolean {
  const intersectTab = intersection(urlTabList, [selectedTab])

  if (intersectTab.length) {
    return true
  }

  return false
}

export function checkRelatedTabInUrl(
  tabPath: string,
  tabs: Tabs,
  urlTabList: Array<string>,
  selectedTab: string
): ?string {
  const tabNames = getFlatTabNames(tabs)

  const intersectTab = intersection(pull(tabNames, selectedTab), urlTabList)

  if (intersectTab.length) {
    return intersectTab[0]
  }

  return null
}

export function getSlash(url: string): string {
  return url[url.length - 1] === '/' ? '' : '/'
}

export function getUrl(
  url: string,
  tabPath?: ?string,
  folderId?: ?string
): string {
  const mainUrl = url
    .replace(tabPath || '', '')
    .replace(folderId || '', '')
    .replace('///', '/')
    .replace('//', '/')

  const slash = getSlash(mainUrl)

  return `${mainUrl}${slash}`
}

export function getNewPathList(
  urlTabList: Array<string>,
  relatedTab: ?string,
  selectedTab: string
): Array<string> {
  if (relatedTab) {
    return urlTabList.map(urlTab => {
      if (urlTab === relatedTab) {
        return selectedTab
      }

      return urlTab
    })
  }

  return urlTabList.concat(selectedTab)
}
