// @flow

import React from 'react'
import { Link } from 'react-router-dom'
import seapig, { OPTIONAL } from 'seapig'
import classnames from 'classnames'
import type { Node } from 'react'

import Checkbox from '../Checkbox'

import styles from './Card.module.scss'

type Props = {
  canToggle?: boolean,
  checked?: boolean,
  children?: Node,
  imgSrc: string,
  link: string,
  onChange: (SyntheticEvent<HTMLInputElement>) => void,
  price?: string,
  text: string,
  value?: string,
}

const Card = (props: Props): Node => {
  const {
    imgSrc,
    price,
    text,
    link,
    checked,
    value,
    children,
    canToggle,
    icon: Icon,
  } = props

  const { managersChildren, rest } = seapig(children, { managers: OPTIONAL })

  const cardClass = classnames(styles.card, { [styles.checked]: checked })

  return (
    <div className={cardClass}>
      <Link to={link}>
        <div className={styles.preview}>
          {imgSrc ? <img src={imgSrc} /> : Icon ? <>{Icon}</> : null}
        </div>
      </Link>
      <div className={styles.info}>
        {price && <div className={styles.price}>{price}</div>}
        <div className={styles.text}>
          {canToggle && (
            <Checkbox
              checked={checked}
              value={value}
              onChange={props.onChange}
            />
          )}
          <Link to={link}>{text}</Link>
        </div>
        <div className={styles.stats}>{rest}</div>
        {managersChildren}
      </div>
    </div>
  )
}

export default Card
