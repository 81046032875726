// @flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelected } from '../../../hooks'
import classNames from 'classnames'

import { getAutomations } from '../../../core/api/api.automate'

import Modal from '../../Modal/Modal'
import ModalHead from '../../Modal/ModalHead'
import ModalBody from '../../Modal/ModalBody'
import ModalRow from '../../Modal/ModalRow'
import ModalText from '../../Modal/ModalText'
import ModalLabel from '../../Modal/ModalLabel'
import ModalError from '../../Modal/ModalError'
import ModalButtons from '../../Modal/ModalButtons'
import ModalCloseButton from '../../Modal/ModalCloseButton'

import Chip from '../../Chip'
import { EntityIcon } from '../../Icon'

import NewSelectAsync from '../../NewSelectAsync'
import Button from '../../Button'
import { isEqual } from 'lodash-es'

import {
  STATUS_COLORS,
  STATUS_ACTIVE,
} from '../../Automate/AutomateTable/Automate.constants'

import styles from './ConnectAutomation.module.scss'

import type { Node } from 'react'

type Props = {
  activity: Object,
  onClose: () => void,
  onSave: (number, Array<number>) => void,
}

const MAX_AUTOMATIONS_COUNT = 5

const ConnectAutomation = (props: Props): Node => {
  const {
    activity: { id, automations },
  } = props
  const { t } = useTranslation('Automate')
  const [selected, changeSelected, setSelected] = useSelected([...automations])
  const [count, setCount] = useState(null)
  const [isOpen, setOpen] = useState(false)

  const handleToggle = () => {
    setOpen(!isOpen)
  }

  const getStatusText = status =>
    status === STATUS_ACTIVE ? t('Active') : t('Inactive')

  const getLabel = a => a.name
  const getDescription = a => (
    <span style={{ color: STATUS_COLORS[a.status] }}>
      {getStatusText(a.status)}
    </span>
  )

  const hideBuildingList = classNames(
    'modal__label--drop button-drop button-drop--small',
    { 'modal__label--drop--open': isOpen }
  )

  return (
    <Modal isOpen onRequestClose={props.onClose}>
      <ModalCloseButton onClose={props.onClose} />
      <ModalHead title={t('ConnectToAutomation')} />
      <ModalBody style={{ width: '400px' }}>
        <ModalRow>
          <ModalLabel>{t('AutomationSelectTitle')}</ModalLabel>
        </ModalRow>
        <ModalRow>
          <NewSelectAsync
            isMulti
            noAll
            pageSize={10}
            api={getAutomations}
            searchKey='search'
            getLabel={getLabel}
            getSelectedLabel={getLabel}
            getDescription={getDescription}
            placeholder={t('SelectName')}
            view='dropdown'
            selectedItems={selected}
            style={{ width: '100%', marginBottom: '16px' }}
            setSelectedItems={setSelected}
            isAllSelected={selected => count === selected.length}
            setMetaCount={setCount}
            onClick={({ id, name, status }) =>
              changeSelected({ id, name, status })
            }
          />
        </ModalRow>
        {!!selected.length && (
          <ModalRow onClick={handleToggle}>
            <ModalText
              className={styles.text}
              text={t('Pipeline:ShowAutomationList')}
            />
            <ModalLabel className={styles.label}>{selected.length}</ModalLabel>
            <button
              type='button'
              className={hideBuildingList}
              style={{ marginTop: '1px' }}
            />
          </ModalRow>
        )}
        {isOpen && !!selected.length && (
          <div className={styles.automations}>
            {selected.map(a => (
              <Chip
                text={a.name}
                key={a.id}
                className={styles.chip}
                onClose={() => {
                  changeSelected(a)
                }}
              >
                <div className={styles.iconBounding}>
                  <EntityIcon id='automate' className={styles.icon} />
                </div>
              </Chip>
            ))}
          </div>
        )}
      </ModalBody>
      {selected.length > MAX_AUTOMATIONS_COUNT && (
        <ModalError
          error={t('MaxAutomationCount', { max: MAX_AUTOMATIONS_COUNT })}
        />
      )}
      <ModalButtons>
        <Button.Save
          disabled={
            selected.length > MAX_AUTOMATIONS_COUNT ||
            isEqual(selected, automations)
          }
          onClick={() =>
            props.onSave(
              id,
              selected.map(a => a.id)
            )
          }
        >
          {t('Common:Save')}
        </Button.Save>
        <Button.Cancel onClick={props.onClose}>
          {t('Common:Cancel')}
        </Button.Cancel>
      </ModalButtons>
    </Modal>
  )
}

export default ConnectAutomation
