//@flow

import { FORMIK_EMPTY_VALUE } from '../../../constants'

export const IBAN = 'iban'
export const BIC = 'bic'
export const BANK_NAME = 'bank_name'
export const TYPE_PAYMENT = 'payment_type'
export const OPEN_AMOUNT = 'open_amount'
export const DEPOSIT = 'deposit'

export const MAX_BANK_NAME_LENGTH = 256
export const MAX_TYPE_OF_PAYMENT_LENGTH = 256
export const MAX_AMOUNT = 1000000000

export const KEYS = [IBAN, BIC, BANK_NAME, TYPE_PAYMENT, OPEN_AMOUNT, DEPOSIT]

export const CURRENCY_LABELS = {
  ['USD']: '$',
  ['EUR']: '€',
  ['GBP']: '£',
}

export const DEFAULT_VALUES: Object = {
  [IBAN]: FORMIK_EMPTY_VALUE,
  [BIC]: FORMIK_EMPTY_VALUE,
  [BANK_NAME]: FORMIK_EMPTY_VALUE,
  [TYPE_PAYMENT]: FORMIK_EMPTY_VALUE,
  [OPEN_AMOUNT]: {},
  [DEPOSIT]: {},
}
