// @flow

import React from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import { MARKETPLACE_ROUTES } from '../constants'
import { showMarketplace } from '../utils/commonSelectors'

const withMarketplace = WrappedComponent => {
  const Component = ({ isMarketplaceEnabled, ...props }) =>
    isMarketplaceEnabled ? (
      <WrappedComponent {...props} />
    ) : (
      <Redirect to={MARKETPLACE_ROUTES['404']} />
    )

  const mapStateToProps = state => ({
    isMarketplaceEnabled: showMarketplace(state),
  })

  return connect(mapStateToProps)(Component)
}

export default withMarketplace
