// @flow

import React, { useEffect, useState } from 'react'
import { get } from 'lodash-es'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'
import type { Match } from 'react-router-dom'

import Button from '../Button'
import { formatDate } from '../../utils/utils'
import { EntityIcon } from '../Icon'
import { SimpleBlock, Header, CellsBar, Cell } from '../Block'
import UserExternalOutgoingEmail from './OutgoingEmail'
import UserExternalIncomingEmail from './IncomingEmail'
import {
  toggleAccountStatus,
  getMailAccount,
  getCorporateMailAccount,
  toggleAccountShared,
  toggleCorporateAccountMarked,
  toggleCorporateAccountStatus,
  integrateAccount,
  integrateCorporateAccount,
} from '../../core/api/api.mail'
import Loader from '../Loader'
import FontSetting from './FontSetting'
import type { Account } from './EmailIntegration.types'

import { redirectTo404 } from '../../utils/routing'

import styles from '../../containers/MarketplaceSettings/MarketplaceSettings.module.scss'
import ErrorPopup from '../modals/ErrorPopup'
import Modal from '../Modal'

type Props = { isPrivate?: boolean, match: Match }

const EmailIntegrationEdit = (props: Props): Node => {
  const { match, isPrivate } = props
  const accountId = get(match, ['params', 'id'])

  const [isLoading, setLoading] = useState(true)
  const [isActive, setActive] = useState(false)
  const [isShared, setShared] = useState(false)
  const [account, setAccount] = useState(null)
  const [isMarked, setMarked] = useState(false)
  const [modal, setModal] = useState(null)

  const { t } = useTranslation('EmailIntegration')

  useEffect(() => {
    if (!isLoading) {
      return
    }

    const getApi = isPrivate ? getMailAccount : getCorporateMailAccount

    getApi(accountId)
      .then((data: Account) => {
        // TODO update batching
        setAccount(data)

        setActive(data.is_active)
        setShared(data.shared)
        setMarked(data.mark_read_by_all)
      })
      .catch(error => redirectTo404(error))
      .finally(() => setLoading(false))
  }, [isLoading])

  const hideModal = () => {
    setModal(null)
  }

  const setSignature = signature => {
    if (account) {
      setAccount({ ...account, signature })
    }
  }

  if (!account) {
    return <Loader type='big' text={false} />
  }

  const {
    incoming,
    outgoing,
    last_polling,
    signature,
    insert_signature_before_quoted_text,
    can_integrate,
  } = account

  const toggleStatusApi = isPrivate
    ? toggleAccountStatus
    : toggleCorporateAccountStatus

  const toggleActive = () =>
    toggleStatusApi(accountId).then(data => setActive(data.is_active))

  const toggleShared = () =>
    toggleAccountShared(accountId).then(data => setShared(data.shared))

  const toggleMarked = () =>
    toggleCorporateAccountMarked(accountId).then(data =>
      setMarked(data.mark_read_by_all)
    )

  const accountActiveText = isActive ? t('accountIsOn') : t('accountIsOff')
  const accountSharedText = isShared
    ? t('accountIsShared')
    : t('accountIsNotShared')
  const accountMarkedText = isMarked
    ? t('accountIsMarkedAsRead')
    : t('accountIsNotMarkedAsRead')

  const handleAuth = () => {
    let integrationApi = isPrivate
      ? integrateAccount
      : integrateCorporateAccount
    integrationApi(accountId)
      .then(a => {
        setLoading(false)
        window.location.assign(a.auth_url)
      })
      .catch(error => {
        const data = get(
          error,
          ['message', 'response', 'data', 'errors'],
          'UnknownError'
        )
        setModal(
          <ErrorPopup
            isServerError
            text={t(`Errors:${data}`)}
            title={t('Error')}
            confirm={t('Close')}
            onOk={hideModal}
            onClose={hideModal}
          />
        )
        setLoading(false)
      })
  }

  return (
    <>
      <SimpleBlock>
        <Header header>
          <EntityIcon icon large id='email' />
          {t('EmailAccountTitle')}
        </Header>
        <CellsBar>
          <Cell title={t('EmailAccountLastPolling')}>
            {formatDate(last_polling) || '-'}
          </Cell>
          <Cell title={t('EmailAccountStatus')}>
            <Button.Toggle
              checked={isActive}
              disabled={isLoading}
              id='active'
              onChange={toggleActive}
            />
            <span className={styles['header-cell-right-value']}>
              {accountActiveText}
            </span>
          </Cell>
          {isPrivate ? (
            <Cell title={t('EmailAccountSharing')}>
              <Button.Toggle
                checked={isShared}
                disabled={isLoading}
                id='shared'
                onChange={toggleShared}
              />
              <span className={styles['header-cell-right-value']}>
                {accountSharedText}
              </span>
            </Cell>
          ) : (
            <Cell title={t('EmailAccountMarkedAsRead')}>
              <Button.Toggle
                checked={isMarked}
                disabled={isLoading}
                id='marked'
                onChange={toggleMarked}
              />
              <span className={styles['header-cell-right-value']}>
                {accountMarkedText}
              </span>
            </Cell>
          )}
          {can_integrate && (
            <Cell>
              <Button.Save
                disabled={isLoading || !isActive}
                onClick={handleAuth}
              >
                {t('Authorization')}
              </Button.Save>
            </Cell>
          )}
        </CellsBar>
      </SimpleBlock>
      <FontSetting accountId={accountId} />
      <UserExternalIncomingEmail
        accountId={accountId}
        initialParams={incoming}
        setLoading={setLoading}
        isPrivate={isPrivate}
      />
      <UserExternalOutgoingEmail
        accountId={accountId}
        initialParams={{
          ...outgoing,
          insert_signature_before_quoted_text,
          signature,
        }}
        setSignature={setSignature}
        setLoading={setLoading}
        isPrivate={isPrivate}
      />
      <Modal isOpen={!!modal} onRequestClose={hideModal}>
        {modal}
      </Modal>
    </>
  )
}

export default EmailIntegrationEdit
