// @flow

import React from 'react'

type Props = {
  buttonClass: string,
  handleClick: ({ [string]: boolean }) => void,
  isActivateButton: boolean,
  permissionName: string,
  text: string,
}

const Button = (props: Props) => {
  const { buttonClass, text, isActivateButton, permissionName } = props

  const handleClick = () =>
    props.handleClick({ [permissionName]: isActivateButton })

  return (
    <div className={buttonClass} onClick={handleClick}>
      <span className='radio-button__text'>{text}</span>
    </div>
  )
}

Button.defaultProps = {
  isActivateButton: false,
}

export default Button
