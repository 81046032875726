// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import { EntityIcon } from '../../Icon'
import { Header } from '../../Block'

import styles from './BudgetPlanHeader.module.scss'

type Props = {
  isLoading?: ?boolean,
  requestNo?: ?number,
}

const BudgetPlanHeader = (props: Props): Node => {
  const { requestNo, isLoading } = props

  const { t } = useTranslation('BudgetPlan')

  return (
    <Header goBack notCollapse icon={<EntityIcon id='request' />}>
      <div className={styles.header}>
        <span>{t('BudgetPlanTitle')}</span>
        {requestNo ? (
          <span>{`${t('RequestTitle')} ${t(
            'Common:NumberSymbol'
          )} ${requestNo}`}</span>
        ) : (
          !isLoading && <span>{t('BudgetPlanCreating')}</span>
        )}
      </div>
    </Header>
  )
}

export default BudgetPlanHeader
