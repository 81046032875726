// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import { get } from 'lodash-es'

import { Row } from '../../../Block'
import SelectRequestType from '../../../Select/SelectRequestType'

import styles from './RequestType.module.scss'

type Props = {
  editing: boolean,
  onChange: ({ type: { id: number, name: string } }) => void,
  onEdit?: () => void,
  outbound?: string,
  permissions: { can_edit_content: boolean },
  value: ?{ id: number, name: string },
}

const RequestType = (props: Props) => {
  const {
    permissions: { can_edit_content: canEditContent },
    value,
    editing,
    outbound,
  } = props

  const name = get(value, 'name', null)

  const { t } = useTranslation('Request')

  const title = t('TypeTitle')

  if (editing) {
    const valueClassname = classnames('request-type__value', styles.value)

    return (
      <Row title={title}>
        <div className={valueClassname}>
          <div className='input input--large input--default input--block'>
            <SelectRequestType
              className={styles.select}
              value={value}
              outbound={outbound}
              onChange={props.onChange}
            />
          </div>
        </div>
      </Row>
    )
  }

  const rowProps = Object.assign(
    {},
    { title },
    canEditContent ? { onEdit: props.onEdit } : undefined
  )

  return (
    <Row {...rowProps}>
      <span className='bar__submit-label_light bar__submit-label' title={name}>
        {name || t('RequestTypeIsNotSelected')}
      </span>
    </Row>
  )
}

RequestType.defaultProps = {
  value: {},
  permissions: {
    can_edit_content: true,
  },
}

export default RequestType
