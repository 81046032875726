// @flow

import {
  MAILS_LIST_CLEAR,
  MAILS_LIST_LOAD,
  MAILS_LIST_WAS_LOADED,
} from './MailsList.actions'

const initialState = {
  working: true,
  meta: {},
  results: {
    objects: [],
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case MAILS_LIST_LOAD:
      return {
        ...state,
        working: true,
      }

    case MAILS_LIST_WAS_LOADED:
      return {
        ...state,
        working: false,
        ...action.payload,
      }

    case MAILS_LIST_CLEAR:
      return initialState

    default:
      return state
  }
}
