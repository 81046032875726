// @flow

import React from 'react'
import classnames from 'classnames'
import type { Node } from 'react'

import styles from './Button.module.scss'

type Props = { disabled: boolean, text: string }

const Button = (props: Props): Node => {
  const { disabled, text } = props

  const buttonClass = classnames(styles.button, {
    [styles.accepted]: disabled,
  })

  return (
    <div className={styles.container}>
      <button className={buttonClass} type='submit' disabled={!disabled}>
        {text}
      </button>
    </div>
  )
}

export default Button
