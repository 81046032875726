/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type OrderProfileComment_data$ref: FragmentReference;
declare export opaque type OrderProfileComment_data$fragmentType: OrderProfileComment_data$ref;
export type OrderProfileComment_data = {|
  +comment: ?string,
  +$refType: OrderProfileComment_data$ref,
|};
export type OrderProfileComment_data$data = OrderProfileComment_data;
export type OrderProfileComment_data$key = {
  +$data?: OrderProfileComment_data$data,
  +$fragmentRefs: OrderProfileComment_data$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "OrderProfileComment_data",
  "type": "CustomerOrderNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "comment",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '366f9f3b19cd7ea06460dd81ea504247';
module.exports = node;
