import {
  LOAD_ACTIVITIES,
  ACTIVITIES_WAS_LOADED
} from './ActivitiesWidget.actions'

const initialState = {
  working: true,
  data: []
}

const activitiesWidget = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ACTIVITIES:
      return {
        ...state,
        working: true
      }
    case ACTIVITIES_WAS_LOADED:
      return {
        ...state,
        working: false,
        data: action.data
      }
    default:
      return state
  }
}

export default activitiesWidget
