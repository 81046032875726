// @flow

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'

import Button from '../../Button'
import FileMenuItem from './FileMenuItem'
import Icon from '../../Icon'
import Loader from '../../Loader'

import styles from './WidgetAddEntities.module.scss'

const FILE_ITEM_KEYS = ['AddFile', 'File']

type MenuItem = {
  callback: any => void,
  icon: string,
  key: string,
  outbound?: string,
}

type Props = {
  menuItems: Array<MenuItem>,
  t: string => string,
}

type State = {
  filesUploading: boolean,
  opened: boolean,
}

class WidgetAddEntities extends Component<Props, State> {
  state = {
    filesUploading: false,
    opened: true,
  }

  onClickOut = () => {
    const { opened, filesUploading } = this.state

    if (opened && !filesUploading) {
      this.setState({ opened: false })
    }
  }

  toggleMenu = () => {
    this.setState(state => ({
      opened: !state.opened,
    }))
  }

  startFilesUpload = () => {
    this.setState({ filesUploading: true })
  }

  isFileKey = key => FILE_ITEM_KEYS.includes(key)

  finishFilesUpload = (files: Array<Object>) => {
    if (files.length) {
      const { menuItems } = this.props
      const item = menuItems.find(m => this.isFileKey(m.key))

      if (item) {
        item.callback(files)
      }
    }

    this.setState({
      filesUploading: false,
      opened: false,
    })
  }

  renderItem = (item: MenuItem) =>
    this.isFileKey(item.key)
      ? this.renderFileItem(item)
      : this.renderSimpleItem(item)

  renderFileItem = (item: MenuItem) => (
    <FileMenuItem
      key={item.key}
      outbound={this.props.outbound}
      onFinishUpload={this.finishFilesUpload}
      onStartUpload={this.startFilesUpload}
    >
      {this.renderSimpleItem(item, false)}
    </FileMenuItem>
  )

  renderSimpleItem = (item: MenuItem, clickable: boolean = true) => (
    <div
      className='widget__menu-item'
      key={item.key}
      onClick={clickable ? item.callback : null}
    >
      <Icon id={item.icon} />
      <span className=''>{this.props.t(item.key)}</span>
    </div>
  )

  renderMenu = () => {
    const { filesUploading, opened } = this.state

    if (!opened) {
      return null
    }

    const { menuItems } = this.props
    const menuClass = classnames('widget__menu', {
      'working-overlay': filesUploading,
    })

    return <div className={menuClass}>{menuItems.map(this.renderItem)}</div>
  }

  render() {
    const { title } = this.props
    const { opened, filesUploading } = this.state

    const widgetClass = classnames(
      'widget',
      'widget--no-border',
      'widget-add-tab',
      { 'working-overlay': filesUploading }
    )

    return (
      <aside className={widgetClass}>
        <header className={styles.header} onClick={this.toggleMenu}>
          <span className='widget__new-header-text'>
            {title || this.props.t('Common:Add')}
          </span>
          {filesUploading ? (
            <Loader text={false} type='small' />
          ) : (
            <Button.Minimize isOpen={opened} />
          )}
        </header>
        {this.renderMenu()}
      </aside>
    )
  }
}

export default withTranslation('WidgetAddEntities')(WidgetAddEntities)
