// @flow

import { clone, pull, map } from 'lodash-es'

import * as actions from './FlatList.actionTypes'
import * as flatPopupActions from '../../modals/AddFlatPopup/AddFlatPopup.actionTypes'

const initialState = {
  error: false,
  flats: [],
  loaded: false,
  loading: false,
  meta: {},
  modal: null,
  permissions: {},
  selected: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.FLAT_LIST_SHOW_MODAL:
      return {
        ...state,
        modal: action.modal,
      }
    case actions.FLAT_LIST_HIDE_MODAL:
    case flatPopupActions.FLAT_POPUP_ADDED_FLAT:
      return {
        ...state,
        modal: null,
      }
    case actions.FLAT_LIST_TOGGLE: {
      const { id } = action
      let selected = clone(state.selected)

      if (selected.includes(id)) {
        selected = pull(selected, id)
      } else {
        selected.push(id)
      }

      return {
        ...state,
        selected,
      }
    }
    case actions.FLAT_LIST_TOGGLE_ALL: {
      let selected = []

      if (state.selected.length !== state.flats.length) {
        selected = map(state.flats, item => item.id)
      }

      return {
        ...state,
        selected,
      }
    }
    case actions.FLAT_LIST_RESTORE:
    case actions.FLAT_LIST_LOADING:
    case actions.FLAT_LIST_REMOVING:
      return {
        ...state,
        loading: true,
        selected: [],
        modal: null,
      }
    case actions.FLAT_LIST_LOADED:
      return {
        ...state,
        loading: false,
        flats: action.results.objects,
        meta: action.meta,
        permissions: action.permissions,
        loaded: true,
      }
    case actions.FLAT_LIST_RESET:
      return { ...initialState }
    case actions.FLAT_LIST_ERROR:
    default:
      return state
  }
}
