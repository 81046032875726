// @flow

export const FLAT_LIST_SHOW_MODAL = 'FLAT_LIST_SHOW_MODAL'
export const FLAT_LIST_HIDE_MODAL = 'FLAT_LIST_HIDE_MODAL'
export const FLAT_LIST_TOGGLE = 'FLAT_LIST_TOGGLE'
export const FLAT_LIST_TOGGLE_ALL = 'FLAT_LIST_TOGGLE_ALL'
export const FLAT_LIST_RESTORE = 'FLAT_LIST_RESTORE'
export const FLAT_LIST_REMOVING = 'FLAT_LIST_REMOVING'
export const FLAT_LIST_LOADING = 'FLAT_LIST_LOADING'
export const FLAT_LIST_ERROR = 'FLAT_LIST_ERROR'
export const FLAT_LIST_RESET = 'FLAT_LIST_RESET'
export const FLAT_LIST_LOADED = 'FLAT_LIST_LOADED'
