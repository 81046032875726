// @flow

import React from 'react'
import { connect } from 'react-redux'

import Dweller from './Dweller'
import Staff from './Staff'
import ExternalContact from './ExternalContact'
import { getUser } from '../../../../utils/commonSelectors'
import { USER_GROUPS } from '../../../../constants'

type PrimaryProps = {
  canDelete: boolean,
  canRestore: boolean,
  externalContact?: boolean,
  isArchived: boolean,
  onApprove: Object => void,
  onReinvite: Object => void,
  onRemove: Object => void,
  onToggle: Object => void,
  onUpdateBuilding: Object => void,
  onUpdateRole: (Object, ?Object) => void,
  onUpdateStatus: (Object, boolean) => void,
  selected: boolean,
  statusOptions: Array<Object>,
  user: Object,
}

export type ItemProps = PrimaryProps & {
  canUpdateRole: boolean,
  t: string => string,
}

type Props = PrimaryProps & {
  loggedUser: Object,
}

const UserItem = (props: Props) => {
  const {
    user,
    loggedUser,
    canDelete,
    canRestore,
    selected,
    statusOptions,
    externalContact,
  } = props

  const itemProps = {
    canDelete,
    canRestore,
    selected,
    statusOptions,
    user,
    onToggle: props.onToggle,
    onApprove: props.onApprove,
    onDelete: props.onDelete,
    onReinvite: props.onReinvite,
    onUpdateBuilding: props.onUpdateBuilding,
    onUpdateCategory: props.onUpdateCategory,
    onUpdateRole: props.onUpdateRole,
    onUpdateStatus: props.onUpdateStatus,
    isArchived: props.isArchived,
  }

  if (externalContact) {
    return <ExternalContact {...itemProps} />
  }

  if (user.group === USER_GROUPS.dweller) {
    return (
      <Dweller
        {...itemProps}
        canUpdateRole={
          user.permissions.can_change_dweller_role &&
          loggedUser.permissions.can_change_dweller_role
        }
        onRemove={props.onRemove}
      />
    )
  }

  return (
    <Staff
      {...itemProps}
      canUpdateRole={
        loggedUser.permissions.can_change_manager_role && !user.super
      }
    />
  )
}

const mapStateToProps = state => ({
  loggedUser: getUser(state),
})

export default connect(mapStateToProps)(UserItem)
