// @flow

import React from 'react'
import { createFragmentContainer, graphql } from 'react-relay'
import { useSelector } from 'react-redux'

import { getUser } from '../../../utils/commonSelectors'
import { SimpleBlock, Header, CellsBar } from '../../../components/Block'
import OrderProfileHeader from './OrderProfileHeader'
import OrderProfileStatus from './OrderProfileStatus'
import OrderProfilePrice from './OrderProfilePrice'
import OrderProfileCreationDate from './OrderProfileCreationDate'
import OrderProfileRequest from './OrderProfileRequest'
import OrderProfileProvider from './OrderProfileProvider'
import OrderProfileOffer from './OrderProfileOffer'
import OrderProfileCustomer from './OrderProfileCustomer'
import OrderProfileComment from './OrderProfileComment'
import { isStaffUser } from '../../../utils/utils'

const OrderProfile = props => {
  const user = useSelector(state => getUser(state))

  const { data } = props

  const isStaff = isStaffUser(user)

  return (
    <>
      <SimpleBlock>
        <Header header>
          <OrderProfileHeader data={data} />
        </Header>
        <CellsBar>
          <OrderProfileCreationDate data={data} />
          <OrderProfileStatus data={data} isStaff={isStaff} />
        </CellsBar>
        <CellsBar>
          <OrderProfileOffer data={data} />
          <OrderProfilePrice data={data} />
        </CellsBar>
        <OrderProfileProvider data={data} />
        {isStaff && <OrderProfileRequest data={data} />}
      </SimpleBlock>
      <SimpleBlock>
        <Header header>
          <OrderProfileCustomer data={data} />
        </Header>
        <CellsBar>
          <OrderProfileComment data={data} />
        </CellsBar>
      </SimpleBlock>
    </>
  )
}

export default createFragmentContainer(OrderProfile, {
  data: graphql`
    fragment OrderProfile_data on CustomerOrderNode
    @argumentDefinitions(orderID: { type: ID, defaultValue: 0 }) {
      ...OrderProfileHeader_data
      ...OrderProfileStatus_data
      ...OrderProfileCreationDate_data
      ...OrderProfilePrice_data
      ...OrderProfileRequest_data
      ...OrderProfileProvider_data
      ...OrderProfileOffer_data
      ...OrderProfileCustomer_data
      ...OrderProfileComment_data
    }
  `,
})
