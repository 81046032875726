// @flow

import type { Node } from 'react'

export function hasSelected(selectedItems: Array<any>): boolean {
  return !!(selectedItems && selectedItems.length)
}

export function getSelectedText(
  selectedItems: Array<any>,
  getSelectedLabel?: Function
): string {
  const valueArray = getSelectedLabel
    ? selectedItems.map(getSelectedLabel)
    : selectedItems

  return valueArray.join(', ')
}

export function getAvatar(selectedItems?: Array<any>): string | void {
  if (!selectedItems || selectedItems.length !== 1) {
    return
  }

  return selectedItems[0].avatar
}

export function getMeta(
  selectedItems?: Array<any>,
  getMetaComponent?: (Object, boolean) => Node
): ?Node {
  if (!selectedItems || selectedItems.length !== 1 || !getMetaComponent) {
    return null
  }

  return getMetaComponent(selectedItems[0], true)
}
