// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Row } from '../../Block'
import Button from '../../Button'
import SelectUser from '../../Select/SelectUser'
import SelectValue from '../../Select/ValueItem'
import { USER_GROUPS, USER_STATUS, ACTIVE_STATUS } from '../../../constants'
import { getUrlForAvatar, getUserId } from '../../../utils/utils'
import { getUser } from '../../../utils/commonSelectors'

import styles from './PropertyOwnerBar.module.scss'

const PropertyOwnerBar = props => {
  const { t } = useTranslation('Building')
  const {
    data: { property_owner_objs: owners, permissions = {} },
    working,
    editingOwners,
  } = props

  const {
    can_edit_property_owners: canEdit,
    can_view_property_owners: canView,
  } = permissions

  const selectParams = {
    group: [USER_GROUPS.dweller],
    is_landlord: true,
    soft_archived: false,
  }
  const onEdit = canEdit ? props.editOwners : null
  const user = useSelector(state => getUser(state))

  const renderOwner = man => (
    <div className={styles.owner} key={`owner-${getUserId(man) || ''}`}>
      <SelectValue
        inline
        profile
        round
        isLink={
          !(
            user.group === USER_GROUPS.dweller &&
            USER_STATUS[man.status] !== ACTIVE_STATUS
          )
        }
        avatar={getUrlForAvatar(man)}
        profileId={man.id}
        group={man.group}
        text={`${man.name} ${man.second_name || ''}`}
      />
    </div>
  )

  if (!canView) {
    return null
  }

  if (editingOwners) {
    const initialIds = owners.map(getUserId)

    return (
      <Row title={t('PropertyOwner')}>
        <SelectUser
          initAll
          cleanable
          multi
          explicitLoading
          order
          round
          id='building-owner'
          initialValueId={initialIds}
          params={selectParams}
          onChange={props.updateOwners}
        />
        <div
          style={{
            marginTop: '15px',
            marginBottom: '5px',
          }}
        >
          <Button.Save working={working} onClick={props.savePropertyOwners}>
            {t('Save')}
          </Button.Save>
          <Button.Cancel onClick={props.cancelOwners}>
            {t('Cancel')}
          </Button.Cancel>
        </div>
      </Row>
    )
  }

  return (
    <Row title={t('PropertyOwner')} onEdit={onEdit}>
      {owners.length ? (
        <div className={styles.owners}>{owners.map(renderOwner)}</div>
      ) : (
        <span className='bar__submit-label_light bar__submit-label'>
          {t('SmthEmpty')}
        </span>
      )}
    </Row>
  )
}

export default PropertyOwnerBar
