// @flow

import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useRouteMatch, useLocation } from 'react-router-dom'

import BuildingGroupInfo from './BuildingGroupInfo'

import RouterTabs from '../Tabs/RouterTabs'
import Pan from '../Tabs/Pan'

import Loader from '../Loader'

import {
  getGroup,
  updateGroup,
  updateGroupManagers,
} from '../../core/api/api.building'

import BuildingGallery from '../BuildingGallery'
import FlatList from '../Flat/FlatList'
import Users from '../Users/Users'
import RequestList from '../Request/RequestList'
import Files from '../Files'
import NewsList from '../../containers/NewsList'

const BuildingGroupView = () => {
  const { t } = useTranslation('Building')
  const match = useRouteMatch()
  const location = useLocation()
  const groupId = match.params.groupId

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({})

  useEffect(() => {
    if (loading && groupId) {
      getGroup(groupId).then(data => {
        setData(data)
        setLoading(false)
      })
    }
  }, [loading])

  const handleUpdateGroup = params => {
    updateGroup(groupId, params).then(() => setLoading(true))
  }

  const handleUpdateManagers = params => {
    updateGroupManagers(groupId, params).then(() => setLoading(true))
  }

  if (loading) {
    return <Loader text={false} type='big' />
  }

  const permissions = data.permissions || {}
  const {
    can_view_documents,
    can_view_flats,
    can_view_inhabitants,
    can_view_posts,
    can_view_requests,
  } = permissions

  return (
    <div className='content__col'>
      <BuildingGroupInfo
        data={data}
        onSave={handleUpdateGroup}
        onSaveManagers={handleUpdateManagers}
      />
      <RouterTabs className='tabs tabs-style-transparent' openFirst='homes'>
        <Pan label={t('Buildings')} linkHash='#homes'>
          <BuildingGallery noMargin buildingGroup={groupId} />
        </Pan>
        {can_view_flats && (
          <Pan label={t('Flats')} linkHash='#flats'>
            <FlatList buildingGroup={groupId} />
          </Pan>
        )}
        {can_view_inhabitants && (
          <Pan label={t('Dwellers')} linkHash='#dwellers'>
            <Users location={location} />
          </Pan>
        )}
        {can_view_requests && (
          <Pan label={t('Requests')} linkHash='#requests'>
            <RequestList params={{ buildingGroup: groupId }} />
          </Pan>
        )}
        {can_view_documents && (
          <Pan label={t('Documents')} linkHash='#documents'>
            <Files
              scope={'building_group'}
              directoryId={data.directory_id}
              match={match}
              location={location}
            />
          </Pan>
        )}
        {can_view_posts && (
          <Pan label={t('Posts')} linkHash='#posts'>
            <NewsList
              additionalQueryParams={{ building_group: groupId }}
              emptyBoxTitle={t('Posts')}
            />
          </Pan>
        )}
      </RouterTabs>
    </div>
  )
}

export default BuildingGroupView
