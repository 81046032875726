// @flow

import { all, put, call, fork, takeLatest } from 'redux-saga/effects'
import { replace } from 'connected-react-router'
import { omit } from 'lodash-es'

import api from '../../../core/api'
import {
  serverError,
  globalModalError,
  showPopupMessage,
} from '../../Layout/Layout.actions'
import { SERVER_404_ERROR } from '../../Layout/Layout.constants'
import * as actions from './BuildingList.actionTypes'
import { getFileById } from '../../../core/api/api.file'

function* watchInitiate() {
  yield takeLatest(actions.BUILDING_LIST_INITIATING, initiate)
}

function* watchLoad() {
  yield takeLatest(actions.BUILDING_LIST_LOAD, load)
}

function* watchRemove() {
  yield takeLatest(actions.BUILDING_LIST_REMOVE, remove)
}

function* watchRemoveFromGroup() {
  yield takeLatest(actions.BUILDING_LIST_REMOVE_FROM_GROUP, removeFromGroup)
}

function* watchRestore() {
  yield takeLatest(actions.BUILDING_LIST_RESTORE, restore)
}

function* watchUpdateImages() {
  yield takeLatest(actions.UPDATE_IMAGES, updateImages)
}

function* watchUpdateGroupBuildings() {
  yield takeLatest(actions.UPDATE_GROUP_BUILDINGS, updateGroupBuildings)
}

function* updateImages({ ids }) {
  try {
    const updatedImages = yield call(getFileById, {
      file_ids: ids.join(','),
      scope: 'building',
    })

    yield put({ type: actions.IMAGES_WAS_UPDATED, updatedImages })
  } catch (error) {
    const action = serverError(error)

    if (action.type === SERVER_404_ERROR) {
      yield put(replace('/404'))
    } else {
      yield put(action)
      yield put({ type: actions.BUILDING_LIST_ERROR, error })
    }
  }
}

function* initiate({ params }) {
  try {
    let getApi = api.building.getListTiny

    if (params?.soft_archived) {
      getApi = api.building.getArchivedListTiny
    } else if (params?.buildingGroup) {
      getApi = api.building.getGroupBuildings
    } else if (params?.contractorId) {
      getApi = api.contractor.getContractorBuildings
    } else if (params?.search) {
      getApi = api.building.getListSearch
    }

    let data

    if (params?.buildingGroup) {
      data = yield call(
        api.building.getGroupBuildings,
        params.buildingGroup,
        omit(params, 'buildingGroup', 'all')
      )
    } else if (params?.contractorId) {
      data = yield call(
        api.contractor.getContractorBuildings,
        params.contractorId,
        omit(params, 'contractorId', 'all')
      )
    } else {
      data = yield call(getApi, omit(params, ['sub', 'soft_archived']))
    }

    yield put({ type: actions.BUILDING_LIST_INITIATED, ...data })
  } catch (error) {
    const action = serverError(error)

    if (action.type === SERVER_404_ERROR) {
      yield put(replace('/404'))
    } else {
      yield put(action)
      yield put({ type: actions.BUILDING_LIST_ERROR, error })
    }
  }
}

function* load({ params }) {
  try {
    let getApi = api.building.getListTiny

    if (params?.soft_archived) {
      getApi = api.building.getArchivedListTiny
    } else if (params?.buildingGroup) {
      getApi = api.building.getGroupBuildings
    } else if (params?.contractorId) {
      getApi = api.contractor.getContractorBuildings
    } else if (params?.search) {
      getApi = api.building.getListSearch
    }

    let data

    if (params?.buildingGroup) {
      data = yield call(
        api.building.getGroupBuildings,
        params.buildingGroup,
        omit(params, 'buildingGroup', 'all')
      )
    } else if (params?.contractorId) {
      data = yield call(
        api.contractor.getContractorBuildings,
        params.contractorId,
        omit(params, 'contractorId', 'all')
      )
    } else {
      data = yield call(getApi, omit(params, ['sub', 'soft_archived']))
    }

    yield put({ type: actions.BUILDING_LIST_WAS_LOADED, ...data })
  } catch (error) {
    const action = serverError(error)

    if (action.type === SERVER_404_ERROR) {
      yield put(replace('/404'))
    } else {
      yield put(action)
      yield put({ type: actions.BUILDING_LIST_ERROR, error })
    }
  }
}

function* restore({ ids, params }) {
  try {
    const result = yield call(api.massAction.updateRequest, {
      model: 'Building',
      model_pks: JSON.stringify(ids),
      patch: { soft_archived: false },
    })

    const error = result.find(obj => obj.errors)

    if (error) {
      if (error.errors !== 'building_unexisted') {
        yield put(showPopupMessage(error.errors))
      }
    }

    const data = yield call(
      api.building.getArchivedListTiny,
      omit(params, ['sub', 'soft_archived'])
    )
    yield put({ type: actions.BUILDING_LIST_WAS_LOADED, ...data })
  } catch (error) {
    yield put(serverError(error))

    if (error.message.response.status === 400) {
      yield put(globalModalError(error.message.response.data.detail))
    }

    yield put({ type: actions.BUILDING_LIST_ERROR, error })
  }
}

function* remove({ ids, params, update_responsible_users }) {
  try {
    const result = yield call(api.massAction.updateRequest, {
      model: 'Building',
      model_pks: JSON.stringify(ids),
      patch: { soft_archived: true, update_responsible_users },
    })

    const error = result.find(obj => obj.errors)

    if (error) {
      if (error.errors !== 'building_unexisted') {
        yield put(showPopupMessage(error.errors))
      }
    }

    let data

    if (params.buildingGroup) {
      data = yield call(
        api.building.getGroupBuildings,
        params.buildingGroup,
        omit(params, 'buildingGroup', 'all')
      )
    } else {
      data = yield call(
        api.building.getListTiny,
        omit(params, ['sub', 'soft_archived'])
      )
    }

    yield put({ type: actions.BUILDING_LIST_WAS_LOADED, ...data })
  } catch (error) {
    yield put(serverError(error))

    if (error.message.response.status === 400) {
      yield put(globalModalError(error.message.response.data.detail))
    }

    yield put({ type: actions.BUILDING_LIST_ERROR, error })
  }
}

function* removeFromGroup({ buildings, params, update_responsible_users }) {
  try {
    yield call(api.building.removeFromGroup, params.buildingGroup, {
      buildings,
      update_responsible_users,
    })
    const data = yield call(
      api.building.getGroupBuildings,
      params.buildingGroup,
      omit(params, 'buildingGroup', 'all')
    )
    yield put({ type: actions.BUILDING_LIST_WAS_LOADED, ...data })
  } catch (error) {
    yield put(serverError(error))

    if (error.message.response.status === 400) {
      yield put(globalModalError(error.message.response.data.detail))
    }

    yield put({ type: actions.BUILDING_LIST_ERROR, error })
  }
}

function* updateGroupBuildings({
  buildings,
  params,
  update_responsible_users,
}) {
  try {
    yield call(api.building.updateGroupBuildings, params.buildingGroup, {
      buildings,
      update_responsible_users,
    })
    const data = yield call(
      api.building.getGroupBuildings,
      params.buildingGroup,
      omit(params, 'buildingGroup', 'all')
    )
    yield put({ type: actions.BUILDING_LIST_WAS_LOADED, ...data })
  } catch (error) {
    yield put(serverError(error))

    if (error.message.response.status === 400) {
      yield put(globalModalError(error.message.response.data.detail))
    }

    yield put({ type: actions.BUILDING_LIST_ERROR, error })
  }
}

export default function* watch() {
  yield all([
    fork(watchInitiate),
    fork(watchLoad),
    fork(watchRemove),
    fork(watchRemoveFromGroup),
    fork(watchRestore),
    fork(watchUpdateImages),
    fork(watchUpdateGroupBuildings),
  ])
}
