// @flow

import { put, call, fork, takeEvery } from 'redux-saga/effects'
import { get, map } from 'lodash-es'
import type { Saga } from 'redux-saga'

import api from '../../core/api'
import * as actions from './RequestCreate.actionTypes'
import {
  serverError,
  modalError,
  globalModalError,
} from '../Layout/Layout.actions'

function* watchCreate() {
  yield takeEvery(actions.REQUEST_CREATE_CREATE, create)
}

function* create(action) {
  try {
    const { params, relatedRequests } = action

    const result = yield call(api.request.createRequest, params)

    const buildingId = get(result, 'id', null)

    if (buildingId && relatedRequests && relatedRequests.length) {
      yield call(api.request.connectRequest, buildingId, {
        requests: map(relatedRequests, 'id'),
      })
    }

    yield put({ type: actions.REQUEST_CREATE_CREATED, id: buildingId })
  } catch (error) {
    yield put({ type: actions.REQUEST_CREATE_ERROR })

    const response = get(error, ['message', 'response'], {})

    if (response.status === 400) {
      yield put(modalError(response.data))

      return
    }

    if (response.status === 403) {
      yield put(
        globalModalError('RequestCreatePermissionsError', undefined, true)
      )

      return
    }

    yield put(serverError(error))
  }
}

export default function* (): Saga<void> {
  yield fork(watchCreate)
}
