// @flow

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { push } from 'connected-react-router'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { compose } from 'redux'

import type { ItemProps } from './index'
import Phone from '../../../Phone'
import Cell from './Cell'
import SelectCustom from '../../../Select/SelectCustom'
import StatusValue from './StatusValue'
import {
  USER_STATUS,
  BLOCKED_STATUS,
  NOT_CONFIRMED_STATUS,
  ACTIVE_STATUS,
} from '../../../../constants'

class Dweller extends Component {
  getOptions = () => {
    const {
      user,
      canUpdateRole,
      onReinvite,
      onUpdateRole,
      onRemove,
      onApprove,
      canDelete,
      onUpdateBuilding,
      isArchived,
    } = this.props
    const options = [
      {
        value: 'checkProfile',
        label: this.props.t('CheckProfile'),
        handler: this.open,
        icon: 'eye',
      },
    ]

    const userStatus = USER_STATUS[user.status]

    if (!isArchived && userStatus !== NOT_CONFIRMED_STATUS) {
      options.push({
        value: 'moveToOtherFlat',
        label: this.props.t('MoveToOtherFlat'),
        handler: () => onUpdateBuilding(user),
        icon: 'move',
      })
    }

    if (userStatus !== ACTIVE_STATUS) {
      options.push({
        value: 'sentInvitation',
        label: this.props.t('SentInvitation'),
        handler: () => onReinvite(user),
        icon: 'forward',
      })
    }

    if (canUpdateRole) {
      options.push({
        value: 'changeRole',
        label: this.props.t('ChangeRole'),
        handler: () => onUpdateRole(user),
        icon: 'pencil',
      })
    }

    if (user.permissions?.can_unblock_user) {
      if (!isArchived && userStatus === BLOCKED_STATUS) {
        options.push({
          value: 'unblock',
          icon: 'lock',
          label: this.props.t('unblock'),
          handler: () => this.props.onUpdateStatus(user),
        })
      }
    }

    if (user.permissions?.can_block_user) {
      options.push({
        value: 'block',
        icon: 'lock',
        label: this.props.t('block'),
        handler: () => this.props.onUpdateStatus(user, true),
      })
    }

    if (canDelete) {
      options.push({
        value: 'remove',
        label: this.props.t('Remove'),
        handler: () => onRemove(user),
        icon: 'bin',
      })
    }

    if (userStatus === NOT_CONFIRMED_STATUS && canDelete) {
      options.push({
        value: 'approveSignup',
        label: this.props.t('ApproveSignup'),
        handler: () => onApprove(user),
        icon: 'check',
      })
    }

    return options
  }

  props: ItemProps

  open = () => {
    const { user } = this.props

    this.props.dispatch(push(`/profile/${user.id}`))
  }

  preventOpen = e => {
    e.stopPropagation()
  }

  renderExtra = () => (
    <div className='table-extra table-extra--settings'>
      <SelectCustom
        options={this.getOptions()}
        onClick={this.preventOpen}
        onChange={opt => opt.handler()}
      />
    </div>
  )

  render() {
    const {
      user,
      selected,
      canDelete,
      canRestore,
      canUpdateRole,
      statusOptions,
      onUpdateBuilding,
      onUpdateRole,
      onToggle,
      isArchived,
    } = this.props
    const { id, fullname, avatar, phone, status } = user
    const preventDelete = user.permissions && !user.permissions.can_delete
    const preventResore = user.permissions && !user.permissions.can_restore
    const canSelect =
      (canDelete && !preventDelete) || (canRestore && !preventResore)
    const userClass = classnames('table__row', {
      'table__row--attention': selected,
    })

    return (
      <div className={userClass} key={`user-${id}`}>
        <div className='table__cell'>
          <div className='table__cell-wrapper' onClick={() => this.open(id)}>
            <div
              className='table-item table-item__user'
              style={{ display: 'flex', alignItems: 'flex-start' }}
            >
              {canSelect ? (
                <label
                  className='checkbox checkbox--medium checkbox--default checkbox--notext table-item__checkbox'
                  htmlFor={`check-${id}`}
                  onClick={this.preventOpen}
                >
                  <input
                    id={`check-${id}`}
                    type='checkbox'
                    value={id}
                    checked={selected}
                    onChange={onToggle}
                  />
                  <span />
                </label>
              ) : (
                <label className='checkbox checkbox--medium checkbox--default checkbox--notext table-item__checkbox'>
                  &nbsp;
                </label>
              )}
              <div className='table-item__user-info'>
                <i className='table-item__user-pic' onClick={this.open}>
                  <img src={avatar} alt='user' />
                </i>
                <div>
                  <span className='table-item__user-name' title={fullname}>
                    {fullname}
                  </span>
                  <StatusValue options={statusOptions} value={status} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Cell.Role
          canUpdate={canUpdateRole}
          user={user}
          onClick={onUpdateRole}
        />

        <Cell.Address
          user={user}
          archived={isArchived}
          onClick={onUpdateBuilding}
        />

        <Cell.Email user={user} />

        <div className='table__cell'>
          <div className='table__cell-wrapper'>
            <div className='table-item table-item__text'>
              {phone && (
                <Phone phone={`${phone[0] !== '+' ? '+' : ''}${phone}`} />
              )}
            </div>
          </div>
        </div>

        <Cell.Updated extra={this.renderExtra()} user={user} />
      </div>
    )
  }
}

export default compose(withTranslation('User'), connect())(Dweller)
