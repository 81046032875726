// @flow

export const MAILS_LIST_LOAD = 'MAILS_LIST_LOAD'
export const MAILS_LIST_WAS_LOADED = 'MAILS_LIST_WAS_LOADED'
export const MAILS_LIST_CLEAR = 'MAILS_LIST_CLEAR'

export const loadList = (search, filters) => ({
  type: MAILS_LIST_LOAD,
  params: { search, ...filters },
})

export const listWasLoaded = payload => ({
  type: MAILS_LIST_WAS_LOADED,
  payload,
})

export const clearMailList = () => ({ type: MAILS_LIST_CLEAR })
