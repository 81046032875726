// @flow

import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer, graphql } from 'react-relay'

import { Row } from '../../../../components/Block'
import {
  getRequest,
  getListTiny,
  updateRequestRaw,
} from '../../../../core/api/api.request'
import OrderProfileRequestInfo from './OrderProfileRequestInfo'
import Button from '../../../../components/Button'

import styles from './OrderProfileRequest.module.scss'
import NewSelectAsync from '../../../../components/NewSelectAsync'
import { get } from 'lodash-es'

const OrderProfileRequest = props => {
  const { t } = useTranslation('Orders')
  const [editing, setEditing] = useState(false)
  const [initialRequest, setInitialRequest] = useState(null)
  const [request, setRequest] = useState(null)
  const [loading, setLoading] = useState(true)

  const {
    data: { idwellRequestId, id: orderId },
  } = props

  const handleChange = value => {
    setRequest(value.id === request?.id ? null : value)
  }

  const handleEdit = () => {
    setEditing(true)
  }

  useEffect(() => {
    if (idwellRequestId) {
      getRequest(idwellRequestId).then(data => {
        setInitialRequest(data)
        setRequest(data)
      })
    }

    setLoading(false)
  }, [])

  const handleSave = () => {
    let requestId = idwellRequestId
    let nextOrderId = null

    if (request) {
      requestId = request.id
      nextOrderId = orderId
    }

    updateRequestRaw(requestId, {
      order_ids: nextOrderId ? [nextOrderId] : [],
    }).then(() => setInitialRequest(request))

    setEditing(false)
  }

  const handleCancel = () => {
    setEditing(false)
    setRequest(initialRequest)
  }

  const requestParams = request
    ? { wo_orders_excl: request.id }
    : { wo_orders_excl: 0 }

  return (
    <>
      <Row title={t('request')} working={loading} onEdit={handleEdit}>
        {editing ? (
          <NewSelectAsync
            pageSize={10}
            disabled={loading}
            selectedItems={request ? [request] : []}
            view='dropdown'
            api={getListTiny}
            searchKey='search'
            getLabel={option => option.title}
            getDescription={option => get(option, ['address_obj', 'value'])}
            getSelectedLabel={option => option.title}
            placeholder={t('Select:SelectRequest')}
            permanentParams={requestParams}
            onClick={handleChange}
          />
        ) : (
          <OrderProfileRequestInfo loading={loading} request={request} />
        )}
      </Row>
      {editing && (
        <div className={styles.buttons}>
          <Button.Save disabled={loading} onClick={handleSave}>
            {t('Save')}
          </Button.Save>
          <Button.Cancel disabled={loading} onClick={handleCancel}>
            {t('Cancel')}
          </Button.Cancel>
        </div>
      )}
    </>
  )
}

export default createFragmentContainer(OrderProfileRequest, {
  data: graphql`
    fragment OrderProfileRequest_data on CustomerOrderNode {
      idwellRequestId
      id
    }
  `,
})
