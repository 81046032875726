// @flow

import React, { Component } from 'react'
import classnames from 'classnames'
import { connect } from 'react-redux'

import Loader from '../Loader'
import ResidentList from './ResidentList'
import * as actions from './Residents.actionTypes'

type Props = {
  dispatch: Object => void,
  listInitiated: boolean,
}

class Residents extends Component<Props> {
  onListInitiated = () => {
    this.props.dispatch({ type: actions.RESIDENTS_LIST_READY })
  }

  render() {
    const { listInitiated } = this.props

    const contentClass = classnames({
      'content-initiating': !listInitiated,
    })

    return (
      <div className={contentClass}>
        {!listInitiated && <Loader text={false} type='big' />}
        <ResidentList onInit={this.onListInitiated} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state.residents,
})

export default connect(mapStateToProps)(Residents)
