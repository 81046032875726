// @flow

import type { Node } from 'react'

import React, { useState } from 'react'

import { useSelector, useDispatch } from 'react-redux'

import Modal from '../../../Modal'
import PhoneValidationPopUp from '../../../modals/PhoneValidationPopup'

import api from '../../../../core/api'

import { NOTIFICATION_ERROR } from '../../../Notification/NotificationItem'

import { USER_EMAIL_SAVE_PHONE_SUCCESS } from '../UserEmail.actionTypes'

type Props = {
  email: string,
  isOpen: boolean,
  onCloseModal: () => void,
  onShowNotification: () => void,
  password: string,
  phone: string,
  user: string,
}

const ValidationModal = ({
  isOpen,
  user,
  password,
  phone = ' ',
  onCloseModal,
  onShowNotification,
}): Node<Props> => {
  const [validationCodeErrors, setValidationCodeErrors] = useState([])
  const dispatch = useDispatch()
  const codeResendTimeout: number = useSelector(
    state => state.init.validate_phone.key_resend_timeout_minutes
  )

  const onClose = (): void => onCloseModal(true)

  const updateProfile = () => {
    api.profile
      .update(user, {
        current_password: password,
        phone,
      })
      .then(() => dispatch({ type: USER_EMAIL_SAVE_PHONE_SUCCESS, phone }))
      .catch(error => {
        setValidationCodeErrors([
          {
            type: NOTIFICATION_ERROR,
            text: error.message.response.data.errors,
            translate: true,
          },
        ])
      })
  }

  const validateCode = (code: string) => {
    api.system
      .validateCode({ number: phone, key: code })
      .then(() => {
        updateProfile()
        onClose()
        setValidationCodeErrors([])
      })
      .catch(error => {
        setValidationCodeErrors([error.message.response.data.errors])
      })
  }

  const resendValidationCode = () => {
    api.system.resendValidationCode({ number: phone }).catch(error =>
      onShowNotification({
        type: NOTIFICATION_ERROR,
        text: error.message.response.data.errors,
        translate: true,
      })
    )
  }

  return (
    <Modal
      contentLabel='requestControlPanelModals'
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      <PhoneValidationPopUp
        number={phone}
        codeResendTimeout={codeResendTimeout}
        validationCodeErrors={validationCodeErrors}
        onHide={onClose}
        onValidateCode={validateCode}
        onResendValidationCode={resendValidationCode}
      />
    </Modal>
  )
}

export default ValidationModal
