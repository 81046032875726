// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../../Button'
import WysiwygEditor from '../../WysiwygEditor'
import Input from '../../Auth/Input/Input'
import InputValidation from '../../forms/InputValidation'

const RequestBodyForm = props => {
  const { formik, data, isCancelButtonDisabled, isSaveButtonDisabled } = props

  const { t } = useTranslation('Request')

  const handleChange = value => formik.setFieldValue('text', value)

  const buttonSaveText = !data ? t('Create') : t('Save')

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='input input--font-xlarge input--default input--large input--block'>
        <Input
          value={formik.values.title}
          placeholder={t('TitlePlaceholder')}
          className='textarea-2__input'
          name='title'
          onChange={formik.handleChange}
        />
        {formik.errors.title && (
          <InputValidation message={[formik.errors.title]} />
        )}
      </div>
      <div className='textarea textarea--default textarea--xlarge textarea--block textarea--noresize signature'>
        <WysiwygEditor
          autoResizeOnInit
          placeholder={t('TextPlaceholder')}
          value={formik.values.text}
          onChange={handleChange}
        />
      </div>
      <div className='apply-profile-edit__submit'>
        <Button.Save
          type='submit'
          disabled={!formik.dirty || isSaveButtonDisabled}
        >
          {buttonSaveText}
        </Button.Save>
        <Button.Cancel
          type='button'
          disabled={isCancelButtonDisabled}
          onClick={props.handleReset}
        >
          {t('Cancel')}
        </Button.Cancel>
      </div>
    </form>
  )
}

export default RequestBodyForm
