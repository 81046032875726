/**
 * @flow
 * @relayHash 07cf5cd305740278925f62ec52bbc115
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type NewOrdersSelectQueryVariables = {|
  pageSize: number,
  first: number,
|};
export type NewOrdersSelectQueryResponse = {|
  +allCustomerOrders: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +offer: ?any,
        +orderNo: ?string,
      |}
    |}>
  |}
|};
export type NewOrdersSelectQuery = {|
  variables: NewOrdersSelectQueryVariables,
  response: NewOrdersSelectQueryResponse,
|};
*/


/*
query NewOrdersSelectQuery(
  $pageSize: Float!
  $first: Int!
) {
  allCustomerOrders(pageSize: $pageSize, first: $first) {
    edges {
      node {
        id
        offer
        orderNo
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "pageSize",
    "type": "Float!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "allCustomerOrders",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "pageSize",
        "variableName": "pageSize"
      }
    ],
    "concreteType": "CustomerOrderNodeFilterConnection",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "edges",
        "storageKey": null,
        "args": null,
        "concreteType": "CustomerOrderNodeFilterEdge",
        "plural": true,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "CustomerOrderNode",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "offer",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "orderNo",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "NewOrdersSelectQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "NewOrdersSelectQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "NewOrdersSelectQuery",
    "id": null,
    "text": "query NewOrdersSelectQuery(\n  $pageSize: Float!\n  $first: Int!\n) {\n  allCustomerOrders(pageSize: $pageSize, first: $first) {\n    edges {\n      node {\n        id\n        offer\n        orderNo\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'aa4faa30280df72be97a57811f4a7ff2';
module.exports = node;
