// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import type Node from 'react'

import TopPagination from '../../Pagination/TopPagination'
import Button from '../../Button'
import Toolbox from '../../Toolbox'
import ToolboxCell from '../../Users/UserList/Toolbox/ToolboxCell'

type Props = {
  checked: boolean,
  isLoading: boolean,
  items: Array<Object>,
  meta: Object,
  onAdd: Function,
  onRemove: Function,
  permissions: Object,
  selected: boolean,
  selectedItems?: Array<any>,
  setSelected: Function,
}

const AutomateToolbox = (props: Props): Node => {
  const {
    meta,
    items,
    permissions,
    selectedItems,
    selected,
    checked,
    isLoading,
  } = props
  const { t } = useTranslation('Automate')

  return (
    <Toolbox
      showSelectAllCheckbox
      checked={checked}
      setSelected={props.setSelected}
      selected={selected}
      items={items}
      itemValueKey='id'
    >
      {permissions?.can_create &&
        (!selectedItems || selectedItems.length === 0) && (
          <ToolboxCell>
            <Button.Save icon='add' disabled={isLoading} onClick={props.onAdd}>
              {t('Add')}
            </Button.Save>
          </ToolboxCell>
        )}
      {permissions?.can_delete && selectedItems && selectedItems.length !== 0 && (
        <ToolboxCell>
          <Button.Remove
            disabled={!selectedItems.length || isLoading}
            onClick={() => props.onRemove()}
          >
            {t('Delete')}
          </Button.Remove>
        </ToolboxCell>
      )}
      <ToolboxCell isRight>
        <TopPagination
          classes='pager pager--large pager--default-2'
          meta={meta}
        />
      </ToolboxCell>
    </Toolbox>
  )
}

export default AutomateToolbox
