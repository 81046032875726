// @flow

import { CHECKING_COUNT, RETRY_TIMEOUT } from './MappedDocument.constants'
import { getDocumentMappingStatus } from '../../core/api/api.documentMapping'

export async function getStatus(
  eventId: string,
  checkingCount: number = CHECKING_COUNT
) {
  let status = 'new'
  let response = null

  let count = checkingCount

  while ((status === 'new' || status === 'in_progress') && count) {
    count--

    status = await new Promise(resolve =>
      setTimeout(() => {
        getDocumentMappingStatus(eventId).then((data: Object) => {
          response = data

          return resolve(data.status)
        })
      }, RETRY_TIMEOUT)
    )
  }

  return response
}
