export const LINKS = ['/requests', '/buildings', '/flats', '/users/']

const FLATS = 'flats_list'
const REQUESTS = 'requests_list'
const BUILDINGS = 'buildings_list'
const ACTIVITIES = 'activities_list'
const INHABITANTS = 'inhabitants_list'
const EMAILS = 'email_threads_list'

export const redesignLinksMatch = {
  [FLATS]: '/flats',
  [REQUESTS]: '/requests',
  [BUILDINGS]: '/buildings',
  [ACTIVITIES]: '/activities',
  [INHABITANTS]: '/users/',
  [EMAILS]: '/mails',
}
