// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'

import EmptyList from '../../EmptyList'
import FlatListTable from './FlatListTable'
import BottomPagination from '../../Pagination/BottomPagination'

const FlatList = props => {
  const { flats, loading, meta } = props

  const { t } = useTranslation('Building')

  return (
    <>
      {flats.length ? (
        <FlatListTable
          flats={flats}
          loading={loading}
          setFlats={props.setFlats}
        />
      ) : (
        !loading && (
          <EmptyList
            icon='apartment'
            title={t('ThereAreNoFlatsThatMatchTheFilter')}
          />
        )
      )}
      {!loading && (
        <BottomPagination classes='paginator paginator--middle' meta={meta} />
      )}
    </>
  )
}

export default FlatList
