// @flow

import React from 'react'

import ImportMapTable from '../../components/ImportMapTable'

type Props = {
  params: Object,
}

const ImportMap = ({ match: { params } }: Props) => (
  <ImportMapTable importId={params.importId} />
)

export default ImportMap
