// @flow

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import ClickOutHandler from 'react-onclickout'
import { compose } from 'redux'
import type { Dispatch } from 'redux'

import Button from '../Button'
import Icon from '../Icon'
import Modal from '../Modal'
import * as actions from '../CompanyView/CompanyView.actionTypes'
import { selectCreateModal } from '../CompanyView/CompanyView.selectors'
import UkAddressPopup from '../modals/UkAddressPopup/UkAddressPopup'

type Action = 'COMPANY_VIEW_ADDRESS_MODAL_CREATE_TOGGLE'

type Props = {
  dispatch: Dispatch<{
    type: Action,
  }>,
  modal?: React$Node,
  t: (string, ?Object) => string,
}

type State = { isMenuOpen: boolean }

class AddUkAddressWidget extends Component<Props, State> {
  state = {
    isMenuOpen: false,
  }

  onClickOut = () => this.setState({ isMenuOpen: false })

  toggleAddressModal = () =>
    this.props.dispatch({
      type: actions.COMPANY_VIEW_ADDRESS_MODAL_CREATE_TOGGLE,
    })

  toggleVisibility = () =>
    this.setState(prevState => ({ isMenuOpen: !prevState.isMenuOpen }))

  render() {
    const { modal } = this.props
    const { isMenuOpen } = this.state

    return (
      <ClickOutHandler onClickOut={this.onClickOut}>
        <aside
          className='widget widget--no-border widget-add-tab'
          style={{ marginBottom: '2rem' }}
        >
          <header
            className='widget__new-header widget__new-header--invert widget__new-header--nodblclick widget__header-add-prop'
            onClick={this.toggleVisibility}
          >
            <span className='widget__new-header-text'>
              {this.props.t('Common:Add')}
            </span>
            <Button.Minimize isOpen={isMenuOpen} />
          </header>
          {isMenuOpen && (
            <div className='widget__menu widget__menu-actions'>
              <div
                className='widget__menu-item'
                key='address'
                onClick={this.toggleAddressModal}
              >
                <Icon id='address' />
                <span className=''>{this.props.t('OfficeAddress')}</span>
              </div>
            </div>
          )}
        </aside>
        {modal && (
          <Modal
            contentLabel=''
            className='Modal__Bootstrap modal-dialog'
            isOpen={!!modal}
            onRequestClose={this.toggleAddressModal}
          >
            <UkAddressPopup
              t={this.props.t}
              closeModal={this.toggleAddressModal}
            />
          </Modal>
        )}
      </ClickOutHandler>
    )
  }
}

const mapStateToProps = state => ({
  modal: selectCreateModal(state),
})

export default compose(
  withTranslation('Company'),
  connect(mapStateToProps)
)(AddUkAddressWidget)
