// @flow

import * as actions from './SelectAsync.actionTypes'

const getUpdatedData = (stateData, key, data) => ({
  ...stateData,
  [key]: {
    ...stateData[key],
    ...data,
  },
})

const initialState = {
  data: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.SELECT_ASYNC_INITIATING:
      return {
        ...initialState,
        data: getUpdatedData(state.data, action.key, {
          loading: true,
          valueInitiated: false,
          optionsInitiated: false,
          menuLoader: false,
          meta: {},
          error: false,
          ...(action.withDisableOnLoad ? {} : { value: null }),
        }),
      }
    case actions.SELECT_ASYNC_INITIATED:
      return {
        ...state,
        data: getUpdatedData(state.data, action.key, {
          ...state.data[action.key],
          value: action.value,
          valueInitiated: true,
          options: action.results ? action.results.objects : action[action.key],
          optionsInitiated: !!action.results,
          loading: false,
          meta: action.meta || {},
        }),
      }
    case actions.SELECT_ASYNC_RESET: {
      const value = state.data[action.key] ? state.data[action.key].value : null

      return {
        ...initialState,
        data: {
          ...state.data,
          [action.key]: action.withDisableOnLoad ? { value } : {},
        },
      }
    }
    case actions.SELECT_ASYNC_RESET_OPTIONS:
      return {
        ...state,
        data: getUpdatedData(state.data, action.key, {
          ...state.data[action.key],
          options: [],
          optionsInitiated: false,
          meta: {},
        }),
      }
    case actions.SELECT_ASYNC_LOADING: {
      const { options } = state.data[action.key]

      return {
        ...state,
        data: getUpdatedData(state.data, action.key, {
          ...state.data[action.key],
          loading: true,
          options: action.params.page === 1 ? [] : options,
        }),
      }
    }
    case actions.SELECT_ASYNC_LOADED: {
      const { options } = state.data[action.key]

      return {
        ...state,
        data: getUpdatedData(state.data, action.key, {
          loading: false,
          menuLoader: false,
          options: !options
            ? action.results.objects
            : options.concat(action.results.objects),
          optionsInitiated: true,
          meta: action.meta,
        }),
      }
    }
    case actions.SELECT_ASYNC_SET_VALUE:
      return {
        ...state,
        data: getUpdatedData(state.data, action.key, {
          value: action.value,
        }),
      }
    case actions.SELECT_ASYNC_SHOW_MENU_LOADER:
      return {
        ...state,
        data: getUpdatedData(state.data, action.key, {
          menuLoader: true,
        }),
      }
    case actions.SELECT_ASYNC_ERROR:
      return {
        ...state,
        data: getUpdatedData(state.data, action.key, {
          loading: false,
          error: action.error.message,
        }),
      }
    case actions.SELECT_ASYNC_CHANGE_FILTER:
      return {
        ...state,
        data: getUpdatedData(state.data, action.key, {
          optionsInitiated: false,
        }),
      }
    default:
      return state
  }
}
