// @flow

import React from 'react'
import type { Node } from 'react'

import Loader from '../Loader'

import styles from './SelectLoader.module.scss'

const SelectLoader = (): Node => {
  return (
    <div className={styles.loader}>
      <Loader />
    </div>
  )
}

export default SelectLoader
