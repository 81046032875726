// @flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import type { Node } from 'react'

import { SimpleBlock, Header } from '../Block'
import Settings from '../Settings'
import SelectRegion from '../SelectRegion'

type Props = {
  language: Object,
  onClickSaveLanguage: Object => void,
  working: boolean,
}

const UserLanguage = (props: Props): Node => {
  const { working, language } = props

  const { t } = useTranslation('Settings')

  const [cleanValue, setCleanValue] = useState(null)

  const formik = useFormik({
    validateOnChange: true,
    enableReinitialize: true,
    initialValues: {
      language: { value: language.id, label: language.name },
    },
    validationSchema: Yup.object({
      language: Yup.object()
        .shape({ value: Yup.number(), label: Yup.string() })
        .required(),
    }),
    onSubmit: () => {
      props.onClickSaveLanguage(cleanValue)
    },
  })

  const handleChange = (value, cleanOption) => {
    formik.setFieldValue('language', value)

    setCleanValue(cleanOption)
  }

  if (!language) {
    return null
  }

  return (
    <SimpleBlock working={working}>
      <Header header>{t('PersonalLanguageTitle')}</Header>
      <form onSubmit={formik.handleSubmit}>
        <Settings.Row label={t('Language')}>
          <SelectRegion
            params='language'
            placeholder={t('Choice')}
            value={formik.values.language}
            onChange={handleChange}
          />
        </Settings.Row>
        <Settings.Actions
          withChanges={formik.dirty}
          isValid={formik.isValid}
          working={working}
          onCancel={formik.handleReset}
        />
      </form>
    </SimpleBlock>
  )
}

export default UserLanguage
