// @flow

import React from 'react'
import classnames from 'classnames'

import FlatListTableHeader from './FlatListTableHeader'
import FlatListTableBody from './FlatListTableBody'

const FlatListTable = props => {
  const { loading, flats } = props

  const tableClass = classnames('table table--default', {
    'working-overlay': loading,
  })

  return (
    <div className={tableClass}>
      <div className='table__wrapper' style={{ tableLayout: 'fixed' }}>
        <FlatListTableHeader loading={loading} />
        <FlatListTableBody
          flats={flats}
          loading={loading}
          setFlats={props.setFlats}
        />
      </div>
    </div>
  )
}

export default FlatListTable
