// @flow

import { all, put, call, fork, takeEvery } from 'redux-saga/effects'

import api from '../../../core/api'
import { serverError, globalModalError } from '../../Layout/Layout.actions'
import * as actions from './BuildingFlats.actionTypes'
import * as flatPopupActions from '../../modals/AddFlatPopup/AddFlatPopup.actionTypes'
import { BUILDING_VIEW_RELOAD } from '../../BuildingView/BuildingView.actionTypes'

function* watchInit() {
  yield takeEvery(actions.BUILDING_FLATS_INITIATING, init)
}

function* watchRemove() {
  yield takeEvery(actions.BUILDING_FLATS_REMOVE, remove)
}

function* watchReload() {
  yield takeEvery(actions.BUILDING_FLATS_RELOAD, reload)
}

function* watchLoad() {
  yield takeEvery(actions.BUILDING_FLATS_LOAD, load)
}

function* watchFlatAdd() {
  yield takeEvery(flatPopupActions.FLAT_POPUP_ADDED_FLAT, flatAdded)
}

function* init({ params }) {
  try {
    const { building, ...rest } = params

    const data = yield call(api.building.getBuildingFlats, building, {
      ...rest,
    })
    yield put({ type: actions.BUILDING_FLATS_INITIATED, ...data })
  } catch (error) {
    yield put(serverError(error))
    yield put({ type: actions.BUILDING_FLATS_ERROR, error })
  }
}

function* remove({ values, params }) {
  try {
    const { page, building, get_dwellers, get_landlords } = params

    yield call(api.massAction.updateRequest, {
      model: 'Flat',
      model_pks: JSON.stringify(values),
      patch: { soft_archived: true },
    })

    const data = yield call(api.building.getBuildingFlats, building, {
      page,
      get_dwellers,
      get_landlords,
    })
    yield put({ type: actions.BUILDING_FLATS_UPDATE, ...data })
  } catch (error) {
    yield put(serverError(error))

    if (error.message.response.status === 400) {
      yield put(globalModalError(error.message.response.data.detail))
    }

    yield put({ type: actions.BUILDING_FLATS_ERROR, error })
  }
}

function* flatAdded({ flat, params = {} }) {
  try {
    const data = yield call(api.building.getBuildingFlats, flat.building, {
      ...params,
    })

    yield put({ type: actions.BUILDING_FLATS_RELOAD_UPDATE, ...data })

    if (data.results.objects.length === 1) {
      yield put({ type: BUILDING_VIEW_RELOAD })
    }
  } catch (error) {
    yield put(serverError(error))
    yield put({ type: actions.BUILDING_FLATS_ERROR, error })
  }
}

function* reload({ params }) {
  try {
    const { building, ...rest } = params

    const data = yield call(api.building.getBuildingFlats, building, {
      ...rest,
    })

    yield put({ type: actions.BUILDING_FLATS_RELOAD_UPDATE, ...data })
  } catch (error) {
    yield put(serverError(error))
    yield put({ type: actions.BUILDING_FLATS_ERROR, error })
  }
}

function* load({ params }) {
  try {
    const { building, ...rest } = params

    const data = yield call(api.building.getBuildingFlats, building, {
      ...rest,
    })

    yield put({ type: actions.BUILDING_FLATS_UPDATE, ...data })
  } catch (error) {
    yield put(serverError(error))
    yield put({ type: actions.BUILDING_FLATS_ERROR, error })
  }
}

export default function* watch() {
  yield all([
    fork(watchInit),
    fork(watchRemove),
    fork(watchReload),
    fork(watchLoad),
    fork(watchFlatAdd),
  ])
}
