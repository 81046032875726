import { UPDATE_COUNTERS_SUCCESS } from './init.actionTypes'

const initialState = {
}

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_COUNTERS_SUCCESS:
      return {
        ...state,
        ...action.counters
      }

    default:
      return state
  }
}
