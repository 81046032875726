// @flow

import { FORMIK_EMPTY_VALUE } from '../../../../constants'

export const ACCOUNTANT = 'accountant'
export const ASSET_MANAGER = 'asset_manager'
export const BROKER = 'broker'
export const IDENTICAL_ADDRESS = 'identical_address'
export const NUMBER_OWNER = 'number_owner'
export const TAX_CONSULTANT = 'tax_consultant'
export const WARRANTY = 'warranty'

export const MAX_LENGTH = 256

export const KEYS = [
  ACCOUNTANT,
  ASSET_MANAGER,
  BROKER,
  IDENTICAL_ADDRESS,
  NUMBER_OWNER,
  TAX_CONSULTANT,
  WARRANTY,
]

export const DEFAULT_VALUES: Object = {
  [ACCOUNTANT]: FORMIK_EMPTY_VALUE,
  [ASSET_MANAGER]: FORMIK_EMPTY_VALUE,
  [BROKER]: FORMIK_EMPTY_VALUE,
  [IDENTICAL_ADDRESS]: FORMIK_EMPTY_VALUE,
  [NUMBER_OWNER]: FORMIK_EMPTY_VALUE,
  [TAX_CONSULTANT]: FORMIK_EMPTY_VALUE,
  [WARRANTY]: FORMIK_EMPTY_VALUE,
}
