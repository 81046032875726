// @flow

import React, { useContext } from 'react'
import type { Node } from 'react'

import { useThemeColor } from '../../../../../../../../../../hooks'
import InlineSvg from '../../../../../../../../../../components/InlineSvg'
import Label from '../../../../../../../../../../components/Label'
import { hexToRGB } from '../../../../../../../../../../utils/utils'
import RequestActivityContext from '../../../../../../../RequestActivity.context'
import Email from './Email'
import Files from './Files'

import styles from './Type.module.scss'

type Props = {
  fileCount: number,
  icon: Object,
  id: number,
  name: string,
  onChange: () => void,
  phaseId: number,
  priority: ?Object,
  requestThreadCount: number,
  title: string,
  unreadRequestThreadCount: number,
}

const Type = (props: Props): Node => {
  const {
    icon,
    name,
    title,
    priority,
    requestThreadCount,
    unreadRequestThreadCount,
    id,
    phaseId,
    fileCount,
  } = props

  const { isDweller, setThread, setFileActivityModal, setPhaseId, outbound } =
    useContext(RequestActivityContext)

  const themeColor = useThemeColor()

  const rgbTheme = hexToRGB(themeColor)

  const spanProps = Object.assign(
    {},
    !isDweller && !outbound
      ? {
          onClick: () => {
            setPhaseId(phaseId)
            props.onChange()
          },
        }
      : undefined
  )

  const handleClick = e => {
    e.stopPropagation()

    setThread({ activityId: id })
  }

  const handleClickFile = e => {
    e.stopPropagation()
    setPhaseId(phaseId)
    setFileActivityModal({ activityId: id })
  }

  return (
    <div className={styles.type} style={{ '--rgbThemeColor': rgbTheme }}>
      <InlineSvg url={icon.origin} alt={name} />
      <span {...spanProps}>{title}</span>
      <div className={styles.attaches}>
        {priority && (
          <Label text={priority.value} className={styles.label} color='theme' />
        )}
        {!!requestThreadCount && (
          <Email
            requestThreadCount={requestThreadCount}
            unreadRequestThreadCount={unreadRequestThreadCount}
            onClick={handleClick}
          />
        )}
        {!!fileCount && <Files count={fileCount} onClick={handleClickFile} />}
      </div>
    </div>
  )
}

export default Type
