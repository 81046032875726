import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { pick, isEqual, omitBy, isNil } from 'lodash-es'
import { useSelector, useDispatch } from 'react-redux'

import { ErrorMessage, Formik, Form } from 'formik'
import Textarea from 'react-textarea-autosize'
import { globalModalError } from '../../Layout/Layout.actions'
import * as Yup from 'yup'
import classnames from 'classnames'

import { updateContractor } from '../../../core/api/api.contractor'

import Input from '../../Input'
import PhoneInput from '../../forms/PhoneInput'
import Phone from '../../Phone'
import Icon from '../../Icon'
import ValidationText from '../../ValidationText'
import ButtonsBar from '../../../containers/ProviderProfilePage/ButtonsBar'
import Button from '../../Button'

import styles from './ContractorInfo.module.scss'

import { CONTACT_TYPES_SVG } from '../../../constants'
import {
  PHONE_REG_EXP,
  WEBSITE_REG_EXP,
  MAX_EMAIL_LENGTH,
  MAX_WEBSITE_LENGTH,
  MAX_WORKING_HOURS_LENGTH,
  MAX_ADDRESS_LENGTH,
  MAX_DESCRIPTION_LENGTH,
} from '../Contractors.constants'

import { getHref } from '../../../utils/utils'

const contactTypes = {
  email: {
    name: 'email',
    inputType: 'email',
    translateKey: 'Email',
  },
  phone: {
    name: 'phone',
    inputType: 'text',
    translateKey: 'Phone',
  },
  website: {
    name: 'website',
    inputType: 'url',
    translateKey: 'Website',
  },
  order_email: {
    name: 'order_email',
    inputType: 'email',
    translateKey: 'OrderEmail',
  },
  order_phone: {
    name: 'order_phone',
    inputType: 'text',
    translateKey: 'OrderPhone',
  },
  working_hours: {
    name: 'working_hours',
    inputType: 'text',
    translateKey: 'working_hours',
  },
  address: {
    name: 'address',
    inputType: 'text',
    translateKey: 'address',
  },
  description: {
    name: 'description',
    inputType: 'text',
    translateKey: 'description',
  },
}

const Contacts = props => {
  const { contractor } = props
  const { t } = useTranslation('Contractors')
  const dispatch = useDispatch()
  const [edit, setEdit] = useState(false)
  const countryCode = useSelector(state => state.init.uk.country_obj.code)
  const [isHover, setHover] = useState(false)
  const handleMouseEnter = () => setHover(true)
  const handleMouseLeave = () => setHover(false)

  const contacts = Object.keys(contactTypes).map(contactKey => ({
    ...contactTypes[contactKey],
    value: contractor[contactKey] || '',
    type: contactKey,
  }))

  const initialValues = pick(contractor, Object.keys(contactTypes))

  const renderError = text => <ValidationText active text={text} />

  const getValidationSchema = () => {
    const getErrorText = name => t('EmptyInput', { key: t(name) })

    return Yup.object().shape({
      website: Yup.string()
        .nullable()
        .matches(WEBSITE_REG_EXP, t('InvalidURL'))
        .max(
          MAX_WEBSITE_LENGTH,
          t('MaxInputLength', { max: MAX_WEBSITE_LENGTH })
        ),
      email: Yup.string()
        .email('Invalid email')
        .required(getErrorText('email'))
        .max(MAX_EMAIL_LENGTH, t('MaxInputLength', { max: MAX_EMAIL_LENGTH })),
      phone: Yup.string().nullable().matches(PHONE_REG_EXP, t('InvalidPhone')),
      order_email: Yup.string()
        .email('Invalid email')
        .nullable()
        .max(MAX_EMAIL_LENGTH, t('MaxInputLength', { max: MAX_EMAIL_LENGTH })),
      order_phone: Yup.string()
        .nullable()
        .matches(PHONE_REG_EXP, t('InvalidPhone')),
      working_hours: Yup.string()
        .nullable()
        .max(
          MAX_WORKING_HOURS_LENGTH,
          t('MaxInputLength', { max: MAX_WORKING_HOURS_LENGTH })
        ),
      description: Yup.string()
        .nullable()
        .max(
          MAX_DESCRIPTION_LENGTH,
          t('MaxInputLength', { max: MAX_DESCRIPTION_LENGTH })
        ),
      address: Yup.string()
        .nullable()
        .max(
          MAX_ADDRESS_LENGTH,
          t('MaxInputLength', { max: MAX_ADDRESS_LENGTH })
        ),
    })
  }

  const onSubmit = (values, { setSubmitting }) => {
    const params = {
      ...values,
      phone: values.phone === '+' ? '' : values.phone,
      order_phone: values.order_phone === '+' ? '' : values.order_phone,
    }
    updateContractor(contractor.id, omitBy(params, isNil))
      .then(() => {
        setEdit(false)
        props.onUpdate()
      })
      .catch(err => {
        dispatch(
          globalModalError(
            err.message?.response?.data?.errors || 'Common:Error',
            '',
            true
          )
        )
      })
      .finally(() => setSubmitting(false))
  }

  const disabled = ({ isSubmitting, values }) =>
    isSubmitting || isEqual(values, initialValues)

  const renderEmailNotes = () => (
    <span
      className={styles.notes}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Icon id='about' className={styles.icon} />
      {isHover && (
        <div className={styles.tooltip}>
          <div />
          <span>{t('EmailNotes')}</span>
        </div>
      )}
    </span>
  )

  const renderInput = formikProps => contact => {
    const { setFieldValue, values, touched, errors } = formikProps
    const { type, translateKey, inputType } = contact
    const title = t(translateKey)
    const isPhone = type === 'phone' || type === 'order_phone'
    const isDescription = type === 'description'
    const isMainEmail = type === 'email'
    const handleChange = value => setFieldValue(type, value)
    const errorClassName = {
      [styles.inputError]: errors[type] && touched[type],
    }
    const inputClassName = classnames(errorClassName)
    const phoneClassName = classnames(
      'input input--default input--block input--large input--multiple',
      errorClassName
    )

    return (
      <div key={type} className={styles['contact__row']}>
        <div className={styles['contact__header']} title={title}>
          {CONTACT_TYPES_SVG[type] && (
            <Icon
              className={styles['contact__icon']}
              id={CONTACT_TYPES_SVG[type]}
            />
          )}
          <span className={styles['contact__label']}>{title}</span>
          {isMainEmail && renderEmailNotes()}
        </div>
        <div style={{ width: '100%' }}>
          {isPhone ? (
            <PhoneInput
              type='text'
              value={values[type]}
              name={type}
              className={phoneClassName}
              countryCode={countryCode}
              onChange={handleChange}
            />
          ) : isDescription ? (
            <Textarea
              className={styles.textarea}
              value={values[type]}
              name={type}
              onChange={e => setFieldValue(type, e.target.value)}
            />
          ) : (
            <div className='input input--large input--block input--default input--success'>
              <Input.Regular
                name={type}
                type={inputType}
                className={inputClassName}
                value={values[type]}
                placeholder={t(translateKey)}
                onChange={setFieldValue}
              />
            </div>
          )}
          <ErrorMessage name={type} render={renderError} />
        </div>
      </div>
    )
  }

  const renderEditForm = () => (
    <Formik
      initialValues={initialValues}
      validationSchema={getValidationSchema()}
      onSubmit={onSubmit}
    >
      {formikProps => (
        <Form>
          {contacts.map(renderInput(formikProps))}

          <ButtonsBar
            disabled={disabled(formikProps)}
            cancelDisabled={formikProps.isSubmitting}
            onCancel={() => setEdit(false)}
            onSubmit={formikProps.handleSubmit}
          />
        </Form>
      )}
    </Formik>
  )

  const renderContact = (contact, i) => {
    const { value } = contact

    if (!value) {
      return null
    }

    const { type, translateKey } = contact
    const contactLabel = t(translateKey)
    const hrefValue = getHref(type, value)
    const isMainEmail = type === 'email'
    const isDescription = type === 'description'
    const isPhone = type === 'phone' || type === 'order_phone'

    return (
      <div className={styles['contact']} key={`contact-${i}`}>
        <div className={styles['contact__header']} title={contactLabel}>
          {CONTACT_TYPES_SVG[type] && (
            <Icon
              className={styles['contact__icon']}
              id={CONTACT_TYPES_SVG[type]}
            />
          )}
          <span className={styles['contact__label']}>{contactLabel}</span>
          {isMainEmail && renderEmailNotes()}
        </div>
        <div className={styles['contact__value-container']}>
          {type === 'website' ? (
            <a
              title={value}
              className={styles['contact__value']}
              href={hrefValue}
              target='_blank'
              rel='noopener noreferrer'
            >
              {value}
            </a>
          ) : isPhone ? (
            <Phone phone={`${value && value[0] !== '+' ? '+' : ''}${value}`} />
          ) : (
            <span
              className={
                styles[
                  isDescription ? 'contact__description' : 'contact__value'
                ]
              }
            >
              {value}
            </span>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className={styles.block}>
      <div className={styles.description}>
        <div className={styles['contacts-header']}>
          <span>{t('Contacts')}</span>
          {!edit && !contractor.archived && (
            <Button.Edit onClick={() => setEdit(true)} />
          )}
        </div>
        <div className={styles.contacts}>
          {edit ? renderEditForm() : contacts.map(renderContact)}
        </div>
      </div>
    </div>
  )
}

export default Contacts
