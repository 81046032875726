// @flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import classnames from 'classnames'
import type { Node } from 'react'

import Modal from '../Modal'
import { createDirectory } from '../../core/api/api.file'
import { isStaffUser } from '../../utils/utils'
import { useOverflow, useUser } from '../../hooks'
import NewSelectSimple from '../NewSelectSimple'
import { trim } from 'lodash-es'

type Props = {
  buttonTitle: string,
  closePopup: () => void,
  isOpen: boolean,
  outbound?: string,
  parent: number,
  setLoading: boolean => void,
  title: string,
}

const FolderCreatingPopup = (props: Props): Node => {
  const { isOpen, title, buttonTitle, outbound, parent } = props

  const [loading, setLoading] = useState(false)

  const user = useUser()

  useOverflow()

  const isStaff = isStaffUser(user)

  const { t } = useTranslation('Files')

  const formik = useFormik({
    validateOnChange: true,
    initialValues: {
      name: '',
      usersAccess: !outbound && isStaff ? 'deny' : 'allow',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .max(100, t('MaxNameLength'))
        .required(t('NameRequired')),
      usersAccess: Yup.string(),
    }),
    onSubmit: values => {
      if (trim(values.name).length === 0) {
        formik.setFieldValue('name', '')

        return
      }

      setLoading(true)
      createDirectory({
        outbound,
        parent,
        name: values.name,
        users_access: values.usersAccess,
      }).then(() => {
        formik.setSubmitting(false)
        formik.resetForm()
        setLoading(false)
        props.setLoading(true)
        props.closePopup()
      })
    },
  })

  const handleClose = e => {
    e.preventDefault()
    formik.setSubmitting(false)
    formik.resetForm()
    props.closePopup()
  }

  const blockClassName = classnames(
    'input input--medium input--block input--default',
    { 'input--error': formik.errors.name }
  )

  const handleSelect = option => {
    formik.setFieldValue('usersAccess', option.value)
  }

  const accessOptions = [
    { label: t('SetPublic'), value: 'allow' },
    { label: t('SetPrivate'), value: 'deny' },
  ]

  const selectedOption = accessOptions.find(
    option => option.value === formik.values.usersAccess
  )

  return (
    <Modal
      isOpen={isOpen}
      contentLabel=''
      className='Modal__Bootstrap modal-dialog'
    >
      <form onSubmit={formik.handleSubmit}>
        <div className='modal__title'>{t(title)}</div>
        <button className='modal__close' type='button' onClick={handleClose} />
        <div className='modal__body'>
          <div className='m-a10__row'>
            <div className='m-a10__cell m-a10__cell_1'>
              <span className='m-a10__label'>{t('FolderName')}</span>
            </div>
            <div className='m-a10__cell m-a10__cell_2'>
              <div className={blockClassName}>
                <input
                  autoFocus
                  type='text'
                  name='name'
                  placeholder={t('FolderNamePlaceholder')}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                />
                {formik.errors.name && (
                  <span className='input__msg'>{formik.errors.name}</span>
                )}
              </div>
            </div>
          </div>
          {!outbound && isStaff && (
            <div className='m-a10__row'>
              <div className='m-a10__cell m-a10__cell_1'>
                <span className='m-a10__label'>{t('FolderAccess')}</span>
              </div>
              <div className='m-a10__cell m-a10__cell_2'>
                <div className={blockClassName}>
                  <NewSelectSimple
                    name='users-access'
                    clearable={false}
                    value={selectedOption}
                    options={accessOptions}
                    onChange={handleSelect}
                  />
                  {formik.errors.usersAccess && (
                    <span className='input__msg'>
                      {formik.errors.usersAccess}
                    </span>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className='modal__submit'>
          <button
            disabled={loading}
            className='button button--large button--success'
            type='submit'
          >
            {t(buttonTitle)}
          </button>
          <button
            className='button button--large button--danger-3'
            type='button'
            onClick={handleClose}
          >
            {t('Cancel')}
          </button>
        </div>
      </form>
    </Modal>
  )
}

export default FolderCreatingPopup
