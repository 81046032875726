// @flow

import React from 'react'
import classnames from 'classnames'

import styles2 from './NewFilterAsync/NewFilterAsyncOption.module.scss'
import styles3 from './RequestProviders.module.scss'

const RequestProvidersListItem = props => {
  const {
    option: { id, name },
    contractors,
  } = props

  const isSelected = contractors.map(c => c.id).includes(id)

  const listItemStyle = classnames(styles2.option, {
    [styles3.checked]: isSelected,
  })

  return (
    <li
      className={listItemStyle}
      style={{ maxWidth: 'initial' }}
      onClick={props.handleClick(id)}
    >
      <span>{name}</span>
    </li>
  )
}

export default RequestProvidersListItem
