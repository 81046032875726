// @flow

export const REQUEST_CONSTRUCTOR_INITIATE = 'REQUEST_CONSTRUCTOR_INITIATE'
export const REQUEST_CONSTRUCTOR_WAS_INITIATED =
  'REQUEST_CONSTRUCTOR_WAS_INITIATED'
export const REQUEST_CONSTRUCTOR_UPDATE = 'REQUEST_CONSTRUCTOR_UPDATE'
export const REQUEST_CONSTRUCTOR_WAS_UPDATED = 'REQUEST_CONSTRUCTOR_WAS_UPDATED'
export const REQUEST_CONSTRUCTOR_GROUPS_WERE_UPDATED =
  'REQUEST_CONSTRUCTOR_GROUPS_WERE_UPDATED'

export const REQUEST_CONSTRUCTOR_ADD_GROUP = 'REQUEST_CONSTRUCTOR_ADD_GROUP'
export const REQUEST_CONSTRUCTOR_GROUP_WAS_ADDED =
  'REQUEST_CONSTRUCTOR_GROUP_WAS_ADDED'
export const REQUEST_CONSTRUCTOR_RENAME_GROUP =
  'REQUEST_CONSTRUCTOR_RENAME_GROUP'
export const REQUEST_CONSTRUCTOR_GROUP_WAS_RENAMED =
  'REQUEST_CONSTRUCTOR_GROUP_WAS_RENAMED'
export const REQUEST_CONSTRUCTOR_REMOVE_GROUP =
  'REQUEST_CONSTRUCTOR_REMOVE_GROUP'
export const REQUEST_CONSTRUCTOR_GROUP_WAS_REMOVED =
  'REQUEST_CONSTRUCTOR_GROUP_WAS_REMOVED'
export const REQUEST_CONSTRUCTOR_MOVE_BLOCK = 'REQUEST_CONSTRUCTOR_MOVE_BLOCK'
export const REQUEST_CONSTRUCTOR_BLOCK_WAS_MOVED =
  'REQUEST_CONSTRUCTOR_BLOCK_WAS_MOVED'

export const REQUEST_CONSTRUCTOR_ADD_BLOCK = 'REQUEST_CONSTRUCTOR_ADD_BLOCK'
export const REQUEST_CONSTRUCTOR_BLOCK_WAS_ADDED =
  'REQUEST_CONSTRUCTOR_BLOCK_WAS_ADDED'

export const REQUEST_CONSTRUCTOR_RESET = 'REQUEST_CONSTRUCTOR_RESET'
export const REQUEST_CONSTRUCTOR_ERROR = 'REQUEST_CONSTRUCTOR_ERROR'

export const WIZARD_STATUS_WAS_UPDATED = 'WIZARD_STATUS_WAS_UPDATED'
export const REQUEST_CONSTRUCTOR_CLEAR_ERROR = 'REQUEST_CONSTRUCTOR_CLEAR_ERROR'
export const REQUEST_CONSTRUCTOR_TOGGLE = 'REQUEST_CONSTRUCTOR_TOGGLE'
export const REQUEST_CONSTRUCTOR_TOGGLED = 'REQUEST_CONSTRUCTOR_TOGGLED'
export const WORKFLOW_PUBLISHED = 'WORKFLOW_PUBLISHED'
export const PUBLISH_WORKFLOW = 'PUBLISH_WORKFLOW'
