import React from 'react'

import { useLoad } from '../../hooks'
import { getFileById } from '../../core/api/api.file'
import ViewerComponent from './ViewerComponent'
import styles from './Viewer.module.scss'
import Loader from '../Loader'

const PdfPreview = ({ fileId, setPdf, scope = 'tree' }) => {
  const [file] = useLoad(getFileById, { file_ids: fileId, scope })

  if (!file) {
    return (
      <div className={styles.fill}>
        <Loader text={false} type='big' className={styles.loader} />
      </div>
    )
  }

  return <>{file && <ViewerComponent file={file} setPdf={setPdf} />}</>
}

export default PdfPreview
