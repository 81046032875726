// @flow

import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { isEqual } from 'lodash-es'
import type { Node } from 'react'

import BuildingPicker from './BuildingPicker'
import BuildingsList from './BuildingsList'
import Button from '../../../components/Button'
import Checkbox from '../../Checkbox'
import ConfirmationPopup from '../ConfirmationPopup'
import { getUserId, isDwellerUser } from '../../../utils/utils'
import { ALL_PAGES } from '../../../constants'
import ModalCloseButton from '../../Modal/ModalCloseButton'
import ModalHead from '../../Modal/ModalHead'
import ModalBody from '../../Modal/ModalBody'
import ModalButtons from '../../Modal/ModalButtons'
import ModalRow from '../../Modal/ModalRow'
import ModalText from '../../Modal/ModalText'
import InputField from '../../InputField'
import { useOverflow, useSelected } from '../../../hooks'
import api from '../../../core/api'

import styles from './BuildingPopup.module.scss'

type Props = {
  createGroup?: boolean,
  editGroup?: number,
  onClose: () => void,
  onOk?: (Array<Object>) => void,
  onUpdateGroup?: (Array<Object>, string | boolean) => void,
  user: Object,
}

const BuildingPopup = (props: Props): Node => {
  const { user, createGroup, editGroup } = props

  const isBuildingGroup = createGroup || !!editGroup

  const [isLoading, setLoading] = useState(!createGroup)
  const [isConfirmed, setConfirmed] = useState(false)
  const [building, setBuilding] = useState(null)
  const [flat, setFlat] = useState(null)
  const [initialItems, setInitialItems] = useState(null)
  const [isAllLoading, setAllLoading] = useState(false)

  const [group, setGroup] = useState('')
  const [editManagers, setEditManagers] = useState(false)

  const { t } = useTranslation('BuildingPopup')

  const [selectedItems, changeSelected, setSelected] = useSelected()

  useOverflow()

  const isDwellerGroup = user && isDwellerUser(user)
  const canManageAllFlats = user?.permissions?.can_manage_all_user_flats

  useEffect(() => {
    if (!createGroup) {
      const getApi = isDwellerGroup
        ? api.flat.getListTiny
        : editGroup
        ? params => api.building.getGroupBuildings(editGroup, params)
        : api.building.getBuildingOptions
      getApi({ page_size: ALL_PAGES, owner: getUserId(user) })
        .then(data => {
          setSelected(data.results.objects.map(getFormattedBuilding))
          setInitialItems(data.results.objects.map(getFormattedBuilding))
        })
        .finally(() => setLoading(false))
    }
  }, [])

  const bodyClass = classnames({
    'working-overlay': isLoading || isAllLoading,
  })

  const getFormattedBuilding = ({ id, address_obj, number, address }) =>
    Object.assign(
      {},
      { id },
      { address: address_obj?.value || address },
      number ? { number } : undefined
    )

  const handleSubmit = () => {
    setLoading(true)
    const ids = selectedItems.map(({ id }) => id)

    if (isBuildingGroup) {
      props.onUpdateGroup(ids, editGroup ? editManagers : group)
    } else {
      props.onOk(ids)
    }
  }

  const handleSave = () => {
    if (selectedItems.length === 0 && isDwellerGroup && canManageAllFlats) {
      setConfirmed(true)
    } else {
      handleSubmit()
    }
  }
  const handleCloseConfirm = () => {
    setConfirmed(false)
  }

  const isDisabled = () => {
    return isEqual(initialItems, selectedItems) || (createGroup && !group)
  }

  if (isConfirmed) {
    return (
      <ConfirmationPopup
        title={t('ConfirmTitle')}
        text={t('ConfirmText')}
        confirm={t('Ok')}
        cancel={t('Cancel')}
        onClose={handleCloseConfirm}
        onOk={handleSubmit}
      />
    )
  }

  return (
    <div className={styles.modal}>
      <ModalHead
        title={t(
          isBuildingGroup
            ? editGroup
              ? 'TitleEditGroup'
              : 'TitleGroup'
            : 'Title'
        )}
      />
      <ModalCloseButton onClose={props.onClose} />
      <ModalBody className={bodyClass}>
        {createGroup && (
          <>
            <ModalRow>
              <ModalText text={t('BuildingGroupName')} />
            </ModalRow>
            <ModalRow>
              <InputField
                type='text'
                maxLength={256}
                value={group}
                onChange={e => setGroup(e.target.value)}
              />
            </ModalRow>
          </>
        )}
        <BuildingPicker
          isDwellerGroup={isDwellerGroup}
          selected={selectedItems}
          isloading={isLoading}
          changeSelected={changeSelected}
          building={building}
          setBuilding={setBuilding}
          flat={flat}
          setFlat={setFlat}
          setSelected={setSelected}
          setAllLoading={setAllLoading}
        />
        <BuildingsList
          changeSelected={changeSelected}
          isloading={isLoading}
          selected={selectedItems}
          isDwellerGroup={isDwellerGroup}
        />
        {!!editGroup && (
          <ModalRow>
            <Checkbox
              checked={editManagers}
              text={t('CheckboxGroupManagers')}
              onChange={() => setEditManagers(!editManagers)}
            />
          </ModalRow>
        )}
      </ModalBody>
      <ModalButtons>
        <Button.Save disabled={isLoading || isDisabled()} onClick={handleSave}>
          {t('Save')}
        </Button.Save>
        <Button.Cancel disabled={isLoading} onClick={props.onClose}>
          {t('Cancel')}
        </Button.Cancel>
      </ModalButtons>
    </div>
  )
}

export default BuildingPopup
