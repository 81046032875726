// @flow

import React from 'react'
import classnames from 'classnames'

import styles from './SelectListOption.module.scss'

export type Option = {
  label: string,
  value: string,
}

type Props = {
  handleClick: string => void,
  isSelected: boolean,
  option: Option,
}

const SelectListOption = (props: Props) => {
  const {
    option: { label, value },
    isSelected,
  } = props

  const optionClass = classnames(styles.option, {
    [styles.selected]: isSelected,
  })

  const handleClick = () => props.handleClick(value)

  return (
    <li className={optionClass} onClick={handleClick}>
      <span>{label}</span>
    </li>
  )
}

SelectListOption.defaultProps = {
  handleClick: () => {},
  isSelected: false,
}

export default SelectListOption
