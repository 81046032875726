// @flow

import * as actions from './PostCreating.actionTypes'

type State = {
  createdPost: ?Object,
  error: ?Object,
  working: boolean,
}

type Action = {
  error: ?Object,
  post: ?Object,
  ticker: ?Object,
  type: string,
}

const initialState: State = {
  createdPost: null,
  working: false,
  error: null,
}

export default (state: State = initialState, action: Action) => {
  switch (action.type) {
    case actions.CREATE_TICKER:
    case actions.CREATE_POST:
      return {
        ...state,
        working: true,
      }
    case actions.POST_WAS_CREATED:
      return {
        ...state,
        working: false,
        createdPost: action.post,
      }
    case actions.TICKER_WAS_CREATED:
      return {
        ...state,
        working: false,
        createdPost: action.ticker,
      }
    case actions.POST_CREATING_ERROR:
    case actions.TICKER_CREATING_ERROR:
      return {
        ...state,
        working: false,
        error: action.error,
      }
    case actions.POST_CREATING_RESET:
      return initialState
    default:
      return state
  }
}
