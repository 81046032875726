// @flow

import React from 'react'
import { createFragmentContainer, graphql } from 'react-relay'
import { useTranslation } from 'react-i18next'
import BigNumber from 'bignumber.js'
import classnames from 'classnames'
import { useSelector } from 'react-redux'

import { getUser } from '../../../../utils/commonSelectors'
import { parseMpPrice } from '../../../../utils/utils'
import { Cell } from '../../../../components/Block'

import styles from './OrderProfilePrice.module.scss'

const OrderProfilePrice = props => {
  const { t } = useTranslation('Orders')

  const {
    data: { grandTotal, vatDetail },
  } = props

  const {
    language_obj: { code },
  } = useSelector(getUser)

  const isNullPrice = parseFloat(grandTotal) === 0

  const priceClass = classnames({ [styles.price]: !isNullPrice })

  const offerVat = new BigNumber(vatDetail[0].vat)
  const priceValue = isNullPrice
    ? '-'
    : `${parseMpPrice(grandTotal, code)}€ ${t('vat', {
        value: offerVat.toFixed(0),
      })}`

  return (
    <Cell title={t('price')}>
      <div className={priceClass}>{priceValue}</div>
    </Cell>
  )
}

export default createFragmentContainer(OrderProfilePrice, {
  data: graphql`
    fragment OrderProfilePrice_data on CustomerOrderNode {
      grandTotal
      vatDetail {
        vat
        total
      }
    }
  `,
})
