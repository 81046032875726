// @flow

import React from 'react'
import type { Node } from 'react'

import NewTable from '../../NewTable'
import NewTableBody from '../../NewTable/NewTableBody'
import FolderSyncTableHeader from './FolderSyncTableHeader'
import FolderSyncTableRow from './FolderSyncTableRow'

import Loader from '../../Loader'

type Props = {
  folders: Array<Object>,
  loading: boolean,
  onChangeSelected: number => void,
  onSort: string => void,
  onUpdateFolder: (id: number, is_sync: boolean) => void,
  ordering: string,
  selectedItems: Array<number>,
}

const FolderSyncTable = (props: Props): Node => {
  const {
    loading,
    folders,
    onUpdateFolder,
    onChangeSelected,
    selectedItems,
    onSort,
    location,
  } = props

  return loading ? (
    <Loader text={false} type='big' />
  ) : (
    <NewTable>
      <FolderSyncTableHeader location={location} onSort={onSort} />
      <NewTableBody>
        {folders.map(folder => (
          <FolderSyncTableRow
            key={folder.id}
            folder={folder}
            selectedItems={selectedItems}
            onUpdateFolder={onUpdateFolder}
            onChangeSelected={onChangeSelected}
          />
        ))}
      </NewTableBody>
    </NewTable>
  )
}

export default FolderSyncTable
