// @flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useOverflow } from '../../../hooks'
import { globalModalError } from '../../Layout/Layout.actions'
import type { Node } from 'react'

import Button from '../../Button'
import Checkbox from '../../Checkbox'
import ModalHead from '../../Modal/ModalHead'
import ModalBody from '../../Modal/ModalBody'
import ModalRow from '../../Modal/ModalRow'
import ModalButtons from '../../Modal/ModalButtons'
import ModalCloseButton from '../../Modal/ModalCloseButton'

import ManagersBar from '../ManagersBar'

import { MANAGERS_LIMIT } from '../../../constants'

type Props = {
  onClose: () => void,
  onSave: (Array<Object>, boolean) => void,
}

const AddGroupManagersModal = (props: Props): Node => {
  const { onClose, onSave } = props
  const [managers, setManagers] = useState([])
  const [perform, setPerform] = useState(false)
  const [working, setWorking] = useState(false)
  const [editingManagers, setEditingManagers] = useState(true)
  const { t } = useTranslation('Building')
  const dispatch = useDispatch()
  useOverflow()

  const handleSave = () => {
    setWorking(true)
    onSave(managers, perform)
  }

  const handleChangeManagers = managers => {
    if (managers.length > MANAGERS_LIMIT) {
      dispatch(
        globalModalError(
          t('GroupManagersLimitText', { limit: MANAGERS_LIMIT }),
          t('GroupManagersLimitTitle'),
          true
        )
      )
      setEditingManagers(false)
      setTimeout(() => setEditingManagers(true), 0)
    } else {
      setManagers(managers)
    }
  }

  return (
    <div style={{ width: '495px' }}>
      <ModalCloseButton onClose={onClose} />
      <ModalHead title={t('GroupManagersAssignTitle')} />
      <ModalBody>
        <ModalRow>
          <ManagersBar
            data={{
              owner_objs: managers,
              permissions: { can_manage_responsible_users: true },
            }}
            title={t('GroupResponsibleManagers')}
            editingManagers={editingManagers}
            updateManagers={handleChangeManagers}
            working={working}
            max={MANAGERS_LIMIT}
          />
        </ModalRow>
        <ModalRow>
          <Checkbox
            checked={perform}
            text={t('GroupManagersPerform')}
            onChange={e => setPerform(e.currentTarget.checked)}
          />
        </ModalRow>
      </ModalBody>
      <ModalButtons>
        <Button.Save
          disabled={!managers.length}
          working={working}
          onClick={handleSave}
        >
          {t('Save')}
        </Button.Save>
        <Button.Cancel onClick={onClose}>{t('Cancel')}</Button.Cancel>
      </ModalButtons>
    </div>
  )
}

export default AddGroupManagersModal
