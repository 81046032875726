// @flow

import React, { useEffect } from 'react'
import Scrollbar from 'react-scrollbars-custom'

import TrackY from './NewFilterAsync/TrackY'
import ThumbY from './NewFilterAsync/ThumbY'

import styles from './NewFilterAsync/NewFilterAsyncMenuList/NewFilterAsyncMenuList.module.scss'
import styles3 from './RequestProviders.module.scss'
import RequestProvidersListItem from './RequestProvidersListItem'

const RequestProvidersList = props => {
  const { contractors, list, loadMore } = props

  useEffect(() => {
    props.setLoading(false)
  }, [])

  const handleClick = id => () => {
    const ids = contractors.map(c => c.id)
    const contractor = list.find(c => c.id === id)
    props.setContractors(
      ids.includes(id)
        ? contractors.filter(c => c.id !== id)
        : [...contractors, contractor]
    )
  }

  const handleMenuScroll = e => {
    const { scrollHeight, offsetHeight, scrollTop } = e.target
    const scrolled = offsetHeight + scrollTop

    if (scrollHeight / 2 <= scrolled) {
      loadMore()
    }
  }

  return (
    <>
      <div className={styles3.listWrapper} onScroll={handleMenuScroll}>
        <Scrollbar
          noScrollX
          style={{ height: 175 }}
          trackYProps={{
            renderer: TrackY,
          }}
          thumbYProps={{
            renderer: ThumbY,
          }}
        >
          <ul className={styles.list}>
            {list.map(item => (
              <RequestProvidersListItem
                option={item}
                key={item.id}
                contractors={contractors}
                handleClick={handleClick}
              />
            ))}
          </ul>
        </Scrollbar>
      </div>
    </>
  )
}

export default RequestProvidersList
