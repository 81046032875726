// @flow

import React, { Suspense, Fragment } from 'react'

import styles from './MenuExpandedFilters.module.scss'

const MenuExpandedFilters = props => {
  const { expandedFilterItems } = props

  return expandedFilterItems.map(item => {
    const { component: ExpandedFilterComponent, filterKey, text } = item

    return (
      <Fragment key={`expanded-filter-${filterKey}`}>
        <Suspense fallback={null}>
          <ExpandedFilterComponent text={text} styles={styles} />
        </Suspense>
      </Fragment>
    )
  })
}

export default MenuExpandedFilters
