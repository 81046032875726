// @flow

import React, { PureComponent, Fragment } from 'react'
import OutsideClick from 'react-onclickout'
import classnames from 'classnames'
import { withTranslation } from 'react-i18next'

import Button from '../../../components/Button'

type Props = {
  cancelTitle?: string,
  canEdit: boolean,
  className: string,
  extendedText?: string,
  isAnswer?: boolean,
  isInitialEditing: boolean,
  isWorking: boolean,
  maxLength: number,
  onCancel: () => void,
  onRemove?: () => void,
  onSave: () => void,
  onToggleEdit: () => void,
  saveTitle?: string,
  value: string,
}
type State = {
  newValue: string,
}

class Editor extends PureComponent<Props, State> {
  static defaultProps = {
    maxLength: 1000,
    isInitialEditing: false,
    extendedText: '',
    canEdit: true,
    isAnswer: false,
  }

  state = {
    isEditing: this.props.isInitialEditing,
    newValue: this.props.value,
  }

  input = React.createRef()

  componentDidMount() {
    const { isInitialEditing } = this.props

    if (isInitialEditing) {
      this.input.current.focus()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isEditing !== this.state.isEditing && this.state.isEditing) {
      this.input.current.focus()
    }

    if (prevProps.isWorking !== this.props.isWorking && !this.props.isWorking) {
      this.setState({
        isEditing: false,
      })
    }
  }

  handleChange = ({ target: { value } }) => {
    this.setState({
      newValue: value,
    })
  }

  handleSave = () => {
    const { newValue } = this.state
    this.props.onSave(newValue)
  }

  handleCancel = () => {
    const { isWorking, editing, id } = this.props

    if (!isWorking) {
      this.setState({
        isEditing: false,
        newValue: this.props.value,
      })
    }

    if (editing === id) {
      this.props.onToggleEdit()
    }
  }

  handleEdit = e => {
    e.stopPropagation()
    this.setState({
      isEditing: true,
    })
    this.props.onToggleEdit()
  }

  render() {
    const {
      t,
      placeholder,
      isWorking,
      saveTitle,
      cancelTitle,
      maxLength,
      value,
      extendedText,
      canEdit,
      className,
      isAnswer,
    } = this.props
    const { newValue, isEditing } = this.state
    const isDisabledSave = value === newValue.trim() || !newValue.trim()
    const classButtonCancel = classnames(
      'button button--default-3 button--large button--inline',
      {
        'button--square-x button--square ': !isAnswer,
      }
    )

    return (
      <Fragment>
        {isEditing ? (
          <OutsideClick onClickOut={this.handleCancel}>
            <div className={`faq__question-edit ${className}`}>
              <div className='input input--default input--large input--block mprofile-view-contacts-input'>
                {!isAnswer ? (
                  <input
                    ref={this.input}
                    type='text'
                    value={newValue}
                    maxLength={maxLength}
                    placeholder={placeholder}
                    onChange={this.handleChange}
                  />
                ) : (
                  <textarea
                    className='textarea-2__input modal-adduser__comment faq__answer-editor'
                    ref={this.input}
                    value={newValue}
                    maxLength={maxLength}
                    placeholder={placeholder}
                    onChange={this.handleChange}
                  />
                )}
              </div>
              <div className='mprofile-edit-contacts__cell faq__question-edit-buttons'>
                <Button.Regular
                  disabled={isDisabledSave}
                  className='button--success'
                  working={isWorking}
                  onClick={this.handleSave}
                >
                  {saveTitle || t('Save')}
                </Button.Regular>
                <button
                  type='button'
                  disabled={isWorking}
                  className={classButtonCancel}
                  onClick={this.props.onRemove || this.handleCancel}
                >
                  {cancelTitle || t('Cancel')}
                </button>
              </div>
            </div>
          </OutsideClick>
        ) : (
          <div className='faq__editor-value'>
            {value}
            {!isAnswer && <Fragment>&nbsp;</Fragment>}
            <span className='faq__question-edit_extend'>{extendedText}</span>
            {canEdit && (
              <Button.Edit
                className='input--edit--pencil'
                onClick={this.handleEdit}
              />
            )}
          </div>
        )}
      </Fragment>
    )
  }
}

export default withTranslation('Common')(Editor)
