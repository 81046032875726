// @flow

import { getTranslationKeys } from '../../../utils/utils'
import * as actions from './AddRequestCategoryPopup.actionTypes'

const initialState = {
  initiated: false,
  items: [],
  working: false,
  error: false,
  errorMessage: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.ADD_REQUEST_CATEGORY_POPUP_INITIATE:
      return initialState

    case actions.ADD_REQUEST_CATEGORY_POPUP_WAS_UPDATED:
      return {
        ...state,
        items: action.response,
      }
    case actions.ADD_REQUEST_CATEGORY_POPUP_WAS_INITIATED:
      return {
        ...state,
        items: action.results.objects,
        initiated: true,
      }
    case actions.ADD_REQUEST_CATEGORY_POPUP_SAVE:
      return {
        ...state,
        working: true,
      }
    case actions.ADD_REQUEST_CATEGORY_POPUP_WAS_SAVED:
      return {
        ...state,
        working: false,
      }
    case actions.ADD_REQUEST_CATEGORY_POPUP_ERROR:
      return {
        ...state,
        error: true,
        working: false,
        errorMessage: getTranslationKeys(action.response),
      }
    case actions.ADD_REQUEST_CATEGORY_POPUP_RESET:
      return initialState
    default:
      return state
  }
}
