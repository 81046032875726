// @flow

import React from 'react'
import type { Node } from 'react'

import CheckList from './Checklist'

// import styles from './AdminInfo.module.scss'

type Props = {
  firstLineWidth: number,
  items: Array<Object>,
  secondLineWidth: number,
}

const Checklists = (props: Props): Node => {
  const { items, count } = props

  if (!items || !items.length) {
    return null
  }

  const firstItem = items[0]

  return <CheckList data={firstItem} count={count} />
}

export default Checklists
