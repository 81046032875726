// @flow

export const CHECKING_COUNT = 200
export const RETRY_TIMEOUT = 2000
export const RULE_BOX_DEFAULT_WIDTH = 150
export const RULE_BOX_DEFAULT_HEIGHT = 50
export const RULE_MIN_WIDTH = 50
export const RULE_MIN_HEIGHT = 20

export const NEW_STATUS = 'new'
export const IN_PROGRESS_STATUS = 'in_progress'
export const COMPLETE_STATUS = 'complete'
export const ERROR_STATUS = 'error'

export const STATUSES = {
  [NEW_STATUS]: ['NewStatus', 'new'],
  [IN_PROGRESS_STATUS]: ['InProgressStatus', 'in-progress'],
  [COMPLETE_STATUS]: ['CompleteStatus', 'complete'],
  [ERROR_STATUS]: ['ErrorStatus', 'error'],
}

export const SPLIT_STEP = 'split'
export const MAPPING_STEP = 'mapping'
export const PREVIEW_STEP = 'preview'
export const RESULT_STEP = 'result'

export const RULE_TRANSLATION_KEYS = {
  external_id: 'ExternalId',
  contract_id: 'ContractId',
  email: 'Email',
  phone: 'Phone',
}

export const EMAIL_AND_FILE_TYPE = 'email_and_file'
export const EMAIL_TYPE = 'email'
