// @flow

export const COMMON_USE_SSL_FIELD = 'use_ssl'
export const COMMON_USE_TLS_FIELD = 'use_tls'

export const DEFAULT_POP_PORT = '110'
export const DEFAULT_SMTP_PORT = '25'
export const DEFAULT_POP_SSL_PORT = '995'
export const DEFAULT_SMTP_SSL_PORT = '465'
export const NO_ENCRYPT_VALUE = 'noEncrypt'
export const USE_TLS_VALUE = 'useTLS'
export const USE_SSL_VALUE = 'useSSL'
export const ALWAYS_VALUE = 'always'
export const NEVER_VALUE = 'never'
export const MONTH_VALUE = 'month'
export const WEEK_VALUE = 'week'
export const POP_VALUE = 'pop3'
export const IMAP_VALUE = 'imap'
export const MIN_PORT_NUMBER = 0
export const MAX_PORT_NUMBER = 65535

export const TEST_IN_PROGRESS = 'in_progress'
export const TEST_SUCCESS = 'ok'
export const TEST_FAILED = 'fail'
export const LIGHT_TIMEOUT = 1000
