// @flow

import React from 'react'
import { useSelector } from 'react-redux'
import { get } from 'lodash-es'
import type { Node } from 'react'

import Nbsp from '../../NBSP'

type Props = {
  text: string,
}

const UserInfoTitle = (props: Props): Node => {
  const { text } = props

  const users = useSelector(state => state.userList)

  const userCount = get(users, ['meta', 'count'])

  return (
    <div className='bar__title-indented bar__row'>
      <div className='bar__title-subtitle'>
        <span className='bar__title-text'>
          {text}
          <span className='unit__title-num'>
            <Nbsp />
            {!!userCount && userCount}
          </span>
        </span>
      </div>
    </div>
  )
}

export default UserInfoTitle
