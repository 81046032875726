// flow

export const USER = 'user'
export const BUILDING = 'building'
export const ROLE = 'role'
export const FLAT = 'flat'
export const EXTERNAL_ID = 'external_id'
export const NAME = 'name'
export const SECOND_NAME = 'second_name'
export const EMAIL = 'email'
export const PHONE = 'phone'
export const MOVE_OUT_DATE = 'move_out_date'
export const MESSAGE = 'message'
export const SEND_INVITE = 'send_invitation'
