import React from 'react'
import { withTranslation } from 'react-i18next'

import { USER_STATUS } from '../../../../constants'
import { Cell } from '../../../Block'

import styles from './ProfileStatus.module.scss'

const ProfileStatus = props => {
  const { status } = props
  const statusText = USER_STATUS[status]

  return (
    <Cell title={props.t('StatusTitle')}>
      <span className={styles[statusText]}>{props.t(statusText)}</span>
    </Cell>
  )
}

export default withTranslation('User')(ProfileStatus)
