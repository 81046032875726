// @flow

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import type { Node } from 'react'

import { getFullDateFormat } from '../../utils/commonSelectors'
import DateTime from '../DateTime'
import HideableMessagePart from '../Chatroom/ChatroomMessage/HideableMessagePart'
import { getMsgParts } from '../Chatroom/ChatroomMessage/helpers'
import ThreadsListItemErrors from '../../containers/Mail/ThreadsListItemErrors/ThreadsListItemErrors'
import ThreadsListItemRequests from '../../containers/Mail/ThreadsListItemRequests'
import { getMessageWithLinks } from '../Mail/MailMessageFull/Message/Message.utils'

const MESSAGE_DIVIDER = 'BLOCKQUOTE'

type Props = {
  avatar: string,
  created: string,
  errors: Array<string>,
  messageOpened: boolean,
  onClickMessage: string => void,
  owner_obj: Object,
  sender_display_name?: string,
  sender_email: string,
  text: string,
  uuid: string,
}

const MailMessageShort = (props: Props): Node => {
  const {
    uuid,
    avatar,
    text,
    sender_display_name,
    sender_email,
    messageOpened,
    created,
    errors,
    linked_requests,
    attached_request,
  } = props

  const [links, setLinks] = useState([...linked_requests])

  useEffect(() => {
    if (
      attached_request &&
      !links?.find(req => req.id === attached_request.id)
    ) {
      setLinks(prev => [attached_request, ...prev])
    }
  }, [attached_request])

  const { t } = useTranslation('Mail')

  const dateFormat = useSelector(getFullDateFormat)

  const name = sender_display_name || sender_email
  const msgParts = getMsgParts(text, MESSAGE_DIVIDER)

  const handleClick = () => props.onClickMessage(uuid)

  return (
    <div className='mail-message mail-message__short' onClick={handleClick}>
      <div className='avatar'>
        <img src={avatar} className='avatar-image' />
      </div>
      <div className='message-content'>
        <div className='message-author'>
          <span className='message-author-name'>{name}</span>
          ,&nbsp;
          <span className='message-date'>
            <DateTime dateTime={created} dateFormat={dateFormat} />
          </span>
          <ThreadsListItemRequests requests={links} viewedCount={1} />
          <ThreadsListItemErrors
            hasError={!!(errors && errors.length)}
            errors={errors}
            style={{ flexShrink: 0 }}
          />
        </div>
        {msgParts[1] ? (
          <div className='message-text'>
            <HideableMessagePart
              t={t}
              text={msgParts[1]}
              messageOpened={messageOpened}
            />
          </div>
        ) : (
          <div
            className='message-text'
            dangerouslySetInnerHTML={{ __html: getMessageWithLinks(text) }}
          />
        )}
      </div>
    </div>
  )
}

export default MailMessageShort
