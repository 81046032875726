// @flow

import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import ModalLabel from '../../../../Modal/ModalLabel'
import ModalRow from '../../../../Modal/ModalRow'
import { getOptions } from './Link.utils'
import NewSelectSimple from '../../../../NewSelectSimple'
import CardStageContext from '../../CardStage.context'
import { LINK } from './Link.constants'
import ModalError from '../../../../Modal/ModalError'
import type { LinksType } from '../../../NewWizard.types'

import styles from './Link.module.scss'

type Props = { links: LinksType }

const Link = (props: Props): Node => {
  const { links } = props

  const options = getOptions(links)

  const { t } = useTranslation('WizardPopup')

  const { errors, values, handleChangeLink } = useContext(CardStageContext)

  return (
    <>
      <ModalRow className={styles.label}>
        <ModalLabel>{`${t('Links')}:`}</ModalLabel>
      </ModalRow>
      <ModalRow>
        <NewSelectSimple
          useSearch
          searchPlaceholder={t('LinkSearchPlaceholder')}
          value={values[LINK]}
          error={errors[LINK]}
          options={options}
          className={styles.select}
          placeholder={t('Select')}
          onChange={handleChangeLink}
        />
      </ModalRow>
      {errors[LINK] && <ModalError error={t(errors[LINK])} />}
    </>
  )
}

export default Link
