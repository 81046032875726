// @flow

import React from 'react'
import classnames from 'classnames'
import type { Node } from 'react'

import styles from './Widgets.module.scss'

type Props = {
  children: Node,
  collapsed: boolean,
}

export const WIDGET_TYPES = {
  files: 'filesWidget',
  participants: 'participantsWidget',
  address: 'address',
  updates: 'updates',
  providers: 'providers',
  buildingGroups: 'building-groups',
}

const Widgets = ({ children, collapsed }: Props) => {
  const widgetClass = classnames(styles.container, {
    [styles.collapsed]: collapsed,
  })

  return (
    <div className={widgetClass}>
      <div className='widget__container--content'>{children}</div>
    </div>
  )
}

export default Widgets
