// @flow

import React, { Suspense } from 'react'
import { useTranslation } from 'react-i18next'

import Checkbox from '../Checkbox'
import Table from '../Table'
import { getFormattedDate } from '../../utils/utils'
import { EntityIcon } from '../Icon'
import { getStatus } from '../MappedDocument/MappedDocument.utils'

import styles from './DocumentMappingTableBodyRow.module.scss'

const STATUSES = {
  new: ['NewStatus', 'new'],
  in_progress: ['InProgressStatus', 'in-progress'],
  complete: ['CompleteStatus', 'complete'],
  error: ['ErrorStatus', 'error'],
}

type Props = {
  document: {
    event_id: string,
    file_obj: { filename: string },
    id: number,
    page_count: number,
    status: string,
    updated: string,
    user: {
      avatar: { preview: string },
      fullname: ?string,
      name: string,
      second_name: string,
    },
  },
  handleOpen: number => void,
}

const DocumentMappingTableBodyRow = (props: Props) => {
  const { t } = useTranslation('DocumentMapping')

  const {
    document: {
      id,
      updated,
      status,
      event_id: eventId,
      page_count: pageCount,
      file_obj: { filename },
      user: {
        avatar: { preview },
        fullname,
        name,
        second_name: secondName,
      },
    },
    checked,
  } = props

  const formattedDate = updated ? getFormattedDate(updated) : '-'

  const handleOpen = () => {
    props.setChecking(true)

    getStatus(eventId).then(() => {
      props.setChecking(false)
      props.handleOpen(id)
    })
  }

  const statusText = t(STATUSES[status][0])
  const statusClass = styles[STATUSES[status][1]]

  const userName = fullname || `${name} ${secondName}`

  return (
    <Table.BodyRow key={id} id={id} handleClick={handleOpen}>
      <Table.BodyCell title={filename}>
        <Checkbox
          className={styles.checkbox}
          checked={checked}
          onChange={() => props.handleChange(id)}
        />
        <Suspense fallback={null}>
          <EntityIcon id='pdf' />
        </Suspense>
      </Table.BodyCell>
      <Table.BodyCell title={userName}>
        <i className='table-item__user-pic'>
          <img src={preview} alt='user' />
        </i>
      </Table.BodyCell>
      <Table.BodyCell>
        <div className='table-item table-item__text'>
          <span className={statusClass}>{statusText}</span>
        </div>
      </Table.BodyCell>
      <Table.BodyCell title={pageCount} />
      <Table.BodyCell title={formattedDate} />
    </Table.BodyRow>
  )
}

DocumentMappingTableBodyRow.defaultProps = {
  name: '',
  second_name: '',
}

export default DocumentMappingTableBodyRow
