// @flow

import React from 'react'
import classnames from 'classnames'
import type { Node } from 'react'

type Props = {
  checked: boolean,
  disabled?: boolean,
  highlighted?: boolean,
  id?: string,
  onChange: (SyntheticEvent<HTMLInputElement>) => void,
}

const Toggle = (props: Props): Node => {
  const { checked, disabled, highlighted, id } = props

  const btnClass = classnames('togglebutton', {
    'togglebutton--highlighted': highlighted,
  })

  const inputId = id || 'unchecked'

  return (
    <div className={btnClass} onClick={e => e.stopPropagation()}>
      <input
        className='cbx hidden'
        checked={checked}
        disabled={disabled}
        id={inputId}
        type='checkbox'
        onChange={props.onChange}
      />
      <label htmlFor={inputId} className='lbl' />
    </div>
  )
}

export default Toggle
