import { commitMutation, graphql } from 'react-relay'

import environment from '../../createRelayEnvironment'

const mutation = graphql`
  mutation CreateOrderPageMutation($input: NoOfferOrderMutationInput!) {
    noOfferOrder(input: $input) {
      order {
        id
      }
    }
  }
`

const commit = (orderData, callback, errorCallback) => {
  return commitMutation(environment, {
    mutation,
    variables: {
      input: orderData
    },
    onCompleted: callback,
    onError: errorCallback
  })
}

export default { commit }
