// @flow

import { all, put, call, fork, takeLatest } from 'redux-saga/effects'
import { replace } from 'connected-react-router'
import { omit } from 'lodash-es'

import api from '../../../core/api'
import { serverError, globalModalError } from '../../Layout/Layout.actions'
import { SERVER_404_ERROR } from '../../Layout/Layout.constants'
import * as actions from './UserList.actionTypes'
import { initUserInfo } from '../UserListInfo/UserListInfo.saga'
import { getUserId } from '../../../utils/utils'

function* watchInit() {
  yield takeLatest(actions.USER_LIST_INITIATING, init)
}

function* watchLoad() {
  yield takeLatest(actions.USER_LIST_LOAD, load)
}

function* watchLoadOne() {
  yield takeLatest(actions.USER_LIST_LOAD_ONE, loadOne)
}

function* watchRemove() {
  yield takeLatest(actions.USER_LIST_REMOVE, remove)
}

function* watchRestore() {
  yield takeLatest(actions.USER_LIST_RESTORE, restore)
}

function* watchDelete() {
  yield takeLatest(actions.USER_LIST_DELETE, deleteUsers)
}

function* watchApproveUsers() {
  yield takeLatest(actions.USER_LIST_APROVE_USERS, approveUsers)
}

function* watchSetManagerBuildings() {
  yield takeLatest(actions.USER_LIST_SET_MANAGER_BUILDINGS, setManagerBuildings)
}

function* watchSetManagerCategories() {
  yield takeLatest(
    actions.USER_LIST_SET_MANAGER_CATEGORIES,
    setManagerCategories
  )
}

function* watchMassSetManagerCategories() {
  yield takeLatest(
    actions.USER_LIST_MASS_SET_MANAGER_CATEGORIES,
    setMassManagerCategories
  )
}

function* watchUpdateUser() {
  yield takeLatest(actions.USER_LIST_UPDATE_ITEM, updateUser)
}

function* watchUpdate() {
  yield takeLatest(actions.USER_LIST_UPDATE, update)
}

function* init({ params, isStaff, externalContact = false, search }) {
  try {
    const suffix = externalContact
      ? 'External'
      : isStaff
      ? 'Manager'
      : 'Dweller'

    let getApi =
      params.soft_archived && !externalContact
        ? api.profile[`getArchived${suffix}List`]
        : params.buildingGroup
        ? params => api.building.getGroupDwellers(params.buildingGroup, params)
        : api.profile[`get${suffix}List`]

    let searchQuery = {}

    if (search) {
      getApi = api.profile.getListSearch

      searchQuery = { query: search }
    }

    const result = yield call(getApi, {
      ...searchQuery,
      ...omit(params, ['sub', 'soft_archived']),
    })

    yield put({ type: actions.USER_LIST_INITIATED, ...result })
  } catch (error) {
    const action = serverError(error)

    if (action.type === SERVER_404_ERROR) {
      yield put(replace('/404'))
    } else {
      yield put(action)
      yield put({ type: actions.USER_LIST_ERROR, error })
    }
  }
}

function* load({ params, isStaff, externalContact = false, search }) {
  try {
    const suffix = externalContact
      ? 'External'
      : isStaff
      ? 'Manager'
      : 'Dweller'

    let getApi =
      params.soft_archived && !externalContact
        ? api.profile[`getArchived${suffix}List`]
        : params.buildingGroup
        ? params => api.building.getGroupDwellers(params.buildingGroup, params)
        : api.profile[`get${suffix}List`]

    let searchQuery = {}

    if (search) {
      getApi = api.profile.getListSearch

      searchQuery = { query: search }
    }

    const result = yield call(getApi, {
      ...searchQuery,
      ...omit(params, ['sub', 'soft_archived']),
    })

    yield put({ type: actions.USER_LIST_WAS_UPDATED, ...result })

    yield* initUserInfo()
  } catch (error) {
    const action = serverError(error)

    if (action.type === SERVER_404_ERROR) {
      yield put(replace('/404'))
    } else {
      yield put(action)
      yield put({ type: actions.USER_LIST_ERROR, error })
    }
  }
}

function* loadOne({ id }) {
  try {
    const user = yield call(api.profile.getProfileTiny, id)
    yield put({
      type: actions.USER_LIST_WAS_UPDATED,
      results: { objects: [user] },
      meta: {
        count: 1,
        curr_page: 1,
        limit: 20,
        offset: 0,
        page_count: 1,
      },
    })
  } catch (error) {
    yield put(serverError(error))
    yield put({ type: actions.USER_LIST_ERROR, error })
  }
}

function* remove({ values, params, meta }) {
  try {
    const { count, curr_page, limit, page_count } = meta

    let newPage = curr_page

    const removedUsers = yield call(api.massAction.remove, {
      model: 'Profile',
      model_pks: JSON.stringify(values),
    })

    if (
      curr_page === page_count &&
      page_count > 1 &&
      removedUsers &&
      (removedUsers.length === count % limit || removedUsers.length === limit)
    ) {
      newPage = curr_page - 1
    }

    if (
      curr_page === page_count &&
      page_count > 1 &&
      removedUsers &&
      removedUsers.length >= count % limit
    ) {
      newPage = 1
    }

    const result = yield call(api.profile.getListTiny, {
      ...params,
      page: newPage,
      counters: 1,
      get_permissions: 1,
    })

    yield put({ type: actions.USER_LIST_WAS_UPDATED, ...result })
    yield* initUserInfo()
  } catch (error) {
    yield put(serverError(error))

    if (error.message.response.status === 400) {
      yield put(globalModalError(error.message.response.data.detail))
    }

    yield put({ type: actions.USER_LIST_ERROR, error })
  }
}

function* restore({ params, values, meta }) {
  try {
    const { count, curr_page, limit, page_count } = meta

    let newPage = curr_page

    const restoredUsers = yield call(api.massAction.updateRequest, {
      model: 'Profile',
      model_pks: JSON.stringify(values),
      patch: { soft_archived: false },
    })

    if (curr_page === page_count && page_count > 1 && restoredUsers) {
      if (restoredUsers.length >= count % limit) {
        newPage = 1
      } else if (
        restoredUsers.length === count % limit ||
        restoredUsers.length === limit
      ) {
        newPage = curr_page - 1
      }
    }

    const result = yield call(api.profile.getArchivedListTiny, {
      ...params,
      page: newPage,
      counters: 1,
      get_permissions: 1,
    })
    yield put({ type: actions.USER_LIST_WAS_UPDATED, ...result })
    yield* initUserInfo()
  } catch (error) {
    yield put(serverError(error))

    if (error.message.response.status === 400) {
      yield put(globalModalError(error.message.response.data.detail))
    }

    yield put({ type: actions.USER_LIST_ERROR, error })
  }
}

function* deleteUsers({ params, values, meta }) {
  try {
    const { count, curr_page, limit, page_count } = meta

    let newPage = curr_page

    const restoredUsers = yield call(api.massAction.remove, {
      model: 'external_contact',
      model_pks: JSON.stringify(values),
    })

    if (curr_page === page_count && page_count > 1 && restoredUsers) {
      if (restoredUsers.length >= count % limit) {
        newPage = 1
      } else if (
        restoredUsers.length === count % limit ||
        restoredUsers.length === limit
      ) {
        newPage = curr_page - 1
      }
    }

    const result = yield call(api.profile.getExternalList, {
      ...params,
      page: newPage,
      counters: 1,
      get_permissions: 1,
    })
    yield put({ type: actions.USER_LIST_WAS_UPDATED, ...result })
    yield* initUserInfo()
  } catch (error) {
    yield put(serverError(error))

    if (error.message.response.status === 400) {
      yield put(globalModalError(error.message.response.data.detail))
    }

    yield put({ type: actions.USER_LIST_ERROR, error })
  }
}
function* setManagerBuildings({ owner, ids }) {
  try {
    const user = yield call(api.profile.setManagerBuildings, owner, ids)

    yield put({
      type: actions.USER_LIST_ITEM_WAS_UPDATED,
      id: owner,
      user,
    })
  } catch (error) {
    yield put(serverError(error))
    yield put({ type: actions.USER_LIST_ERROR, error })
  }
}

function* setManagerCategories({ manager, ids }) {
  try {
    const user = yield call(api.profile.setManagerCategories, manager, ids)

    yield put({
      type: actions.USER_LIST_ITEM_WAS_UPDATED,
      id: manager,
      user,
    })
  } catch (error) {
    yield put(serverError(error))
    yield put({ type: actions.USER_LIST_ERROR, error })
  }
}

function* setMassManagerCategories({ managers, ids }) {
  try {
    yield call(api.profile.setMassManagerCategories, managers, ids)

    yield put({
      type: actions.USER_LIST_RELOAD,
    })
  } catch (error) {
    yield put(serverError(error))
    yield put({ type: actions.USER_LIST_ERROR, error })
  }
}

function* approveUsers({ users, params, meta = {} }) {
  try {
    const { count, curr_page = 1, limit, page_count } = meta
    let newPage = curr_page

    const approvedUsers = yield call(api.massAction.updateRequest, {
      model: 'Profile',
      model_pks: JSON.stringify(users.map(getUserId)),
      patch: { validated: true },
    })

    if (
      curr_page === page_count &&
      page_count > 1 &&
      approvedUsers &&
      approvedUsers.length >= count % limit
    ) {
      newPage = 1
    }

    const result = yield call(api.profile.getListTiny, {
      ...params,
      page: newPage,
      counters: 1,
    })
    yield put({ type: actions.USER_LIST_WAS_UPDATED, ...result })
  } catch (error) {
    if (error.message.response.status === 400) {
      yield put(globalModalError(error.message.response.data.errors))

      return
    }

    yield put(serverError(error))
  }
}

function* updateUser({ id, params, tiny }) {
  try {
    // TODO Get rid of update and updateTiny and leave only one (I hope tiny)
    const callback = tiny ? api.profile.updateTiny : api.profile.update
    const user = yield call(callback, id, params)

    const updated = tiny
      ? user
      : {
          ...params,
          group: user.group,
          permissions: user.permissions,
          is_landlord: user.is_landlord,
        }
    yield put({
      type: actions.USER_LIST_ITEM_WAS_UPDATED,
      id,
      user: updated,
    })
  } catch (error) {
    yield put({ type: actions.USER_LIST_ERROR, error })

    if (error.message.response.status === 400) {
      yield put(globalModalError(error.message.response.data.errors))

      return
    }

    yield put(serverError(error))
  }
}

function* update({ ids, patch, params, meta }) {
  try {
    const { count, curr_page = 1, limit, page_count } = meta

    let newPage = curr_page
    const updatedUsers = yield call(api.massAction.updateRequest, {
      model: 'Profile',
      model_pks: JSON.stringify(ids),
      patch,
    })

    if (curr_page === page_count && page_count > 1 && updatedUsers) {
      if (updatedUsers.length >= count % limit) {
        newPage = 1
      } else if (
        updatedUsers.length === count % limit ||
        updatedUsers.length === limit
      ) {
        newPage = curr_page - 1
      }
    }

    const result = yield call(api.profile.getListTiny, {
      ...params,
      page: newPage,
      counters: 1,
    })
    yield put({ type: actions.USER_LIST_WAS_UPDATED, ...result })
  } catch (error) {
    if (error.message.response.status === 400) {
      yield put(globalModalError(error.message.response.data.errors))

      return
    }

    yield put(serverError(error))
  }
}

export default function* watch() {
  yield all([
    fork(watchInit),
    fork(watchLoad),
    fork(watchLoadOne),
    fork(watchRemove),
    fork(watchRestore),
    fork(watchDelete),
    fork(watchApproveUsers),
    fork(watchSetManagerBuildings),
    fork(watchSetManagerCategories),
    fork(watchMassSetManagerCategories),
    fork(watchUpdateUser),
    fork(watchUpdate),
  ])
}
