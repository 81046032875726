// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'

import ContactForm from '../../ContactForm'
import * as actions from './ProfileContacts.actionTypes'

type Props = {
  dispatch: Object => void,
  modelId: number,
  noMargin: boolean,
  onInit: () => void,
  profileId: number | string,
  tabView: boolean,
}

class ProfileContacts extends Component<Props> {
  componentDidMount() {
    if (!this.props.tabView) {
      this.props.dispatch({ type: actions.PROFILE_CONTACTS_INITIATING })
    }
  }

  onInit = () => {
    if (!this.props.tabView) {
      this.props.dispatch({ type: actions.PROFILE_CONTACTS_INITIATED })
      this.props.onInit()
    }
  }

  onUpdate = data => {
    this.props.dispatch({ type: actions.PROFILE_CONTACTS_UPDATE, data })
  }

  render() {
    const { profileId, modelId, tabView, noMargin, isExternal } = this.props
    const props = { profileId, modelId, tabView, noMargin, isExternal }

    return (
      <ContactForm {...props} onInit={this.onInit} onUpdate={this.onUpdate} />
    )
  }
}

const mapStateToProps = state => state.profileContacts

export default connect(mapStateToProps)(ProfileContacts)
