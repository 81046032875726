import i18n from '../i18n'

export const loadI18NextTranslations = translations => {
  const { locale } = translations
  Object.entries(translations).forEach(([namespace, keys]) => {
    if (namespace !== 'locale') {
      i18n.addResourceBundle(locale, namespace, keys)
    }
  })

  if (i18n.language !== locale) {
    i18n.changeLanguage(locale)
  }

  document.documentElement.setAttribute('lang', locale)
}
