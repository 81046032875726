// @flow

import React from 'react'

type Props = {
  active: boolean,
  text: string,
}

const ValidationText = ({ active, text }: Props) => {
  if (!active) {
    return null
  }

  return (
    <div className='input input--medium'>
      <div className='input__msg--error'>{text}</div>
    </div>
  )
}

ValidationText.defaulProps = {
  text: '',
}

export default ValidationText
