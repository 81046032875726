// @flow

import {
  SERVER_502_ERROR,
  SERVER_500_ERROR,
  SERVER_404_ERROR,
  SERVER_403_ERROR,
  CLOSE_POP_UP,
  GLOBAL_MODAL_ERROR,
  GLOBAL_MODAL_MESSAGE,
  SERVER_ANY_ERROR,
  SERVER_402_ERROR,
  SERVER_401_ERROR,
} from './Layout.constants'
import { getShouldStickBool } from '../../utils/utils'

const initialState = {
  errorFlag: false,
  isServerError: false,
  showMessage: false,
  message: '',
  title: '',
  error: {},
  messageParams: {},
  needTranslate: false,
  widgetsShouldStick: getShouldStickBool(),
}

let code

function layotPageReducer(state = initialState, action) {
  switch (action.type) {
    case SERVER_403_ERROR:
    case SERVER_402_ERROR:
    case SERVER_401_ERROR:
    case SERVER_404_ERROR:
    case SERVER_500_ERROR:
    case SERVER_502_ERROR:
    case SERVER_ANY_ERROR:
      code = action.error.message.response.status

      return {
        errorFlag: true,
        error: action.error,
        isServerError: true,
        code,
      }
    case CLOSE_POP_UP:
      return initialState
    case GLOBAL_MODAL_ERROR:
      return {
        errorFlag: true,
        needTranslate: !!action.needTranslate,
        errorTitle: action.errorTitle,
        errorText: action.errorText,
        error: action.error,
      }
    case GLOBAL_MODAL_MESSAGE:
      return {
        showMessage: true,
        message: action.text,
        title: action.title,
        messageParams: action.textParams,
      }
    default:
      return state
  }
}

export default layotPageReducer
