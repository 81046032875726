// @flow

import React from 'react'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { omit } from 'lodash-es'
import { useLocation } from 'react-router-dom'

import {
  createOrderingFilter,
  formatPathname,
  getCurrentFilters,
  getNewQueryString,
  getCurrentPage,
} from '../utils/routing'

const addTableFilterHandler = WrappedComponent => {
  const Component = props => {
    const location = useLocation()

    const handleChangeFilter = (key, value, resetPage, omitFilters) => {
      const { push } = props
      const filters = getCurrentFilters(location)
      const newFilter = key !== 'Object' ? { [key]: value } : { ...value }
      const page = getCurrentPage(location)

      const search = getNewQueryString({
        ...omit(filters, omitFilters),
        ...newFilter,
        page: resetPage ? 1 : page,
      })
      const pathname = formatPathname(location.pathname)

      push(`/${pathname}/?${search}`)
    }

    const handleSort = key => {
      const value = createOrderingFilter(key, location)

      handleChangeFilter('ordering', value)
    }

    return (
      <WrappedComponent
        {...props}
        handleChangeFilter={handleChangeFilter}
        handleSort={handleSort}
      />
    )
  }

  return connect(null, { push })(Component)
}

export default addTableFilterHandler
