// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'

import { isDwellerUser } from '../../../../utils/utils'
import { Row } from '../../../Block'
import DueDateView from './DueDateView'
import DueDateEdit from './DueDateEdit'

type Props = {
  editing: boolean,
  onChange: (?string) => void,
  onEdit: () => void,
  permissions: { can_edit_due_date: boolean },
  user: Object,
  value: ?string,
}

const DueDate = (props: Props) => {
  const {
    value,
    permissions: { can_edit_due_date: canEdit },
    user,
    editing,
  } = props

  const { t } = useTranslation('Request')

  if (isDwellerUser(user)) {
    return null
  }

  const handleEdit = () => {
    props.onEdit()
  }

  const rowProps = Object.assign(
    {},
    { title: t('DueDate') },
    editing || !canEdit ? undefined : { onEdit: handleEdit }
  )

  return (
    <Row {...rowProps}>
      {editing ? (
        <DueDateEdit
          dateTime={value}
          style={{ maxWidth: 'initial' }}
          clearStyle={{ marginLeft: '10px' }}
          onChange={props.onChange}
        />
      ) : (
        <DueDateView dateTime={value} />
      )}
    </Row>
  )
}

export default DueDate
