// @flow

import React from 'react'
import classnames from 'classnames'
import type { Node } from 'react'

import styles from './Checkbox.module.scss'
import { useThemeColor } from '../../hooks'

type Props = {
  checked: boolean,
  className?: string,
  disabled: boolean,
  name?: string,
  onChange?: (event: { ... }) => void,
  outer: boolean,
  selected?: boolean,
  size: 'medium' | 'large',
  style?: Object,
  text?: string,
  value?: number | string,
}

const Checkbox = (props: Props): Node => {
  const {
    checked,
    disabled,
    outer,
    text,
    value,
    style = {},
    name,
    selected,
    size,
    className,
  } = props

  const checkboxClass = classnames(styles.checkbox, styles[size], className, {
    [styles.disabled]: disabled,
    [styles.outer]: outer,
    [styles.selected]: selected,
  })

  const themeColor = useThemeColor()

  const handleClick = e => {
    e.stopPropagation()
  }

  return (
    <label
      className={checkboxClass}
      style={{ ...style, '--themeColor': themeColor }}
      onClick={handleClick}
    >
      <div>
        <input
          name={name}
          type='checkbox'
          disabled={disabled}
          checked={checked}
          value={value}
          onChange={props.onChange}
        />
        <span />
      </div>
      {!!text && <span>{text}</span>}
    </label>
  )
}

Checkbox.defaultProps = {
  checked: false,
  disabled: false,
  outer: false,
  size: 'medium',
}

export default Checkbox
