// @flow

export const ITEM_INDENT = 10

export const MAX_COUNT = 20

export const MEDIA_MIN_WIDTHS = {
  medium: 1170,
  large: 1650,
}

export const ROW_LENGTHS = {
  small: 2,
  medium: 3,
  large: 4,
}

export const ROWS_COUNT = 2

export const THUMBNAIL_SIZE = 130
