// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import NewTable from '../../NewTable'
import NewTableBody from '../../NewTable/NewTableBody'
import PreferredCommunicationTableRow from './PreferredCommunicationTableRow'

type Props = { onEdit: Function }

const PreferredCommunicationTable = (props: Props): Node => {
  const { t } = useTranslation('Profile')

  const options = [
    {
      value: 'editPreferredCommunication',
      label: t('EditPreferredCommunication'),
      onClick: props.onEdit,
      icon: 'pencil',
    },
  ]

  return (
    <NewTable>
      <NewTableBody>
        <PreferredCommunicationTableRow
          options={options}
          onEdit={props.onEdit}
        />
      </NewTableBody>
    </NewTable>
  )
}

export default PreferredCommunicationTable
