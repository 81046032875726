// @flow

import { cloneDeep, map, take } from 'lodash-es'
import * as actions from './BuildingDwellers.actionTypes'
import { isIncluded } from './helpers'

const initialState = {
  initiated: false,
  dwellers: [],
  meta: {},
  selected: [],
  selectedAllPages: false,
  ordering: null,
  filter: {},
  working: true,
  modal: null,
  error: false,
  iscompletereload: false,
  reload: false,
  permissions: {},
  allDwellers: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.BUILDING_DWELLERS_INITIATING:
      return {
        ...state,
      }
    case actions.BUILDING_DWELLERS_INITIATED:
      return {
        ...state,
        allDwellers: action.objects,
        dwellers: action.objects,
        meta: {
          count: action.count,
          page_count: action.pages,
          limit: 20,
          curr_page: Number(action.page) || 1,
        },
        permissions: action.permissions,
        working: false,
        initiated: true,
        iscompletereload: true,
      }
    case actions.BUILDING_DWELLERS_SHOW_MODAL:
      return {
        ...state,
        modal: action.modal,
      }
    case actions.BUILDING_DWELLERS_HIDE_MODAL:
      return {
        ...state,
        modal: null,
      }
    case actions.BUILDING_DWELLERS_TOGGLE: {
      const { item } = action
      const selected = cloneDeep(state.selected)
      const index = selected.findIndex(
        ({ owner, flatId }) => owner === item.owner && flatId === item.flatId
      )
      index >= 0 && selected.splice(index, 1)
      const newSelected = index >= 0 ? selected : [...state.selected, item]

      return {
        ...state,
        selected: newSelected,
        selectedAllPages: false,
      }
    }
    case actions.BUILDING_DWELLERS_TOGGLE_ALL: {
      let selected = []
      let allDwellers = []
      const dwellers = take(
        state.dwellers.filter(d => !d.archived),
        action.count
      )
      const pages = action.params?.pages?.filter(d => !d.archived) || []

      if (action?.params?.all) {
        selected = map(
          pages,
          ({ userprofile: { id: owner }, flat_id: flatId }) => ({
            owner,
            flatId,
          })
        )
        allDwellers = [...pages]
      } else if (
        state.selected.length < dwellers.length &&
        !action?.params?.empty &&
        !action?.params?.curr
      ) {
        selected = map(
          dwellers,
          ({ userprofile: { id: owner }, flat_id: flatId }) => ({
            owner,
            flatId,
          })
        )
        allDwellers = [...state.dwellers]
      } else if (
        action?.params?.curr &&
        !action?.params?.empty &&
        (!state.selected[0] ||
          state.selected.filter(u =>
            state.dwellers.map(s => s.userprofile.id).includes(u.owner)
          ).length === state.selected.length)
      ) {
        selected = map(
          dwellers,
          ({ userprofile: { id: owner }, flat_id: flatId }) => ({
            owner,
            flatId,
          })
        )
        allDwellers = [...state.dwellers]
      } else if (pages.length > 0 && !action?.params?.empty) {
        selected = [
          ...state.selected.filter(
            u =>
              !isIncluded(
                pages.map(d => ({
                  owner: d.userprofile.id,
                  flat_id: d.flat_id,
                })),
                u,
                'flat_id'
              )
          ),
          ...map(pages, ({ userprofile: { id: owner }, flat_id: flatId }) => ({
            owner,
            flatId,
          })),
        ]
        allDwellers = [
          ...state.allDwellers.filter(
            u => !pages.map(s => s.userprofile.id).includes(u.owner)
          ),
          ...pages,
        ]
      }

      return {
        ...state,
        working: false,
        allDwellers,
        selected,
        selectedAllPages: action.params?.all,
      }
    }
    case actions.BUILDING_DWELLERS_SET_SELECTED:
      return {
        ...state,
        selected: [...action.selected],
      }
    case actions.BUILDING_DWELLERS_LOAD:
    case actions.BUILDING_DWELLERS_LOAD_ONE:
    case actions.BUILDING_DWELLERS_UPDATE_ROLE:
      return {
        ...state,
        working: true,
        selected: [],
        modal: null,
      }
    case actions.BUILDING_DWELLERS_SET_ORDERING:
      return {
        ...state,
        ordering: action.value,
      }
    case actions.BUILDING_DWELLERS_REMOVE:
      return {
        ...state,
        working: true,
        selected: [],
        modal: null,
      }
    case actions.BUILDING_DWELLERS_RELOAD:
      return {
        ...state,
        iscompletereload: false,
        working: true,
      }
    case actions.BUILDING_DWELLERS_RELOAD_END:
      return {
        ...state,
        dwellers: action.objects,
        allDwellers: action.objects,
        meta: {
          count: action.count,
          page_count: action.pages,
          limit: 20,
          curr_page: Number(action.page),
        },
        permissions: action.permissions,
        working: false,
        initiated: true,
        iscompletereload: true,
        reload: false,
      }
    case actions.BUILDING_DWELLERS_UPDATE:
      return {
        ...state,
        working: false,
        initiated: true,
        allDwellers: [
          ...state.allDwellers,
          ...action.objects.filter(
            d => !isIncluded(state.allDwellers, d, 'flat_id', 'id')
          ),
        ],
        dwellers: action.objects,
        permissions: action.permissions,
        meta: {
          count: action.count,
          page_count: action.pages,
          limit: 20,
          curr_page: Number(action.page),
        },
      }
    case actions.BUILDING_DWELLERS_BEFORE_LOAD:
      return {
        ...state,
        working: true,
      }
    case actions.BUILDING_DWELLERS_ERROR:
      return {
        ...state,
        initiated: true,
        working: false,
        error: action.error.message,
      }
    case actions.BUILDING_DWELLERS_RESET:
      return initialState
    default:
      return state
  }
}
