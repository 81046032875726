// @flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { partial } from 'lodash-es'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import type { Node } from 'react'

import Textarea from 'react-textarea-autosize'
import ModalRow from '../Modal/ModalRow'
import ModalLabel from '../Modal/ModalLabel'
import Modal from '../Modal'
import ModalBody from '../Modal/ModalBody'
import ModalCloseButton from '../Modal/ModalCloseButton'
import ModalHead from '../Modal/ModalHead'
import { useOverflow } from '../../hooks/useOverflow'
import Button from '../Button'
import ModalButtons from '../Modal/ModalButtons'
import { patchThread } from '../../core/api/api.request'
import { changeNotes, changeNotesSingle } from '../../core/api/api.mail'

import styles from './NotesChangeModal.module.scss'

type Props = {
  isOpen: boolean,
  onClose: () => void,
  onSubmit?: (string, string) => void,
  reloadThreads?: true => void,
  requestId?: number,
  thread: Object,
}

const NOTES_MAX_LENGTH = 2000

const NotesChangeModal = (props: Props): Node => {
  const { isOpen, thread, requestId } = props

  const [isLoading, setLoading] = useState(false)

  const { t } = useTranslation('Request')

  useOverflow()

  const formik = useFormik({
    validateOnChange: true,
    enableReinitialize: true,
    initialValues: {
      notes: thread.notes || null,
    },
    validationSchema: Yup.object({
      notes: Yup.string().max(
        NOTES_MAX_LENGTH,
        t('NotesMaxLength', { maxLength: NOTES_MAX_LENGTH })
      ),
    }),
    onSubmit: submitValues => {
      setLoading(true)

      const api = requestId
        ? partial(patchThread, requestId)
        : thread.isSingle
        ? changeNotesSingle
        : changeNotes

      api(thread.threadUuid, {
        notes: submitValues.notes || null,
      }).finally(() => {
        setLoading(false)

        if (props.onSubmit) {
          props.onSubmit(thread.threadUuid, submitValues.notes)
        } else {
          props.onClose()
        }

        if (props.reloadThreads) {
          props.reloadThreads(true)
        }
      })
    },
  })

  return (
    <Modal
      isOpen={isOpen}
      style={{ content: { minWidth: '348px' } }}
      onRequestClose={props.onClose}
    >
      <ModalHead title={t('ChangeNotesTitle')} />
      <ModalCloseButton onClose={props.onClose} />
      <ModalBody style={{ marginBottom: 0 }}>
        <ModalRow>
          <ModalLabel>{t('NotesLabel')}</ModalLabel>
        </ModalRow>
        <ModalRow>
          <Textarea
            autoFocus
            className={styles.textarea}
            name='notes'
            value={formik.values.notes}
            placeholder={t('NotesPlaceholder')}
            error={formik.errors.notes}
            onChange={formik.handleChange}
          />
        </ModalRow>
        <form onSubmit={formik.handleSubmit}>
          <ModalButtons>
            <Button.Save
              type='submit'
              disabled={!formik.dirty || isLoading}
              working={isLoading}
            >
              {t('ChangeNotesSubmit')}
            </Button.Save>
            <Button.Cancel
              disabled={!formik.dirty || isLoading}
              onClick={formik.handleReset}
            >
              {t('Common:Cancel')}
            </Button.Cancel>
          </ModalButtons>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default NotesChangeModal
