// @flow

import React, { Component } from 'react'
import type { Node } from 'react'
import { DropTarget } from 'react-dnd'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'
import update from 'immutability-helper'
import { countBy, forEach, pickBy, uniq } from 'lodash-es'

import Button from '../../Button'
import Category, { CategoryType, MaxLength } from './Category'
import Icon from '../../../../static/icons/option-radio.svg'
import Loader from '../../Loader'
import Warning from '../../Warning'
import * as actions from './AddRequestCategoryPopup.actionTypes'

const dropTarget = {
  drop() {},
}

type Props = {
  connectDropTarget: Node => Node,
  dispatch: Object => void,
  // eslint-disable-line
  error: ?string,
  initiated: boolean,
  items: Array<Object>,
  onClose: () => void,
  t: string => string,
  working: boolean,
}
type State = {
  created: string,
  createdError: ?string,
  duplicates: Array<string>,
  editingItem: ?number,
  items: Array<Object>,
}

class AddRequestCategoryPopup extends Component {
  state: State

  state = {
    created: '',
    createdError: null,
    duplicates: [],
    editingItem: null,
    items: [],
  }

  componentDidMount() {
    this.props.dispatch({ type: actions.ADD_REQUEST_CATEGORY_POPUP_INITIATE })
  }

  componentDidUpdate(prev: Props) {
    const { working, initiated, onClose } = prev

    if (!initiated && this.props.initiated) {
      this.setState({
        items: this.props.items.map((u, i) => ({ ...u, position: i + 1 })),
      })

      return
    }

    if (working && !this.props.working && !this.props.error) {
      onClose()
    }
  }

  componentWillUnmount() {
    this.props.dispatch({ type: actions.ADD_REQUEST_CATEGORY_POPUP_RESET })
  }

  getCreated = () => {
    const { created } = this.state

    return created
      ? created
          .split('\n')
          .map(s => s.trim())
          .filter(Boolean)
      : []
  }

  props: Props

  updateCreated = e => {
    this.setState({
      created: e.target.value,
      createdError: null,
    })
  }

  editItem = (id: ?number) => {
    this.setState({ editingItem: id })
  }

  save = () => {
    if (!this.checkDuplicates()) {
      return
    }

    const created = this.getCreated()

    if (!this.checkCreated(created)) {
      return
    }

    const { dispatch } = this.props

    dispatch({
      type: actions.ADD_REQUEST_CATEGORY_POPUP_SAVE,
      created,
    })
  }

  hasChanges = () => {
    const { created } = this.state

    if (created.length) {
      return true
    }
  }

  checkDuplicates = () => {
    const { items } = this.state

    const duplicates = []
    const names = items.map(i => i.name.toLowerCase())

    const nonunique = pickBy(
      countBy(names, n => n),
      c => c > 1
    )
    forEach(nonunique, (c, name) => {
      duplicates.push(name)
    })

    this.getCreated().forEach(name => {
      const value = name.toLowerCase()

      if (names.includes(value)) {
        duplicates.push(value)
      }
    })
    this.setState({ duplicates })

    return !duplicates.length
  }

  checkCreated = items => {
    const unique = uniq(items.map(i => i.toLowerCase()))

    if (unique.length !== items.length) {
      this.setState({ createdError: 'NonUniqueTypes' })

      return false
    }

    for (const item of items) {
      // eslint-disable-line
      if (item.length > MaxLength) {
        this.setState({ createdError: 'TooLongType' })

        return false
      }
    }

    return true
  }

  moveItem = (id, to) => {
    const { items } = this.state
    const from = items.findIndex(i => i.id === id)

    if (from === to) {
      return
    }

    const item = items[from]
    const updated = update(items, {
      $splice: [
        [from, 1],
        [to, 0, item],
      ],
    })

    this.setState({
      items: updated.map((u, i) => ({ ...u, position: i + 1 })),
    })
  }

  dropItem = () => {
    const { items } = this.state
    const { dispatch } = this.props

    dispatch({
      type: actions.ADD_REQUEST_CATEGORY_POPUP_UPDATE,
      items,
    })
  }

  removeItem = (id: number) => {
    const { items } = this.state
    const { dispatch } = this.props

    const filtered = items.filter(i => i.id !== id)

    const nextItems = filtered.map((item, i) => ({ ...item, position: i + 1 }))

    this.setState({
      items: nextItems,
    })

    dispatch({
      type: actions.ADD_REQUEST_CATEGORY_POPUP_UPDATE,
      items: nextItems,
    })
  }

  updateItem = (item: string, index: number) => {
    const { items } = this.state
    const { dispatch } = this.props

    const nextItems = update(items, { [index]: { $set: item } })

    this.setState({
      items: nextItems,
    })

    dispatch({
      type: actions.ADD_REQUEST_CATEGORY_POPUP_UPDATE,
      items: nextItems,
    })
  }

  renderCategories = () => {
    const { working } = this.props
    const { items } = this.state
    const { duplicates, editingItem } = this.state

    return (
      <div className='modal__request-category modal__text modal__request-category--options'>
        {items.map((item, i) => (
          <Category
            data={item}
            disabled={working}
            duplicate={duplicates.includes(item.name.toLowerCase())}
            editing={editingItem === item.id}
            index={i}
            key={item.id}
            onEdit={this.editItem}
            onMove={this.moveItem}
            onRemove={this.removeItem}
            onSave={this.updateItem}
            onDrop={this.dropItem}
          />
        ))}
      </div>
    )
  }

  render() {
    if (!this.props.initiated) {
      return (
        <div>
          <Loader text={false} type='medium' />
        </div>
      )
    }

    const { items, onClose, working, t, connectDropTarget } = this.props
    const { created, createdError } = this.state
    const textareaClassName = classnames('modal__text', {
      'modal__text--error': !!createdError,
    })

    return connectDropTarget(
      <div className='modal__content  modal__content--type-fields'>
        <button className='modal__close' type='button' onClick={onClose} />
        <div className='modal__title'>{t('ChangeField')}</div>
        <div className='modal__request-category--box'>
          <div className='modal__request-category modal__request-category--info'>
            <div className='modal__request-category modal__request-category--info-icon'>
              <Icon />
            </div>
            <div className='modal__text'>{t('OneOption')}</div>
          </div>
          <div className='modal__request-category--input modal__request-category--margin'>
            <div className='modal__text'>{t('Name')}</div>
            <div className='modal__text'>{t('TypeTitle')}</div>
          </div>
          {!!items.length && this.renderCategories()}
          {!items.length && <Warning text={t('OneOptionPerLine')} />}
          <div className='modal__request-category--textarea modal__request-category--margin'>
            <div className='modal__text'>{t('Options')}</div>
            <div>
              <textarea
                className={textareaClassName}
                disabled={working}
                value={created}
                onChange={this.updateCreated}
              />
              {!!createdError && (
                <div className='input--error input--large'>
                  <div className='input__msg'>{t(createdError)}</div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='modal__request-category--margin'>
          <Button.Save
            disabled={!this.hasChanges()}
            working={working}
            onClick={this.save}
          >
            {t('Common:Save')}
          </Button.Save>
          <Button.Cancel onClick={onClose}>{t('Common:Cancel')}</Button.Cancel>
        </div>
      </div>
    )
  }
}

export default compose(
  withTranslation('AddRequestCategoryPopup'),
  DropTarget(CategoryType, dropTarget, con => ({
    connectDropTarget: con.dropTarget(),
  })),
  connect(state => ({
    ...state.addRequestCategoryPopup,
  }))
)(AddRequestCategoryPopup)
