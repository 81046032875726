// @flow

import React, { useState, useEffect } from 'react'
import type { Node } from 'react'

import Email from './ActionCards/Email'
import Phase from './ActionCards/Phase'
import CreateActivity from './ActionCards/CreateActivity'
import CreatePost from './ActionCards/CreatePost'
import CreateRequest from './ActionCards/CreateRequest'

import styles from './AutomateInfo.module.scss'
import CardHeader from '../RequestConstructor/CardHeader'
import classnames from 'classnames'

import { ActionTypes } from '../RequestConstructor/constants'

import CardArrow from '../RequestConstructor/CardArrow'

type Props = {
  actions: Array<Object>,
  canEdit: boolean,
  onRemove: number => void,
  onUpdate: (number, Object) => Function,
  setWorking: boolean => void,
}

const AutomateActions = (props: Props): Node => {
  const { canEdit } = props

  const [actions, setActions] = useState([])

  useEffect(() => {
    setActions(props.actions)
  }, [props.actions])

  const cardClass = classnames(
    'request-constructor__card',
    'request-constructor__card--wide'
  )

  const getCardType = type =>
    Object.keys(ActionTypes).find(key => ActionTypes[key] === type)

  const renderCard = action => {
    switch (action.action_type) {
      case 'send_email':
        return (
          <Email
            data={action.action_data}
            setWorking={props.setWorking}
            onUpdate={data => props.onUpdate(action.id, data)}
          />
        )
      case 'start_phase':
        return (
          <Phase
            data={action.action_data}
            onUpdate={data => props.onUpdate(action.id, data)}
          />
        )
      case 'create_activity':
        return (
          <CreateActivity
            data={action.action_data}
            onUpdate={data => props.onUpdate(action.id, data)}
          />
        )
      case 'create_request':
        return (
          <CreateRequest
            data={action.action_data}
            setWorking={props.setWorking}
            onUpdate={data => props.onUpdate(action.id, data)}
          />
        )
      case 'create_post':
        return (
          <CreatePost
            data={action.action_data}
            setWorking={props.setWorking}
            onUpdate={data => props.onUpdate(action.id, data)}
          />
        )
    }

    return null
  }

  const renderAction = action => (
    <React.Fragment key={action.id}>
      <CardArrow wide />
      <div className={cardClass} key={action.id}>
        <button
          className='modal__close'
          type='button'
          disabled={!canEdit}
          onClick={() => props.onRemove(action.id)}
        />
        <CardHeader automate cardType={getCardType(action.action_type)} />
        {renderCard(action)}
      </div>
    </React.Fragment>
  )

  return (
    <div className={styles.actionsContent}>
      {actions.map(action => renderAction(action))}
    </div>
  )
}

export default AutomateActions
