// @flow

import React from 'react'

import styles from './ScrollbarTrackY.module.scss'

const TrackY = props => {
  const { elementRef, ...restProps } = props

  return (
    <div {...restProps} style={{}} ref={elementRef} className={styles.trackY} />
  )
}

export default TrackY
