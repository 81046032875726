// @flow

import { SET_WIDGET, TOGGLE_WIDGET } from './Widget.actions'

const widget = (state = null, action) => {
  switch (action.type) {
    case TOGGLE_WIDGET: {
      const { name } = action

      if (name === state) {
        return null
      }

      return name
    }
    case SET_WIDGET: {
      const { name } = action

      return name
    }
    default:
      return state
  }
}

export default widget
