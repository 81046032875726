// @flow

import { get } from 'lodash-es'

import {
  getUrlForAvatar,
  getUserId,
  getUserName,
  isNoProfile,
} from '../../../../utils/utils'

export function getLinkData(ownerObj?: ?Object, uk: Object): Array<string> {
  if (!ownerObj) {
    const avatar = get(uk, ['logo_mobile_obj', 'origin'])

    return ['/uk', avatar, uk.name]
  }

  const userId = getUserId(ownerObj)
  const isNoProfileUser = isNoProfile(ownerObj)

  const link = userId && !isNoProfileUser ? `/profile/${userId}` : ''

  const avatar = getUrlForAvatar(ownerObj)

  return [link, avatar, getUserName(ownerObj)]
}
