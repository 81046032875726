// @flow

import React from 'react'
import classnames from 'classnames'
import { isNil } from 'lodash-es'
import type { Node } from 'react'

import Button from '../../../Button'
import { useThumbnail } from '../../../../hooks'
import { getFileType, IMAGE_TYPE, VIDEO_TYPE } from '../../../../utils/file'
import File from '../../../Documents/DocumentPreview/File'

import styles from './RequestFile.module.scss'

type Props = {
  extended?: boolean,
  file: Object,
  fileDim: number,
  onClick?: number => void,
  onRemove?: Object => void,
  preview?: boolean,
  selected: boolean,
}

const RequestFile = (props: Props): Node => {
  const { file, selected, fileDim, preview } = props

  const fileType = getFileType(file)
  const thumbnail = useThumbnail(file, '170X170', fileType === VIDEO_TYPE)

  const { width, height, filename, name } = file

  const imgClass = classnames({
    [styles.square]: !isNil(width) && width === height,
    [styles.fallback]: !width || !height,
    [styles.horizontal]: width > height,
    [styles.vertical]: width < height,
  })

  const fileClass = classnames(styles.file, {
    [styles.selected]: selected,
    [styles.selectable]: !preview && props.onClick,
    [styles.preview]: preview && props.onClick,
    [styles.document]: fileType !== IMAGE_TYPE && fileType !== VIDEO_TYPE,
  })

  const hadleClick = () => props.onClick(file.id)

  const fileProps = Object.assign(
    {},
    props.onClick ? { onClick: hadleClick } : undefined
  )

  const handleRemove = e => {
    e.stopPropagation()
    props.onRemove && props.onRemove(file)
  }

  return (
    <div
      className={fileClass}
      style={{
        width: `calc(${fileDim}% - 12px)`,
        paddingBottom: `calc(${fileDim}% - 14px)`,
      }}
      {...fileProps}
      title={filename || name}
    >
      {fileType === IMAGE_TYPE || fileType === VIDEO_TYPE ? (
        <div className={styles.imgContainer}>
          <img src={thumbnail} className={imgClass} />
        </div>
      ) : (
        <File data={file} className={styles.doc} />
      )}
      <div className={styles.hoverConatiner} />
      <span>{filename || name}</span>
      {props.onRemove && (
        <Button.Close className={styles.close} onClick={handleRemove} />
      )}
    </div>
  )
}

export default RequestFile
