// @flow

import React from 'react'
import type { Node } from 'react'

import styles from './Cell.module.scss'

type Props = {
  children?: Node,
  style?: Object,
}

const Cell = (props: Props): Node => {
  const { children, style } = props

  return (
    <div style={style} className={styles.cell}>
      {children}
    </div>
  )
}

export default Cell
