// @flow

import React, { Component } from 'react'
import type Node from 'react'
import { connect } from 'react-redux'
import Stickyfill from 'stickyfilljs'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'

import WidgetAddEntities from '../../components/widgets/WidgetAddEntities'
import WidgetParticipantsList from '../../components/widgets/WidgetParticipantsList/WidgetParticipantsList'
import WidgetFiles from '../../components/widgets/WidgetFiles/WidgetFiles'
import LabelsPopup from '../../components/Labels/LabelsPopup'
import * as actions from '../../containers/Chatroom/Chatroom.actions'
import { getUser, isMenuCollapsed } from '../../utils/commonSelectors'
import { setWidget } from '../../containers/Widget/Widget.actions'
import { isStaffUser } from '../../utils/utils'
import Widgets, { WIDGET_TYPES } from './widgets'
import Lightbox from '../../components/LightboxGallery'
import { getActiveWidget } from '../Widget/Widget.selectors'

type Props = {
  isEmailChat: boolean,
  match: Object,
  openAddMembersPopUp: () => void,
  openConvertToRequestPopUp: () => void,
  openedWidget: string,
  openLabelsPopUp: () => void,
  openModal: (Node | null) => void,
  permissions: Object,
  setWidget: string => void,
  t: string => string,
}
type State = {
  filesOpen: boolean,
  participantsOpen: boolean,
}

class ChatroomWidgets extends Component<Props, State> {
  state = {
    lightboxIndex: null,
  }

  componentDidMount() {
    const elements = document.querySelectorAll('.widget__container--content')
    Stickyfill.add(elements)
  }

  componentWillUnmount() {
    this.props.setWidget(null)
  }

  isNew = () => {
    const {
      match: { params },
    } = this.props

    return params.chatroomUuid === 'create'
  }

  openLabelsPopUp = () => {
    const { chatroomInfo } = this.props
    const handleChange = labels => {
      this.props.updateLabelsInit(chatroomInfo.uuid, labels)
    }

    this.props.openModal(
      <LabelsPopup
        infoData={chatroomInfo}
        onChange={handleChange}
        onEdit={this.props.openModal}
      />
    )
  }

  setFileIndex = ind => {
    this.setState({ lightboxIndex: ind })
  }

  closeLightbox = () => {
    this.setState({ lightboxIndex: null })
  }

  render() {
    const {
      match: { params },
      openAddMembersPopUp,
      openConvertToRequestPopUp,
      permissions: {
        can_edit_labels: canEditLabels,
        can_edit_members: canEditMembers,
      },
      isEmailChat,
      collapsed,
      user,
      files,
      activeWidget,
    } = this.props

    // todo removeMembers
    let canConvertToRequest = isStaffUser(user)

    if (!isEmailChat) {
      canConvertToRequest = false
    }

    if (this.isNew()) {
      return <Widgets collapsed={collapsed}></Widgets>
    }

    const menuItems = []

    if (canEditMembers) {
      menuItems.push({
        icon: 'user',
        key: 'Participants',
        callback: openAddMembersPopUp,
      })
    }

    if (canEditLabels) {
      menuItems.push({
        icon: 'label',
        key: 'Label',
        callback: this.openLabelsPopUp,
      })
    }

    if (canConvertToRequest) {
      menuItems.push({
        icon: 'request',
        key: 'Request',
        callback: openConvertToRequestPopUp,
      })
    }

    const { lightboxIndex } = this.state

    return (
      <>
        <Widgets collapsed={collapsed}>
          <WidgetAddEntities menuItems={menuItems} />
          <WidgetParticipantsList
            params={params}
            isActive={activeWidget === WIDGET_TYPES.participants}
          />
          <WidgetFiles setFileIndex={this.setFileIndex} />
        </Widgets>
        <Lightbox
          newGallery
          activeIndex={lightboxIndex}
          images={files}
          scope={'chat'}
          onClose={this.closeLightbox}
        />
      </>
    )
  }
}

const mapStateToProps = state => {
  const { chatroomInfo, openedWidget } = state.chatroom
  const permissions = chatroomInfo ? chatroomInfo.permissions : {}

  return {
    ...state.WidgetFiles,
    permissions,
    openedWidget,
    isEmailChat: chatroomInfo && chatroomInfo.from_email,
    collapsed: isMenuCollapsed(state),
    chatroomInfo,
    user: getUser(state),
    activeWidget: getActiveWidget(state),
  }
}

const mapDispatchToProps = {
  openAddMembersPopUp: actions.openAddMembersPopUp,
  openConvertToRequestPopUp: actions.openConvertToRequestPopUp,
  openModal: actions.showModal,
  updateLabelsInit: actions.updateLabelsInit,
  setWidget,
}

export default compose(
  withTranslation('WidgetsGeneral'),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ChatroomWidgets)
