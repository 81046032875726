// @flow

import * as actions from './CompanyRegistration.actionTypes'

export function inviteCompany(
  params: Object
): { params: Object, type: string } {
  return {
    type: actions.COMPANY_REGISTRATION_INVITE,
    params,
  }
}
