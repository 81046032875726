import React from 'react'
import Icon from '../../../Icon'
import styles from './ProviderItem.module.scss'

const ProviderItem = ({ icon, name, setStage }) => (
  <div className={styles.item} onClick={setStage}>
    <Icon className={styles['main-icon']} id={icon} />
    <span>{name}</span>
    <Icon className={styles['arrow-icon']} id={'arrowRight'} />
  </div>
)

export default ProviderItem
