// @flow

import * as actions from '../../../User/UserList/UserList.actionTypes'

export function toggleAll(params) {
  return {
    type: actions.USER_LIST_TOGGLE_ALL,
    params,
  }
}

export function allSelectedUsers(selected, all) {
  return {
    type: actions.USER_LIST_SELECTED,
    selected,
    all,
  }
}

export function remove(values, params, meta) {
  return {
    type: actions.USER_LIST_REMOVE,
    values,
    params,
    meta,
  }
}

export function restore(values, params, meta) {
  return {
    type: actions.USER_LIST_RESTORE,
    values,
    params,
    meta,
  }
}

export function update(ids, patch, params, meta) {
  return {
    type: actions.USER_LIST_UPDATE,
    ids,
    patch,
    params,
    meta,
  }
}

export function approve(users, params, meta) {
  return {
    type: actions.USER_LIST_APROVE_USERS,
    users,
    params,
    meta,
  }
}

export function error() {
  return {
    type: actions.USER_LIST_ERROR,
  }
}

export function onUpdate() {
  return {
    type: actions.USER_LIST_BEFORE_LOAD,
  }
}
