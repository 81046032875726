import { all, put, fork, takeLatest, call, select } from 'redux-saga/effects'
import { omit, has, get, differenceBy } from 'lodash-es'

import * as actions from './Feedback.actions'
import {
  widgetFilesWereLoaded,
  widgetFilesWereDeleted,
} from '../../components/widgets/WidgetFiles/WidgetFiles.actionTypes'
import { widgetChatParticipantsListInitiated } from '../../components/widgets/WidgetParticipantsList/WidgetParticipantsList.actionTypes'
import * as selectors from './Feedback.selectors'
import api from '../../core/api'
import { RATING_TYPES } from '../../constants'
import { serverError } from '../../components/Layout/Layout.actions'
import { redirectTo404 } from '../../utils/routing'

function getRatingPayload({ key, value, id }) {
  const relations = has(omit(RATING_TYPES, 'general'), key)
    ? 'feedback_ratings'
    : 'requests'

  return {
    params: {
      value,
      rating_type: RATING_TYPES[key],
      [relations]: id,
    },
  }
}

function* watchLoadFeedback() {
  yield takeLatest(actions.LOAD_FEEDBACK, loadFeedback)
}

function* watchSendRating() {
  yield takeLatest(actions.SEND_RATING, sendRating)
}

function* watchSendFeedback() {
  yield takeLatest(actions.SEND_FEEDBACK, sendFeedback)
}

function* watchUpdateFeedback() {
  yield takeLatest(actions.UPDATE_FEEDBACK, updateFeedback)
}

function* watchUpdateRating() {
  yield takeLatest(actions.UPDATE_RATING, updateRating)
}

function* watchUpdateFiles() {
  yield takeLatest(actions.FEEDBACK_UPDATE_FILES, updateFiles)
}

function* watchUpdateLike() {
  yield takeLatest(actions.UPDATE_LIKE, updateLike)
}

function* watchLoadRequest() {
  yield takeLatest(actions.LOAD_REQUEST, loadRequest)
}

function* updateRating({ id, params }) {
  try {
    const rating = yield call(api.rating.updateRating, id, params)

    yield put(actions.ratingWasUpdated(rating))
  } catch (e) {
    console.log(e)
    yield put(serverError(e))
  }
}

function* loadRating({ params }) {
  try {
    const {
      results: { objects },
    } = yield call(api.rating.getRating, params)

    const rating = get(objects, ['0']) || { id: null }

    yield put(actions.ratingWasLoaded(rating))
  } catch (e) {
    console.log(e)
    yield put(serverError(e))
  }
}

function* loadFeedback({ params, isUpdate }) {
  try {
    const data = yield call(api.feedback.getFeedback, params)

    yield put(actions.feedbackWasLoaded(data))

    if (!isUpdate) {
      const files = data.file_objs.map(f => {
        return { ...f, scope: 'post' }
      })
      yield put(widgetFilesWereLoaded(files))
      yield put(
        widgetChatParticipantsListInitiated([
          { ...data.owner_obj, active: true },
        ])
      )
    }
  } catch (e) {
    console.log(e)
    redirectTo404(e) || (yield put(serverError(e)))
  }
}

function* sendRating({ params }) {
  try {
    const rating = yield call(api.rating.setRating, params)

    yield put(actions.ratingWasSent(rating))
  } catch (e) {
    console.log(e)
    yield put(serverError(e))
  }
}

function* sendFeedback({ payload }) {
  try {
    const generalRating = yield select(selectors.getGeneralRating)
    const feedbackPayload = {
      rating: generalRating.id,
      ...(payload.text ? { text: payload.text } : {}),
      ...(payload.files ? { files: payload.files.map(el => el.id) } : {}),
    }

    const feedback = yield call(api.feedback.sendFeedback, feedbackPayload)

    const ratings = omit(payload, ['text', 'files'])

    for (const key in ratings) {
      const value = ratings[key]

      if (value) {
        yield* sendRating(getRatingPayload({ key, value, id: feedback.uuid }))
      }
    }

    yield put(actions.feedbackWasSent(feedback))
  } catch (e) {
    console.log(e)
    yield put(serverError(e))
  }
}

function* updateFeedback({ payload }) {
  try {
    const feedback = yield select(selectors.getFeedbackData)
    let updatedFeedback

    if (feedback.text !== payload.text) {
      updatedFeedback = yield call(api.feedback.updateFeedback, feedback.uuid, {
        text: payload.text,
      })
    }

    const ratings = omit(payload, 'text')

    for (const key in ratings) {
      const extraRating = feedback.extra_ratings.find(
        el => el.rating_type === RATING_TYPES[key]
      )

      if (extraRating) {
        yield* updateRating({
          id: extraRating.id,
          params: { value: ratings[key] },
        })
      } else {
        const value = ratings[key]

        if (value) {
          yield* sendRating(getRatingPayload({ key, value, id: feedback.uuid }))
        }
      }
    }

    if (updatedFeedback) {
      yield put(actions.feedbackWasUpdated(updatedFeedback))
    }
  } catch (e) {
    console.log(e)
    yield put(serverError(e))
  }
}

function* updateFiles({ files }) {
  try {
    const feedbackData = yield select(selectors.getFeedbackData)
    const feedbackId = feedbackData.uuid
    const payload = { files: files.map(el => el.id) }

    const data = yield call(api.feedback.updateFeedback, feedbackId, payload)
    yield put(actions.feedbackFilesWasUpdated(data))

    if (files.length < feedbackData.file_objs.length) {
      const deletedFilesId = differenceBy(
        feedbackData.file_objs,
        files,
        'id'
      ).map(f => f.id)
      yield put(widgetFilesWereDeleted(deletedFilesId))
    } else {
      yield put(widgetFilesWereLoaded(data.file_objs))
    }
  } catch (e) {
    console.log(e)
    yield put(serverError(e))
  }
}

function* updateLike({ isDislike }) {
  try {
    const feedbackData = yield select(selectors.getFeedbackData)
    const payload = {
      model: 'post',
      model_pk: feedbackData.uuid,
      ...(isDislike ? { dislike: true } : {}),
    }
    const getApi = () => {
      const { is_liked: isLiked, is_disliked: isDisliked } = feedbackData

      if ((!isDislike && isLiked) || (isDislike && isDisliked)) {
        return api.like.removeLike
      }

      if (!isLiked && !isDisliked) {
        return api.like.upLike
      }

      return api.like.updateLike
    }

    yield call(getApi(), { likeId: feedbackData.user_like, payload })

    yield* loadFeedback({ params: { id: feedbackData.uuid }, isUpdate: true })
  } catch (e) {
    console.log(e)
    yield put(serverError(e))
  }
}

function* loadRequest({ id }) {
  try {
    const request = yield call(api.request.getRequest, id)
    yield* loadRating({ params: { request: id } })
    yield put(actions.requestWasLoaded(request))
  } catch (e) {
    console.log(e)
    yield put(serverError(e))
  }
}

export default function* watch() {
  yield all([
    fork(watchLoadFeedback),
    fork(watchSendRating),
    fork(watchSendFeedback),
    fork(watchUpdateFeedback),
    fork(watchUpdateRating),
    fork(watchUpdateFiles),
    fork(watchUpdateLike),
    fork(watchLoadRequest),
  ])
}
