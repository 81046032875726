// @flow

import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import {
  getDomondaSettings,
  updateDomondaSettings,
} from '../../core/api/api.budgetPlan'

import { SimpleBlock, Header } from '../Block'
import Settings from '../Settings'

import InputField from '../InputField'

const DOMONDA_API_KEY = 'domonda_api_key'
const HIDDEN_KEY = '**********************************'

const DomondaIntegration = (): Node => {
  const { t } = useTranslation('Settings')

  const [isLoading, setLoading] = useState(true)
  const [apiKey, setApiKey] = useState(null)

  useEffect(() => {
    if (isLoading) {
      getDomondaSettings().then(data => {
        setApiKey((data[DOMONDA_API_KEY] && HIDDEN_KEY) || '')
        setLoading(false)
      })
    }
  }, [isLoading])

  const formik = useFormik({
    validateOnChange: true,
    enableReinitialize: true,
    initialValues: {
      [DOMONDA_API_KEY]: apiKey || '',
    },
    validationSchema: Yup.object({
      [DOMONDA_API_KEY]: Yup.string(),
    }),
    onSubmit: submitValues => {
      updateDomondaSettings(submitValues).then(data => {
        formik.setFieldValue(
          DOMONDA_API_KEY,
          (data[DOMONDA_API_KEY] && HIDDEN_KEY) || ''
        )
      })
    },
  })

  const checkHiddenKey = ({ target: { name, value } }: Object) => {
    if (value === HIDDEN_KEY) {
      formik.setFieldValue(name, '')
    }
  }

  return (
    <SimpleBlock working={isLoading}>
      <Header header>{t('DomondaIntegrationTitle')}</Header>
      <form onSubmit={formik.handleSubmit}>
        <Settings.Row label={t('DomondaAPIKeyLabel')}>
          <div className='input input--large input--default input--block'>
            <InputField
              value={formik.values[DOMONDA_API_KEY]}
              error={formik.errors[DOMONDA_API_KEY]}
              name={DOMONDA_API_KEY}
              placeholder={t('DomondaAPIKeyPlaceholder')}
              onChange={formik.handleChange}
              onFocus={checkHiddenKey}
            />
          </div>
        </Settings.Row>
        <Settings.Actions
          withChanges={formik.dirty}
          isValid={formik.isValid}
          working={isLoading}
          onCancel={formik.handleReset}
        />
      </form>
    </SimpleBlock>
  )
}

export default DomondaIntegration
