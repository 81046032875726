// @flow

import React from 'react'
import { Link } from 'react-router-dom'

type Props = { linkText: string }

const RecoveryLink = (props: Props) => {
  const { linkText } = props

  return (
    <div className='recovery'>
      <Link to='/recover'>{linkText}</Link>
    </div>
  )
}

export default RecoveryLink
