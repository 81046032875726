// @flow

import { REQUEST_DATA_LOAD } from './RequestCreate.actionTypes'

export function requestDataLoad(data) {
  return {
    type: REQUEST_DATA_LOAD,
    data,
  }
}
