// @flow

import React from 'react'
import classnames from 'classnames'
import type { Node } from 'react'

import Regular from '../Regular'
import type { Props } from '../Regular/Regular'

import styles from './Delete.module.scss'

const Delete = (props: Props): Node => {
  const { className, ...rest } = props

  const buttonClass = classnames(styles.button, className)

  return (
    <Regular
      {...rest}
      className={buttonClass}
      view='desaturated'
      color='reject'
    />
  )
}

export default Delete
