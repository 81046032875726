// @flow

import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { get } from 'lodash-es'

import Input from '../Input/Input'
import Button from '../Button'
import { getUk } from '../../../utils/commonSelectors'
import { inviteSetupPass } from '../../../core/api/api.system'
import Checkbox from '../../Checkbox/Checkbox'

import { passwordSecureRegExp } from '../../../utils/utils'
import { TERMS_LINK, GDPR_LINK, PASS_INFO_LINK } from '../../../constants'

import styles from './PasswordSettingForm.module.scss'

type Props = {
  hash: string,
  setVerifiedHash: boolean => void,
  setWorking: boolean => void,
}

const PasswordSettingForm = (props: Props) => {
  const { hash } = props
  const { t } = useTranslation('Registration')
  const { name: ukName } = useSelector(state => getUk(state))

  const formik = useFormik({
    validateOnChange: false,
    initialValues: {
      password: '',
      passwordConfirm: '',
      checked: false,
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required(t('"Field" is not allowed to be empty'))
        .matches(passwordSecureRegExp, t('PasswordSecureValidation')),
      passwordConfirm: Yup.mixed()
        .required(t('"Field" is not allowed to be empty'))
        .oneOf(
          [Yup.ref('password')],
          t('"Field" must be one of [refpassword]')
        ),
      checked: Yup.boolean().required().oneOf([true]),
    }),
    onSubmit: () => {
      props.setWorking(true)

      inviteSetupPass(hash, { password: formik.values.password })
        .then(data => {
          const profileId = get(data, 'profile_id')

          if (profileId) {
            window.location = '/'
          }
        })
        .catch(() => props.setVerifiedHash(false))
    },
  })

  const getError = () => {
    if (formik.errors.password === t('PasswordSecureValidation')) {
      return [
        <>
          {' '}
          {formik.errors.password}{' '}
          <a
            target='_blank'
            href={PASS_INFO_LINK}
            rel='noopener noreferrer'
            className={styles.errorLink}
          >
            {t('Details')}
          </a>
        </>,
      ]
    } else {
      return formik.errors.password && [formik.errors.password]
    }
  }

  return (
    <>
      <div className={styles.title}>
        <div>{t('SuccessTitleFirst', { uk: ukName })}</div>
        <div
          dangerouslySetInnerHTML={{
            __html: t('SuccessTitleSecond', { uk: ukName }),
          }}
        ></div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <Input
          type='password'
          value={formik.values.password}
          name='password'
          errors={getError()}
          placeholder={t('PasswordPlaceholder')}
          onChange={formik.handleChange}
        />
        <Input
          type='password'
          value={formik.values.passwordConfirm}
          name='passwordConfirm'
          placeholder={t('PasswordConfirmPlaceholder')}
          errors={[formik.errors.passwordConfirm]}
          onChange={formik.handleChange}
        />
        <div className={styles.conditions}>
          <Checkbox
            name='checked'
            checked={formik.values.checked}
            onChange={formik.handleChange}
          />
          <span>
            {`${t('Accept')} `}
            <a target='_blank' href={TERMS_LINK} rel='noopener noreferrer'>
              {t('TermsAndConditions')}
            </a>
            {` ${t('And')} `}
            <a target='_blank' href={GDPR_LINK} rel='noopener noreferrer'>
              {t('Gdpr')}
            </a>
          </span>
        </div>
        <Button disabled type='submit' text={t('Confirm')} />
      </form>
    </>
  )
}

export default PasswordSettingForm
