// @flow

import { all, put, call, fork, takeEvery } from 'redux-saga/effects'
import type { Saga } from 'redux-saga'

import api from '../../core/api'
import { serverError } from '../Layout/Layout.actions'
import * as actions from './SettingsCorporate.actionTypes'

function* watchUpdate() {
  yield takeEvery(actions.SETTINGS_CORPORATE_SAVE, update)
}

function* update({ id, params }) {
  try {
    const result = yield call(api.uk.update, id, params)
    yield put({ type: actions.SETTINGS_CORPORATE_UPDATE, ...result })
  } catch (error) {
    yield put(serverError(error))
    yield put({ type: actions.SETTINGS_CORPORATE_ERROR, error })
  }
}

function* watchUpdateTheme() {
  yield takeEvery(actions.SETTINGS_CORPORATE_SAVE_THEME, updateTheme)
}

function* updateTheme({ theme }) {
  try {
    const result = yield call(api.uk.update, 'theme', theme)
    window.location.reload(true)
    yield put({ type: actions.SETTINGS_CORPORATE_SAVED_THEME, ...result })
  } catch (error) {
    yield put(serverError(error))
    yield put({ type: actions.SETTINGS_CORPORATE_ERROR, error })
  }
}

function* watchUpdateUkConfig() {
  yield takeEvery(actions.SETTINGS_CORPORATE_SAVE_CONFIG, updateConfig)
}

function* updateConfig({ params }) {
  try {
    const config = yield call(api.uk.updateConfig, params)
    yield put({ type: actions.SETTINGS_CORPORATE_SAVED_CONFIG, config })
  } catch (error) {
    yield put(serverError(error))
    yield put({ type: actions.SETTINGS_CORPORATE_ERROR, error })
  }
}

export default function* watch(): Saga<void> {
  yield all([
    fork(watchUpdate),
    fork(watchUpdateTheme),
    fork(watchUpdateUkConfig),
  ])
}
