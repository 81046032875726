// @flow

import React from 'react'
import { Link } from 'react-router-dom'

import { MARKETPLACE_ROUTES } from '../../constants'
import Avatar from '../../components/ListItem/Avatar'

import styles from './Managers.module.scss'

const Managers = props => {
  const {
    provider: {
      id,
      image: { origin },
      name,
    },
  } = props

  return (
    <Link to={MARKETPLACE_ROUTES['marketplaceProvider'](id)}>
      <div className={styles.managers} title={name}>
        <Avatar avatar={origin} />
        <span className={styles.text}>{name}</span>
      </div>
    </Link>
  )
}

export default Managers
