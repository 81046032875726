// @flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { map } from 'lodash-es'

import Button from '../../components/Button'
import Modal from '../../components/Modal'
import ModalBody from '../../components/Modal/ModalBody'
import ModalButtons from '../../components/Modal/ModalButtons'
import ModalCloseButton from '../../components/Modal/ModalCloseButton'
import ModalHead from '../../components/Modal/ModalHead'
import { useOverflow } from '../../hooks'
import ModalRow from '../../components/Modal/ModalRow'
import List from '../../components/List'
import ListItem from '../../components/ListItem'
import { ORDER_STATUSES } from '../OrderProfilePage/OrderProfile/OrderProfile.constants'
import OrderProfileStatusMutation from '../OrderProfilePage/OrderProfile/OrderProfileStatus/OrderProfileStatus.mutation'

function getOptions(t) {
  return ORDER_STATUSES.map(status => ({
    value: status.value,
    label: t(`status${status.translationKey}`),
    style: { color: status.color },
  }))
}

const OrdersStatusModal = props => {
  const { selectedOrders } = props

  const initialValue =
    selectedOrders && selectedOrders.length === 1
      ? selectedOrders[0].status
      : null

  const { t } = useTranslation('Orders')

  const [value, setValue] = useState(initialValue)

  useOverflow()

  const isChanged = value !== initialValue

  const options = getOptions(t)

  const handleSelect = value => () => setValue(value)

  const handleComlete = () => props.onClose(true)

  const handleError = error => {
    console.error(error)
    props.onClose(true)
  }

  const handleSave = () => {
    OrderProfileStatusMutation.commit(
      { ids: map(selectedOrders, 'id'), status: value },
      handleComlete,
      handleError
    )
  }

  const title =
    selectedOrders.length > 1
      ? 'ChangeOrdersStatusesTitle'
      : 'ChangeOrderStatusTitle'

  const saveButtonText =
    selectedOrders.length > 1 ? 'SaveOrdersStatuses' : 'SaveOrderStatus'

  const handleClose = () => props.onClose()

  return (
    <Modal
      isOpen
      style={{ content: { minWidth: '348px' } }}
      onRequestClose={handleClose}
    >
      <ModalHead title={t(title)} />
      <ModalCloseButton onClose={handleClose} />
      <ModalBody>
        <ModalRow>
          <List styleType='bordered' style={{ width: '100%' }}>
            {options.map(option => (
              <ListItem
                primaryText={option.label}
                key={option.value}
                style={option.style}
                isSelected={option.value === value}
                onClick={handleSelect(option.value)}
              />
            ))}
          </List>
        </ModalRow>
      </ModalBody>
      <ModalButtons>
        <Button.Save disabled={!isChanged} onClick={handleSave}>
          {t(saveButtonText)}
        </Button.Save>
        <Button.Cancel onClick={handleClose}>
          {t('Common:Cancel')}
        </Button.Cancel>
      </ModalButtons>
    </Modal>
  )
}

export default OrdersStatusModal
