// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import type { Element } from 'react'

import SelectCustom from '../../Select/SelectCustom'
import ConfirmationPopup from '../../modals/ConfirmationPopup'

type Props = {
  handleDelete: void => void,
  handleDisconnect: void => void,
  hideModal: boolean => boolean,
  setRemoveModal: (?Element<'div'>) => void,
}

const ExtraActions = (props: Props) => {
  const { setRemoveModal, hideModal, handleDelete, handleDisconnect, email } =
    props

  const { t } = useTranslation('EmailIntegrationNylas')

  const canDelete = email?.permissions.can_delete
  const canDisconnect = email?.permissions.can_disconnect

  const openDeleteModal = () => {
    setRemoveModal(
      <ConfirmationPopup
        confirm={t('Delete')}
        title={t('IntegrationDeleteMainTitle')}
        text={t('IntegrationDeleteTitle')}
        onClose={hideModal}
        onOk={handleDelete}
      />
    )
  }

  const openDisconnectModal = () => {
    setRemoveModal(
      <ConfirmationPopup
        confirm={t('Disconnect')}
        title={t('IntegrationDisconnectMainTitle')}
        text={t('IntegrationDisconnectTitle')}
        onClose={hideModal}
        onOk={handleDisconnect}
      />
    )
  }

  const getOptions = () => {
    if (canDisconnect) {
      return [
        {
          value: 'disconnect',
          label: t('Disconnect'),
          handler: () => openDisconnectModal(),
          icon: 'link',
        },
      ]
    }

    if (canDelete) {
      return [
        {
          value: 'delete',
          label: t('Delete'),
          handler: () => openDeleteModal(),
          icon: 'onDelete',
        },
      ]
    }
  }

  const preventOpen = e => {
    e.stopPropagation()
  }

  if (!canDelete && !canDisconnect) {
    return null
  }

  return (
    <div className='table-extra table-extra--settings'>
      <SelectCustom
        options={getOptions()}
        onClick={preventOpen}
        onChange={opt => opt.handler()}
      />
    </div>
  )
}

export default ExtraActions
