// @flow

export const BUILDING_VIEW_INITIATING = 'BUILDING_VIEW_INITIATING'
export const BUILDING_VIEW_STATE_RESET = 'BUILDING_VIEW_STATE_RESET'
export const BUILDING_VIEW_INITIATED = 'BUILDING_VIEW_INITIATED'
export const BUILDING_VIEW_NEED_UPDATE = 'BUILDING_VIEW_NEED_UPDATE'
export const BUILDING_VIEW_UPDATED = 'BUILDING_VIEW_UPDATED'
export const BUILDING_VIEW_VIRTUAL_FLAT_NEED_UPDATE =
  'BUILDING_VIEW_VIRTUAL_FLAT_NEED_UPDATE'
export const BUILDING_VIEW_VIRTUAL_FLAT_UPDATED =
  'BUILDING_VIEW_VIRTUAL_FLAT_UPDATED'
export const BUILDING_VIEW_SHOW_MODAL = 'BUILDING_VIEW_SHOW_MODAL'
export const BUILDING_VIEW_HIDE_MODAL = 'BUILDING_VIEW_HIDE_MODAL'
export const BUILDING_VIEW_TOGGLE_COLLAPSE = 'BUILDING_VIEW_TOGGLE_COLLAPSE'
export const BUILDING_VIEW_ERROR = 'BUILDING_VIEW_ERROR'
export const BUILDING_VIEW_RELOAD = 'BUILDING_VIEW_RELOAD'
