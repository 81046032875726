// @flow

import React from 'react'
import type { Node } from 'react'
import { connect } from 'react-redux'

import Widgets from './widgets'
// import WidgetMayKnow from '../../components/widgets/WidgetMayKnow'
// import WidgetLastReg from '../../components/widgets/WidgetLastReg'
import { isMenuCollapsed, getUser } from '../../utils/commonSelectors'
// import { isDwellerUser } from '../../utils/utils'

import type { User } from '../../components/Auth/Login/Login.reducer'

type Props = {
  children: Node,
  collapsed: boolean,
  user: User,
}

const DefaultWidgets = ({ collapsed, children }: Props) => (
  <Widgets collapsed={collapsed}>
    {children}
    {/* {isDwellerUser(user) ? <WidgetMayKnow /> : null} */}
    {/* {isStaffUser(user) ? <WidgetLastReg /> : null} */}
  </Widgets>
)

const mapStateToProps = state => ({
  user: getUser(state),
  collapsed: isMenuCollapsed(state),
})

export default connect(mapStateToProps)(DefaultWidgets)
