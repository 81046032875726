// @flow

import React from 'react'
import { map } from 'lodash-es'
import classNames from 'classnames'
import type { Node } from 'react'

import Checkbox from '../Checkbox'
import ToolboxCell from '../Users/UserList/Toolbox/ToolboxCell'

import styles from './Toolbox.module.scss'

type Props = {
  checked: boolean,
  children?: Node,
  className?: string,
  isDisabled?: boolean,
  items?: Array<Object>,
  itemValueKey?: string,
  selected: boolean,
  setSelected?: (Array<number>) => void,
  showSelectAllCheckbox?: boolean,
}

const Toolbox = (props: Props): Node => {
  const {
    items,
    itemValueKey,
    checked,
    showSelectAllCheckbox,
    children,
    selected,
    className,
    isDisabled,
  } = props

  const toggleAll = () => props.setSelected(map(items, itemValueKey))

  const checkBoxProps = Object.assign(
    {},
    showSelectAllCheckbox && props.setSelected && items && itemValueKey
      ? { onChange: toggleAll }
      : undefined
  )
  const toolboxClass = classNames(styles.toolbox, className)

  return (
    <div className={toolboxClass}>
      {showSelectAllCheckbox && (
        <ToolboxCell>
          <Checkbox
            outer
            selected={selected}
            disabled={(items && !items.length) || isDisabled}
            checked={checked}
            {...checkBoxProps}
          />
        </ToolboxCell>
      )}
      {children}
    </div>
  )
}

Toolbox.defaultProps = {
  showSelectAllCheckbox: false,
  checked: false,
  selected: false,
}

export default Toolbox
