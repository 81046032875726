// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import Step from './Step'

import styles from './Steps.module.scss'
import {
  PREVIEW_STEP,
  MAPPING_STEP,
  SPLIT_STEP,
} from '../MappedDocument.constants'

type Props = { setPrevStep: string => void, step: string }

const Steps = (props: Props) => {
  const { step } = props

  const { t } = useTranslation('DocumentMapping')

  const stepClass = classnames(styles.step, styles[step])

  const handleClick = step => () => {
    props.setPrevStep(step)
  }

  const handleSplitStep = Object.assign(
    {},
    step === PREVIEW_STEP || step === MAPPING_STEP
      ? { handleClick: handleClick(SPLIT_STEP) }
      : undefined
  )

  const handleMappingStep = Object.assign(
    {},
    step === PREVIEW_STEP
      ? { handleClick: handleClick(MAPPING_STEP) }
      : undefined
  )

  return (
    <div className={styles.container}>
      <Step text={t('Step1')} className={stepClass} {...handleSplitStep} />
      <Step text={t('Step2')} className={stepClass} {...handleMappingStep} />
      <Step text={t('Step3')} className={stepClass} />
      <Step text={t('Step4')} className={stepClass} />
    </div>
  )
}

Steps.defaultProps = { step: 1 }

export default Steps
