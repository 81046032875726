// @flow

import React from 'react'
import { Link } from 'react-router-dom'

import { getUserId, getUserName } from '../../../utils/utils'

type Props = { user: Object }

const CompanyTeamItem = (props: Props) => {
  const { user } = props

  return (
    <Link to={`/profile/${getUserId(user)}`}>
      <div className='cprofile-view-team__list-item cprofile-view-team__list-item--selected'>
        <div className='cprofile-view-team__list-item-userpic cover'>
          <img src={user.avatar} alt={getUserName(user)} />
        </div>
        <span className='cprofile-view-team__list-item-stat'>
          {getUserName(user)}
        </span>
        <span className='cprofile-view-team__list-item-stat'>
          {user.company_post_name}
        </span>
      </div>
    </Link>
  )
}

export default CompanyTeamItem
