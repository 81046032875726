// @flow

import { put, call, fork, takeLatest } from 'redux-saga/effects'
import type { Saga } from 'redux-saga'

import api from '../../core/api'
import * as t from './HeaderSearch.actionTypes'
import { serverError } from '../Layout/Layout.actions'

const {
  HEADER_SEARCH_QUERY,
  HEADER_SEARCH_QUERY_SUCCESS,
  HEADER_SEARCH_QUERY_ERROR,
} = t

function* watchSearch() {
  yield takeLatest(HEADER_SEARCH_QUERY, loadSearchList)
}

function* loadSearchList(action) {
  try {
    const { search, path } = action
    let data = {}
    switch (path) {
      case 'request': {
        const count = yield call(api.request.getRequestListCount, { search })
        const main = yield call(api.request.getRequestListLight, { search })

        data = { ...main, meta: { ...main.meta, ...count } }
        break
      }
      case 'requests':
        data = yield call(api.request.getListTiny, { search })
        break
      case 'building-gallery':
        data = yield call(api.building.getListTiny, { search })
        break
      case 'building':
        data = yield call(api.building.getListLiveSearch, { query: search })
        break
      case 'profile':
        data = yield call(api.profile.getListLiveSearch, { query: search })
        break
      case 'flat':
        data = yield call(api.flat.getListLiveSearch, { query: search })
        break
      case 'newsband':
        data = yield call(api.newsband.getListTiny, { search })
        break
      case 'mail':
        data = yield call(api.mail.liveSearch, search)
        break
      default:
        data = yield call(api.profile.getListLiveSearch, { query: search })
        break
    }
    yield put({ type: HEADER_SEARCH_QUERY_SUCCESS, data })
  } catch (error) {
    yield put(serverError(error))
    yield put({ type: HEADER_SEARCH_QUERY_ERROR, error })
  }
}

export default function* watch(): Saga<void> {
  yield fork(watchSearch)
}
