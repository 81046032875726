// @flow

import React from 'react'
import classNames from 'classnames'
import type { Node } from 'react'

import Icon from '../../../../../../../../../../../components/Icon'
import type { IconId } from '../../../../../../../../../../../components/Icon/Icon.types'

import styles from './Label.module.scss'

type Props = {
  automation?: boolean,
  deadlineClass?: string,
  iconId: IconId,
  text: string,
}

const Label = (props: Props): Node => {
  const { text, iconId, deadlineClass, automation } = props

  const labelClass = classNames(styles.label, {
    [styles[deadlineClass]]: !!deadlineClass,
    [styles.automate]: !!automation,
  })

  return (
    <div className={labelClass}>
      <Icon id={iconId} />
      <span>{text}</span>
    </div>
  )
}

export default Label
