// @flow

import React, { useContext } from 'react'
import Textarea from 'react-textarea-autosize'
import type { Node } from 'react'

import ModalLabel from '../../../../../Modal/ModalLabel'
import ModalRow from '../../../../../Modal/ModalRow'
import CardStageContext from '../../../CardStage.context'
import type { CardType } from '../../../../NewWizard.types'

import styles from './TextInput.module.scss'

type Props = {| card: CardType |}

const TextInput = (props: Props): Node => {
  const {
    card: { label, name },
  } = props

  const { errors, values, handleChange } = useContext(CardStageContext)

  return (
    <>
      <ModalRow>
        <ModalLabel>{label}</ModalLabel>
      </ModalRow>
      <Textarea
        className={styles.textarea}
        name={name}
        error={errors[name]}
        value={values[name]}
        onChange={handleChange}
      />
    </>
  )
}

export default TextInput
