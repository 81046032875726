// @flow

import React from 'react'
import OutsideClick from 'react-onclickout'

import SelectListOption from '../../../SelectListOption/SelectListOption'
import { difference, union } from 'lodash-es'

const NewOrdersSelectList = props => {
  const { options, selectedOrderIds } = props

  const handleSelect = value => {
    const isIntersect = selectedOrderIds.includes(value)

    let nextSelectedOrderIds = []

    if (isIntersect) {
      nextSelectedOrderIds = difference(selectedOrderIds, [value])
    } else {
      nextSelectedOrderIds = union(selectedOrderIds, [value])
    }

    props.setSelectedOrderIds(nextSelectedOrderIds)
  }

  return (
    <OutsideClick onClickOut={props.handleClickOut}>
      <ul>
        {options.map(option => {
          return (
            <SelectListOption
              key={option.value}
              option={option}
              handleClick={handleSelect}
              isSelected={selectedOrderIds.includes(option.value)}
            />
          )
        })}
      </ul>
    </OutsideClick>
  )
}

export default NewOrdersSelectList
