/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type OrderProfileRequest_data$ref: FragmentReference;
declare export opaque type OrderProfileRequest_data$fragmentType: OrderProfileRequest_data$ref;
export type OrderProfileRequest_data = {|
  +idwellRequestId: ?number,
  +id: string,
  +$refType: OrderProfileRequest_data$ref,
|};
export type OrderProfileRequest_data$data = OrderProfileRequest_data;
export type OrderProfileRequest_data$key = {
  +$data?: OrderProfileRequest_data$data,
  +$fragmentRefs: OrderProfileRequest_data$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "OrderProfileRequest_data",
  "type": "CustomerOrderNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "idwellRequestId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '9679def7a2f1e919e344bc156c2feda9';
module.exports = node;
