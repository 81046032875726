// @flow

export const HEADER_SEARCH_LONG = 'HEADER_SEARCH_LONG'
export const HEADER_SEARCH_SHORT = 'HEADER_SEARCH_SHORT'
export const HEADER_SEARCH_INPUT = 'HEADER_SEARCH_INPUT'
export const HEADER_SEARCH_QUERY = 'HEADER_SEARCH_QUERY'
export const HEADER_SEARCH_QUERY_SUCCESS = 'HEADER_SEARCH_QUERY_SUCCESS'
export const HEADER_SEARCH_QUERY_ERROR = 'HEADER_SEARCH_QUERY_ERROR'
export const HEADER_SEARCH_CHANGE_LOCATION = 'HEADER_SEARCH_CHANGE_LOCATION'
export const HEADER_SEARCH_SHOW_SELECT = 'HEADER_SEARCH_SHOW_SELECT'
export const HEADER_SEARCH_HIDE_SELECT = 'HEADER_SEARCH_HIDE_SELECT'
export const HEADER_SEARCH_SELECT_OPTION = 'HEADER_SEARCH_SELECT_OPTION'
export const HEADER_SEARCH_HIDE_SUGGEST = 'HEADER_SEARCH_HIDE_SUGGEST'
