import * as actions from './ConfirmEmail.actionTypes'

const initialState = {
  working: true,
  error: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.CONFIRM_EMAIL_SEND:
      return initialState
    case actions.CONFIRM_EMAIL_UPDATE:
      return {
        ...state,
        email: action.email,
        working: false
      }
    case actions.CONFIRM_EMAIL_ERROR:
      return {
        ...state,
        working: false,
        error: action.detail
      }
    default:
      return state
  }
}
