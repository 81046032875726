// @flow

import { commitMutation, graphql } from 'react-relay'

const mutation = graphql`
  mutation FavoriteProvidersMutation(
    $input: RemoveFavoriteProvidersMutationInput!
  ) {
    removeFavoriteProviders(input: $input) {
      removedIds
    }
  }
`

const commit = (environment, providerData, callback) => {
  return commitMutation(environment, {
    mutation,
    variables: {
      input: providerData,
    },
    onCompleted: callback,
    onError: callback,
  })
}

export default { commit }
