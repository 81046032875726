// @flow

import { put, call, fork, takeEvery } from 'redux-saga/effects'
import type { Saga } from 'redux-saga'

import api from '../../../core/api'
import * as actions from './WidgetLastReg.actionTypes'
import { serverError } from '../../Layout/Layout.actions'

function* watchLoad() {
  yield takeEvery(actions.WIDGET_GET_LAST_REG_INITIATING, load)
}

function* load() {
  try {
    const result = yield call(
      api.profile.getListTiny,
      { last_registered: true },
      false
    )

    yield put({
      type: actions.WIDGET_GET_LAST_REG_INITIATED,
      users: result.results.objects,
    })
  } catch (error) {
    yield put(serverError(error))
  }
}

export default function* watch(): Saga<void> {
  yield fork(watchLoad)
}
