// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import Button from '../../Button'

export const NOTIFICATION_TICKET = 'NOTIFICATION_TICKET'
export const NOTIFICATION_POST = 'NOTIFICATION_POST'
export const NOTIFICATION_EMAIL = 'NOTIFICATION_EMAIL'
export const NOTIFICATION_PASSWORD = 'NOTIFICATION_PASSWORD'
export const NOTIFICATION_ERROR = 'NOTIFICATION_ERROR'
export const NOTIFICATION_RETRY_ERROR = 'NOTIFICATION_RETRY_ERROR'
export const NOTIFICATION_USER_REMOVAL = 'NOTIFICATION_USER_REMOVAL'
export const NOTIFICATION_USER_RESTORE = 'NOTIFICATION_USER_RESTORE'

const ERRORS_TYPES_LIST = [NOTIFICATION_ERROR, NOTIFICATION_RETRY_ERROR]

type Props = {
  data: Object,
  remove: number => void,
  t: string => string,
}

const NotificationItem = (props: Props) => {
  const { data, remove } = props

  const { t } = useTranslation('Notification')

  const getText = data => {
    let text = ''
    let param = data.param
    switch (data.type) {
      case NOTIFICATION_TICKET:
        text = `${t('NewRequest')} <b>${t('Common:NumberSymbol')}${
          data.article
        }</b> ${t('CreatedSuccessively')}!`
        break
      case NOTIFICATION_POST:
        text = t('PostSuccess')
        break
      case NOTIFICATION_EMAIL:
        text = t('VerifyEmail', { email: data.email })
        break
      case NOTIFICATION_PASSWORD:
        text = t('PasswordChanged')
        break
      case NOTIFICATION_USER_REMOVAL:
        text = `${t('Common:User')} ${data.fullname} ${t('Common:Removed')}`
        break
      case NOTIFICATION_USER_RESTORE:
        text = `${t('Common:User')} ${data.fullname} ${t('Common:Restored')}`
        break
      case NOTIFICATION_RETRY_ERROR || NOTIFICATION_ERROR:
        if (data.translate) {
          text = t(data.text, { ...param })
        } else {
          text = t(`Errors:${data.text}`)
        }

        break

      default:
        if (data.translate) {
          text = t(data.text)
        } else {
          text = data.text
        }
    }

    return { __html: text }
  }

  const isError = ERRORS_TYPES_LIST.includes(data.type) || data.error

  const css = classnames(
    'alert alert--medium alert--warning alert--block alert--center alert--top',
    {
      'alert--error': isError,
    }
  )

  const alertText = getText(data)

  return (
    <div className={css}>
      <Button.Close onClick={() => remove(data.id)} />
      <p className='alert__text' dangerouslySetInnerHTML={alertText} />
    </div>
  )
}

export default NotificationItem
