// @flow

import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import Textarea from 'react-textarea-autosize'
import { useDispatch } from 'react-redux'

import Icon from '../../../../static/icons/danger.svg'
import { serverError } from '../../Layout/Layout.actions'
import Button from '../../Button'
import { update } from '../../../core/api/api.uk'
import { COMPANY_INFO_UPDATE } from './CompanyInfo.actionTypes'

type Props = {
  description?: string,
  id: number,
  name: ?string,
  setEditing: boolean => void,
  setWorking: boolean => void,
}

const CompanyInfoForm = (props: Props) => {
  const { name, description, id } = props

  const { t } = useTranslation('Company')

  const dispatch = useDispatch()

  const formik = useFormik({
    validateOnChange: false,
    enableReinitialize: true,
    initialValues: {
      name: name || '',
      description: description || '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t('Errors:Required')),
      description: Yup.string(),
    }),
    onSubmit: values => {
      props.setWorking(true)

      update(id, { ...values })
        .then(data => dispatch({ type: COMPANY_INFO_UPDATE, ...data }))
        .catch(err => {
          dispatch(serverError(err))
        })
        .finally(() => {
          props.setWorking(false)
          props.setEditing(false)
        })
    },
  })

  const handleCancel = e => {
    formik.handleReset(e)

    props.setEditing(false)
  }

  return (
    <form className='apply-profile-edit' onSubmit={formik.handleSubmit}>
      <span className='apply-profile-edit__01'>{t('Name')}</span>
      <div className='input input--font-xlarge input--default input--large input--block'>
        <input
          name='name'
          value={formik.values.name}
          onChange={formik.handleChange}
        />
        {formik.errors.name && (
          <div
            className='u-a14__row u-a14__row--01'
            style={{ flexDirection: 'column' }}
          >
            <div className='u-a14__alert u-a14__alert_danger'>
              <Icon className='u-a14__alert-icon' />
              <span className='u-a14__alert-text'>{formik.errors.name}</span>
            </div>
          </div>
        )}
      </div>
      <span className='apply-profile-edit__02'>{t('Description')}</span>
      <div className='textarea textarea--default textarea--xlarge textarea--block textarea--noresize'>
        <Textarea
          className='textarea-2__input'
          name='description'
          placeholder={t('DescPlaceholder')}
          value={formik.values.description}
          minRows={5}
          maxRows={10}
          onChange={formik.handleChange}
        />
      </div>
      <div className='cprofile-edit-user__submit'>
        <Button.Save type='submit' disabled={!formik.dirty}>
          {t('Save')}
        </Button.Save>
        <Button.Cancel type='button' onClick={handleCancel}>
          {t('Cancel')}
        </Button.Cancel>
      </div>
    </form>
  )
}

export default CompanyInfoForm
