// @flow

import React from 'react'
import { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import type { Node } from 'react'

import { DocumentsView } from '../../Documents'
import Header from './Header'
import Content from './Content'
import Title from './Title'
import Text from './Text'
import Info from './Info'
import Actions from './Actions'
import PostItemAudience from '../PostItem/PostItemAudience'
import { useUser } from '../../../hooks/useUser'
import { isStaffUser } from '../../../utils/utils'
import Footer from './Footer'
import Like from '../../Like'
import Conversation from './Conversation'
import { TICKER_TYPE } from '../Post.constants'
import { newsListReadPost } from '../../../containers/NewsList/NewsList.actions'
import ThreadsListItemRequests from '../../../containers/Mail/ThreadsListItemRequests'

import styles from './NewPostItem.module.scss'

type Props = { pinning: boolean, post: Object, scrollParams: Object }

const NewPostItem = (props: Props): Node => {
  const {
    post: {
      owner_obj,
      request,
      title,
      text,
      uuid,
      permissions: {
        can_pin_post,
        can_unpin_post,
        can_block,
        can_block_owner,
        can_unblock_owner,
        can_like,
        can_edit,
      },
      is_liked,
      like_count,
      building_objs,
      building_group_objs,
      new_audience,
      date_to,
      date_from,
      pinned,
      spam,
      created,
      post_type,
      comment_count,
      file_objs,
      is_unread,
    },
    pinning,
    scrollParams,
  } = props

  const postRef = useRef(null)
  const dispatch = useDispatch()
  const user = useUser()

  const isPostOnScreen = () => {
    const element = postRef.current

    if (!element || !is_unread || !scrollParams) {
      return false
    }

    const innerHeight = scrollParams.innerHeight

    const bottom = element.getBoundingClientRect().bottom

    if (bottom <= innerHeight) {
      return true
    }
  }

  useEffect(() => {
    if (isPostOnScreen()) {
      dispatch(newsListReadPost(uuid))
    }
  }, [scrollParams])

  return (
    <div className={styles.postItem} ref={postRef}>
      <Header>
        <Info
          ownerObj={owner_obj}
          spam={spam}
          dateFrom={date_from}
          dateTo={date_to}
          created={created}
          isUnread={is_unread}
        />
        <Actions
          canEdit={can_edit}
          canBlock={can_block}
          canUnblockOwner={can_unblock_owner}
          canBlockOwner={can_block_owner}
          pinned={pinned}
          canPinPost={can_pin_post}
          canUnpinPost={can_unpin_post}
          uuid={uuid}
          pinning={pinning}
          ownerObj={owner_obj}
          spam={spam}
        />
      </Header>
      <Content>
        <PostItemAudience
          buildings={building_objs}
          building_groups={building_group_objs}
          audience={new_audience}
          user={user}
          uuid={uuid}
          owner={owner_obj.id}
        />
        {isStaffUser(user) && request?.title && (
          <div className={styles.requests}>
            <ThreadsListItemRequests requests={[request]} viewedCount={1} />
          </div>
        )}
        <Title title={title} uuid={uuid} />
        <Text text={text} />
        <DocumentsView
          showGreatPreview
          withoutBlanks
          files={file_objs}
          minRowLength={3}
          scope='post'
          postUuid={uuid}
        />
      </Content>
      {post_type !== TICKER_TYPE && (
        <Footer>
          <Like
            active={is_liked}
            count={like_count}
            disabled={!can_like}
            model='post'
            uuid={uuid}
          />
          <Conversation commentCount={comment_count} uuid={uuid} />
        </Footer>
      )}
    </div>
  )
}

export default NewPostItem
