// @flow

import React from 'react'
import type { Node } from 'react'

type Props = {
  onClick?: (SyntheticEvent<HTMLButtonElement>) => void,
  text: string,
  type: 'button' | 'submit',
  working: boolean,
}

const Button = (props: Props): Node => {
  const { working, text, type } = props

  return (
    // TODO remove eslint-disable string when eslint-plugin-react package will be fixed
    // eslint-disable-next-line react/button-has-type
    <button className='btn' type={type} onClick={props.onClick}>
      {working && <span className='button__spinner' />}
      {text}
    </button>
  )
}

Button.defaultProps = { type: 'button', working: false }

export default Button
