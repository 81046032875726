// @flow

import React, { useState } from 'react'
import type { Node } from 'react'
import Textarea from '../../Textarea'

import { useTranslation } from 'react-i18next'

import Button from '../../Button'
import Modal from '../../Modal'
import ModalBody from '../../Modal/ModalBody'
import ModalButtons from '../../Modal/ModalButtons'
import ModalHead from '../../Modal/ModalHead'

import styles from './NotesPopup.module.scss'
import ModalCloseButton from '../../Modal/ModalCloseButton'

type Props = {
  notes: string,
  onClose: () => void,
  updateNotice: (notice: string) => void,
}

const NotesPopup = ({ onClose, notes = '', updateNotice }: Props): Node => {
  const { t } = useTranslation('Common')
  const [text, setText] = useState(notes)

  const handleChange = (e: SyntheticInputEvent<HTMLTextAreaElement>) => {
    setText(e.target.value)
  }
  const handleSave = () => {
    updateNotice(text)
    onClose()
  }

  return (
    <Modal isOpen className={styles.modal} onRequestClose={onClose}>
      <ModalHead title={t('Notes')} />
      <ModalCloseButton onClose={onClose} />
      <ModalBody>
        <Textarea
          autoFocus
          minRows={2}
          placeholder={t('Notes')}
          value={text}
          className={styles.textarea}
          onChange={handleChange}
        />
      </ModalBody>
      <ModalButtons>
        <Button.Save disabled={notes === text} onClick={handleSave}>
          {t('Common:Save')}
        </Button.Save>
        <Button.Cancel disabled={notes === text} onClick={onClose}>
          {t('Common:Cancel')}
        </Button.Cancel>
      </ModalButtons>
    </Modal>
  )
}

export default NotesPopup
