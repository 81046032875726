// @flow

import React from 'react'

import NewTableBody from '../NewTable/NewTableBody'
import TemplatesBodyRow from './TemplatesBodyRow'

const TemplatesTableBody = props => {
  const { templates, selectedItems, isEmail } = props

  return (
    <NewTableBody>
      {templates.map(template => (
        <TemplatesBodyRow
          selectedItems={selectedItems}
          handleChange={props.handleChange}
          key={template.id}
          template={template}
          isEmail={isEmail}
          handleClone={props.handleClone}
          handleEdit={props.handleEdit}
          openRemoveModal={props.openRemoveModal}
        />
      ))}
    </NewTableBody>
  )
}

export default TemplatesTableBody
