// @flow

import React from 'react'
import type { Node } from 'react'

import LoginLink from '../LoginLink'

type Props = {
  descriptionText: string,
  headerText: string,
  t: string => string,
}

const NotAvailable = (props: Props): Node => {
  const { descriptionText, headerText } = props

  return (
    <div className='newpass_wrap free-registration'>
      <div className='b_main_title'>
        <h1>{headerText}</h1>
      </div>
      <div className='b_white_block--bottom'>
        <div className='def_form login_form js_validate'>
          <div className='lf__fields'>
            <div className='def_form_line' />
            <div
              dangerouslySetInnerHTML={{
                __html: descriptionText,
              }}
              className='free-registration--success-text'
            />
          </div>
        </div>
      </div>
      <LoginLink linkText={props.t('Login')} />
    </div>
  )
}

export default NotAvailable
