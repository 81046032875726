// @flow

import React, { Children, Component } from 'react'
import type { Node } from 'react'
import { withResizeDetector } from 'react-resize-detector'
import { isNull, zipObject } from 'lodash-es'

import Row, { ITEM_INDENT } from './TileRow'

import { getRowsHeight, prepareRows } from './TileList.utils'
import styles from './TileList.module.scss'

const BOX_INDENT = 15

type Props = {
  boxIndent: number,
  children: Node,
  itemIndent: number,
  maxRows?: number,
  rowLength: number,
}
type State = {
  maxHeight: ?number,
  rowHeights: ?Object,
}

class TileList extends Component<Props, State> {
  static defaultProps = {
    boxIndent: BOX_INDENT,
    itemIndent: ITEM_INDENT,
    rowLength: 5,
  }

  state = {
    maxHeight: this.getInitialMaxHeight(),
    rowHeights: this.getInitialRowHeights(),
  }

  getInitialMaxHeight() {
    const { maxRows } = this.props

    return !maxRows || maxRows < 0 ? null : 0
  }

  getInitialRowHeights() {
    const { maxRows } = this.props

    if (!maxRows || maxRows < 0) {
      return null
    }

    const values = Array(maxRows).fill(0)
    const keys = values.map((r, i) => i)

    return zipObject(keys, values)
  }

  getRows = () => {
    const { children, rowLength } = this.props

    return prepareRows(Children.toArray(children), rowLength)
  }

  getMaxHeightStyle = () => {
    const { maxRows } = this.props
    const { maxHeight } = this.state

    if (!isNull(maxHeight) && maxRows) {
      const { boxIndent, itemIndent } = this.props
      const indents = boxIndent * 2 + itemIndent * (maxRows - 1)

      return {
        maxHeight: `${maxHeight + indents}px`,
      }
    }

    return {}
  }

  updateMaxHeight = (row: number, height: number) => {
    const { maxRows } = this.props
    const { rowHeights } = this.state

    if (rowHeights && maxRows && maxRows > row) {
      const heights = { ...rowHeights, [row]: height }

      this.setState({
        maxHeight: getRowsHeight(heights),
        rowHeights: heights,
      })
    }
  }

  render() {
    const { rowLength, boxIndent, itemIndent, children } = this.props

    if (!Children.count(children)) {
      return null
    }

    const style = {
      '--boxIndent': `${boxIndent}px`,
      '--itemIndent': `${itemIndent}px`,
      ...this.getMaxHeightStyle(),
    }

    return (
      <div className={styles.container} style={style}>
        {this.getRows().map((row, i) => (
          <Row
            index={i}
            itemIndent={itemIndent}
            key={row.id}
            length={rowLength}
            onUpdateHeight={this.updateMaxHeight}
          >
            {row.items}
          </Row>
        ))}
      </div>
    )
  }
}

export default withResizeDetector(TileList)
