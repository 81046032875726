// @flow

import React from 'react'
import type { Node } from 'react'

import Icon from '../../../Icon'
import type { IconId } from '../../../Icon/Icon.types'

type Props = { icon?: IconId | Node, iconChildren?: Array<Node> }

const HeaderIcon = (props: Props): Node => {
  const { iconChildren, icon } = props

  if (iconChildren && iconChildren.length) {
    return iconChildren
  }

  if (icon) {
    return typeof icon === 'string' ? (
      <Icon id={icon} className='bar__title-symbol' />
    ) : (
      icon
    )
  }

  return null
}

export default HeaderIcon
