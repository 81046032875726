// @flow

import React from 'react'
import classnames from 'classnames'
import { isUndefined } from 'lodash-es'
import Select from '../Select'

class SelectTableCell extends Select {
  static defaultProps = {
    ...Select.defaultProps,
    backspaceRemoves: false,
    className: 'select-table-cell',
    openOnFocus: true,
    autoBlur: true,
  }

  handleClick = e => {
    e.preventDefault()
  }

  renderInput = () => {
    const { tabIndex, value } = this.props

    if (!isUndefined(value)) {
      return (
        <button
          className='table-item__text-dropdown'
          ref={_ref => (this.input = _ref)}
          tabIndex={tabIndex}
          type='button'
          onFocus={this.handleInputFocus}
          onBlur={this.onInputBlur}
        >
          {value.label}&nbsp;
        </button>
      )
    }

    return null
  }

  renderMenu = options => {
    const { value } = this.props

    if (options && options.length) {
      const optionElems = options.map(opt => {
        const liClassName = classnames('dropdown__list-item', {
          'dropdown__list-item--checked': opt.value === value.value,
        })

        return (
          <li
            className={liClassName}
            key={`opt-${opt.value}`}
            onClick={() => this.selectValue(opt)}
          >
            <span className='dropdown__list-item-text'>{opt.label}</span>
          </li>
        )
      })

      return (
        <div className='dropdown dropdown--default dropdown--open'>
          <ul className='dropdown__list dropdown__list--checklist dropdown__list--default'>
            {optionElems}
          </ul>
        </div>
      )
    }

    return null
  }

  render() {
    const { options, className } = this.props
    const { isOpen, isFocused, isPseudoFocused } = this.state

    const selectClass = classnames('Select Select--single', className, {
      'is-focused': isFocused,
      'is-open': isOpen,
      'is-pseudo-focused': isPseudoFocused,
    })

    return (
      <div className={selectClass} onClick={this.handleClick}>
        <div
          onKeyDown={this.handleKeyDown}
          onMouseDown={this.handleMouseDown}
          onTouchEnd={this.handleTouchEnd}
          onTouchStart={this.handleTouchStart}
          onTouchMove={this.handleTouchMove}
        >
          {this.renderInput()}
        </div>
        {isOpen && this.renderOuter(options)}
      </div>
    )
  }
}

export default SelectTableCell
