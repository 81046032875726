//@flow

import React, { useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import Settings from '../Settings'

import { useDispatch } from 'react-redux'
import { globalModalError } from '../Layout/Layout.actions'

import {
  addPrinter,
  updatePrinter,
  deletePrinter,
} from '../../core/api/api.printer'
import { getChangedValues } from '../../utils/utils'

import InputField from '../InputField'
import Button from '../Button'
import Modal from '../Modal'
import ConfirmationPopup from '../modals/ConfirmationPopup'

type Props = {
  onUpdate: () => void,
  printer?: Object,
}

const Printer = (props: Props): Node => {
  const { printer } = props

  const { t } = useTranslation('Service')
  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(false)
  const [modal, setModal] = useState(null)

  const handleDeletePrinter = () => {
    setLoading(true)
    deletePrinter(printer.id).then(() => {
      setLoading(false)
      props.onUpdate()
    })
  }

  const showSuccessModal = () => {
    setModal(
      <ConfirmationPopup
        title={t('SuccessModalTitle')}
        text={
          printer?.id ? t('SuccessModalUpdateText') : t('SuccessModalAddText')
        }
        confirm={t('Ok')}
        showCancel={false}
        onOk={() => hideModal(true)}
        onClose={() => hideModal(true)}
      />
    )
  }

  const showDeleteModal = () => {
    setModal(
      <ConfirmationPopup
        title={t('DeleteModalTitle')}
        text={t('DeleteModalText')}
        confirm={t('DeleteYes')}
        onOk={handleDeletePrinter}
        onClose={() => hideModal()}
      />
    )
  }

  const hideModal = update => {
    setModal(null)

    if (update) {
      props.onUpdate()
    }
  }

  const formik = useFormik({
    validateOnChange: true,
    enableReinitialize: true,
    initialValues: {
      name: printer?.name || '',
      email: printer?.email || '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required(),
      email: Yup.string().email().required(),
    }),
    onSubmit: submitValues => {
      const changedValues = getChangedValues(formik.initialValues, submitValues)
      setLoading(true)
      const api = printer?.id ? updatePrinter : addPrinter
      api(changedValues, printer?.id)
        .then(() => showSuccessModal())
        .catch(error => {
          const errorTitle = t('ErrorModalTitle')
          const errors = error.message?.response?.data?.errors
          const errorText = errors
            ? errors.email || errors.name || errors
            : printer?.id
            ? t('ErrorModalUpdateText')
            : t('ErrorModalAddText')
          dispatch(globalModalError(errorText, errorTitle, true))
        })
        .finally(() => setLoading(false))
    },
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <Settings.Row label={t('PrinterName')}>
        <div className='input input--large input--default input--block'>
          <InputField
            value={formik.values.name}
            name={'name'}
            placeholder={t('EnterPrinterName')}
            onChange={formik.handleChange}
          />
        </div>
      </Settings.Row>
      <Settings.Row label={t('PrinterEmail')}>
        <div className='input input--large input--default input--block'>
          <InputField
            value={formik.values.email}
            name={'email'}
            placeholder={t('EnterPrinterEmail')}
            onChange={formik.handleChange}
          />
        </div>
      </Settings.Row>
      <Settings.Actions
        showCancel={!printer?.id || formik.dirty}
        withChanges={formik.dirty}
        isValid={formik.isValid}
        working={isLoading}
        additional={
          printer?.id && !formik.dirty ? (
            <Button.Cancel working={isLoading} onClick={showDeleteModal}>
              {t('DeletePrinter')}
            </Button.Cancel>
          ) : null
        }
        onCancel={formik.handleReset}
      />
      <Modal
        className='Modal__Bootstrap modal-dialog'
        isOpen={!!modal}
        onRequestClose={hideModal}
      >
        {modal}
      </Modal>
    </form>
  )
}

export default Printer
