import React from 'react'
import type { Node } from 'react'
import { DragSource } from 'react-dnd'

const ItemTypes = {
  category: 'categoryItem',
  unmatched: 'unmatchedItem'
}
export { ItemTypes }

type Props = {
  category?: string,
  connectDragSource: (Node) => Node,
  isDragging: boolean,
  name: string,
}
type WrapperProps = {
  category?: string,
  dndType: string,
  name: string,
}

const dragSource = {
  beginDrag ({ name, category }: Props) {
    return { name, category }
  }
}

const DraggableItem = (props: Props) => {
  const { isDragging, connectDragSource, name } = props
  const style = { opacity: isDragging ? 0.4 : 1 }
  return connectDragSource(
    <div
      className='modal__mapping-categories--table-item'
      style={style}
    >
      <div className='modal__text'>{name}</div>
    </div>
  )
}

const DraggableItemWrapper = (props: WrapperProps) => {
  const { dndType, name, category } = props
  const Item = DragSource(
    dndType,
    dragSource,
    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging()
    })
  )(DraggableItem)
  return <Item category={category} name={name} />
}

export default DraggableItemWrapper
