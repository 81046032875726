// @flow

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import { getTags } from '../../../../core/api/api.template'
import { getEmailTags } from '../../../../core/api/api.emailTemplate'

import NewSelectSimple from '../../../NewSelectSimple'
import { CREATE } from '../TemplatePopup.constants'

import styles from './TagSelect.module.scss'

type Props = {
  actionType: string,
  buttonText: string,
  disabled: boolean,
  handleChangeTag: Object => void,
  isEmail: boolean,
  onSelectAll?: (Array<Object>) => void,
  selectedTags: Array<string | number>,
  textType: string,
}

const TagSelect = (props: Props): Node => {
  const { buttonText, selectedTags, textType, disabled, actionType, isEmail } =
    props

  const { t } = useTranslation('Templates')

  const [tags, setTags] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const params = {
      text_type: textType,
      name_exclude: textType === 'plain' ? 'automation' : undefined,
    }
    const api = isEmail ? getEmailTags : getTags

    api(params)
      .then(data => {
        const tags = data.results.objects.map(tag => ({
          value: tag.name,
          label: t(tag.description),
        }))

        setTags(tags)

        if (props.onSelectAll && actionType === CREATE) {
          props.onSelectAll(tags.map(tag => tag.value))
        }
      })
      .finally(() => setLoading(false))
  }, [textType])

  if (loading) {
    return null
  }

  const handleChange = tag => props.handleChangeTag(tag)

  const selectedItems = tags.filter(tag => selectedTags.includes(tag.value))

  const handleClick = option => {
    props.handleChangeTag(option.value)
  }

  const getSelectedLabel = tag => tag.label

  return (
    <NewSelectSimple
      useSearch
      isMulti
      className={styles.select}
      openClass={styles.open}
      disabled={disabled}
      listSize={5}
      noResultsText={t('NoTagsFound')}
      options={tags}
      searchPlaceholder={t('TagSearchPlaceholder')}
      selectedItems={selectedItems}
      placeholder={buttonText}
      setSelectedItems={handleClick}
      getSelectedLabel={getSelectedLabel}
      onChange={handleChange}
    />
  )
}

TagSelect.defaultProps = { disabled: false }

export default TagSelect
