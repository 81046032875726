// @flow

import React from 'react'
import classnames from 'classnames'
import type { Node } from 'react'

import styles from './MenuDivider.module.scss'

type Props = {
  collapsed?: boolean,
  hasSub?: boolean,
}

const MenuDivider = (props: Props): Node => {
  const { collapsed, hasSub } = props

  const minimized = collapsed || hasSub

  const dividerClass = classnames(styles.divider, {
    [styles.collapsed]: minimized,
  })

  return <div className={dividerClass} />
}

export default MenuDivider
