import { connect } from 'react-redux'
import ImportMapTable from './ImportMapTable'
import { getLanguageCode } from '../../utils/commonSelectors'

const mapStateToProps = (state) => ({
  ...state.ImportMapTableReducer,
  languageCode: getLanguageCode(state)
})

export default connect(mapStateToProps)(ImportMapTable)
