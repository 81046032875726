// @flow

import React from 'react'
import classnames from 'classnames'
import type { Node } from 'react'

import Button from '../../Button'

import styles from './Row.module.scss'

type Props = {
  children: Node,
  className?: string,
  editing?: boolean,
  onEdit?: () => void,
  title?: string,
  working?: boolean,
}

const Row = (props: Props) => {
  const { title, children, working, editing, className } = props

  const rowClassName = classnames(styles.root, className)

  return (
    <div className={rowClassName} key={title} id={title}>
      <div className='request-owner-label'>
        {title && <>{title}</>}
        {!editing && !working && props.onEdit && (
          <Button.Edit onClick={props.onEdit} />
        )}
      </div>
      {children}
    </div>
  )
}

export default Row
