// @flow

export const GET_CHECKITEMS_INIT = '/Checklist/GET_CHECKITEMS_INIT'
export const GET_CHECKITEMS_SUCCESS = '/Checklist/GET_CHECKITEMS_SUCCESS'
export const GET_CHECKITEMS_ERROR = '/Checklist/GET_CHECKITEMS_ERROR'

export const CREATE_CHECKITEM_INIT = '/Checklist/CREATE_CHECKITEM_INIT'
export const CREATE_CHECKITEM_SUCCESS = '/Checklist/CREATE_CHECKITEM_SUCCESS'
export const CREATE_CHECKITEM_ERROR = '/Checklist/CREATE_CHECKITEM_ERROR'

export const DELETE_CHECKITEM_INIT = '/Checklist/DELETE_CHECKITEM_INIT'
export const DELETE_CHECKITEM_SUCCESS = '/Checklist/DELETE_CHECKITEM_SUCCESS'
export const DELETE_CHECKITEM_ERROR = '/Checklist/DELETE_CHECKITEM_ERROR'

export const TOGGLE_CHECKITEM_INIT = '/Checklist/TOGGLE_CHECKITEM_INIT'
export const TOGGLE_CHECKITEM_SUCCESS = '/Checklist/TOGGLE_CHECKITEM_SUCCESS'
export const TOGGLE_CHECKITEM_ERROR = '/Checklist/TOGGLE_CHECKITEM_ERROR'

export const EDIT_CHECKITEM_INIT = '/Checklist/EDIT_CHECKITEM_INIT'
export const EDIT_CHECKITEM_SUCCESS = '/Checklist/EDIT_CHECKITEM_SUCCESS'
export const EDIT_CHECKITEM_ERROR = '/Checklist/EDIT_CHECKITEM_ERROR'

export const RESET_INITIAL_STATE = '/Checklist/RESET_INITIAL_STATE'

export type Checklist = {
  id: number,
  name: string,
}

export type Checkitem = {
  checked: boolean,
  id: number,
  text: string,
}
