// @flow

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import classnames from 'classnames'

import Modal from '../Modal'
import { renameWorkflow } from '../../core/api/api.requestConstructor'
import {
  REQUEST_CONSTRUCTOR_ERROR,
  REQUEST_CONSTRUCTOR_WAS_UPDATED,
} from '../../containers/RequestConstructor/RequestConstructor.actionTypes'

const RenameWorkflowPopup = props => {
  const { isOpen, workflowId, title } = props
  const [value, setValue] = useState(title)
  const [saving, setSaving] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => () => (document.body.style.overflowY = 'scroll'), [])

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflowY = 'hidden'
    } else {
      document.body.style.overflowY = 'scroll'
    }

    setValue(title)
  }, [isOpen])

  const { t } = useTranslation('RequestConstructor')

  const handleClose = () => {
    props.hideModal()
  }

  const handleSave = () => {
    setSaving(true)

    renameWorkflow(workflowId, { title: value })
      .then(data => {
        setSaving(false)
        handleClose()
        dispatch({ type: REQUEST_CONSTRUCTOR_WAS_UPDATED, data })
      })
      .catch(error => {
        dispatch({
          type: REQUEST_CONSTRUCTOR_ERROR,
          error: error.message.response.data,
        })
        setSaving(false)
      })
  }

  const handleChange = e => setValue(e.currentTarget.value)

  const wrapperClass = classnames('modal__content', {
    'working-overlay': saving,
  })

  return (
    <Modal
      contentLabel=''
      className='Modal__Bootstrap modal-dialog'
      isOpen={isOpen}
      onRequestClose={handleClose}
    >
      <div className={wrapperClass}>
        <div className='modal__title'>{t('RenameWorkflow')}</div>
        <button className='modal__close' type='button' onClick={handleClose} />
        <div className='m-a10'>
          <div className='m-a10__row' style={{ marginTop: '10px' }}>
            <div className={`m-a10__cell m-a10__cell_1`}>
              <span className='m-a10__label'>{t('NewWorkflowName')}</span>
            </div>
            <div
              className={`m-a10__cell m-a10__cell--2`}
              style={{ width: '30rem' }}
            >
              <div className='input input--medium input--default input--block'>
                <input
                  type='text'
                  placeholder={t('WorkflowNamePlaceholder')}
                  value={value}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className='modal__submit modal-counters-add-data__submit'>
            <button
              type='button'
              className='m-a06__06 button button--large button--success'
              onClick={handleSave}
            >
              {saving && <span className='button__spinner' />}
              {t('Save')}
            </button>
            <button
              type='button'
              className='m-a06__05 button button--large button--danger-3'
              onClick={handleClose}
            >
              {t('Cancel')}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default RenameWorkflowPopup
