// @flow

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'
import zxcvbn from 'zxcvbn'

import Icon from '../Icon'
import StrengthMeter from './StrengthMeter'
import {
  PASSWORD_MIN_LENGTH,
  PASSWORD_TYPE,
  TEXT_TYPE,
} from './InputPassword.constants'

import styles from './InputPassword.module.scss'

type Props = {
  alwaysSecure: boolean,
  autoComplete: string,
  className: string,
  disabled: boolean,
  isNew: boolean,
  name: string,
  onBlur: (SyntheticEvent<HTMLInputElement>) => void,
  onChange: (SyntheticEvent<HTMLInputElement>) => void,
  placeholder: string,
  t: string => string,
  value: string,
}

type State = {
  type: string,
}

class InputPassword extends Component<Props, State> {
  static defaultProps = {
    value: '',
  }

  state = {
    type: PASSWORD_TYPE,
  }

  onClick = () => {
    const { disabled } = this.props

    if (disabled) {
      return
    }

    this.setState(state => {
      const { type } = state

      const newType = type === PASSWORD_TYPE ? TEXT_TYPE : PASSWORD_TYPE

      return { type: newType }
    })
  }

  render() {
    const {
      alwaysSecure,
      autoComplete,
      disabled,
      placeholder,
      name,
      value,
      className,
      isNew,
    } = this.props

    const { type } = this.state

    const strength = [
      {
        message: this.props.t('VeryWeakPassword'),
        className: 'red',
      },
      {
        message: this.props.t('WeakPassword'),
        className: 'red',
      },
      {
        message: this.props.t('SomewhatGoodPassword'),
        className: 'orange',
      },
      {
        message: this.props.t('GoodPassword'),
        className: 'yellowGreen',
      },
      {
        message: this.props.t('StrongPassword'),
        className: 'green',
      },
    ]

    const score = zxcvbn(value).score

    const length = value.length
    const isShortPassword = length < PASSWORD_MIN_LENGTH
    const message = isShortPassword
      ? this.props.t('TooShortPassword')
      : strength[score].message
    const scoreForClassname = isShortPassword ? 0 : score
    const messageClassname = classnames(
      'input__msg input__msg--password',
      strength[scoreForClassname].className
    )

    return (
      <div>
        <div className={styles.container}>
          <input
            disabled={disabled}
            type={type}
            placeholder={placeholder}
            name={name}
            value={value}
            className={className}
            autoComplete={
              autoComplete || (isNew ? 'new-password' : 'current-password')
            }
            onBlur={this.props.onBlur}
            onChange={this.props.onChange}
          />
          {!alwaysSecure && (
            <Icon
              colorOnHover
              className={styles.eye}
              colored={type === PASSWORD_TYPE}
              id='eye'
              onClick={this.onClick}
            />
          )}
        </div>
        {isNew && (
          <StrengthMeter
            length={length}
            message={message}
            messageClassname={messageClassname}
          />
        )}
      </div>
    )
  }
}

export default withTranslation('InputPassword')(InputPassword)
