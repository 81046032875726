// @flow

import { useReducer, useEffect } from 'react'

import {
  LOAD_ERROR,
  LOAD_INIT,
  LOAD_SUCCESS,
  RELOAD_INIT,
} from './useSingleLoad.constants'
import dataLoadReducer from './useSingleLoad.reducer'

export const useSingleLoad = (api, params) => {
  const [state, dispatch] = useReducer(dataLoadReducer, {
    isLoading: false,
    isReloading: false,
    isError: false,
    isInitiated: false,
    data: null,
  })

  useEffect(() => {
    if (!state.isReloading && state.isInitiated) {
      return
    }

    let didCancel = false

    const fetchData = async () => {
      dispatch({ type: LOAD_INIT })

      try {
        const data = await api(params)

        if (!didCancel) {
          dispatch({ type: LOAD_SUCCESS, payload: data })
        }
      } catch (error) {
        if (!didCancel) {
          dispatch({ type: LOAD_ERROR })
        }
      }
    }

    fetchData()

    return () => {
      didCancel = true
    }
  }, [state.isReloading])

  const reload = () => dispatch({ type: RELOAD_INIT })

  return [state.data, state.isLoading, state.isError, reload]
}
