import { REQUEST_STATUS_SELECT } from './RequestStatus.actionTypes'

const initialState = {
  selected: null
}

export default (state = initialState, action) => {
  const { type, value } = action

  switch (type) {
    case REQUEST_STATUS_SELECT:
      return {
        ...state,
        selected: value
      }
    default:
      return state
  }
}
