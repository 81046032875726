// @flow

import React from 'react'
import type { Node } from 'react'

type Props = {
  children: Node,
  style?: Object,
}

const Content = (props: Props): Node => {
  const { children, style } = props

  return (
    <div className='content__col' style={style}>
      {children}
    </div>
  )
}

export default Content
