// @flow

import React from 'react'
import type { Node } from 'react'

import styles from './Icon.module.scss'

type Props = { children: Node, onClick?: Function }

const Icon = (props: Props): Node => {
  const { children } = props

  const handleClick = e => {
    e.stopPropagation()

    props.onClick(e)
  }

  const iconProps = Object.assign(
    {},
    props.onClick ? { onClick: handleClick } : undefined
  )

  return (
    <div className={styles.icon} {...iconProps}>
      {children}
    </div>
  )
}

export default Icon
