// @flow

import React from 'react'
import classnames from 'classnames'
import type { Node } from 'react'

import { FileIcon } from '../../Icon'

import styles from './DocumentPreview.module.scss'

type Props = {
  canHover?: boolean,
  data: Object,
}

const File = ({ data, canHover, className }: Props): Node => {
  const iconClass = classnames(styles.icon, styles.document, className, {
    [styles.interactive]: canHover,
  })

  return (
    <div className={iconClass}>
      <FileIcon file={data} />
    </div>
  )
}

export default File
