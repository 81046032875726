import React, { useEffect, useState } from 'react'
import { isFunction } from 'lodash-es'
import ModalCloseButton from '../Modal/ModalCloseButton'
import ModalHead from '../Modal/ModalHead'
import ModalBody from '../Modal/ModalBody'
import ModalButtons from '../Modal/ModalButtons'
import Modal from '../Modal'
import Button from '../Button'
import { Prompt } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const RouterPrompt = props => {
  const { t } = useTranslation('Mail')
  const {
    navigate,
    when,
    shouldBlockNavigation,
    openModal = false,
    isModal = false,
  } = props

  const [modalVisible, updateModalVisible] = useState(false)
  const [lastLocation, updateLastLocation] = useState()
  const [confirmedNavigation, updateConfirmedNavigation] = useState(false)

  const showModal = location => {
    updateModalVisible(true)
    !isModal && updateLastLocation(location)
  }

  const closeModal = (cb = undefined) => {
    updateModalVisible(false)
    isModal && props.setShowCanLeave(false)

    if (cb && isFunction(cb) && !isModal) {
      cb()
    }
  }

  const handleBlockedNavigation = nextLocation => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      showModal(nextLocation)

      return false
    }

    return true
  }
  const handleConfirmNavigationClick = () => {
    if (!isModal) {
      closeModal(() => {
        if (lastLocation) {
          updateConfirmedNavigation(true)
        }
      })
    } else {
      updateModalVisible(false)
      props.closeMailModal()
    }
  }

  useEffect(() => {
    if (confirmedNavigation) {
      navigate(lastLocation.pathname)
      updateConfirmedNavigation(false)
    }
  }, [confirmedNavigation])

  useEffect(() => {
    updateModalVisible(openModal)
  }, [openModal])

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      {modalVisible && (
        <Modal isOpen>
          <ModalCloseButton onClose={closeModal} />
          <ModalHead title={t('Attention')} />
          <ModalBody>
            <p className='modal__text'>{t('YouAreLeavingPage')}</p>
            <p className='modal__text'>{t('AreYouReadyToLeaveThePage')}</p>
          </ModalBody>
          <ModalButtons>
            <Button.Save onClick={closeModal}>{t('no')}</Button.Save>
            <Button.Cancel onClick={handleConfirmNavigationClick}>
              {' '}
              {t('yes')}
            </Button.Cancel>
          </ModalButtons>
        </Modal>
      )}
    </>
  )
}
export default RouterPrompt
