// @flow

export type ActionType =
  | 'LOGIN_INIT'
  | 'LOGIN_SUCCESS'
  | 'LOGIN_ERROR'
  | 'CLEAR_LOGIN'
  | 'LOGOUT'

export const LOGIN_INIT = 'LOGIN_INIT'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const CLEAR_LOGIN = 'CLEAR_LOGIN'
export const LOGOUT = 'LOGOUT'
