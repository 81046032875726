// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'

const Header = (): Node => {
  const { t } = useTranslation('FAQ')

  return <h2 className='unit__title'>{t('FAQ')}</h2>
}

export default Header
