import { all, put, call, fork, takeLatest, select } from 'redux-saga/effects'
import { omit } from 'lodash-es'

import api from '../../../core/api'
import { serverError } from '../../../components/Layout/Layout.actions'
import * as actions from './Igel.actionTypes'

const STATUS_EXPIRED = 'expired-credits'

function * watchInitiate () {
  yield takeLatest(actions.IGEL_INITIATE, initiate)
}

function * watchCheckStatus () {
  yield takeLatest(actions.IGEL_CHECK_STATUS, checkStatus)
}

function * watchCreate () {
  yield takeLatest(actions.IGEL_CREATE, create)
}

function * watchApplyConfig () {
  yield takeLatest(actions.IGEL_APPLY_CONFIG, applyConfig)
}

function * watchSync () {
  yield takeLatest(actions.IGEL_SYNCHRONIZE, sync)
}

function * watchMatchRequests () {
  yield takeLatest(actions.IGEL_MATCH_REQUESTS, matchRequests)
}

function * watchCopyRequestTypes () {
  yield takeLatest(actions.IGEL_COPY_REQUEST_TYPES, copyRequestTypes)
}

function * handleError (error, type = actions.IGEL_ERROR) {
  const { response } = error.message
  yield put({ type, response })
  yield put(serverError(error))
}

function * getConfig () {
  try {
    return yield call(api.integrations.getIgelConfig)
  } catch (error) {
    if (error.message.response.status === 404) {
      return null
    }
    throw error
  }
}

function * getStatus () {
  try {
    return yield call(api.integrations.getIgelStatus)
  } catch (error) {
    if (error.message.response.status === 403) {
      return STATUS_EXPIRED
    } else if (error.message.response.status === 404) {
      return {}
    }
    throw error
  }
}

function * getRequests () {
  const requests = yield call(api.integrations.getIgelMatchingRequests)
  const { is_types_copied: wereCopied } = yield call(api.integrations.checkIgelCopiedTypes)
  return { requests, requestsWereCopied: wereCopied }
}

function * initiate () {
  try {
    const config = yield getConfig()
    const status = yield getStatus()

    const expired = status === STATUS_EXPIRED
    const commonData = {
      config: config || {},
      status: expired ? {} : status,
      exist: !!config && !expired
    }

    const requestsData = commonData.exist
      ? yield getRequests()
      : {
        requests: { unmapped: [] },
        requestsWereCopied: false
      }

    yield put({
      type: actions.IGEL_WAS_INITIATED,
      data: {
        ...commonData,
        ...requestsData
      },
      expiredCredits: expired
    })
  } catch (error) {
    yield put(serverError(error))
  }
}

function * checkStatus () {
  try {
    const status = yield call(api.integrations.getIgelStatus)
    yield put({ type: actions.IGEL_STATUS_WAS_CHECKED, status })
  } catch (error) {
    yield * handleError(error)
  }
}

function * create ({ config }) {
  try {
    yield call(api.integrations.createIgel, config)
    const requestsData = yield getRequests()
    yield put({
      type: actions.IGEL_WAS_CREATED,
      data: { config, ...requestsData }
    })
  } catch (error) {
    yield * handleError(error)
  }
}

function * applyConfig ({ config }) {
  try {
    const initialConfig = yield select(({ igel }) => igel.config)
    const formattedConfig = omit(config, 'password')

    if (config.password !== initialConfig.password) {
      formattedConfig.password = config.password
    }

    yield call(api.integrations.applyIgelConfig, formattedConfig)
    yield put({
      type: actions.IGEL_CONFIG_WAS_APPLIED,
      config: formattedConfig
    })
  } catch (error) {
    yield * handleError(error)
  }
}

function * sync () {
  try {
    yield call(api.integrations.syncIgel)
    yield put({
      type: actions.IGEL_STATUS_WAS_CHECKED,
      status: { is_sync_in_progress: true }
    })
  } catch (error) {
    yield * handleError(error)
  }
}

function * matchRequests (action) {
  try {
    const items = yield call(api.integrations.igelMatchRequests, action.items)
    yield put({ type: actions.IGEL_REQUESTS_WERE_MATCHED, items })
  } catch (error) {
    yield * handleError(error, actions.IGEL_POPUP_ERROR)
  }
}

function * copyRequestTypes (action) {
  try {
    yield call(api.integrations.igelMatchRequests, action.items)
    const items = yield call(api.integrations.copyRequestsFromIgel)
    yield put({ type: actions.IGEL_REQUEST_TYPES_WERE_COPIED, items })
  } catch (error) {
    yield * handleError(error, actions.IGEL_POPUP_ERROR)
  }
}

export default function * watch () {
  yield all([
    fork(watchInitiate),
    fork(watchCheckStatus),
    fork(watchCreate),
    fork(watchApplyConfig),
    fork(watchSync),
    fork(watchMatchRequests),
    fork(watchCopyRequestTypes)
  ])
}
