// @flow

export const BUILDING_LIST_INITIATING = 'BUILDING_LIST_INITIATING'
export const BUILDING_LIST_INITIATED = 'BUILDING_LIST_INITIATED'
export const BUILDING_LIST_LOAD = 'BUILDING_LIST_LOAD'
export const BUILDING_LIST_WAS_LOADED = 'BUILDING_LIST_WAS_LOADED'
export const BUILDING_LIST_REMOVE = 'BUILDING_LIST_REMOVE'
export const BUILDING_LIST_REMOVE_FROM_GROUP = 'BUILDING_LIST_REMOVE_FROM_GROUP'
export const BUILDING_LIST_RESTORE = 'BUILDING_LIST_RESTORE'
export const BUILDING_LIST_ERROR = 'BUILDING_LIST_ERROR'
export const BUILDING_LIST_RESET = 'BUILDING_LIST_RESET'
export const UPDATE_IMAGES = 'UPDATE_IMAGES'
export const UPDATE_GROUP_BUILDINGS = 'UPDATE_GROUP_BUILDINGS'
export const IMAGES_WAS_UPDATED = 'IMAGES_WAS_UPDATED'
