// @flow

import React, { Component } from 'react'
import { useTranslation } from 'react-i18next'
import { Sticky } from 'react-sticky'
import { map } from 'lodash-es'
import classnames from 'classnames'

import CardIcon from './CardIcon'
import Icon from '../Icon'
import { ButtonTypes } from './constants'

type Props = {
  block: ?number,
  isSticky: boolean,
  onClick: Object => void,
  style: Object,
  t: string => string,
}
type State = {
  animated: boolean,
  open: boolean,
}

class ControlPanel extends Component<Props, State> {
  state = {
    open: true,
    animated: false,
  }

  componentDidUpdate(prev: Props) {
    const { isSticky } = this.props

    if (!isSticky && isSticky !== prev.isSticky) {
      this.setState({
        open: true,
        animated: false,
      })
    }
  }

  handleToggle = () => {
    this.setState(state => {
      const { open } = state

      return {
        open: !open,
        animated: !open,
      }
    })
  }

  render() {
    const { block, isSticky, style } = this.props
    const { open, animated } = this.state

    const className = classnames('request-constructor__control-panel', {
      'request-constructor__control-panel-sticky': isSticky,
      'request-constructor__control-panel-sticky-show': isSticky && open,
    })
    const classNameButtons = classnames(
      'request-constructor__control-panel--buttons',
      {
        'request-constructor__control-panel--buttons-hide': !open,
        'request-constructor__control-panel--buttons-show': animated,
      }
    )

    return (
      <div className={className} style={style}>
        {isSticky ? (
          <Icon id='add' onClick={this.handleToggle} />
        ) : (
          <div className='request-constructor__control-panel--title'>
            {this.props.t('addCard')}:
          </div>
        )}
        <div className={classNameButtons} key={1}>
          {map(ButtonTypes, type => (
            <CardIcon
              key={type}
              type={type}
              onClick={() => this.props.onClick({ block, type })}
            />
          ))}
        </div>
      </div>
    )
  }
}

const ControlPanelHOC = props => {
  const { t } = useTranslation('RequestConstructor')

  return (
    <div className='request-constructor__control-panel--box'>
      <Sticky>
        {({ isSticky, style }) => (
          <ControlPanel {...props} isSticky={isSticky} style={style} t={t} />
        )}
      </Sticky>
    </div>
  )
}

export default ControlPanelHOC
