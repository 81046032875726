// @flow

import React, { Component } from 'react'
import classnames from 'classnames'
import { get } from 'lodash-es'

import Button from '../../Button'
import File from './File'
import Name from './Name'
import Image, { IMAGE_FIT_TYPES } from '../../ImageSquareFit'
import Video from './Video'
import { isImageFile, isVideoFile } from '../../../utils/file'
import { getFileById } from '../../../core/api/api.file'

import styles from './DocumentPreview.module.scss'

const THUMBNAIL_SIZE = 512

type Props = {
  canHover?: boolean,
  canRemove?: boolean,
  file: Object,
  onClick?: () => void,
  onRemove?: Object => void,
  reload?: boolean,
  scope?: string,
  thumbnailSize: number,
}

type State = {
  file: Object,
}

class DocumentPreview extends Component<Props, State> {
  state = {
    file: '',
  }

  static defaultProps = {
    thumbnailSize: THUMBNAIL_SIZE,
  }

  componentDidMount() {
    const {
      reload,
      scope,
      file: { id },
      postUuid,
    } = this.props

    if (reload) {
      const obj = Object.assign(
        {},
        scope === 'post' && postUuid ? { object_id: postUuid } : undefined
      )

      getFileById({ file_ids: id, scope, ...obj }).then(data =>
        this.setState({ file: data[0] })
      )
    }
  }

  remove = () => {
    if (this.props.onRemove) {
      const { file } = this.props
      this.props.onRemove(file)
    }
  }

  renderUploading = () => {
    const { file } = this.props

    return (
      <div className={styles.uploading}>
        <div />
        <Name>{file.name}</Name>
      </div>
    )
  }

  renderPreview = () => {
    const { canHover, file, thumbnailSize, reload } = this.props

    const srcFile = reload ? this.state.file : file

    const { thumbnails, origin, filename } = srcFile

    const thumbnail = get(thumbnails, thumbnailSize, origin)

    if (isImageFile(srcFile)) {
      const { width: w, height: h } = file
      const containAll =
        !thumbnail && (!w || !h || w < thumbnailSize || h < thumbnailSize)

      return (
        <Image
          alt={filename}
          fit={
            containAll
              ? IMAGE_FIT_TYPES.containAll
              : IMAGE_FIT_TYPES.containVertical
          }
          src={reload ? this.state.file.thumbnail : thumbnail || origin}
        />
      )
    }

    if (isVideoFile(srcFile)) {
      return <Video data={srcFile} />
    }

    return <File canHover={canHover} data={srcFile} />
  }

  render() {
    const { file, canHover, canRemove } = this.props

    if (!file.id) {
      return this.renderUploading()
    }

    const { filename, name } = file

    const removable = canRemove && this.props.onRemove
    const boxClass = classnames(styles.preview, {
      [styles.interactive]: canHover,
    })

    return (
      <div className={boxClass} onClick={this.props.onClick}>
        {removable && (
          <Button.Close className={styles.close} onClick={this.remove} />
        )}
        {this.renderPreview()}
        <Name>{filename || name}</Name>
      </div>
    )
  }
}

export default DocumentPreview
