// @flow

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'
import { take } from 'lodash-es'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import MessagesFileAttachment from '../Messages/MessagesFileAttachment/MessagesFileAttachment'
import { getLastMessageText, getMessageUserName } from './Message.utils'
import { getFormattedDate } from '../../utils/utils'
import Checkbox from '../Checkbox'

const MAX_FILES_COUNT = 2

type Props = {
  canCheck: boolean,
  chat: Object,
  push: string => void,
  selected: Array<string>,
  t: string => void,
  viewFile: (number, string) => void,
}

class Message extends Component<Props> {
  viewFile = (id: number) => (e: Object) => {
    const { chat } = this.props

    e.stopPropagation()
    this.props.viewFile(id, chat.uuid)
  }

  openChat = () => {
    const { chat } = this.props

    this.props.push(`/chatroom/${chat.uuid}`)
  }

  preventOpen = e => {
    e.stopPropagation()
  }

  toggle = e => {
    this.props.toggle(e.target.value)
  }

  render() {
    const {
      chat: {
        avatar,
        title,
        users,
        uuid,
        last_message: last,
        parent,
        unviewed_msg_count: notReadCounter,
        users_count: usersCount,
      },
      selected,
      canCheck,
    } = this.props

    const checked = selected.includes(uuid)
    const lastMessageText = getLastMessageText(last)
    const inboxItemClassName = classnames('inbox__item', {
      'inbox__item--unread': notReadCounter > 0,
    })
    const inboxText =
      last === null ? this.props.t('NoMessages') : lastMessageText
    const name = getMessageUserName(this.props.t, users, usersCount)

    return (
      <div className={inboxItemClassName} key={uuid} onClick={this.openChat}>
        {canCheck && (
          <div
            className='inbox__cell inbox__cell--01'
            onClick={this.preventOpen}
          >
            <Checkbox value={uuid} checked={checked} onChange={this.toggle} />
          </div>
        )}
        <div
          className='inbox__cell inbox__cell--02'
          style={{ marginRight: '10px' }}
        >
          <img className='inbox__userpic' src={avatar} alt='user' />
        </div>
        <div className='inbox__cell inbox__cell--03'>
          <span className='inbox__username' title={name}>
            {name}
          </span>
        </div>
        <div
          className='inbox__cell inbox__cell--04'
          style={{ minWidth: 0, maxWidth: '100%' }}
        >
          {!!title && (
            <a className='inbox__subject' title={title}>
              {title}
            </a>
          )}
          <a
            className='inbox__text'
            title={inboxText}
            style={{ maxWidth: '100%' }}
          >
            {inboxText}
          </a>
          {notReadCounter > 0 && (
            <a className='inbox__counter'>&nbsp;({notReadCounter})</a>
          )}
        </div>
        <div className='inbox__cell inbox__cell--05'>
          {last !== null &&
            take(last.files, MAX_FILES_COUNT).map(file => (
              <MessagesFileAttachment
                key={file.id}
                file={file}
                onClick={this.viewFile(file.id)}
              />
            ))}
          {!!parent && (
            <span className='chat-converted-label'>
              {this.props.t('ConvertedLabel')}
            </span>
          )}
        </div>
        <div className='inbox__cell inbox__cell--06'>
          <span className='inbox__date'>
            {last !== null && getFormattedDate(last.created)}
          </span>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = { push }

export default compose(
  withTranslation('Messages'),
  connect(null, mapDispatchToProps)
)(Message)
