// @flow

import Documents from './Documents'
import DocumentFilePreview from './DocumentPreview/File'
import DocumentPreview from './DocumentPreview'
import DocumentVideoPreview from './DocumentPreview/Video'
import DocumentsEditing from './DocumentsEditing'
import DocumentsView from './DocumentsView'

export { DocumentsView }
export { DocumentsEditing }
export { DocumentPreview }
export { DocumentFilePreview }
export { DocumentVideoPreview }

export default Documents
