// @flow

import React from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { parse } from 'qs'
import type { Node } from 'react'

import Content from '../Content'
import CreateBudgetPlan from './CreateBudgetPlan'
import UpdateBudgetPlan from './UpdateBudgetPlan'

const BudgetPlan = (): Node => {
  const { id } = useParams()
  const location = useLocation()

  const { requestId } = parse(location.search, { ignoreQueryPrefix: true })

  if (!id && !requestId) {
    return null
  }

  return (
    <Content>
      {id ? (
        <UpdateBudgetPlan id={id} />
      ) : (
        <CreateBudgetPlan requestId={requestId} />
      )}
    </Content>
  )
}

export default BudgetPlan
