// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash-es'

import Row from '../../../Block/Row'
import SelectUser from '../../../Select/SelectUser'

type Props = {
  building: ?{ id: number },
  flat: ?{ id: number },
  onUpdate: Object,
  valueId?: ?number,
}

const RequestInitiatorSelect = (props: Props) => {
  const { valueId, building, flat } = props

  const buildingId = get(building, 'id')
  const flatId = get(flat, 'id')

  const { t } = useTranslation('Request')

  const handleChangeInitiator = (initiator = null) => {
    props.onUpdate(initiator)
  }

  const selectParams = {
    building: buildingId,
    flat: flatId,
  }

  return (
    <Row title={t('InitiatorTitle')}>
      <SelectUser
        showStatus
        isDweller
        cleanable
        round
        withDisableOnLoad
        monitor={`${buildingId} ${flatId}`}
        btnPlaceholder='select_dweller'
        id='request-initiator'
        initialValueId={valueId}
        params={selectParams}
        onChange={handleChangeInitiator}
      />
    </Row>
  )
}

export default RequestInitiatorSelect
