// @flow

import React from 'react'
import { useSelector } from 'react-redux'
import { get } from 'lodash-es'
import { Link } from 'react-router-dom'
import type { Node } from 'react'

import Button from '../../Button'
import { getUk } from '../../../utils/commonSelectors'

import styles from './Header.module.scss'

type Props = {
  t: string => string,
}

const Header = (props: Props): Node => {
  const uk = useSelector(getUk)

  const ukName = get(uk, 'name', '')

  return (
    <header className={styles.header}>
      <div>
        <h1>{ukName}</h1>
        <Link to='/login'>
          <Button.Regular view='outlined'>{props.t('Login')}</Button.Regular>
        </Link>
      </div>
    </header>
  )
}

export default Header
