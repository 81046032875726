// @flow

import React from 'react'
import type { Node } from 'react'

import styles from './Navs.module.scss'

type Props = {
  children: Node,
}

const Navs = (props: Props): Node => {
  const { children } = props

  return <nav className={styles.navs}>{children}</nav>
}

export default Navs
