// @flow

import { findIndex } from 'lodash-es'

import * as convertActions from '../../../components/modals/ConvertToRequestPopup/ConverToRequestPopup.actionTypes'
import * as attachActions from '../../../components/modals/AttachToRequestPopup/AttachToRequestPopup.actionTypes'

import * as actions from './MailThread.actionTypes'

const initialState = {
  title: '',
  thread: null,
  messages: [],
  working: true,
  initiated: false,
  modal: null,
  redirect: false,
  unicAddressee: new Set([]),
}

export default function mailthreadReducer(state = initialState, action) {
  switch (action.type) {
    case actions.MAIL_INIT:
      return initialState
    case actions.MAIL_INITED:
      return {
        ...state,
        working: false,
        initiated: true,
        thread: action.thread,
        messages: action.messages,
        messageCounter: action.meta.count,
        unicAddressee: action.unicAddressee,
      }
    case actions.MAIL_MODAL:
      return {
        ...state,
        modal: action.modal,
      }
    case actions.REMOVED_MESSAGE:
    case actions.RESTORE_MESSAGE: {
      const messageIndex = findIndex(state.messages, action.message)

      return {
        ...state,
        messages: [...state.messages.slice(0, messageIndex)],
        modal: null,
      }
    }
    case actions.MAIL_CREATE:
      return {
        ...state,
        working: true,
      }
    case actions.MAIL_CREATED:
      return {
        ...state,
        working: false,
      }
    case actions.MAIL_UPDATE:
      return {
        ...state,
        working: true,
      }
    case attachActions.ATTACHED:
    case convertActions.REQUEST_CREATED:
      return {
        ...state,
        modal: null,
      }
    case actions.MAIL_RESET:
      return initialState
    case actions.MAIL_ERROR:
      return {
        ...state,
        working: false,
      }
    case actions.MAIL_SEARCH_SELECT_FILES:
      return {
        ...state,
        selectedFiles: action.selectedFiles,
      }
    default:
      return state
  }
}
