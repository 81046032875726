// @flow

import React from 'react'

type arg = {children: React.Node, value: string}

const InfoCard = ({ children, value }: arg) => (
  <div className='public-building-page__info-card'>
    <div className='public-building-page__info-card-icon'>{children}</div>
    <div className='public-building-page__info-card-value'>{value}</div>
  </div>
)

export default InfoCard
