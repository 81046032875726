// @flow

import { clone } from 'lodash-es'

import * as actions from './Friends.actionTypes'

const updateItem = (items, owner, working = true) => {
  const updated = clone(items)
  const index = updated.findIndex(i => i.owner === owner)
  updated[index].working = working

  return updated
}

const initalState = {
  initiated: false,
  working: false,
  items: [],
  meta: {},
}

export default (state = initalState, action) => {
  switch (action.type) {
    case actions.FRIENDS_LIST_INITIATING:
      return initalState
    case actions.FRIENDS_LIST_INITIATED:
      return {
        ...state,
        initiated: true,
        items: action.results.objects,
        meta: action.meta,
      }
    case actions.FRIENDS_LIST_LOAD:
      return {
        ...state,
        working: true,
      }
    case actions.FRIENDS_LIST_LOADED:
      return {
        ...state,
        working: false,
        items: action.results.objects,
        meta: action.meta,
      }
    case actions.FRIENDS_LIST_SUBSCRIBE:
    case actions.FRIENDS_LIST_UNSUBSCRIBE:
      return {
        ...state,
        items: updateItem(state.items, action.owner),
      }
    case actions.FRIENDS_LIST_SUBSCRIBED:
      return {
        ...state,
        items: updateItem(state.items, action.owner, false),
        meta: {
          ...state.meta,
          count: state.meta.count + 1,
        },
      }
    case actions.FRIENDS_LIST_UNSUBSCRIBED: {
      return {
        ...state,
        items: state.items.filter(i => i.owner !== action.owner),
        meta: {
          ...state.meta,
          count: state.meta.count - 1,
        },
      }
    }
    default:
      return state
  }
}
