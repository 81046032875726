// @flow

import React, { useEffect, useState } from 'react'
import type { Node } from 'react'
import { useTranslation } from 'react-i18next'

import { omit } from 'lodash-es'

import classnames from 'classnames'

import NewSelectAsync from '../../NewSelectAsync'
import NewSelectSimple from '../../NewSelectSimple'
import ModalRow from '../../Modal/ModalRow'
import ActivityModalLabel from '../../Activities/ActivityModal/ActivityModalLabel'
import ActivityModalRow from '../../Activities/ActivityModal/ActivityModalRow'
import ActivityModalType from '../../Activities/ActivityModal/ActivityModalType'
import ActivityModalPriority from '../../Activities/ActivityModal/ActivityModalPriority'
import InputField from '../../InputField'
import Button from '../../Button'
import Textarea from 'react-textarea-autosize'

import {
  getPipelineTemplates,
  getPhaseTemplates,
  getActivityTypes,
  getActivityPriorities,
} from '../../../core/api/api.pipeline'

import { getAdminAndManagerOptionList } from '../../../core/api/api.profile'

import {
  DONE_COMMIT_TYPE,
  YESNO_COMMIT_TYPE,
} from '../../Activities/ActivityModal/ActivityModal.constants'
import {
  CONTAINED_VIEW,
  OUTLINED_VIEW,
} from '../../Button/Regular/Regular.constants'
import { OMIT_FIELDS } from '../AutomateTable/Automate.constants'

import { getFetchedItems, getUserName } from '../../../utils/utils'

import styles from '../AutomateInfo.module.scss'
import activityStyles from '../../Activities/ActivityModal/ActivityModal.module.scss'

import {
  isTemplateText,
  getPriorityValue,
  getDeadlineTemplate,
  getDeadlineObject,
} from '../../Activities/ActivityModal/ActivityModal.utils'

type Props = {
  data: Object,
  onUpdate: Object => void,
}

const CreateActivity = (props: Props): Node => {
  const { data } = props
  const { t } = useTranslation('Activities')
  const systemManager = { id: 0, fullname: t('FirstAssigned') }

  const [pipeline, setPipeline] = useState(null)
  const [phase, setPhase] = useState(null)
  const [activityTypes, setActivityTypes] = useState(null)
  const [activityType, setActivityType] = useState(null)
  const [activityPriorities, setActivityPriorities] = useState([])
  const [priority, setPriority] = useState(null)
  const [manager, setManager] = useState(
    data.assignee_first_request_assigned ? systemManager : data.responsible_user
  )
  const [deadlineTemplate, setDeadlineTemplate] = useState(
    getDeadlineTemplate(
      data.deadline_days,
      data.deadline_hours,
      data.deadline_minutes
    )
  )

  const options = activityPriorities.map(getPriorityValue)
  const visibilityOptions = [
    { value: false, label: t('Hide') },
    { value: true, label: t('Share') },
  ]

  useEffect(() => {
    setManager(data.responsible_user || systemManager)
    setPipeline(data.pipeline_template)
    setPhase(data.phase_template)
    setPriority(getPriorityValue(data.priority))
    getActivityTypes().then(res => {
      const types = getFetchedItems(res)
      setActivityTypes(
        types.filter(t => t.enabled || t.id === data.activity_type_id)
      )

      if (data.activity_type_id) {
        setActivityType(types.find(t => t.id === data.activity_type_id))
      } else {
        setActivityType(types[0])
      }

      props.onUpdate({
        ...omit(data, OMIT_FIELDS),
        title: data.title || types[0].name,
        activity_type_id: data.activity_type_id || types[0].id,
        responsible_user_id: data.responsible_user?.id || null,
        assignee_first_request_assigned: !data.responsible_user,
        visible_to_tenant: data.visible_to_tenant || false,
      })
    })
    getActivityPriorities().then(res => {
      const priorities = getFetchedItems(res)
      setActivityPriorities(priorities)
    })
  }, [])

  const handleChangeType = value => {
    let title = data.title || ''

    if (
      isTemplateText(title, activityType.icon.origin, activityTypes) ||
      title === ''
    ) {
      title = value.name
    }

    props.onUpdate({ ...data, activity_type_id: value.id, title })
    setActivityType(value)
  }

  const handleChangeDeadline = e => {
    const deadline = e.target.value
    setDeadlineTemplate(deadline)
    props.onUpdate({ ...data, ...getDeadlineObject(deadline) })
  }

  const handleChangePriority = option => {
    setPriority(option)
    props.onUpdate({ ...data, priority_id: option.value })
  }

  const handleClearPriority = () => {
    setPriority(null)
    props.onUpdate({ ...data, priority_id: null })
  }

  const handleChangeManager = option => {
    setManager(option)
    props.onUpdate({
      ...data,
      responsible_user_id: option.id || null,
      assignee_first_request_assigned: !option.id,
    })
  }

  const cardClass = classnames(
    'request-constructor__card--body request-constructor__card--email'
  )

  const phaseApi = () => getPhaseTemplates(pipeline.id)

  const getSystemValue = params =>
    Promise.resolve(params.fullname?.length >= 3 ? [] : [systemManager])

  const managerApi = params =>
    Promise.all([
      getSystemValue(params),
      getAdminAndManagerOptionList(params),
    ]).then(data => {
      const res = {
        results: {
          objects: [...data[0], ...(data[1].results?.objects || [])],
        },
        meta: { ...data[1].meta, count: data[1].meta.count + data[0].length },
      }

      return res
    })

  return (
    <div className={cardClass}>
      <div>{t('RequestConstructor:selectPipeline')}</div>
      <NewSelectAsync
        className={styles.selectPhase}
        pageSize={10}
        api={getPipelineTemplates}
        searchKey='name'
        placeholder={t('RequestConstructor:PipelinePlaceholder')}
        getLabel={a => a.name}
        getSelectedLabel={a => a.name}
        view='dropdown'
        selectedItems={pipeline ? [pipeline] : []}
        onClick={option => {
          props.onUpdate({
            ...data,
            pipeline_template_id: option.id,
            phase_template_id: null,
          })
          setPipeline(option)
          setPhase(null)
        }}
      />
      <div>{t('RequestConstructor:selectPhase')}</div>
      <NewSelectAsync
        pageSize={10}
        disabled={!pipeline}
        className={styles.selectPhase}
        api={phaseApi}
        dynamicParams={{ pipeline_id: pipeline?.id }}
        searchKey='name'
        placeholder={t('RequestConstructor:PhasePlaceholder')}
        getLabel={a => a.name}
        getSelectedLabel={a => a.name}
        view='dropdown'
        selectedItems={phase ? [phase] : []}
        onClick={option => {
          props.onUpdate({
            ...data,
            pipeline_template_id: pipeline.id,
            phase_template_id: option.id,
          })
          setPhase(option)
        }}
      />
      <ModalRow>
        <ActivityModalLabel title={t('DescriptionLabel')} />
      </ModalRow>
      <ActivityModalRow>
        <InputField
          className={activityStyles.input}
          name={'title'}
          maxLength={256}
          value={data.title}
          placeholder={t('DescriptionPlaceholder')}
          onChange={e => props.onUpdate({ ...data, title: e.target.value })}
        />
      </ActivityModalRow>
      {activityTypes && activityType && (
        <ActivityModalRow>
          <ActivityModalType
            activityTypes={activityTypes}
            value={activityType}
            handleChangeType={handleChangeType}
          />
        </ActivityModalRow>
      )}
      <ModalRow>
        <ActivityModalLabel title={t('DeadlineDurationLabel')} />
      </ModalRow>
      <ActivityModalRow>
        <InputField
          className={activityStyles.input}
          name={'deadline_template'}
          value={deadlineTemplate}
          placeholder='4d 6h 45m'
          onChange={handleChangeDeadline}
        />
      </ActivityModalRow>
      <ActivityModalRow>
        <div style={{ display: 'flex' }}>
          <Button.Regular
            className={activityStyles['commit-button']}
            view={
              data.commit_type === DONE_COMMIT_TYPE
                ? CONTAINED_VIEW
                : OUTLINED_VIEW
            }
            onClick={() =>
              props.onUpdate({ ...data, commit_type: DONE_COMMIT_TYPE })
            }
          >
            {t('ChecklistDoneNotDone')}
          </Button.Regular>
          <Button.Regular
            className={activityStyles['commit-button']}
            view={
              data.commit_type === YESNO_COMMIT_TYPE
                ? CONTAINED_VIEW
                : OUTLINED_VIEW
            }
            onClick={() =>
              props.onUpdate({ ...data, commit_type: YESNO_COMMIT_TYPE })
            }
          >
            {t('QuestionYesNo')}
          </Button.Regular>
        </div>
      </ActivityModalRow>
      {!!options.length && (
        <>
          <ModalRow>
            <ActivityModalLabel title={t('ActivityPriorityLabel')} />
          </ModalRow>
          <ActivityModalRow>
            <ActivityModalPriority
              placeholder={t('PriorityPlaceholder')}
              className={activityStyles.select}
              options={options}
              value={priority}
              disabled={!priority}
              onToggle={() => {}}
              onChange={handleChangePriority}
              onClear={handleClearPriority}
            />
          </ActivityModalRow>
        </>
      )}
      <ModalRow>
        <ActivityModalLabel title={t('NotesLabel')} />
      </ModalRow>
      <ActivityModalRow>
        <Textarea
          className={activityStyles.textarea}
          value={data.notes}
          name={'notes'}
          onChange={e => props.onUpdate({ ...data, notes: e.target.value })}
        />
      </ActivityModalRow>
      <ModalRow>
        <ActivityModalLabel title={t('ManagerLabel')} />
      </ModalRow>
      <ActivityModalRow>
        <NewSelectAsync
          pageSize={10}
          className={activityStyles.select}
          api={managerApi}
          searchKey='fullname'
          placeholder={t('ManagerPlaceholder')}
          getLabel={getUserName}
          getSelectedLabel={getUserName}
          view='dropdown'
          selectedItems={manager ? [manager] : []}
          onClick={handleChangeManager}
        />
      </ActivityModalRow>
      <ModalRow>
        <ActivityModalLabel title={t('VisibilityForDweller')} />
      </ModalRow>
      <ActivityModalRow>
        <NewSelectSimple
          className={activityStyles.select}
          options={visibilityOptions}
          value={visibilityOptions.find(
            o => o.value === data.visible_to_tenant
          )}
          onChange={option =>
            props.onUpdate({ ...data, visible_to_tenant: option.value })
          }
        />
      </ActivityModalRow>
    </div>
  )
}

export default CreateActivity
