// @flow

import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import { SimpleBlock } from '../../components/Block'
import Header from './Header'
import Modal from './Modal'
import ToolBox from './ToolBox/ToolBox'
import Empty from './Empty/Empty'
import GroupsList from './GroupsList'
import Loader from '../../components/Loader'
import { loadGroups, clearState } from './Faq.actions'
import { isLoading } from './Faq.selectors'

type Props = {
  isLoading: boolean,
  loadGroups: () => void,
}

class Faq extends PureComponent<Props> {
  componentDidMount() {
    this.props.loadGroups()
  }

  componentWillUnmount() {
    this.props.clearState()
  }

  render() {
    const { isLoading } = this.props

    if (isLoading) {
      return (
        <div className='content__col'>
          <Loader type='big' text={false} />
        </div>
      )
    }

    return (
      <div className='content__col'>
        <SimpleBlock>
          <Header header />
          <ToolBox />
          <Empty />
        </SimpleBlock>
        <GroupsList />
        <Modal />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  isLoading: isLoading(state),
})
const mapDispatchToProps = {
  loadGroups,
  clearState,
}

export default connect(mapStateToProps, mapDispatchToProps)(Faq)
