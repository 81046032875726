// @flow

import { get } from 'lodash-es'

import {
  SERVER_500_ERROR,
  SERVER_403_ERROR,
  SERVER_404_ERROR,
  SERVER_ERROR,
  GLOBAL_MODAL_ERROR,
  GLOBAL_MODAL_MESSAGE,
  UPDATE_COUNTERS,
  SERVER_ANY_ERROR,
  SERVER_402_ERROR,
  SERVER_401_ERROR,
  SERVER_502_ERROR,
} from './Layout.constants'

export function serverError(error, show = false) {
  const status = get(error, ['message', 'response', 'status'], null)

  if (show) {
    return {
      type: SERVER_ANY_ERROR,
      error,
    }
  }

  if (!status) {
    return null
  }

  switch (status) {
    case 500:
      return {
        type: SERVER_500_ERROR,
        error,
      }
    case 502:
      return {
        type: SERVER_502_ERROR,
        error,
      }
    case 401:
      return {
        type: SERVER_401_ERROR,
        error,
      }
    case 402:
      return {
        type: SERVER_402_ERROR,
        error,
      }
    case 403:
      return {
        type: SERVER_403_ERROR,
        error,
      }
    case 404:
      return {
        type: SERVER_404_ERROR,
        error,
      }
    default:
      return {
        type: SERVER_ERROR,
      }
  }
}

export function globalModalError(errorText, errorTitle = '', needTranslate) {
  return {
    type: GLOBAL_MODAL_ERROR,
    errorText,
    errorTitle,
    needTranslate,
  }
}

export function modalError(error) {
  return {
    type: GLOBAL_MODAL_ERROR,
    error,
  }
}

export function showPopupMessage(text, title, textParams) {
  return { type: GLOBAL_MODAL_MESSAGE, text, title, textParams }
}

export function updateCountersAction() {
  return { type: UPDATE_COUNTERS }
}
