// @flow

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { Node } from 'react'
import { push } from 'connected-react-router'
import { useTranslation } from 'react-i18next'

import Regular from './Regular'
import type { Props } from './Regular/Regular'

import { MAIL_SEARCH_SELECT_FILES } from '../../containers/Mail/MailThread/MailThread.actionTypes'
import { MAIL_SEARCH_SELECT_USERS } from '../MailSearch/MailSearch.actionTypes'
import { getAllDwellerList } from '../../core/api/api.profile'
import { getUser } from '../../utils/commonSelectors'
type PropsExt = Props & {
  selectedFiles: Array<Object>,
}

const SendFile = (props: PropsExt): Node => {
  const { t } = useTranslation('Common')
  const dispatch = useDispatch()
  const user = useSelector(getUser)

  const { selectedFiles, subject } = props

  const createEmail = () => {
    if (subject?.type && subject?.type !== 'profile') {
      const params = {
        [subject.type]: subject.id,
      }

      if (subject?.type === 'building') {
        params.sub === 'all'
      }

      getAllDwellerList(params)
        .then(data => {
          dispatch({
            type: MAIL_SEARCH_SELECT_USERS,
            selectedUsers: data.results.filter(u => u.id !== user.owner),
          })
        })
        .catch()
        .finally(() => {
          dispatch({
            type: MAIL_SEARCH_SELECT_FILES,
            selectedFiles: selectedFiles,
          })
          dispatch(push('/mail/create'))
        })
    } else {
      if (subject?.type === 'profile' && subject?.owner !== user.owner) {
        dispatch({
          type: MAIL_SEARCH_SELECT_USERS,
          selectedUsers: [subject],
        })
      }

      dispatch({
        type: MAIL_SEARCH_SELECT_FILES,
        selectedFiles: selectedFiles,
      })
      dispatch(push('/mail/create'))
    }
  }

  return (
    <Regular {...props} icon='email' view='contained' onClick={createEmail}>
      {t('SendByEmail')}
    </Regular>
  )
}

export default SendFile
