// @flow

import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Header, SimpleBlock } from '../Block'

import type { IPartner, IModalName } from './ProfileData'
import type { Node } from 'react'

import Icon from '../Icon'
import SelectCustom from '../Select/SelectCustom'
import Notes from '../Notes'

import styles from './Profile.module.scss'
import ORAGClusterForm from './ProfileInformationForms/ORAGClusterForm'

type Props = {
  setCurrentNoticeModal: (type: IModalName) => void,
  updateProfileInfo: (data: { personal: IPartner }) => Promise<IPartner>,
}

const ORAGCluster = ({
  cluster: { notes },
  cluster,
  updateProfileInfo,
  setCurrentNoticeModal,
}: Props): Node => {
  const { t } = useTranslation('Profile')
  const [isEdit, setIsEdit] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (!isOpen) {
      setIsEdit(false)
    }
  }, [isOpen])

  const handleEditOpen = () => {
    setIsEdit(true)
    setIsOpen(true)
  }
  const handleEditClose = () => setIsEdit(false)

  const options = [
    {
      value: 'addNotice',
      label: t('AddNotice'),
      onClick: () => setCurrentNoticeModal('orag_cluster'),
      icon: 'pencil',
    },
  ]

  return (
    <SimpleBlock style={{ marginTop: 0 }}>
      <Header overflowVisible header>
        <div className={styles.profileWrap}>
          <div>
            {t('ORAGCluster')}
            {!isEdit && (
              <Icon
                colorOnHover
                style={{ width: 16, height: 16, paddingLeft: '8px' }}
                id='pencil'
                onClick={handleEditOpen}
              />
            )}
          </div>
          <div className={styles.profileOpenIcon}>
            <Icon
              style={{ fill: 'transparent' }}
              id={isOpen ? 'openSection' : 'closedSection'}
              onClick={() => setIsOpen(isOpen => !isOpen)}
            />
            <SelectCustom options={options} onChange={opt => opt.onClick()} />
          </div>
        </div>
      </Header>
      <ORAGClusterForm
        updateProfileInfo={updateProfileInfo}
        active={isEdit}
        handleEditClose={handleEditClose}
        initialValues={cluster}
        isOpen={isOpen}
      />
      <Notes notes={notes && notes.trim()} />
    </SimpleBlock>
  )
}

export default ORAGCluster
