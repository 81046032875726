// @flow

export const COMPANY_VIEW_INITIATE = 'COMPANY_VIEW_INITIATE'
export const COMPANY_VIEW_SECTION_READY = 'COMPANY_VIEW_SECTION_READY'
export const COMPANY_VIEW_ADDRESS_UPDATE = 'COMPANY_VIEW_ADDRESS_UPDATE'
export const COMPANY_VIEW_PRIMARY_SAVED = 'COMPANY_VIEW_PRIMARY_SAVED'
export const COMPANY_VIEW_ADDRESS_EDIT_MODE = 'COMPANY_VIEW_ADDRESS_EDIT_MODE'
export const COMPANY_VIEW_ADDRESS_EDIT_CANCEL =
  'COMPANY_VIEW_ADDRESS_EDIT_CANCEL'
export const COMPANY_VIEW_ADDRESS_EDIT = 'COMPANY_VIEW_ADDRESS_EDIT'

export const COMPANY_VIEW_ADDRESS_SAVE = 'COMPANY_VIEW_ADDRESS_SAVE'
export const COMPANY_VIEW_ADDRESS_SAVED = 'COMPANY_VIEW_ADDRESS_SAVED'

export const COMPANY_VIEW_ADDRESS_CREATE_IN_PROGRESS =
  'COMPANY_VIEW_ADDRESS_CREATE_IN_PROGRESS'
export const COMPANY_VIEW_ADDRESS_CREATE = 'COMPANY_VIEW_ADDRESS_CREATE'
export const COMPANY_VIEW_ADDRESS_CREATED = 'COMPANY_VIEW_ADDRESS_CREATED'

export const COMPANY_VIEW_ADDRESS_REMOVE = 'COMPANY_VIEW_ADDRESS_REMOVE'
export const COMPANY_VIEW_ADDRESS_REMOVED = 'COMPANY_VIEW_ADDRESS_REMOVED'
export const COMPANY_VIEW_ADDRESS_REMOVE_ERROR =
  'COMPANY_VIEW_ADDRESS_REMOVE_ERROR'

export const COMPANY_VIEW_ADDRESS_MODAL_CREATE_TOGGLE =
  'COMPANY_VIEW_ADDRESS_MODAL_CREATE_TOGGLE'
export const COMPANY_VIEW_ADDRESS_MODAL_REMOVE_TOGGLE =
  'COMPANY_VIEW_ADDRESS_MODAL_REMOVE_TOGGLE'
