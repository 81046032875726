// @flow

import React, { Component, Fragment } from 'react'
import classnames from 'classnames'
import styles from './MarketplaceTabs.module.scss'

type Props = {
  items: Array<Object>,
  tabStyle?: Object,
  headerStyle?: Object
}

type State = {
  currentTab: string
}

// TODO hooks
class MarketplaceTabs extends Component<Props, State> {
  state = {
    currentTab: this.props.items[0].id
  }

  selectTab = id => {
    return () => {
      this.setState({
        currentTab: id
      })
    }
  }

  renderCurrentTab () {
    const { currentTab } = this.state
    const { items } = this.props
    const tab = items.find(item => item.id === currentTab)

    return tab.content
  }

  render () {
    const { items, headerStyle, tabStyle } = this.props
    const { currentTab } = this.state

    return (
      <Fragment>
        <div className={styles.tabs} style={tabStyle}>
          {
            items.map(tab => (
              <div
                key={tab.id}
                className={classnames(styles.tab, {
                  [styles['tab-selected']]: tab.id === currentTab
                })}
                style={headerStyle}
                onClick={this.selectTab(tab.id)}
              >{tab.text}</div>
            ))
          }
        </div>
        {this.renderCurrentTab()}
      </Fragment>
    )
  }
}

export default MarketplaceTabs
