// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import Select from '../../../../Select/SelectTableCell'
import { getUser } from '../../../../../utils/commonSelectors'
import { USER_GROUPS } from '../../../../../constants'
import { getUserId } from '../../../../../utils/utils'
import type { Props } from './index'

type RoleProps = Props & {
  canUpdate: boolean,
  loggedUser: Object,
  onClick: (Object, Object) => void,
  t: string => string,
}

class Role extends Component {
  props: RoleProps

  renderSelect = () => {
    const { user, onClick, t } = this.props

    const roles =
      user.group === USER_GROUPS.dweller
        ? [USER_GROUPS.dweller, USER_GROUPS.landlord]
        : [USER_GROUPS.admin, USER_GROUPS.manager]

    const options = roles.map((role, i) => ({
      value: i + 1,
      group: role,
      label: t(role),
    }))

    const group = user.is_landlord ? USER_GROUPS.landlord : user.group
    const value = options.find(o => o.group === group)

    return (
      <Select
        value={value}
        options={options}
        onChange={val => onClick(user, val)}
      />
    )
  }

  render() {
    const { canUpdate, user, loggedUser, t } = this.props
    const isSuper = user.super
    const group = user.is_landlord
      ? USER_GROUPS.landlord
      : isSuper
      ? 'SuperAdmin'
      : user.group
    const disabled = getUserId(loggedUser) === getUserId(user) || !canUpdate

    return (
      <div className='table__cell'>
        <div className='table__cell-wrapper'>
          <div className='table-item table-item__text-role'>
            {disabled ? <div>{t(group)}</div> : this.renderSelect()}
          </div>
        </div>
      </div>
    )
  }
}

export default connect(state => ({
  loggedUser: getUser(state),
}))(withTranslation('User')(Role))
