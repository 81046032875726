// @flow

export const MAIL_LIST_INITIATING = 'MAIL_LIST_INITIATING'
export const MAIL_LIST_INITIATED = 'MAIL_LIST_INITIATED'
export const MAIL_LIST_ERROR = 'MAIL_LIST_ERROR'
export const MAIL_GET_FILES_INITIATING = 'MAIL_GET_FILES_INITIATING'
export const MAIL_GET_FILES_INITIATED = 'MAIL_GET_FILES_INITIATED'
export const MAIL_GET_FILES_ERROR = 'MAIL_GET_FILES_ERROR'
export const MAIL_LIST_RESET = 'MAIL_LIST_RESET'
export const TOGGLE_ALL = 'TOGGLE_ALL'
export const TOGGLE = 'TOGGLE'
export const MODAL = '/Mail/MODAL'
export const DELETE = '/Mail/DELETE'
export const RELOAD = '/Mail/RELOAD'
export const RESTORE = '/Mail/RESTORE'
export const MARK_AS_READ = 'MARK_AS_READ'
export const MARK_AS_UNREAD = 'MARK_AS_UNREAD'
export const ADD_STAR = 'ADD_STAR'
export const REMOVE_STAR = 'REMOVE_STAR'
export const CHANGE_NOTES = 'CHANGE_NOTES'
export const SET_FOLDER = 'SET_FOLDER'
export const THREADS_ERROR = 'THREADS_ERROR'
