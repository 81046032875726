// @flow

import React, { useContext } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { entries, get, isArray, map } from 'lodash-es'
import type { Node } from 'react'

import ModalBody from '../../Modal/ModalBody'
import NewWizardContext from '../NewWizard.context'
import Button from '../../Button'
import Card from './Card'
import {
  getInitialValues,
  isBotVisible,
  isEmptyChunk,
  isWorkflowEnd,
  getValidationSchema,
} from './CardStage.utils'
import BotCard from './BotCard'
import Text from './Text'
import ModalButtons from '../../Modal/ModalButtons'
import CardStageContext from './CardStage.context'
import type { ChunkType } from '../NewWizard.types'

import styles from './CardStage.module.scss'

type Props = {|
  chunk: ChunkType,
  isCancel: boolean,
  onCancel: () => void,
  onClose: () => void,
|}

const CardStage = (props: Props): Node => {
  const { chunk, isCancel } = props

  const { t } = useTranslation('WizardPopup')

  const { onSubmit } = useContext(NewWizardContext)

  const cardClass = classNames({ [styles.hidden]: isCancel })

  const initialValues = getInitialValues(chunk)
  const validationSchema = getValidationSchema(chunk)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues || {},
    validationSchema,
    onSubmit: submitValues => {
      let isLink = false

      const submitEntries = entries(submitValues)

      let processedValues = submitEntries.map(entry => {
        const name = entry[0]
        const value = entry[1]

        if (name === 'link') {
          isLink = true

          return { block: value.value }
        }

        return {
          name: entry[0],
          value: isArray(value) ? JSON.stringify(map(value, 'id')) : value,
        }
      })

      if (isLink) {
        processedValues = processedValues[0]
      } else {
        processedValues = {
          variables: JSON.stringify(
            processedValues.filter(entry => !!get(entry, 'value'))
          ),
        }
      }

      formik.setSubmitting(false)

      onSubmit(processedValues)
      formik.resetForm()
    },
  })

  const handleChangeLink = option => formik.setFieldValue('link', option)

  return (
    <div className={cardClass}>
      {isBotVisible(chunk) && <BotCard />}
      <form onSubmit={formik.handleSubmit}>
        <ModalBody>
          <CardStageContext.Provider
            value={{
              values: formik.values,
              errors: formik.errors,
              handleChange: formik.handleChange,
              setFieldValue: formik.setFieldValue,
              handleChangeLink,
            }}
          >
            {chunk.map(card => (
              <Card key={card.type + card.id + card.name} card={card} />
            ))}
          </CardStageContext.Provider>
        </ModalBody>
        {!isWorkflowEnd(chunk) && (
          <ModalButtons>
            <Button.Save type='submit' disabled={formik.isSubmitting}>
              {t('Common:Continue')}
            </Button.Save>
            <Button.Cancel
              disabled={formik.isSubmitting}
              onClick={props.onCancel}
            >
              {t('Common:Cancel')}
            </Button.Cancel>
          </ModalButtons>
        )}
      </form>
      {isEmptyChunk(chunk) && <Text text={t('WorkflowEndText')} />}
      {isWorkflowEnd(chunk) && (
        <ModalButtons>
          <Button.Save onClick={props.onClose}>{t('Common:Ok')}</Button.Save>
        </ModalButtons>
      )}
    </div>
  )
}

export default CardStage
