// @flow

import React from 'react'
import type { Node } from 'react'

import List from './List'
import { SimpleBlock, Header } from '../Block'

type Props = { attributes: Object, title: string }

const ProfileAttrs = (props: Props): Node => {
  const { attributes, title } = props

  return (
    <SimpleBlock>
      <Header header>{title}</Header>
      <div>
        <List attrs={attributes} />
      </div>
    </SimpleBlock>
  )
}

export default ProfileAttrs
