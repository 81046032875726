// @flow

import * as actions from './MarketplaceSettings.actionTypes'

export function get() {
  return {
    type: actions.MARKETPLACE_SETTINGS_INITIATE,
  }
}

export function update(params: { is_active: boolean }) {
  return {
    type: actions.MARKETPLACE_SETTINGS_UPDATE,
    params,
  }
}
