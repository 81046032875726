// @flow

import React from 'react'
import { goBack } from 'connected-react-router'
import { useDispatch } from 'react-redux'
import type { Node } from 'react'

import Icon from '../../Icon'

import styles from './Back.module.scss'

type Props = {
  onClick?: (SyntheticEvent<HTMLLinkElement>) => void,
}

const Back = (props: Props): Node => {
  const dispatch = useDispatch()

  const handleClick = e => {
    if (props.onClick) {
      return props.onClick(e)
    }

    return dispatch(goBack())
  }

  return (
    <a className={styles.button} onClick={handleClick}>
      <Icon id='back' />
    </a>
  )
}

export default Back
