// @flow

import React from 'react'
import type { Node } from 'react'

import { getUserName } from '../../../utils/utils'
import LoginLink from '../LoginLink'

type Props = {
  email: string,
  name: string,
  secondName: string,
  t: (string, ?Object) => string,
}

const Confirm = (props: Props): Node => {
  const { name, secondName, email } = props

  const fullname = getUserName({
    name,
    second_name: secondName,
  })

  return (
    <div className='newpass_wrap free-registration'>
      <div className='b_main_title'>
        <h1>{props.t('FreeRegistrationConfirmedTitle')}</h1>
      </div>
      <div className='b_white_block--bottom'>
        <div className='def_form login_form js_validate'>
          <div className='lf__fields'>
            <div className='def_form_line'>
              <div
                className='free-registration--success-text'
                dangerouslySetInnerHTML={{
                  __html: props.t('EmailSendedText', { name: fullname, email }),
                }}
              />
            </div>
          </div>
        </div>
        <LoginLink linkText={props.t('Login')} />
      </div>
    </div>
  )
}

export default Confirm
