// @flow

import React from 'react'
import classNames from 'classnames'
import type { Node } from 'react'
import { useTranslation } from 'react-i18next'

import { getProcessedValue } from './Value.utils'

import styles from './Value.module.scss'

type Props = { isSub?: boolean, value: any }

const Value = (props: Props): Node => {
  const { value, isSub } = props

  const { t } = useTranslation('Common')

  const valueClass = classNames(styles.value, { [styles.sub]: isSub })

  return (
    <span title={value} className={valueClass}>
      {getProcessedValue(value, t)}
    </span>
  )
}

export default Value
