// @flow

import React, { useState, useEffect } from 'react'
import type { Node } from 'react'
import { useTranslation } from 'react-i18next'

import { omit } from 'lodash-es'

import Request from '../../RequestConstructor/Card/Request'

import {
  getDeadlineTemplate,
  getDeadlineObject,
} from '../../Activities/ActivityModal/ActivityModal.utils'

import { OMIT_FIELDS } from '../AutomateTable/Automate.constants'

type Props = {
  data: Object,
  onUpdate: Object => void,
  setWorking: boolean => void,
}

const CreateRequest = (props: Props): Node => {
  const { data, onUpdate } = props
  const { t } = useTranslation()

  const getInitialValues = () => ({
    assignees: [].concat(data.assignees || []).concat(
      data.assignees_option
        ? [
            {
              id: 'request_assignees',
              fullname: t('RequestConstructor:request_assignees'),
            },
          ]
        : []
    ),
    building:
      data.building ||
      (data.building_option ? { id: data.building_option } : null),
    category: data.category || null,
    contractors: data.contractors || [],
    contractors_option:
      data.contractors?.length > 0
        ? 'custom'
        : data.contractors_option || 'not_assign',
    files: data.files || [],
    files_options: data.files_options || [],
    flat: data.flat || (data.flat_option ? { id: data.flat_option } : null),
    labels: data.labels || [],
    due_date_interval: getDeadlineTemplate(
      data.deadline_days,
      data.deadline_hours,
      data.deadline_minutes
    ),
    owner:
      data.owner ||
      (data.owner_option && data.owner_option !== 'super_admin'
        ? { id: data.owner_option }
        : null),
    related_requests: data.related_requests || [],
    related_requests_option:
      data.related_requests_option === undefined
        ? 'request'
        : data.related_requests_option,
    status: data.status_id || 1,
    text: data.text || '',
    title: data.title || '',
  })

  const [values, setValues] = useState(getInitialValues())

  useEffect(() => {
    onUpdate({
      ...omit(data, OMIT_FIELDS),
    })
  }, [])

  const getOwnerFields = owner => {
    let owner_id = null
    let owner_option = null

    if (!owner) {
      owner_option = 'super_admin'
    } else if (
      ['trigger_initiator', 'automation_executor', 'request_owner'].includes(
        owner.id
      )
    ) {
      owner_option = owner.id
    } else {
      owner_id = owner.id
    }

    return { owner_id, owner_option }
  }

  const getAssigneesFields = assignees => {
    let assignee_ids = []
    let assignees_option = null

    if (assignees) {
      assignee_ids = JSON.parse(assignees)

      if (assignee_ids.includes('request_assignees')) {
        assignee_ids = assignee_ids.filter(id => id !== 'request_assignees')
        assignees_option = 'request_assignees'
      }
    }

    return { assignee_ids, assignees_option }
  }

  const updateValues = values => {
    setValues(values)
    onUpdate({
      text: values.text.replace(/\n/g, ''),
      title: values.title,
      category_id: values.category || null,
      status_id: values.status,
      label_ids: (values.labels && JSON.parse(values.labels)) || [],
      ...getDeadlineObject(values.due_date_interval),
      ...getOwnerFields(values.owner),
      building_id:
        !values.building || values.building?.id === 'request_building'
          ? null
          : values.building.id,
      building_option:
        values.building?.id === 'request_building' ? 'request_building' : null,
      flat_id:
        !values.flat || values.flat?.id === 'request_flat'
          ? null
          : values.flat.id,
      flat_option: values.flat?.id === 'request_flat' ? 'request_flat' : null,
      ...getAssigneesFields(values.assignees),
      contractor_ids:
        values.contractors_option === 'custom'
          ? values.contractors.map(c => c.id)
          : [],
      contractors_option:
        values.contractors_option === 'custom' ||
        values.contractors_option === 'not_assign'
          ? null
          : values.contractors_option,
      related_request_ids: values.related_requests?.map(r => r.id) || [],
      related_requests_option: values.related_requests_option,
      file_ids: (values.files && JSON.parse(values.files)) || [],
      files_options: values.files_options,
    })
  }

  return (
    <Request
      automate
      data={values}
      canSaveOnUnmount={() => false}
      onUpdate={updateValues}
      onFilesUploading={() => props.setWorking(true)}
      onFinishedFilesUploading={() => props.setWorking(false)}
    />
  )
}

export default CreateRequest
