// @flow

import React from 'react'
import type Node from 'react'

import Address from './Address'
import Editor from './Editor'

type Props = {
  building?: Object,
  editing: boolean,
  flat?: Object,
  flatError: boolean,
  onChange: Object => void,
  onEdit?: string => void,
  outbound?: string,
  owner?: Object,
  permissions: { can_edit_owner: boolean },
}

const OwnerAddress = (props: Props): Node => {
  const {
    editing,
    flat,
    building,
    outbound,
    owner,
    permissions: { can_edit_owner: canEditOwner },
  } = props

  if (editing) {
    return (
      <Editor
        key={owner}
        flat={flat}
        building={building}
        owner={owner}
        outbound={outbound}
        onChange={props.onChange}
      />
    )
  }

  const addressProps = Object.assign(
    {},
    { flat, building },
    { outbound },
    canEditOwner && props.onEdit ? { onEdit: props.onEdit } : undefined
  )

  return <Address {...addressProps} />
}

OwnerAddress.defaultProps = {
  permissions: {
    can_edit_owner: false,
  },
}

export default OwnerAddress
