// @flow

import {
  delay,
  fork,
  call,
  select,
  put,
  race,
  takeEvery,
  all,
} from 'redux-saga/effects'
import { isEqual } from 'lodash-es'
import { replace } from 'connected-react-router'

import * as actions from './PublicBuildingPage.actions'
import * as selectors from './PublicBuildingPage.selectors'
import api from '../../core/api'

function* watchLoadBuilding() {
  yield takeEvery(actions.LOAD_BUILDING, loadBuilding)
}

function* watchLoadContact() {
  yield takeEvery(actions.LOAD_CONTACTS, loadContacts)
}

function* watchLoadNews() {
  yield takeEvery(actions.LOAD_NEWS, loadNews)
}

function* watchLoadTickerNews() {
  yield takeEvery(actions.LOAD_TICKER_NEWS, loadTickerNews)
}

function* watchLoadWeather() {
  yield takeEvery(actions.LOAD_WEATHER, loadWeather)
}

function* watchLoadInit() {
  yield takeEvery(actions.LOAD_INIT_DIGITAL_BOARD, loadInit)
}

function* watchLoadVersion() {
  yield takeEvery(actions.LOAD_VERSION, loadVersion)
}

function* loadBuilding() {
  try {
    const buildingId = yield select(selectors.getBuildingId)
    const weather = yield select(selectors.getWeather)
    const building = yield call(
      api.publicBuildingPage.getBuildingInfo,
      buildingId
    )

    yield put(actions.buildingWasLoaded(building))

    if (weather.main.temp === null) {
      yield* loadWeather()
    }
  } catch (e) {
    const { response } = e.message

    if (response) {
      response.status === 404 && (yield put(replace('/404')))
    }

    console.warn(e.message)
  }
}

function* loadContacts() {
  try {
    const params = yield select(selectors.getContactsParams)
    const contacts = yield call(api.publicBuildingPage.getContactsUk, params)

    yield put(actions.contactsWasLoading(contacts))
  } catch (e) {
    console.warn(e)
  }
}

function* loadNews() {
  try {
    const params = yield select(selectors.getNewsParams)
    const news = yield call(api.publicBuildingPage.getNews, params)
    const oldNews = yield select(
      state => state.publicBuildingPage.news.results.objects
    )

    if (!isEqual(news, oldNews)) {
      yield put(actions.newsWasLoaded(news))
    }
  } catch (e) {
    console.warn(e)
    yield put(actions.newsWasFailed(e))
  }
}

function* loadTickerNews() {
  try {
    const params = yield select(selectors.getNewsParams)
    const tickerNews = yield call(api.publicBuildingPage.getTickerNews, params)
    const oldTickerNews = yield select(
      state => state.publicBuildingPage.tickerNews.results.objects
    )

    if (!isEqual(tickerNews, oldTickerNews)) {
      yield put(actions.tickerNewsWasLoaded(tickerNews))
    }
  } catch (e) {
    console.warn(e)
    yield put(actions.tickerNewsWasFailed(e))
  }
}

function* loadWeather() {
  try {
    const params = yield select(selectors.getWeatherParams)
    const { weather, timeout } = yield race({
      weather: call(api.weather.getCurrentWeather, params),
      timeout: delay(2000),
    })

    if (timeout) {
      Error('Timeout error.')
    }

    yield put(actions.weatherWasLoaded(weather.data))
  } catch (e) {
    console.warn(e)
    yield put(actions.weatherWasFailed(e))
  }
}

function* loadVersion() {
  try {
    yield put(actions.loadVersion())
    const version = yield call(api.publicBuildingPage.getVersion)
    yield put(actions.versionWasLoaded(version))
  } catch (e) {
    console.warn(e)
  }
}

function* loadInit() {
  try {
    const system = yield call(api.system.init)
    yield put(actions.initWasLoaded(system))
  } catch (e) {
    console.warn(e)
  }
}

export default function* () {
  yield all([
    fork(watchLoadBuilding),
    fork(watchLoadContact),
    fork(watchLoadNews),
    fork(watchLoadTickerNews),
    fork(watchLoadWeather),
    fork(watchLoadInit),
    fork(watchLoadVersion),
  ])
}
