// @flow

import React from 'react'

import RequestList from '../../../components/Request/RequestList'

const RequestRequests = props => {
  const { init, params } = props

  return <RequestList params={params} init={init} tabContext='request' />
}

export default RequestRequests
