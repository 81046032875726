// @flow

import React from 'react'
import type { Node } from 'react'

import ConnectedCompanies from '../ConnectedCompanies'

const IdwellToIdwell = (): Node => {
  return (
    <div className='content__col' style={{ paddingTop: '20px' }}>
      <section className='settings-rights unit unit--default'>
        <ConnectedCompanies />
      </section>
    </div>
  )
}

export default IdwellToIdwell
