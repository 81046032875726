// @flow

import React from 'react'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import classnames from 'classnames'
import type { Node } from 'react'

type Props = {
  checked: boolean,
  children: Node,
  handleClick: number => void,
  id: number,
  noClickable?: boolean,
  style?: Object,
}

const BodyRow = (props: Props) => {
  const { children, id, checked, forwardRef, style, noClickable } = props

  const dispatch = useDispatch()

  const handleClick = () => {
    if (props.handleClick) {
      props.handleClick(id)
    } else {
      dispatch(push(`/settings/workflow/${id}`))
    }
  }

  const rowClass = classnames('table__row', {
    'table__row--attention': checked,
    'table__row--not-clickable': noClickable,
  })

  return (
    <div
      className={rowClass}
      ref={forwardRef}
      style={style}
      onClick={handleClick}
    >
      {children}
    </div>
  )
}

BodyRow.defaultProps = {
  checked: false,
  style: {},
}

export default React.forwardRef<Props, HTMLDivElement>((props, ref) => (
  <BodyRow {...props} forwardRef={ref} />
))
