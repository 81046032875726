/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type OrderProfileStatus_data$ref: FragmentReference;
declare export opaque type OrderProfileStatus_data$fragmentType: OrderProfileStatus_data$ref;
export type OrderProfileStatus_data = {|
  +status: ?string,
  +id: string,
  +$refType: OrderProfileStatus_data$ref,
|};
export type OrderProfileStatus_data$data = OrderProfileStatus_data;
export type OrderProfileStatus_data$key = {
  +$data?: OrderProfileStatus_data$data,
  +$fragmentRefs: OrderProfileStatus_data$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "OrderProfileStatus_data",
  "type": "CustomerOrderNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "status",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '27e2d5bf183540480d42cfbadb13a774';
module.exports = node;
