// @flow

import { map, concat, intersection, difference } from 'lodash-es'
import moment from 'moment'

import {
  AUDIENCE_ICONS,
  BUILDING_TYPE,
  AUDIENCE_TYPE,
  GROUPED_OPTIONS,
  GROUPED_AUDIENCE,
  GROUPED_AUDIENCE_DICT,
  AUDIENCE_LIST,
  SIGNUP_AUDIENCE,
  TO_ALL_BUILDINGS,
  TO_BUILDING,
  BUILDINGS_SCOPE,
  ALIASES,
  POST_TYPE,
  TICKER_TYPE,
  TO_DIGITAL_BOARD,
  REQUEST_BUILDING,
  REQUEST_BUILDING_GROUP,
} from './Post.constants'

import { USER_GROUPS } from '../../constants'

type TimeValue = {
  label: string,
  value: number,
}

type AudienceItem = {
  iconId?: string,
  id: string,
  text: string,
  type: 'audience' | 'building',
}

type BuildingItem = {
  address_obj: Object,
  id: string,
  photo_obj: Object,
}

type GroupItem = {
  avatar_obj: Object,
  id: string,
  name: string,
}

type Option = {
  label: string,
  value: string,
}

type Translate = string => string

const getIcon = (item: string): string => {
  return AUDIENCE_ICONS[item]
}

export const getAudience = (
  audience: Array<string>,
  buildings: Array<BuildingItem> = [],
  user: Object,
  allBuildings: boolean,
  isGroupsPost: boolean,
  requestBuilding?: boolean
): Array<AudienceItem> => {
  const { group: userGroup } = user
  const { admin: adminGroup, dweller: dwellerGroup } = USER_GROUPS

  let newAudienceList = audience || []

  if (
    userGroup === dwellerGroup &&
    intersection(audience, SIGNUP_AUDIENCE).length > 0
  ) {
    newAudienceList = difference(audience, SIGNUP_AUDIENCE).concat([
      TO_BUILDING,
    ])
  }

  let requestBuildingAudience =
    requestBuilding && adminGroup
      ? [isGroupsPost ? REQUEST_BUILDING_GROUP : REQUEST_BUILDING]
      : []

  if (allBuildings && adminGroup) {
    return map(
      newAudienceList
        .concat([TO_ALL_BUILDINGS])
        .concat(requestBuildingAudience),
      getAudienceItem
    )
  }

  const buildingItems = map(
    buildings,
    isGroupsPost ? getGroupItem : getBuildingItem
  )

  return concat(
    map(newAudienceList.concat(requestBuildingAudience), getAudienceItem),
    buildingItems
  )
}

export const getAudienceItem = (item: string): AudienceItem => ({
  id: item,
  text: item,
  type: AUDIENCE_TYPE,
  iconId: getIcon(item),
})

export const getBuildingItem = (item: BuildingItem) => ({
  avatar: item.photo_obj?.['130'] || item.avatar_obj?.preview,
  id: item.id,
  iconId: getIcon(BUILDING_TYPE),
  text: item.address_obj?.value || item.address,
  type: BUILDING_TYPE,
})

export const getGroupItem = (item: GroupItem) => ({
  avatar: item.avatar_obj?.preview,
  id: item.id || item.value,
  iconId: getIcon(BUILDING_TYPE),
  text: item.name || item.label,
  type: BUILDING_TYPE,
  isGroup: true,
})

const getTimeMinute = () => {
  return [...new Array(95).keys()].reduce(
    acc => {
      const currentObj = acc[acc.length - 1]
      const currentMinute = currentObj.minute
      const nextMinute = currentMinute + 15
      const currentHour = currentObj.hour

      if (nextMinute % 60 === 0) {
        acc.push({ hour: currentHour + 1, minute: 0 })
      } else {
        acc.push({ hour: currentHour, minute: nextMinute })
      }

      return acc
    },
    [{ hour: 0, minute: 0 }]
  )
}

export const getTimeOptions = (): Array<TimeValue> =>
  [...new Array(24).keys()].map(num => ({
    value: num,
    label: `${num < 10 ? '0' : ''}${num}:00`,
  }))

export const getTimeMinuteOptions = (): Array<TimeValue> =>
  getTimeMinute().map(time => ({
    value: time.hour * 60 + time.minute,
    label: `${time.hour < 10 ? '0' : ''}${time.hour}:${
      time.minute < 10 ? '0' : ''
    }${time.minute}`,
  }))

export const getTime = (date: ?string, minute = false): TimeValue => {
  if (!date) {
    return !minute ? getTimeOptions()[12] : getTimeMinuteOptions()[48]
  }

  const hours = moment(date).get('hour')
  const minutes = moment(date).get('minute')

  if (minute) {
    return {
      value: hours,
      label: `${hours < 10 ? '0' : ''}${hours}:${
        minutes < 10 ? '0' : ''
      }${minutes}`,
    }
  }

  return { value: hours, label: `${hours < 10 ? '0' : ''}${hours}:00` }
}

export const isSameDay = (from, to) => {
  const format = 'YYYY MMMM DD'

  return moment(from).format(format) === moment(to).format(format)
}

export const getGroupedOptions = (
  groupedAudience: string,
  t: Translate
): Array<Option> =>
  GROUPED_OPTIONS[groupedAudience].map(item =>
    getOptionFromAudienceItem(item, t)
  )

export const getOptionTranslate = (key, t) => t(ALIASES[key] || key)

export const getOptionFromAudienceItem = (
  audienceItem: string,
  t: Translate
): Option => ({
  label: getOptionTranslate(audienceItem, t),
  value: audienceItem,
})

export const getSelectValue = (
  groupedAudience: string,
  audience: Array<string>,
  t: Translate
): Option => {
  const groupeAudienceList = GROUPED_AUDIENCE_DICT[groupedAudience]
  const sameAudienceItems = intersection(audience, groupeAudienceList)

  if (sameAudienceItems.length === 1) {
    return getOptionFromAudienceItem(sameAudienceItems[0], t)
  }

  return getOptionFromAudienceItem(groupedAudience, t)
}

export const isGroupedAudience = (item: string): boolean =>
  GROUPED_AUDIENCE.includes(item)

export const getCheckedAudienceList = (
  audienceListObj: Array<Object>,
  user: Object,
  postType: string
): Array<string> => {
  const { group } = user

  if (postType === TICKER_TYPE) {
    return [TO_DIGITAL_BOARD]
  }

  if (group !== USER_GROUPS.dweller) {
    return AUDIENCE_LIST
  }

  const audienceList = map(audienceListObj, 'title')

  const newAudienceList = difference(audienceList, SIGNUP_AUDIENCE)

  return newAudienceList.concat([TO_BUILDING])
}

export const isAllSelected = (
  initialBuildings: Array<Object>,
  audience: Array<string>
): boolean =>
  !initialBuildings.length && intersection(audience, BUILDINGS_SCOPE).length > 0

export const getPostTypeOptions = (t: Translate): Array<Object> => [
  { value: POST_TYPE, label: t('Post') },
  { value: TICKER_TYPE, label: t('Ticker') },
]
