// @flow

import * as actions from './WarningStripe.actionTypes'

const initialState = {
  working: false,
  completed: false,
  error: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.SHOW_MODAL:
      return {
        ...state,
        modal: action.modal,
      }
    case actions.CONFIRM_DELETE:
      return {
        ...state,
        modal: null,
        working: true,
      }
    case actions.DELETED:
      return {
        ...state,
        working: false,
        modal: null,
        completed: true,
      }
    case actions.ERROR:
      return {
        ...state,
        working: false,
        error: action.error,
      }
    default:
      return state
  }
}
