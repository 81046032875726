// @flow

export const MAX_TITLE_LENGTH = 256
export const MAX_NOTE_LENGTH = 2000

export const DEADLINE_REGEXP: RegExp =
  /^ *(\d{1,3}[dD] *)*?(\d{1,2}[hH] *)*?(\d{1,2}[mM])*? *$/

export const MANAGER = 'manager'
export const REQUEST = 'request'
export const TITLE = 'title'
export const COMMIT_TYPE = 'commit_type'
export const NOTES = 'notes'
export const DEADLINE = 'deadline'
export const PRIORITY = 'priority'
export const TYPE = 'type'
export const DEADLINE_DAYS = 'deadline_days'
export const DEADLINE_HOURS = 'deadline_hours'
export const DEADLINE_MINUTES = 'deadline_minutes'
export const PHASE = 'phase'
export const BUILDING = 'building'
export const PHASE_TEMPLATE = 'phase_template'
export const POSITION = 'position'

export const DEADLINE_DATE = 'deadlineDate'
export const DEADLINE_TIME = 'deadlineTime'
export const DEADLINE_TEMPLATE = 'deadlineTemplate'

export const DONE_COMMIT_TYPE = 'done'
export const YESNO_COMMIT_TYPE = 'yesno'
export const DEFAULT_COMMIT_TYPE = DONE_COMMIT_TYPE

export const REQUEST_ID = 'id'
export const REQUEST_TITLE = 'title'
export const REQUEST_NO = 'request_no'
