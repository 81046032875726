// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import NewTableHeaderCell from '../../NewTable/NewTableHeaderCell'
import NewTableHeaderRow from '../../NewTable/NewTableHeaderRow'
import NewTableHeader from '../../NewTable/NewTableHeader'

const ContractorsTableHeader = (): Node => {
  const { t } = useTranslation('Contractors')

  return (
    <NewTableHeader>
      <NewTableHeaderRow>
        <NewTableHeaderCell
          title={t('Name')}
          style={{ width: '20%' }}
          sortKey='name'
        />
        <NewTableHeaderCell title={t('Categories')} style={{ width: '20%' }} />
        <NewTableHeaderCell title={t('Address')} style={{ width: '20%' }} />
        <NewTableHeaderCell
          title={t('Email')}
          style={{ width: '15%' }}
          sortKey='email'
        />
        <NewTableHeaderCell
          title={t('Phone')}
          style={{ width: '15%' }}
          sortKey='phone'
        />
        <NewTableHeaderCell
          title={t('Created')}
          style={{ width: '10%' }}
          sortKey='created'
        />
      </NewTableHeaderRow>
    </NewTableHeader>
  )
}

export default ContractorsTableHeader
