// @flow

import React from 'react'
import { useSelector } from 'react-redux'
import type { Node } from 'react'

import RequestNewsPost from '../Request/RequestNewsPost'
import RatingPost from '../../containers/Feedback/RatingPost'
import { POST_TYPE, SYSTEM_TYPE, TICKER_TYPE } from './Post.constants'
import NewPostItem from './NewPostItem'

type Props = {
  post: Object,
  requestStatuses: Object,
  scrollParams: Object,
}

const NewsFeedPost = (props: Props): Node => {
  const { post, scrollParams, requestStatuses } = props

  const { request_dump: requestDump, post_type: postType } = post

  const postsPinning = useSelector(state => state.newsList.postsPinning)

  switch (postType) {
    case POST_TYPE:
    case TICKER_TYPE:
      return (
        <NewPostItem
          scrollParams={scrollParams}
          post={post}
          key={post.uuid}
          pinning={postsPinning.has(post.uuid)}
        />
      )
    case SYSTEM_TYPE:
      if (requestDump) {
        return (
          <RequestNewsPost
            post={post}
            key={post.uuid}
            requestStatuses={requestStatuses}
          />
        )
      }

      return (
        <RatingPost
          created={post.created}
          owner={post.owner_obj}
          request={post.request}
        />
      )
    default:
      return null
  }
}

export default NewsFeedPost
