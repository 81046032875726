// @flow

import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { push } from 'connected-react-router'
import { useParams } from 'react-router-dom'
import classnames from 'classnames'
import { compose } from 'redux'

import {
  getCurrentFilters,
  getCurrentPage,
} from '../../../../../../utils/routing'
import BuildingPopup from '../../../../../modals/BuildingPopup'
import { USER_GROUPS } from '../../../../../../constants'
import Phone from '../../../../../Phone'
import Cell from '../../../../../User/UserList/UserItem/Cell'
import StatusValue from '../../../../../User/UserList/UserItem/StatusValue'
import { toggle, updateUser } from './UserListItem.actions'
import { getLocation, getUser } from '../../../../../../utils/commonSelectors'
import { approve, remove, update } from '../../../Toolbox/Toolbox.actions'
import ConfirmationPopup from '../../../../../modals/ConfirmationPopup'
import ExtraActions from './ExtraActions'
import { getStatusOptions } from './UserListItem.utils'
import { getUserName } from '../../../../../../utils/utils'
import ChangeRolePopup from '../../../../../modals/ChangeRolePopup'

const UserListItem = props => {
  const {
    isSelected,
    permissions: { can_delete: canDelete, can_restore: canRestore },
    statuses,
    user,
    user: { avatar, phone, status, id, validated },
    users,
    loggedUser,
    meta,
  } = props

  const [updated, setUpdated] = useState(false)
  const { groupId: buildingGroup, search } = useParams()

  useEffect(() => {
    if (updated && !user.address && props.load) {
      props.load()
      setUpdated(false)
    }
  }, [updated, user])

  const userClass = classnames('table__row', {
    'table__row--attention': isSelected,
  })

  const getParams = () => {
    const { location } = props
    const page = getCurrentPage(location)

    return {
      page,
      counters: 1,
      group: USER_GROUPS.dweller,
      ...getCurrentFilters(location),
    }
  }

  const preventOpen = e => {
    e.stopPropagation()
  }

  const updateUser = (id, params, tiny = false) => {
    props.updateUser(id, params, tiny)
  }

  const confirmRoleUpdating = (user, role) => {
    if (!user || !role) {
      const onOk = user
        ? val => updateUser(user.id, { group: val })
        : val => props.update({ group: val })

      props.setModal(
        <ChangeRolePopup
          confirm={props.t('Save')}
          isLandlord={user && user.is_landlord}
          massAction={!user}
          onClose={() => props.setModal(null)}
          onOk={onOk}
        />
      )

      return
    }

    const { group, label } = role

    if (
      (user.group !== USER_GROUPS.dweller && group === user.group) ||
      (user.is_landlord && group === USER_GROUPS.landlord) ||
      (!user.is_landlord && group === USER_GROUPS.dweller)
    ) {
      return
    }

    const [name, ...surname] = user.fullname.split(' ')
    const text = props.t('UpdateRoleText', {
      name,
      surname: surname.join(' '),
      group: label,
    })

    props.setModal(
      <ConfirmationPopup
        title={props.t('UpdateRoleTitle')}
        text={text}
        confirm={props.t('Update')}
        cancel={props.t('Cancel')}
        onClose={() => props.setModal(null)}
        onOk={() => updateUser(user.id, { group })}
      />
    )
  }

  const toggle = e => {
    props.toggle(parseInt(e.target.value, 10))
  }

  const open = () => {
    props.push(`/profile/${id}/`)
  }

  const updateDwellerFlats = ({ id, permissions }, flats) => {
    if (flats.length || !permissions?.can_manage_all_user_flats) {
      updateUser(id, { flats }, true)

      if (!flats.length) {
        setUpdated(true)
      }
    } else {
      remove(id)
    }
  }

  const remove = owner => {
    const user = users.find(u => u.id === owner)

    if (user && user.permissions && user.permissions.can_delete) {
      props.remove([owner], getParams(), meta)
    }
  }

  const showBuildingModal = user => {
    if (validated) {
      props.setModal(
        <BuildingPopup
          user={user}
          onClose={() => props.setModal(null)}
          onOk={ids => updateDwellerFlats(user, ids)}
        />
      )
    }
  }

  const canSelect = canDelete || canRestore
  const userInfoClass = classnames('table-item__user-info')

  const canUpdateRole =
    loggedUser.permissions.can_change_dweller_role &&
    user.permissions.can_change_dweller_role

  const renderExtra = () => {
    if (search) {
      return null
    }

    return (
      <ExtraActions
        canUpdateRole={canUpdateRole}
        meta={meta}
        approve={props.approve}
        remove={props.remove}
        user={user}
        getParams={getParams}
        setModal={props.setModal}
        T={props.T}
        t={props.t}
        load={props.load}
        updateUser={updateUser}
        open={open}
        showBuildingModal={showBuildingModal}
        confirmRoleUpdating={confirmRoleUpdating}
        loggedUser={loggedUser}
        canDelete={canDelete}
        canRestore={canRestore}
        isArchived={getParams().soft_archived}
      />
    )
  }

  const userName = getUserName(user)

  const userFormattedRole = {
    id: user.id,
    fullname: user.fullname,
    group: user.group || USER_GROUPS.dweller,
    is_landlord:
      user.is_landlord !== undefined
        ? user.is_landlord
        : user.role === USER_GROUPS.landlord,
  }

  return (
    <div className={userClass}>
      <div className='table__cell'>
        <div className='table__cell-wrapper' onClick={open}>
          <div
            className='table-item table-item__user'
            style={{ display: 'flex', alignItems: 'flex-start' }}
          >
            {canSelect ? (
              <label
                className='checkbox checkbox--medium checkbox--default checkbox--notext table-item__checkbox'
                htmlFor={`check-${id}`}
                onClick={preventOpen}
              >
                <input
                  id={`check-${id}`}
                  type='checkbox'
                  value={id}
                  checked={isSelected}
                  onChange={toggle}
                />
                <span />
              </label>
            ) : (
              !search && (
                <label className='checkbox checkbox--medium checkbox--default checkbox--notext table-item__checkbox'>
                  &nbsp;
                </label>
              )
            )}
            <div className={userInfoClass}>
              <i className='table-item__user-pic' onClick={open}>
                <img src={avatar} alt='user' />
              </i>
              <div>
                <span className='table-item__user-name' title={userName}>
                  {userName}
                </span>
                <StatusValue
                  options={getStatusOptions(statuses, props.t)}
                  value={status}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Cell.Role
        user={userFormattedRole}
        canUpdate={!search && canUpdateRole}
        onClick={confirmRoleUpdating}
      />
      {!buildingGroup && (
        <Cell.Address
          user={user}
          archived={getParams().soft_archived || search}
          onClick={showBuildingModal}
        />
      )}
      {buildingGroup && (
        <div className='table__cell'>
          <div className='table__cell-wrapper'>
            <div
              className='table-item table-item__user-pic'
              style={{ border: '1px solid #e0e0e0' }}
            >
              <img src={user.building?.avatar_obj?.thumbnail} />
            </div>
            <div className='table-item table-item__text-building'>
              {user.building?.address || ''}
            </div>
            {user.building_count > 1 && (
              <span>{`+${user.building_count - 1}`}</span>
            )}
          </div>
        </div>
      )}
      {!buildingGroup && (
        <>
          <Cell.Email user={user} />
          <div className='table__cell'>
            <div className='table__cell-wrapper'>
              <div className='table-item table-item__text'>
                {phone && (
                  <Phone phone={`${phone[0] !== '+' ? '+' : ''}${phone}`} />
                )}
              </div>
            </div>
          </div>
          <Cell.Created user={user} extra={renderExtra()} />
        </>
      )}
    </div>
  )
}

const mapStateToProps = state => ({
  ...state.userList,
  location: getLocation(state),
  loggedUser: getUser(state),
})

const mapDispatchToProps = { toggle, push, update, updateUser, remove, approve }

export default compose(
  withTranslation('User'),
  connect(mapStateToProps, mapDispatchToProps)
)(UserListItem)
