// @flow

import * as actions from './RequestTranslate.actionTypes'

const initialState = {
  initiated: false,
  working: false,
  languages: [],
  translations: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.REQUEST_TRANSLATOR_LOAD_LANGUAGES:
      return {
        ...initialState,
        working: true,
      }
    case actions.REQUEST_TRANSLATE_LANGUAGES_WERE_LOADED:
      return {
        ...state,
        initiated: true,
        working: false,
        languages: action.languages,
      }
    case actions.REQUEST_TRANSLATE:
      return {
        ...state,
        working: true,
      }
    case actions.REQUEST_WAS_TRANSLATED:
      return {
        ...state,
        working: false,
        translations: { title: action.title, text: action.text },
      }
    case actions.REQUEST_TRANSLATIONS_RESET:
      return {
        ...state,
        translations: null,
      }
    default:
      return state
  }
}
