// @flow

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Textarea from 'react-textarea-autosize'
import update from 'immutability-helper'
import { filter, isEqual } from 'lodash-es'
import type { Node } from 'react'

import Button from '../../Button'
import Modal from '../../Modal'
import ModalBody from '../../Modal/ModalBody'
import ModalButtons from '../../Modal/ModalButtons'
import ModalCloseButton from '../../Modal/ModalCloseButton'
import ModalHead from '../../Modal/ModalHead'
import ModalRow from '../../Modal/ModalRow'
import { useOverflow } from '../../../hooks/useOverflow'
import {
  createPreferredCommunication,
  getPreferredCommunication,
} from '../../../core/api/api.preferredCommunication'
import PreferredCommunicationListItem from './PreferredCommunicationList/PreferredCommunicationListItem'
import PreferredCommunicationList from './PreferredCommunicationList'

import styles from './PreferredCommunicationTypeTableRow.module.scss'

type Props = { onClose: Function }

const PreferredCommunicationModal = (props: Props): Node => {
  const { t } = useTranslation('Profile')

  const [referredCommunications, setPreferredCommunications] = useState(null)
  const [initialPreferredCommunications, setInitialPreferredCommunications] =
    useState(null)
  const [value, setValue] = useState('')
  const [editingPreferredCommunications, setEditingPreferredCommunications] =
    useState(null)

  useEffect(() => {
    getPreferredCommunication().then(data => {
      setPreferredCommunications(data.results.objects)
      setInitialPreferredCommunications(data.results.objects)
    })
  }, [])

  useOverflow()

  const handleChangeText = e => setValue(e.target.value)

  const handleReplace = (id, targetIndex) => {
    const from = referredCommunications.findIndex(i => i.id === id)

    if (from === targetIndex) {
      return
    }

    const proirity = referredCommunications[from]

    const updated = update(referredCommunications, {
      $splice: [
        [from, 1],
        [targetIndex, 0, proirity],
      ],
    })

    setPreferredCommunications(
      updated.map((u, i) => ({ ...u, position: i + 1 }))
    )
  }

  if (!referredCommunications || !initialPreferredCommunications) {
    return null
  }

  const hasChanges = () => {
    if (
      initialPreferredCommunications.length !== referredCommunications.length ||
      value
    ) {
      return true
    }

    let changed = filter(
      referredCommunications,
      (referredCommunications, index) =>
        !isEqual(referredCommunications, initialPreferredCommunications[index])
    )

    return !!changed.length
  }

  const handleClose = () => {
    if (hasChanges()) {
      setPreferredCommunications(initialPreferredCommunications)
      setValue('')
    } else {
      props.onClose()
    }
  }

  const handleSubmit = () => {
    const create = value
      ? value
          .split('\n')
          .map(s => s.trim())
          .filter(Boolean)
      : []

    createPreferredCommunication({
      create,
      update: referredCommunications,
    }).finally(() => props.onClose())
  }

  const handleRemove = id => {
    setPreferredCommunications(
      referredCommunications.filter(
        referredCommunication => referredCommunication.id !== id
      )
    )
    setEditingPreferredCommunications(null)
  }

  const handleSave = (item, index) => {
    setPreferredCommunications(
      update(referredCommunications, { [index]: { $set: item } })
    )
    setEditingPreferredCommunications(null)
  }

  return (
    <Modal isOpen className={styles.modal} onRequestClose={props.onClose}>
      <ModalHead title={t('PreferredCommunication')} />
      <ModalCloseButton onClose={props.onClose} />
      <ModalBody>
        {!referredCommunications && (
          <ModalRow>
            <div className={styles.form}>
              <label className={styles.title}>
                {t('PreferredCommunicationName')}
              </label>
              <input disabled value={t('PreferredCommunication')} />
            </div>
          </ModalRow>
        )}
        {referredCommunications && !!referredCommunications.length && (
          <ModalRow>
            <div className={styles.form}>
              <span className={styles.title}>
                {t('PreferredCommunicationList')}
              </span>
              <PreferredCommunicationList>
                {referredCommunications.map((referredCommunication, index) => (
                  <PreferredCommunicationListItem
                    index={index}
                    isEditing={
                      editingPreferredCommunications ===
                      referredCommunication.id
                    }
                    payment={referredCommunication}
                    key={referredCommunication.id}
                    onRemove={handleRemove}
                    onReplace={handleReplace}
                    onSave={handleSave}
                    onEdit={setEditingPreferredCommunications}
                  />
                ))}
              </PreferredCommunicationList>
            </div>
          </ModalRow>
        )}
        <ModalRow>
          <div className={styles.form}>
            <span className={styles.title}>
              {t('AddPreferredCommunication')}
            </span>
            <Textarea
              autoFocus
              maxRows={5}
              className={styles.textarea}
              value={value}
              placeholder={t('AddPreferredCommunicationPlaceholder')}
              maxLength={128}
              onChange={handleChangeText}
            />
          </div>
        </ModalRow>
      </ModalBody>
      <ModalButtons>
        <Button.Save disabled={!hasChanges()} onClick={handleSubmit}>
          {t('Common:Save')}
        </Button.Save>
        <Button.Cancel onClick={handleClose}>
          {t('Common:Cancel')}
        </Button.Cancel>
      </ModalButtons>
    </Modal>
  )
}

export default PreferredCommunicationModal
