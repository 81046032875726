// @flow

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Textarea from 'react-textarea-autosize'
import update from 'immutability-helper'
import { filter, isEqual } from 'lodash-es'
import type { Node } from 'react'

import Button from '../../Button'
import Modal from '../../Modal'
import ModalBody from '../../Modal/ModalBody'
import ModalButtons from '../../Modal/ModalButtons'
import ModalCloseButton from '../../Modal/ModalCloseButton'
import ModalHead from '../../Modal/ModalHead'
import ModalRow from '../../Modal/ModalRow'
import { useOverflow } from '../../../hooks/useOverflow'
import { createRoomType, getRoomType } from '../../../core/api/api.room'
import RoomListItem from './RoomList/RoomListItem'
import RoomList from './RoomList'

import styles from './RoomTypeModal.module.scss'
import classnames from 'classnames'
type Props = { onClose: Function }

const RoomTypeModal = (props: Props): Node => {
  const { t } = useTranslation('Flat')

  const [roomTypes, setRoomTypes] = useState(null)
  const [initialRoomTypes, setInitialRoomTypes] = useState(null)
  const [value, setValue] = useState('')
  const [editingRoomTypes, setEditingRoomTypes] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    getRoomType().then(data => {
      setRoomTypes(data.results.objects)
      setInitialRoomTypes(data.results.objects)
    })
  }, [])

  useEffect(() => {
    if (value.length > 500) {
      setError(t('Errors:Ensure_this_field_has_no_more_than_500_characters'))
    } else {
      setError(null)
    }
  }, [value])
  useOverflow()

  const handleChangeText = e => setValue(e.target.value)

  const handleReplace = (id, targetIndex) => {
    const from = roomTypes.findIndex(i => i.id === id)

    if (from === targetIndex) {
      return
    }

    const proirity = roomTypes[from]

    const updated = update(roomTypes, {
      $splice: [
        [from, 1],
        [targetIndex, 0, proirity],
      ],
    })

    setRoomTypes(updated.map((u, i) => ({ ...u, position: i + 1 })))
  }

  if (!roomTypes || !initialRoomTypes) {
    return null
  }

  const hasChanges = () => {
    if (initialRoomTypes.length !== roomTypes.length || value) {
      return true
    }

    let changed = filter(
      roomTypes,
      (roomTypes, index) => !isEqual(roomTypes, initialRoomTypes[index])
    )

    return !!changed.length
  }

  const handleClose = () => {
    if (hasChanges()) {
      setRoomTypes(initialRoomTypes)
      setValue('')
    } else {
      props.onClose()
    }
  }

  const handleSubmit = () => {
    const create = value
      ? value
          .split('\n')
          .map(s => s.trim())
          .filter(Boolean)
      : []

    createRoomType({ create, update: roomTypes }).finally(() => props.onClose())
  }

  const handleRemove = id => {
    setRoomTypes(roomTypes.filter(roomType => roomType.id !== id))
    setEditingRoomTypes(null)
  }

  const handleSave = (item, index) => {
    setRoomTypes(update(roomTypes, { [index]: { $set: item } }))
    setEditingRoomTypes(null)
  }

  const textareaClassName = classnames(styles.textarea, {
    'modal__text--error': error,
  })

  return (
    <Modal isOpen className={styles.modal} onRequestClose={props.onClose}>
      <ModalHead title={t('AddRoomTypeTitle')} />
      <ModalCloseButton onClose={props.onClose} />
      <ModalBody>
        {roomTypes && !!roomTypes.length && (
          <ModalRow>
            <div className={styles.form}>
              <span className={styles.title}>{t('RoomTypeList')}</span>
              <RoomList>
                {roomTypes.map((roomType, index) => (
                  <RoomListItem
                    index={index}
                    isEditing={editingRoomTypes === roomType.id}
                    payment={roomType}
                    key={roomType.id}
                    onRemove={handleRemove}
                    onReplace={handleReplace}
                    onSave={handleSave}
                    onEdit={setEditingRoomTypes}
                  />
                ))}
              </RoomList>
            </div>
          </ModalRow>
        )}
        <ModalRow>
          <div className={styles.form}>
            <span className={styles.title}>{t('AddRoomType')}</span>
            <Textarea
              autoFocus
              maxRows={5}
              className={textareaClassName}
              value={value}
              placeholder={t('AddRoomTypePlaceholder')}
              onChange={handleChangeText}
            />
            <div className='input--error input--large'>
              <div className='input__msg'>{error}</div>
            </div>
          </div>
        </ModalRow>
      </ModalBody>
      <ModalButtons>
        <Button.Save disabled={!hasChanges() || error} onClick={handleSubmit}>
          {t('Common:Save')}
        </Button.Save>
        <Button.Cancel onClick={handleClose}>
          {t('Common:Cancel')}
        </Button.Cancel>
      </ModalButtons>
    </Modal>
  )
}

export default RoomTypeModal
