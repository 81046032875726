// @flow

import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Draggable from 'react-draggable'
import { ResizableBox } from 'react-resizable'
import { get } from 'lodash-es'
import Progress from 'antd/es/progress'
import { useSelector } from 'react-redux'
import classnames from 'classnames'
import type { ElementRef } from 'react'

import { getThemeColor } from '../../utils/commonSelectors'
import Button from '../Button'
import PageContainer from './PageContainer'
import {
  setStep3,
  setStep2,
  getDocumentMapping,
} from '../../core/api/api.documentMapping'
import { getStatus } from './MappedDocument.utils'
import {
  RULE_BOX_DEFAULT_HEIGHT,
  RULE_BOX_DEFAULT_WIDTH,
  RULE_MIN_WIDTH,
  RULE_MIN_HEIGHT,
  RULE_TRANSLATION_KEYS,
} from './MappedDocument.constants'
import DocumentContainer from './DocumentContainer'
import NewSelectSimple from '../NewSelectSimple'

import 'react-resizable/css/styles.css'

import styles from './MappedDocument.module.scss'

type Props = {
  fileOrigin: string,
  id: string,
  mappingRules: Array<Object>,
  pageCount: Number,
  pageRef: ElementRef<typeof PageContainer>,
  setDocument: Object => void,
}

const Step2 = (props: Props) => {
  const { t } = useTranslation('DocumentMapping')
  const color = useSelector(state => getThemeColor(state))
  const documentMatchingRules = useSelector(
    state => state.init.document_matching_rules
  )

  const { fileOrigin, id, pageCount, pageRef, mappingRules } = props

  const prevPage = get(mappingRules, ['0', 'page'], 1)
  const prevOptionValue = get(mappingRules, ['0', 'rule'], 'external_id')
  const prevCoordX = get(mappingRules, ['0', 'bbox', '0'], 0)
  const prevCoordY = get(mappingRules, ['0', 'bbox', '1'], 0)
  const prevCoordX2 = get(
    mappingRules,
    ['0', 'bbox', '2'],
    RULE_BOX_DEFAULT_WIDTH
  )
  const prevCoordY2 = get(
    mappingRules,
    ['0', 'bbox', '3'],
    RULE_BOX_DEFAULT_HEIGHT
  )

  const [width, setWidth] = useState(0)

  useEffect(() => {
    const offsetWidth = get(pageRef, ['current', 'offsetWidth'])

    if (offsetWidth) {
      setWidth(offsetWidth - 2)
    }
  }, [])

  const options = documentMatchingRules.map(rule => ({
    value: rule,
    label: t(RULE_TRANSLATION_KEYS[rule]),
  }))

  const prevOption = options.find(option => option.value === prevOptionValue)

  const [option, setOption] = useState(prevOption)
  const [resizing, setResizing] = useState(false)
  const [page, setPage] = useState(prevPage)
  const [originWidth, setOriginWidth] = useState(0)
  const [dragging, setDragging] = useState(false)
  const [progress, setProgress] = useState(null)
  const [checkedBox, setCheckedBox] = useState(null)
  const [checkedPage, setCheckedPage] = useState(null)
  const [checkedRule, setCheckedRule] = useState(null)
  const [loading, setLoading] = useState(true)

  const getRatio = () => originWidth / width

  const [cooords, setCoords] = useState({ x: 0, y: 0 })

  const [size, setSize] = useState({
    width: RULE_BOX_DEFAULT_WIDTH,
    height: RULE_BOX_DEFAULT_HEIGHT,
  })

  const [isChecked, setChecked] = useState(false)
  const [checking, setChecking] = useState(false)

  useEffect(() => {
    if (
      prevCoordX ||
      prevCoordY ||
      RULE_BOX_DEFAULT_WIDTH !== prevCoordX2 ||
      RULE_BOX_DEFAULT_HEIGHT !== prevCoordY2
    ) {
      setCoords({ x: prevCoordX / getRatio(), y: prevCoordY / getRatio() })
      setSize({
        width: (prevCoordX2 - prevCoordX) / getRatio(),
        height: (prevCoordY2 - prevCoordY) / getRatio(),
      })
    }
  }, [originWidth])

  const getBbox = () => [
    parseInt(cooords.x * getRatio()),
    parseInt(cooords.y * getRatio()),
    parseInt((cooords.x + size.width) * getRatio()),
    parseInt((cooords.y + size.height) * getRatio()),
  ]

  const handleClick = () => {
    setChecking(true)

    setStep3(id, {
      mapping_rules: [
        {
          page: checkedPage,
          bbox: checkedBox,
          rule: checkedRule,
        },
      ],
    })
      .then((data: Object) => getStatus(data.eventId))
      .then(() => getDocumentMapping(id))
      .then(data => {
        setChecking(false)
        props.setDocument(data)
      })
  }

  const handleCheck = () => {
    setChecking(true)

    setStep2(id, {
      mapping_rule: {
        page,
        bbox: getBbox(),
        rule: option.value,
      },
    })
      .then((data: Object) => getStatus(data.eventId))
      .then(() => getDocumentMapping(id))
      .then(response => {
        const matchedUser = get(response, 'matched_user')

        setChecked(!!matchedUser)
        setCheckedBox(getBbox())
        setCheckedPage(page)
        setCheckedRule(option.value)
        setChecking(false)
      })
  }

  const getPercent = () => {
    if (!progress) {
      return 0
    }

    const { total, loaded } = progress

    return (100 / total) * loaded
  }

  const handleResizeStart = e => {
    e.stopPropagation()
    e.preventDefault()

    setResizing(true)
  }

  const handleResizeStop = (a, b) => {
    setSize({ width: b.size.width, height: b.size.height })
    setResizing(false)
  }

  const handleDragStart = e => {
    const targetClass = get(e, ['target', 'className'])

    if (
      resizing ||
      targetClass === 'react-resizable-handle react-resizable-handle-se'
    ) {
      return false
    }

    setDragging(true)
  }

  const handleDrag = () => {
    if (resizing) {
      return false
    }
  }

  const handleDragStop = (a, b) => {
    setCoords({ x: b.x, y: b.y })
    setDragging(false)
  }

  const handleSucces = () => {
    setLoading(false)
    setProgress(null)
  }

  const matchingText = isChecked ? t('SuccessMatching') : t('MatchingNotFound')
  const matchingClass = classnames(
    'alert alert--small alert--block alert--center alert--top',
    { 'alert--success': isChecked, 'alert--error': !isChecked }
  )

  return (
    <>
      <section className='unit unit--default'>
        <div className='settings-personal__form-row'>
          <div className='alert--warning-box'>
            <div className='alert alert--small alert--warning alert--block alert--center alert--top'>
              <p className='alert__text'>{t('Step2Description')}</p>
            </div>
          </div>
        </div>
        <div className='settings-personal__form-row'>
          <div className='settings-personal__form-cell'>
            <label className='settings-personal__form-label'>
              {t('MatchingRule')}
            </label>
          </div>
          <div className='settings-personal__form-cell'>
            <NewSelectSimple
              name='divide-select'
              value={option}
              disabled={checking || loading}
              options={options}
              onChange={setOption}
            />
          </div>
        </div>
        <div className='settings-personal__form-row'>
          <div className='settings-personal__form-cell' />
          {!!checkedBox && (
            <div className='settings-personal__form-cell'>
              <div className={matchingClass}>
                <p className='alert__text'>{matchingText}</p>
              </div>
            </div>
          )}
        </div>
        <div className='settings-personal__submit'>
          <Button.Save disabled={checking || loading} onClick={handleCheck}>
            {t('CheckMatching')}
          </Button.Save>
          <Button.Save
            disabled={!isChecked || checking || loading}
            onClick={handleClick}
          >
            {t('NextStep')}
          </Button.Save>
        </div>
      </section>
      {progress && (
        <Progress
          strokeColor={color}
          percent={getPercent()}
          status='active'
          showInfo={false}
          style={{ padding: '0', paddingTop: '1px' }}
        />
      )}
      <section className='unit unit--default'>
        <div className={styles.container}>
          {!progress && !loading && (
            <Draggable
              allowAnyClick
              position={cooords}
              scale={1}
              disabled={resizing}
              onStart={handleDragStart}
              onDrag={handleDrag}
              onStop={handleDragStop}
            >
              <div style={{ position: 'absolute', zIndex: 1, cursor: 'move' }}>
                <ResizableBox
                  width={size.width}
                  height={size.height}
                  disabled={dragging}
                  minConstraints={[RULE_MIN_WIDTH, RULE_MIN_HEIGHT]}
                  onResizeStart={handleResizeStart}
                  onResizeStop={handleResizeStop}
                />
              </div>
            </Draggable>
          )}
          <DocumentContainer
            fileOrigin={fileOrigin}
            width={width}
            onLoadProgress={setProgress}
            onLoadSuccess={handleSucces}
          >
            <PageContainer
              pageCount={pageCount}
              width={width}
              setPage={setPage}
              setOriginWidth={setOriginWidth}
            />
          </DocumentContainer>
        </div>
      </section>
    </>
  )
}

export default Step2
