// @flow

import React, { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelected } from '../../../hooks'
import type { Node } from 'react'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'

import { getCurrentFilters, getCurrentPage } from '../../../utils/routing'
import { getFormattedDate } from '../../../utils/utils'

import {
  getBuildingGroups,
  getGroupsByBuilding,
  updateBuilding,
  createGroup,
  deleteGroup,
  addGroupManagers,
  deleteBuildingFromGroups,
} from '../../../core/api/api.building'

import EmptyList from '../../EmptyList'
import Loader from '../../Loader'

import Modal from '../../Modal'
import BuildingPopup from '../../modals/BuildingPopup'
import AddGroupManagersModal from './AddGroupManagersModal'
import BuildingGroupPopup from '../../modals/BuildingGroupPopup'

import NewTable from '../../NewTable'
import NewTableHeader from '../../NewTable/NewTableHeader'
import NewTableHeaderRow from '../../NewTable/NewTableHeaderRow'
import NewTableHeaderCell from '../../NewTable/NewTableHeaderCell'
import NewTableBody from '../../NewTable/NewTableBody'
import NewTableBodyRow from '../../NewTable/NewTableBodyRow'
import NewTableBodyCell from '../../NewTable/NewTableBodyCell'
import SelectCustom from '../../Select/SelectCustom'

import BuildingManagers from '../../TableCellUsers'

import Toolbox from '../../Toolbox'
import ToolboxCell from '../../Users/UserList/Toolbox/ToolboxCell'
import Button from '../../Button'
import Checkbox from '../../Checkbox'

import TopPagination from '../../Pagination/TopPagination'
import BottomPagination from '../../Pagination/BottomPagination'

import ConfirmationPopup from '../../modals/ConfirmationPopup'

import Icon from '../../Icon'
import classnames from 'classnames'
import styles from '../TableItem.module.scss'
import { Header, SimpleBlock } from '../../Block'
import Nbsp from '../../NBSP'

type Props = {
  buildingId?: number,
  setGroupCount: number => void,
}

const BuildingGroupList = (props: Props): Node => {
  const { buildingId } = props
  const { t } = useTranslation('Building')
  const dispatch = useDispatch()
  const location = useLocation()
  const [loading, setLoading] = useState(true)
  const [meta, setMeta] = useState({})
  const [permissions, setPermissions] = useState({})
  const [groups, setGroups] = useState([])
  const [modal, setModal] = useState(null)
  const [selectedItems, changeSelected, setSelected, isAllSelected] =
    useSelected()
  const deletedGroups = useRef(0)
  const [deleting, setDeleting] = useState(false)
  const [resetPage, setResetPage] = useState(false)

  useEffect(() => {
    if (loading) {
      const page = resetPage ? 1 : getCurrentPage(location)
      const filters = getCurrentFilters(location)

      const api = buildingId
        ? params => getGroupsByBuilding(buildingId, params)
        : getBuildingGroups

      api({ ...filters, page }).then(data => {
        const { meta, permissions, results } = data
        setMeta(meta)

        if (props.setGroupCount) {
          props.setGroupCount(meta.count)
        }

        setPermissions(permissions)
        setGroups(results.objects)
        setLoading(false)
        setResetPage(false)
      })
    }
  }, [loading])

  useEffect(() => {
    setLoading(true)
    setSelected([])
  }, [location])

  const create = () => {
    setModal(
      <BuildingPopup
        createGroup
        onClose={hideModal}
        onUpdateGroup={handleCreateGroup}
      />
    )
  }

  const addManagers = () => {
    setModal(
      <AddGroupManagersModal onClose={hideModal} onSave={handleAddManagers} />
    )
  }

  const editBuildingGroups = () => {
    setModal(
      <BuildingGroupPopup
        buildingId={buildingId}
        onClose={hideModal}
        onUpdate={handleUpdateBuildingGroups}
      />
    )
  }

  const handleAddManagers = (managers, perform_for_buildings) => {
    const responsible_users = managers.map(m => m.id)
    const building_groups = selectedItems
    addGroupManagers({
      responsible_users,
      building_groups,
      perform_for_buildings,
    }).then(() => {
      hideModal()
      setSelected([])
      setLoading(true)
    })
  }

  const handleCreateGroup = (buildings, name) => {
    createGroup({ name, buildings }).then(() => {
      hideModal()
      setLoading(true)
    })
  }

  const handleRemoveGroups = (ids, perform_for_buildings = false) => {
    hideModal()
    setDeleting(true)

    if (buildingId) {
      deleteBuildingFromGroups(buildingId, {
        building_groups: ids,
        update_responsible_users: perform_for_buildings,
      }).then(() => {
        setDeleting(false)
        checkResetPage()
        setSelected([])
        setLoading(true)
      })
    } else {
      ids.forEach(id => {
        deleteGroup(id, { perform_for_buildings }).then(() => {
          deletedGroups.current++

          if (deletedGroups.current >= selectedItems.length) {
            setDeleting(false)
            deletedGroups.current = 0
            checkResetPage()
            setSelected([])
            setLoading(true)
          }
        })
      })
    }
  }

  const handleUpdateBuildingGroups = (
    building_groups,
    update_responsible_users
  ) => {
    hideModal()
    setDeleting(true)
    updateBuilding(buildingId, {
      building_groups,
      update_responsible_users,
    }).then(() => {
      setDeleting(false)
      checkResetPage()
      setSelected([])
      setLoading(true)
    })
  }

  const checkResetPage = () => {
    const { count, curr_page, limit, page_count } = meta

    if (
      curr_page === page_count &&
      page_count > 1 &&
      (selectedItems.length === count % limit || selectedItems.length === limit)
    ) {
      setResetPage(true)
    }
  }

  const handleGroupProfile = id => dispatch(push(`/building-group/${id}`))

  const hideModal = () => setModal(null)

  const confirmRemove = ids =>
    setModal(
      <ConfirmationPopup
        title={t(
          buildingId
            ? 'BuildingDeleteFromGroupTitle'
            : 'BuildingDeleteTitleGroup'
        )}
        text={t(
          buildingId ? 'BuildingDeleteFromGroupText' : 'BuildingDeleteTextGroup'
        )}
        confirm={t(buildingId ? 'DeleteFromGroup' : 'DeleteGroupPopup')}
        cancel={t('Cancel')}
        isAsync={false}
        onClose={hideModal}
        onOk={() => confirmRemoveManagers(ids)}
      />
    )

  const confirmRemoveManagers = ids =>
    setModal(
      <ConfirmationPopup
        title={t('RemoveManagersTitle')}
        text={t(
          buildingId ? 'RemoveManagersBuildingText' : 'RemoveManagersGroupText'
        )}
        confirm={t('No')}
        cancel={t('RemoveManagersButton')}
        onClose={hideModal}
        onCancel={() => handleRemoveGroups(ids, true)}
        onOk={() => handleRemoveGroups(ids, false)}
      />
    )

  const canDoMassActions = ({
    can_create,
    can_delete,
    can_bulk_add_responsible_users,
    can_edit_building_groups,
  }) =>
    can_create ||
    can_delete ||
    can_bulk_add_responsible_users ||
    can_edit_building_groups

  const renderToolbox = () => {
    return canDoMassActions(permissions) ? (
      <Toolbox
        showSelectAllCheckbox
        itemValueKey='id'
        checked={isAllSelected(groups)}
        items={groups}
        setSelected={setSelected}
      >
        {permissions.can_create && selectedItems.length === 0 && (
          <ToolboxCell>
            <Button.Save disabled={loading} icon='building' onClick={create}>
              {t('AddBuildingGroup')}
            </Button.Save>
          </ToolboxCell>
        )}
        {buildingId &&
          permissions.can_edit_building_groups &&
          selectedItems.length === 0 && (
            <ToolboxCell>
              <Button.Save
                disabled={loading}
                working={deleting}
                icon='building'
                onClick={editBuildingGroups}
              >
                {t('EditBuildingGroups')}
              </Button.Save>
            </ToolboxCell>
          )}
        {selectedItems.length > 0 && (
          <>
            {permissions.can_bulk_add_responsible_users && (
              <ToolboxCell>
                <Button.Save disabled={loading} onClick={addManagers}>
                  {t('AddGroupManagers')}
                </Button.Save>
              </ToolboxCell>
            )}
            {(permissions.can_delete ||
              permissions.can_edit_building_groups) && (
              <ToolboxCell>
                <Button.Remove
                  disabled={loading}
                  working={deleting}
                  onClick={() => confirmRemove(selectedItems)}
                >
                  {t(buildingId ? 'DeleteFromGroup' : 'DeleteGroup')}
                </Button.Remove>
              </ToolboxCell>
            )}
          </>
        )}
        <ToolboxCell isRight>
          <TopPagination
            classes='pager pager--large pager--default-2'
            meta={meta}
          />
        </ToolboxCell>
      </Toolbox>
    ) : null
  }

  const renderLoader = () => <Loader text={false} type='big' />

  const renderEmpty = () => (
    <EmptyList
      btnText={t('Add')}
      canAdd={permissions.can_create}
      icon='building'
      title={t('NoBuildingGroups')}
      onClick={create}
    />
  )

  const renderTable = () => (
    <NewTable className={styles.groupListTable}>
      {renderHeader()}
      {!loading && renderBody()}
    </NewTable>
  )

  const renderHeader = () => (
    <NewTableHeader>
      <NewTableHeaderRow>
        <NewTableHeaderCell
          title={t('GroupName')}
          style={{ width: buildingId ? '40%' : '25%' }}
          sortKey='name'
        />
        <NewTableHeaderCell
          title={t('MainInfo')}
          style={{ width: buildingId ? '20%' : '12%' }}
          sortKey='building_count'
        />
        <NewTableHeaderCell title={t('Managers')} style={{ width: '25%' }} />
        {!buildingId && (
          <>
            <NewTableHeaderCell
              title={t('Flats')}
              style={{ width: '12%' }}
              sortKey='flat_count'
            />
            <NewTableHeaderCell
              title={t('DwellersBalloon')}
              style={{ width: buildingId ? '20%' : '12%' }}
              sortKey='inhabitant_count'
            />
          </>
        )}
        <NewTableHeaderCell
          title={t('Updated')}
          style={{ width: buildingId ? '20%' : '14%' }}
          sortKey='updated'
        />
      </NewTableHeaderRow>
    </NewTableHeader>
  )

  const getExtraOptions = () => [
    {
      value: 'deleteGroup',
      label: t(buildingId ? 'DeleteFromGroup' : 'DeleteGroup'),
      handler: id => confirmRemove([id]),
      icon: 'bin',
    },
  ]

  const renderBody = () => (
    <NewTableBody>
      {groups.map(
        ({
          id,
          name,
          building_count,
          flat_count,
          inhabitant_count,
          responsible_user_objs,
          avatar_obj,
          updated,
        }) => (
          <NewTableBodyRow key={id} onClick={() => handleGroupProfile(id)}>
            <NewTableBodyCell>
              <div
                className={classnames(
                  styles.link,
                  styles.groupName,
                  'table-item'
                )}
              >
                {canDoMassActions(permissions) && (
                  <span>
                    <Checkbox
                      style={{ marginRight: '12px' }}
                      checked={selectedItems.includes(id)}
                      onChange={() => changeSelected(id)}
                    />
                  </span>
                )}
                <span
                  className={classnames(
                    styles.icon,
                    'messages__header-title-symbol-container'
                  )}
                >
                  {avatar_obj?.thumbnail ? (
                    <img src={avatar_obj.thumbnail} />
                  ) : (
                    <Icon id='building' />
                  )}
                </span>
                <span className={classnames(styles.name)}>{name}</span>
              </div>
            </NewTableBodyCell>
            <NewTableBodyCell>
              <div className='table-item table-item__text'>
                {building_count}
              </div>
            </NewTableBodyCell>
            <div
              className={classnames(
                'table__cell table__cell--aligned',
                styles.usersCell
              )}
            >
              <BuildingManagers items={responsible_user_objs} />
            </div>
            {!buildingId && (
              <>
                <NewTableBodyCell>
                  <div className='table-item table-item__text'>
                    {flat_count}
                  </div>
                </NewTableBodyCell>
                <NewTableBodyCell>
                  <div className='table-item table-item__text'>
                    {inhabitant_count}
                  </div>
                </NewTableBodyCell>
              </>
            )}
            <NewTableBodyCell>
              <div className='table-item table-item__text'>
                {getFormattedDate(updated)}
              </div>
              {(permissions.can_delete ||
                permissions.can_edit_building_groups) && (
                <div className='table-extra table-extra--settings'>
                  <SelectCustom
                    options={getExtraOptions()}
                    onClick={e => e.stopPropagation()}
                    onChange={opt => opt.handler(id)}
                  />
                </div>
              )}
            </NewTableBodyCell>
          </NewTableBodyRow>
        )
      )}
    </NewTableBody>
  )

  const renderBottomPaginaton = () => (
    <BottomPagination meta={meta} classes='paginator-2' />
  )

  const renderModal = () => {
    if (!modal) {
      return null
    }

    return (
      <Modal isOpen onRequestClose={hideModal}>
        {modal}
      </Modal>
    )
  }

  if (buildingId) {
    return (
      <>
        {renderToolbox()}
        {loading || groups.length > 0 ? renderTable() : renderEmpty()}
        {loading && renderLoader()}
        {renderBottomPaginaton()}
        {renderModal()}
      </>
    )
  }

  return (
    <SimpleBlock>
      <Header header>
        {`${t('GroupListTitle')}`}
        {!!meta.count && (
          <span className='unit__title-num'>
            <Nbsp />
            {meta.count}
          </span>
        )}
      </Header>
      {renderToolbox()}
      {loading || groups.length > 0 ? renderTable() : renderEmpty()}
      {loading && renderLoader()}
      {renderBottomPaginaton()}
      {renderModal()}
    </SimpleBlock>
  )
}

export default BuildingGroupList
