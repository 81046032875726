// @flow

import { postApi, patchApi, deleteApi, postRaw } from './api.core'

export const createAddress = params => postApi('api/office/', params)
export const updateAddress = (id, params) =>
  patchApi(`api/office/${id}/`, params)
export const removeAddress = id => deleteApi(`api/office/${id}/`)
export const setPrimary = data =>
  postRaw(`api/office/set-primary/`, { id: data.id })
