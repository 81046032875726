// @flow

export const BUILDING_FLATS_INITIATING = 'BUILDING_FLATS_INITIATING'
export const BUILDING_FLATS_INITIATED = 'BUILDING_FLATS_INITIATED'
export const BUILDING_FLATS_SHOW_MODAL = 'BUILDING_FLATS_SHOW_MODAL'
export const BUILDING_FLATS_HIDE_MODAL = 'BUILDING_FLATS_HIDE_MODAL'
export const BUILDING_FLATS_TOGGLE = 'BUILDING_FLATS_TOGGLE'
export const BUILDING_FLATS_TOGGLE_ALL = 'BUILDING_FLATS_TOGGLE_ALL'
export const BUILDING_FLATS_SHOW_PAGINATION = 'BUILDING_FLATS_SHOW_PAGINATION'
export const BUILDING_FLATS_SET_ORDERING = 'BUILDING_FLATS_SET_ORDERING'
export const BUILDING_FLATS_SET_FILTER = 'BUILDING_FLATS_SET_FILTER'
export const BUILDING_FLATS_LOAD = 'BUILDING_FLATS_LOAD'
export const BUILDING_FLATS_REMOVE = 'BUILDING_FLATS_REMOVE'
export const BUILDING_FLATS_UPDATE = 'BUILDING_FLATS_UPDATE'
export const BUILDING_FLATS_RELOAD = 'BUILDING_FLATS_RELOAD'
export const BUILDING_FLATS_RELOAD_UPDATE = 'BUILDING_FLATS_RELOAD_UPDATE'
export const BUILDING_FLATS_ERROR = 'BUILDING_FLATS_ERROR'
