// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import SelectCustom from '../../Select/SelectCustom'
import NewTableBodyCell from '../../NewTable/NewTableBodyCell'
import NewTableBodyRow from '../../NewTable/NewTableBodyRow'

type Props = { onEdit: Function, options: Array<Object> }

const LivingTypeTableRow = (props: Props): Node => {
  const { options } = props

  const { t } = useTranslation('Building')

  return (
    <NewTableBodyRow onClick={() => props.onEdit()}>
      <NewTableBodyCell align='space-between'>
        <div className='table-item table-item__text'>
          <span>{t('EditLivingType')}</span>
        </div>
        <div
          className='table-extra'
          style={{
            width: 'auto',
            display: 'flex',
            position: 'static',
            justifyContent: 'center',
            marginLeft: '10px',
          }}
        >
          <SelectCustom
            options={options}
            onChange={option => option.onClick()}
          />
        </div>
      </NewTableBodyCell>
    </NewTableBodyRow>
  )
}

export default LivingTypeTableRow
