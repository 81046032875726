// @flow

import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import type { Location } from 'react-router-dom'

import { usePathname } from '../../hooks/usePathname'
import UserList from './UserList'
import UserInfo from './UserInfo'
import { getCurrentFilters } from '../../utils/routing'
import { load, init, cleanUp, infoCleanUp } from './Users.actions'

type Params = {
  page: string,
  [string]: string,
}

export type Action = { params?: Params, type: string }

type ActionCreator = (?Params) => Action

type Props = {
  cleanUp: ActionCreator,
  infoCleanUp: ActionCreator,
  init: ActionCreator,
  load: ActionCreator,
  location: Location,
}

const Users = (props: Props) => {
  const { location } = props

  const [isMounted, setInit] = useState(false)

  const isLocked = usePathname()
  const { groupId: buildingGroup, search } = useParams()

  const dispatch = useDispatch()

  const getParams = () => {
    const filters = getCurrentFilters(location)

    return {
      ...filters,
      buildingGroup,
    }
  }

  const loadUsers = () => {
    dispatch(load(getParams(), search ? decodeURIComponent(search) : undefined))
  }

  useEffect(() => {
    if (isLocked) {
      return
    }

    dispatch(init(getParams(), search ? decodeURIComponent(search) : undefined))
    setInit(true)

    return function clean() {
      dispatch(cleanUp())
      dispatch(infoCleanUp())
    }
  }, [isLocked])

  useEffect(() => {
    if (isMounted && !isLocked && location.search) {
      loadUsers()
    }
  }, [location.search, search, isLocked])

  return (
    <div className='content__col'>
      {!search && <UserInfo />}
      <UserList loadUsers={loadUsers} search={search} />
    </div>
  )
}

export default Users
