// @flow

import React from 'react'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

type arg = {
  size: string,
  text: string,
  title: string,
}

const Stub = ({ size, title, text }: arg): Node => {
  const { t } = useTranslation('PublicBuildingPage')

  const className = classnames('public-building-page__stub', {
    'public-building-page__stub-s': size === 's',
    'public-building-page__stub-m': size === 'm',
    'public-building-page__stub-l': size === 'l',
  })

  const defaultMessage = {
    title: t('sorry'),
    text: t('service temporarily out of order'),
  }

  return (
    <div className={className}>
      <div className={`public-building-page__stub-${size}-image`}>
        <img src='/static/images/dead_monitor.png' alt='something went wrong' />
      </div>
      <div className={`public-building-page__stub-${size}-description`}>
        <h4>{title || defaultMessage.title}</h4>
        <p>{text || defaultMessage.text}</p>
      </div>
    </div>
  )
}

Stub.defaultProps = {
  size: 's',
}

export default Stub
