// @flow

import React from 'react'
import type { Node } from 'react'

import styles from './Content.module.scss'

type Props = {
  children: Node,
}

const Content = (props: Props): Node => {
  const { children } = props

  return <div className={styles.content}>{children}</div>
}

export default Content
