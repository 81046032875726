// @flow

import React from 'react'
import type { Node } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../../Button'

import styles from './Actions.module.scss'

type Props = {
  additional?: ?Node,
  isValid: boolean,
  onCancel: (SyntheticEvent<HTMLButtonElement>) => void,
  onSave?: (SyntheticEvent<HTMLButtonElement>) => void,
  saveText?: string,
  showCancel: boolean,
  withChanges: boolean,
  working: boolean,
}

const SettingsActions = (props: Props): Node => {
  const { withChanges, working, additional, isValid, saveText, showCancel } =
    props

  const { t } = useTranslation('Common')

  const saveButtonProps = Object.assign(
    {},
    props.onSave ? { onClick: props.onSave } : { type: 'submit' }
  )

  return (
    <div className={styles.container}>
      <Button.Save
        disabled={!withChanges || !isValid || working}
        working={working}
        {...saveButtonProps}
      >
        {saveText || t('Save')}
      </Button.Save>
      {showCancel && (
        <Button.Cancel
          disabled={!withChanges || working}
          onClick={props.onCancel}
        >
          {t('Cancel')}
        </Button.Cancel>
      )}
      {additional}
    </div>
  )
}

SettingsActions.defaultProps = {
  isValid: true,
  showCancel: true,
}

export default SettingsActions
