/**
 * @flow
 * @relayHash 9f512f39a50ad77a020ce83a44a48960
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type OfferProfile_offer$ref = any;
export type OfferProfilePageQueryVariables = {|
  offerID: string
|};
export type OfferProfilePageQueryResponse = {|
  +offer: ?{|
    +provider: {|
      +contacts: ?$ReadOnlyArray<?{|
        +type: ?string,
        +value: ?string,
        +verboseName: ?string,
      |}>
    |},
    +appliedAttributes: ?any,
    +$fragmentRefs: OfferProfile_offer$ref,
  |}
|};
export type OfferProfilePageQuery = {|
  variables: OfferProfilePageQueryVariables,
  response: OfferProfilePageQueryResponse,
|};
*/


/*
query OfferProfilePageQuery(
  $offerID: ID!
) {
  offer(id: $offerID) {
    ...OfferProfile_offer_4DYe2E
    provider {
      contacts {
        type
        value
        verboseName
      }
      id
    }
    appliedAttributes
    id
  }
}

fragment OfferProfile_offer_4DYe2E on OfferNode {
  id
  name
  description
  finalPrice
  priceType {
    name
    id
  }
  gallery {
    id
    origin
    default
    alt
    thumbnails {
      size
      url
    }
  }
  provider {
    id
    name
    contacts {
      type
      value
      verboseName
    }
    image {
      origin
      alt
      thumbnails {
        size
        url
      }
      id
    }
  }
  appliedAttributes
  enabled
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "offerID",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "offerID"
  }
],
v2 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "contacts",
  "storageKey": null,
  "args": null,
  "concreteType": "ContactObjectType",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "type",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "value",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "verboseName",
      "args": null,
      "storageKey": null
    }
  ]
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "appliedAttributes",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "origin",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "alt",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "thumbnails",
  "storageKey": null,
  "args": null,
  "concreteType": "Thumbnail",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "size",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "url",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "OfferProfilePageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "offer",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "OfferNode",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "provider",
            "storageKey": null,
            "args": null,
            "concreteType": "ProviderNode",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ]
          },
          (v3/*: any*/),
          {
            "kind": "FragmentSpread",
            "name": "OfferProfile_offer",
            "args": [
              {
                "kind": "Variable",
                "name": "offerID",
                "variableName": "offerID"
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "OfferProfilePageQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "offer",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "OfferNode",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "description",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "finalPrice",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "priceType",
            "storageKey": null,
            "args": null,
            "concreteType": "PriceTypeNode",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v4/*: any*/)
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "gallery",
            "storageKey": null,
            "args": null,
            "concreteType": "GalleryImageNode",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v6/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "default",
                "args": null,
                "storageKey": null
              },
              (v7/*: any*/),
              (v8/*: any*/)
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "provider",
            "storageKey": null,
            "args": null,
            "concreteType": "ProviderNode",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "image",
                "storageKey": null,
                "args": null,
                "concreteType": "ProviderImageNode",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v4/*: any*/)
                ]
              }
            ]
          },
          (v3/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "enabled",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "OfferProfilePageQuery",
    "id": null,
    "text": "query OfferProfilePageQuery(\n  $offerID: ID!\n) {\n  offer(id: $offerID) {\n    ...OfferProfile_offer_4DYe2E\n    provider {\n      contacts {\n        type\n        value\n        verboseName\n      }\n      id\n    }\n    appliedAttributes\n    id\n  }\n}\n\nfragment OfferProfile_offer_4DYe2E on OfferNode {\n  id\n  name\n  description\n  finalPrice\n  priceType {\n    name\n    id\n  }\n  gallery {\n    id\n    origin\n    default\n    alt\n    thumbnails {\n      size\n      url\n    }\n  }\n  provider {\n    id\n    name\n    contacts {\n      type\n      value\n      verboseName\n    }\n    image {\n      origin\n      alt\n      thumbnails {\n        size\n        url\n      }\n      id\n    }\n  }\n  appliedAttributes\n  enabled\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5cb775ece5465c0b2dceb4bb91bec6e9';
module.exports = node;
