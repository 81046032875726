// @flow

import React from 'react'

import type { Address } from '../AddressSection.reducer'

import { useTranslation } from 'react-i18next'

import Map from '../../../Map/Map'
import Icon from '../../../../../static/icons/map.svg'

type Props = {
  address: Address,
  balloon: Node,
  city: any,
  collapsed: boolean,
  edit: () => void,
  editable: boolean,
  editMode: boolean,
  isCreating: boolean,
  onMapReady: () => void,
  updateAddress: (address: string) => void,
}

const AddressSectionMap = ({
  address,
  collapsed,
  editable,
  editMode,
  isCreating,
  onMapReady,
  updateAddress,
  city: [currentCity],
  edit,
  balloon,
}: Props): React.Element => {
  const { t } = useTranslation('Common')

  const {
    lattitude,
    longitude,
    widget_zoom: widgetZoom,
    widget_lattitude_str: widgetLatitudeStr,
    widget_longitude_str: widgetLongitudeStr,
  } = address

  const mapProps = {
    onMapReady: onMapReady,
    collapsed,
    editMode,
    onChangeAddress: updateAddress,
    zoom: widgetZoom,
  }

  const mapCenter = {
    lat: parseFloat(widgetLatitudeStr),
    lng: parseFloat(widgetLongitudeStr),
  }

  // our backend can send "none" values in string , gg

  if (mapCenter.lat && mapCenter.lng) {
    mapProps.center = mapCenter
  } else {
    mapProps.center =
      currentCity && currentCity.lattitude && currentCity.longitude
        ? {
            lat: currentCity.lattitude,
            lng: currentCity.longitude,
          }
        : null
  }

  if (lattitude && longitude) {
    mapProps.buildingMarker = {
      name: 'buildingMarker',
      position: {
        lat: parseFloat(lattitude),
        lng: parseFloat(longitude),
      },
    }
  }

  if (balloon && mapProps.buildingMarker) {
    mapProps.buildingMarker.showBalloon = true
    mapProps.buildingMarker.balloon = balloon
  }

  return (
    <>
      <Map {...mapProps} />
      {!editMode && isCreating && (
        <div className='u-a19__overlay'>
          <Icon className='u-a19__icon' />
          <span className='u-a19__heading'>{t('Common:NoAddress')}</span>
          {editable && (
            <button
              type='button'
              className='u-a19__button button button--large button--outline-invert'
              style={{ marginRight: 0 }}
              onClick={edit}
            >
              {t('Common:SetAddress')}
            </button>
          )}
        </div>
      )}
    </>
  )
}

export default AddressSectionMap
