// @flow

import React from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import Button from '../../Button'
import { EDIT_FROM_WIDGET } from '../../../containers/RequestView/RequestView.constants'

type Props = {
  item: {
    avatar: string,
    email: string,
    id: number,
    name: string,
  },
}

const ProviderListItem = (props: Props) => {
  const {
    item,
    item: { id, name, avatar },
  } = props

  const dispatch = useDispatch()

  const openProvidersPopup = () => {
    dispatch({ type: EDIT_FROM_WIDGET, contractor: item })
  }

  return (
    <div
      className='widget-new-usermeta'
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Link
        to={`/contractor/${id}`}
        onClick={e => {
          e.stopPropagation()
        }}
      >
        <span>
          <span className='widget-new-usermeta__userpic'>
            <img
              src={
                avatar ||
                'https://files.idwell.com/sys/thumbnail/170_default_avatar.png' // TODO change to default image from back
              }
            />
          </span>
          <span className='widget-new-usermeta__username'>
            <span title={name}>{name}</span>
          </span>
        </span>
      </Link>
      <Button.Add name='addProvider' onClick={openProvidersPopup} />
    </div>
  )
}

export default ProviderListItem
