// @flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import RouterTabs from '../Tabs/RouterTabs'
import Pan from '../Tabs/Pan'
import { SimpleBlock, Header } from '../Block'

import AllProviders from '../../containers/ProvidersPage/AllProviders'
import FavoriteProviders from '../../containers/ProvidersPage/FavoriteProviders'
import Offers from '../../containers/Offers'
import Orders from '../../containers/Orders'

const Marketplace = ({ location }) => {
  const { t } = useTranslation('Menu')
  const [allCount, setAllCount] = useState(0)
  const [favsCount, setFavsCount] = useState(0)
  const [ordersCount, setOrdersCount] = useState(0)
  const [offersCount, setOffersCount] = useState(0)

  return (
    <div className='content__col'>
      <SimpleBlock>
        <Header header className='mail-section__title'>
          {t('Offers')}
        </Header>
        <RouterTabs openFirst='offers'>
          <Pan
            id='all-providers'
            label={`${t('AllProviders')} ${allCount || ''}`}
            linkHash='#all-providers'
          >
            <AllProviders location={location} setCount={setAllCount} />
          </Pan>
          <Pan
            id='providers'
            label={`${t('Favorites')} ${favsCount || ''}`}
            linkHash='#providers'
          >
            <FavoriteProviders location={location} setCount={setFavsCount} />
          </Pan>
          <Pan
            id='offers'
            label={`${t('Offers')} ${offersCount || ''}`}
            linkHash='#offers'
          >
            <Offers location={location} setCount={setOffersCount} />
          </Pan>
          <Pan
            id='orders'
            label={`${t('Orders')} ${ordersCount || ''}`}
            linkHash='#orders'
          >
            <Orders location={location} setCount={setOrdersCount} />
          </Pan>
        </RouterTabs>
      </SimpleBlock>
    </div>
  )
}

export default Marketplace
