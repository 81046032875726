// @flow

import React from 'react'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import { intersection, isBoolean } from 'lodash-es'
import type { Node } from 'react'

import styles from './Nav.module.scss'

type Props = {
  read?: boolean,
  selectedTab: string,
  tab: Object,
  tabNames: Array<string>,
  url: string,
  urlTabList: Array<string>,
}

function getLink(value, url, urlTabList, tabNames) {
  const replacingUrl = intersection(urlTabList, tabNames)[0]

  const nextTabList = urlTabList.map(tab => {
    if (tab === replacingUrl) {
      return value
    }

    return tab
  })

  return `${url}${nextTabList.join('/')}/`
}

const Nav = (props: Props): Node => {
  const {
    tab: { value, label },
    selectedTab,
    url,
    urlTabList,
    tabNames,
    read,
  } = props

  const tabClass = classnames(styles.tab, {
    [styles.active]: selectedTab === value,
    [styles.unread]: isBoolean(read) && !read,
  })

  return (
    <div className={tabClass}>
      <Link to={getLink(value, url, urlTabList, tabNames)}>
        <span>{label}</span>
      </Link>
    </div>
  )
}

export default Nav
