// @flow

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import classnames from 'classnames'
import { compose } from 'redux'

import { Cell, CellsBar, Header, SimpleBlock } from '../../components/Block'
import Button from '../../components/Button'
import Loader from '../../components/Loader'
import { EntityIcon } from '../../components/Icon'
import { formatDate, isAdminUser } from '../../utils/utils'
import { get, update } from './MarketplaceSettings.actions'
import { getUser } from '../../utils/commonSelectors'

import styles from './MarketplaceSettings.module.scss'

type Props = {
  get: () => { type: 'MARKETPLACE_SETTINGS_INITIATE' },
  is_active: boolean,
  show_secondary_mappings: boolean,
  t: string => string,
  update: ({ is_active: boolean }) => {
    params: { is_active: boolean },
    type: 'MARKETPLACE_SETTINGS_UPDATE',
  },
  updated: ?string,
  updating: boolean,
  user: Object,
  working: boolean,
}

class MarketplaceSettings extends Component<Props> {
  componentDidMount() {
    this.props.get()
  }

  componentDidUpdate(prevProps) {
    const { updating } = this.props

    if (!updating && prevProps.updating) {
      // In order to update menu items, it's required to reload the page to get initial data (nicely done!)
      setTimeout(function () {
        window.location.reload()
      }, 1000)
    }
  }

  toggleMarketplaceVisibility = (e: SyntheticEvent<HTMLInputElement>) => {
    const { checked } = e.currentTarget

    this.props.update({ is_active: !!checked })
  }

  render() {
    const { working, user } = this.props

    if (working) {
      return <Loader type='big' text={false} />
    }

    const { updating, updated, is_active: isActive, t } = this.props

    const marketplaceVisibilityText = isActive ? t('mpIsOn') : t('mpIsOff')
    const statusText = isActive ? t('statusIsOn') : t('statusIsOff')
    const toggleCellClassName = classnames(
      styles['header-cell'],
      styles['header-cell-right']
    )

    return (
      <SimpleBlock>
        <Header header>
          <EntityIcon icon large id='marketplace' />
          {t('marketplace')}
        </Header>
        <CellsBar>
          <Cell title={t('updatedAt')}>{formatDate(updated)}</Cell>
          <Cell title={t('status')} className={styles.noBorder}>
            {statusText}
          </Cell>
          {isAdminUser(user) && (
            <Cell className={toggleCellClassName}>
              <Button.Toggle
                checked={isActive}
                disabled={updating}
                onChange={this.toggleMarketplaceVisibility}
              />
              <span className={styles['header-cell-right-value']}>
                {marketplaceVisibilityText}
              </span>
            </Cell>
          )}
        </CellsBar>
      </SimpleBlock>
    )
  }
}

const mapStateToProps = state => ({
  ...state.marketplaceSettings,
  user: getUser(state),
})
const mapDispatchToProps = { get, update }

export default compose(
  withTranslation('Settings'),
  connect(mapStateToProps, mapDispatchToProps)
)(MarketplaceSettings)
