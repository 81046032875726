// @flow

import React, { useRef } from 'react'
import type { Node } from 'react'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useDrop } from 'react-dnd'
import { useDispatch } from 'react-redux'
import { omitBy, isNil } from 'lodash-es'

import Button from '../../../Button'
import { EmptyListIcon } from '../../../Icon'
import type { EmptyListIconId } from '../../../Icon'

import { NativeTypes } from 'react-dnd-html5-backend'
import { FILE_ORIGIN_TYPES } from '../../../../containers/FilesUpload'
import { getChunks } from '../../../NewFilesUpload/NewFilesUpload'
import { globalModalError } from '../../../Layout/Layout.actions'

import styles from './EmptyFilesList.module.scss'

type Props = {
  additionalButton?: Node,
  btnIcon?: string,
  btnText?: string,
  canAdd?: boolean,
  canDrop?: boolean,
  children?: Node,
  className?: string,
  embedded?: boolean,
  folderId?: number,
  icon: EmptyListIconId,
  noShowAddIcon: boolean,
  onClick?: () => void,
  outbound?: string,
  setLoading?: boolean => void,
  setUploading?: boolean => void,
  title: string,
}

const EmptyFilesList = (props: Props) => {
  const {
    children,
    embedded,
    icon,
    title,
    additionalButton,
    btnText,
    canAdd,
    btnIcon,
    noShowAddIcon,
    className,
    canDrop,
    setLoading,
    setUploading,
    folderId,
    outbound,
  } = props

  const ref = useRef(null)
  const dispatch = useDispatch()
  const { t } = useTranslation('Errors')

  const hasBtn = Boolean(props.onClick) && Boolean(btnText) && canAdd
  const showAddSymbol = (hasBtn || Boolean(children)) && !noShowAddIcon
  const boxClass = classnames(styles.container, className, {
    [styles.embedded]: embedded,
    [styles.interactive]: showAddSymbol,
  })

  const [, drop] = useDrop({
    accept: ['file', NativeTypes.FILE],
    canDrop: (props, monitor) => {
      if (!canDrop) {
        return false
      }

      const { files } = monitor.getItem()

      return files?.length
    },
    drop: (item, monitor) => {
      const { files } = monitor.getItem()

      if (Array.isArray(files) && files.length) {
        setUploading(true)
        getChunks(
          files.map(f =>
            omitBy(
              {
                origin: [f],
                type: FILE_ORIGIN_TYPES.default,
                name: f.name,
                parent: folderId,
              },
              isNil
            )
          ),
          null,
          outbound
        ).then(checkedFiles => {
          const badFiles = checkedFiles.filter(f => !f || !f.id)

          if (badFiles.length) {
            dispatch(globalModalError(t('File')))
          } else {
            setLoading(true)
          }

          setUploading(false)
        })
      }
    },
    collect: monitor => ({
      isDropTarget:
        canDrop && monitor.canDrop() && monitor.isOver({ shallow: true }),
    }),
  })
  drop(ref)

  return (
    <div className={boxClass} ref={ref}>
      <EmptyListIcon canAdd={showAddSymbol} id={icon} />
      <h2 className={styles.title}>{title}</h2>
      {Boolean(children) && children}
      {hasBtn && (
        <Button.Save icon={btnIcon} onClick={props.onClick}>
          {btnText}
        </Button.Save>
      )}
      {additionalButton}
    </div>
  )
}

EmptyFilesList.defaultProps = {
  noShowAddIcon: false,
}

export default EmptyFilesList
