// @flow

import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { push } from 'connected-react-router'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import { get } from 'lodash-es'

import Icon from '../../../components/Icon'
import Nbsp from '../../../components/NBSP'
import PostHeader from '../../../components/Post/PostHeader'
import Rating from '../../../components/Rating/Rating'
import Button from '../../../components/Button'
import { RATING_TYPES } from '../../../constants'
import { getUk } from '../../../utils/commonSelectors'
import { sendRating, updateRating } from '../Feedback.actions'
import { isWorking } from '../Feedback.selectors'

import styles from './RatingPost.module.scss'

type Props = {
  created: string,
  t: string => string,
}

class RatingPost extends PureComponent<Props> {
  state = {
    rating: get(this.props.request, ['rating_obj', 'value']),
  }

  openFeedback = e => {
    e.preventDefault()
    this.props.push(this.getLink())
  }

  handleClickRating = value => {
    const { request } = this.props
    const { rating } = this.state
    const ratingId = get(request, ['rating_obj', 'id'])

    this.setState({ rating: value })

    const params = {
      value,
      requests: request.id,
      rating_type: RATING_TYPES.general,
    }

    if (rating) {
      this.props.updateRating(ratingId, { value })
    } else {
      this.props.sendRating(params)
    }
  }

  getLink = () => {
    const { request } = this.props
    const feedbackId = get(request, ['rating_obj', 'feedback'])

    if (feedbackId) {
      return `/request/${request.id}/feedback/`
    }

    return `/request/${request.id}/feedback/create`
  }

  render() {
    const { created, request, isWorking } = this.props
    const { rating } = this.state
    const buttonText = get(request, ['rating_obj', 'feedback'])
      ? 'ViewFeedback'
      : 'CreateFeedback'

    const contentClassName = classnames(
      'post-content post-content--01',
      styles.content
    )
    const titleClassName = classnames(
      'post-content__title u-a15__title',
      styles.title
    )
    const controlClassName = classnames('u-a15__control', styles.control)
    const rootClassNames = classnames(
      'unit unit--default unit--margin home-news-item',
      { 'working-overlay': isWorking }
    )

    return (
      <section className={rootClassNames}>
        <PostHeader date={created} />
        <div className={contentClassName}>
          <div className={titleClassName}>
            <Icon className='u-a15__title-symbol' id='request' />
            <span>
              {this.props.t('RatingPostTitle')}
              <Nbsp />
              {this.props.t('Common:NumberSymbol')}
              <Nbsp />
              {request.request_no}
            </span>
          </div>
          <Rating big label value={rating} onClick={this.handleClickRating} />
        </div>
        <div className={controlClassName}>
          <Link to={this.getLink()} onClick={this.openFeedback}>
            <Button.Save>{this.props.t(buttonText)}</Button.Save>
          </Link>
        </div>
      </section>
    )
  }
}

const mapStateToProps = state => ({
  isWorking: isWorking(state),
  uk: getUk(state),
})

const mapDispatchToProps = {
  push,
  sendRating,
  updateRating,
}

export default compose(
  withTranslation('Feedback'),
  connect(mapStateToProps, mapDispatchToProps)
)(RatingPost)
