// @flow

import React, { useState, useEffect } from 'react'
import type { Node } from 'react'

import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import { useTranslation } from 'react-i18next'

import { createFile, getFile } from '../../../core/api/api.file'
import {
  updateContractor,
  getContractorBuildingOptions,
  getContractorCategoryOptions,
} from '../../../core/api/api.contractor'
import { updateRequest } from '../../../core/api/api.massAction'

import { ALL_PAGES } from '../../../constants'

import { globalModalError } from '../../Layout/Layout.actions'

import { SimpleBlock, Header, CellsBar, Cell } from '../../Block'
import Avatar from '../../AvatarEditable'
import SelectCustom from '../../Select/SelectCustom'
import Button from '../../Button'
import DateTime from '../../DateTime'
import Label from '../../Label'
import Loader from '../../Loader'

import Modal from '../../Modal'
import NameModal from './NameModal'
import ExternalIdModal from '../../Building/ExternalIdModal'

import ConfirmationPopup from '../../modals/ConfirmationPopup'
import BuildingMappingModal from '../../../containers/ProvidersPage/AllProviders/BuildingMappingModal'
import CategoryMappingModal from '../../../containers/ProvidersPage/AllProviders/CategoryMappingModal'

import styles from './ContractorInfo.module.scss'

type Props = {
  conractor: Object,
  permissions: Object,
  setLoading: boolean => void,
}

const ContractorInfo = (props: Props): Node => {
  const {
    contractor: { id, name, created, archived, external_id, avatar },
  } = props
  const { t } = useTranslation('Contractors')
  const dispatch = useDispatch()
  const can_edit = !archived
  const [modal, setModal] = useState(false)
  const [buildingModal, setBuildingModal] = useState(null)
  const [categoryModal, setCategoryModal] = useState(null)
  const [editName, setEditName] = useState(false)
  const [editExtId, setEditExtId] = useState(false)
  const [avatarUploading, setAvatarUploading] = useState(false)
  const [avatarObj, setAvatarObj] = useState(null)

  useEffect(() => {
    setAvatarObj({ origin: avatar, preview: avatar })
  }, [])

  const hideModal = () => setModal(null)
  const handleCloseBuildingModal = () => setBuildingModal(null)
  const handleCloseCategoryModal = () => setCategoryModal(null)

  const getOptions = () => [
    { value: 'setBuildings', label: t('SetBuildings') },
    { value: 'setCategories', label: t('SetCategories') },
    { value: 'editExternalId', label: t('EditExternalId') },
    { value: 'remove', label: t('Archive') },
  ]

  const moreOptions = ({ value }) => {
    switch (value) {
      case 'remove':
        setModal(
          <ConfirmationPopup
            title={t('RemoveTitle')}
            text={t('RemoveOneText', { name })}
            confirm={t('Archive')}
            cancel={t('Cancel')}
            onClose={hideModal}
            onOk={() => setContractorArchived(true)}
          />
        )
        break
      case 'setBuildings':
        handleMatchBuildings()
        break
      case 'setCategories':
        handleMatchCategories()
        break
      case 'editExternalId':
        setEditExtId(true)
        break
      default:
    }
  }

  const setContractorArchived = soft_archived => {
    updateRequest({
      model: 'Profile',
      model_pks: JSON.stringify([id]),
      patch: { soft_archived },
    }).then(() => {
      hideModal()
      props.setLoading(true)
    })
  }

  const handleRestore = () => {
    setModal(
      <ConfirmationPopup
        title={t('RestoreTitle')}
        text={t('RestoreTextOne', { name })}
        confirm={t('Restore')}
        cancel={t('Cancel')}
        onClose={hideModal}
        onOk={() => setContractorArchived(false)}
      />
    )
  }

  const handleUpdateName = name => {
    setEditName(false)
    updateContractor(id, { name }).then(() => props.setLoading(true))
  }

  const handleUpdateExternalId = external_id => {
    updateContractor(id, { external_id })
      .then(() => {
        setEditExtId(false)
        props.setLoading(true)
      })
      .catch(err =>
        dispatch(
          globalModalError(
            err.message?.response?.data?.errors || 'Common:Error',
            '',
            true
          )
        )
      )
  }

  const handleEditAvatar = avatar => {
    if (avatar) {
      setAvatarUploading(true)
      const { dataUrl, filename: name } = avatar
      createFile({ dataUrl, name }).then(file => checkFile(file.id))
    }
  }

  const checkFile = fileId => {
    getFile(fileId).then(data => {
      if (data.checking) {
        setTimeout(() => checkFile(data.id), 500)
      } else {
        updateContractor(id, { avatar: data.id }).then(() => {
          setAvatarObj(data)
          setAvatarUploading(false)
        })
      }
    })
  }

  const handleMatchBuildings = () => {
    getContractorBuildingOptions({ contractor: id, page_size: ALL_PAGES }).then(
      data => {
        setBuildingModal({ global_id: id, buildings: data.results.objects })
      }
    )
  }

  const handleMatchCategories = () => {
    getContractorCategoryOptions({ contractor: id, page_size: ALL_PAGES }).then(
      data => {
        setCategoryModal({ global_id: id, categories: data.results.objects })
      }
    )
  }

  return (
    <SimpleBlock>
      <Header
        header
        goBack={() => dispatch(push(`/contractors`))}
        textTitle={name}
        className={styles.header}
      >
        {avatarUploading ? (
          <Loader type='small' text={false} className={styles.avatarLoader} />
        ) : can_edit && avatarObj ? (
          <div icon='true' className='roundbutton--subtitle'>
            <Avatar
              image={avatarObj}
              title={t('AvatarLoad')}
              style={{ border: '1px solid rgb(224, 224, 224)' }}
              onStartEdit={() => {}}
              onFinishEdit={handleEditAvatar}
            />{' '}
            :
          </div>
        ) : (
          <div
            icon='true'
            className='mprofile-view-user__userpic mprofile-view-user__userpic-building'
          >
            <img src={avatar} />
          </div>
        )}
        <span className={styles.name}>{name}</span>
        {can_edit && <Button.Edit onClick={() => setEditName(true)} />}
        {archived && (
          <Label
            text={t('Archived')}
            style={{ marginLeft: '8px' }}
            type='outlined'
          />
        )}
      </Header>
      <CellsBar>
        <Cell title={t('Created')}>
          <DateTime dateTime={created} />
        </Cell>
        <Cell title={t('Role')}>{t('ExternalContact')}</Cell>
        <Cell title={t('ExternalId')} className={styles.externalId}>
          <div className={styles.externalIdText}>{external_id || '-'}</div>
          {can_edit && <Button.Edit onClick={() => setEditExtId(true)} />}
        </Cell>
      </CellsBar>
      <CellsBar>
        {!archived && (
          <SelectCustom
            alignLeft
            buttonClass='button button--large button--dropdown button--showmore '
            style={{ marginLeft: '8px' }}
            options={getOptions()}
            onChange={moreOptions}
          >
            {t('More')}
          </SelectCustom>
        )}
        {archived && (
          <Button.Save style={{ marginLeft: '8px' }} onClick={handleRestore}>
            {t('Restore')}
          </Button.Save>
        )}
      </CellsBar>
      {editName && (
        <NameModal
          name={name}
          updateName={handleUpdateName}
          onClose={() => setEditName(false)}
        />
      )}
      {editExtId && (
        <ExternalIdModal
          externalId={external_id}
          updateExternalId={handleUpdateExternalId}
          title={t('ExternalId')}
          onClose={() => setEditExtId(false)}
        />
      )}
      {!!modal && (
        <Modal isOpen={!!modal} onRequestClose={hideModal}>
          {modal}
        </Modal>
      )}
      {buildingModal && (
        <BuildingMappingModal
          isContractor
          buildingModal={buildingModal}
          setLoading={props.setLoading}
          onClose={handleCloseBuildingModal}
        />
      )}
      {categoryModal && (
        <CategoryMappingModal
          isContractor
          categoryModal={categoryModal}
          setLoading={props.setLoading}
          onClose={handleCloseCategoryModal}
        />
      )}
    </SimpleBlock>
  )
}

export default ContractorInfo
