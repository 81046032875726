// @flow

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useLocation } from 'react-router-dom'
import { difference, union } from 'lodash-es'

import TopPagination from '../Pagination/TopPagination'
import AddUserPopup from '../modals/AddUserPopup/AddUserPopup'
import ConfirmationPopup from '../modals/ConfirmationPopup'
import Button from '../Button'
import { Header, SimpleBlock } from '../Block'
import ProfileList from './ProfileList'
import Nbsp from '../NBSP'
import { getAllProfileList } from '../../core/api/api.flat'
import { getCurrentPage, getCurrentFilters } from '../../utils/routing'
import Loader from '../Loader'
import Checkbox from '../Checkbox'
import { USER_GROUPS } from '../../constants'
import { update } from '../../core/api/api.profile'
import { updateRequest } from '../../core/api/api.massAction'

const Profiles = props => {
  const {
    flat: {
      soft_archived: softArchived,
      address_obj: { value },
      number,
      building,
    },
  } = props

  const { t } = useTranslation('FlatDwellers')

  const location = useLocation()

  const { flatId } = useParams()

  const [loading, setLoading] = useState(false)
  const [profiles, setProfiles] = useState([])
  const [profileLength, setProfileLength] = useState(0)
  const [meta, setMeta] = useState({})
  const [selected, setSelected] = useState([])
  const [permissions, setPermissions] = useState({})
  const [modal, setModal] = useState(null)

  useEffect(() => {
    setLoading(true)
  }, [location.search, location.pathname])

  useEffect(() => {
    if (loading) {
      const page = getCurrentPage(location)
      const filters = getCurrentFilters(location)
      const params = { ...filters, flat: flatId, page }
      getAllProfileList(params).then(data => {
        setLoading(false)
        setProfiles(data.objects)
        setProfileLength(data.objects.filter(o => !o.archived).length)
        setMeta({
          count: data.count,
          limit: 20,
          offset: 0,
          page_count: data.pages,
          curr_page: parseInt(page),
        })
        setPermissions(data.permissions)
      })
    }
  }, [loading])

  if (loading) {
    return <Loader type='medium' text={false} />
  }

  const handleToggleAll = () => {
    if (profileLength !== selected.length) {
      setSelected(profiles.filter(p => !p.archived).map(p => p.userprofile.id))
    } else {
      setSelected([])
    }
  }

  const handleCheck = id => {
    const isIntersect = selected.includes(id)

    let nextSelected = []

    if (isIntersect) {
      nextSelected = difference(selected, [id])
    } else {
      nextSelected = union(selected, [id])
    }

    setSelected(nextSelected)
  }

  const onSave = () => {
    hideModal()
    setSelected([])
    setLoading(true)
  }

  const showAddUserModal = () => {
    setModal(
      <AddUserPopup
        buildingId={building}
        buildingAddress={value}
        flatNumber={number}
        flatId={flatId}
        onHide={hideModal}
        onSave={onSave}
      />
    )
  }
  const remove = () => {
    updateRequest({
      model: 'Profile',
      model_pks: JSON.stringify(selected),
      patch: {
        rm_from_flat: flatId,
      },
    })
      .then(() => {
        setSelected([])
        setLoading(true)
      })
      .finally(() => hideModal())
  }

  const showRemoveModal = () => {
    setModal(
      <ConfirmationPopup
        title={t('DeleteTitle')}
        text={t('Common:confirmDwellerRemoving')}
        confirm={t('Delete')}
        cancel={t('Cancel')}
        onClose={hideModal}
        onOk={remove}
      />
    )
  }

  const updateRole = (owner, group) => {
    update(owner, { group })
      .then(() => {
        setSelected([])
        setLoading(true)
      })
      .finally(() => hideModal())
  }

  const showUpdateDwellerRoleModal = (profile, role) => {
    const { group, label } = role

    if (
      (profile.role === 'landlord' && group === USER_GROUPS.landlord) ||
      (profile.role !== 'landlord' && group === USER_GROUPS.dweller)
    ) {
      return
    }

    const { name, second_name: surname, id } = profile

    setModal(
      <ConfirmationPopup
        title={t('UpdateRoleTitle')}
        text={t('UpdateRoleText', { name, surname, group: label })}
        confirm={t('Update')}
        cancel={t('Cancel')}
        onClose={hideModal}
        onOk={() => updateRole(id, group)}
      />
    )
  }

  const hideModal = () => setModal(null)

  return (
    <SimpleBlock className='unit unit--default unit--nomargin'>
      <Header header>
        {t('Dwellers')}
        <span className='unit__title-num'>
          <Nbsp />
          {!!meta.count && meta.count}
        </span>
      </Header>
      <div className='toolbox'>
        {!softArchived && permissions.can_delete_user && !!profileLength && (
          <div className='toolbox__cell'>
            <Checkbox
              outer
              checked={!!profileLength && selected.length === profileLength}
              onChange={handleToggleAll}
            />
          </div>
        )}
        {!softArchived &&
          permissions.can_add_user_role &&
          selected.length === 0 && (
            <div className='toolbox__cell'>
              <Button.Save icon='user' onClick={showAddUserModal}>
                {t('AddUser')}
              </Button.Save>
            </div>
          )}
        {!softArchived && permissions.can_delete_user && selected.length > 0 && (
          <div className='toolbox__cell'>
            <Button.Remove onClick={showRemoveModal} />
          </div>
        )}
        {selected.length > 0 && (
          <div className='toolbox__cell'>
            <Button.CreateEmail
              selectedUsers={profiles
                .filter(p => !p.archived)
                .map(p => p.userprofile)
                .filter(up => selected.includes(up.id))}
              params={{ flat_id: flatId }}
            />
          </div>
        )}
        <div className='toolbox__cell toolbox__cell--right'>
          <TopPagination
            classes='pager pager--large pager--default-2'
            meta={meta}
          />
        </div>
      </div>
      <ProfileList
        profiles={profiles}
        showUpdateDwellerRoleModal={showUpdateDwellerRoleModal}
        loading={loading}
        selected={selected}
        meta={meta}
        handleCheck={handleCheck}
        permissions={permissions}
        hideModal={hideModal}
        modal={modal}
        setProfiles={setProfiles}
      />
    </SimpleBlock>
  )
}

export default Profiles
