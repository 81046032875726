// @flow

const header = [
  '#60c78f',
  '#a7e5c7',
  '#55b144',
  '#ebcc02',
  '#f99d3f',
  '#e84f3d',
  '#bb6cdc',
  '#00badb',
  '#fddf9d',
  '#b7b7b7',
  '#5c3967',
  '#5c7595',
  '#c1f5bd',
  '#85be8a',
  '#006eb7',
  '#48e48d',
  '#ff75c7',
  '#444444',
  '#76c9dc',
  '#c1f6be',
  '#616f7e',
  '#5b2d8e',
  '#1e9dee',
  '#989ea3',
  '#414142',
  '#005680',
  '#525e82',
  '#1c6ba6',
  '#cf003d',
  '#dfe6f1',
  '#97c037',
  '#f29400',
  '#e17e68',
  '#4f65a9',
  '#d4d600',
  '#007aad',
  '#9fc2de',
  '#2084ab',
  '#749513',
  '#0060a7',
  '#f98a46',
  '#1b74a6',
  '#70b365',
  '#787a7d',
  '#bfa086',
  '#8c8c90',
  '#72246c',
  '#4bbdcf',
  '#00a086',
  '#636466',
  '#e8405d',
  '#464646',
  '#174a8c',
  '#00a8bc',
  '#27a2e2',
  '#090d54',
  '#16313a',
  '#12375b',
  '#337539',
  '#4166b3',
  '#00234f',
  '#6eb8b0',
  '#000063',
  '#005694',
  '#0474be',
  '#011459',
  '#2e469e',
  '#d7e3f4',
  '#005382',
  '#3a7bc8',
  '#212325',
  '#870000',
  '#003b79',
  '#218dc8',
  '#6f263d',
  '#336aa0',
  '#1d644a',
  '#e10615',
  '#d6a029',
  '#2a4d9c',
  '#292928',
  '#40566e',
  '#1a1a18',
  '#781204',
  '#4a4a4a',
  '#1c3835',
  '#023e84',
  '#0069b4',
  '#7c1e33',
  '#b0b0b0',
  '#01294d',
  '#326295',
  '#333399',
  '#7586bb',
  '#d63d32',
  '#88ba14',
  '#ffd100',
  '#008550',
  '#c0d23b',
  '#223f2f',
  '#00609c',
  '#122f70',
  '#3aa935',
  '#a6b56c',
  '#002e63',
  '#3e4b7d',
  '#23231f',
  '#3e7f81',
  '#073057',
  '#54af47',
  '#164d85',
  '#337ab7',
  '#435052',
  '#001e4e',
  '#76927a',
  '#26566c',
  '#004c83',
  '#75532b',
  '#004d81',
  '#d01a15',
  '#242424',
  '#3352cc',
  '#393085',
  '#507283',
  '#280c49',
  '#ff6633',
  '#000000',
  '#003767',
  '#7c0142',
  '#a07a4c',
  '#b01c34',
  '#327049',
  '#143256',
  '#94cf82',
  '#00509f',
  '#00007d',
  '#1b87de',
  '#d01414',
  '#201f1f',
  '#669900',
  '#6c6e5f',
  '#83275f',
  '#4682b4',
  '#bbbcbf',
  '#9a0e0f',
  '#183b7e',
  '#0e3e8a',
  '#de002e',
  '#e30c1a',
  '#171d70',
  '#31353a',
  '#0f70b7',
  '#2c8c59',
  '#598cc4',
  '#27306c',
]

const buttons = [
  '#60c78f',
  '#a7e5c7',
  '#55b144',
  '#ebcc02',
  '#f99d3f',
  '#e84f3d',
  '#bb6cdc',
  '#00badb',
  '#fddf9d',
  '#b7b7b7',
  '#5c3967',
  '#5c7595',
  '#c1f5bd',
  '#85be8a',
  '#006eb7',
  '#48e48d',
  '#ff75c7',
  '#444444',
  '#76c9dc',
  '#c1f6be',
  '#616f7e',
  '#5b2d8e',
  '#1e9dee',
  '#f59509',
  '#be2720',
  '#565656',
  '#aaafb2',
  '#cf003d',
  '#0272ac',
  '#97c037',
  '#f29400',
  '#e17e68',
  '#4f65a9',
  '#d4d600',
  '#007aad',
  '#9fc2de',
  '#2084ab',
  '#749513',
  '#676c6d',
  '#f98a46',
  '#72c4ef',
  '#6bc7dc',
  '#ed1c24',
  '#edcba7',
  '#bdbec0',
  '#a3a3a3',
  '#4a4949',
  '#222222',
  '#c1604a',
  '#e8405d',
  '#781b7d',
  '#706f6f',
  '#00a8bc',
  '#b61f20',
  '#2a2d2e',
  '#27a2e2',
  '#f26684',
  '#16313a',
  '#bdcf33',
  '#337539',
  '#4166b3',
  '#009ee3',
  '#6eb8b0',
  '#000063',
  '#005694',
  '#0474be',
  '#cfb284',
  '#ea7b2f',
  '#0080c6',
  '#fab515',
  '#be7c5e',
  '#870000',
  '#003b79',
  '#218dc8',
  '#6f263d',
  '#336aa0',
  '#84366c',
  '#e10615',
  '#d6a029',
  '#5a8cc8',
  '#7da41b',
  '#7f9cba',
  '#b61412',
  '#781204',
  '#f49a00',
  '#668b8d',
  '#ffd300',
  '#0069b4',
  '#7c1e33',
  '#2e6793',
  '#01294d',
  '#98b0ca',
  '#b3def0',
  '#e79286',
  '#486680',
  '#88ba14',
  '#ffd100',
  '#008550',
  '#c0d23b',
  '#223f2f',
  '#b5cc03',
  '#3b74ca',
  '#3aa935',
  '#a6b56c',
  '#002e63',
  '#a82b33',
  '#ffdb00',
  '#70a9a5',
  '#841326',
  '#54af47',
  '#00aaa7',
  '#337ab7',
  '#db0132',
  '#62224b',
  '#76927a',
  '#cd181a',
  '#26566c',
  '#004c83',
  '#75532b',
  '#007755',
  '#d01a15',
  '#3352cc',
  '#008ed2',
  '#a2c617',
  '#280c49',
  '#ff6633',
  '#b4c773',
  '#003767',
  '#7c0142',
  '#a07a4c',
  '#3fc1e6',
  '#b01c34',
  '#000000',
  '#327049',
  '#45abd8',
  '#94cf82',
  '#50af31',
  '#fea500',
  '#1b87de',
  '#d01414',
  '#8f3936',
  '#669900',
  '#242424',
  '#9b1e23',
  '#602b60',
  '#4682b4',
  '#1d326b',
  '#9d9d9d',
  '#556fa3',
  '#0e3e8a',
  '#de002e',
  '#a6aba1',
  '#171d70',
  '#c82745',
  '#bed7c8',
  '#2bb6f6',
  '#ed7d3a',
  '#322869',
  '#49ac33',
  '#fee602',
]

export default { header, buttons }
