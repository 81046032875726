// @flow

import { NOT_CONFIRMED_STATUS, USER_STATUS } from '../../../constants/users'
import { getUserId, isDwellerUser, isStaffUser } from '../../../utils/utils'
import {
  BUILDING_VARIABLE_NAME,
  CHAT_MESSAGE,
  EMAIL_MESSAGE,
  FLAT_VARIABLE_NAME,
  PROFILE_VARIABLE_NAME,
} from './InitStage.constants'

export function isSubmitDisabled(
  user: { group: string, status: number } | '',
  isFlatSelected: Boolean,
  currentUser: Object,
  outbound?: string
): boolean {
  const isStaff = isStaffUser(currentUser)

  if (outbound) {
    return !isFlatSelected
  }

  if (!user && isStaff) {
    return false
  }

  const initiator = user || currentUser

  const isDweller = isDwellerUser(initiator)

  return (
    isDweller &&
    USER_STATUS[initiator.status] !== NOT_CONFIRMED_STATUS &&
    !isFlatSelected
  )
}

export function getVariables(
  submitUser: Object | '',
  user: Object,
  building: Object | '',
  flat: Object | '',
  messageUuid?: string,
  chatMessageId?: string
): Array<Object> {
  const profile = submitUser || user
  const profileId = getUserId(profile)

  let variables = [{ name: PROFILE_VARIABLE_NAME, value: profileId }]

  if (flat) {
    variables.push({
      name: FLAT_VARIABLE_NAME,
      value: flat.id,
    })
  } else if (building) {
    variables.push({
      name: BUILDING_VARIABLE_NAME,
      value: building.id,
    })
  }

  if (messageUuid) {
    variables.push({ name: EMAIL_MESSAGE, value: messageUuid })
  }

  if (chatMessageId) {
    variables.push({ name: CHAT_MESSAGE, value: chatMessageId })
  }

  return variables
}
