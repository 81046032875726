// @flow

import React from 'react'
import classnames from 'classnames'
import type { Node } from 'react'

import TwoLineText from './TwoLineText'
import PrimaryText from './PrimaryText'
import SecondaryText from './SecondaryText'
import Avatar from './Avatar'
import { DEFAULT_LIST_ITEM_HEIGHT } from './ListItem.constants'

import styles from './ListItem.module.scss'

type Props = {
  avatar?: string,
  className?: string,
  isSelected?: boolean,
  listItemHeight: number,
  onClick?: (SyntheticEvent<HTMLDivElement>) => void,
  primaryText: string,
  secondaryText?: string,
  style?: Object,
  title?: string,
}

const ListItem = (props: Props): Node => {
  const {
    index,
    primaryText,
    secondaryText,
    avatar,
    listItemHeight,
    className,
    isSelected,
    style,
    meta,
    forwardRef,
    title,
    isHover,
    handlerMouse,
    onMouseDown,
  } = props

  const listItemClass = classnames(styles.listItem, className, {
    [styles.selected]: isSelected,
    [styles.isHovered]: isHover,
  })
  const itemStyle = Object.assign({}, style ? { ...style } : {})

  const listItemProps = Object.assign(
    {},
    props.onClick ? { onClick: props.onClick } : undefined,
    listItemHeight
      ? { style: { height: `${listItemHeight}px`, ...itemStyle } }
      : { style: { ...itemStyle } }
  )

  const onHandlerMouse = () => handlerMouse(index)

  return (
    <li
      className={listItemClass}
      onMouseEnter={handlerMouse ? onHandlerMouse : undefined}
      {...listItemProps}
      ref={forwardRef}
      title={title}
      onMouseDown={onMouseDown}
    >
      {avatar && <Avatar avatar={avatar} />}
      {secondaryText ? (
        <TwoLineText>
          <PrimaryText text={primaryText} />
          <SecondaryText text={secondaryText} />
        </TwoLineText>
      ) : (
        <PrimaryText text={primaryText} />
      )}
      {meta && <span className={styles.meta}>{meta}</span>}
    </li>
  )
}

ListItem.defaultProps = {
  isTwoLine: false,
  listItemHeight: DEFAULT_LIST_ITEM_HEIGHT,
}

export default React.forwardRef((props, ref) => (
  <ListItem {...props} forwardRef={ref} />
))
