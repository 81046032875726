// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import Button from './Button'

type Props = {
  handleClick: ({ [string]: boolean }) => void,
  isActive: boolean,
  permissionDescription: string,
  permissionName: string,
  title?: string,
}

const Permission = (props: Props) => {
  const { permissionDescription, title, permissionName, isActive } = props

  const { t } = useTranslation('Permissions')

  const itemYesClass = classnames('radio-button__item ', {
    'radio-button__item--active': isActive,
  })

  const itemNoClass = classnames('radio-button__item ', {
    'radio-button__item--active': !isActive,
  })

  const rowClass = classnames('settings-rights__row', {
    'settings-rights__row--notitle': !title,
  })

  return (
    <div>
      {title && <h2 className='settings-rights__title'>{title}</h2>}
      <div className={rowClass}>
        <div className='settings-rights__cell'>{permissionDescription}</div>
        <div className='settings-rights__cell'>
          <span className='radio-button radio-button--normal'>
            <Button
              isActivateButton
              text={t('Yes')}
              buttonClass={itemYesClass}
              handleClick={props.handleClick}
              permissionName={permissionName}
            />
            <Button
              text={t('No')}
              buttonClass={itemNoClass}
              handleClick={props.handleClick}
              permissionName={permissionName}
            />
          </span>
        </div>
      </div>
    </div>
  )
}

export default Permission
