// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './ContactFormField.module.scss'
import classnames from 'classnames'
import OutsideClick from 'react-onclickout'

import {
  MAIL_GROUP,
  ONE_VALUE_FIELDS,
  PHONE_GROUP,
  TEXT_GROUP,
  WEB_GROUP,
} from '../ContactForm.constants'

import ContactFormSelect from '../ContactFormSelect'
import InputField from '../../InputField'
import Icon from '../../Icon'
import Button from '../../Button'
import PhoneInput from '../../forms/PhoneInput'
import Phone from '../../Phone'

import { useField } from 'formik'

type Props = {
  actionOnField: Object,
  countryCode: string,
  icon: string,
  index: number,
  isExternal: boolean,
  isOpen: boolean,
  isOpenModal: boolean,
  isSubmitting: boolean,
  label: string,
  name: string,
  onChange: () => void,
  onOpenPopUp: (isOpen: boolean) => void,
}

const ContactFormField = ({
  index,
  label,
  icon,
  isOpenModal,
  isOpen,
  countryCode,
  actionOnField,
  name,
  onOpenPopUp,
  isSubmitting,
  isExternal,
  valuesTypes = [],
}: Props) => {
  const { t } = useTranslation(['ContactForm', 'Errors'])

  const [field, meta] = useField({
    name: name,
  })

  const {
    onChangeField,
    onToggleOpenField,
    onGetField,
    onRemoveField,
    resetForm,
  } = actionOnField

  const {
    id,
    fk,
    contact_type,
    values: fieldValue,
    permissions: { can_edit: isEditable, can_delete: isDeletable },
  } = onGetField(index)

  const DEFAULT_VALUES = ['AuthPhoneDoesNotExists', 'AuthEmailDoesNotExists']
  const IS_PHONE_GROUP = PHONE_GROUP.includes(contact_type)
  const IS_TEXT_GROUP = TEXT_GROUP.includes(contact_type)
  const IS_WEB = WEB_GROUP.includes(contact_type)
  const iS_MAIL = MAIL_GROUP.includes(contact_type)
  const isError = meta.touched && !!meta.error
  const isNewField = !id && !fk

  const onClickOut = () => {
    if (isOpenModal) return

    if (!isNewField) {
      if (meta.initialValue !== fieldValue) {
        onChangeField(index, { values: meta.initialValue })
      }

      onToggleOpenField()
    } else {
      if (!fieldValue) {
        onRemoveField(index)
      }
    }
  }

  const onEdit = () => {
    resetForm()
    onToggleOpenField(index)
  }

  const onChange = value => onChangeField(index, { values: value, value })

  const fieldClass = classnames(styles.field, {
    [styles.fieldHover]: !isOpen,
    [styles.newField]: isNewField,
  })
  const inputClass = classnames(styles.inputs)
  const phoneClass = classnames(
    'input input--default input--block input--large input--multiple',
    {
      [styles.phone]: true,
    }
  )

  const value = DEFAULT_VALUES.includes(fieldValue)
    ? t(`${fieldValue}`, { ns: ['ContactForm', 'Errors'] })
    : fieldValue

  const canDeleteContact = !(isExternal && iS_MAIL)

  const excludeFields = ONE_VALUE_FIELDS.filter(i => valuesTypes.includes(i))

  return (
    <div className={styles.group}>
      <div className={styles.label}>
        {icon && (
          <div className={styles.icon}>
            <Icon id={icon} />
          </div>
        )}
        <div className={styles.labelText}>{label}</div>
      </div>
      <div className={fieldClass}>
        {!isOpen ? (
          <div className={styles.wrap}>
            {IS_PHONE_GROUP && (
              <>
                {DEFAULT_VALUES.includes(meta.value) ? (
                  <>{value}</>
                ) : (
                  <Phone
                    phone={`${value && value[0] !== '+' ? '+' : ''}${value}`}
                  />
                )}
              </>
            )}
            {IS_WEB && (
              <div className={styles.text}>
                <a
                  href={value}
                  title={value}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {value}
                </a>
              </div>
            )}
            {!IS_WEB && !IS_PHONE_GROUP && (
              <div title={value} className={styles.text}>
                {value}
              </div>
            )}
            {isEditable && (
              <Button.Edit className={styles.buttonEdit} onClick={onEdit} />
            )}
          </div>
        ) : (
          <OutsideClick onClickOut={onClickOut}>
            <div className={styles.inputsWrap}>
              {isNewField && (
                <ContactFormSelect
                  {...actionOnField}
                  isExternal={isExternal}
                  excludeFields={excludeFields}
                  index={index}
                  name={name}
                />
              )}
              <div className={inputClass}>
                {IS_PHONE_GROUP && (
                  <PhoneInput
                    autoFocus
                    type='phone'
                    className={phoneClass}
                    name={name}
                    value={value}
                    countryCode={countryCode}
                    onChange={onChange}
                  />
                )}
                {IS_TEXT_GROUP && (
                  <InputField
                    {...field}
                    autoFocus
                    error={isError}
                    placeholder={
                      IS_WEB
                        ? 'http://website.com'
                        : iS_MAIL
                        ? 'example@mail.com'
                        : ''
                    }
                    value={
                      !id && meta.initialValue === fieldValue ? '' : fieldValue
                    }
                  />
                )}
                {isError ? (
                  <div className={styles.error}>
                    {t(`${meta.error}`, { ns: ['ContactForm', 'Errors'] })}
                  </div>
                ) : null}
              </div>
            </div>
            <Button.Save
              type='submit'
              className={styles.buttonSave}
              disabled={
                !value ||
                DEFAULT_VALUES.includes(fieldValue) ||
                meta.initialValue === value ||
                isSubmitting ||
                isError
              }
            />
            <Button.Cancel
              className={styles.buttonCancel}
              disabled={!isDeletable || !canDeleteContact}
              onClick={() => onOpenPopUp(true)}
            />
          </OutsideClick>
        )}
      </div>
    </div>
  )
}

export default ContactFormField
