// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import { pick } from 'lodash-es'

import Checkbox from '../Checkbox'
import SelectCustom from '../Select/SelectCustom'
import { getFormattedDate } from '../../utils/utils'
import OwnerIcon from '../Files/FileList/FileListItem/OwnerIcon'
import NewTableBodyRow from '../NewTable/NewTableBodyRow'
import NewTableBodyCell from '../NewTable/NewTableBodyCell'

const PostTemplatesBodyRow = props => {
  const { template, selectedItems } = props

  const {
    id,
    name,
    text_preview,
    updated,
    hidden,
    created_by,
    post_title,
    permissions: { can_edit },
  } = template

  const { fullname } = created_by

  const { t } = useTranslation('PostTemplates')

  const formattedDate = updated ? getFormattedDate(updated) : '-'

  let options = []

  const handleEdit = props.handleEdit(
    pick(template, [
      'name',
      'id',
      'text',
      'private',
      'files',
      'post_type',
      'hidden',
      'permissions',
      'post_title',
      'post_ttl',
      'building_group_objs',
      'for_building_without_group',
    ])
  )

  const handleChange = () => props.handleChange(id)

  options.push({
    label: t('EditTemplate'),
    value: 'editTemplate',
    handler: handleEdit,
    icon: 'pencil',
  })

  options.push({
    label: t('CloneTemplate'),
    value: 'cloneTemplate',
    handler: props.handleClone(pick(template, ['name', 'id'])),
    icon: 'clone',
  })

  if (can_edit) {
    options.push({
      label: t('RemoveTemplate'),
      value: 'removeTemplate',
      handler: props.openRemoveModal(id),
      icon: 'bin',
    })
  }

  const hiddenTitle = hidden ? t('HiddenTempalte') : t('VisibleTempalte')

  return (
    <NewTableBodyRow onClick={handleEdit}>
      <NewTableBodyCell title={name}>
        <Checkbox
          style={{ marginLeft: '16px' }}
          checked={selectedItems.includes(id)}
          onChange={handleChange}
        />
      </NewTableBodyCell>
      <NewTableBodyCell title={post_title} text={post_title} />
      <NewTableBodyCell title={text_preview} />
      <NewTableBodyCell title={hiddenTitle} text={hiddenTitle} />
      <NewTableBodyCell title={fullname}>
        <OwnerIcon file={created_by} />
      </NewTableBodyCell>
      <NewTableBodyCell>
        <div className='table-item table-item__text' title={formattedDate}>
          <span>{formattedDate}</span>
        </div>
        <div className='table-extra table-extra--settings'>
          <SelectCustom
            options={options}
            onChange={({ handler }) => handler()}
          />
        </div>
      </NewTableBodyCell>
    </NewTableBodyRow>
  )
}

export default PostTemplatesBodyRow
