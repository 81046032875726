// @flow

import React from 'react'
import classnames from 'classnames'
import { useSelector } from 'react-redux'
import type { Node } from 'react'

import { getThemeColor } from '../../utils/commonSelectors'

import styles from './InputField.module.scss'

type Props = {
  autoFocus?: boolean,
  className?: string,
  disabled?: boolean,
  error?: string,
  maxLength?: number,
  name?: string,
  onBlur?: (SyntheticEvent<HTMLInputElement>) => void,
  onChange?: (SyntheticEvent<HTMLInputElement>) => void,
  onFocus?: (SyntheticEvent<HTMLInputElement>) => void,
  onlyInteger?: boolean,
  placeholder?: string,
  showErrorText?: boolean,
  style?: Object,
  title?: string,
  type?: string,
  value?: string,
}

const InputField = (props: Props): Node => {
  const {
    className,
    style,
    placeholder,
    name,
    value,
    showErrorText,
    error,
    disabled,
    title,
    autoFocus,
    type: inputType,
    maxLength,
  } = props

  const themeColor = useSelector(getThemeColor)

  const inputClass = classnames(className, styles.input, {
    [styles.inputError]: error,
  })

  const inputProps = Object.assign(
    {},
    {
      className: inputClass,
      name,
      value,
      type: inputType,
    },
    props.onChange ? { onChange: props.onChange } : undefined,
    props.onBlur ? { onBlur: props.onBlur } : undefined,
    props.onFocus ? { onFocus: props.onFocus } : undefined,
    disabled ? { disabled } : undefined,
    style
      ? { style: { ...style, '--themeColor': themeColor } }
      : { style: { '--themeColor': themeColor } },
    placeholder ? { placeholder } : undefined,
    title ? { title } : undefined,
    autoFocus ? { autoFocus } : undefined,
    maxLength ? { maxLength } : undefined,
    props.onlyInteger ? { min: '0', step: '1' } : undefined
  )

  return (
    <>
      <input {...inputProps} />
      {error && showErrorText && (
        <span className={styles.errorText}>{error}</span>
      )}
    </>
  )
}

InputField.defaultProps = {
  type: 'text',
  showErrorText: true,
}

export default InputField
