export const FLAT_DWELLERS_INITIATING = 'FLAT_DWELLERS_INITIATING'
export const FLAT_DWELLERS_INITIATED = 'FLAT_DWELLERS_INITIATED'
export const FLAT_DWELLERS_ERROR = 'FLAT_DWELLERS_ERROR'
export const FLAT_DWELLERS_SHOW_MODAL = 'FLAT_DWELLERS_SHOW_MODAL'
export const FLAT_DWELLERS_HIDE_MODAL = 'FLAT_DWELLERS_HIDE_MODAL'
export const FLAT_DWELLERS_UPDATE_ROLE = 'FLAT_DWELLERS_UPDATE_ROLE'
export const FLAT_DWELLERS_UPDATED_ROLE = 'FLAT_DWELLERS_UPDATED_ROLE'
export const FLAT_DWELLERS_TOGGLE = 'FLAT_DWELLERS_TOGGLE'
export const FLAT_DWELLERS_TOGGLE_ALL = 'FLAT_DWELLERS_TOGGLE_ALL'
export const FLAT_DWELLERS_REMOVE = 'FLAT_DWELLERS_REMOVE'
export const FLAT_DWELLERS_LOAD_ONE = 'FLAT_DWELLERS_LOAD_ONE'
export const FLAT_DWELLERS_LOAD = 'FLAT_DWELLERS_LOAD'
export const FLAT_DWELLERS_UPDATE = 'FLAT_DWELLERS_UPDATE'
export const FLAT_DWELLERS_SHOW_PAGINATION = 'FLAT_DWELLERS_SHOW_PAGINATION'
