// @flow

import React, { Children, Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'
import { compose } from 'redux'
import { isBoolean, isEmpty, isEqual, isNull } from 'lodash-es'

import Icon from '../Icon'
import { GLOBAL_MODAL_ERROR } from '../Layout/Layout.constants'
import BrowserStorage from '../../utils/browserStorage'

const clearChildren = children =>
  Children.toArray(children).filter(el => !isEmpty(el))

type Props = {
  children: any,
  className?: string,
  count?: number,
  dispatch: () => void,
  label?: string,
  large?: boolean,
  onSelectTab?: () => void,
  selected?: number,
  storageKey?: string,
  svg?: string,
  t: string => string,
}
type State = {
  selected: number,
}

class Tabs extends Component<Props, State> {
  state = {
    selected: this.getSelected(),
    clearChildren: clearChildren(this.props.children),
  }

  componentDidMount() {
    const { selected, storageKey } = this.props

    if (storageKey && !BrowserStorage.get(storageKey)) {
      BrowserStorage.set(storageKey, selected)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { forcedTabIndex, loading, resetState, storageKey, confirmLink } =
      this.props
    const { selected } = this.state

    if (prevProps.confirmLink !== confirmLink) {
      if (!isNull(confirmLink)) {
        this.setState({ selected: confirmLink })
        this.props.setConfirmLink(null)
      }
    }

    if (!isEqual(prevProps.children, this.props.children)) {
      this.setState({
        clearChildren: clearChildren(this.props.children),
      })
    }

    if (storageKey && selected !== prevState.selected) {
      BrowserStorage.set(storageKey, selected)
    }

    if (!loading) {
      return
    }

    if (
      Number.isFinite(forcedTabIndex) &&
      forcedTabIndex !== this.state.selected
    ) {
      this.setState({ selected: forcedTabIndex })
      resetState()
    }
  }

  getSelected() {
    const { selected, storageKey } = this.props
    const storageSelected = BrowserStorage.get(storageKey)

    if (storageKey && storageSelected) {
      return parseInt(storageSelected)
    }

    return selected
  }

  getItemClass = (key, props) => {
    const { selected } = this.state
    const tabsClass = this.isLarge() ? 'tabs-3' : 'tabs'
    const { disabled, read } = props

    return classnames(`${tabsClass}__item`, {
      [`${tabsClass}__item--unread`]: isBoolean(read) && !read,
      [`${tabsClass}__item--active`]: key === selected,
      [`${tabsClass}__item--disabled`]: !!disabled,
    })
  }

  getChild = (props, selected) => {
    const { label, count, svg } = props

    if (this.isLarge()) {
      return (
        <span className='tabs-3__text'>
          {label} <b className='tabs-3__count'>{count}</b>
        </span>
      )
    }

    return (
      <span className='tabs__item-wrap'>
        {svg && <Icon className='tabs__symbol' id={svg} />}
        <span>
          {label}{' '}
          {!!count && selected && <b className='tabs-3__count'>{count}</b>}
        </span>
      </span>
    )
  }

  isLarge = () => 'large' in this.props && this.props.large !== false

  handleClick = index => {
    const { dispatch, onSelectTab, t } = this.props
    const { clearChildren } = this.state
    const item = clearChildren[index]

    return e => {
      e.preventDefault()
      this.props.onShowSuggestModal && this.props.onShowSuggestModal(null)

      if (item.props.notAllowed) {
        dispatch({
          type: GLOBAL_MODAL_ERROR,
          errorText: t('NeedToBeApprovedDwellerText'),
          errorTitle: t('NeedToBeApprovedDwellerTitle'),
        })
      } else if (item.props.onClick) {
        item.props.onClick()
      } else {
        this.setState({ selected: index })

        if (onSelectTab) {
          onSelectTab(index)
        }
      }
    }
  }

  handleShowModal = index => {
    const { onShowSuggestModal } = this.props

    return e => {
      e.preventDefault()
      onShowSuggestModal(index)
    }
  }

  renderNav = (item, i) => {
    const { itemStyle, needShowSuggestModal } = this.props

    return (
      item && (
        <div
          style={itemStyle}
          id={item.props.id}
          className={this.getItemClass(i, item.props)}
          key={i}
          onClick={
            item.props.disabled
              ? null
              : needShowSuggestModal
              ? this.handleShowModal(i)
              : this.handleClick(i)
          }
        >
          {this.getChild(item.props, this.state.selected === i)}
        </div>
      )
    )
  }

  render() {
    const { className } = this.props
    const { selected, clearChildren } = this.state
    const large = this.isLarge()
    let tabsClass = large ? 'tabs-3' : 'tabs'

    if (className) {
      tabsClass = className
    }

    return (
      <div>
        <nav className={tabsClass}>
          {Children.map(clearChildren, this.renderNav)}
        </nav>

        <div className='tab-content'>
          {clearChildren.length ? clearChildren[selected] : clearChildren}
        </div>
      </div>
    )
  }
}

export default compose(withTranslation('Modal'), connect())(Tabs)
