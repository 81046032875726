// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import { useUser } from '../../../hooks'
import { isDwellerUser } from '../../../utils/utils'

import Table from '../../Table'

type Props = { loading: boolean }

const ProfileListTableHeader = (props: Props): Node => {
  const { loading } = props

  const user = useUser()

  const isDweller = isDwellerUser(user)

  const { t } = useTranslation('FlatDwellers')

  return (
    <Table.HeaderRow>
      <Table.HeaderCell
        title={<>{t('User')}&nbsp;</>}
        sortKey='userprofile_fullname'
        working={loading}
      />
      <Table.HeaderCell
        title={<>{t('Role')}&nbsp;</>}
        style={{ width: '15%' }}
        sortKey='is_landlord'
        working={loading}
      />
      {!isDweller && (
        <>
          <Table.HeaderCell
            title={<>{t('ContractId')}&nbsp;</>}
            sortKey='contract_id'
            working={loading}
          />
          <Table.HeaderCell
            title={<>{t('SecondId')}&nbsp;</>}
            sortKey='purchase_contract_number'
            working={loading}
          />
          <Table.HeaderCell
            title={<>{t('ContractStart')}&nbsp;</>}
            sortKey='contract_start'
            working={loading}
          />
          <Table.HeaderCell
            title={<>{t('ContractDuration')}&nbsp;</>}
            sortKey='duration'
            working={loading}
          />
          <Table.HeaderCell
            title={t('ContractEnd')}
            sortKey='contract_end'
            working={loading}
          />
        </>
      )}
    </Table.HeaderRow>
  )
}

export default ProfileListTableHeader
