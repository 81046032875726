// @flow

import React, { Component } from 'react'
import type { ComponentType } from 'react'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'

import Button from '../../Button'
import EmptyList from '../../EmptyList'
import Filename from '../DocumentPreview/Name'
import { FilesList } from '../../../containers/FilesUpload'
import IconAdd from '../../../../static/icons/add-thin.svg'
import Preview from '../DocumentPreview'
import NewFilesUpload from '../../NewFilesUpload'

import styles from './DocumentsEditing.module.scss'

const MIN_ITEM_INDENT = 12

type Items = Array<Object>
type Props = {
  canRemove?: boolean,
  disabled?: boolean,
  files: Items,
  Footer?: ComponentType<*>,
  gallery?: number,
  hideAddFirst?: boolean,
  itemIndent: number,
  multiple?: boolean,
  onRemove?: Object => void,
  onStartUpload?: () => void,
  onUpdate: Items => void,
  outbound?: string,
  rowLength: number,
  saving?: boolean,
  t: string => string,
}
type State = {
  uploading: boolean,
}

class DocumentsEditing extends Component<Props, State> {
  static defaultProps = {
    itemIndent: MIN_ITEM_INDENT,
  }

  state = {
    uploading: false,
  }

  startUpload = () => {
    if (this.props.onStartUpload) {
      this.props.onStartUpload()
    }

    this.setState({ uploading: true })
  }

  update = (uploaded: Array<Object>) => {
    const { files } = this.props

    this.props.onUpdate(files.concat(uploaded))
    this.setState({ uploading: false })
  }

  remove = (file: Object) => {
    if (this.props.onRemove) {
      this.props.onRemove(file)
    }
  }

  renderFooter = () => {
    const { Footer } = this.props

    return Footer ? <Footer /> : null
  }

  renderFile = (file: Object, key: string) => {
    const { canRemove } = this.props

    return (
      <Preview
        canRemove={canRemove && this.props.onRemove}
        file={file}
        key={key}
        onRemove={this.remove}
      />
    )
  }

  renderAddButton = () => {
    const { multiple } = this.props

    const dropClass = classnames('drop-zone', styles.drop)

    return (
      <div className={styles.add} key='add-button'>
        <NewFilesUpload
          multiple={multiple}
          name='attach-file'
          className={dropClass}
          onFinishUpload={this.update}
          onStartUpload={this.startUpload}
        >
          <IconAdd />
        </NewFilesUpload>
        <Filename>{this.props.t('Add')}</Filename>
      </div>
    )
  }

  renderAddFirst = () => {
    const { multiple, disabled, outbound } = this.props

    return (
      <EmptyList embedded icon='file' title={this.props.t('EmptyTitle')}>
        <NewFilesUpload
          className='uploader__attach drop-zone'
          multiple={multiple}
          style={{
            margin: '1.5rem 0 2rem 0',
            display: 'inline-block',
          }}
          name='attach-file'
          imageMaxSize={10}
          outbound={outbound}
          onFinishUpload={this.update}
          onStartUpload={this.startUpload}
        >
          <Button.Save
            className='button button--large button--success'
            style={{ margin: 0 }}
            disabled={disabled}
          >
            {this.props.t('Upload')}
          </Button.Save>
        </NewFilesUpload>
      </EmptyList>
    )
  }

  render() {
    const { files, rowLength, saving, itemIndent, hideAddFirst } = this.props
    const { uploading } = this.state

    if ((!files || !files.length) && !uploading && !hideAddFirst) {
      return this.renderAddFirst()
    }

    const boxClass = classnames({
      'working-overlay': uploading || saving,
    })

    return (
      <div className={boxClass}>
        <FilesList
          ExtraItem={this.renderAddButton}
          files={files}
          itemIndent={Math.max(itemIndent, MIN_ITEM_INDENT)}
          renderItem={this.renderFile}
          rowLength={rowLength}
        />
        {this.renderFooter()}
      </div>
    )
  }
}

export default withTranslation('File')(DocumentsEditing)
