// @flow

import React from 'react'
import type { Element } from 'react'
import SelectCustom from '../Select/SelectCustom'
import ConfirmationPopup from '../modals/ConfirmationPopup'

type Props = {
  isOffline: boolean,
  setModal: (?Element<'div'>) => void,
  t: (string, ?Object) => string,
  workflow: Object,
}
const ExtraActions = (props: Props) => {
  const { isOffline, workflow, t } = props

  const preventOpen = e => {
    e.stopPropagation()
  }

  const remove = () => {
    props.remove(workflow.id)
  }

  const handleDelete = () => {
    props.setModal(
      <ConfirmationPopup
        title={t('DeleteTitle')}
        text={t('DeleteText')}
        confirm={t('DeleteButtonTitle')}
        cancel={t('Cancel')}
        onClose={hideModal}
        onOk={remove}
      />
    )
  }

  const getOptions = () => {
    let options = [
      {
        value: 'copyWorkflow',
        label: t('CloneWorkflow'),
        handler: () => props.copyWorkflow(workflow.id),
        icon: 'clone',
      },
    ]

    if (isOffline) {
      options.push({
        value: 'deleteWorkflow',
        label: t('DeleteWorkflow'),
        handler: handleDelete,
        icon: 'bin',
      })
    }

    return options
  }

  const hideModal = () => {
    props.setModal(null)
  }

  return (
    <div className='table-extra table-extra--settings'>
      <SelectCustom
        options={getOptions()}
        onClick={preventOpen}
        onChange={opt => opt.handler()}
      />
    </div>
  )
}

export default ExtraActions
