// @flow

export const isDefaultOption = (defaultOption, option) => {
  const { value: defaultvalue } = defaultOption
  const { value } = option

  return value === defaultvalue
}

export const getOption = (options, option) =>
  options.find(({ value }) => value === option)
