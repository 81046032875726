/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ProviderProfileOffers_query$ref = any;
type ProviderProfile_provider$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProviderView_query$ref: FragmentReference;
declare export opaque type ProviderView_query$fragmentType: ProviderView_query$ref;
export type ProviderView_query = {|
  +provider: ?{|
    +id: string,
    +contacts: ?$ReadOnlyArray<?{|
      +type: ?string,
      +value: ?string,
      +verboseName: ?string,
    |}>,
    +image: ?{|
      +origin: ?string,
      +alt: ?string,
    |},
    +name: string,
    +$fragmentRefs: ProviderProfile_provider$ref,
  |},
  +$fragmentRefs: ProviderProfileOffers_query$ref,
  +$refType: ProviderView_query$ref,
|};
export type ProviderView_query$data = ProviderView_query;
export type ProviderView_query$key = {
  +$data?: ProviderView_query$data,
  +$fragmentRefs: ProviderView_query$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "ProviderView_query",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "providerID",
      "type": "ID!",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "providerGlobalId",
      "type": "String",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "page",
      "type": "Float",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "provider",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "providerID"
        }
      ],
      "concreteType": "ProviderNode",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "id",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "contacts",
          "storageKey": null,
          "args": null,
          "concreteType": "ContactObjectType",
          "plural": true,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "type",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "value",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "verboseName",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "image",
          "storageKey": null,
          "args": null,
          "concreteType": "ProviderImageNode",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "origin",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "alt",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "name",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "FragmentSpread",
          "name": "ProviderProfile_provider",
          "args": null
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "ProviderProfileOffers_query",
      "args": [
        {
          "kind": "Variable",
          "name": "page",
          "variableName": "page"
        },
        {
          "kind": "Variable",
          "name": "providerGlobalId",
          "variableName": "providerGlobalId"
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'e194d58659bec27e50eb50bdd3631dba';
module.exports = node;
