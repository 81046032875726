// @flow

import React, { useEffect, useState } from 'react'
import type { Node } from 'react'

import NewSelectSimple from './NewSelectSimple'
import { getDictOptions } from '../core/api/api.system'

type Props = {
  buttonClass?: string,
  disabled?: boolean,
  name?: string,
  onChange: (Object, Object) => void,
  params: Function,
  placeholder: string,
  value: Object,
}

function getOptions(data) {
  return data.map(item => ({ value: item.id, label: item.name }))
}

const SelectRegion = (props: Props): Node => {
  const { disabled, params, placeholder, value, name, buttonClass } = props

  const [loading, setLoading] = useState(false)
  const [isOpen, setOpen] = useState(false)
  const [options, setOptions] = useState([])
  const [cleanOptions, setCleanOptions] = useState([])

  const handleOpen = isOpen => setOpen(isOpen)

  useEffect(() => {
    if (isOpen) {
      setLoading(true)

      getDictOptions(params)
        .then(data => {
          setOptions(getOptions(data))
          setCleanOptions(data)
        })
        .finally(() => setLoading(false))
    }
  }, [isOpen])

  const handleChange = option => {
    const cleanOption = cleanOptions.find(
      cleanOption => cleanOption.id === option.value
    )

    props.onChange(option, cleanOption)
  }

  return (
    <NewSelectSimple
      disabled={disabled}
      buttonClass={buttonClass}
      name={name}
      loading={loading}
      options={options}
      placeholder={placeholder}
      value={value}
      onOpen={handleOpen}
      onChange={handleChange}
    />
  )
}

export default SelectRegion
