// @flow

import React from 'react'
import type Node from 'react'
import { withTranslation } from 'react-i18next'

import { Cell } from '../../Block'

type Props = {
  group: string,
  t: string => string,
}

const ProfileType = (props: Props): Node => (
  <Cell title={props.t('TypeTitle')}>{props.t(props.group)}</Cell>
)

export default withTranslation('User')(ProfileType)
