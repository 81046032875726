// @flow

import React, { useState, useEffect } from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import classnames from 'classnames'
import { compose } from 'redux'
import { connect, useSelector } from 'react-redux'
import { isEqual, find } from 'lodash-es'

import Button from '../Button'
import Option from './Option'
import { getOption, isDefaultOption } from './RequestSettings.utils'
import { SETTINGS_CORPORATE_SAVE_CONFIG } from '../SettingsCorporate/SettingsCorporate.actionTypes'
import NewSelectSimple from '../NewSelectSimple'
import InputField from '../InputField'
import { getPipelineCriteria } from '../../utils/commonSelectors'

const MARK_REQUEST_OPTION = 'markRequest'
const AUTO_ASSIGN_OPTION = 'autoAssign'
const AUTO_ASSIGN_ADMIN_OPTION = 'autoAssignAdmin'
const REVERSED_CHAT_TABS_OPTION = 'reversedChatTabs'
const MARK_EMAIL_AS_READ = 'markEmailAsRead'
const AUTO_ARCHIVE_OPTION = 'autoArchive'
const AUTO_ARCHIVE_STATUS_OPTION = 'autoArchiveStatus'
const SUPPLIER_MAPPING_OPTION = 'supplierMapping'
const ASSIGNEE_MAPPING_OPTION = 'assigneMapping'
const CATEGORY_MAPPING = 'category'
const BUILDING_MAPPING = 'building'
const BUILDING_AND_CATEGORY_MAPPING = 'building_and_category'
const PIPELINE_OPTION = 'pipeline'

const REQUEST_STATUS_COMPLETED = 3
const REQUEST_STATUS_DENIED = 4
const DEFAULT_DELAY = 30
const DELAY_MIN = 2
const DELAY_MAX = 365

const RequestSettings = props => {
  const {
    ukConfig: {
      mark_request_as_read_for_building_managers: markRequest,
      auto_assign_building_manager_on_request: autoAssign,
      auto_assign_building_admin_on_request: autoAssignAdmin,
      reversed_request_chat_tabs: reversedChatTabs,
      mark_email_as_read_after_attach_to_request: markEmailAsRead,
      auto_archive_done_request: autoArchiveDoneRequest,
      auto_archive_done_request_delay: autoArchiveRequestDelay,
      auto_archive_done_request_status: autoArchiveRequestStatus,
      filters_for_supplier_list_in_request: filtersForSupplierListInRequest,
      request_assignee_mapping_criterion: requestAssigneeMappingCriterion,
      pipeline_autostart_criterion: pipeline,
    },
  } = props

  const { t } = useTranslation('Settings')

  const requestOptions = [
    { value: false, label: t('DisableFreeRegistration') },
    { value: true, label: t('EnableFreeRegistration') },
  ]

  const statusOptions = [
    { value: REQUEST_STATUS_COMPLETED, label: t('ArchiveDoneRequests') },
    { value: REQUEST_STATUS_DENIED, label: t('ArchiveDeniedRequests') },
    {
      value: REQUEST_STATUS_COMPLETED * REQUEST_STATUS_DENIED,
      label: t('ArchiveDeniedDoneRequests'),
    },
  ]

  const supplierMappingOptions = [
    { value: [], label: t('NoMappingOption') },
    { value: [BUILDING_MAPPING], label: t('BuildingOnlyOption') },
    { value: [CATEGORY_MAPPING], label: t('CategoryOnlyOption') },
    {
      value: [BUILDING_MAPPING, CATEGORY_MAPPING],
      label: t('BuildingCategoryIntersectionOption'),
    },
  ]

  const requestAssigneeMappingCriterionOptions = [
    { value: BUILDING_MAPPING, label: t('ByBuildingOnlyOption') },
    { value: CATEGORY_MAPPING, label: t('ByCategoryOnlyOption') },
    {
      value: BUILDING_AND_CATEGORY_MAPPING,
      label: t('ByBuildingCategoryIntersectionOption'),
    },
  ]

  const pipelineCriteria = useSelector(getPipelineCriteria)
  const pipelineOptions = pipelineCriteria.map(value => ({
    value,
    label: t(`Pipeline.${value}`),
  }))

  const getStatusValue = () =>
    autoArchiveRequestStatus?.length === 2
      ? REQUEST_STATUS_COMPLETED * REQUEST_STATUS_DENIED
      : autoArchiveRequestStatus?.length === 1
      ? autoArchiveRequestStatus[0]
      : REQUEST_STATUS_COMPLETED

  const defaultMarkRequestOption = getOption(requestOptions, markRequest)
  const defaultAutoAssignOption = getOption(requestOptions, autoAssign)
  const defaultAutoAssignAdminOption = getOption(
    requestOptions,
    autoAssignAdmin
  )
  const defaultReversedChatTabsOption = getOption(
    requestOptions,
    reversedChatTabs
  )

  const defaultMarkEmailAsReadOption = getOption(
    requestOptions,
    markEmailAsRead
  )

  const defaultArchiveRequestOption = getOption(
    requestOptions,
    autoArchiveDoneRequest
  )

  const defaultArchiveDelay = autoArchiveDoneRequest
    ? autoArchiveRequestDelay
    : DEFAULT_DELAY

  const defaultArchiveStatusOption = getOption(statusOptions, getStatusValue())

  const defaultSupplierMappingOption = find(supplierMappingOptions, {
    value: filtersForSupplierListInRequest,
  })

  const defaultRequestAssigneeMappingCriterion = find(
    requestAssigneeMappingCriterionOptions,
    {
      value: requestAssigneeMappingCriterion,
    }
  )

  const defaultPipelineOption = getOption(pipelineOptions, pipeline)

  const [markRequestOption, setMarkRequestOption] = useState(
    defaultMarkRequestOption
  )
  const [autoAssignOption, setAutoAssignOption] = useState(
    defaultAutoAssignOption
  )
  const [autoAssignAdminOption, setAutoAssignAdminOption] = useState(
    defaultAutoAssignAdminOption
  )
  const [reversedChatTabsOption, setReversedChatTabsOption] = useState(
    defaultReversedChatTabsOption
  )

  const [markEmailAsReadOption, setMarkEmailAsReadOption] = useState(
    defaultMarkEmailAsReadOption
  )

  const [archiveRequestOption, setArchiveRequestOption] = useState(
    defaultArchiveRequestOption
  )

  const [archiveRequestDelay, setArchiveRequestDelay] =
    useState(defaultArchiveDelay)

  const [archiveStatusOption, setArchiveStatusOption] = useState(
    defaultArchiveStatusOption
  )

  const [supplierMappingOption, setSupplierMappingOption] = useState(
    defaultSupplierMappingOption
  )

  const [assigneeMappingOption, setAssigneeMappingOption] = useState(
    defaultRequestAssigneeMappingCriterion
  )

  const [pipelineOption, setPipelineOption] = useState(defaultPipelineOption)

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const { value: prevMarkRequest } = markRequestOption
    const { value: prevAutoAssign } = autoAssignOption
    const { value: prevAutoAssignAdmin } = autoAssignAdminOption
    const { value: prevReversedChatTabs } = reversedChatTabsOption
    const { value: prevMarkEmailAsRead } = markEmailAsReadOption
    const { value: prevArchiveRequestOption } = archiveRequestOption
    const { value: prevArchiveStatusOption } = archiveStatusOption
    const { value: prevSupplierMappingOption } = supplierMappingOption
    const { value: prevAssigneeMappingOption } = assigneeMappingOption
    const { value: prevPipelineOption } = pipelineOption

    if (markRequest !== prevMarkRequest) {
      setMarkRequestOption(getOption(requestOptions, markRequest))
    }

    if (autoAssign !== prevAutoAssign) {
      setAutoAssignOption(getOption(requestOptions, autoAssign))
    }

    if (autoAssignAdmin !== prevAutoAssignAdmin) {
      setAutoAssignAdminOption(getOption(requestOptions, autoAssignAdmin))
    }

    if (reversedChatTabs !== prevReversedChatTabs) {
      setReversedChatTabsOption(getOption(requestOptions, reversedChatTabs))
    }

    if (markEmailAsRead !== prevMarkEmailAsRead) {
      setMarkEmailAsReadOption(getOption(requestOptions, markEmailAsRead))
    }

    if (autoArchiveDoneRequest !== prevArchiveRequestOption) {
      setArchiveRequestOption(getOption(requestOptions, autoArchiveDoneRequest))
    }

    if (autoArchiveRequestDelay != archiveRequestDelay) {
      setArchiveRequestDelay(autoArchiveRequestDelay)
    }

    if (pipelineOption !== prevPipelineOption) {
      setPipelineOption(getOption(pipelineOptions, pipeline))
    }

    if (getStatusValue() != prevArchiveStatusOption) {
      setArchiveStatusOption(getOption(statusOptions, getStatusValue()))
    }

    if (!isEqual(filtersForSupplierListInRequest, prevSupplierMappingOption)) {
      setSupplierMappingOption(
        find(supplierMappingOptions, { value: filtersForSupplierListInRequest })
      )
    }

    if (!isEqual(requestAssigneeMappingCriterion, prevAssigneeMappingOption)) {
      setAssigneeMappingOption(
        find(requestAssigneeMappingCriterionOptions, {
          value: requestAssigneeMappingCriterion,
        })
      )
    }

    setLoading(false)
  }, [
    markRequest,
    autoAssign,
    autoAssignAdmin,
    reversedChatTabs,
    markEmailAsRead,
    autoArchiveDoneRequest,
    autoArchiveRequestDelay,
    autoArchiveRequestStatus,
    filtersForSupplierListInRequest,
    requestAssigneeMappingCriterion,
    pipeline,
  ])

  const handleChange = label => option => {
    switch (label) {
      case MARK_REQUEST_OPTION:
        setMarkRequestOption(option)
        break
      case AUTO_ASSIGN_OPTION:
        setAutoAssignOption(option)
        break
      case AUTO_ASSIGN_ADMIN_OPTION:
        setAutoAssignAdminOption(option)
        break
      case REVERSED_CHAT_TABS_OPTION:
        setReversedChatTabsOption(option)
        break
      case MARK_EMAIL_AS_READ:
        setMarkEmailAsReadOption(option)
        break
      case AUTO_ARCHIVE_OPTION:
        setArchiveRequestOption(option)
        break
      case AUTO_ARCHIVE_STATUS_OPTION:
        setArchiveStatusOption(option)
        break
      case SUPPLIER_MAPPING_OPTION:
        setSupplierMappingOption(option)
        break
      case ASSIGNEE_MAPPING_OPTION:
        setAssigneeMappingOption(option)
        break
      case PIPELINE_OPTION:
        setPipelineOption(option)
        break
      default:
        return null
    }
  }

  const handleSave = () => {
    setLoading(true)

    props.dispatch({
      type: SETTINGS_CORPORATE_SAVE_CONFIG,
      params: {
        mark_request_as_read_for_building_managers: markRequestOption.value,
        auto_assign_building_manager_on_request: autoAssignOption.value,
        auto_assign_building_admin_on_request: autoAssignAdminOption.value,
        reversed_request_chat_tabs: reversedChatTabsOption.value,
        mark_email_as_read_after_attach_to_request: markEmailAsReadOption.value,
        auto_archive_done_request: archiveRequestOption.value,
        filters_for_supplier_list_in_request: supplierMappingOption.value,
        request_assignee_mapping_criterion: assigneeMappingOption.value,
        pipeline_autostart_criterion: pipelineOption.value,
        auto_archive_done_request_delay: archiveRequestOption.value
          ? archiveRequestDelay
          : DEFAULT_DELAY,
        auto_archive_done_request_status:
          archiveStatusOption.value ===
          REQUEST_STATUS_COMPLETED * REQUEST_STATUS_DENIED
            ? [REQUEST_STATUS_COMPLETED, REQUEST_STATUS_DENIED]
            : [archiveStatusOption.value],
      },
    })
  }

  const handleCancel = () => {
    setMarkRequestOption(defaultMarkRequestOption)
    setAutoAssignOption(defaultAutoAssignOption)
    setAutoAssignAdminOption(defaultAutoAssignAdminOption)
    setReversedChatTabsOption(defaultReversedChatTabsOption)
    setMarkEmailAsReadOption(defaultMarkEmailAsReadOption)
    setArchiveRequestOption(defaultArchiveRequestOption)
    setArchiveRequestDelay(defaultArchiveDelay)
    setArchiveStatusOption(defaultArchiveStatusOption)
    setSupplierMappingOption(defaultSupplierMappingOption)
    setAssigneeMappingOption(defaultRequestAssigneeMappingCriterion)
    setPipelineOption(defaultPipelineOption)
  }

  const sectionClass = classnames(
    'settings-personal unit unit--default settings-regional',
    {
      'working-overlay': loading,
    }
  )

  const submitSectionClass = classnames('settings-personal__submit', {
    'working-overlay': loading,
  })

  const isDefaultOptions = () =>
    isDefaultOption(defaultMarkRequestOption, markRequestOption) &&
    isDefaultOption(defaultAutoAssignOption, autoAssignOption) &&
    isDefaultOption(defaultAutoAssignAdminOption, autoAssignAdminOption) &&
    isDefaultOption(defaultReversedChatTabsOption, reversedChatTabsOption) &&
    isDefaultOption(defaultMarkEmailAsReadOption, markEmailAsReadOption) &&
    isDefaultOption(defaultArchiveRequestOption, archiveRequestOption) &&
    archiveRequestDelay == defaultArchiveDelay &&
    isDefaultOption(defaultArchiveStatusOption, archiveStatusOption) &&
    isDefaultOption(defaultPipelineOption, pipelineOption) &&
    isEqual(defaultSupplierMappingOption, supplierMappingOption) &&
    isEqual(defaultRequestAssigneeMappingCriterion, assigneeMappingOption)

  const getRequestDelayError = () => {
    if (archiveRequestOption.value) {
      if (!archiveRequestDelay) {
        return t('ArchiveRequestsDueRequired')
      }

      if (archiveRequestDelay < DELAY_MIN || archiveRequestDelay > DELAY_MAX) {
        return t('ArchiveRequestsDueError')
      }

      if (archiveRequestDelay != Math.floor(archiveRequestDelay)) {
        return t('ArchiveRequestsDueInteger')
      }

      return null
    } else {
      return null
    }
  }

  return (
    <div>
      <section className={sectionClass}>
        <h2 className='unit__title'>{t('RequestSectionTitle')}</h2>
        <Option label={t('MarkForManagers')}>
          <NewSelectSimple
            name='mark-for-managers'
            value={markRequestOption}
            options={requestOptions}
            onChange={handleChange(MARK_REQUEST_OPTION)}
          />
        </Option>
        <Option label={t('AutoAssignBuildingManager')}>
          <NewSelectSimple
            name='auto-assign-building-manager'
            value={autoAssignOption}
            options={requestOptions}
            onChange={handleChange(AUTO_ASSIGN_OPTION)}
          />
        </Option>
        <Option label={t('AutoAssignBuildingAdmin')}>
          <NewSelectSimple
            name='auto_assign_building_admin_on_request'
            value={autoAssignAdminOption}
            options={requestOptions}
            onChange={handleChange(AUTO_ASSIGN_ADMIN_OPTION)}
          />
        </Option>
        <Option label={t('AssigneeMappingTitle')}>
          <NewSelectSimple
            name='assignee-mapping'
            value={assigneeMappingOption}
            options={requestAssigneeMappingCriterionOptions}
            onChange={handleChange(ASSIGNEE_MAPPING_OPTION)}
          />
        </Option>
        <Option label={t('ReversedRequestChatTabs')}>
          <NewSelectSimple
            name='reversed-request-chat-tabs'
            value={reversedChatTabsOption}
            options={requestOptions}
            onChange={handleChange(REVERSED_CHAT_TABS_OPTION)}
          />
        </Option>
        <Option label={t('MarkEmailAsRead')}>
          <NewSelectSimple
            name='mark-email-as-read-after-attach-to-request'
            value={markEmailAsReadOption}
            options={requestOptions}
            onChange={handleChange(MARK_EMAIL_AS_READ)}
          />
        </Option>
        <Option label={t('ArchiveRequests')}>
          <NewSelectSimple
            name='auto-archive-done-request'
            value={archiveRequestOption}
            options={requestOptions}
            onChange={handleChange(AUTO_ARCHIVE_OPTION)}
          />
        </Option>
        {archiveRequestOption.value && (
          <>
            <Option label={t('ArchiveRequestsDue')}>
              <InputField
                value={archiveRequestDelay}
                error={getRequestDelayError()}
                type='number'
                name='auto-archive-done-request-delay'
                placeholder={t('ArchiveRequestsDue')}
                onChange={e => setArchiveRequestDelay(e.target.value)}
              />
            </Option>
            <Option label={t('ArchiveRequestsStatus')}>
              <NewSelectSimple
                name='auto-archive-status'
                value={archiveStatusOption}
                options={statusOptions}
                onChange={handleChange(AUTO_ARCHIVE_STATUS_OPTION)}
              />
            </Option>
          </>
        )}
        <Option label={t('SupplierFilterMappingTitle')}>
          <NewSelectSimple
            name='supplier-filter-mapping'
            value={supplierMappingOption}
            options={supplierMappingOptions}
            onChange={handleChange(SUPPLIER_MAPPING_OPTION)}
          />
        </Option>
        <Option label={t('PipelineOptionTitle')}>
          <NewSelectSimple
            name='pipeline'
            value={pipelineOption}
            options={pipelineOptions}
            onChange={handleChange(PIPELINE_OPTION)}
          />
        </Option>
        <div className={submitSectionClass}>
          <Button.Save
            type='button'
            disabled={loading || getRequestDelayError() || isDefaultOptions()}
            onClick={handleSave}
          >
            {props.t('Common:Save')}
          </Button.Save>
          <Button.Cancel
            type='button'
            disabled={loading || isDefaultOptions()}
            onClick={handleCancel}
          >
            {props.t('Common:Cancel')}
          </Button.Cancel>
        </div>
      </section>
    </div>
  )
}

export default compose(connect(), withTranslation('Settings'))(RequestSettings)
