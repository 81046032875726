// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

const PasswordResetError = (): Node => {
  const { t } = useTranslation('Registration')

  return <div className='login_anno_text'>{t('ErrorTitleReset')}</div>
}

export default PasswordResetError
