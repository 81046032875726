// @flow

import React from 'react'
import type { Node } from 'react'

import NewSelectSimple from '../../../NewSelectSimple'
import Button from '../../../Button'

import styles from './ActivityModalPriority.module.scss'

type Props = {
  className: string,
  disabled: boolean,
  onChange: Object => void,
  onClear: () => void,
  onToggle: boolean => void,
  options: Array<Object>,
  placeholder: string,
  value: Object | '',
}

const ActivityModalPriority = (props: Props): Node => {
  const { disabled, value, options, placeholder, className } = props

  return (
    <div className={styles.priority}>
      <NewSelectSimple
        className={className}
        toggleMenuCb={props.onToggle}
        placeholder={placeholder}
        options={options}
        value={value}
        onChange={props.onChange}
      />
      <Button.Delete
        disabled={disabled}
        className={styles.clear}
        onClick={props.onClear}
      />
    </div>
  )
}

export default ActivityModalPriority
