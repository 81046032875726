// @flow

import React, { useContext } from 'react'
import type { Node } from 'react'

import Label from './Label'
import {
  getFormattedDate,
  getUserName,
} from '../../../../../../../../../../utils/utils'
import { isExpired } from '../../../../../../../../../../components/Post/PostInfo/PostInfo.utils'
import ActivityItemContext from '../../ActivityItem.context'

import styles from './ItemLabels.module.scss'

const ItemLabels = ({ isDone }): Node => {
  const {
    activity: { manager, deadline },
  } = useContext(ActivityItemContext)

  const deadlineClass = isDone
    ? 'done'
    : isExpired(deadline)
    ? 'expired'
    : 'active'

  return (
    <div className={styles['item-labels']}>
      {deadline && (
        <Label
          text={getFormattedDate(deadline, { specifyHours: true })}
          iconId='deadline'
          deadlineClass={deadlineClass}
        />
      )}
      <Label text={getUserName(manager)} iconId='manager' />
    </div>
  )
}

export default ItemLabels
