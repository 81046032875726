// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'
import NumberFormat from 'react-number-format'
import moment from 'moment'
import { getFormattedDate } from '../../../../utils/utils'

import NewTableBodyRow from '../../../NewTable/NewTableBodyRow'
import NewTableBodyCell from '../../../NewTable/NewTableBodyCell'
import Checkbox from '../../../Checkbox'
import SelectCustom from '../../../Select/SelectCustom'
import FilesIcon from '../../../../../static/icons/file-paperclip.svg'
import styles from './InvoicesTable.module.scss'

type Props = {
  fields: Array<Object>,
  invoice: Object,
  onClick: Function,
  onSelect: Function,
  openRemoveModal: Function,
  selectedItems: Array<number>,
  setEditingInvoice: Function,
}

const InvoicesTableRow = (props: Props): Node => {
  const {
    fields,
    invoice: { id, fields: invoice_fields, invoice_file },
    selectedItems,
    invoice,
  } = props

  const { t } = useTranslation('BudgetPlan')

  const handleClick = () => props.onClick(invoice)
  const handleSelect = () => props.onSelect(id)

  const options = [
    {
      value: 'onEdit',
      label: t('EditInvoice'),
      handler: () => props.setEditingInvoice(invoice),
      icon: 'pencil',
    },
    {
      value: 'onRemove',
      label: t('RemoveInvoice'),
      handler: () => props.openRemoveModal(id),
      icon: 'bin',
    },
  ]

  return (
    <NewTableBodyRow onClick={handleClick}>
      <NewTableBodyCell>
        <Checkbox
          className={styles.checkbox}
          checked={selectedItems.includes(id)}
          onChange={handleSelect}
        />

        {invoice_file && (
          <div className={styles.filesIcon}>
            <FilesIcon />
          </div>
        )}
      </NewTableBodyCell>
      {fields.map((f, i) => {
        let value = invoice_fields.find(field => field.name === f.name)?.value

        switch (f.type) {
          case 'supplier':
            value = value?.fullname || ''
            break
          case 'select':
            value = f.options?.find(o => o.value === value)?.label || ''
            break
          case 'float':
            value = (
              <NumberFormat
                fixedDecimalScale
                isNumericString
                displayType='text'
                placeholder='0,00'
                decimalSeparator=','
                thousandSeparator='.'
                decimalScale={2}
                value={value}
              />
            )
            break
          case 'date':
            value =
              value &&
              getFormattedDate(moment(value).format('YYYY-MM-DD'), {
                onlyDate: true,
              })
            break
        }

        return i < fields.length - 1 ? (
          <NewTableBodyCell key={i} title={value} />
        ) : (
          <NewTableBodyCell key={i}>
            <div className='table-item table-item__text' title={value}>
              <span>{value}</span>
            </div>
            <div className='table-extra table-extra--settings'>
              <SelectCustom options={options} onChange={opt => opt.handler()} />
            </div>
          </NewTableBodyCell>
        )
      })}
    </NewTableBodyRow>
  )
}

export default InvoicesTableRow
