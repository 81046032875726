/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type OrderProfileProvider_data$ref: FragmentReference;
declare export opaque type OrderProfileProvider_data$fragmentType: OrderProfileProvider_data$ref;
export type OrderProfileProvider_data = {|
  +provider: {|
    +id: string,
    +name: string,
    +image: ?{|
      +origin: ?string
    |},
  |},
  +$refType: OrderProfileProvider_data$ref,
|};
export type OrderProfileProvider_data$data = OrderProfileProvider_data;
export type OrderProfileProvider_data$key = {
  +$data?: OrderProfileProvider_data$data,
  +$fragmentRefs: OrderProfileProvider_data$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "OrderProfileProvider_data",
  "type": "CustomerOrderNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "provider",
      "storageKey": null,
      "args": null,
      "concreteType": "ProviderNode",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "id",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "name",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "image",
          "storageKey": null,
          "args": null,
          "concreteType": "ProviderImageNode",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "origin",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '5b19905ca2df4d9b1ec4294a60e007a5';
module.exports = node;
