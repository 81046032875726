// @flow

import React from 'react'
import type { Node } from 'react'
import type { Match } from 'react-router-dom'

import EmailIntegrationEdit from './EmailIntegrationEdit'

type Props = { match: Match }

const PrivateEmailIntegrationEdit = (props: Props): Node => {
  const { match } = props

  return <EmailIntegrationEdit isPrivate match={match} />
}

export default PrivateEmailIntegrationEdit
