// @flow

import React, { Component } from 'react'
import { push } from 'connected-react-router'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'

import CellButton from './CellButton'
import { USER_GROUPS } from '../../../../../constants'
import { getUserId } from '../../../../../utils/utils'
import type { Props } from './index'

type AddressProps = Props & {
  onClick: Object => void,
  t: string => string,
}

class Address extends Component<AddressProps> {
  getCount = () => {
    const { buildings, flats_count: flatsCount, building } = this.props.user

    if (this.isDweller()) {
      return flatsCount
    }

    return Array.isArray(buildings)
      ? buildings.length
      : buildings?.count || (building ? 1 : 0)
  }

  getText = () => {
    const {
      user: { address, building, buildings, flat_number: flatNumber },
    } = this.props

    if (this.isDweller()) {
      let text = building?.address || address || this.props.t('NoFlats')

      if (flatNumber) {
        text += ` ${this.props.t('Common:flatNumber')} ${flatNumber}`
      }

      return text
    }

    return buildings && buildings.length
      ? buildings[0]
      : building?.address || buildings?.title || ''
  }

  isDweller = () =>
    this.props.user.group === USER_GROUPS.dweller ||
    this.props.user.role === USER_GROUPS.dweller

  preventOpen = e => {
    e.stopPropagation()
  }

  open = () => {
    const { user } = this.props

    this.props.dispatch(push(`/profile/${getUserId(user)}`))
  }

  renderButton = (text: string) => {
    const { user, onClick } = this.props

    return <CellButton onClick={() => onClick(user)}>{text}</CellButton>
  }

  renderValue = () => {
    const { user, archived } = this.props
    const count = this.getCount()

    if (!count && !this.isDweller() && !archived) {
      return (
        <div>
          {user.validated
            ? this.renderButton(this.props.t('NoBuilding'))
            : this.props.t('NoBuilding')}
        </div>
      )
    }

    const text = this.getText()

    if (!user.validated || archived) {
      return <div>{text}</div>
    }

    return (
      <div>
        {count > 1 ? (
          <div>
            <span className='table-item__text-building-first' title={text}>
              {text}
            </span>
            {this.renderButton(`${this.props.t('ShowMore')} ${count - 1}`)}
          </div>
        ) : (
          <div title={text}>{this.renderButton(text)}</div>
        )}
      </div>
    )
  }

  render() {
    const { user } = this.props

    return (
      <div className='table__cell' onClick={!user.validated ? this.open : null}>
        <div className='table__cell-wrapper'>
          <div className='table-item table-item__text-building'>
            {this.renderValue()}
          </div>
        </div>
      </div>
    )
  }
}

export default compose(withTranslation('User'), connect())(Address)
