// @flow

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import Joi from 'joi-browser'
import validation from 'react-validation-mixin'
import validationStrategy from 'joi-browser-validation-strategy'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { debounce, join } from 'lodash-es'
import { compose } from 'redux'

import Input from './input'
import SuggestList from './suggest-list'

import { getUkCity } from '../../utils/commonSelectors'
import defaults from './defaults'
import filterInputAttributes from './filter-input-attributes'
import styles from './Geosuggest.module.scss'

type ValidateCallback = boolean => void
type Props = {
  autoActivateFirstSuggest?: boolean,
  city: ?Object,
  fixtures: Array<Object>,
  getSuggestLabel: string => string,
  getValidationMessages: string => Array<string>,
  ignoreTab?: boolean,
  initialValue: string,
  onActivateSuggest: Object => void,
  onBlur: string => void,
  onFocus: () => void,
  onInputChange: (?string) => void,
  onSelect: Object => void,
  onSuggestNoResults: (?string) => void,
  onSuggestSelect: Object => void,
  queryDelay: number,
  skipSuggest: Object => boolean,
  t: string => string,
  validate: (?ValidateCallback) => void,
}

function escapeRegExp(str: string) {
  const reg = new RegExp('[-\\[\\]\\/\\{\\}\\(\\)\\*\\+\\?\\.\\\\^$\\|]', 'g')

  return str.replace(reg, '\\$&')
}

class Geosuggest extends Component<Props> {
  static defaultProps = defaults

  state = {
    isSuggestsHidden: true,
    inputText: this.props.initialValue,
    userInput: this.props.initialValue,
    activeSuggest: null,
    suggests: [],
    timer: null,
    isError: false,
  }

  componentDidUpdate(prev: Props) {
    const { initialValue, error } = this.props

    if (prev.initialValue !== initialValue) {
      this.setState({ userInput: initialValue, isError: false })
    }

    const { userInput, isError } = this.state

    if (userInput === '' && error && !isError) {
      this.props.validate()
      this.setState({ isError: true })
    }
  }

  componentWillUnmount() {
    clearTimeout(this.state.timer)
  }

  onInputChange = userInput => {
    this.setState(
      {
        userInput,
        inputText: userInput,
      },
      debounce(this.onAfterInputChange, this.props.queryDelay)
    )
  }

  onAfterInputChange = () => {
    this.showSuggests()
    this.props.onInputChange(this.state.userInput)
  }

  onInputFocus = () => {
    this.props.onFocus()
    this.showSuggests()
  }

  onInputBlur = () => {
    const { validate } = this.props
    validate(error => {
      if (error && !this.state.isError) {
        this.setState({ isError: true })
      } else if (this.state.isError) {
        this.setState({ isError: false })
      }
    })

    if (!this.state.ignoreBlur) {
      this.hideSuggests()
    }
  }

  onNext = () => {
    this.activateSuggest('next')
  }

  onPrev = () => {
    this.activateSuggest('prev')
  }

  onSelect = () => {
    const { isSuggestsHidden, suggests } = this.state
    const suggest =
      !isSuggestsHidden && suggests.length === 1
        ? suggests[0]
        : this.state.activeSuggest

    this.selectSuggest(suggest)
  }

  onSuggestMouseDown = () => this.setState({ ignoreBlur: true })

  onSuggestMouseOut = () => this.setState({ ignoreBlur: false })

  onSuggestNoResults = () => {
    this.props.onSuggestNoResults(this.state.userInput)
  }

  getValidatorData = () => {
    const { userInput } = this.state

    return {
      address: userInput,
    }
  }

  validatorTypes = {
    address: Joi.string()
      .options({
        language: {
          any: {
            empty: `!! ${this.props.t('FieldIsRequired')}`,
            required: `!! ${this.props.t('FieldIsRequired')}`,
          },
        },
      })
      .label('address')
      .required()
      .empty(),
  }

  searchSuggests(worldSearch = false) {
    const { userInput } = this.state

    if (!userInput) {
      this.updateSuggests()

      return
    }

    let searchAddr = userInput

    const { city } = this.props

    const cityName = city ? city.name : ''

    if (!worldSearch) {
      searchAddr = `${cityName}, ${searchAddr}`
    }

    const googleMapSearchResults = predictions => {
      if (worldSearch && !predictions) return

      let items = []

      if (predictions && predictions.length) {
        items = predictions.map(prediction => ({
          description: prediction.description,
          place_id: prediction.place_id,
          id: prediction.id,
          value: prediction.description,
        }))
      }

      this.updateSuggests(items, () => {
        if (this.props.autoActivateFirstSuggest && !this.state.activeSuggest) {
          this.activateSuggest('next')
        }
      })
    }

    const service = new window.google.maps.places.AutocompleteService()
    const searchQueryParams = {
      input: searchAddr,
      types: ['geocode'],
    }

    service.getPlacePredictions(searchQueryParams, googleMapSearchResults)
  }

  updateSuggests(suggestsGoogle = [], callback) {
    const suggests = []
    const regex = new RegExp(escapeRegExp(this.state.userInput), 'gim')
    const skipSuggest = this.props.skipSuggest
    const maxFixtures = 10
    let fixturesSearched = 0

    this.props.fixtures.forEach(suggest => {
      if (fixturesSearched >= maxFixtures) {
        return
      }

      if (!skipSuggest(suggest) && suggest.label.match(regex)) {
        fixturesSearched += 1

        suggest.placeId = suggest.label; // eslint-disable-line
        suggest.isFixture = true; // eslint-disable-line
        suggests.push(suggest)
      }
    })

    suggestsGoogle.forEach(suggest => {
      if (!skipSuggest(suggest)) {
        suggests.push({
          label: this.props.getSuggestLabel(suggest),
          placeId: suggest.place_id,
          isFixture: false,
          ...suggest,
        })
      }
    })

    const activeSuggest = this.updateActiveSuggest(suggests)

    if (suggests.length === 0 && this.state.userInput) {
      // TODO no internal loop
      this.searchSuggests(true)
    }

    this.setState({ suggests, activeSuggest }, callback)
  }

  updateActiveSuggest(suggests = []) {
    let activeSuggest = this.state.activeSuggest

    if (activeSuggest) {
      const newSuggest = suggests.find(
        listedSuggest =>
          activeSuggest.placeId === listedSuggest.placeId &&
          activeSuggest.isFixture === listedSuggest.isFixture
      )

      activeSuggest = newSuggest || null
    }

    return activeSuggest
  }

  showSuggests() {
    this.searchSuggests()
    this.setState({ isSuggestsHidden: false })
  }

  hideSuggests = () => {
    this.props.onBlur(this.state.userInput)
    const timer = setTimeout(() => {
      this.setState({
        isSuggestsHidden: true,
        activeSuggest: null,
      })
    }, 100)

    this.setState({ timer })
  }

  activateSuggest(direction) {
    // eslint-disable-line complexity
    if (this.state.isSuggestsHidden) {
      this.showSuggests()

      return null
    }

    const suggestsCount = this.state.suggests.length - 1
    const next = direction === 'next'
    let newActiveSuggest = null
    let newIndex = 0
    let i = 0

    for (i; i <= suggestsCount; i += 1) {
      if (this.state.suggests[i] === this.state.activeSuggest) {
        newIndex = next ? i + 1 : i - 1
      }
    }

    if (!this.state.activeSuggest) {
      newIndex = next ? 0 : suggestsCount
    }

    if (newIndex >= 0 && newIndex <= suggestsCount) {
      newActiveSuggest = this.state.suggests[newIndex]
    }

    this.props.onActivateSuggest(newActiveSuggest)

    this.setState(prevState => ({
      userInput: newActiveSuggest
        ? newActiveSuggest.label
        : prevState.inputText,
      activeSuggest: newActiveSuggest,
    }))

    return newActiveSuggest
  }

  selectSuggest = suggest => {
    if (!suggest) {
      suggest = { // eslint-disable-line
        label: this.state.userInput,
      }
    }

    this.setState({
      isSuggestsHidden: true,
      userInput: suggest.label,
    })

    if (suggest.location) {
      this.setState({ ignoreBlur: false })
      this.props.onSuggestSelect(suggest)

      return
    }

    this.props.onSelect(suggest)
    this.geocodeSuggest(suggest)
  }

  geocodeSuggest(suggest) {
    if (suggest && !suggest.place_id) return

    const geocoder = new window.google.maps.Geocoder()
    geocoder.geocode({ placeId: suggest.place_id }, results => {
      const latLng = results[0].geometry.location
      const suggestOptions = {
        label: results[0].formatted_address,
        lattitude: latLng.lat(),
        longitude: latLng.lng(),
      }
      this.props.onSelect(suggestOptions)
    })
  }

  renderError = field => {
    const { getValidationMessages } = this.props
    const validationMessages = getValidationMessages(field)
    const html = join(validationMessages, '<br />')

    return validationMessages.length > 0 ? (
      <div
        className='input__msg'
        dangerouslySetInnerHTML={{ __html: html }} // eslint-disable-line react/no-danger
      />
    ) : null
  }

  render() {
    const attributes = filterInputAttributes(this.props)
    const inputClassNames = classnames(
      'input input--default input--block input--large input--font-xlarge input--search input--dropdown',
      {
        'input--error': this.state.isError,
      }
    )
    const iconClassName = classnames('input__icon', styles.icon)

    return (
      <div className='geosuggest'>
        <div className={inputClassNames}>
          <Input
            value={this.state.userInput}
            ignoreTab={this.props.ignoreTab}
            onChange={this.onInputChange}
            onFocus={this.onInputFocus}
            onBlur={this.onInputBlur}
            onNext={this.onNext}
            onPrev={this.onPrev}
            onSelect={this.onSelect}
            onEscape={this.hideSuggests}
            {...attributes}
            placeholder={this.props.t('EnterAddress')}
          />
          <div className={iconClassName} />
          {this.state.isError && this.renderError('address')}
        </div>

        <div className='geosuggest__suggests-wrapper'>
          <SuggestList
            isHidden={this.state.isSuggestsHidden}
            suggests={this.state.suggests}
            activeSuggest={this.state.activeSuggest}
            onSuggestNoResults={this.onSuggestNoResults}
            onSuggestMouseDown={this.onSuggestMouseDown}
            onSuggestMouseOut={this.onSuggestMouseOut}
            onSuggestSelect={this.selectSuggest}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  city: getUkCity(state),
})

export default compose(
  withTranslation('DefaultCity'),
  connect(mapStateToProps),
  validation(validationStrategy)
)(Geosuggest)
