// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../../Button'
import { useClearFilters } from '../../../hooks'

import styles from './MenuResetButton.module.scss'

const MenuResetButton = props => {
  const { menuName } = props

  const { t } = useTranslation('Menu')

  const [isDisabled, handleClear] = useClearFilters(menuName)

  return (
    <Button.Save
      disabled={isDisabled}
      className={styles.button}
      onClick={handleClear}
    >
      {t('ClearOrdering')}
    </Button.Save>
  )
}

export default MenuResetButton
