// @flow

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import TextareaAutosize from 'react-textarea-autosize'
import classnames from 'classnames'
import {
  omit,
  clone,
  values,
  unset,
  join,
  map,
  find,
  pick,
  get,
  flow,
  partialRight,
} from 'lodash-es'
import Joi from 'joi-browser'
import validation from 'react-validation-mixin'
import validationStrategy from 'joi-browser-validation-strategy'
import { Link } from 'react-router-dom'
import { compose } from 'redux'

import { getThemeColor } from '../../../utils/commonSelectors'
import * as actions from './AddManagerPopUp.actionTypes'
import { brToNewLine, newLineToBr } from '../../../utils/text'
import ValidationText from '../../ValidationText'
import NewSelectSimple from '../../NewSelectSimple'
import Button from '../../Button'
import Checkbox from '../../Checkbox'

import styles from './AddManagerPopUp.module.scss'

type Props = {}

type State = {
  isEmailMessageInvalid: boolean,
}

class AddManagerPopUp extends Component<Props, State> {
  static propTypes = {
    onHide: PropTypes.func,
    profile: PropTypes.object,
    users: PropTypes.array,
    message: PropTypes.string,
    reinvite: PropTypes.object,
    working: PropTypes.bool,
    saving: PropTypes.bool,
    send_invitation: PropTypes.bool,
    isSuperAdmin: PropTypes.bool,
    validate: PropTypes.func,
    clearValidations: PropTypes.func,
    getValidationMessages: PropTypes.func,
    isValid: PropTypes.func,
    dispatch: PropTypes.func,
    ukName: PropTypes.string,
    t: PropTypes.func,
    onSave: PropTypes.func,
  }

  static defaultProps = {
    profile: null,
  }

  state = { isEmailMessageInvalid: false }

  componentDidMount() {
    const { profile, t, ukName, users } = this.props
    const name = (users.length === 1 && users[0].name) || '%profile_name%'
    const message =
      this.props.message || t('DefaultManagerMessage', { name, ukName })

    document.body.style.overflowY = 'hidden'
    this.props.dispatch({
      type: actions.ADD_MANAGER_POPUP_INITIATE,
      profile,
      message: brToNewLine(message),
    })
  }

  componentDidUpdate(prevProps) {
    const { saving } = prevProps

    if (!this.props.error && saving && !this.props.saving) {
      this.props.onHide()
    }
  }

  componentWillUnmount() {
    this.props.dispatch({ type: actions.ADD_MANAGER_POPUP_RESET })
    document.body.style.overflowY = 'scroll'
  }

  onSubmit = event => {
    event.preventDefault()
    const { users, message, reinvite, send_invitation, onSave } = this.props

    const onValidate = error => {
      if (error) {
        return
      }

      const usersClone = clone(users)
      usersClone.forEach(user => unset(user, 'error'))
      usersClone.forEach(user => unset(user, 'errors'))
      const params = { users, message: newLineToBr(message), send_invitation }

      if (reinvite) {
        params.reinvite = reinvite
      }

      this.props.dispatch({
        type: actions.ADD_MANAGER_POPUP_SAVE,
        params,
        onSave,
      })
    }

    this.props.validate(onValidate)
  }

  getValidatorData = () => {
    const { users } = this.props

    return {
      users: users.map(user => ({
        name: user.name.trim(),
        second_name: user.second_name.trim(),
        email: user.email,
      })),
    }
  }

  setMessage = e => {
    const {
      target: { value },
    } = e

    const { maxLength } = this.props

    this.setState({ isEmailMessageInvalid: value.length > maxLength })

    this.props.dispatch({
      type: actions.ADD_MANAGER_POPUP_SET_MESSAGE,
      value,
    })
  }

  setSendInvitation = e => {
    this.props.dispatch({
      type: actions.ADD_MANAGER_POPUP_SET_SEND_INVITATION,
      value: e.currentTarget.checked,
    })
  }

  updateBuilding = ({ id }) => {
    this.props.dispatch({ type: actions.ADD_MANAGER_POPUP_SET_BUILDING, id })
  }

  validatorTypes = {
    users: Joi.array().items(
      Joi.object().keys({
        // If flat is provided in props then do not validate it
        // because flat form input is disabled and it's value is undefined
        name: Joi.string().required().label('Field'),
        second_name: Joi.string().required().label('Field'),
        email: Joi.string().email().required().label('Field'),
      })
    ),
  }

  addUser = () => {
    this.props.dispatch({ type: actions.ADD_MANAGER_POPUP_ADD_MANAGER })
  }

  deleteUser = index => {
    this.props.dispatch({ type: actions.DELETE_USER_POPUP, index })
    this.props.clearValidations()
  }

  updateField = (e, index) => {
    const {
      target: { name, value },
    } = e
    this.props.dispatch({
      type: actions.ADD_MANAGER_POPUP_UPDATE_USER,
      key: name,
      value,
      index,
    })
  }

  updateRole = (value, index) => {
    this.props.dispatch({
      type: actions.ADD_MANAGER_POPUP_UPDATE_USER,
      key: 'role',
      value,
      index,
    })
  }

  renderError = field => {
    const { getValidationMessages, t } = this.props
    const errors = getValidationMessages(field).map(err => t(err))
    const html = errors[0]

    return errors.length ? (
      <div className='input__msg' dangerouslySetInnerHTML={{ __html: html }} />
    ) : null
  }

  renderServerValidationsError = user => {
    const { errors } = user

    if (!errors) {
      return null
    }

    const { themeColor } = this.props

    let text = ''
    let userId = null

    if (typeof errors === 'string') {
      text = this.props.t(errors)
    } else {
      userId = get(pick(errors, 'user_id'), 'user_id')

      text = flow([
        partialRight(omit, 'user_id'),
        values,
        partialRight(map, err => this.props.t(err)),
        partialRight(join, '<br/>'),
      ])(errors)
    }

    return (
      <div className='m-a06__row'>
        <div className='input input--medium input--default input--block input--error'>
          <span
            className='input__msg'
            dangerouslySetInnerHTML={{ __html: text }}
          />
          {!!userId && (
            <span
              className={styles.profileLink}
              style={{ '--themeColor': themeColor }}
            >
              <Link to={`/profile/${userId}`}>
                {this.props.t('LinkToProfile')}
              </Link>
            </span>
          )}
        </div>
      </div>
    )
  }

  renderRoleSelect = (cell, index) => {
    const { users, t, isSuperAdmin } = this.props
    const roleList = [{ value: 'manager', label: t('Manager') }]

    if (isSuperAdmin) {
      roleList.push({ value: 'admin', label: t('Admin') })
    }

    const value = find(roleList, { value: users[index].role })
    const inputClass = classnames(
      'input input--medium input--default input--block'
    )

    return (
      <div className='m-a06__cell m-a06__cell--01 manage_or_admin'>
        <div className={inputClass}>
          <NewSelectSimple
            placeholder={t('SelectRole')}
            value={value}
            options={roleList}
            disabled={!isSuperAdmin}
            onChange={val => this.updateRole(val ? val.value : null, index)}
          />
        </div>
      </div>
    )
  }

  renderCellTitle = (cell, label) => (
    <div className={`m-a06__cell manage_or_admin m-a06__cell--${cell}`}>
      <span className='m-a06__label m-a06__label--01'>{label}</span>
    </div>
  )

  renderCellValue = (cell, index, key, placeholder) => {
    const { users, isValid } = this.props
    const joiKey = `users[${index}][${key}]`
    const inputClass = classnames(
      'input input--medium input--default input--block',
      {
        'input--error': !isValid(joiKey),
      }
    )

    return (
      <div className={`m-a06__cell m-a06__cell--${cell}`}>
        <div className={inputClass}>
          <input
            type='text'
            name={key}
            value={users[index][key]}
            placeholder={placeholder}
            onChange={e => this.updateField(e, index)}
          />
          {this.renderError(joiKey)}
        </div>
      </div>
    )
  }

  renderDeleteButton = (cell, index) => (
    <div className={`m-a06__cell m-a06__cell--${cell}`}>
      <button
        type='button'
        className='button button--default-3 button--square button--medium button--square-x'
        onClick={() => this.deleteUser(index)}
      >
        {this.props.t('Delete')}
      </button>
    </div>
  )

  render() {
    const {
      onHide,
      users,
      message,
      reinvite,
      working,
      saving,
      send_invitation,
      t,
      maxLength,
      ukName,
    } = this.props

    const { isEmailMessageInvalid } = this.state

    const wrapperClass = classnames('modal__content', {
      'working-overlay': working || saving,
    })

    const title = reinvite ? t('Send') : t('Title', { ukName })
    const okButtonTitle = reinvite ? t('ButtonSend') : t('Send')

    const textAreaClass = classnames(
      'textarea-2__input invite-message',
      styles.textarea
    )

    /* eslint react/no-array-index-key: 0 */
    return (
      <div className={wrapperClass}>
        <button className='modal__close' type='button' onClick={onHide} />
        <div className='modal__title' style={{ maxWidth: '760px' }}>
          {title}
        </div>
        <div className='m-a06 '>
          <div
            className='m-a06__row manager_or_admin m-a06__row--01'
            style={{ marginTop: '1rem' }}
          >
            {this.renderCellTitle('01', t('RoleTitle'))}
            {this.renderCellTitle('02', t('NameTitle'))}
            {this.renderCellTitle('02', t('LastnameTitle'))}
            {this.renderCellTitle('03', t('EmailTitle'))}
            {this.renderCellTitle('04', '')}
          </div>
          {users.map((user, i) => (
            <div className='add-user-popup-row' key={`user-${i}`}>
              <div className='m-a06__row  manager_or_admin m-a06__row--02'>
                {this.renderRoleSelect('01', i, 'role', t('Role'))}
                {this.renderCellValue('02', i, 'name', t('NamePlaceholder'))}
                {this.renderCellValue(
                  '02',
                  i,
                  'second_name',
                  t('LastnamePlaceholder')
                )}
                {this.renderCellValue('03', i, 'email', t('EmailPlaceholder'))}
                {this.renderDeleteButton('04', i)}
              </div>
              {this.renderServerValidationsError(user)}
            </div>
          ))}

          {!reinvite && (
            <div className='m-a06__row m-a06__row--03'>
              <Button.Upload onClick={this.addUser}>
                {t('AddOne')}
              </Button.Upload>
            </div>
          )}

          <div className='m-a06__row m-a06__03'>
            <span
              className='m-a06__label m-a06__label--01'
              style={{ marginTop: 10 }}
            >
              {t('Message')}
            </span>
            <div className='m-a06__02 textarea-2 textarea-2--large textarea-2--block textarea-2--default'>
              <TextareaAutosize
                className={textAreaClass}
                value={message}
                onChange={this.setMessage}
              />
              <div className='textarea-2__placeholder'>
                {t('MessagePlaceholder')}
                <br />
                {t('MessageLength')}
              </div>
            </div>
            <ValidationText
              active={isEmailMessageInvalid}
              text={t('errorLengthEmailMessage', {
                maxLength: maxLength.toString(),
              })}
            />
          </div>
          <div className='m-a06__control modal__control'>
            <Button.Save
              working={saving}
              disabled={isEmailMessageInvalid}
              onClick={this.onSubmit}
            >
              {okButtonTitle}
            </Button.Save>
            <Button.Cancel onClick={onHide}>{t('Cancel')}</Button.Cancel>
            <Checkbox
              className={styles.sendInvite}
              text={t('SendInvite')}
              name='send_invite'
              checked={send_invitation}
              onChange={this.setSendInvitation}
            />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  themeColor: getThemeColor(state),
  isSuperAdmin: state.login.user.permissions.can_change_manager_role,
  maxLength: state.init.uk_config.invite_max_length,
})

export default compose(
  withTranslation('AddUserPopup'),
  connect(mapStateToProps),
  validation(validationStrategy)
)(AddManagerPopUp)
