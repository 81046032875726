// @flow

import React from 'react'
import type { Node } from 'react'

import PostHeader from '../../Post/PostHeader/PostHeader'

type Props = {
  collapsed: boolean,
  data?: ?Object,
  dateFormat: string,
  outbound?: string,
}

const RequestBodyHeader = (props: Props): Node => {
  const { data, dateFormat, outbound } = props

  if (!data) {
    return null
  }

  const { created, created_by_obj: owner } = data

  return (
    <PostHeader
      outbound={outbound}
      owner={owner}
      date={created}
      dateFormat={dateFormat}
    />
  )
}

export default RequestBodyHeader
