// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'

import Widget from '../../../containers/Widget'
import { WIDGET_TYPES } from '../../../containers/widgets/widgets'
import ProviderListItem from './ProviderListItem'

const ProvidersList = props => {
  const { t } = useTranslation('Request')
  const { list } = props

  const noProviders = (
    <div className='bar__submit-label_light bar__submit-label no-manager-assignet-label'>
      {t('NoProviders')}
    </div>
  )

  const renderList =
    list.length > 0
      ? list.map(item => <ProviderListItem key={item.id} item={item} />)
      : noProviders

  return (
    <Widget name={WIDGET_TYPES.providers} title={t('Common:Providers')}>
      {renderList}
    </Widget>
  )
}

export default ProvidersList
