// @flow

import React from 'react'
import type { Node } from 'react'

import styles from './Container.module.scss'

type Props = {
  children: Node,
}

const Container = (props: Props) => {
  const { children } = props

  return <div className={styles.container}>{children}</div>
}

export default Container
