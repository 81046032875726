// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'
import { map, isEmpty, partial } from 'lodash-es'
import { compose } from 'redux'
import type { Node } from 'react'

import AddManagerPopUp from '../../modals/AddManagerPopUp'
import AddUserPopup from '../../modals/AddUserPopup/AddUserPopup'
import BottomPagination from '../../Pagination/BottomPagination'
import BuildingPopup from '../../modals/BuildingPopup'
import CategoryPopup from '../../modals/CategoryPopup'
import Button from '../../Button'
import ChangeRolePopup from '../../modals/ChangeRolePopup'
import ConfirmationPopup from '../../modals/ConfirmationPopup'
import EmplyList from '../../EmptyList'
import Loader from '../../Loader'
import Modal from '../../Modal'
import ResendInvitePopUp from '../../modals/ResendInvitePopUp'
import SelectCustom from '../../Select/SelectCustom'
import Table from '../../Table'
import TopPagination from '../../Pagination/TopPagination'
import { USER_GROUPS } from '../../../constants'
import UserItem from './UserItem/index'
import { getLocation } from '../../../utils/commonSelectors'
import { getCurrentFilters, getCurrentPage } from '../../../utils/routing'
import * as actions from './UserList.actionTypes'
import addRedirectToFirstPage from '../../../hocs/addRedirectToFirstPage'
import Checkbox from '../../Checkbox'

type Props = {
  dispatch: Object => void,
  externalContact?: boolean,
  filter: Object,
  initiated: boolean,
  isStaff?: boolean,
  location: Object,
  meta: Object,
  onCompleteResend: string => void,
  onInit: () => void,
  permissions: Object,
  roles: Object,
  selected: Array<number>,
  statuses: Object,
  t: string => string,
  users: Array<Object>,
  working: boolean,
}
type State = {
  modal: ?Node,
}

class UserList extends Component<Props, State> {
  state = {
    modal: null,
  }

  constructor(props) {
    super(props)
    this.statusOptions = this.getStatusOptions(props)
  }

  componentDidMount() {
    const { location, isStaff, externalContact } = this.props

    const filters = getCurrentFilters(location)

    if (filters.page) {
      this.props.dispatch({
        type: actions.USER_LIST_INITIATING,
        isStaff,
        externalContact,
        params: {
          ...filters,
        },
      })
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { initiated, location, working, reload } = this.props

    if (initiated && !prevProps.initiated) {
      this.props.onInit()
    }

    if (prevProps.working && !working) {
      this.hideModal()
    }

    if (location.search !== prevProps.location.search) {
      this.load()
    }

    if (reload && !prevProps.reload) {
      this.load()
    }
  }

  componentWillUnmount() {
    this.props.dispatch({
      type: actions.USER_LIST_CLEANUP,
    })
  }

  getGroup = () =>
    this.props.isStaff
      ? [USER_GROUPS.admin, USER_GROUPS.manager]
      : USER_GROUPS.dweller

  getParams = (props = this.props) => {
    const { location } = props

    const page = getCurrentPage(location)

    return {
      page,
      group: this.getGroup(),
      ...getCurrentFilters(location),
    }
  }

  getStatusOptions = props => {
    const { statuses, t } = props

    return map(statuses, (val, label) => ({
      code: val,
      value: label,
      label: t(`Common:${label}`),
    }))
  }

  getMassActionOptions() {
    const { t } = this.props
    const options = [
      {
        value: 'changeRole',
        label: t('ChangeRole'),
        handler: () => this.confirmRoleUpdating(),
        icon: 'pencil',
      },
      {
        value: 'sentInvitation',
        label: t('ResendInviteToMany'),
        handler: () => this.confirmReinviting(),
        icon: 'forward',
      },
      {
        value: 'approveSignup',
        label: t('ApproveButtonTitle'),
        handler: () => this.confirmApproving(),
        icon: 'check',
      },
    ]

    return options
  }

  getAdminMassActionOptions() {
    const { t, users } = this.props

    const userIds = map(users, 'id')

    const options = [
      {
        value: 'addCategories',
        label: t('AddCategories'),
        handler: () => this.showCategoryModal(null, userIds),
        icon: 'pencil',
      },
    ]

    return options
  }

  props: Props

  preventOpen = e => {
    e.stopPropagation()
  }

  load = () => {
    const { location, isStaff, externalContact } = this.props

    const page = getCurrentPage(location)
    const filters = getCurrentFilters(location)

    this.props.dispatch({
      type: actions.USER_LIST_LOAD,
      isStaff,
      externalContact,
      params: {
        page,
        ...filters,
      },
    })
  }

  showToolbox = () => {
    const {
      permissions: {
        can_create: canCreate,
        can_delete: canDelete,
        can_restore: canRestore,
      },
      meta,
    } = this.props

    return canCreate || canDelete || canRestore || meta.count > meta.limit
  }

  toggle = e => {
    this.props.dispatch({
      type: actions.USER_LIST_TOGGLE,
      owner: parseInt(e.target.value, 10),
    })
  }

  toggleAll = () => {
    this.props.dispatch({ type: actions.USER_LIST_TOGGLE_ALL })
  }

  hideModal = () => {
    this.setState({ modal: null })
  }

  updateUser = (id, params, tiny = false) => {
    this.props.dispatch({
      type: actions.USER_LIST_UPDATE_ITEM,
      id,
      params,
      tiny,
    })
  }

  addUser = () => {
    const props = {
      onHide: this.hideModal,
      onSave: this.load,
    }
    this.setState({
      modal: this.props.isStaff ? (
        <AddManagerPopUp {...props} />
      ) : (
        <AddUserPopup {...props} />
      ),
    })
  }

  reinvite = () => {
    const { t, onCompleteResend } = this.props

    onCompleteResend(t('IsSended'))
    this.load()
  }

  approve = users => {
    this.props.dispatch({
      type: actions.USER_LIST_APROVE_USERS,
      users,
      params: this.getParams(),
    })
  }

  updateManagerBuildings = (user, ids) => {
    const { dispatch } = this.props

    dispatch({
      type: actions.USER_LIST_SET_MANAGER_BUILDINGS,
      owner: user.id,
      ids,
    })
  }

  updateDwellerFlats = ({ id, permissions }, flats) => {
    if (flats.length || !permissions?.can_manage_all_user_flats) {
      this.updateUser(id, { flats }, true)
    } else {
      this.remove(id)
    }
  }

  updateCategories = (user, ids) => {
    const { dispatch } = this.props

    dispatch({
      type: actions.USER_LIST_SET_MANAGER_CATEGORIES,
      manager: user.id,
      ids,
    })
  }

  massUpdateCategories = (userIds, ids) => {
    const { dispatch } = this.props

    dispatch({
      type: actions.USER_LIST_MASS_SET_MANAGER_CATEGORIES,
      managers: userIds,
      ids,
    })
  }

  remove = (owner = null) => {
    const { selected, users, meta } = this.props
    const idsToDelete = owner ? [owner] : selected
    const values = idsToDelete.filter(id => {
      const user = users.find(u => u.id === id)

      return user && user.permissions && user.permissions.can_delete
    })

    this.props.dispatch({
      type: actions.USER_LIST_REMOVE,
      values,
      meta,
      params: this.getParams(),
    })
  }

  restore = (owner = null) => {
    const { selected, users, meta } = this.props

    const idsToRestore = owner ? [owner] : selected
    const values = idsToRestore.filter(id => {
      const user = users.find(u => u.id === id)

      return user && user.permissions && user.permissions.can_restore
    })

    this.props.dispatch({
      type: actions.USER_LIST_RESTORE,
      values,
      meta,
      params: this.getParams(),
    })
  }

  confirmStatusUpdating = (user, block: boolean = false) => {
    const { t } = this.props
    const verb = block ? 'block' : 'unblock'
    const status = block ? 3 : 2

    this.setState({
      modal: (
        <ConfirmationPopup
          title={t(`User ${verb}ing`)}
          text={t('Are you sure you want to continue?')}
          confirm={t(verb)}
          cancel={t('Common:Cancel')}
          onClose={this.hideModal}
          onOk={() => this.updateUser(user.id, { status })}
        />
      ),
    })
  }

  showBuildingModal = user => {
    if (user.validated) {
      const { isStaff } = this.props

      const onOk = isStaff
        ? this.updateManagerBuildings
        : this.updateDwellerFlats

      this.setState({
        modal: (
          <BuildingPopup
            user={user}
            onClose={this.hideModal}
            onOk={ids => onOk(user, ids)}
          />
        ),
      })
    }
  }

  showCategoryModal = (user, userIds) => {
    const handler = userIds
      ? partial(this.massUpdateCategories, userIds)
      : partial(this.updateCategories, user)

    this.setState({
      modal: (
        <CategoryPopup
          user={user}
          userIds={userIds}
          onClose={this.hideModal}
          onOk={ids => handler(ids)}
        />
      ),
    })
  }

  showRemoveModal = () => {
    const { selected, users, t } = this.props
    const testForDwellerGroup =
      users.find(u => u.id === selected[0]).group === USER_GROUPS.dweller

    const text = testForDwellerGroup
      ? t('Common:confirmDwellerRemoving')
      : t('DeleteText')
    this.setState({
      modal: (
        <ConfirmationPopup
          title={t('DeleteTitle')}
          text={text}
          confirm={t('Delete')}
          cancel={t('Cancel')}
          onClose={this.hideModal}
          onOk={() => this.remove()}
        />
      ),
    })
  }

  deleteExternal = (owner = null) => {
    const { selected, users, meta } = this.props
    const idsToDelete = owner ? [owner] : selected
    const values = idsToDelete.filter(id => {
      const user = users.find(u => u.id === id)

      return user && user.permissions && user.permissions.can_delete
    })

    this.props.dispatch({
      type: actions.USER_LIST_DELETE,
      values,
      meta,
      params: this.getParams(),
    })
  }

  showDeleteModal = () => {
    const { t } = this.props

    this.setState({
      modal: (
        <ConfirmationPopup
          title={t('DeleteExternalTitle')}
          text={t('DeleteExternalText')}
          confirm={t('DeleteExternal')}
          cancel={t('Cancel')}
          onClose={this.hideModal}
          onOk={() => this.deleteExternal()}
        />
      ),
    })
  }

  showRestoreModal = () => {
    const { selected, users } = this.props
    const testForDwellerGroup =
      users.find(u => u.id === selected[0]).group === USER_GROUPS.dweller

    const text = testForDwellerGroup
      ? this.props.t('Common:confirmDwellerRestoring')
      : this.props.t('RestoreText')

    this.setState({
      modal: (
        <ConfirmationPopup
          title={this.props.t('RestoreTitle')}
          text={text}
          confirm={this.props.t('Common:Restore')}
          cancel={this.props.t('Cancel')}
          onClose={this.hideModal}
          onOk={() => this.restore()}
        />
      ),
    })
  }

  confirmReinviting = (user = null) => {
    const { selected, users } = this.props
    const resendUsers = user
      ? [user]
      : users.filter(u => u.status !== 1 && selected.includes(u.id))

    this.setState({
      modal: (
        <ResendInvitePopUp
          reinvite
          single={resendUsers.length <= 1}
          group={user ? user.group : this.getGroup()}
          user={user}
          usersResend={resendUsers.map(u => u.id)}
          onHide={this.hideModal}
          onSave={this.reinvite}
        />
      ),
    })
  }

  confirmApproving = (user = null) => {
    const { selected, users } = this.props

    const approveUsers = user
      ? [user]
      : users.filter(u => selected.includes(u.id) && !u.validated)

    const selectedUsers = user
      ? [user]
      : users.filter(u => selected.includes(u.id))

    const text =
      selectedUsers.length > 1
        ? this.props.t('ApproveModalTextMulti', { count: selectedUsers.length })
        : this.props.t('ApproveModalText', { name: selectedUsers[0].name })

    this.setState({
      modal: (
        <ConfirmationPopup
          title={this.props.t('ApproveModalTitle')}
          text={text}
          confirm={this.props.t('ApproveButtonTitle')}
          cancel={this.props.t('Cancel')}
          onClose={this.hideModal}
          onOk={() => this.approve(approveUsers)}
        />
      ),
    })
  }

  confirmRemoving = user => {
    this.setState({
      modal: (
        <ConfirmationPopup
          title={this.props.t('DeleteTitle')}
          text={this.props.t('DeleteText', { name: user.fullname })}
          confirm={this.props.t('Delete')}
          cancel={this.props.t('Cancel')}
          onClose={this.hideModal}
          onOk={() => this.remove(user.id)}
        />
      ),
    })
  }

  confirmDelete = user => {
    this.setState({
      modal: (
        <ConfirmationPopup
          title={this.props.t('DeleteExternalTitle')}
          text={this.props.t('DeleteExternalText')}
          confirm={this.props.t('DeleteExternal')}
          cancel={this.props.t('Cancel')}
          onClose={this.hideModal}
          onOk={() => this.deleteExternal(user.id)}
        />
      ),
    })
  }

  confirmRoleUpdating = (user: ?Object, role: ?Object) => {
    if (!user || !role) {
      const onOk = user
        ? val => this.updateUser(user.id, { group: val })
        : val => this.update({ group: val })

      this.setState({
        modal: (
          <ChangeRolePopup
            confirm={this.props.t('Save')}
            isLandlord={user && user.is_landlord}
            massAction={!user}
            onClose={this.hideModal}
            onOk={onOk}
          />
        ),
      })

      return
    }

    const { group: nextGroup, label } = role
    const { group: prevGroup, is_landlord: isLandlord } = user
    const { dweller, landlord } = USER_GROUPS

    if (
      (nextGroup === prevGroup && prevGroup !== dweller) ||
      (isLandlord && nextGroup === landlord) ||
      (!isLandlord && nextGroup === dweller)
    ) {
      return
    }

    const [name, ...surname] = user.fullname.split(' ')
    const text = this.props.t('UpdateRoleText', {
      name,
      surname: surname.join(' '),
      group: label,
    })

    this.setState({
      modal: (
        <ConfirmationPopup
          title={this.props.t('UpdateRoleTitle')}
          text={text}
          confirm={this.props.t('Update')}
          cancel={this.props.t('Cancel')}
          onClose={this.hideModal}
          onOk={() => this.updateUser(user.id, { group: nextGroup })}
        />
      ),
    })
  }

  update = patch => {
    const { users, selected, dispatch, meta } = this.props
    const updateUsers = users.filter(u => selected.includes(u.id))
    dispatch({
      type: actions.USER_LIST_UPDATE,
      ids: updateUsers.map(u => u.id),
      patch,
      params: this.getParams(),
      meta,
    })
  }

  renderModal = () => {
    const { modal } = this.state

    if (!modal) {
      return null
    }

    return (
      <Modal isOpen onRequestClose={this.hideModal}>
        {modal}
      </Modal>
    )
  }

  renderEmpty = (button = false) => {
    const addText = button ? ` ${this.props.t('AddFirst')}` : ''

    return (
      <EmplyList
        embedded
        btnText={this.props.t('AddUser')}
        canAdd={button}
        icon='user'
        title={`${this.props.t('NoUsers')}${addText}`}
        onClick={this.addUser}
      />
    )
  }

  renderTable = () => {
    const { working, users, meta, isStaff, externalContact } = this.props

    const tableClass = classnames('table table--default', {
      'working-overlay': working,
    })

    return (
      <div className={tableClass}>
        <div className='table__outer-wrapper'>
          <div className='table__wrapper table--users__list'>
            {externalContact
              ? this.renderExternalContactsHead()
              : isStaff
              ? this.renderManagerHead()
              : this.renderDwellerHead()}
            {users.map(this.renderUser)}
          </div>
        </div>
        {!meta.count && this.renderEmpty()}
      </div>
    )
  }

  renderUser = user => {
    const { selected, permissions, externalContact } = this.props

    return (
      <UserItem
        externalContact={externalContact}
        canDelete={permissions.can_delete}
        canRestore={permissions.can_restore}
        key={user.id}
        selected={selected.includes(user.id)}
        statusOptions={this.statusOptions}
        user={user}
        isArchived={!!this.getParams().soft_archived}
        onApprove={this.confirmApproving}
        onReinvite={this.confirmReinviting}
        onRemove={this.confirmRemoving}
        onDelete={this.confirmDelete}
        onToggle={this.toggle}
        onUpdateBuilding={this.showBuildingModal}
        onUpdateCategory={this.showCategoryModal}
        onUpdateRole={this.confirmRoleUpdating}
        onUpdateStatus={this.confirmStatusUpdating}
      />
    )
  }

  renderDwellerHead() {
    const { t, working } = this.props

    return (
      <Table.HeaderRow>
        <Table.HeaderCell
          title={t('UserStatus')}
          sortKey='fullname'
          working={working}
        />
        <Table.HeaderCell title={t('Role')} sortKey='role' working={working} />
        <Table.HeaderCell title={t('AddressColumnTitle')} working={working} />
        <Table.HeaderCell
          title={t('Email')}
          sortKey='owner__email'
          working={working}
        />
        <Table.HeaderCell
          title={t('PhoneColumnTitle')}
          sortKey='phone'
          working={working}
        />
        <Table.HeaderCell
          title={t('Updated')}
          sortKey='updated'
          working={working}
        />
      </Table.HeaderRow>
    )
  }

  renderExternalContactsHead() {
    const { t, working } = this.props

    return (
      <Table.HeaderRow>
        <Table.HeaderCell
          title={t('User')}
          sortKey='fullname'
          working={working}
          style={{ width: '29%' }}
        />
        <Table.HeaderCell
          title={t('Email')}
          sortKey='owner__email'
          working={working}
          style={{ width: '28%' }}
        />
        <Table.HeaderCell
          title={t('PhoneColumnTitle')}
          sortKey='phone'
          working={working}
          style={{ width: '28%' }}
        />
        <Table.HeaderCell
          title={t('Updated')}
          sortKey='updated'
          working={working}
          style={{ width: '15%' }}
        />
      </Table.HeaderRow>
    )
  }

  renderManagerHead() {
    const { t, working } = this.props

    return (
      <Table.HeaderRow>
        <Table.HeaderCell
          title={t('User')}
          sortKey='fullname'
          working={working}
          style={{ width: '23%' }}
        />
        <Table.HeaderCell
          title={t('Group')}
          sortKey='role'
          working={working}
          style={{ width: '12%' }}
        />
        <Table.HeaderCell
          title={t('Buildings')}
          working={working}
          style={{ width: '24%' }}
        />
        <Table.HeaderCell
          title={t('Categories')}
          working={working}
          style={{ width: '18%' }}
        />
        <Table.HeaderCell
          title={t('Email')}
          sortKey='owner__email'
          working={working}
          style={{ width: '19%' }}
        />
        <Table.HeaderCell
          title={t('Status')}
          sortKey='status'
          working={working}
          style={{ width: '12%' }}
        />
        <Table.HeaderCell
          title={t('Updated')}
          sortKey='updated'
          working={working}
          style={{ width: '10%' }}
        />
      </Table.HeaderRow>
    )
  }

  render() {
    const {
      users,
      selected,
      meta,
      working,
      initiated,
      t,
      isStaff,
      permissions: {
        can_create: canCreate,
        can_delete: canDelete,
        can_restore: canRestore,
      },
      location,
      externalContact,
    } = this.props

    if (!initiated || isEmpty(meta)) {
      return <Loader text={false} type='medium' />
    }

    const emptyFilter = isEmpty(getCurrentFilters(location))

    if (!working && !meta.count && emptyFilter) {
      return (
        <div className='table table--default'>
          <div className='table__outer-wrapper'>
            <div className='table__wrapper'>{this.renderEmpty(canCreate)}</div>
          </div>
          {this.renderModal()}
        </div>
      )
    }

    const massActionOptions = this.getMassActionOptions()
    const adminMassActionOptions = this.getAdminMassActionOptions()

    return (
      <div>
        {this.showToolbox() && (
          <div className='toolbox'>
            {(canDelete || canRestore) && !externalContact && (
              <div className='toolbox__cell'>
                <Checkbox
                  outer
                  checked={selected.length && selected.length === users.length}
                  onChange={this.toggleAll}
                />
              </div>
            )}
            {canCreate && isStaff && selected.length > 0 && !externalContact && (
              <div className='toolbox__cell'>
                <SelectCustom
                  style={{ marginRight: '-10px' }}
                  options={adminMassActionOptions}
                  onClick={this.preventOpen}
                  onChange={opt => opt.handler()}
                >
                  {t('Actions')}
                </SelectCustom>
              </div>
            )}
            {canCreate && selected.length === 0 && !externalContact && (
              <div className='toolbox__cell'>
                <Button.Upload icon='user' onClick={this.addUser}>
                  {this.props.t(isStaff ? 'AddManager' : 'AddUser')}
                </Button.Upload>
              </div>
            )}
            {canDelete && !isStaff && selected.length > 0 && !externalContact && (
              <div className='toolbox__cell'>
                <SelectCustom
                  style={{ marginRight: '-10px' }}
                  options={massActionOptions}
                  onClick={this.preventOpen}
                  onChange={opt => opt.handler()}
                >
                  {t('Actions')}
                </SelectCustom>
              </div>
            )}
            {canDelete && selected.length > 0 && !externalContact && (
              <div className='toolbox__cell'>
                <Button.Remove
                  disabled={!selected.length}
                  onClick={this.showRemoveModal}
                />
              </div>
            )}
            {canRestore && !externalContact && (
              <div className='toolbox__cell'>
                <Button.Cancel
                  type='button'
                  disabled={!selected.length}
                  icon='restore'
                  onClick={this.showRestoreModal}
                >
                  {this.props.t('Common:Restore')}
                </Button.Cancel>
              </div>
            )}
            {canDelete && !externalContact && selected.length > 0 && (
              <div className='toolbox__cell'>
                <Button.CreateEmail
                  selectedUsers={users.filter(u => selected.includes(u.id))}
                />
              </div>
            )}
            <div className='toolbox__cell toolbox__cell--right'>
              <TopPagination
                classes='pager pager--large pager--default-2'
                meta={meta}
              />
            </div>
          </div>
        )}
        {this.renderTable()}
        <BottomPagination classes='paginator paginator--middle' meta={meta} />
        {this.renderModal()}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state.userList,
  roles: state.init.role_synonyms_id,
  statuses: state.init.user_status,
  init: state.init,
  location: getLocation(state),
})

export default compose(
  withTranslation('User'),
  connect(mapStateToProps),
  addRedirectToFirstPage
)(UserList)
