// @flow

import {
  get,
  has,
  isUndefined,
  mapKeys,
  defaults,
  mapValues,
  omitBy,
  partial,
} from 'lodash-es'

import { KEYS_DICT, VALUES_GETTERS } from './RequestViewInfo.constants'

export const compare = (
  initialValues: Object,
  value: any,
  key: string
): boolean => initialValues[key] === value

export const getKey = (value: any, key: string): string => KEYS_DICT[key]

export const getUserId = (user: Object): ?number => {
  if (!user) {
    return null
  }

  return user.id || user.owner
}

export const getValue = (value: any, key: string): string =>
  VALUES_GETTERS[key] ? get(value, VALUES_GETTERS[key]) : value

export const setPreviousValue = (
  key: string,
  params: Object,
  initialValues: Object
): Object => {
  if (!has(params, key) && initialValues[key]) {
    params[key] = initialValues[key].id
  }

  return params
}

export const setOwnerParam = (params: Object, user: Object): Object => {
  if (has(params, 'owner')) {
    defaults(params, { owner: getUserId(user) })
  }

  return params
}

export const setFlatParam = (
  params: Object,
  user: Object,
  initialValues: Object
): Object => {
  if (params.owner === getUserId(user)) {
    setPreviousValue('flat', params, initialValues)
  }

  return params
}

export const setBuildingParam = (
  params: Object,
  initialValues: Object,
  user: Object
): Object => {
  if (params.owner === getUserId(user) && !has(params, 'flat')) {
    setPreviousValue('building', params, initialValues)
  }

  if (
    has(params, 'flat') &&
    !params.flat &&
    !has(params, 'building') &&
    initialValues.building
  ) {
    params.building = initialValues.building.id
  }

  if (
    has(params, 'flat') &&
    params.flat &&
    has(params, 'building') &&
    params.building
  ) {
    params.building = undefined
  }

  return params
}

export const getInfoParams = (
  initialValues: Object,
  submitValues: Object,
  user: Object
): Object => {
  const partialCompare = partial(compare, initialValues)

  const changedValues = omitBy(submitValues, partialCompare)

  let params = mapKeys(changedValues, getKey)

  params = mapValues(params, getValue)

  setOwnerParam(params, user)

  setFlatParam(params, user, initialValues)

  setBuildingParam(params, initialValues)

  params = omitBy(params, isUndefined)

  return params
}
