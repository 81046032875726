// @flow

import React from 'react'

import GoogleDrive from './GoogleDrive'

const DocumentTemplates = () => (
  <div className='content__col'>
    <GoogleDrive />
  </div>
)

export default DocumentTemplates
