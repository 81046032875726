// @flow

import React from 'react'
import type { Node } from 'react'

type Props = {
  children: Node,
}

const NewTableHeaderRow = (props: Props): Node => {
  return <tr>{props.children}</tr>
}

export default NewTableHeaderRow
