// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'
import { compose } from 'redux'
import { isEmpty } from 'lodash-es'
import { push } from 'connected-react-router'

import Button from '../../Button'
import { DocumentPreview } from '../../Documents'
import { FilesList } from '../../../containers/FilesUpload'
import PostScope from '../../Post/PostScope'
import { removeFile } from '../../../utils/file'
import { notValidUserAction } from '../../../utils/utils'
import * as actions from './PostCreating.actionTypes'
import { postDataLoad } from '../../Post/Post.actions'
import { audienceListReset } from '../../modals/AudienceList/AudienceList.actions'
import { reset } from '../../Building/BuildingList/BuildingList.actions'
import { getUser } from '../../../utils/commonSelectors'
import NewFilesUpload from '../../NewFilesUpload'
import { renderTemplate } from '../../../core/api/api.postTemplate'
import WysiwygEditor from '../../WysiwygEditor'
import InputField from '../../InputField'

import styles from './PostCreating.module.scss'

const INITIAL_STATE = {
  files: [],
  scope: [],
  text: '',
  title: '',
  uploadingFiles: false,
  showTemplates: false,
}

const FILES_INDENT = 15
const FILES_ROW_LENGTH = 5

type Error = string | Object | Array<string>

type Props = {
  createdPost: ?Object,
  dispatch: Object => void,
  error: ?Object,
  onError?: Error => void,
  onWasCreated: Object => void,
  postLoaded: boolean,
  t: string => string,
  user: Object,
  working: boolean,
}

type State = {
  files: Array<Object>,
  scope: Array<string>,
  text: string,
  title: string,
  uploadingFiles: boolean,
}

class PostCreating extends Component<Props, State> {
  state = INITIAL_STATE

  componentDidMount() {
    this.reset()
  }

  componentDidUpdate(prev: Props) {
    const { error, postLoaded } = this.props

    if (postLoaded && !prev.postLoaded) {
      this.props.dispatch(push('/post/create'))

      return
    }

    if (error && !prev.error) {
      this.handleError(error)
      this.reset()

      return
    }

    const { working, createdPost } = this.props

    if (createdPost && !working && prev.working) {
      this.props.onWasCreated(createdPost)

      this.setState(INITIAL_STATE)
      this.reset()
    }
  }

  componentWillUnmount() {
    const { postLoaded } = this.props

    if (!postLoaded) {
      const { files } = this.state

      files.forEach(f => removeFile(f.id))
    }

    this.reset()
    this.props.dispatch(audienceListReset())
    this.props.dispatch(reset())
  }

  handleError = (error: Error) => {
    if (this.props.onError) {
      this.props.onError(error)
    }
  }

  reset = () => {
    this.props.dispatch({
      type: actions.POST_CREATING_RESET,
    })
  }

  startFilesUpload = () => {
    this.setState({ uploadingFiles: true })
  }

  updateFiles = (files: Array<Object>) => {
    this.setState(state => ({
      files: state.files.concat(files),
      uploadingFiles: false,
    }))
  }

  removeFile = ({ id }: Object) => {
    const { files } = this.state
    this.setState({
      files: files.filter(file => file.id !== id),
    })

    removeFile(id)
  }

  updateTitle = ({ target }: Object) => {
    this.setState({
      [target.name]: target.value,
    })
  }

  updateText = text => {
    this.setState({
      text,
    })
  }

  updateScope = (scope: Object) => {
    this.setState({ scope })
  }

  submit = () => {
    const { user } = this.props

    const showErrorAction = notValidUserAction(user)

    if (showErrorAction) {
      this.props.dispatch(showErrorAction)

      return
    }

    const { files, scope, title, text } = this.state
    const { buildings, scope: newAudience, isGroupsPost } = scope

    const buildingOrGroupField = isGroupsPost ? 'building_groups' : 'buildings'

    const data = {
      title,
      text,
      [buildingOrGroupField]: buildings.map(b => b.id || b.value),
      files: files.map(f => f.id),
      new_audience: newAudience,
    }

    this.props.dispatch({
      type: actions.CREATE_POST,
      data,
    })
  }

  renderFile = (file: Object, key: string) => (
    <DocumentPreview
      canRemove
      file={file}
      key={key}
      onRemove={this.removeFile}
    />
  )

  renderAddFileButton = () => (
    <NewFilesUpload
      multiple
      className={styles.add}
      name='attach-file'
      imageMaxSize={10}
      onUploadErrors={this.handleError}
      onFinishUpload={this.updateFiles}
      onStartUpload={this.startFilesUpload}
    >
      <Button.Regular icon='folder' view='desaturated'>
        {this.props.t('addFile')}
      </Button.Regular>
    </NewFilesUpload>
  )

  renderDocuments = () => {
    const { files } = this.state

    return (
      <FilesList
        files={files}
        itemIndent={FILES_INDENT}
        renderItem={this.renderFile}
        rowLength={FILES_ROW_LENGTH}
      />
    )
  }

  edit = () => {
    const {
      files,
      scope: {
        scope = [],
        buildings = [],
        allBuildings = false,
        isGroupsPost = false,
      },
      title,
      text,
    } = this.state

    this.props.dispatch(
      postDataLoad({
        files,
        new_audience: scope,
        allBuildings,
        buildings,
        isGroupsPost,
        title,
        text,
        description: text,
      })
    )
  }

  toggleTemplates = () => {
    this.setState(state => ({ showTemplates: !state.showTemplates }))
  }

  closeDropdown = () => {
    this.setState({ showTemplates: false })
  }

  handleSelect = id => {
    this.closeDropdown()

    renderTemplate(id).then(data =>
      this.setState({ text: data.text, title: data.post_title })
    )
  }

  render() {
    const { working, user, createdPost } = this.props
    const { scope, title, text, uploadingFiles } = this.state

    const boxClassName = classnames(styles.box, {
      'working-overlay': uploadingFiles || working,
    })

    return (
      <div className={boxClassName}>
        <InputField
          className={styles.title}
          placeholder={this.props.t('newPostTitle')}
          name='title'
          value={title}
          maxLength={250}
          onChange={this.updateTitle}
        />
        <WysiwygEditor
          textareaName='text'
          placeholder={this.props.t('newPostText')}
          value={text}
          resize={false}
          maxHeight={200}
          showToolbar={false}
          onChange={this.updateText}
        />
        {this.renderDocuments()}
        <div className={styles.buttonsContainer}>
          <div className={styles.buttonsGroup}>
            {this.renderAddFileButton()}
            <PostScope
              highlighted={Boolean(title && text)}
              user={user}
              wasCreated={Boolean(createdPost)}
              onUpdate={this.updateScope}
            />
          </div>
          <div className={styles.buttonsGroup}>
            <Button.Save
              disabled={isEmpty(scope) || !title.trim() || !text.trim()}
              working={working}
              onClick={this.submit}
            >
              {this.props.t('Common:send')}
            </Button.Save>
            <Button.Save
              working={working}
              disabled={uploadingFiles}
              onClick={this.edit}
            >
              {this.props.t('EditDetail')}
            </Button.Save>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: getUser(state),
  postLoaded: state.post.loaded,
  ...state.postCreating,
})

export default compose(
  withTranslation('Post'),
  connect(mapStateToProps)
)(PostCreating)
