// @flow

import React, { Component, Suspense } from 'react'
import { withRouter } from 'react-router-dom'

import Value from './MenuFilterValue'
import { getCurrentFilters } from '../../../utils/routing'

import styles from './MenuFilterItem.module.scss'

type Item = {
  filterKey: ?string,
  name: string,
  text: string,
}

type Props = {
  item: Item,
  location: Object,
}

type State = {
  key: number,
  softArchived: boolean,
}

class MenuFilterItem extends Component<Props, State> {
  state = {
    key: 0,
    softArchived: false,
  }

  isSoftArchived = (location): boolean => {
    const { soft_archived: softArchived } = getCurrentFilters(location)

    return !!softArchived
  }

  componentDidMount() {
    const { location } = this.props

    this.setState({ softArchived: this.isSoftArchived(location) })
  }

  componentDidUpdate(prevProps) {
    const { key } = this.state
    const { location: prevLocation } = prevProps
    const { location } = this.props
    const prevSoftArchived = this.isSoftArchived(prevLocation)
    const softArchived = this.isSoftArchived(location)

    if (softArchived !== prevSoftArchived) {
      this.setState({ key: key + 1, softArchived })
    }
  }

  renderValue = (text: string, onClear?: () => void, renderValue) => (
    <Value text={text} renderValue={renderValue} onClear={onClear} />
  )

  render() {
    const {
      item: {
        component: FilterComponent,
        filterKey,
        name,
        text,
        filterType,
        searchKey,
        avatarKey,
        labelKey,
        valueKey,
        descriptionKey,
        checkSub,
        useGqlId,
      },
    } = this.props

    const { key, softArchived } = this.state

    return (
      <li className={styles.item} key={key}>
        <Suspense fallback={null}>
          <FilterComponent
            name={name}
            text={text}
            filterType={filterType}
            filterKey={filterKey}
            searchKey={searchKey}
            valueKey={valueKey}
            labelKey={labelKey}
            descriptionKey={descriptionKey}
            avatarKey={avatarKey}
            checkSub={checkSub}
            useGqlId={useGqlId}
            renderValue={this.renderValue}
            softArchived={softArchived}
          />
        </Suspense>
      </li>
    )
  }
}

export default withRouter(MenuFilterItem)
