// @flow

import { useState } from 'react'
import { cloneDeep, find, isEqual, isEqualWith, isObject } from 'lodash-es'

type Item = string | number
type Items = Array<Item>

export const useSelected = (
  initialItems: Items = [],
  compareKey
): Array<any> => {
  const [selectedItems, setSelectedItems] = useState(initialItems)

  const compare = (a, b) => a[compareKey] === b[compareKey]

  const removeSelected = (selectedItem: Item): void => {
    const equal = (item, selectedItem) =>
      compareKey
        ? isEqualWith(selectedItem, item, compare)
        : isEqual(selectedItem, item)

    const items: Items = selectedItems.filter(
      item => !equal(selectedItem, item)
    )

    setSelectedItems(items)
  }

  const addSelected = (selectedItem: Item): void => {
    const items: Items = selectedItems.concat(selectedItem)

    setSelectedItems(items)
  }

  const setSelected = (items: Items) => {
    if (!items.length || items.length === selectedItems.length) {
      setSelectedItems([])
    } else {
      const clonedItems: Items = cloneDeep(items)

      setSelectedItems(clonedItems)
    }
  }

  const changeSelected = (selectedItem: Item) => {
    let isIntersect = false

    if (isObject(selectedItem)) {
      const compare = compareKey
        ? item => item[compareKey] === selectedItem[compareKey]
        : selectedItem
      isIntersect = !!find(selectedItems, compare)
    } else {
      isIntersect = selectedItems.includes(selectedItem)
    }

    if (isIntersect) {
      removeSelected(selectedItem)
    } else {
      addSelected(selectedItem)
    }
  }

  const isAllSelected = (items: Items) =>
    !!(items.length && items.length === selectedItems.length)

  const isSelected = (item: Item) => selectedItems.includes(item)

  return [selectedItems, changeSelected, setSelected, isAllSelected, isSelected]
}
