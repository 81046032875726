// @flow

import React from 'react'
import type { Node } from 'react'

import NewTableBody from '../../NewTable/NewTableBody'
import TableBodyRow from './TableBodyRow'

type Props = {
  onCopyItem: Function,
  onSelect: Function,
  selectedItems: Array<Object>,
  setRemovingItems: Function,
  templates: Array<any>,
}

const TableBody = (props: Props): Node => {
  const { templates, selectedItems } = props

  return (
    <NewTableBody>
      {templates.map(template => (
        <TableBodyRow
          template={template}
          key={template.id}
          selectedItems={selectedItems}
          setRemovingItems={props.setRemovingItems}
          onCopyItem={props.onCopyItem}
          onSelect={props.onSelect}
        />
      ))}
    </NewTableBody>
  )
}

export default TableBody
