// @flow

import * as actions from './ConverToRequestPopup.actionTypes'

const initialState = {
  working: true,
  created: false,
  error: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.INIT:
      return {
        ...state,
        created: false,
        working: false,
      }
    case actions.ERROR:
      return {
        ...state,
        working: false,
        error: action.error,
      }
    case actions.REQUEST_CREATE:
      return {
        ...state,
        working: true,
      }
    case actions.REQUEST_CREATED:
      return {
        ...state,
        created: true,
        working: false,
      }
    default:
      return state
  }
}
