// @flow

import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import classnames from 'classnames'

import Question from './Question'
import Header from './Header'
import Button from '../../../components/Button'
import { editQuestion, clearEditQuestion } from '../Faq.actions'
import * as selectors from '../Faq.selectors'

type Props = {
  createQuestionFor: ?number,
  editQuestion: () => void,
  id: number,
  isAdmin: boolean,
  permissions: Object,
  questions: Array<Object>,
  t: string => string,
  themeColor: string,
  title: number,
}
type State = {
  isCollapsed: boolean,
}

class Group extends PureComponent<Props, State> {
  state = {
    isCollapsed: true,
  }

  componentDidUpdate(prevProps) {
    if (prevProps.questions.length !== this.props.questions.length) {
      this.setState({
        isCollapsed: false,
      })
    }
  }

  handleCollapse = () => {
    const { isCollapsed } = this.state
    this.setState(state => ({
      isCollapsed: !state.isCollapsed,
    }))

    if (!isCollapsed) {
      this.props.clearEditQuestion()
    }
  }

  createQuestion = () => {
    this.props.editQuestion(this.getIdNewQuestion())
  }

  getIdNewQuestion = () => {
    const { id } = this.props

    return `new_${id}`
  }

  renderCreateQuestion = () => {
    const { id, t, editingQuestion, permissions } = this.props
    const newQuestionId = this.getIdNewQuestion()
    const isCreatingNewQuestion = editingQuestion === newQuestionId

    if (!permissions.can_create) {
      return null
    }

    return isCreatingNewQuestion ? (
      <Question id={newQuestionId} groupId={id} title={''} />
    ) : (
      <div className='faq__question-add'>
        <Button.Add title={t('add_question')} onClick={this.createQuestion} />
      </div>
    )
  }

  getItemStyle = (isDragging, draggableStyle) => {
    const { themeColor } = this.props
    const style = { ...draggableStyle }

    if (isDragging) {
      style.border = `1px solid ${themeColor}`
      style.borderRadius = '3px'
      style.backgroundColor = 'white'
    }

    return style
  }

  render() {
    const { questions, id, title, isAdmin, working } = this.props
    const { isCollapsed } = this.state
    const groupClass = classnames('unit unit--default', {
      'working-overlay': working,
    })

    return (
      <div className={groupClass}>
        <div className='table table--default'>
          <div className='table__outer-wrapper'>
            <Header
              id={id}
              title={title}
              count={questions.length}
              isCollapsed={isCollapsed}
              onCollapse={this.handleCollapse}
            />
            <Droppable droppableId={id.toString()}>
              {provided => (
                <div
                  ref={provided.innerRef}
                  style={{ minHeight: !questions.length ? '50px' : '0px' }}
                >
                  {!isCollapsed &&
                    questions.map((q, index) => (
                      <Draggable
                        key={q.id}
                        draggableId={q.id.toString()}
                        isDragDisabled={!isAdmin}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={
                              snapshot.isDragging
                                ? 'faq__question-dragging'
                                : ''
                            }
                            style={this.getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <Question
                              key={q.id}
                              id={q.id}
                              title={q.title}
                              groupId={id}
                              position={index}
                              avatar={q.avatar}
                              answer={q.answer}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            {this.renderCreateQuestion()}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  editingQuestion: selectors.getEditingQuestion(state),
  permissions: selectors.getPermissions(state),
  themeColor: selectors.getThemeColor(state),
  isAdmin: selectors.isAdmin(state),
  working: selectors.isWorking(state),
})
const mapDispatchToProps = {
  editQuestion,
  clearEditQuestion,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('FAQ')
)(Group)
