import * as actions from './SelectStatus.actionTypes'

const initialState = {
  value: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.SELECT_STATUS_INIT:
      return {
        ...initialState,
        value: action.value || null,
      }
    case actions.SELECT_STATUS_SET_VALUE:
      return {
        ...state,
        value: action.value,
      }
    default:
      return state
  }
}
