// @flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import Modal from '../Modal'
import ModalBody from '../Modal/ModalBody'
import ModalCloseButton from '../Modal/ModalCloseButton'
import ModalHead from '../Modal/ModalHead'
import Radio from '../Radio'
import Button from '../Button'

import { useOverflow } from '../../hooks'
import { useThemeColor } from '../../hooks'

import styles from './ConfirmUpdateManagersModal.module.scss'

type Props = {
  isOpen: boolean,
  noText: string,
  onClose: () => void,
  onConfirm: boolean => void,
  text: string,
  title: string,
  yesText: string,
}

const ConfirmUpdateManagersModal = (props: Props): Node => {
  const { isOpen, onClose, onConfirm, title, text, yesText, noText } = props
  const { t } = useTranslation('Building')
  useOverflow()
  const themeColor = useThemeColor()
  const [performForBuildings, setPerformForBuildings] = useState(true)
  const handleChangePerform = e =>
    setPerformForBuildings(JSON.parse(e.target.value))
  const handleApply = () => onConfirm(performForBuildings)

  return (
    <>
      <Modal isOpen={isOpen} onRequestClose={onClose}>
        <ModalHead title={title} />
        <ModalCloseButton onClose={onClose} />
        <ModalBody
          className={styles.selectCase}
          style={{
            '--themeColor': themeColor,
          }}
        >
          <p>{text}</p>
          <div className={styles.actions}>
            <Radio
              id='perform'
              name='is_perform'
              value={true}
              checked={performForBuildings}
              label={yesText}
              onChange={handleChangePerform}
            />
            <Radio
              id='not_perform'
              name='is_perform'
              value={false}
              checked={!performForBuildings}
              label={noText}
              onChange={handleChangePerform}
            />
          </div>
        </ModalBody>
        <div className={styles.buttons}>
          <Button.Save onClick={handleApply}>{t('Apply')}</Button.Save>
          <Button.Cancel onClick={onClose}>{t('Cancel')}</Button.Cancel>
        </div>
      </Modal>
    </>
  )
}

export default ConfirmUpdateManagersModal
