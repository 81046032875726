// @flow

import { createSelector } from 'reselect'

import { getThemeColor } from '../../utils/commonSelectors'

export const isLoading = state => state.FAQ.loading
export const isWorking = state => state.FAQ.working
export const getSelected = state => state.FAQ.selected
export const isSelectedEmpty = state => Boolean(getSelected(state).length)
export const getGroups = state => state.FAQ.groups
export const isGroupsEmpty = state => Boolean(getGroups(state).length)
export const getModal = state => state.FAQ.modal
export const isAllSelected = createSelector(
  getSelected,
  getGroups,
  (selected, groups) => selected.length === groups.length
)
export const getModalParams = state => state.FAQ.modalParams
export const isDisableDeleteGroups = createSelector(
  getSelected,
  getGroups,
  (selected, groups) =>
    groups
      .filter(g => selected.includes(g.id))
      .some(g => Boolean(g.questions.length))
)
export { getThemeColor }

export const isGroupWorking = state => state.FAQ.groupWorking
export const getUserGroup = state => state.login.user.group
export const getEditingGroup = state => state.FAQ.editGroup

export const isQuestionWorking = state => state.FAQ.questionWorking
export const getEditingQuestion = state => state.FAQ.editQuestion

export const getEditingAnswer = state => state.FAQ.editAnswer
export const isAnswerWorking = state => state.FAQ.answerWorking

export const isAdmin = state => getUserGroup(state) === 'admin'
export const getPermissions = state => state.FAQ.permissions
export const getAdminAvatar = state => state.login.user.avatar_obj.thumbnail
