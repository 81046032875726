// @flow

import { all, put, call, fork, takeLatest } from 'redux-saga/effects'
import type { Saga } from 'redux-saga'

import api from '../../core/api'
import * as actions from './RequestConstructor.actionTypes'
import { serverError } from '../../components/Layout/Layout.actions'
import { REQUEST_CONSTRUCTOR_CARD_CHANGED } from '../RequestConstructorCards/RequestConstructorCards.actionTypes'

import { redirectTo404 } from '../../utils/routing'

function* handleError(error) {
  const { data } = error.message.response

  yield put({
    type: actions.REQUEST_CONSTRUCTOR_ERROR,
    error: data,
  })
  yield put(serverError(error))
}

function* watchInit() {
  yield takeLatest(actions.REQUEST_CONSTRUCTOR_INITIATE, init)
}

function* watchToggle() {
  yield takeLatest(actions.REQUEST_CONSTRUCTOR_TOGGLE, toggle)
}

function* watchUpdate() {
  yield takeLatest(actions.REQUEST_CONSTRUCTOR_UPDATE, update)
}

function* watchAddGroup() {
  yield takeLatest(actions.REQUEST_CONSTRUCTOR_ADD_GROUP, addGroup)
}

function* watchRenameGroup() {
  yield takeLatest(actions.REQUEST_CONSTRUCTOR_RENAME_GROUP, renameGroup)
}

function* watchRemoveGroup() {
  yield takeLatest(actions.REQUEST_CONSTRUCTOR_REMOVE_GROUP, removeGroup)
}

function* watchAddBlock() {
  yield takeLatest(actions.REQUEST_CONSTRUCTOR_ADD_BLOCK, addBlock)
}

function* watchMoveBlock() {
  yield takeLatest(actions.REQUEST_CONSTRUCTOR_MOVE_BLOCK, moveBlock)
}

function* watchPublish() {
  yield takeLatest(actions.PUBLISH_WORKFLOW, publish)
}

function* publish({ workflow }) {
  try {
    yield call(api.requestConstructor.publish, workflow)

    const data = yield call(api.requestConstructor.getWorkflow, workflow)

    yield put({
      type: actions.WORKFLOW_PUBLISHED,
      row_is_changed: data.row_is_changed,
    })
  } catch (error) {
    yield* handleError(error)
  }
}

function* init({ workflowId }) {
  try {
    const data = yield call(api.requestConstructor.getWorkflow, workflowId)
    yield put({ type: actions.REQUEST_CONSTRUCTOR_WAS_INITIATED, data })
  } catch (error) {
    redirectTo404(error) || (yield put(handleError(error)))
  }
}

function* update({ id, data }) {
  try {
    const result = yield call(api.requestConstructor.update, id, data)
    yield put({
      type: actions.REQUEST_CONSTRUCTOR_WAS_UPDATED,
      data: result,
    })

    yield put({
      type: actions.WIZARD_STATUS_WAS_UPDATED,
      data,
    })
  } catch (error) {
    yield* handleError(error)
  }
}

function* addGroup({ title, workflow }) {
  try {
    const group = yield call(api.requestConstructor.addGroup, title, workflow)
    yield put({ type: actions.REQUEST_CONSTRUCTOR_GROUP_WAS_ADDED, group })
  } catch (error) {
    yield* handleError(error)
  }
}

function* renameGroup({ id, title }) {
  try {
    const group = yield call(api.requestConstructor.renameGroup, id, title)
    yield put({ type: actions.REQUEST_CONSTRUCTOR_GROUP_WAS_RENAMED, group })
  } catch (error) {
    yield* handleError(error)
  }
}

function* removeGroup({ id }) {
  try {
    yield call(api.requestConstructor.removeGroup, id)
    yield put({ type: actions.REQUEST_CONSTRUCTOR_GROUP_WAS_REMOVED, id })
  } catch (error) {
    yield* handleError(error)
  }
}

function* addBlock({ group }) {
  try {
    const block = yield call(api.requestConstructor.addBlock, group)
    yield put({ type: actions.REQUEST_CONSTRUCTOR_BLOCK_WAS_ADDED, block })
  } catch (error) {
    yield* handleError(error)
  }
}

function* moveBlock({ id, from, to, position, workflowId }) {
  try {
    yield call(api.requestConstructor.updateBlock, id, { group: to, position })
    yield put({
      type: actions.REQUEST_CONSTRUCTOR_BLOCK_WAS_MOVED,
      id,
      from,
      to,
      position,
    })

    yield put({
      type: REQUEST_CONSTRUCTOR_CARD_CHANGED,
      workflowId: workflowId,
    })
  } catch (error) {
    yield* handleError(error)
  }
}

function* toggle({ id }) {
  try {
    const data = yield call(api.requestConstructor.toggleWorkflow, id)

    yield put({ type: actions.REQUEST_CONSTRUCTOR_TOGGLED, data })
    yield put({
      type: actions.WIZARD_STATUS_WAS_UPDATED,
      data,
    })
  } catch (error) {
    yield* handleError(error)
  }
}

export default function* watch(): Saga<void> {
  yield all([
    fork(watchInit),
    fork(watchUpdate),
    fork(watchAddGroup),
    fork(watchRenameGroup),
    fork(watchRemoveGroup),
    fork(watchAddBlock),
    fork(watchMoveBlock),
    fork(watchToggle),
    fork(watchPublish),
  ])
}
