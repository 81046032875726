// @flow

import { put, call, fork, takeEvery } from 'redux-saga/effects'
import type { Saga } from 'redux-saga'

import api from '../../../core/api'
import { serverError } from '../../Layout/Layout.actions'
import * as actions from './ResendInvitePopUp.actionTypes'

function* watchAdd() {
  yield takeEvery(actions.RESEND_INVITE_POP_UP_SAVE, add)
}

function* add({ params, onSave }) {
  try {
    yield call(api.profile.invite, params)
    yield put({ type: actions.RESEND_INVITE_POP_UP_SAVED })

    if (onSave) {
      onSave('hello')
    }
  } catch (error) {
    yield put(serverError(error))
  }
}

export default function* watch(): Saga<void> {
  yield fork(watchAdd)
}
