import * as actions from './Feedback.actions'
import { RATING_TYPES } from '../../constants'
import { getUpdatedExtraRating } from './Feedback.utils'

const initialState = {
  loading: true,
  data: {
    rating: null,
    text: '',
    request_obj: {
      id: null,
      request_no: null,
      title: '',
    },
    permissions: { can_edit: false },
    files_objs: [],
    extra_ratings: [],
  },
  working: false,
  error: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.LOAD_FEEDBACK:
      return {
        ...state,
        loading: true,
      }
    case actions.FEEDBACK_WAS_LOADED:
      return {
        ...state,
        loading: false,
        working: false,
        data: action.payload,
      }
    case actions.FEEDBACK_WAS_UPDATED:
    case actions.FEEDBACK_WAS_SENT:
      return {
        ...state,
        working: false,
        data: action.payload,
      }
    case actions.REQUEST_WAS_LOADED:
      return {
        ...state,
        data: {
          ...state.data,
          request_obj: {
            ...state.data.request_obj,
            ...{
              id: action.payload.id,
              request_no: action.payload.request_no,
              title: action.payload.title,
            },
          },
        },
      }
    case actions.CLOSE_FEEDBACK:
      return initialState
    case actions.SEND_FEEDBACK:
    case actions.UPDATE_FEEDBACK:
    case actions.FEEDBACK_UPDATE_FILES:
      return {
        ...state,
        working: true,
      }
    case actions.FEEDBACK_FILES_WAS_UPDATED:
      return {
        ...state,
        working: false,
        ...action.payload,
      }
    case actions.RATING_WAS_UPDATED:
      return {
        ...state,
        data: {
          ...state.data,
          extra_ratings: getUpdatedExtraRating(
            state.data.extra_ratings,
            action.rating
          ),
        },
        working: false,
      }
    case actions.LIKE_WAS_UPDATED:
      return {
        ...state,
        working: false,
      }
    case actions.RATING_WAS_SENT: {
      if (action.payload.rating_type === RATING_TYPES.general) {
        return {
          ...state,
          data: {
            ...state.data,
            rating_obj: action.payload,
          },
          working: false,
        }
      }

      return {
        ...state,
        data: {
          ...state.data,
          extra_ratings: getUpdatedExtraRating(
            state.data.extra_ratings,
            action.payload
          ),
        },
        working: false,
      }
    }
    case actions.RATING_WAS_LOADED:
      return {
        ...state,
        data: {
          ...state.data,
          permissions: { can_edit: true },
          rating: action.rating.id,
          rating_obj: action.rating,
        },
        loading: false,
      }
    case actions.FEEDBACK_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case actions.CLEAR_FEEDBACK_ERROR:
      return {
        ...state,
        error: null,
      }
    default:
      return state
  }
}
