// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import styles from './EmailConfirmSuccess.module.scss'

const EmailConfirmSuccess = props => {
  const { email } = props

  const { t } = useTranslation('ConfirmEmail')

  return (
    <>
      <div className={styles.title}>
        <div>{t('SuccessTitleFirst')}</div>
        <div
          dangerouslySetInnerHTML={{
            __html: t('SuccessTitleSecond', { email }),
          }}
        />
      </div>
      <Link
        className='btn'
        to='/login'
        style={{ marginTop: '30px', marginBottom: '24px' }}
      >
        {t('LoginLink')}
      </Link>
    </>
  )
}

export default EmailConfirmSuccess
