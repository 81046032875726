// @flow

import React from 'react'
import type { Node } from 'react'

import Cross from '../../../../../../../Button/Cross'
import ModalRow from '../../../../../../../Modal/ModalRow'

import styles from './Item.module.scss'

type Props = { file: Object, onRemove: number => void }

const Item = (props: Props): Node => {
  const {
    file: { id, origin, name },
  } = props

  const handleRemove = () => props.onRemove(id)

  return (
    <ModalRow>
      <div className={styles.item}>
        <a href={origin} target='_blank' rel='noopener noreferrer'>
          {name}
        </a>
        <Cross onClick={handleRemove} />
      </div>
    </ModalRow>
  )
}

export default Item
