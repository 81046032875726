// @flow

import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash-es'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'

import Block, { Header, CellsBar, Cell } from '../Block'
import { getDocumentMapping } from '../../core/api/api.documentMapping'
import Loader from '../Loader'
import { getById } from '../../core/api/api.file'
import Steps from './Steps'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'
import { getFormattedDate } from '../../utils/utils'
import {
  STATUSES,
  SPLIT_STEP,
  MAPPING_STEP,
  PREVIEW_STEP,
  RESULT_STEP,
} from './MappedDocument.constants'

import styles from '../DocumentMapping/DocumentMappingTableBodyRow.module.scss'

type Document = {
  file_obj: { filename: string },
  id: number,
  mapping_rules: Array<{ bbox: Array<number>, page: number, rule: string }>,
  notification_type: string,
  page_count: number,
  pages_per_document: ?number,
  status: string,
  step: string,
  updated: string,
  users_to_pages: Array<{ page_from: number, page_to: number, user: Object }>,
}

const MappedDocument = () => {
  const { t } = useTranslation('DocumentMapping')

  const { mappedDocumentId } = useParams()

  const [document, setDocument] = useState(null)
  const [loading, setLoading] = useState(true)
  const [notFound, setNotFound] = useState(false)
  const [fileOrigin, setFileOrigin] = useState(null)
  const [prevStep, setPrevStep] = useState(null)

  const pageRef = useRef(null)

  const dispatch = useDispatch()

  useEffect(() => {
    if (loading && mappedDocumentId) {
      getDocumentMapping(mappedDocumentId, { step: prevStep })
        .then((data: Object) => {
          setDocument(data)
          setLoading(false)
          setPrevStep(null)
        })
        .catch(() => setNotFound(true))
    }
  }, [loading])

  useEffect(() => {
    if (document) {
      const fileId = get(document, ['file_obj', 'id'])
      const step = get(document, 'step')

      if (fileId && (step === SPLIT_STEP || step === MAPPING_STEP)) {
        getById(fileId).then((data: Object) => setFileOrigin(data))
      }
    }
  }, [document])

  useEffect(() => {
    if (prevStep) {
      setLoading(true)
    }
  }, [prevStep])

  const goToList = () => {
    dispatch(push('/files/map/'))
  }

  if (notFound) {
    return (
      <Block notCollapse>
        <Header header goBack={goToList}>
          {t('NotFound')}
        </Header>
      </Block>
    )
  }

  if (loading || !document) {
    return <Loader text={false} type='big' />
  }

  const {
    step,
    id,
    page_count: pageCount,
    updated,
    status,
    file_obj: { filename },
    users_to_pages: usersToPages,
    notification_type: notificationType,
    pages_per_document: pagesPerDocument,
    mapping_rules: mappingRules,
  }: Document = document

  const formattedDate = updated ? getFormattedDate(updated) : '-'

  const statusText = t(STATUSES[status][0])
  const statusClass = styles[STATUSES[status][1]]

  return (
    <>
      <Block notCollapse working={loading}>
        <Header header goBack={goToList}>{`${t(
          'FileImport'
        )}: ${filename}`}</Header>
        <CellsBar>
          <Cell title={t('Updated')}>{formattedDate}</Cell>
          <Cell title={t('Status')}>
            <span className={statusClass}>{statusText}</span>
          </Cell>
          <Cell title={t('PageCount')}>{pageCount}</Cell>
        </CellsBar>
      </Block>
      <Steps step={step} setPrevStep={setPrevStep} />
      <div ref={pageRef}>
        {step === SPLIT_STEP && (
          <Step1
            pageCount={pageCount}
            fileOrigin={fileOrigin}
            id={id}
            setDocument={setDocument}
            pageRef={pageRef}
            pagesPerDocument={pagesPerDocument}
          />
        )}
        {step === MAPPING_STEP && (
          <Step2
            pageCount={pageCount}
            fileOrigin={fileOrigin}
            id={id}
            setDocument={setDocument}
            pageRef={pageRef}
            mappingRules={mappingRules}
          />
        )}
        {step === PREVIEW_STEP && (
          <Step3
            fileOrigin={fileOrigin}
            setDocument={setDocument}
            usersToPages={usersToPages}
            id={id}
          />
        )}
        {step === RESULT_STEP && (
          <Step4
            fileOrigin={fileOrigin}
            setDocument={setDocument}
            id={id}
            notificationType={notificationType}
          />
        )}
      </div>
    </>
  )
}

export default MappedDocument
