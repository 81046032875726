// @flow

import React, { useRef } from 'react'
import classNames from 'classnames'
import { useDrag, useDrop } from 'react-dnd'
import type { Node } from 'react'

import Input from '../../../../Input'
import Button from '../../../../Button'
import { updatePhaseTemplate } from '../../../../../core/api/api.pipeline'

import styles from './PhaseListItem.module.scss'

type Props = {
  isActive: boolean,
  isEditing: boolean,
  onClick: Function,
  phase: Object,
  reload: Function,
  setEditingPhaseId: Function,
}

const PhaseListItem = (props: Props): Node => {
  const {
    phase: { name, id, position },
    isActive,
    isEditing,
  } = props

  const ref = useRef(null)

  const spanClass = classNames({
    [styles.active]: isActive,
  })

  const handleEdit = () => {
    props.setEditingPhaseId(id)
  }

  const handleSave = value => {
    updatePhaseTemplate(id, { name: value }).finally(() => {
      props.setEditingPhaseId(null)
      props.reload()
    })
  }

  const handleRemove = () => props.setRemovalId(id)

  const [, drag] = useDrag({
    item: {
      id,
      type: 'phase',
      name,
      position,
    },
    canDrag: () => true,
    collect: monitor => {
      return {
        isDragging: monitor.isDragging(),
      }
    },
  })

  const [, drop] = useDrop({
    accept: 'phase',
    canDrop: (dropProps, monitor) => {
      const { id: targetId } = monitor.getItem()

      return targetId !== id
    },
    drop: (dropProps, monitor) => {
      const { id: targetId } = monitor.getItem()

      if (id === targetId) {
        return
      }

      updatePhaseTemplate(targetId, { position: position }).finally(() => {
        props.reload()
      })
    },
    collect: monitor => ({
      isDropTarget: monitor.canDrop() && monitor.isOver({ shallow: true }),
    }),
  })

  drag(drop(ref))

  return (
    <li className={styles['list-item']} ref={ref}>
      {isEditing ? (
        <Input.Edit
          draggable
          editing
          value={name}
          className={styles.edit}
          maxLength={200}
          onToggleEdit={() => props.setEditingPhaseId(null)}
          onRemove={handleRemove}
          onSave={handleSave}
        />
      ) : (
        <>
          <span className={spanClass} onClick={() => props.onClick(id)}>
            {name}
          </span>
          <Button.Edit onClick={handleEdit} />
        </>
      )}
    </li>
  )
}

export default PhaseListItem
