// @flow

import React, { Component } from 'react'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import type { Node } from 'react'

import { getFormattedDate, getUserId } from '../../../../../utils/utils'
import type { Props } from './index'

type UpdatedProps = Props & {
  extra: Node,
}

class Updated extends Component {
  props: UpdatedProps

  open = () => {
    const { user, external } = this.props
    !external
      ? this.props.push(`/profile/${getUserId(user)}`)
      : this.props.push(`/external-contact/${getUserId(user)}`)
  }

  render() {
    const { user, extra } = this.props
    const text = getFormattedDate(user.updated)

    return (
      <div className='table__cell' onClick={this.open}>
        <div className='table__cell-wrapper'>
          <div className='table-item table-item__text' title={text}>
            <span>{text}</span>
          </div>
          {extra}
        </div>
      </div>
    )
  }
}

export default connect(null, { push })(Updated)
