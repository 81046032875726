// @flow

import React, { useState, useEffect } from 'react'
import type { Node } from 'react'

import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import { useTranslation } from 'react-i18next'

import { updateAutomation } from '../../core/api/api.automate'
import { getFormattedDate, getUrlForAvatar } from '../../utils/utils'

import {
  STATUS_ACTIVE,
  STATUS_INACTIVE,
  STATUS_COLORS,
} from './AutomateTable/Automate.constants'

import { SimpleBlock, Header, CellsBar, Cell, Row } from '../Block'
import Icon from '../Icon'
import Button from '../Button'
import TableCellUsers from '../TableCellUsers'
import Textarea from '../Textarea'
import ValueItem from '../Select/ValueItem'

import NameModal from './NameModal'
import Modal from '../Modal'
import LabelsRow from '../Labels/LabelsRow'

import styles from './AutomateInfo.module.scss'

type Props = {
  automate: Object,
  setLoading: boolean => void,
}

const AutomateInfo = (props: Props): Node => {
  const {
    automate: {
      id,
      name,
      created,
      updated,
      created_by,
      executed_by_option,
      labels: label_objs,
      permissions: { can_edit },
    },
  } = props

  const { t } = useTranslation('Automate')
  const dispatch = useDispatch()
  const [editName, setEditName] = useState(false)
  const [status, setStatus] = useState(props.automate.status)
  const [editDescription, setEditDesctiption] = useState(false)
  const [description, setDescription] = useState(props.automate.description)
  const [updatingStatus, setUpdatingStatus] = useState(false)
  const [labelsModal, setLabelsModal] = useState(null)

  const handleUpdateName = name => {
    setEditName(false)
    updateAutomation(id, { name }).then(() => props.setLoading(true))
  }

  const handleToggleStatus = statusBool => {
    setUpdatingStatus(true)
    setStatus(statusBool ? STATUS_ACTIVE : STATUS_INACTIVE)
  }

  const handleUpdateDescription = () => {
    setEditDesctiption(false)
    updateAutomation(id, { description }).then(() => props.setLoading(true))
  }

  const updateLabels = labelsArr => {
    const labels = labelsArr.filter(l => l.checked).map(l => l.id)
    updateAutomation(id, { labels }).then(() => props.setLoading(true))
    hideLabelsModal()
  }

  useEffect(() => {
    if (updatingStatus) {
      updateAutomation(id, { status })
    }

    setUpdatingStatus(false)
  }, [status])

  const statusText = status === STATUS_ACTIVE ? t('Active') : t('Inactive')
  const statusColor = STATUS_COLORS[status]

  const hideLabelsModal = () => setLabelsModal(null)

  return (
    <>
      <SimpleBlock>
        <Header
          header
          goBack={() => dispatch(push(`/settings/automate`))}
          textTitle={name}
          className={styles.header}
        >
          <div icon='true' className={styles.icon}>
            <Icon id='pipeline' />
          </div>
          <span className={styles.name}>{name}</span>
          {can_edit && !editName && (
            <Button.Edit onClick={() => setEditName(true)} />
          )}
        </Header>
        <CellsBar>
          <Cell title={t('Created')}>{getFormattedDate(created)}</Cell>
          <Cell
            title={t('Creator')}
            className={styles.userCell}
            valueClassName={styles.user}
          >
            <TableCellUsers items={[created_by]} />
          </Cell>
          <Cell title={t('LastUpdate')}>{getFormattedDate(updated)}</Cell>
          <Cell title={t('Status')}>
            <Button.Toggle
              disabled={!can_edit}
              checked={status === STATUS_ACTIVE}
              id={`status_${id}`}
              onChange={() => handleToggleStatus(status !== STATUS_ACTIVE)}
            />
            <span className={styles.status} style={{ color: statusColor }}>
              {statusText}
            </span>
          </Cell>
        </CellsBar>
        <Row
          className={styles.description}
          title={t('Description')}
          onEdit={can_edit ? () => setEditDesctiption(true) : null}
        >
          {editDescription ? (
            <>
              <Textarea
                style={{ width: '100%', lineHeight: '20px' }}
                minRows={3}
                maxLength={512}
                value={description}
                onChange={e => setDescription(e.target.value)}
              />{' '}
              <div className={styles.buttonWrapper}>
                <Button.Save
                  disabled={props.automate.description === description}
                  onClick={handleUpdateDescription}
                >
                  {t('Common:Save')}
                </Button.Save>
                <Button.Cancel onClick={() => setEditDesctiption(false)}>
                  {t('Common:Cancel')}
                </Button.Cancel>
              </div>
            </>
          ) : (
            <span>{props.automate.description || t('NoDescription')}</span>
          )}
        </Row>
        <Row title={t('Author')}>
          <ValueItem
            inline
            profile
            round
            avatar={getUrlForAvatar()}
            text={t(executed_by_option)}
          />
        </Row>
        <LabelsRow
          infoData={{ label_objs }}
          value={label_objs}
          permissions={{ can_edit_labels: can_edit }}
          onEdit={can_edit && setLabelsModal}
          onChange={updateLabels}
        />
      </SimpleBlock>
      {editName && (
        <NameModal
          isEdit
          updateName={handleUpdateName}
          name={name}
          onClose={() => setEditName(false)}
        />
      )}
      {labelsModal && (
        <Modal isOpen={!!labelsModal} onRequestClose={hideLabelsModal}>
          {labelsModal}
        </Modal>
      )}
    </>
  )
}

export default AutomateInfo
