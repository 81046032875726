// @flow

import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import { useSelector } from 'react-redux'
import type { Node } from 'react'

import { isStaffUser } from '../../../../utils/utils'
import NewAvatar from '../../../NewAvatar'
import Label from '../../../Label'
import {
  isAppropriateInterval,
  isExpired,
  isUpcoming,
} from '../../PostInfo/PostInfo.utils'
import DateTime from '../../../DateTime'
import { useUser } from '../../../../hooks'
import { getLinkData } from './Info.utils'
import { getUk } from '../../../../utils/commonSelectors'

import styles from './Info.module.scss'

type Props = {
  created: string,
  dateFrom?: string,
  dateTo?: string,
  isUnread?: boolean,
  isWelcome?: boolean,
  ownerObj?: Object,
  spam?: boolean,
}

const Info = (props: Props): Node => {
  const { ownerObj, spam, dateFrom, dateTo, created, isUnread, isWelcome } =
    props

  const user = useUser()
  const uk = useSelector(getUk)

  const { t } = useTranslation('Post')

  const [to, avatar, name] = getLinkData(ownerObj, uk)

  const linkClass = classnames(styles.link, { [styles.bold]: isUnread })
  const noLinkClass = classnames(styles.nolink, { [styles.bold]: isUnread })
  const dateClass = classnames(styles.date, { [styles.bold]: isUnread })

  return (
    <div className={styles.info}>
      <span className={styles.wrap}>
        {to ? (
          <Link to={to} className={styles.avatar}>
            <NewAvatar src={avatar} />
          </Link>
        ) : (
          <NewAvatar className={styles.avatar} src={avatar} />
        )}
        <div className={styles.text}>
          {to ? (
            <Link to={to} className={linkClass}>
              {name}
            </Link>
          ) : (
            <span className={noLinkClass}>{name}</span>
          )}
          <DateTime dateTime={created} className={dateClass} />
        </div>
        {!spam &&
          !isWelcome &&
          isStaffUser(user) &&
          isAppropriateInterval(dateFrom, dateTo) && (
            <Label
              text={t('PostIsActive')}
              className={styles.label}
              color='success'
            />
          )}
        {isExpired(dateTo) && (
          <Label text={t('DateToIsExpired')} className={styles.label} />
        )}
        {isUpcoming(dateFrom) && (
          <Label
            text={t('DateFromIsPlanned')}
            className={styles.label}
            color='warning'
          />
        )}
        {spam && (
          <Label text={t('Blocked')} className={styles.label} type='outlined' />
        )}
      </span>
    </div>
  )
}

export default Info
