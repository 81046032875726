// @flow

import axios from 'axios'

import { paramsToQuery } from './api.core'

const HOST = 'https://api.openweathermap.org/data/2.5/weather'

export const getCurrentWeather = params =>
  axios.get(`${HOST}?${paramsToQuery(params)}`)
