// @flow

import React, { Component, useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import validation from 'react-validation-mixin'
import validationStrategy from 'joi-browser-validation-strategy'
import Joi from 'joi-browser'

import {
  TICKER_TYPE,
  POST_TYPE,
  TICKER_MAX_TITLE_LENGTH,
  TICKER_MAX_DESCRIPTION_LENGTH,
} from './Post.constants'
import { removeFile } from '../../utils/file'
import { audienceListReset } from '../modals/AudienceList/AudienceList.actions'
import { reset } from '../Building/BuildingList/BuildingList.actions'
import { getTime } from './Post.utils'

export type StateInfoProps = {
  isDateFromOpen: boolean,
  isDateToOpen: boolean,
}

export const withInfoState = WrappedComponent => {
  const Component = props => {
    const {
      postData: {
        new_audience: initialAudience,
        title: initialTitle,
        text: inititalDescription,
        date_from: initialDateFrom,
        date_to: initialDateTo,
      },
    } = props

    const [isDateFromOpen, openDateFrom] = useState(false)
    const [isDateToOpen, openDateTo] = useState(false)

    const [audience, setAudience] = useState(initialAudience)

    const [title, setTitle] = useState(initialTitle)
    const [description, setDescription] = useState(inititalDescription)

    const [postType, setPostType] = useState(POST_TYPE)

    const [dateFrom, setDateFrom] = useState(initialDateFrom)
    const [timeFrom, setTimeFrom] = useState(getTime(initialDateFrom))
    const [dateTo, setDateTo] = useState(initialDateTo)
    const [timeTo, setTimeTo] = useState(getTime(initialDateTo))

    return (
      <WrappedComponent
        {...props}
        isDateFromOpen={isDateFromOpen}
        openDateFrom={openDateFrom}
        isDateToOpen={isDateToOpen}
        openDateTo={openDateTo}
        audience={audience}
        setAudience={setAudience}
        title={title}
        setTitle={setTitle}
        postType={postType}
        setPostType={setPostType}
        description={description}
        setDescription={setDescription}
        dateFrom={dateFrom}
        setDateFrom={setDateFrom}
        dateTo={dateTo}
        setDateTo={setDateTo}
        timeFrom={timeFrom}
        setTimeFrom={setTimeFrom}
        timeTo={timeTo}
        setTimeTo={setTimeTo}
      />
    )
  }

  return Component
}

export const withBodyState = WrappedComponent => {
  const Component = props => {
    const {
      postData: {
        title: initialTitle,
        text: inititalDescription,
        post_type: initialPostType = POST_TYPE,
      },
    } = props

    const [title, setTitle] = useState(initialTitle)
    const [description, setDescription] = useState(inititalDescription)
    const [postType, setPostType] = useState(initialPostType)

    return (
      <WrappedComponent
        {...props}
        title={title}
        setTitle={setTitle}
        postType={postType}
        setPostType={setPostType}
        description={description}
        setDescription={setDescription}
      />
    )
  }

  return Component
}

export const withClass = WrappedComponent => {
  class ClassComponent extends Component {
    componentWillUnmount() {
      const {
        createdPost,
        postData: { files },
      } = this.props

      if (!createdPost) {
        files.forEach(f => removeFile(f.id))
      }

      this.props.dispatch(audienceListReset())
      this.props.dispatch(reset())
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }

  const mapStateToProps = state => ({
    postData: state.post,
    ...state.postCreating,
  })

  return connect(mapStateToProps)(ClassComponent)
}

export const withValidation = WrappedComponent => {
  class ValidatedComponent extends Component {
    reqText = `!! ${this.props.t('Common:FieldIsRequired')}`

    maxTitleText = `!! ${this.props.t('Common:TooLongTitle')}`

    maxDescriptionText = `!! ${this.props.t('Common:TooLongDescription')}`

    validationTitleMsg = {
      language: {
        any: {
          empty: this.reqText,
          invalid: this.reqText,
        },
        string: {
          max: this.maxTitleText,
        },
      },
    }

    validationDescriptionMsg = {
      language: {
        any: {
          empty: this.reqText,
          invalid: this.reqText,
        },
        string: {
          max: this.maxDescriptionText,
        },
      },
    }

    validatorTypes = {
      postType: Joi.string(),
      title: Joi.string()
        .options(this.validationTitleMsg)
        .required()
        .when('postType', {
          is: TICKER_TYPE,
          then: Joi.string().max(TICKER_MAX_TITLE_LENGTH),
        }),
      description: Joi.string()
        .invalid('<p><br></p>')
        .options(this.validationDescriptionMsg)
        .required()
        .when('postType', {
          is: TICKER_TYPE,
          then: Joi.string().max(TICKER_MAX_DESCRIPTION_LENGTH),
        }),
    }

    getValidatorData() {
      const { title, description, postType } = this.props

      return { title: title.trim(), description: description.trim(), postType }
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }

  return compose(
    withTranslation('Post'),
    validation(validationStrategy)
  )(ValidatedComponent)
}
