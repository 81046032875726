// @flow

import React from 'react'
import type { Node } from 'react'

import NewTable from '../../../NewTable'
import InvoicesTableHeader from './InvoicesTableHeader'
import NewTableBody from '../../../NewTable/NewTableBody'
import InvoicesTableRow from './InvoicesTableRow'

type Props = {
  fields: Array<Object>,
  invoices: Array<Object>,
  isLoadingReport: boolean,
  onClick: Function,
  onSelect: Function,
  openRemoveModal: Function,
  selectedItems: Array<number>,
  setEditingInvoice: Function,
}

const InvoicesTable = (props: Props): Node => {
  const { fields, selectedItems, isLoadingReport, invoices } = props

  return (
    <NewTable loading={isLoadingReport}>
      <InvoicesTableHeader fields={fields} />
      <NewTableBody>
        {invoices.map(invoice => (
          <InvoicesTableRow
            fields={fields}
            invoice={invoice}
            key={invoice.id}
            setEditingInvoice={props.setEditingInvoice}
            selectedItems={selectedItems}
            openRemoveModal={props.openRemoveModal}
            onClick={props.onClick}
            onSelect={props.onSelect}
          />
        ))}
      </NewTableBody>
    </NewTable>
  )
}

export default InvoicesTable
