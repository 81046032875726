// @flow

import React from 'react'

type Props = {
  className: string,
  subTitle: string,
  text: string,
  title: string,
}

const NoInternetPage = ({ className, title, subTitle, text }: Props) => (
  <div className={`site_wrap no-internet ${className}`}>
    <main className='site_workarea'>
      <div className='no-internet__message'>
        <div className='no-internet__header'>{title}</div>
        <div className='no-internet__sub-header'>{subTitle}</div>
        <div className='no-internet__separator'>
          <div className='no-internet__separator-start' />
          <div className='no-internet__separator-line' />
        </div>
        <p>{text}</p>
      </div>
    </main>
  </div>
)

NoInternetPage.defaultProps = {
  title: 'Whoops!',
  subTitle: 'It seems there is no Internet connection!',
  text:
    'Please, let your property manager know about this by phone number on the right panel',
}

export default NoInternetPage
