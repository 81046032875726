// @flow

import React from 'react'
import type { Node } from 'react'
import styles from './TwoLineText.module.scss'

type Props = {
  children: Node,
}

const TwoLineText = (props: Props): Node => {
  const { children } = props

  return <div className={styles.textBlock}>{children}</div>
}

export default TwoLineText
