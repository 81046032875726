// @flow

import React from 'react'
import type { Node } from 'react'

import styles from './CellContainer.module.scss'

type Props = { children: Node, style?: Object }

const CellContainer = (props: Props): Node => {
  const { children, style } = props

  return (
    <div className={styles.container} style={style}>
      {children}
    </div>
  )
}

export default CellContainer
