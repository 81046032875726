// @flow

import React from 'react'
import type { Node } from 'react'

import type { User } from '../Auth/Login/Login.reducer'
import Menu from '../Menu'
import Container from '../Container'
import Layout from './Layout'
import WidgetLayout from '../../containers/widgets/widgets'

type Props = {
  children: Node,
  user: User,
  widgets: ?(Node | Array<Node>),
}

const ThreeColumnLayout = (props: Props) => {
  const {
    widgets: Widgets,
    children,
    contacts,
    address,
    setAddressModal,
  } = props

  return (
    <Layout>
      <div className='content content--3-col'>
        <div className='container container_three'>
          <Menu />
          <Container>{children}</Container>
          {Widgets ? (
            <Widgets
              contacts={contacts}
              address={address}
              setAddressModal={setAddressModal}
            />
          ) : (
            <WidgetLayout />
          )}
        </div>
      </div>
    </Layout>
  )
}

ThreeColumnLayout.defaultProps = {
  widgets: null,
}

export default ThreeColumnLayout
