// @flow

import React, { Component, Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import { size } from 'lodash-es'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { compose } from 'redux'
import classnames from 'classnames'

import Checkbox from '../Checkbox'
import { Cell, CellsBar, Header, SimpleBlock } from '../Block'
import Lightbox from '../LightboxGallery'
import FileDownloader from '../FileDownloader'
import Nbsp from '../NBSP'
import BottomPagination from '../Pagination/BottomPagination'
import Button from '../Button'
import ConfirmationPopup from '../modals/ConfirmationPopup'
import DangerIcon from '../../../static/icons/danger.svg'
import DateTime from '../DateTime'
import EmptyList from '../EmptyList'
import {
  FILE_ACCEPT_TYPES,
  FILE_ORIGIN_TYPES,
} from '../../containers/FilesUpload'
import Icon, { EntityIcon } from '../Icon'
import Loader from '../Loader'
import Modal from '../Modal'
import Table from '../Table'
import TopPagination from '../Pagination/TopPagination'
import { getLocation } from '../../utils/commonSelectors'
import { getCurrentFilters, getCurrentPage } from '../../utils/routing'
import { getUrlForAvatar } from '../../utils/utils'
import addRedirectToFirstPage from '../../hocs/addRedirectToFirstPage'
import * as actions from './ImportFilesTable.actionTypes'
import NewFilesUpload from '../NewFilesUpload'

import styles from './ImportFilesTable.module.scss'

const STATUS_CSS = [
  'danger',
  'progress',
  'progress',
  'progress',
  'success',
  'danger',
  'warning',
  'default',
  'warning',
]

type Props = {
  data: any,
  dispatch: Object => void,
  forceLast: ?Object,
  initiated: boolean,
  last: Object,
  location: Object,
  modal: boolean,
  page: any,
  pk: ?string,
  selected: Array<string>,
  statuses: Object,
  t: string => string,
  working: boolean,
}
type State = {
  activeFile: string | null,
  uploading: boolean,
}

class ImportFilesTable extends Component<Props, State> {
  animateTimer = null

  timer = null

  state = {
    uploading: false,
    activeFile: null,
  }

  componentDidMount() {
    const { location } = this.props
    this.props.dispatch({
      type: actions.IMPORT_FILES_TABLE_INITIATING,
      params: getCurrentFilters(location),
    })

    this.timer = setInterval(() => {
      this.checklast()
    }, 5000)
  }

  componentDidUpdate(prev: Props) {
    const { forceLast, initiated, pk, last, location } = this.props

    if (location.search !== prev.location.search) {
      this.checklast()

      const page = getCurrentPage(location)
      this.setPage(page)
    }

    if (!prev.pk && pk) {
      this.props.dispatch(push(`/settings/import/map/${pk}`))

      return
    }

    if (!initiated) {
      return
    }

    const clearAnimate = () => {
      this.props.dispatch({
        type: actions.IMPORT_FORCE_LAST_STATUS,
        forceLast: null,
      })
    }

    const nextAnimate = () => {
      this.props.dispatch({
        type: actions.IMPORT_FORCE_LAST_STATUS,
        forceLast: {
          ...last,
          ...(last.progress === 1 ? { status: 3 } : {}), // for animation we consider is in progress
        },
      })
    }

    if (forceLast) {
      clearTimeout(this.animateTimer)
      this.animateTimer = setTimeout(
        forceLast.progress === 1 ? clearAnimate : nextAnimate,
        2000
      )
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  startUpload = () => {
    this.setState({ uploading: true })
  }

  finishUpload = (files: Array<Object>) => {
    if (files.length) {
      this.props.dispatch({
        type: actions.IMPORT_FILES_GET_IMPORT_PK,
        fileId: files[0].id,
      })
    } else {
      this.setState({ uploading: false })
    }
  }

  onToogle = e => {
    this.props.dispatch({
      type: actions.IMPORT_FILES_TABLE_TOGGLE,
      pk: parseInt(e.target.value, 10),
    })
  }

  onToggleAll = () => {
    this.props.dispatch({
      type: actions.IMPORT_FILES_TABLE_TOGGLE_ALL,
    })
  }

  onGoClick = index => {
    this.props.dispatch(push(`/settings/import/map/${index}`))
  }

  onCancelClick = pk => {
    this.props.dispatch({ type: actions.IMPORT_FILES_TABLE_CANCEL, pk })
  }

  onDeleteClick = pk => {
    this.props.dispatch({ type: actions.IMPORT_FILES_TABLE_REMOVE, sel: [pk] })
  }

  onRemove = () => {
    this.props.dispatch({ type: actions.IMPORT_FILES_TABLE_MODAL, modal: true })
  }

  setPage = page => {
    this.props.dispatch({
      type: actions.IMPORT_FILES_TABLE_SET_PAGE,
      page,
    })
  }

  checklast() {
    const { location } = this.props
    this.props.dispatch({
      type: actions.IMPORT_FILES_TABLE_LAST_STATUS,
      params: getCurrentFilters(location),
    })
  }

  removeImport = () => {
    const { selected, dispatch } = this.props
    dispatch({ type: actions.IMPORT_FILES_TABLE_REMOVE, sel: selected })
  }

  hideModal = () => {
    this.props.dispatch({
      type: actions.IMPORT_FILES_TABLE_MODAL,
      modal: false,
    })
  }

  showLightbox = (file: Object) => () => {
    this.setState({ activeFile: file })
  }

  closeLightbox = () => {
    this.setState({ activeFile: null })
  }

  renderUploader = () => {
    const {
      data: { results },
    } = this.props
    const { uploading } = this.state

    const unfinished = results.objects.filter(
      imp => imp.status === 3 || imp.status === 2
    )

    return (
      !unfinished.length && (
        <div className='u-a02-01'>
          <NewFilesUpload
            accept={FILE_ACCEPT_TYPES.excel}
            className='alert alert--middle alert--theme-1 alert--block alert--center alert--bottom'
            name='attach-file'
            originType={FILE_ORIGIN_TYPES.import}
            onStartUpload={this.startUpload}
            onFinishUpload={this.finishUpload}
          >
            <EmptyList
              embedded
              icon='file'
              title={`${this.props.t('FileTypeForImport')} .xls ${this.props.t(
                'And'
              )} .xlsx`}
            >
              <Button.Save working={uploading}>
                {this.props.t('LoadFile')}
              </Button.Save>
            </EmptyList>
          </NewFilesUpload>
        </div>
      )
    )
  }

  renderImports = () => {
    const { data, t, working, selected, statuses } = this.props
    const imports = data.results.objects
    const fileCellClassName = classnames(
      'table-item table-item__text',
      styles.fileCell
    )

    if (!imports.length) return null

    if (!working) {
      return (
        <div className='table table--default'>
          <div className='table__outer-wrapper'>
            <div className='table__wrapper' style={{ tableLayout: 'fixed' }}>
              <div className='table__head'>
                <Table.HeaderCell
                  title={t('Filename')}
                  sortKey='file__filename'
                  working={working}
                />
                <Table.HeaderCell
                  title={t('User')}
                  sortKey='owner__profile__fullname'
                  working={working}
                />
                <Table.HeaderCell
                  title={t('Status')}
                  style={{ width: '30rem' }}
                  sortKey='status'
                  working={working}
                />
                <Table.HeaderCell
                  title={t('DateAndTime')}
                  style={{ width: '20rem' }}
                  sortKey='updated'
                  working={working}
                />
              </div>
              {imports.map((row, index) => (
                <div
                  className={`table__row ${
                    selected.includes(row.pk) ? 'table__row--attention' : ''
                  }`}
                  key={index}
                >
                  <div className='table__cell'>
                    <div className='table__cell-wrapper'>
                      <div className={fileCellClassName}>
                        <label className='checkbox checkbox--medium checkbox--default checkbox--notext table-item__checkbox import'>
                          <input
                            type='checkbox'
                            value={row.pk}
                            checked={selected.includes(row.pk)}
                            onChange={this.onToogle}
                          />
                          <span />
                        </label>
                        <Icon className='table-item__icon' id='upload' />
                        <a
                          title={row.file_obj.filename}
                          onClick={this.showLightbox(row.file_obj)}
                        >
                          {row.file_obj.filename}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className='table__cell'>
                    <div className='table__cell-wrapper'>
                      <div
                        className='table-item table-item__user'
                        title={row.owner_obj.fullname}
                      >
                        <i className='table-item__user-pic'>
                          <img
                            src={getUrlForAvatar(row.owner_obj)}
                            alt={row.owner_obj.fullname}
                          />
                        </i>
                        <span className='table-item__user-name'>
                          {row.owner_obj.fullname}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='table__cell'>
                    <div className='table__cell-wrapper'>
                      <div
                        title={t(statuses[row.status])}
                        className={`table-item table-item__status table-item__status--${
                          STATUS_CSS[row.status]
                        }`}
                      >
                        <span>{t(statuses[row.status])}</span>
                        {row.status === 6 && this.renderWarning(row)}
                      </div>
                    </div>
                  </div>
                  <div className='table__cell table__cell--text-right'>
                    <div className='table__cell-wrapper'>
                      <div className='table-item table-item__text'>
                        <span>
                          <DateTime newFormatting dateTime={row.created} />
                        </span>
                      </div>
                      <div className='table-extra'>
                        {this.renderBtns(row.status, row.pk)}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )
    }

    return <Loader type='medium' text={false} />
  }

  renderStatus = () => {
    const { forceLast } = this.props
    const last = {
      ...this.props.last,
      ...(forceLast || {}),
    }

    const { status, progress } = last
    switch (status) {
      case 0:
        return (
          <div className='bar__row bar-import__submit'>
            <button
              type='button'
              className='button button--large button--success'
              onClick={() => this.onGoClick(last.pk)}
            >
              {this.props.t('ContinueImport')}
            </button>
            <button
              type='button'
              className='button button--large button--danger-3'
              onClick={() => this.onDeleteClick(last.pk)}
            >
              {this.props.t('Cancel')}
            </button>
          </div>
        )
      case 1:
        return (
          <div className='bar__row bar-import__submit'>
            <button
              type='button'
              className='button button--large button--success'
              onClick={() => this.onGoClick(last.pk)}
            >
              {this.props.t('ContinueImport')}
            </button>
            <div className='bar__progress bar-import__submit'>
              <div className='bar__progress'>
                <span>
                  &nbsp;
                  {this.props.t('Imported')}
                  {last.added_count ? last.added_count : 0}{' '}
                  {this.props.t('Cells')}
                </span>
                <div className='progressbar progressbar--success'>
                  <div
                    className='progressbar__scale'
                    style={{ width: `${last.progress * 100}%` }}
                  />
                </div>
              </div>
            </div>
          </div>
        )
      case 2:
      case 3:
        return (
          <div className='bar__row'>
            <div className='bar__progress bar-import__submit'>
              <span>{this.props.t('InProgress')}</span>
              <div className='bar__progress'>
                <div className='progressbar progressbar--success'>
                  <div
                    className='progressbar__scale'
                    style={{
                      width: `${progress * 100}%`,
                      transition: 'width 2s',
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )
      case 5:
        return null
      case 6:
        return (
          <div className='bar__row bar-import__submit'>
            <FileDownloader
              id={last.err_file_obj.id}
              label={this.props.t('ShowImportSkips')}
            />
          </div>
        )
      default:
        return null
    }
  }

  renderBtns = (status, pk) => {
    const { t } = this.props

    if (status === 0) {
      return (
        <div style={{ display: 'flex' }}>
          <button
            type='button'
            className='button button--medium button--default-2'
            onClick={() => this.onDeleteClick(pk)}
          >
            {t('Cancel')}
          </button>
          <button
            type='button'
            className='button button--medium button--success'
            onClick={() => this.onGoClick(pk)}
          >
            {t('ContinueImport')}
          </button>
        </div>
      )
    }

    if (status === 7 || status === 8) {
      return (
        <button
          type='button'
          className='button button--medium button--danger'
          onClick={() => this.onDeleteClick(pk)}
        >
          {t('Delete')}
        </button>
      )
    }

    return (
      <button
        type='button'
        className='button button--medium button--default-2'
        onClick={() => this.onCancelClick(pk)}
      >
        {t('Cancel')}
      </button>
    )
  }

  renderWarning = warning => (
    <span>
      <div className='table-item__status-icon'>
        <DangerIcon className='table-item__status-icon--danger' />
        <div className='table-item__status-icon-tooltip'>
          <span>{this.props.t('SomePositionSkipped')}</span>
        </div>
      </div>
      <div className='table-item__status-details'>
        <a>{this.props.t('Details')}</a>
        <div className='dropdown dropdown--default dropdown--right'>
          <div className='table-item__status-details-drop'>
            <span className='table-item__status-details-title'>
              {this.props.t('ImportedPosition')}
            </span>
            <span className='table-item__status-details-string'>
              <i>{this.props.t('Added')}</i> {warning.added_count}
            </span>
            <span className='table-item__status-details-string'>
              <i>{this.props.t('Skipped')}</i> {warning.error_count}
            </span>
            <FileDownloader
              id={warning.err_file_obj.id}
              label={this.props.t('ShowImportSkips')}
            />
          </div>
        </div>
      </div>
    </span>
  )

  render() {
    if (!this.props.initiated) {
      return <Loader type='big' text={false} />
    }

    const { statuses, data, selected, modal, last } = this.props
    const { activeFile } = this.state
    const imports = data.results.objects

    return (
      <Fragment>
        {Boolean(size(last)) && (
          <SimpleBlock>
            <Header header>
              <EntityIcon large icon id='importation' />
              {this.props.t('ImportFileName')}:
              <Nbsp />
              <b>{last.file_obj.filename}</b>
            </Header>
            <CellsBar>
              <Cell title={this.props.t('DateOfImport')}>
                <DateTime dateTime={last.created} />
              </Cell>
              <Cell title={this.props.t('Status')}>
                <span className={`bar__cell-value--${STATUS_CSS[last.status]}`}>
                  {this.props.t(statuses[last.status])}
                </span>
              </Cell>
              <Cell title={this.props.t('Added')}>{last.added_count || 0}</Cell>
              <Cell title={this.props.t('Skipped')}>
                {last.error_count || 0}
              </Cell>
            </CellsBar>
            {this.renderStatus()}
          </SimpleBlock>
        )}

        <SimpleBlock>
          <Header header>
            {this.props.t('ImportedFiles')}
            <Nbsp />
            <span className='unit__title-num'>
              {!!data.meta.count && data.meta.count}
            </span>
          </Header>

          {imports.length > 0 && (
            <div className='toolbox'>
              <div className='toolbox__cell'>
                <Checkbox
                  outer
                  checked={
                    selected.length === imports.length && imports.length > 0
                  }
                  onChange={this.onToggleAll}
                />
              </div>

              {selected.length > 0 && (
                <div className='toolbox__cell'>
                  <Button.Remove onClick={this.onRemove} />
                </div>
              )}

              <div className='toolbox__cell toolbox__cell--right'>
                <TopPagination
                  basePage='settings/import'
                  classes='pager pager--large pager--default-2'
                  meta={data.meta}
                />
              </div>
            </div>
          )}

          {this.renderUploader()}

          {this.renderImports()}

          <BottomPagination
            classes='paginator paginator--middle'
            basePage='settings/import'
            meta={data.meta}
          />
          <Modal
            contentLabel=''
            className='Modal__Bootstrap modal-dialog'
            isOpen={modal}
            onRequestClose={this.hideModal}
          >
            <ConfirmationPopup
              title={this.props.t('DeleteImport')}
              text={this.props.t('Confirmmessage')}
              confirm={this.props.t('Delete')}
              cancel={this.props.t('Cancel')}
              onClose={this.hideModal}
              onOk={this.removeImport}
            />
          </Modal>
        </SimpleBlock>

        <Lightbox
          newGallery
          activeIndex={activeFile ? 0 : null}
          images={[activeFile]}
          scope='userimport'
          onClose={this.closeLightbox}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  ...state.ImportFilesTableReducer,
  location: getLocation(state),
})

export default compose(
  withTranslation('ImportFilesTable'),
  connect(mapStateToProps),
  addRedirectToFirstPage
)(ImportFilesTable)
