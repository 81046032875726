// @flow

const HIDDEN_OVERFLOW = 'hidden'

let blockedScroll = false
let savedOverflow = ''

export const blockScroll = () => {
  if (blockedScroll) {
    return
  }

  try {
    const { body } = document
    savedOverflow = body.style.overflow
    body.style.overflow = HIDDEN_OVERFLOW

    blockedScroll = true
  } catch (err) {
    console.log(err)
  }
}

export const allowScroll = () => {
  if (!blockedScroll) {
    return
  }

  try {
    const { body } = document
    body.style.overflow = savedOverflow
    savedOverflow = ''

    blockedScroll = false
  } catch (err) {
    console.log(err)
  }
}
