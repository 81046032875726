// @flow

import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import Loader from '../Loader'
import EmptyList from '../EmptyList'
import EmailIntegrationTable from './EmailIntegrationTable'
import TopPagination from '../Pagination/TopPagination'
import Toolbox from '../Toolbox'
import ToolboxCell from '../Users/UserList/Toolbox/ToolboxCell'
import { getCorporateMailAccountList } from '../../core/api/api.mail'
import { useLoad, usePathname } from '../../hooks'
import BottomPagination from '../Pagination/BottomPagination'
import addRedirectToFirstPage from '../../hocs/addRedirectToFirstPage'
import ErrorPopup from '../modals/ErrorPopup'
import Modal from '../Modal'

type Props = { setCount: (?number) => void }

const EmailIntegrationCorporate = (props: Props): Node => {
  const isLocked = usePathname()
  const [data, isLoading] = useLoad(getCorporateMailAccountList, null, isLocked)
  const location = useLocation()
  const history = useHistory()

  const queryParams = new URLSearchParams(location.search)
  const [modal, setModal] = useState(null)
  const { t } = useTranslation('EmailIntegration')

  useEffect(() => {
    if (data) {
      props.setCount(data.meta.count)
    }

    return function cleanup() {
      props.setCount(null)
    }
  }, [data])

  const hideModal = () => {
    setModal(null)
    queryParams.delete('error_message')
    history.replace({
      search: queryParams.toString(),
    })
  }

  useEffect(() => {
    if (queryParams.has('error_message')) {
      setModal(
        <ErrorPopup
          isServerError
          text={t(`Errors:${queryParams.get('error_message')}`)}
          title={t('Errors:IntegrationError')}
          confirm={t('Common:Close')}
          onOk={hideModal}
          onClose={hideModal}
        />
      )
    }
  }, [])

  if (!data && !isLoading) {
    return null
  }

  return (
    <>
      {data ? (
        <>
          <Toolbox>
            <ToolboxCell isRight>
              <TopPagination
                classes='pager pager--large pager--default-2'
                meta={data.meta}
              />
            </ToolboxCell>
          </Toolbox>
          <EmailIntegrationTable
            emails={data.results.objects}
            loading={isLoading}
          />
          {!data.results.objects.length && !isLoading && (
            <EmptyList
              embedded
              canAdd
              icon='email'
              title={t('EmailAccountNotFound')}
            />
          )}
          <BottomPagination meta={data.meta} classes='paginator-2' />
        </>
      ) : (
        <Loader type='big' text={false} />
      )}
      <Modal isOpen={!!modal} onRequestClose={hideModal}>
        {modal}
      </Modal>
    </>
  )
}

export default addRedirectToFirstPage(EmailIntegrationCorporate)
