// @flow

import React, { useState, useRef } from 'react'
import moment from 'moment'
import OutsideClick from 'react-onclickout'
import classNames from 'classnames'
import type { Node } from 'react'
import { useTranslation } from 'react-i18next'

import { getFormattedDate } from '../../../../../utils/utils'
import ReactCalendar from '../../../../filters/CalendarFilter/ReactCalendar'
import Button from '../../../../Button'

import styles from './InvoicesModalDate.module.scss'

type Props = {
  disabled?: boolean,
  error?: string,
  onChangeDate: Function,
  payment_date?: string,
  placeholder: string,
}

const InvoicesModalDate = (props: Props): Node => {
  const { placeholder, payment_date, error, disabled } = props
  const [menuIsOpen, setMenuOpen] = useState(false)
  const [position, setPosition] = useState('bottom')

  const selectRef = useRef()

  const { t } = useTranslation('BudgetPlan')

  const handleToggle = () => {
    if (!menuIsOpen) {
      const isBottom =
        selectRef.current &&
        window.innerHeight - selectRef.current.getBoundingClientRect().bottom >
          380

      setPosition(isBottom ? 'bottom' : 'top')
    }

    setMenuOpen(!menuIsOpen)
  }

  const handleClickOut = () => setMenuOpen(false)

  const calendarClass = classNames(styles.calendar, styles[position])

  const handleUpdateDate = date =>
    props.onChangeDate(moment(date).format('YYYY-MM-DD'))
  const clearDate = () => props.onChangeDate(null)

  return (
    <OutsideClick onClickOut={handleClickOut}>
      <div className={styles['payment-date']} ref={selectRef}>
        <div className={styles.date}>
          <Button.NewSelect
            disabled={disabled}
            error={!!error}
            placeholder={placeholder}
            selectedItems={payment_date ? [payment_date] : []}
            getSelectedLabel={value =>
              getFormattedDate(moment(value).format('YYYY-MM-DD'), {
                onlyDate: true,
              })
            }
            menuIsOpen={menuIsOpen}
            onClick={handleToggle}
          />
          {menuIsOpen && (
            <div className={calendarClass}>
              <ReactCalendar
                single
                minYear={1900}
                maxYear={moment().get('year') + 5}
                clearText={t('ClearDate')}
                style={{
                  textAlign: 'center',
                }}
                onValueChange={handleUpdateDate}
                onChange={date => {
                  handleUpdateDate(date[0])
                  setMenuOpen(false)
                }}
                onClear={() => {
                  clearDate()
                  setMenuOpen(false)
                }}
              />
            </div>
          )}
          {error && <span className={styles.errorText}>{error}</span>}
        </div>
      </div>
    </OutsideClick>
  )
}

export default InvoicesModalDate
