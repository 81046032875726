// @flow

import React, { Component } from 'react'
import seapig, { REQUIRED } from 'seapig'
import classnames from 'classnames'

import type { Node } from 'react'

type Props = {
  children: Node,
  className?: string,
  collapsed: boolean,
  notCollapse: boolean,
  onClickHeader?: () => void,
  style: Object,
  working?: boolean,
}

type State = {
  collapsed: boolean,
}

class Block extends Component<Props, State> {
  static defaultProps = {
    collapsed: false,
    onClickHeader: null,
    notCollapse: false,
    style: {},
  }

  state = {
    collapsed: this.props.collapsed,
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.collapsed !== this.props.collapsed) {
      this.setState({ collapsed: this.props.collapsed })
    }
  }

  onClickHeader = () => {
    this.setState(state => ({
      collapsed: !state.collapsed,
    }))
  }

  render() {
    const { className, working, children, notCollapse, style } = this.props

    const { collapsed } = this.state

    const sectionClass = classnames(
      'bar bar--default request-info',
      className,
      { 'working-overlay': working }
    )

    const { headerChildren, rest } = seapig(children, {
      header: REQUIRED,
    })

    const onClickHeader = notCollapse
      ? null
      : this.props.onClickHeader || this.onClickHeader

    const getHeaderProps = headerType => {
      const isElement = typeof headerType === 'string'

      const elementProps = {
        onClick: onClickHeader,
      }

      const componentProps = {
        collapsed,
        onClickHeader,
        notCollapse,
      }

      return isElement ? elementProps : componentProps
    }

    return (
      <section
        className={sectionClass}
        style={{ marginBottom: '20px', ...style }}
      >
        {headerChildren.map((child, index) =>
          React.cloneElement(child, {
            ...getHeaderProps(child.type),
            key: { index },
          })
        )}
        {!collapsed && rest}
      </section>
    )
  }
}

export default Block
