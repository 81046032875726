//@flow

export const EMAIL_REG_EXP: RegExp =
  /^([a-z0-9]+[a-z0-9_\-+]*(\.[a-z0-9_\-+]+)*)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/i
export const NOREPLY_EMAIL_REG_EXP: RegExp =
  /^(no[-]*reply|do-not-reply)\S*@\S+\.\S+/i

export const TEMPLATE_TYPE = {
  EMAIL: 'email',
  DOCS: 'docs',
}

export const DWELLER_AUDIENCE = 'dwellers'
export const MANAGER_AUDIENCE = 'managers'
export const PROVIDER_AUDIENCE = 'providers'
