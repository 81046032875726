// @flow

import React from 'react'
import classnames from 'classnames'
import type { Node } from 'react'

import styles from './Phase.module.scss'

type Props = { name: string, phaseWidth: number, progress: number }

const Phase = (props: Props): Node => {
  const { name, phaseWidth, progress } = props

  const phaseClass = classnames(styles.phase, {
    [styles.completed]: progress === 100,
  })

  return (
    <div
      className={phaseClass}
      style={{ width: `calc(${phaseWidth}% - 12px)` }}
    >
      <div />
      <span title={name}>{name}</span>
      <div />
    </div>
  )
}

export default Phase
