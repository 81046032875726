// @flow

import React from 'react'
import { ErrorMessage, Form, Formik } from 'formik'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { pickBy } from 'lodash-es'
import message from 'antd/es/message'
import { useTranslation } from 'react-i18next'
import { compose } from 'redux'
import * as Yup from 'yup'
import { useSelector } from 'react-redux'

import {
  Cell,
  CellsBar,
  Row,
  SimpleBlock,
  Header,
} from '../../components/Block'
import DateTime from '../../components/DateTime'
import { EntityIcon } from '../../components/Icon'
import { getFullDateFormat, getUkLogo } from '../../utils/commonSelectors'
import Button from '../../components/Button'
import Input from './Input'
import ValidationText from '../../components/ValidationText'
import CreateProviderPageMutation from './CreateProviderPage.mutation'
import { MARKETPLACE_ROUTES } from '../../constants'
import Checkbox from '../../components/Checkbox'

const FIELDS = [
  {
    name: 'name',
    type: 'text',
  },
  {
    name: 'address',
    type: 'text',
  },
  {
    name: 'contact_email',
    type: 'email',
  },
  {
    name: 'contact_phone',
    type: 'tel',
  },
  {
    name: 'workTime',
    type: 'text',
  },
  {
    name: 'description',
    type: 'textarea',
  },
]
const CONTACT_TYPES = ['contact_email', 'contact_phone']

const CreateProviderPage = props => {
  const { t } = useTranslation('Providers')
  const logo = useSelector(state => getUkLogo(state))
  const ukPhone = useSelector(state => state.init.uk.phone)
  let initialValues = FIELDS.reduce((acc, item) => {
    acc[item.name] = ''

    return acc
  }, {})

  initialValues.sendInvite = false

  const getValidationSchema = () => {
    const getErrorText = name => t('emptyInput', { key: t(name) })
    const stringValidation = name =>
      Yup.string().trim(getErrorText(name)).required(getErrorText(name))

    return Yup.object().shape({
      name: stringValidation('name'),
      address: stringValidation('address'),
      contact_email: Yup.string()
        .email('Invalid email')
        .required(getErrorText('contact_email')),
      contact_phone: stringValidation('contact_phone'),
      description: stringValidation('description'),
      workTime: stringValidation('workTime'),
      sendInvite: Yup.boolean().required(),
    })
  }

  const onSubmit = (values, { setSubmitting }) => {
    const providerInfo = pickBy(
      values,
      (_, key) => !CONTACT_TYPES.includes(key)
    )
    const contacts = Object.keys(values)
      .filter(k => CONTACT_TYPES.includes(k))
      .map(k => ({
        type: k,
        value: values[k],
      }))

    const thumbnail = (logo && (logo.thumbnail || logo.origin || '')) || ''

    CreateProviderPageMutation.commit(
      {
        pmcPhone: ukPhone || '',
        pmcLogo: thumbnail,
        ...providerInfo,
        contacts,
      },
      (info, errors) => {
        if (errors && errors.length > 0) {
          // TODO show errors
          const errorData = JSON.stringify(errors)
          // captureException(errorData)
          setSubmitting(false)
          message.error(errorData)
        } else {
          const { mutateProviderEx } = info

          if (mutateProviderEx && 'provider' in mutateProviderEx) {
            const { provider } = mutateProviderEx

            if (provider) {
              const { id } = provider
              props.history.push(MARKETPLACE_ROUTES.marketplaceProvider(id))
            } else {
              // TODO show error
            }
          }
        }
      },
      error => {
        setSubmitting(false)
        // error callback
        message.error(error)
      }
    )
  }

  const renderRow = formikProps => contact =>
    (
      <Row title={t(contact.name)} key={contact.name}>
        <Input
          autoFocus={contact.name === 'name'}
          contact={contact}
          formikProps={formikProps}
        />
        <ErrorMessage name={contact.name} render={renderError} />
      </Row>
    )

  const renderError = text => <ValidationText active text={text} />

  const goBack = e => {
    e.preventDefault()
    props.history.push(MARKETPLACE_ROUTES['marketplaceProviders'])
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={getValidationSchema()}
      onSubmit={onSubmit}
    >
      {formikProps => (
        <Form>
          <SimpleBlock working={formikProps.isSubmitting}>
            <Header header goBack={goBack}>
              <EntityIcon large icon id='provider' />
              {t('CreateProviderTitle')}
            </Header>
            <CellsBar>
              <Cell title={t('CreatedDate')}>
                <DateTime dateTime={new Date()} dateFormat={props.dateFormat} />
              </Cell>
              <Cell title={t('ServicesCount')}>0</Cell>
              <Cell title={t('OrdersCount')}>0</Cell>
            </CellsBar>
            {FIELDS.map(renderRow(formikProps))}
            <Row title={t('SendInvite')}>
              <Checkbox
                checked={formikProps.values.sendInvite}
                name='sendInvite'
                onChange={formikProps.handleChange}
              />
            </Row>
            <div style={{ padding: '15px' }}>
              <Button.Regular
                type='button'
                disabled={formikProps.isSubmitting}
                onClick={formikProps.handleSubmit}
              >
                {t('submit')}
              </Button.Regular>
            </div>
          </SimpleBlock>
        </Form>
      )}
    </Formik>
  )
}

const mapStateToProps = state => ({
  dateFormat: getFullDateFormat(state),
})

export default compose(withRouter, connect(mapStateToProps))(CreateProviderPage)
