//@flow

import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import MailSearch from '../../../MailSearch/MailSearch'
import ManagersListItem from './MagagersListItem'

import Modal from '../../../Modal'
import ModalBody from '../../../Modal/ModalBody'
import ModalCloseButton from '../../../Modal/ModalCloseButton'
import ModalHead from '../../../Modal/ModalHead'
import { useOverflow } from '../../../../hooks/useOverflow'
import { useThemeColor } from '../../../../hooks'
import { useUser } from '../../../../hooks/useUser'
import { getUserId } from '../../../../utils/utils'

import Checkbox from '../../../Checkbox'
import Button from '../../../Button'
import Pagination from '../../../../ui/Pagination'

import classnames from 'classnames'
import styles from './EditManagersListModal.module.scss'

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

const PAGE_SIZE = 10

type Props = {
  isOpen: boolean,
  managers: Array<Object>,
  onClose: () => void,
  onSave: (values: Array<Object>) => void,
  withProviders: boolean,
}

const EditManagersListModal = (props: Props): Node => {
  const { managers, isOpen, onClose, onSave, withProviders } = props
  const [managersList, setManagersList] = useState([])
  const [selected, setSelected] = useState([])
  const [inserted, setInserted] = useState(false)
  const [removed, setRemoved] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const { t } = useTranslation('Mail')

  useOverflow()

  const themeColor = useThemeColor()

  const buttonsClass = classnames(styles.buttons, {
    [styles.buttonsSelected]: selected.length > 0,
  })

  const user = useUser()
  const userId = getUserId(user)

  const nonSystemManager = (m, id) => ({
    profile: { id, email: m.name, fullname: '' },
    blacklist: m.blacklist,
  })

  useEffect(() => {
    setManagersList(managers.map(getManagerItem))
  }, [])

  useEffect(() => {
    if (inserted) {
      setCurrentPage(Math.ceil(managersList.length / PAGE_SIZE))
    }
  }, [inserted, managersList.length])

  useEffect(() => {
    if (removed) {
      const lastPage = Math.ceil(managersList.length / PAGE_SIZE)

      if (currentPage > lastPage) setCurrentPage(lastPage)
    }
  }, [removed, managersList.length])

  const getManagerItem = (manager, index) =>
    manager.profile ? manager : nonSystemManager(manager, index * -1)

  const toggle = id => {
    if (!selected.includes(+id)) setSelected([...selected, +id])
    else setSelected(selected.filter(i => i !== +id))
  }

  const toggleAll = () => {
    setSelected(
      selected.length === managersList.length
        ? []
        : managersList.map(m => m.profile.id)
    )
  }

  const insert = value => {
    setManagersList(value.map(getManagerItem))
    setInserted(true)
  }

  const removeSelected = () => {
    setManagersList(managersList.filter(m => !selected.includes(m.profile.id)))
    setSelected([])
    setRemoved(true)
  }

  const saveManagersList = () => {
    onSave(
      managersList.map(m => (m.profile.id > 0 ? m : { name: m.profile.email }))
    )
  }

  const renderManagersListItem = item => (
    <ManagersListItem item={item} selected={selected} toggle={toggle} />
  )

  const listChanged = inserted || removed

  return (
    <Modal
      isOpen={isOpen}
      style={{ content: { width: '550px' } }}
      className='edit-managers-list-modal'
      onRequestClose={onClose}
    >
      <ModalHead
        className='edit-managers-list-modal-title'
        title={t('SelectContacts')}
      />
      <ModalCloseButton onClose={onClose} />
      <ModalBody
        style={{
          height: '516px',
          '--themeColor': themeColor,
        }}
      >
        <DndProvider backend={HTML5Backend}>
          <MailSearch
            fromList
            withProviders={withProviders}
            id='edit-managers-list-search'
            managers={managersList}
            placeholder={t('SearchForContacts')}
            className='resend-popup-choose-usesdr'
            currentUserId={userId}
            params={{ group: ['admin', 'manager'] }}
            onChange={insert}
            onValidate={() => {}}
          />
          <div className={styles.toolbox}>
            {managersList.length > 0 && (
              <Checkbox
                className={styles.checkbox}
                checked={selected.length === managersList.length}
                text={t('SelectAll')}
                onChange={toggleAll}
              />
            )}
          </div>
          <div className={styles.list}>
            {managersList
              .slice(PAGE_SIZE * (currentPage - 1), PAGE_SIZE * currentPage)
              .map(renderManagersListItem)}
          </div>
          <div className={styles.paginator}>
            {managersList.length > PAGE_SIZE && (
              <Pagination
                noScroll
                currentPage={currentPage}
                pageSize={PAGE_SIZE}
                totalCount={managersList.length}
                onPageChange={setCurrentPage}
              />
            )}
          </div>
          <div className={buttonsClass}>
            {selected.length > 0 && (
              <>
                <Button.Cross
                  style={{ fill: '#f06785' }}
                  onClick={removeSelected}
                />
                <span className={styles.selected}>
                  {t('SelectedCount', { count: selected.length })}
                </span>
              </>
            )}
            {listChanged && (
              <Button.SaveList
                style={{ position: 'absolute', right: '16px' }}
                onClick={saveManagersList}
              >
                {t('Save')}
              </Button.SaveList>
            )}
          </div>
        </DndProvider>
      </ModalBody>
    </Modal>
  )
}

export default EditManagersListModal
