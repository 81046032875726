// @flow

import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { push } from 'connected-react-router'
import type { Node } from 'react'

import { getFormattedDate } from '../../../utils/utils'

import { STATUS_ACTIVE, STATUS_COLORS } from './Automate.constants'

import Checkbox from '../../Checkbox'
import NewTableBodyRow from '../../NewTable/NewTableBodyRow'
import NewTableBodyCell from '../../NewTable/NewTableBodyCell'
import SelectCustom from '../../Select/SelectCustom'
import TableCellUsers from '../../TableCellUsers'
import Labels from '../../Request/RequestList/ListItem/Cells/Info/Labels'

import styles from './AutomateTable.module.scss'

type Props = {
  item: Object,
  onRemove: Function,
  onSelect: Function,
  selectedItems: Array<Object>,
}

const TableBodyRow = (props: Props): Node => {
  const {
    item: { id, name, status, created_by, updated, permissions, labels },
    selectedItems,
  } = props
  const { t } = useTranslation('Automate')
  const dispatch = useDispatch()
  const handleClick = () => dispatch(push(`/settings/automate/profile/${id}/`))
  const [, setLabelsHeight] = useState(0)

  const handleSelect = () => props.onSelect(id)

  const statusText = status === STATUS_ACTIVE ? t('Active') : t('Inactive')
  const statusColor = STATUS_COLORS[status]

  let options = []

  if (permissions?.can_delete && props.onRemove) {
    options.push(
      options.push({
        label: t('Delete'),
        value: 'remove',
        handler: () => props.onRemove(id),
        icon: 'remove',
      })
    )
  }

  return (
    <NewTableBodyRow onClick={handleClick}>
      <NewTableBodyCell
        title={name}
        style={labels?.length ? { paddingTop: '10px' } : {}}
      >
        <div className={styles.labels}>
          <Labels items={labels} setHeight={setLabelsHeight} />
        </div>
        <Checkbox
          style={{ marginLeft: '10px' }}
          checked={selectedItems.includes(id)}
          onChange={handleSelect}
        />
      </NewTableBodyCell>
      <NewTableBodyCell style={{ color: statusColor }} title={statusText} />
      <NewTableBodyCell className={styles.users}>
        <TableCellUsers items={[created_by]} />
      </NewTableBodyCell>
      <NewTableBodyCell title={getFormattedDate(updated)}>
        {options.length > 0 && (
          <div className='table-extra table-extra--settings'>
            <SelectCustom
              options={options}
              onChange={({ handler }) => handler()}
            />
          </div>
        )}
      </NewTableBodyCell>
    </NewTableBodyRow>
  )
}

export default TableBodyRow
