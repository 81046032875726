// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import { map, pick } from 'lodash-es'
import type { Node } from 'react'

import Checkbox from '../Checkbox'
import SelectCustom from '../Select/SelectCustom'
import { getFormattedDate } from '../../utils/utils'
import OwnerIcon from '../Files/FileList/FileListItem/OwnerIcon'
import NewTableBodyRow from '../NewTable/NewTableBodyRow'
import NewTableBodyCell from '../NewTable/NewTableBodyCell'
import type { Params } from './Templates.types'

type Props = {
  isEmail?: boolean,
  selectedItems: Array<string>,
  template: Params,
}

const TemplatesBodyRow = (props: Props): Node => {
  const { template, selectedItems, isEmail } = props

  const {
    id,
    name,
    subject,
    tags,
    text_preview: textPreview,
    updated,
    hidden,
    created_by: createdBy,
    permissions: { can_edit: canEdit },
  } = template

  const { fullname } = createdBy

  const { t } = useTranslation('Templates')

  const formattedDate = updated ? getFormattedDate(updated) : '-'

  let options = []

  const handleEdit = props.handleEdit(
    pick(template, [
      'name',
      'id',
      'text',
      'subject',
      'private',
      'files',
      'tags',
      'text_type',
      'hidden',
      'permissions',
      'building_group_objs',
      'for_building_without_group',
    ])
  )

  const handleChange = () => props.handleChange(id)

  options.push({
    label: t('EditTemplate'),
    value: 'editTemplate',
    handler: handleEdit,
    icon: 'pencil',
  })

  options.push({
    label: t('CloneTemplate'),
    value: 'cloneTemplate',
    handler: props.handleClone(pick(template, ['name', 'id'])),
    icon: 'clone',
  })

  if (canEdit) {
    options.push({
      label: t('RemoveTemplate'),
      value: 'removeTemplate',
      handler: props.openRemoveModal(id),
      icon: 'bin',
    })
  }

  const tagsTitle = tags.length
    ? map(tags, tag => t(tag.description)).join(', ')
    : ''

  const rowProps = Object.assign(
    {},
    props.handleEdit
      ? {
          onClick: handleEdit,
        }
      : undefined
  )

  const hiddenTitle = hidden ? t('HiddenTempalte') : t('VisibleTempalte')

  return (
    <NewTableBodyRow {...rowProps}>
      <NewTableBodyCell title={name}>
        <Checkbox
          style={{ marginLeft: '16px' }}
          checked={selectedItems.includes(id)}
          onChange={handleChange}
        />
      </NewTableBodyCell>
      {isEmail ? (
        <>
          <NewTableBodyCell title={subject} />
          <NewTableBodyCell title={textPreview} />
        </>
      ) : (
        <NewTableBodyCell title={textPreview} />
      )}
      <NewTableBodyCell title={tagsTitle} text={tagsTitle} />
      <NewTableBodyCell title={hiddenTitle} text={hiddenTitle} />
      <NewTableBodyCell title={fullname}>
        <OwnerIcon file={createdBy} />
      </NewTableBodyCell>
      <NewTableBodyCell>
        <div className='table-item table-item__text' title={formattedDate}>
          <span>{formattedDate}</span>
        </div>
        <div className='table-extra table-extra--settings'>
          <SelectCustom
            options={options}
            onChange={({ handler }) => handler()}
          />
        </div>
      </NewTableBodyCell>
    </NewTableBodyRow>
  )
}

export default TemplatesBodyRow
