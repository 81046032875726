// @flow

import React, { useContext } from 'react'
import type { Node } from 'react'

import Type from './Type'
import CommitType from './CommitType'
import Actions from './Actions'
import ActivityItemContext from '../ActivityItem.context'
import RequestActivityContext from '../../../../../../RequestActivity.context'
import Icon from '../../../../../../../../../components/Icon'
import Nbsp from '../../../../../../../../../components/NBSP'

import styles from './ActivityItemHead.module.scss'

type Props = {
  isDone: boolean,
  isYesNo: ?'yes' | 'no',
  setDone: Function,
  setYesNo: Function,
}

const ActivityItemHead = (props: Props): Node => {
  const {
    activity: {
      type,
      commit_type,
      id,
      pinned,
      title,
      visible_to_tenant,
      priority,
      request_thread_count,
      unread_request_thread_count,
      request_threads,
      files,
      file_count,
      active_automation_count,
    },
    activity,
    phaseId,
  } = useContext(ActivityItemContext)

  const { setActivity, isDweller, outbound } = useContext(
    RequestActivityContext
  )

  const { isYesNo, isDone } = props

  const { icon, name } = type

  const handleChangeActivity = () => setActivity(activity)

  const activityFiles = files ? files.map(file => file.id) : []

  return (
    <div className={styles['item-head']}>
      <div className={styles['info-cont']}>
        <div className={styles.wrap}>
          <CommitType
            commit_type={commit_type}
            active_automation_count={active_automation_count}
            isYesNo={isYesNo}
            isDone={isDone}
            id={id}
            setDone={props.setDone}
            setYesNo={props.setYesNo}
          />
          <div className={styles.automateWrap}>
            {!outbound && !isDweller && active_automation_count > 0 ? (
              <Icon id='automate' />
            ) : (
              <Nbsp />
            )}
          </div>
          <Type
            icon={icon}
            name={name}
            title={title}
            priority={priority}
            fileCount={file_count}
            requestThreadCount={request_thread_count}
            unreadRequestThreadCount={unread_request_thread_count}
            id={id}
            phaseId={phaseId}
            threads={request_threads}
            onChange={handleChangeActivity}
          />
        </div>
      </div>
      {!outbound && !isDweller && (
        <Actions
          pinned={pinned}
          id={id}
          phaseId={phaseId}
          threads={request_threads}
          visible_to_tenant={visible_to_tenant}
          activityFiles={activityFiles}
          onChange={handleChangeActivity}
        />
      )}
    </div>
  )
}

export default ActivityItemHead
