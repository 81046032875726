// @flow

import React from 'react'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import Checkbox from '../../../Checkbox'
import NewTableBodyRow from '../../../NewTable/NewTableBodyRow'
import NewTableBodyCell from '../../../NewTable/NewTableBodyCell'
import SelectCustom from '../../../Select/SelectCustom'

import styles from './TableBodyRow.module.scss'

type Props = {
  onCopyItem: Function,
  onSelect: Function,
  selectedItems: Array<any>,
  setRemovingItems: Function,
  template: Object,
}

const TableBodyRow = (props: Props): Node => {
  const {
    template: {
      name,
      id,
      request_category: { name: categoryName },
    },
    selectedItems,
  } = props

  const { t } = useTranslation('Pipeline')

  const dispatch = useDispatch()

  const handleClick = () => dispatch(push(`/settings/pipeline/${id}/`))

  const handleSelect = () => props.onSelect(id)

  const options = [
    {
      value: 'editPipeline',
      label: t('EditPipeline'),
      onClick: handleClick,
      icon: 'pencil',
    },
    {
      value: 'deletePipeline',
      label: t('DeletePipeline'),
      onClick: () => props.setRemovingItems([id]),
      icon: 'bin',
    },
    {
      value: 'copyPipeline',
      label: t('CopyPipeline'),
      onClick: () => props.onCopyItem(id, name),
      icon: 'company',
    },
  ]

  return (
    <NewTableBodyRow onClick={handleClick}>
      <NewTableBodyCell title={name}>
        <Checkbox
          className={styles.checkbox}
          checked={selectedItems.includes(id)}
          onChange={handleSelect}
        />
      </NewTableBodyCell>
      <NewTableBodyCell title={categoryName}>
        <div className='table-extra table-extra--settings'>
          <SelectCustom options={options} onChange={opt => opt.onClick()} />
        </div>
      </NewTableBodyCell>
    </NewTableBodyRow>
  )
}

export default TableBodyRow
