// @flow

import React from 'react'
import Scrollbar from 'react-scrollbars-custom'

import TrackY from './NewFilterAsync/TrackY'
import ThumbY from './NewFilterAsync/ThumbY'
import ValueItem from './Select/ValueItem'

import styles3 from './RequestProviders.module.scss'

const RequestProviderLabels = props => {
  const { contractors } = props

  const handleRemove = id => () =>
    props.setContractors(contractors.filter(c => id !== c.id))

  return (
    <div
      className={styles3.listWrapper}
      style={{
        paddingLeft: '10px',
        paddingTop: '5px',
      }}
    >
      <Scrollbar
        noScrollX
        style={{ height: 115 }}
        trackYProps={{
          renderer: TrackY,
        }}
        thumbYProps={{
          renderer: ThumbY,
        }}
      >
        {contractors.map(contractor => (
          <ValueItem
            inline
            profile
            round
            cleanable
            text={contractor.name}
            key={contractor.id}
            id={contractor.id}
            avatar={contractor.avatar}
            className={styles3.valueItem}
            onRemove={handleRemove(contractor.id)}
          />
        ))}
      </Scrollbar>
    </div>
  )
}

export default RequestProviderLabels
