// @flow

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'

import ConfirmationPopup from '../modals/ConfirmationPopup'
import Input from '../Input'
import Modal from '../Modal'

type Props = {
  blocksCount: Array<Object>,
  onRemove: () => void,
  onRename: string => void,
  t: (string, ?Object) => string,
  title: string,
  working: boolean,
}
type State = {
  modal: boolean,
}

class RequestConstructorGroupTitle extends Component<Props, State> {
  state = {
    modal: false,
  }

  cancelRemoving = () => {
    this.setState({ modal: false })
  }

  confirmRemoving = () => {
    const { blocksCount, onRemove } = this.props

    if (blocksCount) {
      this.setState({ modal: true })
    } else {
      onRemove()
    }
  }

  remove = () => {
    this.cancelRemoving()
    this.props.onRemove()
  }

  renderConfirmation = () => {
    const { blocksCount, title, t } = this.props

    return (
      <Modal
        isOpen
        contentLabel=''
        className='Modal__Bootstrap modal-dialog'
        onRequestClose={this.cancelRemoving}
      >
        <ConfirmationPopup
          title={t('removeGroupTitle')}
          text={t('removeGroupText', { count: blocksCount, title })}
          confirm={t('Common:Delete')}
          cancel={t('Common:Cancel')}
          onClose={this.cancelRemoving}
          onOk={this.remove}
        />
      </Modal>
    )
  }

  render() {
    const { onRename, title, working } = this.props
    const { modal } = this.state

    return (
      <div>
        <Input.Edit
          value={title}
          working={working}
          onRemove={this.confirmRemoving}
          onSave={onRename}
        />
        {modal && this.renderConfirmation()}
      </div>
    )
  }
}

export default withTranslation('RequestConstructor')(
  RequestConstructorGroupTitle
)
