// @flow

import React, { Component } from 'react'
import { filter } from 'lodash-es'
import classnames from 'classnames'
import last from 'lodash-es/last'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'

import Loader from '../../components/Loader'
import Info from '../../components/Company/CompanyInfo'
import Contacts from '../../components/Company/CompanyContacts'
import ConfirmationPopup from '../../components/modals/ConfirmationPopup'
import Team from '../../components/Company/CompanyTeam'
import Modal from '../../components/Modal'
import CompanyAddress from '../../components/Company/CompanyAddress'
import * as actions from './CompanyView.actionTypes'
import {
  selectUkId,
  selectUkOffices,
} from '../../components/init/init.selectors'

class CompanyView extends Component {
  componentDidMount() {
    const { offices } = this.props
    // TODO move office init immediately after acquiring uk data
    this.props.dispatch({
      type: actions.COMPANY_VIEW_INITIATE,
      addresses: offices.map(office => ({
        ...office.address,
        officeId: office.id,
      })),
    })
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.modalCreate &&
      !this.props.modalCreate &&
      this.props.addresses.length > prevProps.addresses.length
    ) {
      const addedElement = last(this.props.offices)
      const id = `${addedElement.address.id}-${addedElement.id}`
      document.getElementById(id).scrollIntoView({ behavior: 'smooth' })
    }
  }

  toggleModal = () => {
    this.props.dispatch({
      type: actions.COMPANY_VIEW_ADDRESS_MODAL_REMOVE_TOGGLE,
    })
  }

  edit = (id, data) => {
    this.props.dispatch({
      type: actions.COMPANY_VIEW_ADDRESS_EDIT,
      id,
      data,
    })
  }

  save = () => {
    this.props.dispatch({ type: actions.COMPANY_VIEW_ADDRESS_SAVE })
  }

  cancel = (id, data) => {
    this.props.dispatch({
      type: actions.COMPANY_VIEW_ADDRESS_EDIT_CANCEL,
      id,
      data,
    })
  }

  remove = () => {
    this.props.dispatch({ type: actions.COMPANY_VIEW_ADDRESS_REMOVE })
  }

  toggleEditMode = (id, cancel = false) => {
    this.props.dispatch({
      type: actions.COMPANY_VIEW_ADDRESS_EDIT_MODE,
      id,
      cancel,
    })
  }

  onSectionInit = key => {
    this.props.dispatch({ type: actions.COMPANY_VIEW_SECTION_READY, key })
  }

  render() {
    const {
      companyId,
      modelId,
      ready,
      addresses,
      editMode,
      modalCreate,
      modalRemove,
      offices,
    } = this.props
    const initiating = !!filter(ready, section => !section).length

    const sectionClass = classnames({
      'content-initiating': initiating,
    })

    return (
      <div className={sectionClass}>
        {initiating && <Loader text={false} type='big' />}
        <Info />
        <Team onInit={() => this.onSectionInit('team')} />
        <Contacts
          profileId={companyId}
          modelId={modelId}
          onInit={() => this.onSectionInit('contacts')}
        />
        {addresses.map(address => (
          <CompanyAddress
            modal={modalCreate}
            working={this.props.working}
            address={address}
            editMode={editMode}
            key={`${address.id}-${address.officeId}`}
            id={`${address.id}-${address.officeId}`}
            initiated={!initiating}
            offices={offices}
            officeId={address.officeId}
            onEdit={this.edit}
            onCancel={this.cancel}
            onSave={this.save}
            onRemove={this.remove}
            onToggleEditMode={this.toggleEditMode}
          />
        ))}
        {modalRemove && (
          <Modal
            contentLabel=''
            className='Modal__Bootstrap modal-dialog'
            isOpen={modalRemove}
            onRequestClose={this.toggleModal}
          >
            <ConfirmationPopup
              title={this.props.t('DeleteAddressTitle')}
              text={this.props.t('DeleteAddressText')}
              onClose={this.toggleModal}
              onOk={this.remove}
            />
          </Modal>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state.companyView,
  companyId: selectUkId(state),
  modelId: state.init.models_with_contacts.Uk,
  offices: selectUkOffices(state),
})

export default compose(
  withTranslation('Company'),
  connect(mapStateToProps)
)(CompanyView)
