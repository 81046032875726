// @flow

import { all, put, call, fork, takeLatest } from 'redux-saga/effects'
import type { Saga } from 'redux-saga'

import api from '../../core/api'
import * as actions from './MarketplaceSettings.actionTypes'
import { serverError } from '../../components/Layout/Layout.actions'

function* handleError(error) {
  yield put(serverError(error))
}

function* watchInit() {
  yield takeLatest(actions.MARKETPLACE_SETTINGS_INITIATE, init)
}

function* watchUpdate() {
  yield takeLatest(actions.MARKETPLACE_SETTINGS_UPDATE, update)
}

function* init() {
  try {
    const data = yield call(api.marketplace.get)
    yield put({ type: actions.MARKETPLACE_SETTINGS_WERE_INITIATED, data })
  } catch (error) {
    yield* handleError(error)
  }
}

function* update({ params }) {
  try {
    const data = yield call(api.marketplace.update, params)
    yield put({
      type: actions.MARKETPLACE_SETTINGS_WERE_UPDATED,
      data,
    })
  } catch (error) {
    yield* handleError(error)
  }
}

export default function* watch(): Saga<void> {
  yield all([fork(watchInit), fork(watchUpdate)])
}
