// @flow

import React from 'react'

function CellButton(props) {
  const { children } = props

  return (
    <button
      type='button'
      className='table-item__text-dropdown'
      onClick={props.onClick}
      onMouseDown={e => e.stopPropagation()}
    >
      {children}
    </button>
  )
}

export default CellButton
