// @flow

import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Progress from 'antd/es/progress'
import { useSelector } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import classnames from 'classnames'

import Button from '../Button'
import PageContainer from './PageContainer'
import {
  setStep1,
  getDocumentMapping,
} from '../../core/api/api.documentMapping'
import { getStatus } from './MappedDocument.utils'
import { getThemeColor } from '../../utils/commonSelectors'
import DocumentContainer from './DocumentContainer'
import NewSelectSimple from '../NewSelectSimple'

import 'antd/es/progress/style/index.css'

import styles from './MappedDocument.module.scss'

const Step1 = props => {
  const { t } = useTranslation('DocumentMapping')

  const { pageRef, pagesPerDocument, fileOrigin, id, pageCount } = props

  const [width, setWidth] = useState(0)

  useEffect(() => {
    setWidth(pageRef.current.offsetWidth - 2)
  }, [])

  const formik = useFormik({
    initialValues: {
      splittingOption: true,
      pagesPerDocument: pagesPerDocument || '',
    },
    initialTouched: { pagesPerDocument: true },
    validationSchema: Yup.object({
      splittingOption: Yup.boolean(),
      pagesPerDocument: Yup.mixed().when('splittingOption', {
        is: true,
        then: Yup.number()
          .integer(t('EnterValidPageNumber'))
          .positive(t('EnterValidPageNumber'))
          .typeError(t('EnterValidPageNumber'))
          .required(t('FieldIsRequired')),
        otherwise: Yup.mixed(),
      }),
    }),
    onSubmit: () => {
      handleStep()
    },
  })

  const color = useSelector(state => getThemeColor(state))

  const [loading, setLoading] = useState(true)
  const [progress, setProgress] = useState(null)

  const options = [
    { value: false, label: t('NotSplitDocument') },
    { value: true, label: t('SplitDocument') },
  ]

  const handleStep = () => {
    setLoading(true)

    setStep1(id, {
      pages_per_document: formik.values.splittingOption
        ? formik.values.pagesPerDocument
        : null,
    })
      .then(data => getStatus(data.eventId))
      .then(() => getDocumentMapping(id))
      .then(data => {
        setLoading(false)
        props.setDocument(data)
      })
  }

  const getPercent = () => {
    if (!progress) {
      return 0
    }

    const { total, loaded } = progress

    return (100 / total) * loaded
  }

  const handleSucces = () => {
    setLoading(false)
    setProgress(null)
  }

  const numberFieldClass = classnames(
    'input input--large input--block input--default',
    {
      'input--error': !!formik.errors.pagesPerDocument,
    }
  )

  const selectValue = options.find(
    option => option.value === formik.values.splittingOption
  )

  const handleSelect = option => {
    const { value } = option

    formik.setFieldValue('splittingOption', value)
  }

  return (
    <>
      <section className='unit unit--default'>
        <div className='settings-personal__form-row'>
          <div className='alert--warning-box'>
            <div className='alert alert--small alert--warning alert--block alert--center alert--top'>
              <p className='alert__text'>{t('Step1Description')}</p>
            </div>
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className='settings-personal__form-row'>
            <div className='settings-personal__form-cell'>
              <label className='settings-personal__form-label'>
                {t('DocumentSplitting')}
              </label>
            </div>
            <div className='settings-personal__form-cell'>
              <NewSelectSimple
                disabled={loading}
                name='splittingOption'
                value={selectValue}
                options={options}
                onChange={handleSelect}
              />
            </div>
          </div>
          <div className='settings-personal__form-row'>
            <div className='settings-personal__form-cell'>
              <label className='settings-personal__form-label'>
                {t('SplittingPageCount')}
              </label>
            </div>
            <div className='settings-personal__form-cell'>
              <div className={numberFieldClass}>
                <div>
                  <input
                    disabled={!formik.values.splittingOption}
                    value={formik.values.pagesPerDocument}
                    name='pagesPerDocument'
                    onChange={formik.handleChange}
                  />
                  <div className='input__msg'>
                    {formik.errors.pagesPerDocument}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='settings-personal__submit'>
            <Button.Save
              disabled={loading || !!formik.errors.pagesPerDocument}
              type='submit'
            >
              {t('NextStep')}
            </Button.Save>
          </div>
        </form>
      </section>
      {progress && (
        <Progress
          strokeColor={color}
          percent={getPercent()}
          status='active'
          showInfo={false}
          style={{ padding: '0', paddingTop: '1px' }}
        />
      )}
      <section className='unit unit--default'>
        <div className={styles.container}>
          <DocumentContainer
            fileOrigin={fileOrigin}
            width={width}
            onLoadProgress={setProgress}
            onLoadSuccess={handleSucces}
          >
            <PageContainer pageCount={pageCount} width={width} />
          </DocumentContainer>
        </div>
      </section>
    </>
  )
}

export default Step1
