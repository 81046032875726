// @flow

import { all, put, call, fork, takeEvery, takeLatest } from 'redux-saga/effects'

import api from '../../../core/api'
import { serverError, globalModalError } from '../../Layout/Layout.actions'
import * as actions from './ProfileInfo.actionTypes'
import { PROFILE_INITIATING } from '../../../containers/Profile/Profile.actionTypes'
import { deleteExternalProfile } from '../../../core/api/api.profile'

function* watchUpdate() {
  yield takeLatest(actions.PROFILE_INFO_UPDATE, update)
}

function* watchSubscribe() {
  yield takeEvery(actions.PROFILE_INFO_SUBSCRIBE, subscribe)
}

function* watchUnsubscribe() {
  yield takeEvery(actions.PROFILE_INFO_UNSUBSCRIBE, unsubscribe)
}

function* watchAccept() {
  yield takeEvery(actions.PROFILE_INFO_ACCEPT, acceptInvite)
}

function* watchRejected() {
  yield takeEvery(actions.PROFILE_INFO_REJECT, rejectedInvite)
}

function* watchChangeUserStatus() {
  yield takeEvery(actions.PROFILE_INFO_CHANGE_USER_STATUS, changeUserStatus)
}

function* watchApproved() {
  yield takeEvery(actions.PROFILE_INFO_APPROVE, approveDweller)
}

function* watchRemoveDweller() {
  yield takeEvery(actions.PROFILE_INFO_REMOVE, removeUser)
}

function* watchRestoreDweller() {
  yield takeEvery(actions.PROFILE_INFO_RESTORE, restoreUser)
}

function* watchChangeDwellerRole() {
  yield takeEvery(actions.PROFILE_INFO_CHANGE_DWELLER_ROLE, changeDwellerRole)
}

function* watchChangeManagerRole() {
  yield takeEvery(actions.PROFILE_INFO_CHANGE_MANAGER_ROLE, changeManagerRole)
}

function* watchChangeExternalId() {
  yield takeEvery(actions.PROFILE_INFO_CHANGE_EXTERNAL_ID, changeExternalId)
}

function* approveDweller({ id }) {
  try {
    const data = yield call(api.profile.update, id, { validated: true })
    yield put({ type: actions.PROFILE_INFO_UPDATED, data })
  } catch (error) {
    yield put(serverError(error))
    yield put({ type: actions.PROFILE_INFO_ERROR, error })
  }
}

function* update({ params, id, tiny, external }) {
  try {
    let avatar = null

    if (params.avatar) {
      const newParams = {
        dataUrl: params.avatar.dataUrl,
        name: params.avatar.origin,
      }

      const file = yield call(api.file.createFile, newParams)
      avatar = file.id
    }

    const data = yield call(
      tiny
        ? api.profile.updateTiny
        : external
        ? api.profile.externalUpdate
        : api.profile.update,
      id,
      {
        ...params,
        ...(avatar ? { avatar } : {}),
      }
    )

    yield put({ type: actions.PROFILE_INFO_UPDATED, data })
  } catch (error) {
    yield put(serverError(error))
    yield put({
      type: actions.PROFILE_INFO_ERROR,
      error: error.message.response.data,
    })
  }
}

function* subscribe({ owner }) {
  try {
    yield call(api.friends.subscribe, owner)
    yield put({ type: actions.PROFILE_INFO_SUBSCRIBED })
  } catch (error) {
    yield put(serverError(error))
    yield put({ type: actions.PROFILE_INFO_ERROR, error })
  }
}

function* acceptInvite({ user }) {
  try {
    const relationship = 'friend_request_accepted'
    const result = yield call(api.friends.handleRequest, { user, relationship })
    yield put({ type: actions.PROFILE_INFO_ACCEPTED, result })
  } catch (error) {
    yield put(serverError(error))
  }
}

function* rejectedInvite({ user }) {
  try {
    const relationship = 'friend_request_rejected'
    const result = yield call(api.friends.handleRequest, { user, relationship })
    yield put({ type: actions.PROFILE_INFO_REJECTED, result })
  } catch (error) {
    yield put(serverError(error))
  }
}

function* unsubscribe({ owner }) {
  try {
    yield call(api.friends.unsubscribe, owner)
    yield put({ type: actions.PROFILE_INFO_UNSUBSCRIBED })
  } catch (error) {
    yield put(serverError(error))
    yield put({ type: actions.PROFILE_INFO_ERROR, error })
  }
}

function* changeUserStatus({ data: { profileId, newStatus } }) {
  try {
    const result = yield call(api.profile.changeStatus, profileId, {
      status: newStatus,
    })
    yield put({
      type: actions.PROFILE_INFO_CHANGE_USER_STATUS_COMPLETED,
      newStatus,
      permissions: result.permissions,
    })
  } catch (error) {
    if (error.message.response.status === 400) {
      yield put(globalModalError(error.message.response.data.errors))

      return
    }

    yield put(serverError(error))
  }
}

function* removeUser(action) {
  const {
    profileId,
    profileData: { fullname },
    external,
  } = action

  try {
    if (external) {
      yield call(deleteExternalProfile, profileId)
    } else {
      yield call(api.massAction.remove, {
        model: 'Profile',
        model_pks: JSON.stringify([profileId]),
      })
      yield put({ type: actions.PROFILE_INFO_REMOVE_COMPLETED, fullname })
      yield put({ type: PROFILE_INITIATING, id: profileId })
    }
  } catch (error) {
    yield put(serverError(error))

    if (error.message.response.status === 400) {
      yield put(globalModalError(error.message.response.data.detail))
    }

    yield put({ type: actions.PROFILE_INFO_ERROR, error })
  }
}

function* restoreUser(action) {
  const {
    profileId,
    profileData: { fullname },
  } = action

  try {
    yield call(api.massAction.updateRequest, {
      model: 'Profile',
      model_pks: JSON.stringify([profileId]),
      patch: {
        soft_archived: false,
      },
    })

    yield put({ type: actions.PROFILE_INFO_RESTORE_COMPLETED, fullname })
    yield put({ type: PROFILE_INITIATING, id: profileId })
  } catch (error) {
    yield put(serverError(error))

    if (error.message.response.status === 400) {
      yield put(globalModalError(error.message.response.data.detail))
    }

    yield put({ type: actions.PROFILE_INFO_ERROR, error })
  }
}

function* changeDwellerRole(action) {
  const { profileId, group } = action
  try {
    const result = yield call(api.profile.changeUserRole, profileId, { group })
    yield put({ type: actions.PROFILE_INFO_CHANGED_DWELLER_ROLE, result })
  } catch (error) {
    yield put(serverError(error))
  }
}

function* changeManagerRole(action) {
  const { profileId, group } = action
  try {
    const result = yield call(api.profile.changeUserRole, profileId, { group })
    yield put({ type: actions.PROFILE_INFO_CHANGED_MANAGER_ROLE, result })
  } catch (error) {
    yield put(serverError(error))
  }
}

function* changeExternalId(action) {
  const { profileId, externalId, external } = action
  try {
    const result = yield call(
      external ? api.profile.externalUpdate : api.profile.changeUserRole,
      profileId,
      {
        external_id: externalId,
      }
    )
    yield put({ type: actions.PROFILE_INFO_CHANGED_EXTERNAL_ID, result })
  } catch (error) {
    if (error.message.response.status === 400) {
      yield put({ type: actions.PROFILE_INFO_EXTERNAL_ID_ERROR })

      return
    }

    yield put(serverError(error))
  }
}

export default function* watch() {
  yield all([
    fork(watchUpdate),
    fork(watchAccept),
    fork(watchSubscribe),
    fork(watchUnsubscribe),
    fork(watchRejected),
    fork(watchApproved),
    fork(watchChangeUserStatus),
    fork(watchRemoveDweller),
    fork(watchRestoreDweller),
    fork(watchChangeDwellerRole),
    fork(watchChangeManagerRole),
    fork(watchChangeExternalId),
  ])
}
