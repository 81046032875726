// @flow

import React from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import RouterTabs from '../Tabs/RouterTabs'
import Pan from '../Tabs/Pan'
import Templates from '../Templates/Templates'
import PostTemplates from '../PostTemplates'

const TemplatesTab = (): Node => {
  const location = useLocation()

  const { t } = useTranslation('Templates')

  return (
    <div className='content__col' style={{ paddingTop: '20px' }}>
      <section className='settings-rights unit unit--default'>
        <RouterTabs>
          <Pan label={t('EmailTemplatesTabTitle')} linkHash='#email'>
            <Templates isEmail location={location} />
          </Pan>
          <Pan label={t('CommonTemplatesTabTitle')} linkHash='#common'>
            <Templates location={location} />
          </Pan>
          <Pan label={t('PostTemplatesTabTitle')} linkHash='#post'>
            <PostTemplates location={location} />
          </Pan>
        </RouterTabs>
      </section>
    </div>
  )
}

export default TemplatesTab
