// @flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import OutsideClick from 'react-onclickout'
import type { Node } from 'react'
import NewFilterAsyncSearch from '../../../components/NewFilterAsync/NewFilterAsyncSearch'
import List from '../../../components/List'
import { Link } from 'react-router-dom'
import { useDebounce } from '../../../hooks'
import styles from './RequestSearch.module.scss'

type Props = {
  handleClickOut: () => void,
  items: Array<Object>,
  placeholder?: string,
  position: 'bottom' | 'top',
  searchKey?: string,
}

const RequestSearch = (props: Props): Node => {
  const { placeholder, items, position, searchKey } = props
  const { t } = useTranslation('Select')
  const [value, setValue] = useState('')

  const debouncedValue = useDebounce(value, 300)
  const filteredItems = debouncedValue
    ? items.filter(item => {
        const label = item.request_no.toString()

        return label.toLowerCase().includes(debouncedValue.toLocaleLowerCase())
      })
    : items

  const menuClass = classnames(styles.menu, styles[position])

  return (
    <OutsideClick onClickOut={props.handleClickOut}>
      <div className={menuClass}>
        <NewFilterAsyncSearch
          loading={false}
          initiating={false}
          value={value}
          disabled={false}
          placeholder={placeholder}
          onInputChange={setValue}
        />
        <List className={styles.list}>
          {filteredItems.length ? (
            filteredItems.map(item => {
              return (
                <li key={item.id} className={styles.listItem}>
                  <Link className={styles.link} to={`/request/${item.id}`}>
                    {' '}
                    {t('Common:NumberSymbol')} {item[searchKey]}
                  </Link>
                </li>
              )
            })
          ) : (
            <div className={styles.noResults}>{t('NoResults')}</div>
          )}
        </List>
      </div>
    </OutsideClick>
  )
}

export default RequestSearch
