// @flow

import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import Textarea from 'react-textarea-autosize'
import OutsideClick from 'react-onclickout'

import Button from './Button'

type Props = {
  checkitemText: string,
  isEdit: boolean,
  onCancel: () => void,
  onDelete: () => void,
  onSave: string => void,
}

const IGNORE_CLICKOUT_CLASS = 'ignore-checklist-clickout'

const CheckitemEditable = (props: Props): Node => {
  const [text, setText] = useState(props.checkitemText)
  const textarea = useRef()
  const { t } = useTranslation('Checklist')
  const { isEdit } = props

  const listenForKeyPress = event => {
    if (event.key === 'Escape' || event.keyCode === 27) {
      event.preventDefault()
      props.onCancel()
    } else if (event.key === 'Enter' || event.keyCode === 13) {
      event.preventDefault()
      save()
    }
  }

  const handleClickOut = p => {
    let cancel = true

    if (
      p
        .composedPath()
        .some(
          el =>
            typeof el.className === 'string' &&
            el.className.includes(IGNORE_CLICKOUT_CLASS)
        )
    )
      cancel = false

    if (cancel) {
      props.onCancel()
    }
  }

  const handleTextChange = e => {
    setText(e.target.value)
  }

  const save = () => {
    if (text.length === 0 && !isEdit) {
      return
    }

    props.onSave(text)

    if (!isEdit) setText('')

    if (textarea.current) {
      textarea.current.focus()
    }
  }

  return (
    <div className='checklist__item-details-edit-container'>
      <OutsideClick onClickOut={handleClickOut}>
        <Textarea
          autoFocus
          className='checklist__item-details-textarea'
          inputRef={textarea}
          placeholder={t('Checklist')}
          value={text}
          onChange={handleTextChange}
          onKeyDown={listenForKeyPress}
        />
      </OutsideClick>
      <div className='checklist__item-details-controls'>
        <Button.Text
          large
          bordered
          className={IGNORE_CLICKOUT_CLASS}
          icon='plusLarge'
          title={t(isEdit ? 'Save' : 'Add')}
          onClick={save}
        />
        <Button.Text
          large
          warning
          bordered
          className={IGNORE_CLICKOUT_CLASS}
          icon='close'
          title={t('Cancel')}
          onClick={props.onCancel}
        />
        {isEdit && (
          <Button.Text
            large
            warning
            bordered
            className={IGNORE_CLICKOUT_CLASS}
            icon='close'
            title={t('Delete')}
            onClick={props.onDelete}
          />
        )}
      </div>
    </div>
  )
}

export default CheckitemEditable
