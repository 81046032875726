// @flow

import React from 'react'
import type { Node } from 'react'

import Row from '../Row'

import styles from './Textarea.module.scss'

type Props = {
  error?: string,
  name: string,
  onBlur: Object => void,
  onChange: Object => void,
  placeholder: string,
  title: string,
  value: string,
}

const Textarea = (props: Props): Node => {
  const { title, name, value, placeholder, error } = props

  return (
    <Row top title={title} error={error}>
      <textarea
        className={styles.textarea}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={props.onChange}
        onBlur={props.onBlur}
      />
    </Row>
  )
}

export default Textarea
