// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'

import NewTableBodyCell from '../../NewTable/NewTableBodyCell'
import NewTableBodyRow from '../../NewTable/NewTableBodyRow'
import Button from '../../Button'
import InlineSvg from '../../InlineSvg'

const ActivityTypeTableRow = props => {
  const { t } = useTranslation('Activities')

  const {
    activityType,
    activityType: {
      name,
      enabled,
      id,
      icon: { origin },
    },
    toggleDisabled,
  } = props

  return (
    <NewTableBodyRow
      onClick={() => {
        props.setEditingActivityType(activityType)
        props.onEdit()
      }}
    >
      <NewTableBodyCell title={name} />
      <NewTableBodyCell align='center'>
        <InlineSvg url={origin} />
      </NewTableBodyCell>
      <NewTableBodyCell>
        <div className='table-item table-item__text'>
          <span>{t(enabled ? 'Active' : 'InActive')}</span>
        </div>
        <Button.Toggle
          checked={enabled}
          id={id}
          disabled={toggleDisabled}
          onChange={() =>
            props.handleToggleActivityType(id, { enabled: !enabled })
          }
        />
      </NewTableBodyCell>
    </NewTableBodyRow>
  )
}

export default ActivityTypeTableRow
