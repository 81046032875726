export const LOAD_FEEDBACK = 'LOAD_FEEDBACK'
export const FEEDBACK_WAS_LOADED = 'FEEDBACK_WAS_LOADED'
export const SEND_RATING = 'SEND_RATING'
export const RATING_WAS_SENT = 'RATING_WAS_SENT'
export const SEND_FEEDBACK = 'SEND_FEEDBACK'
export const FEEDBACK_WAS_SENT = 'FEEDBACK_WAS_SENT'
export const UPDATE_RATING = 'UPDATE_RATING'
export const RATING_WAS_UPDATED = 'RATING_WAS_UPDATED'
export const CLOSE_FEEDBACK = 'CLOSE_FEEDBACK'
export const UPDATE_FEEDBACK = 'UPDATE_FEEDBACK'
export const FEEDBACK_WAS_UPDATED = 'FEEDBACK_WAS_UPDATED'
export const LOAD_RATING = 'LOAD_RATING'
export const RATING_WAS_LOADED = 'RATING_WAS_LOADED'
export const FEEDBACK_UPDATE_FILES = 'FEEDBACK_UPDATE_FILES'
export const FEEDBACK_FILES_WAS_UPDATED = 'FEEDBACK_FILES_WAS_UPDATED'
export const UPDATE_LIKE = 'SET_LIKE'
export const LIKE_WAS_UPDATED = 'LIKE_WAS_UPDATED'
export const LOAD_REQUEST = 'LOAD_REQUEST'
export const REQUEST_WAS_LOADED = 'REQUEST_WAS_LOADED'
export const FEEDBACK_ERROR = 'FEEDBACK_ERROR'
export const CLEAR_FEEDBACK_ERROR = 'CLEAR_FEEDBACK_ERROR'

export const loadRating = (params) => ({ type: LOAD_RATING, params })
export const ratingWasLoaded = (rating) => ({ type: RATING_WAS_LOADED, rating })
export const sendRating = (params) => ({ type: SEND_RATING, params })
export const ratingWasSent = (payload) => ({ type: RATING_WAS_SENT, payload })
export const updateRating = (id, params) => ({ type: UPDATE_RATING, id, params })
export const ratingWasUpdated = (rating) => ({ type: RATING_WAS_UPDATED, rating })

export const loadFeedback = (id) => ({ type: LOAD_FEEDBACK, params: { id } })
export const feedbackWasLoaded = (payload) => ({ type: FEEDBACK_WAS_LOADED, payload })
export const sendFeedback = (payload) => ({ type: SEND_FEEDBACK, payload })
export const feedbackWasSent = (payload) => ({ type: FEEDBACK_WAS_SENT, payload })
export const updateFeedback = (payload) => ({ type: UPDATE_FEEDBACK, payload })
export const feedbackWasUpdated = (payload) => ({ type: FEEDBACK_WAS_UPDATED, payload })

export const closeFeedback = () => ({ type: CLOSE_FEEDBACK })

export const updateFeedbackFiles = (files) => ({ type: FEEDBACK_UPDATE_FILES, files })
export const feedbackFilesWasUpdated = (data) => ({ type: FEEDBACK_FILES_WAS_UPDATED, payload: { data } })

export const updateLike = (isDislike) => ({ type: UPDATE_LIKE, isDislike })

export const loadRequest = (id) => ({ type: LOAD_REQUEST, id })
export const requestWasLoaded = (payload) => ({ type: REQUEST_WAS_LOADED, payload })

export const throwFeedbackError = (payload) => ({ type: FEEDBACK_ERROR, payload })
export const clearFeedbackError = () => ({ type: CLEAR_FEEDBACK_ERROR })
