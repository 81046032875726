// @flow

import React from 'react'
import type { Node } from 'react'
import { useTranslation } from 'react-i18next'

import NewTable from '../../NewTable'
import NewTableHeader from '../../NewTable/NewTableHeader'
import NewTableHeaderRow from '../../NewTable/NewTableHeaderRow'
import NewTableHeaderCell from '../../NewTable/NewTableHeaderCell'
import NewTableBody from '../../NewTable/NewTableBody'
import SalutationTableRow from './SalutationTableRow'

import EmptyList from '../../EmptyList/EmptyList'

type Props = {
  isLoading: boolean,
  onEdit: () => void,
  salutations: Object,
  setEditingSalutation: (Salutation: Object) => void,
  setIsModalDeleteOpen: (isOpen: boolean) => void,
}

const SalutationTable = ({
  salutations,
  isLoading,
  setEditingSalutation,
  setIsModalDeleteOpen,
  onEdit,
}: Props): Node => {
  const { t } = useTranslation('Salutation')

  const options = [
    {
      value: 'deleteSalutation',
      label: t('DeleteSalutation'),
      onClick: id => {
        setIsModalDeleteOpen(true)
        setEditingSalutation({ id })
      },
      icon: 'bin',
    },
  ]

  return (
    <>
      <NewTable>
        <NewTableHeader>
          <NewTableHeaderRow>
            <NewTableHeaderCell title={t('Salutation')} />
            <NewTableHeaderCell title={t('Profile')} />
          </NewTableHeaderRow>
        </NewTableHeader>
        <NewTableBody>
          {salutations.results.objects.map(salutations => {
            return (
              <SalutationTableRow
                key={salutations.id}
                salutations={salutations}
                options={options}
                setEditingSalutation={setEditingSalutation}
                onEdit={onEdit}
              />
            )
          })}
        </NewTableBody>
      </NewTable>
      {!salutations.results.objects.length && !isLoading && (
        <EmptyList icon='folder' title={t('SalutationNotFound')} />
      )}
    </>
  )
}

export default SalutationTable
