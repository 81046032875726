// @flow

import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Textarea from 'react-textarea-autosize'

import { MARKETPLACE_ROUTES } from '../../constants'
import Button from '../../components/Button'
import { parseMpPrice } from '../../utils/utils'
import { useSelector } from 'react-redux'
import { getUk, getUser } from '../../utils/commonSelectors'

const ConfirmOrderModal = ({ loading, ...props }) => {
  const { comment } = props

  const { t } = useTranslation('Offers')

  const { name } = useSelector(getUk)

  const handleChangeComment = e => {
    props.setComment(e.target.value)
  }

  return (
    <div className='modal__content modal__content--width'>
      <button className='modal__close' type='button' onClick={props.onClose} />
      <div className='modal__title'>{t('orderOfferHeader')}</div>
      <div className='modal__body modal__body--postscope'>
        <div className='m-a10__row'>{t('orderOfferText')}</div>
        <div className='m-a10__row'>
          <Textarea
            className='textarea-2__input modal-adduser__comment'
            placeholder={t('SetOrderComment')}
            value={comment}
            onChange={handleChangeComment}
          />
        </div>
        <div className='m-a10__row'>
          <span>{t('SendingOrderConfirm', { name })}</span>
        </div>
      </div>
      <div className='modal__submit'>
        <Button.Save
          working={loading}
          type='button'
          disabled={loading}
          onClick={props.onOrder}
        >
          {t('confirm')}
        </Button.Save>
        <button
          type='button'
          className='m-a06__05 button button--large button--danger-3'
          onClick={props.onClose}
        >
          {t('cancel')}
        </button>
      </div>
    </div>
  )
}

const OrderCreatedModal = props => {
  const {
    oneClickOrder: { order: { orderNo, id, offer } = {}, order },
    phone,
  } = props.info

  const {
    language_obj: { code },
  } = useSelector(getUser)

  if (!order || Object.keys(order).length === 0) {
    // TODO обработка ошибки
    return null
  }

  const { t } = useTranslation('Offers') // eslint-disable-line
  // TODO убрать костыль, как только на бэке начнет возвращаться правильная цена заказа
  const cost = JSON.parse(offer).final_price

  const isNullPrice = parseFloat(cost) === 0

  const price = isNullPrice ? t('CostWilBeSpecified') : parseMpPrice(cost, code)

  const orderText = t('orderCreatedText', {
    orderNo,
    cost: price,
    phone,
  })

  return (
    <div className='modal__content modal__content--width'>
      <button className='modal__close' type='button' onClick={props.onClose} />
      <div className='modal__title'>{t('orderCreatedHeader')}</div>
      <div className='modal__body modal__body--postscope'>{orderText}</div>
      <div className='modal__submit'>
        <Link
          className='m-a06__06 button button--large button--success'
          to={MARKETPLACE_ROUTES['marketplaceOrder'](id)}
        >
          {t('goToOrder')}
        </Link>
        <button
          type='button'
          className='m-a06__05 button button--large button--danger-3'
          onClick={props.onClose}
        >
          {t('cancel')}
        </button>
      </div>
    </div>
  )
}

export default {
  Confirm: ConfirmOrderModal,
  CreateOrder: OrderCreatedModal,
}
