export const LABELS_MODAL_INIT = 'LABELS_MODAL_INIT'
export const LABELS_MODAL_INITED = 'LABELS_MODAL_INITED'
export const LABELS_MODAL_LABEL_CREATE = 'LABELS_MODAL_LABEL_CREATE'
export const LABELS_MODAL_LABEL_CREATED = 'LABELS_MODAL_LABEL_CREATED'
export const LABELS_MODAL_LABEL_UPDATE = 'LABELS_MODAL_LABEL_UPDATE'
export const LABELS_MODAL_LABEL_UPDATED = 'LABELS_MODAL_LABEL_UPDATED'
export const LABELS_MODAL_LABEL_DELETE = 'LABELS_MODAL_LABEL_DELETE'
export const LABELS_MODAL_LABEL_DELETED = 'LABELS_MODAL_LABEL_DELETED'
export const LABELS_MODAL_SET_REQUEST_LABEL = 'LABELS_MODAL_SET_REQUEST_LABEL'
export const LABELS_MODAL_REMOVE_REQUEST_LABEL =
  'LABELS_MODAL_REMOVE_REQUEST_LABEL'
export const LABELS_MODAL_CANCELED_LABEL = 'LABELS_MODAL_CANCELED_LABEL'
