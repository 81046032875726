// @flow

import React from 'react'

import RequestList from '../../Request/RequestList'

type Props = {
  data: Object,
}

const BuildingRequests = (props: Props) => {
  const { data } = props

  return (
    <RequestList params={{ building: data.id }} init={{ building: data.id }} />
  )
}

export default BuildingRequests
