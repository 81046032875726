// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import NewTableHeaderCell from '../../../../components/NewTable/NewTableHeaderCell'
import NewTableHeaderRow from '../../../../components/NewTable/NewTableHeaderRow'
import NewTableHeader from '../../../../components/NewTable/NewTableHeader'

const AllProvidersTableHeader = (): Node => {
  const { t } = useTranslation('Providers')

  return (
    <NewTableHeader>
      <NewTableHeaderRow>
        <NewTableHeaderCell
          title={t('ProvidersNameSort')}
          style={{ width: '20%' }}
          sortKey='name'
        />
        <NewTableHeaderCell
          title={t('ProvidersCategorySort')}
          style={{ width: '20%' }}
          sortKey='category_count'
        />
        <NewTableHeaderCell
          title={t('ProvidersAddressSort')}
          style={{ width: '20%' }}
          sortKey='building_count'
        />
        <NewTableHeaderCell
          title={t('ProvidersEmailSort')}
          style={{ width: '15%' }}
          sortKey='email'
        />
        <NewTableHeaderCell
          title={t('ProvidersPhoneSort')}
          style={{ width: '15%' }}
          sortKey='phone'
        />
        <NewTableHeaderCell
          title={t('ProvidersOfferCountSort')}
          style={{ width: '10%' }}
          sortKey='offer_count'
        />
      </NewTableHeaderRow>
    </NewTableHeader>
  )
}

export default AllProvidersTableHeader
