// @flow

import React, { useState, useEffect } from 'react'
import type { Node } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useLoad, usePathname, useSelected } from '../../hooks'
import { getCurrentFilters } from '../../utils/routing'
import Loader from '../Loader'
import { Header } from '../Block'
import addRedirectToFirstPage from '../../hocs/addRedirectToFirstPage'
import AddCompanyModal from './AddCompanyModal'
import EmptyList from '../EmptyList'
import Toolbox from '../Toolbox'
import ToolboxCell from '../Users/UserList/Toolbox/ToolboxCell'
import Button from '../Button'
import TopPagination from '../Pagination/TopPagination'
import ConfirmationPopup from '../modals/ConfirmationPopup'
import ConnectedCompaniesTable from './ConnectedCompaniesTable'
import Modal from '../Modal'
import BottomPagination from '../Pagination/BottomPagination'

import {
  getInboundConfig,
  getOutboundConfig,
  massDeleteInbound,
  massDeleteOutbound,
} from '../../core/api/api.idwell2idwell'

const ConnectedCompanies = (): Node => {
  const { t } = useTranslation('IdwellToIdwell')
  const location = useLocation()
  const filters = getCurrentFilters(location)
  const outbound = filters.sub === 'outbound'

  const isLocked = usePathname()
  const [data, isLoading, isError, load] = useLoad(
    outbound ? getOutboundConfig : getInboundConfig,
    null,
    isLocked
  )
  const [selectedItems, changeSelected, setSelected, isAllSelected] =
    useSelected()
  const [modal, setModal] = useState(null)

  useEffect(() => {
    setSelected([])
  }, [outbound, filters.page])

  const handleOpenAddModal = () =>
    setModal(
      <AddCompanyModal isOpen outbound={outbound} onClose={handleCloseModal} />
    )

  const handleCloseModal = reload => {
    setModal(null)

    if (reload) {
      load()
    }
  }

  const handleConfirmRemove = ids => {
    setModal(
      <Modal isOpen onRequestClose={handleCloseModal}>
        <ConfirmationPopup
          isAsync
          confirm={t('Delete')}
          title={t('DeleteTitle')}
          text={t(
            selectedItems.length > 1 ? 'ConfirmDelete' : 'ConfirmDeleteOne'
          )}
          onClose={handleCloseModal}
          onOk={() => handleRemove(ids)}
        />
      </Modal>
    )
  }

  const handleRemove = ids => {
    const deleteApi = outbound ? massDeleteOutbound : massDeleteInbound
    deleteApi(ids).then(() => {
      handleCloseModal()
      setSelected([])
      load()
    })
  }

  if (isError) {
    return null
  }

  return (
    <>
      <Header header notCollapse>
        {t(outbound ? 'OutboundTitle' : 'InboundTitle')}&nbsp;
        {data && <span className='unit__title-num'>{data.meta.count}</span>}
      </Header>
      {isLoading && <Loader type='big' text={false} />}
      {!isLoading && data ? (
        <>
          <Toolbox
            showSelectAllCheckbox
            checked={isAllSelected(data?.results?.objects || [])}
            setSelected={setSelected}
            selected={
              !!selectedItems.length &&
              !isAllSelected(data?.results?.objects || [])
            }
            items={data?.results?.objects}
            itemValueKey='uuid'
          >
            {!selectedItems?.length && data.permissions?.can_create && (
              <ToolboxCell>
                <Button.Save onClick={handleOpenAddModal}>
                  {t('AddCompany')}
                </Button.Save>
              </ToolboxCell>
            )}
            {data.permissions?.can_delete &&
              selectedItems &&
              selectedItems.length !== 0 && (
                <ToolboxCell>
                  <Button.Remove
                    disabled={!selectedItems.length || isLoading}
                    onClick={() => handleConfirmRemove(selectedItems)}
                  >
                    {t('DeleteCompany')}
                  </Button.Remove>
                </ToolboxCell>
              )}
            <ToolboxCell isRight>
              <TopPagination
                classes='pager pager--large pager--default-2'
                meta={data.meta}
              />
            </ToolboxCell>
          </Toolbox>
          <ConnectedCompaniesTable
            companies={data.results?.objects || []}
            selectedItems={selectedItems}
            outbound={outbound}
            permissions={data.permissions}
            onSelect={changeSelected}
            onDelete={id => {
              handleConfirmRemove([id])
            }}
          />
          {data?.results?.objects?.length > 0 && (
            <BottomPagination meta={data.meta} classes='paginator-2' />
          )}
          {!data.results.objects.length && !isLoading && (
            <EmptyList
              embedded
              canAdd
              btnText={t('AddCompany')}
              icon='connect'
              title={t('NoConnectedCompanies')}
              onClick={handleOpenAddModal}
            />
          )}
        </>
      ) : null}
      {!!modal && modal}
    </>
  )
}

export default addRedirectToFirstPage(ConnectedCompanies)
