// flow

import { put, call, fork, takeEvery } from 'redux-saga/effects'

import api from '../../../core/api'
import { serverError } from '../../Layout/Layout.actions'
import * as actions from './UserListInfo.actionTypes'

function* watchInit() {
  yield takeEvery(actions.USER_LIST_INFO_INITIATING, initUserInfo)
}

export function* initUserInfo() {
  try {
    const {
      admins_managers: staffCount,
      dwellers_landlords: dwellersCount,
    } = yield call(api.profile.getCounters)

    yield put({
      type: actions.USER_LIST_INFO_INITIATED,
      staffCount,
      dwellersCount,
    })
  } catch (error) {
    yield put(serverError(error))
    yield put({ type: actions.USER_LIST_INFO_ERROR, error })
  }
}

export default function* watch() {
  yield fork(watchInit)
}
