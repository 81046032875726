// @flow

import React from 'react'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'

import NewTableBody from '../../NewTable/NewTableBody'

import ContractorsTableBodyRow from './ContractorsTableBodyRow'

const ContractorsTableBody = props => {
  const { contractors, selectedItems, fromBuildings } = props
  const dispatch = useDispatch()
  const handleOpen = id => dispatch(push(`/contractor/${id}`))

  return (
    <NewTableBody>
      {contractors.map(contractor => (
        <ContractorsTableBodyRow
          selectedItems={selectedItems}
          handleChange={
            props.toggleContractor && props.toggleContractor(contractor.id)
          }
          key={contractor.id}
          contractor={contractor}
          setBuildingModal={props.setBuildingModal}
          setCategoryModal={props.setCategoryModal}
          fromBuildings={fromBuildings}
          onOpen={handleOpen}
          onRemove={() => props.onRemove(contractor.id)}
          onRestore={() => props.onRestore(contractor.id)}
        />
      ))}
    </NewTableBody>
  )
}

export default ContractorsTableBody
