export const IMPORT_MAP_TABLE_INITIATING = 'IMPORT_MAP_TABLE_INITIATING'
export const IMPORT_MAP_TABLE_INITIATED = 'IMPORT_MAP_TABLE_INITIATED'
export const IMPORT_MAP_TABLE_ERROR = 'IMPORT_MAP_TABLE_ERROR'
export const IMPORT_MAP_TABLE_HIDE_COLUMN = 'IMPORT_MAP_TABLE_HIDE_COLUMN'
export const IMPORT_MAP_TABLE_SELECT = 'IMPORT_MAP_TABLE_SELECT'
export const IMPORT_MAP_TABLE_SUBMIT = 'IMPORT_MAP_TABLE_SUBMIT'
export const IMPORT_MAP_TABLE_SUBMIT_SUCCESS = 'IMPORT_MAP_TABLE_SUBMIT_SUCCESS'
export const IMPORT_MAP_TABLE_HIDE_HINT = 'IMPORT_MAP_TABLE_HIDE_HINT'
export const IMPORT_MAP_TABLE_HIDE_RESET_HINT = 'IMPORT_MAP_TABLE_HIDE_RESET_HINT'
export const IMPORT_MAP_TABLE_UNMOUNT = 'IMPORT_MAP_TABLE_UNMOUNT'
export const IMPORT_MAP_TABLE_REMOVE_SUCCESS = 'IMPORT_MAP_TABLE_REMOVE_SUCCESS'
export const IMPORT_MAP_TABLE_REMOVE = 'IMPORT_MAP_TABLE_REMOVE'
export const IMPORT_MAP_TABLE_CLOSE_MODAL = 'IMPORT_MAP_TABLE_CLOSE_MODAL'
