// @flow

import React, { memo } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { SimpleBlock } from '../../../components/Block'

import Chatroom from '../../Chatroom/Chatroom'
import * as selectors from '../Feedback.selectors'
import { NEW_FEEDBACK_ID } from '../Feedback'

type Props = {
  data: Object,
  match: Object,
  t: string => string,
}

const Chat = (props: Props) => {
  const { match, data, isLoading, buildingId, requestId } = props
  const { id: postUuid } = match.params

  if (isLoading || postUuid === NEW_FEEDBACK_ID) {
    return null
  }

  return (
    <SimpleBlock>
      <h2 header='true' className='unit__title'>
        {props.t('Discussion')}
      </h2>

      <Chatroom
        isEmbedded
        isPublic
        key='clients'
        parent='post'
        feedback={postUuid}
        chatroomUuid={data.chat || NEW_FEEDBACK_ID}
        templateParams={{ for_building: buildingId }}
        requestId={requestId}
        disabled={!data.permissions.can_add_comment}
      />
    </SimpleBlock>
  )
}

const mapStateToProps = state => ({
  data: selectors.getFeedbackData(state),
  buildingId: selectors.getRequestBuilding(state),
  requestId: selectors.getRequestId(state),
  isLoading: selectors.isLoading(state),
})

export default compose(
  withTranslation('Feedback'),
  withRouter,
  connect(mapStateToProps),
  memo
)(Chat)
