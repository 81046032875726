// @flow

import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import Icon from '../../../../../../../../../../components/Icon'
import SelectCustom from '../../../../../../../../../../components/Select/SelectCustom'
import { updateActivity } from '../../../../../../../../../../core/api/api.pipeline'
import ActionIcon from './ActionIcon'
import RequestActivityContext from '../../../../../../../RequestActivity.context'

import {
  DWELLER_AUDIENCE,
  PROVIDER_AUDIENCE,
} from '../../../../../../../../../../constants'

import styles from './Actions.module.scss'

const Actions = (props): Node => {
  const { pinned, visible_to_tenant, id, phaseId, threads, activityFiles } =
    props

  const { t } = useTranslation('Activities')

  const {
    setReloading,
    setThreadActivity,
    setThreadAudience,
    handleUpload,
    setThreadModal,
    setEmailsCreatingModal,
    setPhaseId,
  } = useContext(RequestActivityContext)

  const [isMounted, setMounted] = useState(false)
  const [isPinned, setPinned] = useState(pinned)

  useEffect(() => {
    if (!isMounted) {
      setMounted(true)
    }
  }, [])

  useEffect(() => {
    if (isMounted) {
      updateActivity(id, { pinned: isPinned }).then()
    }
  }, [isPinned])

  const handleOpenThread = () => {
    setPhaseId(phaseId)
    setThreadActivity({ activityId: id, threads })
    setThreadModal(true)
  }

  const handleEmailsCreating = audience => {
    setThreadActivity({ activityId: id, threads })
    setThreadAudience(audience)
    setEmailsCreatingModal(true)
  }

  const options = [
    {
      value: 'editActivity',
      label: t('EditActivity'),
      onClick: () => {
        setPhaseId(phaseId)
        props.onChange()
      },
      icon: 'pencil',
    },
    {
      value: 'createThreadContractors',
      label: t('CreateThreadContractors'),
      onClick: () => {
        setPhaseId(phaseId)
        handleEmailsCreating(PROVIDER_AUDIENCE)
      },
      icon: 'addEnv',
    },
    {
      value: 'createThreadCustomers',
      label: t('CreateThreadCustomers'),
      onClick: () => {
        setPhaseId(phaseId)
        handleEmailsCreating(DWELLER_AUDIENCE)
      },
      icon: 'addEnv',
    },
    {
      value: 'editThread',
      label: t('EditAttachedThreads'),
      onClick: handleOpenThread,
      icon: 'connectEnv',
    },
    {
      value: 'uploadFiles',
      label: t('UploadFiles'),
      onClick: () => {
        setPhaseId(phaseId)
        handleUpload({ activityId: id, activityFiles })
      },
      icon: 'attach',
    },
    {
      value: 'pinActivity',
      label: t(isPinned ? 'UnPinActivity' : 'PinActivity'),
      onClick: () => {
        setPinned(!isPinned)
      },
      icon: 'pin',
    },
    {
      value: 'coverActivity',
      label: t(visible_to_tenant ? 'UnCover' : 'Cover'),
      onClick: () => {
        updateActivity(id, { visible_to_tenant: !visible_to_tenant }).then(
          () => {
            setPhaseId(phaseId)
            setReloading(true)
          }
        )
      },
      icon: visible_to_tenant ? 'hideEye' : 'showEye',
    },
  ]

  return (
    <>
      <div className={styles.actions}>
        {isPinned && (
          <ActionIcon>
            <Icon id='activityPin' />
          </ActionIcon>
        )}
        <ActionIcon>
          <Icon id={visible_to_tenant ? 'showEye' : 'superIcon'} />
        </ActionIcon>
        <SelectCustom options={options} onChange={o => o.onClick()} />
      </div>
    </>
  )
}

export default Actions
