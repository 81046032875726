// @flow

import React, { useState } from 'react'
import type Node from 'react'

type Props = {
  fileOrigin: string,
  height: number,
  width: number,
}

const ImagePreview = ({ width, height, fileOrigin }: Props): Node => {
  const [zoom, setZoom] = useState(false)
  const toggleZoom = () => setZoom(!zoom)

  return (
    <img
      src={fileOrigin}
      style={{
        width: `${width}px`,
        maxHeight: `${height - 10}px`,
        objectFit: 'cover',
        cursor: zoom ? 'zoom-out' : 'zoom-in',
        transform: zoom ? 'scale(4)' : 'scale(1)',
        transformOrigin: 'top left',
      }}
      onClick={toggleZoom}
    />
  )
}

export default ImagePreview
