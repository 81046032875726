// @flow

import { Environment, Network, RecordSource, Store } from 'relay-runtime'

import cookies from './utils/cookies'

function fetchQuery(operation, variables) {
  const marketplaceToken = cookies.getItem('marketplace_token')
  const marketplaceUrl = cookies.getItem('marketplace_url')
  const mpSession = cookies.getItem('mpSessionId')

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Token ${marketplaceToken}`,
    MPSession: mpSession,
  }

  return fetch(`${marketplaceUrl}graphql/`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      query: operation.text,
      variables,
    }),
  }).then(response => {
    return response.json()
  })
}

const network = Network.create(fetchQuery)

const source = new RecordSource()
const store = new Store(source)

export default new Environment({
  network,
  store,
})
