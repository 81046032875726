// @flow

import React, { useEffect, useState } from 'react'
import { QueryRenderer, graphql } from 'react-relay'

import NewOrdersSelect from '../NewOrdersSelect'
import ValueItem from '../../../../Select/ValueItem'
import environment from '../../../../../createRelayEnvironment'
import { map, difference } from 'lodash-es'
import { useTranslation } from 'react-i18next'
import Loader from '../../../../Loader'

import styles from './OrdersPopup.module.scss'

const query = graphql`
  query OrdersPopupQuery($orderIds: String) {
    allLimitedCustomerOrders(ids: $orderIds) {
      totalCount
      edges {
        node {
          orderNo
          customerOrderId
          title
          canView
          canUnbind
        }
      }
    }
  }
`

function getLabel(order, t) {
  return `${t('OrderNumberPrefix')} №${order.orderNo}: ${
    order.title || JSON.parse(order.offer).name
  }`
}

const OrdersPopup = props => {
  const { title, orderIds } = props
  const [selectedOrderIds, setSelectedOrderIds] = useState(orderIds)

  const { t } = useTranslation('Request')

  useEffect(() => {
    document.body.style.overflowY = 'hidden'

    return function cleanup() {
      document.body.style.overflowY = 'scroll'
    }
  }, [])

  const handleRemove = value =>
    setSelectedOrderIds(difference(selectedOrderIds, [value]))

  const handleSave = () => {
    props.onChange({ order_ids: selectedOrderIds })
    props.onClose()
  }

  const minHeight = Math.min(selectedOrderIds.length * 40, 160)

  return (
    <div className='modal__content'>
      <button className='modal__close' type='button' onClick={props.onClose} />
      <div className='modal__title modal__title--big-bottom-margin'>
        {title}
      </div>
      <div className='modal__body'>
        {!!selectedOrderIds.length && (
          <div
            className='modal-adduser__assignees'
            style={{ minHeight: `${minHeight}px` }}
          >
            <QueryRenderer
              environment={environment}
              query={query}
              variables={{
                orderIds: JSON.stringify(selectedOrderIds),
              }}
              render={({ props }) => {
                if (!props) {
                  return <Loader text={false} />
                }

                const items = map(props.allLimitedCustomerOrders.edges, 'node')

                return items.map(item => (
                  <ValueItem
                    inline
                    profile
                    round
                    className={styles.valueItem}
                    cleanable={item.canUnbind}
                    text={getLabel(item, t)}
                    key={item.customerOrderId}
                    id={item.customerOrderId}
                    onRemove={() => handleRemove(item.customerOrderId)}
                  />
                ))
              }}
            />
          </div>
        )}
        <NewOrdersSelect
          selectedOrderIds={selectedOrderIds}
          setSelectedOrderIds={setSelectedOrderIds}
        />
      </div>
      <div className='modal__submit'>
        <button
          type='button'
          className='button button--large button--success'
          disabled={
            !(
              orderIds.length !== selectedOrderIds.length ||
              difference(orderIds, selectedOrderIds).length
            )
          }
          onClick={handleSave}
        >
          {t('OrderPopupOk')}
        </button>
        <button
          type='button'
          className='button button--large button--danger-3'
          onClick={props.onClose}
        >
          {t('Cancel')}
        </button>
      </div>
    </div>
  )
}

export default OrdersPopup
