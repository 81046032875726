import React from 'react'

import DateTHead from './DateTHead'
import DateTBody from './DateTBody'

const DateTable = (props) => (
  <table className='calendar__grid'>
    <DateTHead {...props} />
    <DateTBody {...props} />
  </table>
)

export default DateTable
