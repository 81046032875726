// @flow

import type { Node } from 'react'
import type { IBankDetails } from '../BuildingInformation'
import React from 'react'
import setFormikError from '../../../../utils/setFormikError'
import { isValid } from 'iban'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { bicRegExp } from '../../../../utils/utils'
import FieldRow from '../../../FieldRow'
import Button from '../../../Button'

import {
  IBAN,
  BIC,
  BANK_NAME,
  MAX_BANK_NAME_LENGTH,
  BANK_OWNER,
  MAX_BANK_OWNER_LENGTH,
} from './BuildingInformationBankDetails.constants'
import * as Yup from 'yup'

import styles from './BuildingInformationForms.module.scss'

type Props = {
  active: boolean,
  handleEditClose: () => void,
  initialValues: IBankDetails,
  isOpen: boolean,
  updateBankDetails: (data: {
    bankDetails: IBankDetails,
  }) => Promise<IBankDetails>,
}

const BankDetailsForm = ({
  isOpen,
  initialValues,
  active,
  handleEditClose,
  updateBankDetails,
}: Props): Node => {
  const { t } = useTranslation('Building')

  const onSubmit = values => {
    const bankDetails: IBankDetails = { ...values }
    updateBankDetails({ bank_details: bankDetails })
      .then(() => handleEditClose())
      .catch(error => setFormikError(error, setFieldError))
    handleEditClose()
  }

  const {
    values,
    errors,
    dirty,
    resetForm,
    setFieldError,
    isSubmitting,
    handleSubmit,
    handleChange,
    setFieldValue,
  } = useFormik({
    validateOnChange: true,
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      [IBAN]: Yup.string().test({
        message: t('ValidСharacters'),
        test: (value: string) => !value || isValid(value),
      }),
      [BIC]: Yup.string().matches(bicRegExp, t('ValidСharacters')),
      [BANK_NAME]: Yup.string().max(
        MAX_BANK_NAME_LENGTH,
        t('MaxInputLength', { length: MAX_BANK_NAME_LENGTH })
      ),
      [BANK_OWNER]: Yup.string().max(
        MAX_BANK_NAME_LENGTH,
        t('MaxInputLength', { length: MAX_BANK_NAME_LENGTH })
      ),
    }),
    onSubmit,
  })

  const handleChangeIBAN = e => {
    setFieldValue(IBAN, e.target.value.replace(/\s/g, ''))
  }

  const handleChangeBIC = e => {
    setFieldValue(BIC, e.target.value.replace(/\s/g, ''))
  }

  const onClosed = () => {
    handleEditClose()
    resetForm()
  }

  return (
    <form name='building_info_bank_details' onSubmit={handleSubmit}>
      <FieldRow
        name={IBAN}
        label={'IBAN'}
        value={values[IBAN]}
        error={errors[IBAN]}
        active={active}
        placeholder={'EnterIBAN'}
        handleChange={handleChangeIBAN}
      />
      <FieldRow
        name={BIC}
        active={active}
        label={'BIC'}
        error={errors[BIC]}
        value={values[BIC]}
        placeholder={'EnterBIC'}
        handleChange={handleChangeBIC}
      />
      {(values[BANK_NAME] || isOpen) && (
        <FieldRow
          name={BANK_NAME}
          active={active}
          label={'BankName'}
          error={errors[BANK_NAME]}
          value={values[BANK_NAME]}
          placeholder={'EnterBankName'}
          maxLength={MAX_BANK_NAME_LENGTH}
          handleChange={handleChange}
        />
      )}
      {(values[BANK_OWNER] || isOpen) && (
        <FieldRow
          name={BANK_OWNER}
          active={active}
          label={'BankOwner'}
          error={errors[BANK_OWNER]}
          value={values[BANK_OWNER]}
          placeholder={'EnterBankOwner'}
          maxLength={MAX_BANK_OWNER_LENGTH}
          handleChange={handleChange}
        />
      )}
      {active && (
        <div className={styles.buttonWrapper}>
          <Button.Save disabled={!dirty || isSubmitting} type='submit'>
            {t('Common:Save')}
          </Button.Save>
          <Button.Cancel onClick={onClosed}>{t('Common:Cancel')}</Button.Cancel>
        </div>
      )}
    </form>
  )
}

export default BankDetailsForm
