// @flow

import React from 'react'

import ListItem from '../../../ListItem'
import Button from '../../../Button'

function CategoryListItem(props) {
  const { item, isloading } = props

  const { name } = item

  const handleRemove = () => props.changeSelected(item)

  return (
    <ListItem
      primaryText={name}
      title={name}
      meta={<Button.Cross disabled={isloading} onClick={handleRemove} />}
    />
  )
}

export default CategoryListItem
