// @flow

import React from 'react'
import type { Node } from 'react'

import { useTranslation } from 'react-i18next'
import { TERMS_LINK, GDPR_LINK } from '../../../constants'
import styles from './Terms.module.scss'

const Terms = (): Node => {
  const { t } = useTranslation('Registration')

  return (
    <div className={styles.terms}>
      <span>
        {`${t('MoreInformation')} `}
        <a target='_blank' href={TERMS_LINK} rel='noopener noreferrer'>
          {t('TermsAndConditions')}
        </a>
        {` ${t('And')} `}
        <a target='_blank' href={GDPR_LINK} rel='noopener noreferrer'>
          {t('Gdpr')}
        </a>
        {` ${t('Here')} `}
      </span>
    </div>
  )
}

export default Terms
