// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import Icon from '../../../../../../Icon'

import styles from './AdminInfo.module.scss'

type Props = { count: number }

const Invoices = (props: Props): Node => {
  const { count } = props

  const { t } = useTranslation('Request')

  return (
    <>
      <div className={styles[['provider-notify']]} title={t('HasInvoices')}>
        <Icon
          id='budget'
          className={styles.icon}
          title={t('HasInvoices')}
          style={{ width: 'auto' }}
        />
      </div>
      {count === 1 && (
        <span style={{ marginLeft: '7px' }} className={styles.singleText}>
          {t('HasInvoices')}
        </span>
      )}
    </>
  )
}

export default Invoices
