// @flow

import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import type { Node } from 'react'

import { useOverflow } from '../../../hooks'
import { closeModal, createGroup } from '../Faq.actions'
import { isWorking } from '../Faq.selectors'
import ModalHead from '../../Modal/ModalHead/ModalHead'
import ModalCloseButton from '../../Modal/ModalCloseButton/ModalCloseButton'
import ModalBody from '../../Modal/ModalBody/ModalBody'
import ModalRow from '../../Modal/ModalRow/ModalRow'
import ModalCell from '../../Modal/ModalCell/ModalCell'
import ModalLabel from '../../Modal/ModalLabel/ModalLabel'
import ModalButtons from '../../Modal/ModalButtons/ModalButtons'
import Button from '../../Button'
import InputField from '../../InputField/InputField'

const MAX_GROUP_NAME_LENGTH = 256

const AddGroup = (): Node => {
  const dispatch = useDispatch()

  const working = useSelector(isWorking)

  const { t } = useTranslation('FAQ')

  useOverflow()

  const handleSubmit = name => dispatch(createGroup(name))

  const handleClose = () => dispatch(closeModal())

  const formik = useFormik({
    validateOnChange: true,
    enableReinitialize: true,
    initialValues: {
      name: '',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .max(
          MAX_GROUP_NAME_LENGTH,
          t('MaxGroupNameLength', { length: MAX_GROUP_NAME_LENGTH })
        )
        .required(t('GroupNameRequired')),
    }),
    onSubmit: values => {
      handleSubmit(values.name)
    },
  })

  return (
    <>
      <ModalHead title={t('add_group')} />
      <ModalCloseButton onClose={handleClose} />
      <form onSubmit={formik.handleSubmit}>
        <ModalBody>
          <ModalRow>
            <ModalCell>
              <ModalLabel>{t('group_name')}</ModalLabel>
            </ModalCell>
            <ModalCell gridNumber={2}>
              <InputField
                autoFocus
                value={formik.values.name}
                name='name'
                placeholder={t('GroupNamePlaceholder')}
                error={formik.errors.name}
                onChange={formik.handleChange}
              />
            </ModalCell>
          </ModalRow>
        </ModalBody>
        <ModalButtons>
          <Button.Save type='submit' disabled={working}>
            {t('Common:Add')}
          </Button.Save>
          <Button.Cancel disabled={working} onClick={handleClose}>
            {t('Common:Cancel')}
          </Button.Cancel>
        </ModalButtons>
      </form>
    </>
  )
}

export default AddGroup
