// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'

import Table from '../Table'

const DocumentMappingTableHeader = () => {
  const { t } = useTranslation('DocumentMapping')

  return (
    <Table.HeaderRow>
      <Table.HeaderCell
        title={t('DocumentMappingFileNameSort')}
        style={{ width: '30%' }}
      />
      <Table.HeaderCell
        title={t('DocumentMappingOwnerSort')}
        style={{ width: '30%' }}
      />
      <Table.HeaderCell
        title={t('DocumentMappingStatusSort')}
        style={{ width: '15%' }}
      />
      <Table.HeaderCell
        title={t('DocumentMappingCountSort')}
        style={{ width: '10%' }}
      />
      <Table.HeaderCell
        title={t('DocumentMappingDateSort')}
        style={{ width: '15%' }}
      />
    </Table.HeaderRow>
  )
}

export default DocumentMappingTableHeader
