//@flow

import { FORMIK_EMPTY_VALUE } from '../../../constants'

export const COOLING_TYPE = 'cooling_type'
export const EDV_NUMBER = 'edv_number'
export const FLAT_EQUIPMENT = 'flat_equipment'
export const FUNDING = 'funding'
export const METER_READING = 'meter_reading'
export const REASON_TERMINATION = 'reason_termination'
export const REASON_VACANCY = 'reason_vacancy'
export const UNIT_NUMBER = 'unit_number'
export const KEYS = 'keys'

export const MAX_LENGTH = 256

export const ALL_KEYS = [
  COOLING_TYPE,
  EDV_NUMBER,
  FLAT_EQUIPMENT,
  FUNDING,
  METER_READING,
  REASON_TERMINATION,
  REASON_VACANCY,
  UNIT_NUMBER,
  KEYS,
]

export const DEFAULT_VALUES: Object = {
  [COOLING_TYPE]: FORMIK_EMPTY_VALUE,
  [EDV_NUMBER]: FORMIK_EMPTY_VALUE,
  [FLAT_EQUIPMENT]: FORMIK_EMPTY_VALUE,
  [FUNDING]: FORMIK_EMPTY_VALUE,
  [METER_READING]: FORMIK_EMPTY_VALUE,
  [REASON_TERMINATION]: FORMIK_EMPTY_VALUE,
  [REASON_VACANCY]: FORMIK_EMPTY_VALUE,
  [UNIT_NUMBER]: FORMIK_EMPTY_VALUE,
  [KEYS]: FORMIK_EMPTY_VALUE,
}
