// @flow

import React, { useEffect, useRef, useState } from 'react'
import { get, map, omit, partial, pick, isEqual } from 'lodash-es'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import type { Node } from 'react'

import Button from '../../Button'
import { getVariables } from '../../../core/api/api.postTemplate'
import Checkbox from '../../Checkbox'
import { getBuildingGroupFilter } from '../../../core/api/api.building'
import { useOverflow } from '../../../hooks/useOverflow'
import { useSelected } from '../../../hooks'
import Modal from '../../Modal'
import ModalBody from '../../Modal/ModalBody'
import ModalHead from '../../Modal/ModalHead'
import { CLONE, CREATE, EDIT } from './PostTemplatePopup.constants'
import {
  getActionParams,
  getFieldClass,
  getInitialValues,
} from './PostTemplatePopup.utils'
import ModalCloseButton from '../../Modal/ModalCloseButton'
import InputField from '../../InputField'
import NewSelectSimple from '../../NewSelectSimple'
import NewSelectAsync from '../../NewSelectAsync'
import RequestFileList from '../../Emails/RequestFileList'
import TemplateVariables from '../../Templates/TemplatePopup/TemplateVariables'
import WysiwygEditor from '../../WysiwygEditor'
import ModalRow from '../../Modal/ModalRow'
import ModalButtons from '../../Modal/ModalButtons'

import styles from './PostTemplatePopup.module.scss'
import classnames from 'classnames'

type Params = {
  buttonText: string,
  id?: number,
  name?: string,
  private?: boolean,
  title: string,
}

type Props = {
  isOpen: boolean,
  onClose: () => void,
  params: Params,
  resetTemplates: () => void,
}

const PostTemplatePopup = (props: Props): Node => {
  const {
    params,
    params: { title, buttonText, id, permissions, post_ttl },
    isOpen,
  } = props

  const initialDay = post_ttl ? parseInt(post_ttl / (24 * 60 * 60)) : 0
  const initialHour = post_ttl
    ? parseInt((post_ttl % (24 * 60 * 60)) / (60 * 60))
    : 0

  const canEdit = get(permissions, 'can_edit', true)
  const canEditVisibility = get(permissions, 'can_change_visibility', true)

  const { t } = useTranslation('PostTemplates')

  const [loading, setLoading] = useState(false)
  const [day, setDay] = useState(initialDay)
  const [hour, setHour] = useState(initialHour)
  const [isMounted, setMounted] = useState(false)

  useOverflow()
  TemplateVariables
  const editorRef = useRef(null)

  const {
    type: actionType,
    fields,
    defaultValues,
    api,
  } = getActionParams(params)

  const initialValues = getInitialValues(params, fields, defaultValues)

  let initialGroups = []

  if (actionType === CREATE || actionType === EDIT) {
    if (id && initialValues.for_building_without_group) {
      initialGroups.push({ value: 0, label: t('WithoutGroup') })
    }

    initialGroups = initialGroups.concat(initialValues.building_groups)
  }

  const [selectedGroups, changeSelectedGroups, setSelectedGroups] =
    useSelected(initialGroups)
  const [groupCount, setGroupCount] = useState(null)

  const validationSchema = Object.assign(
    {},
    canEdit
      ? {
          name: Yup.string()
            .max(100, t('MaxNameLength'))
            .required(t('NameRequired')),
        }
      : undefined,
    actionType === CLONE || !canEdit
      ? undefined
      : {
          post_title: Yup.string().required(t('PostTitleRequired')),
          text: Yup.string()
            .transform(current => {
              if (current === '<p><br></p>') {
                return ''
              }

              return current
            })
            .required(t('TextRequired')),
          private: Yup.boolean(),
          hidden: Yup.boolean(),
          post_ttl: Yup.number(),
          post_type: Yup.string().required(),
          files: Yup.array().of(Yup.object()),
          building_groups: Yup.array().of(Yup.object()),
        }
  )

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object(validationSchema),
    onSubmit: values => {
      if (!canEdit && !canEditVisibility) {
        return
      }

      setLoading(true)

      let templateApi = api

      if (actionType !== CREATE) {
        templateApi = partial(api, id)
      }

      const files = map(values.files, 'id')

      let params = {
        ...omit(values, ['files']),
        files,
      }

      if (actionType !== CLONE) {
        params.building_groups = selectedGroups
          .map(g => g.value)
          .filter(Boolean)
        params.for_building_without_group = selectedGroups.some(
          g => g.value === 0
        )
      }

      if (!canEdit) {
        params = pick(params, 'hidden')
      } else if (!canEditVisibility) {
        params = omit(params, 'hidden')
      }

      templateApi(params).then(() => props.resetTemplates())
    },
  })

  const textClass = getFieldClass(!!formik.errors.text)

  const handleChange = value => formik.setFieldValue('text', value)

  const startFilesUpload = () => setLoading(true)

  const finishFilesUpload = uploadedfiles => {
    formik.setFieldValue('files', formik.values.files.concat(uploadedfiles))

    setLoading(false)
  }

  const handleRemoveFile = deletedFile =>
    formik.setFieldValue(
      'files',
      formik.values.files.filter(file => file.id !== deletedFile.id)
    )

  const modalProps = Object.assign(
    {},
    { isOpen, style: { content: { maxWidth: '932px' } } },
    loading ? { className: 'working-overlay' } : undefined,
    props.onClose ? { onRequestClose: props.onClose } : undefined
  )

  const handleChangeVisibility = e =>
    formik.setFieldValue('hidden', !e.currentTarget.checked)

  const dayOptions = [...Array(32).keys()].map(i => ({
    value: i,
    label: `${i > 9 ? '' : '0'}${i}`,
  }))

  const hourOptions = [...Array(24).keys()].map(i => ({
    value: i,
    label: `${i > 9 ? '' : '0'}${i}`,
  }))

  const getPostTtl = () => {
    let time = 0

    if (day) {
      time += day * 24 * 60 * 60
    }

    if (hour) {
      time += hour * 60 * 60
    }

    return time || 0
  }

  useEffect(() => {
    setMounted(true)
  }, [])

  useEffect(() => {
    if (isMounted) {
      formik.setFieldValue('post_ttl', getPostTtl())
    }
  }, [day, hour])

  const handleDay = option => setDay(option.value)
  const handleHour = option => setHour(option.value)

  const dayOption = dayOptions.find(option => option.value === day)
  const hourOption = hourOptions.find(option => option.value === hour)

  const handleAddVar = name => {
    if (editorRef && editorRef.current) {
      editorRef.current.insertContent(name)
    }
  }

  const handleInit = (e, editor) => {
    editorRef.current = editor

    editor.focus()
  }

  const handleRemoveGroups = () => {
    setSelectedGroups([])
  }

  const formClass =
    (actionType === CREATE || actionType === EDIT) && styles.form
  const groupsInputClass = classnames(
    'm-a10__cell',
    canEdit && selectedGroups?.length > 0 ? ' m-a10__cell_9' : 'm-a10__cell_7'
  )

  return (
    <Modal {...modalProps}>
      <ModalHead title={title} />
      <ModalCloseButton onClose={props.onClose} />
      <form className={formClass} onSubmit={formik.handleSubmit}>
        <ModalBody className={styles.body}>
          <ModalRow>
            <div className='m-a10__cell m-a10__cell_5'>
              <span className='m-a10__label'>{t('TemplateName')}</span>
            </div>
            <div className='m-a10__cell m-a10__cell_7'>
              <InputField
                disabled={!canEdit}
                name='name'
                placeholder={t('TemplateNamePlaceholder')}
                value={formik.values.name}
                error={formik.errors.name}
                onChange={formik.handleChange}
              />
            </div>
          </ModalRow>
          {(actionType === CREATE || actionType === EDIT) && (
            <>
              <ModalRow>
                <div className='m-a10__cell m-a10__cell_5'>
                  <span className='m-a10__label'>
                    {t('TemplateVisibility')}
                  </span>
                </div>
                <div className='m-a10__cell'>
                  <Checkbox
                    disabled={!canEditVisibility}
                    name='hidden'
                    checked={!formik.values.hidden}
                    text={t(formik.values.hidden ? 'Hidden' : 'Visible')}
                    onChange={handleChangeVisibility}
                  />
                </div>
              </ModalRow>
              <ModalRow>
                <div className='m-a10__cell m-a10__cell_5'>
                  <span className='m-a10__label'>{t('TemplateType')}</span>
                </div>
                <div className='m-a10__cell'>
                  <Checkbox
                    disabled={!canEdit}
                    name='private'
                    checked={formik.values.private}
                    text={t(formik.values.private ? 'Private' : 'Public')}
                    onChange={formik.handleChange}
                  />
                </div>
              </ModalRow>
              <ModalRow>
                <div className='m-a10__cell m-a10__cell_5'>
                  <span className='m-a10__label'>
                    {t('TemplateTimeInterval')}
                  </span>
                </div>
                <div className='m-a10__cell'>
                  {!day && !hour && (
                    <span className='m-a10__label'>
                      {t('TimeIntervalNotSelected')}
                    </span>
                  )}
                  {!!day && (
                    <span
                      className='m-a10__label'
                      style={{ display: 'inline', marginRight: '10px' }}
                    >
                      {t('DayInterval')}: {day}
                    </span>
                  )}
                  {!!hour && (
                    <span
                      className='m-a10__label'
                      style={{ display: 'inline', marginRight: '10px' }}
                    >
                      {t('HourInterval')}: {hour}
                    </span>
                  )}
                </div>
              </ModalRow>
              <ModalRow>
                <div className='m-a10__cell m-a10__cell_5'></div>
                <div
                  className='m-a10__cell m-a10__cell_7'
                  style={{ display: 'flex' }}
                >
                  <div className='m-a10__cell m-a10__cell_8'>
                    <NewSelectSimple
                      className={styles.select}
                      disabled={!canEdit}
                      options={dayOptions}
                      value={dayOption}
                      onChange={handleDay}
                    />
                  </div>
                  <div className='m-a10__cell m-a10__cell_8'>
                    <NewSelectSimple
                      className={styles.select}
                      disabled={!canEdit}
                      options={hourOptions}
                      value={hourOption}
                      clearable={false}
                      onChange={handleHour}
                    />
                  </div>
                </div>
              </ModalRow>
              <ModalRow>
                <div className='m-a10__cell m-a10__cell_5'>
                  <span className='m-a10__label'>
                    {t('Templates:BuildingGroup')}
                  </span>
                </div>
                <div className={groupsInputClass}>
                  <div style={{ display: 'flex', position: 'relative' }}>
                    <div style={{ width: '100%' }}>
                      <NewSelectAsync
                        isMulti
                        idField='value'
                        className={styles.groupSelect}
                        placeholder={t('Templates:BuildingGroupPlaceholder')}
                        disabled={!canEdit}
                        api={getBuildingGroupFilter}
                        permanentParams={{
                          ordering: 'name',
                        }}
                        pageSize={10}
                        view='dropdown'
                        searchKey='search'
                        initialItems={[
                          { value: 0, label: t('Templates:WithoutGroup') },
                        ]}
                        selectedItems={selectedGroups}
                        getLabel={g => g.label}
                        getSelectedLabel={g => g.label}
                        setSelectedItems={setSelectedGroups}
                        isAllSelected={selected =>
                          groupCount === selected.length
                        }
                        setMetaCount={setGroupCount}
                        preloadAll={actionType === CREATE}
                        onClick={changeSelectedGroups}
                      />
                    </div>
                    {!!selectedGroups.length && canEdit && (
                      <div className={styles.removeButton}>
                        <Button.Delete onClick={handleRemoveGroups} />
                      </div>
                    )}
                  </div>
                </div>
              </ModalRow>
              <ModalRow>
                <div className='m-a10__cell m-a10__cell_5'>
                  <span className='m-a10__label'>{t('TemplatePostTitle')}</span>
                </div>
                <div className='m-a10__cell m-a10__cell_7'>
                  <InputField
                    disabled={!canEdit}
                    name='post_title'
                    placeholder={t('TemplatePostTitlePlaceholder')}
                    value={formik.values.post_title}
                    error={formik.errors.post_title}
                    onChange={formik.handleChange}
                  />
                </div>
              </ModalRow>
              {canEdit && (
                <ModalRow>
                  <div className='m-a10__cell m-a10__cell_5'>
                    <span className='m-a10__label'>{t('Variables')}</span>
                  </div>
                  <div className='m-a10__cell m-a10__cell_7'>
                    <TemplateVariables
                      t={t}
                      api={getVariables}
                      tags={formik.values.tags}
                      handleSelect={handleAddVar}
                    />
                  </div>
                </ModalRow>
              )}
              <ModalRow>
                <div className='m-a10__cell m-a10__cell_5'>
                  <span className='m-a10__label'>
                    {t('TemplateDescription')}
                  </span>
                </div>
                <div className='m-a10__cell m-a10__cell_6 signature'>
                  <div className={textClass}>
                    <WysiwygEditor
                      maxHeight={200}
                      resize={false}
                      value={formik.values.text}
                      disabled={!canEdit}
                      placeholder={t('TextPlaceholder')}
                      onInit={handleInit}
                      onChange={handleChange}
                    />
                    {formik.errors.text && (
                      <span className='input__msg'>{formik.errors.text}</span>
                    )}
                  </div>
                </div>
              </ModalRow>
              <ModalRow>
                <div className='m-a10__cell m-a10__cell_5'></div>
                <div className='m-a10__cell m-a10__cell_6'>
                  <RequestFileList
                    showAddButton
                    files={formik.values.files}
                    onRemove={handleRemoveFile}
                    onUpload={finishFilesUpload}
                    onStartUpload={startFilesUpload}
                  />
                </div>
              </ModalRow>
            </>
          )}
        </ModalBody>
        <ModalButtons>
          <Button.Save
            type='submit'
            disabled={
              loading ||
              !formik.isValid ||
              (!formik.dirty && isEqual(selectedGroups, initialGroups))
            }
          >
            {buttonText}
          </Button.Save>
          <Button.Cancel disabled={loading} onClick={props.onClose}>
            {t('Cancel')}
          </Button.Cancel>
        </ModalButtons>
      </form>
    </Modal>
  )
}

export default PostTemplatePopup
