// @flow

import React, { useEffect, useRef, useState } from 'react'
import {
  difference,
  get,
  map,
  omit,
  partial,
  pick,
  union,
  isEqual,
} from 'lodash-es'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import type { Node } from 'react'

import Checkbox from '../../Checkbox'
import { getVariables } from '../../../core/api/api.template'
import { getEmailVariables } from '../../../core/api/api.emailTemplate'
import { getBuildingGroupFilter } from '../../../core/api/api.building'
import { useSelected } from '../../../hooks'
import Button from '../../Button'
import TemplateVariables from './TemplateVariables'
import { useOverflow } from '../../../hooks/useOverflow'
import Modal from '../../Modal'
import ModalBody from '../../Modal/ModalBody'
import ModalHead from '../../Modal/ModalHead'
import {
  CLONE,
  CREATE,
  EDIT,
  HTML_TEXT_TYPE,
  PLAIN_TEXT_TYPE,
} from './TemplatePopup.constants'
import {
  getActionParams,
  getFieldClass,
  getInitialValues,
  getQuillEmptyValue,
} from './TemplatePopup.utils'
import TagSelect from './TagSelect'
import ModalCloseButton from '../../Modal/ModalCloseButton'
import Textarea from '../../Textarea'
import InputField from '../../InputField'
import NewSelectSimple from '../../NewSelectSimple/NewSelectSimple'
import NewSelectAsync from '../../NewSelectAsync'
import RequestFileList from '../../Emails/RequestFileList'
import WysiwygEditor from '../../WysiwygEditor'
import ModalRow from '../../Modal/ModalRow'
import ModalButtons from '../../Modal/ModalButtons'
import type { Params } from '../Templates.types'

import styles from './TagSelect/TagSelect.module.scss'
import styles2 from './TemplatePopup.module.scss'
import classnames from 'classnames'

type Props = {
  isEmail: boolean,
  isOpen: boolean,
  onClose: () => void,
  params: Params,
  resetTemplates: () => void,
}

const TemplatePopup = (props: Props): Node => {
  const {
    params,
    params: { title, buttonText, id, permissions },
    isOpen,
    isEmail,
  } = props

  const canEdit = get(permissions, 'can_edit', true)
  const canEditVisibility = get(permissions, 'can_change_visibility', true)

  const { t } = useTranslation('Templates')

  const [loading, setLoading] = useState(false)
  const [inititated, setInitiated] = useState(false)

  useOverflow()

  const {
    type: actionType,
    fields,
    defaultValues,
    api,
  } = getActionParams(params, isEmail)

  const initialValues = getInitialValues(params, fields, defaultValues)
  let initialGroups = []

  if (actionType === CREATE || actionType === EDIT) {
    if (id && initialValues.for_building_without_group) {
      initialGroups.push({ value: 0, label: t('WithoutGroup') })
    }

    initialGroups = initialGroups.concat(initialValues.building_groups)
  }

  const [selectedGroups, changeSelectedGroups, setSelectedGroups] =
    useSelected(initialGroups)
  const [groupCount, setGroupCount] = useState(null)

  const validationSchema = Object.assign(
    {},
    canEdit
      ? {
          name: Yup.string()
            .max(100, t('MaxNameLength'))
            .required(t('NameRequired')),
        }
      : undefined,
    actionType === CLONE || !canEdit
      ? undefined
      : {
          text_type: Yup.string().required(),
          text: Yup.string().when('text_type', {
            is: HTML_TEXT_TYPE,
            then: s =>
              s.transform(getQuillEmptyValue).required(t('TextRequired')),
          }),
          simpleText: Yup.string().when('text_type', {
            is: PLAIN_TEXT_TYPE,
            then: s => s.required(t('TextRequired')),
          }),
          subject: Yup.string().max(255, t('MaxNameLength')),
          private: Yup.boolean().required(),
          hidden: Yup.boolean().required(),
          tags: Yup.array().of(Yup.string()),
          files: Yup.array().of(Yup.object()),
          building_groups: Yup.array().of(Yup.object()),
        }
  )

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object(validationSchema),
    onSubmit: values => {
      if (!canEdit && !canEditVisibility) {
        return
      }

      setLoading(true)

      let templateApi = api

      if (actionType !== CREATE) {
        templateApi = partial(api, id)
      }

      let text = values.text

      if (values.text_type === PLAIN_TEXT_TYPE) {
        text = values.simpleText
      }

      const files =
        values.text_type === PLAIN_TEXT_TYPE ? [] : map(values.files, 'id')

      let params = {
        ...omit(values, ['simpleText', 'files']),
        text,
        files,
      }

      if (isEmail) {
        params.subject = values.subject
      }

      if (!canEdit) {
        params = pick(params, 'hidden')
      } else if (!canEditVisibility) {
        params = omit(params, 'hidden')
      }

      if (actionType !== CLONE) {
        params.building_groups = selectedGroups
          .map(g => g.value)
          .filter(Boolean)
        params.for_building_without_group = selectedGroups.some(
          g => g.value === 0
        )
      }

      templateApi(params).then(() => props.resetTemplates())
    },
  })

  useEffect(() => {
    setInitiated(true)
  }, [])

  useEffect(() => {
    if (inititated) {
      formik.setFieldValue('tags', [])
    }
  }, [formik.values.text_type])

  const textClass = getFieldClass(
    !!(
      (formik.values.text_type === HTML_TEXT_TYPE && formik.errors.text) ||
      (formik.values.text_type === PLAIN_TEXT_TYPE && formik.errors.simpleText)
    )
  )

  const handleChange = value => formik.setFieldValue('text', value)
  const handleChangeTag = tag => {
    let tags = []

    const isIntersect = formik.values.tags.includes(tag)

    if (isIntersect) {
      tags = difference(formik.values.tags, [tag])
    } else {
      tags = union(formik.values.tags, [tag])
    }

    formik.setFieldValue('tags', tags)
  }

  const handleRemoveTag = () => formik.setFieldValue('tags', [])

  const handleSelectAll = tags => {
    formik.setFieldValue('tags', tags)
  }

  const startFilesUpload = () => setLoading(true)

  const finishFilesUpload = uploadedfiles => {
    formik.setFieldValue('files', formik.values.files.concat(uploadedfiles))

    setLoading(false)
  }

  const textOptions = [
    { value: HTML_TEXT_TYPE, label: t('HtmlText') },
    { value: PLAIN_TEXT_TYPE, label: t('PlainText') },
  ]

  const textValue = textOptions.find(
    option => option.value === formik.values.text_type
  )

  const handleTextTypeChange = option =>
    formik.setFieldValue('text_type', option.value)

  const handleChangeSimpleText = e =>
    formik.setFieldValue('simpleText', e.target.value)

  const handleAddVar = name => {
    if (formik.values.text_type === PLAIN_TEXT_TYPE) {
      const simpleText = formik.values.simpleText
      const cursorStartPosition = get(textareaRef, [
        'current',
        'selectionStart',
      ])
      const startText = simpleText.substr(0, cursorStartPosition)
      const endText = simpleText.substr(cursorStartPosition, simpleText.length)

      formik.setFieldValue('simpleText', startText + name + endText)
    } else {
      if (editorRef && editorRef.current) {
        editorRef.current.insertContent(name)
      }
    }
  }

  const handleRemoveFile = deletedFile =>
    formik.setFieldValue(
      'files',
      formik.values.files.filter(file => file.id !== deletedFile.id)
    )

  const modalProps = Object.assign(
    {},
    { isOpen, style: { content: { maxWidth: '932px' } } },
    loading ? { className: 'working-overlay' } : undefined,
    props.onClose ? { onRequestClose: props.onClose } : undefined
  )

  const handleChangeVisibility = e =>
    formik.setFieldValue('hidden', !e.currentTarget.checked)

  const textareaRef = useRef()
  const editorRef = useRef()

  const handleInit = (e, editor) => {
    editorRef.current = editor

    editor.focus()
  }

  const handleRemoveGroups = () => {
    setSelectedGroups([])
  }

  const formClass =
    (actionType === CREATE || actionType === EDIT) && styles2.form

  const tagsInputClass = classnames(
    'm-a10__cell',
    canEdit && formik.values.tags?.length > 0
      ? ' m-a10__cell_9'
      : 'm-a10__cell_7'
  )

  const groupsInputClass = classnames(
    'm-a10__cell',
    canEdit && selectedGroups?.length > 0 ? ' m-a10__cell_9' : 'm-a10__cell_7'
  )

  return (
    <Modal {...modalProps}>
      <ModalHead title={title} />
      <ModalCloseButton onClose={props.onClose} />
      <form className={formClass} onSubmit={formik.handleSubmit}>
        <ModalBody className={styles2.body}>
          <ModalRow>
            <div className='m-a10__cell m-a10__cell_5'>
              <span className='m-a10__label'>{t('TemplateName')}</span>
            </div>
            <div className='m-a10__cell m-a10__cell_7'>
              <InputField
                disabled={!canEdit}
                name='name'
                placeholder={t('TemplateNamePlaceholder')}
                value={formik.values.name}
                error={formik.errors.name}
                onChange={formik.handleChange}
              />
            </div>
          </ModalRow>
          {(actionType === CREATE || actionType === EDIT) && (
            <>
              <ModalRow>
                <div className='m-a10__cell m-a10__cell_5'>
                  <span className='m-a10__label'>
                    {t('TemplateVisibility')}
                  </span>
                </div>
                <div className='m-a10__cell'>
                  <Checkbox
                    disabled={!canEditVisibility}
                    name='hidden'
                    checked={!formik.values.hidden}
                    text={t(formik.values.hidden ? 'Hidden' : 'Visible')}
                    onChange={handleChangeVisibility}
                  />
                </div>
              </ModalRow>
              <ModalRow>
                <div className='m-a10__cell m-a10__cell_5'>
                  <span className='m-a10__label'>{t('TemplateType')}</span>
                </div>
                <div className='m-a10__cell'>
                  <Checkbox
                    disabled={!canEdit}
                    name='private'
                    checked={formik.values.private}
                    text={t(formik.values.private ? 'Private' : 'Public')}
                    onChange={formik.handleChange}
                  />
                </div>
              </ModalRow>
              {!isEmail && (
                <ModalRow>
                  <div className='m-a10__cell m-a10__cell_5'>
                    <span className='m-a10__label'>{t('TextType')}</span>
                  </div>
                  <div className='m-a10__cell m-a10__cell_7'>
                    <NewSelectSimple
                      className={styles.select}
                      openClass={styles.open}
                      disabled={!canEdit}
                      options={textOptions}
                      value={textValue}
                      onChange={handleTextTypeChange}
                    />
                  </div>
                </ModalRow>
              )}
              <ModalRow>
                <div className='m-a10__cell m-a10__cell_5'>
                  <span className='m-a10__label'>{t('BuildingGroup')}</span>
                </div>
                <div className={groupsInputClass}>
                  <div style={{ display: 'flex', position: 'relative' }}>
                    <div style={{ width: '100%' }}>
                      <NewSelectAsync
                        isMulti
                        idField='value'
                        className={styles.groupSelect}
                        placeholder={t('BuildingGroupPlaceholder')}
                        disabled={!canEdit}
                        api={getBuildingGroupFilter}
                        permanentParams={{
                          ordering: 'name',
                        }}
                        pageSize={10}
                        view='dropdown'
                        searchKey='search'
                        initialItems={[{ value: 0, label: t('WithoutGroup') }]}
                        selectedItems={selectedGroups}
                        getLabel={g => g.label}
                        getSelectedLabel={g => g.label}
                        setSelectedItems={setSelectedGroups}
                        isAllSelected={selected =>
                          groupCount === selected.length
                        }
                        setMetaCount={setGroupCount}
                        preloadAll={actionType === CREATE}
                        onClick={changeSelectedGroups}
                      />
                    </div>
                    {!!selectedGroups.length && canEdit && (
                      <div className={styles.removeButton}>
                        <Button.Delete onClick={handleRemoveGroups} />
                      </div>
                    )}
                  </div>
                </div>
              </ModalRow>
              <ModalRow>
                <div className='m-a10__cell m-a10__cell_5'>
                  <span className='m-a10__label'>{t('TemplateTag')}</span>
                </div>
                <div className={tagsInputClass}>
                  <div style={{ display: 'flex', position: 'relative' }}>
                    <div style={{ width: '100%' }}>
                      <TagSelect
                        disabled={!canEdit}
                        isEmail={isEmail}
                        textType={formik.values.text_type}
                        selectedTags={formik.values.tags}
                        buttonText={t('SetTag')}
                        handleChangeTag={handleChangeTag}
                        actionType={actionType}
                        onSelectAll={handleSelectAll}
                      />
                    </div>
                    {!!formik.values.tags.length && canEdit && (
                      <div className={styles.removeButton}>
                        <Button.Delete onClick={handleRemoveTag} />
                      </div>
                    )}
                  </div>
                </div>
              </ModalRow>
              {isEmail && (
                <ModalRow>
                  <div className='m-a10__cell m-a10__cell_5'>
                    <span className='m-a10__label'>{t('TemplateSubject')}</span>
                  </div>
                  <div className='m-a10__cell m-a10__cell_7'>
                    <InputField
                      disabled={!canEdit}
                      name='subject'
                      placeholder={t('TemplateSubjectPlaceholder')}
                      value={formik.values.subject}
                      error={formik.errors.subject}
                      onChange={formik.handleChange}
                    />
                  </div>
                </ModalRow>
              )}
              {canEdit && (
                <ModalRow>
                  <div className='m-a10__cell m-a10__cell_5'>
                    <span className='m-a10__label'>{t('Variables')}</span>
                  </div>
                  <div className='m-a10__cell m-a10__cell_7'>
                    <TemplateVariables
                      api={isEmail ? getEmailVariables : getVariables}
                      t={t}
                      tags={formik.values.tags}
                      handleSelect={handleAddVar}
                    />
                  </div>
                </ModalRow>
              )}
              <ModalRow>
                <div className='m-a10__cell m-a10__cell_5'>
                  <span className='m-a10__label'>
                    {t('TemplateDescription')}
                  </span>
                </div>
                <div className='m-a10__cell m-a10__cell_6 signature'>
                  <div className={textClass}>
                    {formik.values.text_type === PLAIN_TEXT_TYPE ? (
                      <>
                        <Textarea
                          disabled={!canEdit}
                          name='simpleText'
                          placeholder={t('TextPlaceholder')}
                          value={formik.values.simpleText}
                          error={formik.errors.simpleText}
                          textareaRef={textareaRef}
                          onChange={handleChangeSimpleText}
                        />
                        {formik.errors.simpleText && (
                          <span className='input__msg'>
                            {formik.errors.simpleText}
                          </span>
                        )}
                      </>
                    ) : (
                      <>
                        <WysiwygEditor
                          autoResizeOnInit
                          value={formik.values.text}
                          disabled={!canEdit}
                          placeholder={t('TextPlaceholder')}
                          onInit={handleInit}
                          onChange={handleChange}
                        />
                        {formik.errors.text && (
                          <span className='input__msg'>
                            {formik.errors.text}
                          </span>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </ModalRow>
              {formik.values.text_type === HTML_TEXT_TYPE && (
                <ModalRow>
                  <div className='m-a10__cell m-a10__cell_5'></div>
                  <div className='m-a10__cell m-a10__cell_6'>
                    <RequestFileList
                      showAddButton
                      files={formik.values.files}
                      onRemove={handleRemoveFile}
                      onUpload={finishFilesUpload}
                      onStartUpload={startFilesUpload}
                    />
                  </div>
                </ModalRow>
              )}
            </>
          )}
        </ModalBody>
        <ModalButtons>
          <Button.Save
            type='submit'
            disabled={
              loading ||
              !formik.isValid ||
              (!formik.dirty && isEqual(selectedGroups, initialGroups))
            }
          >
            {buttonText}
          </Button.Save>
          <Button.Cancel disabled={loading} onClick={props.onClose}>
            {t('Cancel')}
          </Button.Cancel>
        </ModalButtons>
      </form>
    </Modal>
  )
}

export default TemplatePopup
