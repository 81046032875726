// @flow

import { has } from 'lodash-es'

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex - 1, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export const move = (
  source,
  destination,
  droppableSource,
  droppableDestination
) => {
  const sourceClone = Array.from(source)
  const destClone = Array.from(destination)
  const [removed] = sourceClone.splice(droppableSource.index - 1, 1)

  destClone.splice(droppableDestination.index, 0, removed)

  const result = {}
  result[droppableSource.droppableId] = sourceClone
  result[droppableDestination.droppableId] = destClone

  return result
}

export const getNewGroups = (source, destination, groups) => {
  if (source.droppableId === destination.droppableId) {
    const droppableId = Number.parseInt(source.droppableId)
    const group = groups.find(g => g.id === droppableId)
    const questions = reorder(group.questions, source.index, destination.index)

    return groups.map(g => {
      if (g.id === droppableId) {
        return { ...g, questions }
      }

      return g
    })
  } else {
    const sourceDroppableId = Number.parseInt(source.droppableId)
    const destinationDroppableId = Number.parseInt(destination.droppableId)
    const sourceGroup = groups.find(g => g.id === sourceDroppableId)
    const destinationGroup = groups.find(g => g.id === destinationDroppableId)
    const result = move(
      sourceGroup.questions,
      destinationGroup.questions,
      source,
      destination
    )

    return groups.map(g => {
      const id = g.id.toString()

      if (has(result, id)) {
        return {
          ...g,
          questions: result[id],
        }
      }

      return g
    })
  }
}
