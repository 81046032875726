// @flow

import React, { PureComponent, createRef } from 'react'
import { push } from 'connected-react-router'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroller'
import { mapKeys, get } from 'lodash-es'
import { compose } from 'redux'
import moment from 'moment'
import classnames from 'classnames'
import type { Node } from 'react'

import Button from '../../components/Button'
import ChatroomHeader from '../../components/Chatroom/ChatroomHeader'
import ChatroomInfo from '../../components/Chatroom/ChatroomInfo'
import ChatroomMessage from '../../components/Chatroom/ChatroomMessage/ChatroomMessage'
import AttachToRequestPopup from '../../components/modals/AttachToRequestPopup'
import ConvertToRequestPopup from '../../components/modals/ConvertToRequestPopup'
import NewWizard from '../../components/NewWizard'
import EmptyList from '../../components/EmptyList'
import Loader from '../../components/Loader'
import Modal from '../../components/Modal'
import SendMessageForm from '../../components/SendMessageForm'
import SystemMessage from '../../components/Chatroom/SystemMessage'
import UserPopup from '../../components/modals/UserPopup/UserPopup'
import ConfirmationPopup from '../../components/modals/ConfirmationPopup'
import { getUrlForAvatar, isDeLocale } from '../../utils/utils'
import BrowserStorage from '../../utils/browserStorage'
import {
  initiate,
  reset,
  getNewMessagesInitiating,
  getOldMessagesInitiating,
  addMembersInitiating,
  removeMembersInitiating,
  create,
  sendMessageInitiating,
  renameChatroomInitiating,
  likeMessageInitiating,
  hideModal,
  updateLabelsInit,
  toggleChatroomMessage,
  deleteFiles,
  deleteMessage,
  updateMessages,
} from './Chatroom.actions'
import {
  MESSAGES_LIMIT_PER_REQUEST,
  POLLING_PERIOD,
  STICKY_DISTANCE,
  AUTOSCROLL_TO_LATEST_MAX_DISTANCE,
} from './Chatroom.constants'
import * as Utils from './Chatroom.utils'
import { getUser, isWizardEnabled } from '../../utils/commonSelectors'

import styles from './Chatroom.module.scss'
import Lightbox from '../../components/LightboxGallery'

type Props = {
  addMembersInitiating: (string, Array<number>) => void,
  allPreviousAreLoaded: boolean,
  buildingId?: number,
  chatroomUuid: string,
  create: Object => void,
  creating: boolean,
  getNewMessagesInitiating: (string, number, string) => void,
  getOldMessagesInitiating: Object => void,
  hideModal: () => void,
  initiate: Object => void,
  initiated: boolean,
  isAddMembersPopUpOpen: boolean, // TODO get rid off, key 'match' is too weird
  isConvertToRequestPopUpOpen: boolean,
  isEmbedded?: boolean,
  isLabelsPopUpOpen: boolean,
  likeMessageInitiating: (string, boolean, boolean, string) => void, // TODO divide creating and view to different components
  loadingLatest: boolean,
  loadingPrevious: boolean,
  match: Object,
  members: Array<Object>,
  messages: Array<Object>,
  onRename: (string, string) => void,
  outbound?: string,
  push: string => void,
  readOnly?: boolean,
  removeMembersInitiating: (string, Array<number>) => void,
  requestId?: string,
  reset: () => void,
  sendingMessage: boolean,
  // TODO toggle???
  sendMessageInitiating: Object => void,
  t: string => string,
  templateParams?: Object,
  toggleChatroomMessage: string => void,
  updateLabelsInit: (string, Array<Object>) => void,
  user: Object,
  uuid: string,
}
type State = {
  files: Array<Object>,
  frozenLoadingPrevious: boolean,
  headerWidth: ?string,
  isCreated: boolean,
  isMinimized: boolean,
  lastShouldStick: boolean,
  messageText: string,
  modal: ?Node,
  newChatroomMembers: Array<Object>,
  previousNextPage: number,
  previousStartUuid: ?string,
  scrollHeight: number,
}

class Chatroom extends PureComponent<Props, State> {
  static defaultProps = {
    scope: 'chat',
  }

  state = {
    files: [],
    messageText: '',
    count: 0,
    newChatroomMembers: [
      {
        user: this.props.user.owner,
        name: this.props.user.name,
        second_name: this.props.user.second_name,
        surname: this.props.user.surname,
        avatar:
          this.props.user.avatar_obj !== null
            ? this.props.user.avatar_obj.preview
            : '',
      },
    ],
    newChatroomTitle: this.props.t('WriteAMessage'),
    isMinimized: false,
    modal: null,
    lastShouldStick: false,
    frozenLoadingPrevious: true,
    previousNextPage: 1,
    previousStartUuid: null,
    scrollHeight: 0,
    isCreated: false,
    headerWidth: null,
    chatHeight: 200,
    isExpanded: false,
    activeIndex: null,
  }

  scrollBox = null

  loadingLatestInterval = null

  loadingAllInterval = null

  messageBox = createRef()

  componentDidMount() {
    const uuid = this.getUuid()

    if (uuid !== 'create' && !this.props.isEmbedded) {
      const key = this.getMinimizedKey(uuid)
      const minimized = JSON.parse(BrowserStorage.get(key))

      this.setState({
        isMinimized: !!minimized,
        chatHeight: minimized
          ? window.innerHeight * 0.6
          : window.innerHeight * 0.3,
      })

      if (minimized === null) {
        BrowserStorage.set(key, true)
      }
    }

    const { requestId, outbound } = this.props

    this.props.initiate({
      uuid: this.getUuid(),
      limit: MESSAGES_LIMIT_PER_REQUEST,
      request: requestId,
      outbound,
    })
  }

  componentDidUpdate(prev: Props, prevState) {
    const {
      isEmbedded,
      initiated,
      messages,
      loadingLatest,
      loadingPrevious,
      allPreviousAreLoaded,
      sendingMessage,
      creating,
      modal,
      outbound,
    } = this.props

    const { isMinimized, isExpanded } = this.state

    if (isMinimized !== prevState.isMinimized) {
      this.setState({
        isExpanded: false,
        chatHeight: isMinimized
          ? window.innerHeight * 0.6
          : window.innerHeight * 0.3,
      })
    }

    if (isExpanded !== prevState.isExpanded) {
      let height = 200

      if (isEmbedded) {
        if (isExpanded) {
          height = window.innerHeight - 165
        }
      } else {
        if (isExpanded) {
          height = window.innerHeight - 165 - 62
        } else {
          if (isMinimized) {
            height = window.innerHeight * 0.6
          } else {
            height = window.innerHeight * 0.3
          }
        }
      }

      this.setState({ chatHeight: height }, () =>
        this.messageBox?.current?.scrollIntoView()
      )
    }

    if (prev.creating && !this.props.creating) {
      if (!prev.isEmbedded) {
        this.props.push(`/chatroom/${this.props.uuid}`)
      }

      this.props.initiate({
        uuid: this.props.uuid,
        limit: MESSAGES_LIMIT_PER_REQUEST,
        outbound,
      })
    }

    // Modals opening on redux actions dispatched from widget
    switch (true) {
      case !prev.isAddMembersPopUpOpen && this.props.isAddMembersPopUpOpen:
        this.showAddMembersModal()
        break
      case !prev.isConvertToRequestPopUpOpen &&
        this.props.isConvertToRequestPopUpOpen:
        this.showConvertToRequestModal()
        break
      default:
    }

    if (prev.modal !== modal) {
      this.setState({ modal })
    }

    if (!prev.initiated && initiated) {
      this.loadingLatestInterval = setInterval(
        this.getNewMessages,
        POLLING_PERIOD
      )

      if (!isEmbedded) {
        window.addEventListener('scroll', this.handleScroll)
      }

      if (!allPreviousAreLoaded) {
        this.setState({
          previousStartUuid: messages[0].uuid,
          frozenLoadingPrevious: false,
        })
      }

      this.scrollToLatest(false)

      return
    }

    if (
      (!sendingMessage && prev.sendingMessage) ||
      (!creating && prev.creating)
    ) {
      this.setState({ files: [] })
    }

    if (messages.length > prev.messages.length) {
      const latestWereLoaded =
        (!loadingLatest && prev.loadingLatest) ||
        (!sendingMessage && prev.sendingMessage)

      if (latestWereLoaded) {
        this.scrollToLatest()
      } else {
        this.scrollToPrevious()
      }
    }

    if (prev.loadingPrevious && !loadingPrevious && !allPreviousAreLoaded) {
      this.setState(state => ({
        previousNextPage: state.previousNextPage + 1,
        frozenLoadingPrevious: false,
      }))
    }
  }

  componentWillUnmount() {
    const { isEmbedded } = this.props

    this.props.reset()

    if (this.loadingLatestInterval) {
      clearInterval(this.loadingLatestInterval)
    }

    if (this.loadingAllInterval) {
      clearInterval(this.loadingAllInterval)
    }

    window.removeEventListener('scroll', this.handleChatScroll)

    if (!isEmbedded) {
      window.removeEventListener('scroll', this.handleScroll)
    }
  }

  getMinimizedKey = uuid => `chatroom-${uuid}-minimized`

  getUuid = () => {
    const { isEmbedded, chatroomUuid, match: { params } = {} } = this.props

    return isEmbedded ? chatroomUuid : params.chatroomUuid
  }

  clearCreatedStatus = () => {
    this.setState({ isCreated: false })
  }

  getTitle = members =>
    this.isCreating()
      ? this.props.t('WriteAMessage')
      : Utils.getTitle(
          members.filter(m => m.active),
          this.props.t
        )

  getNewMessages = () => {
    const { messages, uuid, outbound } = this.props
    const { count } = this.state

    if (messages.length > 0 && !this.isCreating()) {
      if (count < 3) {
        const lastMessageUuid = messages[messages.length - 1].uuid

        this.props.getNewMessagesInitiating(
          uuid,
          MESSAGES_LIMIT_PER_REQUEST,
          lastMessageUuid,
          outbound
        )

        this.setState({ count: count + 1 })
      } else {
        const messageUuid = messages[0].uuid

        this.props.updateMessages(uuid, messages.length, messageUuid)

        this.setState({ count: 0 })
      }
    }
  }

  setMessagesScrollBox = ref => {
    this.scrollBox = ref

    if (this.scrollBox) {
      this.scrollBox.addEventListener('scroll', this.handleChatScroll)
    }
  }

  isCreating = () => this.props.uuid === 'create'

  isSendDisabled = () => {
    const { loadingPrevious, sendingMessage, creating } = this.props

    if (loadingPrevious || sendingMessage || creating) {
      return true
    }

    const { messageText } = this.state
    const messageRegex = /(<([^>]+)>)/gi

    return messageText.replace(messageRegex, '').trim() === ''
  }

  handleChatScroll = () => {
    if (!this.scrollBox) {
      return
    }

    this.setState({ scrollHeight: this.scrollBox.scrollHeight })
  }

  scrollToPrevious = () => {
    if (!this.scrollBox) {
      return
    }

    const { scrollHeight } = this.state
    this.scrollBox.scrollTop = this.scrollBox.scrollHeight - scrollHeight
  }

  scrollToLatest = (checkHeight = true) => {
    if (!this.scrollBox) {
      return
    }

    const { scrollHeight: height, scrollTop: top } = this.scrollBox

    if (!checkHeight || height - top < AUTOSCROLL_TO_LATEST_MAX_DISTANCE) {
      this.scrollBox.scrollTop = height
    }
  }

  loadMore = () => {
    const { uuid, outbound } = this.props
    const { previousNextPage, previousStartUuid, frozenLoadingPrevious } =
      this.state

    if (!frozenLoadingPrevious) {
      this.props.getOldMessagesInitiating({
        uuid,
        limit: MESSAGES_LIMIT_PER_REQUEST,
        page: previousNextPage,
        messageUuid: previousStartUuid,
        outbound,
      })
      this.setState({ frozenLoadingPrevious: true })
    }
  }

  handleScroll = () => {
    // TODO Move sticky ability to standalone component
    const { lastShouldStick } = this.state
    const shouldStick = this.shouldStick()

    if (shouldStick !== lastShouldStick) {
      this.toggleStick(shouldStick)
    }
  }

  toggleStick = shouldStick => {
    const headerClass = classnames('messages unit unit--default', {
      // 'messages--sticky': shouldStick,
    })
    const bodyClass = classnames('messages unit unit--default', {
      // 'messages--margin-top': shouldStick,
    })
    const headerWidth = shouldStick ? `${this.header.offsetWidth}px` : null

    this.setState({ headerWidth, lastShouldStick: shouldStick })
    this.header.className = headerClass
    this.body.className = bodyClass
  }

  shouldStick = () => {
    const { isMinimized } = this.state

    return window.pageYOffset > STICKY_DISTANCE && isMinimized
  }

  hidePopUp = () => {
    this.setState({ modal: null })
    this.props.hideModal()
  }

  handleLike = (messageUuid, isLike, isRemove) => {
    this.props.likeMessageInitiating(
      messageUuid,
      isLike,
      isRemove,
      this.props.outbound
    )
  }

  updateMembers = profiles => {
    const newMembers = profiles.map(profile =>
      mapKeys(profile, (value, key) => (key === 'owner' ? 'user' : key))
    )
    const { members, uuid } = this.props

    if (this.isCreating()) {
      // In this case there's no need in XHR-requests
      this.setState({ newChatroomMembers: newMembers })
      this.setState({ newChatroomTitle: this.getTitle(newMembers) })
    } else {
      const newMemberIds = newMembers.map(member => member.user)
      const activeMemberIds = members
        .filter(m => m.active)
        .map(p => p.user || p.owner)
      const removedIds = []
      const addedIds = []
      activeMemberIds.forEach(id => {
        if (newMemberIds.indexOf(id) === -1) {
          removedIds.push(id)
        }
      })
      newMemberIds.forEach(id => {
        if (activeMemberIds.indexOf(id) === -1) {
          addedIds.push(id)
        }
      })

      if (addedIds.length) {
        this.props.addMembersInitiating(uuid, addedIds)
      }

      if (removedIds.length) {
        this.props.removeMembersInitiating(uuid, removedIds)
      }
    }
  }

  toggleHeader = () => {
    const { uuid } = this.props
    const isMinimized = !this.state.isMinimized

    if (!isMinimized) {
      this.toggleStick(false)
    }

    const update = () => {
      if (uuid !== 'create') {
        BrowserStorage.set(this.getMinimizedKey(uuid), isMinimized)
      }
    }

    this.setState(
      prevState => ({ isMinimized: !prevState.isMinimized }),
      update
    )
  }

  formatMessageDelimiterDate(date) {
    const now = moment()
    const then = moment(date)

    if (now.isSame(then, 'day')) {
      return this.props.t('Today')
    } else if (now.subtract(1, 'days').isSame(then, 'day')) {
      return this.props.t('Yesterday')
    } else if (then.isSame(now, 'year')) {
      return then.format('D MMMM')
    }

    return then.format('LL')
  }

  renameChatroom = newTitle => {
    const { uuid } = this.props

    this.props.renameChatroomInitiating(uuid, newTitle)
    this.hidePopUp()
  }

  saveLabels = labels => {
    const { uuid } = this.props
    this.props.updateLabelsInit(uuid, labels)
  }

  showAddMembersModal = () => {
    this.setState({ modal: this.renderAddMembersModal() })
  }

  showModal = modal => {
    this.setState({ modal })
  }

  showConvertToRequestModal = () => {
    this.setState({ modal: this.renderConvertToRequestModal() })
  }

  updateFiles = (files: Array<Object>) => {
    this.setState({ files })
  }

  setActiveIndex = activeIndex => this.setState({ activeIndex })

  closeLightbox = () => this.setState({ activeIndex: null })
  handleChangeQuill = html => {
    this.setState({ messageText: html })
  }

  addDelimitersToMessages = messages => {
    let messagesWithDayDelimiters = []

    messages.reduce((acc, currVal, currInd, arr) => {
      // Also put flags to messages to group them by the author
      if (
        currVal &&
        arr[currInd - 1] &&
        currVal.owner === arr[currInd - 1].owner
      ) {
        currVal.groupedView = true // eslint-disable-line
      }

      const currDate = moment(currVal.created)
      const prevDate = moment(acc.created)
      // I think that this key prop should work pretty well because it is
      // unlikely that it will match with another key.
      const delimiter = (
        <div className='messages__date' key={`${currVal.uuid}1`}>
          <span className='messages__date-badge'>
            {this.formatMessageDelimiterDate(currDate)}
          </span>
        </div>
      )

      const { loadingPrevious } = this.props
      const beginning = (
        <div className='messages__date-beginning' key={`${currVal.uuid}2`}>
          {loadingPrevious ? (
            <Loader />
          ) : (
            <span>{this.props.t('ChatBeginningLabel')}</span>
          )}
        </div>
      )

      if (currInd === 0) {
        messagesWithDayDelimiters.push(beginning)
        messagesWithDayDelimiters.push(delimiter)
        messagesWithDayDelimiters.push(arr[currInd])
      } else if (!currDate.isSame(prevDate, 'day')) {
        messagesWithDayDelimiters.push(delimiter)
        messagesWithDayDelimiters.push(arr[currInd])
      } else {
        messagesWithDayDelimiters.push(arr[currInd])
      }

      return currVal
    }, {})

    return messagesWithDayDelimiters
  }

  toggleMessage = msgUuid => () => {
    this.props.toggleChatroomMessage(msgUuid)
  }

  sendMessage = () => {
    const {
      messages,
      isEmbedded,
      isPublic,
      buildingId,
      flatId,
      requestId,
      postUuid,
      user,
      uuid,
      feedback,
      outbound,
    } = this.props

    const files = this.state.files.map(f => f.id)
    const text = this.state.messageText
      .replace(/&nbsp;/g, ' ')
      .replace(/<br>/g, '\n')

    if (text.length === 0 && files.length === 0) {
      return // Send message if it has some text or attachments
    }

    const params = { text, requestId, outbound }

    if (this.isCreating()) {
      this.setState({ isCreated: true })

      const { newChatroomMembers: members } = this.state

      if (members.length === 1 && !isEmbedded) {
        // TODO maybe show message about members?
        return // Chatroom must have at least 2 members
      }

      if (isEmbedded) {
        this.props.create({
          ...params,
          feedback,
          files,
          isPublic: !!isPublic,
          request: feedback ? undefined : requestId,
          post: postUuid,
          building: buildingId,
          flat: flatId,
        })
      } else {
        this.props.create({
          ...params,
          files,
          users: members.map(m => m.user),
        })
      }

      this.setState({ messageText: '' })

      return
    }

    const membersIds = this.props.members.map(({ id }) => id)
    const newMember = !membersIds.some(id => id === user.owner)
    const messageUuid =
      messages && messages.length && messages[messages.length - 1].uuid // first message in current chat hist
    this.props.sendMessageInitiating({
      ...params,
      fileIds: files,
      uuid,
      limit: MESSAGES_LIMIT_PER_REQUEST,
      messageUuid,
      newMemberToSet: newMember ? user : null,
    })
    this.setState({ messageText: '' })
  }

  renderContent = () => {
    const { messages } = this.props

    if (this.isCreating() || !messages.length) {
      return (
        <EmptyList
          embedded
          icon='chat'
          title={this.props.t('ThereAreNoMessagesYet')}
        />
      )
    }

    return this.renderMessages()
  }

  renderAddMembersModal = () => {
    const { t, members, messages, user } = this.props
    const realMembers = members.filter(m => m.active)

    return (
      <UserPopup
        addChatMembers
        isActive
        profileCheck
        emailCheck
        params={{ group: ['dweller', 'admin', 'manager'] }}
        title={t('ChangeParticipants')}
        inputLabel={t('InviteParticipantsByNameOrEmail')}
        inputPlaceholder={t('InviteParticipantPlaceholder')}
        data={realMembers}
        chatOwner={messages[0].owner !== user.owner ? messages[0].owner : null}
        onClose={this.hidePopUp}
        onSave={({ users }) => this.updateMembers(users)}
      />
    )
  }

  showDeleteFilesPopup = (uuid, fileIds, id) => {
    const modal = (
      <ConfirmationPopup
        confirm={this.props.t('DeleteFile')}
        cancel={this.props.t('Cancel')}
        onClose={this.hidePopUp}
        onOk={this.deleteFiles(uuid, fileIds, id)}
      />
    )

    this.setState({ modal })
  }

  showDeleteMessagePopup = (uuid, deletingFileIds) => {
    const modal = (
      <ConfirmationPopup
        confirm={this.props.t('DeleteMessage')}
        cancel={this.props.t('Cancel')}
        onClose={this.hidePopUp}
        onOk={this.deleteMessage(uuid, deletingFileIds)}
      />
    )

    this.setState({ modal })
  }

  showConvertToRequestPopup = (uuid, isChatroomPublic) => {
    const modal = (
      <ConvertToRequestPopup
        chatMessageId={uuid}
        isChatroomPublic={isChatroomPublic}
        onClose={this.hidePopUp}
      />
    )

    this.setState({ modal })
  }

  showWizardPopup = (uuid, isChatroomPublic) => {
    const modal = (
      <NewWizard
        chatMessageId={uuid}
        isChatroomPublic={isChatroomPublic}
        onClose={this.hidePopUp}
      />
    )
    this.setState({ modal })
  }

  showAttachToRequestPopup = (uuid, isChatroomPublic) => {
    const modal = (
      <AttachToRequestPopup
        chatMessageId={uuid}
        isChatroomPublic={isChatroomPublic}
        onClose={this.hidePopUp}
      />
    )

    this.setState({ modal })
  }

  deleteFiles = (editingMessageUuid, fileIds, id) => () => {
    const { uuid: chatroomUuid, outbound } = this.props

    this.props.deleteFiles(
      chatroomUuid,
      editingMessageUuid,
      fileIds,
      MESSAGES_LIMIT_PER_REQUEST,
      id,
      outbound
    )

    this.hidePopUp()
  }

  deleteMessage = (deletingMessageUuid, deletingFileIds) => () => {
    const { uuid: chatroomUuid, outbound } = this.props

    this.props.deleteMessage(
      chatroomUuid,
      deletingMessageUuid,
      MESSAGES_LIMIT_PER_REQUEST,
      deletingFileIds,
      outbound
    )

    this.hidePopUp()
  }

  handleMaximize = () => {
    this.setState({ isExpanded: true })
  }

  handleMinimize = () => {
    this.setState({ isExpanded: false })
  }

  renderConvertToRequestModal = () => (
    <ConvertToRequestPopup chatId={this.props.uuid} onClose={this.hidePopUp} />
  )

  renderDelimiter = message => {
    const {
      uuid,
      created,
      owner,
      text,
      is_system: isSystem,
      groupedView,
    } = message

    if (created) {
      if (isSystem) {
        return <SystemMessage key={uuid} text={text} created={created} />
      }

      const {
        members,
        openedMessages,
        user,
        scope,
        isPublic,
        isWizardEnabled,
        object,
        outbound,
      } = this.props
      const messageOpened = openedMessages.includes(uuid)

      return (
        <ChatroomMessage
          messageOpened={messageOpened}
          isMyMessage={owner === user.owner}
          isPublic={isPublic}
          owner={owner}
          message={message}
          members={members}
          toggleMessage={this.toggleMessage}
          key={uuid}
          scope={scope}
          object={object}
          outbound={outbound}
          groupedView={groupedView}
          showConvertToRequestPopup={
            isWizardEnabled
              ? this.showWizardPopup
              : this.showConvertToRequestPopup
          }
          showDeleteFilesPopup={this.showDeleteFilesPopup}
          showDeleteMessagePopup={this.showDeleteMessagePopup}
          showAttachToRequestPopup={this.showAttachToRequestPopup}
          onLike={this.handleLike}
        />
      )
    }

    return message
  }

  isMinimized = () => {
    const getRect = get(this.messageBox, ['current', 'getBoundingClientRect'])

    if (getRect) {
      const height = this.messageBox.current.getBoundingClientRect().height

      if (height === 200) {
        return true
      }
    }

    return false
  }

  renderMessages = () => {
    const { members, messages, isEmbedded } = this.props
    const { frozenLoadingPrevious, chatHeight, isExpanded } = this.state

    return (
      <div
        className='messages__body'
        style={{
          minHeight: chatHeight,
          boxSizing: 'border-box',
        }}
        ref={this.messageBox}
      >
        {isEmbedded && (
          <div className={styles.buttonCont}>
            {isExpanded ? (
              <Button.Regular
                className='button--default'
                onClick={this.handleMinimize}
              >
                {this.props.t('Minimize')}
              </Button.Regular>
            ) : (
              <Button.Regular
                className='button--default'
                onClick={this.handleMaximize}
              >
                {this.props.t('Maximize')}
              </Button.Regular>
            )}
          </div>
        )}
        <div
          className='messages__history has-scroll'
          style={{ paddingTop: 50 }}
          ref={this.setMessagesScrollBox}
        >
          <InfiniteScroll
            isReverse
            loadMore={this.loadMore}
            hasMore={!frozenLoadingPrevious}
            useWindow={false}
            threshold={250}
            pageStart={0}
          >
            <div>
              {messages.length > 0 && members.length > 0 ? (
                this.addDelimitersToMessages(messages).map(this.renderDelimiter)
              ) : (
                <div />
              )}
            </div>
          </InfiniteScroll>
        </div>
        <div className='hidden scrollbar scrollbar--default scrollbar--vertical scrollbar--medium'>
          <div className='scrollbar__track'>
            <div className='scrollbar__thumb' style={{ height: '100px' }} />
          </div>
        </div>
      </div>
    )
  }

  render() {
    const {
      members,
      messages,
      isWaitingToRenameChatroom,
      sendingMessage,
      user,
      chatroomInfo,
      title,
      isEmbedded,
      requestId,
      parent,
      disabled,
      countersUpdated,
      initiated,
      creating,
      uuid,
      readOnly,
      postUuid,
      feedback,
      templateParams,
      scope,
      object,
      outbound,
    } = this.props
    const isCreating = this.isCreating()

    if (!initiated && !isCreating) {
      return <Loader text={false} type='medium' />
    }

    const { isMinimized, files, headerWidth } = this.state
    let activeMembers
    let chatroomTitle

    if (isCreating) {
      activeMembers = this.state.newChatroomMembers
      chatroomTitle = this.state.newChatroomTitle
    } else {
      activeMembers = members && members.filter(m => m.active)
      chatroomTitle = title || this.getTitle(members)
    }

    let headerAvatarUrl = null

    if (activeMembers && activeMembers.length === 2) {
      const anotherUser = activeMembers.filter(
        member => member.user !== user.owner
      )[0]

      headerAvatarUrl = getUrlForAvatar(anotherUser)
    }

    const userIsInactive =
      members
        .filter(member => !member.active)
        .map(member => member.user)
        .indexOf(user.owner) !== -1
    // all can send message, if not inacvite
    let canSendMessage = isCreating || !userIsInactive || isEmbedded

    // если чаты в заявке, то можно отправлять сообщение, когда один человек в беседе
    if (activeMembers.length < 2 && !this.props.isEmbedded) {
      canSendMessage = false
    }

    let inputMessage = this.props.t('TypeInAResponse')

    if (!messages.length) {
      inputMessage = `${this.props.t('StartTypingAMessageHere')}`
    }

    // todo refactor this ugly piece
    if (['post', 'building', 'flat'].includes(parent)) {
      inputMessage = `${this.props.t('StartTypingAMessageHere')}...`
    } else if (!initiated) {
      if (isCreating && activeMembers.length === 1 && !requestId) {
        inputMessage = `${this.props.t('SelectUserToSendMessageFirst')}`
      } else if (isCreating) {
        inputMessage = `${this.props.t('StartTypingAMessageHere')}`
      }
    } else if (userIsInactive) {
      inputMessage = `${this.props.t('YouAreExcludedFromChat')}`
    }

    const quillProps = {
      onChange: this.handleChangeQuill,
      placeholder: inputMessage,
      readOnly: userIsInactive,
      value: this.state.messageText,
    }
    const messagesBodyClassnames = classnames('messages unit unit--default', {
      'messages--embedded': isEmbedded,
      'messages--readonly': disabled,
    })
    const wrapperCss = isEmbedded ? '' : 'content__col'
    const headerClass = headerWidth ? { width: headerWidth } : {}

    return (
      <div className={wrapperCss} style={{ marginTop: '20px' }}>
        {!isEmbedded && (
          <section
            className='messages unit unit--default'
            ref={element => {
              this.header = element
            }}
            style={headerClass}
          >
            <ChatroomHeader
              avatar={headerAvatarUrl}
              membersCount={activeMembers.length}
              renaming={isWaitingToRenameChatroom}
              title={chatroomTitle}
              permissions={chatroomInfo ? chatroomInfo.permissions : {}}
              minimized={isMinimized}
              isCreated={this.state.isCreated}
              clearCreatedStatus={this.clearCreatedStatus}
              onRenameChatroom={this.renameChatroom}
              onToggle={this.toggleHeader}
            />
            {!!user && (
              <ChatroomInfo
                isGerman={isDeLocale(user.language_obj.code)}
                created={
                  chatroomInfo === null ? undefined : chatroomInfo.created
                }
                members={activeMembers}
                newChatroomMembers={this.state.newChatroomMembers}
                chatroomUuid={uuid}
                chatroomInfo={chatroomInfo}
                signedInUser={user}
                isHidden={isMinimized}
                permissions={chatroomInfo ? chatroomInfo.permissions : {}}
                labels={chatroomInfo ? chatroomInfo.label_objs : []}
                showStatusMessage={countersUpdated}
                onSaveLabels={this.saveLabels}
                onEdit={this.showModal}
                onEditMembers={this.showAddMembersModal}
                onUpdate={this.updateMembers}
              />
            )}
          </section>
        )}
        <section
          className={messagesBodyClassnames}
          ref={element => {
            this.body = element
          }}
          style={{ marginTop: isMinimized ? '-1px' : '2rem' }}
        >
          {this.renderContent()}
          {!disabled && !readOnly && (
            <SendMessageForm
              isChatroom
              showAddButton
              autoFocus={!requestId && !postUuid}
              requestId={requestId}
              templateParams={templateParams}
              postUuid={postUuid}
              feedback={feedback}
              canSubmit={canSendMessage && !this.isSendDisabled()}
              files={files}
              gallery={chatroomInfo ? chatroomInfo.gallery : null}
              quillProps={quillProps}
              working={sendingMessage || creating}
              setActiveIndex={this.setActiveIndex}
              outbound={outbound}
              onSubmit={this.sendMessage}
              onUpdateFiles={this.updateFiles}
            />
          )}
          <Modal
            contentLabel='requestControlPanelModals'
            isOpen={this.state.modal !== null}
            onRequestClose={this.hidePopUp}
          >
            {this.state.modal}
          </Modal>
          <Lightbox
            newGallery
            activeIndex={this.state.activeIndex}
            images={files}
            scope={scope || 'tree'}
            object={object}
            outbound={outbound}
            onClose={this.closeLightbox}
          />
        </section>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state.chatroom,
  user: getUser(state),
  isWizardEnabled: isWizardEnabled(state),
})

const mapDispatchToProps = {
  initiate,
  reset,
  getNewMessagesInitiating,
  getOldMessagesInitiating,
  addMembersInitiating,
  removeMembersInitiating,
  create,
  sendMessageInitiating,
  renameChatroomInitiating,
  likeMessageInitiating,
  hideModal,
  updateLabelsInit,
  toggleChatroomMessage,
  push,
  deleteFiles,
  deleteMessage,
  updateMessages,
}

export default compose(
  withTranslation('Chatroom'),
  connect(mapStateToProps, mapDispatchToProps)
)(Chatroom)
