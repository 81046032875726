// @flow

import { all, put, call, fork, takeEvery, takeLatest } from 'redux-saga/effects'
import { replace } from 'connected-react-router'
import { map, has, isNumber } from 'lodash-es'
import { stringify } from 'qs'

import api from '../../../core/api'
import {
  serverError,
  updateCountersAction,
  globalModalError,
} from '../../Layout/Layout.actions'
import { SERVER_404_ERROR } from '../../Layout/Layout.constants'
import * as actions from './RequestList.actionTypes'
import { getRequestExtra, isSingleLoad } from './RequestList.utils'

function* watchInitiate() {
  yield takeLatest(actions.REQUEST_LIST_INITIATE, initiate)
}

function* watchLoad() {
  yield takeLatest(actions.REQUEST_LIST_LOAD, load)
}

function* watchUpdate() {
  yield takeLatest(actions.REQUEST_LIST_MASS_UPDATE, update)
}

function* watchMarkAsRead() {
  yield takeEvery(actions.REQUEST_LIST_MARK_AS_READ, markAsRead)
}

function* watchMarkAsUnread() {
  yield takeEvery(actions.REQUEST_LIST_MARK_AS_UNREAD, markAsUnread)
}

function* getList(params) {
  let getApi =
    params && params.soft_archived
      ? api.request.getArchivedRequestListLight
      : api.request.getRequestListLight

  if (params.outbound) {
    getApi = params =>
      api.idwell2idwell.getRequestListLight(params.outbound, params)
  } else if (params.flat) {
    getApi = api.request.getFlatRequestList
  } else if (params.building) {
    getApi = api.request.getBuildingRequestList
  } else if (params.owner || params.manager) {
    getApi = api.request.getProfileRequestList
  } else if (params.buildingGroup) {
    getApi = params =>
      api.building.getGroupRequests({
        ...params,
        building_group: params.buildingGroup,
      })
  } else if (params.request) {
    getApi = params => api.request.getRelatedRequestList(params.request, params)
  }

  const data = yield call(getApi, params)

  yield put(updateCountersAction())

  return data
}

function* getListCount(params) {
  const countApi =
    params && params.soft_archived
      ? api.request.getArchivedRequestListCount
      : params.owner || params.manager
      ? api.request.getProfileRequestListCount
      : api.request.getRequestListCount

  if (params.request) {
    return yield call(
      api.request.getRelatedRequestListCount,
      params.request,
      params
    )
  } else if (params.outbound) {
    return yield call(
      api.idwell2idwell.getRequestListLightCount,
      params.outbound,
      params
    )
  } else {
    return yield call(countApi, params)
  }
}

function* getListExtra(data) {
  const ids = map(data.results.objects, 'id')
  let withExtra = []

  if (ids.length > 0) {
    const extra = yield call(api.request.getRequestListExtra, {
      request_ids: ids.join(','),
    })

    withExtra = data.results.objects.map(request => ({
      ...request,
      ...getRequestExtra(request, extra.results),
    }))
  }

  return withExtra
}

function* initiate({ params, isStaff }) {
  try {
    const singleLoad = isSingleLoad(params)

    const data = yield all([
      call(getList, params),
      !singleLoad && call(getListCount, params),
    ])

    const [list, meta] = data

    yield put({ type: actions.REQUEST_LIST_INITIATED, ...list })

    if (meta || list.meta) {
      yield put({
        type: actions.REQUEST_LIST_META_INITIATED,
        meta: { ...meta, ...list.meta },
      })

      if (isStaff && !params.outbound) {
        const withExtra = yield call(getListExtra, list)

        yield put({
          type: actions.REQUEST_LIST_EXTRA_INITIATED,
          withExtra,
        })
      }
    }
  } catch (error) {
    const action = serverError(error)

    if (action.type === SERVER_404_ERROR) {
      yield put(replace('/404'))
    } else {
      yield put(action)
      yield put({ type: actions.REQUEST_LIST_ERROR, error })
    }
  }
}

function* load({ params, isStaff }) {
  try {
    let singleLoad = isSingleLoad(params)

    const data = yield all([
      call(getList, params),
      !singleLoad && call(getListCount, params),
    ])

    const [list, meta] = data

    yield put({ type: actions.REQUEST_LIST_UPDATED, ...list })

    if (meta || list.meta) {
      yield put({
        type: actions.REQUEST_LIST_META_UPDATED,
        meta: { ...meta, ...list.meta },
      })

      if (isStaff && !params.outbound) {
        const withExtra = yield call(getListExtra, list)

        yield put({
          type: actions.REQUEST_LIST_EXTRA_UPDATED,
          withExtra,
        })
      }
    }
  } catch (error) {
    const action = serverError(error)

    if (action.type === SERVER_404_ERROR) {
      yield put(replace('/404'))
    } else {
      yield put(action)
      yield put({ type: actions.REQUEST_LIST_ERROR, error })
    }
  }
}

function* update({ ids, patch, params, isStaff, pathname, query }) {
  try {
    const singleLoad = isSingleLoad(params)

    const checkPage = !singleLoad && has(patch, 'soft_archived')

    yield call(api.massAction.updateRequest, {
      model: 'request',
      model_pks: JSON.stringify(ids),
      patch,
    })

    const data = yield all([
      call(getList, params),
      !singleLoad && call(getListCount, params),
    ])

    const [list, meta] = data

    yield put({ type: actions.REQUEST_LIST_UPDATED, ...list })

    if (checkPage) {
      const currPage = list?.meta?.curr_page
      const pageCount = meta?.page_count

      if (isNumber(currPage) && isNumber(pageCount) && currPage > pageCount) {
        yield put(replace(`${pathname}?${stringify({ ...query, page: 1 })}`))

        return
      }
    }

    if (meta) {
      yield put({
        type: actions.REQUEST_LIST_META_UPDATED,
        meta: { ...meta, ...list.meta },
      })

      if (isStaff) {
        const withExtra = yield call(getListExtra, list)

        yield put({
          type: actions.REQUEST_LIST_EXTRA_UPDATED,
          withExtra,
        })
      }
    }
  } catch (error) {
    yield put(serverError(error))

    if (error.message.response.status === 400) {
      yield put(globalModalError(error.message.response.data.detail))
    }

    yield put({ type: actions.REQUEST_LIST_ERROR, error })
  }
}

function* markAsRead({ ids, params, isStaff }) {
  try {
    const patchParams = {
      model: 'request',
      model_pks: JSON.stringify(ids),
      patch: { read: true },
    }

    yield call(api.massAction.updateRequest, patchParams)

    yield put({
      type: actions.REQUEST_LIST_INITIATE,
      params,
      isStaff,
    })
  } catch (error) {
    yield put(serverError(error))
    yield put({ type: actions.REQUEST_LIST_ERROR, error })
  }
}

function* markAsUnread({ ids, params, isStaff }) {
  try {
    const patchParams = {
      model: 'request',
      model_pks: JSON.stringify(ids),
      patch: { unread: true },
    }

    yield call(api.massAction.updateRequest, patchParams)

    yield put({
      type: actions.REQUEST_LIST_INITIATE,
      params,
      isStaff,
    })
  } catch (error) {
    yield put(serverError(error))
    yield put({ type: actions.REQUEST_LIST_ERROR, error })
  }
}

export default function* watch() {
  yield all([
    fork(watchInitiate),
    fork(watchLoad),
    fork(watchUpdate),
    fork(watchMarkAsRead),
    fork(watchMarkAsUnread),
  ])
}
