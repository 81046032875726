// @flow

import React, { Component } from 'react'
import { QueryRenderer } from 'react-relay'
import { Redirect } from 'react-router-dom'
import { has } from 'lodash-es'

import ThreeColumnLayout from '../../components/Layout/ThreeColumnLayout'
import MarketplaceContactsWidget from '../widgets/MarketplaceContactsWidget'
import Loader from '../../components/Loader'
import environment from '../../createRelayEnvironment'
import OfferProfile from './OfferProfile'
import query from './OfferProfilePage.query'
import ErrorMessage from '../../ui/ErrorMessage'
import Modal from '../../components/Modal'

const { MarketplaceError } = ErrorMessage

/*
  Необходимо отредактировать ThreeColumnLayout, чтобы props.history не передавалась явно (withRouter)
*/

let routerHistory

function withRoute(routerProps, setModal) {
  routerHistory = routerProps

  return params => render(params, setModal)
}

function render({ error, props }, setAddressModal) {
  if (error) {
    return <MarketplaceError error={error} />
  } else if (props) {
    if (!props.offer) {
      return <Redirect to='/404' />
    } else {
      const addressAttr = JSON.parse(props.offer.appliedAttributes).find(
        attr => has(attr, 'type') && attr.type === 'ADDRESS'
      )

      return (
        <ThreeColumnLayout
          widgets={MarketplaceContactsWidget}
          contacts={props.offer.provider.contacts}
          address={addressAttr}
          setAddressModal={setAddressModal}
          {...props}
          {...routerHistory}
        >
          <OfferProfile {...props} />
        </ThreeColumnLayout>
      )
    }
  } else {
    return <Loader text={false} type='big' />
  }
}

class OfferProfilePage extends Component {
  state = {
    modal: null,
  }

  setModal = modal => {
    this.setState({ modal })
  }

  render() {
    const {
      match: {
        params: { id },
      },
    } = this.props

    return (
      <div>
        <QueryRenderer
          variables={{
            offerID: id,
          }}
          environment={environment}
          query={query}
          render={withRoute(this.props, this.setModal)}
        />
        <Modal
          contentLabel='requestControlPanelModals'
          isOpen={!!this.state.modal}
          onRequestClose={() => this.setModal(null)}
        >
          {this.state.modal}
        </Modal>
      </div>
    )
  }
}

export default OfferProfilePage
