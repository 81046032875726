// @flow

import React from 'react'
import { isObject } from 'lodash-es'
import classNames from 'classnames'
import type { Node } from 'react'

import List from '../List'
import Value from './Value'
import Label from './Label'

import styles from './Attr.module.scss'

type Props = { attr: Object, isLast?: boolean, isSub?: boolean, t: Function }

const Attr = (props: Props): Node => {
  const {
    attr: { value, label },
    isSub,
    isLast,
    t,
  } = props

  if (isObject(value)) {
    return (
      <>
        <div className={styles.attr}>
          <Label isSub label={t(label)} />
        </div>
        <List isSub attrs={value} />
      </>
    )
  }

  const attrClass = classNames(styles.attr, {
    [styles.sub]: isSub,
    [styles['last-sub']]: isLast && isSub,
  })

  return (
    <div className={attrClass}>
      <Label label={t(label)} />
      <Value value={value} isSub={isSub} />
    </div>
  )
}

export default Attr
