// @flow

export const PROFILE_INITIATING = 'PROFILE_INITIATING'
export const PROFILE_INITIATED = 'PROFILE_INITIATED'
export const PROFILE_SECTION_READY = 'PROFILE_SECTION_READY'
export const PROFILE_ERROR = 'PROFILE_ERROR'
export const PROFILE_UPDATE_FILES = 'PROFILE_UPDATE_FILES'
export const PROFILE_UPDATED = 'PROFILE_UPDATED'
export const PROFILE_UPDATE = 'PROFILE_UPDATE'
export const PROFILE_RESET = 'PROFILE_RESET'
