// @flow

import React, { useState } from 'react'
import { range } from 'lodash-es'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

type Props = {
  currentPage?: number,
  noScroll: boolean,
  onPageChange: number => void,
  pageSize: number,
  totalCount: number,
}

const PAGE_SEPARATOR = '..'

function getItems(count, current) {
  // TODO check legacy
  let items = range(1, count + 1)

  if (count > 7) {
    let startArr = current - 1
    let endArr = current + 2
    let startClose = false
    let endClose = false

    if (startArr <= 3) {
      startArr = 3
      startClose = true
    }

    if (endArr > count - 2) {
      endArr = count - 1
      endClose = true
    }

    const middleArr = range(startArr, endArr)
    items = [1, 2]

    if (startClose) {
      items = items.concat(middleArr)
    } else {
      items = items.concat([PAGE_SEPARATOR]).concat(middleArr)
    }

    if (endClose) {
      items = items.concat([count - 1, count])
    } else {
      items = items.concat([PAGE_SEPARATOR]).concat([count - 1, count])
    }
  }

  return items
}

const Pagination = ({
  currentPage,
  pageSize,
  totalCount: totalItemsCount,
  ...props
}: Props) => {
  const [pageInput, setPageInput] = useState('1')

  const totalCount = Math.ceil(totalItemsCount / pageSize)
  const items = getItems(totalCount, currentPage)
  const isButtonDisabled =
    Number(pageInput) > totalCount ||
    Number(pageInput) === currentPage ||
    !pageInput

  const { t } = useTranslation('BottomPagination')

  const goToPage = (item: number | string) => {
    return () => {
      props.onPageChange(item)

      if (!props.noScroll) {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }
    }
  }

  const onPageInputChange = e => {
    const pageValue = Number(e.target.value)
    setPageInput(pageValue === 0 || isNaN(pageValue) ? '' : e.target.value)
  }

  const renderItem = (item: number | string, key: number) => {
    if (item === PAGE_SEPARATOR) {
      return (
        <span key={key} className='paginator-2__item'>
          ...
        </span>
      )
    }

    const itemClass = classnames('paginator-2__item', {
      'paginator-2__item--current': item === currentPage,
    })

    return (
      <div className={itemClass} key={key} onClick={goToPage(item)}>
        {item}
      </div>
    )
  }

  return (
    <nav className='paginator paginator--middle'>
      {items.map(renderItem)}
      <div className='paginator-2__extra'>
        <span className='paginator-2__01'>{t('MoveToPage')}</span>
        <div className='paginator-2__02 input input--medium input--default input--center'>
          <input
            type='text'
            title={t('PageNumber')}
            value={pageInput}
            onChange={onPageInputChange}
          />
        </div>
        <button
          className='paginator-2__03 button button--medium button--success-2'
          disabled={isButtonDisabled}
          type='button'
          onClick={goToPage(Number(pageInput))}
        >
          {t('Move')}
        </button>
      </div>
    </nav>
  )
}

Pagination.defaultProps = {
  currentPage: 1,
}

export default Pagination
