// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import classnames from 'classnames'

import {
  getLocation,
  getUkLogo,
  getUkMobileLogo,
} from '../../utils/commonSelectors'

import styles from './Logo.module.scss'

type Props = {
  footer?: boolean,
  location: { pathname: string },
  logo: ?Object,
  push: string => void,
  tiny?: boolean,
  tinyLogo: ?Object,
}

const MOBILE_WIDTH = 130
const WIDTH = 340
const FOOTER_WIDTH = 182

const PATH = '/'

class Logo extends Component<Props> {
  goHome = () => {
    this.props.push(PATH)
  }

  render() {
    const { tiny, footer, location } = this.props
    const logo = tiny ? this.props.tinyLogo : this.props.logo

    if (!logo) {
      return null
    }

    const { thumbnails = {}, origin } = logo

    const style = classnames(styles.logo, {
      [styles.footer]: footer,
      [styles.tiny]: tiny,
      [styles.clickable]: location.pathname !== PATH,
    })

    let src = thumbnails[footer ? FOOTER_WIDTH : WIDTH]

    if (tiny) {
      src = thumbnails[MOBILE_WIDTH]
    }

    return (
      <div className={style} onClick={this.goHome}>
        <img src={src || origin} alt='logo' />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  location: getLocation(state),
  logo: getUkLogo(state),
  tinyLogo: getUkMobileLogo(state),
})

const mapActionsToProps = { push }

export default connect(mapStateToProps, mapActionsToProps)(Logo)
