import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import { get, isEqual } from 'lodash-es'
import { Formik, Form, ErrorMessage } from 'formik'

import ButtonsBar from './ButtonsBar'
import Input from '../../components/Input/Regular'
import ValidationText from '../../components/ValidationText'
import UploadAvatar from './UploadAvatar'
import styles from './ProviderProfile.module.scss'

const EditHeaderForm = props => {
  const { providerData } = props
  const [imgId, setImgId] = useState(null)
  const { t } = useTranslation('Providers')

  const validate = ({ name, address }) => {
    const errors = {}

    if (!name || !name.trim()) {
      errors.name = t('emptyInput', { key: t('name') })
    }

    if (!address || !address.trim()) {
      errors.address = t('emptyInput', { key: t('address') })
    }

    return errors
  }

  const onSubmit = (values, { setSubmitting }) => {
    const fullValues = { ...values }

    if (imgId) {
      fullValues.imageId = imgId
    }

    delete fullValues.image

    props.onSubmit(
      fullValues,
      () => {
        setSubmitting(false)
      },
      () => {
        setSubmitting(false)
      }
    )
  }

  const renderInput = (name, formikProps) => {
    const { values, setFieldValue } = formikProps
    const rootClassName = classnames(
      'mprofile-edit-user__field',
      styles['edit-header__input']
    )
    const labelClass = classnames(
      'mprofile-edit-user__label',
      'mprofile-edit-user__label--required',
      'mprofile-edit-user__label--large',
      styles['edit-header__label']
    )
    const inputClass = classnames(
      'input',
      'input--default',
      'input--active',
      'input--large',
      'input--font-xlarge',
      'input--xlarge',
      styles['edit-header__control']
    )
    const label = t(name)

    return (
      <div className={rootClassName}>
        <div className={labelClass}>{label}</div>
        <div className={inputClass}>
          <Input
            type='text'
            placeholder={label}
            name={name}
            value={values[name]}
            onChange={setFieldValue}
          />
          <ErrorMessage name={name} render={renderError} />
        </div>
      </div>
    )
  }

  const renderError = text => <ValidationText active text={text} />
  const disabled = ({ isSubmitting, values }) =>
    isSubmitting || !(!isEqual(values, providerData) || imgId)

  return (
    <Formik
      initialValues={providerData}
      validate={validate}
      onSubmit={onSubmit}
    >
      {formikProps => (
        <Form className={styles['edit-header']}>
          <div className={styles['edit-header__container']}>
            <UploadAvatar
              image={get(formikProps, ['values', 'image', 'origin']) || null}
              onSetImgId={setImgId}
            />

            <div className={styles['edit-header__text']}>
              {renderInput('name', formikProps)}

              {renderInput('address', formikProps)}
            </div>
          </div>

          <ButtonsBar
            disabled={disabled(formikProps)}
            cancelDisabled={formikProps.isSubmitting}
            onCancel={props.onCancel}
            onSubmit={formikProps.handleSubmit}
          />
        </Form>
      )}
    </Formik>
  )
}

export default EditHeaderForm
