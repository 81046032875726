// @flow

import React, { useEffect, useState } from 'react'
import type { Node } from 'react'
import { useTranslation } from 'react-i18next'

import { omit } from 'lodash-es'

import classnames from 'classnames'

import NewSelectAsync from '../../NewSelectAsync'

import {
  getPipelineTemplates,
  getPhaseTemplates,
} from '../../../core/api/api.pipeline'

import { OMIT_FIELDS } from '../AutomateTable/Automate.constants'

import styles from '../AutomateInfo.module.scss'

type Props = {
  data: Object,
  onUpdate: Object => void,
}

const Phase = (props: Props): Node => {
  const { data, onUpdate } = props
  const { t } = useTranslation('RequestConstructor')

  const [pipeline, setPipeline] = useState(null)
  const [phase, setPhase] = useState(null)

  const cardClass = classnames(
    'request-constructor__card--body request-constructor__card--email'
  )

  const phaseApi = () => getPhaseTemplates(pipeline.id)

  useEffect(() => {
    setPipeline(data.pipeline_template)
    setPhase(data.phase_template)
    onUpdate({
      ...omit(data, OMIT_FIELDS),
    })
  }, [])

  return (
    <div className={cardClass}>
      <div>{t('selectPipeline')}</div>
      <NewSelectAsync
        className={styles.selectPhase}
        pageSize={10}
        api={getPipelineTemplates}
        searchKey='name'
        placeholder={t('PipelinePlaceholder')}
        getLabel={a => a.name}
        getSelectedLabel={a => a.name}
        view='dropdown'
        selectedItems={pipeline ? [pipeline] : []}
        onClick={option => {
          onUpdate({
            pipeline_template_id: option.id,
            phase_template_id: null,
          })
          setPipeline(option)
          setPhase(null)
        }}
      />
      <div>{t('selectPhase')}</div>
      <NewSelectAsync
        pageSize={10}
        disabled={!pipeline}
        className={styles.selectPhase}
        api={phaseApi}
        searchKey='name'
        placeholder={t('PhasePlaceholder')}
        getLabel={a => a.name}
        getSelectedLabel={a => a.name}
        view='dropdown'
        selectedItems={phase ? [phase] : []}
        dynamicParams={{ pipeline_id: pipeline?.id }}
        onClick={option => {
          onUpdate({
            pipeline_template_id: pipeline.id,
            phase_template_id: option.id,
          })
          setPhase(option)
        }}
      />
    </div>
  )
}

export default Phase
