// @flow

import React, { memo } from 'react'

import Icon from '../../../../../../Icon'
import { CHECKLIST_WIDTH } from '../Info.constants'

import styles from './AdminInfo.module.scss'

type Props = {
  data: Object,
  nameWidth: number,
}

const Checklists = memo<Props>(props => {
  const {
    count,
    data: { checked_count, count: checklistCount, name },
  } = props

  return (
    <>
      <div className={styles['checklist-box']} title={name}>
        <span
          className={styles.checklist}
          style={{ '--itemWidth': `${CHECKLIST_WIDTH}px` }}
        >
          <Icon id='checklist' className='checkitem__icon' />
          <span className='checkitem__countwrapper'>
            <span className='checkitem__checkedcount'>{checked_count}</span>/
            <span className='checkitem__count'>{checklistCount}</span>
          </span>
        </span>
      </div>
      {count === 1 && <span className={styles.singleText}>{name}</span>}
    </>
  )
})

export default Checklists
