// @flow

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'

import Button from '../../components/Button'
import CheckItem from './CheckItem'
import CheckitemEditable from '../../components/CheckitemEditable'
import ConfirmationPopUp from '../../components/modals/ConfirmationPopup'
import Modal from '../../components/Modal'
import {
  resetInitialState,
  getCheckitemsInit,
  createCheckitemInit,
  deleteCheckitemInit,
  toggleCheckitemInit,
  editCheckitemInit,
} from './Checklist.actions'

class Checklist extends Component {
  state = {
    isAddingNewCheckitem: false,
    isDeleteCheckListModalOpen: false,
  }

  componentDidMount() {
    const {
      startGetCheckitemsInit,
      shouldScrollIntoView,
      checklistId,
      cancelChecklist,
    } = this.props
    startGetCheckitemsInit(checklistId)

    if (shouldScrollIntoView) {
      this.section.scrollIntoView({ block: 'start', behavior: 'smooth' })
      this.startAddingNewCheckitem()
      cancelChecklist()
    }
  }

  toggleCheckItem = (checkitemId, checked) => {
    const { startToggleCheckitemInit, checklistId } = this.props
    startToggleCheckitemInit(checklistId, checkitemId, checked)
  }

  showDeleteCheckListPopUp = () => {
    this.setState({ isDeleteCheckListModalOpen: true })
  }

  hideDeleteCheckListPopUp = () => {
    this.setState({ isDeleteCheckListModalOpen: false })
  }

  updateCheckItemText = (checkitemId, text) => {
    const { startEditCheckitemInit, checklistId, startDeleteCheckitemInit } =
      this.props

    if (text.length === 0) {
      startDeleteCheckitemInit(checklistId, checkitemId)

      return
    }

    startEditCheckitemInit(checklistId, checkitemId, text)
  }

  deleteChecklist = () => {
    const { checklistId, onDeleteChecklist } = this.props
    this.setState({ isDeleteCheckListModalOpen: false })
    onDeleteChecklist(checklistId)
  }

  startAddingNewCheckitem = () => {
    this.setState({ isAddingNewCheckitem: true })
  }

  cancelAddingNewCheckitem = () => {
    this.setState({ isAddingNewCheckitem: false })
  }

  addCheckitem = text => {
    const { checklistId, startCreateCheckitemInit } = this.props
    startCreateCheckitemInit(checklistId, text)
  }

  deleteCheckitem = checkitemId => {
    const { checklistId, startDeleteCheckitemInit } = this.props
    startDeleteCheckitemInit(checklistId, checkitemId)
  }

  render() {
    const { name, checklists, t, checklistId, isEditable } = this.props
    const { isAddingNewCheckitem } = this.state

    let checkitems

    if (checklists[checklistId] !== undefined) {
      checkitems = checklists[checklistId].checkitems
    } else {
      checkitems = []
    }

    const numberOfChecked = checkitems.filter(item => item.checked).length
    const percentage = Math.round(
      (numberOfChecked / (checkitems.length || 1)) * 100
    )

    return (
      <section
        className='unit unit--default unit--nomargin checklist checklist--default'
        ref={element => {
          this.section = element
        }}
      >
        <h2 className='uploader__title'>{t('Checklist')}</h2>
        <div className='checklist__header'>
          <div className='checklist__title'>{name}</div>
          {isEditable && (
            <a
              className='checklist__header-url'
              onClick={this.showDeleteCheckListPopUp}
            >
              {t('Delete')}
            </a>
          )}
        </div>
        <div className='checklist__content'>
          <div className='checklist__progress-container'>
            <div className='checklist__progress-bar'>
              <div
                className='checklist__progress-bar-current'
                style={{
                  width: `${percentage}%`,
                  backgroundColor: percentage !== 100 ? '#ff96a4' : '#5ad2bc',
                }}
              />
            </div>
            <div className='checklist__progress-percentage'>
              {checkitems.length === 0 ? 0 : percentage}%
            </div>
          </div>
          <div className='checklist__item-list'>
            {checkitems.map(({ id, text, checked }) => (
              <CheckItem
                key={id}
                checkitemId={id}
                text={text}
                checked={checked}
                canEdit={isEditable}
                onCheckboxClick={this.toggleCheckItem}
                onTextSave={this.updateCheckItemText}
                onDelete={this.deleteCheckitem}
              />
            ))}
          </div>
          {isEditable && (
            <div className='checklist__bottom-button-block'>
              {isAddingNewCheckitem ? (
                <CheckitemEditable
                  checkitemText=''
                  onSave={this.addCheckitem}
                  onCancel={this.cancelAddingNewCheckitem}
                  onDelete={this.deleteCheckitem}
                />
              ) : (
                <Button.Text
                  bordered
                  large
                  icon='plusLarge'
                  title={this.props.t('AddElement')}
                  onClick={this.startAddingNewCheckitem}
                />
              )}
            </div>
          )}
        </div>
        <Modal
          contentLabel=''
          className='Modal__Bootstrap modal-dialog'
          isOpen={this.state.isDeleteCheckListModalOpen}
          onRequestClose={this.hideDeleteCheckListPopUp}
        >
          <ConfirmationPopUp
            title={t('AreYouShureYouWantToDeleteChecklist')}
            text={t('YouAreDeletingChecklist {{value1}}', { value1: name })}
            confirm={t('Delete')}
            cancel={t('Common:Cancel')}
            onClose={this.hideDeleteCheckListPopUp}
            onOk={this.deleteChecklist}
          />
        </Modal>
      </section>
    )
  }
}

const mapStateToProps = state => ({
  ...state.checklists,
})

const mapDispatchToProps = {
  startResetInitialState: resetInitialState,
  startGetCheckitemsInit: getCheckitemsInit,
  startCreateCheckitemInit: createCheckitemInit,
  startDeleteCheckitemInit: deleteCheckitemInit,
  startToggleCheckitemInit: toggleCheckitemInit,
  startEditCheckitemInit: editCheckitemInit,
}

export default compose(
  withTranslation('Checklist'),
  connect(mapStateToProps, mapDispatchToProps)
)(Checklist)
