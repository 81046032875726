// @flow

import { useReducer, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

import { getCurrentFilters, getCurrentPage } from '../../utils/routing'
import { LOAD_ERROR, LOAD_INIT, LOAD_SUCCESS } from './useLoad.constants'
import dataLoadReducer from './useLoad.reducer'

export const useLoad = (api, extraParams, isLocked) => {
  const [state, dispatch] = useReducer(dataLoadReducer, {
    isLoading: false,
    isError: false,
    data: null,
  })

  const location = useLocation()
  const history = useHistory()

  const load = (needReplace, customPathname) => {
    const pathname = customPathname || location.pathname

    let historyFunc = history.push

    if (needReplace) {
      historyFunc = history.replace
    }

    historyFunc(pathname)
  }

  useEffect(() => {
    if (isLocked) {
      return
    }

    let didCancel = false

    const fetchData = async () => {
      dispatch({ type: LOAD_INIT })

      try {
        const page = getCurrentPage(location)
        const filters = getCurrentFilters(location)

        const params = Object.assign(
          {},
          { page, ...filters },
          extraParams ? { ...extraParams } : undefined
        )

        const data = await api(params)

        if (!didCancel) {
          dispatch({ type: LOAD_SUCCESS, payload: data })
        }
      } catch (error) {
        if (!didCancel) {
          dispatch({ type: LOAD_ERROR })
        }
      }
    }

    fetchData()

    return () => {
      didCancel = true
    }
  }, [location, isLocked])

  return [state.data, state.isLoading, state.isError, load]
}
