/**
 * @flow
 * @relayHash a07bc5cd59fd6694b80fc819ffc77294
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type OffersTable_query$ref = any;
export type OffersQueryVariables = {|
  page: number,
  store?: ?string,
  providerName?: ?string,
  categoryId?: ?string,
|};
export type OffersQueryResponse = {|
  +$fragmentRefs: OffersTable_query$ref
|};
export type OffersQuery = {|
  variables: OffersQueryVariables,
  response: OffersQueryResponse,
|};
*/


/*
query OffersQuery(
  $page: Float!
  $store: String
  $providerName: String
  $categoryId: String
) {
  ...OffersTable_query_3dmXAd
}

fragment OffersTable_query_3dmXAd on Query {
  allOffers(page: $page, store: $store, providerName: $providerName, categoryId: $categoryId) {
    totalCount
    edges {
      node {
        id
        name
        finalPrice
        orderCount
        provider {
          name
          id
          image {
            origin
            id
          }
        }
        gallery {
          id
          origin
          default
          thumbnails {
            url
            size
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "page",
    "type": "Float!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "store",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "providerName",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "categoryId",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "categoryId",
    "variableName": "categoryId"
  },
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  },
  {
    "kind": "Variable",
    "name": "providerName",
    "variableName": "providerName"
  },
  {
    "kind": "Variable",
    "name": "store",
    "variableName": "store"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "origin",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "OffersQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "OffersTable_query",
        "args": (v1/*: any*/)
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "OffersQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allOffers",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "OfferNodeFilterConnection",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "totalCount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "OfferNodeFilterEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "OfferNode",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "finalPrice",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "orderCount",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "provider",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ProviderNode",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v2/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "image",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ProviderImageNode",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v2/*: any*/)
                        ]
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "gallery",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "GalleryImageNode",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v4/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "default",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "thumbnails",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Thumbnail",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "url",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "size",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "OffersQuery",
    "id": null,
    "text": "query OffersQuery(\n  $page: Float!\n  $store: String\n  $providerName: String\n  $categoryId: String\n) {\n  ...OffersTable_query_3dmXAd\n}\n\nfragment OffersTable_query_3dmXAd on Query {\n  allOffers(page: $page, store: $store, providerName: $providerName, categoryId: $categoryId) {\n    totalCount\n    edges {\n      node {\n        id\n        name\n        finalPrice\n        orderCount\n        provider {\n          name\n          id\n          image {\n            origin\n            id\n          }\n        }\n        gallery {\n          id\n          origin\n          default\n          thumbnails {\n            url\n            size\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ab2d1725b134db548e720f1a6e121e6e';
module.exports = node;
