// @flow

import { createContext } from 'react'

const FlatAddressContext = createContext({
  number: '',
  entrance: '',
  floor: '',
  flat: null,
  flatId: null,
})

export default FlatAddressContext
