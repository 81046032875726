import { put, call, fork, takeLatest, all, select } from 'redux-saga/effects'

import api from '../../../core/api'
import * as actions from './ActivitiesWidget.actions'
import { REQUEST_VIEW_UPDATED } from '../../../containers/RequestView/RequestView.constants'
import { getActiveWidget } from '../../../containers/Widget/Widget.selectors'
import { WIDGET_TYPES } from '../../../containers/widgets/widgets'
import { groupActivities } from './ActivitiesWidget.utils'
import { serverError } from '../../Layout/Layout.actions'

function * watchLoad () {
  yield takeLatest(actions.LOAD_ACTIVITIES, load)
}

function * watchUpdateRequest () {
  yield takeLatest(REQUEST_VIEW_UPDATED, update)
}

function * update () {
  try {
    const requestId = yield select((state) => state.requestView.data.id)
    const isOpen = (yield select(getActiveWidget)) === WIDGET_TYPES.updates

    if (isOpen) {
      yield put({ type: actions.LOAD_ACTIVITIES, requestId })
    }
  } catch (e) {
    yield put(serverError(e))
  }
}

function * load ({ requestId }) {
  try {
    const rowData = yield call(api.request.getLogs, requestId, { page_size: 5 })
    const data = groupActivities(rowData.reverse())

    yield put(actions.activitiesWasLoaded(data))
  } catch (e) {
    yield put(serverError(e))
  }
}

export default function * watch () {
  yield all([
    fork(watchLoad),
    fork(watchUpdateRequest)
  ])
}
