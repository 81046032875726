//@flow

import React from 'react'
import { useTranslation } from 'react-i18next'

import NewTableHeader from '../../NewTable/NewTableHeader'
import NewTableHeaderCell from '../../NewTable/NewTableHeaderCell'
import NewTableHeaderRow from '../../NewTable/NewTableHeaderRow'

const FolderSyncTableHeader = props => {
  const { t } = useTranslation('EmailIntegrationNylas')
  const { onSort, location } = props

  return (
    <NewTableHeader>
      <NewTableHeaderRow>
        <NewTableHeaderCell
          title={t('FolderName')}
          style={{ width: '55%' }}
          sortKey='display_name'
          location={location}
          onSort={onSort}
        />
        <NewTableHeaderCell
          title={t('FolderSyncStatus')}
          style={{ width: '45%' }}
          sortKey='is_sync'
          location={location}
          onSort={onSort}
        />
      </NewTableHeaderRow>
    </NewTableHeader>
  )
}

export default FolderSyncTableHeader
