// @flow

import React, { Suspense } from 'react'
import classnames from 'classnames'
import type { Node } from 'react'

import * as Icons from './EntityIcons'

import styles from './EntityIcon.module.scss'

type IconId =
  | 'apartment'
  | 'automate'
  | 'building'
  | 'chat'
  | 'email'
  | 'importation'
  | 'marketplace'
  | 'offer'
  | 'order'
  | 'post'
  | 'request'
  | 'review'
  | 'pdf'
  | 'provider'
  | 'requestProvider'
  | 'requestOrder'
  | 'user'

type Props = {
  className?: string,
  id: IconId,
  large?: boolean,
  onClick?: () => void,
  style?: Object,
}

const EntityIcon = (props: Props): Node => {
  const { id, className, large, style } = props
  const Svg = Icons[id]

  const svgClass = classnames(className, {
    [styles.small]: !large,
    [styles.large]: large,
  })

  return (
    <Suspense fallback={null}>
      <Svg className={svgClass} style={style} onClick={props.onClick} />
    </Suspense>
  )
}

export default EntityIcon
