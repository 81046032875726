// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import NewTableHeader from '../../NewTable/NewTableHeader'
import NewTableHeaderCell from '../../NewTable/NewTableHeaderCell'
import NewTableHeaderRow from '../../NewTable/NewTableHeaderRow'

const TableHead = (): Node => {
  const { t } = useTranslation('Automate')

  return (
    <NewTableHeader>
      <NewTableHeaderRow>
        <NewTableHeaderCell title={t('Name')} sortKey='name' />
        <NewTableHeaderCell title={t('Status')} sortKey='status' />
        <NewTableHeaderCell
          title={t('Creator')}
          sortKey='created_by_fullname'
        />
        <NewTableHeaderCell title={t('LastUpdate')} sortKey='updated' />
      </NewTableHeaderRow>
    </NewTableHeader>
  )
}

export default TableHead
