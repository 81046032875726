// @flow

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import type { Node } from 'react'

import { deletePhaseTemplate } from '../../../../core/api/api.pipeline'
import Modal from '../../../Modal'
import { createPhaseTemplate } from '../../../../core/api/api.pipeline'
import Button from '../../../Button'
import Input from '../../../Input'
import PhaseListItem from './PhaseListItem'
import ConfirmationPopup from '../../../modals/ConfirmationPopup'

import styles from './PhaseList.module.scss'

type Props = {
  activePhaseId: ?number,
  data: Object,
  pipelineId: string,
  reload: Function,
  setActivePhaseId: Function,
}

const PhaseList = (props: Props): Node => {
  const { pipelineId, data, activePhaseId } = props

  const { t } = useTranslation('Pipeline')

  const [isCreating, setCreating] = useState(false)
  const [editingPhaseId, setEditingPhaseId] = useState(null)
  const [isModalOpen, setModalOpen] = useState(false)
  const [removalId, setRemovalId] = useState(null)

  const handleClick = () => setCreating(true)
  const handleClose = () => setCreating(false)
  const handleSave = value => {
    createPhaseTemplate({
      name: value,
      pipeline_template: pipelineId,
      position: 0,
    }).finally(() => {
      setCreating(false)
      props.reload()
    })
  }

  const handleOpen = id => props.setActivePhaseId(id)

  const hideModal = () => {
    setModalOpen(false)
    setRemovalId(null)
  }

  useEffect(() => {
    if (removalId) {
      setModalOpen(true)
    }
  }, [removalId])

  const handleRemove = () => {
    deletePhaseTemplate(removalId).finally(() => {
      setEditingPhaseId(null)
      hideModal()
      props.reload()
    })
  }

  return (
    <div className={styles['phase-list']}>
      <h2>{t('PhaseListTitle')}</h2>
      <DndProvider backend={HTML5Backend}>
        <ul>
          {data.results.objects.map(phase => (
            <PhaseListItem
              reload={props.reload}
              key={phase.id}
              isActive={activePhaseId === phase.id}
              isEditing={editingPhaseId === phase.id}
              phase={phase}
              setEditingPhaseId={setEditingPhaseId}
              setRemovalId={setRemovalId}
              onClick={handleOpen}
            />
          ))}
        </ul>
      </DndProvider>
      {isCreating && (
        <Input.Edit
          draggable
          editing
          className={styles.edit}
          maxLength={200}
          value=''
          onToggleEdit={isEditing => setCreating(isEditing)}
          onRemove={handleClose}
          onSave={handleSave}
        />
      )}
      <Button.Add
        title={t('AddPhaseButton')}
        className={styles.add}
        onClick={handleClick}
      />
      {isModalOpen && (
        <Modal isOpen={isModalOpen}>
          <ConfirmationPopup
            confirm={t('PhaseRemoveButton')}
            title={t('PhaseRemoveTitle')}
            text={t('ConfirmPhaseRemoving')}
            onClose={hideModal}
            onOk={handleRemove}
          />
        </Modal>
      )}
    </div>
  )
}

export default PhaseList
