// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import { push } from 'connected-react-router'
import { useDispatch } from 'react-redux'
import classNames from 'classnames'
import type { Node } from 'react'

import { getFormattedDate } from '../../../utils/utils'
import NewTableBody from '../../NewTable/NewTableBody'
import NewTableBodyRow from '../../NewTable/NewTableBodyRow'
import NewTableBodyCell from '../../NewTable/NewTableBodyCell'

import styles from './TableBody.module.scss'
import { deleteWorkflow } from '../../../core/api/api.requestConstructor'
import ExtraActions from '../ExtraActions'
import { serverError } from '../../Layout/Layout.actions'

type Props = { setCloningWorkflowId: number => void, workflows: Array<Object> }

const TableBody = (props: Props): Node => {
  const { workflows, setModal } = props
  const { t } = useTranslation('Workflow')
  const dispatch = useDispatch()

  const copyWorkflow = id => {
    props.setCloningWorkflowId(id)
  }

  const handleOpen = id => () => dispatch(push(`/settings/workflow/${id}`))

  const remove = id => {
    let params = {
      ids: [id],
    }

    deleteWorkflow(params)
      .then(() => {
        setModal(null)
        props.load()
      })
      .catch(error => {
        setModal(null)
        dispatch(serverError(error))
      })
  }

  const renderExtra = workflow => {
    return (
      <ExtraActions
        t={t}
        setModal={setModal}
        workflow={workflow}
        isOffline={!workflow.row_is_active}
        copyWorkflow={copyWorkflow}
        remove={remove}
      />
    )
  }

  return (
    <NewTableBody>
      {workflows.map(workflow => {
        const {
          id,
          title,
          publication_date,
          type,
          row_is_active: isActive,
          row_is_changed: isChanged,
        } = workflow

        const formattedDate = publication_date
          ? getFormattedDate(publication_date)
          : '-'

        const statusClass = classNames({
          [styles.active]: isActive,
          [styles.inactive]: !isActive,
        })

        return (
          <NewTableBodyRow key={id} onClick={handleOpen(id)}>
            <NewTableBodyCell title={title} />
            <NewTableBodyCell
              className={statusClass}
              title={isActive ? t('WorkflowActive') : t('WorkflowDisabled')}
            />
            <NewTableBodyCell
              title={isChanged ? t('WorkflowChanged') : t('WorkflowClean')}
            />
            <NewTableBodyCell title={t(type)} />
            <NewTableBodyCell>
              <div
                className='table-item table-item__text'
                title={formattedDate}
              >
                <span>{formattedDate}</span>
              </div>
              {renderExtra(workflow)}
            </NewTableBodyCell>
          </NewTableBodyRow>
        )
      })}
    </NewTableBody>
  )
}

export default TableBody
