// @flow

import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { parse, stringify } from 'qs'
import { isEmpty } from 'lodash-es'
import { useDispatch } from 'react-redux'
import { replace } from 'connected-react-router'

import BrowserStorage from '../utils/browserStorage'
import { useUser } from './useUser'
import { getUserId } from '../utils/utils'
import { formatPathname, getCurrentFilters } from '../utils/routing'

export const usePathname = (): boolean => {
  const user = useUser()
  const location = useLocation()
  const dispatch = useDispatch()

  const [params, setParams] = useState(null)
  const [isLocked, setLocked] = useState(true)

  const { pathname, search } = location

  const userId = getUserId(user)

  useEffect(() => {
    if (!pathname) {
      return
    }

    if (pathname[pathname.length - 1] !== '/') {
      dispatch(replace(`${pathname}/`))

      return
    }

    const filters = getCurrentFilters(location)

    const storagePath =
      userId &&
      (BrowserStorage.get(`${userId}_${pathname}`) ||
        BrowserStorage.get(`${userId}_${pathname}/`))

    if (!isEmpty(filters)) {
      if (filters.page) {
        setLocked(false)

        return
      } else {
        setParams({ page: 1, ...filters })
      }
    } else if (storagePath) {
      const paths = storagePath.split('?')

      if (paths && paths.length > 1) {
        if (paths[1]) {
          setParams({ page: 1, ...parse(paths[1]) })
        } else {
          setParams({ page: 1 })
        }
      }
    } else {
      setParams({ page: 1 })
    }
  }, [pathname])

  useEffect(() => {
    if (params) {
      const formattedPathname = formatPathname(pathname)

      dispatch(replace(`/${formattedPathname}/?${stringify(params)}`))

      setLocked(false)
      setParams(null)
    }
  }, [params])

  useEffect(() => {
    if (!isLocked && !search) {
      const storagePath =
        userId &&
        (BrowserStorage.get(`${userId}_${pathname}`) ||
          BrowserStorage.get(`${userId}_${pathname}/`))

      if (storagePath) {
        const paths = storagePath.split('?')

        if (paths && paths.length > 1) {
          if (paths[1]) {
            setParams({ page: 1, ...parse(paths[1]) })
          } else {
            setParams({ page: 1 })
          }
        }
      } else {
        setParams({ page: 1 })
      }
    }
  }, [search])

  return isLocked
}
