import React from 'react'
import DateConstants from 'rc-calendar/lib/date/DateConstants'
import moment from 'moment'

const DateTHead = (props) => {
  const value = props.value
  const localeData = value.localeData()
  const veryShortWeekdays = []
  const weekDays = []
  const firstDayOfWeek = localeData.firstDayOfWeek()
  const now = moment()

  for (let dateColIndex = 0; dateColIndex < DateConstants.DATE_COL_COUNT; dateColIndex++) {
    const index = (firstDayOfWeek + dateColIndex) % DateConstants.DATE_COL_COUNT
    now.day(index)
    veryShortWeekdays[dateColIndex] = localeData.weekdaysMin(now)
    weekDays[dateColIndex] = localeData.weekdaysShort(now)
  }

  const weekDaysEls = weekDays.map((day, xindex) => (
    <tr
      key={xindex}
      title={day}
      className='calendar__caption'
    >
      <td className='calendar__item'>
        {veryShortWeekdays[xindex]}
      </td>
    </tr>)
  )

  return (
    <thead className='calendar__row'>
      {weekDaysEls}
    </thead>
  )
}
export default DateTHead
