// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import Icon from '../../Icon'

import styles from './SSOLogin.module.scss'

const SSOLogin = () => {
  const { t } = useTranslation('LoginForm')

  const handleSSOLogin = e => {
    e.preventDefault()
    window.location.assign('/api/sso/login/')
  }

  return (
    <div className={styles.ssologin}>
      <Link to='/api/sso/login/' onClick={handleSSOLogin}>
        <Icon id='user' />
        {t('LoginAsEmployee')}
      </Link>
    </div>
  )
}

export default SSOLogin
