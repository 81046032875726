import { lazy } from 'react'

export const doc = lazy(() => import('../../../../static/icons/file-doc.svg'))
export const other = lazy(() =>
  import('../../../../static/icons/file-other.svg')
)
export const pdf = lazy(() => import('../../../../static/icons/file-pdf.svg'))
export const ppt = lazy(() => import('../../../../static/icons/file-ppt.svg'))
export const xls = lazy(() => import('../../../../static/icons/file-xls.svg'))

export const docWhite = lazy(() =>
  import('../../../../static/icons/file-doc-white.svg')
)
export const otherWhite = lazy(() =>
  import('../../../../static/icons/file-other-white.svg')
)
export const pdfWhite = lazy(() =>
  import('../../../../static/icons/file-pdf-white.svg')
)
export const pptWhite = lazy(() =>
  import('../../../../static/icons/file-ppt-white.svg')
)
export const xlsWhite = lazy(() =>
  import('../../../../static/icons/file-xls-white.svg')
)
export const image = lazy(() => import('../../../../static/icons/painting.svg'))
export const video = lazy(() => import('../../../../static/icons/play.svg'))
export const print = lazy(() => import('../../../../static/icons/printer.svg'))
