// @flow

import React from 'react'
import type { Node } from 'react'

import Block from './Block'

type Props = {
  children: Node,
}

const SimpleBlock = ({ children, ...props }: Props): Node => (
  <Block {...props} notCollapse>
    {children}
  </Block>
)

export default SimpleBlock
