// @flow

import React from 'react'

import { EmptyListIcon } from '../../Icon'
import type { IconId } from '../../Icon'

import styles from './EmptyWidget.module.scss'

type Props = {
  icon: IconId,
  text: string,
  title: string,
}

const EmptyWidget = ({ icon, title, text }: Props) => (
  <div className={styles.widget}>
    <EmptyListIcon className={styles.icon} id={icon} />
    <h2 className={styles.title}>{text || title}</h2>
  </div>
)

export default EmptyWidget
