// @flow

import React, { useState, useEffect } from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import classnames from 'classnames'
import { compose } from 'redux'
import { connect } from 'react-redux'

import Button from '../Button'
import Option from '../RequestSettings/Option'
import {
  getOption,
  isDefaultOption,
} from '../RequestSettings/RequestSettings.utils'
import { SETTINGS_CORPORATE_SAVE_CONFIG } from '../SettingsCorporate/SettingsCorporate.actionTypes'
import NewSelectSimple from '../NewSelectSimple'
import InputField from '../InputField'

const DEFAULT_DELAY = 14
const DELAY_MIN = 1
const DELAY_MAX = 365

const AUTO_ARCHIVE_OPTION = 'autoArchive'

const ArchivedBuildingsSettings = props => {
  const {
    ukConfig: {
      auto_archive_building_when_contract_ends: autoArchiveBuilding,
      auto_archive_building_when_contract_ends_delay: autoArchiveBuildingDelay,
    },
  } = props

  const { t } = useTranslation('Settings')

  const options = [
    { value: false, label: t('DisableFreeRegistration') },
    { value: true, label: t('EnableFreeRegistration') },
  ]

  const defaultArchiveOption = getOption(options, autoArchiveBuilding)

  const defaultArchiveDelay = autoArchiveBuildingDelay || DEFAULT_DELAY

  const [archiveBuildingOption, setArchiveBuildingOption] =
    useState(defaultArchiveOption)

  const [archiveBuildingDelay, setArchiveBuildingDelay] =
    useState(defaultArchiveDelay)

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const { value: prevArchiveBuildingOption } = archiveBuildingOption

    if (autoArchiveBuilding !== prevArchiveBuildingOption) {
      setArchiveBuildingOption(getOption(options, archiveBuildingOption))
    }

    if (autoArchiveBuildingDelay != archiveBuildingDelay) {
      setArchiveBuildingDelay(autoArchiveBuildingDelay)
    }

    setLoading(false)
  }, [autoArchiveBuilding, autoArchiveBuildingDelay])

  const handleChange = label => option => {
    switch (label) {
      case AUTO_ARCHIVE_OPTION:
        setArchiveBuildingOption(option)
        break
      default:
        return null
    }
  }

  const handleSave = () => {
    setLoading(true)

    props.dispatch({
      type: SETTINGS_CORPORATE_SAVE_CONFIG,
      params: {
        auto_archive_building_when_contract_ends: archiveBuildingOption.value,
        auto_archive_building_when_contract_ends_delay:
          archiveBuildingOption.value ? archiveBuildingDelay : undefined,
      },
    })
  }

  const handleCancel = () => {
    setArchiveBuildingOption(defaultArchiveOption)
    setArchiveBuildingDelay(defaultArchiveDelay)
  }

  const sectionClass = classnames(
    'settings-personal unit unit--default settings-regional',
    {
      'working-overlay': loading,
    }
  )

  const submitSectionClass = classnames('settings-personal__submit', {
    'working-overlay': loading,
  })

  const isDefaultOptions = () =>
    isDefaultOption(defaultArchiveOption, archiveBuildingOption) &&
    (!archiveBuildingOption.value ||
      archiveBuildingDelay == defaultArchiveDelay)

  const getBuildingDelayError = () => {
    if (archiveBuildingOption.value) {
      if (!archiveBuildingDelay) {
        return t('ArchiveRequestsDueRequired')
      }

      if (
        archiveBuildingDelay < DELAY_MIN ||
        archiveBuildingDelay > DELAY_MAX
      ) {
        return t('ArchiveBuildingsDueError')
      }

      if (archiveBuildingDelay != Math.floor(archiveBuildingDelay)) {
        return t('ArchiveRequestsDueInteger')
      }

      return null
    } else {
      return null
    }
  }

  return (
    <div>
      <section className={sectionClass}>
        <h2 className='unit__title'>{t('ArchiveBuildingsSectionTitle')}</h2>

        <Option label={t('ArchiveBuildings')}>
          <NewSelectSimple
            name='auto-archive-building'
            value={archiveBuildingOption}
            options={options}
            onChange={handleChange(AUTO_ARCHIVE_OPTION)}
          />
        </Option>
        {archiveBuildingOption.value && (
          <Option label={t('ArchiveBuildingsDue')}>
            <InputField
              value={archiveBuildingDelay}
              error={getBuildingDelayError()}
              type='number'
              name='auto-archive-building-delay'
              placeholder={t('ArchiveBuildingsDue')}
              onChange={e => setArchiveBuildingDelay(e.target.value)}
            />
          </Option>
        )}

        <div className={submitSectionClass}>
          <Button.Save
            type='button'
            disabled={loading || getBuildingDelayError() || isDefaultOptions()}
            onClick={handleSave}
          >
            {props.t('Common:Save')}
          </Button.Save>
          <Button.Cancel
            type='button'
            disabled={loading || isDefaultOptions()}
            onClick={handleCancel}
          >
            {props.t('Common:Cancel')}
          </Button.Cancel>
        </div>
      </section>
    </div>
  )
}

export default compose(
  connect(),
  withTranslation('Settings')
)(ArchivedBuildingsSettings)
