//@flow

import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'
import classnames from 'classnames'
import { useThemeColor } from '../../../hooks'
import styles from './PrintMailThreadPreview.module.scss'

import { useSelector } from 'react-redux'
import { getUser } from '../../../utils/commonSelectors'
import { getFullDateFormat } from '../../../utils/commonSelectors'
import { getHumanFileSize } from '../../../components/Files/Files.utils'
import { getFileType, IMAGE_TYPE } from '../../../utils/file'
import { getUserNameAndEmail } from './NewMailThread.utils'

import api from '../../../core/api'

import Message from '../../../components/Mail/MailMessageFull/Message'

import Logo from '../../../components/Logo'
import DateTime from '../../../components/DateTime'
import { FileIcon } from '../../../components/Icon'

type Props = {
  messageCount?: number,
  messages: Array<Object>,
  messageUuid?: string,
  outbound?: string,
  title: string,
  working: boolean,
}

const INTERVAL = 350

const PrintMailThreadPreview = (props: Props): Node => {
  const {
    messageCount,
    messages,
    title,
    messageUuid,
    outbound,
    working,
    threadSubject = '',
  } = props
  const { t } = useTranslation('Mail')
  const user = useSelector(getUser)
  const dateFormat = useSelector(getFullDateFormat)
  const themeColor = useThemeColor()
  const { name, second_name, email } = user

  const titleSubStyle = classnames({
    [styles.titleSub]: threadSubject,
  })

  const timerRef = useRef()

  useEffect(() => {
    document.body.style.height = 'unset'

    return () => {
      clearTimeout(timerRef.current)
    }
  }, [])

  useEffect(() => {
    if (!working) {
      setTimeout(() => window.print(), 100)
    }
  }, [working])

  const downloadFile = id => {
    api.file.getById(id, { outbound }).then(res => {
      if (res.checking) {
        timerRef.current = setTimeout(downloadFile, INTERVAL)
      } else {
        window.open(res)
      }
    })
  }

  const openImage = file => window.open(file.origin)

  return (
    <div className={styles.body} style={{ '--themeColor': themeColor }}>
      <div className={styles.header}>
        <div className={styles.logo}>
          <Logo />
        </div>
        <div className={styles.user}>
          {name} {second_name} [{email}]
        </div>
      </div>
      <hr />
      <div className={styles.title}>
        {threadSubject && <div>{threadSubject}</div>}
        <div className={titleSubStyle}>{title}</div>
      </div>
      {!messageUuid && (
        <div className={styles.count}>
          {messageCount} {t('MailThreadTitle').toLowerCase()}{' '}
        </div>
      )}
      {messages
        .filter(m => (messageUuid ? m.uuid === messageUuid : true))
        .map(message => (
          <div className={styles.message} key={message.uuid}>
            <hr />
            <div className={styles.messageHeader}>
              <div className={styles.fromTo}>
                <b>{message.sender_display_name || message.sender_email}</b> [{' '}
                {message.sender_email} ]
                <br />
                {t('MessageTo')}:{' '}
                {message.participants.to
                  .map(u => getUserNameAndEmail(u))
                  .join(', ')}
                {!!message.participants.cc.length && (
                  <div>
                    <span>CC:</span>{' '}
                    {message.participants.cc
                      .map(u => getUserNameAndEmail(u))
                      .join(', ')}
                  </div>
                )}
                {!!message.participants.bcc.length && (
                  <div>
                    <span>BCC:</span>{' '}
                    {message.participants.bcc
                      .map(u => getUserNameAndEmail(u))
                      .join(', ')}
                  </div>
                )}
              </div>
              <div className={styles.dateTime}>
                <DateTime dateTime={message.created} dateFormat={dateFormat} />
              </div>
            </div>
            <div className={styles.content}>
              <Message text={message.text} />
              {message.files && message.files.length > 0 && (
                <div className={styles.documents}>
                  <hr />
                  {message.files.length > 1 && (
                    <b>{`${message.files.length} ${t('Attachments')}`} </b>
                  )}
                  {message.files.map(file => {
                    const fileType = getFileType(file)

                    return (
                      <div className={styles.document} key={file.id}>
                        <div
                          className={
                            fileType === IMAGE_TYPE ? styles.image : styles.icon
                          }
                        >
                          {fileType === IMAGE_TYPE ? (
                            <img
                              src={file.thumbnail}
                              onClick={() => openImage(file)}
                            />
                          ) : (
                            <FileIcon file={file} />
                          )}
                        </div>
                        <div className={styles.desc}>
                          <b
                            style={{
                              cursor:
                                fileType === IMAGE_TYPE ? 'default' : 'pointer',
                            }}
                            onClick={() =>
                              fileType === IMAGE_TYPE
                                ? {}
                                : downloadFile(file.id)
                            }
                          >
                            {file.filename}
                          </b>
                          <br />
                          {getHumanFileSize(file.size)}
                        </div>
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
          </div>
        ))}
    </div>
  )
}

export default PrintMailThreadPreview
