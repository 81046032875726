// @flow

import * as actions from './RequestConstructorBlock.actionTypes'

const initialState = {
  initiated: false,
  data: {},
  working: false,
  removed: false,
  error: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.REQUEST_CONSTRUCTOR_INITIATE_BLOCK: {
      return {
        ...initialState,
        working: true
      }
    }
    case actions.REQUEST_CONSTRUCTOR_BLOCK_WAS_INITIATED:
      return {
        ...state,
        data: action.data,
        initiated: true,
        working: false
      }
    case actions.REQUEST_CONSTRUCTOR_RENAME_BLOCK:
    case actions.REQUEST_CONSTRUCTOR_REMOVE_BLOCK:
      return {
        ...state,
        working: true,
        error: false
      }
    case actions.REQUEST_CONSTRUCTOR_BLOCK_WAS_RENAMED:
      return {
        ...state,
        data: { ...state.data, ...action.data },
        working: false
      }
    case actions.REQUEST_CONSTRUCTOR_BLOCK_WAS_REMOVED:
      return {
        ...state,
        removed: true
      }
    case actions.REQUEST_CONSTRUCTOR_BLOCK_RESET:
      return initialState
    case actions.REQUEST_CONSTRUCTOR_BLOCK_ERROR:
      return {
        ...state,
        working: false,
        error: true
      }
    default:
      return state
  }
}
