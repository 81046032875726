import React from 'react'
import { useTranslation } from 'react-i18next'

import { Cell } from '../../../Block'

const ProfileRole = () => {
  const { t } = useTranslation('Profile')

  return (
    <Cell title={t('RoleTitle')}>
      <span>{t('ExternalContactRole')}</span>
    </Cell>
  )
}

export default ProfileRole
