// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'

import { Row } from '../../Block'
import { TICKER_TYPE } from '../Post.constants'

const PostInfoType = props => {
  const { t } = useTranslation('Post')
  const { postType } = props

  const postTypeText = TICKER_TYPE === postType ? t('Ticker') : t('Post')

  return (
    <Row title={t('PostType')} onEdit={props.onEdit}>
      <span className='bar__cell-value'>{postTypeText}</span>
    </Row>
  )
}

export default PostInfoType
