// @flow

import React from 'react'
import { useEffect, useRef, useState } from 'react'
import classnames from 'classnames'
import { useLocation } from 'react-router-dom'
import { parse, stringify } from 'qs'
import { omit } from 'lodash-es'

import BrowserStorage from '../../../utils/browserStorage'
import SubListItem from '../MenuSubListItem'
import MenuFilters from '../MenuFilters'
import MenuExpandedFilters from '../MenuExpandedFilters'
import { useUser } from '../../../hooks/useUser'
import { getUserId } from '../../../utils/utils'

import styles from './MenuSubList.module.scss'

const MenuSubList = props => {
  const {
    activeName,
    collapsed,
    isPseudo,
    filterItems,
    menuItems,
    hasSub,
    expandedFilterItems,
    menuName,
    search,
    url,
    currentTab,
  } = props

  const location = useLocation()
  const user = useUser()
  const userId = getUserId(user)

  const pathnameRef = useRef()

  const [isMounted, setMounted] = useState(false)
  const [isChanged, setChanged] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  useEffect(() => {
    if (pathnameRef.current !== location.pathname) {
      setChanged(true)
      pathnameRef.current = location.pathname
    } else {
      setChanged(false)
    }
  })

  useEffect(() => {
    if (!userId || !isMounted) {
      return
    }

    if (!location.search || isChanged) {
      return
    }

    const processedSearch = stringify(
      omit(parse(location.search, { ignoreQueryPrefix: true }), [
        'ordering',
        'page',
      ])
    )

    BrowserStorage.set(
      `${userId}_${location.pathname}`,
      `${location.pathname}?${processedSearch}`
    )
  }, [location.search])

  const contClass = classnames(styles.container, {
    [styles.collapsed]: collapsed,
  })

  return (
    <div className={contClass}>
      {hasSub && (
        <ul className={styles.list}>
          {menuItems.map(item => (
            <SubListItem
              item={item}
              active={item.name === activeName}
              key={item.name}
              menuName={menuName}
              search={search}
              url={url}
              isPseudo={isPseudo}
              currentTab={currentTab}
            />
          ))}
        </ul>
      )}
      {filterItems && (
        <MenuFilters
          filterItems={filterItems}
          menuName={menuName}
          hasSub={hasSub}
        />
      )}
      {expandedFilterItems && (
        <MenuExpandedFilters
          expandedFilterItems={expandedFilterItems}
          menuName={menuName}
        />
      )}
    </div>
  )
}

export default MenuSubList
