// @flow

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Joi from 'joi-browser'
import validation from 'react-validation-mixin'
import strategy from 'joi-browser-validation-strategy'
import classnames from 'classnames'

import SelectBuilding from '../../Select/SelectAsync'
import * as actions from './AddFlatPopup.actionTypes'

type Props = {
  address: string,
  buildingId: number,
  clearValidations: () => void,
  dispatch: () => void,
  error: boolean,
  getValidationMessages: () => void,
  handleValidation: () => void,
  isValid: () => void,
  onHide: () => void,
  t: string => string,
  updateField: () => void,
  validate: () => void,
}
type State = {
  building: string,
  frontDoor: string,
  number: string,
}

class AddFlatPopup extends Component<Props, State> {
  constructor(props) {
    super(props)

    this.state = {
      number: '',
      frontDoor: '',
      building: '',
    }

    this.validationMsg = {
      language: {
        any: {
          required: `!! ${this.props.t('"Field" is not allowed to be empty')}`,
          empty: `!! ${this.props.t('"Field" is not allowed to be empty')}`,
        },
        number: {
          base: `!! ${this.props.t('"Field" is not allowed to be empty')}`,
        },
      },
    }

    this.validatorTypes = {
      number: Joi.string().options(this.validationMsg).required(),
      building: Joi.number().options(this.validationMsg).required(),
    }
  }

  componentDidMount() {
    window.addEventListener('keydown', this.listenForKeyPress, true)
    document.body.style.overflowY = 'hidden'
    this.props.dispatch({ type: actions.FLAT_POPUP_INIT })
  }

  componentDidUpdate(prevProps) {
    const { error } = prevProps

    if (!this.props.error && error) {
      this.props.dispatch({ type: actions.FLAT_POPUP_INIT })
      this.props.onHide()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.listenForKeyPress, true)
    document.body.style.overflowY = 'scroll'
  }

  onSubmit = () => {
    const { buildingId, params } = this.props
    const building = buildingId || this.state.building
    const entrance = this.frontDoor.value
    const number = this.number.value

    const onValidate = error => {
      if (error) {
        console.error('form error', error)
      } else {
        this.props.dispatch({
          type: actions.FLAT_POPUP_ADD_FLAT,
          flat: { building, number, entrance },
          params,
        })
      }
    }

    this.props.validate(onValidate)
  }

  onChange = field => event => {
    const value = event.target.value
    this.props.updateField(field, value)
  }

  getClasses = (defaultCls, field) =>
    classnames(defaultCls, {
      'input--error': !this.props.isValid(field),
    })

  getValidatorData = () => ({
    number: this.number.value.trim(),
    building: this.props.buildingId || this.state.building,
  })

  listenForKeyPress = event => {
    if (event.key === 'Escape' || event.keyCode === 27) {
      this.props.onHide()
    } else if (event.key === 'Enter' || event.keyCode === 13) {
      this.onSubmit()
    }
  }

  updateBuilding = building => {
    this.setState({ building: building.id })
    this.props.clearValidations()
  }

  renderFldError = error =>
    error ? (
      <div className='input__msg measure-popup__error_msg'>{error}</div>
    ) : null

  renderNumberItem = () => {
    const key = 'number'
    const { handleValidation, getValidationMessages, isValid, t } = this.props

    return (
      <div
        className='m-a10__row'
        style={{ marginBottom: !isValid(key) && '4rem' }}
      >
        <div className='m-a10__cell m-a10__cell_1'>
          <span className='m-a10__label'>{t('FlatNumber')}</span>
        </div>
        <div className={this.getClasses('m-a10__cell m-a10__cell_3', key)}>
          <div className='input input--medium input--block input--default'>
            <input
              autoFocus
              name='flat_number'
              type='text'
              ref={ref => {
                this.number = ref
              }}
              onBlur={() => handleValidation(key)}
              onChange={() => handleValidation(key)}
            />
          </div>
          {this.renderFldError(getValidationMessages(key))}
        </div>
      </div>
    )
  }

  renderFrontDoorItem = () => {
    const key = 'frontDoor'
    const { handleValidation, getValidationMessages, t } = this.props

    return (
      <div className='m-a10__row'>
        <div className='m-a10__cell m-a10__cell_1'>
          <span className='m-a10__label'>{t('EntranceNumber')}</span>
        </div>
        <div className={this.getClasses('m-a10__cell m-a10__cell_3', key)}>
          <div className='input input--medium input--block input--default'>
            <input
              type='text'
              ref={ref => {
                this.frontDoor = ref
              }}
              onBlur={() => handleValidation(key)}
              onChange={() => handleValidation(key)}
            />
          </div>
          {this.renderFldError(getValidationMessages(key))}
        </div>
      </div>
    )
  }

  renderSelectBuilding = () => {
    const { buildingId, address, isValid, getValidationMessages } = this.props

    return (
      <div
        className={this.getClasses('m-a06__row', 'building')}
        style={{
          marginTop: '1rem',
          marginBottom: !this.props.isValid('building') ? '3rem' : '1rem',
          maxWidth: '50rem',
        }}
      >
        <div className='input input--medium input--default input--block'>
          {buildingId ? (
            <div
              className='m-a10__label'
              style={{ color: '#000', marginBottom: '20px' }}
            >
              {address}
            </div>
          ) : (
            <SelectBuilding
              apiFn='building.getListTiny'
              id='add-flat-building'
              className='bar__userbar'
              inputError={!isValid('building')}
              params={{ is_promo: 0 }}
              labelKey='address'
              placeholder='FindAddress'
              onChange={this.updateBuilding}
            />
          )}
        </div>
        {this.renderFldError(getValidationMessages('building'))}
      </div>
    )
  }

  renderServerError(error) {
    if (!error) {
      return null
    }

    const { t } = this.props

    return typeof error.errors === 'string'
      ? t(`Errors:${error.errors}`)
      : Object.keys(error.errors).map(field =>
          t(`Errors:${error.errors[field]}`)
        )
  }

  render() {
    const { t, onHide, error } = this.props
    const serverValidationError = this.renderServerError(error)

    return (
      <div>
        <div className='modal__title'>{t('AddFlat')}</div>
        <button className='modal__close' type='button' onClick={onHide} />

        <div className='modal__body'>
          {error && (
            <div
              className='m-a06__row input--error m-a10__label'
              style={{ marginTop: '1rem' }}
            >
              <div className='input__msg'>{serverValidationError}</div>
            </div>
          )}
          {this.renderSelectBuilding()}
          {this.renderNumberItem()}
          {this.renderFrontDoorItem()}
        </div>

        <div className='modal__submit'>
          <button
            className='button button--large button--success'
            type='button'
            onClick={this.onSubmit}
          >
            {t('Add')}
          </button>
          <button
            className='button button--large button--danger-3'
            type='button'
            onClick={onHide}
          >
            {t('Cancel')}
          </button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state.addFlatPopup,
})

export default compose(
  connect(mapStateToProps),
  withTranslation('Flat'),
  validation(strategy)
)(AddFlatPopup)
