// @flow

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { partial } from 'lodash-es'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import Button from '../../Button'
import Modal from '../../Modal'
import ModalBody from '../../Modal/ModalBody'
import ModalButtons from '../../Modal/ModalButtons'
import ModalCloseButton from '../../Modal/ModalCloseButton'
import ModalHead from '../../Modal/ModalHead'
import ModalRow from '../../Modal/ModalRow'
import { useThemeColor } from '../../../hooks'
import {
  createActivityType,
  getActivityTypeIcons,
  updateActivityType,
} from '../../../core/api/api.pipeline'
import { getUk } from '../../../utils/commonSelectors'
import { useOverflow } from '../../../hooks/useOverflow'
import InlineSvg from '../../InlineSvg'

import styles from './ActivityTypeModal.module.scss'
import InputField from '../../InputField'

const ActivityTypeModal = props => {
  const { t } = useTranslation('Activities')

  const { editingActivityType } = props

  const [icons, setIcons] = useState(null)

  const { name, id, icon } = editingActivityType || {}

  const { id: uk } = useSelector(getUk)

  const themeColor = useThemeColor()

  useOverflow()

  useEffect(() => {
    getActivityTypeIcons().then(data => {
      setIcons(data.results.objects)
    })
  }, [])

  const formik = useFormik({
    validateOnChange: true,
    enableReinitialize: true,
    initialValues: {
      name: name || '',
      icon: (icon && icon.id) || (icons && icons[0].id) || '',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .max(128, t('MaxTitleLength', { maxLength: 128 }))
        .required(t('Common:FieldIsRequired')),
      icon: Yup.number().required(t('Common:FieldIsRequired')),
    }),
    onSubmit: submitValues => {
      const api = id ? partial(updateActivityType, id) : createActivityType

      api({ name: submitValues.name, icon: submitValues.icon, uk }).then(() => {
        props.onClose()
        props.load()
      })
    },
  })

  useEffect(() => {
    return function cleanup() {
      props.setEditingActivityType(null)
    }
  }, [])

  const handleSetIcon = id => {
    formik.setFieldValue('icon', id)
  }

  if (!icons) {
    return null
  }

  const title = editingActivityType
    ? 'EditActivityTypeTitle'
    : 'CreateActivityTypeTitle'

  return (
    <Modal isOpen className={styles.modal} onRequestClose={props.onClose}>
      <ModalHead title={t(title)} />
      <ModalCloseButton onClose={props.onClose} />
      <form onSubmit={formik.handleSubmit}>
        <ModalBody>
          <ModalRow>
            <div className={styles.form}>
              <label className={styles.title} htmlFor='name'>
                {t('ActivityTypeName')}
              </label>
              <InputField
                autoFocus
                error={formik.errors['name']}
                value={formik.values.name}
                id='name'
                name={'name'}
                placeholder={t('ActivityTypeNamePlaceholder')}
                onChange={formik.handleChange}
              />
            </div>
          </ModalRow>
          <ModalRow>
            <div>
              <span className={styles.title}>{t('ActivityTypeIcon')}</span>
              <div
                className={styles['icon-box']}
                style={{ '--themeColor': themeColor }}
              >
                {icons.map(icon => {
                  return (
                    <div
                      key={icon.id}
                      id={icon.id}
                      className={
                        formik.values.icon === icon.id ? styles.active : ''
                      }
                      onClick={() => handleSetIcon(icon.id)}
                    >
                      <InlineSvg url={icon.origin} />
                    </div>
                  )
                })}
              </div>
            </div>
          </ModalRow>
        </ModalBody>
        <ModalButtons>
          <Button.Save type='submit' disabled={!formik.dirty}>
            {t('Common:Save')}
          </Button.Save>
          <Button.Cancel disabled={!formik.dirty} onClick={formik.handleReset}>
            {t('Common:Cancel')}
          </Button.Cancel>
        </ModalButtons>
      </form>
    </Modal>
  )
}

export default ActivityTypeModal
