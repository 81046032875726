// @flow

import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import { useSelector } from 'react-redux'

import Loader from '../../Loader'
import BottomPagination from '../../Pagination/BottomPagination'
import CompanyTeamItem from './CompanyTeamItem'
import { getListTiny } from '../../../core/api/api.profile'
import { ACTIVE_STATUS, USER_GROUPS } from '../../../constants'
import { getUkConfig, getUser } from '../../../utils/commonSelectors'

import styles from './CompanyTeam.module.scss'

type Props = {
  onInit: () => void,
}

const CompanyTeam = (props: Props) => {
  const [users, setUsers] = useState([])
  const [working, setWorking] = useState(true)
  const [meta, setMeta] = useState(null)
  const [page, setPage] = useState(null)

  const { t } = useTranslation('Team')

  const nodeToScroll = useRef()

  const { only_building_managers_in_team } = useSelector(getUkConfig)
  const { group } = useSelector(getUser)

  const handlePage = (page, nodeToScroll) => {
    setWorking(true)
    setPage(page)

    if (nodeToScroll) {
      nodeToScroll.current.scrollIntoView()
    }
  }

  const params = Object.assign(
    {},
    only_building_managers_in_team && group === USER_GROUPS.dweller
      ? { building_managers_only: true }
      : undefined
  )

  useEffect(() => {
    getListTiny({
      page_size: 21,
      group: ['manager', 'admin'],
      all: true,
      page,
      status: ACTIVE_STATUS,
      ...params,
    })
      .then((data: Object) => {
        setUsers(data.results.objects)
        setMeta(data.meta)
      })
      .finally(() => {
        setWorking(false)
        props.onInit()
      })
  }, [page])

  const sectionClass = classnames(
    'cprofile-view-team unit unit--default',
    styles.section
  )

  return (
    <section className={sectionClass} ref={nodeToScroll}>
      <h2 className='unit__title'>{t('title')}</h2>
      <p className='cprofile-view-team__text' />
      {working ? (
        <Loader text={false} type='big' />
      ) : (
        <div>
          <div className='cprofile-view-team__list'>
            {users.map(user => (
              <CompanyTeamItem key={user.id} user={user} />
            ))}
          </div>
          <BottomPagination
            classes='paginator-2'
            setPage={handlePage}
            meta={meta}
            nodeToScroll={nodeToScroll}
          />
        </div>
      )}
    </section>
  )
}

export default CompanyTeam
