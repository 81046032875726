// @flow

import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import companyLogo from '../components/CompanyLogo/CompanyLogo.reducer'
import filesUpload from '../containers/FilesUpload/FilesUpload.reducer'
import layout from '../components/Layout/Layout.reducer'
import postCreating from '../components/Post/PostCreating/PostCreating.reducer'
import init from '../components/init/Init.reducer'
import counters from '../components/init/counters.reducer'
import login from '../components/Auth/Login/Login.reducer'
import headerSearch from '../components/HeaderSearch/HeaderSearch.reducer'
import requestView from '../containers/RequestView/RequestView.reducer'
import requestCreate from '../components/RequestCreate/RequestCreate.reducer'
import requestList from '../components/Request/RequestList/RequestList.reducer'
import requestStatus from '../components/modals/RequestStatus/RequestStatus.reducer'
import flatDescription from '../components/Flat/FlatDescription/FlatDescription.reducer'
import flatDwellers from '../components/Flat/FlatDwellers/FlatDwellers.reducer'
import flatList from '../components/Flat/FlatList/FlatList.reducer'
import flatView from '../containers/FlatView/FlatView.reducer'
import residentList from '../components/Residents/ResidentList/ResidentList.reducer'
import companyView from '../components/CompanyView/CompanyView.reducer'
import like from '../components/Like/Like.reducer'
import widgetMayKnowReducer from '../components/widgets/WidgetMayKnow/WidgetMayKnow.reducer'
import WidgetFilesReducer from '../components/widgets/WidgetFiles/WidgetFiles.reducer'
import WidgetParticipantsListReducer from '../components/widgets/WidgetParticipantsList/WidgetParticipantsList.reducer'
import widgetLastReg from '../components/widgets/WidgetLastReg/WidgetLastReg.reducer'
import profile from '../containers/Profile/Profile.reducer'
import profileInfo from '../components/Profile/ProfileInfo/ProfileInfo.reducer'
import profileContacts from '../components/Profile/ProfileContacts/ProfileContacts.reducer'
import publicBuildingPage from '../containers/PublicBuildingPage/PublicBuildingPage.reducer'
import ImportFilesTableReducer from '../components/ImportFilesTable/ImportFilesTable.reducer'
import ImportMapTableReducer from '../components/ImportMapTable/ImportMapTable.reducer'
import selectAsync from '../components/Select/SelectAsync/SelectAsync.reducer'
import selectStatus from '../components/Select/SelectStatus/SelectStatus.reducer'
import selectChatMember from '../components/Select/SelectChatMember/SelectChatMember.reducer'
import addFlatPopup from '../components/modals/AddFlatPopup/AddFlatPopup.reducer'
import resendInvitePopUp from '../components/modals/ResendInvitePopUp/ResendInvitePopUp.reducer'
import addManagerPopUp from '../components/modals/AddManagerPopUp/AddManagerPopUp.reducer'
import userPopup from '../components/modals/UserPopup/UserPopup.reducer'

import notificationList from '../components/Notification/NotificationList/NotificationList.reducer'
import buildingList from '../components/Building/BuildingList/BuildingList.reducer'
import buildingCreate from '../components/BuildingCreate/BuildingCreate.reducer'
import buildingView from '../components/BuildingView/BuildingView.reducer'
import buildingFlats from '../components/Building/BuildingFlats/BuildingFlats.reducer'
import buildingDwellers from '../components/Building/BuildingDwellers/BuildingDwellers.reducer'

import postView from '../components/PostView/PostView.reducer'
import postBody from '../components/Post/PostBody/PostBody.reducer'

import userListInfo from '../components/User/UserListInfo/UserListInfo.reducer'
import userList from '../components/User/UserList/UserList.reducer'
import settingsCorporate from '../components/SettingsCorporate/SettingsCorporate.reducer'
import settingsPersonal from '../components/SettingsPersonal/SettingsPersonal.reducer'
import settingsUsers from '../containers/SettingsUsers/SettingsUsers.reducer'
import confirmEmail from '../containers/ConfirmEmail/ConfirmEmail.reducer'
import residents from '../components/Residents/Residents.reducer'
import friends from '../components/Friends/Friends.reducer'
import friendsNotifications from '../components/FriendsNotifications/FriendsNotifications.reducer'
import friendsRequests from '../components/FriendsRequests/FriendsRequests.reducer'
import messages from '../containers/Messages/Messages.reducer'
import threadsList from '../containers/Mail/ThreadsList.reducer'
import mailThread from '../containers/Mail/MailThread/MailThread.reducer'
import chatroom from '../containers/Chatroom/Chatroom.reducer'
import usersuggest from '../components/UserSuggest/UserSuggest.reducer'
import checklists from '../components/Checklist/Checklist.reducer'
import requestTranslate from '../components/Request/RequestTranslate/RequestTranslate.reducer'
import labelsPopup from '../components/modals/Labels/LabelsPopup.reducer'
import companyRegistration from '../components/CompanyRegistration/CompanyRegistration.reducer'
import convertToRequestPopup from '../components/modals/ConvertToRequestPopup/ConverToRequestPopup.reducer'
import attachToRequestPopup from '../components/modals/AttachToRequestPopup/AttachToRequestPopup.reducer'
import demoDataStripe from '../components/WarningStripe/WarningStripe.reducer'
import mailSearch from '../components/MailSearch/MailSearch.reducer'
import igel from '../containers/Integrations/Igel/Igel.reducer'
import realData from '../containers/Integrations/RealData/RealData.reducer'
import addRequestCategoryPopup from '../components/modals/AddRequestCategoryPopup/AddRequestCategoryPopup.reducer'
import requestConstructor from '../containers/RequestConstructor/RequestConstructor.reducer'
import requestConstructorBlock from '../containers/RequestConstructorBlock/RequestConstructorBlock.reducer'
import requestConstructorCards from '../containers/RequestConstructorCards/RequestConstructorCards.reducer'
import companyBackground from '../components/Company/CompanyBackground/CompanyBackground.reducer'
import sharedRequest from '../components/SharedRequest/SharedRequest.reducer'
import FAQ from '../components/FAQ/Faq.reducer'
import menu from '../components/Menu/Menu.reducer'
import requestFeedback from '../components/Request/RequestFeedback/RequestFeedback.reducer'
import feedback from '../containers/Feedback/Feedback.reducer'
import widget from '../containers/Widget/Widget.reducer'
import activitiesWidget from '../components/widgets/ActivitiesWidget/ActivitiesWidget.reducer'
import marketplaceSettings from '../containers/MarketplaceSettings/MarketplaceSettings.reducer'
import newsList from '../containers/NewsList/NewsList.reducer'
import audienceList from '../components/modals/AudienceList/AudienceList.reducer'
import post from '../components/Post/Post.reducer'
import mailsList from '../containers/Mail/MailsList/MailsList.reducer'
import translations from '../components/translations/translations.reducer'
import notFoundPage from '../containers/NotFoundPage/NotFoundPage.reducer'

// !!! Please, keep alphabetical order !!!
export default history =>
  combineReducers({
    activitiesWidget,
    addFlatPopup,
    addManagerPopUp,
    addRequestCategoryPopup,
    attachToRequestPopup,
    audienceList,
    buildingCreate,
    buildingDwellers,
    buildingFlats,
    buildingList,
    buildingView,
    chatroom,
    checklists,
    companyBackground,
    companyLogo,
    companyRegistration,
    companyView,
    confirmEmail,
    convertToRequestPopup,
    counters,
    demoDataStripe,
    FAQ,
    feedback,
    filesUpload,
    flatDescription,
    flatDwellers,
    flatList,
    flatView,
    friends,
    friendsNotifications,
    friendsRequests,
    headerSearch,
    igel,
    ImportFilesTableReducer,
    ImportMapTableReducer,
    init,
    labelsPopup,
    layout,
    like,
    login,
    threadsList,
    mailsList,
    mailSearch,
    mailThread,
    marketplaceSettings,
    menu,
    messages,
    newsList,
    notificationList,
    post,
    postBody,
    postCreating,
    postView,
    profile,
    profileContacts,
    profileInfo,
    publicBuildingPage,
    realData,
    requestConstructor,
    requestConstructorBlock,
    requestConstructorCards,
    requestCreate,
    requestFeedback,
    requestList,
    requestStatus,
    requestTranslate,
    requestView,
    resendInvitePopUp,
    residentList,
    residents,
    router: connectRouter(history),
    selectAsync,
    selectChatMember,
    selectStatus,
    settingsCorporate,
    settingsPersonal,
    settingsUsers,
    sharedRequest,
    translations,
    userList,
    userListInfo,
    userPopup,
    usersuggest,
    widget,
    WidgetFiles: WidgetFilesReducer,
    widgetLastReg,
    widgetMayKnow: widgetMayKnowReducer,
    WidgetParticipantsList: WidgetParticipantsListReducer,
    notFoundPage,
  })
