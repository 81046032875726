// @flow

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { isEqual } from 'lodash-es'
import { compose } from 'redux'
import { connect } from 'react-redux'

import Domain from '../Company/Domain'
import RegionAndLang from '../Company/RegionAndLang'
import * as actions from './SettingsCorporate.actionTypes'
import CompanyTheme from '../Company/CompanyTheme/CompanyTheme'
import CompanyBackground from '../Company/CompanyBackground'
import RequestSettings from '../RequestSettings'
import CompanyLogo from '../CompanyLogo'
import CompanyLoginForm from '../CompanyLoginForm'
import CompanyRegistration from '../Company/CompanyRegistration/CompanyRegistration'
import TableConfigSettings from '../TableConfigSettings'
import { getUk } from '../../utils/commonSelectors'
import Content from '../Content'
import BudgetPlanSettings from '../BudgetPlanSettings'
import ServiceSettings from '../ServiceSettings'
import ArchivedSettings from '../ArchivedSettings'
import ArchivedBuildingsSettings from '../ArchivedBuildingsSettings'

type Props = {
  dispatch: Object => void,
  registerWorking: boolean,
  t: string => string,
  theme: Object,
  themeWorking: boolean,
  uk: Object,
  ukConfig: Object,
  url: string,
  working: boolean,
}

class SettingsCorporate extends Component<Props> {
  componentDidMount() {
    const { uk } = this.props

    this.props.dispatch({ type: actions.SETTINGS_CORPORATE_INIT, uk })
  }

  componentDidUpdate(prev: Props) {
    const { uk } = this.props

    if (uk.language_obj.id !== prev.uk.language_obj.id) {
      window.location.reload(true)
    }
  }

  saveThemeSettings = () => {
    const { theme, uk } = this.props

    if (!isEqual(theme, uk.theme)) {
      this.props.dispatch({
        type: actions.SETTINGS_CORPORATE_SAVE_THEME,
        theme,
      })
    }
  }

  saveConfigSettings = () => {
    const { ukConfig } = this.props
    const params = ukConfig // difference(ukConfig, {})

    if (params) {
      this.props.dispatch({
        type: actions.SETTINGS_CORPORATE_SAVE_CONFIG,
        params,
      })
    }
  }

  onUpdateTheme = theme => {
    this.props.dispatch({
      type: actions.SETTINGS_CORPORATE_SET_THEME,
      theme,
    })
  }

  onUpdateConfig = params => {
    this.props.dispatch({
      type: actions.SETTINGS_CORPORATE_UPDATE_CONFIG,
      params,
    })
  }

  onClickCancelUkConfig = () => {
    this.props.dispatch({
      type: actions.SETTINGS_CORPORATE_CANCEL_CONFIG,
      params: this.props.uk.config,
    })
  }

  onClickCancelTheme = () => {
    this.props.dispatch({
      type: actions.SETTINGS_CORPORATE_CANCEL_THEME,
      theme: this.props.uk.theme,
    })
  }

  updateRegionAndLang = (params: Object) => {
    const { uk } = this.props
    this.props.dispatch({
      type: actions.SETTINGS_CORPORATE_SAVE,
      id: uk.id,
      params,
    })
  }

  render() {
    const { working, uk, themeWorking, theme, ukConfig, registerWorking } =
      this.props

    return (
      <Content>
        <Domain value={uk.url} working={working} />
        <RegionAndLang
          uk={uk}
          working={working}
          onSubmit={this.updateRegionAndLang}
        />
        <CompanyLogo />
        <CompanyBackground />
        <CompanyTheme
          working={themeWorking}
          theme={theme}
          onUpdate={this.onUpdateTheme}
          onCancel={this.onClickCancelTheme}
          onSave={this.saveThemeSettings}
        />
        <CompanyLoginForm url={uk.url} />
        <CompanyRegistration
          working={registerWorking}
          config={ukConfig}
          onUpdate={this.onUpdateConfig}
          onCancel={this.onClickCancelUkConfig}
          onSave={this.saveConfigSettings}
        />
        {ukConfig.is_new_design_available && (
          <TableConfigSettings ukConfig={ukConfig} />
        )}
        <RequestSettings ukConfig={ukConfig} />
        <ArchivedBuildingsSettings ukConfig={ukConfig} />
        <ArchivedSettings ukConfig={ukConfig} />
        <BudgetPlanSettings />
        <ServiceSettings />
      </Content>
    )
  }
}

const mapStateToProps = state => ({
  ...state.settingsCorporate,
  uk: getUk(state),
})

export default compose(
  withTranslation('Company'),
  connect(mapStateToProps)
)(SettingsCorporate)
