// @flow

import styles from './EmailIntegrationModal.module.scss'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ModalButtons from '../../Modal/ModalButtons'
import Button from '../../Button'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import ModalRow from '../../Modal/ModalRow'
import ModalLabel from '../../Modal/ModalLabel'
import InputField from '../../InputField'
import ModalBody from '../../Modal/ModalBody'
import type { Node } from 'react'
import { SELECT_PROVIDER_STAGE } from './EmailIntegrationModal.constant'
import ModalHead from '../../Modal/ModalHead'
import InputPassword from '../../InputPassword'
import Icon from '../../Icon'
import classnames from 'classnames'
import ExpandArrow from '../../ExpandArrow'

type Provider = {
  icon: string,
  logIntTranslationKey: string,
  stage: string,
  translationKey: string,
}

type Props = {|
  onClose: () => void,
  provider: Provider,
  //  setErrorRequest: () => void,
  setStage: () => void,
|}

const OtherAccountModal = (props: Props): Node => {
  const { t } = useTranslation('EmailIntegrationNylas')

  const { onClose, setStage, provider } = props
  const [loading, setLoading] = useState(false)
  const [settingsOpen, setSettingsOpen] = useState(false)

  const formik = useFormik({
    validateOnBlur: true,
    validateOnChange: false,
    initialValues: { email: '', password: '' },
    validationSchema: Yup.object({
      password: Yup.string().required(t('Common:PasswordIsRequired')),
      email: Yup.string()
        .email(t('Common:EnterValidEmailErrorText'))
        .required(t('Common:FieldIsRequired')),
    }),
    onSubmit: () => {
      setLoading(true)
      setLoading(false)
    },
  })
  const passClass = classnames(
    'input input--large input--block input--default',
    {
      'input--error': !!formik.errors.password,
    }
  )
  const direction = settingsOpen ? 'bottom' : 'top'

  return (
    <>
      <ModalHead title={t('AddEmailAccount')} />
      <form onSubmit={formik.handleSubmit}>
        <ModalBody>
          <ModalRow>
            <Icon id={provider.icon} className={styles.icon} />
            <span className={styles.microsoftTitle}>
              {t(provider.logIntTranslationKey)}
            </span>
          </ModalRow>
          <ModalRow className={styles.row}>
            <ModalLabel>{t('EmailAddress')}</ModalLabel>
          </ModalRow>
          <InputField
            autoFocus
            disabled={loading}
            name='email'
            placeholder={t('AddEmailAddress')}
            value={formik.values.email}
            error={formik.errors.email}
            onChange={formik.handleChange}
          />
          <ModalRow className={styles.row}>
            <ModalLabel>{t('Password')}</ModalLabel>
          </ModalRow>
          <div className={passClass}>
            <InputPassword
              field='password'
              name='password'
              value={formik.values.password}
              placeholder={t('AddPassword')}
              onChange={formik.handleChange}
            />
            <div className='input__msg'>{formik.errors.password}</div>
          </div>
          <ModalRow className={styles.row}>
            <div
              className={styles.settings}
              onClick={() => setSettingsOpen(prev => !prev)}
            >
              <span>{t('AdvancedSettings')}</span>
              <ExpandArrow direction={direction} />
            </div>
          </ModalRow>
          {settingsOpen && (
            <>
              <ModalRow className={styles.row}>
                <div className={styles.column2}>
                  <ModalRow>
                    <ModalLabel>{t('AddImapHost')}</ModalLabel>
                  </ModalRow>
                  <InputField
                    disabled={loading}
                    name='imap'
                    placeholder={t('AddImapHost')}
                    value={formik.values.imap}
                    error={formik.errors.imap}
                    onChange={formik.handleChange}
                  />
                </div>
                <div className={styles.column1}>
                  <ModalRow>
                    <ModalLabel>{t('Port')}</ModalLabel>
                  </ModalRow>
                  <InputField
                    disabled={loading}
                    name='imapPort'
                    placeholder={t('AddPort')}
                    value={formik.values.imapPort}
                    error={formik.errors.imapPort}
                    onChange={formik.handleChange}
                  />
                </div>
              </ModalRow>
              <ModalRow className={styles.row}>
                <div className={styles.column2}>
                  <ModalRow>
                    <ModalLabel>{t('ExchangeServer')}</ModalLabel>
                  </ModalRow>
                  <InputField
                    disabled={loading}
                    name='smtp'
                    placeholder={t('AddSmtpHost')}
                    value={formik.values.smtp}
                    error={formik.errors.smtp}
                    onChange={formik.handleChange}
                  />
                </div>
                <div className={styles.column1}>
                  <ModalRow>
                    <ModalLabel>{t('Port')}</ModalLabel>
                  </ModalRow>
                  <InputField
                    disabled={loading}
                    name='imapPort'
                    placeholder={t('AddPort')}
                    value={formik.values.smtpPort}
                    error={formik.errors.smtpPort}
                    onChange={formik.handleChange}
                  />
                </div>
              </ModalRow>
            </>
          )}
        </ModalBody>
        <ModalButtons>
          <Button.Save type='submit' disabled={loading}>
            {t('Continue')}
          </Button.Save>
          <Button.Cancel onClick={onClose}>{t('Common:Cancel')}</Button.Cancel>
          <Button.Regular
            disabled={loading}
            view='desaturated'
            className={styles.anotherBtn}
            onClick={() => setStage(SELECT_PROVIDER_STAGE)}
          >
            {t('ChooseAnother')}
          </Button.Regular>
        </ModalButtons>
      </form>
    </>
  )
}

export default OtherAccountModal
