// @flow

import { ErrorMessage, Form, Formik } from 'formik'
import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import * as Yup from 'yup'

import SelectAsync from '../../components/Select/SelectAsync'
import { Row, SimpleBlock, Header } from '../../components/Block'
import Button from '../../components/Button'
import DateTime from '../../components/DateTime'
import Input from '../CreateProviderPage/Input'
import { EntityIcon } from '../../components/Icon'
import ValidationText from '../../components/ValidationText'
import { getFullDateFormat, getUser } from '../../utils/commonSelectors'
import { MARKETPLACE_ROUTES } from '../../constants'
import { USER_GROUPS } from '../../constants'

import styles from './CreateOrderPage.module.scss'

const MAX_NAME_LENGTH = 80
const FIELDS = [
  {
    name: 'name',
    type: 'text',
  },
  {
    name: 'description',
    type: 'textarea',
  },
]

const CreateOrderPageForm = props => {
  const {
    loading,
    providerName,
    dateFormat,
    providerId,
    providerAvatar,
    user,
  } = props
  const { t } = useTranslation('Orders')
  const handleSubmit = values => {
    props.onSubmit({ ...values, price: '0' })
  }

  const initialValues = {
    name: '',
    description: '',
  }
  const getErrorMessage = key => t('emptyInput', { key: t(key) })
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .max(
        MAX_NAME_LENGTH,
        t('maxLength', { key: t('name'), value: MAX_NAME_LENGTH })
      )
      .required(getErrorMessage('name')),
    description: Yup.string().required(getErrorMessage('description')),
  })

  const renderError = text => <ValidationText active text={text} />

  const goBack = e => {
    e.preventDefault()

    props.history.push(MARKETPLACE_ROUTES['marketplaceProvider'](providerId))
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {formikProps => (
        <Form className={styles['edit-header']}>
          <SimpleBlock working={loading}>
            <Header header goBack={goBack}>
              <EntityIcon large icon id='order' />
              {t('createOrder')}
            </Header>

            <Row title={t('createdAt')}>
              <div className='bar__cell-value'>
                <DateTime dateTime={new Date()} dateFormat={dateFormat} />
              </div>
            </Row>
            <Row title={t('status')} className={styles['form-item']}>
              <div className='bar__cell-value'>
                <span>{t('statusNew')}</span>
              </div>
            </Row>
            <Row title={t('providerName')}>
              <div className='bar__cell-value' style={{ margin: 0 }}>
                <img
                  className={styles.avatar}
                  src={providerAvatar}
                  alt='provider avatar'
                />
                <span>{providerName}</span>
              </div>
            </Row>
            {user && user.group !== USER_GROUPS.dweller && (
              <Row title={t('request')} working={loading}>
                <SelectAsync
                  cleanable
                  searchRequest
                  withDisableOnLoad
                  disabled={loading}
                  id='request-select'
                  apiFn='request.getListTiny'
                  params={{ wo_orders_excl: 0 }}
                  labelKey='title'
                  filterKey='search'
                  placeholder='SelectRequest'
                  onChange={props.setRequest}
                />
              </Row>
            )}
            <Row title={t('name')}>
              <Input autoFocus contact={FIELDS[0]} formikProps={formikProps} />
              <ErrorMessage name='name' render={renderError} />
            </Row>
            <Row title={t('description')}>
              <Input contact={FIELDS[1]} formikProps={formikProps} />
              <ErrorMessage name='description' render={renderError} />
            </Row>
            <div style={{ padding: '15px' }}>
              <Button.Regular
                type='button'
                disabled={loading}
                onClick={formikProps.handleSubmit}
              >
                {t('submit')}
              </Button.Regular>
            </div>
          </SimpleBlock>
        </Form>
      )}
    </Formik>
  )
}

const mapStateToProps = state => ({
  dateFormat: getFullDateFormat(state),
  user: getUser(state),
})

export default compose(
  withRouter,
  connect(mapStateToProps)
)(CreateOrderPageForm)
