// @flow

import * as flatPopupActions from '../modals/AddFlatPopup/AddFlatPopup.actionTypes'
import * as actions from './BuildingView.actionTypes'

const initialState = {
  initiated: false,
  working: false,
  data: {},
  modal: null,
  collapse: {},
  error: null,
  reload: false,
  reloadFlat: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.BUILDING_VIEW_INITIATING:
    case actions.BUILDING_VIEW_STATE_RESET:
      return initialState
    case actions.BUILDING_VIEW_INITIATED:
      return {
        ...state,
        initiated: true,
        data: action.data,
      }
    case actions.BUILDING_VIEW_NEED_UPDATE:
    case actions.BUILDING_VIEW_VIRTUAL_FLAT_NEED_UPDATE:
      return {
        ...state,
        error: null,
        working: true,
      }
    case actions.BUILDING_VIEW_UPDATED:
      return {
        ...state,
        working: false,
        data: action.data,
      }
    case actions.BUILDING_VIEW_VIRTUAL_FLAT_UPDATED:
      return {
        ...state,
        working: false,
        flat: {
          ...state.flat,
          ...action.flat,
        },
      }
    case actions.BUILDING_VIEW_RELOAD:
      return {
        ...state,
        reloadFlat: true,
        reloadDweller: true,
      }
    case actions.BUILDING_VIEW_SHOW_MODAL:
      return {
        ...state,
        modal: action.modal,
      }
    case flatPopupActions.FLAT_POPUP_ADDED_FLAT:
    case actions.BUILDING_VIEW_HIDE_MODAL:
      return {
        ...state,
        modal: null,
      }
    case actions.BUILDING_VIEW_TOGGLE_COLLAPSE: {
      const { section } = action

      return {
        ...state,
        collapse: {
          ...state.collapse,
          [section]: !state.collapse[section],
        },
      }
    }
    case actions.BUILDING_VIEW_ERROR:
      return {
        ...state,
        initiated: true,
        working: false,
        error: action.error,
      }
    default:
      return state
  }
}
