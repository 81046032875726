// @flow

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { get, map } from 'lodash-es'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import classNames from 'classnames'

import { useOverflow } from '../../../../hooks/useOverflow'
import Button from '../../../Button'
import ModalBody from '../../../Modal/ModalBody'
import ModalRow from '../../../Modal/ModalRow/ModalRow'
import ModalCloseButton from '../../../Modal/ModalCloseButton'
import ModalButtons from '../../../Modal/ModalButtons'
import ModalHead from '../../../Modal/ModalHead'
import NewSelectAsync from '../../../NewSelectAsync'
import {
  connectRequest,
  getRequestListLight,
  getUnrelatedRequestList,
  getUnrelatedRequestListCount,
} from '../../../../core/api/api.request'
import { useSelected } from '../../../../hooks'
import { REQUEST_LIST_LOAD } from '../RequestList.actionTypes'
import { isStaffUser } from '../../../../utils/utils'
import { useUser } from '../../../../hooks/useUser'
import { getCurrentFilters } from '../../../../utils/routing'
import { getExtendedFilters, getOwnerFilter } from '../RequestList.utils'
import ConfirmationPopup from '../../../modals/ConfirmationPopup'
import Modal from '../../../Modal'
import ActiveRequests from './ActiveRequests'
import ModalText from '../../../Modal/ModalText'
import ModalLabel from '../../../Modal/ModalLabel'
import Checkbox from '../../../Checkbox'

import styles from './ConnectRequestModal.module.scss'

const ConnectRequestModal = props => {
  const { id, relatedRequests, automate, relateToCurrent } = props

  const { t } = useTranslation('Request')

  const [requestCount, setRequestCount] = useState(null)
  const [loading, setLoading] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const [isOpen, setOpen] = useState(false)
  const [initiated, setInitiated] = useState(false)

  const location = useLocation()
  const params = useParams()
  const dispatch = useDispatch()
  const profileGroup = useSelector(state => state.profile.data.group)

  const user = useUser()
  const isStaff = isStaffUser(user)

  const [selectedItems, changeSelected, setSelected] = useSelected([], 'id')
  const [check, setCheck] = useState(relateToCurrent)

  useEffect(() => {
    if (initiated) {
      return
    }

    if (relatedRequests && relatedRequests.length) {
      setSelected(relatedRequests)
    }

    setInitiated(true)
  }, [relatedRequests, initiated])

  useOverflow()

  const getRequestLabel = option => {
    return `${t('Request:request_number_title')}${option.request_no}: ${
      option.title
    }`
  }

  const handleChangeCheck = e => setCheck(e.currentTarget.checked)

  const handleConnect = () => {
    if (!id && props.onChange) {
      props.onChange({ relatedRequests: selectedItems, relateToCurrent: check })
      props.onClose()

      return
    }

    setLoading(true)

    connectRequest(id, { requests: map(selectedItems, 'id') }).finally(() => {
      setLoading(false)

      const { profileId, search } = params
      const filters = getCurrentFilters(location)
      const extendedFilters = getExtendedFilters({ params })
      const ownerFilter = getOwnerFilter(profileId, profileGroup)

      dispatch({
        type: REQUEST_LIST_LOAD,
        params: {
          search,
          ...filters,
          ...extendedFilters,
          ...ownerFilter,
        },
        isStaff,
      })

      props.onClose()
    })
  }

  const handleToggle = () => {
    setOpen(!isOpen)
  }

  const getApi = params => {
    if (id) {
      return Promise.all([
        getUnrelatedRequestList(id, params),
        getUnrelatedRequestListCount(id, params),
      ]).then(([data, count]) => {
        return { ...data, meta: { ...data.meta, ...count } }
      })
    }

    return getRequestListLight({ ...params, all: true })
  }

  const getMeta = option => {
    if (!option || !option.archived) {
      return null
    }

    return <span className={styles.archived}>{t('Archived')}</span>
  }

  const hideBuildingList = classNames(
    'modal__label--drop button-drop button-drop--small',
    { 'modal__label--drop--open': isOpen }
  )

  return (
    <>
      <ModalCloseButton onClose={props.onClose} />
      <ModalHead
        title={t('AddRequestConnectionsTitle')}
        className={styles.head}
      />
      <ModalBody>
        <ModalRow>
          <NewSelectAsync
            isMulti
            noAll
            getLabel={getRequestLabel}
            getSelectedLabel={getRequestLabel}
            selectedItems={selectedItems}
            placeholder={t('SelectRequestsPlaceholder')}
            api={getApi}
            getMeta={getMeta}
            pageSize={10}
            view='dropdown'
            isAllSelected={selected => requestCount === selected.length}
            searchKey='search'
            getDescription={option => get(option, ['address_obj', 'value'])}
            setMetaCount={setRequestCount}
            className={styles.select}
            onClick={changeSelected}
          />
        </ModalRow>
        {!!selectedItems.length && (
          <ModalRow onClick={handleToggle}>
            <ModalText className={styles.text} text={t('RequestList')} />
            <ModalLabel className={styles.label}>
              {selectedItems.length}
            </ModalLabel>
            <button
              type='button'
              className={hideBuildingList}
              style={{ marginTop: '1px' }}
            />
          </ModalRow>
        )}
        {isOpen && !!selectedItems.length && (
          <ActiveRequests
            requests={selectedItems}
            changeSelected={changeSelected}
          />
        )}
        {automate && (
          <ModalRow>
            <Checkbox
              checked={check}
              text={t('ConnectedRequestsCurrent')}
              style={{ marginTop: '8px' }}
              onChange={handleChangeCheck}
            />
          </ModalRow>
        )}
      </ModalBody>
      <ModalButtons>
        <Button.Save
          disabled={!selectedItems.length && !automate}
          working={loading}
          onClick={() => setShowConfirm(true)}
        >
          {t('AddRequestConnectionsBtn')}
        </Button.Save>
        <Button.Cancel working={loading} onClick={props.onClose}>
          {t('Cancel')}
        </Button.Cancel>
      </ModalButtons>
      <Modal isOpen={showConfirm} onRequestClose={() => setShowConfirm(false)}>
        <ConfirmationPopup
          title={t('AddRequestConnectionsConfirmTitle')}
          text={t('AddRequestConnectionsConfirmText')}
          confirm={t('AddRequestConnectionsConfirmBtn')}
          cancel={t('Cancel')}
          onClose={() => setShowConfirm(false)}
          onOk={handleConnect}
        />
      </Modal>
    </>
  )
}

export default ConnectRequestModal
