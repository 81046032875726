// @flow

import { clone } from 'lodash-es'
import {
  GET_CHECKITEMS_SUCCESS,
  CREATE_CHECKITEM_SUCCESS,
  DELETE_CHECKITEM_INIT,
  TOGGLE_CHECKITEM_INIT,
  EDIT_CHECKITEM_INIT,
  RESET_INITIAL_STATE,
} from './Checklist.constants'

const initialState = {
  checklists: {},
}

export default function checklistReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_INITIAL_STATE:
      return initialState
    case GET_CHECKITEMS_SUCCESS: {
      const newChecklists = clone(state.checklists)
      newChecklists[action.checklistId] = {}
      newChecklists[action.checklistId].checkitems = action.checkitems

      return {
        ...state,
        checklists: newChecklists,
      }
    }
    case CREATE_CHECKITEM_SUCCESS: {
      const newChecklists = clone(state.checklists)
      newChecklists[action.checklistId] = {}
      newChecklists[action.checklistId].checkitems = [
        ...state.checklists[action.checklistId].checkitems,
        action.checkitem,
      ]

      return {
        ...state,
        checklists: newChecklists,
      }
    }
    case TOGGLE_CHECKITEM_INIT: {
      const newChecklists = clone(state.checklists)
      const newCheckitems = clone(newChecklists[action.checklistId].checkitems)
      newCheckitems.find(item => item.id === action.checkitemId).checked =
        action.checked
      newChecklists[action.checklistId].checkitems = newCheckitems

      return {
        ...state,
        checklists: newChecklists,
      }
    }
    case EDIT_CHECKITEM_INIT: {
      const newChecklists = clone(state.checklists)
      const newCheckitems = clone(newChecklists[action.checklistId].checkitems)
      newCheckitems.find(item => item.id === action.checkitemId).text =
        action.text
      newChecklists[action.checklistId].checkitems = newCheckitems

      return {
        ...state,
        checklists: newChecklists,
      }
    }
    case DELETE_CHECKITEM_INIT: {
      const newChecklists = clone(state.checklists)
      newChecklists[action.checklistId].checkitems = clone(
        newChecklists[action.checklistId].checkitems
      ).filter(item => item.id !== action.checkitemId)

      return {
        ...state,
        checklists: newChecklists,
      }
    }
    default:
      return state
  }
}
