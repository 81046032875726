// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import NewTableHeaderCell from '../NewTable/NewTableHeaderCell'
import NewTableHeaderRow from '../NewTable/NewTableHeaderRow'
import NewTableHeader from '../NewTable/NewTableHeader'

type Props = { isEmail?: boolean }

const TemplatesTableHeader = (props: Props): Node => {
  const { t } = useTranslation('Templates')
  const { isEmail } = props

  return (
    <NewTableHeader>
      <NewTableHeaderRow>
        <NewTableHeaderCell
          title={t('TemplateNameSort')}
          sortKey='name'
          style={{ width: '20%' }}
        />
        {isEmail ? (
          <>
            <NewTableHeaderCell
              title={t('TemplateEmailSubjectSort')}
              sortKey='subject'
              style={{ width: '15%' }}
            />
            <NewTableHeaderCell
              title={t('TemplateEmailTextSort')}
              sortKey='text'
              style={{ width: '20%' }}
            />
          </>
        ) : (
          <NewTableHeaderCell
            title={t('TemplateTextSort')}
            sortKey='text'
            style={{ width: '35%' }}
          />
        )}
        <NewTableHeaderCell
          title={t('TemplatePrivacySort')}
          style={{ width: '10%' }}
        />
        <NewTableHeaderCell
          title={t('TemplateHiddenSort')}
          sortKey='hidden'
          style={{ width: '10%' }}
        />
        <NewTableHeaderCell
          title={t('TemplateCreatedBySort')}
          sortKey='created_by'
          style={{ width: '15%' }}
        />
        <NewTableHeaderCell
          title={t('TemplateUpdatedSort')}
          sortKey='updated'
          style={{ width: '10%' }}
        />
      </NewTableHeaderRow>
    </NewTableHeader>
  )
}

export default TemplatesTableHeader
