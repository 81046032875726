// @flow

import { OPEN_MENU, TOGGLE_MENU } from './Menu.actionTypes'

type Action = {
  type: string,
}

export function openMenu(): Action {
  return {
    type: OPEN_MENU,
  }
}

export function toggleMenu(): Action {
  return {
    type: TOGGLE_MENU,
  }
}
