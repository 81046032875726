// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import NewTableHeader from '../NewTable/NewTableHeader'
import NewTableHeaderCell from '../NewTable/NewTableHeaderCell'
import NewTableHeaderRow from '../NewTable/NewTableHeaderRow'

type Props = {
  outbound: boolean,
}

const ConnectedCompaniesTableHead = (props: Props): Node => {
  const { outbound } = props
  const { t } = useTranslation('IdwellToIdwell')

  return (
    <NewTableHeader>
      <NewTableHeaderRow>
        <NewTableHeaderCell
          title={t('CompanyNameColumn')}
          sortKey='name'
          style={{ width: outbound ? '34%' : '25%' }}
        />
        {outbound ? (
          <NewTableHeaderCell
            title={t('ConnectionStatusColumn')}
            sortKey='status'
            style={{ width: '33%' }}
          />
        ) : (
          <>
            <NewTableHeaderCell
              title={t('PartnerUserColumn')}
              sortKey='inbound_user_obj'
              style={{ width: '25%' }}
            />
            <NewTableHeaderCell
              title={t('WorkflowColumn')}
              style={{ width: '25%' }}
            />
          </>
        )}
        <NewTableHeaderCell
          title={t('UpdateColumn')}
          sortKey={outbound ? 'updated' : 'created'}
          style={{ width: outbound ? '33%' : '25%' }}
        />
      </NewTableHeaderRow>
    </NewTableHeader>
  )
}

export default ConnectedCompaniesTableHead
