export const TITLE = 'title'
export const TEXT = 'text'

export const getText = {
  400: {
    [TITLE]: 'BadRequest',
    [TEXT]: 'CheckPageAddress',
  },
  401: {
    [TITLE]: 'Unauthorized',
    [TEXT]: 'CheckPageAddress',
  },
  402: {
    [TITLE]: 'PaymentRequired',
    [TEXT]: 'CheckPageAddress',
  },
  403: {
    [TITLE]: 'Forbidden',
    [TEXT]: 'CheckPageAddress',
  },
  404: {
    [TITLE]: 'PageNotFound',
    [TEXT]: 'CheckPageAddress',
  },
  500: {
    [TITLE]: 'InternalServerError',
    [TEXT]: 'ServerEncountered',
  },
  502: {
    [TITLE]: 'BadGateway',
    [TEXT]: 'ServerEncountered',
  },
}
