// @flow

import React, { PureComponent } from 'react'
import { withTranslation } from 'react-i18next'
import { map } from 'lodash-es'
import classnames from 'classnames'

import ValidationText from '../../../ValidationText'
import { VariableTypes } from '../../constants'
import NewSelectSimple from '../../../NewSelectSimple'

type Props = {
  active: boolean,
  data: Object,
  error: boolean,
  incorrectNames: Array<string>,
  nameIsForbidden: boolean,
  onRemove: () => void,
  onUpdate: Object => void,
  onValidate: () => void,
  t: string => string,
}

function getOptions(t) {
  return map(VariableTypes, type => ({
    value: type,
    label: t(`variable-${type}`),
  }))
}

class VariableItem extends PureComponent<Props> {
  updateType = type => {
    const { data } = this.props

    if (type.value !== data.type) {
      this.update('type', type.value)
    }
  }

  updateField = ({ target }) => {
    this.update(target.name, target.value)
  }

  updateName = ({ target }) => {
    this.update('name', target.value)
  }

  update = (name, value) => {
    const { data, onUpdate } = this.props

    onUpdate({ ...data, [name]: value })

    if (name === 'name' && value) {
      this.setState({ nameIsForbidden: false })
    }
  }

  render() {
    const {
      active,
      data: { label, type, name },
      error,
      incorrectNames,
      nameIsForbidden,
      onRemove,
      onValidate,
      t,
    } = this.props

    const options = getOptions(this.props.t)

    const selectedType = options.find(o => o.value === type)
    const nameClass = classnames('input modal__wizard-input', {
      'input--error': error || incorrectNames.includes(name) || nameIsForbidden,
    })

    return (
      <div className='request-constructor__card--variable-item'>
        <div>
          <input
            type='text'
            className='input modal__wizard-input'
            placeholder={t('variableLabelPlaceholder')} // TODO ask about max length
            name='label'
            value={label || ''}
            onBlur={onValidate}
            onChange={this.updateField}
          />
        </div>
        <div>
          <NewSelectSimple
            name='variable-type'
            value={selectedType || null}
            options={options}
            placeholder={t('variableTypePlaceholder')}
            onChange={this.updateType}
          />
        </div>
        <div>
          <div>
            <input
              type='text'
              className={nameClass}
              placeholder={t('variableNamePlaceholder')}
              value={name || ''}
              onBlur={onValidate}
              onChange={this.updateName}
            />
            {active && (
              <button
                className='button button--default-3 button--square button--large button--square-x button--inline'
                type='button'
                onClick={onRemove}
              />
            )}
          </div>
          <ValidationText active={nameIsForbidden} text={t('forbiddenName')} />
          <ValidationText
            active={incorrectNames.includes(name)}
            text={t('incorrectName')}
          />
        </div>
      </div>
    )
  }
}

export default withTranslation('RequestConstructor')(VariableItem)
