// @flow

import React, { useEffect, useState } from 'react'
import { get, isObject } from 'lodash-es'
import classnames from 'classnames'
import api from '../../core/api'
import Loader from '../Loader'
import {
  DEFAULT_FIELD_GROUP,
  EXCLUDED_FIELD_EXTERNAL_GROUP,
} from './ContactForm.constants'

import styles from './ContactForm.module.scss'

import { useTranslation } from 'react-i18next'
import { useFormik, FormikProvider, FieldArray } from 'formik'
import { sortBy } from 'lodash-es'
import { getChangedValues } from '../../utils/utils'

import ContactFormList from './ContactFormList'

import type { Node } from 'react'
import ExternalContactFormList from './ExternalContactFormList'

type Props = {
  edit: boolean,
  isCompany: boolean,
  isExternal: boolean,
  modelId: number,
  noMargin?: boolean,
  onInit: () => void,
  onUpdate: Object => void,
  profileId: number,
  working: boolean,
}

const ContactForm = ({
  profileId,
  modelId,
  noMargin,
  edit,
  working,
  onInit,
  onUpdate,
  isCompany,
  isExternal,
}: Props): Node => {
  const { t } = useTranslation('ContactForm')
  const [isLoading, setIsLoading] = useState(true)
  const [initial, setInitial] = useState({
    initialValues: {
      fields: [],
    },
    initialStatus: {
      isCompany,
      defaultOpenField: -1,
      permissions: {},
    },
  })

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    dirty: true,
    ...initial,
    onSubmit: (values, action) => {
      const changedValues = getChangedValues(
        formik.initialValues.fields,
        values.fields
      )
      const currentField = Object.values(changedValues)[0]

      requestToChangeFrom(currentField)
        .then(() => {
          requestData(profileId, modelId)
          action.setStatus({
            ...formik.status,
            defaultOpenField: -1,
          })
        })
        .catch(error => {
          let errorText = get(error, ['message', 'response', 'data', 'errors'])
          const {
            initialValues: { fields },
          } = initial

          if (isObject(errorText)) {
            errorText = Object.values(errorText)[0]
          }

          if (!currentField.fieldFormIndex) {
            currentField.fieldFormIndex = isExternal
              ? `fields[${fields.length}].value`
              : `fields[${fields.length}].values`
          }

          action.setFieldError(currentField.fieldFormIndex, errorText)
        })
        .finally(() => {
          action.setSubmitting(false)
        })
    },
  })

  useEffect(() => requestData(profileId, modelId), [profileId, modelId])

  useEffect(() => {
    onInit()
  }, [])

  useEffect(() => {
    const {
      initialValues: { fields: initialFields },
    } = initial

    if (initialFields.length) {
      const fields = initialFields.map(field => ({
        ...field,
        value: field.values || field.value,
        type: field.contact_type,
      }))

      onUpdate(fields)
    }
  }, [initial.initialValues])

  const requestToChangeFrom = currentField => {
    let { id, values, contact_type } = currentField
    const {
      updateContact,
      createContact,
      deleteContact,
      createExternalContact,
      updateExternalContact,
      deleteExternalContact,
    } = api.contactForm

    let result

    if (id && values === null) {
      result = isExternal ? deleteExternalContact(id) : deleteContact(id)
    } else if (id) {
      result = isExternal
        ? updateExternalContact(id, { value: values })
        : updateContact(id, { values })
    } else {
      result = isExternal
        ? createExternalContact(profileId, { contact_type, value: values })
        : createContact({
            contact_type,
            fk: profileId,
            model: modelId,
            values,
          })
    }

    return result
  }

  const requestData = (profileId: number, modelId: number) => {
    const callApi = isExternal
      ? api.contactForm.getExternalContacts
      : api.contactForm.getContacts
    callApi(profileId, modelId)
      .then(contacts => {
        const {
          permissions,
          results: { objects: fields },
        } = contacts

        let defaultFields = fields.filter(field =>
          isExternal
            ? !EXCLUDED_FIELD_EXTERNAL_GROUP.includes(field.contact_type) &&
              DEFAULT_FIELD_GROUP.includes(field.contact_type)
            : DEFAULT_FIELD_GROUP.includes(field.contact_type)
        )

        const fieldsSort = [
          ...sortBy(defaultFields, 'contact_type'),
          ...fields.filter(({ contact_type }) => {
            if (isExternal) {
              return (
                !EXCLUDED_FIELD_EXTERNAL_GROUP.includes(contact_type) &&
                !DEFAULT_FIELD_GROUP.includes(contact_type)
              )
            }

            return !DEFAULT_FIELD_GROUP.includes(contact_type)
          }),
        ]

        const currentFieldsValues = fieldsSort.map((field, index) => {
          return {
            ...field,
            fieldFormIndex: isExternal
              ? `fields[${index}].value`
              : `fields[${index}].values`,
          }
        })

        setInitial({
          initialValues: { fields: currentFieldsValues },
          initialStatus: {
            isCompany,
            permissions,
          },
        })
      })
      .finally(() => setIsLoading(false))
  }

  const contentClass = classnames('unit', 'unit--default', {
    'unit--nomargin': noMargin,
    'mprofile-edit-contacts': edit,
    'mprofile-view-contacts': !edit,
    'working-overlay': working,
  })

  const formClass = classnames({
    [styles.companyForm]: isCompany,
  })

  return (
    <section className={contentClass}>
      <h2 className='unit__title'>{t('Contacts')}&nbsp;</h2>
      {isLoading ? (
        <Loader text={false} type='big' />
      ) : (
        <form className={formClass} onSubmit={formik.handleSubmit}>
          <FormikProvider value={formik}>
            <FieldArray
              name='fields'
              component={isExternal ? ExternalContactFormList : ContactFormList}
            />
          </FormikProvider>
        </form>
      )}
    </section>
  )
}

export default ContactForm
