// @flow

import React from 'react'
import type { Node } from 'react'

import PhaseItem from './PhaseItem'

import styles from './PhaseList.module.scss'

type Props = { isDweller: boolean, pipeline: Array<Object> }

const PhaseList = (props: Props): Node => {
  const { pipeline, isDweller } = props

  return (
    <div className={styles['phase-list']}>
      {pipeline.map((phase, index) => (
        <PhaseItem
          phase={phase}
          key={phase.id}
          index={index}
          count={pipeline.length}
          isDweller={isDweller}
        />
      ))}
    </div>
  )
}

export default PhaseList
