// @flow

import React from 'react'
import type { Node } from 'react'

import Icon from '../Icon'
import type { IconId } from '../Icon/Icon.types'

import styles from './Stat.module.scss'

type Props = { count: number, iconId: IconId }

const Stat = (props: Props): Node => {
  const { iconId, count } = props

  return (
    <div className={styles.stat}>
      <Icon id={iconId} />
      <span>{count}</span>
    </div>
  )
}

export default Stat
