// @flow

import React, { Component } from 'react'
import { push } from 'connected-react-router'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'

import { Header, SimpleBlock } from '../Block'
import Nbsp from '../NBSP'
import BottomPagination from '../Pagination/BottomPagination'
import EmptyList from '../EmptyList'
import Loader from '../Loader'
import FriendsRequests from '../FriendsRequests'
import TileList from '../TileList'
import UserItem from '../UserItem'
import addRedirectToFirstPage from '../../hocs/addRedirectToFirstPage'
import { getCurrentFilters } from '../../utils/routing'
import { getLocation } from '../../utils/commonSelectors'
import * as actions from './Friends.actionTypes'
import { FRIENDS_REQUESTS_INITIATING } from '../FriendsRequests/FriendsRequests.actionTypes'
import { getUserId } from '../../utils/utils'

const FRIEND_REQUESTS_PAGE_SIZE = 5

type Props = {
  dispatch: Object => void,
  initiated: boolean,
  initiatedFriendsRequests: boolean,
  items: Array<Object>,
  location: Object,
  meta: Object,
  t: string => string,
  working: boolean,
}

class Friends extends Component<Props> {
  componentDidMount() {
    const { page } = getCurrentFilters(this.props.location)

    this.props.dispatch({
      type: FRIENDS_REQUESTS_INITIATING,
      params: { page_size: FRIEND_REQUESTS_PAGE_SIZE },
    })
    this.props.dispatch({ type: actions.FRIENDS_LIST_INITIATING, page })
  }

  componentDidUpdate(prev: Props) {
    const { location } = this.props

    if (location.search !== prev.location.search) {
      const { page } = getCurrentFilters(location)
      const { page: prevPage } = getCurrentFilters(prev.location)

      if (prevPage && page !== prevPage) {
        this.load(page)
      }
    }
  }

  load = page => {
    this.props.dispatch({ type: actions.FRIENDS_LIST_LOAD, page })
  }

  openResidents = () => {
    this.props.dispatch(push('/residents'))
  }

  subscribe = user => {
    const { owner } = user
    this.props.dispatch({ type: actions.FRIENDS_LIST_SUBSCRIBE, owner })
  }

  unSubscribe = user => {
    const { owner } = user
    this.props.dispatch({ type: actions.FRIENDS_LIST_UNSUBSCRIBE, owner })
  }

  resendSubscribe = user => {
    const { owner } = user
    this.props.dispatch({ type: actions.FRIENDS_LIST_SUBSCRIBE, owner })
  }

  renderHeader = () => {
    const {
      meta: { count },
      t,
    } = this.props

    return (
      <Header header>
        {t('FriendsTitle')}
        <Nbsp />
        {!!count && <span className='unit__title-num'>{count}</span>}
      </Header>
    )
  }

  renderEmpty = () => (
    <EmptyList
      canAdd
      embedded
      btnText={this.props.t('AddFriend')}
      icon='user'
      title={this.props.t('NoFriends')}
      onClick={this.openResidents}
    />
  )

  render() {
    const {
      initiated,
      working,
      items,
      meta,
      dispatch,
      initiatedFriendsRequests,
    } = this.props

    if (!initiated || !initiatedFriendsRequests) {
      return (
        <div className='content__col'>
          <Loader text={false} type='big' />
        </div>
      )
    }

    if (working) {
      return (
        <div className='content__col'>
          <SimpleBlock>
            {this.renderHeader()}
            <Loader text={false} type='medium' />
          </SimpleBlock>
        </div>
      )
    }

    return (
      <div className='content__col'>
        <FriendsRequests pageSize={FRIEND_REQUESTS_PAGE_SIZE} />
        <SimpleBlock>
          {this.renderHeader()}
          {!items.length ? (
            this.renderEmpty()
          ) : (
            <TileList>
              {items.map(user => (
                <UserItem
                  data={user}
                  key={`friend-${getUserId(user)}`}
                  resubscribe={this.resendSubscribe}
                  subscribe={this.subscribe}
                  unsubscribe={this.unSubscribe}
                />
              ))}
            </TileList>
          )}
          <BottomPagination
            classes='paginator-2'
            basePage='friends'
            meta={meta}
            dispatch={dispatch}
          />
        </SimpleBlock>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state.friends,
  location: getLocation(state),
  initiatedFriendsRequests: state.friendsRequests.initiated,
})

export default compose(
  connect(mapStateToProps),
  withTranslation('Friends'),
  addRedirectToFirstPage
)(Friends)
