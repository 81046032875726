// @flow

import { all, put, call, fork, takeEvery } from 'redux-saga/effects'
import type { Saga } from 'redux-saga'

import api from '../../core/api'
import { serverError } from '../Layout/Layout.actions'
import * as actions from './SettingsPersonal.actionTypes'

function* watchInit() {
  yield takeEvery(actions.SETTINGS_PERSONAL_INIT, init)
}

function* watchSave() {
  yield takeEvery(actions.SETTINGS_PERSONAL_SAVE, save)
}

function* init() {
  try {
    const profileConfig = yield call(api.profile.getMyProfileConfig)
    yield put({ type: actions.SETTINGS_PERSONAL_INIT_SUCCESS, profileConfig })
  } catch (error) {
    yield put({ type: actions.SETTINGS_PERSONAL_INIT_ERROR, error })
    yield put(serverError(error))
    yield put({ type: actions.SETTINGS_PERSONAL_INIT_SUCCESS })
  }
}

function* save(action) {
  try {
    const {
      id,
      language,
      notificationFrequencyType,
      changedValues,
      workingSave,
    } = action

    const params = {}
    const profileConfigParams = {}

    if (language) {
      params.language = language.code
    }

    let profileConfig

    if (notificationFrequencyType !== undefined) {
      profileConfigParams.email_notification_period = notificationFrequencyType
      profileConfig = yield call(
        api.profile.updateMyProfileConfig,
        profileConfigParams
      )
    }

    if (changedValues !== undefined) {
      profileConfig = yield call(
        api.profile.updateMyProfileConfig,
        changedValues
      )
    }

    const user = yield call(api.profile.update, id, params)

    if (language !== undefined) {
      window.location.reload(true)

      return
    }

    yield put({
      type: actions.SETTINGS_PERSONAL_SAVED,
      user,
      profileConfig,
      workingSave,
    })
  } catch (error) {
    yield put(serverError(error))
  }
}

export default function* watch(): Saga<void> {
  yield all([fork(watchSave), fork(watchInit)])
}
