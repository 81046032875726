// @flow

import React from 'react'
import { get } from 'lodash-es'
import { withRouter } from 'react-router-dom'

import Files from '../Files'

type Props = {
  isDweller: boolean,
  noMargin: boolean,
  onSave: () => void,
  profile: Object,
  saving: boolean,
}

const ProfileDocuments = (props: Props) => {
  const directoryId = get(props, ['profile', 'directory', 'id'])
  const profileInfo = { type: 'profile', ...props.profile }

  return (
    <Files
      isDweller={props.isDweller}
      subject={profileInfo}
      directoryId={directoryId}
      match={props.match}
      location={props.location}
      scope={'user'}
    />
  )
}

export default withRouter(ProfileDocuments)
