// @flow

import React from 'react'
import type { Node } from 'react'

import ModalText from '../../../Modal/ModalText'

import styles from './Text.module.scss'

type Props = { text: string }

const Text = (props: Props): Node => {
  const { text } = props

  return (
    <div className={styles.container}>
      <ModalText text={text} className={styles.text} />
    </div>
  )
}

export default Text
