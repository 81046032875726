// @flow

import type { Node } from 'react'
import React from 'react'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

import FieldRow from '../../FieldRow'

import styles from './ProfileInformation.module.scss'

import Button from '../../Button'
import * as Yup from 'yup'

import {
  DEPOSIT_TYPE,
  FUNDINGS,
  GUARANTEED_VALUE,
  MAX_FIELD_LENGTH,
  REMEDY,
  STOP_OVERDUE_NOTICE,
  TERMINATION_DENIED,
  TERMINATION_TIME,
  TERMINATION_TIMEFRAME,
  setFormikError,
  normalizeValues,
} from './PropfileInformationORAGCluster.constants'

type Props = {
  active: boolean,
  handleEditClose: () => void,
  isOpen: boolean,
}

const ORAGClusterForm = ({
  isOpen,
  initialValues,
  active,
  handleEditClose,
  updateProfileInfo,
}: Props): Node => {
  const { t } = useTranslation('Profile')

  const onSubmit = values => {
    setValues({ ...normalizeValues(values) })
    updateProfileInfo({ orag_cluster: { ...normalizeValues(values) } })
      .then(() => handleEditClose())
      .catch(error => setFormikError(error, setFieldError))
  }

  const onClosed = () => {
    handleEditClose()
    resetForm()
  }

  const {
    values,
    errors,
    dirty,
    isSubmitting,
    handleSubmit,
    handleChange,
    setFieldError,
    resetForm,
    setValues,
  } = useFormik({
    validateOnChange: false,
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      [DEPOSIT_TYPE]: Yup.string()
        .max(MAX_FIELD_LENGTH, t('MaxInputLength'))
        .nullable(),
      [FUNDINGS]: Yup.string()
        .max(MAX_FIELD_LENGTH, t('MaxInputLength'))
        .nullable(),
      [REMEDY]: Yup.string()
        .max(MAX_FIELD_LENGTH, t('MaxInputLength'))
        .nullable(),
      [STOP_OVERDUE_NOTICE]: Yup.string()
        .max(MAX_FIELD_LENGTH, t('MaxInputLength'))
        .nullable(),
      [TERMINATION_DENIED]: Yup.string()
        .max(MAX_FIELD_LENGTH, t('MaxInputLength'))
        .nullable(),
      [TERMINATION_TIME]: Yup.string()
        .max(MAX_FIELD_LENGTH, t('MaxInputLength'))
        .nullable(),
      [TERMINATION_TIMEFRAME]: Yup.string()
        .max(MAX_FIELD_LENGTH, t('MaxInputLength'))
        .nullable(),
      [GUARANTEED_VALUE]: Yup.string()
        .max(MAX_FIELD_LENGTH, t('MaxInputLength'))
        .nullable(),
    }),
    onSubmit,
  })

  return (
    <form
      name='partner_information'
      onSubmit={handleSubmit}
      onChange={handleChange}
    >
      <FieldRow
        name={DEPOSIT_TYPE}
        active={active}
        label={'DepositTypeTitle'}
        error={errors[DEPOSIT_TYPE]}
        value={values[DEPOSIT_TYPE]}
        placeholder={t('EnterDepositType')}
        handleChange={handleChange}
      />
      <FieldRow
        name={FUNDINGS}
        active={active}
        label={'FundingsTitle'}
        error={errors[FUNDINGS]}
        value={values[FUNDINGS]}
        placeholder={t('EnterFundings')}
        handleChange={handleChange}
      />
      {(values[GUARANTEED_VALUE] || isOpen) && (
        <FieldRow
          name={GUARANTEED_VALUE}
          active={active}
          label={'GuaranteedTitle'}
          error={errors[GUARANTEED_VALUE]}
          value={values[GUARANTEED_VALUE]}
          placeholder={t('EnterGuaranteed')}
          handleChange={handleChange}
        />
      )}
      {(values[TERMINATION_TIME] || isOpen) && (
        <FieldRow
          name={TERMINATION_TIME}
          active={active}
          label={'TerminationTimeTitle'}
          error={errors[TERMINATION_TIME]}
          value={values[TERMINATION_TIME]}
          placeholder={t('TerminationTimeType')}
          handleChange={handleChange}
        />
      )}
      {(values[TERMINATION_DENIED] || isOpen) && (
        <FieldRow
          name={TERMINATION_DENIED}
          active={active}
          label={'TerminationDeniedTitle'}
          error={errors[TERMINATION_DENIED]}
          value={values[TERMINATION_DENIED]}
          placeholder={t('EnterTerminationDenied')}
          handleChange={handleChange}
        />
      )}
      {(values[TERMINATION_TIMEFRAME] || isOpen) && (
        <FieldRow
          name={TERMINATION_TIMEFRAME}
          active={active}
          label={'TerminationTimeframeTitle'}
          error={errors[TERMINATION_TIMEFRAME]}
          value={values[TERMINATION_TIMEFRAME]}
          placeholder={t('EnterTerminationTimeframe')}
          handleChange={handleChange}
        />
      )}
      {(values[STOP_OVERDUE_NOTICE] || isOpen) && (
        <FieldRow
          name={STOP_OVERDUE_NOTICE}
          active={active}
          label={'StopOverdueNoticeTitle'}
          error={errors[STOP_OVERDUE_NOTICE]}
          value={values[STOP_OVERDUE_NOTICE]}
          placeholder={t('EnterStopOverdueNotice')}
          handleChange={handleChange}
        />
      )}
      {(values[REMEDY] || isOpen) && (
        <FieldRow
          name={REMEDY}
          active={active}
          label={'RemedyTitle'}
          error={errors[REMEDY]}
          value={values[REMEDY]}
          placeholder={t('EnterRemedy')}
          handleChange={handleChange}
        />
      )}
      {active && (
        <div className={styles.buttonWrapper}>
          <Button.Save
            disabled={!dirty || (isSubmitting && !errors)}
            type='submit'
          >
            {t('Common:Save')}
          </Button.Save>
          <Button.Cancel onClick={onClosed}>{t('Common:Cancel')}</Button.Cancel>
        </div>
      )}
    </form>
  )
}

export default ORAGClusterForm
