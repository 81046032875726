// @flow

import classnames from 'classnames'
import { defaults, get, keys, map, omit, pick } from 'lodash-es'

import {
  cloneTemplate,
  createTemplate,
  patchTemplate,
} from '../../../core/api/api.template'
import {
  cloneEmailTemplate,
  createEmailTemplate,
  patchEmailTemplate,
} from '../../../core/api/api.emailTemplate'
import { CLONE, CREATE, EDIT, HTML_TEXT_TYPE } from './TemplatePopup.constants'
import type { Params } from '../Templates.types'

export function getFieldClass(error: boolean): string {
  return classnames('input input--medium input--block input--default', {
    'input--error': error,
  })
}

export function getActionParams(
  params: Params,
  isEmail: boolean
): {
  api: Function,
  defaultValues?: Object,
  fields?: { [string]: any },
  type: string,
} {
  const id = get(params, 'id')

  if (id) {
    const { name, text, subject } = params

    if (text) {
      const {
        tags,
        text_type,
        private: isPrivate,
        files,
        hidden,
        building_group_objs,
        for_building_without_group,
      } = params

      let building_groups = building_group_objs.map(({ id, name }) => ({
        value: id,
        label: name,
      }))

      const fields = Object.assign(
        {},
        { name, private: isPrivate, tags, text_type, files, hidden }
      )

      const defaultValues = Object.assign(
        {},
        { tags: tags && tags.length ? map(tags, 'name') : [] },
        text_type === HTML_TEXT_TYPE
          ? { simpleText: '', text }
          : { text: '', simpleText: text },
        { building_groups, for_building_without_group },
        isEmail ? { subject } : {}
      )

      return {
        type: EDIT,
        fields,
        api: isEmail ? patchEmailTemplate : patchTemplate,
        defaultValues,
      }
    } else {
      return {
        type: CLONE,
        fields: { name },
        api: isEmail ? cloneEmailTemplate : cloneTemplate,
      }
    }
  } else {
    return {
      type: CREATE,
      api: isEmail ? createEmailTemplate : createTemplate,
      defaultValues: {
        name: '',
        text: '',
        simpleText: '',
        subject: isEmail ? '' : undefined,
        private: true,
        tags: [],
        files: [],
        hidden: false,
        text_type: HTML_TEXT_TYPE,
        building_groups: [],
      },
    }
  }
}

export function getInitialValues(params: Params, fields, defaultValues) {
  const processedParams = omit(params, ['text', 'tags'])

  return defaults(pick(processedParams, keys(fields)), defaultValues)
}

export const getQuillEmptyValue = (value: string): string =>
  value === '<p><br></p>' ? '' : value
