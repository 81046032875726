// @flow

import React from 'react'
import { useLocation, useRouteMatch } from 'react-router-dom'
import type { Node } from 'react'

import Files from '../Files'

type Props = {
  directoryId: number,
  isArchived: Boolean,
  isDweller: boolean,
  outbound?: string,
  requestId: number,
  working: boolean,
}

const RequestFiles = (props: Props): Node => {
  const { directoryId, working, outbound, requestId, isDweller, isArchived } =
    props

  const location = useLocation()
  const match = useRouteMatch()

  if (!directoryId) {
    return null
  }

  return (
    <Files
      outbound={outbound}
      scope={'tree'}
      requestId={requestId}
      directoryId={directoryId}
      isDweller={isDweller}
      isArchived={isArchived}
      match={match}
      location={location}
      working={working}
      object={requestId}
      setReloading={props.setReloading}
      subject={{ type: 'request' }}
      onAddToInvoice={props.onAddToInvoice}
    />
  )
}

export default RequestFiles
