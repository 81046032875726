// @flow

import React from 'react'
import type Node from 'react'
import { connect } from 'react-redux'

import { getRequestData } from '../../../../containers/RequestView/Info.selectors'
import {
  hideModal,
  updateDueDateInit,
} from '../../../../containers/RequestView/RequestView.actions'
import SetDueDatePopUp from '../../../modals/SetDueDatePopUp'

type Props = {
  hideModal: () => void,
  id: number,
  updateDueDateInit: string => void,
  value?: string,
}

const Popup = ({ value, id, ...props }: Props): Node => {
  const handleChange = date => {
    props.updateDueDateInit(id, date)
    props.hideModal()
  }

  return (
    <SetDueDatePopUp
      dueDate={value}
      onHide={props.hideModal}
      onSave={handleChange}
    />
  )
}

const mapStateToProps = state => ({
  value: getRequestData(state).due_date,
  id: getRequestData(state).id,
})
const mapDispatchToProps = {
  hideModal,
  updateDueDateInit,
}

export default connect(mapStateToProps, mapDispatchToProps)(Popup)
