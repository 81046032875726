// @flow

import React from 'react'
import type Node from 'react'

import { DragSource } from 'react-dnd'
import { compose } from 'redux'

import classnames from 'classnames'

import styles from './MailSearchRecipient.module.scss'

type Props = {
  children: Node,
  connectDragSource: Node => Node,
  isDragging: boolean,
}

const DragItemType = 'RecipientGroup'

export { DragItemType }

const dragSource = {
  beginDrag(props) {
    const { group, name, variable } = props

    return { group, name, variable }
  },
}

const MailSearchRecipient = ({
  isDragging,
  children,
  connectDragSource,
}: Props) => {
  const classNameDragSpan = classnames(styles.recipient, {
    [styles.recipientDragging]: isDragging,
  })

  return connectDragSource(
    <span className={classNameDragSpan}>{children}</span>
  )
}

export default compose(
  DragSource(DragItemType, dragSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  }))
)(MailSearchRecipient)
