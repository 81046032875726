import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

export default class SuggestItem extends PureComponent {
  onClick = (event) => {
    event.preventDefault()
    this.props.onSelect(this.props.suggest)
  }

  render () {
    const { onMouseDown, onMouseOut, active, suggest } = this.props
    const css = classnames('dropdown__list-item', {
      'dropdown__list-item_active': active
    })

    return (
      <li
        className={css}
        onMouseDown={onMouseDown}
        onMouseOut={onMouseOut}
        onBlur={onMouseOut}
        onClick={this.onClick}
      >
        <span className='dropdown__list-item-text'>{suggest.label}</span>
      </li>
    )
  }
}

SuggestItem.propTypes = {
  onMouseDown: PropTypes.func,
  onMouseOut: PropTypes.func,
  onSelect: PropTypes.func,
  active: PropTypes.bool,
  suggest: PropTypes.object
}

SuggestItem.defaultProps = {
  isActive: false,
  className: '',
  suggest: {}
}
