/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type Offer_offer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProviderProfileOffers_query$ref: FragmentReference;
declare export opaque type ProviderProfileOffers_query$fragmentType: ProviderProfileOffers_query$ref;
export type ProviderProfileOffers_query = {|
  +allOffersByProvider: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +$fragmentRefs: Offer_offer$ref
      |}
    |}>,
    +totalCount: ?number,
  |},
  +$refType: ProviderProfileOffers_query$ref,
|};
export type ProviderProfileOffers_query$data = ProviderProfileOffers_query;
export type ProviderProfileOffers_query$key = {
  +$data?: ProviderProfileOffers_query$data,
  +$fragmentRefs: ProviderProfileOffers_query$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "ProviderProfileOffers_query",
  "type": "Query",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": null,
        "direction": "forward",
        "path": [
          "allOffersByProvider"
        ]
      }
    ]
  },
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "providerGlobalId",
      "type": "String",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "page",
      "type": "Float",
      "defaultValue": 1
    },
    {
      "kind": "LocalArgument",
      "name": "pageSize",
      "type": "Float",
      "defaultValue": 15
    },
    {
      "kind": "LocalArgument",
      "name": "first",
      "type": "Int",
      "defaultValue": 15
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": "allOffersByProvider",
      "name": "__ProviderProfileOffers_allOffersByProvider_connection",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "page",
          "variableName": "page"
        },
        {
          "kind": "Variable",
          "name": "pageSize",
          "variableName": "pageSize"
        },
        {
          "kind": "Variable",
          "name": "providerGlobalId",
          "variableName": "providerGlobalId"
        }
      ],
      "concreteType": "AdminOfferNodeFilterConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "AdminOfferNodeFilterEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "OfferNode",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "__typename",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "FragmentSpread",
                  "name": "Offer_offer",
                  "args": null
                }
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "cursor",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "totalCount",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "pageInfo",
          "storageKey": null,
          "args": null,
          "concreteType": "PageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "endCursor",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hasNextPage",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'b97ae9993ddd4281ce925ac0df2e7342';
module.exports = node;
