/**
 * @flow
 * @relayHash ba5ef7bb4f929786f1d154f22d5bfd54
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type OrdersInfoQueryVariables = {|
  orderIds?: ?string
|};
export type OrdersInfoQueryResponse = {|
  +allLimitedCustomerOrders: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +orderNo: ?string,
        +customerOrderId: ?string,
        +title: ?string,
        +canView: ?boolean,
      |}
    |}>,
  |}
|};
export type OrdersInfoQuery = {|
  variables: OrdersInfoQueryVariables,
  response: OrdersInfoQueryResponse,
|};
*/


/*
query OrdersInfoQuery(
  $orderIds: String
) {
  allLimitedCustomerOrders(ids: $orderIds) {
    totalCount
    edges {
      node {
        orderNo
        customerOrderId
        title
        canView
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "orderIds",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "orderIds"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalCount",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "orderNo",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "customerOrderId",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "canView",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "OrdersInfoQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allLimitedCustomerOrders",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "LimitedCustomerOrderNodeFilterConnection",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "LimitedCustomerOrderNodeFilterEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "LimitedCustomerOrderNode",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "OrdersInfoQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allLimitedCustomerOrders",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "LimitedCustomerOrderNodeFilterConnection",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "LimitedCustomerOrderNodeFilterEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "LimitedCustomerOrderNode",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "id",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "OrdersInfoQuery",
    "id": null,
    "text": "query OrdersInfoQuery(\n  $orderIds: String\n) {\n  allLimitedCustomerOrders(ids: $orderIds) {\n    totalCount\n    edges {\n      node {\n        orderNo\n        customerOrderId\n        title\n        canView\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2352da07fe4e4af86dc25b54a0e235be';
module.exports = node;
