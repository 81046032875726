// @flow

export const PROFILE_INFO_INITIATE = 'PROFILE_INFO_INITIATE'
export const PROFILE_INFO_RESET = 'PROFILE_INFO_RESET'
export const PROFILE_INFO_EDIT = 'PROFILE_INFO_EDIT'
export const PROFILE_INFO_CANCEL = 'PROFILE_INFO_CANCEL'
export const PROFILE_INFO_UPDATE_FIELD = 'PROFILE_INFO_UPDATE_FIELD'
export const PROFILE_INFO_UPDATE = 'PROFILE_INFO_UPDATE'
export const PROFILE_INFO_UPDATED = 'PROFILE_INFO_UPDATED'
export const PROFILE_INFO_SUBSCRIBE = 'PROFILE_INFO_SUBSCRIBE'
export const PROFILE_INFO_SUBSCRIBED = 'PROFILE_INFO_SUBSCRIBED'
export const PROFILE_INFO_UNSUBSCRIBE = 'PROFILE_INFO_UNSUBSCRIBE'
export const PROFILE_INFO_UNSUBSCRIBED = 'PROFILE_INFO_UNSUBSCRIBED'
export const PROFILE_INFO_ERROR = 'PROFILE_INFO_ERROR'
export const PROFILE_INFO_ACCEPT = 'PROFILE_INFO_ACCEPT'
export const PROFILE_INFO_ACCEPTED = 'PROFILE_INFO_ACCEPTED'
export const PROFILE_INFO_REJECT = 'PROFILE_INFO_REJECT'
export const PROFILE_INFO_REJECTED = 'PROFILE_INFO_REJECTED'
export const PROFILE_INFO_APPROVE = 'PROFILE_INFO_APPROVE'
export const PROFILE_INFO_CHANGE_USER_STATUS = 'PROFILE_INFO_CHANGE_USER_STATUS'
export const PROFILE_INFO_CHANGE_USER_STATUS_COMPLETED =
  'PROFILE_INFO_CHANGE_USER_STATUS_COMPLETED'
export const PROFILE_INFO_OPEN_MODAL = 'PROFILE_INFO_OPEN_MODAL'
export const PROFILE_INFO_CLOSE_MODAL = 'PROFILE_INFO_CLOSE_MODAL'
export const PROFILE_INFO_REMOVE = 'PROFILE_INFO_REMOVE'
export const PROFILE_INFO_RESTORE = 'PROFILE_INFO_RESTORE'
export const PROFILE_INFO_REMOVE_COMPLETED = 'PROFILE_INFO_REMOVE_COMPLETED'
export const PROFILE_INFO_RESTORE_COMPLETED = 'PROFILE_INFO_RESTORE_COMPLETED'
export const PROFILE_INFO_CHANGE_DWELLER_ROLE =
  'PROFILE_INFO_CHANGE_DWELLER_ROLE'
export const PROFILE_INFO_CHANGED_DWELLER_ROLE =
  'PROFILE_INFO_CHANGED_DWELLER_ROLE'
export const PROFILE_INFO_CHANGE_MANAGER_ROLE =
  'PROFILE_INFO_CHANGE_MANAGER_ROLE'
export const PROFILE_INFO_CHANGED_MANAGER_ROLE =
  'PROFILE_INFO_CHANGED_MANAGER_ROLE'
export const REDIRECT_RESET = 'REDIRECT_RESET'
export const PROFILE_INFO_CHANGED_EXTERNAL_ID =
  'PROFILE_INFO_CHANGED_EXTERNAL_ID'
export const PROFILE_INFO_CHANGE_EXTERNAL_ID = 'PROFILE_INFO_CHANGE_EXTERNAL_ID'
export const PROFILE_INFO_EXTERNAL_ID_ERROR = 'PROFILE_INFO_EXTERNAL_ID_ERROR'
export const PROFILE_INFO_EXTERNAL_ID_ERROR_CLEANUP =
  'PROFILE_INFO_EXTERNAL_ID_ERROR_CLEANUP'
