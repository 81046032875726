// @flow

import { parse, stringify } from 'qs'
import { isNil, omitBy, get, pick, intersection } from 'lodash-es'
import type { Location } from 'react-router-dom'

import history from '../history'

const PAGE_KEY = 'page'
const ORDERING_FILTER_KEY = 'ordering'

export const checkCurrentPage = (location: Location): boolean =>
  !!getCurrentFilters(location, [PAGE_KEY])[PAGE_KEY]

export const getCurrentPage = (location: Location): number => {
  const page = getCurrentFilters(location, [PAGE_KEY])[PAGE_KEY]

  return page ? parseInt(page) : 1
}

export const getCurrentFilters = (
  location: Location,
  acceptedfilterKeys?: Array<string>
): Object => {
  const filters = parse(location.search, { ignoreQueryPrefix: true })

  if (acceptedfilterKeys) {
    return pick(filters, acceptedfilterKeys)
  }

  return filters
}

export const getSorting = (location: Location): Array<string> => {
  const sorting = getCurrentFilters(location, [ORDERING_FILTER_KEY])[
    ORDERING_FILTER_KEY
  ]

  if (!sorting) {
    return []
  }

  return sorting.split(',')
}

export const getKeySorting = (key: string, location: Location): ?string => {
  const sorting = getSorting(location)

  const reverseKey = `-${key}`

  const intersections = intersection(sorting, [key, reverseKey])

  if (intersections.length) {
    return intersections[0]
  } else {
    return null
  }
}

export const getEntityGoBackCallback = (
  history: Object,
  listPathname: string
) => {
  const { location } = history

  return !location.key || history.length === 1
    ? () => history.push(`/${listPathname}`)
    : null
}

export const createOrderingFilter = (key: string, location: Location) => {
  const sorting = getSorting(location)
  const reverseKey = `-${key}`

  if (sorting.includes(key) || sorting.includes(reverseKey)) {
    const currentSorting = getKeySorting(key, location)
    const isReverseKey = currentSorting === reverseKey
    const newSortingKey = isReverseKey ? key : reverseKey

    return sorting
      .filter(s => s !== key && s !== reverseKey)
      .concat(newSortingKey)
      .join(',')
  }

  return sorting.concat(key).join(',')
}

export const formatPathname = (pathname: string): string =>
  pathname[pathname.length - 1] === '/'
    ? pathname.slice(1, pathname.length - 1)
    : pathname.slice(1)

export const getNewQueryString = (params: Object = {}) =>
  stringify(omitBy(params, isNil))

export const getBoolean = (object: Object, key: string): boolean =>
  !!(object[key] && JSON.parse(object[key]))

export const getNewPage = (
  location: Location,
  meta: Object,
  defaultPageLength: number,
  idsLength: number
): number => {
  const currentPage = Number(getCurrentPage(location))
  const { page_count: pageCount, limit, count } = meta

  const isSelectedAllMails =
    idsLength === count % limit || idsLength === defaultPageLength

  if (currentPage === pageCount && currentPage !== 1 && isSelectedAllMails) {
    return currentPage - 1
  }

  return currentPage
}

export const redirectTo404 = (error: Object): boolean => {
  if (get(error, ['message', 'response', 'status']) === 404) {
    history.push('/404')

    return true
  }

  return false
}
