// @flow

import { isArray, isString } from 'lodash-es'

const getIndex = (index: number) => (index < 0 ? 0 : index)

// TODO Remove with tests
export const getVersion = (config: Object = {}) => {
  const { version = '', front_version: subversion } = config
  const text = subversion ? `${version} #${subversion}` : version

  return text.trim()
}

export const getAddressPart = (
  address: ?Object = {},
  from: number = 0,
  to?: number
) => {
  if (address) {
    if (address.value) {
      return getAddressPart(address.value, from, to)
    }

    if (isString(address)) {
      return getAddressPart(address.split(','), from, to)
    }

    if (isArray(address)) {
      const sliced = address.slice(getIndex(from), getIndex(to))

      return sliced.join(',').trim()
    }
  }

  return ''
}
