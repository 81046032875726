// @flow

import React from 'react'
import classnames from 'classnames'

import Logo from '../../Logo'

import styles from './MenuLogo.module.scss'

type Props = {
  collapsed: boolean,
}

const MenuLogo = ({ collapsed }: Props) => {
  const menuLogoClass = classnames(styles.logo, {
    [styles.collapsed]: collapsed,
  })

  return (
    <div className={menuLogoClass}>
      <Logo tiny={collapsed} />
    </div>
  )
}

export default MenuLogo
