// @flow

import * as actions from './Login.actionTypes'

export function login({
  username,
  password,
}: {
  password: string,
  username: string,
}) {
  return {
    type: actions.LOGIN_INIT,
    username,
    password,
  }
}

export function clear() {
  return {
    type: actions.CLEAR_LOGIN,
  }
}

export function logout(pathName = null) {
  return {
    type: actions.LOGOUT,
    pathName,
  }
}
