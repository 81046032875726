// @flow

import * as actions from './FriendsNotifications.actionTypes'

const initialState = {
  working: false,
  opened: false,
  items: [],
  meta: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.FRIEND_NOTIFICATION_INITIATE:
      return {
        ...initialState,
      }
    case actions.FRIEND_NOTIFICATION_INITIATED:
      return {
        ...state,
      }
    case actions.FRIEND_NOTIFICATION_OPEN:
      return {
        ...state,
        opened: true,
      }
    case actions.FRIEND_NOTIFICATION_CLOSE:
      return {
        ...state,
        opened: false,
        items: [],
        meta: {},
      }
    case actions.FRIEND_NOTIFICATION_LOAD:
    case actions.FRIEND_NOTIFICATION_HANDLE:
      return {
        ...state,
        working: true,
      }
    case actions.FRIEND_NOTIFICATION_LOADED:
      return {
        ...state,
        working: false,
        items: state.opened ? [...action.results.objects] : [],
        meta: state.opened ? action.meta : state.meta,
      }
    default:
      return state
  }
}
