// @flow

import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'

import WeatherCard from '../WeatherCard'
import InfoCard from '../InfoCard'
import DownloadBanner from '../DownloadBanner'
import { loadBuilding, loadContacts } from '../PublicBuildingPage.actions'
import * as selectors from '../PublicBuildingPage.selectors'
import { CONTACT_TYPES } from '../../../constants'
import Phone from '../../../components/Phone'
import Icon from '../../../components/Icon'

const INTERVAL = 1000 * 60 * 10 // 10 minutes

type Props = {
  address: string,
  contacts: Array<{
    contact_type: number,
    id: number,
    values: string,
  }>,
  managers: Array<{
    avatar_obj: {
      preview: string,
    },
    id: number,
    name: string,
    second_name: string,
  }>,
  t: string => string,
  theme: {
    header: string,
  },
}

class ContactInfo extends PureComponent<Props> {
  componentDidMount() {
    this.getInfo()
    this.interval = setInterval(this.getInfo, INTERVAL)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  getInfo = () => {
    this.props.loadBuilding()
    this.props.loadContacts()
  }

  getFullName = manager => `${manager.name}  ${manager.second_name}`

  formatContactValue = (type, value) => {
    if (type === 2 || type === 6) {
      return <Phone phone={`${value[0] !== '+' ? '+' : ''}${value}`} />
    }

    return value
  }

  renderContactIcon = type => {
    const {
      theme: { header },
    } = this.props

    const style = { fill: header }

    switch (type) {
      case CONTACT_TYPES.MAIL:
        return <Icon id='email' style={style} />
      case CONTACT_TYPES.PHONE:
      case CONTACT_TYPES.EMERGENCY_PHONE:
        return <Icon id='phone' style={style} />
      case CONTACT_TYPES.WEB:
        return <Icon id='website' style={style} />
      case 'address':
        return <Icon id='address' style={style} />
      default:
        return <div className='public-building-page__icon' />
    }
  }

  renderManagers = () => {
    const { managers, t } = this.props

    if (managers.length === 0) {
      return null
    }

    return (
      <div className='public-building-page__info-managers'>
        <p>{t('managers')}</p>
        {managers.map(manager => (
          <InfoCard value={this.getFullName(manager)} key={manager.id}>
            <img
              src={manager.avatar_obj.preview}
              alt={this.getFullName(manager)}
              className='public-building-page__avatar'
            />
          </InfoCard>
        ))}
      </div>
    )
  }

  renderContacts = () => {
    const { contacts, t } = this.props

    if (contacts.length === 0) {
      return null
    }

    return (
      <div className='public-building-page__info-contacts'>
        <p>{t('Contact_us')}</p>
        {contacts.map(({ values, contact_type: contactType, id }) => (
          <InfoCard
            value={this.formatContactValue(contactType, values)}
            key={id}
          >
            <div className='public-building-page__icon-small'>
              {this.renderContactIcon(contactType)}
            </div>
          </InfoCard>
        ))}
      </div>
    )
  }

  render() {
    const { address } = this.props

    return (
      <aside className='public-building-page__info'>
        <div>
          <WeatherCard />
          <div className='public-building-page__info-address'>
            <InfoCard value={address}>
              <div className='public-building-page__icon'>
                {this.renderContactIcon('address')}
              </div>
            </InfoCard>
          </div>
          <div className='public-building-page__info-contacts-group'>
            {this.renderManagers()}
            {this.renderContacts()}
          </div>
        </div>
        <DownloadBanner />
      </aside>
    )
  }
}

const mapStateToProps = state => ({
  address: selectors.getAddress(state),
  managers: selectors.getManagers(state),
  contacts: selectors.getContacts(state),
  theme: selectors.getUKTheme(state),
})
const mapDispatchToProps = {
  loadBuilding,
  loadContacts,
}

export default compose(
  withTranslation('PublicBuildingPage'),
  connect(mapStateToProps, mapDispatchToProps)
)(ContactInfo)
