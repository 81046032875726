// @flow

import { deleteApi, getApi, postApi, postRaw, patchApi } from './api.core'

export const getInboundConfig = params =>
  getApi('api/id2id/inbound-access-config/', params)

export const getInboundConfigProfile = uuid =>
  getApi(`api/id2id/inbound-access-config/${uuid}/`)

export const getOutboundConfig = params =>
  getApi('api/id2id/outbound-access-config/', params)

export const getOutboundConfigProfile = uuid =>
  getApi(`api/id2id/outbound-access-config/${uuid}/`)

export const addInboundConfig = params =>
  postApi('api/id2id/inbound-access-config/', params)

export const addOutboundConfig = params =>
  postApi('api/id2id/outbound-access-config/', params)

export const massDeleteInbound = ids =>
  postRaw('api/async-mass-action/inbound-access-config/', {
    ids,
    payload: {
      deleted: true,
    },
  })

export const massDeleteOutbound = ids =>
  postRaw('api/async-mass-action/outbound-access-config/', {
    ids,
    payload: {
      deleted: true,
    },
  })

export const updateInboundConfigProfile = (uuid, params) =>
  patchApi(`api/id2id/inbound-access-config/${uuid}/`, params)

export const updateOutboundConfigProfile = (uuid, params) =>
  patchApi(`api/id2id/outbound-access-config/${uuid}/`, params)

export const deleteInboundConfigProfile = uuid =>
  deleteApi(`api/id2id/inbound-access-config/${uuid}/`)

export const deleteOutboundConfigProfile = uuid =>
  deleteApi(`api/id2id/outbound-access-config/${uuid}/`)

export const getRequestListLight = (uuid, params) =>
  getApi(`api/id2id/${uuid}/request-list-light/`, params)

export const getRequestListLightCount = (uuid, params) =>
  getApi(`api/id2id/${uuid}/request-list-light/count/`, params)

export const getRequest = (uuid, requestId) =>
  getApi(`api/id2id/${uuid}/request/${requestId}/`)

export const getOutboundUserProfile = (uuid, id) =>
  getApi(`api/id2id/${uuid}/profile-tiny/${id}/`)

export const getSharedRequestPipeline = (uuid, requestId) =>
  getApi(`api/id2id/${uuid}/request/${requestId}/pipeline/`)

export const getSharedPhaseActivities = (uuid, phaseId) =>
  getApi(`api/id2id/${uuid}/pipeline/phase/${phaseId}/activity/`)

export const updateSharedRequest = (uuid, requestId, params) =>
  patchApi(`api/id2id/${uuid}/request/${requestId}/`, params)

export const getOutboundFilter = (uuid, params) =>
  getApi(`api/id2id/${uuid}/request-single-field/`, params)

export const getOutboundManagers = (uuid, params) =>
  getApi(`api/id2id/${uuid}/request-list/managers/`, params)
