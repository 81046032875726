// @flow

import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'
import Joi from 'joi-browser'
import validation from 'react-validation-mixin'
import validationStrategy from 'joi-browser-validation-strategy'
import { every } from 'lodash-es'

import Block, { Header, CellsBar, Cell } from '../../../components/Block'
import Icon from '../../../../static/icons/real-data.svg'
import Button from '../../../components/Button'
import InputPassword from '../../../components/InputPassword'
import Loader from '../../../components/Loader'
import MatchingRequests from '../../../components/modals/MatchingRealDataRequests'
import Modal from '../../../components/Modal'
import Select from '../../../components/Select/SelectSimple'
import Settings from '../../../components/Settings'
import { formatDateTime } from '../../../utils/utils'
import { SECRET_PASSWORD } from '../Integration.constants'
import * as actions from './RealData.actionTypes'
import * as constants from './RealData.constants'
import BrowserStorage from '../../../utils/browserStorage'
import { getFormattedManagersList } from './RealData.selectors'

import styles from '../Integrations.module.scss'

type Callback = boolean => void

type Props = {
  config: Object,
  copyingRequestTypes: boolean,
  dispatch: Object => void,
  errors: Array<Object>,
  exist: boolean,
  getValidationMessages: string => string,
  initiated: boolean,
  isValid: string => boolean,
  matchingRequests: boolean,
  popupError: ?string,
  requests: Object,
  requestsWereCopied: boolean,
  status: Object,
  t: string => string,
  validate: Callback => void,
  working: boolean,
}

type Config = {
  download_from_ftp: boolean,
  ftp_password: string,
  ftp_port: number,
  ftp_url: string,
  ftp_username: string,
  overwrite_building: boolean,
  overwrite_profile: boolean,
  overwrite_request: boolean,
  password: string,
  restore_data: boolean,
  update_frequency: number,
  url: string,
  username: string,
}

type State = {
  collapsed: boolean,
  config: Config,
  openPopup: boolean,
}

class RealData extends Component<Props, State> {
  constructor(props) {
    super(props)

    const reqText = `!! ${this.props.t('Common:FieldIsRequired')}`
    const options = {
      language: {
        any: {
          invalid: reqText,
          required: reqText,
          empty: reqText,
        },
        string: {
          base: reqText,
          empty: reqText,
          required: reqText,
          uri: `!! ${this.props.t('Common:incorrectUrl')}`,
        },
        number: {
          base: reqText,
          empty: reqText,
          required: reqText,
          invalid: reqText,
        },
      },
    }

    this.validatorTypes = {
      password: Joi.string().required().options(options),
      url: Joi.string().uri().required().options(options),
      username: Joi.string().required().options(options),
      download_from_ftp: Joi.boolean().options(options),
      ftp_username: Joi.when(Joi.ref('download_from_ftp'), {
        is: true,
        then: Joi.string().options(options).required().empty(),
      }),
      ftp_port: Joi.when(Joi.ref('download_from_ftp'), {
        is: true,
        then: Joi.number().options(options).required().empty(),
      }),
      manager_reporter: Joi.when(Joi.ref('export_requests'), {
        is: true,
        then: Joi.options(options).required().empty(),
      }),
    }
  }

  state = {
    config: {
      overwrite_building: true,
      overwrite_profile: true,
      overwrite_request: false,
      password: '',
      restore_data: false,
      update_frequency: 0,
      url: '',
      username: '',
      ftp_url: '',
      ftp_username: '',
      ftp_port: '',
      ftp_password: '',
      download_from_ftp: false,
    },
    openPopup: false,
    collapsed: true,
  }

  overwriteOptions = [
    { value: true, label: this.props.t('Common:yes') },
    { value: false, label: this.props.t('Common:no') },
  ]

  syncOptions = [
    { value: 1, label: this.props.t('Common:yes') },
    { value: 0, label: this.props.t('Common:no') },
  ]

  componentDidUpdate(prevProps) {
    const { dispatch, initiated } = prevProps

    if (!initiated && this.props.initiated) {
      if (this.props.exist) {
        this.setState({
          config: { ...this.props.config, overwrite_request: false },
        })
      } else {
        const { config } = this.state
        dispatch({
          type: actions.REAL_DATA_CONFIG_WAS_APPLIED,
          config,
        })
      }
    }
  }

  componentDidMount() {
    const collapsed = JSON.parse(
      BrowserStorage.get(constants.REAL_DATA_LOCALE_STORAGE_KEY)
    )

    const { initiated, config } = this.props

    if (collapsed === null) {
      BrowserStorage.set(constants.REAL_DATA_LOCALE_STORAGE_KEY, true)
      this.setState({ collapsed: true })
    } else if (collapsed === false && !initiated) {
      this.setState({ collapsed: false })
      this.props.dispatch({ type: actions.REAL_DATA_INITIATE })
    } else if (collapsed === false && initiated) {
      this.setState({ collapsed: false, config: { ...config } })
    } else if (collapsed === true && initiated) {
      this.setState({ collapsed: true, config: { ...config } })
    }
  }

  onClickTitle = () => {
    const { collapsed } = this.state
    const { initiated } = this.props

    const update = () => {
      BrowserStorage.set(constants.REAL_DATA_LOCALE_STORAGE_KEY, !collapsed)

      if (!initiated) {
        this.props.dispatch({ type: actions.REAL_DATA_INITIATE })
      }
    }

    this.setState({ collapsed: !collapsed }, update)
  }

  getRowsClassName = () =>
    classnames({
      'working-overlay':
        this.props.status.is_sync_in_progress || this.props.working,
    })

  getInputSettingsProps = field => {
    const { isValid, getValidationMessages, t } = this.props

    return {
      isValid,
      getError: getValidationMessages,
      field,
      label: t(`realData.${field}`),
    }
  }

  updateField = (key, value) => {
    const { config } = this.state

    this.setState({
      config: {
        ...config,
        [key]: value,
      },
    })
  }

  updateManager = ({ value: id }) => {
    const { managers } = this.props
    const selectedManager = managers.find(manager => manager.realdata_id === id)

    this.updateField('manager_reporter', selectedManager)
  }

  updateInput = ({ target: { name, value } }) => {
    this.updateField(name, value)
  }

  renderActions = () => {
    const {
      working,
      status: {
        last_sync_timestamp: lastSync,
        is_sync_in_progress: inProgress,
      },
      exist,
      requests: { unmapped },
      t,
    } = this.props
    const { config } = this.state
    const sameFields = every(
      config,
      (val, key) => this.props.config[key] === val
    )

    const text = !!lastSync && (
      <div className='submit--additional'>
        <span className='settings-personal__form-label'>
          {t('realData.wasSynced')}:{' '}
        </span>
        <span className='input'>{formatDateTime(lastSync)}</span>
      </div>
    )

    if (inProgress) {
      return (
        <div className='settings-personal__submit'>
          <Button.Save working={working} onClick={this.checkStatus}>
            {t('realData.checkSync')}
          </Button.Save>
          {text}
        </div>
      )
    }

    if (exist && sameFields) {
      return (
        <div className='settings-personal__submit'>
          <Button.Save
            disabled={!!unmapped.length}
            working={working}
            onClick={this.sync}
          >
            {t('realData.startSync')}
          </Button.Save>
          {text}
        </div>
      )
    }

    return (
      <Settings.Actions
        additional={text}
        withChanges={!sameFields}
        working={working}
        onCancel={this.cancel}
        onSave={this.save}
      />
    )
  }

  renderSelect = key => {
    const { config } = this.state
    const options =
      key === 'update_frequency' ? this.syncOptions : this.overwriteOptions

    const value = options.find(o => o.value === config[key])

    return (
      <Select
        noClearButton
        options={options}
        value={value}
        placeholder={this.props.t('realData.select_option')}
        onChange={o => this.updateField([key], o.value)}
      />
    )
  }

  renderCommon = () => {
    const { t, config } = this.props
    const { username, password, url } = this.state.config
    const defaultPassword = config.username ? SECRET_PASSWORD : ''

    return (
      <div>
        <div className='bar__title bar__title-indented'>
          <span className='bar__title-text'>{t('realData.common')}</span>
        </div>

        <div className={this.getRowsClassName()}>
          <Settings.Row {...this.getInputSettingsProps('username')}>
            <input
              autoComplete='off'
              autoCorrect='off'
              name='username'
              value={username}
              spellCheck={false}
              onChange={this.updateInput}
            />
          </Settings.Row>

          <Settings.Row {...this.getInputSettingsProps('password')}>
            <InputPassword
              alwaysSecure
              autoComplete='off'
              name='password'
              value={password || defaultPassword}
              onChange={this.updateInput}
            />
          </Settings.Row>

          <Settings.Row {...this.getInputSettingsProps('url')}>
            <input
              autoComplete='off'
              autoCorrect='off'
              name='url'
              value={url}
              spellCheck={false}
              onChange={this.updateInput}
            />
          </Settings.Row>

          <Settings.Row label={t('realData.autoSync')}>
            {this.renderSelect('update_frequency')}
          </Settings.Row>

          <Settings.Hint text={t('realData.restoreHint')} />
          <Settings.Row label={t('realData.restore')}>
            {this.renderSelect('restore_data')}
          </Settings.Row>
        </div>
      </div>
    )
  }

  renderStatus = () => {
    const {
      status: {
        is_sync_in_progress: inProgress,
        last_sync_timestamp: lastSync,
      },
      t,
    } = this.props

    let text = 'realData.empty'

    if (inProgress) {
      text = 'realData.inProgress'
    } else if (lastSync) {
      text = 'realData.done'
    }

    const className = classnames('bar__cell-value', {
      'value-progress': inProgress,
      'value-done': lastSync,
    })

    return (
      <Cell title={t('realData.status')} valueClassName={className}>
        {t(text)}
      </Cell>
    )
  }

  renderContent = () => {
    const { collapsed } = this.state
    const {
      initiated,
      status: { last_sync_timestamp: lastSync, synced_obj_count: objects },
    } = this.props

    if (collapsed) {
      return null
    }

    if (!initiated) {
      return <Loader text={false} type='medium' />
    }

    return (
      <Fragment>
        <CellsBar>
          <Cell title={this.props.t('realData.lastSync')}>
            {lastSync ? formatDateTime(lastSync) : '-'}
          </Cell>
          {this.renderStatus()}
          <Cell title={this.props.t('realData.handled')}>
            {lastSync ? objects : '-'}
          </Cell>
        </CellsBar>

        <div className='settings-personal__form'>
          {this.renderCommon()}
          <div className='bar__delimiter' />
          {this.renderFtp()}
          <div className='bar__delimiter' />
          {this.renderBuildings()}
          <div className='bar__delimiter' />
          {this.renderRequests()}
        </div>
        {this.renderModal()}
        <div className='bar__delimiter' />
        {this.renderActions()}
      </Fragment>
    )
  }

  cancel = () => {
    const { config } = this.props

    this.setState({
      config,
    })
  }

  sync = () => {
    this.props.dispatch({ type: actions.REAL_DATA_SYNCHRONIZE })
  }

  checkStatus = () => {
    this.props.dispatch({ type: actions.REAL_DATA_CHECK_STATUS })
  }

  copyRequestTypes = items => {
    this.props.dispatch({
      type: actions.REAL_DATA_COPY_REQUEST_TYPES,
      items: JSON.stringify(items),
    })
  }

  matchRequests = items => {
    this.props.dispatch({
      type: actions.REAL_DATA_MATCH_REQUESTS,
      items: JSON.stringify(items),
    })
  }

  save = () => {
    const { validate, working, exist, dispatch } = this.props

    if (working) {
      return
    }

    validate(err => {
      if (!err) {
        const { config } = this.state
        const type = exist
          ? actions.REAL_DATA_APPLY_CONFIG
          : actions.REAL_DATA_CREATE

        dispatch({ type, config })
      }
    })
  }

  renderRequests = () => {
    const { formattedManagers } = this.props
    const { config } = this.state
    const selectedManager = config.manager_reporter
      ? {
          value: config.manager_reporter.realdata_id,
          label: config.manager_reporter.name,
        }
      : null

    return (
      <div>
        <div className='bar__title bar__title-indented'>
          <span className='bar__title-text'>
            {this.props.t('realData.requestsTitle')}
          </span>
        </div>

        <div className={this.getRowsClassName()}>
          <Settings.Row label={this.props.t('realData.requests')}>
            {this.renderSelect('export_requests')}
          </Settings.Row>

          {
            // TODO this functionality will work in the future
            false && (
              <Settings.Row label={this.props.t('realData.matchingTypes')}>
                {this.renderMatching()}
              </Settings.Row>
            )
          }

          <Settings.Row {...this.getInputSettingsProps('manager_reporter')}>
            <Select
              noClearButton
              options={formattedManagers}
              name='manager_reporter'
              placeholder={this.props.t('realData.select_option')}
              value={selectedManager}
              onChange={this.updateManager}
            />
          </Settings.Row>
        </div>
      </div>
    )
  }

  renderFtp = () => {
    const { t, config } = this.props

    const {
      ftp_username: ftpUsername,
      ftp_password: ftpPassword,
      ftp_port: ftpPort,
      ftp_url: ftpUrl,
    } = this.state.config
    const defaultPassword = config.ftp_username ? SECRET_PASSWORD : ''

    return (
      <div>
        <div className='bar__title bar__title-indented'>
          <span className='bar__title-text'>{t('realData.ftpTitle')}</span>
        </div>

        <div className={this.getRowsClassName()}>
          <Settings.Row label={t('realData.download_from_ftp')}>
            {this.renderSelect('download_from_ftp')}
          </Settings.Row>

          <Settings.Row {...this.getInputSettingsProps('ftp_username')}>
            <input
              autoComplete='off'
              autoCorrect='off'
              name='ftp_username'
              value={ftpUsername || ''}
              spellCheck={false}
              onChange={this.updateInput}
            />
          </Settings.Row>

          <Settings.Row {...this.getInputSettingsProps('ftp_password')}>
            <InputPassword
              alwaysSecure
              autoComplete='off'
              name='ftp_password'
              value={ftpPassword || defaultPassword}
              onChange={this.updateInput}
            />
          </Settings.Row>

          <Settings.Row {...this.getInputSettingsProps('ftp_url')}>
            <input
              autoComplete='off'
              autoCorrect='off'
              name='ftp_url'
              value={ftpUrl || ''}
              spellCheck={false}
              onChange={this.updateInput}
            />
          </Settings.Row>

          <Settings.Row {...this.getInputSettingsProps('ftp_port')}>
            <input
              autoComplete='off'
              autoCorrect='off'
              name='ftp_port'
              value={ftpPort || ''}
              spellCheck={false}
              onChange={this.updateInput}
            />
          </Settings.Row>
        </div>
      </div>
    )
  }

  renderBuildings = () => {
    const { t } = this.props

    return (
      <div>
        <div className='bar__title bar__title-indented'>
          <span className='bar__title-text'>
            {t('realData.buildingsTitle')}
          </span>
        </div>
        <div className={this.getRowsClassName()}>
          <Settings.Hint text={t('realData.buildingsHint')} />
          <Settings.Row label={t('realData.buildings')}>
            {this.renderSelect('overwrite_building')}
          </Settings.Row>

          <Settings.Row label={t('realData.profiles')}>
            {this.renderSelect('overwrite_profile')}
          </Settings.Row>
        </div>
      </div>
    )
  }

  openMatchingRequests = () => {
    this.setState({ openPopup: true })
  }

  hidePopup = () => {
    this.setState({ openPopup: false })
    this.props.dispatch({ type: actions.REAL_DATA_RESET_POPUP_ERROR })
  }

  getValidatorData = () => this.state.config

  renderModal = () => {
    const {
      requests,
      requestsWereCopied,
      matchingRequests,
      copyingRequestTypes,
      popupError,
    } = this.props
    const { openPopup } = this.state

    return (
      <Modal contentLabel='' isOpen={openPopup} onRequestClose={this.hidePopup}>
        <MatchingRequests
          copying={copyingRequestTypes}
          error={popupError}
          items={requests}
          itemsWereCopied={requestsWereCopied}
          working={matchingRequests}
          onClose={this.hidePopup}
          onCopyTypes={this.copyRequestTypes}
          onSave={this.matchRequests}
        />
      </Modal>
    )
  }

  renderMatching = () => {
    const {
      requests: { unmapped },
      exist,
      t,
    } = this.props
    const {
      config: {
        overwrite_request: overwrite,
        manager_reporter: manager,
        export_requests: exportRequests,
      },
    } = this.state
    const labelClassName = exist
      ? classnames(
          { 'map-tag-cat__map': !unmapped.length },
          { 'map-tag-cat__not-map': unmapped.length }
        )
      : ''
    const isMatchingDisabled =
      !exist || !overwrite || (!manager && exportRequests)

    return (
      <div className='map-tag-cat'>
        <div className={labelClassName}>
          {unmapped.length
            ? t('realData.typesWereNotMatched')
            : t('realData.typesWereMatched')}
        </div>
        <Button.Regular
          className={unmapped.length ? 'button--success' : 'button--success-2'}
          disabled={isMatchingDisabled}
          style={{ marginLeft: '10px' }}
          onClick={this.openMatchingRequests}
        >
          {unmapped.length ? t('realData.matchTypes') : t('Common:edit')}
        </Button.Regular>
      </div>
    )
  }

  render() {
    const { working } = this.props
    const { collapsed } = this.state

    return (
      <Block
        className={styles.block}
        working={working}
        collapsed={collapsed}
        onClickHeader={this.onClickTitle}
      >
        <Header header>
          <span icon='true' className={styles.headerIcon}>
            <Icon />
          </span>
          Real Data Integration
        </Header>

        {this.renderContent()}
      </Block>
    )
  }
}

const mapStateToProps = state => ({
  ...state.realData,
  formattedManagers: getFormattedManagersList(state),
})

export default compose(
  withTranslation('Integrations'),
  connect(mapStateToProps),
  validation(validationStrategy)
)(RealData)
