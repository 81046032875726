// @flow

import { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import {
  LIGHT_TIMEOUT,
  TEST_FAILED,
  TEST_IN_PROGRESS,
  TEST_SUCCESS,
} from './EmailIntegration.constants'
import { testConnection } from '../../core/api/api.mail'

export const useConnection = (
  id: number
): [boolean, boolean, string, string, Function, Function, Function] => {
  const [isModalOpen, setModalOpen] = useState(false)
  const [isChecking, setChecking] = useState(false)
  const [connectionStatus, setConnectionStatus] = useState(null)
  const [errorLink, setErrorLink] = useState('')

  const { t } = useTranslation('SettingsExternalEmail')

  const timeout = useRef(null)

  useEffect(() => {
    return function cleanup() {
      clearTimeout(timeout.current)
    }
  }, [])

  useEffect(() => {
    if (connectionStatus === TEST_SUCCESS || connectionStatus === TEST_FAILED) {
      setModalOpen(true)
    }
  }, [connectionStatus])

  const cancelTest = () => {
    clearTimeout(timeout.current)
    setChecking(false)
  }

  const test = () => {
    if (!isChecking) {
      setChecking(true)
    }

    testConnection({ credentials_id: id }).then(data => {
      if (!data) {
        setChecking(false)

        return
      }

      const { status, help_text } = data
      LIGHT_TIMEOUT

      if (status === TEST_IN_PROGRESS) {
        timeout.current = setTimeout(test, LIGHT_TIMEOUT)
      } else if (status === TEST_FAILED) {
        setErrorLink(help_text)
        setChecking(false)
      } else {
        setChecking(false)
      }

      if (connectionStatus !== status) {
        setConnectionStatus(status)
      }
    })
  }

  const closeModal = () => {
    setModalOpen(false)
    setConnectionStatus(null)
    setErrorLink('')
  }

  const connectionTitle =
    connectionStatus === TEST_SUCCESS
      ? t('SuccessConnectionTitle')
      : t('FailedConnectionTitle')

  const connectionText =
    connectionStatus === TEST_SUCCESS
      ? t('SuccessConnectionText')
      : `${t(
          'FailedConnectionText'
        )}<br><a class='modal__label  modal__label--link modal__label--danger' href='${errorLink}' target='_blank' rel='noopener noreferrer'>${errorLink}</a>`

  return [
    isChecking,
    isModalOpen,
    connectionTitle,
    connectionText,
    test,
    cancelTest,
    closeModal,
  ]
}
