// @flow

import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import type { Node } from 'react'

import CommonPhoneInput from '../../../../../forms/PhoneInput'
import ModalLabel from '../../../../../Modal/ModalLabel'
import ModalRow from '../../../../../Modal/ModalRow'
import ModalError from '../../../../../Modal/ModalError'
import CardStageContext from '../../../CardStage.context'
import NewWizardContext from '../../../../NewWizard.context'
import { getUkCountry } from '../../../../../../utils/commonSelectors'
import type { CardType } from '../../../../NewWizard.types'

import styles from './PhoneInput.module.scss'

type Props = { card: CardType }

const PhoneInput = (props: Props): Node => {
  const {
    card: { name, label },
  } = props

  const { t } = useTranslation('WizardPopup')

  const { serverError } = useContext(NewWizardContext)
  const { errors, values, setFieldValue } = useContext(CardStageContext)

  const handleChange = value => setFieldValue(name, value.replace('+', ''))

  const error = errors[name] || (serverError && serverError[name])

  const country = useSelector(getUkCountry)

  return (
    <>
      <ModalRow>
        <ModalLabel>{label}</ModalLabel>
      </ModalRow>
      <ModalRow>
        <CommonPhoneInput
          className={styles.phone}
          name={name}
          countryCode={country.code}
          value={values[name]}
          onChange={handleChange}
        />
      </ModalRow>
      {error && <ModalError error={t(error)} />}
    </>
  )
}

export default PhoneInput
