// @flow

import React, { useState, useEffect, useReducer } from 'react'
import {
  ADDRESS_SECTION_CANCEL,
  ADDRESS_SECTION_EDIT,
  ADDRESS_SECTION_ERROR,
  ADDRESS_SECTION_CHANGE,
  ADDRESS_SECTION_INITIATE,
} from './AddressSection.actionTypes'
import { reducer, initialState } from './AddressSection.reducer'

import type { Address } from './AddressSection.reducer'

import { isEmpty } from 'lodash-es'
import * as yup from 'yup'
import classnames from 'classnames'

import { getBuildingGroups } from '../../../core/api/api.building'

import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { usePrevious } from '../../../hooks/usePrevious'
import { useSelected } from '../../../hooks'

import { getUkCity } from '../../../utils/commonSelectors'
import { getAction } from './helpers'

import Geosuggest from '../../Geosuggest/Geosuggest'
import NewSelectAsync from '../../NewSelectAsync'
import Button from '../../Button'

import AddressSectionHead from './AddressSectionHead'
import AddressSectionMap from './AddressSectionMap'
import StaticMap from '../../StaticMap'

import styles from './AddressSection.module.scss'

type Props = {
  balloon: Node,
  collapsed: boolean,
  editable: boolean,
  editMode: boolean,
  initialAddress: Address,
  initiated: boolean,
  noFlats?: boolean,
  noMargin: boolean,
  onCollapse: () => void,
  save: Object => void,
  title: string,
  working: boolean,
}

let schemaAddress = yup.object().shape({
  value: yup.string().required(),
})

const AddressSection = ({
  initialAddress,
  title,
  editable,
  collapsed,
  initiated,
  onCollapse,
  noMargin,
  working,
  balloon,
  save,
}: Props): React.Element => {
  const { t } = useTranslation('Common')

  const [{ address, isError, editMode, isPromo, mapReady }, dispatch] =
    useReducer(reducer, initialState)

  const city = useState(getUkCity(useSelector(state => state)))
  const prevWorking = usePrevious(working)
  const [selectedGroups, changeSelected, setSelected] = useSelected()
  const [groupCount, setGroupCount] = useState(null)

  useEffect(() => {
    if (working && !prevWorking) {
      onCancel()
    }
  }, [working])

  useEffect(() => {
    if (initialAddress) {
      dispatch(getAction(ADDRESS_SECTION_CHANGE, { address: initialAddress }))
    }
  }, [initialAddress])

  const isCollapsible = !!onCollapse

  const isCreating = isEmpty(initialAddress)

  const isShowPencil = editable && !editMode && !isCreating

  const isDisableSave = initialAddress?.value === address?.value

  const onCancel = () =>
    dispatch(getAction(ADDRESS_SECTION_CANCEL, { address: initialAddress }))

  const onMapReady = () => dispatch(getAction(ADDRESS_SECTION_INITIATE))

  const onEdit = e => {
    e && e.stopPropagation()
    dispatch(getAction(ADDRESS_SECTION_EDIT))
  }

  const onSave = () => {
    schemaAddress.isValid(address).then(valid => {
      if (valid) {
        save(
          address,
          selectedGroups.map(g => g.id),
          isPromo
        )
      } else {
        dispatch(getAction(ADDRESS_SECTION_ERROR))
      }
    })
  }

  const collapse = () => isCollapsible && onCollapse()

  const updateAddress = address => {
    dispatch(getAction(ADDRESS_SECTION_CHANGE, { address }))
  }

  const onChangeGeosuggest = suggest => {
    let params = {
      value: suggest.label,
    }

    if (suggest.lattitude && suggest.longitude) {
      const lattitude = parseFloat(suggest.lattitude)
      const longitude = parseFloat(suggest.longitude)

      params = {
        ...params,
        widget_lattitude_str: `${lattitude}`,
        widget_longitude_str: `${longitude}`,
        widget_lattitude: lattitude,
        widget_longitude: longitude,
        lattitude,
        longitude,
      }
    }

    updateAddress(params)
  }

  const updateGeosuggest = value => updateAddress({ value: value })

  const getGroupLabel = group => group.name

  let sectionClass = classnames('cprofile-view-houses unit unit--default', {
    'working-overlay': working,
    'unit--collapsed': isCollapsible,
  })

  sectionClass = `${sectionClass}${noMargin ? ' unit--nomargin' : ''}`

  if (collapsed) {
    return (
      <section className={sectionClass}>
        <AddressSectionHead
          open={isCollapsible && !collapsed}
          title={title}
          isShowPencil={isShowPencil}
          collapse={collapse}
          edit={onEdit}
        />
      </section>
    )
  }

  return (
    <section className={sectionClass}>
      <AddressSectionHead
        open={isCollapsible && !collapsed}
        title={title}
        isShowPencil={isShowPencil}
        collapse={collapse}
        edit={onEdit}
      />
      {editMode && (
        <>
          <div className='u-a12__row--02'>
            <Geosuggest
              mapReady={mapReady}
              error={isError}
              initialValue={address?.value}
              onInputChange={updateGeosuggest}
              onSelect={onChangeGeosuggest}
            />
          </div>
          {isCreating && (
            <div className='u-a12__row--02 u-a12__row--04'>
              <NewSelectAsync
                isMulti
                className={
                  selectedGroups.length > 0
                    ? styles.groupSelected
                    : styles.groupSelect
                }
                placeholder={t('BuildingGroupPlaceholder')}
                api={getBuildingGroups}
                pageSize={10}
                view='dropdown'
                permanentParams={{ ordering: 'name' }}
                searchKey='search'
                selectedItems={selectedGroups}
                getLabel={getGroupLabel}
                getSelectedLabel={getGroupLabel}
                setSelectedItems={setSelected}
                isAllSelected={selected => groupCount === selected.length}
                setMetaCount={setGroupCount}
                onClick={changeSelected}
              />
              {selectedGroups.length > 0 && (
                <div className={styles.removeButton}>
                  <Button.Delete onClick={() => setSelected([])} />
                </div>
              )}
            </div>
          )}
        </>
      )}
      {!editMode && !isCreating && (
        <h3 className='u-a12__title--01'>
          <span>{address.value}</span>
        </h3>
      )}
      {editMode && (
        <div className='cprofile-edit-address__submit'>
          <Button.Save
            disabled={isDisableSave}
            working={working}
            onClick={onSave}
          >
            {t('Common:Save')}
          </Button.Save>
          <Button.Cancel disabled={working} onClick={onCancel}>
            {t('Common:Cancel')}
          </Button.Cancel>
        </div>
      )}
      <div className='u-a19'>
        {initiated && (
          <>
            {editMode || isCreating ? (
              <AddressSectionMap
                address={address}
                collapsed={collapsed}
                editable={editable}
                editMode={editMode}
                isCreating={isCreating}
                updateAddress={updateAddress}
                city={city}
                edit={onEdit}
                balloon={balloon}
                onMapReady={onMapReady}
              />
            ) : (
              <div className={styles.mapCont}>
                <StaticMap address={address} size='828x383' />
              </div>
            )}
          </>
        )}
      </div>
    </section>
  )
}

export default AddressSection
