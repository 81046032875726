// @flow

import React from 'react'
import Icon from './Icon'

type Props = {
  header: string,
  icon?: ?string,
  text: string,
}

const SectionHeader = (props: Props) => {
  const { header, text, icon } = props

  return (
    <h2 header={header} className='unit__title'>
      {text}
      {icon && (
        <Icon
          id={icon}
          className='bar__title-symbol'
          style={{
            fill: '#e84f3d',
            minWidth: '16px',
            width: '16px',
            height: '16px',
            marginRight: '7px',
            marginLeft: '7px',
          }}
        />
      )}
    </h2>
  )
}

export default SectionHeader
