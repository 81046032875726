// @flow

export const STATUS_ACTIVE = 10
export const STATUS_INACTIVE = 0

export const STATUS_COLORS = {
  [STATUS_ACTIVE]: '#9ec221',
  [STATUS_INACTIVE]: '#e44440',
}

export const STATUS_HISTORY_COLOR = [
  null,
  '#d3b801',
  '#9ec221',
  '#e44440',
  '#b0b0b0',
]
export const STATUS_HISTORY_NAME = [
  null,
  'in_progress',
  'success',
  'failed',
  'canceled',
]

export const OMIT_FIELDS = [
  'assignees',
  'building',
  'buildings',
  'building_groups',
  'category',
  'contractors',
  'files',
  'flat',
  'labels',
  'owner',
  'phase_template',
  'pipeline_template',
  'priority',
  'related_requests',
  'responsible_user',
]
