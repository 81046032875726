// @flow

import { CONTACT_TYPES, EXTERNAL_CONTACT_TYPES } from '../../constants'

const { MAIL, PHONE, WEB, AUTH_EMAIL, AUTH_PHONE, EMERGENCY_PHONE } =
  CONTACT_TYPES

const {
  PHONE_EXTERNAL,
  EMAIL_EXTERNAL,
  ADDRESS_EXTERNAL,
  EMERGENCY_PHONE_EXTERNAL,
  WEB_EXTERNAL,
  WORKING_HOURS_EXTERNAL,
  DESCRIPTION_EXTERNAL,
} = EXTERNAL_CONTACT_TYPES
export const PHONE_GROUP = [
  PHONE,
  AUTH_PHONE,
  EMERGENCY_PHONE,
  PHONE_EXTERNAL,
  EMERGENCY_PHONE_EXTERNAL,
]
export const TEXT_GROUP = [
  MAIL,
  WEB,
  AUTH_EMAIL,
  EMAIL_EXTERNAL,
  ADDRESS_EXTERNAL,
  WEB_EXTERNAL,
  WORKING_HOURS_EXTERNAL,
  DESCRIPTION_EXTERNAL,
]
export const MAIL_GROUP = [MAIL, AUTH_EMAIL, EMAIL_EXTERNAL]
export const WEB_GROUP = [WEB, WEB_EXTERNAL]
export const SELECT_GROUP = [
  PHONE,
  WEB,
  MAIL,
  EMAIL_EXTERNAL,
  PHONE_EXTERNAL,
  WEB_EXTERNAL,
]
export const COMPANY_SELECT_GROUP = [
  ...SELECT_GROUP,
  EMERGENCY_PHONE,
  EMERGENCY_PHONE_EXTERNAL,
]
export const DEFAULT_FIELD_GROUP = [AUTH_EMAIL, AUTH_PHONE, EMERGENCY_PHONE]
export const EXCLUDED_FIELD_EXTERNAL_GROUP = [AUTH_EMAIL, AUTH_PHONE]
export const MAX_LENGTH = 20
export const MAX_FIELDS_COUNT = 20

export const CONTACT_TEXTS = {
  [MAIL]: 'email',
  [PHONE]: 'phone',
  [WEB]: 'website',
  [AUTH_EMAIL]: 'authEmail',
  [AUTH_PHONE]: 'authPhone',
  [EMERGENCY_PHONE]: 'emergPhone',
  [EMAIL_EXTERNAL]: 'email',
  [PHONE_EXTERNAL]: 'phone',
  [WEB_EXTERNAL]: 'website',
  [ADDRESS_EXTERNAL]: 'address',
  [EMERGENCY_PHONE_EXTERNAL]: 'emergency_phone',
  [WORKING_HOURS_EXTERNAL]: 'working_hours',
  [DESCRIPTION_EXTERNAL]: 'description',
}

export const CONTACT_TYPES_SVG = {
  [MAIL]: 'email',
  [AUTH_EMAIL]: 'email',
  [PHONE]: 'phone',
  [AUTH_PHONE]: 'phone',
  [WEB]: 'website',
  [EMERGENCY_PHONE]: 'phone',
  [EMAIL_EXTERNAL]: 'email',
  [PHONE_EXTERNAL]: 'phone',
  [WEB_EXTERNAL]: 'website',
  [ADDRESS_EXTERNAL]: 'address',
  [EMERGENCY_PHONE_EXTERNAL]: 'phone',
  [WORKING_HOURS_EXTERNAL]: 'deadline',
  [DESCRIPTION_EXTERNAL]: 'company',
}

export const CONTACT_TYPES_FIELD = {
  [MAIL]: 'MAIL',
  [AUTH_EMAIL]: 'AUTH_EMAIL',
  [PHONE]: 'PHONE',
  [AUTH_PHONE]: 'AUTH_PHONE',
  [WEB]: 'WEB',
  [EMERGENCY_PHONE]: 'EMERGENCY_PHONE',
}

export const ONE_VALUE_FIELDS = [EMAIL_EXTERNAL, ADDRESS_EXTERNAL]
