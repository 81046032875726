// @flow

import React from 'react'
import classnames from 'classnames'
import { useSelector } from 'react-redux'
import type { Node } from 'react'

import { getThemeColor } from '../../../utils/commonSelectors'
import Icon from '../../Icon'
import { CONTAINED_VIEW } from './Regular.constants'
import Avatar from '../../ListItem/Avatar'
import type { IconId } from '../../Icon'

import styles from './Regular.module.scss'

export type Props = {
  avatar?: string,
  children?: Node,
  className?: string,
  color?: 'reject' | 'theme',
  disabled?: boolean,
  error?: boolean,
  icon?: IconId,
  iconClassName?: string,
  meta?: Node,
  name?: string,
  onBlur?: (SyntheticEvent<HTMLButtonElement>) => void,
  onClick?: (e?: { ... }) => void,
  onMouseDown?: (SyntheticEvent<HTMLButtonElement>) => void,
  onMouseEnter?: (SyntheticEvent<HTMLButtonElement>) => void,
  onMouseLeave?: (SyntheticEvent<HTMLButtonElement>) => void,
  style?: Object,
  title?: string,
  type?: 'button' | 'submit' | 'reset',
  view?: 'contained' | 'outlined' | 'desaturated',
  working?: boolean,
}

const RegularButton = (props: Props): Node => {
  const {
    className,
    disabled,
    working,
    children,
    style,
    icon,
    type,
    view,
    error,
    meta,
    color,
    name,
    iconClassName,
    avatar,
    title,
  } = props

  const themeColor: string = useSelector(getThemeColor)

  const btnClass = classnames(
    styles.button,
    styles[view],
    styles[color],
    className,
    {
      [styles.withIcon]: icon,
      [styles.error]: error,
    }
  )

  const themeColorStyle = { '--themeColor': themeColor }

  const buttonProps = Object.assign(
    {},
    style
      ? { style: { ...style, ...themeColorStyle } }
      : { style: { ...themeColorStyle } },
    props.onBlur ? { onBlur: props.onBlur } : undefined,
    props.onClick ? { onClick: props.onClick } : undefined,
    props.onMouseDown ? { onMouseDown: props.onMouseDown } : undefined,
    props.onMouseEnter ? { onMouseEnter: props.onMouseEnter } : undefined,
    props.onMouseLeave ? { onMouseLeave: props.onMouseLeave } : undefined,
    name ? { name } : undefined,
    title ? { title } : undefined
  )

  return (
    <button
      // TODO remove eslint-disable string when eslint-plugin-react package will be fixed
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={btnClass}
      disabled={disabled || working}
      {...buttonProps}
    >
      {working && <span className='button__spinner' />}
      {!working && icon && <Icon id={icon} className={iconClassName} />}
      {!working && !icon && avatar && <Avatar avatar={avatar} />}
      {children && <span className={styles.text}>{children}</span>}
      {meta && <span className={styles.meta}>{meta}</span>}
    </button>
  )
}

RegularButton.defaultProps = {
  type: 'button',
  view: CONTAINED_VIEW,
  color: 'theme',
}

export default RegularButton
