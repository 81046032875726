// @flow

import React from 'react'
import type { Node } from 'react'

import styles from './Success.module.scss'

type Props = { result: Object, t: string => string }

const Success = (props: Props): Node => {
  const {
    result: { first_name, email },
  } = props

  return (
    <div className={styles.success}>
      <div className={styles.title}>
        {props.t('CompanyRegistrationSuccessTitle')}
      </div>
      <div className={styles.text}>
        <p>
          <b>{first_name} </b>, {props.t('YourEmail')}&nbsp;
          <b>{email}</b> {props.t('MessageSend')}
        </p>
        <p>{props.t('GoToLinkInEmail')}</p>
      </div>
    </div>
  )
}

export default Success
