// @flow

import React, { memo, useEffect, useRef } from 'react'

import { useComponentSize } from '../../../../../../hooks'
import { ITEM_MARGIN } from './Info.constants'

import styles from './Info.module.scss'

const MARGIN_BOTTOM = 4

type Props = {
  isShowTitle?: boolean,
  items: Array<Object>,
  setHeight: number => void,
}

const Labels = memo<Props>(props => {
  const { items, isShowTitle = false } = props

  const ref = useRef(null)
  const { height } = useComponentSize(ref)

  useEffect(() => {
    props.setHeight(height ? height + MARGIN_BOTTOM + ITEM_MARGIN : 0)
  })

  return (
    <div
      className={styles.labels}
      ref={ref}
      style={{ marginBottom: `${MARGIN_BOTTOM}px` }}
    >
      {items.map(l => (
        <div
          title={isShowTitle && l.name}
          className={styles.label}
          key={l.id}
          style={{ backgroundColor: l.color.value || l.color }}
        />
      ))}
    </div>
  )
})

export default Labels
