// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useSelector } from 'react-redux'
import type { Node } from 'react'

import { SimpleBlock, Header } from '../../Block'
import Settings from '../../Settings'
import {
  getUkCity,
  getUkCountry,
  getUkLanguage,
} from '../../../utils/commonSelectors'
import SelectRegion from '../../SelectRegion'

type Props = {
  onSubmit: Object => void,
  uk: Object,
  working: boolean,
}

const RegionAndLang = (props: Props): Node => {
  const { working } = props

  const { t } = useTranslation('Settings')

  const initialCountry = useSelector(getUkCountry)
  const initialCity = useSelector(getUkCity)
  const initialLanguage = useSelector(getUkLanguage)

  const formik = useFormik({
    validateOnChange: true,
    enableReinitialize: true,
    initialValues: {
      country: { value: initialCountry.id, label: initialCountry.name },
      city: { value: initialCity.id, label: initialCity.name },
      language: { value: initialLanguage.id, label: initialLanguage.name },
    },
    validationSchema: Yup.object({
      country: Yup.object()
        .shape({ value: Yup.number(), label: Yup.string() })
        .required(),
      city: Yup.object()
        .shape({ value: Yup.number(), label: Yup.string() })
        .nullable()
        .required(),
      language: Yup.object()
        .shape({ value: Yup.number(), label: Yup.string() })
        .required(),
    }),
    onSubmit: values => {
      props.onSubmit({
        country: values.country.value,
        city: values.city.value,
        language: values.language.value,
      })
    },
  })

  const handleChange = name => value => {
    formik.setFieldValue(name, value)

    if (name === 'country') {
      formik.setFieldValue('city', null)
    }
  }

  return (
    <SimpleBlock working={working}>
      <Header header>{t('CompanyOtherTitle')}</Header>
      <form onSubmit={formik.handleSubmit}>
        <Settings.Row label={t('Country')}>
          <SelectRegion
            params='country'
            placeholder={t('Choice')}
            value={formik.values.country}
            onChange={handleChange('country')}
          />
        </Settings.Row>
        {formik.values.country && (
          <Settings.Row label={t('City')} hasError={!!formik.errors.city}>
            <SelectRegion
              params={`country/${formik.values.country.value}/city`}
              placeholder={t('Choice')}
              value={formik.values.city}
              onChange={handleChange('city')}
            />
          </Settings.Row>
        )}
        <Settings.Row label={t('Language')}>
          <SelectRegion
            params='language'
            placeholder={t('Choice')}
            value={formik.values.language}
            onChange={handleChange('language')}
          />
        </Settings.Row>
        <Settings.Actions
          withChanges={formik.dirty}
          isValid={formik.isValid}
          working={working}
          onCancel={formik.handleReset}
        />
      </form>
    </SimpleBlock>
  )
}

export default RegionAndLang
