// @flow

import {
  deleteApi,
  getApi,
  patchApi,
  postApi,
  putRaw,
  postRaw,
  patchRaw,
  deleteRaw,
} from './api.core'

export const getInbox = (params = {}) => getApi('api/email-thread/', params)
export const getInboxNoThread = (params = {}) =>
  getApi('api/email-messages-nothread-view/', params)
export const getArchivedInbox = (params = {}) =>
  getApi('api/archived/email-thread/', params)
export const getSubjects = (params = {}) =>
  getApi('api/email-thread/subjects/', params)
export const getArchivedSubjects = (params = {}) =>
  getApi('api/archived/email-thread/subjects/', params)
export const getThread = (id, params) =>
  getApi(`api/email-thread/${id}/`, params)
export const getEmail = (id, params) =>
  getApi(`api/email-message/${id}/`, params)
export const getArchivedThread = id =>
  getApi(`api/archived/email-thread/${id}/`) // unused
export const readArchivedThread = id =>
  getApi(`api/archived/email-thread/${id}/read/`)
export const createThread = params => postRaw('api/email-thread/', params)
export const getThreadMessage = params => getApi('api/email-message/', params)
export const getArchivedMessage = params =>
  getApi('api/archived/email-message/', params)
export const getArchivedThreadMessage = params =>
  getApi('api/archived/email-message/', params) // unused
export const attachMailToRequest = (requestId, params) =>
  postApi(`api/request/${requestId}/copy_messages/`, params) // unused
export const createRequestFromMail = params => postApi('api/request/', params) // unused
export const createPostInThread = params =>
  postRaw('api/email-message/', params)
export const deletePosts = id => deleteApi(`api/email-message/${id}/`)

export const resendRequestMessage = id =>
  postRaw(`api/request/37/email-message/${id}/resend/`, {})
export const resendMessage = id =>
  postRaw(`api/email-message/${id}/resend/`, {})
export const getMailCounters = () => getApi('api/profile/counters/') // unused
export const getThreadFiles = uuid => getApi(`api/email-thread/${uuid}/files/`)
export const getEmailFiles = uuid => getApi(`api/email-message/${uuid}/files/`)
export const getArchivedThreadFiles = uuid =>
  getApi(`api/archived/email-thread/${uuid}/files/`) // unused
export const getMembers = uuid => getApi(`api/email-thread/${uuid}/members/`)
export const getMessageMembers = uuid =>
  getApi(`api/email-message/${uuid}/members/`)
export const getArchivedMembers = uuid =>
  getApi(`api/archived/email-thread/${uuid}/members/`) // unused
export const restorePost = (uuid, params) =>
  patchRaw(`api/email-message/${uuid}/`, params)
export const restoreArchivedPost = (uuid, params) =>
  patchApi(`api/archived/email-message/${uuid}/`, params) // unused
export const getMailsList = params => getApi(`api/email-message/`, params)
export const getMailAccountList = (params: Object): Object =>
  getApi(`api/mail-account/`, params)
export const getMailIntegrationList = (params: Object): Object =>
  getApi(`api/mail-account/mail-integration/`, params)
export const getCorporateMailAccountList = (params: Object): Object =>
  getApi(`api/mail-account/corporate/`, params)
export const getMailAccount = (id: string) => getApi(`api/mail-account/${id}/`)
export const getMailIntegrationAccount = (id: string) =>
  getApi(`api/mail-account/mail-integration/${id}/`)
export const getCorporateMailAccount = (id: string) =>
  getApi(`api/mail-account/corporate/${id}/`)
export const getMailAccountSimpleList = (params: Object) =>
  getApi(`api/mail-account/simple/`, params)
export const getMailAccountMenuList = params =>
  getApi(`api/mail-account/menu/`, params)
export const getAccountStatus = id =>
  getApi(`api/mail-account/${id}/toggle-is-active/`) // unused
export const toggleAccountStatus = id =>
  postRaw(`api/mail-account/${id}/toggle-is-active/`)
export const toggleCorporateAccountStatus = id =>
  postRaw(`api/mail-account/corporate/${id}/toggle-is-active/`)
export const toggleCorporateAccountMarked = id =>
  postRaw(`api/mail-account/corporate/${id}/toggle-mark-read-by-all/`)
export const toggleAccountShared = id =>
  postRaw(`api/mail-account/${id}/toggle-shared/`)
export const toggleAccountIntegrationShared = id =>
  postRaw(`api/mail-account/mail-integration/${id}/toggle-shared/`)
export const setOutgoingMail = (id: string, params: Object) =>
  putRaw(`api/mail-account/outgoing/${id}/`, params)
export const setAccountSettingsMail = (id: string, params: Object) =>
  putRaw(`api/mail-account/mail-integration/${id}/`, params)
export const patchAccountSettingsMail = (id: string, params: Object) =>
  patchRaw(`api/mail-account/mail-integration/${id}/`, params)
export const setCorporateOutgoingMail = (id: string, params: Object) =>
  putRaw(`api/mail-account/corporate-outgoing/${id}/`, params)
export const setIncomingMail = (id: string, params: Object) =>
  putRaw(`api/mail-account/incoming/${id}/`, params)
export const setCorporateIncomingMail = (id: string, params: Object) =>
  putRaw(`api/mail-account/corporate-incoming/${id}/`, params)
export const createIcomingMail = (params: Object) =>
  postRaw('api/mail-account/incoming/', params)
export const createCorporateIcomingMail = (params: Object) =>
  postRaw('api/mail-account/corporate-incoming/', params)
export const createOutgoingMail = (params: Object) =>
  postRaw('api/mail-account/outgoing/', params)
export const createCorporateOutgoingMail = (params: Object) =>
  postRaw('api/mail-account/corporate-outgoing/', params)
export const checkOutgoingMail = (id: string, params: Object) =>
  putRaw(`api/mail-account/outgoing/${id}/check/`, params) // unused
export const checkIncomingMail = (id: string, params: Object) =>
  putRaw(`api/mail-account/incoming/${id}/check/`, params) // unused
export const getEmailContacts = params =>
  getApi('api/profile/email-contacts/', params)
export const getArchivedEmailContacts = params =>
  getApi('api/archived/profile/email-contacts/', params)
export const readThread = id => postApi(`api/email-thread/${id}/read/`)
export const unreadThread = id => postApi(`api/email-thread/${id}/unread/`)
export const readMessage = id => postApi(`api/email-message/${id}/read/`)
export const unreadMessage = id => postApi(`api/email-message/${id}/unread/`)
export const updateStarred = (id, params) =>
  patchApi(`api/email-thread/${id}/`, params)
export const updateMessageStarred = (id, params) =>
  patchApi(`api/email-message/${id}/`, params)
export const testConnection = params =>
  postRaw('api/mail-account/test/', params)
export const changeNotes = (id, params) =>
  patchRaw(`api/email-thread/${id}/`, params)
export const changeNotesSingle = (id, params) =>
  patchRaw(`api/email-message/${id}/`, params)
export const getFont = (accountId: number) =>
  getApi(`api/email-font/${accountId}/`)
export const patchFont = (accountId: number, params) =>
  patchRaw(`api/email-font/${accountId}/`, params)
export const emailPending = (params: { pending_threads: Array<string> }) =>
  postRaw('api/email-pending/', params)
export const addEmailAccount = params =>
  postRaw('api/mail-account/mail-integration/', params)
export const reconnectAccount = id =>
  postRaw(`api/mail-account/mail-integration/${id}/reconnect/`)
export const deleteAccount = id =>
  deleteRaw(`api/mail-account/mail-integration/${id}/`)
export const arhivatedAccount = id =>
  postRaw(`api/mail-account/mail-integration/${id}/archive/`)
export const restoreAccount = id =>
  postRaw(`api/mail-account/mail-integration/${id}/restore/`)
export const integrateAccount = id =>
  postRaw(`api/mail-account/${id}/integrate/`)
export const integrateCorporateAccount = id =>
  postRaw(`api/mail-account/corporate/${id}/integrate/`)
export const syncEmails = account_id =>
  postRaw(`api/mail-account/mail-integration/${account_id}/sync-emails/`)
export const syncFolders = account_id =>
  postRaw(`api/email-integration/mail-account/${account_id}/folders/sync/`)
export const deleteAsyncTack = task_id =>
  getApi(`api/system/async_task/${task_id}/`)
export const getMailFolder = (account_id, params) =>
  getApi(`api/email-integration/mail-account/${account_id}/folders/`, params)
export const updateFolders = (account_id, folders) =>
  putRaw(
    `api/email-integration/mail-account/${account_id}/folders/batch-update/`,
    folders
  )
export const liveSearch = query =>
  getApi(`api/email-thread/live-search/`, { query })
export const search = params => getApi(`api/email-thread/search/`, params)

export const getAuthorizedUsers = (id, params) =>
  getApi(`api/mail-account/${id}/authorized-users/`, params)
export const updateAuthorizedUsers = (id, params) =>
  putRaw(`api/mail-account/${id}/authorized-users/`, params)
export const checkBlacklist = params => postRaw(`api/check-blacklist/`, params)
