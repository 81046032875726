// @flow

import { getApi, postApi, deleteApi } from './api.core'

export const getList = params => getApi('api/profile/', params)
export const getIncoming = params =>
  getApi('api/relationship/friend_requests_to_me/', params)
export const getOutgoing = () =>
  getApi('api/relationship/friend_request/?all=true') // unused
export const subscribe = user =>
  postApi('api/relationship/', { user, relationship: 'friend_request' })
export const unsubscribe = user =>
  deleteApi('api/relationship/', { user, relationship: 'friend' })
export const handleRequest = params => postApi('api/relationship/', params)
export const blockUser = (profileId, params) =>
  postApi(`api/profile/${profileId}/blacklist/`, params) // unused
export const addToWhiteList = (profileId, params) =>
  postApi(`api/profile/${profileId}/whitelist/`, params) // unused
