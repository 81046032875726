// @flow

export const date = 'DD MMMM YYYY'
export const dateTimeServer = 'YYYY-MM-DD HH:mm'
export const dateTime = 'HH:mm DD MMMM YYYY'

const AppConfig = {
  api: {
    url: `//${window.location.host}/`,
  },
  defaultApi: `//${window.location.host}/`,
  version: '{version_after_build}',
  front_version: '{front_version_build}',
}

export default AppConfig
