// @flow

import { fork, takeLatest, call, put } from 'redux-saga/effects'

import api from '../../../core/api'
import { MAILS_LIST_LOAD, listWasLoaded } from './MailsList.actions'

function* watchLoadList() {
  yield takeLatest(MAILS_LIST_LOAD, loadList)
}

function* loadList({ params }) {
  try {
    const payload = yield call(api.mail.getMailsList, params)
    yield put(listWasLoaded(payload))
  } catch (e) {
    console.log(e)
  }
}

export default function* watch() {
  yield fork(watchLoadList)
}
