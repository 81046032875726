// @flow

import React, { Component } from 'react'
import { isEmpty } from 'lodash-es'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'

import Geosuggest from '../../Geosuggest/Geosuggest'
import Map from '../../Map/Map'
import Button from '../../Button'
import api from '../../../core/api'
import StaticMap from '../../StaticMap'

import styles from './CompanyAddressItem.module.scss'

import Icon from '../../../../static/icons/map.svg'

class CompanyAddressItem extends Component {
  state = {
    initialAddress: {},
  }

  componentDidMount() {
    const { address } = this.props
    this.setState({ initialAddress: address })
  }

  getInitialData = () => this.state.initialAddress

  isCreating = () => isEmpty(this.props.address)

  editMode = () => {
    const {
      address: { officeId },
    } = this.props
    this.props.onToggleEditMode(officeId)
  }

  cancel = () => {
    const {
      address: { officeId },
    } = this.props
    const { initialAddress } = this.state
    this.props.onCancel(officeId, initialAddress)
  }

  updateGeosuggest = value => {
    this.updateAddress({ value })
  }

  onChangeGeosuggest = suggest => {
    let params = {
      value: suggest.label,
    }

    if (suggest.lattitude && suggest.longitude) {
      const lattitude = parseFloat(suggest.lattitude)
      const longitude = parseFloat(suggest.longitude)
      params = {
        ...params,
        widget_lattitude_str: `${lattitude}`,
        widget_longitude_str: `${longitude}`,
        widget_lattitude: lattitude,
        widget_longitude: longitude,
        lattitude,
        longitude,
      }
    }

    this.updateAddress(params)
  }

  updateAddress = data => {
    const { address } = this.props
    this.props.onEdit(address.officeId, data)
  }

  renderHeader = () => {
    const { title, editable, editMode = false } = this.props
    const showPencil = editable && !editMode && !this.isCreating()

    return (
      <h2 className='unit__title' onClick={this.collapse}>
        {title}
        {showPencil && <Button.Edit onClick={this.editMode} />}
      </h2>
    )
  }

  handleClick = () => {
    api.office.setPrimary({ id: this.props.officeId }).then(data => {
      this.props.updateOffices(data)
      this.cancel()
    })
  }

  render() {
    const {
      address,
      error,
      editable,
      city,
      editMode,
      working,
      initiated,
      canDelete,
      id,
      isPrimary,
    } = this.props

    const sectionClass = classnames('cprofile-view-houses unit unit--default', {
      'working-overlay': working,
    })

    const {
      value,
      lattitude,
      longitude,
      widget_zoom: widgetZoom,
      widget_lattitude_str: widgetLatitudeStr,
      widget_longitude_str: widgetLongitudeStr,
    } = address

    const mapProps = {
      editMode,
      onChangeAddress: this.updateAddress,
      zoom: widgetZoom,
    }

    const mapCenter = {
      lat: parseFloat(widgetLatitudeStr),
      lng: parseFloat(widgetLongitudeStr),
    }

    if (mapCenter.lat && mapCenter.lng) {
      mapProps.center = mapCenter
    } else {
      mapProps.center =
        city && city.lattitude && city.longitude
          ? {
              lat: city.lattitude,
              lng: city.longitude,
            }
          : null
    }

    if (lattitude && longitude) {
      mapProps.buildingMarker = {
        name: 'buildingMarker',
        position: {
          lat: parseFloat(lattitude),
          lng: parseFloat(longitude),
        },
      }
    }

    const activeBtn = this.getInitialData().value !== address.value

    const checkboxText = isPrimary
      ? this.props.t('AddressIsPrimary')
      : this.props.t('AddressIsNotPrimary')

    const textClass = classnames('table-item table-item__text', {
      'table-item__status--success': isPrimary,
    })

    return (
      <section className={sectionClass} id={id}>
        {this.renderHeader()}
        {canDelete && editMode && (
          <button
            className='modal__close'
            type='button'
            onClick={this.props.onRemove}
          />
        )}
        {editMode && (
          <>
            <div className='u-a12__row--02'>
              <div style={{ display: 'flex' }}>
                <div className={textClass}>
                  <span>{checkboxText}</span>
                </div>
                {!isPrimary && (
                  <Button.Save working={working} onClick={this.handleClick}>
                    {this.props.t('SetAddressAsPrimary')}
                  </Button.Save>
                )}
              </div>
            </div>
            <div className='u-a12__row--02'>
              <div className='input input--medium input--block input--default'>
                <Geosuggest
                  error={error}
                  initialValue={value}
                  onInputChange={this.updateGeosuggest}
                  onSelect={this.onChangeGeosuggest}
                />
              </div>
            </div>
          </>
        )}
        {!editMode && !this.isCreating() && (
          <h3 className='u-a12__title--01'>
            <span>{value}</span>
          </h3>
        )}

        {editMode && (
          <div className='cprofile-edit-address__submit'>
            <Button.Save
              disabled={!activeBtn}
              working={working}
              onClick={this.props.onSave}
            >
              {this.props.t('Save')}
            </Button.Save>
            <Button.Cancel disabled={working} onClick={this.cancel}>
              {this.props.t('Cancel')}
            </Button.Cancel>
          </div>
        )}
        <div className='u-a19'>
          {initiated && (
            <>
              {editMode ? (
                <Map {...mapProps} />
              ) : (
                <div className={styles.mapCont}>
                  <StaticMap address={address} size='828x383' />
                </div>
              )}
            </>
          )}
          {this.isCreating() && (
            <div className='u-a19__overlay'>
              <Icon className='u-a19__icon' />
              <span className='u-a19__heading'>
                {this.props.t('NoAddress')}
              </span>
              {editable && (
                <button
                  type='button'
                  className='u-a19__button button button--large button--outline-invert'
                  style={{ marginRight: 0 }}
                  onClick={this.edit}
                >
                  {this.props.t('SetAddress')}
                </button>
              )}
            </div>
          )}
        </div>
      </section>
    )
  }
}

export default withTranslation('Company')(CompanyAddressItem)
