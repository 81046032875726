// @flow

import React from 'react'

type Props = {
  className: string,
  handleClick?: () => void,
  text: string,
}

const Step = (props: Props) => {
  const { text, className } = props

  const handleClick = () => {
    if (props.handleClick) {
      props.handleClick()
    }
  }

  return (
    <div className={className} title={text} onClick={handleClick}>
      <span>{text}</span>
      <div></div>
    </div>
  )
}

export default Step
