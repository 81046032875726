// same as ThreeColumnRoute
// todo flow

import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'

import { updateCountersAction } from '../components/Layout/Layout.actions'
import BrowserStorage from '../utils/browserStorage'
import ErrorRoute from './ErrorRoute'
import { USER_GROUPS } from '../constants'
import { isDwellerUser } from '../utils/utils'
import { getUser } from '../utils/commonSelectors'

class MarketplaceColumnRoute extends Component {
  componentDidMount() {
    if (this.requireLogin(true)) {
      this.props.dispatch(updateCountersAction())
    }
  }

  requireLogin = withWorker => {
    const { user } = this.props
    const isWorker = user && user.group === USER_GROUPS.worker

    return BrowserStorage.get('token') && (!isWorker || withWorker)
  }

  isAllowedGroupsAccess = (groups = []) => {
    const { user } = this.props
    // Workers only allowed to request page

    const noAccess =
      groups.indexOf(user.group) === -1 &&
      !(user.is_landlord && groups.includes(USER_GROUPS.landlord))

    return !noAccess
  }

  isUserValidated = pathname => {
    // TODO в чем вообще смысл этих условий?
    const { user } = this.props

    if (!user || user.validated || !isDwellerUser(user) || pathname === '/') {
      return true
    }

    return true
  }

  renderThreeColumn = routeProps => {
    const {
      component: ThreeColumnComponent,
      allowOnlyAdmin,
      allowAdminManager,
      allowWorker,
      user,
      requestForWorker,
      pathname,
    } = this.props

    if (!this.requireLogin(true)) {
      return (
        <Redirect
          to={{
            pathname: '/login',
            params:
              pathname !== '/' ? { redirect: pathname + location.search } : {},
          }}
        />
      )
    }

    if (
      user &&
      user.group === USER_GROUPS.worker &&
      !(allowWorker && pathname.includes(requestForWorker))
    ) {
      return <Redirect to='/login' />
    }

    if (allowOnlyAdmin && !this.isAllowedGroupsAccess([USER_GROUPS.admin])) {
      return <Redirect to='/404' />
    }

    if (
      allowAdminManager &&
      !this.isAllowedGroupsAccess([USER_GROUPS.admin, USER_GROUPS.manager])
    ) {
      return <Redirect to='/404' />
    }

    return <ThreeColumnComponent {...routeProps} />
  }

  render() {
    const { component, ...rest } = this.props // eslint-disable-line
    const {
      location: { pathname },
    } = this.props

    if (!this.isUserValidated(pathname)) {
      return <ErrorRoute {...this.props} />
    }

    return <Route {...rest} render={this.renderThreeColumn} />
  }
}

const mapStateToProps = state => ({
  user: getUser(state),
  requestForWorker: state.sharedRequest.id.toString(),
  pathname: state.router.location.pathname,
})

export default compose(connect(mapStateToProps))(MarketplaceColumnRoute)
