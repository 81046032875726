// @flow

import { graphql } from 'react-relay'

const query = graphql`
  query OfferProfilePageQuery($offerID: ID!) {
    offer(id: $offerID) {
      ...OfferProfile_offer @arguments(offerID: $offerID)
      provider {
        contacts {
          type
          value
          verboseName
        }
      }
      appliedAttributes
    }
  }
`

export default query
