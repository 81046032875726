// @flow

import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Scrollbars } from 'react-custom-scrollbars'
import type { Node } from 'react'
import { usePrevious } from '../../../../hooks/usePrevious'

import ModalRow from '../../../Modal/ModalRow'
import BuildingsListItem from './BuildingsListItem'
import List from '../../../List/List'

import styles from './BuildingsList.module.scss'

type Props = {
  changeSelected: Object => void,
  isDwellerGroup: boolean,
  isloading: boolean,
  selected: Array<Object>,
}

const Buildingslist = (props: Props): Node => {
  const { isDwellerGroup, isloading, selected } = props

  const ref = useRef(null)

  const { t } = useTranslation('BuildingPopup')

  const prevSelected = usePrevious(selected)

  useEffect(() => {
    if (
      selected &&
      selected.length &&
      ref &&
      ref.current &&
      prevSelected?.length < selected.length
    ) {
      const scrollHeight = ref.current.getScrollHeight()
      ref.current.scrollToBottom(scrollHeight)
    }
  }, [selected])

  return (
    <ModalRow>
      <Scrollbars className={styles.scrollbar} ref={ref}>
        <List embedded>
          {selected.map(item => (
            <BuildingsListItem
              t={t}
              key={item.id}
              item={item}
              isDwellerGroup={isDwellerGroup}
              isloading={isloading}
              changeSelected={props.changeSelected}
            />
          ))}
        </List>
      </Scrollbars>
    </ModalRow>
  )
}

export default Buildingslist
