// @flow

import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { push } from 'connected-react-router'
import { useDispatch } from 'react-redux'
import { omit, isEmpty } from 'lodash-es'

import Button from '../../Button'
import {
  getCurrentFilters,
  formatPathname,
  getNewQueryString,
} from '../../../utils/routing'
import { getShortDateFormat } from '../../../utils/utils'
import Table from '../../Table'
import NumberCell from '../../Flat/FlatList/NumberCell'
import { setContractData } from '../../../core/api/api.profile'
import ContractPopup from '../../Profiles/ProfileList/ContractPopup'
import ContractDateCalendar from '../ContractDateCalendar'

import styles from './FlatList.module.scss'

const FlatListTableBodyRow = props => {
  const { t } = useTranslation('Building')
  const location = useLocation()
  const dispatch = useDispatch()
  const CONTRACT_END = 'contract_end'
  const CONTRACT_START = 'contract_start'
  const {
    flat: { id, building },
    inhabitancyId,
    contractId,
    purchaseContractNumber,
    duration,
    contractStart,
    contractEnd,
    permissions: {
      can_change_end_date: canChangeEndDate,
      can_change_contract_id: canChangeContractId,
      can_change_purchase_contract_number: canChangePurchaseContractNumber,
      can_change_start_date: canChangeStartDate,
      can_change_duration: canChangeDuration,
    },
    isDweller,
  } = props

  const [contractEdit, setContractEdit] = useState({})
  const [contractDate, setContractDate] = useState({
    name: '',
  })

  const updateContractDate = ({ value, name }) => {
    setContractData(inhabitancyId, {
      [name]: value ? value.format('YYYY-MM-DD') : null,
    }).then(data => {
      props.updateFlats(data)

      const filters = getCurrentFilters(location)

      if (filters.contract_end_gte || filters.contract_end_lte) {
        const search = getNewQueryString({
          ...omit(filters, ['contract_end_gte', 'contract_end_lte']),
        })

        const pathname = formatPathname(location.pathname)

        dispatch(push(`/${pathname}/?${search}`))
      }
    })
  }

  const updateContract = contract => {
    setContractData(inhabitancyId, { ...contract }).then(data => {
      return props.updateFlats(data)
    })
  }

  const handleContractEnd = e => {
    e.stopPropagation()

    if (contractDate.name !== CONTRACT_END) {
      setContractDate({
        name: CONTRACT_END,
        contractEnd,
      })
    }
  }

  const handleContractStart = e => {
    e.stopPropagation()

    if (contractDate.name !== CONTRACT_START) {
      setContractDate({
        name: CONTRACT_START,
        contractStart,
      })
    }
  }

  const handleChangeContractId = e => {
    e.stopPropagation()

    setContractEdit({
      initialValues: { contract_id: contractId },
      titleModal: t('ContractIdEditTitle'),
      titleLabel: t('ContractId'),
    })
  }

  const handleChangePCN = e => {
    e.stopPropagation()

    setContractEdit({
      initialValues: { purchase_contract_number: purchaseContractNumber },
      titleModal: t('SecondIdEditTitle'),
      titleLabel: t('SecondId'),
    })
  }

  const handleChangeDuration = e => {
    e.stopPropagation()

    setContractEdit({
      initialValues: {
        duration: duration || null,
      },
      titleModal: duration
        ? t('EditContractDuration')
        : t('AddContractDuration'),
      titleLabel: t('ContractDuration'),
    })
  }

  const handleClose = () => setContractEdit({})

  const handleOpen = () => {
    props.handleOpen(id)
  }

  const onClickOut = () => {
    setContractDate({
      name: '',
    })
  }

  const onClear = () => {
    updateContractDate({ ...contractDate, value: null })
    onClickOut()
  }

  return (
    <>
      <Table.BodyRow key={id} id={id} handleClick={handleOpen}>
        <Table.BodyCell>
          <NumberCell flat={props.flat} open={handleOpen} />
        </Table.BodyCell>
        <Table.BodyCell>
          <div
            className='table-item table-item__text'
            title={building.address.value}
            style={{ overflow: 'hidden' }}
          >
            <span>{building.address.value}</span>
          </div>
        </Table.BodyCell>
        {!isDweller && (
          <>
            <Table.BodyCell>
              <div className='table__cell-wrapper'>
                {canChangeContractId ? (
                  <Button.Edit
                    className={styles.buttonEdit}
                    onClick={handleChangeContractId}
                  >
                    <div className='table-item table-item__text'>
                      {contractId ? (
                        <span title={contractId}>{contractId}</span>
                      ) : (
                        <>&mdash;</>
                      )}
                    </div>
                  </Button.Edit>
                ) : (
                  <div className='table-item table-item__text'>
                    {contractId ? (
                      <span title={contractId}>{contractId}</span>
                    ) : (
                      <>&mdash;</>
                    )}
                  </div>
                )}
              </div>
            </Table.BodyCell>
            <Table.BodyCell>
              <div className='table__cell-wrapper'>
                {canChangePurchaseContractNumber ? (
                  <Button.Edit
                    className={styles.buttonEdit}
                    onClick={handleChangePCN}
                  >
                    <div className='table-item table-item__text'>
                      {purchaseContractNumber ? (
                        <span title={purchaseContractNumber}>
                          {purchaseContractNumber}
                        </span>
                      ) : (
                        <>&mdash;</>
                      )}
                    </div>
                  </Button.Edit>
                ) : (
                  <div className='table-item table-item__text'>
                    {purchaseContractNumber ? (
                      <span title={purchaseContractNumber}>
                        {purchaseContractNumber}
                      </span>
                    ) : (
                      <>&mdash;</>
                    )}
                  </div>
                )}
              </div>
            </Table.BodyCell>
            <Table.BodyCell>
              <div className='table__cell-wrapper'>
                {canChangeStartDate ? (
                  <div
                    className='table__cell-wrapper'
                    style={{ display: 'block' }}
                  >
                    <div className='table-item table-item__text'>
                      <button
                        type='button'
                        className='table-item__text-dropdown'
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                        onClick={handleContractStart}
                      >
                        {contractStart ? (
                          getShortDateFormat(contractStart)
                        ) : (
                          <>&mdash;</>
                        )}
                        &nbsp;
                      </button>
                      {contractDate.name === CONTRACT_START && (
                        <ContractDateCalendar
                          updateDate={updateContractDate}
                          value={contractDate}
                          onClickOut={onClickOut}
                          onClear={onClear}
                        />
                      )}
                    </div>
                  </div>
                ) : (
                  <div className='table-item table-item__text'>
                    {contractStart ? (
                      getShortDateFormat(contractStart)
                    ) : (
                      <>&mdash;</>
                    )}
                  </div>
                )}
              </div>
            </Table.BodyCell>
            <Table.BodyCell>
              <div className='table__cell-wrapper'>
                {canChangeDuration ? (
                  <Button.Edit
                    className={styles.buttonEdit}
                    onClick={handleChangeDuration}
                  >
                    <div className='table-item table-item__text'>
                      {duration ? `${duration} ${t('Months')}` : <>&mdash;</>}
                    </div>
                  </Button.Edit>
                ) : (
                  <div className='table-item table-item__text'>
                    {duration ? `${duration} ${t('Months')}` : <>&mdash;</>}
                  </div>
                )}
              </div>
            </Table.BodyCell>
            <Table.BodyCell>
              {canChangeEndDate ? (
                <div
                  className='table__cell-wrapper'
                  style={{ display: 'block' }}
                >
                  <div className='table-item table-item__text'>
                    <button
                      type='button'
                      className='table-item__text-dropdown'
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      onClick={handleContractEnd}
                    >
                      {contractEnd ? (
                        getShortDateFormat(contractEnd)
                      ) : (
                        <>&mdash;</>
                      )}
                      &nbsp;
                    </button>
                    {contractDate.name === CONTRACT_END && (
                      <ContractDateCalendar
                        updateDate={updateContractDate}
                        value={contractDate}
                        onClickOut={onClickOut}
                        onClear={onClear}
                      />
                    )}
                  </div>
                </div>
              ) : (
                <div className='table-item table-item__text'>
                  {contractEnd ? getShortDateFormat(contractEnd) : <>&mdash;</>}
                </div>
              )}
            </Table.BodyCell>
          </>
        )}
      </Table.BodyRow>
      {!isEmpty(contractEdit) && (
        <ContractPopup
          {...contractEdit}
          translationSection='FlatDwellers'
          updateContract={updateContract}
          onClose={handleClose}
        />
      )}
    </>
  )
}

export default FlatListTableBodyRow
