// @flow

import React from 'react'
import linkifyHtml from 'linkifyjs/html'
import { useTranslation } from 'react-i18next'

import EmptyList from '../../EmptyList'

type Props = {
  description: ?string,
  editing: boolean,
  handleEdit: () => void,
  name: ?string,
  permissions: Object,
}

const CompanyInfoText = (props: Props) => {
  const { name, description, editing, permissions } = props

  const { t } = useTranslation('Company')

  return !description && !editing ? (
    <>
      <div className='cprofile-view-user__title'>&laquo;{name}&raquo;</div>
      <EmptyList
        embedded
        btnText={t('EmptyButton')}
        canAdd={permissions.can_edit}
        icon='company'
        title={t('EmptyLabel')}
        onClick={props.handleEdit}
      />
    </>
  ) : (
    <div className='company-description'>
      <div className='cprofile-view-user__title'>&laquo;{name}&raquo;</div>
      <div
        dangerouslySetInnerHTML={{ __html: linkifyHtml(description || '') }}
        className='cprofile-view-user__paragraph'
      />
    </div>
  )
}

export default CompanyInfoText
