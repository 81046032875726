// @flow

import React from 'react'
import classnames from 'classnames'
import type { Node } from 'react'

import styles from './NewAvatar.module.scss'

type Props = {
  className?: string,
  isBordered?: boolean,
  size: string,
  src: string,
}

const NewAvatar = (props: Props): Node => {
  const { src, className, size, isBordered } = props

  const avatarClass = classnames(styles.avatar, styles[size], className, {
    [styles.bordered]: isBordered,
  })

  return (
    <div className={avatarClass}>
      <img src={src} />
    </div>
  )
}

NewAvatar.defaultProps = { size: 'medium' }

export default NewAvatar
