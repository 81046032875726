// @flow

export const FRIEND_NOTIFICATION_INITIATE = 'FRIEND_NOTIFICATION_INITIATE'
export const FRIEND_NOTIFICATION_INITIATED = 'FRIEND_NOTIFICATION_INITIATED'
export const FRIEND_NOTIFICATION_OPEN = 'FRIEND_NOTIFICATION_OPEN'
export const FRIEND_NOTIFICATION_CLOSE = 'FRIEND_NOTIFICATION_CLOSE'
export const FRIEND_NOTIFICATION_LOAD = 'FRIEND_NOTIFICATION_LOAD'
export const FRIEND_NOTIFICATION_LOADED = 'FRIEND_NOTIFICATION_LOADED'
export const FRIEND_NOTIFICATION_HANDLE = 'FRIEND_NOTIFICATION_HANDLE'
export const FRIEND_NOTIFICATION_ERROR = 'FRIEND_NOTIFICATION_ERROR'
