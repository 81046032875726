// @flow

import React, { useState } from 'react'
import type { Node } from 'react'
import { useTranslation } from 'react-i18next'
import { SimpleBlock, Header } from '../Block'
import Settings from '../Settings'
import InputPassword from '../InputPassword/InputPassword'
import Modal from '../Modal'
import ConfirmationPopup from '../modals/ConfirmationPopup'

import { generateRemoveProfileLink } from '../../core/api/api.profile'

const ProfileRemove = (): Node => {
  const [password, setPassword] = useState('')
  const [working, setWorking] = useState(false)
  const [modal, setModal] = useState(null)
  const { t } = useTranslation('Settings')

  const showSuccessModal = () => {
    setModal(
      <ConfirmationPopup
        text={t('RemoveAccountPopup')}
        title={t('RemoveAccountTitle')}
        confirm={t('RemoveOk')}
        onClose={hideModal}
        onOk={hideModal}
      />
    )
  }

  const showErrorModal = () => {
    setModal(
      <ConfirmationPopup
        text={t('RemoveAccountError')}
        title={t('RemoveAccountTitleError')}
        confirm={t('RemoveError')}
        onClose={hideModal}
        onOk={hideModal}
      />
    )
  }

  const hideModal = () => {
    setModal(null)
  }

  const confirmRemove = () => {
    setWorking(true)
    generateRemoveProfileLink({ password })
      .then(data => {
        if (data.token) {
          showSuccessModal()
        }
      })
      .catch(() => showErrorModal())
      .finally(() => setWorking(false))
  }

  return (
    <>
      <SimpleBlock working={working}>
        <Header header>{t('RemoveAccount')}</Header>
        <Settings.Row label={t('RemoveAccountPassword')}>
          <InputPassword
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
        </Settings.Row>
        <Settings.Actions
          saveText={t('RemoveAccountConfirm')}
          showCancel={false}
          working={working}
          isValid={password.length !== 0}
          withChanges={password.length !== 0}
          onSave={confirmRemove}
        />
      </SimpleBlock>
      {!!modal && (
        <Modal
          contentLabel=''
          className='Modal__Bootstrap modal-dialog'
          isOpen={!!modal}
          onRequestClose={hideModal}
        >
          {modal}
        </Modal>
      )}
    </>
  )
}

export default ProfileRemove
