// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'
import { PROVIDERS_LIST, providers } from './Providers/Providers.constant'
import ProviderItem from './Providers/ProviderItem'
import ModalHead from '../../Modal/ModalHead'

type Props = {|
  setStage: () => void,
|}

const SelectProviderModal = (props: Props): Node => {
  const { setStage } = props

  const { t } = useTranslation('EmailIntegrationNylas')

  return (
    <>
      <ModalHead title={t('SelectProviderTitle')} />
      <div>
        {PROVIDERS_LIST.map(provider => (
          <ProviderItem
            key={providers[provider].stage}
            setStage={() => setStage(providers[provider].stage)}
            name={t(providers[provider].translationKey)}
            {...providers[provider]}
          />
        ))}
      </div>
    </>
  )
}

export default SelectProviderModal
