// @flow

import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'
import { find, difference } from 'lodash-es'

import Select from '../Select'
import Scrollbar from '../../Scrollbar'

class SelectSimple extends Select {
  static propTypes = {
    options: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
      .isRequired,
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    onChange: PropTypes.func.isRequired,
    style: PropTypes.object,
    position: PropTypes.number,
  }

  static defaultProps = {
    ...Select.defaultProps,
    backspaceRemoves: false,
    className: 'select-simple',
    filtering: false,
    openOnFocus: true,
    pageSize: 5,
  }

  renderInput = () => {
    const {
      tabIndex,
      filtering,
      value,
      options,
      labelKey,
      placeholder,
      inputError,
      disabled,
      calendar,
      calendar2,
      importSelect,
      onInputChange,
      style,
      name,
    } = this.props
    const showInput = filtering || !!onInputChange
    const props = {
      tabIndex,
      onBlur: this.onInputBlur,
      onClick: e => {
        e.preventDefault()
        this.handleInputFocus(e)
      },
      ref: _ref => {
        this.input = _ref
      },
      disabled,
      name,
    }
    const btnClassName = classnames(
      'button button--default  button--block button--dropdown add-profile-contacts',
      {
        'button--large': !calendar || !calendar2,
        'button--medium': calendar || calendar2,
        'button--input': showInput,
        'button--dropdown-empty': !options.length,
        'button--height': style,
        'button--success': name === 'create-thread',
        'button--change-role': name === 'change-role',
      }
    )

    if (calendar) {
      const calendarElement = find(
        options,
        o => o.value.year() === value.year()
      )

      return (
        <button {...props} className={btnClassName} type='button'>
          {value ? calendarElement[labelKey] : placeholder}
        </button>
      )
    }

    if (calendar2) {
      const calendarElement = find(
        options,
        o => o.value.month() === value.month()
      )

      return (
        <button {...props} className={btnClassName} type='button'>
          {value ? calendarElement[labelKey] : placeholder}
        </button>
      )
    }

    if (!showInput && importSelect) {
      const selected = find(options, o => o.value === value)
      const textStyle = selected ? { color: '#000000' } : {}

      return (
        <button {...props} className={btnClassName} type='button'>
          <span style={textStyle} title={selected ? selected[labelKey] : ''}>
            {selected ? selected[labelKey] : placeholder}
          </span>
        </button>
      )
    }

    if (!showInput) {
      const textStyle = value ? { color: '#000000' } : {}
      const btnClass = classnames(btnClassName, {
        'input--error': inputError,
      })

      return (
        <button {...props} className={btnClass} type='button'>
          <span
            title={value ? value[labelKey] : ''}
            className='add-profile-contacts'
            style={textStyle}
          >
            {value ? value[labelKey] : placeholder}
          </span>
        </button>
      )
    }

    const inputClass = classnames(
      'input input--default input--large input--block',
      {
        'input--error': inputError,
      }
    )
    const { inputValue } = this.state

    return (
      <button className={btnClassName} disabled={disabled} type='button'>
        <div className={inputClass}>
          <input
            {...props}
            type='text'
            value={value ? value[labelKey] : inputValue}
            placeholder={placeholder}
            onChange={this.handleInputChange}
          />
        </div>
      </button>
    )
  }

  renderMenu = (options, valueArray, focusedOption) => {
    const {
      labelKey,
      valueKey,
      pageSize,
      t,
      calendar,
      value,
      calendar2,
      importSelect,
      ignoredOptions,
      position,
    } = this.props

    const opts = ignoredOptions ? difference(options, ignoredOptions) : options

    if (opts && opts.length) {
      const optionElems = opts.map(opt => {
        let style = opt === focusedOption ? { backgroundColor: '#fefbd8' } : {}

        if (importSelect) {
          style = opt.value === value ? { backgroundColor: '#fefbd8' } : {}
        }

        if (calendar) {
          const calendarElement = find(
            opts,
            o => o.value.year() === value.year()
          )
          style =
            opt.value.year() === calendarElement.value.year()
              ? { backgroundColor: '#fefbd8' }
              : {}
        }

        if (calendar2) {
          const calendarElement = find(
            opts,
            o => o.value.month() === value.month()
          )
          style =
            opt.value.month() === calendarElement.value.month()
              ? { backgroundColor: '#fefbd8' }
              : {}
        }

        const liClassName = classnames('add-profile-contacts', {
          'dropdown__list-item': true,
        })

        return (
          <div key={`option-${opt[valueKey]}`}>
            {!opt.button ? (
              <li
                className={liClassName}
                style={style}
                onClick={() => this.selectValue(opt)}
              >
                <span className='dropdown__list-item-text add-profile-contacts'>
                  {opt[labelKey]}
                </span>
              </li>
            ) : (
              <div>{opt[labelKey]}</div>
            )}
          </div>
        )
      })

      return (
        <div
          className='dropdown dropdown--default dropdown--open'
          style={{ width: '100%' }}
        >
          <Scrollbar
            items={opts}
            listElements={optionElems}
            listClassName='dropdown__list dropdown__list--default'
            pageSize={pageSize}
            position={position}
          />
        </div>
      )
    }

    return <div className='Select-noresults'>{t('NoResults')}</div>
  }

  render() {
    const { className, noClearButton, importSelect, style } = this.props
    const { isOpen, isFocused, isPseudoFocused } = this.state

    const selectClass = classnames('Select Select--single', className, {
      'is-focused': isFocused,
      'is-open': isOpen,
      'is-pseudo-focused': isPseudoFocused,
      'is-import-select': importSelect,
    })

    return (
      <div className={selectClass} style={style || {}}>
        <div onKeyDown={this.handleKeyDown} onMouseDown={this.handleMouseDown}>
          {this.renderInput()}
          {!noClearButton && this.renderClear()}
        </div>
        {isOpen && this.renderOuter(this.filterOptions())}
      </div>
    )
  }
}

export default withTranslation('Select')(SelectSimple)
