import * as actions from './SelectChatMember.actionTypes'

const initialState = {
  loading: false,
  value: null,
  valueInitiated: false,
  options: [],
  optionsInitiated: false,
  menuLoader: false,
  meta: {},
  error: false,
  addMemberToChat: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.SELECT_CHAT_MEMBER_INITIATING:
      return {
        ...initialState,
        addMemberToChat: state.addMemberToChat,
        loading: true
      }
    case actions.SELECT_CHAT_MEMBER_INITIATED:
      return {
        ...initialState,
        addMemberToChat: action.addMemberToChat,
        value: action.value,
        valueInitiated: true,
        options: action.results.objects,
        optionsInitiated: true,
        loading: false,
        meta: action.meta
      }
    case actions.SELECT_CHAT_MEMBER_RESET:
      return initialState
    case actions.SELECT_CHAT_MEMBER_LOADING:
      return {
        ...state,
        loading: true,
        options: action.params.page === 1 ? [] : state.options
      }
    case actions.SELECT_CHAT_MEMBER_LOADED:
      return {
        ...state,
        loading: false,
        menuLoader: false,
        options: state.options.concat(action.results.objects),
        optionsInitiated: true,
        meta: action.meta
      }
    case actions.SELECT_CHAT_MEMBER_SET_VALUE:
      return {
        ...state,
        value: action.value
      }
    case actions.SELECT_CHAT_MEMBER_SHOW_MENU_LOADER:
      return {
        ...state,
        menuLoader: true
      }
    case actions.SELECT_CHAT_MEMBER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error.message
      }
    case actions.ADD_MEMBER_TO_CHAT: {
      return {
        ...state,
        addMemberToChat: action.profileId
      }
    }
    default:
      return state
  }
}
