// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { EntityIcon } from '../../../../components/Icon'
import Loader from '../../../../components/Loader'

const OrderProfileRequestInfo = props => {
  const { loading, request } = props

  const { t } = useTranslation('Orders')

  if (!request) {
    return <span className='bar__cell-value'>{t('NoRequest')}</span>
  }

  if (loading) {
    return <Loader />
  }

  return (
    <span className='bar__cell-value'>
      <EntityIcon id='request' />
      <Link to={`/request/${request.id}`}>
        {t('request_number_title')} {request.request_no}
      </Link>
      <span>&nbsp;{request.title}</span>
    </span>
  )
}

export default OrderProfileRequestInfo
