// @flow

import React from 'react'
import type { Node } from 'react'

import NewTableBody from '../../NewTable/NewTableBody'
import TableBodyRow from './TableBodyRow'

type Props = {
  list: Array<Object>,
  onRemove: Function,
  onSelect: Function,
  selectedItems: Array<Object>,
}

const TableBody = (props: Props): Node => {
  const { list, selectedItems } = props

  return (
    <NewTableBody>
      {list.map(item => (
        <TableBodyRow
          item={item}
          key={item.id}
          selectedItems={selectedItems}
          onSelect={props.onSelect}
          onRemove={props.onRemove}
        />
      ))}
    </NewTableBody>
  )
}

export default TableBody
