// @flow

import React from 'react'
import type { Node } from 'react'

import BudgetPlanHeader from './BudgetPlanHeader'
import { SimpleBlock } from '../Block'
import BudgetPlanToolbox from './BudgetPlanToolbox'

type Props = { requestId: string }

const CreateBudgetPlan = (props: Props): Node => {
  const { requestId } = props

  return (
    <SimpleBlock>
      <BudgetPlanHeader header />
      <BudgetPlanToolbox requestId={requestId} />
    </SimpleBlock>
  )
}

export default CreateBudgetPlan
