/**
 * @flow
 * @relayHash 94cd40e85878374681b989724d12e698
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type OrdersTable_query$ref = any;
export type OrdersQueryVariables = {|
  page: number,
  orderNo?: ?string,
  status?: ?string,
  providerName?: ?string,
  categoryId?: ?string,
  all?: ?boolean,
|};
export type OrdersQueryResponse = {|
  +$fragmentRefs: OrdersTable_query$ref
|};
export type OrdersQuery = {|
  variables: OrdersQueryVariables,
  response: OrdersQueryResponse,
|};
*/


/*
query OrdersQuery(
  $page: Float!
  $orderNo: String
  $status: String
  $providerName: String
  $categoryId: String
  $all: Boolean
) {
  ...OrdersTable_query_1fAYfz
}

fragment OrdersTable_query_1fAYfz on Query {
  allCustomerOrders(page: $page, orderNo: $orderNo, status: $status, providerName: $providerName, categoryId: $categoryId, all: $all) {
    edges {
      node {
        id
        clientInfo
        orderNo
        createdAt
        grandTotal
        status
        offer
        provider {
          name
          image {
            origin
            id
          }
          id
        }
      }
    }
    totalCount
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "page",
    "type": "Float!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderNo",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "status",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "providerName",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "categoryId",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "all",
    "type": "Boolean",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "all",
    "variableName": "all"
  },
  {
    "kind": "Variable",
    "name": "categoryId",
    "variableName": "categoryId"
  },
  {
    "kind": "Variable",
    "name": "orderNo",
    "variableName": "orderNo"
  },
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  },
  {
    "kind": "Variable",
    "name": "providerName",
    "variableName": "providerName"
  },
  {
    "kind": "Variable",
    "name": "status",
    "variableName": "status"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "OrdersQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "OrdersTable_query",
        "args": (v1/*: any*/)
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "OrdersQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allCustomerOrders",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CustomerOrderNodeFilterConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "CustomerOrderNodeFilterEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "CustomerOrderNode",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "clientInfo",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "orderNo",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "createdAt",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "grandTotal",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "status",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "offer",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "provider",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ProviderNode",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "name",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "image",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ProviderImageNode",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "origin",
                            "args": null,
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ]
                      },
                      (v2/*: any*/)
                    ]
                  }
                ]
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "totalCount",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "OrdersQuery",
    "id": null,
    "text": "query OrdersQuery(\n  $page: Float!\n  $orderNo: String\n  $status: String\n  $providerName: String\n  $categoryId: String\n  $all: Boolean\n) {\n  ...OrdersTable_query_1fAYfz\n}\n\nfragment OrdersTable_query_1fAYfz on Query {\n  allCustomerOrders(page: $page, orderNo: $orderNo, status: $status, providerName: $providerName, categoryId: $categoryId, all: $all) {\n    edges {\n      node {\n        id\n        clientInfo\n        orderNo\n        createdAt\n        grandTotal\n        status\n        offer\n        provider {\n          name\n          image {\n            origin\n            id\n          }\n          id\n        }\n      }\n    }\n    totalCount\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'afd5ea99405bc97d7aa95a7303dffcbc';
module.exports = node;
