// @flow

import { filter } from 'lodash-es'

import * as actions from './UserPopup.actionTypes'

type State = {
  assignees: Array<Object>,
  comment: string,
  providerRemoved: boolean,
  users: Array<Object>,
}

type Action = {
  assignee: Object,
  assignees: Array<Object>,
  comment: string,
  type: string,
  users: Array<Object>,
}

const initialState = {
  assignees: [],
  users: [],
  comment: '',
  providerRemoved: false,
}

export default (state: State = initialState, action: Action) => {
  switch (action.type) {
    case actions.USER_POPUP_USERS_INIT:
      return {
        ...state,
        assignees: action.assignees,
        users: [],
        comment: '',
      }
    case actions.USER_POPUP_USERS_DELETE: {
      const assignees: Array<Object> = filter(
        state.assignees,
        assignee => action.assignee.id !== assignee.id
      )

      return {
        ...state,
        providerRemoved: true,
        assignees,
      }
    }
    case actions.USER_POPUP_USERS_ADD:
      return {
        ...state,
        users: action.users,
      }
    case actions.USER_POPUP_USERS_CHANGE_COMMENT:
      return {
        ...state,
        comment: action.comment,
      }
    case actions.USER_POPUP_CLEAR:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
