import React from 'react'
import classnames from 'classnames'
import { useSelector } from 'react-redux'
import Textarea from 'react-textarea-autosize'
import { useTranslation } from 'react-i18next'

import Input from '../../Input'
import PhoneInput from '../../forms/PhoneInput'

import styles from './CreateContractorPage.module.scss'

const TEXTAREA_ROWS_COUNT = 7

const InputWrapper = ({ contact, formikProps, autoFocus }) => {
  const { t } = useTranslation('Contractors')
  const countryCode = useSelector(state => state.init.uk.country_obj.code)
  const { name, type } = contact
  const { setFieldValue, errors, values, touched } = formikProps

  const label = t(name)
  const value = values[name]
  const props = { value, name, placeholder: label, autoFocus }

  const errorClassName = { [styles.inputError]: errors[name] && touched[name] }
  const textAreaClassName = classnames(
    'textarea-2 textarea-2--large textarea-2--block textarea-2--default',
    styles.wide,
    styles.textarea,
    errorClassName
  )
  const inputClassName = classnames(styles.wide, errorClassName)
  const inputWrapperClassName = classnames(
    'input input--large input--block input--default input--success',
    styles.wide
  )
  const phoneClassName = classnames(
    'input input--default input--block input--large input--multiple',
    styles.wide,
    errorClassName
  )

  const handleChange = value => setFieldValue(name, value)
  const handleChangeTextarea = e => handleChange(e.target.value)

  switch (type) {
    case 'text':
    case 'email':
      return (
        <div className={inputWrapperClassName}>
          <Input.Regular
            {...props}
            name={name}
            className={inputClassName}
            placeholder={label}
            onChange={setFieldValue}
          />
        </div>
      )
    case 'tel':
      return (
        <PhoneInput
          {...props}
          type='text'
          className={phoneClassName}
          countryCode={countryCode}
          onChange={handleChange}
        />
      )
    case 'textarea':
      return (
        <Textarea
          {...props}
          style={{ width: '100%' }}
          className={textAreaClassName}
          minRows={TEXTAREA_ROWS_COUNT}
          maxRows={TEXTAREA_ROWS_COUNT}
          onChange={handleChangeTextarea}
        />
      )
    default:
      return null
  }
}

export default InputWrapper
