// @flow

import React from 'react'
import classnames from 'classnames'
import type { Node } from 'react'

import styles from './NewTable.module.scss'

type Props = {
  checked?: boolean,
  children: Node,
  className?: string,
  forwardedRef?: Object,
  hovered: boolean,
  onClick?: (SyntheticEvent<HTMLTableElement>) => void,
  style?: Object,
}

const NewTableBodyRow = (props: Props): Node => {
  const { children, checked, style, className, hovered, forwardedRef } = props

  const rowClass = classnames(styles.tableBodyRow, 'table__row', className, {
    'table__row--attention': checked,
    [styles.hovered]: hovered,
  })

  const trProps = Object.assign(
    {},
    style ? { style } : undefined,
    props.onClick ? { onClick: props.onClick } : undefined
  )

  return (
    <tr className={rowClass} {...trProps} ref={forwardedRef}>
      {children}
    </tr>
  )
}

NewTableBodyRow.defaultProps = { hovered: true }

export default React.forwardRef((props, ref) => (
  <NewTableBodyRow {...props} forwardedRef={ref} />
))
