// @flow

import React from 'react'
import { connect } from 'react-redux'
import { isNull } from 'lodash-es'

import Dropdown from './VariablesDropdown'

type Props = {
  exclude?: string,
  items: Array<string>,
  onSelect: string => void,
  position: Array<number>,
  value: string,
}

const SuggestVariable = (props: Props) => {
  const { exclude, items, value, position, onSelect } = props

  if (isNull(value)) {
    return null
  }

  const filtered = items.filter(({ type }) => type !== exclude)
  const names =
    value.length > 2
      ? filtered.filter(({ name }) => name.includes(value))
      : filtered
  const style = {
    left: `${position[0] + 10}px`,
    top: `${position[1] + 50}px`,
  }

  return <Dropdown items={names} style={style} onSelect={onSelect} />
}

export default connect(state => ({
  items: state.requestConstructorCards.variables,
}))(SuggestVariable)
