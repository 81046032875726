// @flow

export const USER_LIST_INITIATING = 'USER_LIST_INITIATING'
export const USER_LIST_INITIATED = 'USER_LIST_INITIATED'
export const USER_LIST_TOGGLE = 'USER_LIST_TOGGLE'
export const USER_LIST_TOGGLE_ALL = 'USER_LIST_TOGGLE_ALL'
export const USER_LIST_LOAD = 'USER_LIST_LOAD'
export const USER_LIST_LOAD_ONE = 'USER_LIST_LOAD_ONE'
export const USER_LIST_REMOVE = 'USER_LIST_REMOVE'
export const USER_LIST_RESTORE = 'USER_LIST_RESTORE'
export const USER_LIST_SET_MANAGER_BUILDINGS = 'USER_LIST_SET_MANAGER_BUILDINGS'
export const USER_LIST_SET_MANAGER_CATEGORIES =
  'USER_LIST_SET_MANAGER_CATEGORIES'
export const USER_LIST_MASS_SET_MANAGER_CATEGORIES =
  'USER_LIST_MASS_SET_MANAGER_CATEGORIES'
export const USER_LIST_UPDATE_ITEM = 'USER_LIST_UPDATE_ITEM'
export const USER_LIST_ITEM_WAS_UPDATED = 'USER_LIST_ITEM_WAS_UPDATED'
export const USER_LIST_UPDATE = 'USER_LIST_UPDATE'
export const USER_LIST_WAS_UPDATED = 'USER_LIST_WAS_UPDATED'
export const USER_LIST_ERROR = 'USER_LIST_ERROR'
export const USER_LIST_APROVE_USERS = 'USER_LIST_APROVE_USERS'
export const USER_LIST_CLEANUP = 'USER_LIST_CLEANUP'
export const USER_LIST_SELECTED = 'USER_LIST_SELECTED'
export const USER_LIST_BEFORE_LOAD = 'USER_LIST_BEFORE_LOAD'
export const USER_LIST_DELETE = 'USER_LIST_DELETE'
export const USER_LIST_RELOAD = 'USER_LIST_RELOAD'
