// @flow

import { all, put, call, fork, takeEvery } from 'redux-saga/effects'
import _ from 'lodash-es'

import api from '../../../core/api'
import * as actions from './RequestTranslate.actionTypes'

let token = null

function clearToken() {
  token = null
}

function* watchLoadLanguages() {
  yield takeEvery(actions.REQUEST_TRANSLATOR_LOAD_LANGUAGES, loadLanguages)
}

function* watchTranslate() {
  yield takeEvery(actions.REQUEST_TRANSLATE, translate)
}

function* loadLanguages(action) {
  try {
    const { data } = yield call(api.translation.getLanguages, {
      locale: action.locale,
    })
    const languages = _.chain(data.translation)
      .map((lang, key) => ({
        label: lang.name,
        value: key,
      }))
      .orderBy('label')
      .value()
    yield put({
      type: actions.REQUEST_TRANSLATE_LANGUAGES_WERE_LOADED,
      languages,
    })
  } catch (error) {
    console.error(error)
  }
}

function* translate(action) {
  try {
    if (!token) {
      const { data } = yield call(api.translation.getTranslateToken)
      token = data
      setTimeout(clearToken, 9 * 60 * 1000) // token is valid for 10 min
    }

    const { from, to } = action
    const params = { from, to, token }
    const [title, text] = yield all([
      call(api.translation.translateText, { ...params, text: action.title }),
      call(api.translation.translateText, { ...params, text: action.text }),
    ])

    yield put({
      type: actions.REQUEST_WAS_TRANSLATED,
      title: title.data,
      text: text.data,
    })
  } catch (error) {
    console.error(error)
  }
}

export default function* watch() {
  yield all([fork(watchLoadLanguages), fork(watchTranslate)])
}
