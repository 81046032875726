// @flow

import React, { useState } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import UserListTable from './UserListTable'
import Toolbox from './Toolbox'
import UserInfoTitle from '../UserInfo/UserInfoTitle'

type Props = {
  loadUsers: () => void,
}

const UserList = (props: Props) => {
  const { search } = props

  const { t } = useTranslation('User')

  const [modal, setModal] = useState(null)

  const hideModal = () => {
    setModal(null)
  }

  const sectionClassName = classNames('unit unit--default', {
    'bar bar--default': search,
  })

  return (
    <section className={sectionClassName}>
      {search && (
        <UserInfoTitle
          text={`${t('UsersInfoSearchTitle')} : ${decodeURIComponent(search)}`}
        />
      )}
      <Toolbox modal={modal} setModal={setModal} hideModal={hideModal} />
      <UserListTable
        modal={modal}
        setModal={setModal}
        hideModal={hideModal}
        load={props.loadUsers}
      />
    </section>
  )
}

export default UserList
