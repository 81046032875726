// @flow

import React from 'react'
import type Node from 'react'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'

import Row from '../Block/Row'
import LabelsPopup from './LabelsPopup'

import styles from '../Labels/LabelItem.module.scss'

type labelProps = {
  color: string,
  id: number,
  name: string,
}

const labelClassName = classnames('label-item__name', styles.label)
const LabelItem = ({ name, id, color }: labelProps): Node => (
  <div
    className='label-item request-label-item'
    key={id}
    style={{ backgroundColor: color.value ? color.value : color }}
    title={name}
  >
    <span className={labelClassName}>{name}</span>
  </div>
)

type Props = {
  onChange: (Array<Object>) => void,
  onEdit: (Node | null) => void,
  permissions: Object,
  t: string => string,
  value: Array<Object>,
}

const LabelsRow = (props: Props): Node => {
  const { value, permissions, infoData } = props
  const handleEdit = () => {
    props.onEdit(
      <LabelsPopup
        infoData={infoData}
        onChange={props.onChange}
        onEdit={props.onEdit}
      />
    )
  }

  return (
    <Row
      className={props.className}
      title={props.t('LabelsTitle') + `${props.infoData.labels ? ':' : ''}`}
      onEdit={permissions.can_edit_labels ? handleEdit : null}
    >
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {value.length ? (
          value.map(label => <LabelItem {...label} key={label.id} />)
        ) : (
          <span
            className='bar__submit-label_light bar__submit-label'
            style={{ marginLeft: 0 }}
          >
            {props.t('LabelsEmptyTitle')}
          </span>
        )}
      </div>
    </Row>
  )
}

export default withTranslation('Request')(LabelsRow)
