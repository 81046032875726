// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'

import CellButton from '../CellButton'

function Value(props) {
  const { user, title, archived } = props

  const { t } = useTranslation('User')

  const emptyCategoryText = t('NoCategory')

  const validated = user?.validated
  const count = user?.categories?.count

  if (!validated || archived) {
    return title
  }

  if (!count) {
    return <CellButton onClick={props.onClick}>{emptyCategoryText}</CellButton>
  }

  if (count > 1) {
    return (
      <div>
        <div>
          <span className='table-item__text-building-first' title={title}>
            {title}
          </span>
          <CellButton onClick={props.onClick}>{`${t('ShowMore')} ${
            count - 1
          }`}</CellButton>
        </div>
      </div>
    )
  }

  return <CellButton onClick={props.onClick}>{title}</CellButton>
}

export default Value
