// @flow

import { all, put, call, fork, takeEvery } from 'redux-saga/effects'
import api from '../../core/api'
import { serverError } from '../../components/Layout/Layout.actions'

import {
  GET_CHECKITEMS_INIT,
  CREATE_CHECKITEM_INIT,
  DELETE_CHECKITEM_INIT,
  TOGGLE_CHECKITEM_INIT,
  EDIT_CHECKITEM_INIT,
} from './Checklist.constants'

import {
  getCheckitemsSuccess,
  getCheckitemsError,
  createCheckitemSuccess,
  createCheckitemError,
  deleteCheckitemSuccess,
  deleteCheckitemError,
  toggleCheckitemSuccess,
  toggleCheckitemError,
  editCheckitemSuccess,
  editCheckitemError,
} from './Checklist.actions'

function* getCheckitems({ checklistId }) {
  try {
    const checkitems = yield call(api.checklist.getCheckitems, {
      checklist: checklistId,
      page_size: 1000000,
    })
    yield put(getCheckitemsSuccess(checklistId, checkitems.results.objects))
  } catch (error) {
    yield put(serverError(error))
    yield put(getCheckitemsError(error))
  }
}

function* createCheckitem({ checklistId, text }) {
  try {
    const checkitem = yield call(api.checklist.createCheckitem, {
      checklist: checklistId,
      text,
    })
    yield put(createCheckitemSuccess(checklistId, checkitem))
  } catch (error) {
    yield put(serverError(error))
    yield put(createCheckitemError(error))
  }
}

function* deleteCheckitem({ checklistId, checkitemId }) {
  try {
    yield call(api.checklist.deleteCheckitem, checkitemId)
    yield put(deleteCheckitemSuccess(checklistId, checkitemId))
  } catch (error) {
    yield put(serverError(error))
    yield put(deleteCheckitemError(error))
  }
}

function* toggleCheckitem({ checklistId, checkitemId, checked }) {
  try {
    const checkitem = yield call(api.checklist.patchCheckitem, checkitemId, {
      checked,
    })
    yield put(toggleCheckitemSuccess(checklistId, checkitem))
  } catch (error) {
    yield put(serverError(error))
    yield put(toggleCheckitemError(error))
  }
}

function* editCheckitem({ checklistId, checkitemId, text }) {
  try {
    const checkitem = yield call(api.checklist.patchCheckitem, checkitemId, {
      text,
    })
    yield put(editCheckitemSuccess(checklistId, checkitem))
  } catch (error) {
    yield put(serverError(error))
    yield put(editCheckitemError(error))
  }
}

function* watchGetCheckitemsInit() {
  yield takeEvery(GET_CHECKITEMS_INIT, getCheckitems)
}

function* watchCreateCheckitemInit() {
  yield takeEvery(CREATE_CHECKITEM_INIT, createCheckitem)
}

function* watchDeleteCheckitemInit() {
  yield takeEvery(DELETE_CHECKITEM_INIT, deleteCheckitem)
}

function* watchToggleCheckitemInit() {
  yield takeEvery(TOGGLE_CHECKITEM_INIT, toggleCheckitem)
}

function* watchEditCheckitemInit() {
  yield takeEvery(EDIT_CHECKITEM_INIT, editCheckitem)
}

export default function* watch() {
  yield all([
    fork(watchGetCheckitemsInit),
    fork(watchCreateCheckitemInit),
    fork(watchDeleteCheckitemInit),
    fork(watchToggleCheckitemInit),
    fork(watchEditCheckitemInit),
  ])
}
