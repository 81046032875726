// @flow

import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import type { Node } from 'react'

import AuthPage from '../AuthPage'
import RegistrationForm from './RegistrationForm'
import { clear } from '../Login/Login.actions'

const Registration = (): Node => {
  const dispatch = useDispatch()

  const [working, setWorking] = useState(false)
  const [height, setHeight] = useState(null)

  useEffect(() => {
    dispatch(clear())
  })

  return (
    <AuthPage working={working} height={height} setFormHeight={setHeight}>
      <RegistrationForm working={working} setWorking={setWorking} />
    </AuthPage>
  )
}

export default Registration
