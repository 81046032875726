/**
 * @flow
 * @relayHash a51ed8117f7a14b245897e917bbd491d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ProviderView_query$ref = any;
export type ProviderViewQueryVariables = {|
  providerID: string,
  providerGlobalId?: ?string,
  page?: ?number,
|};
export type ProviderViewQueryResponse = {|
  +$fragmentRefs: ProviderView_query$ref
|};
export type ProviderViewQuery = {|
  variables: ProviderViewQueryVariables,
  response: ProviderViewQueryResponse,
|};
*/


/*
query ProviderViewQuery(
  $providerID: ID!
  $providerGlobalId: String
  $page: Float
) {
  ...ProviderView_query_13tho3
}

fragment Offer_offer on OfferNode {
  id
  name
  finalPrice
  priceType {
    name
    id
  }
  provider {
    name
    id
    image {
      origin
      id
    }
  }
  gallery {
    id
    origin
    default
    thumbnails {
      url
      size
    }
  }
  orderCount
}

fragment ProviderProfileOffers_query_3jiDvX on Query {
  allOffersByProvider(providerGlobalId: $providerGlobalId, first: 15, pageSize: 15, page: $page) {
    edges {
      node {
        ...Offer_offer
        id
        __typename
      }
      cursor
    }
    totalCount
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment ProviderProfile_provider on ProviderNode {
  id
  name
  description
  editable
  workTime
  address
  user {
    id
  }
  contacts {
    type
    value
    verboseName
  }
  image {
    origin
    alt
    thumbnails {
      size
      url
    }
    id
  }
}

fragment ProviderView_query_13tho3 on Query {
  provider(id: $providerID) {
    id
    contacts {
      type
      value
      verboseName
    }
    image {
      origin
      alt
      id
    }
    name
    ...ProviderProfile_provider
  }
  ...ProviderProfileOffers_query_3jiDvX
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "providerID",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "providerGlobalId",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "page",
    "type": "Float",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "page",
  "variableName": "page"
},
v2 = {
  "kind": "Variable",
  "name": "providerGlobalId",
  "variableName": "providerGlobalId"
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "origin",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "size",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "url",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v8 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 15
  },
  (v1/*: any*/),
  {
    "kind": "Literal",
    "name": "pageSize",
    "value": 15
  },
  (v2/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ProviderViewQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "ProviderView_query",
        "args": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "kind": "Variable",
            "name": "providerID",
            "variableName": "providerID"
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ProviderViewQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "provider",
        "storageKey": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "providerID"
          }
        ],
        "concreteType": "ProviderNode",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "contacts",
            "storageKey": null,
            "args": null,
            "concreteType": "ContactObjectType",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "type",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "value",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "verboseName",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "image",
            "storageKey": null,
            "args": null,
            "concreteType": "ProviderImageNode",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "alt",
                "args": null,
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "thumbnails",
                "storageKey": null,
                "args": null,
                "concreteType": "Thumbnail",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/)
                ]
              }
            ]
          },
          (v7/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "description",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "editable",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "workTime",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "address",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "UserNode",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allOffersByProvider",
        "storageKey": null,
        "args": (v8/*: any*/),
        "concreteType": "AdminOfferNodeFilterConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "AdminOfferNodeFilterEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "OfferNode",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v7/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "finalPrice",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "priceType",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "PriceTypeNode",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v3/*: any*/)
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "provider",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ProviderNode",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v3/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "image",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ProviderImageNode",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v3/*: any*/)
                        ]
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "gallery",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "GalleryImageNode",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "default",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "thumbnails",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Thumbnail",
                        "plural": true,
                        "selections": [
                          (v6/*: any*/),
                          (v5/*: any*/)
                        ]
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "orderCount",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "__typename",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "cursor",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "totalCount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageInfo",
            "storageKey": null,
            "args": null,
            "concreteType": "PageInfo",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "endCursor",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasNextPage",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedHandle",
        "alias": null,
        "name": "allOffersByProvider",
        "args": (v8/*: any*/),
        "handle": "connection",
        "key": "ProviderProfileOffers_allOffersByProvider",
        "filters": [
          "providerGlobalId",
          "pageSize",
          "page"
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ProviderViewQuery",
    "id": null,
    "text": "query ProviderViewQuery(\n  $providerID: ID!\n  $providerGlobalId: String\n  $page: Float\n) {\n  ...ProviderView_query_13tho3\n}\n\nfragment Offer_offer on OfferNode {\n  id\n  name\n  finalPrice\n  priceType {\n    name\n    id\n  }\n  provider {\n    name\n    id\n    image {\n      origin\n      id\n    }\n  }\n  gallery {\n    id\n    origin\n    default\n    thumbnails {\n      url\n      size\n    }\n  }\n  orderCount\n}\n\nfragment ProviderProfileOffers_query_3jiDvX on Query {\n  allOffersByProvider(providerGlobalId: $providerGlobalId, first: 15, pageSize: 15, page: $page) {\n    edges {\n      node {\n        ...Offer_offer\n        id\n        __typename\n      }\n      cursor\n    }\n    totalCount\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment ProviderProfile_provider on ProviderNode {\n  id\n  name\n  description\n  editable\n  workTime\n  address\n  user {\n    id\n  }\n  contacts {\n    type\n    value\n    verboseName\n  }\n  image {\n    origin\n    alt\n    thumbnails {\n      size\n      url\n    }\n    id\n  }\n}\n\nfragment ProviderView_query_13tho3 on Query {\n  provider(id: $providerID) {\n    id\n    contacts {\n      type\n      value\n      verboseName\n    }\n    image {\n      origin\n      alt\n      id\n    }\n    name\n    ...ProviderProfile_provider\n  }\n  ...ProviderProfileOffers_query_3jiDvX\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'eb5f134ecee5d552163879a9c8f83b5f';
module.exports = node;
