// @flow

import React from 'react'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'

import FlatListTableBodyRow from './FlatListTableBodyRow'
import { useUser } from '../../../hooks/useUser'
import { isDwellerUser } from '../../../utils/utils'

const FlatListTableBody = props => {
  const { flats } = props

  const dispatch = useDispatch()
  const user = useUser()

  const isDweller = isDwellerUser(user)

  const handleOpen = id => {
    dispatch(push(`/flat/${id}`))
  }

  const updateFlats = flat => {
    props.setFlats(
      flats.map(f => {
        if (f.id === flat.id) {
          return flat
        }

        return f
      })
    )
  }

  return (
    <>
      {flats.map(flat => (
        <FlatListTableBodyRow
          isDweller={isDweller}
          updateFlats={updateFlats}
          key={flat.id}
          flat={flat.flat}
          inhabitancyId={flat.id}
          contractId={flat.contract_id}
          purchaseContractNumber={flat.purchase_contract_number}
          contractStart={flat.contract_start}
          duration={flat.duration}
          contractEnd={flat.contract_end}
          handleOpen={handleOpen}
          permissions={flat.permissions}
        />
      ))}
    </>
  )
}

export default FlatListTableBody
