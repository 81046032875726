//@flow

import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import type { Node } from 'react'

import EmptyWidget from '../EmptyWidget'
import Loader from '../../Loader'
import Widget from '../../../containers/Widget'
import { WIDGET_TYPES } from '../../../containers/widgets/widgets'
import { cutText } from '../../../utils/utils'

import { getBuildingGroupsTiny } from '../../../core/api/api.building'

import Icon from '../../Icon'

type Props = {
  buildingId: number,
  isActive: boolean,
}

const BuildingGroupWidget = (props: Props): Node => {
  const { buildingId: building, isActive } = props

  const { t } = useTranslation('WidgetGroups')

  const [loading, setLoading] = useState(false)
  const [groups, setGroups] = useState([])

  useEffect(() => {
    if (isActive) {
      setLoading(true)

      getBuildingGroupsTiny({ building, manageable_only: 1 }).then(data => {
        setGroups(data.results?.objects || [])
        setLoading(false)
      })
    }
  }, [isActive])

  if (loading) {
    return <Loader text={false} type='medium' />
  }

  const getGroups = () =>
    groups.length > 0 ? (
      groups.map(({ id, avatar_obj, name }) => (
        <div className='widget-new-usermeta' key={id}>
          <Link to={`/building-group/${id}`}>
            <span>
              <span className='widget-new-usermeta__userpic'>
                {avatar_obj?.thumbnail ? (
                  <img src={avatar_obj.thumbnail} />
                ) : (
                  <Icon id='building' />
                )}
              </span>
              <span className='widget-new-usermeta__username'>
                <span title={name}>{cutText(name, 24)}</span>
              </span>
            </span>
          </Link>
        </div>
      ))
    ) : (
      <div className='widget-new-usermeta'>
        <EmptyWidget icon='building' title={t('NoGroups')} />
      </div>
    )

  return (
    <Widget name={WIDGET_TYPES.buildingGroups} title={t('BuildingGroups')}>
      {getGroups()}
    </Widget>
  )
}

export default BuildingGroupWidget
