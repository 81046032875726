import { reject } from 'lodash-es'
import * as actions from './NotificationList.actionTypes'

const initialState = {
  items: {},
  timeouts: [],
  error: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.NOTIFICATION_LIST_INIT:
      return {
        ...state,
        items: {
          ...state.items,
          [action.parent]: [],
        },
      }
    case actions.NOTIFICATION_LIST_ADD:
      return {
        ...state,
        items: {
          ...state.items,
          [action.parent]: [...state.items[action.parent], action.data],
        },
      }
    case actions.NOTIFICATION_LIST_SINGLE_UPDATE:
      return {
        ...state,
        items: {
          ...state.items,
          [action.parent]: [action.data],
        },
      }
    case actions.NOTIFICATION_LIST_REMOVE:
      return {
        ...state,
        items: {
          ...state.items,
          [action.parent]: reject(state.items[action.parent], {
            id: action.id,
          }),
        },
      }
    case actions.NOTIFICATION_LIST_TIMEOUT_SAVE:
      return {
        ...state,
        timeouts: [...state.timeouts, action.timeout],
      }
    case actions.NOTIFICATION_LIST_ERROR:
      return {
        ...state,
        error: action.error.message,
      }
    default:
      return state
  }
}
