/**
 * @flow
 * @relayHash 84f26196f9b451497d68fa96795b10bd
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UserSuggestQueryVariables = {|
  pageSize: number,
  page: number,
  first: number,
  list: string,
|};
export type UserSuggestQueryResponse = {|
  +allProviders: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +name: string,
        +image: ?{|
          +thumbnails: ?$ReadOnlyArray<?{|
            +url: ?string
          |}>
        |},
        +contactEmail: ?string,
      |}
    |}>
  |}
|};
export type UserSuggestQuery = {|
  variables: UserSuggestQueryVariables,
  response: UserSuggestQueryResponse,
|};
*/


/*
query UserSuggestQuery(
  $pageSize: Float!
  $page: Float!
  $first: Int!
  $list: String!
) {
  allProviders(pageSize: $pageSize, page: $page, first: $first, ids: $list) {
    edges {
      node {
        name
        image {
          thumbnails {
            url
          }
          id
        }
        contactEmail
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "pageSize",
    "type": "Float!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "page",
    "type": "Float!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "list",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "list"
  },
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  },
  {
    "kind": "Variable",
    "name": "pageSize",
    "variableName": "pageSize"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "thumbnails",
  "storageKey": null,
  "args": null,
  "concreteType": "Thumbnail",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "url",
      "args": null,
      "storageKey": null
    }
  ]
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "contactEmail",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UserSuggestQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allProviders",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ProviderNodeFilterConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "ProviderNodeFilterEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "ProviderNode",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "image",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ProviderImageNode",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/)
                    ]
                  },
                  (v4/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UserSuggestQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allProviders",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ProviderNodeFilterConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "ProviderNodeFilterEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "ProviderNode",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "image",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ProviderImageNode",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v5/*: any*/)
                    ]
                  },
                  (v4/*: any*/),
                  (v5/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "UserSuggestQuery",
    "id": null,
    "text": "query UserSuggestQuery(\n  $pageSize: Float!\n  $page: Float!\n  $first: Int!\n  $list: String!\n) {\n  allProviders(pageSize: $pageSize, page: $page, first: $first, ids: $list) {\n    edges {\n      node {\n        name\n        image {\n          thumbnails {\n            url\n          }\n          id\n        }\n        contactEmail\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '57f132df83c8fa5867d5591fb9bbe4df';
module.exports = node;
