// @flow

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import EmptyList from '../EmptyList'
import BottomPagination from '../Pagination/BottomPagination'
import api from '../../core/api'
import WorkflowModal from './WorkflowModal'
import WorkflowListToolbox from './WorkflowListToolbox'
import WorkflowListTable from './WorkflowListTable'
import { useLoad, usePathname } from '../../hooks'
import Loader from '../Loader'
import Content from '../Content'
import { SimpleBlock, Header } from '../Block'
import addRedirectToFirstPage from '../../hocs/addRedirectToFirstPage'
import Modal from '../Modal'

const WorkflowList = (): Node => {
  const [isModalOpen, setModalOpen] = useState(false)
  const [cloningWorkflowId, setCloningWorkflowId] = useState(null)
  const [modal, setModal] = useState(null)

  const handleClick = () => {
    setModalOpen(true)
  }

  useEffect(() => {
    if (cloningWorkflowId) {
      setModalOpen(true)
    }
  }, [cloningWorkflowId])
  const isLocked = usePathname()
  const [data, isLoading, isError, load] = useLoad(
    api.requestConstructor.getWorkflowList,
    null,
    isLocked
  )

  const { t } = useTranslation('Workflow')

  const closeModal = needReset => {
    setModalOpen(false)

    if (cloningWorkflowId) {
      setCloningWorkflowId(null)
    }

    if (needReset) {
      load()
    }
  }

  // TODO Need error component
  if (isError) {
    return null
  }

  if (!data && !isLoading) {
    return null
  }

  return (
    <Content>
      <SimpleBlock>
        <Header header>
          {t('WorkflowsTitle')}&nbsp;
          {data && <span className='unit__title-num'>{data.meta.count}</span>}
        </Header>
        {data ? (
          <>
            <WorkflowListToolbox handleClick={handleClick} meta={data.meta} />
            <WorkflowListTable
              load={load}
              setModal={setModal}
              loading={isLoading}
              workflows={data.results.objects}
              setCloningWorkflowId={setCloningWorkflowId}
            />
            {!data.results.objects.length && !isLoading && (
              <EmptyList
                embedded
                canAdd
                noShowAddIcon
                btnText={t('AddWorkflow')}
                icon='workflow'
                title={t('WorkflowNotFound')}
                onClick={handleClick}
              />
            )}
            <BottomPagination meta={data.meta} classes='paginator-2' />
          </>
        ) : (
          <Loader type='big' text={false} />
        )}
        {isModalOpen && (
          <WorkflowModal
            cloningWorkflowId={cloningWorkflowId}
            isModalOpen={isModalOpen}
            closeModal={closeModal}
          />
        )}
        {!!modal && (
          <Modal isOpen onRequestClose={() => setModal(null)}>
            {modal}
          </Modal>
        )}
      </SimpleBlock>
    </Content>
  )
}

export default addRedirectToFirstPage(WorkflowList)
