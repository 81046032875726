// @flow

import React, { Suspense } from 'react'
import classnames from 'classnames'
import type { Node } from 'react'

import * as Icons from './Icons'
import type { IconId } from './Icon.types'

import styles from './Icon.module.scss'

type Props = {
  className?: string,
  colored?: boolean,
  colorOnHover?: boolean,
  disabled?: boolean,
  fallback?: Object,
  id: IconId,
  onClick?: () => void,
  style?: Object,
  title?: string,
}

const Icon = (props: Props): Node => {
  const {
    id,
    className,
    colored,
    colorOnHover,
    disabled,
    style,
    title,
    fallback: Fallback,
  } = props

  const Svg = Icons[id]

  const iconClass = classnames(styles.icon, className, {
    [styles.interactive]: Boolean(props.onClick),
    [styles.colored]: colored,
    [styles.coloredHover]: colorOnHover,
    [styles.disabled]: disabled,
  })

  const svgProps = Object.assign(
    {},
    { className: iconClass, title },
    style ? { style } : undefined,
    !disabled && props.onClick ? { onClick: props.onClick } : undefined
  )

  return (
    <Suspense fallback={Fallback ? <Fallback /> : null}>
      <Svg {...svgProps} />
    </Suspense>
  )
}

export default Icon
