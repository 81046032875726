// @flow

import { castArray } from 'lodash-es'

import {
  NOTIFICATION_ERROR,
  NOTIFICATION_POST,
} from '../../components/Notification/NotificationItem'
import { NUMBER_OF_POSTS_PER_REQUEST } from '../../constants'
import BrowserStorage from '../../utils/browserStorage'
import { getTranslationKeys } from '../../utils/utils'
import * as actions from './NewsList.actions'

const getHiddenPosts = () => {
  const posts = BrowserStorage.get('hiddenPosts')

  return posts ? JSON.parse(posts) : {}
}
const hasMore = objects => objects.length > NUMBER_OF_POSTS_PER_REQUEST - 1
const initialState = {
  initiating: true,
  loadingMore: false,
  posts: [],
  notifications: [],
  hasMore: false,
  postsPinning: new Set(),
  hiddenPosts: getHiddenPosts(),
  postsCount: 0,
  permissions: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.NEWS_LIST_RESET:
      return initialState

    case actions.NEWS_LIST_INITIATED:
      return {
        ...state,
        initiating: false,
        posts: action.data.results.objects,
        permissions: action.data.permissions,
        postsCount: action.data.meta.count,
        hasMore: hasMore(action.data.results.objects),
      }

    case actions.NEWS_LIST_LOAD_MORE_INITIATING:
      return {
        ...state,
        loadingMore: true,
      }

    case actions.NEWS_LIST_LOAD_MORE_INITIATED:
      return {
        ...state,
        loadingMore: false,
        posts: state.posts.concat(action.data.results.objects),
        hasMore: hasMore(action.data.results.objects),
      }

    case actions.NEWS_LIST_BLOCK_POST_INITIATED: {
      const posts = state.posts.map(p => {
        if (p.uuid === action.postUuid) {
          return {
            ...p,
            spam: !action.isUnblock,
          }
        }

        return p
      })

      return {
        ...state,
        posts,
      }
    }

    case actions.NEWS_LIST_BLOCK_USER_INITIATED: {
      const posts = state.posts.map(p => {
        if (p.uuid === action.postUuid) {
          return {
            ...p,
            owner_obj: {
              ...p.owner_obj,
              status: action.isUnblock ? 1 : 3,
            },
          }
        }

        return p
      })

      return {
        ...state,
        posts,
      }
    }

    case actions.NEWS_LIST_PIN_POST_INITIATING:
      return {
        ...state,
        postsPinning: new Set([...state.postsPinning]).add(action.uuid),
      }

    case actions.NEWS_LIST_POST_WAS_PINNED: {
      const posts = state.posts.map(p => {
        if (p.uuid === action.post.uuid) {
          return action.post
        }

        return p
      })
      const postsPinning = new Set([...state.postsPinning])
      postsPinning.delete(action.post.uuid)

      return {
        ...state,
        posts,
        postsPinning,
      }
    }

    case actions.NEWS_LIST_ADD_POST:
      return {
        ...state,
        posts: [action.post].concat(state.posts),
        notifications: state.notifications.concat([
          { type: NOTIFICATION_POST },
        ]),
      }

    case actions.NEWS_LIST_ERROR:
      return {
        ...state,
        notifications: state.notifications.concat(
          castArray(action.error).map(err => ({
            type: NOTIFICATION_ERROR,
            text: getTranslationKeys(err),
          }))
        ),
      }

    case actions.NEWS_LIST_WELCOME_NEWS_CHANGED:
      return {
        ...state,
        hiddenPosts: action.hiddenPosts,
      }

    case actions.NEWS_LIST_READ_POST: {
      const posts = state.posts.map(p => {
        if (p.uuid === action.uuid) {
          return {
            ...p,
            is_unread: false,
          }
        }

        return p
      })

      return {
        ...state,
        posts,
      }
    }

    default:
      return state
  }
}
