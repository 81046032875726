const numberArrayTypes = [
  'building',
  'building_group',
  'exclude_id',
  'id',
  'partner_salutation',
  'personal_preferred_communication',
  'personal_salutation',
  'personal_status',
  'status',
]

function hasOnlyDigits(value) {
  return /^\d+$/.test(value)
}

function toValidParamValue(value, key) {
  // We should transform string 'null' to native null
  if (value === 'null') {
    return null
  }

  if (numberArrayTypes.includes(key) && hasOnlyDigits(value)) {
    return +value
  }

  return value
}

export const filterStringTypes = [
  'archived',
  'created_gte',
  'created_lte',
  'last_invited_gte',
  'last_invited_lte',
  'registered_gte',
  'registered_lte',
  'updated_gte',
  'updated_lte',
]

export const formatFilters = params => {
  let paramsKeys = Object.keys(params)
  let filters = { ...params }

  paramsKeys.forEach(key => {
    if (!filterStringTypes.includes(key)) {
      let nextParams

      if (Array.isArray(params[key])) {
        nextParams = params[key].map(param => toValidParamValue(param, key))
      } else {
        nextParams = [params[key]].map(param => toValidParamValue(param, key))
      }

      filters[key] = nextParams
    }
  })

  return filters
}
