// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import NewTable from '../../NewTable'
import NewTableHeader from '../../NewTable/NewTableHeader'
import NewTableHeaderRow from '../../NewTable/NewTableHeaderRow'
import NewTableHeaderCell from '../../NewTable/NewTableHeaderCell'
import NewTableBody from '../../NewTable/NewTableBody'
import ActivityPriorityTableRow from './ActivityPriorityTableRow'

type Props = { onEdit: Function }

const ActivityPriorityTable = (props: Props): Node => {
  const { t } = useTranslation('Activities')

  const options = [
    {
      value: 'editActivityPriority',
      label: t('EditActivityPriority'),
      onClick: props.onEdit,
      icon: 'pencil',
    },
  ]

  return (
    <NewTable>
      <NewTableHeader>
        <NewTableHeaderRow>
          <NewTableHeaderCell title={t('ActivityPriorityTitle')} />
        </NewTableHeaderRow>
      </NewTableHeader>
      <NewTableBody>
        <ActivityPriorityTableRow options={options} onEdit={props.onEdit} />
      </NewTableBody>
    </NewTable>
  )
}

export default ActivityPriorityTable
