// @flow

import React, { Fragment } from 'react'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { map } from 'lodash-es'

import { EntityIcon } from '../Icon'
import MoreIcon from '../../../static/icons/more.svg'
import Avatar from '../../components/Avatar'
import { isNoProfile } from '../../utils/utils'
import StatusValue from '../User/UserList/UserItem/StatusValue'

type Props = {
  arrow?: boolean,
  avatar?: ?string,
  cleanable?: boolean,
  clickable?: boolean,
  disabled?: boolean,
  error?: boolean,
  fullWidth?: boolean,
  iconId?: string,
  inline?: boolean,
  isLink: boolean,
  margin?: boolean,
  more?: boolean,
  onRemove?: () => void,
  pathName?: string,
  profile?: boolean,
  profileId?: number,
  round?: boolean,
  text: string,
  valueId?: string,
}

const ValueItem = (props: Props) => {
  const {
    cleanable,
    avatar,
    avatarClass,
    profile,
    text,
    role,
    onRemove,
    fullWidth,
    round,
    clickable,
    disabled,
    arrow,
    more,
    inline,
    error,
    profileId,
    margin,
    pathName,
    valueId,
    iconId,
    status,
    statusClass,
  } = props

  const { t } = useTranslation()

  const statuses = useSelector(state => state.init.user_status)

  const statusOptions = map(statuses, (val, label) => ({
    code: val,
    value: label,
    label: t(`Common:${label}`),
  }))

  const isLink =
    (pathName && props.isLink) ||
    (profileId && !isNoProfile(profile) && props.isLink)
  const className = classnames('select--value-item', props.className, {
    'select--value-item--arrow': arrow,
    'select--value-item-fullwidth': fullWidth,
    'select--value-item-round': round,
    'select--value-item-clickable': clickable,
    'select--value-item-disabled': disabled,
    'select--value-item-inline': inline,
    'select--value-item-error': error,
    'select--value-item-margin': margin,
    'select--value-item-link': isLink,
  })
  const style = { marginRight: '10px' }
  var content = (
    <Fragment>
      <div>
        {iconId && <EntityIcon id={iconId} />}
        {!iconId &&
          profile &&
          (avatar ? (
            <div className={avatarClass}>
              <img alt='userpic' src={avatar} />
            </div>
          ) : (
            <Avatar />
          ))}
        <span className='select-value-text' title={text}>
          {text}
        </span>
        {role && <span className='select-value-role'>| {role}</span>}
        {isFinite(status) && (
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginLeft: '4px' }}>|</span>
            <StatusValue
              value={status}
              options={statusOptions}
              className={statusClass}
            />
          </span>
        )}
      </div>
      {cleanable && !!onRemove ? (
        <span
          className='select-value-remove'
          onMouseDown={e => {
            e.stopPropagation()
            e.preventDefault()
          }}
          onClick={e => {
            e.stopPropagation()
            e.preventDefault()
            onRemove()
          }}
        >
          &#10005;
        </span>
      ) : (
        arrow && <span className='select-value-arrow' />
      )}
      {more && <MoreIcon className='select-value-more' />}
    </Fragment>
  )

  const url = pathName || 'profile'
  const id = valueId || profileId

  return isLink ? (
    <Link className={className} style={style} to={`/${url}/${id}`}>
      {content}
    </Link>
  ) : (
    <div className={className} style={style} onClick={props.onClick}>
      {content}
    </div>
  )
}

ValueItem.defaultProps = {
  profileId: null,
  isLink: true,
}

export default ValueItem
