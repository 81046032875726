// @flow

import React from 'react'
import { get } from 'lodash-es'
import type { Node } from 'react'

import styles from './Image.module.scss'

type Props = { description?: string, file?: ?Object, title?: string }

const Image = (props: Props): Node => {
  const { file, description, title } = props

  if (!file) {
    return null
  }

  const thumbnail = get(file, ['thumbnails', '340'])

  const src = thumbnail || file.preview

  if (!src) {
    return null
  }

  const alt = title || description

  return (
    <div className={styles.image}>
      <img src={src} alt={alt} />
    </div>
  )
}

export default Image
