// @flow

// TODO появляется для неадминов. Для админов -- выбор "любимых" провайдеров
import React, { useState, useEffect, Fragment } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { captureException } from '@sentry/browser'

import { MARKETPLACE_ROUTES } from '../../../constants'
import EmptyList from '../../../components/EmptyList'
import TileList from '../../../components/TileList'
import { USER_GROUPS } from '../../../constants'
import Button from '../../../components/Button'
import Modal from '../../../components/Modal'
import environment from '../../../createRelayEnvironment'
import AllProvidersListMutation from './AllProvidersList.mutation'
import Checkbox from '../../../components/Checkbox'
import Toolbox from '../../../components/Toolbox'
import Card from '../../../components/Card/Card'
import Stat from '../../../components/Stat'
import ListViewToggleButton from '../../../components/ListViewToggleButton'
import AllProvidersTable from './AllProvidersTable'
import BottomPagination from '../../../components/Pagination/BottomPagination'
import ConfirmationPopup from '../../../components/modals/ConfirmationPopup'
import BuildingMappingModal from './BuildingMappingModal'
import CategoryMappingModal from './CategoryMappingModal'

import commonStyles from './common.module.scss'

// TODO заменить contractors на providers
type Props = {
  query: Object,
}

const AllProvidersList = (props: Props) => {
  const [selectedContractors, setSelectedContractors] = useState([])
  const [isModalShown, toggleModal] = useState(false)
  const [buildingModal, setBuildingModal] = useState(null)
  const [categoryModal, setCategoryModal] = useState(null)
  const [redirect, setRedirect] = useState(false)
  const [loading, setLoading] = useState(false)
  const [tileView, setTileView] = useState(false)
  const [singleId, setSingleId] = useState(null)

  const { t } = useTranslation('Providers')

  useEffect(() => {
    const tileView = localStorage.getItem('allProvidersTileView')

    if (tileView !== null) {
      setTileView(tileView === 'true')
    } else {
      localStorage.setItem('allProvidersTileView', 'false')
    }

    return () => {
      const {
        userGroup,
        location: { pathname },
      } = props

      if (
        pathname === MARKETPLACE_ROUTES['marketplaceAllProviders'] &&
        userGroup !== USER_GROUPS.admin
      ) {
        setRedirect(true)
      }
    }
  }, [])

  const updateProviders = () => {
    //eslint-disable-line

    const ids = singleId ? [singleId] : selectedContractors

    AllProvidersListMutation.commit(environment, { ids }, (_, errors) => {
      if (errors && errors.length > 0) {
        const errorData = JSON.stringify(errors)
        captureException(errorData)
      }

      hideModal()
      setSelectedContractors([])
      setLoading(false)
    })
  }

  const showModal = id => {
    if (id) {
      setSingleId(id)
    }

    toggleModal(true)
  }

  const hideModal = () => {
    if (singleId) {
      setSingleId(null)
    }

    toggleModal(false)
  }

  const toggleProvider = id => {
    return () => {
      if (selectedContractors.includes(id)) {
        setSelectedContractors(
          selectedContractors.filter(contractorId => contractorId !== id)
        )
      } else {
        setSelectedContractors([...selectedContractors, id])
      }
    }
  }

  const toggleAll = () => {
    if (selectedContractors.length !== providers.length) {
      setSelectedContractors(providers.map(provider => provider.global_id))
    } else {
      setSelectedContractors([])
    }
  }

  const toggleView = () => {
    const nextTileView = !tileView

    localStorage.setItem('allProvidersTileView', nextTileView.toString())

    setTileView(nextTileView)
  }

  const renderControls = () => {
    const { providers } = props

    return (
      <Toolbox>
        <div className='toolbox__cell'>
          <Checkbox
            outer
            checked={selectedContractors.length === providers.length}
            onChange={toggleAll}
          />
        </div>
        <div className='toolbox__cell'>
          <Button.Save
            type='button'
            disabled={selectedContractors.length === 0}
            icon='provider'
            onClick={() => showModal()}
          >
            {t('addToCatalog')}
          </Button.Save>
        </div>
        <div className='toolbox__cell toolbox__cell--right'>
          <ListViewToggleButton tileView={tileView} onClick={toggleView} />
        </div>
      </Toolbox>
    )
  }

  const renderModal = function () {
    return (
      <Modal isOpen={isModalShown}>
        <ConfirmationPopup
          title={t('addProviders')}
          text={t('setVisibility')}
          confirm={t('add')}
          cancel={t('cancel')}
          onClose={hideModal}
          onOk={() => updateProviders()}
        />
      </Modal>
    )
  }

  const renderProviderItem = provider => {
    const { global_id, avatar, name, offer_count, order_count } = provider

    const { userGroup } = props

    const isAdmin = userGroup === USER_GROUPS.admin

    const checked = selectedContractors.includes(global_id)

    return (
      <Card
        key={global_id}
        link={MARKETPLACE_ROUTES['marketplaceProvider'](global_id)}
        imgSrc={avatar}
        canToggle={isAdmin}
        checked={checked}
        text={name}
        onChange={toggleProvider(global_id)}
      >
        <Stat iconId='order' count={order_count} />
        <Stat iconId='offer' count={offer_count} />
      </Card>
    )
  }

  const redirectToCreateProvider = () => {
    props.history.push(MARKETPLACE_ROUTES['marketplaceCreateProvider'])
  }

  if (redirect) {
    return <Redirect to='/' />
  }

  const { providers, meta } = props

  const { userGroup } = props

  const isAdmin = userGroup === USER_GROUPS.admin
  const isManager = userGroup === USER_GROUPS.manager

  const renderResults = function () {
    if (providers.length === 0) {
      return <EmptyList icon='provider' title={t('noProvidersFound')} />
    } else {
      return (
        <Fragment>
          <div className={commonStyles.content}>
            {isAdmin && renderControls()}
            {isManager && (
              <div className='toolbox'>
                <div className='toolbox__cell'>
                  <Button.Save
                    icon='provider'
                    type='button'
                    onClick={redirectToCreateProvider}
                  >
                    {t('createProvider')}
                  </Button.Save>
                </div>
              </div>
            )}
            {tileView ? (
              <TileList rowLength={5} working={loading}>
                {providers.map(renderProviderItem)}
              </TileList>
            ) : (
              <AllProvidersTable
                providers={providers}
                selectedItems={selectedContractors}
                loading={loading}
                setBuildingModal={setBuildingModal}
                setCategoryModal={setCategoryModal}
                toggleProvider={toggleProvider}
                updateProviders={showModal}
              />
            )}
            <BottomPagination classes='paginator-2' meta={meta} />
          </div>
        </Fragment>
      )
    }
  }

  const handleCloseBuildingModal = () => {
    setBuildingModal(null)
  }

  const handleCloseCategoryModal = () => {
    setCategoryModal(null)
  }

  return (
    <>
      {isAdmin && renderModal()}
      {renderResults()}
      {buildingModal && (
        <BuildingMappingModal
          buildingModal={buildingModal}
          setLoading={props.setLoading}
          onClose={handleCloseBuildingModal}
        />
      )}
      {categoryModal && (
        <CategoryMappingModal
          categoryModal={categoryModal}
          setLoading={props.setLoading}
          onClose={handleCloseCategoryModal}
        />
      )}
    </>
  )
}

const mapStateToProps = ({ login: { user } }) => ({
  userGroup: user.group,
})

export default compose(connect(mapStateToProps), withRouter)(AllProvidersList)
