export const Convert = {
  obj2params(obj, isQueryString, duplicateParams) {
    const prependSign = isQueryString ? '?' : ''

    return (
      prependSign +
      Object.keys(obj)
        .map(key => {
          if (Object.prototype.toString.call(obj[key]) === '[object Array]') {
            let param = ''
            for (let i = 0; i < obj[key].length; i++) {
              if (
                Object.prototype.toString.call(obj[key][i]) ===
                '[object Object]'
              ) {
                for (const slk in obj[key][i]) {
                  param += `${encodeURIComponent(
                    key
                  )}[][${slk}]=${encodeURIComponent(obj[key][i][slk])}&`
                }
              } else {
                const restArray = duplicateParams ? '' : '[]'

                param += `${encodeURIComponent(
                  key
                )}${restArray}=${encodeURIComponent(obj[key][i])}&`
              }
            }

            return param.slice(0, -1)
          }

          return `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`
        })
        .join('&')
    )
  },
}
