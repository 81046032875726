// @flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import { get, map } from 'lodash-es'

import Modal from '../Modal'
import ConfirmationPopup from '../modals/ConfirmationPopup'
import Checkbox from '../Checkbox'
import { globalModalError } from '../Layout/Layout.actions'
import Loader from '../Loader/Loader'
import TopPagination from '../Pagination/TopPagination'
import Button from '../Button'
import NewFilesUpload from '../NewFilesUpload'
import {
  importDocument,
  deleteDocumentMapping,
  patchDocumentMapping,
} from '../../core/api/api.documentMapping'
import { getStatus } from '../MappedDocument/MappedDocument.utils'

type Props = {
  loading: boolean,
  meta: Object,
  setLoading: boolean => void,
  setUploading: boolean => void,
}

const DocumentMappingHeader = (props: Props) => {
  const { meta, loading, documents, selectedItems, softArchived } = props
  const dispatch = useDispatch()

  const [modal, setModal] = useState(null)

  const { t } = useTranslation('DocumentMapping')

  const handleFinishUpload = file => {
    if (!file || !file.length) {
      props.setUploading(false)

      return
    }

    importDocument({ file: file[0].id })
      .then((data: Object) => getStatus(data.eventId))
      .then((data: Object) => {
        const status = get(data, 'status')

        if (status === 'error') {
          const action: Object = globalModalError(
            t('WrongFileFormatText'),
            t('WrongFileFormatTitle')
          )

          props.setUploading(false)
          dispatch(action)
        } else {
          dispatch(push(`/document-mapping/${data.instance}/`))
        }
      })
  }

  const handleStartUpload = () => {
    props.setUploading(true)
  }

  const toggleAll = () => {
    props.setSelected(map(documents, 'id'))
  }

  const hideModal = () => {
    setModal(null)
  }

  const removeDocuments = () => {
    return Promise.all(
      selectedItems.map(document => {
        return deleteDocumentMapping(document).then(data => data)
      })
    )
  }

  const restoreDocuments = () => {
    return Promise.all(
      selectedItems.map(document => {
        return patchDocumentMapping(document, { soft_archived: false }).then(
          data => data
        )
      })
    ).catch(() => {
      props.setRemoving(false)
      props.setLoading(true)
    })
  }

  const handleMassRemove = async () => {
    hideModal()

    props.setRemoving(true)

    await removeDocuments()

    props.setRemoving(false)
    props.setLoading(true)
  }

  const handleMassRestore = async () => {
    hideModal()

    props.setRemoving(true)

    await restoreDocuments()

    props.setRemoving(false)
    props.setLoading(true)
  }

  const openMassRemoveModal = () => {
    setModal(
      <ConfirmationPopup
        confirm={t('DocumentsRemoveButton')}
        title={t('DocumentsRemoveTitle')}
        text={t('ConfirmDocumentsRemoving')}
        onClose={hideModal}
        onOk={handleMassRemove}
      />
    )
  }

  const openMassRestoreModal = () => {
    setModal(
      <ConfirmationPopup
        confirm={t('DocumentsRestoreButton')}
        title={t('DocumentsRestoreTitle')}
        text={t('ConfirmDocumentsRestoring')}
        onClose={hideModal}
        onOk={handleMassRestore}
      />
    )
  }

  return (
    <>
      {loading ? (
        <div className='toolbox'>
          <div className='toolbox__cell'>
            <Loader style={{ padding: '7px 0' }} />
          </div>
        </div>
      ) : (
        <div className='toolbox'>
          <div className='toolbox__cell'>
            <Checkbox
              outer
              disabled={!documents.length}
              checked={props.isAllSelected(documents)}
              onChange={toggleAll}
            />
          </div>
          {selectedItems.length === 0 && !softArchived && (
            <div className='toolbox__cell'>
              <NewFilesUpload
                name='attach-document'
                maxSize={1000}
                accept='application/pdf'
                onFinishUpload={handleFinishUpload}
                onStartUpload={handleStartUpload}
              >
                <Button.Save disabled={loading}>{t('AddDocument')}</Button.Save>
              </NewFilesUpload>
            </div>
          )}
          {!!selectedItems.length && !softArchived && (
            <div className='toolbox__cell'>
              <Button.Remove
                disabled={!selectedItems.length}
                onClick={openMassRemoveModal}
              >
                {t('RemoveDocuments')}
              </Button.Remove>
            </div>
          )}
          {softArchived && (
            <div className='toolbox__cell'>
              <Button.Cancel
                disabled={!selectedItems.length}
                icon='restore'
                onClick={openMassRestoreModal}
              >
                {t('RestoreDocuments')}
              </Button.Cancel>
            </div>
          )}
          <div className='toolbox__cell toolbox__cell--right'>
            <TopPagination
              classes='pager pager--large pager--default-2'
              meta={meta}
            />
          </div>
        </div>
      )}
      <Modal
        contentLabel=''
        className='Modal__Bootstrap modal-dialog'
        isOpen={!!modal}
        onRequestClose={hideModal}
      >
        {modal}
      </Modal>
    </>
  )
}

export default DocumentMappingHeader
