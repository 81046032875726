// @flow

import React from 'react'
import ReactTelephoneInput from 'react-telephone-input'
import type { Node } from 'react'

type Props = {
  autoFocus?: boolean,
  className: string,
  countryCode: string,
  disabled?: boolean,
  name?: string,
  onChange: string => void,
  value: string,
}

const PhoneInput = (props: Props): Node => {
  const {
    className,
    value,
    countryCode,
    disabled,
    name = '',
    autoFocus = false,
  } = props

  const handleChange = value => {
    const input = value.substr(0, 16)

    props.onChange(input)
  }

  return (
    <ReactTelephoneInput
      inputProps={{
        name: name,
        autoFocus: autoFocus,
      }}
      defaultCountry={countryCode.toLowerCase()}
      preferredCountries={['at', 'de', 'ch']}
      flagsImagePath='/static/images/flags.png'
      value={value}
      classNames={className}
      autoFormat={false}
      maxlength='15'
      disabled={disabled}
      onChange={handleChange}
    />
  )
}

export default PhoneInput
