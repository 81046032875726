// @flow

import { get } from 'lodash-es'
import { useLocation } from 'react-router-dom'

import { getCurrentFilters } from '../utils/routing'

export const useFilterValue = (filterKey: string): any => {
  const location = useLocation()

  const currentFilters = getCurrentFilters(location, [filterKey])

  return get(currentFilters, filterKey, null)
}
