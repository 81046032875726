// @flow

import AddManagerPopUp from './AddManagerPopUp'
import { connect } from 'react-redux'
import { getUk } from '../../../utils/commonSelectors'

const mapStateToProps = state => ({
  ...state.addManagerPopUp,
  uk: getUk(state),
  ukName: state.init.uk.name,
})

export default connect(mapStateToProps)(AddManagerPopUp)
