// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'

import NewTable from '../../NewTable'
import NewTableHeader from '../../NewTable/NewTableHeader'
import NewTableHeaderRow from '../../NewTable/NewTableHeaderRow'
import NewTableHeaderCell from '../../NewTable/NewTableHeaderCell'
import NewTableBody from '../../NewTable/NewTableBody'
import ActivityTypeTableRow from './ActivityTypeTableRow'
import { updateActivityType } from '../../../core/api/api.pipeline'
import EmptyList from '../../EmptyList/EmptyList'

const ActivityTypeTable = props => {
  const { t } = useTranslation('Activities')

  const { data, isLoading } = props

  const options = [
    {
      value: 'editActivityType',
      label: t('EditActivityType'),
      onClick: activityType => {
        props.setEditingActivityType(activityType)
        props.onEdit()
      },
      icon: 'pencil',
    },
  ]

  const handleToggleActivityType = (id, params) => {
    updateActivityType(id, params).then(() => {
      props.load()
    })
  }

  return (
    <>
      <NewTable>
        <NewTableHeader>
          <NewTableHeaderRow>
            <NewTableHeaderCell
              title={t('ActivityTypeTitle')}
              style={{ width: '75%' }}
            />
            <NewTableHeaderCell
              title={t('ActivityTypeSymbol')}
              style={{ width: '5%' }}
            />
            <NewTableHeaderCell
              title={t('ActivityTypeStatus')}
              style={{ width: '20%' }}
            />
          </NewTableHeaderRow>
        </NewTableHeader>
        <NewTableBody>
          {data.results.objects.map((activityType, num) => {
            return (
              <ActivityTypeTableRow
                setEditingActivityType={props.setEditingActivityType}
                options={options}
                activityType={activityType}
                key={activityType.id}
                toggleDisabled={num === 0}
                handleToggleActivityType={handleToggleActivityType}
                onEdit={props.onEdit}
              />
            )
          })}
        </NewTableBody>
      </NewTable>
      {!data.results.objects.length && !isLoading && (
        <EmptyList icon='folder' title={t('ActivityTypesNotFound')} />
      )}
    </>
  )
}

export default ActivityTypeTable
