// @flow

import React, { useState } from 'react'
import { take } from 'lodash-es'
import type { Node } from 'react'

import Chip from '../../../components/Chip/Chip'
import type { MailItem } from '../ThreadsList'

import styles from './ThreadsListItemFiles.module.scss'
import Lightbox from '../../../components/LightboxGallery'

type Props = { lastMessage: MailItem }

const ThreadsListItemFiles = (props: Props): Node => {
  const { lastMessage } = props
  const [activeIndex, setActiveIndex] = useState(null)

  if (!lastMessage) {
    return null
  }

  const { files } = lastMessage

  if (!files || !files.length) {
    return null
  }

  const openPreview = (e, id) => {
    e.stopPropagation()
    let activeIndex = files.findIndex(f => f.id === id)
    setActiveIndex(activeIndex)
  }

  const closeLightbox = () => {
    setActiveIndex(null)
  }

  return (
    <div
      className={styles.files}
      onClick={e => {
        e.stopPropagation()
      }}
    >
      {take(files, 3).map(file => (
        <Chip
          key={file.id}
          file={file}
          onClick={e => openPreview(e, file.id)}
        />
      ))}
      <Lightbox
        newGallery
        activeIndex={activeIndex}
        images={files}
        scope='mail'
        onClose={closeLightbox}
      />
    </div>
  )
}

export default ThreadsListItemFiles
