import React from 'react'
import { useTranslation } from 'react-i18next'

import NewTableHeader from '../../NewTable/NewTableHeader'
import NewTableHeaderCell from '../../NewTable/NewTableHeaderCell'
import NewTableHeaderRow from '../../NewTable/NewTableHeaderRow'

const EmailIntegrationTableHeader = () => {
  const { t } = useTranslation('EmailIntegrationNylas')

  return (
    <NewTableHeader>
      <NewTableHeaderRow>
        <NewTableHeaderCell
          title={t('EmailAccount')}
          style={{ width: '20%' }}
        />
        <NewTableHeaderCell title={t('TypeAccount')} style={{ width: '16%' }} />
        <NewTableHeaderCell title={t('EmailStatus')} style={{ width: '16%' }} />
        <NewTableHeaderCell title={t('LastSync')} style={{ width: '16%' }} />
        <NewTableHeaderCell
          title={t('ShareInternally')}
          style={{ width: '16%' }}
        />
        <NewTableHeaderCell title={t('Activated')} style={{ width: '16%' }} />
      </NewTableHeaderRow>
    </NewTableHeader>
  )
}

export default EmailIntegrationTableHeader
