// @flow

import React from 'react'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'

import DocumentMappingTableBodyRow from './DocumentMappingTableBodyRow'

const DocumentMappingTableBody = props => {
  const { documents, selectedItems } = props

  const dispatch = useDispatch()

  const handleOpen = id => {
    dispatch(push(`/document-mapping/${id}/`))
  }

  return (
    <>
      {documents.map(document => (
        <DocumentMappingTableBodyRow
          key={document.id}
          checked={selectedItems.includes(document.id)}
          document={document}
          handleOpen={handleOpen}
          setChecking={props.setChecking}
          handleChange={props.changeSelected}
        />
      ))}
    </>
  )
}

export default DocumentMappingTableBody
