// @flow

import React from 'react'
import { useParams } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import Table from '../../../../Table'

type Props = {
  t: string => string,
  working: boolean,
}

const UserListHead = (props: Props) => {
  const { working } = props
  const { groupId: buildingGroup, search } = useParams()

  return (
    <Table.HeaderRow>
      <Table.HeaderCell
        title={props.t('UserFullName')}
        sortKey={search ? null : buildingGroup ? 'fullname' : 'name'}
        working={working}
        style={{ width: buildingGroup ? '40%' : '25%' }}
      />
      <Table.HeaderCell
        title={props.t('Role')}
        sortKey={search ? null : buildingGroup ? 'is_landlord' : 'role'}
        working={working}
        style={{ width: buildingGroup ? '20%' : '10%' }}
      />
      <Table.HeaderCell
        title={props.t('AddressColumnTitle')}
        working={working}
        style={{ width: buildingGroup ? '40%' : '25%' }}
      />
      {!buildingGroup && (
        <>
          <Table.HeaderCell
            title={props.t('Email')}
            sortKey={search ? null : 'owner__email'}
            working={working}
            style={{ width: '15%' }}
          />
          <Table.HeaderCell
            title={props.t('PhoneColumnTitle')}
            sortKey={search ? null : 'phone'}
            working={working}
            style={{ width: '15%' }}
          />
          <Table.HeaderCell
            title={props.t('Created')}
            sortKey={search ? null : 'created'}
            working={working}
            style={{ width: '10%' }}
          />
        </>
      )}
    </Table.HeaderRow>
  )
}

export default withTranslation('User')(UserListHead)
