// @flow

import React, { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import type { Node } from 'react'

import Button from '../../../Button'
import ListItem from '../../../ListItem'
import Input from '../../../Input'

import styles from './InsuranceListItem.module.scss'

type Props = {
  index: number,
  isEditing: boolean,
  onEdit: (?number) => void,
  onRemove: (?number) => void,
  onReplace: Function,
  onSave: (Object, number) => void,
  payment: Object,
}

const InsuranceListItem = (props: Props): Node => {
  const {
    isEditing,
    payment: { value, id },
    payment,
    onReplace,
    index,
  } = props

  const ref = useRef(null)

  const [{ isDragging }, drag] = useDrag({
    item: {
      id,
      type: 'insurance',
      value,
    },
    canDrag: () => true,
    collect: monitor => {
      return {
        isDragging: monitor.isDragging(),
      }
    },
  })

  const [, drop] = useDrop({
    accept: 'insurance',
    canDrop: (props, monitor) => {
      const { id: targetId } = monitor.getItem()

      return targetId !== id
    },
    hover: (props, monitor) => {
      const { id: targetId } = monitor.getItem()

      if (targetId !== id) {
        onReplace(targetId, index)
      }
    },
    collect: monitor => ({
      isDropTarget: monitor.canDrop() && monitor.isOver({ shallow: true }),
    }),
  })

  const style = { opacity: isDragging ? 0 : 1 }

  drag(drop(ref))

  const handleClick = () => props.onEdit(id)

  const handleRemove = () => props.onRemove(id)

  const handleSave = value => {
    if (payment.value !== value) {
      props.onSave({ ...payment, value }, index)
    }
  }

  const handleEdit = isFieldEditing => {
    if (!isFieldEditing) {
      props.onEdit(null)
    }
  }

  return (
    <>
      {isEditing ? (
        <Input.Edit
          draggable
          editing
          value={value}
          maxLength={200}
          onToggleEdit={handleEdit}
          onRemove={handleRemove}
          onSave={handleSave}
        />
      ) : (
        <ListItem
          className={styles['list-item']}
          primaryText={value}
          meta={<Button.Edit onClick={handleClick} />}
          ref={ref}
          style={style}
        />
      )}
    </>
  )
}

export default InsuranceListItem
