// @flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import { STATUSES_COLORS } from '../../../../ui/MainColors.constants'

import { getRequestPipeline } from '../../../../core/api/api.pipeline'
import BS from '../../../../utils/browserStorage'
import NewSelectSimple from '../../../NewSelectSimple'

import ConfirmationPopup from '../../../modals/ConfirmationPopup'
import Modal from '../../../Modal'
import { getUser } from '../../../../utils/commonSelectors'
import { isAdminUser, isManagerUser } from '../../../../utils/utils'
import { useSelector } from 'react-redux'

import { COMPLETED_REQUEST_STATUS } from '../../../../constants'

type Props = {
  onChange: Object => void,
  onEdit: Object => void,
  requestId: number,
  statuses: any,
  statusId: number,
}

const StatusSelect = (props: Props): Node => {
  const { statusId, statuses, requestId } = props
  const NOTICE_TITLE = 'notice_warn_closing'
  const { t } = useTranslation('Request')

  const [isOpenModal, setIsOpenModal] = useState(false)
  const user = useSelector(getUser)

  const isPermitted = isAdminUser(user) || isManagerUser(user)
  const isShowed = BS.get(NOTICE_TITLE)

  const status = statuses.find(s => s.id === statusId)

  const getOptions = () => {
    return statuses.map(status => ({
      value: status.id,
      label: t(`${status.title}`),
      style: { color: STATUSES_COLORS[status.css] },
    }))
  }

  const onToggleCheck = isCheck =>
    isCheck ? BS.set(NOTICE_TITLE, true) : BS.remove(NOTICE_TITLE)

  const onAccept = () => {
    props.onChange(COMPLETED_REQUEST_STATUS)
    setIsOpenModal(false)
  }

  const onClose = () => setIsOpenModal(false)

  const onOpen = isOpen => isOpen && props.onEdit()

  const onChange = status => {
    if (isPermitted && !isShowed && status.value === COMPLETED_REQUEST_STATUS) {
      getRequestPipeline(requestId).then(phases => {
        if (
          Array.isArray(phases) > 0 &&
          phases.some(p => p.has_undone_activities)
        ) {
          setIsOpenModal(true)
        } else {
          props.onChange(status.value)
        }
      })
    } else {
      props.onChange(status.value)
    }
  }

  return (
    <>
      <NewSelectSimple
        options={getOptions()}
        selectedItems={status.id ? [{ ...status, value: status.id }] : []}
        buttonType='edit'
        onOpen={onOpen}
        onChange={onChange}
      />
      {isOpenModal && (
        <Modal isOpen>
          <ConfirmationPopup
            isNedShowAgain
            title={t('Request:Attention')}
            text={t('Request:UnfinishedActivitiesWarning')}
            isAsync={false}
            confirm='Ok'
            onToggleCheck={onToggleCheck}
            onOk={onAccept}
            onClose={onClose}
          />
        </Modal>
      )}
    </>
  )
}

export default StatusSelect
