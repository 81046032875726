// @flow

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'
import { capitalize } from 'lodash-es'
import { compose } from 'redux'
import { connect } from 'react-redux'

import Loader from '../../Loader'
import Widget from '../../../containers/Widget'
import { isInitiated, getSteps } from './ProfileFilling.selectors'
import Icon from '../../../../static/icons/contract.svg'

type Props = {
  initiated: boolean,
  steps: Array<Object>,
  t: string => string,
}

class ProfileFilling extends Component<Props> {
  renderStep = (step, i) => {
    const itemClass = classnames('steps__item', {
      steps__item_prev: !!step.value,
    })

    return (
      <div className={itemClass} key={step.key}>
        <span className='steps__num'>{i + 1}</span>
        <span className='steps__text'>
          {this.props.t(capitalize(step.key))}
        </span>
      </div>
    )
  }

  render() {
    const { initiated, steps } = this.props

    return (
      <Widget
        notCollapse
        icon={<Icon />}
        headerClassName='widget-profileWidget'
        title={this.props.t('Filling')}
      >
        <div className='widget__steps steps'>
          {initiated ? (
            steps.map(this.renderStep)
          ) : (
            <Loader text={false} type='medium' />
          )}
        </div>
      </Widget>
    )
  }
}

const mapStateToProps = state => ({
  initiated: isInitiated(state),
  steps: getSteps(state),
})

export default compose(
  withTranslation('Profile'),
  connect(mapStateToProps)
)(ProfileFilling)
