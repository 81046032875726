// @flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import NewTableBodyRow from '../../NewTable/NewTableBodyRow'
import NewTableBodyCell from '../../NewTable/NewTableBodyCell'
import Button from '../../Button'
import { getFormattedDate } from '../../../utils/utils'
import {
  toggleCorporateAccountMarked,
  toggleAccountShared,
  toggleAccountStatus,
  toggleCorporateAccountStatus,
} from '../../../core/api/api.mail'
import Icon from '../../Icon'

import styles from './EmailIntegrationTableBodyRow.module.scss'

type Props = {
  email: {
    description: string,
    id: string,
    is_active: boolean,
    last_polling: string,
    mark_read_by_all: boolean,
    shared: boolean,
    status: Object,
  },
  handleOpen: string => void,
  isPrivate?: boolean,
}

const EmailIntegrationTableBodyRow = (props: Props): Node => {
  const {
    email: {
      id,
      description,
      is_active,
      last_polling,
      shared,
      status,
      mark_read_by_all,
    },
    isPrivate,
  } = props

  const { t } = useTranslation('EmailIntegration')

  const [isActive, setActive] = useState(is_active)
  const [isShared, setShared] = useState(shared)
  const [isMarked, setMarked] = useState(mark_read_by_all)
  const [isHover, setHover] = useState(false)

  const handleMouseEnter = () => setHover(true)
  const handleMouseLeave = () => setHover(false)

  const statusTitle = isActive ? t('Enabled') : t('Disabled')
  const statusColor = isActive ? '#9ec221' : '#e44440'

  const sharedTitle = isShared ? t('accountIsShared') : t('accountIsNotShared')
  const sharedColor = isShared ? '#9ec221' : '#e44440'

  const markedTitle = isMarked
    ? t('accountIsMarkedAsRead')
    : t('accountIsNotMarkedAsRead')
  const markedColor = isMarked ? '#9ec221' : '#e44440'

  const formattedDate = last_polling ? getFormattedDate(last_polling) : '-'

  const toggleStatusApi = isPrivate
    ? toggleAccountStatus
    : toggleCorporateAccountStatus

  const handleOpen = () => {
    props.handleOpen(id)
  }

  const handleToggleActive = () =>
    toggleStatusApi(id).then(() => setActive(!isActive))

  const handleToggleShared = () =>
    toggleAccountShared(id).then(() => setShared(!isShared))

  const handleToggleMarkAsRead = () =>
    toggleCorporateAccountMarked(id).then(() => setMarked(!isMarked))

  const errorMessage =
    status && status.status === 'fail' ? status.message : null

  return (
    <NewTableBodyRow key={id} id={id} onClick={handleOpen}>
      <NewTableBodyCell>
        <div className='table-item table-item__text' title={description}>
          <span>{description}</span>
        </div>
        {errorMessage && (
          <div
            style={{ position: 'relative' }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <Icon id='repeat' className={styles.icon} />
            {isHover && (
              <div className={styles.tooltip}>
                <div />
                <span>
                  <b>{t('HintEmailErrorTitle')}: </b>
                  {errorMessage}
                </span>
              </div>
            )}
          </div>
        )}
      </NewTableBodyCell>
      <NewTableBodyCell className={styles.toggle}>
        <Button.Toggle
          checked={isActive}
          id={`status_${id}`}
          onChange={handleToggleActive}
        />
        <div className='table-item table-item__text' title={statusTitle}>
          <span style={{ color: statusColor }}>{statusTitle}</span>
        </div>
      </NewTableBodyCell>
      {isPrivate ? (
        <NewTableBodyCell className={styles.toggle}>
          <Button.Toggle
            checked={isShared}
            id={`shared_${id}`}
            onChange={handleToggleShared}
          />
          <div className='table-item table-item__text' title={sharedTitle}>
            <span style={{ color: sharedColor }}>{sharedTitle}</span>
          </div>
        </NewTableBodyCell>
      ) : (
        <NewTableBodyCell className={styles.toggle}>
          <Button.Toggle
            checked={isMarked}
            id={`mark_${id}`}
            onChange={handleToggleMarkAsRead}
          />
          <div className='table-item table-item__text' title={markedTitle}>
            <span style={{ color: markedColor }}>{markedTitle}</span>
          </div>
        </NewTableBodyCell>
      )}
      <NewTableBodyCell title={formattedDate} />
    </NewTableBodyRow>
  )
}

export default EmailIntegrationTableBodyRow
