// @flow

import { all, put, call, fork, takeEvery } from 'redux-saga/effects'
import { push, replace } from 'connected-react-router'
import type { Saga } from 'redux-saga'

import api from '../../../core/api'
import {
  serverError,
  updateCountersAction,
  modalError,
} from '../../../components/Layout/Layout.actions'
import { WIDGET_FILES_LOAD } from '../../../components/widgets/WidgetFiles/WidgetFiles.actionTypes'
import * as actions from './MailThread.actionTypes'

function* init({ uuid, limit, params, softArchived }) {
  try {
    yield put({
      type: WIDGET_FILES_LOAD,
      thread: uuid,
      params,
    })

    const getApi = softArchived
      ? api.mail.getArchivedMessage
      : api.mail.getThreadMessage
    const readApi = softArchived
      ? api.mail.readArchivedThread
      : api.mail.readThread
    const req = yield call(getApi, {
      thread: uuid,
      limit,
      ...params,
    })
    const thread = yield call(api.mail.getThread, uuid, params)
    yield call(readApi, uuid, params)

    const unicAddressee = new Set([])
    const addUsers = ids => ids.forEach(id => unicAddressee.add(id))
    req.results.objects.forEach(mail => {
      addUsers(mail.to)
      addUsers(mail.cc)
      addUsers(mail.bcc)
    })

    yield put({
      type: actions.MAIL_INITED,
      meta: req.meta,
      messages: req.results.objects,
      unicAddressee,
      thread,
    })
    yield put(updateCountersAction())
  } catch (error) {
    yield put(serverError(error))
  }
}

function* create(action) {
  try {
    const { params } = action

    yield call(api.mail.createThread, {
      ...params,
      text: params.text.replace(/\n/g, ''),
    })

    yield put({
      type: actions.MAIL_CREATED,
    })

    yield put(replace(`/mails/sent/`))
  } catch (error) {
    if (error.message.response.status === 400) {
      yield put(modalError(error.message.response.data))
      yield put({ type: actions.MAIL_ERROR })

      return
    }

    yield put(serverError(error))
  }
}

function* update(action) {
  try {
    const { params } = action

    const result = yield call(api.mail.createPostInThread, {
      ...params,
      text: params.text.replace(/\n/g, ''),
    })

    yield put({
      type: actions.MAIL_INIT,
      uuid: result.thread,
    })
  } catch (error) {
    if (error.message.response.status === 400) {
      yield put(modalError(error.message.response.data))
      yield put({ type: actions.MAIL_ERROR })

      return
    }

    yield put(serverError(error))
  }
}

function* remove({ uuid, threadUUID, isLast }) {
  try {
    const result = yield call(api.mail.deletePosts, uuid)
    yield put({
      type: actions.REMOVED_MESSAGE,
      uuid,
      ...result,
    })

    if (isLast) {
      yield put(push('/mails'))

      return
    }

    yield put({
      type: actions.MAIL_INIT,
      uuid: threadUUID,
    })
  } catch (error) {
    yield put(serverError(error))
  }
}

function* restore({ uuid, threadUUID, isLast }) {
  try {
    const result = yield call(api.mail.restorePost, uuid, {
      soft_archived: false,
    })
    yield put({
      type: actions.REMOVED_MESSAGE,
      uuid,
      ...result,
    })

    if (isLast) {
      yield put(push('/mails'))

      return
    }

    yield put({
      type: actions.MAIL_INIT,
      uuid: threadUUID,
      params: { soft_archived: true },
    })
  } catch (error) {
    yield put(serverError(error))
  }
}

function* watchInit() {
  yield takeEvery(actions.MAIL_INIT, init)
}

function* watchCreate() {
  yield takeEvery(actions.MAIL_CREATE, create)
}

function* watchUpdate() {
  yield takeEvery(actions.MAIL_UPDATE, update)
}

function* watchRemove() {
  yield takeEvery(actions.REMOVE_MESSAGE, remove)
}

function* watchRestore() {
  yield takeEvery(actions.RESTORE_MESSAGE, restore)
}

export default function* watch(): Saga<void> {
  yield all([
    fork(watchInit),
    fork(watchCreate),
    fork(watchUpdate),
    fork(watchRemove),
    fork(watchRestore),
  ])
}
