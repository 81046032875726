// @flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash-es'

import { Row } from '../../../Block'
import ConnectRequestModal from '../../RequestList/ConnectRequestModal'
import EntityIcon from '../../../Icon/EntityIcon'
import Chip from '../../../Chip'

import styles from './RelatedRequests.module.scss'

const MAX_PREVIEW_COUNT = 5

const RelatedRequests = props => {
  const { value, editing, relatedRequests, relateToCurrent, automate } = props

  const name = get(value, 'name', null)

  const { t } = useTranslation('Request')

  const [showAll, setShowAll] = useState(false)

  const title = t('ConnectedRequestsTitle')

  const handleClose = () => props.onEdit(null)

  const rowProps = Object.assign(
    {},
    { title },
    automate ? { className: styles.automateRow } : {},
    editing
      ? {
          onEdit: () =>
            props.onEdit(
              <ConnectRequestModal
                automate={automate}
                relatedRequests={relatedRequests}
                relateToCurrent={relateToCurrent}
                onClose={handleClose}
                onChange={props.onChange}
              />
            ),
        }
      : undefined
  )

  const getRequestLabel = option => {
    return `${t('Request:request_number_title')}${option.request_no}: ${
      option.title
    }`
  }

  return (
    <>
      <Row {...rowProps}>
        <span
          className={!automate && 'bar__submit-label_light bar__submit-label'}
          title={name}
        >
          {(!relatedRequests || !relatedRequests.length) && !relateToCurrent
            ? name || t('ConnectedRequestsAreNotSelected')
            : null}
          {relatedRequests
            .slice(
              0,
              showAll
                ? relatedRequests.length
                : MAX_PREVIEW_COUNT - !!relateToCurrent
            )
            .map(request => (
              <Chip
                text={getRequestLabel(request)}
                key={request.id || request.value}
                className={styles.chip}
              >
                <div className={styles.iconBounding}>
                  <EntityIcon id='request' className={styles.icon} />
                </div>
              </Chip>
            ))}
          {automate && relateToCurrent && (
            <Chip text={t('ConnectedRequestsCurrent')} className={styles.chip}>
              <div className={styles.iconBounding}>
                <EntityIcon id='request' className={styles.icon} />
              </div>
            </Chip>
          )}
          {!showAll &&
            relatedRequests.length + !!relateToCurrent > MAX_PREVIEW_COUNT && (
              <div
                className={styles.rest}
                title={t('ShowAll')}
                onClick={() => setShowAll(true)}
              >
                +
                {relatedRequests.length - MAX_PREVIEW_COUNT + !!relateToCurrent}
              </div>
            )}
        </span>
      </Row>
    </>
  )
}

export default RelatedRequests
