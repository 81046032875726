import { getUserName } from '../../utils/utils'

export const getLastMessageText = lastMessage => {
  if (!lastMessage) return null

  return lastMessage.is_system
    ? lastMessage.text.replace(/<b>|<\/b>/g, '')
    : lastMessage.text
}

export const getMessageUserName = (t, users, usersCount) => {
  let name = t('Me')

  if (usersCount === 2) {
    const secondName = getUserName(users[0])
    name += `, ${secondName}`
  } else if (usersCount > 2) {
    name += ` +${usersCount - 1}`
  }

  return name
}
