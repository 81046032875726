import { commitMutation, graphql } from 'react-relay'

const mutation = graphql`
  mutation OfferProfileMutation($input: OneClickOrderMutationInput!) {
    oneClickOrder(input: $input) {
      order {
        id
        orderNo
        cost
        pmc
        offer
      }
    }
  }
`

const commit = (environment, offerData, callback, errorCallback) => {
  return commitMutation(environment, {
    mutation,
    variables: {
      input: offerData,
    },
    onCompleted: callback,
    onError: errorCallback,
  })
}

export default { commit }
