// @flow

import { compose } from 'redux'
import { DragSource, DropTarget } from 'react-dnd'

import Card from './Card'
import type { Props } from './Card'

const DragItemType = 'blockCard'

const dragSource = {
  beginDrag(props: Props) {
    const { data } = props.cardProps

    return { id: data.id }
  },
  canDrag(props: Props) {
    const { active } = props.cardProps

    return !active
  },
}
const dropTarget = {
  canDrop(props: Props, monitor) {
    const { id } = monitor.getItem()

    return id !== props.cardProps.data.id
  },
  drop(props: Props, monitor) {
    const { id } = monitor.getItem()
    const { data, onMove } = props.cardProps

    if (id !== data.id) {
      onMove(id)
    }
  },
}

export default compose(
  DragSource(DragItemType, dragSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  })),
  DropTarget(DragItemType, dropTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isDropTarget: monitor.canDrop() && monitor.isOver({ shallow: true }),
  }))
)(Card)
