// @flow

import React from 'react'
import moment from 'moment'
import YearPanel from 'rc-calendar/lib/year/YearPanel'

import Select from '../../../Select/SelectSimple'

const SELECT_HEIGHT = 35

class YearPanelNew extends YearPanel {
  constructor(props) {
    super(props)
    this.state = {
      value: props.value || props.defaultValue,
      year: this.years(props.value || props.defaultValue),
    }
  }

  componentDidUpdate(prevProps) {
    if ('value' in this.props && this.props.value !== prevProps.value) {
      this.setState({
        value: this.props.value,
        year: this.years(this.props.value),
      })
    }
  }

  years(value) {
    let currentYear = new Date().getFullYear()
    let year = 1900
    let toDate = ++currentYear // TODO: this from what start, add from server data integration
    const years = []

    if (this.props.minYear) {
      year = this.props.minYear
      toDate = moment().year()
    }

    if (this.props.maxYear && year <= this.props.maxYear) {
      toDate = this.props.maxYear
    }

    let index = 0

    while (year <= toDate) {
      const content = String(year)
      years[index] = {
        content,
        value: value.clone().year(year),
        title: content,
      }
      year += 1
      index += 1
    }

    return years
  }

  chooseYear = year => {
    this.setState({ value: year.value })
    this.props.onSelect(year.value)
  }

  render() {
    const value = this.state.value
    const years = this.state.year
    const position =
      years.findIndex(y => y.title == value.format('YYYY')) * SELECT_HEIGHT

    return (
      <div className='button-group'>
        <Select
          calendar
          filtering
          labelKey='title'
          clearable={false}
          valueKey='value'
          value={value}
          placeholder=''
          options={years}
          position={position}
          onChange={this.chooseYear}
        />
      </div>
    )
  }
}

export default YearPanelNew
