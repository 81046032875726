// @flow

import React from 'react'

import { isImageFile, isVideoFile } from '../../../../utils/file'

type RenderFileProps = {
  el: Object,
}

const File = ({ el }: RenderFileProps) => {
  if (isImageFile(el)) {
    return <img src={el.origin} alt={el.filename} key={el.id} />
  }

  if (isVideoFile(el)) {
    return (
      <video controls style={{ width: '100%' }}>
        <source src={el.origin} type={el.mime_type} />
      </video>
    )
  }

  return null
}

File.defaultProps = {
  onLoad: null,
}

export default File
