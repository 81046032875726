// @flow

import React from 'react'
import type { Node } from 'react'
import { useTranslation } from 'react-i18next'

import Nbsp from '../NBSP'
import Icon from '../../../static/icons/loader.svg'

export const LOADER_TYPE = {
  medium: 'medium',
  big: 'big',
  small: 'small',
}

type Props = {
  className: string,
  imgStyle?: Object,
  show: boolean,
  showImg: boolean,
  style?: Object,
  text: boolean,
  type: 'small' | 'medium' | 'big',
}

const Loader = (props: Props): Node => {
  const { show, type, text, className, style, showImg, imgStyle } = props

  const { t } = useTranslation('Common')

  if (!show) {
    return null
  }

  return (
    <div className={`loader-${type} ${className}`} style={style}>
      {showImg && <Icon alt='loader' style={imgStyle} />}
      {!!text && (
        <span className='tile__loader-text'>
          <Nbsp />
          {t('Loading')}
        </span>
      )}
    </div>
  )
}

Loader.defaultProps = {
  show: true,
  showImg: true,
  type: 'small',
  text: true,
  className: '',
}

export default Loader
