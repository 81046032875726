// @flow

import React, { Component } from 'react'
import { difference, map } from 'lodash-es'
import moment from 'moment'
import { connect } from 'react-redux'

import NotificationItem from '../NotificationItem'
import * as actions from './NotificationList.actionTypes'

const DEFAULT_REMOVE_TIMEOUT = 5000

type Props = {
  dispatch: Object => void,
  indented?: boolean,
  items: Array<Object>,
  notifications: Array<Object>,
  parent: string,
  removeTimeoutTime: number,
  single?: boolean,
  style?: Object,
  timeouts: Array<Object>,
}
type State = {
  notifications: Array<Object>,
}

class NotificationList extends Component<Props, State> {
  state = {
    notifications: [],
  }

  componentDidMount() {
    const { parent } = this.props
    this.props.dispatch({ type: actions.NOTIFICATION_LIST_INIT, parent })
  }

  componentDidUpdate(prevProps) {
    const { parent, removeTimeoutTime, single } = prevProps

    const diff = difference(this.props.notifications, this.state.notifications)

    if (difference(this.props.notifications, prevProps.notifications).length) {
      this.setState({ notifications: this.props.notifications })
    }

    if (diff.length) {
      const id = this.getId()
      this.setState({ notifications: this.props.notifications })
      this.props.dispatch({
        type: single
          ? actions.NOTIFICATION_LIST_SINGLE_UPDATE
          : actions.NOTIFICATION_LIST_ADD,
        data: { ...diff[0], id },
        parent,
      })

      if (!single) {
        const removeTime = removeTimeoutTime || DEFAULT_REMOVE_TIMEOUT

        const timeout = setTimeout(() => {
          this.props.dispatch({
            type: actions.NOTIFICATION_LIST_REMOVE,
            id,
            parent,
          })
        }, removeTime)

        this.props.dispatch({
          type: actions.NOTIFICATION_LIST_TIMEOUT_SAVE,
          timeout,
        })
      }
    }
  }

  componentWillUnmount() {
    const { timeouts, single } = this.props

    !single &&
      map(timeouts, timeout => {
        clearTimeout(timeout)
      })
  }

  getId = () => moment().valueOf()

  remove = id => {
    const { parent } = this.props

    this.props.dispatch({ type: actions.NOTIFICATION_LIST_REMOVE, id, parent })

    if (this.props.onRemove) {
      this.props.onRemove()
    }
  }

  render() {
    const { parent, items, indented } = this.props

    if (!items[parent] || !items[parent].length) {
      return null
    }

    const style = {
      marginTop: indented ? '20px' : 0,
      ...this.props.style,
    }

    return (
      <div style={style}>
        {map(items[parent], (item, i) => (
          <NotificationItem key={i} data={item} remove={this.remove} />
        ))}
      </div>
    )
  }
}

NotificationList.defaultProps = {
  parent: 'all',
  onRemove: null,
  single: false,
}

const mapStateToProps = state => state.notificationList

export default connect(mapStateToProps)(NotificationList)
