import React from 'react'
import { useTranslation } from 'react-i18next'
import NewTableBodyRow from '../../../NewTable/NewTableBodyRow'
import NewTableBodyCell from '../../../NewTable/NewTableBodyCell'
import styles from './UploadingFileItem.module.scss'
import FilesIcon from '../../FilesIcon'
import SelectCustom from '../../../Select/SelectCustom'
import Loader from '../../../Loader'

const UploadingFileItem = props => {
  const { file } = props
  const { t } = useTranslation('Files')

  let options = [
    {
      value: 'onRemove',
      label: t('Remove'),
      handler: props.openRemoveModal(file.id, null),
      icon: 'bin',
    },
  ]

  return (
    <NewTableBodyRow key={file.id} id={file.id}>
      <NewTableBodyCell title={file.name} className={styles.uploadItem}>
        <Loader text={false} type='small' className={styles.loader} />
        <FilesIcon file={file} />
      </NewTableBodyCell>
      <NewTableBodyCell title={''}></NewTableBodyCell>
      <NewTableBodyCell title={''} />
      <NewTableBodyCell align='flex-end' title={''}></NewTableBodyCell>
      <NewTableBodyCell>
        <div className='table-item table-item__text' title={''}>
          <span>{}</span>
        </div>
        <div className='table-extra table-extra--settings'>
          <SelectCustom options={options} onChange={opt => opt.handler()} />
        </div>
      </NewTableBodyCell>
    </NewTableBodyRow>
  )
}

export default UploadingFileItem
