// @flow

import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'
import { clone, filter, findIndex, isArray, map, memoize } from 'lodash-es'
import { compose } from 'redux'
import { connect } from 'react-redux'

import { getUrlForAvatar } from '../../../utils/utils'
import Button from '../../Button'
import Loader from '../../Loader'
import Select from '../Select'
import Scrollbar from '../../Scrollbar'
import * as actions from './SelectChatMember.actionTypes'

const MIN_LETTERS = 2

class SelectChatMember extends Select {
  static propTypes = {
    ...Select.propTypes,
    onChange: PropTypes.func.isRequired,
    pageSize: PropTypes.number,
    valueId: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.number),
      PropTypes.arrayOf(PropTypes.string),
    ]),
    placeholderClick: PropTypes.string,
    params: PropTypes.object,
    owner: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    canRemove: PropTypes.bool,
    loggedUserId: PropTypes.number,
    snap: PropTypes.string,
    isDweller: PropTypes.bool,
  }

  static defaultProps = {
    ...Select.defaultProps,
    backspaceRemoves: false,
    clearable: false,
    openOnFocus: true,
    pageSize: 5,
    placeholder: 'StartTypingName',
    params: {},
    canRemove: false,
  }

  componentDidMount() {
    this.init()
  }

  componentWillUnmount() {
    this.props.dispatch({ type: actions.SELECT_CHAT_MEMBER_RESET })
  }

  componentDidUpdate(prevProps, prevState) {
    const { isOpen, inputValue } = this.state

    if (prevProps.snap !== this.props.snap) {
      this.props.dispatch({ type: actions.SELECT_CHAT_MEMBER_RESET })
      this.init()
    }

    if (!prevState.isOpen && isOpen) {
      this.focus()

      if (!this.props.optionsInitiated) {
        this.loadOptions()
      }

      return
    }

    if (prevState.inputValue !== inputValue) {
      const backspaced = prevState.inputValue.length > inputValue.length
      const removed = !inputValue && prevState.inputValue.length > 1

      if (inputValue && inputValue.length > MIN_LETTERS) {
        this.loadOptions()
      } else if (removed || (backspaced && inputValue.length === MIN_LETTERS)) {
        this.loadOptions(false, true)
      }
    }
  }

  init = () => {
    const { valueId, addMemberToChat, onChange, addChatMembers } = this.props

    this.props.dispatch({
      type: actions.SELECT_CHAT_MEMBER_INITIATING,
      valueId,
      addChatMembers,
      addMemberToChat,
      onChange,
    })
  }

  loadOptions = (scrolling = false, reset = false) => {
    const {
      meta: { curr_page: page, page_count: count },
      addChatMembers,
    } = this.props

    if (scrolling && page === count) {
      return
    }

    const { inputValue } = this.state

    this.props.dispatch({
      type: actions.SELECT_CHAT_MEMBER_LOADING,
      params: {
        page: scrolling ? page + 1 : 1,
        fullname:
          reset || (inputValue && inputValue.length < 3)
            ? null
            : inputValue || null,
      },
      addChatMembers,
    })
  }

  selectValue = opt => {
    const { value } = this.props
    const singleSelected = filter(value, { single: true }).length
    const selectSingle = opt.single && value && value.length
    const needReset = !!this.state.inputValue

    this.setState(
      {
        isOpen: !selectSingle && value,
        inputValue: '',
        focusedIndex: null,
      },
      () => {
        const unselect = findIndex(value, o => o.owner === opt.owner) > -1

        if (unselect) {
          this.removeValue(opt)
        } else if (selectSingle || singleSelected) {
          this.setValue([opt])
        } else {
          this.addValue(opt)
        }

        if (needReset) {
          this.loadOptions()
        }
      }
    )
  }

  setValue = value => {
    this.props.dispatch({ type: actions.SELECT_CHAT_MEMBER_SET_VALUE, value })
    this.props.onChange(value)
  }

  addValue = opt => {
    const { value } = this.props
    this.setValue(value ? value.concat(opt) : [opt])
  }

  removeValue = opt => {
    const { canRemove, value, owner } = this.props

    if (!canRemove || value.owner === owner || value.length < 2) {
      return
    }

    this.setValue(filter(value, o => o.owner !== opt.owner))
  }

  onInputBlur = e => {
    const ignore =
      '.scrollbar__thumb:active, .dropdown__search-input input:active'

    if (this.menu && this.menu.querySelector(ignore)) {
      this.focus()

      return
    }

    const { inputValue } = this.state

    if (inputValue && inputValue.length > MIN_LETTERS) {
      this.loadOptions(false, true)
    }

    this.handleInputBlur(e)
  }

  handleMenuScroll = e => {
    const { scrollHeight, offsetHeight, scrollTop } = e.target
    const { loading, menuLoader } = this.props
    const scrolled = offsetHeight + scrollTop

    if (!loading && scrollHeight / 2 <= scrolled) {
      this.loadOptions(true)
    }

    const allScrolled = scrollHeight <= scrolled

    if (!menuLoader && loading && allScrolled) {
      this.props.dispatch({ type: actions.SELECT_CHAT_MEMBER_SHOW_MENU_LOADER })
    }
  }

  renderValue = user => {
    const { canRemove, owner, value, loggedUserId, isDweller } = this.props
    let canKick =
      user.owner !== loggedUserId &&
      canRemove &&
      value.owner !== owner &&
      value.length > 1

    if (isDweller && user.group !== 'dweller') {
      canKick = false
    }

    return (
      <span
        className='bar__userbar bar__userbar-round bar__userbar--chatroom'
        key={`member-${user.id || user.owner}`}
      >
        <img
          className='bar__userbar-image'
          alt='userpic'
          src={user.avatar || getUrlForAvatar(user)}
        />
        {user.name}&nbsp;{user.second_name}
        {canKick && (
          <i
            className='messages__members-item-remove'
            onClick={() => this.removeValue(user)}
          >
            ✕
          </i>
        )}
      </span>
    )
  }

  renderInput = () => {
    const { tabIndex, disabled } = this.props

    const inputProps = {
      onFocus: this.handleInputFocus,
      tabIndex,
      disabled,
      ref: _ref => {
        this.input = _ref

        return _ref
      },
    }

    return (
      <button className='button-link__button-url' {...inputProps} type='button'>
        <Button.Text icon='plus' title={this.props.t('Common:Add')} />
      </button>
    )
  }

  renderMenu = () => {
    const {
      loading,
      value,
      menuLoader,
      pageSize,
      placeholder,
      options,
      t,
      loggedUserId,
    } = this.props
    const { inputValue } = this.state

    const filteredValue = value.filter(elem => elem.owner !== loggedUserId)
    const filteredOptions = options.filter(opt => opt.owner !== loggedUserId)

    const hasOptions = filteredOptions.length

    const filterProps = {
      type: 'text',
      value: inputValue,
      placeholder: t(placeholder),
      onBlur: this.onInputBlur,
      onFocus: this.handleInputFocus,
      onChange: this.handleInputChange,
      ref: _ref => {
        this.input = _ref

        return _ref
      },
    }

    const filterElem = (
      <div className='dropdown__search'>
        <div className='dropdown__search-input'>
          <input {...filterProps} />
        </div>
      </div>
    )

    const wrapperClass =
      'dropdown dropdown--default -dropdown--up-right dropdown--open'

    if (loading && (!hasOptions || (!!inputValue && inputValue.length > 2))) {
      return (
        <div className={wrapperClass}>
          {filterElem}
          <Loader />
        </div>
      )
    } else if (hasOptions) {
      let orderedOptions = clone(filteredOptions)

      if (inputValue.length <= MIN_LETTERS && filteredValue) {
        orderedOptions = filteredOptions.filter(o => {
          if (isArray(filteredValue)) {
            return !filteredValue.find(v => o.owner === v.owner)
          }

          return o.owner !== filteredValue.owner
        })

        if (isArray(filteredValue)) {
          orderedOptions = [...filteredValue, ...orderedOptions]
        } else {
          orderedOptions.unshift(filteredValue)
        }
      }

      const optionsElem = orderedOptions.map(opt => {
        let selected = false

        if (isArray(filteredValue)) {
          selected = findIndex(filteredValue, o => o.owner === opt.owner) > -1
        } else if (filteredValue) {
          selected = filteredValue.owner === opt.owner
        }

        const className = classnames('dropdown__list-item', {
          'dropdown__list-item--checked': selected,
        })

        return (
          <li
            className={className}
            key={`option-${opt.owner}`}
            onClick={() => this.selectValue(opt)}
          >
            <img src={opt.avatar || getUrlForAvatar(opt)} alt='userpic' />
            <span className='dropdown__list-item-text'>{opt.fullname}</span>
          </li>
        )
      })

      return (
        <div
          className={wrapperClass}
          ref={_ref => {
            this.menu = _ref

            return _ref
          }}
          onScroll={this.handleMenuScroll}
          onMouseDown={this.handleMouseDownOnMenu}
        >
          {filterElem}
          <div>
            <Scrollbar
              items={filteredOptions}
              listElements={optionsElem}
              listClassName='dropdown__list dropdown__list--default dropdown__list--checklist'
              pageSize={pageSize}
            />
            {menuLoader && <Loader />}
          </div>
        </div>
      )
    }

    return (
      <div className={wrapperClass}>
        {filterElem}
        <div className='Select-noresults'>{t('NoResults')}</div>
      </div>
    )
  }

  render() {
    const {
      valueInitiated,
      value,
      loading,
      meta: { count },
      options,
      loggedUserId,
    } = this.props

    if (!valueInitiated) {
      return (
        <div className='messages__members-list' style={{ maxHeight: '35px' }}>
          <Loader />
        </div>
      )
    }

    const areOptionsHaveCurrentUser = memoize(options =>
      options.find(v => v.owner === loggedUserId)
    )
    const allSelected = areOptionsHaveCurrentUser(options)
      ? value.length === count
      : value.length === count + 1
    const { isOpen, inputValue } = this.state
    const ignoreMenu = !loading && !inputValue && !!value && allSelected

    return (
      <div
        className='messages__members-list select-user select-user-multi'
        style={{ position: 'static' }}
      >
        {map(value, this.renderValue)}
        {!ignoreMenu && (
          <div>
            <div
              onKeyDown={this.handleKeyDown}
              onMouseDown={this.handleMouseDown}
              onTouchEnd={this.handleTouchEnd}
              onTouchStart={this.handleTouchStart}
              onTouchMove={this.handleTouchMove}
            >
              {this.renderInput()}
            </div>
            {isOpen && this.renderMenu()}
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state.selectChatMember,
  loggedUserId: state.login.user.owner,
  addMemberToChat: state.selectChatMember.addMemberToChat,
})

export default compose(
  withTranslation('Select'),
  connect(mapStateToProps)
)(SelectChatMember)
