import { put, call, fork, takeEvery } from 'redux-saga/effects'
import api from '../../core/api'
import * as actions from './Like.actionTypes'
import { serverError } from '../Layout/Layout.actions'

function * watchUpdate () {
  yield takeEvery(actions.LIKE_UPDATE, update)
}

function * update ({ model, uuid, active }) {
  try {
    const action = active ? api.like.removeLike : api.like.upLike
    yield call(action, { payload: { model, model_pk: uuid } })
    yield put({
      type: actions.LIKE_WAS_UPDATED,
      uuid,
      active: !active
    })
  } catch (error) {
    yield put(serverError(error))
    yield put({ type: actions.LIKE_ERROR, error })
  }
}

export default function * () {
  yield fork(watchUpdate)
}
