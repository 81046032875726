// @flow

import {
  LOAD_ERROR,
  LOAD_INIT,
  LOAD_SUCCESS,
  RELOAD_INIT,
} from './useSingleLoad.constants'

export default function dataLoadReducer(state, action) {
  switch (action.type) {
    case LOAD_INIT:
      return { ...state, isLoading: true }
    case RELOAD_INIT:
      return { ...state, isReloading: true }
    case LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isInitiated: true,
        isReloading: false,
        data: action.payload,
      }
    case LOAD_ERROR:
      return {
        ...state,
        isLoading: false,
        isReloading: false,
        isInitiated: true,
        isError: true,
      }
    default:
      throw new Error()
  }
}
