// @flow

import * as actions from './CompanyRegistration.actionTypes'

const initialState = {
  initiated: true,
  working: false,
  error: '',
  confirm: false,
  result: null,
}

type State = {
  confirm: boolean,
  error: string,
  result: ?Object,
  working: boolean,
}

type Action = { error: string, result: ?Object, type: string }

export default (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case actions.COMPANY_REGISTRATION_INVITE:
      return {
        ...state,
        working: true,
      }
    case actions.COMPANY_REGISTRATION_ERROR:
      return {
        ...state,
        working: false,
        error: action.error,
      }
    case actions.COMPANY_REGISTRATION_CONFIRMED:
      return {
        ...state,
        confirm: true,
        working: false,
        result: action.result,
      }
    default:
      return state
  }
}
