//@flow

import { get } from 'lodash-es'
import { FORMIK_EMPTY_VALUE } from '../../../constants'

export const DEPOSIT_TYPE = 'deposit_type'
export const FUNDINGS = 'fundings'
export const REMEDY = 'remedy'
export const STOP_OVERDUE_NOTICE = 'stop_overdue_notice'
export const TERMINATION_DENIED = 'termination_denied'
export const TERMINATION_TIME = 'termination_time'
export const TERMINATION_TIMEFRAME = 'termination_timeframe'
export const GUARANTEED_VALUE = 'guaranteed_value'

export const MAX_FIELD_LENGTH = 256

export const DEFAULT_VALUES: Object = {
  [DEPOSIT_TYPE]: FORMIK_EMPTY_VALUE,
  [FUNDINGS]: FORMIK_EMPTY_VALUE,
  [REMEDY]: FORMIK_EMPTY_VALUE,
  [STOP_OVERDUE_NOTICE]: FORMIK_EMPTY_VALUE,
  [TERMINATION_DENIED]: FORMIK_EMPTY_VALUE,
  [TERMINATION_TIME]: FORMIK_EMPTY_VALUE,
  [TERMINATION_TIMEFRAME]: FORMIK_EMPTY_VALUE,
  [GUARANTEED_VALUE]: FORMIK_EMPTY_VALUE,
}

export const setFormikError = (error, setFieldError) => {
  const data = get(error, ['message', 'response', 'data', 'errors'])
  for (const field of data) {
    setFieldError(field.loc[1], 'is not valid')
  }
}

export const normalizeValues = values => {
  let keys = Object.keys(values)
  let current = {}
  keys.forEach(item => {
    current[item] = values[item].trim()
  })

  return current
}
