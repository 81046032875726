// @flow

import React, { memo } from 'react'

type Props = {
  checked: boolean,
  onChange: number => void,
  request: number,
}

const Checkbox = memo<Props>(props => {
  const { checked, request } = props
  const id = `check-request-${request}`

  return (
    <label
      htmlFor={id}
      className='checkbox checkbox--medium checkbox--default checkbox--notext table-item__checkbox'
      onClick={e => e.stopPropagation()}
    >
      <input
        type='checkbox'
        value={request}
        id={id}
        checked={Boolean(checked)}
        onChange={props.onChange}
      />
      <span />
    </label>
  )
})

export default Checkbox
