import ReactSelect from 'react-select'

class Select extends ReactSelect {
  onInputBlur = (e) => {
    if (this.menu && this.menu.querySelector('.scrollbar__thumb:active')) {
      this.focus()
      return
    }
    this.handleInputBlur(e)
  }
}

export default Select
