// @flow

import React from 'react'
import m from 'moment'

import { date as defaultDateFormat } from '../../config'

type Props = {
  date?: string,
  dateFormat?: string,
}

const Date = ({ date, dateFormat = defaultDateFormat }: Props) => (
  <span>{date ? m(date).format(dateFormat) : null}</span>
)

export default Date
