// @flow

import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'

import Button from '../../../components/Button'
import Checkbox from '../../../components/Checkbox'
import { toggleAllGroups, showModal } from '../Faq.actions'
import * as selectors from '../Faq.selectors'
import { CREATE_POPUP, REMOVE_POPUP, DONT_REMOVE_GROUP } from '../Faq.constants'

type Props = {
  isAdmin: boolean,
  isAllSelected: boolean,
  isDisableDeleteGroups: boolean,
  isGroupsEmpty: boolean,
  isSelectedEmpty: boolean,
  permissions: Object,
  showModal: () => void,
  t: string => string,
  toggleAllGroups: () => void,
}

class ToolBox extends PureComponent<Props> {
  showModal = type => () => {
    this.props.showModal(type)
  }

  render() {
    const {
      t,
      isAllSelected,
      isSelectedEmpty,
      isGroupsEmpty,
      permissions,
      isDisableDeleteGroups,
    } = this.props

    if (!isGroupsEmpty || !permissions.can_create || !permissions.can_delete) {
      return null
    }

    return (
      <div className='toolbox'>
        <div className='toolbox__cell'>
          <Checkbox
            outer
            checked={isAllSelected}
            onChange={this.props.toggleAllGroups}
          />
        </div>
        {!isSelectedEmpty && (
          <div className='toolbox__cell'>
            <button
              type='button'
              className='button button--success button--large'
              onClick={this.showModal(CREATE_POPUP)}
            >
              {t('add_group')}
            </button>
          </div>
        )}
        {isSelectedEmpty && (
          <div className='toolbox__cell'>
            <Button.Remove
              onClick={this.showModal(
                isDisableDeleteGroups ? DONT_REMOVE_GROUP : REMOVE_POPUP
              )}
            />
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  isAllSelected: selectors.isAllSelected(state),
  selected: selectors.getSelected(state),
  isSelectedEmpty: selectors.isSelectedEmpty(state),
  isGroupsEmpty: selectors.isGroupsEmpty(state),
  permissions: selectors.getPermissions(state),
  isDisableDeleteGroups: selectors.isDisableDeleteGroups(state),
})
const mapDispatchToProps = {
  toggleAllGroups,
  showModal,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('FAQ')
)(ToolBox)
