// @flow

import { get } from 'lodash-es'

export const getRequestParticipants = state => {
  const ownerObj = get(state, ['requestView', 'data', 'owner_obj'])
  const assigneeObjs = get(state, ['requestView', 'data', 'assignee_objs'])

  return ownerObj && assigneeObjs ? [ownerObj, ...assigneeObjs] : []
}
