import React, { Suspense } from 'react'

import Loader from '../Loader'
import style from './Phone.module.scss'

const Phone = React.lazy(() => import('./Phone'))

export default ({ ...props }) => (
  <Suspense fallback={<Loader className={style.suspense} />}>
    <Phone {...props} />
  </Suspense>
)
