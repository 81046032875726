// @flow

import * as actions from './AttachToRequestPopup.actionTypes'

const initialState = {
  working: false,
  error: null,
  request: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.CLEAN_ATTACHED:
    case actions.INIT:
      return {
        ...initialState,
      }
    case actions.ERROR:
      return {
        ...state,
        error: action.error,
        working: false,
      }
    case actions.ATTACH:
    case actions.MOVE:
      return {
        ...state,
        working: true,
      }
    case actions.ATTACHED:
    case actions.MOVED:
      return {
        ...state,
        request: action.request,
        working: false,
      }
    default:
      return state
  }
}
