// @flow

import React from 'react'
import ClickOutHandler from 'react-onclickout'
import moment from 'moment'

import ReactCalendar from '../../filters/CalendarFilter/ReactCalendar'
import { isSameDay } from '../Post.utils'
import TextInput from '../TextInput'
import Select from '../../Select/Select'
import Button from '../../Button'
import NewSelectSimple from '../../NewSelectSimple/NewSelectSimple'

import styles from './PostTimeInterval.module.scss'

const calendarStyles = {
  position: 'absolute',
  left: 0,
  zIndex: 1000,
  background: '#fff',
  borderRadius: '0.5rem',
  border: '0.1rem solid #d0d0d0',
  boxShadow: '0 0.35rem 0.8rem rgba(0, 0, 0, 0.11)',
  margin: 0,
  overflow: 'hidden',
}

const PostTimeInterval = props => {
  const {
    handleOpenDateFrom,
    handleOpenDateTo,
    onClickOut,
    updateDateFrom,
    updateDateTo,
    timeFrom,
    timeTo,
    dateFrom,
    isDateFromOpen,
    isDateToOpen,
    dateTo,
    setTimeFrom,
    setTimeTo,
    timeOptions,
  } = props

  const getTimeToOptions = () => {
    if (isSameDay(dateFrom, dateTo)) {
      return timeOptions.filter(opt => opt.value > timeFrom.value)
    }

    return timeOptions
  }

  return (
    <div className={styles.date}>
      <div className='input input--default input--active input--large date-input'>
        <TextInput
          placeholder='Input Date'
          value={dateFrom ? moment(dateFrom).format('DD.MM.YYYY') : '-'}
          onClick={handleOpenDateFrom}
        />
        <div
          className='date-calendar'
          style={{ width: '16.5rem', zIndex: 1000 }}
        >
          {isDateFromOpen && (
            <ClickOutHandler onClickOut={onClickOut}>
              <ReactCalendar
                single
                minYear={1900}
                maxYear={moment().get('year') + 5}
                Select={Select}
                value={dateFrom || moment()}
                style={calendarStyles}
                onValueChange={updateDateFrom}
                onChange={date => {
                  onClickOut()
                  updateDateFrom(date[0])
                }}
              />
            </ClickOutHandler>
          )}
        </div>
      </div>
      <div className={styles.select}>
        <NewSelectSimple
          disabled={!dateFrom}
          name='time'
          value={dateFrom ? timeFrom : { value: null, label: '-' }}
          options={timeOptions}
          openClass={styles.open}
          onChange={setTimeFrom}
        />
      </div>
      <div className={styles.mdash}>&mdash;</div>
      <div className='input input--default input--active input--large date-input'>
        <TextInput
          placeholder='Input Date'
          disabled={!dateFrom}
          value={dateTo ? moment(dateTo).format('DD.MM.YYYY') : '-'}
          onClick={handleOpenDateTo}
        />
        <div
          className='date-calendar'
          style={{ width: '16.5rem', zIndex: 1000 }}
        >
          {isDateToOpen && (
            <ClickOutHandler onClickOut={onClickOut}>
              <ReactCalendar
                single
                minYear={1900}
                maxYear={moment().get('year') + 3}
                Select={Select}
                value={dateTo || moment()}
                disabledDate={date => date < dateFrom}
                style={calendarStyles}
                onValueChange={updateDateTo}
                onChange={date => {
                  onClickOut()
                  updateDateTo(date[0])
                }}
              />
            </ClickOutHandler>
          )}
        </div>
      </div>
      <div className={styles.select}>
        <NewSelectSimple
          disabled={!dateTo}
          name='time'
          value={dateTo ? timeTo : { value: null, label: '-' }}
          options={getTimeToOptions()}
          openClass={styles.open}
          onChange={setTimeTo}
        />
      </div>
      {dateFrom ? (
        <Button.Delete className={styles.clear} onClick={props.onClear} />
      ) : null}
    </div>
  )
}

export default PostTimeInterval
