// @flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import type { Node } from 'react'

import styles from './MessageBlockquote.module.scss'

import MoreIcon from '../../../../../../static/icons/more_h.svg'

type Props = { children: Node }

const MessageBlockquote = (props: Props): Node => {
  const { children } = props

  const { t } = useTranslation('Mail')

  const [isShown, setShown] = useState(false)

  const handleClick = () => setShown(!isShown)

  const altText = isShown ? 'HideQuote' : 'ShowQuote'

  const quoteClass = classnames(styles.quote, { [styles.shown]: isShown })

  return (
    <div className={quoteClass}>
      <div title={t(altText)}>
        <MoreIcon onClick={handleClick} />
      </div>
      {isShown && children}
    </div>
  )
}

export default MessageBlockquote
