/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type Offer_offer$ref: FragmentReference;
declare export opaque type Offer_offer$fragmentType: Offer_offer$ref;
export type Offer_offer = {|
  +id: string,
  +name: string,
  +finalPrice: ?string,
  +priceType: ?{|
    +name: ?string
  |},
  +provider: {|
    +name: string,
    +id: string,
    +image: ?{|
      +origin: ?string
    |},
  |},
  +gallery: ?$ReadOnlyArray<?{|
    +id: ?string,
    +origin: ?string,
    +default: ?boolean,
    +thumbnails: ?$ReadOnlyArray<?{|
      +url: ?string,
      +size: ?$ReadOnlyArray<?number>,
    |}>,
  |}>,
  +orderCount: ?number,
  +$refType: Offer_offer$ref,
|};
export type Offer_offer$data = Offer_offer;
export type Offer_offer$key = {
  +$data?: Offer_offer$data,
  +$fragmentRefs: Offer_offer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "origin",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "Offer_offer",
  "type": "OfferNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "finalPrice",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "priceType",
      "storageKey": null,
      "args": null,
      "concreteType": "PriceTypeNode",
      "plural": false,
      "selections": [
        (v1/*: any*/)
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "provider",
      "storageKey": null,
      "args": null,
      "concreteType": "ProviderNode",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        (v0/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "image",
          "storageKey": null,
          "args": null,
          "concreteType": "ProviderImageNode",
          "plural": false,
          "selections": [
            (v2/*: any*/)
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "gallery",
      "storageKey": null,
      "args": null,
      "concreteType": "GalleryImageNode",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "default",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "thumbnails",
          "storageKey": null,
          "args": null,
          "concreteType": "Thumbnail",
          "plural": true,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "url",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "size",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "orderCount",
      "args": null,
      "storageKey": null
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '833bae7812b88e4c0cc0ba1d35f2a882';
module.exports = node;
