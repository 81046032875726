// @flow

import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import Stub from '../Stub'
import { loadWeather } from '../PublicBuildingPage.actions'
import { getWeather, isError } from '../PublicBuildingPage.selectors'

const INTERVAL = 1000 * 60 // one minute
const STUB_INTERVAL = 1000 * 60 * 30 // 30 minutes

type Props = {
  isError: boolean,
  weather: {
    main: {
      temp: number,
    },
    weather: any,
  },
}
type State = {
  isVisibleStub: boolean,
}

class WeatherCard extends PureComponent<Props, State> {
  state = {
    isVisibleStub: false,
  }

  componentDidMount() {
    this.interval = setInterval(this.props.loadWeather, INTERVAL)
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.isError !== prevProps.isError &&
      'weather' in this.props.isError &&
      prevProps.weather.main.temp === null
    ) {
      this.setState({ isVisibleStub: true })

      return
    }

    if (
      this.props.isError !== prevProps.isError &&
      'weather' in this.props.isError
    ) {
      this.stubTimeout = setTimeout(() => {
        this.setState({ isVisibleStub: true })
      }, STUB_INTERVAL)
    }

    if (
      this.props.isError !== prevProps.isError &&
      !('weather' in this.props.isError)
    ) {
      clearTimeout(this.stubTimeout)
      this.setState({ isVisibleStub: false })
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  render() {
    const { weather } = this.props
    const { isVisibleStub } = this.state
    const { icon } = weather.weather[0]
    const backgroundImage = icon && `url(/static/images/weather/${icon}.jpg)`
    const temp = Math.round(weather.main.temp)

    if (isVisibleStub) {
      return <Stub />
    }

    return (
      <div
        className='public-building-page__weather'
        style={{ backgroundImage }}
      >
        <div className='public-building-page__weather-temp'>{temp} °C</div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  weather: getWeather(state),
  isError: isError(state),
})

export default connect(mapStateToProps, { loadWeather })(WeatherCard)
