// @flow

import { postApi, deleteApi, patchApi } from './api.core'

const BASE_PATHNAME = 'api/like/'

export const upLike = ({ payload }) => postApi(BASE_PATHNAME, payload)
export const updateLike = ({ likeId, payload }) =>
  patchApi(`${BASE_PATHNAME}${likeId}/`, payload)
export const removeLike = ({ payload }) => deleteApi(BASE_PATHNAME, payload)
