// @flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { get, omit, partial } from 'lodash-es'
import { useDispatch } from 'react-redux'
import { replace } from 'connected-react-router'
import type { Node } from 'react'

import Icon from '../../Icon'
import SectionHeader from '../../SectionHeader'
import InputPassword from '../../InputPassword'
import { SimpleBlock } from '../../Block'
import {
  USE_TLS_VALUE,
  NO_ENCRYPT_VALUE,
  USE_SSL_VALUE,
  POP_VALUE,
  IMAP_VALUE,
  ALWAYS_VALUE,
  MIN_PORT_NUMBER,
  MAX_PORT_NUMBER,
} from '../EmailIntegration.constants'
import {
  createIcomingMail,
  setIncomingMail,
  createCorporateIcomingMail,
  setCorporateIncomingMail,
} from '../../../core/api/api.mail'
import Button from '../../Button'
import Field from '../Field'
import ConfirmationPopup from '../../modals/ConfirmationPopup'
import Modal from '../../Modal'
import {
  DEFAULT_VALUES,
  EMAIL_FROM,
  HOST,
  KEYS,
  MARK_AS_READ,
  PASSWORD,
  PORT,
  PROTOCOL,
  USERNAME,
  USE_SSL,
  USE_TLS,
} from './IncomingEmail.constants'
import { useConnection } from '../useConnection'
import { useError } from '../useError'
import NewSelectSimple from '../../NewSelectSimple'
import { getInitialValues, getEncryption } from '../EmailIntegration.utils'

import styles from '../EmailIntegration.module.scss'

type Props = {
  accountId?: number,
  initialParams?: ?Object,
  isPrivate?: boolean,
  setLoading?: boolean => void,
}

const IncomingEmail = (props: Props): Node => {
  const { accountId, initialParams, isPrivate } = props

  const id = get(initialParams, 'id')
  const status = get(initialParams, ['status', 'status'])
  const message = get(initialParams, ['status', 'message'])

  const [isWorking, setWorking] = useState(false)
  const [setError] = useError()

  const dispatch = useDispatch()
  const { t } = useTranslation('SettingsExternalEmail')
  const [
    isChecking,
    isModalOpen,
    connectionTitle,
    connectionText,
    handleTest,
    handleCancelTest,
    handleCloseModal,
  ] = useConnection(id)

  const initialValues = getInitialValues(initialParams, DEFAULT_VALUES, KEYS)

  const formik = useFormik({
    validateOnChange: false,
    enableReinitialize: true,
    validateOnBlur: true,
    initialValues: {
      ...initialValues,
      incomingEncryptOption: getEncryption(initialValues),
    },
    validationSchema: Yup.object({
      [EMAIL_FROM]: Yup.string()
        .email(t('Common:EnterValidEmailErrorText'))
        .required(t('Common:FieldIsRequired')),
      [USERNAME]: Yup.string().required(t('Common:FieldIsRequired')),
      [PASSWORD]: Yup.string().required(t('Common:FieldIsRequired')),
      [HOST]: Yup.string().required(t('Common:FieldIsRequired')),
      [PORT]: Yup.number()
        .integer(t('Common:EnterValidPort'))
        .positive(t('Common:EnterValidPort'))
        .max(
          MAX_PORT_NUMBER,
          t('EnterPortInRange', { from: MIN_PORT_NUMBER, to: MAX_PORT_NUMBER })
        )
        .min(
          MIN_PORT_NUMBER,
          t('EnterPortInRange', { from: MIN_PORT_NUMBER, to: MAX_PORT_NUMBER })
        )
        .typeError(t('Common:EnterValidPort'))
        .required(t('Common:FieldIsRequired')),
      incomingEncryptOption: Yup.string(),
      [PROTOCOL]: Yup.string(),
      [USE_SSL]: Yup.boolean(),
      [USE_TLS]: Yup.boolean(),
    }),
    onSubmit: values => {
      setWorking(true)

      let params = omit(values, 'incomingEncryptOption')

      if (params[PROTOCOL] === POP_VALUE) {
        params = omit(params, MARK_AS_READ)
      }

      if (values[PASSWORD] === formik.initialValues[PASSWORD]) {
        params = omit(params, PASSWORD)
      }

      formik.setFieldValue(PASSWORD, '********')

      params.messages_store_time = ALWAYS_VALUE

      let api = partial(
        isPrivate ? setIncomingMail : setCorporateIncomingMail,
        id
      )

      if (!id) {
        api = isPrivate ? createIcomingMail : createCorporateIcomingMail

        if (accountId) {
          params.account = accountId
        }
      }

      api(params)
        .then(data => {
          if (accountId && props.setLoading) {
            props.setLoading(true)
          } else {
            const prefix = isPrivate ? 'private' : 'corporate'

            dispatch(replace(`/settings/email/${prefix}/${data.account}`))
          }
        })
        .catch(error => setError(error))
        .finally(() => setWorking(false))
    },
  })

  const encryptOptions = [
    { value: NO_ENCRYPT_VALUE, label: t('noEncrypt') },
    { value: USE_TLS_VALUE, label: t('useTLS') },
    { value: USE_SSL_VALUE, label: t('useSSL') },
  ]

  const incomingProtocolOptions = [
    { value: POP_VALUE, label: t('usePop') },
    { value: IMAP_VALUE, label: t('useImap') },
  ]

  const markAsReadOptions = [
    { value: true, label: t('isMarkAsRead') },
    { value: false, label: t('isNotMarkAsRead') },
  ]

  const selectValue = encryptOptions.find(
    option => option.value === formik.values.incomingEncryptOption
  )

  const markAsReadOption = markAsReadOptions.find(
    opt => opt.value === formik.values[MARK_AS_READ]
  )

  const selectedProtocolOption = incomingProtocolOptions.find(
    option => option.value === formik.values[PROTOCOL]
  )

  const handleSelectProtocol = option => {
    const { value } = option

    handleCancelTest()

    formik.setFieldValue(PROTOCOL, value)

    if (value === POP_VALUE) {
      formik.setFieldValue(MARK_AS_READ, false)
    }
  }

  const handleSelectMark = option => {
    const { value } = option

    handleCancelTest()

    formik.setFieldValue(MARK_AS_READ, value)
  }

  const handleSelect = option => {
    const { value } = option

    handleCancelTest()

    formik.setFieldValue('incomingEncryptOption', value)
    formik.setFieldValue(USE_SSL, value === USE_SSL_VALUE)
    formik.setFieldValue(USE_TLS, value === USE_TLS_VALUE)
  }

  const handleChangeForm = () => {
    handleCancelTest()
  }

  return (
    <SimpleBlock working={isWorking}>
      <SectionHeader header='true' text={t('ExternalIncomingSectionTitle')} />
      <form
        name='incoming'
        onSubmit={formik.handleSubmit}
        onChange={handleChangeForm}
      >
        <div className='settings-personal__form'>
          <Field
            label='incomingEmailFrom'
            name={EMAIL_FROM}
            error={formik.errors[EMAIL_FROM]}
            value={formik.values[EMAIL_FROM]}
            handleChange={formik.handleChange}
          />
          <Field
            label='incomingUsername'
            name={USERNAME}
            error={formik.errors[USERNAME]}
            value={formik.values[USERNAME]}
            handleChange={formik.handleChange}
          />
          <Field
            label='incomingPassword'
            error={formik.errors[PASSWORD]}
            value={formik.values[PASSWORD]}
            handleChange={formik.handleChange}
          >
            <InputPassword
              value={formik.values[PASSWORD]}
              name={PASSWORD}
              id={PASSWORD}
              onChange={formik.handleChange}
            />
          </Field>
          <Field error={formik.errors[PROTOCOL]} label='incomingProtocol'>
            <NewSelectSimple
              options={incomingProtocolOptions}
              value={selectedProtocolOption}
              name={PROTOCOL}
              clearable={false}
              onChange={handleSelectProtocol}
            />
          </Field>
          <Field
            label='incomingHost'
            name={HOST}
            error={formik.errors[HOST]}
            value={formik.values[HOST]}
            handleChange={formik.handleChange}
          />
          <Field
            label='incomingPort'
            name={PORT}
            error={formik.errors[PORT]}
            value={formik.values[PORT]}
            handleChange={formik.handleChange}
            type='number'
          />
          <Field
            error={formik.errors.incomingEncryptOption}
            label='incomingEncrypt'
          >
            <NewSelectSimple
              options={encryptOptions}
              value={selectValue}
              name='incomingEncrypt'
              clearable={false}
              onChange={handleSelect}
            />
          </Field>
          <Field error={formik.errors[MARK_AS_READ]} label='markAsRead'>
            <NewSelectSimple
              options={markAsReadOptions}
              value={markAsReadOption}
              name={MARK_AS_READ}
              clearable={false}
              disabled={formik.values[PROTOCOL] === POP_VALUE}
              onChange={handleSelectMark}
            />
          </Field>
        </div>
        <div className='settings-personal__submit'>
          {formik.dirty || isWorking || !id ? (
            <>
              <Button.Save
                type='submit'
                working={isWorking}
                disabled={!formik.dirty}
              >
                {t('Common:Save')}
              </Button.Save>
              <Button.Cancel
                disabled={!formik.dirty}
                onClick={formik.handleReset}
              >
                {t('Common:Cancel')}
              </Button.Cancel>
            </>
          ) : (
            <Button.Save
              disabled={isChecking}
              working={isChecking}
              onClick={handleTest}
            >
              {t('TestConnection')}
            </Button.Save>
          )}
          {status === 'fail' && (
            <div className={styles.error}>
              <Icon id='repeat' />
              <span>{message}</span>
            </div>
          )}
        </div>
      </form>
      {isModalOpen && (
        <Modal isOpen>
          <ConfirmationPopup
            showCancel={false}
            confirm={t('CloseModalButton')}
            title={connectionTitle}
            text={connectionText}
            onClose={handleCloseModal}
            onOk={handleCloseModal}
          />
        </Modal>
      )}
    </SimpleBlock>
  )
}

export default IncomingEmail
