// @flow

import React, { useEffect } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'
import { useLoad, usePathname } from '../../hooks'
import { getMailIntegrationList } from '../../core/api/api.mail'
import EmptyList from '../EmptyList'
import BottomPagination from '../Pagination/BottomPagination'
import Loader from '../Loader'
import ToolboxWrapper from './ToolboxWrapper'
import EmailIntegrationTable from './EmailIntegrationTable'
import Nbsp from '../NBSP'
import Modal from '../Modal'
import EmailIntegrationModal from './EmailIntegrationModal'
import addRedirectToFirstPage from '../../hocs/addRedirectToFirstPage'
import { useHistory, useLocation } from 'react-router-dom'

const EmailIntegrationNew = (): Node => {
  const { t } = useTranslation('EmailIntegrationNylas')
  const isLocked = usePathname()
  const [data, isLoading, isError, load] = useLoad(
    getMailIntegrationList,
    {
      archived: false,
    },
    isLocked
  )
  const [count, setCount] = useState(null)
  const [addModal, setAddModal] = useState(false)
  const [errorModal, setErrorModal] = useState(null)
  const [removeModal, setRemoveModal] = useState(false)

  const location = useLocation()
  const history = useHistory()

  const queryParams = new URLSearchParams(location.search)

  useEffect(() => {
    if (queryParams.has('error_message')) {
      setErrorModal({ message: queryParams.get('error_message') })
    }
  }, [])

  useEffect(() => {
    if (data) {
      setCount(data.meta.count)
    }

    return function cleanup() {
      setCount(null)
    }
  }, [data])

  if (isError) {
    return null
  }

  const hideModal = () => setRemoveModal(null)

  const add = isPrivate => {
    setAddModal({ isPrivate })
  }

  const actionOptions = () => {
    return [
      {
        value: 'private',
        label: t('Private'),
        handler: () => add(true),
        icon: 'privateAcc',
      },
      {
        value: 'corporate',
        label: t('Corporate'),
        handler: () => add(false),
        icon: 'corporateAcc',
      },
    ]
  }

  const handleCloseError = () => {
    setErrorModal(null)
    queryParams.delete('error_message')
    history.replace({
      search: queryParams.toString(),
    })
  }

  return (
    <div className='content__col' style={{ paddingTop: '20px' }}>
      <section className='bar bar--default'>
        {data?.results ? (
          <>
            <div className='bar__title-indented bar__row'>
              <div className='bar__title-subtitle'>
                <span className='bar__title-text'>
                  {t('EmailIntegrationTitle')}
                  <span className='unit__title-num'>
                    <Nbsp />
                    {!!count && count}
                  </span>
                </span>
              </div>
            </div>
            <ToolboxWrapper
              loading={isLoading}
              actionOptions={actionOptions}
              data={data}
              items={data.results.objects}
              addPrivate={() => add(true)}
            />
            <EmailIntegrationTable
              emails={data.results.objects}
              loading={isLoading}
              setRemoveModal={setRemoveModal}
              hideModal={hideModal}
              load={load}
            />
            {!data.results.objects.length && !isLoading && (
              <EmptyList
                embedded
                canAdd
                icon='email'
                title={t('EmailAccountNotFound')}
              />
            )}
            <BottomPagination meta={data.meta} classes='paginator-2' />
          </>
        ) : (
          <Loader type='big' text={false} />
        )}
        {addModal && (
          <EmailIntegrationModal
            isPrivate={addModal.isPrivate}
            handleCancel={() => setAddModal(null)}
          />
        )}
        {errorModal && (
          <EmailIntegrationModal
            error={errorModal.message}
            handleCancel={() => handleCloseError()}
          />
        )}
        {!!removeModal && (
          <Modal isOpen={!!removeModal} onRequestClose={hideModal}>
            {removeModal}
          </Modal>
        )}
      </section>
    </div>
  )
}

export default addRedirectToFirstPage(EmailIntegrationNew)
