// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import NewTable from '../../NewTable'

import NewTableBody from '../../NewTable/NewTableBody'
import PaymentTypeTableRow from './PaymentTypeTableRow'

type Props = { onEdit: Function }

const PaymentTypeTable = (props: Props): Node => {
  const { t } = useTranslation('BudgetPlan')

  const options = [
    {
      value: 'editPaymentType',
      label: t('EditInvoicePaymentType'),
      onClick: props.onEdit,
      icon: 'pencil',
    },
  ]

  return (
    <NewTable>
      <NewTableBody>
        <PaymentTypeTableRow options={options} onEdit={props.onEdit} />
      </NewTableBody>
    </NewTable>
  )
}

export default PaymentTypeTable
