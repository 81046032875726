// @flow

export const ADD_REQUEST_CATEGORY_POPUP_INITIATE =
  'ADD_REQUEST_CATEGORY_POPUP_INITIATE'
export const ADD_REQUEST_CATEGORY_POPUP_WAS_INITIATED =
  'ADD_REQUEST_CATEGORY_POPUP_WAS_INITIATED'
export const ADD_REQUEST_CATEGORY_POPUP_SAVE = 'ADD_REQUEST_CATEGORY_POPUP_SAVE'
export const ADD_REQUEST_CATEGORY_POPUP_UPDATE =
  'ADD_REQUEST_CATEGORY_POPUP_UPDATE'
export const ADD_REQUEST_CATEGORY_POPUP_WAS_UPDATED =
  'ADD_REQUEST_CATEGORY_POPUP_WAS_UPDATED'
export const ADD_REQUEST_CATEGORY_POPUP_WAS_SAVED =
  'ADD_REQUEST_CATEGORY_POPUP_WAS_SAVED'
export const ADD_REQUEST_CATEGORY_POPUP_ERROR =
  'ADD_REQUEST_CATEGORY_POPUP_ERROR'
export const ADD_REQUEST_CATEGORY_POPUP_RESET =
  'ADD_REQUEST_CATEGORY_POPUP_RESET'
