// @flow

import React from 'react'
import { useState } from 'react'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import Icon from '../../../components/Icon/Icon'

import styles from './ThreadsListItemErrors.module.scss'

type Props = {
  className?: string,
  errors?: Array<string>,
  hasError: boolean,
  style?: Object,
}

const ThreadsListItemErrors = (props: Props): Node => {
  const { hasError, style, errors, className } = props

  const [isHover, setHover] = useState(false)

  const handleMouseEnter = () => setHover(true)
  const handleMouseLeave = () => setHover(false)

  const { t } = useTranslation('Mail')

  if (!hasError) {
    return null
  }

  const errorClass = classnames(styles.error, className)

  const divProps = Object.assign(
    {},
    errors && errors.length
      ? { onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave }
      : undefined
  )

  return (
    <div className={errorClass} style={style} {...divProps}>
      <Icon id='error' className={styles.icon} />
      <span>{t('MailThreadErrorsLabel')}</span>
      {isHover && (
        <div className={classnames(styles.tooltip, 'error-tooltip')}>
          <div />
          {errors &&
            errors.map((error, index) => (
              <span key={index}>
                <b>{t('HintEmailErrorTitle')}: </b>
                {error}
              </span>
            ))}
        </div>
      )}
    </div>
  )
}

export default ThreadsListItemErrors
