// @flow

import React from 'react'
import { withTranslation } from 'react-i18next'

import { ButtonTypes, NewButtonTypes } from './constants'

import ArchiveIcon from '../../../static/icons/bin.svg'
import ActivityIcon from '../../../static/icons/activity.svg'
import EmailIcon from '../../../static/icons/email.svg'
import FilterIcon from '../../../static/icons/filter.svg'
import ImageIcon from '../../../static/icons/picture.svg'
import PhaseIcon from '../../../static/icons/constructor.svg'
import PostIcon from '../../../static/icons/post.svg'
import RequestIcon from '../../../static/icons/request.svg'
import TextIcon from '../../../static/icons/note.svg'
import UserIcon from '../../../static/icons/user.svg'

const DISABLED_COLOR = '#aaaaaa'

type Props = {
  automate?: boolean,
  disabled?: boolean,
  onClick?: () => void,
  t: string => string,
  type: string,
}

const Colors = {
  [ButtonTypes.text]: '#a6efc4',
  [ButtonTypes.image]: '#ea9b6e',
  [ButtonTypes.variable]: '#99dfea',
  [ButtonTypes.filter]: '#e6847a',
  [ButtonTypes.email]: '#ce97e3',
  [ButtonTypes.request]: '#f4afdc',
}

const NewColors = {
  [NewButtonTypes.phase]: '#111111',
  [NewButtonTypes.activity]: '#ff7f27',
  [NewButtonTypes.post]: '#b7eff8',
  [NewButtonTypes.archive]: '#f45e5f',
  [NewButtonTypes.text]: '#b6eff8',
  [NewButtonTypes.email]: '#ce97e3',
  [NewButtonTypes.request]: '#f4afdc',
}

const Icons = {
  [ButtonTypes.text]: TextIcon,
  [ButtonTypes.image]: ImageIcon,
  [ButtonTypes.variable]: UserIcon,
  [ButtonTypes.filter]: FilterIcon,
  [ButtonTypes.email]: EmailIcon,
  [ButtonTypes.request]: RequestIcon,
  [NewButtonTypes.phase]: PhaseIcon,
  [NewButtonTypes.activity]: ActivityIcon,
  [NewButtonTypes.post]: PostIcon,
  [NewButtonTypes.archive]: ArchiveIcon,
}
const Titles = {
  [ButtonTypes.text]: 'textCardTitle',
  [ButtonTypes.image]: 'imageCardTitle',
  [ButtonTypes.variable]: 'variableCardTitle',
  [ButtonTypes.filter]: 'filterCardTitle',
  [ButtonTypes.email]: 'emailCardTitle',
  [ButtonTypes.request]: 'requestCardTitle',
}

const NewTitles = {
  [NewButtonTypes.phase]: 'Automate:actionPhase',
  [NewButtonTypes.activity]: 'Automate:actionActivity',
  [NewButtonTypes.post]: 'Automate:actionPost',
  [NewButtonTypes.archive]: 'Automate:actionArchive',
  [NewButtonTypes.text]: 'Automate:actionUpdate',
  [NewButtonTypes.email]: 'Automate:actionEmail',
  [NewButtonTypes.request]: 'Automate:actionRequest',
}

const CardIcon = (props: Props) => {
  const { automate, type, disabled } = props
  const Icon = Icons[type]

  return (
    <div
      className='request-constructor__card--icon'
      title={props.t(automate ? NewTitles[type] : Titles[type])}
      style={disabled ? { cursor: 'default' } : {}}
      onClick={disabled ? undefined : props.onClick}
    >
      <Icon
        className={type}
        style={{
          fill: disabled
            ? DISABLED_COLOR
            : automate
            ? NewColors[type]
            : Colors[type],
        }}
      />
    </div>
  )
}

export default withTranslation('RequestConstructor')(CardIcon)
