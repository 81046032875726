// @flow

import { all, put, call, fork, takeLatest, select } from 'redux-saga/effects'

import api from '../../../core/api'
import { serverError } from '../../Layout/Layout.actions'
import * as actions from './SelectChatMember.actionTypes'
import { WIDGET_PARTICIPANTS_LIST_INITIATING } from '../../widgets/WidgetParticipantsList/WidgetParticipantsList.actionTypes'
import { getUser } from '../../../utils/commonSelectors'

function* watchInit() {
  yield takeLatest(actions.SELECT_CHAT_MEMBER_INITIATING, init)
}

function* watchLoad() {
  yield takeLatest(actions.SELECT_CHAT_MEMBER_LOADING, load)
}

function* init(action) {
  try {
    const owner = yield select(state => getUser(state))
    const chatMembers = yield select(state => state.chatroom.members)
    const { params, addMemberToChat, addChatMembers, chatroomUuid, onChange } =
      action
    const getApi = addChatMembers
      ? api.profile.availableChatMembers
      : api.profile.getListTiny
    const result = yield call(getApi, {
      ...params,
      status: 'active',
    })

    const setValue = chatMembers.length ? chatMembers : [owner]
    const extendedValue = []

    if (addMemberToChat) {
      const user = yield call(api.profile.getProfile, addMemberToChat)

      extendedValue.push(owner)
      extendedValue.push(user)

      yield put({ type: actions.SELECT_CHAT_MEMBER_SET_VALUE, extendedValue })
      onChange(extendedValue)
    }

    yield put({
      type: actions.SELECT_CHAT_MEMBER_INITIATED,
      ...result,
      value: addMemberToChat ? extendedValue : setValue,
    })

    if (chatroomUuid) {
      yield put({ type: WIDGET_PARTICIPANTS_LIST_INITIATING, chatroomUuid })
    }
  } catch (error) {
    yield put(serverError(error))
    yield put({ type: actions.SELECT_CHAT_MEMBER_ERROR, error })
  }
}

function* load({ params, addChatMembers }) {
  const getApi = addChatMembers
    ? api.profile.availableChatMembers
    : api.profile.getListTiny
  try {
    const result = yield call(getApi, { ...params, status: 'active' }, false)
    yield put({ type: actions.SELECT_CHAT_MEMBER_LOADED, ...result })
  } catch (error) {
    yield put(serverError(error))
    yield put({ type: actions.SELECT_CHAT_MEMBER_ERROR, error })
  }
}

export default function* watch() {
  yield all([fork(watchInit), fork(watchLoad)])
}
