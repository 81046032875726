// @flow

import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { get } from 'lodash-es'
import { push } from 'connected-react-router'

import Nbsp from '../../../NBSP'
import Rating from '../../../Rating'
import { Cell } from '../../../Block'
import { REQUEST_TYPES_WITHOUT_RATING } from '../../../../constants'

import styles from './RatingCell.module.scss'

type Props = {
  id: number,
  permissions: Object,
  push: string => void,
  rating?: number,
  status_obj: { id: number },
  t: string => string,
}

const RatingCell = (props: Props) => {
  const { rating, permissions, status_obj: statusObj, id } = props

  const statusType = get(statusObj, 'id') || 1

  if (REQUEST_TYPES_WITHOUT_RATING.includes(statusType)) {
    return null
  }

  return (
    <Cell title={props.t('Rating')} valueClassName={styles.value}>
      <Rating readonly value={rating} />
      {typeof rating !== 'number' && permissions.can_set_rating && (
        <Fragment>
          <Nbsp />
          <Link to={`/request/${id}/feedback/create`}>
            {props.t('AddRating')}
          </Link>
        </Fragment>
      )}
    </Cell>
  )
}

const mapStateToProps = state => state.requestView.data
const mapDispatchToProps = {
  push,
}

export default compose(
  withTranslation('Request'),
  connect(mapStateToProps, mapDispatchToProps)
)(RatingCell)
