// @flow

import React from 'react'
import { useDispatch } from 'react-redux'
import classnames from 'classnames'
import type { Node } from 'react'

import { toggleMenu } from '../../Menu/Menu.actions'

import styles from './HeaderBurger.module.scss'

import Icon from '../../../../static/icons/menu.svg'

type Props = { theme: string }

const HeaderBurger = (props: Props): Node => {
  const { theme } = props

  const dispatch = useDispatch()

  const handleClick = () => dispatch(toggleMenu())

  const burgerClass = classnames(styles.burger, {
    [styles.gray]: theme === '#ffffff',
  })

  return (
    <div className={burgerClass} onClick={handleClick}>
      <Icon />
    </div>
  )
}

export default HeaderBurger
