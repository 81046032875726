// @flow

import React from 'react'
import type { Node } from 'react'

import { useThemeColor } from '../../hooks'
import Icon from '../Icon'

type Props = {
  disabled?: boolean,
  onClick: () => void,
  style?: Object,
}

const Cross = (props: Props): Node => {
  const { disabled, style } = props

  const themeColor = useThemeColor()

  const iconStyle = Object.assign(
    {},
    { '--themeColor': themeColor },
    style ? { ...style } : undefined
  )

  return (
    <Icon
      colorOnHover
      className='button-edit'
      disabled={disabled}
      id='cross'
      style={iconStyle}
      onClick={props.onClick}
    />
  )
}

export default Cross
