// @flow

import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { replace } from 'connected-react-router'
import type { Location } from 'react-router-dom'

import { useSelected } from '../../hooks'
import BottomPagination from '../Pagination/BottomPagination'
import {
  getDocumentMappingList,
  getArchivedDocumentMappingList,
} from '../../core/api/api.documentMapping'
import { serverError } from '../Layout/Layout.actions'
import { SERVER_404_ERROR } from '../Layout/Layout.constants'
import DocumentMappingHeader from './DocumentMappingHeader'
import DocumentMappingTable from './DocumentMappingTable'
import { getCurrentPage, getCurrentFilters } from '../../utils/routing'
import addRedirectToFirstPage from '../../hocs/addRedirectToFirstPage'

type Props = { location: Location }

const DocumentMapping = (props: Props) => {
  const { location } = props

  const [loading, setLoading] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [checking, setChecking] = useState(false)
  const [documents, setDocuments] = useState([])
  const [meta, setMeta] = useState({})
  const [removing, setRemoving] = useState(false)

  const [selectedItems, changeSelected, setSelected, isAllSelected] =
    useSelected()

  const dispatch = useDispatch()
  const { soft_archived: softArchived } = getCurrentFilters(location)

  useEffect(() => {
    if (!loading) {
      return
    }

    const page = getCurrentPage(location)

    const api = softArchived
      ? getArchivedDocumentMappingList
      : getDocumentMappingList

    api({ page })
      .then((data: Object) => {
        setDocuments(data.results.objects)
        setMeta(data.meta)
        setSelected([])
      })
      .catch(error => {
        const action: Object = serverError(error)

        if (action.type === SERVER_404_ERROR) {
          dispatch(replace('/404'))
        } else {
          dispatch(action)
        }
      })
      .finally(() => setLoading(false))
  }, [loading])

  useEffect(() => {
    setLoading(true)
  }, [location.pathname, location.search])

  return (
    <>
      <DocumentMappingHeader
        meta={meta}
        setLoading={setLoading}
        setUploading={setUploading}
        loading={loading || uploading || checking || removing}
        documents={documents}
        setSelected={setSelected}
        isAllSelected={isAllSelected}
        selectedItems={selectedItems}
        setRemoving={setRemoving}
        softArchived={softArchived}
      />
      <DocumentMappingTable
        documents={documents}
        loading={loading || uploading || checking || removing}
        setChecking={setChecking}
        setUploading={setUploading}
        selectedItems={selectedItems}
        changeSelected={changeSelected}
      />
      {!loading && <BottomPagination meta={meta} classes='paginator-2' />}
    </>
  )
}

export default addRedirectToFirstPage(DocumentMapping)
