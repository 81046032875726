// @flow

import React, { useEffect, useState } from 'react'
import { pick } from 'lodash-es'
import type { Location } from 'react-router-dom'
import type { Node } from 'react'

import Loader from '../../../components/Loader'
import AllProvidersList from './AllProvidersList'
import { getCurrentFilters, getCurrentPage } from '../../../utils/routing'
import { getProviders } from '../../../core/api/api.provider'
import { usePathname } from '../../../hooks'

import { redirectTo404 } from '../../../utils/routing'

type Props = {
  location: Location,
  setCount: number => void,
}

const AllProviders = (props: Props): Node => {
  const { location } = props

  const [loading, setLoading] = useState(false)
  const [providers, setProviders] = useState([])
  const [meta, setMeta] = useState(null)

  const page = getCurrentPage(location) || 1
  const filters = getCurrentFilters(location)

  const { category_id, address, b2b, b2c, all, ...rest } = pick(filters, [
    'category_id',
    'name',
    'email',
    'phone',
    'address',
    'request_category',
    'b2b',
    'b2c',
    'all',
    'search',
    'ordering',
  ])

  const categoryId = category_id || b2b || b2c

  const params = Object.assign(
    {},
    { page, ...rest },
    categoryId ? { category_id: categoryId } : undefined,
    all ? { all: JSON.parse(all) } : { all: true },
    address ? { building: address } : undefined
  )

  const isLocked = usePathname()

  useEffect(() => {
    if (loading && !isLocked) {
      getProviders(params)
        .then(data => {
          setProviders(data.results.objects)
          setMeta(data.meta)
          props.setCount & props.setCount(data.meta.count)
          setLoading(false)
        })
        .catch(error => redirectTo404(error))
    }
  }, [loading, isLocked])

  useEffect(() => {
    if (!isLocked && location.search && !loading) {
      setLoading(true)
    }
  }, [location.search, isLocked])

  if (loading || !meta) {
    return <Loader text={false} type='big' />
  }

  return (
    <AllProvidersList
      providers={providers}
      meta={meta}
      setLoading={setLoading}
    />
  )
}

export default AllProviders
