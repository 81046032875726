// @flow

import React, { Component, Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import { map, isEqual, isEmpty } from 'lodash-es'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Link } from 'react-router-dom'

import Checkbox from '../../Checkbox'
import Button from '../../Button'
import { EntityIcon } from '../../Icon'
import Loader from '../../Loader'
import SelectRole from '../../Select/SelectTableCell'
import TopPagination from '../../Pagination/TopPagination'
import BottomPagination from '../../Pagination/BottomPagination'
import AddUserPopup from '../../modals/AddUserPopup/AddUserPopup'
import ConfirmationPopup from '../../modals/ConfirmationPopup'
import Modal from '../../Modal'
import Table from '../../Table'
import * as actions from './BuildingDwellers.actionTypes'
import addRedirectToFirstPage from '../../../hocs/addRedirectToFirstPage'
import { getLocation } from '../../../utils/commonSelectors'
import { getCurrentFilters } from '../../../utils/routing'
import EmptyList from '../../EmptyList'
import SelectPopUpDwellers from './SelectPopUp'
import { isIncluded } from './helpers'

import { uniq } from 'lodash-es'

const ROLES = {
  1: 'dweller',
  2: 'landlord',
}

type Props = {
  buildingAddress: string,
  buildingID: number,
  buildingPermissions: Object,
  canChangeRole: boolean,
  dispatch: Object => string,
  dwellers: Array<any>,
  emptyComponent: React.Node,
  headerClass: string,
  initiated: boolean,
  iscompletereload: boolean,
  location: Object,
  meta: Object,
  modal: React.Element<'div'>,
  onCountUpdate: (number, boolean) => string,
  onSuccessAddUser: () => string,
  ordering: string,
  reload: boolean,
  selected: Array<any>,
  statuses: Object,
  t: (string, params?: Object) => string,
  working: boolean,
}

class BuildingDwellers extends Component<Props, State> {
  componentDidMount() {
    const filters = this.getFilters()

    if (filters.page) {
      this.props.dispatch({
        type: actions.BUILDING_DWELLERS_INITIATING,
        params: {
          building: this.getId(),
          ...filters,
        },
      })
    }
  }

  componentDidUpdate(prev) {
    const { ordering, buildingID, meta } = this.props
    const filters = this.getFilters()
    const prevFilters = this.getFilters(prev)

    if (
      meta.count !== null &&
      meta.count !== prev.count &&
      this.props.onCountUpdate
    ) {
      this.props.onCountUpdate(meta.count, !isEmpty(filters))
    }

    if (prev.reload && prev.iscompletereload) {
      this.props.dispatch({
        type: actions.BUILDING_DWELLERS_RELOAD,
        params: {
          building: this.getId(),
          ...filters,
        },
      })
    }

    if (filters.owner && filters.owner !== prevFilters.owner) {
      this.props.dispatch({
        type: actions.BUILDING_DWELLERS_LOAD_ONE,
        params: {
          building: buildingID,
          ...filters,
        },
      })

      return
    }

    if (ordering !== prev.ordering) {
      this.load()

      return
    }

    if (!isEqual(prevFilters, filters)) {
      this.load()
    }

    if (
      prev.location?.query?.page !== this.props.location.query.page ||
      (meta.curr_page && prev.meta?.curr_page !== meta.curr_page)
    ) {
      this.props.dispatch({
        type: actions.BUILDING_DWELLERS_SET_SELECTED,
        selected: prev.selected,
      })
    }
  }

  componentWillUnmount() {
    this.props.dispatch({
      type: actions.BUILDING_DWELLERS_SET_SELECTED,
      selected: [],
    })
  }

  onSuccessAddUser = () => {
    const {
      meta: { count },
    } = this.props

    this.props.onSuccessAddUser()

    if (this.props.onCountUpdate) {
      this.props.onCountUpdate(count, !isEmpty(this.getFilters()))
    }

    this.hideModal()
  }

  getId = () => this.props.buildingID

  getFilters = (props = this.props) => {
    const { location } = props

    return getCurrentFilters(location)
  }

  getRoleOptions = roles => {
    const { t } = this.props

    return map(roles, (label, key) => ({
      value: parseInt(key, 10),
      group: label,
      label: t(label),
    }))
  }

  getStatusOptions = () => {
    const { statuses, t } = this.props

    return map(statuses, (val, label) => ({
      code: val,
      value: label,
      label: t(`Common:${label}`),
    }))
  }

  getUserById = (dwellers, id) => {
    const [user] = dwellers.filter(d => d.userprofile.id === id)

    return user?.userprofile
  }

  statusOptions = this.getStatusOptions()

  load = () => {
    const { ordering } = this.props

    this.props.dispatch({
      type: actions.BUILDING_DWELLERS_LOAD,
      params: {
        building: this.getId(),
        ordering,
        ...this.getFilters(),
      },
    })
  }

  toggle = ({ owner, flatId }) => {
    this.props.dispatch({
      type: actions.BUILDING_DWELLERS_TOGGLE,
      item: { owner, flatId },
    })
  }

  toggleAll = empty => {
    const { meta, dwellers } = this.props

    if (empty) {
      this.props.dispatch({
        type: actions.BUILDING_DWELLERS_SET_SELECTED,
        selected: [],
      })
    }

    this.props.dispatch({
      type: actions.BUILDING_DWELLERS_TOGGLE_ALL,
      count: meta.limit,
      params: {
        empty,
        curr: true,
        pages: [...dwellers],
      },
    })
  }

  showCreateModal = () => {
    const { dispatch, buildingAddress } = this.props
    dispatch({
      type: actions.BUILDING_DWELLERS_SHOW_MODAL,
      modal: (
        <AddUserPopup
          buildingId={this.getId()}
          buildingAddress={buildingAddress}
          onHide={this.hideModal}
          onSave={this.onSuccessAddUser}
        />
      ),
    })
  }

  showRemoveModal = () => {
    const { selected, t } = this.props

    if (selected.length) {
      this.props.dispatch({
        type: actions.BUILDING_DWELLERS_SHOW_MODAL,
        modal: (
          <ConfirmationPopup
            title={t('DwellerDeleteTitle')}
            text={t('Common:confirmDwellerRemoving')}
            onClose={this.hideModal}
            onOk={this.remove}
          />
        ),
      })
    }
  }

  hideModal = () => {
    this.props.dispatch({ type: actions.BUILDING_DWELLERS_HIDE_MODAL })
  }

  remove = () => {
    const { ordering, selected } = this.props

    this.props.dispatch({
      type: actions.BUILDING_DWELLERS_REMOVE,
      selected: selected.map(user => ({
        user: user.owner,
        flat: user.flatId,
      })),
      model_pks: JSON.stringify(selected.map(({ owner }) => owner)),
      params: {
        building: this.getId(),
        ordering,
        ...this.getFilters(),
      },
    })
  }

  showToolbox = () => {
    const {
      meta,
      buildingPermissions: { can_rm_dwellers: canChange },
      initiated,
    } = this.props

    return initiated && (canChange || meta.count > meta.limit)
  }

  updateRole = (owner, group) => {
    const { ordering } = this.props

    this.props.dispatch({
      type: actions.BUILDING_DWELLERS_UPDATE_ROLE,
      owner,
      group,
      params: {
        building: this.getId(),
        ordering,
        ...this.getFilters(),
      },
    })
  }

  showUpdateDwellerRoleModal(user, role) {
    const { group, label } = role

    if (
      (user.role !== 'dweller' && group === 'landlord') ||
      (user.role === 'dweller' && group === 'dweller')
    ) {
      return
    }

    const { t } = this.props
    const { name, second_name: surname, id: owner } = user

    this.props.dispatch({
      type: actions.BUILDING_DWELLERS_SHOW_MODAL,
      modal: (
        <ConfirmationPopup
          title={t('UpdateRoleTitle')}
          text={t('UpdateRoleText', { name, surname, group: label })}
          confirm={t('Update')}
          cancel={t('Cancel')}
          onClose={this.hideModal}
          onOk={() => this.updateRole(owner, group)}
        />
      ),
    })
  }

  renderRoleSelect = user => {
    const isLandlord = user.role !== 'dweller'
    const roleOptions = this.getRoleOptions(ROLES)
    const value = roleOptions.find(
      o =>
        (isLandlord && o.group === 'landlord') ||
        (!isLandlord && o.group === 'dweller')
    )

    return (
      <SelectRole
        value={value}
        options={roleOptions}
        onChange={val => {
          this.showUpdateDwellerRoleModal(user, val)
        }}
      />
    )
  }

  renderTable = dwellers => {
    const { working, t, initiated, buildingID } = this.props

    return (
      <div className='table table--default'>
        <div className='table__outer-wrapper'>
          <div className='table__wrapper table--building-dwellers__list'>
            <div className='table__head'>
              <Table.HeaderCell
                title={<Fragment>{t('User')}&nbsp;</Fragment>}
                sortKey='userprofile_fullname'
                working={working}
              />
              <Table.HeaderCell
                title={<Fragment>{t('Flat')}&nbsp;</Fragment>}
                sortKey='flat_number'
                working={working}
              />
              <Table.HeaderCell
                title={<Fragment>{t('Role')}&nbsp;</Fragment>}
                style={{ width: '15%' }}
                sortKey='is_landlord'
                working={working}
              />
              <Table.HeaderCell
                title={<Fragment>{t('Status')}&nbsp;</Fragment>}
                sortKey='status'
                working={working}
              />
            </div>
            {!working && !!dwellers.length && map(dwellers, this.renderDweller)}
          </div>
        </div>
        {working && initiated && <Loader type='medium' text={false} />}
        {!working && !dwellers.length && (
          <EmptyList embedded icon='user' title={t('empty_list_title')} />
        )}
        <SelectPopUpDwellers buildingID={buildingID} />
      </div>
    )
  }

  renderDweller = user => {
    const {
      canChangeRole,
      selected,
      buildingPermissions: { can_rm_dwellers: canDelete },
      permissions: { can_change_user_role: landlordHasOneFlat },
      t,
    } = this.props
    const {
      flat_id: flatId,
      flat_number,
      userprofile,
      userprofile: { fullname, avatar, status, id: owner, role, is_available },
      archived,
    } = user
    const isLandlord = role !== 'dweller'
    const statusUser = this.statusOptions.find(o => o.code === status)
    const checked =
      selected.filter(u => u.flatId === flatId && u.owner === owner).length > 0
    const itemClass = classnames('table__row', {
      'table__row--attention': checked,
      'table__row--archived': archived,
      'table__row--not-clickable': !is_available,
    })
    const statusClass = classnames(
      'table-item table-item__status table-item__status--building',
      {
        'table-item__status--success': status === 1,
        'table-item__status--warning': status !== 1 && status !== 3,
        'table-item__status--danger': status === 3,
      }
    )
    const flatClass = classnames('ti-text ti-text_medium', {
      'ti-text_default': !archived,
    })
    const statusText = statusUser.label
    const key = `dweller-${owner}-${flatId}`

    const linkProps = {
      to: is_available ? `/profile/${owner}` : '/',
      className: itemClass,
      key,
    }

    if (!is_available) {
      linkProps.onClick = e => e.preventDefault()
    }

    return (
      <Link {...linkProps}>
        <div className='table__cell'>
          <div className='table__cell-wrapper'>
            <div className='table-item table-item__user'>
              {canDelete && !archived && (
                <label
                  className='checkbox checkbox--medium checkbox--default checkbox--notext table-item__checkbox'
                  htmlFor={`check-${key}`}
                  onClick={e => e.stopPropagation()}
                >
                  <input
                    type='checkbox'
                    id={`check-${key}`}
                    checked={checked}
                    onChange={() => this.toggle({ owner, flatId })}
                  />
                  <span />
                </label>
              )}
              <i className='table-item__user-pic'>
                <img alt='user' src={avatar} />
              </i>
              <span
                className='table-item__user-name table-item__user-building'
                title={fullname}
              >
                {fullname}
              </span>
            </div>
          </div>
        </div>
        <div className='table__cell'>
          <div className='table__cell-wrapper'>
            <div className='ti hide-overflow'>
              <EntityIcon id='apartment' />
              <div className={flatClass} title={flat_number || t('NoData')}>
                <span>
                  {`${t('flat_number_title')} ${flat_number || t('NoData')}`}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className='table__cell'>
          <div className='table__cell-wrapper'>
            {canChangeRole && landlordHasOneFlat && !archived ? (
              this.renderRoleSelect(userprofile)
            ) : (
              <span
                style={{
                  fontFamily: '"Roboto", Helvetica, Arial, sans-serif',
                }}
              >
                {t(isLandlord ? 'Landlord' : 'Dweller')}
              </span>
            )}
          </div>
        </div>
        <div className='table__cell'>
          <div className='table__cell-wrapper'>
            <div className={statusClass} title={statusText}>
              <span>{statusText}</span>
            </div>
          </div>
        </div>
      </Link>
    )
  }

  renderToolbox = () => {
    const {
      working,
      selected,
      allDwellers,
      dwellers,
      meta,
      buildingPermissions: { can_rm_dwellers: canChangeDwellers },
    } = this.props

    const activeDwellers = dwellers.filter(d => !d.archived)
    const isAllSelected =
      selected.length > 0 &&
      map(
        activeDwellers,
        ({ userprofile: { id: owner }, flat_id: flatId }) => ({
          owner,
          flatId,
        })
      ).filter(u => isIncluded(selected, u, 'flatId')).length ===
        activeDwellers.length

    return (
      this.showToolbox() && (
        <div className='toolbox'>
          {canChangeDwellers && activeDwellers.length > 0 && (
            <div className='toolbox__cell'>
              <Checkbox
                outer
                checked={isAllSelected}
                disabled={working}
                onChange={() => this.toggleAll(isAllSelected)}
              />
            </div>
          )}
          {canChangeDwellers && selected.length === 0 && (
            <div className='toolbox__cell'>
              <Button.Save
                disabled={working}
                icon='user'
                onClick={this.showCreateModal}
              >
                {this.props.t('AddUser')}
              </Button.Save>
            </div>
          )}
          {canChangeDwellers && selected.length > 0 && (
            <div className='toolbox__cell'>
              <Button.Remove
                disabled={working}
                onClick={this.showRemoveModal}
              />
            </div>
          )}
          {selected.length > 0 && (
            <div className='toolbox__cell'>
              <Button.CreateEmail
                selectedUsers={uniq(
                  selected.map(s => this.getUserById(allDwellers, s.owner))
                )}
                params={{ building_id: this.getId() }}
                disabled={working}
              />
            </div>
          )}
          <div className='toolbox__cell toolbox__cell--right'>
            <TopPagination
              classes='pager pager--large pager--default-2'
              meta={meta}
            />
          </div>
        </div>
      )
    )
  }

  render() {
    const {
      working,
      dwellers,
      meta,
      modal,
      t,
      emptyComponent,
      headerClass,
      initiated,
    } = this.props

    const filters = this.getFilters()

    return (
      <div>
        <h2 className={headerClass}>
          {t('DwellersAndLandlords')}
          &nbsp;
          {meta.count > 0 ? (
            <span className='unit__title-num'>{meta.count}</span>
          ) : null}
        </h2>
        {(meta.count > 0 || !isEmpty(filters)) && (
          <div>
            {this.renderToolbox()}
            {this.renderTable(dwellers)}
            {dwellers.length > 0 && (
              <BottomPagination
                classes='paginator paginator--middle'
                meta={meta}
              />
            )}
          </div>
        )}
        {!initiated && <Loader type='medium' text={false} />}
        {!working && meta.count === 0 && isEmpty(filters) && emptyComponent}
        <Modal
          contentLabel=''
          className='Modal__Bootstrap modal-dialog'
          isOpen={!!modal}
          onRequestClose={this.hideModal}
        >
          {modal}
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state.buildingDwellers,
  statuses: state.init.user_status,
  location: getLocation(state),
})

export default compose(
  connect(mapStateToProps),
  withTranslation('Building'),
  addRedirectToFirstPage
)(BuildingDwellers)
