// @flow

import {
  ADDRESS_SECTION_CANCEL,
  ADDRESS_SECTION_EDIT,
  ADDRESS_SECTION_ERROR,
  ADDRESS_SECTION_CHANGE,
  ADDRESS_SECTION_INITIATE,
  ADDRESS_SECTION_PROMO,
} from './AddressSection.actionTypes'
export interface Address {
  id: number;
  lattitude: number;
  longitude: number;
  value: string;
  widget_lattitude: number;
  widget_lattitude_str: string;
  widget_longitude: number;
  widget_longitude_str: string;
  widget_zoom: number;
}

type State = {
  address: Address | {},
  editMode: boolean,
  isError: boolean,
  isPromo: boolean,
  mapReady: boolean,
}

export const initialState: State = {
  address: {},
  editMode: false,
  mapReady: false,
  isPromo: false,
  isError: false,
}

export function reducer(state: State, action) {
  switch (action.type) {
    case ADDRESS_SECTION_CANCEL:
      return {
        ...state,
        address: {
          ...action.payload.address,
        },
        editMode: false,
        isPromo: false,
      }
    case ADDRESS_SECTION_INITIATE:
      return { ...state, mapReady: true }
    case ADDRESS_SECTION_EDIT:
      return { ...state, editMode: true }
    case ADDRESS_SECTION_ERROR:
      return { ...state, isError: true }
    case ADDRESS_SECTION_PROMO:
      return { ...state, isPromo: action.payload.isPromo }
    case ADDRESS_SECTION_CHANGE:
      return {
        ...state,
        address: {
          ...state.address,
          ...action.payload?.address,
        },
      }
    default:
      throw new Error('NewAddressSection: Unknown action type')
  }
}
