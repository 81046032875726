// @flow

import React, { PureComponent } from 'react'
import { withTranslation } from 'react-i18next'
import TextareaAutosize from 'react-textarea-autosize'

import Button from '../../../components/Button'
import styles from './Editor.module.scss'

const HALF_AVAILABLE_CHARS = 1000

type Props = {
  canEdit: boolean,
  isEditing: boolean,
  maxLength?: number,
  onChange: () => void,
  t?: string => string,
  value: string,
}

class Editor extends PureComponent<Props> {
  handleEdit = e => {
    this.props.onChange(e.target.value)
  }

  render() {
    const { canEdit, value, maxLength, disabled, isEditing } = this.props
    const charactersLeft = maxLength ? maxLength - value.length : 0

    if (isEditing) {
      return (
        <div className={styles.textarea}>
          <TextareaAutosize
            className='textarea-2__input modal-adduser__comment faq__answer-editor'
            value={value}
            disabled={disabled}
            maxLength={maxLength}
            placeholder={this.props.t('TextPlaceholder')}
            onChange={this.handleEdit}
          />
          {maxLength && charactersLeft < HALF_AVAILABLE_CHARS && (
            <div className={styles.counter}>{charactersLeft}</div>
          )}
        </div>
      )
    }

    return (
      <div className={styles.root}>
        {value}
        {canEdit && <Button.Edit onClick={this.props.onOpen} />}
      </div>
    )
  }
}

Editor.defaultProps = {
  isEditing: false,
}

export default withTranslation('Feedback')(Editor)
