// @flow

export const CHATROOMS_INITIATING = '/Messages/CHATROOMS_INITIATING'
export const CHATROOMS_INITIATED = '/Messages/CHATROOMS_INITIATED'
export const CHATROOMS_ERROR = '/Messages/CHATROOMS_ERROR'
export const CHATROOMS_GET_FILES_INITIATING =
  '/Messages/CHATROOMS_GET_FILES_INITIATING'
export const CHATROOMS_GET_FILES_INITIATED =
  '/Messages/CHATROOMS_GET_FILES_INITIATED'
export const CHATROOMS_GET_FILES_ERROR = '/Messages/CHATROOMS_GET_FILES_ERROR'
export const CHATROOMS_RESET_INITIAL_STATE =
  '/Messages/CHATROOMS_RESET_INITIAL_STATE'
export const CHATROOMS_MARK_AS_READ = '/Messages/CHATROOMS_MARK_AS_READ'
export const CHATROOMS_MARK_AS_UNREAD = '/Messages/CHATROOMS_MARK_AS_UNREAD'
