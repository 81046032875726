// @flow

import {
  USER_POPUP_USERS_CHANGE_COMMENT,
  USER_POPUP_USERS_DELETE,
  USER_POPUP_USERS_INIT,
  USER_POPUP_USERS_ADD,
  USER_POPUP_CLEAR,
} from './UserPopup.actionTypes'
import { SELECT_CHAT_MEMBER_INITIATING } from '../../Select/SelectChatMember/SelectChatMember.actionTypes'

export const initUsers = assignees => ({
  type: USER_POPUP_USERS_INIT,
  assignees,
})

export const selectChatMemberInitiating = ({
  valueId,
  chatroomUuid,
  addChatMembers,
}) => ({
  type: SELECT_CHAT_MEMBER_INITIATING,
  valueId,
  chatroomUuid,
  addChatMembers,
})

export const addUsers = users => ({ type: USER_POPUP_USERS_ADD, users })

export const changeComment = comment => ({
  type: USER_POPUP_USERS_CHANGE_COMMENT,
  comment,
})

export const removeAssignee = assignee => ({
  type: USER_POPUP_USERS_DELETE,
  assignee,
})

export const clearState = () => ({
  type: USER_POPUP_CLEAR,
})
