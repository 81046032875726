// @flow

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { Node } from 'react'
import { push } from 'connected-react-router'
import { useTranslation } from 'react-i18next'

import Regular from './Regular'
import type { Props } from './Regular/Regular'

import { MAIL_SEARCH_SELECT_USERS } from '../MailSearch/MailSearch.actionTypes'

import { getUser } from '../../utils/commonSelectors'
import { isAdminUser, isManagerUser } from '../../utils/utils'
import { Convert } from '../../utils/convert'

type PropsExt = Props & {
  params?: Object,
  selectedUsers: Array<Object>,
}

const CreateEmail = (props: PropsExt): Node => {
  const { t } = useTranslation('Common')
  const dispatch = useDispatch()
  const user = useSelector(getUser)
  const { selectedUsers, params } = props
  const canCreateEmail = isAdminUser(user) || isManagerUser(user)
  const templateParams = params ? `&${Convert.obj2params(params, false)}` : ''

  const createEmail = () => {
    dispatch({
      type: MAIL_SEARCH_SELECT_USERS,
      selectedUsers: selectedUsers,
    })
    dispatch(push(`/mail/create?send=mass${templateParams}`))
  }

  return (
    (canCreateEmail && (
      <Regular {...props} icon='email' view='contained' onClick={createEmail}>
        {t('CreateEmail')}
      </Regular>
    )) ||
    null
  )
}

export default CreateEmail
