// @flow

export const PAYMENT_TYPE_FOR_EXPORT_ID = 'payment_type_for_export_id'
export const PAYMENT_TYPE_FOR_EXPORT = 'payment_type_for_export'
export const PAYMENT_TYPE_AFTER_EXPORT_ID = 'payment_type_after_export_id'
export const PAYMENT_TYPE_AFTER_EXPORT = 'payment_type_after_export'
export const INVOICES_SYSTEM_TYPE = 'invoices_system_type'
export const DEBITOR = 'debitor'
export const DEBITOR_BIC = 'debitor_bic'
export const DEBITOR_IBAN = 'debitor_iban'

export const DEBITOR_MAX_LENGTH = 128
export const DEBITOR_BIC_MAX_LENGTH = 64
export const DEBITOR_IBAN_MAX_LENGTH = 22
