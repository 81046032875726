// @flow

export const LOAD_FEEDBACK_LIST = 'LOAD_FEEDBACK_LIST'
export const FEEDBACK_LIST_WAS_LOADED = 'FEEDBACK_LIST_WAS_LOADED'
export const FEEDBACK_LIST_WAS_NOT_LOADED = 'FEEDBACK_LIST_WAS_NOT_LOADED'

export const loadFeedbackList = () => ({ type: LOAD_FEEDBACK_LIST })
export const feedbackListWasNotLoaded = () => ({
  type: FEEDBACK_LIST_WAS_NOT_LOADED,
})
export const feedbackListWasLoaded = payload => ({
  type: FEEDBACK_LIST_WAS_LOADED,
  payload,
})
