import * as actions from './CompanyBackground.actionTypes'

const initialState = {
  working: false,
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.COMPANY_BACKGROUND_SAVE:
      return {
        ...state,
        error: null,
        working: true
      }
    case actions.COMPANY_BACKGROUND_WAS_SAVED:
      return {
        ...state,
        working: false
      }
    case actions.COMPANY_BACKGROUND_ERROR:
      return {
        ...state,
        working: false,
        error: action.error.message.response
      }
    case actions.COMPANY_BACKGROUND_RESET:
      return initialState
    default:
      return state
  }
}
