// @flow

import React from 'react'
import type { Node } from 'react'

import styles from './Avatar.module.scss'

type Props = {
  avatar: string | Node,
}

const Avatar = (props: Props): Node => {
  const { avatar } = props

  if (typeof avatar === 'string') {
    return (
      <div className={styles.avatar}>
        <img src={avatar} />
      </div>
    )
  }

  return avatar
}

export default Avatar
