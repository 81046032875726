// @flow

import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { push } from 'connected-react-router'
import { omit } from 'lodash-es'

import {
  formatPathname,
  getCurrentFilters,
  getCurrentPage,
  getNewQueryString,
} from '../utils/routing'

type Handler = (string, string, ?boolean, ?Array<string>) => void

export const useFilterHandler = (): Handler => {
  const dispatch = useDispatch()
  const location = useLocation()

  const handleChangeFilter = (key, value, resetPage, omitFilters) => {
    const filters = getCurrentFilters(location)
    const page = getCurrentPage(location)

    const search = getNewQueryString({
      ...omit(filters, omitFilters),
      [key]: value,
      page: resetPage ? 1 : page,
    })

    const pathname = formatPathname(location.pathname)

    if (`?${search}` === location.search) {
      return
    }

    dispatch(push(`/${pathname}/?${search}`))
  }

  return handleChangeFilter
}
