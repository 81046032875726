// @flow

import React, { useState } from 'react'
import type { Node } from 'react'

import Loader from '../Loader'

import { getPaymentTypes } from '../../core/api/api.budgetPlan'
import { useLoad } from '../../hooks'
import PaymentTypeModal from './PaymentTypeModal'
import PaymentTypeTable from './PaymentTypeTable'

const PaymentType = (): Node => {
  const [isModalOpen, setModalOpen] = useState(false)

  const [editingPaymentType, setEditingPaymentType] = useState(null)

  const [data, isLoading, isError, load] = useLoad(getPaymentTypes)

  // TODO Need error component
  if (isError) {
    return null
  }

  const handleClose = () => setModalOpen(false)
  const handleCreate = () => setModalOpen(true)

  return (
    <>
      {data ? (
        <>
          <PaymentTypeTable isLoading={isLoading} onEdit={handleCreate} />
          {isModalOpen && (
            <PaymentTypeModal
              load={load}
              editingPaymentType={editingPaymentType}
              setEditingPaymentType={setEditingPaymentType}
              onClose={handleClose}
            />
          )}
        </>
      ) : (
        <Loader type='big' text={false} />
      )}
    </>
  )
}

export default PaymentType
