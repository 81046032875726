// @flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import type { Node } from 'react'

import { Header, SimpleBlock } from '../Block'
import Notifications from '../Notification/NotificationList'
import InputPassword from '../InputPassword'
import Settings from '../Settings'
import { update } from '../../core/api/api.profile'
import {
  NOTIFICATION_PASSWORD,
  NOTIFICATION_ERROR,
} from '../Notification/NotificationItem'

type Props = {
  user: number,
}

const UserPassword = (props: Props): Node => {
  const { user } = props

  const { t } = useTranslation('User')

  const [notifications, setNotifications] = useState([])
  const [working, setWorking] = useState(false)

  const formik = useFormik({
    initialValues: { passwordOld: '', passwordNew: '', passwordVerify: '' },
    validationSchema: Yup.object({
      passwordOld: Yup.string().required(t('Common:FieldIsRequired')),
      passwordNew: Yup.string().required(t('Common:FieldIsRequired')).min(8),
      passwordVerify: Yup.mixed()
        .required(t('Common:FieldIsRequired'))
        .oneOf([Yup.ref('passwordNew')], t('PasswordNotMatch')),
    }),
    onSubmit: () => {
      if (working) {
        return
      }

      setWorking(true)

      update(user, {
        current_password: formik.values.passwordOld,
        password: formik.values.passwordVerify,
      })
        .then(() => setNotifications([{ type: NOTIFICATION_PASSWORD }]))
        .catch(error =>
          setNotifications([
            {
              type: NOTIFICATION_ERROR,
              text: error.message.response.data.errors.replaceAll(':', ''),
              translate: true,
            },
          ])
        )
        .finally(() => {
          formik.handleReset()

          setWorking(false)
        })
    },
  })

  const passwordOldErrorClass = formik.errors.passwordOld ? 'input--error' : ''
  const passwordNewErrorClass = formik.errors.passwordNew ? 'input--error' : ''
  const passwordVerifyErrorClass = formik.errors.passwordVerify
    ? 'input--error'
    : ''

  return (
    <div id='settings-personal--password'>
      <Notifications
        style={{ marginTop: '1.5rem' }}
        notifications={notifications}
        parent='settings-password-notifications'
      />
      <SimpleBlock working={working}>
        <Header header>{t('PasswordTitle')}</Header>
        <form onSubmit={formik.handleSubmit}>
          <Settings.Row
            label={t('PasswordOld')}
            className={passwordOldErrorClass}
          >
            <InputPassword
              name='passwordOld'
              value={formik.values.passwordOld}
              placeholder={t('PasswordOldPlaceholder')}
              onChange={formik.handleChange}
            />
            <div className='input__msg'>{formik.errors.passwordOld}</div>
          </Settings.Row>
          <Settings.Hint text={t('PasswordInfo')} />
          <Settings.Row
            label={t('PasswordNew')}
            className={passwordNewErrorClass}
          >
            <InputPassword
              isNew
              name='passwordNew'
              value={formik.values.passwordNew}
              placeholder={t('PasswordNewPlaceholder')}
              onChange={formik.handleChange}
            />
            {!formik.values.passwordNew && (
              <div className='input__msg'>{formik.errors.passwordNew}</div>
            )}
          </Settings.Row>
          <Settings.Row
            label={t('PasswordVerify')}
            className={passwordVerifyErrorClass}
          >
            <InputPassword
              name='passwordVerify'
              placeholder={t('PasswordVerifyPlaceholder')}
              value={formik.values.passwordVerify}
              onChange={formik.handleChange}
            />
            <div className='input__msg'>{formik.errors.passwordVerify}</div>
            {formik.dirty &&
              !formik.errors.passwordVerify &&
              !formik.errors.passwordNew && (
                <div className='input__msg input__msg--password green'>
                  {t('PasswordMuch')}
                </div>
              )}
          </Settings.Row>
          <Settings.Actions
            working={working}
            withChanges={formik.dirty}
            isValid={formik.isValid}
            onCancel={formik.handleReset}
          />
        </form>
      </SimpleBlock>
    </div>
  )
}

export default UserPassword
