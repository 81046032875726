// @flow

import React from 'react'
import type { ElementType } from 'react'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'
import seapig, { OPTIONAL } from 'seapig'
import { Link } from 'react-router-dom'

import Icon from '../../Icon'
import Like from '../../Like'

import styles from './PostFooter.module.scss'

type Props = {
  children: ElementType,
  className?: string,
  disabledConversation?: boolean,
  openConversation?: () => void,
  post: Object,
  t: string => string,
}

const PostFooter = (props: Props) => {
  const { post, children, disabledConversation, className, requestId } = props

  const {
    uuid,
    is_liked: liked,
    comment_count: commentCount,
    like_count: likeCount,
    permissions,
    rating,
  } = post
  const commentClassName = classnames('post-chat-btn', styles.conversation, {
    'post-chat-btn--active': !!commentCount,
  })
  const { likeChildren } = seapig(children, { like: OPTIONAL })
  const rootClassName = classnames('post-like', className, styles.root)
  const link = `${
    rating === undefined ? '/post/' : `/request/${requestId}/feedback/`
  }${uuid}/`

  return (
    <div className={rootClassName}>
      {likeChildren.length ? (
        likeChildren
      ) : (
        <div className={styles.likeBar}>
          <Like
            active={liked}
            count={likeCount}
            disabled={!permissions.can_like}
            model='post'
            uuid={uuid}
          />
        </div>
      )}
      {!disabledConversation && (
        <Link to={link} className={commentClassName}>
          <Icon id='chat' />
          <span className='comments__likes-counter'>{commentCount}</span>
        </Link>
      )}
    </div>
  )
}

PostFooter.defaultProps = {
  openConversation: null,
  disabledConversation: false,
}

export default withTranslation('PostItem')(PostFooter)
