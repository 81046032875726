// @flow

import React from 'react'
import classNames from 'classnames'
import type { Node } from 'react'

import styles from './Label.module.scss'

type Props = { isSub?: boolean, label: string }

const Label = (props: Props): Node => {
  const { label, isSub } = props

  const labelClass = classNames(styles.label, { [styles.sub]: isSub })

  return (
    <span title={label} className={labelClass}>
      {label}
    </span>
  )
}

export default Label
