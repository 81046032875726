// @flow

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { Location } from 'react-router-dom'
import type { Node } from 'react'

import {
  getTemplates,
  deleteTemplate,
  patchTemplate,
} from '../../core/api/api.template'
import { SUBMENU_TEMPLATE_DEFAULT_KEY } from '../Menu/Menu.constants'
import Modal from '../Modal'
import Button from '../Button'
import EmptyList from '../EmptyList'
import {
  getEmailTemplates,
  deleteEmailTemplate,
  patchEmailTemplate,
} from '../../core/api/api.emailTemplate'
import { useSelected } from '../../hooks'
import { getCurrentFilters } from '../../utils/routing'
import ButtonSave from '../Button/Save'
import NewTable from '../NewTable'
import BottomPagination from '../Pagination/BottomPagination'
import TopPagination from '../Pagination/TopPagination'
import Toolbox from '../Toolbox'
import ToolboxCell from '../Users/UserList/Toolbox/ToolboxCell'
import ConfirmationPopup from '../modals/ConfirmationPopup'
import TemplatePopup from './TemplatePopup'
import TemplatesTableHeader from './TemplatesTableHeader'
import TemplatesTableBody from './TemplatesTableBody'
import VisibilityPopup from '../modals/VisibilityPopup'
import type { Params } from './Templates.types'
import { usePathname } from '../../hooks/usePathname'

import { redirectTo404 } from '../../utils/routing'

type Props = { isEmail?: boolean, location: Location }

const Templates = (props: Props): Node => {
  const { location, isEmail } = props

  const { t } = useTranslation('Templates')

  const [selectedItems, changeSelected, setSelected, isAllSelected] =
    useSelected()
  const [templates, setTemplates] = useState([])
  const [meta, setMeta] = useState(null)
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(null)
  const [params: ?Params, setParams] = useState(null)
  const [isPopupOpen, setPopupOpen] = useState(false)

  const isLocked = usePathname()
  const filters = getCurrentFilters(location)

  useEffect(() => {
    if (loading && !isLocked) {
      const api = isEmail ? getEmailTemplates : getTemplates
      api({ ...filters })
        .then(data => {
          setMeta({
            count: data.count,
            limit: 20,
            offset: 0,
            page_count: data.pages,
            curr_page: parseInt(filters.page),
          })
          setTemplates(data.objects)
          setSelected([])
          setLoading(false)
        })
        .catch(error => redirectTo404(error))
    }
  }, [loading, isLocked])

  useEffect(() => {
    if (!isLocked && location.search && !loading) {
      setLoading(true)
    }
  }, [location.search, isLocked])

  useEffect(() => {
    if (!isPopupOpen) {
      setParams(null)
    }
  }, [isPopupOpen])

  const handleTemplate = params => {
    setParams(params)
    setPopupOpen(true)
  }

  const handleCreate = () =>
    handleTemplate({ title: t('AddTemplate'), buttonText: t('AddButton') })

  const handleClone = initialValues => () =>
    handleTemplate({
      title: t('CloneTemplate'),
      buttonText: t('CloneButton'),
      ...initialValues,
    })

  const handleEdit = initialValues => () =>
    handleTemplate({
      title: t('EditTemplate'),
      buttonText: t('EditButton'),
      ...initialValues,
    })

  const hideModal = () => setModal(null)

  const hidePopup = () => setPopupOpen(false)

  const removeTemplates = async () => {
    return Promise.all(
      selectedItems.map(item => {
        const templateObj = templates.find(t => t.id === item)

        if (!templateObj.permissions.can_edit) {
          return null
        }

        const deleteApi = isEmail ? deleteEmailTemplate : deleteTemplate

        return deleteApi(item).then(data => data)
      })
    )
  }

  const handleMassRemove = async () => {
    hideModal()

    await removeTemplates()

    setLoading(true)
  }

  const handleRemove = id => () => {
    hideModal()
    const deleteApi = isEmail ? deleteEmailTemplate : deleteTemplate
    deleteApi(id).then(() => setLoading(true))
  }

  const openMassModal = () => {
    setModal(
      <ConfirmationPopup
        confirm={t('TemplatesRemoveButton')}
        title={t('TemplatesRemoveTitle')}
        text={t('ConfirmTemplatesRemoving')}
        onClose={hideModal}
        onOk={handleMassRemove}
      />
    )
  }

  const openMassRemoveModal = () => {
    openMassModal()
  }

  const openMassChangeModal = () => {
    setModal(
      <VisibilityPopup
        selectedItems={selectedItems}
        templates={templates}
        setLoading={setLoading}
        sectionKey='Templates'
        patchApi={isEmail ? patchEmailTemplate : patchTemplate}
        onClose={hideModal}
      />
    )
  }

  const openModal = id => {
    setModal(
      <ConfirmationPopup
        confirm={t('TemplateRemoveButton')}
        title={t('TemplateRemoveTitle')}
        text={t('ConfirmTemplateRemoving')}
        onClose={hideModal}
        onOk={handleRemove(id)}
      />
    )
  }

  const openRemoveModal = id => () => {
    openModal(id)
  }

  const resetTemplates = () => {
    hidePopup()
    setLoading(true)
  }

  return (
    <>
      <Toolbox
        showSelectAllCheckbox
        itemValueKey='id'
        checked={isAllSelected(templates)}
        selected={!!selectedItems.length && !isAllSelected(templates)}
        items={templates}
        setSelected={setSelected}
      >
        {filters.sub !== SUBMENU_TEMPLATE_DEFAULT_KEY &&
          selectedItems.length === 0 && (
            <ToolboxCell>
              <ButtonSave onClick={handleCreate}>{t('AddTemplate')}</ButtonSave>
            </ToolboxCell>
          )}
        {selectedItems.length !== 0 && (
          <ToolboxCell>
            <Button.Remove
              icon='eye'
              disabled={!selectedItems.length}
              onClick={openMassChangeModal}
            >
              {t('ChangeVisibilityButton')}
            </Button.Remove>
            <Button.Remove
              disabled={!selectedItems.length}
              onClick={openMassRemoveModal}
            >
              {t('RemoveTemplates')}
            </Button.Remove>
          </ToolboxCell>
        )}
        <ToolboxCell isRight>
          <TopPagination
            classes='pager pager--large pager--default-2'
            meta={meta}
          />
        </ToolboxCell>
      </Toolbox>
      <NewTable loading={loading}>
        <TemplatesTableHeader isEmail={isEmail} />
        <TemplatesTableBody
          isEmail={isEmail}
          selectedItems={selectedItems}
          handleChange={changeSelected}
          templates={templates}
          handleClone={handleClone}
          handleEdit={handleEdit}
          openRemoveModal={openRemoveModal}
        />
      </NewTable>
      {!templates.length && !loading && (
        <EmptyList icon='templates' title={t('TemplatesNotFound')} />
      )}
      <BottomPagination meta={meta} classes='paginator-2' />
      {!!modal && (
        <Modal
          contentLabel=''
          className='Modal__Bootstrap modal-dialog'
          isOpen={!!modal}
          onRequestClose={hideModal}
        >
          {modal}
        </Modal>
      )}
      {isPopupOpen && params && (
        <TemplatePopup
          params={params}
          resetTemplates={resetTemplates}
          isEmail={isEmail}
          isOpen={isPopupOpen}
          onClose={hidePopup}
        />
      )}
    </>
  )
}

export default Templates
