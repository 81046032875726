// @flow

import { isEqual } from 'lodash-es'
import { createSelectorCreator, defaultMemoize } from 'reselect'

export const getRequestData = state => state.requestView.data
export const getActiveModal = state => state.requestView.modal
export const isWorking = state => state.requestView.working
const getType = state => getRequestData(state).request_type
const getTypeName = state => getRequestData(state).request_type_name
const getOwner = state => getRequestData(state).owner_obj
const getFlat = state => getRequestData(state).flat_obj
const getBuildingId = state => getRequestData(state).building
const getAssignees = state => getRequestData(state).assignee_objs
const getStatus = state => getRequestData(state).status_obj
const getOrder = state => getRequestData(state).order_ids
const isSystemRequest = state => getRequestData(state).is_system_type
const dueDate = state => getRequestData(state).due_date

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual)

export const getInitialState = createDeepEqualSelector(
  getType,
  getTypeName,
  getOwner,
  getFlat,
  getBuildingId,
  getAssignees,
  getStatus,
  getOrder,
  isSystemRequest,
  dueDate,
  (
    type,
    typeName,
    owner,
    flat,
    building,
    assignees,
    status,
    orders,
    isSystem,
    dueDate
  ) => ({
    type: {
      id: type,
      name: typeName,
    },
    owner,
    flat,
    building: { id: building },
    assignees,
    status,
    orders,
    isSystem,
    dueDate,
  })
)
