// @flow

import * as actions from './Profile.actionTypes'

const initialState = {
  initiated: false,
  working: false,
  data: {},
  ready: {
    info: false,
    contacts: false,
    buildings: false,
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.PROFILE_INITIATING:
    case actions.PROFILE_RESET:
      return initialState
    case actions.PROFILE_INITIATED:
      return {
        ...state,
        initiated: true,
        data: action.data,
      }
    case actions.PROFILE_SECTION_READY:
      return {
        ...state,
        ready: {
          ...state.ready,
          [action.key]: true,
        },
      }
    case actions.PROFILE_UPDATE:
      return {
        ...state,
        working: true,
      }
    case actions.PROFILE_UPDATE_FILES:
      return {
        ...state,
        working: true,
      }
    case actions.PROFILE_UPDATED: {
      return {
        ...state,
        working: false,
        data: action.data,
      }
    }
    case actions.PROFILE_ERROR:
      return {
        ...state,
        initiated: true,
        data: {},
      }
    default: {
      return state
    }
  }
}
