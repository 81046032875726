// @flow

import React from 'react'
import classnames from 'classnames'
import type { Node } from 'react'

import styles from './ModalBody.module.scss'

type Props = {
  children: Node,
  className?: string,
  style?: Object,
}

const ModalBody = (props: Props): Node => {
  const { children, style, className } = props

  const modalBodyClass = classnames(className, styles.body)

  return (
    <div className={modalBodyClass} style={style}>
      {children}
    </div>
  )
}

export default ModalBody
