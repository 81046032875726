// @flow

import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import linkifyHtml from 'linkifyjs/html'
import classnames from 'classnames'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { get } from 'lodash-es'
import { goBack } from 'connected-react-router'
import type { Node } from 'react'

import RequestTranslate from '../RequestTranslate'
import { getFullDateFormat } from '../../../utils/commonSelectors'
import RequestBodyHeader from './RequestBodyHeader'
import Button from '../../Button'
import RequestBodyForm from './RequestBodyForm'
import { newLineToBr } from '../../../utils/text'

import styles from './RequestBody.module.scss'

type Props = {
  isCancelButtonDisabled: boolean,
  isSaveButtonDisabled: boolean,
  onSave: ({ text?: string, title: string }) => void,
  outbound?: string,
  requestData?: ?Object,
  working: boolean,
}

const RequestBody = (props: Props): Node => {
  const {
    outbound,
    requestData,
    working,
    isCancelButtonDisabled,
    isSaveButtonDisabled,
  } = props

  const [collapsed, setCollapsed] = useState(false)
  const [saving, setSaving] = useState(false)
  const [editing, setEditing] = useState(false)
  const [translations, setTranslations] = useState(null)

  const dispatch = useDispatch()

  const dateFormat = useSelector(getFullDateFormat)

  const sectionClass = classnames(
    'apply-profile-edit unit unit--default requests',
    {
      'working-overlay': saving,
      'request-body-section-nomargin': !!requestData,
    }
  )

  const title = get(requestData, 'title', '')
  const text = get(requestData, 'text', '')

  const canEditContent =
    !outbound && get(requestData, ['permissions', 'can_edit_content'], false)

  const handleEdit = () => setEditing(true)
  const handleToggleCollapsed = () => setCollapsed(collapsed => !collapsed)
  const handleReset = e => {
    formik.handleReset(e)

    setTranslations(null)
    setEditing(false)

    if (!requestData) {
      dispatch(goBack())
    }
  }

  const formik = useFormik({
    initialValues: {
      title,
      text,
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required('RequestTitleIsRequired')
        .max(255, 'RequestTitleMaxLength'),
      text: Yup.string(),
    }),
    onSubmit: values => {
      setSaving(true)
      setTranslations(null)

      props.onSave({ ...values })
    },
  })

  useEffect(() => {
    if (!working) {
      setSaving(false)
      setEditing(false)
    }
  }, [working])

  useEffect(() => {
    if (translations?.title) {
      formik.setFieldValue('title', translations.title)
    }

    if (translations?.text) {
      formik.setFieldValue('text', translations.text)
    }
  }, [translations])

  if (collapsed) {
    return (
      <section className={sectionClass}>
        <RequestBodyHeader
          data={requestData}
          dateFormat={dateFormat}
          collapsed={collapsed}
          outbound={outbound}
          toggleCollapse={handleToggleCollapsed}
        />
      </section>
    )
  }

  const requestTextClassName = classnames(
    'post-content__text request-text',
    styles.text
  )

  return (
    <section className={sectionClass}>
      <RequestBodyHeader
        data={requestData}
        dateFormat={dateFormat}
        collapsed={collapsed}
        outbound={outbound}
        toggleCollapse={handleToggleCollapsed}
      />
      {!!requestData && (
        <RequestTranslate
          disabled={editing}
          saving={saving}
          title={get(translations, 'title', title)}
          text={get(translations, 'text', text)}
          onWasTranslated={setTranslations}
        />
      )}
      {editing || !requestData ? (
        <RequestBodyForm
          formik={formik}
          data={requestData}
          isSaveButtonDisabled={isSaveButtonDisabled}
          handleReset={handleReset}
          isCancelButtonDisabled={isCancelButtonDisabled}
        />
      ) : (
        <div>
          <div className='post-content post-content--01'>
            <h2
              title={formik.values.title}
              className='post-content__title request-title'
            >
              {get(translations, 'title', title)}
              {canEditContent && <Button.Edit onClick={handleEdit} />}
            </h2>
            <p
              dangerouslySetInnerHTML={{
                __html: linkifyHtml(
                  newLineToBr(get(translations, 'text', text))
                ),
              }}
              className={requestTextClassName}
            />
          </div>
        </div>
      )}
    </section>
  )
}

RequestBody.defaultProps = {
  isCancelButtonDisabled: false,
  isSaveButtonDisabled: false,
}

export default RequestBody
