// @flow

import React from 'react'
import type { Node } from 'react'

import ToggleButton from '../Button/Toggle'

import styles from './ListViewToggleButton.module.scss'

import ListIcon from '../../../static/icons/list.svg'
import TileIcon from '../../../static/icons/tile.svg'

type Props = {
  onClick: (SyntheticEvent<HTMLInputElement>) => void,
  tileView: boolean,
}

const ListViewToggleButton = (props: Props): Node => {
  const { tileView } = props

  const listIconProps = Object.assign(
    {},
    tileView ? undefined : { className: styles.active }
  )

  const tileIconProps = Object.assign(
    {},
    tileView ? { className: styles.active } : undefined
  )

  return (
    <div className={styles.toggle}>
      <ListIcon {...listIconProps} />
      <ToggleButton highlighted checked={tileView} onChange={props.onClick} />
      <TileIcon {...tileIconProps} />
    </div>
  )
}

export default ListViewToggleButton
