// @flow

import { INIT_LOADED } from '../../components/init/init.actionTypes'

export const LOAD_BUILDING = 'LOAD_BUILDING'
export const BUILDING_WAS_LOADED = 'BUILDING_WAS_LOADED'
export const LOAD_NEWS = 'LOAD_NEWS'
export const LOAD_TICKER_NEWS = 'LOAD_TICKER_NEWS'
export const NEWS_WAS_LOADED = 'NEWS_WAS_LOADED'
export const TICKER_NEWS_WAS_LOADED = 'TICKER_NEWS_WAS_LOADED'
export const NEWS_WAS_FAILED = 'NEWS_WAS_FAILED'
export const TICKER_NEWS_WAS_FAILED = 'TICKER_NEWS_WAS_FAILED'
export const LOAD_CONTACTS = 'LOAD_CONTACTS'
export const CONTACTS_WAS_LOADED = 'CONTACTS_WAS_LOADED'
export const LOAD_WEATHER = 'LOAD_WEATHER'
export const WEATHER_WAS_LOADED = 'WEATHER_WAS_LOADED'
export const WEATHER_WAS_FAILED = 'WEATHER_WAS_FAILED'
export const LOAD_VERSION = 'LOAD_VERSION'
export const VERSION_WAS_LOADED = 'VERSION_WAS_LOADED'
export const LOAD_INIT_DIGITAL_BOARD = 'LOAD_INIT_DIGITAL_BOARD'

export const loadBuilding = () => ({
  type: LOAD_BUILDING,
  payload: { building: true },
})
export const buildingWasLoaded = building => ({
  type: BUILDING_WAS_LOADED,
  payload: { building },
})

export const loadNews = () => ({
  type: LOAD_NEWS,
  payload: { news: true },
})
export const loadTickerNews = () => ({
  type: LOAD_TICKER_NEWS,
  payload: { tickerNews: true },
})
export const tickerNewsWasLoaded = tickerNews => ({
  type: TICKER_NEWS_WAS_LOADED,
  payload: { tickerNews },
})
export const newsWasLoaded = news => ({
  type: NEWS_WAS_LOADED,
  payload: { news },
})
export const newsWasFailed = error => ({
  type: NEWS_WAS_FAILED,
  payload: { news: error },
})
export const tickerNewsWasFailed = error => ({
  type: TICKER_NEWS_WAS_FAILED,
  payload: { tickerNews: error },
})

export const loadContacts = () => ({
  type: LOAD_CONTACTS,
  payload: { contacts: true },
})
export const contactsWasLoading = contacts => ({
  type: CONTACTS_WAS_LOADED,
  payload: { contacts },
})

export const loadWeather = () => ({
  type: LOAD_WEATHER,
  payload: { weather: true },
})
export const weatherWasLoaded = weather => ({
  type: WEATHER_WAS_LOADED,
  payload: { weather },
})
export const weatherWasFailed = error => ({
  type: WEATHER_WAS_FAILED,
  payload: { weather: error },
})

export const loadVersion = () => ({ type: LOAD_VERSION })
export const versionWasLoaded = version => ({
  type: VERSION_WAS_LOADED,
  payload: { version },
})

export const loadInit = () => ({ type: LOAD_INIT_DIGITAL_BOARD })
export const initWasLoaded = system => ({
  type: INIT_LOADED,
  data: system,
})
