// @flow

export const MAIL_SEARCH_SELECT_USERS = 'MAIL_SEARCH_SELECT_USERS'
export const MAIL_SEARCH_SELECT_CUSTOMERS = 'MAIL_SEARCH_SELECT_CUSTOMERS'
export const MAIL_SEARCH_SELECT_PROVIDERS = 'MAIL_SEARCH_SELECT_PROVIDERS'
export const MAIL_SEARCH_INIT = 'MAIL_SEARCH_INIT'
export const MAIL_SEARCH_LOAD = 'MAIL_SEARCH_LOAD'
export const MAIL_SEARCH_LOADED = 'MAIL_SEARCH_LOADED'
export const MAIL_SEARCH_UPDATE_VALUE = 'MAIL_SEARCH_UPDATE_VALUE'
export const MAIL_SEARCH_CLEAR = 'MAIL_SEARCH_CLEAR'
export const MAIL_SEARCH_CHANGE_MANAGER = 'MAIL_SEARCH_CHANGE_MANAGER'
export const MAIL_SEARCH_RESET = 'MAIL_SEARCH_RESET'
export const MAIL_SET_VALUES = 'MAIL_SET_VALUES'
