// @flow

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Widget from '../../../containers/Widget'
import { WIDGET_TYPES } from '../../../containers/widgets/widgets'
import { getWidgetContractors } from '../../../core/api/api.contractor'
import Loader from '../../Loader/Loader'
import ProvidersList from './ProvidersList'

const ProvidersWidget = props => {
  const { isActive } = props

  const { t } = useTranslation('Request')

  const building = useSelector(state => state.requestView.data.building)
  const category = useSelector(state => state.requestView.data.request_type)
  const isSystemCategory = useSelector(
    state => state.requestView.data.is_system_type
  )
  const [list, setList] = useState([])
  const [working, setWorking] = useState(false)

  const getProviders = async () => {
    if (isSystemCategory) {
      setWorking(false)

      return
    }

    const data = await getWidgetContractors({ building, category })

    setList(data.results.objects)
    setWorking(false)
  }

  useEffect(() => {
    if (isActive && (building || category)) {
      setWorking(true)

      getProviders()
    }
  }, [building, category, isActive])

  if (working) {
    return <Loader text={false} type='medium' />
  }

  if (!list.length) {
    return (
      <Widget name={WIDGET_TYPES.providers} title={t('Common:Providers')}>
        <div className='bar__submit-label_light bar__submit-label no-manager-assignet-label'>
          {t('NoProviders')}
        </div>
      </Widget>
    )
  }

  return <ProvidersList list={list} />
}

export default ProvidersWidget
