// @flow

import React from 'react'
import { get } from 'lodash-es'
import { useSelector } from 'react-redux'
import { createFragmentContainer, graphql } from 'react-relay'
import { Link, withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Icon from '../../components/Icon'
import Image from '../../components/ImageSquareFit'
import { MARKETPLACE_ROUTES } from '../../constants'
import { getUser } from '../../utils/commonSelectors'
import { parseMpPrice } from '../../utils/utils'
import { getAvatar } from '../../utils/utils'

import styles from './OffersList.module.scss'
import defaultStyles from './OfferTileList.module.scss'

const DEFAULT_OFFER_IMAGE = '/static/images/offer_default.png'
const DEFAULT_OFFER_ALT = 'offer-image'

const Offer = ({ offer, ...props }) => {
  const {
    language_obj: { code },
  } = useSelector(getUser)

  const { t } = useTranslation('Offers')

  const redirectToOffer = providerId => e => {
    e.preventDefault()
    props.history.push(MARKETPLACE_ROUTES['marketplaceProvider'](providerId))
  }

  const isNullPrice = parseFloat(offer.finalPrice) === 0

  const priceType = get(offer, ['priceType', 'name'], '')

  const price = isNullPrice
    ? t('CostWilBeSpecified')
    : t('price', {
        price: parseMpPrice(offer.finalPrice, code),
        priceType,
      })

  return (
    <Link key={offer.id} to={MARKETPLACE_ROUTES['marketplaceOffer'](offer.id)}>
      <div className={defaultStyles.container}>
        <Image
          alt={
            offer.gallery.length > 0
              ? offer.gallery.find(img => img.default).alt
              : DEFAULT_OFFER_ALT
          }
          src={
            offer.gallery.length > 0
              ? getAvatar(offer.gallery.find(img => img.default))
              : DEFAULT_OFFER_IMAGE
          }
          fit='contain'
        />
        <div className={defaultStyles.info}>
          <div className={defaultStyles.price} title={price}>
            {price}
          </div>
          <div className={defaultStyles.name}>{offer.name}</div>
          <div className={styles.box}>
            <div className={styles.counter}>
              <Icon id='order' className={styles.icon} />
              <span className='u-a20__stat'>{offer.orderCount}</span>
            </div>
          </div>
          <div
            className={styles.company}
            onClick={redirectToOffer(offer.provider.id)}
          >
            <div className={styles['company-logo-container']}>
              <img
                src={offer.provider.image.origin}
                className={styles['company-logo']}
              />
            </div>
            <div className={styles['company-name']} title={offer.provider.name}>
              {offer.provider.name}
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default createFragmentContainer(withRouter(Offer), {
  offer: graphql`
    fragment Offer_offer on OfferNode {
      id
      name
      finalPrice
      priceType {
        name
      }
      provider {
        name
        id
        image {
          origin
        }
      }
      gallery {
        id
        origin
        default
        thumbnails {
          url
          size
        }
      }
      orderCount
    }
  `,
})
