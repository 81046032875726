// @flow

import { all, put, call, fork, takeEvery } from 'redux-saga/effects'
import { get } from 'lodash-es'
import type { Saga } from 'redux-saga'

import api from '../../../core/api'
import * as actions from './Login.actionTypes'
import { serverError } from '../../Layout/Layout.actions'
import {
  INIT_LOADED,
  UPDATE_COUNTERS_SUCCESS,
} from '../../init/init.actionTypes'
import BrowserStorage from '../../../utils/browserStorage'

function* watchLogin(): Saga<void> {
  yield takeEvery(actions.LOGIN_INIT, login)
}

export function* login({
  username,
  password,
}: {
  password: string,
  username: string,
}): Saga<void> {
  try {
    const req = { username, password }
    const token = yield call(api.login.authorize, req)

    if (token.error) {
      yield put({ type: actions.LOGIN_ERROR, error: token })

      return
    }

    BrowserStorage.set('api', `//${token.host}/`)

    const user = yield call(api.profile.getMyProfile)
    const initData = yield call(api.system.init)

    yield put({ type: INIT_LOADED, data: initData })
    yield put({ type: actions.LOGIN_SUCCESS, response: { ...token, ...user } })
    yield put({
      type: UPDATE_COUNTERS_SUCCESS,
      counters: { unviewed_requests: user.requests_counter },
    })
  } catch (error) {
    yield put(serverError(error))
    const errorObj = get(error, ['message', 'response', 'data', 'errors'])
    const statusCode = get(error, [
      'message',
      'response',
      'data',
      'status_code',
    ])
    yield put({
      type: actions.LOGIN_ERROR,
      error: errorObj,
      status: statusCode,
    })
  }
}

export default function* watch(): Saga<void> {
  yield all([fork(watchLogin)])
}
