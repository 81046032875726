// @flow

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'

import { SimpleBlock, Header } from '../../components/Block'
import Image from '../../components/CompanyImage'
import LoadImagePopup, {
  IMAGE_TYPES,
} from '../../components/modals/LoadImagePopup'
import Settings from '../../components/Settings'

import { getUkLogo, getUkMobileLogo } from '../../utils/commonSelectors'

import { getWebFallback, getMobileFallback } from './CompanyLogo.selectors'
import * as actions from './CompanyLogo.actionTypes'

const SIZES = {
  [IMAGE_TYPES.web]: {
    width: 182,
    height: 135,
  },
  [IMAGE_TYPES.mobile]: {
    width: 512,
    height: 512,
  },
}

type LogoType = 'mobile' | 'web'
type Props = {
  dispatch: Object => void,
  fallbacks: Object,
  mobileLogo: Object,
  saving: boolean,
  t: string => string,
  uk: number,
  webLogo: Object,
}
type State = {
  mobile: ?Object,
  uploadedLogo: ?{
    image: Object,
    type: LogoType,
  },
  uploading: boolean,
  web: ?Object,
}

class CompanyLogo extends Component<Props, State> {
  state = {
    mobile: null,
    uploadedLogo: null,
    uploading: false,
    web: null,
  }

  componentDidUpdate(prev: Props) {
    const { saving } = this.props

    if (prev.saving && !saving) {
      this.setState({ mobile: null, web: null })
    }
  }

  componentWillUnmount() {
    this.props.dispatch({
      type: actions.COMPANY_LOGO_RESET,
    })
  }

  startUpload = () => {
    this.setState({ uploading: true })
  }

  finishUpload = (type: LogoType) => (files: Array<Object>) => {
    if (files.length) {
      this.setState({
        uploadedLogo: {
          image: files[0],
          type,
        },
      })
    }

    this.setState({ uploading: false })
  }

  cancelUpload = () => {
    this.setState({ uploadedLogo: null })
  }

  updateLogo = (file: Object) => {
    const { uploadedLogo } = this.state

    if (uploadedLogo) {
      this.setState({
        [uploadedLogo.type]: file,
        uploadedLogo: null,
      })
    }
  }

  resetLogo = (type: LogoType) => () => {
    const { fallbacks } = this.props
    this.setState({
      [type]: { id: '', filename: '', origin: fallbacks[type] },
    })
  }

  cancel = () => {
    this.setState({
      mobile: null,
      web: null,
    })
  }

  save = () => {
    const { uk } = this.props
    const { web, mobile } = this.state

    this.props.dispatch({
      type: actions.COMPANY_LOGO_SAVE,
      uk,
      web,
      mobile,
    })
  }

  renderModal = () => {
    const { uploadedLogo } = this.state

    return (
      !!uploadedLogo && (
        <LoadImagePopup
          {...SIZES[uploadedLogo.type]}
          image={uploadedLogo.image}
          titleText={this.props.t('LogoLoad')}
          type={uploadedLogo.type}
          onCancel={this.cancelUpload}
          onSave={this.updateLogo}
        />
      )
    )
  }

  renderLogo = (file: Object, type: LogoType) => {
    const { fallbacks } = this.props

    return (
      <div className='settings-personal__form-row'>
        <Image
          useBase64
          fallback={fallbacks[type]}
          file={file}
          type={type}
          onFinishUpload={this.finishUpload(type)}
          onStartUpload={this.startUpload}
          onRemove={this.resetLogo(type)}
        />
      </div>
    )
  }

  render() {
    const { saving, mobileLogo, webLogo } = this.props
    const { mobile, web, uploading } = this.state
    const canSave = mobile || web

    return (
      <>
        <SimpleBlock working={uploading || saving}>
          <Header header>{this.props.t('IconsSectionTitle')}</Header>

          <div className='settings-personal__form-row'>
            <label className='settings-personal__form-label'>
              {this.props.t('MobileAppIconTitle')}
            </label>
          </div>

          {this.renderLogo(mobile || mobileLogo, IMAGE_TYPES.mobile)}

          <div className='settings-personal__form-row theme-first-row-field'>
            <label className='settings-personal__form-label'>
              {this.props.t('MainIconTitle')}
            </label>
          </div>

          {this.renderLogo(web || webLogo, IMAGE_TYPES.web)}

          <Settings.Actions
            withChanges={canSave}
            working={uploading || saving}
            onSave={this.save}
            onCancel={this.cancel}
          />
        </SimpleBlock>
        {this.renderModal()}
      </>
    )
  }
}

const mapStateToProps = state => ({
  uk: state.init.uk.id,
  webLogo: getUkLogo(state),
  mobileLogo: getUkMobileLogo(state),
  fallbacks: {
    [IMAGE_TYPES.web]: getWebFallback(state),
    [IMAGE_TYPES.mobile]: getMobileFallback(state),
  },
  ...state.companyLogo,
})

export default compose(
  connect(mapStateToProps),
  withTranslation('Settings')
)(CompanyLogo)
