// @flow

import React from 'react'
import { QueryRenderer, graphql } from 'react-relay'
import { useSelector } from 'react-redux'
import type { Location } from 'react-router-dom'
import type { Node } from 'react'

import OffersTable from './OffersTable'
import environment from '../../createRelayEnvironment'
import Loader from '../../components/Loader'
import ErrorMessage from '../../ui/ErrorMessage'
import { useUrlParams } from '../../hooks'
import { isAdminUser } from '../../utils/utils'
import { getUser } from '../../utils/commonSelectors'
import { usePathname } from '../../hooks'

const { MarketplaceError } = ErrorMessage

type Props = { location: Location, setCount: number => void }

export const query = graphql`
  query OffersQuery(
    $page: Float!
    $store: String
    $providerName: String
    $categoryId: String
  ) {
    ...OffersTable_query
      @arguments(
        page: $page
        store: $store
        providerName: $providerName
        categoryId: $categoryId
      )
  }
`

function render({ error, props, setCount }) {
  if (error) {
    return <MarketplaceError error={error} />
  } else if (props) {
    return <OffersTable query={props} setCount={setCount} />
  } else {
    return <Loader text={false} type='big' />
  }
}

const Offers = (props: Props): Node => {
  const { location, setCount } = props

  const isLocked = usePathname()

  const { b2b, b2c, store, category_id, ...rest } = useUrlParams(location, [
    'providerName',
    'category_id',
    'store',
    'b2b',
    'b2c',
    'page',
  ])

  const categoryId = category_id || b2b || b2c

  const user = useSelector(getUser)

  const isAdmin = isAdminUser(user)

  const params = Object.assign(
    {},
    { ...rest },
    isAdmin ? (store ? { store } : { store: 'b2b' }) : undefined,
    categoryId ? { categoryId } : undefined
  )

  const variables = { ...params }

  if (isLocked || !location.search) {
    return null
  }

  return (
    <QueryRenderer
      environment={environment}
      query={query}
      variables={variables}
      render={({ error, props }) => render({ error, props, setCount })}
    />
  )
}

export default Offers
