// @flow

import { keys, difference, reduce } from 'lodash-es'

import * as actions from './FlatView.actionTypes'

const initialState = {
  initiated: false,
  data: [],
  working: false,
  complete: false,
  error: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.FLAT_VIEW_INITIATING:
    case actions.FLAT_VIEW_STATE_RESET:
      return initialState
    case actions.FLAT_VIEW_INITIATED:
      return {
        ...state,
        initiated: true,
        data: action.data,
      }
    case actions.FLAT_VIEW_UPDATE:
    case actions.FLAT_VIEW_UPDATE_GALLERY:
      return {
        ...state,
        working: true,
      }
    case actions.FLAT_VIEW_UPDATED: {
      const diffs = difference(keys(state.data), keys(action.data))

      const emptyFieldsObj = reduce(
        diffs,
        (sum, n) => {
          sum[n] = null

          return sum
        },
        {}
      )

      return {
        ...state,
        working: false,
        data: {
          ...state.data,
          ...action.data,
          ...emptyFieldsObj,
        },
      }
    }
    case actions.FLAT_VIEW_ERROR:
      return {
        ...state,
        initiated: true,
        working: false,
        error: action.error,
      }
    case actions.FLAT_VIEW_CLEAR_ERROR:
      return {
        ...state,
        error: false,
      }
    default:
      return state
  }
}
