// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { createFragmentContainer, graphql } from 'react-relay'

import { Row } from '../../../../components/Block'
import { MARKETPLACE_ROUTES } from '../../../../constants'

import styles from './OrderProfileProvider.module.scss'

const OrderProfileProvider = props => {
  const { t } = useTranslation('Orders')

  const {
    data: {
      provider: {
        id,
        image: { origin },
        name,
      },
    },
  } = props

  return (
    <Row title={t('provider')}>
      <Link
        className={styles.providerValue}
        to={MARKETPLACE_ROUTES['marketplaceProvider'](id)}
      >
        <i className='table-item__user-pic'>
          <img src={origin} />
        </i>
        <span style={{ marginLeft: 10 }}>{name}</span>
      </Link>
    </Row>
  )
}

export default createFragmentContainer(OrderProfileProvider, {
  data: graphql`
    fragment OrderProfileProvider_data on CustomerOrderNode {
      provider {
        id
        name
        image {
          origin
        }
      }
    }
  `,
})
