// @flow

import * as actions from './BuildingList.actionTypes'

const initialState = {
  initiated: false,
  buildings: [],
  meta: {},
  permissions: {},
  working: false,
  error: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.BUILDING_LIST_INITIATING:
      return initialState
    case actions.BUILDING_LIST_INITIATED:
      return {
        ...state,
        initiated: true,
        meta: action.meta,
        buildings: action.results.objects,
        permissions: action.permissions,
      }
    case actions.BUILDING_LIST_LOAD:
    case actions.BUILDING_LIST_REMOVE:
    case actions.BUILDING_LIST_REMOVE_FROM_GROUP:
    case actions.UPDATE_GROUP_BUILDINGS:
      return {
        ...state,
        working: true,
        error: false,
      }
    case actions.BUILDING_LIST_WAS_LOADED:
      return {
        ...state,
        working: false,
        meta: action.meta,
        permissions: action.permissions,
        buildings:
          action.meta.page > 1
            ? [...state.buildings, ...action.results.objects]
            : action.results.objects,
      }
    case actions.BUILDING_LIST_RESET:
      return initialState
    case actions.BUILDING_LIST_ERROR:
      return {
        ...state,
        working: false,
        error: true,
      }

    case actions.IMAGES_WAS_UPDATED: {
      const processedImages = action.updatedImages.reduce((acc, item) => {
        const { id, thumbnails } = item

        acc[id] = {
          id,
          preview: thumbnails['130'],
          '130': thumbnails['130'],
          '170': thumbnails['170'],
          '340': thumbnails['340'],
        }

        return acc
      }, {})

      return {
        ...state,
        buildings: state.buildings.map(building => {
          const imgId = building?.photo_obj?.id

          if (imgId && processedImages[imgId]) {
            return { ...building, photo_obj: processedImages[imgId] }
          }

          return building
        }),
      }
    }
    default:
      return state
  }
}
