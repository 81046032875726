// @flow

import React, { useState } from 'react'
import type { Node } from 'react'

import ActivityItemHead from './ActivityItemHead'
import ActivityItemBody from './ActivityItemBody'
import ActivityItemContext from './ActivityItem.context'

import styles from './ActivityItem.module.scss'

type Props = { activity: Object, last: boolean, phaseId: number }

const ActivityItem = (props: Props): Node => {
  const {
    activity,
    last,
    activity: { done, yesno },
    phaseId,
  } = props

  const [isDone, setDone] = useState(done)
  const [isYesNo, setYesNo] = useState(yesno)

  return (
    <div className={styles['activity-item']}>
      <ActivityItemContext.Provider value={{ activity, phaseId }}>
        <ActivityItemHead
          isDone={isDone}
          setDone={setDone}
          isYesNo={isYesNo}
          setYesNo={setYesNo}
        />
        <ActivityItemBody last={last} isYesNo={isYesNo} isDone={isDone} />
      </ActivityItemContext.Provider>
    </div>
  )
}

export default ActivityItem
