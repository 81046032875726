// @flow

import React from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import type { Node } from 'react'

import Icon from '../../../Icon'

import styles from './Conversation.module.scss'

type Props = { commentCount: number, uuid: string }

const Conversation = (props: Props): Node => {
  const { commentCount, uuid } = props

  const conversationClass = classnames(styles.conversation, {
    [styles.active]: commentCount,
  })

  return (
    <Link to={`/post/${uuid}`} className={conversationClass}>
      <Icon id='chat' />
      <span className={styles.counter}>{commentCount}</span>
    </Link>
  )
}

export default Conversation
