// @flow

import React from 'react'
import classnames from 'classnames'

import ProfileListTableHeader from './ProfileListTableHeader'
import ProfileListTableBody from './ProfileListTableBody'

const ProfileListTable = props => {
  const { loading, profiles, selected, permissions } = props

  const tableClass = classnames('table table--default', {
    'working-overlay': loading,
  })

  return (
    <div className={tableClass}>
      <div className='table__wrapper' style={{ tableLayout: 'fixed' }}>
        <ProfileListTableHeader loading={loading} />
        <ProfileListTableBody
          profiles={profiles}
          selected={selected}
          loading={loading}
          showUpdateDwellerRoleModal={props.showUpdateDwellerRoleModal}
          permissions={permissions}
          handleCheck={props.handleCheck}
          setProfiles={props.setProfiles}
        />
      </div>
    </div>
  )
}

export default ProfileListTable
