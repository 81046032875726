// @flow

import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { goBack } from 'connected-react-router'
import { useTranslation } from 'react-i18next'

import { GLOBAL_MODAL_ERROR } from '../components/Layout/Layout.constants'

const ErrorRoute = (): null => {
  const { t } = useTranslation('Modal')
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({
      type: GLOBAL_MODAL_ERROR,
      errorText: t('NeedToBeApprovedDwellerText'),
      errorTitle: t('NeedToBeApprovedDwellerTitle'),
    })

    dispatch(goBack())
  }, [])

  return null
}

export default ErrorRoute
