// @flow

import React, { useState } from 'react'
import { useLocation, useRouteMatch } from 'react-router-dom'
import { Header, SimpleBlock } from '../Block'
import { useTranslation } from 'react-i18next'
import EmptyList from '../EmptyList/EmptyList'
import RequestGallery from '../RequestGallery'
import Nbsp from '../NBSP'

import { getCurrentFilters } from '../../utils/routing'

const ConnectedRequests = () => {
  const { t } = useTranslation('Request')
  const match = useRouteMatch()
  const location = useLocation()
  const filters = getCurrentFilters(location)
  const [count, setCount] = useState(null)

  return (
    <div className='content__col'>
      <SimpleBlock className='unit unit--default'>
        <Header header>
          {t('RequestListTitle')}
          <span className='unit__title-num'>
            <Nbsp />
            {count ? count : ''}
          </span>
        </Header>
        {filters.outbound ? (
          <RequestGallery
            match={match}
            location={location}
            setCount={setCount}
          />
        ) : (
          <EmptyList
            noShowAddIcon
            icon='connect'
            title={t('NoConnectedCompanies')}
          />
        )}
      </SimpleBlock>
    </div>
  )
}

export default ConnectedRequests
