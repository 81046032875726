// @flow

import {
  TRANSLATIONS_LOADED,
  TRANSLATIONS_LOADING,
} from './translations.actionTypes'

const initialState = {
  loading: false,
  loaded: false,
  translations: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case TRANSLATIONS_LOADING:
      return { ...state, loading: true, loaded: false }
    case TRANSLATIONS_LOADED:
      return {
        ...state,
        loading: false,
        loaded: true,
        translations: action.translations,
      }
    default:
      return state
  }
}
