// @flow

import axios from 'axios'

import { getApi } from './api.core'

const GET_LANGUAGES_URL =
  'https://dev.microsofttranslator.com/languages?api-version=2.0&scope=translation'
const GET_TOKEN_URL = 'https://api.cognitive.microsoft.com/sts/v1.0/issueToken'
const TRANSLATE_URL =
  'https://api.microsofttranslator.com/V2/Ajax.svc/Translate'

const SUBSCRIPTION_KEY = '7d814cbb81f746beb6483e0c918d780d'

export const getTranslation = () => getApi('api/translate/')

export const getTranslateToken = () =>
  axios.post(GET_TOKEN_URL, null, {
    headers: { 'Ocp-Apim-Subscription-Key': SUBSCRIPTION_KEY },
  })

export const translateText = ({ from, to, text, token }) =>
  axios.get(TRANSLATE_URL, {
    params: {
      from,
      to,
      text,
      appId: `Bearer ${token}`,
    },
  })

export const getLanguages = ({ locale }) =>
  axios.get(GET_LANGUAGES_URL, { headers: { 'accept-language': locale } })
