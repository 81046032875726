// @flow

import React, { useState } from 'react'
import classNames from 'classnames'
import type { Node } from 'react'
import { useTranslation } from 'react-i18next'

import { formatDate, getUserName, getUrlForAvatar } from '../../../utils/utils'
import NewTableBodyCell from '../../NewTable/NewTableBodyCell'
import NewTableBodyRow from '../../NewTable/NewTableBodyRow'
import TableCellDone from './TableCellDone'
import InlineSvg from '../../InlineSvg'

import styles from '../../ActivityType/ActivityTypeTable/ActivityTypeTableRow.module.scss'

type Props = { onClick: Object => void }

const ActivitiesTableRow = (props: Props): Node => {
  const {
    activity: {
      priority = '',
      title,
      building,
      manager,
      deadline,
      done,
      commit_type,
      yesno,
      id,
      request: { title: requestTitle, request_no, id: requestId },
      type: {
        name,
        icon: { origin },
      },
    },
  } = props

  const { t } = useTranslation('Common')

  const requestName = `${t('NumberSymbol')}${request_no}: ${requestTitle}`

  const [isDone, setDone] = useState(done)
  const [isYesNo, setYesNo] = useState(yesno)

  const handleClick = () => props.onClick(id)

  const address = building ? building.address : '-'

  const crossed = !!(
    (commit_type === 'done' && isDone === true) ||
    (commit_type === 'yesno' && isYesNo !== null)
  )

  const iconClass = classNames({ [styles.crossed]: crossed })

  return (
    <NewTableBodyRow onClick={handleClick}>
      <NewTableBodyCell align='center'>
        <TableCellDone
          isDone={isDone}
          setDone={setDone}
          commit_type={commit_type}
          id={id}
          isYesNo={isYesNo}
          setYesNo={setYesNo}
        />
      </NewTableBodyCell>
      <NewTableBodyCell title={title} crossed={crossed}>
        <InlineSvg url={origin} alt={name} className={iconClass} />
      </NewTableBodyCell>
      <NewTableBodyCell title={getUserName(manager)} crossed={crossed}>
        <i className='table-item__user-pic'>
          <img src={getUrlForAvatar(manager)} alt='user' />
        </i>
      </NewTableBodyCell>
      <NewTableBodyCell
        title={requestName}
        crossed={crossed}
        link={`/request/${requestId}/`}
        download={false}
        target='_self'
      />
      <NewTableBodyCell title={priority?.value} crossed={crossed} />
      <NewTableBodyCell title={formatDate(deadline)} crossed={crossed} />
      <NewTableBodyCell title={address} crossed={crossed} />
    </NewTableBodyRow>
  )
}

export default ActivitiesTableRow
