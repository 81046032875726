// @flow

import { getFullThumbnailPath } from '../utils/file'

export const useThumbnail = (
  file: Object,
  size?: ?string,
  isVideo?: boolean
): ?string => {
  if (isVideo && !file.preview) {
    return null
  }

  return getFullThumbnailPath(file, size, isVideo)
}
