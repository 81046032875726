//@flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import type Node from 'react'

import Modal from '../../../Modal'
import ModalBody from '../../../Modal/ModalBody'
import ModalCloseButton from '../../../Modal/ModalCloseButton'
import ModalHead from '../../../Modal/ModalHead'
import Button from '../../../Button'
import Pagination from '../../../../ui/Pagination'
import SelectCustom from '../../../Select/SelectCustom'

import { useOverflow } from '../../../../hooks/useOverflow'
import { useThemeColor } from '../../../../hooks'

import styles from './AnalyzeModal.module.scss'

import { TEMPLATE_TYPE } from '../../../../constants'

const { DOCS } = TEMPLATE_TYPE
const PAGE_SIZE = 10

const MODE_MAIN = 'main'
const MODE_REPORT = 'report'

type Props = {
  isOpen: boolean,
  onClose: () => void,
  onDeleteDoc?: () => void,
  onDeleteEmails?: (Array<number>) => void,
  onEditExternal?: () => void,
  recipients: Array<Object>,
  report: Array<Object>,
  templateType?: string,
}

const VariableItem = ({ name, variable }) => (
  <div className={styles.variable}>
    <div>{name}</div>
    <div>{variable}</div>
  </div>
)

const AnalyzeModal = (props: Props): Node => {
  const { report, recipients, isOpen, onClose, templateType } = props

  const { t } = useTranslation('Mail')

  const [mode, setMode] = useState(MODE_MAIN)
  const [hint, setHint] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)

  useOverflow()
  const themeColor = useThemeColor()

  const handleDelete = () => {
    if (props.onDeleteEmails) {
      const removeEmails = [...new Set(report.map(r => r.email))]
      const removeIds = [...new Set(report.map(r => r.recipient))]
      props.onDeleteEmails(
        recipients.filter(
          r =>
            !removeEmails.includes(
              r.profile?.email || r.name || r.profile.printer
            ) && !removeIds.includes(r.profile?.id)
        )
      )
      onClose()
    }
  }

  const handleEditExternal = () => {
    if (props.onEditExternal) {
      props.onEditExternal()
    }

    onClose()
  }

  const handleDeleteDoc = () => {
    if (props.onDeleteDoc) {
      props.onDeleteDoc()
    }

    onClose()
  }

  const handleReport = () => setMode(MODE_REPORT)
  const handleCloseReport = () => setMode(MODE_MAIN)
  const handleMouseEnter = () => setHint(true)
  const handleMouseLeave = () => setHint(false)

  const getRecipientName = (id, email) => {
    const [recipient] = recipients.filter(
      r => r.profile?.id === id || r.name === email
    )

    if (recipient) {
      return recipient.profile?.fullname || recipient.name
    }

    return t('AllRecipients')
  }

  const getVariable = variable =>
    t(variable) === `Mail.${variable}` ? variable : t(variable)

  const getActions = () => {
    let actions = []

    if (report.some(r => r.external)) {
      actions.push({
        value: 'editExternal',
        label: t('EditExternalVars'),
        handler: handleEditExternal,
      })
    }

    if (report.some(r => r.recipient || r.email)) {
      actions.push({
        value: 'deleteEmails',
        label: t('DeleteEmails'),
        handler: handleDelete,
      })
    }

    if (templateType === DOCS) {
      actions.push({
        value: 'deleteDoc',
        label: t('DeleteDoc'),
        handler: handleDeleteDoc,
      })
    }

    return actions
  }

  const actionButtons = getActions()

  const preventOpen = e => {
    e.stopPropagation()
  }

  return (
    <>
      <Modal isOpen={isOpen} onRequestClose={onClose}>
        <ModalHead
          title={
            mode === MODE_MAIN ? t('VariablesNotFound') : t('VariablesReport')
          }
        />
        <ModalCloseButton onClose={onClose} />
        <ModalBody
          className={styles.analyze}
          style={{
            maxHeight: '600px',
            width: '800px',
            '--themeColor': themeColor,
          }}
        >
          {mode === MODE_MAIN && (
            <>
              <div>{t('VariablesNotFoundText')}</div>
              <div className={styles.actions}>
                <div onClick={handleReport}>{t('ViewReport')}</div>
                <div
                  className={styles.hint}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  {t('Hint')}
                  {hint && (
                    <div className={styles.tooltip}>
                      <div />
                      <span>{t('VariablesHintText1')}</span>
                      <span>{t('VariablesHintText2')}</span>
                      <span>{t('VariablesHintText3')}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.buttons}>
                <Button.Save onClick={onClose}>{t('YesButton')}</Button.Save>
                {actionButtons.length > 1 ? (
                  <SelectCustom
                    options={actionButtons}
                    onClick={preventOpen}
                    onChange={opt => opt.handler()}
                  >
                    {t('SelectAction')}
                  </SelectCustom>
                ) : (
                  actionButtons.length === 1 && (
                    <Button.Cancel
                      className='messages__tools-send'
                      onClick={actionButtons[0].handler}
                    >
                      {actionButtons[0].label}
                    </Button.Cancel>
                  )
                )}
              </div>
            </>
          )}
          {mode === MODE_REPORT && (
            <>
              <div className={styles.report}>
                <div className={styles.variablesHeader}>
                  <div>{t('VariableUser')}</div>
                  <div>{t('VariableName')}</div>
                </div>
                {report
                  .slice(PAGE_SIZE * (currentPage - 1), PAGE_SIZE * currentPage)
                  .map(r => (
                    <VariableItem
                      key={`${r?.recipient || ''}_${r.variable}`}
                      name={
                        r.external
                          ? t('AllRecipients')
                          : getRecipientName(r.recipient, r.email)
                      }
                      variable={getVariable(r.variable)}
                    />
                  ))}
                <div className={styles.paginator}>
                  {report.length > PAGE_SIZE && (
                    <Pagination
                      noScroll
                      currentPage={currentPage}
                      pageSize={PAGE_SIZE}
                      totalCount={report.length}
                      onPageChange={setCurrentPage}
                    />
                  )}
                </div>
              </div>
              <div className='messages__tools-submit'>
                <Button.Cancel onClick={handleCloseReport}>
                  {t('CloseButton')}
                </Button.Cancel>
              </div>
            </>
          )}
        </ModalBody>
      </Modal>
    </>
  )
}

export default AnalyzeModal
