// @flow

import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer, graphql } from 'react-relay'
import { useLocation } from 'react-router-dom'

import { getCurrentPage } from '../../utils/routing'
import BottomPagination from '../../components/Pagination/BottomPagination'
import Content from '../../components/Content'
import TileList from '../../components/TileList'
import OfferItem from './OfferItem'
import EmptyList from '../../components/EmptyList'

type Props = {
  categoryId: number,
  history: Object,
  pageSize: number,
  query: Object,
  refetch: (Object, null, () => void) => void,
  relay: Object,
  setCount: number => void,
}

const OffersTable = (props: Props) => {
  const {
    query: {
      allOffers: { edges: list, totalCount },
    },
  } = props

  const { t } = useTranslation('Offers')

  const location = useLocation()

  const page = getCurrentPage(location)

  useEffect(() => {
    props.setCount && props.setCount(totalCount)
  }, [totalCount])

  return (
    <Content>
      <TileList rowLength={5}>
        {list.map(offer => (
          <OfferItem offer={offer} key={offer.node.id} />
        ))}
      </TileList>
      {!totalCount && <EmptyList icon='order' title={t('noOffersFound')} />}
      <BottomPagination
        classes='paginator-2'
        meta={{
          curr_page: parseInt(page),
          page_count: Math.ceil(totalCount / 20),
        }}
      />
    </Content>
  )
}

export default createFragmentContainer(OffersTable, {
  query: graphql`
    fragment OffersTable_query on Query
    @argumentDefinitions(
      pageSize: { type: Float }
      page: { type: Float }
      first: { type: Int }
      store: { type: String }
      providerName: { type: String }
      categoryId: { type: String }
    ) {
      allOffers(
        page: $page
        store: $store
        providerName: $providerName
        categoryId: $categoryId
      ) {
        totalCount
        edges {
          node {
            id
            name
            finalPrice
            orderCount
            provider {
              name
              id
              image {
                origin
              }
            }
            gallery {
              id
              origin
              default
              thumbnails {
                url
                size
              }
            }
          }
        }
      }
    }
  `,
})
