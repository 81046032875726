// @flow

import React from 'react'
import m from 'moment'
import { connect } from 'react-redux'

import { getDateFormat } from '../../utils/commonSelectors'
import { getFormattedDate } from '../../utils/utils'

type Props = {
  className?: string,
  dateFormat: string,
  dateTime: string,
  defaultDateFormat: string,
  newFormatting: boolean,
}

const DateTime = ({
  dateTime,
  dateFormat,
  defaultDateFormat,
  newFormatting,
  className,
}: Props) => {
  const date = dateTime ? m(dateTime) : m()

  const formattedDate = newFormatting
    ? getFormattedDate(date, { specifyHours: true, specifyYear: true })
    : date.format(dateFormat || defaultDateFormat)

  let count = 0

  return (
    <span style={{ whiteSpace: 'normal' }} className={className}>
      {formattedDate.replace(/(?!^) /g, match => {
        count += 1

        return count === 1 ? match : '\u00a0'
      })}
    </span>
  )
}

const mapStateToProps = state => ({
  defaultDateFormat: getDateFormat(state),
})

export default connect(mapStateToProps)(DateTime)
