// @flow

import React, { useState } from 'react'
import { isNil } from 'lodash-es'
import classnames from 'classnames'
import type { Node } from 'react'

import { getFileById } from '../../core/api/api.file'

import styles from './NewTable.module.scss'

type Props = {
  align: string,
  children?: Node,
  className?: string,
  crossed?: boolean,
  link?: ?string,
  style?: Object,
  text?: string,
  title?: string,
  titleStyle?: Object,
}

const NewTableBodyCell = (props: Props): Node => {
  const {
    title,
    children,
    align,
    link,
    text,
    style,
    titleStyle,
    className,
    crossed,
    contClass,
    download,
    target,
    bold,
    colSpan,
    linkId,
  } = props

  const [working, setWorking] = useState(false)

  const cellClass = classnames(styles.tableBodyCell, className)
  const divClass = classnames('table-item table-item__text', {
    crossed,
    bold,
  })

  const tdProps = Object.assign(
    {},
    style ? { style } : undefined,
    text ? { title: text } : undefined
  )

  const containerClass = classnames(styles.tableBodyCellWrapper, contClass)

  const handleClick = e => e.stopPropagation()

  const handleClickLink = () => {
    if (props.usePropsCallback) {
      props.onClickLink()
    } else {
      if (!working) {
        setWorking(true)
        getFileById({ file_ids: linkId, scope: 'tree' }).then(updatedFile => {
          window.open(updatedFile[0].origin, '_blank')
          setWorking(false)
        })
      }
    }
  }

  const divProps = Object.assign(
    {},
    link ? { onClick: handleClick } : undefined
  )

  return (
    <td className={cellClass} {...tdProps} colSpan={colSpan}>
      <div className={containerClass} style={{ justifyContent: align }}>
        {children}
        {!isNil(title) && (
          <div
            className={divClass}
            style={titleStyle}
            title={title}
            {...divProps}
          >
            {link ? (
              <a
                download={download}
                target={target}
                href={link}
                rel='noopener noreferrer'
              >
                {title}
              </a>
            ) : linkId ? (
              <span className={styles.link} onClick={handleClickLink}>
                {title}
              </span>
            ) : (
              <span>{title}</span>
            )}
          </div>
        )}
      </div>
    </td>
  )
}

NewTableBodyCell.defaultProps = {
  align: 'flex-start',
  download: true,
  target: '_blank',
}

export default NewTableBodyCell
