// @flow

import React from 'react'
import type { Node } from 'react'

import Warning from '../Warning'
import Cell from './Cell'

import rowStyles from './Row.module.scss'
import styles from './Hint.module.scss'

type Props = {
  text: string,
}

const SettingsHint = ({ text }: Props): Node => (
  <div className={rowStyles.row}>
    <Cell />
    <Cell>
      <Warning
        text={text}
        boxClassName={styles.box}
        className={styles.warning}
      />
    </Cell>
  </div>
)

export default SettingsHint
