// @flow

import React from 'react'
import type { Node } from 'react'

import { Cell } from '../../../Block'
import DateTime from '../../../DateTime'

type Props = {
  joined?: ?string,
  title: string,
}

const ProfileDate = (props: Props): Node => {
  const { joined, title } = props

  return (
    <Cell title={title}>{joined ? <DateTime dateTime={joined} /> : '-'}</Cell>
  )
}

export default ProfileDate
