// @flow

import React, { Component } from 'react'
import { push } from 'connected-react-router'
import { Redirect } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'
import { isNil, omitBy, get } from 'lodash-es'
import { connect } from 'react-redux'
import { compose } from 'redux'

import { DocumentsEditing } from '../Documents'
import { getUser, showMarketplace } from '../../utils/commonSelectors'
import RequestCreateInfo from './RequestCreateInfo'
import RequestBody from '../Request/RequestBody'
import { removeFile } from '../../utils/file'
import { clearBr } from '../../utils/text'
import { isStaffUser, getUserId, userCanCreateRequest } from '../../utils/utils'
import * as actions from './RequestCreate.actionTypes'
import { USER_GROUPS } from '../../constants'
import { getProfileTiny } from '../../core/api/api.profile'
import { getOutboundUserProfile } from '../../core/api/api.idwell2idwell'

const FILES_ROW_LENGTH = 4

type Props = {
  dispatch: Object => void,
  history: Object,
  id: number,
  isMarketplaceEnabled: boolean,
  location: Object,
  t: string => string,
  user: Object,
  working: boolean,
}

type State = {
  assignees: Array<Object>,
  building: any,
  contractors: Array<Object>,
  dueDate: any,
  files: Array<Object>,
  filesUploading: boolean,
  flat: any,
  flatError: boolean,
  labels: Array<string>,
  order?: Object,
  order_ids: Array<string>,
  owner: any,
  type: any,
}

class RequestCreate extends Component<Props, State> {
  constructor(props) {
    super(props)

    const { data } = props

    let stateObj = {
      assignees: [],
      building: null,
      dueDate: '',
      files: [],
      labels: [],
      filesUploading: false,
      flat: null,
      owner: null,
      type: null,
      contractors: [],
      contractor_ids: [],
      order_ids: [],
      relatedRequests: [],
      flatError: false,
    }

    if (data) {
      stateObj = { ...stateObj, ...data }
    }

    this.state = stateObj
  }

  componentDidMount() {
    const { user, data } = this.props
    // Set current user as request initiator if user role is dweller or user of outbound company if idwell2idwell
    const profileApi = data?.outbound
      ? id => getOutboundUserProfile(data.outbound, id)
      : getProfileTiny
    const userId =
      data?.outbound && data?.inbound_user
        ? data.inbound_user
        : user && user.group === USER_GROUPS.dweller
        ? user.owner
        : null

    if (userId) {
      profileApi(userId).then(data => {
        const newState = Object.assign(
          {},
          { owner: data },
          data.flats_count > 1
            ? undefined
            : {
                building: { address: data.address },
                flat: { number: data.flat_number },
              }
        )
        this.setState(newState)
      })
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { id, data } = this.props

    const { flat } = this.state

    // Redirect to request list if request created successfully
    if (id && !prevProps.id) {
      this.props.dispatch(
        push(
          data?.outbound
            ? `/connected_requests/?outbound=${data.outbound}`
            : '/requests/'
        )
      )

      return
    }

    // Clean error for required field flat if flat is selected.
    if (flat && !prevState.flat) {
      this.setState({ flatError: false })
    }
  }

  componentWillUnmount() {
    const { id } = this.props

    // Remove unbind files from server if request not created.
    if (!id) {
      const { files } = this.state

      files.forEach(f => removeFile(f.id))
    }

    this.props.dispatch({ type: actions.REQUEST_CREATE_RESET })
  }

  getOwner = () => {
    const { owner } = this.state

    if (owner) {
      return getUserId(owner)
    }

    const { user } = this.props

    return isStaffUser(user) ? getUserId(user) : null
  }

  getBuilding = () => {
    const { building, owner } = this.state

    const flat = this.getFlat()

    if (flat) {
      return null
    }

    return get(building, 'id') || get(owner, 'building_id') // TODO Obscure check
  }

  getFlat = () => {
    const { flat, owner } = this.state

    return get(flat, 'id') || get(owner, 'flat_id') // TODO Obscure check
  }

  update = data => {
    this.setState(prevState => ({
      ...prevState,
      ...data,
    }))
  }

  updateType = data => {
    this.setState(prevState => ({
      ...prevState,
      type: data,
    }))
  }

  updateOwner = data => {
    this.setState(prevState => ({
      ...prevState,
      owner: data,
    }))
  }

  startFilesUpload = () => {
    this.setState({ filesUploading: true })
  }

  updateFiles = (files: Array<Object>) => {
    this.setState({
      files,
      filesUploading: false,
    })
  }

  removeFile = ({ id }: Object) => {
    this.setState(state => ({
      files: state.files.filter(f => f.id !== id),
    }))

    removeFile(id)
  }

  save = ({ title, text }) => {
    const {
      assignees,
      files,
      type,
      dueDate,
      order_ids,
      contractors,
      notify_contractors,
      labels,
      relatedRequests,
    } = this.state
    const { data } = this.props

    const formattedFiles = JSON.stringify(files.map(f => f.id))
    const formattedAssignees = JSON.stringify(
      assignees.map(({ id, fullname }) => id || fullname)
    )

    const requestType = type ? type.id : undefined

    const notifyContractors = contractors?.length ? { notify_contractors } : {}

    const params = {
      assignees: formattedAssignees,
      building: this.getBuilding(),
      owner: this.getOwner(),
      flat: this.getFlat(),
      title,
      text: clearBr(text),
      files: formattedFiles,
      request_type: requestType,
      order_ids,
      contractors: contractors.map(c => c.id),
      ...notifyContractors,
    }

    if (isStaffUser(this.props.user) && !data?.outbound) {
      params.labels = labels.map(l => l.id)
      params.due_date = dueDate
    }

    if (data?.outbound) {
      params.outbound = data.outbound
    }

    this.props.dispatch({
      type: actions.REQUEST_CREATE_CREATE,
      params: omitBy(params, p => isNil(p)),
      relatedRequests,
    })
  }

  setFlatError = flatError => {
    this.setState({ flatError })
  }

  isSaveButtonDisabled = () => {
    // const groupsWithoutFlat = [USER_GROUPS.admin, USER_GROUPS.manager]
    const { working } = this.props
    const { owner, flat, filesUploading } = this.state

    // Dweller assign as request initiator and flat not selected
    const dwellerCondition = !!(
      owner &&
      owner.group === USER_GROUPS.dweller &&
      !flat &&
      owner.flats_count !== 1
    )

    return dwellerCondition || filesUploading || working
  }

  isCancelButtonDisabled = () => {
    const { working } = this.props

    const { filesUploading } = this.state

    return filesUploading || working
  }

  render() {
    const { working, user, isMarketplaceEnabled, history, data } = this.props

    const {
      dueDate,
      labels,
      owner,
      files,
      assignees,
      building,
      type,
      flat,
      order_ids,
      contractors,
      flatError,
      relatedRequests,
    } = this.state

    if (!userCanCreateRequest(user)) {
      return <Redirect to='/404' />
    }

    const documentsClass = classnames('unit', 'unit--default', {
      'working-overlay': working,
    })

    return (
      <div>
        <RequestCreateInfo
          user={user}
          type={type}
          history={history}
          building={building}
          flat={flat}
          labels={labels}
          owner={owner}
          working={working}
          order_ids={order_ids}
          contractors={contractors}
          assigneeList={assignees}
          flatError={flatError}
          dueDate={dueDate}
          isMarketplaceEnabled={isMarketplaceEnabled}
          relatedRequests={relatedRequests}
          outbound={data?.outbound}
          onChange={this.update}
          onChangeContractors={contractors => this.setState({ contractors })}
          onChangeOwner={this.updateOwner}
          onChangeType={this.updateType}
        />
        <RequestBody
          isSaveButtonDisabled={this.isSaveButtonDisabled()}
          working={working}
          setFlatError={this.setFlatError}
          isCancelButtonDisabled={this.isCancelButtonDisabled()}
          onSave={this.save}
        />
        <section className={documentsClass} style={{ marginTop: '20px' }}>
          <h2 className='unit__title'>{this.props.t('Files')}</h2>
          <DocumentsEditing
            canRemove
            multiple
            files={files}
            outbound={data?.outbound}
            rowLength={FILES_ROW_LENGTH}
            onRemove={this.removeFile}
            onStartUpload={this.startFilesUpload}
            onUpdate={this.updateFiles}
          />
        </section>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state.requestCreate,
  isMarketplaceEnabled: showMarketplace(state),
  user: getUser(state),
})

export default compose(
  withTranslation('Request'),
  connect(mapStateToProps)
)(RequestCreate)
