// @flow

import React, { Component } from 'react'
import classnames from 'classnames'

import Check from '../../../../static/icons/check.svg'
import Cross from '../../../../static/icons/cross.svg'

import styles from './Button.module.scss'

type Props = {
  accept?: boolean,
  id: number,
  onClick: (number, boolean) => void,
}

class Button extends Component<Props> {
  handleClick = () => {
    const { id, accept } = this.props
    this.props.onClick(id, Boolean(accept))
  }

  render() {
    const { accept } = this.props

    const btnClass = classnames(styles.button, {
      [styles.accept]: accept,
      [styles.decline]: !accept,
    })

    return (
      <div className={btnClass} onClick={this.handleClick}>
        {accept ? <Check /> : <Cross />}
      </div>
    )
  }
}

export default Button
