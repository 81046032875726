// @flow

import React, { useEffect, useState, useRef } from 'react'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { bicRegExp } from '../../../../utils/utils'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import * as Yup from 'yup'
import { partial } from 'lodash-es'
import NumberFormat from 'react-number-format'
import InvoicesModalDate from './InvoicesModalDate'
import type { Node } from 'react'

import { PDF_TYPE } from '../../../../utils/file'

import InputField from '../../../InputField'
import NewSelectSimple from '../../../NewSelectSimple'
import NewSelectAsync from '../../../NewSelectAsync'
import SelectCustom from '../../../Select/SelectCustom'
import { useOverflow } from '../../../../hooks'
import Button from '../../../Button'
import Modal from '../../../Modal'
import ModalLabel from '../../../Modal/ModalLabel'
import ModalBody from '../../../Modal/ModalBody'
import ModalButtons from '../../../Modal/ModalButtons'
import ModalCloseButton from '../../../Modal/ModalCloseButton'
import ModalHead from '../../../Modal/ModalHead'
import { INVOICE_FILE } from './InvoicesModal.constants'
import { getNormalizedValues } from './InvoicesModal.utils'
import {
  createInvoice,
  updateInvoice,
  getSuppliers,
  getDomondaSettings,
  domondaParseInvoice,
  domondaCheckParse,
} from '../../../../core/api/api.budgetPlan'

import DocumentPreview from '../../../MappedDocument/DocumentPreview'
import NewFilesUpload from '../../../NewFilesUpload'
import Icon from '../../../Icon'
import Loader from '../../../Loader'

import { getById, getFile } from '../../../../core/api/api.file'

import { FILE_ACCEPT_TYPES } from '../../../../containers/FilesUpload'
import { DEBITOR_IBAN_MAX_LENGTH } from '../../../BudgetPlanSettings/BudgetPlanSettings.constants'

import styles from './InvoicesModal.module.scss'

const DOMONDA_API_KEY = 'domonda_api_key'
const PARSE_POLLING_INTERVAL = 1000
const PARSE_STATUS_PENDING = 'PENDING'
const PARSE_STATUS_STARTED = 'STARTED'
const PARSE_STATUS_SUCCESS = 'SUCCESS'
const PARSE_MAX_ATTEMPTS = 30

type Props = {
  editingInvoice: ?Object,
  fields?: Array<Object>,
  fileId?: number,
  onClose: Function,
  planId?: number,
  reload?: Function,
  requestId?: number,
  setEditingInvoice: Function,
  setInvoiceFields?: Function,
}

const InvoicesModal = (props: Props): Node => {
  const { editingInvoice, planId, requestId, fileId, fields } = props

  useOverflow()
  const dispatch = useDispatch()

  const ref = useRef()

  const [docLoading, setDocLoading] = useState(false)
  const [doc, setDoc] = useState(null)

  const [domondaAPIkey, setDomondaAPIkey] = useState(null)
  const [docParsing, setDocParsing] = useState(false)
  const [parseError, setParseError] = useState(false)

  const [docFile, setDocFile] = useState(null)
  const [supplier, setSupplier] = useState(null)

  useEffect(() => {
    if (editingInvoice && editingInvoice.invoice_file) {
      setDocLoading(true)
      setDoc(editingInvoice.invoice_file)
    }

    return function cleanup() {
      if (editingInvoice) {
        props.setEditingInvoice(null)
      }

      if (props.setInvoiceFields) {
        props.setInvoiceFields(null)
      }
    }
  }, [])

  useEffect(() => {
    if (fileId) {
      getFile(fileId).then(data => setDoc(data))
    }
  }, [fileId])

  useEffect(() => {
    setSupplier(
      editingInvoice?.fields?.find(f => f.name === 'supplier_id')?.value || null
    )
  }, [editingInvoice])

  const { t } = useTranslation('BudgetPlan')

  const getInitialValuesNew = () =>
    fields.reduce(
      (acc, field) => {
        let value = editingInvoice?.fields?.find(
          f => f.name === field.name
        )?.value

        if (field.name === 'supplier_id') {
          value = value && value.owner_id
        }

        const initialValue = value !== undefined && value !== null ? value : ''

        return {
          ...acc,
          [field.name]: initialValue,
        }
      },
      {
        [INVOICE_FILE]:
          (editingInvoice && editingInvoice[INVOICE_FILE]?.file) ||
          fileId ||
          null,
      }
    )

  const getSchemaValue = field => {
    const fieldData = fields.find(f => f.name === field)
    let ret = undefined

    if (!fieldData || fieldData.type === 'readonly') {
      return ret
    }

    switch (fieldData.type) {
      case 'int':
      case 'float':
      case 'select':
        ret = Yup.number()
        break
      case 'date':
        ret = Yup.string().nullable()
        break
      case 'str':
      case 'supplier':
        ret = Yup.string()

        if (fieldData.validation) {
          if (fieldData.validation.min) {
            ret = ret.min(
              fieldData.validation.min,
              t('FieldMinLength', { min: fieldData.validation.min })
            )
          }

          if (fieldData.validation.max) {
            ret = ret.max(
              fieldData.validation.max,
              t('FieldMaxLength', { max: fieldData.validation.max })
            )
          }
        }

        break
      case 'iban':
        ret = Yup.string().max(DEBITOR_IBAN_MAX_LENGTH, t('IbanAllowedLength'))
        break
      case 'bic':
        ret = Yup.string().matches(bicRegExp, t('BicAllowedLength'))
        break
    }

    if (fieldData.required) {
      ret = ret.required(t('RequiredField'))
    }

    return ret
  }

  const handleChangePaymentDate = (field, date) =>
    formik.setFieldValue(field, date)
  const handleChangeOption = (field, value) => {
    formik.setFieldValue(field, value)
  }

  const getFieldInput = field => {
    let ret = null
    switch (field.type) {
      case 'int':
      case 'float':
        ret = (
          <NumberFormat
            fixedDecimalScale
            isNumericString
            disabled={docParsing}
            placeholder={field.type === 'float' ? '0,00' : '0'}
            customInput={InputField}
            decimalSeparator={field.type === 'float' && ','}
            thousandSeparator='.'
            decimalScale={(field.type === 'float' && 2) || 0}
            value={formik.values[field.name]}
            name={field.name}
            error={formik.errors[field.name]}
            onValueChange={values =>
              formik.setFieldValue(field.name, values.value)
            }
          />
        )
        break
      case 'str':
      case 'bic':
      case 'iban':
        ret = (
          <InputField
            disabled={docParsing}
            name={field.name}
            value={formik.values[field.name]}
            placeholder={t(field.label)}
            error={formik.errors[field.name]}
            onChange={formik.handleChange}
          />
        )
        break
      case 'date':
        ret = (
          <InvoicesModalDate
            disabled={docParsing}
            error={formik.errors[field.name]}
            payment_date={formik.values[field.name]}
            placeholder={t(field.label)}
            onChangeDate={date => handleChangePaymentDate(field.name, date)}
          />
        )
        break
      case 'select':
        ret = (
          <NewSelectSimple
            disabled={docParsing}
            name={field.name}
            options={field.options}
            value={field.options.find(
              option => option.value === formik.values[field.name]
            )}
            placeholder={t(field.label)}
            error={formik.errors[field.name]}
            onChange={option => handleChangeOption(field.name, option.value)}
          />
        )
        break
      case 'supplier':
        ret = (
          <NewSelectAsync
            disabled={docParsing}
            className={styles.select}
            pageSize={10}
            error={formik.errors[field.name]}
            idField='owner_id'
            api={getSuppliers}
            searchKey='search'
            minSearchLength={1}
            placeholder={t(field.label)}
            getLabel={s => s.fullname}
            getSelectedLabel={s => s.fullname}
            view='dropdown'
            selectedItems={supplier ? [supplier] : []}
            onClick={value => {
              formik.setFieldValue(field.name, value.owner_id)
              setSupplier(value)
            }}
          />
        )
        break
    }

    return ret
  }

  const formik = useFormik({
    validateOnChange: true,
    enableReinitialize: true,
    initialValues: getInitialValuesNew(),
    validationSchema: Yup.object(
      fields.reduce((acc, field) => {
        return { ...acc, [field.name]: getSchemaValue(field.name) }
      }, {})
    ),
    onSubmit: submitValues => {
      const normalizedValues = getNormalizedValues(
        formik.initialValues,
        submitValues,
        fields.map(checkDepends)
      )

      const invoice_file = fileId || normalizedValues[INVOICE_FILE]
      delete normalizedValues[INVOICE_FILE]

      const values = {
        invoice_file,
        fields: normalizedValues,
        budget_plan: planId || undefined,
        request: requestId || undefined,
      }

      const api = editingInvoice
        ? partial(updateInvoice, editingInvoice.id)
        : createInvoice

      api(values).then(data => {
        props.onClose()

        if (requestId) {
          dispatch(push(`/budget-plan/${data.budget_plan}`))
        } else if (props.reload) {
          props.reload()
        }
      })
    },
  })

  const setDefaultFormValues = () => {
    fields.forEach(f => {
      if (f.default !== null && f.default !== undefined) {
        formik.setFieldValue(f.name, f.default)
      }
    })
  }

  const checkDepends = field => {
    const { field: parentField, values } = field.depends

    if (!parentField) {
      return field
    }

    const parentValue = values.find(v => v.value === formik.values[parentField])

    return parentValue?.params ? { ...field, ...parentValue?.params } : field
  }

  useEffect(() => {
    if (!editingInvoice) {
      setTimeout(setDefaultFormValues, 100)
    }
  }, [])

  useEffect(() => {
    if (doc) {
      const { id, file } = doc
      const currentId = id || file

      getById(currentId).then(res => {
        formik.setFieldValue(INVOICE_FILE, currentId)
        setDocFile(res)
        setDocLoading(false)
        getDomondaSettings().then(data => {
          setDomondaAPIkey((data && data[DOMONDA_API_KEY]) || null)
        })
      })
    } else {
      setDocFile(null)
    }
  }, [doc])

  useEffect(() => {
    if (domondaAPIkey && (fileId || doc?.id)) {
      parseDoc()
    }
  }, [domondaAPIkey, doc])

  const modalTitle = editingInvoice ? 'EditInvoiceTitle' : 'CreateInvoiceTitle'
  const modalSaveButton = editingInvoice
    ? 'EditInvoiceButton'
    : 'CreateInvoiceButton'

  const docUploaded = doc => {
    if (doc?.length) {
      setDoc(doc[0])
    } else {
      setDocLoading(false)
    }
  }

  const handleCancel = e => {
    if (formik.dirty) {
      formik.handleReset(e)

      if (!fileId) {
        setDoc(editingInvoice?.invoice_file || null)
      }
    } else {
      props.onClose()
    }
  }

  const handleDeleteDoc = () => {
    setDoc(null)
    formik.setFieldValue(INVOICE_FILE, null)
  }

  const handleReplaceDoc = () => {
    ref.current.click()
  }

  const getDocActions = () => {
    return [
      {
        value: 'replaceDoc',
        label: t('ReplaceButton'),
        handler: () => handleReplaceDoc(),
        icon: 'restore',
      },
      {
        value: 'deleteDoc',
        label: t('DeleteButton'),
        handler: () => handleDeleteDoc(),
        icon: 'bin',
      },
    ]
  }

  const preventOpen = e => {
    e.stopPropagation()
  }

  const parseDoc = () => {
    setParseError(false)
    setDocParsing(true)

    const { id, file } = doc
    const invoice_file = id || file

    domondaParseInvoice({ invoice_file })
      .then(data => {
        if (data?.eventId) {
          checkParse(data.eventId, 1)
        } else {
          setParseError('FieldsNotParsed')
          setDocParsing(false)
        }
      })
      .catch(err => {
        setParseError(err.errors || 'FieldsNotParsed')
      })
  }

  const checkParse = (eventId, parseAttempt) => {
    domondaCheckParse(eventId)
      .then(data => {
        if (data?.status === PARSE_STATUS_SUCCESS) {
          const { result } = data

          if (result !== undefined) {
            for (let field in result) {
              if (formik.values[field] !== undefined) {
                formik.setFieldValue(field, result[field])
              }
            }
          }

          setDocParsing(false)
        } else if (parseAttempt >= PARSE_MAX_ATTEMPTS) {
          setParseError('DomondaRequestTimeout')
          setDocParsing(false)
        } else if (
          data?.status === PARSE_STATUS_PENDING ||
          data?.status === PARSE_STATUS_STARTED
        ) {
          setTimeout(
            () => checkParse(eventId, parseAttempt + 1),
            PARSE_POLLING_INTERVAL
          )
        } else {
          setParseError('FieldsNotParsed')
          setDocParsing(false)
        }
      })
      .catch(err => {
        setParseError(err.errors || 'FieldsNotParsed')
        setDocParsing(false)
      })
  }

  return (
    <>
      <Modal isOpen className={styles.modal} onRequestClose={props.onClose}>
        <ModalHead title={t(modalTitle)} />
        <ModalCloseButton onClose={props.onClose} />
        <form onSubmit={formik.handleSubmit}>
          <ModalBody className={styles.invoicesModal}>
            <div className={styles.column}>
              {fields
                .map(checkDepends)
                .filter(field => field.type !== 'readonly')
                .sort((a, b) => (a.order > b.order ? 1 : -1))
                .map(
                  (field, i) =>
                    field.visible && (
                      <React.Fragment key={i}>
                        <ModalLabel className={styles.label}>
                          {t(field.label)}
                          {field.required ? (
                            <span style={{ color: 'red' }}>*</span>
                          ) : (
                            ''
                          )}
                        </ModalLabel>
                        {getFieldInput(field)}
                      </React.Fragment>
                    )
                )}
            </div>
            <div className={styles.column}>
              {docLoading ? (
                <Loader className={styles.loader} text={false} type='big' />
              ) : (
                <DocumentPreview
                  fileOrigin={docFile}
                  fileType={PDF_TYPE}
                  height={550}
                  outerWidth={750}
                  placeholder={t('InvoiceDocumentPlaceholder')}
                  width={350}
                />
              )}
            </div>
          </ModalBody>
          <ModalButtons className={styles.buttons}>
            <Button.Save
              type='submit'
              disabled={!formik.dirty || docLoading || docParsing}
            >
              {t(modalSaveButton)}
            </Button.Save>
            <Button.Cancel onClick={handleCancel}>
              {t('Common:Cancel')}
            </Button.Cancel>
            <div className={styles.uploadBtns}>
              {!fileId && (
                <NewFilesUpload
                  accept={FILE_ACCEPT_TYPES.pdf}
                  onStartUpload={() => setDocLoading(true)}
                  onFinishUpload={docUploaded}
                >
                  {!doc && (
                    <Button.Save icon='request' working={docLoading}>
                      {t('UploadButton')}
                    </Button.Save>
                  )}
                  {doc && <span ref={ref}></span>}
                </NewFilesUpload>
              )}
              {doc && (
                <>
                  {parseError && (
                    <span className={styles.parseError}>
                      <Icon id='danger' />
                      {t(parseError)}
                    </span>
                  )}
                  {domondaAPIkey && (
                    <Button.Save
                      working={docParsing}
                      disabled={docLoading}
                      onClick={parseDoc}
                    >
                      {t('Parse')}
                    </Button.Save>
                  )}
                  {!fileId && (
                    <SelectCustom
                      options={getDocActions()}
                      style={{ marginRight: '-10px', marginLeft: '-3px' }}
                      onClick={preventOpen}
                      onChange={opt => opt.handler()}
                    >
                      {t('Actions')}
                    </SelectCustom>
                  )}
                </>
              )}
            </div>
          </ModalButtons>
        </form>
      </Modal>
    </>
  )
}

export default InvoicesModal
