// @flow

import { pick } from 'lodash-es'
import type { Location } from 'react-router-dom'

import { getCurrentFilters } from '../utils/routing'

export const useUrlParams = (
  location: Location,
  filterList: Array<string>
): Object => {
  const filters = getCurrentFilters(location)

  return pick(filters, filterList)
}
