// @flow

import React from 'react'
import { useEffect } from 'react'
import { replace } from 'connected-react-router'
import { connect } from 'react-redux'
import { parse, stringify } from 'qs'
import { isEmpty, isEqual } from 'lodash-es'

import { getLocation, getUser } from '../utils/commonSelectors'
import {
  formatPathname,
  getCurrentFilters,
  getNewQueryString,
} from '../utils/routing'
import BrowserStorage from '../utils/browserStorage'
import { getUserId } from '../utils/utils'

const addRedirectToFirstPage = WrappedComponent => {
  const Redirect = props => {
    const { location, replace, user } = props
    const { pathname } = location

    useEffect(() => {
      const userId = getUserId(user)
      const { page = 1, ...filters } = getCurrentFilters(location)
      const storagePath =
        userId &&
        (BrowserStorage.get(`${userId}_${pathname}`) ||
          BrowserStorage.get(`${userId}_${pathname}/`))

      if (storagePath && isEmpty(filters)) {
        const paths = storagePath.split('?')

        replace(`${paths[0]}?${stringify({ ...parse(paths[1]), page })}`)
      } else {
        const formattedPathname = formatPathname(pathname)

        if (!filters.page && formattedPathname) {
          const search = getNewQueryString({ ...filters, page })
          const currentLocation = getCurrentFilters(location)

          if (!isEqual(currentLocation, { ...filters, page })) {
            replace(`/${formattedPathname}/?${search}`)
          }
        }
      }
    }, [pathname])

    return <WrappedComponent {...props} />
  }

  return connect(
    state => ({ location: getLocation(state), user: getUser(state) }),
    { replace }
  )(Redirect)
}

export default addRedirectToFirstPage
