// @flow

import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { isNull, omitBy } from 'lodash-es'

import SelectSimple from '../../../components/Select/SelectSimple'
import InputPassword from '../../../components/InputPassword'
import BrowserStorage from '../../../utils/browserStorage'
import Block, { Header, CellsBar, Cell } from '../../../components/Block'
import { DOMUS_LOCALE_STORAGE_KEY } from './Domus.constants'
import {
  getDomusConfig,
  getDomusStatus,
  createDomus,
  updateDomus,
  syncDomus,
} from '../../../core/api/api.integrations'
import Field from './Field'
import { formatDateTime } from '../../../utils/utils'
import DomusStatus from './DomusStatus'
import DomusActions from './DomusActions'

import styles from '../Integrations.module.scss'

import Icon from '../../../../static/icons/real-data.svg'

function getConfig() {
  return getDomusConfig()
    .then(data => data)
    .catch(() => null)
}

function getStatus() {
  return getDomusStatus()
    .then(data => data)
    .catch(() => null)
}

async function getDomusData() {
  const config = await getConfig()
  const status = await getStatus()

  return [config, status]
}

function getCollapsed() {
  const localStoragecollapsed = BrowserStorage.get(DOMUS_LOCALE_STORAGE_KEY)

  let collapsed = true

  if (!isNull(localStoragecollapsed)) {
    collapsed = JSON.parse(localStoragecollapsed)
  }

  return collapsed
}

const Domus = () => {
  const [collapsed, setCollapsed] = useState(getCollapsed())
  const [working, setWorking] = useState(false)
  const [initiated, setInititated] = useState(false)
  const [status, setStatus] = useState(null)
  const [config, setConfig] = useState(null)

  const {
    db_host: dbHost = '',
    db_port: dbPort = '',
    db_user: dbUser = '',
    db_pass: dbPass = '',
    db_name: dbName = '',
    ftp_enabled: ftpEnabled = false,
    ftp_host: ftpHost = '',
    ftp_port: ftpPort = '',
    ftp_user: ftpUser = '',
    ftp_pass: ftpPass = '',
  } = (config && omitBy(config, isNull)) || {}

  const {
    integration_available: integrationAvailable,
    in_progress: inProgress,
    started,
    finished,
  } = status || {}

  useEffect(() => {
    BrowserStorage.set(DOMUS_LOCALE_STORAGE_KEY, collapsed)

    if (!initiated && !collapsed) {
      setWorking(true)

      getDomusData().then(data => {
        setInititated(true)

        const [initialConfig, initialStatus] = data

        setStatus(initialStatus)
        setConfig(initialConfig)

        setWorking(false)
      })
    }
  }, [collapsed])

  const onClickHeader = () => {
    setCollapsed(!collapsed)
  }

  const { t } = useTranslation('Integrations')

  const requiredText = t('Common:FieldIsRequired')

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      dbHost,
      dbPort,
      dbUser,
      dbPass,
      dbName,
      ftpEnabled,
      ftpHost,
      ftpPort,
      ftpUser,
      ftpPass,
    },
    validationSchema: Yup.object({
      dbHost: Yup.string().required(requiredText),
      dbPort: Yup.string().required(requiredText),
      dbUser: Yup.string().required(requiredText),
      dbPass: Yup.string().required(requiredText),
      dbName: Yup.string().required(requiredText),
      ftpEnabled: Yup.boolean().required(requiredText),
      ftpPort: Yup.string(),
      ftpHost: Yup.mixed().when('ftpEnabled', {
        is: true,
        then: Yup.string().required(requiredText),
        otherwise: Yup.mixed(),
      }),
      ftpUser: Yup.mixed().when('ftpEnabled', {
        is: true,
        then: Yup.string().required(requiredText),
        otherwise: Yup.mixed(),
      }),
      ftpPass: Yup.mixed().when('ftpEnabled', {
        is: true,
        then: Yup.string().required(requiredText),
        otherwise: Yup.mixed(),
      }),
    }),
    onSubmit: values => {
      setWorking(true)

      const {
        dbHost,
        dbPort,
        dbUser,
        dbPass,
        dbName,
        ftpEnabled,
        ftpHost,
        ftpPort,
        ftpUser,
        ftpPass,
      } = values

      const api = config ? updateDomus : createDomus

      api({
        db_host: dbHost,
        db_port: dbPort,
        db_user: dbUser,
        db_pass: dbPass,
        db_name: dbName,
        ftp_enabled: ftpEnabled,
        ftp_host: ftpHost,
        ftp_port: ftpPort,
        ftp_user: ftpUser,
        ftp_pass: ftpPass,
      })
        .then(data => setConfig(data))
        .catch(error => console.log(error))
        .finally(() => setWorking(false))
    },
  })

  const integrate = () => {
    setWorking(true)

    syncDomus()
      .then(() => getStatus())
      .then(data => setStatus(data))
      .catch(error => console.log(error))
      .finally(() => setWorking(false))
  }

  const checkStatus = () => {
    setWorking(true)

    getStatus()
      .then(data => setStatus(data))
      .finally(() => setWorking(false))
  }

  const options = [
    { value: true, label: t('DomusFtpEnabled') },
    { value: false, label: t('DomusFtpDisabled') },
  ]

  const selectValue = options.find(
    option => option.value === formik.values.ftpEnabled
  )

  const handleSelect = option => {
    const { value } = option

    formik.setFieldValue('ftpEnabled', value)
  }

  return (
    <Block
      className={styles.block}
      collapsed={collapsed}
      working={working}
      onClickHeader={onClickHeader}
    >
      <Header header>
        <span icon='true' className={styles.headerIcon}>
          <Icon />
        </span>
        Domus Integration
      </Header>
      <CellsBar>
        <Cell title={t('DomusSyncStartDateTime')}>
          {started ? formatDateTime(started) : '-'}
        </Cell>
        <Cell title={t('DomusSyncFinishDateTime')}>
          {finished ? formatDateTime(finished) : '-'}
        </Cell>
        <DomusStatus syncInProgress={inProgress} syncFinishedTime={finished} />
      </CellsBar>
      <div className='settings-personal__form'>
        <div className='bar__title bar__title-indented'>
          <span className='bar__title-text'>
            {t('DomusCommonOptionsTitle')}
          </span>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <Field
            name='dbHost'
            error={formik.errors.dbHost}
            value={formik.values.dbHost}
            handleChange={formik.handleChange}
          />
          <Field
            name='dbPort'
            error={formik.errors.dbPort}
            value={formik.values.dbPort}
            handleChange={formik.handleChange}
          />
          <Field
            name='dbUser'
            error={formik.errors.dbUser}
            value={formik.values.dbUser}
            handleChange={formik.handleChange}
          />
          <Field
            name='dbPass'
            error={formik.errors.dbPass}
            value={formik.values.dbPass}
            handleChange={formik.handleChange}
          >
            <InputPassword
              value={formik.values.dbPass}
              name='dbPass'
              id='dbPass'
              onChange={formik.handleChange}
            />
          </Field>
          <Field
            name='dbName'
            error={formik.errors.dbName}
            value={formik.values.dbName}
            handleChange={formik.handleChange}
          />
          <div className='bar__title bar__title-indented'>
            <span className='bar__title-text'>{t('DomusFtpOptionsTitle')}</span>
          </div>
          <Field error={formik.errors.outgoingEncryptOption} name='ftpEnabled'>
            <SelectSimple
              options={options}
              value={selectValue}
              name='ftpEnabled'
              clearable={false}
              onChange={handleSelect}
            />
          </Field>
          <Field
            name='ftpHost'
            error={formik.errors.ftpHost}
            value={formik.values.ftpHost}
            handleChange={formik.handleChange}
          />
          <Field
            name='ftpPort'
            error={formik.errors.ftpPort}
            value={formik.values.ftpPort}
            handleChange={formik.handleChange}
          />
          <Field
            name='ftpUser'
            error={formik.errors.ftpUser}
            value={formik.values.ftpUser}
            handleChange={formik.handleChange}
          />
          <Field
            name='ftpPass'
            error={formik.errors.ftpPass}
            value={formik.values.ftpPass}
            handleChange={formik.handleChange}
          >
            <InputPassword
              value={formik.values.ftpPass}
              name='ftpPass'
              id='ftpPass'
              onChange={formik.handleChange}
            />
          </Field>
          <DomusActions
            configIsExists={!!config}
            formIsDirty={formik.dirty}
            integrationAvailable={integrationAvailable}
            syncFinishedTime={finished}
            syncInProgress={inProgress}
            working={working}
            handleReset={formik.handleReset}
            handleIntegrate={integrate}
            handleCheckStatus={checkStatus}
          />
        </form>
      </div>
    </Block>
  )
}

export default Domus
