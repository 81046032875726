// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

type Props = { accounts: Array<Object>, working: boolean }

const ReplyAdditional = (props: Props): Node => {
  const { accounts, working } = props

  const { t } = useTranslation('Mail')

  if (working || accounts?.length) {
    return null
  }

  return (
    <div className='table-item table-item__text'>
      <span style={{ color: '#f24059', paddingLeft: '4px' }}>
        {t('AddAccountFirst')}
      </span>
    </div>
  )
}

export default ReplyAdditional
