export const SELECT_ASYNC_INITIATING = 'SELECT_ASYNC_INITIATING'
export const SELECT_ASYNC_INITIATED = 'SELECT_ASYNC_INITIATED'
export const SELECT_ASYNC_RESET = 'SELECT_ASYNC_RESET'
export const SELECT_ASYNC_RESET_OPTIONS = 'SELECT_ASYNC_RESET_OPTIONS'
export const SELECT_ASYNC_LOADING = 'SELECT_ASYNC_LOADING'
export const SELECT_ASYNC_LOADED = 'SELECT_ASYNC_LOADED'
export const SELECT_ASYNC_SET_VALUE = 'SELECT_ASYNC_SET_VALUE'
export const SELECT_ASYNC_SHOW_MENU_LOADER = 'SELECT_ASYNC_SHOW_MENU_LOADER'
export const SELECT_ASYNC_ERROR = 'SELECT_ASYNC_ERROR'
export const SELECT_ASYNC_CHANGE_FILTER = 'SELECT_ASYNC_CHANGE_FILTER'
