// @flow

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import OutsideClick from 'react-onclickout'
import classnames from 'classnames'

import SelectVariable from '../SelectVariable'
import MailReplyForm from '../../Mail/MailReplyForm'
import { VariableTypes } from '../constants'

type Props = {
  active: boolean,
  canSaveOnUnmount: () => boolean,
  data: Object,
  onClickOut: (?boolean) => void,
  onUpdate: Object => void,
  t: string => string,
}
type State = {
  errorText: boolean,
  errorTitle: boolean,
  values: Object,
}

class Email extends Component<Props, State> {
  state = {
    errorText: false,
    errorTitle: false,
    values: this.getInitialValues(),
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { active } = this.props
    const { files } = this.state.values

    if (
      files.length !== prevState.values.files.length ||
      (!active && prevProps.active)
    ) {
      this.update()
    }
  }

  componentWillUnmount() {
    if (this.props.canSaveOnUnmount()) {
      this.update()
    }
  }

  getInitialValues() {
    const { data } = this.props

    const getList = list => list.map(i => ({ name: i }))

    return {
      bcc: getList(data.bcc || []),
      cc: getList(data.cc || []),
      files: data.files,
      message: data.text || '',
      title: data.title || '',
      to: getList(data.to || []),
      variable: data.variable,
    }
  }

  onClickOut = () => {
    this.props.onClickOut()
  }

  updateValues = values => {
    this.setState(state => ({
      values: { ...values, variable: state.values.variable },
      errorText: values.message ? false : state.errorText,
      errorTitle: values.title ? false : state.errorTitle,
    }))
  }

  update = () => {
    const { data } = this.props

    if (this.validate()) {
      const { values } = this.state
      const getEmails = list =>
        JSON.stringify(list.map(i => (i.profile ? i.profile.email : i.name)))

      const newText = values.message.replace(/\n/g, '')
      const regExp = /(<p+)(.*?)(style=")(.*?)(;")(.*?)(>?)/g

      this.props.onUpdate({
        id: data.id,
        bcc: getEmails(values.bcc),
        cc: getEmails(values.cc),
        files: JSON.stringify(values.files.map(f => f.id)),
        text: newText.replace(regExp, '$1$2$3$4; margin: 0px$5$6$7'),
        title: values.title,
        to: getEmails(values.to),
        variable: values.variable || '',
      })
    }
  }

  updateField = opt => {
    this.setState(state => ({
      values: { ...state.values, variable: opt.value },
    }))
  }

  renderFileVariable = () => {
    const { t } = this.props
    const { variable } = this.state.values

    return (
      <div className='request-constructor__card--request-documents'>
        <div className='request-constructor__card--request-item-title'>
          {t('documents')}:
        </div>
        <SelectVariable
          clearable
          include={[VariableTypes.file]}
          placeholder={t('selectFileVariable')}
          value={variable}
          onChange={this.updateField}
        />
      </div>
    )
  }

  validate = () => {
    const { values } = this.state
    let error = false

    if (!values.message) {
      this.setState({ errorText: true })
      error = true
    }

    if (!values.title) {
      this.setState({ errorTitle: true })
      error = true
    }

    return !error
  }

  setViewFiles = ind => {
    const { setFileIndex, setFiles } = this.props
    const { files } = this.state.values
    setFileIndex(ind)
    setFiles([...files])
  }

  render() {
    const { data } = this.props
    const { values, errorText, errorTitle } = this.state

    const cardClass = classnames(
      'request-constructor__card--body request-constructor__card--email',
      {
        'request-constructor__card--email--error-title': errorTitle,
        'request-constructor__card--email--error-text': errorText,
      }
    )

    return (
      <OutsideClick onClickOut={this.onClickOut}>
        <div className={cardClass}>
          <div>{this.props.t('emailDescription')}</div>
          <MailReplyForm
            creating
            showAddButton
            onlyManualFileRemove
            uuid={`card-${data.id}`}
            renderAdditional={this.renderFileVariable}
            values={values}
            setFileIndex={this.setViewFiles}
            onUpdate={this.updateValues}
            onWysiwygFocus={this.props.onWysiwygFocus}
          />
        </div>
      </OutsideClick>
    )
  }
}

export default withTranslation('RequestConstructor')(Email)
