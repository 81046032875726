// @flow

import { getApi, postApi, patchApi } from './api.core'

const BASE_PATHNAME = 'api/ratings/'

export const getRatings = () => getApi(BASE_PATHNAME)
export const setRating = params => postApi(BASE_PATHNAME, params)
export const getRating = params => getApi(BASE_PATHNAME, params)
export const updateRating = (id, params) =>
  patchApi(`${BASE_PATHNAME}${id}/`, params)
