// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'

import Header from '../Header'
import Footer from '../Footer'
import Modal from '../Modal'
import ErrorPopup from '../modals/ErrorPopup'
import MessagePopup from '../modals/Message'
import * as actions from './Layout.constants'
import { getThemeColor, getUser, getUk } from '../../utils/commonSelectors'

import styles from './Layout.module.scss'
import ErrorPage from '../ErrorPage'

const { CLOSE_POP_UP } = actions

type Props = {
  children: any,
  code: string,
  dispatch: Object => void,
  error: Object,
  errorFlag: boolean,
  errorText: string,
  errorTitle: string,
  isServerError: boolean,
  message: string,
  messageParams: Object,
  showMessage: boolean,
  t: string => string,
  themeColor: string,
  title: string,
  uk: Object,
  user: Object,
}

class Layout extends Component<Props> {
  closePopUp = () => {
    this.props.dispatch({ type: CLOSE_POP_UP })
  }

  render() {
    const {
      errorTitle,
      errorText,
      uk,
      errorFlag,
      user,
      showMessage,
      message,
      title,
      code,
      t,
      error,
      isServerError,
      messageParams,
      needTranslate,
      themeColor,
    } = this.props

    if (!this.props.user) {
      // TODO user not provided !, fix error
      return null
    }

    let errorPopupProps = {}

    if (isServerError) {
      errorPopupProps = {
        error,
        isServerError,
        text: `${t('SeverErrorMessage')} code: ${code}`,
        title: t('Error'),
      }
    } else {
      errorPopupProps = {
        error,
        isServerError,
        text: errorText,
        needTranslate,
        title: errorTitle || t('Error'),
      }
    }

    const overlayClassName = classnames(styles.overlay)

    if (errorFlag && isServerError) {
      return <ErrorPage t={t} status={code} />
    }

    return (
      <div style={{ '--themeColor': themeColor }} className={styles.layout}>
        <Header user={user} uk={uk} />
        {this.props.children}
        <Footer uk={uk} />
        <Modal
          className='Modal__Bootstrap modal-dialog'
          overlayClassName={overlayClassName}
          isOpen={errorFlag}
        >
          <ErrorPopup
            {...errorPopupProps}
            confirm={t('Close')}
            onOk={this.closePopUp}
          />
        </Modal>
        <Modal
          className='Modal__Bootstrap modal-dialog'
          overlayClassName={overlayClassName}
          isOpen={showMessage}
        >
          <MessagePopup
            title={title}
            text={message}
            textParams={messageParams}
            onOk={this.closePopUp}
          />
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state.layout,
  user: getUser(state),
  uk: getUk(state),
  themeColor: getThemeColor(state),
})

export default compose(
  withTranslation('Layout'),
  connect(mapStateToProps)
)(Layout)
