// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'

import { SimpleBlock, Header } from '../../Block'
import InputValidation from '../../forms/InputValidation'
import Image, { IMAGE_TYPES } from '../../CompanyImage'
import { removeFile } from '../../../utils/file'
import { getTranslationKeys } from '../../../utils/utils'
import Settings from '../../Settings'
import * as actions from './CompanyBackground.actionTypes'
import { getUk } from '../../../utils/commonSelectors'

import styles from './CompanyBackground.module.scss'

type Props = {
  dispatch: Object => void,
  error: ?Object | string,
  fallback: Object,
  t: string => string,
  uk: Object,
  working: boolean,
}
type State = {
  background: ?Object,
  uploading: boolean,
}

class CompanyBackground extends Component<Props, State> {
  state = {
    background: null,
    uploading: false,
  }

  componentDidUpdate(prev: Props) {
    const { working, error } = this.props

    if (!error && !working && prev.working) {
      this.setState({ background: null })
    }
  }

  componentWillUnmount() {
    this.props.dispatch({
      type: actions.COMPANY_BACKGROUND_RESET,
    })
  }

  startUpload = () => {
    this.setState({ uploading: true })
  }

  finishUpload = (files: Array<Object>) => {
    if (files.length) {
      const { background } = this.state

      if (background) {
        removeFile(background.id)
      }

      this.setState({ background: files[0] })
    }

    this.setState({ uploading: false })
  }

  cancel = () => {
    this.setState({ background: null })
  }

  save = () => {
    const { background } = this.state

    if (background) {
      const { uk } = this.props
      this.props.dispatch({
        type: actions.COMPANY_BACKGROUND_SAVE,
        uk: uk.id,
        data: { bg_image: background.id },
      })
    }
  }

  resetImage = () => {
    const { fallback } = this.props
    this.setState({
      background: { id: '', filename: '', origin: fallback },
    })
  }

  render() {
    const { working, uk, fallback, error } = this.props
    const { background, uploading } = this.state
    const sectionClass = classnames(
      'settings-personal',
      'unit',
      'unit--default',
      'settings-regional'
    )

    return (
      <SimpleBlock working={working || uploading} className={sectionClass}>
        <Header header>{this.props.t('BackgroundTitle')}</Header>

        <div className='settings-personal__form'>
          <div className='settings-personal__form-row'>
            <label className='settings-personal__form-label'>
              {this.props.t('BackgroundRequirements')}
            </label>
          </div>
          <div className='settings-personal__form-row'>
            <Image
              fallback={fallback}
              file={background || uk.bg_image_obj}
              type={IMAGE_TYPES.background}
              onFinishUpload={this.finishUpload}
              onStartUpload={this.startUpload}
              onRemove={this.resetImage}
            />
          </div>
        </div>

        <Settings.Actions
          withChanges={background}
          working={working || uploading}
          onSave={this.save}
          onCancel={this.cancel}
        />

        {!!error && (
          <InputValidation
            className={styles.error}
            message={getTranslationKeys(error)}
          />
        )}
      </SimpleBlock>
    )
  }
}

const mapStateToProps = state => ({
  uk: getUk(state),
  fallback: state.init.default_bg_image,
  ...state.companyBackground,
})

export default compose(
  withTranslation('Settings'),
  connect(mapStateToProps)
)(CompanyBackground)
