// @flow

import { FILE_ORIGIN_TYPES } from '../../containers/FilesUpload'

export const IMAGE_TYPES = {
  background: 'background',
  mobile: 'mobile',
  web: 'web',
  avatar: 'avatar',
}

export const HTML_IDS = {
  [IMAGE_TYPES.background]: 'background',
  [IMAGE_TYPES.web]: 'company-logo',
  [IMAGE_TYPES.mobile]: 'mobile-logo',
}

export const FALLBACK_IMAGE_IDS = {
  [IMAGE_TYPES.background]: 4246,
  [IMAGE_TYPES.web]: 1,
  [IMAGE_TYPES.mobile]: 18, // TODO get this from init
}

export const ORIGIN_TYPES = {
  [IMAGE_TYPES.background]: FILE_ORIGIN_TYPES.background,
  [IMAGE_TYPES.web]: FILE_ORIGIN_TYPES.default,
  [IMAGE_TYPES.mobile]: FILE_ORIGIN_TYPES.default,
}

export const MAX_SIZE = {
  [IMAGE_TYPES.background]: 2,
  [IMAGE_TYPES.web]: 2.5,
  [IMAGE_TYPES.avatar]: 2.5,
  [IMAGE_TYPES.mobile]: 2.5,
}

export const MIN_DIMENSIONS = {
  [IMAGE_TYPES.background]: { width: 1920, height: 1080 },
}
