// @flow

export const FRIENDS_LIST_INITIATING = 'FRIENDS_LIST_INITIATING'
export const FRIENDS_LIST_INITIATED = 'FRIENDS_LIST_INITIATED'
export const FRIENDS_LIST_LOAD = 'FRIENDS_LIST_LOAD'
export const FRIENDS_LIST_LOADED = 'FRIENDS_LIST_LOADED'
export const FRIENDS_LIST_ERROR = 'FRIENDS_LIST_ERROR'
export const FRIENDS_LIST_SUBSCRIBE = 'FRIENDS_LIST_SUBSCRIBE'
export const FRIENDS_LIST_UNSUBSCRIBE = 'FRIENDS_LIST_UNSUBSCRIBE'
export const FRIENDS_LIST_SUBSCRIBED = 'FRIENDS_LIST_SUBSCRIBED'
export const FRIENDS_LIST_UNSUBSCRIBED = 'FRIENDS_LIST_UNSUBSCRIBED'
