// @flow

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { difference, xor } from 'lodash-es'
import classnames from 'classnames'

import Button from '../Button'
import DocumentsEditing from './DocumentsEditing'
import EmptyList from '../EmptyList'
import DocumentsView from './DocumentsView'

import { removeFile } from '../../utils/file'

import styles from './Documents.module.scss'

const ROW_LENGTH = 5

type Files = Array<Object>
type Props = {
  canEdit: boolean,
  canRemove?: boolean,
  files: Files,
  gallery?: number,
  multiple?: boolean,
  onSave: Files => void,
  t: string => string,
  updating: boolean,
}
type State = {
  canSave: boolean,
  editing: boolean,
  items: Files,
  needUpdateSnap: boolean,
  saving: boolean,
}

class Documents extends Component<Props, State> {
  state = {
    canSave: false,
    editing: false,
    items: [],
    needUpdateSnap: false,
    saving: false,
  }

  componentDidUpdate(prev: Props) {
    const { files, updating } = this.props
    const { editing, saving } = this.state

    if (!updating && prev.updating) {
      if (saving && editing) {
        this.setState({
          canSave: false,
          editing: false,
          items: files,
          saving: false,
        })

        this.removeOddFiles(prev.files, files)
      } else {
        const diffIds = this.getDiffIds(files, prev.files)

        if (diffIds.length) {
          this.setState(
            state => ({
              needUpdateSnap: !state.needUpdateSnap,
            }),
            this.cancel
          )
        }
      }
    }
  }

  componentWillUnmount() {
    const { editing, items } = this.state

    if (editing) {
      const { files } = this.props
      this.removeOddFiles(items, files)
    }
  }

  getIds = (files: Files) => files.map(f => f.id)

  getDiffIds = (our: Files, their: Files) =>
    xor(this.getIds(our), this.getIds(their))

  update = (items: Files) => {
    this.setState({
      canSave: true,
      editing: true,
      items,
    })
  }

  remove = ({ id }: Object) => {
    const { files } = this.props
    const { items } = this.state

    const filtered = items.filter(f => f.id !== id)
    const diffIds = this.getDiffIds(filtered, files)
    this.setState({
      canSave: Boolean(diffIds.length),
      items: filtered,
    })

    if (!files.find(f => f.id === id)) {
      removeFile(id)
    }
  }

  // Remove files with ids from verifiable that are not included in verifier ids
  removeOddFiles = (verifiable: Files, verifier: Files) => {
    const ids = difference(this.getIds(verifiable), this.getIds(verifier))

    ids.forEach(removeFile)
  }

  edit = () => {
    const { files, updating } = this.props

    if (!updating) {
      this.setState({
        editing: true,
        items: files,
      })
    }
  }

  save = () => {
    const { items } = this.state
    this.props.onSave(items)

    this.setState({ saving: true })
  }

  cancel = () => {
    const { files } = this.props
    const { items } = this.state

    this.setState({
      canSave: false,
      editing: false,
      items: [],
    })

    this.removeOddFiles(items, files)
  }

  renderButtons = () => {
    const { editing, saving, canSave } = this.state

    return (
      <div className={styles.actions}>
        <Button.Save
          disabled={!canSave}
          working={saving && editing}
          onClick={this.save}
        >
          {this.props.t('Save')}
        </Button.Save>
        <Button.Cancel onClick={this.cancel}>
          {this.props.t('Cancel')}
        </Button.Cancel>
      </div>
    )
  }

  renderContent = () => {
    const { canEdit, canRemove, gallery, multiple, files, scope, postUuid } =
      this.props
    const { editing, saving } = this.state

    if (!canEdit && !files.length) {
      return (
        <EmptyList embedded icon='file' title={this.props.t('EmptyTitle')} />
      )
    }

    const { items, needUpdateSnap } = this.state
    const showEditing = !files.length || editing

    return (
      <div className='apartment-profile__uploader'>
        {showEditing ? (
          <DocumentsEditing
            canRemove={canRemove}
            files={items}
            Footer={this.renderButtons}
            gallery={gallery}
            hideAddFirst={editing && !!files.length}
            multiple={multiple}
            rowLength={ROW_LENGTH}
            saving={saving}
            onRemove={this.remove}
            onUpdate={this.update}
          />
        ) : (
          <DocumentsView
            files={files}
            maxRowLength={ROW_LENGTH}
            needUpdateSnap={needUpdateSnap}
            scope={scope}
            postUuid={postUuid}
          />
        )}
      </div>
    )
  }

  renderEditButton = () => {
    const { canEdit, files } = this.props
    const { editing } = this.state

    const editable = canEdit && this.props.onSave

    return (
      editable &&
      !editing &&
      !!files.length && <Button.Edit onClick={this.edit} />
    )
  }

  render() {
    const { updating } = this.props
    const { saving } = this.state

    const className = classnames({
      'working-overlay': updating && !saving,
    })

    return (
      <div className={className}>
        <h2 className={styles.title}>
          {this.props.t('Documents')}
          {this.renderEditButton()}
        </h2>
        {this.renderContent()}
      </div>
    )
  }
}

export default withTranslation('File')(Documents)
