// @flow

import React from 'react'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import classnames from 'classnames'

import Cell from './Cell'

import type Node from 'react'
import type { ItemProps } from './index'

const ExternalContact = (props: ItemProps): Node => {
  const { user } = props
  const { id, fullname, avatar } = user

  const dispatch = useDispatch()

  const open = () => {
    const params = ''
    dispatch(push(`/external-contact/${user.id}${params}`))
  }

  const userClass = classnames('table__row')

  return (
    <div className={userClass} key={`user-${id}`}>
      <div className='table__cell'>
        <div className='table__cell-wrapper' onClick={() => open(id)}>
          <div className='table-item table-item__user'>
            <div className='table-item__user-info'>
              <i className='table-item__user-pic' onClick={open}>
                <img src={avatar} alt='user' />
              </i>
              <div>
                <span className='table-item__user-name' title={fullname}>
                  {fullname}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Cell.Email user={user} onClick={() => open(id)} />
      <Cell.Phone user={user} onClick={() => open(id)} />
      <Cell.Updated external user={user} />
    </div>
  )
}

export default ExternalContact
