// @flow

import { put, call, fork, debounce } from 'redux-saga/effects'
import type { Saga } from 'redux-saga'

import { serverError } from '../Layout/Layout.actions'
import * as actions from './MailSearch.actionTypes'
import {
  getEmailRecipients,
  getListTiny,
  availableChatMembers,
} from '../../core/api/api.profile'

function* watchLoad() {
  yield debounce(700, actions.MAIL_SEARCH_LOAD, load)
}

function* load(action: Object) {
  const { params, key, value, withProviders, addChatMembers } = action

  let api = getListTiny
  let param = {}

  if (withProviders) {
    api = getEmailRecipients
    param = { with_providers: 1 }
  }

  if (addChatMembers) {
    api = availableChatMembers
  }

  try {
    const response = yield call(api, { ...params, ...param })

    const suggestions = response.results.objects.map(item => ({
      ...item,
      id: item.owner || item.id,
    }))

    yield put({
      type: actions.MAIL_SEARCH_LOADED,
      suggestions,
      append: params.page && params.page > 1,
      page_count: response.meta?.page_count || 1,
      value,
      key,
    })
  } catch (error) {
    yield put(serverError(error))
  }
}

export default function* watch(): Saga<void> {
  yield fork(watchLoad)
}
