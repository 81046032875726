import React from 'react'
import styles from './SelectPopUp.module.scss'

import Button from '../../../Button'
import classnames from 'classnames'

const SelectPopUp = ({
  working,
  themeColor,
  clearAll,
  getActionMessage,
  getInfoMessages,
  action,
  onClick,
  parentWidth,
}) => {
  const bodyClass = classnames(styles.popUpBody, { [styles.spinner]: working })

  return (
    <div className={styles.selectPopUp} style={{ width: parentWidth }}>
      <div className={bodyClass}>
        {working ? (
          <span className='button__spinner' />
        ) : (
          <>
            <span>{getInfoMessages[action]}</span>
            <span
              className={styles.action}
              style={{ color: themeColor }}
              onClick={onClick[action]}
            >
              {getActionMessage[action]}
            </span>
            <Button.Cross
              style={{ padding: '11px' }}
              disabled={working}
              onClick={clearAll}
            />
          </>
        )}
      </div>
    </div>
  )
}

export default SelectPopUp
