// @flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import Modal from '../Modal'
import ModalBody from '../Modal/ModalBody'
import ModalCloseButton from '../Modal/ModalCloseButton'
import ModalHead from '../Modal/ModalHead'
import { useOverflow } from '../../hooks'
import NewSelectSimple from '../NewSelectSimple'

type Props = {
  onClose: () => void,
  patchApi: (
    id: number,
    params?: {
      hidden?: boolean,
      name?: string,
      tags?: Array<string>,
      text?: string,
    }
  ) => Promise<Object>,
  sectionKey: string,
  selectedItems: Array<number>,
  setLoading: boolean => void,
  templates: Array<Object>,
}

const VisibilityPopup = (props: Props): Node => {
  const { selectedItems, templates, sectionKey } = props

  const { t } = useTranslation(sectionKey)

  useOverflow()

  const [value, setValue] = useState(true)
  const [loading, setLoading] = useState(false)

  const visibilityOptions = [
    { label: t('SetHidden'), value: true },
    { label: t('SetVisible'), value: false },
  ]

  const handleSelect = option => setValue(option.value)

  const cahngeType = async () => {
    return Promise.all(
      selectedItems.map(template => {
        const templateObj = templates.find(t => template === t.id)

        if (!templateObj || !templateObj.permissions.can_change_visibility) {
          return null
        }

        return props.patchApi(template, { hidden: value }).then(data => data)
      })
    )
  }

  const handleSave = async () => {
    setLoading(true)

    await cahngeType()

    setLoading(false)

    props.onClose()

    props.setLoading(true)
  }

  const selectedOption = visibilityOptions.find(
    option => option.value === value
  )

  const modalProps = Object.assign(
    {},
    loading ? { className: 'working-overlay' } : undefined
  )

  return (
    <Modal isOpen onRequestClose={props.onClose} {...modalProps}>
      <ModalHead title={t('ChangeVisibilityTitle')} />
      <ModalCloseButton onClose={props.onClose}></ModalCloseButton>
      <ModalBody>
        <div className='m-a10__row'>
          <div className='m-a10__cell m-a10__cell_1'>
            <span className='m-a10__label'>{t('Visibility')}</span>
          </div>
          <div className='m-a10__cell m-a10__cell_2'>
            <div className='input input--medium input--block input--default'>
              <NewSelectSimple
                name='template-visibility'
                value={selectedOption}
                options={visibilityOptions}
                onChange={handleSelect}
              />
            </div>
          </div>
        </div>
      </ModalBody>
      <div className='modal__submit'>
        <button
          type='button'
          disabled={loading}
          className='button button--large button--success'
          onClick={handleSave}
        >
          {t('ConfirmChangeVisibility')}
        </button>
        <button
          disabled={loading}
          className='button button--large button--danger-3'
          type='button'
          onClick={props.onClose}
        >
          {t('Cancel')}
        </button>
      </div>
    </Modal>
  )
}

export default VisibilityPopup
