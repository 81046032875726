// @flow

import * as actions from './CompanyView.actionTypes'

const initialState = {
  ready: {
    team: false,
    contacts: false,
  },
  addresses: [],
  working: false,
  creatingAddress: {},
  modalCreate: false,
  modalRemove: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.COMPANY_VIEW_INITIATE:
      return {
        ...initialState,
        addresses: action.addresses,
      }
    case actions.COMPANY_VIEW_SECTION_READY:
      return {
        ...state,
        ready: {
          ...state.ready,
          [action.key]: true,
        },
      }
    case actions.COMPANY_VIEW_ADDRESS_EDIT_MODE:
      return {
        ...state,
        addresses: state.addresses.map(address => ({
          ...address,
          editMode: address.officeId === action.id,
        })),
      }
    case actions.COMPANY_VIEW_ADDRESS_EDIT_CANCEL:
      return {
        ...state,
        addresses: state.addresses.map(address =>
          address.officeId === action.id
            ? { ...address, ...action.data, editMode: false }
            : { ...address }
        ),
      }
    case actions.COMPANY_VIEW_ADDRESS_EDIT:
      return {
        ...state,
        addresses: state.addresses.map(address =>
          address.officeId === action.id
            ? { ...address, ...action.data }
            : { ...address }
        ),
      }
    case actions.COMPANY_VIEW_ADDRESS_CREATE:
    case actions.COMPANY_VIEW_ADDRESS_SAVE:
    case actions.COMPANY_VIEW_ADDRESS_REMOVE:
      return {
        ...state,
        working: true,
      }
    case actions.COMPANY_VIEW_ADDRESS_CREATED:
      return {
        ...state,
        working: false,
        creatingAddress: {},
        addresses: [
          ...state.addresses,
          {
            ...action.res.address,
            officeId: action.res.id,
          },
        ],
        modalCreate: false,
      }
    case actions.COMPANY_VIEW_ADDRESS_SAVED:
      return {
        ...state,
        addresses: state.addresses.map(address =>
          address.officeId === action.officeId
            ? {
                ...action.address,
                officeId: action.officeId,
                editMode: false,
              }
            : { ...address }
        ),
        working: false,
      }
    case actions.COMPANY_VIEW_ADDRESS_REMOVED:
      return {
        ...state,
        addresses: state.addresses.filter(
          address => address.officeId !== action.officeId
        ),
        working: false,
        modalRemove: false,
      }
    case actions.COMPANY_VIEW_ADDRESS_REMOVE_ERROR:
      return {
        ...state,
        working: false,
        modalRemove: false,
        addresses: state.addresses.map(address =>
          address.editMode
            ? {
                ...address,
                editMode: false,
              }
            : address
        ),
      }
    case actions.COMPANY_VIEW_ADDRESS_CREATE_IN_PROGRESS:
      return {
        ...state,
        creatingAddress: !action.reset
          ? {
              ...state.creatingAddress,
              ...action.data,
            }
          : {},
      }
    case actions.COMPANY_VIEW_ADDRESS_MODAL_CREATE_TOGGLE:
      return {
        ...state,
        modalCreate: !state.modalCreate,
      }
    case actions.COMPANY_VIEW_ADDRESS_MODAL_REMOVE_TOGGLE:
      return {
        ...state,
        modalRemove: !state.modalRemove,
      }
    default:
      return state
  }
}
