// @flow

import type { Node } from 'react'
import type { IObjectType } from '../BuildingInformation'
import React from 'react'
import setFormikError from '../../../../utils/setFormikError'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import NewSelectAsync from '../../../NewSelectAsync'
import FieldRow from '../../../FieldRow'

import styles from './BuildingInformationForms.module.scss'

import Button from '../../../Button'
import {
  MAX_OBJECT_NUMBER_LENGTH,
  SALUTATION,
  OBJECT_NUMBER,
  POSTAL_CODE,
  LOCATION,
  MAX_POSTAL_CODE_LENGTH,
  MAX_LOCATION_LENGTH,
  TYPE_OF_LIVING,
  CONTRACT_START,
  CONTRACT_END,
  STREET,
  DOOR_NUMBER,
  FULL_ADDRESS,
  CONSTRUCTION_YEAR,
  ADDRESS_INVOICES,
  AMOUNT_RESTRICTION,
  HOUSE_KEEPER,
} from './ObjectInfo.constants'
import * as Yup from 'yup'

import { getSalutation } from '../../../../core/api/api.pipeline'
import { getLivingType } from '../../../../core/api/api.living'

type Props = {
  active: boolean,
  handleEditClose: () => void,
  initialValues: IObjectType,
  isOpen: boolean,
  updateObjectInfo: (data: {
    object_info: IObjectType,
  }) => Promise<IObjectType>,
}

const ObjectInfoForm = ({
  isOpen,
  initialValues,
  active,
  handleEditClose,
  updateObjectInfo,
}: Props): Node => {
  const { t } = useTranslation('Building')
  const onSubmit = values => {
    const object_info: IObjectType = {
      ...values,
      [SALUTATION]: values[SALUTATION]?.id || null,
      [POSTAL_CODE]: values[POSTAL_CODE],
      [TYPE_OF_LIVING]: values[TYPE_OF_LIVING]?.id || null,
      contract_start:
        values.contract_start &&
        moment(values.contract_start).format('YYYY-MM-DD'),
      contract_end:
        values.contract_end && moment(values.contract_end).format('YYYY-MM-DD'),
    }
    updateObjectInfo({ object_info })
      .then(() => handleEditClose())
      .catch(error => setFormikError(error, setFieldError))
      .finally(() => setSubmitting(false))
  }
  const onClosed = () => {
    handleEditClose()
    resetForm()
  }

  const {
    values,
    errors,
    dirty,
    resetForm,
    isSubmitting,
    setSubmitting,
    setFieldError,
    handleSubmit,
    handleChange,
    setFieldValue,
  } = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      [SALUTATION]: Yup.object({
        id: Yup.number().nullable(),
        values: Yup.string().nullable(),
      }).nullable(),
      [OBJECT_NUMBER]: Yup.string().max(
        MAX_OBJECT_NUMBER_LENGTH,
        t('MaxInputLength', { length: MAX_OBJECT_NUMBER_LENGTH })
      ),
      [STREET]: Yup.string()
        .nullable()
        .max(
          MAX_POSTAL_CODE_LENGTH,
          t('MaxInputLength', {
            length: MAX_POSTAL_CODE_LENGTH,
          })
        ),
      [POSTAL_CODE]: Yup.string()
        .nullable()
        .max(
          MAX_POSTAL_CODE_LENGTH,
          t('MaxInputLength', {
            length: MAX_POSTAL_CODE_LENGTH,
          })
        ),
      [LOCATION]: Yup.string()
        .nullable()
        .max(
          MAX_LOCATION_LENGTH,
          t('MaxInputLength', { length: MAX_LOCATION_LENGTH })
        ),
      [STREET]: Yup.string()
        .nullable()
        .max(
          MAX_LOCATION_LENGTH,
          t('MaxInputLength', { length: MAX_LOCATION_LENGTH })
        ),
      [DOOR_NUMBER]: Yup.string()
        .nullable()
        .max(
          MAX_LOCATION_LENGTH,
          t('MaxInputLength', { length: MAX_LOCATION_LENGTH })
        ),
      [FULL_ADDRESS]: Yup.string()
        .nullable()
        .max(
          MAX_LOCATION_LENGTH,
          t('MaxInputLength', { length: MAX_LOCATION_LENGTH })
        ),
      [CONSTRUCTION_YEAR]: Yup.string()
        .nullable()
        .max(
          MAX_LOCATION_LENGTH,
          t('MaxInputLength', { length: MAX_LOCATION_LENGTH })
        ),
      [ADDRESS_INVOICES]: Yup.string()
        .nullable()
        .max(
          MAX_LOCATION_LENGTH,
          t('MaxInputLength', { length: MAX_LOCATION_LENGTH })
        ),
      [AMOUNT_RESTRICTION]: Yup.string()
        .nullable()
        .max(
          MAX_LOCATION_LENGTH,
          t('MaxInputLength', { length: MAX_LOCATION_LENGTH })
        ),
      [HOUSE_KEEPER]: Yup.string()
        .nullable()
        .max(
          MAX_LOCATION_LENGTH,
          t('MaxInputLength', { length: MAX_LOCATION_LENGTH })
        ),

      [TYPE_OF_LIVING]: Yup.object({
        id: Yup.number().nullable(),
        value: Yup.string().nullable(),
      }).nullable(),
      [CONTRACT_START]: Yup.string().nullable(),
      [CONTRACT_END]: Yup.string().nullable(),
    }),
    onSubmit,
  })

  const handleSelectSalutation = option => {
    setFieldValue(SALUTATION, option)
  }

  const handleSelectTypeOfLiving = option => {
    setFieldValue(TYPE_OF_LIVING, option)
  }

  const handleClearSalutation = () => {
    setFieldValue(SALUTATION, null)
  }

  const handleClearTypeOfLiving = () => {
    setFieldValue(TYPE_OF_LIVING, null)
  }

  const handleChangeContractStart = value => {
    setFieldValue(CONTRACT_START, value || null)
  }

  const handleChangeContractEnd = value => {
    setFieldValue(CONTRACT_END, value || null)
  }

  return (
    <form
      name='building_object_info'
      onSubmit={handleSubmit}
      onChange={handleChange}
    >
      {(values[SALUTATION] || isOpen) && (
        <FieldRow
          value={values[SALUTATION]?.salutation}
          label={'Salutation'}
          error={errors[SALUTATION]}
          active={active}
          type='select'
          handleClear={handleClearSalutation}
        >
          <NewSelectAsync
            pageSize={10}
            permanentParams={{ object_type: 'building' }}
            name={SALUTATION}
            api={getSalutation}
            searchKey='search'
            placeholder={t('SelectSalutation')}
            view='dropdown'
            getLabel={a => a?.salutation}
            getSelectedLabel={a => a?.salutation}
            selectedItems={
              values[SALUTATION]?.salutation ? [values[SALUTATION]] : []
            }
            className={styles.select}
            onClick={handleSelectSalutation}
          />
        </FieldRow>
      )}
      <FieldRow
        name={OBJECT_NUMBER}
        active={active}
        label={'ObjectNumber'}
        error={errors[OBJECT_NUMBER]}
        value={values[OBJECT_NUMBER]}
        placeholder={'EnterObjectNumber'}
        maxLength={MAX_OBJECT_NUMBER_LENGTH}
        handleChange={handleChange}
      />
      {(values[STREET] || isOpen) && (
        <FieldRow
          name={STREET}
          active={active}
          label={'Street'}
          error={errors[STREET]}
          value={values[STREET]}
          placeholder={'EnterStreet'}
          handleChange={handleChange}
        />
      )}
      {(values[DOOR_NUMBER] || isOpen) && (
        <FieldRow
          name={DOOR_NUMBER}
          active={active}
          label={'DoorNumber'}
          error={errors[DOOR_NUMBER]}
          value={values[DOOR_NUMBER]}
          placeholder={'DoorNumber'}
          handleChange={handleChange}
        />
      )}
      <FieldRow
        name={POSTAL_CODE}
        active={active}
        label={'PostalCode'}
        error={errors[POSTAL_CODE]}
        value={values[POSTAL_CODE]}
        placeholder={'EnterPostalCode'}
        handleChange={handleChange}
      />
      <FieldRow
        name={LOCATION}
        active={active}
        label={'Location'}
        error={errors[LOCATION]}
        value={values[LOCATION]}
        placeholder={'EnterLocation'}
        maxLength={MAX_LOCATION_LENGTH}
        handleChange={handleChange}
      />
      {(values[FULL_ADDRESS] || isOpen) && (
        <FieldRow
          name={FULL_ADDRESS}
          active={active}
          label={'FullAddress'}
          error={errors[FULL_ADDRESS]}
          value={values[FULL_ADDRESS]}
          placeholder={'EnterFullAddress'}
          handleChange={handleChange}
        />
      )}
      <FieldRow
        value={values[TYPE_OF_LIVING]?.value}
        label={'TypeOfLiving'}
        error={errors[TYPE_OF_LIVING]}
        active={active}
        type='select'
        handleClear={handleClearTypeOfLiving}
      >
        <NewSelectAsync
          pageSize={10}
          name={TYPE_OF_LIVING}
          api={getLivingType}
          searchKey='search'
          placeholder={t('SelectTypeOfLiving')}
          view='dropdown'
          getLabel={a => a?.value}
          getSelectedLabel={a => a?.value}
          selectedItems={
            values[TYPE_OF_LIVING]?.value ? [values[TYPE_OF_LIVING]] : []
          }
          className={styles.select}
          onClick={handleSelectTypeOfLiving}
        />
      </FieldRow>
      {(values[CONSTRUCTION_YEAR] || isOpen) && (
        <FieldRow
          name={CONSTRUCTION_YEAR}
          active={active}
          label={'ConstructionYear'}
          error={errors[CONSTRUCTION_YEAR]}
          value={values[CONSTRUCTION_YEAR]}
          placeholder={'EnterConstructionYear'}
          handleChange={handleChange}
        />
      )}
      {(values[ADDRESS_INVOICES] || isOpen) && (
        <FieldRow
          name={ADDRESS_INVOICES}
          active={active}
          label={'AddressInvoices'}
          error={errors[ADDRESS_INVOICES]}
          value={values[ADDRESS_INVOICES]}
          placeholder={'EnterAddressInvoices'}
          handleChange={handleChange}
        />
      )}
      {(values[AMOUNT_RESTRICTION] || isOpen) && (
        <FieldRow
          name={AMOUNT_RESTRICTION}
          active={active}
          label={'AmountRestriction'}
          error={errors[AMOUNT_RESTRICTION]}
          value={values[AMOUNT_RESTRICTION]}
          placeholder={'EnterAmountRestriction'}
          handleChange={handleChange}
        />
      )}
      {(values[HOUSE_KEEPER] || isOpen) && (
        <FieldRow
          name={HOUSE_KEEPER}
          active={active}
          label={'HouseKeeper'}
          error={errors[HOUSE_KEEPER]}
          value={values[HOUSE_KEEPER]}
          placeholder={'EnterHouseKeeper'}
          handleChange={handleChange}
        />
      )}
      {(values[CONTRACT_START] || isOpen) && (
        <FieldRow
          name={CONTRACT_START}
          value={values[CONTRACT_START]}
          label={'ContractStart'}
          error={errors[CONTRACT_START]}
          active={active}
          type={'date'}
          placeholder={'EnterContractStart'}
          handleChange={handleChangeContractStart}
        />
      )}
      {(values[CONTRACT_END] || isOpen) && (
        <FieldRow
          name={CONTRACT_END}
          value={values[CONTRACT_END]}
          label={'ContractEnd'}
          error={errors[CONTRACT_END]}
          active={active}
          type={'date'}
          placeholder={'EnterContractEnd'}
          handleChange={handleChangeContractEnd}
        />
      )}
      {active && (
        <div className={styles.buttonWrapper}>
          <Button.Save disabled={!dirty || isSubmitting} type='submit'>
            {t('Common:Save')}
          </Button.Save>
          <Button.Cancel onClick={onClosed}>{t('Common:Cancel')}</Button.Cancel>
        </div>
      )}
    </form>
  )
}

export default ObjectInfoForm
