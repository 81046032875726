// @flow

import { put, call, fork, takeLatest } from 'redux-saga/effects'

import { serverError } from '../../components/Layout/Layout.actions'
import * as actions from './WarningStripe.actionTypes'
import { deleteDemoData } from '../../core/api/api.system'

function* watchDelete() {
  yield takeLatest(actions.CONFIRM_DELETE, load)
}

function* load() {
  try {
    yield call(deleteDemoData)
    yield put({
      type: actions.DELETED,
    })
    window.location.reload()
  } catch (error) {
    yield put(serverError(error))
  }
}

export default function* watch() {
  yield fork(watchDelete)
}
