// @flow

import React from 'react'
import classNames from 'classnames'
import type { Node } from 'react'

import styles from './ExpandArrow.module.scss'

import Icon from '../../../static/icons/expand.svg'

type Props = {
  className?: string,
  direction: 'top' | 'bottom' | 'right' | 'left',
  disabled?: boolean,
}

const ExpandArrow = (props: Props): Node => {
  const { direction, disabled, className } = props

  const iconClass = classNames(styles[direction], className, {
    [styles.disabled]: disabled,
  })

  return <Icon className={iconClass} />
}

ExpandArrow.defaultProps = {
  direction: 'top',
  disabled: false,
}

export default ExpandArrow
