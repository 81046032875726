// @flow

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import Joi from 'joi-browser'
import validation from 'react-validation-mixin'
import strategy from 'joi-browser-validation-strategy'
import { join } from 'lodash-es'
import classnames from 'classnames'
import { compose } from 'redux'

import Button from '../../Button'
import * as a from './LabelsPopup.actionTypes'
import Modal from '../../Modal/Modal'
import ConfirmationPopup from '../ConfirmationPopup'

const MAX_LABEL_LENGTH = 100

class EditLabelPopup extends Component {
  static propTypes = {
    t: PropTypes.func,
    onHide: PropTypes.func,
    onCreate: PropTypes.func,
    validate: PropTypes.func,
    getValidationMessages: PropTypes.func,
    isValid: PropTypes.bool,
    label: PropTypes.object,
    dispatch: PropTypes.func,
    onDelete: PropTypes.func,
  }

  state = {
    name: '',
    selectedColor: '#00b63b',
    working: false,
    deleteConfirmation: false,
  }

  componentDidMount() {
    const { label } = this.props

    if (label) {
      this.setState({ name: label.name, selectedColor: label.color.value })
    }
  }

  showDeleteConfirm = () => {
    this.setState({
      deleteConfirmation: (
        <ConfirmationPopup
          title={this.props.t('LabelDeleteWarning')}
          text={this.props.t('LabelDelete')}
          confirm={this.props.t('LabelOk')}
          onClose={this.hideDeleteConfirm}
          onOk={this.onDeleteClick}
        />
      ),
    })
  }

  hideDeleteConfirm = () => {
    this.setState({ deleteConfirmation: false })
  }

  onDeleteClick = () => {
    const { label, dispatch, onDelete, onCreate } = this.props

    if (!label) {
      if (onCreate) {
        onCreate()
      }

      return
    }

    dispatch({ type: a.LABELS_MODAL_LABEL_DELETE, id: label.id, cb: onDelete })
  }

  onOkButtonClick = () => {
    const { validate, dispatch, onCreate, onDelete } = this.props
    const { name, selectedColor } = this.state

    validate(err => {
      if (err === undefined) {
        this.setState({ working: true })
        const { label } = this.props

        if (!label) {
          dispatch({
            type: a.LABELS_MODAL_LABEL_CREATE,
            name,
            color: selectedColor,
            cb: onCreate,
          })
        } else if (label.name !== name || label.color.value !== selectedColor) {
          dispatch({
            type: a.LABELS_MODAL_LABEL_UPDATE,
            name,
            color: selectedColor,
            id: label.id,
            cb: onCreate,
          })
        } else {
          dispatch({
            type: a.LABELS_MODAL_CANCELED_LABEL,
          })
          onDelete()
        }
      }
    })
  }

  getValidatorData = () => {
    const { name } = this.state

    return { name }
  }

  colors = [
    '#00b63b',
    '#fcd100',
    '#ffa238',
    '#ff4f3b',
    '#d46ce0',
    '#00bcdd',
    '#006fbb',
    '#00e58a',
    '#ff75cb',
    '#424242',
    '#3dcadd',
    '#a9f6bc',
    '#ffdf9b',
    '#5b6f80',
    '#b8b8b8',
  ]

  validatorTypes = {
    name: Joi.string()
      .required()
      .options({
        language: {
          any: { empty: `!! ${this.props.t('fieldIsRequired')}` },
          label: 'name',
        },
      }),
  }

  updateName = e => {
    this.setState({ name: e.target.value })
  }

  updateColor = color => {
    this.setState({ selectedColor: color })
  }

  renderError = field => {
    const { getValidationMessages } = this.props
    const validationMessages = getValidationMessages(field)
    const html = join(validationMessages, '<br />')

    return validationMessages.length > 0 ? (
      <div
        className='input__msg'
        dangerouslySetInnerHTML={{ __html: html }} // eslint-disable-line react/no-danger
      />
    ) : null
  }

  renderColorList() {
    const { selectedColor } = this.state
    const makeColorItem = color => {
      const selected = selectedColor === color
      const className = classnames('color-list-item', {
        'color-list-item-selected': selected,
      })
      const onClick = () => this.updateColor(color)
      const props = {
        className,
        onClick,
        key: color,
        style: { backgroundColor: color },
      }

      return <div {...props} />
    }

    return (
      <div className='label-color-list'>{this.colors.map(makeColorItem)}</div>
    )
  }

  render() {
    const { onHide, t, isValid } = this.props

    const { working, name, deleteConfirmation } = this.state
    const nameInputClassName = `input input--medium input--block input--default ${
      isValid('name') ? '' : 'input--error'
    } modal-addchecklist__namefield`

    return (
      <div className='modal-labels'>
        <button className='modal__close' type='button' onClick={onHide} />
        <div className='modal__title'>{t('EditLabelPopupTitle')}</div>
        <div className='modal__body'>
          <div className='modal__row'>
            <span className='m-a10__label'>{t('LabelName')}</span>
          </div>
          <div className={nameInputClassName}>
            <input
              autoFocus
              type='text'
              maxLength={MAX_LABEL_LENGTH}
              placeholder={t('EmptyLabelName')}
              value={name}
              onChange={this.updateName}
            />
            {this.renderError('name')}
          </div>

          <div className='modal__row'>
            <span className='m-a10__label'>{t('Color')}</span>
          </div>
          {this.renderColorList()}
        </div>
        <div className='modal__submit'>
          <Button.Save
            disabled={!name.trim()}
            working={working}
            onClick={this.onOkButtonClick}
          >
            {t('Back')}
          </Button.Save>
          <Button.Cancel onClick={this.showDeleteConfirm}>
            {t('Delete')}
          </Button.Cancel>
        </div>
        {working && <div className='overlay' />}
        {deleteConfirmation && (
          <Modal
            contentLabel=''
            className='Modal__Bootstrap modal-dialog'
            isOpen={!!deleteConfirmation}
            onRequestClose={this.hideDeleteConfirm}
          >
            {deleteConfirmation}
          </Modal>
        )}
      </div>
    )
  }
}

export default compose(
  withTranslation('Labels'),
  connect(),
  validation(strategy)
)(EditLabelPopup)
