// @flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Row } from '../../Block'
import Button from '../../Button'
import AudienceItem from '../AudienceItem'
import Modal from '../../Modal'
import SetPostScope from '../../modals/SetPostScope'

import styles from './PostAudience.module.scss'

const PostAudience = props => {
  const {
    allBuildings,
    audience,
    automate,
    buildings,
    newAudience,
    editing,
    postType,
    requestBuilding,
    isGroupsPost,
  } = props

  const { t } = useTranslation('Post')

  const [modal, setModal] = useState(null)

  const hideModal = () => {
    setModal(null)
  }

  const handleOk = (
    audience,
    buildings,
    allBuildings,
    isGroupsPost,
    requestBuilding
  ) => {
    hideModal()
    props.updateAudience(
      audience,
      buildings,
      allBuildings,
      isGroupsPost,
      requestBuilding
    )
  }

  const handleClick = () => {
    const modal = (
      <SetPostScope
        allBuildings={allBuildings}
        automate={automate}
        isGroupsPost={isGroupsPost}
        buildings={buildings}
        scope={audience}
        postType={postType}
        requestBuilding={requestBuilding}
        onClose={hideModal}
        onOk={handleOk}
      />
    )

    setModal(modal)
  }

  return (
    <Row
      className={automate ? styles.automateAudienceRow : undefined}
      title={t('Audience')}
      onEdit={props.onEdit}
    >
      <div className={styles.audience}>
        {newAudience.map(item => (
          <AudienceItem
            automate={automate}
            editing={editing}
            key={item.id}
            item={item}
            handleClick={props.removeItem}
          />
        ))}
        {editing && <Button.Add title={t('addScope')} onClick={handleClick} />}
      </div>
      <Modal isOpen={!!modal} style={{ content: { width: '370px' } }}>
        {modal}
      </Modal>
    </Row>
  )
}

export default PostAudience
