import React from 'react'
import styles from './ErrorPage.module.scss'
import { getText, TITLE, TEXT } from './helpers'
import { get } from 'lodash'

const ErrorPage = ({ t, status }) => {
  const Title = get(getText, [[status], [TITLE]], 'BadRequest')
  const Text = get(getText, [[status], [TEXT]], 'CheckPageAddress')

  return (
    <div className={styles.errorPage}>
      <section className={styles.content}>
        <div className={styles.container}>
          <div className={styles.image}>
            <img src='/static/images/error-page.svg' alt='error' />
          </div>
          <h1 className={styles.title}>{t(Title)}</h1>
          <div className={styles.text}>
            <p>{t(Text)}</p>
            <p>
              {t('Report', {
                email: 'support@idwell.com',
              })}
            </p>
          </div>
        </div>
      </section>
    </div>
  )
}

export default ErrorPage
