// @flow

import React from 'react'
import { createFragmentContainer, graphql } from 'react-relay'
import { useTranslation } from 'react-i18next'

import { EntityIcon } from '../../../../components/Icon'

import styles from './OrderProfileHeader.module.scss'

const OrderProfileHeader = props => {
  const { t } = useTranslation('Orders')

  const {
    data: { orderNo, offer: offerJSON },
  } = props

  const offerData = JSON.parse(offerJSON)

  const offerText = offerData.name

  const orderText = t('orderNo', { orderNo })

  return (
    <div className={styles.header}>
      <EntityIcon large id='order' />
      <div className={styles['header-name']}>
        <span>{orderText}</span>
        <span
          style={{
            marginLeft: 10,
            color: '#939393',
            fontSize: 27,
            fontWeight: 300,
          }}
        >
          {offerText}
        </span>
      </div>
    </div>
  )
}

export default createFragmentContainer(OrderProfileHeader, {
  data: graphql`
    fragment OrderProfileHeader_data on CustomerOrderNode {
      orderNo
      offer
    }
  `,
})
