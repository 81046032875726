// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'
import classnames from 'classnames'

import FlatAddressHeader from './FlatAddressHeader'
import FlatAddressView from './FlatAddressView'
import FlatAddressEditing from './FlatAddressEditing'
import { getLanguageCode, getUkCity } from '../../../utils/commonSelectors'
import FlatAddressContext from './FlatAddress.context'
import {
  getInitialValues,
  getPreparedValues,
  isChanged,
} from './FlatAddress.utils'
import { FLAT_VIEW_CLEAR_ERROR } from '../../../containers/FlatView/FlatView.actionTypes'
import StaticMap from '../../StaticMap'

export type Flat = {
  entrance: ?string,
  floor: ?string,
  number: ?string,
  permissions: {
    can_edit: boolean,
  },
}

type Props = {
  address: Object,
  building: number,
  canEditFlatNumber?: boolean,
  city: ?Object,
  flat?: ?Flat,
  flatId: ?number,
  lang: string,
  marginTop: boolean,
  onSave?: Object => void,
  saving?: boolean,
  status?: number,
  working?: boolean,
}

export type State = {
  editing: boolean,
  entrance: ?string,
  floor: ?string,
  number: ?string,
}

class FlatAddress extends Component<Props, State> {
  state = {
    editing: false,
    ...getInitialValues(this.props),
  }

  componentDidUpdate(prevProps: Props) {
    const { working, error } = this.props

    if (prevProps.working && !working) {
      this.setState({ editing: false })
    }

    if (!prevProps.error && error) {
      this.cancel()
    }
  }

  edit = () => {
    this.setState({ editing: true })
    this.props.dispatch({ type: FLAT_VIEW_CLEAR_ERROR })
  }

  cancel = () => {
    this.setState({
      editing: false,
      ...getInitialValues(this.props),
    })
  }

  save = () => {
    if (!this.props.onSave) {
      return
    }

    this.props.onSave(getPreparedValues(this.state))
    this.setState({ editing: false })
  }

  updateField = (name, value) => {
    this.setState({ [name]: value.trim() })
  }

  canSave = () => {
    const { number } = this.state

    return isChanged(this.state, this.props) && number
  }

  render() {
    const {
      address,
      // city,
      saving,
      flat,
      working,
      marginTop,
      status,
      building,
      flatId,
      canEditFlatNumber,
    } = this.props

    const sectionClass = classnames('unit unit--default flat-address', {
      'working-overlay': saving || working,
      'apartment-profile ': !marginTop,
    })

    const { editing, entrance, floor, number } = this.state

    return (
      <section className={sectionClass}>
        <FlatAddressHeader
          flat={flat}
          editing={editing}
          canEditFlatNumber={canEditFlatNumber}
          edit={this.edit}
          status={status}
          onSave={this.props.onSave}
        />
        {editing ? (
          <FlatAddressEditing
            entrance={entrance}
            floor={floor}
            number={number}
            address={address}
            saving={saving}
            updateField={this.updateField}
            save={this.save}
            canSave={this.canSave}
            cancel={this.cancel}
            flat={flat}
          />
        ) : (
          <FlatAddressContext.Provider
            value={{ entrance, floor, number, flat, flatId }}
          >
            <FlatAddressView
              flat={flat}
              address={address}
              building={building}
            />
          </FlatAddressContext.Provider>
        )}
        <div className='apartment-profile__map'>
          <StaticMap address={address} size='828x383' />
        </div>
        {/* <FlatAddressMap address={address} city={city} flat={flat} /> */}
      </section>
    )
  }
}

const mapStateToProps = state => ({
  city: getUkCity(state),
  lang: getLanguageCode(state),
})

export default connect(mapStateToProps)(FlatAddress)
