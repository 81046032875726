// @flow

import React, { PureComponent } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash-es'
import { withTranslation } from 'react-i18next'
import { push } from 'connected-react-router'

import Loader from '../../Loader'
import EmptyList from '../../EmptyList'
import FeedbackPost from '../../../containers/Feedback/FeedbackPost'
import { loadFeedbackList } from './RequestFeedback.actions'
import { feedbackWasLoaded } from '../../../containers/Feedback/Feedback.actions'
import * as selectors from './RequestFeedback.selectors'

type Props = {
  feedbackList: Array<Object>,
  isLoading: boolean,
  loadFeedback: () => void,
  push: string => void,
  request: Object,
  t: string => string,
}

class RequestFeedback extends PureComponent<Props> {
  componentDidMount() {
    this.props.loadFeedback()
  }

  createFeedback = e => {
    if (e) {
      e.preventDefault()
    }

    const {
      request: { id },
    } = this.props

    this.props.push(`/request/${id}/feedback/create/`)
  }

  renderEmpty = () => {
    const {
      request: { permissions },
    } = this.props

    return (
      <section className='unit unit--default request-docs-nomargin'>
        <h2 className='unit__title'>{this.props.t('Feedback')}</h2>
        <EmptyList
          embedded
          btnText={this.props.t('AddFeedback')}
          canAdd={permissions.can_add_feedback}
          icon='review'
          title={this.props.t('EmptyFeedback')}
          onClick={this.createFeedback}
        />
      </section>
    )
  }

  render() {
    const { feedbackList, isLoading } = this.props

    if (isLoading) {
      return <Loader type='medium' text={false} />
    }

    if (isEmpty(feedbackList)) {
      return this.renderEmpty()
    }

    return <FeedbackPost />
  }
}

const mapStateToProps = state => ({
  isLoading: selectors.isLoading(state),
  feedbackList: selectors.getFeedbackList(state),
  request: state.requestView.data,
})

const mapDispatchToProps = {
  loadFeedback: loadFeedbackList,
  feedbackWasLoaded,
  push,
}

export default compose(
  withTranslation('RequestFeedback'),
  connect(mapStateToProps, mapDispatchToProps)
)(RequestFeedback)
