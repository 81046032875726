// @flow

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'

import Button from '../../Button'
import LabelItem from '../../Labels/LabelItem'
import Loader from '../../Loader'

import * as a from './LabelsPopup.actionTypes'

class LabelsPopUp extends Component {
  static propTypes = {
    t: PropTypes.func,
    showEditLabelModal: PropTypes.func.isRequired,
    onHide: PropTypes.func,
    onSave: PropTypes.func,
    labels: PropTypes.array,
    parentEntity: PropTypes.object,
    dispatch: PropTypes.func,
    working: PropTypes.bool,
    initiated: PropTypes.bool,
  }

  componentDidMount() {
    const { parentEntity } = this.props
    this.props.dispatch({ type: a.LABELS_MODAL_INIT, parentEntity })
    document.body.style.overflowY = 'hidden'
  }

  componentWillUnmount = () => {
    document.body.style.overflowY = 'scroll'
  }

  onClickAddLabel = () => {
    this.props.showEditLabelModal()
  }

  onEditLabel = label => {
    this.props.showEditLabelModal({ label })
  }

  onSelectLabel = label => {
    label.checked = !label.checked // eslint-disable-line
    this.props.dispatch({ type: a.LABELS_MODAL_LABEL_UPDATED, label })
  }

  onClickSave = () => {
    const { labels, onSave } = this.props
    onSave(labels)
  }

  render() {
    const { onHide, t, labels, working, initiated } = this.props

    return (
      <div className='modal-labels-manage'>
        <button className='modal__close' type='button' onClick={onHide} />
        <div className='modal__title '>{t('LabelsManagePopupTitle')}</div>
        <div className='modal__body'>
          <div className='modal__row'>
            {!initiated && <Loader text={false} type='medium' />}
            <div className='labels-list'>
              {labels.map(label => (
                <LabelItem
                  key={label.id}
                  label={label}
                  onEdit={() => this.onEditLabel(label)}
                  onSelect={() => this.onSelectLabel(label)}
                />
              ))}
            </div>
          </div>

          <Button.Text
            className='modal__row'
            icon='plus'
            title={this.props.t('AddNewLabelButton')}
            onClick={this.onClickAddLabel}
          />
        </div>
        <div className='modal__submit'>
          <Button.Save onClick={this.onClickSave}>{t('Save')}</Button.Save>
          <Button.Cancel onClick={onHide}>{t('Cancel')}</Button.Cancel>
        </div>
        {working && <div className='modal__overlay' />}
      </div>
    )
  }
}

const mapStateToProps = state => state.labelsPopup

export default compose(
  withTranslation('Labels'),
  connect(mapStateToProps)
)(LabelsPopUp)
