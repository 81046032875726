// @flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Textarea from 'react-textarea-autosize'
import type { Node } from 'react'

import Modal from '../Modal'
import { useOverflow } from '../../hooks'

import NewSelectAsync from '../NewSelectAsync'
import ModalHead from '../Modal/ModalHead'
import ModalCloseButton from '../Modal/ModalCloseButton'
import ModalBody from '../Modal/ModalBody'
import ModalButtons from '../Modal/ModalButtons'
import Button from '../Button'
import ModalRow from '../Modal/ModalRow/ModalRow'
import ModalCell from '../Modal/ModalCell'
import ModalLabel from '../Modal/ModalLabel'
import ModalError from '../Modal/ModalError'
import InputField from '../InputField'
import Warning from '../Warning'

import AddKeyModal from './AddKeyModal'

import { getDwellerOptionList } from '../../core/api/api.profile'

import {
  addInboundConfig,
  addOutboundConfig,
} from '../../core/api/api.idwell2idwell'

import styles from './ConnectedCompanies.module.scss'

type Props = {
  isOpen: boolean,
  onClose: (?boolean) => void,
  outbound: boolean,
}

const AddCompanyModal = (props: Props): Node => {
  const { isOpen, outbound } = props

  const { t } = useTranslation('IdwellToIdwell')

  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')
  const [active_key, setActiveKey] = useState('')
  const [user, setUser] = useState(null)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(false)

  useOverflow()

  const handleChangeName = e => setName(e.target.value)
  const handleChangeKey = e => setActiveKey(e.target.value)
  const handleSelectUser = option => setUser(option)

  const handleClose = () => {
    props.onClose()
  }

  const handleAddCompany = () => {
    setError(null)
    setLoading(true)
    const addApi = outbound ? addOutboundConfig : addInboundConfig
    const params = Object.assign(
      {},
      { name },
      outbound ? { active_key } : { inbound_user: user.id }
    )
    addApi(params)
      .then(data => {
        if (!outbound) {
          setActiveKey(data.active_key)
          setSuccess(true)
        } else {
          props.onClose(true)
        }
      })
      .catch(err => {
        setError(err.message?.response?.data?.errors.active_key)
      })
      .finally(() => setLoading(false))
  }

  const isSaveDisabled = () => {
    if (!name) {
      return true
    }

    if (outbound && !active_key) {
      return true
    }

    return !outbound && !user
  }

  if (success) {
    return (
      <AddKeyModal
        isOpen
        activeKey={active_key}
        onClose={() => props.onClose(true)}
      />
    )
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={handleClose}>
      <ModalHead title={t('AddCompanyModalTitle')} />
      <ModalCloseButton onClose={handleClose} />
      <ModalBody style={{ marginBottom: 0 }}>
        <ModalRow>
          <ModalCell>
            <ModalLabel>{t('CompanyName')}</ModalLabel>
          </ModalCell>
          <ModalCell gridNumber={2}>
            <InputField
              autoFocus
              disabled={loading}
              maxLength={128}
              name='name'
              placeholder={t('CompanyNamePlaceholder')}
              value={name}
              onChange={handleChangeName}
            />
          </ModalCell>
        </ModalRow>
        {!outbound && (
          <ModalRow>
            <ModalCell>
              <ModalLabel>{t('PartnerUser')}</ModalLabel>
            </ModalCell>
            <ModalCell gridNumber={2}>
              <NewSelectAsync
                style={{ width: '100%' }}
                pageSize={20}
                api={getDwellerOptionList}
                searchKey='fullname'
                placeholder={t('SelectUserPlaceholder')}
                getLabel={o => o.fullname}
                getSelectedLabel={o => o.fullname}
                view='dropdown'
                selectedItems={user ? [user] : []}
                permanentParams={{ archived: false, status: 'active' }}
                onClick={handleSelectUser}
              />
            </ModalCell>
          </ModalRow>
        )}
        {outbound && (
          <>
            <ModalRow>
              <ModalCell>
                <ModalLabel>{t('AccessKey')}</ModalLabel>
              </ModalCell>
              <ModalCell gridNumber={2}>
                <Textarea
                  className={styles.textarea}
                  name={name}
                  value={active_key}
                  placeholder={t('AccessKeyPlaceholder')}
                  minRows={3}
                  maxRows={5}
                  maxLength={256}
                  onChange={handleChangeKey}
                />
              </ModalCell>
            </ModalRow>
            <ModalRow>
              <Warning
                noArrow
                text={t('HideKeyNotice')}
                boxClassName={styles.warningBox}
                className={styles.warning}
              />
            </ModalRow>
          </>
        )}
        {error && (
          <ModalRow>
            <ModalError error={t(`Errors:${error}`)} />
          </ModalRow>
        )}
        <ModalButtons>
          <Button.Save
            working={loading}
            disabled={loading || isSaveDisabled()}
            onClick={handleAddCompany}
          >
            {t('Common:Save')}
          </Button.Save>
          <Button.Cancel disabled={loading} onClick={handleClose}>
            {t('Common:Cancel')}
          </Button.Cancel>
        </ModalButtons>
      </ModalBody>
    </Modal>
  )
}

export default AddCompanyModal
