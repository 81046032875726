// @flow

import { noramlizeValidationValues } from '../../utils/utils'
import {
  USE_SSL_VALUE,
  USE_TLS_VALUE,
  NO_ENCRYPT_VALUE,
  COMMON_USE_SSL_FIELD,
  COMMON_USE_TLS_FIELD,
} from './EmailIntegration.constants'

export function getInitialValues(
  initialParams?: ?Object,
  defaultValues: Object,
  keys: Array<string>
): Object {
  return initialParams
    ? noramlizeValidationValues(initialParams, keys)
    : defaultValues
}

export function getEncryption(initialValues: Object): string {
  const { [COMMON_USE_SSL_FIELD]: useSsl, [COMMON_USE_TLS_FIELD]: useTls } =
    initialValues

  if (useSsl) {
    return USE_SSL_VALUE
  }

  if (useTls) {
    return USE_TLS_VALUE
  }

  return NO_ENCRYPT_VALUE
}
