import React, { useState } from 'react'
import classnames from 'classnames'
import { createFragmentContainer, graphql } from 'react-relay'
import { useTranslation } from 'react-i18next'
import TileList from '../../components/TileList'
import Offer from '../Offers/Offer'
import Pagination from '../../ui/Pagination'

import EmptyList from '../../components/EmptyList'

const PAGE_SIZE = 15

const ProviderProfileOffers = props => {
  const {
    query: { allOffersByProvider },
    provider: { id, image, name },
  } = props
  const [currentPage, setCurrentPage] = useState(1)
  const [loading, setLoading] = useState(false)

  const fetchData = page => {
    setLoading(true)

    props.refetch({ page }, null, () => {
      setLoading(false)
      setCurrentPage(page)
    })
  }

  const { t } = useTranslation('Offers')
  const title = t('noOffersFound')

  if (!allOffersByProvider || allOffersByProvider.edges.length === 0) {
    return <EmptyList embedded icon='offer' title={title} />
  } else {
    const offersList = allOffersByProvider.edges.map(offer => ({
      node: {
        ...offer.node,
        provider: {
          id,
          image,
          name,
        },
      },
    }))

    const containerClass = classnames({ 'working-overlay': loading })

    return (
      <div className={containerClass}>
        <TileList rowLength={5}>
          {offersList.map(({ node }, i) => (
            <Offer key={`offer-${i}`} offer={node} />
          ))}
        </TileList>
        <Pagination
          currentPage={currentPage}
          pageSize={PAGE_SIZE}
          totalCount={allOffersByProvider.totalCount}
          onPageChange={fetchData}
        />
      </div>
    )
  }
}

export default createFragmentContainer(ProviderProfileOffers, {
  query: graphql`
    fragment ProviderProfileOffers_query on Query
    @argumentDefinitions(
      providerGlobalId: { type: "String" }
      page: { type: Float, defaultValue: 1 }
      pageSize: { type: Float, defaultValue: 15 }
      first: { type: Int, defaultValue: 15 }
    ) {
      allOffersByProvider(
        providerGlobalId: $providerGlobalId
        first: $first
        pageSize: $pageSize
        page: $page
      ) @connection(key: "ProviderProfileOffers_allOffersByProvider") {
        edges {
          node {
            ...Offer_offer
          }
        }
        totalCount
      }
    }
  `,
})
