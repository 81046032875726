// @flow

import React from 'react'
import type { Node } from 'react'

import {
  STRING_VARIABLE_TYPE,
  INTEGER_VARIABLE_TYPE,
  EMAIL_VARIABLE_TYPE,
  TEXT_VARIABLE_TYPE,
  PHONE_VARIABLE_TYPE,
  FILE_VARIABLE_TYPE,
} from '../../CardStage.constants'
import Input from './Input'
import TextInput from './TextInput'
import PhoneInput from './PhoneInput'
import type { CardType } from '../../../NewWizard.types'
import FileInput from './FileInput'

type Props = {| card: CardType |}

const Variable = (props: Props): Node => {
  const { card } = props

  return (
    <>
      {(card.variable_type === STRING_VARIABLE_TYPE ||
        card.variable_type === INTEGER_VARIABLE_TYPE ||
        card.variable_type === EMAIL_VARIABLE_TYPE) && <Input card={card} />}
      {card.variable_type === PHONE_VARIABLE_TYPE && <PhoneInput card={card} />}
      {card.variable_type === TEXT_VARIABLE_TYPE && <TextInput card={card} />}
      {card.variable_type === FILE_VARIABLE_TYPE && <FileInput card={card} />}
    </>
  )
}

export default Variable
