// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import NewTableBodyRow from '../../NewTable/NewTableBodyRow'
import NewTableBodyCell from '../../NewTable/NewTableBodyCell'
import Button from '../../Button'
import Checkbox from '../../Checkbox'

type Props = {
  folder: Object,
  onChangeSelected: number => void,
  onUpdateFolder: (id: number, is_sync: boolean) => void,
  selectedItems: Array<number>,
}

const FolderSyncTableRow = (props: Props): Node => {
  const {
    folder: { display_name, id, is_sync },
    onUpdateFolder,
    selectedItems,
    onChangeSelected,
  } = props
  const { t } = useTranslation('EmailIntegrationNylas')

  const syncColor = is_sync ? '#9ec221' : '#e44440'

  const handleUpdate = () => onUpdateFolder && onUpdateFolder(id, !is_sync)
  const handleChangeSelected = () => onChangeSelected && onChangeSelected(id)

  return (
    <NewTableBodyRow key={id} id={id}>
      <NewTableBodyCell title={display_name}>
        <Checkbox
          style={{ marginLeft: '12px', marginRight: '12px' }}
          checked={selectedItems.includes(id)}
          onChange={handleChangeSelected}
        />
      </NewTableBodyCell>

      <NewTableBodyCell>
        <div className='table-item table-item__text'>
          <span style={{ color: syncColor }}>
            {t(is_sync ? 'FolderSynced' : 'FolderNotSynced')}
          </span>
        </div>
      </NewTableBodyCell>
      <NewTableBodyCell>
        <div className='table-extra'>
          {is_sync ? (
            <Button.Cancel onClick={handleUpdate}>
              {t('FolderDisconnect')}
            </Button.Cancel>
          ) : (
            <Button.Save onClick={handleUpdate}>
              {t('FolderConnect')}
            </Button.Save>
          )}
        </div>
      </NewTableBodyCell>
    </NewTableBodyRow>
  )
}

export default FolderSyncTableRow
