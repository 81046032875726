// @flow

import React from 'react'
import classnames from 'classnames'
import type { Node } from 'react'

import ExpandArrow from '../../ExpandArrow'
import Regular from '../Regular'
import Cross from '../Cross'
import {
  getAvatar,
  getSelectedText,
  hasSelected,
  getMeta,
} from './NewSelect.utils'
import type { Props as ButtonProps } from '../Regular/Regular'

import styles from './NewSelect.module.scss'

type Props = ButtonProps & {
  avatar?: string,
  buttonClass?: string,
  disabled?: boolean,
  getMeta?: (Object, boolean) => Node,
  getSelectedLabel: Function,
  menuIsOpen: boolean,
  name?: string,
  onClear?: Object => void,
  placeholder: string,
  selectedItems: Array<Object>,
}

const NewSelect = (props: Props): Node => {
  const {
    disabled,
    error,
    buttonClass,
    placeholder,
    selectedItems,
    menuIsOpen,
  } = props

  const hasSelectedItems = hasSelected(selectedItems)

  const text = hasSelectedItems
    ? getSelectedText(selectedItems, props.getSelectedLabel)
    : placeholder

  const buttonClassName = classnames(styles.button, buttonClass, {
    [styles.placeholder]: !hasSelectedItems,
  })

  const direction = menuIsOpen ? 'bottom' : 'top'

  const MetaComponent = getMeta(selectedItems, props.getMeta)

  return (
    <Regular
      {...props}
      title={text}
      avatar={getAvatar(selectedItems)}
      error={error}
      view='desaturated'
      meta={
        <>
          {MetaComponent}
          {!disabled && !(hasSelectedItems && props.onClear) && (
            <ExpandArrow direction={direction} />
          )}
          {!disabled && props.onClear && hasSelectedItems && (
            <Cross onClick={props.onClear} />
          )}
        </>
      }
      className={buttonClassName}
    >
      {text}
    </Regular>
  )
}

NewSelect.defaultProps = {
  disabled: false,
}

export default NewSelect
