// @flow

import moment from 'moment'

export const isExpired = (initialDateTo?: ?string): boolean => {
  if (!initialDateTo) {
    return false
  }

  return moment(initialDateTo).unix() < moment().unix()
}

export const isUpcoming = (initialDateFrom?: ?string): boolean => {
  if (!initialDateFrom) {
    return false
  }

  return moment(initialDateFrom).unix() > moment().unix()
}

export const isAppropriateInterval = (
  initialDateFrom?: ?string,
  initialDateTo?: ?string
): boolean => {
  return !isExpired(initialDateTo) && !isUpcoming(initialDateFrom)
}
