// @flow

import { getFetchedItems } from '../../../utils/utils'
import {
  NYLAS,
  NYLAS_V3,
} from '../../Menu/filters/MailsCredentialFilter/MailsCredentialFilter.constants'

export const getAccounts = data => {
  return getFetchedItems(data)
    .map(item => {
      if (
        !item.outgoing &&
        item.account_type !== NYLAS &&
        item.account_type !== NYLAS_V3
      ) {
        return null
      }

      if (item.account_type === NYLAS || item.account_type === NYLAS_V3) {
        return {
          ...item,
          email_from: item.email,
          is_info: item.description === 'info',
          is_system: item.description === 'service',
          signature: item.signature || '',
          corporate: item.corporate,
          email_from_name: item.email_from_name,
          insert_signature_before_quoted_text:
            item.insert_signature_before_quoted_text,
        }
      } else {
        return {
          ...item.outgoing,
          is_info: item.description === 'info',
          is_system: item.description === 'service',
          signature: item.signature || '',
          corporate: item.corporate,
          email_from_name: item.email_from_name,
          insert_signature_before_quoted_text:
            item.insert_signature_before_quoted_text,
        }
      }
    })
    .filter(item => !!item)
}

export function getInitialValues(
  initialValues?: Object,
  threadTitle?: string,
  signature?: string
): Object {
  let values = {
    bcc: [],
    cc: [],
    files: [],
    message: signature || '',
    title: threadTitle || '',
    to: [],
  }

  if (!initialValues) {
    return values
  }

  return initialValues
}

export function getUserIdOrEmail(user: Object): string {
  if (user.profile) {
    return (
      user.profile.owner ||
      user.profile.id ||
      user.profile.user ||
      user.profile.email
    )
  }

  return user.name
}

export function getUsersEmails(users: Array<Object>): Array<string> {
  return users ? users.map(getUserIdOrEmail) : []
}

export function getInitialFiles(
  values?: Object,
  initial?: Array
): Array<Object> {
  if (initial) {
    return initial
  }

  if (values && values.files) {
    return values.files
  }

  return []
}
