// @flow

import * as actions from './CompanyLogo.actionTypes'

const initialState = {
  saving: false,
  error: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.COMPANY_LOGO_SAVE:
      return {
        ...state,
        saving: true,
      }
    case actions.COMPANY_LOGO_WAS_SAVED:
      return {
        ...state,
        saving: false,
      }
    case actions.COMPANY_LOGO_RESET:
      return initialState
    case actions.COMPANY_LOGO_ERROR:
      return {
        ...state,
        saving: false,
        error: action.error.message,
      }
    default:
      return state
  }
}
