// @flow

import React from 'react'
import type { Node } from 'react'

import { useThemeColor } from '../../../../hooks'
import InlineSvg from '../../../InlineSvg'

import styles from './ActivityModalType.module.scss'

type Props = {
  activityTypes: Array<Object>,
  handleChangeType: Function,
  value: Object,
}

const ActivityModalType = (props: Props): Node => {
  const {
    activityTypes,
    value: { id: currentId },
  } = props

  const themeColor = useThemeColor()

  return (
    <div className={styles['icon-box']} style={{ '--themeColor': themeColor }}>
      {activityTypes.map(activityType => {
        const {
          name,
          id,
          icon: { origin },
        } = activityType

        return (
          <div
            title={name}
            key={id}
            className={currentId === id ? styles.active : ''}
            onClick={() => props.handleChangeType(activityType)}
          >
            <InlineSvg url={origin} />
          </div>
        )
      })}
    </div>
  )
}

export default ActivityModalType
