// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'
import { compose } from 'redux'

import { SimpleBlock, Header } from '../../Block'
import Settings from '../../Settings'
import Colors from './Colors'

import styles from './CompanyTheme.module.scss'

type Props = {
  onCancel: () => void,
  onSave: () => void,
  onUpdate: () => void,
  t: string => string,
  theme: Object,
  working: boolean,
}

class ThemeSettings extends Component<Props> {
  updateColor = (name, color) => {
    const changedColor = {}
    changedColor[name] = color
    this.props.onUpdate(changedColor)
  }

  renderColorList(name) {
    const active = this.props.theme[name]
    const makeColorItem = color => {
      const selected = active && active.toLowerCase() === color.toLowerCase()
      const className = classnames('color-list-item', {
        'color-list-item-selected': selected,
        'color-list-item-black': color === '#ffffff',
      })
      const onClick = () => this.updateColor(name, color)
      const props = {
        className,
        onClick,
        key: color,
        style: { backgroundColor: color },
      }

      return <div {...props} />
    }

    return (
      <div className='settings-color-list'>
        {Colors[name].map(makeColorItem)}
      </div>
    )
  }

  render() {
    const { working, t, isNewDesignAvailable } = this.props

    return (
      <SimpleBlock working={working}>
        <Header header>{t('StylesSectionTitle')}</Header>
        <div className='settings-personal__form'>
          <div className='settings-personal__form-row'>
            <label className='settings-personal__form-label'>
              {t('MainColorTitle')}
            </label>
          </div>
          <div className='settings-personal__form-row'>
            {this.renderColorList('header')}
          </div>
          <div className='settings-personal__form-row theme-first-row-field'>
            <label className='settings-personal__form-label'>
              {t('ButtonsColorTitle')}
            </label>
          </div>
          <div className='settings-personal__form-row'>
            {this.renderColorList('buttons')}
          </div>
          {isNewDesignAvailable && (
            <div className='settings-personal__form-row theme-first-row-field'>
              <label className='settings-personal__form-label'>
                <a
                  href='/redesign/settings/corporate'
                  target='_blank'
                  className={styles.link}
                >
                  {t('RedesignThemeLinkKey')}
                </a>
              </label>
            </div>
          )}
        </div>

        <Settings.Actions
          withChanges={this.props.saveButtonActive}
          working={working}
          onSave={this.props.onSave}
          onCancel={this.props.onCancel}
        />
      </SimpleBlock>
    )
  }
}

const mapStateToProps = state => ({
  saveButtonActive: state.settingsCorporate.saveButtonActiveTheme,
  isNewDesignAvailable: state.init.uk.config.is_new_design_available,
})

export default compose(
  connect(mapStateToProps),
  withTranslation('Settings')
)(ThemeSettings)
