import { useState, useEffect } from 'react'

const useKeyPress = targetKey => {
  const [keyPressed, setKeyPressed] = useState(false)
  const ELEM_NOT_LOCKED = ['TEXTAREA']

  useEffect(() => {
    const downHandler = e => {
      if (e.key === targetKey) {
        if (!ELEM_NOT_LOCKED.includes(e.target.tagName)) {
          e.preventDefault()
        }

        setKeyPressed(prev => !prev)
      }
    }

    const upHandler = e => {
      if (e.key === targetKey) {
        if (!ELEM_NOT_LOCKED.includes(e.target.tagName)) {
          e.preventDefault()
        }

        setKeyPressed(prev => !prev)
      }
    }

    window.addEventListener('keydown', downHandler)
    window.addEventListener('keyup', upHandler)

    return () => {
      window.removeEventListener('keydown', downHandler)
      window.removeEventListener('keyup', upHandler)
    }
  }, [targetKey])

  return keyPressed
}

export default useKeyPress
