// @flow

import { cloneDeep, findIndex } from 'lodash-es'

import { errorUtils } from '../../../utils/utils'
import * as actions from './ResidentList.actionTypes'

const updateItems = (items, owner, working, friendRequestStatus = null) => {
  const users = cloneDeep(items)
  const index = findIndex(users, { owner })

  users[index].working = working
  users[index].follow = false
  users[index].friend_request_status = null

  if (!working) {
    users[index].friend_request_status = friendRequestStatus
    users[index].follow = true
  }

  return users
}

const updateItemsUnSubscribe = (
  items,
  owner,
  working,
  friendRequestStatus = null
) => {
  const users = cloneDeep(items)
  const index = findIndex(users, { owner })

  users[index].working = working

  if (!working) {
    users[index].friend_request_status = friendRequestStatus
    users[index].follow = false
    users[index].is_friend = false
  }

  return users
}

const initialState = {
  initiated: false,
  working: false,
  items: [],
  meta: {},
  permissions: {},
  notifications: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.RESIDENT_LIST_INITIATED:
      return {
        ...state,
        initiated: true,
        items: action.results.objects,
        meta: action.meta,
        permissions: action.permissions,
      }
    case actions.RESIDENT_LIST_LOAD:
      return {
        ...state,
        working: true,
      }
    case actions.RESIDENT_LIST_LOADED:
      return {
        ...state,
        working: false,
        items: action.result.results.objects,
        meta: action.result.meta,
      }
    case actions.RESIDENT_LIST_SUBSCRIBE:
      return {
        ...state,
        items: updateItems(state.items, action.owner, true),
      }
    case actions.RESIDENT_LIST_SUBSCRIBED:
      return {
        ...state,
        items: updateItems(state.items, action.user, false, action.detail),
      }
    case actions.RESIDENT_LIST_ALREADY_FRIEND: {
      const items = cloneDeep(state.items)
      const indexFriend = findIndex(items, { owner: action.owner })

      items[indexFriend].working = false
      items[indexFriend].is_friend = true

      return {
        ...state,
        items,
      }
    }
    case actions.RESIDENT_LIST_UNSUBSCRIBE:
      return {
        ...state,
        items: updateItems(state.items, action.owner, true),
      }
    case actions.RESIDENT_LIST_UNSUBSCRIBED:
      return {
        ...state,
        items: updateItemsUnSubscribe(state.items, action.owner, false),
      }
    case actions.RESIDENT_LIST_ERROR: {
      const notificationText = errorUtils(action.error)

      return {
        ...state,
        initiated: true,
        working: false,
        notifications: [...state.notifications, notificationText],
      }
    }
    default:
      return state
  }
}
