// @flow

import React from 'react'
import { QueryRenderer } from 'react-relay'
import { Redirect } from 'react-router-dom'

import query from './OrderProfilePage.query'
import ThreeColumnLayout from '../../components/Layout/ThreeColumnLayout'
import MarketplaceContactsWidget from '../widgets/MarketplaceContactsWidget'
import Loader from '../../components/Loader'
import environment from '../../createRelayEnvironment'
import OrderProfile from './OrderProfile'
import ErrorMessage from '../../ui/ErrorMessage'

import { MARKETPLACE_ROUTES } from '../../constants'

const { MarketplaceError } = ErrorMessage

/*
  TODO Необходимо отредактировать ThreeColumnLayout, чтобы props.history не передавалась явно (withRouter)
*/

let routerHistory

function withRoute(routerProps) {
  routerHistory = routerProps

  return render
}

function render({ error, props, retry }) {
  if (error) {
    return <MarketplaceError error={error} />
  } else if (props) {
    if (!props.customerOrder) {
      return <Redirect to={MARKETPLACE_ROUTES['404']} />
    }

    const { customerOrder, ...rest } = props

    return (
      <ThreeColumnLayout
        widgets={MarketplaceContactsWidget}
        contacts={props.customerOrder.provider.contacts}
        {...props}
        {...routerHistory}
      >
        <OrderProfile
          data={customerOrder}
          {...rest}
          {...routerHistory}
          reload={retry}
        />
      </ThreeColumnLayout>
    )
  } else {
    return <Loader text={false} type='big' />
  }
}

const OrderProfilePage = props => {
  const {
    match: {
      params: { id },
    },
  } = props

  return (
    <div>
      <QueryRenderer
        variables={{
          orderID: id,
        }}
        environment={environment}
        query={query}
        render={withRoute(props)}
      />
    </div>
  )
}
export default OrderProfilePage
