// @flow

/* eslint-disable */

import React from 'react'

import DndCard from './DnDCard'
import Email from './Email'
import Filter from './Filter'
import Image from './Image'
import Request from './Request'
import Text from './Text'
import Variable from './Variable'
import { ButtonTypes } from '../constants'

import type { CardProps, LinkCardProps } from './Card'

export default {
  Email: (props: CardProps) => (
    <DndCard wide cardProps={props} type={ButtonTypes.email} Card={Email} />
  ),
  Filter: (props: CardProps) => (
    <DndCard wide cardProps={props} type={ButtonTypes.filter} Card={Filter} />
  ),
  Image: (props: LinkCardProps) => (
    <DndCard cardProps={props} type={ButtonTypes.image} Card={Image} />
  ),
  Request: (props: CardProps) => (
    <DndCard wide cardProps={props} type={ButtonTypes.request} Card={Request} />
  ),
  Text: (props: LinkCardProps) => (
    <DndCard cardProps={props} type={ButtonTypes.text} Card={Text} />
  ),
  Variable: (props: CardProps) => (
    <DndCard
      wide
      cardProps={props}
      type={ButtonTypes.variable}
      Card={Variable}
    />
  ),
}
