// @flow

import React from 'react'

import styles from './ThumbY.module.scss'

const ThumbY = props => {
  const { elementRef, ...restProps } = props

  return <div {...restProps} ref={elementRef} className={styles.thumbY} />
}

export default ThumbY
