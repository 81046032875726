// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import ModalBody from '../Modal/ModalBody'
import ModalRow from '../Modal/ModalRow'
import ModalHead from '../Modal/ModalHead'
import ModalButtons from '../Modal/ModalButtons'
import Button from '../Button'
import ModalText from '../Modal/ModalText'

type Props = {|
  onInit: () => void,
  onRestore: () => void,
|}

const RestoreModal = (props: Props): Node => {
  const { t } = useTranslation('WizardPopup')

  return (
    <>
      <ModalHead title={t('restoringWorkflow')} />
      <ModalBody>
        <ModalRow>
          <ModalText text={t('restoreWorkflow')} />
        </ModalRow>
      </ModalBody>
      <ModalButtons>
        <Button.Save onClick={props.onRestore}>{t('Common:yes')}</Button.Save>
        <Button.Cancel onClick={props.onInit}>{t('startNew')}</Button.Cancel>
      </ModalButtons>
    </>
  )
}

export default RestoreModal
