// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import NewTableHeader from '../../../NewTable/NewTableHeader'
import NewTableHeaderCell from '../../../NewTable/NewTableHeaderCell'
import NewTableHeaderRow from '../../../NewTable/NewTableHeaderRow'

const InvoicesTableHeader = ({ fields }): Node => {
  const { t } = useTranslation('BudgetPlan')

  return (
    <NewTableHeader>
      <NewTableHeaderRow>
        <NewTableHeaderCell style={{ width: `4%` }} />
        {fields.map((f, i) => (
          <NewTableHeaderCell
            key={i}
            title={t(f.label)}
            style={{ width: `${Math.floor(94 / fields.length)}%` }}
          />
        ))}
      </NewTableHeaderRow>
    </NewTableHeader>
  )
}

export default InvoicesTableHeader
