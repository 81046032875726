// @flow

import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import type { Node } from 'react'
import { useTranslation } from 'react-i18next'
import { useLoad } from '../../../hooks/useLoad/useLoad'
import { partial } from 'lodash-es'

import { Header, SimpleBlock } from '../../Block'
import SelectCustom from '../../Select/SelectCustom'
import FlatInformationForm from './FlatInformationForm'
import OragInfo from './OragInfo'
import Icon from '../../Icon'
import NotesPopup from '../../modals/NotesPopup'

import { getFlatInfo, updateFlatInfo } from '../../../core/api/api.flat'

import styles from './FlatInformation.module.scss'
import Loader from '../../Loader'
import Notes from '../../Notes'
import { useUser } from '../../../hooks'
import { isDwellerUser } from '../../../utils/utils'

export type IGeneral = {
  category: string,
  condition: string,
  entrance: string,
  equipment: string,
  notes: string,
  property_share: {
    share: number | null,
    total: number | null,
  },
  rent_payment_type: string,
  room_type: number | null,
}

export type IOragDetails = {
  block_name: string,
  cooling_type: string,
  edv_number: string,
  flat_equipment: string,
  funding: string,
  keys: string,
  meter_reading: string,
  notes: string,
  reason_termination: string,
  reason_vacancy: string,
  unit_number: string,
}

type IFlatInfo = {
  general: IGeneral,
  orag_cluster: IOragDetails,
}

type Props = {
  flatId: number,
}

const FlatInformation = ({ flatId: id }: Props): Node => {
  const { t } = useTranslation('Flat')
  const { display_orag_cluster } = useSelector(state => state.init.uk_config)
  const [isEdit, setIsEdit] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [currentModalNotice, setCurrentModalNotice] = useState(false)
  const [flatInfo, , , load]: [IFlatInfo, boolean, boolean, Function] = useLoad(
    getFlatInfo,
    {
      id,
    }
  )
  const {
    orag_cluster,
    general,
    general: { notes = false } = {},
  } = flatInfo || {}

  const user = useUser()
  const isDweller = isDwellerUser(user)

  const asyncUpdateFlatInfo = async (id: number, params: Object) => {
    await updateFlatInfo(id, params)
    load()
  }
  const handleCloseModalNotice = () => setCurrentModalNotice(null)
  const handleUpdateFlatInfo = partial(asyncUpdateFlatInfo, id)
  const handleEditOpen = () => {
    setIsEdit(true)
    setIsOpen(true)
  }
  const handleEditClose = () => setIsEdit(false)

  const updateFlatInfoNotice = notes => {
    const noticeData = { [(currentModalNotice: string)]: { notes } }
    handleUpdateFlatInfo(noticeData).then(load)
  }

  useEffect(() => {
    if (!isOpen) {
      setIsEdit(false)
    }
  }, [isOpen])

  const options = [
    {
      value: 'addNotice',
      label: t('AddNotice'),
      onClick: () => setCurrentModalNotice('general'),
      icon: 'pencil',
    },
  ]

  const list = { general, orag_cluster }

  return (
    <>
      <SimpleBlock className='unit unit--default' style={{ marginTop: 0 }}>
        <Header overflowVisible header>
          <div className={styles.profileWrap}>
            <div>
              {t('FlatInfo')}
              {!isEdit && !isDweller && (
                <Icon
                  colorOnHover
                  style={{ width: 16, height: 16, paddingLeft: '8px' }}
                  id='pencil'
                  onClick={handleEditOpen}
                />
              )}
            </div>
            {!isDweller && (
              <div className={styles.profileOpenIcon}>
                <Icon
                  style={{ fill: 'transparent' }}
                  id={isOpen ? 'openSection' : 'closedSection'}
                  onClick={() => setIsOpen(isOpen => !isOpen)}
                />
                <SelectCustom
                  options={options}
                  onChange={opt => opt.onClick()}
                />
              </div>
            )}
          </div>
        </Header>
        {general ? (
          <FlatInformationForm
            isDweller={isDweller}
            isOpen={isDweller ? true : isOpen}
            initialValues={general}
            active={isEdit}
            handleEditClose={handleEditClose}
            updateFlatInfo={handleUpdateFlatInfo}
          />
        ) : (
          <Loader type='medium' text={false} />
        )}
        <Notes notes={notes && notes.trim()} />
      </SimpleBlock>
      {display_orag_cluster && orag_cluster && (
        <OragInfo
          oragDetails={orag_cluster}
          updateOragDetails={handleUpdateFlatInfo}
          setCurrentNoticeModal={setCurrentModalNotice}
        />
      )}
      {currentModalNotice && list[currentModalNotice] && (
        <NotesPopup
          updateNotice={updateFlatInfoNotice}
          notes={list[currentModalNotice].notes}
          onClose={handleCloseModalNotice}
        />
      )}
    </>
  )
}
export default FlatInformation
