// @flow

import React, { useReducer } from 'react'
import { graphql, QueryRenderer } from 'react-relay'
import { useSelector } from 'react-redux'
import type { Location } from 'react-router-dom'
import type { Node } from 'react'

import Loader from '../../components/Loader'
import environment from '../../createRelayEnvironment'
import OrdersTable from './OrdersTable'
import ErrorMessage from '../../ui/ErrorMessage'
import { useUrlParams } from '../../hooks'
import { isAdminUser } from '../../utils/utils'
import { getUser } from '../../utils/commonSelectors'
import { usePathname } from '../../hooks'

const { MarketplaceError } = ErrorMessage

type Props = { location: Location, setCount: number => void }

export const query = graphql`
  query OrdersQuery(
    $page: Float!
    $orderNo: String
    $status: String
    $providerName: String
    $categoryId: String
    $all: Boolean
  ) {
    ...OrdersTable_query
      @arguments(
        page: $page
        orderNo: $orderNo
        status: $status
        providerName: $providerName
        categoryId: $categoryId
        all: $all
      )
  }
`

function render(setKey, setCount) {
  return ({ error, props }) => {
    if (error) {
      return <MarketplaceError error={error} />
    } else if (props) {
      return <OrdersTable query={props} setKey={setKey} setCount={setCount} />
    } else {
      return <Loader text={false} type='big' />
    }
  }
}

const Orders = (props: Props): Node => {
  const { location, setCount } = props

  const isLocked = usePathname()

  const { page, status, category_id, b2b, b2c, all, ...rest } = useUrlParams(
    location,
    [
      'status',
      'orderNo',
      'providerName',
      'page',
      'category_id',
      'b2b',
      'b2c',
      'all',
    ]
  )

  const [key, setKey] = useReducer(x => x + 1, 0)

  const user = useSelector(getUser)

  const isAdmin = isAdminUser(user)

  const categoryId = category_id || b2b || b2c

  const params = Object.assign(
    {},
    { ...rest },
    page ? { page } : { page: 1 },
    status ? { status: JSON.stringify([status]) } : undefined,
    isAdmin ? (all ? { all: JSON.parse(all) } : { all: true }) : undefined,
    categoryId ? { categoryId } : undefined
  )

  const variables = { ...params }

  if (isLocked || !location.search) {
    return null
  }

  return (
    <QueryRenderer
      key={key}
      environment={environment}
      query={query}
      variables={variables}
      render={render(setKey, setCount)}
    />
  )
}

export default Orders
