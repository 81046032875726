// @flow

import React, { useState } from 'react'
import type { Node } from 'react'
import { useSelector } from 'react-redux'
import ObjectInfo from './BuildingInformationForms/ObjectInfo'
import OragInfo from './BuildingInformationForms/OragInfo'
import BankDetails from './BuildingInformationForms/BankDetails'
import Insurance from './BuildingInformationForms/Insurance'
import { useLoad } from '../../../hooks'
import {
  getBuildingInfo,
  updateBuildingInfo,
} from '../../../core/api/api.building'
import { partial } from 'lodash-es'
import Loader from '../../Loader'
import NotesPopup from '../../modals/NotesPopup'

export type IObjectType = {
  contract_end: string | null,
  contract_start: string | null,
  living_type: number | null,
  location: string,
  notes: string,
  object_number: string,
  postal_code: string,
  salutation: number | null,
}

export type IInsurance = {
  contract_number: string,
  insurance_type: number | null,
  notes: string,
}

export type IBankDetails = {
  bank_account_owner: string,
  bank_name: string,
  bic: string,
  iban: string,
  notes: string,
}

export type IOragDetails = {
  asset_manager: string,
  broker: string,
  identical_address: string,
  tax_consultant: string,
  warranty: string,
}

type IBuildingData = {
  bank_details: IBankDetails,
  insurance: IInsurance,
  object_info: IObjectType,
  orag_cluster?: IOragDetails,
}

export type IModalName =
  | 'object_info'
  | 'insurance'
  | 'bank_details'
  | 'orag_cluster'
  | ''

type Props = {
  buildingId: number,
}

const BuildingInformation = ({ buildingId }: Props): Node => {
  const [currentModalNotice, setCurrentModalNotice] = useState<IModalName>('')
  const [buildingInfo, , , load]: [IBuildingData, boolean, boolean, Function] =
    useLoad(getBuildingInfo, {
      id: buildingId,
    })
  const { bank_details, insurance, object_info, orag_cluster } =
    buildingInfo || {}

  const { display_orag_cluster } = useSelector(state => state.init.uk_config)

  const asyncUpdateBuildingInfo = async (id: number, params: Object) => {
    await updateBuildingInfo(id, params)
    load()
  }
  const handleUpdateBuildingInfo = partial(asyncUpdateBuildingInfo, buildingId)
  const updateBuildingInfoNotice = notes => {
    const noticeData = { [(currentModalNotice: string)]: { notes } }
    handleUpdateBuildingInfo(noticeData).then(load)
  }
  const handleCloseModalNotice = () => setCurrentModalNotice('')

  const list = { bank_details, insurance, object_info, orag_cluster }

  return (
    <>
      {buildingInfo ? (
        <>
          <ObjectInfo
            objectInfo={object_info}
            updateObjectInfo={handleUpdateBuildingInfo}
            setCurrentNoticeModal={setCurrentModalNotice}
          />
          <Insurance
            insurance={insurance}
            updateInsurance={handleUpdateBuildingInfo}
            setCurrentNoticeModal={setCurrentModalNotice}
          />
          <BankDetails
            bankDetails={bank_details}
            updateBankDetails={handleUpdateBuildingInfo}
            setCurrentNoticeModal={setCurrentModalNotice}
          />
          {display_orag_cluster && orag_cluster && (
            <OragInfo
              oragDetails={orag_cluster}
              updateOragDetails={handleUpdateBuildingInfo}
              setCurrentNoticeModal={setCurrentModalNotice}
            />
          )}
        </>
      ) : (
        <Loader type='medium' text={false} />
      )}
      {currentModalNotice && (
        <NotesPopup
          updateNotice={updateBuildingInfoNotice}
          notes={list[currentModalNotice].notes}
          onClose={handleCloseModalNotice}
        />
      )}
    </>
  )
}

export default BuildingInformation
