// @flow

import React from 'react'
import type { Node } from 'react'

import Icon from '../../../../../../../../../../../components/Icon'

import styles from './Files.module.scss'

type Props = { count: number, onClick: Function }

const Files = (props: Props): Node => {
  const { count } = props

  return (
    <div className={styles.files}>
      <span className={styles.icon} onClick={props.onClick}>
        <Icon id='attach' />
      </span>
      <span className={styles.count}>{count}</span>
    </div>
  )
}

export default Files
