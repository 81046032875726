// @flow

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useLocation } from 'react-router-dom'

import { Header, SimpleBlock } from '../Block'
import FlatList from './FlatList'
import Nbsp from '../NBSP'
import { getFlatList } from '../../core/api/api.profile'
import { getCurrentPage, getCurrentFilters } from '../../utils/routing'
import Loader from '../Loader'

const Flats = () => {
  const { t } = useTranslation('Building')

  const location = useLocation()

  const { profileId } = useParams()

  const [loading, setLoading] = useState(false)
  const [flats, setFlats] = useState([])
  const [meta, setMeta] = useState({})

  useEffect(() => {
    setLoading(true)
  }, [location.search, location.pathname])

  useEffect(() => {
    if (loading) {
      const page = getCurrentPage(location)
      const filters = getCurrentFilters(location)

      if (filters.updated_gte) {
        filters.flat_updated_gte = filters.updated_gte
      }

      if (filters.updated_lte) {
        filters.flat_updated_lte = filters.updated_lte
      }

      const params = { ...filters, userprofile: profileId, page }

      getFlatList(params).then(data => {
        setLoading(false)
        setFlats(data.objects)
        setMeta({
          count: data.count,
          limit: 20,
          offset: 0,
          page_count: data.pages,
          curr_page: parseInt(page),
        })
      })
    }
  }, [loading])

  if (loading) {
    return <Loader type='medium' text={false} />
  }

  return (
    <div className='content__col'>
      <SimpleBlock className='unit unit--default unit--nomargin'>
        <Header header>
          {t('Flats')}
          <span className='unit__title-num'>
            <Nbsp />
            {!!meta.count && meta.count}
          </span>
        </Header>
        <FlatList
          flats={flats}
          loading={loading}
          meta={meta}
          setFlats={setFlats}
        />
      </SimpleBlock>
    </div>
  )
}

export default Flats
