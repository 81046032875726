// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import ConfirmationPopup from './ConfirmationPopup'

type Props = {
  onOk: () => void,
  t?: string => string,
  text: string,
  textParams?: Object,
  title?: string,
}

const MessagePopup = (props: Props): Node => {
  const { text, title, textParams } = props

  const { t } = useTranslation('Modal')
  const T = props.t || t

  return (
    <ConfirmationPopup
      title={title ? T(title) : ''}
      text={t(text, textParams)}
      showCancel={false}
      confirm={t('Close')}
      onOk={props.onOk}
      onClose={props.onOk}
    />
  )
}

export default MessagePopup
