// @flow

import {
  getApi,
  patchApi,
  postApi,
  deleteApi,
  patchRaw,
  postRaw,
} from './api.core'

export const invite = params => postApi('api/profile/invite/', params)
export const changeStatus = (userId, params) =>
  patchApi(`api/profile/${userId}/`, params)

export const update = (id, params) => patchApi(`api/profile/${id}/`, params)
export const externalUpdate = (id, params) =>
  patchApi(`api/external-contact/${id}/`, params)
// TODO Get rid of update and updateTiny and leave only one (I hope tiny)
export const updateTiny = (id, params) =>
  patchRaw(`api/profile-tiny/${id}/`, params)
export const remove = id => deleteApi(`api/profile/${id}/`)

export const getMyProfile = () => getApi('api/profile/current/')
export const getMyProfileConfig = () => getApi('api/profile/current/config/')
export const updateMyProfileConfig = params =>
  patchApi('api/profile/current/config/', params)
export const getProfile = (id, { outbound, ...params } = {}) =>
  getApi(`api/${outbound ? `id2id/${outbound}/` : ''}profile/${id}/`, params)
export const getProfileInfo = ({ id }) => getApi(`api/profile/${id}/info/`)
export const updateProfileInfo = (id, params) =>
  patchRaw(`api/profile/${id}/info/`, params)

export const getArchivedProfile = (id, params) =>
  getApi(`api/archived/profile/${id}/`, params)
export const getProfileTiny = (id, params) =>
  getApi(`api/profile-tiny/${id}/`, params)
export const getListTiny = (params = {}, getPermissions = true) => {
  const allParams = getPermissions ? { ...params, get_permissions: 1 } : params

  return getApi('api/profile-tiny/', allParams)
}

export const getAllListTiny = (params = {}, getPermissions = true) => {
  const allParams = getPermissions ? { ...params, get_permissions: 1 } : params

  return getApi('api/profile-tiny/all/', allParams)
}

export const availableChatMembers = params =>
  getApi('api/profile/available-chat-members/', params)

export const getDwellerOptionList = ({ outbound, ...params }) =>
  getApi(
    `api/${outbound ? `id2id/${outbound}/` : ''}dweller-list-options/`,
    params
  )

export const getAdminAndManagerOptionList = params =>
  getApi('api/manager-list-options/', params)

export const getArchivedDwellerOptionList = params =>
  getApi('api/archived/dweller-list-options/', params)

export const getArchivedAdminAndManagerOptionList = params =>
  getApi('api/archived/manager-list-options/', params)

export const getArchivedListTiny = (params = {}, getPermissions = true) => {
  const allParams = getPermissions ? { ...params, get_permissions: 1 } : params

  return getApi('api/archived/profile-tiny/', allParams)
}

export const setManagerBuildings = (id, buildings) =>
  postRaw(`api/profile/manager/${id}/set-buildings/`, { buildings })
export const setManagerCategories = (id, categories) =>
  postRaw(`api/profile/manager/${id}/set-categories/`, { categories })
export const setMassManagerCategories = (managers, categories) =>
  postRaw(`api/profile/manager/categories/`, { categories, managers })
export const getNeighbors = () => getApi('api/profile/neighbors/')
export const getCounters = () => getApi('api/profile/counters/')

export const freeRegistration = params =>
  postApi('api/profile/free-registration/', params)

export const changeUserRole = (id, params) =>
  patchApi(`api/profile/${id}/`, params)

export const getFlatList = params => getApi('api/inhabitancy/flats/', params)
export const setContractData = (id, params) =>
  patchRaw(`api/inhabitancy/flats/${id}/`, params)

export const getManagerList = params => getApi('api/manager-list/', params)
export const getArchivedManagerList = params =>
  getApi('api/archived/manager-list/', params)
export const getDwellerList = params => getApi('api/dweller-list/', params)
export const getArchivedDwellerList = params =>
  getApi('api/archived/dweller-list/', params)
export const getExternalList = params => getApi('api/external-contact/', params)
export const getExternalContact = (id, params) =>
  getApi(`api/external-contact/${id}/`, params)
export const getAllDwellerList = params =>
  getApi('api/dweller-list/all/', params)
export const getAllArchivedDwellerList = params =>
  getApi('api/archived/dweller-list/all/', params)

export const getEmailRecipients = params =>
  postRaw('api/profile/email-recipients/', params)
export const getDwellerCounters = () => getApi('api/dweller-list/counters/')

export const generateRemoveProfileLink = params =>
  postApi('api/profile/generate-remove-profile-link/', params)
export const removeProfile = params => postApi('api/profile/remove/', params)
export const deleteExternalProfile = id =>
  deleteApi(`api/external-contact/${id}/`)
export const removeProfileTokenCheck = params =>
  postApi('api/profile/remove-profile-token-check/', params)
export const getUsersForMassEmail = params =>
  postRaw('idwell-api/api/v3/inhabitancy/email-recipients/', params)
export const getListSearch = params =>
  getApi('api/profile/search/dweller/', params)
export const getListLiveSearch = params =>
  getApi('api/profile/live-search/dweller/', params)
