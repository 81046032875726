// @flow

import React from 'react'
import type { Node } from 'react'

import { useTranslation } from 'react-i18next'

import Button from '../../../Button'
import Modal from '../../../Modal'
import ModalBody from '../../../Modal/ModalBody'
import ModalButtons from '../../../Modal/ModalButtons'
import ModalHead from '../../../Modal/ModalHead'

import styles from '../SalutationModal.module.scss'

type Props = {
  onClose: () => void,
}

const SalutationModalError = ({ onClose }: Props): Node => {
  const { t } = useTranslation('Salutation')

  return (
    <Modal isOpen className={styles.modal} onRequestClose={onClose}>
      <ModalHead title={t('SalutationDeleteError')} />
      <ModalBody>Error</ModalBody>
      <ModalButtons>
        <Button.Save onClick={onClose}>{t('Common:Ok')}</Button.Save>
      </ModalButtons>
    </Modal>
  )
}

export default SalutationModalError
