// @flow

export const SERVER_500_ERROR = 'SERVER_500_ERROR'
export const SERVER_502_ERROR = 'SERVER_502_ERROR'
export const SERVER_403_ERROR = 'SERVER_403_ERROR'
export const SERVER_402_ERROR = 'SERVER_402_ERROR'
export const SERVER_401_ERROR = 'SERVER_401_ERROR'
export const SERVER_404_ERROR = 'SERVER_404_ERROR'
export const SERVER_ANY_ERROR = 'SERVER_ANY_ERROR'
export const SERVER_ERROR = 'SERVER_ERROR'
export const CLOSE_POP_UP = 'CLOSE_POP_UP'
export const GLOBAL_MODAL_ERROR = 'GLOBAL_MODAL_ERROR'
export const GLOBAL_MODAL_MESSAGE = 'GLOBAL_MODAL_MESSAGE'
export const UPDATE_COUNTERS = 'UPDATE_COUNTERS'
