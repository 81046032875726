// @flow

import React from 'react'
import type { Node } from 'react'

import Icon from '../../../../../../../../../../../components/Icon'

import styles from './Email.module.scss'

type Props = {
  onClick: Function,
  requestThreadCount: number,
  unreadRequestThreadCount: number,
}

const Email = (props: Props): Node => {
  const { unreadRequestThreadCount, requestThreadCount } = props

  return (
    <div className={styles.email}>
      <span className={styles.icon} onClick={props.onClick}>
        <Icon id='emailActivity' />
        {!!unreadRequestThreadCount && (
          <span className={styles.unread}>{unreadRequestThreadCount}</span>
        )}
      </span>
      <span className={styles.count}>{requestThreadCount}</span>
    </div>
  )
}

export default Email
