// @flow

import React from 'react'
import { useRouteMatch, useLocation } from 'react-router-dom'
import type { Node } from 'react'

import Files from '../Files'

type Props = {
  buildingPermissions: Object,
  directoryId: number,
  isDweller: boolean,
}

const BuildingDocuments = (props: Props): Node => {
  const { directoryId, buildingPermissions, buildinId, isDweller } = props

  const location = useLocation()
  const match = useRouteMatch()
  const buildingInfo = { type: 'building', id: buildinId }

  if (!directoryId) {
    return null
  }

  return (
    <Files
      isDweller={isDweller}
      subject={buildingInfo}
      directoryId={directoryId}
      match={match}
      location={location}
      permissions={buildingPermissions}
    />
  )
}

export default BuildingDocuments
