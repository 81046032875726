// @flow

import libphonenumber from 'google-libphonenumber'

export function getSecondaryText(user) {
  const { email, phone } = user

  let fieldCount = 0
  let divider = ''

  if (email) {
    ++fieldCount
  }

  if (phone) {
    ++fieldCount
  }

  if (!fieldCount) {
    return ''
  }

  if (fieldCount == 2) {
    divider = ' | '
  }

  return `${formatPhone(phone)}${divider}${email || ''}`
}

export function formatPhone(phone) {
  if (!phone) {
    return ''
  }

  const PNF = libphonenumber.PhoneNumberFormat
  const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance()

  let phoneNumber

  try {
    phoneNumber = phoneUtil.parse(`${phone[0] !== '+' ? '+' : ''}${phone}`)
  } catch (error) {
    return phone
  }

  return phoneUtil.format(phoneNumber, PNF.INTERNATIONAL)
}
