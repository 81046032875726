// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import Day from '../Day'
import Time from '../Time'
import Separator from '../Separator'
import { getUkName, getUKTheme } from '../PublicBuildingPage.selectors'
import { getUkLogo, getUk } from '../../../utils/commonSelectors'
import { getThumbnailUrl } from '../../../utils/utils'

const timeFormat = 'HH:mm'
const THUMNAIL_PATH = '/v2/thumbnails/0X98/'

type Props = {
  logo: Object,
  name: string,
  s3Url: string,
  theme: {
    header: string,
  },
  uk: Object,
}
type State = {
  date: string,
}

class Header extends Component<Props, State> {
  constructor(props) {
    super(props)
    const date = this.getDate()
    this.state = {
      date,
    }
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState({ date: this.getDate() })
    }, 1000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  getDate = () => moment()

  render() {
    const { name, logo, theme, s3Url, uk } = this.props
    const { date } = this.state

    const src = getThumbnailUrl(logo.origin, uk.url, s3Url, THUMNAIL_PATH)

    return (
      <header
        className='public-building-page__header'
        style={{ backgroundColor: theme.header }}
      >
        <div>
          <img
            src={src}
            name={name}
            className='public-building-page__header-logo'
          />
          <Separator />
          <div className='site-header__company-name public-building-page__header-name'>
            <p>{name}</p>
          </div>
        </div>
        <div>
          <Day date={date} />
          <Separator />
          <Time date={date} timeFormat={timeFormat} />
        </div>
      </header>
    )
  }
}

const mapStateToProps = state => ({
  name: getUkName(state),
  logo: getUkLogo(state),
  theme: getUKTheme(state),
  s3Url: state.init.s3_url,
  uk: getUk(state),
})

export default connect(mapStateToProps)(Header)
