// @flow

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'
import { useParams } from 'react-router-dom'

import UserInfoTitle from './UserInfoTitle'
import UserInfoCell from './UserInfoCell'
import { getDwellerCounters } from '../../../core/api/api.profile'
import classNames from 'classnames'

const UsetInfo = (): Node => {
  const { t } = useTranslation('User')

  const [counters, setCounters] = useState({
    active_users: 0,
    dwellers: 0,
    users: 0,
  })

  const { groupId: buildingGroup } = useParams()

  useEffect(() => {
    if (!buildingGroup) {
      getDwellerCounters().then(data => {
        setCounters(data)
      })
    }
  }, [])

  const { active_users, dwellers, users } = counters

  const sectionClass = classNames('bar', 'user-list-info', 'bar--default', {
    'bar--group': buildingGroup,
  })

  return (
    <section className={sectionClass}>
      <UserInfoTitle text={t('UsersInfoTitle')} />
      {!buildingGroup && (
        <div className='bar__cols bar__row'>
          <UserInfoCell counter={dwellers} text={t('DwellersCount')} />
          <UserInfoCell counter={users} text={t('UsersCount')} />
          <UserInfoCell counter={active_users} text={t('ActiveUsersCount')} />
        </div>
      )}
    </section>
  )
}

export default UsetInfo
