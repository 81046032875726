// @flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useOverflow } from '../../hooks'
import type { Node } from 'react'

import Modal from '../Modal'
import ModalCloseButton from '../Modal/ModalCloseButton'
import ModalHead from '../Modal/ModalHead'
import ModalBody from '../Modal/ModalBody'
import ModalRow from '../Modal/ModalRow'
import ModalCell from '../Modal/ModalCell'
import ModalLabel from '../Modal/ModalLabel'
import ModalButtons from '../Modal/ModalButtons'
import Button from '../Button'
import InputField from '../InputField'
import NewSelectAsync from '../NewSelectAsync'
import Warning from '../Warning'

import styles from './Pipelines.module.scss'

import { getRequestTypes } from '../../core/api/api.pipeline'

import { MAX_PIPELINE_NAME_LENGTH } from '../../constants'

type Props = {
  handleCopy: Function,
  itemId: integer,
  itemName: string,
  onClose: Function,
}

const PipelineCopyModal = (props: Props): Node => {
  const { itemId, itemName } = props

  useOverflow()

  const { t } = useTranslation('Pipeline')

  const copyText = '_' + t('CopyText')
  const [name, setName] = useState(
    itemName.substr(0, MAX_PIPELINE_NAME_LENGTH - copyText.length) + copyText
  )
  const [category, setCategory] = useState(null)

  return (
    <Modal isOpen onRequestClose={props.onClose}>
      <ModalCloseButton onClose={props.onClose} />
      <ModalHead title={t('CopyPipelineTitle')} />
      <ModalBody>
        <ModalRow>
          <ModalCell>
            <ModalLabel>{t('PipelineNameLabel')}</ModalLabel>
          </ModalCell>
          <ModalCell gridNumber={2}>
            <InputField
              autoFocus
              maxLength={MAX_PIPELINE_NAME_LENGTH}
              name='name'
              value={name}
              placeholder={t('NamePlaceholder')}
              onChange={e => setName(e.target.value)}
            />
          </ModalCell>
        </ModalRow>
        <ModalRow>
          <ModalCell>
            <ModalLabel>{t('RequestTypeLabel')}</ModalLabel>
          </ModalCell>
          <ModalCell gridNumber={2}>
            <NewSelectAsync
              style={{ width: '100%' }}
              pageSize={10}
              selectedItems={category ? [category] : []}
              getLabel={c => c.name}
              getSelectedLabel={c => c.name}
              searchKey='name'
              view='dropdown'
              api={getRequestTypes}
              placeholder={t('RequestTypePLaceholder')}
              onClick={setCategory}
            />
          </ModalCell>
        </ModalRow>
        <ModalRow>
          <Warning
            noArrow
            text={t('CopyPipelineNotice')}
            boxClassName={styles.warningBox}
            className={styles.warning}
          />
        </ModalRow>
      </ModalBody>
      <ModalButtons>
        <Button.Save
          disabled={!name.length || !category}
          onClick={() =>
            props.handleCopy(itemId, { name, request_category: category?.id })
          }
        >
          {t('Common:Save')}
        </Button.Save>
        <Button.Cancel onClick={props.onClose}>
          {t('Common:Cancel')}
        </Button.Cancel>
      </ModalButtons>
    </Modal>
  )
}

export default PipelineCopyModal
