import { all, put, call, fork, takeLatest } from 'redux-saga/effects'

import api from '../../core/api'
import * as actions from './RequestConstructorBlock.actionTypes'
import { serverError } from '../../components/Layout/Layout.actions'

function * handleError (error) {
  const { data } = error.message.response
  yield put({
    type: actions.REQUEST_CONSTRUCTOR_BLOCK_ERROR,
    error: data
  })
  yield put(serverError(error))
}

function * watchInitiate () {
  yield takeLatest(actions.REQUEST_CONSTRUCTOR_INITIATE_BLOCK, initiate)
}

function * watchRename () {
  yield takeLatest(actions.REQUEST_CONSTRUCTOR_RENAME_BLOCK, rename)
}

function * watchRemove () {
  yield takeLatest(actions.REQUEST_CONSTRUCTOR_REMOVE_BLOCK, remove)
}

function * initiate ({ id }) {
  try {
    const data = yield call(api.requestConstructor.getBlock, id)
    yield put({ type: actions.REQUEST_CONSTRUCTOR_BLOCK_WAS_INITIATED, data })
  } catch (error) {
    yield * handleError(error)
  }
}

function * rename ({ id, params }) {
  try {
    const data = yield call(api.requestConstructor.updateBlock, id, params)
    yield put({ type: actions.REQUEST_CONSTRUCTOR_BLOCK_WAS_RENAMED, data })
  } catch (error) {
    yield * handleError(error)
  }
}

function * remove ({ id }) {
  try {
    yield call(api.requestConstructor.removeBlock, id)
    yield put({ type: actions.REQUEST_CONSTRUCTOR_BLOCK_WAS_REMOVED })
  } catch (error) {
    yield * handleError(error)
  }
}

export default function * watch () {
  yield all([
    fork(watchInitiate),
    fork(watchRename),
    fork(watchRemove)
  ])
}
