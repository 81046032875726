// @flow

import * as actions from './WidgetLastReg.actionTypes'

const initialState = {
  initiated: false,
  users: [],
}

export default function widgetLastRegReducer(state = initialState, action) {
  switch (action.type) {
    case actions.WIDGET_GET_LAST_REG_INITIATING:
      return initialState
    case actions.WIDGET_GET_LAST_REG_INITIATED:
      return {
        ...state,
        initiated: true,
        users: action.users,
      }
    default:
      return state
  }
}
