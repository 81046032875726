// @flow

import React from 'react'

type Props = {
  align: string,
  link?: string,
  title?: string,
}

const BodyCell = ({ title, children, align, link }: Props) => (
  <div className='table__cell'>
    <div className='table__cell-wrapper' style={{ justifyContent: align }}>
      {children}
      <div className='table-item table-item__text'>
        {link ? (
          <a download target='_blank' href={link} rel='noopener noreferrer'>
            {title}
          </a>
        ) : (
          <span>{title}</span>
        )}
      </div>
    </div>
  </div>
)

BodyCell.defaultProps = {
  align: 'flex-start',
}

export default BodyCell
