// @flow

import React from 'react'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import type { Node } from 'react'

import ProfileListTableBodyRow from './ProfileListTableBodyRow'
import { useUser } from '../../../hooks'
import { isDwellerUser } from '../../../utils/utils'

type Props = { permissions: Object, profiles: Array<Object> }

const ProfileListTableBody = (props: Props): Node => {
  const { profiles, selected, permissions } = props

  const dispatch = useDispatch()
  const user = useUser()

  const isDweller = isDwellerUser(user)

  const handleOpen = id => {
    dispatch(push(`/profile/${id}`))
  }

  const updateProfiles = profile => {
    props.setProfiles(
      profiles.map(p => {
        if (p.id === profile.id) {
          return profile
        }

        return p
      })
    )
  }

  return (
    <>
      {profiles.map(profile => (
        <ProfileListTableBodyRow
          isDweller={isDweller}
          updateProfiles={updateProfiles}
          selected={selected}
          showUpdateDwellerRoleModal={props.showUpdateDwellerRoleModal}
          key={profile.id}
          profile={profile.userprofile}
          archived={profile.archived}
          inhabitancyId={profile.id}
          contractId={profile.contract_id}
          purchaseContractNumber={profile.purchase_contract_number}
          contractStart={profile.contract_start}
          duration={profile.duration}
          contractEnd={profile.contract_end}
          handleOpen={handleOpen}
          handleCheck={props.handleCheck}
          canChangeUserRole={permissions.can_change_user_role}
          permissions={profile.permissions}
        />
      ))}
    </>
  )
}

export default ProfileListTableBody
