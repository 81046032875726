// @flow

import React from 'react'
import type { Node } from 'react'

import styles from './Footer.module.scss'

type Props = { children: Node }

const Footer = (props: Props): Node => {
  const { children } = props

  return <div className={styles.footer}>{children}</div>
}

export default Footer
