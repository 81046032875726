// @flow

import React from 'react'
import type { Node } from 'react'

import Regular from './Regular'
import type { Props } from './Regular/Regular'

const Upload = (props: Props): Node => <Regular {...props} view='outlined' />

export default Upload
