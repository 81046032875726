// @flow

import React from 'react'
import type { Node } from 'react'

import ModalLabel from '../../../Modal/ModalLabel'

import styles from './ActivityModalLabel.module.scss'

type Props = { title: string }

const ActivityModalLabel = (props: Props): Node => {
  const { title } = props

  return <ModalLabel className={styles.label}>{title}</ModalLabel>
}

export default ActivityModalLabel
