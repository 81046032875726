// @flow

import React from 'react'
import { useSelector } from 'react-redux'

import { formatDateTime } from '../../../utils/utils'

type Props = {
  created: string,
  text: string,
}

const SystemMessage = (props: Props) => {
  const locale = useSelector(state => state.translations.translations.locale)

  const format = locale === 'en' ? 'hh:mm A' : 'HH:mm'

  const { created, text } = props

  return (
    <div className='messages__item messages__system'>
      <span className='created'>{formatDateTime(created, format)}</span>
      <span dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  )
}

export default SystemMessage
