// @flow

import React from 'react'
import classnames from 'classnames'

import Icon from '../../Icon'
import styles from './SimpleLike.module.scss'

type Props = {
  count: number,
  disabled?: boolean,
  dislike?: boolean,
  marked: boolean,
  onClick: () => void,
}

const Like = (props: Props) => {
  const {
    dislike,
    count,
    marked,
    disabled
  } = props
  const iconClass = classnames(styles.like, {
    [styles.dislike]: dislike
  })
  const counterClassName = classnames(
    'messages__item-like-count',
    styles.counter
  )
  const rootClassName = classnames('messages__item-like', styles.root)

  return (
    <div className={rootClassName}>
      <Icon
        className={iconClass}
        colored={marked}
        colorOnHover
        disabled={disabled}
        id='like'
        onClick={props.onClick}
      />
      <span className={counterClassName}>{count}</span>
    </div>
  )
}

Like.defaultProps = {
  dislike: false,
  count: 0
}

export default Like
