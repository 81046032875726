// @flow

import React from 'react'
import classnames from 'classnames'

import Icon from '../../Icon'
import Image from '../../ImageSquareFit'

import styles from './DocumentPreview.module.scss'

type Props = {
  data: { name: string, preview: string },
}

const Video = ({ data }: Props) => {
  const { preview, name } = data

  if (preview) {
    return (
      <div className={styles.icon}>
        <Image alt={name} src={preview} />
        <Icon className={styles.play} id='play' />
      </div>
    )
  }

  const iconClass = classnames(styles.icon, styles.video)

  return (
    <div className={iconClass}>
      <Icon className={styles.play} id='play' />
    </div>
  )
}

export default Video
