// @flow

import { reduce, flow, partialRight, filter, map } from 'lodash-es'

import Store from '../../core/Store'
import { cutText, getUserName } from '../../utils/utils'

type TranslateCallback = string => string

const getChatroomUserName = (user: Object, t: TranslateCallback) => {
  if (!user) {
    return ''
  }

  const logged = Store.getLoggedUser()
  const { user: id, owner } = user

  return (id || owner) === logged.owner
    ? t('Me')
    : cutText(
        getUserName(user),
        100 // TODO use css text-overflow instead of cut func
      )
}

export const getTitle = (users: Array<Object>, t: TranslateCallback) => {
  if (!users || !users.length) {
    return ''
  }

  const { length } = users

  const first = getChatroomUserName(users[0], t)

  if (length === 1) {
    return first
  }

  const second = getChatroomUserName(users[1], t)

  if (length === 2) {
    return second
  }

  const third = getChatroomUserName(users[2], t)

  if (length === 3) {
    return `${first}, ${second} ${t('and')} ${third}`
  }

  return `${first}, ${second} ${t('andMore')} ${length - 2} ${t('member')}`
}

export const getArhivedUuid = messagesInfo => {
  return flow([
    partialRight(filter, ({ archived }) => archived === true),
    partialRight(map, 'uuid'),
  ])(messagesInfo)
}

export const getFiles = messagesInfo => {
  return flow([
    partialRight(filter, ({ archived }) => archived === false),
    partialRight(
      reduce,
      (acc, item) => {
        acc[item.uuid] = item.files

        return acc
      },
      {}
    ),
  ])(messagesInfo)
}

export const removeLike = msgToUpdate => {
  msgToUpdate.like.liked = false
  msgToUpdate.like.like_count -= 1
}

export const addLike = msgToUpdate => {
  msgToUpdate.like.liked = true
  msgToUpdate.like.like_count += 1
}

export const removeDislike = msgToUpdate => {
  msgToUpdate.like.disliked = false
  msgToUpdate.like.dislike_count -= 1
}

export const addDislike = msgToUpdate => {
  msgToUpdate.like.disliked = true
  msgToUpdate.like.dislike_count += 1
}
