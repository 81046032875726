// @flow

import React from 'react'
import classNames from 'classnames'

import { useThemeColor } from '../../hooks'
import { hexToRGB } from '../../utils/utils'

import styles from './Radio.module.scss'

const Radio = props => {
  const { name, checked, value, label, id, disabled } = props

  const themeColor = useThemeColor()

  const rgbTheme = hexToRGB(themeColor)

  const radioClass = classNames(styles.radio, {
    [styles.checked]: checked,
    [styles.disabled]: disabled,
  })

  return (
    <div className={styles.container}>
      <span className={radioClass} style={{ '--rgbThemeColor': rgbTheme }}>
        <input
          type='radio'
          name={name}
          checked={checked}
          disabled={disabled}
          value={value}
          id={id}
          onChange={props.onChange}
        />
      </span>
      <label className={styles.label} htmlFor={id}>
        {label}
      </label>
    </div>
  )
}

export default Radio
