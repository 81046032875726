// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import { getLocation } from '../../../utils/commonSelectors'
import {
  getCurrentFilters,
  getNewQueryString,
  formatPathname,
} from '../../../utils/routing'

type Props = {
  classes?: string,
  location: Object,
  meta: Object,
  setPage?: () => void,
  t: string => string,
}

class TopPagination extends Component<Props> {
  renderItem = (page, itemClass) => {
    const { setPage, location } = this.props

    if (setPage) {
      return <a className={itemClass} onClick={() => setPage(page)} />
    }

    const filters = getCurrentFilters(location)
    const search = getNewQueryString({ ...filters, page })
    const { pathname } = location

    return (
      <Link
        className={itemClass}
        to={`/${formatPathname(pathname)}/?${search}`}
      />
    )
  }

  render() {
    const { meta, classes, t } = this.props

    if (!meta || !meta.page_count || meta.page_count === 1) {
      return null
    }

    const { curr_page: current, page_count: count } = meta

    return (
      <div className={classes}>
        <span className='pager__text'>
          {' '}
          {t('Page')} {current} <strong>{t('From')}</strong> {count}
        </span>
        {current === 1 ? (
          <span className='pager__previous pager__previous_disable' />
        ) : (
          this.renderItem(current - 1, 'pager__previous')
        )}

        {current === count ? (
          <span className='pager__next pager__next_disable' />
        ) : (
          this.renderItem(current + 1, 'pager__next')
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  location: getLocation(state),
})

export default compose(
  withTranslation('TopPagination'),
  connect(mapStateToProps)
)(TopPagination)
