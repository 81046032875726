import { put, call, fork, takeEvery } from 'redux-saga/effects'
import api from '../../../core/api'

import { serverError } from '../../Layout/Layout.actions'
import * as actions from './AddManagerPopUp.actionTypes'

function * watchAdd () {
  yield takeEvery(actions.ADD_MANAGER_POPUP_SAVE, add)
}

function * add ({ params, onSave }) {
  try {
    const result = yield call(api.profile.invite, params)
    const error = result.find((obj) => obj.errors)
    if (error) {
      yield put({ type: actions.ADD_MANAGER_POPUP_ERROR, data: params, result })
    } else {
      yield put({ type: actions.ADD_MANAGER_POPUP_SAVED })
      if (onSave) {
        onSave()
      }
    }
  } catch (error) {
    yield put(serverError(error))
  }
}

export default function * watch () {
  yield fork(watchAdd)
}
