// @flow

import { all, put, call, fork, takeEvery } from 'redux-saga/effects'

import api from '../../core/api'
import { serverError } from '../Layout/Layout.actions'
import * as actions from './FriendsNotifications.actionTypes'
import { UPDATE_COUNTERS_SUCCESS } from '../init/init.actionTypes'
import { FRIENDS_REQUESTS_INITIATING } from '../FriendsRequests/FriendsRequests.actionTypes'
import { FRIENDS_LIST_LOAD } from '../Friends/Friends.actionTypes'

function* watchInit() {
  yield takeEvery(actions.FRIEND_NOTIFICATION_INITIATE, init)
}

function* watchLoad() {
  yield takeEvery(actions.FRIEND_NOTIFICATION_LOAD, load)
}

function* watchHandle() {
  yield takeEvery(actions.FRIEND_NOTIFICATION_HANDLE, handle)
}

function* init() {
  try {
    const { meta } = yield call(api.friends.getIncoming)
    yield put({ type: actions.FRIEND_NOTIFICATION_INITIATED, ...meta })
    yield put({
      type: UPDATE_COUNTERS_SUCCESS,
      counters: { friend_invites: meta.count },
    })
  } catch (error) {
    yield put(serverError(error))
  }
}

function* load({ page }) {
  try {
    const result = yield call(api.friends.getIncoming, { page })
    yield put({ type: actions.FRIEND_NOTIFICATION_LOADED, ...result })
  } catch (error) {
    yield put(serverError(error))
  }
}

function* handle({ page, user, accept }) {
  try {
    const relationship = `friend_request_${accept ? 'accepted' : 'rejected'}`
    yield call(api.friends.handleRequest, { user, relationship })

    const pageSize = page ? 20 * page : null
    const { results } = yield call(api.friends.getIncoming, {
      page_size: pageSize,
    })
    const { meta } = yield call(api.friends.getIncoming, { page })
    yield put({ type: actions.FRIEND_NOTIFICATION_LOADED, results, meta })
    yield put({
      type: UPDATE_COUNTERS_SUCCESS,
      counters: { friend_invites: meta.count },
    })

    yield put({ type: FRIENDS_REQUESTS_INITIATING })
    yield put({ type: FRIENDS_LIST_LOAD, page: 1 })
  } catch (error) {
    yield put(serverError(error))
  }
}

export default function* watch() {
  yield all([fork(watchInit), fork(watchLoad), fork(watchHandle)])
}
