// @flow

import { isNull } from 'lodash-es'

export function getProcessedValue(value: any, t: Function): string {
  if (isNull(value)) {
    return t('AttrNullValue')
  }

  if (value === true) {
    return t('AttrTrueValue')
  }

  if (value === false) {
    return t('AttrFalseValue')
  }

  return value
}
