// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import type { Node } from 'react'

import styles from './Field.module.scss'

type Props = {
  children?: Node,
  error?: string,
  handleChange?: (SyntheticEvent<HTMLInputElement>) => void,
  name: string,
  type: 'text' | 'number',
  value?: string | number,
}

const Field = (props: Props) => {
  const { error, name, value, children, type } = props
  const { t } = useTranslation('Integrations')

  const fieldClass = classnames(
    'input input--large input--block input--default',
    {
      'input--error': !!error,
    }
  )

  return (
    <div className='settings-personal__form-row'>
      <div className='settings-personal__form-cell'>
        <label className='settings-personal__form-label'>{t(name)}</label>
      </div>
      <div className='settings-personal__form-cell'>
        <div className={fieldClass}>
          {children || (
            <input
              className={styles.input}
              type={type}
              value={value}
              name={name}
              onChange={props.handleChange}
            />
          )}
          <div className='input__msg'>{error}</div>
        </div>
      </div>
    </div>
  )
}

Field.defaultProps = {
  type: 'text',
}

export default Field
