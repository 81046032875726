// @flow

import { commitMutation, graphql } from 'react-relay'

import environment from '../../createRelayEnvironment'

const mutation = graphql`
  mutation CreateProviderPageMutation($input: ExternalProviderMutationInput!) {
    mutateProviderEx(input: $input) {
      provider {
        id
      }
    }
  }
`

const commit = (providerData, callback, errorCallback) => {
  return commitMutation(environment, {
    mutation,
    variables: {
      input: providerData,
    },
    onCompleted: callback,
    onError: errorCallback,
  })
}

export default { commit }
