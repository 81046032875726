// @flow

import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import { useOverflow } from '../../hooks/useOverflow'
import Button from '../Button'
import Modal from '../Modal'
import ModalBody from '../Modal/ModalBody'
import ModalButtons from '../Modal/ModalButtons'
import ModalCloseButton from '../Modal/ModalCloseButton'
import ModalHead from '../Modal/ModalHead'
import ModalRow from '../Modal/ModalRow/ModalRow'
import InputField from '../InputField/InputField'
import ModalCell from '../Modal/ModalCell'
import ModalLabel from '../Modal/ModalLabel'

type Props = {
  externalId?: ?string,
  onClose: Function,
  title?: string,
  updateExternalId: Function,
}

const ExternalIdModal = (props: Props): Node => {
  const { externalId, title } = props

  const { t } = useTranslation('Building')

  const [working, setWorking] = useState(false)

  useOverflow()

  const handleUpdate = async externalId => {
    setWorking(true)

    await props.updateExternalId(externalId)

    props.onClose()
  }

  const formik = useFormik({
    initialValues: {
      externalId: externalId || '',
    },
    validationSchema: Yup.object({
      externalId: Yup.string()
        .transform(current => {
          if (current) {
            return current.trim()
          }

          return current
        })
        .max(255, t('ExternalIdMaxLength', { maxLength: 255 }))
        .matches(/^(\w|\/|-|\.|;|:| )*$/, t('WrongFormat')),
    }),
    onSubmit: values => {
      handleUpdate((values.externalId && values.externalId.trim()) || null)
    },
  })

  return (
    <Modal isOpen>
      <ModalCloseButton onClose={props.onClose} />
      <ModalHead title={title || t('ExternalIdEditTitle')} />
      <form onSubmit={formik.handleSubmit}>
        <ModalBody>
          <ModalRow>
            <ModalCell>
              <ModalLabel>{t('ExternalIdLabel')}</ModalLabel>
            </ModalCell>
            <ModalCell gridNumber={2}>
              <InputField
                autoFocus
                name='externalId'
                value={formik.values.externalId}
                error={formik.errors.externalId}
                placeholder={t('ExternalIdPlaceholder')}
                onChange={formik.handleChange}
              />
            </ModalCell>
          </ModalRow>
        </ModalBody>
        <ModalButtons>
          <Button.Save disabled={working || !formik.dirty} type='submit'>
            {t('Common:Save')}
          </Button.Save>
          <Button.Cancel disabled={working} onClick={props.onClose}>
            {t('Common:Cancel')}
          </Button.Cancel>
        </ModalButtons>
      </form>
    </Modal>
  )
}

export default ExternalIdModal
