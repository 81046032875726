// @flow

import { clone, pull, map, take } from 'lodash-es'

import * as actions from './BuildingFlats.actionTypes'
import * as flatPopupActions from '../../modals/AddFlatPopup/AddFlatPopup.actionTypes'

const initialState = {
  initiated: false,
  flats: [],
  meta: {},
  permissions: {},
  selected: [],
  ordering: null,
  working: true,
  modal: false,
  pagination: false,
  reload: false,
  iscompletereload: true,
  error: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.BUILDING_FLATS_INITIATING:
      return {
        ...initialState,
      }
    case actions.BUILDING_FLATS_INITIATED:
      return {
        ...state,
        flats: action.results.objects,
        meta: action.meta,
        permissions: action.permissions,
        initiated: true,
        working: false,
        iscompletereload: true,
      }
    case actions.BUILDING_FLATS_SHOW_MODAL:
      return {
        ...state,
        modal: action.modal,
      }
    case actions.BUILDING_FLATS_HIDE_MODAL:
    case flatPopupActions.FLAT_POPUP_ADDED_FLAT:
      return {
        ...state,
        modal: false,
      }
    case actions.BUILDING_FLATS_TOGGLE: {
      const { id } = action

      let selected = clone(state.selected)

      if (selected.includes(id)) {
        selected = pull(selected, id)
      } else {
        selected.push(id)
      }

      return {
        ...state,
        selected,
      }
    }
    case actions.BUILDING_FLATS_TOGGLE_ALL: {
      let selected = []
      const flats = take(state.flats, action.count)

      if (state.selected.length !== flats.length) {
        selected = map(flats, item => item.id)
      }

      return {
        ...state,
        selected,
      }
    }
    case actions.BUILDING_FLATS_SHOW_PAGINATION:
      return {
        ...state,
        pagination: true,
      }
    case actions.BUILDING_FLATS_RELOAD:
      return {
        ...state,
        iscompletereload: false,
        working: true,
      }
    case actions.BUILDING_FLATS_RELOAD_UPDATE:
      return {
        ...state,
        iscompletereload: true,
        reload: false,
        flats: action.results.objects,
        meta: action.meta,
        permissions: action.permissions,
        working: false,
      }
    case actions.BUILDING_FLATS_SET_ORDERING:
      return {
        ...state,
        ordering: action.value,
      }
    case actions.BUILDING_FLATS_LOAD:
      return {
        ...state,
        working: true,
        selected: [],
        modal: false,
      }
    case actions.BUILDING_FLATS_REMOVE:
      return {
        ...state,
        working: true,
        selected: [],
        modal: false,
      }
    case actions.BUILDING_FLATS_UPDATE:
      return {
        ...state,
        working: false,
        flats: action.results.objects,
        permissions: action.permissions,
        meta: action.meta,
        reload: false,
      }
    case actions.BUILDING_FLATS_ERROR:
      return {
        ...state,
        initiated: true,
        working: false,
        error: action.error.message,
      }
    default:
      return state
  }
}
