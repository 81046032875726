// @flow

import {
  PROFILE_INITIATING,
  PROFILE_RESET,
  PROFILE_SECTION_READY,
  PROFILE_UPDATE,
  PROFILE_UPDATE_FILES,
} from './Profile.actionTypes'
import { CONTACT_FORM_INITIATING } from '../../components/ContactForm/ContactForm.actionTypes'
import { PROFILE_CONTACTS_UPDATE } from '../../components/Profile/ProfileContacts/ProfileContacts.actionTypes'

export function initiate(id, archived, external, isAdmin) {
  return { type: PROFILE_INITIATING, id, archived, external, isAdmin }
}
export function reset() {
  return { type: PROFILE_RESET }
}
export function sectionReady(key) {
  return { type: PROFILE_SECTION_READY, key }
}
export function profileUpdate({ id, params, external }) {
  return { type: PROFILE_UPDATE, id, params, external }
}
export function updateFiles({ id, files }) {
  return {
    type: PROFILE_UPDATE_FILES,
    files,
    id,
  }
}
export function updateContacts(data) {
  return {
    type: PROFILE_CONTACTS_UPDATE,
    data,
  }
}
export function initiateProfileContacts({ profileId, modelId }) {
  return {
    type: CONTACT_FORM_INITIATING,
    profileId,
    modelId,
  }
}
