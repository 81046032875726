// @flow

import React from 'react'
import classnames from 'classnames'
import type { Node } from 'react'

import Button from '../Button'

type Props = {
  children: Node,
  className: string,
  onClose: () => void,
}

const Content = ({ children, className, ...props }: Props): Node => (
  <div className={classnames('modal__content', className)}>
    <Button.Close large onClick={props.onClose} />
    {children}
  </div>
)

export default Content
