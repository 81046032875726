// @flow

import { deleteApi, getApi, patchRaw, postRaw } from './api.core'

const basePath = 'api/text-module/email-snippet'

export const getEmailTemplates = (params: Object) =>
  getApi(`${basePath}/`, params)
export const createEmailTemplate = (params: {
  name: string,
  subject?: string,
  tags?: Array<string>,
  text: string,
}) => postRaw(`${basePath}/`, params)
export const cloneEmailTemplate = (id: number, params?: { name: string }) =>
  postRaw(`${basePath}/${id}/clone/`, params)
export const deleteEmailTemplate = (id: number) =>
  deleteApi(`${basePath}/${id}/`)
export const getEmailVariables = (params?: { tags: string }) =>
  getApi(`${basePath}/variables/`, params)
export const getAuthors = params => getApi(`${basePath}/authors/`, params)
export const getEmailTags = params => getApi(`${basePath}/tags/`, params)
export const patchEmailTemplate = (
  id: number,
  params?: {
    hidden?: boolean,
    name?: string,
    subject?: string,
    tags?: Array<string>,
    text?: string,
  }
): Promise<Object> => patchRaw(`${basePath}/${id}/`, params)
export const renderEmailTemplate = (id: number, params?: Object) =>
  postRaw(`${basePath}/${id}/render/`, params)
export const renderEmailPreview = (id: number, params?: { text: string }) =>
  postRaw(`${basePath}/${id}/render-preview/`, params)
export const analyzeVariables = (id: number, params: Object) =>
  postRaw(`${basePath}/${id}/analyze-variables/`, params)
export const analyzeVariablesStatus = eventId =>
  getApi(`api/text-module/analyze-variables-status/${eventId}/`)
