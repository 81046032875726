//@flow

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type Node from 'react'

import Modal from '../../../Modal'
import ModalBody from '../../../Modal/ModalBody'
import ModalCloseButton from '../../../Modal/ModalCloseButton'
import ModalHead from '../../../Modal/ModalHead'
import ModalButtons from '../../../Modal/ModalButtons'
import Button from '../../../Button'
import Loader from '../../../Loader'
import InputField from '../../../InputField'

import DocumentPreview from '../../../MappedDocument/DocumentPreview'

import { useDebounce, useOverflow, useThemeColor } from '../../../../hooks'

import {
  getGoogleTemplates,
  refreshGoogleTemplates,
  getExternalVars,
} from '../../../../core/api/api.docTemplates'

import Icon from '../../../Icon'

import classnames from 'classnames'
import styles from './DocumentTemplatesModal.module.scss'

type Props = {
  extVars: Object,
  folderType: string,
  isOpen: boolean,
  onAddTemplate: (Object, Object) => void,
  onClose: () => void,
  templateId: number,
}

const DocumentTemplatesModal = (props: Props): Node => {
  const { isOpen, onClose, folderType, onAddTemplate, templateId, extVars } =
    props

  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const [templates, setTemplates] = useState([])
  const [template, setTemplate] = useState(null)
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)
  const [varsLoading, setVarsLoading] = useState(false)
  const [docLoading, setDocLoading] = useState(false)
  const [noLink, setNoLink] = useState(false)
  const [pagesContent, setPagesContent] = useState([])
  const [externalVars, setExternalVars] = useState({})
  const [initialExtVars, setInitialExtVars] = useState(extVars)
  const [page, setPage] = useState(1)
  const [initActive, setInitActive] = useState(false)

  const debouncedSearch = useDebounce(search, 500)

  const { t } = useTranslation('Mail')
  useOverflow()
  const themeColor = useThemeColor()

  useEffect(() => {
    setHeight(Math.floor(document.body.offsetHeight * 0.7))
    setWidth(Math.floor(document.body.offsetHeight * 0.45))
  }, [])

  useEffect(() => {
    getTemplates()
  }, [debouncedSearch])

  useEffect(() => {
    if (templates.length > 0 && templateId > 0) {
      const [currentTemplate] = templates.filter(t => t.id === templateId)

      if (currentTemplate) {
        setTemplate(currentTemplate)
      }
    }
  }, [templates])

  useEffect(() => {
    if (template) {
      setVarsLoading(true)
      setDocLoading(true)
      getExternalVars(template.id).then(vars => {
        if (Array.isArray(vars)) {
          let ext_vars = {}
          vars.forEach(v => {
            ext_vars[v] = ''
          })
          setExternalVars({ ...ext_vars, ...initialExtVars })
          setInitialExtVars({})
          setVarsLoading(false)
        }
      })

      if (templateId === template.id && !initActive) {
        document
          .getElementById(`template_menu_item_${templateId}`)
          .scrollIntoView()
        setInitActive(true)
      }
    }
  }, [template])

  const resetSearch = () => setSearch('')

  const getTemplates = () => {
    setLoading(true)
    setNoLink(false)
    getGoogleTemplates(debouncedSearch)
      .then(data => {
        if (!Array.isArray(data[folderType])) {
          setNoLink(true)
        } else {
          setTemplates(data[folderType])
        }
      })
      .catch(() => {
        setNoLink(true)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const refreshTemplates = () => {
    setLoading(true)
    refreshGoogleTemplates().then(() => getTemplates())
  }

  const handleAddTemplate = () => {
    onAddTemplate(template, externalVars)
    onClose()
  }

  const handleDocLoaded = pdf => {
    const count = pdf?.numPages || 1

    if (pdf) {
      let content = []
      for (let i = 1; i <= count; i++) {
        pdf.getPage(i).then(page =>
          page.getTextContent().then(data => {
            if (Array.isArray(data.items)) {
              content[i] = data.items.map(item => item.str).join('')
            }
          })
        )
      }
      setPagesContent(content)
    }

    setDocLoading(false)
  }

  const handleChangeExtVar = e => {
    setExternalVars({ ...externalVars, [e.target.name]: e.target.value })
  }

  const highlightVar = e => {
    if (pagesContent.length === 2) {
      toggleHighlight(e.target.name)
    } else {
      const varName = e.target.name
      setPage(getExtVarPage(varName))
      setTimeout(() => toggleHighlight(varName), 0)
    }
  }

  const getExtVarPage = varName => {
    for (let i = 1; i <= pagesContent.length; i++) {
      if (pagesContent[i].includes(`{{${varName}}}`)) {
        return i
      }
    }

    return 1
  }

  const removeHighlight = e => toggleHighlight(e.target.name, true)

  const toggleHighlight = (varName, remove = false) => {
    const replace = [`{{${varName}}}`, `<mark>{{${varName}}}</mark>`]

    if (remove) {
      replace.reverse()
    }

    const el = document.querySelector(
      '.doc-template-preview .react-pdf__Page__textContent'
    )
    el.innerHTML = el.innerHTML.replaceAll(...replace)
  }

  return (
    <>
      <Modal isOpen={isOpen} className={styles.modal} onRequestClose={onClose}>
        <ModalHead title={t('DocumentTemplatesTitle')} />
        <ModalCloseButton onClose={onClose} />
        <ModalBody
          style={{
            '--themeColor': themeColor,
            height: `${height}px`,
          }}
          className={styles.documents}
        >
          <div className={styles.body}>
            <div className={styles.list}>
              <div className={styles.search}>
                <InputField
                  autoFocus
                  name='search-template'
                  value={search}
                  placeholder={t('SearchForTemplates')}
                  onChange={e => setSearch(e.target.value)}
                />
                <Icon
                  id={search.length > 0 ? 'cross' : 'magnifier'}
                  onClick={search.length > 0 ? resetSearch : false}
                />
              </div>
              {search.length > 0 && templates.length === 0 && !loading && (
                <span className={styles.noResults}>{t('NoResults')}</span>
              )}
              {loading ? (
                <Loader text={false} type='medium' />
              ) : (
                <ul
                  style={{
                    height: `${height - 108}px`,
                  }}
                >
                  {templates.map(t => (
                    <li
                      className={classnames({
                        [styles.itemActive]: t.id === template?.id,
                      })}
                      key={t.id}
                      id={`template_menu_item_${t.id}`}
                      onClick={() => setTemplate(t)}
                    >
                      <span>{t.name}</span>
                    </li>
                  ))}
                </ul>
              )}
              <div className={styles.refresh} onClick={refreshTemplates}>
                <span>{t('RefreshTemplatesList')}</span>
                <Icon id='updates' />
              </div>
            </div>
            <DocumentPreview
              fileOrigin={template?.pdf_export_link}
              fileType='pdf'
              height={height}
              iconId={noLink ? 'magnifier' : 'request'}
              outerWidth={template ? width + 240 : width + 502}
              page={page}
              setPage={setPage}
              placeholder={
                noLink
                  ? t('DocumentPreviewError', {
                      field: t(`FolderType.${folderType}`),
                    })
                  : t('DocumentPreviewPlaceholder')
              }
              width={width}
              onLoadSuccess={handleDocLoaded}
            />
            {template && (
              <div
                className={styles.variables}
                style={{
                  height: `${height - 2}px`,
                }}
              >
                {!varsLoading &&
                  !docLoading &&
                  Object.keys(externalVars).map(v => (
                    <div key={v} className={styles.variable}>
                      <div className={styles.label}>
                        <span>{v}</span>
                      </div>
                      <div className={styles.input}>
                        <InputField
                          name={v}
                          value={externalVars[v]}
                          onChange={handleChangeExtVar}
                          onFocus={highlightVar}
                          onBlur={removeHighlight}
                        />
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
        </ModalBody>
        <ModalButtons>
          <Button.Save
            disabled={!template}
            working={docLoading || varsLoading}
            onClick={handleAddTemplate}
          >
            {templateId ? t('UpdateTemplate') : t('AddTemplate')}
          </Button.Save>
          <Button.Cancel onClick={onClose}>{t('Cancel')}</Button.Cancel>
        </ModalButtons>
      </Modal>
    </>
  )
}

export default DocumentTemplatesModal
