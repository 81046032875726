// @flow

import React from 'react'
import type { Node } from 'react'

type Props = {
  children: Node,
  style?: Object,
}

const NewTableHeader = (props: Props): Node => {
  const { children, style } = props

  return <thead style={style}>{children}</thead>
}

export default NewTableHeader
