// @flow

import React from 'react'
import classnames from 'classnames'
import { useSelector } from 'react-redux'
import type { Node } from 'react'

import Logo from './Logo'
import PoweredBy from './PoweredBy'
import { getUk } from '../../../utils/commonSelectors'

type Props = {
  children: ?Node,
  working: boolean,
}

const AuthPage = (props: Props): Node => {
  const {
    bg_image_obj: { origin },
  } = useSelector(getUk)

  const { children, working } = props

  const authClass = classnames('auth-page', {
    'working-overlay': working,
  })

  const src = `url(${origin})`

  return (
    <div className={authClass} style={{ backgroundImage: src }}>
      <div className='auth-form'>
        <Logo />
        {children}
        <PoweredBy />
      </div>
    </div>
  )
}

AuthPage.defaultProps = {
  working: false,
}

export default AuthPage
