// @flow

export const BUILDING_DWELLERS_INITIATING = 'BUILDING_DWELLERS_INITIATING'
export const BUILDING_DWELLERS_INITIATED = 'BUILDING_DWELLERS_INITIATED'
export const BUILDING_DWELLERS_SHOW_MODAL = 'BUILDING_DWELLERS_SHOW_MODAL'
export const BUILDING_DWELLERS_HIDE_MODAL = 'BUILDING_DWELLERS_HIDE_MODAL'
export const BUILDING_DWELLERS_TOGGLE = 'BUILDING_DWELLERS_TOGGLE'
export const BUILDING_DWELLERS_TOGGLE_ALL = 'BUILDING_DWELLERS_TOGGLE_ALL'
export const BUILDING_DWELLERS_SET_ORDERING = 'BUILDING_DWELLERS_SET_ORDERING'
export const BUILDING_DWELLERS_SET_FILTER = 'BUILDING_DWELLERS_SET_FILTER'
export const BUILDING_DWELLERS_LOAD = 'BUILDING_DWELLERS_LOAD'
export const BUILDING_DWELLERS_LOAD_ONE = 'BUILDING_DWELLERS_LOAD_ONE'
export const BUILDING_DWELLERS_REMOVE = 'BUILDING_DWELLERS_REMOVE'
export const BUILDING_DWELLERS_UPDATE = 'BUILDING_DWELLERS_UPDATE'
export const BUILDING_DWELLERS_ERROR = 'BUILDING_DWELLERS_ERROR'
export const BUILDING_DWELLERS_RELOAD = 'BUILDING_DWELLERS_RELOAD'
export const BUILDING_DWELLERS_RELOAD_END = 'BUILDING_DWELLERS_RELOAD_END'
export const BUILDING_DWELLERS_UPDATE_ROLE = 'BUILDING_DWELLERS_UPDATE_ROLE'
export const BUILDING_DWELLERS_RESET = 'BUILDING_DWELLERS_RESET'
export const BUILDING_DWELLERS_SET_SELECTED = 'BUILDING_DWELLERS_SET_SELECTED'
export const BUILDING_DWELLERS_BEFORE_LOAD = 'BUILDING_DWELLERS_BEFORE_LOAD'
