// @flow

import React from 'react'

import Igel from './Igel'
import RealData from './RealData'
import Domus from './Domus'

const Integrations = () => (
  <div className='content__col'>
    <Igel />
    <RealData />
    <Domus />
  </div>
)

export default Integrations
