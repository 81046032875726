// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import { QueryRenderer, graphql } from 'react-relay'
import { map } from 'lodash-es'

import Loader from '../../../Loader'
import ErrorMessage from '../../../../ui/ErrorMessage'
import environment from '../../../../createRelayEnvironment'
import ValueItem from '../../../Select/ValueItem'
import { Row } from '../../../Block'
import { INFINITY_FIRST } from './Orders.constants'
import OrdersPopup from './OrdersPopup'

const { MarketplaceError } = ErrorMessage

const infoQuery = graphql`
  query OrdersInfoQuery($orderIds: String) {
    allLimitedCustomerOrders(ids: $orderIds) {
      totalCount
      edges {
        node {
          orderNo
          customerOrderId
          title
          canView
        }
      }
    }
  }
`

function getLabel(order, t) {
  return `${t('OrderNumberPrefix')} №${order.orderNo}: ${
    order.title || JSON.parse(order.offer).name
  }`
}

const Orders = props => {
  const {
    permissions: {
      can_view_request_order_link: canViewRequestOrderLink,
      can_edit_request_order_link: canEditRequestOrderLink,
    },
    editing,
    orderIds,
    softArchived,
  } = props

  const { t } = useTranslation('Request')

  if (!canViewRequestOrderLink) {
    return null
  }

  const render = ({ error, props: queryProps }) => {
    if (error) {
      return <MarketplaceError error={error} />
    } else if (queryProps) {
      const items = map(queryProps.allLimitedCustomerOrders.edges, 'node')

      return (
        <>
          {items.map(item => (
            <ValueItem
              inline
              profile
              margin
              round
              text={getLabel(item, t)}
              key={item.orderNo}
              valueId={item.customerOrderId}
              isLink={item.canView}
              pathName='marketplace/order'
            />
          ))}
        </>
      )
    } else {
      return <Loader type='small' text={false} />
    }
  }

  const handleClose = () => props.onEdit(null)

  return (
    <Row
      title={t('OrdersTitle')}
      onEdit={
        !editing &&
        canEditRequestOrderLink &&
        !softArchived &&
        (() =>
          props.onEdit(
            <OrdersPopup
              orderIds={orderIds}
              title={t('SetOrdersTitle')}
              onClose={handleClose}
              onChange={props.onChange}
            />
          ))
      }
    >
      {orderIds && orderIds.length ? (
        <QueryRenderer
          environment={environment}
          query={infoQuery}
          variables={{
            orderIds: JSON.stringify(orderIds),
            pageSize: INFINITY_FIRST,
            first: INFINITY_FIRST,
          }}
          render={render}
        />
      ) : (
        <span className='bar__submit-label_light bar__submit-label no-manager-assignet-label'>
          {t('NoOrders')}
        </span>
      )}
    </Row>
  )
}

export default Orders
