// @flow

import React from 'react'
import { get } from 'lodash-es'
import { withRouter } from 'react-router-dom'
import type { Location, Match } from 'react-router-dom'

import Files from '../Files'

type Props = {
  flat: Object,
  isDweller: boolean,
  location: Location,
  match: Match,
  permissions: Object,
}

const FlatDocuments = (props: Props) => {
  const directoryId = get(props, ['flat', 'directory', 'id'])

  const flatInfo = { type: 'flat_id', id: props.flat.id }

  return (
    <Files
      scope={'flat'}
      isDweller={props.isDweller}
      subject={flatInfo}
      directoryId={directoryId}
      match={props.match}
      location={props.location}
      permissions={props.permissions}
    />
  )
}

export default withRouter(FlatDocuments)
