// @flow

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'
import type { RouterHistory, Location } from 'react-router-dom'

import Loader from '../../../components/Loader'
import Modal from '../../../components/Modal'
import MailThreadInfo from '../../../components/Mail/MailThreadInfo'
import MailMessageFull from '../../../components/Mail/MailMessageFull'
import MailMessageShort from '../../../components/Mail/MailMessageShort'
import NewMailReplyForm from '../../../components/Mail/NewMailReplyForm'
import ConvertToRequestPopup from '../../../components/modals/ConvertToRequestPopup'
import AttachToRequestPopup from '../../../components/modals/AttachToRequestPopup'
import ConfirmationPopup from '../../../components/modals/ConfirmationPopup'
import Nbsp from '../../../components/NBSP'
import * as actions from './MailThread.actionTypes'
import { getBoolean, getCurrentFilters } from '../../../utils/routing'
import { getUser, isWizardEnabled } from '../../../utils/commonSelectors'
import { SimpleBlock, Header } from '../../../components/Block'
import {
  convertUserToManagerSearchObj,
  getForwardMessage,
  getReplyMessage,
} from '../../../utils/utils'
import NewWizard from '../../../components/NewWizard'
import { newLineToBr } from '../../../utils/text'
import { resendMessage } from '../../../core/api/api.mail'
import {
  globalModalError,
  serverError,
} from '../../../components/Layout/Layout.actions'
import { get } from 'lodash-es'

type Props = {
  dispatch: Object => void,
  history: RouterHistory,
  initiated: boolean,
  isWizardEnabled: boolean,
  location: Location,
  match: Object,
  messageCounter: number,
  messages: Array<Object>,
  modal: any,
  t: string => string,
  thread: Object,
  unicAddressee: Object,
  user: Object,
  working: boolean,
}

type State = {
  isWizardOpen: boolean,
  selectedMessageId: ?number,
  softArchived: boolean,
}

class MailThread extends Component<Props, State> {
  state = {
    selectedMessageId: null,
    softArchived: false,
    isWizardOpen: false,
  }

  componentDidMount() {
    const {
      match: {
        params: { uuid },
      },
      location,
    } = this.props

    const filters = getCurrentFilters(location)
    const softArchived = getBoolean(filters, 'soft_archived')

    this.setState({ softArchived })

    if (uuid === 'create') {
      const emptyThread = {
        uuid,
        created: new Date().toISOString(),
        title: '',
      }
      this.props.dispatch({
        type: actions.MAIL_INITED,
        thread: emptyThread,
        messages: [],
        meta: {},
        unicAddressee: new Set([]),
      })
    } else {
      this.props.dispatch({
        type: actions.MAIL_INIT,
        uuid,
        softArchived,
      })
    }
  }

  componentDidUpdate() {
    const { messages } = this.props

    let { selectedMessageId } = this.state

    if (!selectedMessageId && messages.length) {
      selectedMessageId = messages[messages.length - 1].uuid
      this.setState({ selectedMessageId })
    }
  }

  componentWillUnmount() {
    this.props.dispatch({
      type: actions.MAIL_RESET,
    })
  }

  onClickMessage = uuid => {
    this.setState({ selectedMessageId: uuid })
  }

  handleCloseWizard = () => {
    this.setState({ isWizardOpen: false })
  }

  showConvertModal = id => {
    if (this.props.isWizardEnabled) {
      this.setState({ isWizardOpen: true })
    } else {
      const modal = (
        <ConvertToRequestPopup emailMessageId={id} onClose={this.hideModal} />
      )
      this.props.dispatch({
        type: actions.MAIL_MODAL,
        modal,
      })
    }
  }

  showAttachModal = id => {
    const modal = (
      <AttachToRequestPopup emailMessageId={id} onClose={this.hideModal} />
    )
    this.props.dispatch({
      type: actions.MAIL_MODAL,
      modal,
    })
  }

  hideModal = () =>
    this.props.dispatch({ type: actions.MAIL_MODAL, modal: null })

  save = params => {
    this.props.dispatch({
      type: actions.MAIL_CREATE,
      params,
    })
  }

  update = params => {
    this.props.dispatch({
      type: actions.MAIL_UPDATE,
      params: {
        ...params,
        thread: this.props.match.params.uuid,
      },
    })
  }

  remove = uuid => {
    this.props.dispatch({
      type: actions.REMOVE_MESSAGE,
      uuid,
      isLast: this.props.messages.length === 1,
      threadUUID: this.props.match.params.uuid,
    })
  }

  restore = uuid => {
    this.props.dispatch({
      type: actions.RESTORE_MESSAGE,
      uuid,
      isLast: this.props.messages.length === 1,
      threadUUID: this.props.match.params.uuid,
    })
  }

  onDelete = uuid => {
    const { t } = this.props
    const modal = (
      <ConfirmationPopup
        title={t('DeleteMessageTitle')}
        text={t('ConfirmActionDeleteText')}
        confirm={t('Delete')}
        cancel={t('Cancel')}
        onClose={this.hideModal}
        onOk={() => this.remove(uuid)}
      />
    )
    this.props.dispatch({
      type: actions.MAIL_MODAL,
      modal,
    })
  }

  onRestore = uuid => {
    const { t } = this.props
    const modal = (
      <ConfirmationPopup
        title={t('RestoreMessageTitle')}
        text={t('ConfirmActionRestoreText')}
        confirm={t('Restore')}
        cancel={t('Cancel')}
        onClose={this.hideModal}
        onOk={() => this.restore(uuid)}
      />
    )
    this.props.dispatch({
      type: actions.MAIL_MODAL,
      modal,
    })
  }

  getImgString = alt => {
    return `<p>[ ${alt} ]</p>`
  }

  getQuote = text => {
    return `<blockquote>${text.replace(
      /<img.*?alt=['"](.*?)['"].*?>/g,
      this.getImgString('$1')
    )}</blockquote>`
  }

  onResend = () => {
    const { uuid } = this.props

    resendMessage(uuid)
      .then(() => {})
      .catch(error => {
        const status = get(error, ['message', 'response', 'status'], null)
        const errorText = get(
          error,
          ['message', 'response', 'data', 'errors'],
          null
        )

        if (status === 400) {
          this.props.dispatch(globalModalError(errorText, '', true))
        } else if (status) {
          this.props.dispatch(serverError(error))
        }
      })
  }

  getReplyValues = (uuid, owner, toList, subject, created, sender_email) => {
    const { messages, user } = this.props

    const message: Object = messages.find(m => m.uuid === uuid)

    let to = []

    if (user.owner === message.owner_obj.id && message.to_objs.length) {
      to = message.to_objs.map(someone =>
        convertUserToManagerSearchObj(someone)
      )
    } else {
      to = [convertUserToManagerSearchObj(message.owner_obj)]
    }

    return {
      to,
      cc: [],
      bcc: [],
      message: `${getReplyMessage(
        owner,
        created,
        this.props.t,
        sender_email
      )}${this.getQuote(newLineToBr(message.text))}`,
      title: subject,
    }
  }

  getReplyAllValues = (uuid, owner, toList, subject, created, sender_email) => {
    const { messages, user } = this.props

    const message: Object = messages.find(m => m.uuid === uuid)

    let to = [message.owner_obj].concat(message.to_objs)
    to = to.filter(u => u.id !== user.owner)

    return {
      to: to.map(convertUserToManagerSearchObj),
      bcc: [],
      cc: message.cc_objs.map(convertUserToManagerSearchObj),
      message: `${getReplyMessage(
        owner,
        created,
        this.props.t,
        sender_email
      )}${this.getQuote(newLineToBr(message.text))}`,
      title: subject,
    }
  }

  getForwardValues = (uuid, owner, toList, subject, created, sender_email) => {
    const { messages } = this.props

    const message: Object = messages.find(m => m.uuid === uuid)

    return {
      cc: [],
      bcc: [],
      to: [],
      message: getForwardMessage(
        owner,
        toList,
        subject,
        created,
        newLineToBr(message.text),
        this.props.t,
        sender_email
      ),
      files: message.files,
      title: subject,
    }
  }

  onCollapseMessage = () => {
    this.setState({ selectedMessageId: null })
  }

  renderMessage = message => {
    const { selectedMessageId } = this.state
    const {
      messages,
      user: {
        language_obj: { code },
      },
      working,
      thread: { shared, title },
    } = this.props

    const { softArchived } = this.state

    const isLastMessage = message.uuid === messages[messages.length - 1].uuid

    if (message.uuid === selectedMessageId || isLastMessage) {
      const fullMessageProps = {
        ...message,
        key: message.uuid,
        onGetReplyValues: this.getReplyValues,
        onGetReplyAllValues: this.getReplyAllValues,
        onGetForwardValues: this.getForwardValues,
        onConverToRequest: this.showConvertModal,
        onAttachToRequest: this.showAttachModal,
        onDelete: this.onDelete,
        onRestore: this.onRestore,
        showReplyForm: isLastMessage,
        onCollapse: this.onCollapseMessage,
        onResend: this.onResend,
      }

      if (isLastMessage) {
        fullMessageProps.isToHided = true
      }

      return (
        <MailMessageFull
          {...fullMessageProps}
          showAddButton
          createProps={fullMessageProps}
          languageCode={code}
          working={working}
          softArchived={softArchived}
          isLastMessage={isLastMessage}
          shared={shared}
          threadTitle={title}
          onSave={this.update}
        />
      )
    }

    return (
      <MailMessageShort
        key={message.uuid}
        {...message}
        languageCode={code}
        onClickMessage={this.onClickMessage}
      />
    )
  }

  renderCreateThread() {
    const { thread, working } = this.props

    return (
      <section className='unit unit--default '>
        <NewMailReplyForm
          {...thread}
          showAddButton
          withProviders
          working={working}
          onSave={this.save}
        />
      </section>
    )
  }

  render() {
    const {
      t,
      initiated,
      thread,
      messages,
      messageCounter,
      modal,
      unicAddressee,
      history,
      user: {
        language_obj: { code },
      },
    } = this.props

    const { isWizardOpen } = this.state

    if (!initiated) {
      return <Loader text={false} type='big' />
    }

    return (
      <div className='mail-thread'>
        <MailThreadInfo
          languageCode={code}
          unicAddressee={unicAddressee}
          history={history}
          {...thread}
        />
        {thread.uuid === 'create' ? (
          this.renderCreateThread()
        ) : (
          <SimpleBlock>
            <Header header>
              {t('MailThreadTitle')}
              <Nbsp />
              <span className='unit__title-num message-counter'>
                {messageCounter && messageCounter > 0 && messageCounter}
              </span>
            </Header>
            <div className='messages'>{messages.map(this.renderMessage)}</div>
          </SimpleBlock>
        )}
        <Modal isOpen={!!modal} onRequestClose={this.hideModal}>
          {modal}
        </Modal>
        {isWizardOpen && <NewWizard onClose={this.handleCloseWizard} />}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state.mailThread,
  user: getUser(state),
  isWizardEnabled: isWizardEnabled(state),
})

export default compose(
  withTranslation('Mail'),
  connect(mapStateToProps)
)(MailThread)
