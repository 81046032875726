// @flow

import React from 'react'
import type { Node } from 'react'

import EmailIntegrationCreate from './EmailIntegrationCreate'

const PrivateEmailIntegrationCreate = (): Node => {
  return <EmailIntegrationCreate isPrivate />
}

export default PrivateEmailIntegrationCreate
