// @flow

import React, { PureComponent } from 'react'
import SuggestItem from './suggest-item'

export default class SuggestList extends PureComponent {
  isHidden() {
    return this.props.isHidden || this.props.suggests.length === 0
  }

  componentDidUpdate(prevProps) {
    if (this.props.suggests !== prevProps.suggests) {
      if (this.props.suggests.length === 0) {
        this.props.onSuggestNoResults()
      }
    }
  }

  render() {
    if (this.isHidden()) {
      return null
    }

    const {
      suggests,
      onSuggestMouseDown,
      onSuggestMouseOut,
      onSuggestSelect,
      activeSuggest,
    } = this.props

    return (
      <div className='dropdown dropdown--default dropdown--open'>
        <div className='dropdown__wrapper'>
          <ul className='dropdown__list dropdown__list--default'>
            {suggests.map(suggest => (
              <SuggestItem
                key={suggest.placeId}
                active={activeSuggest === suggest}
                suggest={suggest}
                onMouseDown={onSuggestMouseDown}
                onMouseOut={onSuggestMouseOut}
                onSelect={onSuggestSelect}
              />
            ))}
          </ul>
        </div>
      </div>
    )
  }
}

SuggestList.defaultProps = {
  isHidden: true,
  suggests: [],
}
