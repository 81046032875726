// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'
import Stickyfill from 'stickyfilljs'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'

import WidgetParticipantsList from '../../components/widgets/WidgetParticipantsList/WidgetParticipantsList'
import WidgetFiles from '../../components/widgets/WidgetFiles/WidgetFiles'
import { isMenuCollapsed } from '../../utils/commonSelectors'
import { getChatroomUuid } from '../Feedback/Feedback.selectors'
import { setWidget } from '../../containers/Widget/Widget.actions'
import Widgets, { WIDGET_TYPES } from './widgets'
import Lightbox from '../../components/LightboxGallery'

type Props = {
  chatroomUuid: string,
  collapsed: boolean,
  openedWidget: string,
  setWidget: string => void,
}

class FeedbackWidgets extends Component<Props> {
  state = {
    lightboxIndex: null,
  }
  componentDidMount() {
    const elements = document.querySelectorAll('.widget__container--content')
    Stickyfill.add(elements)

    this.props.setWidget(WIDGET_TYPES.files)
  }

  setFileIndex = ind => {
    this.setState({ lightboxIndex: ind })
  }

  closeLightbox = () => {
    this.setState({ lightboxIndex: null })
  }

  render() {
    const { collapsed, chatroomUuid, files } = this.props
    const params = { chatroomUuid: chatroomUuid || 'create' }
    const { lightboxIndex } = this.state

    return (
      <>
        <Widgets collapsed={collapsed}>
          <WidgetParticipantsList params={params} />
          <WidgetFiles setFileIndex={this.setFileIndex} />
        </Widgets>
        <Lightbox
          newGallery
          activeIndex={lightboxIndex}
          images={files}
          scope={'post'}
          onClose={this.closeLightbox}
        />
      </>
    )
  }
}

const mapStateToProps = state => ({
  ...state.WidgetFiles,
  collapsed: isMenuCollapsed(state),
  chatroomUuid: getChatroomUuid(state),
})
const mapDispatchToProps = {
  setWidget,
}

export default compose(
  withTranslation('WidgetsGeneral'),
  connect(mapStateToProps, mapDispatchToProps)
)(FeedbackWidgets)
