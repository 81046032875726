// @flow

import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { get, last, partial } from 'lodash-es'
import classNames from 'classnames'
import { useLocation, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import type { Node } from 'react'

import ModalHead from '../Modal/ModalHead'
import { useOverflow } from '../../hooks'
import {
  CARD_STAGE,
  INIT_STAGE,
  LOAD_STAGE,
  PROLONG_TIMEOUT,
  RESTORE_STAGE,
} from './NewWizard.constants'
import CancelModal from './CancelModal'
import {
  dryRun,
  sendPayloadChunk,
  initWizard,
  getChunk,
  prolong,
} from '../../core/api/api.wizard'
import InitStage from './InitStage'
import Modal from '../Modal'
import CardStage from './CardStage'
import NewWizardContext from './NewWizard.context'
import ModalCloseButton from '../Modal/ModalCloseButton/ModalCloseButton'
import {
  clearWizardUuid,
  getNewPath,
  getWizardPrevUuid,
  saveWizardUuid,
} from './NewWizard.utils'
import { LINK_TYPE, VARIABLE_TYPE } from './CardStage/CardStage.constants'
import RestoreModal from './RestoreModal'
import { getCurrentFilters } from '../../utils/routing'
import {
  getExtendedFilters,
  getOwnerFilter,
} from '../Request/RequestList/RequestList.utils'
import { REQUEST_LIST_LOAD } from '../Request/RequestList/RequestList.actionTypes'
import Loader from '../Loader'
import ErrorModal from './ErrorModal'
import { useUser } from '../../hooks'
import { isStaffUser } from '../../utils/utils'

import styles from './NewWizard.module.scss'
import { getBuilding } from '../../core/api/api.building'
import { getProfile } from '../../core/api/api.profile'
import { getFlat } from '../../core/api/api.flat'
import { globalModalError } from '../Layout/Layout.actions'

type Props = {|
  chatMessageId?: string,
  id?: number,
  messageUuid?: string,
  onClose: () => void,
  onReload?: () => void,
|}

const NewWizard = (props: Props): Node => {
  const { id, messageUuid, init, isChatroomPublic, chatMessageId, outbound } =
    props

  const { t } = useTranslation('WizardPopup')
  const prolongInterval = useRef()
  const location = useLocation()
  const params = useParams()
  const dispatch = useDispatch()
  const profileGroup = useSelector(state => state.profile.data.group)

  const user = useUser()
  const isStaff = isStaffUser(user)

  const [stage, setStage] = useState(LOAD_STAGE)
  const [uuid, setUuid] = useState(null)
  const [chunk, setChunk] = useState(null)
  const [inputData, setInputData] = useState(null)
  const [serverError, setServerError] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [isUploading, setUploading] = useState(false)
  const [isCancel, setCancel] = useState(false)
  const [initError, setInitError] = useState(null)
  const [initValues, setInitValues] = useState({})

  useOverflow()

  useEffect(() => {
    if (init?.building) {
      setLoading(true)
      getBuilding(init.building).then(building => {
        setInitValues({ building })
        setLoading(false)
      })
    }

    if (init?.user) {
      setLoading(true)
      getProfile(init.user, { outbound })
        .then(user => {
          setInitValues({ user })
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
        })
    }

    if (init?.flat) {
      setLoading(true)
      getFlat({ id: init.flat }).then(flat => {
        setInitValues({ flat })
        setLoading(false)
      })
    }
  }, [init])

  useEffect(() => {
    if (initValues.flat && !initValues.building) {
      getBuilding(initValues.flat.building).then(building => {
        setInitValues(prev => {
          return { ...prev, building: building }
        })
      })
    }
  }, [initValues.flat])

  const getPrevChunk = async prevUuid => {
    try {
      const chunk = await getChunk(prevUuid, {
        is_chatroom_public: isChatroomPublic,
        outbound,
      })
      const lastChunk = last(chunk)

      if (
        lastChunk &&
        lastChunk.type !== VARIABLE_TYPE &&
        lastChunk.type !== LINK_TYPE
      ) {
        getNewUuid()
        setStage(INIT_STAGE)
      } else {
        setChunk(chunk)
        setUuid(prevUuid)
        setStage(RESTORE_STAGE)
      }
    } catch (error) {
      const status = get(error, ['message', 'response', 'status'])

      if (status >= 400 && status < 500) {
        getNewUuid()
        setStage(INIT_STAGE)
      }
    }
  }

  const setProlongInterval = async () => {
    if (!uuid) {
      return
    }

    if (prolongInterval.current) {
      clearInterval(prolongInterval.current)
    }

    prolongInterval.current = setInterval(
      () => prolong(uuid, outbound),
      PROLONG_TIMEOUT
    )
  }

  const getNewUuid = () => {
    const params = init?.relatedRequest
      ? { original_request_id: init?.relatedRequest }
      : { outbound }

    const api = id ? partial(dryRun, id, params) : partial(initWizard, params)

    api()
      .then(data => {
        const { uuid } = data

        setUuid(uuid)
      })
      .catch(error => {
        setInitError(
          get(error, ['message', 'response', 'data', 'errors'], null)
        )

        setLoading(false)
      })
  }

  const loadChunk = () => {
    if (uuid) {
      sendPayloadChunk(
        uuid,
        { ...inputData, is_chatroom_public: isChatroomPublic },
        outbound
      )
        .then(setChunk)
        .catch(error => {
          setServerError(
            get(error, ['message', 'response', 'data', 'errors'], null)
          )
          dispatch(
            globalModalError('RequestCreatePermissionsError', undefined, true)
          )
        })
        .finally(() => setLoading(false))
    } else {
      getNewUuid()
    }
  }

  useEffect(() => {
    if (uuid) {
      saveWizardUuid(uuid, outbound)
      setProlongInterval()
    }

    if (inputData) {
      loadChunk()
    }
  }, [uuid])

  useEffect(() => {
    const prevUuid = getWizardPrevUuid(outbound)

    if (prevUuid) {
      getPrevChunk(prevUuid)
    } else {
      setStage(INIT_STAGE)
    }

    return function cleanup() {
      if (location.pathname.includes('requests')) {
        getNewPath(location)

        const { profileId, search } = params
        const filters = getCurrentFilters(location)
        const extendedFilters = getExtendedFilters({ params })
        const ownerFilter = getOwnerFilter(profileId, profileGroup)

        dispatch({
          type: REQUEST_LIST_LOAD,
          params: {
            search,
            ...filters,
            ...extendedFilters,
            ...ownerFilter,
          },
          isStaff,
        })
      }

      clearWizardUuid(outbound)

      if (prolongInterval.current) {
        clearInterval(prolongInterval.current)
      }

      props.onReload && props.onReload()
    }
  }, [])

  useEffect(() => {
    if (inputData) {
      setLoading(true)
      loadChunk()
    }
  }, [inputData])

  useEffect(() => {
    if (chunk && stage !== CARD_STAGE) {
      setStage(CARD_STAGE)
    }
  }, [chunk])

  const handleReject = () => setCancel(false)

  const handleCancel = () => setCancel(true)

  const handleInit = () => {
    setUuid(null)
    setChunk(null)
    setStage(INIT_STAGE)
    clearWizardUuid(outbound)

    setStage(INIT_STAGE)
  }

  const handleRestore = () => setStage(CARD_STAGE)

  const modalClass = classNames(styles.modal, {
    'working-overlay': isLoading || isUploading,
  })

  return (
    <Modal
      isOpen
      className={modalClass}
      onRequestClose={initError ? props.onClose : handleCancel}
    >
      {!initError && !isCancel && stage !== RESTORE_STAGE && (
        <ModalHead title={t('RequestCreate')} />
      )}
      {stage !== LOAD_STAGE && (
        <ModalCloseButton
          disabled={isLoading || isUploading}
          onClose={
            isCancel ? handleReject : initError ? props.onClose : handleCancel
          }
        />
      )}
      <NewWizardContext.Provider
        value={{
          onSubmit: setInputData,
          serverError,
          setUploading,
        }}
      >
        {stage === LOAD_STAGE && <Loader text={false} type='medium' />}
        {isCancel && (
          <CancelModal onReject={handleReject} onClose={props.onClose} />
        )}
        {initError && <ErrorModal error={initError} onClose={props.onClose} />}
        {stage === RESTORE_STAGE && !initError && !isCancel && (
          <RestoreModal onInit={handleInit} onRestore={handleRestore} />
        )}
        {stage === INIT_STAGE && (
          <InitStage
            inbound_user={outbound ? init?.user : null}
            initObj={initValues}
            isCancel={isCancel || initError || stage === RESTORE_STAGE}
            messageUuid={messageUuid}
            chatMessageId={chatMessageId}
            outbound={outbound}
            relatedRequest={init?.relatedRequest}
            onCancel={handleCancel}
          />
        )}
        {stage === CARD_STAGE && chunk && (
          <CardStage
            chunk={chunk}
            isCancel={isCancel || stage === RESTORE_STAGE}
            onCancel={handleCancel}
            onClose={props.onClose}
          />
        )}
      </NewWizardContext.Provider>
    </Modal>
  )
}

export default NewWizard
