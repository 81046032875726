// @flow

import React from 'react'
import type { Node } from 'react'
import { DropTarget } from 'react-dnd'

import Item, { ItemTypes } from './DraggableItem'

type Props = {
  connectDropTarget: (Node) => Node,
  items: Array<string>,
  name: string,
  onAdd: (string) => void
}

const dropTarget = {
  canDrop (props: Props, monitor) {
    const { category } = monitor.getItem()
    return !category || category !== props.name
  },
  drop (props: Props, monitor) {
    const { name } = monitor.getItem()
    props.onAdd(name)
  }
}

const Category = (props: Props) => {
  const {
    items,
    name,
    connectDropTarget
  } = props

  return connectDropTarget(
    <div className='modal__mapping-categories--table-col-2-tag'>
      <div className='modal__mapping-categories--table-col-2-tag-row1 modal__text'>
        {name}
      </div>
      <div className='modal__mapping-categories--table-col-2-tag-row2 modal__text'>
        {items.map((item) => (
          <Item
            category={name}
            dndType={ItemTypes.category}
            key={item}
            name={item}
          />
        ))}
      </div>
    </div>
  )
}

export default DropTarget(
  [ItemTypes.unmatched, ItemTypes.category],
  dropTarget,
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    canDrop: monitor.canDrop()
  })
)(Category)
