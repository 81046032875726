// @flow

import { FORMIK_EMPTY_VALUE } from '../../../../constants'

export const SALUTATION = 'salutation'
export const OBJECT_NUMBER = 'object_number'
export const STREET = 'street'
export const DOOR_NUMBER = 'door_number'
export const POSTAL_CODE = 'postal_code'
export const FULL_ADDRESS = 'full_address'
export const CONSTRUCTION_YEAR = 'construction_year'
export const ADDRESS_INVOICES = 'address_invoice'
export const AMOUNT_RESTRICTION = 'amount_restriction'
export const LOCATION = 'location'
export const TYPE_OF_LIVING = 'living_type'
export const HOUSE_KEEPER = 'house_keeper'
export const CONTRACT_START = 'contract_start'
export const CONTRACT_END = 'contract_end'

export const MAX_OBJECT_NUMBER_LENGTH = 10
export const MAX_POSTAL_CODE_LENGTH = 10
export const MAX_LOCATION_LENGTH = 256

export const KEYS = [
  SALUTATION,
  OBJECT_NUMBER,
  POSTAL_CODE,
  STREET,
  DOOR_NUMBER,
  FULL_ADDRESS,
  CONSTRUCTION_YEAR,
  ADDRESS_INVOICES,
  AMOUNT_RESTRICTION,
  HOUSE_KEEPER,
  LOCATION,
  TYPE_OF_LIVING,
  CONTRACT_START,
  CONTRACT_END,
]

export const DEFAULT_VALUES: Object = {
  [SALUTATION]: null,
  [OBJECT_NUMBER]: FORMIK_EMPTY_VALUE,
  [POSTAL_CODE]: FORMIK_EMPTY_VALUE,
  [STREET]: FORMIK_EMPTY_VALUE,
  [DOOR_NUMBER]: FORMIK_EMPTY_VALUE,
  [FULL_ADDRESS]: FORMIK_EMPTY_VALUE,
  [CONSTRUCTION_YEAR]: FORMIK_EMPTY_VALUE,
  [ADDRESS_INVOICES]: FORMIK_EMPTY_VALUE,
  [AMOUNT_RESTRICTION]: FORMIK_EMPTY_VALUE,
  [HOUSE_KEEPER]: FORMIK_EMPTY_VALUE,
  [LOCATION]: FORMIK_EMPTY_VALUE,
  [TYPE_OF_LIVING]: null,
  [CONTRACT_START]: FORMIK_EMPTY_VALUE,
  [CONTRACT_END]: FORMIK_EMPTY_VALUE,
}
