// @flow

import { call, put, takeEvery, fork, all, select } from 'redux-saga/effects'

import * as actions from './Faq.actions'
import api from '../../core/api'
import * as selectors from './Faq.selectors'

function* watchLoadGroups() {
  yield takeEvery(actions.FAQ_LOAD_GROUPS, loadGroups)
}

function* watchRemoveGroups() {
  yield takeEvery(actions.FAQ_REMOVE_GROUPS, removeGroups)
}

function* watchCreateGroup() {
  yield takeEvery(actions.FAQ_CREATE_GROUP, createGroup)
}

function* watchSaveGroupTitle() {
  yield takeEvery(actions.FAQ_SAVE_GROUP_NAME, saveGroupTitle)
}

function* watchSaveQuestion() {
  yield takeEvery(actions.FAQ_SAVE_QUESTION, saveQuestion)
}

function* watchCreateQuestion() {
  yield takeEvery(actions.FAQ_CREATE_QUESTION, createQuestion)
}

function* watchSaveAnswer() {
  yield takeEvery(actions.FAQ_SAVE_ANSWER, saveAnswer)
}

function* watchRemoveQuestion() {
  yield takeEvery(actions.FAQ_REMOVE_QUESTION, removeQuestion)
}

function* watchChangeQuestionPosition() {
  yield takeEvery(actions.FAQ_QUESTION_CHANGE_POSITION, changeQuestionPosition)
}

function* loadGroups() {
  try {
    const { objects, permissions } = yield call(api.faq.getGroups)
    yield put(actions.groupsWasLoaded(objects.groups, permissions))
  } catch (e) {
    console.log(e)
  }
}

function* removeGroups() {
  try {
    const ids = yield select(selectors.getSelected)
    const isMassAction = ids.length > 1

    if (isMassAction) {
      yield call(api.massAction.remove, {
        model: 'FaqGroup',
        model_pks: JSON.stringify(ids),
      })
    } else {
      yield call(api.faq.removeGroup, ids[0])
    }

    yield put(actions.groupsWasRemoved())
    yield* loadGroups()
    yield put(actions.closeModal())
  } catch (e) {
    console.log(e)
  }
}

function* createGroup({ name }) {
  try {
    yield call(api.faq.createGroup, { title: name, created_by: 4 })
    yield* loadGroups()
    yield put(actions.closeModal())
  } catch (e) {
    console.log(e)
  }
}

function* saveGroupTitle({ name }) {
  try {
    const id = yield select(selectors.getEditingGroup)
    yield call(api.faq.renameGroupTitle, { id, title: name })
    yield* loadGroups()
    yield put(actions.groupNameWasSaved())
  } catch (e) {
    console.log(e)
  }
}

function* saveQuestion({ title, group, position }) {
  try {
    const id = yield select(selectors.getEditingQuestion)
    yield call(api.faq.editQuestion, { id, title, group, position })
    yield* loadGroups()
    yield put(actions.questionWasSaved())
  } catch (e) {
    console.log(e)
  }
}

function* createQuestion({ title, group }) {
  try {
    yield call(api.faq.addQuestion, { title, group })
    yield* loadGroups()
    yield put(actions.questionWasCreated())
  } catch (e) {
    console.log(e)
  }
}

function* saveAnswer({ answer, group, position }) {
  try {
    const id = yield select(selectors.getEditingAnswer)
    yield call(api.faq.editQuestion, { id, answer, group, position })
    yield* loadGroups()
    yield put(actions.answerWasSaved())
  } catch (e) {
    console.log(e)
  }
}

function* removeQuestion() {
  try {
    const id = yield select(selectors.getEditingQuestion)
    const params = yield select(selectors.getModalParams)
    yield call(api.faq.removeQuestion, { id, ...params })
    yield* loadGroups()
    yield put(actions.questionWasRemoved())
    yield put(actions.closeModal())
  } catch (e) {
    console.log(e)
  }
}

function* changeQuestionPosition({ params: { position, group, id } }) {
  try {
    yield call(api.faq.editQuestion, { position, group, id })
    yield put(actions.questionPositionWasChanged())
    yield* loadGroups()
  } catch (e) {
    console.log(e)
  }
}

export default function* () {
  yield all([
    fork(watchRemoveGroups),
    fork(watchCreateGroup),
    fork(watchLoadGroups),
    fork(watchSaveGroupTitle),
    fork(watchSaveQuestion),
    fork(watchSaveAnswer),
    fork(watchCreateQuestion),
    fork(watchRemoveQuestion),
    fork(watchChangeQuestionPosition),
  ])
}
