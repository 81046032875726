// @flow

import { all, put, call, fork, takeEvery } from 'redux-saga/effects'
import { replace } from 'connected-react-router'

import api from '../../../core/api'
import { serverError } from '../../Layout/Layout.actions'
import { SERVER_404_ERROR } from '../../Layout/Layout.constants'
import * as actions from './ResidentList.actionTypes'

const initParams = {
  get_relations: true,
  without_myself: 1,
  group: 'dweller',
  status: 'active',
}

function* watchInit() {
  yield takeEvery(actions.RESIDENT_LIST_INITIATING, init)
}

function* watchLoad() {
  yield takeEvery(actions.RESIDENT_LIST_LOAD, load)
}

function* watchSubscribe() {
  yield takeEvery(actions.RESIDENT_LIST_SUBSCRIBE, subscribe)
}

function* watchUnsubscribe() {
  yield takeEvery(actions.RESIDENT_LIST_UNSUBSCRIBE, unsubscribe)
}

function* init({ params }) {
  try {
    const result = yield call(api.profile.getListTiny, {
      ...initParams,
      ...params,
    })

    yield put({ type: actions.RESIDENT_LIST_INITIATED, ...result })
  } catch (error) {
    const action = serverError(error)

    if (action.type === SERVER_404_ERROR) {
      yield put(replace('/404'))
    } else {
      yield put(action)
      yield put({ type: actions.RESIDENT_LIST_ERROR, error })
    }
  }
}

function* load({ params }) {
  try {
    const result = yield call(api.profile.getListTiny, {
      ...initParams,
      ...params,
    })

    yield put({ type: actions.RESIDENT_LIST_LOADED, result })
  } catch (error) {
    const action = serverError(error)

    if (action.type === SERVER_404_ERROR) {
      yield put(replace('/404'))
    } else {
      yield put(action)
      yield put({ type: actions.RESIDENT_LIST_ERROR, error })
    }
  }
}

function* subscribe({ owner }) {
  try {
    const own = yield call(api.friends.subscribe, owner)
    yield put({ type: actions.RESIDENT_LIST_SUBSCRIBED, ...own })
  } catch (error) {
    const { response } = error.message

    if (
      response.status === 400 &&
      response.data.errors === 'You_are_already_friends'
    ) {
      yield put({ type: actions.RESIDENT_LIST_ALREADY_FRIEND, owner })

      return
    }

    yield put(serverError(error))
    yield put({ type: actions.RESIDENT_LIST_ERROR, error })
  }
}

function* unsubscribe({ owner }) {
  try {
    yield call(api.friends.unsubscribe, owner)

    yield put({ type: actions.RESIDENT_LIST_UNSUBSCRIBED, owner })
  } catch (error) {
    yield put(serverError(error))
    yield put({ type: actions.RESIDENT_LIST_ERROR, error })
  }
}

export default function* watch() {
  yield all([
    fork(watchInit),
    fork(watchLoad),
    fork(watchSubscribe),
    fork(watchUnsubscribe),
  ])
}
