//@flow

import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import type Node from 'react'

import Modal from '../../../Modal'
import ModalBody from '../../../Modal/ModalBody'
import ModalCloseButton from '../../../Modal/ModalCloseButton'
import ModalHead from '../../../Modal/ModalHead'
import ConfirmationPopup from '../../../modals/ConfirmationPopup'
import WysiwygEditor from '../../../WysiwygEditor'
import RequestFileList from '../../../Emails/RequestFileList'
import Button from '../../../Button'
import Loader from '../../../Loader'
import { useOverflow } from '../../../../hooks/useOverflow'
import { useThemeColor } from '../../../../hooks'
import { renderEmailPreview } from '../../../../core/api/api.emailTemplate'
import { googleTemplatePreview } from '../../../../core/api/api.docTemplates'

import classnames from 'classnames'
import styles from './PreviewModal.module.scss'
import Lightbox from '../../../LightboxGallery'

type Props = {
  authorId: number,
  bcc: Array<Object>,
  building_id?: number,
  cc: Array<Object>,
  externalVars?: Object,
  files: Array<Object>,
  filesAsLinks: boolean,
  flat_id?: number,
  from: string,
  isOpen: boolean,
  onClose: () => void,
  onDeleteEmail: number => void,
  onSend: () => void,
  recipients: Array<Object>,
  requestId?: number,
  subject: string,
  templateId?: number,
  text: string,
}

const PreviewModal = (props: Props): Node => {
  const {
    isOpen,
    templateId,
    onClose,
    onDeleteEmail,
    onSend,
    from,
    recipients,
    cc,
    bcc,
    requestId,
    subject,
    text,
    files,
    filesAsLinks,
    authorId: author_id,
    externalVars: external_variables,
    building_id,
    flat_id,
  } = props

  const { t } = useTranslation('Mail')

  const [hidden, setHidden] = useState(false)
  const [current, setCurrent] = useState(1)
  const [message, setMessage] = useState('')
  const [confirm, setConfirm] = useState(null)
  const [loading, setLoading] = useState(false)
  const [docId, setDocId] = useState(null)
  const [activeIndex, setActiveIndex] = useState(null)

  useOverflow()

  const themeColor = useThemeColor()

  useEffect(() => {
    setMessage(text)

    if (templateId) {
      setLoading(true)
      const recipient_id = recipients[current - 1]?.profile?.id || undefined
      let dependencies = []

      if (recipient_id) {
        dependencies.push('recipient_id')
      }

      if (building_id) {
        dependencies.push('building_id')
      }

      if (flat_id) {
        dependencies.push('flat_id')
      }

      if (requestId) {
        dependencies.push('request_id')
      }

      renderEmailPreview(templateId, {
        text,
        dependencies,
        request_id: requestId || undefined,
        recipient_id,
        building_id,
        flat_id,
      }).then(data => {
        let res = data.text

        if (files.length > 0 && filesAsLinks) {
          res += `<br/><br/>${files
            .map(file => `<a href="${file.origin}">${file.name}</a>`)
            .join('<br/>')}`
        }

        setMessage(res)
        setLoading(false)
      })
    }

    const docFile = files.find(f => !!f.doc)

    if (docFile) {
      const recipient =
        recipients[current - 1]?.profile?.id ||
        recipients[current - 1]?.name ||
        undefined

      setLoading(true)
      googleTemplatePreview({
        dependencies: {
          author_id,
          request_id: requestId || undefined,
          building_id,
          flat_id,
        },
        recipient,
        template: docFile.id,
        external_variables,
      })
        .then(data => {
          if (data.file_id) {
            setDocId(data.file_id)
          }

          setLoading(false)
        })
        .catch(() => setLoading(false))
    }
  }, [current])

  const handleDeleteEmail = () => {
    onDeleteEmail(current - 1)

    if (recipients.length > 0 && current > recipients.length) {
      prevRecipient()
    }

    setConfirm(
      <ConfirmationPopup
        title={t('DeletePreviewSuccess')}
        text={false}
        confirm={t('ContinueButton')}
        showCancel={false}
        onOk={cancelDeleteConfirm}
        onClose={cancelDeleteConfirm}
      />
    )
  }

  const handleSend = () => {
    onSend()
    onClose()
  }

  const prevRecipient = () => {
    setCurrent(current - 1)
  }

  const nextRecipient = () => {
    setCurrent(current + 1)
  }

  const showDeleteConfirm = () => {
    setHidden(true)
    setConfirm(
      <ConfirmationPopup
        title={t('ConfirmPreviewDelete')}
        text={false}
        confirm={t('YesButton')}
        cancel={t('NoButton')}
        isAsync={false}
        onOk={handleDeleteEmail}
        onClose={cancelDeleteConfirm}
      />
    )
  }

  const cancelDeleteConfirm = () => {
    setConfirm(null)
    setHidden(false)

    if (recipients.length === 0) {
      onClose()
    }
  }

  const handleOpenPreview = fileId => {
    const file = files.findIndex(f => f.id === fileId)
    setActiveIndex(file)
  }

  const closeLightbox = () => {
    setActiveIndex(null)
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        style={{
          content: {
            width: '900px',
            visibility: hidden ? 'hidden' : 'visible',
          },
        }}
        onRequestClose={onClose}
      >
        <ModalHead title={t('MessagePreview')} />
        <ModalCloseButton onClose={onClose} />
        <ModalBody
          className={styles.preview}
          style={{
            maxHeight: '615px',
            overflowY: 'auto',
            '--themeColor': themeColor,
          }}
        >
          <div className={styles.nav}>
            {`${current}/${recipients.length} ${t('Emails')}`}
            {current > 1 && (
              <button
                type='button'
                className={classnames(styles.arrow, styles.prev)}
                onClick={prevRecipient}
              />
            )}
            {current < recipients.length && (
              <button
                type='button'
                className={classnames(styles.arrow, styles.next)}
                onClick={nextRecipient}
              />
            )}
          </div>
          <div className='reply-headers'>
            <div
              className={classnames('reply-headers__input', styles.headerFrom)}
            >
              <label>{t('MessageFrom')}</label>
              <div className={styles.inputFrom}>
                <span>{from}</span>
              </div>
              <span className={styles.deleteLabel} onClick={showDeleteConfirm}>
                {t('DeleteCurrent')}
              </span>
            </div>
            <div className='reply-headers__input'>
              <label>{t('MessageTo')} </label>
              <div className={styles.tags}>
                <div
                  style={{ paddingRight: '8px' }}
                  className={classnames('react-tagsinput-tag', styles.tag, {
                    blacklist: recipients[current - 1]?.blacklist,
                  })}
                >
                  {recipients[current - 1]?.name || ''}
                </div>
              </div>
            </div>
            {cc.length > 0 && (
              <div className='reply-headers__input'>
                <label>{t('CC')} </label>
                <div className={styles.tags}>
                  {cc.map((item, i) => (
                    <div
                      key={i}
                      style={{ paddingRight: '8px' }}
                      className={classnames('react-tagsinput-tag', styles.tag, {
                        blacklist: item.blacklist,
                      })}
                    >
                      {item.name || ''}
                    </div>
                  ))}
                </div>
              </div>
            )}
            {bcc.length > 0 && (
              <div className='reply-headers__input'>
                <label>{t('BCC')} </label>
                <div className={styles.tags}>
                  {bcc.map((item, i) => (
                    <div
                      key={i}
                      style={{ paddingRight: '8px' }}
                      className={classnames('react-tagsinput-tag', styles.tag, {
                        blacklist: item.blacklist,
                      })}
                    >
                      {item.name || ''}
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className='reply-headers__input reply-headers__input--subject'>
              <label>{t('Subject')}</label>
              <div className={styles.inputSubject}>{subject}</div>
            </div>
            {loading ? (
              <Loader type='medium' text={false} className={styles.loader} />
            ) : (
              <WysiwygEditor
                autoResizeOnInit
                disabled
                showToolbar={false}
                statusbar={false}
                value={message}
              />
            )}
          </div>
          {files.length > 0 && !filesAsLinks && (
            <RequestFileList
              preview
              filesAsLinks={false}
              headerStyle={{ marginTop: '16px' }}
              showAddButton={false}
              files={files}
              onFilePreview={handleOpenPreview}
              onDocPreview={handleOpenPreview}
            />
          )}
          <div className='messages__tools-submit'>
            <Button.Regular
              className='messages__tools-send button--success'
              disabled={!subject.length || recipients.some(r => r.blacklist)}
              onClick={handleSend}
            >
              {t('SendMass', { count: recipients.length })}
            </Button.Regular>
          </div>
        </ModalBody>
        <Lightbox
          newGallery
          activeIndex={activeIndex}
          images={[...files.map(f => (f.doc ? { ...f, docId } : f))]}
          scope='tree'
          object={requestId}
          onClose={closeLightbox}
        />
      </Modal>
      <Modal
        contentLabel=''
        className='Modal__Bootstrap modal-dialog'
        isOpen={!!confirm}
        onRequestClose={cancelDeleteConfirm}
      >
        {confirm}
      </Modal>
    </>
  )
}

export default PreviewModal
