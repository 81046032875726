/* eslint-disable class-methods-use-this */
// @flow

import React, { Component } from 'react'
import { isEqual } from 'lodash-es'

import GoogleMap from './GoogleMap'

const DEFAULT_ZOOM = 17

export const MAP_WITHOUT_CONTROLS = {
  disableDefaultUI: true,
  streetViewControl: false,
  scaleControl: false,
  mapTypeControl: false,
  panControl: false,
  zoomControl: false,
  rotateControl: false,
  fullscreenControl: false,
  draggable: false,
  draggableCursor: 'pointer',
}

type Props = {
  editMode: boolean,
  height: string,
  zoom: number,
}
type State = {
  buildingMarker: any,
  editBuildingMarker: any,
}

class Map extends Component<Props, State> {
  state = {
    buildingMarker: null,
    editBuildingMarker: null,
  }

  componentDidMount() {
    this.init(this.props)
  }

  componentDidUpdate(prevProps) {
    if (isEqual(prevProps.buildingMarker, this.props.buildingMarker)) {
      return
    }

    const { buildingMarker, editMode } = this.props

    let { editBuildingMarker } = this.state

    if (buildingMarker && editBuildingMarker) {
      editBuildingMarker = {
        ...editBuildingMarker,
        position: { ...buildingMarker.position },
      }
    }

    if (editMode && !editBuildingMarker && buildingMarker) {
      editBuildingMarker = this.createEditMaker(buildingMarker.position)
    }

    this.setState({ buildingMarker, editBuildingMarker })
  }

  onMapClick = position => {
    if (!this.props.editMode) {
      return
    }

    let { editBuildingMarker } = this.state

    if (editBuildingMarker) {
      editBuildingMarker.position = position
    } else {
      editBuildingMarker = this.createEditMaker(position)
    }

    this.setState({
      editBuildingMarker,
    })
  }

  init(props) {
    const { buildingMarker, editMode } = props
    let { editBuildingMarker } = this.state

    if (buildingMarker && editBuildingMarker) {
      editBuildingMarker.position = buildingMarker.position
    }

    if (editMode && !editBuildingMarker && buildingMarker) {
      editBuildingMarker = this.createEditMaker(buildingMarker.position)
    }

    this.setState({ buildingMarker, editBuildingMarker })
  }

  createEditMaker(position) {
    return {
      name: 'editBuildingMarker',
      draggable: true,
      position,
    }
  }

  render() {
    const { zoom, editMode, options } = this.props
    const { buildingMarker, editBuildingMarker } = this.state
    const marker = editMode ? editBuildingMarker : buildingMarker
    const props = {
      buildingMarker: marker,
      onMapClick: this.props.onClick || this.onMapClick,
      zoom: zoom || DEFAULT_ZOOM,
      options,
    }
    const height = this.props.height || '38.3rem'

    return (
      <div className='map u-a10__map' style={{ height }}>
        <GoogleMap {...this.props} {...props} />
      </div>
    )
  }
}

Map.defaultProps = {
  options: {},
}

export default Map
