/**
 * @flow
 * @relayHash a4684b3c584830fc249cfea0607c78aa
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CustomerOrderStatusMutationInput = {|
  ids: $ReadOnlyArray<?string>,
  status: string,
  clientMutationId?: ?string,
|};
export type OrderProfileStatusMutationVariables = {|
  input: CustomerOrderStatusMutationInput
|};
export type OrderProfileStatusMutationResponse = {|
  +updateCustomerOrderStatus: ?{|
    +ids: $ReadOnlyArray<?string>,
    +status: string,
  |}
|};
export type OrderProfileStatusMutation = {|
  variables: OrderProfileStatusMutationVariables,
  response: OrderProfileStatusMutationResponse,
|};
*/


/*
mutation OrderProfileStatusMutation(
  $input: CustomerOrderStatusMutationInput!
) {
  updateCustomerOrderStatus(input: $input) {
    ids
    status
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CustomerOrderStatusMutationInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateCustomerOrderStatus",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CustomerOrderStatusMutationPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "ids",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "status",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "OrderProfileStatusMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "OrderProfileStatusMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "OrderProfileStatusMutation",
    "id": null,
    "text": "mutation OrderProfileStatusMutation(\n  $input: CustomerOrderStatusMutationInput!\n) {\n  updateCustomerOrderStatus(input: $input) {\n    ids\n    status\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ee75542760327b926f7b4a340b3f2287';
module.exports = node;
