// @flow

import React from 'react'
import type { Node } from 'react'

import { useThemeColor } from '../../../../../../hooks'
import InlineSvg from '../../../../../InlineSvg'
import Label from '../../../../../Label'
import { hexToRGB } from '../../../../../../utils/utils'

import styles from './Type.module.scss'

type Props = {
  icon: Object,
  name: string,
  priority: ?Object,
  title: string,
}

const Type = (props: Props): Node => {
  const { icon, name, title, priority } = props

  const themeColor = useThemeColor()

  const rgbTheme = hexToRGB(themeColor)

  return (
    <div className={styles.type} style={{ '--rgbThemeColor': rgbTheme }}>
      <InlineSvg url={icon.origin} alt={name} />
      <span onClick={props.onChange}>{title}</span>
      {priority && (
        <Label text={priority.value} className={styles.label} color='theme' />
      )}
    </div>
  )
}

export default Type
