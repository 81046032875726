// @flow

export const INIT_STAGE = 'init'
export const CARD_STAGE = 'card'
export const LOAD_STAGE = 'load'
export const RESTORE_STAGE = 'restore'

export const UUID_KEY = 'wizardUUID'
export const OUTBOUND_UUID_KEY = 'wizardOutboundUUID'

export const PROLONG_TIMEOUT = 1000 * 60 * 3
