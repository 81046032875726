// @flow

import React from 'react'
import type { Node } from 'react'

import { getRequestPipeline } from '../../../../core/api/api.pipeline'
import { useSingleLoad } from '../../../../hooks/useSingleLoad/useSingleLoad'
import Phase from './Phase'

import styles from './RequestPipeline.module.scss'

type Props = { requestId: number }

const RequestPipeline = (props: Props): Node => {
  const { requestId } = props

  const [data] = useSingleLoad(getRequestPipeline, requestId)

  if (!data || !data.length) {
    return null
  }

  const phaseWidth = 100 / data.length

  return (
    <div className={styles.pipeline}>
      {data.map(({ id, name, progress }) => (
        <Phase
          name={name}
          key={id}
          phaseWidth={phaseWidth}
          progress={progress}
        />
      ))}
    </div>
  )
}

export default RequestPipeline
