// @flow

import React, { useState } from 'react'
import { omit, map } from 'lodash-es'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import { createEmailMessage } from '../../core/api/api.request'
import NewMailReplyForm from '../Mail/NewMailReplyForm'
import Modal from '../Modal'
import ModalBody from '../Modal/ModalBody'
import ModalCloseButton from '../Modal/ModalCloseButton'
import ModalHead from '../Modal/ModalHead'
import { useOverflow } from '../../hooks/useOverflow'
import { useThemeColor, useUser } from '../../hooks'
import { getUserId } from '../../utils/utils'
import { modalError } from '../Layout/Layout.actions'

import styles from './EmailsCreatingModal.module.scss'

type Props = {
  audience?: string,
  buildingId?: number,
  directoryId: number,
  isOpen: boolean,
  onClose: (data?: Object) => void,
  reloadThreads?: true => void,
  requestId: number,
}

const EmailsCreatingModal = (props: Props): Node => {
  const { isOpen, requestId, audience, directoryId, buildingId, flatId } = props

  const user = useUser()

  const [working, setWorking] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState(props.selectedFiles || [])
  const [isMass, setIsMass] = useState(false)
  const [showCanLeave, setShowCanLeave] = useState(false)
  const [changedValues, setChangedValues] = useState(false)

  const dispatch = useDispatch()

  const { t } = useTranslation('Request')

  useOverflow()

  const themeColor = useThemeColor()

  const handleMass = mass => {
    setIsMass(mass)
  }

  const handleSave = params => {
    setWorking(true)

    createEmailMessage(requestId, {
      ...omit(params, ['title', 'to', 'cc', 'bcc', 'files', 'text']),
      subject: params.title,
      owner: getUserId(user),
      audience,
      to: params.to,
      text: params.text.replace(/\n/g, ''),
      cc: params.cc,
      bcc: params.bcc,
      files: params.files.concat(map(selectedFiles, 'id')),
    })
      .then(data => {
        props.onClose(data)
        props.reloadThreads && props.reloadThreads(true)
      })
      .catch(error => {
        if (error.message.response.status === 400) {
          dispatch(modalError(error.message.response.data))
        }

        setWorking(false)
      })
  }

  const removeExternalFile = id =>
    setSelectedFiles(
      selectedFiles.filter(selectedFile => selectedFile.id !== id)
    )

  const handleSelectFiles = files => {
    const selectedIds = map(selectedFiles, 'id')

    const newFiles = files.filter(file => !selectedIds.includes(file.id))

    setSelectedFiles(selectedFiles.concat(newFiles))
  }

  const onClose = () => {
    if (changedValues) {
      setShowCanLeave(true)
    } else {
      setShowCanLeave(false)
      props.onClose()
    }
  }

  return (
    <Modal isOpen={isOpen} className={styles.modal} onRequestClose={onClose}>
      <ModalHead title={t('CreateMessageTitle')} className={styles.modalHead} />
      <ModalCloseButton onClose={onClose} />
      <ModalBody
        style={{
          maxHeight: '600px',
          overflowY: 'auto',
          '--themeColor': themeColor,
        }}
      >
        <NewMailReplyForm
          isModal
          isSystemIncluding
          withProviders
          showAddButton
          isRequestThread
          setSelectedFiles={handleSelectFiles}
          directoryId={directoryId}
          filesHeaderTitle={t('AttachedFiles')}
          requestId={requestId}
          buildingId={buildingId}
          requestFlatId={flatId}
          uuid='create'
          removeExternalFile={removeExternalFile}
          working={working}
          externalFiles={selectedFiles.map(file => ({
            ...file,
            isExternalFile: true,
          }))}
          changedValues={changedValues}
          isMass={isMass}
          showCanLeave={showCanLeave}
          setShowCanLeave={setShowCanLeave}
          setChangedValues={setChangedValues}
          onClose={props.onClose}
          onSave={handleSave}
          onToggleMass={handleMass}
        />
      </ModalBody>
    </Modal>
  )
}

export default EmailsCreatingModal
