// @flow

import React from 'react'
import classnames from 'classnames'
import type { Node } from 'react'

import styles from './ModalButtons.module.scss'

type Props = {
  children: Node,
  className?: string,
  style?: Object,
}

const ModalButtons = (props: Props): Node => {
  const { children, style, className } = props

  const buttonsClass = classnames(className, styles.buttons)

  return (
    <div className={buttonsClass} style={style}>
      {children}
    </div>
  )
}

export default ModalButtons
