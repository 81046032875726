// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { get, mapKeys, omitBy, partial } from 'lodash-es'
import type { Node } from 'react'

import { SimpleBlock, Header } from '../Block'
import NewSelectSimple from '../NewSelectSimple'
import Settings from '../Settings'
import { isDwellerUser } from '../../utils/utils'

type Props = {
  onSave: Object => void,
  profileConfig: Object,
  user: Object,
  working: boolean,
}

const KEYS_DICT = {
  allowAddToChat: 'allow_add_to_chat',
  contactsVisibility: 'contacts_visibility',
  hideAddress: 'hide_address',
}

const UserVisibility = (props: Props): Node => {
  const { working, profileConfig, user } = props

  const { t } = useTranslation('Settings')

  const allowAddToChat = get(profileConfig, 'allow_add_to_chat')
  const contactsVisibility = get(profileConfig, 'contacts_visibility')
  const hideAddress = get(profileConfig, 'hide_address')

  const hideAddressOptions = [
    { value: true, label: t('HideAddressOption') },
    { value: false, label: t('ShowAddressOption') },
  ]

  const allowAddToChatOptions = [
    { value: true, label: t('AllowPrivateMessages') },
    { value: false, label: t('DenyPrivateMessages') },
  ]

  const contactsVisibilityOptions = [
    { value: 'show_to_all', label: t('show_to_all') },
    {
      value: 'show_to_friends_only',
      label: t('show_to_friends_only'),
    },
    { value: 'hide_for_all', label: t('hide_for_all') },
  ]

  const formik = useFormik({
    validateOnChange: true,
    enableReinitialize: true,
    initialValues: {
      allowAddToChat,
      contactsVisibility,
      hideAddress,
    },
    validationSchema: Yup.object({
      allowAddToChat: Yup.boolean().required(),
      contactsVisibility: Yup.string().required(),
      hideAddress: Yup.boolean().required(),
    }),
    onSubmit: submitValues => {
      const compare = (
        initialValues: Object,
        value: any,
        key: string
      ): boolean => initialValues[key] === value

      const partialCompare = partial(compare, formik.initialValues)

      const changedValues = omitBy(submitValues, partialCompare)

      const getKey = (value: any, key: string): string => KEYS_DICT[key]

      const params = mapKeys(changedValues, getKey)

      props.onSave(params)
    },
  })

  const allowAddToChatOption = allowAddToChatOptions.find(
    opt => opt.value === formik.values.allowAddToChat
  )
  const hideAddressOption = hideAddressOptions.find(
    opt => opt.value === formik.values.hideAddress
  )
  const contactsVisibilityOption = contactsVisibilityOptions.find(
    opt => opt.value === formik.values.contactsVisibility
  )

  const handleChange = name => opt => formik.setFieldValue(name, opt.value)

  return (
    <SimpleBlock working={working}>
      <Header header>{t('VisibilitySettingsTitle')}</Header>
      <form onSubmit={formik.handleSubmit}>
        {isDwellerUser(user) && (
          <Settings.Row label={t('AddressVisibility')}>
            <NewSelectSimple
              name='hideAddress'
              value={hideAddressOption}
              options={hideAddressOptions}
              onChange={handleChange('hideAddress')}
            />
          </Settings.Row>
        )}
        <Settings.Row label={t('ContactsVisibility')}>
          <NewSelectSimple
            name='contactsVisibility'
            value={contactsVisibilityOption}
            options={contactsVisibilityOptions}
            onChange={handleChange('contactsVisibility')}
          />
        </Settings.Row>
        <Settings.Row label={t('PrivateMessages')}>
          <NewSelectSimple
            name='allowAddToChat'
            value={allowAddToChatOption}
            options={allowAddToChatOptions}
            onChange={handleChange('allowAddToChat')}
          />
        </Settings.Row>
        <Settings.Actions
          withChanges={formik.dirty}
          isValid={formik.isValid}
          working={working}
          onCancel={formik.handleReset}
        />
      </form>
    </SimpleBlock>
  )
}

export default UserVisibility
