// @flow

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import type { Node } from 'react'

import ConfirmationPopup from '../../../modals/ConfirmationPopup'
import Modal from '../../../Modal'
import Button from '../../../Button'
import { useLoad } from '../../../../hooks'

import {
  deleteActivityTemplate,
  updateActivityTemplate,
  getActivityPriorities,
  getActivityTemplates,
  getActivityTypes,
} from '../../../../core/api/api.pipeline'
import ActivityListItem from './ActivityListItem'
import ActivityModal from '../../../Activities/ActivityModal'
import ConnectAutomation from '../../../modals/ConnectAutomation'
import Warning from '../../../Warning'
import Loader from '../../../Loader'
import { filter, get } from 'lodash-es'

import styles from './ActivityList.module.scss'

const ACTIVITIES_LIMIT = 50

type Props = { activePhaseId: number }

const ActivityList = (props: Props): Node => {
  const { activePhaseId } = props

  const { t } = useTranslation('Pipeline')

  const [isModalOpen, setModalOpen] = useState(false)
  const [isRemovalModalOpen, setRemovalModalOpen] = useState(false)
  const [activity, setActivity] = useState(null)
  const [activityTypes, setActivityTypes] = useState(null)
  const [activityPriorities, setActivityPriorities] = useState(null)
  const [removalId, setRemovalId] = useState(null)
  const [isLocked, setIsLocked] = useState(true)
  const [automationModal, setAutomationModal] = useState(null)
  const [automationRemovalModal, setAutomationRemovalModal] = useState(null)

  useEffect(() => {
    if (activity) {
      setModalOpen(true)
    }
  }, [activity])

  useEffect(() => {
    if (removalId) {
      setRemovalModalOpen(true)
    }
  }, [removalId])

  const [data, isLoading, , reload] = useLoad(
    getActivityTemplates,
    {
      phaseId: activePhaseId,
      page_size: ACTIVITIES_LIMIT,
    },
    isLocked
  )

  useEffect(() => {
    if (activePhaseId) {
      setIsLocked(false)
      reload(true)
    }
  }, [activePhaseId])

  const handleClose = () => setModalOpen(false)
  const handleOpen = () => setModalOpen(true)
  const handleRemove = () =>
    deleteActivityTemplate(removalId).finally(() => {
      hideModal()
      reload(true)
    })

  useEffect(() => {
    if (isModalOpen && !activityTypes && !activityPriorities) {
      let ActivityType = get(activity, 'type.id')
      getActivityTypes().then(data => {
        let enableActivityTypes = filter(
          data.results.objects,
          item => item.enabled || item.id === ActivityType
        )
        setActivityTypes(enableActivityTypes)
      })

      getActivityPriorities().then(data => {
        setActivityPriorities(data.results.objects)
      })
    }
  }, [isModalOpen])

  const hideModal = () => {
    setRemovalModalOpen(false)
    setRemovalId(null)
  }

  const handleConnectAutonation = (id, automations) => {
    setAutomationModal(null)
    updateActivityTemplate(id, {
      automations,
    }).then(() => {
      reload(true)
    })
  }

  const handleDisconnectAutomation = () => {
    setAutomationRemovalModal(null)
    updateActivityTemplate(automationRemovalModal, {
      automation: null,
    }).then(() => {
      reload(true)
    })
  }

  if (!data && !isLoading) {
    return null
  }

  return (
    <>
      <div className={styles['activity-list']}>
        <h2>{t('ActivityListTitle')}</h2>
        <Warning
          noArrow
          className={styles.warning}
          text={t('ActivityLimit', { limit: ACTIVITIES_LIMIT })}
        />
        {isLoading ? (
          <Loader text={false} type='medium' />
        ) : (
          <DndProvider backend={HTML5Backend}>
            <ul>
              {data.results.objects.map(activity => (
                <ActivityListItem
                  activity={activity}
                  key={activity.id}
                  setActivity={setActivity}
                  setAutomation={setAutomationModal}
                  setRemoveAutomation={setAutomationRemovalModal}
                  reload={reload}
                  setRemovalId={setRemovalId}
                  onRemove={handleRemove}
                />
              ))}
            </ul>
          </DndProvider>
        )}
        {data?.results?.objects?.length < ACTIVITIES_LIMIT && (
          <Button.Add
            title={t('AddActivityButton')}
            className={styles.add}
            onClick={handleOpen}
          />
        )}
      </div>
      {isRemovalModalOpen && (
        <Modal isOpen={isRemovalModalOpen}>
          <ConfirmationPopup
            confirm={t('AcvtivityRemoveButton')}
            title={t('AcvtivityRemoveTitle')}
            text={t('ConfirmAcvtivityRemoving')}
            onClose={hideModal}
            onOk={handleRemove}
          />
        </Modal>
      )}
      {automationRemovalModal && (
        <Modal isOpen={!!automationRemovalModal}>
          <ConfirmationPopup
            confirm={t('Common:Ok')}
            title={t('DisconnectAutomationTitle')}
            text={t('DisconnectAutomationText')}
            onClose={() => setAutomationRemovalModal(null)}
            onOk={handleDisconnectAutomation}
          />
        </Modal>
      )}
      {isModalOpen && activityPriorities && activityTypes && (
        <ActivityModal
          isTemplate
          activity={activity}
          isModalOpen={isModalOpen}
          setModalOpen={setModalOpen}
          load={reload}
          activityTypes={activityTypes}
          activityPriorities={activityPriorities}
          setActivityTypes={setActivityTypes}
          setActivityPriorities={setActivityPriorities}
          phaseTemplateId={activePhaseId}
          setActivity={setActivity}
          onClose={handleClose}
        />
      )}
      {automationModal && (
        <ConnectAutomation
          activity={automationModal}
          onClose={() => setAutomationModal(null)}
          onSave={handleConnectAutonation}
        />
      )}
    </>
  )
}

export default ActivityList
