// @flow

import React from 'react'
import type { Node } from 'react'

type args = {
  date: Object,
  timeFormat: string,
}

const Time = ({ date, timeFormat }: args): Node => (
  <div className='public-building-page__header-time'>
    {date.format(timeFormat)}
  </div>
)

export default Time
