// @flow

import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import axios from 'axios'

import WarningStripe from '../WarningStripe'
import Logo from '../Logo'
import Phone from '../Phone'
import { getAddressPart } from './Footer.utils'

import styles from './Footer.module.scss'

type Props = {
  uk: Object,
}

const ADDRESS_FIRST_LINE_LENGTH = 2

const Footer = (props: Props) => {
  const {
    uk: {
      name,
      address_obj: address,
      phone,
      is_demo_data_deleted: hiddenWarning,
    },
  } = props

  const isDevelopment = process.env.NODE_ENV === 'development'

  const [version, setVersion] = useState(isDevelopment ? 'development' : '')

  // TODO Use getTxtApi, but no need to load local
  useEffect(() => {
    if (!isDevelopment) {
      axios({
        baseURL: `//${window.location.host}/`,
        method: 'get',
        url: 'build_version.txt',
      })
        .then(data => setVersion(data.data.split('\n')[0]))
        .catch(error => console.error(error))
    }
  }, [])

  const { t } = useTranslation('Footer')

  const addressFirstLine = getAddressPart(address, 0, ADDRESS_FIRST_LINE_LENGTH)
  const addressSecondLine = getAddressPart(address, ADDRESS_FIRST_LINE_LENGTH)

  const style = classnames(styles.footerBox, {
    [styles.warning]: !hiddenWarning,
  })

  return (
    <footer className={style}>
      <div className={styles.footer}>
        <div className={styles.info}>
          <div className={styles.logo}>
            <Logo footer />
          </div>

          <div>
            <span className={styles.name}>{name}</span>
            {!!addressFirstLine && <span>{addressFirstLine}</span>}
            {!!addressSecondLine && <span>{addressSecondLine}</span>}
            {!!phone && (
              <Phone phone={phone} defaultText={t('PhoneNotSpecified')} />
            )}
          </div>
        </div>

        <div className={styles.about}>
          <span dangerouslySetInnerHTML={{ __html: t('AllRightsReserved') }} />
          <span>{`${t('Version')} ${version}`}</span>
        </div>
      </div>
      <WarningStripe />
    </footer>
  )
}

export default Footer
