// @flow

import * as actions from './PostView.actionTypes'
import {
  NEWS_LIST_POST_WAS_PINNED,
  NEWS_LIST_BLOCK_POST_INITIATED,
  NEWS_LIST_BLOCK_USER_INITIATING,
  NEWS_LIST_BLOCK_USER_INITIATED,
} from '../../containers/NewsList/NewsList.actions'
import { errorUtils } from '../../utils/utils'

const initialState = {
  postData: {},
  initiated: false,
  working: false,
  error: false,
  blocking: false,
  notifications: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.POST_VIEW_INITIATING:
    case actions.POST_VIEW_RESET:
      return initialState
    case actions.POST_VIEW_INITIATED:
      return {
        ...state,
        initiated: true,
        working: false,
        postData: action.data,
      }
    case actions.POST_VIEW_UPDATE_BODY:
    case actions.POST_VIEW_UPDATE_FILES:
      return {
        ...state,
        working: true,
      }
    case actions.POST_VIEW_UPDATED:
      return {
        ...state,
        working: false,
        postData: action.data,
      }
    case actions.POST_VIEW_ERROR: {
      const notificationText = errorUtils(action.error)

      return {
        ...state,
        working: false,
        notifications: [...state.notifications, notificationText],
      }
    }
    case NEWS_LIST_POST_WAS_PINNED:
      return { ...state, postData: action.post }
    case NEWS_LIST_BLOCK_POST_INITIATED:
      if (action.postUuid === state.postData.uuid) {
        return {
          ...state,
          postData: { ...state.postData, spam: !action.isUnblock },
        }
      }

      return state
    case NEWS_LIST_BLOCK_USER_INITIATED: {
      if (action.postUuid === state.postData.uuid) {
        return {
          ...state,
          blocking: false,
          postData: {
            ...state.postData,
            owner_obj: {
              ...state.postData.owner_obj,
              status: action.isUnblock ? 0 : 3,
            },

            permissions: {
              ...state.postData.permissions,
              can_block_owner: action.isUnblock,
              can_unblock_owner: !action.isUnblock,
            },
          },
        }
      }

      return state
    }
    case NEWS_LIST_BLOCK_USER_INITIATING: {
      return { ...state, blocking: true }
    }
    default:
      return state
  }
}
