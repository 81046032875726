// @flow

import React from 'react'
import { withTranslation } from 'react-i18next'

import Input from '../../Input/Regular'

type Props = {
  inputProps: {
    autoFocus?: boolean,
    name: string,
    negative?: boolean,
    onChange: (string, string) => void,
    value: string | number,
  },
  label: string,
  t: String,
}

const EditingRow = (props: Props) => (
  <div className='apartment-create__row apartment-create__row--a'>
    <div className='apartment-profile__cell apartment-profile__cell--a'>
      {props.t(props.label)}
    </div>
    <div className='apartment-create__cell apartment-create__cell--b'>
      <Input
        className='button button--large button--block button--default'
        type='text'
        {...props.inputProps}
      />
    </div>
  </div>
)

export default withTranslation('Flat')(EditingRow)
