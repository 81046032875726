// @flow

import { map } from 'lodash-es'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { getFormattedDate } from '../../../utils/utils'

import Checkbox from '../../Checkbox'
import NewTableBodyCell from '../../NewTable/NewTableBodyCell'
import NewTableBodyRow from '../../NewTable/NewTableBodyRow'
import SelectCustom from '../../Select/SelectCustom'
import { formatPhone } from '../../HeaderSearch/HeaderSearch.utils'

const ContractorsTableBodyRow = props => {
  const { contractor, selectedItems, fromBuildings } = props

  const {
    name,
    id,
    categories = [],
    phone,
    email,
    buildings = [],
    created,
    archived,
    avatar,
  } = contractor

  const { t } = useTranslation('Contractors')

  const handleChange = () => props.handleChange && props.handleChange(id)

  const handleClick = () => props.onOpen(id)

  const checked = selectedItems.includes(id)

  let options = []

  if (!fromBuildings) {
    if (!archived && props.setBuildingModal) {
      options.push({
        label: t('SetBuildings'),
        value: 'setBuildings',
        handler: () => props.setBuildingModal({ global_id: id, buildings }),
        icon: 'building',
      })
    }

    if (!archived && props.setCategoryModal) {
      options.push({
        label: t('SetCategories'),
        value: 'setCategories',
        handler: () => props.setCategoryModal({ global_id: id, categories }),
        icon: 'objects',
      })
    }

    if (!archived && props.onRemove) {
      options.push({
        label: t('Archive'),
        value: 'remove',
        handler: () => props.onRemove(id),
        icon: 'remove',
      })
    }

    if (archived && props.onRestore) {
      options.push({
        label: t('Restore'),
        value: 'Restore',
        handler: () => props.onRestore(id),
        icon: 'restore',
      })
    }
  }

  return (
    <NewTableBodyRow checked={!fromBuildings && checked} onClick={handleClick}>
      <NewTableBodyCell title={name}>
        {!fromBuildings && (
          <Checkbox
            style={{ margin: '0 0 0 16px' }}
            checked={checked}
            onChange={handleChange}
          />
        )}
        <i
          className='table-item__user-pic'
          style={{ marginLeft: !fromBuildings ? '16px' : '0' }}
        >
          <img
            src={
              avatar ||
              'https://files.idwell.com/sys/thumbnail/170_default_avatar.png'
            }
            alt='user'
          />
        </i>
      </NewTableBodyCell>
      <NewTableBodyCell title={map(categories, 'name').join(', ')} />
      <NewTableBodyCell title={map(buildings, 'address').toString()} />
      <NewTableBodyCell title={email} />
      <NewTableBodyCell title={formatPhone(phone)} />
      <NewTableBodyCell title={getFormattedDate(created)}>
        {options.length > 0 && (
          <div className='table-extra table-extra--settings'>
            <SelectCustom
              options={options}
              onChange={({ handler }) => handler()}
            />
          </div>
        )}
      </NewTableBodyCell>
    </NewTableBodyRow>
  )
}

export default ContractorsTableBodyRow
