// @flow

import React from 'react'
import classnames from 'classnames'
import type { Node } from 'react'

type Props = {
  className?: string,
  isOpen: boolean,
}

const MinimizeButton = ({ className, isOpen }: Props): Node => {
  const btnClassName = classnames(
    'widget__new-header--drop button-drop button-drop--large',
    { 'widget__new-header--drop--open': isOpen },
    className
  )

  return <button className={btnClassName} type='button' />
}

export default MinimizeButton
