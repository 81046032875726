import { omit } from 'lodash-es'

const omitPassword = (config, initialConfig) => {
  if (config.password === initialConfig.password) {
    return omit(config, 'password')
  }

  return config
}

export const formatConfig = (config, initialConfig) => {
  const { download_from_ftp: downloadFromFtp } = config
  const formattedConfig = {
    ...config,
    manager_reporter: JSON.stringify(config.manager_reporter)
  }

  if (!downloadFromFtp) {
    const result = Object.keys(formattedConfig).reduce((acc, key) => {
      if (!key.includes('ftp')) {
        acc[key] = formattedConfig[key]
      }

      return acc
    }, {})

    result['download_from_ftp'] = false

    return omitPassword(result, initialConfig)
  }

  return omitPassword(formattedConfig, initialConfig)
}
