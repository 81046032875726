// @flow

import React from 'react'
import classnames from 'classnames'
import type { Node } from 'react'

import { FileIcon } from '../Icon'
import { cutText } from '../../utils/utils'
import Button from '../Button'

import styles from './Chip.module.scss'

type Props = {
  children?: Node,
  className?: string,
  color: string,
  file?: Object,
  onClick?: () => void,
  onClose?: () => void,
  text?: string,
}

const Chip = (props: Props): Node => {
  const { file, text, children, className, color } = props

  const currentText = file && file.filename ? file.filename : text

  const chipClass = classnames(
    styles.chip,
    styles[color],
    {
      [styles.centered]: text && !file && !children,
    },
    className
  )

  return (
    <div className={chipClass} onClick={props.onClick}>
      {!!file && (
        <div className={styles.avatar}>
          <FileIcon file={file} iconClass={styles.iconClass} />
        </div>
      )}
      {children}
      <span title={currentText}>{cutText(currentText, 13)}</span>
      {props.onClose && (
        <Button.Cross
          style={{ height: '12px', marginLeft: '4px' }}
          onClick={props.onClose}
        />
      )}
    </div>
  )
}

Chip.defaultProps = { color: 'regular' }

export default Chip
