// @flow

import React from 'react'
import moment from 'moment'
import MonthPanel from 'rc-calendar/lib/month/MonthPanel'
import Select from '../../../Select/SelectSimple'
import classnames from 'classnames'

class MonthPanelNew extends MonthPanel {
  componentDidUpdate(prevProps) {
    if ('value' in this.props && this.props.value !== prevProps.value) {
      this.setState({
        value: this.props.value,
      })
    }
  }

  setValue = value => {
    if (!('value' in this.props)) {
      this.setState({
        value,
      })
    }
  }

  setAndSelectValue = ({ value }) => {
    this.setValue(moment(value))
    this.props.onSelect(moment(value))
  }

  selectNextMonth = () => {
    const { value } = this.state
    this.setAndSelectValue({ value: value.add(1, 'M') })
  }

  selectPrevMonth = () => {
    const { value } = this.state
    this.setAndSelectValue({ value: value.subtract(1, 'M') })
  }

  months = () => {
    const value = this.state.value
    const current = value.clone()
    const months = []
    const localeData = value.localeData()
    let index = 0

    for (let rowIndex = 0; rowIndex < 12; rowIndex += 1) {
      const currents = current.clone().month(index)
      const content = localeData.months(currents)
      months.push({
        value: currents,
        content,
        title: content,
      })
      index += 1
    }

    return months
  }

  render() {
    const { onlyMonth, showNext, showPrev } = this.props
    const value = this.state.value
    const prevBtnClassName = classnames(
      'button button--calendar button--calendar--prev',
      {
        'button--calendar--hidden': !showPrev,
      }
    )
    const nextBtnClassName = classnames(
      'button button--calendar button--calendar--next',
      {
        'button--calendar--hidden': !showNext,
      }
    )

    return (
      <div className='calendar__month-panel'>
        {onlyMonth ? (
          <div className='calendar__month-panel-button-container'>
            <button
              type='button'
              className='button button--calendar button--calendar--prev'
              onClick={this.selectPrevMonth}
            />
            <span className='calendar__month-caption'>
              {this.months()[value.month()].title.replace(
                /.*/g,
                txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
              )}
              &nbsp;{value.year()}
            </span>
            <button
              type='button'
              className='button button--calendar button--calendar--next'
              onClick={this.selectNextMonth}
            />
          </div>
        ) : (
          <div className='calendar__month-panel-button-container'>
            <Select
              calendar2
              filtering
              labelKey='title'
              clearable={false}
              valueKey='value'
              value={value}
              placeholder=''
              options={this.months()}
              className='calendar__month-select'
              onChange={this.setAndSelectValue}
            />
            <button
              type='button'
              className={prevBtnClassName}
              onClick={this.selectPrevMonth}
            />
            <button
              type='button'
              className={nextBtnClassName}
              onClick={this.selectNextMonth}
            />
          </div>
        )}
      </div>
    )
  }
}

export default MonthPanelNew
