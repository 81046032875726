// @flow

import React from 'react'
import type { Node } from 'react'
import type {
  RouterHistory,
  Location,
  Match,
  StaticRouterContext,
} from 'react-router-dom'

import IndexContent from './IndexContent'

type Props = {
  history: RouterHistory,
  location: Location,
  match: Match,
  staticContext: StaticRouterContext,
}

const IndexPage = (props: Props): Node => {
  const {
    match: {
      params: { search },
    },
    staticContext,
    history,
  } = props

  return (
    <div className='content__col'>
      <IndexContent
        staticContext={staticContext}
        search={search}
        history={history}
        location={location}
      />
    </div>
  )
}

export default IndexPage
