// @flow

import { all, put, call, fork, takeEvery } from 'redux-saga/effects'

import api from '../../../core/api'
import * as actions from './PostCreating.actionTypes'
import { globalModalError } from '../../../components/Layout/Layout.actions'

function* watchCreate() {
  yield takeEvery(actions.CREATE_POST, create)
}

function* watchCreateTicker() {
  yield takeEvery(actions.CREATE_TICKER, createTicker)
}

function* create({ data }) {
  try {
    const post = yield call(api.newsband.createPost, data)
    yield put({ type: actions.POST_WAS_CREATED, post })
  } catch (error) {
    yield put(globalModalError(error.message.response.data.errors, '', true))
    yield put({
      type: actions.POST_CREATING_ERROR,
      error: error.message.response.data.errors,
    })
  }
}

function* createTicker({ data }) {
  try {
    const ticker = yield call(api.newsband.createTicker, data)
    yield put({ type: actions.TICKER_WAS_CREATED, ticker })
  } catch (error) {
    yield put(
      globalModalError(error.message.response.data.errors.non_field_errors)
    )
    yield put({
      type: actions.TICKER_CREATING_ERROR,
      error: error.message.response.data.errors,
    })
  }
}

export default function* watch() {
  yield all([fork(watchCreate), fork(watchCreateTicker)])
}
