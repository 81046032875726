// @flow

import * as actions from './SettingsUsers.actionTypes'
import {
  NOTIFICATION_USER_REMOVAL,
  NOTIFICATION_USER_RESTORE,
} from '../../components/Notification/NotificationItem'

const initialState = {
  infoInitiated: false,
  listInitiated: false,
  notifications: [],
}

export default (state = initialState, action) => {
  const { fullname } = action

  switch (action.type) {
    case actions.SETTINGS_USERS_INFO_READY:
      return {
        ...state,
        infoInitiated: true,
      }
    case actions.SETTINGS_USERS_LIST_READY:
      return {
        ...state,
        listInitiated: true,
      }
    case actions.SETTINGS_USERS_NOTIFICATION:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            text: action.message,
          },
        ],
      }
    case actions.PROFILE_INFO_REMOVE_COMPLETED: {
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            type: NOTIFICATION_USER_REMOVAL,
            fullname,
          },
        ],
      }
    }
    case actions.PROFILE_INFO_RESTORE_COMPLETED: {
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            type: NOTIFICATION_USER_RESTORE,
            fullname,
          },
        ],
      }
    }
    case actions.SETTINGS_USERS_RESET:
      return initialState
    default:
      return state
  }
}
