// @flow

import React from 'react'

import styles from './SecondaryText.module.scss'

type Props = {
  text: string,
}

const SecondaryText = (props: Props): React$Element<'span'> => {
  const { text } = props

  return <span className={styles.text}>{text}</span>
}

export default SecondaryText
