// @flow

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import Textarea from 'react-textarea-autosize'
import OutsideClick from 'react-onclickout'
import classnames from 'classnames'
import { isNull } from 'lodash-es'

import Suggestion from './Variable/SuggestVariable'
import { getTextareaCursorPosition } from '../utils'

type State = {
  activeVariable: ?string,
  ignoreBlur: boolean,
  suggestPosition: ?Array<number>,
  suggestStart: ?number,
  text: string,
}
type Props = {
  active: boolean,
  canSaveOnUnmount: () => boolean,
  data: Object,
  onClickOut: () => void,
  onUpdate: Object => void,
  t: string => string,
}

class Text extends Component<Props, State> {
  state = {
    activeVariable: null,
    ignoreBlur: false,
    suggestPosition: null,
    suggestStart: null,
    text: this.props.data.text || '',
  }

  componentDidUpdate(prevProps: Props) {
    const { active } = prevProps

    if (active && !this.props.active) {
      this.saveText()
    }
  }

  componentWillUnmount() {
    if (this.props.canSaveOnUnmount()) {
      this.saveText()
    }
  }

  getSuggestStart = target => {
    const beforeText = target.value.substring(0, target.selectionStart)
    const startIndex = beforeText.lastIndexOf('{{')
    const endIndex = beforeText.lastIndexOf('}}')

    return startIndex === -1 || startIndex < endIndex ? null : startIndex
  }

  getPosition = start => {
    const { activeVariable, suggestPosition } = this.state

    if (!this.textareaBox || !isNull(activeVariable)) {
      return suggestPosition
    }

    return getTextareaCursorPosition(this.textareaBox._ref, start)
  }

  getActiveVariable = (target, start) => {
    const afterText = target.value.substring(start)

    const regex = /{{([^{{}}\s]*)}*\s?/i
    const matches = regex.exec(afterText)

    return matches ? matches[1] : null
  }

  getVariableValues = target => {
    const start = this.getSuggestStart(target)

    if (isNull(start)) {
      return {
        activeVariable: null,
        suggestPosition: null,
        suggestStart: null,
      }
    }

    return {
      activeVariable: this.getActiveVariable(target, start),
      suggestPosition: this.getPosition(target.selectionStart),
      suggestStart: start,
    }
  }

  textareaBox = null

  handleMouseEnter = () => {
    this.setState({ ignoreBlur: true })
  }

  handleMouseLeave = () => {
    this.setState({ ignoreBlur: false })
  }

  handleMouseUp = ({ target }) => {
    this.setState(this.getVariableValues(target))
  }

  handleChange = e => {
    this.setState({
      text: e.target.value,
      ...this.getVariableValues(e.target),
    })
  }

  handleBlur = () => {
    if (this.state.ignoreBlur) {
      return
    }

    this.setState({
      activeVariable: null,
      suggestPosition: null,
      suggestStart: null,
    })
  }

  insertVariable = (name: string) => {
    const { suggestStart, text } = this.state
    const outdated = text.substring(suggestStart)
    const updated = outdated.replace(/{{[^{{}}\s]*}*/i, `{{${name}}}`)
    this.setState({
      text: `${text.substring(0, suggestStart)}${updated}`,
      activeVariable: null,
      suggestPosition: null,
      suggestStart: null,
    })
  }

  saveText = () => {
    const { data, onUpdate } = this.props
    const { text } = this.state

    if (text !== (data.text || '').toString()) {
      onUpdate({ id: data.id, text })
    }
  }

  render() {
    const { active, onClickOut, t } = this.props
    const { text, activeVariable, suggestPosition } = this.state

    const textClass = classnames(
      'request-constructor__card--text textarea-2 textarea-2--large textarea-2--block textarea-2--default',
      { 'request-constructor__card--text--no-border': !active && text }
    )

    return (
      <OutsideClick onClickOut={() => onClickOut()}>
        <div className='request-constructor__card--body'>
          <div
            className={textClass}
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
          >
            <Textarea
              className='textarea-2__input modal-adduser__comment'
              name='workflow'
              placeholder={t('textPlaceholder')}
              minRows={2}
              maxLength={2000}
              maxRows={5}
              ref={ref => {
                this.textareaBox = ref
              }}
              value={text}
              onBlur={this.handleBlur}
              onChange={this.handleChange}
              onMouseUp={this.handleMouseUp}
            />
            <Suggestion
              value={activeVariable}
              position={suggestPosition}
              onSelect={this.insertVariable}
            />
          </div>
        </div>
      </OutsideClick>
    )
  }
}

export default withTranslation('RequestConstructor')(Text)
