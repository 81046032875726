// @flow

import { getApi, patchRaw } from './api.core'

const BASE_PATHNAME = 'api/permissions/'

export const getDwellerPermissions = () => getApi(`${BASE_PATHNAME}dweller/`)
export const getManagerPermissions = () => getApi(`${BASE_PATHNAME}manager/`)
export const getUnvalidatedDwellerPermissions = () =>
  getApi(`${BASE_PATHNAME}unvalidated-dweller/`)

export const updateDwellerPermission = (params: { [string]: boolean }) =>
  patchRaw(`${BASE_PATHNAME}dweller/`, params)
export const updateManagerPermission = (params: { [string]: boolean }) =>
  patchRaw(`${BASE_PATHNAME}manager/`, params)
export const updateUnvalidatedDwellerPermission = (params: {
  [string]: boolean,
}) => patchRaw(`${BASE_PATHNAME}unvalidated-dweller/`, params)
