// @flow

import React from 'react'
import classnames from 'classnames'
import type { Node } from 'react'

import styles from './Sort.module.scss'

import Icon from '../../../../static/icons/sort.svg'

type Props = {
  labelKey: string,
  onClick?: (SyntheticEvent<HTMLButtonElement>) => void,
  sorting: ?string,
  working?: boolean,
}

const Sort = (props: Props): Node => {
  const { sorting, labelKey, working } = props

  const leftArrowStyle = classnames(styles.arrow, {
    [styles.unsorted]: !sorting,
  })

  const rightArrowStyle = classnames(leftArrowStyle, {
    [styles.asc]: sorting === labelKey || !sorting,
  })

  const buttonProps = Object.assign(
    {},
    props.onClick ? { onClick: props.onClick } : undefined
  )

  return (
    <button
      type='button'
      className={styles.sort}
      disabled={working}
      {...buttonProps}
    >
      <Icon className={leftArrowStyle} />
      <Icon className={rightArrowStyle} />
    </button>
  )
}

export default Sort
