// @flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import NewTableBodyRow from '../../NewTable/NewTableBodyRow'
import NewTableBodyCell from '../../NewTable/NewTableBodyCell'
import Button from '../../Button'
import { getFormattedDate, getUserId } from '../../../utils/utils'
import {
  toggleAccountIntegrationShared,
  // deleteAccount,
  arhivatedAccount,
  // deleteAsyncTack,
} from '../../../core/api/api.mail'
import Icon from '../../Icon'

import styles from './EmailIntegrationTableBodyRow.module.scss'
import ExtraActions from './ExtraActions'
import { useError } from '../useError'
// import { get } from 'lodash-es'
import BrowserStorage from '../../../utils/browserStorage'
import { useUser } from '../../../hooks'
import { MAIL_PATH_KEY } from '../../Menu/Menu.constants'

type Props = {
  email: {
    corporate: boolean,
    email: string,
    id: string,
    last_sync_at: string,
    need_reconnect: boolean,
    shared: boolean,
    status: Object,
  },
  handleOpen: string => void,
}

const EmailIntegrationTableBodyRow = (props: Props): Node => {
  const {
    email: {
      id,
      email,
      last_sync_at,
      shared,
      status,
      corporate,
      need_reconnect,
      created,
    },
    load,
    hideModal,
  } = props

  const user = useUser()
  const userId = getUserId(user)
  const { t } = useTranslation('EmailIntegrationNylas')

  const [isShared, setShared] = useState(shared)
  const [isHover, setHover] = useState(false)
  const [setError] = useError()
  const handleMouseEnter = () => setHover(true)
  const handleMouseLeave = () => setHover(false)

  const accountType = corporate ? t('Corporate') : t('Private')

  const sharedTitle = isShared ? t('accountIsShared') : t('accountIsNotShared')
  const sharedColor = isShared ? '#9ec221' : '#e44440'

  const LastSyncDate = last_sync_at ? getFormattedDate(last_sync_at) : '-'

  const formattedDateActivated = created ? getFormattedDate(created) : '-'

  const handleOpen = () => {
    props.handleOpen(id)
  }

  const handleToggleShared = () =>
    toggleAccountIntegrationShared(id).then(() => setShared(!isShared))

  const needReconnectMessage = need_reconnect ? t('NeedReconnect') : null

  const deleteLinks = () => {
    BrowserStorage.remove(`${userId}_/${MAIL_PATH_KEY}/inbox/`)
    BrowserStorage.remove(`${userId}_/${MAIL_PATH_KEY}/sent/`)
  }

  // const deleteAsync = taskId => {
  //   let timer = null
  //   let interval = setInterval(() => {
  //     deleteAsyncTack(taskId).then(data => {
  //       if (data.ready) {
  //         deleteLinks()
  //         clearInterval(interval)
  //         clearTimeout(timer)
  //         load()
  //         hideModal()
  //       } else {
  //         return
  //       }
  //     })
  //   }, 2000)
  //   timer = setTimeout(() => {
  //     clearInterval(interval)
  //     load()
  //     hideModal()
  //   }, 30000)
  // }

  // const handleDelete = () => {
  //   deleteAccount(id)
  //     .then(res => {
  //       deleteAsync(get(res, ['task_id']))
  //     })
  //     .catch(error => setError(error))
  // }

  const handleDisconnect = () => {
    arhivatedAccount(id)
      .then(() => {
        deleteLinks()
        load()
        hideModal()
      })
      .catch(error => setError(error))
  }

  return (
    <NewTableBodyRow key={id} id={id} onClick={handleOpen}>
      <NewTableBodyCell>
        <div className='table-item table-item__text' title={email}>
          <span>{email}</span>
        </div>
        {needReconnectMessage && (
          <div
            className={styles.needReconnect}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <Icon id='repeat' className={styles.icon} />
            {isHover && (
              <div className={styles.tooltip}>
                <div />
                <span>{needReconnectMessage}</span>
              </div>
            )}
          </div>
        )}
      </NewTableBodyCell>
      <NewTableBodyCell className={styles.toggle}>
        <div className='table-item table-item__text' title={accountType}>
          <span>{accountType}</span>
        </div>
      </NewTableBodyCell>
      <NewTableBodyCell className={styles.toggle}>
        <div
          className='table-item table-item__text'
          title={t(`status_${status}`)}
        >
          <span>{t(`status_${status}`)}</span>
        </div>
      </NewTableBodyCell>
      <NewTableBodyCell title={LastSyncDate} />
      <NewTableBodyCell className={styles.toggle}>
        {!corporate && (
          <>
            <Button.Toggle
              checked={isShared}
              id={`shared_${id}`}
              onChange={handleToggleShared}
            />
            <div className='table-item table-item__text' title={sharedTitle}>
              <span style={{ color: sharedColor }}>{sharedTitle}</span>
            </div>
          </>
        )}
      </NewTableBodyCell>
      <NewTableBodyCell title={formattedDateActivated} />
      <NewTableBodyCell>
        <ExtraActions
          isAsync
          handleDelete={handleDisconnect}
          handleDisconnect={handleDisconnect}
          {...props}
        />
      </NewTableBodyCell>
    </NewTableBodyRow>
  )
}

export default EmailIntegrationTableBodyRow
