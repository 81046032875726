// @flow

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import type { Node } from 'react'

import Button from '../Button'
import Image from '../ImageSquareFit'
import Text from '../TextClipped'
import { PREVIEW_GREAT_SIZE } from '../../constants'
import { isNoProfile } from '../../utils/utils'

import styles from './UserItem.module.scss'

type Props = {
  children?: Node,
  data: Object,
  resubscribe?: Object => void,
  subscribe?: Object => void,
  t: string => string,
  unsubscribe?: Object => void,
}
type State = {
  hover: boolean,
}

class UserItem extends Component<Props, State> {
  state = {
    hover: false,
  }

  hoverButton = () => {
    this.setState({ hover: true })
  }

  blurButton = () => {
    this.setState({ hover: false })
  }

  submit = (callback: ?(Object) => void) => () => {
    if (callback) {
      const { data } = this.props
      callback(data)
    }

    this.blurButton()
  }

  renderButton = () => {
    if (!this.props.subscribe) {
      return null
    }

    const { data } = this.props
    const { hover } = this.state

    const {
      working,
      follow,
      friend_request_status: status,
      relationships = {},
    } = data

    if (working) {
      return (
        <button
          disabled
          type='button'
          className='button button--large button--inline button--success-2'
        >
          <span className='button__spinner' />
          <span className='button__text'>{this.props.t('Wait')}</span>
        </button>
      )
    }

    const isFriend = data.is_friend || relationships.is_friend
    const subscribed =
      status === 'sended' || follow || relationships.is_friend_request_sended
    const ignore =
      ['sended_to_me', 'rejected'].includes(status) ||
      relationships.is_friend_request_rejected ||
      relationships.is_friend_request_received

    let handleClick = this.submit(this.props.subscribe)
    let text = 'Subscribe'
    let showIcon = !hover

    if (isFriend) {
      handleClick = this.submit(this.props.unsubscribe)
      text = hover ? 'UnSubscribe' : 'Friend'
    } else if (subscribed) {
      handleClick = this.submit(this.props.resubscribe)
      text = hover ? 'ResendSubscribe' : 'RequestSent'
    } else if (ignore) {
      return null
    } else {
      showIcon = false
    }

    return (
      <Button.Save
        blank
        icon={showIcon ? 'check' : null}
        onClick={handleClick}
        onMouseEnter={this.hoverButton}
        onMouseLeave={this.blurButton}
      >
        {this.props.t(text)}
      </Button.Save>
    )
  }

  // TODO move avatar to standalone component
  getImgUrl = () => {
    const { data } = this.props

    if (data.avatar) {
      return data.avatar
    }

    const { avatar_obj: avatar } = data

    if (avatar) {
      const preview = avatar.preview || avatar.origin || avatar

      return avatar.thumbnails
        ? avatar.thumbnails[PREVIEW_GREAT_SIZE] || preview
        : preview
    }

    return null
  }

  render() {
    const { children, data } = this.props
    const {
      address_hided: hideAddress,
      address,
      name,
      second_name: surname,
    } = data

    const id = data.owner || data.id
    const link = isNoProfile(data) ? '' : `/profile/${id}`

    return (
      <div className={styles.container}>
        <Link to={link}>
          <Image alt='userpic' src={this.getImgUrl()} />
        </Link>
        <Link className={styles.info} to={link}>
          <Text className={styles.name} title={name}>
            {name}
          </Text>
          {surname ? (
            <Text className={styles.name} title={surname}>
              {surname}
            </Text>
          ) : (
            <br />
          )}
          {!hideAddress && !!address && (
            <Text
              className={styles.address}
              maxLines={2}
              fontSize={12}
              title={address}
            >
              {address}
            </Text>
          )}
        </Link>
        {children || this.renderButton()}
      </div>
    )
  }
}

export default withTranslation('User')(UserItem)
