import * as actions from './ProfileInfo.actionTypes'

const initData = data => ({
  ...data,
  fullname: data.fullname,
  name: data.name,
  surname: data.second_name || '',
  post: data.company_post_name || '',
  birthday: data.birthday,
  avatar: data.avatar_obj || data.avatar,
})

const initialState = {
  initiated: false,
  data: {},
  backup: {},
  relationships: null,
  working: false,
  editMode: false,
  error: false,
  notifications: [],
  externalIdError: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.PROFILE_INFO_INITIATE:
      return {
        ...state,
        data: initData(action.data),
        backup: initData(action.data),
        relationships: action.data.relationships,
        initiated: true,
        editMode: false,
      }
    case actions.PROFILE_INFO_RESET:
      return initialState
    case actions.PROFILE_INFO_EDIT:
      return {
        ...state,
        editMode: true,
      }
    case actions.PROFILE_INFO_CANCEL:
      return {
        ...state,
        data: { ...state.backup },
        editMode: false,
      }
    case actions.PROFILE_INFO_UPDATE_FIELD:
      return {
        ...state,
        data: {
          ...state.data,
          [action.name]: action.value,
        },
      }
    case actions.PROFILE_INFO_APPROVE:
    case actions.PROFILE_INFO_UPDATE:
      return {
        ...state,
        working: true,
      }
    case actions.PROFILE_INFO_UPDATED:
      return {
        ...state,
        data: initData(action.data),
        backup: initData(action.data),
        editMode: false,
        profileData: initData(action.data),
        working: false,
        modal: null,
      }
    case actions.PROFILE_INFO_SUBSCRIBE:
    case actions.PROFILE_INFO_UNSUBSCRIBE:
      return {
        ...state,
        subscribing: true,
      }
    case actions.PROFILE_INFO_SUBSCRIBED:
      return {
        ...state,
        relationships: { ...state.relationships, is_friend_request: true },
        subscribing: false,
      }
    case actions.PROFILE_INFO_UNSUBSCRIBED:
      return {
        ...state,
        relationships: { ...state.relationships, is_friend: false },
        subscribing: false,
      }
    case actions.PROFILE_INFO_ACCEPTED: {
      const { relationships } = action.result

      return {
        ...state,
        relationships,
      }
    }
    case actions.PROFILE_INFO_ERROR:
      return {
        ...state,
        initiated: true,
        working: false,
        error: action.error,
      }
    case actions.PROFILE_INFO_REJECTED: {
      const { relationships } = action.result

      return {
        ...state,
        relationships,
      }
    }
    case actions.PROFILE_INFO_OPEN_MODAL: {
      return {
        ...state,
        modal: action.modal,
      }
    }
    case actions.PROFILE_INFO_CLOSE_MODAL: {
      return {
        ...state,
        modal: null,
      }
    }
    case actions.PROFILE_INFO_CHANGE_USER_STATUS_COMPLETED: {
      return {
        ...state,
        modal: null,
        data: {
          ...state.data,
          status: action.newStatus,
          permissions: action.permissions,
        },
      }
    }
    case actions.PROFILE_INFO_RESTORE_COMPLETED:
    case actions.PROFILE_INFO_REMOVE_COMPLETED: {
      return {
        ...state,
        modal: null,
      }
    }
    case actions.PROFILE_INFO_CHANGED_DWELLER_ROLE: {
      return {
        ...state,
        data: {
          ...state.data,
          is_landlord: action.result.is_landlord,
        },
        modal: null,
      }
    }
    case actions.PROFILE_INFO_CHANGED_MANAGER_ROLE: {
      return {
        ...state,
        data: {
          ...state.data,
          group: action.result.group,
        },
        modal: null,
      }
    }
    case actions.PROFILE_INFO_CHANGED_EXTERNAL_ID: {
      return {
        ...state,
        data: {
          ...state.data,
          external_id: action.result.external_id,
        },
        modal: null,
      }
    }
    case actions.PROFILE_INFO_EXTERNAL_ID_ERROR:
      return {
        ...state,
        externalIdError: true,
      }
    case actions.PROFILE_INFO_EXTERNAL_ID_ERROR_CLEANUP:
      return {
        ...state,
        externalIdError: false,
      }
    case actions.REDIRECT_RESET: {
      return {
        ...state,
        redirect: null,
      }
    }
    default:
      return state
  }
}
