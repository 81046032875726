// @flow

import React from 'react'
import classNames from 'classnames'
import type { Node } from 'react'

import styles from './ModalText.module.scss'

type Props = {
  className?: string,
  text: string,
}

const ModalText = (props: Props): Node => {
  const { text, className } = props

  const textClass = classNames(styles.text, className)

  return (
    <p title={text} className={textClass}>
      {text}
    </p>
  )
}

export default ModalText
