//@flow

import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelected } from '../../../hooks'
import { useThemeColor } from '../../../hooks'
import { useDispatch } from 'react-redux'

import {
  getMailFolder,
  updateFolders,
  syncFolders,
  deleteAsyncTack,
} from '../../../core/api/api.mail'

import { SimpleBlock } from '../../Block'
import SectionHeader from '../../SectionHeader'
import Warning from '../../Warning'

import FolderSyncTable from './FolderSyncTable'
import TopPagination from '../../Pagination/TopPagination'
import BottomPagination from '../../Pagination/BottomPagination'
import Toolbox from '../../Toolbox'
import ToolboxCell from '../../Users/UserList/Toolbox/ToolboxCell'
import Button from '../../Button'
import SelectCustom from '../../Select/SelectCustom'
import Search from './Search'
import EmptyList from '../../EmptyList'

import SelectPopUp from '../../Users/UserList/SelectPopUp'

import { globalModalError } from '../../Layout/Layout.actions'

import styles from '../EmailIntegrationNew.module.scss'

const FolderSyncSettings = props => {
  const { accountId } = props
  const { t } = useTranslation('EmailIntegrationNylas')
  const dispatch = useDispatch()
  const themeColor = useThemeColor()
  const [isWorking, setWorking] = useState(false)
  const [selectWorking, setSelectWorking] = useState(false)
  const [syncWorking, setSyncWorking] = useState(false)
  const [folders, setFolders] = useState([])
  const [page, setPage] = useState(1)
  const [meta, setMeta] = useState({})
  const [search, setSearch] = useState('')
  const [sort, setSort] = useState('')

  const [selectedItems, changeSelected, setSelected] = useSelected()

  useEffect(() => {
    setWorking(true)
  }, [page, search, sort])

  useEffect(() => {
    setPage(1)
    setSelected([])
  }, [search])

  useEffect(() => {
    if (isWorking) {
      getMailFolder(accountId, {
        is_disabled: 0,
        page,
        search: search || undefined,
        ordering: sort || undefined,
      }).then(data => {
        setFolders(data.results.objects)
        setMeta(data.meta)
        setWorking(false)
      })
    }
  }, [isWorking])

  const getAllFolders = () => {
    setSelectWorking(true)
    getMailFolder(accountId, {
      is_disabled: 0,
      page_size: 1000000,
      search: search || undefined,
    }).then(data => {
      setSelected(data.results.objects.map(f => f.id))
      setSelectWorking(false)
    })
  }

  const updateFolderSync = (id, is_sync) => {
    updateFolders(accountId, [{ id, is_sync }]).then(() => setWorking(true))
  }

  const massUpdateFolderSync = is_sync => {
    updateFolders(
      accountId,
      selectedItems.map(id => ({ id, is_sync }))
    ).then(() => {
      setSelected([])
      setWorking(true)
    })
  }

  const handleSyncFolders = () => {
    setSyncWorking(true)
    syncFolders(accountId)
      .then(data => {
        if (data.task_id) {
          checkSyncTask(data.task_id)
        }
      })
      .catch(error => {
        setSyncWorking(false)
        const errorMsg = error.message?.response?.data?.errors

        if (errorMsg) {
          dispatch(globalModalError(errorMsg.replace('.', ':'), '', true))
        }
      })
  }

  const checkSyncTask = task_id => {
    deleteAsyncTack(task_id).then(res => {
      if (!res.ready) {
        setTimeout(() => checkSyncTask(task_id), 500)
      } else {
        setSyncWorking(false)
        setWorking(true)
      }
    })
  }

  const getMassActionOptions = () => [
    {
      value: 'foldersSync',
      label: t('FolderConnect'),
      handler: () => massUpdateFolderSync(true),
      icon: 'upload',
    },
    {
      value: 'foldersUnsync',
      label: t('FolderDisconnect'),
      handler: () => massUpdateFolderSync(false),
      icon: 'cross',
    },
  ]

  const handleSort = sortKey =>
    setSort(sortKey === sort ? `-${sortKey}` : sortKey)

  const selectAction =
    selectedItems.length >= meta.count
      ? 'selected_all'
      : folders.filter(f => !selectedItems.includes(f.id)).length === 0 &&
        selectedItems.length === folders.length
      ? 'selected_page'
      : 'selected_some'

  const selectPopupMessages = {
    selected_all: t('FolderSelectedAll', { count: meta.count }),
    selected_page: t('FolderSelectedCurrPage', { count: selectedItems.length }),
    selected_some: t('FolderSelectedSome', { count: selectedItems.length }),
  }

  const selectPopupActionMessage = {
    selected_all: t('FolderUnselectAll', { count: meta.count }),
    selected_page: t('FolderSelectAll', { count: meta.count }),
    selected_some: t('FolderSelectAll', { count: meta.count }),
  }

  const selectOnClick = {
    selected_all: () => setSelected([]),
    selected_page: () => getAllFolders(),
    selected_some: () =>
      meta.count > folders.length
        ? getAllFolders()
        : setSelected(folders.map(f => f.id)),
  }

  return (
    <SimpleBlock working={isWorking}>
      <SectionHeader header='true' text={t('FolderSyncSettings')} />
      <Warning
        noArrow
        text={t('FolderSyncHint')}
        boxClassName={styles.warningBox}
        className={styles.warning}
      />
      <Toolbox
        showSelectAllCheckbox
        itemValueKey='id'
        checked={
          folders.length > 0 &&
          ['selected_all', 'selected_page'].indexOf(selectAction) !== -1
        }
        items={folders}
        setSelected={items =>
          selectedItems.length === meta.count
            ? setSelected([])
            : setSelected(items)
        }
      >
        {!selectedItems.length && (
          <ToolboxCell>
            <Button.Save working={syncWorking} onClick={handleSyncFolders}>
              {t('FolderSyncUpdate')}
            </Button.Save>
          </ToolboxCell>
        )}
        {selectedItems.length > 0 && (
          <SelectCustom
            style={{ marginLeft: '5px' }}
            options={getMassActionOptions()}
            onChange={opt => opt.handler()}
          >
            {t('Actions')}
          </SelectCustom>
        )}
        <ToolboxCell>
          <Search
            value={search}
            setSearchValue={setSearch}
            placeHolder={t('SearchFolders')}
          />
        </ToolboxCell>
        <ToolboxCell isRight>
          <TopPagination
            classes='pager pager--large pager--default-2'
            meta={meta}
            setPage={setPage}
          />
        </ToolboxCell>
      </Toolbox>
      {folders.length > 0 || isWorking || syncWorking ? (
        <FolderSyncTable
          selectedItems={selectedItems}
          loading={isWorking || syncWorking}
          folders={folders}
          location={{ search: sort ? `?ordering=${sort}` : '' }}
          onUpdateFolder={updateFolderSync}
          onChangeSelected={changeSelected}
          onSort={handleSort}
        />
      ) : (
        <EmptyList
          embedded
          icon='file'
          title={search ? t('NoSearchResults') : t('NoSyncFolders')}
        />
      )}
      <BottomPagination meta={meta} setPage={setPage} classes='paginator-2' />
      {selectedItems.length > 0 && (
        <SelectPopUp
          getInfoMessages={selectPopupMessages}
          getActionMessage={selectPopupActionMessage}
          working={selectWorking}
          action={selectAction}
          clearAll={() => setSelected([])}
          themeColor={themeColor}
          parentWidth={'800px'}
          onClick={selectOnClick}
        />
      )}
    </SimpleBlock>
  )
}

export default FolderSyncSettings
