// @flow

import React, { useReducer, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import type { Node } from 'react'

import Chatroom from '../../containers/Chatroom/Chatroom'
import Pan from '../Tabs/Pan'
import Tabs from '../Tabs/Tabs'
import RequestActivities from './RequestActivities'
import Emails from '../Emails'
import NewTabRouter from '../NewTabRouter'
import { isDwellerUser, isStaffUser } from '../../utils/utils'
import { useUser } from '../../hooks'
import { getUkConfig } from '../../utils/commonSelectors'
import {
  DWELLER_AUDIENCE,
  MANAGER_AUDIENCE,
  PROVIDER_AUDIENCE,
} from '../../constants/email'
import { isNull } from 'lodash-es'

import { MAIL_SEARCH_SELECT_CUSTOMERS } from '../MailSearch/MailSearch.actionTypes'

type Props = {
  isReloading: boolean,
  outbound?: string,
  requestData: Object,
  setReloading: boolean => void,
}

export const RequestUpdates = (props: Props): Node => {
  const { outbound, requestData, isReloading, setReloading } = props

  const [logs, setLogs] = useState([])
  const [reset, setReset] = useReducer(x => x + 1, 0)
  const [changedMessageValues, setChangedMessageValues] = useState(false)
  const [showSuggestModal, setShowSuggestModal] = useState(null)
  const [confirmLink, setConfirmLink] = useState(null)
  const {
    public_chatroom_read,
    public_chatroom,
    private_chatroom_read,
    private_chatroom,
    manager_thread_read,
    provider_thread_read,
    dweller_thread_read,
    permissions: { can_add_comment, can_move_thread },
    id,
    directory: { id: directoryId },
    owner_obj,
    building,
    flat,
  } = requestData

  const { t } = useTranslation('Request')
  const dispatch = useDispatch()

  const { match } = useParams()

  const user = useUser()

  const isStaff = isStaffUser(user)
  const isDweller = isDwellerUser(user)

  const { reversed_request_chat_tabs } = useSelector(getUkConfig)

  const isAllThreadsRead =
    provider_thread_read && manager_thread_read && dweller_thread_read

  const isChatsThreadsRead = isDweller
    ? public_chatroom_read
    : public_chatroom_read && private_chatroom_read

  let mainTabs = [
    { value: 'chats', label: t('Chats'), read: isChatsThreadsRead },
  ]

  if (!outbound && isStaff) {
    mainTabs.push({
      value: 'emails',
      label: t('Emails'),
      read: isAllThreadsRead,
    })
  }

  dispatch({
    type: MAIL_SEARCH_SELECT_CUSTOMERS,
    selectedCustomers:
      owner_obj && owner_obj.email
        ? [
            {
              id: owner_obj.id,
              fullname: owner_obj.fullname,
              email: owner_obj.email,
            },
          ]
        : [],
  })

  if (!outbound) {
    mainTabs.push({ value: 'activity', label: t('Updates') })
  }

  return (
    <NewTabRouter withBorder tabs={mainTabs}>
      <Tabs chats selected={0}>
        {reversed_request_chat_tabs && !isDweller && !outbound && (
          <Pan
            id='order-colleagues-tab'
            label={t('Colleagues')}
            linkHash='#colleagues'
            read={private_chatroom_read}
          >
            <Chatroom
              isEmbedded
              chatroomUuid={private_chatroom || 'create'}
              key='colleagues'
              match={match}
              readOnly={!can_add_comment}
              requestId={id}
              scope='request_chat'
              object={id}
              templateParams={{ for_building: building }}
            />
          </Pan>
        )}
        <Pan
          id='order-clients-tab'
          label={isDweller ? t('Discussion') : t('Clients')}
          linkHash='#clients'
          read={public_chatroom_read}
        >
          <Chatroom
            isEmbedded
            isPublic
            chatroomUuid={public_chatroom || 'create'}
            key='clients'
            match={match}
            outbound={outbound}
            readOnly={!can_add_comment}
            requestId={id}
            scope='request_chat'
            object={id}
            templateParams={{ for_building: building }}
          />
        </Pan>
        {!reversed_request_chat_tabs && !isDweller && !outbound && (
          <Pan
            id='order-colleagues-tab'
            label={t('Colleagues')}
            linkHash='#colleagues'
            read={private_chatroom_read}
          >
            <Chatroom
              isEmbedded
              chatroomUuid={private_chatroom || 'create'}
              key='colleagues'
              match={match}
              readOnly={!can_add_comment}
              requestId={id}
              scope='request_chat'
              object={id}
              templateParams={{ for_building: building }}
            />
          </Pan>
        )}
      </Tabs>
      {!outbound && !isDweller && (
        <Tabs
          emails
          selected={0}
          storageKey='request-emails-tab'
          itemStyle={{
            width: '25%',
          }}
          confirmLink={confirmLink}
          setConfirmLink={setConfirmLink}
          needShowSuggestModal={changedMessageValues}
          onSelectTab={setReset}
          onShowSuggestModal={setShowSuggestModal}
        >
          <Pan
            id='all-email-tab'
            label={t('AllEmails')}
            linkHash='#all-emails'
            read={isAllThreadsRead}
          >
            <Emails
              flatId={flat}
              showSuggestModal={!isNull(showSuggestModal)}
              buildingId={building}
              requestId={id}
              canMoveThread={can_move_thread}
              key={reset}
              directoryId={directoryId}
              isReloading={isReloading}
              setReloading={setReloading}
              setActivity={props.setActivity}
              scope='request_thread'
              object={id}
              setChangedValues={setChangedMessageValues}
              changedValues={changedMessageValues}
              canOpenTab={() => setConfirmLink(showSuggestModal)}
              setShowSuggestModal={setShowSuggestModal}
            />
          </Pan>
          <Pan
            id='provider-email-tab'
            label={t('ProviderEmails')}
            linkHash='#provider-emails'
            read={provider_thread_read}
          >
            <Emails
              flatId={flat}
              showSuggestModal={showSuggestModal}
              buildingId={building}
              requestId={id}
              canMoveThread={can_move_thread}
              audience={PROVIDER_AUDIENCE}
              key={reset}
              directoryId={directoryId}
              isReloading={isReloading}
              setReloading={setReloading}
              setActivity={props.setActivity}
              scope='request_thread'
              object={id}
              setChangedValues={setChangedMessageValues}
              changedValues={changedMessageValues}
              canOpenTab={() => setConfirmLink(showSuggestModal)}
              setShowSuggestModal={setShowSuggestModal}
            />
          </Pan>
          <Pan
            showSuggestModal={showSuggestModal}
            id='dweller-email-tab'
            label={t('DwellerEmails')}
            linkHash='#dweller-emails'
            read={dweller_thread_read}
          >
            <Emails
              flatId={flat}
              showSuggestModal={showSuggestModal}
              buildingId={building}
              requestId={id}
              canMoveThread={can_move_thread}
              audience={DWELLER_AUDIENCE}
              key={reset}
              directoryId={directoryId}
              isReloading={isReloading}
              setReloading={setReloading}
              setActivity={props.setActivity}
              scope='request_thread'
              object={id}
              setChangedValues={setChangedMessageValues}
              changedValues={changedMessageValues}
              canOpenTab={() => setConfirmLink(showSuggestModal)}
              setShowSuggestModal={setShowSuggestModal}
            />
          </Pan>
          <Pan
            id='manager-email-tab'
            label={t('ManagerEmails')}
            linkHash='#manager-emails'
            read={manager_thread_read}
          >
            <Emails
              showSuggestModal={showSuggestModal}
              buildingId={building}
              flatId={flat}
              requestId={id}
              canMoveThread={can_move_thread}
              audience={MANAGER_AUDIENCE}
              key={reset}
              directoryId={directoryId}
              isReloading={isReloading}
              setReloading={setReloading}
              setActivity={props.setActivity}
              scope='request_thread'
              object={id}
              setChangedValues={setChangedMessageValues}
              changedValues={changedMessageValues}
              canOpenTab={() => setConfirmLink(showSuggestModal)}
              setShowSuggestModal={setShowSuggestModal}
            />
          </Pan>
        </Tabs>
      )}
      {!outbound && (
        <RequestActivities
          activity
          requestId={id}
          logs={logs}
          setLogs={setLogs}
        />
      )}
    </NewTabRouter>
  )
}

export default RequestUpdates
