// @flow

import React from 'react'
import classnames from 'classnames'
import type { Node } from 'react'

import styles from './NewTable.module.scss'

type Props = {
  children: Node,
  className?: string,
  fixed: boolean,
  loading: boolean,
}

const NewTable = (props: Props): Node => {
  const { loading, className, fixed } = props

  const wrapperClass = classnames(className, styles.wrapper, {
    [styles.loading]: loading,
  })

  const tableClass = classnames(styles.table, { [styles.fixed]: fixed })

  return (
    <div className={wrapperClass}>
      <table className={tableClass}>{props.children}</table>
    </div>
  )
}

NewTable.defaultProps = {
  loading: false,
  fixed: true,
}

export default NewTable
