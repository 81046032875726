// @flow

const ELEM_PROPERTIES = [
  'direction',
  'boxSizing',
  'width',
  'height',
  'overflowX',
  'overflowY',

  'borderTopWidth',
  'borderRightWidth',
  'borderBottomWidth',
  'borderLeftWidth',

  'paddingTop',
  'paddingRight',
  'paddingBottom',
  'paddingLeft',

  'fontStyle',
  'fontVariant',
  'fontWeight',
  'fontStretch',
  'fontSize',
  'fontSizeAdjust',
  'lineHeight',
  'fontFamily',

  'textAlign',
  'textTransform',
  'textIndent',
  'textDecoration',

  'letterSpacing',
  'wordSpacing',
]

export const getTextareaCursorPosition = (elem, start) => {
  const div = document.createElement('div')
  elem.parentNode.insertBefore(div, elem)

  const computed = window.getComputedStyle
    ? window.getComputedStyle(elem)
    : elem.currentStyle // currentStyle for IE < 9

  const style = div.style
  style.whiteSpace = 'pre-wrap'
  style.wordWrap = 'break-word'
  style.position = 'absolute'
  style.visibility = 'hidden'

  ELEM_PROPERTIES.forEach(prop => {
    style[prop] = computed[prop]
  })
  style.overflow = 'hidden'

  const divText = document.createTextNode('')
  div.appendChild(divText)
  const span = document.createElement('span')
  const spanText = document.createTextNode('')
  span.appendChild(spanText)
  div.appendChild(span)

  window.addEventListener('resize', () => {
    style.width = window.self.computed.width
  })

  divText.nodeValue = elem.value.substring(0, start)
  spanText.nodeValue = elem.value.substring(start) || '.'
  const left = span.offsetLeft + parseInt(computed.borderLeftWidth, 10)
  const top = span.offsetTop + parseInt(computed.borderTopWidth, 10)

  return [left, top]
}
