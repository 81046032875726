// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import NewTable from '../../NewTable'
import NewTableBody from '../../NewTable/NewTableBody'
import InvoiceTypeTableRow from './InvoiceTypeTableRow'

type Props = { onEdit: Function }

const InvoiceTypeTable = (props: Props): Node => {
  const { t } = useTranslation('BudgetPlan')

  const options = [
    {
      value: 'editInvoiceType',
      label: t('EditInvoiceType'),
      onClick: props.onEdit,
      icon: 'pencil',
    },
  ]

  return (
    <NewTable>
      <NewTableBody>
        <InvoiceTypeTableRow options={options} onEdit={props.onEdit} />
      </NewTableBody>
    </NewTable>
  )
}

export default InvoiceTypeTable
