// @flow

import {
  GET_CHECKITEMS_INIT,
  GET_CHECKITEMS_SUCCESS,
  GET_CHECKITEMS_ERROR,
  CREATE_CHECKITEM_INIT,
  CREATE_CHECKITEM_SUCCESS,
  CREATE_CHECKITEM_ERROR,
  DELETE_CHECKITEM_INIT,
  DELETE_CHECKITEM_SUCCESS,
  DELETE_CHECKITEM_ERROR,
  TOGGLE_CHECKITEM_INIT,
  TOGGLE_CHECKITEM_SUCCESS,
  TOGGLE_CHECKITEM_ERROR,
  EDIT_CHECKITEM_INIT,
  EDIT_CHECKITEM_SUCCESS,
  EDIT_CHECKITEM_ERROR,
  RESET_INITIAL_STATE,
} from './Checklist.constants'

import type { Checkitem } from './Checklist.constants'

// =================== Reset initial state =====================

type ResetInitialStateAction = { type: string }
export function resetInitialState(): ResetInitialStateAction {
  return {
    type: RESET_INITIAL_STATE,
  }
}

// ================= Get checkitems  ===================

type GetCheckitemsInitAction = { checklistId: number, type: string }
export function getCheckitemsInit(
  checklistId: number
): GetCheckitemsInitAction {
  return {
    type: GET_CHECKITEMS_INIT,
    checklistId,
  }
}

type GetCheckitemsSuccessAction = {
  checkitems: Array<Checkitem>,
  checklistId: number,
  type: string,
}
export function getCheckitemsSuccess(
  checklistId: number,
  checkitems: Array<Checkitem>
): GetCheckitemsSuccessAction {
  return {
    type: GET_CHECKITEMS_SUCCESS,
    checklistId,
    checkitems,
  }
}

type GetCheckitemsErrorAction = { error: string, type: string }
export function getCheckitemsError(error: string): GetCheckitemsErrorAction {
  return {
    type: GET_CHECKITEMS_ERROR,
    error,
  }
}

// ================ Create checkitem =======================

type CreateCheckitemInitAction = {
  checklistId: number,
  text: string,
  type: string,
}
export function createCheckitemInit(
  checklistId: number,
  text: string
): CreateCheckitemInitAction {
  return {
    type: CREATE_CHECKITEM_INIT,
    checklistId,
    text,
  }
}

type CreateCheckitemSuccessAction = {
  checkitem: Checkitem,
  checklistId: number,
  type: string,
}
export function createCheckitemSuccess(
  checklistId: number,
  checkitem: Checkitem
): CreateCheckitemSuccessAction {
  return {
    type: CREATE_CHECKITEM_SUCCESS,
    checklistId,
    checkitem,
  }
}

type CreateCheckitemErrorAction = { error: string, type: string }
export function createCheckitemError(
  error: string
): CreateCheckitemErrorAction {
  return {
    type: CREATE_CHECKITEM_ERROR,
    error,
  }
}

// ================ Delete checkitem =======================

type DeleteCheckitemInitAction = {
  checkitemId: number,
  checklistId: number,
  type: string,
}
export function deleteCheckitemInit(
  checklistId: number,
  checkitemId: number
): DeleteCheckitemInitAction {
  return {
    type: DELETE_CHECKITEM_INIT,
    checklistId,
    checkitemId,
  }
}

type DeleteCheckitemSuccessAction = {
  checkitemId: number,
  checklistId: number,
  type: string,
}
export function deleteCheckitemSuccess(
  checklistId: number,
  checkitemId: number
): DeleteCheckitemSuccessAction {
  return {
    type: DELETE_CHECKITEM_SUCCESS,
    checklistId,
    checkitemId,
  }
}

type DeleteCheckitemErrorAction = { error: string, type: string }
export function deleteCheckitemError(
  error: string
): DeleteCheckitemErrorAction {
  return {
    type: DELETE_CHECKITEM_ERROR,
    error,
  }
}

// ================ Toggle checkitem =======================

type ToggleCheckitemInitAction = {
  checked: boolean,
  checkitemId: number,
  checklistId: number,
  type: string,
}
export function toggleCheckitemInit(
  checklistId: number,
  checkitemId: number,
  checked: boolean
): ToggleCheckitemInitAction {
  return {
    type: TOGGLE_CHECKITEM_INIT,
    checklistId,
    checkitemId,
    checked,
  }
}

type ToggleCheckitemSuccessAction = {
  checkitem: Checkitem,
  checklistId: number,
  type: string,
}
export function toggleCheckitemSuccess(
  checklistId: number,
  checkitem: Checkitem
): ToggleCheckitemSuccessAction {
  return {
    type: TOGGLE_CHECKITEM_SUCCESS,
    checklistId,
    checkitem,
  }
}

type ToggleCheckitemErrorAction = { error: string, type: string }
export function toggleCheckitemError(
  error: string
): ToggleCheckitemErrorAction {
  return {
    type: TOGGLE_CHECKITEM_ERROR,
    error,
  }
}

// ==================== Change checkitem text ===========================

type EditCheckitemInitAction = {
  checkitemId: number,
  checklistId: number,
  text: string,
  type: string,
}
export function editCheckitemInit(
  checklistId: number,
  checkitemId: number,
  text: string
): EditCheckitemInitAction {
  return {
    type: EDIT_CHECKITEM_INIT,
    checklistId,
    checkitemId,
    text,
  }
}

type EditCheckitemSuccessAction = {
  checkitem: Checkitem,
  checklistId: number,
  type: string,
}
export function editCheckitemSuccess(
  checklistId: number,
  checkitem: Checkitem
): EditCheckitemSuccessAction {
  return {
    type: EDIT_CHECKITEM_SUCCESS,
    checklistId,
    checkitem,
  }
}

type EditCheckitemErrorAction = { error: string, type: string }
export function editCheckitemError(error: string): EditCheckitemErrorAction {
  return {
    type: EDIT_CHECKITEM_ERROR,
    error,
  }
}
