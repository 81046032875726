import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import filterInputAttributes from './filter-input-attributes'

class Input extends PureComponent {
  static propTypes = {
    ignoreTab: PropTypes.bool,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    onPrev: PropTypes.func.isRequired,
    onEscape: PropTypes.func.isRequired,
  }

  static defaultProps = {
    value: '',
    ignoreTab: false,
  }

  onChange = e => {
    this.props.onChange(e.target.value)
  }

  onInputKeyDown = e => {
    switch (e.which) {
      case 40: // DOWN
        e.preventDefault()
        this.props.onNext()
        break
      case 38: // UP
        e.preventDefault()
        this.props.onPrev()
        break
      case 13: // ENTER
        e.preventDefault()
        this.props.onSelect()
        break
      case 9: // TAB
        if (!this.props.ignoreTab) {
          this.props.onSelect()
        }

        break
      case 27: // ESC
        this.props.onEscape()
        break
      default:
        break
    }
  }

  render() {
    const { onFocus, onBlur } = this.props
    const attributes = filterInputAttributes(this.props)

    return (
      <input
        id='geosuggest__input'
        className='geosuggest__input'
        type='text'
        autoComplete='off'
        {...attributes}
        autoFocus
        value={this.props.value}
        onKeyDown={this.onInputKeyDown}
        onChange={this.onChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    )
  }
}

export default Input
