// @flow

import { UK_CONTACTS_MODEL } from '../../constants'

export const getUk = state => state.init.uk
export const getUkName = state => getUk(state).name
export const getUkLogo = state => getUk(state).logo_obj.thumbnail
export const getUKTheme = state => getUk(state).theme
export const getBuildingId = state => {
  const params = state.router.location.pathname.split('/').reverse()

  return params[0] || params[1]
}
export const getAddressObj = state =>
  state.publicBuildingPage.building.address_obj
export const getAddress = state => getAddressObj(state).value
export const getManagers = state =>
  state.publicBuildingPage.building.owner_objs.slice(0, 2)
export const getContacts = state =>
  state.publicBuildingPage.contacts.results.objects.slice(0, 3)
export const getNewsList = state =>
  state.publicBuildingPage.news.results.objects
export const getTickerNewsList = state =>
  state.publicBuildingPage.tickerNews.results.objects
export const getNewsBand = state =>
  state.publicBuildingPage.tickerNews.results.objects
export const getWeather = state => state.publicBuildingPage.weather
export const getVersion = state => state.publicBuildingPage.version
export const getBanner = state => getUk(state).banner_obj
export const getSmallBanner = state => getUk(state).banner_small_obj
export const isError = state => state.publicBuildingPage.error
export const isLoading = state => state.publicBuildingPage.loading
export const isInitialLoading = state =>
  isLoading(state).building && getAddress(state) === null

export const getWeatherParams = state => ({
  lat: getAddressObj(state).lattitude,
  lon: getAddressObj(state).longitude,
  appid: state.publicBuildingPage.appid,
  units: 'metric',
})
export const getContactsParams = state => ({
  model: UK_CONTACTS_MODEL,
  fk: getUk(state).id,
})
export const getNewsParams = state => ({
  building: getBuildingId(state),
})
