// @flow

import * as actions from './BuildingList.actionTypes'

export function init(params: Object): Object {
  return { type: actions.BUILDING_LIST_INITIATING, params }
}

export function load(params: Object): Object {
  return { type: actions.BUILDING_LIST_LOAD, params }
}

export function remove(
  params: Object,
  ids: Array<number>,
  update_responsible_users: boolean
): Object {
  return {
    type: actions.BUILDING_LIST_REMOVE,
    params,
    ids,
    update_responsible_users,
  }
}

export function removeFromGroup(
  params: Object,
  buildings: Array<number>,
  update_responsible_users: boolean
): Object {
  return {
    type: actions.BUILDING_LIST_REMOVE_FROM_GROUP,
    params,
    buildings,
    update_responsible_users,
  }
}

export function reset(): Object {
  return { type: actions.BUILDING_LIST_RESET }
}

export function restore(params: Object, ids: Array<number>): Object {
  return { type: actions.BUILDING_LIST_RESTORE, params, ids }
}

export function updateImages(ids: Array<number>): Object {
  return { type: actions.UPDATE_IMAGES, ids }
}

export function updateGroupBuildings(
  params: Object,
  buildings: Array<number>,
  update_responsible_users: boolean
): Object {
  return {
    type: actions.UPDATE_GROUP_BUILDINGS,
    params,
    buildings,
    update_responsible_users,
  }
}
