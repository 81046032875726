// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'

import Files from '../Files'

const DwellerFiles = props => {
  const { location, match } = props

  const { t } = useTranslation('Files')

  return (
    <div className='content__col'>
      <section className='unit unit--default bar'>
        <h2 className='unit__title'>{t('DwellerFilesTitle')}</h2>
        <Files isDweller location={location} match={match} />
      </section>
    </div>
  )
}

export default DwellerFiles
