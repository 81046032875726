// @flow

import React, { useState } from 'react'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash-es'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'

import NewSelectSimple from '../NewSelectSimple'
import Button from '../Button'
import Table from '../Table'
import {
  setStep4,
  getDocumentMapping,
} from '../../core/api/api.documentMapping'
import { getStatus } from './MappedDocument.utils'
import { EMAIL_AND_FILE_TYPE, EMAIL_TYPE } from './MappedDocument.constants'

const Step3 = props => {
  const { usersToPages, id } = props

  const [working, setWorking] = useState(false)

  const dispatch = useDispatch()

  const { t } = useTranslation('DocumentMapping')

  const options = [
    { label: t('NotificationEmailAndFileType'), value: EMAIL_AND_FILE_TYPE },
    { label: t('NotificationEmailType'), value: EMAIL_TYPE },
  ]

  const [option, setOption] = useState(options[0])

  const tableClass = classnames('table table--default', {
    'working-overlay': working,
  })

  const handleClick = () => {
    setWorking(true)

    setStep4(id, { notification_type: option.value })
      .then((data: Object) => getStatus(data.eventId))
      .then(() => getDocumentMapping(id))
      .then(data => {
        setWorking(false)
        props.setDocument(data)
      })
  }

  const handleChange = option => {
    setOption(option)
  }

  const handleOpenUser = (userId, availableToManager) => {
    if (!availableToManager) {
      return
    }

    dispatch(push(`/profile/${userId}/`))
  }

  return (
    <section className='unit unit--default bar'>
      <div className='settings-personal__form-row'>
        <div className='alert--warning-box'>
          <div className='alert alert--small alert--warning alert--block alert--center alert--top'>
            <p className='alert__text'>{t('Step3Description')}</p>
          </div>
        </div>
      </div>
      <div className='toolbox'>
        <div className='toolbox__cell'>
          <NewSelectSimple
            name='notification-type-select'
            options={options}
            value={option}
            style={{ width: '300px' }}
            onChange={handleChange}
          />
        </div>
        <div className='toolbox__cell'>
          <div className='toolbox__cell'>
            <Button.Save disabled={working} onClick={handleClick}>
              {t('SetNotification')}
            </Button.Save>
          </div>
        </div>
      </div>
      <div className={tableClass}>
        <div className='table__wrapper' style={{ tableLayout: 'fixed' }}>
          <Table.HeaderRow>
            <Table.HeaderCell
              title={t('DocumentMappingUserSort')}
              style={{ width: '50%' }}
            />
            <Table.HeaderCell
              title={t('DocumentMappingCountSort')}
              style={{ width: '50%' }}
            />
          </Table.HeaderRow>
          <>
            {usersToPages.map((item, index) => {
              const preview = get(item, ['user', 'avatar', 'preview'])
              const fullname = get(item, ['user', 'fullname'])
              const name = get(item, ['user', 'name'])
              const userId = get(item, ['user', 'id'])
              const secondName = get(item, ['user', 'second_name'])
              const userName = fullname || `${name} ${secondName}`
              const availableToManager = get(item, [
                'user',
                'available_to_manager',
              ])
              const { page_from: pageFrom, page_to: pageTo } = item
              const pages =
                pageFrom === pageTo ? pageFrom : `${pageFrom} - ${pageTo}`

              return (
                <Table.BodyRow
                  key={index}
                  id={userId}
                  handleClick={id => handleOpenUser(id, availableToManager)}
                >
                  <Table.BodyCell title={userName}>
                    <i className='table-item__user-pic'>
                      <img src={preview} alt='user' />
                    </i>
                  </Table.BodyCell>
                  <Table.BodyCell title={pages} />
                </Table.BodyRow>
              )
            })}
          </>
        </div>
      </div>
    </section>
  )
}

export default Step3
