// @flow

export const INITIAL_STAGE = 'init'
export const ERROR_REQUEST_STAGE = 'error'
export const SELECT_PROVIDER_STAGE = 'select'
export const GOOGLE_STAGE = 'google'
export const MICROSOFT_EXCHANGE_STAGE = 'exchange'
export const MICROSOFT_OUTLOOK_STAGE = 'outlook'
export const MICROSOFT_OFFICE_STAGE = 'office'
export const OTHER_STAGE = 'other'
