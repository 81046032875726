// @flow

import React from 'react'
import { useSelector } from 'react-redux'
import type { Node } from 'react'

import Widgets from './widgets'
// import WidgetMayKnow from '../../components/widgets/WidgetMayKnow'
import AddUkAddressWidget from '../../components/widgets/AddUkAddressWidget'
import { isMenuCollapsed, getUser } from '../../utils/commonSelectors'
import { isAdminUser } from '../../utils/utils'
import type { User } from '../../components/Auth/Login/Login.reducer'

type Props = {
  children: Node,
}

const UkWidgets = ({ children }: Props) => {
  const user: User = useSelector(getUser)
  const collapsed: boolean = useSelector(isMenuCollapsed)

  return (
    <Widgets collapsed={collapsed}>
      {children}
      {/* {isDwellerUser(user) ? <WidgetMayKnow /> : null} */}
      {isAdminUser(user) ? <AddUkAddressWidget /> : null}
    </Widgets>
  )
}

export default UkWidgets
