// @flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { take } from 'lodash-es'
import classnames from 'classnames'

import { getMessageText } from '../../containers/Mail/ThreadsList.utils'
import { getFormattedDate, getUserEmailName } from '../../utils/utils'
import NewTableBodyCell from '../NewTable/NewTableBodyCell'
import NewTableBodyRow from '../NewTable/NewTableBodyRow'
import Chip from '../Chip'
import ExtraActions from './ExtraActions'
import ThreadsListItemErrors from '../../containers/Mail/ThreadsListItemErrors/ThreadsListItemErrors'
import Checkbox from '../Checkbox'
import NewAvatar from '../NewAvatar'

import styles from './EmailsTableBodyRow.module.scss'
import Icon from '../Icon/Icon'
import { getActivity } from '../../core/api/api.pipeline'
import { patchThread } from '../../core/api/api.request'

import Loader from '../Loader'
import Lightbox from '../LightboxGallery'

import ThreadsListItemRequests from '../../containers/Mail/ThreadsListItemRequests'

const EmailsTableBodyRow = props => {
  const { thread, requestId, selectedItems, scope = 'request', object } = props

  const { t } = useTranslation('Mail')
  const [activeIndex, setActiveIndex] = useState(null)
  const lastMessage = thread.last_message
  const inboxUsername = getUserEmailName(thread.last_message.recipient)
  const lastMessageText = getMessageText(thread.last_message)
  const lastMessageCreated = getFormattedDate(thread.last_message.created)
  const title = thread.title
  const totalMsgCount = thread.total_msg_count
  const unviewedMsgCount = thread.unviewed_msg_count
  const has_pending_message = thread.has_pending_message
  const linked_requests = thread.linked_requests
  const [starred, setStarred] = useState(thread.starred)

  const handleClick = () => {
    props.setUuid(thread.uuid)
    props.setAudience(thread.audience)
    props.setSubject(thread.title)
  }

  const inboxItemClassName = classnames('inbox__item', {
    'inbox__item--unread': unviewedMsgCount > 0,
  })

  const titleStyle = unviewedMsgCount > 0 ? { fontWeight: 700 } : {}

  const cellProps = Object.assign(
    {},
    thread.notes || thread.has_error_message
      ? { contClass: styles.cont, className: styles.cell }
      : undefined
  )

  const handleChange = () => props.handleChange(thread.uuid)

  const toggleStarred = e => {
    e.stopPropagation()
    patchThread(requestId, thread.uuid, { starred: !starred })
    setStarred(!starred)
  }

  const handleClickActivity = id => {
    getActivity(id).then(props.setActivity)
  }

  const restActivityCount =
    thread.activities &&
    thread.activities.length > 3 &&
    thread.activities.length - 3

  const openPreview = (e, id) => {
    e.stopPropagation()
    let activeIndex = lastMessage.files.findIndex(f => f.id === id)
    setActiveIndex(activeIndex)
  }

  const closeLightbox = () => {
    setActiveIndex(null)
  }

  return (
    <>
      <NewTableBodyRow
        hovered={false}
        className={styles.row}
        onClick={handleClick}
      >
        <NewTableBodyCell
          title={inboxUsername}
          titleStyle={titleStyle}
          style={{ width: '25%', paddingLeft: '16px' }}
          {...cellProps}
        >
          <Checkbox
            style={{ marginRight: '8px' }}
            checked={selectedItems.includes(thread.uuid)}
            onChange={handleChange}
          />
          <i className='table-item__star' onClick={toggleStarred}>
            <Icon id={starred ? 'starFilled' : 'newStar'} />
          </i>
          <NewAvatar src={thread.last_message.recipient.avatar} size='small' />
        </NewTableBodyCell>
        <NewTableBodyCell style={{ width: '55%' }}>
          <div style={{ maxWidth: '100%' }}>
            <div className='maillist' style={{ maxWidth: '100%' }}>
              <div className={inboxItemClassName} style={{ borderBottom: 0 }}>
                <div
                  className='inbox__cell inbox__cell--04'
                  style={{ minWidth: 0, maxWidth: '100%' }}
                  title={title}
                >
                  {!!title && <a className='inbox__subject'>{title}</a>}
                  <a
                    className='inbox__text'
                    style={{ minWidth: 0, maxWidth: '100%' }}
                  >
                    {lastMessageText ? lastMessageText : t('NoMail')}
                  </a>
                  {totalMsgCount > 0 ? (
                    <a className='inbox__counter'>
                      &nbsp;(
                      {totalMsgCount})
                    </a>
                  ) : null}
                </div>
              </div>
            </div>
            <div
              style={{ paddingLeft: '25px', display: 'flex', flexWrap: 'wrap' }}
              onClick={e => {
                e.stopPropagation()
              }}
            >
              {lastMessage !== null &&
                take(lastMessage.files, 3).map(file => (
                  <Chip
                    key={file.id}
                    file={file}
                    onClick={e => openPreview(e, file.id)}
                  />
                ))}
              {lastMessage.files.length > 3 && (
                <Chip text={`+${lastMessage.files.length - 3}`} />
              )}
              <Lightbox
                newGallery
                scope={scope}
                object={object}
                activeIndex={activeIndex}
                images={lastMessage.files}
                onClose={closeLightbox}
              />
              <ThreadsListItemRequests
                requests={linked_requests}
                viewedCount={1}
              />
            </div>
          </div>
        </NewTableBodyCell>
        <NewTableBodyCell
          title={has_pending_message ? t('Sending') : lastMessageCreated}
          style={{
            width: '20%',
            color: has_pending_message ? '#888' : 'inherit',
          }}
        >
          {has_pending_message && <Loader type='small' text={false} />}
          <ExtraActions
            openRemoveModal={props.openRemoveModal}
            unviewedMsgCount={unviewedMsgCount}
            setModal={props.setModal}
            setActionModal={props.setActionModal}
            uuid={thread.uuid}
            activities={thread.activities}
            audience={thread.audience}
            notes={thread.notes}
            requestId={requestId}
            setLoading={props.setLoading}
            openAudienceModal={props.openAudienceModal}
            openNotesModal={props.openNotesModal}
            openActivitiesModal={props.openActivitiesModal}
            openMoveModal={props.openMoveModal}
          />
        </NewTableBodyCell>
      </NewTableBodyRow>
      {thread.activities && !!thread.activities.length && (
        <NewTableBodyRow hovered={false} className={styles.row}>
          <NewTableBodyCell colSpan={3}>
            <div className={styles.activities}>
              {take(thread.activities, 3).map(activity => (
                <Chip
                  key={activity.id}
                  text={activity.title}
                  color='gray'
                  onClick={() => handleClickActivity(activity.id)}
                >
                  <Icon id='threadActivity' />
                </Chip>
              ))}
              {!!restActivityCount && <span>+{restActivityCount}</span>}
            </div>
          </NewTableBodyCell>
        </NewTableBodyRow>
      )}
      {thread.notes && (
        <NewTableBodyRow hovered={false} className={styles.row}>
          <NewTableBodyCell colSpan={3}>
            <div className={styles.notes}>
              <span title={thread.notes}>{thread.notes}</span>
            </div>
          </NewTableBodyCell>
        </NewTableBodyRow>
      )}
      {thread.has_error_message && (
        <NewTableBodyRow hovered={false}>
          <NewTableBodyCell colSpan={3}>
            <ThreadsListItemErrors hasError />
          </NewTableBodyCell>
        </NewTableBodyRow>
      )}
    </>
  )
}

export default EmailsTableBodyRow
