// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../../../components/Button'
import { formatDateTime } from '../../../utils/utils'

type Props = {
  configIsExists: boolean,
  formIsDirty: boolean,
  integrationAvailable: boolean,
  syncFinishedTime?: string,
  syncInProgress: boolean,
  working: boolean,
}

const DomusActions = (props: Props) => {
  const {
    formIsDirty,
    integrationAvailable,
    syncInProgress,
    syncFinishedTime,
    configIsExists,
    working,
  } = props

  const { t } = useTranslation('Integrations')

  const text = !!syncFinishedTime && (
    <div className='submit--additional'>
      <span className='settings-personal__form-label'>
        {t('DomusWasSynced')}:{' '}
      </span>
      <span className='input'>{formatDateTime(syncFinishedTime)}</span>
    </div>
  )

  if (syncInProgress) {
    return (
      <div className='settings-personal__submit'>
        <Button.Save working={working} onClick={props.handleCheckStatus}>
          {t('CheckDomusStatus')}
        </Button.Save>
        {text}
      </div>
    )
  }

  if (configIsExists && !formIsDirty) {
    return (
      <div className='settings-personal__submit'>
        <Button.Save
          working={working}
          disabled={!integrationAvailable}
          onClick={props.handleIntegrate}
        >
          {t('StartDomusSync')}
        </Button.Save>
        {text}
      </div>
    )
  }

  return (
    <div className='settings-personal__submit'>
      <Button.Save type='submit' working={working} disabled={!formIsDirty}>
        {t('Common:Save')}
      </Button.Save>
      <Button.Cancel
        type='button'
        disabled={!formIsDirty}
        onClick={props.handleReset}
      >
        {t('Common:Cancel')}
      </Button.Cancel>
      {text}
    </div>
  )
}

export default DomusActions
