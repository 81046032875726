// @flow

import React, { useEffect, useMemo, useState, useRef } from 'react'
import type { Node } from 'react'
import Button from '../Button'
import classnames from 'classnames'
import Scrollbar from '../Scrollbar'
import OutsideClick from 'react-onclickout'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

type Props = {
  filterAfter?: number,
  groups: Array<Object>,
  ignoreBlock?: number,
  onChange: Object => void,
  pageSize: number,
  placeholder: ?string,
  t: string => string,
  value: ?number,
}

const SelectBlock = (props: Props): Node => {
  const {
    filterAfter = 2,
    labelKey = 'title',
    pageSize = 5,
    placeholder = 'selectBlock',
    valueKey = 'id',
    onChange,
    value,
    ignoreBlock,
    t,
    groups,
  } = props

  const [isOpen, setIsOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const menuRef = useRef()
  const inputRef = useRef()
  const options = useMemo(() => {
    return groups.reduce((acc, val) => {
      const group = { id: val.id, title: val.title, isGroup: true }

      return [...acc, group, ...val.blocks]
    }, [])
  }, [groups])
  useEffect(() => {
    if (isOpen) {
      inputRef?.current?.focus?.()
    } else if (inputValue) {
      setInputValue('')
    }
  }, [isOpen])

  const onInputBlur = () => {
    const ignore =
      '.scrollbar__thumb:active, .dropdown__search-input input:active'

    if (menuRef.current && menuRef.current.querySelector(ignore)) {
      menuRef.current.focus()

      return
    }

    inputRef?.current?.focus?.()
  }

  const getLabel = opt => {
    return opt[labelKey] || t('emptyBlockTitle')
  }

  const getValue = () => options.find(o => o.id === value)

  const setValue = value => onChange(value)

  const getBtnValueText = () => {
    return value ? getLabel(getValue()) : t('selectBlock')
  }

  const isSelected = opt => {
    const value = getValue()

    return value ? value[valueKey] === opt[valueKey] : false
  }

  const hideSelect = () => setIsOpen(false)

  const selectValue = opt => {
    setIsOpen(false)
    setInputValue('')
    setValue(opt)
  }

  const filterOptions = () => {
    const filtered = options.filter(o => {
      const title = (o.title || '').toLowerCase()

      return o.isGroup || title.includes(inputValue.toLowerCase())
    })

    return filtered.filter((o, i) => {
      if (o.isGroup) {
        if (i === filtered.length - 1) {
          return false
        }

        return !filtered[i + 1].isGroup
      }

      return true
    })
  }

  const handleChangeSearch = e => {
    setInputValue(e.currentTarget.value)
  }

  const onClickButton = () => {
    setIsOpen(true)
    inputRef?.current?.focus?.()
  }
  const renderInput = () => (
    <Button.Select
      {...props}
      text={getBtnValueText()}
      value={getValue()}
      ref={inputRef}
      onBlur={onInputBlur}
      onClick={onClickButton}
    />
  )
  const optionsList = useMemo(() => {
    return inputValue.length > filterAfter - 1 ? filterOptions() : options
  }, [inputValue, options])

  const renderOption = (opt: Object) => {
    const className = classnames('dropdown__list-item', {
      'dropdown__list-item--checked': isSelected(opt),
      'request-constructor__link--select-label': opt.isGroup,
      'request-constructor__link--select-item': !opt.isGroup,
      'request-constructor__link--select-item--disabled':
        !opt.isGroup && opt.id === ignoreBlock,
    })

    const optionKey = opt.isGroup
      ? `option-group-${opt.id}`
      : `option-block-${opt.id}`
    const label = getLabel(opt)

    const onClick =
      opt.isGroup || opt.id === ignoreBlock ? undefined : () => selectValue(opt)

    return (
      <li className={className} key={optionKey} onClick={onClick}>
        <span className='dropdown__list-item-text' title={label}>
          {label}
        </span>
      </li>
    )
  }

  const renderMenu = () => {
    return (
      <div
        className='dropdown dropdown--default -dropdown--up-right dropdown--open'
        ref={menuRef}
      >
        <div className='dropdown__search'>
          <div className='dropdown__search-input'>
            <input
              type='text'
              value={inputValue}
              placeholder={t(placeholder)}
              ref={inputRef}
              onChange={handleChangeSearch}
            />
          </div>
        </div>
        <div>
          <Scrollbar
            items={optionsList}
            listElements={optionsList.map(renderOption)}
            listClassName='dropdown__list dropdown__list--default dropdown__list--checklist'
            pageSize={pageSize}
          />
        </div>
      </div>
    )
  }

  return (
    <div className='select-user bar__userbar'>
      <div>
        <OutsideClick onClickOut={hideSelect}>
          {renderInput()}
          {isOpen && renderMenu()}
        </OutsideClick>
      </div>
    </div>
  )
}

export default compose(
  withTranslation('RequestConstructor'),
  connect(state => ({
    groups: state.requestConstructor.data.groups,
  }))
)(SelectBlock)
