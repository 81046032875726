// @flow

import { all, put, call, fork, takeLatest } from 'redux-saga/effects'

import api from '../../core/api'
import { serverError } from '../Layout/Layout.actions'
import * as actionTypes from './UserSuggest.actionTypes'
import { getUserId } from '../../utils/utils'

function* watchLoadUsers() {
  yield takeLatest(actionTypes.USERSUGGEST_LOAD, loadUsers)
}

function* watchLoadProviders() {
  yield takeLatest(actionTypes.PROVIDERSUGGEST_LOAD, loadProviders)
}

function* loadProviders(action) {
  try {
    const { key, value, buildingId, categoryId } = action

    const args = categoryId
      ? [api.marketplace.getRequestProviders, buildingId, categoryId]
      : [
          api.marketplace.getBuildingProviders,
          buildingId,
          {
            provider_name: value,
          },
        ]

    const response = yield call(...args)

    let suggestions = []

    if (categoryId) {
      suggestions = response.results.objects.map(p => ({
        ...p,
        id: p.id,
      }))
    } else {
      suggestions = response.providers.map(p => ({
        ...p,
        id: p.id,
      }))
    }

    yield put({
      type: actionTypes.USERSUGGEST_LOADED,
      suggestions,
      value,
      key,
    })
  } catch (error) {
    yield put(serverError(error))
  }
}

function* loadUsers(action) {
  try {
    const { params, key, value, onlyManagers, addChatMembers } = action

    const newParams = { ...params }

    if (onlyManagers) {
      newParams.group = ['manager', 'admin']
    }

    const getApi = addChatMembers
      ? api.profile.availableChatMembers
      : api.profile.getAdminAndManagerOptionList

    const response = yield call(getApi, newParams)

    let suggestions = []

    suggestions = response.results.objects.map(p => ({
      ...p,
      id: getUserId(p),
      owner: getUserId(p),
    }))

    yield put({
      type: actionTypes.USERSUGGEST_LOADED,
      suggestions,
      value,
      key,
    })
  } catch (error) {
    yield put(serverError(error))
  }
}

export default function* watch() {
  yield all([fork(watchLoadUsers), fork(watchLoadProviders)])
}
