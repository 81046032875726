// @flow

import React from 'react'
import classnames from 'classnames'
import type { Node } from 'react'

import SelectRegion from '../../../SelectRegion'
import Row from '../Row'

import styles from './Select.module.scss'

type Props = {
  disabled?: boolean,
  error: string,
  name: string,
  onBlur: Object => void,
  onChange: Object => void,
  params: string,
  placeholder: string,
  required?: boolean,
  title: string,
  value: Object,
}

const SelectField = (props: Props): Node => {
  const {
    title,
    placeholder,
    value,
    name,
    error,
    params,
    disabled,
    required,
  } = props

  const buttonClass = classnames({
    [styles.button]: !error,
    [styles.placeholder]: !value,
  })

  return (
    <Row title={title} required={required} error={error}>
      <SelectRegion
        disabled={disabled}
        buttonClass={buttonClass}
        params={params}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={props.onChange}
        onBlur={props.onBlur}
      />
    </Row>
  )
}

export default SelectField
