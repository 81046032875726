// flow

import React from 'react'

import NewMailThread from '../../containers/Mail/MailThread/NewMailThread'

const MailMessage = props => {
  return <NewMailThread {...props} isSingle />
}

export default MailMessage
