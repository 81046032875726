// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import update from 'immutability-helper'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { compose } from 'redux'
import type { Match } from 'react-router-dom'

import Block from '../RequestConstructorBlock'
import Groups from './Groups'
import RequestConstructorHeader from '../../components/RequestConstructor/RequestConstructorHeader'
import Loader from '../../components/Loader'
import * as actions from './RequestConstructor.actionTypes'
import ErrorPopup from '../../components/modals/ErrorPopup'
import Modal from '../../components/Modal'
import NewWizard from '../../components/NewWizard'

type Props = {
  data: Object,
  dispatch: Object => void,
  error: boolean,
  initiated: boolean,
  match: Match,
  t: string => string,
  workflowTypes: Array<string>,
  working: boolean,
  workingGroups: boolean,
}
type State = {
  activeBlock: ?{
    group: number,
    id: number,
    index: number,
  },
  isModalOpen: boolean,
  isWizardOpen: boolean,
}

class RequestConstructor extends Component<Props, State> {
  state = {
    activeBlock: null,
    isModalOpen: false,
    isWizardOpen: false,
  }

  componentDidMount() {
    this.props.dispatch({
      type: actions.REQUEST_CONSTRUCTOR_INITIATE,
      workflowId: this.props.match.params.workflowId,
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.errorText && !prevProps.errorText) {
      this.setState({ isModalOpen: true })

      return
    }

    if (this.props.error) {
      return
    }

    const { workingGroups, data } = prevProps
    const { activeBlock } = this.state

    const wasUpdated = workingGroups && !this.props.workingGroups
    const { groups } = this.props.data

    if (activeBlock && wasUpdated) {
      const group = groups.find(g => g.id === activeBlock.group)

      if (!group) {
        this.setState({ activeBlock: null })
      }
    }

    if (data.row_is_active && wasUpdated) {
      if (!groups.find(g => !!g.blocks.length)) {
        this.toggleActive()
      }
    }
  }

  componentWillUnmount() {
    this.props.dispatch({ type: actions.REQUEST_CONSTRUCTOR_RESET })
  }

  getOptions = () =>
    this.props.workflowTypes.map(item => ({ label: item, value: item }))

  startTest = () => {
    this.setState({ isWizardOpen: true })
  }

  handleClose = () => {
    this.props.dispatch({ type: actions.REQUEST_CONSTRUCTOR_CLEAR_ERROR })
    this.setState({ isModalOpen: false })
  }

  handleCloseWizard = () => {
    this.setState({ isWizardOpen: false })
  }

  handleOpenWizard = () => {
    this.setState({ isWizardOpen: true })
  }

  startPublish = () => {
    const {
      data: { id },
    } = this.props

    this.props.dispatch({ type: actions.PUBLISH_WORKFLOW, workflow: id })
  }

  showBlock = (block: Object) => {
    this.setState({ activeBlock: block })
  }

  toggleActive = () => {
    const { data } = this.props

    this.props.dispatch({
      type: actions.REQUEST_CONSTRUCTOR_TOGGLE,
      id: data.id,
    })
  }

  removeBlock = (info: Object) => {
    const {
      data: { groups },
    } = this.props
    const group = groups.findIndex(g => g.id === info.group)
    const blocks = groups[group].blocks.filter(b => b.id !== info.id)
    this.updateGroupBlocks(group, blocks)
    this.setState({ activeBlock: null })
  }

  updateBlock = (info: Object) => {
    const {
      data: { groups },
    } = this.props
    const group = groups.findIndex(g => g.id === info.group)
    const block = groups[group].blocks.findIndex(b => b.id === info.id)

    const blocks = update(groups[group].blocks, {
      [block]: {
        $set: { ...groups[group].blocks[block], title: info.title },
      },
    })
    this.updateGroupBlocks(group, blocks)
  }

  updateGroupBlocks = (index, blocks) => {
    const {
      dispatch,
      data: { groups, row_is_active: isActive },
    } = this.props

    const updated = update(groups, {
      [index]: {
        $set: { ...groups[index], blocks },
      },
    })

    dispatch({
      type: actions.REQUEST_CONSTRUCTOR_GROUPS_WERE_UPDATED,
      groups: updated,
    })

    if (isActive && !updated.find(g => !!g.blocks.length)) {
      this.toggleActive()
    }
  }

  render() {
    if (!this.props.initiated) {
      return (
        <div className='content__col'>
          <Loader text={false} type='big' />
        </div>
      )
    }

    const { data, working, t, errorText } = this.props

    const { activeBlock, isModalOpen, isWizardOpen } = this.state
    const disabledActions = !data.groups.find(g => g.blocks.length)

    return (
      <DndProvider backend={HTML5Backend}>
        <div
          className='content__col request-constructor'
          style={{ paddingTop: '20px' }}
        >
          <RequestConstructorHeader
            active={data.row_is_active}
            changed={data.row_is_changed}
            date={data.updated}
            workflowType={data.type}
            disabledActions={disabledActions}
            title={data.title}
            working={working}
            workflowId={data.id}
            onStartTest={this.startTest}
            onToggleActive={this.toggleActive}
            onStartPublish={this.startPublish}
          />
          <section className='unit unit--default request-constructor--body'>
            <Groups
              activeBlock={activeBlock}
              workflow={data.id}
              onShowBlock={this.showBlock}
            />
            {!data.groups.length ? (
              <div className='request-constructor__block request-constructor__block--hint'>
                <span>{t('createGroupAndBlock')}</span>
              </div>
            ) : (
              <Block
                id={activeBlock ? activeBlock.id : null}
                justCreated={activeBlock ? activeBlock.justCreated : false}
                position={activeBlock ? activeBlock.position : null}
                onRemove={this.removeBlock}
                onUpdate={this.updateBlock}
              />
            )}
          </section>
          <Modal isOpen={isModalOpen} className='Modal__Bootstrap modal-dialog'>
            <ErrorPopup
              error={errorText}
              title={t('WorkflowError')}
              confirm={t('Close')}
              onClose={this.handleClose}
              onOk={this.handleClose}
            />
          </Modal>
          {isWizardOpen && (
            <NewWizard id={data.id} onClose={this.handleCloseWizard} />
          )}
        </div>
      </DndProvider>
    )
  }
}

export default compose(
  withTranslation('RequestConstructor'),
  connect(state => ({
    ...state.requestConstructor,
    workflowTypes: state.init.workflow_types,
  }))
)(RequestConstructor)
