// @flow

import React, { Fragment } from 'react'
import { invokeMap } from 'lodash-es'
import classnames from 'classnames'

import MenuListItem from '../MenuListItem'
import MenuDivider from '../MenuDivider'

import styles from './MenuList.module.scss'

const MenuList = props => {
  const { collapsed, hasSub, lastItemName, mainPathName, menuItems } = props
  const listClass = classnames(styles.list, {
    [styles.hasSub]: hasSub,
  })

  return (
    <ul className={listClass}>
      {invokeMap(menuItems, 'map', (item, index, arr) => {
        const islastGroupKey = arr.length - 1 === index
        const isLastItem = item.name === lastItemName
        const renderDivider = islastGroupKey && !isLastItem

        return (
          <Fragment key={item.name}>
            <MenuListItem
              {...item}
              collapsed={collapsed}
              hasSub={hasSub}
              mainPathName={mainPathName}
              onOpenMenu={props.onOpenMenu}
            />
            {renderDivider && (
              <MenuDivider collapsed={collapsed} hasSub={hasSub} />
            )}
          </Fragment>
        )
      })}
    </ul>
  )
}

export default MenuList
