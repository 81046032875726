// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { push } from 'connected-react-router'
import { compose } from 'redux'

import { Header, CellsBar, Cell, SimpleBlock } from '../Block'
import Avatar from '../AvatarEditable'
import Date from '../Date'
import { EntityIcon } from '../Icon'
import ManagersBar from './ManagersBar'
import { getUserId, isDeLocale, isStaffUser } from '../../utils/utils'
import { getLanguageCode } from '../../utils/commonSelectors'
import Button from '../Button'
import ExternalIdModal from './ExternalIdModal'
import Label from '../Label'

import styles from './BuildingInfo.module.scss'
import PropertyOwnerBar from './PropertyOwnerBar'

import { MANAGERS_LIMIT } from '../../constants'

type Props = {
  data: Object,
  dispatch: Object => void,
  error?: ?(Object | boolean | string),
  history: Object,
  languageCode: string,
  location: Object,
  noFlats: boolean,
  onSave: Object => void,
  t: string => string,
  working: boolean,
}
type State = {
  editingAvatar: boolean,
  editingManagers: boolean,
  isExternalIdEdit: boolean,
  managers: Array<number>,
  savingManagers: boolean,
}

class BuildingInfo extends Component<Props, State> {
  state = {
    editingAvatar: false,
    editingManagers: false,
    editingOwners: false,
    managers: this.getCurrentOwnersIds(),
    propertyOwners: this.getCurrentPropertyOwnersIds(),
    savingManagers: false,
    savingOwners: false,
    isExternalIdEdit: false,
  }

  componentDidUpdate(prev: Props) {
    const { error, working } = this.props

    if (!working && prev.working) {
      const { savingManagers, savingOwners } = this.state

      if (savingManagers) {
        this.setState({ savingManagers: false })

        if (!error) {
          this.setState({
            editingManagers: false,
            managers: this.getCurrentOwnersIds(),
          })
        }
      }

      if (savingOwners) {
        this.setState({ savingOwners: false })

        if (!error) {
          this.setState({
            editingOwners: false,
            propertyOwners: this.getCurrentPropertyOwnersIds(),
          })
        }
      }
    }
  }

  getCurrentOwnersIds() {
    const { data } = this.props

    if (!data) {
      return []
    }

    const {
      data: { owner_objs: owners },
    } = this.props

    return owners.map(getUserId)
  }

  getCurrentPropertyOwnersIds() {
    const { data } = this.props

    if (!data) {
      return []
    }

    const {
      data: { property_owner_objs: owners },
    } = this.props

    return owners.map(getUserId)
  }
  getAvatarBgImage = () => {
    const { data = {} } = this.props
    const { avatar_obj: avatar = {} } = data
    const url = avatar.preview || avatar.origin

    return url ? `url(${url})` : ''
  }

  getGoBackCallback = () => {
    const { location, history } = this.props
    const locationKey = location.key
    const historyLength = history.length

    if (!locationKey || historyLength === 1) {
      return () => this.props.dispatch(push('/buildings'))
    }

    return null
  }

  startEditAvatar = () => {
    this.setState({ editingAvatar: true })
  }

  finishEditAvatar = (avatar?: Object) => {
    if (avatar) {
      this.props.onSave({ avatar })
    }

    this.setState({ editingAvatar: false })
  }

  editManagers = () => {
    this.setState({ editingManagers: true })
  }

  editOwners = () => {
    this.setState({ editingOwners: true })
  }

  cancelManagers = () => {
    this.setState({
      editingManagers: false,
      managers: this.getCurrentOwnersIds(),
    })
  }

  cancelOwners = () => {
    this.setState({
      editingOwners: false,
      propertyOwners: this.getCurrentPropertyOwnersIds(),
    })
  }
  saveManagers = () => {
    const { managers } = this.state

    this.props.onSave({
      owners: JSON.stringify(managers),
    })
    this.setState({ savingManagers: true })
  }

  savePropertyOwners = () => {
    const { propertyOwners } = this.state

    this.props.onSave({
      property_owners: propertyOwners,
    })
    this.setState({ savingOwners: true })
  }

  updateManagers = (managers: Array<Object>) => {
    this.setState({ managers: managers.map(manager => manager.owner) })
  }

  updateOwners = (propertyOwners: Array<Object>) => {
    this.setState({ propertyOwners: propertyOwners.map(owner => owner.owner) })
  }

  renderInfo = () => {
    const {
      noFlats,
      user,
      languageCode,
      data = {
        dweller_count: 0,
        flat_count: 0,
        construction_date: null,
      },
    } = this.props

    const handleExternalId = e => {
      e.stopPropagation()

      this.setState(state => ({ isExternalIdEdit: !state.isExternalIdEdit }))
    }

    return (
      <CellsBar>
        <Cell title={this.props.t('Created')}>
          <Date
            date={data.created}
            dateFormat={`D${isDeLocale(languageCode) ? '.' : ''} MMM YYYY`}
          />
        </Cell>
        <Cell title={this.props.t('InfoDwellers')}>
          {data.dweller_count || 0}
        </Cell>
        {!noFlats && (
          <Cell title={this.props.t('InfoFlats')}>{data.flat_count || 0}</Cell>
        )}
        {user && isStaffUser(user) && (
          <Cell
            title={this.props.t('ExternalId')}
            className={styles.externalId}
          >
            <div className={styles.externalIdText}>
              <span
                className={styles.externalIdEllipse}
                title={data.external_id}
              >
                {data.external_id || this.props.t('ExternalIdNotDefined')}
              </span>
              {data.permissions?.can_edit && (
                <Button.Edit onClick={handleExternalId} />
              )}
            </div>
          </Cell>
        )}
      </CellsBar>
    )
  }

  handleClose = () => this.setState({ isExternalIdEdit: false })

  updateExternalId = externalId => {
    this.props.onSave({ external_id: externalId })
  }

  render() {
    const { working, data } = this.props
    const { editingAvatar, editingManagers, isExternalIdEdit, editingOwners } =
      this.state
    const isWorking = working || editingAvatar

    if (!data) {
      return (
        <SimpleBlock working={isWorking}>
          <Header
            header
            goBack={this.getGoBackCallback()}
            icon={<EntityIcon large id='building' />}
          >
            {this.props.t('AddressCreateLabel')}
          </Header>

          {this.renderInfo()}
        </SimpleBlock>
      )
    }

    const { address_obj: address, avatar_obj: avatar, permissions = {} } = data
    const { can_edit: canEdit } = permissions
    const avatarBgImage = this.getAvatarBgImage()
    const maxAddressFieldLength = 37
    const buildingTitle = (address && address.value) || ''
    const textTitle =
      buildingTitle.length > maxAddressFieldLength ? buildingTitle : null

    return (
      <SimpleBlock working={isWorking}>
        <Header header goBack={this.getGoBackCallback()} textTitle={textTitle}>
          {canEdit ? (
            <div icon='true' className='roundbutton--subtitle'>
              <Avatar
                image={avatar}
                title={this.props.t('AvatarLoad')}
                style={{ border: '1px solid rgb(224, 224, 224)' }}
                onStartEdit={this.startEditAvatar}
                onFinishEdit={this.finishEditAvatar}
              />
            </div>
          ) : (
            <div
              icon='true'
              className='mprofile-view-user__userpic mprofile-view-user__userpic-building'
              style={{ backgroundImage: avatarBgImage }}
            />
          )}

          {address ? address.value : this.props.t('AddressCreateLabel')}
          {data.soft_archived && (
            <Label
              text={this.props.t('Archived')}
              style={{ marginLeft: '8px' }}
              type='outlined'
            />
          )}
        </Header>

        {this.renderInfo()}

        <ManagersBar
          data={data}
          working={working}
          editingManagers={editingManagers}
          saveManagers={this.saveManagers}
          editManagers={this.editManagers}
          cancelManagers={this.cancelManagers}
          updateManagers={this.updateManagers}
          max={MANAGERS_LIMIT}
        />
        <PropertyOwnerBar
          owner
          data={data}
          working={working}
          editingOwners={editingOwners}
          savePropertyOwners={this.savePropertyOwners}
          editOwners={this.editOwners}
          cancelOwners={this.cancelOwners}
          updateOwners={this.updateOwners}
        />
        {isExternalIdEdit && (
          <ExternalIdModal
            externalId={this.props.data.external_id}
            updateExternalId={this.updateExternalId}
            onClose={this.handleClose}
          />
        )}
      </SimpleBlock>
    )
  }
}

const mapStateToProps = state => ({
  languageCode: getLanguageCode(state),
})

export default compose(
  withTranslation('Building'),
  connect(mapStateToProps)
)(BuildingInfo)
