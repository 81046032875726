// @flow

import React from 'react'

import NewTable from '../../../../components/NewTable'
import AllProvidersTableHeader from './AllProvidersTableHeader'
import AllProvidersTableBody from './AllProvidersTableBody'

const AllProvidersTable = props => {
  const { loading, providers, selectedItems } = props

  return (
    <NewTable loading={loading}>
      <AllProvidersTableHeader />
      <AllProvidersTableBody
        setBuildingModal={props.setBuildingModal}
        setCategoryModal={props.setCategoryModal}
        providers={providers}
        selectedItems={selectedItems}
        removeProviders={props.removeProviders}
        toggleProvider={props.toggleProvider}
        updateProviders={props.updateProviders}
      />
    </NewTable>
  )
}

export default AllProvidersTable
