// @flow

import React from 'react'
import type { Node } from 'react'

import styles from './Notes.module.scss'

type Props = { notes: ?string }

const Notes = (props: Props): Node => {
  const { notes } = props

  if (!notes) {
    return null
  }

  return <div className={styles.notes}>{notes}</div>
}

export default Notes
