// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'
import classnames from 'classnames'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'

import Cards from '../RequestConstructorCards'
import ConfirmationPopup from '../../components/modals/ConfirmationPopup'
import Input from '../../components/Input'
import Loader from '../../components/Loader'
import Modal from '../../components/Modal'
import * as actions from './RequestConstructorBlock.actionTypes'
import Lightbox from '../../components/LightboxGallery'

type Props = {
  data: Object,
  dispatch: Object => void,
  error: boolean,
  id: ?number,
  initiated: boolean,
  initiatedCards: boolean,
  justCreated?: ?boolean,
  onRemove: number => void,
  onUpdate: Object => void,
  position: ?number,
  removed: boolean,
  t: (string, ?Object) => string,
  working: boolean,
  workingCards: boolean,
}
type State = {
  modal: boolean,
}

class RequestConstructorBlock extends Component<Props, State> {
  state = {
    modal: false,
  }

  componentDidMount() {
    const { id } = this.props

    if (id) {
      this.load(id)
    }
  }

  componentDidUpdate(prevProps) {
    const { id, data, working, initiated, removed } = prevProps

    if (this.props.id && this.props.id !== id) {
      this.load(this.props.id)

      return
    }

    if (!this.props.working && working && initiated && !this.props.error) {
      this.props.onUpdate(this.props.data)

      return
    }

    if (!removed && this.props.removed) {
      this.props.onRemove(data)
      this.closeRemoving()
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props
    dispatch({ type: actions.REQUEST_CONSTRUCTOR_BLOCK_RESET })
  }

  load = id => {
    const { dispatch } = this.props
    dispatch({ type: actions.REQUEST_CONSTRUCTOR_INITIATE_BLOCK, id })
  }

  confirmRemoving = () => {
    this.setState({ modal: true })
  }

  closeRemoving = () => {
    this.setState({ modal: false })
  }

  remove = () => {
    const { dispatch, id } = this.props
    dispatch({ type: actions.REQUEST_CONSTRUCTOR_REMOVE_BLOCK, id })
  }

  rename = title => {
    const {
      dispatch,
      data: { group, id },
      position,
    } = this.props
    dispatch({
      type: actions.REQUEST_CONSTRUCTOR_RENAME_BLOCK,
      id,
      params: { group, title, position },
    })
  }

  renderConfirmation = () => {
    const {
      data: { title },
      t,
    } = this.props

    return (
      <Modal
        isOpen
        contentLabel=''
        className='Modal__Bootstrap modal-dialog'
        onRequestClose={this.closeRemoving}
      >
        <ConfirmationPopup
          title={t('removeBlockTitle')}
          text={t('removeBlockText', { title: title ? `"${title}" ` : '' })}
          confirm={t('Common:Delete')}
          cancel={t('Common:Cancel')}
          onClose={this.closeRemoving}
          onOk={this.remove}
        />
      </Modal>
    )
  }

  setFiles = items => {
    this.setState({ files: [...items] })
  }

  setFileIndex = ind => {
    this.setState({ activeIndex: ind })
  }

  closeLightbox = () => {
    this.setFiles([])
    this.setState({ activeIndex: null })
  }

  render() {
    const { initiated, initiatedCards, id } = this.props

    if (!id) {
      return <div className='request-constructor__block' />
    }

    const { data, working, workingCards, justCreated, t } = this.props
    const { modal } = this.state
    const blockClass = classnames('request-constructor__block', {
      'working-overlay': initiated && (working || workingCards),
    })

    return (
      <div className={blockClass} style={{ minHeight: 0 }}>
        {initiatedCards && initiated ? (
          <Input.Edit
            editing={justCreated}
            value={data.title}
            working={working}
            placeholder={t('emptyBlockTitle')}
            onRemove={this.confirmRemoving}
            onSave={this.rename}
          />
        ) : (
          <Loader text={false} type='big' />
        )}

        {initiated && (
          <Cards
            block={id}
            setFiles={this.setFiles}
            setFileIndex={this.setFileIndex}
          />
        )}
        {modal && this.renderConfirmation()}
        <Lightbox
          newGallery
          activeIndex={this.state.activeIndex}
          images={this.state.files}
          scope='tree'
          onClose={this.closeLightbox}
        />
      </div>
    )
  }
}

export default compose(
  withTranslation('RequestConstructor'),
  connect(state => ({
    ...state.requestConstructorBlock,
    initiatedCards: state.requestConstructorCards.initiated,
    workingCards:
      state.requestConstructorCards.working ||
      state.requestConstructorCards.updatingId ||
      state.requestConstructorCards.removingId,
  }))
)(RequestConstructorBlock)
