// @flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import classnames from 'classnames'
import { map, isEmpty } from 'lodash-es'
import { useLocation } from 'react-router-dom'
import { push } from 'connected-react-router'
import { useDispatch } from 'react-redux'
import { omit } from 'lodash-es'

import Button from '../../Button'
import {
  getCurrentFilters,
  formatPathname,
  getNewQueryString,
} from '../../../utils/routing'
import SelectRole from '../../Select/SelectTableCell'
import { getShortDateFormat } from '../../../utils/utils'
import Table from '../../Table'
import Checkbox from '../../Checkbox'
import { setContractData } from '../../../core/api/api.flat'
import { USER_GROUPS } from '../../../constants'
import ContractPopup from './ContractPopup'
import ContractDateCalendar from '../../Flats/ContractDateCalendar'

import styles from './ProfileList.module.scss'

const ProfileListTableBodyRow = props => {
  const { t } = useTranslation('FlatDwellers')
  const statuses = useSelector(state => state.init.user_status)
  const location = useLocation()
  const dispatch = useDispatch()

  const CONTRACT_END = 'contract_end'
  const CONTRACT_START = 'contract_start'
  const {
    profile: { id, avatar, role, fullname, status, is_available },
    archived,
    inhabitancyId,
    selected,
    permissions: {
      can_change_end_date: canChangeEndDate,
      can_change_contract_id: canChangeContractId,
      can_change_purchase_contract_number: canChangePurchaseContractNumber,
      can_change_start_date: canChangeStartDate,
      can_change_duration: canChangeDuration,
    },
    canChangeUserRole,
    contractId,
    purchaseContractNumber,
    duration,
    contractStart,
    contractEnd,
    isDweller,
  } = props

  const [contractEdit, setContractEdit] = useState({})
  const [contractDate, setContractDate] = useState({
    name: '',
  })

  const updateContractDate = ({ value, name }) => {
    setContractData(inhabitancyId, {
      [name]: value ? value.format('YYYY-MM-DD') : null,
    }).then(data => {
      props.updateProfiles(data)

      const filters = getCurrentFilters(location)

      if (filters.contract_end_gte || filters.contract_end_lte) {
        const search = getNewQueryString({
          ...omit(filters, ['contract_end_gte', 'contract_end_lte']),
        })

        const pathname = formatPathname(location.pathname)

        dispatch(push(`/${pathname}/?${search}`))
      }
    })
  }

  const updateContract = contract => {
    setContractData(inhabitancyId, { ...contract }).then(data => {
      return props.updateProfiles(data)
    })
  }

  const handleContractEnd = e => {
    e.stopPropagation()

    if (contractDate.name !== CONTRACT_END) {
      setContractDate({
        name: CONTRACT_END,
        contractEnd,
      })
    }
  }

  const handleContractStart = e => {
    e.stopPropagation()

    if (contractDate.name !== CONTRACT_START) {
      setContractDate({
        name: CONTRACT_START,
        contractStart,
      })
    }
  }

  const onClickOut = () => {
    setContractDate({
      name: '',
    })
  }

  const handleOpen = userId => {
    if (!is_available) {
      return
    }

    dispatch(push(`/profile/${userId}/`))
  }

  const isLandlord = role === 'landlord'

  const statusClass = classnames('table-item table-item__status', {
    'table-item__status--success': status === 1,
    'table-item__status--warning': status !== 1 && status !== 3,
    'table-item__status--danger': status === 3,
  })

  const userFlatClass = classnames(
    'table-item__user-name table-item__user-flat',
    {
      'table-item__user-flat__archived': archived,
    }
  )

  const statusOptions = map(statuses, (val, label) => ({
    code: val,
    value: label,
    label: t(`Common:${label}`),
  }))

  const statusUser = statusOptions.find(o => o.code === status)
  const statusText = statusUser.label

  const handleCheck = () => {
    props.handleCheck(id)
  }

  const roleOptions = [
    {
      value: USER_GROUPS.dweller,
      group: USER_GROUPS.dweller,
      label: t(USER_GROUPS.dweller),
    },
    {
      value: USER_GROUPS.landlord,
      group: USER_GROUPS.landlord,
      label: t(USER_GROUPS.landlord),
    },
  ]

  const value = roleOptions.find(
    o =>
      (isLandlord && o.group === USER_GROUPS.landlord) ||
      (!isLandlord && o.group === USER_GROUPS.dweller)
  )
  const onClear = () => {
    updateContractDate({ ...contractDate, value: null })
    onClickOut()
  }

  const handleChangeContractId = e => {
    e.stopPropagation()

    setContractEdit({
      initialValues: { contract_id: contractId },
      titleModal: t('ContractIdEditTitle'),
      titleLabel: t('ContractId'),
    })
  }

  const handleChangePCN = e => {
    e.stopPropagation()

    setContractEdit({
      initialValues: { purchase_contract_number: purchaseContractNumber },
      titleModal: t('SecondIdEditTitle'),
      titleLabel: t('SecondId'),
    })
  }

  const handleChangeDuration = e => {
    e.stopPropagation()

    setContractEdit({
      initialValues: {
        duration: duration || null,
      },
      titleModal: duration
        ? t('EditContractDuration')
        : t('AddContractDuration'),
      titleLabel: t('ContractDuration'),
    })
  }

  const handleClose = () => setContractEdit({})

  const rowStyle = {}

  if (archived) {
    rowStyle.color = '#949494'
  }

  return (
    <>
      <Table.BodyRow
        key={id}
        id={id}
        handleClick={handleOpen}
        style={rowStyle}
        noClickable={!is_available}
      >
        <div className='table__cell'>
          <div className='table__cell-wrapper'>
            <div className='table-item table-item__user'>
              {!archived && !isDweller && (
                <Checkbox
                  style={{ marginRight: '6px' }}
                  checked={selected.includes(id)}
                  onChange={handleCheck}
                />
              )}
              <i
                className='table-item__user-pic'
                style={archived ? { marginLeft: '24px' } : {}}
              >
                <img alt='user' src={avatar} />
              </i>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginLeft: '10px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                <span className={userFlatClass} title={fullname}>
                  {fullname}
                </span>
                <div
                  className={statusClass}
                  title={statusText}
                  style={{ fontSize: '14px', marginTop: '5px' }}
                >
                  <span>{statusText}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='table__cell'>
          <div
            className='table__cell-wrapper'
            style={{ paddingLeft: '5px' }}
            onClick={e => e.stopPropagation()}
          >
            {!archived && canChangeUserRole ? (
              <SelectRole
                value={value}
                options={roleOptions}
                onChange={val => {
                  props.showUpdateDwellerRoleModal(props.profile, val)
                }}
              />
            ) : (
              t(isLandlord ? 'Landlord' : 'Dweller')
            )}
          </div>
        </div>
        {!isDweller && (
          <>
            <div className='table__cell'>
              <div className='table__cell-wrapper'>
                {!archived && canChangeContractId ? (
                  <Button.Edit
                    className={styles.buttonEdit}
                    onClick={handleChangeContractId}
                  >
                    <div className='table-item table-item__text'>
                      {contractId ? (
                        <span title={contractId}>{contractId}</span>
                      ) : (
                        <>&mdash;</>
                      )}
                    </div>
                  </Button.Edit>
                ) : (
                  <div className='table-item table-item__text'>
                    {contractId ? (
                      <span title={contractId}>{contractId}</span>
                    ) : (
                      <>&mdash;</>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className='table__cell'>
              <div className='table__cell-wrapper'>
                {!archived && canChangePurchaseContractNumber ? (
                  <Button.Edit
                    className={styles.buttonEdit}
                    onClick={handleChangePCN}
                  >
                    <div className='table-item table-item__text'>
                      {purchaseContractNumber ? (
                        <span title={purchaseContractNumber}>
                          {purchaseContractNumber}
                        </span>
                      ) : (
                        <>&mdash;</>
                      )}
                    </div>
                  </Button.Edit>
                ) : (
                  <div className='table-item table-item__text'>
                    {purchaseContractNumber ? (
                      <span title={purchaseContractNumber}>
                        {purchaseContractNumber}
                      </span>
                    ) : (
                      <>&mdash;</>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className='table__cell'>
              {!archived && canChangeStartDate ? (
                <div
                  className='table__cell-wrapper'
                  style={{ display: 'block' }}
                >
                  <div className='table-item table-item__text'>
                    <button
                      type='button'
                      className='table-item__text-dropdown'
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      onClick={handleContractStart}
                    >
                      {contractStart ? (
                        getShortDateFormat(contractStart)
                      ) : (
                        <>&mdash;</>
                      )}
                      &nbsp;
                    </button>
                    {contractDate.name === CONTRACT_START && (
                      <ContractDateCalendar
                        updateDate={updateContractDate}
                        value={contractDate}
                        onClickOut={onClickOut}
                        onClear={onClear}
                      />
                    )}
                  </div>
                </div>
              ) : (
                <div className='table-item table-item__text'>
                  {contractStart ? (
                    getShortDateFormat(contractStart)
                  ) : (
                    <>&mdash;</>
                  )}
                </div>
              )}
            </div>

            <div className='table__cell'>
              <div className='table__cell-wrapper'>
                {!archived && canChangeDuration ? (
                  <Button.Edit
                    className={styles.buttonEdit}
                    onClick={handleChangeDuration}
                  >
                    <div className='table-item table-item__text'>
                      {duration ? `${duration} ${t('months')}` : <>&mdash;</>}
                    </div>
                  </Button.Edit>
                ) : (
                  <div className='table-item table-item__text'>
                    {duration ? `${duration} ${t('months')}` : <>&mdash;</>}
                  </div>
                )}
              </div>
            </div>

            <div className='table__cell'>
              {!archived && canChangeEndDate ? (
                <div
                  className='table__cell-wrapper'
                  style={{ display: 'block' }}
                >
                  <div className='table-item table-item__text'>
                    <button
                      type='button'
                      className='table-item__text-dropdown'
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      onClick={handleContractEnd}
                    >
                      {contractEnd ? (
                        getShortDateFormat(contractEnd)
                      ) : (
                        <>&mdash;</>
                      )}
                      &nbsp;
                    </button>
                    {contractDate.name === CONTRACT_END && (
                      <ContractDateCalendar
                        updateDate={updateContractDate}
                        value={contractDate}
                        onClickOut={onClickOut}
                        onClear={onClear}
                      />
                    )}
                  </div>
                </div>
              ) : (
                <div className='table-item table-item__text'>
                  {contractEnd ? getShortDateFormat(contractEnd) : <>&mdash;</>}
                </div>
              )}
            </div>
          </>
        )}
      </Table.BodyRow>
      {!isEmpty(contractEdit) && (
        <ContractPopup
          {...contractEdit}
          translationSection='FlatDwellers'
          updateContract={updateContract}
          onClose={handleClose}
        />
      )}
    </>
  )
}

export default ProfileListTableBodyRow
