// @flow

import React, { useEffect, useState, useRef } from 'react'
import type { Node } from 'react'

import { useTranslation } from 'react-i18next'
import { useOverflow } from '../../../../hooks/useOverflow'
import { Link } from 'react-router-dom'

import {
  exportAsFiles,
  exportAsFilesTask,
} from '../../../../core/api/api.request'
import { getById } from '../../../../core/api/api.file'

import Button from '../../../Button'
import Modal from '../../../Modal'
import ModalBody from '../../../Modal/ModalBody'
import ModalRow from '../../../Modal/ModalRow/ModalRow'
import ModalText from '../../../Modal/ModalText'
import ModalCloseButton from '../../../Modal/ModalCloseButton'
import ModalButtons from '../../../Modal/ModalButtons'
import ModalHead from '../../../Modal/ModalHead'
import Radio from '../../../Radio'
import Loader from '../../../Loader'

import ConfirmationPopup from '../../../modals/ConfirmationPopup'

import styles from './ExportAsFilesModal.module.scss'

const STATUS_IN_PROGRESS = 'in_progress'
const STATUS_SUCCESS = 'success'
const STATUS_FAILED = 'failed'
const TASK_INTERVAL = 30000
const CHECK_FILE_INTERVAL = 1000

type Props = {
  filters: Object,
  onClose: () => void,
  onError: () => void,
  onSuccess: () => void,
}

const ExportAsFilesModal = (props: Props): Node => {
  const { filters, onClose, onSuccess, onError } = props

  useOverflow()
  const { t } = useTranslation('Request')

  const [working, setWorking] = useState(false)
  const [error, setError] = useState(null)
  const [instantLink, setInstantLink] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const [exportLink, setExportLink] = useState(null)
  const [folderLink, setFolderLink] = useState(false)

  const timerRef = useRef()

  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current)
    }
  }, [])

  const handleChangeMode = e => setInstantLink(JSON.parse(e.target.value))

  const handleExport = () => {
    if (instantLink) {
      startExport()
    } else {
      setShowConfirm(true)
    }
  }

  const handleClickFile = () => getFile(exportLink)

  const handleClose = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }

    onClose()
  }

  const getFile = id => {
    setWorking(true)
    getById(id).then(res => {
      if (res.checking) {
        timerRef.current = setTimeout(() => getFile(id), CHECK_FILE_INTERVAL)
      } else {
        setWorking(false)
        window.open(res)
      }
    })
  }

  const startExport = () => {
    setError(null)
    setWorking(true)
    exportAsFiles({
      request_filters: filters,
    })
      .then(data => {
        if (instantLink) {
          checkingExportTask(data.task_id)
        } else {
          onSuccess()
        }
      })
      .catch(err => {
        const detail = err.message?.response?.data?.detail

        if (instantLink) {
          setWorking(false)
          setError(t(detail ? `Errors:${detail}` : 'ExportError'))
        } else {
          onError(detail ? t(`Errors:${detail}`) : null)
        }
      })
      .finally(() => {
        if (!instantLink) {
          setWorking(false)
          handleClose()
        }
      })
  }

  const checkingExportTask = uuid => {
    exportAsFilesTask(uuid).then(data => {
      if (data.status === STATUS_IN_PROGRESS) {
        timerRef.current = setTimeout(
          () => checkingExportTask(uuid),
          TASK_INTERVAL
        )
      } else if (data.status === STATUS_SUCCESS) {
        setWorking(false)
        setFolderLink(data.files.length > 1)
        setExportLink(data.files.length > 1 ? data.directory_id : data.files[0])
      } else if (data.status === STATUS_FAILED) {
        setWorking(false)
        setError(t('ExportError'))
      }
    })
  }

  return (
    <>
      <ModalCloseButton onClose={handleClose} />
      <ModalHead title={t('ExportAsFilesTitle')} />
      <ModalBody className={styles.body}>
        {!exportLink && (
          <ModalRow>
            <ModalText text={t('ExportAsFilesNotes')} />
          </ModalRow>
        )}
        {!working && !exportLink && (
          <>
            <ModalRow>
              <Radio
                id='get_link'
                name='export_mode'
                value={false}
                checked={!instantLink}
                label={t('StartAndCloseModal')}
                onChange={handleChangeMode}
              />
            </ModalRow>
            <ModalRow>
              <Radio
                id='documents'
                name='export_mode'
                value={true}
                checked={instantLink}
                label={t('WaitAndGetLink')}
                onChange={handleChangeMode}
              />
            </ModalRow>
          </>
        )}
        {working && (
          <ModalRow>
            <Loader type='small' />
          </ModalRow>
        )}
        {exportLink && !working && (
          <>
            <ModalRow>{t('ExportReady')}</ModalRow>
            <ModalRow>
              {folderLink ? (
                <Link to={`/files/dir/${exportLink}`}>{t('LinkToFolder')}</Link>
              ) : (
                <span className={styles.link} onClick={handleClickFile}>
                  {t('DownloadExportFile')}
                </span>
              )}
            </ModalRow>
          </>
        )}
        {error && (
          <ModalRow>
            <span className={styles.error}>{error}</span>
          </ModalRow>
        )}
      </ModalBody>
      <ModalButtons>
        {!exportLink && (
          <Button.Save working={working} onClick={handleExport}>
            {t('CreateExport')}
          </Button.Save>
        )}
        <Button.Cancel disabled={working} onClick={handleClose}>
          {t(exportLink ? 'Close' : 'Cancel')}
        </Button.Cancel>
      </ModalButtons>
      <Modal isOpen={showConfirm} onRequestClose={() => setShowConfirm(false)}>
        <ConfirmationPopup
          title={t('Attention')}
          text={t('RequestReportGeneratingText')}
          confirm={t('Start')}
          cancel={t('Cancel')}
          onClose={() => setShowConfirm(false)}
          onOk={startExport}
        />
      </Modal>
    </>
  )
}

export default ExportAsFilesModal
