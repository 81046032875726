// @flow

export const REQUEST_CONSTRUCTOR_INITIATE_CARDS =
  'REQUEST_CONSTRUCTOR_INITIATE_CARDS'
export const REQUEST_CONSTRUCTOR_CARDS_WERE_INITIATED =
  'REQUEST_CONSTRUCTOR_CARDS_WERE_INITIATED'
export const REQUEST_CONSTRUCTOR_ADD_CARD = 'REQUEST_CONSTRUCTOR_ADD_CARD'
export const REQUEST_CONSTRUCTOR_CARD_WAS_ADDED =
  'REQUEST_CONSTRUCTOR_CARD_WAS_ADDED'
export const REQUEST_CONSTRUCTOR_UPDATE_CARD = 'REQUEST_CONSTRUCTOR_UPDATE_CARD'
export const REQUEST_CONSTRUCTOR_CARD_WAS_UPDATED =
  'REQUEST_CONSTRUCTOR_CARD_WAS_UPDATED'
export const REQUEST_CONSTRUCTOR_REMOVE_CARD = 'REQUEST_CONSTRUCTOR_REMOVE_CARD'
export const REQUEST_CONSTRUCTOR_CARD_WAS_REMOVED =
  'REQUEST_CONSTRUCTOR_CARD_WAS_REMOVED'
export const REQUEST_CONSTRUCTOR_MOVE_CARD = 'REQUEST_CONSTRUCTOR_MOVE_CARD'
export const REQUEST_CONSTRUCTOR_CARD_WAS_MOVED =
  'REQUEST_CONSTRUCTOR_CARD_WAS_MOVED'

export const REQUEST_CONSTRUCTOR_CARD_ADD_LINK =
  'REQUEST_CONSTRUCTOR_CARD_ADD_LINK'
export const REQUEST_CONSTRUCTOR_CARD_LINK_WAS_ADDED =
  'REQUEST_CONSTRUCTOR_CARD_LINK_WAS_ADDED'
export const REQUEST_CONSTRUCTOR_CARD_UPDATE_LINK =
  'REQUEST_CONSTRUCTOR_CARD_UPDATE_LINK'
export const REQUEST_CONSTRUCTOR_CARD_LINK_WAS_UPDATED =
  'REQUEST_CONSTRUCTOR_CARD_LINK_WAS_UPDATED'
export const REQUEST_CONSTRUCTOR_CARD_REMOVE_LINK =
  'REQUEST_CONSTRUCTOR_CARD_REMOVE_LINK'
export const REQUEST_CONSTRUCTOR_CARD_LINK_WAS_REMOVED =
  'REQUEST_CONSTRUCTOR_CARD_LINK_WAS_REMOVED'

export const REQUEST_CONSTRUCTOR_CARDS_ERROR = 'REQUEST_CONSTRUCTOR_CARDS_ERROR'
export const REQUEST_CONSTRUCTOR_RESET_CARDS = 'REQUEST_CONSTRUCTOR_RESET_CARDS'
export const REQUEST_CONSTRUCTOR_CARD_CHANGED =
  'REQUEST_CONSTRUCTOR_CARD_CHANGED'
