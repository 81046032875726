import { emailPending } from '../../core/api/api.mail'

const POLLING_INTERVAL = 2000
const PENDING_STATUS = 'PENDING'
const STARTED_STATUS = 'STARTED'

export const checkPending = (threads, cbk_success) => {
  const pending_threads = threads
    .filter(t => t.has_pending_message)
    .map(t => t.uuid)

  if (pending_threads.length > 0) {
    emailPending({ pending_threads }).then(data => {
      const res = data.threads

      if (
        Array.isArray(res) &&
        pending_threads.length !==
          res.filter(
            t =>
              Array.isArray(t.messages) &&
              t.messages?.filter(
                m =>
                  m.task_status === PENDING_STATUS ||
                  m.task_status === STARTED_STATUS
              ).length > 0
          ).length
      ) {
        setTimeout(cbk_success, POLLING_INTERVAL)
      } else {
        setTimeout(() => checkPending(threads, cbk_success), POLLING_INTERVAL)
      }
    })
  }
}
