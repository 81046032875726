// @flow

import React from 'react'
import classNames from 'classnames'
import type { Node } from 'react'
import { useTranslation } from 'react-i18next'

import { STATUS_COLORS, USER_STATUS } from '../../../../constants'

import styles from './UserStatus.module.scss'

type Props = { isSelected: boolean, status: number }

const UserStatus = (props: Props): Node => {
  const { status, isSelected } = props

  const { t } = useTranslation('Common')

  const statusClass = classNames(styles.status, styles[STATUS_COLORS[status]], {
    [styles.selected]: isSelected,
  })

  return <span className={statusClass}>{t(USER_STATUS[status])}</span>
}

export default UserStatus
