// @flow

import React from 'react'

import styles from './DocumentPreview.module.scss'

type Props = {
  children: string
}

const Filename = ({ children }: Props) => (
  <span
    className={styles.filename}
    title={children}
  >{children}</span>
)

export default Filename
