// @flow

import { clone, cloneDeep, unionBy } from 'lodash-es'

import * as actionTypes from './Chatroom.actionTypes'
import {
  getArhivedUuid,
  getFiles,
  removeLike,
  addLike,
  removeDislike,
  addDislike,
} from './Chatroom.utils'

type Action = {
  type: string,
  uuid: string,
}

type State = {
  allPreviousAreLoaded: boolean,
  chatroomInfo: ?Object,
  countersUpdated: boolean,
  creating: boolean,
  initiated: boolean,
  isWaitingToUploadFile: boolean,
  loadingLatest: boolean,
  loadingPrevious: boolean,
  members: Array<Object>,
  messages: Array<string>,
  modal: ?Object,
  openedMessages: Array<Object>,
  sendingMessage: boolean,
  title: ?string,
  updating: boolean,
  uuid: ?string,
}

const initialState = {
  initiated: false,
  creating: false,
  updating: false,
  uuid: null,
  chatroomInfo: null,
  title: '',
  messages: [],
  members: [],
  loadingPrevious: false,
  allPreviousAreLoaded: false,
  loadingLatest: false,
  isWaitingToUploadFile: false,
  sendingMessage: false,
  openedMessages: [],
  modal: null,
  countersUpdated: false,
}

export default function chatroomReducer(
  state: State = initialState,
  action: Action
) {
  const { uuid } = action

  switch (action.type) {
    case actionTypes.CHATROOM_INITIATE:
      return {
        ...initialState,
        uuid: action.uuid,
      }
    case actionTypes.CHATROOM_WAS_INITIATED:
      return state.uuid === action.uuid
        ? {
            ...state,
            initiated: true,
            messages: action.messages.results.objects.reverse(),
            members: action.members,
            chatroomInfo: action.info,
            title: action.info.title,
            allPreviousAreLoaded: action.messages.meta.page_count === 1,
          }
        : state
    case actionTypes.GET_OLD_MESSAGES_INITIATING:
      return {
        ...state,
        loadingPrevious: true,
      }
    case actionTypes.GET_OLD_MESSAGES_INITIATED: {
      const { results, meta } = action.result

      return {
        ...state,
        messages: results.objects.reverse().concat(state.messages),
        allPreviousAreLoaded: meta.curr_page === meta.page_count,
        loadingPrevious: false,
      }
    }
    case actionTypes.GET_NEW_MESSAGES_INITIATING:
      return {
        ...state,
        loadingLatest: true,
      }
    case actionTypes.GET_NEW_MESSAGES_INITIATED:
      return state.uuid === action.uuid
        ? {
            ...state,
            messages: unionBy(state.messages, action.messages, 'uuid'),
            loadingLatest: false,
          }
        : state
    case actionTypes.ADD_MEMBERS_INITIATING:
      return {
        ...state,
        isWaitingToGetMembers: true,
      }
    case actionTypes.ADD_MEMBERS_INITIATED:
      return {
        ...state,
        members: action.members,
        isWaitingToGetMembers: false,
      }
    case actionTypes.REMOVE_MEMBERS_INITIATING: {
      // users  - array of memberIds, which were removed
      const newMembers = clone(state.members)
      action.users.forEach(memberId => {
        newMembers.find(member => member.user === memberId).active = false
      })

      return {
        ...state,
        members: newMembers,
      }
    }
    case actionTypes.RENAME_CHATROOM_INITIATING: {
      return {
        ...state,
        isWaitingToRenameChatroom: true,
      }
    }
    case actionTypes.RENAME_CHATROOM_INITIATED: {
      return {
        ...state,
        isWaitingToRenameChatroom: false,
        title: action.title,
      }
    }
    case actionTypes.CHATROOM_CREATE:
      return {
        ...state,
        creating: true,
      }
    case actionTypes.CHATROOM_WAS_CREATED:
      return !state.creating
        ? state
        : {
            ...state,
            creating: false,
            uuid: action.uuid,
          }
    case actionTypes.SEND_MESSAGE_INITIATING:
      return {
        ...state,
        sendingMessage: true,
      }
    case actionTypes.SEND_MESSAGE_INITIATED:
      return state.uuid === action.uuid
        ? {
            ...state,
            members: action.newMemberToSet
              ? [...state.members, action.newMemberToSet]
              : [...state.members],
            messages: state.messages.concat(action.sentMessage),
            messageText: action.messageText,
            sendingMessage: false,
          }
        : state
    case actionTypes.LIKE_MESSAGE_INITIATING: {
      const updatedMessages = cloneDeep(state.messages)
      const { messageUuid, isLike, isRemove } = action
      const msgToUpdate = updatedMessages.find(msg => msg.uuid === messageUuid)
      switch (true) {
        case isLike && isRemove: // remove like
          removeLike(msgToUpdate)
          break
        case isLike && !isRemove: // add like
          addLike(msgToUpdate)

          if (msgToUpdate.like.disliked) {
            removeDislike(msgToUpdate)
          }

          break
        case !isLike && isRemove: // remove dislike
          removeDislike(msgToUpdate)
          break
        case !isLike && !isRemove: // add dislike
          addDislike(msgToUpdate)

          if (msgToUpdate.like.liked) {
            removeLike(msgToUpdate)
          }

          break
        default:
      }

      return {
        ...state,
        backupMessages: cloneDeep(state.messages),
        messages: updatedMessages,
      }
    }
    case actionTypes.LIKE_MESSAGE_ERROR: {
      return {
        ...state,
        messages: state.backupMessages,
      }
    }
    case actionTypes.SHOW_MODAL_ADD_MEMBERS: {
      return {
        ...state,
        isAddMembersPopUpOpen: true,
      }
    }
    case actionTypes.SHOW_MODAL_LABELS: {
      return {
        ...state,
        isLabelsPopUpOpen: true,
      }
    }
    case actionTypes.SHOW_MODAL_CONVERT_TO_REQUEST: {
      return {
        ...state,
        isConvertToRequestPopUpOpen: true,
      }
    }
    case actionTypes.HIDE_MODAL: {
      return {
        ...state,
        isAddMembersPopUpOpen: false,
        isLabelsPopUpOpen: false,
        isConvertToRequestPopUpOpen: false,
      }
    }
    case actionTypes.UPDATE_LABELS_INIT: {
      const chatroomInfo = cloneDeep(state.chatroomInfo)
      chatroomInfo.label_objs = action.labels.filter(l => l.checked)

      return {
        ...state,
        chatroomInfo,
        isLabelsPopUpOpen: false,
      }
    }
    case actionTypes.TOGGLE_CHATROOM_MESSAGE: {
      const messageIndex = state.openedMessages.indexOf(uuid)
      const openedMessages =
        messageIndex === -1
          ? state.openedMessages.concat([uuid])
          : [
              ...state.openedMessages.slice(0, messageIndex),
              ...state.openedMessages.slice(messageIndex + 1),
            ]

      return {
        ...state,
        openedMessages,
      }
    }
    case actionTypes.CHATROOM_UPDATE_COUNTERS: {
      return {
        ...state,
        countersUpdated: true,
      }
    }
    case actionTypes.CHATROOM_RESET:
      return initialState
    case actionTypes.DELETE_MESSAGE:
      return {
        ...state,
        messages: state.messages.filter(
          ({ uuid }) => uuid !== action.deletingMessageUuid
        ),
      }
    case actionTypes.CHATROOM_MESSAGES_UPDATING:
      return state.uuid === action.uuid
        ? {
            ...state,
            updating: true,
          }
        : state
    case actionTypes.CHATROOM_MESSAGES_UPDATED: {
      const archivedUuid = getArhivedUuid(action.archivedInfo.results.objects)
      const files = getFiles(action.archivedInfo.results.objects)

      return state.uuid === action.uuid
        ? {
            ...state,
            updating: false,
            messages: state.messages
              .filter(({ uuid }) => !archivedUuid.includes(uuid))
              .map(item => {
                if (
                  files[item.uuid] &&
                  files[item.uuid].length !== item.files.length
                ) {
                  return {
                    ...item,
                    files: item.files.filter(file =>
                      files[item.uuid].includes(file.id)
                    ),
                  }
                }

                return item
              }),
          }
        : state
    }
    case actionTypes.UPDATE_MESSAGE: {
      const { message: updatingMessage } = action
      const { messages } = state

      return {
        ...state,
        messages: messages.map(message => {
          if (updatingMessage.uuid === message.uuid) {
            return updatingMessage
          }

          return message
        }),
      }
    }
    case actionTypes.SHOW_MODAL:
      return {
        ...state,
        modal: action.modal,
      }
    default:
      return state
  }
}
