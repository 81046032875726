import React, { useMemo } from 'react'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { getLocation, getThemeColor } from '../../../../utils/commonSelectors'
import { getAllProfileList } from '../../../../core/api/api.flat'
import { getCurrentFilters } from '../../../../utils/routing'
import * as actions from '../BuildingDwellers.actionTypes'
import SelectPopUp from '../../../Users/UserList/SelectPopUp'

const actionType = {
  SELECTED_ALL_PAGES: 'SELECTED_ALL_PAGES',
  SELECTED_SOME: 'SELECTED_SOME',
  SELECTED_CURR_PAGE: 'SELECTED_CURR_PAGE',
}

const SelectPopUpDwellers = ({
  selected,
  selectedAllPages,
  ordering,
  dwellers,
  location,
  working,
  meta,
  buildingID,
  themeColor,
  t,
}) => {
  const dispatch = useDispatch()

  const action = useMemo(() => {
    const activeDwellers = dwellers.filter(d => !d.archived)

    if (selectedAllPages) {
      return actionType.SELECTED_ALL_PAGES
    } else if (
      activeDwellers.filter(
        u => !selected.map(s => s.owner).includes(u.userprofile.id)
      ).length === 0 &&
      activeDwellers.length === selected.length
    ) {
      return actionType.SELECTED_CURR_PAGE
    } else {
      return actionType.SELECTED_SOME
    }
  }, [selected])

  if (!selected.length || working) {
    return null
  }

  const getAllPagesUsers = () => {
    const getParams = {
      building: buildingID,
      ordering,
      page_size: 100500,
      ...getCurrentFilters(location),
    }

    delete getParams.page

    dispatch({
      type: actions.BUILDING_DWELLERS_BEFORE_LOAD,
    })

    getAllProfileList(getParams)
      .then(data => {
        dispatch({
          type: actions.BUILDING_DWELLERS_TOGGLE_ALL,
          count: meta.count,
          params: {
            pages: [...data.objects],
            all: true,
          },
        })
      })
      .catch(error =>
        dispatch({
          type: actions.BUILDING_DWELLERS_ERROR,
          error,
        })
      )
  }

  const selectAll = empty => {
    if (selected.length > 0) {
      dispatch({
        type: actions.BUILDING_DWELLERS_TOGGLE_ALL,
        count: meta.limit,
        params: { empty: true },
      })
    }

    if (meta.count > meta.limit && !empty) {
      getAllPagesUsers()
    } else {
      dispatch({
        type: actions.BUILDING_DWELLERS_TOGGLE_ALL,
        count: meta.limit,
        params: { empty },
      })
    }
  }

  const onClick = {
    [actionType.SELECTED_ALL_PAGES]: () => selectAll(true),
    [actionType.SELECTED_CURR_PAGE]: () => getAllPagesUsers(),
    [actionType.SELECTED_SOME]: () => selectAll(false),
  }

  const getMessages = {
    [actionType.SELECTED_ALL_PAGES]: t('SelectedAll', {
      count: selected.length,
    }),
    [actionType.SELECTED_CURR_PAGE]: t('SelectedCurrPage', {
      count: selected.length,
    }),
    [actionType.SELECTED_SOME]: t('SelectedSome', {
      count: selected.length,
    }),
  }

  const getActionMessage = {
    [actionType.SELECTED_ALL_PAGES]: t('unSelectAll', {
      count: '',
    }),
    [actionType.SELECTED_CURR_PAGE]: t('SelectAll', {
      count: '',
    }),
    [actionType.SELECTED_SOME]: t('SelectAll', {
      count: '',
    }),
  }

  return (
    <SelectPopUp
      getInfoMessages={getMessages}
      getActionMessage={getActionMessage}
      working={working}
      action={action}
      clearAll={() => selectAll(true)}
      themeColor={themeColor}
      onClick={onClick}
    />
  )
}

const mapStateToProps = state => ({
  ...state.buildingDwellers,
  location: getLocation(state),
  themeColor: getThemeColor(state),
})

export default compose(
  withTranslation('Building'),
  connect(mapStateToProps)
)(SelectPopUpDwellers)
