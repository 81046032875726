// @flow

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { push } from 'connected-react-router'

import * as actions from './ImportMapTable.actionTypes'
import Button from '../../components/Button'
import Date from '../../components/Date'
import { EntityIcon } from '../../components/Icon'
import Loader from '../Loader'
import Select from '../Select/SelectSimple'
import ErrorPopup from '../modals/ErrorPopup'
import Modal from '../Modal'
import Warning from '../Warning'
import { cutText, isDeLocale } from '../../utils/utils'

const {
  IMPORT_MAP_TABLE_INITIATING,
  IMPORT_MAP_TABLE_HIDE_COLUMN,
  IMPORT_MAP_TABLE_SELECT,
  IMPORT_MAP_TABLE_SUBMIT,
  IMPORT_MAP_TABLE_HIDE_HINT,
  IMPORT_MAP_TABLE_HIDE_RESET_HINT,
  IMPORT_MAP_TABLE_UNMOUNT,
  IMPORT_MAP_TABLE_REMOVE,
  IMPORT_MAP_TABLE_CLOSE_MODAL,
} = actions

const propTypes = {
  dispatch: PropTypes.func,
  importId: PropTypes.string,
  errorMessage: PropTypes.string,
  error: PropTypes.bool,
  dropdowns: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  selected: PropTypes.array,
  hide_index: PropTypes.array,
  initiated: PropTypes.bool,
  indexes: PropTypes.array,
  rows: PropTypes.array,
  fields: PropTypes.array,
  languageCode: PropTypes.string,
  headers: PropTypes.array,
  hideHint: PropTypes.bool,
  impData: PropTypes.object,
  statuses: PropTypes.any,
  resetMaping: PropTypes.bool,
  t: PropTypes.func,
}

const STATUS_CSS_ARRAY = [
  'danger',
  'progress',
  'progress',
  'progress',
  'success',
  'danger',
  'warning',
  'default',
  'warning',
]

function renderSelectChoose(selected, fields, index) {
  for (let j = 0; j < selected.length; j += 1) {
    if (selected[j].column_id === index) {
      return selected[j].field_id
    }
  }

  return undefined
}

class ImportMapTable extends Component {
  componentDidMount() {
    this.props.dispatch({
      type: IMPORT_MAP_TABLE_INITIATING,
      importId: this.props.importId,
    })
  }

  componentDidUpdate() {
    if (this.props.submited !== false || this.props.redirect === true) {
      this.props.dispatch(push('/settings/import'))
    }
  }

  componentWillUnmount() {
    this.props.dispatch({ type: IMPORT_MAP_TABLE_UNMOUNT })
  }

  hideColumn = index => {
    const hidden = this.props.hide_index
    this.props.dispatch({
      type: IMPORT_MAP_TABLE_HIDE_COLUMN,
      index,
      hided: hidden,
    })
  }

  resetField = () => {
    this.props.dispatch({ type: IMPORT_MAP_TABLE_HIDE_RESET_HINT })
  }

  selectField = (findex, index) => {
    const inIndex = index.value
    const selected = this.props.selected
    const dd = this.props.dropdowns
    this.props.dispatch({
      type: IMPORT_MAP_TABLE_SELECT,
      col_index: findex,
      field_index: inIndex,
      sel: selected,
      columns: dd,
    })
  }

  onDeleteClick = pk => {
    this.props.dispatch({ type: IMPORT_MAP_TABLE_REMOVE, pk })
  }

  closePopUp = () => {
    this.props.dispatch({ type: IMPORT_MAP_TABLE_CLOSE_MODAL })
  }

  handleSubmit() {
    this.props.dispatch({
      type: IMPORT_MAP_TABLE_SUBMIT,
      importId: this.props.importId,
      sel: this.props.selected,
    })
  }

  onHideHint = () => {
    this.props.dispatch({ type: IMPORT_MAP_TABLE_HIDE_HINT })
  }

  render() {
    const {
      indexes,
      hide_index: hideIndex,
      rows,
      fields,
      headers,
      selected,
      hideHint,
      impData,
      statuses,
      t,
      resetMaping,
      error,
      languageCode,
      errorMessage,
    } = this.props

    /* eslint react/no-array-index-key: 0 */
    if (!this.props.initiated) {
      return (
        <div className='content__col'>
          <Loader type='big' text={false} />
        </div>
      )
    }

    const minimumNumberOfActiveFields = 4
    const disableDeleteField =
      hideIndex.filter(field => field === false).length <=
      minimumNumberOfActiveFields
    let updatedFields = fields.map(field => ({
      ...field,
      label: t(field.label),
    }))

    // filter already mapped options
    const selectedFieldsIds = selected.map(o => o.field_id)
    const ignoredOptions = updatedFields.filter(
      field => selectedFieldsIds.indexOf(field.value) !== -1
    )

    const translateFields = fields.map(field => ({
      key: field.label,
      value: t(field.label),
    }))
    const result = {}
    for (let i = 0; i < translateFields.length; i += 1) {
      result[translateFields[i].key] = translateFields[i].value
    }
    const errMessage = t(errorMessage.replaceAll(':', ''), result)

    return (
      <div className='content__col'>
        <section className='unit unit--default'>
          <div className='bar__title bar__title-indented bar__row'>
            <EntityIcon id='importation' />
            <span className='bar__title-text'>
              {t('ImportFile')}: <b>{impData.file_obj.filename}</b>
            </span>
          </div>
          <div className='bar__cols bar__row'>
            <div className='bar__cell'>
              <span className='bar__cell-title'>{t('ImportDate')}</span>
              <span className='bar__cell-value'>
                <Date
                  date={impData.created}
                  dateFormat={`D${
                    isDeLocale(languageCode) ? '.' : ''
                  } MMMM YYYY`}
                />
              </span>
            </div>
            <div className='bar__cell'>
              <span className='bar__cell-title'>{t('Status')}</span>
              <span
                className={`bar__cell-value bar__cell-value--${
                  STATUS_CSS_ARRAY[impData.status]
                }`}
              >
                {t(statuses[impData.status])}
              </span>
            </div>
          </div>
          <div className='bar__row bar-import__submit'>
            <button
              type='button'
              className='button button--large button--success'
              onClick={() => {
                this.handleSubmit()
              }}
            >
              {t('StartImport')}
            </button>
            <button
              type='button'
              className='button button--large button--danger-3'
              onClick={() => this.onDeleteClick(impData.pk)}
            >
              {t('Cancel')}
            </button>
          </div>
        </section>
        {!hideHint && (
          <Warning
            className='alert--middle alert--top-hint'
            text={t('ImportMessage')}
            onClose={this.onHideHint}
          />
        )}
        <section className='unit unit--default'>
          <h2 className='unit__title'>{t('FileRow')}</h2>
          <div className='toolbox'>
            <div className='toolbox__cell'>
              <Button.Save
                blank
                disabled={resetMaping}
                icon='restore'
                onClick={this.resetField}
              >
                {this.props.t('ResetMaping')}
              </Button.Save>
            </div>
          </div>
          <div className='table table--default table--scroll-horizont table--import-map'>
            <div
              className='table__outer-wrapper'
              style={{ minHeight: '230px' }}
            >
              <div className='table__wrapper'>
                <div className='table__head'>
                  {indexes.map((col, index) => {
                    if (hideIndex[index] === false) {
                      return (
                        <div
                          className='table__cell table__caption table__caption--import-filter'
                          key={index}
                          style={{ minWidth: '170px', maxWidth: 'none' }}
                        >
                          <div className='table__cell-wrapper'>
                            <Select
                              importSelect
                              className='table__import--select'
                              labelKey='label'
                              valueKey='value'
                              autosize={false}
                              clearable={false}
                              filtering={false}
                              value={renderSelectChoose(
                                selected,
                                updatedFields,
                                index
                              )}
                              options={updatedFields}
                              ignoredOptions={ignoredOptions}
                              placeholder={t('Choice')}
                              onChange={selectValue => {
                                this.selectField(index, selectValue)
                              }}
                            />
                            <button
                              type='button'
                              disabled={disableDeleteField}
                              className='button button--default-3 button--square button--large button--square-x'
                              onClick={() => {
                                this.hideColumn(index)
                              }}
                            >
                              {t('Delete')}
                            </button>
                          </div>
                        </div>
                      )
                    }

                    return false
                  })}
                </div>

                <div className='table__head'>
                  {indexes.map((col, index) => {
                    if (hideIndex[index] === false) {
                      return (
                        <div
                          className='table__cell table__caption table__caption--gray'
                          key={index}
                          style={{ minWidth: '170px', maxWidth: 'none' }}
                        >
                          <div className='table__cell-wrapper'>
                            <span className='table__caption-text'>
                              {headers[index]}
                            </span>
                          </div>
                        </div>
                      )
                    }

                    return false
                  })}
                </div>

                {rows.map((row, index) => (
                  <div className='table__row' key={index}>
                    {row.map((col, colIndex) => {
                      if (hideIndex[colIndex] === false) {
                        return (
                          <div className='table__cell' key={colIndex}>
                            <div className='table__cell-wrapper'>
                              <div className='table-item table-item__text'>
                                <span>{cutText(col, 30)}</span>
                              </div>
                            </div>
                          </div>
                        )
                      }

                      return false
                    })}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
        {error && (
          <Modal
            className='Modal__Bootstrap modal-dialog'
            isOpen={error}
            contentLabel=''
          >
            <ErrorPopup
              imports
              title={t('Error')}
              text={errMessage}
              confirm={t('Close')}
              onOk={this.closePopUp}
            />
          </Modal>
        )}
      </div>
    )
  }
}

ImportMapTable.propTypes = propTypes

export default withTranslation('ImportMapTable')(ImportMapTable)
