// @flow

import { getApi, deleteApi, postApi, putApi } from './api.core'

export const getGroups = () => getApi('api/faq/')
export const createGroup = params => postApi(`api/faq/group/`, params)
export const removeGroup = id => deleteApi(`api/faq/group/${id}/`)
export const renameGroupTitle = ({ id, ...params }) =>
  putApi(`api/faq/group/${id}/`, params)
export const addQuestion = params => postApi(`api/faq/question/`, params)
export const editQuestion = ({ id, ...params }) =>
  putApi(`api/faq/question/${id}/`, params)
export const removeQuestion = ({ id, ...params }) =>
  deleteApi(`api/faq/question/${id}/`, params)
