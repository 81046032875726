// @flow

export const DEFAULT_POSITION_SCROLL_BUTTON = 50
export const MAX_SCROLLABLE_RATIO = 1.3

export const getExtendedFilters = match => {
  const { requestID, flatId, buildingId, groupId } = match.params

  if (flatId) {
    return { flat: flatId }
  }

  if (buildingId) {
    return { building: buildingId }
  }

  if (groupId) {
    return { buildingGroup: groupId }
  }

  if (requestID) {
    return { request: requestID }
  }

  return {}
}

export const getOwnerFilter = (profileId, profileGroup) => {
  if (profileGroup === 'manager') {
    return { manager: profileId }
  }

  if (profileGroup === 'admin' && !profileId) {
    return {}
  }

  return { owner: profileId }
}

const getRatioScrollAndWindow = () =>
  document.documentElement.scrollHeight / window.innerHeight

export const getPositionButtonHorizontalScroll = el => {
  if (!el) {
    return DEFAULT_POSITION_SCROLL_BUTTON
  }

  const { top, height } = el.getBoundingClientRect()
  const isAllListVisible = top > 0 && top + height < window.innerHeight
  const ratio = getRatioScrollAndWindow()
  const visibleListPart =
    (1 - (height - (window.innerHeight - top)) / height) * 100

  if (ratio < MAX_SCROLLABLE_RATIO || isAllListVisible) {
    return DEFAULT_POSITION_SCROLL_BUTTON
  }

  const result =
    (visibleListPart > 80 ? visibleListPart * 1.1 : visibleListPart) / 2

  return result > 100 ? 50 : result
}

export const getInitialPositionButtonScroll = () => {
  const ratio = getRatioScrollAndWindow()

  if (ratio < MAX_SCROLLABLE_RATIO) {
    return DEFAULT_POSITION_SCROLL_BUTTON
  }

  return Math.ceil(36 / ratio)
}

export const getRequestExtra = (request, extraList) => {
  return extraList.find(item => item.id === request.id)
}

export const isSingleLoad = params => Boolean(params.flat || params.building)
