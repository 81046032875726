import { clone, pull, map } from 'lodash-es'

import * as actions from './ImportFilesTable.actionTypes'
import { IMPORT_MAP_TABLE_SUBMIT_SUCCESS } from '../ImportMapTable/ImportMapTable.actionTypes'

const initialState = {
  initiated: false,
  data: [],
  last: {},
  selected: [],
  statuses: {},
  working: false,
  complete: false,
  hasError: false,
  errorMessage: '',
  modal: false,
  page: 1,
  pk: null
}

export default function ImportFilesTableReducer (state = initialState, action) {
  switch (action.type) {
    case actions.IMPORT_FILES_TABLE_INITIATING: {
      return {
        ...initialState,
        working: true
      }
    }
    case actions.IMPORT_FILES_TABLE_INITIATED: {
      return {
        ...state,
        initiated: true,
        data: action.data,
        last: action.last,
        statuses: action.statuses,
        working: false
      }
    }
    case IMPORT_MAP_TABLE_SUBMIT_SUCCESS: {
      return {
        ...state,
        forceLast: {
          progress: 0,
          status: 3
        }
      }
    }
    case actions.IMPORT_FORCE_LAST_STATUS: {
      return {
        ...state,
        forceLast: action.forceLast
      }
    }
    case actions.IMPORT_FILES_TABLE_ERROR: {
      return {
        ...state,
        initiated: true,
        working: false,
        error: true,
        errorMessage: action.error.message
      }
    }
    case actions.IMPORT_FILES_TABLE_LAST: {
      return {
        ...state,
        last: action.last,
        data: action.data
      }
    }
    case actions.IMPORT_FILES_TABLE_SET_PAGE: {
      return {
        ...state,
        page: action.page,
        working: true
      }
    }
    case actions.IMPORT_FILES_TABLE_PAGE: {
      return {
        ...state,
        working: false,
        data: action.data
      }
    }
    case actions.IMPORT_FILES_SET_IMPORT_PK: {
      return {
        ...state,
        pk: action.pk
      }
    }
    case actions.IMPORT_FILES_TABLE_START: {
      return {
        ...state,
        working: true
      }
    }
    case actions.IMPORT_FILES_TABLE_STARTED: {
      return {
        ...state,
        working: false
      }
    }

    case actions.IMPORT_FILES_TABLE_TOGGLE: {
      const { pk } = action

      let selected = clone(state.selected)
      if (selected.includes(pk)) {
        selected = pull(selected, pk)
      } else {
        selected.push(pk)
      }

      return {
        ...state,
        selected
      }
    }

    case actions.IMPORT_FILES_TABLE_TOGGLE_ALL: {
      const { data } = state

      let selected = []

      if (state.selected.length !== data.results.objects.length) {
        selected = map(data.results.objects, (imp) => imp.pk)
      }

      return {
        ...state,
        selected
      }
    }
    case actions.IMPORT_FILES_TABLE_CANCEL: {
      return {
        ...state,
        working: true
      }
    }

    case actions.IMPORT_FILES_TABLE_CANCELED: {
      return {
        ...state,
        working: false,
        data: action.data
      }
    }

    case actions.IMPORT_FILES_TABLE_REMOVE: {
      return {
        ...state,
        working: true,
        modal: false
      }
    }
    case actions.IMPORT_FILES_TABLE_LAST_STATUS: {
      return {
        ...state
      }
    }

    case actions.IMPORT_FILES_TABLE_REMOVED: {
      return {
        ...state,
        selected: [],
        working: false,
        data: action.data
      }
    }

    case actions.IMPORT_FILES_TABLE_MODAL: {
      return {
        ...state,
        modal: action.modal
      }
    }

    default:
      return state
  }
}
