// @flow

export const MAIL_INIT = '/MAIL_THREAD/MAIL_NIT'
export const MAIL_INITED = '/MAIL_THREAD/MAIL_INITED'
export const MAIL_MODAL = '/MAIL_THREAD/MAIL_MODAL'
export const MAIL_CREATE = '/MAIL_THREAD/MAIL_CREATE'
export const MAIL_CREATED = '/MAIL_THREAD/MAIL_CREATED'
export const MAIL_UPDATE = '/MAIL_THREAD/MAIL_UPDATE'
export const MAIL_RESET = '/MAIL_THREAD/MAIL_RESET'
export const REMOVE_MESSAGE = '/MAIL_THREAD/REMOVE_MESSAGE'
export const REMOVED_MESSAGE = '/MAIL_THREAD/REMOVED_MESSAGE'
export const RESTORE_MESSAGE = '/MAIL_THREAD/RESTORE_MESSAGE'
export const MAIL_ERROR = '/MAIL_THREAD/MAIL_ERROR'
export const MAIL_SEARCH_SELECT_FILES = 'MAIL_SEARCH_SELECT_FILES'
