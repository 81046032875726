// @flow

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { Location } from 'react-router-dom'
import type { Node } from 'react'

import { patchTemplate } from '../../core/api/api.postTemplate'
import Modal from '../Modal'
import EmptyList from '../EmptyList'
import NewTable from '../NewTable'
import TopPagination from '../Pagination/TopPagination'
import BottomPagination from '../Pagination/BottomPagination'
import Button from '../Button'
import ButtonSave from '../Button/Save'
import { getTemplates, deleteTemplate } from '../../core/api/api.postTemplate'
import { useSelected } from '../../hooks'
import { getCurrentFilters } from '../../utils/routing'
import ConfirmationPopup from '../modals/ConfirmationPopup'
import Toolbox from '../Toolbox'
import ToolboxCell from '../Users/UserList/Toolbox/ToolboxCell'
import PostTemplatesTableBody from './PostTemplatesTableBody'
import PostTemplatesTableHeader from './PostTemplatesTableHeader'
import PostTemplatePopup from './PostTemplatePopup'
import VisibilityPopup from '../modals/VisibilityPopup'
import { usePathname } from '../../hooks'

type Props = { location: Location }

const PostTemplates = (props: Props): Node => {
  const { location } = props

  const [selectedItems, changeSelected, setSelected, isAllSelected] =
    useSelected()

  const isLocked = usePathname()

  const { t } = useTranslation('PostTemplates')

  const [templates, setTemplates] = useState([])
  const [meta, setMeta] = useState(null)
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(null)
  const [params: ?Params, setParams] = useState(null)
  const [isPopupOpen, setPopupOpen] = useState(false)

  useEffect(() => {
    if (loading && !isLocked) {
      const filters = getCurrentFilters(location)

      getTemplates({ ...filters }).then(data => {
        setMeta({
          count: data.count,
          limit: 20,
          offset: 0,
          page_count: data.pages,
          curr_page: parseInt(filters.page),
        })
        setTemplates(data.objects)
        setSelected([])
        setLoading(false)
      })
    }
  }, [loading, isLocked])

  useEffect(() => {
    if (!isLocked && location.search && !loading) {
      setLoading(true)
    }
  }, [location.search, isLocked])

  useEffect(() => {
    if (!isPopupOpen) {
      setParams(null)
    }
  }, [isPopupOpen])

  const handleTemplate = params => {
    setParams(params)
    setPopupOpen(true)
  }

  const handleCreate = () =>
    handleTemplate({ title: t('AddTemplate'), buttonText: t('AddButton') })

  const handleClone = initialValues => () =>
    handleTemplate({
      title: t('CloneTemplate'),
      buttonText: t('CloneButton'),
      ...initialValues,
    })

  const handleEdit = initialValues => () =>
    handleTemplate({
      title: t('EditTemplate'),
      buttonText: t('EditButton'),
      ...initialValues,
    })

  const hideModal = () => setModal(null)

  const hidePopup = () => setPopupOpen(false)

  const removeTemplates = async () => {
    return Promise.all(
      selectedItems.map(item => {
        const templateObj = templates.find(t => t.id === item)

        if (!templateObj.permissions.can_edit) {
          return null
        }

        return deleteTemplate(item).then(data => data)
      })
    )
  }

  const handleMassRemove = async () => {
    hideModal()

    await removeTemplates()

    setLoading(true)
  }

  const handleRemove = id => () => {
    hideModal()

    deleteTemplate(id).then(() => setLoading(true))
  }

  const openMassModal = () => {
    setModal(
      <ConfirmationPopup
        confirm={t('TemplatesRemoveButton')}
        title={t('TemplatesRemoveTitle')}
        text={t('ConfirmTemplatesRemoving')}
        onClose={hideModal}
        onOk={handleMassRemove}
      />
    )
  }

  const openMassRemoveModal = () => {
    openMassModal()
  }

  const openMassChangeModal = () => {
    setModal(
      <VisibilityPopup
        selectedItems={selectedItems}
        templates={templates}
        setLoading={setLoading}
        sectionKey='PostTemplates'
        patchApi={patchTemplate}
        onClose={hideModal}
      />
    )
  }

  const openModal = id => {
    setModal(
      <ConfirmationPopup
        confirm={t('TemplateRemoveButton')}
        title={t('TemplateRemoveTitle')}
        text={t('ConfirmTemplateRemoving')}
        onClose={hideModal}
        onOk={handleRemove(id)}
      />
    )
  }

  const openRemoveModal = id => () => {
    openModal(id)
  }

  const resetTemplates = () => {
    hidePopup()
    setLoading(true)
  }

  return (
    <>
      <Toolbox
        showSelectAllCheckbox
        itemValueKey='id'
        checked={isAllSelected(templates)}
        items={templates}
        setSelected={setSelected}
        selected={!!selectedItems.length && !isAllSelected(templates)}
      >
        {selectedItems.length === 0 && (
          <ToolboxCell>
            <ButtonSave onClick={handleCreate}>{t('AddTemplate')}</ButtonSave>
          </ToolboxCell>
        )}
        {selectedItems.length !== 0 && (
          <ToolboxCell>
            <Button.Remove
              icon='eye'
              disabled={!selectedItems.length}
              onClick={openMassChangeModal}
            >
              {t('ChangeVisibilityButton')}
            </Button.Remove>
            <Button.Remove
              disabled={!selectedItems.length}
              onClick={openMassRemoveModal}
            >
              {t('RemoveTemplates')}
            </Button.Remove>
          </ToolboxCell>
        )}
        <ToolboxCell isRight>
          <TopPagination
            classes='pager pager--large pager--default-2'
            meta={meta}
          />
        </ToolboxCell>
      </Toolbox>
      <NewTable loading={loading}>
        <PostTemplatesTableHeader />
        <PostTemplatesTableBody
          selectedItems={selectedItems}
          handleChange={changeSelected}
          templates={templates}
          handleClone={handleClone}
          handleEdit={handleEdit}
          openRemoveModal={openRemoveModal}
        />
      </NewTable>
      {!templates.length && !loading && (
        <EmptyList icon='templates' title={t('TemplatesNotFound')} />
      )}
      <BottomPagination meta={meta} classes='paginator-2' />
      {!!modal && (
        <Modal
          contentLabel=''
          className='Modal__Bootstrap modal-dialog'
          isOpen={!!modal}
          onRequestClose={hideModal}
        >
          {modal}
        </Modal>
      )}
      {isPopupOpen && params && (
        <PostTemplatePopup
          params={params}
          resetTemplates={resetTemplates}
          isOpen={isPopupOpen}
          onClose={hidePopup}
        />
      )}
    </>
  )
}

export default PostTemplates
