// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  getUnvalidatedDwellerPermissions,
  updateUnvalidatedDwellerPermission,
} from '../../core/api/api.permission'
import Permission from './Permission'
import Loader from '../Loader'
import { usePermissions } from '../../hooks'

const UNVALIDATED_USERS_CAN_CHAT = 'unvalidated_users_can_chat'
const UNVALIDATED_USERS_CAN_COMMENT = 'unvalidated_users_can_comment'
const UNVALIDATED_USERS_CAN_SEE_ABOUT_US = 'unvalidated_users_can_see_about_us'
const UNVALIDATED_USERS_CAN_SEE_FAQ_GROUPS =
  'unvalidated_users_can_see_faq_groups'
const UNVALIDATED_USERS_CAN_USE_MARKETPLACE =
  'unvalidated_users_can_use_marketplace'
const UNVALIDATED_USERS_CAN_SEE_REQUEST_ASSIGNEE =
  'unvalidated_users_can_see_request_assignee'

const UnvalidatedDwellerPermissions = () => {
  const { t } = useTranslation('Permissions')

  const [permissions, handleClick] = usePermissions(
    getUnvalidatedDwellerPermissions,
    updateUnvalidatedDwellerPermission
  )

  if (!permissions) {
    return <Loader text={false} type='big' />
  }

  return (
    <div className='settings-rights__wrapper'>
      <Permission
        isActive={permissions[UNVALIDATED_USERS_CAN_CHAT]}
        handleClick={handleClick}
        permissionDescription={t(UNVALIDATED_USERS_CAN_CHAT)}
        title={t('chats')}
        permissionName={UNVALIDATED_USERS_CAN_CHAT}
      />
      <Permission
        isActive={permissions[UNVALIDATED_USERS_CAN_COMMENT]}
        handleClick={handleClick}
        permissionDescription={t(UNVALIDATED_USERS_CAN_COMMENT)}
        title={t('posts')}
        permissionName={UNVALIDATED_USERS_CAN_COMMENT}
      />
      <Permission
        isActive={permissions[UNVALIDATED_USERS_CAN_SEE_REQUEST_ASSIGNEE]}
        handleClick={handleClick}
        permissionDescription={t(UNVALIDATED_USERS_CAN_SEE_REQUEST_ASSIGNEE)}
        title={t('service_request')}
        permissionName={UNVALIDATED_USERS_CAN_SEE_REQUEST_ASSIGNEE}
      />
      <Permission
        isActive={permissions[UNVALIDATED_USERS_CAN_USE_MARKETPLACE]}
        handleClick={handleClick}
        permissionDescription={t(UNVALIDATED_USERS_CAN_USE_MARKETPLACE)}
        title={t('marketplace')}
        permissionName={UNVALIDATED_USERS_CAN_USE_MARKETPLACE}
      />
      <Permission
        isActive={permissions[UNVALIDATED_USERS_CAN_SEE_FAQ_GROUPS]}
        handleClick={handleClick}
        permissionDescription={t(UNVALIDATED_USERS_CAN_SEE_FAQ_GROUPS)}
        title={t('faq')}
        permissionName={UNVALIDATED_USERS_CAN_SEE_FAQ_GROUPS}
      />
      <Permission
        isActive={permissions[UNVALIDATED_USERS_CAN_SEE_ABOUT_US]}
        handleClick={handleClick}
        permissionDescription={t(UNVALIDATED_USERS_CAN_SEE_ABOUT_US)}
        title={t('aboutUs')}
        permissionName={UNVALIDATED_USERS_CAN_SEE_ABOUT_US}
      />
    </div>
  )
}

export default UnvalidatedDwellerPermissions
