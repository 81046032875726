// @flow

//unused

import { postApi, getApi, patchApi } from './api.core'

export const getPersons = () => getApi('api/person-preview/')
export const updatePersons = (id, params) =>
  patchApi(`api/person-preview/${id}/`, params)
export const createPersons = params => postApi('api/person-preview/', params)
