// @flow

import { all, put, call, fork, takeEvery } from 'redux-saga/effects'
import { replace } from 'connected-react-router'

import { PREVIEW_GREAT_SIZE } from '../../constants'
import { SERVER_404_ERROR } from '../Layout/Layout.constants'
import api from '../../core/api'
import { serverError } from '../Layout/Layout.actions'
import * as actions from './Friends.actionTypes'

const getParams = page => ({
  get_friends: 1,
  page,
  preview_size: PREVIEW_GREAT_SIZE,
})

function* watchInit() {
  yield takeEvery(actions.FRIENDS_LIST_INITIATING, init)
}

function* watchSubscribe() {
  yield takeEvery(actions.FRIENDS_LIST_SUBSCRIBE, subscribe)
}

function* watchUnSubscribe() {
  yield takeEvery(actions.FRIENDS_LIST_UNSUBSCRIBE, unsubscribe)
}

function* watchLoad() {
  yield takeEvery(actions.FRIENDS_LIST_LOAD, load)
}

export function* init({ page }) {
  try {
    const result = yield call(api.friends.getList, getParams(page))
    yield put({ type: actions.FRIENDS_LIST_INITIATED, ...result })
  } catch (error) {
    const action = serverError(error)

    if (action.type === SERVER_404_ERROR) {
      yield put(replace('/404'))
    } else {
      yield put(action)
      yield put({ type: actions.FRIENDS_LIST_ERROR, error })
    }
  }
}

export function* load({ page }) {
  try {
    const result = yield call(api.friends.getList, getParams(page))
    yield put({ type: actions.FRIENDS_LIST_LOADED, ...result })
  } catch (error) {
    const action = serverError(error)

    if (action.type === SERVER_404_ERROR) {
      yield put(replace('/404'))
    } else {
      yield put(action)
      yield put({ type: actions.FRIENDS_LIST_ERROR, error })
    }
  }
}

function* subscribe({ owner }) {
  try {
    yield call(api.friends.subscribe, owner)
    yield put({ type: actions.FRIENDS_LIST_SUBSCRIBED, owner })
  } catch (error) {
    yield put(serverError(error))
    yield put({ type: actions.FRIENDS_LIST_ERROR, error })
  }
}

function* unsubscribe({ owner }) {
  try {
    yield call(api.friends.unsubscribe, owner)
    yield put({ type: actions.FRIENDS_LIST_UNSUBSCRIBED, owner })
  } catch (error) {
    yield put(serverError(error))
    yield put({ type: actions.FRIENDS_LIST_ERROR, error })
  }
}

export default function* watch() {
  yield all([
    fork(watchInit),
    fork(watchLoad),
    fork(watchSubscribe),
    fork(watchUnSubscribe),
  ])
}
