import {
  MICROSOFT_EXCHANGE_STAGE,
  MICROSOFT_OFFICE_STAGE,
  MICROSOFT_OUTLOOK_STAGE,
  OTHER_STAGE,
  GOOGLE_STAGE,
} from '../EmailIntegrationModal.constant'
export const GOOGLE = 'google'
export const MICROSOFT_EXCHANGE = 'exchange'
export const MICROSOFT_OUTLOOK = 'outlook'
export const MICROSOFT_OFFICE = 'office'
export const OTHER = 'other'

export const PROVIDERS_LIST = [
  GOOGLE,
  MICROSOFT_EXCHANGE,
  MICROSOFT_OUTLOOK,
  MICROSOFT_OFFICE,
  OTHER,
]

export const providers = {
  [GOOGLE]: {
    stage: GOOGLE_STAGE,
    icon: 'gmail',
    translationKey: 'Google',
    logIntTranslationKey: 'LogInWithGoogle',
  },
  [MICROSOFT_EXCHANGE]: {
    stage: MICROSOFT_EXCHANGE_STAGE,
    icon: 'mExchange',
    translationKey: 'MicrosoftExchange',
    logIntTranslationKey: 'LogInWithMicrosoftExchange',
  },
  [MICROSOFT_OUTLOOK]: {
    stage: MICROSOFT_OUTLOOK_STAGE,
    icon: 'outlook',
    translationKey: 'MicrosoftOutlook',
    logIntTranslationKey: 'LogInWithMicrosoftOutlook',
  },
  [MICROSOFT_OFFICE]: {
    stage: MICROSOFT_OFFICE_STAGE,
    icon: 'mOffice',
    translationKey: 'MicrosoftOffice',
    logIntTranslationKey: 'LogInWithMicrosoftOffice',
  },
  [OTHER]: {
    stage: OTHER_STAGE,
    icon: 'closedEnv',
    translationKey: 'Other',
    logIntTranslationKey: 'LogInWithOther',
  },
}
