// @flow

import React, { useState } from 'react'
import { isEqual, map } from 'lodash-es'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import Button from '../../../../components/Button'
import Modal from '../../../../components/Modal'
import ModalBody from '../../../../components/Modal/ModalBody'
import ModalButtons from '../../../../components/Modal/ModalButtons'
import ModalCloseButton from '../../../../components/Modal/ModalCloseButton'
import ModalHead from '../../../../components/Modal/ModalHead'
import NewSelectAsync from '../../../../components/NewSelectAsync'
import { getBuildingOptions } from '../../../../core/api/api.building'
import { postProvider } from '../../../../core/api/api.provider'
import {
  updateContractor,
  addBuildings,
} from '../../../../core/api/api.contractor'
import { useOverflow, useSelected } from '../../../../hooks'
import ModalRow from '../../../../components/Modal/ModalRow/ModalRow'
import ValueItem from '../../../../components/Select/ValueItem'

import styles from '../../../../components/RequestProviders.module.scss'
import styles2 from './BuildingMappingModal.module.scss'

const BuildingMappingModal = props => {
  const { buildingModal, isContractor } = props

  const { buildings = [], massAction, contractors } = buildingModal
  const [isBuildingListActive, setBuildingListActive] = useState(false)

  const [selectedItems, changeSelected, setSelected] = useSelected(buildings)
  const [metaCount, setMetaCount] = useState(null)

  const { t } = useTranslation('Providers')

  useOverflow()

  const isChanged = !isEqual(buildings, selectedItems)

  const handleClose = () => {
    if (isChanged) {
      setSelected(buildings)
    } else {
      props.onClose()
    }
  }

  const handleClick = () => {
    const api = isContractor
      ? massAction
        ? (id, params) =>
            addBuildings({ contractors, buildings: params.buildings })
        : updateContractor
      : (id, params) => postProvider({ buildings: params.buildings, id })

    api(buildingModal.global_id, {
      buildings: map(selectedItems, 'id'),
    }).finally(() => {
      props.onClose()
      props.setLoading(true)
    })
  }

  const getLabel = item => item.address
  const getSelectedLabel = item => item.address

  const hideBuildingList = classnames(
    'modal__label--drop button-drop button-drop--small',
    { 'modal__label--drop--open': isBuildingListActive }
  )

  const toggleBuildingListActive = () =>
    setBuildingListActive(isBuildingListActive => !isBuildingListActive)

  const handleSelect = item => changeSelected(item)

  const handleRemove = item => changeSelected(item)

  const valueClassName = classnames(styles.valueItem, styles2.label)

  return (
    <Modal
      isOpen={!!buildingModal}
      style={{ content: { minWidth: '486px' } }}
      onRequestClose={props.onClose}
    >
      <ModalHead title={t('ChangeBuildingsTitle')} />
      <ModalCloseButton onClose={props.onClose} />
      <ModalBody>
        <ModalRow>
          <NewSelectAsync
            isMulti
            className={styles2.select}
            placeholder={t('SearchBuildingPlaceholder')}
            api={getBuildingOptions}
            permanentParams={{ is_promo: 0 }}
            pageSize={10}
            selectedItems={selectedItems}
            getLabel={getLabel}
            getSelectedLabel={getSelectedLabel}
            searchKey='search'
            view='dropdown'
            setSelectedItems={setSelected}
            isAllSelected={selected =>
              metaCount && selected.length === metaCount
            }
            setMetaCount={count => {
              if (!metaCount) {
                setMetaCount(count)
              }
            }}
            onClick={handleSelect}
          />
        </ModalRow>
        {selectedItems && !!selectedItems.length && (
          <ModalRow>
            <span
              className='modal__label modal__label--margin'
              style={{ cursor: 'pointer' }}
              onClick={toggleBuildingListActive}
            >
              <span className='modal__label--black modal__label--margin'>
                {t('ShowBuildingList')}
              </span>
              {selectedItems.length}
            </span>
            <button
              type='button'
              className={hideBuildingList}
              style={{ marginTop: 0 }}
              onClick={toggleBuildingListActive}
            />
          </ModalRow>
        )}
        {isBuildingListActive && selectedItems && !!selectedItems.length && (
          <ModalRow>
            <div
              className={styles.listWrapper}
              style={{
                padding: '8px',
                width: '486px',
                boxSizing: 'border-box',
                maxHeight: '200px',
                overflowY: 'auto',
                overflowX: 'hidden',
              }}
            >
              {selectedItems.map(item => (
                <ValueItem
                  inline
                  profile
                  round
                  cleanable
                  key={item.id}
                  text={item.address_obj?.value || item.address}
                  id={item.id}
                  className={valueClassName}
                  onRemove={() => handleRemove(item)}
                />
              ))}
            </div>
          </ModalRow>
        )}
      </ModalBody>
      <ModalButtons>
        <Button.Save disabled={!isChanged} onClick={handleClick}>
          {t('SaveBuildings')}
        </Button.Save>
        <Button.Cancel onClick={handleClose}>
          {t('Common:Cancel')}
        </Button.Cancel>
      </ModalButtons>
    </Modal>
  )
}

export default BuildingMappingModal
