// @flow

import { getApi, postRaw, deleteApi, patchRaw } from './api.core'

export const importDocument = (params: { file: number }) =>
  postRaw(`api/document-mapping/import/`, params)
export const getDocumentMappingList = (params: { page: number }) =>
  getApi(`api/document-mapping/import/`, params)
export const getArchivedDocumentMappingList = (params: { page: number }) =>
  getApi(`api/archived/document-mapping/import/`, params)
export const getDocumentMapping = (id: string, params: Object) =>
  getApi(`api/document-mapping/import/${id}/`, params)
export const deleteDocumentMapping = (id: string) =>
  deleteApi(`api/document-mapping/import/${id}/`)
export const patchDocumentMapping = (id: string, params: Object) =>
  patchRaw(`api/archived/document-mapping/import/${id}/`, params)
export const getDocumentMappingStatus = (eventId: string) =>
  getApi(`api/document-mapping/event/${eventId}/`)
export const setStep1 = (id: string, params: Object) =>
  postRaw(`api/document-mapping/import/${id}/step1/`, params)
export const setStep2 = (id: string, params: Object) =>
  postRaw(`api/document-mapping/import/${id}/step2/`, params)
export const setStep3 = (id: string, params: Object) =>
  postRaw(`api/document-mapping/import/${id}/step3/`, params)
export const setStep4 = (id: string, params: Object) =>
  postRaw(`api/document-mapping/import/${id}/step4/`, params)
export const notify = (id: string) =>
  postRaw(`api/document-mapping/${id}/notify/`)
export const getDocumentList = (id: string, params: Object) =>
  getApi(`api/document-mapping/import/${id}/documents/`, params)
