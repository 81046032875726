// @flow

import { getApi, patchRaw, postRaw } from './api.core'

const BASE_PATHNAME = 'api/v2/newsband-feedback/'

export const getFeedbackList = params => getApi(BASE_PATHNAME, params)
export const getFeedback = ({ id }) => getApi(`${BASE_PATHNAME}${id}/`)
export const sendFeedback = params => postRaw(BASE_PATHNAME, params)
export const updateFeedback = (id, params) =>
  patchRaw(`${BASE_PATHNAME}${id}/`, params)
