// @flow

import React, { useState, Fragment, useEffect, memo } from 'react'
import Article from './Article'

import HeightGrabber from './HeightGrabber'
import File from './File'
import {
  COLUMN_HEIGHT,
  MIN_NEWS_HEIGHT,
  HEIGHT_BETWEEN_NEWS,
} from '../NewsPage.constants'

type OneNewsProps = {
  el: Object,
  freeColumnHeight: number,
  onMount: number => void,
}

const OneNews = memo((props: OneNewsProps) => {
  const { freeColumnHeight, el, config, isVisible } = props
  const [realHeight, setHeight] = useState(0)
  const [isScrollable, changeScrollableMode] = useState(false)
  useEffect(() => {
    const stabHeight =
      freeColumnHeight < MIN_NEWS_HEIGHT
        ? freeColumnHeight - HEIGHT_BETWEEN_NEWS
        : 0

    if (realHeight) {
      props.onMount({
        height: realHeight,
        isScrollable,
        stabHeight,
      })
    }
  }, [realHeight])

  const getStyles = () => {
    if (config) {
      return {
        height: `${config.height}px`,
        overflowY: config.isScrollable ? 'scroll' : 'inherit',
        opacity: isVisible ? 1 : 0,
      }
    }

    return {
      height: `${realHeight - HEIGHT_BETWEEN_NEWS}px`,
      overflowY: isScrollable ? 'scroll' : 'inherit',
    }
  }

  const handleGrabHeight = height => {
    if (height > freeColumnHeight && freeColumnHeight >= MIN_NEWS_HEIGHT) {
      changeScrollableMode(true)
      setHeight(freeColumnHeight - HEIGHT_BETWEEN_NEWS)
    }

    if (height > freeColumnHeight && freeColumnHeight < MIN_NEWS_HEIGHT) {
      if (height > COLUMN_HEIGHT) {
        setHeight(COLUMN_HEIGHT)
        changeScrollableMode(true)
      } else {
        setHeight(height)
      }
    }

    if (height < freeColumnHeight) {
      setHeight(height)
    }

    if (height === freeColumnHeight) {
      setHeight(height)
    }
  }

  if (!realHeight && !config) {
    return <HeightGrabber element={el} onSetHeight={handleGrabHeight} />
  }

  if (realHeight || config) {
    return (
      <Fragment>
        {Boolean(config) && config.stabHeight > 0 && (
          <div
            className='public-building-page__news-page-one'
            style={{
              height: config.stabHeight,
              opacity: 0,
            }}
          />
        )}

        <Article data={el} style={getStyles()}>
          {el.file_objs.map(el => (
            <File el={el} key={el.id} />
          ))}
        </Article>
      </Fragment>
    )
  }

  return null
})

export default OneNews
