// @flow

import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'

import Block, { Header, SimpleBlock } from '../Block'
import Button from '../Button'
import Loader from '../Loader'
import Nbsp from '../NBSP'
import TileRow from '../TileList/TileRow'
import User from '../UserItem'
import * as actions from './FriendsRequests.actionTypes'

import styles from './FriendsRequests.module.scss'

type Props = {
  dispatch: Object => void,
  initiated: boolean,
  items: Array<Object>,
  meta: Object,
  onInit: () => void,
  pageSize: number,
  t: string => string,
  working: boolean,
}

class FriendsRequests extends Component<Props> {
  componentDidUpdate(prevProps) {
    if (this.props.initiated && !prevProps.initiated) {
      this.props.onInit()
    }
  }

  getSafePage = () => {
    const {
      items,
      meta: { curr_page: currPage },
    } = this.props

    if (items.length > 1) {
      return currPage
    }

    if (currPage > 1) {
      return currPage - 1
    }

    return null
  }

  load = (page: number) => {
    const { pageSize } = this.props

    this.props.dispatch({
      type: actions.FRIENDS_REQUESTS_PAGE,
      params: { page_size: pageSize, page },
    })
  }

  loadPrev = () => {
    const { meta } = this.props
    this.load(meta.curr_page - 1)
  }

  loadNext = () => {
    const { meta } = this.props
    this.load(meta.curr_page + 1)
  }

  handle = (user, accept) => {
    const { pageSize } = this.props

    this.props.dispatch({
      type: actions.FRIENDS_REQUESTS_HANDLE,
      params: { page_size: pageSize, page: this.getSafePage() },
      accept,
      user,
    })
  }

  renderHeader = () => {
    const {
      working,
      meta: { count },
    } = this.props

    return (
      <Header header>
        {this.props.t('RequestsTitle')}
        <Nbsp />
        {!working && !!count && (
          <span className='unit__title-num'>{count}</span>
        )}
      </Header>
    )
  }

  renderButton = (id, working) => (
    <Button.Save
      blank
      icon='check'
      working={working}
      onClick={() => this.handle(id, true)}
    >
      {this.props.t('Accept')}
    </Button.Save>
  )

  renderItem = (user: Object) => {
    const { id, accept, decline } = user

    return (
      <User data={user} key={`user-${id}`}>
        <div>
          {this.renderButton(id, accept)}
          <Button.Cancel
            icon='cross'
            working={decline}
            onClick={() => this.handle(id, false)}
          >
            {this.props.t('Decline')}
          </Button.Cancel>
        </div>
      </User>
    )
  }

  render() {
    const {
      working,
      items,
      meta: { page_count: pageCount, curr_page: currPage },
      pageSize,
    } = this.props

    if (working) {
      return (
        <Block>
          {this.renderHeader()}
          <Loader text={false} type='medium' />
        </Block>
      )
    }

    if (!items.length) {
      return null
    }

    const paginalList = pageCount > 1
    const sliderStyle = classnames(styles.slider, {
      [styles['slider-paginal']]: paginalList,
    })

    return (
      <SimpleBlock className='u24'>
        {this.renderHeader()}
        <div className={sliderStyle}>
          {paginalList && (
            <Fragment>
              <Button.Arrow disabled={currPage < 2} onClick={this.loadPrev} />
              <Button.Arrow
                forward
                disabled={pageCount === currPage}
                onClick={this.loadNext}
              />
            </Fragment>
          )}

          <div className={styles.list}>
            <TileRow length={pageSize}>{items.map(this.renderItem)}</TileRow>
          </div>
        </div>
      </SimpleBlock>
    )
  }
}

const mapStateToProps = state => state.friendsRequests

export default compose(
  connect(mapStateToProps),
  withTranslation('Friends')
)(FriendsRequests)
