// @flow

import React, { Component } from 'react'
import classnames from 'classnames'

import styles from './ImageSquareFit.module.scss'

export const IMAGE_FIT_TYPES = {
  containAll: 'contain',
  cover: 'cover',
  containVertical: 'vertical',
}

type Props = {
  alt: string,
  fit: 'contain' | 'cover' | 'vertical',
  src: ?string,
}
type State = {
  vertical: boolean,
}

class ImageSquareFit extends Component<Props, State> {
  static defaultProps = {
    fit: IMAGE_FIT_TYPES.cover,
  }

  state = {
    vertical: false,
  }

  handleLoad = ({ target }: Object) => {
    if (target.naturalHeight > target.naturalWidth) {
      this.setState({ vertical: true })
    }
  }

  render() {
    const { alt, src, fit, style } = this.props

    if (!src) {
      return null
    }

    const { vertical } = this.state

    const imgClass = classnames({
      [styles.contain]: fit === IMAGE_FIT_TYPES.containAll,
      [styles.vertical]: fit === IMAGE_FIT_TYPES.containVertical && vertical,
    })

    return (
      <div className={styles.box}>
        <div>
          <img
            style={style}
            alt={alt}
            className={imgClass}
            src={src}
            onLoad={this.handleLoad}
          />
        </div>
      </div>
    )
  }
}

export default ImageSquareFit
