// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../../../Button'

type Props = {
  dirty: boolean,
  onCancel: () => void,
  onSave: () => void,
  working: boolean,
}

const ButtonsBar = (props: Props) => {
  const { dirty, working } = props

  const { t } = useTranslation('Request')

  return (
    <div className='request-info__buttons'>
      <Button.Save disabled={!dirty} working={working} onClick={props.onSave}>
        {t('Save')}
      </Button.Save>
      <Button.Cancel onClick={props.onCancel}>{t('Cancel')}</Button.Cancel>
    </div>
  )
}

export default ButtonsBar
