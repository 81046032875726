/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type OffersTable_query$ref: FragmentReference;
declare export opaque type OffersTable_query$fragmentType: OffersTable_query$ref;
export type OffersTable_query = {|
  +allOffers: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
        +finalPrice: ?string,
        +orderCount: ?number,
        +provider: {|
          +name: string,
          +id: string,
          +image: ?{|
            +origin: ?string
          |},
        |},
        +gallery: ?$ReadOnlyArray<?{|
          +id: ?string,
          +origin: ?string,
          +default: ?boolean,
          +thumbnails: ?$ReadOnlyArray<?{|
            +url: ?string,
            +size: ?$ReadOnlyArray<?number>,
          |}>,
        |}>,
      |}
    |}>,
  |},
  +$refType: OffersTable_query$ref,
|};
export type OffersTable_query$data = OffersTable_query;
export type OffersTable_query$key = {
  +$data?: OffersTable_query$data,
  +$fragmentRefs: OffersTable_query$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "origin",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "OffersTable_query",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "pageSize",
      "type": "Float",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "page",
      "type": "Float",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "first",
      "type": "Int",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "store",
      "type": "String",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "providerName",
      "type": "String",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "categoryId",
      "type": "String",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "allOffers",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "categoryId",
          "variableName": "categoryId"
        },
        {
          "kind": "Variable",
          "name": "page",
          "variableName": "page"
        },
        {
          "kind": "Variable",
          "name": "providerName",
          "variableName": "providerName"
        },
        {
          "kind": "Variable",
          "name": "store",
          "variableName": "store"
        }
      ],
      "concreteType": "OfferNodeFilterConnection",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "totalCount",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "OfferNodeFilterEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "OfferNode",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "finalPrice",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "orderCount",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "provider",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "ProviderNode",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v0/*: any*/),
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "image",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "ProviderImageNode",
                      "plural": false,
                      "selections": [
                        (v2/*: any*/)
                      ]
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "gallery",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "GalleryImageNode",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/),
                    (v2/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "default",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "thumbnails",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "Thumbnail",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "url",
                          "args": null,
                          "storageKey": null
                        },
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "size",
                          "args": null,
                          "storageKey": null
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b6b3e8288e8a7d917d72024179ae766b';
module.exports = node;
