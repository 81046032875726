// @flow

import React from 'react'
import classnames from 'classnames'
import type { Node } from 'react'

type Props = {
  children?: Node,
  className?: string,
  title?: string,
  valueClassName?: string,
}

const Cell = ({ title, children, valueClassName, className }: Props) => {
  const valueClass = classnames('bar__cell-value', valueClassName)
  const rootClassName = classnames('bar__cell', className)

  return (
    <div className={rootClassName}>
      {!!title && <span className='bar__cell-title'>{title}</span>}
      <span className={valueClass}>{children}</span>
    </div>
  )
}

export default Cell
