/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type OrderProfileCustomer_data$ref: FragmentReference;
declare export opaque type OrderProfileCustomer_data$fragmentType: OrderProfileCustomer_data$ref;
export type OrderProfileCustomer_data = {|
  +clientInfo: ?any,
  +$refType: OrderProfileCustomer_data$ref,
|};
export type OrderProfileCustomer_data$data = OrderProfileCustomer_data;
export type OrderProfileCustomer_data$key = {
  +$data?: OrderProfileCustomer_data$data,
  +$fragmentRefs: OrderProfileCustomer_data$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "OrderProfileCustomer_data",
  "type": "CustomerOrderNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "clientInfo",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'c48a276655440cc2f346ded7d17739ba';
module.exports = node;
