// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'

import TileBuildingManager from '../TileBuildingManager'
import { PREVIEW_GREAT_SIZE, USER_GROUPS } from '../../constants'
import Card from '../Card'
import Stat from '../Stat'
import Icon from '../Icon/Icon'

import styles from './TableItem.module.scss'

type Props = {
  canToggle: boolean,
  data: Object,
  onToggle: number => void,
  selected: boolean,
}

class TileItem extends Component<Props> {
  toggle = ({ target: { value } }) => {
    this.props.onToggle(parseInt(value, 10))
  }

  render() {
    const {
      data,
      canToggle,
      selected,
      canTogglePage = true,
      onToggle,
    } = this.props

    if (data.fake) {
      return <div className='buildings-tile-block buildings-tile-fake' />
    }

    const {
      address_obj: { value },
      dweller_count: dwellerCount,
      flat_count: flatCount,
      id,
      photo_url: photoPath,
      photo_obj: photo,
      owner_objs: managers,
    } = this.props.data

    const avatar =
      photoPath ||
      (photo && photo.id ? photo[PREVIEW_GREAT_SIZE] || photo.preview : null)

    return (
      <Card
        text={value}
        link={`/building/${id}`}
        checked={selected}
        value={id}
        canToggle={onToggle && canToggle && canTogglePage}
        imgSrc={avatar}
        icon={!avatar && <Icon id='building' className={styles.tileIcon} />}
        onChange={this.toggle}
      >
        <Stat iconId='user' count={dwellerCount} />
        <Stat iconId='apartment' count={flatCount} />
        {!!managers.length && <TileBuildingManager managers users={managers} />}
      </Card>
    )
  }
}

const mapStateToProps = state => ({
  canToggle: state.login.user.group === USER_GROUPS.admin,
})

export default connect(mapStateToProps)(TileItem)
