// @flow

import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { get } from 'lodash-es'

import Button from '../Button'
import { recoverSetupPass } from '../../../core/api/api.system'
import { getUk } from '../../../utils/commonSelectors'
import Input from '../Input/Input'
import styles from './PasswordResetForm.module.scss'
import { PASS_INFO_LINK } from '../../../constants'
import { passwordSecureRegExp } from '../../../utils/utils'

type Props = {
  hash: string,
  setVerifiedHash: boolean => void,
  setWorking: boolean => void,
}

const PasswordResetForm = (props: Props) => {
  const { hash } = props
  const { t } = useTranslation('Registration')
  const { name: ukName } = useSelector(state => getUk(state))

  const formik = useFormik({
    validateOnChange: false,
    initialValues: {
      password: '',
      passwordConfirm: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required(t('"Field" is not allowed to be empty'))
        .matches(passwordSecureRegExp, t('PasswordSecureValidation')),
      passwordConfirm: Yup.mixed()
        .required(t('"Field" is not allowed to be empty'))
        .oneOf(
          [Yup.ref('password')],
          t('"Field" must be one of [refpassword]')
        ),
    }),
    onSubmit: () => {
      props.setWorking(true)

      recoverSetupPass(hash, { password: formik.values.password })
        .then(data => {
          const profileId = get(data, 'profile_id')

          if (profileId) {
            window.location = '/'
          }
        })
        .catch(() => props.setVerifiedHash(false))
    },
  })

  const getError = () => {
    if (formik.errors.password === t('PasswordSecureValidation')) {
      return [
        <>
          {' '}
          {formik.errors.password}{' '}
          <a
            target='_blank'
            href={PASS_INFO_LINK}
            rel='noopener noreferrer'
            className={styles.errorLink}
          >
            {t('Details')}
          </a>
        </>,
      ]
    } else {
      return formik.errors.password && [formik.errors.password]
    }
  }

  return (
    <>
      <div
        className={styles.title}
        dangerouslySetInnerHTML={{
          __html: t('SuccessResetTitleSecond', { uk: ukName }),
        }}
      ></div>
      <form onSubmit={formik.handleSubmit}>
        <Input
          type='password'
          value={formik.values.password}
          name='password'
          errors={getError()}
          placeholder={t('PasswordPlaceholder')}
          onChange={formik.handleChange}
        />
        <Input
          type='password'
          value={formik.values.passwordConfirm}
          name='passwordConfirm'
          placeholder={t('PasswordConfirmPlaceholder')}
          errors={[formik.errors.passwordConfirm]}
          onChange={formik.handleChange}
        />
        <Button type='submit' text={t('Confirm')} />
      </form>
    </>
  )
}

export default PasswordResetForm
