// @flow

import React from 'react'
import type { Node } from 'react'

import NewTable from '../../NewTable'
import TableHead from './TableHead'
import TableBody from './TableBody'

type Props = {
  isLoading: boolean,
  list: Array<Object>,
  onRemove: Function,
  onSelect: Function,
  selectedItems: Array<any>,
}

const AutomateTable = (props: Props): Node => {
  const { list, selectedItems, isLoading } = props

  return (
    <NewTable loading={isLoading}>
      <TableHead />
      <TableBody
        list={list}
        selectedItems={selectedItems}
        onSelect={props.onSelect}
        onRemove={props.onRemove}
      />
    </NewTable>
  )
}

export default AutomateTable
