// @flow

import React from 'react'
import { connect } from 'react-redux'
import classnames from 'classnames'

import Icon from '../../Icon'
import { getThemeColor } from '../../../utils/commonSelectors'
import { isActive } from '../Menu.utils'
import FallbackIcon from './FallbackIcon'

import styles from './MenuListItem.module.scss'
import CustomNavLink from '../../CustomNavLink/CustomNavLink'

const MenuListItem = props => {
  const {
    hasSub,
    linkTo,
    iconPath,
    text,
    exact,
    name,
    mainPathName,
    collapsed,
    themeColor,
    unviewedCounter,
  } = props

  const minimized = collapsed || (hasSub && name !== mainPathName)

  const textClass = classnames(styles.text, {
    [styles.textCollapsed]: minimized,
  })
  const counterClass = classnames(styles.counter, {
    [styles.roundCounter]: minimized,
  })
  const itemClass = classnames(styles.item, 'menu__item', {
    [styles.itemCollapsed]: minimized,
  })
  const counterStyle = {
    backgroundColor: minimized && unviewedCounter ? themeColor : 'inherit',
  }
  const unviewedCounterStyle = {
    color: !minimized ? themeColor : '#fff',
  }
  const activeClassName = classnames(styles.active, 'menu__item-active')

  const proccessedCounter = unviewedCounter > 99 ? '99+' : unviewedCounter

  return (
    <li className={itemClass}>
      <CustomNavLink
        to={linkTo}
        name={name}
        activeClassName={activeClassName}
        isActive={isActive(name, mainPathName)}
        onClick={props.onOpenMenu}
        {...{ exact }}
      >
        <div {...(minimized ? { title: text } : {})}>
          <Icon className={styles.icon} id={iconPath} fallback={FallbackIcon} />
          <span className={textClass}>{text}</span>
        </div>
        {!!unviewedCounter && (
          <span className={counterClass} style={counterStyle}>
            <span style={unviewedCounterStyle}>{proccessedCounter}</span>
          </span>
        )}
      </CustomNavLink>
    </li>
  )
}

const mapStateToProps = (state, props) => ({
  themeColor: getThemeColor(state),
  unviewedCounter: state.counters[props.counterKey],
})

export default connect(mapStateToProps)(MenuListItem)
