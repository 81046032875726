// @flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ModalButtons from '../../Modal/ModalButtons'
import Button from '../../Button'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import ModalRow from '../../Modal/ModalRow'
import ModalLabel from '../../Modal/ModalLabel'
import InputField from '../../InputField'
import ModalBody from '../../Modal/ModalBody'
import type { Node } from 'react'
import { addEmailAccount } from '../../../core/api/api.mail'
import { ERROR_REQUEST_STAGE } from './EmailIntegrationModal.constant'
import ModalHead from '../../Modal/ModalHead'
import { get } from 'lodash-es'

type Props = {|
  onClose: () => void,
  setErrorRequest: () => void,
  setStage: () => void,
|}

const CheckEmailModal = (props: Props): Node => {
  const { t } = useTranslation('EmailIntegrationNylas')
  const { onClose, setStage, setErrorRequest, isPrivate } = props
  const [loading, setLoading] = useState(false)
  const formik = useFormik({
    validateOnBlur: true,
    validateOnChange: false,
    initialValues: { email: '' },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(t('Common:EnterValidEmailErrorText'))
        .required(t('Common:FieldIsRequired')),
    }),
    onSubmit: ({ email }) => {
      setLoading(true)

      addEmailAccount({ email, corporate: !isPrivate })
        .then(a => {
          setLoading(false)
          window.location.assign(a.auth_url)
        })
        .catch(error => {
          const data = get(error, ['message', 'response', 'data'])
          setErrorRequest(data.errors ? data.errors : data)
          setStage(ERROR_REQUEST_STAGE)
          setLoading(false)
        })
    },
  })

  return (
    <>
      <ModalHead title={t('AddAccount')} />
      <form onSubmit={formik.handleSubmit}>
        <ModalBody>
          <ModalRow>
            <ModalLabel>{t('emailAddress')}</ModalLabel>
          </ModalRow>
          <InputField
            autoFocus
            disabled={loading}
            name='email'
            placeholder={t('emailAddress')}
            value={formik.values.email}
            error={formik.errors.email}
            onChange={formik.handleChange}
          />
        </ModalBody>
        <ModalButtons>
          <Button.Save type='submit'>{t('Common:Continue')}</Button.Save>
          <Button.Cancel onClick={onClose}>{t('Common:Cancel')}</Button.Cancel>
        </ModalButtons>
      </form>
    </>
  )
}

export default CheckEmailModal
