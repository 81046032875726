import WebViewer from '@pdftron/pdfjs-express-viewer'
import React, { useEffect, useRef } from 'react'
import styles from './Viewer.module.scss'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const ViewerComponent = ({ file }) => {
  const viewer = useRef(null)
  const {
    i18n: { language = 'en' },
  } = useTranslation()

  const { pdf_preview_api_key } = useSelector(state => state.init)

  useEffect(() => {
    WebViewer(
      {
        path: '/static/pdfjsexpress',
        extension: 'pdf',
        initialDoc: file.origin,
        licenseKey: pdf_preview_api_key,
      },
      viewer.current
    ).then(instance => {
      const { Core, UI } = instance
      UI.setLanguage(language)

      UI.setHeaderItems(header => {
        header.getHeader('default').delete('menuButton')
      })

      const theme = UI.Theme
      UI.setTheme(theme.DARK)
      instance.openElements(['leftPanel'])

      const style = UI.iframeWindow.document.documentElement.style

      style.setProperty(`--document-background-color`, 'rgba(0, 0, 0, 0)')

      let FitMode = UI.FitMode

      Core.documentViewer.addEventListener('documentLoaded', function () {
        instance.UI.setFitMode(FitMode.FitWidth)
      })
    })
  }, [])

  return (
    <div className={styles.wrapper}>
      <div className={styles.webviewer} ref={viewer}></div>
    </div>
  )
}

export default ViewerComponent
