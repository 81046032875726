// @flow

import React from 'react'
import type { Node } from 'react'

import NewMailReplyForm from '../../../components/Mail/NewMailReplyForm'

type Props = {|
  isMass: boolean,
  onCreate: Object => void,
  onDocsEnabled?: boolean => void,
  onToggleMass: boolean => void,
  thread: Object,
|}

const NewMailThreadCreate = (props: Props): Node => {
  const { thread, isMass, files } = props

  return (
    <section className='unit unit--default'>
      <NewMailReplyForm
        {...thread}
        withProviders
        showAddButton
        isMass={isMass}
        files={files}
        onDocsEnabled={props.onDocsEnabled}
        onSave={props.onCreate}
        onToggleMass={props.onToggleMass}
      />
    </section>
  )
}

export default NewMailThreadCreate
