// @flow

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'

import ValueItem from '../Select/ValueItem'
import { getUrlForAvatar, getUserName, isStaffUser } from '../../utils/utils'

type Props = {
  items: Array<Object>,
  maxPreview?: integer,
  outbound?: string,
  role?: boolean,
  t: string => string,
}

type State = {
  showAll: boolean,
}

function getWorkerAvatar(item) {
  return item.avatar || '/static/img/icon_user.png'
}

class RequestManagers extends Component<Props, State> {
  state = {
    showAll: false,
  }

  renderManager = item => (
    <ValueItem
      inline
      profile
      round
      margin
      avatar={getUrlForAvatar(item)}
      isLink={!this.props.outbound}
      profileId={item.id}
      group={item.group}
      key={`worker-${item.id || item.owner}`}
      text={getUserName(item)}
      role={
        this.props.role &&
        this.props.t(`Role_${item.group || item.role || 'user'}`)
      }
    />
  )

  renderWorker = item => {
    return (
      <ValueItem
        inline
        profile
        margin
        round
        avatar={getWorkerAvatar(item)}
        isLink={!this.props.outbound}
        profileId={item.id}
        key={`worker-${item.id || item.owner}`}
        text={getUserName(item)}
        role={
          this.props.role &&
          this.props.t(`Role_${item.group || item.role || 'user'}`)
        }
      />
    )
  }

  toggleShowAll = () => this.setState(state => ({ showAll: !state.showAll }))

  render() {
    const { items, t, maxPreview } = this.props
    const { showAll } = this.state

    if (items.length > 0) {
      return (
        <div>
          {items
            .slice(0, (!showAll && maxPreview) || items.length)
            .map(item =>
              isStaffUser(item)
                ? this.renderManager(item)
                : this.renderWorker(item)
            )}
          {!showAll && items.length > maxPreview && (
            <div className='bar__preview-badge' onClick={this.toggleShowAll}>
              +{items.length - maxPreview}
            </div>
          )}
        </div>
      )
    }

    return (
      <span className='bar__submit-label_light bar__submit-label no-manager-assignet-label'>
        {t('ManagerMissing')}
      </span>
    )
  }
}

RequestManagers.defaultProps = {
  items: [],
}

export default withTranslation('Request')(RequestManagers)
