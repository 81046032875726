// @flow

import React from 'react'
import type { Node } from 'react'

import { cutText } from '../../utils/utils'

import styles from './TileBuildingManager.module.scss'

type Props = {
  users: Object,
}

const TileBuildingManager = ({ users }: Props): Node => {
  const managersCount = users.length

  if (managersCount === 0) return null

  const altText = users
    .map(user => `${user.name} ${user.second_name}`)
    .join(', ')

  const { avatar_obj: avatar, name, second_name: surname } = users[0]

  return (
    <div className={styles.managers} title={altText}>
      <i className='table-item__user-pic'>
        <img src={avatar.preview} />
      </i>
      <span>{cutText(`${name} ${surname}`, managersCount > 1 ? 8 : 13)}</span>
      {managersCount > 1 && (
        <span className={styles.circle}>+{managersCount - 1}</span>
      )}
    </div>
  )
}

export default TileBuildingManager
