// @flow

import * as actions from './BuildingCreate.actionTypes'

const initialState = {
  initiated: false,
  working: false,
  permissions: {},
  notifications: [],
  error: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.BUILDING_CREATE_INITIATNG:
      return initialState
    case actions.BUILDING_CREATE_INITIATED:
      return {
        ...state,
        initiated: true,
        permissions: action.permissions,
      }
    case actions.BUILDING_CREATE_SAVE:
      return {
        ...state,
        working: true,
      }
    case actions.BUILDING_CREATE_ERROR:
      // it shows in popup
      return {
        ...state,
        working: false,
      }
    case actions.BUILDING_HIDE_EROR:
      return {
        ...state,
        working: false,
        error: null,
      }
    case actions.BUILDING_SHOW_CONFIRM:
      // it shows in popup
      return {
        ...state,
        managersModal: true,
      }
    case actions.BUILDING_HIDE_CONFIRM:
      return {
        ...state,
        working: false,
        managersModal: false,
      }

    default:
      return state
  }
}
