// @flow

import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash-es'
import { push } from 'connected-react-router'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import Button from '../Button'
import Table from '../Table'
import { getFormattedDate, getUserName } from '../../utils/utils'
import { notify, getDocumentList } from '../../core/api/api.documentMapping'
import TopPagination from '../Pagination/TopPagination'
import BottomPagination from '../Pagination/BottomPagination'
import { getCurrentPage } from '../../utils/routing'
import {
  COMPLETE_STATUS,
  STATUSES,
  EMAIL_AND_FILE_TYPE,
} from './MappedDocument.constants'

import styles from '../DocumentMapping/DocumentMappingTableBodyRow.module.scss'

const Step4 = props => {
  const { notificationType, id } = props
  const [loading, setLoading] = useState(true)
  const [documents, setDocuments] = useState([])
  const [meta, setMeta] = useState(null)
  const location = useLocation()

  const { t } = useTranslation('DocumentMapping')

  const tableClass = classnames('table table--default', {
    'working-overlay': loading,
  })

  const dispatch = useDispatch()

  useEffect(() => {
    if (loading) {
      const page = getCurrentPage(location)

      getDocumentList(id, { page }).then(data => {
        setDocuments(data.results.objects)
        setMeta(data.meta)
        setLoading(false)
      })
    }
  }, [loading])

  useEffect(() => {
    setLoading(true)
  }, [location])

  const handleClick = () => {
    dispatch(push(`/files/map/`))
  }

  const handleUpdateStatus = () => setLoading(true)

  const handleOpenUser = (userId, availableToManager, deleted) => {
    if (!availableToManager || deleted) {
      return
    }

    dispatch(push(`/profile/${userId}/`))
  }

  const handleChange = id => e => {
    e.stopPropagation()

    setLoading(true)

    notify(id).then(data => {
      setDocuments(
        documents.map(document => {
          if (document.id === id) {
            return { ...document, updated: data.updated }
          }

          return document
        })
      )

      setLoading(false)
    })
  }

  return (
    <section className='unit unit--default bar'>
      <div className='settings-personal__form-row'>
        <div className='alert--warning-box'>
          <div className='alert alert--small alert--warning alert--block alert--center alert--top'>
            <p className='alert__text'>{t('Step4Description')}</p>
          </div>
        </div>
      </div>
      <div className='toolbox'>
        <div className='toolbox__cell'>
          <div className='toolbox__cell'>
            <Button.Save disabled={loading} onClick={handleClick}>
              {t('Complete')}
            </Button.Save>
          </div>
        </div>
        <div className='toolbox__cell'>
          <div className='toolbox__cell'>
            <Button.Save disabled={loading} onClick={handleUpdateStatus}>
              {t('UpdateStatus')}
            </Button.Save>
          </div>
        </div>
        <div className='toolbox__cell toolbox__cell--right'>
          <TopPagination
            classes='pager pager--large pager--default-2'
            meta={meta}
          />
        </div>
      </div>
      <div className={tableClass}>
        <div className='table__wrapper' style={{ tableLayout: 'fixed' }}>
          <Table.HeaderRow>
            <Table.HeaderCell
              title={t('DocumentMappingUserSort')}
              style={{ width: '30%' }}
            />
            <Table.HeaderCell
              title={t('DocumentMappingFileNameSort')}
              style={{ width: '30%' }}
            />
            <Table.HeaderCell
              title={t('DocumentMappingStatusSort')}
              style={{ width: '20%' }}
            />
            <Table.HeaderCell
              title={t('DocumentMappingDateSort')}
              style={{ width: '20%' }}
            />
          </Table.HeaderRow>
          <>
            {documents.map(document => {
              const preview = get(document, ['user', 'avatar', 'preview'])
              const filename = get(document, ['file_obj', 'filename'])
              const userId = get(document, ['user', 'id'])
              const deleted = get(document, ['user', 'deleted'])
              const status = get(document, 'status')
              const updated = get(document, 'updated')
              const id = get(document, 'id')
              const userName = getUserName(get(document, ['user']))
              const availableToManager = get(document, [
                'user',
                'available_to_manager',
              ])
              const formattedDate = updated ? getFormattedDate(updated) : '-'

              const statusText =
                status === COMPLETE_STATUS
                  ? notificationType === EMAIL_AND_FILE_TYPE
                    ? t('EmailAndFileSendStatus')
                    : t('EmailStatus')
                  : t(STATUSES[status][0])

              const statusClass = styles[STATUSES[status][1]]

              return (
                <Table.BodyRow
                  key={id}
                  id={userId}
                  handleClick={id =>
                    handleOpenUser(id, availableToManager, deleted)
                  }
                  style={{ cursor: (deleted && 'default') || 'pointer' }}
                >
                  <Table.BodyCell title={userName}>
                    <i className='table-item__user-pic'>
                      <img src={preview} alt='user' />
                    </i>
                  </Table.BodyCell>
                  <Table.BodyCell title={filename} />
                  <Table.BodyCell>
                    <div className='table-item table-item__text'>
                      <span className={statusClass}>{statusText}</span>
                    </div>
                  </Table.BodyCell>
                  <Table.BodyCell>
                    <div
                      className='table-item table-item__text'
                      title={formattedDate}
                    >
                      <span>{formattedDate}</span>
                    </div>
                    {!deleted && (
                      <div className='table-extra table-extra--settings'>
                        <Button.Save onClick={handleChange(id)}>
                          {t('SendAgain')}
                        </Button.Save>
                      </div>
                    )}
                  </Table.BodyCell>
                </Table.BodyRow>
              )
            })}
          </>
        </div>
      </div>
      <BottomPagination classes='paginator paginator--middle' meta={meta} />
    </section>
  )
}

export default Step4
