// @flow

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'

import Notifications from '../../components/Notification/NotificationList'
import Loader from '../../components/Loader'
import UserListInfo from '../../components/User/UserListInfo'
import UserList from '../../components/User/UserList'
import RouterTabs from '../../components/Tabs/RouterTabs'
import Pan from '../../components/Tabs/Pan'
import * as actions from './SettingsUsers.actionTypes'

type Props = {
  count: number,
  dispatch: () => void,
  infoInitiated: boolean,
  listInitiated: boolean,
  match: Object,
  notifications: Array<Object>,
  t: string => string,
}

class SettingsUsers extends Component<Props> {
  componentWillUnmount() {
    this.props.dispatch({ type: actions.SETTINGS_USERS_RESET })
  }

  onInfoInitiated = () => {
    this.props.dispatch({ type: actions.SETTINGS_USERS_INFO_READY })
  }

  onListInitiated = () => {
    this.props.dispatch({ type: actions.SETTINGS_USERS_LIST_READY })
  }

  onCompleteResend = message => {
    this.props.dispatch({ type: actions.SETTINGS_USERS_NOTIFICATION, message })
  }

  render() {
    const { infoInitiated, notifications, count } = this.props
    const initiating = !infoInitiated
    const contentClass = classnames('content__col', {
      'content-initiating': initiating,
    })

    return (
      <div className={contentClass}>
        {initiating && <Loader text={false} type='big' />}
        <Notifications
          style={{ marginTop: '1.5rem' }}
          notifications={notifications}
        />
        <UserListInfo onInit={this.onInfoInitiated} />
        <section className='unit unit--default'>
          <RouterTabs count={count}>
            <Pan
              id='settings-users-workers-tab'
              label={this.props.t('ManagersAndAdministrators')}
              linkHash='#managers'
            >
              <UserList
                isStaff
                key='manager-list'
                onInit={this.onListInitiated}
                onCompleteResend={this.onCompleteResend}
              />
            </Pan>
            <Pan
              id='settings-users-dwellers-tab'
              label={this.props.t('DwellersAndLandlords')}
              linkHash='#dwellers'
            >
              <UserList
                key='dweller-list'
                onInit={this.onListInitiated}
                onCompleteResend={this.onCompleteResend}
              />
            </Pan>
            <Pan
              id='settings-users-external-contacts-tab'
              label={this.props.t('ExternalContacts')}
              linkHash='#external-contacts'
              svg='about'
              tooltip={this.props.t('ExternalContactsTooltip')}
            >
              <UserList
                externalContact
                key='external-contacts'
                onInit={this.onListInitiated}
                onCompleteResend={this.onCompleteResend}
              />
            </Pan>
          </RouterTabs>
        </section>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state.settingsUsers,
  count: state.userList.meta.count,
})

export default compose(
  withTranslation('User'),
  withRouter,
  connect(mapStateToProps)
)(SettingsUsers)
