// @flow

import React, { useState, useEffect, useMemo } from 'react'
import moment from 'moment'
import ClickOutHandler from 'react-onclickout'

import Button from '../../../Button'
import Select from '../../../Select/Select'
import ReactCalendar from '../../../filters/CalendarFilter/ReactCalendar'
import TextInput from '../../../Post/TextInput'
import { getTime, getTimeMinuteOptions } from '../../../Post/Post.utils'
import NewSelectSimple from '../../../NewSelectSimple'

import styles from '../../../Post/PostTimeInterval/PostTimeInterval.module.scss'

const calendarStyles = {
  position: 'absolute',
  left: 0,
  zIndex: 1000,
  background: '#fff',
  borderRadius: '0.5rem',
  border: '0.1rem solid #d0d0d0',
  boxShadow: '0 0.35rem 0.8rem rgba(0, 0, 0, 0.11)',
  margin: 0,
  overflow: 'hidden',
}

const DueDateEdit = props => {
  const { dateTime, style, clearStyle } = props

  const [isDateOpen, setDateOpen] = useState(false)
  const [date, setDate] = useState(dateTime)
  const [time, setTime] = useState(getTime(dateTime, true))

  useEffect(() => {
    const dateValue = time?.label?.split(':')

    const dueDate = date
      ? moment(date)
          .set('hour', dateValue[0])
          .set('minute', dateValue[1])
          .set('second', 0)
      : null

    props.onChange(dueDate ? dueDate.format() : null)
  }, [date, time])

  const handleOpenDate = () => setDateOpen(true)

  const onClickOut = () => setDateOpen(false)

  const options = useMemo(() => {
    let opt = getTimeMinuteOptions()

    if (moment().isSame(date, 'day')) {
      opt = opt.filter(i => {
        return (
          i.label.split(':')[0] > moment().get('hour') ||
          (i.label.split(':')[0] == moment().get('hour') &&
            i.label.split(':')[1] > moment().get('minute'))
        )
      })
    }

    return opt
  }, [date])

  const handleUpdateDate = date => {
    if (
      moment().isSame(date, 'day') &&
      moment().get('hour') * 60 + moment().get('minute') >= time.value
    ) {
      const options = getTimeMinuteOptions()

      const option = options.find(
        option =>
          option.value > moment().get('hour') * 60 + moment().get('minute')
      )

      setTime(option)
    }

    setDate(date)
  }

  const handleClear = () => {
    setDate(null)
    setTime(getTime())
  }

  return (
    <div className={styles.date} style={style}>
      <div
        className='input input--default input--active input--large date-input'
        style={style}
      >
        <TextInput
          placeholder='Input Date'
          value={date ? moment(date).format('DD.MM.YYYY') : '-'}
          onClick={handleOpenDate}
        />
        <div
          className='date-calendar'
          style={{ width: '16.5rem', zIndex: 1000 }}
        >
          {isDateOpen && (
            <ClickOutHandler onClickOut={onClickOut}>
              <ReactCalendar
                single
                minYear={1900}
                maxYear={moment().get('year') + 15}
                Select={Select}
                value={date || moment()}
                style={calendarStyles}
                onValueChange={handleUpdateDate}
                onChange={date => {
                  handleUpdateDate(date[0])
                  onClickOut()
                }}
              />
            </ClickOutHandler>
          )}
        </div>
      </div>
      <div className={styles.select}>
        <NewSelectSimple
          disabled={!date}
          name='time'
          value={date ? time : { value: null, label: '-' }}
          options={options}
          onChange={setTime}
        />
      </div>
      {date ? (
        <Button.Delete
          className={styles.clear}
          style={clearStyle}
          onClick={handleClear}
        />
      ) : null}
    </div>
  )
}

export default DueDateEdit
