// @flow

import React from 'react'
import type { Element } from 'react'

import styles from './Rabbit.module.scss'

const Rabbit = (): Element<'div'> => <div className={styles.rabbit} />

export default Rabbit
