// @flow

import React from 'react'

import Text from '../../TextClipped'

import styles from './MenuFilterItem.module.scss'

type Props = {
  onClear?: () => void,
  text: string,
}

const MenuFilterValue = (props: Props) => {
  const { text } = props

  return (
    <span className={styles.value}>
      {!!text && (
        <Text fontSize={14} lineHeight={1.4} title={text}>
          {text}
        </Text>
      )}
      {!!props.renderValue && props.renderValue()}
      {!!props.onClear && (
        <button type='button' onClick={props.onClear}>
          &#10005;
        </button>
      )}
    </span>
  )
}

export default MenuFilterValue
