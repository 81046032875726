// @flow

import React, { useState } from 'react'
import classnames from 'classnames'
import type { Node } from 'react'

import Error from './Error'
import Cell from './Cell'

import styles from './Row.module.scss'

type Props = {
  children: Node | string,
  className?: string,
  field?: string,
  getError?: string => Array<string>,
  hasError?: boolean,
  icon?: Node,
  isValid?: string => boolean,
  label?: string,
  note?: string,
  withNotes?: boolean,
}

const SettingsRow = (props: Props): Node => {
  const {
    children,
    className,
    isValid,
    field,
    getError,
    label,
    hasError,
    icon,
    note,
    withNotes,
  } = props

  const [hint, setHint] = useState(false)

  const valid = isValid && field ? isValid(field) : true
  const valueClass = classnames(
    'input input--large input--block input--default',
    { 'input--error': !valid || hasError },
    { [styles.error]: hasError },
    className
  )

  const rowClass = classnames({
    [styles.row]: true,
    [styles.withNotes]: withNotes,
  })

  const handleMouseEnter = () => setHint(true)
  const handleMouseLeave = () => setHint(false)

  return (
    <div className={rowClass}>
      <Cell>
        {label && <label className={styles.label}>{label}</label>}
        {icon && note && (
          <span
            className={styles.icon}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {icon}
            {hint && (
              <div className={styles.tooltip}>
                <div />
                <span>{note}</span>
              </div>
            )}
          </span>
        )}
      </Cell>
      <Cell>
        <div className={valueClass}>
          {children}
          {!valid && !!getError && !!field && (
            <Error field={field} getText={getError} />
          )}
        </div>
      </Cell>
    </div>
  )
}

export default SettingsRow
