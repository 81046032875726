// @flow

import { useEffect } from 'react'

export const useKeyboard = (
  onEnter?: () => void,
  onEscape?: () => void
): void => {
  const handleKeyDown = event => {
    if ((event.key === 'Escape' || event.keyCode === 27) && onEscape) {
      event.preventDefault()
      onEscape()
    } else if ((event.key === 'Enter' || event.keyCode === 13) && onEnter) {
      event.preventDefault()
      onEnter()
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)

    return function cleanup() {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])
}
