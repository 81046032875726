// @flow

import React, { useState } from 'react'
import type { Node } from 'react'

import Loader from '../Loader'

import { getRoomType } from '../../core/api/api.room'
import { useLoad } from '../../hooks'
import RoomTypeModal from './RoomTypeModal'
import RoomTypeTable from './RoomTypeTable'

const RoomType = (): Node => {
  const [isModalOpen, setModalOpen] = useState(false)
  const [editingRoomType, setEditingRoomType] = useState(null)
  const [roomType, isLoading, , load] = useLoad(getRoomType)

  const handleClose = () => setModalOpen(false)
  const handleCreate = () => setModalOpen(true)

  return (
    <>
      {roomType ? (
        <>
          <RoomTypeTable isLoading={isLoading} onEdit={handleCreate} />
          {isModalOpen && (
            <RoomTypeModal
              load={load}
              editingRoomType={editingRoomType}
              setEditingRoomType={setEditingRoomType}
              onClose={handleClose}
            />
          )}
        </>
      ) : (
        <Loader type='big' text={false} />
      )}
    </>
  )
}

export default RoomType
