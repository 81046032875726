// @flow

import React, { useState, useRef } from 'react'
import moment from 'moment'
import OutsideClick from 'react-onclickout'
import classNames from 'classnames'
import type { Node } from 'react'

import ReactCalendar from '../filters/CalendarFilter/ReactCalendar'
import Button from '../Button'

import styles from './FieldRow.module.scss'

type Props = {
  dateValue: string,
  error?: string,
  onChangeDate: string => void,
  placeholder: string,
  toggleMenuCb?: boolean => void,
}

const DateField = (props: Props): Node => {
  const { error, placeholder, dateValue } = props

  const [menuIsOpen, setMenuOpen] = useState(false)
  const [position, setPosition] = useState('bottom')

  const selectRef = useRef()

  const handleToggle = () => {
    if (!menuIsOpen) {
      const isBottom =
        selectRef.current &&
        window.innerHeight - selectRef.current.getBoundingClientRect().bottom >
          380

      setPosition(isBottom ? 'bottom' : 'top')
    }

    setMenuOpen(!menuIsOpen)
  }

  const handleUpdateDate = date => {
    const newDate = date
      ? moment(date).set('hour', 0).set('minute', 0).set('second', 0)
      : null
    props.onChangeDate(newDate ? newDate.format() : null)
  }

  const handleClickOut = () => setMenuOpen(false)

  const calendarClass = classNames(styles.calendar, styles[position])

  const handleClear = () => {
    props.onChangeDate('')
  }

  return (
    <OutsideClick onClickOut={handleClickOut}>
      <div className={styles.deadline} ref={selectRef}>
        <div className={styles.date}>
          <Button.NewSelect
            error={error}
            placeholder={placeholder}
            selectedItems={dateValue ? [dateValue] : []}
            getSelectedLabel={value => moment(value).format('DD.MM.YYYY')}
            menuIsOpen={menuIsOpen}
            onClick={handleToggle}
          />
          {menuIsOpen && (
            <div className={calendarClass}>
              <ReactCalendar
                single
                minYear={1900}
                maxYear={moment().get('year') + 5}
                onValueChange={handleUpdateDate}
                onChange={date => {
                  handleUpdateDate(date[0])
                  setMenuOpen(false)
                }}
              />
            </div>
          )}
        </div>
        {dateValue && (
          <Button.Delete
            disabled={!dateValue}
            className={styles.clear}
            onClick={handleClear}
          />
        )}
      </div>
    </OutsideClick>
  )
}

export default DateField
