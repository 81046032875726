// @flow

import React, { useContext } from 'react'
import classNames from 'classnames'
import type { Node } from 'react'

import { useThemeColor } from '../../../../../../../hooks'
import PhaseItemContext from '../../PhaseItem.context'

import styles from './Progress.module.scss'

const Progress = (): Node => {
  const {
    phase: { progress },
  } = useContext(PhaseItemContext)

  const themeColor = useThemeColor()
  const progressClass = classNames(styles.progress, {
    [styles.bordered]: progress === 100,
  })

  return (
    <div
      className={progressClass}
      style={{
        '--themeColor': themeColor,
        width: `${Math.min(progress, 100)}%`,
      }}
    />
  )
}

export default Progress
