// @flow

import { all, put, call, fork, takeEvery } from 'redux-saga/effects'
import type { Saga } from 'redux-saga'

import api from '../../core/api'
import cookies from '../../utils/cookies'
import { serverError } from '../../components/Layout/Layout.actions'
import {
  INIT_LOAD,
  INIT_LOADED,
  INIT_ERROR,
  UPDATE_COUNTERS_SUCCESS,
} from './init.actionTypes'
import { LOGIN_SUCCESS } from '../../components/Auth/Login/Login.actionTypes'
import { UPDATE_COUNTERS } from '../../components/Layout/Layout.constants'
import { MARKETPLACE_AUTH_ERROR } from '../../core/variables'

function* watchInit() {
  yield takeEvery(INIT_LOAD, init)
}

function* watchLogin() {
  yield takeEvery(LOGIN_SUCCESS, init)
}

function* init() {
  try {
    const system = yield call(api.system.init)
    yield put({ type: INIT_LOADED, data: system })

    if (system.marketplace_token) {
      yield call(
        [cookies, cookies.setItem],
        'marketplace_token',
        system.marketplace_token,
        undefined,
        '/'
      )
      yield call(
        [cookies, cookies.setItem],
        'marketplace_url',
        system.marketplace_url,
        undefined,
        '/'
      )
      const mpSession = yield call(api.system.getMpSession)
      yield call(
        [cookies, cookies.setItem],
        'mpSessionId',
        mpSession.mpSessionId,
        undefined,
        '/'
      )
    }
  } catch (error) {
    if (error.message !== MARKETPLACE_AUTH_ERROR) {
      yield put(serverError(error))
      yield put({ type: INIT_ERROR, error })
    }
  }
}

function* watchUpdateCounter() {
  yield takeEvery(UPDATE_COUNTERS, updateCounter)
}

function* updateCounter() {
  try {
    const counters = yield call(api.profile.getCounters)
    const chatCounters = yield call(api.messages.getCounters)

    yield put({
      type: UPDATE_COUNTERS_SUCCESS,
      counters: { ...counters, ...chatCounters },
    })
  } catch (error) {
    // failed to update counters
    console.error(error)
    // yield put(serverError(error));
  }
}

export default function* (): Saga<void> {
  yield all([fork(watchInit), fork(watchLogin), fork(watchUpdateCounter)])
}
