// @flow

import React from 'react'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import NewFilesUpload from '../../../NewFilesUpload'

import styles from './FileUploader.module.scss'

import IconAdd from '../../../../../static/icons/add-thin.svg'

type Props = {
  fileDim: number,
  onStartUpload: (?Array<Object>) => void,
  onUpload: (Array<Object>) => void,
  outbound?: string,
}

const FileUploader = (props: Props): Node => {
  const { fileDim, outbound } = props

  const { t } = useTranslation('Request')

  const dropClass = classnames('drop-zone', styles.drop)

  return (
    <div
      className={styles.add}
      style={{
        width: `calc(${fileDim}% - 12px)`,
        paddingBottom: `calc(${fileDim}% - 14px)`,
      }}
      title={t('UploadFile')}
    >
      <NewFilesUpload
        multiple
        name='attach-file'
        className={dropClass}
        outbound={outbound}
        onFinishUpload={props.onUpload}
        onStartUpload={props.onStartUpload}
      >
        <IconAdd />
      </NewFilesUpload>
      <span>{t('UploadFile')}</span>
    </div>
  )
}

export default FileUploader
