// @flow

import React, { Component, Fragment } from 'react'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'
import { map, take, toString } from 'lodash-es'
import { compose } from 'redux'

import ShowAllButton from '../../ShowAllButton'
import Button from '../../Button'
import TopPagination from '../../Pagination/TopPagination'
import BottomPagination from '../../Pagination/BottomPagination'
import AddFlatPopup from '../../modals/AddFlatPopup'
import ConfirmationPopup from '../../modals/ConfirmationPopup'
import Modal from '../../Modal'
import NumberCell from '../../Flat/FlatList/NumberCell'
import Loader from '../../Loader'
import Table from '../../Table'
import FlatUsers from '../../TableCellUsers'
import addRedirectToFirstPage from '../../../hocs/addRedirectToFirstPage'
import * as actions from './BuildingFlats.actionTypes'
import { getFormattedDate } from '../../../utils/utils'
import { getLocation } from '../../../utils/commonSelectors'
import { getCurrentFilters } from '../../../utils/routing'
import Checkbox from '../../Checkbox'

const INITIAL_ITEMS_COUNT = 5

const DWELLERS_AND_LANDLORDS = {
  get_dwellers: 10,
  get_landlords: 10,
}

class BuildingFlats extends Component {
  componentDidMount() {
    const { id, dispatch, location } = this.props
    const filters = getCurrentFilters(location)

    if (filters.page) {
      dispatch({
        type: actions.BUILDING_FLATS_INITIATING,
        params: {
          building: id,
          ...DWELLERS_AND_LANDLORDS,
          ...filters,
        },
      })
    }
  }

  componentDidUpdate(prev) {
    const { id, meta } = this.props

    if (this.props.location.search !== prev.location.search) {
      this.load()
    }

    if (
      meta.count !== null &&
      meta.count !== prev.count &&
      this.props.onCountUpdate
    ) {
      this.props.onCountUpdate(meta.count)
    }

    if (this.props.reload && this.props.iscompletereload) {
      this.props.dispatch({
        type: actions.BUILDING_FLATS_RELOAD,
        params: {
          building: id,
          ...DWELLERS_AND_LANDLORDS,
        },
      })
    }
  }

  getFlats = () => {
    const { flats, pagination } = this.props

    return pagination ? flats : take(flats, INITIAL_ITEMS_COUNT)
  }

  open = id => {
    this.props.dispatch(push(`/flat/${id}`))
  }

  load = () => {
    const { id, location } = this.props

    this.props.dispatch({
      type: actions.BUILDING_FLATS_LOAD,
      params: {
        building: id,
        ...DWELLERS_AND_LANDLORDS,
        ...getCurrentFilters(location),
      },
    })
  }

  order = field => {
    const { ordering } = this.props

    let value = field

    if (
      ordering &&
      field === ordering.replace('-', '') &&
      ordering[0] !== '-'
    ) {
      value = `-${value}`
    }

    this.props.dispatch({ type: actions.BUILDING_FLATS_SET_ORDERING, value })
  }

  filter = (key, value) => {
    this.props.dispatch({
      type: actions.BUILDING_FLATS_SET_FILTER,
      key,
      value,
    })
  }

  toggle = e => {
    this.props.dispatch({
      type: actions.BUILDING_FLATS_TOGGLE,
      id: parseInt(e.target.value, 10),
    })
  }

  toggleAll = () => {
    const { pagination, flats } = this.props
    this.props.dispatch({
      type: actions.BUILDING_FLATS_TOGGLE_ALL,
      count: pagination ? flats.length : INITIAL_ITEMS_COUNT,
    })
  }

  showPagination = () => {
    this.props.dispatch({ type: actions.BUILDING_FLATS_SHOW_PAGINATION })
  }

  remove = () => {
    const { selected, id, location } = this.props

    this.props.dispatch({
      type: actions.BUILDING_FLATS_REMOVE,
      id,
      values: selected,
      params: {
        ...DWELLERS_AND_LANDLORDS,
        building: id,
        ...getCurrentFilters(location),
      },
    })
  }

  showCreateModal = () => {
    const { address, id, dispatch } = this.props
    dispatch({
      type: actions.BUILDING_FLATS_SHOW_MODAL,
      modal: (
        <AddFlatPopup
          address={address}
          buildingId={id}
          params={DWELLERS_AND_LANDLORDS}
          onHide={this.hideModal}
        />
      ),
    })
  }

  showRemoveModal = () => {
    const { selected, t } = this.props

    if (selected.length) {
      this.props.dispatch({
        type: actions.BUILDING_FLATS_SHOW_MODAL,
        modal: (
          <ConfirmationPopup
            title={t('FlatDeleteTitle')}
            text={t('Common:delete_flat_warning_text')}
            onClose={this.hideModal}
            onOk={this.remove}
          />
        ),
      })
    }
  }

  hideModal = () => {
    this.props.dispatch({ type: actions.BUILDING_FLATS_HIDE_MODAL })
  }

  showToolbax = () => {
    const {
      permissions: { can_create: canCreate, can_delete: canDelete },
      meta,
      pagination,
    } = this.props

    return canCreate || canDelete || pagination || meta.count > meta.limit
  }

  isDisplayShowAllButton = () => {
    const { working, pagination, meta } = this.props

    return !working && !pagination && meta.count > INITIAL_ITEMS_COUNT
  }

  renderTable = flats => {
    const { working, t } = this.props

    return (
      <div className='table table--default'>
        <div className='table__outer-wrapper'>
          <div className='table__wrapper table--building-flats__list'>
            <div className='table__head'>
              <Table.HeaderCell
                title={t('FlatNumber')}
                sortKey='number'
                working={working}
              />
              <Table.HeaderCell
                title={t('FlatSquare')}
                sortKey='area'
                working={working}
              />
              <Table.HeaderCell
                title={<Fragment>{t('FlatOwners')}&nbsp;</Fragment>}
                working={working}
                style={{ width: '20%' }}
              />
              <Table.HeaderCell
                title={<Fragment>{t('FlatDwellers')}&nbsp;</Fragment>}
                working={working}
                style={{ width: '20%' }}
              />

              <Table.HeaderCell
                title={<Fragment>{t('Updated')}&nbsp;</Fragment>}
                sortKey='updated'
                working={working}
              />
            </div>
            {!working && map(flats, this.renderFlat)}
          </div>
        </div>
        {working && <Loader type='medium' text={false} />}
        {!working && !flats.length && (
          <div className='u-a16'>
            <h2 className='u-a16__heading'>{t('empty_list_title')}</h2>
          </div>
        )}
      </div>
    )
  }

  renderFlat = flat => {
    const {
      selected,
      permissions: { can_delete: canDelete },
      t,
    } = this.props
    const { id, area } = flat

    const checked = selected.includes(id)
    const itemClass = classnames('table__row', {
      'table__row--attention': checked,
    })

    const formattedArea = area ? toString(area).replace('.', ',') : t('NoData')

    return (
      <div className={itemClass} key={`flat-${id}`}>
        <NumberCell
          canToggle={canDelete}
          checked={checked}
          flat={flat}
          open={this.open}
          toggle={this.toggle}
        />
        <div className='table__cell' onClick={() => this.open(id)}>
          <div className='table__cell-wrapper'>
            <div className='table-item table-item__text' title={formattedArea}>
              <span>{formattedArea}</span>
            </div>
          </div>
        </div>
        <div className='table__cell' onClick={() => this.open(id)}>
          <div className='table__cell-wrapper'>
            <FlatUsers count={flat.landlords?.length} items={flat.landlords} />
          </div>
        </div>
        <div className='table__cell' onClick={() => this.open(id)}>
          <div className='table__cell-wrapper'>
            <FlatUsers count={flat.dwellers?.length} items={flat.dwellers} />
          </div>
        </div>
        <div
          className='table__cell table__cell--text-right'
          onClick={() => this.open(id)}
        >
          <div className='table__cell-wrapper'>
            <div className='table-item table-item__text'>
              <span>{getFormattedDate(flat.updated)}</span>
            </div>
            <div className='table-extra'>
              <Button.Save icon='eye'>{t('View')}</Button.Save>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const {
      permissions: { can_create: canCreate, can_delete: canDelete },
      meta,
      modal,
      pagination,
      selected,
      working,
    } = this.props
    const flats = this.getFlats()

    return (
      <div>
        {this.showToolbax() && (
          <div className='toolbox'>
            {canCreate && (
              <div className='toolbox__cell'>
                <Checkbox
                  outer
                  checked={!!flats.length && selected.length === flats.length}
                  onChange={this.toggleAll}
                />
              </div>
            )}
            {canCreate && selected.length === 0 && (
              <div className='toolbox__cell'>
                <Button.Save icon='apartment' onClick={this.showCreateModal}>
                  {this.props.t('AddBuildingFlats')}
                </Button.Save>
              </div>
            )}
            {canDelete && selected.length > 0 && (
              <div className='toolbox__cell'>
                <Button.Remove onClick={this.showRemoveModal} />
              </div>
            )}
            {!working && pagination && (
              <div className='toolbox__cell toolbox__cell--right'>
                <TopPagination
                  classes='pager pager--large pager--default-2'
                  meta={meta}
                />
              </div>
            )}
          </div>
        )}
        {this.renderTable(flats)}

        {!working && pagination && (
          <BottomPagination classes='paginator paginator--middle' meta={meta} />
        )}
        {this.isDisplayShowAllButton() && (
          <ShowAllButton
            text={this.props.t('ShowAll')}
            count={meta.count}
            onClick={this.showPagination}
          />
        )}
        <Modal
          contentLabel=''
          className='Modal__Bootstrap modal-dialog'
          isOpen={!!modal}
          onRequestClose={this.hideModal}
        >
          {modal}
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state.buildingFlats,
  location: getLocation(state),
})

export default compose(
  withTranslation('Building'),
  connect(mapStateToProps),
  addRedirectToFirstPage
)(BuildingFlats)
