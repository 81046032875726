// @flow

import React, { useState } from 'react'
import type { Node } from 'react'
import * as Yup from 'yup'

import moment from 'moment'
import classnames from 'classnames'
import { get } from 'lodash-es'
import { useFormik } from 'formik'

import api from '../../../core/api'
import { getFullDateFormat, cutText } from '../../../utils/utils'
import { brToNewLine, newLineToBr } from '../../../utils/text'
import { FORMIK_EMPTY_VALUE, USER_GROUPS } from '../../../constants'

import styles from './AddUserPopup.module.scss'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import TextareaAutosize from 'react-textarea-autosize'
import ContentEditable from 'react-contenteditable'
import ClickOutHandler from 'react-onclickout'

import {
  BUILDING,
  FLAT,
  ROLE,
  EXTERNAL_ID,
  NAME,
  SECOND_NAME,
  EMAIL,
  PHONE,
  MOVE_OUT_DATE,
  MESSAGE,
  SEND_INVITE,
} from './AddUserPopup.constants.js'
import NewSelectAsync from '../../NewSelectAsync/NewSelectAsync'

import ModalCloseButton from '../../Modal/ModalCloseButton/ModalCloseButton'
import ModalHead from '../../Modal/ModalHead/ModalHead'
import ModalBody from '../../Modal/ModalBody/ModalBody'
import ModalRow from '../../Modal/ModalRow/ModalRow'
import ModalButtons from '../../Modal/ModalButtons/ModalButtons'
import ModalLabel from '../../Modal/ModalLabel/ModalLabel'

import NewSelectSimple from '../../NewSelectSimple'
import ValidationText from '../../ValidationText'
import ReactCalendar from '../../filters/CalendarFilter/ReactCalendar'

import InputField from '../../InputField'
import PhoneInput from '../../forms/PhoneInput'

import Button from '../../Button'
import Checkbox from '../../Checkbox'

type Props = {
  buildingAddress?: string,
  buildingId?: number,
  flatId?: number,
  flatNumber?: string,
  onHide: () => void,
  onSave: () => void,
  owner?: number,
}

const AddUserPopup = ({
  owner,
  buildingId,
  buildingAddress,
  flatId,
  flatNumber,
  onHide,
  onSave,
}: Props): Node => {
  const {
    name: ukName,
    country_obj: { code: countryCode },
  }: Object = useSelector(state => state.init.uk)
  const maxLength = useSelector(state => state.init.uk_config.invite_max_length)

  const [reinvite, setReinvite] = useState(false)
  const [isLandlord, setIsLandlord] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [isEmailMessageVisible, setIsEmailMessageVisible] = useState(false)
  const [isSmsMessageVisible, setIsSmsMessageVisible] = useState(false)
  const [isEmailMessageInvalid, setIsEmailMessageInvalid] = useState(false)
  const [erorrMessages, setErorrMessages] = useState(false)
  const [working, setWorking] = useState(false)

  const { t } = useTranslation('AddUserPopup')

  const dwellerOptions = [
    { value: USER_GROUPS.dweller, label: t('Dweller') },
    { value: USER_GROUPS.landlord, label: t('Landlord') },
  ]

  const localization = {
    title: reinvite ? t('Send') : t('TitleDwellers'),
    succesButtonTitle: reinvite ? t('ButtonSend') : t('Send'),
    scheme: {
      required: t('Required'),
      email: t('Enter_a_valid_email_address'),
    },
    doorNumber: t('FlatTitle'),
    findAddress: t('FindAddress'),
    extrenalId: t('ExternalIdPlaceholder'),
    name: t('NamePlaceholder'),
    secondName: t('LastnamePlaceholder'),
    email: t('EmailPlaceholder'),
    moveOutDate: t('MoveOutDatePlaceholder'),
    clearText: t('ClearText'),
    messagePlaceholder: t('MessagePlaceholder'),
    messageLength: t('MessageLength'),
    send: t('Send'),
    cancel: t('Cancel'),
    errorLengthEmailMessage: t('errorLengthEmailMessage', {
      maxLength: maxLength.toString(),
    }),
    smsInviteMessageTitle: t('SmsInviteMessageTitle'),
    emailMessageTitle: t('EmailMessageTitle'),
    message: brToNewLine(
      t('DefaultMessage', { name: '%profile_name%', ukName })
    ),
  }

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      [BUILDING]: buildingId
        ? { id: buildingId, number: buildingAddress }
        : FORMIK_EMPTY_VALUE,
      [FLAT]: flatId ? { id: flatId, number: flatNumber } : FORMIK_EMPTY_VALUE,
      [ROLE]: isLandlord ? dwellerOptions[1] : dwellerOptions[0],
      [EXTERNAL_ID]: FORMIK_EMPTY_VALUE,
      [NAME]: FORMIK_EMPTY_VALUE,
      [SECOND_NAME]: FORMIK_EMPTY_VALUE,
      [EMAIL]: FORMIK_EMPTY_VALUE,
      [PHONE]: FORMIK_EMPTY_VALUE,
      [MESSAGE]: localization.message,
      [MOVE_OUT_DATE]: FORMIK_EMPTY_VALUE,
      [SEND_INVITE]: true,
    },
    validationSchema: Yup.object({
      [BUILDING]: Yup.object().required(localization.scheme.required),
      [FLAT]: Yup.object().required(localization.scheme.required),
      [EXTERNAL_ID]: Yup.string().max(255),
      [NAME]: Yup.string().max(50).required(localization.scheme.required),
      [SECOND_NAME]: Yup.string()
        .max(50)
        .required(localization.scheme.required),
      [EMAIL]: Yup.string().email(localization.scheme.email),
    }),
    onSubmit: values => {
      const {
        building: { id: buildingId },
        flat: { number: flatNumber },
        role: { value },
        move_out_date,
        external_id,
        email,
        name,
        phone,
        second_name,
        message,
        send_invitation,
      } = values

      let requestData = {
        message: newLineToBr(message),
        send_invitation,
        users: [
          {
            flat: flatNumber,
            role: value,
            name,
            second_name,
            email,
            phone,
            building: buildingId,
            external_id,
          },
        ],
      }

      if (move_out_date) {
        requestData = {
          ...requestData,
          move_out_date: move_out_date.format('YYYY-MM-DD'),
        }
      }

      setWorking(true)
      api.profile
        .invite(requestData)
        .then(() => {
          onSave()
        })
        .catch(error => {
          const data = get(error, ['message', 'response', 'data', 'errors'])
          let errorMessageList = []

          if (typeof data === 'string') {
            errorMessageList = [t('DefaultError')]
          } else {
            if (data.message) {
              errorMessageList = [t(data.message.replaceAll(':', ''))]
            } else {
              for (const [, value] of Object.entries(data)) {
                if (typeof value === 'string') {
                  errorMessageList.push(t(value))
                }
              }
            }
          }

          setErorrMessages(errorMessageList)
        })
        .finally(() => {
          formik.setSubmitting(false)
          setWorking(false)
        })
      setReinvite(true)
    },
  })

  const buildingSelectParam = { all: true, owner, is_promo: 0 }

  const minYear = moment().year() + 1

  const textAreaClass = classnames(
    'textarea-2__input invite-message',
    styles.textarea
  )

  const hideEmailMessageClassnames = classnames(
    'modal__label--drop button-drop button-drop--small',
    { 'modal__label--drop--open': isEmailMessageVisible }
  )

  const hideSmsMessageClassnames = classnames(
    'modal__label--drop button-drop button-drop--small',
    { 'modal__label--drop--open': isSmsMessageVisible }
  )

  const textAreaPlaceholderClassnames = classnames(
    'textarea-2__placeholder',
    styles.textareaPlaceholder
  )
  const handleChangeBuilding = option => {
    formik.setFieldValue(FLAT, FORMIK_EMPTY_VALUE)
    formik.setFieldValue(BUILDING, option)
  }

  const handleChangeFlat = option => {
    formik.setFieldValue(FLAT, option)
  }

  const handleChangePhone = option => {
    formik.setFieldValue(PHONE, option)
  }

  const handleChangeRole = option => {
    setIsLandlord(option.value === USER_GROUPS.landlord)

    formik.setFieldValue(ROLE, option)
  }

  const handleClearFlat = e => {
    e.stopPropagation()

    formik.setFieldValue(FLAT, FORMIK_EMPTY_VALUE)
  }

  const handleClearBuilding = e => {
    e.stopPropagation()

    formik.setFieldValue(BUILDING, FORMIK_EMPTY_VALUE)
  }

  const handleClear = () => {
    formik.handleReset()
    onHide()
  }

  const handleCloseFlatSelect = val => {
    if (val) {
      handleChangeFlat({
        number: val,
      })
    }
  }

  const onClickOut = () => {
    setIsOpen(false)
  }

  const handleMoveOutDate = () => {
    setIsOpen(true)
  }

  const handlClickWrapCalendar = e => {
    e.stopPropagation()
  }

  const onClear = () => {
    formik.setFieldValue(MOVE_OUT_DATE, FORMIK_EMPTY_VALUE)
    onClickOut()
  }

  const onUpdateDate = date => {
    formik.setFieldValue(MOVE_OUT_DATE, date)
  }

  const toggleEmailMessageVisibility = () => {
    setIsEmailMessageVisible(isEmailMessageVisible => !isEmailMessageVisible)
  }
  const toggleSmsMessageVisibility = () => {
    setIsSmsMessageVisible(isSmsMessageVisible => !isSmsMessageVisible)
  }

  const getValue = name => formik.values[name]

  const getErrrors = name => formik.errors[name]

  const setCurrentMessage = e => {
    const {
      target: { value },
    } = e
    setIsEmailMessageInvalid(value.length > maxLength)
    formik.setFieldValue(MESSAGE, value)
  }

  const selectedBuildingItems = formik.values[BUILDING]
    ? [formik.values[BUILDING]]
    : []

  const selectedFlatItems = formik.values[FLAT] ? [formik.values[FLAT]] : []

  return (
    <div className={'modal__content'}>
      <ModalCloseButton onClose={handleClear} />
      <ModalHead title={localization.title} />
      <ModalBody>
        <form onSubmit={formik.handleSubmit}>
          <ModalRow>
            {!buildingId ? (
              <NewSelectAsync
                view='dropdown'
                searchKey='address'
                placeholder={localization.findAddress}
                pageSize={10}
                className={styles.select}
                disabled={formik.isSubmitting}
                initialValueId={
                  typeof buildingId === 'string' ? null : buildingId
                }
                api={api.building.getListTiny}
                error={getErrrors(BUILDING)}
                getLabel={building => building.address_obj.value}
                getSelectedLabel={building => building.address_obj.value}
                selectedItems={selectedBuildingItems}
                params={buildingSelectParam}
                onClear={handleClearBuilding}
                onClick={handleChangeBuilding}
              />
            ) : (
              <ModalLabel className='modal__label--caption'>
                {buildingAddress}
              </ModalLabel>
            )}
          </ModalRow>
          <ModalRow className='modal-invite-dweller-form__row'>
            <div className='modal-invite-dweller-form'>
              <ModalRow className='modal-invite-dweller-form__row'>
                <div className='modal-invite-dweller-form__cell modal-invite-dweller-form__cell--flat-number'>
                  {flatId && formik.values[FLAT].id ? (
                    <InputField
                      disabled
                      type='text'
                      value={formik.values[FLAT].number}
                    />
                  ) : (
                    <NewSelectAsync
                      view='dropdown'
                      searchKey='number_icontains'
                      pageSize={10}
                      minSearchLength={1}
                      className={styles.buildingSelect}
                      selectedItems={selectedFlatItems}
                      dynamicParams={{
                        building: formik.values[BUILDING].id,
                      }}
                      params={buildingSelectParam}
                      disabled={!formik.values[BUILDING] || formik.isSubmitting}
                      initialValueId={
                        typeof flatId === 'string' ? null : flatId
                      }
                      placeholder={localization.doorNumber}
                      error={getErrrors(FLAT)}
                      api={api.flat.getListTiny}
                      getLabel={flat => flat.number}
                      getSelectedLabel={flat => flat.number}
                      onClear={handleClearFlat}
                      onClick={handleChangeFlat}
                      onClose={handleCloseFlatSelect}
                    />
                  )}
                </div>
                <NewSelectSimple
                  className={styles.changeRole}
                  value={getValue(ROLE)}
                  error={getErrrors(ROLE)}
                  options={dwellerOptions}
                  onChange={handleChangeRole}
                />
                <div className='modal-invite-dweller-form__cell modal-invite-dweller-form__cell--02'>
                  <InputField
                    type='text'
                    name={EXTERNAL_ID}
                    value={getValue(EXTERNAL_ID)}
                    error={getErrrors(EXTERNAL_ID)}
                    placeholder={localization.extrenalId}
                    onChange={formik.handleChange}
                  />
                </div>
              </ModalRow>
              <ModalRow className='modal-invite-dweller-form__row'>
                <div className='modal-invite-dweller-form__cell modal-invite-dweller-form__cell--02'>
                  <InputField
                    type='text'
                    name={NAME}
                    placeholder={localization.name}
                    value={getValue(NAME)}
                    error={getErrrors(NAME)}
                    onChange={formik.handleChange}
                  />
                </div>
                <div className='modal-invite-dweller-form__cell modal-invite-dweller-form__cell--02'>
                  <InputField
                    type='text'
                    placeholder={localization.secondName}
                    name={SECOND_NAME}
                    value={getValue(SECOND_NAME)}
                    error={getErrrors(SECOND_NAME)}
                    onChange={formik.handleChange}
                  />
                </div>
              </ModalRow>
              <ModalRow className='modal-invite-dweller-form__row'>
                <div className='modal-invite-dweller-form__cell modal-invite-dweller-form__cell--04'>
                  <InputField
                    name={EMAIL}
                    placeholder={localization.email}
                    value={getValue(EMAIL)}
                    error={getErrrors(EMAIL)}
                    onChange={formik.handleChange}
                  />
                </div>

                <div className='modal-invite-dweller-form__cell modal-invite-dweller-form__cell--05'>
                  <PhoneInput
                    className='input input--default input--block input--large input--multiple'
                    type='text'
                    countryCode={countryCode}
                    name={PHONE}
                    value={getValue(PHONE)}
                    error={getErrrors(PHONE)}
                    onChange={handleChangePhone}
                  />
                </div>
                <div className='modal-invite-dweller-form__cell modal-invite-dweller-form__cell--06'>
                  <div className='table-item table-item__text'>
                    <button
                      type='button'
                      className='table-item__text-dropdown'
                      onClick={handleMoveOutDate}
                    >
                      {formik.values[MOVE_OUT_DATE] ? (
                        cutText(
                          getFullDateFormat(formik.values[MOVE_OUT_DATE]),
                          6
                        )
                      ) : (
                        <>{cutText(localization.moveOutDate, 6)}</>
                      )}
                      &nbsp;
                    </button>
                    {isOpen && (
                      <div
                        className='date-calendar'
                        onClick={handlClickWrapCalendar}
                      >
                        <ClickOutHandler onClickOut={onClickOut}>
                          <ReactCalendar
                            single
                            onlyMonth
                            minYear={minYear}
                            Select={NewSelectAsync}
                            styleName={styles.calendar__layout}
                            value={getValue(MOVE_OUT_DATE) || moment()}
                            clearText={localization.clearText}
                            onClear={onClear}
                            onValueChange={onUpdateDate}
                            onChange={date => {
                              onUpdateDate(date[0])
                              onClickOut()
                            }}
                          />
                        </ClickOutHandler>
                      </div>
                    )}
                  </div>
                </div>
              </ModalRow>
              {erorrMessages.length > 0 &&
                erorrMessages.map(error => (
                  <>
                    <div className='input input--medium input--default input--block input--error'>
                      <span className='input__msg'>{error}</span>
                    </div>
                  </>
                ))}
            </div>
          </ModalRow>

          {getValue(SEND_INVITE) && (
            <ModalRow>
              <ModalLabel
                className='modal__label--link'
                onClick={toggleEmailMessageVisibility}
              >
                {localization.emailMessageTitle}
              </ModalLabel>
              <button
                type='button'
                className={hideEmailMessageClassnames}
                onClick={toggleEmailMessageVisibility}
              />
            </ModalRow>
          )}
          {isEmailMessageVisible && getValue(SEND_INVITE) ? (
            <ModalRow>
              <div className={styles.textarea}>
                <TextareaAutosize
                  className={textAreaClass}
                  value={formik.values[MESSAGE]}
                  maxRows={10}
                  onChange={setCurrentMessage}
                />
                <div className={textAreaPlaceholderClassnames}>
                  {localization.messagePlaceholder}
                  <br />
                  {localization.messageLength}
                </div>
              </div>
              <ValidationText
                active={isEmailMessageInvalid}
                text={localization.errorLengthEmailMessage}
              />
            </ModalRow>
          ) : null}
          {getValue(SEND_INVITE) && (
            <ModalRow>
              <ModalLabel
                className='modal__label--link'
                onClick={toggleSmsMessageVisibility}
              >
                {localization.smsInviteMessageTitle}
              </ModalLabel>
              <button
                type='button'
                className={hideSmsMessageClassnames}
                onClick={toggleSmsMessageVisibility}
              />
            </ModalRow>
          )}
          {isSmsMessageVisible && getValue(SEND_INVITE) ? (
            <ModalRow>
              <div className={styles.textareaSms}>
                <ContentEditable
                  disabled
                  className='textarea-2__input invite-message'
                  html={t('SmsInviteMessageText', { ukName })}
                  style={{ backgroundColor: '#f0f0f0', padding: '5px' }}
                />
                <div className='textarea-2__placeholder'>
                  {localization.messagePlaceholder}
                  <br />
                  {localization.messageLength}
                </div>
              </div>
            </ModalRow>
          ) : null}
          <ModalButtons>
            <Button.Save
              type='submit'
              disabled={!formik.dirty || formik.isSubmitting}
              working={working}
            >
              {localization.send}
            </Button.Save>
            <Button.Cancel disabled={formik.isSubmitting} onClick={handleClear}>
              {localization.cancel}
            </Button.Cancel>
            <Checkbox
              className={styles.sendInvite}
              text={t('SendInvite')}
              name={SEND_INVITE}
              checked={getValue(SEND_INVITE)}
              onChange={formik.handleChange}
            />
          </ModalButtons>
        </form>
      </ModalBody>
    </div>
  )
}

export default AddUserPopup
