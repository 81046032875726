// @flow

import React, { Component } from 'react'
import classnames from 'classnames'
import type { Node } from 'react'

import Tile from '../TileList'
import TileItem from './TileItem'

type Props = {
  allSelected: boolean,
  EmptyList: Node,
  items: Array<Object>,
  onToggleItem: number => void,
  rowLength: number,
  selectedIds: Array<number>,
  shortRow: boolean,
  working: boolean,
}

class TileList extends Component<Props> {
  static defaultProps = {
    rowLength: 5,
  }

  renderItem = (item: Object) => {
    const { allSelected, selectedIds, searching } = this.props

    return (
      <TileItem
        canTogglePage={!searching}
        key={item.id}
        data={item}
        selected={allSelected || selectedIds.includes(item.id)}
        onToggle={this.props.onToggleItem}
      />
    )
  }

  render() {
    const { EmptyList, items, working, shortRow } = this.props

    const className = classnames('buildings-tile', {
      'working-overlay': working,
    })

    return (
      <div className={className}>
        {!items.length && <EmptyList />}
        <Tile rowLength={shortRow ? 4 : 5}>{items.map(this.renderItem)}</Tile>
      </div>
    )
  }
}

export default TileList
