// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import { Cell } from '../../../components/Block'

const DomusStatus = props => {
  const { syncInProgress, syncFinishedTime } = props

  const { t } = useTranslation('Integrations')

  let text = 'DomusNotSyncYet'

  if (syncInProgress) {
    text = 'DomusSyncInProgress'
  } else if (syncFinishedTime) {
    text = 'DomusSyncIsDone'
  }

  const className = classnames('bar__cell-value', {
    'value-progress': syncInProgress,
    'value-done': syncFinishedTime,
  })

  return (
    <Cell title={t('DomusStatus')} valueClassName={className}>
      {t(text)}
    </Cell>
  )
}

export default DomusStatus
