// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'

import AddressSection from '../../Address/AddressSection/'
import MapBalloon from '../../Address/BuildingBalloon'

type Props = {
  collapsed: boolean,
  data: Object,
  initiated: boolean,
  noMargin?: boolean,
  onCollapse: () => void,
  onUpdate: Object => void,
  working: boolean,
}

const BuildingAddress = (props: Props): Node => {
  const {
    data,
    working,
    initiated,
    collapsed,
    noMargin,
    onCollapse,
    onUpdate,
  } = props
  const { t } = useTranslation('Building')

  const balloon = (
    <MapBalloon
      address={data.address_obj.value}
      flatsCount={data.flat_count}
      dwellersCount={data.dweller_count}
      t={t}
    />
  )

  // TODO collapsed is undefined why?

  return (
    <AddressSection
      balloon={balloon}
      initialAddress={data.address_obj}
      editable={data.permissions.can_edit}
      title={t('MainInfo')}
      working={working}
      initiated={initiated}
      collapsed={collapsed}
      noMargin={noMargin}
      save={onUpdate}
      onCollapse={onCollapse}
    />
  )
}

export default BuildingAddress
