// @flow

import update from 'immutability-helper'

import * as actions from './FilesUpload.actionTypes'

const initialState = {
  files: [],
  count: 0,
  activeKey: null,
  errors: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.UPLOAD_FILES:
      return {
        ...initialState,
        files: action.files.map(file => ({
          name: file.name,
        })),
        activeKey: action.activeKey,
      }
    case actions.FILE_WAS_UPLOADED:
      return update(state, {
        files: { $splice: [[action.index, 1, action.file]] },
        count: { $set: state.count + 1 },
      })
    case actions.FILE_UPLOAD_ERROR:
      return {
        ...state,
        errors: state.errors.concat([action.filename]),
        count: state.count + 1,
      }
    case actions.FILES_UPLOAD_RESET:
      return initialState
    default:
      return state
  }
}
