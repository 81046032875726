// @flow

import React from 'react'
import classNames from 'classnames'
import type { Node } from 'react'

import styles from './Square.module.scss'

type Props = { active: boolean, children: Node, onClick?: Function }

const Square = (props: Props): Node => {
  const { children, active } = props

  const handleClick = e => {
    e.stopPropagation()

    if (props.onClick) {
      props.onClick(e)
    }
  }

  const squareClass = classNames(styles.square, { [styles.active]: active })

  return (
    <button className={squareClass} type='button' onClick={handleClick}>
      {children}
    </button>
  )
}

export default Square
