// @flow

import { OPEN_MENU, TOGGLE_MENU } from './Menu.actionTypes'

type Action = {
  type: string,
}
type State = {|
  +collapsed: boolean,
|}

const initialState: State = {
  collapsed: false,
}

export default (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case TOGGLE_MENU:
      return { collapsed: !state.collapsed }
    case OPEN_MENU:
      return { ...initialState }
    default:
      return state
  }
}
