// @flow

import * as actions from './SettingsPersonal.actionTypes'

const initialState = {
  initiated: false,
  working: false,
  workingSave: [],
  language: {},
  notificationFrequencyType: 0,
  profileConfig: {},
  error: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.SETTINGS_PERSONAL_INIT:
      return {
        ...initialState,
        language: action.user.language_obj,
        working: true,
      }
    case actions.SETTINGS_PERSONAL_INIT_SUCCESS:
      return {
        ...state,
        notificationFrequencyType:
          action.profileConfig &&
          action.profileConfig.email_notification_period,
        profileConfig: action.profileConfig,
        backupConfig: { ...action.profileConfig, language: state.language },
        working: false,
        initiated: true,
      }
    case actions.SETTINGS_PERSONAL_CANCEL:
      return {
        ...state,
        notificationFrequencyType:
          action.cancel !== 'cancelNotificationFrequencyType'
            ? state.notificationFrequencyType
            : action.emailNotificationPeriod,
        language:
          action.cancel !== 'cancelLanguage'
            ? state.language
            : state.backupConfig.language,
        profileConfig:
          action.cancel !== 'cancelNotificationFrequencyType'
            ? {
                ...state.backupConfig,
                notificationFrequencyType: action.emailNotificationPeriod,
              }
            : { ...state.backupConfig },
      }
    case actions.SETTINGS_PERSONAL_SAVE:
      return {
        ...state,
        working: true,
        workingSave: [...state.workingSave, action.workingSave],
      }
    case actions.SETTINGS_PERSONAL_SAVED: {
      const index = state.workingSave.indexOf(action.workingSave)
      const workingSaveClone = [...state.workingSave]
      workingSaveClone.splice(index, 1)

      return {
        ...state,
        user: action.user,
        notificationFrequencyType:
          action.workingSave !== 'saveNotificationFrequencyType'
            ? state.notificationFrequencyType
            : action.profileConfig &&
              action.profileConfig.email_notification_period,
        profileConfig: action.profileConfig,
        backupConfig: { ...state.backupConfig, ...action.profileConfig },
        working: false,
        workingSave: [...workingSaveClone],
      }
    }
    case actions.SETTINGS_PERSONAL_UPDATE: {
      const updated = {}
      updated[action.key] = action[action.key]

      return {
        ...state,
        ...updated,
        working: false,
      }
    }
    default:
      return state
  }
}
