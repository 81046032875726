// @flow

import React from 'react'
import type { Node } from 'react'

import {
  IMAGE_TYPE,
  LINK_TYPE,
  TEXT_TYPE,
  VARIABLE_TYPE,
} from '../CardStage.constants'
import Variable from './Variable'
import Text from '../Text'
import Image from './Image'
import Link from './Link'
import type { CardType } from '../../NewWizard.types'

type Props = {| card: CardType |}

const Card = (props: Props): Node => {
  const { card } = props

  return (
    <>
      {card.type === TEXT_TYPE && <Text text={card.text} />}
      {card.type === VARIABLE_TYPE && <Variable card={card} />}
      {card.type === IMAGE_TYPE && (
        <Image
          description={card.description}
          title={card.title}
          file={card.file}
        />
      )}
      {card.type === LINK_TYPE && <Link links={card.links} />}
    </>
  )
}

export default Card
