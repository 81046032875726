// @flow

import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { confirmRecovery } from '../../../core/api/api.system'
import AuthPage from '../AuthPage'
import PasswordResetForm from './PasswordResetForm'
import PasswordResetError from './PasswordResetError'

const PasswordReset = () => {
  const { hash } = useParams()
  const [isVerifiedHash, setVerifiedHash] = useState(false)
  const [working, setWorking] = useState(false)

  useEffect(() => {
    if (hash) {
      setWorking(true)

      confirmRecovery(hash)
        .then((data: Object) => {
          if (data.error) {
            setVerifiedHash(false)
          } else {
            setVerifiedHash(true)
          }
        })
        .catch(() => setVerifiedHash(false))
        .finally(() => setWorking(false))
    }
  }, [hash])

  return (
    <AuthPage working={working}>
      {isVerifiedHash && hash ? (
        <PasswordResetForm
          hash={hash}
          setVerifiedHash={setVerifiedHash}
          setWorking={setWorking}
        />
      ) : (
        <PasswordResetError />
      )}
    </AuthPage>
  )
}

export default PasswordReset
