// @flow

import { put, call, fork, takeEvery } from 'redux-saga/effects'

import {
  TRANSLATIONS_LOADING,
  TRANSLATIONS_LOADED,
} from './translations.actionTypes'
import { getTranslation } from '../../core/api/api.translation'
import { serverError } from '../Layout/Layout.actions'
import { loadI18NextTranslations } from '../../utils/translations'

function* watchTranlationsLoading() {
  yield takeEvery(TRANSLATIONS_LOADING, loadTranslations)
}

export function* loadTranslations() {
  try {
    const translations = yield call(getTranslation)
    loadI18NextTranslations(translations)

    yield put({ type: TRANSLATIONS_LOADED, translations })
  } catch (error) {
    yield put(serverError(error))
  }
}

export default function* watch() {
  yield fork(watchTranlationsLoading)
}
