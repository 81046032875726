// @flow

import React from 'react'

import type { Props } from './index'

const EmailCell = ({ user, onClick }: Props) => (
  <div className='table__cell' onClick={onClick}>
    <div className='table__cell-wrapper'>
      <div className='table-item table-item__text' title={user.email}>
        <span>{user.email}</span>
      </div>
    </div>
  </div>
)

export default EmailCell
