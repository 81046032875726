// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import Icon from '../../../Icon'

import styles from './Title.module.scss'

type Props = { requestTitle: string }

const Title = (props: Props): Node => {
  const { requestTitle } = props

  const { t } = useTranslation('Request')

  return (
    <h2 className={styles.title}>
      <Icon className={styles.icon} id='request' />
      <span>
        {t('YourRequest')} &laquo;{requestTitle}&raquo;
      </span>
    </h2>
  )
}

export default Title
