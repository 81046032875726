// @flow

import React, { useContext } from 'react'
import type { Node } from 'react'

import ActivityItem from './ActivityItem'
import PhaseItemContext from '../../PhaseItem.context'

import styles from './ActivityList.module.scss'

const ActivityList = (): Node => {
  const {
    activities,
    phase: { id },
  } = useContext(PhaseItemContext)

  return (
    <>
      <div className={styles['activity-list']}>
        {activities.map((activity, index) => (
          <ActivityItem
            last={index === activities.length - 1}
            activity={activity}
            key={activity.id}
            phaseId={id}
          />
        ))}
      </div>
    </>
  )
}

export default ActivityList
