// @flow

import React, { PureComponent } from 'react'
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import ClickOutHandler from 'react-onclickout'

import ReactCalendar from '../filters/CalendarFilter/ReactCalendar'
import Select from '../Select/Select'
import NewSelectSimple from '../NewSelectSimple'

import styles from './SetDueDatePopUp.module.scss'

class SetDueDatePopUp extends PureComponent {
  state = {
    dateTime: this.props.dueDate,
    date: this.props.dueDate,
    time: this.props.dueDate
      ? {
          value: parseInt(moment(this.props.dueDate).local().format('HH'), 10),
          label: moment(this.props.dueDate).local().format('HH:mm'),
        }
      : {
          value: 12,
          label: '12:00',
        },
    checkListTemplateId: undefined,
    dateOpen: false,
  }

  componentDidMount() {
    window.addEventListener('keydown', this.listenForKeyPress, true)
    document.body.style.overflowY = 'hidden'
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.listenForKeyPress, true)
    document.body.style.overflowY = 'scroll'
  }

  onClickDateOpen = () => {
    this.setState({ dateOpen: true })
  }

  onClickOut = () => {
    this.setState({ dateOpen: false })
  }

  selectTimeOptions = [...new Array(24).keys()].map(num => ({
    value: num,
    label: `${num < 10 ? '0' : ''}${num}:00`,
  }))

  listenForKeyPress = event => {
    if (event.key === 'Escape' || event.keyCode === 27) {
      event.preventDefault()
      this.props.onHide()
    } else if (event.key === 'Enter' || event.keyCode === 13) {
      event.preventDefault()
      this.save()
    }
  }

  updateDate = date => {
    this.setState({ date: date.format('YYYY-MM-DDTHH:mm:ssZ') })
  }

  updateTime = val => {
    this.setState({ time: val })
  }

  save = () => {
    const { onSave } = this.props
    const { date, time } = this.state
    const dueDate = date ? moment(date) : moment().add(1, 'days')
    const hour = time !== null ? parseInt(time.value, 10) : 12
    onSave(
      dueDate
        .set({
          hour,
          minute: 0,
          second: 0,
          millisecond: 0,
        })
        .format()
    )
  }

  render() {
    const { onHide, t } = this.props
    const dueDate = this.state.date
      ? moment(this.state.date)
      : moment().add(1, 'days')
    let dueDateText = ''

    if (dueDate) {
      dueDateText = dueDate.format('DD.MM.YYYY')
    }

    return (
      <div className='modal__content'>
        <button className='modal__close' type='button' onClick={onHide} />
        <div className='modal__title modal__title--big-bottom-margin'>
          {t('ChangeDueDate')}
        </div>
        <div className='modal__body'>
          <div className='modal__row'>
            <div>
              <span className='modal__label'>{t('Date')}:</span>
              <div className='input input--default input--active input--large date-input'>
                <input
                  readOnly
                  type='text'
                  placeholder={t('NoDate')}
                  value={dueDateText}
                  onClick={this.onClickDateOpen}
                />
                <div className='date-calendar' style={{ width: '16.5rem' }}>
                  {this.state.dateOpen && (
                    <ClickOutHandler onClickOut={this.onClickOut}>
                      <ReactCalendar
                        single
                        minYear={1900}
                        maxYear={moment().get('year') + 5}
                        Select={Select}
                        value={dueDate || moment()}
                        style={{
                          position: 'absolute',
                          right: 0,
                          zIndex: 1,
                          background: '#fff',
                          borderRadius: '0.5rem',
                          border: '0.1rem solid #d0d0d0',
                          boxShadow: '0 0.35rem 0.8rem rgba(0, 0, 0, 0.11)',
                          margin: 0,
                          overflow: 'hidden',
                        }}
                        onValueChange={this.updateDate}
                        onChange={date => {
                          this.onClickOut()
                          this.updateDate(date[0])
                        }}
                      />
                    </ClickOutHandler>
                  )}
                </div>
              </div>
            </div>
            <div style={{ marginLeft: '15px' }}>
              <span className='modal__label'>{t('Time')}:</span>
              <div
                className='input input--large input--default'
                style={{ width: '16.5rem' }}
              >
                <NewSelectSimple
                  useSearch
                  name='time'
                  searchClass={styles.search}
                  value={this.state.time}
                  options={this.selectTimeOptions}
                  onChange={this.updateTime}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='modal__submit'>
          <button
            type='button'
            className='button button--large button--success'
            onClick={this.save}
          >
            {t('Add')}
          </button>
          <button
            type='button'
            className='button button--large button--danger-3'
            onClick={onHide}
          >
            {t('Cancel')}
          </button>
        </div>
      </div>
    )
  }
}

export default withTranslation('Request')(SetDueDatePopUp)
