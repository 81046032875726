// @flow

import Utils from '../../../utils/utils'
import * as actions from './RealData.actionTypes'

const initialState = {
  initiated: false,
  working: false,
  config: {},
  status: {},
  managers: [],
  exist: false,
  requests: {},
  requestsWereCopied: false,
  matchingRequests: false,
  copyingRequestTypes: false,
  errors: [],
  popupError: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.REAL_DATA_INITIATE:
      return {
        ...initialState,
        working: true
      }
    case actions.REAL_DATA_WAS_INITIATED:
      return {
        ...state,
        initiated: true,
        working: false,
        ...action.data
      }
    case actions.REAL_DATA_CHECK_STATUS:
    case actions.REAL_DATA_CREATE:
    case actions.REAL_DATA_APPLY_CONFIG:
    case actions.REAL_DATA_SYNCHRONIZE:
      return {
        ...state,
        working: true,
        errors: []
      }
    case actions.REAL_DATA_STATUS_WAS_CHECKED:
      return {
        ...state,
        working: false,
        status: action.status
      }
    case actions.REAL_DATA_WAS_CREATED:
      return {
        ...state,
        working: false,
        exist: true,
        ...action.data
      }
    case actions.REAL_DATA_CONFIG_WAS_APPLIED:
      return {
        ...state,
        working: false,
        config: action.config
      }
    case actions.REAL_DATA_MATCH_REQUESTS:
      return {
        ...state,
        matchingRequests: true,
        popupError: null
      }
    case actions.REAL_DATA_REQUESTS_WERE_MATCHED:
      return {
        ...state,
        matchingRequests: false,
        requests: action.items
      }
    case actions.REAL_DATA_COPY_REQUEST_TYPES:
      return {
        ...state,
        copyingRequestTypes: true,
        popupError: null
      }
    case actions.REAL_DATA_REQUEST_TYPES_WERE_COPIED:
      return {
        ...state,
        copyingRequestTypes: false,
        requests: action.items,
        requestsWereCopied: true
      }
    case actions.REAL_DATA_POPUP_ERROR:
      return {
        ...state,
        matchingRequests: false,
        copyingRequestTypes: false,
        popupError: Utils.getErrorText(action.response)
      }
    case actions.REAL_DATA_RESET_POPUP_ERROR:
      return {
        ...state,
        popupError: null
      }
    case actions.REAL_DATA_ERROR:
      return {
        ...state,
        working: false,
        errors: [
          ...state.errors,
          { text: Utils.getErrorText(action.response) }
        ]
      }
    default:
      return state
  }
}
