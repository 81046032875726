// @flow

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'
import Button from '../Button'

class RenameChatroomPopUp extends Component {
  state = {
    newTitle: this.props.oldTitle,
  }

  componentDidMount() {
    this.input.focus()
    window.addEventListener('keydown', this.listenForKeyPress, true)
    document.body.style.overflowY = 'hidden'
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.listenForKeyPress, true)
    document.body.style.overflowY = 'scroll'
  }

  listenForKeyPress = event => {
    if (event.key === 'Escape' || event.keyCode === 27) {
      this.props.onHide()
    } else if (event.key === 'Enter' || event.keyCode === 13) {
      this.save()
    }
  }

  save = () => {
    const { onSave } = this.props
    onSave(this.state.newTitle)
  }

  renderCellTitle = (cell, label) => (
    <div className={`m-a10__cell m-a10__cell_${cell}`}>
      <span className='m-a10__label'>{label}</span>
    </div>
  )

  renderCellValue = (cell, key, placeholder) => {
    const inputClass = classnames(
      'input input--medium input--default input--block'
    )

    return (
      <div
        className={`m-a10__cell m-a10__cell--${cell}`}
        style={{ width: '30rem' }}
      >
        <div className={inputClass}>
          <input
            type='text'
            name={key}
            value={this.state.newTitle}
            placeholder={placeholder}
            ref={element => {
              this.input = element
            }}
            onChange={e => this.setState({ newTitle: e.target.value })}
          />
        </div>
      </div>
    )
  }

  render() {
    const { onHide, saving, t } = this.props
    const wrapperClass = classnames('modal__content', {
      'working-overlay': saving,
    })

    return (
      <div className={wrapperClass}>
        <button className='modal__close' type='button' onClick={onHide} />
        <div className='modal__title'>{t('ChatroomChange')}</div>
        <div className='m-a10'>
          <div className='m-a10__row' style={{ marginTop: '1rem' }}>
            {this.renderCellTitle('1', t('NewChatroomTitle'))}
            {this.renderCellValue('2', 'name', t('NamePlaceholder'))}
          </div>

          <div className='modal__submit modal-counters-add-data__submit'>
            <Button.Save onClick={this.save}>{t('Save')}</Button.Save>
            <Button.Cancel onClick={onHide}>{t('Cancel')}</Button.Cancel>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation('Chatroom')(RenameChatroomPopUp)
