// @flow

import React from 'react'
import type { Node } from 'react'

import NewTable from '../../NewTable'
import EmailIntegrationTableBody from './EmailIntegrationTableBody'
import EmailIntegrationTableHeader from './EmailIntegrationTableHeader'

type Props = {
  emails: Array<Object>,
  isPrivate?: boolean,
  loading: boolean,
}

const EmailIntegrationTable = (props: Props): Node => {
  const { loading, emails } = props

  return (
    <NewTable>
      <EmailIntegrationTableHeader />
      <EmailIntegrationTableBody emails={emails} loading={loading} {...props} />
    </NewTable>
  )
}

export default EmailIntegrationTable
