import { all, put, call, fork, takeEvery } from 'redux-saga/effects'
import { replace } from 'connected-react-router'

import api from '../../core/api'
import { SERVER_404_ERROR } from '../Layout/Layout.constants'
import * as actions from './ImportFilesTable.actionTypes'
import { serverError } from '../../components/Layout/Layout.actions'

function * watchLoad () {
  yield takeEvery(actions.IMPORT_FILES_TABLE_INITIATING, load)
}

function * watchGetLast () {
  yield takeEvery(actions.IMPORT_FILES_TABLE_LAST_STATUS, getLast)
}

function * watchSetPage () {
  yield takeEvery(actions.IMPORT_FILES_TABLE_SET_PAGE, setPage)
}

function * watchGetPk () {
  yield takeEvery(actions.IMPORT_FILES_GET_IMPORT_PK, getPk)
}

function * watchStartImport () {
  yield takeEvery(actions.IMPORT_FILES_TABLE_START, startImport)
}

function * watchCancelImport () {
  yield takeEvery(actions.IMPORT_FILES_TABLE_CANCEL, cancelImport)
}

function * watchRemoveImport () {
  yield takeEvery(actions.IMPORT_FILES_TABLE_REMOVE, removeImport)
}

function * load ({ params }) {
  try {
    const data = yield call(api.userImport.getByPage, params)
    const last = yield call(api.userImport.getLast)
    const statuses = yield call(api.userImport.getStatuses)
    yield put({ type: actions.IMPORT_FILES_TABLE_INITIATED, data, statuses, last })
  } catch (error) {
    const action = serverError(error)

    if (action.type === SERVER_404_ERROR) {
      yield put(replace('/404'))
    } else {
      yield put(action)
      yield put({ type: actions.IMPORT_FILES_TABLE_ERROR, error })
    }
  }
}

function * getLast ({ params }) {
  try {
    const last = yield call(api.userImport.getLast)
    const data = yield call(api.userImport.getByPage, params)

    yield put({ type: actions.IMPORT_FILES_TABLE_LAST, last, data })
  } catch (error) {
    const action = serverError(error)

    if (action.type === SERVER_404_ERROR) {
      yield put(replace('/404'))
    } else {
      yield put(action)
      yield put({ type: actions.IMPORT_FILES_TABLE_ERROR, error })
    }
  }
}

/**
 * need some edits for paging
 */
function * setPage ({ page }) {
  try {
    const data = yield call(api.userImport.getByPage, { page })
    yield put({ type: actions.IMPORT_FILES_TABLE_PAGE, data })
  } catch (error) {
    const action = serverError(error)

    if (action.type === SERVER_404_ERROR) {
      yield put(replace('/404'))
    } else {
      yield put(action)
      yield put({ type: actions.IMPORT_FILES_TABLE_ERROR, error })
    }
  }
}

function * getPk ({ fileId }) {
  try {
    const { pk } = yield call(api.userImport.setImport, fileId)
    yield put({ type: actions.IMPORT_FILES_SET_IMPORT_PK, pk })
  } catch (error) {
    yield put(serverError(error))
    yield put({ type: actions.IMPORT_FILES_TABLE_ERROR, error })
  }
}

function * startImport ({ importId }) {
  try {
    yield call(api.userImport.startImport, importId)
    const data = yield call(api.userImport.getImports)
    const statuses = yield call(api.userImport.getStatuses)
    const last = yield call(api.userImport.getLast)
    yield put({ type: actions.IMPORT_FILES_TABLE_INITIATED, data, statuses, last })
  } catch (error) {
    yield put(serverError(error))
    yield put({ type: actions.IMPORT_FILES_TABLE_ERROR, error })
  }
}

function * cancelImport ({ pk }) {
  try {
    yield call(api.userImport.cancelImport, pk)
    const data = yield call(api.userImport.getImports)
    yield put({ type: actions.IMPORT_FILES_TABLE_CANCELED, data })
  } catch (error) {
    yield put(serverError(error))
    yield put({ type: actions.IMPORT_FILES_TABLE_ERROR, error })
  }
}

function * removeImport ({ sel }) {
  const results = []
  let errors = false
  let error = {}
  for (let i = 0; i < sel.length; i += 1) {
    try {
      const imp = yield call(api.userImport.deleteImport, sel[i])
      results[i] = imp
    } catch (err) {
      errors = true
      error = err
    }
  }

  if (!errors) {
    try {
      const data = yield call(api.userImport.getImports)
      yield put({ type: actions.IMPORT_FILES_TABLE_REMOVED, data })
    } catch (err) {
      yield put(serverError(err))
      yield put({ type: actions.IMPORT_FILES_TABLE_ERROR, err })
    }
  } else {
    yield put(serverError(error))
    yield put({ type: actions.IMPORT_FILES_TABLE_ERROR, error })
  }
}

export default function * watch () {
  yield all([
    fork(watchLoad),
    fork(watchGetLast),
    fork(watchSetPage),
    fork(watchGetPk),
    fork(watchStartImport),
    fork(watchCancelImport),
    fork(watchRemoveImport)
  ])
}
