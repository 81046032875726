// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'
import update from 'immutability-helper'
import { compose } from 'redux'

import Button from '../../components/Button'
import Group from './Group'
import Input from '../../components/Input'
import * as actions from './RequestConstructor.actionTypes'

type Props = {
  activeBlock: ?Object,
  dispatch: Object => void,
  items: Array<Object>,
  onShowBlock: Object => void,
  t: string => string,
  workflow: string,
  working: boolean,
}
type State = {
  adding: boolean,
  items: ?Array<Object>,
  moveToGroup: ?number,
  moveToIndex: ?number,
}

class RequestConstructorGroups extends Component<Props, State> {
  state = {
    adding: false,
    items: null,
    moveToGroup: null,
    moveToIndex: null,
  }

  componentDidUpdate(prevProps: Props) {
    const { working } = prevProps

    if (working && !this.props.working) {
      this.setState({
        items: null,
        moveToGroup: null,
        moveToIndex: null,
      })
    }
  }

  getItems = () => this.state.items || this.props.items

  toggleAdding = active => {
    this.setState({ adding: active })
  }

  create = title => {
    const { dispatch, workflow } = this.props

    dispatch({ type: actions.REQUEST_CONSTRUCTOR_ADD_GROUP, title, workflow })
  }

  startDragBlock = () => {
    const { items } = this.props
    this.setState({ items })
  }

  endDragBlock = block => {
    const { dispatch, workflow } = this.props
    const { moveToIndex, moveToGroup } = this.state

    if (!moveToGroup) {
      return
    }

    dispatch({
      type: actions.REQUEST_CONSTRUCTOR_MOVE_BLOCK,
      id: block.id,
      from: block.group,
      to: moveToGroup,
      position: moveToIndex + 1,
      workflowId: workflow,
    })
  }

  moveBlock = (block, toIndex, toGroup) => {
    const { items, moveToGroup } = this.state

    if (!items) {
      return
    }

    const { id, group, title } = block

    const from = items.findIndex(g => g.id === (moveToGroup || group))
    const fromBlocks = items[from].blocks.filter(b => b.id !== id)
    const reduced = update(items, {
      [from]: {
        blocks: { $set: fromBlocks },
      },
    })

    const expanded = update(reduced, {
      [toGroup]: {
        blocks: { $splice: [[toIndex, 0, { id, title }]] },
      },
    })
    this.setState({
      items: expanded,
      moveToIndex: toIndex,
      moveToGroup: items[toGroup].id,
    })
  }

  render() {
    const { working, activeBlock, onShowBlock, t } = this.props
    const { adding } = this.state
    const items = this.getItems()

    const boxClass = classnames('request-constructor__groups', {
      'working-overlay': working,
    })

    return (
      <div className={boxClass}>
        {items.map(({ id, blocks, title }, i) => (
          <Group
            activeBlock={activeBlock}
            blocks={blocks}
            id={id}
            key={id}
            title={title}
            onEndDragBlock={this.endDragBlock}
            onMoveBlock={(b, to) => this.moveBlock(b, to, i)}
            onShowBlock={onShowBlock}
            onStartDragBlock={this.startDragBlock}
          />
        ))}
        {adding && (
          <Input.Edit
            alwaysActive
            className='request-constructor__group__create'
            working={working}
            onRemove={() => this.toggleAdding(false)}
            onSave={this.create}
            onToggleEdit={this.toggleAdding}
          />
        )}
        <Button.Add
          disabled={adding}
          title={t('addGroup')}
          onClick={() => this.toggleAdding(true)}
        />
      </div>
    )
  }
}

export default compose(
  withTranslation('RequestConstructor'),
  connect(state => ({
    items: state.requestConstructor.data.groups,
    working: state.requestConstructor.workingGroups,
  }))
)(RequestConstructorGroups)
