/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type OrderProfileComment_data$ref = any;
type OrderProfileCreationDate_data$ref = any;
type OrderProfileCustomer_data$ref = any;
type OrderProfileHeader_data$ref = any;
type OrderProfileOffer_data$ref = any;
type OrderProfilePrice_data$ref = any;
type OrderProfileProvider_data$ref = any;
type OrderProfileRequest_data$ref = any;
type OrderProfileStatus_data$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type OrderProfile_data$ref: FragmentReference;
declare export opaque type OrderProfile_data$fragmentType: OrderProfile_data$ref;
export type OrderProfile_data = {|
  +$fragmentRefs: OrderProfileHeader_data$ref & OrderProfileStatus_data$ref & OrderProfileCreationDate_data$ref & OrderProfilePrice_data$ref & OrderProfileRequest_data$ref & OrderProfileProvider_data$ref & OrderProfileOffer_data$ref & OrderProfileCustomer_data$ref & OrderProfileComment_data$ref,
  +$refType: OrderProfile_data$ref,
|};
export type OrderProfile_data$data = OrderProfile_data;
export type OrderProfile_data$key = {
  +$data?: OrderProfile_data$data,
  +$fragmentRefs: OrderProfile_data$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "OrderProfile_data",
  "type": "CustomerOrderNode",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "orderID",
      "type": "ID",
      "defaultValue": 0
    }
  ],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "OrderProfileHeader_data",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "OrderProfileStatus_data",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "OrderProfileCreationDate_data",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "OrderProfilePrice_data",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "OrderProfileRequest_data",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "OrderProfileProvider_data",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "OrderProfileOffer_data",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "OrderProfileCustomer_data",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "OrderProfileComment_data",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '881ded1ccaa6220a9b8da02a589a283e';
module.exports = node;
