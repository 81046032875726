// @flow

import { omitBy, partial } from 'lodash-es'

import { compare } from '../../../../utils/utils'
import {
  AMOUNT_DUE,
  NUMBER,
  PAYMENT_DATE,
  PAYMENT_TYPE,
  RECIPIENT,
  SUPPLIER,
  TAX,
  TYPE,
  BIC,
  IBAN,
  DEMAND,
  NOTES,
  INVOICE_FILE,
} from './InvoicesModal.constants'

export function getInitialValues(invoice: ?Object, fileId: number): Object {
  const initialInvoice = invoice || {}

  return {
    [TAX]: initialInvoice[TAX] || '',
    [DEMAND]: initialInvoice[DEMAND] || '',
    [AMOUNT_DUE]: initialInvoice[AMOUNT_DUE] || '',
    [PAYMENT_DATE]: initialInvoice[PAYMENT_DATE] || '',
    [TYPE]: (initialInvoice[TYPE] && initialInvoice[TYPE].id) || '',
    [SUPPLIER]: initialInvoice[SUPPLIER]
      ? {
          value: initialInvoice[SUPPLIER].id,
          label: initialInvoice[SUPPLIER].fullname,
        }
      : '',
    [RECIPIENT]: initialInvoice[RECIPIENT] || '',
    [NOTES]: initialInvoice[NOTES] || '',
    [BIC]: initialInvoice[BIC] || '',
    [IBAN]: initialInvoice[IBAN] || '',
    [NUMBER]: initialInvoice[NUMBER] || '',
    [PAYMENT_TYPE]:
      (initialInvoice[PAYMENT_TYPE] && initialInvoice[PAYMENT_TYPE].id) || '',
    [INVOICE_FILE]: initialInvoice[INVOICE_FILE]?.file || fileId || '',
  }
}

export function getNormalizedValues(
  initialValues: Object,
  submitValues: Object,
  fields: Array<Object>
): Object {
  let values = submitValues
  fields.forEach(field => {
    if (!field.visible) {
      values[field.name] = ''
    }
  })

  const partialCompare = partial(compare, initialValues)

  const changedValues = omitBy(values, partialCompare)

  return changedValues
}
