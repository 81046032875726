// @flow

import React from 'react'
import { connect } from 'react-redux'
import type { ComponentType, Node } from 'react'

import TileList from '../../components/TileList'
import { DEFAULT_UPLOAD_KEY } from './FilesUpload.constants'

type Props = {
  activeKey: string,
  ExtraItem?: ComponentType<*>,
  files?: Array<Object>,
  itemIndent: number,
  renderItem: (Object, string) => Node,
  rowLength: number,
  uploadingItems: Array<Object>,
  uploadKey?: string,
}

const FilesList = (props: Props) => {
  const {
    ExtraItem,
    files,
    uploadingItems,
    activeKey,
    uploadKey,
    itemIndent,
    rowLength,
  } = props

  const additionItems = ExtraItem ? [<ExtraItem key='extra' />] : []

  const showUploading = activeKey === uploadKey
  const list = additionItems.concat(
    (files || [])
      .concat(showUploading ? uploadingItems : [])
      .map(f => props.renderItem(f, `file-${f.id || f.name}`))
  )

  return (
    <TileList itemIndent={itemIndent} rowLength={rowLength}>
      {list}
    </TileList>
  )
}

FilesList.defaultProps = {
  uploadKey: DEFAULT_UPLOAD_KEY,
}

const mapStateToProps = state => ({
  uploadingItems: state.filesUpload.files,
  activeKey: state.filesUpload.activeKey,
})

export default connect(mapStateToProps)(FilesList)
