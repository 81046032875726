//@flow

import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Scrollbars } from 'react-custom-scrollbars'

import { usePrevious } from '../../../../hooks/usePrevious'
import ModalRow from '../../../Modal/ModalRow'
import List from '../../../List'
import CategoryListItem from './CategoryListItem'

import styles from './CategoryList.module.scss'

function CategoryList(props) {
  const { isloading, selected } = props

  const { t } = useTranslation('CategoryPopup')

  const ref = useRef(null)

  const prevSelected = usePrevious(selected)

  useEffect(() => {
    if (
      selected &&
      selected.length &&
      ref &&
      ref.current &&
      prevSelected?.length < selected.length
    ) {
      const scrollHeight = ref.current.getScrollHeight()
      ref.current.scrollToBottom(scrollHeight)
    }
  }, [selected])

  return (
    <ModalRow>
      <Scrollbars className={styles.scrollbar} ref={ref}>
        <List embedded>
          {selected.map(item => (
            <CategoryListItem
              key={item.id}
              isloading={isloading}
              t={t}
              item={item}
              changeSelected={props.changeSelected}
            />
          ))}
        </List>
      </Scrollbars>
    </ModalRow>
  )
}

export default CategoryList
