import React from 'react'

import styles from './ProfileAvatar.module.scss'

const ProfileAvatar = props => {
  const { fullname, image } = props

  return (
    <div
      className={styles.imageContainer}
    >
      <img src={image} alt={fullname} className={styles.image} />
    </div>
  )
}

export default ProfileAvatar
