// @flow

import { conforms, filter, get } from 'lodash-es'

export function isRequired(validationSchema: Object, name: string): boolean {
  const schemeProps = validationSchema.describe()

  const fieldProps = get(schemeProps, ['fields', name, 'tests'])

  if (!fieldProps) {
    return false
  }

  return !!filter(fieldProps, conforms({ name: value => value === 'required' }))
    .length
}

export function getInputParams(
  formik: Object,
  name: string,
  validationSchema: Object
): Object {
  return {
    required: isRequired(validationSchema, name),
    name,
    value: formik.values[name],
    error: formik.errors[name],
    onChange: formik.handleChange,
    onBlur: () => formik.validateField(name),
  }
}

export function getSelectParams(
  formik: Object,
  name: string,
  validationSchema: Object,
  onChange: Function
): Object {
  return {
    required: isRequired(validationSchema, name),
    name,
    value: formik.values[name],
    error: formik.errors[name],
    onChange,
  }
}
