// @flow

import React from 'react'

import Documents from '../Documents'

type Files = Array<Object>
type Props = {
  files: Files,
  onSave: Files => void,
  permissions: Object,
  saving?: boolean,
}

const PostFiles = (props: Props) => {
  const {
    saving,
    files,
    postUuid,
    permissions: { can_edit: canEdit, can_delete: canRemove },
  } = props

  if (!canEdit && !files.length) {
    return null
  }

  return (
    <Documents
      multiple
      canEdit={canEdit}
      canRemove={canRemove}
      files={files}
      updating={saving}
      scope='post'
      postUuid={postUuid}
      onSave={props.onSave}
    />
  )
}

export default PostFiles
