// @flow

import React from 'react'
import type { Node } from 'react'

import NewTable from '../../NewTable'
import EmailIntegrationTableBody from './EmailIntegrationTableBody'
import EmailIntegrationTableHeader from './EmailIntegrationTableHeader'

type Props = {
  emails: Array<Object>,
  isPrivate?: boolean,
  loading: boolean,
}

const EmailIntegrationTable = (props: Props): Node => {
  const { isPrivate, loading, emails } = props

  return (
    <NewTable>
      <EmailIntegrationTableHeader isPrivate={isPrivate} />
      <EmailIntegrationTableBody
        isPrivate={isPrivate}
        emails={emails}
        loading={loading}
      />
    </NewTable>
  )
}

export default EmailIntegrationTable
