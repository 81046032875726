// @flow

import React from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import Button from '../../Button'
import ModalText from '../../Modal/ModalText'
import ModalRow from '../../Modal/ModalRow'
import NewSelectAsync from '../../NewSelectAsync'
import api from '../../../core/api'

import styles from './BuildingPopup.module.scss'

type Props = {
  building: ?Object,
  changeSelected: Object => void,
  flat: ?Object,
  isDwellerGroup: boolean,
  isloading: boolean,
  selected: Array<Object>,
  setBuilding: Object => void,
  setFlat: Object => void,
}

const BuildingPicker = (props: Props): Node => {
  const { isloading, selected, building, flat, isDwellerGroup } = props

  const { t } = useTranslation('BuildingPopup')

  const [metaCount, setMetaCount] = useState(null)

  useEffect(() => {
    if (flat && building) {
      props.setBuilding(null)
      props.setFlat(null)
    }
  }, [selected])

  const getBuildingLabel = building =>
    building.address_obj?.value || building.address
  const getFlatLabel = flat => flat.number

  const handleChangeBuilding = building => {
    if (isDwellerGroup) {
      props.setBuilding(building)
    } else {
      props.changeSelected(building)
    }
  }

  const handleAddAddress = () => {
    props.changeSelected(flat)
  }

  const isItemSelected = (item: Object) => {
    return selected.some(s => s.id === item.id)
  }

  const canAddFlat = () => {
    if (!building || !flat) {
      return false
    }

    const flatSelected = flat && isItemSelected(flat)

    return !flatSelected
  }

  const handleMetaCount = count => {
    if (!metaCount) {
      setMetaCount(count)
    }
  }

  const checkAllSelected = selectedItems => {
    if (metaCount && metaCount === selectedItems.length) {
      return true
    }

    return false
  }

  const handleClickAll = isLoading => {
    props.setAllLoading(isLoading)
  }

  return (
    <>
      <ModalRow>
        <ModalText text={t('BuildingAddress')} />
      </ModalRow>
      <ModalRow>
        <NewSelectAsync
          isMulti={!isDwellerGroup}
          placeholder={t('Select:selectAddress')}
          api={api.building.getBuildingOptions}
          pageSize={10}
          view='dropdown'
          searchKey='search'
          selectedItems={
            isDwellerGroup ? (building ? [building] : []) : selected
          }
          getLabel={getBuildingLabel}
          getSelectedLabel={getBuildingLabel}
          permanentParams={{ is_promo: 0 }}
          className={styles.select}
          setSelectedItems={isDwellerGroup ? undefined : props.setSelected}
          isAllSelected={isDwellerGroup ? undefined : checkAllSelected}
          setMetaCount={isDwellerGroup ? undefined : handleMetaCount}
          onClick={handleChangeBuilding}
          onClickAll={isDwellerGroup ? undefined : handleClickAll}
        />
      </ModalRow>
      {isDwellerGroup && (
        <>
          <ModalRow>
            <ModalText text={t('Flat')} />
          </ModalRow>
          <ModalRow>
            <NewSelectAsync
              className={styles.select}
              placeholder={t('Select:selectFlat')}
              api={api.flat.getListTiny}
              pageSize={10}
              disabled={!building}
              view='dropdown'
              searchKey='number_icontains'
              selectedItems={flat ? [flat] : []}
              getLabel={getFlatLabel}
              getSelectedLabel={getFlatLabel}
              dynamicParams={{ building: building && building.id }}
              onClick={props.setFlat}
            />
          </ModalRow>
        </>
      )}
      {isDwellerGroup && (
        <ModalRow>
          <Button.Save
            disabled={isloading || !canAddFlat()}
            onClick={handleAddAddress}
          >
            {t('Common:Add')}
          </Button.Save>
        </ModalRow>
      )}
    </>
  )
}

export default BuildingPicker
