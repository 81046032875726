// @flow

import classnames from 'classnames'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { omit, get, isNull, omitBy } from 'lodash-es'
import { push, replace } from 'connected-react-router'
import { withRouter } from 'react-router-dom'

import { SimpleBlock } from '../../../components/Block'
import SelectCustom from '../../../components/Select/SelectCustom'
import PostHeader from '../../../components/Post/PostHeader/PostHeader'
import PostFooter from '../../../components/Post/PostFooter/PostFooter'
import Button from '../../../components/Button'
import Rating from '../../../components/Rating'
import ValidationText from '../../../components/ValidationText'
import ControlledDocuments from '../Documents/ControlledDocuments'
import Editor from '../Editor'
import Documents from '../Documents'
import * as selectors from '../Feedback.selectors'
import * as actions from '../Feedback.actions'
import { getInitialState, getExtraRatings } from '../Feedback.utils'
import { NEW_FEEDBACK_ID } from '../Feedback'
import { RATING_TYPES, USER_GROUPS } from '../../../constants'
import { getLocation, getUser } from '../../../utils/commonSelectors'
import { SimpleLike } from '../../../components/Like'
import { removeFile } from '../../../utils/file'
import { isStaffUser } from '../../../utils/utils'

import styles from './FeedbackPost.module.scss'

const MAX_LENGTH_TEXT = 2000

type Props = {
  data: Object,
  dateFormat: string,
  isWorking: boolean,
  t: string => string,
}
type State = {
  general: ?number,
  isChanged: boolean,
  isEditing: boolean,
  provider: ?number,
  text: ?string,
  uk: ?number,
}

class FeedbackPost extends PureComponent<Props, State> {
  state = getInitialState(this.props)

  componentDidUpdate(prevProps, prevState) {
    if (prevState.general !== this.state.general) {
      const { general: currentRating } = this.state
      const { requestId, generalRating } = this.props
      const params = {
        value: currentRating,
        requests: requestId,
        rating_type: RATING_TYPES.general,
      }

      prevState.general
        ? this.props.updateRating(generalRating.id, { value: currentRating })
        : this.props.sendRating(params)
    }

    if (
      prevProps.isWorking !== this.props.isWorking &&
      !this.props.isWorking &&
      this.state.isChanged
    ) {
      this.setState({ isChanged: false, isEditing: false })

      if (this.props.match.params.id === NEW_FEEDBACK_ID) {
        const { requestId } = this.props
        this.props.replace(`/request/${requestId}/feedback/`)
        this.props.closeFeedback()
      }
    }
  }

  componentWillUnmount() {
    this.props.closeFeedback()
  }

  handleChangeText = text => {
    this.setState({ text, isChanged: true })
  }

  handleChangeRating = type => value => {
    const changedParams = type !== 'general' ? { isChanged: true } : {}
    this.setState({ [type]: value, ...changedParams })
  }

  handleSelect = opt => {
    switch (opt.value) {
      case 'replay':
        this.handleReplay()

        return
      default:
        return null
    }
  }

  handleReplay = () => {
    const {
      data: {
        uuid,
        request_obj: { id },
      },
    } = this.props

    this.props.push(`/request/${id}/feedback/${uuid}/`)
  }

  handleCancel = () => {
    this.setState(omit(getInitialState(this.props), ['general']))
  }

  handleLike = type => () => {
    this.props.updateLike(type === 'dislike')
  }

  handleSubmit = () => {
    const { match, data } = this.props
    const { files, uk, provider, text } = this.state
    let payload = { files, text }

    if (text.length > MAX_LENGTH_TEXT) {
      this.props.throwFeedbackError({
        errorType: 'text',
        text: this.props.t('TextLengthError', { maxLength: MAX_LENGTH_TEXT }),
      })

      return
    }

    const extraRatings = getExtraRatings(data)

    payload = omitBy(
      {
        ...payload,
        uk: get(extraRatings, ['uk']) !== uk ? uk : null,
        provider:
          get(extraRatings, ['provider']) !== provider ? provider : null,
      },
      isNull
    )

    if (match.params.id === NEW_FEEDBACK_ID) {
      this.props.sendFeedback(payload)
    } else {
      this.props.updateFeedback(omit(payload, 'files'))
    }
  }

  handleOpenEditor = () => {
    this.setState({
      isEditing: true,
    })
  }

  handleRemoveFile = ({ id }: Object) => {
    this.setState(state => ({
      files: state.files.filter(f => f.id !== id),
      isChanged: true,
    }))

    removeFile(id)
  }

  handleStartFilesUpload = () => {
    this.setState({ filesUploading: true })
  }

  handleUpdateFiles = (files: Array<Object>) => {
    this.setState({
      files,
      filesUploading: false,
      isChanged: true,
    })
  }

  render() {
    const { isWorking, data, user, match, generalRating, error, requestId } =
      this.props

    const {
      params: { id },
    } = match
    const {
      text,
      general,
      uk,
      provider,
      files,
      filesUploading,
      isChanged,
      isEditing,
    } = this.state
    const feedbackUser = data.owner_obj || user
    const selectOptions = [{ value: 'replay', label: this.props.t('Replay') }]
    const isNewFeedback = !data.uuid
    const isVisibleButtonsBar =
      isNewFeedback || (get(generalRating, 'id') && (isChanged || isEditing))
    const readonly = !get(data, ['permissions', 'can_edit'])
    const isEditableFeedback = general && !readonly
    const isReadonlyRating = !(isEditableFeedback && !isStaffUser(user))
    const canLike = get(data, ['permissions', 'can_like_or_dislike'])
    const isFeedbackPage = match.path === '/request/:requestID/feedback/:id'
    const isTextError = get(error, 'errorType') === 'text'
    const errorText = get(error, 'text')
    const likeBarTitleClassName = classnames(
      'comments__likes-counter',
      styles.likeBarTitle
    )

    return (
      <SimpleBlock working={isWorking} className={styles.root}>
        <PostHeader header owner={feedbackUser} date={data.updated}>
          {!isFeedbackPage && (
            <SelectCustom
              actions
              blank
              options={selectOptions}
              onChange={this.handleSelect}
            />
          )}
        </PostHeader>

        <div className={styles.body}>
          <div className={styles.mainRating}>
            <Rating
              big
              label
              readonly={readonly || isStaffUser(user)}
              value={general}
              onClick={this.handleChangeRating('general')}
            />
          </div>
          <div className={styles.part}>
            <div className={styles.label}>{this.props.t('UkWorkQuality')}</div>
            <Rating
              big
              value={uk}
              readonly={isReadonlyRating}
              onClick={this.handleChangeRating('uk')}
            />
          </div>
          <div className={styles.part}>
            <div className={styles.label}>
              {this.props.t('ProviderWorkQuality')}
            </div>
            <Rating
              big
              value={provider}
              readonly={isReadonlyRating}
              onClick={this.handleChangeRating('provider')}
            />
          </div>
          <Editor
            canEdit={user.group !== USER_GROUPS.manager}
            disabled={!isEditableFeedback}
            value={text}
            maxLength={MAX_LENGTH_TEXT}
            isEditing={isEditing}
            onChange={this.handleChangeText}
            onOpen={this.handleOpenEditor}
          />
          <ValidationText active={isTextError} text={errorText} />
        </div>
        {isVisibleButtonsBar && (
          <div className='apply-profile-edit__submit'>
            <Button.Save disabled={!isChanged} onClick={this.handleSubmit}>
              {this.props.t('Common:Save')}
            </Button.Save>
            <Button.Cancel onClick={this.handleCancel}>
              {this.props.t('Common:Cancel')}
            </Button.Cancel>
          </div>
        )}

        {id === NEW_FEEDBACK_ID ? (
          <ControlledDocuments
            files={files}
            disabled={!general}
            isWorking={filesUploading}
            onRemove={this.handleRemoveFile}
            onStartUpload={this.handleStartFilesUpload}
            onUpdate={this.handleUpdateFiles}
          />
        ) : (
          <Documents />
        )}

        {id !== NEW_FEEDBACK_ID && (
          <PostFooter
            post={data}
            className={styles.footer}
            disabledConversation={isFeedbackPage}
            requestId={requestId}
          >
            <div like='true' className={styles.likeBar}>
              <span className={likeBarTitleClassName}>
                {this.props.t('LikeBarTitle')}
              </span>
              <SimpleLike
                disabled={!canLike}
                count={data.like_count}
                marked={data.is_liked}
                onClick={this.handleLike('like')}
              />
              <SimpleLike
                dislike
                disabled={!canLike}
                count={data.dislike_count}
                marked={data.is_disliked}
                onClick={this.handleLike('dislike')}
              />
            </div>
          </PostFooter>
        )}
      </SimpleBlock>
    )
  }
}

FeedbackPost.defaultProps = {
  text: '',
}

const mapStateToProps = state => ({
  data: selectors.getFeedbackData(state),
  isWorking: selectors.isWorking(state),
  isNew: selectors.isNew(state),
  user: getUser(state),
  requestId: selectors.getRequestId(state),
  generalRating: selectors.getGeneralRating(state),
  location: getLocation(state),
  error: selectors.getFeedbackError(state),
})
const mapDispatchToProps = {
  updateRating: actions.updateRating,
  sendRating: actions.sendRating,
  sendFeedback: actions.sendFeedback,
  updateFeedback: actions.updateFeedback,
  loadRating: actions.loadRating,
  closeFeedback: actions.closeFeedback,
  updateFeedbackFiles: actions.updateFeedbackFiles,
  updateLike: actions.updateLike,
  throwFeedbackError: actions.throwFeedbackError,
  push,
  replace,
}

export default compose(
  withTranslation('Feedback'),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(FeedbackPost)
