// @flow

import React, { Component } from 'react'
import { clamp, endsWith, toString } from 'lodash-es'

type Props = {
  accuracy: number,
  className: string,
  disabled: boolean,
  max: number,
  min: number,
  name: string,
  onChange: Object => void,
  type: string,
  value: any,
}

class InputNumber extends Component<Props> {
  static defaultProps = {
    accuracy: 2,
  }

  onChange = e => {
    const { accuracy, onChange } = this.props
    const isFloat = this.isFloat()
    const isNegative = this.isNegative()

    let value = e.target.value

    if (isNegative) {
      value = value.replace(/-+/i, '-')
    }

    if (isFloat) {
      value = value.replace(/,+/i, ',')

      if (accuracy) {
        const reg = new RegExp(
          `^([0-9]+,?(?:[0-9]{1,${accuracy}})?)[0-9]*$`,
          'i'
        )
        value = value.replace(reg, '$1')
      }
    }

    const ignoreParse =
      (isNegative && value === '-') ||
      (isFloat && (endsWith(value, ',') || endsWith(value, '0')))

    if (!ignoreParse) {
      const min =
        this.props.min || (isFloat ? Number.MIN_VALUE : Number.MIN_SAFE_INTEGER)
      const max =
        this.props.max || (isFloat ? Number.MAX_VALUE : Number.MAX_SAFE_INTEGER)
      value = isFloat ? this.toFloat(value) : parseInt(value, 10)
      value = isFloat ? value : clamp(value, isNegative ? min : 1, max)
    }

    onChange(
      e.target.name,
      isNaN(isFloat ? value.replace(',', '.') : value) ? '' : value,
      isFloat
    )
  }

  isEnabledProp = prop => prop in this.props && this.props[prop] !== false

  isFloat = () => this.isEnabledProp('float')

  isNegative = () => this.isEnabledProp('negative')

  toFloat = value =>
    value.length > 0
      ? toString(parseFloat(value.replace(',', '.'))).replace('.', ',')
      : value

  check = e => {
    const { value, onChange } = this.props

    let val = e.target.value

    if (this.isFloat()) {
      val = this.toFloat(val)
    }

    if (this.isNegative()) {
      val = isNaN(value) ? null : val
    }

    return val !== value && onChange(e.target.name, val)
  }

  render() {
    const { name, type, value, className, disabled } = this.props

    return (
      <div className={className}>
        <input
          value={value}
          disabled={disabled}
          type={type}
          name={name}
          autoFocus={this.isEnabledProp('autofocus')}
          onChange={this.onChange}
          onBlur={this.check}
        />
      </div>
    )
  }
}

export default InputNumber
