// @flow

import React from 'react'
import { get } from 'lodash-es'

import Value from './Value'

function Category(props) {
  const { user, archived } = props

  const title = get(user, ['categories', 'title'], '')

  const handleClick = () => props.onClick(user)

  return (
    <div className='table__cell'>
      <div className='table__cell-wrapper'>
        <div className='table-item table-item__text-building' title={title}>
          <Value
            title={title}
            user={user}
            archived={archived}
            onClick={handleClick}
          />
        </div>
      </div>
    </div>
  )
}

export default Category
