import { findIndex } from 'lodash-es'
import * as t from './ImportMapTable.actionTypes'

const {
  IMPORT_MAP_TABLE_INITIATING,
  IMPORT_MAP_TABLE_INITIATED,
  IMPORT_MAP_TABLE_ERROR,
  IMPORT_MAP_TABLE_HIDE_COLUMN,
  IMPORT_MAP_TABLE_SELECT,
  IMPORT_MAP_TABLE_SUBMIT,
  IMPORT_MAP_TABLE_SUBMIT_SUCCESS,
  IMPORT_MAP_TABLE_HIDE_HINT,
  IMPORT_MAP_TABLE_UNMOUNT,
  IMPORT_MAP_TABLE_REMOVE_SUCCESS,
  IMPORT_MAP_TABLE_CLOSE_MODAL,
  IMPORT_MAP_TABLE_HIDE_RESET_HINT
} = t

const initialState = {
  initiated: false,
  data: [],
  impData: {},
  error: false,
  fields: [],
  rows: [],
  indexes: [],
  hide_index: [],
  dropdowns: [],
  selected: [],
  dump_hide_index: [],
  statuses: [],
  isUpdating: false,
  submited: false,
  working: false,
  complete: false,
  hasError: false,
  hideHint: false,
  resetMaping: true,
  errorMessage: ''
}

export default function ImportMapTableReducer (state = initialState, action) {
  switch (action.type) {
    case IMPORT_MAP_TABLE_INITIATING: {
      return {
        ...state,
        initiated: false,
        working: true
      }
    }
    case IMPORT_MAP_TABLE_INITIATED: {
      const rows = []
      const indexes = []
      const dropdowns = []
      const hideIndex = []
      const length = action.data.data[1][1].length
      for (let i = 0; i < length; i += 1) { // API const, there are always 13 elements
        rows[i] = []
        action.data.data.forEach((col, index) => {
          const colKey = Object.keys(col)[0]
          indexes[colKey] = col
          dropdowns[colKey] = false
          hideIndex[colKey] = false
          rows[i][index] = col[colKey][i]
        })
      }
      const fields = action.data.fields.map((field, index) => ({ value: index + 1, label: field }))
      const headers = action.data.headers

      return {
        ...state,
        initiated: true,
        data: action.data,
        impData: action.iData,
        statuses: action.statuses,
        fields,
        headers,
        rows,
        indexes,
        dropdowns,
        hide_index: hideIndex,
        dump_hide_index: hideIndex,
        working: false
      }
    }
    case IMPORT_MAP_TABLE_CLOSE_MODAL:
      return {
        ...state,
        error: false
      }
    case IMPORT_MAP_TABLE_REMOVE_SUCCESS: {
      return {
        ...state,
        redirect: true
      }
    }
    case IMPORT_MAP_TABLE_HIDE_COLUMN: {
      const hidden = [...action.hided]
      hidden[action.index] = true
      const indexInSelected = findIndex(state.selected, { column_id: action.index })
      if (indexInSelected !== -1) {
        state.selected.splice(indexInSelected, 1)
      }
      return {
        ...state,
        selected: [...state.selected],
        resetMaping: false,
        hide_index: hidden
      }
    }
    case IMPORT_MAP_TABLE_HIDE_RESET_HINT: {
      return {
        ...state,
        resetMaping: true,
        selected: [],
        hide_index: state.dump_hide_index
      }
    }
    case IMPORT_MAP_TABLE_SELECT: {
      const selected = [...action.sel]
      const colIndex = action.col_index
      const findex = action.field_index
      let there = -1
      for (let i = 0; i < selected.length; i += 1) {
        if (selected[i].column_id === colIndex) {
          there = i
          break
        }
      }
      const dd = { ...action.columns }
      dd[action.col_index] = !dd[action.col_index]

      if (there > -1) {
        selected[there].field_id = findex
      } else {
        selected.push({ column_id: colIndex, field_id: findex })
      }

      return {
        ...state,
        selected,
        dropdowns: dd
      }
    }
    case IMPORT_MAP_TABLE_ERROR: {
      return {
        ...state,
        initiated: true,
        working: false,
        error: true,
        errorMessage: action.error.message.response.data.errors
      }
    }
    case IMPORT_MAP_TABLE_SUBMIT: {
      return {
        ...state,
        working: true,
        isUpdating: true
      }
    }
    case IMPORT_MAP_TABLE_SUBMIT_SUCCESS: {
      return {
        ...state,
        working: false,
        isUpdating: true,
        submited: true
      }
    }
    case IMPORT_MAP_TABLE_UNMOUNT: {
      return {
        ...state,
        working: false,
        submited: false,
        redirect: false,
        selected: []
      }
    }
    case IMPORT_MAP_TABLE_HIDE_HINT: {
      return {
        ...state,
        hideHint: true
      }
    }
    default:
      return state
  }
}
