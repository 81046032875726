/**
 * @param {string} text String to be split.
 * @param {string} delimeter Delimeters by which string is split.
 * @return {array} Array containing 1 part if there is no delimeter found in given text or 2 part split by the given delimeter.
 */
export function getMsgParts(text, delimeter) {
  const parser = new DOMParser()
  const stringToParse =
    text.substr(0, 6) === '<html>' ? text : `<html>${text}</html>`
  const htmlDoc = parser.parseFromString(stringToParse, 'text/html')
  const firstBlockquote = htmlDoc.querySelector('blockquote')

  if (!firstBlockquote) return [text]

  const blockquotesParent = firstBlockquote.parentElement
  const lastTopChild = blockquotesParent.lastElementChild

  if (typeof lastTopChild === 'object' && lastTopChild.nodeName === delimeter) {
    blockquotesParent.removeChild(lastTopChild)
    const { childNodes } = htmlDoc

    return [childNodes[0].innerHTML, lastTopChild.outerHTML]
  } else {
    return [text]
  }
}

/**
 * @param {string} text String to be split.
 * @param {string} delimeter Delimeters by which string is split.
 * @return {array} Array containing 1 part if there is no delimeter found in given text or 2 part split by the given delimeter.
 */
export function getMsgParts2(text, delimeter) {
  const splitIndex = text.lastIndexOf(delimeter)

  if (splitIndex !== -1) {
    return [text.substr(0, splitIndex), text.substr(splitIndex)]
  } else return [text]
}

/**
 * @param {string} text String to be split.
 * @param {array} delimeters Array of delimeters by which string is split.
 * @return {array}
 */
export function multiSplit(text, delimeters) {
  let result = []
  while (delimeters.length > 0) {
    for (let i = 0; i < result.length; i++) {
      const tempSplit = result[i].split(delimeters[0])
      result = result
        .slice(0, i)
        .concat(tempSplit)
        .concat(result.slice(i + 1))
    }
    delimeters.shift()
  }

  return result
}
