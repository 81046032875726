// @flow

import React, { useState, useEffect, useReducer } from 'react'
import classnames from 'classnames'
import moment from 'moment'
import { filter, difference } from 'lodash-es'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { goBack, push } from 'connected-react-router'
import { useTranslation } from 'react-i18next'

import Checkbox from '../../Checkbox/Checkbox'
import { isStaffUser } from '../../../utils/utils'
import { USER_GROUPS } from '../../../constants'
import { clearBr } from '../../../utils/text'
import * as actions from '../PostCreating/PostCreating.actionTypes'
import { removeFile } from '../../../utils/file'
import { DocumentsEditing } from '../../Documents'
import { EntityIcon } from '../../Icon'
import Button from '../../Button'
import { CellsBar, Cell, Row, SimpleBlock, Header } from '../../Block'
import DateTime from '../../DateTime'
import PostAudience from '../PostAudience'
import PostType from '../PostType'
import PostTimeInterval from '../PostTimeInterval'
import PostDescription from '../PostDescription'
import PostTitle from '../PostTitle'
import { notValidUserAction } from '../../../utils/utils'
import {
  getAudience,
  getTime,
  getTimeOptions,
  isSameDay,
  getPostTypeOptions,
} from '../Post.utils'
import {
  BUILDINGS_SCOPE,
  BUILDING_TYPE,
  SIGNUP_AUDIENCE,
  TO_BUILDING,
  TO_ALL_BUILDINGS,
  TICKER_TYPE,
  TO_DIGITAL_BOARD,
  FILES_ROW_LENGTH,
} from '../Post.constants'
import { postDataReset, postFilesUpdate } from '../Post.actions'
import { withClass, withInfoState, withValidation } from '../Post.hocs'
import ConfirmationPopup from '../../modals/ConfirmationPopup'
import Modal from '../../Modal'
import { getUser } from '../../../utils/commonSelectors'
import { getDwellerPermissions } from '../../../core/api/api.permission'

import styles from './PostCreate.module.scss'

export type Option = {
  label: string,
  value: string,
}

const PostCreate = props => {
  const { t } = useTranslation('Post')

  const {
    createdPost,
    postData: {
      files,
      allBuildings: initialAllBuildings,
      buildings: initialBuildings,
    },
    isDateFromOpen,
    isDateToOpen,
    audience,
    working,
    user,
  } = props

  const isStaff = isStaffUser(user)

  const postCreateClass = classnames('bar bar--default', styles.postCreate)
  const postTypeOptions = getPostTypeOptions(t)

  const [buildings, setBuildings] = useState(initialBuildings)
  const [allBuildings, setAllBuildings] = useState(initialAllBuildings)
  const [isGroupsPost, setGroupsPost] = useState(props.postData?.isGroupsPost)

  const [postTypeOption, setPostTypeOption] = useState(postTypeOptions[0])

  const [comment, setComment] = useState(false)

  const [modal, setModal] = useState(null)

  const [filesUploading, setFilesUploading] = useState(false)
  const [canDwelleCommentPosts, setDwellerCommentPosts] = useState(false)

  const [selectKey, forceUpdate] = useReducer(x => x + 1, 0) // hack

  useEffect(() => {
    if (isStaff) {
      getDwellerPermissions().then(data =>
        setDwellerCommentPosts(data.can_dweller_comment_post)
      )
    }

    return () => {
      props.dispatch(postDataReset())
    }
  }, [])

  useEffect(() => {
    if (createdPost) {
      props.dispatch(push('/'))
    }
  }, [createdPost])

  const removeItem = (id, type) => {
    const removeHandler =
      type === BUILDING_TYPE ? removeBuildingItem : removeAudienceItem

    removeHandler(id)
  }

  const hideModal = () => {
    setModal(null)
    forceUpdate()
  }

  const showModal = option => {
    if (option.value === postTypeOption.value) {
      return
    }

    if (!props.description && !props.title && !props.audience.length) {
      handleChangeType(option)

      return
    }

    setModal(
      <ConfirmationPopup
        title={t('ChangePostTypeTitle')}
        text={t('ConfirmPostType')}
        confirm={t('ChangeType')}
        onClose={hideModal}
        onOk={() => handleChangeType(option)}
      />
    )
  }

  const reset = () => {
    setAllBuildings(false)
    setBuildings([])
    props.setDescription('')
    props.setTitle('')
    props.setAudience([])
  }

  const handleChangeType = option => {
    hideModal()
    reset()
    setPostTypeOption(option)
    props.setPostType(option.value)
    forceUpdate()
  }

  const removeAudienceItem = id => {
    const { user } = props

    let newAudience = filter(audience, item => item !== id)

    if (user.group === 'dweller' && id === TO_BUILDING) {
      newAudience = difference(newAudience, SIGNUP_AUDIENCE)
    }

    if (user.group !== 'dweller' && id === TO_ALL_BUILDINGS) {
      newAudience = difference(newAudience, BUILDINGS_SCOPE)
      setAllBuildings(false)
      setBuildings([])
    }

    let buildingRelation = false

    newAudience.forEach(item => {
      if (BUILDINGS_SCOPE.includes(item)) {
        buildingRelation = true
      }
    })

    if (!newAudience.length || !buildingRelation) {
      setAllBuildings(false)
      setBuildings([])
    }

    if (id === TO_BUILDING) {
      newAudience = difference(newAudience, BUILDINGS_SCOPE)
      setAllBuildings(false)
      setBuildings([])
    }

    props.setAudience(newAudience)
  }

  const removeBuildingItem = id => {
    const newBuildings = filter(
      buildings,
      item => item.id !== id && item.value !== id
    )

    if (!newBuildings.length) {
      const newAudience = filter(
        audience,
        item => !BUILDINGS_SCOPE.includes(item)
      )

      if (newAudience.length !== audience.length) {
        props.setAudience(newAudience)
      }
    }

    setBuildings(filter(buildings, item => item.id !== id && item.value !== id))
  }

  const handleOpenDateFrom = () => {
    props.openDateFrom(!isDateFromOpen)
  }

  const handleOpenDateTo = () => {
    props.openDateTo(!isDateToOpen)
  }

  const onClickOut = () => {
    props.openDateFrom(false)
    props.openDateTo(false)
  }

  const updateDateFrom = date => {
    props.setDateFrom(date)
  }

  const updateDateTo = date => {
    props.setDateTo(date)

    incrementTimeTo(props.dateFrom, date, props.timeFrom)
  }

  const handleSetTimeFrom = time => {
    if (time.value >= props.timeTo.value) {
      incrementTimeTo(props.dateFrom, props.dateTo, time)
    }

    props.setTimeFrom(time)
  }

  const incrementTimeTo = (dateFrom, dateTo, timeFrom) => {
    if (isSameDay(dateFrom, dateTo)) {
      const indexTimeFrom = timeOptions.findIndex(
        opt => opt.value === timeFrom.value
      )
      props.setTimeTo(timeOptions[indexTimeFrom + 1])
    }
  }

  const updateAudience = (audience, buildings, allBuildings, isGroupsPost) => {
    props.setAudience(audience)
    setBuildings(buildings)
    setAllBuildings(allBuildings)
    setGroupsPost(isGroupsPost)
  }

  const clearInterval = () => {
    props.setDateFrom(null)
    props.setTimeFrom(getTime(null))
    props.setDateTo(null)
    props.setTimeTo(getTime(null))
  }

  const handleSubmit = e => {
    e.preventDefault()

    props.validate(error => {
      if (!error) {
        save()
      }
    })
  }

  const save = () => {
    const {
      user,
      postData: { request },
    } = props

    const showErrorAction = notValidUserAction(user)

    if (showErrorAction) {
      props.dispatch(showErrorAction)

      return
    }

    const formattedDateFrom = props.dateFrom
      ? moment(props.dateFrom)
          .set('hour', props.timeFrom.value)
          .set('minute', 0)
          .set('second', 0)
      : undefined

    const formattedDateTo = props.dateTo
      ? moment(props.dateTo)
          .set('hour', props.timeTo.value)
          .set('minute', 0)
          .set('second', 0)
      : undefined

    const buildingOrGroupField =
      user.group !== 'dweller' && isGroupsPost ? 'building_groups' : 'buildings'

    if (postTypeOption.value !== TICKER_TYPE) {
      const data = {
        title: props.title,
        comments_forbidden: comment,
        [buildingOrGroupField]: buildings.map(b => b.id || b.value),
        text: clearBr(props.description),
        files: files.map(f => f.id),
        date_from: formattedDateFrom,
        date_to: formattedDateTo,
        new_audience: audience,
        set_request: request,
      }

      props.dispatch({
        type: actions.CREATE_POST,
        data,
      })
    } else {
      const data = {
        comments_forbidden: comment,
        title: props.title,
        [buildingOrGroupField]: buildings.map(b => b.id || b.value),
        text: props.description,
        date_from: formattedDateFrom,
        date_to: formattedDateTo,
        new_audience: [TO_DIGITAL_BOARD],
        set_request: request,
      }

      props.dispatch({
        type: actions.CREATE_TICKER,
        data,
      })
    }
  }

  const cancel = () => {
    props.dispatch(goBack())
  }

  const handleRemove = ({ id }: Object) => {
    props.dispatch(postFilesUpdate(files.filter(f => f.id !== id)))

    removeFile(id)
  }

  const handleUpdate = files => {
    props.dispatch(postFilesUpdate(files))
    setFilesUploading(false)
  }

  const handleStartFilesUpload = () => {
    setFilesUploading(true)
  }

  const isSaveBtnDisabled = () =>
    !newAudience.length ||
    !props.title ||
    !props.description ||
    props.description === '<p><br></p>' ||
    filesUploading ||
    working

  const { group } = user

  const newAudience = getAudience(
    audience,
    buildings,
    user,
    allBuildings,
    isGroupsPost
  )
  const timeOptions = getTimeOptions()

  const handelChangeComment = e => setComment(e.currentTarget.checked)
  const templateParamsField = isGroupsPost
    ? 'for_building_group'
    : 'for_building'

  return (
    <>
      <SimpleBlock>
        <Header header goBack icon={<EntityIcon large id='post' />}>
          {t('NewPostTitle')}
        </Header>
        <CellsBar>
          <Cell title={t('CreatedDate')} className='order-date-create'>
            <DateTime dateTime={moment().format()} />
          </Cell>
          <Cell title={t('PostStatus')}>
            <span className={styles.status}>{t('NotActive')}</span>
          </Cell>
          {isStaff && canDwelleCommentPosts && (
            <Cell title={t('Comments')}>
              <span className={styles.toggleText}>{t('DenyCommentPost')}</span>
              <Checkbox
                style={{ marginLeft: '16px' }}
                checked={comment}
                onChange={handelChangeComment}
              />
            </Cell>
          )}
        </CellsBar>
        {group !== USER_GROUPS.dweller && (
          <PostType
            key={selectKey}
            postTypeOption={postTypeOption}
            handleChangeType={showModal}
            postTypeOptions={postTypeOptions}
          />
        )}
        {group !== USER_GROUPS.dweller && (
          <Row title={t('PostIntervalTitle')}>
            <PostTimeInterval
              setTimeFrom={handleSetTimeFrom}
              handleOpenDateFrom={handleOpenDateFrom}
              isDateToOpen={isDateToOpen}
              updateDateFrom={updateDateFrom}
              updateDateTo={updateDateTo}
              timeFrom={
                props.dateFrom ? props.timeFrom : { value: null, label: '-' }
              }
              timeTo={props.dateTo ? props.timeTo : { value: null, label: '-' }}
              handleOpenDateTo={handleOpenDateTo}
              setTimeTo={props.setTimeTo}
              timeOptions={timeOptions}
              dateFrom={props.dateFrom}
              dateTo={props.dateTo}
              isDateFromOpen={isDateFromOpen}
              onClickOut={onClickOut}
              onClear={clearInterval}
            />
          </Row>
        )}
        <PostAudience
          editing
          allBuildings={allBuildings}
          audience={audience}
          buildings={buildings}
          newAudience={newAudience}
          updateAudience={updateAudience}
          removeItem={removeItem}
          postType={postTypeOption.value}
          isGroupsPost={isGroupsPost}
        />
      </SimpleBlock>

      <section className='unit unit--default'>
        <PostTitle
          title={props.title}
          setTitle={props.setTitle}
          handleValidation={props.handleValidation}
          getValidationMessages={props.getValidationMessages}
        />
        <PostDescription
          displayTemplates
          templateParams={{
            [templateParamsField]:
              (buildings.length &&
                buildings.map(g => g.id || g.value).join(',')) ||
              undefined,
          }}
          description={props.description}
          setDescription={props.setDescription}
          handleValidation={props.handleValidation}
          getValidationMessages={props.getValidationMessages}
          postType={postTypeOption.value}
          setTitle={props.setTitle}
          files={files}
          title={props.title}
          updateDateFrom={updateDateFrom}
          updateDateTo={updateDateTo}
          setTimeTo={props.setTimeTo}
          setTimeFrom={props.setTimeFrom}
          onUpdateFiles={handleUpdate}
        />
        <div className={styles.actions}>
          <Button.Save disabled={isSaveBtnDisabled()} onClick={handleSubmit}>
            {t('SendButton')}
          </Button.Save>
          <Button.Cancel onClick={cancel}>{t('Cancel')}</Button.Cancel>
        </div>
      </section>

      {postTypeOption.value !== TICKER_TYPE && (
        <section className={postCreateClass}>
          <DocumentsEditing
            canEdit
            canRemove
            multiple
            files={files}
            rowLength={FILES_ROW_LENGTH}
            onRemove={handleRemove}
            onUpdate={handleUpdate}
            onStartUpload={handleStartFilesUpload}
          />
        </section>
      )}
      <Modal
        contentLabel=''
        className='Modal__Bootstrap modal-dialog'
        isOpen={!!modal}
        onRequestClose={hideModal}
      >
        {modal}
      </Modal>
    </>
  )
}

const mapStateToProps = state => ({
  user: getUser(state),
  postData: state.post,
  ...state.postCreating,
})

export default compose(
  connect(mapStateToProps),
  withClass,
  withInfoState,
  withValidation
)(PostCreate)
