// @flow

import React, { useState } from 'react'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'

import Icon from '../../../Icon'

import styles from './Search.module.scss'

const Search = props => {
  const { t } = useTranslation('Settings')
  const { name, placeholderKey, className, placeHolder } = props

  const [value, setValue] = useState(props.value)
  const [focused, setFocused] = useState(false)

  const handleFocus = () => setFocused(true)
  const handleBlur = () => setFocused(false)

  const inputClass = classnames(
    'input input--large input--block input--default',
    className || styles.title
  )

  const iconClass = classnames('input__icon', styles.icon)

  const handleChange = val => setValue(val)

  const handleClick = () => {
    props.setSearchValue(value)
  }

  const handleKeyDown = e => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      handleClick()
    }
  }

  return (
    <div className={inputClass}>
      <input
        maxLength={256}
        autoComplete='off'
        autoCorrect='off'
        name={name}
        placeholder={placeHolder || t(placeholderKey)}
        spellCheck={false}
        value={value}
        onChange={e => handleChange(e.target.value)}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
      />
      <Icon
        className={iconClass}
        id='magnifier'
        colored={focused}
        onClick={handleClick}
      />
    </div>
  )
}

export default Search
