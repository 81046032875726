// @flow

import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import linkifyHtml from 'linkifyjs/html'
import { push, replace } from 'connected-react-router'
import classnames from 'classnames'
import { compose } from 'redux'

import Block from '../../Block'
import PostHeader from '../PostHeader'
import Button from '../../Button'
import Chatroom from '../../../containers/Chatroom/Chatroom'
import PostFiles from '../PostFiles'
import * as actions from './PostBody.actionTypes'
import SelectCustom from '../../Select/SelectCustom'
import {
  newsListPinPost,
  newsListBlockPostInitiating,
  newsListBlockUserInitiating,
} from '../../../containers/NewsList/NewsList.actions'
import PostTitle from '../PostTitle'
import PostDescription from '../PostDescription'
import { withValidation, withBodyState } from '../Post.hocs'
import { USER_STATUS, BLOCKED_STATUS } from '../../../constants'
import { newLineToBr } from '../../../utils/text'

import styles from './PostBody.module.scss'

type Props = {
  description: string,
  dispatch: Object => void,

  edit: boolean,
  getValidationMessages: string => Array<string>,
  handleValidation: string => void,
  mode: boolean,
  onGallerySave: Object => void,
  onSave: Object => void,
  postData: {
    created: string,
    file_objs: Array<Object>,
    owner_obj: Object,
    permissions: Object,
    pinned: boolean,
    post_type: string,
    spam: boolean,
    text: string,
    title: string,
    uuid: string,
  },
  postsPinning: Array<string>,
  saving: boolean,
  t: string => string,
  title: string,
  validate: Object => void,
  working: boolean,
}

class PostBody extends Component<Props> {
  componentDidMount() {
    const { mode } = this.props

    this.props.dispatch({ type: actions.POST_BODY_INIT })

    if (mode) {
      this.edit()
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { edit, working } = this.props

    if (prevProps.mode && prevProps.edit && !edit) {
      this.props.dispatch(replace(`/post/${prevProps.postData.uuid}`))
    }

    if (prevProps.saving && prevProps.working && !working) {
      this.props.dispatch({ type: actions.POST_BODY_SAVED })
    }
  }

  componentWillUnmount() {
    this.props.dispatch({ type: actions.POST_BODY_INIT })
  }

  edit = () => {
    this.props.dispatch({ type: actions.POST_BODY_EDIT })
  }

  save = () => {
    const { title, description } = this.props

    this.props.validate(err => {
      if (!err) {
        this.props.onSave({ title, text: description })
        this.props.dispatch({ type: actions.POST_BODY_SAVE })
      }
    })
  }

  cancel = () => {
    const {
      mode,
      postData: { text, title },
    } = this.props

    if (mode) {
      this.props.dispatch(push('/'))
    } else {
      this.props.dispatch({ type: actions.POST_BODY_CANCEL })
      this.props.setDescription(text)
      this.props.setTitle(title)
    }
  }

  handlePin = () => {
    const {
      postData: { uuid, pinned },
    } = this.props

    this.props.dispatch(newsListPinPost(uuid, pinned))
  }

  getPinning = () => {
    const {
      postsPinning,
      postData: { uuid },
    } = this.props

    return postsPinning.has(uuid)
  }

  isPostBlocked = () => this.props.postData.spam

  isUserBlocked = () =>
    USER_STATUS[this.props.postData.owner_obj.status] === BLOCKED_STATUS

  blockPostOrUser = (opt: Object) => {
    const {
      postData: {
        owner_obj: { id: userId },
        uuid: postUuid,
      },
    } = this.props

    switch (opt.value) {
      case 0:
        this.props.dispatch(
          newsListBlockPostInitiating({
            postUuid,
            isUnblock: this.isPostBlocked(),
          })
        )
        break
      case 1:
        this.props.dispatch(
          newsListBlockUserInitiating({
            postUuid,
            userId,
            isUnblock: this.isUserBlocked(),
          })
        )
        break
      default:
    }
  }

  isSaveBtnDisabled = () =>
    !this.props.title ||
    !this.props.description ||
    this.props.description === '<p><br></p>'

  render() {
    const {
      saving,
      working,
      title,
      description,
      postData: {
        uuid,
        building_objs,
        building_group_objs,
        created,
        owner_obj: owner,
        file_objs: fileObjs,
        chat,
        pinned,
        post_type: postType,
        permissions,
        permissions: {
          can_edit: canEdit,
          can_add_comment: canAddComment,
          can_block: canBlock,
          can_block_owner: canBlockOwner,
          can_unblock_owner: canUnblockOwner,
          can_pin_post: canPinPost,
          can_unpin_post: canUnpinPost,
        },
      },
      edit,
    } = this.props

    const infoClass = classnames({ 'working-overlay': saving })
    const submitClass = classnames('apply-profile-edit__submit', infoClass)
    const canShowChat = canAddComment || chat
    const isVisiblePinButton = canPinPost || pinned || canUnpinPost
    const isVisibleActionSelect = canBlock || canBlockOwner || canUnblockOwner

    const textClassName = classnames(
      'u-a12__row u-a12__row--03 u-a12__row--03-padding-bottom',
      styles.text
    )

    const isDisablePinButton =
      (!canPinPost && !canUnpinPost) || this.getPinning()

    const blockOptions = []

    if (canBlock) {
      blockOptions.push({
        value: 0,
        label: this.props.t(this.isPostBlocked() ? 'UnblockPost' : 'BlockPost'),
      })
    }

    if (canBlockOwner || canUnblockOwner) {
      blockOptions.push({
        value: 1,
        label: this.props.t(this.isUserBlocked() ? 'UnblockUser' : 'BlockUser'),
      })
    }

    let templateParams = {}

    if (building_objs?.length > 0) {
      templateParams.for_building = building_objs.map(b => b.id).join(',')
    }

    if (building_group_objs?.length > 0) {
      templateParams.for_building_group = building_group_objs
        .map(g => g.id)
        .join(',')
    }

    return (
      <Fragment>
        <Block notCollapse>
          <PostHeader header date={created} owner={owner}>
            <div actions='true' style={{ display: 'flex' }}>
              {isVisiblePinButton && (
                <Button.Pin
                  disabled={isDisablePinButton}
                  active={pinned}
                  onClick={this.handlePin}
                />
              )}
              {isVisibleActionSelect && (
                <SelectCustom
                  blank
                  options={blockOptions}
                  onChange={this.blockPostOrUser}
                />
              )}
            </div>
          </PostHeader>
          {edit ? (
            <div className={infoClass}>
              <span className='apply-profile-edit__01'>
                {this.props.t('Theme')}
              </span>
              <PostTitle
                title={title}
                setTitle={this.props.setTitle}
                handleValidation={this.props.handleValidation}
                getValidationMessages={this.props.getValidationMessages}
              />
              <span className='apply-profile-edit__02'>
                {this.props.t('Text')}
              </span>
              <PostDescription
                description={description}
                setDescription={this.props.setDescription}
                handleValidation={this.props.handleValidation}
                getValidationMessages={this.props.getValidationMessages}
                postType={postType}
              />
            </div>
          ) : (
            <div>
              <h2 className='u-a12__title u-a12__title--01'>
                {title}
                {canEdit && <Button.Edit onClick={this.edit} />}
              </h2>
              <div className={textClassName}>
                <p
                  dangerouslySetInnerHTML={{
                    __html: linkifyHtml(newLineToBr(description)),
                  }}
                  className='u-a12__paragraph u-a12__paragraph--01'
                />
              </div>
            </div>
          )}

          {edit && (
            <div className={submitClass} style={{ marginTop: '1.5rem' }}>
              <Button.Save
                disabled={this.isSaveBtnDisabled()}
                onClick={this.save}
              >
                {this.props.t('SendButton')}
              </Button.Save>
              <Button.Cancel onClick={this.cancel}>
                {this.props.t('Cancel')}
              </Button.Cancel>
            </div>
          )}

          <PostFiles
            files={fileObjs}
            permissions={permissions}
            saving={working}
            postUuid={uuid}
            onSave={this.props.onGallerySave}
          />
        </Block>
        {canShowChat && (
          <section className='apply-profile-edit unit unit--default'>
            <h2 className='unit__title'>{this.props.t('Discussion')}</h2>
            <Chatroom
              isEmbedded
              isPublic
              key='clients'
              chatroomUuid={chat || 'create'}
              disabled={!canAddComment}
              postUuid={uuid}
              parent='post'
              scope='chat'
              templateParams={templateParams}
            />
          </section>
        )}
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  ...state.postBody,
  postsPinning: state.newsList.postsPinning,
})

export default compose(
  withTranslation('Post'),
  withBodyState,
  connect(mapStateToProps),
  withValidation
)(PostBody)
