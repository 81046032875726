// @flow

import React from 'react'
import classnames from 'classnames'
import { difference } from 'lodash-es'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import RequestFile from './RequestFile'
import FileUploader from './FileUploader'
import Checkbox from '../../Checkbox/Checkbox'
import Loader from '../../Loader'
import IconAdd from '../../../../static/icons/add-thin.svg'

import styles from './RequestFileList.module.scss'
import styles2 from './FileUploader/FileUploader.module.scss'

type Props = {
  className?: string,
  extended?: boolean,
  files: Array<Object>,
  filesAsLinks?: boolean,
  filesInRowCount: number,
  headerStyle?: Object,
  headerTitle?: string,
  isBodyLimit?: boolean,
  isEmailSendForm?: boolean,
  isLimit?: boolean,
  isRequestThread?: boolean,
  loading?: boolean,
  onChangeFilesAsLinks?: (event: { ... }) => void,
  onDocPreview?: number => void,
  onFilePreview?: number => void,
  onRemove?: Object => void,
  onStartUpload: (?Array<Object>) => void,
  onUpload: (Array<Object>) => void,
  outbound?: string,
  preview?: boolean,
  previewLoading?: number,
  selectedFiles: Array<number>,
  setSelectedFiles: (Array<number>) => void,
  showAddButton?: boolean,
  style?: Object,
  uploadingFiles?: Array<Object>,
}

const RequestFileList = (props: Props): ?Node => {
  const {
    files,
    selectedFiles,
    filesInRowCount,
    style,
    headerTitle,
    showAddButton,
    headerStyle,
    loading,
    isLimit,
    className,
    isBodyLimit,
    filesAsLinks,
    preview,
    onFilePreview,
    onDocPreview,
    previewLoading,
    isRequestThread,
    outbound,
    onChangeFilesAsLinks,
    onShowExtraTools,
    isEmailSendForm,
    uploadingFiles,
  } = props

  const { t } = useTranslation('Mail')

  const handleClick = fileId => {
    if (preview && onFilePreview) {
      onFilePreview(fileId)

      return
    }

    let nextSelectedFiles = []

    if (selectedFiles.includes(fileId)) {
      nextSelectedFiles = difference(selectedFiles, [fileId])
    } else {
      nextSelectedFiles = selectedFiles.concat([fileId])
    }

    props.setSelectedFiles(nextSelectedFiles)
  }

  const getSelected = file => {
    if (!selectedFiles || !props.setSelectedFiles) {
      return false
    }

    return selectedFiles.includes(file.id)
  }

  if ((!files || !files.length) && !showAddButton && !isBodyLimit) {
    return null
  }

  const filesClass = classnames(styles.files, { [styles.overlay]: loading })

  const fileProps = Object.assign(
    {},
    selectedFiles && props.setSelectedFiles
      ? { onClick: handleClick }
      : undefined
  )

  const fileDim = 100 / filesInRowCount

  return (
    <div className={className}>
      {headerTitle && !onShowExtraTools && (
        <h2 className={styles.header} style={headerStyle}>
          {headerTitle}
        </h2>
      )}
      {isLimit && (isBodyLimit || !filesAsLinks) && (
        <div className={styles.big}>{t('SizeExceeded')}</div>
      )}
      {isBodyLimit && <div className={styles.big}>{t('RemoveText')}</div>}
      {isLimit && !isBodyLimit && !filesAsLinks && (
        <div className={styles.big}>{t('RemoveFiles')}</div>
      )}
      {isLimit && !isBodyLimit && (
        <Checkbox
          checked={filesAsLinks}
          text={t('AttachLinks')}
          style={{ marginTop: '16px' }}
          onChange={onChangeFilesAsLinks}
        />
      )}

      <div className={filesClass} style={style}>
        {showAddButton && (!isEmailSendForm || !onShowExtraTools) && (
          <FileUploader
            fileDim={fileDim}
            outbound={outbound}
            onUpload={props.onUpload}
            onStartUpload={props.onStartUpload}
          />
        )}
        {isRequestThread && (!isEmailSendForm || !onShowExtraTools) && (
          <div
            className={styles2.add}
            style={{
              width: `calc(${fileDim}% - 12px)`,
              paddingBottom: `calc(${fileDim}% - 14px)`,
            }}
            title={t('AttachFile')}
            onClick={props.onOpenFiles}
          >
            <div className={styles2.drop}>
              <IconAdd />
            </div>
            <span>{t('AttachFile')}</span>
          </div>
        )}
        {files.map(file =>
          file.id === previewLoading ? (
            <Loader
              text={false}
              type='medium'
              style={{ margin: '25px 12px', height: `${fileDim}%` }}
            />
          ) : (
            <RequestFile
              fileDim={fileDim}
              file={file}
              key={file.id}
              selected={getSelected(file)}
              preview={preview}
              onRemove={props.onRemove}
              {...fileProps}
              {...(preview
                ? { onClick: file.doc ? onDocPreview : onFilePreview }
                : {})}
            />
          )
        )}
        {uploadingFiles &&
          uploadingFiles.map((file, i) => (
            <React.Fragment key={`${file.name}_${i}`}>
              <Loader
                text={false}
                type='medium'
                style={{ margin: '25px 12px', height: `${fileDim}%` }}
              />
            </React.Fragment>
          ))}
      </div>
    </div>
  )
}

RequestFileList.defaultProps = {
  filesInRowCount: 6,
  isEmailSendForm: false,
}

export default RequestFileList
