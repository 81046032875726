// @flow

import React from 'react'
import classNames from 'classnames'
import type { Node } from 'react'

import ItemLabels from './ItemLabels'
import ItemNotes from './ItemNotes'
import { useThemeColor } from '../../../../../../../../../hooks'

import styles from './ActivityItemBody.module.scss'

type Props = {
  isDone: boolean,
  isYesNo: ?'yes' | 'no',
  last?: boolean,
}

const ActivityItemBody = (props: Props): Node => {
  const { isYesNo, isDone, last } = props

  const themeColor = useThemeColor()

  const bodyClass = classNames(styles['item-body'], {
    [styles.done]: isDone || isYesNo,
    [styles.last]: last,
  })

  return (
    <div className={bodyClass} style={{ '--themeColor': themeColor }}>
      <ItemLabels isDone={isDone || isYesNo} />
      <ItemNotes />
    </div>
  )
}

export default ActivityItemBody
