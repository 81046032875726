// @flow

import { LOCATION_CHANGE } from 'connected-react-router'

type State = {
  pathname: string,
}

const initialState: State = {
  pathname: '',
}

export default (state: State = initialState, action) => {
  switch (action.type) {
    case LOCATION_CHANGE: {
      const {
        payload: {
          location: { pathname },
        },
      } = action

      return pathname === '/404' || pathname === '/404/'
        ? { ...state, pathname: state.pathname }
        : { ...state, pathname }
    }

    default:
      return state
  }
}
