// @flow

import React, { Component, Fragment } from 'react'
import { push } from 'connected-react-router'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { get } from 'lodash-es'

import Notifications from '../Notification/NotificationList'
import Pan from '../Tabs/Pan'
import PostCreating from '../Post/PostCreating'
import Tabs from '../Tabs/Tabs'
import WelcomeNews from '../../containers/NewsList/WelcomeNews'
import NewsList from '../../containers/NewsList'
import * as actions from '../../containers/NewsList/NewsList.actions'
import {
  getThemeColor,
  getUser,
  isWizardEnabled,
} from '../../utils/commonSelectors'
import NewWizard from '../NewWizard'

import { userCanCreateRequest } from '../../utils/utils'

import styles from './IndexContent.module.scss'

type Props = {
  dispatch: Object => void,
  hasMore: boolean,
  initiating: boolean,
  isWizardEnabled: boolean,
  location: Object,
  notifications: Array<Object>,
  permissions: Object,
  posts: Array<Object>,
  postsCount: number,
  router: Object,
  search: string,
  t: string => string,
  themeColor: string,
  user: Object,
}

type State = {
  isWizardOpen: boolean,
}

class IndexContent extends Component<Props, State> {
  state = {
    isWizardOpen: false,
  }

  addPost = (post: Object) => {
    this.props.dispatch(actions.newsListAddPost(post))
  }

  showError = (error: Object | Array<string>) => {
    this.props.dispatch(actions.newsListError(error))
  }

  handleCloseWizard = () => {
    this.setState({ isWizardOpen: false })
  }

  createRequest = () => {
    if (this.props.isWizardEnabled) {
      this.setState({ isWizardOpen: true })
    } else {
      this.props.dispatch(push('/request/create'))
    }
  }

  createBuilding = () => {
    this.props.dispatch(push(`/building/create`))
  }

  renderSearch = () => {
    const { search } = this.props

    return (
      <Fragment>
        <div className={styles['search-header']}>
          <h2>
            {this.props.t('SearchTitle')}: {decodeURIComponent(search)}
          </h2>
        </div>

        <NewsList
          key={decodeURIComponent(search)}
          additionalQueryParams={{ search: decodeURIComponent(search) }}
        />
      </Fragment>
    )
  }

  render() {
    const {
      notifications,
      initiating,
      search,
      posts,
      location,
      themeColor,
      postsCount,
      user,
    } = this.props

    const { isWizardOpen } = this.state

    if (search) {
      return this.renderSearch()
    }

    const showWelcomeNews =
      !initiating && location.pathname === '/' && postsCount === posts.length

    const canCreatePost = get(
      this.props,
      ['user', 'permissions', 'can_create_post'],
      false
    )

    const canCreateRequest = userCanCreateRequest(user)

    return (
      <div className='content--span2' style={{ '--themeColor': themeColor }}>
        <Notifications
          style={{ marginTop: '2rem' }}
          notifications={notifications}
        />
        {canCreatePost && (
          <div className={styles.postCreating}>
            <Tabs selected={0}>
              <Pan
                id='home-add-news-tab'
                label={this.props.t('ArticleHeader')}
                svg='post'
                linkHash='#addnews'
              >
                <PostCreating
                  onError={this.showError}
                  onWasCreated={this.addPost}
                />
              </Pan>
              {canCreateRequest && (
                <Pan
                  id='home-add-order-tab'
                  label={this.props.t('TicketHeader')}
                  svg='request'
                  onClick={this.createRequest}
                />
              )}
            </Tabs>
          </div>
        )}
        <Tabs
          selected={1}
          className='tabs tabs-style-transparent'
          storageKey='post-tab'
        >
          <Pan id='home-add-news-tab' label={this.props.t('AllPosts')}>
            <NewsList />
          </Pan>
          <Pan id='home-add-order-tab' label={this.props.t('News')}>
            <NewsList
              additionalQueryParams={{
                post_type: ['post', 'ticker'],
              }}
            />
          </Pan>
          <Pan id='home-add-order-tab' label={this.props.t('SystemPosts')}>
            <NewsList additionalQueryParams={{ post_type: 'system_post' }} />
          </Pan>
        </Tabs>
        {showWelcomeNews && (
          <WelcomeNews
            canCreateRequest={canCreateRequest}
            onBuildingCreate={this.createBuilding}
          />
        )}
        <div className='clearfix' />
        {isWizardOpen && <NewWizard onClose={this.handleCloseWizard} />}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state.newsList,
  user: getUser(state),
  themeColor: getThemeColor(state),
  isWizardEnabled: isWizardEnabled(state),
})

export default compose(
  withTranslation('IndexContent'),
  connect(mapStateToProps)
)(IndexContent)
