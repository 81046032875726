// @flow

import React from 'react'
import classnames from 'classnames'
import seapig, { OPTIONAL } from 'seapig'
import { isUndefined } from 'lodash-es'
import type { Node } from 'react'

import Button from '../../Button'
import HeaderIcon from './HeaderIcon'
import { formatChildren } from './Header.utils'

import styles from './Header.module.scss'

type Props = {
  chat?: boolean,
  children: Node,
  className?: string,
  collapsed?: boolean,
  goBack?: (() => void) | boolean,
  icon?: string | Node,
  notCollapse?: boolean,
  onClickHeader?: () => void,
  overflowVisible?: boolean,
  style?: Object,
  textTitle?: string,
  wide?: boolean,
}

const Header = (props: Props): Node => {
  const {
    chat,
    collapsed,
    notCollapse,
    className,
    icon,
    children,
    textTitle,
    style,
    overflowVisible,
  } = props

  const headerClass = classnames(styles.header, styles.root, className, {
    [styles.collapsable]: !notCollapse,
    [styles.expanded]: !collapsed && !notCollapse,
    [styles.collapsed]: collapsed && !notCollapse,
    [styles.visible]: overflowVisible,
  })

  const h2Class = classnames(styles.text, {
    [styles.visible]: overflowVisible,
  })

  const { extraChildren, iconChildren, rest } = seapig(
    formatChildren(children),
    { extra: OPTIONAL, icon: OPTIONAL }
  )

  const headerProps = Object.assign(
    {},
    { className: headerClass, style },
    props.onClickHeader ? { onClick: props.onClickHeader } : undefined
  )

  const backButtonProps =
    !props.goBack || props.goBack === true ? {} : { onClick: props.goBack }

  return (
    <div className={styles.headerWrap}>
      {chat && !isUndefined(props.goBack) && (
        <span className={styles.chatBackBtn}>
          <Button.Back {...backButtonProps} />
        </span>
      )}
      <div {...headerProps}>
        {!isUndefined(props.goBack) && !chat && (
          <Button.Back {...backButtonProps} />
        )}
        {(icon || !!(iconChildren && iconChildren.length)) && (
          <div className={styles.icon}>
            <HeaderIcon iconChildren={iconChildren} icon={icon} />
          </div>
        )}
        <h2 className={h2Class} title={textTitle}>
          {rest}
        </h2>
        {extraChildren}
      </div>
    </div>
  )
}

Header.defaultProps = {
  notCollapse: false,
  collapsed: false,
  className: '',
  textTitle: '',
}

export default Header
