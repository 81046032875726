// @flow

import React from 'react'
import { connect } from 'react-redux'

import Band from './Band'
import * as selectors from '../PublicBuildingPage.selectors'

type Props = {
  tickerNews: Array<Object>,
}

const NewsBand = (props: Props) => {
  const { tickerNews } = props

  return (
    <div className='public-building-page__news-band'>
      {tickerNews.length && <Band news={tickerNews} />}
    </div>
  )
}

const mapStateToProps = state => ({
  tickerNews: selectors.getNewsBand(state),
})

export default connect(mapStateToProps)(NewsBand)
