import { get } from 'lodash-es'

export const isLoading = state => state.feedback.loading
export const getFeedbackData = state => state.feedback.data
export const isWorking = state => state.feedback.working
export const isNew = state => !state.feedback.data.rating
export const getRequestId = state =>
  get(state.feedback.data, ['request_obj', 'id'])
export const getRequestTitle = state =>
  get(state.feedback.data, ['request_obj', 'title'])
export const getRequestNumber = state =>
  get(state.feedback.data, ['request_obj', 'request_no'])
export const getRequestBuilding = state =>
  get(state.feedback.data, ['request_obj', 'building'])
export const getGeneralRating = state => state.feedback.data.rating_obj
export const getChatroomUuid = state => state.feedback.data.chat
export const getFeedbackError = state => state.feedback.error
