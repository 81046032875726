// @flow

import { get } from 'lodash-es'
import { useTranslation } from 'react-i18next'

export const useRequestTitle = (): Function => {
  const { t } = useTranslation('Common')

  const getRequestTitle = request => {
    if (!request) {
      return ''
    }

    const requestNo = get(request, 'request_no')
    const title = get(request, 'title')

    if (!requestNo || !title) {
      return ''
    }

    return `${t('NumberSymbol')}${requestNo}: ${title}`
  }

  return getRequestTitle
}
