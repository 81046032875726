// @flow

import React, { Component } from 'react'
import OutsideClick from 'react-onclickout'
import classnames from 'classnames'

import Button from '../Button'

type Props = {
  alwaysActive?: boolean,
  className?: string,
  disabled?: boolean,
  draggable?: boolean,
  editing?: ?boolean,
  error?: boolean,
  maxLength?: number,
  onRemove: () => void,
  onSave: string => void,
  onToggleEdit?: boolean => void,
  placeholder?: string,
  style?: Object,
  value: ?string,
  working?: boolean,
}
type State = {
  editing: boolean,
  value: ?string,
}

class Edit extends Component<Props, State> {
  state = {
    editing: !!this.props.editing,
    value: this.props.value,
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { alwaysActive } = prevProps
    const { editing } = prevState

    if ((alwaysActive || editing) && 'working' in prevProps) {
      if (prevProps.working && !this.props.working) {
        this.cancel()
      }
    }
  }

  update = (e: Object) => {
    this.setState({ value: e.target.value })
  }

  save = () => {
    const { onSave } = this.props
    const { value } = this.state

    if (value) {
      onSave(value.trim())
    }

    if (!('working' in this.props)) {
      this.cancel()
    }
  }

  edit = (e: Object) => {
    e.stopPropagation()

    this.setState({ editing: true })

    if (this.props.onToggleEdit) {
      this.props.onToggleEdit(true)
    }
  }

  cancel = (resetValue: ?boolean) => {
    this.setState((state, props) => ({
      editing: false,
      value: resetValue ? props.value : state.value,
    }))

    if (this.props.onToggleEdit) {
      this.props.onToggleEdit(false)
    }
  }

  renderEdit = () => {
    const { maxLength, placeholder, onRemove } = this.props
    const { value } = this.state

    return (
      <OutsideClick onClickOut={() => this.cancel(true)}>
        <input
          autoFocus
          className='modal__text input input--default input--large'
          maxLength={maxLength}
          name='value'
          placeholder={placeholder}
          value={value || ''}
          onChange={this.update}
        />
        <div>
          <button
            disabled={!value || !value.trim()}
            className='button button--success button--default-3 button--square button--large button--square-check-x button--inline'
            onClick={this.save}
          />
          <button
            className='button button--default-3 button--square button--large button--square-x button--inline'
            onClick={onRemove}
          />
        </div>
      </OutsideClick>
    )
  }

  render() {
    const {
      alwaysActive,
      value,
      style,
      disabled,
      draggable,
      className,
      placeholder,
      error,
    } = this.props
    const { editing } = this.state

    const boxClass = classnames(className, 'input--edit', {
      'input--edit-disabled': disabled,
      'input--edit-active': alwaysActive || editing,
      'input--edit-error': error,
      'input--edit-draggable': draggable,
    })

    return (
      <div className={boxClass} style={style}>
        {alwaysActive || editing ? (
          this.renderEdit()
        ) : (
          <div>
            <span>{value || placeholder}</span>
            <Button.Edit className='input--edit--pencil' onClick={this.edit} />
          </div>
        )}
      </div>
    )
  }
}

export default Edit
