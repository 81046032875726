// @flow

import EmptyListIcon from './EmptyListIcon'
import type { EmptyListIconId } from './EmptyListIcon'

import EntityIcon from './EntityIcon'

import FileIcon from './FileIcon'

import Icon from './Icon'
import type { IconId } from './Icon.types'

export type { IconId, EmptyListIconId }

export { EmptyListIcon, EntityIcon, FileIcon }

export default Icon
