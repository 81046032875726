import * as actions from './ProfileContacts.actionTypes'

const initialState = {
  initiated: false,
  data: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.PROFILE_CONTACTS_INITIATING:
      return initialState
    case actions.PROFILE_CONTACTS_INITIATED:
      return {
        ...state,
        initiated: true
      }
    case actions.PROFILE_CONTACTS_UPDATE:
      return {
        ...state,
        data: action.data
      }
    default:
      return state
  }
}
