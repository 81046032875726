// @flow

import React, { useEffect, useState, useRef, useMemo } from 'react'
import classnames from 'classnames'
import OutsideClick from 'react-onclickout'
import Scrollbar from 'react-scrollbars-custom'
import classNames from 'classnames'
import { lowerCase } from 'lodash-es'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import List from '../List'
import ListItem from '../ListItem'
import ExpandArrow from '../ExpandArrow'
import Loader from '../Loader'
import ThumbY from '../NewFilterAsync/ThumbY'
import TrackY from '../NewFilterAsync/TrackY'
import Button from '../Button'
import {
  DEFAULT_LIST_SCROLL_SIZE,
  DEFAULT_LIST_SIZE,
} from './NewSelectSimple.constants'
import { DEFAULT_LIST_ITEM_HEIGHT } from '../ListItem/ListItem.constants'
import { LIST_PADDING } from '../List/List.constants'
import type { IconId } from '../Icon'

import styles from './NewSelectSimple.module.scss'
import searchStyles from '../NewFilterAsync/NewFilterAsyncSearch/NewFilterAsyncSearch.module.scss'

import useKeyPress from '../../hooks/useKeyPress'

type Option = {
  label: string,
  style?: Object,
  value: ?(string | number | boolean),
}

type Props = {
  additionalBottomMargin: number,
  buttonClass?: string,
  buttonIcon?: IconId,
  buttonType: 'regular' | 'edit',
  buttonView: 'contained' | 'outlined' | 'desaturated',
  className?: string,
  disabled: boolean,
  error?: boolean,
  getAvatar?: Object => Node,
  getSelectedLabel?: Option => string,
  isBottom?: boolean,
  isMulti: boolean,
  isTop?: boolean,
  listSize: number,
  loading?: boolean,
  name?: string,
  noResultsText?: string,
  onChange: Option => void,
  onClear?: Object => void,
  onOpen?: boolean => void,
  openClass?: string,
  options: Array<Option>,
  placeholder?: string,
  searchClass?: 'string',
  searchPlaceholder?: string,
  selectedItems?: Array<mixed>,
  setSelectedItems?: Option => void,
  style?: Object,
  toggleMenuCb?: boolean => void,
  useSearch?: boolean,
  value?: ?(Option | ''),
}

const NewSelectSimple = (props: Props): Node => {
  const {
    loading,
    isBottom,
    isTop,
    placeholder,
    options,
    className,
    value,
    name,
    disabled,
    noResultsText,
    buttonClass,
    buttonType,
    style,
    error,
    additionalBottomMargin,
    listSize,
    useSearch,
    searchPlaceholder,
    buttonView,
    buttonIcon,
    isMulti,
    selectedItems,
    searchClass,
    openClass,
  } = props

  const { t } = useTranslation('Select')
  const [isOpen, setOpen] = useState(false)
  const [position, setPosition] = useState('bottom')
  const [searchValue, setSearchValue] = useState('')
  const arrowUpPressed = useKeyPress('ArrowUp')
  const arrowDownPressed = useKeyPress('ArrowDown')
  const arrowEnterPressed = useKeyPress('Enter')
  const [isBoardMode, setIsBoardMode] = useState(false)

  const proccessedOptions = useMemo(() => {
    return searchValue
      ? options.filter(option =>
          lowerCase(option.label).includes(lowerCase(searchValue))
        )
      : options
  }, [options, searchValue])

  const [currentIndex, setCurrentIndex] = useState({
    index: 0,
    needScroll: false,
  })

  const selectRef = useRef()
  const scrollRef = useRef(null)
  const elemRef = useRef([])

  useEffect(() => {
    if (props.toggleMenuCb) {
      props.toggleMenuCb(isOpen)
    }

    if (searchValue) {
      setSearchValue('')
    }
  }, [isOpen])

  useEffect(() => {
    if (isOpen) {
      if (arrowUpPressed) {
        setIsBoardMode(true)
        setCurrentIndex(prevState => ({
          ...prevState,
          needScroll: true,
          index: prevState.index !== 0 ? prevState.index - 1 : 0,
        }))
      }
    }
  }, [arrowUpPressed, isOpen])

  useEffect(() => {
    if (isOpen) {
      if (arrowDownPressed) {
        setIsBoardMode(true)
        setCurrentIndex(prevState => ({
          ...prevState,
          needScroll: true,
          index:
            prevState.index !== proccessedOptions.length - 1
              ? prevState.index + 1
              : proccessedOptions.length - 1,
        }))
      }
    }
  }, [arrowDownPressed, isOpen])

  useEffect(() => {
    if (isOpen) {
      if (arrowEnterPressed) {
        handleSetValue(proccessedOptions[currentIndex.index])()
      }
    }
  }, [arrowEnterPressed])

  useEffect(() => {
    if (isOpen) {
      if (currentIndex.needScroll) {
        scrollRef.current?.centerAt(
          0,
          elemRef.current[currentIndex.index]?.offsetTop
        )
      }
    }
  }, [currentIndex, isOpen])

  const onKeyBoardMode = () => {
    if (isBoardMode) {
      setIsBoardMode(false)
    }
  }

  const handlerMouse = index => {
    if (isBoardMode) return

    setCurrentIndex(prevState => ({
      ...prevState,
      needScroll: false,
      index,
    }))
  }

  const hasSearch = useSearch && !!options.length

  const selectClass = classnames(styles.select, className, {
    [styles.open]: isOpen,
    [openClass]: isOpen,
  })

  const handleClick = () => {
    if (props.onOpen) {
      props.onOpen(!isOpen)
    }

    if (!isOpen && selectRef.current) {
      const sizeOfSearch = hasSearch
        ? DEFAULT_LIST_ITEM_HEIGHT + LIST_PADDING
        : 0

      const bottom =
        isBottom ||
        (!isTop &&
          window.innerHeight -
            selectRef.current.getBoundingClientRect().bottom >
            DEFAULT_LIST_ITEM_HEIGHT * DEFAULT_LIST_SIZE +
              LIST_PADDING * 2 +
              additionalBottomMargin +
              sizeOfSearch)

      setPosition(bottom ? 'bottom' : 'top')
    }

    setOpen(!isOpen)
  }

  const handleCloseList = () => {
    setOpen(false)

    if (props.onOpen) {
      props.onOpen(false)
    }
  }
  const handleSetValue = option => () => {
    if (!option) {
      return
    }

    props.onChange(option)

    if (isMulti && isOpen && props.setSelectedItems) {
      props.setSelectedItems(option)
    } else {
      handleCloseList()
    }
  }
  const buttonClassName = classnames(styles.button, buttonClass, {
    [styles.placeholder]: !value || !value.label,
  })

  const direction = isOpen ? 'bottom' : 'top'

  const listClass = classNames(styles.list, styles[position], {
    [styles['list-search']]: hasSearch,
  })

  const listStyle = hasSearch && { paddingTop: 0 }
  const searchClassName = classNames(searchStyles.search, searchClass)

  const handleChangeSearch = e => {
    setSearchValue(e.currentTarget.value)
  }

  if (buttonType === 'edit') {
    return (
      <OutsideClick onClickOut={handleCloseList}>
        <div className={selectClass} style={style} ref={selectRef}>
          <Button.Edit disabled={disabled} onClick={handleClick} />
          {isOpen && (
            <div className={styles.listWrapper}>
              <List className={listClass} onMouseMove={onKeyBoardMode}>
                {proccessedOptions.map((option, index) => {
                  return (
                    <ListItem
                      index={index}
                      avatar={props.getAvatar && props.getAvatar(option)}
                      style={option.style}
                      primaryText={option.label}
                      isSelected={currentIndex.index === index}
                      key={option.value ? option.value.toString() : 'empty'}
                      handlerMouse={handlerMouse}
                      onClick={handleSetValue(option)}
                    />
                  )
                })}
              </List>
            </div>
          )}
        </div>
      </OutsideClick>
    )
  }

  return (
    <OutsideClick onClickOut={handleCloseList}>
      <div className={selectClass} style={style} ref={selectRef}>
        {isMulti && selectedItems && placeholder ? (
          <Button.NewSelect
            buttonClass={buttonClass}
            error={!!error}
            name={name}
            disabled={disabled}
            placeholder={placeholder}
            selectedItems={selectedItems}
            getSelectedLabel={props.getSelectedLabel}
            menuIsOpen={isOpen}
            onClick={handleClick}
            onClear={props.onClear}
          />
        ) : (
          <Button.Regular
            icon={buttonIcon}
            disabled={disabled}
            view={buttonView}
            meta={<ExpandArrow direction={direction} disabled={disabled} />}
            className={buttonClassName}
            name={name}
            error={error}
            working={loading}
            onClick={handleClick}
          >
            {(value && value.label) || placeholder}
          </Button.Regular>
        )}
        {isOpen && (
          <List
            className={listClass}
            style={listStyle}
            onMouseMove={onKeyBoardMode}
          >
            {hasSearch && (
              <div className={searchClassName}>
                <div>
                  <input
                    autoFocus
                    placeholder={searchPlaceholder}
                    type='text'
                    value={searchValue}
                    onChange={handleChangeSearch}
                  />
                </div>
              </div>
            )}
            {loading ? (
              <div className={styles.loaderWrapper}>
                <Loader type='small' />
              </div>
            ) : proccessedOptions.length > DEFAULT_LIST_SCROLL_SIZE ? (
              <Scrollbar
                noScrollX
                ref={scrollRef}
                className='g-scroll--smooth'
                style={{ height: DEFAULT_LIST_ITEM_HEIGHT * listSize }}
                trackYProps={{
                  renderer: TrackY,
                }}
                thumbYProps={{
                  renderer: ThumbY,
                }}
              >
                <>
                  {proccessedOptions.map((option, index) => {
                    return (
                      <ListItem
                        index={index}
                        ref={ref => (elemRef.current[index] = ref)}
                        avatar={props.getAvatar && props.getAvatar(option)}
                        isHover={currentIndex.index === index}
                        isSelected={
                          isMulti
                            ? selectedItems.includes(option.value)
                            : currentIndex.index === index
                        }
                        handlerMouse={handlerMouse}
                        style={option.style}
                        primaryText={option.label}
                        title={props.getListTitle && props.getListTitle(option)}
                        key={option.value ? option.value.toString() : 'empty'}
                        onClick={handleSetValue(option)}
                      />
                    )
                  })}
                </>
              </Scrollbar>
            ) : (
              <>
                {proccessedOptions.length > 0 ? (
                  proccessedOptions.map((option, index) => {
                    return (
                      <ListItem
                        index={index}
                        avatar={props.getAvatar && props.getAvatar(option)}
                        isHover={currentIndex.index === index}
                        isSelected={
                          isMulti
                            ? selectedItems.includes(option.value)
                            : currentIndex.index === index
                        }
                        handlerMouse={handlerMouse}
                        style={option.style}
                        primaryText={option.label}
                        key={option.value ? option.value.toString() : 'empty'}
                        onClick={handleSetValue(option)}
                      />
                    )
                  })
                ) : (
                  <div className={styles.noResults}>
                    {noResultsText || t('NoResults')}
                  </div>
                )}
              </>
            )}
          </List>
        )}
      </div>
    </OutsideClick>
  )
}

NewSelectSimple.defaultProps = {
  disabled: false,
  buttonView: 'desaturated',
  buttonType: 'regular',
  listSize: DEFAULT_LIST_SIZE,
  additionalBottomMargin: 0,
  isMulti: false,
}

export default NewSelectSimple
