import { all, put, call, fork, takeLatest, select } from 'redux-saga/effects'

import api from '../../../core/api'
import { serverError } from '../../../components/Layout/Layout.actions'
import * as actions from './RealData.actionTypes'
import { formatConfig } from './RealData.utils'

function * watchInitiate () {
  yield takeLatest(actions.REAL_DATA_INITIATE, initiate)
}

function * watchCheckStatus () {
  yield takeLatest(actions.REAL_DATA_CHECK_STATUS, checkStatus)
}

function * watchCreate () {
  yield takeLatest(actions.REAL_DATA_CREATE, create)
}

function * watchApplyConfig () {
  yield takeLatest(actions.REAL_DATA_APPLY_CONFIG, applyConfig)
}

function * watchSync () {
  yield takeLatest(actions.REAL_DATA_SYNCHRONIZE, sync)
}

function * watchMatchRequests () {
  yield takeLatest(actions.REAL_DATA_MATCH_REQUESTS, matchRequests)
}

function * watchCopyRequestTypes () {
  yield takeLatest(actions.REAL_DATA_COPY_REQUEST_TYPES, copyRequestTypes)
}

function * handleError (error, type = actions.REAL_DATA_ERROR) {
  const { response } = error.message
  yield put({ type, response })
  yield put(serverError(error))
}

function * getData () {
  try {
    const config = yield call(api.integrations.getRealDataConfig)
    const status = yield call(api.integrations.getRealDataStatus)
    const { managers_list: managers } = yield call(api.integrations.getRealDataAdditional)

    return { config, status, exist: true, managers }
  } catch (error) {
    if (error.message.response.status === 404) {
      return { config: {}, status: {}, exist: false, managers: [] }
    }
    throw error
  }
}

function * getRequests () {
  const requests = yield call(api.integrations.getRealDataMatchingRequests)
  const { is_types_copied: wereCopied } = yield call(
    api.integrations.checkRealDataCopiedTypes
  )
  return { requests, requestsWereCopied: wereCopied }
}

function * initiate () {
  try {
    const configData = yield getData()
    const requestsData =
      // TODO ????
      configData.exist && configData.config.overwrite_request && false // eslint-disable-line
        ? yield getRequests()
        : {
          requests: { unmapped: [] },
          requestsWereCopied: false
        }
    yield put({
      type: actions.REAL_DATA_WAS_INITIATED,
      data: {
        ...configData,
        ...requestsData
      }
    })
  } catch (error) {
    yield put(serverError(error))
  }
}

function * checkStatus () {
  try {
    const status = yield call(api.integrations.getRealDataStatus)
    yield put({ type: actions.REAL_DATA_STATUS_WAS_CHECKED, status })
  } catch (error) {
    yield * handleError(error)
  }
}

function * create ({ config }) {
  try {
    yield call(api.integrations.createRealData, config)
    // const requestsData = yield getRequests()
    yield put({
      type: actions.REAL_DATA_WAS_CREATED,
      data: { config }
      // data: { config, ...requestsData },
    })
  } catch (error) {
    yield * handleError(error)
  }
}

function * applyConfig ({ config }) {
  try {
    const initialConfig = yield select(({ realData }) => realData.config)

    yield call(api.integrations.applyRealDataConfig, formatConfig(config, initialConfig))
    yield put({ type: actions.REAL_DATA_CONFIG_WAS_APPLIED, config })
  } catch (error) {
    yield * handleError(error)
  }
}

function * sync () {
  try {
    yield call(api.integrations.syncRealData)
    yield put({
      type: actions.REAL_DATA_STATUS_WAS_CHECKED,
      status: { is_sync_in_progress: true }
    })
  } catch (error) {
    yield * handleError(error)
  }
}

function * matchRequests (action) {
  try {
    const items = yield call(
      api.integrations.realDataMatchRequests,
      action.items
    )
    yield put({ type: actions.REAL_DATA_REQUESTS_WERE_MATCHED, items })
  } catch (error) {
    yield * handleError(error, actions.REAL_DATA_POPUP_ERROR)
  }
}

function * copyRequestTypes (action) {
  try {
    yield call(api.integrations.realDataMatchRequests, action.items)
    const items = yield call(api.integrations.copyRequestsFromRealData)
    yield put({ type: actions.REAL_DATA_REQUEST_TYPES_WERE_COPIED, items })
  } catch (error) {
    yield * handleError(error, actions.REAL_DATA_POPUP_ERROR)
  }
}

export default function * watch () {
  yield all([
    fork(watchInitiate),
    fork(watchCheckStatus),
    fork(watchCreate),
    fork(watchApplyConfig),
    fork(watchSync),
    fork(watchMatchRequests),
    fork(watchCopyRequestTypes)
  ])
}
