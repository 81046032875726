// @flow

import React, { Fragment, useRef } from 'react'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { isEmpty } from 'lodash-es'
import type { Element } from 'react'

import addRedirectToFirstPage from '../../../../hocs/addRedirectToFirstPage'

import Loader from '../../../Loader'
import UserListHead from './UserListHead'
import UserListBody from './UserListBody'
import Modal from '../../../Modal'
import BottomPagination from '../../../Pagination/BottomPagination'
import EmptyList from '../../../EmptyList'
import SelectPopUpUsers from '../SelectPopUpUsers'

type Props = {
  hideModal: Function,
  initiated: boolean,
  meta: Object,
  modal: Element<'div'>,
  setModal: (Element<'div'>) => void,
  t: string => string,
  users: Array<Object>,
  working: boolean,
}

const UserListTable = (props: Props) => {
  const { initiated, modal, working, meta, users } = props
  const ref = useRef()
  const tableClass = classnames('table table--default', {
    'working-overlay': working,
  })

  if (!initiated || isEmpty(meta)) {
    return <Loader text={false} type='medium' />
  }

  return (
    <Fragment>
      <div className={tableClass}>
        <div
          className='table__wrapper'
          style={{ tableLayout: 'fixed' }}
          ref={ref}
        >
          <UserListHead />
          {!!users.length && (
            <UserListBody setModal={props.setModal} load={props.load} />
          )}
          <SelectPopUpUsers
            parentWidth={ref?.current?.getBoundingClientRect().width}
          />
        </div>
      </div>
      {!!modal && (
        <Modal isOpen onRequestClose={props.hideModal}>
          {modal}
        </Modal>
      )}
      <BottomPagination classes='paginator paginator--middle' meta={meta} />
      {!users.length && (
        <EmptyList embedded icon='user' title={props.t('NoUsers')} />
      )}
    </Fragment>
  )
}

const mapStateToProps = state => ({
  ...state.userList,
})

export default compose(
  withTranslation('User'),
  connect(mapStateToProps),
  addRedirectToFirstPage
)(UserListTable)
