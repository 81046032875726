// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../../Button'
import { canEditFlat, isNotConfirmedUser } from './FlatAddress.utils'

const FlatAddressHeader = props => {
  const { editing } = props

  const isEditable = () => {
    const { flat, canEditFlatNumber, status } = props

    return (
      props.onSave &&
      (canEditFlat(flat) || (canEditFlatNumber && isNotConfirmedUser(status)))
    )
  }

  const { t } = useTranslation('Flat')

  return (
    <h2 className='unit__title apartment-profile__title'>
      {t('FlatAddress')}
      {!editing && isEditable() && <Button.Edit onClick={props.edit} />}
    </h2>
  )
}

export default FlatAddressHeader
