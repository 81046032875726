// @flow

import * as actionTypes from './Chatroom.actionTypes'
import { WIDGET_FILES_LOAD } from '../../components/widgets/WidgetFiles/WidgetFiles.actionTypes'

type Simple = { type: string }

type Message = {
  created: string,
  files: Array<{
    filename: string,
    human_mime_type: ?string,
    id: number,
    mime_type: string,
    origin: ?string,
    preview: ?string,
    short_mime_type: ?string,
    size: number,
  }>,
  subject: ?string,
  text: string,
  user: number,
  uuid: string,
  viewed: boolean,
}

type GetOldMessagesErrorAction = { error: string, type: string }

type GetNewMessagesInitiatingAction = {
  limit: number,
  messageUuid: string,
  type: string,
  uuid: string,
}

type GetNewMessagesInitiatedAction = {
  messages: Array<Message>,
  type: string,
  uuid: string,
}

type GetNewMessagesErrorAction = { error: string, type: string }

type Member = {
  avatar: string,
  name: string,
  owner: number,
  second_name: ?string,
  surname: ?string,
}
type Members = Array<Member>

type CreateParams = {
  fileIds: Array<number>,
  isPublic: boolean,
  postUuid: string,
  requestId: number,
  text: string,
  title: string,
  users: Array<number>,
}

type CreateAction = {
  params: CreateParams,
  type: string,
}

type SendMessageErrorAction = { error: string, type: string }

type WasCreatedAction = {
  isPublic: boolean,
  request: number,
  type: string,
  uuid: string,
}

type AddMembersInitiatingAction = {
  type: string,
  users: Array<number>,
  uuid: string,
}

type AddMembersInitiatedAction = { members: Members, type: string }

export const reset = (): Simple => ({ type: actionTypes.CHATROOM_RESET })

export const initiate = params => {
  return {
    type: actionTypes.CHATROOM_INITIATE,
    ...params,
  }
}

export const wasInitiated = result => ({
  type: actionTypes.CHATROOM_WAS_INITIATED,
  ...result,
})

export function getOldMessagesInitiating({
  uuid,
  limit,
  messageUuid,
  page,
  outbound,
}) {
  return {
    type: actionTypes.GET_OLD_MESSAGES_INITIATING,
    uuid,
    limit,
    messageUuid,
    page,
    outbound,
  }
}

export function getOldMessagesInitiated(result) {
  return {
    type: actionTypes.GET_OLD_MESSAGES_INITIATED,
    result,
  }
}

export function getOldMessagesError(error: string): GetOldMessagesErrorAction {
  return {
    type: actionTypes.GET_OLD_MESSAGES_ERROR,
    error,
  }
}

export function getNewMessagesInitiating(
  uuid: string,
  limit: number,
  messageUuid: string,
  outbound?: string
): GetNewMessagesInitiatingAction {
  return {
    type: actionTypes.GET_NEW_MESSAGES_INITIATING,
    uuid,
    limit,
    messageUuid,
    outbound,
  }
}

export function getNewMessagesInitiated(
  uuid: string,
  messages: Array<Message>
): GetNewMessagesInitiatedAction {
  return {
    type: actionTypes.GET_NEW_MESSAGES_INITIATED,
    messages,
    uuid,
  }
}

export function getNewMessagesError(error: string): GetNewMessagesErrorAction {
  return {
    type: actionTypes.GET_NEW_MESSAGES_ERROR,
    error,
  }
}

export function sendMessageInitiating({
  uuid,
  text,
  fileIds,
  limit,
  messageUuid,
  requestId,
  newMemberToSet,
  outbound,
}) {
  return {
    type: actionTypes.SEND_MESSAGE_INITIATING,
    uuid,
    text,
    fileIds,
    limit,
    messageUuid,
    requestId,
    newMemberToSet,
    outbound,
  }
}

export function sendMessageInitiated(uuid, sentMessage, newMemberToSet) {
  return {
    type: actionTypes.SEND_MESSAGE_INITIATED,
    uuid,
    sentMessage,
    newMemberToSet,
  }
}

export function sendMessageError(error: string): SendMessageErrorAction {
  return {
    type: actionTypes.SEND_MESSAGE_ERROR,
    error,
  }
}

export function create(params: CreateParams): CreateAction {
  return { type: actionTypes.CHATROOM_CREATE, params }
}

export function wasCreated(
  uuid: string,
  request: number,
  isPublic: boolean
): WasCreatedAction {
  return {
    type: actionTypes.CHATROOM_WAS_CREATED,
    uuid,
    request,
    isPublic,
  }
}

export function addMembersInitiating(
  uuid: string,
  users: Array<number>
): AddMembersInitiatingAction {
  return {
    type: actionTypes.ADD_MEMBERS_INITIATING,
    uuid,
    users,
  }
}

export function addMembersInitiated(
  members: Members
): AddMembersInitiatedAction {
  return {
    type: actionTypes.ADD_MEMBERS_INITIATED,
    members,
  }
}

type AddMembersErrorAction = { error: string, type: string }

export function addMembersError(error: string): AddMembersErrorAction {
  return {
    type: actionTypes.ADD_MEMBERS_ERROR,
    error,
  }
}

type RemoveMembersInitiatingAction = {
  type: string,
  users: Array<number>,
  uuid: string,
}

export function removeMembersInitiating(
  uuid: string,
  users: Array<number>
): RemoveMembersInitiatingAction {
  return {
    type: actionTypes.REMOVE_MEMBERS_INITIATING,
    uuid,
    users,
  }
}

type RemoveMembersInitiatedAction = { type: string, users: Array<number> }

export function removeMembersInitiated(): RemoveMembersInitiatedAction {
  return {
    type: actionTypes.REMOVE_MEMBERS_INITIATED,
  }
}

type RemoveMembersErrorAction = { error: string, type: string }

export function removeMembersError(error: string): RemoveMembersErrorAction {
  return {
    type: actionTypes.REMOVE_MEMBERS_ERROR,
    error,
  }
}

type RenameChatroomInitiatingAction = {
  chatroomUuid: string,
  title: string,
  type: string,
}

export function renameChatroomInitiating(
  chatroomUuid: string,
  title: string
): RenameChatroomInitiatingAction {
  return {
    type: actionTypes.RENAME_CHATROOM_INITIATING,
    chatroomUuid,
    title,
  }
}

type RenameChatroomInitiatedAction = { title: string, type: string }

export function renameChatroomInitiated(title): RenameChatroomInitiatedAction {
  return {
    type: actionTypes.RENAME_CHATROOM_INITIATED,
    title,
  }
}

type RenameChatroomErrorAction = { error: string, type: string }

export function renameChatroomError(error: string): RenameChatroomErrorAction {
  return {
    type: actionTypes.RENAME_CHATROOM_ERROR,
    error,
  }
}

type LikeMessageInitiatingAction = {
  isLike: boolean,
  isRemove: boolean,
  messageUuid: string,
  type: string,
}

export function likeMessageInitiating(
  messageUuid: string,
  isLike: boolean,
  isRemove: boolean,
  outbound?: string
): LikeMessageInitiatingAction {
  return {
    type: actionTypes.LIKE_MESSAGE_INITIATING,
    messageUuid,
    isLike,
    isRemove,
    outbound,
  }
}

type LikeMessageInitiatedAction = {
  isLike: boolean,
  isRemove: boolean,
  messageUuid: string,
  type: string,
}

export function likeMessageInitiated(
  messageUuid: string,
  isLike: boolean,
  isRemove: boolean
): LikeMessageInitiatedAction {
  return {
    type: actionTypes.LIKE_MESSAGE_INITIATED,
    messageUuid,
    isLike,
    isRemove,
  }
}

type LikeMessageErrorAction = { error: string, type: string }

export function likeMessageError(error: string): LikeMessageErrorAction {
  return {
    type: actionTypes.LIKE_MESSAGE_ERROR,
    error,
  }
}

// ================= Actions from right widget ==================

export function openAddMembersPopUp() {
  return {
    type: actionTypes.SHOW_MODAL_ADD_MEMBERS,
  }
}

export function openLabelsPopUp() {
  return {
    type: actionTypes.SHOW_MODAL_LABELS,
  }
}

export function openConvertToRequestPopUp() {
  return {
    type: actionTypes.SHOW_MODAL_CONVERT_TO_REQUEST,
  }
}

export function hideModal() {
  return {
    type: actionTypes.HIDE_MODAL,
  }
}

// ================= Actions with labels ==================

type UpdateLabelsInitAction = {
  chatroomUuid: string,
  labels: any,
  type: string,
}

export function updateLabelsInit(
  chatroomUuid: string,
  labels: any
): UpdateLabelsInitAction {
  return {
    type: actionTypes.UPDATE_LABELS_INIT,
    chatroomUuid,
    labels,
  }
}

type UpdateLabelsSuccessAction = {
  chatroomUuid: string,
  labels: any,
  type: string,
}

export function updateLabelsSuccess(
  chatroomUuid: number,
  labels: any
): UpdateLabelsSuccessAction {
  return {
    type: actionTypes.UPDATE_LABELS_SUCCESS,
    chatroomUuid,
    labels,
  }
}

type UpdateLabelsErrorAction = { error: string, type: string }

export function updateLabelsError(error: string): UpdateLabelsErrorAction {
  return {
    type: actionTypes.UPDATE_LABELS_ERROR,
    error,
  }
}

export function toggleChatroomMessage(uuid) {
  return {
    type: actionTypes.TOGGLE_CHATROOM_MESSAGE,
    uuid,
  }
}

export function initiateWidgetFiles({ chatroomUuid = null, requestId = null }) {
  return {
    type: WIDGET_FILES_LOAD,
    chatroom: chatroomUuid,
    request: requestId,
  }
}

export function toggleWidget(openedWidget) {
  return {
    type: actionTypes.TOGGLE_WIDGET,
    openedWidget,
  }
}

export function updateCounters() {
  return {
    type: actionTypes.CHATROOM_UPDATE_COUNTERS,
  }
}

export function deleteFiles(
  uuid,
  editingMessageUuid,
  fileIds,
  limit,
  deletingFileId,
  outbound
) {
  return {
    type: actionTypes.CHATROOM_DELETE_FILES,
    uuid,
    fileIds,
    limit,
    editingMessageUuid,
    deletingFileId,
    outbound,
  }
}

export function deleteMessage(
  uuid,
  deletingMessageUuid,
  limit,
  deletingFileIds,
  outbound
) {
  return {
    type: actionTypes.CHATROOM_DELETE_MESSAGE,
    uuid,
    deletingMessageUuid,
    limit,
    deletingFileIds,
    outbound,
  }
}

export function updateMessages(uuid, limit, messageUuid) {
  return {
    type: actionTypes.CHATROOM_MESSAGES_UPDATING,
    uuid,
    limit,
    messageUuid,
  }
}

export function updateMessage(message) {
  return {
    type: actionTypes.UPDATE_MESSAGE,
    message,
  }
}

export const showModal = modal => ({
  type: actionTypes.SHOW_MODAL,
  modal,
})
