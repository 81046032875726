// @flow

const BrowserStorage = {
  // default settings
  storage: {
    type: 'localStorage', // localStorage | sessionStorage | cookie
    prefix: 'bs_',
    cookie: {
      expiry: 30,
      path: '/',
    },
  },

  // aliases
  set(key, value) {
    this.addToStorage(key, value)
  },
  get(key) {
    return this.getFromStorage(key)
  },
  remove(key) {
    this.removeFromStorage(key)
  },

  addToStorage(key, value) {
    if (!this.isStorageAvailable() || this.storage.type === 'cookie')
      return this.addToCookies(key, value)

    if (typeof value === 'undefined') value = null

    const ws = window[this.storage.type]

    try {
      ws.setItem(this.storage.prefix + key, value)
    } catch (e) {
      return this.addToCookies(key, value)
    }

    return true
  },

  getFromStorage(key) {
    if (this.storage.type === 'cookie') return this.getFromCookies(key)

    const ws = window[this.storage.type]

    return ws.getItem(this.storage.prefix + key)
  },

  removeFromStorage(key) {
    if (this.storage.type === 'cookie') return this.removeFromCookies(key)

    const ws = window[this.storage.type]
    try {
      ws.removeItem(this.storage.prefix + key)
    } catch (e) {
      return this.removeFromCookies(key)
    }

    return true
  },

  setStorageType(type) {
    if (
      type !== 'localStorage' &&
      type !== 'sessionStorage' &&
      type !== 'cookie'
    )
      return

    this.storage.type = type
  },

  isStorageAvailable(type) {
    if (!type) type = this.storage.type

    if (type === 'cookie') return this.isCookieAvailable()

    try {
      const ws = window[type]
      const test = `${this.storage.prefix}storage_test_${Math.round(
        Math.random() * 1e8
      )}`
      ws.setItem(test, test)
      ws.removeItem(test)

      return true
    } catch (e) {
      this.setStorageType('cookie')

      return false
    }
  },

  isCookieAvailable() {
    try {
      return (
        navigator.cookieEnabled ||
        ('cookie' in document &&
          (document.cookie.length > 0 ||
            (document.cookie = 'test').indexOf.call(document.cookie, 'test') >
              -1))
      )
    } catch (e) {
      console.error(`Cookie is not available: ${e.message}`)

      return false
    }
  },

  addToCookies(key, value) {
    if (typeof value === 'undefined') return false

    if (!this.isStorageAvailable()) return false

    try {
      let expiry = ''
      const expiryDate = new Date()
      let cookieDomain = ''

      if (value === null) {
        // reset the cookie
        expiryDate.setTime(expiryDate.getTime() + -1 * 24 * 60 * 60 * 1000)
        expiry = `; expires=${expiryDate.toGMTString()}`
        value = ''
      } else if (this.storage.cookie.expiry !== 0) {
        expiryDate.setTime(
          expiryDate.getTime() +
            this.storage.cookie.expiry * 24 * 60 * 60 * 1000
        )
        expiry = `; expires=${expiryDate.toGMTString()}`
      }

      if (key) {
        const cookiePath = `; path=${this.storage.cookie.path}`

        if (this.storage.cookie.domain) {
          cookieDomain = `; domain=${this.storage.cookie.domain}`
        }

        document.cookie = `${this.storage.prefix + key}=${encodeURIComponent(
          value
        )}${expiry}${cookiePath}${cookieDomain}`
      }
    } catch (e) {
      console.error('Unable to set cookie: ', e.message)

      return false
    }

    return true
  },

  getFromCookies(key) {
    if (!this.isStorageAvailable()) return false

    const cookies = (document.cookie && document.cookie.split(';')) || []
    for (let i = 0; i < cookies.length; i += 1) {
      let thisCookie = cookies[i]
      while (thisCookie.charAt(0) === ' ') {
        thisCookie = thisCookie.substring(1, thisCookie.length)
      }

      if (thisCookie.indexOf(`${this.storage.prefix + key}=`) === 0) {
        return decodeURIComponent(
          thisCookie.substring(
            this.storage.prefix.length + key.length + 1,
            thisCookie.length
          )
        )
      }
    }

    return null
  },

  removeFromCookies(key) {
    this.addToCookies(key, null)
  },
}

export default BrowserStorage
