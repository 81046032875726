import React from 'react'
import classnames from 'classnames'
import { withTranslation } from 'react-i18next'

import { DocumentsEditing } from '../../../components/Documents'

const FILES_ROW_LENGTH = 4

const ControlledDocuments = ({ isWorking, files, disabled, ...props }) => {
  const documentsClass = classnames('unit', {
    'working-overlay': isWorking,
  })

  return (
    <section className={documentsClass} style={{ marginBottom: '20px' }}>
      <h2 className='unit__title'>{props.t('Files')}</h2>
      <DocumentsEditing
        canRemove
        multiple
        files={files}
        disabled={disabled}
        rowLength={FILES_ROW_LENGTH}
        onRemove={props.onRemove}
        onStartUpload={props.onStartUpload}
        onUpdate={props.onUpdate}
      />
    </section>
  )
}

export default withTranslation('Feedback')(ControlledDocuments)
