// @flow

import React from 'react'
import classnames from 'classnames'
import type { Node } from 'react'
import styles from './FieldRow.module.scss'
import InputField from '../InputField'
import { useTranslation } from 'react-i18next'
import Textarea from 'react-textarea-autosize'
import DateField from './DateField'
import moment from 'moment'
import Button from '../Button'

type type = 'select' | 'text' | 'date' | 'textarea'

type Props = {
  active: boolean,
  children?: Node,
  error?: string,
  handleChange?: (event: { ... }) => void,
  handleClear?: (event: { ... }) => void,
  label: string,
  maxLength?: number,
  name?: string,
  placeholder?: string,
  type?: type,
  value?: string | number,
}

const FieldRow = (props: Props): Node => {
  const {
    error,
    name,
    value,
    children,
    label,
    active,
    placeholder,
    type = 'text',
    maxLength,
    handleClear,
    handleChange,
  } = props

  const translations = ['Building', 'Flat', 'Profile']

  const { t } = useTranslation(translations)

  const fieldClass = classnames(styles.input, {
    'input--error': !!error,
  })
  const labelClass = classnames('settings-personal__form-label', styles.label)
  const cellClass = classnames('settings-personal__form-cell', {
    [styles['field-cell']]: !active,
  })
  const fieldSelectClass = classnames({
    [styles.fieldSelectWrap]: type === 'select',
    [styles.fieldSelectWrapActive]: type === 'select' && !!value,
  })
  const textareaClass = classnames(styles.textarea, styles.inputPlaceholder, {
    [styles.textareaError]: error,
  })

  const nativeErrors = ['textarea', 'select']

  return (
    <div className='settings-personal__form-row'>
      <div className='settings-personal__form-cell'>
        <label className={labelClass}>{t(label, { ns: translations })}</label>
      </div>
      <div className={cellClass}>
        {active ? (
          <div className={fieldClass}>
            <div className={fieldSelectClass}>
              {children ||
                {
                  text: (
                    <InputField
                      className={styles.input}
                      error={error}
                      name={name}
                      value={value}
                      placeholder={t(placeholder, {
                        ns: translations,
                      })}
                      maxLength={maxLength}
                      onChange={handleChange}
                    />
                  ),
                  date: (
                    <DateField
                      dateValue={value}
                      name={name}
                      error={error}
                      deadlineDate={value}
                      placeholder={t(placeholder, {
                        ns: translations,
                      })}
                      onChangeDate={handleChange}
                    />
                  ),
                  textarea: (
                    <Textarea
                      name={name}
                      value={value}
                      placeholder={t(placeholder, {
                        ns: translations,
                      })}
                      className={textareaClass}
                      minRows={8}
                      maxRows={9}
                      maxLength={maxLength}
                      onChange={handleChange}
                    />
                  ),
                }[type]}
              {children && type === 'select' && (
                <>
                  {value && (
                    <Button.Delete
                      className={styles.clear}
                      onClick={handleClear}
                    />
                  )}
                </>
              )}
            </div>
            {(children || nativeErrors.includes(type)) && (
              <div className='input__msg'>{error}</div>
            )}
          </div>
        ) : (
          <span
            title={type !== 'date' && value}
            className={styles['not-active']}
          >
            {(type == 'date' && !!value
              ? moment(value).format('DD.MM.YYYY')
              : value) || t('NoDataAvailable', { ns: translations })}
          </span>
        )}
      </div>
    </div>
  )
}

export default FieldRow
