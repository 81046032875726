// @flow

import React, { useState } from 'react'

import Activities from './index'
import { useLocation, useRouteMatch } from 'react-router-dom'
import { Header, SimpleBlock } from '../Block'
import Nbsp from '../NBSP'
import { useTranslation } from 'react-i18next'

const Activity = () => {
  const match = useRouteMatch()
  const location = useLocation()
  const { t } = useTranslation('Activities')
  const [activityCount, setActivityCount] = useState(null)

  return (
    <div className='content__col'>
      <SimpleBlock className='unit unit--default'>
        <Header header>
          {t('ActivityListTitle')}
          <span className='unit__title-num'>
            <Nbsp />
            {activityCount ? activityCount : ''}
          </span>
        </Header>
        <Activities
          match={match}
          location={location}
          setActivityCount={setActivityCount}
        />
      </SimpleBlock>
    </div>
  )
}

export default Activity
