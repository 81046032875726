// @flow

import React from 'react'

import Icon from './Icon'

type Props = {
  handleSelect: (number, Array<string>) => void,
  hasPrivate: boolean,
  template: Object,
}

const TemplateDropdownItem = (props: Props) => {
  const {
    template: { name, id, dependencies, private: privateTemplate },
    hasPrivate,
  } = props

  const style = hasPrivate && !privateTemplate ? { marginLeft: '20px' } : {}

  const handleSelect = () => props.handleSelect(id, dependencies)

  return (
    <li className='dropdown__list-item' onClick={handleSelect}>
      {privateTemplate && (
        <Icon
          id='lock'
          style={{ width: '14px', height: '14px', marginRight: '4px' }}
        />
      )}
      <span className='dropdown__list-item-text' title={name} style={style}>
        {name}
      </span>
    </li>
  )
}

export default TemplateDropdownItem
