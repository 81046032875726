import React from 'react'
import ToolboxCell from '../Users/UserList/Toolbox/ToolboxCell'
import TopPagination from '../Pagination/TopPagination'
import Toolbox from '../Toolbox'
import { useTranslation } from 'react-i18next'
import SelectCustom from '../Select/SelectCustom'
import { isAdminUser } from '../../utils/utils'
import { useUser } from '../../hooks'
import Button from '../Button'

const ToolboxWrapper = props => {
  const { t } = useTranslation('EmailIntegration')
  const { data, loading, actionOptions, addPrivate } = props
  const user = useUser()
  const isAdmin = isAdminUser(user)

  const preventOpen = e => {
    e.preventDefault()
    e.stopPropagation()
  }

  return (
    <Toolbox {...props}>
      <ToolboxCell>
        {isAdmin ? (
          <SelectCustom
            disabled={loading}
            options={actionOptions()}
            onClick={preventOpen}
            onChange={opt => opt.handler()}
          >
            {t('AddAccount')}
          </SelectCustom>
        ) : (
          <Button.Save disabled={loading} onClick={addPrivate}>
            {t('AddAccount')}
          </Button.Save>
        )}
      </ToolboxCell>
      <ToolboxCell isRight>
        <TopPagination
          classes='pager pager--large pager--default-2'
          meta={data.meta}
        />
      </ToolboxCell>
    </Toolbox>
  )
}

export default ToolboxWrapper
