import React, { useState, useEffect } from 'react'
import styles from './ContactFormList.module.scss'

import {
  CONTACT_TEXTS,
  CONTACT_TYPES_SVG,
  MAX_FIELDS_COUNT,
} from '../ContactForm.constants'

import ContactFormField from '../ContactFormField'
import Button from '../../Button'
import Modal from '../../Modal'
import Notes from '../../Notes'
import ConfirmationPopup from '../../modals/ConfirmationPopup'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const templateNewField = {
  contact_type: 1,
  id: null,
  fk: null,
  values: '',
  touched: {},
  permissions: { can_delete: true, can_edit: true },
}

const ContactFormList = ({
  form: {
    dirty,
    initialValues: { fields: initialFields },
    values: { fields },
    submitForm,
    resetForm,
    status: {
      defaultOpenField,
      permissions: { can_create: canCreate },
    },
    isSubmitting,
  },
  replace,
  push,
  remove,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const countryCode = useSelector(state => state.init.uk.country_obj.code)
  const [openField, setOpenField] = useState(-1)
  const { t } = useTranslation('ContactForm')

  useEffect(() => {
    if (defaultOpenField) {
      setOpenField(defaultOpenField)
    }
  }, [defaultOpenField])

  const onOpenPopUp = () => setIsOpenModal(true)
  const onClosePopUp = () => setIsOpenModal(false)

  const onAddField = () => {
    push(templateNewField)
    setOpenField(fields.length)
  }
  const onChangeField = (index, field) =>
    replace(index, { ...fields[index], ...field })

  const onGetField = index => fields[index]

  const onToggleOpenField = (index = -1) =>
    setOpenField(index === openField ? -1 : index)

  const onRemoveField = index => {
    const { id } = onGetField(index)

    if (!id) {
      remove(index)
    } else {
      onChangeField(index, { id, values: null })
      submitForm().finally(() => remove(index))
    }

    onToggleOpenField(-1)

    if (isOpenModal) {
      onClosePopUp()
    }
  }

  const onSave = () => onRemoveField(openField)

  const actionOnField = {
    onRemoveField,
    onChangeField,
    onGetField,
    onToggleOpenField,
    submitForm,
    resetForm,
  }

  const isDisabled = dirty || openField !== -1

  return (
    <div className={styles.wrap}>
      {fields?.map((field, index) => (
        <ContactFormField
          key={field.id || index}
          index={index}
          icon={CONTACT_TYPES_SVG[field.contact_type]}
          label={t(CONTACT_TEXTS[field.contact_type])}
          name={`fields[${index}].values`}
          isOpen={openField === index}
          countryCode={countryCode}
          actionOnField={actionOnField}
          isSubmitting={isSubmitting}
          isOpenModal={isOpenModal}
          onOpenPopUp={onOpenPopUp}
        />
      ))}
      {canCreate && initialFields.length >= MAX_FIELDS_COUNT ? (
        <Notes notes={t('LimitFields')} />
      ) : (
        <>
          {canCreate && (
            <Button.Add
              title={t('Add')}
              disabled={isDisabled}
              onClick={onAddField}
            />
          )}
        </>
      )}
      {isOpenModal && (
        <Modal isOpen={isOpenModal}>
          <ConfirmationPopup
            title={t('DeleteContactTitleModal')}
            text={t('DeleteContactTextModal')}
            onClose={onClosePopUp}
            onOk={onSave}
          />
        </Modal>
      )}
    </div>
  )
}

export default ContactFormList
