// @flow

import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import type { Node } from 'react'
import { isEqual } from 'lodash-es'

import Icon from '../Icon'
import { getTemplates } from '../../core/api/api.template'
import { getTemplates as getPostTemplates } from '../../core/api/api.postTemplate'
import { getEmailTemplates } from '../../core/api/api.emailTemplate'
import NewSelectSimple from '../NewSelectSimple'

import styles from './NewTemplateDropdown.module.scss'

type Props = {
  handleSelect: Object => void,
  isAutomation?: boolean,
  isEmail?: boolean,
  isFeedback?: boolean,
  isManager?: boolean,
  isMass?: boolean,
  isPost?: boolean,
  isPostTemplate?: boolean,
  isProvider?: boolean,
  isRequest?: boolean,
  isRequestThread?: boolean,
  permanentParams?: Object,
  showHeader?: boolean,
}

const NewTemplateDropdown = (props: Props): Node => {
  const {
    isRequest,
    isPost,
    isFeedback,
    isProvider,
    isEmail,
    isMass,
    isManager,
    isPostTemplate,
    isRequestThread,
    isAutomation,
    showHeader,
    permanentParams,
  } = props

  const [templates, setTemplates] = useState([])
  const [loading, setLoading] = useState(true)
  const [isOpen, setOpen] = useState(false)
  const [additionalParams, setAdditionalParams] = useState({})

  const { t } = useTranslation('Templates')

  const params: {
    hidden: boolean,
    ordering: string,
    page_size: number,
    tag?: string,
  } = {
    ordering: 'name',
    hidden: false,
    page_size: 100500,
  }

  if (isMass) {
    params.tag = 'mass_emailing'
  } else if (isRequestThread) {
    params.tag = 'requestthread_email'
  } else if (isPost) {
    params.tag = 'post_comment'
  } else if (isFeedback) {
    params.tag = 'request_feedback'
  } else if (isProvider) {
    params.tag = 'provider_email'
  } else if (isManager) {
    params.tag = 'manager_email'
  } else if (isRequest) {
    params.tag = 'request_comment'
  } else if (isAutomation) {
    params.tag = 'email,requestthread_email'
  } else if (isEmail) {
    params.tag = 'email'
  } else {
    if (!isPostTemplate) {
      params.tag = 'chat'
    }
  }

  let api = getTemplates

  if (isEmail) {
    api = getEmailTemplates
  }

  if (isPostTemplate) {
    api = getPostTemplates
  }

  if (permanentParams && !isEqual(additionalParams, permanentParams)) {
    setAdditionalParams(permanentParams)
  }

  useEffect(() => {
    setLoading(true)

    api({ ...params, ...additionalParams })
      .then(data =>
        setTemplates(
          data.objects.map(item => ({
            ...item,
            value: item.id,
            label: item.name,
          }))
        )
      )
      .finally(() => setLoading(false))
  }, [isMass, additionalParams])

  const getAvatar = option =>
    option.private ? (
      <Icon
        id='lock'
        style={{ width: '14px', height: '14px', marginRight: '4px' }}
      />
    ) : null

  const selectClass = classnames(styles.select, { [styles.open]: isOpen })

  return (
    <>
      {showHeader && (
        <label className={styles.label}>{`${t('Templates')}:`}</label>
      )}
      <NewSelectSimple
        useSearch
        loading={loading}
        options={templates}
        getAvatar={getAvatar}
        getListTitle={opt => opt.label}
        className={selectClass}
        buttonClass={styles.placeholder}
        placeholder={t('ChooseTemplate')}
        searchPlaceholder={t('TemplateSearchPlaceholder')}
        noResultsText={t('TemplatesNotFoundInContext')}
        toggleMenuCb={setOpen}
        onChange={props.handleSelect}
      />
    </>
  )
}

export default NewTemplateDropdown
