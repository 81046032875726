// @flow

import React, { useState, useEffect, useRef } from 'react'
import type { Node } from 'react'

import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { globalModalError } from '../Layout/Layout.actions'

import { isEqual, cloneDeep, map, omit } from 'lodash-es'

import {
  updateAutomation,
  getAutomationActions,
  addAutomationAction,
  updateAutomationAction,
} from '../../core/api/api.automate'

import { parseActions, validateAction } from './Automate.utils'
import { OMIT_FIELDS } from './AutomateTable/Automate.constants'

import { SimpleBlock, Header } from '../Block'
import EmptyList from '../EmptyList'
import Warning from '../Warning'
import Modal from '../Modal'
import ConfirmationPopup from '../modals/ConfirmationPopup'
import ConditionField from './ConditionField'
import AutomateActions from './AutomateActions'

import Button from '../Button'
import Radio from '../Radio'

import { NewButtonTypes, ActionTypes } from '../RequestConstructor/constants'
import CardIcon from '../RequestConstructor/CardIcon'

import styles from './AutomateInfo.module.scss'

type Props = {
  canEdit: boolean,
  condition: Object,
  id: number,
  setLoading: boolean => void,
}

const CONDITION_TYPE_OR = 'or'
const CONDITION_TYPE_AND = 'and'

const DISABLED_ACTIONS = ['text', 'archive']

const AutomateConstructor = (props: Props): Node => {
  const { id, canEdit } = props
  const { t } = useTranslation('Automate')
  const dispatch = useDispatch()

  const [condition, setCondition] = useState(cloneDeep(props.condition))
  const [initialActions, setInitialActions] = useState([])
  const [actions, setActions] = useState([])
  const [saveModal, setSaveModal] = useState(false)
  const [removeModal, setRemoveModal] = useState(null)
  const [working, setWorking] = useState(false)
  const needUpdateActionIds = useRef(false)
  const noCondition = Object.keys(condition).length === 0
  const oneCondition = Object.keys(condition).length !== 1

  const [conditionType, setConditionType] = useState(
    oneCondition || noCondition ? CONDITION_TYPE_AND : Object.keys(condition)[0]
  )

  const newEmptyCondition = {
    field: null,
    operator: null,
    value: '',
  }

  const normalizedCondition = isEqual(condition, newEmptyCondition)
    ? {}
    : condition

  useEffect(() => {
    getAutomationActions(id).then(data => {
      setInitialActions([...parseActions(data)])
      setActions([...parseActions(data)])

      if (data.length > 0 && noCondition) {
        setCondition(newEmptyCondition)
      }
    })
  }, [])

  useEffect(() => {
    setConditionType(
      oneCondition || noCondition
        ? CONDITION_TYPE_AND
        : Object.keys(condition)[0]
    )
  }, [condition])

  const saveAutomation = action_ids => {
    if (needUpdateActionIds.current || actions.length < initialActions.length) {
      updateAutomation(id, { action_ids, condition: normalizedCondition })
        .then(() => props.setLoading(true))
        .catch(err => handleSaveError(err))
    } else {
      updateAutomation(id, { condition: normalizedCondition })
        .then(() => props.setLoading(true))
        .catch(err => handleSaveError(err))
    }
  }

  const handleAddCondition = () => {
    setCondition(
      noCondition
        ? newEmptyCondition
        : {
            [conditionType]: oneCondition
              ? [condition, newEmptyCondition]
              : [...condition[conditionType], newEmptyCondition],
          }
    )
  }

  const handleRemoveCondition = i => {
    if (oneCondition) {
      setCondition(newEmptyCondition)

      return
    }

    let newCondition = cloneDeep(condition)
    newCondition[conditionType].splice(i, 1)
    setCondition(
      newCondition[conditionType].length === 1
        ? newCondition[conditionType][0]
        : newCondition
    )
  }

  const handleChangeConditionType = e => {
    const newConditionType = JSON.parse(e.currentTarget.value)
      ? CONDITION_TYPE_OR
      : CONDITION_TYPE_AND
    setConditionType(newConditionType)
    setCondition({ [newConditionType]: Object.values(condition)[0] })
  }

  const handleChangeCondition = (i, field, value) => {
    const oldCondition = oneCondition ? condition : condition[conditionType][i]
    const newCondition =
      field === 'field'
        ? { ...newEmptyCondition, [field]: value }
        : { ...oldCondition, [field]: value }
    let newConditions = cloneDeep(condition)

    if (oneCondition) {
      newConditions = newCondition
    } else {
      newConditions[conditionType][i] = newCondition
    }

    setCondition(newConditions)
  }

  const handleAddAction = type => {
    const newAction = {
      id: -1 * (actions.length + 1),
      action_type: ActionTypes[type],
      action_data:
        type === 'activity'
          ? {
              priority_id: null,
              deadline_days: null,
              deadline_hours: null,
              deadline_minutes: null,
              notes: null,
            }
          : {},
    }
    setActions([...actions, newAction])
  }

  const handleUpdateAction = (actionId, data) => {
    let i = actions.findIndex(a => a.id === actionId)
    let newActions = [...actions]
    newActions[i].action_data = data
    setActions(newActions)
  }

  const handleRemoveAction = actionId => {
    setRemoveModal(
      <ConfirmationPopup
        confirm={t('Delete')}
        title={t('ConfirmRemoveActionTitle')}
        text={t('ConfirmRemoveActionText')}
        onClose={() => setRemoveModal(null)}
        onOk={() => confirmRemoveAction(actionId)}
      />
    )
  }

  const confirmRemoveAction = actionId => {
    let i = actions.findIndex(a => a.id === actionId)
    let newActions = [...actions]
    newActions.splice(i, 1)
    setActions(newActions)
    setRemoveModal(null)
  }

  const hideSaveModal = () => setSaveModal(false)

  const handleSave = () => {
    if (!isEqual(initialActions, actions)) {
      saveAction()
    } else {
      updateAutomation(id, { condition: normalizedCondition })
        .then(() => props.setLoading(true))
        .catch(err => handleSaveError(err))
    }
  }

  const saveAction = (i = 0, action_ids = []) => {
    if (actions.length > 0) {
      const action = actions[i]

      if (!action.id || action.id < 0) {
        needUpdateActionIds.current = true
        delete action.id
        addAutomationAction(id, action)
          .then(data => {
            saveActionCallback(i, action_ids, data.id)
          })
          .catch(err => handleSaveError(err))
      } else {
        const { action_data } = action
        const oldAction = initialActions.find(a => a.id === action.id)

        if (!isEqual(action, oldAction)) {
          updateAutomationAction(id, action.id, { ...action_data })
            .then(() => {
              saveActionCallback(i, action_ids, action.id)
            })
            .catch(err => handleSaveError(err))
        } else {
          saveActionCallback(i, action_ids, action.id)
        }
      }
    } else {
      saveAutomation(action_ids)
    }
  }

  const saveActionCallback = (i, action_ids, action_id) => {
    action_ids.push(action_id)

    if (i === actions.length - 1) {
      saveAutomation(action_ids)
    } else {
      saveAction(i + 1, action_ids)
    }
  }

  const isSaveDisabled = () => {
    if (!canEdit) {
      return true
    }

    if (working) {
      return true
    }

    if (isCancelDisabled()) {
      return true
    }

    for (let i = 0; i < actions.length; i++) {
      if (!validateAction(actions[i])) {
        return true
      }
    }

    if (isEqual(condition, newEmptyCondition)) {
      return false
    }

    if (oneCondition) {
      if (!validCondition(condition)) {
        return true
      }
    } else {
      for (let i = 0; i < condition[conditionType].length; i++) {
        if (!validCondition(condition[conditionType][i])) {
          return true
        }
      }
    }

    return false
  }

  const validCondition = c => {
    if (c.field === 'activity_deadline' && c.operator) {
      return true
    } else {
      for (let key in c) {
        if (!c[key] && c[key] !== 0) {
          return false
        }
      }
    }

    return true
  }

  const handleSaveError = err => {
    dispatch(
      globalModalError(err.message?.response?.data?.errors || t('ErrorSave'))
    )
    hideSaveModal()
  }

  const handleCancel = () => props.setLoading(true)

  const isCancelDisabled = () => {
    let equalCondition = false

    if (isEqual(condition, props.condition)) {
      equalCondition = true
    }

    if (isEqual(condition, newEmptyCondition)) {
      equalCondition = isEqual(props.condition, {})
    }

    const equalActions = isEqual(
      actions,
      initialActions.map(a => ({
        ...a,
        action_data: omit(a.action_data, OMIT_FIELDS),
      }))
    )

    return equalCondition && equalActions
  }

  const renderConditions = () => {
    if (noCondition) {
      return (
        <EmptyList
          embedded
          canAdd={canEdit}
          btnText={t('AddCondition')}
          icon='automate'
          title={t('EmptyCondition')}
          onClick={handleAddCondition}
        />
      )
    }

    return (
      <>
        <Warning
          noArrow
          text={t('TriggerWarning')}
          boxClassName={styles.warningBox}
          className={styles.warning}
        />
        {canEdit && (
          <div className={styles.actions}>
            <div className={styles.actionsCaption}>{t('AddAction')}:</div>
            <div className={styles.actionCards}>
              {map(NewButtonTypes, type => (
                <CardIcon
                  automate
                  key={type}
                  type={type}
                  disabled={DISABLED_ACTIONS.includes(type)}
                  onClick={() => handleAddAction(type)}
                />
              ))}
            </div>
          </div>
        )}
        <div className={styles.conditionCard}>
          <div className={styles.conditionTitle}>{t('Condition')}</div>
          <div className={styles.conditionBody}>
            {oneCondition
              ? renderCondition(condition, 0)
              : condition[conditionType]
              ? condition[conditionType].map((item, i) =>
                  renderCondition(item, i)
                )
              : null}
          </div>
          {canEdit && (
            <div className={styles.conditionFooter}>
              <Button.Add
                title={t('AddMoreConditions')}
                onClick={handleAddCondition}
              />
            </div>
          )}
        </div>
        {actions.length > 0 && (
          <AutomateActions
            actions={actions}
            canEdit={canEdit}
            setWorking={setWorking}
            onUpdate={handleUpdateAction}
            onRemove={handleRemoveAction}
          />
        )}
      </>
    )
  }

  const renderCondition = (item, i) => (
    <div key={i} className={styles.conditionItem}>
      {i > 0 && renderTypeRadio()}
      <ConditionField
        canEdit={canEdit}
        condition={item}
        showWarning={isEqual(condition, newEmptyCondition)}
        onChange={(field, value) => handleChangeCondition(i, field, value)}
        onRemove={() => handleRemoveCondition(i)}
      />
    </div>
  )

  const renderTypeRadio = () => (
    <div className={styles.conditionRadio}>
      <Radio
        id='exclude'
        name={'condition_type'}
        value={false}
        checked={conditionType === CONDITION_TYPE_AND}
        label={t(CONDITION_TYPE_AND)}
        onChange={handleChangeConditionType}
      />
      <Radio
        id='or'
        name={'condition_type'}
        value={true}
        checked={conditionType === CONDITION_TYPE_OR}
        label={t(CONDITION_TYPE_OR)}
        onChange={handleChangeConditionType}
      />
    </div>
  )

  return (
    <>
      <SimpleBlock>
        <Header header textTitle={t('AutomationProcesses')}>
          <span>{t('AutomationProcesses')}</span>
        </Header>
        {renderConditions()}
        <div className={styles.buttons}>
          <Button.Save
            disabled={isSaveDisabled()}
            onClick={() => setSaveModal(true)}
          >
            {t('Common:Save')}
          </Button.Save>
          <Button.Cancel disabled={isCancelDisabled()} onClick={handleCancel}>
            {t('Common:Cancel')}
          </Button.Cancel>
        </div>
      </SimpleBlock>
      {saveModal && (
        <Modal isOpen onRequestClose={hideSaveModal}>
          <ConfirmationPopup
            isAsync
            confirm={t('Common:Save')}
            title={t('ConfirmSaveTitle')}
            text={t('ConfirmSaveText')}
            onClose={hideSaveModal}
            onOk={handleSave}
          />
        </Modal>
      )}
      {removeModal && (
        <Modal isOpen onRequestClose={() => setRemoveModal(null)}>
          {removeModal}
        </Modal>
      )}
    </>
  )
}

export default AutomateConstructor
