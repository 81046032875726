// @flow

import { call, fork, put, takeLatest } from 'redux-saga/effects'

import api from '../../../core/api'
import { AUDIENCE_LIST_LOADING } from './AudienceList.actionTypes'
import { audienceListLoaded, audienceListError } from './AudienceList.actions'

function * watchLoadAudienceList () {
  yield takeLatest(AUDIENCE_LIST_LOADING, loadAudienceList)
}

function * loadAudienceList () {
  try {
    const data = yield call(api.newsband.getAudience)
    yield put(audienceListLoaded(data))
  } catch (error) {
    yield put(audienceListError(error))
  }
}

export default function * watch () {
  yield fork(watchLoadAudienceList)
}
