// @flow

import React from 'react'
import classnames from 'classnames'
import type { Node } from 'react'

import styles from './ModalRow.module.scss'

type Props = {
  children: Node,
  className?: string,
  onClick?: Function,
  style?: Object,
}

const ModalRow = (props: Props): Node => {
  const { children, style, className } = props

  const rowClass = classnames(styles.row, className)

  const rowProps = Object.assign(
    {},
    style ? { style } : undefined,
    props.onClick ? { onClick: props.onClick } : undefined
  )

  return (
    <div className={rowClass} {...rowProps}>
      {children}
    </div>
  )
}

export default ModalRow
