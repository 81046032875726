// @flow

import React from 'react'
import classnames from 'classnames'

import Icon from '../../../static/icons/down-arrow.svg'

type Props = {
  isRed?: boolean,
  wide?: boolean,
}

const CardArrow = ({ isRed, wide }: Props) => {
  const arrowClassName = classnames('request-constructor__arrow', {
    'request-constructor__arrow--red': isRed,
    'request-constructor__arrow--wide': wide,
  })

  return (
    <div className={arrowClassName}>
      <Icon />
    </div>
  )
}

export default CardArrow
