// @flow

import { lazy } from 'react'

export const apartment = lazy(() =>
  import('../../../../static/icons/apartment-entity.svg')
)
export const automate = lazy(() =>
  import('../../../../static/icons/automate.svg')
)
export const building = lazy(() =>
  import('../../../../static/icons/building-entity.svg')
)
export const chat = lazy(() =>
  import('../../../../static/icons/chat-entity.svg')
)
export const email = lazy(() =>
  import('../../../../static/icons/email-entity.svg')
)
export const importation = lazy(() =>
  import('../../../../static/icons/import.svg')
)
export const marketplace = lazy(() =>
  import('../../../../static/icons/marketplace-entity.svg')
)
export const offer = lazy(() =>
  import('../../../../static/icons/offer-entity.svg')
)
export const order = lazy(() =>
  import('../../../../static/icons/order-entity.svg')
)
export const post = lazy(() =>
  import('../../../../static/icons/post-entity.svg')
)
export const request = lazy(() =>
  import('../../../../static/icons/request-entity.svg')
)
export const review = lazy(() =>
  import('../../../../static/icons/review-entity.svg')
)
export const provider = lazy(() =>
  import('../../../../static/icons/provider.svg')
)
export const pdf = lazy(() => import('../../../../static/icons/file-pdf.svg'))
export const requestProvider = lazy(() =>
  import('../../../../static/icons/request-provider.svg')
)
export const requestOrder = lazy(() =>
  import('../../../../static/icons/request-order.svg')
)
export const user = lazy(() => import('../../../../static/icons/user.svg'))
