// @flow

import { postApi, getApi, putApi } from './api.core'

export const get = params => getApi('api/request-category/', params)
export const getCategory = id => getApi(`api/request-category/${id}/`)
export const getManagerCategories = params =>
  getApi('api/request-category/mapped-manager-category-options/', params)
export const getArchivedManagerCategories = params =>
  getApi(
    'api/archived/request-category/mapped-manager-category-options/',
    params
  )
export const massCreate = names =>
  postApi('api/request-category/batch_create/', { names })
export const massUpdate = categories =>
  putApi('api/request-category/batch_update/', { categories })
