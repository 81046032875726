// @flow

import React, { Component } from 'react'
import type { Node } from 'react'
import { compose } from 'redux'
import { DragSource, DropTarget } from 'react-dnd'

import Input from '../../Input'

type Props = {
  connectDragSource: Node => Node,
  connectDropTarget: Node => Node,
  data: Object,
  disabled?: boolean,
  duplicate?: boolean,
  editing: boolean, // eslint-disable-line
  index: number,
  isDragging: boolean,
  onEdit: (?number) => void,
  onMove: (number, number) => void, // eslint-disable-line
  onRemove: number => void,
  onSave: (Object, number) => void,
}

const CategoryType = 'Category'
const MaxLength = 200
export { CategoryType, MaxLength }

const dragSource = {
  beginDrag(props: Props) {
    return {
      id: props.data.id,
    }
  },
  canDrag(props: Props) {
    return !props.editing
  },
}

const dropTarget = {
  hover(props, monitor) {
    const { id } = monitor.getItem()

    if (id !== props.id) {
      props.onMove(id, props.index)
    }
  },
  drop(props, monitor) {
    const { id } = monitor.getItem()

    if (id !== props.id) {
      props.onDrop(id, props.index)
    }
  },
}

class Category extends Component {
  props: Props

  toggleEdit = editing => {
    const { data, onEdit } = this.props
    onEdit(editing ? data.id : null)
  }

  save = name => {
    const { onSave, data, index } = this.props

    if (data.name !== name) {
      onSave({ ...data, name }, index)
    }
  }

  render() {
    const {
      data: { id, name },
      disabled,
      connectDragSource,
      connectDropTarget,
      isDragging,
      duplicate,
      onRemove,
    } = this.props

    const style = { opacity: isDragging ? 0 : 1 }

    return connectDragSource(
      connectDropTarget(
        <div>
          <Input.Edit
            draggable
            disabled={disabled}
            error={duplicate}
            maxLength={MaxLength}
            style={style}
            value={name}
            onToggleEdit={this.toggleEdit}
            onRemove={() => onRemove(id)}
            onSave={this.save}
          />
        </div>
      )
    )
  }
}

export default compose(
  DragSource(CategoryType, dragSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  })),
  DropTarget(CategoryType, dropTarget, connect => ({
    connectDropTarget: connect.dropTarget(),
  }))
)(Category)
