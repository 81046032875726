// @flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import Loader from '../Loader'
import Button from '../Button'
import Toolbox from '../Toolbox'
import { useLoad } from '../../hooks'
import { getActivityPriorities } from '../../core/api/api.pipeline'
import ActivityPriorityModal from './ActivityPriorityModal'
import ActivityPriorityTable from './ActivityPriorityTable'

const ActivityPriority = (): Node => {
  const { t } = useTranslation('Activities')

  const [isModalOpen, setModalOpen] = useState(false)

  const [data, isLoading, isError, load] = useLoad(getActivityPriorities)

  const handleClose = () => setModalOpen(false)
  const handleCreate = () => setModalOpen(true)

  // TODO Need error component
  if (isError) {
    return null
  }

  return (
    <>
      {data ? (
        <>
          <Toolbox>
            <div className='toolbox__cell'>
              <Button.Save onClick={handleCreate}>
                {t('AddPriorityButton')}
              </Button.Save>
            </div>
          </Toolbox>
          <ActivityPriorityTable isLoading={isLoading} onEdit={handleCreate} />
          {isModalOpen && (
            <ActivityPriorityModal
              load={load}
              data={data}
              onClose={handleClose}
            />
          )}
        </>
      ) : (
        <Loader type='big' text={false} />
      )}
    </>
  )
}

export default ActivityPriority
