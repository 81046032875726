// @flow

import React from 'react'
import type { Node } from 'react'

import ConnectedCompaniesTableHead from './ConnectedCompaniesTableHead'
import ConnectedCompaniesTableBody from './ConnectedCompaniesTableBody'
import NewTable from '../NewTable'

type Props = {
  companies: Array<Object>,
  loading: boolean,
  onDelete: string => void,
  outbound: boolean,
  permissions: Object,
}

const ConnectedCompaniesTable = (props: Props): Node => {
  const { companies, outbound, permissions, onDelete } = props

  return (
    <NewTable>
      <ConnectedCompaniesTableHead outbound={outbound} />
      <ConnectedCompaniesTableBody
        companies={companies}
        outbound={outbound}
        permissions={permissions}
        selectedItems={props.selectedItems}
        onDelete={onDelete}
        onSelect={props.onSelect}
      />
    </NewTable>
  )
}

export default ConnectedCompaniesTable
