// @flow

import React, { useLayoutEffect } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { useHistory } from 'react-router-dom'
import BrowserStorage from '../../utils/browserStorage'
import { getUserId } from '../../utils/utils'
import ErrorPage from '../../components/ErrorPage'

type Props = {
  locationReferer: string,
  t: string => string,
  userId: number,
}

const NotFound = (props: Props) => {
  const { userId, locationReferer } = props
  const path = `${userId}_${locationReferer}`
  let history = useHistory()

  useLayoutEffect(() => {
    if (BrowserStorage.get(path)) {
      BrowserStorage.remove(path)
      history.replace(locationReferer)
    }
  }, [])

  return <ErrorPage t={props.t} status={404} />
}

const mapStateToProps = state => ({
  locationReferer: state.notFoundPage.pathname,
  userId: getUserId(state.login.user),
})

export default compose(
  withTranslation('Layout'),
  connect(mapStateToProps)
)(NotFound)
