// @flow

import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import { useRouteMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Loader from '../Loader'
import { redirectTo404 } from '../../utils/routing'

import { getFormattedDate } from '../../utils/utils'
import { SimpleBlock, Header, CellsBar, Cell, Row } from '../Block'
import Icon from '../Icon'
import Button from '../Button'
import TableCellUsers from '../TableCellUsers'
import Modal from '../Modal'
import ConfirmationPopup from '../modals/ConfirmationPopup'
import TitleModal from './TitleModal'
import AddKeyModal from './AddKeyModal'

import SelectCustom from '../Select/SelectCustom'

import {
  deleteOutboundConfigProfile,
  getOutboundConfigProfile,
  updateOutboundConfigProfile,
} from '../../core/api/api.idwell2idwell'

import styles from './ConnectedCompanies.module.scss'

const OutboundCompanyProfile = () => {
  const { t } = useTranslation('IdwellToIdwell')
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(true)
  const [company, setCompany] = useState(null)
  const [editTitle, setEditTitle] = useState(false)
  const [modal, setModal] = useState(null)

  const match = useRouteMatch()
  const uuid = match.params.uuid

  useEffect(() => {
    if (loading && uuid) {
      getOutboundConfigProfile(uuid)
        .then(data => {
          setCompany(data)
        })
        .catch(error => redirectTo404(error))
        .finally(() => setLoading(false))
    }
  }, [loading])

  const handleUpdateName = name => {
    updateOutboundConfigProfile(uuid, { name }).then(() => {
      setLoading(true)
      setEditTitle(false)
    })
  }

  const handleAddKeyModal = () => {
    setModal(<AddKeyModal isOpen uuid={uuid} onClose={handleCloseModal} />)
  }

  const handleConfirmDeleteCompany = () => {
    setModal(
      <Modal isOpen onRequestClose={handleCloseModal}>
        <ConfirmationPopup
          title={t('DeleteTitle')}
          text={t('ConfirmDeleteOne')}
          confirm={t('Delete')}
          cancel={t('Common:Cancel')}
          onClose={handleCloseModal}
          onOk={handleDeleteCompany}
        />
      </Modal>
    )
  }

  const handleDeleteCompany = () => {
    deleteOutboundConfigProfile(uuid).then(() => {
      dispatch(push('/settings/idwell-to-idwell'))
    })
  }

  const handleCloseModal = reset => {
    setModal(null)

    if (reset) {
      setLoading(true)
    }
  }

  const moreOptions = [{ value: 'deleteCompany', label: t('DeleteCompany') }]

  const handleMoreAction = ({ value }) => {
    switch (value) {
      case 'deleteCompany':
        handleConfirmDeleteCompany()
        break
    }
  }

  if (loading) {
    return <Loader text={false} type='big' />
  }

  return (
    <div className='content__col'>
      <SimpleBlock>
        <Header header textTitle={company.name} className={styles.header}>
          <div icon='true' className={styles.icon}>
            <Icon id='connect' />
          </div>
          <span className={styles.name}>{company.name}</span>
          {!editTitle && <Button.Edit onClick={() => setEditTitle(true)} />}
        </Header>
        <CellsBar>
          <Cell title={t('UpdateColumn')}>
            {getFormattedDate(company.updated)}
          </Cell>
          <Cell
            title={t('Creator')}
            className={styles.headerUserCell}
            valueClassName={styles.headerUser}
          >
            <TableCellUsers items={[company.created_by_obj]} />
          </Cell>
          <Cell
            title={t('ConnectionStatus')}
            className={
              company.status === 'valid' ? styles.valid : styles.invalid
            }
          >
            {t(`Status.${company.status}`)}
          </Cell>
        </CellsBar>
        <Row>
          <div className={styles.buttonWrapper}>
            <Button.Save onClick={handleAddKeyModal}>{t('AddKey')}</Button.Save>
            <SelectCustom
              alignLeft
              buttonClass='button button--large button--dropdown button--showmore '
              options={moreOptions}
              onChange={handleMoreAction}
            >
              {t('ShowMore')}
            </SelectCustom>
          </div>
        </Row>
      </SimpleBlock>
      {editTitle && (
        <TitleModal
          updateTitle={handleUpdateName}
          name={company.name}
          onClose={() => setEditTitle(false)}
        />
      )}
      {modal}
    </div>
  )
}

export default OutboundCompanyProfile
