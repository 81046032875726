// @flow

import React, { useState, useEffect } from 'react'
import type { Node } from 'react'
import { ReactHeight } from 'react-height'
import { isImageFile } from '../../../../utils/file'

import Article from './Article'
import { WIDTH_COLUMN } from '../NewsPage.constants'

type Props = {
  element: Object,
  onSetHeight: (number) => void
}

const HeightGrabber = ({ element, ...props }: Props): Node | null => {
  const [height, setHeight] = useState(0)
  useEffect(() => {
    if (height) {
      const filesHeight = Math.ceil(element.file_objs.reduce((acc, file) => {
        if (isImageFile(file)) {
          const { height: fileHeight, width: fileWidth } = file
          const height = fileHeight || WIDTH_COLUMN
          const width = fileWidth || WIDTH_COLUMN
          const realHeight = fileWidth > WIDTH_COLUMN
            ? height / (width / WIDTH_COLUMN)
            : height

          return acc + realHeight
        }

        return acc
      }, 0))
      props.onSetHeight(height + filesHeight)
    }
  }, [height])

  return (
    <div style={{ position: 'fixed', opacity: 0 }}>
      <ReactHeight onHeightReady={setHeight}>
        <Article data={element} />
      </ReactHeight>
    </div>
  )
}

export default HeightGrabber
