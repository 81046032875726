// @flow

import React, { useEffect } from 'react'
import seapig, { REQUIRED } from 'seapig'
import { useParams, useRouteMatch } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { replace } from 'connected-react-router'
import type { Node } from 'react'

import {
  getUrlTabList,
  getInitialTab,
  checkTabInUrl,
  checkRelatedTabInUrl,
  getUrl,
  getNewPathList,
  getFlatTabNames,
} from './NewTabRouter.utils'
import Nav from './Nav'
import Content from './Content'
import Navs from './Navs'

import styles from './NewTabRouter.module.scss'

type Props = {
  children: Node,
  selectedTab?: string,
  tabs: Array<Object>,
  withBorder?: boolean,
}

const NewTabRouter = (props: Props): Node => {
  const { selectedTab: initialSelectedTab, children, tabs, withBorder } = props

  const { tab: tabPath, folderId } = useParams()

  const dispatch = useDispatch()
  const match = useRouteMatch()

  const urlTabList = getUrlTabList(tabPath)
  const initialTab = getInitialTab(urlTabList, tabs, initialSelectedTab)

  const { [initialTab + 'Children']: component } = seapig(children, {
    [initialTab]: REQUIRED,
  })

  const url = getUrl(match.url, tabPath, folderId)

  useEffect(() => {
    if (!tabPath) {
      dispatch(replace(`${url}${initialTab}/`))
    } else if (!checkTabInUrl(initialTab, urlTabList)) {
      const relatedTab = checkRelatedTabInUrl(
        tabPath,
        tabs,
        urlTabList,
        initialTab
      )

      const pathList = getNewPathList(urlTabList, relatedTab, initialTab)

      dispatch(replace(`${url}${pathList.join('/')}/`))
    }
  }, [tabPath])

  const withKeysChildren = component
    ? component.map(child =>
        React.cloneElement(child, {
          key: initialTab,
        })
      )
    : null

  return (
    <div className={styles.tabs}>
      <Navs>
        {tabs.map(tab => (
          <Nav
            selectedTab={initialTab}
            key={tab.value}
            tab={tab}
            url={url}
            initialTab={initialTab}
            urlTabList={urlTabList}
            read={tab.read}
            tabNames={getFlatTabNames(tabs)}
          />
        ))}
      </Navs>
      {withBorder ? (
        <Content>{withKeysChildren}</Content>
      ) : (
        <>{withKeysChildren}</>
      )}
    </div>
  )
}

export default NewTabRouter
