// @flow

import * as actions from './SettingsCorporate.actionTypes'
import { INIT_LOADED } from '../init/init.actionTypes'

const initialState = {
  working: false,
  theme: {},
  ukConfig: {},
  saveButtonActiveTheme: false,
  saveButtonActiveConfig: false,
  themeWorking: false,
  registerWorking: false,
  error: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case INIT_LOADED:
      return {
        ...state,
        ukConfig: action.data.uk.config,
      }
    case actions.SETTINGS_CORPORATE_INIT:
      return {
        ...initialState,
        theme: action.uk.theme,
        ukConfig: action.uk.config,
      }
    case actions.SETTINGS_CORPORATE_SET_THEME:
      return {
        ...state,
        saveButtonActiveTheme: true,
        theme: {
          ...state.theme,
          ...action.theme,
        },
      }
    case actions.SETTINGS_CORPORATE_CANCEL_THEME: {
      return {
        ...state,
        saveButtonActiveTheme: false,
        theme: {
          ...state.theme,
          ...action.theme,
        },
      }
    }
    case actions.SETTINGS_CORPORATE_SAVE_THEME:
      return {
        ...state,
        themeWorking: true,
      }
    case actions.SETTINGS_CORPORATE_SAVED_THEME:
      return {
        ...state,
        saveButtonActiveTheme: false,
        themeWorking: false,
      }
    case actions.SETTINGS_CORPORATE_SAVE_CONFIG:
      return {
        ...state,
        configWorking: true,
      }
    case actions.SETTINGS_CORPORATE_SAVED_CONFIG:
      return {
        ...state,
        saveButtonActiveConfig: false,
        configWorking: false,
        ukConfig: action.config,
      }
    case actions.SETTINGS_CORPORATE_UPDATE_CONFIG: {
      const ukConfig = {
        ...state.ukConfig,
        ...action.params,
      }

      return {
        ...state,
        saveButtonActiveConfig: !state.saveButtonActiveConfig,
        ukConfig,
      }
    }
    case actions.SETTINGS_CORPORATE_CANCEL_CONFIG: {
      const ukConfig = {
        ...state.ukConfig,
        ...action.params,
      }

      return {
        ...state,
        saveButtonActiveConfig: false,
        ukConfig,
      }
    }
    case actions.SETTINGS_CORPORATE_SAVE:
      return {
        ...state,
        working: true,
      }
    case actions.SETTINGS_CORPORATE_UPDATE:
      return {
        ...state,
        working: false,
      }
    case actions.SETTINGS_CORPORATE_ERROR:
      return {
        ...state,
        error: action.error.message,
      }
    default:
      return state
  }
}
