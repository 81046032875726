// @flow

import React, { Fragment } from 'react'
import type { Node } from 'react'

// Because seapig find schema props in props children array element
export const formatChildren = (children: Node): Node =>
  React.Children.toArray(children).map(el => {
    if (typeof el !== 'object') {
      return React.createElement(Fragment, { key: el }, el)
    }

    return el
  })
