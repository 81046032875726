// @flow

import React from 'react'
import { Link } from 'react-router-dom'

type Props = {
  infoText: string,
  linkText: string,
}

const RegistrationLink = (props: Props) => {
  const { infoText, linkText } = props

  return (
    <div className='register-offer'>
      <span>{infoText}</span>
      <Link to='/free-registration'>{linkText}</Link>
    </div>
  )
}

export default RegistrationLink
