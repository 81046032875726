//@flow

import { FORMIK_EMPTY_VALUE } from '../../../constants'

export const SALUTATION = 'salutation'
export const FIRST_NAME = 'first_name'
export const SECOND_NAME = 'last_name'
export const FULL_NAME = 'full_name'
export const MIDDLE_NAME = 'middle_name'
export const NAME_INFO = 'name_info'
export const STATUS = 'status'
export const GDPR_INFO = 'gdpr_info'
export const PREFERRED_COMMUNICATION = 'preferred_communication'
export const TITLE = 'title'

export const MAX_NAME_LENGTH = 50
export const MAX_FULL_NAME_LENGTH = 100

export const KEYS = [SALUTATION, FIRST_NAME, SECOND_NAME, FULL_NAME]

export const DEFAULT_VALUES: Object = {
  [SALUTATION]: FORMIK_EMPTY_VALUE,
  [FIRST_NAME]: FORMIK_EMPTY_VALUE,
  [SECOND_NAME]: FORMIK_EMPTY_VALUE,
  [FULL_NAME]: FORMIK_EMPTY_VALUE,
}
