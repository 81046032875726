// @flow

import React from 'react'
import { createFragmentContainer, graphql } from 'react-relay'
import { Link } from 'react-router-dom'

import styles from '../OrderProfileProvider/OrderProfileProvider.module.scss'

const OrderProfileCustomer = props => {
  const {
    data: { clientInfo },
  } = props

  const { name, client_user_id } = JSON.parse(clientInfo)

  return (
    <Link className={styles.providerValue} to={`/profile/${client_user_id}/`}>
      <i className='table-item__user-pic'>
        <img src='https://files.idwell.com/sys/thumbnail/170_default_avatar.png' />
      </i>
      <span style={{ marginLeft: 10 }}>{name}</span>
    </Link>
  )
}

export default createFragmentContainer(OrderProfileCustomer, {
  data: graphql`
    fragment OrderProfileCustomer_data on CustomerOrderNode {
      clientInfo
    }
  `,
})
