// @flow

export const RESIDENT_LIST_INITIATING = 'RESIDENT_LIST_INITIATING'
export const RESIDENT_LIST_INITIATED = 'RESIDENT_LIST_INITIATED'
export const RESIDENT_LIST_LOAD = 'RESIDENT_LIST_LOAD'
export const RESIDENT_LIST_LOADED = 'RESIDENT_LIST_LOADED'
export const RESIDENT_LIST_SUBSCRIBE = 'RESIDENT_LIST_SUBSCRIBE'
export const RESIDENT_LIST_SUBSCRIBED = 'RESIDENT_LIST_SUBSCRIBED'
export const RESIDENT_LIST_UNSUBSCRIBE = 'RESIDENT_LIST_UNSUBSCRIBE'
export const RESIDENT_LIST_UNSUBSCRIBED = 'RESIDENT_LIST_UNSUBSCRIBED'
export const RESIDENT_LIST_ERROR = 'RESIDENT_LIST_ERROR'
export const RESIDENT_LIST_ALREADY_FRIEND = 'RESIDENT_LIST_ALREADY_FRIEND'
