// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'
import type { Dispatch } from 'redux'

import Geosuggest from '../../Geosuggest/Geosuggest'
import Button from '../../Button'
import * as actions from '../../CompanyView/CompanyView.actionTypes'
import {
  selectCreatingAddress,
  selectWorking,
} from '../../CompanyView/CompanyView.selectors'
import {
  selectUkCity,
  selectUkCountry,
  selectUkId,
} from '../../init/init.selectors'
import Map from '../../Map/Map'
import Checkbox from '../../Checkbox/Checkbox'

const RESET = true

type Action =
  | 'COMPANY_VIEW_ADDRESS_CREATE_IN_PROGRESS'
  | 'COMPANY_VIEW_ADDRESS_CREATE'

type Props = {
  closeModal: () => void,
  creatingAddress: Object,
  dispatch: Dispatch<{
    data?: Object,
    reset?: boolean,
    type: Action,
    uk?: Object,
  }>,
  t: (string, ?Object) => string,
  uk: { city: Object, country: Object, id: number },
}
type State = { mapReady: boolean }

class UkAddressPopup extends Component<Props, State> {
  state = {
    mapReady: false,
    setPrimary: false,
  }

  componentWillUnmount() {
    this.props.dispatch({
      type: actions.COMPANY_VIEW_ADDRESS_CREATE_IN_PROGRESS,
      data: {},
      reset: RESET,
    })
  }

  onChangeGeosuggest = suggest => {
    let params = {
      value: suggest.label,
    }

    if (suggest.lattitude && suggest.longitude) {
      const lattitude = parseFloat(suggest.lattitude)
      const longitude = parseFloat(suggest.longitude)

      params = {
        ...params,
        widget_lattitude_str: `${lattitude}`,
        widget_longitude_str: `${longitude}`,
        widget_lattitude: lattitude,
        widget_longitude: longitude,
        lattitude,
        longitude,
      }
    }

    this.update(params)
  }

  updateGeosuggest = value => {
    this.update({ value })
  }

  save = () => {
    const { uk } = this.props

    this.props.dispatch({
      type: actions.COMPANY_VIEW_ADDRESS_CREATE,
      uk: uk.id,
      setPrimary: this.state.setPrimary,
    })
  }

  update = value => {
    const { creatingAddress } = this.props

    let params = { ...value }

    if (
      value.lattitude &&
      value.longitude &&
      !creatingAddress.widget_lattitude_str
    ) {
      const lattitude = parseFloat(value.lattitude)
      const longitude = parseFloat(value.longitude)
      params = {
        ...params,
        widget_lattitude_str: `${lattitude}`,
        widget_longitude_str: `${longitude}`,
        widget_lattitude: lattitude,
        widget_longitude: longitude,
        lattitude,
        longitude,
      }
    }

    this.props.dispatch({
      type: actions.COMPANY_VIEW_ADDRESS_CREATE_IN_PROGRESS,
      data: params,
    })
  }

  onMapReady = () => {
    this.setState({ mapReady: true })
  }

  handleSetPrimary = e => {
    this.setState({ setPrimary: e.currentTarget.checked })
  }

  render() {
    const { uk, creatingAddress, working } = this.props

    const {
      value = '',
      lattitude,
      longitude,
      widget_zoom: widgetZoom,
      widget_lattitude_str: widgetLatitudeStr,
      widget_longitude_str: widgetLongitudeStr,
    } = creatingAddress

    const editMapProps = {
      onMapReady: this.onMapReady,
      collapsed: false,
      editMode: true,
      onChangeAddress: this.update,
      zoom: widgetZoom,
    }

    const mapCenter = {
      lat: parseFloat(widgetLatitudeStr),
      lng: parseFloat(widgetLongitudeStr),
    }

    if (mapCenter.lat && mapCenter.lng) {
      editMapProps.center = mapCenter
    } else {
      editMapProps.center =
        uk.city && uk.city.lattitude && uk.city.longitude
          ? {
              lat: uk.city.lattitude,
              lng: uk.city.longitude,
            }
          : null
    }

    if (lattitude && longitude) {
      editMapProps.buildingMarker = {
        name: 'buildingMarker',
        position: {
          lat: parseFloat(lattitude),
          lng: parseFloat(longitude),
        },
      }
    }

    const activeBtn = !value || working

    return (
      <div
        className='modal__content modal__content--width'
        style={{ width: 720 }}
      >
        <button
          className='modal__close'
          type='button'
          onClick={this.props.closeModal}
        />
        <div className='modal__title'>{this.props.t('AddOfficeAddress')}</div>
        <section className='cprofile-view-houses unit unit--default'>
          <div className='u-a12__row--02'>
            <div className='input input--medium input--block input--default'>
              <Checkbox
                checked={this.state.setPrimary}
                text={this.props.t('SetAddressAsPrimary')}
                onChange={this.handleSetPrimary}
              />
            </div>
          </div>
          <div className='u-a12__row--02'>
            <div className='input input--medium input--block input--default'>
              <Geosuggest
                mapReady={this.state.mapReady}
                initialValue={value}
                onInputChange={this.updateGeosuggest}
                onSelect={this.onChangeGeosuggest}
              />
            </div>
          </div>
          <div className='cprofile-edit-address__submit'>
            <Button.Save disabled={activeBtn} onClick={this.save}>
              {this.props.t('Save')}
            </Button.Save>
            <Button.Cancel onClick={this.props.closeModal}>
              {this.props.t('Cancel')}
            </Button.Cancel>
          </div>
          <div className='u-a19'>
            <Map {...editMapProps} />
          </div>
        </section>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  creatingAddress: selectCreatingAddress(state),
  uk: {
    city: selectUkCity(state),
    country: selectUkCountry(state),
    id: selectUkId(state),
  },
  working: selectWorking(state),
})

export default connect(mapStateToProps)(UkAddressPopup)
