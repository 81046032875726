// @flow

import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import message from 'antd/es/message'
import axios from 'axios'

import cookies from '../../utils/cookies'
import AvatarEditable from '../../components/AvatarEditable'

import 'antd/es/message/style/index.css'

type Props = {
  image: ?string,
  onSetImgId: number => void,
}

const UploadAvatar = (props: Props) => {
  const { image } = props
  const { t } = useTranslation('Providers')
  const [imageData, setImageData] = useState({
    imageUrl: image || null,
    loading: null,
  })

  const customRequest = useCallback(async function ({ dataUrl, filename }) {
    const parseResponse = response => {
      const status = response.status

      if (status === 204) {
        return { data: {} }
      } else if (status >= 200 && status < 300) {
        return response.data
      }

      return null
    }
    setImageData({
      ...imageData,
      loading: true,
    })
    let file = null

    await fetch(dataUrl)
      .then(res => res.blob())
      .then(blob => (file = blob))

    const data = new FormData()
    data.append('file', file, filename)

    try {
      const marketplaceUrl = await cookies.getItem('marketplace_url')
      const marketplaceToken = cookies.getItem('marketplace_token')
      const mpSession = cookies.getItem('mpSessionId')

      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Token ${marketplaceToken}`,
        MPSession: mpSession,
      }
      const imgResp = await axios({
        url: `${marketplaceUrl}api/provider-image-upload/`,
        headers,
        method: 'POST',
        data,
      })
      const parsedImgResp = await parseResponse(imgResp)

      if (parsedImgResp) {
        const { id, content } = parsedImgResp
        setImageData({
          loading: false,
          imageUrl: content,
        })
        props.onSetImgId(id)
      } else {
        // TODO translate me
        throw new Error('Something went wrong')
      }
    } catch (error) {
      let errorMessage

      if (error.response && error.response.data && error.response.data.detail) {
        errorMessage = error.response.data.detail
      } else {
        errorMessage = 'Something went wrong'
      }

      setImageData({
        ...imageData,
        loading: false,
      })
      message.error(errorMessage)
    }
  })

  return (
    <div
      className='mprofile-edit-user__cell'
      style={{
        minWidth: '85px',
        minHeight: '85px',
        boxSizing: 'border-box',
      }}
    >
      <AvatarEditable
        alt='avatar'
        image={{ origin: imageData.imageUrl }}
        title={t('uploadAvatar')}
        onFinishEdit={customRequest}
        onStartEdit={() => {}}
      />
    </div>
  )
}

export default UploadAvatar
