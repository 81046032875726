// @flow

import { put, call, fork, takeEvery } from 'redux-saga/effects'

import api from '../../../core/api'
import { serverError } from '../../Layout/Layout.actions'
import * as actions from './AddFlatPopup.actionTypes'

function* add({ flat, params }) {
  try {
    const flatData = yield call(api.flat.addFlat, flat)
    yield put({ type: actions.FLAT_POPUP_ADDED_FLAT, flat: flatData, params })
  } catch (error) {
    const {
      message: { response },
    } = error

    if (response.status === 400) {
      yield put({ type: actions.FLAT_POPUP_ERROR, error: response.data })
    } else {
      yield put(serverError(error))
    }
  }
}

function* watchAdd() {
  yield takeEvery(actions.FLAT_POPUP_ADD_FLAT, add)
}

export default function* watch() {
  yield fork(watchAdd)
}
