// @flow

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { compose } from 'redux'

import WelcomePost from '../../components/Post/WelcomePost'
import BrowserStorage from '../../utils/browserStorage'
import { NEWS_LIST_WELCOME_NEWS_CHANGED } from '../../containers/NewsList/NewsList.actions'
import { getUser, isWizardEnabled } from '../../utils/commonSelectors'
import NewWizard from '../../components/NewWizard'
import { getUserName } from '../../utils/utils'

const SMALL_THUMBNAIL_SIZE = 182

type Props = {
  canCreateRequest?: boolean,
  dispatch: Object => void,
  hiddenPosts: Object,
  onBuildingCreate: () => void,
  t: string => string,
  uk: Object,
  user: Object,
}

type State = {
  isWizardOpen: boolean,
}

class WelcomeNews extends Component<Props, State> {
  state = {
    isWizardOpen: false,
  }

  create = () => {
    if (this.props.isWizardEnabled) {
      this.setState({ isWizardOpen: true })
    } else {
      this.props.dispatch(push('/request/create'))
    }
  }

  handleCloseWizard = () => {
    this.setState({ isWizardOpen: false })
  }

  getPosts = () => {
    const { hiddenPosts, uk, user, t, onBuildingCreate, canCreateRequest } =
      this.props

    // Strange check for user,
    if (!user.group) {
      return []
    }

    const ukName = uk.name
    const fullName = getUserName(user)

    const firstPostButtons = (
      <button
        className='button button--large button--danger-3'
        type='button'
        onClick={() => this.hidePost(1)}
      >
        {t('HidePost')}
      </button>
    )

    const welcomePost = {
      id: 1,
      created: user.created,
      type_obj: {
        title: 'welcome_post',
        noComment: true,
        noLike: true,
      },
      title: t('TitleMessage', { ukName }),
      text: `${t('WelcomeMessage', { fullName, ukName })} ${t('TextMessage')}`,
      uk,
      buttons: firstPostButtons,
    }
    const welcomePostDweller = {
      id: 1,
      created: user.created,
      type_obj: {
        title: 'welcome_post',
        noComment: true,
        noLike: true,
      },
      title: t('TitleMessageUser', { ukName }),
      text: `${t('WelcomeMessageUser', { fullName, ukName })} ${t(
        'TextMessageUser'
      )}`,
      uk,
      buttons: firstPostButtons,
    }
    const dweller = [
      welcomePostDweller,
      this.createPost(
        2,
        t('CreateFirstRequestTitle'),
        t('CreateFirstRequestText', { ukName }),
        <>
          {canCreateRequest && (
            <button
              className='button button--large button--success'
              type='button'
              onClick={this.create}
            >
              {t('CreateRequest')}
            </button>
          )}
          <button
            className='button button--large button--danger-3'
            type='button'
            onClick={() => this.hidePost(2)}
          >
            {t('HidePost')}
          </button>
        </>
      ),
    ]

    const manager = [welcomePost]

    const admin = [
      welcomePost,
      this.createPost(
        3,
        t('CreateBuilding', { ukName }),
        t('CreateBuildingInfo'),
        <>
          <button
            className='button button--large button--success'
            type='button'
            onClick={onBuildingCreate}
          >
            {t('AddBuilding')}
          </button>
          <button
            type='button'
            className='button button--large button--danger-3'
            onClick={() => this.hidePost(3)}
          >
            {t('HidePost')}
          </button>
        </>
      ),
      this.createPost(
        4,
        t('CreateFlatTitle'),
        t('CreateFlatText'),
        <button
          type='button'
          className='button button--large button--danger-3'
          onClick={() => this.hidePost(4)}
        >
          {t('HidePost')}
        </button>
      ),
      this.createPost(
        5,
        t('InvitePeopleTitle'),
        t('InvitePeopleText'),

        <button
          type='button'
          className='button button--large button--danger-3'
          onClick={() => this.hidePost(5)}
        >
          {t('HidePost')}
        </button>
      ),
    ]

    const groupPosts = { dweller, manager, admin }

    return groupPosts[user.group].filter(p => !hiddenPosts[p.id])
  }

  createPost = (id, title, text, buttons) => {
    const { uk, user } = this.props

    return {
      id,
      created: user.created,
      title,
      text,
      uk,
      buttons,
    }
  }

  hidePost = id => {
    let hiddenPosts = BrowserStorage.get('hiddenPosts')
    hiddenPosts = hiddenPosts ? JSON.parse(hiddenPosts) : {}
    hiddenPosts[id] = true
    BrowserStorage.set('hiddenPosts', JSON.stringify(hiddenPosts))
    this.props.dispatch({
      type: NEWS_LIST_WELCOME_NEWS_CHANGED,
      hiddenPosts,
    })
  }

  render() {
    const news = this.getPosts()

    const { isWizardOpen } = this.state

    return (
      <div className='welcome-news'>
        {news.map(post => (
          <WelcomePost data={post} key={`welcome-post-${post.id}`} />
        ))}
        {isWizardOpen && <NewWizard onClose={this.handleCloseWizard} />}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  hiddenPosts: state.newsList.hiddenPosts,
  user: getUser(state),
  uk: {
    name: state.init.uk.name,
    logo: state.init.uk.logo_obj.thumbnails[SMALL_THUMBNAIL_SIZE],
  },
  isWizardEnabled: isWizardEnabled(state),
})

export default compose(
  withTranslation('WelcomeNews'),
  connect(mapStateToProps)
)(WelcomeNews)
