// @flow

import React, { useRef } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { login } from './Login.actions'
import RegistrationLink from './RegistrationLink'
import Button from '../Button'
import RecoveryLink from './RecoveryLink'
import SSOLogin from '../SSOLogin'
import Terms from '../Terms'
import Input from '../Input/Input'
import { isObject } from 'lodash-es'

const LoginForm = () => {
  const { t } = useTranslation('LoginForm')
  const dispatch = useDispatch()
  const error = useSelector(state => state.login.error)
  const status = useSelector(state => state.login.status)
  const working = useSelector(state => state.login.working)
  const sso = useSelector(state => state.init.sso)

  const ref = useRef(null)

  const formik = useFormik({
    validateOnChange: false,
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: Yup.object({
      username: Yup.string().required(t('Common:FieldIsRequired')),
      password: Yup.string().required(t('Common:FieldIsRequired')),
    }),
    onSubmit: () => {
      dispatch(
        login({
          username: formik.values.username,
          password: formik.values.password,
        })
      )
    },
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <Input
        ref={ref}
        value={formik.values.username}
        name='username'
        errors={[formik.errors.username]}
        placeholder={t('Login')}
        onChange={formik.handleChange}
      />
      <Input
        type='password'
        value={formik.values.password}
        name='password'
        errors={[formik.errors.password]}
        placeholder={t('Password')}
        onChange={formik.handleChange}
      />
      <RecoveryLink linkText={t('RecoverPassword')} />
      <Button text={t('Enter')} type='submit' />
      {!working && !!error && (
        <div className='error' style={{ marginBottom: '24px' }}>
          {isObject(error) ? (
            Object.values(error).map(err => <div key={err}>{t(err)}</div>)
          ) : status === 429 ? (
            <div>{t('PasswordAttemptsExceeded')}</div>
          ) : null}
        </div>
      )}
      <RegistrationLink
        linkText={t('Registration')}
        infoText={t('NotRegisteredYet')}
      />
      <Terms />
      {sso?.enabled ? <SSOLogin /> : null}
    </form>
  )
}

export default LoginForm
