// @flow

import {
  COMMON_USE_SSL_FIELD,
  COMMON_USE_TLS_FIELD,
} from '../EmailIntegration.constants'

export const EMAIL_FROM = 'email_from'
export const REPLY_TO = 'reply_to'
export const USERNAME = 'username'
export const EMAIL_FROM_NAME = 'email_from_name'
export const PASSWORD = 'password'
export const HOST = 'host'
export const PORT = 'port'
export const SIGNATURE = 'signature'
export const USE_SSL = COMMON_USE_SSL_FIELD
export const USE_TLS = COMMON_USE_TLS_FIELD
export const INSERT_SIGNATURE_BEFORE = 'insert_signature_before_quoted_text'
export const PROTOCOL = 'protocol'
export const THUMBNAIL_SIZE = 510

export const KEYS = [
  EMAIL_FROM,
  REPLY_TO,
  USERNAME,
  PASSWORD,
  HOST,
  PORT,
  SIGNATURE,
  USE_SSL,
  USE_TLS,
  INSERT_SIGNATURE_BEFORE,
  EMAIL_FROM_NAME,
]

export const DEFAULT_VALUES: Object = {
  [EMAIL_FROM]: '',
  [REPLY_TO]: '',
  [USERNAME]: '',
  [PASSWORD]: '',
  [HOST]: '',
  [PORT]: '',
  [SIGNATURE]: '',
  [USE_SSL]: false,
  [USE_TLS]: false,
  [INSERT_SIGNATURE_BEFORE]: false,
  [EMAIL_FROM_NAME]: '',
}

export const SIG_IMAGE_MAX_SIZE = 2.5
