// @flow

import React from 'react'
import { useSelector } from 'react-redux'
import classnames from 'classnames'
import type { Node } from 'react'

import { getThemeColor } from '../../../utils/commonSelectors'

import styles from './Filter.module.scss'

import Icon from '../../../../static/icons/mail-filter.svg'

type Props = {
  children?: Node,
  className?: string,
  disabled?: boolean,
  onClick?: (SyntheticEvent<HTMLButtonElement>) => void,
  style?: Object,
}

const Filter = (props: Props): Node => {
  const { disabled, className, children, style } = props

  const themeColor: string = useSelector(getThemeColor)

  const iconStyle = { '--themeColor': themeColor }

  const btnClass = classnames(styles.filter, className, {
    [styles.inactive]: disabled,
  })

  return (
    <button
      style={style}
      className={btnClass}
      disabled={disabled}
      type='button'
      onClick={props.onClick}
    >
      {children}
      <Icon className={styles.icon} style={iconStyle} />
    </button>
  )
}

export default Filter
