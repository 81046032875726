// @flow

import React from 'react'
import classnames from 'classnames'
import type { Node } from 'react'

import styles from './Search.module.scss'

import Icon from '../../Icon'

type Props = {
  children?: Node,
  className?: string,
  disabled?: boolean,
  iconClassName?: String,
  onClick?: (SyntheticEvent<HTMLButtonElement>) => void,
  style?: Object,
}

const Search = (props: Props): Node => {
  const { children, disabled, className, iconClassName, style, onClick } = props

  const btnClass = classnames(styles.search, className)
  const iconClass = classnames(styles.icon, iconClassName)

  return onClick ? (
    <button
      style={style}
      className={btnClass}
      disabled={disabled}
      type='button'
      onClick={onClick}
    >
      {children}
      <Icon id='magnifier' className={iconClass} />
    </button>
  ) : (
    <Icon id='magnifier' className={iconClass} />
  )
}

export default Search
