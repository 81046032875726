// @flow

import { getApi, postApi, deleteApi, patchApi } from './api.core'

export const getListTiny = params => getApi('api/checklist-tiny/', params)
export const createChecklist = params => postApi('api/checklist/', params)
export const deleteChecklist = id => deleteApi(`api/checklist/${id}/`)
export const getCheckitems = params => getApi('api/checkitem/', params)
export const createCheckitem = params => postApi('api/checkitem/', params)
export const patchCheckitem = (id, params) =>
  patchApi(`api/checkitem/${id}/`, params)
export const deleteCheckitem = id => deleteApi(`api/checkitem/${id}/`)
