// @flow

export const TEXT_TYPE = 'text'
export const IMAGE_TYPE = 'image'
export const VARIABLE_TYPE = 'variable'
export const LINK_TYPE = 'link'

export const STRING_VARIABLE_TYPE = 'string'
export const INTEGER_VARIABLE_TYPE = 'integer'
export const EMAIL_VARIABLE_TYPE = 'email'
export const PHONE_VARIABLE_TYPE = 'phone'
export const TEXT_VARIABLE_TYPE = 'text'
export const FILE_VARIABLE_TYPE = 'file'
