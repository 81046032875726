// @flow

import React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import RouterTabs from '../Tabs/RouterTabs'
import Pan from '../Tabs/Pan'
import EmailIntegrationPrivate from './EmailIntegrationPrivate'
import EmailIntegrationCorporate from './EmailIntegrationCorporate'
import { useUser } from '../../hooks'
import { isAdminUser } from '../../utils/utils'

const EmailIntegration = (): Node => {
  const { t } = useTranslation('EmailIntegration')

  const user = useUser()

  const isAdmin = isAdminUser(user)

  const [count, setCount] = useState(null)

  return (
    <div className='content__col' style={{ paddingTop: '20px' }}>
      <section className='settings-rights unit unit--default'>
        {isAdmin ? (
          <RouterTabs count={count}>
            <Pan label={t('PrivateEmailIntegrationTitle')} linkHash='#private'>
              <EmailIntegrationPrivate
                key='private-email'
                setCount={setCount}
                isAdmin={isAdmin}
              />
            </Pan>
            <Pan
              label={t('CorporateEmailIntegrationTitle')}
              linkHash='#corporate'
            >
              <EmailIntegrationCorporate
                key='corporate-email'
                setCount={setCount}
              />
            </Pan>
          </RouterTabs>
        ) : (
          <EmailIntegrationPrivate key='private-email' setCount={setCount} />
        )}
      </section>
    </div>
  )
}

export default EmailIntegration
