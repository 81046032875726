// @flow

import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import classnames from 'classnames'

import Editor from '../Editor'
import Button from '../../../components/Button'
import {
  toggleGroup,
  saveGroupName,
  editGroup,
  showModal,
} from '../Faq.actions'
import * as selectors from '../Faq.selectors'
import { DONT_REMOVE_GROUP, REMOVE_POPUP } from '../Faq.constants'

type Props = {
  count: number,
  editGroup: () => void,
  editingGroup?: number,
  id: number,
  isAdmin: boolean,
  isCollapsed: boolean,
  isWorking: boolean,
  onCollapse: () => void,
  permissions: Object,
  saveGroupName: () => void,
  selected: Array<number>,
  showModal: () => void,
  title: string,
  toggleGroup: () => void,
}

class Header extends PureComponent<Props> {
  handleSelectGroup = e => {
    this.stopPropagation(e)
    const { id } = this.props
    this.props.toggleGroup(id, this.isSelected())
  }

  isSelected = () => {
    const { selected, id } = this.props

    return selected.some(s => s === id)
  }

  edit = () => {
    const { id } = this.props
    this.props.editGroup(id)
  }

  handleSaveName = value => {
    this.props.saveGroupName(value)
  }

  handleCollapse = () => {
    !this.isEditing() && this.props.onCollapse()
  }

  isEditing = (props = this.props) => {
    const { editingGroup, id } = props

    return editingGroup === id
  }

  removeGroup = () => {
    const { count, id } = this.props

    if (count) {
      this.props.showModal(DONT_REMOVE_GROUP)
    } else {
      if (!this.isSelected()) {
        this.props.toggleGroup(id, false)
      }

      this.props.showModal(REMOVE_POPUP)
    }
  }

  stopPropagation = e => {
    e.stopPropagation()
  }

  render() {
    const {
      title,
      id,
      isWorking,
      count,
      isCollapsed,
      permissions,
      editingGroup,
    } = this.props
    const isEditing = editingGroup === id
    const inputId = `group_${id}`
    const className = classnames('unit__title faq__group', {
      faq__group_edit: this.isEditing(),
    })
    const classNameTitle = classnames('faq__group-title', {
      'faq__group-title-editing': isEditing,
    })

    return (
      <div className={className} onClick={this.handleCollapse}>
        {permissions.can_delete && (
          <label
            className='checkbox checkbox--medium checkbox--default checkbox--notext ti-checkbox'
            htmlFor={inputId}
            onClick={this.stopPropagation}
          >
            <input
              autoFocus
              type='checkbox'
              id={inputId}
              value={id}
              checked={this.isSelected()}
              onChange={this.handleSelectGroup}
            />
            <span />
          </label>
        )}
        <div className={classNameTitle}>
          <Editor
            value={title}
            isWorking={isWorking}
            placeholder={title}
            editing={editingGroup}
            id={id}
            extendedText={count || ''}
            maxLength={256}
            canEdit={permissions.can_edit}
            onCancel={this.edit}
            onRemove={this.removeGroup}
            onSave={this.handleSaveName}
            onToggleEdit={this.edit}
          />
        </div>
        {Boolean(count) && !isEditing && (
          <Button.Minimize className='faq__minimize' isOpen={!isCollapsed} />
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  isAdmin: selectors.isAdmin(state),
  selected: selectors.getSelected(state),
  isWorking: selectors.isGroupWorking(state),
  editingGroup: selectors.getEditingGroup(state),
  permissions: selectors.getPermissions(state),
})
const mapDispatchToProps = {
  toggleGroup,
  saveGroupName,
  editGroup,
  showModal,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Header)
