// @flow

import React, { Component } from 'react'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { hot } from 'react-hot-loader/root'
import { captureException } from '@sentry/browser'
import { get } from 'lodash-es'

import makeRoutes from './routes'

type Props = {
  cookiesRefreshTimeout: number,
  history: Object,
  store: Object,
  translations: Object,
}

class App extends Component<Props> {
  componentDidCatch(error) {
    const code = get(error, 'code')
    const name = get(error, 'name')

    if (name === 'ChunkLoadError' || code === 'CSS_CHUNK_LOAD_FAILED') {
      window.location.reload()
    } else {
      captureException(error)
    }
  }

  render() {
    const Routes = makeRoutes(this.props.store)

    return (
      <React.StrictMode>
        <Provider store={this.props.store}>
          <ConnectedRouter history={this.props.history}>
            {Routes}
          </ConnectedRouter>
        </Provider>
      </React.StrictMode>
    )
  }
}

export default hot(App)
