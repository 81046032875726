// @flow

export function getItemText(
  item: Object,
  isDwellerGroup: boolean,
  t: string => string
): string {
  const { address_obj, address, number } = item

  const value = address_obj?.value || address

  return isDwellerGroup
    ? `${value}${` ${t('FlatNumberTitle')} ${number}`}`
    : value
}
