// @flow

import { getApi, postApi, patchRaw } from './api.core'

export const getContractors = params => getApi('api/contractor/', params)

export const getWidgetContractors = params =>
  getApi('api/mapped-contractor-options/', params)

export const getContractor = id => getApi(`api/contractor/${id}/`)

export const getContractorBuildings = (id, params) =>
  getApi(`api/contractor/${id}/buildings/`, params)

export const getContractorBuildingOptions = params =>
  getApi('api/mapped-building-options/', params)

export const getContractorCategoryOptions = params =>
  getApi('api/mapped-category-options/', params)

export const getArchivedContrators = params =>
  getApi('api/archived/contractor/', params)

export const getArchivedContractorBuildingOptions = params =>
  getApi('api/archived/mapped-building-options/', params)

export const getArchivedContractorCategoryOptions = params =>
  getApi('api/archived/mapped-category-options/', params)

export const createContractor = params => postApi('api/contractor/', params)

export const updateContractor = (id, params) =>
  patchRaw(`api/contractor/${id}/`, params)

export const addBuildings = params =>
  patchRaw('api/contractor/buildings/', params)
export const addCategories = params =>
  patchRaw('api/contractor/categories/', params)
