// @flow

import React, { useEffect, useState, useRef } from 'react'
import type { Node } from 'react'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'
import { useDrag, useDrop } from 'react-dnd'

import BlockLink from './BlockLink'
import ConfirmationPopup from '../../modals/ConfirmationPopup'
import Header from '../CardHeader'
import Links from './CardLinks'
import Modal from '../../Modal'

export type CardProps = {
  active: boolean,
  data: Object,
  onBlur: () => void,
  onFocus: () => void,
  onMove: number => void,
  onRemove: Object => void,
  removingId: ?number,
  working: boolean,
}

export type LinkCardProps = CardProps & {
  onLinkAdd: (number, Object) => void,
  onLinkRemove: (number, Object) => void,
  onLinkUpdate: (number, Object) => void,
}

export type Props = {
  Card: Node,
  cardProps: CardProps | LinkCardProps,
  connectDragSource: Node => Node,
  connectDropTarget: Node => Node,
  isDragging: boolean,
  isDropTarget: boolean,
  t: string => string,
  type: string,
  wide?: boolean,
}

const CardItem = (props: Props) => {
  const { cardProps, type, wide, Card } = props

  const { data, active, removingId } = cardProps
  const [addingLink, setAddingLink] = useState(null)
  const [editingLink, setEditingLink] = useState(null)
  const [ignoreClickOut, setIgnoreClickOut] = useState(false)
  const [modal, setModal] = useState(null)
  const [activeLinkIndex, setActiveLinkIndex] = useState(null)

  useEffect(() => {
    if (!cardProps.working) {
      setAddingLink(null)
      setActiveLinkIndex(null)
    }
  }, [cardProps.working])

  const handleMouseEnter = () => setIgnoreClickOut(true)

  const handleMouseLeave = () => setIgnoreClickOut(false)

  const handleCardClickOut = (force = false) => {
    if (!force && (addingLink || editingLink || modal)) {
      return
    }

    stopEdit(force)
  }

  const handleLinkClickOut = (force = false) => {
    stopEdit(force)
  }

  const cancelRemoving = () => setModal(false)

  const confirmRemoving = () => setModal(true)

  const startEdit = () => {
    props.cardProps.onFocus()
  }

  const stopEdit = (force = false) => {
    const { active } = props.cardProps

    if (!active || (!force && ignoreClickOut)) {
      return
    }

    setEditingLink(null)
    props.cardProps.onBlur()
  }

  const canSaveOnUnmount = () => {
    return active && removingId !== data.id
  }

  const editLink = data => {
    if (addingLink) {
      setAddingLink({
        ...addingLink,
        ...data,
      })
    }
  }

  const showLink = (link, index) => {
    const show = () => {
      if (link) {
        setTimeout(() => {
          setEditingLink(link)
        }, 100)
      } else {
        setEditingLink({ title: '', block: '' })
      }

      setActiveLinkIndex(index)
    }

    if (addingLink || editingLink) {
      setAddingLink(null)
      setEditingLink(null)
      setAddingLink(null)
    }

    show()
  }

  const removeLink = () => {
    if (editingLink && editingLink.id) {
      cardProps.onLinkRemove(data.id, editingLink)
    }

    setAddingLink(null)
    setEditingLink(null)
    setActiveLinkIndex(null)
  }

  const updateLink = link => {
    if (link.id) {
      cardProps.onLinkUpdate(data.id, link)
    } else {
      cardProps.onLinkAdd(data.id, link)
    }

    setEditingLink(null)
    setActiveLinkIndex(null)
  }

  const renderConfirmation = () => {
    return (
      <Modal
        isOpen
        className='Modal__Bootstrap modal-dialog'
        onRequestClose={cancelRemoving}
      >
        <ConfirmationPopup
          title={props.t('removeCardTitle')}
          text={props.t('removeCardText')}
          confirm={props.t('Common:Delete')}
          cancel={props.t('Common:Cancel')}
          onClose={cancelRemoving}
          onOk={() => cardProps.onRemove(data)}
        />
      </Modal>
    )
  }

  const ref = useRef(null)

  const [collectedDragProps, drag] = useDrag({
    item: { id: data.id, type: 'blockCard' },
    canDrag: () => !active,
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const [collectedDropProps, drop] = useDrop({
    accept: 'blockCard',
    canDrop: (item, monitor) => {
      const { id } = monitor.getItem()

      return id !== data.id
    },
    drop: (item, monitor) => {
      const { id } = monitor.getItem()

      if (id !== data.id) {
        cardProps.onMove(id)
      }
    },
    collect: monitor => ({
      isDropTarget: monitor.canDrop() && monitor.isOver({ shallow: true }),
    }),
  })

  const cardClass = classnames('request-constructor__card', {
    'request-constructor__card--edit': active && removingId !== data.id,
    'request-constructor__card--wide': wide,
    'request-constructor__card--drop': collectedDropProps.isDropTarget,
  })

  drag(drop(ref))

  const style = {
    opacity: collectedDragProps.isDragging ? 0.4 : 1,
    zIndex: active ? 1 : 0,
  }

  return (
    <div>
      <div
        ref={active ? null : ref} // HACK: react-dnd prevent all events in firefox and ie
        className={cardClass}
        style={style}
        onClick={startEdit}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <button
          className='modal__close'
          type='button'
          onClick={confirmRemoving}
        />
        <Header cardType={type} />
        <Card
          {...cardProps}
          active={active}
          canSaveOnUnmount={canSaveOnUnmount}
          onWysiwygFocus={startEdit}
          onClickOut={handleCardClickOut}
        />
        {data.links && (
          <Links adding={addingLink} items={data.links} onEdit={showLink} />
        )}
        {(!!editingLink || !!addingLink) && (
          <BlockLink
            data={editingLink}
            ignoreBlock={data.block}
            linkBottomIndex={data.links.length - activeLinkIndex - 1}
            onClickOut={handleLinkClickOut}
            onEdit={editLink}
            onRemove={removeLink}
            onUpdate={updateLink}
          />
        )}
      </div>
      {cardProps.renderArrow()}
      {modal && renderConfirmation()}
    </div>
  )
}

export default withTranslation('RequestConstructor')(CardItem)
