// @flow

import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'react-app-polyfill/ie11'
import React from 'react'
import ReactDOM from 'react-dom'
import { cloneDeep, get, isNil, mergeWith } from 'lodash-es'
import moment from 'moment'
import { init as sentryInit } from '@sentry/browser'
import smoothscroll from 'smoothscroll-polyfill'
import TagManager from 'react-gtm-module'
import 'moment-timezone'

import cookies from './utils/cookies'
import { loadI18NextTranslations } from './utils/translations'
import browserHistory from './history'
import App from './app'
import SomethingWentWrong from './components/SomethingWentWrong'
import { configureStore } from './core/Store'
import api from './core/api'
import BrowserStorage from './utils/browserStorage'
import { MENU_LOCALE_STORAGE_KEY } from './components/Menu/Menu.constants'

import '../stylesheets/main.scss'

import {
  INIT_LOADED,
  UPDATE_COUNTERS_SUCCESS,
} from './components/init/init.actionTypes'

const REPLACEMANT_USER_PROPS = {
  surname: '',
  second_name: '',
  name: '',
  company_name: '',
  company_post_name: '',
}

const COOKIE_NAME = 'idw_token'

const token = cookies.getItem(COOKIE_NAME)

if (token) {
  if (!BrowserStorage.get('token')) {
    BrowserStorage.set('token', token)
  }

  cookies.removeItem(COOKIE_NAME, '/', '.idwell.at')
}

// If backend works local
if (process.env.IS_LOCAL_BACK) {
  localStorage.setItem('bs_api', 'http://localhost:8000/')
}

if (process.env.BACKEND) {
  localStorage.setItem('bs_api', `//${process.env.BACKEND}/`)
}

const fromLandingPageToken = cookies.getItem('hash_time')

if (fromLandingPageToken) {
  BrowserStorage.set('token', fromLandingPageToken)
  cookies.removeItem('hash_time')
  cookies.removeItem('hash_time', '/', '.idwell.ru')
  cookies.removeItem('hash_time', '/', '.idwell.com')
  cookies.removeItem('hash_time', '/', '*')
}

const profile = api.profile.getMyProfile()
const translation = api.translation.getTranslation()
const init = api.system.init()
const mpSession = init.then(function (resultA) {
  if (resultA.marketplace_token) {
    return api.system.getMpSession()
  } else {
    return resultA
  }
})

function customizer(objValue, srcValue) {
  if (isNil(objValue)) {
    return srcValue
  }

  return objValue
}

function updateUserObject(user) {
  return mergeWith(user, REPLACEMANT_USER_PROPS, customizer)
}

Promise.all([profile, translation, init, mpSession]).then(
  ([profileData, translations, initData, mpsession]) => {
    if (!initData) {
      return
    }

    const gtmId = initData.gtm_container_id

    if (gtmId) {
      const tagManagerArgs = {
        gtmId,
      }

      TagManager.initialize(tagManagerArgs)
    }

    const hostName = get(initData, ['uk_company', 'host_name'])

    let environment

    if (hostName) {
      environment = hostName.split('.').slice(1).join('.')
    }

    if (process.env.NODE_ENV !== 'development') {
      sentryInit(
        Object.assign(
          {},
          {
            dsn: 'https://2c820c0f7ff0481695c7eddb5a69cc73@sentry.idwell.at//3',
          },
          environment ? { environment } : undefined
        )
      )
    }

    if (mpsession && initData.marketplace_token) {
      cookies.setItem(
        'marketplace_token',
        initData.marketplace_token,
        undefined,
        '/'
      )
      cookies.setItem(
        'marketplace_url',
        initData.marketplace_url,
        undefined,
        '/'
      )
      cookies.setItem('mpSessionId', mpsession.mpSessionId, undefined, '/')
    }

    // Load translations to i18next
    loadI18NextTranslations(translations)

    let user = cloneDeep(profileData)
    // dirty check for empty values type

    if (user) {
      user = updateUserObject(user)
    }

    const timezone = get(
      initData,
      ['uk', 'city_obj', 'timezone'],
      'Europe/Vienna'
    )

    moment.tz.setDefault(timezone)

    if (profileData && profileData.language_obj) {
      // If user is signed in, take his profile's language
      moment.locale(profileData.language_obj.code)
    } else if (initData && initData.uk && initData.uk.language_obj) {
      // Take his UK language otherwise
      moment.locale(initData.uk.language_obj.code)
    } else {
      // Fallback case
      moment.locale('en')
    }

    const collapsed = !!JSON.parse(BrowserStorage.get(MENU_LOCALE_STORAGE_KEY))

    const state = {
      login: initData.authorize ? { user } : {},
      menu: { collapsed },
      init: {
        ...initData,
      },
      translations: { translations },
    }

    const store = configureStore(state, browserHistory)

    store.dispatch({ type: 'APP:INIT' })
    store.dispatch({ type: INIT_LOADED, data: initData })

    if (initData.authorize) {
      store.dispatch({
        type: UPDATE_COUNTERS_SUCCESS,
        counters: { unviewed_requests: user.requests_counter },
      })
    }

    const render = Application => {
      ReactDOM.render(
        <Application history={browserHistory} store={store} />,
        document.getElementById('app')
      )
    }

    const themeCss = Object.keys(initData.uk.theme)
      .map(
        style => `theme-${style}-${initData.uk.theme[style].replace('#', '')}`
      )
      .join(' ')
    document.body.className = `${document.body.className} ${themeCss}`

    if (initData.seo) {
      const { title, meta_description } = initData.seo

      if (title) {
        document.title = title
      }

      if (meta_description) {
        document
          .querySelector('meta[name="description"]')
          .setAttribute('content', meta_description)
      }
    }

    render(App)

    // HMR interface
    if (module.hot) {
      module.hot.accept('./app', () => render(App))
    }
  },
  err => {
    console.error(err)
    BrowserStorage.remove('token')

    if (/login/.test(window.location.pathname)) {
      ReactDOM.render(<SomethingWentWrong />, document.getElementById('app'))
    } else {
      window.location.href = '/login'
    }
  }
)

smoothscroll.polyfill()
