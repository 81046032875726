// @flow

import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import ModalLabel from '../../../../../Modal/ModalLabel'
import ModalRow from '../../../../../Modal/ModalRow'
import NewFilesUpload from '../../../../../NewFilesUpload'
import Button from '../../../../../Button'
import InputField from '../../../../../InputField'
import CardStageContext from '../../../CardStage.context'
import NewWizardContext from '../../../../NewWizard.context'
import { FORMIK_EMPTY_VALUE } from '../../../../../../constants'
import FileList from './FileList'
import { removeFile } from '../../../../../../utils/file'
import ModalError from '../../../../../Modal/ModalError'
import type { CardType } from '../../../../NewWizard.types'

import styles from './FileInput.module.scss'

type Props = { card: CardType }

const FileInput = (props: Props): Node => {
  const {
    card: { name, label },
  } = props

  const { t } = useTranslation('WizardPopup')

  const [isUploading, setUploading] = useState(false)

  const { errors, values, setFieldValue } = useContext(CardStageContext)
  const { setUploading: setModalUploading } = useContext(NewWizardContext)

  const value = values[name]
  const error = errors[name]

  const onFinishUpload = files => {
    const processedFiles = files.map(file => ({
      id: file.id,
      name: file.name,
      origin: file.origin,
    }))

    if (value === FORMIK_EMPTY_VALUE) {
      setFieldValue(name, processedFiles)
    } else {
      setFieldValue(name, value.concat(processedFiles))
    }

    setUploading(false)
    setModalUploading(false)
  }

  const onstartUpload = () => {
    setModalUploading(true)
    setUploading(true)
  }

  const handleRemove = removingFileId => {
    setFieldValue(
      name,
      value.filter(file => file.id !== removingFileId)
    )

    removeFile(removingFileId)
  }

  return (
    <>
      <ModalRow>
        <ModalLabel>{label}</ModalLabel>
      </ModalRow>
      <FileList files={value} onRemove={handleRemove} />
      <ModalRow>
        <NewFilesUpload
          multiple
          className={styles.upload}
          name='wizardFileAttach'
          onFinishUpload={onFinishUpload}
          onStartUpload={onstartUpload}
        >
          <InputField disabled name='icon-file' />
          <Button.Regular
            disabled={isUploading}
            className={styles.button}
            working={isUploading}
          >
            {t('UploadFile')}
          </Button.Regular>
        </NewFilesUpload>
      </ModalRow>
      {error && <ModalError error={error} />}
    </>
  )
}

export default FileInput
