import React from 'react'
import { useTranslation } from 'react-i18next'

import { Cell } from '../../../Block'

import DateTime from '../../../DateTime'

const ProfileCreatedOn = props => {
  const { created } = props
  const { t } = useTranslation('Profile')

  return (
    <Cell title={t('CreatedOnTitle')}>
      {created ? <DateTime dateTime={created} /> : '-'}
    </Cell>
  )
}

export default ProfileCreatedOn
