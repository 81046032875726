// @flow

import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import type Node from 'react'

import { SimpleBlock, Header, Row } from '../Block'

import { getPrintersList } from '../../core/api/api.printer'

import Printer from './Printer'
import Button from '../Button'

const ServiceSettings = (): Node => {
  const { t } = useTranslation('Service')
  const [isLoading, setLoading] = useState(true)
  const [printers, setPrinters] = useState([])

  const getPrinters = () => {
    setLoading(true)
    getPrintersList().then(data => {
      if (Array.isArray(data.results?.objects)) {
        setPrinters(data.results?.objects)
      }

      setLoading(false)
    })
  }

  useEffect(() => {
    getPrinters()
  }, [])

  const handleAddPrinter = () => setPrinters([...printers, null])

  return (
    <SimpleBlock working={isLoading}>
      <Header header>{t('ServiceTitle')}</Header>
      {printers.length > 0 ? (
        printers.map((p, i) => (
          <Printer key={p?.id || -1 * i} printer={p} onUpdate={getPrinters} />
        ))
      ) : (
        <Printer onUpdate={getPrinters} />
      )}
      {printers.length > 0 && !printers.includes(null) && (
        <Row>
          <Button.Add
            name='add-more'
            title={t('AddMore')}
            onClick={handleAddPrinter}
          />
        </Row>
      )}
    </SimpleBlock>
  )
}

export default ServiceSettings
