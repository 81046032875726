// @flow

import React, { useState, useEffect, useRef } from 'react'
import { map } from 'lodash-es'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import OutsideClick from 'react-onclickout'
import type { Node } from 'react'

import useKeyPress from '../../../hooks/useKeyPress'

import NewFilterAsyncSearch from '../../NewFilterAsync/NewFilterAsyncSearch'
import List from '../../List'
import ListItem from '../../ListItem'
import Loader from '../../Loader'
import SelectLoader from '../../SelectLoader'

import styles from '../NewSelectAsync.module.scss'
import styles2 from './NewSelectAsyncMenu.module.scss'

type Props = {
  avatarKey?: string,
  className?: string,
  debouncedValue: string,
  emptyMenu?: Node,
  getDescription: Object => string,
  getLabel: Object => string,
  getMeta?: (Object, boolean) => Node,
  handleClickOut: () => void,
  idField?: string,
  initiating: boolean,
  isAllLoaded: boolean,
  isAllSelected?: (Array<Object>) => boolean,
  isEmbedded: boolean,
  items: Array<Object>,
  loading: boolean,
  minSearchLength: number,
  noAll?: boolean,
  onClick: Object => Function,
  onClickAll?: (boolean, boolean) => void,
  onScroll: Function,
  placeholder: string,
  position: 'bottom' | 'top',
  searchKey?: string,
  selectedItems: Array<Object>,
  setAllLoading: boolean => void,
  setSelectedItems?: (Array<Object>) => void,
  setValue: string => void,
  value: string,
}

const NewSelectAsyncMenu = (props: Props): Node => {
  const {
    className,
    isEmbedded,
    placeholder,
    value,
    idField,
    initiating,
    loading,
    items,
    selectedItems,
    position,
    searchKey,
    avatarKey,
    emptyMenu,
    isAllSelected,
    isAllLoaded,
    minSearchLength,
    debouncedValue,
    noAll,
  } = props

  const { t } = useTranslation('Select')

  const arrowUpPressed = useKeyPress('ArrowUp')
  const arrowDownPressed = useKeyPress('ArrowDown')
  const arrowEnterPressed = useKeyPress('Enter')
  const elemRef = useRef([])
  const scrollRef = useRef()
  const [isBoardMode, setIsBoardMode] = useState(false)
  const isMultiSelect = Boolean(isAllSelected)

  const [currentIndex, setCurrentIndex] = useState({
    index: isMultiSelect && !noAll ? -1 : 0,
    needScroll: true,
  })

  const loaderStyle = {
    margin: 0,
    padding: 0,
  }

  const imgStyle = {
    display: 'block',
    margin: '0 auto',
    width: '36px',
  }

  const listProps = Object.assign(
    {},
    { className: styles.list },
    isEmbedded ? { embedded: true } : undefined
  )

  const menuClass = classnames(styles2.menu, styles2[position], className, {
    [styles2.embedded]: isEmbedded,
  })

  const handleClickAll = () => {
    if (isAllLoaded && isAllSelected) {
      if (isAllSelected(selectedItems)) {
        props.setSelectedItems([])

        if (props.onClickAll) {
          props.onClickAll(false, false)
        }
      } else {
        props.setSelectedItems(items)
        props.setMenuOpen(false)

        if (props.onClickAll) {
          props.onClickAll(false, true)
        }
      }

      return
    }

    if (props.onClickAll) {
      props.onClickAll(true, true)
    }

    props.setAllLoading(true)
  }

  const filteredItems =
    isAllLoaded && minSearchLength <= debouncedValue.length
      ? items.filter(item => {
          const label = props.getLabel(item)

          return label
            .toLowerCase()
            .includes(debouncedValue.toLocaleLowerCase())
        })
      : items

  const endLimit = isMultiSelect && !noAll ? -1 : 0

  useEffect(() => {
    if (arrowUpPressed) {
      setIsBoardMode(true)
      setCurrentIndex(prevState => ({
        ...prevState,
        needScroll: true,
        index: prevState.index !== endLimit ? prevState.index - 1 : endLimit,
      }))
    }
  }, [arrowUpPressed, filteredItems])

  useEffect(() => {
    if (arrowDownPressed) {
      setIsBoardMode(true)
      setCurrentIndex(prevState => ({
        ...prevState,
        needScroll: true,
        index:
          prevState.index !== filteredItems.length - 1
            ? prevState.index + 1
            : filteredItems.length - 1,
      }))
    }
  }, [arrowDownPressed, filteredItems])

  useEffect(() => {
    if (arrowEnterPressed) {
      if (currentIndex.index === -1) {
        handleClickAll()

        return
      }

      if (props.onClick && filteredItems[currentIndex.index]) {
        props.onClick(filteredItems[currentIndex.index])()
      }
    }
  }, [arrowEnterPressed])

  useEffect(() => {
    if (currentIndex.needScroll) {
      scrollRef.current.scrollTo({
        top: elemRef.current[currentIndex.index]?.offsetTop - 150,
      })
    }
  }, [currentIndex])

  const onKeyBoardMode = () => {
    if (isBoardMode) {
      setIsBoardMode(false)
    }
  }

  const handlerMouse = index => {
    if (isBoardMode) return

    setCurrentIndex(prevState => ({
      ...prevState,
      needScroll: false,
      index,
    }))
  }

  return (
    <OutsideClick onClickOut={props.handleClickOut}>
      <div className={menuClass} onScroll={props.onScroll}>
        {searchKey && (
          <NewFilterAsyncSearch
            loading={loading}
            initiating={initiating}
            value={value}
            disabled={initiating}
            placeholder={placeholder}
            onInputChange={props.setValue}
          />
        )}
        <List {...listProps} ref={scrollRef} onMouseMove={onKeyBoardMode}>
          {!noAll &&
            value.length < minSearchLength &&
            !initiating &&
            props.setSelectedItems &&
            !!items.length && (
              <ListItem
                index={-1}
                isHover={currentIndex.index === -1}
                primaryText={t('all')}
                isSelected={isAllSelected && isAllSelected(selectedItems)}
                handlerMouse={handlerMouse}
                onClick={handleClickAll}
              />
            )}
          {!initiating ? (
            filteredItems.length ? (
              filteredItems.map((item, index) => {
                const isSelected =
                  (isAllSelected && isAllSelected(selectedItems)) ||
                  map(selectedItems, idField || 'id').includes(
                    item[idField || 'id']
                  )
                const text = props.getLabel(item)
                const description = props.getDescription
                  ? props.getDescription(item)
                  : undefined

                return (
                  <ListItem
                    index={index}
                    avatar={item[avatarKey]}
                    primaryText={text}
                    secondaryText={description}
                    title={text}
                    key={item[idField || 'id']}
                    ref={ref => (elemRef.current[index] = ref)}
                    isHover={currentIndex.index === index}
                    isSelected={
                      isMultiSelect || isEmbedded
                        ? isSelected
                        : currentIndex.index === index
                    }
                    handlerMouse={handlerMouse}
                    meta={
                      props.getMeta
                        ? props.getMeta(item, isSelected)
                        : undefined
                    }
                    onClick={props.onClick(item)}
                  />
                )
              })
            ) : emptyMenu && !selectedItems.length ? (
              emptyMenu
            ) : (
              <div className={styles.noResults}>{t('NoResults')}</div>
            )
          ) : (
            <Loader
              type='small'
              text={false}
              style={loaderStyle}
              imgStyle={imgStyle}
            />
          )}
        </List>
        {loading && <SelectLoader />}
      </div>
    </OutsideClick>
  )
}

export default NewSelectAsyncMenu
