// @flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useSelector } from 'react-redux'
import type { Node } from 'react'

import Modal from '../Modal'
import {
  addWorkflow,
  cloneWorkflow,
} from '../../core/api/api.requestConstructor'
import { useOverflow } from '../../hooks'
import NewSelectSimple from '../NewSelectSimple'
import ModalHead from '../Modal/ModalHead'
import ModalCloseButton from '../Modal/ModalCloseButton'
import ModalBody from '../Modal/ModalBody'
import ModalButtons from '../Modal/ModalButtons'
import Button from '../Button'
import ModalRow from '../Modal/ModalRow/ModalRow'
import ModalCell from '../Modal/ModalCell'
import ModalLabel from '../Modal/ModalLabel'
import InputField from '../InputField'

type Props = {
  cloningWorkflowId: ?number,
  closeModal: (?boolean) => void,
  isModalOpen: boolean,
}

const WorkflowModal = (props: Props): Node => {
  const { isModalOpen, cloningWorkflowId } = props

  const { t } = useTranslation('Workflow')

  const [loading, setLoading] = useState(false)

  useOverflow()

  const workflowTypes = useSelector(state => state.init.workflow_types)

  const options = workflowTypes.map(item => ({ label: t(item), value: item }))

  const [typeOption, setTypeOption] = useState(options[0])

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .max(100, t('MaxNameLength'))
        .required(t('NameRequired')),
    }),
    onSubmit: values => {
      setLoading(true)

      if (cloningWorkflowId) {
        cloneWorkflow(cloningWorkflowId, {
          title: values.name,
          type: typeOption.value,
        }).then(() => {
          props.closeModal(true)
        })
      } else {
        addWorkflow({ title: values.name, type: typeOption.value }).then(() => {
          props.closeModal(true)
        })
      }
    },
  })

  const handleChange = value => {
    setTypeOption(value)
  }

  const handleClose = () => {
    props.closeModal()
  }

  const title = cloningWorkflowId ? 'CloneWorkflow' : 'AddWorkflow'
  const buttonTitle = cloningWorkflowId ? 'Clone' : 'Add'

  return (
    <Modal isOpen={isModalOpen} onRequestClose={handleClose}>
      <ModalHead title={t(title)} />
      <ModalCloseButton onClose={handleClose} />
      <ModalBody style={{ marginBottom: 0 }}>
        <form onSubmit={formik.handleSubmit}>
          <ModalRow>
            <ModalCell>
              <ModalLabel>{t('WorkflowName')}</ModalLabel>
            </ModalCell>
            <ModalCell gridNumber={2}>
              <InputField
                autoFocus
                disabled={loading}
                name='name'
                placeholder={t('WorkflowNamePlaceholder')}
                value={formik.values.name}
                error={formik.errors.name}
                onChange={formik.handleChange}
              />
            </ModalCell>
          </ModalRow>
          <ModalRow>
            <ModalCell>
              <ModalLabel>{t('WorkflowType')}</ModalLabel>
            </ModalCell>
            <ModalCell gridNumber={2}>
              <NewSelectSimple
                name='type'
                options={options}
                value={typeOption}
                onChange={handleChange}
              />
            </ModalCell>
          </ModalRow>
          <ModalButtons>
            <Button.Save type='submit' disabled={loading}>
              {t(buttonTitle)}
            </Button.Save>
            <Button.Cancel disabled={loading} onClick={handleClose}>
              {t('Cancel')}
            </Button.Cancel>
          </ModalButtons>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default WorkflowModal
