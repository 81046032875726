// @flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import Textarea from 'react-textarea-autosize'
import { useSelector } from 'react-redux'
import { unionBy } from 'lodash-es'
import type { Node } from 'react'

import NewTemplateDropdown from '../../NewTemplateDropdown'
import InputValidation from '../../forms/InputValidation'
import { TICKER_TYPE } from '../Post.constants'
import { isStaffUser } from '../../../utils/utils'
import { getUser } from '../../../utils/commonSelectors'
import { renderTemplate } from '../../../core/api/api.postTemplate'
import { getTime } from '../Post.utils'
import WysiwygEditor from '../../WysiwygEditor'

import styles from './PostDescription.module.scss'

type Props = {
  description: string,
  displayTemplates?: boolean,
  postType: string,
  setDescription: string => void,
  templateParams?: Object,
  title?: string,
}

const PostDescription = (props: Props): Node => {
  const handleChange = value => {
    props.setDescription(value)
  }

  const { t } = useTranslation('Post')

  const user = useSelector(getUser)

  const {
    description,
    postType,
    displayTemplates,
    files,
    templateParams,
    automate,
  } = props
  const [editorRef, setEditorRef] = useState(null)

  const descriptionClass = classnames(
    'textarea textarea--default textarea--xlarge textarea--block textarea--noresize',
    { [styles.description]: !automate }
  )

  const message = props.getValidationMessages?.('description')

  const handleSelect = option => {
    if (!option || !option.id || !editorRef) {
      return
    }

    renderTemplate(option.id).then(data => {
      editorRef.insertContent(data.text)

      editorRef.focus()

      const currentTitle = props.title || ''
      const newTitle = currentTitle + ' ' + data.post_title
      const newFiles = unionBy(files, data.files, 'id')

      props.updateDateTo?.(data.post_date_to)
      props.setTimeTo?.(getTime(data.post_date_to))
      props.updateDateFrom?.(data.post_date_from)
      props.setTimeFrom?.(getTime(data.post_date_from))
      props.setTitle?.(newTitle)
      props.onUpdateFiles?.(newFiles)
      props.handleValidation?.('title')()
      props.handleValidation?.('description')()
      props.setPostData?.(newTitle, description + data.text, newFiles)
    })
  }

  return (
    <div className={descriptionClass}>
      {postType !== TICKER_TYPE ? (
        <>
          {isStaffUser(user) && displayTemplates && (
            <NewTemplateDropdown
              isPostTemplate
              handleSelect={handleSelect}
              permanentParams={templateParams || {}}
            />
          )}
          <WysiwygEditor
            autoResizeOnInit
            value={description}
            placeholder={t('PostDescriptionPlaceholder')}
            setEditorRef={setEditorRef}
            onBlur={props.handleValidation?.('description')}
            onChange={handleChange}
          />
        </>
      ) : (
        <Textarea
          className='textarea-2__input'
          name='text'
          placeholder={t('PostDescriptionPlaceholder')}
          value={description}
          onBlur={props.handleValidation?.('description')}
          onChange={e => handleChange(e.target.value)}
        />
      )}
      {!!message?.length && (
        <InputValidation message={message} needTranslate={false} />
      )}
    </div>
  )
}

export default PostDescription
