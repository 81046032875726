// @flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { graphql, QueryRenderer } from 'react-relay'
import { map } from 'lodash-es'
import Scrollbar from 'react-scrollbars-custom'

import environment from '../../../../createRelayEnvironment'
import NewOrdersSelectList from './NewOrdersSelectList'

import styles from './NewOrdersSelectList.module.scss'

import TrackY from '../../../NewFilterAsync/TrackY'
import ThumbY from '../../../NewFilterAsync/ThumbY'

const query = graphql`
  query NewOrdersSelectQuery($pageSize: Float!, $first: Int!) {
    allCustomerOrders(pageSize: $pageSize, first: $first) {
      edges {
        node {
          id
          offer
          orderNo
        }
      }
    }
  }
`

function getLabel(order, t) {
  return `${t('OrderNumberPrefix')} №${order.orderNo}: ${
    order.title || JSON.parse(order.offer).name
  }`
}

const INFINITY_FIRST = 100500

const NewOrdersSelect = props => {
  const { selectedOrderIds } = props
  const [isOpen, setOpen] = useState(false)
  // const [loading, setLoading] = useState(true)
  // const [loaded, setLoaded] = useState(false)

  const { t } = useTranslation('Request')

  const handleToggle = () => setOpen(!isOpen)

  return (
    <>
      <div className={styles.select}>
        <button type='button' onClick={handleToggle}>
          {t('SetOrder')}
        </button>
        <QueryRenderer
          environment={environment}
          query={query}
          variables={{
            page: 0,
            pageSize: INFINITY_FIRST,
            first: INFINITY_FIRST,
          }}
          render={({ props: queryProps }) => {
            if (!queryProps || !isOpen) {
              return null
            }

            const items = map(queryProps.allCustomerOrders.edges, 'node')

            const options = items.map(item => ({
              label: getLabel(item, t),
              value: item.id,
            }))

            const scrollbarHeight = Math.min(items.length * 35, 175)

            return (
              <div className={styles.selectList}>
                <Scrollbar
                  translateContentSizeXToHolder
                  noScrollX
                  style={{ height: scrollbarHeight }}
                  trackYProps={{
                    renderer: TrackY,
                  }}
                  thumbYProps={{
                    renderer: ThumbY,
                  }}
                >
                  <NewOrdersSelectList
                    options={options}
                    selectedOrderIds={selectedOrderIds}
                    setSelectedOrderIds={props.setSelectedOrderIds}
                    handleClickOut={handleToggle}
                  />
                </Scrollbar>
              </div>
            )
          }}
        />
      </div>
    </>
  )
}

export default NewOrdersSelect
