// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import type { Node } from 'react'

import Header from './Header'
import Form from './Form'
import Title from './Title'
import Loader from '../Loader'
import Success from './Success'
import Error from './Error'

import styles from './CompanyRegistration.module.scss'

const CompanyRegistration = (): Node => {
  const { t } = useTranslation('Registration')

  const { working, error, confirm, result } = useSelector(
    state => state.companyRegistration
  )

  return (
    <div className='company-registration'>
      <Header t={t} />
      {!working && !confirm && error && <Error error={error} t={t} />}
      {working && !confirm && !error && <Loader text={false} type='big' />}
      {!working && !confirm && !error && (
        <main className={styles.main}>
          <Title t={t} />
          <Form t={t} />
        </main>
      )}
      {!working && confirm && !error && <Success result={result} t={t} />}
    </div>
  )
}

export default CompanyRegistration
