// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  getDwellerPermissions,
  updateDwellerPermission,
} from '../../core/api/api.permission'
import Permission from './Permission'
import Loader from '../Loader'
import { usePermissions } from '../../hooks'

const CAN_DWELLER_COMMENT_POST = 'can_dweller_comment_post'
const CAN_DWELLER_CREATE_POST = 'can_dweller_create_post'
const CAN_DWELLER_CHAT_WITH_DWELLER = 'can_dweller_chat_with_dweller'
const CAN_DWELLER_USE_MARKETPLACE = 'can_dweller_use_marketplace'
const CAN_DWELLER_VIEW_DWELLER_REQUEST = 'can_dweller_view_dweller_request'
const CAN_DWELLER_VIEW_LANDLORD_REQUEST = 'can_dweller_view_landlord_request'
const CAN_LANDLORD_VIEW_DWELLER_REQUEST = 'can_landlord_view_dweller_request'
const CAN_LANDLORD_VIEW_LANDLORD_REQUEST = 'can_landlord_view_landlord_request'
const CAN_DWELLER_VIEW_REQUEST_ASSIGNEE = 'can_dweller_view_request_assignee'

const DwellerPermissions = () => {
  const { t } = useTranslation('Permissions')

  const [permissions, handleClick] = usePermissions(
    getDwellerPermissions,
    updateDwellerPermission
  )

  if (!permissions) {
    return <Loader text={false} type='big' />
  }

  return (
    <div className='settings-rights__wrapper'>
      <Permission
        isActive={permissions[CAN_DWELLER_COMMENT_POST]}
        handleClick={handleClick}
        permissionDescription={t(CAN_DWELLER_COMMENT_POST)}
        title={t('newsband')}
        permissionName={CAN_DWELLER_COMMENT_POST}
      />
      <Permission
        isActive={permissions[CAN_DWELLER_CREATE_POST]}
        handleClick={handleClick}
        permissionDescription={t(CAN_DWELLER_CREATE_POST)}
        permissionName={CAN_DWELLER_CREATE_POST}
      />
      <Permission
        isActive={permissions[CAN_DWELLER_VIEW_DWELLER_REQUEST]}
        handleClick={handleClick}
        permissionDescription={t(CAN_DWELLER_VIEW_DWELLER_REQUEST)}
        title={t('service_request')}
        permissionName={CAN_DWELLER_VIEW_DWELLER_REQUEST}
      />
      <Permission
        isActive={permissions[CAN_DWELLER_VIEW_LANDLORD_REQUEST]}
        handleClick={handleClick}
        permissionDescription={t(CAN_DWELLER_VIEW_LANDLORD_REQUEST)}
        permissionName={CAN_DWELLER_VIEW_LANDLORD_REQUEST}
      />
      <Permission
        isActive={permissions[CAN_LANDLORD_VIEW_DWELLER_REQUEST]}
        handleClick={handleClick}
        permissionDescription={t(CAN_LANDLORD_VIEW_DWELLER_REQUEST)}
        permissionName={CAN_LANDLORD_VIEW_DWELLER_REQUEST}
      />
      <Permission
        isActive={permissions[CAN_LANDLORD_VIEW_LANDLORD_REQUEST]}
        handleClick={handleClick}
        permissionDescription={t(CAN_LANDLORD_VIEW_LANDLORD_REQUEST)}
        permissionName={CAN_LANDLORD_VIEW_LANDLORD_REQUEST}
      />
      <Permission
        isActive={permissions[CAN_DWELLER_VIEW_REQUEST_ASSIGNEE]}
        handleClick={handleClick}
        permissionDescription={t(CAN_DWELLER_VIEW_REQUEST_ASSIGNEE)}
        permissionName={CAN_DWELLER_VIEW_REQUEST_ASSIGNEE}
      />
      <Permission
        isActive={permissions[CAN_DWELLER_CHAT_WITH_DWELLER]}
        handleClick={handleClick}
        permissionDescription={t(CAN_DWELLER_CHAT_WITH_DWELLER)}
        title={t('chats')}
        permissionName={CAN_DWELLER_CHAT_WITH_DWELLER}
      />
      <Permission
        isActive={permissions[CAN_DWELLER_USE_MARKETPLACE]}
        handleClick={handleClick}
        permissionDescription={t(CAN_DWELLER_USE_MARKETPLACE)}
        title={t('marketplace')}
        permissionName={CAN_DWELLER_USE_MARKETPLACE}
      />
    </div>
  )
}

export default DwellerPermissions
