// @flow

import React from 'react'
import { Link } from 'react-router-dom'
import type { Node } from 'react'

import AudienceItem from '../../AudienceItem'
import { getAudience, isAllSelected } from '../../Post.utils'
import { MAX_AUDIENCE_LENGTH } from '../../Post.constants'
import { USER_GROUPS } from '../../../../constants'

import styles from './PostItemAudience.module.scss'

type Props = {
  audience: Array<string>,
  building_groups: Array<Object>,
  buildings: Array<Object>,
  owner: number,
  user: Object,
  uuid: string,
}

const PostItemAudience = (props: Props): Node => {
  const { audience, buildings, building_groups, user, uuid, owner } = props

  const allBuildings =
    !building_groups?.length && isAllSelected(buildings, audience)

  const formattedAudience = getAudience(
    user.group !== USER_GROUPS.dweller || user.owner === owner ? audience : [],
    building_groups?.length > 0 ? building_groups : buildings,
    user,
    allBuildings,
    building_groups?.length > 0
  ).slice(0, MAX_AUDIENCE_LENGTH)

  const restItemsLength = formattedAudience.length - MAX_AUDIENCE_LENGTH

  return (
    <div className={styles.audience}>
      {formattedAudience.map(item => (
        <AudienceItem item={item} key={item.id} />
      ))}
      {restItemsLength > 0 && (
        <Link to={`/post/${uuid}`}>
          <div className={styles.rest}>+{restItemsLength}</div>
        </Link>
      )}
    </div>
  )
}

export default PostItemAudience
