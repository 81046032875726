// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { get, isEmpty } from 'lodash-es'
import moment from 'moment'

import { clear } from './Login.actions'
import AuthPage from '../AuthPage'
import LoginForm from './LoginForm'
import { loadTranslations } from '../../translations/translations.actions'
import { getUser, getUk } from '../../../utils/commonSelectors'

type Props = {
  translations: { loaded: boolean },
}

class Login extends Component<Props> {
  componentDidUpdate(prevProps) {
    const {
      translations: { loaded: prevLoaded },
    } = prevProps

    const {
      translations: { loaded },
    } = this.props

    if (!prevLoaded && loaded) {
      this.props.clear()
    }
  }

  getLang = (langObj: Object) => get(langObj, ['language_obj', 'code'])

  isEqualLangs = () => {
    const { user, uk } = this.props

    const ukLang = this.getLang(uk)
    const userLang = this.getLang(user)

    return ukLang === userLang
  }

  needUpdateTranslations = () => {
    const { user } = this.props

    return !isEmpty(user) && !this.isEqualLangs() && user.group !== 'worker'
  }

  componentDidMount() {
    if (this.needUpdateTranslations()) {
      const { uk } = this.props

      moment.locale(this.getLang(uk))

      this.props.loadTranslations()
    } else {
      this.props.clear()
    }
  }

  render() {
    const {
      working = false,
      error,
      translations: { loading, loaded },
    } = this.props

    if (loading) {
      return null // add normal loader
    }

    if (this.needUpdateTranslations() && !loaded) {
      return null
    }

    return (
      <AuthPage working={working} error={error}>
        <LoginForm working={working} error={error} />
      </AuthPage>
    )
  }
}

const mapStateToProps = state => ({
  user: getUser(state),
  error: state.login.error,
  uk: getUk(state),
  translations: state.translations,
  working: state.login.working,
})

const mapActionsToProps = {
  clear,
  push,
  loadTranslations,
}

export default connect(mapStateToProps, mapActionsToProps)(Login)
