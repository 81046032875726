import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DateConstants from 'rc-calendar/lib/date/DateConstants'
import { getTitleString, getTodayTime } from 'rc-calendar/lib/util'

function isSameDay (one, two) {
  return one && two && one.isSame(two, 'day')
}

function beforeCurrentMonthYear (current, today) {
  if (current.year() < today.year()) {
    return 1
  }
  return current.year() === today.year() &&
    current.month() < today.month()
}

function afterCurrentMonthYear (current, today) {
  if (current.year() > today.year()) {
    return 1
  }
  return current.year() === today.year() &&
    current.month() > today.month()
}

function getIdFromDate (date) {
  return `rc-calendar-${date.year()}-${date.month()}-${date.date()}`
}

class DateTBody extends Component {
  static propTypes = {
    contentRender: PropTypes.func,
    dateRender: PropTypes.func,
    disabledDate: PropTypes.func,
    prefixCls: PropTypes.string,
    selectedValue: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
    value: PropTypes.object,
    hoverValue: PropTypes.any,
    showWeekNumber: PropTypes.bool,
    single: PropTypes.bool
  }

  static defaultProps = {
    hoverValue: []
  }

  render () {
    const {
      contentRender, prefixCls, selectedValue, value,
      showWeekNumber, dateRender, disabledDate,
      hoverValue
    } = this.props
    const props = this.props
    let iIndex
    let jIndex
    let current
    const dateTable = []
    const today = getTodayTime(value)
    const cellClass = 'calendar__item'
    const weekNumberCellClass = `${prefixCls}-week-number-cell`
    const todayClass = this.props.single ? '' : ` ${prefixCls}-today`
    const selectedClass = this.props.single ? `${prefixCls}-today` : 'calendar__cell--range'
    const selectedDateClass = 'calendar__item--mark' // do not move with mouse operation
    const inRangeClass = 'calendar__cell--range'
    const lastMonthDayClass = 'calendar__item--prev-month'
    const nextMonthDayClass = 'calendar__item--next-month'
    const disabledClass = `${prefixCls}-disabled-cell`
    const firstDisableClass = `${prefixCls}-disabled-cell-first-of-row`
    const lastDisableClass = `${prefixCls}-disabled-cell-last-of-row`
    const month1 = value.clone()
    month1.date(1)
    const day = month1.day()
    const lastMonthDiffDay = (day + 7 - value.localeData().firstDayOfWeek()) % 7
    // calculate last month
    const lastMonth1 = month1.clone()
    lastMonth1.add(0 - lastMonthDiffDay, 'days')
    let passed = 0

    for (iIndex = 0; iIndex < DateConstants.DATE_ROW_COUNT; iIndex++) {
      for (jIndex = 0; jIndex < DateConstants.DATE_COL_COUNT; jIndex++) {
        current = lastMonth1
        if (passed) {
          current = current.clone()
          current.add(passed, 'days')
        }
        dateTable.push(current)
        passed++
      }
    }

    const tableHtml = []
    passed = 0

    for (iIndex = 0; iIndex < DateConstants.DATE_ROW_COUNT; iIndex++) {
      let weekNumberCell
      const dateCells = []

      if (showWeekNumber) {
        weekNumberCell = (
          <td
            key={dateTable[passed].week()}
            role='gridcell'
            className={weekNumberCellClass}
          >
            {dateTable[passed].week()}
          </td>
        )
      }

      for (jIndex = 0; jIndex < DateConstants.DATE_COL_COUNT; jIndex++) {
        let next = null
        let last = null
        current = dateTable[passed]
        if (jIndex < DateConstants.DATE_COL_COUNT - 1) {
          next = dateTable[passed + 1]
        }
        if (jIndex > 0) {
          last = dateTable[passed - 1]
        }
        let cls = cellClass
        let disabled = false
        let selected = false

        if (isSameDay(current, today)) {
          cls += ` ${todayClass}`
        }

        const isBeforeCurrentMonthYear = beforeCurrentMonthYear(current, value)
        const isAfterCurrentMonthYear = afterCurrentMonthYear(current, value)

        if (selectedValue && Array.isArray(selectedValue)) {
          const rangeValue = hoverValue.length ? hoverValue : selectedValue
          if (!isBeforeCurrentMonthYear && !isAfterCurrentMonthYear) {
            const startValue = rangeValue[0]
            const endValue = rangeValue[1]
            if (startValue) {
              if (isSameDay(current, startValue)) {
                selected = true
              }
            }
            if (startValue && endValue) {
              if (isSameDay(current, endValue)) {
                selected = true
              } else if (current.isAfter(startValue, 'day') &&
                current.isBefore(endValue, 'day')) {
                cls += ` ${inRangeClass}`
              }
            }
          }
        } else if (isSameDay(current, value)) {
          // keyboard change value, highlight works
          selected = true
        }

        if (isSameDay(current, selectedValue)) {
          cls += ` ${selectedDateClass}`
        }

        if (isBeforeCurrentMonthYear) {
          cls += ` ${lastMonthDayClass}`
        }
        if (isAfterCurrentMonthYear) {
          cls += ` ${nextMonthDayClass}`
        }

        if (disabledDate) {
          if (disabledDate(current, value)) {
            disabled = true

            if (!last || !disabledDate(last, value)) {
              cls += ` ${firstDisableClass}`
            }

            if (!next || !disabledDate(next, value)) {
              cls += ` ${lastDisableClass}`
            }
          }
        }

        if (selected) {
          cls += ` ${selectedClass}`
        }

        if (disabled) {
          cls += ` ${disabledClass}`
        }

        let dateHtml
        if (dateRender) {
          dateHtml = dateRender(current, value)
        } else {
          const content = contentRender ? contentRender(current, value) : current.date()
          dateHtml = (
            <div
              key={getIdFromDate(current)}
              aria-selected={selected}
              aria-disabled={disabled}
              className={cls}
            >
              {content}
            </div>)
        }

        dateCells.push(
          <td
            key={passed}
            className='calendar__cell'
            title={getTitleString(current)}
            onMouseEnter={
              disabled
                ? undefined
                : (props.onDayHover && props.onDayHover.bind(null, current)) || undefined
            }
            onClick={disabled ? undefined : props.onSelect.bind(null, current)}
          >
            {dateHtml}
          </td>
        )

        passed++
      }

      tableHtml.push(
        <tr
          key={iIndex}
          className='calendar__row'
        >
          {weekNumberCell}
          {dateCells}
        </tr>)
    }

    return <tbody>{tableHtml}</tbody>
  }
}

export default DateTBody
