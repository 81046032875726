// @flow

import { lazy } from 'react'

export const apartment = lazy(() =>
  import('../../../../static/icons/apartment-refined.svg')
)
export const automate = lazy(() =>
  import('../../../../static/icons/automate-refined.svg')
)
export const building = lazy(() =>
  import('../../../../static/icons/building-refined.svg')
)
export const chat = lazy(() =>
  import('../../../../static/icons/chat-refined.svg')
)
export const checklist = lazy(() =>
  import('../../../../static/icons/checklist-refined.svg')
)
export const company = lazy(() =>
  import('../../../../static/icons/company.svg')
)
export const connect = lazy(() =>
  import('../../../../static/icons/connect-refined.svg')
)
export const email = lazy(() => import('../../../../static/icons/email.svg'))
export const faq = lazy(() => import('../../../../static/icons/faq-thin.svg'))
export const file = lazy(() =>
  import('../../../../static/icons/folder-refined.svg')
)
export const offer = lazy(() =>
  import('../../../../static/icons/offer-refined.svg')
)
export const order = lazy(() =>
  import('../../../../static/icons/order-refined.svg')
)
export const post = lazy(() =>
  import('../../../../static/icons/post-refined.svg')
)
export const provider = lazy(() =>
  import('../../../../static/icons/provider-refined.svg')
)
export const request = lazy(() =>
  import('../../../../static/icons/request-refined.svg')
)
export const review = lazy(() =>
  import('../../../../static/icons/review-refined.svg')
)
export const updates = lazy(() =>
  import('../../../../static/icons/updates.svg')
)
export const user = lazy(() =>
  import('../../../../static/icons/user-refined.svg')
)
export const folder = lazy(() => import('../../../../static/icons/folder.svg'))
export const templates = lazy(() =>
  import('../../../../static/icons/templates.svg')
)
export const activity = lazy(() =>
  import('../../../../static/icons/activities/add-activity.svg')
)
export const workflow = lazy(() =>
  import('../../../../static/icons/constructor.svg')
)
