// @flow

export const isDefaultOption = (defaultOption, option) => {
  const { value: defaultvalue } = defaultOption
  const { value } = option

  return value === defaultvalue
}

export const getOption = (requestOptions, markRequest) =>
  requestOptions.find(({ value }) => value === markRequest)
