// @flow

import React from 'react'
import { useLocation } from 'react-router-dom'
import type { Node } from 'react'

import { useSortingHandler } from '../../../hooks'
import { getKeySorting } from '../../../utils/routing'
import Button from '../../Button'
import Text from './Text'

import styles from '../NewTable.module.scss'

type Props = {
  children?: Node,
  className?: string,
  location?: string,
  onSort?: string => void,
  sortKey?: string,
  style?: Object,
  title?: string,
  working?: boolean,
}

const NewTableHeaderCell = (props: Props): Node => {
  const { sortKey, working, title, style, className } = props

  const handleSort = useSortingHandler()
  const location = useLocation()

  const handleClick = () => {
    if (props.onSort) {
      props.onSort(sortKey)
    } else {
      handleSort(sortKey)
    }
  }

  const thProps = Object.assign(
    {},
    sortKey ? { onClick: handleClick } : undefined
  )

  return (
    <th style={style} className={className} {...thProps}>
      <div className={styles.headerCellContent}>
        {title && <Text title={title} />}
        {props.children}
        {sortKey && (
          <Button.Sort
            sorting={getKeySorting(sortKey, props.location || location)}
            labelKey={sortKey}
            working={working}
          />
        )}
      </div>
    </th>
  )
}

export default NewTableHeaderCell
