// @flow

import React from 'react'
import type { Node } from 'react'

type Props = {
  children: ?Node,
  onClose: () => void,
}

const Balloon = ({ children, onClose }: Props) => (
  <div className='map-balloon'>
    <button
      className='modal__close'
      onClick={onClose}
    />
    {children}
    <div className='ballon-arrow' />
  </div>
)

export default Balloon
