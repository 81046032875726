// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'

import Chip from '../../../../Chip'
import { EntityIcon } from '../../../../Icon'

import styles from './ActiveRequests.module.scss'

const ActiveRequests = props => {
  const { requests } = props

  const { t } = useTranslation('Request')

  const getRequestLabel = option => {
    return `${t('Request:request_number_title')}${option.request_no}: ${
      option.title
    }`
  }

  return (
    <div className={styles.requests}>
      {requests.map(request => (
        <Chip
          text={getRequestLabel(request)}
          key={request.id || request.value}
          className={styles.chip}
          onClose={() => {
            props.changeSelected(request)
          }}
        >
          <div className={styles.iconBounding}>
            <EntityIcon id='request' className={styles.icon} />
          </div>
        </Chip>
      ))}
    </div>
  )
}

export default ActiveRequests
