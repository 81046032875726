// @flow

import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import EditingRow from './EditingRow'
import Button from '../../Button'
import { canEditFlat } from './FlatAddress.utils'

const FlatAddressEditing = props => {
  const {
    address: { value },
    saving,
    number,
    floor,
    entrance,
    flat,
  } = props

  const { t } = useTranslation('Flat')

  return (
    <div>
      <h3 className='apartment-profile__title-2'>
        <span className='apartment-profile__title-2-high'>{value}</span>
      </h3>
      <div style={{ marginTop: '1.5rem' }}>
        <EditingRow
          inputProps={{
            autoFocus: true,
            name: 'number',
            value: number,
            onChange: props.updateField,
          }}
          label='NumberLabel'
        />
        {canEditFlat(flat) && (
          <Fragment>
            <EditingRow
              disabled
              inputProps={{
                negative: true,
                name: 'floor',
                value: floor,
                maxLength: 64,
                onChange: props.updateField,
              }}
              label='FloorLabel'
            />
            <EditingRow
              disabled
              inputProps={{
                name: 'entrance',
                value: entrance,
                onChange: props.updateField,
              }}
              label='EntranceLabel'
            />
          </Fragment>
        )}
        <div className='apartment-create__submit'>
          <Button.Save
            blank
            disabled={!props.canSave()}
            working={saving}
            onClick={props.save}
          >
            {t('Save')}
          </Button.Save>
          <Button.Cancel onClick={props.cancel}>{t('Cancel')}</Button.Cancel>
        </div>
      </div>
    </div>
  )
}

export default FlatAddressEditing
