// @flow

import { getFullDateFormat } from '../../../utils/utils'

export const groupActivities = (data: Array<Object>): Array<Array> =>
  data.reduce((acc, el) => {
    const date = getFullDateFormat(el.timestamp)
    const dayList = acc[date]

    acc[date] = dayList ? dayList.concat(el) : [el]

    return acc
  }, {})
