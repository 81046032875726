// @flow

import * as actions from './PostBody.actionTypes'

const initialState = {
  edit: false,
  saving: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.POST_BODY_INIT:
      return {
        ...initialState,
      }
    case actions.POST_BODY_EDIT:
      return {
        ...state,
        edit: true,
      }
    case actions.POST_BODY_CANCEL:
      return {
        ...state,
        edit: false,
      }
    case actions.POST_BODY_SAVE:
      return {
        ...state,
        saving: true,
      }
    case actions.POST_BODY_SAVED:
      return {
        ...state,
        saving: false,
        edit: false,
      }
    default:
      return state
  }
}
