// @flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import RouterTabs from '../Tabs/RouterTabs'
import Pan from '../Tabs/Pan'
import { SimpleBlock, Header } from '../Block'

import AutomateMain from './AutomateMain'
import AutomateHistory from './AutomateHistory'

const Automate = () => {
  const { t } = useTranslation('Automate')
  const [count, setCount] = useState(0)
  const [logs, setLogs] = useState(0)

  return (
    <div className='content__col'>
      <SimpleBlock>
        <Header header className='mail-section__title'>
          {t('Title')}
        </Header>
        <RouterTabs openFirst='automations'>
          <Pan
            id='automations'
            label={`${t('Title')} ${count || ''}`}
            linkHash='#automations'
          >
            <AutomateMain setCount={setCount} />
          </Pan>
          <Pan
            id='history'
            label={`${t('AutomationHistory')} ${logs || ''}`}
            linkHash='#history'
          >
            <AutomateHistory setCount={setLogs} />
          </Pan>
        </RouterTabs>
      </SimpleBlock>
    </div>
  )
}

export default Automate
