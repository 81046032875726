// @flow

import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'

import EmptyList from '../../../components/EmptyList'

import { isGroupsEmpty, getPermissions } from '../Faq.selectors'
import { showModal } from '../Faq.actions'
import { CREATE_POPUP } from '../Faq.constants'

type Props = {
  isGroupsEmpty: boolean,
  showModal: () => void,
  t: string => string,
}

class Empty extends PureComponent<Props> {
  showModal = () => {
    this.props.showModal(CREATE_POPUP)
  }

  render() {
    const { isGroupsEmpty, permissions } = this.props

    return (
      !isGroupsEmpty && (
        <EmptyList
          btnText={this.props.t('add_group')}
          canAdd={permissions.can_create}
          icon='faq'
          title={this.props.t('groups_list_is_empty')}
          onClick={this.showModal}
        />
      )
    )
  }
}

const mapStateToProps = state => ({
  isGroupsEmpty: isGroupsEmpty(state),
  permissions: getPermissions(state),
})
const mapDispatchToProps = {
  showModal,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('FAQ')
)(Empty)
