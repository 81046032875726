// @flow

import { createContext } from 'react'

const ListItemContext = createContext({
  labelsHeight: 0,
  titleHeight: 0,
})

export default ListItemContext
