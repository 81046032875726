// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import { isArray } from 'lodash-es'
import classNames from 'classnames'

import ErrorItem from './ErrorItem'

type Props = {
  className?: string,
  message?: string | Array<string>,
  needTranslate: boolean,
}

const InputValidation = (props: Props) => {
  const { message, className, needTranslate } = props
  const { t } = useTranslation('Errors')

  const inputClass = classNames('u-a14__row u-a14__row--01', className)

  const translate = needTranslate ? t : text => text

  return (
    <div className={inputClass} style={{ flexDirection: 'column' }}>
      {isArray(message) ? (
        message.map((text, i) => <ErrorItem text={translate(text)} key={i} />)
      ) : (
        <ErrorItem text={translate(message) || t('Required')} />
      )}
    </div>
  )
}

InputValidation.defaultProps = {
  needTranslate: true,
}

export default InputValidation
