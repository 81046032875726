// @flow

import React from 'react'
import type { Node } from 'react'

import ModalRow from '../ModalRow'

import styles from './ModalError.module.scss'

type Props = { error: string }

const ModalError = (props: Props): Node => {
  const { error, style } = props

  return (
    <ModalRow style={style}>
      <span className={styles.error}>{error}</span>
    </ModalRow>
  )
}

export default ModalError
