// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import ModalText from '../../../Modal/ModalText'

import styles from './BotCard.module.scss'

import Icon from '../../../../../static/icons/wizard.svg'

const BotCard = (): Node => {
  const { t } = useTranslation('WizardPopup')

  return (
    <div className={styles.card}>
      <Icon />
      <ModalText text={t('Idwellbot')} className={styles.text} />
    </div>
  )
}

export default BotCard
