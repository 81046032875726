// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import NewTable from '../../NewTable'
import NewTableBody from '../../NewTable/NewTableBody'
import StatusTableRow from './StatusTableRow'

type Props = { onEdit: Function }

const StatusTable = (props: Props): Node => {
  const { t } = useTranslation('Profile')

  const options = [
    {
      value: 'editStatus',
      label: t('EditStatus'),
      onClick: props.onEdit,
      icon: 'pencil',
    },
  ]

  return (
    <NewTable>
      <NewTableBody>
        <StatusTableRow options={options} onEdit={props.onEdit} />
      </NewTableBody>
    </NewTable>
  )
}

export default StatusTable
