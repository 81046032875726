// @flow

import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Header, SimpleBlock } from '../../../Block'

import type { Node } from 'react'
import type { IOragDetails } from '../BuildingInformation'
import type { IModalName } from '../BuildingInformation'

import Icon from '../../../Icon'
import SelectCustom from '../../../Select/SelectCustom'
import OragInfoForm from './OragInfoForm'

import styles from './BuildingInformationForms.module.scss'
import Notes from '../../../Notes'

type Props = {
  oragDetails: IOragDetails,
  setCurrentNoticeModal: (modalName: IModalName) => void,
  updateOragDetails: (data: {
    oragDetails: IOragDetails,
  }) => Promise<IOragDetails>,
}

const OragInfo = ({
  oragDetails,
  oragDetails: { notes },
  updateOragDetails,
  setCurrentNoticeModal,
}: Props): Node => {
  const { t } = useTranslation('Building')

  const [isEdit, setIsEdit] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (!isOpen) {
      setIsEdit(false)
    }
  }, [isOpen])

  const handleEditOpen = () => {
    setIsEdit(true)
    setIsOpen(true)
  }
  const handleEditClose = () => setIsEdit(false)

  const options = [
    {
      value: 'addNotice',
      label: t('AddNotice'),
      onClick: () => setCurrentNoticeModal('orag_cluster'),
      icon: 'pencil',
    },
  ]

  return (
    <SimpleBlock style={{ marginTop: 0 }}>
      <Header overflowVisible header>
        <div className={styles.profileWrap}>
          <div>
            {t('OragInfo')}
            {!isEdit && (
              <Icon
                colorOnHover
                style={{ width: 16, height: 16, paddingLeft: '8px' }}
                id='pencil'
                onClick={handleEditOpen}
              />
            )}
          </div>
          <div className={styles.profileOpenIcon}>
            <Icon
              style={{ fill: 'transparent' }}
              id={isOpen ? 'openSection' : 'closedSection'}
              onClick={() => setIsOpen(isOpen => !isOpen)}
            />
            <SelectCustom options={options} onChange={opt => opt.onClick()} />
          </div>
        </div>
      </Header>
      <OragInfoForm
        isOpen={isOpen}
        initialValues={oragDetails}
        active={isEdit}
        handleEditClose={handleEditClose}
        updateOragDetails={updateOragDetails}
      />
      <Notes notes={notes && notes.trim()} />
    </SimpleBlock>
  )
}

export default OragInfo
