// @flow

import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'

import Icon from '../../Icon'
import { BUILDING_TYPE } from '../Post.constants'

import styles from './AudienceItem.module.scss'

const AudienceItem = props => {
  const { t } = useTranslation('Post')

  const {
    item: { text, id, type, avatar, iconId, isGroup },
    automate,
    editing,
  } = props

  const handleClick = e => {
    e.preventDefault()

    props.handleClick(id, type)
  }

  const spanClass = classnames({
    [styles.automateSpan]: automate,
  })

  const renderContent = () => (
    <Fragment>
      <div className={styles.avatar}>
        {avatar ? (
          <img src={avatar} alt={text} />
        ) : (
          <Icon colored id={iconId} />
        )}
      </div>
      <span className={spanClass}>
        {type === BUILDING_TYPE ? text : t(text)}
      </span>
      {props.handleClick && editing && (
        <Icon id='cross' className={styles.icon} onClick={handleClick} />
      )}
    </Fragment>
  )

  if (typeof id === 'number') {
    const className = classnames(styles.label, styles.link)

    return (
      <Link
        to={isGroup ? `/building-group/${id}` : `/building/${id}`}
        className={className}
      >
        {renderContent()}
      </Link>
    )
  }

  return <div className={styles.label}>{renderContent()}</div>
}

export default AudienceItem
