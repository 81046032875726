// @flow

import {
  USER_LIST_LOAD,
  USER_LIST_INITIATING,
  USER_LIST_CLEANUP,
} from '../User/UserList/UserList.actionTypes'
import { USER_LIST_INFO_CLEANUP } from '../User/UserListInfo/UserListInfo.actionTypes'
import type { Action } from './Users'

export function load(params: Object, search): Action {
  return {
    type: USER_LIST_LOAD,
    params,
    search,
  }
}

export function init(params: Object, search): Action {
  return {
    type: USER_LIST_INITIATING,
    params,
    search,
  }
}

export function cleanUp(): Action {
  return {
    type: USER_LIST_CLEANUP,
  }
}

export function infoCleanUp(): Action {
  return {
    type: USER_LIST_INFO_CLEANUP,
  }
}
