// @flow

import React, { Component } from 'react'
import classnames from 'classnames'
import { withTranslation } from 'react-i18next'

import Button from '../Button'
import Error from '../forms/InputValidation'
import { FILE_ACCEPT_TYPES } from '../../containers/FilesUpload'
import {
  HTML_IDS,
  FALLBACK_IMAGE_IDS,
  ORIGIN_TYPES,
  MAX_SIZE,
  MIN_DIMENSIONS,
} from './CompanyImage.constants'
import NewFilesUpload from '../NewFilesUpload'

import styles from './CompanyImage.module.scss'

type Props = {
  fallback?: string,
  file: Object,
  onFinishUpload: (Array<Object>) => void,
  onRemove: () => void,
  onStartUpload: () => void,
  t: string => string,
  thumbnailSize: number,
  type: 'background' | 'mobile' | 'web',
  useBase64?: boolean,
  working: boolean,
}
type State = {
  errors: Array<string>,
}

class CompanyImage extends Component<Props, State> {
  static defaultProps = {
    thumbnailSize: 340,
  }

  state = {
    errors: [],
  }

  showUploadErrors = (errors: Array<string>) => {
    this.setState(state => ({
      errors: state.errors.concat(errors),
    }))
  }

  startUpload = (errors: Array<string>) => {
    this.setState({ errors })

    this.props.onStartUpload()
  }

  render() {
    const { fallback, file, thumbnailSize, type, useBase64 } = this.props
    const { errors } = this.state

    const canRemove =
      fallback &&
      FALLBACK_IMAGE_IDS[type] !== file.id &&
      (file.id || file.dataUrl)

    const minDimensions = MIN_DIMENSIONS[type] || {}

    const src = file.thumbnails
      ? file.thumbnails[thumbnailSize] || file.origin
      : file.origin || file.dataUrl

    const previewClass = classnames(styles.preview, {
      [styles.interactive]: canRemove,
    })

    return (
      <div className={styles.logo} id={HTML_IDS[type]}>
        <NewFilesUpload
          useBase64={useBase64}
          accept={FILE_ACCEPT_TYPES.rasterImages}
          className={styles.drop}
          maxSize={MAX_SIZE[type]}
          minHeight={minDimensions.height}
          minWidth={minDimensions.width}
          originType={ORIGIN_TYPES[type]}
          onFinishUpload={this.props.onFinishUpload}
          onStartUpload={this.startUpload}
          onUploadErrors={this.showUploadErrors}
        >
          <div>
            <div className='input input--large input--block input--default input--success'>
              <input disabled name='icon-file' value={file.filename} />
            </div>
            <Button.Upload>
              {this.props.t('ChooseFileButtonTitle')}
            </Button.Upload>
          </div>
        </NewFilesUpload>

        {!!errors &&
          !!errors.length &&
          errors.map((err, i) => (
            <Error
              needTranslate={false}
              className={styles.error}
              key={`file-error-${i}`}
              message={err}
            />
          ))}

        <div className={previewClass}>
          <img src={src} className='image-preview-img' />
          {canRemove && (
            <Button.Close
              large
              className='modal__close-wrapper'
              onClick={this.props.onRemove}
            />
          )}
        </div>
      </div>
    )
  }
}

export default withTranslation('Common')(CompanyImage)
