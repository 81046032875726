// @flow

import React from 'react'
import type { Node } from 'react'

import Toolbox from '../../Toolbox'
import BudgetPlanForm from '../BudgetPlanForm'

import styles from './BudgetPlanToolbox.module.scss'

type Props = { plan?: Object, reload?: Function, requestId?: string }

const BudgetPlanToolbox = (props: Props): Node => {
  const { plan, requestId } = props

  return (
    <Toolbox className={styles.toolbox}>
      <div className={styles['toolbox-row']}>
        <BudgetPlanForm
          plan={plan}
          requestId={requestId}
          reload={props.reload}
        />
      </div>
    </Toolbox>
  )
}

export default BudgetPlanToolbox
