// @flow

import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import AuthPage from '../AuthPage'
import EmailConfirmError from './EmailConfirmError'
import { confirmEmail } from '../../../core/api/api.system'
import EmailConfirmSuccess from './EmailConfirmSuccess'

const EmailConfirm = () => {
  const { hash } = useParams()
  const [isVerifiedHash, setVerifiedHash] = useState(false)
  const [email, setEmail] = useState('')

  const [working, setWorking] = useState(false)

  useEffect(() => {
    if (hash) {
      setWorking(true)

      confirmEmail(hash)
        .then((data: Object) => {
          if (data.error) {
            setVerifiedHash(false)
          } else {
            setVerifiedHash(true)
            setEmail(data.email)
          }
        })
        .catch(() => setVerifiedHash(false))
        .finally(() => setWorking(false))
    }
  }, [hash])

  return (
    <AuthPage working={working}>
      {isVerifiedHash && hash ? (
        <EmailConfirmSuccess email={email} />
      ) : (
        !working && <EmailConfirmError />
      )}
    </AuthPage>
  )
}

export default EmailConfirm
