// @flow

import type { Node } from 'react'
import type { IInsurance } from '../BuildingInformation'
import React from 'react'
import setFormikError from '../../../../utils/setFormikError'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

import FieldRow from '../../../FieldRow'
import styles from './BuildingInformationForms.module.scss'
import Button from '../../../Button'

import * as Yup from 'yup'
import NewSelectAsync from '../../../NewSelectAsync'

import {
  TYPE_OF_INSURANCE,
  CONTRACT_NUMBER,
  SUPPLIER,
  MAX_CONTACT_NUMBER_LENGTH,
} from './BuildingInformationInsurace.constants'

import { getSuppliers } from '../../../../core/api/api.budgetPlan'
import { getInsuranceType } from '../../../../core/api/api.insurance'

type Props = {
  active: boolean,
  handleEditClose: () => void,
  initialValues: IInsurance,
  isOpen: boolean,
  updateInsurance: (data: { insurance: IInsurance }) => Promise<IInsurance>,
}

const InsuranceForm = ({
  initialValues,
  active,
  handleEditClose,
  updateInsurance,
}: Props): Node => {
  const { t } = useTranslation('Building')
  const onSubmit = values => {
    const insurance = {
      ...values,
      [TYPE_OF_INSURANCE]: values[TYPE_OF_INSURANCE]?.id || null,
      [SUPPLIER]: values[SUPPLIER]?.owner_id || values[SUPPLIER]?.id || null,
    }

    updateInsurance({ insurance })
      .then(() => handleEditClose())
      .catch(error => setFormikError(error, setFieldError))
  }
  const onClosed = () => {
    handleEditClose()
    resetForm()
  }

  const {
    values,
    errors,
    dirty,
    resetForm,
    isSubmitting,
    setFieldError,
    handleSubmit,
    handleChange,
    setFieldValue,
  } = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      [TYPE_OF_INSURANCE]: Yup.object({
        id: Yup.number().nullable(),
        value: Yup.string().nullable(),
      }).nullable(),
      [CONTRACT_NUMBER]: Yup.string().max(MAX_CONTACT_NUMBER_LENGTH),
      [SUPPLIER]: Yup.object({
        id: Yup.number().nullable(),
        fullname: Yup.string().nullable(),
      }).nullable(),
    }),
    onSubmit,
  })
  const handleSelectTypeOfInsurance = option => {
    setFieldValue(TYPE_OF_INSURANCE, option)
  }
  const handleClearTypeOfInsurance = () => {
    setFieldValue(TYPE_OF_INSURANCE, null)
  }
  const handleSelectSupplier = option => {
    setFieldValue(SUPPLIER, option)
  }
  const handleClearSupplier = () => {
    setFieldValue(SUPPLIER, null)
  }

  return (
    <form
      name='building_insurance'
      onSubmit={handleSubmit}
      onChange={handleChange}
    >
      <FieldRow
        value={values[TYPE_OF_INSURANCE]?.value}
        label={'TypeOfInsurance'}
        error={errors[TYPE_OF_INSURANCE]}
        active={active}
        type='select'
        handleClear={handleClearTypeOfInsurance}
      >
        <NewSelectAsync
          pageSize={10}
          name={TYPE_OF_INSURANCE}
          api={getInsuranceType}
          searchKey='search'
          placeholder={t('SelectTypeOfInsurance')}
          view='dropdown'
          getLabel={a => a?.value}
          getSelectedLabel={a => a?.value}
          selectedItems={
            values[TYPE_OF_INSURANCE]?.value ? [values[TYPE_OF_INSURANCE]] : []
          }
          className={styles.select}
          onClick={handleSelectTypeOfInsurance}
        />
      </FieldRow>
      <FieldRow
        name={CONTRACT_NUMBER}
        active={active}
        label={'ContractNumber'}
        error={errors[CONTRACT_NUMBER]}
        value={values[CONTRACT_NUMBER]}
        placeholder={'EnterContractNumber'}
        maxLength={MAX_CONTACT_NUMBER_LENGTH}
        handleChange={handleChange}
      />

      <FieldRow
        value={values[SUPPLIER]?.fullname}
        label={'Supplier'}
        error={errors[SUPPLIER]}
        active={active}
        type='select'
        handleClear={handleClearSupplier}
      >
        <NewSelectAsync
          pageSize={10}
          name={SUPPLIER}
          idField='owner_id'
          api={getSuppliers}
          searchKey='search'
          placeholder={t('SelectSupplier')}
          view='dropdown'
          getLabel={a => a?.fullname}
          getSelectedLabel={a => a?.fullname}
          selectedItems={values[SUPPLIER]?.fullname ? [values[SUPPLIER]] : []}
          className={styles.select}
          onClick={handleSelectSupplier}
        />
      </FieldRow>
      {active && (
        <div className={styles.buttonWrapper}>
          <Button.Save disabled={!dirty || isSubmitting} type='submit'>
            {t('Common:Save')}
          </Button.Save>
          <Button.Cancel onClick={onClosed}>{t('Common:Cancel')}</Button.Cancel>
        </div>
      )}
    </form>
  )
}

export default InsuranceForm
