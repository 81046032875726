// @flow

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { intersection, map } from 'lodash-es'
import update from 'immutability-helper'

import Category from './Category'
import Button from '../../Button'
import UnmatchedBox from './UnmatchedBox'

type Props = {
  copying: boolean,
  error?: ?string,
  items: Object,
  itemsWereCopied: boolean,
  onClose: () => void,
  onCopyTypes: () => void,
  onSave: Object => void,
  t: string => string,
  working: boolean,
}

type State = {
  canSave: boolean,
  categories: Array<Object>,
  unmatched: Array<string>,
}

class MatchingRealDataRequests extends Component<Props, State> {
  getDeserializedCategories = items =>
    map(items, (names, key) => ({ key, names }))
      .filter(c => c.key !== 'unmapped')
      .sort((a, b) => (a.key.toLowerCase() < b.key.toLowerCase() ? -1 : 1))

  state = {
    canSave: false,
    categories: this.getDeserializedCategories(this.props.items),
    unmatched: this.props.items.unmapped,
  }

  componentDidUpdate(prevProps) {
    const { working, copying, onClose } = prevProps

    if (working && !this.props.working && !this.props.error) {
      onClose()
    }

    if (copying && !this.props.copying && !this.props.error) {
      this.setState({
        categories: this.getDeserializedCategories(this.props.items),
      })
    }
  }

  getSerializedItems = () => {
    const { categories, unmatched } = this.state
    const items = []
    for (const c of categories) {
      items[c.key] = c.names
    }

    return { ...items, unmapped: unmatched }
  }

  save = () => {
    const { onSave } = this.props
    onSave(this.getSerializedItems())
  }

  copyTypes = () => {
    const { onCopyTypes } = this.props
    onCopyTypes(this.getSerializedItems())
  }

  removeFromCategory = (name, index, callback = null) => {
    const { categories } = this.state
    this.setState(
      {
        categories: update(categories, {
          [index]: {
            $set: {
              key: categories[index].key,
              names: categories[index].names.filter(n => n !== name),
            },
          },
        }),
      },
      callback
    )
  }

  addToCategory = (name, index, callback = null) => {
    const { categories } = this.state
    this.setState(
      {
        categories: update(categories, {
          [index]: {
            $set: {
              key: categories[index].key,
              names: [name, ...categories[index].names],
            },
          },
        }),
      },
      callback
    )
  }

  moveToCategory = (name, index) => {
    const { categories, unmatched } = this.state

    const from = categories.findIndex(c => c.names.includes(name))
    const callback = () => {
      this.addToCategory(name, index, this.checkCategoriesUpdates)
    }

    if (from >= 0) {
      this.removeFromCategory(name, from, callback)
    } else {
      this.setState(
        {
          unmatched: unmatched.filter(n => n !== name),
        },
        callback
      )
    }
  }

  checkCategoriesUpdates = () => {
    const { items } = this.props
    const { categories, unmatched } = this.state

    if (unmatched.length !== items.unmapped.length) {
      this.setState({ canSave: true })

      return
    }

    for (const c of categories) {
      const names = items[c.key]
      const common = intersection(c.names, names)

      const updated =
        c.names.length !== names.length || common.length !== names.length

      if (updated) {
        this.setState({ canSave: true })

        return
      }
    }
    this.setState({ canSave: false })
  }

  addToUnmatched = (name, key) => {
    const { unmatched, categories } = this.state

    const index = categories.findIndex(c => c.key === key)
    this.removeFromCategory(name, index)

    this.setState(
      {
        unmatched: [...unmatched, name],
      },
      this.checkCategoriesUpdates
    )
  }

  renderUnmatchedActions = () => {
    const { items, itemsWereCopied, copying, t } = this.props
    const { unmatched } = this.state

    return (
      <div>
        {!items.unmapped.length && !unmatched.length && (
          <div className='modal__mapping-categories--no-types modal__text'>
            {t('NoTypes')}
          </div>
        )}
        {!itemsWereCopied && (
          <Button.Save working={copying} onClick={this.copyTypes}>
            {t('ToCopyFromRealData')}
          </Button.Save>
        )}
      </div>
    )
  }

  render() {
    const { items, onClose, working, error, t } = this.props
    const { unmatched, categories, canSave } = this.state

    return (
      <DndProvider backend={HTML5Backend}>
        <div className='modal__mapping-categories'>
          <button className='modal__close' type='button' onClick={onClose} />
          <div className='modal__title'>{t('MappingTagsAndCategories')}</div>
          <div className='modal__text'>{t('MappingTagsAndCategoriesText')}</div>
          {items.unmapped.length > 0 && (
            <div className='modal__mapping-categories--new-types modal__text'>
              {t('NewTypes')}
            </div>
          )}
          <div className='modal__mapping-categories--title'>
            <div>IDWELL:</div>
            <div>REAL DATA:</div>
          </div>
          <hr />
          <div className='modal__mapping-categories--table'>
            {working && (
              <div className='modal__mapping-categories--table--overlay' />
            )}
            <UnmatchedBox
              items={unmatched}
              renderActions={this.renderUnmatchedActions}
              onAdd={this.addToUnmatched}
            />
            <div className='modal__mapping-categories--table-col modal__mapping-categories--table-col-2'>
              {categories.map(({ key, names }, index) => (
                <Category
                  key={key}
                  name={key}
                  items={names}
                  onAdd={name => this.moveToCategory(name, index)}
                />
              ))}
            </div>
          </div>
          <hr />
          {unmatched.length ? (
            <div className='modal__mapping-categories--mapping modal__mapping-categories--mapping-not-map modal__text'>
              {t('TypesRequestsIsNotMapped')}
            </div>
          ) : (
            <div className='modal__mapping-categories--mapping modal__mapping-categories--mapping-map modal__text'>
              {t('TypesRequestsIsMapped')}
            </div>
          )}
          {!!error && <div className='error-text'>{error}</div>}
          <div style={{ marginTop: 15 }}>
            <Button.Save
              disabled={!canSave}
              working={working}
              onClick={this.save}
            >
              {t('Common:Save')}
            </Button.Save>
            <Button.Cancel onClick={onClose}>
              {t('Common:Cancel')}
            </Button.Cancel>
          </div>
        </div>
      </DndProvider>
    )
  }
}

export default withTranslation('MappingTagsAndCategoriesPopup')(
  MatchingRealDataRequests
)
