// @flow

import { all, put, call, fork, takeLatest, select } from 'redux-saga/effects'
import { last } from 'lodash-es'
import type { Saga } from 'redux-saga'

import { NUMBER_OF_POSTS_PER_REQUEST } from '../../constants'
import api from '../../core/api'
import { serverError } from '../../components/Layout/Layout.actions'
import * as actions from './NewsList.actions'
import * as selectors from './NewsList.selectors'

function* watchInitialLoad() {
  yield takeLatest(actions.NEWS_LIST_INITIATING, loadInitialPosts)
}

function* watchLoadMore() {
  yield takeLatest(actions.NEWS_LIST_LOAD_MORE_INITIATING, loadMorePosts)
}

function* watchBlockPost() {
  yield takeLatest(actions.NEWS_LIST_BLOCK_POST_INITIATING, blockPost)
}

function* watchBlockUser() {
  yield takeLatest(actions.NEWS_LIST_BLOCK_USER_INITIATING, blockUser)
}

function* watchPinPost() {
  yield takeLatest(actions.NEWS_LIST_PIN_POST_INITIATING, pinPost)
}

function* watchReadPost() {
  yield takeLatest(actions.NEWS_LIST_READ_POST, readPost)
}

function* watchUnreadPost() {
  yield takeLatest(actions.NEWS_LIST_UNREAD_POST, unreadPost)
}

function* loadInitialPosts({ params }) {
  try {
    const result = yield call(api.newsband.getNews, params)

    yield put(actions.newsListInitiated(result))
  } catch (error) {
    console.log(error)
    const e = error.message ? error.message.response : error
    yield put(actions.newsListError(e))
  }
}

function* loadMorePosts({ params = {} }) {
  try {
    const posts = yield select(selectors.getPosts)
    const lastPostUuid = posts.length ? last(posts).uuid : null
    const allParams = {
      ...params,
      lastPostUuid,
      limit: NUMBER_OF_POSTS_PER_REQUEST,
    }
    const result = yield call(api.newsband.getNews, allParams)

    yield put(actions.newsListLoadMoreInitiated(result))
  } catch (error) {
    yield put(serverError(error))
  }
}

function* blockPost({ params: { postUuid, isUnblock } }) {
  try {
    const params = { spam: isUnblock ? 0 : 1 }
    yield call(api.newsband.editPost, postUuid, params)
    yield put(actions.newsListBlockPostInitiated({ postUuid, isUnblock }))
  } catch (error) {
    console.log(error)
    yield put(serverError(error))
  }
}

function* blockUser({ params: { postUuid, isUnblock, userId } }) {
  try {
    const params = { status: isUnblock ? 0 : 3 }
    yield call(api.profile.changeStatus, userId, params)
    yield put(actions.newsListBlockUserInitiated({ postUuid, isUnblock }))
  } catch (error) {
    yield put(serverError(error))
  }
}

function* pinPost({ uuid, isPinned }) {
  try {
    const params = { pinned: !isPinned }
    const post = yield call(api.newsband.pinPost, uuid, params)
    yield put(actions.newsListPostWasPenned(post))
  } catch (error) {
    yield put(serverError(error))
  }
}

function* readPost({ uuid }) {
  try {
    yield call(api.newsband.readPost, uuid)
  } catch (error) {
    yield put(serverError(error))
  }
}

function* unreadPost({ uuid }) {
  try {
    yield call(api.newsband.unreadPost, uuid)
  } catch (error) {
    yield put(serverError(error))
  }
}

export default function* watch(): Saga<void> {
  yield all([
    fork(watchInitialLoad),
    fork(watchLoadMore),
    fork(watchBlockPost),
    fork(watchBlockUser),
    fork(watchPinPost),
    fork(watchReadPost),
    fork(watchUnreadPost),
  ])
}
