// @flow

import { useLocation } from 'react-router-dom'

import { createOrderingFilter } from '../utils/routing'
import { useFilterHandler } from './useFilterHandler'

type Handler = string => void

export const useSortingHandler = (): Handler => {
  const location = useLocation()
  const handleChangeFilter = useFilterHandler()

  const handleSort = key => {
    const value = createOrderingFilter(key, location)

    handleChangeFilter('ordering', value)
  }

  return handleSort
}
