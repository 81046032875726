// @flow

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'

import { selectUk } from '../../init/init.selectors'
import { selectRemoveModal } from '../../CompanyView/CompanyView.selectors'
import CompanyAddressItem from './CompanyAddressItem'
import * as actions from '../../CompanyView/CompanyView.actionTypes'

class CompanyAddress extends Component {
  showModal = () => {
    this.props.dispatch({
      type: actions.COMPANY_VIEW_ADDRESS_MODAL_REMOVE_TOGGLE,
    })
  }

  toggleEditMode = () => {
    var {
      address: { officeId },
    } = this.props
    this.props.onToggleEditMode(officeId)
  }

  updateOffices = addressObj => {
    this.props.dispatch({
      type: actions.COMPANY_VIEW_PRIMARY_SAVED,
      officeId: this.props.officeId,
    })

    this.props.dispatch({
      type: actions.COMPANY_VIEW_ADDRESS_UPDATE,
      addressObj,
    })
  }

  render() {
    const {
      uk: {
        permissions: {
          can_edit: canEdit = false,
          can_delete: canDelete = false,
        },
        city,
      },
      working,
      initiated,
      address,
      id,
      offices,
      officeId,
    } = this.props

    const isPrimary = !!offices.filter(
      office => office.id === officeId && office.primary
    ).length

    return (
      <CompanyAddressItem
        dispatch
        id={id}
        isPrimary={isPrimary}
        initiated={initiated}
        address={address}
        officeId={officeId}
        updateOffices={this.updateOffices}
        editable={canEdit}
        canDelete={canDelete}
        working={working}
        title={this.props.t('AddressTitle')}
        editMode={address.editMode}
        city={city}
        onEdit={this.props.onEdit}
        onRemove={this.showModal}
        onCancel={this.props.onCancel}
        onSave={this.props.onSave}
        onToggleEditMode={this.toggleEditMode}
      />
    )
  }
}

const mapStateToProps = state => ({
  ...state.companyAddress,
  uk: selectUk(state),
  isModal: selectRemoveModal(state),
})

export default compose(
  withTranslation('Company'),
  connect(mapStateToProps)
)(CompanyAddress)
