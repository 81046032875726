// @flow

export const REAL_DATA_INITIATE: string = 'REAL_DATA_INITIATE'
export const REAL_DATA_WAS_INITIATED: string = 'REAL_DATA_WAS_INITIATED'
export const REAL_DATA_CHECK_STATUS: string = 'REAL_DATA_CHECK_STATUS'
export const REAL_DATA_STATUS_WAS_CHECKED: string =
  'REAL_DATA_STATUS_WAS_CHECKED'
export const REAL_DATA_CREATE: string = 'REAL_DATA_CREATE'
export const REAL_DATA_WAS_CREATED: string = 'REAL_DATA_WAS_CREATED'
export const REAL_DATA_APPLY_CONFIG: string = 'REAL_DATA_APPLY_CONFIG'
export const REAL_DATA_CONFIG_WAS_APPLIED: string =
  'REAL_DATA_CONFIG_WAS_APPLIED'
export const REAL_DATA_SYNCHRONIZE: string = 'REAL_DATA_SYNCHRONIZE'
export const REAL_DATA_MATCH_REQUESTS: string = 'REAL_DATA_MATCH_REQUESTS'
export const REAL_DATA_REQUESTS_WERE_MATCHED: string =
  'REAL_DATA_REQUESTS_WERE_MATCHED'
export const REAL_DATA_COPY_REQUEST_TYPES: string =
  'REAL_DATA_COPY_REQUEST_TYPES'
export const REAL_DATA_REQUEST_TYPES_WERE_COPIED: string =
  'REAL_DATA_REQUEST_TYPES_WERE_COPIED'
export const REAL_DATA_POPUP_ERROR: string = 'REAL_DATA_POPUP_ERROR'
export const REAL_DATA_RESET_POPUP_ERROR: string = 'REAL_DATA_RESET_POPUP_ERROR'
export const REAL_DATA_ERROR: string = 'REAL_DATA_ERROR'
