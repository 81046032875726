// @flow

import * as actionTypes from './Login.actionTypes'
import { CONFIRM_EMAIL_UPDATE } from '../../../containers/ConfirmEmail/ConfirmEmail.actionTypes'
import { PROFILE_INFO_UPDATED } from '../../Profile/ProfileInfo/ProfileInfo.actionTypes'
import { USER_EMAIL_SAVE_PHONE_SUCCESS } from '../../SettingsPersonal/UserEmail/UserEmail.actionTypes'

import type { ActionType } from './Login.actionTypes'

type ExtendedActionType =
  | ActionType
  | 'CONFIRM_EMAIL_UPDATE'
  | 'PROFILE_INFO_UPDATED'
  | 'USER_EMAIL_SAVE_PHONE_SUCCESS'

type Action = {
  data: {
    avatar_obj: string,
    name: string,
    owner: string,
  },
  email?: string,
  error?: Object,
  phone?: string,
  response: Object,
  status?: number,
  type: ExtendedActionType,
}

type Language = {
  code: string,
  id: number,
  name: string,
}

type Thumbnail = {
  [string]: string,
}

type Avatar = {
  filename: string,
  height: number,
  id: number,
  mime_type: string,
  mobile_thumbnails: Array<Thumbnail>,
  origin: string,
  preview: string,
  size: number,
  size_h: string,
  thumbnail: string,
  thumbnails: Array<Thumbnail>,
  width: number,
}

type Permissions = {
  can_block_user: boolean,
  can_change_dweller_role: boolean,
  can_change_manager_role: boolean,
  can_delete: boolean,
  can_edit: boolean,
  can_edit_building: boolean,
  can_edit_contacts: boolean,
  can_edit_files: boolean,
  can_edit_flat_number: boolean,
  can_edit_info: boolean,
  can_restore: boolean,
  can_start_new_chat: boolean,
  can_unblock_user: boolean,
  can_validate: boolean,
  can_view_address: boolean,
  can_view_contacts: boolean,
  can_view_files: boolean,
  can_view_info: boolean,
}

export type User = {
  accepted_user_agreement: string,
  address: string,
  address_hided: boolean,
  address_name: string,
  authorize: boolean,
  avatar_obj: Avatar,
  birthday: ?string,
  company_name: ?string,
  company_post_name: string,
  created: string,
  email: ?string,
  fullname: string,
  group: string,
  is_landlord: boolean,
  language_obj: Language,
  name: string,
  owner: number,
  permissions: Permissions,
  phone: ?string,
  second_name: ?boolean,
  soft_archived: boolean,
  status: number,
  surname: ?string,
  updated: string,
  validated: boolean,
}

export type InititalState = {
  error: boolean,
  initiated: boolean,
  status: boolean,
  user: User,
  working: boolean,
}

const { LOGIN_INIT, LOGIN_SUCCESS, LOGIN_ERROR, LOGOUT, CLEAR_LOGIN } =
  actionTypes

const initialState = {
  user: {},
  working: true,
  error: false,
  status: false,
  initiated: false,
}

export default (state: InititalState = initialState, action: Action) => {
  switch (action.type) {
    case LOGIN_INIT: {
      return {
        ...state,
        user: {},
        working: true,
      }
    }
    case LOGIN_SUCCESS: {
      return {
        ...state,
        working: false,
        user: {
          ...action.response,
        },
        error: false,
      }
    }
    case LOGIN_ERROR: {
      return {
        ...state,
        working: false,
        user: {},
        error: action.error,
        status: action.status,
      }
    }
    case LOGOUT: {
      if (!state.user) {
        return state
      }

      return {
        ...state,
        user: {
          avatar_obj: state.user.avatar_obj,
          language_obj: state.user.language_obj,
          name: state.user.name,
          permissions: state.user.permissions,
        },
      }
    }
    case CONFIRM_EMAIL_UPDATE:
      return {
        ...state,
        user: {
          ...state.user,
          email: action.email,
        },
      }
    case PROFILE_INFO_UPDATED:
      // If user changes his own profile data, update state.login,
      // to update avatar and name in header.
      if (state.user.owner === action.data.owner) {
        return {
          ...state,
          user: {
            ...state.user,
            name: action.data.name,
            avatar_obj: action.data.avatar_obj,
          },
        }
      }

      return state
    case USER_EMAIL_SAVE_PHONE_SUCCESS:
      // If user changes his own phone number, update state.login.
      return {
        ...state,
        user: {
          ...state.user,
          phone: action.phone,
        },
      }
    case CLEAR_LOGIN:
      // TODO к обсуждению
      return {}
    default: {
      return state
    }
  }
}
