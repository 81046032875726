// @flow

import {
  postRaw,
  postApi,
  getApi,
  putApi,
  deleteApi,
  patchApi,
} from './api.core'

export const getWorkflow = workflowId => getApi(`api/workflow/${workflowId}/`)
export const getWorkflowList = params => getApi('api/workflow/', params)
export const update = (id, data) => putApi(`api/workflow/${id}/`, data)

export const addGroup = (title, workflow) =>
  postApi('api/workflow/group/', { title, workflow })
export const renameGroup = (id, title) =>
  patchApi(`api/workflow/group/${id}/`, { title })
export const removeGroup = id => deleteApi(`api/workflow/group/${id}/`)

export const addBlock = group => postApi('api/workflow/block/', { group })
export const getBlock = id => getApi(`api/workflow/block/${id}/`)
export const updateBlock = (id, params) =>
  putApi(`api/workflow/block/${id}/`, params)
export const removeBlock = id => deleteApi(`api/workflow/block/${id}/`)

export const getCards = block => getApi('api/workflow/card/', { block })
export const addCard = card => postApi('api/workflow/card/', card)
export const updateCard = card =>
  patchApi(`api/workflow/card/${card.id}/`, card)
export const removeCard = id => deleteApi(`api/workflow/card/${id}/`)

export const addCardLink = (id, link) =>
  postApi(`api/workflow/card/${id}/link/`, link)
export const updateCardLink = (id, link) =>
  patchApi(`api/workflow/card/${id}/link/`, link)
export const removeCardLink = (id, link) =>
  deleteApi(`api/workflow/card/${id}/link/`, link)

export const getVariables = id => getApi(`api/workflow/${id}/variables/`)

export const getSnippetVariables = params =>
  getApi(`api/text-module/message-snippet/variables/`, params)

export const getEmailVariables = params =>
  getApi(`api/text-module/email-snippet/variables/`, params)

export const addWorkflow = params => postRaw(`api/workflow/`, params)
export const cloneWorkflow = (id, params) =>
  postRaw(`api/workflow/${id}/clone/`, params)

export const toggleWorkflow = workflowId =>
  postApi(`api/workflow/${workflowId}/toggle-is-active/`)

export const renameWorkflow = (id, params) =>
  patchApi(`api/workflow/${id}/`, params)

export const publish = workflow => postApi(`api/workflow/${workflow}/publish/`)
export const deleteWorkflow = params =>
  postRaw(`api/workflow/mass-delete/`, params)
