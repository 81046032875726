// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'

import EmptyList from '../../EmptyList'
import ProfileListTable from './ProfileListTable'
import BottomPagination from '../../Pagination/BottomPagination'
import Modal from '../../Modal'

const ProfileList = props => {
  const { profiles, loading, meta, selected, permissions, modal } = props

  const { t } = useTranslation('FlatDwellers')

  return (
    <>
      {profiles.length ? (
        <ProfileListTable
          profiles={profiles}
          loading={loading}
          meta={meta}
          handleCheck={props.handleCheck}
          selected={selected}
          permissions={permissions}
          showUpdateDwellerRoleModal={props.showUpdateDwellerRoleModal}
          setProfiles={props.setProfiles}
        />
      ) : (
        !loading && <EmptyList icon='apartment' title={t('empty_list_title')} />
      )}

      <BottomPagination classes='paginator paginator--middle' meta={meta} />

      <Modal
        contentLabel=''
        className='Modal__Bootstrap modal-dialog'
        isOpen={!!modal}
        onRequestClose={props.hideModal}
      >
        {modal}
      </Modal>
    </>
  )
}

export default ProfileList
