// @flow

import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { replace } from 'connected-react-router'
import { getCurrentFilters } from '../../../utils/routing'
import classnames from 'classnames'

import { getContractorCategoryOptions } from '../../../core/api/api.contractor'

import { SimpleBlock, Header } from '../../Block'
import Nbsp from '../../NBSP'
import Button from '../../Button'
import Table from '../../Table'

import EmptyList from '../../EmptyList'
import Loader from '../../Loader'
import BottomPagination from '../../Pagination/BottomPagination'

import CategoryMappingModal from '../../../containers/ProvidersPage/AllProviders/CategoryMappingModal'

import { ALL_PAGES } from '../../../constants'

import type { Node } from 'react'

type Props = {
  canChange: boolean,
  contractorId: number,
}

const Categories = (props: Props): Node => {
  const { contractorId, canChange } = props
  const { t } = useTranslation('Contractors')
  const dispatch = useDispatch()
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)
  const [categories, setCategories] = useState([])
  const [meta, setMeta] = useState({})
  const [categoryModal, setCategoryModal] = useState(null)

  useEffect(() => {
    setLoading(true)
  }, [location])

  useEffect(() => {
    if (loading) {
      const filters = getCurrentFilters(location)
      getContractorCategoryOptions({
        ...filters,
        contractor: contractorId,
      })
        .then(data => {
          setCategories(data.results.objects)
          setMeta(data.meta)
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
          dispatch(replace(location.pathname))
        })
    }
  }, [loading])

  const matchCategories = () => {
    setModalLoading(true)
    getContractorCategoryOptions({
      contractor: contractorId,
      page_size: ALL_PAGES,
    }).then(data => {
      setModalLoading(false)
      setCategoryModal({
        global_id: contractorId,
        categories: data.results.objects,
      })
    })
  }

  const tableClass = classnames('table table--default', {
    'working-overlay': loading,
  })

  return (
    <>
      <SimpleBlock style={{ marginTop: 0, marginBottom: 0 }}>
        <Header header>
          {t('Categories')}
          {!!meta.count && (
            <span className='unit__title-num'>
              <Nbsp />
              {meta.count}
            </span>
          )}
        </Header>
        {canChange && (
          <div className='toolbox'>
            <div className='toolbox__cell'>
              <Button.Save
                disabled={loading}
                working={modalLoading}
                icon='objects'
                onClick={matchCategories}
              >
                {t('SetCategories')}
              </Button.Save>
            </div>
          </div>
        )}
        {!!categories.length && !loading && (
          <div className={tableClass}>
            <div className='table__outer-wrapper'>
              <div className='table__wrapper table--homes__list'>
                <div className='table__head'>
                  <Table.HeaderCell
                    title={t('Category')}
                    sortKey='name'
                    working={loading}
                  />
                </div>
                {categories.map(({ id, name }) => (
                  <div key={id} className='table__row'>
                    <div className='table__cell'>{name}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        {!loading && (
          <BottomPagination
            classes='paginator paginator--middle-flat'
            meta={meta}
          />
        )}
        {!categories.length && !loading && (
          <EmptyList
            canAdd={!contractorId && canChange}
            btnText={t('AddCategory')}
            icon='building'
            title={t('NoCategories')}
            onClick={matchCategories}
          />
        )}
        {loading && <Loader type='big' text={false} />}
      </SimpleBlock>
      {categoryModal && (
        <CategoryMappingModal
          isContractor
          categoryModal={categoryModal}
          setLoading={setLoading}
          onClose={() => setCategoryModal(null)}
        />
      )}
    </>
  )
}

export default Categories
