// @flow

import React from 'react'

import HideablePart from './HideablePart'

type Props = {
  messageOpened: boolean,
  t: string => string,
  text: string,
  toggleMessage?: (SyntheticEvent<HTMLSpanElement>) => void,
}

const HideableMessagePart = (props: Props) => {
  const { text, messageOpened } = props

  const spanProps = Object.assign(
    {},
    props.toggleMessage ? { onClick: props.toggleMessage } : undefined
  )

  return (
    <p>
      <span className='modal__label  modal__label--link' {...spanProps}>
        {messageOpened ? props.t('HideMessage') : props.t('SeeMore')}
      </span>
      {messageOpened && <HideablePart text={text} />}
    </p>
  )
}

export default HideableMessagePart
