// @flow

import * as actions from './PublicBuildingPage.actions'

const initialState = {
  appid: '8e89659320ed5c51446747fd27a74a99',
  version: null,
  loading: {
    news: false,
    building: false,
    contacts: false,
    weather: true,
  },
  building: {
    address_obj: {
      value: null,
    },
    owner_objs: [],
  },
  contacts: {
    results: {
      objects: [],
    },
  },
  weather: {
    main: {
      temp: null,
    },
    weather: [{}],
  },
  news: {
    results: {
      objects: [],
    },
  },
  tickerNews: {
    results: {
      objects: [],
    },
  },
  error: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.LOAD_NEWS:
    case actions.LOAD_TICKER_NEWS:
    case actions.LOAD_BUILDING:
    case actions.LOAD_CONTACTS:
    case actions.LOAD_WEATHER:
      return {
        ...state,
        loading: {
          ...state.loading,
          ...action.payload,
        },
      }

    case actions.BUILDING_WAS_LOADED:
    case actions.CONTACTS_WAS_LOADED:
    case actions.NEWS_WAS_LOADED:
    case actions.TICKER_NEWS_WAS_LOADED:
    case actions.VERSION_WAS_LOADED:
    case actions.WEATHER_WAS_LOADED: {
      const propKey = Object.keys(action.payload)[0]
      const error = Object.keys(state.error).reduce((acc, key) => {
        if (key !== propKey) {
          acc[key] = state.error[key]
        }

        return acc
      }, {})

      return {
        ...state,
        loading: {
          ...state.loading,
          [propKey]: false,
        },
        error,
        ...action.payload,
      }
    }
    case actions.WEATHER_WAS_FAILED:
    case actions.TICKER_NEWS_WAS_FAILED:
    case actions.NEWS_WAS_FAILED:
      return {
        ...state,
        loading: false,
        error: {
          ...state.error,
          ...action.payload,
        },
      }
    default:
      return state
  }
}
