// @flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import Loader from '../Loader'
import Button from '../Button'
import Toolbox from '../Toolbox'
import { useLoad } from '../../hooks'
import ActivityTypeModal from './ActivityTypeModal'
import { getActivityTypes } from '../../core/api/api.pipeline'
import ActivityTypeTable from './ActivityTypeTable'

const ActivityType = (): Node => {
  const { t } = useTranslation('Activities')

  const [isModalOpen, setModalOpen] = useState(false)

  const [editingActivityType, setEditingActivityType] = useState(null)

  const [data, isLoading, isError, load] = useLoad(getActivityTypes)

  const handleClose = () => {
    setModalOpen(false)
  }

  const handleCreate = () => setModalOpen(true)

  // TODO Need error component
  if (isError) {
    return null
  }

  return (
    <>
      {data ? (
        <>
          <Toolbox>
            <div className='toolbox__cell'>
              <Button.Save onClick={handleCreate}>
                {t('AddTypeButton')}
              </Button.Save>
            </div>
          </Toolbox>
          <ActivityTypeTable
            data={data}
            isLoading={isLoading}
            setEditingActivityType={setEditingActivityType}
            load={load}
            onEdit={handleCreate}
          />
          {isModalOpen && (
            <ActivityTypeModal
              load={load}
              editingActivityType={editingActivityType}
              setEditingActivityType={setEditingActivityType}
              onClose={handleClose}
            />
          )}
        </>
      ) : (
        <Loader type='big' text={false} />
      )}
    </>
  )
}

export default ActivityType
