import { cloneDeep, clone, pull, take, map } from 'lodash-es'
import * as t from './FlatDwellers.actionTypes'

const {
  FLAT_DWELLERS_INITIATING, FLAT_DWELLERS_INITIATED,
  FLAT_DWELLERS_ERROR, FLAT_DWELLERS_SHOW_MODAL, FLAT_DWELLERS_HIDE_MODAL,
  FLAT_DWELLERS_UPDATED_ROLE, FLAT_DWELLERS_TOGGLE, FLAT_DWELLERS_TOGGLE_ALL,
  FLAT_DWELLERS_REMOVE, FLAT_DWELLERS_LOAD_ONE,
  FLAT_DWELLERS_UPDATE, FLAT_DWELLERS_LOAD, FLAT_DWELLERS_SHOW_PAGINATION
} = t

const initialState = {
  initiated: false,
  dwellers: [],
  error: null,
  meta: {},
  working: true,
  pagination: false,
  permissions: {},
  selected: [],
  filter: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FLAT_DWELLERS_INITIATING:
      return {
        ...initialState
      }
    case FLAT_DWELLERS_INITIATED:
      return {
        ...state,
        initiated: true,
        dwellers: action.results.objects,
        meta: action.meta,
        permissions: action.permissions,
        working: false,
        pagination: action.meta.page_count > 1
      }
    case FLAT_DWELLERS_ERROR:
      return {
        ...state,
        error: action.error
      }
    case FLAT_DWELLERS_SHOW_MODAL:
      return {
        ...state,
        modal: action.modal
      }
    case FLAT_DWELLERS_HIDE_MODAL:
      return {
        ...state,
        modal: false
      }
    case FLAT_DWELLERS_UPDATED_ROLE: {
      const index = state.dwellers.findIndex(({ owner }) => owner === action.result.owner)
      const updatedUsers = cloneDeep(state.dwellers)
      updatedUsers[index].is_landlord = action.result.is_landlord
      return {
        ...state,
        dwellers: updatedUsers,
        modal: null,
        selected: []
      }
    }
    case FLAT_DWELLERS_TOGGLE: {
      const { id } = action

      let selected = clone(state.selected)
      if (selected.includes(id)) {
        selected = pull(selected, id)
      } else {
        selected.push(id)
      }
      return {
        ...state,
        selected
      }
    }
    case FLAT_DWELLERS_TOGGLE_ALL: {
      let selected = []
      const dwellers = take(state.dwellers, action.count)
      if (state.selected.length !== dwellers.length) {
        selected = map(dwellers, (item) => item.owner)
      }
      return {
        ...state,
        selected
      }
    }
    case FLAT_DWELLERS_REMOVE:
      return {
        ...state,
        working: true,
        selected: [],
        modal: null
      }
    case FLAT_DWELLERS_LOAD_ONE:
    case FLAT_DWELLERS_LOAD:
      return {
        ...state,
        working: true,
        selected: [],
        modal: null
      }
    case FLAT_DWELLERS_UPDATE:
      return {
        ...state,
        working: false,
        dwellers: action.results.objects,
        meta: action.meta
      }
    case FLAT_DWELLERS_SHOW_PAGINATION:
      return {
        ...state,
        pagination: true
      }
    default:
      return state
  }
}
