import { all, put, call, fork, takeEvery } from 'redux-saga/effects'
import api from '../../core/api'
import * as t from './ImportMapTable.actionTypes'
import { serverError } from '../../components/Layout/Layout.actions'

const {
  IMPORT_MAP_TABLE_INITIATING,
  IMPORT_MAP_TABLE_INITIATED,
  IMPORT_MAP_TABLE_ERROR,
  IMPORT_MAP_TABLE_SUBMIT,
  IMPORT_MAP_TABLE_REMOVE,
  IMPORT_MAP_TABLE_REMOVE_SUCCESS,
  IMPORT_MAP_TABLE_SUBMIT_SUCCESS,
} = t

function* watchInit() {
  yield takeEvery(IMPORT_MAP_TABLE_INITIATING, init)
}

function* watchDelete() {
  yield takeEvery(IMPORT_MAP_TABLE_REMOVE, remove)
}

function* watchSubmit() {
  yield takeEvery(IMPORT_MAP_TABLE_SUBMIT, submit)
}

function* remove({ pk }) {
  try {
    yield call(api.userImport.deleteImport, pk)
    yield put({ type: IMPORT_MAP_TABLE_REMOVE_SUCCESS })
  } catch (error) {
    yield put(serverError(error))
    yield put({ type: IMPORT_MAP_TABLE_ERROR, error })
  }
}

function* init({ importId }) {
  try {
    const data = yield call(api.userImport.getImportData, importId)
    const iData = yield call(api.userImport.getImport, importId)
    const statuses = yield call(api.userImport.getStatuses)
    yield put({ type: IMPORT_MAP_TABLE_INITIATED, data, iData, statuses })
  } catch (error) {
    // eslint-disable-next-line no-console
    yield put(serverError(error))
    yield put({ type: IMPORT_MAP_TABLE_ERROR, error })
  }
}

function* submit({ importId, sel }) {
  try {
    const params = {
      relations: JSON.stringify(sel),
    }

    const data = yield call(api.userImport.patchImport, importId, params)
    yield put({ type: IMPORT_MAP_TABLE_SUBMIT_SUCCESS, data })
  } catch (error) {
    // eslint-disable-next-line no-console
    yield put(serverError(error))
    yield put({ type: IMPORT_MAP_TABLE_ERROR, error })
  }
}

export default function* watch() {
  yield all([fork(watchInit), fork(watchDelete), fork(watchSubmit)])
}
