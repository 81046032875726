// @flow

import {
  CHATROOMS_RESET_INITIAL_STATE,
  CHATROOMS_INITIATING,
  CHATROOMS_INITIATED,
  CHATROOMS_GET_FILES_INITIATING,
  CHATROOMS_GET_FILES_INITIATED,
} from './Messages.constants'

const initialState = {
  chatrooms: [],
  meta: {},
  fileIdToBeShown: null,
  files: [],
  metaFiles: {},
  permissions: {},
  working: true,
  initiated: false,
}

export default function messagesReducer(state = initialState, action) {
  switch (action.type) {
    case CHATROOMS_RESET_INITIAL_STATE:
      return initialState
    case CHATROOMS_INITIATING:
      return {
        ...state,
        working: true,
      }
    case CHATROOMS_INITIATED:
      return {
        ...state,
        chatrooms: action.chatrooms,
        meta: action.meta,
        permissions: action.permissions,
        working: false,
        initiated: true,
      }
    case CHATROOMS_GET_FILES_INITIATING:
      return {
        ...state,
      }
    case CHATROOMS_GET_FILES_INITIATED:
      return {
        ...state,
        fileIdToBeShown: action.fileIdToBeShown,
        files: action.files,
        filesMeta: action.meta,
      }
    default:
      return state
  }
}
