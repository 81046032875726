// @flow

import React from 'react'
import type { Node } from 'react'
import { useTranslation } from 'react-i18next'
import SelectCustom from '../../Select/SelectCustom'
import NewTableBodyCell from '../../NewTable/NewTableBodyCell'
import NewTableBodyRow from '../../NewTable/NewTableBodyRow'

import styles from './SalutationTableRow.module.scss'

type Props = {
  onEdit: () => void,
  options: Array<Object>,
  salutations: Object,
  setEditingSalutation: (Salutation: Object) => void,
}

const SalutationTableRow = (props: Props): Node => {
  const { t } = useTranslation('Salutation')
  const translate = {
    building: t('Bulding'),
    user: t('User'),
  }
  const {
    options,
    salutations,
    salutations: { id, salutation, object_type },
    onEdit,
    setEditingSalutation,
  } = props

  const handleChange = () => {
    setEditingSalutation(salutations)
    onEdit()
  }

  return (
    <NewTableBodyRow onClick={handleChange}>
      <NewTableBodyCell className={styles.tableCell}>
        <span>{salutation}</span>
      </NewTableBodyCell>
      <NewTableBodyCell className={styles.tableCell}>
        {translate[object_type]}
        <div className='table-extra'>
          <SelectCustom
            options={options}
            onChange={option => option.onClick(id)}
          />
        </div>
      </NewTableBodyCell>
    </NewTableBodyRow>
  )
}

export default SalutationTableRow
