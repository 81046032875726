// @flow

import createSagaMiddleware from 'redux-saga'
import { applyMiddleware, compose, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'

import rootReducer from './rootReducer'
import rootSaga from './rootSaga'

let store

const Store = {
  getLoggedUser: () => store.getState().login.user,
}

export const configureStore = (initialState = {}, history) => {
  const sagaMiddleware = createSagaMiddleware()

  const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  store = createStore(
    rootReducer(history),
    initialState,
    composeEnhancer(applyMiddleware(sagaMiddleware, routerMiddleware(history)))
  )

  sagaMiddleware.run(rootSaga)

  if (module.hot && process.env.NODE_ENV === 'development') {
    module.hot.accept('./rootReducer', () => {
      store.replaceReducer(rootReducer(history))
    })
  }

  return store
}

export default Store
