// @flow

import React, { useContext } from 'react'
import type { Node } from 'react'

import Title from './Title'
import Main from './Main'
import Icons from './Icons'
import PhaseItemContext from '../../PhaseItem.context'

import styles from './Info.module.scss'

const Info = (): Node => {
  const { activities } = useContext(PhaseItemContext)

  return (
    <div className={styles.info}>
      <Title />
      <Main />
      {(!activities || !!activities.length) && <Icons />}
    </div>
  )
}

export default Info
