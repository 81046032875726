// @flow

import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { get, omit, partial } from 'lodash-es'
import { useDispatch } from 'react-redux'
import { replace } from 'connected-react-router'
import { Editor } from '@tinymce/tinymce-react'
import { useDropzone } from 'react-dropzone'
import type { Node } from 'react'

import Icon from '../../Icon'
import Modal from '../../Modal'
import ConfirmationPopup from '../../modals/ConfirmationPopup'
import { SimpleBlock } from '../../Block'
import Button from '../../Button'
import Field from '../Field'
import InputPassword from '../../InputPassword'
import {
  NO_ENCRYPT_VALUE,
  USE_TLS_VALUE,
  USE_SSL_VALUE,
  MIN_PORT_NUMBER,
  MAX_PORT_NUMBER,
} from '../EmailIntegration.constants'
import SectionHeader from '../../SectionHeader'
import {
  createOutgoingMail,
  setOutgoingMail,
  setCorporateOutgoingMail,
  createCorporateOutgoingMail,
} from '../../../core/api/api.mail'
import {
  KEYS,
  DEFAULT_VALUES,
  PASSWORD,
  EMAIL_FROM,
  REPLY_TO,
  USERNAME,
  HOST,
  PORT,
  INSERT_SIGNATURE_BEFORE,
  SIGNATURE,
  USE_SSL,
  USE_TLS,
  PROTOCOL,
  EMAIL_FROM_NAME,
  THUMBNAIL_SIZE,
  SIG_IMAGE_MAX_SIZE,
} from './OutgoingEmail.constants'
import Checkbox from '../../Checkbox'
import { useError } from '../useError'
import { useConnection } from '../useConnection'
import { getInitialValues, getEncryption } from '../EmailIntegration.utils'
import NewSelectSimple from '../../NewSelectSimple'
import { createFile, getFile } from '../../../core/api/api.file'
import { formatFilesToBase64, getFullThumbnailPath } from '../../../utils/file'

import styles from './OutgoingEmail.module.scss'
import styles2 from '../EmailIntegration.module.scss'
import { globalModalError } from '../../Layout/Layout.actions'

const REACT_DROPZONE_ERROR_CODES = {
  'file-too-large': (t, name, size) => t('FileSize', { name, size }),
  'file-invalid-type': t => t('WrongFileType'),
}

type Props = {
  accountId?: number,
  initialParams?: ?Object,
  isPrivate?: boolean,
  setLoading?: boolean => void,
}

const UserExternalOutgoingEmail = (props: Props): Node => {
  const { accountId, initialParams, isPrivate } = props

  const id = get(initialParams, 'id')
  const status = get(initialParams, ['status', 'status'])
  const message = get(initialParams, ['status', 'message'])

  const [isWorking, setWorking] = useState(false)
  const [setError] = useError()

  const editorRef = useRef(null)

  const { open, acceptedFiles, getInputProps, fileRejections } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: false,
    maxSize: SIG_IMAGE_MAX_SIZE * 1024 * 1024,
    accept: 'image/jpeg,image/png',
  })

  let interval = null

  useEffect(() => {
    clearInterval(interval)
  }, [])

  useEffect(() => {
    if (!acceptedFiles || !acceptedFiles.length || !editorRef.current) {
      return
    }

    formatFilesToBase64(acceptedFiles).then(base64 => {
      const date = new Date()
      const time = date.getTime()

      const blobCache = editorRef.current.editorUpload.blobCache
      const blobInfo = blobCache.create(
        String(time),
        acceptedFiles[0],
        base64[0].origin
      )

      blobCache.add(blobInfo)

      editorRef.current.insertContent(
        editorRef.current.dom.createHTML('img', {
          src: blobInfo.blobUri(),
          maxWidth: `${THUMBNAIL_SIZE}px`,
        })
      )
    })
  }, [acceptedFiles])

  const dispatch = useDispatch()
  const { t } = useTranslation('SettingsExternalEmail')
  const { t: errorT } = useTranslation('Errors')

  useEffect(() => {
    if (fileRejections?.length) {
      let errors = fileRejections.map(f => {
        const error = get(f, ['errors', '0', 'code'])
        const name = get(f, ['file', 'name'], '')

        if (error) {
          return REACT_DROPZONE_ERROR_CODES[error](
            errorT,
            name,
            SIG_IMAGE_MAX_SIZE
          )
        }

        return null
      })

      const errorText = errors.reduce((acc, error) => {
        return acc + error + '<br/>'
      }, '')

      dispatch(globalModalError(errorText, errorT('File')))
    }
  }, [fileRejections])

  const [
    isChecking,
    isModalOpen,
    connectionTitle,
    connectionText,
    handleTest,
    handleCancelTest,
    handleCloseModal,
  ] = useConnection(id)

  const initialValues = getInitialValues(initialParams, DEFAULT_VALUES, KEYS)

  const formik = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: false,
    initialValues: {
      ...initialValues,
      outgoingEncryptOption: getEncryption(initialValues),
    },
    validationSchema: Yup.object({
      [EMAIL_FROM]: Yup.string()
        .email(t('Common:EnterValidEmailErrorText'))
        .required(t('Common:FieldIsRequired')),
      [REPLY_TO]: Yup.string().email(t('Common:EnterValidEmailErrorText')),
      [USERNAME]: Yup.string().required(t('Common:FieldIsRequired')),
      [PASSWORD]: Yup.string().required(t('Common:FieldIsRequired')),
      [HOST]: Yup.string().required(t('Common:FieldIsRequired')),
      [PORT]: Yup.number()
        .integer(t('Common:EnterValidPort'))
        .positive(t('Common:EnterValidPort'))
        .max(
          MAX_PORT_NUMBER,
          t('EnterPortInRange', { from: MIN_PORT_NUMBER, to: MAX_PORT_NUMBER })
        )
        .min(
          MIN_PORT_NUMBER,
          t('EnterPortInRange', { from: MIN_PORT_NUMBER, to: MAX_PORT_NUMBER })
        )
        .typeError(t('Common:EnterValidPort'))
        .required(t('Common:FieldIsRequired')),
      outgoingEncryptOption: Yup.string(),
      [SIGNATURE]: Yup.string(),
      [USE_SSL]: Yup.boolean(),
      [USE_TLS]: Yup.boolean(),
      [INSERT_SIGNATURE_BEFORE]: Yup.boolean(),
      [EMAIL_FROM_NAME]: Yup.string(),
    }),
    onSubmit: values => {
      setWorking(true)

      let params = omit(values, 'outgoingEncryptOption')

      if (values[PASSWORD] === formik.initialValues[PASSWORD]) {
        params = omit(params, PASSWORD)
      }

      formik.setFieldValue(PASSWORD, '********')

      params[PROTOCOL] = 'smtp'

      let api = partial(
        isPrivate ? setOutgoingMail : setCorporateOutgoingMail,
        id
      )

      if (accountId) {
        params.account_id = accountId
      }

      if (!id) {
        api = isPrivate ? createOutgoingMail : createCorporateOutgoingMail
      }

      api(params)
        .then(data => {
          if (accountId && props.setLoading) {
            props.setLoading(true)
          } else {
            const prefix = isPrivate ? 'private' : 'corporate'

            dispatch(replace(`/settings/email/${prefix}/${data.account}`))
          }
        })
        .catch(error => setError(error))
        .finally(() => setWorking(false))
    },
  })

  const handleSelect = option => {
    const { value } = option

    handleCancelTest()

    formik.setFieldValue('outgoingEncryptOption', value)
    formik.setFieldValue(USE_SSL, value === USE_SSL_VALUE)
    formik.setFieldValue(USE_TLS, value === USE_TLS_VALUE)
  }

  const handleSignature = value => {
    handleCancelTest()

    formik.setFieldValue(SIGNATURE, value)
  }

  const encryptOptions = [
    { value: NO_ENCRYPT_VALUE, label: t('noEncrypt') },
    { value: USE_TLS_VALUE, label: t('useTLS') },
    { value: USE_SSL_VALUE, label: t('useSSL') },
  ]

  const selectValue = encryptOptions.find(
    option => option.value === formik.values.outgoingEncryptOption
  )

  const handleChangeForm = () => {
    handleCancelTest()
  }

  const handleUpload = () => {
    open()
  }

  const handleInit = (e, editor) => {
    editorRef.current = editor
  }

  return (
    <SimpleBlock working={isWorking}>
      <SectionHeader header='true' text={t('ExternalOutgoingSectionTitle')} />
      <form
        name='outgoing'
        onSubmit={formik.handleSubmit}
        onChange={handleChangeForm}
      >
        <div className='settings-personal__form'>
          {!isPrivate && (
            <Field
              label='EmailFromName'
              name={EMAIL_FROM_NAME}
              error={formik.errors[EMAIL_FROM_NAME]}
              value={formik.values[EMAIL_FROM_NAME]}
              handleChange={formik.handleChange}
            />
          )}
          <Field
            label='outgoingEmailFrom'
            name={EMAIL_FROM}
            error={formik.errors[EMAIL_FROM]}
            value={formik.values[EMAIL_FROM]}
            handleChange={formik.handleChange}
          />
          <Field
            label='replyTo'
            name={REPLY_TO}
            error={formik.errors[REPLY_TO]}
            value={formik.values[REPLY_TO]}
            handleChange={formik.handleChange}
          />
          <Field
            label='outgoingUsername'
            name={USERNAME}
            error={formik.errors[USERNAME]}
            value={formik.values[USERNAME]}
            handleChange={formik.handleChange}
          />
          <Field
            label='outgoingPassword'
            error={formik.errors[PASSWORD]}
            value={formik.values[PASSWORD]}
            handleChange={formik.handleChange}
          >
            <InputPassword
              value={formik.values[PASSWORD]}
              name={PASSWORD}
              id={PASSWORD}
              onChange={formik.handleChange}
            />
          </Field>
          <Field
            label='outgoingHost'
            name={HOST}
            error={formik.errors[HOST]}
            value={formik.values[HOST]}
            handleChange={formik.handleChange}
          />
          <Field
            label='outgoingPort'
            name={PORT}
            error={formik.errors[PORT]}
            value={formik.values[PORT]}
            handleChange={formik.handleChange}
            type='number'
          />
          <Field
            error={formik.errors.outgoingEncryptOption}
            label='outgoingEncrypt'
          >
            <NewSelectSimple
              options={encryptOptions}
              className={styles.select}
              value={selectValue}
              name='outgoingEncrypt'
              clearable={false}
              onChange={handleSelect}
            />
          </Field>
          <Field error={formik.errors[SIGNATURE]} label='signature'>
            <div className='textarea-2 textarea-2--large textarea-2--block textarea-2--default'>
              <Editor
                tinymceScriptSrc='https://files.idwell.com/js/tinymce/5.10.8/tinymce.min.js'
                value={formik.values[SIGNATURE]}
                init={{
                  menubar: false,
                  element_format: 'html',
                  elementpath: false,
                  entity_encoding: 'raw',
                  min_height: 200,
                  max_height: 400,
                  object_resizing: false,
                  setup: function (editor) {
                    editor.ui.registry.addButton('customImageUploading', {
                      icon: 'image',
                      tooltip: 'Insert image',
                      onAction: () => {
                        handleUpload()
                      },
                    })
                  },
                  fontsize_formats: '8px 9px 10px 11px 12px 14px 16px',
                  content_style:
                    'body { font-family: sans-serif; font-size: 14px; }' +
                    'p { line-height: 16px; margin: 0; }',
                  font_formats:
                    'Arial=Arial,sans-serif; Calibri=calibri,sans-serif; Monospace=monospace; Plus Jakarta Sans=Plus Jakarta Sans,sans-serif; Sans Serif=sans-serif; Serif=serif; Times New Roman=Times New Roman,sans-serif; Verdana=Verdana,sans-serif',
                  images_upload_handler: (blobInfo, success) => {
                    const checkFile = id => {
                      getFile(id, {
                        thumbnail_resolution: THUMBNAIL_SIZE,
                      }).then(data => {
                        if (data.checking) {
                          return
                        } else {
                          setWorking(false)
                          clearInterval(interval)
                          success(getFullThumbnailPath(data, THUMBNAIL_SIZE))
                        }
                      })
                    }
                    setWorking(true)
                    createFile({
                      origin: [blobInfo.blob()],
                      type: 6,
                      name: blobInfo.filename(),
                    }).then(data => {
                      if (data.checking) {
                        interval = setInterval(() => {
                          checkFile(data.id)
                        }, 1000)
                      } else {
                        setWorking(false)
                        success(getFullThumbnailPath(data, THUMBNAIL_SIZE))
                      }
                    })
                  },
                  quickbars_insert_toolbar: '',
                  quickbars_selection_toolbar: '',
                  plugins: ['autoresize', 'image', 'quickbars'],
                  toolbar:
                    'fontselect fontsizeselect | bold italic underline forecolor | customImageUploading',
                }}
                onEditorChange={handleSignature}
                onInit={handleInit}
              />
              <input {...getInputProps()} />
            </div>
          </Field>
          <Field
            error={formik.errors[INSERT_SIGNATURE_BEFORE]}
            label='SignatureBefore'
          >
            <Checkbox
              text={t('InsertSignatureBefore')}
              name={INSERT_SIGNATURE_BEFORE}
              checked={formik.values[INSERT_SIGNATURE_BEFORE]}
              onChange={formik.handleChange}
            />
          </Field>
        </div>
        <div className='settings-personal__submit'>
          {formik.dirty || isWorking || !id ? (
            <>
              <Button.Save
                type='submit'
                working={isWorking}
                disabled={!formik.dirty}
              >
                {t('Common:Save')}
              </Button.Save>
              <Button.Cancel
                disabled={!formik.dirty}
                onClick={formik.resetForm}
              >
                {t('Common:Cancel')}
              </Button.Cancel>
            </>
          ) : (
            <Button.Save
              disabled={isChecking}
              working={isChecking}
              onClick={handleTest}
            >
              {t('TestConnection')}
            </Button.Save>
          )}
          {status === 'fail' && (
            <div className={styles2.error}>
              <Icon id='repeat' />
              <span>{message}</span>
            </div>
          )}
        </div>
      </form>
      {isModalOpen && (
        <Modal isOpen>
          <ConfirmationPopup
            showCancel={false}
            confirm={t('CloseModalButton')}
            title={connectionTitle}
            text={connectionText}
            onClose={handleCloseModal}
            onOk={handleCloseModal}
          />
        </Modal>
      )}
    </SimpleBlock>
  )
}

export default UserExternalOutgoingEmail
