// @flow

export const SETTINGS_CORPORATE_INIT = 'SETTINGS_CORPORATE_INIT'
export const SETTINGS_CORPORATE_SAVE = 'SETTINGS_CORPORATE_SAVE'
export const SETTINGS_CORPORATE_UPDATE = 'SETTINGS_CORPORATE_UPDATE'
export const SETTINGS_CORPORATE_ERROR = 'SETTINGS_CORPORATE_ERROR'
export const SETTINGS_CORPORATE_SAVE_THEME = 'SETTINGS_CORPORATE_SAVE_THEME'
export const SETTINGS_CORPORATE_SET_THEME = 'SETTINGS_CORPORATE_SET_THEME'
export const SETTINGS_CORPORATE_CANCEL_THEME = 'SETTINGS_CORPORATE_CANCEL_THEME'
export const SETTINGS_CORPORATE_SAVED_THEME = 'SETTINGS_CORPORATE_SAVED_THEME'
export const SETTINGS_CORPORATE_UPDATE_CONFIG =
  'SETTINGS_CORPORATE_UPDATE_CONFIG'
export const SETTINGS_CORPORATE_CANCEL_CONFIG =
  'SETTINGS_CORPORATE_CANCEL_CONFIG'
export const SETTINGS_CORPORATE_SAVE_CONFIG = 'SETTINGS_CORPORATE_SAVE_CONFIG'
export const SETTINGS_CORPORATE_SAVED_CONFIG = 'SETTINGS_CORPORATE_SAVED_CONFIG'
