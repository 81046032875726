// @flow

import React, { Component } from 'react'
import type { Node } from 'react'
import classnames from 'classnames'
import { withTranslation } from 'react-i18next'
import { push } from 'connected-react-router'
import { get, isEmpty, debounce } from 'lodash-es'
import { connect } from 'react-redux'
import { compose } from 'redux'

import { getUser } from '../../utils/commonSelectors'
import { isStaffUser, getFormattedDate, getUserName } from '../../utils/utils'
import Modal from '../Modal'
import TextClipped from '../TextClipped'
import * as actions from './HeaderSearch.actionTypes'
import ListItem from '../ListItem'
import { getSecondaryText } from './HeaderSearch.utils'
import { STATUS_COLORS, USER_STATUS } from '../../constants/users'

import styles from './HeaderSearch.module.scss'

import Icon from '../../../static/icons/magnifier.svg'

const {
  HEADER_SEARCH_LONG,
  HEADER_SEARCH_SHORT,
  HEADER_SEARCH_INPUT,
  HEADER_SEARCH_QUERY,
  HEADER_SEARCH_CHANGE_LOCATION,
  HEADER_SEARCH_SHOW_SELECT,
  HEADER_SEARCH_HIDE_SELECT,
  HEADER_SEARCH_SELECT_OPTION,
  HEADER_SEARCH_HIDE_SUGGEST,
} = actions

const INPUT_SEARCH_MAX_LENGTH = 256
const SEARCH_DEBOUNCE_INTERVAL = 500

type Props = {
  data: Object,
  dispatch: Object => void,
  hasOpenSearch: boolean,
  isSuggestOpen: boolean,
  modal: ?Node,
  searchOptions: Array<Object>,
  searchStr: ?string,
  selectedOptionId: ?number,
  showSelect: boolean,
  t: string => string,
}

class HeaderSearch extends Component {
  componentDidMount() {
    window.addEventListener('click', this.onBlurSearch, false)
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onBlurSearch, false)
  }

  onFocusSearch = e => {
    e.stopPropagation()
    this.props.dispatch({ type: HEADER_SEARCH_LONG })
  }

  onBlurSearch = () => {
    const { hasOpenSearch } = this.props

    if (hasOpenSearch) {
      this.props.dispatch({ type: HEADER_SEARCH_SHORT })
    }
  }

  onBlurSearchInputValue = () => {
    this.props.dispatch({ type: HEADER_SEARCH_HIDE_SELECT })
  }

  onClickSelect = e => {
    const { showSelect } = this.props

    e.stopPropagation()

    if (!showSelect) {
      this.props.dispatch({ type: HEADER_SEARCH_SHOW_SELECT })
    } else {
      this.props.dispatch({ type: HEADER_SEARCH_HIDE_SELECT })
    }
  }

  onInputSearch = evt => {
    const val = evt.target.value

    if (val.length < 3) {
      this.props.dispatch({ type: HEADER_SEARCH_INPUT, i: val })
    } else {
      this.props.dispatch({ type: HEADER_SEARCH_INPUT, i: val })
      this.searchQuery(val)
    }
  }

  searchQuery = debounce(search => {
    const searchDropDownActive = this.getSelectedOption()
    this.props.dispatch({
      type: HEADER_SEARCH_QUERY,
      search,
      path: searchDropDownActive.value,
    })
  }, SEARCH_DEBOUNCE_INTERVAL)

  onSelect = val => {
    const searchDropDownActive = this.getSelectedOption()
    const { itemUrl } = searchDropDownActive
    const valId = val.thread || val.id || val.owner || val.uuid || val.thread_id
    this.props.dispatch({
      type: HEADER_SEARCH_CHANGE_LOCATION,
    })
    this.props.dispatch(push(`/${itemUrl}/${valId}`))
  }

  getSelectedOption() {
    const { searchOptions, selectedOptionId } = this.props

    return searchOptions.find(o => o.id === selectedOptionId)
  }

  props: Props

  selectSearchCredential = (e, option) => {
    e.stopPropagation()

    this.props.dispatch({
      type: HEADER_SEARCH_SELECT_OPTION,
      option,
    })

    this.searchStrInput.focus()
  }

  handleKeyPress = e => {
    const searchDropDownActive = this.getSelectedOption()
    const { searchStr } = this.props

    const trimmedSearchStr = searchStr.trim()

    if (!trimmedSearchStr) {
      return
    }

    if (e.key === 'Enter') {
      let pathname = `/${
        searchDropDownActive.value
      }s/search/${encodeURIComponent(trimmedSearchStr)}`

      if (searchDropDownActive.id === 3) {
        pathname += '/?ordering=-created'
      }

      this.props.dispatch({
        type: HEADER_SEARCH_HIDE_SUGGEST,
      })

      this.props.dispatch(push(pathname))
    }
  }

  renderSelectItem = (item, active, key) => {
    const { label, value, id } = item
    const { t } = this.props
    const isActive = id === active.id
    const itemClass = classnames('b3-drop__item', {
      'b3-drop__item_checked': isActive,
    })

    return (
      <span
        className={itemClass}
        key={key}
        title={t(label)}
        onClick={e => this.selectSearchCredential(e, item)}
      >
        <input
          className='b3-drop__control'
          value={value}
          type='checkbox'
          name={`check-id${id}`}
        />
        {t(label)}
      </span>
    )
  }

  renderSelect = () => {
    const { searchOptions, showSelect, user } = this.props
    const searchDropDownActive = this.getSelectedOption()
    const availableOptions = isStaffUser(user)
      ? searchOptions
      : searchOptions.filter(o => !o.staffOnly)
    const classForSelect = classnames('b3-drop b3-drop_checklist ', {
      'b3-drop_open': showSelect,
    })

    return (
      <div onClick={this.onClickSelect}>
        <span className='b3-select'>
          <span className='b3-select__text'>
            {this.props.t(searchDropDownActive.label)}
          </span>
          <span className={classForSelect}>
            {availableOptions.map((item, key) =>
              this.renderSelectItem(item, searchDropDownActive, key)
            )}
          </span>
        </span>
      </div>
    )
  }

  renderModal = () => {
    const { modal } = this.props

    return (
      <Modal
        contentLabel=''
        className='Modal__Bootstrap modal-dialog'
        isOpen={!!modal}
        onRequestClose={this.hideModal}
      >
        {modal}
      </Modal>
    )
  }

  renderMailsOptions = () => {
    const { data } = this.props

    if (!data || !Array.isArray(data) || isEmpty(data)) {
      return null
    }

    return data.map((mail, i) => (
      <span
        key={`${i}_${mail.thread_id}`}
        className='b3-drop__item b3-drop__email-item'
        onMouseDown={() => this.onSelect(mail)}
      >
        <TextClipped>{mail.subject}</TextClipped>
        <span
          style={{ fontSize: '18px', whiteSpace: 'nowrap', paddingLeft: '5px' }}
        >
          {getFormattedDate(mail.created, {
            specifyHours: true,
            specifyYear: true,
          })}
        </span>
      </span>
    ))
  }

  renderUserOptions = () => {
    const { data } = this.props

    if (!data || !Array.isArray(data) || isEmpty(data)) {
      return null
    }

    return data.map(item => {
      const statusClassName = classnames(
        styles.status,
        styles[STATUS_COLORS[item.status]]
      )

      const meta = (
        <span className={statusClassName}>
          {this.props.t(`Common:${USER_STATUS[item.status]}`)}
        </span>
      )

      const group = item.is_landlord ? 'landlord' : item.group

      return (
        <ListItem
          key={item.id}
          className={styles.userItem}
          primaryText={
            <span className={styles.textBlock}>
              <span>{getUserName(item)}</span>
              <span>&nbsp;{`| ${this.props.t(`User:${group}`)}`}</span>
            </span>
          }
          secondaryText={getSecondaryText(item)}
          avatar={item.avatar}
          meta={meta}
          onMouseDown={() => this.onSelect(item)}
        />
      )
    })
  }

  getObjectValue = object => {
    return object.number && object.address
      ? `${this.props.t('Building:flat_number_title')} ${object.number}, ${
          object.address
        }`
      : object.title ||
          object.fullname ||
          object.address ||
          get(object, ['address_obj', 'value'])
  }

  render() {
    const { isSuggestOpen, hasOpenSearch, t, searchStr, theme, data } =
      this.props
    const headerSearchCls = classnames('header__col b3', {
      b3_active: hasOpenSearch,
    })

    const objects = data?.results?.objects || data || []

    const options = objects.map(object => ({
      css: null,
      id: object.id || object.owner,
      title: this.getObjectValue(object),
    }))

    const elems = objects.map(opt => (
      <span
        key={opt.id || opt.owner}
        className='b3-drop__item'
        onMouseDown={() => this.onSelect(opt)}
      >
        {this.getObjectValue(opt)}
      </span>
    ))

    const optionsClassName = classnames('b3-drop', {
      'b3-drop_open': options.length,
    })

    const searchClass = classnames('header__notifier', {
      'header__notifier--gray': theme === '#ffffff',
    })

    return (
      <div className={headerSearchCls}>
        {hasOpenSearch ? (
          <div className='b3__container'>
            <div className='b3__wrapper' onClick={this.onFocusSearch}>
              {this.renderSelect()}

              <div className='b3-input'>
                <input
                  autoFocus
                  type='text'
                  value={searchStr}
                  placeholder={t('Search')}
                  ref={input => {
                    this.searchStrInput = input
                  }}
                  maxLength={INPUT_SEARCH_MAX_LENGTH}
                  onBlur={this.onBlurSearchInputValue}
                  onChange={this.onInputSearch}
                  onClick={this.onFocusSearch}
                  onKeyDown={this.handleKeyPress}
                />

                {isSuggestOpen && !!options.length && (
                  <span className={optionsClassName}>
                    {this.getSelectedOption().value === 'mail'
                      ? this.renderMailsOptions()
                      : this.getSelectedOption().value === 'user'
                      ? this.renderUserOptions()
                      : elems}
                  </span>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div
            className='header__nav-item header-search-button'
            onClick={this.onFocusSearch}
          >
            <Icon className={searchClass} />
          </div>
        )}
        {this.renderModal()}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state.headerSearch,
  user: getUser(state),
})

export default compose(
  withTranslation('HeaderSearch'),
  connect(mapStateToProps)
)(HeaderSearch)
