// @flow

import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { logout } from '../components/Auth/Login/Login.actions'

const LogoutRoute = (): null => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(logout())
  }, [])

  return null
}

export default LogoutRoute
