// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import Input from '../../Input'
import InputValidation from '../../forms/InputValidation'

import styles from './PostTitle.module.scss'

type Props = {
  setTitle: string => void,
  title: string,
}

const PostTitle = (props: Props) => {
  const handleChange = (name, value) => {
    props.setTitle(value)
  }

  const { t } = useTranslation('Post')

  const { title } = props

  const titleClass = classnames(
    'input input--font-xlarge input--default input--large input--block',
    { [styles.title]: !props.automate }
  )

  const message = props.getValidationMessages?.('title')

  return (
    <div className={titleClass}>
      <Input.Regular
        value={title}
        placeholder={t('PostTitlePlaceholder')}
        className='textarea-2__input'
        name='post-title'
        onBlur={props.handleValidation?.('title')}
        onChange={handleChange}
      />
      {!!message?.length && (
        <InputValidation message={message} needTranslate={false} />
      )}
    </div>
  )
}

export default PostTitle
