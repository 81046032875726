// @flow

import React, { useState } from 'react'
import type { Node } from 'react'
import { connect } from 'react-redux'
import classnames from 'classnames'

import OneNews from './OneNews'
import {
  HEIGHT_BETWEEN_NEWS,
  COLUMN_HEIGHT,
  MIN_NEWS_HEIGHT,
} from './NewsPage.constants'
import {
  getNewsList,
  getNewsBand,
  getUKTheme,
} from '../PublicBuildingPage.selectors'

type Props = {
  news: Array<Object>,
  newsBand: Array<Object>,
  theme: Object,
}

const NewsPage = (props: Props): Node => {
  const { news, newsBand } = props
  const [freeColumnHeight, updateFreeColumnHeight] = useState(COLUMN_HEIGHT)
  const [newsConfigs, addNewsConfig] = useState([])
  const [mountedNewsCount, newsWasMounted] = useState(0)
  const isBig = newsBand.length === 0
  const className = classnames('public-building-page__news-page', {
    'public-building-page__news-page-big': isBig,
  })

  const handleMountNews = config => {
    const { height } = config

    if (height === freeColumnHeight) {
      updateFreeColumnHeight(COLUMN_HEIGHT)
    }

    if (height > freeColumnHeight && freeColumnHeight >= MIN_NEWS_HEIGHT) {
      updateFreeColumnHeight(freeColumnHeight)
    }

    if (height > freeColumnHeight && freeColumnHeight < MIN_NEWS_HEIGHT) {
      if (height === COLUMN_HEIGHT) {
        updateFreeColumnHeight(COLUMN_HEIGHT)
      } else {
        updateFreeColumnHeight(COLUMN_HEIGHT - height)
      }
    }

    if (height < freeColumnHeight) {
      updateFreeColumnHeight(freeColumnHeight - height - HEIGHT_BETWEEN_NEWS)
    }

    addNewsConfig(newsConfigs.concat(config))
    newsWasMounted(mountedNewsCount + 1)
  }

  const renderStab = () => (
    <div
      className='public-building-page__news-page-one'
      style={{ height: `${COLUMN_HEIGHT}px`, opacity: 0 }}
    />
  )

  const isVisibleNews = mountedNewsCount === news.length

  return (
    <section className={className}>
      {news.map((el, index) => {
        return index <= mountedNewsCount ? (
          <OneNews
            el={el}
            key={el.uuid}
            isVisible={isVisibleNews}
            config={newsConfigs[index]}
            freeColumnHeight={freeColumnHeight}
            onMount={handleMountNews}
          />
        ) : null
      })}
      {renderStab()}
      {renderStab()}
    </section>
  )
}

const mapStateToProps = state => ({
  news: getNewsList(state),
  newsBand: getNewsBand(state),
  theme: getUKTheme(state),
})

export default connect(mapStateToProps)(NewsPage)
