// @flow

import React from 'react'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import DateTime from '../../../DateTime'

type Props = {
  dateTime: ?string,
}

const DueDateView = (props: Props) => {
  const { dateTime } = props

  const { t } = useTranslation('Request')

  const dueDateCellClassNames = classnames('bar__cell-value', {
    'bar__cell-value--danger': moment(dateTime).isBefore(moment()),
  })

  return (
    <span className={dueDateCellClassNames}>
      {!dateTime ? (
        t('NotSpecified')
      ) : (
        <DateTime dateTime={dateTime} style={{ textDecoration: 'underline' }} />
      )}
    </span>
  )
}

export default DueDateView
