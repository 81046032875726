// @flow

import React from 'react'

import type { Props } from './index'
import Phone from '../../../../Phone'

const PhoneCell = ({ user, onClick }: Props) => (
  <div className='table__cell' onClick={onClick}>
    <div className='table__cell-wrapper'>
      <div className='table-item table-item__text'>
        {user.phone && (
          <Phone phone={`${user.phone[0] !== '+' ? '+' : ''}${user.phone}`} />
        )}
      </div>
    </div>
  </div>
)

export default PhoneCell
