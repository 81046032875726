// @flow

import React from 'react'
import classNames from 'classnames'
import type { Node } from 'react'

type Option = { code: number, label: string }

type Props = {
  options: Array<Option>,
  value: number,
}

const StatusValue = ({ options, value, className }: Props): Node => {
  const option = options.find(o => o.code === value)

  const CSSCLASSES = ['warning', 'success', 'warning', 'danger', 'warning']

  const statusClassName = classNames(
    `table-item table-item__status table-item__status--${CSSCLASSES[value]}`,
    className
  )

  return (
    <div
      title={option?.label}
      style={{ whiteSpace: 'nowrap' }}
      className={statusClassName}
    >
      <span>{option?.label}</span>
    </div>
  )
}

export default StatusValue
