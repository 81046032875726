// @flow

import React from 'react'
import classnames from 'classnames'
import type { Node } from 'react'

import styles from './ModalLabel.module.scss'

type Props = {
  children: Node,
  className?: string,
  onClick?: (SyntheticEvent<HTMLSpanElement>) => void,
  style?: Object,
}

const ModalLabel = (props: Props): Node => {
  const { children, className, style, onClick } = props

  const labelClass = classnames(styles.label, className)

  return (
    <span className={labelClass} style={style} onClick={onClick}>
      {children}
    </span>
  )
}

export default ModalLabel
