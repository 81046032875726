// @flow

export const WIDGET_PARTICIPANTS_LIST_INITIATING =
  'WIDGET_PARTICIPANTS_LIST_INITIATING'
export const WIDGET_REQUEST_PARTICIPANTS_LIST_INITIATING =
  'WIDGET_REQUEST_PARTICIPANTS_LIST_INITIATING'
export const WIDGET_CHAT_PARTICIPANTS_LIST_INITIATED =
  'WIDGET_CHAT_PARTICIPANTS_LIST_INITIATED'
export const WIDGET_PARTICIPANTS_LIST_ERROR = 'WIDGET_PARTICIPANTS_LIST_ERROR'
export const UPDATE_PARTICIPANTS_LIST =
  'WIDGET_PARTICIPANTS_LIST/UPDATE_PARTICIPANTS_LIST'
export const WIDGET_MAIL_PARTICIPANTS_LIST_INITIATING =
  'WIDGET_PARTICIPANTS_LIST/WIDGET_MAIL_PARTICIPANTS_LIST_INITIATING'
export const CLEAR_WIDGET_PARTICIPANTS_LIST = 'CLEAR_WIDGET_PARTICIPANTS_LIST'

export const widgetChatParticipantsListInitiated = data => ({
  type: WIDGET_CHAT_PARTICIPANTS_LIST_INITIATED,
  data,
})
