// @flow

import React from 'react'
import classnames from 'classnames'
import type { Node } from 'react'

import styles from './TextClipped.module.scss'

type Props = {
  children: Node,
  className?: ?string,
  constantHeight?: boolean,
  fontSize: number,
  lineHeight: number,
  maxLines: number,
  title?: string,
}

const TextClipped = (props: Props) => {
  const {
    children,
    className,
    fontSize,
    lineHeight,
    maxLines,
    title,
    constantHeight,
  } = props

  const textClass = classnames(styles.text, className, {
    [styles.multiline]: maxLines > 1,
  })

  const maxHeight = `${fontSize * lineHeight * maxLines}px`
  const style = {
    fontSize: `${fontSize}px`,
    lineHeight,
    maxHeight,
    WebkitLineClamp: maxLines,
    ...(constantHeight ? { minHeight: maxHeight } : {}),
  }

  return (
    <span className={textClass} style={style} title={title}>
      {children}
    </span>
  )
}

TextClipped.defaultProps = {
  fontSize: 18,
  lineHeight: 1.2,
  maxLines: 1,
  title: '',
}

export default TextClipped
