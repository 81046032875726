// @flow

import React, { Children, Component, Fragment } from 'react'
import type { Node } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'
import { compose } from 'redux'
import { defaults, difference, omit, isEqual } from 'lodash-es'
import { Link, withRouter } from 'react-router-dom'
import { replace } from 'connected-react-router'
import { parse } from 'qs'

import Icon from '../Icon'
import { getLocation } from '../../utils/commonSelectors'
import { getCurrentFilters, getNewQueryString } from '../../utils/routing'

import Tooltip from '../Tooltip/Tooltip'

type Props = {
  children: any,
  className: string,
  count: number,
  dispatch: Object => void,
  forwardedParams?: Array<string>,
  large?: boolean,
  location: Object,
  match: Object,
  openFirst?: string,
  t: string => string,
  tooltip?: string,
}
type State = {
  children: Array<Node>,
}

class RouterTabs extends Component<Props, State> {
  state = {
    children: Children.toArray(this.props.children).filter(item => item),
  }

  static defaultProps = {
    count: 0,
    forwardedParams: [],
  }

  componentDidMount() {
    this.redirectToTab()
  }

  componentDidUpdate(prevProps: Props) {
    const { children, match } = this.props

    if (!isEqual(children, prevProps.children)) {
      this.setState({
        children: Children.toArray(children).filter(item => item),
      })
    }

    if (
      match.params.tab !== prevProps.match.params.tab &&
      prevProps.match.params.tab
    ) {
      this.redirectToTab(this.props) // this is case if tab doesn't valid
    }

    if (!match.params.tab && children.length !== 0) {
      this.redirectToTab(this.props) // this is case if tab doesn't pass
    }
  }

  getForwardedSearch = search => {
    const { forwardedParams, location } = this.props
    const filters = getCurrentFilters(location)
    const parsedSearch = parse(search, { ignoreQueryPrefix: true })
    const overriddenFilters = defaults(parsedSearch, filters)
    const forwardedSearch = omit(
      overriddenFilters,
      difference(Object.keys(overriddenFilters), forwardedParams)
    )

    return getNewQueryString(forwardedSearch)
  }

  getChild = (props, isSelected) => {
    const { match, count } = this.props
    const { label, svg, tooltip, linkHash = '', disabled, onClick } = props
    const tab = linkHash.slice(1)
    const to = this.getDestination(match, tab)
    const largeChildren = (
      <Fragment>
        {label} <b className='tabs-3__count'>{count}</b>
      </Fragment>
    )
    const regularChildren = (
      <Fragment>
        {label}{' '}
        {!!count && isSelected && <b className='tabs-3__count'>{count}</b>}
      </Fragment>
    )

    if (this.isLarge()) {
      return (
        <span className='tabs-3__text'>
          {disabled || onClick || isSelected ? (
            <span>{largeChildren}</span>
          ) : (
            <Link to={to}>{largeChildren}</Link>
          )}
        </span>
      )
    }

    return (
      <span className='tabs__item-wrap'>
        {isSelected && tooltip && svg ? (
          <Tooltip text={tooltip}>
            <Icon className='tabs__symbol' id={svg} />
          </Tooltip>
        ) : (
          !tooltip && svg && <Icon className='tabs__symbol' id={svg} />
        )}
        <span>
          {disabled || onClick || isSelected ? (
            <span>{regularChildren}</span>
          ) : (
            <Link to={to}>{regularChildren}</Link>
          )}
        </span>
      </span>
    )
  }

  isLarge = () => 'large' in this.props && this.props.large !== false

  getItemClass = (key, props, isSelected) => {
    const tabsClass = this.isLarge() ? 'tabs-3' : 'tabs'

    return classnames(`${tabsClass}__item`, {
      [`${tabsClass}__item--active`]: isSelected,
      [`${tabsClass}__item--disabled`]: !!props.disabled,
    })
  }

  getTabContent = () => {
    const { match } = this.props
    const { children } = this.state

    return children.find(
      item => item.props.linkHash.slice(1) === match.params.tab
    )
  }

  redirectToTab = (props = this.props) => {
    const { match, openFirst } = props
    const { children } = this.state
    const { tab } = match.params
    const isTabExists = children.some(item => item.props.linkHash === `#${tab}`)

    if (children.length !== 0 && !isTabExists) {
      // if tab doesn't pass and tabs exist
      const tab = openFirst || this.getFirstTabLink()
      const to = this.getDestination(match, tab)
      this.props.dispatch(replace(to))
    }
  }

  getFirstTabLink = () => this.state.children[0].props.linkHash.slice(1)

  getDestination = (match, tab, search = '') => {
    const pathname = Object.keys(match.params).reduce((acc, key) => {
      if (key === 'folderId') {
        return acc.replace(`:folderId?/`, '')
      }

      if (key !== 'tab') {
        return acc.replace(`:${key}`, match.params[key])
      } else {
        return acc.replace(`:tab?/`, tab)
      }
    }, match.path)

    const forwardedSearch = this.getForwardedSearch(search)
    const searchPrefix = forwardedSearch ? '?' : ''

    return `${pathname}/${searchPrefix}${forwardedSearch}`
  }

  renderRouterNav = (item, i) => {
    const { tab } = this.props.match.params
    const isSelected = tab === item.props.linkHash.slice(1)

    return (
      <div
        id={item.props.id}
        className={this.getItemClass(i, item.props, isSelected)}
        key={i}
        onClick={item.props.onClick || null}
      >
        {this.getChild(item.props, isSelected)}
      </div>
    )
  }

  render() {
    const { className } = this.props
    const { children } = this.state
    const large = this.isLarge()
    const tabsClass = large ? 'tabs-3' : 'tabs'

    return (
      <div>
        <nav className={className || tabsClass}>
          {children.map(this.renderRouterNav)}
        </nav>

        <div className='tab-content'>
          {children.length ? this.getTabContent() : null}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  location: getLocation(state),
})

export default compose(
  withTranslation('Modal'),
  connect(mapStateToProps),
  withRouter
)(RouterTabs)
