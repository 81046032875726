// @flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import type { Node } from 'react'

import Icon from '../../Icon'

import styles from './Field.module.scss'

type Props = {
  children?: Node,
  disabled?: boolean,
  error?: string,
  handleChange?: (event: { ... }) => void,
  label: string,
  name?: string,
  type: 'text' | 'number',
  value?: string | number,
}

const Field = (props: Props): Node => {
  const { error, name, value, children, type, label, disabled, helpText } =
    props

  const [isHover, setHover] = useState(false)
  const handleMouseEnter = () => setHover(true)
  const handleMouseLeave = () => setHover(false)

  const { t } = useTranslation('EmailIntegrationNylas')

  const fieldClass = classnames(
    'input input--large input--block input--default',
    {
      'input--error': !!error,
    }
  )

  const labelClass = classnames('settings-personal__form-label', styles.label)

  return (
    <div className='settings-personal__form-row'>
      <div className='settings-personal__form-cell'>
        <label className={labelClass}>{t(label)}</label>
        {helpText && (
          <span
            className={styles.container}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <Icon id='about' className={styles.icon} />
            {isHover && (
              <div className={styles.tooltip}>
                <div />
                <span>{t(helpText)}</span>
              </div>
            )}
          </span>
        )}
      </div>
      <div className='settings-personal__form-cell'>
        <div className={fieldClass}>
          {children || (
            <input
              disabled={disabled}
              className={styles.input}
              type={type}
              value={value}
              name={name}
              onChange={props.handleChange}
            />
          )}
          <div className='input__msg'>{error}</div>
        </div>
      </div>
    </div>
  )
}

Field.defaultProps = {
  type: 'text',
}

export default Field
