// @flow

import React from 'react'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'

import DateTime from '../../DateTime'
import Label from '../../Label'

import styles from './PostInfo.module.scss'

const PostInfoInterval = props => {
  const { dateFrom, dateTo, isExpired, isUpcoming } = props

  const intervalClass = classnames('bar__cell-value', styles.interval, {
    [styles.activeDateTo]: !isExpired && dateTo,
    [styles.expiredDateTo]: isExpired && dateTo,
  })

  const { t } = useTranslation('Post')

  return (
    <div className={intervalClass}>
      {dateFrom ? (
        <DateTime dateTime={dateFrom} />
      ) : (
        <span>{t('DateFromIsNotSet')}</span>
      )}
      <span className={styles.dash}>&mdash;</span>
      {dateTo ? (
        <DateTime dateTime={dateTo} />
      ) : (
        <span>{t('DateToIsNotSet')}</span>
      )}
      {isExpired && dateTo && (
        <Label text={t('DateToIsExpired')} style={{ marginLeft: '10px' }} />
      )}
      {isUpcoming && dateFrom && (
        <Label
          text={t('DateFromIsPlanned')}
          style={{ marginLeft: '10px' }}
          color='warning'
        />
      )}
    </div>
  )
}

export default PostInfoInterval
