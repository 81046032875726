// @flow

export const NEWS_LIST_INITIATING = 'NEWS_LIST_INITIATING'
export const NEWS_LIST_INITIATED = 'NEWS_LIST_INITIATED'
export const NEWS_LIST_ERROR = 'NEWS_LIST_ERROR'
export const NEWS_LIST_LOAD_MORE_INITIATING = 'NEWS_LIST_LOAD_MORE_INITIATING'
export const NEWS_LIST_LOAD_MORE_INITIATED = 'NEWS_LIST_LOAD_MORE_INITIATED'
export const NEWS_LIST_BLOCK_POST_INITIATING = 'NEWS_LIST_BLOCK_POST_INITIATING'
export const NEWS_LIST_BLOCK_POST_INITIATED = 'NEWS_LIST_BLOCK_POST_INITIATED'
export const NEWS_LIST_BLOCK_USER_INITIATING = 'NEWS_LIST_BLOCK_USER_INITIATING'
export const NEWS_LIST_BLOCK_USER_INITIATED = 'NEWS_LIST_BLOCK_USER_INITIATED'
export const NEWS_LIST_WELCOME_NEWS_CHANGED = 'NEWS_LIST_WELCOME_NEWS_CHANGED'
export const NEWS_LIST_ADD_POST = 'NEWS_LIST_ADD_POST'
export const NEWS_LIST_RESET = 'NEWS_LIST_RESET'
export const NEWS_LIST_PIN_POST_INITIATING = 'NEWS_LIST_PIN_POST_INITIATING'
export const NEWS_LIST_POST_WAS_PINNED = 'NEWS_LIST_POST_WAS_PINNED'
export const NEWS_LIST_READ_POST = 'NEWS_LIST_READ_POST'
export const NEWS_LIST_UNREAD_POST = 'NEWS_LIST_UNREAD_POST'

export const newsListInitiating = params => ({
  type: NEWS_LIST_INITIATING,
  params,
})
export const newsListInitiated = data => ({
  type: NEWS_LIST_INITIATED,
  data,
})
export const newsListError = error => ({
  type: NEWS_LIST_ERROR,
  error,
})
export const newsListLoadMoreInitiating = params => ({
  type: NEWS_LIST_LOAD_MORE_INITIATING,
  params,
})
export const newsListLoadMoreInitiated = data => ({
  type: NEWS_LIST_LOAD_MORE_INITIATED,
  data,
})
export const newsListBlockPostInitiating = params => ({
  type: NEWS_LIST_BLOCK_POST_INITIATING,
  params,
})
export const newsListBlockPostInitiated = params => ({
  type: NEWS_LIST_BLOCK_POST_INITIATED,
  ...params,
})
export const newsListBlockUserInitiating = params => ({
  type: NEWS_LIST_BLOCK_USER_INITIATING,
  params,
})
export const newsListBlockUserInitiated = params => ({
  type: NEWS_LIST_BLOCK_USER_INITIATED,
  ...params,
})
export const newsListPinPost = (uuid, isPinned) => ({
  type: NEWS_LIST_PIN_POST_INITIATING,
  uuid,
  isPinned,
})
export const newsListPostWasPenned = post => ({
  type: NEWS_LIST_POST_WAS_PINNED,
  post,
})
export const newsListReset = () => ({
  type: NEWS_LIST_RESET,
})
export const newsListAddPost = post => ({
  type: NEWS_LIST_ADD_POST,
  post,
})
export const newsListReadPost = (uuid: string): Object => ({
  type: NEWS_LIST_READ_POST,
  uuid,
})
export const newsListUnreadPost = (uuid: string): Object => ({
  type: NEWS_LIST_UNREAD_POST,
  uuid,
})
