// @flow

import React, { useEffect, useRef } from 'react'
import type { Node } from 'react'

import styles from './NewFilterAsyncSearch.module.scss'

type Props = {
  disabled?: boolean,
  initiating?: boolean,
  loading?: boolean,
  onInputChange: string => void,
  onKeyDown?: () => void,
  placeholder: string,
  value: string,
}

const NewFilterAsyncSearch = (props: Props): Node => {
  const { placeholder, value, disabled, loading, initiating, onKeyDown } = props

  const ref = useRef(null)

  useEffect(() => {
    if ((!loading || !initiating) && ref && ref.current) {
      ref.current.focus()
    }
  }, [loading, initiating])

  return (
    <div className={styles.search}>
      <div>
        <input
          autoFocus
          ref={ref}
          value={value}
          disabled={disabled}
          type='text'
          placeholder={placeholder}
          onChange={e => props.onInputChange(e.currentTarget.value)}
          onKeyDown={onKeyDown}
        />
      </div>
    </div>
  )
}

NewFilterAsyncSearch.defaultProps = {
  disabled: false,
}

export default NewFilterAsyncSearch
