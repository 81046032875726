// @flow

import { map } from 'lodash-es'
import React from 'react'
import { useTranslation } from 'react-i18next'

import Checkbox from '../../../../components/Checkbox'
import NewTableBodyCell from '../../../../components/NewTable/NewTableBodyCell'
import NewTableBodyRow from '../../../../components/NewTable/NewTableBodyRow'
import SelectCustom from '../../../../components/Select/SelectCustom'

const AllProvidersTableBodyRow = props => {
  const { provider, selectedItems, isAdmin } = props

  const {
    name,
    global_id,
    categories,
    phone,
    email,
    offer_count,
    buildings,
    avatar,
  } = provider

  const { t } = useTranslation('Providers')

  const handleChange = () => props.handleChange(global_id)

  const handleClick = () => props.onOpen(global_id)

  const checked = selectedItems.includes(global_id)

  let options = []

  options.push({
    label: t('ShowProvider'),
    value: 'showProvider',
    handler: handleClick,
    icon: 'eye',
  })

  if (isAdmin) {
    if (props.removeProviders) {
      options.push({
        label: t('RemoveFromFavorites'),
        value: 'removeFromFavorites',
        handler: () => props.removeProviders(global_id),
        icon: 'favorite',
      })
    }

    if (props.updateProviders) {
      options.push({
        label: t('AddToFavorites'),
        value: 'addToFavorites',
        handler: () => props.updateProviders(global_id),
        icon: 'favorite',
      })
    }
  }

  if (props.setBuildingModal) {
    options.push({
      label: t('SetBuildings'),
      value: 'setBuildings',
      handler: () => props.setBuildingModal(provider),
      icon: 'building',
    })
  }

  if (props.setCategoryModal) {
    options.push({
      label: t('SetCategories'),
      value: 'setCategories',
      handler: () => props.setCategoryModal(provider),
      icon: 'objects',
    })
  }

  return (
    <NewTableBodyRow checked={checked} onClick={handleClick}>
      <NewTableBodyCell title={name}>
        {isAdmin && (
          <Checkbox
            style={{ margin: '0 0 0 16px' }}
            checked={checked}
            onChange={handleChange}
          />
        )}
        <i className='table-item__user-pic' style={{ marginLeft: '16px' }}>
          <img src={avatar} alt='user' />
        </i>
      </NewTableBodyCell>
      <NewTableBodyCell title={map(categories, 'name').toString()} />
      <NewTableBodyCell title={map(buildings, 'address').toString()} />
      <NewTableBodyCell title={email} />
      <NewTableBodyCell title={phone} />
      <NewTableBodyCell title={offer_count}>
        <div className='table-extra table-extra--settings'>
          <SelectCustom
            options={options}
            onChange={({ handler }) => handler()}
          />
        </div>
      </NewTableBodyCell>
    </NewTableBodyRow>
  )
}

export default AllProvidersTableBodyRow
