import { createSelector } from 'reselect'

export const getManagers = (state) => state.realData.managers
export const getFormattedManagersList = createSelector(
  getManagers,
  (managers) => managers.map(({ realdata_id: realdataId, name }) => ({
    value: realdataId,
    label: name
  }))
)
