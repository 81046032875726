// @flow

import React, { Component } from 'react'
import { isArray } from 'lodash-es'
import classNames from 'classnames'
import type { Node } from 'react'

import ValueItem from '../Select/ValueItem'

const CLICK_PROP = navigator.userAgent.match(/Android/i)
  ? 'onTouchStart'
  : 'onClick'

type Props = {
  cleanable?: boolean,
  disabled?: boolean,
  EmptyMenu?: Node,
  fullWidth?: boolean,
  inputError?: boolean,
  more?: boolean,
  multi?: boolean,
  onBlur?: Object => void,
  onClick: Object => void,
  onRef: Object => void,
  onRemove?: () => void,
  profile?: boolean,
  round?: boolean,
  text: string,
  value: ?any,
}

class SelectButton extends Component<Props> {
  renderValue = (): Node => {
    const {
      fullWidth,
      cleanable,
      disabled,
      more,
      multi,
      value,
      onRemove,
      text,
      profile,
      round,
      inputError,
    } = this.props

    const avatar = profile && !isArray(value) && value ? value.avatar : null
    const showArrow = !more && (Array.isArray(value) ? !value.length : !value)

    return (
      <ValueItem
        arrow={showArrow}
        avatar={avatar}
        cleanable={!multi && value && cleanable && !disabled}
        clickable={!disabled}
        disabled={disabled}
        error={inputError}
        fullWidth={fullWidth}
        more={more}
        profile={profile}
        round={round}
        text={text}
        onRemove={onRemove}
      />
    )
  }

  render(): Node {
    const { disabled, fullWidth, inputError, EmptyMenu } = this.props

    const props = {
      disabled,
      [CLICK_PROP]: !disabled ? this.props.onClick : undefined,
      ref: this.props.onRef,
      style: fullWidth ? { width: '100%' } : null,
    }

    if (EmptyMenu && this.props.onBlur) {
      const className = classNames('select-fake-input-button', {
        'input--error': inputError,
      })

      return (
        <button
          type='button'
          {...props}
          className={className}
          onBlur={this.props.onBlur}
          onClick={this.props.onClick}
        >
          {this.renderValue()}
        </button>
      )
    }

    return <div {...props}>{this.renderValue()}</div>
  }
}

export default SelectButton
