// @flow

import React from 'react'
import type { Element } from 'react'

import SelectCustom from '../../../../../Select/SelectCustom'
import {
  USER_GROUPS,
  USER_STATUS,
  BLOCKED_STATUS,
  NOT_CONFIRMED_STATUS,
  IMPORTED_STATUS,
} from '../../../../../../constants'
import ConfirmationPopup from '../../../../../modals/ConfirmationPopup'
import ResendInvitePopUp from '../../../../../modals/ResendInvitePopUp'
import {
  canApprove,
  canBlock,
  canReinvite,
  canUnblock,
  getUserStatusKey,
} from './UserListItem.utils'
import { getUserId } from '../../../../../../utils/utils'

type User = {
  name: string,
  owner: number,
  status: number,
}

type Props = {
  canDelete: boolean,
  canUpdateRole: boolean,
  getParams: () => {
    counters: number,
    group: string,
    page: number,
  },
  isArchived: boolean,
  setModal: (?Element<'div'>) => void,
  t: (string, ?Object) => string,
  user: User,
}

const ExtraActions = (props: Props) => {
  const {
    user,
    canDelete,
    canUpdateRole,
    meta,
    user: { name, status },
    isArchived,
  } = props

  const isBlocked = () => USER_STATUS[status] === BLOCKED_STATUS

  const hideModal = () => {
    props.setModal(null)
  }

  const handleApprove = () => {
    props.setModal(
      <ConfirmationPopup
        title={props.t('ApproveModalTitle')}
        text={props.t('ApproveModalText', { name })}
        confirm={props.t('ApproveButtonTitle')}
        cancel={props.t('Cancel')}
        onClose={hideModal}
        onOk={approve}
      />
    )
  }

  const approve = () => props.approve([user], props.getParams(), meta)

  const handleSave = () => {
    props.load()
  }

  const handleReinvite = () => {
    props.setModal(
      <ResendInvitePopUp
        single
        reinvite
        group={USER_GROUPS.dweller}
        user={user}
        usersResend={[getUserId(user)]}
        onHide={hideModal}
        onSave={handleSave}
      />
    )
  }

  const remove = () => props.remove([user.id], props.getParams(), meta)

  const handleRemove = () => {
    props.setModal(
      <ConfirmationPopup
        title={props.t('DeleteTitle')}
        text={props.t('DeleteText', { name: user.fullname })}
        confirm={props.t('Delete')}
        cancel={props.t('Cancel')}
        onClose={hideModal}
        onOk={remove}
      />
    )
  }

  const handleStatusUpdate = () => {
    const verb = isBlocked() ? 'unblock' : 'block'

    props.setModal(
      <ConfirmationPopup
        title={props.t(`User ${verb}ing`)}
        text={props.t('Are you sure you want to continue?')}
        confirm={props.t(verb)}
        cancel={props.t('Common:Cancel')}
        onClose={hideModal}
        onOk={updateUser}
      />
    )
  }

  const updateUser = () => {
    const newStatus = isBlocked()
      ? getUserStatusKey(IMPORTED_STATUS)
      : getUserStatusKey(BLOCKED_STATUS)

    props.updateUser(getUserId(user), { status: newStatus })
  }

  const getOptions = () => {
    let options = [
      {
        value: 'checkProfile',
        label: props.t('CheckProfile'),
        handler: props.open,
        icon: 'eye',
      },
    ]

    const userStatus = USER_STATUS[user.status]

    if (!isArchived && userStatus !== NOT_CONFIRMED_STATUS) {
      options.push({
        value: 'moveToOtherFlat',
        label: props.t('MoveToOtherFlat'),
        handler: () => props.showBuildingModal(user),
        icon: 'move',
      })
    }

    if (canReinvite(user)) {
      options.push({
        value: 'sentInvitation',
        label: props.t('SentInvitation'),
        handler: handleReinvite,
        icon: 'forward',
      })
    }

    if (canUpdateRole) {
      options.push({
        value: 'changeRole',
        label: props.t('ChangeRole'),
        handler: () => props.confirmRoleUpdating(user),
        icon: 'pencil',
      })
    }

    if (!isArchived && canUnblock(user)) {
      options.push({
        value: 'unblock',
        icon: 'lock',
        label: props.t('unblock'),
        handler: handleStatusUpdate,
      })
    }

    if (canBlock(user)) {
      options.push({
        value: 'block',
        icon: 'lock',
        label: props.t('block'),
        handler: handleStatusUpdate,
      })
    }

    if (canDelete) {
      options.push({
        value: 'remove',
        label: props.t('Remove'),
        handler: handleRemove,
        icon: 'bin',
      })
    }

    if (canApprove(user)) {
      options.push({
        value: 'approveSignup',
        label: props.t('ApproveSignup'),
        handler: handleApprove,
        icon: 'check',
      })
    }

    return options
  }

  const preventOpen = e => {
    e.stopPropagation()
  }

  return (
    <div className='table-extra table-extra--settings'>
      <SelectCustom
        options={getOptions()}
        onClick={preventOpen}
        onChange={opt => opt.handler()}
      />
    </div>
  )
}

export default ExtraActions
