// @flow

import React, { useState, useEffect } from 'react'
import { intersection } from 'lodash-es'
import { useSelector, useDispatch } from 'react-redux'
import type { Node } from 'react'

import Loader from '../../Loader'
import AudienceListItem from './AudienceListItem'
import {
  SIGNUP_AUDIENCE,
  NOT_SIGNUP_AUDIENCE,
  TO_ALL_SIGNEDUP,
} from '../../Post/Post.constants'
import {
  isGroupedAudience,
  getCheckedAudienceList,
} from '../../Post/Post.utils'
import { audienceListLoading } from './AudienceList.actions'
import { getUser } from '../../../utils/commonSelectors'
import { USER_GROUPS } from '../../../constants'

type Props = {
  postType: string,
  scope: Array<string>,
  toggleOption: string => void,
}

const AudienceList = (props: Props): Node => {
  const { scope, postType } = props

  const dispatch = useDispatch()

  const { loading, loaded, audienceList } = useSelector(
    state => state.audienceList
  )

  const user = useSelector(state => getUser(state))

  useEffect(() => {
    if (!loaded && !loading) {
      dispatch(audienceListLoading())
    }
  }, [])

  const isIntersect = audience => intersection(scope, audience).length > 0

  const [signedUp, setSignedUp] = useState(isIntersect(SIGNUP_AUDIENCE))
  const [notSignedUp, setNotSignedUp] = useState(
    isIntersect(NOT_SIGNUP_AUDIENCE)
  )
  const [allBuildings, setAllBuildings] = useState(isIntersect(SIGNUP_AUDIENCE))

  const isAllSignedUp = item => item === TO_ALL_SIGNEDUP

  const isChecked = item => {
    if (isGroupedAudience(item)) {
      if (user.group === USER_GROUPS.dweller) {
        return allBuildings
      }

      return isAllSignedUp(item) ? signedUp : notSignedUp
    }

    return scope.includes(item)
  }

  const getCb = item => {
    if (isGroupedAudience(item)) {
      if (user.group === USER_GROUPS.dweller) {
        return setAllBuildings
      }

      return isAllSignedUp(item) ? setSignedUp : setNotSignedUp
    }
  }

  if (loading) {
    return <Loader />
  }

  if (!loaded) {
    return null
  }

  const checkedAudienceList = getCheckedAudienceList(
    audienceList,
    user,
    postType
  )

  return (
    <>
      {checkedAudienceList.map(item => (
        <AudienceListItem
          item={item}
          checked={isChecked(item)}
          key={item}
          toggleOption={props.toggleOption}
          scope={scope}
          cb={getCb(item)}
        />
      ))}
    </>
  )
}

export default AudienceList
