// @flow

import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { push, replace } from 'connected-react-router'
import type { Node } from 'react'

import {
  getContractors,
  getArchivedContrators,
} from '../../core/api/api.contractor'

import { updateRequest } from '../../core/api/api.massAction'

import { getCurrentFilters, getCurrentPage } from '../../utils/routing'

import ContractorsTable from './ContractorsTable'
import EmptyList from '../EmptyList'

import Toolbox from '../Toolbox'
import Checkbox from '../Checkbox'
import Button from '../Button'
import BottomPagination from '../Pagination/BottomPagination'
import SelectCustom from '../Select/SelectCustom'

import Modal from '../Modal'
import ConfirmationPopup from '../modals/ConfirmationPopup'

import BuildingMappingModal from '../../containers/ProvidersPage/AllProviders/BuildingMappingModal'
import CategoryMappingModal from '../../containers/ProvidersPage/AllProviders/CategoryMappingModal'

import commonStyles from './common.module.scss'

const Contractors = (props): Node => {
  const { t } = useTranslation('Contractors')
  const dispatch = useDispatch()
  const location = useLocation()

  const [loading, setLoading] = useState(true)
  const [contractors, setContractors] = useState([])
  const [selectedContractors, setSelectedContractors] = useState([])
  const [meta, setMeta] = useState({})
  const [permissions, setPermissions] = useState({})
  const [modal, setModal] = useState(null)
  const [buildingModal, setBuildingModal] = useState(null)
  const [categoryModal, setCategoryModal] = useState(null)

  const { can_create, can_delete } = permissions
  const filters = getCurrentFilters(location)
  const soft_archived = filters.sub === 'soft_archived'

  useEffect(() => {
    if (loading) {
      const page = getCurrentPage(location)
      const api = soft_archived ? getArchivedContrators : getContractors
      const building = props.building
      api({ building, ...filters, page })
        .then(data => {
          setContractors(data.results.objects)
          setMeta(data.meta)
          setPermissions(data.permissions)
          setSelectedContractors([])
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
          dispatch(replace(location.pathname))
        })
    }
  }, [loading])

  useEffect(() => {
    setLoading(true)
    setSelectedContractors([])
  }, [location])

  const handleAddContractor = () => dispatch(push('/contractor/create'))

  const updateContractors = (ids, restore) => {
    updateRequest({
      model: 'Profile',
      model_pks: JSON.stringify(ids),
      patch: { soft_archived: !restore },
    }).then(() => {
      setSelectedContractors([])
      hideModal()
      setLoading(true)
    })
  }

  const toggleContractor = id => {
    return () => {
      if (selectedContractors.includes(id)) {
        setSelectedContractors(
          selectedContractors.filter(contractorId => contractorId !== id)
        )
      } else {
        setSelectedContractors([...selectedContractors, id])
      }
    }
  }

  const toggleAll = () => {
    if (selectedContractors.length !== contractors.length) {
      setSelectedContractors(contractors.map(contractor => contractor.id))
    } else {
      setSelectedContractors([])
    }
  }

  const getContractorName = id => {
    const contractor = contractors.find(c => c.id === id)

    return contractor?.name || ''
  }

  const confirmDelete = ids => {
    setModal(
      <ConfirmationPopup
        title={t('RemoveTitle')}
        text={
          ids.length > 1
            ? t('RemoveText', { count: ids.length })
            : t('RemoveTextOne', { name: getContractorName(ids[0]) })
        }
        confirm={t('Archive')}
        cancel={t('Cancel')}
        onClose={hideModal}
        onOk={() => updateContractors(ids)}
      />
    )
  }

  const confirmRestore = ids => {
    setModal(
      <ConfirmationPopup
        isAsync
        title={t('RestoreTitle')}
        text={
          ids.length > 1
            ? t('RestoreText', { count: ids.length })
            : t('RestoreTextOne', { name: getContractorName(ids[0]) })
        }
        confirm={t('Restore')}
        cancel={t('Cancel')}
        onClose={hideModal}
        onOk={() => updateContractors(ids, true)}
      />
    )
  }

  const hideModal = () => setModal(null)
  const handleCloseBuildingModal = () => setBuildingModal(null)
  const handleCloseCategoryModal = () => setCategoryModal(null)

  const getOptions = () => [
    { value: 'setBuildings', label: t('SetBuildings') },
    { value: 'setCategories', label: t('SetCategories') },
  ]

  const moreOptions = ({ value }) => {
    switch (value) {
      case 'setBuildings':
        setBuildingModal({ contractors: selectedContractors, massAction: true })
        break
      case 'setCategories':
        setCategoryModal({ contractors: selectedContractors, massAction: true })
        break
      default:
    }
  }

  const renderControls = () => {
    return (
      !props.building && (
        <Toolbox>
          <div className='toolbox__cell'>
            <Checkbox
              outer
              checked={
                !loading && selectedContractors.length === contractors.length
              }
              onChange={toggleAll}
            />
          </div>
          {!soft_archived && can_create && !selectedContractors.length && (
            <div className='toolbox__cell'>
              <Button.Save
                type='button'
                icon='provider'
                onClick={handleAddContractor}
              >
                {t('Add')}
              </Button.Save>
            </div>
          )}
          {!soft_archived && can_delete && !!selectedContractors.length && (
            <div className='toolbox__cell'>
              <Button.Remove
                type='button'
                disabled={selectedContractors.length === 0}
                onClick={() => confirmDelete(selectedContractors)}
              >
                {t('Archive')}
              </Button.Remove>
            </div>
          )}
          {soft_archived && can_delete && !!selectedContractors.length && (
            <div className='toolbox__cell'>
              <Button.Restore
                type='button'
                disabled={selectedContractors.length === 0}
                onClick={() => confirmRestore(selectedContractors)}
              >
                {t('Restore')}
              </Button.Restore>
            </div>
          )}
          {!soft_archived && !!selectedContractors.length && (
            <SelectCustom
              alignLeft
              buttonClass='button button--large button--dropdown button--showmore '
              style={{ marginLeft: '8px' }}
              options={getOptions()}
              onChange={moreOptions}
            >
              {t('More')}
            </SelectCustom>
          )}
        </Toolbox>
      )
    )
  }

  const containerClass = classnames(commonStyles.container, {
    'working-overlay': loading,
  })

  const renderTable = () => {
    if (contractors.length === 0 && !loading) {
      return (
        <EmptyList
          icon='provider'
          title={t('NoFound')}
          canAdd={!soft_archived && can_create}
          btnText={t('Add')}
          btnIcon='provider'
          onClick={handleAddContractor}
        />
      )
    } else {
      return (
        <div className={commonStyles.content}>
          {renderControls()}
          <ContractorsTable
            contractors={contractors}
            selectedItems={selectedContractors}
            loading={loading}
            toggleContractor={toggleContractor}
            setBuildingModal={setBuildingModal}
            setCategoryModal={setCategoryModal}
            fromBuildings={!!props.building}
            onRemove={id => confirmDelete([id])}
            onRestore={id => confirmRestore([id])}
          />
          {!loading && <BottomPagination classes='paginator-2' meta={meta} />}
        </div>
      )
    }
  }

  return (
    <div className={commonStyles.root}>
      <div className={containerClass}>
        <div className={commonStyles.title}>
          {t('Title')}
          {!!meta.count && (
            <>
              &nbsp;<span className={commonStyles.number}>{meta.count}</span>
            </>
          )}
        </div>
        {renderTable()}
      </div>
      {!!modal && (
        <Modal isOpen={!!modal} onRequestClose={hideModal}>
          {modal}
        </Modal>
      )}
      {buildingModal && (
        <BuildingMappingModal
          isContractor
          buildingModal={buildingModal}
          setLoading={setLoading}
          onClose={handleCloseBuildingModal}
        />
      )}
      {categoryModal && (
        <CategoryMappingModal
          isContractor
          categoryModal={categoryModal}
          setLoading={setLoading}
          onClose={handleCloseCategoryModal}
        />
      )}
    </div>
  )
}

export default Contractors
