// @flow

import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import message from 'antd/es/message'
import { useSelector } from 'react-redux'

import CreateOrderPageForm from './CreateOrderPageForm'
import CreateOrderMutation from './CreateOrderPage.mutation'
import { MARKETPLACE_ROUTES } from '../../constants'
import { updateRequestRaw } from '../../core/api/api.request'
import { USER_GROUPS } from '../../constants'
import { getUser } from '../../utils/commonSelectors'

import 'antd/es/message/style/index.css'
import commonStyles from '../ProvidersPage/AllProviders/common.module.scss'

// TODO сюда в роут search нужно забирать providerId
const CreateOrderPage = props => {
  const user = useSelector(state => getUser(state))
  const [request, setRequest] = useState(null)
  const [loading, setLoading] = useState(false)

  if (user.group === USER_GROUPS.dweller) {
    return <Redirect to='/404' />
  }

  const {
    history: {
      location: { search },
    },
  } = props

  const searchParams = new URLSearchParams(search)
  const providerId = searchParams.get('provider')
  const providerName = searchParams.get('name')
  const providerAvatar = searchParams.get('avatar')

  if (!providerId || !providerName) {
    return <Redirect to='/404' />
  }

  const handleSubmit = values => {
    CreateOrderMutation.commit(
      {
        ...values,
        providerId,
      },
      (info, errors) => {
        if (errors && errors.length > 0) {
          // TODO show errors
          const errorData = JSON.stringify(errors)
          // captureException(errorData)
          setLoading(false)
          message.error(errorData)
        } else {
          const { noOfferOrder } = info

          if (noOfferOrder && 'order' in noOfferOrder) {
            const { order } = noOfferOrder
            const { id: orderId } = order

            if (orderId) {
              if (request) {
                updateRequestRaw(request.id, {
                  order_ids: orderId ? [orderId] : [],
                }).then(() =>
                  props.history.push(
                    MARKETPLACE_ROUTES['marketplaceOrder'](orderId)
                  )
                )
              } else {
                props.history.push(
                  MARKETPLACE_ROUTES['marketplaceOrder'](orderId)
                )
              }
            } else {
              // TODO show error
            }
          }
        }
      },
      error => {
        setLoading(false)
        // error callback
        message.error(error)
      }
    )
  }

  return (
    <div className={commonStyles.root}>
      <CreateOrderPageForm
        loading={loading}
        providerId={providerId}
        providerAvatar={providerAvatar}
        providerName={providerName}
        setRequest={setRequest}
        onSubmit={handleSubmit}
        onLoading={setLoading}
      />
    </div>
  )
}

export default CreateOrderPage
