// @flow

import React, { useState } from 'react'
import type { Node } from 'react'

import { getInvoiceTypes } from '../../core/api/api.budgetPlan'
import { useLoad } from '../../hooks'
import InvoiceTypeModal from './InvoiceTypeModal'
import InvoiceTypeTable from './InvoiceTypeTable'

const InvoiceType = (): Node => {
  const [isModalOpen, setModalOpen] = useState(false)

  const [editingInvoiceType, setEditingInvoiceType] = useState(null)

  const [data, isLoading, isError, load] = useLoad(getInvoiceTypes)

  // TODO Need error component
  if (isError) {
    return null
  }

  const handleClose = () => setModalOpen(false)
  const handleCreate = () => setModalOpen(true)

  return (
    <>
      {data && (
        <>
          <InvoiceTypeTable isLoading={isLoading} onEdit={handleCreate} />
          {isModalOpen && (
            <InvoiceTypeModal
              load={load}
              editingInvoiceType={editingInvoiceType}
              setEditingInvoiceType={setEditingInvoiceType}
              onClose={handleClose}
            />
          )}
        </>
      )}
    </>
  )
}

export default InvoiceType
