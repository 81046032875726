// @flow

import React from 'react'
import type { Node } from 'react'

import EmailIntegrationCreate from './EmailIntegrationCreate'

const CorporateEmailIntegrationCreate = (): Node => {
  return <EmailIntegrationCreate />
}

export default CorporateEmailIntegrationCreate
