// @flow

import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import type { Element } from 'react'

import { getLocation } from '../../../../../utils/commonSelectors'
import UserListItem from './UserListItem'

type Props = {
  permissions: Object,
  selected: Array<number>,
  setModal: (Element<'div'>) => void,
  statuses: Object,
  users: Array<Object>,
}

const UserListBody = (props: Props) => {
  const { permissions, selected, statuses, users, meta } = props

  return (
    <Fragment>
      {users.map(user => (
        <UserListItem
          meta={meta}
          key={user.id}
          permissions={permissions}
          statuses={statuses}
          setModal={props.setModal}
          user={user}
          load={props.load}
          isSelected={selected.includes(user.id)}
        />
      ))}
    </Fragment>
  )
}

const mapStateToProps = state => ({
  ...state.userList,
  location: getLocation(state),
  statuses: state.init.user_status,
})

export default connect(mapStateToProps)(UserListBody)
