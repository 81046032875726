// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import { get } from 'lodash-es'

import { globalModalError } from '../Layout/Layout.actions'
import NewFilesUpload from '../NewFilesUpload'
import EmptyList from '../EmptyList'
import Button from '../Button'
import DocumentMappingTableHeader from './DocumentMappingTableHeader'
import DocumentMappingTableBody from './DocumentMappingTableBody'
import { importDocument } from '../../core/api/api.documentMapping'
import { getStatus } from '../MappedDocument/MappedDocument.utils'

type Props = {
  documents: Array<Object>,
  loading: boolean,
}

const DocumentMappingTable = (props: Props) => {
  const { loading, documents, selectedItems } = props

  const { t } = useTranslation('DocumentMapping')

  const dispatch = useDispatch()

  const tableClass = classnames('table table--default', {
    'working-overlay': loading,
  })

  const handleStartUpload = () => {
    props.setUploading(true)
  }

  const handleFinishUpload = file => {
    if (!file || !file.length) {
      props.setUploading(false)

      return
    }

    importDocument({ file: file[0].id })
      .then((data: Object) => getStatus(data.eventId))
      .then((data: Object) => {
        const status = get(data, 'status')

        if (status === 'error') {
          const action: Object = globalModalError(
            t('WrongFileFormatText'),
            t('WrongFileFormatTitle')
          )

          props.setUploading(false)
          dispatch(action)
        } else {
          dispatch(push(`/document-mapping/${data.instance}/`))
        }
      })
  }

  return (
    <div className={tableClass}>
      <div className='table__wrapper' style={{ tableLayout: 'fixed' }}>
        <DocumentMappingTableHeader />
        <DocumentMappingTableBody
          setChecking={props.setChecking}
          documents={documents}
          selectedItems={selectedItems}
          changeSelected={props.changeSelected}
        />
      </div>
      {!documents.length && !loading && (
        <EmptyList
          embedded
          canAdd
          icon='request'
          title={t('DocumentsNotFound')}
          additionalButton={
            <NewFilesUpload
              accept='application/pdf'
              name='attach-document'
              maxSize={1000}
              onFinishUpload={handleFinishUpload}
              onStartUpload={handleStartUpload}
            >
              <Button.Save>{t('AddDocument')}</Button.Save>
            </NewFilesUpload>
          }
        />
      )}
    </div>
  )
}

export default DocumentMappingTable
