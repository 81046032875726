// @flow

import * as actions from './RequestCreate.actionTypes'

const initialState = {
  id: null,
  working: false,
  loaded: false,
  data: null,
}

type Action = { id: ?number, type: string }

type State = { id: ?number, working: boolean }

export default (state: State = initialState, action: Action) => {
  switch (action.type) {
    case actions.REQUEST_CREATE_RESET:
    case actions.REQUEST_CREATE_ERROR:
      return initialState
    case actions.REQUEST_CREATE_CREATE:
      return {
        ...state,
        working: true,
      }
    case actions.REQUEST_CREATE_CREATED:
      return {
        ...state,
        id: action.id,
      }
    case actions.REQUEST_DATA_LOAD:
      return {
        data: action.data,
        loaded: true,
      }
    default:
      return state
  }
}
