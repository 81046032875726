// @flow

import React, { PureComponent, Fragment } from 'react'
import ReactRating from 'react-rating'
import classnames from 'classnames'
import { withTranslation } from 'react-i18next'

import ActiveIcon from '../../../static/icons/active-star.svg'
import UnActiveIcon from '../../../static/icons/unactive-star.svg'

import styles from './Rating.module.scss'

// TODO create normal array or object with string keys
const RATING_LABELS = [
  undefined,
  'JustDisaster',
  'VeryBad',
  'SoSo',
  'Good',
  'Excellent',
]

type Props = {
  big?: boolean,
  label?: boolean,
  onClick?: () => void,
  readonly?: boolean,
  t: string => string,
  value?: number,
}

type State = {
  hoverLabel: ?string,
}

class Rating extends PureComponent<Props, State> {
  state = {
    hoverLabel: null,
  }

  static defaultprops = {
    readonly: false,
    big: false,
    label: false,
    onClick: null,
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ hoverLabel: null })
    }
  }

  handleHover = rating => {
    const { label } = this.props

    if (label) {
      this.setState({ hoverLabel: rating })
    }
  }

  getStarClassName = extraClass =>
    classnames(styles.star, extraClass, {
      [styles.big]: this.props.big,
    })

  render() {
    const { big, label, value, readonly } = this.props
    const { hoverLabel } = this.state
    const labelClassName = classnames(styles.label, {
      [styles.labelBig]: big,
    })
    const ratingIndex = hoverLabel || value
    const getLabel = this.props.t(
      ratingIndex ? RATING_LABELS[parseInt(ratingIndex)] : 'NoRating'
    )
    const emptyClassName = this.getStarClassName('star__empty')
    const fullClassName = this.getStarClassName('star__full')

    return (
      <Fragment>
        <ReactRating
          className={styles.container}
          initialRating={value}
          readonly={readonly}
          emptySymbol={<UnActiveIcon className={emptyClassName} />}
          fullSymbol={<ActiveIcon className={fullClassName} />}
          onClick={this.props.onClick}
          onHover={this.handleHover}
        />
        {label && <span className={labelClassName}>{getLabel}</span>}
      </Fragment>
    )
  }
}

export default withTranslation('Feedback')(Rating)
