// @flow

import React from 'react'
import { get } from 'lodash-es'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { MARKETPLACE_ROUTES } from '../../constants'
import Card from '../../components/Card'
import Stat from '../../components/Stat'
import { getAvatar, parseMpPrice } from '../../utils/utils'
import Managers from './Managers'
import { getUser } from '../../utils/commonSelectors'

const DEFAULT_OFFER_IMAGE = '/static/images/offer_default.png'

const OfferItem = props => {
  const {
    offer: {
      node: { id, name, finalPrice, orderCount, gallery, provider, priceType },
    },
  } = props

  const {
    language_obj: { code },
  } = useSelector(getUser)

  const { t } = useTranslation('Offers')

  const isNullPrice = parseFloat(finalPrice) === 0

  const priceName = get(priceType, 'name', '')

  const price = isNullPrice
    ? t('CostWilBeSpecified')
    : t('price', {
        price: parseMpPrice(finalPrice, code),
        priceType: priceName,
      })

  return (
    <Card
      text={name}
      price={price}
      link={MARKETPLACE_ROUTES['marketplaceOffer'](id)}
      imgSrc={
        gallery.length > 0
          ? getAvatar(gallery.find(img => img.default))
          : DEFAULT_OFFER_IMAGE
      }
    >
      <Stat iconId='order' count={orderCount} />
      <Managers managers provider={provider} />
    </Card>
  )
}

export default OfferItem
