// @flow

import React from 'react'
import type { Node } from 'react'

import styles from './ThreadsListItemNotes.module.scss'

type Props = { notes: ?string }

const ThreadsListItemNotes = (props: Props): Node => {
  const { notes } = props

  if (!notes) {
    return null
  }

  return (
    <div title={notes} className={styles.notes}>
      {notes}
    </div>
  )
}

export default ThreadsListItemNotes
