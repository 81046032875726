// @flow

import React from 'react'
import { useState } from 'react'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import type { Node } from 'react'

import ModalCloseButton from '../../Modal/ModalCloseButton'
import ModalHead from '../../Modal/ModalHead'
import ModalBody from '../../Modal/ModalBody'
import ModalRow from '../../Modal/ModalRow'
import ModalLabel from '../../Modal/ModalLabel'
import InputField from '../../InputField/InputField'
import ModalButtons from '../../Modal/ModalButtons'
import NewSelectAsync from '../../NewSelectAsync'
import { getListTiny } from '../../../core/api/api.checklist'
import Button from '../../Button'
import { useOverflow, useKeyboard } from '../../../hooks'

import styles from './AddCheckListPopUp.module.scss'

type Props = { onCreate: (string, number | void) => void, onHide: () => void }

const CheckListPopUp = (props: Props): Node => {
  const [isLoading, setLoading] = useState(false)

  const { t } = useTranslation('Request')

  useOverflow()
  useKeyboard()

  const formik = useFormik({
    validateOnChange: true,
    enableReinitialize: true,
    initialValues: { name: '', checklist: null },
    validationSchema: Yup.object({
      name: Yup.string()
        .max(128)
        .trim(t('fieldIsRequired'))
        .required(t('fieldIsRequired')),
      checklist: Yup.object().nullable(),
    }),
    onSubmit: submitValues => {
      setLoading(true)

      const { name, checklist } = submitValues

      props.onCreate(name, checklist ? checklist.id : undefined)
    },
  })

  const handleChangeChecklist = checklist =>
    formik.setFieldValue('checklist', checklist)

  const handleClear = () => formik.setFieldValue('checklist', null)

  return (
    <>
      <ModalCloseButton onClose={props.onHide} />
      <ModalHead title={t('AddChecklist')} />
      <form onSubmit={formik.handleSubmit}>
        <ModalBody>
          <ModalRow>
            <ModalLabel>{t('ChecklistName')}</ModalLabel>
          </ModalRow>
          <ModalRow className={styles.row}>
            <InputField
              autoFocus
              name='name'
              type='text'
              disabled={isLoading}
              error={formik.errors.name}
              placeholder={t('EnterChecklistName')}
              value={formik.values.name}
              className={styles.input}
              onChange={formik.handleChange}
            />
          </ModalRow>
          <ModalRow>
            <ModalLabel>{t('UseElementsFrom')}</ModalLabel>
          </ModalRow>
          <NewSelectAsync
            pageSize={10}
            disabled={isLoading}
            api={getListTiny}
            searchKey='name'
            placeholder={t('Select:ChooseChecklistPattern')}
            view='dropdown'
            getLabel={a => a.name}
            getSelectedLabel={a => a.name}
            selectedItems={
              formik.values.checklist ? [formik.values.checklist] : []
            }
            className={styles.select}
            onClick={handleChangeChecklist}
            onClear={handleClear}
          />
        </ModalBody>
        <ModalButtons>
          <Button.Save type='submit' disabled={isLoading}>
            {t('Add')}
          </Button.Save>
          <Button.Cancel disabled={isLoading} onClick={props.onHide}>
            {t('Cancel')}
          </Button.Cancel>
        </ModalButtons>
      </form>
    </>
  )
}

export default CheckListPopUp
