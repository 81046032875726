// @flow

import React from 'react'
import type { Node } from 'react'
import { useTranslation } from 'react-i18next'

import Cancel from './Cancel'
import type { Props } from './Regular/Regular'

const Restore = (props: Props): Node => {
  const { children } = props

  const { t } = useTranslation('Common')

  return (
    <Cancel icon='restore' {...props}>
      {children || t('Restore')}
    </Cancel>
  )
}

export default Restore
