// @flow

import { useEffect } from 'react'

export const useOverflow = () => {
  useEffect(() => {
    document.body && (document.body.style.overflowY = 'hidden')

    return function cleanup() {
      document.body && (document.body.style.overflowY = 'scroll')
    }
  }, [])
}
