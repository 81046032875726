// @flow

import { omitBy, partial } from 'lodash-es'

import { parseMpPrice } from '../../../utils/utils'

import {
  CURRENCY_TYPE,
  INCLUDE_TAX,
  MAX_RETAINED_AMOUNT,
  MIN_RETAINED_AMOUNT,
  PERCENT_TYPE,
  RETAINED_AMOUNT_IN_CURRENCY,
  RETAINED_AMOUNT_IN_PERCENT,
  RETAINED_AMOUNT_TYPE,
  TAX,
  TOTAL_ESTIMATED_AMOUNT,
  DEFAULT_AMOUNT_TYPE,
} from './BudgetPlanForm.constants'

export const compare = (
  initialValues: Object,
  value: any,
  key: string
): boolean => initialValues[key] === value

export function parsePrice(plan: Object, key: string): string {
  return plan[key] ? parseMpPrice(plan[key], 'de') : ''
}

export function getInitialValues(plan: Object, code: ?string): Object {
  let initialTax = ''

  if (code === 'AT') {
    initialTax = '20.00'
  } else if (code === 'DE') {
    initialTax = '19.00'
  }

  return {
    [RETAINED_AMOUNT_TYPE]: plan[RETAINED_AMOUNT_TYPE] || DEFAULT_AMOUNT_TYPE,
    [RETAINED_AMOUNT_IN_PERCENT]: plan[RETAINED_AMOUNT_IN_PERCENT] || '',
    [INCLUDE_TAX]: !!plan[INCLUDE_TAX],
    [TAX]: plan[TAX] || initialTax,
    [TOTAL_ESTIMATED_AMOUNT]: plan[TOTAL_ESTIMATED_AMOUNT] || '',
    [RETAINED_AMOUNT_IN_CURRENCY]: plan[RETAINED_AMOUNT_IN_CURRENCY] || '',
    [MAX_RETAINED_AMOUNT]: plan[MAX_RETAINED_AMOUNT] || '',
    [MIN_RETAINED_AMOUNT]: plan[MIN_RETAINED_AMOUNT] || '',
  }
}

export function getAmountTypeOptions(): Array<Object> {
  return [
    { value: CURRENCY_TYPE, label: '€' },
    { value: PERCENT_TYPE, label: '%' },
  ]
}

export function normalizeSubmittedvalues(
  initialValues: Object,
  submitValues: Object
): Object {
  const partialCompare = partial(compare, initialValues)

  const changedValues = omitBy(submitValues, partialCompare)

  return changedValues
}
