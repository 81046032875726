// @flow

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'

import * as actions from './UserListInfo.actionTypes'

type Props = {
  dispatch: ({
    type: 'USER_LIST_INFO_INITIATING' | 'USER_LIST_INFO_CLEANUP',
  }) => void,
  dwellersCount: number,
  initiated: boolean,
  onInit: () => void,
  staffCount: number,
  t: string => string,
}

class UserListInfo extends Component<Props> {
  componentDidMount() {
    this.props.dispatch({ type: actions.USER_LIST_INFO_INITIATING })
  }

  componentDidUpdate(prevProps: Props) {
    const { initiated } = prevProps

    if (this.props.initiated && !initiated) {
      this.props.onInit()
    }
  }

  componentWillUnmount() {
    this.props.dispatch({ type: actions.USER_LIST_INFO_CLEANUP })
  }

  render = () => <div style={{ paddingTop: '20px' }}></div>
}

const mapStateToProps = state => ({
  ...state.userListInfo,
})

export default compose(
  connect(mapStateToProps),
  withTranslation('User')
)(UserListInfo)
