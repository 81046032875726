// @flow

import React from 'react'
import { Link } from 'react-router-dom'
import type { Node } from 'react'

import styles from './LoginLink.module.scss'

type Props = {
  infoText?: string,
  linkText: string,
}

const LoginLink = (props: Props): Node => {
  const { linkText, infoText } = props

  return (
    <div className={styles.loginLink}>
      {infoText && <span>{infoText}</span>}
      <Link to='/login'>{linkText}</Link>
    </div>
  )
}

export default LoginLink
