// @flow

import { all, put, call, fork, takeEvery } from 'redux-saga/effects'
import { replace } from 'connected-react-router'
import { omitBy, isNull, pickBy, identity, isUndefined } from 'lodash-es'

import { SERVER_404_ERROR } from '../Layout/Layout.constants'
import api from '../../core/api'
import { serverError, modalError } from '../Layout/Layout.actions'
import * as actions from './BuildingView.actionTypes'

function* watchInit() {
  yield takeEvery(actions.BUILDING_VIEW_INITIATING, init)
}

function* watchUpdateInfo() {
  yield takeEvery(actions.BUILDING_VIEW_NEED_UPDATE, updateInfo)
}

function* watchUpdateVirtualFlatInfo() {
  yield takeEvery(
    actions.BUILDING_VIEW_VIRTUAL_FLAT_NEED_UPDATE,
    updateVirtualFlatInfo
  )
}

function* init(action) {
  try {
    const data = yield call(api.building.getBuilding, action.id)

    yield put({ type: actions.BUILDING_VIEW_INITIATED, data })
  } catch (error) {
    const action = serverError(error)

    if (action.type === SERVER_404_ERROR) {
      yield put(replace('/404'))
    } else {
      yield put(action)
      yield put({ type: actions.BUILDING_VIEW_ERROR, error })
    }
  }
}

function* updateInfo(action) {
  try {
    const { id, params } = action

    let addressId = null

    if (params.address) {
      const addressParams = pickBy(params.address, identity)
      const result = yield call(api.address.add, addressParams)
      addressId = result.id
    }

    let avatar = null

    if (params.avatar) {
      const newParams = {
        dataUrl: params.avatar.dataUrl,
        name: params.avatar.origin,
      }
      const file = yield call(api.file.createFile, newParams)
      avatar = file.id
    }

    let data = omitBy(
      {
        ...params,
        address: addressId,
        avatar,
      },
      isNull
    )

    if (params && !isUndefined(params.external_id)) {
      data.external_id = params.external_id
    }

    const building = yield call(api.building.updateBuilding, id, data)

    yield put({ type: actions.BUILDING_VIEW_UPDATED, data: building })
  } catch (error) {
    yield put(serverError(error))

    if (error.message.response.status === 400) {
      yield put(modalError(error.message.response.data))
    }

    yield put({ type: actions.BUILDING_VIEW_ERROR, error })
  }
}

function* updateVirtualFlatInfo(action) {
  try {
    const { id, params } = action
    const flat = yield call(api.flat.updateFlat, id, params)
    yield put({ type: actions.BUILDING_VIEW_VIRTUAL_FLAT_UPDATED, flat })
  } catch (error) {
    if (error.message.response.status === 400) {
      yield put(modalError(error.message.response.data))

      return
    }

    yield put(serverError(error))
  }
}

export default function* watch() {
  yield all([
    fork(watchInit),
    fork(watchUpdateInfo),
    fork(watchUpdateVirtualFlatInfo),
  ])
}
