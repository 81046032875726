// @flow

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import classnames from 'classnames'
import Joi from 'joi-browser'
import validation from 'react-validation-mixin'
import strategy from 'joi-browser-validation-strategy'
import { findLastIndex, join } from 'lodash-es'
import { compose } from 'redux'

import Phone from '../Phone'

class PhoneValidationPopup extends Component {
  state = {
    code: '',
    secondsLeft: this.props.codeResendTimeout * 60,
    timerStart: 0,
    timerEnd: 0,
  }

  componentDidMount() {
    this.startCountdown()
    window.addEventListener('keydown', this.listenForKeyPress, true)
    document.body.style.overflowY = 'hidden'
  }

  componentWillUnmount() {
    window.clearInterval(this.interval)
    window.removeEventListener('keydown', this.listenForKeyPress, true)
    document.body.style.overflowY = 'scroll'
  }

  getValidatorData = () => {
    const { code } = this.state

    return { code }
  }

  getInputClass = field => {
    const { isValid, validationCodeErrors } = this.props

    if (field === 'code') {
      return classnames('input input--large input--block input--default', {
        'input--error':
          !isValid(field) ||
          findLastIndex(
            validationCodeErrors,
            o => o === 'Codes_do_not_match'
          ) >= 0,
        'input--success':
          isValid(field) &&
          findLastIndex(validationCodeErrors, o => o === 'Codes_do_not_match') <
            0,
      })
    }

    return classnames('input input--large input--block input--default')
  }

  validatorTypes = {
    code: Joi.string()
      .required()
      .options({
        language: {
          any: { empty: `!! ${this.props.t('Common:FieldIsRequired')}` },
          label: 'code',
        },
      }),
  }

  listenForKeyPress = event => {
    if (event.key === 'Escape' || event.keyCode === 27) {
      event.preventDefault()
      this.props.onHide()
    } else if (event.key === 'Enter' || event.keyCode === 13) {
      event.preventDefault()
      this.validateCode()
    }
  }

  validateCode = () => {
    const { onValidateCode, validate } = this.props
    validate(err => {
      if (err === undefined) {
        onValidateCode(this.state.code)
      }
    })
  }

  resendCode = () => {
    const { number, onResendValidationCode } = this.props
    onResendValidationCode(number)
    this.startCountdown()
  }

  decrementTimer = () => {
    const { secondsLeft, timerEnd } = this.state

    if (secondsLeft > 0) {
      this.setState({ secondsLeft: timerEnd.diff(moment(), 'seconds') })
    } else {
      window.clearInterval(this.interval)
    }
  }

  startCountdown = () => {
    const { codeResendTimeout } = this.props
    this.setState({
      secondsLeft: codeResendTimeout * 60,
      timerStart: moment(),
      timerEnd: moment().add(codeResendTimeout, 'minutes'),
    })
    this.interval = window.setInterval(this.decrementTimer, 1000)
  }

  updateCode = e => {
    const code = e.target.value.replace(/\D+/g, '')
    this.setState({ code })
  }

  renderError = field => {
    const { getValidationMessages, validationCodeErrors, t } = this.props
    const errors = [
      ...getValidationMessages(field),
      ...validationCodeErrors,
    ].map(error => {
      if (error === 'Codes_do_not_match') {
        return t('CodesDoNotMatch')
      }

      return error
    })

    const html = join(errors, '<br />')

    return errors.length > 0 ? (
      <div
        className='input__msg'
        dangerouslySetInnerHTML={{ __html: html }} // eslint-disable-line react/no-danger
      />
    ) : null
  }

  render() {
    const { onHide, number, t, working, validate } = this.props
    const { code, secondsLeft } = this.state

    return (
      <div className='modal__content modal--phone-validation'>
        <button className='modal__close' type='button' onClick={onHide} />
        <div className='modal__title modal__title--big-bottom-margin'>
          {t('Title')}
        </div>
        <div className='modal__body'>
          <div className='modal__row'>
            <span className='message-text'>
              {t('TextSended')}
              <Phone phone={number} />
            </span>
          </div>
          <div className='modal__row'>
            <div className='modal__cell'>
              <div
                className={this.getInputClass('code')}
                style={{ width: '210px' }}
              >
                <input
                  value={code}
                  name='code'
                  type='text'
                  placeholder={t('CodeTitle')}
                  maxLength='8'
                  onChange={this.updateCode}
                  onBlur={validate}
                />
                {this.renderError('code')}
              </div>
            </div>
            {secondsLeft < 1 && (
              <div className='modal__cell'>
                <div
                  className='resend-validation-code'
                  onClick={this.resendCode}
                >
                  {working && <span className='button__spinner' />}
                  {t('ResendButtonTitle')}
                </div>
              </div>
            )}
          </div>
          {secondsLeft > 0 && (
            <div className='modal__row'>
              <div className='timer'>
                <div className='message'>{`${t('CodeCanBeRequestedIn')}:`}</div>
                <div className='seconds'>
                  {moment.utc(secondsLeft * 1000).format('mm:ss')}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className='modal__submit'>
          <button
            type='button'
            className='button button--large button--success'
            onClick={this.validateCode}
          >
            {t('Confirm')}
          </button>
          <button
            type='button'
            className='button button--large button--danger-3'
            onClick={onHide}
          >
            {t('Cancel')}
          </button>
        </div>
      </div>
    )
  }
}

export default compose(
  withTranslation('PhoneValidation'),
  validation(strategy)
)(PhoneValidationPopup)
