// @flow

import React, { useState, useEffect } from 'react'

import { getContractors } from '../core/api/api.contractor'

import RequestProvidersList from './RequestProvidersList'
import Loader from './Loader/Loader'

import styles3 from './RequestProviders.module.scss'

const RequestProvidersSelect = props => {
  const { contractors, searchValue, labelList } = props
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingMore, setLoadingMore] = useState(false)
  const [page, setPage] = useState(1)
  const [meta, setMeta] = useState({})

  useEffect(() => {
    if (loading) {
      getContractors({
        name: searchValue || undefined,
        page,
        page_size: 5,
      }).then(data => {
        setList(
          page > 1 ? [...list, ...data.results.objects] : data.results.objects
        )
        setMeta(data.meta)
        setLoading(false)
        setLoadingMore(false)
      })
    }
  }, [loading])

  useEffect(() => {
    if (page !== 1) {
      setPage(1)
    } else {
      setLoading(true)
    }
  }, [searchValue])

  useEffect(() => {
    setLoading(true)
  }, [page])

  const loadMore = () => {
    if (!loadingMore && page < meta.page_count) {
      setLoadingMore(true)
      setPage(page + 1)
    }
  }

  if (loading && page === 1) {
    return (
      <div
        style={{
          height: '175px',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}
        className={styles3.listWrapper}
      >
        <Loader type='medium' text={false} />
      </div>
    )
  }

  return (
    <RequestProvidersList
      labelList={labelList}
      setLoading={props.setLoading}
      contractors={contractors}
      list={list}
      setContractors={props.setContractors}
      searchValue={searchValue}
      loadMore={loadMore}
    />
  )
}

export default RequestProvidersSelect
