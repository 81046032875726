// @flow

import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'

import TwoColumnLayout from '../components/Layout/TwoColumnLayout'
import { updateCountersAction } from '../components/Layout/Layout.actions'
import * as MailListActions from '../containers/Mail/ThreadsList.actionTypes'
import BrowserStorage from '../utils/browserStorage'
import ErrorRoute from './ErrorRoute'
import { USER_GROUPS } from '../constants'
import { isDwellerUser } from '../utils/utils'
import { getUser } from '../utils/commonSelectors'

class TwoColumnRoute extends Component {
  componentDidMount() {
    if (this.requireLogin(true)) {
      const { pathname } = this.props.location

      if (pathname === '/mails') {
        // When logo is clicked get new posts
        this.props.dispatch({ type: MailListActions.RELOAD })
      }

      if (
        ~pathname.indexOf('/requests/services') &&
        ~pathname.indexOf('/messages')
      ) {
        this.props.dispatch(updateCountersAction())
      }
    }
  }

  requireLogin = withWorker => {
    const { user } = this.props
    const isWorker = user && user.group === USER_GROUPS.worker

    return BrowserStorage.get('token') && (!isWorker || withWorker)
  }

  isAllowedGroupsAccess = (groups = []) => {
    const { user } = this.props
    // Workers only allowed to request page

    const noAccess =
      groups.indexOf(user.group) === -1 &&
      !(user.is_landlord && groups.includes(USER_GROUPS.landlord))

    return !noAccess
  }

  isUserValidated = pathname => {
    const { user } = this.props

    if (!user || user.validated || !isDwellerUser(user) || pathname === '/') {
      return true
    }

    const {
      permissions: {
        can_view_faq,
        can_view_marketplace,
        can_view_about_us,
        can_view_chat,
      },
    } = user

    const paths = [
      'building',
      'buildings',
      'flat',
      'flats',
      'friends',
      'post',
      `profile/${user.owner}`,
      'request',
      'requests',
      'settings',
    ]

    if (can_view_faq) {
      paths.push('faq')
    }

    if (can_view_about_us) {
      paths.push('uk')
    }

    if (can_view_marketplace) {
      paths.push('marketplace')
    }

    if (can_view_chat) {
      paths.push('messages')
      paths.push('chatroom')
    }

    const allowed = paths.map(p => pathname.includes(p)).includes(true)

    if (!allowed) {
      return false
    }

    return true
  }

  renderTwoColumn = routeProps => {
    const {
      component: TwoColumnComponent,
      widgets,
      allowOnlyAdmin,
      allowAdminManager,
      allowWorker,
      user,
      requestForWorker,
      pathname,
    } = this.props

    if (!this.requireLogin(true)) {
      return (
        <Redirect
          to={{
            pathname: '/login',
            params:
              pathname !== '/' ? { redirect: pathname + location.search } : {},
          }}
        />
      )
    }

    if (
      user &&
      user.group === USER_GROUPS.worker &&
      !(allowWorker && pathname.includes(requestForWorker))
    ) {
      return <Redirect to='/login' />
    }

    if (allowOnlyAdmin && !this.isAllowedGroupsAccess([USER_GROUPS.admin])) {
      return <Redirect to='/404' />
    }

    if (
      allowAdminManager &&
      !this.isAllowedGroupsAccess([USER_GROUPS.admin, USER_GROUPS.manager])
    ) {
      return <Redirect to='/404' />
    }

    return (
      <TwoColumnLayout {...routeProps} widgets={widgets}>
        <TwoColumnComponent {...routeProps} />
      </TwoColumnLayout>
    )
  }

  render() {
    const { component, ...rest } = this.props // eslint-disable-line
    const {
      location: { pathname },
    } = this.props

    if (!this.isUserValidated(pathname)) {
      return <ErrorRoute {...this.props} />
    }

    return <Route {...rest} render={this.renderTwoColumn} />
  }
}

const mapStateToProps = state => ({
  user: getUser(state),
  requestForWorker: state.sharedRequest.id.toString(),
  pathname: state.router.location.pathname,
})

export default compose(connect(mapStateToProps))(TwoColumnRoute)
