// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import Table from '../../Table'
import { useUser } from '../../../hooks/useUser'
import { isDwellerUser } from '../../../utils/utils'

type Props = { loading: boolean }

const FlatListTableHeader = (props: Props): Node => {
  const { loading } = props

  const { t } = useTranslation('Building')
  const user = useUser()

  const isDweller = isDwellerUser(user)

  return (
    <Table.HeaderRow>
      <Table.HeaderCell
        title={t('FlatNumber')}
        sortKey='flat_number'
        working={loading}
      />
      <Table.HeaderCell
        title={t('FlatAddress')}
        sortKey='building_address'
        working={loading}
      />
      {!isDweller && (
        <>
          <Table.HeaderCell
            title={<>{t('ContractId')}&nbsp;</>}
            sortKey='contract_id'
            working={loading}
          />
          <Table.HeaderCell
            title={<>{t('SecondId')}&nbsp;</>}
            sortKey='purchase_contract_number'
            working={loading}
          />
          <Table.HeaderCell
            title={<>{t('ContractStart')}&nbsp;</>}
            sortKey='contract_start'
            working={loading}
          />
          <Table.HeaderCell
            title={<>{t('ContractDuration')}&nbsp;</>}
            sortKey='duration'
            working={loading}
          />
          <Table.HeaderCell
            title={t('ContractEnd')}
            sortKey='contract_end'
            working={loading}
          />
        </>
      )}
    </Table.HeaderRow>
  )
}

export default FlatListTableHeader
