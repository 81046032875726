// @flow

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { toString } from 'lodash-es'
import { compose } from 'redux'

import DateTime from '../DateTime'
import { cutText } from '../../utils/utils'
import { SimpleBlock, Header, CellsBar, Cell } from '../Block'
import { EntityIcon } from '../Icon'
import Label from '../Label'

type Props = {
  address_obj: Object,
  area: number,
  created: string,
  dwellers_count: number,
  languageCode: string,
  number: string,
  room_count: number,
  soft_archived: boolean,
  t: string => string,
}

class FlatInfo extends Component<Props> {
  backToList = () => {
    const { location, history } = this.props
    const locationKey = location.key
    const historyLength = history.length

    if (!locationKey || historyLength === 1) {
      return () => this.props.dispatch(push('/flats'))
    }

    return null
  }

  render() {
    const {
      t,
      number,
      created: dateCreated,
      room_count: roomCount,
      area,
      dwellers_count: dwellersCount,
      address_obj: addressObj,
      soft_archived,
    } = this.props
    const maxHeaderLength = 38
    const summaryInfoHeader = `${t('FlatNum')}${number}, ${addressObj.value}`
    const summaryInfoHeaderTitle =
      summaryInfoHeader.length > maxHeaderLength ? summaryInfoHeader : null

    return (
      <SimpleBlock>
        <Header
          header
          goBack={this.backToList()}
          icon={<EntityIcon large id='apartment' />}
          textTitle={summaryInfoHeaderTitle}
        >
          {cutText(summaryInfoHeader, maxHeaderLength)}
          {soft_archived && (
            <Label text={this.props.t('Archived')} type='outlined' />
          )}
        </Header>
        <CellsBar>
          <Cell title={t('CreationDate')}>
            <DateTime dateTime={dateCreated} />
          </Cell>
          <Cell title={t('RoomsNum')}>{roomCount || '0'}</Cell>
          <Cell title={t('Area')}>
            {area ? toString(area).replace('.', ',') : '0'}
          </Cell>
          <Cell title={t('Dwellers')}>{dwellersCount || '0'}</Cell>
        </CellsBar>
      </SimpleBlock>
    )
  }
}

export default compose(withTranslation('FlatInfo'), connect())(FlatInfo)
