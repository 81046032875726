// @flow

import React, { PureComponent, Fragment } from 'react'
import classnames from 'classnames'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Link, withRouter } from 'react-router-dom'
import { replace } from 'connected-react-router'

import { Header, Row, SimpleBlock } from '../../../components/Block'
import { EntityIcon } from '../../../components/Icon'
import Nbsp from '../../../components/NBSP'
import DateTime from '../../../components/DateTime'
import { getDateFormat, getLocation } from '../../../utils/commonSelectors'
import * as selectors from '../Feedback.selectors'
import { closeFeedback } from '../Feedback.actions'

import styles from './Info.module.scss'

type Props = {
  data: Object,
  dateFormat: string,
  isNew: boolean,
  languageCode: string,
  requestId: number,
  t: string => string,
}

class Info extends PureComponent<Props> {
  handleClickBack = () => {
    const {
      match: {
        params: { requestID },
      },
    } = this.props

    this.props.replace(`/request/${requestID}/chats/feedback/`)
    this.props.closeFeedback()
  }

  render() {
    const {
      data,
      isNew,
      dateFormat,
      requestTitle,
      requestNumber,
      requestId,
      isWorking,
    } = this.props
    const classRequestLink = classnames(
      'select--value-item',
      'select--value-item-round',
      'select--value-item-clickable',
      'select--value-item-link',
      styles.link
    )
    const date = isNew ? new Date() : data.created

    return (
      <SimpleBlock working={isWorking}>
        <Header header goBack={this.handleClickBack}>
          <EntityIcon icon large id='review' />
          {this.props.t('Feedback')}
          <Nbsp />
          {requestNumber && (
            <Fragment>
              {this.props.t('Common:NumberSymbol')}
              <Nbsp />
              {requestNumber}
            </Fragment>
          )}
          {requestTitle && (
            <Fragment>
              :<Nbsp />
              <span className={styles.clipped}>{requestTitle}</span>
            </Fragment>
          )}
        </Header>
        <Row title={this.props.t('Created')}>
          <span className='bar__cell-value'>
            <DateTime dateTime={date} dateFormat={dateFormat} />
          </span>
        </Row>

        {requestNumber && (
          <Row title={this.props.t('Request')}>
            <Link to={`/request/${requestId}`} className={classRequestLink}>
              <span className='requestlist__request-title' title={requestTitle}>
                <EntityIcon id='request' />
                {this.props.t('Common:NumberSymbol')} {requestNumber}
              </span>
            </Link>
          </Row>
        )}
      </SimpleBlock>
    )
  }
}

const mapStateToProps = state => ({
  data: selectors.getFeedbackData(state),
  isNew: selectors.isNew(state),
  isWorking: selectors.isWorking(state),
  dateFormat: getDateFormat(state),
  requestId: selectors.getRequestId(state),
  requestTitle: selectors.getRequestTitle(state),
  requestNumber: selectors.getRequestNumber(state),
  location: getLocation(state),
})
const mapDispatchToProps = {
  replace,
  closeFeedback,
}

export default compose(
  withTranslation('Feedback'),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Info)
