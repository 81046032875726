// @flow

import React, { memo } from 'react'
import classnames from 'classnames'

import { getUserName } from '../../../../../../utils/utils'

import styles from './Author.module.scss'

type Props = {
  user: Object,
}

const Author = memo<Props>(({ user }) => {
  const name = getUserName(user)

  const textClass = classnames('table-item__user-name', styles.name)

  return (
    <div>
      <i className='table-item__user-pic'>
        <img src={user ? user.avatar : null} alt='author' />
      </i>
      <span className={textClass} title={name}>
        {name}
      </span>
    </div>
  )
})

export default Author
