// @flow

import React from 'react'
import type { Node } from 'react'

import type { Chunk } from './MessageChunks.types'
import MessageBlockquote from '../MessageBlockquote/MessageBlockquote'
import MessageText from '../MessageText'
import { BLOCKQUOTE_TYPE } from '../Message.constants'

type Props = { chunk: Chunk }

const MessageChunk = (props: Props): Node => {
  const {
    chunk: { type, content },
  } = props

  if (type === BLOCKQUOTE_TYPE) {
    return (
      <MessageBlockquote>
        <MessageText text={content} />
      </MessageBlockquote>
    )
  }

  return <MessageText text={content} />
}

export default MessageChunk
