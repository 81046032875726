// @flow

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'

import { SimpleBlock } from '../Block'
import NewSelectSimple from '../NewSelectSimple'
import Settings from '../Settings'

type Props = {
  notificationOption: number,
  onClickCancelNotificationSettings: () => void,
  onClickSaveNotificationSettings: () => void,
  onUpdate: () => void,
  t: string => string,
  working: boolean,
}

type State = {
  currentOption: Object,
  initialNotificationOption: Object,
}

class UserNotification extends Component<Props, State> {
  notificationSettingsOptions = [
    // eslint-disable-line
    { value: 0, label: this.props.t('Instantly') },
    { value: 2, label: this.props.t('OnceAnHour') },
    { value: 3, label: this.props.t('OnceADay') },
    { value: 1, label: this.props.t('Never') },
  ]

  state = {
    initialNotificationOption: this.props.notificationOption,
    currentOption: this.notificationSettingsOptions.find(
      opt => opt.value === this.props.notificationOption
    ),
  }

  update = option => {
    const { onUpdate } = this.props
    this.setState({ currentOption: option })
    onUpdate(option.value)
  }

  onCancelNotificationSettings = optionForSet => () => {
    this.props.onClickCancelNotificationSettings()
    this.update(optionForSet)
  }

  renderRow = label => (
    <Settings.Row label={this.props.t(label)}>
      <div className='input input--large input--default input--block'>
        <NewSelectSimple
          name='notification-settings-select'
          value={this.state.currentOption}
          options={this.notificationSettingsOptions}
          placeholder='nothing'
          onChange={this.update}
        />
      </div>
    </Settings.Row>
  )

  render() {
    const { working, notificationOption, emailNotificationPeriod } = this.props
    const optionForSet = this.notificationSettingsOptions.find(
      opt => opt.value === emailNotificationPeriod
    )
    const saveButtonActive =
      typeof notificationOption !== 'undefined' &&
      notificationOption !== emailNotificationPeriod

    return (
      <SimpleBlock working={working}>
        <h2 header='true' className='unit__title'>
          {this.props.t('NotificationSettings')}
        </h2>

        <div className='settings-personal__form'>
          <Settings.Hint text={this.props.t('NotificationFrequencyInfo')} />
          {this.renderRow('NotificationFrequency')}
        </div>

        <Settings.Actions
          withChanges={saveButtonActive}
          working={working}
          onSave={this.props.onClickSaveNotificationSettings}
          onCancel={this.onCancelNotificationSettings(optionForSet)}
        />
      </SimpleBlock>
    )
  }
}

export default withTranslation('Settings')(UserNotification)
