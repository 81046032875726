// @flow

import React from 'react'
import type { Node } from 'react'

import Regular from './Regular'
import type { Props } from './Regular/Regular'

const Cancel = (props: Props): Node => (
  <Regular {...props} color='reject' view='contained' />
)

export default Cancel
