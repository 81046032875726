// @flow

import * as actions from './UserListInfo.actionTypes'

type Action = {
  dwellersCount: number,
  staffCount: number,
  type: 'USER_LIST_INFO_INITIATED' | 'USER_LIST_INFO_CLEANUP',
}

type State = {
  dwellersCount: number,
  initiated: boolean,
  staffCount: number,
}

const initialState = {
  initiated: false,
  dwellersCount: 0,
  staffCount: 0,
}

export default (state: State = initialState, action: Action) => {
  switch (action.type) {
    case actions.USER_LIST_INFO_INITIATED: {
      const { dwellersCount, staffCount } = action

      return {
        ...state,
        initiated: true,
        dwellersCount,
        staffCount,
      }
    }
    case actions.USER_LIST_INFO_CLEANUP:
      return initialState
    default:
      return state
  }
}
