// @flow

import { all, put, call, fork, takeEvery } from 'redux-saga/effects'

import api from '../../core/api'
import * as actions from './CompanyLogo.actionTypes'
import { serverError } from '../../components/Layout/Layout.actions'

function* watchSave() {
  yield takeEvery(actions.COMPANY_LOGO_SAVE, save)
}

function* getFileParam(file, key) {
  if (file) {
    if (file.origin) {
      return { [key]: file.id }
    }

    if (file.dataUrl) {
      file.name = file.filename
      const uploaded = yield call(api.file.createFile, file)

      return { [key]: uploaded.id }
    }
  }

  return {}
}

function* save({ uk, web, mobile }) {
  try {
    const params = yield all({
      web: call(getFileParam, web, 'logo'),
      mobile: call(getFileParam, mobile, 'logo_mobile'),
    })

    const data = { ...params.web, ...params.mobile }

    const result = yield call(api.uk.update, uk, data)

    yield put({ type: actions.COMPANY_UPDATE, uk: result })
    yield put({ type: actions.COMPANY_LOGO_WAS_SAVED })
  } catch (error) {
    // TODO check
    yield put(serverError(error))
    yield put({ type: actions.COMPANY_LOGO_ERROR, error })
  }
}

export default function* watch() {
  yield fork(watchSave)
}
