// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import type { Node } from 'react'

import ConfirmationPopup from '../../../components/modals/ConfirmationPopup'
import Modal from '../../Modal'
import AddGroup from './AddGroup'
import {
  closeModal,
  removeGroups,
  createGroup,
  removeQuestion,
} from '../Faq.actions'
import {
  REMOVE_POPUP,
  CREATE_POPUP,
  REMOVE_QUESTION,
  DONT_REMOVE_GROUP,
} from '../Faq.constants'
import * as selectors from '../Faq.selectors'

type Props = {
  closeModal: () => void,
  createGroup: () => void,
  modal: string,
  removeGroups: () => void,
  selected: Array<number>,
  t: string => string,
}

type State = {
  modalComponent: ?Node,
}

class FaqModal extends Component<Props, State> {
  state = {
    modalComponent: null,
  }

  componentDidUpdate(prevProps) {
    const { modal } = this.props

    if (prevProps.modal !== modal) {
      this.setState({ modalComponent: this.selectModal(modal) })
    }
  }

  selectModal = type => {
    const { t } = this.props

    switch (type) {
      case REMOVE_POPUP:
        return (
          <ConfirmationPopup
            title={t('delete_group_text')}
            onClose={this.props.closeModal}
            onOk={this.props.removeGroups}
          />
        )
      case REMOVE_QUESTION:
        return (
          <ConfirmationPopup
            title={t('delete_question_text')}
            onClose={this.props.closeModal}
            onOk={this.props.removeQuestion}
          />
        )
      case CREATE_POPUP:
        return <AddGroup />
      case DONT_REMOVE_GROUP:
        return (
          <ConfirmationPopup
            title={t('DeleteGroupErrorTitle')}
            showCancel={false}
            confirm={t('Common:Ok')}
            text={t('delete_group_error')}
            onOk={this.props.closeModal}
            onClose={this.props.closeModal}
          />
        )
      default:
        return null
    }
  }

  render() {
    const { modalComponent } = this.state

    return (
      <Modal isOpen={!!modalComponent} onRequestClose={this.props.closeModal}>
        {modalComponent}
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  modal: selectors.getModal(state),
})

const mapDispatchToProps = {
  closeModal,
  removeGroups,
  createGroup,
  removeQuestion,
}

export default compose(
  withTranslation('FAQ'),
  connect(mapStateToProps, mapDispatchToProps)
)(FaqModal)
