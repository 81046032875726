//@flow

import React, { useEffect, useState } from 'react'
import type Node from 'react'
import DocumentContainer from './DocumentContainer'
import PageContainer from './PageContainer'
import ImagePreview from './ImagePreview'

import Icon from '../Icon'

import classnames from 'classnames'
import styles from './MappedDocument.module.scss'

import { IMAGE_TYPE, PDF_TYPE } from '../../utils/file'

type Props = {
  fileOrigin?: string,
  fileType?: string,
  height: number,
  iconId?: string,
  onLoadSuccess?: Object => void,
  outerWidth: number,
  page?: number,
  placeholder: string,
  setPage?: number => void,
  width: number,
}

const DocumentPreview = (props: Props): Node => {
  const {
    fileOrigin,
    fileType,
    height,
    iconId,
    outerWidth,
    placeholder,
    width,
  } = props
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(1)

  useEffect(() => {
    if (props.page) {
      setPage(props.page)
    }
  }, [props.page])

  useEffect(() => {
    if (fileOrigin) {
      setPage(1)
    }
  }, [fileOrigin])

  const onLoadSuccess = pdf => {
    const count = pdf?.numPages || 1
    setPageCount(count)

    if (props.onLoadSuccess) {
      props.onLoadSuccess(pdf)
    }
  }

  const getFilePreview = () => {
    switch (fileType) {
      case PDF_TYPE:
        return (
          <DocumentContainer
            style={{
              position: 'relative',
              overflow: 'hidden',
            }}
            fileOrigin={fileOrigin}
            width={outerWidth}
            onLoadSuccess={onLoadSuccess}
          >
            <PageContainer
              scalable
              showGoto
              pageCount={pageCount}
              width={width}
              height={height}
              page={page}
              setPage={props.setPage || setPage}
            />
          </DocumentContainer>
        )
      case IMAGE_TYPE:
        return (
          <ImagePreview fileOrigin={fileOrigin} width={width} height={height} />
        )
      default:
        return <a href={fileOrigin}>{fileOrigin}</a>
    }
  }

  return (
    <div
      className={classnames(styles.preview, 'doc-template-preview')}
      style={{
        width: `${outerWidth}px`,
        height: `${height - 2}px`,
      }}
    >
      {fileOrigin ? (
        getFilePreview()
      ) : (
        <div className={styles.placeholder}>
          <Icon id={iconId || 'request'} />
          <span>{placeholder}</span>
        </div>
      )}
    </div>
  )
}

export default DocumentPreview
