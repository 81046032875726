// @flow

import React from 'react'
import type { Node } from 'react'

import styles from './ModalCloseButton.module.scss'

import Cross from './../../../../static/icons/cross.svg'

type Props = { disabled?: boolean, onClose: () => void }

const ModalCloseButton = (props: Props): Node => {
  const { disabled } = props

  return (
    <button
      className={styles.close}
      disabled={disabled}
      type='button'
      onClick={props.onClose}
    >
      <Cross />
    </button>
  )
}

ModalCloseButton.defaultProps = {
  disabled: false,
}

export default ModalCloseButton
