// @flow

import type { Node } from 'react'
import type { IGeneral } from './FlatInformation'
import React, { useMemo } from 'react'
import setFormikError from '../../../utils/setFormikError'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import NewSelectAsync from '../../NewSelectAsync'
import FieldRow from '../../FieldRow'
import Button from '../../Button'
import styles from './FlatInformation.module.scss'
import {
  CONDITION_MAX_LENGTH,
  CATEGORY_MAX_LENGTH,
  CONTRACT_TYPE,
  FLAT_CATEGORY,
  FLAT_EQUIPMENT,
  FLAT_TYPE_RENT_PAYMENT,
  FLAT_CONDITION,
  FLAT_SPECIFICS,
  EQUIPMENT_MAX_LENGTH,
  UNIT_NUMBER_MAX_LENGTH,
  SHARE_MIN,
  SHARE_MAX,
  TOTAL_MIN,
  TOTAL_MAX,
  TYPE_OF_ROOM,
  TYPE_RENT_PAYMENT_MAX_LENGTH,
  ENTRANCE,
  PROPERTY_SHARE,
  UNIT_NUMBER,
  HEAT_TYPE,
  KEYS,
} from './FlatInformation.constants'
import * as Yup from 'yup'

import InputField from '../../InputField'

import api from '../../../core/api'

import classnames from 'classnames'
import { isNil, toString } from 'lodash-es'

type Props = {
  active: boolean,
  handleEditClose: () => void,
  initialValues: IGeneral,
  isDweller: boolean,
  isOpen: boolean,
  updateFlatInfo: (data: { general: IGeneral }) => Promise<IGeneral>,
}

const FlatInformationForm = ({
  initialValues,
  isOpen,
  isDweller,
  active,
  updateFlatInfo,
  handleEditClose,
}: Props): Node => {
  const { t } = useTranslation('Flat')
  const updateValues = async values => {
    const general: IGeneral = { ...values }
    updateFlatInfo({ general })
    handleEditClose()
  }
  const onClosed = () => {
    handleEditClose()
    resetForm()
  }

  const VisibleFields = useMemo(() => {
    return isDweller ? Object.keys(initialValues) : KEYS
  }, [isDweller, initialValues])

  const onSubmit = values => {
    const { total, share } = values[PROPERTY_SHARE]
    values = {
      ...values,
      [UNIT_NUMBER]: values[UNIT_NUMBER] || null,
      [TYPE_OF_ROOM]: values[TYPE_OF_ROOM]?.id || null,
      [FLAT_CATEGORY]: values[FLAT_CATEGORY]?.id || null,
      [FLAT_CONDITION]: values[FLAT_CONDITION]?.id || null,
      [FLAT_SPECIFICS]: values[FLAT_SPECIFICS]?.id || null,
      [CONTRACT_TYPE]: values[CONTRACT_TYPE]?.id || null,
      [PROPERTY_SHARE]: {
        total: total ? parseInt(total) : null,
        share: share || share === 0 ? parseFloat(share).toFixed(3) : null,
      },
    }

    if (total || share) {
      total && (share || share === 0)
        ? updateValues(values)
            .then(() => handleEditClose())
            .catch(error => setFormikError(error, setFieldError))
        : setErrors({
            [`${PROPERTY_SHARE}`]: {
              share: t('BothFieldsAreRequired'),
            },
          })
    } else {
      updateValues(values)
        .then(() => handleEditClose())
        .catch(error => setFormikError(error, setFieldError))
    }
  }

  const {
    values,
    errors,
    dirty,
    setFieldValue,
    handleSubmit,
    setFieldError,
    handleChange,
    resetForm,
    setErrors,
  } = useFormik({
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      [FLAT_CATEGORY]: Yup.object({
        id: Yup.number().nullable(),
        value: Yup.string().nullable(),
      }).nullable(),
      [FLAT_EQUIPMENT]: Yup.string().max(
        EQUIPMENT_MAX_LENGTH,
        t('MaxInputLength', { length: EQUIPMENT_MAX_LENGTH })
      ),
      [FLAT_TYPE_RENT_PAYMENT]: Yup.string().max(
        TYPE_RENT_PAYMENT_MAX_LENGTH,
        t('MaxInputLength', { length: TYPE_RENT_PAYMENT_MAX_LENGTH })
      ),
      [FLAT_CONDITION]: Yup.object({
        id: Yup.number().nullable(),
        value: Yup.string().nullable(),
      }).nullable(),
      [FLAT_SPECIFICS]: Yup.object({
        id: Yup.number().nullable(),
        value: Yup.string().nullable(),
      }).nullable(),
      [CONTRACT_TYPE]: Yup.object({
        id: Yup.number().nullable(),
        value: Yup.string().nullable(),
      }).nullable(),
      [ENTRANCE]: Yup.string().max(
        CONDITION_MAX_LENGTH,
        t('MaxInputLength', { length: CONDITION_MAX_LENGTH })
      ),
      [HEAT_TYPE]: Yup.string().max(
        CATEGORY_MAX_LENGTH,
        t('MaxInputLength', { length: CATEGORY_MAX_LENGTH })
      ),
      [TYPE_OF_ROOM]: Yup.object({
        id: Yup.number().nullable(),
        value: Yup.string().nullable(),
      }).nullable(),
      [PROPERTY_SHARE]: Yup.object().shape({
        share: Yup.number()
          .nullable()
          .min(SHARE_MIN, t('MinNumberValue', { value: SHARE_MIN }))
          .max(SHARE_MAX, t('MaxNumberValue', { value: SHARE_MAX }))
          .when('total', (total, schema) => {
            return !isNil(total) ? schema.max(total, t('PartIsBigger')) : schema
          }),
        total: Yup.number()
          .nullable()
          .min(TOTAL_MIN, t('MinNumberValue', { value: TOTAL_MIN }))
          .max(TOTAL_MAX, t('MaxNumberValue', { value: TOTAL_MAX })),
      }),
      [UNIT_NUMBER]: Yup.number()
        .nullable()
        .max(
          UNIT_NUMBER_MAX_LENGTH,
          t('MaxInputLength', {
            length: Math.ceil(Math.log10(UNIT_NUMBER_MAX_LENGTH)),
          })
        ),
    }),
    onSubmit,
  })

  const handleSelect = field => option => {
    setFieldValue(field, option)
  }

  const handleClear = field => () => {
    setFieldValue(field, null)
  }

  const inputClass = classnames(styles.input, styles.inputPlaceholder)
  const inputNumberClass = classnames(
    styles.inputNumber,
    styles.inputPlaceholder
  )

  return (
    <form name='flat_information' onSubmit={handleSubmit}>
      {(values[UNIT_NUMBER] ||
        (isOpen && VisibleFields.includes(UNIT_NUMBER))) && (
        <FieldRow
          active={active}
          label={'UnitNumber'}
          value={values[UNIT_NUMBER]}
        >
          <InputField
            type={'number'}
            className={inputNumberClass}
            error={errors[UNIT_NUMBER]}
            name={`${UNIT_NUMBER}`}
            value={values[UNIT_NUMBER]}
            placeholder={t('EnterUnitNumber')}
            onChange={handleChange}
          />
        </FieldRow>
      )}
      {(values[ENTRANCE] || (isOpen && VisibleFields.includes(ENTRANCE))) && (
        <FieldRow
          name={ENTRANCE}
          active={active}
          label={'EntranceAddress'}
          error={errors[ENTRANCE]}
          value={values[ENTRANCE]}
          placeholder={t('EnterEntranceAddress')}
          maxLength={CATEGORY_MAX_LENGTH}
          handleChange={handleChange}
        />
      )}
      {!isDweller && (
        <FieldRow
          value={values[TYPE_OF_ROOM]?.value}
          label={'TypeOfRoom'}
          error={errors[TYPE_OF_ROOM]}
          active={active}
          type='select'
          handleClear={handleClear(TYPE_OF_ROOM)}
        >
          <NewSelectAsync
            pageSize={10}
            name={TYPE_OF_ROOM}
            api={api.roomType.getRoomType}
            searchKey='search'
            placeholder={t('SelectTypeOfRoom')}
            view='dropdown'
            getLabel={a => a?.value}
            getSelectedLabel={a => a?.value}
            selectedItems={
              values[TYPE_OF_ROOM]?.value ? [values[TYPE_OF_ROOM]] : []
            }
            className={styles.select}
            onClick={handleSelect(TYPE_OF_ROOM)}
          />
        </FieldRow>
      )}
      {(values[FLAT_CATEGORY]?.value ||
        (isOpen && VisibleFields.includes(FLAT_CATEGORY))) && (
        <FieldRow
          value={values[FLAT_CATEGORY]?.value}
          label={'FlatCategory'}
          error={errors[FLAT_CATEGORY]}
          active={active}
          type='select'
          handleClear={handleClear(FLAT_CATEGORY)}
        >
          <NewSelectAsync
            pageSize={10}
            name={FLAT_CATEGORY}
            api={api.flatCategory.getFlatCategory}
            searchKey='search'
            placeholder={t('SelectFlatCategory')}
            view='dropdown'
            getLabel={a => a?.value}
            getSelectedLabel={a => a?.value}
            selectedItems={
              values[FLAT_CATEGORY]?.value ? [values[FLAT_CATEGORY]] : []
            }
            className={styles.select}
            onClick={handleSelect(FLAT_CATEGORY)}
          />
        </FieldRow>
      )}
      {(values[FLAT_EQUIPMENT] ||
        (isOpen && VisibleFields.includes(FLAT_EQUIPMENT))) && (
        <FieldRow
          name={FLAT_EQUIPMENT}
          active={active}
          type='textarea'
          label={'FlatEquipment'}
          error={errors[FLAT_EQUIPMENT]}
          value={values[FLAT_EQUIPMENT]}
          placeholder={'EnterFlatEquipment'}
          maxLength={EQUIPMENT_MAX_LENGTH}
          handleChange={handleChange}
        />
      )}
      {(values[FLAT_TYPE_RENT_PAYMENT] ||
        (isOpen && VisibleFields.includes(FLAT_TYPE_RENT_PAYMENT))) && (
        <FieldRow
          name={FLAT_TYPE_RENT_PAYMENT}
          active={active}
          label={'TypeRentPayment'}
          error={errors[FLAT_TYPE_RENT_PAYMENT]}
          value={values[FLAT_TYPE_RENT_PAYMENT]}
          placeholder={'EnterTypeRentPayment'}
          maxLength={TYPE_RENT_PAYMENT_MAX_LENGTH}
          handleChange={handleChange}
        />
      )}
      {(values[FLAT_CONDITION]?.value ||
        (isOpen && VisibleFields.includes(FLAT_CONDITION))) && (
        <FieldRow
          value={values[FLAT_CONDITION]?.value}
          label={'FlatCondition'}
          error={errors[FLAT_CONDITION]}
          active={active}
          type='select'
          handleClear={handleClear(FLAT_CONDITION)}
        >
          <NewSelectAsync
            pageSize={10}
            name={FLAT_CONDITION}
            api={api.flatCondition.getFlatCondition}
            searchKey='search'
            placeholder={t('SelectFlatCondition')}
            view='dropdown'
            getLabel={a => a?.value}
            getSelectedLabel={a => a?.value}
            selectedItems={
              values[FLAT_CONDITION] ? [values[FLAT_CONDITION]] : []
            }
            className={styles.select}
            onClick={handleSelect(FLAT_CONDITION)}
          />
        </FieldRow>
      )}
      {(values[HEAT_TYPE] || (isOpen && VisibleFields.includes(HEAT_TYPE))) && (
        <FieldRow
          name={HEAT_TYPE}
          active={active}
          label={'HeatType'}
          error={errors[HEAT_TYPE]}
          value={values[HEAT_TYPE]}
          placeholder={t('EnterHeatType')}
          handleChange={handleChange}
        />
      )}
      {(values[PROPERTY_SHARE]?.share ||
        (isOpen && VisibleFields.includes(PROPERTY_SHARE))) && (
        <FieldRow
          label={'PropertyShare'}
          error={errors[PROPERTY_SHARE]?.share || errors[PROPERTY_SHARE]?.total}
          value={
            values[PROPERTY_SHARE].share &&
            `${toString(values[PROPERTY_SHARE].share).replace('.', ',')}/${
              values[PROPERTY_SHARE]?.total
            }`
          }
          active={active}
        >
          <div className={styles.shareBlock}>
            <InputField
              showErrorText={false}
              type={'number'}
              className={inputClass}
              error={errors[PROPERTY_SHARE]?.share}
              name={`${PROPERTY_SHARE}.share`}
              value={values[PROPERTY_SHARE].share}
              placeholder={t('EnterPart')}
              onChange={handleChange}
            />
            <span>{'/'}</span>
            <InputField
              onlyInteger
              showErrorText={false}
              type={'number'}
              className={inputClass}
              error={errors[PROPERTY_SHARE]?.total}
              name={`${PROPERTY_SHARE}.total`}
              value={values[PROPERTY_SHARE].total}
              placeholder={t('EnterWholePart')}
              onChange={handleChange}
            />
          </div>
        </FieldRow>
      )}
      {(values[CONTRACT_TYPE]?.value ||
        (isOpen && VisibleFields.includes(CONTRACT_TYPE))) && (
        <FieldRow
          value={values[CONTRACT_TYPE]?.value}
          label={'FlatContract'}
          error={errors[CONTRACT_TYPE]}
          active={active}
          type='select'
          handleClear={handleClear(CONTRACT_TYPE)}
        >
          <NewSelectAsync
            pageSize={10}
            name={CONTRACT_TYPE}
            api={api.flatContract.getFlatContract}
            searchKey='search'
            placeholder={t('SelectFlatContract')}
            view='dropdown'
            getLabel={a => a?.value}
            getSelectedLabel={a => a?.value}
            selectedItems={
              values[CONTRACT_TYPE]?.value ? [values[CONTRACT_TYPE]] : []
            }
            className={styles.select}
            onClick={handleSelect(CONTRACT_TYPE)}
          />
        </FieldRow>
      )}
      {(values[FLAT_SPECIFICS]?.value ||
        (isOpen && VisibleFields.includes(FLAT_SPECIFICS))) && (
        <FieldRow
          value={values[FLAT_SPECIFICS]?.value}
          label={'FlatSpecifics'}
          error={errors[FLAT_SPECIFICS]}
          active={active}
          type='select'
          handleClear={handleClear(FLAT_SPECIFICS)}
        >
          <NewSelectAsync
            pageSize={10}
            name={FLAT_SPECIFICS}
            api={api.flatSpecifics.getFlatSpecifics}
            searchKey='search'
            placeholder={t('SelectFlatSpecifics')}
            view='dropdown'
            getLabel={a => a?.value}
            getSelectedLabel={a => a?.value}
            selectedItems={
              values[FLAT_SPECIFICS]?.value ? [values[FLAT_SPECIFICS]] : []
            }
            className={styles.select}
            onClick={handleSelect(FLAT_SPECIFICS)}
          />
        </FieldRow>
      )}
      {active && (
        <div className={styles.buttonWrapper}>
          <Button.Save disabled={!dirty} type='submit'>
            {t('Common:Save')}
          </Button.Save>
          <Button.Cancel onClick={onClosed}>{t('Common:Cancel')}</Button.Cancel>
        </div>
      )}
    </form>
  )
}

export default FlatInformationForm
