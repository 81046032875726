// @flow

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'

class RequestStatus extends Component {
  static propTypes = {
    onOk: PropTypes.func,
    onClose: PropTypes.func,
    selected: PropTypes.number,
    statuses: PropTypes.array,
    selectStatus: PropTypes.func,
    t: PropTypes.func,
    value: PropTypes.number,
  }

  componentDidMount() {
    this._select(this.props.value)
    document.body.style.overflowY = 'hidden'
  }

  componentWillUnmount() {
    document.body.style.overflowY = 'scroll'
  }

  _select = value => this.props.selectStatus(value)

  _renderItem = ({ id, title, css }) => {
    const { t, selected } = this.props

    const itemClass = classnames('dropdown__list-item', {
      'dropdown__list-item--checked': selected === id,
    })

    const textClass = classnames(
      'dropdown__list-item-text',
      `dropdown__list-item-text--${css}`
    )

    return (
      <li
        className={itemClass}
        key={`status-${id}`}
        onClick={() => this._select(id)}
      >
        <span className={textClass}>{t(title)}</span>
      </li>
    )
  }

  render() {
    const { onClose, onOk, selected, statuses, t } = this.props

    return (
      <div className='modal__content'>
        <button className='modal__close' type='button' onClick={onClose} />
        <div className='modal__title'>{t('UpdateStatusTitle')}</div>
        <div className='modal__body'>
          <div className='m-a11__dropdown dropdown--default dropdown__list--checklist'>
            <ul className='dropdown__list dropdown__list--default'>
              {statuses.map(status => this._renderItem(status))}
            </ul>
          </div>
        </div>
        <div className='modal__submit'>
          <button
            type='button'
            className='button button--large button--success'
            disabled={!selected}
            onClick={() => onOk(selected)}
          >
            {t('UpdateStatusButton')}
          </button>
          <button
            type='button'
            className='button button--large button--danger-3'
            onClick={onClose}
          >
            {t('Cancel')}
          </button>
        </div>
      </div>
    )
  }
}

export default withTranslation('Request')(RequestStatus)
