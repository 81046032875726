// @flow

import { get } from 'lodash-es'

import {
  AUDIENCE_LIST_LOADING,
  AUDIENCE_LIST_LOADED,
  AUDIENCE_LIST_ERROR,
  AUDIENCE_LIST_RESET,
} from './AudienceList.actionTypes'

const initialState = {
  audienceList: null,
  error: null,
  loading: false,
  loaded: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case AUDIENCE_LIST_LOADING:
      return { ...state, loading: true }
    case AUDIENCE_LIST_LOADED: {
      const audienceList = get(action, ['data', 'results', 'objects'], null)

      return {
        ...state,
        loading: false,
        loaded: true,
        audienceList,
      }
    }
    case AUDIENCE_LIST_ERROR:
      return { ...state, loading: false, error: action.error }
    case AUDIENCE_LIST_RESET:
      return initialState
    default:
      return state
  }
}
