// @flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer, graphql } from 'react-relay'

import { Cell } from '../../../../components/Block'
import { ORDER_STATUSES } from '../OrderProfile.constants'

import NewSelectSimple from '../../../../components/NewSelectSimple/NewSelectSimple'
import OrderProfileStatusmutation from './OrderProfileStatus.mutation'

import styles from './OrderProfileStatus.module.scss'

type Props = {
  data: {
    id: string,
    status: 'NEW' | 'DONE' | 'CANCELED' | 'IN_PROGRESS',
  },
  isStaff: boolean,
}

function getOptions(t) {
  return ORDER_STATUSES.map(status => ({
    value: status.value,
    label: t(`status${status.translationKey}`),
    style: { color: status.color },
  }))
}

const OrderProfileStatus = (props: Props) => {
  const { t } = useTranslation('Orders')

  const {
    isStaff,
    data: { id, status: currentStatus },
  } = props

  const [status, setStatus] = useState(currentStatus)

  const statusData = ORDER_STATUSES.find(st => st.value === status)

  const handleComlete = response =>
    setStatus(response.updateCustomerOrderStatus.status)

  const handleError = error => console.error(error)

  const handleChange = option => {
    OrderProfileStatusmutation.commit(
      { ids: [id], status: option.value },
      handleComlete,
      handleError
    )
  }

  return (
    <Cell title={t('status')}>
      <div className={styles.statusValue} style={{ color: statusData.color }}>
        {t(`status${statusData.translationKey}`)}
      </div>
      {isStaff && (
        <NewSelectSimple
          options={getOptions(t)}
          buttonType='edit'
          onChange={handleChange}
        />
      )}
    </Cell>
  )
}

export default createFragmentContainer(OrderProfileStatus, {
  data: graphql`
    fragment OrderProfileStatus_data on CustomerOrderNode {
      status
      id
    }
  `,
})
