// @flow

import update from 'immutability-helper'

import * as actions from './RequestConstructor.actionTypes'
import { get } from 'lodash-es'

const initialState = {
  data: {},
  initiated: false,
  working: false,
  workingGroups: false,
  error: false,
  errorText: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.REQUEST_CONSTRUCTOR_INITIATE:
      return { ...initialState, working: true }
    case actions.REQUEST_CONSTRUCTOR_WAS_INITIATED:
      return {
        ...state,
        data: action.data,
        initiated: true,
        working: false,
      }
    case actions.REQUEST_CONSTRUCTOR_TOGGLE:
      return {
        ...state,
        working: true,
      }
    case actions.REQUEST_CONSTRUCTOR_TOGGLED:
      return {
        ...state,
        data: { ...state.data, row_is_active: action.data.is_active },
        working: false,
      }
    case actions.REQUEST_CONSTRUCTOR_GROUPS_WERE_UPDATED:
      return {
        ...state,
        data: {
          ...state.data,
          groups: action.groups,
        },
      }
    case actions.REQUEST_CONSTRUCTOR_UPDATE:
      return {
        ...state,
        working: true,
        error: false,
      }
    case actions.REQUEST_CONSTRUCTOR_WAS_UPDATED:
      return {
        ...state,
        data: action.data,
        working: false,
      }
    case actions.REQUEST_CONSTRUCTOR_ADD_GROUP:
    case actions.REQUEST_CONSTRUCTOR_RENAME_GROUP:
    case actions.REQUEST_CONSTRUCTOR_REMOVE_GROUP:
    case actions.REQUEST_CONSTRUCTOR_ADD_BLOCK:
    case actions.REQUEST_CONSTRUCTOR_MOVE_BLOCK:
      return {
        ...state,
        workingGroups: true,
        error: false,
      }
    case actions.REQUEST_CONSTRUCTOR_GROUP_WAS_ADDED:
      return {
        ...state,
        data: {
          ...state.data,
          groups: [...state.data.groups, { ...action.group, blocks: [] }],
        },
        workingGroups: false,
      }
    case actions.REQUEST_CONSTRUCTOR_GROUP_WAS_RENAMED: {
      const { group } = action
      const index = state.data.groups.findIndex(g => g.id === group.id)

      return update(state, {
        data: {
          groups: { [index]: { title: { $set: group.title } } },
        },
        workingGroups: { $set: false },
      })
    }
    case actions.REQUEST_CONSTRUCTOR_GROUP_WAS_REMOVED:
      return {
        ...state,
        data: {
          ...state.data,
          groups: state.data.groups.filter(g => g.id !== action.id),
        },
        workingGroups: false,
      }
    case actions.REQUEST_CONSTRUCTOR_BLOCK_WAS_ADDED: {
      const { groups } = state.data
      const index = groups.findIndex(g => g.id === action.block.group)

      return update(state, {
        data: {
          groups: { [index]: { blocks: { $push: [action.block] } } },
        },
        workingGroups: { $set: false },
      })
    }
    case actions.REQUEST_CONSTRUCTOR_BLOCK_WAS_MOVED: {
      const { groups } = state.data
      const from = groups.findIndex(g => g.id === action.from)
      const to = groups.findIndex(g => g.id === action.to)
      const block = groups[from].blocks.find(b => b.id === action.id)

      const updated = update(state, {
        data: {
          groups: {
            [from]: {
              blocks: {
                $set: groups[from].blocks.filter(b => b.id !== action.id),
              },
            },
          },
        },
      })

      return update(updated, {
        data: {
          groups: {
            [to]: {
              blocks: { $splice: [[action.position - 1, 0, block]] },
            },
          },
        },
        workingGroups: { $set: false },
      })
    }
    case actions.REQUEST_CONSTRUCTOR_RESET:
      return initialState
    case actions.REQUEST_CONSTRUCTOR_ERROR: {
      const errorText = get(action, 'error')

      return {
        ...state,
        working: false,
        workingGroups: false,
        error: true,
        errorText,
      }
    }
    case actions.REQUEST_CONSTRUCTOR_CLEAR_ERROR: {
      return {
        ...state,
        working: false,
        workingGroups: false,
        error: false,
        errorText: '',
      }
    }
    case actions.WORKFLOW_PUBLISHED:
      return {
        ...state,
        data: { ...state.data, row_is_changed: action.row_is_changed },
      }
    default:
      return state
  }
}
