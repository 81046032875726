// @flow

import React from 'react'

import type { Node } from 'react'
import styles from './Tooltip.module.scss'
import ReactTooltip from 'react-tooltip'

type Props = {
  children: Node,
  settings?: Object,
  text: string,
}

const Tooltip = (props: Props): Node => {
  const {
    children,
    text,
    settings = {
      place: 'bottom',
      effect: 'solid',
    },
  } = props

  return (
    <>
      <a data-tip={text}> {children} </a>
      <ReactTooltip className={styles.tooltip} {...settings} />
    </>
  )
}

export default Tooltip
