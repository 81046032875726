// @flow

import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import Stab from '../Stub'
import * as selectors from '../PublicBuildingPage.selectors'

type Props = {
  banner: string,
  smallBanner: string,
  contacts: Array<Object>,
  managers: Array<Object>,
}

class DownloadBanner extends PureComponent<Props> {
  selectSize = () => {
    const { banner, smallBanner, contacts, managers } = this.props

    return (
      contacts.length + managers.length > 3
        ? smallBanner
        : banner || smallBanner
    )
  }

  render () {
    const { banner, smallBanner } = this.props
    const image = this.selectSize()

    if ((!banner && !smallBanner) || image === null) {
      return <Stab size='m' />
    }

    return (
      <img
        className='public-building-page__info-banner'
        src={image.origin}
        alt='download app'
      />
    )
  }
}

const mapStateToProps = state => ({
  banner: selectors.getBanner(state),
  smallBanner: selectors.getSmallBanner(state),
  contacts: selectors.getContacts(state),
  managers: selectors.getManagers(state)
})

export default connect(mapStateToProps)(DownloadBanner)
