// @flow

import type { Node } from 'react'
import type { IOragDetails } from './FlatInformation'
import React from 'react'
import { mapValues, trim } from 'lodash'
import setFormikError from '../../../utils/setFormikError'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import FieldRow from '../../FieldRow'
import Button from '../../Button'
import styles from './FlatInformation.module.scss'
import {
  COOLING_TYPE,
  EDV_NUMBER,
  FLAT_EQUIPMENT,
  FUNDING,
  METER_READING,
  REASON_TERMINATION,
  REASON_VACANCY,
  UNIT_NUMBER,
  KEYS,
  MAX_LENGTH,
} from './OragInformation.constants'

import * as Yup from 'yup'

type Props = {
  active: boolean,
  handleEditClose: () => void,
  initialValues: IOragDetails,
  isOpen: boolean,
  updateOragDetails: (data: { general: IOragDetails }) => Promise<IOragDetails>,
}

const OragInformationForm = ({
  initialValues,
  isOpen,
  active,
  updateOragDetails,
  handleEditClose,
}: Props): Node => {
  const { t } = useTranslation('Flat')
  const updateValues = async values => {
    const orag_cluster: IObjectType = {
      ...values,
    }

    setValues({ ...mapValues(orag_cluster, trim) })

    updateOragDetails({ orag_cluster: { ...mapValues(orag_cluster) } })
    handleEditClose()
  }
  const onClosed = () => {
    handleEditClose()
    resetForm()
  }

  const onSubmit = values => {
    updateValues(values)
      .then(() => handleEditClose())
      .catch(error => setFormikError(error, setFieldError))
  }

  const {
    values,
    errors,
    dirty,
    handleSubmit,
    setFieldError,
    setValues,
    handleChange,
    resetForm,
  } = useFormik({
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      [COOLING_TYPE]: Yup.string()
        .nullable()
        .max(
          MAX_LENGTH,
          t('MaxInputLength', {
            length: MAX_LENGTH,
          })
        ),
      [EDV_NUMBER]: Yup.string()
        .nullable()
        .max(
          MAX_LENGTH,
          t('MaxInputLength', {
            length: MAX_LENGTH,
          })
        ),
      [FLAT_EQUIPMENT]: Yup.string()
        .nullable()
        .max(
          MAX_LENGTH,
          t('MaxInputLength', {
            length: MAX_LENGTH,
          })
        ),
      [FUNDING]: Yup.string()
        .nullable()
        .max(
          MAX_LENGTH,
          t('MaxInputLength', {
            length: MAX_LENGTH,
          })
        ),
      [REASON_TERMINATION]: Yup.string()
        .nullable()
        .max(
          MAX_LENGTH,
          t('MaxInputLength', {
            length: MAX_LENGTH,
          })
        ),
      [REASON_VACANCY]: Yup.string()
        .nullable()
        .max(
          MAX_LENGTH,
          t('MaxInputLength', {
            length: MAX_LENGTH,
          })
        ),
      [UNIT_NUMBER]: Yup.string()
        .nullable()
        .max(
          MAX_LENGTH,
          t('MaxInputLength', {
            length: MAX_LENGTH,
          })
        ),
      [METER_READING]: Yup.string()
        .nullable()
        .max(
          MAX_LENGTH,
          t('MaxInputLength', {
            length: MAX_LENGTH,
          })
        ),
      [KEYS]: Yup.string()
        .nullable()
        .max(
          MAX_LENGTH,
          t('MaxInputLength', {
            length: MAX_LENGTH,
          })
        ),
    }),
    onSubmit,
  })

  return (
    <form name='orag_info' onSubmit={handleSubmit}>
      <FieldRow
        name={FLAT_EQUIPMENT}
        active={active}
        label={'FlatEquipment'}
        error={errors[FLAT_EQUIPMENT]}
        value={values[FLAT_EQUIPMENT]}
        placeholder={'EnterFlatEquipment'}
        handleChange={handleChange}
      />
      {(values[EDV_NUMBER] || isOpen) && (
        <FieldRow
          name={EDV_NUMBER}
          active={active}
          label={'EdvNumber'}
          error={errors[EDV_NUMBER]}
          value={values[EDV_NUMBER]}
          placeholder={'EnterEdvNumber'}
          handleChange={handleChange}
        />
      )}
      {(values[UNIT_NUMBER] || isOpen) && (
        <FieldRow
          name={UNIT_NUMBER}
          active={active}
          label={'OragUnitNumber'}
          error={errors[UNIT_NUMBER]}
          value={values[UNIT_NUMBER]}
          placeholder={'OragEnterUnitNumber'}
          handleChange={handleChange}
        />
      )}
      {(values[FUNDING] || isOpen) && (
        <FieldRow
          name={FUNDING}
          active={active}
          label={'Funding'}
          error={errors[FUNDING]}
          value={values[FUNDING]}
          placeholder={'EnterFunding'}
          handleChange={handleChange}
        />
      )}
      <FieldRow
        name={COOLING_TYPE}
        active={active}
        label={'CoolingType'}
        error={errors[COOLING_TYPE]}
        value={values[COOLING_TYPE]}
        placeholder={'EnterCoolingType'}
        handleChange={handleChange}
      />
      <FieldRow
        name={REASON_VACANCY}
        active={active}
        label={'ReasonVacancy'}
        error={errors[REASON_VACANCY]}
        value={values[REASON_VACANCY]}
        placeholder={'EnterReasonVacancy'}
        handleChange={handleChange}
      />
      {(values[REASON_TERMINATION] || isOpen) && (
        <FieldRow
          name={REASON_TERMINATION}
          active={active}
          label={'ReasonTermination'}
          error={errors[REASON_TERMINATION]}
          value={values[REASON_TERMINATION]}
          placeholder={'EnterReasonTermination'}
          handleChange={handleChange}
        />
      )}
      <FieldRow
        name={METER_READING}
        active={active}
        label={'MeterReading'}
        error={errors[METER_READING]}
        value={values[METER_READING]}
        placeholder={'EnterMeterReading'}
        handleChange={handleChange}
      />
      <FieldRow
        name={KEYS}
        active={active}
        label={'Keys'}
        error={errors[KEYS]}
        value={values[KEYS]}
        placeholder={'EnterKeys'}
        handleChange={handleChange}
      />
      {active && (
        <div className={styles.buttonWrapper}>
          <Button.Save disabled={!dirty} type='submit'>
            {t('Common:Save')}
          </Button.Save>
          <Button.Cancel onClick={onClosed}>{t('Common:Cancel')}</Button.Cancel>
        </div>
      )}
    </form>
  )
}

export default OragInformationForm
