import React, { useState } from 'react'
import ClickOutHandler from 'react-onclickout'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import ReactCalendar from '../filters/CalendarFilter/ReactCalendar'
import Select from '../Select/Select'

const ContractDateCalendar = ({ value, onClickOut, onClear, updateDate }) => {
  const { t } = useTranslation('Building')
  const [date, setDate] = useState(value)

  return (
    <div
      className='date-calendar'
      style={{ width: '16.5rem' }}
      onClick={e => e.stopPropagation()}
    >
      <ClickOutHandler onClickOut={onClickOut}>
        <ReactCalendar
          single
          minYear={1900}
          maxYear={moment().get('year') + 15}
          Select={Select}
          value={date || moment().format('YYYY-MM-DD')}
          style={{
            textAlign: 'center',
            position: 'absolute',
            right: 0,
            top: '25px',
            zIndex: 1,
            background: '#fff',
            borderRadius: '0.5rem',
            border: '0.1rem solid #d0d0d0',
            boxShadow: '0 0.35rem 0.8rem rgba(0, 0, 0, 0.11)',
            margin: 0,
            overflow: 'hidden',
          }}
          clearText={t('ClearText')}
          onClear={onClear}
          onValueChange={setDate}
          onChange={date => {
            setDate(date[0])
            updateDate({ name: value.name, value: date[0] })
            onClickOut()
          }}
        />
      </ClickOutHandler>
    </div>
  )
}

export default ContractDateCalendar
