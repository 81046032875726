// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'

const EmailConfirmError = () => {
  const { t } = useTranslation('ConfirmEmail')

  return <div className='login_anno_text'>{t('ErrorTitle')}</div>
}

export default EmailConfirmError
