// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'
import { TERMS_LINK, GDPR_LINK } from '../../../../constants'
import styles from './Checkbox.module.scss'

type Props = {
  checked: boolean,
  name: string,
  onChange: (SyntheticEvent<HTMLInputElement>) => void,
}

const Checkbox = (props: Props): Node => {
  const { name, checked } = props

  const { t } = useTranslation('Registration')

  return (
    <div className={styles.container}>
      <div className={styles.checkbox}>
        <input
          type='checkbox'
          id='license'
          name={name}
          checked={checked}
          onChange={props.onChange}
        />
        <label htmlFor='license'>
          {`${t('Accept')} `}
          <a target='_blank' href={TERMS_LINK} rel='noopener noreferrer'>
            {t('TermsAndConditions')}
          </a>
          {` ${t('And')} `}
          <a target='_blank' href={GDPR_LINK} rel='noopener noreferrer'>
            {t('Gdpr')}
          </a>
        </label>
      </div>
    </div>
  )
}

export default Checkbox
