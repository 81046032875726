// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import { Header, SimpleBlock } from '../Block'
import Settings from '../Settings'
import InputField from '../InputField'

type Props = {
  value: string,
  working: boolean,
}

const Domain = (props: Props): Node => {
  const { working, value } = props

  const { t } = useTranslation('Settings')

  return (
    <SimpleBlock working={working}>
      <Header header>{t('DomainTitle')}</Header>
      <Settings.Row label={t('Domain')}>
        <InputField
          disabled
          name='corporate_website'
          placeholder='yoursite.com'
          value={value}
        />
      </Settings.Row>
    </SimpleBlock>
  )
}

export default Domain
