// @flow

import React, { useState } from 'react'

import { useLocation, useRouteMatch } from 'react-router-dom'
import { Header, SimpleBlock } from '../Block'
import Nbsp from '../NBSP'
import { useTranslation } from 'react-i18next'
import RequestGallery from '../RequestGallery'

const RequestWrapper = () => {
  const match = useRouteMatch()
  const location = useLocation()
  const { t } = useTranslation('Request')
  const [count, setCount] = useState(null)

  return (
    <div className='content__col'>
      <SimpleBlock className='unit unit--default'>
        <Header header>
          {t('RequestListTitle')}
          <span className='unit__title-num'>
            <Nbsp />
            {count ? count : ''}
          </span>
        </Header>
        <RequestGallery match={match} location={location} setCount={setCount} />
      </SimpleBlock>
    </div>
  )
}

export default RequestWrapper
