// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import Checkbox from '../../components/Checkbox'
import { ORDER_STATUSES } from '../OrderProfilePage/OrderProfile/OrderProfile.constants'
import { EntityIcon } from '../../components/Icon'
import NewTableBodyCell from '../../components/NewTable/NewTableBodyCell'
import NewTableBodyRow from '../../components/NewTable/NewTableBodyRow'
import { getFormattedDate, parseMpPrice } from '../../utils/utils'
import Button from '../../components/Button'

const OrdersTableBodyRow = (props): Node => {
  const {
    code,
    order: {
      orderNo,
      offer,
      id,
      clientInfo,
      createdAt,
      status,
      grandTotal: cost,
      provider: {
        name,
        image: { origin },
      },
    },
    isStaff,
    isAdmin,
    checked,
  } = props

  const { t } = useTranslation('Orders')

  const statusObj = ORDER_STATUSES.find(s => s.value === status)

  const offerName = JSON.parse(offer).name

  const cellTextOrder = t('orderOffer', {
    orderNo,
    offer: offerName,
  })

  const isNullPrice = parseFloat(cost) === 0

  const cellTextCost = isNullPrice
    ? '-'
    : t('cost', { cost: parseMpPrice(cost, code) })

  const formattedDate = getFormattedDate(createdAt)

  const handleChange = () => props.changeSelected({ status, id })

  return (
    <NewTableBodyRow onClick={props.onClick}>
      <NewTableBodyCell title={name}>
        {isStaff && (
          <Checkbox
            style={{ margin: '0 0 0 16px' }}
            checked={checked}
            onChange={handleChange}
          />
        )}
        <i className='table-item__user-pic' style={{ marginLeft: '16px' }}>
          <img src={origin} />
        </i>
      </NewTableBodyCell>
      {isAdmin && <NewTableBodyCell title={JSON.parse(clientInfo).name} />}
      <NewTableBodyCell title={cellTextOrder}>
        <EntityIcon id='order' />
      </NewTableBodyCell>
      <NewTableBodyCell title={cellTextCost} />
      <NewTableBodyCell
        title={t(`status${statusObj.translationKey}`)}
        style={{ color: statusObj.color }}
      />
      <NewTableBodyCell title={formattedDate}>
        {isStaff && (
          <div className='table-extra table-extra--settings'>
            <Button.Save
              icon='status'
              style={{ marginRight: '16px' }}
              onClick={props.onChangeStatus}
            >
              {t('ChangeOrderStatus')}
            </Button.Save>
          </div>
        )}
      </NewTableBodyCell>
    </NewTableBodyRow>
  )
}

export default OrdersTableBodyRow
