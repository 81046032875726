// @flow

import React, { Component } from 'react'
import type { Element } from 'react'
import { withTranslation } from 'react-i18next'

import Modal from '../Modal'
import SetPostScope from '../modals/SetPostScope'
import PostScopeButton from './PostScopeButton'

type Props = {
  highlighted?: boolean,
  onUpdate: Object => void,
  t: string => string,
  user: Object,
  wasCreated: boolean,
}

type State = {
  allBuildings: boolean,
  buildings: Array<Object>,
  isGroupsPost: boolean,
  modal: ?Element<typeof SetPostScope>,
  scope: Array<Object>,
}

const INITIAL_STATE = {
  allBuildings: false,
  buildings: [],
  modal: null,
  scope: [],
  isGroupsPost: false,
}

class PostScope extends Component<Props, State> {
  state = INITIAL_STATE

  componentDidUpdate(prevProps) {
    const { wasCreated } = this.props

    if (wasCreated && !prevProps.wasCreated) {
      this.setState(INITIAL_STATE)
    }
  }

  updateScope = (
    scope: Array<Object>,
    buildings: Array<Object>,
    allBuildings: boolean,
    isGroupsPost: boolean
  ) => {
    this.setState(
      {
        scope,
        allBuildings,
        buildings,
        modal: null,
        isGroupsPost,
      },
      this.props.onUpdate({ allBuildings, buildings, scope, isGroupsPost })
    )
  }

  hideModal = () => {
    this.setState({ modal: null })
  }

  showModal = () => {
    const { allBuildings, buildings, scope, isGroupsPost } = this.state

    this.setState({
      modal: (
        <SetPostScope
          allBuildings={allBuildings}
          buildings={buildings}
          isGroupsPost={isGroupsPost}
          scope={scope}
          onClose={this.hideModal}
          onOk={this.updateScope}
        />
      ),
    })
  }

  render() {
    const { highlighted } = this.props
    const { allBuildings, modal, scope } = this.state

    return (
      <>
        <PostScopeButton
          allBuildings={allBuildings}
          showModal={this.showModal}
          scope={scope}
          highlighted={highlighted}
        />
        <Modal isOpen={!!modal} style={{ content: { width: '370px' } }}>
          {modal}
        </Modal>
      </>
    )
  }
}

export default withTranslation('Post')(PostScope)
