// @flow

import { all, put, call, fork, takeLatest } from 'redux-saga/effects'

import { modalError } from '../../Layout/Layout.actions'
import api from '../../../core/api'
import * as actions from './AddRequestCategoryPopup.actionTypes'

function* watchInitiate() {
  yield takeLatest(actions.ADD_REQUEST_CATEGORY_POPUP_INITIATE, initiate)
}

function* watchSave() {
  yield takeLatest(actions.ADD_REQUEST_CATEGORY_POPUP_SAVE, save)
}

function* watchUpdate() {
  yield takeLatest(actions.ADD_REQUEST_CATEGORY_POPUP_UPDATE, update)
}

function* handleError(error) {
  const { response } = error.message
  yield put({ type: actions.ADD_REQUEST_CATEGORY_POPUP_ERROR, response })
}

function* initiate() {
  try {
    const result = yield call(api.requestCategory.get, { page_size: 100 })
    yield put({
      type: actions.ADD_REQUEST_CATEGORY_POPUP_WAS_INITIATED,
      ...result,
    })
  } catch (error) {
    yield* handleError(error)
  }
}

function* save({ created }) {
  try {
    if (created.length) {
      yield call(api.requestCategory.massCreate, JSON.stringify(created))
    }

    yield put({ type: actions.ADD_REQUEST_CATEGORY_POPUP_WAS_SAVED })
  } catch (error) {
    yield* handleError(error)

    if (error.message.response.status === 400) {
      yield put(modalError(error.message.response.data))
    }
  }
}

function* update({ items }) {
  try {
    const response = yield call(
      api.requestCategory.massUpdate,
      JSON.stringify(items)
    )

    yield put({
      type: actions.ADD_REQUEST_CATEGORY_POPUP_WAS_UPDATED,
      response,
    })
  } catch (error) {
    yield* handleError(error)

    if (error.message.response.status === 400) {
      yield put(modalError(error.message.response.data))
    }
  }
}

export default function* watch() {
  yield all([fork(watchInitiate), fork(watchSave), fork(watchUpdate)])
}
