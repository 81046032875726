// @flow

import React, { useState } from 'react'
import type { Node } from 'react'

import { getLivingType } from '../../core/api/api.living'
import { useLoad } from '../../hooks'
import LivingTypeModal from './LivingTypeModal'
import LivingTypeTable from './LivingTypeTable'

const LivingType = (): Node => {
  const [isModalOpen, setModalOpen] = useState(false)
  const [editingLivingType, setEditingLivingType] = useState(null)
  const [livingTypes, isLoading, , load] = useLoad(getLivingType)

  const handleClose = () => setModalOpen(false)
  const handleCreate = () => setModalOpen(true)

  return (
    <>
      {livingTypes && (
        <>
          <LivingTypeTable isLoading={isLoading} onEdit={handleCreate} />
          {isModalOpen && (
            <LivingTypeModal
              load={load}
              editingLivingType={editingLivingType}
              setEditingLivingType={setEditingLivingType}
              onClose={handleClose}
            />
          )}
        </>
      )}
    </>
  )
}

export default LivingType
