// @flow

import React from 'react'
import classnames from 'classnames'
import type { Node } from 'react'

import styles from './ModalHead.module.scss'

type Props = {
  className?: string,
  showAlt?: boolean,
  style?: Object,
  title: string,
}

const ModalHead = (props: Props): Node => {
  const { title, style, className, showAlt } = props

  const modalHeadClass = classnames(className, styles.head)

  const modalHeadProps = Object.assign(
    {},
    { className: modalHeadClass, style },
    showAlt ? { title } : undefined
  )

  return <div {...modalHeadProps}>{title}</div>
}

export default ModalHead
