// @flow

import React from 'react'
import type { Node } from 'react'

import ModalRow from '../../../Modal/ModalRow'

import styles from './ActivityModalRow.module.scss'

type Props = {
  children: Node,
}

const ActivityModalRow = (props: Props): Node => {
  const { children } = props

  return <ModalRow className={styles.row}>{children}</ModalRow>
}

export default ActivityModalRow
