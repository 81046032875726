// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import NewTableHeader from '../../NewTable/NewTableHeader'
import NewTableHeaderCell from '../../NewTable/NewTableHeaderCell'
import NewTableHeaderRow from '../../NewTable/NewTableHeaderRow'

const TableHead = (): Node => {
  const { t } = useTranslation('Workflow')

  return (
    <NewTableHeader>
      <NewTableHeaderRow>
        <NewTableHeaderCell
          title={t('WorkflowNameSort')}
          sortKey='title'
          style={{ width: '20%' }}
        />
        <NewTableHeaderCell
          title={t('WorkflowStatusSort')}
          sortKey='status'
          style={{ width: '20%' }}
        />
        <NewTableHeaderCell
          title={t('WorkflowChangedSort')}
          sortKey='is_changed'
          style={{ width: '20%' }}
        />
        <NewTableHeaderCell
          title={t('WorkflowTypeSort')}
          sortKey='type'
          style={{ width: '20%' }}
        />
        <NewTableHeaderCell
          title={t('WorkflowPublicationDateSort')}
          sortKey='updated'
          style={{ width: '20%' }}
        />
      </NewTableHeaderRow>
    </NewTableHeader>
  )
}

export default TableHead
