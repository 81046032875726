// @flow

import * as t from './HeaderSearch.actionTypes'

const {
  HEADER_SEARCH_LONG,
  HEADER_SEARCH_SHORT,
  HEADER_SEARCH_INPUT,
  HEADER_SEARCH_QUERY,
  HEADER_SEARCH_QUERY_SUCCESS,
  HEADER_SEARCH_QUERY_ERROR,
  HEADER_SEARCH_CHANGE_LOCATION,
  HEADER_SEARCH_SHOW_SELECT,
  HEADER_SEARCH_HIDE_SELECT,
  HEADER_SEARCH_SELECT_OPTION,
  HEADER_SEARCH_HIDE_SUGGEST,
} = t

const initialState = {
  hasOpenSearch: false,
  isSuggestOpen: false,
  searchStr: '',
  data: [],
  error: false,
  working: true,
  errorMessage: '',

  searchOptions: [
    {
      id: 0,
      label: 'SearchRequest',
      value: 'request',
      itemUrl: 'request',
    },
    {
      id: 1,
      label: 'SearchBuilding',
      value: 'building',
      itemUrl: 'building',
    },
    {
      id: 2,
      label: 'SearchFlat',
      value: 'flat',
      itemUrl: 'flat',
    },
    {
      id: 3,
      label: 'SearchNewsband',
      value: 'newsband',
      itemUrl: 'post',
    },
    {
      id: 4,
      label: 'SearchMails',
      value: 'mail',
      itemUrl: 'mail',
      staffOnly: true,
    },
    {
      id: 5,
      label: 'SearchTenant',
      value: 'user',
      itemUrl: 'profile',
      staffOnly: true,
    },
  ],
  selectedOptionId: 0,
  showSelect: false,
}

export default function headerSearchReducer(state = initialState, action) {
  switch (action.type) {
    case HEADER_SEARCH_LONG: {
      return {
        ...state,
        hasOpenSearch: true,
      }
    }
    case HEADER_SEARCH_SHORT: {
      return {
        ...state,
        hasOpenSearch: false,
        isSuggestOpen: false,
        searchStr: '',
        data: { results: { objects: [] } },
      }
    }
    case HEADER_SEARCH_INPUT: {
      return {
        ...state,
        searchStr: action.i,
      }
    }
    case HEADER_SEARCH_QUERY: {
      return {
        ...state,
        isSuggestOpen: true,
        working: true,
      }
    }

    case HEADER_SEARCH_QUERY_SUCCESS: {
      return {
        ...state,
        working: false,
        data: action.data,
      }
    }
    case HEADER_SEARCH_CHANGE_LOCATION: {
      return {
        ...state,
        hasOpenSearch: false,
        isSuggestOpen: false,
        searchStr: '',
        data: { results: { objects: [] } },
      }
    }
    case HEADER_SEARCH_QUERY_ERROR: {
      return {
        ...state,
        error: true,
        errorMessage: action.errorMessage,
      }
    }
    case HEADER_SEARCH_SHOW_SELECT: {
      return {
        ...state,
        isSuggestOpen: false,
        showSelect: true,
      }
    }
    case HEADER_SEARCH_HIDE_SELECT: {
      return {
        ...state,
        isSuggestOpen: false,
        showSelect: false,
      }
    }
    case HEADER_SEARCH_HIDE_SUGGEST: {
      return {
        ...state,
        isSuggestOpen: false,
      }
    }
    case HEADER_SEARCH_SELECT_OPTION: {
      return {
        ...state,
        showSelect: false,
        isSuggestOpen: false,
        selectedOptionId: action.option.id,
        searchStr: '',
        data: { results: { objects: [] } },
      }
    }
    default:
      return state
  }
}
