// @flow

import React from 'react'
import moment from 'moment'

import { isDeLocale } from '../../../utils/utils'

type arg = { date: string }

const Day = ({ date }: arg) => {
  const day = isDeLocale(moment.locale()) ? date.format('D.') : date.format('D')
  const dayWeek = date.format('dddd')
  const month = date.format('MMMM')

  return (
    <div className='public-building-page__header-date'>
      <div className='public-building-page__header-day'>{day}</div>
      <div className='public-building-page__header-month'>
        <div>{month}</div>
        <div>{dayWeek}</div>
      </div>
    </div>
  )
}

export default Day
