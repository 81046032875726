// @flow

import React, { Component, Fragment } from 'react'
import classnames from 'classnames'

import Button from '../Button'
import Image from '../ImageSquareFit'
import LoadImagePopup from '../modals/LoadImagePopup'
import { FILE_ACCEPT_TYPES } from '../../containers/FilesUpload'
import { DEFAULT_AVATAR } from '../../constants'
import NewFilesUpload from '../NewFilesUpload'

import styles from './AvatarEditable.module.scss'

const SIZE = 170

export const AVATAR_MAX_SIZE = 2.5

type Props = {
  alt: string,
  image: Object,
  onFinishEdit: (?Object) => void,
  onStartEdit: () => void,
  title: string,
}
type State = {
  editing: boolean,
  uploaded: ?Object,
  uploading: boolean,
}

class AvatarEditable extends Component<Props, State> {
  static defaultProps = {
    alt: '',
  }

  state = {
    editing: false,
    uploaded: null,
    uploading: false,
  }

  startUpload = () => {
    this.setState({ uploading: true })
    this.props.onStartEdit()
  }

  finishUpload = (files: Array<Object>) => {
    if (files.length) {
      this.setState({
        uploaded: files[0],
      })
    }

    this.setState({
      editing: true,
      uploading: false,
    })

    if (!files.length) {
      this.update()
    }
  }

  update = (file: Object) => {
    this.setState({
      editing: false,
      uploaded: null,
    })

    this.props.onFinishEdit(file)
  }

  hideModal = () => {
    this.setState({
      editing: false,
      uploaded: null,
    })

    this.props.onFinishEdit()
  }

  renderEditing = () => {
    const { title } = this.props
    const { editing, uploaded } = this.state

    return (
      Boolean(editing && uploaded) && (
        <LoadImagePopup
          width={SIZE}
          height={SIZE}
          image={uploaded}
          type='avatar'
          titleText={title}
          onCancel={this.hideModal}
          onSave={this.update}
        />
      )
    )
  }

  render() {
    const { alt, image, style } = this.props
    const src = image.preview || image.origin || image.dataUrl
    const className = classnames(styles.avatar, {
      [styles.withBorder]: src !== DEFAULT_AVATAR,
    })

    return (
      <Fragment>
        <NewFilesUpload
          useBase64
          maxSize={AVATAR_MAX_SIZE}
          accept={FILE_ACCEPT_TYPES.rasterImages}
          className={className}
          onStartUpload={this.startUpload}
          onFinishUpload={this.finishUpload}
        >
          <Image alt={alt} src={src} style={style} />
          <Button.Edit />
        </NewFilesUpload>

        {this.renderEditing()}
      </Fragment>
    )
  }
}

export default AvatarEditable
