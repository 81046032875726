// @flow

export const CREATED_REQUEST_STATUS = 1
export const IN_PROGRESS_REQUEST_STATUS = 2
export const COMPLETED_REQUEST_STATUS = 3
export const REJECTED_REQUEST_STATUS = 4
export const REQUEST_TYPES_WITHOUT_RATING = [
  CREATED_REQUEST_STATUS,
  IN_PROGRESS_REQUEST_STATUS,
]
