// @flow

import { last } from 'lodash-es'
import * as Yup from 'yup'

import {
  EMAIL_VARIABLE_TYPE,
  FILE_VARIABLE_TYPE,
  IMAGE_TYPE,
  INTEGER_VARIABLE_TYPE,
  LINK_TYPE,
  PHONE_VARIABLE_TYPE,
  TEXT_TYPE,
  VARIABLE_TYPE,
} from './CardStage.constants.js'
import type { ChunkType } from '../NewWizard.types'

export function isBotVisible(chunk: ?ChunkType): boolean {
  if (!chunk) {
    return false
  }

  if (chunk.length === 0) {
    return true
  }

  const firstCardType = chunk[0].type

  return firstCardType === TEXT_TYPE || firstCardType === IMAGE_TYPE
}

export function getVariables(chunk: ChunkType): ChunkType {
  return chunk.filter(card => card.type === VARIABLE_TYPE)
}

export function getLinks(chunk: ChunkType): ChunkType {
  return chunk.filter(card => card.type === LINK_TYPE)
}

export function getInitialValues(chunk: ?ChunkType): ?Object {
  if (!chunk || !chunk.length) {
    return null
  }

  const variables = getVariables(chunk)
  const links = getLinks(chunk)

  const variablesValues = variables.reduce((acc, entry) => {
    acc[entry.name] = ''

    return acc
  }, {})

  if (links && links.length) {
    return { ...variablesValues, link: '' }
  }

  return variablesValues
}

export function getValidationSchema(chunk: ?ChunkType): ?Object {
  if (!chunk || !chunk.length) {
    return null
  }

  const variables = getVariables(chunk)
  const links = getLinks(chunk)

  const variablesSchema = variables.reduce((acc, entry) => {
    let validator = Yup.string()

    if (entry.variable_type === INTEGER_VARIABLE_TYPE) {
      validator = Yup.number()
    } else if (entry.variable_type === EMAIL_VARIABLE_TYPE) {
      validator = Yup.string().email()
    } else if (entry.variable_type === FILE_VARIABLE_TYPE) {
      validator = Yup.array().of(Yup.object())
    } else if (entry.variable_type === PHONE_VARIABLE_TYPE) {
      validator = Yup.string()
        .min(5, 'Phone_number_must_contain_at_least_5_digits')
        .max(15, 'Phone_number_must_contain_no_more_than_15_digits')
        .transform(current => {
          if (!current) {
            return current
          }

          return current.replace('+', '')
        })
    }

    acc[entry.name] = validator

    return acc
  }, {})

  if (links && links.length) {
    return Yup.object({
      ...variablesSchema,
      link: Yup.object().required('SelectBlock'),
    })
  }

  return Yup.object(variablesSchema)
}

export function isEmptyChunk(chunk: ?ChunkType): boolean {
  return !!(chunk && chunk.length === 0)
}

export function isWorkflowEnd(chunk: ?ChunkType): boolean {
  return isEmptyChunk(chunk) || isLastChunk(chunk)
}

export function isLastChunk(chunk: ?ChunkType): boolean {
  if (!chunk || !chunk.length) {
    return false
  }

  const cardType = last(chunk).type

  return cardType === IMAGE_TYPE || cardType === TEXT_TYPE
}
