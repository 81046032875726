// @flow

import { all, put, call, fork, takeEvery } from 'redux-saga/effects'

import { PREVIEW_GREAT_SIZE } from '../../constants'
import api from '../../core/api'
import { serverError } from '../Layout/Layout.actions'
import * as actions from './FriendsRequests.actionTypes'
import { UPDATE_COUNTERS_SUCCESS } from '../init/init.actionTypes'
import { FRIENDS_LIST_LOAD } from '../Friends/Friends.actionTypes'

const getParams = params => ({
  preview_size: PREVIEW_GREAT_SIZE,
  ...params,
})

function* watchInit() {
  yield takeEvery(actions.FRIENDS_REQUESTS_INITIATING, init)
}

function* watchSetPage() {
  yield takeEvery(actions.FRIENDS_REQUESTS_PAGE, setPage)
}

function* watchHandle() {
  yield takeEvery(actions.FRIENDS_REQUESTS_HANDLE, handle)
}

function* init({ params }) {
  try {
    const result = yield call(api.friends.getIncoming, getParams(params))
    yield put({ type: actions.FRIENDS_REQUESTS_INITIATED, ...result })
  } catch (error) {
    yield put(serverError(error))
  }
}

function* setPage({ params }) {
  try {
    const result = yield call(api.friends.getIncoming, getParams(params))
    yield put({ type: actions.FRIENDS_REQUEST_LOADED, ...result })
  } catch (error) {
    yield put(serverError(error))
  }
}

function* handle({ params, user, accept }) {
  try {
    const relationship = `friend_request_${accept ? 'accepted' : 'rejected'}`
    yield call(api.friends.handleRequest, { user, relationship })

    const result = yield call(api.friends.getIncoming, getParams(params))
    yield put({
      type: UPDATE_COUNTERS_SUCCESS,
      counters: { friend_invites: result.meta.count },
    })

    yield put({ type: actions.FRIENDS_REQUEST_LOADED, ...result })
    yield put({ type: FRIENDS_LIST_LOAD, page: 1 })
  } catch (error) {
    yield put(serverError(error))
  }
}

export default function* watch() {
  yield all([fork(watchInit), fork(watchSetPage), fork(watchHandle)])
}
