// @flow

import { put, call, fork, takeEvery } from 'redux-saga/effects'
import type { Saga } from 'redux-saga'
import { pick } from 'lodash-es'

import api from '../../core/api'
import { serverError } from '../Layout/Layout.actions'
import * as actions from './CompanyRegistration.actionTypes'

export function* watchInvite(): Saga<void> {
  yield takeEvery(actions.COMPANY_REGISTRATION_INVITE, invite)
}

export function* invite({ params }: { params: Object }): Saga<void> {
  try {
    const response = yield call(api.register.registerUKManager, params)
    const result = pick(response, ['first_name', 'email'])

    yield put({
      type: actions.COMPANY_REGISTRATION_CONFIRMED,
      result,
    })
  } catch (error) {
    if (error.message.response.status === 400) {
      yield put({
        type: actions.COMPANY_REGISTRATION_ERROR,
        error: error.message.response.data,
      })

      return
    }

    yield put(serverError(error))
  }
}

export default function* watch(): Saga<void> {
  yield fork(watchInvite)
}
