// @flow

import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { find } from 'lodash-es'
import type { Node } from 'react'

import { cutText } from '../../../utils/utils'
import Header from '../../Post/NewPostItem/Header'
import Info from '../../Post/NewPostItem/Info'
import Content from '../../Post/NewPostItem/Content'
import Footer from '../../Post/NewPostItem/Footer'
import Button from '../../Button'
import Title from './Title'
import Status from './Status'

import styles from './RequestNewsPost.module.scss'

type Props = { post: Object, requestStatuses: Object }

const RequestNewsPost = (props: Props): Node => {
  const {
    post: { created, owner_obj, request, request_dump },
    requestStatuses,
  } = props

  const { t } = useTranslation('Request')

  const requestTitle = cutText(request.title, 90)

  const status = find(requestStatuses, { id: request_dump.status })

  return (
    <div className={styles.postItem}>
      <Header>
        <Info isWelcome created={created} ownerObj={owner_obj} />
      </Header>
      <Content>
        <Title requestTitle={requestTitle} />
        <Status status={status} />
      </Content>
      <Footer>
        <Link to={`/request/${request.id}`}>
          <Button.Save>{t('View')}</Button.Save>
        </Link>
      </Footer>
    </div>
  )
}

export default RequestNewsPost
