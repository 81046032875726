import { get } from 'lodash-es'

const setFormikError = (error, setFieldError) => {
  const [data] = get(error, ['message', 'response', 'data', 'errors'])
  for (const field of data.loc) {
    setFieldError(field, 'is not valid')
  }
}

export default setFormikError
