// @flow

import React from 'react'
import { useSelector } from 'react-redux'
import classnames from 'classnames'
import type { Node } from 'react'

import Icon from '../../Icon'
import { getThemeColor } from '../../../utils/commonSelectors'
import type { IconId } from '../../Icon'

import styles from './Text.module.scss'

type Props = {
  bordered?: boolean,
  className?: string,
  icon: IconId,
  large?: boolean,
  onClick?: (SyntheticEvent<HTMLDivElement>) => void,
  title: string,
  warning?: boolean,
}

const Text = (props: Props): Node => {
  const { className, icon, title, warning, large, bordered } = props

  const themeColor: string = useSelector(getThemeColor)

  const btnClass = classnames(className, styles.button, {
    [styles.warning]: warning,
    [styles.large]: large,
  })

  const buttonProps = Object.assign(
    {},
    { className: btnClass },
    { style: { '--themeColor': themeColor } },
    props.onClick ? { onClick: props.onClick } : undefined
  )

  const iconClass = classnames({ [styles.bordered]: bordered })

  return (
    <button type='button' {...buttonProps}>
      <span className={iconClass}>
        <Icon id={icon} />
      </span>
      {title}
    </button>
  )
}

export default Text
