// @flow

import React, { useState, useEffect } from 'react'
import type { Node } from 'react'
import { useTranslation } from 'react-i18next'

import classnames from 'classnames'
import { filter, difference, omit, union } from 'lodash-es'

import NewSelectSimple from '../../NewSelectSimple'
import Modal from '../../Modal'
import ModalRow from '../../Modal/ModalRow'
import ConfirmationPopup from '../../modals/ConfirmationPopup'
import Checkbox from '../../Checkbox/Checkbox'
import InputField from '../../InputField'

import PostAudience from '../../Post/PostAudience'
import PostTitle from '../../Post/PostTitle'
import PostDescription from '../../Post/PostDescription'

import TemplateVariables from '../../Templates/TemplatePopup/TemplateVariables'
import NewTemplateDropdown from '../../NewTemplateDropdown'

import { getPostTypeOptions, getAudience } from '../../Post/Post.utils'
import {
  getDeadlineTemplate,
  getDeadlineObject,
} from '../../Activities/ActivityModal/ActivityModal.utils'

import { OMIT_FIELDS } from '../AutomateTable/Automate.constants'

import { removeFile } from '../../../utils/file'

import { getSnippetVariables } from '../../../core/api/api.requestConstructor'

import {
  BUILDING_TYPE,
  BUILDINGS_SCOPE,
  POST_TYPES_INT,
  TICKER_TYPE,
  TO_ALL_BUILDINGS,
  REQUEST_BUILDING,
} from '../../Post/Post.constants'

import RequestFileList from '../../Emails/RequestFileList'

import styles from '../../Post/PostCreate/PostCreate.module.scss'

type Props = {
  data: Object,
  onUpdate: Object => void,
}

const CreatePost = (props: Props): Node => {
  const { data } = props
  const { t } = useTranslation('Post')

  const postTypeOptions = getPostTypeOptions(t)

  const getPostTypeOption = type_id => {
    for (let key in POST_TYPES_INT) {
      if (POST_TYPES_INT[key] === type_id) {
        return postTypeOptions.find(o => o.value === key)
      }
    }

    return postTypeOptions[0]
  }

  const [postTypeOption, setPostTypeOption] = useState(
    getPostTypeOption(data.type_id)
  )
  const [modal, setModal] = useState(null)

  const [buildings, setBuildings] = useState([])
  const [isGroupsPost, setGroupsPost] = useState(false)

  const [periodTemplate, setPeriodTemplate] = useState(
    getDeadlineTemplate(
      data.online_period_days,
      data.online_period_hours,
      data.online_period_minutes
    )
  )

  const [files, setFiles] = useState([])
  const [filesUploading, setFilesUploading] = useState(false)

  useEffect(() => {
    if (data.building_groups?.length > 0) {
      setGroupsPost(true)
      setBuildings(data.building_groups)
    } else {
      setBuildings(data.buildings || [])
    }

    setFiles(data.files || [])

    props.onUpdate({
      ...omit(data, OMIT_FIELDS),
      comments_forbidden: data.comments_forbidden || false,
      files_options: data.files_options || [],
      online_period_days: data.online_period_days || null,
      online_period_hours: data.online_period_hours || null,
      online_period_minutes: data.online_period_minutes || null,
      type_id: data.type_id || 1,
    })
  }, [])

  const handleRemoveFile = ({ id }: Object) => {
    setFiles(files.filter(f => f.id !== id))
    props.onUpdate({
      ...data,
      file_ids: data.file_ids.filter(f => f !== id),
    })
    removeFile(id)
  }

  const handleUpdateFiles = files => {
    setFiles(files)
    setFilesUploading(false)
    props.setWorking(false)
    props.onUpdate({ ...data, file_ids: files.map(f => f.id) })
  }

  const handleStartFilesUpload = () => {
    setFilesUploading(true)
    props.setWorking(true)
  }

  const handleChangePeriod = e => {
    const deadline = e.target.value
    setPeriodTemplate(deadline)
    const deadline_object = getDeadlineObject(deadline)
    const period_object = {
      online_period_days: deadline_object.deadline_days,
      online_period_hours: deadline_object.deadline_hours,
      online_period_minutes: deadline_object.deadline_minutes,
    }
    props.onUpdate({ ...data, ...period_object })
  }

  const handleChangeComment = e =>
    props.onUpdate({ ...data, comments_forbidden: e.currentTarget.checked })

  const hideModal = () => setModal(null)

  const confirmChangeType = option => {
    if (option.value === postTypeOption.value) {
      return
    }

    if (!data.text && !data.title && !data.audiences?.length) {
      handleChangeType(option)

      return
    }

    setModal(
      <ConfirmationPopup
        title={t('ChangePostTypeTitle')}
        text={t('ConfirmPostType')}
        confirm={t('ChangeType')}
        onClose={hideModal}
        onOk={() => handleChangeType(option)}
      />
    )
  }

  const handleChangeType = option => {
    hideModal()
    setPostTypeOption(option)
    setFiles([])
    props.onUpdate({
      ...data,
      audiences: [],
      building_ids: [],
      building_group_ids: [],
      buildings_options: [],
      building_groups_options: [],
      files: [],
      files_options: [],
      text: '',
      title: '',
      type_id: POST_TYPES_INT[option.value],
    })
  }

  const getBuildingOrGroupField = isGroupsPost =>
    isGroupsPost ? 'building_group_ids' : 'building_ids'
  const getBuildingOrGroupOption = isGroupsPost =>
    isGroupsPost ? 'building_groups_options' : 'buildings_options'
  const getAllBuildingsOption = isGroupsPost =>
    isGroupsPost ? 'all_building_groups' : 'all_buildings'
  const getRequestBuildingOption = isGroupsPost =>
    isGroupsPost ? 'request_building_groups' : 'request_building'

  const handleUpdateAudience = (
    audience,
    buildings,
    allBuildings,
    isGroupsPost,
    requestBuilding
  ) => {
    const buildingOrGroupField = getBuildingOrGroupField(isGroupsPost)

    const buildingOrGroupOption = getBuildingOrGroupOption(isGroupsPost)

    let options = []

    if (allBuildings) {
      options.push(getAllBuildingsOption(isGroupsPost))
    }

    if (requestBuilding) {
      options.push(getRequestBuildingOption(isGroupsPost))
    }

    props.onUpdate({
      ...data,
      audiences: audience,
      building_group_ids: [],
      building_ids: [],
      building_groups_options: [],
      buildings_options: [],
      [buildingOrGroupField]: buildings.map(b => b.id || b.value),
      [buildingOrGroupOption]: options,
    })
    setBuildings(buildings)
    setGroupsPost(isGroupsPost)
  }

  const handleRemoveAudienceItem = (id, type) => {
    const removeHandler =
      type === BUILDING_TYPE ? removeBuildingItem : removeAudienceItem

    removeHandler(id)
  }

  const removeBuildingItem = id => {
    const newBuildings = filter(
      buildings,
      item => item.id !== id && item.value !== id
    )
    const buildingOrGroupField = getBuildingOrGroupField(isGroupsPost)

    if (!newBuildings.length && !requestBuilding) {
      const newAudience = filter(
        data.audiences,
        item => !BUILDINGS_SCOPE.includes(item)
      )

      if (newAudience.length !== data.audiences.length) {
        props.onUpdate({
          ...data,
          audiences: newAudience,
          [buildingOrGroupField]: newBuildings.map(b => b.id),
        })
      }
    } else {
      props.onUpdate({
        ...data,
        [buildingOrGroupField]: newBuildings.map(b => b.id),
      })
    }

    setBuildings(filter(buildings, item => item.id !== id && item.value !== id))
  }

  const removeAudienceItem = id => {
    let newAudience = filter(data.audiences, item => item !== id)

    const buildingOrGroupField = getBuildingOrGroupField(isGroupsPost)

    const buildingOrGroupOption = getBuildingOrGroupOption(isGroupsPost)

    let newOptions = data[buildingOrGroupOption]
    const allBuildingsOption = getAllBuildingsOption(isGroupsPost)

    const requestBuildingOption = getRequestBuildingOption(isGroupsPost)

    let allBuildings = newOptions.includes(allBuildingsOption)
    let requestBuilding = newOptions.includes(requestBuildingOption)

    if (id === TO_ALL_BUILDINGS) {
      allBuildings = false
    }

    if (id === REQUEST_BUILDING) {
      requestBuilding = false
    }

    if (!allBuildings && !requestBuilding && !buildings.length) {
      newAudience = difference(newAudience, BUILDINGS_SCOPE)
    }

    let buildingRelation = false

    newAudience.forEach(item => {
      if (BUILDINGS_SCOPE.includes(item)) {
        buildingRelation = true
      }
    })

    let newBuildings = data[buildingOrGroupField]

    if (!newAudience.length || !buildingRelation) {
      allBuildings = false
      requestBuilding = false
      setBuildings([])
      newBuildings = []
    }

    if (!allBuildings) {
      newOptions = newOptions.filter(o => o !== allBuildingsOption)
    }

    if (!requestBuilding) {
      newOptions = newOptions.filter(o => o !== requestBuildingOption)
    }

    props.onUpdate({
      ...data,
      audiences: newAudience,
      [buildingOrGroupOption]: newOptions,
      [buildingOrGroupField]: newBuildings,
    })
  }

  const cardClass = classnames(
    'request-constructor__card--body request-constructor__card--email'
  )

  const allBuildings =
    Array.isArray(data[getBuildingOrGroupOption(isGroupsPost)]) &&
    data[getBuildingOrGroupOption(isGroupsPost)].includes(
      getAllBuildingsOption(isGroupsPost)
    )

  const requestBuilding =
    Array.isArray(data[getBuildingOrGroupOption(isGroupsPost)]) &&
    data[getBuildingOrGroupOption(isGroupsPost)].includes(
      getRequestBuildingOption(isGroupsPost)
    )

  const newAudience = getAudience(
    data.audiences,
    buildings,
    { group: 'admin' },
    allBuildings,
    isGroupsPost,
    requestBuilding
  )

  const templateParamsField = isGroupsPost
    ? 'for_building_group'
    : 'for_building'

  const handlePasteVariable = option => {
    let variable = option

    if (option.label) {
      variable = `{{${option.label}}}`
    }

    props.onUpdate({ ...data, text: data.text + variable })
  }
  const handleSelectTemplate = template => {
    const { text, post_title, files: templateFiles } = template
    const newFilesArr = union(files, templateFiles)
    setFiles(newFilesArr)
    props.onUpdate({
      ...data,
      file_ids: newFilesArr.map(f => f.id),
      text: data.text + text,
      title: data.title ? data.title + ' ' + post_title : post_title,
    })
  }

  const getFilesOptions = () => [
    { value: 'activity_files', label: t('Automate:FromActivity') },
    {
      value: 'request_files',
      label: t('Automate:FromServiceRequestProfile'),
    },
  ]

  const changeFilesOptions = option => {
    let newOptions = []

    if (data.files_options.includes(option)) {
      newOptions = data.files_options.filter(o => o !== option)
    } else {
      newOptions = data.files_options.concat(option)
    }

    props.onUpdate({
      ...data,
      files_options: newOptions,
    })
  }

  return (
    <div className={cardClass}>
      <ModalRow>
        <Checkbox
          style={{ marginRight: '8px' }}
          checked={data.comments_forbidden}
          onChange={handleChangeComment}
        />
        <span className={styles.toggleText}>{t('DenyCommentPost')}</span>
      </ModalRow>
      <ModalRow>{t('PostType')}</ModalRow>
      <NewSelectSimple
        name='post-type'
        value={postTypeOption}
        options={postTypeOptions}
        style={{ marginBottom: '10px' }}
        onChange={confirmChangeType}
      />
      <ModalRow>{t('PostIntervalTitle')}</ModalRow>
      <ModalRow>
        <InputField
          className={styles.input}
          name={'period_template'}
          value={periodTemplate}
          placeholder='4d 6h 45m'
          onChange={handleChangePeriod}
        />
      </ModalRow>
      <PostAudience
        automate
        editing
        allBuildings={allBuildings}
        audience={data.audiences || []}
        buildings={buildings}
        newAudience={newAudience}
        updateAudience={handleUpdateAudience}
        removeItem={handleRemoveAudienceItem}
        postType={postTypeOption.value}
        requestBuilding={requestBuilding}
        isGroupsPost={isGroupsPost}
      />
      <section
        style={{
          marginTop: '10px',
          display: 'flex',
          flexDirection: 'column',
          gap: postTypeOption.value === TICKER_TYPE ? '10px' : '0',
        }}
      >
        {postTypeOption.value !== TICKER_TYPE ? (
          <div className='reply-headers__input reply-headers__input--subject'>
            <label className={styles.label}>{`${t(
              'PostTitlePlaceholder'
            )}:`}</label>
            <input
              className='textarea-2__input'
              type='text'
              style={{ fontSize: '15px' }}
              value={data.title}
              onChange={e => props.onUpdate({ ...data, title: e.target.value })}
            />
          </div>
        ) : (
          <PostTitle
            automate
            title={data.title}
            setTitle={title => props.onUpdate({ ...data, title })}
          />
        )}
        {postTypeOption.value !== TICKER_TYPE && (
          <>
            <div className='reply-headers__input reply-headers__input--subject'>
              <label>{t('Templates:Templates')}:</label>
              <NewTemplateDropdown
                isPostTemplate
                handleSelect={handleSelectTemplate}
                permanentParams={{
                  [templateParamsField]:
                    (buildings.length &&
                      buildings.map(g => g.id || g.value).join(',')) ||
                    undefined,
                }}
              />
            </div>
            <div className={styles.variables}>
              <span>{t('Common:TextTemplatesVariables')}</span>
              <TemplateVariables
                t={t}
                ns='Templates'
                api={getSnippetVariables}
                tags={['request_comment', 'automation']}
                name_exclude={'recipient'}
                handleSelect={handlePasteVariable}
              />
            </div>
          </>
        )}
        <PostDescription
          automate
          description={data.text}
          setDescription={text => props.onUpdate({ ...data, text })}
          setPostData={(title, text, files) => {
            props.onUpdate({
              ...data,
              title,
              text,
              file_ids: files.map(f => f.id),
            })
            setFiles(files)
          }}
          templateParams={{
            [templateParamsField]:
              (buildings.length &&
                buildings.map(g => g.id || g.value).join(',')) ||
              undefined,
          }}
          postType={postTypeOption.value}
          files={files}
          title={data.title}
        />
      </section>
      {postTypeOption.value !== TICKER_TYPE && (
        <>
          <section>
            <RequestFileList
              showAddButton={true}
              files={files}
              loading={filesUploading}
              onUpload={handleUpdateFiles}
              onStartUpload={handleStartFilesUpload}
              onRemove={handleRemoveFile}
            />
          </section>
          <section>
            <div className={styles.attachOptionsLabel}>
              {t('Automate:AddFiles')}:
            </div>
            <NewSelectSimple
              isMulti
              options={getFilesOptions()}
              placeholder={t('Automate:NoAttach')}
              selectedItems={data.files_options}
              getSelectedLabel={option =>
                getFilesOptions().find(o => o.value === option)?.label
              }
              setSelectedItems={option => changeFilesOptions(option.value)}
              onChange={() => {}}
            />
          </section>
        </>
      )}
      <Modal
        contentLabel=''
        className='Modal__Bootstrap modal-dialog'
        isOpen={!!modal}
        onRequestClose={hideModal}
      >
        {modal}
      </Modal>
    </div>
  )
}

export default CreatePost
