// @flow

import React from 'react'
import classnames from 'classnames'
import type { Node } from 'react'

import styles from './Row.module.scss'

type Props = {
  children: Node,
  error?: string,
  required?: boolean,
  title: string,
  top?: boolean,
}

const Row = (props: Props): Node => {
  const { error, title, required, children, top } = props

  const fieldClass = classnames(styles.field, {
    [styles.error]: error,
  })

  const titleClass = classnames(styles.title, { [styles.top]: top })

  return (
    <div className={styles.line}>
      <div className={titleClass}>
        {title}
        {required && (
          <span className={styles.required} aria-required='true'>
            &nbsp;*
          </span>
        )}
      </div>
      <div className={fieldClass}>{children}</div>
    </div>
  )
}

export default Row
