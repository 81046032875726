// @flow

export const ORDER_STATUSES = [
  {
    translationKey: 'New',
    value: 'NEW',
    color: '#e44440',
  },
  {
    translationKey: 'InProgress',
    value: 'IN_PROGRESS',
    color: '#d3b801',
  },
  {
    translationKey: 'Done',
    value: 'DONE',
    color: '#9ec221',
  },
  {
    translationKey: 'Canceled',
    value: 'CANCELED',
    color: '#e44440',
  },
]
