// @flow

import { postApi, getApi, patchApi, deleteApi } from './api.core'

export const getImports = () => getApi('api/import/')
export const getStatuses = () => getApi('api/import/statuses/')
export const getImportData = importId =>
  getApi(`api/import/${importId}/?get_data=true&get_fields_list=true`)
export const getImport = importId => getApi(`api/import/${importId}/`)
export const getLast = () => getApi('api/import/last/')
export const getByPage = params => getApi(`api/import/`, params)
export const setImport = file => postApi('api/import/', { file })
export const patchImport = (importId, credentinals) =>
  patchApi(`api/import/${importId}/`, credentinals)
export const startImport = importId => postApi(`api/import/${importId}/start/`)
export const cancelImport = importId =>
  postApi(`api/import/${importId}/rollback/`)
export const deleteImport = importId => deleteApi(`api/import/${importId}/`)
