// @flow

import React, { useEffect, useState } from 'react'
import axios from 'axios'
import type { Node } from 'react'

type Props = { alt?: string, className?: string, url: string }

const InlineSvg = (props: Props): Node => {
  const { url, alt, className } = props

  useEffect(() => {
    axios(url).then(response => setSvg(response.data))
  }, [url])

  const [svg, setSvg] = useState(null)

  if (!svg) {
    return null
  }

  return (
    <div
      title={alt}
      dangerouslySetInnerHTML={{ __html: svg }}
      className={className}
    />
  )
}

export default InlineSvg
