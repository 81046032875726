// @flow

import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { push } from 'connected-react-router'
import { compose } from 'redux'
import moment from 'moment'
import { filter, difference } from 'lodash-es'
import type { Location } from 'react-router-dom'

import { getDwellerPermissions } from '../../../core/api/api.permission'
import Button from '../../Button'
import DateTime from '../../DateTime'
import { EntityIcon } from '../../Icon'
import Block, { Cell, CellsBar, Header, Row } from '../../Block'
import PostTimeInterval from '../PostTimeInterval'
import PostInfoInterval from './PostInfoInterval'
import PostInfoType from './PostInfoType'
import {
  getAudience,
  getTimeOptions,
  getTime,
  isAllSelected,
} from '../Post.utils'
import PostAudience from '../PostAudience'
import {
  BUILDINGS_SCOPE,
  BUILDING_TYPE,
  TO_BUILDING,
  SIGNUP_AUDIENCE,
  TO_ALL_BUILDINGS,
} from '../Post.constants'
import { withInfoState } from '../Post.hocs'
import { USER_GROUPS } from '../../../constants'
import { getUser } from '../../../utils/commonSelectors'
import { isExpired, isUpcoming } from './PostInfo.utils'
import Label from '../../Label'
import type { StateInfoProps } from '../Post.hocs'
import Checkbox from '../../Checkbox/Checkbox'
import { isStaffUser } from '../../../utils/utils'
import LinkedRequests from '../../Mail/MailThreadInfo/LinkedRequests'

import styles from './PostInfo.module.scss'

type Props = {
  dispatch: Object => void,
  languageCode: Object,
  location: Location,
  postData: Object,
  push: string => void,
  t: string => string,
}

const PostInfo = (props: Props & StateInfoProps) => {
  const {
    postData: {
      spam,
      comments_forbidden,
      title,
      created,
      views_counter: viewsCounter,
      new_audience: newAudience,
      building_objs,
      building_group_objs,
      date_from: initialDateFrom,
      date_to: initialDateTo,
      post_type: postType,
      owner_obj: owner,
      permissions: {
        can_edit_date_period: canEditDatePeriod,
        can_view_date_period: canViewDatePeriod,
        can_edit_audience: canEditAudience,
      },
      request,
    },
    isDateFromOpen,
    isDateToOpen,
    audience,
    user,
    user: { group },
  } = props

  const isStaff = isStaffUser(user)

  const initialBuildings =
    building_group_objs?.length > 0 ? building_group_objs : building_objs

  const [buildings, setBuildings] = useState(initialBuildings)
  const [isGroupsPost, setGroupsPost] = useState(
    building_group_objs?.length > 0
  )
  const [comment, setComment] = useState(comments_forbidden)
  const [allBuildings, setAllBuildings] = useState(
    !building_group_objs?.length && isAllSelected(initialBuildings, audience)
  )

  const [intervalEditing, setIntervalEditing] = useState(false)
  const [audienceEditing, setAudienceEditing] = useState(false)
  const [canDwelleCommentPosts, setDwellerCommentPosts] = useState(false)

  useEffect(() => {
    if (isStaff) {
      getDwellerPermissions().then(data =>
        setDwellerCommentPosts(data.can_dweller_comment_post)
      )
    }
  }, [])

  const openPostList = () => {
    const key = props.location.key

    if (!key) {
      return () => props.push('/')
    }

    return null
  }

  const onClickOut = () => {
    props.openDateFrom(false)
    props.openDateTo(false)
  }

  const updateDateFrom = date => {
    props.setDateFrom(date)
  }

  const updateDateTo = date => {
    props.setDateTo(date)
  }

  const handleOpenDateFrom = () => {
    props.openDateFrom(!isDateFromOpen)
  }

  const handleOpenDateTo = () => {
    props.openDateTo(!isDateToOpen)
  }

  const onEditInterval = () => {
    setIntervalEditing(true)
  }

  const onEditAudience = () => {
    setAudienceEditing(true)
  }

  const clearTimeInterval = () => {
    props.setDateFrom(null)
    props.setDateTo(null)
    props.setTimeFrom(getTime())
    props.setTimeTo(getTime())
  }

  const cancel = () => {
    setAudienceEditing(false)
    setIntervalEditing(false)
    setBuildings(initialBuildings)
    props.setAudience(newAudience)
    setAllBuildings(
      !building_group_objs?.length && isAllSelected(initialBuildings, audience)
    )
    props.setDateTo(initialDateTo)
    props.setDateFrom(initialDateFrom)
    setComment(comments_forbidden)
  }

  const save = () => {
    setAudienceEditing(false)
    setIntervalEditing(false)

    const formattedDateFrom = props.dateFrom
      ? moment(props.dateFrom)
          .set('hour', props.timeFrom.value)
          .set('minute', 0)
          .set('second', 0)
      : null

    const formattedDateTo = props.dateTo
      ? moment(props.dateTo)
          .set('hour', props.timeTo.value)
          .set('minute', 0)
          .set('second', 0)
      : null

    props.onSave({
      audience: canEditAudience ? audience : undefined,
      buildings: canEditAudience
        ? isGroupsPost
          ? building_objs.length > 0
            ? []
            : undefined
          : buildings
        : undefined,
      building_groups:
        canEditAudience && user.group !== USER_GROUPS.dweller
          ? isGroupsPost
            ? buildings
            : building_group_objs.length > 0
            ? []
            : undefined
          : undefined,
      comment,
      dateFrom:
        canEditDatePeriod && user.group !== USER_GROUPS.dweller
          ? formattedDateFrom
          : undefined,
      dateTo:
        canEditDatePeriod && user.group !== USER_GROUPS.dweller
          ? formattedDateTo
          : undefined,
    })
  }

  const updateAudience = (audience, buildings, allBuildings, isGroupsPost) => {
    props.setAudience(audience)
    setBuildings(buildings)
    setAllBuildings(allBuildings)
    setGroupsPost(isGroupsPost)
  }

  const removeAudienceItem = id => {
    let newAudience = filter(audience, item => item !== id)

    if (user.group === USER_GROUPS.dweller && id === TO_BUILDING) {
      newAudience = difference(newAudience, SIGNUP_AUDIENCE)
    }

    if (user.group !== USER_GROUPS.dweller && id === TO_ALL_BUILDINGS) {
      newAudience = difference(newAudience, BUILDINGS_SCOPE)
      setAllBuildings(false)
      setBuildings([])
    }

    let buildingRelation = false

    newAudience.forEach(item => {
      if (BUILDINGS_SCOPE.includes(item)) {
        buildingRelation = true
      }
    })

    if (!newAudience.length || !buildingRelation) {
      setAllBuildings(false)
      setBuildings([])
    }

    if (id === TO_BUILDING) {
      newAudience = difference(newAudience, BUILDINGS_SCOPE)
      setAllBuildings(false)
      setBuildings([])
    }

    props.setAudience(newAudience)
  }

  const removeBuildingItem = id => {
    const newBuildings = filter(buildings, item => item.id !== id)

    if (!newBuildings.length) {
      const newAudience = filter(
        audience,
        item => !BUILDINGS_SCOPE.includes(item)
      )

      if (newAudience.length !== audience.length) {
        props.setAudience(newAudience)
      }
    }

    setBuildings(filter(buildings, item => item.id !== id))
  }

  const removeItem = (id, type) => {
    const removeHandler =
      type === BUILDING_TYPE ? removeBuildingItem : removeAudienceItem

    removeHandler(id)
  }

  const formattedAudience = getAudience(
    user.group !== USER_GROUPS.dweller || user.owner === owner.id
      ? audience
      : [],
    buildings,
    user,
    allBuildings,
    isGroupsPost
  )

  const handelChangeComment = e => setComment(e.currentTarget.checked)

  return (
    <Block notCollapse>
      <Header header goBack={openPostList()}>
        <EntityIcon icon large id='post' />
        <span className='bar__title-text'>{title}</span>
        {spam && (
          <Label
            text={props.t('Blocked')}
            style={{ marginLeft: '8px' }}
            type='outlined'
          />
        )}
      </Header>
      <CellsBar>
        <Cell title={props.t('CreatedDate')}>
          <DateTime dateTime={created} />
        </Cell>
        <Cell title={props.t('Views')}>{viewsCounter}</Cell>
        {isStaff && canDwelleCommentPosts && (
          <Cell title={props.t('Comments')}>
            <span className={styles.toggleText}>
              {props.t('DenyCommentPost')}
            </span>
            <Checkbox
              style={{ marginLeft: '16px' }}
              checked={comment}
              onChange={handelChangeComment}
            />
          </Cell>
        )}
      </CellsBar>
      {group !== USER_GROUPS.dweller && <PostInfoType postType={postType} />}
      {canViewDatePeriod && (
        <Row
          title={props.t('PostIntervalTitle')}
          onEdit={canEditDatePeriod ? onEditInterval : null}
        >
          {intervalEditing ? (
            <PostTimeInterval
              setTimeFrom={props.setTimeFrom}
              handleOpenDateFrom={handleOpenDateFrom}
              isDateToOpen={isDateToOpen}
              updateDateFrom={updateDateFrom}
              updateDateTo={updateDateTo}
              timeFrom={props.timeFrom}
              timeTo={props.timeTo}
              handleOpenDateTo={handleOpenDateTo}
              setTimeTo={props.setTimeTo}
              timeOptions={getTimeOptions()}
              dateFrom={props.dateFrom}
              dateTo={props.dateTo}
              isDateFromOpen={isDateFromOpen}
              onClickOut={onClickOut}
              onClear={clearTimeInterval}
            />
          ) : (
            <PostInfoInterval
              dateFrom={
                initialDateFrom
                  ? moment(initialDateFrom)
                      .set('hour', props.timeFrom.value)
                      .set('minute', 0)
                      .set('second', 0)
                  : null
              }
              dateTo={
                initialDateTo
                  ? moment(initialDateTo)
                      .set('hour', props.timeTo.value)
                      .set('minute', 0)
                      .set('second', 0)
                  : null
              }
              isExpired={isExpired(initialDateTo)}
              isUpcoming={isUpcoming(initialDateFrom)}
            />
          )}
        </Row>
      )}

      {formattedAudience && (
        <PostAudience
          allBuildings={allBuildings}
          audience={audience}
          buildings={buildings}
          isGroupsPost={isGroupsPost}
          newAudience={formattedAudience}
          updateAudience={updateAudience}
          removeItem={removeItem}
          editing={audienceEditing}
          postType={postType}
          onEdit={canEditAudience ? onEditAudience : null}
        />
      )}
      {isStaff && request?.title && (
        <Row title={props.t('LinkedRequests')}>
          <LinkedRequests requests={[request]} />
        </Row>
      )}
      {(audienceEditing ||
        intervalEditing ||
        comments_forbidden !== comment) && (
        <div className={styles.actions}>
          <Button.Save
            disabled={!formattedAudience.length}
            type='button'
            onClick={save}
          >
            {props.t('Common:Save')}
          </Button.Save>
          <Button.Cancel type='button' onClick={cancel}>
            {props.t('Common:Cancel')}
          </Button.Cancel>
        </div>
      )}
    </Block>
  )
}

const mapStateToProps = state => ({
  user: getUser(state),
})

const mapDispatchToProps = {
  push,
}

export default compose(
  withTranslation('Post'),
  connect(mapStateToProps, mapDispatchToProps),
  withInfoState
)(PostInfo)
