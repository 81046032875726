// @flow

import React, { memo, useRef, useState } from 'react'

import Checklists from './Checklists'
import DueDateLabel from './DueDateLabel'
import { useComponentSize } from '../../../../../../../hooks'
import { ITEM_MARGIN } from '../Info.constants'
import Emails from './Emails'
import FilesAttach from './FilesAttach'
import Invoices from './Invoices'
import RelatedRequests from './RelatedRequests'

import styles from './AdminInfo.module.scss'

type Props = {
  data: Object,
}

const AdminInfo = memo<Props>(props => {
  const { data } = props
  const {
    thread_emails_count,
    unread_emails_count,
    has_error_message,
    file_count,
    checklists,
    status,
    has_invoices,
    related_request_count,
    due_date: dueDate,
  } = data

  const [dueDateWidth, setDueDateWidth] = useState(0)

  const ref = useRef(null)
  const size = useComponentSize(ref)

  const firstLineWidth = dueDateWidth
    ? size.width - dueDateWidth - ITEM_MARGIN
    : size.width

  let count = 0

  if (dueDate) {
    count++
  }

  if (thread_emails_count) {
    count++
  }

  if (checklists && checklists.length) {
    count++
  }

  if (file_count) {
    count++
  }

  if (has_invoices) {
    count++
  }

  if (related_request_count) {
    count++
  }

  if (!count) {
    return null
  }

  return (
    <div
      className={styles['admin-info']}
      ref={ref}
      style={{ '--itemMargin': `${ITEM_MARGIN}px` }}
    >
      <DueDateLabel
        count={count}
        dueDate={dueDate}
        setWidthEffect={setDueDateWidth}
        status={status}
      />
      <Checklists
        count={count}
        firstLineWidth={firstLineWidth}
        items={checklists}
        secondLineWidth={size.width}
      />
      {!!thread_emails_count && (
        <Emails
          count={count}
          emailsCount={thread_emails_count}
          unread_emails_count={unread_emails_count}
          has_error_message={has_error_message}
        />
      )}
      {!!file_count && <FilesAttach filesCount={file_count} count={count} />}
      {has_invoices && <Invoices count={count} />}
      {!!related_request_count && (
        <RelatedRequests requestCount={related_request_count} count={count} />
      )}
    </div>
  )
})

export default AdminInfo
