// @flow

import React, { Fragment, useState, useEffect } from 'react'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { captureException } from '@sentry/browser'
import { Link, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import BottomPagination from '../../../components/Pagination/BottomPagination'
import ListViewToggleButton from '../../../components/ListViewToggleButton'
import TextClipped from '../../../components/TextClipped'
import Icon from '../../../components/Icon'
import { MARKETPLACE_ROUTES } from '../../../constants'
import Button from '../../../components/Button'
import EmptyList from '../../../components/EmptyList'
import Image from '../../../components/ImageSquareFit'
import TileList from '../../../components/TileList'
import Loader from '../../../components/Loader'
import Modal from '../../../components/Modal'
import environment from '../../../createRelayEnvironment'
import FavoriteProvidersMutation from './FavoriteProviders.mutation'
import Checkbox from '../../../components/Checkbox'
import AllProvidersTable from '../AllProviders/AllProvidersTable'
import ConfirmationPopup from '../../../components/modals/ConfirmationPopup'
import { getUser } from '../../../utils/commonSelectors'
import { isStaffUser, isAdminUser } from '../../../utils/utils'
import BuildingMappingModal from '../AllProviders/BuildingMappingModal'
import CategoryMappingModal from '../AllProviders/CategoryMappingModal'

import styles from '../AllProviders/AllProvidersList.module.scss'
import commonStyles from '../AllProviders/common.module.scss'

const FavoriteProvidersList = props => {
  const [loading, setLoading] = useState(false)
  const [selectedProviders, setUnfavoriteProviders] = useState([])
  const [isModalShown, toggleModal] = useState(false)
  const [tileView, setTileView] = useState(false)
  const [buildingModal, setBuildingModal] = useState(null)
  const [categoryModal, setCategoryModal] = useState(null)
  const [singleId, setSingleId] = useState(null)

  const history = useHistory()

  const { t } = useTranslation('Providers')

  const user = useSelector(getUser)

  const isStaff = isStaffUser(user)

  const isAdmin = isAdminUser(user)

  useEffect(() => {
    const tileView = localStorage.getItem('providersTileView')

    if (tileView !== null) {
      setTileView(tileView === 'true')
    } else {
      localStorage.setItem('providersTileView', 'false')
    }
  }, [])

  if (loading) {
    return <Loader text={false} type='big' />
  }

  const toggleProvider = id => {
    return function () {
      if (selectedProviders.includes(id)) {
        setUnfavoriteProviders(
          selectedProviders.filter(contractorId => contractorId !== id)
        )
      } else {
        setUnfavoriteProviders([...selectedProviders, id])
      }
    }
  }

  const toggleView = () => {
    const nextTileView = !tileView

    localStorage.setItem('providersTileView', nextTileView.toString())

    setTileView(nextTileView)
  }

  const toggleAll = () => {
    if (selectedProviders.length !== props.providers.length) {
      setUnfavoriteProviders(
        props.providers.map(provider => provider.global_id)
      )
    } else {
      setUnfavoriteProviders([])
    }
  }

  const redirectToProviders = () => {
    history.push(MARKETPLACE_ROUTES['marketplaceAllProviders'])
  }

  const redirectToCreateProvider = () => {
    history.push(MARKETPLACE_ROUTES['marketplaceCreateProvider'])
  }

  const showModal = id => {
    if (id) {
      setSingleId(id)
    }

    toggleModal(true)
  }

  const hideModal = () => {
    if (singleId) {
      setSingleId(null)
    }

    toggleModal(false)
  }

  const removeProviders = () => {
    setLoading(true)

    const ids = singleId ? [singleId] : selectedProviders

    FavoriteProvidersMutation.commit(environment, { ids }, (_, errors) => {
      if (errors && errors.length > 0) {
        const errorData = JSON.stringify(errors)
        captureException(errorData)
      }

      hideModal()

      setUnfavoriteProviders([])

      setLoading(false)

      props.setLoading(true)
    })
  }

  const renderModal = function () {
    return (
      <Modal isOpen={isModalShown}>
        <ConfirmationPopup
          title={t('removeProviderHeader')}
          text={t('removeProviderText')}
          confirm={t('ok')}
          cancel={t('cancel')}
          onClose={hideModal}
          onOk={() => removeProviders()}
        />
      </Modal>
    )
  }

  const CreateProviderButton = isStaff ? (
    <div className='toolbox__cell'>
      <Button.Save
        icon='provider'
        type='button'
        onClick={redirectToCreateProvider}
      >
        {t('createProvider')}
      </Button.Save>
    </div>
  ) : null

  const renderControls = function () {
    return (
      <div className='toolbox'>
        {isAdmin && (
          <div className='toolbox__cell'>
            <Checkbox
              outer
              checked={selectedProviders.length === providers.length}
              onChange={toggleAll}
            />
          </div>
        )}
        {!selectedProviders.length && (
          <>
            {isAdmin && (
              <div className='toolbox__cell'>
                <Button.Save
                  type='button'
                  icon='provider'
                  style={{ marginRight: '0' }}
                  onClick={redirectToProviders}
                >
                  {t('addProvider')}
                </Button.Save>
              </div>
            )}
            {CreateProviderButton}
          </>
        )}
        {isAdmin && selectedProviders.length > 0 && (
          <div className='toolbox__cell'>
            <Button.Cancel
              type='button'
              icon='bin'
              style={{ marginRight: '0' }}
              onClick={() => showModal()}
            >
              {t('removeProvider')}
            </Button.Cancel>
          </div>
        )}
        {isStaff && (
          <div className='toolbox__cell toolbox__cell--right'>
            <ListViewToggleButton tileView={tileView} onClick={toggleView} />
          </div>
        )}
      </div>
    )
  }

  const renderProvider = provider => {
    const { global_id, avatar, name, offer_count, order_count } = provider

    const nameClass = classnames('provider__name', styles.name)
    const checked = selectedProviders.includes(global_id)
    const providerStyle = classnames(styles.providerTileBlock, {
      [styles.checked]: checked,
    })

    return (
      <div className={providerStyle} key={global_id}>
        <Link to={MARKETPLACE_ROUTES['marketplaceProvider'](global_id)}>
          <div style={{ backgroundColor: '#fff' }}>
            <Image src={avatar} fit='contain' />
          </div>
          <div className={styles.info}>
            {isAdmin && (
              <label
                className={classnames(
                  'checkbox checkbox--medium checkbox--default checkbox--notext ti-checkbox',
                  styles.check
                )}
                htmlFor={`provider-${global_id}`}
                onClick={e => e.stopPropagation()}
              >
                <input
                  type='checkbox'
                  id={`provider-${global_id}`}
                  value={global_id}
                  checked={selectedProviders.includes(global_id)}
                  onChange={toggleProvider(global_id)}
                />
                <span />
              </label>
            )}
            <TextClipped
              className={nameClass}
              fontSize={14}
              maxLines={2}
              title={name}
            >
              {name}
            </TextClipped>
          </div>
          <div className={styles.counters}>
            <div className={styles.counter}>
              <Icon id='order' className={styles.icon} />
              <span className='u-a20__stat'>{order_count}</span>
            </div>
            <div className={styles.divider} />
            <div className={styles.counter}>
              <Icon id='offer' className={styles.icon} />
              <span className='u-a20__stat'>{offer_count}</span>
            </div>
          </div>
        </Link>
      </div>
    )
  }

  const { providers, meta } = props

  const renderResults = function () {
    if (loading) {
      return <Loader text={false} type='big' />
    }

    const emptyListProps = Object.assign(
      {},
      isAdmin ? { onClick: redirectToProviders } : undefined
    )

    if (providers.length === 0) {
      return (
        <EmptyList
          canAdd
          btnText={t('addFavoriteProviders')}
          btnIcon='provider'
          icon='provider'
          title={t('noFavoriteProvidersFound')}
          additionalButton={CreateProviderButton}
          {...emptyListProps}
        />
      )
    } else {
      return (
        <Fragment>
          <div className={commonStyles.content}>
            {renderControls()}

            {tileView || !isStaff ? (
              <TileList rowLength={5}>{providers.map(renderProvider)}</TileList>
            ) : (
              <AllProvidersTable
                providers={providers}
                selectedItems={selectedProviders}
                loading={loading}
                toggleProvider={toggleProvider}
                removeProviders={showModal}
                setBuildingModal={setBuildingModal}
                setCategoryModal={setCategoryModal}
              />
            )}
            <BottomPagination classes='paginator-2' meta={meta} />
          </div>
        </Fragment>
      )
    }
  }

  const handleCloseBuildingModal = () => {
    setBuildingModal(null)
  }

  const handleCloseCategoryModal = () => {
    setCategoryModal(null)
  }

  return (
    <>
      {renderModal()}
      {renderResults()}
      {buildingModal && (
        <BuildingMappingModal
          buildingModal={buildingModal}
          setLoading={props.setLoading}
          onClose={handleCloseBuildingModal}
        />
      )}
      {categoryModal && (
        <CategoryMappingModal
          categoryModal={categoryModal}
          setLoading={props.setLoading}
          onClose={handleCloseCategoryModal}
        />
      )}
    </>
  )
}

export default FavoriteProvidersList
