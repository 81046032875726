// @flow

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isNumber } from 'lodash-es'
import type { Node } from 'react'

import { getTypes } from '../../core/api/api.request'
import Button from '../Button'
import NewSelectAsync from '../NewSelectAsync'
import { useUser } from '../../hooks/useUser'
import { getFetchedItems, isAdminUser } from '../../utils/utils'

import styles from './SelectRequestType.module.scss'

type Props = {
  canCreate: boolean,
  onChange: Object => void,
  outbound?: string,
  value: ?Object,
}

const SelectRequestType = (props: Props): Node => {
  const { value, outbound } = props

  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    if (isNumber(value)) {
      getTypes({ id: value, outbound })
        .then(data => {
          const types = getFetchedItems(data)

          if (types && types[0]) {
            props.onChange(types[0])
          } else {
            props.onChange(null)
          }
        })
        .finally(() => setLoading(false))
    } else {
      setLoading(false)
    }
  }, [])

  const { t } = useTranslation('Request')

  const user = useUser()

  const handleEdit = () => {
    window.open('/settings/fields', '_blank')
  }

  const renderEmpty = () => {
    return (
      <div className='request-empty-types'>
        <div className='request-empty-types--text'>{t('emptyTypes')}</div>
        {isAdminUser(user) && !outbound && (
          <Button.Save icon='pencil' type='button' onClick={handleEdit}>
            {t('Edit')}
          </Button.Save>
        )}
      </div>
    )
  }

  return (
    <NewSelectAsync
      disabled={isLoading}
      className={styles.select}
      permanentParams={{ outbound }}
      selectedItems={value ? [value] : []}
      pageSize={10}
      view='dropdown'
      api={getTypes}
      emptyMenu={renderEmpty()}
      searchKey='name'
      getLabel={option => option.name}
      getSelectedLabel={option => option.name}
      placeholder={t('Select:SelectRequestType')}
      menuClass={styles.menu}
      onClick={props.onChange}
    />
  )
}

export default SelectRequestType
