// @flow

import Utils from '../../../utils/utils'
import * as actions from './Igel.actionTypes'

const initialState = {
  initiated: false,
  working: false,
  config: {},
  status: {},
  exist: false,
  requests: {},
  requestsWereCopied: false,
  matchingRequests: false,
  copyingRequestTypes: false,
  errors: [],
  popupError: null
}

export default (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case actions.IGEL_INITIATE:
      return {
        ...initialState,
        working: true
      }
    case actions.IGEL_WAS_INITIATED:
      return {
        ...state,
        initiated: true,
        working: false,
        ...action.data,
        errors: action.expiredCredits
          ? [{
            text: 'expiredIgelCredits',
            translate: true,
            error: true
          }]
          : []
      }
    case actions.IGEL_CHECK_STATUS:
    case actions.IGEL_CREATE:
    case actions.IGEL_APPLY_CONFIG:
    case actions.IGEL_SYNCHRONIZE:
      return {
        ...state,
        working: true,
        errors: []
      }
    case actions.IGEL_STATUS_WAS_CHECKED:
      return {
        ...state,
        working: false,
        status: action.status
      }
    case actions.IGEL_WAS_CREATED:
      return {
        ...state,
        working: false,
        exist: true,
        ...action.data
      }
    case actions.IGEL_CONFIG_WAS_APPLIED:
      return {
        ...state,
        working: false,
        config: action.config
      }
    case actions.IGEL_MATCH_REQUESTS:
      return {
        ...state,
        matchingRequests: true,
        popupError: null
      }
    case actions.IGEL_REQUESTS_WERE_MATCHED:
      return {
        ...state,
        matchingRequests: false,
        requests: action.items
      }
    case actions.IGEL_COPY_REQUEST_TYPES:
      return {
        ...state,
        copyingRequestTypes: true,
        popupError: null
      }
    case actions.IGEL_REQUEST_TYPES_WERE_COPIED:
      return {
        ...state,
        copyingRequestTypes: false,
        requests: action.items,
        requestsWereCopied: true
      }
    case actions.IGEL_POPUP_ERROR:
      return {
        ...state,
        matchingRequests: false,
        copyingRequestTypes: false,
        popupError: Utils.getErrorText(action.response)
      }
    case actions.IGEL_RESET_POPUP_ERROR:
      return {
        ...state,
        popupError: null
      }
    case actions.IGEL_ERROR:
      return {
        ...state,
        working: false,
        errors: [
          ...state.errors,
          { text: Utils.getErrorText(action.response) }
        ]
      }
    default:
      return state
  }
}
