// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import styles from './PoweredBy.module.scss'

const PoweredBy = (): Node => {
  const { t } = useTranslation('Registration')

  return (
    <div className={styles['powered-by']}>
      <span>
        {t('PoweredBy')}
        <div style={{ backgroundImage: 'url("/static/img/idwell.svg")' }} />
      </span>
    </div>
  )
}

export default PoweredBy
