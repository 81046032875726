// @flow

import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import update from 'immutability-helper'
import type { Node } from 'react'

import MailSearch from '../../MailSearch/MailSearch'
import NewSelectSimple from '../../NewSelectSimple'
import EditManagersListModal from './EditManagersListModal'
import PrinterModal from './PrinterModal'
import { useUser } from '../../../hooks/useUser'
import {
  getUserId,
  addPrinterEmailToRecipient,
  markRecipientBlacklisted,
  hasEmail,
} from '../../../utils/utils'
import Button from '../../Button'
import Icon from '../../Icon'
import Modal from '../../Modal'
import Warning from '../../Warning'
import ConfirmationPopup from '../../modals/ConfirmationPopup'
import FilterModal from './FilterModal'

import styles from '../MailReplyForm.module.scss'

const SPAM_USERS_COUNT = 25
const LIMIT_USERS_COUNT = 50

type Props = {
  account: Object,
  accounts: Array<Object>,
  blacklist: Array<string>,
  docsEnabled?: boolean,
  files?: Array<Object>,
  isBCCShown: boolean,
  isCCShown: boolean,
  isMass: boolean,
  isRequestThread: boolean,
  isToHided: boolean,
  onAnalyzeDocs?: Object => void,
  onChangeAccount: Object => void,
  onRemoveDocs?: () => void,
  onShowBCC: () => void,
  onShowCC: () => void,
  onToggleMass?: boolean => void,
  onUpdateFiles?: (Array<Object>) => void,
  printers: Array<Object>,
  proceedExt?: boolean,
  updateValue: (string, Array<string>) => void,
  uuid: string,
  values: Object,
  withProviders: boolean,
  working: boolean,
}

const ReplyHeader = (props: Props): Node => {
  const {
    uuid,
    accounts,
    withProviders,
    isCCShown,
    isBCCShown,
    isToHided,
    values: { to, cc, bcc, title, printer },
    values,
    account,
    replyTo,
    isMass,
    printers,
    working,
    isDeny,
    blacklist,
    setValues,
    requestFlatId,
    requestBuildingId,
  } = props

  const { t } = useTranslation('Mail')
  const user = useUser()
  const [modal, setModal] = useState(null)
  const [editListModalOpen, setEditListModalOpen] = useState(false)
  const [printerModalOpen, setPrinterModalOpen] = useState(false)
  const [filterModalOpen, setFilterModalOpen] = useState(false)
  const [modalEntity, setModalEntity] = useState(null)

  const userId = getUserId(user)

  let hasCorporate = false

  const options = accounts.map(account => {
    if (!hasCorporate && account.corporate) {
      hasCorporate = true
    }

    return {
      label: account.email_from_name || account.email_from,
      value: account.id,
      ...account,
    }
  })

  const getAvatar = account => {
    if (!hasCorporate) {
      return null
    }

    if (!account || !account.corporate) {
      return <div className={styles.empty} />
    }

    return <Icon id='corporate' className={styles.icon} />
  }

  useEffect(() => {
    if (printer) {
      handleChangeRecipients('to', to)
      handleChangeRecipients('cc', cc)
      handleChangeRecipients('bcc', bcc)
    }
  }, [printer])

  useEffect(() => {
    if (blacklist.length > 0) {
      handleChangeRecipients('to', to)
      handleChangeRecipients('cc', cc)
      handleChangeRecipients('bcc', bcc)
    }
  }, [blacklist])

  useEffect(() => {
    if (
      printer &&
      [...to, ...cc, ...bcc].filter(u => u.profile?.printer).length === 0
    ) {
      props.updateValue('printer', null)
    }
  }, [to, cc, bcc])

  const handleUpdateTitle = ({ target: { value } }) =>
    props.updateValue('title', value)

  const handleChangeField = (
    current,
    onChange,
    option,
    confirmChange = false
  ) => {
    if (current && option && option.value !== current.value) {
      if (
        confirmChange &&
        !option.is_info &&
        [...to, ...cc, ...bcc].length > SPAM_USERS_COUNT
      ) {
        setModal(
          <ConfirmationPopup
            title={t('SelectPersonalMailTitle')}
            text={t('SelectPersonalMailText')}
            confirm={t('YesButton')}
            cancel={t('CancelButton')}
            onClose={hideModal}
            onOk={() => {
              props.setBlur(false)
              onChange(option)
              hideModal()
            }}
          />
        )
      } else {
        props.setBlur(false)
        onChange(option)
      }
    }
  }

  const handleChangeRecipients = (field, value) => {
    if (printer) {
      value = value.map(u =>
        !hasEmail(u) || u.profile?.printer
          ? addPrinterEmailToRecipient(u, printer.email)
          : u
      )
    }

    if (blacklist.length > 0) {
      value = value.map(u => markRecipientBlacklisted(u, blacklist))
    }

    if (
      !isMass &&
      props.values[field].length < value.length &&
      [...to, ...cc, ...bcc].length >= LIMIT_USERS_COUNT
    ) {
      setModal(
        <ConfirmationPopup
          title={t('LimitTitle')}
          text={t('LimitText')}
          cancel={t('OkButton')}
          showOk={false}
          onClose={hideModal}
        />
      )
    } else if (
      isMass &&
      !account?.is_info &&
      props.values[field].length < value.length &&
      [...to, ...cc, ...bcc].length > SPAM_USERS_COUNT
    ) {
      setModal(
        <ConfirmationPopup
          title={t('SelectPersonalMailTitle')}
          text={t('SelectPersonalMailText')}
          confirm={t('YesButton')}
          cancel={t('CancelButton')}
          onClose={hideModal}
          onOk={() => {
            props.updateValue(field, value)
            hideModal()
          }}
        />
      )
    } else {
      props.updateValue(field, value)
    }
  }

  const endDragBlock = recipient => {
    if (recipient.toGroup) {
      moveBlock(recipient)
    }
  }

  const moveBlock = ({ name, group, toGroup }) => {
    if (values[toGroup].some(user => user.name === name)) {
      return
    }

    let recipient = values[group].reduce(
      (profile, curr, currentIndex) => {
        if (curr.name === name) {
          profile = {
            index: currentIndex,
            data: curr,
          }
        }

        return profile
      },
      { index: null, data: null }
    )

    const newValues = update(values, {
      [toGroup]: {
        $push: [recipient.data],
      },
      [group]: {
        $splice: [[recipient.index, 1]],
      },
    })

    setValues(newValues)
  }

  const showEditManagersListModal = () => {
    setEditListModalOpen(true)
  }

  const hideEditManagersListModal = () => {
    setEditListModalOpen(false)
  }

  const hideModal = () => {
    setModal(null)
  }

  const onSaveManagersList = values => {
    handleChangeRecipients('to', values)
    hideEditManagersListModal()
  }

  const removeNoEmailRecipients = () => {
    handleChangeRecipients(
      'to',
      to.filter(u => hasEmail(u) && !u.blacklist)
    )
    handleChangeRecipients(
      'cc',
      cc.filter(u => hasEmail(u) && !u.blacklist)
    )
    handleChangeRecipients(
      'bcc',
      bcc.filter(u => hasEmail(u) && !u.blacklist)
    )
  }

  const handleSelectPrinter = () => {
    if (printers.length === 1) {
      props.updateValue('printer', printers[0])
    } else {
      setPrinterModalOpen(true)
    }
  }

  const noEmailUsers = users => users.some(u => !hasEmail(u))

  const noEmailRecipients = noEmailUsers([...to, ...cc, ...bcc])
  const isSpamWarning =
    isMass &&
    !working &&
    !account?.is_info &&
    [...to, ...cc, ...bcc].length > SPAM_USERS_COUNT
  const isLabelsShown = noEmailRecipients || isSpamWarning

  const handleShowFilterModalTo = () => {
    setFilterModalOpen(true)
    setModalEntity('to')
  }

  const handleShowFilterModalCC = () => {
    setFilterModalOpen(true)
    setModalEntity('cc')
  }

  const handleShowFilterModalBCC = () => {
    setFilterModalOpen(true)
    setModalEntity('bcc')
  }

  const handleHideFilterModal = () => {
    setFilterModalOpen(false)
    setModalEntity(null)
  }

  return (
    <>
      <div className='reply-headers'>
        {!isToHided && (
          <div className='reply-headers__input'>
            <label className={styles.label}>{`${t('MessageFrom')}:`}</label>
            <div
              style={{
                paddingRight: '8px',
                maxWidth: 'calc(100% - 172px)',
                zIndex: 4,
              }}
            >
              {accounts && !!accounts.length && (
                <NewSelectSimple
                  buttonClass={!account && styles.placeholder}
                  className={styles.select}
                  openClass={styles.open}
                  options={options}
                  getAvatar={getAvatar}
                  clearable={false}
                  value={account}
                  onChange={option => {
                    handleChangeField(
                      account,
                      props.onChangeAccount,
                      option,
                      isMass
                    )
                  }}
                />
              )}
            </div>
          </div>
        )}
        <DndProvider backend={HTML5Backend}>
          {!isToHided && (
            <div className='reply-headers__input'>
              <label className={styles.label}>
                {`${t('MessageTo')}:`}
                {isMass && (
                  <Button.Search
                    className='reply-headers__input--button'
                    onClick={showEditManagersListModal}
                  />
                )}
              </label>
              <div
                className='reply-headers__search'
                style={{ maxWidth: 'calc(100% - 166px)' }}
              >
                <MailSearch
                  withProviders={withProviders}
                  id={`${uuid}to`}
                  uuid={uuid}
                  className='resend-popup-choose-usesdr'
                  currentUserId={userId}
                  params={{ group: ['admin', 'manager'] }}
                  style={{ flex: 4 }}
                  managers={to}
                  group='to'
                  onChange={value => handleChangeRecipients('to', value)}
                  onEndDragBlock={endDragBlock}
                />
              </div>
              {uuid === 'create' && (
                <Button.Filter
                  className={styles.filterButton}
                  onClick={handleShowFilterModalTo}
                />
              )}
              {(!isCCShown || !isBCCShown) && (
                <div
                  className='reply-headers__edit'
                  style={{ maxWidth: '62px', paddingRight: '16px' }}
                >
                  {!isCCShown && <div onClick={props.onShowCC}>CC</div>}
                  {!isBCCShown && <div onClick={props.onShowBCC}>BCC</div>}
                </div>
              )}
            </div>
          )}
          {isCCShown && (
            <div className='reply-headers__input'>
              <label className={styles.label}>{`${t('CC')}:`}</label>
              <div className='reply-headers__search'>
                <MailSearch
                  withProviders={withProviders}
                  id={`${uuid}cc`}
                  className='resend-popup-choose-usesdr'
                  currentUserId={userId}
                  params={{ group: ['admin', 'manager'] }}
                  managers={cc}
                  group='cc'
                  onChange={value => handleChangeRecipients('cc', value)}
                  onEndDragBlock={endDragBlock}
                />
              </div>
              {uuid === 'create' && (
                <Button.Filter
                  className={styles.filterButton}
                  onClick={handleShowFilterModalCC}
                />
              )}
            </div>
          )}
          {isCCShown && isMass && cc.length > 0 && (
            <div className='reply-headers__input reply-headers__input--warnings'>
              <Warning
                noArrow
                className='reply-headers__input--warning'
                text={t('CcBccWarning')}
              />
            </div>
          )}
          {isBCCShown && (
            <div className='reply-headers__input'>
              <label className={styles.label}>{`${t('BCC')}:`}</label>
              <div className='reply-headers__search'>
                <MailSearch
                  withProviders={withProviders}
                  id={`${uuid}bcc`}
                  managers={bcc}
                  className='resend-popup-choose-usesdr'
                  currentUserId={userId}
                  params={{ group: ['admin', 'manager'] }}
                  group='bcc'
                  onChange={value => handleChangeRecipients('bcc', value)}
                  onEndDragBlock={endDragBlock}
                />
              </div>
              {uuid === 'create' && (
                <Button.Filter
                  className={styles.filterButton}
                  onClick={handleShowFilterModalBCC}
                />
              )}
            </div>
          )}
          {isBCCShown && isMass && bcc.length > 0 && (
            <div className='reply-headers__input reply-headers__input--warnings'>
              <Warning
                noArrow
                className='reply-headers__input--warning'
                text={t('CcBccWarning')}
              />
            </div>
          )}
        </DndProvider>
        {isLabelsShown && account && replyTo && (
          <div className='reply-headers__input reply-headers__input--warnings'>
            {noEmailRecipients && (
              <>
                <Warning
                  noArrow
                  className='reply-headers__input--warning'
                  text={
                    printers.length > 0
                      ? t('NoMailUsersPrinter')
                      : t('NoMailUsers')
                  }
                />
                {printers.length > 0 && (
                  <div className='reply-headers__input--warning-buttons'>
                    <span
                      className='grey-link'
                      onClick={removeNoEmailRecipients}
                    >
                      {t('RemoveRecipients')}
                    </span>
                    <span className='red-link' onClick={handleSelectPrinter}>
                      {t('SendPrinter')}
                    </span>
                  </div>
                )}
              </>
            )}
            {isSpamWarning && (
              <Warning
                noArrow
                className='reply-headers__input--warning'
                text={t('SpamWarning')}
              />
            )}
          </div>
        )}
        {account?.is_info &&
          !isToHided &&
          accounts &&
          !!accounts.filter(a => !a.is_info).length && (
            <div className='reply-headers__input'>
              <label className={styles.label}>{`${t('ReplyTo')}:`}</label>
              <div
                style={{
                  paddingRight: '8px',
                  zIndex: 3,
                  maxWidth: 'calc(100% - 172px)',
                }}
              >
                <NewSelectSimple
                  buttonClass={!replyTo && styles.placeholder}
                  openClass={styles.open}
                  className={styles.select}
                  options={options.filter(o => !o.is_info)}
                  clearable={false}
                  value={replyTo}
                  onChange={option => {
                    handleChangeField(replyTo, props.onChangeReplyTo, option)
                  }}
                />
              </div>
            </div>
          )}
        <div className='reply-headers__input reply-headers__input--subject'>
          <label className={styles.label}>{`${t('Subject')}:`}</label>
          <input
            disabled={!accounts || !accounts.length || isDeny}
            className='textarea-2__input'
            type='text'
            style={{ fontSize: '15px' }}
            value={title}
            onChange={handleUpdateTitle}
          />
        </div>
      </div>
      <Modal
        contentLabel=''
        className='Modal__Bootstrap modal-dialog'
        isOpen={!!modal}
        onRequestClose={hideModal}
      >
        {modal}
      </Modal>
      {editListModalOpen && (
        <EditManagersListModal
          isOpen={editListModalOpen}
          managers={to}
          withProviders={withProviders}
          onClose={hideEditManagersListModal}
          onSave={onSaveManagersList}
        />
      )}
      {printerModalOpen && (
        <PrinterModal
          isOpen={printerModalOpen}
          printers={printers}
          onClose={() => setPrinterModalOpen(false)}
          onSelectPrinter={p => {
            props.updateValue('printer', p)
          }}
        />
      )}
      {filterModalOpen && (
        <FilterModal
          isOpen={filterModalOpen}
          values={values}
          setValues={setValues}
          modalEntity={modalEntity}
          requestBuildingId={requestBuildingId}
          requestFlatId={requestFlatId}
          onClose={handleHideFilterModal}
          onToggleMass={props.onToggleMass}
        />
      )}
    </>
  )
}

export default ReplyHeader
