// @flow

export const CHATROOM_INITIATE = '/Chatroom/CHATROOM_INITIATE'
export const CHATROOM_WAS_INITIATED = '/Chatroom/CHATROOM_WAS_INITIATED'
export const GET_NEW_MESSAGES_INITIATING =
  '/Chatroom/GET_NEW_MESSAGES_INITIATING'
export const GET_NEW_MESSAGES_INITIATED = '/Chatroom/GET_NEW_MESSAGES_INITIATED'
export const GET_NEW_MESSAGES_ERROR = '/Chatroom/GET_NEW_MESSAGES_ERROR'
export const GET_OLD_MESSAGES_INITIATING =
  '/Chatroom/GET_OLD_MESSAGES_INITIATING'
export const GET_OLD_MESSAGES_INITIATED = '/Chatroom/GET_OLD_MESSAGES_INITIATED'
export const GET_OLD_MESSAGES_ERROR = '/Chatroom/GET_OLD_MESSAGES_ERROR'
export const CHATROOM_UPDATE_COUNTERS = '/Chatroom/CHATROOM_UPDATE_COUNTERS'
export const CHATROOM_CREATE = '/Chatroom/CHATROOM_CREATE'
export const CHATROOM_WAS_CREATED = '/Chatroom/CHATROOM_WAS_CREATED'
export const ADD_MEMBERS_INITIATING = '/Chatroom/ADD_MEMBERS_INITIATING'
export const ADD_MEMBERS_INITIATED = '/Chatroom/ADD_MEMBERS_INITIATED'
export const ADD_MEMBERS_ERROR = '/Chatroom/ADD_MEMBERS_ERROR'
export const REMOVE_MEMBERS_INITIATING = '/Chatroom/REMOVE_MEMBERS_INITIATING'
export const REMOVE_MEMBERS_INITIATED = '/Chatroom/REMOVE_MEMBERS_INITIATED'
export const REMOVE_MEMBERS_ERROR = '/Chatroom/REMOVE_MEMBERS_ERROR'
export const SEND_MESSAGE_INITIATING = '/Chatroom/SEND_MESSAGE_INITIATING'
export const SEND_MESSAGE_INITIATED = '/Chatroom/SEND_MESSAGE_INITIATED'
export const SEND_MESSAGE_ERROR = '/Chatroom/SEND_MESSAGE_ERROR'
export const RENAME_CHATROOM_INITIATING = '/Chatroom/RENAME_CHATROOM_INITIATING'
export const RENAME_CHATROOM_INITIATED = '/Chatroom/RENAME_CHATROOM_INITIATED'
export const RENAME_CHATROOM_ERROR = '/Chatroom/RENAME_CHATROOM_ERROR'
export const LIKE_MESSAGE_INITIATING = '/Chatroom/LIKE_MESSAGE_INITIATING'
export const LIKE_MESSAGE_INITIATED = '/Chatroom/LIKE_MESSAGE_INITIATED'
export const LIKE_MESSAGE_ERROR = '/Chatroom/LIKE_MESSAGE_ERROR'
export const CHATROOM_RESET = 'CHATROOM_RESET'
export const TOGGLE_CHATROOM_MESSAGE = 'TOGGLE_CHATROOM_MESSAGE'
export const SHOW_MODAL_ADD_MEMBERS = '/Chatroom/SHOW_MODAL_ADD_MEMBERS'
export const SHOW_MODAL_LABELS = '/Chatroom/SHOW_MODAL_LABELS'
export const SHOW_MODAL_CONVERT_TO_REQUEST =
  '/Chatroom/SHOW_MODAL_CONVERT_TO_REQUEST'
export const HIDE_MODAL = '/Chatroom/HIDE_MODAL'
export const UPDATE_LABELS_INIT = '/Chatroom/UPDATE_LABELS_INIT'
export const UPDATE_LABELS_SUCCESS = '/Chatroom/UPDATE_LABELS_SUCCESS'
export const UPDATE_LABELS_ERROR = '/Chatroom/UPDATE_LABELS_ERROR'
export const TOGGLE_WIDGET = '/Chatroom/TOGGLE_WIDGET'
export const CHATROOM_DELETE_FILES = '/Chatroom/CHATROOM_DELETE_FILES'
export const CHATROOM_DELETE_MESSAGE = '/Chatroom/CHATROOM_DELETE_MESSAGE'
export const CHATROOM_MESSAGES_UPDATING = '/Chatroom/CHATROOM_MESSAGES_UPDATING'
export const CHATROOM_MESSAGES_UPDATED = '/Chatroom/CHATROOM_MESSAGES_UPDATED'
export const DELETE_MESSAGE = '/Chatroom/DELETE_MESSAGE'
export const UPDATE_MESSAGE = '/Chatroom/UPDATE_MESSAGE'
export const SHOW_MODAL = 'CHATROOM_SHOW_MODAL'
