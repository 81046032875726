// @flow

import {
  RECOVERY,
  RECOVERY_ERROR,
  RECOVERY_SUCCESS,
  RECOVERY_RESET,
} from './Recovery.actionTypes'

type Action = { error?: string, type: string }
type State = {
  error: ?string,
  isSuccess: boolean,
  isWorking: boolean,
  status: ?number,
}

export default (state: State, action: Action): State => {
  switch (action.type) {
    case RECOVERY: {
      return {
        ...state,
        isWorking: true,
      }
    }
    case RECOVERY_SUCCESS: {
      return {
        isWorking: false,
        isSuccess: true,
        error: null,
      }
    }
    case RECOVERY_ERROR: {
      return {
        isSuccess: false,
        isWorking: false,
        error: action.error,
        status: action.status,
      }
    }
    case RECOVERY_RESET:
      return { isWorking: false, error: null, isSuccess: false }
    default: {
      return state
    }
  }
}
