import React from 'react'
import { ErrorMessage, Form, Formik } from 'formik'
import Textarea from 'react-textarea-autosize'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import ButtonsBar from './ButtonsBar'
import ValidationText from '../../components/ValidationText'
import styles from './ProviderProfile.module.scss'

const TEXTAREA_ROWS_COUNT = 7

const EditDescriptionForm = props => {
  const { description, onCancel } = props
  const { t } = useTranslation('Providers')

  const renderError = text => <ValidationText active text={text} />

  const validate = ({ description }) => {
    const errors = {}

    if (!description || !description.trim()) {
      errors.description = t('emptyInput', { key: t('description') })
    }

    return errors
  }

  const handleSubmit = (values, { setSubmitting }) => {
    const callBack = () => {
      setSubmitting(false)
    }
    props.onSubmit(values, callBack, callBack)
  }

  const handleChange = (callBack, name) => e => callBack(name, e.target.value)
  const textAreaClassName = ({ errors, touched }) =>
    classnames(
      'textarea-2 textarea-2--large textarea-2--block textarea-2--default',
      styles.textarea,
      { 'textarea-2--error_value': errors.description && touched.description }
    )

  return (
    <Formik
      initialValues={{ description }}
      validate={validate}
      onSubmit={handleSubmit}
    >
      {formikProps => (
        <Form>
          <Textarea
            style={{ width: '100%' }}
            className={textAreaClassName(formikProps)}
            name='description'
            placeholder={t('description')}
            value={formikProps.values.description}
            minRows={TEXTAREA_ROWS_COUNT}
            maxRows={TEXTAREA_ROWS_COUNT}
            onChange={handleChange(formikProps.setFieldValue, 'description')}
          />
          <ErrorMessage name='description' render={renderError} />

          <ButtonsBar
            disabled={formikProps.isSubmitting}
            onSubmit={formikProps.handleSubmit}
            onCancel={onCancel}
          />
        </Form>
      )}
    </Formik>
  )
}

export default EditDescriptionForm
