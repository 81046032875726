// @flow

import { uniqWith } from 'lodash-es'

import { ALL_PAGES } from '../../../../constants'
import {
  DWELLER_ROLE_VALUE,
  LANDLORD_ROLE_VALUE,
} from './FilterModal.constants'
import { convertUserToManagerSearchObj } from '../../../../utils/utils'

export function getBuildingLabel(option) {
  return option.address_obj.value
}

export function getFlatLabel(option) {
  return option.number
}

export function getRoleParam(role) {
  if (!role) {
    return null
  }

  const roleValue = role.value

  if (roleValue === DWELLER_ROLE_VALUE) {
    return { is_landlord: false }
  }

  if (roleValue === LANDLORD_ROLE_VALUE) {
    return { is_landlord: true }
  }

  return null
}

export function getParams(building, flat, role) {
  let params = { page_size: ALL_PAGES }

  if (building) {
    params.building = building.id
  }

  if (building && flat) {
    params.flat = flat.id
  }

  const roleParam = getRoleParam(role)

  if (roleParam) {
    params = { ...params, ...roleParam }
  }

  return params
}

export function compareProfiles(a, b) {
  return !!a.profile && !!b.profile && a.profile.id === b.profile.id
}

export function filterUsers(prevUsers, nextUsers) {
  return uniqWith(
    [...prevUsers, ...nextUsers.map(convertUserToManagerSearchObj)],
    compareProfiles
  )
}
