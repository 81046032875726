// @flow

import { put, call, fork, takeEvery, all } from 'redux-saga/effects'
import { chunk, omitBy, isNil } from 'lodash-es'

import api from '../../core/api'
import * as actions from './FilesUpload.actionTypes'

const UPLOAD_GROUP_SIZE = 3

function* watchUpload() {
  yield takeEvery(actions.UPLOAD_FILES, upload)
}

function* upload({ files, params }) {
  const preparedFiles = chunk(files, UPLOAD_GROUP_SIZE)

  let fileIndex = 0

  const processedParams = omitBy(params, p => isNil(p))

  for (let i = 0; i < preparedFiles.length; ++i) {
    yield all(
      preparedFiles[i].map((f, index) =>
        call(uploadOne, f, fileIndex + index, processedParams)
      )
    )
    fileIndex += UPLOAD_GROUP_SIZE
  }
}

function* uploadOne(file, index, params) {
  try {
    const data = {
      origin: [file],
      name: file.name,
      type: params.type,
    }

    if (params.parent) {
      data.parent = params.parent
    }

    const result = yield call(api.file.createFile, data)
    yield put({
      type: actions.FILE_WAS_UPLOADED,
      file: result,
      index,
    })
  } catch (err) {
    yield put({
      type: actions.FILE_UPLOAD_ERROR,
      filename: file.name,
    })
  }
}

export default function* watch() {
  yield fork(watchUpload)
}
