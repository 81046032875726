// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  getManagerPermissions,
  updateManagerPermission,
} from '../../core/api/api.permission'
import Permission from './Permission'
import Loader from '../Loader'
import { usePermissions } from '../../hooks'

const MANAGER_CAN_PIN_POST = 'manager_can_pinned_post'
const CAN_MANAGER_USE_MARKETPLACE = 'can_manager_use_marketplace'

const ManagerPermissions = () => {
  const { t } = useTranslation('Permissions')

  const [permissions, handleClick] = usePermissions(
    getManagerPermissions,
    updateManagerPermission
  )

  if (!permissions) {
    return <Loader text={false} type='big' />
  }

  return (
    <div className='settings-rights__wrapper'>
      <Permission
        isActive={permissions[MANAGER_CAN_PIN_POST]}
        handleClick={handleClick}
        permissionDescription={t(MANAGER_CAN_PIN_POST)}
        title={t('newsband')}
        permissionName={MANAGER_CAN_PIN_POST}
      />
      <Permission
        isActive={permissions[CAN_MANAGER_USE_MARKETPLACE]}
        handleClick={handleClick}
        permissionDescription={t(CAN_MANAGER_USE_MARKETPLACE)}
        title={t('marketplace')}
        permissionName={CAN_MANAGER_USE_MARKETPLACE}
      />
    </div>
  )
}

export default ManagerPermissions
