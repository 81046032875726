// @flow

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import AddRequestCategoryPopup from './modals/AddRequestCategoryPopup'
import Icon from '../../static/icons/option-radio.svg'
import Modal from './Modal'
import SelectCustom from './Select/SelectCustom'
import NewTable from './NewTable'
import NewTableHeader from './NewTable/NewTableHeader'
import NewTableHeaderRow from './NewTable/NewTableHeaderRow'
import NewTableHeaderCell from './NewTable/NewTableHeaderCell'
import NewTableBodyRow from './NewTable/NewTableBodyRow'
import NewTableBody from './NewTable/NewTableBody'
import NewTableBodyCell from './NewTable/NewTableBodyCell'

type Props = {
  t: string => string,
}
type State = {
  openPopup: boolean,
}

class RequestTypeFields extends Component<Props, State> {
  state = {
    openPopup: false,
  }

  preventOpen = e => {
    e.stopPropagation()
  }

  hidePopup = () => {
    this.setState({ openPopup: false })
  }

  edit = () => {
    this.setState({ openPopup: true })
  }

  renderModal = () => {
    const { openPopup } = this.state

    return (
      <Modal contentLabel='' isOpen={openPopup} onClose={this.hidePopup}>
        <DndProvider backend={HTML5Backend}>
          <AddRequestCategoryPopup onClose={this.hidePopup} />
        </DndProvider>
      </Modal>
    )
  }

  renderFields = () => {
    const { t } = this.props

    const options = [
      {
        value: 'changeField',
        label: t('ChangeField'),
        onClick: () => this.edit(),
        icon: 'pencil',
      },
    ]

    return (
      <NewTable>
        <NewTableHeader>
          <NewTableHeaderRow>
            <NewTableHeaderCell title={t('FieldName')} />
            <NewTableHeaderCell title={t('FieldType')} />
            <NewTableHeaderCell title={t('RequiredField')} />
            <NewTableHeaderCell title={t('DateOfChange')} />
          </NewTableHeaderRow>
        </NewTableHeader>
        <NewTableBody>
          <NewTableBodyRow onClick={this.edit}>
            <NewTableBodyCell title={t('TypeTitle')} />
            <NewTableBodyCell title={t('OneOption')}>
              <Icon />
            </NewTableBodyCell>
            <NewTableBodyCell title={t('Common:no')} />
            <NewTableBodyCell>
              <div className='table-extra'>
                <SelectCustom
                  options={options}
                  onClick={this.preventOpen}
                  onChange={o => o.onClick()}
                />
              </div>
            </NewTableBodyCell>
          </NewTableBodyRow>
        </NewTableBody>
      </NewTable>
    )
  }

  render() {
    return (
      <>
        {this.renderFields()}
        {this.renderModal()}
      </>
    )
  }
}

export default withTranslation('RequestFields')(RequestTypeFields)
