// @flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { changeDirectory, renameFile } from '../../core/api/api.file'
import Checkbox from '../Checkbox/Checkbox'
import { FOLDER_TYPE } from './Files.constants'
import ModalHead from '../Modal/ModalHead'
import ModalCloseButton from '../Modal/ModalCloseButton'
import { useOverflow } from '../../hooks'
import NewSelectSimple from '../NewSelectSimple'

const ChangeTypePopup = props => {
  const { title, buttonTitle, name, usersAccess, id, type } = props

  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState(usersAccess)
  const [isInherit, setInherit] = useState(true)

  const { t } = useTranslation('Files')

  useOverflow()

  const accessOptions = [
    { label: t('SetPublic'), value: 'allow' },
    { label: t('SetPrivate'), value: 'deny' },
  ]

  const handleSelect = option => setValue(option.value)

  const selectedOption = accessOptions.find(option => option.value === value)

  const handleSave = () => {
    setLoading(true)

    const params = Object.assign(
      {},
      { users_access: value },
      FOLDER_TYPE === type ? { update_permissions: isInherit } : undefined
    )

    let api = renameFile

    if (FOLDER_TYPE === type) {
      api = changeDirectory
    }

    api(id, params)
      .then(() => {
        setLoading(false)
        props.setLoading(true)
        props.closePopup()
      })
      .catch(error => props.setError(error))
  }

  const handleInherit = () => setInherit(isInherit => !isInherit)

  return (
    <>
      <ModalHead title={t(title)} />
      <ModalCloseButton onClose={props.closePopup} />
      <div className='modal__body'>
        <div className='m-a10__row'>
          <div className='m-a10__cell m-a10__cell_1'>
            <span className='m-a10__label'>{t('FolderName')}</span>
          </div>
          <div className='m-a10__cell m-a10__cell_2'>
            <div className='input input--medium input--block input--default'>
              <input
                disabled
                type='text'
                name='name'
                placeholder={t('FolderNamePlaceholder')}
                value={name}
              />
            </div>
          </div>
        </div>
        <div className='m-a10__row'>
          <div className='m-a10__cell m-a10__cell_1'>
            <span className='m-a10__label'>{t('FolderAccess')}</span>
          </div>
          <div className='m-a10__cell m-a10__cell_2'>
            <div className='input input--medium input--block input--default'>
              <NewSelectSimple
                name='users-access'
                value={selectedOption}
                options={accessOptions}
                onChange={handleSelect}
              />
            </div>
          </div>
        </div>
        {type === FOLDER_TYPE && (
          <div className='m-a10__row'>
            <div className='m-a10__cell m-a10__cell_1'>
              <span className='m-a10__label'>{t('Inherit')}</span>
            </div>
            <div className='input input--medium input--block input--default'>
              <Checkbox checked={isInherit} onChange={handleInherit} />
            </div>
          </div>
        )}
      </div>
      <div className='modal__submit'>
        <button
          className='button button--large button--success'
          type='button'
          disabled={value === usersAccess || loading}
          onClick={handleSave}
        >
          {loading && <span className='button__spinner' />}
          {t(buttonTitle)}
        </button>
        <button
          disabled={loading}
          className='button button--large button--danger-3'
          type='button'
          onClick={props.closePopup}
        >
          {t('Cancel')}
        </button>
      </div>
    </>
  )
}

export default ChangeTypePopup
