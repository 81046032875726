// @flow

import { graphql } from 'react-relay'

const query = graphql`
  query OrderProfilePageQuery($orderID: ID!) {
    customerOrder(id: $orderID) {
      ...OrderProfile_data @arguments(orderID: $orderID)
      provider {
        contacts {
          type
          value
          verboseName
        }
      }
    }
  }
`

export default query
