// @flow

import React from 'react'
import type { Match } from 'react-router-dom'
import type { Node } from 'react'

import RequestList from '../components/Request/RequestList'

type Props = {
  match: Match,
  setCount: number => void,
}

const RequestGallery = (props: Props): Node => {
  const { match } = props

  return (
    <div className='content__col table-page__height--960'>
      <RequestList
        search={match.params.search}
        tabView={false}
        setCount={props.setCount}
      />
    </div>
  )
}

export default RequestGallery
