// @flow

import React from 'react'
import type { Node } from 'react'
import { connect } from 'react-redux'

import Button from '../../../../components/Button'
import DateTime from '../../../../components/DateTime'
import {
  getFullDateFormat,
  getThemeHeaderColor,
} from '../../../../utils/commonSelectors'
import styles from './Article.module.scss'

type Props = {
  children: Node,
  data: Object,
  dateFormat: string,
  style?: Object,
  themeColor: string,
}

const Article = (props: Props): Node => {
  const { data, children, style, dateFormat, themeColor } = props

  return (
    <div className='public-building-page__news-page-one' style={style}>
      <div className='public-building-page__news-page-one_content'>
        <div className={styles.title} style={{ '--themeColor': themeColor }}>
          {data.title ? <h2>{data.title}</h2> : null}
          {data.pinned ? (
            <Button.Pin active disabled className={styles.pin} />
          ) : null}
        </div>
        <p style={{ fontSize: '18px' }}>
          <DateTime dateTime={data.created} dateFormat={dateFormat} />
        </p>
        <p
          dangerouslySetInnerHTML={{
            __html: data.text,
          }}
        />
        {children}
      </div>
    </div>
  )
}

Article.defaultProps = {
  style: null,
}

const mapStateToProps = state => ({
  dateFormat: getFullDateFormat(state),
  themeColor: getThemeHeaderColor(state),
})

export default connect(mapStateToProps)(Article)
