// @flow

import React from 'react'
import type { Node } from 'react'

import styles from './ActionIcon.module.scss'

type Props = { children: Node }

const ActionIcon = (props: Props): Node => {
  const { children } = props

  return <div className={styles.icon}>{children}</div>
}

export default ActionIcon
