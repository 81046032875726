// @flow

import type { Node } from 'react'

import React, { useState, useEffect, useRef } from 'react'

import api from '../../../core/api'

import * as Yup from 'yup'

import {
  NOTIFICATION_ERROR,
  NOTIFICATION_EMAIL,
  NOTIFICATION_RETRY_ERROR,
} from '../../Notification/NotificationItem'

import { useSelector } from 'react-redux'

import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

import { Header, SimpleBlock } from '../../Block'
import Settings from '../../Settings'

import Icon from '../../Icon'

import InputPassword from '../../InputPassword'
import PhoneInput from '../../forms/PhoneInput'
import Notifications from '../../Notification/NotificationList'
import ValidationModal from './ValidationModal'
import { get, isEmpty } from 'lodash-es'

type IUserInfo = {
  email: string,
  owner: string,
  phone: string,
}

type Props = {
  has_password: Boolean,
}

const UserEmail = (props: Props): Node => {
  const { has_password } = props
  const {
    owner: user,
    email: initialEmail,
    phone: initialPhone,
  }: IUserInfo = useSelector(state => state.login.user)

  const countryCode: string = useSelector(
    state => state.init.uk.country_obj.code
  )
  const refInterval = useRef()
  const [isOpeModal, setIsOpeModal] = useState(false)
  const [notification, setNotification] = useState({})

  const { t } = useTranslation('User')

  useEffect(() => {
    if (!isEmpty(notification)) {
      const { type, param = {} } = notification

      if (type === NOTIFICATION_RETRY_ERROR) {
        if (!param.time) {
          closeNotification()
        }

        if (!refInterval.current && param.time) {
          refInterval.current = setInterval(() => {
            setNotification(prev => ({
              ...prev,
              param: {
                time: prev?.param?.time - 1,
              },
            }))
          }, 1000)

          formik.setSubmitting(true)
        }
      }
    }
  }, [notification])

  useEffect(() => () => closeNotification(), [])

  const validatePhone = (phone: string) => {
    api.system
      .validatePhone({ number: phone })
      .then(() => {
        setIsOpeModal(true)
        closeNotification()
      })
      .catch(error => {
        const {
          message: {
            response: { status },
          },
        } = error

        if (status === 400 || status === 403) {
          setNotification({
            type: NOTIFICATION_ERROR,
            text: error.message.response.data.errors.replaceAll(':', ''),
            translate: true,
          })
        }
      })
      .finally(() => clearNotificationsInterval())
  }

  const updateEmail = (password: string, email: string) => {
    api.profile
      .update(user, {
        current_password: has_password ? password : undefined,
        email,
      })
      .then(() => {
        if (email !== initialEmail) {
          setNotification({
            type: NOTIFICATION_EMAIL,
            translate: true,
            email,
          })
        }
      })
      .catch(error => {
        const text = get(error, ['message', 'response', 'data', 'errors'])

        if (get(error, ['message', 'response', 'status']) === 429) {
          const time = get(error, [
            'message',
            'response',
            'data',
            'retry_after',
          ])

          setNotification({
            type: NOTIFICATION_RETRY_ERROR,
            text,
            translate: true,
            param: {
              time,
            },
          })
        } else {
          setNotification({
            type: NOTIFICATION_ERROR,
            text,
            translate: true,
          })
        }
      })
  }

  const formik = useFormik({
    initialValues: Object.assign({}, has_password ? { password: '' } : {}, {
      email: initialEmail,
      phone: initialPhone,
    }),
    validationSchema: Yup.object(
      Object.assign(
        {},
        has_password
          ? { password: Yup.string().required(t('Common:PasswordIsRequired')) }
          : {},
        {
          email: Yup.string()
            .email(t('Common:EnterValidEmailErrorText'))
            .required(t('Common:FieldIsRequired')),
        }
      )
    ),
    onSubmit: ({ email, phone, password }) => {
      if (phone !== initialPhone) {
        validatePhone(phone)
      }

      if (email !== initialEmail) {
        updateEmail(password, email)
      }

      return true
    },
  })

  const closeNotification = () => {
    clearNotificationsInterval()
    setNotification({})
    formik.setSubmitting(false)
  }
  const clearNotificationsInterval = () => {
    if (refInterval.current) {
      clearInterval(refInterval.current)
      refInterval.current = null
    }
  }

  const getError = (field: string) => [formik.errors[field]]
  const isValid = (field: string) => !formik.errors[field]

  return (
    <div id='settings-personal--email'>
      <Notifications
        single
        indented
        removeTimeoutTime={100}
        notifications={isEmpty(notification) ? [] : [notification]}
        parent='settings-email-notifications'
        onRemove={closeNotification}
      />
      <SimpleBlock>
        <Header header>{t('InfoTitle')}</Header>
        <form onSubmit={formik.handleSubmit} onChange={formik.handleChange}>
          {has_password && (
            <Settings.Row
              label={'Password'}
              field='password'
              getError={getError}
              isValid={isValid}
            >
              <InputPassword
                label={t('Password')}
                name='password'
                field='password'
                placeholder={t('PasswordPlaceholder')}
                value={formik.values.password}
                onChange={formik.handleChange}
              />
            </Settings.Row>
          )}
          <Settings.Hint text={t('EmailInfo')} />
          <Settings.Row
            label={t('UserEmail')}
            field='email'
            getError={getError}
            isValid={isValid}
          >
            <input
              name='email'
              value={formik.values.email}
              onChange={formik.handleChange}
            />
            {!formik.errors.email && (
              <Icon colored className='input__icon' id='check' />
            )}
          </Settings.Row>
          <Settings.Row label={t('UserPhone')}>
            <PhoneInput
              type='text'
              countryCode={countryCode}
              name='phone'
              value={formik.values.phone}
              onChange={formik.handleChange}
            />
            <Icon colored className='input__icon' id='check' />
          </Settings.Row>
          <Settings.Actions
            working={formik.isSubmitting}
            withChanges={formik.dirty}
            onCancel={formik.handleReset}
          />
        </form>
      </SimpleBlock>
      {isOpeModal && (
        <ValidationModal
          isOpen={isOpeModal}
          user={user}
          onCloseModal={() => setIsOpeModal(false)}
          onShowNotification={setNotification}
          {...formik.values}
        />
      )}
    </div>
  )
}

export default UserEmail
