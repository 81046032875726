// @flow

import React, { memo, useEffect, useRef } from 'react'
import m from 'moment'
import classnames from 'classnames'
import { useSelector } from 'react-redux'

import DateTime from '../../../../../../DateTime'
import Icon from '../../../../../../Icon'
import { useComponentSize } from '../../../../../../../hooks'
import { getUser } from '../../../../../../../utils/commonSelectors'
import { isStaffUser } from '../../../../../../../utils/utils'
import {
  COMPLETED_REQUEST_STATUS,
  REJECTED_REQUEST_STATUS,
} from '../../../../../../../constants'

import styles from './AdminInfo.module.scss'

const STATUSES_WITHOUT_DUE_DATE = [
  COMPLETED_REQUEST_STATUS,
  REJECTED_REQUEST_STATUS,
]

type Props = {
  dueDate?: string,
  setWidthEffect: number => void,
  status: number,
  user: Object,
}

const DueDateLabel = memo<Props>(props => {
  const { dueDate, status } = props

  const ref = useRef(null)

  const size = useComponentSize(ref)

  const user = useSelector(getUser)

  useEffect(() => {
    if (size.width) {
      props.setWidthEffect(size.width)
    }
  })

  if (
    !dueDate ||
    !isStaffUser(user) ||
    STATUSES_WITHOUT_DUE_DATE.includes(status)
  ) {
    return null
  }

  const current = m()

  const formattedDueDate = m(dueDate)

  const isExpired = current > formattedDueDate

  const className = classnames(styles['due-date'], {
    [styles['due-date-expired']]: isExpired,
    [styles['due-date-active']]: !isExpired,
  })

  return (
    <div className={className} ref={ref}>
      <Icon id='deadline' className={styles.icon} />
      <DateTime dateTime={dueDate} />
    </div>
  )
})

DueDateLabel.defaultProps = {
  dueDate: null,
}

export default DueDateLabel
