// @flow

import { getApi, patchApi, postApi } from './api.core'

export const getIgelConfig = () => getApi('api/integration/igel/')
export const getIgelStatus = () => getApi('api/integration/igel/status/')
export const createIgel = config => postApi('api/integration/igel/', config)
export const applyIgelConfig = config =>
  patchApi('api/integration/igel/', config)
export const syncIgel = () => postApi('api/integration/igel/integrate/')
export const getIgelMatchingRequests = () =>
  getApi('api/integration/igel/request-type-mapping/')
export const checkIgelCopiedTypes = () =>
  getApi('api/integration/igel/request-type-mapping/is_types_copied/')
export const igelMatchRequests = data =>
  postApi('api/integration/igel/request-type-mapping/', { data })
export const copyRequestsFromIgel = () =>
  postApi('api/integration/igel/request-type-mapping/copy_from_igel/')

export const getRealDataConfig = () => getApi('api/integration/realdata/')
export const getRealDataStatus = () =>
  getApi('api/integration/realdata/status/')
export const createRealData = config =>
  postApi('api/integration/realdata/', config)
export const applyRealDataConfig = config =>
  patchApi('api/integration/realdata/', config)
export const syncRealData = () => postApi('api/integration/realdata/integrate/')
export const getRealDataMatchingRequests = () =>
  getApi('api/integration/realdata/request-type-mapping/')
export const checkRealDataCopiedTypes = () =>
  getApi('api/integration/realdata/request-type-mapping/is_types_copied/')
export const RealDataMatchRequests = data =>
  postApi('api/integration/realdata/request-type-mapping/', { data })
export const copyRequestsFromRealData = () =>
  postApi('api/integration/realdata/request-type-mapping/copy_from_realdata/')
export const getRealDataAdditional = () =>
  getApi('api/integration/realdata/additional/')

export const getDomusConfig = () => getApi('api/integration/domus/')
export const getDomusStatus = () => getApi('api/integration/domus/status/')
export const createDomus = config => postApi('api/integration/domus/', config)
export const updateDomus = config => patchApi('api/integration/domus/', config)
export const syncDomus = () => postApi('api/integration/domus/integrate/')
