// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import type { Node } from 'react'

import Icon from '../../../../../../Icon'

import styles from './AdminInfo.module.scss'

type Props = {
  count: number,
  emailsCount: number,
  has_error_message: boolean,
  unread_emails_count: number,
}

const Emails = (props: Props): Node => {
  const { count, emailsCount, has_error_message, unread_emails_count } = props

  const { t } = useTranslation('Request')

  const iconName = `closed${has_error_message ? 'Error' : ''}Env`

  const countProps = Object.assign(
    {},
    unread_emails_count > 9 ? { style: { padding: '0 2px' } } : undefined
  )

  const countClass = classnames(styles.count, {
    [styles.error]: has_error_message,
  })

  return (
    <>
      <div
        className={styles[['provider-notify']]}
        title={t('WhereAreEmails', { count: emailsCount })}
      >
        {unread_emails_count > 0 && (
          <div className={countClass} {...countProps}>
            {unread_emails_count > 99 ? '99+' : unread_emails_count}
          </div>
        )}
        <Icon
          id={iconName}
          className={styles.icon}
          title={t('WhereAreEmails', { count: emailsCount })}
        />
      </div>
      {count === 1 && (
        <span style={{ marginLeft: '7px' }} className={styles.singleText}>
          {t('WhereAreEmails', {
            count: emailsCount,
          })}
        </span>
      )}
    </>
  )
}

export default Emails
