// @flow

export const UPDATE_INPUT_VALUE = 'UPDATE_INPUT_VALUE'
export const CLEAR_SUGGESTIONS = 'CLEAR_SUGGESTIONS'
export const MAYBE_UPDATE_SUGGESTIONS = 'MAYBE_UPDATE_SUGGESTIONS'
export const LOAD_SUGGESTIONS_BEGIN = 'LOAD_SUGGESTIONS_BEGIN'
export const USERSUGGEST_LOAD = 'USERSUGGEST_LOAD'
export const USERSUGGEST_LOADED = 'USERSUGGEST_LOADED'
export const MANAGER_CHANGE = 'MANAGER_CHANGE'
export const USERSUGGEST_INIT = 'USERSUGGEST_INIT'
export const PROVIDERSUGGEST_LOAD = 'PROVIDERSUGGEST_LOAD'
export const PROVIDERSUGGEST_LOADED = 'PROVIDERSUGGEST_LOADED'
