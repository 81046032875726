// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'

import Blocks from '../../components/RequestConstructor/GroupBlocks'
import GroupTitle from '../../components/RequestConstructor/GroupTitle'
import * as actions from './RequestConstructor.actionTypes'

type Props = {
  activeBlock: ?Object,
  blocks: Array<Object>,
  dispatch: Object => void,
  id: number,
  onEndDragBlock: Object => void,
  onMoveBlock: (Object, number) => void,
  onShowBlock: Object => void,
  onStartDragBlock: () => void,
  title: string,
  working: boolean,
}

class RequestConstructorGroup extends Component {
  props: Props

  remove = id => {
    const { dispatch } = this.props
    dispatch({ type: actions.REQUEST_CONSTRUCTOR_REMOVE_GROUP, id })
  }

  rename = (id, title) => {
    const { dispatch } = this.props
    dispatch({ type: actions.REQUEST_CONSTRUCTOR_RENAME_GROUP, id, title })
  }

  createBlock = group => {
    const { dispatch } = this.props
    dispatch({ type: actions.REQUEST_CONSTRUCTOR_ADD_BLOCK, group })
  }

  render() {
    const {
      id,
      activeBlock,
      blocks,
      title,
      working,
      onShowBlock,
      onMoveBlock,
      onStartDragBlock,
      onEndDragBlock,
    } = this.props

    return (
      <div className='request-constructor__group' key={id}>
        <GroupTitle
          blocksCount={blocks.length}
          title={title}
          working={working}
          onRemove={() => this.remove(id)}
          onRename={name => this.rename(id, name)}
        />
        <Blocks
          active={activeBlock}
          items={blocks}
          group={id}
          onCreate={this.createBlock}
          onEndDrag={onEndDragBlock}
          onMove={onMoveBlock}
          onSelect={onShowBlock}
          onStartDrag={onStartDragBlock}
        />
      </div>
    )
  }
}

export default connect(state => ({
  working: state.requestConstructor.workingGroups,
}))(RequestConstructorGroup)
