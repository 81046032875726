// @flow

import classnames from 'classnames'
import { defaults, get, keys, pick } from 'lodash-es'

import {
  cloneTemplate,
  createTemplate,
  patchTemplate,
} from '../../../core/api/api.postTemplate'
import { CLONE, CREATE, EDIT } from './PostTemplatePopup.constants'
import type { Params } from '../PostTemplates.types'

export function getFieldClass(error: boolean): string {
  return classnames('input input--medium input--block input--default', {
    'input--error': error,
  })
}

export function getActionParams(params: Params): {
  fields?: { [string]: any },
  type: string,
} {
  const id = get(params, 'id')

  if (id) {
    const { name, text } = params

    if (text) {
      const {
        post_title,
        private: isPrivate,
        files,
        hidden,
        post_type,
        post_ttl,
        building_group_objs,
        for_building_without_group,
      } = params

      let building_groups = building_group_objs.map(({ id, name }) => ({
        value: id,
        label: name,
      }))

      const fields = Object.assign(
        {},
        {
          name,
          private: isPrivate,
          post_title,
          post_type,
          files,
          hidden,
          text,
        }
      )

      const defaultValues = Object.assign(
        {},
        post_ttl ? { post_ttl } : { post_ttl: 0 },
        { building_groups, for_building_without_group }
      )

      return {
        type: EDIT,
        fields,
        api: patchTemplate,
        defaultValues,
      }
    } else {
      return { type: CLONE, fields: { name }, api: cloneTemplate }
    }
  } else {
    return {
      type: CREATE,
      api: createTemplate,
      defaultValues: {
        name: '',
        text: '',
        post_title: '',
        post_type: 'post',
        post_ttl: 0,
        private: true,
        files: [],
        hidden: false,
        building_groups: [],
      },
    }
  }
}

export function getInitialValues(params: Params, fields, defaultValues) {
  return defaults(pick(params, keys(fields)), defaultValues)
}
