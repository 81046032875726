// @flow

import React from 'react'

import type { Props } from './index'

const SourceCell = ({ user, t, onClick }: Props) => (
  <div className='table__cell' onClick={onClick}>
    <div className='table__cell-wrapper'>
      <div className='table-item table-item__text' title={t(user.origin)}>
        <span>{t(user.origin)}</span>
      </div>
    </div>
  </div>
)

export default SourceCell
