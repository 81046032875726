// @flow

import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import Loader from '../Loader'
import Button from '../Button'
import Toolbox from '../Toolbox'
import { useLoad } from '../../hooks'
import {
  SalutationModalChange,
  SalutationModalDelete,
  SalutationModalError,
} from './SalutationModal'
import SalutationTable from './SalutationTable'
import { getSalutation } from '../../core/api/api.pipeline'

type ISalutation = {
  deleted: boolean,
  id: number,
  object_type: string,
  salutation: string,
}

const Salutation = (): Node => {
  const { t } = useTranslation('Salutation')

  const [isModalChangeOpen, setChangeModalOpen] = useState(false)
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false)
  const [isModalErrorOpen, setIsModalErrorOpen] = useState(false)
  const [editingSalutation, setEditingSalutation] = useState({})
  const [salutations, isLoading, isError, load]: [
    Array<ISalutation>,
    boolean,
    boolean,
    Function
  ] = useLoad(getSalutation)

  const handleModalChangeClose = () => setChangeModalOpen(false)
  const handleModalDeleteClose = () => setIsModalDeleteOpen(false)
  const handleModalErrorClose = () => setIsModalErrorOpen(false)
  const handleCreate = () => setChangeModalOpen(true)

  useEffect(() => {
    if (isError && !isModalErrorOpen) {
      setIsModalErrorOpen(true)
    }
  }, [isError])

  return (
    <>
      {salutations ? (
        <>
          <Toolbox>
            <Button.Save onClick={handleCreate}>
              {t('AddSalutation')}
            </Button.Save>
          </Toolbox>
          <SalutationTable
            salutations={salutations}
            isLoading={isLoading}
            setEditingSalutation={setEditingSalutation}
            setIsModalDeleteOpen={setIsModalDeleteOpen}
            onEdit={handleCreate}
          />
          {isModalChangeOpen && (
            <SalutationModalChange
              editingSalutation={editingSalutation}
              setEditingSalutation={setEditingSalutation}
              load={load}
              onClose={handleModalChangeClose}
            />
          )}
          {isModalDeleteOpen && (
            <SalutationModalDelete
              editingSalutation={editingSalutation}
              setEditingSalutation={setEditingSalutation}
              load={load}
              onClose={handleModalDeleteClose}
            />
          )}
          {isModalErrorOpen && (
            <SalutationModalError onClose={handleModalErrorClose} />
          )}
        </>
      ) : (
        <Loader type='big' text={false} />
      )}
    </>
  )
}

export default Salutation
