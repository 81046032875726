// @flow

import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { map, isPlainObject } from 'lodash-es'

import ConfirmationPopup from '../ConfirmationPopup'

const ErrorPopup = ({
  title,
  text,
  onOk,
  confirm,
  t,
  needTranslate,
  error,
  isServerError,
}) => {
  const props = {
    onOk,
    onClose: onOk,
    title,
    showCancel: false,
    confirm,
  }

  if (error && !isServerError) {
    const errorData = error.errors
    let errorMessage = ''

    if (isPlainObject(errorData)) {
      map(errorData, item => {
        errorMessage += `${t(item)} <br />`
      })
    } else {
      errorMessage = t(errorData)
    }

    props.text = errorMessage
  } else if (needTranslate) {
    props.text = t(text)
  } else {
    props.text = text
  }

  return <ConfirmationPopup {...props} style={{ zIndex: 2001 }} />
}

ErrorPopup.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  onOk: PropTypes.func.isRequired,
  confirm: PropTypes.string,
  t: PropTypes.func,
}

export default withTranslation('Errors')(ErrorPopup)
