// @flow

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { filter, get } from 'lodash-es'
import type { Node } from 'react'

import EmptyList from '../EmptyList/EmptyList'
import Button from '../Button'
import BottomPagination from '../Pagination/BottomPagination'
import Loader from '../Loader'
import {
  getActivities,
  getActivityTypes,
  getActivityPriorities,
  getActivity,
} from '../../core/api/api.pipeline'
import { useLoad, usePathname } from '../../hooks'
import ActivitiesTable from './ActivitiesTable'
import Toolbox from '../Toolbox'
import ToolboxCell from '../Users/UserList/Toolbox/ToolboxCell'
import ActivityModal from './ActivityModal'
import { getFetchedItems } from '../../utils/utils'

import styles from '../../containers/RequestView/RequestActivity/RequestActivity.module.scss'
import classnames from 'classnames'

type Props = { setActivityCount: number => void }

const Activities = (props: Props): Node => {
  const isLocked = usePathname()

  const [data, isLoading, isError, load] = useLoad(
    getActivities,
    null,
    isLocked
  )

  const [isModalOpen, setModalOpen] = useState(false)
  const [activity, setActivity] = useState(null)
  const [activityId, setActivityId] = useState(null)
  const [activityTypes, setActivityTypes] = useState(null)
  const [activityPriorities, setActivityPriorities] = useState(null)

  const { t } = useTranslation('Activities')

  useEffect(() => {
    if (activity) {
      setModalOpen(true)
    } else if (activityId) {
      setActivityId(null)
    }
  }, [activity])

  useEffect(() => {
    if (activityId) {
      getActivity(activityId).then(data => setActivity(data))
    }
  }, [activityId])

  useEffect(() => {
    if (isModalOpen && !activityTypes && !activityPriorities) {
      let ActivityType = get(activity, 'type.id')
      getActivityTypes().then(data => {
        let enableActivityTypes = filter(
          getFetchedItems(data),
          item => item.enabled || item.id === ActivityType
        )
        setActivityTypes(enableActivityTypes)
      })

      getActivityPriorities().then(data => {
        setActivityPriorities(getFetchedItems(data))
      })
    }
  }, [isModalOpen])

  useEffect(() => {
    if (data) {
      props.setActivityCount(data.meta.count)
    }
  }, [data])

  const handleClose = () => setModalOpen(false)

  const handleCreate = () => setModalOpen(true)

  // TODO Need error component
  if (isError) {
    return null
  }

  if (!data && !isLoading) {
    return null
  }

  const tableClass = classnames('table table--default', {
    'working-overlay': isLoading,
  })

  return (
    <div className={tableClass}>
      {data ? (
        <>
          <Toolbox>
            <ToolboxCell>
              <Button.Save
                icon='activity'
                iconClassName={styles.icon}
                onClick={handleCreate}
              >
                {t('AddActivityButton')}
              </Button.Save>
            </ToolboxCell>
          </Toolbox>
          <ActivitiesTable
            activities={getFetchedItems(data)}
            isLoading={isLoading}
            onClick={setActivityId}
          />
          {!getFetchedItems(data).length && !isLoading && (
            <EmptyList
              canAdd
              noShowAddIcon
              icon='activity'
              title={t('ActivitiesNotFound')}
              btnText={t('AddNewActivity')}
              onClick={handleCreate}
            />
          )}
          <BottomPagination meta={data.meta} classes='paginator-2' />
        </>
      ) : (
        <Loader type='big' text={false} />
      )}
      {isModalOpen && activityPriorities && activityTypes && (
        <ActivityModal
          activity={activity}
          setActivity={setActivity}
          isModalOpen={isModalOpen}
          setModalOpen={setModalOpen}
          load={load}
          activityTypes={activityTypes}
          activityPriorities={activityPriorities}
          setActivityTypes={setActivityTypes}
          setActivityPriorities={setActivityPriorities}
          onClose={handleClose}
        />
      )}
    </div>
  )
}

export default Activities
