// @flow

import * as actions from './Faq.actions'
import { getNewGroups } from './Faq.utils'

const initialState = {
  modal: null,
  modalParams: {},
  groups: [],
  selected: [],
  loading: true,
  working: false,
  collapsed: [],
  editGroup: null,
  groupWorking: false,
  editQuestion: null,
  questionWorking: false,
  editAnswer: null,
  answerWorking: false,
  permissions: {
    can_create: false,
    can_delete: false,
    can_edit: false,
  },
}

const updateEdit = (state, id, key) => {
  const isEditing = state[key] === id || !id

  return {
    ...state,
    [key]: isEditing ? null : id,
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.FAQ_SHOW_MODAL:
      return {
        ...state,
        modal: action.modal,
        modalParams: action.params,
      }

    case actions.FAQ_CLOSE_MODAL:
      return {
        ...state,
        modal: null,
        working: false,
        modalParams: {},
      }
    case actions.FAQ_TOGGLE_ALL_GROUPS: {
      const { groups, selected } = state
      const isAllSelected =
        groups.length === selected.length && groups.length !== 0
      const allSelected = groups.map(group => group.id)

      return {
        ...state,
        selected: isAllSelected ? [] : allSelected,
      }
    }
    case actions.FAQ_LOAD_GROUPS:
      return {
        ...state,
        working: true,
      }
    case actions.FAQ_CREATE_GROUP:
      return {
        ...state,
        working: true,
      }
    case actions.FAQ_GROUPS_WAS_REMOVED:
      return {
        ...state,
        selected: [],
      }
    case actions.FAQ_GROUPS_WAS_LOADED:
      return {
        ...state,
        working: false,
        loading: false,
        groups: action.groups,
        permissions: action.permissions,
      }
    case actions.FAQ_TOGGLE_GROUP: {
      const { isSelected, id } = action
      const selected = isSelected
        ? state.selected.filter(s => s !== id)
        : state.selected.concat(id)

      return {
        ...state,
        selected,
      }
    }
    case actions.FAQ_EDIT_GROUP: {
      return updateEdit(state, action.id, 'editGroup')
    }
    case actions.FAQ_SAVE_GROUP_NAME:
      return {
        ...state,
        groupWorking: true,
      }
    case actions.FAQ_GROUP_NAME_WAS_SAVED:
      return {
        ...state,
        groupWorking: false,
        editGroup: null,
      }
    case actions.FAQ_EDIT_QUESTION: {
      return updateEdit(state, action.id, 'editQuestion')
    }
    case actions.FAQ_CLEAR_EDIT_QUESTION:
      return {
        ...state,
        editQuestion: null,
      }
    case actions.FAQ_CREATE_QUESTION:
    case actions.FAQ_SAVE_QUESTION:
      return {
        ...state,
        questionWorking: true,
      }
    case actions.FAQ_QUESTION_WAS_CREATED:
    case actions.FAQ_QUESTION_WAS_SAVED:
      return {
        ...state,
        questionWorking: false,
        editQuestion: null,
      }
    case actions.FAQ_EDIT_ANSWER:
      return updateEdit(state, action.id, 'editAnswer')
    case actions.FAQ_SAVE_ANSWER:
      return {
        ...state,
        answerWorking: true,
      }
    case actions.FAQ_ANSWER_WAS_SAVED:
      return {
        ...state,
        answerWorking: false,
        editAnswer: null,
      }
    case actions.FAQ_CLEAR_STATE:
      return {
        ...initialState,
      }
    case actions.FAQ_QUESTION_CHANGE_POSITION: {
      const {
        params: { source, destination },
      } = action
      const groups = getNewGroups(source, destination, state.groups)

      return {
        ...state,
        groups,
        working: true,
      }
    }
    default:
      return state
  }
}
