/**
 * @flow
 * @relayHash fbf8a9be0ec08e5255ed8a5567e0797e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type OrderProfile_data$ref = any;
export type OrderProfilePageQueryVariables = {|
  orderID: string
|};
export type OrderProfilePageQueryResponse = {|
  +customerOrder: ?{|
    +provider: {|
      +contacts: ?$ReadOnlyArray<?{|
        +type: ?string,
        +value: ?string,
        +verboseName: ?string,
      |}>
    |},
    +$fragmentRefs: OrderProfile_data$ref,
  |}
|};
export type OrderProfilePageQuery = {|
  variables: OrderProfilePageQueryVariables,
  response: OrderProfilePageQueryResponse,
|};
*/


/*
query OrderProfilePageQuery(
  $orderID: ID!
) {
  customerOrder(id: $orderID) {
    ...OrderProfile_data_3HPek8
    provider {
      contacts {
        type
        value
        verboseName
      }
      id
    }
    id
  }
}

fragment OrderProfileComment_data on CustomerOrderNode {
  comment
}

fragment OrderProfileCreationDate_data on CustomerOrderNode {
  createdAt
}

fragment OrderProfileCustomer_data on CustomerOrderNode {
  clientInfo
}

fragment OrderProfileHeader_data on CustomerOrderNode {
  orderNo
  offer
}

fragment OrderProfileOffer_data on CustomerOrderNode {
  offer
}

fragment OrderProfilePrice_data on CustomerOrderNode {
  grandTotal
  vatDetail {
    vat
    total
  }
}

fragment OrderProfileProvider_data on CustomerOrderNode {
  provider {
    id
    name
    image {
      origin
      id
    }
  }
}

fragment OrderProfileRequest_data on CustomerOrderNode {
  idwellRequestId
  id
}

fragment OrderProfileStatus_data on CustomerOrderNode {
  status
  id
}

fragment OrderProfile_data_3HPek8 on CustomerOrderNode {
  ...OrderProfileHeader_data
  ...OrderProfileStatus_data
  ...OrderProfileCreationDate_data
  ...OrderProfilePrice_data
  ...OrderProfileRequest_data
  ...OrderProfileProvider_data
  ...OrderProfileOffer_data
  ...OrderProfileCustomer_data
  ...OrderProfileComment_data
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "orderID",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "orderID"
  }
],
v2 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "contacts",
  "storageKey": null,
  "args": null,
  "concreteType": "ContactObjectType",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "type",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "value",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "verboseName",
      "args": null,
      "storageKey": null
    }
  ]
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "OrderProfilePageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "customerOrder",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CustomerOrderNode",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "provider",
            "storageKey": null,
            "args": null,
            "concreteType": "ProviderNode",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ]
          },
          {
            "kind": "FragmentSpread",
            "name": "OrderProfile_data",
            "args": [
              {
                "kind": "Variable",
                "name": "orderID",
                "variableName": "orderID"
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "OrderProfilePageQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "customerOrder",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CustomerOrderNode",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "orderNo",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "offer",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "status",
            "args": null,
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "createdAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "grandTotal",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "vatDetail",
            "storageKey": null,
            "args": null,
            "concreteType": "VATDetailNode",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "vat",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "total",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "idwellRequestId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "provider",
            "storageKey": null,
            "args": null,
            "concreteType": "ProviderNode",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "image",
                "storageKey": null,
                "args": null,
                "concreteType": "ProviderImageNode",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "origin",
                    "args": null,
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ]
              },
              (v2/*: any*/)
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "clientInfo",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "comment",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "OrderProfilePageQuery",
    "id": null,
    "text": "query OrderProfilePageQuery(\n  $orderID: ID!\n) {\n  customerOrder(id: $orderID) {\n    ...OrderProfile_data_3HPek8\n    provider {\n      contacts {\n        type\n        value\n        verboseName\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment OrderProfileComment_data on CustomerOrderNode {\n  comment\n}\n\nfragment OrderProfileCreationDate_data on CustomerOrderNode {\n  createdAt\n}\n\nfragment OrderProfileCustomer_data on CustomerOrderNode {\n  clientInfo\n}\n\nfragment OrderProfileHeader_data on CustomerOrderNode {\n  orderNo\n  offer\n}\n\nfragment OrderProfileOffer_data on CustomerOrderNode {\n  offer\n}\n\nfragment OrderProfilePrice_data on CustomerOrderNode {\n  grandTotal\n  vatDetail {\n    vat\n    total\n  }\n}\n\nfragment OrderProfileProvider_data on CustomerOrderNode {\n  provider {\n    id\n    name\n    image {\n      origin\n      id\n    }\n  }\n}\n\nfragment OrderProfileRequest_data on CustomerOrderNode {\n  idwellRequestId\n  id\n}\n\nfragment OrderProfileStatus_data on CustomerOrderNode {\n  status\n  id\n}\n\nfragment OrderProfile_data_3HPek8 on CustomerOrderNode {\n  ...OrderProfileHeader_data\n  ...OrderProfileStatus_data\n  ...OrderProfileCreationDate_data\n  ...OrderProfilePrice_data\n  ...OrderProfileRequest_data\n  ...OrderProfileProvider_data\n  ...OrderProfileOffer_data\n  ...OrderProfileCustomer_data\n  ...OrderProfileComment_data\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '87b801c662f26616cf028494e01de3c4';
module.exports = node;
