// @flow

import React, { Component } from 'react'
import type { Node } from 'react'
import { withTranslation } from 'react-i18next'
import OutsideClick from 'react-onclickout'
import Dropzone from 'react-dropzone'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { get } from 'lodash-es'

import Button from '../../Button'
import CameraIcon from '../../../../static/icons/camera.svg'
import Icon from '../../Icon'
import { FILE_ACCEPT_TYPES } from '../../../containers/FilesUpload'
import { removeFile } from '../../../utils/file'
import { globalModalError } from '../../Layout/Layout.actions'

const MULTIPLIER = 1024 * 1024
const IMG_MAX_SIZE = 100

const REACT_DROPZONE_ERROR_CODES = {
  'file-too-large': (t, name, size) => t('Errors:FileSize', { name, size }),
  'file-invalid-type': t => t('WrongFileType'),
}

type UploaderProps = {
  children: Node,
  className: string,
  onDrop: (Array<Object>) => void,
}
const Uploader = (props: UploaderProps) => (
  <Dropzone
    accept={FILE_ACCEPT_TYPES.images}
    multiple={false}
    maxSize={IMG_MAX_SIZE * MULTIPLIER}
    onDrop={props.onDrop}
  >
    {({ getRootProps, getInputProps }) => (
      <div className={props.className} {...getRootProps()}>
        <input {...getInputProps()} />
        {props.children}
      </div>
    )}
  </Dropzone>
)

type Props = {
  data: Object,
  onClickOut: (?boolean) => void,
  onUpdate: Object => void,
  t: string => string,
}

class Image extends Component<Props> {
  componentDidUpdate(prev: Props) {
    const {
      data: { file },
    } = this.props
    const {
      data: { file: pFile },
    } = prev

    if (pFile && (!file || pFile.id !== file.id)) {
      removeFile(pFile.id)
    }
  }

  onClickOut = () => this.props.onClickOut()

  updateFile = (file: Object, rejectedFiles) => {
    const { data } = this.props

    if (rejectedFiles?.length) {
      let errors = rejectedFiles.map(f => {
        const error = get(f, ['errors', '0', 'code'])
        const name = get(f, ['file', 'name'], '')

        if (error) {
          return REACT_DROPZONE_ERROR_CODES[error](
            this.props.t,
            name,
            IMG_MAX_SIZE
          )
        }

        return null
      })

      const errorText = errors.reduce((acc, error) => {
        return acc + error + '<br/>'
      }, '')

      this.props.dispatch(
        globalModalError(errorText, this.props.t('Errors:File'))
      )
    }

    if (file?.length) {
      this.props.onUpdate({ id: data.id, file })
    }
  }

  render() {
    const {
      data: { file },
    } = this.props

    return (
      <OutsideClick onClickOut={this.onClickOut}>
        <div className='request-constructor__card--body request-constructor__card--image'>
          {!file ? (
            <div className='request-constructor__card--image-upload'>
              <CameraIcon />
              <Uploader
                className='request-constructor__card--image-upload-btn'
                onDrop={this.updateFile}
              >
                <Button.Save>{this.props.t('uploadImage')}</Button.Save>
              </Uploader>
            </div>
          ) : (
            <Uploader
              className='request-constructor__card--image-view'
              onDrop={this.updateFile}
            >
              <img src={file.origin} />
              <div className='request-constructor__card--image-view-overlay' />
              <Icon colored id='pencil' />
            </Uploader>
          )}
        </div>
      </OutsideClick>
    )
  }
}

export default compose(connect(), withTranslation('RequestConstructor'))(Image)
