// @flow

import React, { Suspense } from 'react'
import type { Node } from 'react'

import Loader from '../Loader'

import style from './InputPassword.module.scss'

const InputPassword = React.lazy(() => import('./InputPassword'))

const Component = (props): Node => (
  <Suspense fallback={<Loader className={style.suspense} />}>
    <InputPassword {...props} />
  </Suspense>
)

Component.displayName = 'InputPassword'

export default Component
