import { connect } from 'react-redux'

import RequestStatus from './RequestStatus'
import * as actions from './RequestStatus.actions'

function mapStateToProps (state) {
  const { requests, selected } = state.requestList
  const selectedStatuses = requests.filter(({ id }) => selected.includes(id)).map(({ status }) => status)

  return ({
    ...state.requestStatus,
    statuses: state.init.request_status,
    selectedStatuses
  })
}

export default connect(mapStateToProps, actions)(RequestStatus)
