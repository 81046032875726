// @flow

import React from 'react'
import type { Node } from 'react'

type Props = {
  className: string,
  disabled?: boolean,
  onBlur: Object => void,
  onChange: Object => void,
  onFocus: Object => void,
  onRef: Object => void,
  placeholder: string,
  t: string => string,
  text: string,
}

const SelectInputButton = (props: Props): Node => {
  const {
    className,
    placeholder,
    disabled,
    text,
    onBlur,
    onFocus,
    onChange,
    onRef,
    t,
  } = props

  return (
    <div>
      <div className='dropdown__search-input'>
        <input
          className={className}
          disabled={disabled}
          placeholder={t(placeholder)}
          ref={onRef}
          type='text'
          value={text}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={onChange}
        />
      </div>
    </div>
  )
}

export default SelectInputButton
