// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import { withTranslation } from 'react-i18next'

import { USER_GROUPS } from '../../constants'
import BuildingManagers from '../TableCellUsers'
import { getFormattedDate } from '../../utils/utils'
import { getUser } from '../../utils/commonSelectors'
import Icon from '../Icon'
import SelectCustom from '../Select/SelectCustom'

import styles from './TableItem.module.scss'

type Props = {
  archived: boolean,
  canToggle: boolean,
  canTogglePage: boolean,
  data: Object,
  onDeleteFromGroup?: number => void,
  onToggle: number => void,
  selected: boolean,
  showExtra?: boolean,
}

class TableItem extends Component<Props> {
  static defaultProps = {
    archived: false,
  }

  toggle = ({ target: { value } }) => {
    const { onToggle } = this.props
    onToggle(parseInt(value, 10))
  }

  renderAddress = () => {
    const {
      data: { id, photo_obj: photo, address_obj: address, is_promo },
      selected,
      canToggle,
      archived,
      user: { validated: isUserValidated },
      canTogglePage = true,
      onToggle,
    } = this.props
    const params = archived ? '?archived=true' : ''
    const avatar = photo && photo.id ? photo.preview || photo['130'] : null

    const ConditionalWrapper = ({ condition, wrapper, children }) =>
      condition ? wrapper(children) : children

    const iconClassName = classnames(
      'messages__header-title-symbol-container',
      styles.icon
    )

    return (
      <div className='table--homes-address'>
        <label
          className='checkbox checkbox--medium checkbox--default'
          htmlFor={`check-${id}`}
        >
          {canToggle && canTogglePage && onToggle && (
            <input
              type='checkbox'
              id={`check-${id}`}
              value={id}
              checked={selected}
              onChange={this.toggle}
            />
          )}
          <span />
        </label>
        <ConditionalWrapper
          condition={isUserValidated || is_promo}
          wrapper={children => (
            <Link to={`/building/${id}${params}`} className={styles.link}>
              {children}
            </Link>
          )}
        >
          <React.Fragment>
            <span className={iconClassName}>
              {avatar ? <img src={avatar} /> : <Icon id='building' />}
            </span>
            <span className='table--homes-address-text' title={address.value}>
              {address.value}
            </span>
          </React.Fragment>
        </ConditionalWrapper>
      </div>
    )
  }

  getExtraOptions() {
    return [
      {
        value: 'deleteFromGroup',
        label: this.props.t('DeleteFromGroup'),
        handler: () => this.props.onDeleteFromGroup(this.props.data.id),
        icon: 'bin',
      },
    ]
  }

  render() {
    const {
      dweller_count: dwellersCount,
      flat_count: flatsCount,
      owner_objs: managers,
      property_owner_objs: owners,
      updated,
    } = this.props.data

    const { permissions = {}, showExtra } = this.props

    const { can_view_property_owners: canViewOwner } = permissions

    return (
      <div className='table__row table__row-homes'>
        <div className='table__cell table__cell--aligned'>
          {this.renderAddress()}
        </div>

        <div className='table__cell table__cell--aligned'>
          <BuildingManagers items={managers} />
        </div>

        {canViewOwner && (
          <div className='table__cell table__cell--aligned'>
            <BuildingManagers items={owners} />
          </div>
        )}
        <div className='table__cell table__cell--aligned'>
          <div className='cell_margin'>{flatsCount}</div>
        </div>

        <div className='table__cell table__cell--aligned'>
          <div className='cell_margin'>{dwellersCount}</div>
        </div>

        <div className='table__cell table__cell--aligned'>
          <div className='cell_margin'>{getFormattedDate(updated)}</div>
          {showExtra && (
            <div className='table-extra table-extra--settings'>
              <SelectCustom
                options={this.getExtraOptions()}
                onClick={e => e.stopPropagation()}
                onChange={opt => opt.handler()}
              />
            </div>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  canToggle: state.login.user.group === USER_GROUPS.admin,
  user: getUser(state),
})

export default connect(mapStateToProps)(withTranslation('Building')(TableItem))
