// @flow

import React from 'react'
import type { Node } from 'react'
import { useTranslation } from 'react-i18next'

import Cancel from './Cancel'
import type { Props } from './Regular/Regular'

const Remove = (props: Props): Node => {
  const { t } = useTranslation('Common')

  return (
    <Cancel icon='bin' {...props}>
      {props.children || t('Delete')}
    </Cancel>
  )
}

export default Remove
