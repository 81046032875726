import { all, put, call, fork, takeEvery, takeLatest } from 'redux-saga/effects'
import { isArray, get } from 'lodash-es'

import apiList from '../../../core/api'
import { serverError } from '../../Layout/Layout.actions'
import * as actions from './SelectAsync.actionTypes'

function* watchInit() {
  yield takeEvery(actions.SELECT_ASYNC_INITIATING, init)
}

function* watchLoad() {
  yield takeLatest(actions.SELECT_ASYNC_LOADING, load)
}

const fn = api => get(apiList, api)

function* init(action) {
  const { valueId, params, multi, key, apiFn, apiFnParams, initOptions, all } =
    action

  try {
    const arrayValueFilled = isArray(valueId) && valueId.length
    let value = multi ? [] : null

    if (valueId && (!isArray(valueId) || arrayValueFilled)) {
      value = yield call(fn(apiFn), {
        id: valueId,
        all: params.all || all,
        page_size: params.page_size || undefined,
      })
      value = multi ? value.results.objects : value.results.objects[0]
    }

    const getApi = apiFnParams ? fn(apiFn)(apiFnParams) : fn(apiFn)
    const result = initOptions ? yield call(getApi, params) : {}

    if ('groups' in result) {
      result[key] = result.groups
    }

    yield put({
      type: actions.SELECT_ASYNC_INITIATED,
      ...result,
      value,
      key,
    })
  } catch (error) {
    yield put(serverError(error))
    yield put({ type: actions.SELECT_ASYNC_ERROR, error, key })
  }
}

function* load(action) {
  const { params, apiFn, key, apiFnParams } = action

  try {
    const getApi = apiFnParams ? fn(apiFn)(apiFnParams) : fn(apiFn)

    const result = yield call(getApi, params)
    yield put({
      type: actions.SELECT_ASYNC_LOADED,
      ...result,
      key,
    })
  } catch (error) {
    yield put(serverError(error))
    yield put({ type: actions.SELECT_ASYNC_ERROR, error, key })
  }
}

export default function* watch() {
  yield all([fork(watchInit), fork(watchLoad)])
}
