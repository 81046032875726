// @flow

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import { USER_GROUPS } from '../../../constants'
import NewSelectSimple from '../../NewSelectSimple'

class ChangeRolePopup extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string,
    confirm: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
    t: PropTypes.func,
    isLandlord: PropTypes.bool,
    managerGroup: PropTypes.string,
    massAction: PropTypes.bool,
  }

  static defaultProps = {
    title: 'ChangeRole',
  }

  dwellerOptions = [
    { value: USER_GROUPS.dweller, label: this.props.t('Dweller') },
    { value: USER_GROUPS.landlord, label: this.props.t('Landlord') },
  ]

  managerOptions = [
    { value: USER_GROUPS.manager, label: this.props.t('Manager') },
    { value: USER_GROUPS.admin, label: this.props.t('Admin') },
  ]
  // todo make single function for getOptions

  getOptions = () =>
    this.props.managerGroup ? this.managerOptions : this.dwellerOptions

  getOption = () => {
    const { managerGroup, isLandlord } = this.props

    if (managerGroup) {
      return managerGroup === 'manager'
        ? this.managerOptions[0]
        : this.managerOptions[1]
    } else if (isLandlord) {
      return this.dwellerOptions[1]
    }

    return this.dwellerOptions[0]
  }

  state = {
    option: this.getOption(),
    working: false,
  }

  handleClickOk = () => {
    const { massAction, onOk } = this.props
    massAction && this.setState({ working: true })
    onOk(this.state.option.value)
  }

  componentDidMount() {
    document.body.style.overflowY = 'hidden'
  }

  componentWillUnmount() {
    this.setState({ working: false })
    document.body.style.overflowY = 'scroll'
  }

  render() {
    const { onClose, title, text, confirm, t, massAction } = this.props
    const { working } = this.state
    const isButtonDisabled =
      !massAction &&
      ((this.props.isLandlord &&
        this.state.option.value === USER_GROUPS.landlord) ||
        (!this.props.isLandlord &&
          this.state.option.value === USER_GROUPS.dweller) ||
        (this.props.managerGroup === USER_GROUPS.manager &&
          this.state.option.value === USER_GROUPS.manager) ||
        (this.props.managerGroup === USER_GROUPS.admin &&
          this.state.option.value === USER_GROUPS.admin))

    return (
      <div className='modal__content--notification'>
        <button className='modal__close' type='button' onClick={onClose} />
        <div className='modal__title'> {t(title)} </div>
        <div className='modal__body'>
          <div className='modal__row'>
            <p className='modal__text'>{text}</p>
          </div>
          <div className='modal__row'>
            <NewSelectSimple
              name='change-role'
              value={this.state.option}
              options={this.getOptions()}
              onChange={option => this.setState({ option })}
            />
          </div>
        </div>
        <div className='modal__submit'>
          <button
            type='button'
            className='button button--large button--danger'
            disabled={isButtonDisabled || working}
            onClick={this.handleClickOk}
          >
            {working && <span className='button__spinner' />}
            {confirm || t('Save')}
          </button>
          <button
            type='button'
            className='button button--large button--danger-3'
            disabled={working}
            onClick={onClose}
          >
            {t('Cancel')}
          </button>
        </div>
      </div>
    )
  }
}

export default withTranslation('Modal')(ChangeRolePopup)
