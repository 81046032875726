// @flow

import React from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import type { Node } from 'react'

import Rabbit from './Rabbit'
import Checkbox from './Checkbox'
import Button from './Button'
import Textarea from './Textarea'
import Select from './Select'
import Input from './Input'
import { inviteCompany } from '../CompanyRegistration.actions.js'
import { getInputParams, getSelectParams } from './Form.utils'

import styles from './Form.module.scss'

type Props = { t: string => string }

const Form = (props: Props): Node => {
  const dispatch = useDispatch()

  const validationSchema = Yup.object({
    firstName: Yup.string().max(50).required(),
    passwordVerify: Yup.mixed()
      .required()
      .oneOf([Yup.ref('password')], props.t('PasswordNotMatch')),
    password: Yup.string().required().min(7),
    phone1: Yup.number(),
    flatsCount: Yup.number().required(),
    isLicenseAccepted: Yup.boolean().required(),
    email: Yup.string().email().required(),
    regEmail: Yup.string().email(),
    companyName: Yup.string()
      .matches(/^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-_]*[a-zA-Z0-9]))$/)
      .required()
      .max(50),
    address: Yup.string(),
    website: Yup.string(),
    country: Yup.object()
      .shape({ value: Yup.number(), label: Yup.string() })
      .required(),
    city: Yup.object()
      .shape({ value: Yup.number(), label: Yup.string() })
      .nullable()
      .required(),
  })

  const formik = useFormik({
    validateOnChange: false,
    initialValues: {
      firstName: '',
      email: '',
      companyName: '',
      country: null,
      city: null,
      address: '',
      website: '',
      phone1: '',
      flatsCount: '',
      regEmail: '',
      password: '',
      passwordVerify: '',
      isLicenseAccepted: false,
    },
    validationSchema,
    onSubmit: values => {
      dispatch(
        inviteCompany({
          first_name: values.firstName,
          country: values.country.value,
          city: values.city.value,
          name: values.companyName,
          email: values.email,
          address: values.address,
          website: values.website,
          phone1: values.phone1,
          number_flats: values.flatsCount,
          reg_working_email: values.regEmail,
          password: values.password,
          reg_repeat_password: values.passwordVerify,
          license: values.isLicenseAccepted,
          description: 'from idwell.com',
          number_of_dwellers: values.flatsCount,
        })
      )
    },
  })

  const handleChangeRegion = name => async value => {
    await formik.setFieldValue(name, value)

    if (name === 'country') {
      await formik.setFieldValue('city', null)
    }

    formik.validateField(name)
  }

  const getInputProps = name => getInputParams(formik, name, validationSchema)
  const getSelectProps = name =>
    getSelectParams(formik, name, validationSchema, handleChangeRegion(name))

  const isLicenseAccepted = formik.values.isLicenseAccepted

  return (
    <div className={styles.form}>
      <form onSubmit={formik.handleSubmit}>
        <Input
          placeholder={props.t('FirstNamePlaceholder')}
          title={props.t('FirstName')}
          type='text'
          {...getInputProps('firstName')}
        />
        <Input
          placeholder='ihre@email.com'
          title={props.t('email')}
          type='text'
          {...getInputProps('email')}
        />
        <Input
          placeholder={props.t('CompanyNamePlaceholder')}
          title={props.t('CompanyName')}
          type='text'
          {...getInputProps('companyName')}
        />
        <Select
          placeholder={props.t('CountryPlaceholder')}
          params='country'
          title={props.t('country')}
          {...getSelectProps('country')}
        />
        <Select
          disabled={!formik.values.country}
          placeholder={props.t('CityPlaceholder')}
          params={`country/${
            formik.values.country && formik.values.country.value
          }/city`}
          title={props.t('city')}
          {...getSelectProps('city')}
        />
        <Textarea title={props.t('address')} {...getInputProps('address')} />
        <Input
          placeholder='www.abc-verwaltung.at'
          title={props.t('website')}
          type='text'
          {...getInputProps('website')}
        />
        <Input
          placeholder='8 (912) 345-67-89'
          type='tel'
          title={props.t('phone')}
          {...getInputProps('phone1')}
        />
        <Input
          title={props.t('NumberFlats')}
          type='text'
          {...getInputProps('flatsCount')}
        />
        <Input
          placeholder={props.t('WorkingEmailPlaceholder')}
          title={props.t('WorkingEmail')}
          type='email'
          {...getInputProps('regEmail')}
        />
        <Input
          placeholder={props.t('PasswordSecureValidation')}
          title={props.t('Password')}
          type='password'
          {...getInputProps('password')}
        />
        <Input
          placeholder={props.t('PasswordVerifyPlaceholder')}
          title={props.t('PasswordVerify')}
          type='password'
          {...getInputProps('passwordVerify')}
        />
        <Checkbox
          name='isLicenseAccepted'
          checked={isLicenseAccepted}
          onChange={formik.handleChange}
        />
        <Button
          disabled={isLicenseAccepted}
          text={props.t('CompanyRegistrationSubmitButton')}
        />
      </form>
      <Rabbit />
    </div>
  )
}

export default Form
