//@flow

import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'

import Icon from '../../Icon'
import MoreIcon from '../../../../static/icons/more.svg'
import Select from '../Select'
import Scrollbar from '../../Scrollbar'
import * as actions from './SelectCustom.actionTypes'

import styles from './SelectCustom.module.scss'

class SelectCustom extends Select {
  static propTypes = {
    ...Select.propTypes,
    valueId: PropTypes.number,
    renderAsPencil: PropTypes.bool,
    alignLeft: PropTypes.bool,
  }

  static defaultProps = {
    ...Select.defaultProps,
    backspaceRemoves: false,
    className: 'select-status',
    clearable: false,
    openOnFocus: true,
    pageSize: 10,
    valueKey: 'id',
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isOpen !== this.state.isOpen && this.props.setOpen) {
      this.props.setOpen(this.state.isOpen)
    }
  }

  updateValue = value => {
    this.props.dispatch({ type: actions.SELECT_STATUS_SET_VALUE, value })
  }

  setValue = value => {
    this.updateValue(value)

    if (this.props.onChange) {
      this.props.onChange(value)
    }
  }

  handleClickButton = e => {
    e.stopPropagation()
    this.handleInputFocus()
  }

  handleSelect = opt => e => {
    e.stopPropagation()
    this.selectValue(opt)
  }

  renderButton = () => {
    const { tabIndex, blank, children, colored } = this.props

    const btnClass = classnames(styles.button, {
      'button button--large button--dropdown button--showmore': children,
      [styles.more]: !children,
      [styles.blank]: !children && blank,
      [styles.colored]: colored,
    })

    return (
      <button
        type='button'
        className={btnClass}
        // this select is used only on profile page
        // use open on click only, due to weird behavior with focus after popup hide,
        // onFocus={this.handleInputFocus}
        ref={_ref => {
          this.input = _ref
        }}
        tabIndex={tabIndex}
        onClick={this.handleClickButton}
        onBlur={this.onInputBlur}
      >
        {children || <MoreIcon />}
      </button>
    )
  }

  // button--large button--success-2
  renderMenu = options => {
    const { pageSize, alignLeft } = this.props

    const elems = options.map(opt => {
      // const selected = value ? value[valueKey] === opt[valueKey] : false;
      const selected = false
      const itemClass = classnames('dropdown__list-item', {
        'dropdown__list-item--checked': selected,
      })
      const textClass = `dropdown__list-item-text dropdown__list-item-text--${opt.css}`

      return (
        <li
          className={itemClass}
          key={`opt-${opt.value}`}
          onClick={this.handleSelect(opt)}
        >
          {Boolean(opt.icon) && (
            <Icon className='dropdown__list-item-icon' id={opt.icon} />
          )}
          <span className={textClass}>{opt.label}</span>
        </li>
      )
    })

    const menuClass = classnames(
      'dropdown',
      'dropdown--default',
      'dropdown__list--checklist',
      `dropdown--open dropdown--${alignLeft ? 'left' : 'right'}`,
      styles.menu
    )

    return (
      <div
        className={menuClass}
        ref={_ref => {
          this.menu = _ref
        }}
        onScroll={this.handleMenuScroll}
        onMouseDown={this.handleMouseDownOnMenu}
      >
        <Scrollbar
          items={options}
          listElements={elems}
          listClassName='dropdown__list dropdown__list--default'
          pageSize={pageSize}
        />
      </div>
    )
  }

  render() {
    const { className, options } = this.props
    const { isOpen } = this.state

    return (
      <div
        className={className}
        style={this.props.style ? this.props.style : {}}
        onClick={this.props.onClick ? this.props.onClick : () => {}}
      >
        <div>
          <div
            onKeyDown={this.handleKeyDown}
            onMouseDown={this.handleMouseDown}
            onTouchStart={this.handleTouchStart}
            onTouchMove={this.handleTouchMove}
          >
            {this.renderButton()}
          </div>
          {isOpen && this.renderMenu(options)}
        </div>
      </div>
    )
  }
}

export default withTranslation('Request')(SelectCustom)
