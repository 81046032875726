import * as actions from './AddFlatPopup.actionTypes'

const initialState = {
  error: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.FLAT_POPUP_INIT:
      return initialState
    case actions.FLAT_POPUP_ERROR:
      return {
        ...state,
        error: action.error
      }
    default:
      return state
  }
}
