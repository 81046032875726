// @flow

import React from 'react'
import { useDispatch } from 'react-redux'
import { filter } from 'lodash-es'

import ContactForm from '../../ContactForm'
import { COMPANY_CONTACTS_UPDATE_PHONES } from './CompanyContacts.actionTypes'

type Props = {
  modelId: number,
  onInit: () => void,
  profileId: number,
}

const CompanyContacts = (props: Props) => {
  const { profileId, modelId } = props

  const dispatch = useDispatch()

  const handleUpdate = data => {
    const phones = filter(data, { type: 2 })

    dispatch({ type: COMPANY_CONTACTS_UPDATE_PHONES, phones })
  }

  return (
    <ContactForm
      isCompany
      profileId={profileId}
      modelId={modelId}
      onInit={props.onInit}
      onUpdate={handleUpdate}
    />
  )
}

export default CompanyContacts
