// @flow

import { isString } from 'lodash-es'

export const newLineToBr = (text?: any): string => {
  if (!isString(text)) {
    return ''
  }

  return text.replace(/(?:\r\n|\r|\n)/g, '<br>')
}

export const brToNewLine = (text?: any): string => {
  if (!isString(text)) {
    return ''
  }

  return text.replace(/<br>|<br\/>|<br \/>/g, '\n')
}

export const clearBr = (text?: any): string => {
  if (!isString(text)) {
    return ''
  }

  return text.replace(/<p><br><\/p>/g, '<br>')
}
