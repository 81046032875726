import { findIndex } from 'lodash-es'
import * as a from './LabelsPopup.actionTypes'

const initialState = {
  initiated: false,
  error: null,
  working: true,
  labels: []
}

export default function labelsReducer (state = initialState, action) {
  switch (action.type) {
    case a.LABELS_MODAL_INIT:
      return {
        ...initialState
      }
    case a.LABELS_MODAL_SET_REQUEST_LABEL:
      return {
        ...state,
        working: true
      }
    case a.LABELS_MODAL_INITED:
      return {
        ...state,
        working: false,
        initiated: true,
        labels: action.labels
      }
    case a.LABELS_MODAL_LABEL_UPDATED: {
      const labelIndex = findIndex(state.labels, action.label)
      state.labels[labelIndex] = action.label
      const labels = [...state.labels]
      return {
        ...state,
        labels
      }
    }
    case a.LABELS_MODAL_CANCELED_LABEL: {
      return {
        ...state,
        working: false
      }
    }
    default:
      return state
  }
}
