// @flow

import React, { useState } from 'react'
import OutsideClick from 'react-onclickout'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import { getUrlForAvatar } from '../../../utils/utils'
import type { User } from '../../Auth/Login/Login.reducer'

import styles from './HeaderProfile.module.scss'

type Props = {
  user: User,
}

const HeaderProfile = (props: Props) => {
  const { user, theme } = props

  const { t } = useTranslation('Header')
  const [isProfileDropdownOpen, setProfileDropdownOpen] = useState(false)

  const handleClose = () => setProfileDropdownOpen(false)
  const handleToggle = () => setProfileDropdownOpen(!isProfileDropdownOpen)

  const userProfile = `/profile/${user.owner}`

  const buttonClass = classnames(styles.button, {
    [styles.gray]: theme === '#ffffff',
  })

  return (
    <OutsideClick onClickOut={handleClose}>
      <div
        className={`header__user header__col ${
          isProfileDropdownOpen ? 'active' : ''
        }`}
      >
        <button
          type='button'
          className={buttonClass}
          title={user.name}
          onClick={handleToggle}
        >
          <img src={getUrlForAvatar(user)} alt='' />
          {user.name}
        </button>
        <div className='header__dropdown' onClick={handleToggle}>
          {isProfileDropdownOpen ? (
            <ul className='header__dropdown-list'>
              <li>
                <Link to={userProfile}>{t('MyProfile')}</Link>
              </li>
              <li>
                <Link to='/settings/'>{t('Settings')}</Link>
              </li>
              <li className='header__dropdown-list-delimiter' />
              <li>
                <Link to='/logout'>{t('SignOut')}</Link>
              </li>
            </ul>
          ) : null}
        </div>
      </div>
    </OutsideClick>
  )
}

export default HeaderProfile
